import * as React from "react";
import { FileUpload, FileUploadController, ImageUploadPreview } from "./fileUpload";
import { Field, FieldProps } from "formik";
import { Upload } from "../../types/models/upload";
import { FormField } from "./formField";

interface Props<V> {
  label: string;
  name: keyof V;

  controller: FileUploadController;
  renderPreview: (id: string) => React.ReactNode;

  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;

  onView?: (id: string) => void;
}

export function FileUploadField<V>(props: Props<V>): React.ReactElement {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const value = field.form.values[props.name] as unknown as Upload | undefined;
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];

        return (
          <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
            <FileUpload
              controller={props.controller}
              upload={value}
              preview={value && props.renderPreview(value.id)}

              readOnly={props.readOnly}
              disabled={props.disabled}

              onUpload={(upload) => {
                field.form.setFieldError(field.field.name, "");
                field.form.setFieldValue(field.field.name, {
                  id: upload.response,
                  name: upload.name,
                  length: upload.length
                });
              }}
              onClear={() => {
                field.form.setFieldError(field.field.name, "");
                field.form.setFieldValue(field.field.name, undefined);
              }}
            />
          </FormField>
        );
      }}
    />
  );
}
