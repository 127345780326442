import * as React from "react";
import { Panel } from "../../../containers/panel";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatus } from "../../../../types/operationStatus";
import { UserFacingError } from "../../../../types/userFacingError";
import { parseBlockedAccountExceptionMessage } from "../../../../components/auth/useConnectMutation";
import { ModalController, ModalHook } from "../../../layouts/modal";

interface Props {
  modal: ModalHook;
  blockingMessage: string;
}

export const BlockingMessagePreviewModal: React.FunctionComponent<Props> = (props) => (
  <ModalController
    modal={props.modal}
    title={"Message Displayed to End Users (Preview)"}
    render={() => (
      <Panel>
        <TitlePanelRow>Migrate From</TitlePanelRow>
        <OperationStatusIndicator
          indicators={StatusIndicators.PanelRow()}
          status={
            OperationStatus.Failure(
              UserFacingError.synthetic(
                parseBlockedAccountExceptionMessage(props.blockingMessage)
              )
            )
          }
        />
      </Panel>
    )}
  />
);
