import * as React from "react";
import { CloudService } from "../../types/models/cloudService";
import { OperationStatus } from "../../types/operationStatus";
import { BatchOrder } from "../../types/models/batchOrder";
import { Blueprint } from "../../blueprints/blueprint";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined, roundCurrencyAmount, toJSON } from "../../utils/misc";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { Facts } from "../../types/facts/facts";
import { List } from "immutable";
import { BatchMigrationPreferences } from "./batchMigrationPreferences";
import { BatchMigrationItem } from "./batchCheckoutStep";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { Batch } from "../../types/models/batch";
import { buildPaymentErrorHandlers, Payment, PaymentFlowHook, usePaymentFlow } from "../usePaymentFlow";

interface BatchCheckoutFlowConfig {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: Blueprint;

  items: List<BatchMigrationItem>;
  preferences: BatchMigrationPreferences;

  onSuccess: (batch: Batch) => void;
}

interface PrepareBatchOrderResult {
  batchOrder: BatchOrder;
  clientToken: string;
}

namespace PrepareBatchOrderResult {
  export function parse(data: GraphQL.PrepareBatchOrderResultFragment): PrepareBatchOrderResult {
    return {
      batchOrder: BatchOrder.parse(data.batchOrder),
      clientToken: data.clientToken
    };
  }
}

export interface BatchCheckoutFlowHook extends PaymentFlowHook {
  loadingStatus: OperationStatus<PrepareBatchOrderResult>;
  scheduleBatchStatus: OperationStatus<any>;

  submitDisabled: boolean;
}

export function useBatchCheckoutFlow(config: BatchCheckoutFlowConfig): BatchCheckoutFlowHook {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const batchSpec = {
    sourceCloudServiceId: config.sourceCloudService.id,
    destinationCloudServiceId: config.destinationCloudService.id,
    batchItems: config.items.map((item) => ({
        blueprintInputs: MaterializedMigrationBlueprint.build({
          sourceCloudServiceId: config.sourceCloudService.id,
          destinationCloudServiceId: config.destinationCloudService.id,
          blueprint: config.blueprint,
          cloudServices,
          authProviders,
          sourceConnection: item.sourceConnection,
          destinationConnection: item.destinationConnection,
          facts: Facts.Empty,
          excludedAreas: config.preferences.excludedAreas,
          advancedOptions: {
            doNotCreateSubfolder: !config.preferences.createSubfolder
          }
        }).context.inputs.toGraphQL()
      })
    ).toArray()
  };

  const [prepareOrder, prepareOrderStatus] = usePrepareOrderMutation(batchSpec);

  React.useEffect(
    () => {
      prepareOrder();
    },
    [
      config.sourceCloudService.id,
      config.destinationCloudService.id,
      config.items.map((item) => item.sourceConnection.id + "-" + item.destinationConnection).join(","),
      toJSON(config.preferences)
    ]
  );

  const [scheduleBatch, scheduleBatchStatus] = useScheduleBatchMutation(batchSpec);

  const paymentFlow = usePaymentFlow({
    sourceConnectionId: undefined,
    order: prepareOrderStatus.isSuccess()
      ? {
        originalBasePrice: prepareOrderStatus.result.batchOrder.accountsFee,
        originalBytesFee: prepareOrderStatus.result.batchOrder.bytesFee,
        originalItemsFee: prepareOrderStatus.result.batchOrder.itemsFee,
        priceBeforeDiscounts: prepareOrderStatus.result.batchOrder.priceBeforeDiscounts,
        calcAmountToBePaid: (price, discounts) => Math.max(
          0,
          roundCurrencyAmount(
            price - discounts.reduce((a, b) => roundCurrencyAmount(a + b), 0)
          )
        ),
        isAmbassadorDiscountEnabled: false
      }
      : undefined,
    onPayment: (payment, braintreePayload, deviceData, externalPayment) =>
      scheduleBatch(payment, braintreePayload, deviceData, externalPayment).then(config.onSuccess)
  });

  const submitDisabled = (
    prepareOrderStatus.isWorking() ||
    paymentFlow.braintreeInitStatus.isWorking() ||
    paymentFlow.requestPaymentMethodStatus.isWorking() ||
    scheduleBatchStatus.isWorking() ||
    scheduleBatchStatus.isFailure()
  );

  return {
    ...paymentFlow,
    loadingStatus: prepareOrderStatus,
    scheduleBatchStatus,
    submitDisabled
  };
}

type PrepareOrderMutationHook = [() => Promise<PrepareBatchOrderResult>, OperationStatus<PrepareBatchOrderResult>];

export function usePrepareOrderMutation(
  batchSpec: GraphQL.PrepareBatchOrderMutationVariables
): PrepareOrderMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.usePrepareBatchOrderMutation,
    variables: batchSpec,
    extract: (data: GraphQL.PrepareBatchOrderMutation) => nullToUndefined(data.prepareBatchOrder),
    complete: PrepareBatchOrderResult.parse
  });

  function fireWith(): Promise<PrepareBatchOrderResult> {
    return fire({
      retry: fireWith
    });
  }

  return [fireWith, status];
}

type ScheduleBatchMutationHook = [
  (
    payment: Payment,
    braintreePayload: any,
    deviceData: any,
    externalPayment: GraphQL.ExternalPayment | undefined
  ) => Promise<Batch>,
  OperationStatus<Batch>
];

function useScheduleBatchMutation(
  batchSpec: Omit<GraphQL.ScheduleBatchMutationVariables, "expectedAmountToBePaid">
): ScheduleBatchMutationHook {
  const [fire, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useScheduleBatchMutation,
    extract: (data: GraphQL.ScheduleBatchMutation) => nullToUndefined(data.scheduleBatch),
    complete: Batch.parse,
    catch: buildPaymentErrorHandlers(() => reset())
  });

  function fireWith(
    payment: Payment,
    braintreePayload: any,
    deviceData: any,
    externalPayment: GraphQL.ExternalPayment | undefined
  ): Promise<Batch> {
    return fire({
      variables: {
        ...batchSpec,
        expectedAmountToBePaid: payment.amountToBePaid,
        discounts: {
          couponCode: payment.couponCodeDiscount?.code,
          referralCode: payment.referralCodeDiscount?.code,
        },
        braintreePayloadJson: JSON.stringify(braintreePayload),
        deviceData: deviceData !== undefined && deviceData !== null ? JSON.stringify(deviceData) : undefined,
        externalPayment
      },
      retry: () => fireWith(payment, braintreePayload, deviceData, externalPayment)
    });
  }

  return [fireWith, status];
}
