import { GraphQL } from "../../services/graphql/generated";

export interface OffboardingProjectStats {
  readonly date: Date;
  readonly pending: number;
  readonly openedLink: number;
  readonly connectedAccount: number;
  readonly startedMigration: number;
  readonly completedMigration: number;
  readonly optedOut: number;
}

export namespace OffboardingProjectStats {
  export function parse(data: GraphQL.OffboardingProjectStatsFragment): OffboardingProjectStats {
    return {
      ...data,
      date: new Date(data.date)
    };
  }
}
