import { RoutesContext } from "./routesContext";

export class SandboxRoutes {
  private readonly root = "sandbox";

  constructor(private context: RoutesContext) {}

  public get mask(): string { return this.root; }

  public get homePath(): string { return this.context.resolvePath(this.root); }
}
