import { School } from "../../types/models/school";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { SchoolFormData } from "../../types/models/schoolFormData";

type InsertSchoolMutationHook = [
  (formData: SchoolFormData) => Promise<School>,
  OperationStatus<School>
];

export function useInsertSchoolMutation(): InsertSchoolMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertSchoolMutation,
    extract: (data: GraphQL.InsertSchoolMutation) => nullToUndefined(data.insertSchool),
    complete: School.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "These domains are already being used" })
      ]
    ]
  });

  function fireWith(formData: SchoolFormData): Promise<School> {
    return fire({
      variables: { formData: SchoolFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
