import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import {
  DeleteAccessListEntriesPageView
} from "../../../views/screens/organizationPortal/accessLists/deleteAccessListEntriesPageView";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useDeleteAccessListEntriesMutation } from "../../../queries/accessLists/useDeleteAccessListEntriesMutation";
import { useGetAccessListQuery } from "../../../queries/accessLists/useGetAccessListQuery";
import { useGetAccessListEntryCountQuery } from "../../../queries/accessLists/useGetAccessListEntryCountQuery";

interface Props {
  organization: OrganizationSummary;
}

export const DeleteAccessListEntriesPage: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes().organizationPortalRoutes.accessLists(props.organization.alias);
  const params = routes.importParams();

  const [accessListStatus] = useGetAccessListQuery(params.id);
  const [entryCountStatus, refreshEntryCount] = useGetAccessListEntryCountQuery(params.id);
  const [deleteEntries, deleteStatus] = useDeleteAccessListEntriesMutation(params.id);

  return (
    <DeleteAccessListEntriesPageView
      accessListId={params.id}
      accessListStatus={accessListStatus}

      entryCountStatus={entryCountStatus}
      onEntryCountRefresh={refreshEntryCount}

      homePath={routes.homePath}
      accessListPath={routes.overviewPath(params.id)}

      deleteStatus={deleteStatus}
      onDelete={(emailAddresses) =>
        deleteEntries(emailAddresses).then((result) => {
          refreshEntryCount();
          return result;
        })
      }
    />
  );
};
