import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { State } from "../state";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { Some } from "../../utils/monads/option";
import { DataFlowOf, RelationshipStyling } from "../relationship";
import { List } from "immutable";

export class PreconditionsHub extends DataSource<PreconditionsHub.Props, boolean, boolean> {
  public calcState(context: BlueprintContext): State<boolean> {
    return State.reduceStates<boolean, boolean>(
      this.dataFlows().map((dataFlow) => dataFlow.state(context)),
      false,
      (values) => Some(values.reduce((a: boolean, b: boolean) => a && b, true)),
      State.resolved(true)
    );
  }

  public proposedRelationshipTitle(): string {
    return "requires";
  }

  public proposedRelationshipStyling(): RelationshipStyling {
    return {
      width: 3
    };
  }

  // public failedPreconditions(context: BlueprintContext): List<DataFlowOf<boolean>> {
  //   return this.dataFlows().filter((dataFlow) => {
  //     const state = dataFlow.state(context);
  //     if (state.isBlocked) {
  //       return true;
  //     } else {
  //       return !state.output.getOrUse(true);
  //     }
  //   });
  // }
}

export namespace PreconditionsHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.PreconditionsHubFragment,
    settings?: ComponentHubSettings
  ): PreconditionsHub {
    return new PreconditionsHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
