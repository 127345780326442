import * as React from "react";
import { useLocation } from "react-router";
import { AdminToolsMode } from "../../state/settings/state";
import { useSettings } from "../../utils/useAppState";
import { matchPath } from "react-router-dom";
import { useRoutes } from "../../app/routes/useRoutes";
import { Constants } from "../../app/constants";

export function useAdminToolsMode(): AdminToolsMode {
  const routes = useRoutes();
  const settings = useSettings();
  const { pathname } = useLocation();

  // Every route/page that wants to have a sidebar has to be registered here.
  const masks = [
    routes.batchMigrationSetup.homeMask,
    routes.batchMigrationSetup.wizardPathMask,

    routes.migrationSetup.homeMask,
    routes.migrationSetup.wizardPathMask,

    routes.batches.mask,
    routes.connect.mask,
    routes.migrations.migrationStatusMask,
    routes.static.migrationSimulationMask
  ];

  const contextualMasks = masks
    .concat(masks.map((mask) => Constants.Paths.program + "/:programAlias" + mask))
    .concat(masks.map((mask) => Constants.Paths.configuration + "/:configurationAlias" + mask))
    .concat(masks.map((mask) => Constants.Paths.theme + "/:themeId" + mask));

  return React.useMemo(
    () => contextualMasks.find((path) => matchPath(path, pathname))
      ? settings.adminToolsMode
      : AdminToolsMode.Hidden,
    [pathname, settings.adminToolsMode]
  );
}
