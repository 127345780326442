import * as yup from "yup";

export namespace YupTests {
  export function twoDigitalPlacesFloat(required: boolean): yup.TestOptions<FormData> {
    return {
      name: "maxDigitsAfterDecimal",
      message: "This must be a number containing no more than two decimal places (x.xx)",
      test: (amount) => !required && (amount === undefined || amount === "") || /^-?\d+(\.\d{1,2})?$/.test(amount)
    };
  }
}
