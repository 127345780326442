import * as React from "react";
import { BreadcrumbsContent } from "../widgets/breadcrumbs";
import { CenteredContent } from "../layouts/centeredContent";
import { EditableTitleConfig, HeaderBlock } from "../widgets/headerBlock";

export interface PageProps {
  breadcrumbs?: BreadcrumbsContent;
  title: string;
  error?: boolean;
  subTitle?: string;
  fullWidth?: boolean;
  editableTitleConfig?: EditableTitleConfig;
}

export const Page: React.FunctionComponent<PageProps> = (props) => (
  <CenteredContent fullWidth={props.fullWidth}>
    <HeaderBlock
      breadcrumbs={props.breadcrumbs}
      title={props.title}
      subTitle={props.subTitle}
      editableTitleConfig={props.editableTitleConfig}
    />
    {props.children}
  </CenteredContent>
);
