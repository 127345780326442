import { ConnectionFactRef } from "./connectionFactRef";
import { TypedRegistry } from "./registry";
import { List } from "immutable";
import { BlueprintFactRefFactory } from "./blueprintFactRefFactory";
import { IterationFactRef, MigrationFactRef } from "./migrationFactRef";

export const BlueprintFactRefFactoryRegistry = new TypedRegistry<BlueprintFactRefFactory>(
  "FactRef factory",
  List([ConnectionFactRef.Factory, IterationFactRef.Factory, MigrationFactRef.Factory])
);
