import * as React from "react";
import {
  OrganizationDashboardView
} from "../../../views/screens/organizationPortal/dashboard/organizationDashboardView";
import {
  useGetOrganizationMigrationStatsQuery
} from "../../../queries/migrations/useGetOrganizationMigrationStatsQuery";
import { ActivityTimeUnit } from "../../../types/enums/activityTimeUnit";
import moment from "moment";
import { OrganizationSummary } from "../../../types/models/organization";
import {
  useGetOrganizationAccessListStatsQuery
} from "../../../queries/accessLists/useGetOrganizationAccessListStatsQuery";
import {
  useIncrementSponsorshipLimitAmountRemainingToolController
} from "./incrementSponsorshipLimitAmountRemainingToolController";
import { useRemoveSponsorshipLimitController } from "./removeSponsorshipLimitToolController";

interface Props {
  organization: OrganizationSummary;
  refreshOrganization: () => void;
}

export const OrganizationDashboardPage: React.FunctionComponent<Props> = (props) => {
  const [timeUnit, setTimeUnit] = React.useState(ActivityTimeUnit.Month);

  function timeUnitCount(): number {
    switch (timeUnit) {
      case ActivityTimeUnit.Hour: return 48;
      case ActivityTimeUnit.Day: return 30;
      case ActivityTimeUnit.Month: return 24;
      case ActivityTimeUnit.Year: return 10;
    }
  }

  const [migrationStatsStatus, refreshMigrationStats] = useGetOrganizationMigrationStatsQuery({
    organizationId: props.organization.id,
    timeUnit,
    timeUnitCount: timeUnitCount(),
    recentMigrationDayCount: 30,
    timeZoneOffset: moment().utcOffset()
  });

  const [accessListStatsStatus, refreshAccessListStats] = useGetOrganizationAccessListStatsQuery(props.organization.id);

  const incrementSponsorshipLimitAmountRemainingToolController =
    useIncrementSponsorshipLimitAmountRemainingToolController(props.organization.id);
  const removeSponsorshipLimitController = useRemoveSponsorshipLimitController(props.organization.id);

  return (
    <OrganizationDashboardView
      organization={props.organization}

      timeUnit={timeUnit}
      migrationStatsStatus={migrationStatsStatus}
      onTimeUnitChange={setTimeUnit}

      accessListStatsStatus={accessListStatsStatus}

      incrementSponsorshipLimitAmountRemainingToolController={incrementSponsorshipLimitAmountRemainingToolController}
      removeSponsorshipLimitController={removeSponsorshipLimitController}

      onRefresh={() => {
        refreshMigrationStats();
        refreshAccessListStats();
        props.refreshOrganization();
      }}
    />
  );
};
