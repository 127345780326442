import * as React from "react";
import { List } from "immutable";
import { sentence } from "../../../../../utils/misc";
import { Paragraph } from "../../../../widgets/paragraph";
import { BulletPoints } from "../../../../widgets/bulletPoints";
import { ConnectLink } from "../batchConnectionStepView/endUserCredentialsPanel";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { ErrorInfo } from "./errorInfo";
import { CloudService } from "../../../../../types/models/cloudService";

interface Props {
  source: boolean;
  cloudService: CloudService;
  connectionMethod: "admin" | "end-user" | "self";
  connectLink: string;
  emailAddresses: List<string>;
}

export const MissingEmailAddressesError: React.FunctionComponent<Props> = (props) => {
  const context = props.source ? "source" : "destination";

  return (
    <ErrorInfo title={sentence(context) + " accounts could not be added."}>
      <Paragraph>
        {(() => {
          switch (props.connectionMethod) {
            case "admin":
              return "VaultMe could not find the following email addresses in your " + context + " " +
                props.cloudService.name + " account:";

            default:
              return "The following " + context + " accounts are not connected to VaultMe yet:";
          }
        })()}
      </Paragraph>
      <BulletPoints>
        {props.emailAddresses.map((emailAddress) =>
          <li key={emailAddress}>{emailAddress}</li>
        )}
      </BulletPoints>
      <Paragraph>
        Here's what you can try:
      </Paragraph>
      <BulletPoints>
        <li>
          Check the email addresses for spelling errors.
        </li>
        {(() => {
          switch (props.connectionMethod) {
            case "admin":
              return (
                <>
                  <li>
                    Make sure that the email addresses belong to the {props.cloudService.name} account that was
                    connected on the "{props.source ? "Migrate From" : "Migrate To"}" step.
                  </li>
                  <li>
                    <Paragraph>
                      Manually connect the missing {context} accounts by visiting the link below, or ask the owners
                      of these accounts to do this for you:
                    </Paragraph>
                    <ConnectLink link={props.connectLink}/>
                  </li>
                </>
              );

            case "end-user":
              return (
                <li>
                  <Paragraph>
                    Reach out to the owners of the missing {context} accounts, and ask them to connect their
                    accounts by visiting the following link:
                  </Paragraph>
                  <ConnectLink link={props.connectLink}/>
                </li>
              );

            case "self":
              return (
                <li>
                  <Paragraph>
                    Connect the missing {context} accounts by visiting the link below, or ask the owners of
                    these accounts to do this for you:
                  </Paragraph>
                  <ConnectLink link={props.connectLink}/>
                </li>
              );
          }
        })()}
      </BulletPoints>
      <Paragraph>
        If you need assistance, contact us at <EmailSupportLink/>.
      </Paragraph>
    </ErrorInfo>
  );
};
