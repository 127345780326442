import * as React from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Block } from "../containers/block";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { SimpleToolbar } from "./simpleToolbar";
import { Button } from "./button";
import { OperationStatus } from "../../types/operationStatus";
import { FormikProps } from "formik/dist/types";
import { styled } from "../../app/theme";
import { PanelRowDefs } from "../containers/rows/panelRow";
import { Link } from "react-router-dom";

export interface ChildrenConfig<FormData> {
  firstInputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  formProps: FormikProps<FormData>;
  submitting: boolean;
}

interface Props<Item, FormData, Result> {
  subject: string;

  blankFormData: FormData;
  makeFormData: (item: Item) => FormData;
  makeResult: (formData: FormData) => Result;
  validationSchema: yup.Schema<FormData>;

  children: (config: ChildrenConfig<FormData>) => React.ReactElement;
  additionalButtons?: (submitting: boolean) => React.ReactElement[];
  embedded?: boolean;
  readOnly?: boolean;

  item: Item | undefined;
  isNew: boolean;
  submitStatus: OperationStatus<Item>;

  onSubmit: (formData: Result, continueEditing: boolean) => Promise<Item>;
  cancelPath?: string;
}

export function SimpleForm<Item, FormData, Result>(props: Props<Item, FormData, Result>): React.ReactElement {
  const firstInputRef = React.createRef<HTMLInputElement | HTMLTextAreaElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []
  );

  const submitting = props.submitStatus.isWorking();

  return (
    <Formik<FormData>
      initialValues={props.item ? props.makeFormData(props.item) : props.blankFormData}
      validationSchema={props.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={!!props.item}
      onSubmit={(data, actions) => {
        actions.setSubmitting(false);
        props.onSubmit(props.makeResult(data), false);
      }}
      render={(formProps) => (
        <Form>
          <OperationStatusIndicator
            progressMessage={"Saving " + props.subject + "..."}
            failureMessage={"Failed to save " + props.subject}
            status={props.submitStatus}
            indicators={StatusIndicators.SimplePanel()}
          />
          {props.children({ firstInputRef, formProps, submitting })}
          <SimpleFormButtonsBlock>
            <SimpleToolbar
              items={
                (
                  props.embedded || props.readOnly
                    ? []
                    : [
                      <Button
                        key={"submit"}
                        type={"submit"}
                        size={"small"}
                        disabled={submitting}
                      >
                        {props.isNew ? "Create" : "Save"}
                      </Button>
                    ]
                ).concat(
                  props.readOnly
                    ? []
                    : [
                      <Button
                        key={"apply"}
                        color={props.embedded ? "primary" : "secondary"}
                        size={"small"}
                        disabled={submitting || !formProps.dirty}
                        onClick={() => formProps.validateForm().then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            props.onSubmit(props.makeResult(formProps.values), true)
                              .then((updatedItem) => {
                                if (props.item) {
                                  formProps.resetForm(props.makeFormData(updatedItem));
                                }
                              });
                          }
                        })}
                      >
                        Apply
                      </Button>
                    ]
                ).concat(
                  props.embedded
                    ? (
                      props.readOnly
                        ? []
                        : [
                          <Button
                            key={"cancel"}
                            color={"secondary"}
                            size={"small"}
                            disabled={submitting || !formProps.dirty}
                            onClick={() => {
                              if (props.embedded && props.item) {
                                formProps.resetForm(props.makeFormData(props.item));
                              }
                            }}
                          >
                            Reset
                          </Button>
                        ]
                    )
                    : (
                      props.cancelPath
                        ? [
                          <Link key={"cancel"} to={props.cancelPath}>
                            <Button
                              key={"cancel"}
                              color={"secondary"}
                              size={"small"}
                              disabled={submitting}
                            >
                              {props.readOnly ? "Back" : "Cancel"}
                            </Button>
                          </Link>
                        ]
                        : []
                    )
                ).concat(props.additionalButtons ? props.additionalButtons(submitting) : [])
              }
            />
          </SimpleFormButtonsBlock>
        </Form>
      )}
    />
  );
}

export const SimpleFormButtonsBlock = styled(Block)`
  padding-left: ${PanelRowDefs.PanelHorizontalPadding}rem;
  padding-right: ${PanelRowDefs.PanelHorizontalPadding}rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-left: ${PanelRowDefs.XSmallPanelHorizontalPadding}rem;
    padding-right: ${PanelRowDefs.XSmallPanelHorizontalPadding}rem;
  }
`;
