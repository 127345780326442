import { AnyHub } from "../../blueprints/hub";
import { AnyComponent } from "../../blueprints/component";
import { Map } from "immutable";

export enum FilterState {
  Hide = "Hide",
  Collapse = "Collapse",
  Show = "Show",
  Pin = "Pin"
}

export interface BlueprintFilters {
  filteredSegments: Map<string, FilterState>;
  filteredTags: Map<string, FilterState>;
  // filteredComponentTypes: Map<string, FilterState>;
  // filteredHubTitles: Map<string, FilterState>;
}

export namespace BlueprintFilters {
  export const Empty: BlueprintFilters = {
    filteredSegments: Map(),
    filteredTags: Map(),
    // filteredComponentTypes: Map(),
    // filteredHubTitles: Map()
  };

  export function isComponentHidden(filters: BlueprintFilters, component: AnyComponent): boolean {
    return (
      // filters.filteredComponentTypes.get(component.type) === FilterState.Hide ||
      filters.filteredSegments.get(component.segment) === FilterState.Hide ||
      !component.tags.isEmpty() &&
      !!component.tags.find((tag) => filters.filteredTags.get(tag) === FilterState.Hide)
    );
  }

  export function isRelationshipHidden(filters: BlueprintFilters, hub: AnyHub): boolean {
    return false;
    // return filters.filteredHubTitles.get(hub.title) === FilterState.Hide;
  }

  export function toJson(filters: BlueprintFilters) {
    return {
      filteredSegments: filters.filteredSegments.toArray(),
      filteredTags: filters.filteredTags.toArray(),
      // filteredComponentTypes: filters.filteredComponentTypes.toArray(),
      // filteredHubTitles: filters.filteredHubTitles.toArray()
    };
  }

  export function fromJson(json: any): BlueprintFilters {
    return {
      filteredSegments: Map(json.filteredSegments),
      filteredTags: Map(json.filteredTags),
      // filteredComponentTypes: Map(json.filteredComponentTypes),
      // filteredHubTitles: Map(json.filteredHubTitles)
    };
  }
}
