import * as React from "react";
import { AssetCollection, AssetCollectionItem } from "./assetCollection";
import { CheckMark } from "../glyphs/checkMark";
import { Envelope } from "../glyphs/envelope";
import { Images, resolveImage } from "../../app/images";
import { WarningSign } from "../glyphs/warningSign";
import { VaultMeIcon } from "../glyphs/vaultMeIcon";
import { useCloudServices } from "../../app/configuration";
import { RawImages } from "../../app/rawImages";
import { StickyNote } from "../glyphs/infoSign";
import { AppTheme, useAppTheme } from "../../app/theme";
import { DisconnectIcon } from "../glyphs/disconnectIcon";
import { InvoiceIcon } from "../glyphs/invoiceIcon";
import { PauseIcon } from "../glyphs/pauseIcon";
import { ReportIcon } from "../glyphs/reportIcon";
import { SyncIcon } from "../glyphs/syncIcon";
import { Contacts } from "../glyphs/contacts";
import { UserIcon } from "../glyphs/userIcon";

function glyphs(theme: AppTheme): AssetCollectionItem[] {
  return [
    { id: "disconnect", src: <DisconnectIcon/> },
    { id: "invoice", src: <InvoiceIcon/> },
    { id: "missing", src: Images.MissingIcon },
    { id: "pause", src: <PauseIcon/> },
    { id: "report", src: <ReportIcon/> },
    { id: "sync", src: <SyncIcon/> },

    { id: "check-mark-1", src: <CheckMark/> },
    { id: "check-mark-2", src: <CheckMark color="red" strokeWidthMultiplier={2}/> },
    { id: "check-mark-3", src: <CheckMark color="black" strokeWidthMultiplier={3}/> },
    { id: "contacts", src: <Contacts color={theme.colors.primary}/> },
    { id: "envelope", src: <Envelope color={theme.colors.primary}/> },
    { id: "warning-sign", src: <WarningSign color="yellow"/> },
    { id: "error-sign", src: <WarningSign color="red"/> },
    { id: "sticky-note", src: <StickyNote/> },
    { id: "user", src: <UserIcon color={theme.colors.primary}/> },
    { id: "vault-me", src: <VaultMeIcon/> },
  ];
}

const ServiceIcons: any[] = [
  "/assets/images/cloudServices/google/calendar.svg",
  "/assets/images/cloudServices/google/contacts.svg",
  "/assets/images/cloudServices/google/drive.svg",
  "/assets/images/cloudServices/google/gmail.svg",

  "/assets/images/cloudServices/microsoft/calendar.svg",
  "/assets/images/cloudServices/microsoft/one-drive.svg",
  "/assets/images/cloudServices/microsoft/outlook.svg",
  "/assets/images/cloudServices/microsoft/people.svg",
];

function rawImages(prefix: string): any[] {
  const result: any[] = [];
  for (const key in RawImages) {
    if (RawImages.hasOwnProperty(key) && key.startsWith(prefix)) {
      result.push(RawImages[key]);
    }
  }
  return result;
}

export const Assets: React.FunctionComponent = () => {
  const theme = useAppTheme();
  const cloudServices = useCloudServices();

  return (
    <>
      <AssetCollection
        title="Configurable and System Glyphs"
        items={glyphs(theme)}
        tileWidth={3}
        tileHeight={3}
      />
      <AssetCollection
        title="Cloud Service Logos"
        items={cloudServices.list().map((service) => ({ id: service.id, src: service.logo })).toArray()}
        tileWidth={14}
        tileHeight={2.75}
      />
      <AssetCollection
        title="Cloud Service Icons"
        items={cloudServices.list().map((service) => ({ id: service.id, src: service.icon })).toArray()}
        tileWidth={3}
        tileHeight={3}
      />
      <AssetCollection
        title="App Icons"
        items={ServiceIcons.map((icon) => resolveImage(icon)).map((icon) => ({ id: icon, src: icon }))}
        tileWidth={3}
        tileHeight={3}
      />
      <AssetCollection
        title="Blueprint Components"
        items={rawImages("/assets/images/blueprint/").map((icon) => ({ id: icon, src: icon }))}
        tileWidth={3}
        tileHeight={3}
        backgroundColor={"#606060"}
      />
    </>
  );
};
