import { Migration } from "../types/models/migration";
import { OperationStatus } from "../types/operationStatus";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";

type SetAutoResumingConfigurationHook = [
  (enabled: boolean, timestamp: Date | undefined) => Promise<Migration>,
  OperationStatus<Migration>
];

export function useSetAutoResumingConfiguration(migrationId: string): SetAutoResumingConfigurationHook {
  const [fireWith, { status }] = useManagedMutation({
    mutation: GraphQL.useSetAutoResumingConfigurationMutation,
    extract: (data: GraphQL.SetAutoResumingConfigurationMutation) => nullToUndefined(data.setAutoResumingConfiguration),
    complete: (migration) => Migration.parseCore(migration)
  });

  function fire(enabled: boolean, timestamp: Date | undefined): Promise<Migration> {
    return fireWith({
      variables: {
        migrationId,
        enabled,
        timestamp: timestamp?.toISOString()
      },
      retry: () => fire(enabled, timestamp),
    });
  }

  return [fire, status];
}
