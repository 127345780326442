import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { useCloudServices } from "../../../../app/configuration";
import { SignInLayout } from "../../../blocks/auth/signInLayout";
import {
  ConnectEmailDeliveryAccountViewProps
} from "../../../../controllers/organizationPortal/automatedOffboarding/connectEmailDeliveryAccountController";
import { OneTimeAuthCodeSummary } from "../../../../types/models/oneTimeAuthCodeSummary";

interface Props extends ConnectEmailDeliveryAccountViewProps {
  onSignIn: (oneTimeAuthCodeSummary: OneTimeAuthCodeSummary, cloudServiceId: string) => void;
}

export const ConnectEmailDeliveryAccountTool: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();

  return (
    <>
      <OperationStatusIndicator
        subject={"sign-in form configuration"}
        status={props.signInFormConfigStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      {
        props.signInFormConfigStatus.isSuccess() &&
        props.signInFormConfigStatus.result &&
        props.signInFormConfigStatus.result.flatMap(({ cloudServiceId, signInComponents }) =>
          signInComponents.map((signInComponent, index) =>
            <React.Fragment key={cloudServiceId + "-" + index}>
              {
                React.createElement(signInComponent, {
                  layoutComponent: SignInLayout,
                  verb: "Sign In",
                  cloudServiceId,
                  authProviderId: cloudServices.getOrFail(cloudServiceId).authProviderId,
                  roles: props.roles,
                  onSignIn: (oneTimeAuthCodeSummary) => props.onSignIn(oneTimeAuthCodeSummary, cloudServiceId)
                })
              }
            </React.Fragment>
          )
        )
      }
    </>
  );
};
