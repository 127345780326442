import * as React from "react";
import { UserInfoPanel } from "../views/blocks/userManagement/userInfoPanel";
import { Block } from "../views/containers/block";
import { ConnectionsPanel } from "./connectionManagement/connectionsPanel";
import { ConnectionManagementTools } from "./connectionManagement/connectionManagementTools";
import { User } from "../types/models/user";
import { useDrawer } from "../views/layouts/drawer";
import { useUserMigrations } from "./useUserMigrations";
import { UserMigrationsPanel } from "views/blocks/userManagement/userMigrationsPanel";

interface UserInfoAndConnectionsProps {
  user: User;
  headerContent?: React.ReactNode;
}

export const UserProfile: React.FunctionComponent<UserInfoAndConnectionsProps> = (props) => {
  const drawer = useDrawer();

  const [status, refresh] = useUserMigrations(props.user.id, true);

  return (
    <>
      <Block>
        <UserInfoPanel
          user={props.user}
          headerContent={props.headerContent}
          displayAdminTools={true}
        />
      </Block>
      <Block>
        <ConnectionsPanel
          userId={props.user.id}
          showStatusRow={true}
          actionTitle={undefined}
          onAction={(connection) => drawer.open(<ConnectionManagementTools connection={connection}/>)}
        />
      </Block>
      <Block>
        <UserMigrationsPanel
          status={status}
          onRefresh={refresh}
        />
      </Block>
    </>
  );
};
