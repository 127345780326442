import * as React from "react";
import { styled } from "../../app/theme";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { TextBox } from "./textBox";
import { FormErrorMessage } from "./formErrorMessage";
import { FormikProps } from "formik/dist/types";

interface TextFieldProps<T> {
  label: string;
  name: keyof T;
  required?: boolean;
  autoComplete?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  maxWidth?: number;
  placeholder?: string;
  appendix?: React.ReactNode;
  appendixMargin?: "normal" | "double";
  textBoxRef?: React.RefObject<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<any>, form: FormikProps<T>) => void;
  dirtyRegExp?: RegExp;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const AppendixContainer = styled.div<{ appendixMargin: "normal" | "double" }>`
  margin-left: ${(props) => props.appendixMargin === "normal" ? 0.5 : 1}rem;
  flex-shrink: 0;
`;

// tslint:disable-next-line function-name
export function TextField<V>(props: TextFieldProps<V>) {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];
        return (
          <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
            <Row>
              <TextBox
                {...field.field}
                ref={props.textBoxRef}
                error={error}
                autoComplete={props.autoComplete === undefined || props.autoComplete ? "on" : "off"}
                disabled={props.disabled}
                readOnly={props.readOnly}
                maxLength={props.maxLength}
                maxWidth={props.maxWidth}
                placeholder={props.placeholder}
                onChange={(event) => {
                  if (!props.dirtyRegExp || props.dirtyRegExp.test(event.target.value)) {
                    field.form.setFieldError(event.target.name, "");
                    props.onChange ? props.onChange(event, field.form) : field.field.onChange(event);
                  } else {
                    // It's not clear what to do if we want to ignore the change without any side effects. Currently,
                    // the change is ignored, but the cursor jumps to the end of the input as if the value was
                    // updated programmatically (most probably, this is because the change is reverted by Formik).
                  }
                }}
              />
              {props.appendix && (
                <AppendixContainer appendixMargin={props.appendixMargin || "normal"}>
                  {props.appendix}
                </AppendixContainer>
              )}
            </Row>
            <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
          </FormField>
        );
      }}
    />
  );
}
