import { EnableAuthRolesViewProps } from "../../../../controllers/enableAuthRolesController";
import * as React from "react";
import { SignInFlowState } from "../../../../components/auth/useSignInFlow";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { SignInLayout } from "../../../blocks/auth/signInLayout";

export const EnableAuthRolesView: React.FunctionComponent<EnableAuthRolesViewProps> = (props) => {
  const state = props.state;

  switch (state.type) {
    case SignInFlowState.Type.SelectingCloudService:
      return null;

    case SignInFlowState.Type.InitializingSignInForm:
      return (
        <OperationStatusIndicator
          subject={"sign-in form configuration"}
          status={state.status}
          indicators={StatusIndicators.PanelRow()}
        />
      );

    case SignInFlowState.Type.PendingSignIn: {
      return (
        <>
          {
            state.signInComponents.map((signInComponent, index) =>
              React.createElement(signInComponent, {
                key: index,
                layoutComponent: SignInLayout,
                verb: "Sign In",
                cloudServiceId: state.cloudService.id,
                authProviderId: state.authProvider.id,
                roles: state.roles,
                onSignIn: props.onSignIn
              })
            )
          }
        </>
      );
    }

    case SignInFlowState.Type.CompletingSignIn:
      return (
        <OperationStatusIndicator
          progressMessage={"Completing authentication..."}
          successMessage={"Done!"}
          status={state.status}
          indicators={StatusIndicators.PanelRow()}
        />
      );
  }
};
