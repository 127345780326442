import * as React from "react";
import {
  ProgramViewPathFactory
} from "../../../views/blocks/lookups/programLookupField";
import { useRoutes } from "../../../app/routes/useRoutes";

export function useProgramViewPathFactory(organizationId: number): ProgramViewPathFactory {
  const routes = useRoutes().organizationPortalRoutes;

  return React.useCallback(
    (programId) =>
      organizationId !== undefined ? routes.programs(organizationId).editPath(programId) : "",
    [organizationId]
  );
}
