import { Organization } from "../../types/models/organization";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { OrganizationFormData } from "../../types/models/organizationFormData";

type InsertOrganizationMutationHook = [
  (formData: OrganizationFormData) => Promise<Organization>,
  OperationStatus<Organization>
];

export function useInsertOrganizationMutation(): InsertOrganizationMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertOrganizationMutation,
    extract: (data: GraphQL.InsertOrganizationMutation) => nullToUndefined(data.insertOrganization),
    complete: Organization.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(formData: OrganizationFormData): Promise<Organization> {
    return fire({
      variables: { formData: OrganizationFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
