import * as React from "react";
import { wizardFactory } from "../../wizardry/wizard/makeWizard";
import { futureStep } from "../../wizardry/utils";
import { SourceAndDestination, SourceAndDestinationStep } from "../sourceAndDestinationStep";
import { combineLatest, of } from "rxjs";
import { CloudServices } from "../../types/models/cloudServices";
import { Blueprint } from "../../blueprints/blueprint";
import { map, mergeMap } from "rxjs/operators";
import { MigrationSetupWorkflowContext } from "../migrationSetupWorkflowContext";
import { CloudService } from "../../types/models/cloudService";
import {
  BatchMigrationPlanStep,
  MigrationPlanStepResult,
  PendingBatchMigrationItem,
} from "./batchMigrationPlanStep";
import { List } from "immutable";
import { friendlyCount } from "../../utils/formatting";
import { Connections } from "../../types/models/connections";
import { withoutUndefined } from "../../utils/misc";
import { BatchCheckoutStep, BatchMigrationItem, CheckoutStepResult } from "./batchCheckoutStep";
import { finishWizard } from "../../wizardry/wizard/wizardDestination";
import { BatchMigrationPreferencesStep } from "./batchMigrationPreferencesStep";
import { BatchMigrationPreferences } from "./batchMigrationPreferences";
import { BatchConnectionStep } from "./batchConnectionStep";
import { Connection } from "../../types/models/connection";
import { ConnectionSettings, ResolvedConnectionSettings } from "./connectionSettings";
import { Facts } from "../../types/facts/facts";
import { WorkStatus } from "../../views/models/workStatus";
import { BatchMigrationPlan, BatchMigrationPlanWithFacts } from "./batchMigrationPlan";
import {
  BatchConnectionInterstitialStep
} from "../../views/screens/migrationSetup/batch/batchConnectionInterstitialStep";

export interface BatchMigrationSetupWorkflowResult {
  message: string;
}

const factory = wizardFactory<MigrationSetupWorkflowContext, undefined, BatchMigrationSetupWorkflowResult>();

export const BatchMigrationSetupWorkflow = factory.makeWizard({
  startFrom: () => Providers({}),
});

const TotalSteps = 5;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Providers {
  export interface ResultContext {
    blueprint: Blueprint | undefined;
  }
}

const Providers = factory.makeStep<{}, {}, SourceAndDestination, Providers.ResultContext>({
  id: "types",
  propsContext: () => ({}),
  resultContext: ({ workflowContext, result }) => {
    if (result.sourceCloudServiceId && result.destinationCloudServiceId) {
      return workflowContext.loadBlueprint(result.sourceCloudServiceId, result.destinationCloudServiceId)
        .pipe(map((blueprint) => ({ blueprint })));
    } else {
      return of({ blueprint: undefined });
    }
  },
  render: ({ result, resultContext, hooks }) => (
    <SourceAndDestinationStep
      step={1}
      totalSteps={TotalSteps}
      predefinedSourceCloudServiceId={undefined}
      predefinedDestinationCloudServiceId={undefined}
      sourceCloudServiceId={result && result.sourceCloudServiceId}
      destinationCloudServiceId={result && result.destinationCloudServiceId}
      blueprint={resultContext && resultContext.blueprint}
      {...hooks}
    />
  ),
  title: "Account Types",
  numbered: true,
  description: ({ workflowContext, result, resultContext }) =>
    result && result.sourceCloudServiceId && result.destinationCloudServiceId && resultContext &&
    "Migrate accounts from " +
    workflowContext.sourceCloudServices.getOrFail(result.sourceCloudServiceId).name +
    " to " +
    workflowContext.destinationCloudServices.getOrFail(result.destinationCloudServiceId).name,
  progress: ({ result }) => result && (!result.sourceCloudServiceId || !result.destinationCloudServiceId)
    ? 50
    : undefined,
  resultSchema: SourceAndDestination.Schema,
  route: ({ workflowContext, result, resultContext }) => {
    if (result.sourceCloudServiceId && result.destinationCloudServiceId && resultContext.blueprint) {
      return SourceConnection({
        sourceCloudService: workflowContext.sourceCloudServices.getOrFail(result.sourceCloudServiceId),
        destinationCloudService: workflowContext.destinationCloudServices.getOrFail(result.destinationCloudServiceId),
        blueprint: resultContext.blueprint
      });
    }
  },
  getFutureSteps: () => [
    futureStep(true, "Migrate From"),
    futureStep(true, "Migrate To"),
    futureStep(true, "Migration Plan"),
    futureStep(true, "Preferences"),
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace SourceConnection {
  export interface Props {
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
  }

  export interface ResultContext {
    connection: Connection | undefined;
  }
}

const SourceConnection = factory.makeStep<
  SourceConnection.Props,
  {},
  ConnectionSettings,
  SourceConnection.ResultContext>(
  {
    id: "source",
    propsContext: () => ({}),
    resultContext: ({ workflowContext, result }) =>
      result.adminConnectionId
        ? workflowContext
          .loadConnection(result.adminConnectionId)
          .pipe(
            map((connection) => ({ connection }))
          )
        : of({ connection: undefined }),
    render: ({ props, result, resultContext, hooks }) => (
      <BatchConnectionStep
        step={2}
        totalSteps={TotalSteps}

        source={true}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}

        connectionSettings={result && { mode: result.mode, adminConnection: resultContext?.connection }}

        {...hooks}
      />
    ),
    title: "Migrate From",
    numbered: true,
    description: ({ props, result , resultContext }) =>
      result && resultContext && ConnectionSettings.isValid(result, props.sourceCloudService.id)
        ? ConnectionSettings.description(true, result, props.sourceCloudService, resultContext.connection)
        : undefined,
    progress: ({ result }) => result && (
      result.mode === "admin"
        ? (result.adminConnectionId ? undefined : 50)
        : undefined
    ),
    resultSchema: ConnectionSettings.Schema,
    route: ({ props , result, resultContext }) =>
      ConnectionSettings.isValid(result, props.sourceCloudService.id)
        ? Interstitial({
          ...props,
          sourceConnectionSettings: { mode: result.mode, adminConnection: resultContext.connection }
        })
        : undefined,
    getFutureSteps: () => [
      futureStep(true, "Migrate To"),
      futureStep(true, "Migration Plan"),
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ]
  });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Interstitial = factory.makeInterstitialStep<DestinationConnection.Props>({
  id: "source-connected",
  renderInterstitial: ({ props, hooks }) => (
    <BatchConnectionInterstitialStep
      step={2}
      totalSteps={TotalSteps}

      sourceCloudService={props.sourceCloudService}
      destinationCloudService={props.destinationCloudService}

      {...hooks}
    />
  ),
  route: ({ props }) => DestinationConnection(props),
  getFutureSteps: () => [
    futureStep(true, "Migrate To"),
    futureStep(true, "Migration Plan"),
    futureStep(true, "Preferences"),
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace DestinationConnection {
  export interface Props extends SourceConnection.Props {
    sourceConnectionSettings: ResolvedConnectionSettings;
  }
}

const DestinationConnection = factory.makeStep<
  DestinationConnection.Props,
  {},
  ConnectionSettings,
  SourceConnection.ResultContext>(
  {
    id: "destination",
    propsContext: () => ({}),
    resultContext: ({ workflowContext, result }) =>
      result.adminConnectionId
        ? workflowContext
          .loadConnection(result.adminConnectionId)
          .pipe(
            map((connection) => ({ connection }))
          )
        : of({ connection: undefined }),
    render: ({ props, result, resultContext, hooks }) => (
      <BatchConnectionStep
        step={3}
        totalSteps={TotalSteps}

        source={false}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}

        connectionSettings={result && { mode: result.mode, adminConnection: resultContext?.connection }}

        {...hooks}
      />
    ),
    title: "Migrate To",
    numbered: true,
    description: ({ props, result , resultContext }) =>
      result && resultContext && ConnectionSettings.isValid(result, props.destinationCloudService.id)
        ? ConnectionSettings.description(false, result, props.destinationCloudService, resultContext.connection)
        : undefined,
    progress: ({ result }) => result && (
      result.mode === "admin"
        ? (result.adminConnectionId ? undefined : 50)
        : undefined
    ),
    resultSchema: ConnectionSettings.Schema,
    route: ({ props , result, resultContext }) =>
      ConnectionSettings.isValid(result, props.destinationCloudService.id)
        ? MigrationPlan({
          ...props,
          destinationConnectionSettings: { mode: result.mode, adminConnection: resultContext.connection }
        })
        : undefined,
    getFutureSteps: () => [
      futureStep(true, "Migration Plan"),
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ]
  });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace MigrationPlan {
  export interface Props {
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    sourceConnectionSettings: ResolvedConnectionSettings;
    destinationConnectionSettings: ResolvedConnectionSettings;
    blueprint: Blueprint;
  }

  export interface ResultContext {
    pendingBatchMigrationItems: List<PendingBatchMigrationItem>;
    connections: Connections;
    facts: Facts;
    batchMigrationPlan: BatchMigrationPlan;
    batchMigrationPlanWithFacts: BatchMigrationPlanWithFacts;
  }
}

const MigrationPlan = factory.makeStep<
  MigrationPlan.Props,
  {},
  MigrationPlanStepResult,
  MigrationPlan.ResultContext>(
  {
    id: "plan",
    propsKey: (props) => ({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprintId: props.blueprint.id()
    }),
    propsContext: () => ({}),
    resultContext: ({ workflowContext, props, result, prevResultContext }) =>
      workflowContext
        .loadConnections(
          List(result.items)
            .flatMap((item) =>
              List(withoutUndefined([item.sourceConnectionId, item.destinationConnectionId]))
            )
            .toSet()
        )
        .pipe(
          mergeMap((connections) => {
            const pendingBatchMigrationItems = List(result.items)
              .map((item) => PendingBatchMigrationItem.build(item, connections))
              .filter((item) =>
                (
                  !item.sourceConnection ||
                  item.sourceConnection.cloudServiceId === props.sourceCloudService.id
                ) &&
                (
                  !item.destinationConnection ||
                  item.destinationConnection.cloudServiceId === props.destinationCloudService.id
                )
              );

            const batchContext = {
              cloudServices: workflowContext.cloudServices,
              authProviders: workflowContext.authProviders,

              sourceCloudService: props.sourceCloudService,
              destinationCloudService: props.destinationCloudService,

              blueprint: props.blueprint
            };

            // Attempted to use batchMigrationPlan from prevResultContext, but it did not work - prevResultContext
            // appeared to be always undefined :-\

            const batchMigrationPlan = (
              prevResultContext && prevResultContext.batchMigrationPlan.matchesContext(batchContext)
                ? prevResultContext.batchMigrationPlan
                : new BatchMigrationPlan(batchContext)
            ).setItems(pendingBatchMigrationItems);

            return workflowContext
              .loadBatchFacts(batchMigrationPlan)
              .pipe(
                map((facts) => ({
                  pendingBatchMigrationItems,
                  connections,
                  facts,
                  batchMigrationPlan,
                  batchMigrationPlanWithFacts: batchMigrationPlan.withFacts(facts)
                }))
              );
          })
        ),
    progress: ({ result, resultContext }) => {
      if (result && resultContext) {
        const rows = resultContext.batchMigrationPlanWithFacts.gridRows;
        const working = rows.find((row) =>
          row.sourceStatus === WorkStatus.Working ||
          row.destinationStatus === WorkStatus.Working
        );
        return working !== undefined ? -1 : undefined;
      } else {
        return undefined;
      }
    },
    errorIndicator: ({ result, resultContext }) => {
      if (result && resultContext) {
        const rows = resultContext.batchMigrationPlanWithFacts.gridRows;
        const failed = rows.find((row) =>
          row.sourceStatus === WorkStatus.Issue ||
          row.sourceStatus === WorkStatus.Failure ||
          row.destinationStatus === WorkStatus.Issue ||
          row.destinationStatus === WorkStatus.Failure
        );
        return failed !== undefined ? "Something went wrong" : undefined;
      } else {
        return undefined;
      }
    },
    render: ({ workflowContext, props, resultContext, result, hooks }) => (
      <BatchMigrationPlanStep
        step={4}
        totalSteps={TotalSteps}

        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}
        sourceConnectionSettings={props.sourceConnectionSettings}
        destinationConnectionSettings={props.destinationConnectionSettings}
        blueprint={props.blueprint}

        items={resultContext?.pendingBatchMigrationItems || List()}
        batchMigrationPlan={
          resultContext
            ? resultContext.batchMigrationPlanWithFacts
            : new BatchMigrationPlan({
              cloudServices: workflowContext.cloudServices,
              authProviders: workflowContext.authProviders,

              sourceCloudService: props.sourceCloudService,
              destinationCloudService: props.destinationCloudService,

              blueprint: props.blueprint
            }).withFacts(Facts.Empty)
        }

        {...hooks}
      />
    ),
    title: "Migration Plan",
    numbered: true,
    description: ({ resultContext }) => resultContext &&
      friendlyCount(resultContext.batchMigrationPlanWithFacts.gridRows.size, "account") + " added",
    resultSchema: MigrationPlanStepResult.Schema,
    // validateResult: ({ resultContext }) => !!(resultContext && resultContext.connection),
    route: ({ props, resultContext, result }) =>
      Preferences({
        ...props,

        items: List(
          withoutUndefined(
            result.items.map((item) => BatchMigrationItem.build(item, resultContext.connections))
          )
        ).filter((item) =>
          item.sourceConnection.cloudServiceId === props.sourceCloudService.id &&
          item.destinationConnection.cloudServiceId === props.destinationCloudService.id
        ),
        batchMigrationPlanWithFacts: resultContext.batchMigrationPlanWithFacts,

        connections: resultContext.connections,
        facts: resultContext.facts
      }),
    getFutureSteps: () => [
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ],
  });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Preferences {
  export interface Props {
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    sourceConnectionSettings: ResolvedConnectionSettings;
    destinationConnectionSettings: ResolvedConnectionSettings;
    blueprint: Blueprint;

    items: List<BatchMigrationItem>;
    batchMigrationPlanWithFacts: BatchMigrationPlanWithFacts;

    connections: Connections;
    facts: Facts;
  }
}

const Preferences = factory.makeStep<Preferences.Props, {}, BatchMigrationPreferences, {}>({
  id: "preferences",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ props, result, hooks }) => {
    const notSuccessful = props.batchMigrationPlanWithFacts.gridRows.find((row) =>
      row.sourceStatus !== WorkStatus.Success ||
      row.destinationStatus !== WorkStatus.Success
    );
    return (
      <BatchMigrationPreferencesStep
        step={5}
        totalSteps={TotalSteps}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}
        blueprint={props.blueprint}
        items={props.items}
        prevResult={result}
        isReady={notSuccessful === undefined}
        {...hooks}
      />
    );
  },
  title: "Preferences",
  numbered: true,
  resultSchema: BatchMigrationPreferences.Schema,
  route: ({ props, resultContext, result }) => {
    const notSuccessful = props.batchMigrationPlanWithFacts.gridRows.find((row) =>
      row.sourceStatus !== WorkStatus.Success ||
      row.destinationStatus !== WorkStatus.Success
    );
    return notSuccessful === undefined
      ? Checkout({
        preferences: result,
        ...props
      })
      : undefined;
  },
  getFutureSteps: () => [
    futureStep(false, "Confirm & Go"),
  ],
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Checkout {
  export interface Props {
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    items: List<BatchMigrationItem>;
    preferences: BatchMigrationPreferences;
  }
}

const Checkout = factory.makeStep<Checkout.Props, {}, CheckoutStepResult, {}>({
  id: "checkout",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ workflowContext, props, result, hooks }) => (
    <BatchCheckoutStep
      sourceCloudService={props.sourceCloudService}
      destinationCloudService={props.destinationCloudService}
      blueprint={props.blueprint}
      items={props.items}
      preferences={props.preferences}
      {...hooks}
    />
  ),
  title: "Confirm & Go",
  resultSchema: CheckoutStepResult.Schema,
  route: ({ result }) => finishWizard({ message: "Done" }),
});
