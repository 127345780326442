import { IdGetterObj } from "apollo-cache-inmemory";
import { MigrationNote } from "../../types/models/migrationNote";
import { CacheIdGenerator } from "./cacheIdGenerator";
import { Map } from "immutable";
import { MigrationNoteList } from "../../types/models/migrationNoteList";
import { Scan } from "../../types/models/scan";
import { Order } from "../../types/models/order";

const CacheIdGenerators: Map<string, CacheIdGenerator<any, any>> = Map(
  [
    MigrationNote.cacheId,
    MigrationNoteList.cacheId,
    Order.cacheId,
    Scan.cacheId,
  ].map((generator): [string, CacheIdGenerator<any, any>] => [generator.typename, generator])
);

export function dataIdFromObject(object: IdGetterObj): string | undefined {
  if (object.__typename) {
    const generator = CacheIdGenerators.get(object.__typename);
    if (generator) {
      return generator.forObject(object);
    }
  }
}
