import * as React from "react";
import * as yup from "yup";
import { ChildrenConfig, SimpleForm } from "../../widgets/simpleForm";
import { Button } from "../../widgets/button";
import { FormConfig } from "./formConfig";

interface Props<Item, FormData, Result> {
  config: FormConfig<Item, Result>;

  subject: string;
  embedded?: boolean;
  readOnly?: boolean;

  blankFormData: FormData;
  makeFormData: (item: Item) => FormData;
  makeResult: (formData: FormData) => Result;
  validationSchema: yup.Schema<FormData>;

  children: (config: ChildrenConfig<FormData>) => React.ReactElement;
}

export function ItemForm<Item, FormData, Result>(
  props: Props<Item, FormData, Result>
): React.ReactElement {
  return (
    <SimpleForm<Item, FormData, Result>
      subject={props.subject}

      blankFormData={props.blankFormData}
      makeFormData={props.makeFormData}
      makeResult={props.makeResult}
      validationSchema={props.validationSchema}

      item={props.config.item}
      isNew={props.config.isNew}
      submitStatus={props.config.submitStatus}

      onSubmit={props.config.onSubmit}
      cancelPath={props.config.cancelPath}

      additionalButtons={(submitting) =>
        (
          props.config.onClone && !props.readOnly
            ? [(
              <Button
                key={"clone"}
                color={"secondary"}
                size={"small"}
                disabled={submitting}
                onClick={props.config.onClone}
              >
                Clone
              </Button>
            )]
            : []
        ).concat(
          props.config.onDelete && !props.readOnly
            ? [(
              <Button
                key={"delete"}
                color={"red"}
                size={"small"}
                disabled={submitting}
                onClick={props.config.onDelete}
              >
                Delete
              </Button>
            )]
            : []
        )
      }
      children={props.children}
      embedded={props.embedded}
      readOnly={props.readOnly}
    />
  );
}
