import * as React from "react";
import { EnableAuthRolesController } from "../../../../controllers/enableAuthRolesController";
import { ControllerHost } from "../../../../utils/controller";
import { PanelRow } from "views/containers/rows/panelRow";
import { EnableAuthRolesView } from "./enableAuthRolesView";

interface Props {
  controller: EnableAuthRolesController;
}

export const EnableEmailAddressVerificationTool: React.FunctionComponent<Props> = (props) => (
  <>
    <PanelRow>
      VaultMe requires additional permissions to verify your friend's email addresses. Click the button below to allow
      VaultMe to verify email addresses by checking past messages in your mailbox.
    </PanelRow>
    <ControllerHost
      controller={props.controller}
      render={(viewProps) => <EnableAuthRolesView {...viewProps}/>}
    />
  </>
);
