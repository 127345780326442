import { GraphQL } from "../../graphql/generated";
import { List } from "immutable";
import { WatcherAction } from "../watcherAction";
import { WatcherPlugin } from "../watcherPlugin";
import { ApolloClient } from "apollo-client";
import { WatchedItems } from "../watchedItems";

export namespace WatchedBatches {
  type WatchBatchesActionType = "WatchBatches";

  export interface WatchBatchesAction extends WatcherAction<WatchBatchesActionType> {
    batchIds: List<string>;
  }

  export const WatchBatchesAction = (batches: Iterable<string>): WatchBatchesAction => ({
    type: "WatchBatches",
    batchIds: List(batches)
  });

  export const Plugin: WatcherPlugin<ApolloClient<object>, string, number> = {
    itemType: "batch",

    refreshTimeout: 30 * 1000,

    key: (item) => item,

    // discriminator: (context, item) => {
    //   if (item.__typename) {
    //     const batch = context.readFragment(
    //       {
    //         id: item.__typename + ":" + item.id,
    //         fragment: gql(`
    //           fragment Value on ${item.__typename} {
    //             value {
    //               type
    //               completeValue
    //               partialValue
    //             }
    //           }
    //         `)
    //       }
    //     );
    //     return batch && batch.value.completeValue;
    //   }
    // },

    reduce: (action) => {
      if (action.type === "WatchBatches") {
        return WatchedItems.Diff.build(action.batchIds);
      }
    },

    updateSubscriptions: (context, diff, dispatchRenews) => {
      if (!diff.added.isEmpty()) {
        context
          .subscribe<GraphQL.BatchesUpdatedSubscription, GraphQL.BatchesUpdatedSubscriptionVariables>({
            query: GraphQL.BatchesUpdatedDocument,
            variables: { batchIds: diff.addedItems.toArray() }
          })
          .subscribe(({ data }) => {
            if (data) {
              dispatchRenews(data.batchesUpdated.batches.map((batch) => batch.id));
            }
          });
      }

      // Note: Un-subscribing is not supported by Apollo Client, so not doing anything for diff.removed
    },

    refreshItems: (context, batchIds, dispatchRenews) => {
      if (!batchIds.isEmpty()) {
        return context
          .query<GraphQL.GetBatchesQuery, GraphQL.GetBatchesQueryVariables>({
            query: GraphQL.GetBatchesDocument,
            variables: { batchIds: batchIds.toArray() },
            fetchPolicy: "network-only"
          })
          .then(({ data }) => dispatchRenews(data.batches.map((batch) => batch.id)));
      }
    }
  };
}
