export enum ProgramStatus {
  Disabled = "Disabled",
  Restricted = "Restricted",
  Live = "Live"
}

export namespace ProgramStatus {
  export function friendlyDescription(status: ProgramStatus): string {
    switch (status) {
      case ProgramStatus.Disabled: return "Disabled (does not allow new applications)";
      case ProgramStatus.Restricted: return "Restricted (not offered to users even if they are eligible)";
      case ProgramStatus.Live: return "Live";
    }
  }
}
