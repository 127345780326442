import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";

interface ServiceAccountSignInFlowConfig {
  cloudServiceId: string;
  roles: string[];
  state: any;
}

interface ServiceAccountSignInFlowHook {
  signIn: (emailAddress: string) => Promise<OneTimeAuthCodeSummary>;
  status: OperationStatus<OneTimeAuthCodeSummary>;
}

export function useServiceAccountSignInFlow(config: ServiceAccountSignInFlowConfig): ServiceAccountSignInFlowHook {
  const [serviceAccountSignIn, { status: serviceAccountSignInStatus }] = useManagedMutation({
    mutation: GraphQL.useServiceAccountSignInMutation,
    extract: (data: GraphQL.ServiceAccountSignInMutation) => nullToUndefined(data.serviceAccountSignIn),
    // Passing result
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates
    // a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect
    // cleanup function.
    complete: OneTimeAuthCodeSummary.fromGraphQL,
    catch: [
      [
        ErrorClass.InvalidCredentialsError,
        (error) => UserFacingError.expected(error, {
          title: "This email address is not valid",
        })
      ]
    ]
  });

  function signIn(emailAddress: string): Promise<OneTimeAuthCodeSummary> {
    return serviceAccountSignIn({
      variables: {
        cloudServiceId: config.cloudServiceId,
        roles: config.roles,
        emailAddress,
        state: JSON.stringify(config.state)
      },
      retrying: true
    });
  }

  return {
    signIn,
    status: serviceAccountSignInStatus
  };
}
