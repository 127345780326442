import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

export function useGetOffboardingProjectEntryCountQuery(
  id: string | undefined
): ManagedQueryHook<GraphQL.GetOffboardingProjectEntryCountQueryVariables, number> {
  return useManagedQuery({
    query: GraphQL.useGetOffboardingProjectEntryCountQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetOffboardingProjectEntryCountQuery) => nullToUndefined(data.offboardingProjectEntryCount),
    complete: identity
  });
}
