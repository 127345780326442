import * as React from "react";
import { keyframes, styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

const Background = styled.div`
  position: absolute;
  top: -1px;
  width: 100%;
  height: 2px; 
  overflow-x: hidden;
`;

const Line = styled.div<{ progress: number }>`
  position: absolute;
  background: ${(props) => props.theme.colors.primary};
  height: 2px;
  left: 0;
  width: ${(props) => props.progress}%; 
  transition: width .5s;
`;

interface ProgressBarProps extends StyledComponentsProps {
  progress: number;
}

const StyledProgressBar: React.FunctionComponent<ProgressBarProps> = (props) => (
  <Container className={props.className}>
    <Background>
      <Line progress={props.progress}/>
    </Background>
  </Container>
);

export const ProgressBar = styled(StyledProgressBar)``;
