import * as React from "react";

type Callback = () => void;

// Taken from here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: Callback, delay: number | null) {
  const savedCallback = React.useRef<Callback>();

  // Remember the latest callback.
  React.useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback]
  );

  // Set up the interval.
  React.useEffect(
    () => {
      function tick() {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        // console.log("Restarting timer");
        return () => clearInterval(id);
      }
    },
    [delay]
  );
}
