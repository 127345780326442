import * as React from "react";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { PanelRow } from "../../../containers/rows/panelRow";
import { DoNotMoveFoldersWarning } from "./doNotMoveFoldersWarning";
import { Paragraph } from "../../../widgets/paragraph";

export const WaitingForManualReviewPanel = () => (
  <>
    <IndefiniteProgressBar error={true}/>
    <PanelRow>
      <Paragraph>
        A technical issue with some of your items has caused the migration to temporarily stop. Our support team is
        already looking into the cause of the issue. Once it is resolved (usually within 24 hours), this message will
        automatically go away, and your migration will continue.
      </Paragraph>
      <DoNotMoveFoldersWarning/>
    </PanelRow>
  </>
);
