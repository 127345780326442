export namespace Constants {
  export namespace Brand {
    export const company = "UnboundedCloud LLC";
    export const name = "VaultMe";
  }

  export namespace Address {
    export const line1 = "113 Cherry St";
    export const line2 = "Suite 1422";
    export const city = "Seattle";
    export const state = "WA";
    export const zip = "98104";
    export const full = "113 Cherry St, Suite 1422, Seattle, WA 98104";
  }

  export namespace Product {
    export const name = "VaultMe";
    export const title = "VaultMe";
    export const marketingWebSiteUrl = "https://www.vaultme.com";
    export const supportEmail = "support@vaultme.com";
    export const eduServicesEmail = "eduservices@vaultme.com";

    export const eduAmbassadorTermsUrl = marketingWebSiteUrl + "/edu-ambassador-terms";
  }

  export const ProgressIndicatorDelay = 1000;
  export const SuccessIndicatorTimeout = 2000;

  export namespace Auth {
    export const IdentificationRole = "Identification";

    export const SendEmailsRole = "SendEmails";
    export const VerifyEmailAddresses = "VerifyEmailAddresses";
  }

  export namespace Jobs {
    export const PollingInterval = 10000;
  }

  export const DefaultTimeEstimate = 5 * 60;
  export const UnreliableTimeEstimateThreshold = 6 * 60 * 60;

  export const MaxBatchSize = 100;

  export namespace OutlookHack {
    export const appTitle = "Outlook";
    export const subject = "Outlook";
  }

  export const NewMigrationsAreNotAllowed = false;

  export const MigrationReportLifetimeDays = 30;

  export const MinSearchTermLength = 2;

  export namespace Paths {
    export const configuration = "/c";
    export const program = "/p";
    export const theme = "/t";
  }

  export namespace QueryParams {
    export const ReferralCode = "ref";
    export const AmbassadorCode = "amb";
  }

  export const DefaultFeedbackQuestion = "What convinced you to use VaultMe today?";

  export namespace AutomatedOffboarding {
    export const MaxGmailEntryCount = 2000;
    export const MaxOutlookEntryCount = 10000;
  }
}
