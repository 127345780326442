import { BlueprintContext } from "../blueprintContext";
import { Comparator } from "../comparator";
import { ComponentVisualization } from "../componentVisualization";
import { ComponentHubs, Component } from "../component";
import { ExactlyOneRelationshipHub } from "../hubs/exactlyOneRelationshipHub";
import { MultipleRelationshipsHub } from "../hubs/multipleRelationshipsHub";
import { ComparatorRegistry } from "../comparatorRegistry";
import { State } from "../state";
import { Set } from "immutable";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { BlueprintException } from "../blueprintException";
import { ComponentBinding } from "./componentBinding";
import { Option, Some } from "../../utils/monads/option";
import { Variant } from "../../types/models/variant";

export class RestrictionComp<T>
  extends Component<RestrictionComp.Props, RestrictionComp.Hubs<T>, RestrictionComp.Output<T>> {

  public get comparator(): Comparator<T> {
    return ComparatorRegistry.get(this.props.comparatorType);
  }

  public stateWhenUnblocked(context: BlueprintContext): State<RestrictionComp.Output<T>> {
    const valueState = this.hubs.values.state(context);
    const thresholdState = this.hubs.threshold.state(context);

    return State.compute<RestrictionComp.Output<T>>(valueState, thresholdState)(
      () => Option.flatten(valueState.output).flatMap((value) =>
        thresholdState.output.map((threshold) => {
          const result = this.comparator.compare(value, threshold);
          return {
            restrictionState: this.props.acceptableResults.contains(result),
            value,
            threshold
          };
        })
      )
    );
  }

  // public messages(context: BlueprintContext): List<string> {
  //   const state = this.state(context);
  //   if (state.type === StateType.Resolved && !state.output.state) {
  //     return List(["Oops! Failed restriction: " + this.props.name]);
  //   } else {
  //     return List();
  //   }
  // }

  public visualization(
    context: BlueprintContext,
    state: State<RestrictionComp.Output<T>>
  ): ComponentVisualization {
    const disabled = state.resolvedOutput.exists((output) => !output.restrictionState);
    const comparatorTitle = this.comparator.title;
    return {
      title: "Restriction",
      summary: this.props.name + (comparatorTitle ? "\n" + this.props.comparatorType : ""),
      icon: disabled ? Images.Blueprint.ExclamationMark : Images.Blueprint.CheckMark,
      color: disabled
        ? (this.props.isCritical ? ComponentColorSchema.SolidRed : ComponentColorSchema.SolidYellow)
        : ComponentColorSchema.SolidGreen,
      sizeMultiplier: disabled && this.props.isCritical ? 1.5 : 1
    };
  }
}

export namespace RestrictionComp {
  export interface Props {
    name: string;
    comparatorType: string;
    acceptableResults: Set<number | undefined>;
    isCritical: boolean;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.RestrictionCompProps): Props {
      if (ComparatorRegistry.keys().indexOf(props.comparatorType) !== -1) {
        return {
          name: props.name,
          comparatorType: props.comparatorType,
          acceptableResults: Set(props.acceptableResults.map((r) => r === null ? undefined : r)),
          isCritical: props.isCritical
        };
      } else {
        throw new BlueprintException("Unknown comparator " + props.comparatorType);
      }
    }
  }

  export interface Hubs<T> extends ComponentHubs {
    values: MultipleRelationshipsHub<T>;
    threshold: ExactlyOneRelationshipHub<T>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.RestrictionCompHubsFragment): Hubs<any> {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        values: binding.multipleRelationshipsHub("values", hubs.values),
        threshold: binding.exactlyOneRelationshipHub("threshold", hubs.threshold),
      };
    }
  }

  export interface Output<T> {
    restrictionState: boolean;
    value: T;
    threshold: T;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.RestrictionCompOutputFragment): Output<any> {
      return {
        restrictionState: output.restrictionState,
        value: Variant.fromGraphQL(output.restrictionCompOutputValue),
        threshold: Variant.fromGraphQL(output.threshold)
      };
    }
  }
}
