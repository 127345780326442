import * as React from "react";
import { useInterval } from "../../utils/useInterval";
import { preciseDurationBetween } from "../../utils/formatting";

interface Props {
  from: Date;
}

export const Stopwatch: React.FunctionComponent<Props> = (props) => {
  const [state, setState] = React.useState(0);

  useInterval(
    () => setState((current) => current + 1),
    1000
  );

  return <span>{preciseDurationBetween(new Date(), props.from)}</span>;
};
