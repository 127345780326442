import * as React from "react";
import * as _ from "lodash";
import * as Color from "color";
import { styled } from "../../app/theme";
import { Network, DataSet, Edge as VisEdge, Node as VisNode } from "vis";
import { RelationshipWithOriginComponent } from "../blueprintComponents";
import { AnyComponent } from "../component";
import { ComponentVisualization } from "../componentVisualization";
import { State } from "../state";
import { Toolbar } from "./toolbar";
import { List, Map, Set } from "immutable";
import { resolveImage } from "../../app/images";
import { stealKeyStrokesFrom } from "../../utils/misc";

export interface Cluster {
  title: string;
  size: number;
  nodeIds: Set<string>;
  onDoubleClick?: () => void;
}

export interface Graph {
  nodes: List<Graph.Node>;
  edges: List<Graph.Edge>;
  clusters: Map<string, Cluster>;
}

export namespace Graph {
  export interface GraphObject {
    id: string;
    title: string;
    elementState: State<any>;
    hidden: boolean;
  }

  export interface Node extends GraphObject {
    progress: number | undefined;
    summary?: string;
    icon: any | undefined;

    backgroundColor: string | undefined;
    borderColor: string;
    enabled: boolean;
    size: number;
    fontSize: number;
  }

  export interface Edge extends GraphObject {
    from: string;
    to: string;
    color?: string;
    width?: number;
    dashes?: boolean | number[];
  }
}

export namespace VisGraph {
  export function makeNode(node: Graph.Node): VisNode {
    const borderColor = ComponentVisualization.finalBorderColor(
      node.borderColor,
      node.elementState,
      node.enabled
    );
    const backgroundColor = ComponentVisualization.finalBackgroundColor(
      node.backgroundColor,
      node.elementState,
      node.enabled
    );

    return {
      id: node.id,
      // hidden: component.hidden,
      label: "*" + node.title + "*" + (node.summary ? ("\n" + node.summary) : ""),
      shape: "dot",
      size: node.size,
      mass: node.size / 5,
      borderWidth: 2,
      borderWidthSelected: 2,
      color: {
        background: backgroundColor,
        border: borderColor,
        highlight: {
          background: backgroundColor,
          border: borderColor
        },
        hover: {
          background: new Color(backgroundColor).darken(0.2).hex(),
          border: new Color(borderColor).darken(0.2).hex()
        }
      },
      font: {
        color: borderColor,
        size: node.fontSize * 0.75,
        strokeWidth: 2,
        strokeColor: "#ffffff",
        background: undefined,
        multi: "markdown",
        bold: {
          size: node.fontSize,
          color: borderColor
        }
      },
      labelHighlightBold: false,
    };
  }

  export function makeEdge(edge: Graph.Edge): VisEdge {
    const enabled = edge.elementState.isEnabled;
    const color = enabled ? (edge.color || "#666666") : "#cccccc";
    return {
      id: edge.id,
      // hidden: edge.hidden,
      label: edge.title,
      from: edge.from,
      to: edge.to,
      color: {
        color,
        highlight: color,
      },
      width: edge.width,
      dashes: edge.dashes,
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 0.5
        }
      },
      font: {
        color,
        size: 8,
        // align: "top"
      },
    };
  }

}

interface GraphViewProps {
  // loading: boolean;
  // onReloadClick: () => void;
  animation: boolean;
  onToggleAnimation: () => void;
  onRenderServerComponents: () => void;
  onRunSandboxCode: () => void;

  graph: Graph;
  selectedComponent?: AnyComponent;
  selectedRelationship?: RelationshipWithOriginComponent;
  onComponentSelect: (componentId: string) => void;
  onEdgeSelect: (edgeId: string) => void;
  onClearSelection: () => void;
}

const Container = styled.div`
  height: 100%;
  position: relative;
  
  > .vis-graph {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

interface ClickEvent {
  nodes: string[];
  edges: string[];
}

export class GraphView extends React.Component<GraphViewProps> {
  private readonly visContainerRef: React.RefObject<any>;
  private network?: Network;
  private readonly visNodes: DataSet<VisNode>;
  private readonly visEdges: DataSet<VisEdge>;
  private ignoreNextComponentSelect?: boolean;
  private currentRadius: number = 0;
  private timer?: number;
  private images = Map<string, HTMLImageElement>();

  constructor(props: GraphViewProps) {
    super(props);

    this.visContainerRef = React.createRef();

    this.visNodes = new DataSet<VisNode>();
    this.visEdges = new DataSet<VisEdge>();

    this.handleNetworkClick = this.handleNetworkClick.bind(this);
    this.handleNetworkDoubleClick = this.handleNetworkDoubleClick.bind(this);
    this.handleBeforeDrawingNetwork = this.handleBeforeDrawingNetwork.bind(this);
    this.handleAfterDrawingNetwork = this.handleAfterDrawingNetwork.bind(this);
    this.handleFitButtonClick = this.handleFitButtonClick.bind(this);
    this.updateFrameTimer = this.updateFrameTimer.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public componentDidMount() {
    const data = {
      nodes: this.visNodes,
      edges: this.visEdges
    };

    this.visNodes.add(this.props.graph.nodes.map(VisGraph.makeNode).toArray());
    this.visEdges.add(this.props.graph.edges.map(VisGraph.makeEdge).toArray());

    this.network = new Network(this.visContainerRef.current, data, { interaction: { hover: true } });
    this.network.on("click", this.handleNetworkClick);
    this.network.on("doubleClick", this.handleNetworkDoubleClick);
    this.network.on("beforeDrawing", this.handleBeforeDrawingNetwork);
    this.network.on("afterDrawing", this.handleAfterDrawingNetwork);

    this.createClusters(this.props.graph.clusters, Map());

    this.initAnimation();

    document.addEventListener("keydown", this.handleKeyDown);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  public componentDidUpdate(prevProps: GraphViewProps) {
    if (prevProps.graph !== this.props.graph) {
      const updatedNodes = this.props.graph.nodes.filter((updatedNode) => {
        const existingNode = prevProps.graph.nodes.find((node) => node.id === updatedNode.id);
        return !existingNode || !_.isEqual(updatedNode, existingNode);
      });
      if (!updatedNodes.isEmpty()) {
        this.visNodes.update(updatedNodes.map(VisGraph.makeNode).toArray());
      }
      const updatedEdges = this.props.graph.edges.filter((updatedEdge) => {
        const existingEdge = prevProps.graph.edges.find((edge) => edge.id === updatedEdge.id);
        return !existingEdge || !_.isEqual(updatedEdge, existingEdge);
      });
      if (!updatedEdges.isEmpty()) {
        this.visEdges.update(updatedEdges.map(VisGraph.makeEdge).toArray());
      }

      // this.visNodes.update(this.props.graph.nodes.map(VisGraph.makeNode));
      // this.visEdges.update(this.props.graph.edges.map(VisGraph.makeEdge));

      const nodeIds = this.props.graph.nodes.map((node) => node.id);
      const prevNodeIds = prevProps.graph.nodes.map((node) => node.id);
      this.visNodes.remove(prevNodeIds.filter((nodeId) => nodeIds.indexOf(nodeId) === -1).toArray());

      const edgeIds = this.props.graph.edges.map((edge) => edge.id);
      const prevEdgeIds = prevProps.graph.edges.map((edge) => edge.id);
      this.visEdges.remove(prevEdgeIds.filter((edgeId) => edgeIds.indexOf(edgeId) === -1).toArray());

      this.createClusters(this.props.graph.clusters, prevProps.graph.clusters);
    }

    const selectedNode = this.props.selectedComponent;
    if (selectedNode && prevProps.selectedComponent !== this.props.selectedComponent && this.network) {
      if (!this.ignoreNextComponentSelect) {
        if (this.props.graph.nodes.find((node) => node.id === selectedNode.id)) {
          this.network.selectNodes([selectedNode.id]);
          this.network.fit({
            nodes: [selectedNode.id],
            animation: {
              duration: 100,
              easingFunction: "easeInQuad"
            }
          });
        } else {
          this.network.unselectAll();
        }
      } else {
        this.ignoreNextComponentSelect = false;
      }
    }

    if (prevProps.animation !== this.props.animation) {
      this.initAnimation();
    }
  }

  public render() {
    return (
      <Container>
        <div className="vis-graph" ref={this.visContainerRef}/>
        <Toolbar
          // loading={this.props.loading}
          animation={this.props.animation}
          // onReloadButtonClick={this.props.onReloadClick}
          onFitButtonClick={this.handleFitButtonClick}
          onAnimateButtonClick={this.props.onToggleAnimation}
        />
      </Container>
    );
  }

  protected initAnimation() {
    if (this.props.animation) {
      if (!this.timer) {
        this.timer = window.setInterval(this.updateFrameTimer, 100);
      }
    } else {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = 0;
      }
    }
  }

  protected handleNetworkClick(event: ClickEvent) {
    if (event.nodes.length === 1) {
      this.ignoreNextComponentSelect = true;
      this.props.onComponentSelect(event.nodes[0]);
    } else if (event.edges.length === 1) {
      this.props.onEdgeSelect(event.edges[0]);
    } else {
      this.props.onClearSelection();
    }
  }

  protected handleNetworkDoubleClick(event: ClickEvent) {
    if (event.nodes.length === 1) {
      const cluster = this.props.graph.clusters.get(event.nodes[0]);
      if (cluster && cluster.onDoubleClick) {
        cluster.onDoubleClick();
      }
    }
  }

  protected handleFitButtonClick() {
    if (this.network) {
      this.network.fit();
    }
  }

  protected handleBeforeDrawingNetwork(ctx: any) {
    function circleColor(state: State<any>): string {
      if (state.isAlmostResolved) {
        return "rgba(0, 150, 0, 0.3)";
      } else if (state.isResolving) {
        return "rgba(0, 121, 174, 0.3)";
      } else if (state.isPreparing) {
        return "rgba(0, 121, 174, 0.2)";
      } else if (state.isError) {
        return "rgba(192, 14, 14, 0.3)";
      } else {
        return "rgba(127, 127, 127, 0.1)";
      }
    }

    function progressColor(state: State<any>): string {
      if (state.isAlmostResolved) {
        return "rgba(0, 150, 0, 0.5)";
      } else {
        return "rgba(0, 121, 174, 0.5)";
      }
    }

    const arcOffset = Math.PI / 2;
    const outline = 15;

    if (this.network) {
      const nodePositions = this.network.getPositions();
      const selectedNodes = this.network.getSelectedNodes();

      this.props.graph.nodes.forEach((node) => {
        const nodePosition = nodePositions[node.id];

        if (nodePosition) {
          const cx = nodePosition.x;
          const cy = nodePosition.y;

          if (selectedNodes.indexOf(node.id) !== -1) {
            const enabled = node.elementState.isEnabled;
            ctx.fillStyle = node.elementState.isError
              ? "rgba(192, 14, 14)"
              : (enabled ? Color(node.borderColor).lighten(.5).hex() : "#888888");
            ctx.circle(cx, cy, node.size + 15);
            ctx.fill();
          }

          if (!node.elementState.isBlocked && !node.elementState.isResolved) {
            const speedMultiplier = node.elementState.isPending ? 0.2 : 1;
            const radiusMultiplier = this.props.animation ? Math.sin(this.currentRadius * speedMultiplier) : 1;
            const radius = Math.abs((node.size + outline) * radiusMultiplier);

            ctx.fillStyle = circleColor(node.elementState);
            ctx.circle(cx, cy, radius);
            ctx.fill();

            if (node.progress) {
              ctx.beginPath();
              ctx.moveTo(cx, cy);
              ctx.fillStyle = progressColor(node.elementState);
              ctx.arc(cx, cy, radius, -arcOffset, node.progress / 100 * 2 * Math.PI - arcOffset);
              ctx.lineTo(cx, cy);
              ctx.closePath();
              ctx.fill();
            }
          }
        }
      });
    }
  }

  protected getImage(src: string): HTMLImageElement | undefined {
    const resolvedSrc = resolveImage(src);
    if (resolvedSrc) {
      const existingImage = this.images.get(resolvedSrc);
      if (existingImage) {
        return existingImage;
      } else {
        const image = new Image();
        image.onload = () => this.network && this.network.redraw();
        image.src = resolvedSrc;
        this.images = this.images.set(resolvedSrc, image);
        return image;
      }
    }
  }

  protected handleAfterDrawingNetwork(ctx: CanvasRenderingContext2D) {
    if (this.network) {
      const nodePositions = this.network.getPositions();

      this.props.graph.nodes.forEach((node) => {
        const nodePosition = nodePositions[node.id];

        if (nodePosition) {
          const cx = nodePosition.x;
          const cy = nodePosition.y;
          const size = node.size * 1.25;

          if (node.icon) {
            const image = this.getImage(node.icon);
            if (image) {
              if (node.backgroundColor || node.elementState.isEnabled) {
                ctx.drawImage(image, cx - size / 2, cy - size / 2, size, size);
              } else {
                ctx.save();
                ctx.globalAlpha = 0.3;
                ctx.drawImage(image, cx - size / 2, cy - size / 2, size, size);
                ctx.restore();
              }
            }
          }
        }
      });
    }
  }

  protected updateFrameTimer() {
    if (this.network) {
      this.network.redraw();
      this.currentRadius += 0.5;
    }
  }

  protected createClusters(clusters: Map<string, Cluster>, prevClusters: Map<string, Cluster>): void {
    const network = this.network;
    if (network) {
      const newClusters = clusters.keySeq().toSet().subtract(prevClusters.keySeq());
      const removedClusters = prevClusters.keySeq().toSet().subtract(clusters.keySeq());
      const updatedClusters = prevClusters
        .deleteAll(removedClusters)
        .filter((prevCluster, id) => {
          const newCluster = clusters.get(id);
          return !newCluster || !newCluster.nodeIds.equals(prevCluster.nodeIds);
        })
        .keySeq()
        .toSet();
      const unchangedClusters = clusters.keySeq().toSet().subtract(updatedClusters).subtract(newClusters);

      removedClusters.union(updatedClusters).forEach((id) => {
        if (network.isCluster(id)) {
          network.openCluster(id, {
            releaseFunction: (clusterPosition, containedNodesPositions) => containedNodesPositions
          });
        }
      });

      const sizes = clusters.valueSeq().map((cluster) => cluster.size);
      const minSize = sizes.min() || 0;
      const sizeRange = (sizes.max() || 0) - minSize;

      function calcNodeSize(cluster: Cluster): number {
        const clusterNodeMinSize = 10;
        const clusterNodeSizeRange = 10;
        return clusterNodeMinSize + (
          sizeRange > 0
            ? clusterNodeSizeRange * (cluster.size - minSize) / sizeRange
            : clusterNodeSizeRange
        );
      }

      newClusters.union(updatedClusters).forEach((id) => {
        const cluster = clusters.get(id);
        if (cluster) {
          const nodeSize = calcNodeSize(cluster);
          network.cluster({
            joinCondition: (nodeOptions: any) => cluster.nodeIds.has(nodeOptions.id),
            processProperties: (clusterOptions) => ({ ...clusterOptions, id }),
            clusterNodeProperties: {
              allowSingleNodeCluster: true,
              label: cluster.title,
              shape: "box",
              size: nodeSize,
              mass: 1,
              borderWidth: 0,
              borderWidthSelected: 0,
              color: {
                background: "#888888",
                border: "#888888",
                highlight: {
                  background: "#888888",
                  border: "#888888"
                },
                hover: {
                  background: "#a0a0a0",
                  border: "#a0a0a0"
                },
              },
              font: {
                color: "#ffffff",
                size: nodeSize,
              },
              labelHighlightBold: false,
            } as any // Required to use allowSingleNodeCluster
          });
        }
      });

      unchangedClusters.forEach((id) => {
        const cluster = clusters.get(id);
        if (cluster) {
          const nodeSize = calcNodeSize(cluster);
          if (network.isCluster(id)) {
            // Network supposed to have updateClusteredNode() method, but it's only available in undocumented
            // "clustering" property :-\ Is this because the version is tool old?
            (network as any).clustering.updateClusteredNode(id, {
              size: nodeSize,
              font: {
                size: nodeSize,
              }
            });
          }
        }
      });
    }
  }

  protected handleKeyDown(event: KeyboardEvent): void {
    if (stealKeyStrokesFrom(event.target as Element)) {
      switch (event.key.toLowerCase()) {
        case "r":
          this.props.onRenderServerComponents();
          return;
        case "s":
          this.props.onRunSandboxCode();
          return;
      }
    }
  }
}
