import * as React from "react";
import { Grid } from "../../widgets/grid";
import { preciseTimestamp } from "../../../utils/formatting";
import { List } from "immutable";
import { Images } from "../../../app/images";
import { TaskIssue } from "../../../types/models/taskIssue";
import { TaskIssueEventRow } from "./taskIssueEventRow";
import { TaskHistory } from "../../../types/models/taskHistory";
import { TaskHistoryRecord } from "../../../types/models/taskHistoryRecord";
import { JobHistoryGridIcon, JobHistoryGridSubject, taskResultTypeIcon } from "./utils";
import { InternalJobStatus } from "../../../types/models/internalJobStatus";
import { WidgetStatus } from "../../utils/widgetStatus";

function taskHistoryRecordIcon(event: TaskHistoryRecord): any {
  const resultType = event.resultType;
  if (resultType) {
    return taskResultTypeIcon(resultType);
  } else if (event.internalStatus === InternalJobStatus.Created)  {
    return Images.Jobs.TaskStarted;
  } else {
    return Images.Jobs.TaskContinued;
  }
}

interface TaskEventRowProps {
  event: TaskHistoryRecord;
  prevEvent: TaskHistoryRecord | undefined;
  flash: boolean;
}

const TaskEventRow: React.FunctionComponent<TaskEventRowProps> = (props) => (
  <>
    <Grid.Cell flash={props.flash}>{preciseTimestamp(props.event.updatedAt)}</Grid.Cell>
    <Grid.Cell flash={props.flash}>
      <JobHistoryGridSubject>
        <JobHistoryGridIcon src={taskHistoryRecordIcon(props.event)}/>
        <div>Task "{props.event.taskId}"</div>
      </JobHistoryGridSubject>
    </Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.transition}</Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.updateSummary || "--"}</Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.updatedBy || "--"}</Grid.Cell>
    <Grid.Cell
      status={
        props.prevEvent && props.prevEvent.internalStatus !== props.event.internalStatus
          ? WidgetStatus.Info
          : undefined
      }
      flash={props.flash}
    >
      {props.event.internalStatus}
    </Grid.Cell>
    <Grid.JsonCell
      version={props.event.propsVersion}
      status={props.prevEvent && props.prevEvent.propsJson !== props.event.propsJson ? WidgetStatus.Info : undefined}
      flash={props.flash}
    >
      {props.event.propsJson}
    </Grid.JsonCell>
    <Grid.JsonCell
      version={props.event.stateVersion}
      status={props.prevEvent && props.prevEvent.stateJson !== props.event.stateJson ? WidgetStatus.Info : undefined}
      flash={props.flash}
    >
      {props.event.stateJson}
    </Grid.JsonCell>
    <Grid.JsonCell
      version={props.event.resultVersion}
      status={props.prevEvent && props.prevEvent.resultJson !== props.event.resultJson ? WidgetStatus.Info : undefined}
      flash={props.flash}
    >
      {props.event.resultJson}
    </Grid.JsonCell>
  </>
);

interface Props {
  events: List<TaskHistory.Event>;

  showTaskEvents: boolean;
  showPendingIssues: boolean;
  showResolvedIssues: boolean;
}

export const TaskHistoryEventsGrid: React.FunctionComponent<Props> = (props) => {
  const highlightNewRows = React.useRef(props.events.size);

  React.useEffect(() => {
    highlightNewRows.current = props.events.size;
  });
  const newRows = props.events.size - highlightNewRows.current;

  return (
    <Grid>
      <Grid.Header>
        <Grid.Column>Timestamp</Grid.Column>
        <Grid.Column>Subject</Grid.Column>
        <Grid.Column>Transition</Grid.Column>
        <Grid.Column>Update Summary</Grid.Column>
        <Grid.Column>Updated By</Grid.Column>
        <Grid.Column>System Status</Grid.Column>
        <Grid.Column>Props</Grid.Column>
        <Grid.Column>State</Grid.Column>
        <Grid.Column>Result</Grid.Column>
      </Grid.Header>
      <Grid.Body>
        {
          props.events.map((event, index) => (
            <Grid.Row key={event.key}>
              {
                event instanceof TaskHistoryRecord && props.showTaskEvents && (
                  <TaskEventRow
                    event={event}
                    prevEvent={
                      props.events
                        .takeLast(props.events.size - index - 1)
                        .find((prevEvent) => prevEvent instanceof TaskHistoryRecord, undefined) as
                        (TaskHistoryRecord | undefined)
                    }
                    flash={index < newRows}
                  />
                )
              }
              {
                event instanceof TaskIssue.Event &&
                (
                  props.showPendingIssues && event.issueIsPending ||
                  props.showResolvedIssues && !event.issueIsPending
                ) &&
                (
                  <TaskIssueEventRow
                    event={event}
                    flash={index < newRows}
                    columnCount={9}
                  />
                )
              }
            </Grid.Row>
          ))
        }
      </Grid.Body>
    </Grid>
  );
};
