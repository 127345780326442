import { AppAction } from "./appAction";
import { AppState, initialAppState } from "./appState";
import { reduceSession } from "./session/reducers";
import { reduceSettings } from "./settings/reducers";
import { reduceProtoState } from "./protoState/reducers";
import { reduceTours } from "./tours/reducers";

export function appReducer(appState: AppState | undefined, action: AppAction): AppState {
  const ensuredAppState = appState || initialAppState;

  return {
    protoState: reduceProtoState(ensuredAppState.protoState, action),
    session: reduceSession(ensuredAppState.session, action),
    settings: reduceSettings(ensuredAppState.settings, action),
    tours: reduceTours(ensuredAppState.tours, action)
  };
}
