import { RoutesContext } from "./routesContext";

export class OptOutRoutes {
  constructor(private context: RoutesContext) {}

  public params(): OptOutRoutes.OptOutParams {
    const offboardingProjectEntryId = this.context.routeParams[OptOutRoutes.Params.offboardingProjectEntryId];
    if (offboardingProjectEntryId) {
      return { offboardingProjectEntryId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }
}

export namespace OptOutRoutes {
  export namespace Params {
    export const offboardingProjectEntryId: string = "offboardingProjectEntryId";
  }

  export const optOutRoot = "opt-out";
  export const optOutMask = optOutRoot + "/:" + Params.offboardingProjectEntryId;

  export interface OptOutParams {
    offboardingProjectEntryId: string;
  }
}
