import * as React from "react";
import { ModalController, ModalHook } from "../layouts/modal";
import { SearchPanel } from "./crud/searchPanel";
import { ColumnConfig } from "./crud/columnConfig";
import { OperationStatus } from "../../types/operationStatus";
import { List } from "immutable";

export interface LookupViewProps<Id, Item> {
  getItemId: (item: Item) => Id;
  comparator: (a: Item, b: Item) => number;

  searchTerm: string;
  searchStatus: OperationStatus<List<Item>>;

  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
}

export interface LookupControllerProps<Id, Item> {
  render: (viewProps: LookupViewProps<Id, Item>) => React.ReactElement;
}

export type LookupControllerType<Id, Item> = React.ComponentType<LookupControllerProps<Id, Item>>;

interface LookupModalProps<Id, Item> {
  subject: string;
  gridColumns: ColumnConfig<Item>[];

  modal: ModalHook;
  controller: LookupControllerType<Id, Item>;
  onSelect: (id: Id, item: Item) => void;
}

export function LookupModal<Id, Item>(props: LookupModalProps<Id, Item>): React.ReactElement {
  return (
    <ModalController
      modal={props.modal}
      render={(close) => React.createElement(props.controller, {
        render: (viewProps) => (
          <SearchPanel
            subject={props.subject}
            gridColumns={props.gridColumns}

            getItemId={viewProps.getItemId}
            comparator={viewProps.comparator}

            searchTerm={viewProps.searchTerm}
            searchStatus={viewProps.searchStatus}

            onSearch={viewProps.onSearch}
            onRefresh={viewProps.onRefresh}
            onSelect={(id, item) => {
              close();
              props.onSelect(id, item);
            }}
          />
        )
      })}
    />
  );
}
