import * as React from "react";
import { styled } from "../../app/theme";
import { Constants } from "../../app/constants";
import { EmailSupportLink } from "../widgets/emailSupportLink";

const StyledFooter = styled.footer`
  padding: 1rem;
  font-weight: ${(props) => props.theme.font.light};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
`;

const Row = styled.div`
  margin-top: 0.3rem;
  font-size: 0.7rem;
  color: ${(props) => props.theme.colors.gray};
  display: flex;
  justify-content: center;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    justify-content: flex-start;
  }
`;

const LinkWrapper = styled.div`
  padding: 0 1.2rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0 2rem 0 0;
  }
`;

export const AppFooter: React.FunctionComponent = () => (
  <StyledFooter>
    <Row>
      <LinkWrapper>
        <a href={Constants.Product.marketingWebSiteUrl + "/terms"} rel="nofollow" target="_blank">
          Terms
        </a>
      </LinkWrapper>
      <LinkWrapper>
        <a href={Constants.Product.marketingWebSiteUrl + "/privacy"} rel="nofollow" target="_blank">
          Privacy
        </a>
      </LinkWrapper>
      <LinkWrapper>
        <a href={Constants.Product.marketingWebSiteUrl + "/support"} target="_blank">
          Support
        </a>
      </LinkWrapper>
      <LinkWrapper>
        <EmailSupportLink>Contact Us</EmailSupportLink>
      </LinkWrapper>
    </Row>
    <Row>
      &copy; 2015-{new Date().getFullYear()} Unbounded Cloud LLC, All Rights Reserved
    </Row>
    <Row>
      113 Cherry St, Suite 1422, Seattle, WA 98104
    </Row>
  </StyledFooter>
);
