import * as React from "react";
import { UserFacingError } from "../../types/userFacingError";
import { OperationStatus, useOperationStatus } from "../../types/operationStatus";
import { MutationHookOptions, MutationTuple } from "@apollo/react-hooks";
import { MutationFunctionOptions } from "@apollo/react-common";
import { ErrorHandler } from "./errorHandler";

interface ExecuteMutationFunctionOptions<MutationData, Vars, Extract, Result>
  extends MutationFunctionOptions<MutationData, Vars> {
  retry?: () => void;
  cancel?: true | (() => void);
  retrying?: boolean;
  catch?: ErrorHandler<Result>[];
}

interface ManagedMutationHookOptions<MutationData, Vars, Extract, Result>
  extends ExecuteMutationFunctionOptions<MutationData, Vars, Extract, Result> {
  mutation: (baseOptions?: MutationHookOptions<MutationData, Vars>) => MutationTuple<MutationData, Vars>;
  extract: (result: MutationData) => Extract | undefined;
  complete: (extract: Extract) => Result;
}

type ExecuteMutationFunction<MutationData, Vars, Extract, Result> =
  (options: ExecuteMutationFunctionOptions<MutationData, Vars, Extract, Result>) => Promise<Result>;

interface MutationExecutorStatus<MutationData, Vars, Extract, Result> {
  status: OperationStatus<Result>;
  reset: () => void;
  lastResult: Result | undefined;
}

type MutationExecutorTuple<MutationData, Vars, Extract, Result> = [
  ExecuteMutationFunction<MutationData, Vars, Extract, Result>,
  MutationExecutorStatus<MutationData, Vars, Extract, Result>
  ];

export function useManagedMutation<MutationData, Vars, Extract, Result>(
  options: ManagedMutationHookOptions<MutationData, Vars, Extract, Result>
): MutationExecutorTuple<MutationData, Vars, Extract, Result> {
  const status = useOperationStatus<Result>();
  const lastResult = React.useRef<Result>();
  const { mutation, ...mutationBaseOptions } = options;
  const [mutationTrigger] = options.mutation(mutationBaseOptions);

  return [
    (execOptions) => {
      const mergedExecuteOptions: ExecuteMutationFunctionOptions<MutationData, Vars, Extract, Result> = {
        retry: execOptions.retry || options.retry,
        cancel: execOptions.cancel || options.cancel,
        retrying: execOptions.retrying || options.retrying,
        catch: execOptions.catch || options.catch
          ? (execOptions.catch || []).concat(options.catch || [])
          : undefined
      };

      status.setWorking({ retrying: mergedExecuteOptions.retrying });
      return mutationTrigger(execOptions)
        .then((mutationResult) => {
          if (mutationResult.data) {
            const data = options.extract(mutationResult.data);
            if (data !== undefined) {
              const result = options.complete(data);
              lastResult.current = result;
              status.setSuccess(result);
              return result;
            }
          }
          throw Error("Server did not return required data");
        })
        .catch((error) => {
          const cancel = options.cancel
            ? () => {
              status.setPending();
              if (typeof options.cancel === "function") {
                options.cancel();
              }
            }
            : undefined;
          const retry = mergedExecuteOptions.retry;

          if (mergedExecuteOptions.catch) {
            const errorOrResult = ErrorHandler.handleError(error, mergedExecuteOptions.catch);
            if (errorOrResult === undefined) {
              status.setFailure(UserFacingError.unexpected(error, { retry, cancel }));
              throw error;
            } else if (errorOrResult instanceof UserFacingError) {
              const enrichedError = errorOrResult.enrich({ retry, cancel });
              status.setFailure(enrichedError);
              throw enrichedError;
            } else {
              status.setSuccess(errorOrResult);
              return errorOrResult;
            }
          } else {
            status.setFailure(UserFacingError.unexpected(error, { retry, cancel }));
            throw error;
          }
        });
    },
    {
      status: status.current,
      reset: () => status.setPending(),
      lastResult: lastResult.current
    }
  ];
}
