import { ComponentHubs } from "../component";
import { FactCompLike } from "./factCompLike";
import { Collectable } from "../../types/collectables/collectable";
import { ComponentBinding } from "./componentBinding";
import { GraphQL } from "../../services/graphql/generated";
import { FactRef } from "../../types/facts/factRef";
import { AssociationsHub } from "../hubs/associationsHub";

export class FactComp<P, C, T extends Collectable<P, C>>
  extends FactCompLike<FactComp.Props<P, C, T>, FactComp.Hubs, P, C, T> {
}

export namespace FactComp {
  export type Props<P, C, T extends Collectable<P, C>> = FactCompLike.PropsLike<P, C, T>;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.FactCompProps): Props<any, any, any> {
      return {
        name: props.name,
        factRef: FactRef.Props.fromGraphQL(props.factRef)
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    phase: AssociationsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.FactCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        phase: binding.associationsHub("phase", hubs.phase)
      };
    }
  }
}
