import * as React from "react";
import { styled } from "../../app/theme";

export const DrawerBlock = styled.div`
  margin: 1rem 0;
  
  &:first-of-type {
    margin-top: 0;
  }
  
  &:last-of-type {
    margin-bottom: 0;
  }
`;
