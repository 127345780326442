import { CRUDRoutes } from "./crudRoutes";
import { PricingModel } from "../../types/models/pricingModel";

export class PricingModelsRoutes extends CRUDRoutes<number, PricingModel> {
  protected readonly root: string = "pricing-models";

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}
