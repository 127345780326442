import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { FormErrorMessage } from "./formErrorMessage";
import { FormikProps } from "formik/dist/types";
import { DropDownBox } from "./dropDownBox";

interface DropDownField<T> {
  label: string;
  name: keyof T;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxWidth?: number;
  onChange?: (event: React.ChangeEvent<any>, form: FormikProps<T>) => void;
  children: React.ReactNode;
}

// tslint:disable-next-line function-name
export function DropDownField<V>(props: DropDownField<V>) {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];
        return (
          <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
            <DropDownBox
              {...field.field}
              error={error}
              readOnly={props.readOnly}
              disabled={props.disabled}
              maxWidth={props.maxWidth}
              onChange={(event) => {
                field.form.setFieldError(event.target.name, "");
                field.field.onChange(event);
              }}
            >
              {props.children}
            </DropDownBox>
            <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
          </FormField>
        );
      }}
    />
  );
}
