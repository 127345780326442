import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { EmailDeliveryScheduleTimeUnit } from "../enums/emailDeliveryScheduleTimeUnit";
import { EmailDeliveryErrorType } from "../enums/emailDeliveryErrorType";
import { CloudServices } from "./cloudServices";
import { Constants } from "../../app/constants";

export interface OffboardingProject {
  readonly id: string;

  readonly name: string;
  readonly notes: string | undefined;
  readonly connection: OffboardingProject.Connection | undefined;

  readonly program: OffboardingProject.Program | undefined;

  readonly cutoffAt: Date;
  readonly scheduleId: string;

  readonly from: string | undefined;
  readonly subject1: string;
  readonly body1: string;
  readonly subject2: string | undefined;
  readonly body2: string | undefined;
  readonly subject3: string | undefined;
  readonly body3: string | undefined;
  readonly subject4: string | undefined;
  readonly body4: string | undefined;

  readonly currentRound: number | undefined;
  readonly nextRound: number | undefined;

  readonly currentRoundTotalEmails: number | undefined;
  readonly currentRoundSentEmails: number | undefined;

  readonly emailDeliveryError: OffboardingProject.EmailDeliveryError | undefined;

  readonly currentUpdateId: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly jobId: string;
  readonly isEmailSendingInProgress: boolean;
  readonly isCompleted: boolean;
  readonly schedule: OffboardingProject.Schedule;
}

export namespace OffboardingProject {
  export interface Connection {
    readonly id: string;
    readonly cloudServiceId: string;
    readonly description: string | undefined;

    readonly maxEntryCount: number | undefined;
  }

  export interface Program {
    readonly id: string;
    readonly name: string;
  }

  export interface EmailDeliveryError {
    readonly type: EmailDeliveryErrorType;
    readonly details: string;
  }

  export namespace EmailDeliveryError {
    export function parseEmailDeliveryError(data: GraphQL.OffboardingProject_EmailDeliveryError): EmailDeliveryError {
      return data;
    }
  }

  function maxEntryCount(cloudServiceId: string): number | undefined {
    switch (cloudServiceId) {
      case CloudServices.GSuite: return Constants.AutomatedOffboarding.MaxGmailEntryCount;
      case CloudServices.Office365: return Constants.AutomatedOffboarding.MaxOutlookEntryCount;
    }
  }

  export function parse(data: GraphQL.OffboardingProjectFragment): OffboardingProject {
    return {
      id: data.id,

      name: data.name,
      notes: nullToUndefined(data.notes),
      connection: mapOptional(data.connection, (connection) => ({
        id: connection.id,
        cloudServiceId: connection.cloudServiceId,
        description: nullToUndefined(connection.description),
        maxEntryCount: maxEntryCount(connection.cloudServiceId)
      })),

      program: mapOptional(data.program, (program) => ({
        id: program.id,
        name: program.name
      })),

      cutoffAt: new Date(data.cutoffAt),
      scheduleId: data.scheduleId,

      from: nullToUndefined(data.from),
      subject1: data.subject1,
      body1: data.body1,
      subject2: nullToUndefined(data.subject2),
      body2: nullToUndefined(data.body2),
      subject3: nullToUndefined(data.subject3),
      body3: nullToUndefined(data.body3),
      subject4: nullToUndefined(data.subject4),
      body4: nullToUndefined(data.body4),

      currentRound: nullToUndefined(data.currentRound),
      nextRound: nullToUndefined(data.nextRound),

      currentRoundTotalEmails: nullToUndefined(data.currentRoundTotalEmails),
      currentRoundSentEmails: nullToUndefined(data.currentRoundSentEmails),

      emailDeliveryError: mapOptional(data.emailDeliveryError, EmailDeliveryError.parseEmailDeliveryError),

      currentUpdateId: nullToUndefined(data.currentUpdateId),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),

      jobId: data.jobId,
      isEmailSendingInProgress: data.isEmailSendingInProgress,
      isCompleted: data.isCompleted,
      schedule: Schedule.parseSchedule(data.schedule),
    };
  }

  export interface Schedule {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly timeUnit: EmailDeliveryScheduleTimeUnit;
    readonly rounds: List<Round>;

    readonly friendlyTimeUnit: string;
  }

  export namespace Schedule {
    export function parseSchedule(data: GraphQL.AppliedEmailDeliverySchedule): Schedule {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        timeUnit: data.timeUnit,
        rounds: List(data.rounds).map(Round.parseRound),

        friendlyTimeUnit: data.timeUnit.toLowerCase()
      };
    }
  }

  export interface Round {
    readonly timeUnits: number;
    readonly scheduledFor: Date;
    readonly template: number;
    readonly subject: string;
    readonly body: string;
  }

  export namespace Round {
    export function parseRound(data: GraphQL.AppliedEmailDeliverySchedule_Round): Round {
      return {
        timeUnits: data.timeUnits,
        scheduledFor: new Date(data.scheduledFor),
        template: data.template,
        subject: "",
        body: ""
      };
    }
  }

  export function cacheId(offboardingProjectId: string): string {
    return cachedObjectId("OffboardingProject", offboardingProjectId);
  }

  export const fragmentName = "OffboardingProject";
}
