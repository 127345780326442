import { GraphQL } from "../../services/graphql/generated";
import { DiscountType } from "./discountType";

export interface ReferralCodeDiscount {
  readonly code: string;
  readonly percentage: number;
  readonly discountType: DiscountType;
  readonly amount: number;
}

export namespace ReferralCodeDiscount {
  export function parse(data: GraphQL.ReferralCodeDiscountFragment): ReferralCodeDiscount {
    return data;
  }
}
