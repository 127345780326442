import * as React from "react";
import { styled } from "../../app/theme";

export const SubSection = styled.div`
  & ~ & {
    border-top: 1px solid ${(props) => props.theme.colors.lighterGray};
    margin-top: .5rem;
    padding-top: .5rem;
  }
`;
