import * as React from "react";
import { Set } from "immutable";
import { Connection } from "../../types/models/connection";
import { AccessKeyRevealToolView } from "../../views/blocks/connectionManagement/accessKeyRevealToolView";
import { useAccessKeyReveal } from "./useAccessKeyReveal";
import { AuthProvider } from "../../types/models/authProvider";

interface AccessKeyRevealToolProps {
  connection: Connection;
  authProvider: AuthProvider;
}

export const AccessKeyRevealTool: React.FunctionComponent<AccessKeyRevealToolProps> = (props) => {
  const [roles, setRoles] = React.useState(props.connection.roles || Set());
  const [reveal, status, lastResult] = useAccessKeyReveal(props.connection.id, roles);

  return (
    <AccessKeyRevealToolView
      connection={props.connection}
      authProvider={props.authProvider}

      roles={roles}
      onRolesSelect={setRoles}

      reveal={reveal}
      status={status}
      lastResult={lastResult}
    />
  );
};
