import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface MigrationStats {
  readonly totalBytes: number | undefined;
  readonly totalItems: number | undefined;
  readonly migratedBytes: number | undefined;
  readonly migratedItems: number | undefined;
  readonly skippedBytes: number | undefined;
  readonly skippedItems: number | undefined;

  readonly progress: number;
  readonly skippedPercentage: number;
}

export namespace MigrationStats {
  export function parse(data: GraphQL.MigrationStats, isCompleted: boolean): MigrationStats {
    const totalItems = nullToUndefined(data.totalItems);
    const migratedItems = nullToUndefined(data.migratedItems);
    const skippedItems = nullToUndefined(data.skippedItems);

    function progress(): number {
      return totalItems
        ? Math.min(100, ((migratedItems || 0) + (skippedItems || 0)) / totalItems * 100)
        : (isCompleted ? 100 : 0);
    }

    function skippedPercentage(): number {
      return totalItems ? Math.min(100, (skippedItems || 0) / totalItems * 100) : 0;
    }

    return {
      totalBytes: nullToUndefined(data.totalBytes),
      totalItems,
      migratedBytes: nullToUndefined(data.migratedBytes),
      migratedItems,
      skippedBytes: nullToUndefined(data.skippedBytes),
      skippedItems,

      progress: progress(),
      skippedPercentage: skippedPercentage()
    };
  }
}
