import * as React from "react";
import { MigrationStatusPageView } from "../../views/screens/migrationStatusPageView";
import { useAuthProviders, useCloudServices, useProgramAlias } from "../../app/configuration";
import { Map } from "immutable";
import { WorkStatus } from "../../views/models/workStatus";
import { CenteredContent } from "../../views/layouts/centeredContent";
import { OperationStatusIndicator } from "../../views/utils/operationStatusIndicator";
import { StatusIndicators } from "../../views/utils/statusIndicators";
import { resolveImage } from "../../app/images";
import { useMigrationProgressQuery } from "../../queries/useMigrationProgressQuery";
import { useWatcher, useWatcherDispatch } from "../../services/watcher/useWatcher";
import { WatchedMigrations } from "../../services/watcher/plugins/watchedMigrationsPlugin";
import { WatchedFacts } from "../../services/watcher/plugins/watchedFactsPlugin";
import { MigrationStatus } from "../../types/models/migrationStatus";
import { MigrationStatusPageState } from "../../views/screens/migrationStatusPageView/migrationStatusPageState";
import { ItemsByType } from "../../views/models/itemsByType";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { useDrawer } from "../../views/layouts/drawer";
import { renderMigrationActionItems } from "../../components/migrationIssueHandler";
import { Tracking } from "../../services/tracking";
import {
  useReleaseMigrationFromSupervision,
  useTakeMigrationForSupervision
} from "../../queries/migrationSupervisionHooks";
import { AddMigrationNoteController, MigrationNoteController, } from "../../components/migrationNotes/migrationNotes";
import { AdminSidebar } from "../../views/layouts/sidebar";
import {
  AbortMigrationToolControllerProps,
  MigrationInsightsView
} from "../../views/screens/migrationStatusPageView/components/migrationInsightsView";
import { useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { Migration } from "../../types/models/migration";
import { EntityJsonView } from "../../views/blocks/entityJsonView";
import { MigrationTimingDetails } from "../../views/screens/migrationStatusPageView/components/migrationTimingDetails";
import { MigrationOrderView } from "../../views/screens/migrationStatusPageView/components/migrationOrderView";
import { MigrationHistoryView } from "../../views/screens/migrationStatusPageView/components/migrationHistoryView";
import { useRefreshingDataSource } from "../../views/utils/refreshingDataSource";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { OperationStatus } from "../../types/operationStatus";
import { Iteration } from "../../types/models/iteration";
import { useIterationLaunchController } from "./iterationLaunchController";
import { useIterationsHistoryController } from "./iterationsHistoryController";
import { useDisconnectController } from "./disconnectController";
import { MigrationStatusPageDefs, } from "../../views/screens/migrationStatusPageView/migrationStatusPageDefs";
import { MigrationProgressComp } from "../../blueprints/components/migrationProgressComp";
import { useInvoiceToolController } from "../../components/invoiceToolController";
import { ActionItem } from "../../views/models/actionItem";
import { useSetAutoResumingConfiguration } from "../../queries/useSetAutoResumingConfiguration";
import { useAbortMigration } from "./useAbortMigration";
import {
  useGetAuthorizationStatus,
  useRefundTransaction,
  useSubmitTransactionForSettlement,
  useVoidTransaction
} from "../../queries/transactionManagementHooks";
import { AuthorizationManagementToolControllerProps } from "../../views/blocks/orderDetailsPanelView";
import { useBrowser } from "../../utils/useBrowser";
import { MigrationIssue } from "../../types/models/migrationIssue";
import { usePauseMigrationController } from "./pauseMigrationController";
import { useResumeMigrationController } from "./resumeMigrationController";
import { useConfigurationRoutes, useRoutes } from "../../app/routes/useRoutes";
import { Navigate } from "react-router";
import { SignInContextType } from "../../types/models/signInContextType";

export const MigrationStatusPage: React.FunctionComponent = () => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();
  const programAlias = useProgramAlias();

  const routes = useRoutes().migrations;
  const params = routes.migrationStatusParams();

  React.useEffect(() => Tracking.checkedMigrationStatus(params.migrationId), []);

  const [iterationIndex, setIterationIndex] = React.useState<"active" | number | undefined>("active");

  const status = useMigrationProgressQuery({
    cloudServices,
    authProviders,
    migrationId: params.migrationId,
    iterationIndex: iterationIndex === "active" ? undefined : iterationIndex
  });

  const result = status.someResult();

  if (result) {
    const migrationProgram = result.migration.orderSummary.map((order) => order.program).toJS();
    if (migrationProgram?.alias && programAlias !== migrationProgram.alias) {
      return <SwitchProgram migrationId={params.migrationId} programAlias={migrationProgram.alias}/>;
    } else {
      return (
        <MigrationStatusBody
          loadingStatus={status}
          migration={result.migration}
          blueprint={result.blueprint}
          iterationStatus={result.iterationStatus}

          iterationIndex={iterationIndex}
          setIterationIndex={setIterationIndex}
        />
      );
    }
  } else {
    return (
      <CenteredContent>
        <OperationStatusIndicator
          status={status}
          progressMessage={"Loading migration status..."}
          failureMessage={"Failed to load migration status..."}
          indicators={StatusIndicators.SimplePanel()}
        />
      </CenteredContent>
    );
  }
};

interface SwitchProgramProps {
  migrationId: string;
  programAlias: string;
}

const SwitchProgram: React.FunctionComponent<SwitchProgramProps> = (props) => {
  const programRoutes = useConfigurationRoutes({ programAlias: props.programAlias });
  return <Navigate to={programRoutes.migrations.migrationStatusPath(props.migrationId)} replace={true}/>;
};

interface MigrationStatusBodyProps {
  loadingStatus: OperationStatus<any>;

  migration: Migration & Migration.HasOrderSummary & Migration.HasConnections & Migration.HasProgressData;
  blueprint: MaterializedMigrationBlueprint;
  iterationStatus: OperationStatus<Iteration>;

  iterationIndex: "active" | number | undefined;
  setIterationIndex: (value: "active" | number | undefined) => void;
}

export const MigrationStatusBody: React.FunctionComponent<MigrationStatusBodyProps> = (props) => {
  const sourceCloudServices = useCloudServices(SignInContextType.Source);
  const destinationCloudServices = useCloudServices(SignInContextType.Destination);
  const authProviders = useAuthProviders();
  const watcherDispatch = useWatcherDispatch();
  const watcher = useWatcher();
  const drawer = useDrawer();
  const routes = useRoutes();

  const buildDownloadUrl = React.useCallback(
    (customerDetails: string) => routes.api.migrationInvoiceUrl(props.migration.id, customerDetails),
    [props.migration.id]
  );
  const invoiceToolController = useInvoiceToolController(props.migration.userId, buildDownloadUrl);

  const iterationLaunchController = useIterationLaunchController(
    props.migration,
    () => props.setIterationIndex("active")
  );
  const iterationsHistoryController = useIterationsHistoryController(props.migration);
  const disconnectController = useDisconnectController();
  const pauseMigrationController = usePauseMigrationController(props.migration);
  const resumeMigrationController = useResumeMigrationController(props.migration);

  const starting = React.useRef<boolean>();

  // If ths case of delays we don't want to display throttling issues - instead, there will be a dedicated warning
  // panel
  const visibleMigrationIssues = props.migration.issues.filter((issue) =>
    issue.class !== MigrationIssue.IssueClass.TooManyRequestsIssue ||
    props.migration.timing.delayLevel === Migration.Timing.DelayLevel.OnTime
  );
  // const visibleMigrationIssues = useSuspended(allMigrationIssues, (issue) => issue.ignoreUntil);
  // const visibleMigrationIssues = MigrationIssue.TestData;

  const sourceCloudService = sourceCloudServices.getOrFail(props.migration.sourceConnection.cloudServiceId);

  const iterationIndex = props.iterationIndex === "active"
    ? (props.migration.status === MigrationStatus.Completed ? undefined : props.migration.iteration)
    : props.iterationIndex;

  if (starting.current === undefined) {
    starting.current = props.migration.status === MigrationStatus.Starting;
  }

  const destinationEndpoints = props.blueprint.listEndpointAliases(false);

  watcherDispatch(WatchedFacts.WatchFactsAction(props.blueprint.unblockedFactIds().toArray()));
  watcherDispatch(WatchedMigrations.WatchMigrationsAction([props.migration.id]));
  watcher.watchMigrationIssueLists([props.migration.id], false, true);
  watcher.watchMigrationNoteLists([props.migration.id], false, true);

  const areas = props.blueprint.listAreas();
  const childEndpointsForAreas = areas
    .flatMap(([area]) => props.blueprint.listChildEndpointAliases(area))
    .toSet();

  const sinks = props.blueprint.listSinks();
  const childEndpointsForSinks = sinks
    .flatMap(([sink]) => props.blueprint.listChildEndpointAliases(sink))
    .toSet();

  const timeRemaining = props.blueprint.remainingMigrationTime().map(({ sink, ...time }) => ({
    title: sink.props.title,
    ...time
  }));

  return (
    <>
      <AdminSidebar
        tools={[
          {
            title: "Overview",
            render: () => <MigrationInsights migrationId={props.migration.id}/>
          },
          // {
          //   title: "History",
          //   render: () => <MigrationHistory migrationId={params.migrationId}/>
          // },
          {
            title: "Timing",
            render: () => <MigrationTimingDetails migration={props.migration} items={timeRemaining}/>
          },
          {
            title: "Order",
            render: () => <MigrationOrder migrationId={props.migration.id}/>
          },
          {
            title: "JSON",
            render: () => <MigrationJson migrationId={props.migration.id}/>,
            noPadding: true
          },
        ]}
        blueprint={props.blueprint}
      />
      <MigrationStatusPageView
        loadingStatus={props.loadingStatus}
        source={{
          cloudService: sourceCloudService,
          authProvider: authProviders.getOrFail(props.migration.sourceConnection.accessKeyProvider),
          connection: props.migration.sourceConnection,
          actionItems: renderMigrationActionItems({
            drawer,
            migration: props.migration,
            blueprint: props.blueprint,
            areaOrSink: undefined,
            migrationIssues: visibleMigrationIssues.filter((issue) =>
              !destinationEndpoints.contains(issue.endpointAlias) &&
              !childEndpointsForAreas.contains(issue.endpointAlias)
            ),
            source: true
          }),
          areas: areas
            .filter(([area]) => !sourceCloudService.excludedApps.contains(area.props.title))
            .sortBy(([area]) => area.props.order)
            .map(([area, sink, progress]) => {
              const state = area.state(props.blueprint.context);

              function buildStats(
                metrics: MigrationProgressComp.Output.Metrics | undefined
              ): MigrationStatusPageDefs.SourceArea.Stats {
                return {
                  totalBytes: metrics?.totalBytes.toJS(),
                  totalItems: metrics?.totalItems.map((total) => new ItemsByType(total)).toJS(),
                  migratedBytes: metrics?.processedBytes || 0,
                  migratedItems: new ItemsByType(metrics?.processedItems || {}),
                  skippedItems: new ItemsByType(metrics?.skippedItems || {})
                };
              }

              const excluded = state.isBlocked || !area.isEnabled(props.blueprint.context);

              const childEndpointAliases = props.blueprint.listChildEndpointAliases(area);
              const areaMigrationIssues = visibleMigrationIssues.filter((issue) =>
                childEndpointAliases.contains(issue.endpointAlias)
              );

              function areaStatus(): WorkStatus {
                if (props.migration.status === MigrationStatus.Scheduled) {
                  return WorkStatus.Pending;
                } else if (progress && progress.completed) {
                  return WorkStatus.Success;
                } else if (
                  areaMigrationIssues.find((issue) => issue.isBlocking && !issue.isResolved())
                ) {
                  return WorkStatus.Issue;
                } else {
                  return WorkStatus.Working;
                }
              }

              return {
                areaId: area.id,
                appTitle: area.resolvedAppTitle(props.blueprint.context),
                mainSubject: area.props.mainSubject,
                title: area.props.title,
                description: area.props.description,
                icon: resolveImage(area.settings.icon),
                helpArticle:
                  sink && !excluded
                    ? PreparedHelpArticle.migrationRules({
                      routes,
                      phase: props.migration.status === MigrationStatus.Completed
                        ? "after-migration"
                        : "during-migration",
                      sourceAppId: area.props.internalId,
                      destinationAppId: sink.props.internalId,
                      sourceAppTitle: area.resolvedAppTitle(props.blueprint.context),
                      destinationAppTitle: sink.resolvedAppTitle(props.blueprint.context),
                      vars: Map([
                        ["source", props.migration.sourceConnection.descriptionOrId()],
                        ["destination", props.migration.destinationConnection.descriptionOrId()],
                      ])
                    })
                    : undefined,
                status: areaStatus(),
                actionItems: renderMigrationActionItems({
                  drawer,
                  migration: props.migration,
                  blueprint: props.blueprint,
                  areaOrSink: area,
                  migrationIssues: areaMigrationIssues,
                  source: true
                }),
                excluded,
                disabled: sink
                  ? undefined
                  : {
                    id: ActionItem.noApp(sourceCloudService.name),
                    type: ActionItem.Type.Notification,
                    message: "No app in " + sourceCloudService.name + " can receive these items",
                    actions: []
                  },

                cumulative: buildStats(progress?.cumulative),
                iteration: buildStats(progress?.iteration)
              };
            })
            .toArray(),
          slowness: undefined
        }}
        destination={{
          cloudService: destinationCloudServices.getOrFail(props.migration.destinationConnection.cloudServiceId),
          authProvider: authProviders.getOrFail(props.migration.destinationConnection.accessKeyProvider),
          connection: props.migration.destinationConnection,
          actionItems: renderMigrationActionItems({
            drawer,
            migration: props.migration,
            blueprint: props.blueprint,
            areaOrSink: undefined,
            migrationIssues: visibleMigrationIssues.filter((issue) =>
              destinationEndpoints.contains(issue.endpointAlias) &&
              !childEndpointsForSinks.contains(issue.endpointAlias)
            ),
            source: false
          }),
          areas: sinks
            .sortBy(([sink]) => sink.props.order)
            .map(([sink]) => {
              const childEndpointAliases = props.blueprint.listChildEndpointAliases(sink);
              const sinkMigrationIssues = visibleMigrationIssues.filter((issue) =>
                childEndpointAliases.contains(issue.endpointAlias)
              );

              function sinkStatus(): WorkStatus {
                if (
                  sinkMigrationIssues.find((issue) => issue.isBlocking && !issue.isResolved())
                ) {
                  return WorkStatus.Issue;
                } else {
                  return WorkStatus.Pending;
                }
              }

              return {
                areaId: sink.id,
                appTitle: sink.resolvedAppTitle(props.blueprint.context),
                mainSubject: sink.props.mainSubject,
                title: sink.props.title,
                description: sink.props.description,
                icon: resolveImage(sink.settings.icon),
                helpArticle: undefined,
                status: sinkStatus(),
                actionItems: renderMigrationActionItems({
                  drawer,
                  migration: props.migration,
                  blueprint: props.blueprint,
                  areaOrSink: sink,
                  migrationIssues: sinkMigrationIssues,
                  source: false
                }),
                excluded: false,
                disabled: undefined
              };
            })
            .toArray(),
          slowness: undefined
        }}
        migration={props.migration}
        timeRemaining={timeRemaining}
        state={(() => {
          switch (props.migration.status) {
            case MigrationStatus.Scheduled:
              return MigrationStatusPageState.scheduled();
            case MigrationStatus.Starting:
              return MigrationStatusPageState.starting();
            case MigrationStatus.Running:
            case MigrationStatus.Pausing:
            case MigrationStatus.Paused:
            case MigrationStatus.WaitingForTemporaryCondition:
            case MigrationStatus.WaitingForUserAction:
            case MigrationStatus.StoppedForUnknownReason:
              return MigrationStatusPageState.working();
            case MigrationStatus.WaitingForManualReview:
              return MigrationStatusPageState.waitingForManualReview();
            case MigrationStatus.Completed:
              return MigrationStatusPageState.complete();
            case MigrationStatus.Aborted:
              return MigrationStatusPageState.aborted();
          }
        })()}
        displaySpinUp={starting.current}
        displayWarning={true}

        iterationIndex={iterationIndex}
        onIterationSelect={(newIterationIndex) => {
          if (newIterationIndex === props.migration.iteration && props.migration.status !== MigrationStatus.Completed) {
            props.setIterationIndex("active");
          } else {
            props.setIterationIndex(newIterationIndex);
          }
        }}

        iterationStatus={props.iterationStatus}

        migrationReportUrl={routes.api.migrationReportUrl(props.migration.id)}
        invoiceToolController={invoiceToolController}
        iterationLaunchController={iterationLaunchController}
        iterationsHistoryController={iterationsHistoryController}
        disconnectController={props.migration.batch ? undefined : disconnectController}
        pauseMigrationController={pauseMigrationController}
        resumeMigrationController={resumeMigrationController}
      />
    </>
  );
};

function useMigrationAdminSidebarDataQuery(migrationId: string) {
  return useManagedQuery({
    query: GraphQL.useMigrationAdminSidebarDataQuery,
    deps: null,
    prepare: () => ({ migrationId }),
    extract: (data: GraphQL.MigrationAdminSidebarDataQuery) => data.migration,
    complete: Migration.AdminSidebarData.parse
  });
}

interface MigrationInsightsProps {
  migrationId: string;
}

const MigrationInsights: React.FunctionComponent<MigrationInsightsProps> = (props) => {
  const dataSource = useRefreshingDataSource(useMigrationAdminSidebarDataQuery(props.migrationId));

  const [takeMigrationForSupervision, takeMigrationForSupervisionStatus] =
    useTakeMigrationForSupervision(props.migrationId);

  const [releaseMigrationFromSupervision, useReleaseMigrationFromSupervisionStatus] =
    useReleaseMigrationFromSupervision(props.migrationId);

  const [setAutoResumingConfiguration, setAutoResumingConfigurationStatus] =
    useSetAutoResumingConfiguration(props.migrationId);

  const abortMigrationToolController = useAbortMigrationToolController(props.migrationId);

  return (
    <MigrationInsightsView
      migrationId={props.migrationId}
      dataSource={dataSource}

      takeForSupervisionStatus={takeMigrationForSupervisionStatus}
      takeForSupervision={takeMigrationForSupervision}

      releaseFromSupervisionStatus={useReleaseMigrationFromSupervisionStatus}
      releaseFromSupervision={releaseMigrationFromSupervision}

      abortMigrationToolController={abortMigrationToolController}

      configureAutoResumingTimerStatus={setAutoResumingConfigurationStatus}
      configureAutoResumingTimer={setAutoResumingConfiguration}

      migrationNoteController={MigrationNoteController}
      addMigrationNoteController={AddMigrationNoteController}
    />
  );
};

interface AbortMigrationToolControllerImplProps extends AbortMigrationToolControllerProps {
  migrationId: string;
}

export const AbortMigrationToolControllerImpl: React.FunctionComponent<AbortMigrationToolControllerImplProps> =
  (props) => {
    const browser = useBrowser();

    const [authorizationStatusStatus] = useGetAuthorizationStatus(props.migrationId);
    const [abortMigration, abortMigrationStatus] = useAbortMigration(props.migrationId);

    return props.render({
      authorizationStatusStatus,
      abortMigrationStatus,
      onAbort: (authorizationAction) => abortMigration(authorizationAction).then((result) => {
        browser.refresh();
        return result;
      })
    });
  };

export function useAbortMigrationToolController(migrationId: string) {
  return React.useCallback(
    (controllerProps: AbortMigrationToolControllerProps) =>
      <AbortMigrationToolControllerImpl {...controllerProps} migrationId={migrationId}/>,
    [migrationId]
  );
}

interface AuthorizationManagementToolControllerImplProps extends AuthorizationManagementToolControllerProps {
  migrationId: string;
}

const AuthorizationManagementToolControllerImpl:
  React.FunctionComponent<AuthorizationManagementToolControllerImplProps> =
  (props) => {
    const [authorizationStatusStatus, refreshAuthorizationStatus] = useGetAuthorizationStatus(props.migrationId);

    const [
      submitTransactionForSettlement,
      submitTransactionForSettlementStatus,
      submitTransactionForSettlementOperationReset
    ] = useSubmitTransactionForSettlement(props.migrationId);

    const [voidTransaction, voidTransactionStatus, voidTransactionOperationReset] =
      useVoidTransaction(props.migrationId);

    const [refundTransaction, refundTransactionStatus, refundTransactionOperationReset] =
      useRefundTransaction(props.migrationId);

    return props.render({
      authorizationStatusStatus,

      submitTransactionForSettlementStatus,
      voidTransactionStatus,
      refundTransactionStatus,

      onRefreshAuthorizationStatus: refreshAuthorizationStatus,
      onSubmitTransactionForSettlement: () => submitTransactionForSettlement().then((result) => {
        refreshAuthorizationStatus();
        return result;
      }),
      onVoidTransaction: () => voidTransaction().then((result) => {
        refreshAuthorizationStatus();
        return result;
      }),
      onRefundTransaction: () => refundTransaction().then((result) => {
        refreshAuthorizationStatus();
        return result;
      }),

      onSubmitTransactionForSettlementOperationReset: submitTransactionForSettlementOperationReset,
      onVoidTransactionOperationReset: voidTransactionOperationReset,
      onRefundTransactionOperationReset: refundTransactionOperationReset
    });
  };

export function useAuthorizationManagementToolControllerImpl(migrationId: string) {
  return React.useCallback(
    (controllerProps: AuthorizationManagementToolControllerProps) =>
      <AuthorizationManagementToolControllerImpl {...controllerProps} migrationId={migrationId}/>,
    [migrationId]
  );
}

interface MigrationOrderProps {
  migrationId: string;
}

const MigrationOrder: React.FunctionComponent<MigrationOrderProps> = (props) => {
  const [status] = useMigrationAdminSidebarDataQuery(props.migrationId);

  const authorizationManagementToolController = useAuthorizationManagementToolControllerImpl(props.migrationId);

  return (
    <MigrationOrderView
      status={status}
      authorizationManagementToolController={authorizationManagementToolController}
    />
  );
};

interface MigrationJsonProps {
  migrationId: string;
}

const MigrationJson: React.FunctionComponent<MigrationJsonProps> = (props) => {
  const [status] = useMigrationAdminSidebarDataQuery(props.migrationId);
  return <EntityJsonView status={status}/>;
};

function useMigrationHistoryQuery(migrationId: string) {
  return useManagedQuery({
    query: GraphQL.useMigrationHistoryQuery,
    deps: null,
    prepare: () => ({ migrationId }),
    extract: (data: GraphQL.MigrationHistoryQuery) => data.migration,
    complete: (extract) => ({
      ...Migration.parseCore(extract),
      ...Migration.HasHistory.parse(extract)
    })
  });
}

interface MigrationHistoryProps {
  migrationId: string;
}

const MigrationHistory: React.FunctionComponent<MigrationHistoryProps> = (props) => {
  const [status] = useMigrationHistoryQuery(props.migrationId);
  return <MigrationHistoryView status={status}/>;
};
