import * as React from "react";
import { AuthContext } from "../../authContext";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { AreasForm } from "../areasForm";
import { ConnectionDescriptionRow } from "../rows/connectionDescriptionRow";
import { ErrorInfoRow } from "../../../containers/rows/errorInfoRow";
import { ConnectionSuccessRow, shouldDisplayConnectionStatusRowEx } from "../rows/connectionStatusRow";
import { UserFacingError } from "../../../../types/userFacingError";
import { CloudServices } from "../../../../types/models/cloudServices";
import { ConnectionRolesRow } from "../connectionRolesRow";
import { List, Set } from "immutable";
import { ActionItems } from "../../../models/actionItem";

interface ScanningErrorStateProps {
  authContext: AuthContext;
  defaultRoles: Set<string>;
  cloudServices: CloudServices;
  connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
  areas: List<ConnectionPanelDefs.Area>;
  areaSelection?: ConnectionPanelDefs.AreaSelection | undefined;
  onDisconnect?: () => void;
  error: UserFacingError;
  viewSettings: ConnectionPanelDefs.ViewSettings;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const ScanningErrorState: React.FunctionComponent<ScanningErrorStateProps> = (props) => (
  <>
    <ConnectionDescriptionRow
      authContext={props.authContext}
      connectionDescription={props.connectionDetails.connection.descriptionOrId()}
      status={AuthContext.scanOrTest(props.authContext) + " is paused"}
      onDisconnect={props.onDisconnect}
    />
    <ConnectionRolesRow
      defaultRoles={props.defaultRoles}
      connectionDetails={props.connectionDetails}
    />
    <ErrorInfoRow
      error={props.error.enrich({
        title: "Our apologies. Something's wrong and this " +
          AuthContext.scanOrTest(props.authContext).toLowerCase() + " is paused.",
        summary: null,
        recommendations: "Please DISCONNECT your account and try again."
      })}
    />
    {shouldDisplayConnectionStatusRowEx(props) && (
      <ConnectionSuccessRow
        connection={props.connectionDetails}
        checkboxPlaceholder={props.areas.size !== 1 && !!props.areaSelection}
        showProgressIndicator={props.viewSettings.showProgress}
        viewSettings={props.viewSettings}
        actionItemSuppressing={props.actionItemSuppressing}
      />
    )}
    <AreasForm
      authContext={props.authContext}
      cloudServices={props.cloudServices}
      selectedCloudServiceId={props.connectionDetails.cloudService.id}
      connectionId={props.connectionDetails.connection.id}
      areas={props.areas}
      areaSelection={props.areas.size === 1 ? undefined : props.areaSelection}
      viewSettings={props.viewSettings}
      actionItemSuppressing={props.actionItemSuppressing}
    />
  </>
);
