import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { AccessListUpdate } from "../../types/models/accessListUpdate";
import { List } from "immutable";

export function useListAccessListUpdatesQuery(
  ids: number[]
): ManagedQueryHook<GraphQL.ListAccessListUpdatesQueryVariables, List<AccessListUpdate>> {
  return useManagedQuery({
    query: GraphQL.useListAccessListUpdatesQuery,
    deps: ids.length !== 0 ? ids : undefined,
    prepare: (deps) => ({ ids: deps }),
    extract: (data: GraphQL.ListAccessListUpdatesQuery) => nullToUndefined(data.accessListUpdates),
    complete: (accessListUpdates) => List(accessListUpdates)
      .map(AccessListUpdate.parse)
      .sortBy((update) => -update.createdAt)
  });
}
