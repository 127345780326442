import * as React from "react";
import { css } from "styled-components";
import { AppTheme, keyframes, styled } from "../../app/theme";
import { prettyPrint } from "../../utils/formatting";
import { LinkButton } from "./linkButton";
import { WidgetStatus } from "../utils/widgetStatus";

interface GridProps {
  selectable?: boolean;
  stickyFirstColumn?: boolean;
  compact?: boolean;
}

const StyledTable = styled.table<GridProps>`
  width: 100%;
  
  > tbody, thead {
    border-top: 1px solid ${(props) => props.theme.colors.lightGray};

    > tr {
      // This is required for "z-index: -1" to work in ProgressCellBackground 
      // (taken from here: https://stackoverflow.com/questions/27508962/progress-bar-inside-table-cell/27509082)
      opacity: .999;
      background: ${(props) => props.theme.colors.white};
      
      > td, > th {
        vertical-align: top;
        text-align: left;
        padding: .7rem;
        
        ${(props) => 
          props.compact && css`
            ${props.theme.responsive.respondToSmall()} {
              padding: .3rem; font-size: 0.85rem;
            }
          `
        }
      }
    }

    ${(props) => props.stickyFirstColumn && css`
      > tr {
        > td:first-child:not([colspan]), > th:first-child {
          position: sticky;
          left: 0;
          border-right: 1px solid ${props.theme.colors.gray};
          //background: ${props.theme.colors.lighterGray};
          z-index: 1; // Requited to beat "z-index: -1" used for progress cells
        }

        > th:first-child {
          //border-right: 1px solid ${props.theme.colors.gray};
        }
      }
    `}

    > tr + tr {
      > td {
        border-top: 1px solid ${(props) => props.theme.colors.lightGray};
      }
    }
  }
  
  > thead {
    > tr {
      > th {
        padding-top: .7rem;
        ${(props) =>
          props.compact && css`
            ${props.theme.responsive.respondToSmall()} {
              padding-top: .4rem;
            }
          `
        };
        font-weight: 400;
        background: ${(props) => props.theme.colors.lighterGray};
        border-bottom: 1px solid ${(props) => props.theme.colors.gray};
      }
    }
  }
  
  ${(props) => props.selectable && css`
    > tbody {
      > tr {
        cursor: pointer;
        
        &:hover {
          background: ${props.theme.colors.faintPrimary};
        }
      }
      
      > tr + tr:hover, > tr:hover + tr {
        > td {
          border-top-color: ${props.theme.colors.faintPrimary};
        }
      }
    }
  `}
`;

export function Grid(props: React.PropsWithChildren<GridProps>): React.ReactElement {
  return <StyledTable {...props}>{props.children}</StyledTable>;
}

export namespace Grid {
  function flashKeyframes(theme: AppTheme, statusColor: string) {
    return keyframes`
      from { background-color: ${theme.colors.faintPrimary}; }
      to { background-color: ${statusColor}; }
    `;
  }

  export const Body = styled.tbody``;

  export const Header: React.FunctionComponent = (props) => (
      <thead><tr>{props.children}</tr></thead>
  );

  export const Row = styled.tr``;

  interface CellProps {
    status?: WidgetStatus;
    flash?: boolean;
    nowrap?: boolean;
  }

  export const Cell = styled.td<CellProps>`
    color: ${(props) => WidgetStatus.valueColor(props.theme, props.status)};
    background: ${(props) => WidgetStatus.valueBackground(props.theme, props.status)};

    ${(props) => props.flash && css`
      animation: ${
        flashKeyframes(
          props.theme, 
          WidgetStatus.valueBackground(props.theme, props.status)
        )
      } 5s forwards; 
    `}
    
    ${(props) => props.nowrap && css`
      white-space: nowrap;
    `}
  `;

  interface ProgressCellProps {
    status?: WidgetStatus;
    progress: number;
    nowrap?: boolean;
  }

  const ProgressCellContainer = styled.td<ProgressCellProps>`
    position: relative;
    ${(props) => props.nowrap && css`
      white-space: nowrap;
    `}
  `;

  // Cell's background has to be transparent to show the progress bar. Therefore, we need a special div to style
  // the background behind the progress bar.
  // (see https://stackoverflow.com/questions/27508962/progress-bar-inside-table-cell/27509082)
  const ProgressCellBackground = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;  
  `;

  const ProgressCellForeground = styled.div<ProgressCellProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => props.progress}%;
    background: ${(props) => WidgetStatus.brightValueBackground(props.theme, props.status || WidgetStatus.Info)};
    z-index: -1;  
  `;

  export const ProgressCell: React.FunctionComponent<ProgressCellProps> = ({ children, ...props }) => (
    <ProgressCellContainer {...props}>
      <ProgressCellBackground/>
      <ProgressCellForeground {...props}/>
      {children}
    </ProgressCellContainer>
  );

  interface ColumnProps {
    nowrap?: boolean;
  }

  export const Column = styled.th<ColumnProps>`
    ${(props) => props.nowrap && css`
      white-space: nowrap;
    `}
  `;

  export const Group = styled.td`
    background: ${(props) => props.theme.colors.offWhite};
    font-weight: ${(props) => props.theme.font.medium};
  `;

  interface ActionsCellProps {
    actionType?: "kebab" | "button";
  }

  export const ActionsCell = styled.td<ActionsCellProps>`
    padding: ${(props) => props.actionType === "kebab" ? 0.2 : 0.5}rem !important;
    vertical-align: ${(props) => props.actionType === "kebab" ? "middle" : "top"};
    width: 1%;
  `;

  interface JsonCellProps extends CellProps {
    version?: string;
  }

  export const JsonCell: React.FunctionComponent<JsonCellProps> = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const json = typeof props.children === "string" ? props.children : prettyPrint(props.children);
    return (
        <Cell {...props} title={props.version}>
          {(() => {
            if (json) {
              if (json.length < 20 && json.indexOf("\n") === -1 || expanded) {
                return <pre>{json}</pre>;
              } else {
                return <>{json.length} chars <LinkButton onClick={() => setExpanded(true)}>View</LinkButton></>;
              }
            } else {
              return "";
            }
          })()}
        </Cell>
    );
  };
}
