import { styled } from "../../app/theme";

interface FormLabelProps {
  error?: boolean;
}

export const FormLabel = styled.label<FormLabelProps>`
  color: ${(props) => props.error ? props.theme.colors.red : props.theme.colors.black};
  font-size: 0.9375rem;
  display: block;
`;
