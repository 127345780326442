import * as React from "react";
import { styled } from "../../../app/theme";
import { SimpleLinkButton } from "../../widgets/linkButton";
import { HelpArticles } from "../../../app/helpArticles";
import { useCloudServices } from "../../../app/configuration";
import { PreparedHelpArticle } from "../../../utils/preparedHelpArticle";
import { ExternalHelpArticle } from "../../../types/models/externalHelpArticle";

interface OAuthSignInButtonProps {
  css?: string;
}

export const OAuthSignInButton = styled.button<OAuthSignInButtonProps>`
  border-radius: 0.15rem;
  text-decoration: none;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  border: 1px solid black;

  > .layout {
    display: flex;
  
    > .logo {
      display: flex;
      flex-shrink: 0;
      align-items: center;

      > img {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        margin: 0.7rem;
      }
    }
    
    > .text {
      display: flex;
      align-items: center;
      padding: 0.4rem 1.5rem 0.4rem;
      width: 100%;
    }
  }
  
  @media(hover: hover) {
    &:hover:not([disabled]) {
      transition: 0.1s;
      box-shadow: 0 2px 1px rgba(124,124,124,0.6);
      transform: scale(1.01);
      filter: brightness(110%);
      cursor: pointer;
    }
  }

  ${(props) => props.css};
`;

const Layout = styled.div`
`;

const AdditionalOptions = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  
  > div + div {
    margin-top: .2rem;
  } 
`;

export interface OAuthSignInFormProps {
  cloudServiceId: string;

  verb: string;
  vendorName: string | undefined;
  buttonCss: string | undefined;
  buttonIcon: string | undefined;

  howToSwitchAccountHelpArticle: React.ReactNode | undefined;
  additionalOptions: React.ReactNode[];

  onSignInButtonClick: () => void;
}

export const OAuthSignInForm: React.FunctionComponent<OAuthSignInFormProps> = (props) => {
  const cloudServices = useCloudServices();

  const howToSwitchAccountHelpArticleOption: React.ReactNode[] = [(
    <React.Fragment key={"howToSwitchAccount"}>
      IMPORTANT: When the list of requested access is displayed,
      be sure to check the <strong>"Select all"</strong> option.
      <br/>
      Can't sign in to the right account?{" "}
      <SimpleLinkButton
        onSimpleClick={
          props.howToSwitchAccountHelpArticle ||
          PreparedHelpArticle.fromExternal(
            HelpArticles.oAuthAccountSwitch(
              props.vendorName || cloudServices.getOrFail(props.cloudServiceId).name
            )
          )
        }
      >
        Learn what to do
      </SimpleLinkButton>
    </React.Fragment>
  )];

  const additionalOptions = howToSwitchAccountHelpArticleOption.concat(props.additionalOptions || []);

  return (
    <Layout>
      <OAuthSignInButton
        type={"button"}
        css={props.buttonCss}
        onClick={props.onSignInButtonClick}
      >
        <div className="layout">
          {props.buttonIcon && <div className="logo"><img src={props.buttonIcon}/></div>}
          <div className="text">
            <div>{props.verb + (props.vendorName ? " with " + props.vendorName : "")}</div>
          </div>
        </div>
      </OAuthSignInButton>
      {additionalOptions.length !== 0 && (
        <AdditionalOptions>
          {additionalOptions.map((option, index) => (<div key={index}>{option}</div>))}
        </AdditionalOptions>
      )}
    </Layout>
  );
};
