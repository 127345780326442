import * as React from "react";
import { styled } from "../../app/theme";

interface CheckedCircleProps {
  hollow?: boolean;
  lineStrokeWidthMultiplier?: number;
}

const StyledSvg = styled.svg<CheckedCircleProps & { strokeWidth: number }>`
  width: 100%;
  height: 100%;
  
  circle {
    fill: ${(props) => props.hollow ? "transparent" : props.theme.colors.primary};
    stroke: ${(props) => props.theme.colors.primary};
    stroke-width: ${(props) => props.strokeWidth};
  }
  
  polygon {
    fill: ${(props) => props.hollow ? props.theme.colors.primary : "white"};
    stroke: none;
  }
`;

export const CheckedCircle: React.FunctionComponent<CheckedCircleProps> = (props) => {
  const radius = 16;
  const cx = 7.5;
  const cy = 7.5;
  const strokeWidth = 2.8 * (props.lineStrokeWidthMultiplier || 1);
  const viewBox = (cx - radius) + " " + (cy - radius) +
    " " + (radius * 2) + " " + (radius * 2);
  return (
    <StyledSvg {...props} strokeWidth={strokeWidth} viewBox={viewBox}>
      <circle className="circle" cx={cx} cy={cy} r={radius - strokeWidth}/>
      <polygon className="checkmark" points="14.5 0 16 1.1 6.5 15 0 10.5 1 8.9 6 12.4"/>
    </StyledSvg>
  );
};
