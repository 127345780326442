import * as React from "react";
import { Connection } from "../../../types/models/connection";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Connections } from "../../../types/models/connections";
import { ConnectionsStatusRow } from "./connectionsStatusRow";
import { ConnectionsGrid } from "./connectionsGrid";
import { Panel } from "../../containers/panel";
import { CloudServices } from "../../../types/models/cloudServices";
import { AuthProviders } from "../../../types/models/authProviders";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";

interface Props {
  cloudServices: CloudServices;
  authProviders: AuthProviders;

  showStatusRow: boolean;
  status: OperationStatus<Connections>;
  refreshing: boolean;
  onRefresh: () => void;

  actionTitle: string | undefined;
  onAction: ((connection: Connection) => void) | undefined;
}

export const ConnectionsPanelView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      subject={"connections"}
      progressMessage={props.refreshing ? "Checking connections..." : undefined}
      status={props.status}
      indicators={StatusIndicators.PanelRow()}
    />
    {props.status.mapLastResult((connections) => (
      <>
        {props.showStatusRow && props.status.isSuccess() && (
          <ConnectionsStatusRow
            connectionCount={connections.list().size}
            onRefreshButtonClick={props.onRefresh}
          />
        )}
        <GridPanelRow>
          <ConnectionsGrid
            cloudServices={props.cloudServices}
            authProviders={props.authProviders}
            connections={connections}
            actionTitle={props.actionTitle}
            onAction={props.onAction}
          />
        </GridPanelRow>
      </>
    ))}
  </Panel>
);
