import * as React from "react";
import * as yup from "yup";
import { CheckoutStepView } from "../views/screens/migrationSetup/checkoutStepView/checkoutStepView";
import { LocalStorage } from "../app/localStorage";
import { useElevated } from "../utils/useAppState";
import { CloudService } from "../types/models/cloudService";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { Connection } from "../types/models/connection";
import { useNavigate } from "react-router";
import { useCheckoutFlow } from "./useCheckoutFlow";
import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { Order } from "../types/models/order";
import { AdminSidebar } from "../views/layouts/sidebar";
import { Option } from "../utils/monads/option";
import { CheckoutOrderView } from "../views/blocks/orderDetailsPanelView";
import { useRoutes } from "../app/routes/useRoutes";
import { CheckboxesState } from "../types/models/checkboxesState";
import { useAppBootstrapConfig } from "../app/configuration";

export interface CheckoutStepResult {
  something: string;
}

export namespace CheckoutStepResult {
  export const Schema = yup.object<CheckoutStepResult>({
    something: yup.string().required()
  });
}

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: MaterializedMigrationBlueprint;
  sourceConnection: Connection;
  destinationConnection: Connection;
  checkboxesState: CheckboxesState;
  onNavigateBack?: () => void;
}

export const CheckoutStep: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const routes = useRoutes();
  const elevated = useElevated();

  const configuration = useAppBootstrapConfig().configuration;
  const enableFeedback = !configuration || configuration.enableFeedback;

  const checkoutFlow = useCheckoutFlow({
    ...props,
    onLaunched: (migration) => {
      if (!elevated) {
        LocalStorage.removeObject(LocalStorage.MigrationSetupWorkflowKey);
      }
      navigate(
        enableFeedback
          ? routes.feedback.path(migration.id)
          : routes.migrations.migrationStatusPath(migration.id)
      );
    }
  });

  return (
    <>
      <AdminSidebar
        tools={[
          {
            title: "Order",
            render: () => (
              <CheckoutOrder
                orderId={checkoutFlow.loadingStatus.someResult()?.orderId}
                submitDisabled={checkoutFlow.submitDisabled}
                onExternalPayment={(amount, note) =>
                  checkoutFlow.onExternalPayment({ amount, note })
                }
              />
            )
          },
        ]}
        blueprint={props.blueprint}
      />
      <CheckoutStepView {...checkoutFlow} onNavigateBack={props.onNavigateBack}/>
    </>
  );
};

interface CheckoutOrderProps {
  orderId: string | undefined;
  submitDisabled: boolean;
  onExternalPayment: (amount: number, note: Option<string>) => void;
}

const CheckoutOrder: React.FunctionComponent<CheckoutOrderProps> = (props) => {
  const [status] = useManagedQuery({
    query: GraphQL.useGetOrderQuery,
    deps: props.orderId,
    prepare: (deps) => ({ orderId: deps }),
    extract: (data: GraphQL.GetOrderQuery) => data.order,
    complete: (order) => ({
      ...Order.parse(order),
      ...Order.HasRevenueData.parseRevenueData(order)
    })
  });
  return (
    <CheckoutOrderView
      status={status}
      isWorking={props.submitDisabled}
      onExternalPayment={props.onExternalPayment}
    />
  );
};
