import * as React from "react";
import { StickyNote } from "../../views/glyphs/infoSign";
import { WarningSign } from "../../views/glyphs/warningSign";

export enum MigrationNoteType {
  Info = "Info",
  Warning  = "Warning",
  Issue  = "Issue"
}

export namespace MigrationNoteType {
  export function icon(type: MigrationNoteType) {
    switch (type) {
      case MigrationNoteType.Info: return <StickyNote/>;
      case MigrationNoteType.Warning: return <WarningSign color={"yellow"}/>;
      case MigrationNoteType.Issue: return <WarningSign color={"red"}/>;
    }
  }
}
