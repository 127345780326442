import { GraphQL } from "../../services/graphql/generated";

export interface AccessListFormData {
  readonly name: string;
  readonly notes: string | undefined;

  readonly isEnabled: boolean;
  readonly isProgramAllowlist: boolean;
  readonly programId: string | undefined;
  readonly blockingMessage: string | undefined;

  readonly googleGroupId: string | undefined;
}

export namespace AccessListFormData {
  export function toGraphQL(data: AccessListFormData): GraphQL.AccessListFormData {
    return {
      name: data.name,
      notes: data.notes,

      isEnabled: data.isEnabled,
      isProgramAllowlist: data.isProgramAllowlist,
      programId: data.programId,
      blockingMessage: data.blockingMessage,

      googleGroupId: data.googleGroupId
    };
  }
}
