import { ReferralCode } from "../../types/models/referralCode";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { ReferralCodeFormData } from "../../types/models/referralCodeFormData";

type InsertReferralCodeMutationHook = [
  (formData: ReferralCodeFormData) => Promise<ReferralCode>,
  OperationStatus<ReferralCode>
];

export function useInsertReferralCodeMutation(organizationId: number): InsertReferralCodeMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertReferralCodeMutation,
    extract: (data: GraphQL.InsertReferralCodeMutation) => nullToUndefined(data.insertReferralCode),
    complete: ReferralCode.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This code is already being used" })
      ]
    ]
  });

  function fireWith(formData: ReferralCodeFormData): Promise<ReferralCode> {
    return fire({
      variables: { organizationId, formData: ReferralCodeFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
