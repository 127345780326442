import { createGlobalStyle } from "../app/theme";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }

  input:focus, button:focus, textarea:focus {
    outline: none;
  }

  html {
    position: relative;
    height: 100%;
  }

  html, button, input {
    font-size: 15px;
    font-family: ${(props) => props.theme.font.family};
    color: ${(props) => props.theme.colors.textColor};
    font-weight: ${(props) => props.theme.font.light};
    line-height: 1.2;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    html, button {
      font-size: 15px;
    }
  }

  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.offWhite};
    height: 100%;
  }

  .react-app-container {
    height: 100%;
  }

  strong {
    font-weight: ${(props) => props.theme.font.medium};
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    border-spacing: 0;
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Shepherd
  
  .shepherd-element {
    background: #fff;
    border-radius: .4rem;
    box-shadow: 0 .1rem .3rem rgba(0,0,0,.2);
    max-width: 30rem;
    opacity: 0;
    outline: none;
    transition: opacity .3s,visibility .3s;
    visibility: hidden;
    /*width:100%;*/
    z-index: 9999;
  }
  
  .shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible;
  }
  
  .shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity:0;
    pointer-events:none;
    visibility:hidden;
  }
  
  .shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
    box-sizing: border-box
  }
  
  .shepherd-arrow, .shepherd-arrow:before {
    position: absolute; 
    width: 1rem; 
    height: 1rem;
    z-index: -1;
  }
  
  .shepherd-arrow:before {
    content: "";
    transform: rotate(45deg);
    background: #fff;
  }
  
  .shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
    bottom: -.5rem;
  }
    
  .shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
    top: -.5rem;
  }
  
  .shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
    right: -.5rem;
  }
  
  .shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
    left: -.5rem; 
  }
  
  .shepherd-element.shepherd-centered > .shepherd-arrow {
    opacity: 0;
  }
  
  .shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
    background-color: #e6e6e6
  }
  
  .shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
  .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
    pointer-events: none;
  }
  
  .shepherd-modal-overlay-container {
    -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
    filter: alpha(opacity=50);
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
    width: 100vw;
    z-index: 9997;
  }
  
  .shepherd-modal-overlay-container.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .5;
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s;
  }
  
  .shepherd-modal-overlay-container.shepherd-modal-is-visible path {
    pointer-events: all;
  }
  
  .shepherd-content {
    border-radius: 5px;
    outline: none;
    padding: 0;
  }
  
  .shepherd-footer {
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    display: flex;
    justify-content: flex-start;
    padding: 0 .75rem .75rem;
  }
  
  .shepherd-footer .shepherd-button:last-child {
    margin-right: 0;
  }
  
  //.shepherd-header {
  //  align-items: center;
  //  border-top-left-radius: .4rem;
  //  border-top-right-radius: .4rem;
  //  display: flex;
  //  justify-content: flex-end;
  //  line-height: 2em;
  //  padding: .75rem .75rem 0;
  //}
  //
  //.shepherd-has-title .shepherd-content .shepherd-header {
  //  background: #e6e6e6;
  //  padding: 1em;
  //}
  
  .shepherd-text {
    color: rgba(0,0,0,.75);
    font-size: 1rem;
    line-height: 1.3em;
    padding: .75em;
  }
  
  //.shepherd-text p {
  //  margin-top: 0;
  //}
  //
  //.shepherd-text p:last-child {
  //  margin-bottom: 0;
  //}
  
  //.shepherd-cancel-icon {
  //  background: transparent;
  //  border: none;
  //  color: hsla(0,0%,50.2%,.75);
  //  font-size: 2em;
  //  cursor: pointer;
  //  font-weight: 400;
  //  margin: 0;
  //  padding: 0;
  //  transition: color .5s ease;
  //}
  //  
  //.shepherd-cancel-icon:hover {
  //  color:rgba(0,0,0,.75);
  //}
  //
  //.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  //  color: hsla(0,0%,50.2%,.75);
  //}
  //
  //.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  //  color:rgba(0,0,0,.75);
  //}
  //
  //.shepherd-title {
  //  color: rgba(0,0,0,.75);
  //  display: flex;
  //  font-size: 1rem;
  //  font-weight: 400;
  //  flex: 1 0 auto;
  //  margin: 0;
  //  padding: 0;
  //}
  
  .shepherd-button {
    padding: .5rem 1rem;
    background: ${(props) => props.theme.colors.primary};
    color: white;
    border-radius: .2rem;
    cursor: pointer;
  }
  
  .shepherd-button:active {
    filter: brightness(95%);
  }
  
  .shepherd-button:hover {
    filter: brightness(110%);
  }
`;
