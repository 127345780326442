import { OperationStatus } from "../../../types/operationStatus";
import * as React from "react";
import { Panel } from "../../containers/panel";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";

interface Props {
  onSubmit: () => Promise<any>;
  status: OperationStatus<any>;
}

export const BatchLaunchTool: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      status={props.status}
      progressMessage={"Starting migration..."}
      failureMessage={"Failed to start migration"}
      indicators={StatusIndicators.PanelRow()}
    />
    <PanelRow>
      Clicking the button below will start the migration. You will be able to keep track of the migration progress.
    </PanelRow>
    <PanelRow>
      <Button disabled={props.status.isWorking()} onClick={props.onSubmit}>
        Start Migration
      </Button>
    </PanelRow>
  </Panel>
);
