import * as React from "react";
import { Migration } from "../../../../types/models/migration";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import {
  AuthorizationManagementToolControllerType,
  OrderDetailsPanelView
} from "../../../blocks/orderDetailsPanelView";

interface Props {
  status: OperationStatus<Migration.AdminSidebarData>;
  authorizationManagementToolController?: AuthorizationManagementToolControllerType;
}

export const MigrationOrderView: React.FunctionComponent<Props> = (props) => (
  <>
    <OperationStatusIndicator
      subject={"migration"}
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
    {
      props.status.isSuccess() && (
        props.status.result.order
          .map((order) => (
            <OrderDetailsPanelView
              key={0}
              order={order}
              authorizationManagementToolController={props.authorizationManagementToolController}
            />
          ))
          .getOrUse(<>No order exists for this migration</>)
      )
    }
  </>
);
