import * as React from "react";
import { OrganizationMigrationsPageView } from "../../views/screens/organizationPortal/organizationMigrationsPageView";
import { useWatcherDispatch } from "../../services/watcher/useWatcher";
import { WatchedMigrations } from "../../services/watcher/plugins/watchedMigrationsPlugin";
import { OrganizationSummary } from "../../types/models/organization";
import { useFindOrganizationMigrationsMutation } from "../../queries/migrations/useFindOrganizationMigrationsMutation";
import { useRoutes } from "../../app/routes/useRoutes";
import { Route, Routes, useNavigate } from "react-router";

interface Props {
  organization: OrganizationSummary;
}

export const OrganizationMigrationsPage: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes().organizationPortalRoutes.migrations(props.organization.alias);

  return (
    <Routes>
      <Route path={routes.homeMask} element={<Body organization={props.organization}/>}/>
      <Route path={routes.searchMask} element={<Body organization={props.organization}/>}/>
    </Routes>
  );
};

const Body: React.FunctionComponent<Props> = (props) => {
  const watcher = useWatcherDispatch();

  const navigate = useNavigate();
  const routes = useRoutes().organizationPortalRoutes.migrations(props.organization.alias);
  const searchTerm = routes.searchParams().term;

  const [search, status, reset] = useFindOrganizationMigrationsMutation(props.organization.id);

  React.useEffect(
    () => {
      if (searchTerm) {
        search(searchTerm).then((result) =>
          watcher(WatchedMigrations.WatchMigrationsAction(result.map((migration) => migration.id)))
        );
      } else {
        reset();
      }
    },
    [searchTerm]
  );

  return (
    <OrganizationMigrationsPageView
      searchTerm={searchTerm}
      searchStatus={status}
      onSearch={(newTerm) => navigate(routes.searchPath(newTerm), { replace: !!searchTerm })}
    />
  );
};
