import * as React from "react";
import { AuthContext } from "../../authContext";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { AreasForm } from "../areasForm";
import { ConnectionDescriptionRow } from "../rows/connectionDescriptionRow";
import {
  ConnectionSuccessRow,
  shouldDisplayConnectionStatusRowEx
} from "../rows/connectionStatusRow";
import { ScanningCompleteRow, shouldDisplayScanningCompleteRow } from "../rows/scanningCompleteRow";
import { TotalsRow } from "../rows/totalsRow";
import { CloudServices } from "../../../../types/models/cloudServices";
import { ConnectionRolesRow } from "../connectionRolesRow";
import { List, Set } from "immutable";
import { ActionItems } from "../../../models/actionItem";

interface SuccessStateProps {
  authContext: AuthContext;
  defaultRoles: Set<string>;
  cloudServices: CloudServices;
  connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
  areas: List<ConnectionPanelDefs.Area>;
  areaSelection?: ConnectionPanelDefs.AreaSelection;
  onReScan: () => void;
  onDisconnect: (() => void) | undefined;
  viewSettings: ConnectionPanelDefs.ViewSettings;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const ConnectedState: React.FunctionComponent<SuccessStateProps> = (props) => {
  const shouldRenderConnectionStatusRow = shouldDisplayConnectionStatusRowEx(props);

  function renderFooter() {
    if (props.areaSelection) {
      if (props.areas.size !== 1) {
        return (
          <TotalsRow
            cloudService={props.connectionDetails.cloudService}
            areas={props.areas}
            areaSelection={props.areaSelection}
          />
        );
      }
    } else if (shouldDisplayScanningCompleteRow(props.connectionDetails, props.areas, props.viewSettings)) {
      return (
        <ScanningCompleteRow
          authContext={props.authContext}
          pendingActions={!!props.areas.find((area) => !!area.incrementalSignIn)}
          checkboxPlaceholder={props.areas.size !== 1 && !!props.areaSelection}
          showIssues={!shouldRenderConnectionStatusRow}
          connection={props.connectionDetails}
          successMessage={props.viewSettings.successMessage}
          actionItemSuppressing={props.actionItemSuppressing}
        />
      );
    }
  }

  return (
    <>
      <ConnectionDescriptionRow
        authContext={props.authContext}
        connectionDescription={props.connectionDetails.connection.descriptionOrId()}
        status="Connected Account"
        onReScan={props.onReScan}
        onDisconnect={props.onDisconnect}
      />
      <ConnectionRolesRow
        defaultRoles={props.defaultRoles}
        connectionDetails={props.connectionDetails}
      />
      {shouldRenderConnectionStatusRow && (
        <ConnectionSuccessRow
          connection={props.connectionDetails}
          checkboxPlaceholder={props.areas.size !== 1 && !!props.areaSelection}
          showProgressIndicator={props.viewSettings.showProgress}
          viewSettings={props.viewSettings}
          actionItemSuppressing={props.actionItemSuppressing}
        />
      )}
      <AreasForm
        authContext={props.authContext}
        cloudServices={props.cloudServices}
        selectedCloudServiceId={props.connectionDetails.cloudService.id}
        connectionId={props.connectionDetails.connection.id}
        areas={props.areas}
        areaSelection={props.areas.size === 1 ? undefined : props.areaSelection}
        viewSettings={props.viewSettings}
        actionItemSuppressing={props.actionItemSuppressing}
      />
      {renderFooter()}
    </>
  );
};
