import * as React from "react";
import { WorkflowContextLike } from "./workflowContextLike";
import { Workflow } from "./workflow";
import { List } from "immutable";
import { AnyRenderHooks } from "./workItem";
import { ClickableAnchor } from "./anchor";
import { WorkflowMutationResult } from "./workflowMutationResult";
import { Path } from "./path";
import { FastPromise } from "./fastPromise";
import { WorkflowState } from "./workflowState";
import { Listener } from "./listener";

export class ControlledWorkflowState<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  > {
  constructor(
    public readonly config: ControlledWorkflowStateDefs.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    public readonly workflowState: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    public readonly sideEffects: List<ControlledWorkflowStateDefs.SideEffect<WorkflowResult>> = List(),
    public readonly childrenConfig?: ControlledWorkflowStateDefs.CalculatedChildrenConfig<
      WorkflowContext, WorkflowFeedback, WorkflowResult
      >,
  ) {
  }

  public get workflow(): Workflow<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return this.config.workflow;
  }

  public get listener(): Listener<any> {
    return this.config.listener;
  }

  public withSideEffects(
    ...sideEffects: ControlledWorkflowStateDefs.SideEffect<WorkflowResult>[]
  ): ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return new ControlledWorkflowState(
      this.config,
      this.workflowState,
      this.sideEffects.push(...sideEffects),
      this.childrenConfig
    );
  }

  public clearSideEffects(): ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return new ControlledWorkflowState(this.config, this.workflowState, List(), this.childrenConfig);
  }

  public fireAction(
    action: ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    switch (action.type) {
      case ControlledWorkflowStateDefs.ActionType.Initialize:
        return this.navigate(action.path, true);

      case ControlledWorkflowStateDefs.ActionType.ChangeWorkflowContext:
        return this.changeWorkflowContext(action.context);

      case ControlledWorkflowStateDefs.ActionType.Navigate:
        return this.navigate(action.path, false);

      case ControlledWorkflowStateDefs.ActionType.Complete:
        return this.applyResult(action.path, action.result, true);

      case ControlledWorkflowStateDefs.ActionType.Apply:
        return this.applyResult(action.path, action.result, false);

      case ControlledWorkflowStateDefs.ActionType.Clear:
        return this.clearResult(action.path);

      case ControlledWorkflowStateDefs.ActionType.ProcessFeedback:
        return this.processFeedback(action.path, action.feedback);

      case ControlledWorkflowStateDefs.ActionType.ApplyUpdates:
        return this.applyUpdates(action.events);
    }
  }

  public cleanUp(): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    return this.processMutationResult(this.workflow.cleanUp(this.workflowState.prepareForNextCycle()));
  }

  protected navigate(
    path: string | Path,
    initialization: boolean = false
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    const currentPath = this.workflowState.path;
    const newPath = Path.parse(path);

    // console.log("%%%", "Navigating " + currentPath.toString() + " -> " + newPath.toString());

    if (!currentPath.equals(newPath) || initialization) {
      return this.workflow
        .validatePath(this.workflowState.prepareForNextCycle().withPath(newPath, false))
        .map((validatedPath) => {
          if (
            !validatedPath.result.equals(currentPath) ||
            !validatedPath.result.equals(newPath) ||
            initialization
          ) {
            return this.freezeState(
              validatedPath.state.withPath(validatedPath.result, !validatedPath.result.equals(newPath))
            );
          } else {
            return FastPromise(this);
          }
        });
    } else {
      return FastPromise(this);
    }
  }

  protected changeWorkflowContext(
    workflowContext: WorkflowContext
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    return this.freezeState(this.workflowState.copy({ workflowContext }));
  }

  protected applyResult(
    path: string | Path,
    result: any,
    proceed: boolean
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    if (Path.parse(path).equals(this.workflowState.path)) {
      return this.processMutationResult(
        this.workflow.applyResult(this.workflowState.prepareForNextCycle(), result, proceed)
      );
    } else {
      return FastPromise(this);
    }
  }

  protected clearResult(
    path: string | Path,
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    if (Path.parse(path).equals(this.workflowState.path)) {
      return this.processMutationResult(
        this.workflow.clearResult(this.workflowState.prepareForNextCycle())
      );
    } else {
      return FastPromise(this);
    }
  }

  protected processFeedback(
    path: string | Path,
    feedback: WorkflowFeedback
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    if (Path.parse(path).equals(this.workflowState.path)) {
      return this.processMutationResult(
        this.workflow.processFeedback(this.workflowState.prepareForNextCycle(), feedback)
      );
    } else {
      return FastPromise(this);
    }
  }

  protected processMutationResult(
    promise: Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    return promise
      .map((mutationResult) => {
        switch (mutationResult.result.type) {
          case WorkflowMutationResult.Type.Progressed:
            return this.freezeState(mutationResult.state.withPath(mutationResult.result.newPath, false));

          case WorkflowMutationResult.Type.Completed:
            const workflowResult = mutationResult.result.result;
            return this.freezeState(mutationResult.state.reset()).map((result) =>
              result.withSideEffects(ControlledWorkflowStateDefs.CompleteWorkflowSideEffect(workflowResult))
            );

          case WorkflowMutationResult.Type.DidNotChange:
            return this;
        }
      });
  }

  protected buildChildrenConfig(
    workflowState: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): FastPromise<[
    ControlledWorkflowStateDefs.CalculatedChildrenConfig<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
    ]> {
    return this.workflow.getAnchors(workflowState).map((anchors) =>
      this.workflow.getPreviousWorkItemPath(anchors.state).map((backPath) => {
        const renderHooks: AnyRenderHooks<WorkflowFeedback> = {
          onComplete: (result) => this.config.complete(backPath.state.path, result),
          onApply: (result) => this.config.apply(backPath.state.path, result),
          onClear: () => this.config.clear(backPath.state.path),
          onFeedback: (feedback) => this.config.processFeedback(backPath.state.path, feedback),
          onNavigateTo: (path) => this.config.navigateTo(path),
          onNavigateBack: backPath.result && (() => backPath.result && this.config.navigateTo(backPath.result)),
        };
        return this.workflow.renderCurrentWorkItem(backPath.state, renderHooks).map((renderedWorkItem) =>
          this.workflow.fireSideEffects(renderedWorkItem.state).map(() => [
            {
              anchors: anchors.result.map((anchor) => ({
                ...anchor,
                onClick: () => this.config.navigateTo(anchor.path)
              })),
              renderedWorkItem: renderedWorkItem.result,
              navigateTo: this.config.navigateTo
            },
            renderedWorkItem.state
          ])
        );
      })
    );
  }

  protected freezeState(
    workflowState: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    return this.buildChildrenConfig(workflowState).map(([childrenConfig, newWorkflowState]) => {
      this.listener.activateSubjects(newWorkflowState.workItemContexts.keys());
      return new ControlledWorkflowState(this.config, newWorkflowState, this.sideEffects, childrenConfig);
    });
  }

  protected applyUpdates(
    events: List<Listener.Event<any>>
  ): FastPromise<ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    return this.freezeState(
      this.workflowState.copy({
        workItemContexts: this.workflowState.workItemContexts.applyUpdates(events)
      })
    );
  }
}

export namespace ControlledWorkflowStateDefs {
  export interface Config<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    > {
    readonly workflow: Workflow<WorkflowContext, WorkflowFeedback, WorkflowResult>;
    readonly listener: Listener<any>;

    readonly complete: (path: Path | string, result: any) => void;
    readonly apply: (path: Path | string, result: any) => void;
    readonly clear: (path: Path | string) => void;
    readonly processFeedback: (path: Path | string, feedback: WorkflowFeedback) => void;
    readonly navigateTo: (path: Path | string) => void;
  }

  export interface CalculatedChildrenConfig<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    > {
    anchors: List<ClickableAnchor>;
    renderedWorkItem: React.ReactElement | undefined;
    navigateTo: (path: string) => void;
  }

  export enum ActionType {
    Initialize = "Initialize",
    ChangeWorkflowContext = "ChangeWorkflowContext",
    Navigate = "Navigate",
    Complete = "Complete",
    Apply = "Apply",
    Clear = "Clear",
    ProcessFeedback = "ProcessFeedback",
    ApplyUpdates = "ApplyUpdates",
  }

  export interface ActionLike<T extends ActionType, Self extends ActionLike<T, Self>> {
    type: T;
    merge: (newAction: Self, prevAction: Self) => Self | undefined;
  }

  export interface InitializeAction extends ActionLike<ActionType.Initialize, InitializeAction> {
    path: Path;
  }

  export function InitializeAction(path: Path | string): InitializeAction {
    return {
      type: ActionType.Initialize,
      path: Path.parse(path),
      merge: () => undefined
    };
  }

  export interface ChangeWorkflowContextAction<WorkflowContext extends WorkflowContextLike>
    extends ActionLike<ActionType.ChangeWorkflowContext, ChangeWorkflowContextAction<WorkflowContext>> {
    context: WorkflowContext;
  }

  export function ChangeWorkflowContextAction<WorkflowContext extends WorkflowContextLike>(
    context: WorkflowContext
  ): ChangeWorkflowContextAction<WorkflowContext> {
    return {
      type: ActionType.ChangeWorkflowContext,
      context,
      merge: (newAction) => newAction
    };
  }

  export interface NavigateAction extends ActionLike<ActionType.Navigate, NavigateAction> {
    path: Path;
  }

  export function NavigateAction(path: Path | string): NavigateAction {
    return {
      type: ActionType.Navigate,
      path: Path.parse(path),
      merge: (newAction) => newAction
    };
  }

  export interface CompleteAction extends ActionLike<ActionType.Complete, CompleteAction> {
    path: Path;
    result: any;
  }

  export function CompleteAction(path: Path | string, result: any): CompleteAction {
    return {
      type: ActionType.Complete,
      path: Path.parse(path),
      result,
      merge: (newAction, prevAction) => newAction.path.equals(prevAction.path) ? newAction : undefined
    };
  }

  export interface ApplyAction extends ActionLike<ActionType.Apply, ApplyAction> {
    path: Path;
    result: any;
  }

  export function ApplyAction(path: Path | string, result: any): ApplyAction {
    return {
      type: ActionType.Apply,
      path: Path.parse(path),
      result,
      merge: (newAction, prevAction) => newAction.path.equals(prevAction.path) ? newAction : undefined
    };
  }

  export interface ClearAction extends ActionLike<ActionType.Clear, ClearAction> {
    path: Path;
  }

  export function ClearAction(path: Path | string): ClearAction {
    return {
      type: ActionType.Clear,
      path: Path.parse(path),
      merge: (newAction, prevAction) => newAction.path.equals(prevAction.path) ? newAction : undefined
    };
  }

  export interface ProcessFeedbackAction<WorkflowFeedback>
    extends ActionLike<ActionType.ProcessFeedback, ProcessFeedbackAction<WorkflowFeedback>> {
    path: Path;
    feedback: WorkflowFeedback;
  }

  export function ProcessFeedbackAction<WorkflowFeedback>(
    path: Path | string,
    feedback: WorkflowFeedback
  ): ProcessFeedbackAction<WorkflowFeedback> {
    return {
      type: ActionType.ProcessFeedback,
      path: Path.parse(path),
      feedback,
      merge: (newAction, prevAction) => newAction.path.equals(prevAction.path) ? newAction : undefined
    };
  }

  export interface ApplyUpdatesAction extends ActionLike<ActionType.ApplyUpdates, ApplyUpdatesAction> {
    events: List<Listener.Event<any>>;
  }

  export function ApplyUpdatesAction(events: List<Listener.Event<any>>): ApplyUpdatesAction {
    return {
      type: ActionType.ApplyUpdates,
      events,
      merge: (newAction, prevAction) => {
        const newActionSubjects = newAction.events.map((action) => action.subject).toSet();
        return {
          ...newAction,
          events: newAction.events.concat(
            prevAction.events.filterNot((action) => newActionSubjects.has(action.subject))
          )
        };
      }
    };
  }

  export type Action<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    > =
    InitializeAction |
    ChangeWorkflowContextAction<WorkflowContext> |
    NavigateAction |
    CompleteAction |
    ApplyAction |
    ClearAction |
    ProcessFeedbackAction<WorkflowFeedback> |
    ApplyUpdatesAction;

  export function isCriticalAction<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >(
    action: Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): boolean {
    return action.type === ActionType.ChangeWorkflowContext;
  }

  export function enqueue<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >(
    actions: List<Action<WorkflowContext, WorkflowFeedback, WorkflowResult>>,
    newAction: Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): List<Action<WorkflowContext, WorkflowFeedback, WorkflowResult>> {
    const mergedActions = actions.filter((prevAction) =>
      prevAction.type === newAction.type && !!newAction.merge(newAction as any, prevAction as any)
    );
    if (!mergedActions.isEmpty()) {
      // console.warn("Will merge " + mergedActions.size + " action(s) fof type " + newAction.type);
    }
    return actions
      .filterNot((action) => mergedActions.contains(action))
      .push(mergedActions.reduce(
        (reduced, next) => (reduced.merge(reduced as any, next as any) || next) as any,
        newAction
      ));
  }

  export enum SideEffectType {
    CompleteWorkflow = "CompleteWorkflow"
  }

  interface SideEffectLike<T extends SideEffectType> {
    type: T;
  }

  export interface CompleteWorkflowSideEffect<WorkflowResult> extends SideEffectLike<SideEffectType.CompleteWorkflow> {
    result: WorkflowResult;
  }

  export function CompleteWorkflowSideEffect<WorkflowResult>(
    result: WorkflowResult
  ): CompleteWorkflowSideEffect<WorkflowResult> {
    return { type: SideEffectType.CompleteWorkflow, result };
  }

  export type SideEffect<WorkflowResult> = CompleteWorkflowSideEffect<WorkflowResult>;
}
