import * as React from "react";
import * as Color from "color";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { FormErrorMessage } from "./formErrorMessage";
import { FormikProps } from "formik/dist/types";
// tslint:disable-next-line import-name
import ReactDatePicker, { CalendarContainerProps, ReactDatePickerProps } from "react-datepicker";
import { css, styled } from "../../app/theme";

interface DatePickerProps {
  error?: boolean;
  maxWidth?: number;
  readOnly?: boolean;
}

export const DatePicker = styled(ReactDatePicker)<DatePickerProps>`
  height: 2rem;
  padding: 0 0.25rem;
  border: 2px solid ${(props) => props.error ? props.theme.colors.red : props.theme.colors.lightGray};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  max-width: ${(props) => props.maxWidth ? props.maxWidth + "rem" : "inherit"};
  ${(props) => props.theme.animation.transitionAll()};

  ${(props) => !props.readOnly && css`
    &.focus, &:focus {
      border: 2px solid ${props.error ? props.theme.colors.red : props.theme.colors.primary};
      box-shadow: 0 0 0.2rem ${props.error ? props.theme.colors.red : props.theme.colors.primary};
    }
  `}
  
  &[disabled] {
    background: ${(props) => props.theme.colors.lighterGray};
    color: ${(props) => props.theme.colors.darkGray};
  }
`;

// tslint:disable max-line-length
const StyledCalendarContainer = styled.div<CalendarContainerProps>`
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__close-icon::after {
    background: ${(props) => props.theme.colors.primary};
  }

  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected, 
  .react-datepicker__year-text--keyboard-selected {
    background: ${(props) => props.theme.colors.lightPrimary};
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: ${(props) => new Color(props.theme.colors.primary).opaquer(.5).hex()};
  }  
`;
// tslint:enable max-line-length

interface DateFieldProps<T> extends Omit<ReactDatePickerProps, "calendarContainer" | "name" | "onChange"> {
  label: string;
  name: keyof T;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxWidth?: number;
  onChange?: (event: React.ChangeEvent<any>, form: FormikProps<T>) => void;
}

// tslint:disable-next-line function-name
export function DateField<V>(props: DateFieldProps<V>) {
  const { label, name, required, readOnly, disabled, maxWidth, onChange, ...rest } = props;

  return (
    <Field
      name={name}
      render={(field: FieldProps<V>) => {
        const value = field.form.values[name] as unknown as Date | undefined;
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[name];

        return (
          <FormField label={label} required={required} error={error} limitWidth={true}>
            <DatePicker
              {...rest}
              calendarContainer={StyledCalendarContainer}
              selected={value}
              error={error}
              disabled={disabled}
              readOnly={readOnly}
              maxWidth={maxWidth}
              onChange={(date) => {
                field.form.setFieldError(field.field.name, "");
                field.form.setFieldValue(field.field.name, date);
              }}
            />
            <ErrorMessage name={name as string} component={FormErrorMessage}/>
          </FormField>
        );
      }}
    />
  );
}
