import { CellContent, ColumnConfig } from "./columnConfig";
import { List } from "immutable";
import * as React from "react";
import { Grid } from "../../widgets/grid";

interface ItemGridProps<Id, Item> {
  gridColumns: ColumnConfig<Item>[];
  getItemId: (item: Item) => Id;
  items: List<Item>;
  onSelect: (id: Id, item: Item) => void;
}

export function ItemGrid<Id, Item>(props: ItemGridProps<Id, Item>): React.ReactElement {
  function renderCell(index: number, column: ColumnConfig<Item>, item: Item): React.ReactElement {
    const value = column.render(item);
    if (CellContent.isCellContent(value)) {
      return <Grid.Cell key={index} status={value.status}>{value.content}</Grid.Cell>;
    } else {
      return <Grid.Cell key={index}>{value}</Grid.Cell>;
    }
  }

  return (
    <Grid selectable={true}>
      <Grid.Header>
        {
          props.gridColumns.map((column, index) =>
            <Grid.Column key={index}>{column.title}</Grid.Column>
          )
        }
      </Grid.Header>
      <Grid.Body>
        {
          props.items.map((item) => (
            <Grid.Row key={"" + props.getItemId(item)} onClick={() => props.onSelect(props.getItemId(item), item)}>
              {props.gridColumns.map((column, index) => renderCell(index, column, item))}
            </Grid.Row>
          ))
        }
      </Grid.Body>
    </Grid>
  );
}
