import * as React from "react";
import {
  useAdvanceToNextEmailDeliveryRoundMutation
} from "../../../queries/automatedOffboarding/useAdvanceToNextEmailDeliveryRoundMutation";
import {
  AdvanceToNextEmailDeliveryRoundViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/advanceToNextEmailDeliveryRoundController";
import { ControllerProps } from "../../../utils/controller";

interface AdvanceToNextEmailDeliveryRoundToolControllerProps
  extends ControllerProps<AdvanceToNextEmailDeliveryRoundViewProps> {
  offboardingProjectId: string;
}

const AdvanceToNextEmailDeliveryRoundToolController
  : React.FunctionComponent<AdvanceToNextEmailDeliveryRoundToolControllerProps> =
  (props) => {
    const [advance, status] = useAdvanceToNextEmailDeliveryRoundMutation(props.offboardingProjectId);

    return props.render({
      status,
      onSubmit: advance
    });
  };

export function useAdvanceToNextEmailDeliveryRoundController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<AdvanceToNextEmailDeliveryRoundViewProps>) => (
      <AdvanceToNextEmailDeliveryRoundToolController
        offboardingProjectId={offboardingProjectId}
        {...controllerProps}
      />
    ),
    [offboardingProjectId]
  );
}
