import { Constants } from "../app/constants";

export function makeAmbassadorLink(ambassadorCode: string, url: string): string {
  try {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.append(Constants.QueryParams.AmbassadorCode, ambassadorCode);
    return parsedUrl.toString();
  } catch {
    return "(Invalid URL)";
  }
}
