import * as React from "react";
import { ControllerProps } from "../../utils/controller";
import { ChatGPTLookupViewProps } from "../../controllers/schoolAdmin/chatGPTLookupController";
import { useMakeSchoolLookupPromptMutation } from "../../queries/chatGPT/useMakeSchoolLookupPromptMutation";
import { useGenerateJsonUsingChatGPTMutation } from "../../queries/chatGPT/useGenerateJsonUsingChatGPTMutation";

const DefaultChatGPTLookupController: React.FunctionComponent<ControllerProps<ChatGPTLookupViewProps>> = (props) => {
  const [makePrompt, promptStatus] = useMakeSchoolLookupPromptMutation();
  const [lookup, lookupStatus] = useGenerateJsonUsingChatGPTMutation();

  return props.render({
    makePrompt,
    promptStatus,
    lookup,
    lookupStatus
  });
};

export function useChatGPTLookupController() {
  return React.useCallback(
    (controllerProps: ControllerProps<ChatGPTLookupViewProps>) =>
      <DefaultChatGPTLookupController {...controllerProps}/>,
    []
  );
}
