import * as React from "react";
import { useGetOrganizationContextQuery } from "../../queries/organizations/useGetOrganizationContextQuery";
import { useRoutes } from "../../app/routes/useRoutes";

export const OrganizationPortalTitle: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.organizationPortalRoutes.params();

  const [status] = useGetOrganizationContextQuery(params.organizationIdOrAlias);
  const organizationName = status.someResult()?.organization.name;

  React.useEffect(
    () => {
      if (organizationName) {
        document.title = organizationName + " - EDU Admin Portal - VaultMe";
        return () => {
          document.title = "VaultMe";
        };
      }
    },
    [organizationName]
  );

  return null;
};
