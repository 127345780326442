import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Migration } from "../../types/models/migration";

type AbortMigrationHook = [
  (authorizationAction: GraphQL.AuthorizationAction) => Promise<Migration>,
  OperationStatus<Migration>
];

export function useAbortMigration(migrationId: string): AbortMigrationHook {
  const [fireWith, { status }] = useManagedMutation({
    mutation: GraphQL.useAbortMigrationMutation,
    extract: (data: GraphQL.AbortMigrationMutation) => nullToUndefined(data.abortMigration),
    complete: Migration.parseCore
  });

  function fire(authorizationAction: GraphQL.AuthorizationAction): Promise<Migration> {
    return fireWith({
      variables: { migrationId, authorizationAction },
      retry: () => fire(authorizationAction),
    });
  }

  return [fire, status];
}
