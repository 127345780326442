import * as React from "react";
import { useAppBootstrapConfig } from "../app/configuration";
import { ReferralCodeSummary } from "../types/models/referralCode";
import { useRoutes } from "../app/routes/useRoutes";
import { Announcement, Beacon } from "../views/blocks/referralCodeIndication";

export const ReferralCodeIndication: React.FunctionComponent = () => {
  const config = useAppBootstrapConfig();

  if (config.referralCode?.beacon.enabled && config.referralCode?.beacon.logoId) {
    return (
      <ReferralCodeIndicationBody
        beaconLogoId={config.referralCode.beacon.logoId}
        beaconConfig={config.referralCode.beacon}
        announcement={config.referralCode.announcement}
      />
    );
  } else {
    return null;
  }
};

interface ReferralCodeIndicationBodyProps {
  beaconLogoId: string;
  beaconConfig: ReferralCodeSummary.Beacon;
  announcement: ReferralCodeSummary.Announcement;
}

const ReferralCodeIndicationBody: React.FunctionComponent<ReferralCodeIndicationBodyProps> = (props) => {
  const routes = useRoutes();

  const announcementEnabled = props.announcement.enabled && !!props.announcement.bannerOrganizationLogoId;
  const [isAnnouncementOpen, setAnnouncementOpen] = React.useState(false);

  if (announcementEnabled && isAnnouncementOpen) {
    return (
      <Announcement
        announcementBannerOrganizationLogoId={props.announcement.bannerOrganizationLogoId || ""}
        announcementConfig={props.announcement}
        onClose={() => setAnnouncementOpen(false)}
      />
    );
  } else {
    return (
      <Beacon
        src={routes.api.downloadUrl(props.beaconLogoId)}
        width={props.beaconConfig.logoWidth}
        isClickable={announcementEnabled}
        onClick={() => {
          if (announcementEnabled) {
            setAnnouncementOpen(true);
          }
        }}
      />
    );
  }
};
