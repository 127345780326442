import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchOrganizationPortalUsersQuery(
  organizationId: number | undefined,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchOrganizationPortalUsersQueryVariables, List<OrganizationPortalUser>> {
  return useManagedQuery({
    query: GraphQL.useSearchOrganizationPortalUsersQuery,
    deps: organizationId !== undefined && term !== undefined ? { organizationId, term } : undefined,
    prepare: (deps) => ({ organizationId: deps.organizationId, term: deps.term }),
    extract: (data: GraphQL.SearchOrganizationPortalUsersQuery) => nullToUndefined(data.searchOrganizationPortalUsers),
    complete: (organizationPortalUsers) => List(organizationPortalUsers)
      .filter((organizationPortalUser) => organizationPortalUser.id >= 0)
      .map(OrganizationPortalUser.parse),
    fetchPolicy: "cache-and-network"
  });
}
