import * as React from "react";
import { styled } from "../../../app/theme";
import { CloudServiceButton } from "./cloudServiceButton";
import { CloudService } from "../../../types/models/cloudService";
import { List } from "immutable";

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface CloudServiceListProps {
  cloudServices: List<CloudService>;
  onCloudServiceSelect: (cloudServiceId: string) => void;
}

export const CloudServiceButtonList: React.FunctionComponent<CloudServiceListProps> = (props) => (
  <Layout>
    {props.cloudServices.map((cloudService) => (
        <CloudServiceButton
          key={cloudService.id}
          cloudService={cloudService}
          onClick={() => props.onCloudServiceSelect(cloudService.id)}
        />
      )
    )}
  </Layout>
);
