import * as React from "react";
import { CRUDConfig } from "./crudConfig";
import { OverviewItemPageView } from "../../views/blocks/crud/overviewItemPageView";

interface Props<Id, Item, FormData> {
  config: CRUDConfig<Id, Item, FormData>;
  id: Id;

  homePath: string;
  editPath: string;

  onClone: (item: Item) => void;
  onDelete: () => void;
}

export function OverviewItemPage<Id, Item, FormData>(props: Props<Id, Item, FormData>): React.ReactElement {
  const [loadStatus, refresh] = props.config.useGetQuery(props.id);
  const [deleteItem, deleteStatus] = props.config.useDeleteMutation();

  const item = loadStatus.someResult();
  const autoRefreshInterval = item && props.config.autoRefreshInterval ? props.config.autoRefreshInterval(item) : false;

  React.useEffect(
    () => {
      if (autoRefreshInterval) {
        const timer = setInterval(refresh, autoRefreshInterval * 1000);
        return () => clearInterval(timer);
      }
    },
    [autoRefreshInterval]
  );

  if (props.config.renderOverview) {
    return (
      <OverviewItemPageView
        breadcrumbs={props.config.breadcrumbs}
        title={props.config.title}
        subject={props.config.subject}
        readOnly={!!props.config.readOnly}
        renderOverview={props.config.renderOverview}

        getItemTitle={props.config.getItemTitle}
        getItemDescription={props.config.getItemDescription}

        id={props.id}
        homePath={props.homePath}
        editPath={props.editPath}

        loadStatus={loadStatus}
        deleteStatus={deleteStatus}

        onRefresh={refresh}
        onClone={props.onClone}
        onDelete={() => deleteItem(props.id).then(props.onDelete)}
      />
    );
  } else {
    return <div/>;
  }
}
