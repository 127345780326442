import moment from "moment";

export enum ActivityTimeUnit {
  Hour = "Hour",
  Day = "Day",
  Month = "Month",
  Year = "Year"
}

export namespace ActivityTimeUnit {
  export function momentUnitOfTime(timeUnit: ActivityTimeUnit): moment.unitOfTime.Base {
    switch (timeUnit) {
      case ActivityTimeUnit.Hour: return "hour";
      case ActivityTimeUnit.Day: return "day";
      case ActivityTimeUnit.Month: return "month";
      case ActivityTimeUnit.Year: return "year";
    }
  }
}
