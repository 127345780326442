/* tslint:disable:jsdoc-format */

import { None, Option, Some } from "./option";

export abstract class Either<A, B> {
  public abstract match<C>(options: Either.Match<A, B, C>): C;

  /** Applies `fa` if this is a `Left` or `fb` if this is a `Right`.
   *
   *  @example {{{
   *  val result = util.Try("42".toInt).toEither
   *  result.fold(
   *    e => s"Operation failed with $e",
   *    v => s"Operation produced value: $v"
   *  )
   *  }}}
   *
   *  @param fa the function to apply if this is a `Left`
   *  @param fb the function to apply if this is a `Right`
   *  @return the results of applying the function
   */
  public fold<C>(fa: (a: A) => C, fb: (b: B) => C): C {
    return this.match({ left: fa, right: fb });
  }

  /** If this is a `Left`, then return the left value in `Right` or vice versa.
   *
   *  @example {{{
   *  val left: Either[String, Int]  = Left("left")
   *  val right: Either[Int, String] = left.swap // Result: Right("left")
   *  }}}
   *  @example {{{
   *  val right = Right(2)
   *  val left  = Left(3)
   *  for {
   *    r1 <- right
   *    r2 <- left.swap
   *  } yield r1 * r2 // Right(6)
   *  }}}
   */
  public swap(): Either<B, A> {
    return this.match<Either<B, A>>({
      left: (a) => Right<B, A>(a),
      right: (b) => Left<B, A>(b)
    });
  }

  /** Executes the given side-effecting function if this is a `Right`.
   *
   *  {{{
   *  Right(12).foreach(println) // prints "12"
   *  Left(12).foreach(println)  // doesn't print
   *  }}}
   *  @param f The side-effecting function to execute.
   */
  public foreach<U>(f: (b: B) => U): void {
    this.match({
      right: (b) => f(b),
      left: () => {
        // Do nothing
      }
    });
  }

  /** Returns the value from this `Right` or the given argument if this is a `Left`.
   *
   *  {{{
   *  Right(12).getOrElse(17) // 12
   *  Left(12).getOrElse(17)  // 17
   *  }}}
   */
  public getOrElse<B1>(or: () => B1): B | B1 {
    return this.match<B | B1>({
      right: (b) => b,
      left: () => or()
    });
  }

  /** Returns `true` if this is a `Right` and its value is equal to `elem` (as determined by `==`),
   *  returns `false` otherwise.
   *
   *  {{{
   *  // Returns true because value of Right is "something" which equals "something".
   *  Right("something") contains "something"
   *
   *  // Returns false because value of Right is "something" which does not equal "anything".
   *  Right("something") contains "anything"
   *
   *  // Returns false because it's not a Right value.
   *  Left("something") contains "something"
   *  }}}
   *
   *  @param elem    the element to test.
   *  @return `true` if this is a `Right` value equal to `elem`.
   */
  public contains(elem: B): boolean {
    return this.match({
      right: (b) => b === elem,
      left: () => false
    });
  }

  /** Returns `true` if `Left` or returns the result of the application of
   *  the given predicate to the `Right` value.
   *
   *  {{{
   *  Right(12).forall(_ > 10)    // true
   *  Right(7).forall(_ > 10)     // false
   *  Left(12).forall(_ => false) // true
   *  }}}
   */
  public forall(f: (b: B) => boolean): boolean {
    return this.match({
      right: (b) => f(b),
      left: () => true
    });
  }

  /** Returns `false` if `Left` or returns the result of the application of
   *  the given predicate to the `Right` value.
   *
   *  {{{
   *  Right(12).exists(_ > 10)   // true
   *  Right(7).exists(_ > 10)    // false
   *  Left(12).exists(_ => true) // false
   *  }}}
   */
  public exists(p: (b: B) => boolean): boolean {
    return this.match({
      right: (b) => p(b),
      left: () => false
    });
  }

  /** Binds the given function across `Right`.
   *
   *  @param f The function to bind across `Right`.
   */
  public flatMap<A1, B1>(f: (b: B) => Either<A1, B1>): Either<A, B> | Either<A1, B1> {
    return this.match<Either<A, B> | Either<A1, B1>>({
      right: (b) => f(b),
      left: () => this
    });
  }

  /** The given function is applied if this is a `Right`.
   *
   *  {{{
   *  Right(12).map(x => "flower") // Result: Right("flower")
   *  Left(12).map(x => "flower")  // Result: Left(12)
   *  }}}
   */
  public map<B1>(f: (b: B) => B1): Either<A, B> | Either<A, B1> {
    return this.match<Either<A, B> | Either<A, B1>>({
      right: (b) => Right<A, B1>(f(b)),
      left: () => this
    });
  }

  /** Returns `Right` with the existing value of `Right` if this is a `Right`
   *  and the given predicate `p` holds for the right value,
   *  or `Left(zero)` if this is a `Right` and the given predicate `p` does not hold for the right value,
   *  or `Left` with the existing value of `Left` if this is a `Left`.
   *
   * {{{
   * Right(12).filterOrElse(_ > 10, -1)   // Right(12)
   * Right(7).filterOrElse(_ > 10, -1)    // Left(-1)
   * Left(7).filterOrElse(_ => false, -1) // Left(7)
   * }}}
   */
  public filterOrElse<A1>(p: (b: B) => boolean, zero: () => A1): Either<A, B> | Either<A1, B> {
    return this.match<Either<A, B> | Either<A1, B>>({
      right: (b) => !p(b) ? Left<A1, B>(zero()) : this,
      left: () => this
    });
  }

  /** Returns a `Some` containing the `Right` value
   *  if it exists or a `None` if this is a `Left`.
   *
   * {{{
   * Right(12).toOption // Some(12)
   * Left(12).toOption  // None
   * }}}
   */
  public toOption(): Option<B> {
    return this.match({
      right: (b) => Some(b),
      left: () => None()
    });
  }

  public abstract isLeft(): boolean;

  public abstract isRight(): boolean;
}

export function Left<A, B>(value: A): Either.LeftImpl<A, B> {
  return new Either.LeftImpl(value);
}

export function Right<A, B>(value: B): Either.RightImpl<A, B> {
  return new Either.RightImpl(value);
}

export namespace Either {
  export interface Match<A, B, C> {
    left: (a: A) => C;
    right: (b: B) => C;
  }

  export class LeftImpl<A, B> extends Either<A, B> {
    constructor(private value: A) {
      super();
    }

    public match<C>(options: Either.Match<A, B, C>): C {
      return options.left(this.value);
    }

    public isLeft(): boolean {
      return true;
    }

    public isRight(): boolean {
      return false;
    }

    public toJSON(): any {
      return {
        type: "Left",
        value: this.value
      };
    }
  }

  export class RightImpl<A, B> extends Either<A, B> {
    constructor(private value: B) {
      super();
    }

    public match<C>(options: Either.Match<A, B, C>): C {
      return options.right(this.value);
    }

    public isLeft(): boolean {
      return false;
    }

    public isRight(): boolean {
      return true;
    }

    public toJSON(): any {
      return {
        type: "Right",
        value: this.value
      };
    }
  }
}
