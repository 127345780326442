import * as React from "react";
import { OperationStatus } from "../../../../../types/operationStatus";
import { DrawerBlock } from "../../../../containers/drawerBlock";
import { Panel } from "../../../../containers/panel";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { Paragraph } from "../../../../widgets/paragraph";
import { ErrorInfoRow } from "../../../../containers/rows/errorInfoRow";
import { UserFacingError } from "../../../../../types/userFacingError";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { IndefiniteProgressBar } from "../../../../widgets/indefiniteProgressBar";
import { AmbiguousEmailAddressesError } from "./ambiguousEmailAddressesError";
import { MissingEmailAddressesError } from "./missingEmailAddressesError";
import { TextArea } from "../../../../widgets/textArea";
import { Constants } from "../../../../../app/constants";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { SimpleToolbar } from "../../../../widgets/simpleToolbar";
import { Button } from "../../../../widgets/button";
import { styled } from "../../../../../app/theme";
import { List } from "immutable";
import { Connection } from "../../../../../types/models/connection";
import { EmbeddedHelpArticle } from "./embeddedHelpArticle";
import { WrongCloudServiceError } from "./wrongCloudServiceError";
import { CloudService } from "../../../../../types/models/cloudService";
import { SingleAccountHint } from "./singleAccountHint";
import { NotAccessibleAccountsError } from "./notAccessibleAccountsError";

const Example = styled.pre`
  border: 1px solid #e0e0e0;
  margin: .5rem 0 1rem;
  font-size: 0.9rem;
  padding: 0.5rem;
`;

const exampleText =
  "name1@source.com name1@destination.com\n" +
  "name2@source.com name2@destination.com";

const Spreadsheet = styled.table`
  margin-top: 1rem;
  
  td, th {
    padding: 0.3rem;
    text-align: left;
    border: 1px solid #C0C0C0;
    
    &:not(:first-child) {
      border-left: 0;
    }
  }
  
  tbody > tr {
    > td, > th {
      border-top: 0;
    }
  }
  
  th {
    background: #F8F9FA;    
    text-align: center;
    font-weight: normal;
  
    &:first-child {
      width: 2rem;
    }
  }
`;

const StyledSingleAccountHint = styled(SingleAccountHint)`
  margin-bottom: 1rem;
`;

namespace UnresolvedEmailAddresses {
  export interface Segment {
    notAccessible: List<string>;
    wrongCloudService: List<Connection>;
    ambiguous: List<string>;
    missing: List<string>;
  }
}

interface UnresolvedEmailAddresses {
  submitted: number;
  source: UnresolvedEmailAddresses.Segment;
  destination: UnresolvedEmailAddresses.Segment;
}

export interface AddMultipleRowsControllerProps {
  render: (
    status: OperationStatus<any>,
    onSubmit: (value: string) => Promise<UnresolvedEmailAddresses | undefined>
  ) => React.ReactElement;
}

interface AddMultipleRowsFormProps {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  sourceConnectionMethod: "admin" | "end-user" | "self";
  destinationConnectionMethod: "admin" | "end-user" | "self";

  sourceConnectLink: string;
  destinationConnectLink: string;

  isEmpty: boolean;
  disabled: boolean;
  status: OperationStatus<any>;

  onSubmit: (value: string) => Promise<UnresolvedEmailAddresses | undefined>;
  onCancel: () => void;
}

export const AddMultipleRowsForm: React.FunctionComponent<AddMultipleRowsFormProps> = (props) => {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const [note, setNote] = React.useState("");
  const [unresolvedEmailAddresses, setUnresolvedEmailAddresses] = React.useState<UnresolvedEmailAddresses>();
  const [validationError, setValidationError] = React.useState<{ message: string, submitted: number }>();

  React.useEffect(
    () => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    },
    []
  );

  return (
    <>
      <DrawerBlock>
        <Panel>
          <PanelRow>
            <Paragraph>
              Type or paste the pairs of the source email address and the corresponding destination email address
              below. Each pair of the email addresses must be separated by a tab or a space and added as a new row.
            </Paragraph>
            <Paragraph>
              Example:
            </Paragraph>
            <Example>{exampleText}</Example>
            <Paragraph>
              <EmbeddedHelpArticle title={"How do I prepare this list?"}>
                <Paragraph>
                  If you only need to migrate a few accounts, the easiest way is to type the source email addresses and
                  the corresponding destination email addresses directly into the field below.
                </Paragraph>
                <Paragraph>
                  For larger migrations, it's recommended to make a simple spreadsheet with two columns as shown
                  below. The first column should list the source email addresses, and the second should list the
                  corresponding destination email addresses. Once the spreadsheet is ready, you should be able to
                  select a range of cells, copy them, paste into the field below, and submit without any additional
                  edits.
                </Paragraph>
                <Spreadsheet>
                  <thead>
                  <tr>
                    <th/>
                    <th>A</th>
                    <th>B</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>1</th>
                    <td>name1@source.com</td>
                    <td>name1@destination.com</td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>name2@source.com</td>
                    <td>name2@destination.com</td>
                  </tr>
                  </tbody>
                </Spreadsheet>
              </EmbeddedHelpArticle>
            </Paragraph>
          </PanelRow>

          {props.disabled
            ? (
              <ErrorInfoRow
                error={UserFacingError.synthetic({
                  title: "Oops! That will not work.",
                  summary: "No more account pairs can be added to this batch."
                })}
              />
            )
            : (
              <>
                <OperationStatusIndicator
                  progressMessage={"Processing..."}
                  status={props.status}
                  indicators={StatusIndicators.PanelRow()}
                />
                {validationError && (
                  <ErrorInfoRow
                    error={UserFacingError.synthetic({
                      title: "Oops! That did not work.",
                      summary: validationError.message
                    })}
                  />
                )}
                {props.status.isSuccess() && unresolvedEmailAddresses && (
                  <>
                    <IndefiniteProgressBar error={true}/>
                    <UnresolvedEmailAddressesInfo
                      isEmpty={props.isEmpty}
                      source={true}
                      cloudService={props.sourceCloudService}
                      connectionMethod={props.sourceConnectionMethod}
                      connectLink={props.sourceConnectLink}
                      segment={unresolvedEmailAddresses.source}
                    />
                    <UnresolvedEmailAddressesInfo
                      isEmpty={props.isEmpty}
                      source={false}
                      cloudService={props.destinationCloudService}
                      connectionMethod={props.destinationConnectionMethod}
                      connectLink={props.destinationConnectLink}
                      segment={unresolvedEmailAddresses.destination}
                    />
                  </>
                )}
              </>
            )
          }

          {!props.disabled && (
            <PanelRow>
              {
                props.isEmpty && (
                  validationError && validationError.submitted === 1 ||
                  props.status.isSuccess() && unresolvedEmailAddresses && unresolvedEmailAddresses.submitted === 1
                ) && (
                  <StyledSingleAccountHint
                    adding={true}
                    sourceCloudService={props.sourceCloudService}
                    destinationCloudService={props.destinationCloudService}
                    item={undefined}
                  />
                )
              }
              <TextArea
                ref={textAreaRef}
                value={note}
                rows={10}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value)}
                disabled={props.status.isWorking()}
              />
            </PanelRow>
          )}

          <PanelRow>
            Note: you can add up to {Constants.MaxBatchSize} account pairs to one batch. If you wish to migrate more
            accounts, please create multiple batches or contact us for assistance at <EmailSupportLink/>.
          </PanelRow>
        </Panel>
      </DrawerBlock>

      {!props.disabled && (
        <DrawerBlock>
          <SimpleToolbar>
            <Button
              onClick={() => {
                setUnresolvedEmailAddresses(undefined);
                setValidationError(undefined);
                props.onSubmit(note)
                  .then(setUnresolvedEmailAddresses)
                  .catch(setValidationError);
              }}
              disabled={props.status.isWorking() || note.trim().length === 0}
            >
              Add
            </Button>
            <Button color={"white"} onClick={props.onCancel}>
              Cancel
            </Button>
          </SimpleToolbar>
        </DrawerBlock>
      )}
    </>
  );
};

interface UnresolvedEmailAddressesInfoProps {
  isEmpty: boolean;
  source: boolean;
  cloudService: CloudService;
  connectionMethod: "admin" | "end-user" | "self";
  connectLink: string;
  segment: UnresolvedEmailAddresses.Segment;
}

const UnresolvedEmailAddressesInfo: React.FunctionComponent<UnresolvedEmailAddressesInfoProps> = (props) => (
  <>
    {!props.segment.notAccessible.isEmpty() && (
      <NotAccessibleAccountsError
        source={props.source}
        connectionMethod={props.connectionMethod}
        connectLink={props.connectLink}
        emailAddresses={props.segment.notAccessible}
      />
    )}
    {!props.segment.missing.isEmpty() && (
      <MissingEmailAddressesError
        source={props.source}
        connectionMethod={props.connectionMethod}
        cloudService={props.cloudService}
        connectLink={props.connectLink}
        emailAddresses={props.segment.missing}
      />
    )}
    {!props.segment.ambiguous.isEmpty() && (
      <AmbiguousEmailAddressesError
        source={props.source}
        emailAddresses={props.segment.ambiguous}
      />
    )}
    {!props.segment.wrongCloudService.isEmpty() && (
      <WrongCloudServiceError
        isEmpty={props.isEmpty}
        source={props.source}
        connectionMethod={props.connectionMethod}
        cloudService={props.cloudService}
        connections={props.segment.wrongCloudService}
      />
    )}
  </>
);
