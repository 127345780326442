import { ManagedQueryHook, useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { useWatcherDispatch } from "../services/watcher/useWatcher";
import { useApolloClient } from "@apollo/react-hooks";
import { WatchedFacts } from "../services/watcher/plugins/watchedFactsPlugin";
import { Fact } from "../types/facts/fact";
import { Facts } from "../types/facts/facts";
import { List, Set } from "immutable";
import { BlueprintInputs } from "../blueprints/blueprintInputs";

export interface BlueprintFactSpec {
  blueprintInputs: BlueprintInputs;
  factIds: Set<string>;
}

export function useBlueprintFacts(
  sourceCloudServiceId: string,
  destinationCloudServiceId: string,
  blueprintFactSpecs: List<BlueprintFactSpec>
): ManagedQueryHook<GraphQL.GetBlueprintFactsQueryVariables, Facts> {
  const watcher = useWatcherDispatch();
  const apolloClient = useApolloClient();

  const factIds = blueprintFactSpecs
    .map((spec) => spec.factIds)
    .reduce((a, b) => a.merge(b), Set<string>());

  watcher(WatchedFacts.WatchFactsAction(factIds));

  const missingFactIds = Fact.filterMissing(factIds, apolloClient);

  return useManagedQuery({
    query: GraphQL.useGetBlueprintFactsQuery,
    deps: null,
    prepare: () => ({
      sourceCloudServiceId,
      destinationCloudServiceId,
      blueprintFactSpecs: blueprintFactSpecs
        .map((spec) => ({
          blueprintInputs: spec.blueprintInputs.toGraphQL(),
          factIds: spec.factIds.toArray()
        }))
        .toArray()
    }),
    extract: (data: GraphQL.GetBlueprintFactsQuery) => data.getBlueprintFacts,
    complete: (facts) => Facts.fromGraphQLList(facts.facts),
    fetchPolicy: missingFactIds.isEmpty() ? undefined : "network-only"
  });
}
