import { CRUDRoutes } from "./crudRoutes";
import { CouponCode } from "../../types/models/couponCode";

export class CouponCodesRoutes extends CRUDRoutes<string, CouponCode> {
  protected readonly root: string = "coupon-codes";
  protected readonly send: string = this.root + "/send";

  protected parseId(s: string): string {
    return s;
  }

  public get sendMask(): string {
    return this.send;
  }

  public get sendPath(): string {
    return this.context.resolvePath(this.send);
  }
}
