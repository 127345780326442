import { styled } from "../../app/theme";
import { ReferralCodeSummary } from "../../types/models/referralCode";
import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { VaultMeLogo, VaultMeLogoColorSchema } from "../glyphs/vaultMeLogo";
import {
  ReferralCodeAnnouncementBannerVaultMeLogoVersion
} from "../../types/enums/referralCodeAnnouncementBannerVaultMeLogoVersion";
import { ReferralCodeAnnouncementButtonVersion } from "../../types/enums/referralCodeAnnouncementButtonVersion";
import { CloseModalButton } from "../widgets/closeModalButton";

const MobileCoeff = 0.833;

export const Beacon = styled.img<{ width: number, isClickable: boolean }>`
  display: block;
  width: ${(props) => props.width}rem;
  position: fixed;
  z-index: ${(props) => props.theme.layers.referralCodeIndication};
  bottom: 1rem;
  right: 1rem;
  cursor: ${(props) => props.isClickable ? "pointer" : "default"};

  ${(props) => props.theme.responsive.respondToSmall()} {
    width: ${(props) => props.width * MobileCoeff}rem;
  }
`;

interface AnnouncementProps {
  previewLayout?: boolean;
  announcementBannerOrganizationLogoId: string;
  announcementConfig: ReferralCodeSummary.Announcement;
  onClose: () => void;
}

export const Announcement: React.FunctionComponent<AnnouncementProps> = (props) => {
  const routes = useRoutes();

  function vaultMeLogoColorSchema(): VaultMeLogoColorSchema {
    switch (props.announcementConfig.bannerVaultMeLogoVersion) {
      case ReferralCodeAnnouncementBannerVaultMeLogoVersion.DarkBlueAndBrightBlue: return "black-and-blue";
      case ReferralCodeAnnouncementBannerVaultMeLogoVersion.White: return "white";
      case ReferralCodeAnnouncementBannerVaultMeLogoVersion.WhiteAndBrightBlue: return "white-and-blue";
    }
  }

  function actionButtonsColors(color: string): { backgroundColor: string, borderColor: string, textColor: string } {
    switch (props.announcementConfig.buttonVersion) {
      case ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndBlackText:
        return { backgroundColor: color, borderColor: color, textColor: "#000000" };
      case ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndWhiteText:
        return { backgroundColor: color, borderColor: color, textColor: "#FFFFFF" };
      case ReferralCodeAnnouncementButtonVersion.NoBackgroundAndBlackText:
        return { backgroundColor: "none", borderColor: color, textColor: "#000000" };
      case ReferralCodeAnnouncementButtonVersion.NoBackgroundAndColoredText:
        return { backgroundColor: "none", borderColor: color, textColor: color };
    }
  }

  return (
    <AnnouncementContainer onClick={props.onClose} splitScreen={!!props.previewLayout}>
      <AnnouncementPopup
        background={props.announcementConfig.bodyBackground}
        onClick={(event) => event.stopPropagation()}
      >
        <AnnouncementBanner background={props.announcementConfig.bannerBackground || "#242423"}>
          <AnnouncementCloseButton onClick={props.onClose}/>
          <AnnouncementBannerPrefix>
            <AnnouncementVaultMeLogo colorSchema={vaultMeLogoColorSchema()}/>
            <AnnouncementForWord>for</AnnouncementForWord>
          </AnnouncementBannerPrefix>
          <AnnouncementOrganizationLogo
            src={routes.api.downloadUrl(props.announcementBannerOrganizationLogoId)}
            width={props.announcementConfig.bannerOrganizationLogoWidth}
            offsetXRow={props.announcementConfig.bannerOrganizationLogoOffsetXRow}
            offsetYRow={props.announcementConfig.bannerOrganizationLogoOffsetYRow}
            offsetXColumn={props.announcementConfig.bannerOrganizationLogoOffsetXColumn}
            offsetYColumn={props.announcementConfig.bannerOrganizationLogoOffsetYColumn}
          />
        </AnnouncementBanner>
        <AnnouncementTitle background={props.announcementConfig.titleBackground}>
          {props.announcementConfig.title}
        </AnnouncementTitle>
        <AnnouncementBody desktop={true}>
          {
            props.announcementConfig.bodyDesktop.split("\n\n")
              .map((paragraph, index) => <p key={index}>{paragraph}</p>)
          }
        </AnnouncementBody>
        <AnnouncementBody desktop={false}>
          {
            (props.announcementConfig.bodyMobile || props.announcementConfig.bodyDesktop)
              .split("\n\n").map((paragraph, index) => <p key={index}>{paragraph}</p>)
          }
        </AnnouncementBody>
        <ActionButtonContainer>
          <ActionButton
            {...actionButtonsColors(props.announcementConfig.buttonColor)}
            onClick={props.onClose}
          >
            Explore VaultMe Now
          </ActionButton>
        </ActionButtonContainer>
      </AnnouncementPopup>
    </AnnouncementContainer>
  );
};

const AnnouncementContainer = styled.div<{ splitScreen: boolean }>`
  position: fixed;
  z-index: ${(props) => props.theme.layers.referralCodeIndication};
  top: 0;
  left: ${(props) => props.splitScreen ? "50%" : 0};
  bottom: 0;
  right: 0;

  background: rgba(5,37,53,0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  animation: fadeIn 1s;
`;

const AnnouncementPopup = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;

  border-radius: 0.3rem;
  background: ${(props) => props.background};

  position: relative;
  width: 50rem;
  margin: 2rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 100%;
    margin: 1rem;
  }
`;

const AnnouncementCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 0;
  right: 0;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 1.2rem;
    height: 4rem;
    width: 4rem;
  }
`;

const AnnouncementBanner = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 10rem;

  color: #ffffff;
  background: ${(props) => props.background};
  border-radius: 0.3rem 0.3rem 0 0;

  ${(props) => props.theme.responsive.respondToSmall()} {
    flex-direction: column;
  }
`;

const AnnouncementBannerPrefix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.3rem 1rem 4rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 1rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    flex-direction: column;
  }
`;

const AnnouncementVaultMeLogo = styled(VaultMeLogo)`
  width: 12rem;
  height: 3.5rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 10rem;
    height: 2.9rem;
  }
`;

const AnnouncementForWord = styled.div`
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 200;
  padding: 1.3rem 0 0 1.1rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 2rem;
    padding: 1rem 0 0 0;
  }
`;

interface AnnouncementOrganizationLogoProps {
  width: number;
  offsetXRow: number;
  offsetYRow: number;
  offsetXColumn: number;
  offsetYColumn: number;
}

const AnnouncementOrganizationLogo = styled.img<AnnouncementOrganizationLogoProps>`
  width: ${(props) => props.width}rem;
  margin: ${(props) => 2 + props.offsetYRow}rem 4rem 2rem ${(props) => props.offsetXRow}rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    margin: ${(props) => props.offsetYColumn}rem 1rem 2rem ${(props) => 1 + props.offsetXColumn}rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: ${(props) => props.width * MobileCoeff}rem;
    margin: ${(props) => props.offsetYColumn * MobileCoeff}rem 1rem 1rem
    ${(props) => 1 + props.offsetXColumn * MobileCoeff}rem;
  }
`;

const AnnouncementTitle = styled.div<{ background: string }>`
  line-height: 1.25;
  font-size: 2rem;
  font-weight: 700;
  padding: 1.5rem 12% 1.2rem;
  background: ${(props) => props.background};

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 1rem 5% 0.8rem;
    font-size: 1.5rem;
  }
`;

const AnnouncementBody = styled.div<{ desktop: boolean }>`
  margin-top: 2rem;
  padding: 0 12%;

  p {
    display: block;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
    unicode-bidi: isolate;
    margin-top: 0;
  }

  font-size: 1.25rem;
  line-height: 1.25;

  display: ${(props) => props.desktop ? "block" : "none"};

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: ${(props) => props.desktop ? "none" : "block"};

    margin-top: 1.5rem;
    padding: 0 5%;
    font-size: 1.1rem;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 3rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin: 1rem 0;
  }
`;

const ActionButton = styled.button<{ backgroundColor: string, borderColor: string, textColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 0.15rem;

  color: ${(props) => props.textColor};
  font-size: 1.2rem;
  font-weight: 700;

  padding: 0.8rem 1.6rem;
  min-width: 16rem;

  cursor: pointer;

  transition: all 200ms ease;

  &:hover, &:focus {
    transform: scale(1.02);
    filter: brightness(110%);
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    min-width: 0;
  }
`;
