import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { Batch } from "../types/models/batch";

export function useBatchQuery(batchId: string) {
  return useManagedQuery({
    query: GraphQL.useGetBatchQuery,
    deps: null,
    prepare: () => ({ batchId }),
    extract: (data: GraphQL.GetBatchQuery) => data.batch,
    complete: (batch) => ({
      ...Batch.parse(batch),
      ...Batch.HasMigrations.parseMigrations(batch),
    })
  });
}
