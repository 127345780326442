import * as React from "react";
import { styled } from "../../../../app/theme";
import { Button, MultiLineButtonTitle } from "../../../widgets/button";

interface Props {
  submitTitle: string[];
  hintText: string;
  disabled: boolean;
  onSubmit: () => void;
  onSkip: () => void;
}

export const AmbassadorNavigation: React.FunctionComponent<Props> = (props) => (
  <Layout>
    <ExpandingButton
      size={"narrow"}
      onClick={() => props.onSubmit()}
      disabled={props.disabled}
    >
      <MultiLineButtonTitle>
        {props.submitTitle.map((line, index) => (
          <div key={index}>{line}&nbsp;{index === props.submitTitle.length - 1 && <>&nbsp;&rsaquo;</>}</div>
        ))}
      </MultiLineButtonTitle>
    </ExpandingButton>
    <HintText>{props.hintText}</HintText>
    <ExpandingButton
      color={"secondary"}
      size={"narrow"}
      onClick={() => props.onSkip()}
      disabled={props.disabled}
    >
      <MultiLineButtonTitle>
        <div>No Thanks,&nbsp;</div>
        <div>My Friends Are Good to Go</div>
      </MultiLineButtonTitle>
    </ExpandingButton>
  </Layout>
);

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const HintText = styled.div`
  padding: 0.5rem 0 1.5rem;
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.font.light};
  color: ${(props) => props.theme.colors.darkGray};
  text-align: center;
`;

const ExpandingButton = styled(Button)`
  width: 100%;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.1rem;
  }

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    font-size: 1rem;
  }
`;
