import { CRUDRoutes } from "./crudRoutes";
import { ReferralCode } from "../../types/models/referralCode";
import { RoutesContext } from "./routesContext";

export class ReferralCodesRoutes extends CRUDRoutes<number, ReferralCode> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}
