import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { Button } from "../widgets/button";
import { Panel } from "../containers/panel";
import { PanelRow } from "../containers/rows/panelRow";
import { TextArea } from "../widgets/textArea";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";

export interface UpdateCustomerDetailsToolViewProps {
  customerDetailsStatus: OperationStatus<string>;
  submitStatus: OperationStatus<any>;
  onSubmit: (customerDetails: string) => Promise<any>;
}

export interface UpdateCustomerDetailsToolControllerProps {
  render: (viewProps: UpdateCustomerDetailsToolViewProps) => React.ReactElement;
}

export type UpdateCustomerDetailsToolControllerType = React.ComponentType<UpdateCustomerDetailsToolControllerProps>;

interface UpdateCustomerDetailsToolProps {
  customerDetailsStatus: OperationStatus<string>;
  submitStatus: OperationStatus<any>;
  onSubmit: (customerDetails: string) => Promise<any>;
}

export const UpdateCustomerDetailsTool: React.FunctionComponent<UpdateCustomerDetailsToolProps> = (props) => {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const [customerDetails, setCustomerDetails] = React.useState("");

  const disabled = !props.customerDetailsStatus.isSuccess() || props.submitStatus.isWorking();

  React.useEffect(
    () => {
      if (!disabled && textAreaRef.current) {
        // For some reason we have to memoize the value before setting the timer, otherwise it's not always available
        const textArea = textAreaRef.current;
        window.setTimeout(
          () => {
            textArea.focus();
          },
          0
        );
      }
    },
    [disabled, textAreaRef.current]
  );

  React.useEffect(
    () => {
      if (props.customerDetailsStatus.isSuccess()) {
        setCustomerDetails(props.customerDetailsStatus.result);
      }
    },
    [props.customerDetailsStatus.isSuccess()]
  );

  return (
    <Panel>
      <OperationStatusIndicator
        status={props.customerDetailsStatus}
        subject={"customer details"}
        indicators={StatusIndicators.PanelRow()}
      />
      <OperationStatusIndicator
        status={props.submitStatus}
        progressMessage={"Updating customer details..."}
        failureMessage={"Failed to update customer details"}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow>
        If required, please type any additional information that you'd like to be added to the invoice such as
        a billing address or a business name (250 characters and 10 lines max), and then click the button below.
      </PanelRow>
      <PanelRow>
        <TextArea
          ref={textAreaRef}
          value={customerDetails}
          rows={10}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setCustomerDetails(event.target.value)}
          disabled={disabled}
        />
      </PanelRow>
      <PanelRow>
        <Button
          onClick={() => props.onSubmit(customerDetails)}
          disabled={disabled}
        >
          Generate Invoice
        </Button>
      </PanelRow>
    </Panel>
  );
};
