import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { CacheIdGenerator } from "../../services/graphql/cacheIdGenerator";
import { DataProxy } from "apollo-cache";
import { updateFragments } from "../../services/graphql/updateFragment";
import { MigrationNote } from "./migrationNote";

export namespace MigrationNoteList {
  export function fromGraphQL(migrationNotes: GraphQL.MigrationNoteListFragment): List<MigrationNote> {
    const result = List(migrationNotes.migrationNotes.map(MigrationNote.fromGraphQL));
    // Since "MigrationNote.active" property can be modified by push updates, the data in cache may be inconsistent and
    // need to be filtered.
    return migrationNotes.activeOnly
      ? result.filter((migrationNote) => migrationNote.active)
      : result;
  }

  const TypeName = "MigrationNoteList";

  export const cacheId =
    CacheIdGenerator.simple<GraphQL.MigrationNoteList, { migrationId: string, activeOnly: boolean }>(
      TypeName,
      ({ migrationId, activeOnly }) => [migrationId, activeOnly.toString()]
    );

  export function fragmentRef(migrationId: string, activeOnly: boolean): DataProxy.Fragment<any> {
    return {
      fragment: GraphQL.MigrationNoteListFragmentDoc,
      fragmentName: TypeName,
      id: cacheId.forComps({ migrationId, activeOnly })
    };
  }

  export function cacheMigrationNote(dataProxy: DataProxy, migrationNote: GraphQL.MigrationNoteFragment): void {
    updateFragments<GraphQL.MigrationNoteListFragment>(
      dataProxy,
      [
        fragmentRef(migrationNote.migrationId, true),
        fragmentRef(migrationNote.migrationId, false)
      ],
      (fragment) => {
        if (!fragment.migrationNotes.find((n) => n.id === migrationNote.id)) {
          return {
            ...fragment,
            migrationNotes: fragment.migrationNotes.concat(migrationNote)
          };
        }
      }
    );
  }
}
