export enum RevenueShareSourceType {
  Program = "Program",
  ReferralCode = "ReferralCode"
}

export namespace RevenueShareSourceType {
  export function toFriendlyString(value: RevenueShareSourceType): string {
    switch (value) {
      case RevenueShareSourceType.Program: return "Program";
      case RevenueShareSourceType.ReferralCode: return "Referral Code";
    }
  }
}
