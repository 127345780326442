import * as React from "react";
import { Page } from "../containers/page";
import { ConnectionPanelDefs } from "../blocks/connectionPanel/connectionPanelDefs";
import { ConnectionPanel } from "../blocks/connectionPanel/connectionPanel";
import { OperationStatus } from "../../types/operationStatus";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { UserSummary } from "../../types/models/userSummary";
import { Panel } from "../containers/panel";
import { PanelRow } from "../containers/rows/panelRow";
import { Block } from "../containers/block";
import { Button } from "../widgets/button";
import { signOut } from "../../app/signOut";
import { Paragraph } from "../widgets/paragraph";
import { ConnectionPanelState } from "../blocks/connectionPanel/connectionPanelState";

interface ConnectPageViewProps {
  requestingUser: UserSummary;
  connectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
}

export const ConnectPageView: React.FunctionComponent<ConnectPageViewProps> = (props) => {
  const requestedBy =
    (props.requestingUser.name || "Anonymous user") + " " +
    (props.requestingUser.emailAddress ? "(" + props.requestingUser.emailAddress + ")" : "");

  const done =
    props.connectionState.panelState.type === ConnectionPanelState.Type.Connected ||
    props.connectionState.panelState.type === ConnectionPanelState.Type.Scanning;

  return (
    <Page
      title={
        done
          ? "Thank you! You are almost done."
          : "Please sign in to your " + props.connectionState.cloudService.name + " account"
      }
      subTitle={
        done
          ? "Please check the status of the individual " + props.connectionState.cloudService.name + " apps below. " +
          "If all the apps selected for migration are connected and scanned successfully, you may now close " +
          "this browser window. If you are not sure or if you see any issues, please contact " +
          requestedBy + " for assistance."
          : requestedBy + " requests you to connect your " + props.connectionState.cloudService.name +
          " account to VaultMe in preparation for the upcoming migration."
      }
    >
      <ConnectionPanel
        viewSettings={{
          showConnectionStatusRow: ConnectionPanelDefs.ShowConnectionStatusRow.Always,
          showSuccessRow: ConnectionPanelDefs.ShowSuccessRow.Always,
          successMessage:
            "Please check the status of the individual " + props.connectionState.cloudService.name + " apps above. " +
            "If all the apps selected for migration are connected and scanned successfully, you may now close " +
            "this browser window. If you are not sure or if you see any issues, please contact " +
            requestedBy + " for assistance.",
          showAreasWithNoIssues: true,
          showProgress: true,
          processDescription: undefined,
          showSecurityStatement: false,
          disconnectionConfirmation: (
            <>
              <Paragraph>
                Disconnecting this account from VaultMe will prevent it from being used in the upcoming
                migration. If you connected your account successfully and want to connect another account, please
                close this browser window and open a new one. If you connected this account by mistake and
                want to connect a different account, click the "Disconnect" button below .
              </Paragraph>
              <Paragraph>
                If you are unable to connect your account, please try opening this page in an private (incognito) tab,
                or contact {requestedBy} for assistance.
              </Paragraph>
            </>
          )
        }}
        {...props.connectionState}
      />
    </Page>
  );
};

interface ConnectPageLoadingViewProps {
  status: OperationStatus<any>;
}

export const ConnectPageLoadingView: React.FunctionComponent<ConnectPageLoadingViewProps> = (props) => (
  <Page
    title={
      props.status.isFailure()
        ? "Could not open the page"
        : "Please sign in to your account"
    }
    error={props.status.isFailure()}
  >
    <OperationStatusIndicator
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
  </Page>
);

export const SignOutRequiredView: React.FunctionComponent = () => (
  <Page title={"Sign out required"}>
    <Block>
      <Panel>
        <PanelRow>
          <Paragraph>
            Please sign out from VaultMe before using this link, or open it in a new private (incognito) tab.
          </Paragraph>
          <Paragraph>
            If you see this message AFTER connecting your account, please contact the person who sent you this
            link for assistance.
          </Paragraph>
        </PanelRow>
        <PanelRow>
          <Button onClick={signOut}>Sign Out</Button>
        </PanelRow>
      </Panel>
    </Block>
  </Page>
);
