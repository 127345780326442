import * as styledComponents from "styled-components";
import * as Color from "color";
import { None, Option, Some } from "../utils/monads/option";
import { ThemeOverrides } from "../types/models/themeOverrides";

export interface AppTheme {
  responsive: {
    small: string;
    xSmall: string;
    xxSmall: string;

    respondTo: (size: string) => string;
    respondToSmall: (alwaysApplyIf?: boolean) => string;
    respondToXSmall: (alwaysApplyIf?: boolean) => string;
    respondToXXSmall: (alwaysApplyIf?: boolean) => string;
  };

  layout: {
    blockMargin: number;
    titleMargin: number;
    blockPadding: number;

    header: {
      desktop: number;
      mobile: number;
    };

    leftHandNav: {
      width: string;
    };

    workflow: {
      width: string;
    };

    carousel: {
      width: string;
    };
  };

  layers: {
    leftHandNavOverlay: number;
    leftHandNav: number;
    header: number;
    progressBar: number;
    modal: number;
    tooltip: number;
    connectionStatus: number;
    referralCodeIndication: number;
  };

  font: {
    family: string;
    light: number;
    regular: number;
    medium: number;
    bold: number;
    small: number;
  };

  colors: {
    primary: string;
    darkPrimary: string;
    darkerPrimary: string;
    lightPrimary: string;
    faintPrimary: string;

    red: string;
    lightRed: string;
    subtleRed: string;

    yellow: string;
    lightYellow: string;

    orange: string;
    lightOrange: string;

    green: string;
    lightGreen: string;

    titleColor: string;
    textColor: string;

    black: string;
    gray: string;
    darkGray: string;
    darkerGray: string;
    lightGray: string;
    lighterGray: string;
    offWhite: string;
    white: string;

    useMonochromeLogo: boolean;
  };

  animation: {
    fast: number;

    transitionAll: () => string;
  };
}

export namespace AppTheme {
  export function parseColor(s: string): Option<Color> {
    try {
      return Some(Color(s));
    } catch {
      return None();
    }
  }

  export function applyOverrides(theme: AppTheme, overrides: ThemeOverrides): AppTheme {
    const primary = parseColor(overrides.primary).getOrUse(Color("#000000"));
    const darkerPrimary = overrides.darkerPrimary || primary.darken(0.8).hex();
    const white = Color("#ffffff");

    return {
      ...theme,
      colors: {
        ...defaultAppTheme.colors,
        
        primary: overrides.primary,
        darkPrimary: overrides.darkPrimary || primary.darken(0.5).hex(),
        darkerPrimary,
        lightPrimary: overrides.lightPrimary || primary.mix(white, 0.8).hex(),
        faintPrimary: overrides.faintPrimary || primary.mix(white, 0.9).hex(),

        red: overrides.red || defaultAppTheme.colors.red,
        lightRed: overrides.lightRed ||
          Option.mayBe(overrides.red).flatMap(parseColor)
            .map((color) => color.mix(white, 0.8).hex())
            .getOrUse(defaultAppTheme.colors.lightRed),

        yellow: overrides.yellow || defaultAppTheme.colors.yellow,
        lightYellow: overrides.lightYellow ||
          Option.mayBe(overrides.yellow).flatMap(parseColor)
            .map((color) => color.mix(white, 0.8).hex())
            .getOrUse(defaultAppTheme.colors.lightYellow),

        orange: overrides.orange || defaultAppTheme.colors.orange,
        lightOrange: overrides.lightOrange ||
          Option.mayBe(overrides.orange).flatMap(parseColor)
            .map((color) => color.mix(white, 0.8).hex())
            .getOrUse(defaultAppTheme.colors.lightOrange),

        green: overrides.green || defaultAppTheme.colors.green,
        lightGreen: overrides.lightGreen ||
          Option.mayBe(overrides.green).flatMap(parseColor)
            .map((color) => color.mix(white, 0.8).hex())
            .getOrUse(defaultAppTheme.colors.lightGreen),

        titleColor: overrides.titleColor || darkerPrimary,
        textColor: overrides.textColor || darkerPrimary,

        useMonochromeLogo: overrides.useMonochromeLogo
      }
    };
  }
}

function respondTo(pixels: number): string {
  return "@media only screen and (min-width: " + (pixels + 1) + "px)";
}

function respondToSize(size: string, alwaysApplyIf?: boolean): string {
  return alwaysApplyIf ? "@media only screen" : "@media only screen and (max-width: " + size + ")";
}

export const defaultAppTheme: AppTheme = {
  responsive: {
    small: "47.9em",  // 766.4 px
    xSmall: "32em",   // 512 px
    xxSmall: "22em",  // 352 px

    respondTo: (size: string) => respondToSize(size),
    respondToSmall: (alwaysApplyIf) => respondToSize("47.9em", alwaysApplyIf),
    respondToXSmall: (alwaysApplyIf) => respondToSize("32em", alwaysApplyIf),
    respondToXXSmall: (alwaysApplyIf) => respondToSize("22em", alwaysApplyIf),
  },

  layout: {
    blockMargin: 1.5,
    titleMargin: 1,
    blockPadding: 1,

    header: {
      desktop: 4,
      mobile: 3,
    },

    leftHandNav: {
      width: "15rem"
    },

    workflow: {
      width: "60rem",
    },

    carousel: {
      width: "64rem",
    }
  },

  layers: {
    leftHandNavOverlay: 10,
    leftHandNav: 11,
    header: 12,
    progressBar: 13,
    modal: 1001,
    tooltip: 1002,
    connectionStatus: 1003,
    referralCodeIndication: 1004
  },

  font: {
    family: "'Roboto', Helvetica, Arial, sans-serif",
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    small: 0.8
  },

  colors: {
    primary: "#079fef",
    darkPrimary: "#05689e",
    darkerPrimary: "#002132",
    lightPrimary: "#b4e3f6",
    faintPrimary: "#e6f5fd",

    red: "#fb032d",
    lightRed: "#ffd6d6",
    subtleRed: "#fff6f8",

    yellow: "#e2ba00",
    lightYellow: "#fbf0ba",

    orange: "#bd7d1f",
    lightOrange: "#fffaf0",

    green: "#00912F",
    lightGreen: "#AEE6C0",

    titleColor: "#002132", // darkerPrimary
    textColor: "#002132",  // darkerPrimary

    black: "#000000",
    gray: "#b2b2b2",
    darkGray: "#8e8e8e",
    darkerGray: "#5b5b5b",
    lightGray: "#dadade",
    lighterGray: "#eaeaea",
    offWhite: "#f5f5f5",
    white: "#ffffff",

    useMonochromeLogo: false
  },

  animation: {
    fast: 100,

    transitionAll: () => "transition: all 100ms"
  }
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<AppTheme>;

export { styled, css, createGlobalStyle, keyframes, ThemeProvider };

export function useAppTheme(): AppTheme {
  return styledComponents.useTheme() as AppTheme;
}
