import * as React from "react";
import { List, Set } from "immutable";
import { ModalSettings } from "../layouts/modal";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";

export namespace ActionItem {
  export enum Type {
    Action = "Action",
    Notification = "Notification",
    Warning = "Warning",
    Error = "Error"
  }

  export interface Action {
    title: string;
    onClick?: () => void;
    modal?: ActionModal;
    helpArticle?: PreparedHelpArticle;
    adminOnly?: boolean;
  }

  export interface ActionModal extends ModalSettings {
    render: (actions: ActionModal.Actions) => React.ReactElement;
  }

  export namespace ActionModal {
    export interface Actions {
      close: () => void;
      suppressAndClose: () => void;
    }
  }

  export interface SuppressAction {
    result: SuppressAction.Result;
    affectedSubject: string | undefined;
  }

  export namespace SuppressAction {
    export enum Result {
      WillNotMigrate = "WillNotMigrate",
      WillMigrateAnyway = "WillMigrateAnyway"
    }
  }

  export interface Suppressed {
    message?: React.ReactNode;
    reActivateOptionTitle?: React.ReactNode;
  }

  export function friendlyType(actionItemType: Type): string {
    switch (actionItemType) {
      case ActionItem.Type.Action: return "action";
      case ActionItem.Type.Notification: return "notification";
      case ActionItem.Type.Warning: return "warning";
      case ActionItem.Type.Error: return "issue";
    }
  }

  export function sortKey(actionItemType: Type): number {
    switch (actionItemType) {
      case ActionItem.Type.Notification: return 1000;
      case ActionItem.Type.Action: return 2;
      case ActionItem.Type.Warning: return 1;
      case ActionItem.Type.Error: return 0;
    }
  }

  export function isActive(actionItem: ActionItem, suppressedActiveItemsIds: Set<string>): boolean {
    return !actionItem.masterActionItemId && !suppressedActiveItemsIds.contains(actionItem.id);
  }

  export function noApp(areaId: string): string {
    return "NoApp(" + areaId + ")";
  }

  export function notEnabled(areaId: string): string {
    return "NotEnabled(" + areaId + ")";
  }

  export function restrictionTriggered(restrictionId: string): string {
    return "RestrictionTriggered(" + restrictionId + ")";
  }

  export function scanError(areaId: string): string {
    return "ScanError(" + areaId + ")";
  }

  export function needToEnsureStorageAvailability(subjectId: string): string {
    return "NeedToEnsureStorageAvailability(" + subjectId + ")";
  }

  export function notEnoughStorage(subjectId: string): string {
    return "NotEnoughStorage(" + subjectId + ")";
  }

  export function incrementalSignIn(areaId: string): string {
    return "IncrementalSignIn(" + areaId + ")";
  }

  export function slowness(subjectId: string): string {
    return "Slowness(" + subjectId + ")";
  }
}

export interface ActionItem {
  id: string;
  type: ActionItem.Type;
  message: React.ReactNode;

  actions: ActionItem.Action[];
  suppressAction?: ActionItem.SuppressAction;
  masterActionItemId?: string;

  suppressed?: ActionItem.Suppressed;
}

export type ActionItems = List<ActionItem>;

export namespace ActionItems {
  export interface Suppressing {
    suppressed: Set<string>;
    onSuppress: (actionItemId: string) => void;
    onReActivate: (actionItemId: string) => void;
  }

  export interface Grouped {
    assignments: List<ActionItem>;
    notifications: List<ActionItem>;
    warnings: List<ActionItem>;
    errors: List<ActionItem>;
  }

  export function grouped(actionItems: ActionItems): Grouped {
    const groups = actionItems.groupBy((h) => h.type).map((c) => c.toList());
    return {
      assignments: groups.get(ActionItem.Type.Action) || List<ActionItem>(),
      notifications: groups.get(ActionItem.Type.Notification) || List<ActionItem>(),
      warnings: groups.get(ActionItem.Type.Warning) || List<ActionItem>(),
      errors: groups.get(ActionItem.Type.Error) || List<ActionItem>()
    };
  }

  export interface Stats {
    totalActionable: number;
    assignments: number;
    notifications: number;
    warnings: number;
    errors: number;
  }

  export namespace Stats {
    export const empty: Stats = {
      totalActionable: 0,
      assignments: 0,
      notifications: 0,
      warnings: 0,
      errors: 0,
    };

    export function build(actionItems: ActionItems): Stats {
      const groups = ActionItems.grouped(actionItems);
      return {
        totalActionable: groups.assignments.size + groups.warnings.size + groups.errors.size,
        assignments: groups.assignments.size,
        notifications: groups.notifications.size,
        warnings: groups.warnings.size,
        errors: groups.errors.size
      };
    }

    export function buildForActive(actionItems: ActionItems, suppressedActiveItemsIds: Set<string>): Stats {
      return build(
        actionItems.filter((issue) => ActionItem.isActive(issue, suppressedActiveItemsIds))
      );
    }

    export function add(a: Stats, b: Stats): Stats {
      return {
        totalActionable: a.totalActionable + b.totalActionable,
        assignments: a.assignments + b.assignments,
        notifications: a.notifications + b.notifications,
        warnings: a.warnings + b.warnings,
        errors: a.errors + b.errors
      };
    }
  }
}
