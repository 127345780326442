import * as React from "react";
import { OrganizationSettingsPageView } from "../../views/screens/organizationPortal/organizationSettingsPageView";
import { useGetOrganizationQuery } from "../../queries/organizations/useGetOrganizationQuery";
import { useUpdateOrganizationMutation } from "../../queries/organizations/useUpdateOrganizationMutation";
import {
  useOrganizationThemeLookupControllerFactory
} from "../../components/lookup/themes/useOrganizationThemeLookupControllerFactory";
import { useThemeDetailsController } from "../../components/lookup/themes/useThemeDetailsController";
import {
  useOrganizationThemeViewPathFactory
} from "../../components/lookup/themes/useOrganizationThemeViewPathFactory";
import { useFileUploadController } from "../../queries/api/useFileUploadController";
import { useElevated } from "../../utils/useAppState";

interface Props {
  organizationId: number;
}

export const OrganizationSettingsPage: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();

  const [loadStatus] = useGetOrganizationQuery(props.organizationId);
  const [update, updateStatus] = useUpdateOrganizationMutation();

  const organizationThemeLookupControllerFactory = useOrganizationThemeLookupControllerFactory();
  const themeDetailsController = useThemeDetailsController();
  const organizationThemeViewPathFactory = useOrganizationThemeViewPathFactory();

  const fileUploadController = useFileUploadController();

  return (
    <OrganizationSettingsPageView
      readOnly={!elevated}

      loadStatus={loadStatus}
      updateStatus={updateStatus}

      onSubmit={(formData) => update(props.organizationId, formData)}

      organizationThemeLookupControllerFactory={organizationThemeLookupControllerFactory}
      themeDetailsController={themeDetailsController}
      organizationThemeViewPathFactory={organizationThemeViewPathFactory}

      fileUploadController={fileUploadController}
    />
  );
};
