import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type MakeSchoolLookupPromptMutationHook = [
  (domainName: string) => Promise<string>,
  OperationStatus<string>
];

export function useMakeSchoolLookupPromptMutation(): MakeSchoolLookupPromptMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useMakeSchoolLookupPromptMutation,
    extract: (data: GraphQL.MakeSchoolLookupPromptMutation) => nullToUndefined(data.makeSchoolLookupPrompt),
    complete: identity
  });

  function fireWith(domainName: string): Promise<string> {
    return fire({
      variables: { domainName },
      retry: () => fireWith(domainName),
    });
  }

  return [fireWith, status];
}
