import * as React from "react";
import { AreaConfig } from "./areaConfig";
import { WorkStatus } from "./workStatus";
import { IncrementalSignInDefs } from "../blocks/incrementalSignIn";
import { ActionItem, ActionItems } from "./actionItem";
import { sentence } from "../../utils/misc";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { List } from "immutable";

export namespace AreaStatus {
  export interface Disabled {
    id: string;
    type: ActionItem.Type;
    message: React.ReactNode;
    actions: ActionItem.Action[];
    suppressAction?: ActionItem.SuppressAction;
    masterActionItemId?: string;

    noSuffix?: boolean;
  }

  export namespace Disabled {
    export function toIssues(disabled: Disabled, area: AreaConfig): ActionItems {
      return List([{
        id: disabled.id,
        type: disabled.type,
        message: disabled.noSuffix
          ? disabled.message
          : <>{disabled.message}. {sentence(area.mainSubject)} migration will NOT work.</>,
        actions: disabled.actions,
        suppressAction: disabled.suppressAction,
        masterActionItemId: disabled.masterActionItemId
      }]);
    }
  }
}

export interface AreaStatus extends AreaConfig {
  status: WorkStatus;
  actionItems: ActionItems;
  disabled?: AreaStatus.Disabled;
  incrementalSignIn?: IncrementalSignInDefs.Settings;
  helpArticle?: PreparedHelpArticle;
}
