import * as React from "react";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { PanelRow } from "../../views/containers/rows/panelRow";
import { GraphQL } from "../../services/graphql/generated";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { mapOptional } from "../../utils/misc";
import { EmbeddedContent } from "../../views/utils/embeddedContent";
import { ExternalHelpArticle } from "../../types/models/externalHelpArticle";

export function buildHelpContent(
  options: GraphQL.SignInFormCustomization | undefined
): SignInDefs.SignInHelpContent | undefined {
  if (options) {
    const { vendorName } = options;
    return {
      intro: options.introHtml && (
        <PanelRow
          helpArticle={PreparedHelpArticle.mayBeFromExternal(
            mapOptional(options.introHelpArticle, ExternalHelpArticle.fromGraphQL),
            { defaultTitle: "Sign In" + (vendorName ? " to " + vendorName : "") }
          )}
        >
          <EmbeddedContent html={options.introHtml}/>
        </PanelRow>
      ),

      inline: options.inlineHtml && <EmbeddedContent html={options.inlineHtml}/>,
      inlineHelpArticle: mapOptional(
        options.inlineHelpArticle,
        (helpArticle) =>
          PreparedHelpArticle.fromExternal(ExternalHelpArticle.fromGraphQL(helpArticle))
      ),

      footer: options.footerHtml && (
        <PanelRow
          helpArticle={PreparedHelpArticle.mayBeFromExternal(
            mapOptional(options.footerHelpArticle, ExternalHelpArticle.fromGraphQL),
            { defaultTitle: "Sign In" + (vendorName ? " to " + vendorName : "") }
          )}
        >
          <EmbeddedContent html={options.footerHtml}/>
        </PanelRow>
      ),

      howToSwitchAccount: mapOptional(
        options.howToSwitchAccountHelpArticle,
        (helpArticle) =>
          PreparedHelpArticle.fromExternal(ExternalHelpArticle.fromGraphQL(helpArticle))
      ),
    };
  } else {
    return undefined;
  }
}
