import * as React from "react";
import { Migration } from "../../types/models/migration";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import {
  ResumeMigrationControllerProps,
  ResumeMigrationControllerType
} from "../../views/screens/migrationStatusPageView/components/resumeMigrationToolRow";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";

type ResumeMigrationMutationHook = [() => Promise<Migration>, OperationStatus<Migration>];

function useResumeMigrationMutation(migrationId: string): ResumeMigrationMutationHook {
  const [fireWith, { status }] = useManagedMutation({
    mutation: GraphQL.useResumeMigrationMutation,
    extract: (data: GraphQL.ResumeMigrationMutation) => nullToUndefined(data.resumeMigration),
    complete: Migration.parseCore,
    catch: [
      [
        ErrorClass.UnexpectedStateException,
        (error) => UserFacingError.expected(error, {
          summary: "Your migration cannot be resumed right now.",
          recommendations: "Please refresh this page and try again."
        })
      ]
    ]
  });

  function fire(): Promise<Migration> {
    return fireWith({
      variables: { migrationId },
      retry: fire,
    });
  }

  return [fire, status];
}

interface Props extends ResumeMigrationControllerProps {
  migration: Migration;
}

export const ResumeMigrationController: React.FunctionComponent<Props> = (props) => {
  const [resume, status] = useResumeMigrationMutation(props.migration.id);

  return props.render({
    status,
    onResume: resume
  });
};

export function useResumeMigrationController(migration: Migration): ResumeMigrationControllerType {
  return React.useCallback(
    (controllerProps: ResumeMigrationControllerProps) =>
      <ResumeMigrationController {...controllerProps} migration={migration}/>,
    [migration.id]
  );
}
