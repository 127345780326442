import { Location } from "react-router";

export interface SignInRedirectState {
  redirectTo: string | undefined;
  requireElevation: boolean;
}

export function SignInRedirectState(
  redirectTo: Location | string | undefined,
  requireElevation: boolean
): SignInRedirectState {
  return {
    redirectTo: redirectTo && (typeof redirectTo === "string" ? redirectTo : redirectTo.pathname),
    requireElevation
  };
}
