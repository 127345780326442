import * as React from "react";
import { styled } from "../../app/theme";
import { LinkButton } from "../widgets/linkButton";
import { Link } from "react-router-dom";
import { signOut } from "../../app/signOut";
import { useRoutes } from "../../app/routes/useRoutes";

const Header = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.red};
`;

const Section = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.lighterGray};
  margin-top: .75rem;
  padding-top: .75rem;
  
  > * {
    display: block;
    margin: .35rem 0;
  }
`;

export const AdminMenu: React.FunctionComponent = (props) => {
  const routes = useRoutes();

  return (
    <>
      <Header>Admin Tools</Header>
      <Section>
        <Link to={routes.static.monitoringDashboardPath}>Migrations Dashboard</Link>
      </Section>
      <Section>
        <Link to={routes.migrationSetup.homePath}>New Migration</Link>
        <Link to={routes.batchMigrationSetup.homePath}>New Batch</Link>
      </Section>
      <Section>
        <Link to={routes.couponCodes.homePath}>Coupon Codes</Link>
        <Link to={routes.users.homePath}>Users</Link>
      </Section>
      <Section>
        <Link to={routes.organizations.homePath}>Organizations</Link>
        <Link to={routes.schools.homePath}>Schools</Link>
        <Link to={routes.static.contestParticipantsReportPath}>Contest Participants Report</Link>
        <Link to={routes.pricingModels.homePath}>Pricing Configs</Link>
        <Link to={routes.static.testAccountWhitelistingForProgramsPath}>Test Account Whitelisting</Link>
      </Section>
      <Section>
        <Link to={routes.static.myProfilePath}>My Profile</Link>
        <LinkButton onClick={signOut}>Sign Out</LinkButton>
      </Section>
      <Section>
        <Link to={routes.styleguide.homePath}>Styleguide</Link>
        <Link to={routes.wizardrySandbox.homePath}>Wizardry Sandbox</Link>
        <Link to={routes.static.migrationSimulationPath}>Migration Simulation</Link>
      </Section>
    </>
  );
};
