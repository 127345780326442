import * as React from "react";
import { styled } from "../../app/theme";
import { GraphView } from "./graphView";
import { parseEdgeId, renderBlueprint } from "./renderBlueprint";
import { Sidebar } from "./sidebar";
import { useSettings } from "../../utils/useAppState";
import { useDispatch } from "react-redux";
import { toggledBlueprintAnimationAction, updatedBlueprintFiltersAction } from "../../state/settings/actions";
import { AdminToolsMode } from "../../state/settings/state";
import { BlueprintExplorerConfig } from "./blueprintExplorerConfig";
import { ServerElements } from "../serverElements";
import { BlueprintDiff } from "../blueprintDiff";
import { useApolloClient } from "@apollo/react-hooks";
import { GraphQL } from "../../services/graphql/generated";
import { MaterializedMigrationBlueprint } from "../materializedMigrationBlueprint";
import { useDrawer } from "../../views/layouts/drawer";
import { useAppBootstrapConfig } from "../../app/configuration";

const Layout = styled.div<{ size: AdminToolsMode }>`
  display: flex;
  height: 100%;
  flex-direction: ${(props) => props.size === AdminToolsMode.SplitScreen ? "column" : "row"};
  background: white;
`;

const Popup = styled.div<{ diffs?: boolean }>`
  position: absolute;
  top: .5rem;
  right: .5rem;
  padding: .5rem;
  border-radius: 3px;
  opacity: 0.5;
  background: ${(props) => props.diffs ? props.theme.colors.red : "#aaaaaa"};
  font-size: 0.8rem;
  color: white;
  text-align: center;
`;

const Workspace = styled.div<{ size: AdminToolsMode }>`
  flex-grow: 1;
  height: ${(props) => props.size === AdminToolsMode.SplitScreen ? "auto" : "100%"};
  position: relative;
  background: white;
`;

export interface BlueprintExplorerProps {
  config: BlueprintExplorerConfig;
}

interface InternalState {
  selectedComponentId?: string;
  selectedEdgeId?: string;
}

export const BlueprintExplorer: React.FunctionComponent<BlueprintExplorerProps> = (props) => {
  const [state, setState] = React.useState<InternalState>({});
  const [serverElements, setServerElements] = React.useState<ServerElements>(ServerElements.Empty);
  const [diffs, setDiffs] = React.useState<BlueprintDiff>();
  const [rendering, setRendering] = React.useState(false);
  const [keepPopup, setKeepPopup] = React.useState(false);

  const materializedBlueprint = props.config.blueprint.withServerElements(serverElements);

  const apolloClient = useApolloClient();
  const drawer = useDrawer();
  const configuration = useAppBootstrapConfig();

  React.useEffect(
    () => {
      if (keepPopup) {
        const timer = setTimeout(() => setKeepPopup(false), 500);
        return () => clearTimeout(timer);
      }
    },
    [keepPopup]
  );

  React.useEffect(
    () => {
      if (configuration.development) {
        // Uncomment for auto-rendering after every update (only usable for debugging!)
        // renderServerComponents();
        setDiffs(undefined);
      } else {
        setDiffs(undefined);
      }
    },
    // [props.config.blueprint.blueprint.id(), props.config.blueprint.context.inputs.fingerprint()]
    [props.config.blueprint]
  );

  const settings = useSettings();
  const dispatch = useDispatch();

  function renderBody() {
    const graph = renderBlueprint(
      materializedBlueprint,
      settings.blueprintFilters,
      (filters) => dispatch(updatedBlueprintFiltersAction(filters))
    );

    const selectedComponent = state.selectedComponentId
      ? materializedBlueprint.blueprint.components.get(state.selectedComponentId)
      : undefined;
    const selectedRelationshipLocator = state.selectedEdgeId && parseEdgeId(state.selectedEdgeId);
    const selectedRelationship = selectedRelationshipLocator
      ? materializedBlueprint.blueprint.components.findRelationship(selectedRelationshipLocator)
      : undefined;

    return (
      <>
        <Workspace size={settings.adminToolsMode}>
          <GraphView
            // loading={this.props.cachedBlueprint !== undefined && this.props.cachedBlueprint.updating}
            // onReloadClick={this.handleReloadClick}
            animation={settings.blueprintAnimation}
            onToggleAnimation={() => dispatch(toggledBlueprintAnimationAction())}
            onRenderServerComponents={renderServerComponents}
            onRunSandboxCode={runSandboxCode}
            graph={graph}
            selectedComponent={selectedComponent}
            selectedRelationship={selectedRelationship}
            onComponentSelect={handleComponentSelect}
            onEdgeSelect={handleEdgeSelect}
            onClearSelection={handleClearSelection}
          />
          {renderPopup()}
        </Workspace>
        <Sidebar
          blueprintExplorerSize={settings.adminToolsMode}
          blueprintContext={materializedBlueprint.context}
          blueprint={materializedBlueprint.blueprint}
          diffs={diffs || BlueprintDiff.Empty}
          filters={settings.blueprintFilters}
          onFiltersChange={(filters) => dispatch(updatedBlueprintFiltersAction(filters))}
          selectedComponent={selectedComponent}
          selectedRelationship={selectedRelationship}
          onComponentSelect={handleComponentSelect}
        />
      </>
    );
  }

  function renderPopup() {
    const diffCount = diffs ? diffs.criticalCount : 0;
    if (rendering) {
      return <Popup>Rendering...</Popup>;
    } else if (diffCount) {
      return <Popup diffs={true}>{diffCount} diff{diffCount !== 1 ? "s" : ""}</Popup>;
    } else if (keepPopup) {
      return <Popup>Rendered</Popup>;
    }
  }

  function handleComponentSelect(selectedComponentId: string) {
    setState({ selectedComponentId, selectedEdgeId: undefined });
  }

  function handleEdgeSelect(selectedEdgeId: string) {
    setState({ selectedComponentId: undefined, selectedEdgeId });
  }

  function handleClearSelection() {
    setState({ selectedComponentId: undefined, selectedEdgeId: undefined });
  }

  function renderServerComponents() {
    setRendering(true);
    props.config.renderServerElements().then((result) => {
      setServerElements(result);
      setRendering(false);
      setDiffs(BlueprintDiff.build(props.config.blueprint.withServerElements(result)));
      setKeepPopup(true);
    });
  }

  function runSandboxCode() {
    if (materializedBlueprint instanceof MaterializedMigrationBlueprint) {
      apolloClient.query<GraphQL.BuildWorkflowDefQuery, GraphQL.BuildWorkflowDefQueryVariables>({
        query: GraphQL.BuildWorkflowDefDocument,
        variables: {
          sourceCloudServiceId: materializedBlueprint.sourceCloudServiceId,
          destinationCloudServiceId: materializedBlueprint.destinationCloudServiceId,
          blueprintInputs: materializedBlueprint.context.inputs.values
            .map((value, key) => ({ key, value }))
            .valueSeq()
            .toArray()
        },
        fetchPolicy: "no-cache"
      }).then((result) => {
        drawer.open({ content: <pre>{result.data.buildWorkflowDef}</pre> });
      });
    }
  }

  return (
    <Layout size={settings.adminToolsMode}>
      {renderBody()}
    </Layout>
  );
};
