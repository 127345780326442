import { Option } from "../../utils/monads/option";
import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const userId: string = "userId";
}

export class UsersRoutes {
  private readonly root = "users";

  constructor(private context: RoutesContext) {}

  public params(): UsersRoutes.Params {
    return { userId: Option.mayBe(this.context.routeParams[RouteParams.userId]) };
  }

  public get homeMask(): string {
    return this.root;
  }

  public get userDetailsMask(): string {
    return this.root + "/:" + RouteParams.userId;
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public userDetailsPath(userId: string): string {
    return this.context.resolvePath(this.root + "/" + userId);
  }
}

export namespace UsersRoutes {
  export interface Params {
    userId: Option<string>;
  }
}
