import * as React from "react";
import {
  ConfigurationViewPathFactory
} from "../../../views/blocks/lookups/configurationLookupField";
import { useRoutes } from "../../../app/routes/useRoutes";

export function useConfigurationViewPathFactory(organizationId: number): ConfigurationViewPathFactory {
  const routes = useRoutes().organizationPortalRoutes;

  return React.useCallback(
    (configurationId) =>
      organizationId !== undefined ? routes.configurations(organizationId).editPath(configurationId) : "",
    [organizationId]
  );
}
