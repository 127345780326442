import * as React from "react";
import { Constants } from "../../app/constants";

interface SupportProps {
  subject?: string;
}

export const EmailSupportLink: React.FunctionComponent<SupportProps> = (props) => {
  const subject = (props.subject || "Support Request").replace(" ", "%20");
  return (
    <a href={"mailto:" + Constants.Product.supportEmail + "?subject=" + subject}>
      {props.children || Constants.Product.supportEmail}
    </a>
  );
};
