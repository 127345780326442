import { Map, List, Set, Collection } from "immutable";
import { friendlyCount } from "../../utils/formatting";
import { WatcherPlugin } from "./watcherPlugin";
import { AnyWatcherAction } from "./anyWatcherAction";

export interface WatchedItem<T, D> {
  item: T;
  shouldRefresh: boolean;
  updatedAt: Date;
  discriminator: D | undefined;
}

export class WatchedItems<Ctx, T, D> {
  public static olderThan(timestamp: Date, interval: number): boolean {
    return Date.now() - timestamp.getTime() >= interval;
  }

  constructor(
    protected plugin: WatcherPlugin<Ctx, T, D>,
    protected items: Map<string, WatchedItem<T, D>> = Map()) {
  }

  public size(): number {
    return this.items.size;
  }

  public friendlyCount(count: number): string {
    return friendlyCount(count, this.itemType());
  }

  public diff(previous: WatchedItems<Ctx, T, D>): WatchedItems.Diff<T> {
    return new WatchedItems.Diff(
      this.items
        .removeAll(previous.items.keys())
        .toList()
        .map((item) => [item.item, item.shouldRefresh]),
      previous.items
        .removeAll(this.items.keys())
        .toList()
        .map((item) => item.item)
    );
  }

  public updateSubscriptions(
    context: Ctx,
    previous: WatchedItems<Ctx, T, D>,
    dispatchRenews: WatchedItems.DispatchRenews): WatchedItems.Diff<T> {
    const diff = this.diff(previous);
    if (this.plugin.updateSubscriptions) {
      this.plugin.updateSubscriptions(context, diff, dispatchRenews);
    }
    return diff;
  }

  public review(
    context: Ctx,
    dispatchRenews: WatchedItems.DispatchRenews): WatchedItems.ReviewResult<Ctx, T, D> {
    const groups = this.items.groupBy((item) => this.itemNeedsAction(context, item));
    const needRefresh: Collection<string, WatchedItem<T, D>> = groups.get(WatchedItems.ReviewAction.Refresh, Map());
    const needRenew: Collection<string, WatchedItem<T, D>> = groups.get(WatchedItems.ReviewAction.Renew, Map());

    const refreshPromise = this.refreshItems(context, needRefresh.valueSeq().toList(), dispatchRenews);

    return {
      refreshed: refreshPromise
        ? {
          promise: refreshPromise,
          count: needRefresh.count()
        }
        : undefined,
      renewKeys: needRenew.keySeq().toSet(),
      removeKeys: this.items.filterNot((item) => this.itemWillUpdate(context, item)).keySeq().toSet()
    };
  }

  public renew(context: Ctx, keys: Set<string>): WatchedItems<Ctx, T, D> {
    if (!keys.isEmpty()) {
      const renewedItems = this.items.map((item, key) => {
        if (keys.has(key)) {
          return this.renewItem(context, item);
        } else {
          return item;
        }
      });
      return this.copy(this.items.concat(renewedItems));
    } else {
      return this;
    }
  }

  public remove(keys: Set<string>): WatchedItems<Ctx, T, D> {
    const filtered = this.items.deleteAll(keys);
    return this.items.equals(filtered) ? this : this.copy(filtered);
  }

  public reduce(context: Ctx, action: AnyWatcherAction): WatchedItems<Ctx, T, D> {
    const diff = this.plugin.reduce(action, this.items.toList().map((item) => item.item));
    return diff ? this.withUpdates(context, diff.added, diff.removed) : this;
  }

  protected itemType(): string {
    return this.plugin.itemType;
  }

  protected key(item: WatchedItem<T, D>): string {
    return this.plugin.key(item.item);
  }

  protected discriminator(context: Ctx, item: WatchedItem<T, D>): D | undefined {
    return this.plugin.discriminator && this.plugin.discriminator(context, item.item);
  }

  protected itemNeedsAction(
    context: Ctx,
    item: WatchedItem<T, D>): WatchedItems.ReviewAction | undefined {
    // const d = this.discriminator(context, item);
    // if (d && typeof d === "number" && d === 666) {
    if (this.discriminator(context, item) !== item.discriminator) {
      return WatchedItems.ReviewAction.Renew;
    } else if (WatchedItems.olderThan(item.updatedAt, this.plugin.refreshTimeout)) {
      return WatchedItems.ReviewAction.Refresh;
    } else {
      return this.plugin.itemNeedsAction && this.plugin.itemNeedsAction(context, item.item);
    }
  }

  protected itemWillUpdate(context: Ctx, item: WatchedItem<T, D>): boolean {
    return this.plugin.itemWillUpdate ? this.plugin.itemWillUpdate(context, item.item) : true;
  }

  protected refreshItems(
    context: Ctx,
    items: List<WatchedItem<T, D>>,
    dispatchRenews: WatchedItems.DispatchRenews): Promise<any> | undefined {
    const itemsToRefresh = items.filter((item) => item.shouldRefresh);
    if (itemsToRefresh.isEmpty()) {
      return undefined;
    } else {
      return this.plugin.refreshItems &&
        this.plugin.refreshItems(context, itemsToRefresh.map((item) => item.item), dispatchRenews);
    }
  }

  protected newItem(context: Ctx, item: T, shouldRefresh: boolean): WatchedItem<T, D> {
    return {
      item,
      shouldRefresh,
      updatedAt: new Date(),
      discriminator: this.plugin.discriminator && this.plugin.discriminator(context, item)
    };
  }

  protected renewItem(context: Ctx, item: WatchedItem<T, D>): WatchedItem<T, D> {
    return {
      ...item,
      updatedAt: new Date(),
      discriminator: this.discriminator(context, item)
    };
  }

  protected withUpdates(context: Ctx, added: List<[T, boolean]>, removed: List<T>): WatchedItems<Ctx, T, D> {
    const addedItems = Map<string, WatchedItem<T, D>>(
      added
        .filter(([item]) => !this.items.has(this.plugin.key(item)))
        .map(([item, shouldRefresh]) => [this.plugin.key(item), this.newItem(context, item, shouldRefresh)])
    );
    const removedKeys = removed
      .map((item) => this.plugin.key(item))
      .filter((key) => this.items.has(key));
    if (!addedItems.isEmpty() || !removedKeys.isEmpty()) {
      return this.copy(this.items.concat(addedItems).deleteAll(removedKeys));
    } else {
      return this;
    }
  }

  protected copy(items: Map<string, WatchedItem<T, D>>): WatchedItems<Ctx, T, D> {
    return new WatchedItems<Ctx, T, D>(this.plugin, items);
  }
}

export namespace WatchedItems {
  export interface Refreshed {
    promise: Promise<any>;
    count: number;
  }

  export interface ReviewResult<Ctx, T, D> {
    refreshed?: Refreshed;
    renewKeys: Set<string>;
    removeKeys: Set<string>;
  }

  export enum ReviewAction {
    Refresh = "Refresh",
    Renew = "Renew"
  }

  export type DispatchRenews = (keys: Iterable<string>) => void;

  export class Diff<T> {
    constructor(public added: List<[T, boolean]>, public removed: List<T> = List()) {
    }

    public isEmpty(): boolean {
      return this.added.isEmpty() && this.removed.isEmpty();
    }

    public get addedItems(): List<T> {
      return this.added.map(([item]) => item);
    }
  }

  export namespace Diff {
    export function build<T>(
      added: List<T>,
      addedShouldRefresh: boolean = true,
      removed: Iterable<T> = List()
    ): Diff<T> {
      return new Diff(added.map((item) => [item, addedShouldRefresh]), List(removed));
    }
  }
}
