import * as React from "react";
import {
  useSendTestOffboardingProjectEmailMutation
} from "../../../queries/automatedOffboarding/useSendTestOffboardingProjectEmailMutation";
import {
  SendTestOffboardingProjectEmailViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/sendTestOffboardingProjectEmailController";
import { ControllerProps } from "../../../utils/controller";

interface SendTestOffboardingProjectEmailToolControllerProps
  extends ControllerProps<SendTestOffboardingProjectEmailViewProps> {
  offboardingProjectId: string;
}

const SendTestOffboardingProjectEmailToolController
  : React.FunctionComponent<SendTestOffboardingProjectEmailToolControllerProps> =
  (props) => {
    const [render, status] = useSendTestOffboardingProjectEmailMutation(props.offboardingProjectId);

    return props.render({
      status,
      onSend: render
    });
  };

export function useSendTestOffboardingProjectEmailController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<SendTestOffboardingProjectEmailViewProps>) =>
      <SendTestOffboardingProjectEmailToolController offboardingProjectId={offboardingProjectId} {...controllerProps}/>,
    [offboardingProjectId]
  );
}
