import { Configuration } from "../../types/models/configuration";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchConfigurationsQuery(
  organizationId: number,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchConfigurationsQueryVariables, List<Configuration>> {
  return useManagedQuery({
    query: GraphQL.useSearchConfigurationsQuery,
    deps: term,
    prepare: (deps) => ({ organizationId, term: deps }),
    extract: (data: GraphQL.SearchConfigurationsQuery) => nullToUndefined(data.searchConfigurations),
    complete: (configurations) => List(configurations)
      .filter((configuration) => configuration.id >= 0)
      .map(Configuration.parse),
    fetchPolicy: "cache-and-network"
  });
}
