import * as React from "react";
import { styled } from "../../app/theme";

interface Props {
  message?: string;
}

export const RedirectingPlaceholder: React.FunctionComponent<Props> = (props) => (
  <Layout >{props.message || "Redirecting..."}</Layout>
);

const Layout = styled.div`
  color: #888888;
  text-align: center;
  padding: 3rem;
  font-size: 1.2rem;
`;
