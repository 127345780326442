import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { User } from "../../types/models/user";

export function useGetUserIfPossible(
  mayBeUserId: string | undefined
): ManagedQueryHook<GraphQL.GetUserQueryVariables, User> {
  return useManagedQuery({
    query: GraphQL.useGetUserQuery,
    deps: mayBeUserId,
    prepare: (userId) => ({ userId }),
    extract: (data: GraphQL.GetUserQuery) => data.getUser,
    complete: User.parse
  });
}
