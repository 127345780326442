import * as React from "react";
import { Page } from "../containers/page";
import { AppLayout } from "../layouts/appLayout";

export const NewMigrationsAreNotAllowed: React.FunctionComponent = () => (
  <AppLayout keepLeftHandNavOpenOnLargeScreens={false}>
    <Page
      title={"Sorry, VaultMe is currently under maintenance. New migrations cannot be started."}
      subTitle={
        "We expect maintenance to be completed by the end of Sunday (May 8) US Pacific Time. " +
        "Please check the status after a while. " +
        "We are sorry for the inconvenience!"
      }
    />
  </AppLayout>
);
