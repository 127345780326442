import * as React from "react";
import { CarouselContent } from "./carouselContent";
import { CarouselContainer } from "./carouselContainer";
import { CarouselImage } from "./carouselImage";
import { CarouselTitle } from "./carouselTitle";
import { CarouselSubTitle } from "./carouselSubTitle";
import { CarouselDots } from "./carouselDots";
import { CarouselButton } from "./carouselButton";
import { Button } from "../../../widgets/button";
import { ReviewsContainer, Review } from "./reviews";
import { CarouselStep, CarouselSteps } from "./carouselSteps";
import { CarouselLink } from "./carouselLink";
import { CarouselLinks, CarouselLinksSeparator } from "./carouselLinks";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { ModalController, useModal } from "../../../layouts/modal";
import { ProgramEscape } from "../../../blocks/programEscape";

interface CarouselStepProps {
  stepCount: number;
  selectedStep: number;
  stepConfig: CarouselStep;

  onNext: (step: number) => void;
  onSelect: (step: number) => void;
  onSkip: () => void;
}

export const CarouselStepView: React.FunctionComponent<CarouselStepProps> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  const modal = useModal();

  const reviews = props.stepConfig.reviews.map((review) => <Review review={review} key={review.name}/>);

  function handleNextClick() {
    if (props.selectedStep === props.stepCount - 1) {
      props.onSkip();
    } else {
      props.onSelect(props.selectedStep + 1);
    }
  }

  return (
    <CarouselContent>
      <CarouselContainer>
        <CarouselImage url={props.stepConfig.image}/>
        <CarouselTitle>{props.stepConfig.title}</CarouselTitle>
        <CarouselSubTitle>{props.stepConfig.subTitle}</CarouselSubTitle>
        <CarouselDots
          count={props.stepCount}
          selected={props.selectedStep}
          onSelect={props.onSelect}
        />
        <CarouselButton>
          <Button color="blue" size="wide" onClick={handleNextClick}>
            {props.selectedStep === props.stepCount - 1 ? "Get Started" : "Next"} &nbsp;&rsaquo;
          </Button>
        </CarouselButton>
      </CarouselContainer>
      <CarouselLinks>
        {appBootstrapConfig.configuration?.escapeLinkText && (
          <>
            <ModalController
              modal={modal}
              render={(close) => <ProgramEscape onContinue={close}/>}
            />
            <CarouselLink onClick={modal.open}>{appBootstrapConfig.configuration.escapeLinkText}</CarouselLink>
            <CarouselLinksSeparator/>
          </>
        )}
        <CarouselLink onClick={props.onSkip}>Skip intro</CarouselLink>
      </CarouselLinks>
      <ReviewsContainer centered={true}>{reviews}</ReviewsContainer>
    </CarouselContent>
  );
};
