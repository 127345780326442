import * as React from "react";
import { styled } from "../../app/theme";
import { HamburgerClose, HamburgerCloseColor } from "../glyphs/hamburgerClose";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

interface Props extends StyledComponentsProps {
  color?: HamburgerCloseColor;
  onClick?: () => void;
}

export const CloseModalButton: React.FunctionComponent<Props> = (props) => (
  <Button onClick={props.onClick} className={props.className}>
    <StyledHamburgerClose color={props.color}/>
  </Button>
);

const Button = styled.button`
  height: 5rem;
  width: 5rem;
  padding: 1.5rem;
  z-index: 2;
  flex-shrink: 0;
  border: none;
  background: none;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 1rem;
    width: 3.4rem;
    height: 3.4rem;
  }

  @media(hover: hover) {
    &:hover {
      transition: 0.5s;
      filter: brightness(110%);
      cursor: pointer;
      text-decoration: none;
      stroke-width: 0.4px;
      stroke: ${(props) => props.theme.colors.primary};
      transform: rotate(90deg);
    }
  }
`;

const StyledHamburgerClose = styled(HamburgerClose)`
  width: 100%;
  height: 100%;
`;
