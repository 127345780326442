import * as React from "react";
import { Button } from "../../../widgets/button";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { DrawerBlock } from "../../../containers/drawerBlock";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { EmailSupportLink } from "../../../widgets/emailSupportLink";
import { MigrationIssue } from "../../../../types/models/migrationIssue";
import { styled } from "../../../../app/theme";
import { LinkButton } from "../../../widgets/linkButton";
import { DrawerContent } from "../../../utils/drawerContent";
import { SignInLayout } from "../../../blocks/auth/signInLayout";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { friendlyDateTime } from "../../../../utils/formatting";
import { Set } from "immutable";

type Props = MigrationStatusPageDefs.SimpleMigrationIssueViewProps;

const SimpleMigrationIssueView: React.FunctionComponent<Props> = (props) => {
  const [detailsVisible, setDetailsVisible] = React.useState(false);
  return (
    <>
      <DrawerBlock>
        <Panel>
          <PanelRow horizontalScrollBar={true}>
            <DrawerContent>{props.children}</DrawerContent>
            <IssueDetailsLayout>
              {
                detailsVisible
                  ? <IssueDetails issue={props.issue}/>
                  : <LinkButton onClick={() => setDetailsVisible(true)}>Show technical details</LinkButton>
              }
            </IssueDetailsLayout>
          </PanelRow>
          {props.issue.isBlocking && (
            <PanelRow>
              <Button onClick={props.onResolve} disabled={props.status.isWorking()}>Resume Migration</Button>
            </PanelRow>
          )}
          <OperationStatusIndicator
            progressMessage={"Resolving issue..."}
            successMessage={"Issue resolved."}
            status={props.status}
            indicators={StatusIndicators.PanelRow()}
          />
        </Panel>
      </DrawerBlock>
    </>
  );
};

interface IssueDetailsProps {
  issue: MigrationIssue;
}

const IssueDetailsLayout = styled.div`
  margin-top: 1rem;
  overflow-x: auto;
`;

const IssueDetailsTable = styled.table`
  td {
    white-space: nowrap;
  }

  td:first-child {
    padding: 0.3rem 0.5rem 0.3rem 0;
    font-weight: ${(props) => props.theme.font.medium};
  }

  td:last-child {
    padding: 0.3rem 0;
  }
`;

const IssueDetails: React.FunctionComponent<IssueDetailsProps> = (props) => (
  <IssueDetailsTable>
    <tbody>
    <tr>
      <td>Issue ID:</td>
      <td>{props.issue.id}</td>
    </tr>
    <tr>
      <td>Issue Class:</td>
      <td>{props.issue.class}</td>
    </tr>
    <tr>
      <td>Duration:</td>
      <td>{props.issue.friendlyDuration || "--"}</td>
    </tr>
    <tr>
      <td>Will Be Auto-Resolved At:</td>
      <td>
        {props.issue.willBeAutoResolvedAt !== undefined ? friendlyDateTime(props.issue.willBeAutoResolvedAt) : "--"}
      </td>
    </tr>
    <tr>
      <td>Message:</td>
      <td>{props.issue.message}</td>
    </tr>
    <tr>
      <td>Endpoint:</td>
      <td>{props.issue.endpointAlias}</td>
    </tr>
    </tbody>
  </IssueDetailsTable>
);

export const StorageQuotaExceededMigrationIssueView: React.FunctionComponent<Props> = (props) => {
  const connectionId = props.source
    ? props.migration.sourceConnection.descriptionOrId()
    : props.migration.destinationConnection.descriptionOrId();

  return (
    <SimpleMigrationIssueView {...props}>
      Your account is out of available storage space, so your migration is currently paused.
      To resolve this issue, you can:
      <ul>
        <li>
          [Recommended] Buy more storage for {connectionId}, wait 1-2 hours for {props.providerName} to update your
          account, and click the button below, or
        </li>
        <li>
          [Not recommended] Delete some items from {connectionId}, wait 24-48 hours for {props.providerName} to
          update your account, and click the button below, or
        </li>
        <li>
          <EmailSupportLink subject={"Please abort my migration"}>Contact support</EmailSupportLink> to abort your
          migration
        </li>
      </ul>
    </SimpleMigrationIssueView>
  );
};

export const ServiceNotEnabledMigrationIssueView: React.FunctionComponent<Props> = (props) => {
  return (
    <SimpleMigrationIssueView {...props}>
      VaultMe is unable to {props.source ? "read" : "write"} your
      {" "}{props.mainSubject ? props.mainSubject + "s" : "data"} and has paused this portion of your migration
      while the rest of your migration continues. This error can occur
      if {props.appName ? "the " + props.appName : "a"} service for your account was disabled during the migration.
      Please contact your administrator to
      ensure {props.appName ? "the " + props.appName + " service is" : "all services are"} enabled for your account,
      and then click the button below.
    </SimpleMigrationIssueView>
  );
};

export const TooManyRequestsIssueText: React.FunctionComponent<{ providerNames: string[] }> = (props) => {
  return (
    <>
      VaultMe has encountered an issue which may extend your migration's estimated time
      to completion. This is usually a temporary condition caused when{" "}
      {Set(props.providerNames).sort().map((name) => name + "'s").join(" or ")} servers become slow to
      respond during a migration. VaultMe has automatically 'throttled down' your
      migration and will periodically attempt to resume normal speeds. Once your migration's speed is
      back to normal this notice will disappear. No action from you is required at this time, but feel
      free to <EmailSupportLink subject="Temporary issue with my migration">contact support</EmailSupportLink> if
      you have questions.
    </>
  );
};

export const TooManyRequestsMigrationIssueView: React.FunctionComponent<Props> = (props) => {
  return (
    <SimpleMigrationIssueView {...props}>
      <TooManyRequestsIssueText providerNames={[props.providerName]}/>
    </SimpleMigrationIssueView>
  );
};

export const UnrecognizedNonBlockingMigrationIssueView: React.FunctionComponent<Props> = (props) => {
  return (
    <SimpleMigrationIssueView {...props}>
      <TooManyRequestsIssueText providerNames={[props.providerName]}/>
    </SimpleMigrationIssueView>
  );
};

export const UnrecognizedBlockingMigrationIssueView: React.FunctionComponent<Props> = (props) => (
  <SimpleMigrationIssueView {...props}>
    VaultMe has encountered an unexpected issue which may extend your migration's
    estimated time to completion. This is possibly a transient condition you can resolve yourself by
    clicking the button below. If that doesn't work, please
    {" "}<EmailSupportLink subject="Unexpected issue with my migration">contact support</EmailSupportLink>
    {" "}for further assistance.
  </SimpleMigrationIssueView>
);

export const InvalidAccessKeyMigrationIssueView:
  React.FunctionComponent<MigrationStatusPageDefs.AuthMigrationIssueViewProps> =
  (props) => {
    const SignInComponent = props.signInComponent;

    const [detailsVisible, setDetailsVisible] = React.useState(false);

    const accountReference = props.source
      ? " source account (" + props.migration.sourceConnection.descriptionOrId() + ")"
      : " destination account (" + props.migration.destinationConnection.descriptionOrId() + ")";

    return (
      <>
        <DrawerBlock>
          <Panel>
            <PanelRow
              horizontalScrollBar={true}
              status={props.needAnotherRound ? WidgetStatus.HeadsUp : undefined}
            >
              <DrawerContent>
                {
                  props.needAnotherRound
                    ? (
                      <>
                        VaultMe has restored connection to your {accountReference}, but additional permissions are
                        required to resume your migration. Please provide these permissions in the form below.
                      </>

                    )
                    : (
                      <>
                        VaultMe has lost connection to your {accountReference}, so your migration is paused.
                        This usually occurs if VaultMe's access to your account was revoked.
                        Please attempt to sign in again below.
                      </>
                    )
                }
              </DrawerContent>
              <IssueDetailsLayout>
                {
                  detailsVisible
                    ? <IssueDetails issue={props.issue}/>
                    : <LinkButton onClick={() => setDetailsVisible(true)}>Show technical details</LinkButton>
                }
              </IssueDetailsLayout>
            </PanelRow>
            {!props.status.isWorking() && (
              <SignInComponent
                layoutComponent={SignInLayout}
                onSignIn={props.onReconnect}
              />
            )}
            <OperationStatusIndicator
              progressMessage={"Resolving issue..."}
              successMessage={"Issue resolved."}
              status={props.status}
              indicators={StatusIndicators.PanelRow()}
            />
          </Panel>
        </DrawerBlock>
      </>
    );
  };
