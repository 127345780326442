import * as React from "react";
import { CheckoutStepDefs } from "../views/screens/migrationSetup/checkoutStepView/checkoutStepDefs";
import { CloudService } from "../types/models/cloudService";
import { MaterializedMigrationBlueprint, MigrationBlueprintInputs } from "../blueprints/materializedMigrationBlueprint";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { identity, mapOptional, nullToUndefined, withoutUndefined } from "../utils/misc";
import { UserFacingError } from "../types/userFacingError";
import { OperationStatus } from "../types/operationStatus";
import { Migration } from "../types/models/migration";
import { PricingModelCalculator } from "../types/models/pricingModelCalculator";
import { NewTabLink } from "../views/widgets/newTabLink";
import {
  buildPaymentErrorHandlers,
  Payment,
  PaymentFlowConfig,
  PaymentFlowHook,
  usePaymentFlow
} from "./usePaymentFlow";
import { RoutesHook, useRoutes } from "../app/routes/useRoutes";
import { useProgramAlias } from "../app/configuration";
import { CheckboxesState } from "../types/models/checkboxesState";
import { CouponCodeDiscount } from "../types/models/couponCodeDiscount";
import { ReferralCodeDiscount } from "../types/models/referralCodeDiscount";
import { Connection } from "../types/models/connection";
import { SchoolSummary } from "../types/models/school";
import { useDispatch } from "react-redux";
import { replacedUserAction } from "../state/session/actions";
import { User } from "../types/models/user";
import { AmbassadorStatusDiscount } from "../types/models/ambassadorStatusDiscount";
import { AmbassadorCodeDiscount } from "../types/models/ambassadorCodeDiscount";

interface Config {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  sourceConnection: Connection;
  blueprint: MaterializedMigrationBlueprint;
  checkboxesState: CheckboxesState;
  onLaunched: (migration: Migration) => void;
}

export interface CheckoutFlowHook extends PaymentFlowHook {
  loadingStatus: OperationStatus<CheckoutStepDefs.OrderSummary>;
  launchMigrationStatus: OperationStatus<any>;

  submitDisabled: boolean;
}

export function useCheckoutFlow(config: Config): CheckoutFlowHook {
  const programAlias = useProgramAlias();
  const dispatch = useDispatch();

  const migrationSpec: GraphQL.PlaceOrderMutationVariables = {
    sourceCloudServiceId: config.sourceCloudService.id,
    destinationCloudServiceId: config.destinationCloudService.id,
    programAlias,
    // Disabled areas become excluded on this stage, and this information is sealed in the Order object.
    // But locally materialized blueprint may not take disabled areas in account.
    blueprintInputs: config.blueprint.context.inputs
      .merge(MigrationBlueprintInputs.disabledAreasInputs(config.blueprint))
      .toGraphQL(),
    checkboxesState: config.checkboxesState
  };

  const [placeOrder, placeOrderStatus] = usePlaceOrderMutation(migrationSpec);

  const orderSummary = placeOrderStatus.isSuccess() && placeOrderStatus.result.__typename === "PlaceOrderResult_Success"
    ? placeOrderStatus.result
    : undefined;

  function makeOrderConfig(): PaymentFlowConfig.OrderConfig | undefined {
    if (orderSummary) {
      const priceCalculator = PricingModelCalculator.parse(orderSummary.priceCalculator);
      return {
        originalBasePrice: orderSummary.originalBasePrice,
        originalBytesFee: orderSummary.originalBytesFee,
        originalItemsFee: orderSummary.originalItemsFee,
        priceBeforeDiscounts: orderSummary.priceBeforeDiscounts,
        calcAmountToBePaid: (price, discounts) =>
          priceCalculator.makePaymentSpec(price, discounts).amountToBePaid,
        isAmbassadorDiscountEnabled: !!orderSummary.school
      };
    }
  }

  React.useEffect(
    () => {
      placeOrder();
    },
    [
      config.sourceCloudService.id,
      config.destinationCloudService.id,
      config.blueprint.context.inputs.fingerprint()
    ]
  );

  const [launchMigration, launchMigrationStatus] = useLaunchMigrationMutation();

  // Why this is needed:
  // 1). Using launchMigration.then() does not work after retries because of flaws in the useManagedMutation() function.
  // 2). The current user is stored as part of the session in Redux instead of ApolloClient cache. So it has to be
  //     updated manually via the Redux's dispatch().
  React.useEffect(
    () => {
      if (launchMigrationStatus.isSuccess()) {
        dispatch(replacedUserAction(launchMigrationStatus.result.user));
      }
    },
    [launchMigrationStatus.isSuccess()]
  );

  const paymentFlow = usePaymentFlow({
    sourceConnectionId: config.sourceConnection.id,
    order: makeOrderConfig(),
    onPayment: (payment, braintreePayload, deviceData, externalPayment) => {
      if (orderSummary) {
        launchMigration(orderSummary.orderId, payment, braintreePayload, deviceData, externalPayment)
          .then(({ migration }) => config.onLaunched(migration));
      }
    }
  });

  const routes = useRoutes();

  const submitDisabled = (
    placeOrderStatus.isWorking() ||
    paymentFlow.braintreeInitStatus.isWorking() ||
    paymentFlow.requestPaymentMethodStatus.isWorking() ||
    launchMigrationStatus.isWorking() ||
    launchMigrationStatus.isFailure()
  );

  return {
    ...paymentFlow,
    loadingStatus: placeOrderStatus.flatMap((result) =>
      preparePlaceOrderResult(
        routes,
        result,
        paymentFlow.payment?.couponCodeDiscount,
        paymentFlow.payment?.referralCodeDiscount,
        paymentFlow.payment?.ambassadorStatusDiscount,
        paymentFlow.payment?.ambassadorCodeDiscount,
        placeOrder
      )
    ),
    launchMigrationStatus,
    submitDisabled
  };
}

type PlaceOrderMutationHook = [
  () => Promise<GraphQL.PlaceOrderResultFragment>,
  OperationStatus<GraphQL.PlaceOrderResultFragment>
];

export function usePlaceOrderMutation(variables: GraphQL.PlaceOrderMutationVariables): PlaceOrderMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.usePlaceOrderMutation,
    variables,
    extract: (data: GraphQL.PlaceOrderMutation) => nullToUndefined(data.placeOrder),
    complete: identity
  });

  function fireWith(): Promise<GraphQL.PlaceOrderResultFragment> {
    return fire({
      retry: fireWith
    });
  }

  return [fireWith, status];
}

function preparePlaceOrderResult(
  routes: RoutesHook,
  result: GraphQL.PlaceOrderResultFragment,
  couponCodeDiscount: CouponCodeDiscount | undefined,
  referralCodeDiscount: ReferralCodeDiscount | undefined,
  ambassadorStatusDiscount: AmbassadorStatusDiscount | undefined,
  ambassadorCodeDiscount: AmbassadorCodeDiscount | undefined,
  retry: () => void
): OperationStatus<CheckoutStepDefs.OrderSummary> {
  function priceInfo(order: GraphQL.PlaceOrderResult_SuccessFragment): CheckoutStepDefs.PriceInfo {
    const pricingModelCalculator = PricingModelCalculator.parse(order.priceCalculator);
    const payment = pricingModelCalculator.makePaymentSpec(
      order.priceBeforeDiscounts,
      withoutUndefined([
        couponCodeDiscount?.amount, 
        referralCodeDiscount?.amount, 
        ambassadorStatusDiscount?.amount,
        ambassadorCodeDiscount?.amount
      ])
    );

    return {
      receipt: {
        totalBytes: order.totalBytes,
        totalItems: order.totalItems,

        isProgramDiscountApplied: pricingModelCalculator.isProgramDiscountApplied,

        priceBeforeDiscounts: order.priceBeforeDiscounts,
        couponCodeDiscount,
        referralCodeDiscount,
        ambassadorStatusDiscount,
        ambassadorCodeDiscount,
        priceAfterDiscounts: payment.priceAfterDiscounts,

        usedSponsorship: payment.usedSponsorship,
        amountToBePaid: payment.amountToBePaid
      },
      pricingDetails: pricingModelCalculator.renderBreakdown(order.totalBytes, order.totalItems)
    };
  }

  function connectionsAreInUse(migrationIds: string[]): OperationStatus<CheckoutStepDefs.OrderSummary> {
    const links = migrationIds.map((migrationId, index) => (
      <>
        <NewTabLink to={routes.migrations.migrationStatusPath(migrationId)}>{migrationId}</NewTabLink>
        {index < migrationIds.length - 1 ? ", " : undefined}
      </>
    ));
    return OperationStatus.Failure(UserFacingError.synthetic({
      title: "Sorry, your migration cannot be started right now.",
      summary: (
        <>
          Either your source or destination accounts are currently in use by{" "}
          {
            links.length === 1
              ? <>another migration ({links})</>
              : <>other migrations ({links})</>
          }.
          All running migrations must be finished before a new migration using the same source or destination
          accounts can be started.
        </>
      ),
      retry,
      retryTitle: "Check Again"
    }));
  }

  switch (result.__typename) {
    case "PlaceOrderResult_Success":
      return OperationStatus.Success({
        orderId: result.orderId,
        priceInfo: priceInfo(result),
        school: mapOptional(result.school, SchoolSummary.parse),
        clientToken: result.clientToken
      });

    case "PlaceOrderResult_ConnectionsAreInUse":
      return connectionsAreInUse(result.migrationIds);

    default:
      return OperationStatus.Failure(UserFacingError.synthetic({
        summary: "Unrecognized type: " + result.__typename
      }));
  }
}

interface LaunchMigrationResult {
  readonly migration: Migration;
  readonly user: User;
}

type LaunchMigrationMutationHook = [
  (
    orderId: string,
    payment: Payment,
    braintreePayload: any,
    deviceData: any,
    externalPayment: GraphQL.ExternalPayment | undefined
  ) => Promise<LaunchMigrationResult>,
  OperationStatus<LaunchMigrationResult>
];

function useLaunchMigrationMutation(): LaunchMigrationMutationHook {
  const [fire, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useLaunchMigrationMutation,
    extract: (data: GraphQL.LaunchMigrationMutation) => nullToUndefined(data.launchMigration),
    complete: (result) => ({
      migration: Migration.parseCore(result.migration),
      user: User.parse(result.user)
    }),
    catch: buildPaymentErrorHandlers(() => reset())
  });

  function fireWith(
    orderId: string,
    payment: Payment,
    braintreePayload: any,
    deviceData: any,
    externalPayment: GraphQL.ExternalPayment | undefined
  ): Promise<LaunchMigrationResult> {
    return fire({
      variables: {
        orderId,
        expectedAmountToBePaid: payment.amountToBePaid,
        discounts: {
          couponCode: payment.couponCodeDiscount?.code,
          referralCode: payment.referralCodeDiscount?.code,
          ambassadorCode: payment.ambassadorCodeDiscount?.code,
        },
        braintreePayloadJson: JSON.stringify(braintreePayload),
        deviceData: deviceData !== undefined && deviceData !== null ? JSON.stringify(deviceData) : undefined,
        externalPayment
      },
      retry: () => fireWith(orderId, payment, braintreePayload, deviceData, externalPayment)
    });
  }

  return [fireWith, status];
}
