import * as React from "react";
import { UserSelectorPanel } from "../components/userSelectorPanel";
import { UserProfile } from "../components/userProfile";
import { Button } from "../views/widgets/button";
import { useNavigate } from "react-router";
import { UsersPageView } from "../views/screens/usersPageView";
import { useGetUserIfPossible } from "../queries/users/useGetUserIfPossible";
import { AdminArea } from "../views/utils/whenElevated";
import { useRoutes } from "../app/routes/useRoutes";

export const UsersPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const routes = useRoutes().users;
  const params = routes.params();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [appliedSearchTerm, setAppliedSearchTerm] = React.useState<string>();
  const [userStatus] = useGetUserIfPossible(params.userId.toJS());

  return (
    <AdminArea>
      <UsersPageView
        userId={params.userId.toJS()}
        userStatus={userStatus}
        renderUserSelector={() => (
          <UserSelectorPanel
            searchTerm={searchTerm}
            appliedSearchTerm={appliedSearchTerm}
            onSearchTermChange={setSearchTerm}
            onSearch={setAppliedSearchTerm}
            onSelect={(user) => navigate(routes.userDetailsPath(user.id))}
          />
        )}
        renderUserProfile={(user) => (
          <UserProfile
            user={user}
            headerContent={(
              <Button color="white" size="small" onClick={() => navigate(routes.homePath)}>&lsaquo; Back</Button>
            )}
          />
        )}
      />
    </AdminArea>
  );
};
