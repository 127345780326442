import * as React from "react";
import { Step, WizardNavigationContainer } from "../../containers/step";
import { Review, ReviewsContainer } from "./carouselStepView/reviews";
import { ReviewData } from "./carouselStepView/reviewData";
import { Congratulations } from "./connectionInterstitialStepView";
import { useAppBootstrapConfig } from "../../../app/configuration";
import { Block } from "../../containers/block";
import { styled } from "../../../app/theme";
import { Panel } from "../../containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { AppBootstrapConfig } from "../../../types/models/appBootstrapConfig";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { CheckboxField } from "../../widgets/checkboxField";
import { FormLayout } from "../../widgets/formLayout";
import { WizardNavigation } from "../../widgets/wizardNavigation";
import { CheckboxesState } from "../../../types/models/checkboxesState";
import { Connection } from "../../../types/models/connection";
import { CloudService } from "../../../types/models/cloudService";
import { SchoolSummary } from "../../../types/models/school";
import { CheckMark } from "../../glyphs/checkMark";
import { MaterializedMigrationBlueprint } from "../../../blueprints/materializedMigrationBlueprint";
import { friendlyCount, friendlySize } from "../../../utils/formatting";

const CheckboxesBlock = styled(Block)`
  margin-top: 2rem;
`;

interface Props {
  sourceConnection: Connection;
  sourceCloudService: CloudService;
  school: SchoolSummary | undefined;
  migrationTotals: MaterializedMigrationBlueprint.MigrationTotals;

  destinationCloudService: CloudService;
  destinationConnection: Connection;

  result: CheckboxesState | undefined;

  onComplete: (result: CheckboxesState) => void;
  onNavigateBack?: () => void;
}

export const FinalInterstitialStep: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  return (
    <Step
      breadcrumbs={["Setup Complete!"]}
      title="VaultMe is ready to start when you are!"
      subTitle={
        "VaultMe is ready to migrate your data, exactly like you want it, so that you can move on with your life " +
        "without missing all the important work from your past."
      }
      submitHidden={true}
      noForm={true}

      additionalContent={
        <ReviewsContainer>
          <Review review={ReviewData.victor}/>
          <Review review={ReviewData.colin}/>
          <Review review={ReviewData.brianna}/>
        </ReviewsContainer>
      }
    >
      <Congratulations
        sourceConnection={props.sourceConnection}
        sourceCloudService={props.sourceCloudService}
        sourceLogoId={props.school?.logoId}
        sourceLogoWidth={props.school?.logoWidth}
        sourceConnectionDetails={
          <ConnectionDetails>
            {friendlyCount(props.migrationTotals.itemCount, "item")} selected
            <br/>
            ({props.migrationTotals.size === 0 ? "Zero GB" : friendlySize(props.migrationTotals.size)})
          </ConnectionDetails>
        }

        destinationCloudService={props.destinationCloudService}
        destinationConnection={props.destinationConnection}
        destinationConnectionDetails={
          <ConnectionDetails>
            <CheckMarkContainer><CheckMark/></CheckMarkContainer>
            Ready to receive data
          </ConnectionDetails>
        }

        displayCloudServiceNamePlaceholder={false}
      />
      <CheckboxesForm
        configuration={appBootstrapConfig.configuration}
        result={props.result}
        onComplete={props.onComplete}
        onNavigateBack={props.onNavigateBack}
      />
    </Step>
  );
};

interface FormData {
  checkbox1: boolean;
  checkbox2: boolean;
  checkbox3: boolean;
}

export namespace FormData {
  export const blank: FormData = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false
  };

  export function make(result: CheckboxesState): FormData {
    return {
      checkbox1: result.checkbox1Checked,
      checkbox2: result.checkbox2Checked,
      checkbox3: result.checkbox3Checked
    };
  }

  export function toResult(
    formData: FormData,
    configuration: AppBootstrapConfig.Configuration
  ): CheckboxesState {
    return {
      checkbox1Label: configuration.checkbox1Label,
      checkbox1Checked: formData.checkbox1,
      checkbox2Label: configuration.checkbox2Label,
      checkbox2Checked: formData.checkbox2,
      checkbox3Label: configuration.checkbox3Label,
      checkbox3Checked: formData.checkbox3
    };
  }

  export function validationSchema(configuration: AppBootstrapConfig.Configuration) {
    return yup.object<FormData>().shape({
      checkbox1: yup.boolean().oneOf(
        configuration.checkbox1Label && configuration.checkbox1Required ? [true] : [true, false],
        "Checking this option is required"
      ),
      checkbox2: yup.boolean().oneOf(
        configuration.checkbox2Label && configuration.checkbox2Required ? [true] : [true, false],
        "Checking this option is required"
      ),
      checkbox3: yup.boolean().oneOf(
        configuration.checkbox3Label && configuration.checkbox3Required ? [true] : [true, false],
        "Checking this option is required"
      ),
    });
  }
}

interface CheckboxesFormProps {
  result: CheckboxesState | undefined;
  configuration: AppBootstrapConfig.Configuration | undefined;
  onComplete: (result: CheckboxesState) => void;
  onNavigateBack?: () => void;
}

const CheckboxesForm: React.FunctionComponent<CheckboxesFormProps> = (props) => {
  return (
    <Formik<FormData>
      initialValues={props.result ? FormData.make(props.result) : FormData.blank}
      validationSchema={props.configuration ? FormData.validationSchema(props.configuration) : yup.mixed()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(data, actions) => {
        props.onComplete(
          props.configuration
            ? FormData.toResult(data, props.configuration)
            : CheckboxesState.dummy
        );
      }}
      render={() => (
        <Form>
          {
            props.configuration && (
              props.configuration.checkbox1Label ||
              props.configuration.checkbox2Label ||
              props.configuration.checkbox3Label
            ) && (
              <CheckboxesBlock>
                <Panel>
                  <PanelRow>
                    <FormLayout noBottomMargin={true}>
                      {props.configuration.checkbox1Label && (
                        <CheckboxField<FormData>
                          label={<span dangerouslySetInnerHTML={{ __html: props.configuration.checkbox1Label }}/>}
                          name={"checkbox1"}
                        />
                      )}
                      {props.configuration.checkbox2Label && (
                        <CheckboxField<FormData>
                          label={<span dangerouslySetInnerHTML={{ __html: props.configuration.checkbox2Label }}/>}
                          name={"checkbox2"}
                        />
                      )}
                      {props.configuration.checkbox3Label && (
                        <CheckboxField<FormData>
                          label={<span dangerouslySetInnerHTML={{ __html: props.configuration.checkbox3Label }}/>}
                          name={"checkbox3"}
                        />
                      )}
                    </FormLayout>
                  </PanelRow>
                </Panel>
              </CheckboxesBlock>
            )
          }
          <WizardNavigationContainer>
            <WizardNavigation
              submitTitle={"Proceed to Checkout"}
              submitSize={"normal"}
              onNavigateBack={props.onNavigateBack}
            />
          </WizardNavigationContainer>
        </Form>
      )}
    />
  );
};

const CheckMarkContainer = styled.div`
  display: inline-block;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
  position: relative;
  top: .15rem;
`;

const ConnectionDetails = styled.div`
  font-size: .8rem;
  text-align: center;
`;
