import { Reducer } from "../reducer";
import { Set } from "immutable";

export type ItemTypes = Set<string>;

export namespace ItemTypes {
  export const { Merge, MergeOpt } = Reducer.standardMergeReducers<ItemTypes>(
    "ItemTypes",
    (a, b) => a.merge(b)
  );

  export const Reducers = [Merge, MergeOpt];
}
