import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { DataFlowOf } from "../relationship";
import { State } from "../state";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";

export class ExactlyOneRelationshipHub<Output> extends DataSource<ExactlyOneRelationshipHub.Props, Output, Output> {
  public dataFlow(): DataFlowOf<Output> {
    return this.dataFlows().first();
  }

  public calcState(context: BlueprintContext): State<Output> {
    return this.dataFlow().state(context);
  }
}

export namespace ExactlyOneRelationshipHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.ExactlyOneRelationshipHubFragment,
    settings?: ComponentHubSettings
  ): ExactlyOneRelationshipHub<any> {
    return new ExactlyOneRelationshipHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
