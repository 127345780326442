import * as React from "react";
import { Step } from "../../../../containers/step";
import { Block } from "../../../../containers/block";
import { Panel } from "../../../../containers/panel";
import { TitlePanelRow } from "../../../../containers/rows/titlePanelRow";
import { styled } from "../../../../../app/theme";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { CloudService } from "../../../../../types/models/cloudService";
import { CloudServices } from "../../../../../types/models/cloudServices";
import { GrandOption } from "../../../../widgets/option";
import {
  ConnectAdminAccountControllerType,
  GoogleWorkspaceAdminCredentialsPanel
} from "./googleWorkspaceAdminCredentialsPanel";
import { EndUserCredentialsPanel, ManualConnectPanel } from "./endUserCredentialsPanel";
import { Connection } from "../../../../../types/models/connection";
import { MicrosoftAdminCredentialsPanel } from "./microsoftAdminCredentialsPanel";

const Logo = styled.img`
  height: 2.75rem;
  margin-top: -0.25rem;
  margin-bottom: -0.55rem;
`;

const PaddedGrandOption = styled(GrandOption)`
  margin-top: .75rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin-top: .5rem;
  }
`;

interface AdminOptionParams {
  enabled: boolean;
  titleSuffix: string;
  description: string;
}

function adminOptionFor(source: boolean, cloudService: CloudService): AdminOptionParams {
  const context = source ? "source" : "destination";

  switch (cloudService.id) {
    case CloudServices.GSuite:
      return {
        enabled: true,
        titleSuffix: "recommended",
        description:
          "Selecting this option will allow you to manage your migrations without connecting " + context + " " +
          "accounts individually. You will need to have Super Admin access to your Google Workspace account."
      };

    case CloudServices.Office365:
      return {
        enabled: true,
        titleSuffix: "recommended",
        description:
          "Selecting this option will allow you to manage your migrations without connecting " + context + " " +
          "accounts individually. You will need to have admin access to your Microsoft 365 account."
      };

    default:
      return {
        enabled: false,
        titleSuffix: "not available",
        description: "This option is not currently supported for " + cloudService.name + "."
      };
  }
}

interface ConnectionSettings {
  mode: "admin" | "end-user" | "self";
  adminConnectionId: string | undefined;
}

interface ResolvedConnectionSettings {
  mode: "admin" | "end-user" | "self";
  adminConnection: Connection | undefined;
}

interface Props {
  step: number;
  totalSteps: number;

  source: boolean;
  cloudService: CloudService;
  connectLink: string;

  connectionSettings: ResolvedConnectionSettings | undefined;
  connectGoogleAdminAccountController: ConnectAdminAccountControllerType;
  connectMicrosoftAdminAccountController: ConnectAdminAccountControllerType;

  onApply: (connectionSettings: ConnectionSettings) => void;
  onSubmit: (connectionSettings: ConnectionSettings) => void;
  onNavigateBack?: () => void;
}

export const BatchConnectionStepView: React.FunctionComponent<Props> = (props) => {
  const adminOption = adminOptionFor(props.source, props.cloudService);
  const context =  props.source ? "source" : "destination";

  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title={`How do you want to connect your ${context} accounts to VaultMe?`}
      subTitle={`Choose the connection method to allow VaultMe accessing your ${context} accounts.`}
      submitDisabled={
        !props.connectionSettings ||
        (props.connectionSettings.mode === "admin" && !props.connectionSettings.adminConnection)
      }
      submitDisabledMessage={
        props.connectionSettings === undefined
          ? "Please select an option"
          : (
            props.connectionSettings.mode === "admin" && !props.connectionSettings.adminConnection
              ? "Please complete all the steps listed in the connection instruction"
              : undefined
          )
      }
      onSubmit={() => {
        if (props.connectionSettings) {
          props.onSubmit({
            mode: props.connectionSettings.mode,
            adminConnectionId: props.connectionSettings?.adminConnection?.id
          });
        }
      }}
      onNavigateBack={props.onNavigateBack}
    >
      <Block>
        <Panel>
          <TitlePanelRow>Migrate {props.source ? "From" : "To"}</TitlePanelRow>
          <PanelRow>
            <Logo src={props.cloudService.logo} alt={props.cloudService.name}/>
          </PanelRow>
        </Panel>
      </Block>

      <PaddedGrandOption
        name={"mode"}
        value={"admin"}
        disabled={!adminOption.enabled}
        checked={adminOption.enabled && props.connectionSettings?.mode === "admin"}
        onChange={() =>
          props.onApply({
            mode: "admin",
            adminConnectionId: props.connectionSettings?.adminConnection?.id
          })
        }
        description={adminOption.description}
      >
        Use admin authentication to automatically connect {context} accounts ({adminOption.titleSuffix})
      </PaddedGrandOption>

      {adminOption.enabled && props.connectionSettings && props.connectionSettings.mode === "admin" && (
        props.cloudService.id === CloudServices.GSuite
          ? (
            <GoogleWorkspaceAdminCredentialsPanel
              source={props.source}
              cloudService={props.cloudService}
              verifiedConnection={props.connectionSettings.adminConnection}
              connectAdminAccountController={props.connectGoogleAdminAccountController}
            />
          )
          : (
            <MicrosoftAdminCredentialsPanel
              source={props.source}
              cloudService={props.cloudService}
              verifiedConnection={props.connectionSettings.adminConnection}
              connectAdminAccountController={props.connectMicrosoftAdminAccountController}
            />
          )
      )}

      <GrandOption
        name={"mode"}
        value={"self"}
        checked={props.connectionSettings?.mode === "self"}
        onChange={() =>
          props.onApply({
            mode: "self",
            adminConnectionId: props.connectionSettings?.adminConnection?.id
          })
        }
        description={
          `Selecting this option will require you to connect each ${context} account to VaultMe individually before ` +
          "starting the migration. " +
          `You will need to have login credentials for each ${context} account.`
        }
      >
        Manually connect each {context} account
      </GrandOption>

      {props.connectionSettings?.mode === "self" && (
        <ManualConnectPanel
          source={props.source}
          connectLink={props.connectLink}
        />
      )}

      <GrandOption
        name={"mode"}
        value={"end-user"}
        checked={props.connectionSettings?.mode === "end-user"}
        onChange={() =>
          props.onApply({
            mode: "end-user",
            adminConnectionId: props.connectionSettings?.adminConnection?.id
          })
        }
        description={
          "Selecting this option will require the owners of the " + context + " accounts to connect them to VaultMe " +
          "individually before starting the migration. You will need to reach out to them with instructions."
        }
      >
        Ask the owners of the {context} accounts to connect them
      </GrandOption>

      {props.connectionSettings?.mode === "end-user" && (
        <EndUserCredentialsPanel
          source={props.source}
          connectLink={props.connectLink}
        />
      )}

    </Step>
  );
};
