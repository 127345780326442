import { Theme } from "../../types/models/theme";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { ThemeFormData } from "../../types/models/themeFormData";

type InsertThemeMutationHook = [
  (formData: ThemeFormData) => Promise<Theme>,
  OperationStatus<Theme>
];

export function useInsertThemeMutation(organizationId: number): InsertThemeMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertThemeMutation,
    extract: (data: GraphQL.InsertThemeMutation) => nullToUndefined(data.insertTheme),
    complete: Theme.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(formData: ThemeFormData): Promise<Theme> {
    return fire({
      variables: { organizationId, formData: ThemeFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
