import * as React from "react";
import { ConnectionPanelRow, ConnectionPanelRowDefs } from "../../../blocks/connectionPanelRow";
import { IncrementalSignIn, IncrementalSignInDefs } from "../../../blocks/incrementalSignIn";
import { SignInLayout } from "../../../blocks/auth/signInLayout";
import { WorkStatus } from "../../../models/workStatus";
import { prepareActionItems } from "../prepareActionItems";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { AreaStatus } from "../../../models/areaStatus";
import { CloudServices } from "../../../../types/models/cloudServices";
import { useDrawer } from "../../../layouts/drawer";
import { ActionItems } from "../../../models/actionItem";

interface DestinationAreaRowProps {
  cloudServices: CloudServices;
  selectedCloudServiceId: string;
  connectionId: string;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  area: MigrationStatusPageDefs.DestinationArea;
  connectionSlowness: boolean;
  connectionErrors: boolean;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const DestinationAreaRow: React.FunctionComponent<DestinationAreaRowProps> = (props) => {
  const drawer = useDrawer();

  function renderContent(): ConnectionPanelRowDefs.Content {
    if (props.area.disabled) {
      return { actionItems: AreaStatus.Disabled.toIssues(props.area.disabled, props.area) };
    } else {
      return {
        actionItems: prepareActionItems(props.area)
          .concat(IncrementalSignInDefs.Settings.mayBeToIssues(props.area.incrementalSignIn, handleSignInClick))
      };
    }
  }

  function handleSignInClick(incrementalSignIn: IncrementalSignInDefs.Settings) {
    drawer.open(
      <IncrementalSignIn
        incrementalSignIn={incrementalSignIn}
        layoutComponent={SignInLayout}
        onSignIn={() => drawer.close()}
      />
    );
  }

  return (
    <ConnectionPanelRow
      icon={props.area.icon}
      title={props.area.appTitle + " (" + props.area.description + ")"}
      content={renderContent()}
      helpArticle={props.area.helpArticle}
      status={
        props.connectionErrors && (props.area.status !== WorkStatus.Success && props.area.status !== WorkStatus.Failure)
          ? WorkStatus.Issue
          : props.area.status
      }
      disabled={!!props.area.incrementalSignIn || !!props.area.disabled}
      strokeOut={!!props.area.disabled}
      progressIndicator={"hidden"}
      actionItemSuppressing={props.actionItemSuppressing}
    />
  );
};
