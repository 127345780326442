import * as React from "react";
import { styled } from "../../../app/theme";
import { Set } from "immutable";
import { Connection } from "../../../types/models/connection";
import { Button } from "../../widgets/button";
import { RoleSelector } from "../auth/roleSelector";
import { AuthProvider } from "../../../types/models/authProvider";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatus } from "../../../types/operationStatus";
import { RevealedAccessKeyProperties } from "./revealedAccessKeyProperties";
import { Panel } from "../../containers/panel";
import { RevealedAccessKey } from "../../../types/models/revealedAccessKey";
import { DrawerBlock } from "../../containers/drawerBlock";
import { AccessKeyValidationCapabilities } from "./accessKeyValidationCapabilities";
import { SimplePanel } from "../../containers/simplePanel";
import { PanelRow } from "../../containers/rows/panelRow";

interface AccessKeyRevealToolViewProps {
  connection: Connection;
  authProvider: AuthProvider;

  roles: Set<string>;
  onRolesSelect: (roles: Set<string>) => void;

  reveal: () => void;
  status: OperationStatus<RevealedAccessKey>;
  lastResult: RevealedAccessKey | undefined;
}

const Title = styled(DrawerBlock)`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.red};
`;

const WarningParagraph = styled(DrawerBlock)`
  color: ${(props) => props.theme.colors.red};
`;

export const AccessKeyRevealToolView: React.FunctionComponent<AccessKeyRevealToolViewProps> = (props) => (
  <>
    <DrawerBlock>
      <Title>IMPORTANT!</Title>
      <WarningParagraph>
        Reveal tool will display the access key associated with this connection.
        Always keep in mind that access keys contain EXTREMELY SENSITIVE INFORMATION that allows viewing,
        modifying and permanently deleting customer data. Never share access keys with anybody, and securely
        delete them immediately after use.
      </WarningParagraph>
      <WarningParagraph>
        If you proceed, this action will be logged, and all other administrators will be notified.
      </WarningParagraph>
    </DrawerBlock>
    <DrawerBlock>
      Select the roles you need for this access key, and they will be checked before revealing (not selecting
      anything will skip this check completely):
    </DrawerBlock>
    <DrawerBlock>
      <Panel>
        <PanelRow>
          <DrawerBlock>
            <RoleSelector
              roles={{
                allRoles: props.authProvider.roles,
                selectedRoles: props.roles,
                grantedRoles: props.connection.roles
              }}
              implicitUpdates={true}
              disabled={props.status.isWorking()}
              onSelect={props.onRolesSelect}
            />
          </DrawerBlock>
          <DrawerBlock>
            <Button color="red" disabled={props.status.isWorking()} onClick={props.reveal}>
              Reveal Access Key
            </Button>
          </DrawerBlock>
        </PanelRow>
        <PanelRow>
          <AccessKeyValidationCapabilities authProvider={props.authProvider}/>
        </PanelRow>
        <OperationStatusIndicator
          progressMessage={"Revealing access key..."}
          successMessage={"Access key revealed"}
          status={props.status}
          indicators={StatusIndicators.PanelRow()}
        />
      </Panel>
    </DrawerBlock>
    {props.lastResult && (
      <DrawerBlock>
        <RevealedAccessKeyProperties connection={props.connection} revealedAccessKey={props.lastResult}/>
      </DrawerBlock>
    )}
  </>
);
