import * as React from "react";
import { AdminArea } from "../views/utils/whenElevated";
import { useJobHistory } from "../components/jobManagement/useJobHistory";
import { JobPageView } from "../views/screens/jobPageView";
import { useDrawer } from "../views/layouts/drawer";
import { ScheduleJobTransition } from "../components/jobManagement/scheduleJobTransition";
import { Constants } from "../app/constants";
import { AbortJob } from "../components/jobManagement/abortJob";
import { useRoutes } from "../app/routes/useRoutes";

export const JobPage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.jobs.jobParams();
  const drawer = useDrawer();
  const [includeMinorUpdates, setIncludeMinorUpdates] = React.useState(false);
  const [loadingStatus, refresh] = useJobHistory(params.jobId, includeMinorUpdates);

  React.useEffect(
    () => {
      const timer = setInterval(() => refresh(), Constants.Jobs.PollingInterval);
      return () => clearInterval(timer);
    }
  );

  return (
    <AdminArea>
      <JobPageView
        jobId={params.jobId}
        includeMinorUpdates={includeMinorUpdates}

        loadingStatus={loadingStatus}

        onRefresh={() => refresh()}
        onScheduleTransition={() => drawer.open(
          <ScheduleJobTransition jobId={params.jobId} onScheduled={refresh}/>
        )}
        onAbort={() => drawer.open(
          <AbortJob jobId={params.jobId} onAborted={refresh}/>
        )}
        onChangeIncludeMinorUpdates={(value) => {
          if (includeMinorUpdates !== value) {
            setIncludeMinorUpdates(value);
            refresh({ includeMinorUpdates: value });
          }
        }}
      />
    </AdminArea>
  );
};
