import * as _ from "lodash";
import { Map } from "immutable";
import { State } from "./state";
import { MaterializedBlueprint } from "./materializedBlueprint";
import { ServerElements } from "./serverElements";
import { deepToJSON, isDefined } from "../utils/misc";
import { AnyComponent } from "./component";
import { BlueprintContext } from "./blueprintContext";
import { AnyHub } from "./hub";
import { AnyRelationship } from "./relationship";

export class BlueprintDiff {
  public readonly criticalCount: number;

  constructor(
    public readonly components: Map<string, BlueprintDiff.ComponentStateDiff>
  ) {
    this.criticalCount = this.components
      .valueSeq()
      .filter((componentDiff) => componentDiff.critical)
      .count();
  }

  public getComponentDiff(componentId: string): BlueprintDiff.ComponentStateDiff | undefined {
    return this.components.get(componentId);
  }

  public getHubDiff(componentId: string, hubName: string): BlueprintDiff.HubStateDiff | undefined {
    const componentStateDiff = this.getComponentDiff(componentId);
    if (componentStateDiff) {
      return componentStateDiff.hubs.get(hubName);
    }
  }

  public getRelationshipDiff(
    componentId: string,
    hubName: string,
    targetComponentId: string
  ): BlueprintDiff.RelationshipStateDiff | undefined {
    const hubStateDiff = this.getHubDiff(componentId, hubName);
    if (hubStateDiff) {
      return hubStateDiff.relationships.get(targetComponentId);
    }
  }
}

export namespace BlueprintDiff {
  export const Empty = new BlueprintDiff(Map());

  export interface ElementStateDiff {
    localState: State<any>;
    serverState: State<any>;
    stateDiff: any;
    critical: boolean;
  }

  export interface RelationshipStateDiff extends ElementStateDiff {
    componentId: string;
  }

  export interface HubStateDiff extends ElementStateDiff {
    name: string;
    relationships: Map<string, RelationshipStateDiff>;
  }

  export interface ComponentStateDiff extends ElementStateDiff {
    id: string;
    hubs: Map<string, HubStateDiff>;
  }

  function difference(object: any, base: any) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? difference(value, base[key]) : value;
      }
    });
  }

  function elementDiff(localState: State<any>, serverState: State<any> | undefined): ElementStateDiff | undefined {
    if (serverState !== undefined) {
      const localStateJson = deepToJSON(localState);
      const serverStateJson = deepToJSON(serverState);

      const localToServerDiff = difference(localStateJson, serverStateJson);
      const serverToLocalDiff = difference(serverStateJson, localStateJson);

      const localToServerDiffCount = _.values(localToServerDiff).length;
      const serverToLocalDiffCount = _.values(serverToLocalDiff).length;

      if (localToServerDiffCount !== 0 || serverToLocalDiffCount !== 0) {
        return {
          localState,
          serverState,
          stateDiff: localToServerDiffCount ? localToServerDiff : serverToLocalDiff,
          critical: true // Not sure what non-critical differences mean... They are all critical, no?!
        };
      }
    }
  }

  function relationshipStateDiff(
    context: BlueprintContext,
    serverElements: ServerElements,
    component: AnyComponent,
    hub: AnyHub,
    relationship: AnyRelationship
  ): RelationshipStateDiff | undefined {
    const diff = elementDiff(
      hub.state(context),
      serverElements.getRelationshipState(component.id, hub.name, relationship.componentId)
    );
    return diff && {
      ...diff,
      componentId: relationship.componentId
    };
  }

  function hubStateDiff(
    context: BlueprintContext,
    serverElements: ServerElements,
    component: AnyComponent,
    hub: AnyHub
  ): HubStateDiff | undefined {
    const diff = elementDiff(hub.state(context), serverElements.getHubState(component.id, hub.name));
    return diff && {
      ...diff,
      name: hub.name,
      relationships: Map(
        hub.relationships
          .filterNot((relationship) => relationship.serverSide)
          .map((relationship) => relationshipStateDiff(context, serverElements, component, hub, relationship))
          .filter<RelationshipStateDiff>(isDefined)
          .map((diffItem) => [diffItem.componentId, diffItem])
      )
    };
  }

  function componentStateDiff(
    context: BlueprintContext,
    serverElements: ServerElements,
    component: AnyComponent
  ): ComponentStateDiff | undefined {
    const diff = elementDiff(component.state(context), serverElements.getComponentState(component.id));
    return diff && {
      ...diff,
      id: component.id,
      hubs: Map(
        component.hubList()
          .filterNot((hub) => hub.serverSide)
          .map((hub) => hubStateDiff(context, serverElements, component, hub))
          .filter<HubStateDiff>(isDefined)
          .map((diffItem) => [diffItem.name, diffItem])
      )
    };
  }

  // Note: if component has no differences, then no differences will be searched for hubs and relationships.
  // Same is true for hubs and their relationships.
  export function build(materializedBlueprint: MaterializedBlueprint): BlueprintDiff {
    const localContext = materializedBlueprint.context.withServerElements(ServerElements.Empty);
    const componentDiffs = materializedBlueprint.blueprint.allComponents()
      .filterNot((component) => component.serverSide)
      .map((component) =>
        componentStateDiff(localContext, materializedBlueprint.context.serverElements, component)
      )
      .filter<ComponentStateDiff>(isDefined);
    return new BlueprintDiff(Map(componentDiffs.map((diff) => [diff.id, diff])));
  }
}
