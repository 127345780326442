import * as React from "react";
import { GraphQL } from "../../services/graphql/generated";
import { Grid } from "../widgets/grid";
import { friendlyDuration } from "../../utils/formatting";
import { parseServerJson } from "../../blueprints/parseServerJson";
import { List } from "immutable";
import { WidgetStatus } from "../utils/widgetStatus";

interface Props {
  estimates: List<GraphQL.EstimationResult>;
}

export const TimeEstimatesGrid: React.FunctionComponent<Props> = (props) => {
  function renderEstimationResult(estimate: GraphQL.EstimationResult) {
    if (estimate.estimate) {
      if (estimate.estimate.trainingSamples === 0) {
        return (
          <>
            <Grid.Cell status={WidgetStatus.Error}>{friendlyDuration(estimate.estimate.value)}</Grid.Cell>
            <Grid.Cell status={WidgetStatus.Error} colSpan={2}>
              Error: {estimate.estimate.goodnessOfFit}
            </Grid.Cell>
          </>
        );
      } else if (!estimate.estimate.trainingSamples) {
        return (
          <>
            <Grid.Cell status={WidgetStatus.Warning}>{friendlyDuration(estimate.estimate.value)}</Grid.Cell>
            <Grid.Cell status={WidgetStatus.Warning} colSpan={2}>
              Fallback ({estimate.estimate.goodnessOfFit})
            </Grid.Cell>
          </>
        );
      } else {
        return (
          <>
            <Grid.Cell>{friendlyDuration(estimate.estimate.value)}</Grid.Cell>
            <Grid.Cell>{estimate.estimate.trainingSamples || "Fallback"}</Grid.Cell>
            <Grid.Cell>{estimate.estimate.goodnessOfFit}</Grid.Cell>
          </>
        );
      }
    } else {
      return (
        <Grid.Cell colSpan={3} status={WidgetStatus.Warning}>No estimation model</Grid.Cell>
      );
    }
  }

  return (
    <Grid>
      <Grid.Header>
        <Grid.Column>Subject</Grid.Column>
        <Grid.Column>Connector</Grid.Column>
        <Grid.Column>Service Provider</Grid.Column>
        <Grid.Column>Estimate</Grid.Column>
        <Grid.Column>Training Samples</Grid.Column>
        <Grid.Column>Goodness Of Fit</Grid.Column>
        <Grid.Column>Params</Grid.Column>
      </Grid.Header>
      <Grid.Body>
        {
          props.estimates
            .sortBy((estimate) => -1 * (estimate.estimate?.value || 0))
            .map((estimate) => (
              <Grid.Row key={estimate.title}>
                <Grid.Cell>{estimate.title}</Grid.Cell>
                <Grid.Cell>{estimate.connector}</Grid.Cell>
                <Grid.Cell>{estimate.serviceProvider}</Grid.Cell>
                {renderEstimationResult(estimate)}
                <Grid.JsonCell>{parseServerJson(estimate.params)}</Grid.JsonCell>
              </Grid.Row>
            ))
        }
      </Grid.Body>
    </Grid>
  );
};
