import * as React from "react";
import { useNavigate, useLocation, Navigate } from "react-router";
import { SignInRedirectState } from "../types/signInRedirectState";
import { SignUpPageView } from "../views/screens/signUpPageView";
import { UserType } from "../types/models/userType";
import { useSession } from "../utils/useAppState";
import { UpgradeAccountPageView, UpgradeAccountToolControllerProps } from "../views/screens/upgradeAccountPageView";
import { useUserConnections } from "../queries/useConnections";
import { GraphQL } from "../services/graphql/generated";
import { Session } from "../types/models/session";
import { OperationStatus } from "../types/operationStatus";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { nullToUndefined } from "../utils/misc";
import { User } from "../types/models/user";
import { useSignUpFlow } from "../components/auth/useSignUpFlow";
import { Constants } from "../app/constants";
import { Set } from "immutable";
import { useRoutes } from "../app/routes/useRoutes";

export const SignUpPage: React.FunctionComponent = () => {
  const location  = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const routes = useRoutes();

  const signUpFlow = useSignUpFlow({
    flowId: "SignUp",
    newUserSettings: { userType: UserType.Business },
    onSignUp: (result) => {
      if (result.user.type !== "Personal") {
        setTimeout(() => navigate(redirectTo), 0);
      }
    }
  });

  const redirectState: SignInRedirectState | undefined = location.state;
  const redirectTo = redirectState && redirectState.redirectTo || routes.migrations.homePath;

  if (session) {
    if (session.user.type === "Personal") {
      return <UpgradeAccountPage session={session} redirectTo={redirectTo}/>;
    } else {
      return <Navigate to={routes.migrations.homePath}/>;
    }
  } else {
    return (
      <SignUpPageView
        state={signUpFlow.state}
        roles={Set([Constants.Auth.IdentificationRole])}
        onSignUp={signUpFlow.handleSignUpSuccess}
      />
    );
  }
};

interface UpgradeAccountPageProps {
  session: Session;
  redirectTo: string;
}

const UpgradeAccountPage: React.FunctionComponent<UpgradeAccountPageProps> = (props) => {
  const upgradeAccountToolController = useUpgradeAccountToolController(props.session, props.redirectTo);
  return <UpgradeAccountPageView upgradeAccountToolController={upgradeAccountToolController}/>;
};

interface MyUpgradeAccountToolControllerProps extends UpgradeAccountToolControllerProps {
  session: Session;
  redirectTo: string;
}

export const UpgradeAccountToolController: React.FunctionComponent<MyUpgradeAccountToolControllerProps> = (props) => {
  const navigate = useNavigate();

  const [connectionsStatus] = useUserConnections({
    userId: props.session.user.id,
    accessKeyCheck: GraphQL.AccessKeyCheck.Skip
  });

  const [upgradeUser, upgradeUserStatus] = useUpgradeUserMutation(props.session.user.id);

  return props.render({
    connectionsStatus,
    upgradeUserStatus,
    onSubmit: (connectionId) => upgradeUser(connectionId).then((result) => {
      setTimeout(() => navigate(props.redirectTo), 0);
      return result;
    })
  });
};

export function useUpgradeAccountToolController(session: Session, redirectTo: string) {
  return React.useCallback(
    (controllerProps: UpgradeAccountToolControllerProps) =>
      <UpgradeAccountToolController {...controllerProps} session={session} redirectTo={redirectTo}/>,
    [session]
  );
}

type AssignBatchMutationHook = [(userId: string) => Promise<User>, OperationStatus<User>];

function useUpgradeUserMutation(userId: string): AssignBatchMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpgradeUserMutation,
    extract: (data: GraphQL.UpgradeUserMutation) => nullToUndefined(data.upgradeUser),
    complete: User.parse
  });

  function fireWith(connectionId: string): Promise<User> {
    return fire({
      variables: { userId, connectionId },
      retry: () => fireWith(connectionId),
    });
  }

  return [fireWith, status];
}
