import * as React from "react";
import { HeaderBlock } from "../../widgets/headerBlock";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { TitlePanelRow } from "../../containers/rows/titlePanelRow";
import { ReportSettingsForm } from "../../blocks/reportSettingsForm";

interface Props {
  maxDays: number;
  onGenerate: (startDate: Date, endDate: Date) => void;
}

export const OrganizationReportsPageView: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <HeaderBlock title={"Migration Reports"}/>
      <Block>
        <Panel>
          <TitlePanelRow>Migrations Report</TitlePanelRow>
          <PanelRow>
            <ReportSettingsForm
              maxDays={props.maxDays}
              submitTitle={"Generate Migrations Report"}
              onSubmit={(result) => props.onGenerate(result.startDate, result.endDate)}
            />
          </PanelRow>
        </Panel>
      </Block>
    </>
  );
};
