import * as React from "react";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { useDrawer } from "../layouts/drawer";
import { LinkButton } from "./linkButton";

export interface InfoLinkProps {
  helpArticle: PreparedHelpArticle;
}

export const InfoLink: React.FunctionComponent<InfoLinkProps> = (props) => {
  const drawer = useDrawer();

  return (
    <LinkButton
      onClick={() => drawer.open({ content: props.helpArticle.content, title: props.helpArticle.title })}
    >
      {props.children}
    </LinkButton>
  );
};
