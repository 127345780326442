import * as React from "react";
import { OperationStatus } from "../../../../../types/operationStatus";
import { Panel } from "../../../../containers/panel";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { friendlyCount } from "../../../../../utils/formatting";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { Button } from "../../../../widgets/button";

export interface ReScanAllControllerProps {
  render: (
    status: OperationStatus<{ facts: number, scanJobs: number }>,
    onReScan: () => Promise<any>
  ) => React.ReactElement;
}

interface ReScanAllFormProps {
  status: OperationStatus<{ facts: number, scanJobs: number }>;
  onReScan: () => void;
}

export const ReScanAllForm: React.FunctionComponent<ReScanAllFormProps> = (props) => (
  <Panel>
    <OperationStatusIndicator
      progressMessage={"Processing..."}
      successMessage={(result) =>
        friendlyCount(result.facts, "fact") + " refreshed, " +
        friendlyCount(result.scanJobs, "scan job") + " started"
      }
      status={props.status}
      indicators={StatusIndicators.PanelRow()}
    />
    <PanelRow>
      Clicking the button below will initiate re-scanning of the source and the destination accounts to reflect
      any changes that might have been made.
    </PanelRow>
    <PanelRow>
      <Button color="blue" onClick={props.onReScan} disabled={props.status.isWorking()}>Re-Scan All</Button>
    </PanelRow>
  </Panel>
);
