import * as React from "react";
import { styled } from "../../app/theme";
import { friendlyCount, friendlySize, multiline } from "../../utils/formatting";
import { CheckoutStepDefs } from "../screens/migrationSetup/checkoutStepView/checkoutStepDefs";
import { PricingModelCalculator } from "../../types/models/pricingModelCalculator";
import { PanelRowDefs } from "../containers/rows/panelRow";
import { DiscountType } from "../../types/models/discountType";
import { useAppBootstrapConfig } from "../../app/configuration";

const Table = styled.table`
  width: 100%;

  tbody, tfoot {
    > tr > td {
      background: white;
      padding: .5rem 1rem;
      vertical-align: top;

      &.title {
        padding-left: ${PanelRowDefs.PanelHorizontalPadding}rem;
      }

      &.title, &.price {
        text-align: right;
        white-space: nowrap;
        width: 1%;
      }

      &.comment {
        font-size: 0.8rem;
        padding-top: .6rem;
      }
    }

    > tr:first-of-type > td {
      padding-top: 1rem;

      &.comment {
        padding-top: 1.1rem;
      }
    }

    > tr:last-of-type > td {
      padding-bottom: 1rem;
    }

    &:not(:first-child) > tr:first-of-type > td {
      border-top: 1px solid ${(props) => props.theme.colors.darkGray};
    }
  }

  tfoot > tr > td {
    font-weight: normal;
  }
`;

interface PricingDetailsTableProps {
  receipt: CheckoutStepDefs.Receipt;
  pricingDetails: PricingModelCalculator.PricingItem[][];
}

export const PricingDetailsTable: React.FunctionComponent<PricingDetailsTableProps> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  const subTotal1Discounts = props.receipt.isProgramDiscountApplied ? ["EDU Program Discount"] : [];
  const subTotal2Discounts = subTotal1Discounts
    .concat(props.receipt.referralCodeDiscount ? ["Alumni Association Member Benefit Code"] : [])
    .concat(props.receipt.ambassadorCodeDiscount ? ["EDU Ambassador Code"] : [])
    .concat(
      props.receipt.ambassadorStatusDiscount
        ? (
          props.receipt.ambassadorStatusDiscount.isReturningAmbassadorDiscount
            ? ["Returning EDU Ambassador Discount"]
            : ["New EDU Ambassador Sign-Up Discount"]
        )
        : []
    )
    .concat(props.receipt.couponCodeDiscount ? ["Coupon Code"] : []);
  const subTotal3Discounts = subTotal2Discounts
    .concat(props.receipt.usedSponsorship ? ["Sponsorship"] : []);

  return (
    <Table>
      {
        props.pricingDetails.map((groupItems, groupIndex) => (
          <tbody key={groupIndex}>
          {
            groupItems.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td className={"title"}>{item.title}</td>
                <td className={"price"}>
                  {typeof item.price === "number" ? "$" + item.price.toFixed(2) : item.price}
                </td>
                <td className={"comment"}>{multiline(item.comment)}</td>
              </tr>
            ))
          }
          </tbody>
        ))
      }
      {
        (
          props.receipt.referralCodeDiscount ||
          props.receipt.ambassadorCodeDiscount ||
          props.receipt.ambassadorStatusDiscount ||
          props.receipt.couponCodeDiscount
        ) && (
          <tbody>
          <tr>
            <td className="title">Sub-Total (USD)</td>
            <td className="price">${props.receipt.priceBeforeDiscounts.toFixed(2)}</td>
            <td className="comment"><Discounts discounts={subTotal1Discounts}/></td>
          </tr>
          {props.receipt.referralCodeDiscount && (
            <tr>
              <td className="title">Alumni Association Member Benefit Code</td>
              <td className="price">(${props.receipt.referralCodeDiscount.amount.toFixed(2)})</td>
              <td className="comment">
                Alumni Association Member Benefit Code "{props.receipt.referralCodeDiscount.code}"
                <br/>
                {props.receipt.referralCodeDiscount.percentage}% off Migration Fee
                {
                  props.receipt.referralCodeDiscount.discountType === DiscountType.BasePriceAndDataFees
                    ? " and Data Fees"
                    : ""
                }
              </td>
            </tr>
          )}
          {props.receipt.ambassadorCodeDiscount && (
            <tr>
              <td className="title">EDU Ambassador Code</td>
              <td className="price">(${props.receipt.ambassadorCodeDiscount.amount.toFixed(2)})</td>
              <td className="comment">
                EDU Ambassador Code "{props.receipt.ambassadorCodeDiscount.code}"
                <br/>
                {props.receipt.ambassadorCodeDiscount.percentage}% off Migration Fee
              </td>
            </tr>
          )}
          {props.receipt.ambassadorStatusDiscount && (
            <tr>
              <td className="title">
                {
                  props.receipt.ambassadorStatusDiscount.isReturningAmbassadorDiscount
                    ? "Returning EDU Ambassador"
                    : "New EDU Ambassador Sign-Up"
                }
              </td>
              <td className="price">(${props.receipt.ambassadorStatusDiscount.amount.toFixed(2)})</td>
              <td className="comment">
                {
                  props.receipt.ambassadorStatusDiscount.isReturningAmbassadorDiscount
                    ? props.receipt.ambassadorStatusDiscount.returningAmbassadorPercentage +
                    "% off for Returning EDU Ambassadors"
                    // Normally we record original discounts in the order details to protect our historical data from
                    // changes, but in this case it's missing, so we to use the current value instead.
                    : "$" + appBootstrapConfig.ambassadorsProgram.signUpDiscount + " off for New EDU Ambassadors"
                }
              </td>
            </tr>
          )}
          {props.receipt.couponCodeDiscount && (
            <tr>
              <td className="title">Coupon Code</td>
              <td className="price">(${props.receipt.couponCodeDiscount.amount.toFixed(2)})</td>
              <td className="comment">
                Coupon Code "{props.receipt.couponCodeDiscount.code}"
                <br/>
                {
                  props.receipt.couponCodeDiscount.isPercentage
                    ? props.receipt.couponCodeDiscount.value + "%"
                    : "$" + props.receipt.couponCodeDiscount.value.toFixed(2)
                } off
              </td>
            </tr>
          )}
          </tbody>
        )
      }
      {props.receipt.usedSponsorship && (
        <tbody>
        <tr>
          <td className="title">Sub-Total (USD)</td>
          <td className="price">${props.receipt.priceAfterDiscounts.toFixed(2)}</td>
          <td className="comment"><Discounts discounts={subTotal2Discounts}/></td>
        </tr>
        <tr>
          <td className="title">Sponsorship</td>
          <td className="price">(${props.receipt.usedSponsorship.sponsoredAmount.toFixed(2)})</td>
          <td className="comment">
            {props.receipt.usedSponsorship.sponsorshipConfig && (
              <>
                {props.receipt.usedSponsorship.sponsorshipConfig.programName}
                <br/>
                {
                  props.receipt.usedSponsorship.sponsorshipConfig.maxSponsoredAmount === -1
                    ? "Full amount"
                    : "Up to $" + props.receipt.usedSponsorship.sponsorshipConfig.maxSponsoredAmount.toFixed(2)
                }
                {" "}
                for a single migration
              </>
            )}
          </td>
        </tr>
        </tbody>
      )}
      <tfoot>
      <tr>
        <td className="title">Total Price (USD)</td>
        <td className="price">${props.receipt.amountToBePaid.toFixed(2)}</td>
        <td className="comment">
          To migrate {friendlySize(props.receipt.totalBytes)} and {friendlyCount(props.receipt.totalItems, "item")}
          <Discounts discounts={subTotal3Discounts}/>
        </td>
      </tr>
      </tfoot>
    </Table>
  );
};

interface DiscountsProps {
  discounts: string[];
}

const Discounts: React.FunctionComponent<DiscountsProps> = (props) => {
  if (props.discounts.length === 0) {
    return null;
  } else if (props.discounts.length === 1) {
    return <div>After {props.discounts[0]}</div>;
  } else {
    return (
      <div>
        After{" "}
        {props.discounts.slice(0, props.discounts.length - 1).join(", ")} and{" "}
        {props.discounts[props.discounts.length - 1]}
      </div>
    );
  }
};
