import * as React from "react";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { Column, ColumnResponsiveBehavior, MultiColumnRow } from "../../containers/rows/multiColumnRow";
import { WidgetStatus } from "../../utils/widgetStatus";
import { styled } from "../../../app/theme";
import { ActivityChart, Dataset } from "./activityChart";
import { MonitoringDashboardPageViewDefs } from "./monitoringDashboardPageViewDefs";
import { List } from "immutable";
import moment, { Moment } from "moment";
import { ActivityTimeUnit } from "../../../types/enums/activityTimeUnit";

const IndicatorColumn = styled(Column)`
  padding: 2rem 0 2.5rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 1.5rem 0 2rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 1rem 0 1.25rem;
  }
`;

const Count = styled.div`
  text-align: center;
  font-size: 4rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 3rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 2rem;
  }
`;

const Label = styled.div`
  text-align: center;
  font-size: 1rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 0.85rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.75rem;
  }
`;

const ChartColumn = styled(Column)`
  padding: .5rem;
  display: flex;
  justify-content: center;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0;
  }
`;

const StyledActivityChart = styled(ActivityChart)`
  width: 45vw;
  height: 100%;

  ${(props) => props.theme.responsive.respondToSmall()} {
    width: 90vw;
    height: 10rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 95vw;
    height: 8rem;
  }
`;

interface OverviewProps {
  data: MonitoringDashboardPageViewDefs.MonitoringDashboardData;
  timeZoneOffset: number;
}

export const Overview: React.FunctionComponent<OverviewProps> = (props) => {
  const requiringAction =
    props.data.requireManualReview.size +
    props.data.runningWithIssues.size;

  const requiringAttention =
    props.data.delayed.size +
    props.data.waitingForUserAction.size;

  const chartTimestamps: List<Moment> = React.useMemo(
    () => props.data.recentMigrationsActivity.map(([timestamp]) => moment(timestamp)),
    [props.data.recentMigrationsActivity]
  );

  const chartDatasets: List<Dataset> = React.useMemo(
    () => List([
      new Dataset(
        "Running migrations",
        props.data.recentMigrationsActivity.map((item) => item[1])
      )
    ]),
    [props.data.recentMigrationsActivity]
  );

  return (
    <Block>
      <Panel>
        <MultiColumnRow>
          <IndicatorColumn
            responsiveBehavior={ColumnResponsiveBehavior.Stay}
            status={props.data.completedWithIssues.isEmpty() ? WidgetStatus.Normal : WidgetStatus.Error}
          >
            <Count>{props.data.completedWithIssues.size}</Count>
            <Label>Completed with<br/>pending actions</Label>
          </IndicatorColumn>

          <IndicatorColumn
            responsiveBehavior={ColumnResponsiveBehavior.Stay}
            status={requiringAction ? WidgetStatus.Error : WidgetStatus.Normal}
          >
            <Count>{requiringAction}</Count>
            <Label>Requiring action</Label>
          </IndicatorColumn>

          <IndicatorColumn
            responsiveBehavior={ColumnResponsiveBehavior.Stay}
            status={requiringAttention ? WidgetStatus.Warning : WidgetStatus.Normal}
          >
            <Count>{requiringAttention}</Count>
            <Label>Requiring attention</Label>
          </IndicatorColumn>

          <IndicatorColumn
            responsiveBehavior={ColumnResponsiveBehavior.Stay}
            status={props.data.runningNormally.isEmpty() ? WidgetStatus.Normal : WidgetStatus.Info}
          >
            <Count>{props.data.runningNormally.size}</Count>
            <Label>Running normally</Label>
          </IndicatorColumn>

          <ChartColumn responsiveBehavior={ColumnResponsiveBehavior.Expand} width={3}>
            <StyledActivityChart
              timestamps={chartTimestamps}
              datasets={chartDatasets}
              timeUnit={ActivityTimeUnit.Hour}
              useLightColors={true}
            />
          </ChartColumn>
        </MultiColumnRow>
      </Panel>
    </Block>
  );
};
