import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Panel } from "views/containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Button } from "../../../widgets/button";
import { ModalController, ModalHook } from "../../../layouts/modal";
import {
  ForceStartEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/forceStartEmailDeliveryRoundController";
import { ControllerHost } from "../../../../utils/controller";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { DrawerBlock } from "views/containers/drawerBlock";

interface Props {
  offboardingProject: OffboardingProject;
  controller: ForceStartEmailDeliveryRoundController;
  modal: ModalHook;
  round: number;
}

export const ForceStartEmailDeliveryRoundTool: React.FunctionComponent<Props> = (props) => {
  const repeating =
    props.offboardingProject.currentRound !== undefined &&
    props.round <= props.offboardingProject.currentRound;

  return (
    <ModalController
      modal={props.modal}
      title={(repeating ? "Repeat" : "Start") + " Round " + (props.round + 1)}
      render={(close) =>
        <ControllerHost
          controller={props.controller}
          render={(viewProps) => {
            if (!props.offboardingProject.isEmailSendingInProgress && props.offboardingProject.connection) {
              return (
                <Panel>
                  <PanelRow>
                    Click the button below to immediately {repeating ? "repeat" : "start"}{" "}
                    round {props.round + 1} of email sending.
                  </PanelRow>
                  <OperationStatusIndicator
                    progressMessage={(repeating ? "Repeating" : "Starting") + " email sending..."}
                    failureMessage={"Failed to " + (repeating ? "repeat" : "start") + " email sending"}
                    status={viewProps.status}
                    indicators={StatusIndicators.PanelRow()}
                  />
                  <PanelRow>
                    <Button
                      color={"primary"}
                      size={"narrow"}
                      onClick={() => viewProps.onSubmit(props.round).then(close)}
                      disabled={viewProps.status.isWorking()}
                    >
                      {(repeating ? "Repeat" : "Start") + " Round"}
                    </Button>
                  </PanelRow>
                </Panel>
              );
            } else {
              return (
                <>
                  <DrawerBlock>
                    This tool can be used to immediately start or repeat any round of email sending.
                    It cannot be used while email sending is already in progress.
                  </DrawerBlock>
                  <DrawerBlock>
                    <Button color={"primary"} size={"narrow"} onClick={close}>Close</Button>
                  </DrawerBlock>
                </>
              );
            }
          }}
        />
      }
    />
  );
};
