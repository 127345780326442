import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { PricingModel } from "../../types/models/pricingModel";

export function useGetPricingModelQuery(
  id: number
): ManagedQueryHook<GraphQL.GetPricingModelQueryVariables, PricingModel> {
  return useManagedQuery({
    query: GraphQL.useGetPricingModelQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetPricingModelQuery) => nullToUndefined(data.pricingModel),
    complete: (pricingModel) => PricingModel.parse(pricingModel),
    fetchPolicy: "cache-and-network"
  });
}
