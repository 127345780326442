import * as React from "react";
import { User } from "../../../types/models/user";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { UserInfoPanel } from "../../blocks/userManagement/userInfoPanel";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";

interface Props {
  renderUserSelectorPanel: () => React.ReactElement;
  user: User | undefined;
  onAssign: (user: User) => Promise<any>;
  onChange: () => void;
  status: OperationStatus<any>;
}

export const BatchAssignmentTool: React.FunctionComponent<Props> = (props) => {
  if (props.user) {
    const user = props.user;
    return (
      <>
        {/*
        UserInfoPanel is already a panel, so progress will be displayed in a separate panel.
        This is a bad practice!
        */}
        <OperationStatusIndicator
          status={props.status}
          progressMessage={"Assigning batch..."}
          failureMessage={"Failed to assign batch"}
          indicators={StatusIndicators.SimplePanel()}
        />
        <UserInfoPanel
          user={props.user}
          displayAdminTools={false}
          headerContent={(
            <SimpleToolbar>
              <Button
                size="small"
                color={"blue"}
                onClick={() => props.onAssign(user)}
                disabled={props.status.isWorking()}
              >
                Assign To This User
              </Button>
              <Button size="small" color={"white"} onClick={props.onChange}>Change</Button>
            </SimpleToolbar>
          )}
        />
      </>
    );
  } else {
    return props.renderUserSelectorPanel();
  }
};
