import * as React from "react";
import { GraphQL } from "../../services/graphql/generated";
import {
  ImapSignInForm,
  ImapSignInFormData,
  ImapSignInFormMode,
} from "../../views/blocks/auth/imapSignInForm";
import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { identity, mapOptional, nullToUndefined } from "../../utils/misc";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { buildHelpContent } from "./buildHelpContent";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { ExternalHelpArticle } from "../../types/models/externalHelpArticle";

export interface ImapCredentialsDefaults {
  login: string;
  customizable: boolean;
}

export namespace ImapCredentialsDefaults {
  export function fromGraphQL(details: GraphQL.ImapCredentialsDefaults): ImapCredentialsDefaults {
    return {
      login: details.login,
      customizable: details.customizable,
    };
  }
}

export interface ImapConnectionDefaults {
  host: string;
  port: number;
  customizable: boolean;
}

export namespace ImapConnectionDefaults {
  export function fromGraphQL(details: GraphQL.ImapConnectionDefaults): ImapConnectionDefaults {
    return {
      host: details.host,
      port: details.port,
      customizable: details.customizable,
    };
  }
}

export interface ImapSignInControllerConfig {
  credentialsDefaults: ImapCredentialsDefaults | undefined;
  connectionDefaults: ImapConnectionDefaults | undefined;
  existingAccessKeyId: string | undefined;

  serviceName: string | undefined;

  advancedSettingsHelpArticle: ExternalHelpArticle | undefined;
  helpContent: SignInDefs.SignInHelpContent | undefined;
}

export namespace ImapSignInControllerConfig {
  export function fromGraphQL(
    config: GraphQL.ImapSignInFormConfig,
    customization: GraphQL.ImapSignInFormCustomization | undefined | null
  ): ImapSignInControllerConfig {
    return {
      credentialsDefaults: config.credentialsDefaults
        ? ImapCredentialsDefaults.fromGraphQL(config.credentialsDefaults)
        : undefined,
      connectionDefaults: config.connectionDefaults
        ? ImapConnectionDefaults.fromGraphQL(config.connectionDefaults)
        : undefined,
      existingAccessKeyId: nullToUndefined(config.existingAccessKeyId),

      serviceName: customization !== undefined && customization !== null
        ? nullToUndefined(customization.serviceName)
        : undefined,
      advancedSettingsHelpArticle: customization !== undefined && customization !== null
        ? mapOptional(customization.advancedSettingsHelpArticle, ExternalHelpArticle.fromGraphQL)
        : undefined,
      helpContent: buildHelpContent(nullToUndefined(customization))
    };
  }
}

interface Props extends SignInDefs.SignInComponentProps {
  config: ImapSignInControllerConfig;
}

export const ImapSignInController: React.FunctionComponent<Props> = (props) => {
  const [imapSignIn, { status: imapSignInStatus }] = useManagedMutation({
    mutation: GraphQL.useImapSignInMutation,
    extract: (data: GraphQL.ImapSignInMutation) => data.imapSignIn,
    // Passing result
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates
    // a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect
    // cleanup function.
    complete: identity,
    catch: [
      [
        ErrorClass.InvalidCredentialsError,
        (error) => UserFacingError.expected(error, {
          title: "Access was denied to this account",
          recommendations: "Please re-check your credentials below and try again."
        })
      ]
    ]
  });

  const config = props.config;
  const { credentialsDefaults, connectionDefaults } = config;

  function handleSubmit(imapSignInFormData: ImapSignInFormData) {
    imapSignIn({
      variables: {
        cloudServiceId: props.cloudServiceId,
        roles: props.roles.toArray(),
        signInParams: {
          login: imapSignInFormData.login,
          password: imapSignInFormData.password,
          host: imapSignInFormData.host || connectionDefaults && connectionDefaults.host || "",
          port: imapSignInFormData.port || connectionDefaults && connectionDefaults.port || 0,
          existingAccessKeyId: config.existingAccessKeyId
        }
      },
      retrying: true
    }).then((oneTimeAuthCode) => props.onSignIn(OneTimeAuthCodeSummary.fromGraphQL(oneTimeAuthCode)));
  }

  return React.createElement(
    props.layoutComponent,
    {
      signInForm: (
        <ImapSignInForm
          serviceName={config.serviceName}
          initialValues={{
            login: credentialsDefaults && credentialsDefaults.login,
            host: connectionDefaults && connectionDefaults.host,
            port: connectionDefaults && connectionDefaults.port
          }}
          loginLocked={credentialsDefaults && !credentialsDefaults.customizable}
          mode={connectionDefaults
            ? (connectionDefaults.customizable ? ImapSignInFormMode.SimpleOrAdvanced : ImapSignInFormMode.Simple)
            : ImapSignInFormMode.Advanced
          }
          advancedSettingsHelpArticle={
            PreparedHelpArticle.mayBeFromExternal(
              config.advancedSettingsHelpArticle, {
                defaultSummary: "When do I need this?",
                defaultTitle: "Sign In to " + props.config.serviceName
              }
            )
          }
          secondarySubmitButton={false}
          disabled={imapSignInStatus.isWorking()}
          onSubmit={handleSubmit}
        />
      ),
      operationStatusIndicator: {
        progressMessage: "Connecting to the server...",
        failureMessage: "Failed to connect to the server. Please check connection details and try again.",
        status: imapSignInStatus
      },
      helpContent: config.helpContent
    });
};
