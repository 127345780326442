import * as React from "react";
import { useRoutes } from "../app/routes/useRoutes";
import { Organization } from "../types/models/organization";
import { CrudPages } from "../components/crud/crudPages";
import { friendlyDateTime, multiline } from "../utils/formatting";
import { useSearchOrganizationsQuery } from "../queries/organizations/useSearchOrganizationsQuery";
import { useDeleteOrganizationMutation } from "../queries/organizations/useDeleteOrganizationMutation";
import { useUpdateOrganizationMutation } from "../queries/organizations/useUpdateOrganizationMutation";
import { useInsertOrganizationMutation } from "../queries/organizations/useInsertOrganizationMutation";
import { useGetOrganizationQuery } from "../queries/organizations/useGetOrganizationQuery";
import { OrganizationFormData } from "../types/models/organizationFormData";
import { OrganizationForm } from "../views/screens/organizationPortal/organizationForm";
import {
  useOrganizationThemeLookupControllerFactory
} from "../components/lookup/themes/useOrganizationThemeLookupControllerFactory";
import { useThemeDetailsController } from "../components/lookup/themes/useThemeDetailsController";
import { useFileUploadController } from "../queries/api/useFileUploadController";
import { CenteredContent } from "../views/layouts/centeredContent";
import { useOrganizationThemeViewPathFactory } from "../components/lookup/themes/useOrganizationThemeViewPathFactory";
import { NewTabLink } from "../views/widgets/newTabLink";

export const OrganizationAdminPages: React.FunctionComponent = () => {
  const organizationThemeLookupControllerFactory = useOrganizationThemeLookupControllerFactory();
  const themeDetailsController = useThemeDetailsController();
  const organizationThemeViewPathFactory = useOrganizationThemeViewPathFactory();

  const fileUploadController = useFileUploadController();

  return (
    <CenteredContent fullWidth={true}>
      {
        CrudPages<number, Organization, OrganizationFormData>({
          title: "Organizations",
          subject: "organization",
          gridColumns: [
            {
              title: "Name",
              render: (organization) => organization.name
            },
            {
              title: "Internal notes",
              render: (organization) => multiline(organization.notes)
            },
            {
              title: "Domains",
              render: (organization) => multiline(organization.domains)
            },
            {
              title: "Admin Portal",
              render: (organization) => <AdminPortalLink alias={organization.alias}/>
            },
            {
              title: "Created At",
              render: (organization) => friendlyDateTime(organization.createdAt)
            },
          ],
          renderForm: (config) => (
            <OrganizationForm
              config={config}
              embedded={false}

              organizationThemeLookupControllerFactory={organizationThemeLookupControllerFactory}
              themeDetailsController={themeDetailsController}
              organizationThemeViewPathFactory={organizationThemeViewPathFactory}

              fileUploadController={fileUploadController}
            />
          ),

          useRoutes: () => useRoutes().organizations,
          getItemId: (organization) => organization.id,
          getItemTitle: (organization) => organization.name,
          clone: (organization) => ({ ...organization, name: "Copy of " + organization.name }),
          compare: (a: Organization, b: Organization) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          },

          useGetQuery: useGetOrganizationQuery,
          useSearchQuery: useSearchOrganizationsQuery,
          useInsertMutation: useInsertOrganizationMutation,
          useUpdateMutation: useUpdateOrganizationMutation,
          useDeleteMutation: useDeleteOrganizationMutation
        })
      }
    </CenteredContent>
  );
};

interface PreviewLinkProps {
  alias: string;
}

const AdminPortalLink: React.FunctionComponent<PreviewLinkProps> = (props) => {
  const routes = useRoutes().organizationPortalRoutes;
  return <NewTabLink to={routes.organizationHomePath(props.alias)}>Open Admin Portal</NewTabLink>;
};
