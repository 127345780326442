import * as React from "react";
import { AdminArea } from "../../views/utils/whenElevated";
import { useSendCouponCodesMutation } from "../../queries/couponCodes/useSendCouponCodesMutation";
import { SendCouponCodesPageView } from "../../views/screens/couponCodes/sendCouponCodesPageView";

export const SendCouponCodesPage: React.FunctionComponent = () => {
  const [send, sendStatus] = useSendCouponCodesMutation();

  return (
    <AdminArea>
      <SendCouponCodesPageView
        status={sendStatus}
        onSend={send}
      />
    </AdminArea>
  );
};
