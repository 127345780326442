import { Blueprint } from "./blueprint";
import { BlueprintContext } from "./blueprintContext";
import { Element } from "./element";
import { AnyComponent, ComponentOf } from "./component";
import { State } from "./state";
import { List } from "immutable";
import { Option } from "../utils/monads/option";
import { BlueprintException } from "./blueprintException";
import { HubBinding } from "./hubBinding";
import { nullToUndefined } from "../utils/misc";
import { BlueprintError } from "./blueprintError";

export interface RelationshipProps {
  title: string | undefined;
}

export namespace RelationshipProps {
  export interface Data {
    title?: string | null;
  }

  export function fromGraphQL(data: Data): RelationshipProps {
    return {
      title: nullToUndefined(data.title)
    };
  }
}

export interface RelationshipStyling {
  color?: string;
  width?: number;
  dashes?: boolean | number[];
}

export interface RelationshipData {
  componentId?: string | null;
  serverSide: boolean;
}

export abstract class Relationship<Props extends RelationshipProps>
  implements Element<Props> {

  public readonly blueprint: Blueprint;
  public readonly sourceComponentId: string;
  public readonly hubName: string;
  public readonly componentId: string;
  public readonly serverSide: boolean;
  public readonly props: Props;

  constructor(binding: HubBinding, data: RelationshipData, props: Props) {
    this.blueprint = binding.blueprint;
    this.sourceComponentId  = binding.sourceComponentId;
    this.hubName = binding.hubName;
    this.componentId = data.componentId || "";
    this.serverSide = data.serverSide;
    this.props = props;
  }

  public get type(): string {
    return this.constructor.name;
  }

  public component(): AnyComponent | undefined {
    return this.componentId !== undefined ? this.blueprint.components.getOrFail(this.componentId) : undefined;
  }

  public componentOpt(): Option<AnyComponent> {
    return Option.mayBe(this.component());
  }

  public state(context: BlueprintContext): State<any> {
    try {
      if (this.serverSide && this.componentId !== undefined) {
        const serverState = context.serverElements.getRelationshipState(
          this.sourceComponentId,
          this.hubName,
          this.componentId
        );
        if (serverState !== undefined) {
          return serverState;
        } else {
          return State.error(BlueprintError.NoServerStateAvailable);
        }
      } else {
        return this.calcState(context);
      }
    } catch (error) {
      console.error(
        "Unhandled error in Relationship.state " +
        "(from component: " + this.sourceComponentId +
        ", hub: " + this.hubName +
        ", to component: " + this.componentId + ")",
        error
      );
      throw error;
    }
  }

  public abstract calcState(context: BlueprintContext): State<any>;

  public resolvedByServer(context: BlueprintContext): boolean {
    return (
      this.serverSide &&
      this.componentId !== undefined &&
      context.serverElements.getRelationshipState(this.sourceComponentId, this.hubName, this.componentId) !== undefined
    );
  }

  public messages(context: BlueprintContext): List<string> {
    return List();
  }

  public styling(context: BlueprintContext, state: State<any>): RelationshipStyling {
    return {};
  }
}

export interface DataFlowProps<Output> extends RelationshipProps {
}

export abstract class DataFlow<Props extends DataFlowProps<Output>, Output>
  extends Relationship<Props> {

  public state(context: BlueprintContext): State<Output> {
    return super.state(context);
  }

  public calcState(context: BlueprintContext): State<Output> {
    return this.componentOpt()
      .map((component) => {
        const componentState = component.state(context);
        if (componentState.isBlocked) {
          return State.blocked<Output>();
        } else {
          return this.stateWhenUnblocked(context, componentState);
        }
      })
      .getOrElse(() => State.blocked());
  }

  public abstract stateWhenUnblocked(
    context: BlueprintContext,
    componentState: State<unknown> // Formally it's "any", but using "unknown" prevents bugs
  ): State<Output>;
}

export interface DataFlowWithDefaultProps<Output> extends DataFlowProps<Output> {
  default: Option<Output>;
  promotePartial: boolean;
}

export namespace DataFlowWithDefaultProps {
  export interface Data<OutputData> extends RelationshipProps.Data {
    default?: OutputData | null;
    promotePartial: boolean;
  }

  export function fromGraphQL<OutputData, Output>(
    data: Data<OutputData>,
    parseOutputData: (outputData: OutputData) => Output
  ): DataFlowWithDefaultProps<Output> {
    return {
      ...RelationshipProps.fromGraphQL(data),
      default: Option.mayBe(data.default).map(parseOutputData),
      promotePartial: data.promotePartial
    };
  }
}

export abstract class DataFlowWithDefault<Props extends DataFlowWithDefaultProps<Output>, Output>
  extends DataFlow<Props, Output> {

  public component(): ComponentOf<unknown> {
    if (this.componentId !== undefined) {
      return this.blueprint.components.getOrFail(this.componentId);
    } else {
      throw new BlueprintException("componentId must be defined");
    }
  }

  public calcState(context: BlueprintContext): State<Output> {
    return this.componentOpt()
      .map((component) => {
        const componentState = component.state(context);
        if (componentState.isBlocked) {
          return State.blocked<Output>();
        } else if (!componentState.isResolved && this.props.default.isDefined()) {
          return State.resolved(this.props.default.get());
        } else {
          return this.mayBePromoteState(this.stateWhenUnblocked(context, componentState));
        }
      })
      .getOrElse(() => State.blocked());
  }

  public styling(): RelationshipStyling {
    return this.props.default.isDefined() || this.props.promotePartial ? { dashes: true } : {};
  }

  protected mayBePromoteState(state: State<Output>): State<Output> {
    if (state.isResolving && this.props.promotePartial) {
      return State.almostResolved(state.output.get(), state.progress);
    } else {
      return state;
    }
  }
}

export interface DataFlowWithProviderProps<Output> extends DataFlowWithDefaultProps<Output> {
  outputName: string;
}

export namespace DataFlowWithProviderProps {
  export interface Data<OutputData> extends DataFlowWithDefaultProps.Data<OutputData> {
    outputName: string;
  }

  export function fromGraphQL<OutputData, Output>(
    data: Data<OutputData>,
    parseOutputData: (outputData: OutputData) => Output
  ): DataFlowWithProviderProps<Output> {
    return {
      ...DataFlowWithDefaultProps.fromGraphQL(data, parseOutputData),
      outputName: data.outputName
    };
  }
}

export abstract class DataFlowWithProvider<Props extends DataFlowWithProviderProps<Output>, Input, Output>
  extends DataFlowWithDefault<Props, Output> {

  protected getInput(componentOutput: unknown): Input {
    return this.component().out.get(componentOutput, this.props.outputName);
  }
}

export type DataFlowWithInputProps<Output> = DataFlowWithProviderProps<Output>;

export abstract class DataFlowWithInput<Props extends DataFlowWithInputProps<Output>, Input, Output>
  extends DataFlowWithProvider<Props, Input, Output> {

  public stateWhenUnblocked(context: BlueprintContext, componentState: State<unknown>): State<Output> {
    return componentState.map((componentOutput) => this.mapInput(this.getInput(componentOutput)));
  }

  protected abstract mapInput(input: Input): Output;
}

export type DataFlowWithCompatibleInputProps<Output> = DataFlowWithInputProps<Output>;

export abstract class DataFlowWithCompatibleInput<Props extends DataFlowWithCompatibleInputProps<Output>, Output>
  extends DataFlowWithInput<Props, Output, Output> {

  protected mapInput(input: Output): Output {
    return input;
  }
}

export type AnyRelationship = Relationship<any>;

export type DataFlowOf<Output> = DataFlowWithDefault<any, Output>;
