import * as React from "react";
import { Pipe, PipeProps } from "./pipe";

export interface OperationStatusIndicatorProps<T> extends Omit<PipeProps<T>, "loadingMessage"> {
  progressMessage?: string;
}

export function OperationStatusIndicator<T>(props: OperationStatusIndicatorProps<T>): React.ReactElement | null {
  return <Pipe {...props} loadingMessage={props.progressMessage}/>;
}
