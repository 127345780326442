import * as React from "react";
import { css, styled } from "../../app/theme";

interface DropDownBoxProps {
  error?: boolean;
  password?: boolean;
  maxLength?: number;
  maxWidth?: number;
  disabled?: boolean;
  readOnly?: boolean;
}

// <select> does not seem to have the "readonly" property, se we have to make it disabled instead and handle styling
// differently that we do for text boxes

export const DropDownBox = styled.select.attrs((props: DropDownBoxProps) => ({
  disabled: props.readOnly || props.disabled
}))<DropDownBoxProps>`
  height: 2rem;
  padding: 0 0.25rem;
  border: 2px solid ${(props) => props.error ? props.theme.colors.red : props.theme.colors.lightGray};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  max-width: ${(props) => props.maxWidth ? props.maxWidth + "rem" : "inherit"};
  ${(props) => props.theme.animation.transitionAll()};
  outline: none;

  ${(props) => !props.readOnly && css`
    &.focus, &:focus {
      border: 2px solid ${props.error ? props.theme.colors.red : props.theme.colors.primary};
      box-shadow: 0 0 0.2rem ${props.error ? props.theme.colors.red : props.theme.colors.primary};
    }

    &[disabled] {
      background: ${props.theme.colors.lighterGray};
      color: ${props.theme.colors.darkGray};
    }
  `}
`;
