import * as React from "react";
import { OrganizationSummary } from "../../types/models/organization";
import { ReferAndEarnPageView } from "../../views/screens/organizationPortal/referAndEarn/referAndEarnPageView";
import { useSearchReferralCodesQuery } from "../../queries/referralCodes/useSearchReferralCodesQuery";
import { useListProgramsWithRevenueSharingQuery } from "../../queries/programs/useListProgramsWithRevenueSharingQuery";
import { useElevated } from "../../utils/useAppState";
import { useBrowser } from "../../utils/useBrowser";
import { useRoutes } from "../../app/routes/useRoutes";

interface Props {
  organization: OrganizationSummary;
}

export const ReferAndEarnPage: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();
  const browser = useBrowser();
  const routes = useRoutes();

  const [referralCodesStatus] = useSearchReferralCodesQuery(props.organization.id, "");
  const [programsStatus] = useListProgramsWithRevenueSharingQuery(props.organization.id);

  return (
    <ReferAndEarnPageView
      organizationAlias={props.organization.alias}
      readOnly={!elevated}

      referralCodesStatus={referralCodesStatus}
      programsStatus={programsStatus}

      maxDays={100}
      onGenerateEarningsReport={(startDate, endDate) =>
        browser.openNewTab(routes.api.earningsReportUrl(props.organization.id, startDate, endDate), true)
      }
    />
  );
};
