import * as React from "react";
import { YourGoalStepView } from "../views/screens/migrationSetup/goalsStep";
import * as yup from "yup";
import { useBrowser } from "../utils/useBrowser";
import { useRoutes } from "../app/routes/useRoutes";

export interface MigrationFlow {
  type: "single" | "batch";
}

export namespace MigrationFlow {
  export const Schema = yup.object<MigrationFlow>({
    type: yup.mixed().required().oneOf(["single", "batch"])
  });
}

interface YourGoalStepProps {
  step: number;
  totalSteps: number;

  type: "single" | "batch" | undefined;

  onApply: (step: MigrationFlow) => void;
  onComplete: (step: MigrationFlow) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const YourGoalStep: React.FunctionComponent<YourGoalStepProps> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  return (
    <YourGoalStepView
      step={props.step}
      totalSteps={props.totalSteps}
      type={props.type}
      onApply={(type) => props.onApply({ type })}
      onSubmit={(type) => {
        if (type === "batch") {
          props.onClear();
          browser.navigateTo(routes.batchMigrationSetup.homePath);
        } else {
          props.onComplete({ type });
        }
      }}
      onNavigateBack={props.onNavigateBack}
    />
  );
};
