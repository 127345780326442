import * as React from "react";
import { styled } from "../../app/theme";

export const StyleguideTable = styled.table`
  > thead, tbody {
    > tr {
      > td, th {
        padding: 0.7rem;
      }
      
      > th {
        font-weight: ${(props) => props.theme.font.bold};
      }
    }
  }
  
  > tbody > tr > th {
    text-align: right;
  }
`;
