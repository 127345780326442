import * as React from "react";
import { SimpleWorkflowNavigationItem, WorkflowNavigation } from "../layouts/workflowNavigation";
import { WorkflowTitle } from "../layouts/workflowTitle";
import { StyleguideSections } from "./styleguideSections";
import { useRoutes } from "../../app/routes/useRoutes";

interface StyleGuideNavProps {
  section: string;
}

export const StyleguideNav: React.FunctionComponent<StyleGuideNavProps> = (props) => {
  const routes = useRoutes().styleguide;
  const navigationItems = StyleguideSections.map((section, index) => (
      <SimpleWorkflowNavigationItem
        key={section.id}
        number={index + 1}
        title={section.title}
        enabled={true}
        selected={props.section === section.id}
        nextSelected={index < StyleguideSections.length - 1 && StyleguideSections[index + 1].id === props.section}
        hideProgressIndicator={true}
        onSimpleClick={routes.sectionPath(section.id)}
      />
    )
  );
  return (
    <React.Fragment>
      <WorkflowTitle>Styleguide</WorkflowTitle>
      <WorkflowNavigation>
        {navigationItems}
      </WorkflowNavigation>
    </React.Fragment>
  );
};
