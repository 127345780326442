import * as React from "react";
import { ItemDetailsControllerProps } from "../../../views/widgets/lookupField";
import { Theme } from "../../../types/models/theme";
import { useGetThemeQuery } from "../../../queries/themes/useGetThemeQuery";

export const UseThemeDetailsController: React.FunctionComponent<
  ItemDetailsControllerProps<number, Theme>
  > =
  (props) => {
    const [status] = useGetThemeQuery(props.id);
    return props.render({ status });
  };

export function useThemeDetailsController() {
  return React.useCallback(
    (controllerProps: ItemDetailsControllerProps<number, Theme>) =>
      <UseThemeDetailsController {...controllerProps}/>,
    []
  );
}
