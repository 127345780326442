import * as React from "react";
import {
  ClipboardCopyIndicator,
  copyTextToClipboard,
  useClipboardCopyIndicator
} from "../../../../utils/copyTextToClipboard";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { Paragraph } from "../../../../widgets/paragraph";
import { SimpleToolbar } from "../../../../widgets/simpleToolbar";
import { Button } from "../../../../widgets/button";
import { BulletPoints } from "../../../../widgets/bulletPoints";
import { PaddedPanel } from "../../../../widgets/paddedPanel";

interface ManualConnectPanelProps {
  source: boolean;
  connectLink: string;
}

export const ManualConnectPanel: React.FunctionComponent<ManualConnectPanelProps> = (props) => {
  const context =  props.source ? "source" : "destination";

  return (
    <PaddedPanel tallBottom={true}>
      <PanelRow>
        <Paragraph>
          Connect each {context} account to VaultMe by visiting the following link:
        </Paragraph>
        <ConnectLink link={props.connectLink}/>
        <Paragraph>
          Notes:
        </Paragraph>
        <BulletPoints>
          <li>
            <strong>Important!</strong> Each {context} account must be connected to
            VaultMe before starting the migration.
          </li>
          <li>
            It may be a good idea to connect each individual account in a separate private (incognito) tab.
          </li>
          <li>
            If you are unable to connect some of the accounts to VaultMe, you may ask the owners of these accounts
            to do this for you by visiting the link given above.
          </li>
        </BulletPoints>
      </PanelRow>
    </PaddedPanel>
  );
};

interface EndUserCredentialsPanelProps {
  source: boolean;
  connectLink: string;
}

export const EndUserCredentialsPanel: React.FunctionComponent<EndUserCredentialsPanelProps> = (props) => {
  const context =  props.source ? "source" : "destination";

  return (
    <PaddedPanel>
      <PanelRow>
        <Paragraph>
          Ask the owners of the {context} accounts to connect them to VaultMe by visiting the following link:
        </Paragraph>
        <ConnectLink link={props.connectLink}/>
        <Paragraph>
          Notes:
        </Paragraph>
        <BulletPoints>
          <li>
            <strong>Important!</strong> Each {context} account must be connected to
            VaultMe before starting the migration.
          </li>
          <li>
            The owners of the accounts will not be selecting migration preferences.
            They only need to grant VaultMe access to their accounts.
          </li>
        </BulletPoints>
      </PanelRow>
    </PaddedPanel>
  );
};

interface ConnectLinkProps {
  link: string;
}

export const ConnectLink: React.FunctionComponent<ConnectLinkProps> = (props) => {
  const indicator = useClipboardCopyIndicator();

  return (
    <>
      <Paragraph>
        <a href={props.link} target="_blank">{props.link}</a>
      </Paragraph>
      <Paragraph>
        <SimpleToolbar>
          <Button
            color={"secondary"}
            size={"small"}
            onClick={() => {
              copyTextToClipboard(props.link);
              indicator.show();
            }}
          >
            Copy To Clipboard
          </Button>
          <ClipboardCopyIndicator indicator={indicator}/>
        </SimpleToolbar>
      </Paragraph>
    </>
  );
};
