import * as React from "react";
import {
  BatchMigrationPreferencesStepView
} from "../../views/screens/migrationSetup/batch/batchMigrationPreferencesStepView";
import { BatchMigrationPreferences } from "./batchMigrationPreferences";
import { Blueprint } from "../../blueprints/blueprint";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { Facts } from "../../types/facts/facts";
import { CloudService } from "../../types/models/cloudService";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { List } from "immutable";
import { BatchMigrationItem } from "./batchCheckoutStep";

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: Blueprint;
  items: List<BatchMigrationItem>;

  prevResult: BatchMigrationPreferences | undefined;

  isReady: boolean;

  onApply: (step: BatchMigrationPreferences) => void;
  onComplete: (step: BatchMigrationPreferences) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const BatchMigrationPreferencesStep: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const materializedBlueprint = MaterializedMigrationBlueprint.build({
    sourceCloudServiceId: props.sourceCloudService.id,
    destinationCloudServiceId: props.destinationCloudService.id,
    blueprint: props.blueprint,
    cloudServices,
    authProviders,
    sourceConnection: undefined,
    destinationConnection: undefined,
    facts: Facts.Empty,
    excludedAreas: []
  });

  return (
    <BatchMigrationPreferencesStepView
      step={props.step}
      totalSteps={props.totalSteps}

      sourceCloudService={props.sourceCloudService}
      destinationCloudService={props.destinationCloudService}
      items={props.items}

      areas={
        materializedBlueprint.listAreas()
          .filter(([area, sink]) => sink)
          .map(([area]) => area.props.title)
      }

      isReady={props.isReady}

      initialFormData={props.prevResult}
      onNavigateBack={props.onNavigateBack}
      onSubmit={(formData) => props.onComplete(formData)}
    />
  );
};
