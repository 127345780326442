import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import {
  ImportAccessListPageView
} from "../../../views/screens/organizationPortal/accessLists/importAccessListPageView";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useGetAccessListQuery } from "../../../queries/accessLists/useGetAccessListQuery";
import { useAccessListImportController } from "../../../queries/api/useFileUploadController";
import { useGetAccessListEntryCountQuery } from "../../../queries/accessLists/useGetAccessListEntryCountQuery";
import { useGetAccessListUpdateQuery } from "../../../queries/accessLists/useGetAccessListUpdateQuery";
import { AccessListUpdateStatus } from "../../../types/enums/accessListUpdateStatus";
import { useBrowser } from "../../../utils/useBrowser";
import { useGoogleGroupDetailsController } from "./useGoogleGroupDetailsController";
import { useStartAccessListUpdateJobMutation } from "../../../queries/accessLists/useStartAccessListUpdateJobMutation";

interface Props {
  organization: OrganizationSummary;
}

export const ImportAccessListPage: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const apiRoutes = useRoutes().api;
  const accessListsRoutes = useRoutes().organizationPortalRoutes.accessLists(props.organization.alias);
  const params = accessListsRoutes.importParams();

  const fileUploadController = useAccessListImportController(params.id);
  const googleGroupDetailsController = useGoogleGroupDetailsController();

  const [trackedUpdateId, setTrackedUpdateId] = React.useState<number | undefined>(undefined);

  const [accessListStatus, refreshAccessList] = useGetAccessListQuery(params.id);
  const [entryCountStatus, refreshEntryCount] = useGetAccessListEntryCountQuery(params.id);

  const currentUpdateId = accessListStatus.someResult()?.currentUpdateId || trackedUpdateId;

  const [accessListUpdateStatus, refreshAccessListUpdate] = useGetAccessListUpdateQuery(currentUpdateId);
  const isCurrentUpdateCompleted = !!accessListUpdateStatus.someResult()?.completedAt;

  function refresh(accessListUpdateId: number) {
    refreshAccessList({}, true);
    refreshEntryCount({}, true);
    refreshAccessListUpdate({ id: accessListUpdateId }, true);
  }

  React.useEffect(
    () => {
      if (currentUpdateId && !isCurrentUpdateCompleted) {
        const timer = setInterval(() => refresh(currentUpdateId), 5000);
        return () => clearInterval(timer);
      }
    },
    [currentUpdateId, isCurrentUpdateCompleted]
  );

  const [startAccessListUpdateJob] = useStartAccessListUpdateJobMutation();

  return (
    <ImportAccessListPageView
      accessListId={params.id}
      accessListStatus={accessListStatus}
      accessListUpdateStatus={accessListUpdateStatus}

      displayListUpdateInfo={
        trackedUpdateId !== undefined ||
        accessListUpdateStatus.mapLastResult((accessListUpdate) =>
          accessListUpdate.status !== AccessListUpdateStatus.Success
        ) || false
      }

      entryCountStatus={entryCountStatus}
      onEntryCountRefresh={refreshEntryCount}

      homePath={accessListsRoutes.homePath}
      accessListPath={accessListsRoutes.overviewPath(params.id)}

      fileUploadController={fileUploadController}
      onUpload={(accessListUpdateIdString) => {
        const accessListUpdateId = Number.parseInt(accessListUpdateIdString, 10);
        if (!isNaN(accessListUpdateId)) {
          refresh(accessListUpdateId);
          setTrackedUpdateId(accessListUpdateId);
        }
      }}

      googleGroupDetailsController={googleGroupDetailsController}
      onImport={() => startAccessListUpdateJob(params.id).then((accessListUpdateId) => {
        refresh(accessListUpdateId);
        setTrackedUpdateId(accessListUpdateId);
      })}

      onDownloadOutput={(accessListUpdateId) =>
        browser.openNewTab(apiRoutes.accessListUpdateOutputUrl(accessListUpdateId), true)
      }
    />
  );
};
