import { Organization } from "../../types/models/organization";
import { CRUDRoutes } from "./crudRoutes";

export class OrganizationsRoutes extends CRUDRoutes<number, Organization> {
  protected readonly root: string = "organizations";

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}
