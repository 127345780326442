import * as React from "react";
import { TaskIssue } from "../../../types/models/taskIssue";
import { Grid } from "../../widgets/grid";
import { preciseTimestamp } from "../../../utils/formatting";
import { Images } from "../../../app/images";
import { JobHistoryGridIcon, JobHistoryGridSubject } from "./utils";
import { Link } from "react-router-dom";
import { WidgetStatus } from "../../utils/widgetStatus";
import { useRoutes } from "../../../app/routes/useRoutes";

interface TaskIssueEventRowProps {
  event: TaskIssue.Event;
  flash: boolean;
  columnCount: number;
}

export const TaskIssueEventRow: React.FunctionComponent<TaskIssueEventRowProps> = (props) => {
  const routes = useRoutes();

  const status = props.event.eventType === TaskIssue.Event.Type.Reported ? WidgetStatus.Error : WidgetStatus.Success;

  return (
    <>
      <Grid.Cell status={status} flash={props.flash}>{preciseTimestamp(props.event.timestamp)}</Grid.Cell>
      <Grid.Cell status={status} flash={props.flash} title={props.event.details}>
        <JobHistoryGridSubject>
          <JobHistoryGridIcon
            src={
              props.event.eventType === TaskIssue.Event.Type.Reported
                ? Images.Jobs.IssueReported
                : Images.Jobs.IssueResolved
            }
          />
          <Link to={routes.jobs.taskIssuePath(props.event.jobId, props.event.taskId, props.event.issueId)}>
            Issue "{props.event.issueId}" ({props.event.summary})
          </Link>
        </JobHistoryGridSubject>
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>{props.event.transition}</Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>{props.event.eventType}</Grid.Cell>
      {
        Array.from(Array(props.columnCount - 4).keys()).map((index) =>
          <Grid.Cell key={index} status={status} flash={props.flash}/>
        )
      }
    </>
  );
};
