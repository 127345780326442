import { GraphQL } from "../../services/graphql/generated";

export interface SchoolFormData {
  readonly domains: string;

  readonly officialName: string | undefined;
  readonly commonName: string | undefined;
  readonly mascot: string | undefined;
  readonly logoId: string | undefined;
  readonly logoWidth: number | undefined;
  readonly landingPageUrl: string | undefined;

  readonly country: string | undefined;
  readonly type: string | undefined;
  readonly website: string | undefined;
  readonly ncesUrl: string | undefined;
  readonly avgAnnualGrads: number | undefined;
  readonly usesGoogle: boolean | undefined;
  readonly usesMicrosoft: boolean | undefined;

  readonly welcomeMessage: string | undefined;
  readonly notes: string | undefined;

  readonly reviewed: boolean;
}

export namespace SchoolFormData {
  export function toGraphQL(data: SchoolFormData): GraphQL.SchoolFormData {
    return {
      domains: data.domains,

      officialName: data.officialName,
      commonName: data.commonName,
      mascot: data.mascot,
      logoId: data.logoId,
      logoWidth: data.logoWidth,
      landingPageUrl: data.landingPageUrl,

      country: data.country,
      type: data.type,
      website: data.website,
      ncesUrl: data.ncesUrl,
      avgAnnualGrads: data.avgAnnualGrads,
      usesGoogle: data.usesGoogle,
      usesMicrosoft: data.usesMicrosoft,

      welcomeMessage: data.welcomeMessage,
      notes: data.notes,

      reviewed: data.reviewed
    };
  }
}
