import * as React from "react";
import {
  ConnectAdminAccountControllerProps
} from "../../views/screens/migrationSetup/batch/batchConnectionStepView/googleWorkspaceAdminCredentialsPanel";
import { Connection } from "../../types/models/connection";
import { useSignInFlow } from "../../components/auth/useSignInFlow";
import { SignInContextType } from "../../types/models/signInContextType";
import { CloudServices } from "../../types/models/cloudServices";
import { Set } from "immutable";
import { Constants } from "../../app/constants";
import { AdminConnectionVerificationResult } from "../../types/adminConnectionVerificationResult";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

interface ConnectGoogleAdminAccountControllerProps extends ConnectAdminAccountControllerProps {
  onSuccess: (adminConnectionId: string) => void;
  onDisconnect: () => void;
}

export const ConnectGoogleAdminAccountController: React.FunctionComponent<ConnectGoogleAdminAccountControllerProps> =
  (props) => {
    const [pendingConnection, setPendingConnection] = React.useState<Connection>();

    const [verifyAdminConnection, adminConnectionVerificationStatus] = useVerifyGoogleAdminConnectionMutation();

    const signInFlow = useSignInFlow({
      flowId: "ConnectAdmin",
      contextType: SignInContextType.Other,
      newUserSettings: undefined,
      cloudServiceId: CloudServices.GSuite,
      defaultRoles: Set.of(Constants.Auth.IdentificationRole),
      onSignIn: ((result) => {
        setPendingConnection(result.connection);
        verifyAdminConnection(result.connection.id).then((validated) => {
          if (validated === AdminConnectionVerificationResult.Success) {
            props.onSuccess(result.connection.id);
          }
        });
      })
    });

    return props.render({
      state: signInFlow.state,
      pendingConnection,
      connectionVerificationStatus: adminConnectionVerificationStatus,

      onSignIn: signInFlow.handleSignInSuccess,
      onSignOut: () => {
        setPendingConnection(undefined);
        props.onDisconnect();
      }
    });
  };

export function useConnectGoogleAdminAccountController(
  onSuccess: (adminConnectionId: string) => void,
  onDisconnect: () => void
) {
  return React.useCallback(
    (controllerProps: ConnectAdminAccountControllerProps) => (
      <ConnectGoogleAdminAccountController
        {...controllerProps}
        onSuccess={onSuccess}
        onDisconnect={onDisconnect}
      />
    ),
    []
  );
}

type VerifyGoogleAdminConnectionMutationHook = [
  (connectionId: string) => Promise<AdminConnectionVerificationResult>,
  OperationStatus<AdminConnectionVerificationResult>
];

function useVerifyGoogleAdminConnectionMutation(): VerifyGoogleAdminConnectionMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useVerifyGoogleAdminConnectionMutation,
    extract: (data: GraphQL.VerifyGoogleAdminConnectionMutation) => nullToUndefined(data.verifyGoogleAdminConnection),
    complete: identity
  });

  function fireWith(connectionId: string): Promise<AdminConnectionVerificationResult> {
    return fire({
      variables: { connectionId },
      retry: () => fireWith(connectionId)
    });
  }

  return [fireWith, status];
}
