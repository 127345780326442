import * as React from "react";
import { Modal } from "../../../layouts/modal";
import { Connection } from "../../../../types/models/connection";
import { DrawerBlock } from "../../../containers/drawerBlock";
import { defaultAppTheme, styled } from "../../../../app/theme";
import { useConfigurationRoutes, useRoutes } from "../../../../app/routes/useRoutes";
import { ProgramSummary } from "../../../../types/models/program";
import { Button } from "views/widgets/button";
import { LinkButton } from "views/widgets/linkButton";
import { useBrowser } from "../../../../utils/useBrowser";
import { NewTabLink } from "../../../widgets/newTabLink";
import { SourceConnectionPromotionMessage } from "../../../../types/models/sourceConnectionPromotionMessage";
import { VaultMeIcon } from "../../../glyphs/vaultMeIcon";
import { ThemeOverrides } from "../../../../types/models/themeOverrides";

interface Props {
  sourceConnectionPromotionMessage: SourceConnectionPromotionMessage.Any;
  connection: Connection;
  onDisconnect: (() => void) | undefined;
  onClose: () => void;
}

export const SourceConnectionPromotionMessageModal: React.FunctionComponent<Props> = (props) => {
  switch (props.sourceConnectionPromotionMessage.type) {
    case SourceConnectionPromotionMessage.Type.SwitchFromVanilla:
      return (
        <Modal
          title={"Congratulations!"}
          background={"white"}
          bodyStyle={{ color: props.sourceConnectionPromotionMessage.preferredProgramTheme?.darkerPrimary }}
          titleStyle={{ color: props.sourceConnectionPromotionMessage.preferredProgramTheme?.darkerPrimary }}
        >
          <SwitchToProgramContent
            currentProgram={undefined}
            preferredProgram={props.sourceConnectionPromotionMessage.preferredProgram}
            preferredProgramTheme={props.sourceConnectionPromotionMessage.preferredProgramTheme}
            restartSetupFlow={true}
            connection={props.connection}
            onDisconnect={props.onDisconnect}
          />
        </Modal>
      );

    case SourceConnectionPromotionMessage.Type.SwitchToVanilla:
      return (
        <Modal
          title={"Congratulations!"}
          background={"white"}
          bodyStyle={{ color: defaultAppTheme.colors.textColor }}
          titleStyle={{ color: defaultAppTheme.colors.titleColor }}
        >
          <SwitchToVanillaContent
            currentProgram={props.sourceConnectionPromotionMessage.currentProgram}
            connection={props.connection}
            onDisconnect={props.onDisconnect}
          />
        </Modal>
      );

    case SourceConnectionPromotionMessage.Type.SwitchToAnotherProgram:
      return (
        <Modal
          title={"Congratulations!"}
          background={"white"}
          bodyStyle={{ color: props.sourceConnectionPromotionMessage.preferredProgramTheme?.darkerPrimary }}
          titleStyle={{ color: props.sourceConnectionPromotionMessage.preferredProgramTheme?.darkerPrimary }}
        >
          <SwitchToProgramContent
            currentProgram={props.sourceConnectionPromotionMessage.currentProgram}
            preferredProgram={props.sourceConnectionPromotionMessage.preferredProgram}
            preferredProgramTheme={props.sourceConnectionPromotionMessage.preferredProgramTheme}
            restartSetupFlow={props.sourceConnectionPromotionMessage.restartSetupFlow}
            connection={props.connection}
            onDisconnect={props.onDisconnect}
          />
        </Modal>
      );

    case SourceConnectionPromotionMessage.Type.PossibleFollowUpMigration:
      return (
        <Modal title={"Welcome back!"} background={"white"} onClose={props.onClose}>
          <PossibleFollowUpMigrationContent
            currentProgram={props.sourceConnectionPromotionMessage.currentProgram}
            connection={props.connection}
            onDisconnect={props.onDisconnect}
            onClose={props.onClose}
          />
        </Modal>
      );
  }
};

interface SwitchToProgramContentProps {
  currentProgram: ProgramSummary | undefined;
  preferredProgram: ProgramSummary;
  preferredProgramTheme: ThemeOverrides | undefined;
  restartSetupFlow: boolean;
  connection: Connection;
  onDisconnect: (() => void) | undefined;
}

const SwitchToProgramContent: React.FunctionComponent<SwitchToProgramContentProps> = (props) => {
  const routes = useRoutes();
  const programRoutes = useConfigurationRoutes({ programAlias: props.preferredProgram.alias });
  const browser = useBrowser();

  return (
    <>
      <PreferredProgramContentLayout>
        <div>
          <DrawerBlock>
            <AffiliationMessage>
              Your account is affiliated with <strong>{props.preferredProgram.productNameOrProgramName}</strong>.
            </AffiliationMessage>
          </DrawerBlock>
          {props.preferredProgram.description && <DrawerBlock>{props.preferredProgram.description}</DrawerBlock>}
          {props.preferredProgram.landingPageUrl && (
            <DrawerBlock>
              <NewTabLink to={props.preferredProgram.landingPageUrl}>Learn more</NewTabLink>
            </DrawerBlock>
          )}
        </div>
        {props.preferredProgram.logoId && <ProgramLogo src={routes.api.downloadUrl(props.preferredProgram.logoId)}/>}
      </PreferredProgramContentLayout>
      <DrawerBlock>
        <Button
          size={"narrow"}
          style={{
            backgroundColor: props.preferredProgramTheme?.primary,
            borderColor: props.preferredProgramTheme?.primary
          }}
          onClick={() =>
            browser.navigateTo(
              props.restartSetupFlow
                ? programRoutes.migrationSetup.homePath
                : programRoutes.migrationSetup.sourcePath
            )
          }
        >
          Go to {props.preferredProgram.productNameOrProgramName} &nbsp;&rsaquo;
        </Button>
      </DrawerBlock>
      {props.onDisconnect && (
        <Alternative>
          or,{" "}
          <LinkButton
            onClick={props.onDisconnect}
            style={{ color: props.preferredProgramTheme?.primary }}
          >
            disconnect {props.connection.descriptionOrId()}
          </LinkButton>
          {" "}and connect an account affiliated with{" "}
          {
            props.currentProgram
              ? (
                <>
                  <strong>{props.currentProgram.productNameOrProgramName}.</strong>{" "}
                  {
                    props.currentProgram.landingPageUrl &&
                      <NewTabLink to={props.currentProgram.landingPageUrl}>Learn more</NewTabLink>
                  }
                </>
              )
              : <strong>VaultMe for Individuals.</strong>
          }
        </Alternative>
      )}
    </>
  );
};

interface SwitchToVanillaContentProps {
  currentProgram: ProgramSummary;
  connection: Connection;
  onDisconnect: (() => void) | undefined;
}

const SwitchToVanillaContent: React.FunctionComponent<SwitchToVanillaContentProps> = (props) => {
  const programRoutes = useConfigurationRoutes({ programAlias: undefined });
  const browser = useBrowser();

  return (
    <>
      <PreferredProgramContentLayout>
        <div>
          <DrawerBlock>
            <AffiliationMessage>
              Your account is affiliated with <strong>VaultMe for Individuals</strong>.
            </AffiliationMessage>
          </DrawerBlock>
          <DrawerBlock>
            Use VaultMe for Individuals to process a full-featured migration from this account.
          </DrawerBlock>
        </div>
        <VaultMeIconContainer><VaultMeIcon inverted={true}/></VaultMeIconContainer>
      </PreferredProgramContentLayout>
      <DrawerBlock>
        <Button
          size={"narrow"}
          onClick={() => browser.navigateTo(programRoutes.migrationSetup.homePath)}
          style={{
            backgroundColor: defaultAppTheme.colors.primary,
            borderColor: defaultAppTheme.colors.primary,
          }}
        >
          Go to VaultMe for Individuals &nbsp;&rsaquo;
        </Button>
      </DrawerBlock>
      {props.onDisconnect && (
        <Alternative>
          or,{" "}
          <LinkButton
            onClick={props.onDisconnect}
            style={{ color: defaultAppTheme.colors.primary }}
          >
            disconnect {props.connection.descriptionOrId()}
          </LinkButton>
          {" "}and connect an account affiliated with{" "}
          <strong>{props.currentProgram.productNameOrProgramName}</strong>.{" "}
          {
            props.currentProgram.landingPageUrl &&
              <NewTabLink to={props.currentProgram.landingPageUrl}>Learn more</NewTabLink>
          }
        </Alternative>
      )}
    </>
  );
};

interface PossibleFollowUpMigrationContentProps {
  currentProgram: ProgramSummary;
  connection: Connection;
  onDisconnect: (() => void) | undefined;
  onClose: () => void;
}

const PossibleFollowUpMigrationContent: React.FunctionComponent<PossibleFollowUpMigrationContentProps> = (props) => {
  const routes = useRoutes();

  return (
    <>
      <PreferredProgramContentLayout>
        <div>
          <DrawerBlock>
            <AffiliationMessage>
              Your account is affiliated with <strong>{props.currentProgram.productNameOrProgramName}</strong>.
            </AffiliationMessage>
          </DrawerBlock>
          <DrawerBlock>
            This will be the second migration from this account. You will only pay $1 plus data fees.
          </DrawerBlock>
          {props.currentProgram.landingPageUrl && (
            <DrawerBlock>
              <NewTabLink to={props.currentProgram.landingPageUrl}>Learn more</NewTabLink>
            </DrawerBlock>
          )}
        </div>
        {props.currentProgram.logoId && <ProgramLogo src={routes.api.downloadUrl(props.currentProgram.logoId)}/>}
      </PreferredProgramContentLayout>
      <DrawerBlock>
        <Button size={"narrow"} onClick={props.onClose}>
          Proceed to Second Migration &nbsp;&rsaquo;
        </Button>
      </DrawerBlock>
      {props.onDisconnect && (
        <Alternative>
          or,{" "}
          <LinkButton onClick={props.onDisconnect}>disconnect {props.connection.descriptionOrId()}</LinkButton>
          {" "}and re-connect a different account to use{" "}
          <strong>{props.currentProgram.productNameOrProgramName}</strong>.{" "}
          {
            props.currentProgram.landingPageUrl &&
              <NewTabLink to={props.currentProgram.landingPageUrl}>Learn more</NewTabLink>
          }
        </Alternative>
      )}
    </>
  );
};

const PreferredProgramContentLayout = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 2rem;
  justify-content: space-between;
  padding-left: 0.5rem;
`;

const AffiliationMessage = styled.div`
  font-size: 1.2rem;
`;

const ProgramLogo = styled.img`
  display: block;
  width: 15%;
  max-height: 10rem;
  margin-left: 1.5rem;
`;

const VaultMeIconContainer = styled.div`
  width: 15%;
  height: 15%;
  margin-left: 1.5rem;
  background: #002132;
  border-radius: 10%;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Alternative = styled(DrawerBlock)`
  font-size: 0.85rem;
  max-width: 65%;
  padding-left: 1rem;
  line-height: 1.6;
`;
