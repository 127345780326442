import * as React from "react";
import { Page } from "../containers/page";
import { ContentPlaceholder } from "./contentPlaceholder";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";

export const Pages: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Page Layout</StyleguideHeader>
    <StyleguideBlock>
      <Page
        breadcrumbs={["Section", "Sub-section"]}
        title="Title"
        subTitle="Sub-Title"
      >
        <ContentPlaceholder>Content</ContentPlaceholder>
      </Page>
    </StyleguideBlock>
  </React.Fragment>
);
