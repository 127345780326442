import * as React from "react";
import { ConnectionPanelTotalsRow } from "../../../blocks/connectionPanel/rows/totalsRow";
import { friendlyCount, friendlyCountOf, friendlySizeOf } from "../../../../utils/formatting";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { WorkStatus } from "../../../models/workStatus";
import { CloudService } from "../../../../types/models/cloudService";

interface TotalsRowProps {
  cloudService: CloudService;
  complete: boolean;
  totals: MigrationStatusPageDefs.SourceAreaTotals;
  context: MigrationStatusPageDefs.ContextType;
}

export const TotalsRow: React.FunctionComponent<TotalsRowProps> = (props) => {
  const stats = props.totals.stats(props.context);
  return (
    <ConnectionPanelTotalsRow
      icon={props.cloudService.icon}
      title="All selected items"
      content={{
        summary: [(
          (
            stats.totalItemCount !== undefined
              ? friendlyCountOf(stats.migratedItemCount, stats.totalItemCount, "item")
              : friendlyCount(stats.migratedItemCount, "item")
          ) +
          " migrated" +
          (
            stats.totalSize !== undefined
              ? " (" + friendlySizeOf(stats.migratedSize || 0, stats.totalSize) + ")"
              : ""
          ) +
          ", " +
          friendlyCount(stats.skippedItemCount, "item") +
          " skipped"
        )]
      }}
      status={WorkStatus.Success}
      progressIndicator={"hidden"}
      actionItemSuppressing={undefined}
    />
  );
};
