import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { State } from "../state";
import { Map } from "immutable";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { Some } from "../../utils/monads/option";

export class VarsHub extends DataSource<VarsHub.Props, any, Map<string, any>> {
  public calcState(context: BlueprintContext): State<Map<string, any>> {
    if (!this.relationships.isEmpty()) {
      const states = this.dataFlows()
        .toList()
        .map((dataFlow): [string, any] => [dataFlow.props.title, dataFlow.state(context)]);
      return State.reduceStateMap(
        Map(states),
        false,
        (values) => Some(values)
      );
    } else {
      return State.resolved(Map());
    }
  }
}

export namespace VarsHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.VarsHubFragment,
    settings?: ComponentHubSettings
  ): VarsHub {
    return new VarsHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
