import { CouponCode } from "../../types/models/couponCode";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { CouponCodeFormData } from "../../types/models/couponCodeFormData";

type UpdateCouponCodeMutationHook = [
  (formData: CouponCodeFormData) => Promise<CouponCode>,
  OperationStatus<CouponCode>
];

export function useUpdateCouponCodeMutation(): UpdateCouponCodeMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateCouponCodeMutation,
    extract: (data: GraphQL.UpdateCouponCodeMutation) => nullToUndefined(data.updateCouponCode),
    complete: CouponCode.parse
  });

  function fireWith(formData: CouponCodeFormData): Promise<CouponCode> {
    return fire({
      variables: { formData },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
