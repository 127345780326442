import * as React from "react";
import { HeaderBlock } from "../../widgets/headerBlock";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { MigrationsGrid } from "../userDashboardPageView/userMigrationsGrid";
import { PanelRow } from "../../containers/rows/panelRow";
import { OperationStatus } from "../../../types/operationStatus";
import { List } from "immutable";
import { Migration } from "../../../types/models/migration";
import { SearchForm } from "../../blocks/searchForm";
import { BulletPoints } from "../../widgets/bulletPoints";
import { Paragraph } from "../../widgets/paragraph";

interface Props {
  searchTerm: string | undefined;
  searchStatus: OperationStatus<List<Migration & Migration.HasConnections>>;
  onSearch: (searchTerm: string) => void;
}

export const OrganizationMigrationsPageView: React.FunctionComponent<Props> = (props) => (
  <>
    <HeaderBlock title={"Migration Search"}/>
    <Block>
      <Panel>
        <PanelRow>
          <Paragraph>
            Find a migration by typing one of the following:
          </Paragraph>
          <BulletPoints style={{ marginBottom: 0 }}>
            <li>5-letter migration ID (ex. ABCDE)</li>
            <li style={{ marginBottom: 0 }}>User ID or email address</li>
          </BulletPoints>
        </PanelRow>
        <PanelRow>
          <SearchForm searchTerm={props.searchTerm || ""} onSearch={props.onSearch}/>
        </PanelRow>
      </Panel>
    </Block>
    <OperationStatusIndicator
      status={props.searchStatus}
      subject={"migrations"}
      indicators={StatusIndicators.PanelRow()}
    />
    {props.searchStatus.mapLastResult((result) => (
      <Block>
        <Panel>
          {
            result.size !== 0
              ? (
                <GridPanelRow>
                  <MigrationsGrid
                    migrations={result.sortBy((migration) => -migration.createdAt.getTime())}
                  />
                </GridPanelRow>
              )
              : <PanelRow>No migrations were found.</PanelRow>
          }
        </Panel>
      </Block>
    ))}
  </>
);
