import * as React from "react";
import { Flash } from "./flash";
import { Constants } from "../../app/constants";
import { Button } from "../widgets/button";
import { styled } from "../../app/theme";

export function copyTextToClipboard(text: string): boolean {
  const textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if the element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won"t even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn"t work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don"t need padding, reducing the size if it does flash render.
  textArea.style.padding = "0";

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  let result: boolean = false;

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Copying text command was " + msg);
    result = successful;
  } catch (err) {
    console.log("Oops, unable to copy");
  }

  document.body.removeChild(textArea);

  return result;
}

interface ClipboardCopyIndicatorHook {
  visible: boolean;
  show: () => void;
  hide: () => void;
}

export function useClipboardCopyIndicator(): ClipboardCopyIndicatorHook {
  const [value, setValue] = React.useState(false);
  return {
    visible: value,
    show: () => setValue(true),
    hide: () => setValue(false),
  };
}

interface ClipboardCopyIndicatorProps {
  indicator: ClipboardCopyIndicatorHook;
}

export const ClipboardCopyIndicator: React.FunctionComponent<ClipboardCopyIndicatorProps> = (props) => {
  if (props.indicator.visible) {
    return (
      <Flash timeout={Constants.SuccessIndicatorTimeout} onHide={props.indicator.hide}>
        {props.children || "Copied!"}
      </Flash>
    );
  } else {
    return null;
  }
};

interface CopyToClipboardButtonProps {
  text: string;
}

export const CopyToClipboardButton: React.FunctionComponent<CopyToClipboardButtonProps> = (props) => {
  const [showIndicator, setShowIndicator] = React.useState(false);

  React.useEffect(
    () => {
      if (showIndicator) {
        const timeout = setTimeout(() => setShowIndicator(false), Constants.SuccessIndicatorTimeout);
        return () => clearTimeout(timeout);
      }
    },
    [showIndicator]
  );

  return (
    <StyledButton
      size={"small"}
      color={"secondary"}
      onClick={() => {
        copyTextToClipboard(props.text);
        setShowIndicator(true);
      }}
    >
      {showIndicator ? "Copied!" : "Copy To Clipboard"}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  min-width: 10rem;
`;
