import * as React from "react";
import { ConnectionPanelDefs } from "./connectionPanelDefs";
import { WhenElevated } from "../../utils/whenElevated";
import { RoleSelectorRow } from "../auth/roleSelectorRow";
import { Set } from "immutable";

interface Props {
  defaultRoles: Set<string>;
  connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
}

export const ConnectionRolesRow: React.FunctionComponent<Props> = (props) => (
  <WhenElevated>
    <RoleSelectorRow
      authProviderId={props.connectionDetails.cloudService.authProviderId}
      roles={{
        allRoles: props.connectionDetails.authProvider.roles,
        selectedRoles: props.defaultRoles,
        grantedRoles: props.connectionDetails.connection.roles
      }}
    />
  </WhenElevated>
);
