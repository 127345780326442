import * as React from "react";
import { useBrowser } from "../../utils/useBrowser";
import { LinkButton } from "./linkButton";
import { isAbsoluteUrl } from "../../utils/misc";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

interface Props extends StyledComponentsProps {
  to: string;
}

export const NewTabLink: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  if (isAbsoluteUrl(props.to)) {
    return <a href={props.to} target="_blank" className={props.className}>{props.children}</a>;
  } else {
    return (
      <LinkButton
        onClick={(event) => {
          browser.openNewTab(props.to);
          event.stopPropagation();
        }}
        className={props.className}
      >
        {props.children}
      </LinkButton>
    );
  }
};
