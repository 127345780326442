import * as React from "react";
import * as yup from "yup";
import { Program } from "../../../types/models/program";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { friendlyDateTime } from "../../../utils/formatting";
import { SimplePanel } from "../../containers/simplePanel";
import { ProgramFormData } from "../../../types/models/programFormData";
import { ItemForm } from "../../blocks/crud/itemForm";
import { FormConfig } from "../../blocks/crud/formConfig";
import { DiscountType, DiscountTypeDefs } from "../../../types/models/discountType";
import {
  ConfigurationDetailsControllerType,
  ConfigurationLookupControllerType,
  ConfigurationLookupField, ConfigurationViewPathFactory,
} from "../../blocks/lookups/configurationLookupField";
import { DropDownField } from "../../widgets/dropDownField";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { TitlePanelRow } from "../../containers/rows/titlePanelRow";
import { NewTabLink } from "../../widgets/newTabLink";
import { useConfigurationRoutes } from "../../../app/routes/useRoutes";
import { ProgramStatus } from "../../../types/models/programStatus";
import { Upload } from "../../../types/models/upload";
import { FileUploadController, ImageUploadPreview } from "../../widgets/fileUpload";
import { FileUploadField } from "../../widgets/fileUploadField";
import { CheckboxField } from "../../widgets/checkboxField";
import {
  PricingModelDetailsControllerType,
  PricingModelLookupControllerType, PricingModelLookupField
} from "../../blocks/pricingModels/pricingModelLookupField";
import { ModalController, useModal } from "../../layouts/modal";
import { Button } from "../../widgets/button";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { DrawerBlock } from "../../containers/drawerBlock";
import { List } from "immutable";
import { EmailAddressWhitelistingForProgram } from "../../../types/models/emailAddressWhitelistingForProgram";
import { TestAccountGridPanel } from "../testAccountWhitelistingForProgramsView";
import { ViewOnlyWarningPanel } from "./viewOnlyWarningPanel";
import { styled } from "../../../app/theme";

interface FormData {
  id: string;
  alias: string;
  name: string;
  description: string;
  productName: string;
  landingPageUrl: string;
  logo: Upload | undefined;
  notes: string;
  endUserAccess: string;

  configurationId: number | undefined;
  introBanner: Upload | undefined;
  introTitle: string;
  introContent: string;
  pricingPageBanner: Upload | undefined;
  pricingPageTitle: string;
  pricingPageContent: string;

  pricingModelId: number | undefined;
  sendMigrationNotificationsToAdmins: boolean;
  sendFeedbackNotificationsToAdmins: boolean;

  status: ProgramStatus;

  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  sourceDomains: string;
  destinationDomains: string;

  discount: string;
  discountType: DiscountType;
  sponsoredAmount: string;
  revenueShare: string;
}

namespace FormData {
  export const blank: FormData = {
    id: "",
    alias: "",
    name: "",
    description: "",
    productName: "",
    landingPageUrl: "",
    logo: undefined,
    notes: "",
    endUserAccess: "",

    configurationId: undefined,
    introBanner: undefined,
    introTitle: "",
    introContent: "",
    pricingPageBanner: undefined,
    pricingPageTitle: "",
    pricingPageContent: "",

    pricingModelId: undefined,
    sendMigrationNotificationsToAdmins: false,
    sendFeedbackNotificationsToAdmins: false,

    status: ProgramStatus.Restricted,

    sourceCloudServiceId: "",
    destinationCloudServiceId: "",
    sourceDomains: "",
    destinationDomains: "",

    discount: "10",
    discountType: DiscountType.BasePriceOnly,
    sponsoredAmount: "0.00",
    revenueShare: "0"
  };

  function parseDomains(domains: string): string {
    return domains
      .split("|")
      .map((domain) => domain.trim())
      .filter((domain) => domain.length > 0)
      .join("\n");
  }

  function makeDomains(domains: string): string | undefined {
    if (domains.trim().length !== 0) {
      return "|" + domains
        .split("\n")
        .map((domain) => domain.trim())
        .filter((domain) => domain.length > 0)
        .join("|") + "|";
    }
  }

  export function make(program: Program): FormData {
    return {
      id: program.id,
      alias: program.alias || "",
      name: program.name,
      description: program.description || "",
      productName: program.productName || "",
      landingPageUrl: program.landingPageUrl || "",
      logo: program.logo,
      notes: program.notes || "",
      endUserAccess: program.endUserAccess || "",

      configurationId: program.configurationId,
      introBanner: program.introBanner,
      introTitle: program.introTitle || "",
      introContent: program.introContent || "",
      pricingPageBanner: program.pricingPageBanner,
      pricingPageTitle: program.pricingPageTitle || "",
      pricingPageContent: program.pricingPageContent || "",

      pricingModelId: program.pricingModelId,
      sendMigrationNotificationsToAdmins: program.sendMigrationNotificationsToAdmins,
      sendFeedbackNotificationsToAdmins: program.sendFeedbackNotificationsToAdmins,

      status: program.status,

      sourceCloudServiceId: program.sourceCloudServiceId || "",
      destinationCloudServiceId: program.destinationCloudServiceId || "",
      sourceDomains: program.sourceDomains ? parseDomains(program.sourceDomains) : "",
      destinationDomains: program.destinationDomains ? parseDomains(program.destinationDomains) : "",

      discount: program.discount.toString(),
      discountType: program.discountType,
      sponsoredAmount: program.sponsoredAmount.toFixed(2),
      revenueShare: program.revenueShare.toString()
    };
  }

  export function toResult(formData: FormData): ProgramFormData {
    return {
      id: formData.id,
      alias: formData.alias.length !== 0 ? formData.alias : undefined,
      name: formData.name,
      description: formData.description.length !== 0 ? formData.description : undefined,
      productName: formData.productName.length !== 0 ? formData.productName : undefined,
      landingPageUrl: formData.landingPageUrl.length !== 0 ? formData.landingPageUrl : undefined,
      logoId: formData.logo?.id,
      notes: formData.notes.length !== 0 ? formData.notes : undefined,
      endUserAccess: formData.endUserAccess.length !== 0 ? formData.endUserAccess : undefined,

      configurationId: formData.configurationId,
      introBannerId: formData.introBanner?.id,
      introTitle: formData.introTitle.length ? formData.introTitle : undefined,
      introContent: formData.introContent.length ? formData.introContent : undefined,
      pricingPageBannerId: formData.pricingPageBanner?.id,
      pricingPageTitle: formData.pricingPageTitle.length ? formData.pricingPageTitle : undefined,
      pricingPageContent: formData.pricingPageContent.length ? formData.pricingPageContent : undefined,

      pricingModelId: formData.pricingModelId,
      sendMigrationNotificationsToAdmins: formData.sendMigrationNotificationsToAdmins,
      sendFeedbackNotificationsToAdmins: formData.sendFeedbackNotificationsToAdmins,

      status: formData.status,

      sourceCloudServiceId: formData.sourceCloudServiceId.length !== 0
        ? formData.sourceCloudServiceId
        : undefined,
      destinationCloudServiceId: formData.destinationCloudServiceId.length !== 0
        ? formData.destinationCloudServiceId
        : undefined,
      sourceDomains: makeDomains(formData.sourceDomains),
      destinationDomains: makeDomains(formData.destinationDomains),

      discount: Number.parseFloat(formData.discount),
      discountType: formData.discountType,
      sponsoredAmount: Number.parseFloat(formData.sponsoredAmount),
      revenueShare: Number.parseFloat(formData.revenueShare)
    };
  }

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    id: yup.string().required("Program ID is required"),
    alias: yup.string(),
    name: yup.string().required("Program name is required"),
    description: yup.string(),
    productName: yup.string(),
    landingPageUrl: yup.string(),
    logo: yup.mixed().notRequired(),
    notes: yup.string(),
    endUserAccess: yup.string(),

    configurationId: yup.number(),
    introBanner: yup.mixed().notRequired(),
    introTitle: yup.string(),
    introContent: yup.string(),
    pricingPageBanner: yup.mixed().notRequired(),
    pricingPageTitle: yup.string(),
    pricingPageContent: yup.string(),

    pricingModelId: yup.number(),
    sendMigrationNotificationsToAdmins: yup.boolean(),
    sendFeedbackNotificationsToAdmins: yup.boolean(),

    status: yup.mixed().oneOf([
      ProgramStatus.Disabled,
      ProgramStatus.Restricted,
      ProgramStatus.Live,
    ]),

    sourceCloudServiceId: yup.string(),
    destinationCloudServiceId: yup.string(),
    sourceDomains: yup.string(),
    destinationDomains: yup.string(),

    discount: yup.string().test(
      "maxDigitsAfterDecimal",
      "Discount must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    discountType: yup.mixed().oneOf(DiscountTypeDefs.Values),
    sponsoredAmount: yup.string().test(
      "maxDigitsAfterDecimal",
      "Sponsored amount must be a positive number containing no more than two decimal places (x.xx) " +
      "or it must be equal to -1",
      (amount) => Number.parseFloat(amount) === -1 || /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    revenueShare: yup.string().test(
      "maxDigitsAfterDecimal",
      "Revenue share must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    )
  });
}

export interface WhitelistEmailAddressToolViewProps {
  loadStatus: OperationStatus<List<EmailAddressWhitelistingForProgram>>;
  whitelistingStatus: OperationStatus<any>;
  onWhitelist: (emailAddress: string, programId: string) => Promise<any>;
}

export interface WhitelistEmailAddressToolControllerProps {
  render: (viewProps: WhitelistEmailAddressToolViewProps) => React.ReactElement;
}

export type WhitelistEmailAddressToolControllerType = React.ComponentType<WhitelistEmailAddressToolControllerProps>;

interface Props {
  config: FormConfig<Program, ProgramFormData>;

  revenueSharingEnabled: boolean;

  configurationLookupController: ConfigurationLookupControllerType;
  configurationDetailsController: ConfigurationDetailsControllerType;
  configurationViewPathFactory: ConfigurationViewPathFactory;

  pricingModelLookupController: PricingModelLookupControllerType;
  pricingModelDetailsController: PricingModelDetailsControllerType;

  fileUploadController: FileUploadController;

  whitelistEmailAddressToolController: WhitelistEmailAddressToolControllerType;
}

export const ProgramForm: React.FunctionComponent<Props> = (props) => {
  const programRoutes = useConfigurationRoutes({ programAlias: props.config.item?.alias });
  const modal = useModal();

  return (
    <ItemForm<Program, FormData, ProgramFormData>
      config={props.config}

      subject={"program"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.blank}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, formProps, submitting }) => (
        <>
          <ModalController
            title={"Whitelist A Test Account"}
            wide={true}
            modal={modal}
            render={(close) =>
              React.createElement(props.whitelistEmailAddressToolController, {
                render: (viewProps) => <WhitelistEmailAddressTool
                  {...viewProps}
                  onWhitelist={(emailAddress) => {
                    if (props.config.item) {
                      viewProps.onWhitelist(emailAddress, props.config.item.id).then(close);
                    }
                  }}
                />
              })
            }
          />
          {props.config.readOnly && <ViewOnlyWarningPanel/>}
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextField<FormData>
                label={"ID"}
                name={"id"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={!props.config.isNew || submitting}
                maxLength={36}
                textBoxRef={props.config.isNew ? firstInputRef as React.RefObject<HTMLInputElement> : undefined}
              />
              <TextField<FormData>
                label={"URL-friendly alias"}
                name={"alias"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={100}
                textBoxRef={props.config.isNew ? undefined : firstInputRef as React.RefObject<HTMLInputElement>}
              />
              <TextField<FormData>
                label={"Name"}
                name={"name"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={255}
              />
              <TextAreaField<FormData>
                label={"Description"}
                name={"description"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              <TextField<FormData>
                label={"Product name (ex. VaultMe for SampleU)"}
                name={"productName"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={255}
              />
              <TextField<FormData>
                label={"Landing page URL"}
                name={"landingPageUrl"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={255}
              />
              <FileUploadField<FormData>
                label={"Logo"}
                name={"logo"}
                controller={props.fileUploadController}
                renderPreview={(id) => <ImageUploadPreview id={id}/>}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              <DropDownField<FormData>
                label={"Status"}
                name={"status"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              >
                <option value={ProgramStatus.Disabled}>
                  {ProgramStatus.friendlyDescription(ProgramStatus.Disabled)}
                </option>
                <option value={ProgramStatus.Restricted}>
                  {ProgramStatus.friendlyDescription(ProgramStatus.Restricted)}
                </option>
                <option value={ProgramStatus.Live}>
                  {ProgramStatus.friendlyDescription(ProgramStatus.Live)}
                </option>
              </DropDownField>
              {!props.config.readOnly && (
                <TextAreaField<FormData>
                  label={"Internal notes"}
                  name={"notes"}
                  disabled={submitting}
                />
              )}
              <TextAreaField<FormData>
                label={"End User Access"}
                name={"endUserAccess"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              {props.config.item && !props.config.isNew && (
                <>
                  <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                  <FormField>
                    <>
                      <NewTabLink to={programRoutes.migrationSetup.homePath}>Preview in VaultMe</NewTabLink>
                      {!props.config.readOnly && <>{" "}(changes must be saved to become visible)</>}
                    </>
                  </FormField>
                  {!props.config.readOnly && (
                    <FormField>
                      <Button size={"small"} color={"secondary"} onClick={modal.open}>Whitelist A Test Account</Button>
                    </FormField>
                  )}
                </>
              )}
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              {!props.config.readOnly && (
                <PricingModelLookupField<FormData>
                  name={"pricingModelId"}
                  disabled={submitting}
                  lookupController={props.pricingModelLookupController}
                  detailsController={props.pricingModelDetailsController}
                />
              )}
              <TextField<FormData>
                label={"Discount (%)"}
                name={"discount"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={10}
                autoComplete={false}
                dirtyRegExp={/^[\.\d]*$/}
              />
              <DropDownField<FormData>
                label={"Discount type"}
                name={"discountType"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              >
                <option value={DiscountType.BasePriceOnly}>Migration fee only</option>
                <option value={DiscountType.BasePriceAndDataFees}>Migration fee and data fees</option>
              </DropDownField>
              <TextField<FormData>
                label={"Sponsored amount ($)  [\"-1\" = fully subsidized]"}
                name={"sponsoredAmount"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={10}
                autoComplete={false}
                dirtyRegExp={/^\-?[\.\d]*$/}
              />
              {props.revenueSharingEnabled && (
                <TextField<FormData>
                  label={"Revenue share (%)"}
                  name={"revenueShare"}
                  required={true}
                  readOnly={props.config.readOnly}
                  disabled={submitting}
                  maxLength={10}
                  autoComplete={false}
                  dirtyRegExp={/^[\.\d]*$/}
                />
              )}
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <ConfigurationLookupField<FormData>
                name={"configurationId"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                lookupController={props.configurationLookupController}
                detailsController={props.configurationDetailsController}
                viewPathFactory={props.configurationViewPathFactory}
              />
            </FormLayout>
          </SimplePanel>
          <Block>
            <Panel>
              <TitlePanelRow>Carousel :: intro</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <FileUploadField<FormData>
                    label={"Banner"}
                    name={"introBanner"}
                    controller={props.fileUploadController}
                    renderPreview={(id) => <ImageUploadPreview id={id}/>}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Title"}
                    name={"introTitle"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"Sub-title"}
                    name={"introContent"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>Carousel :: pricing details</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <FileUploadField<FormData>
                    label={"Banner"}
                    name={"pricingPageBanner"}
                    controller={props.fileUploadController}
                    renderPreview={(id) => <ImageUploadPreview id={id}/>}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Title"}
                    name={"pricingPageTitle"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"Sub-title"}
                    name={"pricingPageContent"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <CheckboxField<FormData>
                label={"Send migration notifications to program admins"}
                name={"sendMigrationNotificationsToAdmins"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              <CheckboxField<FormData>
                label={"Send feedback notifications to program admins"}
                name={"sendFeedbackNotificationsToAdmins"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
            </FormLayout>
          </SimplePanel>
          <Block>
            <Panel>
              <TitlePanelRow>Source restrictions</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <NotedField>
                    Important: Either a source account allowlist, a source domain mask, or a destination domain mask
                    must be defined for each program. Allowlisting takes priority over any other source restrictions:
                    if the program is selected based on the allowlist, other source restrictions are not additionally
                    checked. Destination restrictions are always checked (if they are defined).
                  </NotedField>
                  <TextField<FormData>
                    label={"Source cloud service ID"}
                    name={"sourceCloudServiceId"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={36}
                  />
                  <TextAreaField<FormData>
                    label={"Source domains or masks (ex. *.myschool.edu)"}
                    name={"sourceDomains"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>Destination restrictions</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Destination cloud service ID"}
                    name={"destinationCloudServiceId"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={36}
                  />
                  <TextField<FormData>
                    label={"Destination domain (multiple domains and masks are currently not supported)"}
                    name={"destinationDomains"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
        </>
      )}
    </ItemForm>
  );
};

interface WhitelistEmailAddressToolProps {
  loadStatus: OperationStatus<List<EmailAddressWhitelistingForProgram>>;
  whitelistingStatus: OperationStatus<any>;
  onWhitelist: (emailAddress: string) => void;
}

const WhitelistEmailAddressTool: React.FunctionComponent<WhitelistEmailAddressToolProps> = (props) => {
  return (
    <>
      <OperationStatusIndicator
        subject={"test accounts"}
        status={props.loadStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      <OperationStatusIndicator
        progressMessage={"Whitelisting"}
        failureMessage={"Failed to whitelist email address"}
        status={props.whitelistingStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.loadStatus.isSuccess() && (
        <DrawerBlock>
          <TestAccountGridPanel
            items={props.loadStatus.result}
            onWhitelist={(item) => props.onWhitelist(item.emailAddress)}
          />
        </DrawerBlock>
      )}
    </>
  );
};

const NotedField = styled(FormField)`
  max-width: 25rem;
`;
