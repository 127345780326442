import * as React from "react";
import { useConfigurationRoutes, useRoutes } from "../../app/routes/useRoutes";
import { Program } from "../../types/models/program";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime } from "../../utils/formatting";
import { useSearchProgramsQuery } from "../../queries/programs/useSearchProgramsQuery";
import { useDeleteProgramMutation } from "../../queries/programs/useDeleteProgramMutation";
import { useUpdateProgramMutation } from "../../queries/programs/useUpdateProgramMutation";
import { useInsertProgramMutation } from "../../queries/programs/useInsertProgramMutation";
import { useGetProgramQuery } from "../../queries/programs/useGetProgramQuery";
import {
  ProgramForm, WhitelistEmailAddressToolControllerProps
} from "../../views/screens/organizationPortal/programForm";
import { ProgramFormData } from "../../types/models/programFormData";
import {
  useConfigurationDetailsController
} from "../../components/lookup/configurations/useConfigurationDetailsController";
import { WidgetStatus } from "../../views/utils/widgetStatus";
import { ProgramStatus } from "../../types/models/programStatus";
import { useFileUploadController } from "../../queries/api/useFileUploadController";
import { usePricingModelLookupController } from "../pricingModels/usePricingModelLookupController";
import { usePricingModelDetailsController } from "../pricingModels/pricingModelDetailsController";
import { NewTabLink } from "../../views/widgets/newTabLink";
import { OrganizationSummary } from "../../types/models/organization";
import { CRUDConfig } from "../../components/crud/crudConfig";
import {
  useConfigurationLookupController
} from "../../components/lookup/configurations/useConfigurationLookupController";
import {
  useConfigurationViewPathFactory
} from "../../components/lookup/configurations/useConfigurationViewPathFactory";
import {
  ConfigurationLookupControllerType, ConfigurationViewPathFactory
} from "../../views/blocks/lookups/configurationLookupField";
import {
  useWhitelistEmailAddressForProgramMutation
} from "../../queries/programs/useWhitelistEmailAddressForProgramMutation";
import {
  useListTestAccountProgramAssignmentsQuery
} from "../../queries/programs/useListTestAccountProgramAssignmentsQuery";
import { useElevated } from "../../utils/useAppState";

interface ProgramAdminPagesProps extends Pick<
  CRUDConfig<string, Program, ProgramFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  revenueSharingEnabled: boolean;

  configurationLookupController: ConfigurationLookupControllerType;
  configurationViewPathFactory: ConfigurationViewPathFactory;
}

export const ProgramAdminPages: React.FunctionComponent<ProgramAdminPagesProps> = (props) => {
  const elevated = useElevated();

  const configurationDetailsController = useConfigurationDetailsController();

  const pricingModelLookupController = usePricingModelLookupController();
  const pricingModelDetailsController = usePricingModelDetailsController();
  const fileUploadController = useFileUploadController();

  const whitelistEmailAddressToolController = useWhitelistEmailAddressToolController();

  return CrudPages<string, Program, ProgramFormData>({
    title: "End-User Programs",
    subject: "program",
    readOnly: !elevated,
    startFrom: props.startFrom,
    layout: props.layout,
    gridColumns: [
      {
        title: "Name",
        render: (program) => program.name
      },
      {
        title: "URL-Friendly Alias",
        render: (program) => program.alias
      },
      {
        title: "Status",
        render: (program) => ({
          content: ProgramStatus.friendlyDescription(program.status),
          status: program.status === ProgramStatus.Disabled
            ? WidgetStatus.Uncertain
            : program.status === ProgramStatus.Restricted
              ? WidgetStatus.Warning
              : WidgetStatus.Success
        })
      },
      {
        title: "Preview",
        render: (program) => program.alias ? <PreviewLink alias={program.alias}/> : null
      },
      {
        title: "Created",
        render: (program) => friendlyDateTime(program.createdAt)
      },
    ],
    renderForm: (config) => (
      <ProgramForm
        config={config}

        revenueSharingEnabled={props.revenueSharingEnabled}

        configurationLookupController={props.configurationLookupController}
        configurationDetailsController={configurationDetailsController}
        configurationViewPathFactory={props.configurationViewPathFactory}

        pricingModelLookupController={pricingModelLookupController}
        pricingModelDetailsController={pricingModelDetailsController}

        fileUploadController={fileUploadController}

        whitelistEmailAddressToolController={whitelistEmailAddressToolController}
      />
    ),

    useRoutes: props.useRoutes,
    getItemId: (program) => program.id,
    getItemTitle: (program) => program.name,
    clone: (program) => ({
      ...program,
      id: "copy-of-" + program.id,
      name: "Copy of " + program.name
    }),
    compare: (a: Program, b: Program) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },

    useGetQuery: useGetProgramQuery,
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: () => {
      const [update, updateStatus] = useUpdateProgramMutation();
      return [(id, formData) => update(formData), updateStatus];
    },
    useDeleteMutation: useDeleteProgramMutation
  });
};

interface OrganizationProgramAdminPagesProps {
  organization: OrganizationSummary;
}

export const OrganizationProgramAdminPages: React.FunctionComponent<OrganizationProgramAdminPagesProps> = (props) => {
  const configurationLookupController = useConfigurationLookupController(props.organization.id);
  const configurationViewPathFactory = useConfigurationViewPathFactory(props.organization.id);

  return (
    <ProgramAdminPages
      startFrom={"list"}
      layout={"compact"}

      useRoutes={() => useRoutes().organizationPortalRoutes.programs(props.organization.alias)}
      useSearchQuery={(term) => useSearchProgramsQuery(props.organization.id, term)}
      useInsertMutation={() => useInsertProgramMutation(props.organization.id)}

      revenueSharingEnabled={props.organization.revenueSharingEnabled}

      configurationLookupController={configurationLookupController}
      configurationViewPathFactory={configurationViewPathFactory}
    />
  );
};

interface PreviewLinkProps {
  alias: string;
}

const PreviewLink: React.FunctionComponent<PreviewLinkProps> = (props) => {
  const programRoutes = useConfigurationRoutes({ programAlias: props.alias });
  return <NewTabLink to={programRoutes.migrationSetup.homePath}>Preview</NewTabLink>;
};

export const WhitelistEmailAddressToolController: React.FunctionComponent<WhitelistEmailAddressToolControllerProps> =
  (props) => {
    const [loadStatus] = useListTestAccountProgramAssignmentsQuery();
    const [whitelist, whitelistingStatus] = useWhitelistEmailAddressForProgramMutation();

    return props.render({
      loadStatus,
      whitelistingStatus,
      onWhitelist: whitelist
    });
  };

export function useWhitelistEmailAddressToolController() {
  return React.useCallback(
    (controllerProps: WhitelistEmailAddressToolControllerProps) =>
      <WhitelistEmailAddressToolController {...controllerProps}/>,
    []
  );
}
