import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { ComponentHubs, Component } from "../component";
import { ItemTypes } from "../types/itemTypes";
import { State } from "../state";
import { Set } from "immutable";
import { CollectableInteger } from "../../types/collectables/collectableInteger";
import { CollectableItemBreakdown } from "../../types/collectables/collectableItemBreakdown";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { None, Option } from "../../utils/monads/option";
import { ComponentBinding } from "./componentBinding";
import { MigrationFlowsHub } from "../hubs/migrationFlowsHub";
import { AreaComp } from "./areaComp";
import { nullToUndefined } from "../../utils/misc";
import { Tracer } from "../tracer";

export class SinkComp extends Component<SinkComp.Props, SinkComp.Hubs, SinkComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<SinkComp.Output> {
    const preconditionsState = this.hubs.preconditions.state(context);
    const migrationFlowsState = this.hubs.migrationFlows.state(context);

    return preconditionsState.map(() =>
      migrationFlowsState.output
        .map((migrationStream) => ({
          itemTypes: Set(this.props.itemTypes),
          totalBytes: migrationStream.totalBytes,
          totalItems: migrationStream.totalItems,
          maxPathLength: migrationStream.maxPathLength,
        }))
        .getOrElse(() => ({
          itemTypes: Set(this.props.itemTypes),
          totalBytes: None(),
          totalItems: None(),
          maxPathLength: None(),
        }))
      );
  }

  public visualization(): ComponentVisualization {
    return {
      title: "Sink",
      summary: this.props.title + "\n" + this.props.itemTypes.join(", "),
      icon: Images.Blueprint.NoIcon,
      color: ComponentColorSchema.HollowBrown,
      sizeMultiplier: 1.5
    };
  }

  public resolvedAppTitle(context: BlueprintContext): string {
    return (
      this.props.appTitle ||
      new Tracer(this, context).domain(true).toJS() ||
      context.cloudServices.getOrFail(this.blueprint.params.destinationCloudServiceId).name
    );
  }

  public sourceAppId(): Option<string> {
    return Option
      .mayBe(this.hubs.migrationFlows.dataFlows().first())
      .filter((migrationFlow) => migrationFlow.component() instanceof AreaComp)
      .map((migrationFlow) => (migrationFlow.component() as AreaComp).props.internalId);
  }

  public sourceAppTitle(): Option<string> {
    return Option
      .mayBe(this.hubs.migrationFlows.dataFlows().first())
      .filter((migrationFlow) => migrationFlow.component() instanceof AreaComp)
      .flatMap((migrationFlow) => Option.mayBe((migrationFlow.component() as AreaComp).props.appTitle));
  }
}

export namespace SinkComp {
  export interface Props {
    internalId: string;
    appTitle: string | undefined;
    mainSubject: string;
    title: string;
    description: string;
    order: number;
    itemTypes: string[];
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.SinkCompProps): Props {
      return {
        internalId: props.internalId,
        appTitle: nullToUndefined(props.appTitle),
        mainSubject: props.mainSubject,
        title: props.title,
        description: props.description,
        order: props.order,
        itemTypes: props.itemTypes
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    migrationFlows: MigrationFlowsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.SinkCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        migrationFlows: binding.migrationFlowsHub("migrationFlows", hubs.migrationFlows)
      };
    }
  }

  export interface Output {
    itemTypes: ItemTypes;
    totalBytes: Option<CollectableInteger>;
    totalItems: Option<CollectableItemBreakdown>;
    maxPathLength: Option<CollectableInteger>;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.SinkCompOutputFragment): Output {
      return {
        itemTypes: Set(output.itemTypes),
        totalBytes: Option.mayBe(output.totalBytesCollectable).map(CollectableInteger.fromGraphQL),
        totalItems: Option.mayBe(output.totalItemsCollectable).map(CollectableItemBreakdown.fromGraphQL),
        maxPathLength: Option.mayBe(output.maxPathLength).map(CollectableInteger.fromGraphQL),
      };
    }
  }
}
