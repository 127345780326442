import * as React from "react";
import { styled } from "../../app/theme";
import { Blueprint } from "../blueprint";
import { BlueprintContext } from "../blueprintContext";
import { RelationshipWithOriginComponent } from "../blueprintComponents";
import { AnyComponent } from "../component";
import { BlueprintFilters } from "../../state/settings/blueprintFilters";
import { Filters } from "./filters";
import { JsonContent } from "./jsonContent";
import { ComponentProps } from "./componentProps";
import { RelationshipProps } from "./relationshipProps";
import { SidebarMode, SidebarModes } from "./sidebarModes";
import { SimpleSection } from "./simpleSection";
import { AdminToolsMode } from "../../state/settings/state";
import { BlueprintDiff } from "../blueprintDiff";
import { BlueprintDiffList } from "./blueprintDiffList";

const Layout = styled.div<{ horizontal: boolean }>`
  height: ${(props) => props.horizontal ? "10rem" : "100%"};
  width: ${(props) => props.horizontal ? "100%" : "20rem"};
  border-top: ${(props) => props.horizontal ? "1px solid " + props.theme.colors.darkGray : "none"};
  border-left: ${(props) => props.horizontal ? "none" : "1px solid " + props.theme.colors.darkGray};
  display: ${(props) => props.horizontal ? "flex" : "block"};
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: .8rem;
`;

export interface SidebarProps {
  blueprintExplorerSize: AdminToolsMode;
  blueprintContext: BlueprintContext;
  blueprint: Blueprint;
  diffs: BlueprintDiff;

  filters: BlueprintFilters;
  onFiltersChange: (filters: BlueprintFilters) => void;

  selectedComponent?: AnyComponent;
  selectedRelationship?: RelationshipWithOriginComponent;
  onComponentSelect: (componentId: string) => void;
}

interface InternalState {
  sidebarMode: SidebarMode;
  openSectionId?: string;
}

export class Sidebar extends React.Component<SidebarProps, InternalState> {
  constructor(props: SidebarProps) {
    super(props);

    this.state = { sidebarMode: SidebarMode.Props };

    this.handleSidebarModeChange = this.handleSidebarModeChange.bind(this);
    this.handleSectionSelect = this.handleSectionSelect.bind(this);
  }

  public render() {
    const horizontal = this.props.blueprintExplorerSize === AdminToolsMode.SplitScreen;
    return (
      <Layout horizontal={horizontal}>
        <SidebarModes
          horizontal={horizontal}
          sidebarMode={this.state.sidebarMode}
          onViewModeChange={this.handleSidebarModeChange}
        />
        {this.renderBody(horizontal)}
      </Layout>
    );
  }

  protected renderBody(horizontal: boolean) {
    if (this.props.blueprint) {
      switch (this.state.sidebarMode) {
        case SidebarMode.Props:
          if (this.props.selectedComponent) {
            return (
              <ComponentProps
                horizontal={horizontal}
                component={this.props.selectedComponent}
                blueprintContext={this.props.blueprintContext}
                diffs={this.props.diffs}
                openSectionId={this.state.openSectionId}
                onSectionSelect={this.handleSectionSelect}
                onComponentSelect={this.props.onComponentSelect}
              />
            );
          } else if (this.props.selectedRelationship) {
            return (
              <RelationshipProps
                horizontal={horizontal}
                relationship={this.props.selectedRelationship}
                blueprintContext={this.props.blueprintContext}
                diffs={this.props.diffs}
                openSectionId={this.state.openSectionId}
                onSectionSelect={this.handleSectionSelect}
                onComponentSelect={this.props.onComponentSelect}
              />
            );
          } else {
            return (
              <SimpleSection horizontal={horizontal}>
                Please select a component or an edge to view properties
              </SimpleSection>
            );
          }

        case SidebarMode.Filters:
          return (
            <Filters
              horizontal={horizontal}
              blueprint={this.props.blueprint}
              blueprintContext={this.props.blueprintContext}
              filters={this.props.filters}
              onChange={this.props.onFiltersChange}
            />
          );

        case SidebarMode.Inputs:
          return (
            <SimpleSection horizontal={horizontal}>
              <JsonContent data={this.props.blueprintContext.inputs}/>
            </SimpleSection>
          );

        case SidebarMode.Diffs:
          return (
            <SimpleSection horizontal={horizontal}>
              <BlueprintDiffList
                blueprint={this.props.blueprint}
                blueprintContext={this.props.blueprintContext}
                diffs={this.props.diffs}
                onComponentSelect={(componentId) => {
                  this.props.onComponentSelect(componentId);
                  this.setState({ sidebarMode: SidebarMode.Props });
                }}
              />
            </SimpleSection>
          );
      }
    }
  }

  protected handleSidebarModeChange(sidebarMode: SidebarMode) {
    this.setState({ sidebarMode });
  }

  protected handleSectionSelect(openSectionId: string) {
    this.setState({ openSectionId });
  }
}
