import * as React from "react";
import { commafy } from "../../../../utils/formatting";
import { LinkButton } from "../../../widgets/linkButton";
import { OperationStatus } from "../../../../types/operationStatus";
import { styled } from "../../../../app/theme";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { useCloudServices } from "../../../../app/configuration";

interface Props {
  offboardingProject: OffboardingProject;
  status: OperationStatus<number>;
  onRefresh?: () => void;
}

export const OffboardingProjectEntryCount: React.FunctionComponent<Props> = (props) => {
  const onRefresh = props.onRefresh;
  const cloudServices = useCloudServices();

  return (
    <span>
      {
        props.status.mapLastResult((entryCount) => {
          if (props.offboardingProject.connection) {
            const maxEntryCount = props.offboardingProject.connection.maxEntryCount;
            const cloudServiceName = cloudServices.getOrFail(props.offboardingProject.connection.cloudServiceId).name;

            if (maxEntryCount !== undefined) {
              if (entryCount <= maxEntryCount) {
                return (
                  <>
                    <Value>{commafy(entryCount)} of {commafy(maxEntryCount)}</Value>{" "}
                    <Comment>(within the daily email sending limit for {cloudServiceName} accounts)</Comment>
                  </>
                );
              } else {
                return (
                  <>
                    <Value error={true}>{commafy(entryCount)} of {commafy(maxEntryCount)}</Value>{" "}
                    <Comment error={true}>
                      (exceeds the daily email sending limit for {cloudServiceName} accounts)
                    </Comment>
                  </>
                );
              }
            } else {
              return <Value>{commafy(entryCount)}</Value>;
            }
          } else {
            return <Value>{commafy(entryCount)}</Value>;
          }
        }) || "Calculating..."
      }
      {onRefresh && (
        <>
          &nbsp;&nbsp;
          <LinkButton onClick={() => onRefresh()} disabled={!props.status.isSuccess()}>Refresh</LinkButton>
        </>
      )}
    </span>
  );
};

const Value = styled.span<{ error?: boolean }>`
  font-weight: 400;
  color: ${(props) => props.error ? props.theme.colors.red : "inherit"};
`;

const Comment = styled.span<{ error?: boolean }>`
  color: ${(props) => props.error ? props.theme.colors.red : "inherit"};
`;
