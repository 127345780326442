import * as React from "react";
import { styled } from "../../app/theme";
import { AdminToolsMode } from "../../state/settings/state";
import { useSettings } from "../../utils/useAppState";

interface LayoutProps {
  blueprintExplorerSize: AdminToolsMode;
  fullWidth: boolean;
}

const Layout = styled.div<LayoutProps>`
  margin: ${(props) => props.theme.layout.header.desktop}rem auto 0 auto;
  max-width: ${(props) => props.fullWidth ? "inherit" : props.theme.layout.workflow.width};
  padding: 2.8rem 2.5rem 2rem;

  ${(props) => props.theme.responsive.respondToSmall(
  props.blueprintExplorerSize === AdminToolsMode.SplitScreen
    )} {
    margin-top: ${(props) => props.theme.layout.header.mobile}rem;
    padding: 2rem 1.5rem 2rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 1rem ${(props) => props.fullWidth ? "0" : "1rem"} 2rem;
  }

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    padding: 1rem ${(props) => props.fullWidth ? "0" : "0.75rem"} 2rem;
  }
  
  & + & {
    margin-top: 0;
    padding-top: 0;
  }
`;

interface Props {
  fullWidth?: boolean;
}

export const CenteredContent: React.FunctionComponent<Props> = (props) => {
  const settings = useSettings();
  return (
    <Layout
      blueprintExplorerSize={settings.adminToolsMode}
      fullWidth={props.fullWidth || false}
    >
      {props.children}
    </Layout>
  );
};
