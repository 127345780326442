import { GraphQL } from "../../services/graphql/generated";
import moment from "moment";

export interface ProgramPerformanceDataPoint {
  readonly timestamp: moment.Moment;
  readonly migrationCount: number;
}

export namespace ProgramPerformanceDataPoint {
  export function parse(
    data: GraphQL.ProgramPerformance_DataPoint,
    timeZoneOffset: number
  ): ProgramPerformanceDataPoint {
    return {
      timestamp: moment.utc(data.timestamp).subtract(timeZoneOffset, "minute"),
      migrationCount: data.migrationCount
    };
  }
}
