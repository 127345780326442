import { Blueprint } from "./blueprint";
import { BlueprintContext } from "./blueprintContext";
import { ServerElements } from "./serverElements";

export abstract class MaterializedBlueprint {
  constructor(public readonly blueprint: Blueprint, public readonly context: BlueprintContext) {
  }

  public equals(other: MaterializedBlueprint): boolean {
    return this.blueprint === other.blueprint && this.context.equals(other.context);
  }

  public abstract withServerElements(serverElements: ServerElements): MaterializedBlueprint;
}
