import * as React from "react";
import { styled } from "../../app/theme";
import { BreadcrumbsContent } from "../widgets/breadcrumbs";
import { WizardNavigation, WizardNavigationProps } from "../widgets/wizardNavigation";
import { CenteredContent } from "../layouts/centeredContent";
import { HeaderBlock } from "../widgets/headerBlock";

export const WizardNavigationContainer = styled.div`
  padding: 2rem 0 0 0;
  margin: 1rem 0 0 0;
  width: 100%;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0.5rem 0 0 0;
  }
`;

export type StepSubmitHandle = ((event: React.FormEvent<HTMLFormElement>) => void);

export interface StepProps extends WizardNavigationProps {
  breadcrumbs?: BreadcrumbsContent;
  title: string;
  subTitle?: React.ReactNode;
  noForm?: boolean;

  additionalContent?: JSX.Element;

  onSubmit?: StepSubmitHandle;
  onChange?: (event: React.FormEvent<HTMLFormElement>) => void;
  onChanged?: () => void;
}

export class Step extends React.PureComponent<StepProps> {
  constructor(props: StepProps) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount() {
    window.scrollTo(0, 0);
  }

  public render() {
    if (this.props.noForm) {
      return (
        <CenteredContent>
          {this.renderBody()}
        </CenteredContent>
      );
    } else {
      return (
        <CenteredContent>
          <form onSubmit={this.onSubmit} onChange={this.onChange}>
            {this.renderBody()}
          </form>
        </CenteredContent>
      );
    }
  }

  protected renderBody() {
    const customSubmit = typeof this.props.onSubmit === "string" || this.props.noForm;
    return (
      <>
        <HeaderBlock
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          subTitle={this.props.subTitle}
        />

        {this.props.children}

        <WizardNavigationContainer>
          <WizardNavigation
            {...this.props}
            submitType={customSubmit ? "button" : "submit"}
            onSubmitClick={customSubmit ? this.props.onSubmit : undefined}
          />
        </WizardNavigationContainer>

        {this.props.additionalContent}
      </>
    );
  }

  protected onSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (this.props.onSubmit) {
      this.props.onSubmit(event);
    }
  }

  protected onChange(event: React.FormEvent<HTMLFormElement>) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    if (this.props.onChanged) {
      setTimeout(this.props.onChanged, 0);
    }
  }
}
