import { useLocation, useParams, Location } from "react-router";
import { useConfigurationAlias, useProgramAlias, useThemeId } from "../configuration";
import { useSearchParams } from "react-router-dom";
import { RoutesContext } from "./routesContext";
import { MigrationSetupRoutes } from "./migrationSetupRoutes";
import { SandboxRoutes } from "./sandboxRoutes";
import { BatchMigrationSetupRoutes } from "./batchMigrationSetupRoutes";
import { StyleguideRoutes } from "./styleguideRoutes";
import { WizardrySandboxRoutes } from "./wizardrySandboxRoutes";
import { ConnectRoutes } from "./connectRoutes";
import { UsersRoutes } from "./usersRoutes";
import { BatchesRoutes } from "./batchesRoutes";
import { MigrationsRoutes } from "./migrationsRoutes";
import { JobsRoutes } from "./jobsRoutes";
import { QuickSightDashboardsRoutes } from "./quickSightDashboardsRoutes";
import { FeedbackRoutes } from "./feedbackRoutes";
import { StaticRoutes } from "./staticRoutes";
import { ApiRoutes } from "./apiRoutes";
import { CouponCodesRoutes } from "./couponCodesRoutes";
import { Constants } from "../constants";
import { PricingModelsRoutes } from "./pricingModelsRoutes";
import { OrganizationsRoutes } from "./organizationsRoutes";
import { OrganizationPortalRoutes } from "./organizationPortalRoutes";
import { OptOutRoutes } from "./optOutRoutes";
import { SchoolsRoutes } from "./schoolsRoutes";
import { AmbassadorsRoutes } from "./ambassadorsRoutes";

class BasicContext extends RoutesContext {
  public resolvePath(path: string): string {
    return path.startsWith("/") ? path : "/" + path;
  }
}

class ProgramContext extends RoutesContext {
  constructor(
    public programAlias: string,
    routeParams: Record<string, string | undefined>,
    searchParams: URLSearchParams,
    location: Location
  ) {
    super(routeParams, searchParams, location);
  }

  public resolvePath(path: string): string {
    return Constants.Paths.program + "/" + this.programAlias + (path.startsWith("/") ? path : "/" + path);
  }
}

class ConfigurationContext extends RoutesContext {
  constructor(
    public configurationAlias: string,
    routeParams: Record<string, string | undefined>,
    searchParams: URLSearchParams,
    location: Location
  ) {
    super(routeParams, searchParams, location);
  }

  public resolvePath(path: string): string {
    return Constants.Paths.configuration + "/" + this.configurationAlias + (path.startsWith("/") ? path : "/" + path);
  }
}

class ThemeContext extends RoutesContext {
  constructor(
    public themeId: number,
    routeParams: Record<string, string | undefined>,
    searchParams: URLSearchParams,
    location: Location
  ) {
    super(routeParams, searchParams, location);
  }

  public resolvePath(path: string): string {
    return Constants.Paths.theme + "/" + this.themeId + (path.startsWith("/") ? path : "/" + path);
  }
}

export class RoutesHook {
  constructor(private context: RoutesContext) {}

  public get ambassadors(): AmbassadorsRoutes { return new AmbassadorsRoutes(this.context); }
  public get api(): ApiRoutes { return new ApiRoutes(this.context); }
  public get batchMigrationSetup(): BatchMigrationSetupRoutes { return new BatchMigrationSetupRoutes(this.context); }
  public get batches(): BatchesRoutes { return new BatchesRoutes(this.context); }
  public get connect(): ConnectRoutes { return new ConnectRoutes(this.context); }
  public get couponCodes(): CouponCodesRoutes { return new CouponCodesRoutes(this.context); }
  public get feedback(): FeedbackRoutes { return new FeedbackRoutes(this.context); }
  public get jobs(): JobsRoutes { return new JobsRoutes(this.context); }
  public get migrations(): MigrationsRoutes { return new MigrationsRoutes(this.context); }
  public get migrationSetup(): MigrationSetupRoutes { return new MigrationSetupRoutes(this.context); }
  public get optOutRoutes(): OptOutRoutes { return new OptOutRoutes(this.context); }
  public get organizationPortalRoutes(): OrganizationPortalRoutes { return new OrganizationPortalRoutes(this.context); }
  public get organizations(): OrganizationsRoutes { return new OrganizationsRoutes(this.context); }
  public get pricingModels(): PricingModelsRoutes { return new PricingModelsRoutes(this.context); }
  public get quickSightDashboards(): QuickSightDashboardsRoutes { return new QuickSightDashboardsRoutes(); }
  public get styleguide(): StyleguideRoutes { return new StyleguideRoutes(this.context); }
  public get sandbox(): SandboxRoutes { return new SandboxRoutes(this.context); }
  public get schools(): SchoolsRoutes { return new SchoolsRoutes(this.context); }
  public get static(): StaticRoutes { return new StaticRoutes(this.context); }
  public get users(): UsersRoutes { return new UsersRoutes(this.context); }
  public get wizardrySandbox(): WizardrySandboxRoutes { return new WizardrySandboxRoutes(this.context); }
}

interface RoutesContextConfig {
  programAlias?: string;
  configurationAlias?: string;
  themeId?: number;
}

function makeConfigurationRoutes(
  routeParams: Record<string, string | undefined>,
  searchParams: URLSearchParams,
  location: Location,
  contextConfig: RoutesContextConfig
): RoutesHook {
  if (contextConfig.configurationAlias) {
    return new RoutesHook(
      new ConfigurationContext(contextConfig.configurationAlias, routeParams, searchParams, location)
    );
  } else if (contextConfig.programAlias) {
    return new RoutesHook(
      new ProgramContext(contextConfig.programAlias, routeParams, searchParams, location)
    );
  } else if (contextConfig.themeId) {
    return new RoutesHook(new ThemeContext(contextConfig.themeId, routeParams, searchParams, location));
  } else {
    return new RoutesHook(new BasicContext(routeParams, searchParams, location));
  }
}

export function useRoutes(): RoutesHook {
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const programAlias = useProgramAlias();
  const configurationAlias = useConfigurationAlias();
  const themeId = useThemeId();
  const location = useLocation();

  return makeConfigurationRoutes(
    routeParams,
    searchParams,
    location,
    { programAlias, configurationAlias, themeId }
  );
}

export function useConfigurationRoutes(contextConfig: RoutesContextConfig): RoutesHook {
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  return makeConfigurationRoutes(routeParams, searchParams, location, contextConfig);
}
