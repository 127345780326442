import * as React from "react";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "./styledComponentsProps";

const Pixel = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  background: transparent;
`;

interface Props extends StyledComponentsProps {
  onChange: (overflow: boolean) => void;
}

export const OverflowDetector: React.FunctionComponent<Props> = (props) => {
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(
    () => {
      if (ref.current) {
        const observer = new IntersectionObserver(
          ([e]) => props.onChange(e.intersectionRatio < 1),
          { threshold: [1] }
        );
        observer.observe(ref.current);
        return () => observer.disconnect();
      }
    },
    []
  );

  return <Pixel ref={ref} className={props.className}/>;
};
