import { Anchor, PreparedAnchor } from "../anchor";
import {
  isWizardFinish,
  ConfigurableWizardDestinationFactory,
  isWizardStep, AnyWizardStep, WizardDestination, SkipConditions
} from "./wizardDestination";
import { AbstractWorkflow, Workflow } from "../workflow";
import { WorkflowMutationResult } from "../workflowMutationResult";
import { WorkflowContextLike } from "../workflowContextLike";
import { WizardStateTraversal } from "./wizardStateTraversal";
import { List, Set } from "immutable";
import { AnyRenderHooks } from "../workItem";
import { Path } from "../path";
import { FastPromise } from "../fastPromise";
import { WorkItemMutationResult } from "../workItemMutationResult";
import { InitializationError } from "../initializationError";
import { isDefined } from "../../utils/misc";
import { Observable } from "rxjs";
import { WorkflowState } from "../workflowState";
import { AsyncResult } from "../asyncResult";

export abstract class Wizard<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  >
  extends AbstractWorkflow<WizardContext, WizardFeedback, WizardResult> {

  public abstract firstDestinationFactory():
    ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>;

  public validatePath(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): Workflow.AsyncPathResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().currentOrFirstStep(state).map((result) =>
      result.map((step) =>
        step ? step.getValidatedPath(result.state.path.nestedPathFor(step.id)) : Path.Empty
      )
    );
  }

  public getFirstWorkItemPath(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): Workflow.AsyncPathResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().firstStep(state).map((result) =>
      result.map((step) => step ? step.getValidatedPath(Path.Empty) : Path.Empty)
    );
  }

  public getLastWorkItemPath(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): Workflow.AsyncPathResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().lastStep(state).map((result) =>
      result.map((step) => step ? step.getLastNestedWorkItemPath() : Path.Empty)
    );
  }

  public getPreviousWorkItemPath(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): Workflow.AsyncOptionalPathResult<WizardContext, WizardFeedback, WizardResult> {
    const currentStepId = state.path.currentId();
    if (currentStepId) {
      return this.traversal()
        .traverseStepsUntil(state, (step) => step.id === currentStepId).map((result) =>
          result.map((steps) => {
            const currentStep = steps.last(undefined);
            if (currentStep) {
              const previousWorkItemPath = currentStep.getPreviousNestedWorkItemPath();
              if (previousWorkItemPath !== undefined) {
                return previousWorkItemPath;
              } else if (steps.size === 1) {
                return undefined;
              } else {
                const skipConditions: SkipConditions<WizardContext> = {
                  workflowContext: state.workflowContext,
                  previousStepCompletedOrUpdated: false,
                  navigatingBack: true
                };
                const previousStep = steps.pop().findLast((step) => !step.skipWhen(skipConditions));
                return previousStep && previousStep.getLastNestedWorkItemPath();
              }
            } else {
              return undefined;
            }
          })
        );
    } else {
      return FastPromise.resolve(state.buildResult(undefined));
    }
  }

  public applyResult(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    result: any,
    completeAndProceed: boolean
  ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> {

    function selectNextDestination(
      traversal: WizardStateTraversal<WizardContext, WizardFeedback, WizardResult>,
      localState: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
      step: AnyWizardStep<WizardContext, WizardFeedback, WizardResult>,
      conditions: SkipConditions<WizardContext>,
    ): AsyncResult<
      WizardContext,
      WizardFeedback,
      WizardResult,
      WizardDestination<WizardContext, WizardFeedback, WizardResult> | undefined
      > {
      if (completeAndProceed) {
        const nextDestinationFactory = step.getNextDestination();
        if (nextDestinationFactory) {
          return traversal.lastDestinationBefore(
            localState,
            (destination) => isWizardFinish(destination) || !destination.skipWhen(conditions),
            nextDestinationFactory
          );
        }
      }
      return FastPromise(localState.buildResult(undefined));
    }

    return this.traversal().currentOrClosestStep(state).map(({ state: state2, result: step }) => {
      if (step) {
        const [newWorkItemStates, newWorkItemState] =
          state2.workItemStates.applyResult(step.id, result, completeAndProceed);
        const newState = state2.copy({ workItemStates: newWorkItemStates });
        const conditions: SkipConditions<WizardContext> = {
          workflowContext: state.workflowContext,
          previousStepCompletedOrUpdated:
            step.getLastCompletedResultKey() !==
            step
              .copy({ lastCompletedResult: newWorkItemState.lastCompletedResult })
              .getLastCompletedResultKey(),
          navigatingBack: false
        };

        return this.traversal().currentOrClosestStep(newState).map((
          { state: state3, result: updatedCurrentStep }
        ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> => {
          if (updatedCurrentStep) {
            if (updatedCurrentStep.id === step.id) {
              return selectNextDestination(this.traversal(), state3, updatedCurrentStep, conditions)
                .map(({ state: state4, result: nextDestination }) => {
                  if (nextDestination) {
                    if (isWizardFinish(nextDestination)) {
                      return state4.buildResult(WorkflowMutationResult.Completed(nextDestination.wizardResult));
                    } else {
                      return state4.buildResult(
                        WorkflowMutationResult.Progressed(nextDestination.getValidatedPath(Path.Empty))
                      );
                    }
                  } else {
                    return state4.buildResult(WorkflowMutationResult.Progressed(state4.path));
                  }
                });
            } else {
              // Could not traverse to the current step after applying result.
              // Something has changed on previous steps?
              return FastPromise.resolve(
                state3.buildResult(
                  WorkflowMutationResult.Progressed(
                    updatedCurrentStep.getValidatedPath(Path.Empty)
                  )
                )
              );
            }
          } else {
            // No steps at all after applying result?!
            return FastPromise.resolve(state3.buildResult(WorkflowMutationResult.DidNotChange()));
          }
        });
      } else {
        return state2.buildResult(WorkflowMutationResult.DidNotChange());
      }
    });
  }

  public clearResult(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> {

    return this.traversal().currentOrClosestStep(state).map(({ state: state2, result: step }) => {
      if (step) {
        const newState = state2.copy({
          workItemStates: state2.workItemStates.clearResult(step.id)
        });

        return this.traversal().currentOrClosestStep(newState).map((
          { state: state3, result: updatedCurrentStep }
        ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> => {
          if (updatedCurrentStep) {
            return FastPromise.resolve(
              state3.buildResult(
                WorkflowMutationResult.Progressed(
                  updatedCurrentStep.getValidatedPath(Path.Empty)
                )
              )
            );
          } else {
            // No steps at all after clearing result?!
            return FastPromise.resolve(state3.buildResult(WorkflowMutationResult.DidNotChange()));
          }
        });
      } else {
        return state2.buildResult(WorkflowMutationResult.DidNotChange());
      }
    });
  }

  public processFeedback(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    feedback: WizardFeedback
  ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> {

    function traverse(
      traversal: WizardStateTraversal<WizardContext, WizardFeedback, WizardResult>,
      localState: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
      destinationFactory: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>,
      previousStepId: string
    ): FastPromise<WorkflowState<WizardContext, WizardFeedback, WizardResult>> {
      return traversal.buildDestination(localState, destinationFactory)
        .map(({ state: localState2, result: destination }) => {
          if (
            isWizardStep(destination) &&
            destination.isCompleteWithValidResult() &&
            destination.id !== previousStepId &&
            destination.id !== currentStepId
          ) {
            const processResult = destination.processFeedback(feedback);

            switch (processResult.type) {
              case WorkItemMutationResult.Type.Completed: {
                const localState3 = localState2.copy({
                  workItemStates: localState2.workItemStates.updateResult(destination.id, processResult.result)
                });
                return traversal.stepById(localState3, destination.id)
                  .map(({ state: localState4, result: updatedDestination }) => {
                    if (updatedDestination) {
                      const nextDestination = updatedDestination.getNextDestination();
                      return nextDestination
                        ? traverse(traversal, localState4, nextDestination, destination.id)
                        : localState4;
                    } else {
                      // Current step disappeared, reverting to previous state
                      return FastPromise.resolve(localState2);
                    }
                  });
              }

              case WorkItemMutationResult.Type.Progressed: {
                // Item status changed from completed to progressed => nothing more to traverse
                return localState2.copy({
                  workItemStates: localState2.workItemStates.changeToUncompleted(destination.id, processResult.result)
                });
              }

              case WorkItemMutationResult.Type.DidNotChange: {
                const nextDestination = destination.getNextDestination();
                return nextDestination
                  ? traverse(traversal, localState2, nextDestination, destination.id)
                  : localState2;
              }
            }
          }
          return FastPromise.resolve(localState2);
        });
    }

    const currentStepId = state.path.currentId();

    return traverse(this.traversal(), state, this.firstDestinationFactory(), "").map((state2) => {
      if (state2.workItemStates.equals(state.workItemStates)) {
        return FastPromise.resolve(state.buildResult(WorkflowMutationResult.DidNotChange()));
      } else {
        return this.traversal().currentOrClosestStep(state2).map(({ state: state3, result: step }) => {
          if (step) {
            return state3.buildResult(
              WorkflowMutationResult.Progressed(
                step.id === currentStepId ? state.path : step.getLastNestedWorkItemPath()
              )
            );
          } else {
            // No current step after applying feedback?!
            return state3.buildResult(WorkflowMutationResult.DidNotChange());
          }
        });
      }
    });
  }

  public cleanUp(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    round: number = 0
  ): Workflow.AsyncMutationResult<WizardContext, WizardFeedback, WizardResult> {

    function traverse(
      verifiedStepIds: Set<string>,
      traversal: WizardStateTraversal<WizardContext, WizardFeedback, WizardResult>,
      localState: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
      destinationFactory: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>,
      previousStepId: string
    ): FastPromise<WorkflowState<WizardContext, WizardFeedback, WizardResult>> {
      return traversal
        .buildDestination(localState, destinationFactory)
        .map(({ state: localState2, result: destination }) => {
          if (
            isWizardStep(destination) &&
            destination.isCompleteWithValidResult() &&
            destination.id !== previousStepId
          ) {
            const nextDestination = destination.getNextDestination();
            return nextDestination
              ? traverse(verifiedStepIds.add(destination.id), traversal, localState2, nextDestination, destination.id)
              : localState2;
          } else {
            return FastPromise.resolve(localState2);
          }
        })
        .recover((error) => {
          if (error instanceof InitializationError && !error.initForResult) {
            if (previousStepId !== "") {
              const stateForPreviousStep = localState.workItemStates.get(previousStepId);
              if (stateForPreviousStep) {
                return localState.copy({
                  workItemStates: localState.workItemStates.changeToUncompleted(
                    previousStepId,
                    stateForPreviousStep.result
                  )
                });
              }
            }
            // We failed to "un-complete" previous step for some reason (may be we are on the first step?)
            // Anyway, there's no way to recover from that
            return localState;
          } else {
            return localState.copy({
              workItemStates: localState.workItemStates.trim(verifiedStepIds)
            });
          }
        });
    }

    const currentStepId = state.path.currentId();

    return traverse(Set(), this.traversal(), state, this.firstDestinationFactory(), "").map((state2) => {
      if (state2.workItemStates.equals(state.workItemStates)) {
        return FastPromise.resolve(state.buildResult(WorkflowMutationResult.DidNotChange()));
      } else {
        return this.traversal()
          .currentOrClosestStep(state2)
          .map(({ state: state3, result: step })
            : Workflow.SyncMutationResult<WizardContext, WizardFeedback, WizardResult> => {
            if (step) {
              return state3.buildResult(
                WorkflowMutationResult.Progressed(
                  step.id === currentStepId ? state.path : step.getLastNestedWorkItemPath()
                )
              );
            } else {
              // No current step after cleanup?!
              return state3.buildResult(WorkflowMutationResult.DidNotChange());
            }
          })
          .recover((error) => {
            // If initialization error originally happened in resultContext, we will erase invalid result and
            // re-start wizard from the current step. But another error can now happen in propsContext, therefore we
            // need another round to clean this error too. After cleanup, the wizard will return to the previous step
            // that supposed to be already verified.
            // Ideally, we should iterate until all errors are cleaned up, but it's not clear when thi can be required.
            // Anyway, one more iteration is added here just in case.
            if (round < 2) {
              return this.cleanUp(state2, round + 1);
            } else {
              throw error;
            }
          });
      }
    });
  }

  public renderCurrentWorkItem(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    hooks: AnyRenderHooks<WizardFeedback>
  ): Workflow.AsyncOptionalReactElementResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().currentOrClosestStep(state).map((result) =>
      result.map((step) => step && step.render(hooks))
    );
  }

  public getAnchors(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): Workflow.AsyncAnchorsResult<WizardContext, WizardFeedback, WizardResult> {

    function getSelectedStep(
      currentStepId: string | undefined,
      steps: List<AnyWizardStep<WizardContext, WizardFeedback, WizardResult>>
    ): AnyWizardStep<WizardContext, WizardFeedback, WizardResult> | undefined {
      const currentStep = steps.find((step) => step.id === currentStepId);
      if (currentStep && !currentStep.getAnchor()) {
        return steps
          .takeUntil((step) => step === currentStep)
          .findLast((step) => !!step.getAnchor());
      } else {
        return currentStep;
      }
    }

    return this.traversal().traverseSteps(state).map((result) =>
      result.map((steps) => {
        const last = steps.last(undefined);
        if (last !== undefined) {
          const selectedStep = getSelectedStep(result.state.path.currentId(), steps);
          let currentNumber = 0;
          return steps
            .map((step): PreparedAnchor | undefined => {
              const anchor = step.getAnchor();
              if (anchor) {
                if (anchor.numbered) {
                  currentNumber += 1;
                }
                return {
                  ...anchor,
                  selected: selectedStep !== undefined && step.id === selectedStep.id,
                  number: anchor.numbered ? currentNumber : undefined
                };
              }
            })
            .filter<PreparedAnchor>(isDefined)
            .concat(
              last.getFutureSteps().map((anchor): PreparedAnchor => {
                if (anchor.numbered) {
                  currentNumber += 1;
                }
                return {
                  ...anchor,
                  selected: false,
                  number: anchor.numbered ? currentNumber : undefined
                };
              })
            );
        } else {
          return List();
        }
      })
    );
  }

  public fireSideEffects(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): Workflow.AsyncVoidResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().traverseSteps(state).map((steps) => {
      steps.result.forEach((step, index) => step.sideEffect(index === steps.result.size - 1));
      return state.buildResult(void 0);
    });
  }

  public isComplete(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): Workflow.AsyncBooleanResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().lastDestination(state).map((result) => result.map(isWizardFinish));
  }

  public getPartialResult(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): Workflow.AsyncPartialResultResult<WizardContext, WizardFeedback, WizardResult> {
    return this.traversal().lastDestination(state).map((result) =>
      result.map((destination) =>
        isWizardFinish(destination) ? destination.wizardResult : destination.getWizardPartialResult()
      )
    );
  }

  public watch(): Observable<Set<string>> {
    return this.traversal().watch();
  }

  protected traversal(): WizardStateTraversal<WizardContext, WizardFeedback, WizardResult> {
    return new WizardStateTraversal(this.firstDestinationFactory());
  }
}
