import * as React from "react";
import { ConnectionPanelDefs } from "./connectionPanelDefs";
import { AuthContext } from "../authContext";
import { Field, FieldProps, Form, FormikProps, withFormik } from "formik";
import { AreaRow } from "./rows/areaRow";
import { styled } from "../../../app/theme";
import { PanelRowDefs } from "../../containers/rows/panelRow";
import { prepareActionItems } from "./prepareActionItems";
import { CloudServices } from "../../../types/models/cloudServices";
import { List, Set } from "immutable";
import { ActionItems } from "../../models/actionItem";

export function shouldDisplayAreaRow(
  area: ConnectionPanelDefs.Area,
  viewSettings: ConnectionPanelDefs.ViewSettings): boolean {
  return (
    viewSettings.showAreasWithNoIssues ||
    viewSettings.showProgress ||
    !!area.incrementalSignIn ||
    !prepareActionItems({ subjectId: area.areaId, affectedSubject: area.mainSubject, ...area }).isEmpty()
  );
}

const StyledForm = styled(Form)`
  ${PanelRowDefs.borderTopMixin()};
`;

interface FormValues {
  [area: string]: boolean;
}

interface AreasProps {
  authContext: AuthContext;
  cloudServices: CloudServices;
  selectedCloudServiceId: string;
  connectionId: string;
  areas: List<ConnectionPanelDefs.Area>;
  areaSelection: ConnectionPanelDefs.AreaSelection | undefined;
  viewSettings: ConnectionPanelDefs.ViewSettings;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

const AreasView: React.FunctionComponent<AreasProps & FormikProps<FormValues>> = (props) => {
  const visibleAreas = props.areas.filter((area) => shouldDisplayAreaRow(area, props.viewSettings));
  if (visibleAreas.isEmpty()) {
    return null;
  } else {
    return (
      <StyledForm onChange={() => setTimeout(props.submitForm, 0)}>
        {
          visibleAreas.map((area) => (
            <Field
              key={area.title}
              name={area.title}
              render={(field: FieldProps) => (
                <AreaRow
                  role={props.authContext}
                  cloudServices={props.cloudServices}
                  selectedCloudServiceId={props.selectedCloudServiceId}
                  connectionId={props.connectionId}
                  area={area}
                  field={props.areaSelection && field}
                  showProgress={props.viewSettings.showProgress}
                  processDescription={props.viewSettings.processDescription}
                  actionItemSuppressing={props.actionItemSuppressing}
                />
              )}
            />
          ))
        }
      </StyledForm>
    );
  }
};

export const AreasForm = withFormik<AreasProps, FormValues>({
  mapPropsToValues: (props) => {
    const result: FormValues = {};
    props.areas.forEach((area) => {
      // It's important to have curly braces here, because forEach() stops when "false" is returned!
      result[area.title] = !area.disabled && (!props.areaSelection || !props.areaSelection.excluded.has(area.title));
    });
    return result;
  },
  handleSubmit: (values, bag) => {
    if (bag.props.areaSelection) {
      let excluded = Set<string>();
      // We don't want to include disabled areas in the list of explicitly excluded areas.
      bag.props.areas.forEach((area) => {
        if (values.hasOwnProperty(area.title) && !values[area.title] && !area.disabled) {
          excluded = excluded.add(area.title);
        }
      });
      bag.props.areaSelection.onChange(excluded);
    }
  },
  // This is required for the changes to the area's state ("disabled" first and foremost) to take effect after actions
  // not related to the form (such as signing in)
  enableReinitialize: true
})(AreasView);
