import { styled } from "../../../app/theme";
import { TaskResultType } from "../../../types/models/taskResultType";
import { Grid } from "../../widgets/grid";
import { Images } from "../../../app/images";
import { TimelineTooltip } from "../../../types/models/timelineTooltip";
import { WidgetStatus } from "../../utils/widgetStatus";

export const JobHistoryGridSubject = styled.div`
  display: flex;
`;

export const JobHistoryGridIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin: .1rem .3rem 0 0;
`;

export function taskResultTypeStyle(resultType: TaskResultType | undefined): WidgetStatus {
  switch (resultType) {
    case TaskResultType.Success: return WidgetStatus.Success;
    case TaskResultType.Failure: return WidgetStatus.Error;
    default: return WidgetStatus.Normal;
  }
}

export function taskResultTypeIcon(resultType: TaskResultType | undefined): any {
  switch (resultType) {
    case TaskResultType.Success: return Images.Jobs.TaskSuccess;
    case TaskResultType.Failure: return Images.Jobs.TaskFailure;
    case TaskResultType.Abort: return Images.Jobs.TaskAbort;
  }
}

export function renderTooltip(tooltip: TimelineTooltip): string | undefined {
  if (tooltip.summary || tooltip.props) {
    const result = tooltip.summary ? tooltip.summary + "<br><br>" : "";
    if (tooltip.props) {
      return result +
        "<table class=\"vis-timeline-tooltip\"><tbody>" +
        tooltip.props.map(([key, value]) => "<tr><td>" + key + "</td><td>" + value + "</td></tr>").join("") +
        "</tbody></table>";
    } else {
      return result;
    }
  } else {
    return undefined;
  }
}

export function itemProgress(
  createdAt: Date,
  completedAt: Date | undefined,
  archivedAt: Date | undefined): number | undefined {
  const end = archivedAt || new Date();
  if (completedAt) {
    const completed = completedAt.getTime() - createdAt.getTime();
    const total = end.getTime() - createdAt.getTime();
    return completed / total * 100;
  } else {
    return undefined;
  }
}
