import * as React from "react";
import { styled } from "../../app/theme";

export const StyledSvg = styled.svg`
  fill: ${(props) => props.theme.colors.primary};
`;

// tslint:disable max-line-length
export const ReportIcon: React.FunctionComponent = () => (
  <StyledSvg viewBox="106 106 805 805">
    <path d="M789.333333 106.666667H234.666667C198.4 106.666667 170.666667 134.4 170.666667 170.666667v676.266666c0 36.266667 27.733333 64 64 64h554.666666c36.266667 0 64-27.733333 64-64V170.666667c0-36.266667-27.733333-64-64-64z m21.333334 740.266666c0 12.8-8.533333 21.333333-21.333334 21.333334H234.666667c-12.8 0-21.333333-8.533333-21.333334-21.333334V170.666667c0-12.8 8.533333-21.333333 21.333334-21.333334h554.666666c12.8 0 21.333333 8.533333 21.333334 21.333334v676.266666z"  />
    <path d="M704 298.666667H469.333333c-12.8 0-21.333333 8.533333-21.333333 21.333333s8.533333 21.333333 21.333333 21.333333h234.666667c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333333z"  /><path d="M341.333333 320m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"  /><path d="M704 494.933333H469.333333c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h234.666667c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333334z"  /><path d="M341.333333 516.266667m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"  /><path d="M704 682.666667H469.333333c-12.8 0-21.333333 8.533333-21.333333 21.333333s8.533333 21.333333 21.333333 21.333333h234.666667c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333333z"  /><path d="M341.333333 704m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"  />
  </StyledSvg>
);
