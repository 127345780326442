import * as React from "react";
import { OptOutPageView } from "../../../views/screens/organizationPortal/automatedOffboarding/optOutPageView";
import { useRoutes } from "../../../app/routes/useRoutes";
import {
  useGetOffboardingProjectEntryParticipationStatusQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectEntryParticipationStatusQuery";
import {
  useUnsubscribeOffboardingProjectEntryMutation
} from "../../../queries/automatedOffboarding/useUnsubscribeOffboardingProjectEntryMutation";
import {
  useOptOutOffboardingProjectEntryMutation
} from "../../../queries/automatedOffboarding/useOptOutOffboardingProjectEntryMutation";

export const OptOutPage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.optOutRoutes.params();

  const [participationStatus] = useGetOffboardingProjectEntryParticipationStatusQuery(params.offboardingProjectEntryId);
  const [optOut, optOutStatus] = useOptOutOffboardingProjectEntryMutation(params.offboardingProjectEntryId);
  const [unsubscribe, unsubscribeStatus] =
    useUnsubscribeOffboardingProjectEntryMutation(params.offboardingProjectEntryId);

  return (
    <OptOutPageView
      participationStatus={participationStatus}

      optOutStatus={optOutStatus}
      onOptOut={optOut}

      unsubscribeStatus={unsubscribeStatus}
      onUnsubscribe={unsubscribe}
    />
  );
};
