import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyComponent } from "../component";
import { IncomingComponentRelationships } from "./incomingComponentRelationships";
import { JsonContent } from "./jsonContent";
import { ComponentHub } from "./componentHub";
import { ComponentPropertiesTable } from "./componentPropertiesTable";
import { ComponentSummary } from "./componentSummary";
import { Section } from "./section";
import { BlueprintDiff } from "../blueprintDiff";
import { ElementOutput } from "./elementOutput";

interface ComponentPropsProps {
  horizontal: boolean;
  component: AnyComponent;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  openSectionId?: string;
  onSectionSelect: (sectionId: string) => void;
  onComponentSelect: (componentId: string) => void;
}

export const ComponentProps: React.SFC<ComponentPropsProps> = (props) => {
  const hubs = props.component.hubList().map((hub, index) => (
    <ComponentHub
      key={index}
      horizontal={props.horizontal}
      component={props.component}
      index={index}
      hub={hub}
      blueprintContext={props.blueprintContext}
      diffs={props.diffs}
      openSectionId={props.openSectionId}
      onSectionSelect={props.onSectionSelect}
      onComponentSelect={props.onComponentSelect}
    />
  ));
  return (
    <>
      <Section
        horizontal={props.horizontal}
        title="Summary"
        sectionId="summary"
        openSectionId={props.openSectionId || "summary"}
        onSelect={props.onSectionSelect}
      >
        <ComponentSummary component={props.component} blueprintContext={props.blueprintContext}/>
        <ComponentPropertiesTable
          component={props.component}
          blueprintContext={props.blueprintContext}
          diffs={props.diffs}
        />
      </Section>
      <Section
        horizontal={props.horizontal}
        title="Props"
        sectionId="props"
        openSectionId={props.openSectionId}
        onSelect={props.onSectionSelect}
      >
        <JsonContent data={props.component.props}/>
      </Section>
      <Section
        horizontal={props.horizontal}
        title="Output"
        sectionId="output"
        openSectionId={props.openSectionId}
        onSelect={props.onSectionSelect}
      >
        <ElementOutput
          section={true}
          state={props.component.state(props.blueprintContext)}
          resolvedByServer={props.component.resolvedByServer(props.blueprintContext)}
          diff={props.diffs.getComponentDiff(props.component.id)}
        />
      </Section>
      {hubs}
      <Section
        horizontal={props.horizontal}
        title="Incoming Relationships"
        sectionId="incoming"
        openSectionId={props.openSectionId}
        onSelect={props.onSectionSelect}
      >
        <IncomingComponentRelationships
          component={props.component}
          blueprintContext={props.blueprintContext}
          diffs={props.diffs}
          onComponentSelect={props.onComponentSelect}
        />
      </Section>
    </>
  );
};
