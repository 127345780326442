import * as React from "react";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useCloudServices } from "../../../app/configuration";
import { CloudServices } from "../../../types/models/cloudServices";
import { AccountBox, TimeLineBar } from "../migrationSetup/connectionInterstitialStepView";
import { styled } from "../../../app/theme";

interface Props {
  logoId: string | undefined;
  logoWidth: number | undefined;
}

export const LogoPreview: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();
  const cloudServices = useCloudServices();
  const google = cloudServices.getOrFail(CloudServices.GSuite);

  return (
    <Layout>
      <AccountBox
        title="Migrate From:"
        logo={props.logoId ? routes.api.downloadUrl(props.logoId) : google.icon}
        logoWidth={props.logoWidth}
        description={"student@school.edu"}
      />
      <TimeLineBar arrow={true}/>
      <AccountBox
        title="Migrate To:"
        logo={google.icon}
        logoWidth={undefined}
        description={undefined}
      />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
`;
