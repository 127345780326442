import * as React from "react";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { Link } from "react-router-dom";
import { OperationStatus } from "../../../../types/operationStatus";
import { AccessList } from "../../../../types/models/accessList";
import { Button } from "../../../widgets/button";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { AccessListUpdate } from "../../../../types/models/accessListUpdate";
import { List } from "immutable";
import { Grid } from "../../../widgets/grid";
import { Block } from "views/containers/block";
import { GridPanelRow } from "views/containers/rows/gridPanelRow";
import { Panel } from "views/containers/panel";
import { commafy, friendlyCount, friendlyDateTime } from "../../../../utils/formatting";
import { PanelRow } from "views/containers/rows/panelRow";
import { AccessListUpdateStatus } from "../../../../types/enums/accessListUpdateStatus";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { styled } from "../../../../app/theme";
import { CheckedCircle } from "../../../glyphs/checkedCircle";
import { Spinner } from "../../../glyphs/spinner";
import { LinkButton } from "../../../widgets/linkButton";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { SimpleFormButtonsBlock } from "../../../widgets/simpleForm";

interface Props {
  accessListId: number;
  accessListStatus: OperationStatus<AccessList>;
  accessListHistoryStatus: OperationStatus<List<AccessListUpdate>>;

  homePath: string;
  accessListPath: string;

  onRefresh: () => void;
  onDownloadOutput: (accessListUpdateId: number) => void;
}

export const ImportHistoryPageView: React.FunctionComponent<Props> = (props) => {
  const subject = props.accessListStatus
      .mapLastResult((accessList) => accessList.googleGroupId ? "Re-Sync" : "Import") ||
    "Import / Re-Sync";

  return (
    <>
      <HeaderBlock
        breadcrumbs={[
          <Link key={0} to={props.homePath}>Access Lists</Link>,
          <Link key={1} to={props.accessListPath}>
            {props.accessListStatus.mapLastResult((accessList) => accessList.name) || props.accessListId}
          </Link>,
          subject + " History"
        ]}
        title={subject + " History"}
      />
      <OperationStatusIndicator
        subject={"access list details"}
        status={props.accessListStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      <Block>
        <Panel>
          <OperationStatusIndicator
            subject={"access list update history"}
            status={props.accessListHistoryStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          {props.accessListHistoryStatus.mapLastResult((accessListUpdates) => (
            <>
              <PanelRow size={"smaller"}>
                <SimpleToolbar
                  centerVertically={true}
                  rightSide={
                    <Button
                      size={"small"}
                      color={"secondary"}
                      disabled={props.accessListStatus.isWorking()}
                      onClick={props.onRefresh}
                    >
                      Refresh
                    </Button>
                  }
                >
                  <span>{friendlyCount(accessListUpdates.size, "entry")} found</span>
                </SimpleToolbar>
              </PanelRow>
              {!accessListUpdates.isEmpty() && (
                <GridPanelRow>
                  <HistoryGrid
                    accessListUpdates={accessListUpdates}
                    onDownloadOutput={props.onDownloadOutput}
                  />
                </GridPanelRow>
              )}
            </>
          ))}
        </Panel>
      </Block>
      <SimpleFormButtonsBlock>
        <Link to={props.accessListPath}>
          <Button size={"small"} color={"secondary"}>Back</Button>
        </Link>
      </SimpleFormButtonsBlock>
    </>
  );
};

interface HistoryGridProps {
  accessListUpdates: List<AccessListUpdate>;
  onDownloadOutput: (accessListUpdateId: number) => void;
}

export const HistoryGrid: React.FunctionComponent<HistoryGridProps> = (props) => (
  <Grid>
    <Grid.Header>
      <Grid.Column>Started</Grid.Column>
      <Grid.Column>Completed</Grid.Column>
      <Grid.Column>Status</Grid.Column>
      <Grid.Column>Added Entries</Grid.Column>
      <Grid.Column>Removed Entries</Grid.Column>
      <Grid.Column>Errors</Grid.Column>
      <Grid.Column>Results (CSV)</Grid.Column>
    </Grid.Header>
    <Grid.Body>
      {props.accessListUpdates.map((update) => (
        <Grid.Row key={update.id}>
          <Grid.Cell>{friendlyDateTime(update.createdAt)}</Grid.Cell>
          <Grid.Cell>{update.completedAt ? friendlyDateTime(update.createdAt) : ""}</Grid.Cell>
          <Grid.Cell
            status={
              update.status === AccessListUpdateStatus.Success
                ? WidgetStatus.Success
                : update.status === AccessListUpdateStatus.Failure
                  ? WidgetStatus.Error
                  : WidgetStatus.Info
            }
          >
            <StatusIndicatorContainer>
              {
                update.status === AccessListUpdateStatus.Success
                  ? <CheckedCircle/>
                  : update.status === AccessListUpdateStatus.Failure
                    ? <Spinner progress={50} color={"red"}/>
                    : <Spinner progress={-1}/>
              }
            </StatusIndicatorContainer>
            {
              update.status === AccessListUpdateStatus.Success || update.status === AccessListUpdateStatus.Failure
                ? update.status
                : "Running"
            }
            {update.status === AccessListUpdateStatus.Failure && <>{" "}({update.errorMessage})</>}
          </Grid.Cell>
          <Grid.Cell>{commafy(update.addedEntries)}</Grid.Cell>
          <Grid.Cell>{commafy(update.removedEntries)}</Grid.Cell>
          <Grid.Cell>{commafy(update.errors)}</Grid.Cell>
          <Grid.Cell>
            {
              update.status === AccessListUpdateStatus.Success &&
                <LinkButton onClick={() => props.onDownloadOutput(update.id)}>Download results (CSV)</LinkButton>
            }
          </Grid.Cell>
        </Grid.Row>
      ))}
    </Grid.Body>
  </Grid>
);

const StatusIndicatorContainer = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  font-size: 0.9rem;
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
`;
