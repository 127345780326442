import * as React from "react";
import * as yup from "yup";
import { CloudService } from "../../types/models/cloudService";
import { Blueprint } from "../../blueprints/blueprint";
import {
  BatchMigrationPlanStepView
} from "../../views/screens/migrationSetup/batch/batchMigrationPlanStepView/batchMigrationPlanStepView";
import { EditBatchMigrationItem } from "./editBatchMigrationItem";
import { List } from "immutable";
import {
  MigrationBlueprintInputs
} from "../../blueprints/materializedMigrationBlueprint";
import { Connection } from "../../types/models/connection";
import { Connections } from "../../types/models/connections";
import { useResolveConnectionsMutation } from "../../queries/useResolveConnectionsMutation";
import { identity, nullToUndefined } from "../../utils/misc";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { AdminSidebar } from "../../views/layouts/sidebar";
import { useBrowser } from "../../utils/useBrowser";
import { OperationStatus } from "../../types/operationStatus";
import { ResolvedConnectionSettings } from "./connectionSettings";
import { friendlyCount } from "../../utils/formatting";
import { Constants } from "../../app/constants";
import { useElevated, useSession } from "../../utils/useAppState";
import {
  AddMultipleRowsControllerProps
} from "../../views/screens/migrationSetup/batch/batchMigrationPlanStepView/addMultipleRowsForm";
import {
  ReScanAllControllerProps
} from "../../views/screens/migrationSetup/batch/batchMigrationPlanStepView/reScanAllForm";
import { BatchMigrationPlanWithFacts } from "./batchMigrationPlan";
import { useRoutes } from "../../app/routes/useRoutes";

export interface PendingBatchMigrationItem {
  sourceConnection: Connection | undefined;
  destinationConnection: Connection | undefined;
}

export namespace PendingBatchMigrationItem {
  export function build(row: PendingBatchMigrationItemJson, connections: Connections): PendingBatchMigrationItem {
    return {
      sourceConnection: row.sourceConnectionId ? connections.get(row.sourceConnectionId) : undefined,
      destinationConnection: row.destinationConnectionId ? connections.get(row.destinationConnectionId) : undefined
    };
  }

  export function equals(item1: PendingBatchMigrationItem, item2: PendingBatchMigrationItem): boolean {
    return (
      item1.sourceConnection?.id === item2.sourceConnection?.id &&
      item1.destinationConnection?.id === item2.destinationConnection?.id
    );
  }

  export function remove(
    items: List<PendingBatchMigrationItem>,
    itemsToRemove: List<PendingBatchMigrationItem>
  ): List<PendingBatchMigrationItem> {
    return items.filter((item) =>
      itemsToRemove.findIndex((existingItem) => equals(item, existingItem)) === -1
    );
  }
}

export interface PendingBatchMigrationItemJson {
  sourceConnectionId?: string;
  destinationConnectionId?: string;
}

namespace PendingBatchMigrationItemJson {
  export const Schema = yup.object<PendingBatchMigrationItemJson>({
    sourceConnectionId: yup.string().notRequired(),
    destinationConnectionId: yup.string().notRequired(),
  });
}

export interface MigrationPlanStepResult {
  items: PendingBatchMigrationItemJson[];
}

export namespace MigrationPlanStepResult {
  export const Schema = yup.object<MigrationPlanStepResult>({
    items: yup.array().of(PendingBatchMigrationItemJson.Schema.required())
  });

  export const empty: MigrationPlanStepResult = {
    items: []
  };
}

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  sourceConnectionSettings: ResolvedConnectionSettings;
  destinationConnectionSettings: ResolvedConnectionSettings;
  blueprint: Blueprint;

  items: List<PendingBatchMigrationItem>;
  batchMigrationPlan: BatchMigrationPlanWithFacts;

  onApply: (step: MigrationPlanStepResult) => void;
  onComplete: (step: MigrationPlanStepResult) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const BatchMigrationPlanStep: React.FunctionComponent<Props> = (props) => {
  const session = useSession();
  const browser = useBrowser();
  const routes = useRoutes();
  const [editedRow, setEditedRow] = React.useState<number>();

  function buildResult(items: List<PendingBatchMigrationItem>): MigrationPlanStepResult {
    return {
      items: items
        .filter((item) =>
          (
            !item.sourceConnection ||
            item.sourceConnection.cloudServiceId === props.sourceCloudService.id
          ) &&
          (
            !item.destinationConnection ||
            item.destinationConnection.cloudServiceId === props.destinationCloudService.id
          )
        )
        .map((item) => ({
          sourceConnectionId: item.sourceConnection?.id,
          destinationConnectionId: item.destinationConnection?.id
        }))
        .toArray()
    };
  }

  const itemsFingerprint = props.items
    .map(({ sourceConnection, destinationConnection }) => sourceConnection?.id + "-" + destinationConnection?.id)
    .join(",");

  // This is required to avoid controller re-creation (and losing state) on every render
  const addMultipleRowsController = React.useMemo<React.ComponentType<AddMultipleRowsControllerProps>>(
    () => (controllerProps) => (
      <AddMultipleRowsController
        {...controllerProps}
        maxItems={Constants.MaxBatchSize - props.items.size}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}
        sourceAdminConnectionId={props.sourceConnectionSettings.adminConnection?.id}
        destinationAdminConnectionId={props.destinationConnectionSettings.adminConnection?.id}
        onAdd={(items) => {
          props.onApply(buildResult(props.items.concat(PendingBatchMigrationItem.remove(items, props.items))));
          // This allows adding duplicate rows (useful for debugging)
          // props.onApply(buildResult(props.items.concat(items)));
        }}
      />
    ),
    [
      props.sourceCloudService,
      props.destinationCloudService,
      props.sourceConnectionSettings.adminConnection?.id,
      props.destinationConnectionSettings.adminConnection?.id,
      itemsFingerprint
    ]
  );

  // This is required to avoid controller re-creation (and losing state) on every render
  const reScanAllController = React.useMemo<React.ComponentType<ReScanAllControllerProps>>(
    () => (controllerProps) => (
      <ReScanAllController
        {...controllerProps}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}
        blueprint={props.blueprint}
        items={props.items}
      />
    ),
    [props.sourceCloudService, props.destinationCloudService, props.blueprint, itemsFingerprint]
  );

  const [generateBatchReport, generateBatchReportStatus] = useGenerateBatchReportMutation(
    props.sourceCloudService,
    props.destinationCloudService,
    props.items
  );

  return session
    ? (
      <>
        <AdminSidebar
          blueprint={
            editedRow === undefined || editedRow < 0
              ? undefined
              : props.batchMigrationPlan.getMigrationBlueprint({
                sourceConnection: props.items.get(editedRow)?.sourceConnection,
                destinationConnection: props.items.get(editedRow)?.destinationConnection
              })
          }
        />
        <BatchMigrationPlanStepView
          step={props.step}
          totalSteps={props.totalSteps}
          onNavigateBack={props.onNavigateBack}
          onProceed={() => props.onComplete(buildResult(props.items))}

          sourceCloudService={props.sourceCloudService}
          destinationCloudService={props.destinationCloudService}
          items={props.batchMigrationPlan.gridRows}
          edit={editedRow !== undefined
            ? {
              content: (
                <EditBatchMigrationItem
                  sourceCloudService={props.sourceCloudService}
                  destinationCloudService={props.destinationCloudService}
                  blueprint={props.blueprint}

                  adding={editedRow < 0}
                  sourceConnection={editedRow < 0 ? undefined : props.items.get(editedRow)?.sourceConnection}
                  destinationConnection={editedRow < 0 ? undefined : props.items.get(editedRow)?.destinationConnection}

                  onSubmit={(sourceConnection, destinationConnection) => {
                    const updatedItems = editedRow < 0
                      ? props.items.push({ sourceConnection, destinationConnection })
                      : props.items.set(editedRow, { sourceConnection, destinationConnection });
                    props.onApply(buildResult(updatedItems));
                    setEditedRow(undefined);
                  }}
                  onCancel={() => setEditedRow(undefined)}
                  onDelete={undefined}
                  // onDelete={editedRow < 0
                  //   ? undefined
                  //   : () => {
                  //     props.onApply(buildResult(props.items.remove(editedRow)));
                  //     setEditedRow(undefined);
                  //   }
                  // }
                />
              ),
              adding: editedRow < 0
            }
            : undefined
          }

          sourceConnectionMethod={props.sourceConnectionSettings.mode}
          destinationConnectionMethod={props.destinationConnectionSettings.mode}

          sourceConnectLink={
            browser.url(
              routes.connect.pathForParams({
                userId: session.user.id,
                sourceCloudServiceId: props.sourceCloudService.id,
                destinationCloudServiceId: props.destinationCloudService.id,
                isSource: true
              })
            )
          }
          destinationConnectLink={
            browser.url(
              routes.connect.pathForParams({
                userId: session.user.id,
                sourceCloudServiceId: props.sourceCloudService.id,
                destinationCloudServiceId: props.destinationCloudService.id,
                isSource: false
              })
            )
          }

          onAddRow={() => setEditedRow(-1)}
          onEditRow={setEditedRow}
          onRemoveRow={(index) => props.onApply(buildResult(props.items.remove(index)))}
          onClear={() => props.onApply(MigrationPlanStepResult.empty)}

          addingRowsDisabled={!session.elevated && props.items.size >= Constants.MaxBatchSize}
          addMultipleRowsController={addMultipleRowsController}
          reScanAllController={reScanAllController}

          onDownloadScanReport={
            () => generateBatchReport().then((id) =>
              browser.openNewTab(routes.api.batchScanReportUrl(id), true)
            )
          }
          downloadScanReportStatus={generateBatchReportStatus}
        />
      </>
    )
    : null;
};

interface SourceAndDestinationEmailAddresses {
  sourceEmailAddress: string;
  destinationEmailAddress: string | undefined;
}

interface MyAddMultipleRowsControllerProps extends AddMultipleRowsControllerProps {
  maxItems: number;
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  sourceAdminConnectionId: string | undefined;
  destinationAdminConnectionId: string | undefined;

  onAdd: (items: List<PendingBatchMigrationItem>) => void;
}

const AddMultipleRowsController: React.FunctionComponent<MyAddMultipleRowsControllerProps> = (props) => {
  const elevated = useElevated();

  const [resolveConnections, status] = useResolveConnectionsMutation();

  function parseValue(value: string): List<SourceAndDestinationEmailAddresses> {
    return List(value.split("\n"))
      .map((line) => line.trim())
      .filter((line) => line.length !== 0)
      .map((line): SourceAndDestinationEmailAddresses => {
        const parts = line.split(RegExp("\\s+"));
        return {
          sourceEmailAddress: parts[0].toLowerCase(),
          destinationEmailAddress: parts.length > 1 ? parts[1].toLowerCase() : undefined
        };
      });
  }

  return props.render(
    status,
    (value) => {
      const items = parseValue(value);

      if (!elevated && items.size > props.maxItems) {
        return Promise.reject({
          message:
            "Only " + friendlyCount(props.maxItems, "more account pair") + " " +
            "can be added to this batch, " +
            "but you tried to add " + friendlyCount(items.size, "account pair") + ".",
          submitted: items.size
        });
      }

      const incompletePairs = items
        .filter((item) => item.destinationEmailAddress === undefined)
        .map((item) => item.sourceEmailAddress)
        .toList()
        .sort();

      if (!incompletePairs.isEmpty()) {
        return Promise.reject({
          message:
            "The following source email addresses are missing their corresponding destination email addresses: " +
            incompletePairs.join(", "),
          submitted: items.size
        });
      }

      const sourceEmailAddresses = items.map((item) => item.sourceEmailAddress.toLowerCase()).toSet();
      const destinationEmailAddresses = items
        .flatMap((item) =>
          item.destinationEmailAddress !== undefined
            ? List([item.destinationEmailAddress.toLowerCase()])
            : List<string>()
        )
        .toSet();

      return resolveConnections({
        sourceCloudServiceId: props.sourceCloudService.id,
        sourceEmailAddresses,
        sourceAdminConnectionId: props.sourceAdminConnectionId,
        destinationCloudServiceId: props.destinationCloudService.id,
        destinationEmailAddresses,
        destinationAdminConnectionId: props.destinationAdminConnectionId,
      }).then((result) => {
        if (result.noIssues) {
          props.onAdd(
            items.map(({ sourceEmailAddress, destinationEmailAddress }) => ({
              sourceConnection: sourceEmailAddress !== undefined
                ? result.source.resolved.find((connection) =>
                  connection.personalInfo.emailAddress?.toLowerCase() === sourceEmailAddress.toLowerCase()
                )
                : undefined,
              destinationConnection: destinationEmailAddress !== undefined
                ? result.destination.resolved.find((connection) =>
                  connection.personalInfo.emailAddress?.toLowerCase() === destinationEmailAddress.toLowerCase()
                )
                : undefined
            }))
          );
          return undefined;
        } else {
          return {
            ...result,
            submitted: items.size
          };
        }
      });
    }
  );
};

interface MyReScanAllControllerProps extends ReScanAllControllerProps {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: Blueprint;

  items: List<PendingBatchMigrationItem>;
}

export const ReScanAllController: React.FunctionComponent<MyReScanAllControllerProps> = (props) => {
  const [refresh, { status }] = useManagedMutation({
    mutation: GraphQL.useRefreshBatchFactsMutation,
    extract: (data: GraphQL.RefreshBatchFactsMutation) => nullToUndefined(data.refreshBatchFacts),
    complete: (result) => ({ facts: result.facts.length, scanJobs: result.scanJobIds.length })
  });

  return props.render(
    status,
    () => refresh({
      variables: {
        sourceCloudServiceId: props.sourceCloudService.id,
        destinationCloudServiceId: props.destinationCloudService.id,
        batchItems: props.items.map((item) => ({
          blueprintInputs: MigrationBlueprintInputs.build({
            sourceCloudServiceId: props.sourceCloudService.id,
            destinationCloudServiceId: props.destinationCloudService.id,
            blueprint: props.blueprint,
            sourceConnection: item.sourceConnection,
            destinationConnection: item.destinationConnection,
            excludedAreas: []
          }).toGraphQL()
        })).toArray()
      }
    })
  );
};

type GenerateBatchReportMutationHook = [() => Promise<string>, OperationStatus<string>];

function useGenerateBatchReportMutation(
  sourceCloudService: CloudService,
  destinationCloudService: CloudService,
  items: List<PendingBatchMigrationItem>,
): GenerateBatchReportMutationHook {
  const variables: GraphQL.GenerateBatchReportMutationVariables = {
    sourceCloudServiceId: sourceCloudService.id,
    destinationCloudServiceId: destinationCloudService.id,
    batchItems: items
      .filter((item) => !!item.sourceConnection)
      .map((item) => ({
        sourceConnectionId: (item.sourceConnection as Connection).id,
        destinationConnectionId: item.destinationConnection?.id
      }))
      .toArray()
  };

  const [generate, { status }] = useManagedMutation({
    mutation: GraphQL.useGenerateBatchReportMutation,
    variables,
    extract: (data: GraphQL.GenerateBatchReportMutation) => nullToUndefined(data.generateBatchReport),
    complete: identity
  });

  function go(): Promise<string> {
    return generate({
      retry: go,
    });
  }

  return [go, status];
}
