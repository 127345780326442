import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { OffboardingProjectStats } from "../../types/models/offboardingProjectStats";

export function useListOffboardingProjectStatsQuery(
  id: string
): ManagedQueryHook<GraphQL.ListOffboardingProjectStatsQueryVariables, List<OffboardingProjectStats>> {
  return useManagedQuery({
    query: GraphQL.useListOffboardingProjectStatsQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.ListOffboardingProjectStatsQuery) => nullToUndefined(data.offboardingProjectStats),
    complete: (offboardingProjectStats) => List(offboardingProjectStats).map(OffboardingProjectStats.parse)
  });
}
