import { School } from "../../types/models/school";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchSchoolsQuery(
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchSchoolsQueryVariables, List<School>> {
  return useManagedQuery({
    query: GraphQL.useSearchSchoolsQuery,
    deps: term,
    prepare: (deps) => ({ term: deps }),
    extract: (data: GraphQL.SearchSchoolsQuery) => nullToUndefined(data.searchSchools),
    complete: (schools) => List(schools)
      .filter((school) => school.id !== "")
      .map(School.parse),
    fetchPolicy: "cache-and-network"
  });
}
