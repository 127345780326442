import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { RelationshipWithOriginComponent } from "../blueprintComponents";
import { JsonContent } from "./jsonContent";
import { ComponentPropertiesTable } from "./componentPropertiesTable";
import { ComponentSummary } from "./componentSummary";
import { RelationshipPropertiesTable } from "./relationshipPropertiesTable";
import { Section } from "./section";
import { SubSection } from "./subSection";
import { ServerLabel } from "./serverLabel";
import { BlueprintDiff } from "../blueprintDiff";
import { ElementOutput } from "./elementOutput";

interface ComponentPropsProps {
  horizontal: boolean;
  relationship: RelationshipWithOriginComponent;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  openSectionId?: string;
  onSectionSelect: (sectionId: string) => void;
  onComponentSelect: (componentId: string) => void;
}

export const RelationshipProps: React.SFC<ComponentPropsProps> = (props) => {
  const component = props.relationship.relationship.component();
  const componentProps = component && (
    <SubSection>
      <ComponentSummary
        component={component}
        blueprintContext={props.blueprintContext}
        showArrow={true}
        onComponentSelect={props.onComponentSelect}
      />
      <ComponentPropertiesTable
        component={component}
        blueprintContext={props.blueprintContext}
        diffs={props.diffs}
      />
    </SubSection>
  );
  return (
    <React.Fragment>
      <Section
        horizontal={props.horizontal}
        title="Summary"
        sectionId="summary"
        openSectionId={props.openSectionId || "summary"}
        onSelect={props.onSectionSelect}
      >
        <SubSection>
          <RelationshipPropertiesTable
            relationship={props.relationship.relationship}
            blueprintContext={props.blueprintContext}
            diffs={props.diffs}
            noMargin={true}
          />
        </SubSection>
        <SubSection>
          <ComponentSummary
            component={props.relationship.component}
            blueprintContext={props.blueprintContext}
            showArrow={true}
            reverseArrow={true}
            onComponentSelect={props.onComponentSelect}
          />
          <ComponentPropertiesTable
            component={props.relationship.component}
            blueprintContext={props.blueprintContext}
            diffs={props.diffs}
          />
        </SubSection>
        {componentProps}
      </Section>
      <Section
        horizontal={props.horizontal}
        title="Props"
        sectionId="props"
        openSectionId={props.openSectionId}
        onSelect={props.onSectionSelect}
      >
        <JsonContent data={props.relationship.relationship.props}/>
      </Section>
      <Section
        horizontal={props.horizontal}
        title="Output"
        sectionId="output"
        openSectionId={props.openSectionId}
        onSelect={props.onSectionSelect}
      >
        <ElementOutput
          section={true}
          state={props.relationship.relationship.state(props.blueprintContext)}
          resolvedByServer={props.relationship.relationship.resolvedByServer(props.blueprintContext)}
          diff={props.diffs.getRelationshipDiff(
            props.relationship.relationship.sourceComponentId,
            props.relationship.relationship.hubName,
            props.relationship.relationship.componentId,
          )}
        />
      </Section>
    </React.Fragment>
  );
};
