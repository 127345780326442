import { BlueprintContext } from "../blueprintContext";
import { Relationship, RelationshipProps } from "../relationship";
import { State } from "../state";
import { GraphQL } from "../../services/graphql/generated";

export class Association extends Relationship<Association.Props> {
  public calcState(context: BlueprintContext): State<undefined> {
    return State.resolved(undefined);
  }
}

export namespace Association {
  export type Props = RelationshipProps;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.AssociationPropsFragment): Props {
      return RelationshipProps.fromGraphQL(props);
    }
  }
}
