import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { EmailAddressWhitelistingForProgram } from "../../types/models/emailAddressWhitelistingForProgram";

export function useListTestAccountProgramAssignmentsQuery(): ManagedQueryHook<
  GraphQL.TestAccountWhitelistingsForProgramsQueryVariables,
  List<EmailAddressWhitelistingForProgram>
  > {
  return useManagedQuery({
    query: GraphQL.useTestAccountWhitelistingsForProgramsQuery,
    deps: null,
    prepare: () => ({}),
    extract: (data: GraphQL.TestAccountWhitelistingsForProgramsQuery) =>
      nullToUndefined(data.testAccountWhitelistingsForPrograms),
    complete: (items) =>
      List(items)
        .map(EmailAddressWhitelistingForProgram.parse)
        .sortBy((item) => item.sortKey),
    fetchPolicy: "network-only"
  });
}
