import * as React from "react";
import { PanelRow } from "../../containers/rows/panelRow";
import { RoleSelector, RoleSelectorProps } from "./roleSelector";
import { styled } from "../../../app/theme";

const ProviderId = styled.div`
  margin-bottom: 1rem;
`;

interface RoleSelectorRowProps extends RoleSelectorProps {
  authProviderId: string;
}

export const RoleSelectorRow: React.FunctionComponent<RoleSelectorRowProps> = (props) => {
  const { authProviderId, ...rest } = props;
  return (
    <>
      <PanelRow>
        <ProviderId>Authentication provider: <strong>{authProviderId}</strong></ProviderId>
        <RoleSelector {...rest}/>
      </PanelRow>
    </>
  );
};
