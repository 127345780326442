import * as React from "react";
import { styled } from "../../app/theme";
import { InfoButton } from "../widgets/infoButton";
import { Panel } from "../containers/panel";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { Images } from "../../app/images";
import { MultiColumnRow, Column, ColumnResponsiveBehavior } from "../containers/rows/multiColumnRow";
import { TitlePanelRow } from "../containers/rows/titlePanelRow";
import { LinkButton } from "../widgets/linkButton";
import { PanelRow } from "../containers/rows/panelRow";
import { BrandedTitlePanelRow } from "../containers/rows/brandedTitlePanelRow";
import { EmbeddedContent } from "../utils/embeddedContent";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";

const helpArticle: PreparedHelpArticle = {
  id: undefined,
  content: <EmbeddedContent>Hi there!</EmbeddedContent>,
  summary: "Click me!"
};

const actions = [
  <LinkButton key="action1">Additional Action</LinkButton>,
  <LinkButton key="action2">Action</LinkButton>
];

const actionAndInfoButton = [
  <LinkButton key="action">Action</LinkButton>,
  <InfoButton key="infoButton" helpArticle={helpArticle}/>
];

const ContentWithReducedPadding = styled.div`
  margin-top: -.5rem;
`;

const TestLayout = styled.div`
  background: #eee;
`;

export const Panels: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Rows and Sidebars</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <TitlePanelRow>
          A title row
        </TitlePanelRow>
        <PanelRow>
          A regular row
        </PanelRow>
        <PanelRow icon={Images.MissingIcon} helpArticle={helpArticle}>
          A row with an icon and an info button
        </PanelRow>
        <PanelRow icon={Images.MissingIcon} helpArticle={helpArticle}>
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
        </PanelRow>
        <PanelRow actions={<LinkButton>Action</LinkButton>}>
          A row with an action
        </PanelRow>
        <PanelRow actions={actions}>
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
        </PanelRow>
        <PanelRow actions={actions} helpArticle={helpArticle}>
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
          A tall row with lots of content that spans over multiple lines.
        </PanelRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Branded Title Row</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <BrandedTitlePanelRow>
          A branded title row
        </BrandedTitlePanelRow>
        <PanelRow>
          A regular row
        </PanelRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Row Sizes</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow size="custom">
          A row with no padding
        </PanelRow>
        <PanelRow size="small">
          A small row
        </PanelRow>
        <PanelRow size="regular">
          A regular row (default)
        </PanelRow>
        <PanelRow size="larger">
          A larger row
        </PanelRow>
        <PanelRow size="large">
          A large row
        </PanelRow>
        <PanelRow size="x-large">
          An extra large row
        </PanelRow>
        <PanelRow>
          <ContentWithReducedPadding>
            A regular row with a component that slightly shifts upwards wherever it's placed (using negative margin)
          </ContentWithReducedPadding>
        </PanelRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Non-responsive Multi-Column Layout</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <MultiColumnRow>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Stay}>
            This is an expandable column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Stay}>
            This is a column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Stay}>
            This is a column with lots and lots of content that spans over multiple lines
          </Column>
        </MultiColumnRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Simple Responsive Multi-Column Layout</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <MultiColumnRow>
          <Column width={2} responsiveBehavior={ColumnResponsiveBehavior.Expand}>
            This is a wide and expandable column
          </Column>
          <Column>
            This is a column
          </Column>
          <Column>
            This is a column with lots and lots of content that spans over multiple lines
          </Column>
        </MultiColumnRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Advanced Responsive Multi-Column Layout</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow>Regular row</PanelRow>
        <MultiColumnRow>
          <Column>
            This is a column
          </Column>
          <Column>
            This is a column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Expand}>
            This is an expandable column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Expand}>
            This is an expandable column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Stay}>
            This is a column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Stay}>
            This is a column
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Expand}>
            This is an expandable column
          </Column>
        </MultiColumnRow>
        <PanelRow>Regular row</PanelRow>
      </Panel>
    </StyleguideBlock>
  </React.Fragment>
);
