import * as React from "react";
import * as yup from "yup";
import { ConnectionStepView } from "../views/screens/migrationSetup/connectionStepView";
import { CloudService } from "../types/models/cloudService";
import { Connection } from "../types/models/connection";
import { MyConnectionSelectorPanel } from "../components/connectionManagement/myConnectionSelectorPanel";
import { UserConnectionSelectorPanel } from "../components/connectionManagement/userConnectionSelectorPanel";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { useConnectionFlow } from "./useConnectionFlow";
import { AdminSidebar } from "../views/layouts/sidebar";
import { EntityJsonView } from "../views/blocks/entityJsonView";
import { OperationStatus } from "../types/operationStatus";
import { UserType } from "../types/models/userType";
import { useSourceConnectionPromotions } from "./useSourceConnectionPromotions";

export interface ConnectionStepResult {
  connectionId: string;
}

export namespace ConnectionStepResult {
  export const Schema = yup.object<ConnectionStepResult>({
    connectionId: yup.string().required()
  });
}

interface Props {
  step: number;
  totalSteps: number;

  source: boolean;
  cloudService: CloudService;
  oppositeCloudService: CloudService;
  blueprint: MaterializedMigrationBlueprint;
  connection: Connection | undefined;
  oppositeConnection: Connection | undefined;

  onApply: (step: ConnectionStepResult) => void;
  onComplete: (step: ConnectionStepResult) => void;
  onClear: () => void;
  onNavigateBack?: () => void;

  onCloudServiceChange: (cloudServiceId: string) => void;
}

export const ConnectionStep: React.FunctionComponent<Props> = (props) => {
  const connectionState = useConnectionFlow({
    source: props.source,
    cloudService: props.cloudService,
    oppositeCloudService: props.oppositeCloudService,
    blueprint: props.blueprint,
    areas: props.source
      ? props.blueprint.listAreas().map(([area, sink]) =>
        ({ component: area, config: {}, notUsed: !sink })
      )
      : props.blueprint.listSinks().map(([sink, areas]) =>
        ({ component: sink, config: {}, notUsed: areas.isEmpty() })
      ),
    defaultRoles: props.blueprint.roles(props.source),

    connection: props.connection,
    oppositeConnection: props.oppositeConnection,

    connectionHandlers: {
      onConnect: (connection) => props.onApply({ connectionId: connection.id }),
      onDisconnect: () => props.onClear(),
      onCloudServiceChange: props.onCloudServiceChange
    },

    showRestrictions: false,
    newUserSettings: { userType: UserType.Personal }
  });

  const sourceConnectionPromotionMessage = useSourceConnectionPromotions({
    sourceConnectionId: props.source ? props.connection?.id : props.oppositeConnection?.id,
    destinationCloudServiceId: props.source ? props.oppositeCloudService.id : props.cloudService.id,
    destinationConnectionId: props.source ? props.oppositeConnection?.id : props.connection?.id,
  });

  return (
    <>
      <AdminSidebar
        tools={[
          {
            title: "My Connections",
            render: () => <MyConnectionSelectorPanel onSelect={connectionState.connect}/>
          },
          {
            title: "All Connections",
            render: () => <UserConnectionSelectorPanel onSelect={connectionState.connect}/>
          },
          {
            title: "JSON",
            render: () => props.connection
              ? <EntityJsonView status={OperationStatus.Success(props.connection)}/>
              : <>Please connect an account</>
          },
        ]}
        blueprint={props.blueprint}
      />
      <ConnectionStepView
        step={props.step}
        totalSteps={props.totalSteps}

        onNavigateBack={props.onNavigateBack}
        onProceed={() => props.connection && props.onComplete({ connectionId: props.connection.id })}
        connectionState={connectionState}
        sourceConnectionPromotionMessage={props.source ? sourceConnectionPromotionMessage : undefined}
      />
    </>
  );
};
