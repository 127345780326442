import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Organization } from "../../types/models/organization";

export function useGetOrganizationQuery(
  id: number | undefined
): ManagedQueryHook<GraphQL.GetOrganizationQueryVariables, Organization> {
  return useManagedQuery({
    query: GraphQL.useGetOrganizationQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetOrganizationQuery) => nullToUndefined(data.organization),
    complete: (organization) => Organization.parse(organization),
    fetchPolicy: "cache-and-network"
  });
}
