import { OffboardingProject } from "../../types/models/offboardingProject";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { OffboardingProjectFormData } from "../../types/models/offboardingProjectFormData";
import { ErrorHandler } from "../../services/graphql/errorHandler";

type InsertOffboardingProjectMutationHook = [
  (formData: OffboardingProjectFormData) => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export const InsertUpdateOffboardingProjectErrorHandler: ErrorHandler<OffboardingProject>[] = [
  [
    ErrorClass.ItemAlreadyExistsException,
    (error) => UserFacingError.expected(error, {
      summary: "Either the project name or the account selected for email delivery is already in use.",
      recommendations: "Please check the project name, select an account that will be used for email delivery " +
        "exclusively for this project, and try again.",
      retry: null
    })
  ],
  [
    ErrorClass.NotFoundError,
    (error) => UserFacingError.expected(error, {
      summary: "The email delivery setup process failed or timed out.",
      recommendations: "Please re-select the account that will be used for email delivery " +
        "exclusively for this project and try again.",
      retry: null
    })
  ],
  [
    ErrorClass.InvalidOperationException,
    (error) => UserFacingError.expected(error, {
      summary: "@domain of the email address of the account selected for email delivery is not registered " +
        "to this organization.",
      retry: null
    })
  ],
];

export function useInsertOffboardingProjectMutation(organizationId: number): InsertOffboardingProjectMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertOffboardingProjectMutation,
    extract: (data: GraphQL.InsertOffboardingProjectMutation) => nullToUndefined(data.insertOffboardingProject),
    complete: OffboardingProject.parse,
    catch: InsertUpdateOffboardingProjectErrorHandler
  });

  function fireWith(formData: OffboardingProjectFormData): Promise<OffboardingProject> {
    return fire({
      variables: { organizationId, formData: OffboardingProjectFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
