import * as React from "react";
import { useCache } from "./useCache";

export function useCachedMemo<T>(factory: () => T, deps: React.DependencyList, cacheKeys: string[]): T {
  const [currentDeps, setDeps] = useCache(
    () => deps,
    cacheKeys.length !== 0 ? cacheKeys.concat(["Deps"]) : []
  );
  const [currentValue, setValue] = useCache<T>(
    () => factory(),
    cacheKeys.length !== 0 ? cacheKeys.concat(["Value"]) : []
  );

  const value: T =
    currentDeps.length === deps.length && currentDeps.every((item, index) => Object.is(item, deps[index]))
      ? currentValue
      : factory();

  React.useEffect(
    () => {
      setDeps(deps);
      setValue(value);
    },
    deps
  );

  return value;
}
