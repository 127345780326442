import { RoutesContext } from "./routesContext";
import { ProgramsRoutes } from "./programsRoutes";
import { ThemesRoutes } from "./themesRoutes";
import { ConfigurationsRoutes } from "./configurationsRoutes";
import { OrganizationMigrationsRoutes } from "./organizationMigrationsRoutes";
import { OrganizationPortalUsersRoutes } from "./organizationPortalUsersRoutes";
import { AccessListsRoutes } from "./accessListsRoutes";
import { ReferralCodesRoutes } from "./referralCodesRoutes";
import { AutomatedOffboardingRoutes } from "./automatedOffboardingRoutes";

namespace RouteParams {
  export const organizationAlias: string = "organizationIdOrAlias";
}

export class OrganizationPortalRoutes {
  constructor(private readonly context: RoutesContext) {}

  public params(): OrganizationPortalRoutes.Params {
    return { organizationIdOrAlias: this.context.routeParams[RouteParams.organizationAlias] || "" };
  }

  public organizationHomePath(organizationIdOrAlias: number | string): string {
    return this.context.resolvePath(this.organizationPath(organizationIdOrAlias));
  }

  public accessLists(organizationIdOrAlias: number | string): AccessListsRoutes {
    return new AccessListsRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.accessListsRoot
    );
  }

  public automatedOffboarding(organizationIdOrAlias: number | string): AutomatedOffboardingRoutes {
    return new AutomatedOffboardingRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.automatedOffboardingRoot
    );
  }

  public configurations(organizationIdOrAlias: number | string): ConfigurationsRoutes {
    return new ConfigurationsRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.configurationsRoot
    );
  }

  public dashboardPath(organizationIdOrAlias: number | string): string {
    return this.context.resolvePath(
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.dashboardRoot
    );
  }

  public migrations(organizationIdOrAlias: number | string): OrganizationMigrationsRoutes {
    return new OrganizationMigrationsRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.migrationsRoot
    );
  }

  public programs(organizationIdOrAlias: number | string): ProgramsRoutes {
    return new ProgramsRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.programsRoot
    );
  }

  public referAndEarnPath(organizationIdOrAlias: number | string): string {
    return this.context.resolvePath(
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.referAndEarnRoot
    );
  }

  public referralCodes(organizationIdOrAlias: number | string): ReferralCodesRoutes {
    return new ReferralCodesRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.referralCodesRoot
    );
  }

  public reportsPath(organizationIdOrAlias: number | string): string {
    return this.context.resolvePath(
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.reportsRoot
    );
  }

  public settingsPath(organizationIdOrAlias: number | string): string {
    return this.context.resolvePath(
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.settingsRoot
    );
  }

  public themes(organizationIdOrAlias: number | string): ThemesRoutes {
    return new ThemesRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.themesRoot
    );
  }

  public users(organizationIdOrAlias: number | string): OrganizationPortalUsersRoutes {
    return new OrganizationPortalUsersRoutes(
      this.context,
      this.organizationPath(organizationIdOrAlias) + "/" + OrganizationPortalRoutes.usersRoot
    );
  }

  // Important: if the React Routes URL scheme changes, this method will have to be updated (note the "#" sign below).
  public signInRedirectUrl(redirectTo: string): string {
    return location.origin + OrganizationPortalRoutes.signInUrlPath +
      "?" + OrganizationPortalRoutes.signInUrlRedirectToParam + "=" + encodeURIComponent(redirectTo) +
      "#" + this.context.resolvePath(OrganizationPortalRoutes.signInRoot);
  }

  private organizationPath(organizationIdOrAlias: number | string): string {
    return OrganizationPortalRoutes.root + "/" + organizationIdOrAlias;
  }
}

export namespace OrganizationPortalRoutes {
  export const root = "edu";
  export const rootMask = root + "/:" + RouteParams.organizationAlias;

  export const accessListsRoot = "access-lists";
  export const accessListsMask = accessListsRoot + "/*";

  export const automatedOffboardingRoot = "offboarding";
  export const automatedOffboardingMask = automatedOffboardingRoot + "/*";

  export const configurationsRoot = "configurations";
  export const configurationsRootMask = configurationsRoot + "/*";

  export const dashboardRoot = "dashboard";
  export const dashboardMask = dashboardRoot;

  export const migrationsRoot = "migrations";
  export const migrationsRootMask = migrationsRoot + "/*";

  export const programsRoot = "programs";
  export const programsRootMask = programsRoot + "/*";

  export const referAndEarnRoot = "alumni-member-benefit";
  export const referAndEarnMask = referAndEarnRoot;

  export const referralCodesRoot = "referral-codes";
  export const referralCodesRootMask = referralCodesRoot + "/*";

  export const reportsRoot = "reports";
  export const reportsMask = reportsRoot;

  export const settingsRoot = "settings";
  export const settingsMask = settingsRoot;

  export const themesRoot = "themes";
  export const themesRootMask = themesRoot + "/*";

  export const usersRoot = "users";
  export const usersRootMask = usersRoot + "/*";

  export const signInRoot = root + "/sign-in";
  export const signInMask = signInRoot;
  export const signInUrlPath = "/edu-sign-in";
  export const signInUrlRedirectToParam = "redirect-to";

  export interface Params {
    organizationIdOrAlias: string;
  }
}
