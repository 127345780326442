import * as React from "react";
import { User } from "../../../types/models/user";
import { PanelRow } from "../../containers/rows/panelRow";
import { Indicator, Indicators, IndicatorValue } from "../../widgets/indicator";
import { Panel } from "../../containers/panel";
import { WhenElevated } from "../../utils/whenElevated";
import { Characters } from "../../characters";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../app/routes/useRoutes";

interface UserInfoPanelProps {
  user: User;
  headerContent?: React.ReactNode;
  displayAdminTools: boolean;
}

export const UserInfoPanel: React.FunctionComponent<UserInfoPanelProps> = (props) => {
  const routes = useRoutes();

  return (
    <Panel>
      {props.headerContent && <PanelRow size={"smaller"}>{props.headerContent}</PanelRow>}
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"Name"}>
            <IndicatorValue value={props.user.personalInfo.fullName || "--"}/>
          </Indicator>
          <Indicator title={"Profile Type"}>
            <IndicatorValue value={props.user.type}/>
          </Indicator>
          <Indicator title={"Internal ID"}>
            <IndicatorValue value={props.user.id}/>
          </Indicator>
          <Indicator title={"Status"}>
            <IndicatorValue value={props.user.enabled ? "Enabled" : "Disabled"}/>
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"Email Address"}>
            <IndicatorValue value={props.user.personalInfo.emailAddress || "--"}/>
          </Indicator>
          <Indicator title={"Phone Number"}>
            <IndicatorValue value={props.user.personalInfo.phoneNumber || "--"}/>
          </Indicator>
          <Indicator title={"PayPal Email Address"}>
            <IndicatorValue value={props.user.personalInfo.payPalEmailAddress || "--"}/>
          </Indicator>
          <Indicator title={"Time Zone"}>
            <IndicatorValue value={props.user.personalInfo.timeZone || "--"}/>
          </Indicator>
          <Indicator title={"EDU Ambassador Code"}>
            <IndicatorValue
              value={props.user.ambassadorsProgramMembership?.code || "--"}
              parenthetical={
                props.user.ambassadorsProgramMembership && (
                  <Link to={routes.ambassadors.otherAmbassadorDashboardPath(props.user.id)}>
                    Open EDU Ambassador Dashboard
                  </Link>
                )
              }
            />
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicator title={"Customer Details (Added To Invoices)"}>
          <IndicatorValue
            value={
              (props.user.customerDetails || "--")
                .split("\n")
                .map((line, index) => <React.Fragment key={index}>{line}<br/></React.Fragment>)
            }
          />
        </Indicator>
      </PanelRow>
      {props.displayAdminTools && (
        <WhenElevated>
          <PanelRow>
            <a href={props.user.mouseFlowRecordingsUrl} target={"_blank"}>
              MouseFlow recordings for this user
            </a>
            {" "}
            {Characters.NewTab}
          </PanelRow>
        </WhenElevated>
      )}
    </Panel>
  );
};
