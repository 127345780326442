import * as React from "react";
import { useWatcher, useWatcherDispatch } from "../services/watcher/useWatcher";
import { WatchedMigrations } from "../services/watcher/plugins/watchedMigrationsPlugin";
import { MonitoringDashboardPageView } from "../views/screens/monitoringDashboardPageView/monitoringDashboardPageView";
import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { Migration } from "../types/models/migration";
import { List } from "immutable";
import { useRefreshingDataSource } from "../views/utils/refreshingDataSource";
import {
  MonitoringDashboardPageViewDefs
} from "../views/screens/monitoringDashboardPageView/monitoringDashboardPageViewDefs";

function useMonitoringDashboardData(includeCompleted: number) {
  return useManagedQuery({
    query: GraphQL.useMonitoringDashboardDataQuery,
    deps: null,
    prepare: () => ({ includeCompleted }),
    extract: (data: GraphQL.MonitoringDashboardDataQuery) =>
      data.monitoredMigrations && data.recentMigrationsActivity
        ? { monitoredMigrations: data.monitoredMigrations, recentMigrationsActivity: data.recentMigrationsActivity }
        : undefined,
    complete: ({ monitoredMigrations, recentMigrationsActivity }) => {
      const migrations = List(
        monitoredMigrations.map((data) => {
          const migration = Migration.parseCore(data);
          return {
            ...migration,
            ...Migration.HasConnections.parse(data),
            ...Migration.HasMonitoringDashboardData.parse(migration, data)
          };
        })
      );

      const monitoringGroups = migrations
        .groupBy((migration) => migration.monitoringGroup)
        .map((groupMigrations) =>
          groupMigrations.sortBy((migration) =>
            -(migration.completedAt || migration.startedOrScheduledAt)
          )
        );

      const result: MonitoringDashboardPageViewDefs.MonitoringDashboardData & { migrationsIds: List<string> } = {
        migrationsIds: migrations.map((migration) => migration.id),

        completedWithIssues: monitoringGroups.get(Migration.MonitoringGroup.CompletedWithIssues, List()).toList(),

        requireManualReview: monitoringGroups.get(Migration.MonitoringGroup.RequireManualReview, List()).toList(),
        runningWithIssues: monitoringGroups.get(Migration.MonitoringGroup.RunningWithIssues, List()).toList(),
        delayed: monitoringGroups.get(Migration.MonitoringGroup.Delayed, List()).toList(),
        waitingForUserAction: monitoringGroups.get(Migration.MonitoringGroup.WaitingForUserAction, List()).toList(),

        runningNormally: monitoringGroups.get(Migration.MonitoringGroup.RunningNormally, List()).toList(),

        completedNormally: monitoringGroups.get(Migration.MonitoringGroup.CompletedNormally, List())
          .toList()
          .take(includeCompleted),

        recentMigrationsActivity: List(
          recentMigrationsActivity.map(({ timestamp, count }): [Date, number] => [new Date(timestamp), count])
        )
      };

      return result;
    }
  });
}

export const MonitoringDashboardPage: React.FunctionComponent = () => {
  const watcherDispatch = useWatcherDispatch();
  const watcher = useWatcher();

  const [completedNormallyCount, setCompletedNormallyCount] = React.useState(0);

  const dataSource = useRefreshingDataSource(
    useMonitoringDashboardData(completedNormallyCount),
    { variableOverrides: { includeCompleted: completedNormallyCount } }
  );

  if (dataSource.status.isSuccess()) {
    // This action will REPLACE all watched migrations and disable auto-refresh
    watcherDispatch(WatchedMigrations.MonitorMigrationsAction(dataSource.status.result.migrationsIds));

    watcher.watchMigrationIssueLists(dataSource.status.result.migrationsIds, true, false);
    watcher.watchMigrationNoteLists(dataSource.status.result.migrationsIds, true, false);
  }

  return (
    <MonitoringDashboardPageView
      dataSource={dataSource}
      completedNormallyCount={completedNormallyCount}
      completedNormallyWindows={[20, 50, 200]}
      onCompletedNormallyCountChange={(count) => {
        setCompletedNormallyCount(count);
        dataSource.refreshWith({ includeCompleted: count });
      }}
    />
  );
};
