import * as React from "react";
import * as Color from "color";
import * as moment from "moment";
import { List, Range, ValueObject } from "immutable";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  TimeUnit,
  Tooltip
} from "chart.js";
// tslint:disable-next-line import-name
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-moment";
import { styled, useAppTheme } from "../../../app/theme";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { ActivityTimeUnit } from "../../../types/enums/activityTimeUnit";
import { commafy } from "../../../utils/formatting";

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  ChartDataLabels,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
);

const Container = styled.div`
  position: relative;
`;

function stringHashCode(s: string): number {
  if (s.length === 0) {
    return 0;
  } else {
    let hash = 0;
    for (let i = 0; i < s.length; i += 1) {
      // tslint:disable no-bitwise
      hash = ((hash << 5) - hash) + s.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
      // tslint:enable no-bitwise
    }
    return hash;
  }
}

export class Dataset implements ValueObject {
  constructor(public readonly title: string, public readonly values: List<number | null>) {}

  public equals(other: any): boolean {
    return this.title === other.title && this.values.equals(other.values);
  }

  public hashCode(): number {
    let hash = stringHashCode(this.title);
    // tslint:disable no-bitwise
    hash = ((hash << 5) - hash) + this.values.hashCode();
    hash |= 0; // Convert to 32bit integer
    // tslint:enable no-bitwise
    return hash;
  }
}

// function test() {
//   const a = new Dataset(
//     "A",
//     List<number>([123])
//   );
//   const b = new Dataset(
//     "A",
//     List<number>([123])
//   );
//   const c = new Dataset(
//     "B",
//     List<number>([123])
//   );
//   const d = new Dataset(
//     "A",
//     List<number>([124])
//   );
//   console.log(a === b, is(a, b));
//   console.log(a === c, is(a, c));
//   console.log(a === d, is(a, d));
// }

export enum ActivityChartType {
  Bar = "Bar",
  Area = "Area"
}

interface Props extends StyledComponentsProps {
  timestamps: List<moment.Moment>;
  datasets: List<Dataset>;
  timeUnit: ActivityTimeUnit;
  type?: ActivityChartType;
  displayLegend?: boolean;
  useLightColors?: boolean;
}

export const ActivityChart: React.FunctionComponent<Props> = (props) => {
  const theme = useAppTheme();

  const primary = Color(props.useLightColors ? theme.colors.lightPrimary : theme.colors.primary).hsl();

  const datasetColors = Range(0, 6)
    .map((index) => primary.hue((primary.hue() + (360 / 6 * index)) % 360).hex())
    .toList();

  const containerRef = React.useRef<HTMLCanvasElement>(null);
  const graph = React.useRef<Chart>();

  const prevTimestamps = React.useRef<List<moment.Moment>>(List());
  const prevDatasets = React.useRef<List<Dataset>>(List());

  function chartJsLabels() {
    return props.timestamps.toArray();
  }

  function chartJsDatasets() {
    return props.datasets
      .map((dataset, index) => {
        const color = datasetColors.get(index % datasetColors.size);
        return {
          label: dataset.title,
          data: dataset.values.toArray(),
          borderColor: color,
          backgroundColor: color,
          fill: "origin"
        };
      })
      .toArray();
  }

  function maxValue(): number {
    return props.timestamps
      .map((timestamp, index) =>
        props.datasets
          .map((dataset) => dataset.values.get(index) || 0)
          .reduce((a, b) => a + b, 0)
      )
      .max() || 0;
  }

  function yScaleMax(): number {
    return Math.max(1, Math.floor(maxValue() * 1.2) + 1);
  }

  function chartJsTimeUnit(): TimeUnit {
    switch (props.timeUnit) {
      case ActivityTimeUnit.Hour: return "hour";
      case ActivityTimeUnit.Day: return "day";
      case ActivityTimeUnit.Month: return "month";
      case ActivityTimeUnit.Year: return "year";
    }
  }

  function tooltipFormat(): string | undefined {
    switch (props.timeUnit) {
      case ActivityTimeUnit.Hour: return "ha";
      case ActivityTimeUnit.Day: return "MMM D, YYYY";
      case ActivityTimeUnit.Month: return "MMM YYYY";
      case ActivityTimeUnit.Year: return "YYYY";
    }
  }

  React.useEffect(
    () => {
      if (!graph.current && containerRef.current) {
        graph.current = new Chart(containerRef.current, {
          plugins: [ChartDataLabels],
          type: props.type === ActivityChartType.Area ? "line" : "bar",
          data: {
            labels: chartJsLabels(),
            datasets: chartJsDatasets()
          },
          options: {
            scales: {
              x: {
                type: "time",
                time: {
                  unit: chartJsTimeUnit(),
                  tooltipFormat: tooltipFormat(),
                  displayFormats: {
                    hour: "ha"
                  }
                },
                stacked: true,
                ticks: {
                  font: {
                    size: 10
                  },
                }
              },
              y: {
                min: 0,
                max: yScaleMax(),
                stacked: true,
                ticks: {
                  font: {
                    size: 10
                  }
                }
              }
            },
            aspectRatio: 4,
            maintainAspectRatio: false,
            backgroundColor: theme.colors.lightPrimary,
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false
            },
            plugins: {
              legend: {
                display: !!props.displayLegend,
                position: "bottom",
                align: "start"
              },
              datalabels: {
                anchor: "end",
                align: "top",
                offset: -3,
                font: {
                  size: 10
                },
                formatter: (value) => typeof value === "number" ? commafy(value) : value
              }
            }
          }
        });
      }
    },
    [containerRef.current]
  );

  React.useEffect(
    () => {
      // Note that Moment objects are automatically compared by value correctly
      if (
        graph.current && (
          !prevTimestamps.current.equals(props.timestamps) ||
          !prevDatasets.current.equals(props.datasets)
        )
      ) {
        prevTimestamps.current = props.timestamps;
        prevDatasets.current = props.datasets;

        graph.current.data.labels = chartJsLabels();
        graph.current.data.datasets = chartJsDatasets();
        if (graph.current.options.scales) {
          if (
            graph.current.options.scales.x &&
            graph.current.options.scales.x.type === "time" &&
            graph.current.options.scales.x.time &&
            props.timeUnit
          ) {
            graph.current.options.scales.x.time.unit = chartJsTimeUnit();
            graph.current.options.scales.x.time.tooltipFormat = tooltipFormat();
          }
          if (graph.current.options.scales.y) {
            graph.current.options.scales.y.max = yScaleMax();
          }
        }
        graph.current.update();
      }
    },
    [props.timestamps, props.datasets, props.timeUnit]
  );

  return (
    <Container className={props.className}>
      <canvas ref={containerRef}/>
    </Container>
  );
};
