import * as React from "react";
import { styled } from "../../app/theme";
import { Button, Chip } from "./button";

export enum SwitchLayout {
  Horizontal,
  Vertical,
}

export enum SwitchStyle {
  Buttons,
  Chips,
}

const Layout = styled.div<{ layout: SwitchLayout }>`
  display: flex;
  flex-direction: ${(props) => props.layout === SwitchLayout.Vertical ? "column" : "row"};
  flex-wrap: wrap;
  margin: -0.3rem -0.2rem;
  
  > * {
    flex-grow: ${(props) => props.layout === SwitchLayout.Vertical ? 1 : 0};

    > * {
      width: ${(props) => props.layout === SwitchLayout.Vertical ? "100%" : "auto"};
    }
  }
`;

const SwitchButtonContainer = styled.div`
  display: inline-block;
  margin: 0.3rem 0.2rem;
`;

interface SwitchButtonProps {
  value: any;
  style: SwitchStyle;
  selected?: any;
  onSelect: (value: any) => void;
  onClear?: () => void;
}

const SwitchButton: React.FunctionComponent<SwitchButtonProps> = (props) => {
  function handleClick() {
    if (props.value === props.selected) {
      if (props.onClear) {
        props.onClear();
      }
    } else {
      props.onSelect(props.value);
    }
  }

  return (
    <SwitchButtonContainer>
      {
        props.style === SwitchStyle.Buttons
          ? (
            <Button
              color={props.value === props.selected ? "blue" : "white"}
              size="compact"
              onClick={handleClick}
            >
              {props.children}
            </Button>
          )
          : (
            <Chip
              color={props.value === props.selected ? "white" : "gray"}
              size="compact"
              onClick={handleClick}
            >
              {props.children}
            </Chip>
          )
      }
    </SwitchButtonContainer>
  );
};

export interface SwitchItem {
  value: any;
  content: React.ReactNode;
}

interface SwitchProps {
  layout?: SwitchLayout;
  style?: SwitchStyle;
  items: SwitchItem[];
  selected?: any;
  onSelect: (value: any) => void;
  onClear?: () => void;
}

export const Switch: React.FunctionComponent<SwitchProps> = (props) => (
  <Layout layout={props.layout || SwitchLayout.Horizontal}>
    {
      props.items.map((item) => (
        <SwitchButton
          key={item.value}
          value={item.value}
          style={props.style === undefined ? SwitchStyle.Buttons : props.style}
          selected={props.selected}
          onSelect={props.onSelect}
          onClear={props.onClear}
        >
          {item.content}
        </SwitchButton>
      ))
    }
  </Layout>
);
