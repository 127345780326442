import * as React from "react";
import { DataGroup, DataItem, DataSet, DateType, IdType, Timeline } from "vis";
import { styled } from "../../../app/theme";
import { Map, OrderedMap, Set } from "immutable";
import { usePrevious } from "../../../utils/usePrevious";

function compareDates(a: DateType | undefined, b: DateType | undefined): boolean {
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  } else {
    return a === b;
  }
}

function compareNestedGroups(a: IdType[] | undefined, b: IdType[] | undefined): boolean {
  if (a && b) {
    return Set(a).equals(Set(b));
  } else {
    return a === b;
  }
}

function compareDataItems(a: DataItem, b: DataItem): boolean {
  return (
    a.className === b.className &&
    a.content === b.content &&
    compareDates(a.end, b.end) &&
    a.group === b.group &&
    a.id === b.id &&
    compareDates(a.start, b.start) &&
    a.style === b.style &&
    a.subgroup === b.subgroup &&
    a.title === b.title &&
    a.type === b.type &&
    a.editable === b.editable
  );
}

function compareDataGroups(a: DataGroup, b: DataGroup): boolean {
  return (
    a.className === b.className &&
    a.content === b.content &&
    a.id === b.id &&
    // options?: DataGroupOptions;
    a.style === b.style &&
    a.subgroupOrder === b.subgroupOrder &&
    a.title === b.title &&
    compareNestedGroups(a.nestedGroups, b.nestedGroups) &&
    a.subgroupStack === b.subgroupStack &&
    a.visible === b.visible &&
    a.showNested === b.showNested
  );
}

const Container = styled.div`
  font-size: 1rem;
  line-height: 110%;
  background: ${(props) => props.theme.colors.white};

  span.updated-by {
    font-size: 80%;
  }
  
  .vis-timeline {
    border: none;
  }
  
  .vis-label.vis-nesting-group.expanded:before {
    content: none;
  }
  
  .vis-label {
    &.job-statuses {
      background: ${(props) => props.theme.colors.faintPrimary};
    }

    &.tasks {
      background: #fbfbd7;
      
      &.vis-nesting-group.expanded {
        cursor: default;
      }
    }

    &.task-issues {
      background: ${(props) => props.theme.colors.subtleRed};
      padding-left: 1.5rem;
    }
  }
  
  .vis-item.vis-range {
    background-color: #f0f0f0;
    background-image: repeating-linear-gradient(
      -45deg, 
      rgba(255,255,255,.4), 
      rgba(255,255,255,.4) .5rem, 
      rgba(255,255,255,.8) .5rem, 
      rgba(255,255,255,.8) 1rem
    );
    border: none;
    
    .progress {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .content {
      position: relative;
      z-index: 1;
    }
      
    &.job-status.completed {
      &, .progress {
        background-color: ${(props) => props.theme.colors.faintPrimary};      
      }
    }

    &.job-status.active {
      &, .progress {
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
      }
    }

    &.job-status.inactive {
      &, .progress {
        background-color: #f0f0f0;
        color: #a0a0a0;
      }
    }

    &.task.completed {
      &, .progress {
        background-color: ${(props) => props.theme.colors.lightYellow};
      }
    }

    &.task.active {
      &, .progress {
        background-color: ${(props) => props.theme.colors.yellow};
        color: white;
      }
    }

    &.issue {
      font-size: 0.75rem;
      line-height: 115%;

      &.completed {
        &, .progress {
          background-color: ${(props) => props.theme.colors.lightRed};
        }
      }
  
      &.active {
        &, .progress {
          background-color: ${(props) => props.theme.colors.red};
          color: white;
        }
      }
    }

    &.event {
      color: white;
      font-size: 0.75rem;
      line-height: 115%;

      &.started {
        background: #3f985c;
        border-color: #3f985c;
      }

      &.stopped {
        background: #b1527a;
        border-color: #b1527a;
      }

      &.other {
        background: #888888;
        border-color: #888888;
      }
    }
  }
  
  table.vis-timeline-tooltip {
    > tbody > tr > td:first-of-type {
      padding-right: .75rem;
      text-align: right;
      color: #888888;
    }
  }
`;

function updateDataSet<T>(
  dataSet: DataSet<T>,
  prev: Map<IdType, T>,
  next: Map<IdType, T>,
  compare: (a: T, b: T) => boolean): Map<IdType, T> {
  const prevIds = prev.keySeq().toSet();
  const nextIds = next.keySeq().toSet();

  const addedItems = next.deleteAll(prevIds).valueSeq();

  const updatedItems = next
    .filter((nextItem, id) => {
      const prevItem = prev.get(id);
      return prevItem !== undefined && !compare(prevItem, nextItem);
    })
    .valueSeq();

  dataSet.update(addedItems.concat(updatedItems).toArray());
  dataSet.remove(prevIds.subtract(nextIds).toArray());

  return next;
}

interface Props {
  subject: string;
  follow: number | undefined;
  showTooltips: boolean;

  groups: OrderedMap<IdType, DataGroup>;
  items: Map<IdType, DataItem>;

  fitCallbackRef: React.MutableRefObject<(() => void) | undefined>;
  onRangeChange: () => void;
}

export const TimelineView: React.FunctionComponent<Props> = (props) => {
  const visContainerRef = React.useRef<HTMLDivElement>(null);

  const itemsDataSet = React.useRef<DataSet<DataItem>>(new DataSet());
  const groupsDataSet = React.useRef<DataSet<DataGroup>>(new DataSet());

  const timeline = React.useRef<Timeline>();

  const updatedWindow = React.useRef(false);

  const prevItems = React.useRef<Map<IdType, DataItem>>(Map());
  const prevGroups = React.useRef<OrderedMap<IdType, DataGroup>>(OrderedMap());

  const prevSubject = usePrevious(props.subject);

  React.useEffect(
    () => {
      if (!timeline.current && visContainerRef.current) {
        timeline.current = new Timeline(
          visContainerRef.current,
          itemsDataSet.current,
          groupsDataSet.current,
          {
            showTooltips: false,
            groupOrder: (a, b)  => a.id < b.id ? -1 : a.id > b.id ? 1 : 0,
            template: (item) => {
              return (
                `<div class=\"progress\" style=\"width:${item.progress !== undefined ? item.progress : 100}%\"></div>
                 <label class=\"content\">${item.content}</label>`
              );
            }
          }
        );

        props.fitCallbackRef.current = () => timeline.current && timeline.current.fit();

        timeline.current.on(
          "rangechange",
          () => {
            if (!updatedWindow.current) {
              props.onRangeChange();
            }
            updatedWindow.current = false;
          }
        );
      }
    },
    [visContainerRef.current]
  );

  function updateDataSets() {
    prevItems.current = updateDataSet(itemsDataSet.current, prevItems.current, props.items, compareDataItems);
    prevGroups.current = updateDataSet(groupsDataSet.current, prevGroups.current, props.groups, compareDataGroups);
  }

  updateDataSets();

  React.useEffect(updateDataSets, [timeline.current]);

  const minStart = props.items.map((item) => item.start).min();
  const maxEnd = props.items.map((item) => item.end || item.start).max();

  const minTime = minStart !== undefined ? new Date(minStart).getTime() : undefined;
  const maxTime = maxEnd !== undefined ? new Date(maxEnd).getTime() : undefined;

  React.useEffect(
    () => {
      if (timeline.current) {
        if (minTime && maxTime) {
          const headroom = Math.max(10000, (maxTime - minTime) * 0.02);
          timeline.current.setOptions({ min: minTime - headroom, max: maxTime + headroom });
        } else {
          timeline.current.setOptions({ min: undefined, max: undefined });
        }
      }
    },
    [minTime, maxTime, timeline.current]
  );

  React.useEffect(
    () => {
      if (timeline.current && prevSubject !== props.subject) {
        timeline.current.fit();
      }
    },
    [props.subject, timeline.current]
  );

  React.useEffect(
    () => {
      function updateWindow() {
        if (timeline.current && props.follow) {
          const end = new Date().getTime() + props.follow * 0.05;
          updatedWindow.current = true;
          timeline.current.setWindow(end - props.follow, end, { animation: false });
        }
      }

      if (props.follow) {
        updateWindow();
        const timer = setInterval(updateWindow, 1000);
        return () => clearInterval(timer);
      }
    },
    [props.follow]
  );

  React.useEffect(
    () => {
      if (timeline.current) {
        timeline.current.setOptions({ showTooltips: props.showTooltips });
      }
    },
    [props.showTooltips, timeline.current]
  );

  return (
    <Container ref={visContainerRef}/>
  );
};
