import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { State } from "../state";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { MigrationStream } from "../migrationStream";

export class MigrationFlowsHub extends DataSource<MigrationFlowsHub.Props, MigrationStream, MigrationStream> {
  public calcState(context: BlueprintContext): State<MigrationStream> {
    return State.resolved(
      MigrationStream.reduce(
        this.dataFlows().flatMap((dataFlow) => dataFlow.state(context).output.toList())
      )
    );
  }

  public proposedRelationshipTitle(): string {
    return this.props.proposedRelationshipTitle || "streams from";
  }
}

export namespace MigrationFlowsHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.MigrationFlowsHubFragment,
    settings?: ComponentHubSettings
  ): MigrationFlowsHub {
    return new MigrationFlowsHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
