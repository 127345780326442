import * as React from "react";
import { Step } from "../../views/containers/step";
import { SimplePanel } from "../../views/containers/simplePanel";

interface SandboxStep0Props {
  onComplete: (result: string) => void;
}

export const SandboxStep0: React.FunctionComponent<SandboxStep0Props> = (props) => (
  <Step
    title="Step 1"
    onSubmit={() => props.onComplete("Hi!")}
    noForm={true}
  >
    <SimplePanel>
      This is just a dummy step that makes traversals a little longer
    </SimplePanel>
  </Step>
);
