import { GraphQL } from "../../services/graphql/generated";
import { mapOptional } from "../../utils/misc";
import { Facts } from "../facts/facts";
import { MigrationStats } from "./migrationStats";
import { IterationStatus } from "./iterationStatus";
import { Migration } from "./migration";

export interface Iteration {
  readonly migrationId: string;
  readonly index: number;

  readonly status: IterationStatus;
  readonly stats: MigrationStats;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly startedAt: Date | undefined;
  readonly completedAt: Date | undefined;

  readonly completedIn: number | undefined;
}

export namespace Iteration {
  export function parseCore(iteration: GraphQL.IterationFragment): Iteration {
    const startedAt = mapOptional(iteration.startedAt, (value) => new Date(value));
    const completedAt = mapOptional(iteration.completedAt, (value) => new Date(value));

    return {
      migrationId: iteration.migrationId,
      index: iteration.index,

      status: iteration.status,
      // In this context, completion affects calculation of progress. We don't care of the iteration was completed
      // in the past, we only care about the current status.
      stats: MigrationStats.parse(iteration.stats, iteration.status === GraphQL.IterationStatus.Completed),

      createdAt: new Date(iteration.createdAt),
      updatedAt: new Date(iteration.updatedAt),
      startedAt,
      completedAt,

      completedIn: startedAt && completedAt ? (completedAt.getTime() - startedAt.getTime()) / 1000 : undefined
    };
  }

  export interface HasProgressData {
    facts: Facts;
  }

  export namespace HasProgressData {
    export function parse(data: Pick<GraphQL.Iteration, "facts">): HasProgressData {
      return {
        facts: Facts.fromGraphQLList(data.facts)
      };
    }
  }

  export function fromMigration(migration: Migration): Iteration {
    return {
      migrationId: migration.id,
      index: migration.iteration,

      status: IterationStatus.fromMigrationStatus(migration.status),
      stats: migration.iterationStats,

      createdAt: new Date(),
      updatedAt: new Date(),
      startedAt: migration.iterationStartedAt,
      completedAt: migration.iterationCompletedAt,

      completedIn: undefined
    };
  }
}
