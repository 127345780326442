import * as React from "react";
import { List } from "immutable";
import { ClickableAnchor } from "../../../wizardry/anchor";
import { WorkflowNavigation, WorkflowNavigationItem } from "../../layouts/workflowNavigation";

interface WorkflowNavigationAnchorsProps {
  anchors: List<ClickableAnchor>;
}

export const WorkflowNavigationAnchors: React.FunctionComponent<WorkflowNavigationAnchorsProps> = (props) => (
  <WorkflowNavigation>
    {
      props.anchors.map((anchor, index) => (
        <WorkflowNavigationItem
          key={anchor.path}
          enabled={anchor.unlocked}
          selected={anchor.selected}
          started={anchor.started}
          complete={anchor.complete}
          progress={anchor.progress}
          error={anchor.errorIndicator}
          number={anchor.number}
          title={anchor.title}
          description={anchor.description}
          nextSelected={props.anchors.get(index + 1, { selected: false }).selected}
          onClick={anchor.onClick}
        />
      ))
    }
  </WorkflowNavigation>
);
