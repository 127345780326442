import * as React from "react";
import {
  IncrementSponsorshipLimitAmountRemainingToolControllerProps
} from "../../../views/screens/organizationPortal/dashboard/organizationDashboardView";
import {
  useIncrementSponsorshipLimitAmountRemainingMutation
} from "../../../queries/organizations/useIncrementSponsorshipLimitAmountRemainingMutation";

interface Props extends IncrementSponsorshipLimitAmountRemainingToolControllerProps {
  organizationId: number;
}

export const ToolController: React.FunctionComponent<Props> = (props) => {
  const [submit, status] = useIncrementSponsorshipLimitAmountRemainingMutation(props.organizationId);

  return props.render({
    status,
    onSubmit: submit
  });
};

export function useIncrementSponsorshipLimitAmountRemainingToolController(organizationId: number) {
  return React.useCallback(
    (controllerProps: IncrementSponsorshipLimitAmountRemainingToolControllerProps) =>
      <ToolController {...controllerProps} organizationId={organizationId}/>,
    [organizationId]
  );
}
