import { Map } from "immutable";
import { BlueprintInputs } from "./blueprintInputs";
import { State } from "./state";
import { GraphQL } from "../services/graphql/generated";
import { StateParser } from "./stateParser";

export class ServerElements {
  public constructor(
    public readonly blueprintId: string,
    public readonly inputs: BlueprintInputs,
    public readonly components: Map<string, ServerElements.ComponentState>
  ) {
  }

  public equals(other: ServerElements): boolean {
    return (
      this.blueprintId === other.blueprintId &&
      this.inputs.equals(other.inputs) &&
      this.components.equals(other.components)
    );
  }

  public getComponentState(componentId: string): State<any> | undefined {
    const componentState = this.components.get(componentId);
    return componentState && componentState.state;
  }

  public getHubState(componentId: string, hubName: string): State<any> | undefined {
    const componentState = this.components.get(componentId);
    if (componentState) {
      const hubState = componentState.hubs.get(hubName);
      return hubState && hubState.state;
    }
  }

  public getRelationshipState(componentId: string, hubName: string, targetComponentId: string): State<any> | undefined {
    const componentState = this.components.get(componentId);
    if (componentState) {
      const hubState = componentState.hubs.get(hubName);
      if (hubState) {
        const relationshipState = hubState.relationships.get(targetComponentId);
        return relationshipState && relationshipState.state;
      }
    }
  }
}

export namespace ServerElements {
  export const Empty = new ServerElements("", BlueprintInputs.Empty, Map());

  export function fromGraphQL(
    blueprintId: string,
    inputs: BlueprintInputs,
    result: GraphQL.GetRenderedBlueprintQuery
  ): ServerElements {
    return new ServerElements(
      blueprintId,
      inputs,
      Map(result.getRenderedBlueprint.components.map(ComponentState.componentStateFromGraphQL))
    );
  }

  export interface RelationshipState {
    componentId: string;
    state: State<any>;
  }

  export namespace RelationshipState {
    export function relationshipStateFromGraphQL(
      state: GraphQL.RenderedBlueprintRelationshipFragment
    ): [string, RelationshipState] {
      return [
        state.componentId,
        {
          componentId: state.componentId,
          state: StateParser.fromGraphQL(state.state)
        }
      ];
    }
  }

  export interface HubState {
    name: string;
    state: State<any>;
    relationships: Map<string, RelationshipState>;
  }

  export namespace HubState {
    export function hubStateFromGraphQL(state: GraphQL.RenderedBlueprintHubFragment): [string, HubState] {
      return [
        state.name,
        {
          name: state.name,
          state: StateParser.fromGraphQL(state.state),
          relationships: Map(state.relationships.map(RelationshipState.relationshipStateFromGraphQL))
        }
      ];
    }
  }

  export interface ComponentState {
    id: string;
    state: State<any>;
    hubs: Map<string, HubState>;
  }

  export namespace ComponentState {
    export function componentStateFromGraphQL(
      state: GraphQL.RenderedBlueprintComponentFragment
    ): [string, ComponentState] {
      return [
        state.id,
        {
          id: state.id,
          state: StateParser.fromGraphQL(state.state),
          hubs: Map(state.hubs.map(HubState.hubStateFromGraphQL))
        }
      ];
    }
  }
}
