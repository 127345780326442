import * as React from "react";
import { SchoolSummary } from "../../../../types/models/school";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { Step } from "../../../containers/step";
import { Button, MultiLineButtonTitle } from "../../../widgets/button";
import { useSession } from "../../../../utils/useAppState";
import { Connection } from "../../../../types/models/connection";
import { User } from "../../../../types/models/user";
import { AmbassadorLinksPanel } from "../../../blocks/ambassadorLinksPanel";

interface Props {
  school: SchoolSummary;
  sourceConnection: Connection;
  destinationConnection: Connection;
  ambassadorsProgramMembership: User.AmbassadorsProgramMembership;

  numberOfFriendsHelped: number | undefined;

  onProceed: () => void;
  onNavigateBack?: () => void;
}

export const ReturningAmbassadorStepView: React.FunctionComponent<Props> = (props) => {
  const session = useSession();
  const appBootstrapConfig = useAppBootstrapConfig();

  const signUpDiscount = appBootstrapConfig.ambassadorsProgram.signUpDiscount;
  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  const discount = session?.user.ambassadorsProgramMembership?.signUpDiscountMigrationId
    ? codeDiscount + "%"
    : "$" + signUpDiscount;

  const domain = props.sourceConnection.personalInfo.titleizedDomain;

  return (
    <Step
      breadcrumbs={"EDU Ambassador Offer"}
      title={
        props.numberOfFriendsHelped
          ? "Congrats" + (session?.user.personalInfo.firstName ? ", " + session?.user.personalInfo.firstName : "") + "!"
          : "Welcome back, " + (props.school.commonName ? props.school.commonName : "EDU ") + " Ambassador!"
      }
      subTitle={
        props.numberOfFriendsHelped
          ? `You just helped ${props.numberOfFriendsHelped} friends save their @${domain} content ` +
          `before it gets deleted! Plus, you've earned ${discount} off your migration and ` +
          `${props.numberOfFriendsHelped} entries into our monthly iPad giveaway!`
          : `You've earned ${discount} off your migration!`
      }
      noForm={true}
      submitWidget={
        <Button
          size={"narrow"}
          onClick={() => props.onProceed()}
        >
          <MultiLineButtonTitle>
            <div>Proceed to Checkout&nbsp;</div>
            <div>(with {discount} Off!) &nbsp;&rsaquo;</div>
          </MultiLineButtonTitle>

        </Button>
      }
      onNavigateBack={props.onNavigateBack}
    >
      <AmbassadorLinksPanel
        ambassadorCode={props.ambassadorsProgramMembership.code}
        school={props.school}
        destinationConnection={props.destinationConnection}
        numberOfFriendsHelped={props.numberOfFriendsHelped}
        showDashboardLink={true}
      />
    </Step>
  );
};
