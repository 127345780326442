import * as React from "react";
import { ConnectionPanelRow, ConnectionPanelRowDefs } from "../../connectionPanelRow";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { AuthContext } from "../../authContext";
import { FieldProps } from "formik";
import { IncrementalSignIn, IncrementalSignInDefs } from "../../incrementalSignIn";
import { SignInLayout } from "../../auth/signInLayout";
import { sentence } from "../../../../utils/misc";
import { WorkStatus } from "../../../models/workStatus";
import { friendlySize } from "../../../../utils/formatting";
import { prepareActionItems } from "../prepareActionItems";
import { AreaStatus } from "../../../models/areaStatus";
import { CloudServices } from "../../../../types/models/cloudServices";
import { useDrawer } from "../../../layouts/drawer";
import { InfoLink } from "../../../widgets/infoLink";
import { ActionItems } from "../../../models/actionItem";

function renderScanStatus(role: AuthContext, status: WorkStatus, hasIssues: boolean): string {
  const scanOrTest = AuthContext.scanOrTest(role);
  switch (status) {
    case WorkStatus.Pending:
      return scanOrTest + " pending...";
    case WorkStatus.Working:
      return scanOrTest + " in progress...";
    case WorkStatus.Issue:
      return hasIssues ? "" : scanOrTest + " is paused...";
    case WorkStatus.Failure:
      return scanOrTest + " failed.";
    case WorkStatus.Success:
      return scanOrTest + " complete.";
  }
}

function renderAvailableStorage(
  availableStorage: ConnectionPanelDefs.AvailableStorageInfo.Any | undefined): React.ReactNode {
  if (availableStorage) {
    const storageType = ConnectionPanelDefs.AvailableStorageInfo.getStorageType(availableStorage);

    switch (availableStorage.type) {
      case ConnectionPanelDefs.AvailableStorageInfo.Type.Collecting:
        return `Reading available ${storageType}...`;

      case ConnectionPanelDefs.AvailableStorageInfo.Type.Collected:
        if (availableStorage.available === -1) {
          return `Unlimited ${storageType} available.`;
        } else {
          return `${friendlySize(availableStorage.available)} available ${storageType}.`;
        }

      case ConnectionPanelDefs.AvailableStorageInfo.Type.UnableToCollect:
        return (
          <>
            Unable to read available {storageType}.&nbsp;
            {
              availableStorage.helpArticle &&
              <InfoLink helpArticle={availableStorage.helpArticle}>Learn more</InfoLink>
            }
          </>
        );
    }
  }
  return null;
}

function renderSummary(area: ConnectionPanelDefs.Area, showScanProgress: boolean): React.ReactNode {
  const breakdown = area.items && (area.items.verboseSummary() + (showScanProgress ? " found" : ""));
  const size = area.size !== undefined ? friendlySize(area.size) : undefined;
  const availableStorage = renderAvailableStorage(area.availableStorage);
  const willWork = showScanProgress && status === WorkStatus.Success
    ? sentence(area.mainSubject) + " migration will work."
    : undefined;

  if (breakdown) {
    return (
      <React.Fragment>
        {breakdown}{size && " (" + size + ")"} {availableStorage}
      </React.Fragment>
    );
  } else if (size) {
    return (
      <React.Fragment>
        {size}. {availableStorage}
      </React.Fragment>
    );
  } else if (availableStorage) {
    return (
      <React.Fragment>
        {willWork} {availableStorage}
      </React.Fragment>
    );
  } else {
    return willWork;
  }
}

interface AreaRowProps {
  role: AuthContext;
  cloudServices: CloudServices;
  selectedCloudServiceId: string;
  connectionId: string;
  area: ConnectionPanelDefs.Area;
  field: FieldProps | undefined;
  showProgress: boolean;
  processDescription: string | undefined;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const AreaRow: React.FunctionComponent<AreaRowProps> = (props) => {
  const drawer = useDrawer();

  const disabled = !!props.area.incrementalSignIn || !!props.area.disabled;

  function renderTitle() {
    if (props.role === AuthContext.Source) {
      return props.processDescription && !disabled
        ? "All " + props.area.description + " " + props.processDescription
        : sentence(props.area.description);
    } else {
      return props.area.appTitle + " (" + props.area.description + ")";
    }
  }

  function renderContent(): ConnectionPanelRowDefs.Content {
    if (props.area.incrementalSignIn) {
      return { actionItems: IncrementalSignInDefs.Settings.toIssues(props.area.incrementalSignIn, handleSignInClick) };
    } else if (props.area.disabled) {
      return { actionItems: AreaStatus.Disabled.toIssues(props.area.disabled, props.area) };
    } else {
      const issues = prepareActionItems({
        subjectId: props.area.areaId,
        affectedSubject: props.area.mainSubject,
        ...props.area
      });
      const scanStatus = props.showProgress
        ? renderScanStatus(props.role, props.area.status, !issues.isEmpty()) + " "
        : "";
      const summary = renderSummary(props.area, props.showProgress) || "";
      return {
        summary: (scanStatus || summary) && <React.Fragment>{scanStatus}{summary}</React.Fragment>,
        actionItems: issues
      };
    }
  }

  function handleSignInClick(incrementalSignIn: IncrementalSignInDefs.Settings) {
    drawer.open(
      <IncrementalSignIn
        incrementalSignIn={incrementalSignIn}
        layoutComponent={SignInLayout}
        onSignIn={() => drawer.close()}
      />
    );
  }

  return (
    <ConnectionPanelRow
      icon={props.area.icon}
      title={renderTitle()}
      content={renderContent()}
      helpArticle={props.area.helpArticle}
      status={props.area.status}
      disabled={disabled}
      strokeOut={!!props.area.disabled}
      checkBox={props.field}
      progressIndicator={props.showProgress ? "normal" : "hidden"}
      actionItemSuppressing={props.actionItemSuppressing}
    />
  );
};
