import { RevenueShareSourceType } from "../enums/revenueShareSourceType";
import { GraphQL } from "../../services/graphql/generated";

export interface RevenueShare {
  readonly sourceType: RevenueShareSourceType;
  readonly sourceId: string;
  readonly percentage: number;
  readonly amount: number;
}

export namespace RevenueShare {
  export function parseRevenueShare(data: GraphQL.RevenueShareFragment): RevenueShare {
    return data;
  }
}
