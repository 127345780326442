import * as React from "react";
import { EmbeddedContent } from "../views/utils/embeddedContent";
import { buildQueryString, nullToUndefined, withoutUndefined } from "./misc";
import { RemoteContent } from "../views/utils/remoteContent";
import { Map } from "immutable";
import { ExternalHelpArticle } from "../types/models/externalHelpArticle";
import { RoutesHook } from "../app/routes/useRoutes";

export interface PreparedHelpArticle {
  id: string | undefined;
  content: React.ReactNode;
  title?: string;
  summary?: string;
}

export function isPreparedHelpArticle(subject: any): subject is PreparedHelpArticle {
  return typeof subject === "object" && subject.hasOwnProperty("content");
}

export namespace PreparedHelpArticle {
  interface Options {
    id?: string;
    defaultTitle?: string;
    defaultSummary?: string;
    vars?: Map<string, string>;
  }

  function isRemoteContent(content: string): boolean {
    return content.startsWith("http://") || content.startsWith("https://") || content.startsWith("/api/");
  }

  function renderContent(content: string, vars: Map<string, string> | undefined): React.ReactElement {
    function prepareUrl(url: string): string {
      if (vars) {
        const queryString = buildQueryString(vars);
        return url.indexOf("?") !== -1 ? url + "&" + queryString : url + "?" + queryString;
      } else {
        return url;
      }
    }

    if (isRemoteContent(content)) {
      return <RemoteContent url={prepareUrl(content)}/>;
    } else {
      return <EmbeddedContent html={content}/>;
    }
  }

  export function fromExternal(helpArticle: ExternalHelpArticle, options?: Options): PreparedHelpArticle {
    return {
      id: options?.id || (isRemoteContent(helpArticle.content) ? helpArticle.content : undefined),
      content: renderContent(helpArticle.content, options?.vars),
      title: nullToUndefined(helpArticle.title) || options?.defaultTitle,
      summary: nullToUndefined(helpArticle.summary) || options?.defaultSummary,
    };
  }

  export function mayBeFromExternal(
    helpArticle: ExternalHelpArticle | undefined,
    options?: Options
  ): PreparedHelpArticle | undefined {
    return helpArticle ? fromExternal(helpArticle, options) : undefined;
  }

  interface MigrationRulesOptions {
    routes: RoutesHook;
    phase: "before-migration" | "during-migration" | "after-migration";
    sourceAppId: string;
    destinationAppId: string;
    sourceAppTitle: string;
    destinationAppTitle: string;
    vars?: Map<string, string>;
  }

  export function migrationRules(options: MigrationRulesOptions): PreparedHelpArticle {
    const url = options.routes.api.lookupHelpArticleUrl(
      options.phase,
      Map(
        withoutUndefined([
          ["sourceAppId", options.sourceAppId],
          ["destinationAppId", options.destinationAppId],
          options.sourceAppTitle !== undefined ? ["sourceProvider", options.sourceAppTitle] : undefined,
          options.destinationAppTitle !== undefined ? ["destinationProvider", options.destinationAppTitle] : undefined,
        ])
      ).concat(options.vars || Map())
    );

    return {
      id: url,
      content: <RemoteContent url={url}/>,
      title: "VaultMe from " + options.sourceAppTitle + " to " + options.destinationAppTitle,
      summary: "How " + options.sourceAppTitle + " will be migrated"
    };
  }
}
