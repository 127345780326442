import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { ProgramStatus } from "./programStatus";
import { Upload } from "./upload";
import { DiscountType } from "./discountType";

export interface ProgramSummary {
  readonly id: string;
  readonly organizationId: number;
  readonly alias: string | undefined;
  readonly name: string;
  readonly description: string | undefined;
  readonly productName: string | undefined;
  readonly landingPageUrl: string | undefined;
  readonly logoId: string | undefined;

  readonly configurationId: number | undefined;
  readonly introBannerId: string | undefined;
  readonly introTitle: string | undefined;
  readonly introContent: string | undefined;
  readonly pricingPageBannerId: string | undefined;
  readonly pricingPageTitle: string | undefined;
  readonly pricingPageContent: string | undefined;

  readonly pricingModelId: number | undefined;

  readonly status: ProgramStatus;

  readonly sourceCloudServiceId: string | undefined;
  readonly destinationCloudServiceId: string | undefined;
  readonly sourceDomains: string | undefined;
  readonly destinationDomains: string | undefined;

  readonly discount: number;
  readonly discountType: DiscountType;
  readonly sponsoredAmount: number;

  readonly productNameOrProgramName: string;
}

export namespace ProgramSummary {
  export function parse(data: GraphQL.ProgramSummaryFragment): ProgramSummary {
    const name = data.name;
    const productName = nullToUndefined(data.productName);

    return {
      id: data.id,
      organizationId: data.organizationId,
      alias: nullToUndefined(data.alias),
      name,
      description: nullToUndefined(data.description),
      productName,
      landingPageUrl: nullToUndefined(data.landingPageUrl),
      logoId: nullToUndefined(data.logoId),

      configurationId: nullToUndefined(data.configurationId),
      introBannerId: nullToUndefined(data.introBannerId),
      introTitle: nullToUndefined(data.introTitle),
      introContent: nullToUndefined(data.introContent),
      pricingPageBannerId: nullToUndefined(data.pricingPageBannerId),
      pricingPageTitle: nullToUndefined(data.pricingPageTitle),
      pricingPageContent: nullToUndefined(data.pricingPageContent),
      pricingModelId: nullToUndefined(data.pricingModelId),

      status: data.status,

      sourceCloudServiceId: nullToUndefined(data.sourceCloudServiceId),
      destinationCloudServiceId: nullToUndefined(data.destinationCloudServiceId),
      sourceDomains: nullToUndefined(data.sourceDomains),
      destinationDomains: nullToUndefined(data.destinationDomains),

      discount: data.discount,
      discountType: data.discountType,
      sponsoredAmount: data.sponsoredAmount,

      productNameOrProgramName: productName || name
    };
  }
}

export interface Program extends ProgramSummary {
  readonly logo: Upload | undefined;
  readonly notes: string | undefined;
  readonly endUserAccess: string | undefined;

  readonly introBanner: Upload | undefined;
  readonly pricingPageBanner: Upload | undefined;

  readonly sendMigrationNotificationsToAdmins: boolean;
  readonly sendFeedbackNotificationsToAdmins: boolean;

  readonly revenueShare: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace Program {
  export function parse(data: GraphQL.ProgramFragment): Program {
    return {
      ...ProgramSummary.parse(data),

      logo: mapOptional(data.logo, (upload) => Upload.parse(upload)),
      notes: nullToUndefined(data.notes),
      endUserAccess: nullToUndefined(data.endUserAccess),

      introBanner: mapOptional(data.introBanner, (upload) => Upload.parse(upload)),
      pricingPageBanner: mapOptional(data.pricingPageBanner, (upload) => Upload.parse(upload)),

      sendMigrationNotificationsToAdmins: data.sendMigrationNotificationsToAdmins,
      sendFeedbackNotificationsToAdmins: data.sendFeedbackNotificationsToAdmins,

      revenueShare: data.revenueShare,

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(programId: string): string {
    return cachedObjectId("Program", programId);
  }

  export const fragmentName = "Program";
}
