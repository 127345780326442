import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { TaskIssue } from "../../types/models/taskIssue";

export function useTaskIssueHistory(
  jobId: string,
  taskId: string,
  issueId: string
): ManagedQueryHook<GraphQL.GetTaskIssueHistoryQueryVariables, List<TaskIssue>> {
  return useManagedQuery({
    query: GraphQL.useGetTaskIssueHistoryQuery,
    deps: null,
    prepare: () => ({ jobId, taskId, issueId }),
    extract: (data: GraphQL.GetTaskIssueHistoryQuery) => data.getTaskIssueHistory,
    complete: (extract) => List(extract.map(TaskIssue.fromGraphQL))
  });
}
