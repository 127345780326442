import * as React from "react";
import { User } from "../types/models/user";
import { UserSelectorPanelView } from "../views/blocks/userSelectorPanelView";
import { useSearchUsersQuery } from "../queries/users/useSearchUsersQuery";

// Current search terms are stored in the parent's state so that they don't get list after hiding the search bar
interface UserSelectorProps {
  searchTerm: string;
  appliedSearchTerm: string | undefined;
  onSearchTermChange: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onSelect: (user: User) => void;
}

export const UserSelectorPanel: React.FunctionComponent<UserSelectorProps> = (props) => {
  const [searchStatus, refresh] = useSearchUsersQuery(props.appliedSearchTerm);

  return (
    <UserSelectorPanelView
      searchTerm={props.searchTerm}
      appliedSearchTerm={props.appliedSearchTerm}

      searchStatus={searchStatus}

      onSearchTermChange={props.onSearchTermChange}
      onSearch={props.onSearch}
      onRefresh={refresh}
      onSelect={props.onSelect}
    />
  );
};
