import { Map } from "immutable";
import { Option } from "../../utils/monads/option";
import { GraphQL } from "../../services/graphql/generated";
import { CacheIdGenerator } from "../../services/graphql/cacheIdGenerator";
import { prepareErrorMessage } from "../../utils/misc";

export enum ScanStatus {
  Working = "Working",
  Success = "Success",
  Failure = "Failure"
}

export interface Scan {
  readonly id: number;
  readonly userId: string;

  readonly connectionId: string;

  readonly sourceCloudServiceId: string;
  readonly destinationCloudServiceId: string;
  readonly blueprintInputs: Map<string, string>;

  readonly status: ScanStatus;
  readonly error: Option<string>;
  readonly errorMessage: Option<string>;
  readonly errorDetails: Option<string>;

  readonly createdAt: Date;
  readonly completedAt: Option<Date>;
  readonly timeElapsed: () => number;

  readonly jobId: string;
  readonly workflowUrl: string;
}

export namespace Scan {
  const TypeName = "Scan";

  export const cacheId = CacheIdGenerator.simple<GraphQL.Scan, { id: number }>(
    TypeName,
    ({ id }) => id
  );

  export function parse(data: GraphQL.Scan): Scan {
    const createdAt = new Date(data.createdAt);
    const completedAt = Option.mayBe(data.completedAt).map((date) => new Date(date));

    const errorInfo = Option.mayBe(data.error).map((error) => {
      const parts = prepareErrorMessage(error);
      return {
        message: parts[0] + (parts[1] ? " {...}" : ""),
        details: Option.mayBe(parts[1])
      };
    });

    return {
      id: data.id,
      userId: data.userId,

      connectionId: data.connectionId,

      sourceCloudServiceId: data.sourceCloudServiceId,
      destinationCloudServiceId: data.destinationCloudServiceId,
      blueprintInputs: Map(data.blueprintInputs.map((input) => [input.key, input.value])),

      status: data.status,
      error: Option.mayBe(data.error),
      errorMessage: errorInfo.map((info) => info.message),
      errorDetails: errorInfo.flatMap((info) => info.details),

      createdAt,
      completedAt,
      timeElapsed: () => (completedAt.getOrUse(new Date()).getTime()  - createdAt.getTime()) / 1000,

      jobId: data.jobId,
      workflowUrl: data.workflowUrl,
    };
  }
}
