import * as React from "react";
import { ConnectionPanelState } from "./connectionPanelState";
import { AuthContext } from "../authContext";
import { ConnectionPanelDefs } from "./connectionPanelDefs";
import { ScanningState } from "./states/scanningState";
import { ScanningErrorState } from "./states/scanningErrorState";
import { ConnectedState } from "./states/connectedState";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { CloudServiceChooserRow } from "../cloudServiceChooser/cloudServiceChooserRow";
import { TitlePanelRow } from "../../containers/rows/titlePanelRow";
import { ConnectingState } from "./states/connectingState";
import { ErrorInfoRow } from "../../containers/rows/errorInfoRow";
import { ModalController, useModal } from "../../layouts/modal";
import { DrawerBlock } from "../../containers/drawerBlock";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";

export interface ConnectionPanelProps extends ConnectionPanelDefs.ControlledConnectionPanelProps {
  viewSettings: ConnectionPanelDefs.ViewSettings;
}

export const ConnectionPanel: React.FunctionComponent<ConnectionPanelProps> = (props) => {
  const disconnectionModal = useModal();

  function disconnectOrShowConfirmation(onDisconnect: () => void): void {
    if (props.viewSettings.disconnectionConfirmation) {
      disconnectionModal.open();
    } else {
      onDisconnect();
    }
  }

  const viewSettings: ConnectionPanelDefs.ViewSettings = {
    ...props.viewSettings,
    showProgress: props.viewSettingsOverrides.showScanProgress !== undefined
      ? props.viewSettingsOverrides.showScanProgress
      : props.viewSettings.showProgress
  };

  return (
    <Block>
      <ModalController
        title={"Disconnect Account"}
        modal={disconnectionModal}
        render={(close) => (
          <>
            <DrawerBlock>
              <Panel>
                <PanelRow>{props.viewSettings.disconnectionConfirmation}</PanelRow>
              </Panel>
            </DrawerBlock>
            <DrawerBlock>
              <Button
                onClick={() => {
                  close();

                  switch (props.panelState.type) {
                    case ConnectionPanelState.Type.Scanning:
                      if (props.panelState.onDisconnect) {
                        props.panelState.onDisconnect();
                      }
                      return;

                    case ConnectionPanelState.Type.ScanningError:
                      if (props.panelState.onDisconnect) {
                        props.panelState.onDisconnect();
                      }
                      return;

                    case ConnectionPanelState.Type.Connected:
                      if (props.panelState.onDisconnect) {
                        props.panelState.onDisconnect();
                      }
                      return;
                  }
                }}
              >
                Disconnect
              </Button>
            </DrawerBlock>
          </>
        )}
      />

      <Panel>
        <TitlePanelRow>Migrate {props.authContext === AuthContext.Source ? "From" : "To"}</TitlePanelRow>
        {props.badCloudServiceError && <ErrorInfoRow error={props.badCloudServiceError}/>}
        {
          props.panelState.type === ConnectionPanelState.Type.SigningIn ||
          props.panelState.type === ConnectionPanelState.Type.ConnectedBadAccount
            ? (
              <>
                <ConnectingState
                  cloudServices={props.cloudServices}
                  authProviders={props.authProviders}
                  authContext={props.authContext}
                  signInState={props.signInState}
                  grantedRoles={props.grantedRoles}
                  defaultRoles={props.defaultRoles}
                  showSecurityStatement={props.viewSettings.showSecurityStatement}
                  badConnectionError={
                    props.panelState.type === ConnectionPanelState.Type.ConnectedBadAccount
                      ? props.panelState.error
                      : undefined
                  }
                  onCloudServiceSelect={props.onCloudServiceSelect}
                  onRolesSelect={props.onRolesSelect}
                  onSignIn={props.onSignIn}
                />
              </>
            )
            : (
              <>
                <CloudServiceChooserRow
                  authContext={props.authContext}
                  cloudServices={props.cloudServices}
                  selectedCloudServiceId={props.panelState.connectionDetails.cloudService.id}
                  buttonTitle={"Choose"}
                  onCloudServiceSelect={props.onCloudServiceSelect}
                />
                {(() => {
                  switch (props.panelState.type) {
                    case ConnectionPanelState.Type.Scanning: {
                      const onDisconnect = props.panelState.onDisconnect;
                      return (
                        <ScanningState
                          authContext={props.authContext}
                          defaultRoles={props.defaultRoles}
                          cloudServices={props.cloudServices}
                          connectionDetails={props.panelState.connectionDetails}
                          areas={props.panelState.areas}
                          areaSelection={props.areaSelection}
                          onDisconnect={onDisconnect && (() => disconnectOrShowConfirmation(onDisconnect))}
                          viewSettings={viewSettings}
                          actionItemSuppressing={props.actionItemSuppressing}
                        />
                      );
                    }

                    case ConnectionPanelState.Type.ScanningError: {
                      const onDisconnect = props.panelState.onDisconnect;
                      return (
                        <ScanningErrorState
                          authContext={props.authContext}
                          defaultRoles={props.defaultRoles}
                          cloudServices={props.cloudServices}
                          connectionDetails={props.panelState.connectionDetails}
                          areas={props.panelState.areas}
                          areaSelection={props.areaSelection}
                          onDisconnect={onDisconnect && (() => disconnectOrShowConfirmation(onDisconnect))}
                          error={props.panelState.error}
                          viewSettings={viewSettings}
                          actionItemSuppressing={props.actionItemSuppressing}
                        />
                      );
                    }

                    case ConnectionPanelState.Type.Connected: {
                      const onDisconnect = props.panelState.onDisconnect;
                      return (
                        <ConnectedState
                          authContext={props.authContext}
                          defaultRoles={props.defaultRoles}
                          cloudServices={props.cloudServices}
                          connectionDetails={props.panelState.connectionDetails}
                          areas={props.panelState.areas}
                          areaSelection={props.areaSelection}
                          onReScan={props.panelState.onReScan}
                          onDisconnect={onDisconnect && (() => disconnectOrShowConfirmation(onDisconnect))}
                          viewSettings={viewSettings}
                          actionItemSuppressing={props.actionItemSuppressing}
                        />
                      );
                    }
                  }
                })()}
              </>
            )
        }
      </Panel>
    </Block>
  );
};
