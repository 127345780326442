import * as React from "react";
import {
  AddMigrationNoteControllerProps,
  MigrationNoteControllerProps,
} from "../../views/screens/migrationStatusPageView/components/migrationNotesView";
import { useResolveMigrationNoteMutation } from "./useResolveMigrationNoteMutation";
import { useReopenMigrationNoteMutation } from "./useReopenMigrationNoteMutation";
import { useAddMigrationNoteMutation } from "./useAddMigrationNoteMutation";
import { useUpdateMigrationNoteMutation } from "./useUpdateMigrationNoteMutation";

export const MigrationNoteController: React.FunctionComponent<MigrationNoteControllerProps> = (props) => {
  const [resolveMigrationNote, resolveMigrationNoteStatus] = useResolveMigrationNoteMutation(props.migrationNote.id);
  const [reopenMigrationNote, reopenMigrationNoteStatus] = useReopenMigrationNoteMutation(props.migrationNote.id);
  const [updateMigrationNote, updateMigrationNoteStatus] = useUpdateMigrationNoteMutation(props.migrationNote.id);

  const [editing, setEditing] = React.useState(false);

  return props.render({
    migrationNote: props.migrationNote,
    editing,

    resolveMigrationNoteStatus,
    reopenMigrationNoteStatus,
    updateMigrationNoteStatus,

    onResolve: resolveMigrationNote,
    onReopen: reopenMigrationNote,
    onSetEditState: setEditing,
    onUpdate: (type, message) => updateMigrationNote(type, message).then(() => setEditing(false))
  });
};

export const AddMigrationNoteController: React.FunctionComponent<AddMigrationNoteControllerProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [add, status] = useAddMigrationNoteMutation(props.migrationId);

  return props.render({
    open,
    status,
    onStateChange: setOpen,
    onSubmit: (type, message) => add(type, message).then(() => setOpen(false))
  });
};
