import * as React from "react";
import { LookupControllerProps } from "../views/blocks/lookupModal";
import { Constants } from "../app/constants";
import { List } from "immutable";
import { ManagedQueryHook } from "../services/graphql/useManagedQuery";

interface Props<Id, Item> {
  getItemId: (item: Item) => Id;
  comparator: (a: Item, b: Item) => number;

  listAll?: boolean;
  useSearchQuery: (term: string | undefined) => ManagedQueryHook<{ term: string }, List<Item>>;
}

export function SimpleLookupController<Id, Item>(
  props: Props<Id, Item> & LookupControllerProps<Id, Item>
): React.ReactElement  {
  const [searchTerm, setSearchTerm] = React.useState("");

  const trimmedSearchTerm = searchTerm.trim();
  const [searchStatus, refresh] = props.useSearchQuery(
    trimmedSearchTerm.length >= Constants.MinSearchTermLength || props.listAll ? trimmedSearchTerm : undefined
  );

  return props.render({
    getItemId: props.getItemId,
    comparator: props.comparator,

    searchTerm,
    searchStatus,

    onSearch: (newTerm) => {
      if (newTerm === searchTerm) {
        refresh();
      } else {
        setSearchTerm(newTerm);
      }
    },
    onRefresh: refresh
  });
}

export function useSimpleLookupController<Id, Item>(props: Props<Id, Item>) {
  return React.useCallback(
    (controllerProps: LookupControllerProps<Id, Item>) =>
      <SimpleLookupController {...controllerProps} {...props}/>,
    [props.getItemId, props.comparator, props.useSearchQuery]
  );
}
