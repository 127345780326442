import { OrganizationSummary } from "../../types/models/organization";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type IncrementSponsorshipLimitAmountRemainingMutationHook = [
  (amount: number) => Promise<OrganizationSummary>,
  OperationStatus<OrganizationSummary>
];

export function useIncrementSponsorshipLimitAmountRemainingMutation(
  organizationId: number
): IncrementSponsorshipLimitAmountRemainingMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useIncrementSponsorshipLimitAmountRemainingMutation,
    extract: (data: GraphQL.IncrementSponsorshipLimitAmountRemainingMutation) =>
      nullToUndefined(data.incrementSponsorshipLimitAmountRemaining),
    complete: OrganizationSummary.parse
  });

  function fireWith(amount: number): Promise<OrganizationSummary> {
    return fire({
      variables: { organizationId, amount },
      retry: () => fireWith(amount),
    });
  }

  return [fireWith, status];
}
