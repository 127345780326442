import * as React from "react";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { PanelRow } from "./panelRow";
import { ErrorInfo, ErrorInfoProps } from "../../widgets/errorInfo";

interface Props extends ErrorInfoProps {
  hideProgressBar?: boolean;
}

export const ErrorInfoRow: React.FunctionComponent<Props> = (props) => (
  <>
    {!props.hideProgressBar && <IndefiniteProgressBar error={true}/>}
    <PanelRow size={"larger"}>
      <ErrorInfo {...props}/>
    </PanelRow>
  </>
);
