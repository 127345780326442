import { Migration } from "../../../../types/models/migration";
import * as React from "react";
import { ModalController, useModal } from "../../../layouts/modal";
import { IterationLaunchTool } from "./iterationLaunchTool";
import { approximateDurationBetween } from "../../../../utils/formatting";
import { Button } from "../../../widgets/button";
import { OperationStatus } from "../../../../types/operationStatus";
import { List } from "immutable";
import { Iteration } from "../../../../types/models/iteration";
import { ControllerProps } from "../../../../utils/controllerProps";
import { IterationsHistoryTool } from "./iterationsHistoryTool";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { WhenElevated } from "../../../utils/whenElevated";
import { GraphQL } from "../../../../services/graphql/generated";
import { Constants } from "../../../../app/constants";
import { scrollIntoViewById } from "../../../../utils/scrollIntoView";
import { SyncIcon } from "../../../glyphs/syncIcon";

export interface IterationLaunchViewProps {
  status: OperationStatus<GraphQL.JobStatusTransitionSchedulingResult>;
  onLaunch: () => Promise<GraphQL.JobStatusTransitionSchedulingResult>;
}

export interface IterationLaunchControllerProps {
  render: (viewProps: IterationLaunchViewProps) => React.ReactElement;
}

export type IterationLaunchControllerType = React.ComponentType<IterationLaunchControllerProps>;

export interface IterationsHistoryViewProps {
  status: OperationStatus<List<Iteration>>;
}

export type IterationsHistoryControllerProps = ControllerProps<IterationsHistoryViewProps>;

export type IterationsHistoryControllerType = React.ComponentType<IterationsHistoryControllerProps>;

interface IterationsRowProps {
  migration: Migration;

  iterationLaunchController: IterationLaunchControllerType;
  iterationsHistoryController: IterationsHistoryControllerType;

  onIterationSelect: (iterationIndex: number | undefined) => void;
}

export const IterationToolsRow: React.FunctionComponent<IterationsRowProps> = (props) => {
  const iterationLaunchModal = useModal();
  const iterationsHistoryModal = useModal();

  const completedAt = props.migration.iterationCompletedAt || props.migration.completedAt;
  const timePassed = completedAt && approximateDurationBetween(new Date(), completedAt);

  return (
    <>
      <ModalController
        modal={iterationLaunchModal}
        render={(close) => React.createElement(props.iterationLaunchController, {
          render: (viewProps) => (
            <IterationLaunchTool
              migration={props.migration}
              status={viewProps.status}
              onLaunch={() => viewProps.onLaunch().then((result) => {
                setTimeout(close, Constants.SuccessIndicatorTimeout);
                return result;
              })}
            />
          )
        })}
      />

      <ModalController
        modal={iterationsHistoryModal}
        wide={true}
        render={(close) => React.createElement(props.iterationsHistoryController, {
          render: (viewProps) => (
            <IterationsHistoryTool
              migration={props.migration}
              status={viewProps.status}
              onViewDetailsClick={(iterationIndex) => {
                close();
                props.onIterationSelect(iterationIndex);
                scrollIntoViewById(MigrationStatusPageDefs.SourceConnectionPanelId);
              }}
            />
          )
        })}
      />

      <WhenElevated alwaysShow={true}>
        <ToolsRow
          icon={<SyncIcon/>}
          header={
            "Sync up the changes that have been made in your source account" +
            (timePassed ? " " + timePassed : "") +
            " with the destination account"
          }
        >
          <Button color={"white"} size={"small"} onClick={iterationLaunchModal.open}>Sync Up Changes</Button>
          <Button color={"white"} size={"small"} onClick={iterationsHistoryModal.open}>View Sync-Up History</Button>
        </ToolsRow>
      </WhenElevated>
    </>
  );
};
