import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { useRawManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { ConnectResult } from "../../types/models/connectResult";
import { signedInAction } from "../../state/session/actions";
import { useDispatch } from "react-redux";
import { createSignInControllers } from "./createSignInControllers";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { useConnectMutation } from "./useConnectMutation";
import { SignInContextType } from "../../types/models/signInContextType";
import { List } from "immutable";
import { NewUserSettings } from "../../types/models/newUserSettings";
import { Constants } from "../../app/constants";

export namespace SignUpFlowState {
  export enum Type {
    InitializingSignUpForm = "InitializingSignUpForm",
    PendingSignUp = "PendingSignUp",
    CompletingSignUp = "CompletingSignUp"
  }

  interface Base<T extends Type> {
    type: T;
  }

  interface InitializingSignUpFormProps {
    status: OperationStatus<any>;
  }

  export type InitializingSignUpForm = Base<Type.InitializingSignUpForm> & InitializingSignUpFormProps;

  export function InitializingSignUpForm(props: InitializingSignUpFormProps): InitializingSignUpForm {
    return { type: Type.InitializingSignUpForm, ...props };
  }

  interface SignInForm {
    cloudServiceId: string;
    signInComponents: List<SignInDefs.SignInComponentType>;
  }

  interface PendingSignUpProps {
    signInForms: List<SignInForm>;
  }

  export type PendingSignUp = Base<Type.PendingSignUp> & PendingSignUpProps;

  export function PendingSignUp(props: PendingSignUpProps): PendingSignUp {
    return { type: Type.PendingSignUp, ...props };
  }

  interface CompletingSignUpProps {
    status: OperationStatus<any>;
  }

  export type CompletingSignUp = Base<Type.CompletingSignUp> & CompletingSignUpProps;

  export function CompletingSignUp(props: CompletingSignUpProps): CompletingSignUp {
    return { type: Type.CompletingSignUp, ...props };
  }
}

export type SignUpFlowState =
  SignUpFlowState.InitializingSignUpForm |
  SignUpFlowState.PendingSignUp |
  SignUpFlowState.CompletingSignUp;

export interface SignUpFlowHook {
  state: SignUpFlowState;
  handleSignUpSuccess: (cloudServiceId: string, oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
}

export interface SignUpFlowConfig {
  flowId: string;
  newUserSettings: NewUserSettings | undefined;
  onSignUp?: (connectResult: ConnectResult) => void;
}

export function useSignUpFlow(config: SignUpFlowConfig): SignUpFlowHook {
  const dispatch = useDispatch();

  const [initSignInFormStatus, refreshSignInForm] = useRawManagedQuery({
    query: GraphQL.useGetSignUpFormConfigQuery,
    deps: null,
    prepare: () => ({ authFlowId: config.flowId, roles: [Constants.Auth.IdentificationRole] }),
    fetchPolicy: "network-only"
  });

  const [connect, connectStatus] = useConnectMutation(SignInContextType.App, refreshSignInForm);

  function handleSignUpSuccess(cloudServiceId: string, oneTimeAuthCodeSummary: OneTimeAuthCodeSummary): void {
    connect({
      contextType: SignInContextType.App,
      cloudServiceId,
      oneTimeAuthCode: oneTimeAuthCodeSummary.code,
      newUserSettings: config.newUserSettings
    }).then((connectResult) => {
      if (config.onSignUp) {
        config.onSignUp(connectResult);
      }
      dispatch(signedInAction(connectResult));
      refreshSignInForm();
    });
  }

  function buildHook(state: SignUpFlowState): SignUpFlowHook {
    return {
      state,
      handleSignUpSuccess
    };
  }

  if (!initSignInFormStatus.isSuccess()) {
    return buildHook(SignUpFlowState.InitializingSignUpForm({
      status: initSignInFormStatus
    }));
  } else if (connectStatus.isPending()) {
    return buildHook(SignUpFlowState.PendingSignUp({
      signInForms: List(initSignInFormStatus.result.getSignUpFormConfig.map((signUpFormConfig) => ({
        cloudServiceId: signUpFormConfig.cloudServiceId,
        signInComponents: List(createSignInControllers(signUpFormConfig, false))
      })))
    }));
  } else {
    return buildHook(SignUpFlowState.CompletingSignUp({
      status: connectStatus
    }));
  }
}
