import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { OperationStatus, useOperationStatus } from "../../types/operationStatus";
import { closeWhenFoundLoginResult, closeWhenReachedUrl, popUpLoginScreen } from "./loginPopup";
import { parseJson } from "../../utils/parseJson";
import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { UserFacingError } from "../../types/userFacingError";

interface OAuthSignInFlowConfig {
  url: string;
  callbackUrl: string;
  vendorName: string | undefined;
  verb: string;
  onSignIn: SignInDefs.SignInHandle;
  onFailure?: (code: string, summary: string, recommendations: string | undefined) => boolean | UserFacingError;
}

interface OAuthSignInFlowHook {
  fire: () => void;
  status: OperationStatus<unknown>;
  resetStatus: () => void;
}

export function useOAuthSignInFlow(config: OAuthSignInFlowConfig): OAuthSignInFlowHook {
  const status = useOperationStatus();

  function fire() {
    status.setPending();

    popUpLoginScreen({
      url: config.url,
      title: config.verb + (config.vendorName ? " with " + config.vendorName : ""),
      closeWhen: closeWhenReachedUrl(config.callbackUrl, closeWhenFoundLoginResult),
      onSuccess: (result) =>
        parseJson(result, OneTimeAuthCodeSummary.schema)
          .then(config.onSignIn)
          .catch((parsingError) => status.setUnexpectedError(parsingError)),
      onFailure: (code, summary, recommendations) => {
        if (config.onFailure) {
          const result = config.onFailure(code, summary, recommendations);
          if (typeof result === "boolean") {
            if (!result) {
              status.setFailure(
                UserFacingError.synthetic({
                  summary,
                  recommendations:
                    recommendations || "Please refresh the current page in your browser and try signing in again."
                })
              );
            }
          } else {
            status.setFailure(result);
          }
        }
      }
    });
  }

  return {
    fire,
    status: status.current,
    resetStatus: status.setPending
  };
}
