import { AccessKeyStatus } from "./accessKeyStatus";
import { Set } from "immutable";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export class RevealedAccessKey implements RevealedAccessKey.Props {
  constructor(protected readonly props: RevealedAccessKey.Props) {
  }

  public get accessKeyVersion(): string | undefined { return this.props.accessKeyVersion; }
  public get accessKey(): any { return this.props.accessKey; }
  public get accessKeyStatus(): AccessKeyStatus { return this.props.accessKeyStatus; }
  public get accessKeyParamsVersion(): string { return this.props.accessKeyParamsVersion; }
  public get accessKeyParams(): any { return this.props.accessKeyParams; }
  public get currentPermissions(): Set<string> | undefined { return this.props.currentPermissions; }
  public get currentRoles(): Set<string> | undefined { return this.props.currentRoles; }
  public get validatedPermissions(): Set<string> | undefined { return this.props.validatedPermissions; }
  public get missingPermissions(): Set<string> | undefined { return this.props.missingPermissions; }
}

export namespace RevealedAccessKey {
  export interface Props {
    accessKeyVersion?: string;
    accessKey?: any;
    accessKeyStatus: AccessKeyStatus;
    accessKeyParamsVersion: string;
    accessKeyParams: any;
    currentPermissions?: Set<string>;
    currentRoles?: Set<string>;
    validatedPermissions?: Set<string>;
    missingPermissions?: Set<string>;
  }

  export function fromGraphQL(revealedAccessKey: GraphQL.RevealedAccessKey): RevealedAccessKey {
    return new RevealedAccessKey({
      accessKeyVersion: nullToUndefined(revealedAccessKey.accessKeyVersion),
      accessKey: revealedAccessKey.accessKey ? JSON.parse(revealedAccessKey.accessKey) : undefined,
      accessKeyStatus: revealedAccessKey.accessKeyStatus,
      accessKeyParamsVersion: revealedAccessKey.accessKeyParamsVersion,
      accessKeyParams: revealedAccessKey.accessKeyParams ? JSON.parse(revealedAccessKey.accessKeyParams) : undefined,
      currentPermissions: revealedAccessKey.currentPermissions ? Set(revealedAccessKey.currentPermissions) : undefined,
      currentRoles: revealedAccessKey.currentRoles ? Set(revealedAccessKey.currentRoles) : undefined,
      validatedPermissions: revealedAccessKey.validatedPermissions
        ? Set(revealedAccessKey.validatedPermissions)
        : undefined,
      missingPermissions: revealedAccessKey.missingPermissions ? Set(revealedAccessKey.missingPermissions) : undefined
    });
  }
}
