import { GraphQL } from "../../services/graphql/generated";
import { ObjectRepo } from "../../utils/objectRepo";
import { CloudService } from "./cloudService";
import { List, Map } from "immutable";
import { CloudServiceCategory } from "./cloudServiceCategory";
import { AppBootstrapConfig } from "./appBootstrapConfig";
import { RoutesHook } from "../../app/routes/useRoutes";

export class CloudServices extends ObjectRepo<CloudService> {
  constructor(
    public readonly categories: List<CloudServiceCategory>,
    data?: Map<string, CloudService> | CloudService[]
  ) {
    super(data);
  }

  public getOrFail(id: string): CloudService {
    const result = this.get(id);
    if (result) {
      return result;
    } else {
      throw Error("Unknown cloud service " + id);
    }
  }

  public withConfiguration(
    isSource: boolean,
    configuration: AppBootstrapConfig.Configuration,
    routes: RoutesHook
  ): CloudServices {
    const segmentCloudServices = configuration.cloudServices
      .filter((configurationCloudService) =>
        configurationCloudService.isSource === isSource
      );

    if (segmentCloudServices.isEmpty()) {
      return this;
    } else {
      return new CloudServices(
        List([
          new CloudServiceCategory({
            title: "",
            cloudServices: segmentCloudServices.flatMap((configurationCloudService) => {
              const cloudService = this.map.get(configurationCloudService.cloudServiceId);
              return cloudService
                ? List([cloudService.withConfiguration(configurationCloudService, routes)])
                : List();
            })
          })
        ]),
        this.map.map((cloudService) => {
          const configurationCloudService = configuration.cloudServices.find((item) =>
            item.isSource === isSource && item.cloudServiceId === cloudService.id
          );
          return configurationCloudService
            ? cloudService.withConfiguration(configurationCloudService, routes)
            : cloudService;
        })
      );
    }
  }
}

export namespace CloudServices {
  export const Empty: CloudServices = new CloudServices(List());

  export const GSuite = "gsuite";
  export const Office365 = "office365";

  export function fromGraphQLList(categories: GraphQL.CloudServiceCategory[]): CloudServices {
    return new CloudServices(
      List(categories.map(CloudServiceCategory.fromGraphQL)),
      categories
        .map((category) => category.cloudServices)
        .reduce((a, b) => a.concat(b))
        .map(CloudService.fromGraphQL)
    );
  }
}
