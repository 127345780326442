import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface PersonalInfo {
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly emailAddress: string | undefined;
  readonly phoneNumber: string | undefined;
  readonly payPalEmailAddress: string | undefined;
  readonly timeZone: string | undefined;

  readonly fullName: string | undefined;
  readonly fullNameOrEmailAddress: string;
  readonly domain: string | undefined;
  readonly titleizedDomain: string | undefined;
}

export namespace PersonalInfo {
  const anonymous = "Anonymous";

  export const empty: PersonalInfo = {
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    payPalEmailAddress: undefined,
    timeZone: undefined,

    fullName: undefined,
    fullNameOrEmailAddress: anonymous,
    domain: undefined,
    titleizedDomain: undefined
  };

  export function parse(data: GraphQL.PersonalInfo): PersonalInfo {
    const firstName = nullToUndefined(data.firstName);
    const lastName = nullToUndefined(data.lastName);
    const emailAddress = nullToUndefined(data.emailAddress);

    const fullName = firstName || lastName
      ? (firstName || "") + (firstName && lastName ? " " : "") + (lastName || "")
      : undefined;
    const emailAddressParts = emailAddress?.split("@");
    const domain = emailAddressParts && emailAddressParts.length === 2 ? emailAddressParts[1] : undefined;

    return {
      firstName,
      lastName,
      emailAddress,
      phoneNumber: nullToUndefined(data.phoneNumber),
      payPalEmailAddress: nullToUndefined(data.payPalEmailAddress),
      timeZone: nullToUndefined(data.timeZone),

      fullName,
      fullNameOrEmailAddress: fullName || emailAddress || anonymous,
      domain,
      titleizedDomain: domain && domain.length > 0 ? domain[0].toUpperCase() + domain.substr(1) : domain
    };
  }
}
