import * as React from "react";
import { ActionItem, ActionItems } from "../../models/actionItem";
import { friendlySize } from "../../../utils/formatting";
import { ConnectionPanelDefs } from "./connectionPanelDefs";
import { List } from "immutable";
import { DrawerBlock } from "../../containers/drawerBlock";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";

interface PrepareActionItemsParams {
  subjectId: string;
  actionItems: ActionItems;
  availableStorage?: ConnectionPanelDefs.AvailableStorageInfo.Any;
  affectedSubject: string | undefined; // Can be used if we decide to allow suppressing
}

export function prepareActionItems(params: PrepareActionItemsParams): ActionItems {
  const { subjectId, actionItems, availableStorage, affectedSubject } = params;

  if (actionItems && actionItems.size !== 0 || ConnectionPanelDefs.AvailableStorageInfo.raiseIssue(availableStorage)) {
    function compareIssues(h1: ActionItem, h2: ActionItem): number {
      const priority1 = ActionItem.sortKey(h1.type);
      const priority2 = ActionItem.sortKey(h2.type);
      if (priority1 < priority2) {
        return -1;
      } else if (priority1 > priority2) {
        return 1;
      } else {
        return 0;
      }
    }

    let result = actionItems || List();

    if (availableStorage) {
      if (availableStorage.type === ConnectionPanelDefs.AvailableStorageInfo.Type.NeedToEnsure) {
        const requirement = friendlySize(availableStorage.required) +
          " available " + ConnectionPanelDefs.AvailableStorageInfo.getStorageType(availableStorage);

        result = result.concat([{
          id: ActionItem.needToEnsureStorageAvailability(subjectId),
          type: ActionItem.Type.Warning,
          message: "Please confirm that you have " + requirement + ".",
          actions: [{
            title: "Confirm",
            modal: {
              title: availableStorage.helpArticle?.title,
              render: ({ close, suppressAndClose }) => (
                <>
                  {availableStorage.helpArticle?.content}
                  <DrawerBlock>
                    By clicking the "Confirm" button below you confirm that you have {requirement}.
                  </DrawerBlock>
                  <DrawerBlock>
                    <SimpleToolbar>
                      <Button onClick={suppressAndClose}>Confirm</Button>
                      <Button color={"secondary"} onClick={close}>Cancel</Button>
                    </SimpleToolbar>
                  </DrawerBlock>
                </>
              )
            }
          }],
          suppressed: {
            message: "You confirmed that you have " + requirement + ".",
            reActivateOptionTitle: "Check again",
          }
        }]);
      } else if (availableStorage.type === ConnectionPanelDefs.AvailableStorageInfo.Type.NotEnough) {
        result = result.concat([{
          id: ActionItem.notEnoughStorage(subjectId),
          type: ActionItem.Type.Error,
          message: "Not enough available " +
            ConnectionPanelDefs.AvailableStorageInfo.getStorageType(availableStorage) + " (" +
            friendlySize(availableStorage.required - availableStorage.available) + " more is required).",
          actions: [
            {
              title: "Fix",
              helpArticle: availableStorage.helpArticle
            },
            {
              title: "Check Again",
              onClick: availableStorage.checkAgain
            },
          ]
        }]);
      }
    }

    result.sort(compareIssues);

    return result;
  } else {
    return List();
  }
}
