import * as React from "react";
import { Buttons } from "./buttons";
import { Forms } from "./forms";
import { Pages } from "./pages";
import { Panels } from "./panels";
import { Utils } from "./utils";
import { Wizards } from "./wizards";
import { Assets } from "./assets";
import { DataIndicators } from "./dataIndicators";
import { ProgressIndicators } from "./progressIndicators";
import { Grids } from "./grids";

interface StyleguideSection {
  id: string;
  title: string;
  content: React.ReactNode;
}

export const StyleguideSections: StyleguideSection[] = [
  {
    id: "buttons",
    title: "Buttons",
    content: <Buttons/>
  },
  {
    id: "forms",
    title: "Forms",
    content: <Forms/>
  },
  {
    id: "panels",
    title: "Panels",
    content: <Panels/>
  },
  {
    id: "grids",
    title: "Grids",
    content: <Grids/>
  },
  {
    id: "dataIndicators",
    title: "Data Indicators",
    content: <DataIndicators/>
  },
  {
    id: "progressIndicators",
    title: "Progress Indicators",
    content: <ProgressIndicators/>
  },
  {
    id: "misc.ts",
    title: "Utility Widgets",
    content: <Utils/>
  },
  {
    id: "assets",
    title: "Assets",
    content: <Assets/>
  },
  {
    id: "wizards",
    title: "Wizard Layout",
    content: <Wizards/>
  },
  {
    id: "pages",
    title: "Page Layout",
    content: <Pages/>
  },
];
