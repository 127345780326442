import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { School } from "../../types/models/school";

type DeleteSchoolMutationHook = [(id: string) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteSchoolMutation(): DeleteSchoolMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteSchoolMutation,
    extract: (data: GraphQL.DeleteSchoolMutation) => nullToUndefined(data.deleteSchool),
    complete: identity
  });

  function fireWith(id: string): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = School.cacheId(id);

      const school = apolloClient.cache.readFragment<GraphQL.SchoolFragment>({
        id: cacheId,
        fragment: GraphQL.SchoolFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: School.fragmentName
      });

      if (school) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.SchoolFragmentDoc,
          fragmentName: School.fragmentName,
          data: { ...school, id: "" }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
