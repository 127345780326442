import { Option } from "../../utils/monads/option";
import { GraphQL } from "../../services/graphql/generated";

export interface MigrationHistoryItem {
  isEvent: boolean;
  startedAt: Date;
  actor: string;
  type: string;
  details: string;
  completion: Option<MigrationHistoryItem.Completion>;
}

export namespace MigrationHistoryItem {
  export function parse(data: GraphQL.MigrationHistoryItem): MigrationHistoryItem {
    console.log(data);
    return {
      isEvent: data.isEvent,
      startedAt: new Date(data.startedAt),
      actor: data.actor,
      type: data.type,
      details: data.details,
      completion: Option.mayBe(data.completion).map(Completion.parseCompletion)
    };
  }

  export interface Completion {
    completedAt: Date;
    details: string;
  }

  export namespace Completion {
    export function parseCompletion(data: GraphQL.MigrationHistoryItemCompletion): Completion {
      return {
        completedAt: new Date(data.completedAt),
        details: data.details
      };
    }
  }
}
