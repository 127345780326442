import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProject } from "../../types/models/offboardingProject";

type ForceStartEmailDeliveryRoundMutationHook = [
  (round: number) => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export function useForceStartEmailDeliveryRoundMutation(
  offboardingProjectId: string
): ForceStartEmailDeliveryRoundMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useForceStartEmailDeliveryRoundMutation,
    extract: (data: GraphQL.ForceStartEmailDeliveryRoundMutation) => nullToUndefined(data.forceStartEmailDeliveryRound),
    complete: OffboardingProject.parse
  });

  function fireWith(round: number): Promise<OffboardingProject> {
    return fire({
      variables: { offboardingProjectId, round },
      retry: () => fireWith(round),
    });
  }

  return [fireWith, status];
}
