import * as React from "react";
import { Set } from "immutable";
import { SignInDefs } from "../../blocks/auth/signInDefs";
import { styled } from "../../../app/theme";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { ModalController, useModal } from "../../layouts/modal";
import { SignUpFlowState } from "../../../components/auth/useSignUpFlow";
import { OneTimeAuthCodeSummary } from "../../../types/models/oneTimeAuthCodeSummary";
import { useCloudServices } from "../../../app/configuration";
import { OAuthSignInButton } from "../../blocks/auth/oAuthSignInForm";

const Layout = styled.div`
  margin:
          ${(props) => props.theme.layout.header.desktop * 2}rem
          auto
          ${(props) => props.theme.layout.header.desktop}rem;

  min-width: 30rem;
  max-width: 60rem;
  width: max-content;
  background: ${(props) => props.theme.colors.white};

  ${(props) => props.theme.responsive.respondToXSmall()} {
    max-width: none;
    margin:
            ${(props) => props.theme.layout.header.mobile}rem
            0
            ${(props) => props.theme.layout.header.mobile}rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 3rem 1.5rem;
  }
`;

const Options = styled.div`
  width: max-content;

  ${OAuthSignInButton} {
    width: 100%;
  }
`;

const Option = styled.div`
  width: max-content;
  margin: 3rem 0;
  
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Or = styled.div`
  width: 100%;
  text-align: center;
  color: #888;
`;

const Title = styled.div`
  font-size: 2rem;
  margin: 1rem 0;
  border-bottom: 1px solid #aaaaaa;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.75rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
  }
`;

const OperationStatusIndicatorWrapper = styled.div`
  margin: 1.5rem 0;
  max-width: 60rem;
  overflow-x: auto;
`;

const SignInOptionLayoutInner = styled.div`
  width: 100%;
  margin: 1.5rem 0;
`;

export const SignInOptionLayout: SignInDefs.SignInLayoutComponentType = (props) => {
  const modal = useModal();

  const failure = props.operationStatusIndicator && props.operationStatusIndicator.status.isFailure()
    ? props.operationStatusIndicator.status
    : undefined;

  React.useEffect(
    () => {
      if (failure) {
        modal.open();
      } else {
        modal.close();
      }
    },
    [failure]
  );

  return (
    <>
      <ModalController
        modal={modal}
        render={() => (
          <>
            {props.operationStatusIndicator && (
              <OperationStatusIndicator
                {...props.operationStatusIndicator}
                indicators={StatusIndicators.SimplePanel()}
              />
            )}
          </>
        )}
      />
      {props.operationStatusIndicator && props.operationStatusIndicator.status.isPending() && (
        <OperationStatusIndicator
          {...props.operationStatusIndicator}
          indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
        />
      )}
      <SignInOptionLayoutInner>
        {props.signInForm}
      </SignInOptionLayoutInner>
    </>
  );
};

interface Props {
  state: SignUpFlowState;
  roles: Set<string>;
  onSignUp: (cloudServiceId: string, oneTimeAuthCodeSummary: OneTimeAuthCodeSummary) => void;
}

export const OrganizationPortalSignInPageView: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();

  return (
    <Layout>
      <Content>
        <Title>EDU Admin Portal</Title>

        {(() => {
          switch (props.state.type) {
            case SignUpFlowState.Type.InitializingSignUpForm:
              return (
                <OperationStatusIndicator
                  subject={"configuration"}
                  status={props.state.status}
                  indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
                />
              );

            case SignUpFlowState.Type.PendingSignUp: {
              return (
                <Options>
                  {
                    props.state.signInForms
                      .flatMap(({ cloudServiceId, signInComponents }, formIndex) =>
                        signInComponents.map((signInComponent, signInComponentIndex) => (
                          <React.Fragment key={cloudServiceId + "-" + signInComponentIndex}>
                            {(formIndex > 0 || signInComponentIndex > 0) && (
                              <Or>OR</Or>
                            )}
                            <Option>
                              {
                                React.createElement(signInComponent, {
                                  layoutComponent: SignInOptionLayout,
                                  verb: "Sign In",
                                  cloudServiceId,
                                  authProviderId: cloudServices.getOrFail(cloudServiceId).authProviderId,
                                  roles: props.roles,
                                  onSignIn: (oneTimeAuthCode) => props.onSignUp(cloudServiceId, oneTimeAuthCode)
                                })
                              }
                            </Option>
                          </React.Fragment>
                        )))
                  }
                </Options>
              );
            }

            case SignUpFlowState.Type.CompletingSignUp:
              return (
                <OperationStatusIndicator
                  progressMessage={"Signing in..."}
                  successMessage={"Done!"}
                  status={props.state.status}
                  indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
                />
              );
          }
        })()}
      </Content>
    </Layout>
  );
};
