import * as React from "react";
import { Blueprint } from "../blueprint";
import { BlueprintContext } from "../blueprintContext";
import { BlueprintFilters, FilterState } from "../../state/settings/blueprintFilters";
import { FilteringOptions } from "./filteringOptions";
import { Section } from "./section";
import { Map } from "immutable";

interface FiltersProps {
  horizontal: boolean;
  blueprint: Blueprint;
  blueprintContext: BlueprintContext;
  filters: BlueprintFilters;
  onChange: (filters: BlueprintFilters) => void;
}

export class Filters extends React.Component<FiltersProps> {
  constructor(props: FiltersProps) {
    super(props);

    this.handleSegmentFilterChange = this.handleSegmentFilterChange.bind(this);
    this.handleTagFilterChange = this.handleTagFilterChange.bind(this);
    // this.handleComponentTypeFilterChange = this.handleComponentTypeFilterChange.bind(this);
    // this.handleHubTitleFilterChange = this.handleHubTitleFilterChange .bind(this);
  }

  public render() {
    const filteringOptions = this.props.blueprint.components.filteringOptions();
    return (
      <React.Fragment>
        <Section horizontal={this.props.horizontal} title="Segments" sectionId="segments">
          <FilteringOptions
            options={filteringOptions.segments}
            filters={this.props.filters.filteredSegments}
            onChange={this.handleSegmentFilterChange}
          />
        </Section>
        <Section horizontal={this.props.horizontal} title="Tags" sectionId="tags">
          <FilteringOptions
            allowCollapsing={true}
            allowPinning={true}
            options={filteringOptions.tags}
            filters={this.props.filters.filteredTags}
            onChange={this.handleTagFilterChange}
          />
        </Section>
        {/*<Section horizontal={this.props.horizontal} title="Component Types" sectionId="components">*/}
        {/*  <FilteringOptions*/}
        {/*    options={filteringOptions.componentTypes}*/}
        {/*    filters={this.props.filters.filteredComponentTypes}*/}
        {/*    onChange={this.handleComponentTypeFilterChange}*/}
        {/*  />*/}
        {/*</Section>*/}
        {/*<Section horizontal={this.props.horizontal} title="Relationships" sectionId="relationships">*/}
        {/*  <FilteringOptions*/}
        {/*    options={filteringOptions.hubTitles}*/}
        {/*    filters={this.props.filters.filteredHubTitles}*/}
        {/*    onChange={this.handleHubTitleFilterChange}*/}
        {/*  />*/}
        {/*</Section>*/}
      </React.Fragment>
    );
  }

  protected handleSegmentFilterChange(filters: Map<string, FilterState>) {
    this.props.onChange({ ...this.props.filters, filteredSegments: filters });
  }

  protected handleTagFilterChange(filters: Map<string, FilterState>) {
    this.props.onChange({ ...this.props.filters, filteredTags: filters });
  }

  // protected handleComponentTypeFilterChange(filters: Map<string, FilterState>) {
  //   this.props.onChange({ ...this.props.filters, filteredComponentTypes: filters });
  // }
  //
  // protected handleHubTitleFilterChange(filters: Map<string, FilterState>) {
  //   this.props.onChange({ ...this.props.filters, filteredHubTitles: filters });
  // }
}
