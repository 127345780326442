import * as React from "react";
import { OperationStatus } from "../../../../types/operationStatus";
import { Button } from "../../../widgets/button";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Panel } from "../../../containers/panel";

interface Props {
  currentAmount: number | undefined;
  status: OperationStatus<any>;
  onSubmit: () => Promise<any>;
}

export const RemoveSponsorshipLimitTool: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      status={props.status}
      progressMessage={"Removing sponsorship limit..."}
      failureMessage={"Failed to remove sponsorship limit"}
      successMessage={"Sponsorship limit removed successfully"}
      indicators={StatusIndicators.PanelRow({ keepSuccessIndicator: true })}
    />
    {!props.status.isSuccess() && (
      <>
        <PanelRow>
          Click the button below to remove sponsorship limit.
        </PanelRow>
        <PanelRow>
          <Button
            color={"red"}
            onClick={() => props.onSubmit()}
            disabled={props.status.isWorking()}
          >
            Remove
          </Button>
        </PanelRow>
      </>
    )}
  </Panel>
);
