import * as React from "react";
import { Page } from "../../containers/page";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Form, Formik } from "formik";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { Panel } from "../../containers/panel";
import { Block } from "../../containers/block";
import { PanelRow } from "../../containers/rows/panelRow";
import * as yup from "yup";
import { Button } from "../../widgets/button";
import { List } from "immutable";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Grid } from "../../widgets/grid";
import { friendlyCount } from "../../../utils/formatting";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../app/routes/useRoutes";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { useNavigate } from "react-router";

interface SendCouponCodeResult {
  emailAddress: string;
  result: string;
}

interface SendCouponCodesPageViewProps {
  status: OperationStatus<List<SendCouponCodeResult>>;
  onSend: (emailAddresses: string, discount: number, note: string) => Promise<List<SendCouponCodeResult>>;
}

export const SendCouponCodesPageView: React.FunctionComponent<SendCouponCodesPageViewProps> = (props) => {
  const routes = useRoutes().couponCodes;

  return (
    <Page
      breadcrumbs={[<Link key={0} to={routes.homePath}>Coupon Codes</Link>, "Generate & Send in Bulk"]}
      title={"Generate & Send Coupon Codes in Bulk"}
      fullWidth={true}
    >
      {props.status.isSuccess() && (
        <Block>
          <Panel>
            <PanelRow>
              Processed {friendlyCount(props.status.result.count(), "email address")}
            </PanelRow>
            <GridPanelRow>
              <Grid>
                <Grid.Header>
                  <Grid.Column>Email Address</Grid.Column>
                  <Grid.Column>Result</Grid.Column>
                  <Grid.Column/>
                </Grid.Header>
                <Grid.Body>
                  {
                    props.status.result.map((result, index) => (
                      <Grid.Row key={index}>
                        <Grid.Cell style={{ width: "1%" }}>{result.emailAddress}</Grid.Cell>
                        <Grid.Cell>{result.result}</Grid.Cell>
                      </Grid.Row>
                    ))
                  }
                </Grid.Body>
              </Grid>
            </GridPanelRow>
          </Panel>
        </Block>
      )}
      <SendForm
        status={props.status}
        onSubmit={(result) => props.onSend(result.emailAddresses, result.discount, result.note)}
      />
    </Page>
  );
};

interface FormData {
  emailAddresses: string;
  discount: string;
  note: string;
}

interface Result {
  emailAddresses: string;
  discount: number;
  note: string;
}

namespace FormData {
  export const empty: FormData = {
    emailAddresses: "",
    discount: "40.00",
    note: ""
  };

  export function toResult(formData: FormData): Result {
    return {
      emailAddresses: formData.emailAddresses,
      discount: Number.parseFloat(formData.discount),
      note: formData.note
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    emailAddresses: yup.string().required("Email addresses are required"),
    discount: yup.number().required("Discount value is required"),
    note: yup.string()
  });
}

interface SendFormProps {
  status: OperationStatus<any>;
  onSubmit: (result: Result) => Promise<any>;
}

const SendForm: React.FunctionComponent<SendFormProps> = (props) => {
  const routes = useRoutes().couponCodes;
  const navigate = useNavigate();

  const firstInputRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []
  );

  const submitting = props.status.isWorking();

  return (
    <Formik<FormData>
      initialValues={FormData.empty}
      validationSchema={FormData.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={false}
      onSubmit={(data, actions) => {
        props.onSubmit(FormData.toResult(data));
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form>
          <Block>
            <Panel>
              <OperationStatusIndicator
                progressMessage={"Sending coupon codes..."}
                failureMessage={"Failed to send coupon codes"}
                status={props.status}
                indicators={StatusIndicators.PanelRow()}
              />
              <PanelRow>
                <FormLayout>
                  <TextAreaField<FormData>
                    textBoxRef={firstInputRef}
                    label={"Email Addresses (one per line)"}
                    name="emailAddresses"
                    disabled={submitting}
                    required={true}
                    rows={20}
                  />
                  <TextField<FormData>
                    label={"Discount"}
                    name="discount"
                    required={true}
                    maxLength={10}
                    autoComplete={false}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"Note (will be added to the coupon code description)"}
                    name="note"
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <SimpleToolbar>
              <Button type={"submit"} size={"small"} disabled={formProps.isSubmitting || submitting}>
                Generate & Send
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                disabled={formProps.isSubmitting || submitting}
                onClick={() => navigate(routes.homePath)}
              >
                Close
              </Button>
            </SimpleToolbar>
          </Block>
        </Form>
      )}
    />
  );
};
