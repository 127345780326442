import { Map, OrderedMap } from "immutable";
import * as _ from "lodash";

export function withoutUndefined<T>(array: (T | undefined)[]): T[] {
  const result: T[] = [];
  array.forEach((item) => {
    if (item !== undefined) {
      result.push(item);
    }
  });
  return result;
}

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function interpolate(s: string, variables: Map<string, string | undefined>): string {
  let result = s;
  variables.forEach((value, variable) => {
    if (value) {
      result = result.replace("{" + variable + "}", value);
    }
  });
  return result;
}

export function distinct<T>(array: T[], equals?: (item1: T, item2: T) => boolean): T[] {
  return array.filter((item, index) => {
    if (equals) {
      return array.findIndex((otherItem) => equals(item, otherItem)) >= index;
    } else {
      return array.indexOf(item) >= index;
    }
  });
}

export function identity<T>(value: T): T {
  return value;
}

export function mapOptional<T, R>(value: T | null | undefined, map: (value: T) => R): R | undefined {
  return value === null || value === undefined ? undefined : map(value);
}

export function sentence(s: string): string {
  return s.length !== 0 ? s[0].toUpperCase() + s.substring(1) : s;
}

export function nullToUndefined<T>(value: T | null | undefined): T | undefined {
  return value === null ? undefined : value;
}

export function blankStringToUndefined(s: string): string | undefined {
  const trimmed = s.trim();
  return trimmed.length === 0 ? undefined : trimmed;
}

export function enumValues<T>(e: any): T[] {
  return Object.keys(e).map((k) => e[k as any] as T);
}

export function withIndefiniteArticle(phrase: string): string {
  if (phrase.length > 0) {
    const first = phrase[0].toLowerCase();
    if (first === "a" || first === "e" || first === "i" || first === "o" || first === "u") {
      return "an " + phrase;
    } else {
      return "a " + phrase;
    }
  } else {
    return phrase;
  }
}

export function stealKeyStrokesFrom(element: Element): boolean {
  const tagName = element.tagName.toLowerCase();
  const type = element.getAttribute("type");
  return !(
    (tagName === "input" && (type === "text" || type === "password")) ||
    tagName === "textarea"
  );
}

export function toJSON(value: any): any {
  let prev: any;
  let result = value;
  let iteration = 0;
  while (
    prev !== result &&
    iteration < 100 &&
    result !== undefined &&
    result !== null &&
    typeof result === "object" &&
    typeof result.toJSON === "function"
    ) {
    prev = result;
    result = result.toJSON();
    iteration += 1;
  }
  return result;
}

// Performs a deep transformation by calling toJSON where defined. It helps to simplify Immutable.js structures
// before performing comparison.
export function deepToJSON(value: any): any {
  const jsonValue = toJSON(value);
  if (
    jsonValue !== undefined &&
    jsonValue !== null &&
    typeof jsonValue === "object"
    && !(jsonValue instanceof Array)
  ) {
    return _.transform(
      jsonValue,
      (acc, propValue, propKey) => {
        acc[propKey] = deepToJSON(propValue);
      },
      {}
    );
  } else {
    return jsonValue;
  }
}

export function buildQueryString(vars: Map<string, string>): string {
  return vars.map((value, key) => encodeURIComponent(key) + "=" + encodeURIComponent(value)).join("&");
}

export function isAbsoluteUrl(urlOrPath: string): boolean {
  return urlOrPath.startsWith("http://") || urlOrPath.startsWith("https://");
}

export function zipOrderedMaps<K, V>(
  a: OrderedMap<K, V>,
  b: OrderedMap<K, V>,
  f: (valueA: V | undefined, valueB: V | undefined, key: K) => V | undefined
): OrderedMap<K, V> {
  const keys = a.keySeq().concat(b.keySeq().filter((key) => !a.has(key)));
  return OrderedMap(keys.map((key) => [key, f(a.get(key), b.get(key), key) as V]));
}

export function zipOrderedMapsEx<K, V>(
  a: OrderedMap<K, V>,
  b: OrderedMap<K, V>,
  twoValues: (valueA: V, valueB: V, key: K) => V,
  oneValue: (value: V, key: K) => V = identity
): OrderedMap<K, V> {
  return zipOrderedMaps(
    a,
    b,
    (valueA, valueB, key) => {
      if (valueA !== undefined && valueB !== undefined) {
        return twoValues(valueA, valueB, key);
      } else if (valueA !== undefined) {
        return oneValue(valueA, key);
      } else if (valueB !== undefined) {
        return oneValue(valueB, key);
      }
    }
  );
}

// By agreement, error messages may include additional detail after a blank line
export function prepareErrorMessage(errorMessage: string): [string, string | undefined] {
  const separatorIndex = errorMessage.indexOf("\n\n");
  if (separatorIndex !== -1) {
    return [errorMessage.substring(0, separatorIndex), errorMessage.substring(separatorIndex + 2)];
  } else {
    return [errorMessage, undefined];
  }
}

// It appeared that JavaScript can do this: 11.69 - 5 = 6.6899999999999995 :(
export function roundCurrencyAmount(amount: number): number {
  return Math.round(amount * 100) / 100;
}
