import * as React from "react";
import { SimplePanel } from "./containers/simplePanel";
import { styled } from "../app/theme";
import { Button } from "./widgets/button";
import { useBrowser } from "../utils/useBrowser";
import { Link } from "react-router-dom";
import { Page } from "./containers/page";
import { useRoutes } from "../app/routes/useRoutes";

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
`;

export const PageNotFound = () => {
  const browser = useBrowser();
  const routes = useRoutes();

  return (
    <Page
      title={"Page Not Found."}
      subTitle={"We're sorry, the web address entered is not a functioning page in our app."}
    >
      <SimplePanel>
        <ButtonContainer>
          <Button onClick={() => browser.navigateTo(routes.migrationSetup.homePath)}>Start a New Migration</Button>
        </ButtonContainer>
        or, <Link to={routes.migrations.homePath}>Sign In to Manage Your Migrations</Link>
      </SimplePanel>
    </Page>
  );
};
