import { DataFlowWithProviderProps, DataFlowWithCompatibleInput } from "../relationship";
import { GraphQL } from "../../services/graphql/generated";
import { Variant } from "../../types/models/variant";

export class Pipe<Output> extends DataFlowWithCompatibleInput<Pipe.Props<Output>, Output> {
}

export namespace Pipe {
  export type Props<Output> = DataFlowWithProviderProps<Output>;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.PipePropsFragment): Props<any> {
      return DataFlowWithProviderProps.fromGraphQL(props, Variant.fromGraphQL);
    }
  }
}
