import * as React from "react";
import { FastPromise } from "./fastPromise";
import { List } from "immutable";

interface ChildrenConfig<State, Action> {
  state: State;
  schedule: StateMachine.ScheduleFunction<State, Action>;
  initializing: boolean;
  currentAction?: Action;
  error?: StateMachine.ErrorSummary<State, Action>;
}

interface StateMachineProps<State, Action> {
  initialState: (schedule: StateMachine.ScheduleFunction<State, Action>) => State;
  onInit?: (state: State, schedule: StateMachine.ScheduleFunction<State, Action>) => (void | (() => void | undefined));
  initializationAction?: (schedule: StateMachine.ScheduleFunction<State, Action>) => Action;

  fireAction: (state: State, action: Action) => FastPromise<State>;
  reviewState?: (state: State) => State;
  enqueue?: (actions: List<Action>, action: Action) => List<Action>;
  purgeQueue?: (actions: List<Action>) => List<Action>;

  children: (config: ChildrenConfig<State, Action>) => React.ReactElement  | null;
}

interface CurrentAction<State, Action> {
  action: Action;
  error?: StateMachine.ErrorSummary<State, Action>;
}

interface MachineState<State, Action> {
  initializing: boolean;
  currentState: State;
  currentAction: CurrentAction<State, Action> | undefined;
}

export function StateMachine<State, Action>(props: StateMachineProps<State, Action>): React.ReactElement | null {
  // This allows scheduling multiple synchronous actions in a single block of code - we can't rely on state in this
  // case since it won't be updated between calls to schedule().
  const machineStateRef = React.useRef<MachineState<State, Action>>();

  function getMachineState(): MachineState<State, Action> {
    if (!machineStateRef.current) {
      machineStateRef.current = {
        initializing: !!props.initializationAction,
        currentState: props.initialState((action) => scheduleCallback.current(action)),
        currentAction: undefined
      };
    }
    return machineStateRef.current;
  }

  const setMachineState = React.useState<MachineState<State, Action>>(getMachineState())[1];

  const queueRef = React.useRef<List<Action>>(List());

  React.useEffect(
    () => {
      const shutdown = props.onInit &&
        props.onInit(getMachineState().currentState, (action) => scheduleCallback.current(action));

      if (props.initializationAction) {
        schedule(props.initializationAction((action) => scheduleCallback.current(action)));
      }

      return shutdown;
    },
    []
  );

  function schedule(action: Action): void {
    if (getMachineState().currentAction) {
      queueRef.current = props.enqueue ? props.enqueue(queueRef.current, action) : queueRef.current.push(action);
    } else {
      fireAction(getMachineState().currentState, action);
    }
  }

  const scheduleCallback = React.useRef(schedule);
  scheduleCallback.current = schedule;

  function getNextAction(): Action | undefined {
    const result = queueRef.current.first(undefined);
    if (result !== undefined) {
      queueRef.current = queueRef.current.shift();
    }
    return result;
  }

  function fireNextAction(state: State): void {
    const nextAction = getNextAction();
    if (nextAction) {
      fireAction(state, nextAction);
    }
  }

  function purgeQueue(): void {
    queueRef.current = props.purgeQueue ? props.purgeQueue(queueRef.current) : List();
  }

  // State should only be reviewed before commit, because it will trigger side effects!
  function updateState(
    initializing: boolean | undefined,
    newState: State,
    newAction: (CurrentAction<State, Action> | undefined)  |
      ((currentMachineState: MachineState<State, Action>) => CurrentAction<State, Action> | undefined),
    reviewState: boolean
  ): void {
    machineStateRef.current = {
      initializing: initializing !== undefined ? initializing : getMachineState().initializing,
      currentState: props.reviewState && reviewState ? props.reviewState(newState) : newState,
      currentAction: typeof newAction === "function" ? newAction(getMachineState()) : newAction
    };
    setMachineState(machineStateRef.current);
  }

  function setError(state: State, action: Action, error: any): void {
    updateState(
      undefined,
      state,
      (current) => ({
        action,
        error: {
          error,
          tryAgain: () => fireAction(state, action),
          cancel: !current.initializing
            ? () => {
              updateState(false, state, undefined, true);
              purgeQueue();
              fireNextAction(state);
            }
            : undefined,
          cleanUp: current.initializing
            ? (cleanedUpStateFactory) => {
              updateState(true, state, { action }, false);
              cleanedUpStateFactory(state)
                .map((cleanedUpState) => {
                  updateState(true, cleanedUpState, { action }, false);
                  fireAction(cleanedUpState, action);
                })
                .recover((cleanUpError) => setError(state, action, cleanUpError));
            }
            : undefined
        }
      }),
      false
    );
  }

  function fireAction(state: State, action: Action): void {
    // console.log("[StateMachine] Firing", action);
    props.fireAction(state, action)
      .beforeAsync(() => updateState(undefined, state, { action }, false))
      .map((newState) => {
        updateState(false, newState, undefined, true);
        fireNextAction(newState);
      })
      .recover((error) => setError(state, action, error));
  }

  const machineState = getMachineState();

  return props.children({
    state: machineState.currentState,
    schedule,
    initializing: machineState.initializing,
    currentAction: machineState.currentAction && machineState.currentAction.action,
    error: machineState.currentAction && machineState.currentAction.error
  });
}

export namespace StateMachine {
  export type ScheduleFunction<State, Action> = (action: Action) => void;

  export interface ErrorSummary<State, Action> {
    error: any;
    tryAgain: () => void;
    cancel?: () => void;
    cleanUp?: (cleanedUpStateFactory: (state: State) => FastPromise<State>) => void;
  }
}

// type CalcState = number;
// type CalcAction = "add" | "subtract" | "reset" | "fail";
//
// function operation(f: () => CalcState): FastPromise<CalcState> {
//   return FastPromise(new Promise((resolve) => setTimeout(() => resolve(f()), 1000)));
// }
//
// function fireCalcAction(state: CalcState, action: CalcAction): FastPromise<CalcState> {
//   switch (action) {
//     case "add": return operation(() => state + 1);
//     case "subtract": return operation(() => state - 1);
//     case "reset": return operation(() => 0);
//
//     case "fail": {
//       return FastPromise(
//         new Promise((resolve, reject) =>
//           setTimeout(
//             () => {
//               if (state === 0) {
//                 reject("Oops!");
//               } else {
//                 resolve(state);
//               }
//             },
//             1000
//           )
//         )
//       );
//     }
//   }
// }
//
// export const StateMachineCalc: React.FunctionComponent = () => {
//   return (
//     <StateMachine<CalcState, CalcAction>
//       initialState={() => 0}
//       fireAction={fireCalcAction}
//       purgeQueue={(queue) => queue.filter((action) => action === "reset")}
//       // initializationAction={() => "fail"}
//     >
//       {
//         ({ state, schedule, initializing, currentAction, error }) => (
//           <Panel>
//             {initializing && <PanelRow>Initializing...</PanelRow>}
//             <PanelRow>Current value: {state}{currentAction && (" (" + currentAction + ")")}</PanelRow>
//             {error && (
//               <PanelRow>
//                 Error: {error.error}
//                 &nbsp;&nbsp;
//                 <Button color={"white"} size={"small"} onClick={error.tryAgain}>Try Again</Button>
//                 {error.cancel && (
//                   <>
//                     &nbsp;&nbsp;
//                     <Button color={"white"} size={"small"} onClick={error.cancel}>Cancel</Button>
//                   </>
//                 )}
//                 {error.cleanUp && (
//                   <>
//                     &nbsp;&nbsp;
//                     <Button
//                       color={"white"}
//                       size={"small"}
//                       // onClick={() => error.cleanUp && error.cleanUp(FastPromise.reject("Could not clean up!"))}
//                       onClick={() => error.cleanUp && error.cleanUp(() => FastPromise(1))}
//                     >
//                       Clean Up
//                     </Button>
//                   </>
//                 )}
//               </PanelRow>
//             )}
//             <PanelRow>
//               <Button color={"blue"} size={"small"} onClick={() => schedule("add")}>Add</Button>
//               &nbsp;&nbsp;
//               <Button color={"blue"} size={"small"} onClick={() => schedule("subtract")}>Subtract</Button>
//               &nbsp;&nbsp;
//               <Button color={"blue"} size={"small"} onClick={() => schedule("reset")}>Reset</Button>
//               &nbsp;&nbsp;
//               <Button color={"red"} size={"small"} onClick={() => schedule("fail")}>Fail</Button>
//             </PanelRow>
//           </Panel>
//         )
//       }
//     </StateMachine>
//   );
// };
