import { OffboardingProject } from "../../types/models/offboardingProject";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProjectFormData } from "../../types/models/offboardingProjectFormData";
import { InsertUpdateOffboardingProjectErrorHandler } from "./useInsertOffboardingProjectMutation";

type UpdateOffboardingProjectMutationHook = [
  (id: string, formData: OffboardingProjectFormData) => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export function useUpdateOffboardingProjectMutation(): UpdateOffboardingProjectMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateOffboardingProjectMutation,
    extract: (data: GraphQL.UpdateOffboardingProjectMutation) => nullToUndefined(data.updateOffboardingProject),
    complete: OffboardingProject.parse,
    catch: InsertUpdateOffboardingProjectErrorHandler
  });

  function fireWith(id: string, formData: OffboardingProjectFormData): Promise<OffboardingProject> {
    return fire({
      variables: { id, formData: OffboardingProjectFormData.toGraphQL(formData) },
      retry: () => fireWith(id, formData),
    });
  }

  return [fireWith, status];
}
