import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { MigrationNote } from "../../types/models/migrationNote";

type ReopenMigrationNoteMutationHook = [() => Promise<MigrationNote>, OperationStatus<MigrationNote>];

export function useReopenMigrationNoteMutation(noteId: number): ReopenMigrationNoteMutationHook {
  const [reopenMigrationNote, { status }] = useManagedMutation({
    mutation: GraphQL.useReopenMigrationNoteMutation,
    extract: (data: GraphQL.ReopenMigrationNoteMutation) => nullToUndefined(data.reopenMigrationNote),
    complete: (migrationNote) => MigrationNote.fromGraphQL(migrationNote)
  });

  function go(): Promise<MigrationNote> {
    return reopenMigrationNote({
      variables: { noteId },
      retry: go
    });
  }

  return [go, status];
}
