import * as React from "react";
import { useAppTheme } from "../../../../app/theme";
import { prepareTimeEstimate } from "../../../../utils/prepareTimeEstimate";

interface EstimatedTimeIndicatorProps {
  time: number;
}

export const EstimatedTimeIndicator: React.FunctionComponent<EstimatedTimeIndicatorProps> = (props) => {
  const theme = useAppTheme();

  const numberStyle: React.CSSProperties = {
    stroke: "none",
    fill: theme.colors.primary,
    textAnchor: "middle",
    fontSize: 8
  };

  const fractionStyle: React.CSSProperties = {
    textAnchor: "middle",
    fontSize: 4
  };

  const unitStyle: React.CSSProperties = {
    stroke: "none",
    fill: theme.colors.primary,
    textAnchor: "middle",
    fontSize: 1.3
  };

  const { value, fraction, unit } = prepareTimeEstimate(props.time);

  return (
    <svg width="100%" height="100%" viewBox={"0 0 10 10"}>
      <text x={5} y={7} style={numberStyle}>
        {value}
        {fraction && <tspan style={fractionStyle}>{fraction}</tspan>}
      </text>
      <text x={5} y={9} style={unitStyle}>
        {unit.toUpperCase()}
      </text>
    </svg>
  );
};
