import { GraphQL } from "../../services/graphql/generated";

export interface EmailDeliverySchedule {
  id: string;
  name: string;
  description: string;
  isTest: boolean;
}

export namespace EmailDeliverySchedule {
  export function parse(data: GraphQL.EmailDeliveryScheduleFragment): EmailDeliverySchedule {
    return data;
  }
}
