import * as React from "react";
import * as yup from "yup";
import { ReferralCode } from "../../../../types/models/referralCode";
import { FormLayout } from "../../../widgets/formLayout";
import { TextField } from "../../../widgets/textField";
import { TextAreaField } from "../../../widgets/textAreaField";
import { FormField } from "../../../widgets/formField";
import { friendlyDateTime } from "../../../../utils/formatting";
import { ReferralCodeFormData } from "../../../../types/models/referralCodeFormData";
import { FormConfig } from "../../../blocks/crud/formConfig";
import { ItemForm } from "../../../blocks/crud/itemForm";
import { CheckboxField } from "../../../widgets/checkboxField";
import { Random } from "../../../../utils/random";
import { DiscountType, DiscountTypeDefs } from "../../../../types/models/discountType";
import { DropDownField } from "../../../widgets/dropDownField";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import {
  ReferralCodeAnnouncementBannerVaultMeLogoVersion, ReferralCodeAnnouncementBannerVaultMeLogoVersionDefs
} from "../../../../types/enums/referralCodeAnnouncementBannerVaultMeLogoVersion";
import { RadioButtonGroupField } from "../../../widgets/radioButtonGroupField";
import { VaultMeLogo, VaultMeLogoColorSchema } from "../../../glyphs/vaultMeLogo";
import { styled } from "../../../../app/theme";
import { HamburgerClose, HamburgerCloseColor } from "../../../glyphs/hamburgerClose";
import {
  ReferralCodeAnnouncementButtonVersion,
  ReferralCodeAnnouncementButtonVersionDefs
} from "../../../../types/enums/referralCodeAnnouncementButtonVersion";
import { FileUploadController, ImageUploadPreview } from "../../../widgets/fileUpload";
import { FileUploadField } from "../../../widgets/fileUploadField";
import { Upload } from "../../../../types/models/upload";
import { ColorSample } from "views/widgets/colorSample";
import { Button } from "../../../widgets/button";
import { Announcement, Beacon } from "../../../blocks/referralCodeIndication";
import { useRoutes } from "../../../../app/routes/useRoutes";
import { YupTests } from "../../../../utils/yupTests";

interface FormData {
  readonly code: string;

  readonly notes: string;
  readonly isEnabled: boolean;
  readonly url: string;
  readonly endUserAccess: string;

  readonly discount: string;
  readonly discountType: DiscountType;
  readonly revenueShare: string;

  readonly announcementEnabled: boolean;

  readonly announcementBannerBackground: string;
  readonly announcementBannerVaultMeLogoVersion: ReferralCodeAnnouncementBannerVaultMeLogoVersion;
  readonly announcementBannerOrganizationLogo: Upload | undefined;
  readonly announcementBannerOrganizationLogoWidth: string;
  readonly announcementBannerOrganizationLogoOffsetXRow: string;
  readonly announcementBannerOrganizationLogoOffsetYRow: string;
  readonly announcementBannerOrganizationLogoOffsetXColumn: string;
  readonly announcementBannerOrganizationLogoOffsetYColumn: string;

  readonly announcementTitleBackground: string;
  readonly announcementTitle: string;

  readonly announcementBodyBackground: string;
  readonly announcementBodyDesktop: string;
  readonly announcementBodyMobile: string;

  readonly announcementButtonColor: string;
  readonly announcementButtonVersion: ReferralCodeAnnouncementButtonVersion;

  readonly beaconEnabled: boolean;
  readonly beaconLogo: Upload | undefined;
  readonly beaconLogoWidth: string;
}

namespace FormData {
  export function makeBlank(): FormData {
    return {
      code: new Random().alphanumeric(8).toUpperCase(),

      notes: "",
      isEnabled: true,
      url: "",
      endUserAccess: "",

      discount: "10",
      discountType: DiscountType.BasePriceOnly,
      revenueShare: "10",

      announcementEnabled: false,

      announcementBannerBackground: "#666666",
      announcementBannerVaultMeLogoVersion: ReferralCodeAnnouncementBannerVaultMeLogoVersion.DarkBlueAndBrightBlue,
      announcementBannerOrganizationLogo: undefined,
      announcementBannerOrganizationLogoWidth: "13",
      announcementBannerOrganizationLogoOffsetXRow: "0",
      announcementBannerOrganizationLogoOffsetYRow: "0",
      announcementBannerOrganizationLogoOffsetXColumn: "0",
      announcementBannerOrganizationLogoOffsetYColumn: "0",

      announcementTitleBackground: "#F5F5F5",
      announcementTitle: "Welcome!",

      announcementBodyBackground: "#FFFFFF",
      announcementBodyDesktop: "(blank)",
      announcementBodyMobile: "",

      announcementButtonColor: "#FB5603",
      announcementButtonVersion: ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndWhiteText,

      beaconEnabled: false,
      beaconLogo: undefined,
      beaconLogoWidth: "11.5",
    };
  }

  export function make(referralCode: ReferralCode): FormData {
    return {
      code: referralCode.code,

      notes: referralCode.notes || "",
      isEnabled: referralCode.isEnabled,
      url: referralCode.url || "",
      endUserAccess: referralCode.endUserAccess || "",

      discount: referralCode.discount.toString(),
      discountType: referralCode.discountType,
      revenueShare: referralCode.revenueShare.toString(),

      announcementEnabled: referralCode.announcement.enabled,

      announcementBannerBackground: referralCode.announcement.bannerBackground,
      announcementBannerVaultMeLogoVersion: referralCode.announcement.bannerVaultMeLogoVersion,
      announcementBannerOrganizationLogo: referralCode.announcementBannerOrganizationLogo,
      announcementBannerOrganizationLogoWidth: referralCode.announcement.bannerOrganizationLogoWidth.toString(),
      announcementBannerOrganizationLogoOffsetXRow:
        referralCode.announcement.bannerOrganizationLogoOffsetXRow.toString(),
      announcementBannerOrganizationLogoOffsetYRow:
        referralCode.announcement.bannerOrganizationLogoOffsetYRow.toString(),
      announcementBannerOrganizationLogoOffsetXColumn:
        referralCode.announcement.bannerOrganizationLogoOffsetXColumn.toString(),
      announcementBannerOrganizationLogoOffsetYColumn:
        referralCode.announcement.bannerOrganizationLogoOffsetYColumn.toString(),

      announcementTitleBackground: referralCode.announcement.titleBackground,
      announcementTitle: referralCode.announcement.title,

      announcementBodyBackground: referralCode.announcement.bodyBackground,
      announcementBodyDesktop: referralCode.announcement.bodyDesktop,
      announcementBodyMobile: referralCode.announcement.bodyMobile,

      announcementButtonColor: referralCode.announcement.buttonColor,
      announcementButtonVersion: referralCode.announcement.buttonVersion,

      beaconEnabled: referralCode.beacon.enabled,
      beaconLogo: referralCode.beaconLogo,
      beaconLogoWidth: referralCode.beacon.logoWidth.toString()
    };
  }

  export function toResult(formData: FormData): ReferralCodeFormData {
    const trimmedNotes = formData.notes.trim();
    const trimmedUrl = formData.url.trim();
    const trimmedEndUserAccess = formData.endUserAccess.trim();

    return {
      code: formData.code.trim(),

      notes: trimmedNotes.length !== 0 ? trimmedNotes : undefined,
      isEnabled: formData.isEnabled,
      url: trimmedUrl.length !== 0 ? trimmedUrl : undefined,
      endUserAccess: trimmedEndUserAccess.length !== 0 ? trimmedEndUserAccess : undefined,

      discount: Number.parseFloat(formData.discount),
      discountType: formData.discountType,
      revenueShare: Number.parseFloat(formData.revenueShare),

      announcement: {
        enabled: formData.announcementEnabled,

        bannerBackground: formData.announcementBannerBackground,
        bannerVaultMeLogoVersion: formData.announcementBannerVaultMeLogoVersion,
        bannerOrganizationLogoId: formData.announcementBannerOrganizationLogo?.id,
        bannerOrganizationLogoWidth: Number.parseFloat(formData.announcementBannerOrganizationLogoWidth),
        bannerOrganizationLogoOffsetXRow: Number.parseFloat(formData.announcementBannerOrganizationLogoOffsetXRow),
        bannerOrganizationLogoOffsetYRow: Number.parseFloat(formData.announcementBannerOrganizationLogoOffsetYRow),
        bannerOrganizationLogoOffsetXColumn:
          Number.parseFloat(formData.announcementBannerOrganizationLogoOffsetXColumn),
        bannerOrganizationLogoOffsetYColumn:
          Number.parseFloat(formData.announcementBannerOrganizationLogoOffsetYColumn),

        titleBackground: formData.announcementTitleBackground,
        title: formData.announcementTitle,

        bodyBackground: formData.announcementBodyBackground,
        bodyDesktop: formData.announcementBodyDesktop,
        bodyMobile: formData.announcementBodyMobile,

        buttonColor: formData.announcementButtonColor,
        buttonVersion: formData.announcementButtonVersion
      },

      beacon: {
        enabled: formData.beaconEnabled,
        logoId: formData.beaconLogo?.id,
        logoWidth: Number.parseFloat(formData.beaconLogoWidth)
      }
    };
  }

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    code: yup.string()
      .required("Code is required")
      .min(5, "Code must be at least 5 characters long")
      .max(20, "Code must be at most 20 characters long")
      .test(
        "allowedCharacters",
        "Code must only contain letters and digits",
        (code) => /^\w+$/.test(code)
      ),

    notes: yup.string(),
    isEnabled: yup.boolean(),
    url: yup.string(),
    endUserAccess: yup.string(),

    discount: yup.string().test(
      "maxDigitsAfterDecimal",
      "Discount must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    discountType: yup.mixed().oneOf(DiscountTypeDefs.Values),
    revenueShare: yup.string().test(
      "maxDigitsAfterDecimal",
      "Revenue share must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),

    announcementEnabled: yup.boolean(),

    announcementBannerBackground: yup.string().required(),
    announcementBannerVaultMeLogoVersion: yup.mixed()
      .oneOf(ReferralCodeAnnouncementBannerVaultMeLogoVersionDefs.Values).required(),
    announcementBannerOrganizationLogo: yup.mixed().notRequired(),
    announcementBannerOrganizationLogoWidth: yup.string().test(YupTests.twoDigitalPlacesFloat(true)),
    announcementBannerOrganizationLogoOffsetXRow: yup.string().test(YupTests.twoDigitalPlacesFloat(true)),
    announcementBannerOrganizationLogoOffsetYRow: yup.string().test(YupTests.twoDigitalPlacesFloat(true)),
    announcementBannerOrganizationLogoOffsetXColumn: yup.string().test(YupTests.twoDigitalPlacesFloat(true)),
    announcementBannerOrganizationLogoOffsetYColumn: yup.string().test(YupTests.twoDigitalPlacesFloat(true)),

    announcementTitleBackground: yup.string().required(),
    announcementTitle: yup.string().required(),

    announcementBodyBackground: yup.string().required(),
    announcementBodyDesktop: yup.string().required(),
    announcementBodyMobile: yup.string(),

    announcementButtonColor: yup.string().required(),
    announcementButtonVersion: yup.mixed().oneOf(ReferralCodeAnnouncementButtonVersionDefs.Values).required(),

    beaconEnabled: yup.boolean(),
    beaconLogo: yup.mixed().notRequired(),
    beaconLogoWidth: yup.string().test(YupTests.twoDigitalPlacesFloat(true))
  });
}

interface Props {
  config: FormConfig<ReferralCode, ReferralCodeFormData>;

  fileUploadController: FileUploadController;
}

export const ReferralCodeForm: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  const [showAnnouncementPreview, setShowAnnouncementPreview] = React.useState(false);
  const [showBeaconPreview, setShowBeaconPreview] = React.useState(false);

  return (
    <ItemForm<ReferralCode, FormData, ReferralCodeFormData>
      config={props.config}

      subject={"referral code"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.makeBlank()}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, formProps, submitting }) => (
        <>
          {showAnnouncementPreview && formProps.values.announcementBannerOrganizationLogo && (
            <Announcement
              previewLayout={true}
              announcementBannerOrganizationLogoId={formProps.values.announcementBannerOrganizationLogo.id}
              announcementConfig={FormData.toResult(formProps.values).announcement}
              onClose={() => setShowAnnouncementPreview(false)}
            />
          )}
          {showBeaconPreview && formProps.values.beaconLogo && (
            <Beacon
              src={routes.api.downloadUrl(formProps.values.beaconLogo.id)}
              width={Number.parseFloat(formProps.values.beaconLogoWidth)}
              isClickable={true}
              onClick={() => setShowBeaconPreview(false)}
            />
          )}
          <Block>
            <Panel>
              <TitlePanelRow>Referral Code</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Code"}
                    name={"code"}
                    required={true}
                    maxLength={20}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
                  />
                  {!props.config.readOnly && (
                    <TextAreaField<FormData>
                      label={"Internal notes"}
                      name={"notes"}
                      disabled={submitting}
                    />
                  )}
                  <CheckboxField
                    label={"Enabled"}
                    name={"isEnabled"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"URL"}
                    name={"url"}
                    maxLength={255}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"End user access"}
                    name={"endUserAccess"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  {
                    props.config.item && !props.config.isNew &&
                      <FormField>Created at: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                  }
                </FormLayout>
              </PanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Discount (%)"}
                    name={"discount"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    autoComplete={false}
                    dirtyRegExp={/^[\.\d]*$/}
                  />
                  <DropDownField<FormData>
                    label={"Discount type"}
                    name={"discountType"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  >
                    <option value={DiscountType.BasePriceOnly}>Migration fee only</option>
                    <option value={DiscountType.BasePriceAndDataFees}>Migration fee and data fees</option>
                  </DropDownField>
                  <TextField<FormData>
                    label={"Revenue share (%)"}
                    name={"revenueShare"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    autoComplete={false}
                    dirtyRegExp={/^[\.\d]*$/}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>
                Benefit Announcement&nbsp;&nbsp;
                <Button
                  color={"secondary"}
                  size={"small"}
                  disabled={!formProps.values.announcementBannerOrganizationLogo}
                  onClick={() => setShowAnnouncementPreview((value) => !value)}
                >
                  {showAnnouncementPreview ? "Hide" : "Show"} Preview
                </Button>
              </TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <CheckboxField
                    label={"Enabled"}
                    name={"announcementEnabled"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Banner: Background"}
                    name={"announcementBannerBackground"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={50}
                    appendix={<ColorSample color={formProps.values.announcementBannerBackground}/>}
                  />
                  <RadioButtonGroupField<FormData>
                    label={"Banner: VaultMe logo version"}
                    name={"announcementBannerVaultMeLogoVersion"}
                    options={[
                      [
                        ReferralCodeAnnouncementBannerVaultMeLogoVersion.DarkBlueAndBrightBlue,
                        <VaultMeLogoPreview
                          key={ReferralCodeAnnouncementBannerVaultMeLogoVersion.DarkBlueAndBrightBlue}
                          background={formProps.values.announcementBannerBackground || "#aaaaaa"}
                          logoColorSchema={"black-and-blue"}
                          closeButtonColor={"black"}
                        />
                      ],
                      [
                        ReferralCodeAnnouncementBannerVaultMeLogoVersion.White,
                        <VaultMeLogoPreview
                          key={ReferralCodeAnnouncementBannerVaultMeLogoVersion.White}
                          background={formProps.values.announcementBannerBackground || "#aaaaaa"}
                          logoColorSchema={"white"}
                          closeButtonColor={"white"}
                        />
                      ],
                      [
                        ReferralCodeAnnouncementBannerVaultMeLogoVersion.WhiteAndBrightBlue,
                        <VaultMeLogoPreview
                          key={ReferralCodeAnnouncementBannerVaultMeLogoVersion.WhiteAndBrightBlue}
                          background={formProps.values.announcementBannerBackground || "#aaaaaa"}
                          logoColorSchema={"white-and-blue"}
                          closeButtonColor={"white"}
                        />
                      ],
                    ]}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <FileUploadField<FormData>
                    label={"Banner: Organization logo"}
                    name={"announcementBannerOrganizationLogo"}
                    controller={props.fileUploadController}
                    renderPreview={(id) =>
                      <AnnouncementBannerOrganizationLogoPreview
                        id={id}
                        background={formProps.values.announcementBannerBackground || "#aaaaaa"}
                      />
                    }
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Banner: Organization logo width (rem)"}
                    name={"announcementBannerOrganizationLogoWidth"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                  <TextField<FormData>
                    label={"Banner: Organization logo offset by X in a row layout (rem)"}
                    name={"announcementBannerOrganizationLogoOffsetXRow"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                  <TextField<FormData>
                    label={"Banner: Organization logo offset by Y in a row layout (rem)"}
                    name={"announcementBannerOrganizationLogoOffsetYRow"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                  <TextField<FormData>
                    label={"Banner: Organization logo offset by X in a column layout (rem)"}
                    name={"announcementBannerOrganizationLogoOffsetXColumn"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                  <TextField<FormData>
                    label={"Banner: Organization logo offset by Y in a column layout (rem)"}
                    name={"announcementBannerOrganizationLogoOffsetYColumn"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                </FormLayout>
              </PanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Title: Background"}
                    name={"announcementTitleBackground"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={50}
                    appendix={<ColorSample color={formProps.values.announcementTitleBackground}/>}
                  />
                  <TextField<FormData>
                    label={"Title"}
                    name={"announcementTitle"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Body: Background"}
                    name={"announcementBodyBackground"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={50}
                    appendix={<ColorSample color={formProps.values.announcementBodyBackground}/>}
                  />
                  <TextAreaField<FormData>
                    label={"Body (desktop)"}
                    name={"announcementBodyDesktop"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    rows={10}
                  />
                  <TextAreaField<FormData>
                    label={"Body (mobile, optional)"}
                    name={"announcementBodyMobile"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    rows={10}
                  />
                </FormLayout>
              </PanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Button: \"Explore VaultMe Now\" color"}
                    name={"announcementButtonColor"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={50}
                    appendix={<ColorSample color={formProps.values.announcementButtonColor}/>}
                  />
                  <RadioButtonGroupField<FormData>
                    label={"Button: version"}
                    name={"announcementButtonVersion"}
                    options={[
                      [
                        ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndBlackText,
                        <ActionButtonPreview
                          key={ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndBlackText}
                          backgroundColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          borderColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          textColor={"black"}
                        >
                          Explore VaultMe Now
                        </ActionButtonPreview>
                      ],
                      [
                        ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndWhiteText,
                        <ActionButtonPreview
                          key={ReferralCodeAnnouncementButtonVersion.ColoredBackgroundAndWhiteText}
                          backgroundColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          borderColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          textColor={"white"}
                        >
                          Explore VaultMe Now
                        </ActionButtonPreview>
                      ],
                      [
                        ReferralCodeAnnouncementButtonVersion.NoBackgroundAndBlackText,
                        <ActionButtonPreview
                          key={ReferralCodeAnnouncementButtonVersion.NoBackgroundAndBlackText}
                          backgroundColor={"none"}
                          borderColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          textColor={"black"}
                        >
                          Explore VaultMe Now
                        </ActionButtonPreview>
                      ],
                      [
                        ReferralCodeAnnouncementButtonVersion.NoBackgroundAndColoredText,
                        <ActionButtonPreview
                          key={ReferralCodeAnnouncementButtonVersion.NoBackgroundAndColoredText}
                          backgroundColor={"none"}
                          borderColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                          textColor={formProps.values.announcementButtonColor || "#aaaaaa"}
                        >
                          Explore VaultMe Now
                        </ActionButtonPreview>
                      ],
                    ]}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>
                Benefit Beacon&nbsp;&nbsp;
                <Button
                  color={"secondary"}
                  size={"small"}
                  disabled={!formProps.values.beaconLogo}
                  onClick={() => setShowBeaconPreview((value) => !value)}
                >
                  {showBeaconPreview ? "Hide" : "Show"} Preview
                </Button>
              </TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <CheckboxField
                    label={"Enabled"}
                    name={"beaconEnabled"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <FileUploadField<FormData>
                    label={"Beacon: Organization logo"}
                    name={"beaconLogo"}
                    controller={props.fileUploadController}
                    renderPreview={(id) => <ImageUploadPreview id={id}/>}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Beacon: Width (rem)"}
                    name={"beaconLogoWidth"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={10}
                    dirtyRegExp={/^\-?[\.\d]*$/}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
        </>
      )}
    </ItemForm>
  );
};

interface VaultMeLogoPreviewProps {
  background: string;
  logoColorSchema: VaultMeLogoColorSchema;
  closeButtonColor: HamburgerCloseColor;
}

const VaultMeLogoPreview: React.FunctionComponent<VaultMeLogoPreviewProps> = (props) => (
  <VaultMeLogoPreviewLayout background={props.background}>
    <VaultMeLogo colorSchema={props.logoColorSchema} ignoreTheme={true}/>
    <ForWord>for</ForWord>
    <StyledHamburgerClose color={props.closeButtonColor}/>
  </VaultMeLogoPreviewLayout>
);

const VaultMeLogoPreviewLayout = styled.div<{ background: string }>`
  display: flex;
  background: ${(props) => props.background || "none"};
  height: 3rem;
  padding: .5rem;
`;

const ForWord = styled.div`
  font-size: 2.4rem;
  padding: 0.7rem 0 0 1rem;
  color: #ffffff;
`;

const StyledHamburgerClose = styled(HamburgerClose)`
  width: 2rem;
  height: 2rem;
  margin-left: 4rem;
`;

const ActionButtonPreview = styled.div<{ backgroundColor: string, borderColor: string, textColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 0.15rem;

  color: ${(props) => props.textColor};
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;

  padding: 0.8rem 1.6rem;
  min-width: 16rem;
`;

interface AnnouncementBannerOrganizationLogoPreviewProps {
  id: string;
  background: string;
}

const AnnouncementBannerOrganizationLogoPreview
  : React.FunctionComponent<AnnouncementBannerOrganizationLogoPreviewProps> = (props) => (
  <AnnouncementBannerOrganizationLogoPreviewLayout background={props.background}>
    <ImageUploadPreview id={props.id}/>
  </AnnouncementBannerOrganizationLogoPreviewLayout>
);

const AnnouncementBannerOrganizationLogoPreviewLayout = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  text-align: center;
  padding: 1rem;
`;
