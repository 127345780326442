import * as React from "react";
import { styled } from "../../../app/theme";
import { Button } from "../../widgets/button";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { CloudService } from "../../../types/models/cloudService";

const ButtonContainer = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => props.fullWidth ? 100 : 50}%;
  padding: .75rem;
  box-sizing: border-box;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 100%;
    padding: .5rem;
  }
`;

const StyledCloudServiceButton = styled(Button).attrs((props: CloudServiceButtonProps) => ({
  title: props.cloudService.name
}))<CloudServiceButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.cloudService.description ? "normal" : "center"};
  padding: ${(props) => props.cloudService.description ? .75 : .5}rem 1.2rem;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.cloudService.color};
  width: 100%;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: ${(props) => props.cloudService.description ? .6 : .4}rem 0.5rem;
    font-size: 1.2rem;
    flex-direction: column;
  }
`;

const Icon = styled.img`
  height: 2rem;
  max-width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: ${2 * 5.4}rem;
  height: 2rem;
  max-width: 100%;
`;

const Description = styled.div`
  white-space: normal;
  font-size: .9rem;
  text-align: left;
  color: ${(props) => props.theme.colors.darkGray};
  margin-left: 1.5rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin-left: 0;
    margin-top: .3rem;
  }
`;

interface CloudServiceButtonProps extends StyledComponentsProps {
  cloudService: CloudService;
  onClick: () => void;
}

const InternalCloudServiceButton: React.FunctionComponent<CloudServiceButtonProps> = (props) => (
  <ButtonContainer fullWidth={!!props.cloudService.description}>
    <StyledCloudServiceButton cloudService={props.cloudService} onClick={props.onClick} className={props.className}>
      <IconContainer>
        <Icon src={props.cloudService.logo} alt={props.cloudService.name}/>
      </IconContainer>
      {props.cloudService.description && <Description>{props.cloudService.description}</Description>}
    </StyledCloudServiceButton>
  </ButtonContainer>
);

export const CloudServiceButton = styled(InternalCloudServiceButton)``;
