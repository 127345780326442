import * as React from "react";
import { Iteration } from "../../../../types/models/iteration";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { Migration } from "../../../../types/models/migration";
import { MigrationStatus } from "../../../../types/models/migrationStatus";
import { friendlyDateTime, approximateDurationBetween } from "../../../../utils/formatting";
import { Button } from "../../../widgets/button";
import { ModalController, useModal } from "../../../layouts/modal";
import { IterationsHistoryTool } from "./iterationsHistoryTool";
import { IterationsHistoryControllerType } from "./iterationToolsRow";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import { SyncIcon } from "../../../glyphs/syncIcon";

interface Props {
  migration: Migration;
  iterationIndex: number | undefined;
  iteration: Iteration;

  iterationsHistoryController: IterationsHistoryControllerType;

  onIterationSelect: (iterationIndex: number | undefined) => void;
}

interface Content {
  description: React.ReactNode;
  appendix: React.ReactNode;
  switchTo: "totals" | "latest";
}

export const IterationStatsRow: React.FunctionComponent<Props> = (props) => {
  const iterationsHistoryModal = useModal();

  const completedAt = props.migration.iterationCompletedAt || props.migration.completedAt;
  const timePassed = completedAt && approximateDurationBetween(new Date(), completedAt);

  function buildContent(): Content | null {
    const currentlyRunning = props.migration.status !== MigrationStatus.Completed;
    const runningPrefix =
      "VaultMe is currently syncing up the changes that have been made in your source account" +
      (timePassed ? " " + timePassed : "") +
      " with the destination account...";

    if (props.migration.iteration > 0 && props.migration.completedAt) {
      if (currentlyRunning && props.iterationIndex === props.migration.iteration) {
        return {
          description: runningPrefix,
          appendix: null,
          switchTo: "totals"
        };
      } else if (currentlyRunning && props.iterationIndex === undefined) {
        return {
          description: runningPrefix,
          appendix: "Below are the totals for the ENTIRE migration.",
          switchTo: "latest"
        };
      } else if (props.iterationIndex === 0) {
        return {
          description: "Below are the results of the initial migration completed on " +
            friendlyDateTime(props.migration.completedAt, true),
          appendix: null,
          switchTo: "totals"
        };
      } else if (props.iterationIndex !== undefined && props.iterationIndex > 0 && props.iteration.completedAt) {
        return {
          description: "Below are the results of the sync-up migration #" + props.iterationIndex +
            " completed on " + friendlyDateTime(props.iteration.completedAt, true),
          appendix: null,
          switchTo: "totals"
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const content = buildContent();

  if (content) {
    const { description, appendix, switchTo } = content;
    return (
      <>
        <ModalController
          modal={iterationsHistoryModal}
          wide={true}
          render={(close) => React.createElement(props.iterationsHistoryController, {
            render: (viewProps) => (
              <IterationsHistoryTool
                migration={props.migration}
                status={viewProps.status}
                onViewDetailsClick={(iterationIndex) => {
                  close();
                  props.onIterationSelect(iterationIndex);
                }}
              />
            )
          })}
        />

        <ToolsRow
          status={WidgetStatus.Info}
          icon={<SyncIcon/>}
          header={description}
          appendix={appendix}
        >
          <Button
            color={"white"}
            size={"small"}
            onClick={() =>
              props.onIterationSelect(switchTo === "totals" ? undefined : props.migration.iteration)
            }
          >
            {switchTo === "totals" ? "View Migration Totals" : "View Sync-Up Status"}
          </Button>
          <Button color={"white"} size={"small"} onClick={iterationsHistoryModal.open}>View Sync-Up History</Button>
        </ToolsRow>
      </>
    );
  } else {
    return null;
  }
};
