import * as React from "react";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

export type BreadcrumbsContent = React.ReactNode | React.ReactNode[];

interface Props extends StyledComponentsProps {
  content: BreadcrumbsContent;
}

const StyledDiv = styled.div`
  color: ${(props) => props.theme.colors.textColor};
`;

export const Breadcrumbs: React.FunctionComponent<Props> = (props) => {
  if (Array.isArray(props.content)) {
    const array = props.content as React.ReactNode[];
    const content = array.map((value, index) =>
      <span key={index}>{value}{index < array.length - 1 && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}</span>
    );
    return <StyledDiv className={props.className}>{content}</StyledDiv>;
  } else {
    return <StyledDiv className={props.className}>{props.content}</StyledDiv>;
  }
};
