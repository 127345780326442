import * as React from "react";
import { Block } from "views/containers/block";
import { Panel } from "views/containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { ReferralCode } from "../../../../types/models/referralCode";
import { FormLayout } from "../../../widgets/formLayout";
import { FormField } from "../../../widgets/formField";
import { TextBox } from "../../../widgets/textBox";
import { Constants } from "../../../../app/constants";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { Button } from "../../../widgets/button";
import { styled } from "../../../../app/theme";
import {
  ClipboardCopyIndicator,
  copyTextToClipboard,
  useClipboardCopyIndicator
} from "../../../utils/copyTextToClipboard";
import { EarningsSourceDetails } from "./earningsSourceDetails";
import { useRoutes } from "../../../../app/routes/useRoutes";
import { Markdown } from "../../../widgets/markdown";
import { DiscountType } from "../../../../types/models/discountType";

function makeLink(referralCode: ReferralCode, url: string): string {
  try {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.append(Constants.QueryParams.ReferralCode, referralCode.code);
    return parsedUrl.toString();
  } catch {
    return "(Invalid URL)";
  }
}

interface Props {
  organizationAlias: string;
  readOnly: boolean;
  referralCode: ReferralCode;
}

export const ReferralCodeDashboard: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();
  const indicator = useClipboardCopyIndicator();
  const link = makeLink(props.referralCode, props.referralCode.url || Constants.Product.marketingWebSiteUrl);

  return (
    <EarningsSourceDetails
      name={props.referralCode.code}
      sourceType={"Manual Member Benefit Referral Code"}
      entityType={"Member Benefit Referral Code"}
      endUserDiscount={
        props.referralCode.discount + "% off Migration Fee" +
        (props.referralCode.discountType === DiscountType.BasePriceAndDataFees ? " & Data" : "")
      }
      revenueShare={props.referralCode.revenueShare}
      endUserAccess={
        props.referralCode.endUserAccess
          ? <Markdown>{props.referralCode.endUserAccess}</Markdown>
          : (
            <>
              End Users may participate by clicking the Referral Link or inputting
              the Referral Code at checkout while purchasing a Qualified VaultMe Migration.
            </>
          )
      }
      isEnabled={props.referralCode.isEnabled}
      detailsPath={
        props.readOnly
          ? undefined
          : routes.organizationPortalRoutes
            .referralCodes(props.organizationAlias)
            .editPath(props.referralCode.id)
      }
    >
      <PanelRow>
        {props.referralCode.isEnabled && (
          <FormLayout noBottomMargin={true}>
            <FormField label={"Member Benefit Referral Link"} limitWidth={true}>
              <TextBox readOnly={true} value={link}/>
              <StyledSimpleToolbar>
                <Button
                  size={"small"}
                  color={"secondary"}
                  onClick={() => {
                    copyTextToClipboard(link);
                    indicator.show();
                  }}
                >
                  Copy To Clipboard
                </Button>
                <ClipboardCopyIndicator indicator={indicator}/>
              </StyledSimpleToolbar>
            </FormField>
          </FormLayout>
        )}
      </PanelRow>
    </EarningsSourceDetails>
  );
};

const StyledSimpleToolbar = styled(SimpleToolbar)`
  margin-top: .5rem;
`;
