import * as React from "react";
import { ReviewData } from "./reviewData";
import { Images } from "../../../../app/images";
import { Constants } from "../../../../app/constants";
import { EmailSupportLink } from "../../../widgets/emailSupportLink";

export interface CarouselStep {
  image: any;
  title: string;
  subTitle: React.ReactNode;
  reviews: ReviewData[];
}

export const CarouselSteps: CarouselStep[] = [
  {
    image: Images.Carousel.A,
    title: `Welcome to ${Constants.Product.name}!`,
    subTitle:
      `With just a few clicks you can copy, migrate, merge, or back up your
      work, school, or personal email accounts. But first, take a moment to
      learn how ${Constants.Product.name} works.`,
    reviews: [
      ReviewData.victor,
      ReviewData.colin,
      ReviewData.brianna
    ]
  },
  {
    image: Images.Carousel.B,
    title: "Simple to Set Up",
    subTitle:
      `On the coming pages you'll set up your auto-migration. Safely connect
      your accounts, make choices, and get comfortable that everything will
      work before you pay.`,
    reviews: [
      ReviewData.jonathan,
      ReviewData.fitz,
      ReviewData.audrey
    ]
  },
  {
    image: Images.Carousel.C,
    title: "Fast & Secure Migrations",
    subTitle:
      `${Constants.Product.name} doesn't use your computer or device. It uses
      ultra-secure cloud-based services that auto-scale to get you the
      fastest migration possible, even for your biggest accounts.`,
    reviews: [
      ReviewData.hoku,
      ReviewData.dacia,
      ReviewData.jennifer
    ]
  },
  {
    image: Images.Carousel.D,
    title: "Automated Status Notifications",
    subTitle:
      `${Constants.Product.name} will auto-email you when your migration
      starts and when it's done. In the rare instance of a problem, it will
      auto-email you telling you exactly what to do.`,
    reviews: [
      ReviewData.sabri,
      ReviewData.lili,
      ReviewData.katie
    ]
  },
  {
    image: Images.Carousel.E,
    title: "Once Started, You Can Unplug",
    subTitle:
      `Once your migration starts, ${Constants.Product.name} works
      continuously in the cloud until it's done. You can turn off your
      device, close your browser, sit back and relax.`,
    reviews: [
      ReviewData.natasha,
      ReviewData.keith,
      ReviewData.jessica
    ]
  },
  {
    image: Images.Carousel.F,
    title: "OK, You're Ready to Begin",
    subTitle: (
      <React.Fragment>
        We hope you'll love migrating your account with {Constants.Product.name} as
        much as we love crafting it for you. Got an idea or need help? Reach out
        anytime to <EmailSupportLink/>
      </React.Fragment>
    ),
    reviews: [
      ReviewData.amy,
      ReviewData.evelyn,
      ReviewData.lisette
    ]
  },
];
