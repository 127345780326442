import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";

type DeleteOrganizationPortalUserMutationHook = [(id: number) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteOrganizationPortalUserMutation(): DeleteOrganizationPortalUserMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteOrganizationPortalUserMutation,
    extract: (data: GraphQL.DeleteOrganizationPortalUserMutation) => nullToUndefined(data.deleteOrganizationPortalUser),
    complete: identity
  });

  function fireWith(id: number): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = OrganizationPortalUser.cacheId(id);

      const organizationPortalUser = apolloClient.cache.readFragment<GraphQL.OrganizationPortalUserFragment>({
        id: cacheId,
        fragment: GraphQL.OrganizationPortalUserFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: OrganizationPortalUser.fragmentName
      });

      if (organizationPortalUser) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.OrganizationPortalUserFragmentDoc,
          fragmentName: OrganizationPortalUser.fragmentName,
          data: { ...organizationPortalUser, id: -1 }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
