import * as React from "react";
import { styled, css, AppTheme } from "../../app/theme";
import { Checkbox, CheckboxLabel, HiddenCheckbox, VisibleCheckboxLayout } from "../widgets/checkbox";
import { FieldProps } from "formik";
import { StyledComponentsProps } from "../utils/styledComponentsProps";
import { PanelRow, PanelRowDefs, PanelRowIcon } from "../containers/rows/panelRow";
import { WorkStatus } from "../models/workStatus";
import { ActionItem, ActionItems } from "../models/actionItem";
import { ThemeContext } from "styled-components";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { ProgressIndicator } from "../widgets/progressIndicator";
import { ActionItemView, NotificationView } from "./actionItemView";

export namespace ConnectionPanelRowDefs {
  export interface Content {
    summary?: React.ReactNode;
    actionItems?: ActionItems;
  }
}

const SummaryItem = styled.div`
  font-size: .85rem;
`;

const IssuesContainer = styled.div`
  margin-top: .4rem;
  
  ${SummaryItem} + & {
    margin-top: .6rem;
  }
  
  ${ActionItemView} {
    margin-top: .8rem;
  }
`;

interface IssueListProps {
  actionItems: ActionItems;
  suppressing: ActionItems.Suppressing | undefined;
}

const IssueList: React.FunctionComponent<IssueListProps> = (props) => {
  function renderActionItem(actionItem: ActionItem) {
    return (
      <ActionItemView
        key={actionItem.id}
        actionItem={actionItem}
        suppressed={
          !!props.suppressing && (
            props.suppressing.suppressed.contains(actionItem.id) ||
            !!actionItem.masterActionItemId && props.suppressing.suppressed.contains(actionItem.masterActionItemId)
          )
        }
        onSuppress={props.suppressing && (() => props.suppressing && props.suppressing.onSuppress(actionItem.id))}
        onReActivate={props.suppressing && (() => props.suppressing && props.suppressing.onReActivate(actionItem.id))}
      />
    );
  }

  const grouped = ActionItems.grouped(props.actionItems);

  return (
    <>
      {!grouped.errors.isEmpty() && <IssuesContainer>{grouped.errors.map(renderActionItem)}</IssuesContainer>}
      {!grouped.warnings.isEmpty() && <IssuesContainer>{grouped.warnings.map(renderActionItem)}</IssuesContainer>}
      {!grouped.assignments.isEmpty() && <IssuesContainer>{grouped.assignments.map(renderActionItem)}</IssuesContainer>}
      {
        !grouped.notifications.isEmpty() &&
        <IssuesContainer>
          {
            grouped.notifications.map((notification) => (
              <NotificationView key={notification.id} actionItem={notification}/>
            ))
          }
        </IssuesContainer>
      }
    </>
  );
};

// function backgroundColor(issues: Issues, theme: AppTheme): string {
//   const { errors, warnings } = Issue.stats(issues);
//   if (errors !== 0) {
//     return theme.colors.subtleRed;
//   } else if (warnings !== 0) {
//     return theme.colors.subtleYellow;
//   } else {
//     return theme.colors.white;
//   }
// }

const layoutCss = (background: string) => css`
  display: flex;
  flex: 1 1 auto;
  background: ${background};
  ${PanelRowDefs.borderTopMixin()};
  
  ${PanelRow} {
    border-top: none;
    background: ${background};
  }
`;

interface LayoutProps {
  disabled: boolean;
  background: string;
}

const CheckableLayout = styled.label<LayoutProps>`
  ${(props) => layoutCss(props.background)};
  padding-left: 1.25rem;
  cursor: ${(props) => props.disabled ? "inherit" : "pointer"};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-left: 0.75rem;
  }  
  
  ${CheckboxLabel} {
    width: 100%;
    ${(props) => props.theme.animation.transitionAll()};

    ${VisibleCheckboxLayout} {
      width: 100%;
      cursor: ${(props) => props.disabled ? "inherit" : "pointer"};
      align-items: center;
    }
  
    ${HiddenCheckbox}:not(:checked) + ${VisibleCheckboxLayout} {
      color: ${(props) => props.theme.colors.lightGray};
      
       ${PanelRowIcon} {
         opacity: 0.3;
       }
    }
  }

  ${(props) => !props.disabled && css`
    @media(hover: hover) {
      &:hover {
        background: ${props.theme.colors.faintPrimary};
        border-left: 3px solid ${props.theme.colors.primary};

        ${PanelRow} {
          background: ${props.theme.colors.faintPrimary};
        }
      }
    }
    `
}
`;

const PlaceholderLayout = styled.div<LayoutProps>`
  ${(props) => layoutCss(props.background)};
  padding-left: calc(1.25rem + 1rem + 4px);

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-left: calc(0.75rem + 1rem + 4px);
  }
`;

const SimpleLayout = styled.div<LayoutProps>`
  ${(props) => layoutCss(props.background)};
`;

const TitleLayout = styled.div<{ strokeOut?: boolean }>`
  text-decoration: ${(props) => props.strokeOut ? "line-through" : "none"};
  color: ${(props) => props.strokeOut ? props.theme.colors.gray : "inherit"};
`;

const Title = styled.span`
  padding-right: .5rem;
`;

const StyledPanelRow = styled(PanelRow)`
  ${TitleLayout} + ${SummaryItem} {
    margin-top: .2rem;
  }
`;

interface ConnectionPanelRowProps extends StyledComponentsProps {
  icon: any;
  title: React.ReactNode;
  content: ConnectionPanelRowDefs.Content;
  status?: WorkStatus;
  helpArticle?: PreparedHelpArticle | "placeholder";
  disabled?: boolean;
  strokeOut?: boolean;
  checkBox?: FieldProps | "placeholder";
  progressIndicator: "hidden" | "normal" | "large";
  indicateSlowness?: boolean;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const ConnectionPanelRow: React.FunctionComponent<ConnectionPanelRowProps> = (props) => {
  const theme = React.useContext<AppTheme>(ThemeContext);

  const body = (
    <StyledPanelRow
      icon={props.icon}
      iconDisabled={props.disabled}
      helpArticle={props.content.actionItems?.isEmpty() ? props.helpArticle : undefined}
      actions={props.status && !props.disabled && props.progressIndicator !== "hidden" && (
        <ProgressIndicator
          status={props.status}
          indicateSlowness={!!props.indicateSlowness}
          large={props.progressIndicator === "large"}
        />
      )}
    >
      <TitleLayout strokeOut={props.strokeOut}>
        <Title>{props.title}</Title>
      </TitleLayout>
      {props.content.summary && (
        Array.isArray(props.content.summary)
          ? props.content.summary.map((item, index) => <SummaryItem key={index}>{item}</SummaryItem>)
          : <SummaryItem>{props.content.summary}</SummaryItem>
      )}
      {props.content.actionItems && !props.content.actionItems.isEmpty() && (
        <IssueList
          actionItems={props.content.actionItems}
          suppressing={props.actionItemSuppressing}
        />
      )}
    </StyledPanelRow>
  );

  const layoutProps: LayoutProps & StyledComponentsProps = {
    disabled: props.disabled !== undefined ? props.disabled : false,
    // background: backgroundColor(/*props.content.issues || */ List(), theme),
    background: theme.colors.white,
    className: props.className
  };

  if (typeof props.checkBox === "object") {
    return (
      <CheckableLayout {...layoutProps}>
        <Checkbox
          {...props.checkBox.field}
          checked={props.checkBox.field.value}
          disabled={props.disabled}
          rawContent={body}
          hideCheckMarkWhenDisabled={true}
        />
      </CheckableLayout>
    );
  } else if (props.checkBox === "placeholder") {
    return (
      <PlaceholderLayout {...layoutProps}>
        {body}
      </PlaceholderLayout>
    );
  } else {
    return (
      <SimpleLayout {...layoutProps}>
        {body}
      </SimpleLayout>
    );
  }
};
