import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRow, PanelRowProps } from "./panelRow";
import { SimpleToolbar } from "../../widgets/simpleToolbar";

const StyledToolbar = styled(SimpleToolbar)`
  padding-top: 0.4rem;
  font-size: 0.85rem;
`;

const Appendix = styled.div`
  padding-top: 0.4rem;
  font-size: 0.85rem;
`;

interface Props extends PanelRowProps {
  header: React.ReactNode;
  appendix?: React.ReactNode;
}

export const ToolsRow: React.FunctionComponent<Props> = (props) => {
  const { header, appendix, children, ...rest } = props;
  return (
    <PanelRow {...rest}>
      {header}
      {appendix && <Appendix>{appendix}</Appendix>}
      <StyledToolbar>{children}</StyledToolbar>
    </PanelRow>
  );
};
