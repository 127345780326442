import * as React from "react";
import { Page } from "../containers/page";
import { OperationStatus } from "../../types/operationStatus";
import { Block } from "../containers/block";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { TaskHistory } from "../../types/models/taskHistory";
import { TaskInfoPanel } from "../blocks/jobManagement/taskInfoPanel";
import { TaskHistoryPanelView } from "../blocks/jobManagement/taskHistoryPanelView";
import { TaskTimeline } from "../blocks/jobManagement/taskTimeline";

interface Props {
  jobId: string;
  taskId: string;
  includeMinorUpdates: boolean;

  loadingStatus: OperationStatus<TaskHistory>;

  onRefresh: () => void;
  onChangeIncludeMinorUpdates: (value: boolean) => void;
}

export const TaskPageView: React.FunctionComponent<Props> = (props) => {
  const [showTaskEvents, setShowTaskEvents] = React.useState(true);
  const [showPendingIssues, setShowPendingIssues] = React.useState(true);
  const [showResolvedIssues, setShowResolvedIssues] = React.useState(true);

  function setAll(value: boolean) {
    setShowTaskEvents(value);
    setShowPendingIssues(value);
    setShowResolvedIssues(value);
  }

  function reset() {
    setAll(true);
    props.onChangeIncludeMinorUpdates(false);
  }

  function clear() {
    setAll(false);
    props.onChangeIncludeMinorUpdates(false);
  }

  return (
    <Page
      breadcrumbs={["Jobs", props.jobId]}
      title={`Task \"${props.taskId}\"`}
      fullWidth={true}
    >
      {(!props.loadingStatus.someResult() || props.loadingStatus.isFailure()) && (
        <OperationStatusIndicator
          subject={"task"}
          status={props.loadingStatus}
          indicators={StatusIndicators.SimplePanel()}
        />
      )}
      {props.loadingStatus.mapLastResult((taskHistory) => (
        <>
          <Block>
            <TaskInfoPanel
              taskHistory={taskHistory}
              refreshing={props.loadingStatus.isWorking()}

              onRefresh={props.onRefresh}

              includeMinorUpdates={props.includeMinorUpdates}
              onToggleIncludeMinorUpdates={() => props.onChangeIncludeMinorUpdates(!props.includeMinorUpdates)}

              onShowPendingIssuesOnly={() => { clear(); setShowPendingIssues(true); }}
              onShowResolvedIssuesOnly={() => { clear(); setShowResolvedIssues(true); }}
            />
          </Block>
          <Block>
            <TaskTimeline taskHistory={taskHistory}/>
          </Block>
          <Block>
            <TaskHistoryPanelView
              taskHistory={taskHistory}

              showTaskEvents={showTaskEvents}
              showPendingIssues={showPendingIssues}
              showResolvedIssues={showResolvedIssues}

              reset={reset}

              onToggleShowTaskEvents={() => setShowTaskEvents(!showTaskEvents)}
              onToggleShowPendingIssues={() => setShowPendingIssues(!showPendingIssues)}
              onToggleShowResolvedIssues={() => setShowResolvedIssues(!showResolvedIssues)}
            />
          </Block>
        </>
      ))}
    </Page>
  );
};
