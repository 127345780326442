import * as React from "react";
import { AuthProvider } from "../../../types/models/authProvider";

interface Props {
  authProvider: AuthProvider;
}

export const AccessKeyValidationCapabilities: React.FunctionComponent<Props> = (props) => (
  <>
    Note: this authentication provider ({props.authProvider.id})
    {(() => {
      switch (props.authProvider.accessKeyValidationCapabilities) {
        case AuthProvider.AccessKeyValidationCapabilities.CannotValidatePermissions:
          return " will check connection status, but not individual roles.";
        case AuthProvider.AccessKeyValidationCapabilities.CanValidateRequestedPermissions:
          return " will check connection status and selected roles ONLY.";
        case AuthProvider.AccessKeyValidationCapabilities.CanListGrantedPermissions:
          return " will check connection status and list ALL currently granted roles.";
      }
    })()}
  </>
);
