import * as React from "react";
import * as yup from "yup";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { FormLayout } from "../../../widgets/formLayout";
import { TextField } from "../../../widgets/textField";
import { TextAreaField } from "../../../widgets/textAreaField";
import { FormField } from "../../../widgets/formField";
import { friendlyDateTime } from "../../../../utils/formatting";
import { ItemForm } from "../../../blocks/crud/itemForm";
import { FormConfig } from "../../../blocks/crud/formConfig";
import { OffboardingProjectFormData } from "../../../../types/models/offboardingProjectFormData";
import { blankStringToUndefined } from "../../../../utils/misc";
import { CheckboxField } from "../../../widgets/checkboxField";
import { DateField } from "../../../widgets/dateField";
import { OperationStatus } from "../../../../types/operationStatus";
import { List, Range } from "immutable";
import { EmailDeliverySchedule } from "../../../../types/models/emailDeliverySchedule";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { DropDownField } from "../../../widgets/dropDownField";
import { styled } from "../../../../app/theme";
import { Field, FieldProps } from "formik";
import { DropDownBox } from "../../../widgets/dropDownBox";
import { TextBox } from "../../../widgets/textBox";
import { Panel } from "../../../containers/panel";
import { Block } from "../../../containers/block";
import { PanelRow } from "../../../containers/rows/panelRow";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { LinkButton } from "../../../widgets/linkButton";
import { ModalController, useModal } from "../../../layouts/modal";
import { Grid } from "../../../widgets/grid";
import { DrawerBlock } from "views/containers/drawerBlock";
import { Button } from "views/widgets/button";
import { EmailDeliveryAccountField } from "./emailDeliveryAccountField";
import {
  ConnectEmailDeliveryAccountController
} from "../../../../controllers/organizationPortal/automatedOffboarding/connectEmailDeliveryAccountController";
import { EmailDeliveryErrorType } from "../../../../types/enums/emailDeliveryErrorType";
import {
  ProgramDetailsControllerType,
  ProgramLookupControllerType,
  ProgramLookupField, ProgramViewPathFactory
} from "../../../blocks/lookups/programLookupField";

interface FormData {
  name: string;
  notes: string;

  emailDeliveryAccountConfig: OffboardingProjectFormData.EmailDeliveryAccountConfig | undefined;

  programId: string | undefined;

  cutoffAt: Date;
  scheduleId: string;
  preferredDeliveryHour: string;
  preferredDeliveryMinute: string;

  from: string;

  subject1: string;
  body1: string;

  inheritTemplate2: boolean;
  subject2: string;
  body2: string;

  inheritTemplate3: boolean;
  subject3: string;
  body3: string;

  inheritTemplate4: boolean;
  subject4: string;
  body4: string;
}

namespace FormData {
  export function makeBlank(defaultSubjectLine: string, defaultBody: string): FormData {
    return {
      name: "",
      notes: "",

      emailDeliveryAccountConfig: undefined,

      programId: undefined,

      cutoffAt: new Date(),
      scheduleId: "DEFAULT",
      preferredDeliveryHour: "9",
      preferredDeliveryMinute: "0",

      from: "",

      subject1: defaultSubjectLine,
      body1: defaultBody,

      inheritTemplate2: true,
      subject2: "",
      body2: "",

      inheritTemplate3: true,
      subject3: "",
      body3: "",

      inheritTemplate4: true,
      subject4: "",
      body4: ""
    };
  }

  export function make(offboardingProject: OffboardingProject): FormData {
    return {
      name: offboardingProject.name,
      notes: offboardingProject.notes || "",

      emailDeliveryAccountConfig: undefined,

      programId: offboardingProject.program?.id,

      cutoffAt: offboardingProject.cutoffAt,
      scheduleId: offboardingProject.scheduleId,
      preferredDeliveryHour: offboardingProject.cutoffAt.getHours().toString(),
      // preferredDeliveryMinute: (Math.floor(offboardingProject.cutoffAt.getMinutes() / 15) * 15).toString(),
      preferredDeliveryMinute: offboardingProject.cutoffAt.getMinutes().toString(),

      from: offboardingProject.from || "",

      subject1: offboardingProject.subject1,
      body1: offboardingProject.body1,

      inheritTemplate2: offboardingProject.subject2 === undefined || offboardingProject.body2 === undefined,
      subject2: offboardingProject.subject2 || "",
      body2: offboardingProject.body2 || "",

      inheritTemplate3: offboardingProject.subject3 === undefined || offboardingProject.body3 === undefined,
      subject3: offboardingProject.subject3 || "",
      body3: offboardingProject.body3 || "",

      inheritTemplate4: offboardingProject.subject4 === undefined || offboardingProject.body4 === undefined,
      subject4: offboardingProject.subject4 || "",
      body4: offboardingProject.body4 || "",
    };
  }

  export function toResult(formData: FormData): OffboardingProjectFormData {
    const cutoffAt = new Date(formData.cutoffAt);
    cutoffAt.setHours(Number.parseInt(formData.preferredDeliveryHour, 10));
    cutoffAt.setMinutes(Number.parseInt(formData.preferredDeliveryMinute, 10));
    cutoffAt.setSeconds(0);
    cutoffAt.setMilliseconds(0);

    const subject2 = blankStringToUndefined(formData.subject2);
    const body2 = blankStringToUndefined(formData.body2);
    const inheritTemplate2 = formData.inheritTemplate2 || subject2 === undefined || body2 === undefined;

    const subject3 = blankStringToUndefined(formData.subject3);
    const body3 = blankStringToUndefined(formData.body3);
    const inheritTemplate3 = formData.inheritTemplate3 || subject3 === undefined || body3 === undefined;

    const subject4 = blankStringToUndefined(formData.subject4);
    const body4 = blankStringToUndefined(formData.body4);
    const inheritTemplate4 = formData.inheritTemplate4 || subject4 === undefined || body4 === undefined;

    return {
      name: formData.name.trim(),
      notes: blankStringToUndefined(formData.notes),

      emailDeliveryAccountConfig: formData.emailDeliveryAccountConfig,

      programId: formData.programId,

      cutoffAt,
      scheduleId: formData.scheduleId,

      from: blankStringToUndefined(formData.from),

      subject1: formData.subject1.trim(),
      body1: formData.body1.trim(),

      subject2: inheritTemplate2 ? undefined : subject2,
      body2: inheritTemplate2 ? undefined : body2,

      subject3: inheritTemplate3 ? undefined : subject3,
      body3: inheritTemplate3 ? undefined : body3,

      subject4: inheritTemplate4 ? undefined : subject4,
      body4: inheritTemplate4 ? undefined : body4
    };
  }

  export function validationSchema(isNew: boolean) {
    return yup.object<FormData>().shape<FormData>({
      name: yup.string().required("Offboarding project name is required"),
      notes: yup.string(),

      emailDeliveryAccountConfig: yup.mixed().notRequired(),

      programId: yup.string(),

      cutoffAt: yup.date().required("Cutoff date is required"),
      scheduleId: yup.string(),
      preferredDeliveryHour: yup.string(),
      preferredDeliveryMinute: yup.string(),

      from: yup.string(),

      subject1: yup.string().required("Subject line is required"),
      body1: yup.string().required("Message body is required"),

      inheritTemplate2: yup.boolean(),
      subject2: yup.string(),
      body2: yup.string(),

      inheritTemplate3: yup.boolean(),
      subject3: yup.string(),
      body3: yup.string(),

      inheritTemplate4: yup.boolean(),
      subject4: yup.string(),
      body4: yup.string()
    });
  }
}

interface Props {
  config: FormConfig<OffboardingProject, OffboardingProjectFormData>;
  emailDeliverySchedulesStatus: OperationStatus<List<EmailDeliverySchedule>>;

  defaultSubjectLine: string;
  defaultBody: string;

  programLookupController: ProgramLookupControllerType;
  programDetailsController: ProgramDetailsControllerType;
  programViewPathFactory: ProgramViewPathFactory;
  connectEmailDeliveryAccountController: ConnectEmailDeliveryAccountController;
}

export const OffboardingProjectForm: React.FunctionComponent<Props> = (props) => {
  const variablesModal = useModal();

  return (
    <ItemForm<OffboardingProject, FormData, OffboardingProjectFormData>
      config={props.config}

      subject={"offboarding project"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.makeBlank(props.defaultSubjectLine, props.defaultBody)}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema(props.config.isNew)}
    >
      {({ firstInputRef, submitting, formProps }) => {
        const currentSchedule = props.emailDeliverySchedulesStatus.mapLastResult((schedules) =>
          schedules.find((schedule) => schedule.id === formProps.values.scheduleId)
        );

        return (
          <>
            <ModalController
              modal={variablesModal}
              title={"Template Variables"}
              wide={true}
              render={(close) => <TemplateVariables onClose={close}/>}
            />
            <OperationStatusIndicator
              subject={"email delivery schedules"}
              status={props.emailDeliverySchedulesStatus}
              indicators={StatusIndicators.SimplePanel()}
            />
            <Block>
              <Panel>
                <TitlePanelRow>General Settings</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <TextField<FormData>
                      label={"Name"}
                      name={"name"}
                      required={true}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                      maxLength={255}
                      textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
                    />
                    <TextAreaField<FormData>
                      label={"Notes"}
                      name={"notes"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    {
                      props.config.item && !props.config.isNew &&
                        <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                    }
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Email Delivery Settings</TitlePanelRow>
                <EmailDeliveryAccountField<FormData>
                  name={"emailDeliveryAccountConfig"}

                  controller={props.connectEmailDeliveryAccountController}
                  connection={props.config.item?.connection}
                  isNew={props.config.isNew}
                  hasConnectionError={
                    props.config.item?.emailDeliveryError?.type === EmailDeliveryErrorType.InvalidAccessKey
                  }

                  disabled={submitting}
                />
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>End-User Program</TitlePanelRow>
                <PanelRow>
                  <ProgramLookupField<FormData>
                    name={"programId"}
                    required={true}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    lookupController={props.programLookupController}
                    detailsController={props.programDetailsController}
                    viewPathFactory={props.programViewPathFactory}
                  />
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Email Sending Schedule</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <DateField<FormData>
                      label={"Cutoff date"}
                      name={"cutoffAt"}
                      required={true}
                      readOnly={props.config.readOnly}
                    />
                    <DropDownField<FormData>
                      label={"Schedule"}
                      name={"scheduleId"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    >
                      {props.emailDeliverySchedulesStatus.mapLastResult((schedules) =>
                        schedules.map((schedule) =>
                          <option key={schedule.id} value={schedule.id}>{schedule.name}</option>
                        )
                      )}
                    </DropDownField>
                    {
                      currentSchedule?.description &&
                        <ScheduleDescription>{currentSchedule.description}</ScheduleDescription>
                    }
                    <FormField label={"Preferred emailing time"}>
                      <DateTimeFieldLayout>
                        <Field
                          name={"preferredDeliveryHour"}
                          render={(field: FieldProps<FormData>) => (
                            <DropDownBox
                              {...field.field}
                              readOnly={props.config.readOnly}
                              disabled={submitting}
                              maxWidth={5}
                              onChange={(event) => {
                                field.form.setFieldError(event.target.name, "");
                                field.field.onChange(event);
                              }}
                            >
                              <option value={"0"}>12 am</option>
                              {Range(1, 12).map((hour) =>
                                <option key={hour} value={hour.toString()}>{hour.toString()} am</option>
                              )}
                              <option value={"12"}>12 pm</option>
                              {Range(13, 24).map((hour) =>
                                <option key={hour} value={hour.toString()}>{(hour - 12).toString()} pm</option>
                              )}
                            </DropDownBox>
                          )}
                        />
                        <DateTimeSeparator>:</DateTimeSeparator>
                        <Field
                          name={"preferredDeliveryMinute"}
                          render={(field: FieldProps<FormData>) =>
                            currentSchedule?.isTest
                              ? (
                                <TextBox
                                  {...field.field}
                                  readOnly={props.config.readOnly}
                                  disabled={submitting}
                                  maxWidth={5}
                                  onChange={(event) => {
                                    if (/^\d*$/.test(event.target.value)) {
                                      field.form.setFieldError(event.target.name, "");
                                      field.field.onChange(event);
                                    }
                                  }}
                                />
                              )
                              : (
                                <DropDownBox
                                  {...field.field}
                                  readOnly={props.config.readOnly}
                                  disabled={submitting}
                                  maxWidth={5}
                                  onChange={(event) => {
                                    field.form.setFieldError(event.target.name, "");
                                    field.field.onChange(event);
                                  }}
                                >
                                  <option value={"0"}>00</option>
                                  <option value={"15"}>15</option>
                                  <option value={"30"}>30</option>
                                  <option value={"45"}>45</option>
                                </DropDownBox>
                              )
                          }
                        />
                      </DateTimeFieldLayout>
                    </FormField>
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Email Templates</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <TextField<FormData>
                      label={"From"}
                      name={"from"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                      maxLength={255}
                    />
                    <FormField>
                      The templates below can use variables.{" "}
                      <LinkButton onClick={variablesModal.open}>View list of variables</LinkButton>
                    </FormField>
                  </FormLayout>
                </PanelRow>
                <PanelRow>
                  <PhaseTitle>Phase 1 Email Template</PhaseTitle>
                  <FormLayout noBottomMargin={true}>
                    <TextField<FormData>
                      label={"Subject line"}
                      name={"subject1"}
                      required={true}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    <TextAreaField<FormData>
                      label={"Body (Markdown)"}
                      name={"body1"}
                      required={true}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                  </FormLayout>
                </PanelRow>
                <PanelRow>
                  <PhaseTitle>Phase 2 Email Template</PhaseTitle>
                  <FormLayout noBottomMargin={true}>
                    <CheckboxField<FormData>
                      label={"Same as above"}
                      name={"inheritTemplate2"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    <TextField<FormData>
                      label={"Subject line"}
                      name={"subject2"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate2}
                    />
                    <TextAreaField<FormData>
                      label={"Body (Markdown)"}
                      name={"body2"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate2}
                    />
                  </FormLayout>
                </PanelRow>
                <PanelRow>
                  <PhaseTitle>Phase 3 Email Template</PhaseTitle>
                  <FormLayout noBottomMargin={true}>
                    <CheckboxField<FormData>
                      label={"Same as above"}
                      name={"inheritTemplate3"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    <TextField<FormData>
                      label={"Subject line"}
                      name={"subject3"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate3}
                    />
                    <TextAreaField<FormData>
                      label={"Body (Markdown)"}
                      name={"body3"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate3}
                    />
                  </FormLayout>
                </PanelRow>
                <PanelRow>
                  <PhaseTitle>Last Call Email Template</PhaseTitle>
                  <FormLayout noBottomMargin={true}>
                    <CheckboxField<FormData>
                      label={"Same as above"}
                      name={"inheritTemplate4"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    <TextField<FormData>
                      label={"Subject line"}
                      name={"subject4"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate4}
                    />
                    <TextAreaField<FormData>
                      label={"Body (Markdown)"}
                      name={"body4"}
                      readOnly={props.config.readOnly}
                      disabled={submitting || formProps.values.inheritTemplate4}
                    />
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
          </>
        );
      }}
    </ItemForm>
  );
};

const ScheduleDescription = styled.div`
  margin-top: -0.5rem;
  max-width: 25rem;
  margin-bottom: 1rem;
`;

const DateTimeFieldLayout = styled.div`
  display: flex;
`;

const DateTimeSeparator = styled.div`
  padding: .3rem;
`;

interface TemplateVariablesProps {
  onClose: () => void;
}

const TemplateVariables: React.FunctionComponent<TemplateVariablesProps> = (props) => (
  <>
    <DrawerBlock>
      <Grid>
        <Grid.Header>
          <Grid.Column>Variable</Grid.Column>
          <Grid.Column>Description</Grid.Column>
        </Grid.Header>
        <Grid.Body>
          <Grid.Row>
            <Grid.Cell>&#123;first_name&#125;</Grid.Cell>
            <Grid.Cell>Recipient's first name.</Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>&#123;last_name&#125;</Grid.Cell>
            <Grid.Cell>Recipient's last name.</Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>&#123;full_name&#125;</Grid.Cell>
            <Grid.Cell>Recipient's full name.</Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>&#123;remaining_days&#125;</Grid.Cell>
            <Grid.Cell>
              The number of days remaining until the cutoff date (followed by "day" or "days").
              This number cannot be lower than 1.<br/>
              Example output: "3 days" or "1 day".
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>&#123;vaultme_url&#125;</Grid.Cell>
            <Grid.Cell>
              VaultMe app URL with embedded tracking code.<br/>
              Example output: "https://app.vaultme.com/o/0123456789"
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell nowrap={true}>&#123;vaultme_program_url:&lt;program-alias&gt;&#125;</Grid.Cell>
            <Grid.Cell>
              Program-specific VaultMe app URL with embedded tracking code.<br/>
              Example usage: &#123;vaultme_program_url:sampleu-graduates&#125;<br/>
              Example output: "https://app.vaultme.com/o/0123456789/#/p/sampleu-graduates"
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell nowrap={true}>&#123;unsubscribe_url&#125;</Grid.Cell>
            <Grid.Cell>Unsubscription URL with embedded tracking code.</Grid.Cell>
          </Grid.Row>
        </Grid.Body>
      </Grid>
    </DrawerBlock>
    <DrawerBlock>
      <Button size={"narrow"} onClick={props.onClose}>Close</Button>
    </DrawerBlock>
  </>
);

export const PhaseTitle = styled.div`
  font-weight: ${(props) => props.theme.font.medium};
  font-size: 0.8rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkerGray};
  padding-bottom: 1.2rem;
`;
