import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { User } from "../../types/models/user";

export function useSearchUsersQuery(
  searchTerm: string | undefined
): ManagedQueryHook<GraphQL.SearchUsersQueryVariables, List<User>> {
  return useManagedQuery({
    query: GraphQL.useSearchUsersQuery,
    deps: searchTerm,
    prepare: (term) => ({ term }),
    extract: (data: GraphQL.SearchUsersQuery) => data.searchUsers,
    complete: (users) => List(users.map(User.parse))
      .sortBy((user) => user.personalInfo.fullNameOrEmailAddress)
  });
}
