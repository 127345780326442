import { GraphQL } from "../../services/graphql/generated";

export interface AmbassadorCodeDiscount {
  readonly code: string;
  readonly percentage: number;
  readonly amount: number;
}

export namespace AmbassadorCodeDiscount {
  export function parse(data: GraphQL.AmbassadorCodeDiscountFragment): AmbassadorCodeDiscount {
    return data;
  }
}
