import * as React from "react";
import { User } from "../../types/models/user";
import { Panel } from "../containers/panel";
import { PanelRow } from "../containers/rows/panelRow";
import { UserSearch } from "./userManagement/userSearch";
import { List } from "immutable";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { OperationStatus } from "../../types/operationStatus";
import { friendlyCount } from "../../utils/formatting";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { UsersGrid } from "./userManagement/usersGrid";

interface Props {
  searchTerm: string;
  appliedSearchTerm: string | undefined;

  searchStatus: OperationStatus<List<User>>;

  onSearchTermChange: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
  onSelect: (user: User) => void;
}

export const UserSelectorPanelView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow>
      <UserSearch
        searchTerm={props.searchTerm}
        disabled={false}
        onSearchTermChange={props.onSearchTermChange}
        onSearch={(term) => {
          if (term.length > 2) {
            if (props.appliedSearchTerm !== term) {
              props.onSearch(term);
            } else {
              props.onRefresh();
            }
          }
        }}
      />
    </PanelRow>
    <OperationStatusIndicator
      progressMessage={"Searching..."}
      status={props.searchStatus}
      indicators={StatusIndicators.PanelRow()}
    />
    {props.searchStatus.mapLastResult((users) => (
      <>
        <PanelRow>
          {friendlyCount(users.size, "user")} found
        </PanelRow>
        <GridPanelRow>
          <UsersGrid users={users} onSelect={props.onSelect}/>
        </GridPanelRow>
      </>
    ))}
  </Panel>
);
