import * as React from "react";
import { Connections } from "../../../types/models/connections";
import { Connection } from "../../../types/models/connection";
import { Grid } from "../../widgets/grid";
import { ConnectionsGridRow } from "./connectionsGridRow";
import { AuthProviders } from "../../../types/models/authProviders";
import { CloudServices } from "../../../types/models/cloudServices";

interface ConnectionsTableProps {
  cloudServices: CloudServices;
  authProviders: AuthProviders;
  connections: Connections;
  actionTitle: string | undefined;
  onAction: ((connection: Connection) => void) | undefined;
}

export const ConnectionsGrid: React.FunctionComponent<ConnectionsTableProps> = (props) => (
  <Grid>
    <Grid.Header>
      <Grid.Column>ID</Grid.Column>
      <Grid.Column>Cloud Service</Grid.Column>
      <Grid.Column>Description</Grid.Column>
      <Grid.Column>Eligible For Sign-In</Grid.Column>
      <Grid.Column>Status</Grid.Column>
      <Grid.Column>Granted Roles</Grid.Column>
      {props.onAction && <Grid.Column/>}
    </Grid.Header>
    <Grid.Body>
      {
        props.connections.list()
          .sortBy((connection) => connection.id)
          .map((connection) => (
            <ConnectionsGridRow
              key={connection.id}
              cloudServices={props.cloudServices}
              authProviders={props.authProviders}
              connection={connection}
              actionTitle={props.actionTitle}
              onAction={props.onAction}
            />
          ))
      }
    </Grid.Body>
  </Grid>
);
