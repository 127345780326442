import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { ExactlyOneRelationshipHub } from "../hubs/exactlyOneRelationshipHub";
import { ComponentHubs, Component } from "../component";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentBinding } from "./componentBinding";
import { Set } from "immutable";

export class RoleComp extends Component<RoleComp.Props, RoleComp.Hubs, RoleComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<RoleComp.Output> {
    return this.hubs.roles.state(context).output
      .map((roles) =>
        roles.contains(this.props.roleId) ||
        context.inputs.get(RoleComp.IgnoreMissingRolesInputId).contains(RoleComp.Enabled)
          ? State.resolved({ conditionState: true })
          : State.blocked<RoleComp.Output>()
      )
      .getOrElse(() => State.blocked());
  }

  public visualization(): ComponentVisualization {
    return {
      title: "Role",
      summary: this.props.roleId,
      icon: Images.Blueprint.Lock,
      color: ComponentColorSchema.SolidPurple
    };
  }
}

export namespace RoleComp {
  export interface Props {
    roleId: string;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.RoleCompProps): Props {
      return {
        roleId: props.roleId
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    roles: ExactlyOneRelationshipHub<Set<string>>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.RoleCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        roles: binding.exactlyOneRelationshipHub("roles", hubs.roles),
      };
    }
  }

  export interface Output {
    conditionState: boolean;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.RoleCompOutputFragment): Output {
      return {
        conditionState: output.conditionState
      };
    }
  }

  export const IgnoreMissingRolesInputId = "ignoreMissingRoles";
  export const Enabled = "true";
}
