import * as React from "react";
import { Link } from "react-router-dom";
import { CloudService } from "../../../../../types/models/cloudService";
import { Connection } from "../../../../../types/models/connection";
import { SemiBold } from "../../../../widgets/semiBold";
import { StyledComponentsProps } from "../../../../utils/styledComponentsProps";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { WidgetStatus } from "../../../../utils/widgetStatus";
import { useRoutes } from "../../../../../app/routes/useRoutes";

interface Props extends StyledComponentsProps {
  adding: boolean;
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  item: { sourceConnection: Connection | undefined, destinationConnection: Connection | undefined } | undefined;
}

export const SingleAccountHint: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  const sourceDescription = props.item?.sourceConnection?.descriptionOrId();
  const destinationDescription = props.item?.destinationConnection?.descriptionOrId();

  return (
    <PanelRow className={props.className} status={WidgetStatus.HeadsUp}>
      {!props.adding && <><strong>Heads up!</strong>{" "}</>}
      {
        props.adding
          ? "It looks like you are only adding one account to this batch migration."
          : "There's only one account added to this batch migration."
      }
      {" "}If you are not planning to add more accounts, and if your goal is only to migrate
      data from{" "}
      {sourceDescription ? <SemiBold>{sourceDescription}</SemiBold> : "a single old account"}
      {" "}to{" "}
      {destinationDescription ? <SemiBold>{destinationDescription}</SemiBold> : "a new account"}
      , it's highly recommended to use{" "}
      <Link
        to={
          routes.migrationSetup.preConfiguredSingleMigrationHomePath(
            props.sourceCloudService.id,
            props.destinationCloudService.id
          )
        }
      >
        VaultMe for Individuals
      </Link>
      .
      It's a simple and inexpensive tool specifically built for personal, educational, and
      small business needs.
    </PanelRow>
  );
};
