import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

interface SendCouponCodeResult {
  emailAddress: string;
  result: string;
}

type SendCouponCodesMutationHook = [
  (emailAddresses: string, discount: number, note: string) => Promise<List<SendCouponCodeResult>>,
  OperationStatus<List<SendCouponCodeResult>>
];

export function useSendCouponCodesMutation(): SendCouponCodesMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useSendCouponCodesMutation,
    extract: (data: GraphQL.SendCouponCodesMutation) => nullToUndefined(data.sendCouponCodes),
    complete: (result) => List(result)
  });

  function fireWith(emailAddresses: string, discount: number, note: string): Promise<List<SendCouponCodeResult>> {
    return fire({
      variables: { emailAddresses, discount, note },
      retry: () => fireWith(emailAddresses, discount, note),
    });
  }

  return [fireWith, status];
}
