import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { OffboardingProject } from "../../types/models/offboardingProject";

type DeleteOffboardingProjectMutationHook = [(id: string) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteOffboardingProjectMutation(): DeleteOffboardingProjectMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteOffboardingProjectMutation,
    extract: (data: GraphQL.DeleteOffboardingProjectMutation) => nullToUndefined(data.deleteOffboardingProject),
    complete: identity
  });

  function fireWith(id: string): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = OffboardingProject.cacheId(id);

      const offboardingProject = apolloClient.cache.readFragment<GraphQL.OffboardingProjectFragment>({
        id: cacheId,
        fragment: GraphQL.OffboardingProjectFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: OffboardingProject.fragmentName
      });

      if (offboardingProject) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.OffboardingProjectFragmentDoc,
          fragmentName: OffboardingProject.fragmentName,
          data: { ...offboardingProject, id: "" }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
