import * as React from "react";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { styled } from "../../../../app/theme";
import { titleize } from "../../../../utils/titleize";
import { commafy, friendlyCount, friendlyDateTime } from "../../../../utils/formatting";
import { EmailDeliveryScheduleTimeUnit } from "../../../../types/enums/emailDeliveryScheduleTimeUnit";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { useModal } from "../../../layouts/modal";
import { LinkButton } from "views/widgets/linkButton";
import { WhenElevated } from "../../../utils/whenElevated";
import { ForceStartEmailDeliveryRoundTool } from "./forceStartEmailDeliveryRoundTool";
import {
  ForceStartEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/forceStartEmailDeliveryRoundController";
import { LeftHandProgressIndicator } from "../../../widgets/inlineProgressIndicator";
import { WorkStatus } from "../../../models/workStatus";
import { EmailDeliveryErrorType } from "../../../../types/enums/emailDeliveryErrorType";
import {
  RenderOffboardingEmailPreviewController
} from "../../../../controllers/organizationPortal/automatedOffboarding/renderOffboardingEmailPreviewController";
import { PreviewOffboardingEmailTool } from "./previewOffboardingEmailTool";
import {
  SendTestOffboardingProjectEmailController
} from "../../../../controllers/organizationPortal/automatedOffboarding/sendTestOffboardingProjectEmailController";

interface Props {
  offboardingProject: OffboardingProject;

  renderOffboardingEmailPreviewController: RenderOffboardingEmailPreviewController;
  sendTestOffboardingProjectEmailController: SendTestOffboardingProjectEmailController;
  forceStartEmailDeliveryRoundController: ForceStartEmailDeliveryRoundController;
}

export const ScheduleGrid: React.FunctionComponent<Props> = (props) => {
  const previewOffboardingEmailModal = useModal();
  const forceStartEmailDeliveryRoundModal = useModal();

  const [currentRound, setCurrentRound] = React.useState<number>();

  function roundStatus(index: number): RoundStatus {
    if (props.offboardingProject.currentRound === undefined && props.offboardingProject.nextRound === undefined) {
      return RoundStatus.ScheduledForLater;
    } else if (index === props.offboardingProject.currentRound && index === props.offboardingProject.nextRound) {
      return RoundStatus.InProgress;
    } else if (index === props.offboardingProject.nextRound) {
      return RoundStatus.ScheduledNext;
    } else if (props.offboardingProject.currentRound !== undefined && index <= props.offboardingProject.currentRound) {
      return RoundStatus.Completed;
    } else if (props.offboardingProject.nextRound !== undefined && index > props.offboardingProject.nextRound) {
      return RoundStatus.ScheduledForLater;
    } else {
      return RoundStatus.Skipped;
    }
  }

  return (
    <>
      {currentRound !== undefined && (
        <>
          <PreviewOffboardingEmailTool
            offboardingProject={props.offboardingProject}
            modal={previewOffboardingEmailModal}

            renderPreviewController={props.renderOffboardingEmailPreviewController}
            sendTestEmailController={props.sendTestOffboardingProjectEmailController}

            round={currentRound}
          />
          <ForceStartEmailDeliveryRoundTool
            offboardingProject={props.offboardingProject}
            modal={forceStartEmailDeliveryRoundModal}
            controller={props.forceStartEmailDeliveryRoundController}
            round={currentRound}
          />
        </>
      )}
      <Table>
        <thead>
        <tr>
          <th>Round</th>
          <th>
            {titleize(props.offboardingProject.schedule.friendlyTimeUnit)}s Before The Cutoff{" "}
            {props.offboardingProject.schedule.timeUnit === EmailDeliveryScheduleTimeUnit.Day ? "Date" : "Timestamp"}
          </th>
          <th>Scheduled For</th>
          <th>Status</th>
          <th>Email</th>
          <WhenElevated>
            <th>Actions</th>
          </WhenElevated>
        </tr>
        </thead>
        <tbody>
        {props.offboardingProject.schedule.rounds.map((round, index) => (
          <React.Fragment key={index}>
            {
              props.offboardingProject.schedule.rounds.get(index - 1)?.template !== round.template &&
              round.template > 0 &&
                <PhaseRow phase={round.template}/>
            }
            <RoundRow
              index={index}
              round={round}
              friendlyTimeUnit={props.offboardingProject.schedule.friendlyTimeUnit}
              canStart={!props.offboardingProject.isEmailSendingInProgress}

              status={roundStatus(index)}
              currentRoundTotalEmails={props.offboardingProject.currentRoundTotalEmails}
              currentRoundSentEmails={props.offboardingProject.currentRoundSentEmails}
              emailDeliveryError={props.offboardingProject.emailDeliveryError}

              onPreview={() => {
                setCurrentRound(index);
                previewOffboardingEmailModal.open();
              }}
              onRestart={() => {
                setCurrentRound(index);
                forceStartEmailDeliveryRoundModal.open();
              }}
            />
          </React.Fragment>
        ))}
        </tbody>
      </Table>
    </>
  );
};

enum RoundStatus {
  Completed = "Completed",
  ScheduledNext = "ScheduledNext",
  ScheduledForLater = "ScheduledForLater",
  InProgress = "InProgress",
  Skipped = "Skipped"
}

interface StatusCellConfig {
  content: React.ReactNode;
  widgetStatus: WidgetStatus;
  workStatus: WorkStatus;
  indicateSlowness: boolean;
}

namespace RoundStatus {
  export function toConfig(
    status: RoundStatus,
    totalEmails: number | undefined,
    sentEmails: number | undefined,
    emailDeliveryError: OffboardingProject.EmailDeliveryError | undefined
  ): StatusCellConfig {
    switch (status) {
      case RoundStatus.Completed:
        return {
          content: "Completed",
          widgetStatus: WidgetStatus.Success,
          workStatus: WorkStatus.Success,
          indicateSlowness: false
        };

      case RoundStatus.ScheduledNext:
        return {
          content: "Scheduled next",
          widgetStatus: WidgetStatus.Normal,
          workStatus: WorkStatus.Pending,
          indicateSlowness: false
        };

      case RoundStatus.ScheduledForLater:
        return {
          content: "Scheduled for later",
          widgetStatus: WidgetStatus.Uncertain,
          workStatus: WorkStatus.Pending,
          indicateSlowness: false
        };

      case RoundStatus.Skipped:
        return {
          content: "Skipped",
          widgetStatus: WidgetStatus.Uncertain,
          workStatus: WorkStatus.Pending,
          indicateSlowness: false
        };

      case RoundStatus.InProgress: {
        const preparedSentEmails = sentEmails || 0;

        function progress(): string {
          if (totalEmails === undefined) {
            return " (" + friendlyCount(preparedSentEmails, "email") + " sent)";
          } else {
            return " (" + commafy(preparedSentEmails) + " of " + friendlyCount(totalEmails, "email") + " sent" + (
              totalEmails === 0
                ? ""
                : preparedSentEmails < totalEmails
                  ? ", " + Math.min(99, Math.round(preparedSentEmails / totalEmails * 100)) + "%"
                  : ", 100%"
            ) + ")";
          }
        }

        switch (emailDeliveryError?.type) {
          case undefined:
            return {
              content: "In progress " + progress(),
              widgetStatus: WidgetStatus.Info,
              workStatus: WorkStatus.Working,
              indicateSlowness: false
            };

          case EmailDeliveryErrorType.Cancelled:
            return {
              content: "Cancelled " + progress(),
              widgetStatus: WidgetStatus.Warning,
              workStatus: WorkStatus.Pending,
              indicateSlowness: false
            };

          case EmailDeliveryErrorType.Suspended:
            return {
              content: "On pause " + progress(),
              widgetStatus: WidgetStatus.Warning,
              workStatus: WorkStatus.Working,
              indicateSlowness: true
            };

          case EmailDeliveryErrorType.ExhaustedQuota:
            return {
              content: "Delayed " + progress(),
              widgetStatus: WidgetStatus.Warning,
              workStatus: WorkStatus.Working,
              indicateSlowness: true
            };

          case EmailDeliveryErrorType.InvalidAccessKey:
            return {
              content: "On pause " + progress(),
              widgetStatus: WidgetStatus.Error,
              workStatus: WorkStatus.Failure,
              indicateSlowness: false
            };

          case EmailDeliveryErrorType.UnexpectedError:
            return {
              content: "Delayed " + progress(),
              widgetStatus: WidgetStatus.Warning,
              workStatus: WorkStatus.Working,
              indicateSlowness: true
            };
        }
      }
    }
  }
}

interface RoundRowProps {
  index: number;
  round: OffboardingProject.Round;
  friendlyTimeUnit: string;
  canStart: boolean;

  status: RoundStatus;
  currentRoundTotalEmails: number | undefined;
  currentRoundSentEmails: number | undefined;
  emailDeliveryError: OffboardingProject.EmailDeliveryError | undefined;

  onPreview: () => void;
  onRestart: () => void;
}

const RoundRow: React.FunctionComponent<RoundRowProps> = (props) => {
  const statusCell = RoundStatus.toConfig(
    props.status,
    props.currentRoundTotalEmails,
    props.currentRoundSentEmails,
    props.emailDeliveryError
  );

  return (
    <StyledRoundRow highlighted={props.status === RoundStatus.ScheduledNext || props.status === RoundStatus.InProgress}>
      <td>{props.index + 1}</td>
      <td>{friendlyCount(props.round.timeUnits, props.friendlyTimeUnit)}</td>
      <td>{friendlyDateTime(props.round.scheduledFor)}</td>
      <StatusCell status={statusCell.widgetStatus}>
        <LeftHandProgressIndicator status={statusCell.workStatus} indicateSlowness={statusCell.indicateSlowness}/>
        {statusCell.content}
      </StatusCell>
      <td><LinkButton onClick={props.onPreview}>Preview & Test</LinkButton></td>
      <WhenElevated>
        <td>
          {props.canStart && (
            <LinkButton onClick={props.onRestart}>
              {props.status === RoundStatus.Completed ? "Repeat" : "Start now"}
            </LinkButton>
          )}
        </td>
      </WhenElevated>
    </StyledRoundRow>
  );
};

interface PhaseRowProps {
  phase: number;
}

const PhaseRow: React.FunctionComponent<PhaseRowProps> = (props) => (
  <StyledPhaseRow>
    <td colSpan={6}>{props.phase === 4 ? "Last call" : "Phase " + props.phase}</td>
  </StyledPhaseRow>
);

const StyledRoundRow = styled.tr<{ highlighted: boolean }>`
  td {
    font-weight: ${(props) => props.highlighted ? 400 : "inherit"};
  }
`;

const StyledPhaseRow = styled.tr`
  td {
    font-size: 0.8rem;
  }
`;

const StatusCell = styled.td<{ status?: WidgetStatus }>`
  color: ${(props) => WidgetStatus.valueColor(props.theme, props.status)};
  background: ${(props) => WidgetStatus.valueBackground(props.theme, props.status)};
`;

const Table = styled.table`
  th, td {
    padding: 0.5rem;
  }

  th:first-child, td:first-child {
    padding-left: 0;
    font-weight: 400;
  }

  th {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkGray};
    border-style: solid;
    border-color: ${(props) => props.theme.colors.lightGray};
    border-width: 2px 0 2px;
  }

  td {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  }
`;
