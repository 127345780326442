import { PersonalInfo } from "./personalInfo";
import { GraphQL } from "../../services/graphql/generated";
import { MouseFlow } from "../../services/mouseFlow";
import { UserType } from "./userType";
import { mapOptional, nullToUndefined } from "../../utils/misc";

export interface User {
  readonly id: string;

  readonly personalInfo: PersonalInfo;
  readonly customerDetails: string | undefined;

  readonly enabled: boolean;
  readonly type: UserType;
  readonly createdForUserId: string | undefined;

  readonly ambassadorsProgramMembership: User.AmbassadorsProgramMembership | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly signedInAt: Date;

  readonly mouseFlowRecordingsUrl: string;
}

export namespace User {
  export interface AmbassadorsProgramMembership {
    readonly code: string;
    readonly signUpDiscountMigrationId: string | undefined;
  }

  export function parse(data: GraphQL.UserFragment): User {
    return {
      id: data.id,

      personalInfo: PersonalInfo.parse(data.personalInfo),
      customerDetails: nullToUndefined(data.customerDetails),

      enabled: data.enabled,
      type: data.type,
      createdForUserId: nullToUndefined(data.createdForUserId),

      ambassadorsProgramMembership: mapOptional(data.ambassadorsProgramMembership, (membership) => ({
        code: membership.code,
        signUpDiscountMigrationId: nullToUndefined(membership.signUpDiscountMigrationId)
      })),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      signedInAt: new Date(data.signedInAt),

      mouseFlowRecordingsUrl: MouseFlow.recordingsForUserUrl(data.id)
    };
  }
}
