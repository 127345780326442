import * as React from "react";
import { ItemDetailsControllerProps } from "../../../views/widgets/lookupField";
import { Program } from "../../../types/models/program";
import { useGetProgramQuery } from "../../../queries/programs/useGetProgramQuery";

export const UseProgramDetailsController: React.FunctionComponent<
  ItemDetailsControllerProps<string, Program>
  > =
  (props) => {
    const [status] = useGetProgramQuery(props.id);
    return props.render({ status });
  };

export function useProgramDetailsController() {
  return React.useCallback(
    (controllerProps: ItemDetailsControllerProps<string, Program>) =>
      <UseProgramDetailsController {...controllerProps}/>,
    []
  );
}
