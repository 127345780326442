import { Connection } from "../../types/models/connection";
import { OperationStatus } from "../../types/operationStatus";
import { Set } from "immutable";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type AccessKeyCheckHook = [() => Promise<Connection>, OperationStatus<Connection>];

export function useAccessKeyCheck(connectionId: string, roles: Set<string>): AccessKeyCheckHook {
  const [checkAccessKey, { status }] = useManagedMutation({
    mutation: GraphQL.useCheckAccessKeyMutation,
    extract: (data: GraphQL.CheckAccessKeyMutation) => nullToUndefined(data.checkAccessKey),
    complete: (connection) => Connection.fromGraphQL(connection)
  });

  function check(): Promise<Connection> {
    return checkAccessKey({
      variables: { connectionId, roles: roles.toArray() },
      retry: check,
    });
  }

  return [check, status];
}
