import * as React from "react";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { Form, Formik } from "formik";
import { FormButtons, FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { Button } from "../../widgets/button";

interface FormData {
  emailAddress: string;
}

interface Props extends StyledComponentsProps {
  predefinedEmailAddress: string | undefined;
  disabled?: boolean;
  onSubmit: (emailAddress: string) => void;
  onCancel: () => void;
}

export const ServiceAccountSignInForm: React.FunctionComponent<Props> = (props) => {
  const firstInputRef = React.createRef<HTMLInputElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []);

  return (
    <Formik<FormData>
      initialValues={{ emailAddress: props.predefinedEmailAddress || "" }}
      // validationSchema={validationSchema(props.cloudServiceName, usingAdvancedSettings)}
      // validateOnChange={false}
      // validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(data, actions) => {
        props.onSubmit(data.emailAddress);
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form className={props.className}>
          <FormLayout>
            <TextField<FormData>
              label="Email Address"
              name="emailAddress"
              textBoxRef={firstInputRef}
              disabled={formProps.isSubmitting || props.disabled || !!props.predefinedEmailAddress}
            />
            <FormButtons>
              <Button
                color="primary"
                size="small"
                type="submit"
                disabled={formProps.isSubmitting || props.disabled}
              >
                Sign In
              </Button>
              <Button
                color="secondary"
                size="small"
                disabled={formProps.isSubmitting || props.disabled}
                onClick={props.onCancel}
              >
                Cancel
              </Button>
            </FormButtons>
          </FormLayout>
        </Form>
      )}
    />
  );
};
