import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { ComponentHubs, Component } from "../component";
import { MultipleRelationshipsHub } from "../hubs/multipleRelationshipsHub";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentBinding } from "./componentBinding";
import { None, Option, Some } from "../../utils/monads/option";
import { CollectableInteger } from "../../types/collectables/collectableInteger";
import { OptionalRelationshipHub } from "../hubs/optionalRelationshipHub";
import { ExternalHelpArticle } from "../../types/models/externalHelpArticle";

export class StorageRestrictionComp
  extends Component<StorageRestrictionComp.Props, StorageRestrictionComp.Hubs, StorageRestrictionComp.Output> {

  public stateWhenUnblocked(context: BlueprintContext): State<StorageRestrictionComp.Output> {
    if (this.hubs.available.dataFlow().isEmpty()) {
      return this.hubs.required.state(context).map((required) => ({
        restrictionState: true,
        required: Option.flatten(required),
        available: None()
      }));
    } else {
      const requiredState = this.hubs.required.state(context);
      const availableState = this.hubs.available.state(context);
      return State.compute<StorageRestrictionComp.Output>(requiredState, availableState)(
        () => Option.flatten(requiredState.output).flatMap((requiredOutput) =>
          Option.flatten(availableState.output).map((availableOutput) => {
            const required = requiredOutput
              .map((collectable) => collectable.currentValue())
              .getOrElse(() => 0);
            const available = availableOutput.currentValue();

            if (available === -2) {
              return {
                restrictionState: true,
                required: requiredOutput,
                available: None()
              };
            } else if (available === -1) {
              return {
                restrictionState: true,
                required: requiredOutput,
                available: Some(availableOutput)
              };
            } else {
              return {
                restrictionState: Math.round(required * (1 + this.props.headroom)) <= available,
                required: requiredOutput,
                available: Some(availableOutput)
              };
            }
          })
        )
      );
    }
  }

  public visualization(
    context: BlueprintContext,
    state: State<StorageRestrictionComp.Output>
  ): ComponentVisualization {
    const disabled = state.resolvedOutput.exists((output) => !output.restrictionState);
    return {
      title: "Storage Restriction",
      summary: this.props.storageType.toJS(),
      icon: disabled ? Images.Blueprint.ExclamationMark : Images.Blueprint.CheckMark,
      color: disabled ? ComponentColorSchema.SolidRed : ComponentColorSchema.SolidGreen,
      sizeMultiplier: disabled ? 1.5 : 1
    };
  }
}

export namespace StorageRestrictionComp {
  export interface Props {
    storageType: Option<string>;
    headroom: number;
    helpArticle: Option<ExternalHelpArticle>;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.StorageRestrictionCompProps): Props {
      return {
        storageType: Option.mayBe(props.storageType),
        headroom: props.headroom,
        helpArticle: Option.mayBe(props.helpArticle).map(ExternalHelpArticle.fromGraphQL),
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    required: MultipleRelationshipsHub<Option<CollectableInteger>>;
    available: OptionalRelationshipHub<CollectableInteger>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.StorageRestrictionCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        required: binding.multipleRelationshipsHub("required", hubs.required),
        available: binding.optionalRelationshipHub("available", hubs.available),
      };
    }
  }

  export interface Output {
    restrictionState: boolean;
    required: Option<CollectableInteger>;
    available: Option<CollectableInteger>;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.StorageRestrictionCompOutputFragment): Output {
      return {
        restrictionState: output.restrictionState,
        required: Option.mayBe(output.required).map(CollectableInteger.fromGraphQL),
        available: Option.mayBe(output.available).map(CollectableInteger.fromGraphQL)
      };
    }
  }
}
