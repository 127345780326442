import { GraphQL } from "../../services/graphql/generated";
import { ProgramSummary } from "./program";
import { ThemeOverrides } from "./themeOverrides";
import { mapOptional } from "../../utils/misc";

export interface ProgramEligibility {
  readonly program: ProgramSummary;
  readonly theme: ThemeOverrides | undefined;
  readonly discount: number;
  readonly isWhitelisted: boolean;
}

export namespace ProgramEligibility {
  export function parse(data: GraphQL.ProgramEligibilityFragment): ProgramEligibility {
    return {
      program: ProgramSummary.parse(data.program),
      theme: mapOptional(data.theme, ThemeOverrides.parse),
      discount: data.discount,
      isWhitelisted: data.isWhitelisted
    };
  }
}
