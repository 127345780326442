import * as React from "react";
import { AdminArea } from "../views/utils/whenElevated";
import { Constants } from "../app/constants";
import { useTaskIssueHistory } from "../components/jobManagement/useTaskIssueHistory";
import { TaskIssuePageView } from "../views/screens/taskIssuePageView";
import { useRoutes } from "../app/routes/useRoutes";

export const TaskIssuePage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.jobs.taskIssueParams();
  const [loadingStatus, refresh] = useTaskIssueHistory(params.jobId, params.taskId, params.issueId);

  React.useEffect(
    () => {
      const timer = setInterval(() => refresh(), Constants.Jobs.PollingInterval);
      return () => clearInterval(timer);
    }
  );

  return (
    <AdminArea>
      <TaskIssuePageView
        jobId={params.jobId}
        taskId={params.taskId}
        issueId={params.issueId}

        loadingStatus={loadingStatus}

        onRefresh={() => refresh()}
      />
    </AdminArea>
  );
};
