import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { ConfigurationCloudServiceFormData } from "./configurationCloudServiceFormData";

export interface ConfigurationFormData {
  readonly alias: string;
  readonly name: string;
  readonly notes: string | undefined;

  readonly themeId: number | undefined;
  readonly headerSuffix: string | undefined;
  readonly mainLogoId: string | undefined;
  readonly escapeLinkText: string | undefined;

  readonly introBannerId: string | undefined;
  readonly introTitle: string | undefined;
  readonly introContent: string | undefined;
  readonly pricingPageBannerId: string | undefined;
  readonly pricingPageTitle: string | undefined;
  readonly pricingPageContent: string | undefined;

  readonly sourceCloudServiceReference: string | undefined;
  readonly destinationCloudServiceReference: string | undefined;

  readonly enableFeedback: boolean;
  readonly feedbackQuestion: string | undefined;
  readonly checkbox1Label: string | undefined;
  readonly checkbox1Required: boolean;
  readonly checkbox2Label: string | undefined;
  readonly checkbox2Required: boolean;
  readonly checkbox3Label: string | undefined;
  readonly checkbox3Required: boolean;

  readonly cloudServices: List<ConfigurationCloudServiceFormData>;
}

export namespace ConfigurationFormData {
  export function toGraphQL(data: ConfigurationFormData): GraphQL.ConfigurationFormData {
    return {
      alias: data.alias,
      name: data.name,
      notes: data.notes,

      themeId: data.themeId,
      headerSuffix: data.headerSuffix,
      mainLogoId: data.mainLogoId,
      escapeLinkText: data.escapeLinkText,

      carousel: {
        introBannerId: data.introBannerId,
        introTitle: data.introTitle,
        introContent: data.introContent,
        pricingPageBannerId: data.pricingPageBannerId,
        pricingPageTitle: data.pricingPageTitle,
        pricingPageContent: data.pricingPageContent,
      },

      sourceCloudServiceReference: data.sourceCloudServiceReference,
      destinationCloudServiceReference: data.destinationCloudServiceReference,

      enableFeedback: data.enableFeedback,
      feedbackQuestion: data.feedbackQuestion,
      checkbox1Label: data.checkbox1Label,
      checkbox1Required: data.checkbox1Required,
      checkbox2Label: data.checkbox2Label,
      checkbox2Required: data.checkbox2Required,
      checkbox3Label: data.checkbox3Label,
      checkbox3Required: data.checkbox3Required,

      cloudServices: data.cloudServices.map(ConfigurationCloudServiceFormData.toGraphQL).toArray()
    };
  }
}
