import { OffboardingProject } from "../../types/models/offboardingProject";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchOffboardingProjectsQuery(
  organizationId: number,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchOffboardingProjectsQueryVariables, List<OffboardingProject>> {
  return useManagedQuery({
    query: GraphQL.useSearchOffboardingProjectsQuery,
    deps: term,
    prepare: (deps) => ({ organizationId, term: deps }),
    extract: (data: GraphQL.SearchOffboardingProjectsQuery) => nullToUndefined(data.searchOffboardingProjects),
    complete: (offboardingProjects) => List(offboardingProjects)
      .filter((offboardingProject) => offboardingProject.id !== "")
      .map(OffboardingProject.parse),
    fetchPolicy: "cache-and-network"
  });
}
