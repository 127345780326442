import * as React from "react";
import { styled } from "../../../../app/theme";

const Width = 524;
const Height = 217;

// Image has to be placed into a (Width x Height) blank div to avoid annoying blinking when moving from
// one carousel step to another.

const OuterContainer = styled.div`
  width: 100%;
  max-width: ${Width}px;
  margin-top: 1rem;
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${Height / Width * 100}%;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

interface CarouselImageProps {
  url: string;
}

export const CarouselImage: React.FunctionComponent<CarouselImageProps> = (props) => (
  <OuterContainer>
    <InnerContainer>
      <ImageContainer>
        <Image src={props.url}/>
      </ImageContainer>
    </InnerContainer>
  </OuterContainer>
);
