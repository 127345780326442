import * as React from "react";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { ConnectionPanelRow } from "../../connectionPanelRow";
import { friendlyCount, friendlySize } from "../../../../utils/formatting";
import { styled } from "../../../../app/theme";
import { PanelRowDefs } from "../../../containers/rows/panelRow";
import { CloudService } from "../../../../types/models/cloudService";
import { List, Set } from "immutable";

interface TotalsRowProps {
  cloudService: CloudService;
  areas: List<ConnectionPanelDefs.Area>;
  areaSelection: ConnectionPanelDefs.AreaSelection;
}

function summary(areas: List<ConnectionPanelDefs.Area>, excludedAreas: Set<string>): string {
  let totalCount = 0;
  let totalSize: number | undefined;
  areas.forEach((area) => {
    if (!excludedAreas.has(area.title) && !area.disabled && area.items) {
      totalCount += area.items.totalCount();
      if (area.size !== undefined) {
        totalSize = (totalSize || 0) + area.size;
      }
    }
  });
  return friendlyCount(totalCount, "selected item") +
    " (" + (totalSize !== undefined ? friendlySize(totalSize) : "Zero GB") + ")";
}

export const ConnectionPanelTotalsRow = styled(ConnectionPanelRow)`
  ${PanelRowDefs.borderTopMixin("blue")};
`;

export const TotalsRow: React.FunctionComponent<TotalsRowProps> = (props) => (
  <ConnectionPanelTotalsRow
    icon={props.cloudService.icon}
    title="Total selected above"
    content={{ summary: summary(props.areas, props.areaSelection.excluded) }}
    checkBox="placeholder"
    progressIndicator={"hidden"}
    actionItemSuppressing={undefined}
  />
);
