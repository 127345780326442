import * as React from "react";
import { useRoutes } from "../../../app/routes/useRoutes";
import { OrganizationPortalUser } from "../../../types/models/organizationPortalUser";
import { CrudPages } from "../../../components/crud/crudPages";
import { friendlyDateTime } from "../../../utils/formatting";
import {
  useSearchOrganizationPortalUsersQuery
} from "../../../queries/organizationPortalUsers/useSearchOrganizationPortalUsersQuery";
import {
  useDeleteOrganizationPortalUserMutation
} from "../../../queries/organizationPortalUsers/useDeleteOrganizationPortalUserMutation";
import {
  useUpdateOrganizationPortalUserMutation
} from "../../../queries/organizationPortalUsers/useUpdateOrganizationPortalUserMutation";
import {
  useInsertOrganizationPortalUserMutation
} from "../../../queries/organizationPortalUsers/useInsertOrganizationPortalUserMutation";
import {
  useGetOrganizationPortalUserQuery
} from "../../../queries/organizationPortalUsers/useGetOrganizationPortalUserQuery";
import { OrganizationPortalUserForm } from "../../../views/screens/organizationPortal/users/organizationPortalUserForm";
import { OrganizationPortalUserFormData } from "../../../types/models/organizationPortalUserFormData";
import { CRUDConfig } from "../../../components/crud/crudConfig";
import { OrganizationPortalUserRole } from "../../../types/enums/organizationPortalUserRole";
import { useElevated } from "../../../utils/useAppState";
import { OrganizationContext } from "../../../types/models/organizationContext";
import { Button } from "views/widgets/button";
import { useNavigate } from "react-router";

interface OrganizationPortalUsersCrudPagesProps extends Pick<
  CRUDConfig<number, OrganizationPortalUser, OrganizationPortalUserFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  insertInBulkPath: string;
  currentOrganizationPortalUserId: number | undefined;
}

const OrganizationPortalUsersCrudPages: React.FunctionComponent<OrganizationPortalUsersCrudPagesProps> = (props) => {
  const elevated = useElevated();
  const navigate = useNavigate();

  return CrudPages<number, OrganizationPortalUser, OrganizationPortalUserFormData>({
    title: "EDU Admin Users",
    subject: "user",
    startFrom: props.startFrom,
    layout: props.layout,
    additionalTools: [(
      <Button
        key={"add-in-bulk"}
        size={"small"}
        color={"secondary"}
        onClick={() => navigate(props.insertInBulkPath)}
      >
        Add in Bulk
      </Button>
    )],
    gridColumns: [
      {
        title: "Email Address",
        render: (organizationPortalUser) => organizationPortalUser.emailAddress
      },
      {
        title: "First Name",
        render: (organizationPortalUser) => organizationPortalUser.firstName
      },
      {
        title: "Last Name",
        render: (organizationPortalUser) => organizationPortalUser.lastName
      },
      {
        title: "Role",
        render: (organizationPortalUser) =>
          OrganizationPortalUserRole.userFriendly(organizationPortalUser.role)
      },
      {
        title: "Created",
        render: (organizationPortalUser) => friendlyDateTime(organizationPortalUser.createdAt)
      },
      {
        title: "Signed In",
        render: (organizationPortalUser) =>
          organizationPortalUser.signedInAt ? friendlyDateTime(organizationPortalUser.signedInAt) : "--"
      },
    ],
    renderForm: (config) => (
      <OrganizationPortalUserForm
        config={config}
        allowChangingRole={!config.item || config.item.id !== props.currentOrganizationPortalUserId}
        allowOwnerRole={elevated}
      />
    ),

    useRoutes: props.useRoutes,
    getItemId: (organizationPortalUser) => organizationPortalUser.id,
    getItemTitle: (organizationPortalUser) => organizationPortalUser.emailAddress,
    clone: (organizationPortalUser) => ({
      ...organizationPortalUser,
      emailAddress: "copy-of-" + organizationPortalUser.emailAddress
    }),
    compare: (a: OrganizationPortalUser, b: OrganizationPortalUser) => {
      const nameA = a.emailAddress.toLowerCase();
      const nameB = b.emailAddress.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },

    useGetQuery: useGetOrganizationPortalUserQuery,
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: () => {
      const [update, updateStatus] = useUpdateOrganizationPortalUserMutation();
      return [(id, formData) => update(id, formData), updateStatus];
    },
    useDeleteMutation: useDeleteOrganizationPortalUserMutation
  });
};

interface RoutedOrganizationPortalUsersCrudPagesProps {
  organizationContext: OrganizationContext;
}

export const RoutedOrganizationPortalUsersCrudPages:
  React.FunctionComponent<RoutedOrganizationPortalUsersCrudPagesProps> =
  (props) => {
    const routes = useRoutes();

    const organizationAlias = props.organizationContext.organization.alias;
    const organizationId = props.organizationContext.organization.id;

    return (
      <OrganizationPortalUsersCrudPages
        startFrom={"list"}
        layout={"compact"}

        useRoutes={() => useRoutes().organizationPortalRoutes.users(organizationAlias)}
        useSearchQuery={(term) => useSearchOrganizationPortalUsersQuery(organizationId, term)}
        useInsertMutation={() => useInsertOrganizationPortalUserMutation(organizationId)}

        insertInBulkPath={routes.organizationPortalRoutes.users(organizationAlias).insertInBulkPath}
        currentOrganizationPortalUserId={props.organizationContext.organizationPortalUser?.id}
      />
    );
  };
