import * as React from "react";
import { styled } from "../../app/theme";

export const WorkflowTitle = styled.div`
  font-size: 1.35rem;
  font-weight: ${(props) => props.theme.font.regular};
  color: ${(props) => props.theme.colors.white};
  padding: 1.5rem 1.2rem;
  background: ${(props) => props.theme.colors.darkerPrimary};
  -webkit-font-smoothing: antialiased;
`;
