import * as React from "react";
import { ConnectionPanelState } from "./connectionPanelState";
import { AreaStatus } from "../../models/areaStatus";
import { ConnectionDetails } from "../../models/connectionDetails";
import { ItemsByType } from "../../models/itemsByType";
import { List, Set } from "immutable";
import { SignInFlowViewProps } from "../auth/signInFlowView";
import { SignInFlowState } from "../../../components/auth/useSignInFlow";
import { CloudService } from "../../../types/models/cloudService";
import { UserFacingError } from "../../../types/userFacingError";
import { PreparedHelpArticle } from "../../../utils/preparedHelpArticle";
import { Connection } from "../../../types/models/connection";
import { ActionItems } from "../../models/actionItem";
import { IncrementalSignInDefs } from "../incrementalSignIn";
import { prepareActionItems } from "./prepareActionItems";
import { ProgramSummary } from "../../../types/models/program";

export namespace ConnectionPanelDefs {
  export interface AreaSelection {
    excluded: Set<string>;
    onChange: (excluded: Set<string>) => void;
  }

  export interface Area extends AreaStatus {
    items?: ItemsByType;
    size?: number;
    availableStorage?: ConnectionPanelDefs.AvailableStorageInfo.Any;
  }

  export namespace Area {
    export function activeActionItemsStats(area: Area, suppressedActiveItemsIds: Set<string>): ActionItems.Stats {
      return ActionItems.Stats.buildForActive(
        prepareActionItems({
          subjectId: area.areaId,
          actionItems: area.actionItems,
          availableStorage: area.availableStorage,
          affectedSubject: area.mainSubject
        })
          .concat(
            area.disabled
              ? AreaStatus.Disabled.toIssues(area.disabled, area)
              : List()
          )
          .concat(
            area.incrementalSignIn
              ? IncrementalSignInDefs.Settings.toIssues(area.incrementalSignIn, () => console.log("Yikes!"))
              : List()
          ),
        suppressedActiveItemsIds
      );
    }
  }

  export enum ShowConnectionStatusRow {
    Always = "Always",
    WithConnectionIssues = "WithConnectionIssues",
    WithActionableItemsOrVisibleAreas = "WithActionableItemsOrVisibleAreas"
  }

  export enum ShowSuccessRow {
    Always = "Always",
    Never = "Never",
    WithNoErrors = "WithNoErrors"
  }

  export interface ViewSettings {
    showConnectionStatusRow: ShowConnectionStatusRow;
    showSuccessRow: ShowSuccessRow;
    successMessage?: string;
    showAreasWithNoIssues: boolean;
    showProgress: boolean;
    processDescription: string | undefined;
    showSecurityStatement: boolean;
    disconnectionConfirmation?: React.ReactNode;
  }

  export interface ViewSettingsOverrides {
    showScanProgress: boolean | undefined;
  }

  export namespace AvailableStorageInfo {
    export enum Type {
      Collecting = "Collecting",
      UnableToCollect = "UnableToCollect",
      Collected = "Collected",
      NeedToEnsure = "NeedToEnsure",
      NotEnough = "NotEnough",
      Enough = "Enough",
    }

    export interface Base<T extends Type> {
      type: T;
      storageType: string | undefined;
    }

    export interface Collecting extends Base<Type.Collecting> {
    }

    export function collecting(storageType: string | undefined): Collecting {
      return { type: Type.Collecting, storageType };
    }

    export interface UnableToCollect extends Base<Type.UnableToCollect> {
      helpArticle: PreparedHelpArticle | undefined;
    }

    export function unableToCollect(
      storageType: string | undefined,
      helpArticle: PreparedHelpArticle | undefined
    ): UnableToCollect {
      return { type: Type.UnableToCollect, storageType, helpArticle };
    }

    export interface Collected extends Base<Type.Collected> {
      available: number;
    }

    export function collected(available: number, storageType: string | undefined): Collected {
      return { type: Type.Collected, storageType, available };
    }

    export interface NeedToEnsure extends Base<Type.NeedToEnsure> {
      required: number;
      helpArticle: PreparedHelpArticle | undefined;
    }

    export function needToEnsure(
      required: number,
      storageType: string | undefined,
      helpArticle: PreparedHelpArticle | undefined
    ): NeedToEnsure {
      return { type: Type.NeedToEnsure, required, storageType, helpArticle };
    }

    export interface NotEnough extends Base<Type.NotEnough> {
      available: number;
      required: number;
      checkAgain: () => void;
      helpArticle: PreparedHelpArticle | undefined;
    }

    export function notEnough(
      available: number,
      required: number,
      checkAgain: () => void,
      storageType: string | undefined,
      helpArticle: PreparedHelpArticle | undefined
    ): NotEnough {
      return { type: Type.NotEnough, available, required, checkAgain, storageType, helpArticle };
    }

    export interface Enough extends Base<Type.Enough> {
      available: number;
      required: number;
    }

    export function enough(available: number, required: number, storageType: string | undefined): Enough {
      return { type: Type.Enough, available, required, storageType };
    }

    export type Any = Collecting | UnableToCollect | Collected | NeedToEnsure | NotEnough | Enough;

    export function raiseIssue(availableStorage: Any | undefined): boolean {
      return !!availableStorage &&
        (availableStorage.type === Type.NeedToEnsure || availableStorage.type === Type.NotEnough);
    }

    export function getStorageType(availableStorage: Any): string {
      return availableStorage.storageType || "storage";
    }
  }

  export interface ConnectionDetailsEx extends ConnectionDetails {
    availableStorage: AvailableStorageInfo.Any | undefined;
  }

  export interface ControlledConnectionPanelProps extends Omit<SignInFlowViewProps, "state"> {
    cloudService: CloudService;
    defaultRoles: Set<string>;

    signInState: SignInFlowState;
    panelState: ConnectionPanelState.Any;
    badCloudServiceError?: UserFacingError;

    viewSettingsOverrides: ViewSettingsOverrides;
    areaSelection?: AreaSelection;
    actionItemSuppressing?: ActionItems.Suppressing;

    connect: (connection: Connection) => void;
  }
}
