import { List } from "immutable";
import { Migration } from "../../types/models/migration";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export type FindOrganizationMigrationsMutationHook = [
  (searchCriteria: string) => Promise<List<Migration & Migration.HasConnections>>,
  OperationStatus<List<Migration & Migration.HasConnections>>,
  () => void
];

export function useFindOrganizationMigrationsMutation(organizationId: number): FindOrganizationMigrationsMutationHook {
  const [find, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useFindOrganizationMigrationsMutation,
    extract: (data: GraphQL.FindOrganizationMigrationsMutation) => nullToUndefined(data.findOrganizationMigrations),
    complete: (migrations) => List(migrations.map((migration) => ({
      ...Migration.parseCore(migration),
      ...Migration.HasConnections.parse(migration)
    })))
  });

  function doFind(searchCriteria: string): Promise<List<Migration & Migration.HasConnections>> {
    return find({
      variables: { organizationId, searchCriteria },
      retry: () => doFind(searchCriteria),
    });
  }

  return [doFind, status, reset];
}
