import { WorkflowContextLike } from "./workflowContextLike";
import { WorkItemStates } from "./workItemStates";
import { Path } from "./path";
import { Workflow } from "./workflow";

export interface WorkflowMutationResult<
  T extends WorkflowMutationResult.Type,
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  > {
  readonly type: T;
}

export namespace WorkflowMutationResult {
  export enum Type {
    Completed = "Completed",
    Progressed = "Progressed",
    DidNotChange = "DidNotChange"
  }

  export interface Completed<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >
    extends WorkflowMutationResult<Type.Completed, WorkflowContext, WorkflowFeedback, WorkflowResult> {
    readonly result: WorkflowResult;
  }

  export function Completed<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >(
    result: WorkflowResult
  ): Completed<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return { type: Type.Completed, result };
  }

  export interface Progressed<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >
    extends WorkflowMutationResult<Type.Progressed, WorkflowContext, WorkflowFeedback, WorkflowResult> {
    readonly newPath: Path;
  }

  export function Progressed<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    >(
    newPath: Path
  ): Progressed<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return { type: Type.Progressed, newPath };
  }

  export interface DidNotChange<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult>
    extends WorkflowMutationResult<Type.DidNotChange, WorkflowContext, WorkflowFeedback, WorkflowResult> {
  }

  export function DidNotChange<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult>(
  ): DidNotChange<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return { type: Type.DidNotChange };
  }

  export type OneOf<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    Completed<WorkflowContext, WorkflowFeedback, WorkflowResult> |
    Progressed<WorkflowContext, WorkflowFeedback, WorkflowResult> |
    DidNotChange<WorkflowContext, WorkflowFeedback, WorkflowResult>;
}
