import * as React from "react";
import { AppTheme, styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

export type HamburgerCloseColor = "white" | "primary" | "black";

function fillColor(color: HamburgerCloseColor, theme: AppTheme): string {
  switch (color) {
    case "white": return theme.colors.white;
    case "primary": return theme.colors.primary;
    case "black": return theme.colors.black;
  }
}

const StyledPath = styled.path<HamburgerCloseProps>`
  fill: ${(props) => fillColor(props.color || "white", props.theme)};
`;

interface HamburgerCloseProps extends StyledComponentsProps {
  color?: HamburgerCloseColor;
}

// tslint:disable max-line-length
export const HamburgerClose: React.FunctionComponent<HamburgerCloseProps> = (props) => (
  <svg viewBox="0 0 16 16" className={props.className}>
    <StyledPath
      {...props}
      d="M6.6 8L0.2 14.4C-0.1 14.7-0.1 15.3 0.3 15.7 0.7 16.1 1.3 16.1 1.6 15.8L8 9.4 14.4 15.8C14.7 16.1 15.3 16.1 15.7 15.7 16.1 15.3 16.1 14.7 15.8 14.4L9.4 8 15.8 1.6C16.1 1.3 16.1 0.7 15.7 0.3 15.3-0.1 14.7-0.1 14.4 0.2L8 6.6 1.6 0.2C1.3-0.1 0.7-0.1 0.3 0.3 -0.1 0.7-0.1 1.3 0.2 1.6L6.6 8Z"
    />
  </svg>
);
