import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Link } from "react-router-dom";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";
import { ModalController, useModal } from "../../layouts/modal";
import { titleize } from "../../../utils/titleize";
import { HeaderBlock } from "../../widgets/headerBlock";
import { SimpleFormButtonsBlock } from "../../widgets/simpleForm";
import { SimpleToolbar } from "../../widgets/simpleToolbar";

interface Props<Id, Item, FormData> {
  breadcrumbs?: React.ReactNode[];
  title: string;
  subject: string;
  readOnly: boolean;
  renderOverview: (item: Item) => React.ReactElement;

  getItemTitle: (item: Item) => React.ReactNode;
  getItemDescription: ((item: Item) => React.ReactNode) | undefined;

  id: Id;
  homePath: string;
  editPath: string;

  loadStatus: OperationStatus<Item>;
  deleteStatus: OperationStatus<boolean>;

  onRefresh: () => void;
  onClone: (item: Item) => void;
  onDelete: () => void;
}

export function OverviewItemPageView<Id, Item, FormData>(props: Props<Id, Item, FormData>): React.ReactElement {
  const deleteModal = useModal();

  const reference = props.loadStatus.mapLastResult((result) => props.getItemTitle(result)) || "...";
  const description = props.loadStatus.mapLastResult((result) =>
    props.getItemDescription && props.getItemDescription(result)
  );

  return (
    <>
      <HeaderBlock
        breadcrumbs={
          (props.breadcrumbs || []).concat([<Link key={0} to={props.homePath}>{props.title}</Link>, reference])
        }
        title={titleize(props.subject) + " \"" + reference + "\""}
        subTitle={description}
        footer={
          <SimpleToolbar
            items={
              [
                <Button
                  key={"refresh"}
                  color={"secondary"}
                  size={"small"}
                  disabled={props.loadStatus.isWorking()}
                  onClick={() => props.onRefresh()}
                >
                  Refresh
                </Button>
              ].concat(
                props.readOnly
                  ? []
                  : [
                    <Link key={"update"} to={props.editPath}>
                      <Button color={"secondary"} size={"small"}>Edit Settings</Button>
                    </Link>,
                    // <Button
                    //   key={"clone"}
                    //   color={"secondary"}
                    //   size={"small"}
                    //   disabled={!props.loadStatus.isSuccess()}
                    //   onClick={() => props.loadStatus.isSuccess() && props.onClone(props.loadStatus.result)}
                    // >
                    //   Clone
                    // </Button>,
                    <Button
                      key={"delete"}
                      color={"red"}
                      size={"small"}
                      disabled={!props.loadStatus.isSuccess()}
                      onClick={deleteModal.open}
                    >
                      Delete
                    </Button>
                  ]
              )
            }
          />
        }
      />
      <ModalController
        modal={deleteModal}
        title={titleize("Delete " + props.subject) + " \"" + reference + "\"?"}
        render={() => (
          <Panel>
            <OperationStatusIndicator
              progressMessage={"Deleting..."}
              status={props.deleteStatus}
              indicators={StatusIndicators.PanelRow()}
            />
            <PanelRow>
              Click the button below to delete {props.subject} "{reference}".
            </PanelRow>
            <PanelRow>
              <Button color="red" disabled={props.deleteStatus.isWorking()} onClick={props.onDelete}>Delete</Button>
            </PanelRow>
          </Panel>
        )}
      />
      <OperationStatusIndicator
        subject={props.subject}
        status={props.loadStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.loadStatus.mapLastResult(props.renderOverview)}
      <SimpleFormButtonsBlock>
        <Link to={props.homePath}>
          <Button size={"small"} color={"secondary"}>Back</Button>
        </Link>
      </SimpleFormButtonsBlock>
    </>
  );
}
