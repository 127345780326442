import * as React from "react";
import { CloudService } from "../../../../../types/models/cloudService";
import { Connection } from "../../../../../types/models/connection";
import { OperationStatus } from "../../../../../types/operationStatus";
import { SignInFlowState } from "../../../../../components/auth/useSignInFlow";
import { OneTimeAuthCodeSummary } from "../../../../../types/models/oneTimeAuthCodeSummary";
import {
  ClipboardCopyIndicator,
  copyTextToClipboard,
  useClipboardCopyIndicator
} from "../../../../utils/copyTextToClipboard";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { DrawerContent } from "../../../../utils/drawerContent";
import { NewTabLink } from "../../../../widgets/newTabLink";
import { SimpleToolbar } from "../../../../widgets/simpleToolbar";
import { Button } from "../../../../widgets/button";
import { styled } from "../../../../../app/theme";
import { CheckedCircle } from "../../../../glyphs/checkedCircle";
import { LinkButton } from "../../../../widgets/linkButton";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { SignUpOptionLayout } from "../../../signUpPageView";
import { AdminConnectionVerificationResult } from "../../../../../types/adminConnectionVerificationResult";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { PaddedPanel } from "../../../../widgets/paddedPanel";

const AppId = "100707390987972097475";

const Scopes =
  `https://www.googleapis.com/auth/admin.directory.domain.readonly,
https://www.googleapis.com/auth/admin.directory.group,
https://www.googleapis.com/auth/admin.directory.user,
https://www.googleapis.com/auth/userinfo.email,
https://www.googleapis.com/auth/userinfo.profile,
https://www.googleapis.com/auth/calendar,
https://www.googleapis.com/auth/drive,
https://www.googleapis.com/auth/contacts,
https://www.googleapis.com/auth/tasks,
https://www.google.com/m8/feeds,
https://sites.google.com/feeds/,
https://mail.google.com/,
https://picasaweb.google.com/data/
`;

export interface ConnectAdminAccountViewProps {
  state: SignInFlowState;
  pendingConnection: Connection | undefined;
  connectionVerificationStatus: OperationStatus<AdminConnectionVerificationResult>;

  onSignIn: (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
  onSignOut: () => void;
}

export interface ConnectAdminAccountControllerProps {
  render: (viewProps: ConnectAdminAccountViewProps) => React.ReactElement;
}

export type ConnectAdminAccountControllerType = React.ComponentType<ConnectAdminAccountControllerProps>;

interface GoogleWorkspaceAdminCredentialsPanelProps {
  source: boolean;
  cloudService: CloudService;
  verifiedConnection: Connection | undefined;
  connectAdminAccountController: ConnectAdminAccountControllerType;
}

export const GoogleWorkspaceAdminCredentialsPanel: React.FunctionComponent<GoogleWorkspaceAdminCredentialsPanelProps> =
  (props) => {
    const appIdIndicator = useClipboardCopyIndicator();
    const scopesIndicator = useClipboardCopyIndicator();

    return (
      <PaddedPanel tallBottom={true}>
        <PanelRow>
          <DrawerContent>
            <p>Steps for connecting VaultMe to your Google Workspace account:</p>
            <ol>
              <li>
                Sign in to your Google Workspace Admin control panel as a Super Admin
                at <NewTabLink to={"https://admin.google.com"}>https://admin.google.com</NewTabLink>
              </li>
              <li>
                Navigate to <strong>Security &gt; API Controls</strong>.
              </li>
              <li>
                Make sure that the <strong>Block all third-party API access</strong> checkbox is <u>UNCHECKED</u>.
              </li>
              <li>
                Scroll down to the bottom and hit <strong>MANAGE DOMAIN WIDE DELEGATION</strong> link.
              </li>
              <li>
                Hit <strong>Add new</strong> link.
              </li>
              <li>
                Copy and paste the following ID into the <strong>Client ID</strong> field:
                <pre>{AppId}</pre>
                <SimpleToolbar>
                  <Button
                    color={"secondary"}
                    size={"small"}
                    onClick={() => {
                      copyTextToClipboard(AppId);
                      appIdIndicator.show();
                    }}
                  >
                    Copy To Clipboard
                  </Button>
                  <ClipboardCopyIndicator indicator={appIdIndicator}/>
                </SimpleToolbar>
              </li>
              <li>
                Copy and paste the scopes below into the <strong>OAuth Scopes</strong> field:
                <pre>{Scopes}</pre>
                <SimpleToolbar>
                  <Button
                    color={"secondary"}
                    size={"small"}
                    onClick={() => {
                      copyTextToClipboard(Scopes);
                      scopesIndicator.show();
                    }}
                  >
                    Copy To Clipboard
                  </Button>
                  <ClipboardCopyIndicator indicator={scopesIndicator}/>
                </SimpleToolbar>
              </li>
              <li>
                Click the <strong>Authorize</strong> button
              </li>
              <li>
                Verify Google Workspace account ownership by signing in below as Super Admin:
                {
                  React.createElement(props.connectAdminAccountController, {
                    render: (viewProps) => (
                      <ConnectAdminAccount
                        source={props.source}
                        cloudService={props.cloudService}

                        verifiedConnection={props.verifiedConnection}

                        state={viewProps.state}
                        pendingConnection={viewProps.pendingConnection}
                        connectionVerificationStatus={viewProps.connectionVerificationStatus}

                        onSignIn={viewProps.onSignIn}
                        onSignOut={viewProps.onSignOut}
                      />
                    )
                  })
                }
              </li>
            </ol>
          </DrawerContent>
        </PanelRow>
      </PaddedPanel>
    );
  };

// Really crappy! But that's just a prototype, right?
const SignInContainer = styled.div`
  margin-top: -1rem;
`;

const OperationStatusIndicatorWrapper = styled.div`
  margin: 1.5rem 0 0;
`;

const ConnectedAccountLayout = styled.div`
  margin-top: .5rem;
`;

interface ConnectedAccountProps {
  connection: Connection;
  onSignOut: () => void;
}

const ConnectedAccount: React.FunctionComponent<ConnectedAccountProps> = (props) => (
  <ConnectedAccountLayout>
    Signed in as <strong>{props.connection.descriptionOrId()}</strong>&nbsp;&nbsp;
    <LinkButton onClick={props.onSignOut}>Disconnect</LinkButton>
  </ConnectedAccountLayout>
);

const Success = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.3rem;
  display: flex;
  line-height: 1.2;
  align-items: center;
  max-width: 50rem;
  
  > div {
    padding-left: .3rem;
  }
`;

const Failure = styled.div`
  margin-top: .5rem;
  color: ${(props) => props.theme.colors.red};
`;

// Overriding styles from DrawerContent :-\
const StyledCheckedCircle = styled(CheckedCircle)`
  margin: -0.1rem .5rem -0.1rem 0 !important;
  width: 2rem !important;
  height: 2rem !important;
  display: inline !important;
  flex-shrink: 0;
`;

interface ConnectAdminAccountProps {
  source: boolean;
  cloudService: CloudService;

  verifiedConnection: Connection | undefined;

  state: SignInFlowState;
  pendingConnection: Connection | undefined;
  connectionVerificationStatus: OperationStatus<AdminConnectionVerificationResult>;

  onSignIn: (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
  onSignOut: () => void;
}

const ConnectAdminAccount: React.FunctionComponent<ConnectAdminAccountProps> = (props) => {
  if (props.verifiedConnection) {
    return (
      <>
        <ConnectedAccount connection={props.verifiedConnection} onSignOut={props.onSignOut}/>
        <Success>
          <StyledCheckedCircle hollow={true} lineStrokeWidthMultiplier={0.5}/>
          <div>{props.source ? "Source" : "Destination"} Google Workspace account is connected to VaultMe</div>
        </Success>
      </>
    );
  } else if (props.pendingConnection) {
    const connection = props.pendingConnection;

    return (
      <>
        <ConnectedAccount connection={props.pendingConnection} onSignOut={props.onSignOut}/>
        <OperationStatusIndicator
          progressMessage={"Verifying account ownership..."}
          status={props.connectionVerificationStatus}
          indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
        />
        {
          props.connectionVerificationStatus.isSuccess() &&
          props.connectionVerificationStatus.result !== AdminConnectionVerificationResult.Success
          && (
            <Failure>
              {
                (() => {
                  switch (props.connectionVerificationStatus.result) {
                    case AdminConnectionVerificationResult.FailedToConnect: return (
                      <>
                        <p>
                          VaultMe failed to access the Google Workspace account where {connection.descriptionOrId()} is
                          expected to have Super Admin privileges. This means one of the following:
                        </p>
                        <ul>
                          <li>
                            VaultMe was not granted domain-wide delegation in this Google Workspace account
                            as described above.
                          </li>
                          <li>
                            Some of the scopes required by VaultMe to operate (see step #7) are missing.
                          </li>
                          <li>
                            Your changes haven't been applied by Google yet (this may take several minutes).
                          </li>
                        </ul>
                        <p>
                          Please check your Google Workspace configuration, update if needed, then
                          click the "Disconnect" link above and try signing in as Super Admin again.
                        </p>
                      </>
                    );

                    case AdminConnectionVerificationResult.NotAnAdmin: return (
                      <>
                        <p>
                          {connection.descriptionOrId()} does not seem to have Super Admin privileges.
                          If this account was promoted to Super Admin recently, the changes may not have been applied
                          by Google yet (this may take several minutes).
                        </p>
                        <p>
                          Please click the "Disconnect" link above and sign in as Super Admin again.
                        </p>
                      </>
                    );
                  }
                })()
              }
              <p>
                If this error message does not go away, please contact us for assistance at <EmailSupportLink/>.
              </p>
            </Failure>
          )
        }
      </>
    );
  } else {
    switch (props.state.type) {
      case SignInFlowState.Type.SelectingCloudService:
        return null;

      case SignInFlowState.Type.InitializingSignInForm:
        return (
          <OperationStatusIndicator
            subject={"configuration"}
            status={props.state.status}
            indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
          />
        );

      case SignInFlowState.Type.PendingSignIn: {
        const state = props.state;
        return (
          <SignInContainer>
            {
              props.state.signInComponents.map((signInComponent, index) =>
                React.createElement(signInComponent, {
                  key: index,
                  layoutComponent: SignUpOptionLayout,
                  verb: "Sign In",
                  cloudServiceId: props.cloudService.id,
                  authProviderId: props.cloudService.authProviderId,
                  roles: state.roles,
                  onSignIn: props.onSignIn
                })
              )
            }
          </SignInContainer>
        );
      }

      case SignInFlowState.Type.CompletingSignIn:
        return (
          <OperationStatusIndicator
            progressMessage={"Signing in..."}
            successMessage={"Done!"}
            status={props.state.status}
            indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
          />
        );

      default:
        return null;
    }
  }
};
