import { AppAction } from "../appAction";
import { ToursActionType } from "./actions";
import { ToursState } from "./state";

export function reduceTours(state: ToursState, action: AppAction): ToursState {
  switch (action.type) {
    case ToursActionType.CompletedInfoButtonsTour:
      return { ...state, completedInfoButtonsTour: true };

    default:
      return state;
  }
}
