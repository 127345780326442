import * as React from "react";
import { Set } from "immutable";
import { Connection } from "../../../types/models/connection";
import { Panel } from "../../containers/panel";
import { RoleSelector } from "../auth/roleSelector";
import { AuthProvider } from "../../../types/models/authProvider";
import { DrawerBlock } from "../../containers/drawerBlock";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Button } from "../../widgets/button";
import { OperationStatus } from "../../../types/operationStatus";
import { PropertiesGrid, PropertyRow } from "../../widgets/propertiesGrid";
import { AccessKeyStatus } from "../../../types/models/accessKeyStatus";
import { multiline } from "../../../utils/formatting";
import { AccessKeyValidationCapabilities } from "./accessKeyValidationCapabilities";
import { PanelRow } from "../../containers/rows/panelRow";

interface AccessKeyCheckToolViewProps {
  connection: Connection;
  authProvider: AuthProvider;

  roles: Set<string>;
  onRolesSelect: (roles: Set<string>) => void;

  onCheck: () => void;
  status: OperationStatus<Connection>;
}

export const AccessKeyCheckToolView: React.FunctionComponent<AccessKeyCheckToolViewProps> = (props) => (
  <>
    <DrawerBlock>Check this connection for the following roles:</DrawerBlock>
    <DrawerBlock>
      <Panel>
        <PanelRow>
          <DrawerBlock>
            <RoleSelector
              roles={{
                allRoles: props.authProvider.roles,
                selectedRoles: props.roles,
                grantedRoles: props.connection.roles
              }}
              implicitUpdates={true}
              disabled={props.status.isWorking()}
              onSelect={props.onRolesSelect}
            />
          </DrawerBlock>
          <DrawerBlock>
            <Button color="primary" onClick={props.onCheck} disabled={props.status.isWorking()}>Check</Button>
          </DrawerBlock>
        </PanelRow>
        <PanelRow>
          <AccessKeyValidationCapabilities authProvider={props.authProvider}/>
        </PanelRow>
        <OperationStatusIndicator
          progressMessage={"Checking access key..."}
          successMessage={"Access key checked."}
          status={props.status}
          indicators={StatusIndicators.PanelRow()}
        />
      </Panel>
    </DrawerBlock>
    {props.status.mapLastResult((connection) => (
      <>
        <DrawerBlock>
          <PropertiesGrid>
            <PropertyRow
              title="Access Key Status"
              status={connection.accessKeyStatus === AccessKeyStatus.Valid ? "normal" : "error"}
            >
              {connection.accessKeyStatus}
            </PropertyRow>
            <PropertyRow
              title="Granted Roles"
              status={connection.accessKeyStatus === AccessKeyStatus.Valid ? "normal" : "uncertain"}
            >
              {connection.roles && multiline(connection.roles.toList().sort())}
            </PropertyRow>
          </PropertiesGrid>
        </DrawerBlock>
      </>
    ))}
  </>
);
