import * as React from "react";
import { UserFacingError } from "../../types/userFacingError";
import {
  SimpleErrorPanel,
  SimpleProgressIndicatorPanel,
  SimpleSuccessIndicatorPanel
} from "../containers/simplePanel";
import { ProgressIndicatorRow } from "../containers/rows/progressIndicatorRow";
import { ErrorInfoRow } from "../containers/rows/errorInfoRow";
import { SuccessIndicatorRow } from "../containers/rows/successIndicatorRow";
import { wrapped } from "./wrapped";

export interface StatusIndicators {
  renderProgress: (message: string, retrying: boolean) => React.ReactElement;
  renderSuccess: (message: string) => React.ReactElement;
  renderFailure: (error: UserFacingError) => React.ReactElement;
}

export namespace StatusIndicators {
  interface Options {
    wrapper?: React.ComponentType;
    loadingDelay?: number;
    noProgressIndicatorDelay?: boolean;
    keepSuccessIndicator?: boolean;
    hideProgressBarWhenFailure?: boolean;
  }

  export function SimplePanel(options: Options = {}): StatusIndicators {
    return {
      renderProgress: (message, retrying) => (
        <SimpleProgressIndicatorPanel
          delay={retrying || options.noProgressIndicatorDelay ? 0 : options.loadingDelay}
          wrapper={options.wrapper}
        >
          {message}
        </SimpleProgressIndicatorPanel>
      ),
      renderSuccess: (message) => (
        <SimpleSuccessIndicatorPanel
          timeout={options.keepSuccessIndicator ? 0 : undefined}
          wrapper={options.wrapper}
        >
          {message}
        </SimpleSuccessIndicatorPanel>
      ),
      renderFailure: (error) => wrapped(
        <SimpleErrorPanel error={error} hideProgressBar={options.hideProgressBarWhenFailure}/>,
        options.wrapper
      )
    };
  }

  export function PanelRow(options: Options = {}): StatusIndicators {
    return {
      renderProgress: (message, retrying) => (
        <ProgressIndicatorRow
          delay={retrying || options.noProgressIndicatorDelay ? 0 : options.loadingDelay}
          wrapper={options.wrapper}
        >
          {message}
        </ProgressIndicatorRow>
      ),
      renderSuccess: (message) => (
        <SuccessIndicatorRow
          timeout={options.keepSuccessIndicator ? 0 : undefined}
          wrapper={options.wrapper}
        >
          {message}
        </SuccessIndicatorRow>
      ),
      renderFailure: (error) => wrapped(
        <ErrorInfoRow error={error} hideProgressBar={options.hideProgressBarWhenFailure}/>,
        options.wrapper
      )
    };
  }
}
