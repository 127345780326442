import { WorkflowContextLike } from "../workflowContextLike";
import {
  AnyWizardStep,
  ConfigurableWizardDestinationFactory,
  isWizardFinish,
  isWizardStep,
  WizardDestination,
} from "./wizardDestination";
import { List, Set } from "immutable";
import { FastPromise } from "../fastPromise";
import { Observable, EMPTY } from "rxjs";
import { WorkflowState } from "../workflowState";
import { AsyncResult, SyncResult } from "../asyncResult";

type AsyncWizardDestination<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  AsyncResult<
    WizardContext,
    WizardFeedback,
    WizardResult,
    WizardDestination<WizardContext, WizardFeedback, WizardResult>
    >;

type SyncWizardDestinations<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  SyncResult<
    WizardContext,
    WizardFeedback,
    WizardResult,
    List<WizardDestination<WizardContext, WizardFeedback, WizardResult>>
    >;

type AsyncWizardDestinations<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  AsyncResult<
    WizardContext,
    WizardFeedback,
    WizardResult,
    List<WizardDestination<WizardContext, WizardFeedback, WizardResult>>
    >;

type AsyncWizardSteps<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  AsyncResult<
    WizardContext,
    WizardFeedback,
    WizardResult,
    List<AnyWizardStep<WizardContext, WizardFeedback, WizardResult>>
    >;

type AsyncOptionalWizardStep<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  AsyncResult<
    WizardContext,
    WizardFeedback,
    WizardResult,
    AnyWizardStep<WizardContext, WizardFeedback, WizardResult> | undefined
    >;

export class WizardStateTraversal<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > {
  constructor(
    protected readonly firstDestinationFactory:
      ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>
  ) {
    this.buildDestination = this.buildDestination.bind(this);
  }

  public buildDestination(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    factory: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>
  ): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> {
    return FastPromise
      .resolve(
        factory((id) => {
          const workItemState = state.workItemStates.get(id);
          return {
            workflowContext: state.workflowContext,
            nestedPath: state.path.nestedPathFor(id),
            isCompleted: workItemState !== undefined ? workItemState.completed : false,
            result: workItemState && workItemState.result,
            lastCompletedResult: workItemState && workItemState.lastCompletedResult,
            context: undefined,
            resultContext: undefined
          };
        })
      )
      .map((destination): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> => {
        if (isWizardFinish(destination)) {
          return FastPromise.resolve(state.buildResult(destination));
        } else {
          return state.workItemContexts.getContexts(destination).map(([contexts, workItemContexts]) =>
            state.buildResult(destination.copy(contexts), { workItemContexts })
          );
        }
      });
  }

  public firstDestination(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> {
    return this.buildDestination(state, this.firstDestinationFactory);
  }

  public firstStep(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    return this.firstDestination(state).map((result) =>
      result.map((destination) => isWizardStep(destination) ? destination : undefined)
    );
  }

  public traverseDestinations(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    stopWhen: (step: AnyWizardStep<WizardContext, WizardFeedback, WizardResult>) => boolean,
    startFrom: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> =
      this.firstDestinationFactory
  ): AsyncWizardDestinations<WizardContext, WizardFeedback, WizardResult> {

    function traverse(
      accumulator: SyncWizardDestinations<WizardContext, WizardFeedback, WizardResult>,
      destinationFactory: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>,
      previousStepId: string
    ): AsyncWizardDestinations<WizardContext, WizardFeedback, WizardResult> {
      return destinationBuilder(accumulator.state, destinationFactory)
        .map(({ state: updatedState, result: destination }) => {
          const result = updatedState.buildResult(accumulator.result.push(destination));
          if (
            !isWizardFinish(destination) &&
            destination.id !== previousStepId &&
            destination.isCompleteWithValidResult() &&
            !stopWhen(destination)
          ) {
            const nextDestination = destination.getNextDestination();
            if (nextDestination) {
              return traverse(result, nextDestination, destination.id);
            }
          }
          return FastPromise.resolve(result);
        });
    }

    const destinationBuilder = this.buildDestination;
    return traverse(state.buildResult(List()), startFrom, "");
  }

  public lastDestinationBefore(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    stopWhen: (step: AnyWizardStep<WizardContext, WizardFeedback, WizardResult>) => boolean,
    startFrom: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> =
      this.firstDestinationFactory
  ): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> {
    return this.traverseDestinations(state, stopWhen, startFrom).map((result) =>
      this.someDestination(result.state, result.result.last())
    );
  }

  public lastDestination(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    startFrom: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> =
      this.firstDestinationFactory
  ): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> {
    return this.lastDestinationBefore(state, () => false, startFrom);
  }

  public traverseStepsUntil(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    stopWhen: (step: AnyWizardStep<WizardContext, WizardFeedback, WizardResult>) => boolean,
    startFrom: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> =
      this.firstDestinationFactory
  ): AsyncWizardSteps<WizardContext, WizardFeedback, WizardResult> {
    return this.traverseDestinations(state, stopWhen, startFrom).map((result) =>
      result.map((destinations) =>
        destinations.filter<AnyWizardStep<WizardContext, WizardFeedback, WizardResult>>(isWizardStep)
      )
    );
  }

  public traverseSteps(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    startFrom: ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> =
      this.firstDestinationFactory
  ): AsyncWizardSteps<WizardContext, WizardFeedback, WizardResult> {
    return this.traverseStepsUntil(state, () => false, startFrom);
  }

  public lastStep(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    return this.traverseSteps(state).map((result) => this.someStep(result.state, result.result.last()));
  }

  public currentOrClosestStep(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    const currentId = state.path.currentId();
    if (currentId) {
      return this.traverseStepsUntil(state, (step) => step.id === currentId).map((result) =>
        result.map((steps) => steps.last(undefined))
      );
    } else {
      return this.firstStep(state);
    }
  }

  public currentOrFirstStep(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    const currentId = state.path.currentId();
    if (currentId) {
      return this.traverseStepsUntil(state, (step) => step.id === currentId).map((result) => {
        const lastStep = result.result.last(undefined);
        return lastStep && lastStep.id === currentId
          ? result.state.buildResult(lastStep)
          : this.firstStep(result.state);
      });
    } else {
      return this.firstStep(state);
    }
  }

  public stepById(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    stepId: string
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    return this.traverseStepsUntil(state,  (step) => step.id === stepId).map((result) =>
      result.map((steps) => steps.last(undefined))
    );
  }

  public watch(): Observable<Set<string>> {
    // return this.dataSources.getObservable();
    return EMPTY;
  }

  protected someDestination(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    destination: WizardDestination<WizardContext, WizardFeedback, WizardResult> | undefined
  ): AsyncWizardDestination<WizardContext, WizardFeedback, WizardResult> {
    return destination ? FastPromise.resolve(state.buildResult(destination)) : this.firstDestination(state);
  }

  protected someStep(
    state: WorkflowState<WizardContext, WizardFeedback, WizardResult>,
    destination: AnyWizardStep<WizardContext, WizardFeedback, WizardResult> | undefined
  ): AsyncOptionalWizardStep<WizardContext, WizardFeedback, WizardResult> {
    if (destination) {
      return FastPromise.resolve(state.buildResult(destination));
    } else {
      return this.firstDestination(state).map((result) =>
        result.map((d) => isWizardFinish(d) ? undefined : d)
      );
    }
  }
}
