import * as React from "react";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { SimplePanel } from "../../../containers/simplePanel";
import { OperationStatus } from "../../../../types/operationStatus";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { PropertiesTable } from "../accessLists/propertiesTable";
import { OffboardingProjectEntryCount } from "./offboardingProjectEntryCount";
import {
  OffboardingProjectEntryCountController
} from "../../../../controllers/organizationPortal/automatedOffboarding/offboardingProjectEntryCountController";
import { ControllerHost } from "../../../../utils/controller";

interface Props {
  offboardingProjectStatus: OperationStatus<OffboardingProject>;
  entryCountController: OffboardingProjectEntryCountController;
}

export const OffboardingProjectDetailsPanel: React.FunctionComponent<Props> = (props) => (
  <>
    <OperationStatusIndicator
      subject={"offboarding project details"}
      status={props.offboardingProjectStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    <ControllerHost
      controller={props.entryCountController}
      render={(entryCountViewProps) => (
        <>
          <OperationStatusIndicator
            progressMessage={"Calculating entry count"}
            failureMessage={"Failed to calculate entry count"}
            status={entryCountViewProps.status}
            indicators={StatusIndicators.SimplePanel()}
          />
          {props.offboardingProjectStatus.mapLastResult((offboardingProject) => (
            <SimplePanel>
              <PropertiesTable>
                <tbody>
                <tr>
                  <td>Target offboarding project:</td>
                  <td>{offboardingProject.name}</td>
                </tr>
                <tr>
                  <td>Entry count:</td>
                  <td>
                    <OffboardingProjectEntryCount
                      offboardingProject={offboardingProject}
                      {...entryCountViewProps}
                    />
                  </td>
                </tr>
                </tbody>
              </PropertiesTable>
            </SimplePanel>
          ))}
        </>
      )}
    />
  </>
);
