import * as React from "react";
import { useElevated } from "../../utils/useAppState";
import { Outlet, Navigate, useLocation } from "react-router";
import { SignInRedirectState } from "../../types/signInRedirectState";
import { useRoutes } from "../../app/routes/useRoutes";

export const ElevatedRoutes: React.FunctionComponent = () => {
  const routes = useRoutes();
  const elevated = useElevated();
  const location = useLocation();

  return elevated
    ? <Outlet/>
    : <Navigate to={routes.static.signInPath} state={SignInRedirectState(location, true)}/>;
};
