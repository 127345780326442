import * as React from "react";
import { CenteredContent } from "../../layouts/centeredContent";
import { SimpleProgressIndicatorPanel } from "../../containers/simplePanel";

interface WorkflowProgressIndicatorProps {
  leftHandNavContent?: React.ReactNode;
}

export const WorkflowProgressIndicator: React.FunctionComponent<WorkflowProgressIndicatorProps> = (props) => (
  <SimpleProgressIndicatorPanel wrapper={CenteredContent}>
    Loading...
  </SimpleProgressIndicatorPanel>
);
