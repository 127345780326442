import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { identity } from "../utils/misc";

interface Params {
  configurationAlias: string | undefined;
  programAlias: string | undefined;
  themeId: number | undefined;
  organizationIdOrAlias: string | undefined;
  referralCode: string | undefined;
}

export function useGetAppBootstrapConfigQuery(params: Params) {
  return useManagedQuery({
    query: GraphQL.useGetAppBootstrapConfigQuery,
    deps: null,
    prepare: () => params,
    extract: (data: GraphQL.GetAppBootstrapConfigQuery) => data && data.getAppBootstrapConfig && {
      appBootstrapConfig: data.getAppBootstrapConfig,
      session: data.getSession
    },
    complete: identity
  });
}
