import * as React from "react";
import { styled } from "../../app/theme";

export const SubHeader = styled.div`
  padding-top: 0.8rem;
  font-size: 1.1rem;
  font-weight: ${(props) => props.theme.font.light};
  color: ${(props) => props.theme.colors.textColor};

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-top: 0.5rem;
  }
`;
