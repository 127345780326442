import * as React from "react";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { useCloudServices } from "../../../../app/configuration";
import { styled } from "../../../../app/theme";

interface Props {
  connection: OffboardingProject.Connection;
}

export const EmailDeliveryConnectionDescription: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();

  return (
    <>
      Email delivery is enabled from{" "}
      <EmailAddress>{props.connection.description}</EmailAddress>{" "}
      hosted in{" "}
      {cloudServices.getOrFail(props.connection.cloudServiceId).name}.{" "}
    </>
  );
};

const EmailAddress = styled.span`
  font-weight: 400;
`;
