import { ReferralCode } from "../../types/models/referralCode";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchReferralCodesQuery(
  organizationId: number | undefined,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchReferralCodesQueryVariables, List<ReferralCode>> {
  return useManagedQuery({
    query: GraphQL.useSearchReferralCodesQuery,
    deps: organizationId !== undefined && term !== undefined ? { organizationId, term } : undefined,
    prepare: (deps) => ({ organizationId: deps.organizationId, term: deps.term }),
    extract: (data: GraphQL.SearchReferralCodesQuery) => nullToUndefined(data.searchReferralCodes),
    complete: (referralCodes) => List(referralCodes)
      .filter((referralCode) => referralCode.id >= 0)
      .map(ReferralCode.parse),
    fetchPolicy: "cache-and-network"
  });
}
