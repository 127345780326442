import * as React from "react";
import { styled } from "../../../../app/theme";

export const CarouselSubTitle = styled.div`
  padding: 1rem 0 0;
  max-width: 30rem;
  line-height: 1.6;
  font-size: 1.125rem;
  text-align: center;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 100%;
  }
`;
