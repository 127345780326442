import { GraphQL } from "../../services/graphql/generated";
import { InternalJobStatus } from "./internalJobStatus";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { TaskResultType } from "./taskResultType";

export class TaskHistoryRecord implements TaskHistoryRecord.Props {
  constructor(protected readonly props: TaskHistoryRecord.Props) {
  }

  public get jobId(): string { return this.props.jobId; }
  public get taskId(): string { return this.props.taskId; }
  public get transition(): number { return this.props.transition; }

  public get type(): string { return this.props.type; }
  public get internalStatus(): InternalJobStatus { return this.props.internalStatus; }

  public get propsVersion(): string { return this.props.propsVersion; }
  public get propsJson(): string { return this.props.propsJson; }

  public get stateVersion(): string { return this.props.stateVersion; }
  public get stateJson(): string { return this.props.stateJson; }

  public get resultVersion(): string | undefined { return this.props.resultVersion; }
  public get resultJson(): string | undefined { return this.props.resultJson; }
  public get abortReason(): string | undefined { return this.props.abortReason; }

  public get createdAt(): Date { return this.props.createdAt; }

  public get updatedAt(): Date { return this.props.updatedAt; }
  public get updatedBy(): string | undefined { return this.props.updatedBy; }
  public get updateSummary(): string | undefined { return this.props.updateSummary; }
  public get minorUpdate(): boolean { return this.props.minorUpdate; }

  public get completedAt(): Date | undefined { return this.props.completedAt; }
  public get archivedAt(): Date | undefined { return this.props.archivedAt; }

  // There's a chance of collision here if two identical events happen at exactly the same moment of time
  public get key(): string { return this.updatedAt.toISOString() + "/" + this.updateSummary; }

  public get timestamp(): Date { return this.updatedAt; }

  public get resultType(): TaskResultType | undefined {
    if (this.internalStatus === InternalJobStatus.Archived) {
      if (this.abortReason) {
        return TaskResultType.Abort;
      } else if (this.resultJson) {
        return TaskResultType.Success;
      } else {
        return TaskResultType.Failure;
      }
    }
  }

  public isArchived(): boolean { return this.internalStatus === InternalJobStatus.Archived; }
}

export namespace TaskHistoryRecord {
  export interface Props {
    readonly jobId: string;
    readonly taskId: string;
    readonly transition: number;

    readonly type: string;
    readonly internalStatus: InternalJobStatus;

    readonly propsVersion: string;
    readonly propsJson: string;

    readonly stateVersion: string;
    readonly stateJson: string;

    readonly resultVersion: string | undefined;
    readonly resultJson: string | undefined;
    readonly abortReason: string | undefined;

    readonly createdAt: Date;

    readonly updatedAt: Date;
    readonly updatedBy: string | undefined;
    readonly updateSummary: string | undefined;
    readonly minorUpdate: boolean;

    readonly completedAt: Date | undefined;
    readonly archivedAt: Date | undefined;
  }

  export function fromGraphQL(taskHistoryRecord: GraphQL.TaskHistoryRecord): TaskHistoryRecord {
    return new TaskHistoryRecord({
      jobId: taskHistoryRecord.jobId,
      taskId: taskHistoryRecord.taskId,
      transition: taskHistoryRecord.transition,

      type: taskHistoryRecord.type,
      internalStatus: taskHistoryRecord.internalStatus,

      propsVersion: taskHistoryRecord.propsVersion,
      propsJson: taskHistoryRecord.propsJson,

      stateVersion: taskHistoryRecord.stateVersion,
      stateJson: taskHistoryRecord.stateJson,

      resultVersion: nullToUndefined(taskHistoryRecord.resultVersion),
      resultJson: nullToUndefined(taskHistoryRecord.resultJson),
      abortReason: nullToUndefined(taskHistoryRecord.abortReason),

      createdAt: new Date(taskHistoryRecord.createdAt),

      updatedAt: new Date(taskHistoryRecord.updatedAt),
      updatedBy: nullToUndefined(taskHistoryRecord.updatedBy),
      updateSummary: nullToUndefined(taskHistoryRecord.updateSummary),
      minorUpdate: taskHistoryRecord.minorUpdate,

      completedAt: mapOptional(taskHistoryRecord.completedAt, (value) => new Date(value)),
      archivedAt: mapOptional(taskHistoryRecord.archivedAt, (value) => new Date(value))
    });
  }
}
