import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type AbortJobHook = [
  (id: string) => Promise<string>,
  OperationStatus<string>
];

export function useAbortJobManagedMutation(): AbortJobHook {
  const [abortJob, { status }] = useManagedMutation({
    mutation: GraphQL.useAbortJobMutation,
    extract: (data: GraphQL.AbortJobMutation) => nullToUndefined(data.abortJob),
    complete: identity,
    cancel: true
  });

  function execute(jobId: string): Promise<string> {
    return abortJob({
      variables: { jobId },
      retry: () => execute(jobId),
    });
  }

  return [execute, status];
}
