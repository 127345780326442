import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { SimplePanel } from "../../../containers/simplePanel";
import { AccessListEntryCount } from "./accessListEntryCount";
import * as React from "react";
import { PropertiesTable } from "./propertiesTable";
import { OperationStatus } from "../../../../types/operationStatus";
import { AccessList } from "../../../../types/models/accessList";

interface Props {
  accessListStatus: OperationStatus<AccessList>;

  entryCountStatus: OperationStatus<number>;
  onEntryCountRefresh: () => void;
}

export const AccessListDetailsPanel: React.FunctionComponent<Props> = (props) => (
  <>
    <OperationStatusIndicator
      subject={"access list details"}
      status={props.accessListStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    <OperationStatusIndicator
      progressMessage={"Calculating entry count"}
      failureMessage={"Failed to calculate entry count"}
      status={props.entryCountStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.accessListStatus.mapLastResult((accessList) => (
      <SimplePanel>
        <PropertiesTable>
          <tbody>
          <tr>
            <td>Target access list:</td>
            <td>{accessList.name}</td>
          </tr>
          {accessList.program && (
            <tr>
              <td>End-user program:</td>
              <td>{accessList.program.name}</td>
            </tr>
          )}
          <tr>
            <td>Entry count:</td>
            <td><AccessListEntryCount status={props.entryCountStatus} onRefresh={props.onEntryCountRefresh}/></td>
          </tr>
          </tbody>
        </PropertiesTable>
      </SimplePanel>
    ))}
  </>
);
