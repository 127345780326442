import * as React from "react";
import { styled, keyframes, css } from "../../app/theme";
import { VaultMeLogo } from "../glyphs/vaultMeLogo";
import { AppBootstrapConfig } from "../../types/models/appBootstrapConfig";

export interface LeftHandNavProps {
  open: boolean;
  keepOpenOnLargeScreens: boolean;
  appBootstrapConfig: AppBootstrapConfig;
}

interface InternalState {
  closing: boolean;
}

const slideIn = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
`;

const slideOut = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
`;

const animationTime = 250;

const slideInAnimation = css`
  ${slideIn} ${animationTime}ms forwards
`;

const slideOutAnimation = css`
  ${slideOut} ${animationTime}ms forwards
`;

const positionMixin = (open: boolean, keepOpenOnLargeScreens: boolean, closing: boolean) => {
  if (open) {
    return css`
      animation: ${slideInAnimation};
      box-shadow: 0.5rem 0 1rem 0 rgba(5,37,53,0.25);
    `;
  } else if (keepOpenOnLargeScreens) {
    return css`
      transform: translateX(0);
      animation: none;

      ${(props) => props.theme.responsive.respondToSmall()} {
        transform: translateX(-100%);
        animation: ${closing ? slideOutAnimation : "none"};
      }
    `;
  }
};

interface LayoutProps extends LeftHandNavProps {
  closing: boolean;
}

const Layout = styled.div<LayoutProps>`
  position: fixed;
  z-index: ${(props) => props.theme.layers.leftHandNav};
  left: 0;
  top: 0;
  bottom: 0;
  width: ${(props) => props.theme.layout.leftHandNav.width};

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-x: hidden;

  background: ${(props) => props.theme.colors.offWhite};
  border-right: 0.8rem solid #ffffff;
  box-sizing: border-box;

  transform: translateX(-100%);

  ${(props) => positionMixin(props.open, props.keepOpenOnLargeScreens, props.closing)}
`;

const TopSpacer = styled.div`
  padding-top: ${(props) => props.theme.layout.header.desktop}rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding-top: ${(props) => props.theme.layout.header.mobile}rem;
  }
`;

const LogoContainer = styled.div`
  display: none;
  flex-shrink: 0;
  background: ${(props) => props.theme.colors.offWhite};
  padding: .8rem 1rem 1rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    display: block;
  }
`;

const StyledVaultMeLogo = styled(VaultMeLogo)`
  display: block;
  height: 2.3rem;
  width: 8.1rem;
`;

const LogoSuffix = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 0.8rem;
  margin-top: .5rem;
  margin-left: .1rem;
`;

export class LeftHandNav extends React.Component<LeftHandNavProps, InternalState> {

  constructor(props: LeftHandNavProps) {
    super(props);

    this.state = { closing: false };

    this.onClosingComplete = this.onClosingComplete.bind(this);
  }

  public componentDidUpdate(prevProps: LeftHandNavProps) {
    // This prevents slideOut animation from playing every time when components gets re-mounted on mobile screen
    if (prevProps.open && !this.props.open) {
      this.setState({ closing: true });
      setTimeout(this.onClosingComplete, animationTime * 2);
    }
  }

  public render() {
    return (
      <Layout {...this.props} closing={this.state.closing}>
        <TopSpacer/>
        <LogoContainer>
          <StyledVaultMeLogo colorSchema="black-and-blue"/>
          {
            this.props.appBootstrapConfig.configuration?.headerSuffix &&
              <LogoSuffix>{this.props.appBootstrapConfig.configuration?.headerSuffix}</LogoSuffix>
          }
        </LogoContainer>
        {this.props.children}
      </Layout>
    );
  }

  protected onClosingComplete() {
    this.setState({ closing: false });
  }
}
