import * as React from "react";
import { NewItemPageView } from "../../views/blocks/crud/newItemPageView";
import { CRUDConfig } from "./crudConfig";

interface Props<Id, Item, FormData> {
  config: CRUDConfig<Id, Item, FormData>;

  homePath: string;
  cancelPath: string;

  onComplete: (created: Item, continueEditing: boolean) => void;
}

export function NewItemPage<Id, Item, FormData>(props: Props<Id, Item, FormData>): React.ReactElement {
  const routes = props.config.useRoutes();
  const params = routes.newParams();

  const [insert, insertStatus] = props.config.useInsertMutation();

  return (
    <NewItemPageView<Id, Item, FormData>
      breadcrumbs={props.config.breadcrumbs}
      title={props.config.title}
      subject={props.config.subject}
      readOnly={!!props.config.readOnly}
      renderForm={props.config.renderForm}

      template={params.template}
      homePath={props.homePath}
      cancelPath={props.cancelPath}
      insertStatus={insertStatus}

      onSubmit={(formData, continueEditing) =>
        insert(formData).then((item) => {
          props.onComplete(item, continueEditing);
          return item;
        })
      }
    />
  );
}
