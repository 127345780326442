import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRowDefs } from "./panelRow";
import { WidgetStatus } from "../../utils/widgetStatus";

export enum ColumnResponsiveBehavior {
  Stay = "Stay",
  Expand = "Expand",
  Wrap = "Wrap"
}

interface ColumnProps {
  responsiveBehavior?: ColumnResponsiveBehavior;
  width?: number;
  status?: WidgetStatus;
}

const ExpandOnBreakpoint1 = "multi-column-row-expand-1";
const ExpandOnBreakpoint2 = "multi-column-row-expand-2";

function behaviorClassNames(behavior: ColumnResponsiveBehavior | undefined) {
  switch (behavior) {
    case ColumnResponsiveBehavior.Stay: return "";
    case ColumnResponsiveBehavior.Expand: return ExpandOnBreakpoint1 + " " + ExpandOnBreakpoint2;
    default: return ExpandOnBreakpoint2;
  }
}

export const Column = styled.div.attrs((props: ColumnProps) => ({
  className: behaviorClassNames(props.responsiveBehavior)
}))<ColumnProps>`
  flex: ${(props) => props.width !== undefined ? props.width : 1} 1 0;
  padding: ${PanelRowDefs.rowPadding("regular")}rem ${PanelRowDefs.PanelHorizontalPadding}rem;
  color: ${(props) => WidgetStatus.valueColor(props.theme, props.status)};
  background: ${(props) => WidgetStatus.valueBackground(props.theme, props.status)};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: ${PanelRowDefs.rowPadding("regular") * PanelRowDefs.XSmallVerticalPaddingMultiplier}rem 
      ${PanelRowDefs.XSmallPanelHorizontalPadding}rem;
  }  
`;

interface MultiColumnRowProps {
  breakpoint1?: string;
  breakpoint2?: string;
}

export const MultiColumnRow = styled.div<MultiColumnRowProps>`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 !important;
  ${PanelRowDefs.borderTopMixin()};

  ${Column} + ${Column} {
    border-left: 1px solid ${(props) => PanelRowDefs.expandBorderColor(props.theme)};
  }

  @media screen and (max-width: ${(props) => props.breakpoint1 || props.theme.responsive.small}) {
    flex-wrap: wrap;

    ${Column}.${ExpandOnBreakpoint1} {
      flex: 1 1 100%;
      border-left: none;

      &:not(:first-child) {
        border-top: 1px solid ${(props) => PanelRowDefs.expandBorderColor(props.theme)};
      }

      & ~ ${Column}:not(.${ExpandOnBreakpoint1}) {
        border-top: 1px solid ${(props) => PanelRowDefs.expandBorderColor(props.theme)};    
      }
  
      & + ${Column} {
        border-left: none;
      }
    }
  }
  
  @media screen and (max-width: ${(props) => props.breakpoint2 || props.theme.responsive.xSmall}) {
    ${Column}.${ExpandOnBreakpoint2} {
      flex: 1 1 100%;
      border-left: none;

      &:not(:first-child) {
        border-top: 1px solid ${(props) => PanelRowDefs.expandBorderColor(props.theme)};
      }

      & ~ ${Column}:not(.${ExpandOnBreakpoint2}) {
        border-top: 1px solid ${(props) => PanelRowDefs.expandBorderColor(props.theme)};    
      }
  
      & + ${Column} {
        border-left: none;
      }
    }
  }
`;
