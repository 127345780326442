import * as React from "react";
import { useSession } from "../utils/useAppState";
import { Page } from "../views/containers/page";
import { UserProfile } from "../components/userProfile";
import { AdminArea } from "../views/utils/whenElevated";

export const MyProfilePage: React.FunctionComponent = () => {
  const session = useSession();
  if (session) {
    return (
      <AdminArea>
        <Page title="My Profile" fullWidth={true}>
          <UserProfile user={session.user}/>
          {/*<Block>*/}
          {/*  <AddConnectionPanel/>*/}
          {/*</Block>*/}
        </Page>
      </AdminArea>
    );
  } else {
    return null;
  }
};
