import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { ProgramPerformance } from "../../types/models/programPerformance";
import { ActivityTimeUnit } from "../../types/enums/activityTimeUnit";
import { OrganizationMigrationStats } from "../../types/models/organizationMigrationStats";

export function useGetOrganizationMigrationStatsQuery(
  params: {
    organizationId: number,
    timeUnit: ActivityTimeUnit,
    timeUnitCount: number,
    recentMigrationDayCount: number,
    timeZoneOffset: number
  }
): ManagedQueryHook<GraphQL.GetOrganizationMigrationStatsQueryVariables, OrganizationMigrationStats> {
  return useManagedQuery({
    query: GraphQL.useGetOrganizationMigrationStatsQuery,
    deps: null,
    prepare: () => params,
    extract: (data: GraphQL.GetOrganizationMigrationStatsQuery) =>
      data.organizationProgramsPerformance && data.organizationMigrationStats
        ? {
          programsPerformance: data.organizationProgramsPerformance,
          migrationStats: data.organizationMigrationStats
        }
        : undefined,
    complete: ({ programsPerformance, migrationStats }) => ({
      ...params,
      programPerformances: List(
        programsPerformance.map((programPerformance) =>
          ProgramPerformance.parse(programPerformance, params.timeZoneOffset)
        )
      ),
      totalMigrationCount: migrationStats.totalMigrationCount,
      recentMigrationCount: migrationStats.recentMigrationCount
    }),
    // cache-and-network looks like a better strategy, but it's not working well with re-tries (the query gets stuck
    // in a loading state for some reason)
    fetchPolicy: "network-only"
  });
}
