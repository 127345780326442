import { CRUDRoutes } from "./crudRoutes";
import { RoutesContext } from "./routesContext";
import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";

export class OrganizationPortalUsersRoutes extends CRUDRoutes<number, OrganizationPortalUser> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  public get insertInBulkPath(): string {
    return this.context.resolvePath(this.root + "/" + OrganizationPortalUsersRoutes.insertInBulkRoot);
  }

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}

export namespace OrganizationPortalUsersRoutes {
  export const insertInBulkRoot = "add-in-bulk";
  export const insertInBulkMask = insertInBulkRoot;
}
