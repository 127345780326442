import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useGetAccessListQuery } from "../../../queries/accessLists/useGetAccessListQuery";
import { ImportHistoryPageView } from "../../../views/screens/organizationPortal/accessLists/importHistoryPageView";
import { useGetAccessListUpdateHistoryQuery } from "../../../queries/accessLists/useGetAccessListUpdateHistoryQuery";
import { useBrowser } from "../../../utils/useBrowser";
import { useListAccessListUpdatesQuery } from "../../../queries/accessLists/useListAccessListUpdatesQuery";
import { AccessListUpdateStatus } from "../../../types/enums/accessListUpdateStatus";

interface Props {
  organization: OrganizationSummary;
}

export const ImportHistoryPage: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const apiRoutes = useRoutes().api;
  const accessListsRoutes = useRoutes().organizationPortalRoutes.accessLists(props.organization.alias);
  const params = accessListsRoutes.importHistoryParams();

  const [accessListStatus] = useGetAccessListQuery(params.id);
  const [accessListHistoryStatus, refreshAccessListHistory] = useGetAccessListUpdateHistoryQuery(params.id);

  const pendingUpdateIds = accessListHistoryStatus
    .mapLastResult((updates) =>
      updates
        .filter((update) =>
          update.status !== AccessListUpdateStatus.Success &&
          update.status !== AccessListUpdateStatus.Failure
        )
        .map((update) => update.id)
        .toArray()
    ) || [];

  const [accessListUpdatesStatus, refreshAccessListUpdates] = useListAccessListUpdatesQuery(pendingUpdateIds);

  React.useEffect(
    () => {
      if (pendingUpdateIds.length !== 0) {
        const timer = setInterval(
          () => refreshAccessListUpdates({ ids: pendingUpdateIds }, true),
          5000
        );
        return () => clearInterval(timer);
      }
    },
    [pendingUpdateIds]
  );

  return (
    <ImportHistoryPageView
      accessListId={params.id}
      accessListStatus={accessListStatus}
      accessListHistoryStatus={accessListHistoryStatus}

      homePath={accessListsRoutes.homePath}
      accessListPath={accessListsRoutes.overviewPath(params.id)}

      onRefresh={() => refreshAccessListHistory()}
      onDownloadOutput={(accessListUpdateId) =>
        browser.openNewTab(apiRoutes.accessListUpdateOutputUrl(accessListUpdateId), true)
      }
    />
  );
};
