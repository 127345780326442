import * as React from "react";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Batch } from "../../types/models/batch";
import { OperationStatus } from "../../types/operationStatus";
import {
  BatchSyncUpToolControllerProps,
  BatchSyncUpToolControllerType
} from "../../views/screens/batchStatusPageView/batchToolsPanel";

type SyncUpBatchMutationHook = [() => Promise<Batch>, OperationStatus<Batch>];

function useSyncUpBatchMutation(batchId: string): SyncUpBatchMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useSyncUpBatchMutation,
    extract: (data: GraphQL.SyncUpBatchMutation) => nullToUndefined(data.syncUpBatch),
    complete: Batch.parse
  });

  function fireWith(): Promise<Batch> {
    return fire({
      variables: { batchId },
      retry: () => fireWith(),
    });
  }

  return [fireWith, status];
}

interface Props extends BatchSyncUpToolControllerProps {
  batchId: string;
}

const BatchSyncUpToolController: React.FunctionComponent<Props> = (props) => {
  const [syncUpBatch, status] = useSyncUpBatchMutation(props.batchId);
  return props.render(() => syncUpBatch(), status);
};

export function useBatchSyncUpToolController(batchId: string): BatchSyncUpToolControllerType {
  return React.useCallback(
    (controllerProps: BatchSyncUpToolControllerProps) => (
      <BatchSyncUpToolController
        {...controllerProps}
        batchId={batchId}
      />
    ),
    [batchId]
  );
}
