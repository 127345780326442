import * as React from "react";
import {
  useDisableOffboardingProjectEmailDeliveryMutation
} from "../../../queries/automatedOffboarding/useDisableOffboardingProjectEmailDeliveryMutation";
import {
  DisableEmailDeliveryViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/disableEmailDeliveryController";
import { ControllerProps } from "../../../utils/controller";

interface DisableEmailDeliveryToolControllerProps extends ControllerProps<DisableEmailDeliveryViewProps> {
  offboardingProjectId: string;
}

const DisableEmailDeliveryToolController
  : React.FunctionComponent<DisableEmailDeliveryToolControllerProps> =
  (props) => {
    const [disable, status] = useDisableOffboardingProjectEmailDeliveryMutation(props.offboardingProjectId);

    return props.render({
      status,
      onDisable: disable
    });
  };

export function useDisableEmailDeliveryController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<DisableEmailDeliveryViewProps>) =>
      <DisableEmailDeliveryToolController offboardingProjectId={offboardingProjectId} {...controllerProps}/>,
    [offboardingProjectId]
  );
}
