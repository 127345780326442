import { styled } from "../../app/theme";
import { Panel } from "../containers/panel";

export const PaddedPanel = styled(Panel).attrs({})<{ tallBottom?: boolean }>`
  border: 1px dashed #a0a0a0;
  
  margin-top: .75rem;
  margin-bottom: ${(props) => props.tallBottom ? 3 : 0.75}rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin-top: .5rem;
    margin-bottom: ${(props) => props.tallBottom ? 2 : 0.5}rem;
  }
`;
