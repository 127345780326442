import * as React from "react";
import { Map } from "immutable";

// Attempted to use Redux, but with no luck - the app was failing after never-ending cycle of updates.
// May be this will go away after removing dependencies from appStore that exist in wizardry framework?

type State = Map<string, any>;
type Action = [string, any];

function reduce(state: State, [key, value]: Action): State {
  // console.log("CACHE", key, Object.is(state.get(key, undefined), value), value);
  return state.has(key) && Object.is(state.get(key), value) ? state : state.set(key, value);
}

export const CacheStateContext = React.createContext<State | undefined>(undefined);
export const CacheDispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

export const CacheProvider: React.FunctionComponent = (props) => {
  const [state, dispatch] = React.useReducer(reduce, Map());

  return (
    <CacheStateContext.Provider value={state}>
      <CacheDispatchContext.Provider value={dispatch}>
        {props.children}
      </CacheDispatchContext.Provider>
    </CacheStateContext.Provider>
  );
};

export function useCache<T>(factory: () => T, keys: string[]): [T, (value: T) => void] {
  const key = keys.join(":");

  // Not sure if this helps or not, but local storage provided by useRef() is used here as the first level of caching.
  // Only if useRef() contains no value, then the global cache is requested (and the returned value is saved in the
  // local storage).

  const state = React.useContext(CacheStateContext);
  const dispatch = React.useContext(CacheDispatchContext);
  const ref = React.useRef<T | undefined>(undefined);

  function getOrInitKey(): T {
    if (ref.current !== undefined) {
      return ref.current;
    } else if (state && dispatch) {
      if (state.has(key)) {
        const value = state.get(key);
        ref.current = value;
        return value;
      } else {
        const initialValue = factory();
        dispatch([key, initialValue]);
        ref.current = initialValue;
        return initialValue;
      }
    } else {
      throw Error("Cache is not initialized?");
    }
  }

  if (state && dispatch) {
    if (keys.length !== 0) {
      return [
        getOrInitKey(),
        (value) => {
          ref.current = value;
          dispatch([key, value]);
        }
      ];
    } else {
      ref.current = factory();
      return [
        ref.current,
        (value) => {
          ref.current = value;
        }
      ];
    }
  } else {
    throw Error("Cache is not initialized");
  }
}
