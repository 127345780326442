import * as React from "react";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import introspectionResultJson from "../services/graphql/introspectionResult.json";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { keyframes, styled } from "./theme";
import { useSessionEffect } from "../utils/useAppState";
import { GraphQL } from "../services/graphql/generated";
import { getCookie } from "../utils/getCookie";
import { Mixpanel } from "../services/mixpanel";
import { dataIdFromObject } from "../services/graphql/dataIdFromObject";
import { onError } from "apollo-link-error";
import { ErrorClass } from "../services/graphql/errorClass";
import { StaticRoutes } from "./routes/staticRoutes";

export namespace ObjectTypes {
  export namespace Connection {
    export const objectTypeName = "Connection";
    export const fragmentName = "Connection";
  }
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResultJson
});

export function cachedObjectId(objectType: string, objectId: string): string {
  return "{" + objectType + "}" + objectId;
}

export function mayBeCachedObjectId(objectType: string | undefined, objectId: string | undefined): string | undefined {
  return objectType !== undefined && objectId !== undefined
    ? cachedObjectId(objectType, objectId)
    : undefined;
}

export function cachedConnectionId(connectionId: string | undefined): string {
  return connectionId !== undefined ? cachedObjectId(ObjectTypes.Connection.objectTypeName, connectionId) : "";
}

// Additional typecast to "unknown" is required for the entities with numerics IDs, because "object" parameter has it
// defined as string

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object) => {
    const generated = dataIdFromObject(object);
    if (generated) {
      return generated;
    } else if (object.__typename === "FactPlaceholder") {
      return cachedObjectId("Fact", (object as GraphQL.FactPlaceholder).id);
    } else {
      const id = (() => {
        switch (object.__typename) {
          case "AccessList":
            return (object as unknown as GraphQL.AccessList).id.toString();

          case "AccessListUpdate":
            return (object as unknown as GraphQL.AccessListUpdate).id.toString();

          case "AuthProvider":
            return (object as GraphQL.AuthProvider).id;

          case "Batch":
            return (object as GraphQL.Batch).id;

          case "CloudService":
            return (object as GraphQL.CloudService).id;

          case "CloudServiceCategory":
            return (object as GraphQL.CloudServiceCategory).title;

          case "Configuration":
            return (object as unknown as GraphQL.Configuration).id.toString();

          case "Connection":
            return (object as GraphQL.Connection).id;

          case "CouponCode":
            return (object as GraphQL.CouponCode).id;

          case "Fact":
            return (object as GraphQL.Fact).id;

          case "GoogleGroup":
            return (object as GraphQL.GoogleGroup).internalId;

          case "Iteration":
            const iteration = object as GraphQL.Iteration;
            return iteration.migrationId + "/" + iteration.index;

          case "JobHistory":
            return (object as GraphQL.JobHistory).jobId;

          case "JobHistoryRecord": {
            const record = object as GraphQL.JobHistoryRecord;
            return record.id + "/" + record.updatedAt + "/" + record.updateSummary;
          }

          case "MigrationBlueprint": {
            const migrationBlueprint = object as GraphQL.MigrationBlueprint;
            return migrationBlueprint.sourceCloudServiceId + "/" + migrationBlueprint.destinationCloudServiceId;
          }

          case "Migration":
            return (object as GraphQL.Migration).id;

          case "MigrationIssue": {
            const migrationIssue = object as GraphQL.MigrationIssue;
            return migrationIssue.migrationId + "/" + migrationIssue.id;
          }

          case "MigrationIssueList":
            return (object as GraphQL.MigrationIssueList).migrationId;

          case "OffboardingProject":
            return (object as unknown as GraphQL.OffboardingProject).id;

          case "Organization":
            return (object as unknown as GraphQL.Organization).id.toString();

          case "OrganizationPortalUser":
            return (object as unknown as GraphQL.OrganizationPortalUser).id.toString();

          case "PricingModel":
            return (object as unknown as GraphQL.PricingModel).id.toString();

          case "Program":
            return (object as GraphQL.Program).id;

          case "ProgramEligibility":
            return (object as GraphQL.ProgramEligibility).program.id;

          case "ReferralCode":
            return (object as unknown as GraphQL.ReferralCode).id.toString();

          case "School":
            return (object as GraphQL.School).id;

          case "SchoolStats":
            return (object as GraphQL.SchoolStats).schoolId;

          case "TaskHistory": {
            const history = (object as GraphQL.TaskHistory);
            return history.jobId + "/" + history.taskId;
          }

          case "TaskHistoryRecord": {
            const record = object as GraphQL.TaskHistoryRecord;
            return record.jobId + "/" + record.taskId + "/" + record.transition +
              "/" + record.updatedAt + "/" + record.updateSummary;
          }

          case "TaskIssue": {
            const taskIssue = object as GraphQL.TaskIssue;
            // The same issue can be reported multiple times, so a timestamp is required to construct a unique id
            return taskIssue.jobId + "/" + taskIssue.taskId + "/" + taskIssue.transition +
              "/" + taskIssue.issueId + "/" + taskIssue.createdAt;
          }

          case "TaskSummaryRecord": {
            const record = object as GraphQL.TaskSummaryRecord;
            return record.jobId + "/" + record.taskId + "/" + record.transition;
          }

          case "EmailAddressWhitelistingForProgram": {
            const whitelisting = object as GraphQL.EmailAddressWhitelistingForProgram;
            return whitelisting.emailAddress;
          }

          case "Theme":
            return (object as unknown as GraphQL.Theme).id.toString();

          case "User":
            return (object as GraphQL.User).id;

          default: {
            return undefined;
          }
        }
      })();
      return mayBeCachedObjectId(object.__typename, id);
    }
  }
});

type SessionErrorListener = () => void;

const sessionErrorListeners: SessionErrorListener[] = [];

function addSessionErrorListener(listener: SessionErrorListener): void {
  if (sessionErrorListeners.indexOf(listener) === -1) {
    console.log("Added session error listener");
    sessionErrorListeners.push(listener);
  }
}

function removeSessionErrorListener(listener: SessionErrorListener): void {
  const index = sessionErrorListeners.indexOf(listener);
  if (index !== -1) {
    sessionErrorListeners.splice(index, 1);
    console.log("Removed session error listener");
  }
}

const errorLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    graphQLErrors.findIndex((error) =>
      // tslint:disable-next-line:no-string-literal
      error.extensions && error.extensions["class"] === ErrorClass.NotAuthorizedError
    ) !== -1
  ) {
    console.error("Received NotAuthorizedError: session has expired");
    sessionErrorListeners.forEach((listener) => listener());
  }
});

// See application.conf (csrf section)
const contextLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    "Csrf-Token": getCookie("csrfToken") || "",
    "Mp-Distinct-Id": Mixpanel.distinctId() || "undefined"
  }
}));

const httpLink = new HttpLink({
  uri: "/graphql",
  credentials: "same-origin"
});

const subscriptionClient = new SubscriptionClient(
  (window.location.protocol === "https:" ? "wss" : "ws") + "://" + window.location.host +
  "/graphql/subscribe/websockets",
  { reconnect: true }
);

enum ConnectionStatus {
  Connecting = "Connecting",
  Timeout = "Timeout",
  Connected = "Connected"
}

type ConnectionStatusListener = (status: ConnectionStatus) => void;

let currentConnectionStatus: ConnectionStatus = ConnectionStatus.Connecting;
const connectionStatusListeners: ConnectionStatusListener[] = [];
let timer: any;

function startTimer(): void {
  timer = setTimeout(
    () => {
      setConnectionStatus("Timeout", ConnectionStatus.Timeout);
      timer = undefined;
    },
    10000
  );
}

function stopTimer(): void {
  if (timer) {
    clearInterval(timer);
    timer = undefined;
  }
}

function addConnectionStatusListener(listener: ConnectionStatusListener): void {
  if (connectionStatusListeners.indexOf(listener) === -1) {
    console.log("[WS] Added listener");
    connectionStatusListeners.push(listener);
  }
}

function removeConnectionStatusListener(listener: ConnectionStatusListener): void {
  const index = connectionStatusListeners.indexOf(listener);
  if (index !== -1) {
    connectionStatusListeners.splice(index, 1);
    console.log("[WS] Removed listener");
  }
}

function setConnectionStatus(cause: string, newStatus: ConnectionStatus): void {
  console.log("[WS] " + cause + " => [" + newStatus + "]");
  currentConnectionStatus = newStatus;
  connectionStatusListeners.forEach((listener) => listener(newStatus));
}

function setConnected(cause: string): void {
  setConnectionStatus(cause, ConnectionStatus.Connected);
  stopTimer();
}

function setConnecting(cause: string, force: boolean = false): void {
  if (currentConnectionStatus === ConnectionStatus.Connected || force) {
    setConnectionStatus(cause, ConnectionStatus.Connecting);
    startTimer();
  }
}

setConnecting("Initializing", true);

subscriptionClient.onConnected(() => setConnected("Connected"));
subscriptionClient.onConnecting(() => setConnecting("Connecting"));
subscriptionClient.onDisconnected(() => setConnecting("Disconnected"));
subscriptionClient.onReconnected(() => setConnected("Reconnected"));
subscriptionClient.onReconnecting(() => setConnecting("Reconnecting"));

const wsLink = new WebSocketLink(subscriptionClient);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  contextLink.concat(errorLink).concat(httpLink),
);

export const apolloClient = new ApolloClient({
  cache,
  link
});

const fadeOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.8; }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.layers.connectionStatus};

  background: rgba(5,37,53,0.9);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  animation: ${fadeOut} 0.25s forwards;
  text-align: center;
`;

const Header = styled.div`
  font-size: 3rem;
`;

const Text = styled.div`
  font-size: 1.2rem;
  padding: 2rem 1rem 0 1rem;
  max-width: 30rem;
`;

interface ApolloClientProviderProps {
  children: (apolloClient: ApolloClient<any>) => React.ReactElement;
}

export const ApolloClientProvider: React.FunctionComponent<ApolloClientProviderProps> = (props) => {
  const [connectionStatusState, setConnectionStatusState] = React.useState<ConnectionStatus>(currentConnectionStatus);

  function flagSessionError(): void {
    window.location.assign(StaticRoutes.signedOutPath);
  }

  React.useEffect(
    () => {
      addConnectionStatusListener(setConnectionStatusState);
      setConnectionStatusState(currentConnectionStatus);

      addSessionErrorListener(flagSessionError);

      return () => {
        removeConnectionStatusListener(setConnectionStatusState);
        removeSessionErrorListener(flagSessionError);
      };
    },
    []
  );

  useSessionEffect(
    () => {
      console.log("[WS] Closing connection after sign in");
      subscriptionClient.close(false, false);
    }
  );

  return (
    <>
      {props.children(apolloClient)}
      {connectionStatusState === ConnectionStatus.Timeout && (
        <Overlay>
          <Header>Connecting...</Header>
          <Text>
            Please check your internet connection.
            <br/>
            If this message does not go away automatically please refresh this page in your browser.
          </Text>
        </Overlay>
      )}
    </>
  );
};
