import * as React from "react";
import { styled } from "../../../../app/theme";
import { AuthContext } from "../../authContext";
import { ConnectionPanelRow } from "../../connectionPanelRow";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { prepareActionItems } from "../prepareActionItems";
import { List } from "immutable";
import { WorkStatus } from "../../../models/workStatus";
import { ActionItems } from "../../../models/actionItem";

function totalConnectionAndAreaErrors(
  connection: ConnectionPanelDefs.ConnectionDetailsEx,
  areas: List<ConnectionPanelDefs.Area>
): number {
  return (
    ActionItems.Stats.build(prepareActionItems({
      subjectId: connection.connection.id,
      affectedSubject: undefined,
      ...connection
    })).errors +
    areas
      .map((area) => ActionItems.Stats.build(prepareActionItems({
        subjectId: area.areaId,
        affectedSubject: area.mainSubject,
        ...area
      })).errors)
      .reduce((a, b) => a + b, 0)
  );
}

export function shouldDisplayScanningCompleteRow(
  connection: ConnectionPanelDefs.ConnectionDetailsEx,
  areas: List<ConnectionPanelDefs.Area>,
  viewSettings: ConnectionPanelDefs.ViewSettings): boolean {
  // NOTE: The value of WithNoErrors is no longer used - can viewSettings.showSuccessRow become a boolean?
  if (viewSettings.showSuccessRow === ConnectionPanelDefs.ShowSuccessRow.Always) {
    return true;
  } else if (viewSettings.showSuccessRow === ConnectionPanelDefs.ShowSuccessRow.Never) {
    return false;
  } else {
    return totalConnectionAndAreaErrors(connection, areas) === 0;
  }
}

const Title = styled.span`
  font-weight: ${(props) => props.theme.font.medium};
  
  em {
    text-decoration: underline;
    font-style: normal;
  }
`;

interface ScanningCompleteRowProps {
  authContext: AuthContext;
  pendingActions: boolean;
  checkboxPlaceholder: boolean;
  showIssues: boolean;
  connection: ConnectionPanelDefs.ConnectionDetailsEx;
  successMessage?: string;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const ScanningCompleteRow: React.FunctionComponent<ScanningCompleteRowProps> = (props) => {
  const issues = prepareActionItems({
    subjectId: props.connection.connection.id,
    affectedSubject: undefined,
    ...props.connection
  });
  return (
    <ConnectionPanelRow
      icon={<div/>}
      title={(
        <Title>
          Account connected and&nbsp;
          {props.pendingActions && <><em>partially</em>&nbsp;</>}
          {AuthContext.choose(props.authContext, "itemized", "tested")}
        </Title>
      )}
      content={{
        summary: (issues.isEmpty() || !props.showIssues) &&
          (props.successMessage || "You may now proceed to the next step"),
        actionItems: issues
      }}
      checkBox={props.checkboxPlaceholder ? "placeholder" : undefined}
      progressIndicator={"large"}
      status={WorkStatus.Success}
      helpArticle={"placeholder"}
      actionItemSuppressing={props.actionItemSuppressing}
    />
  );
};
