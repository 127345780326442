import * as React from "react";
import { Step, StepSubmitHandle } from "../../../../containers/step";
import { Button } from "../../../../widgets/button";
import { List } from "immutable";
import { GridPanelRow } from "../../../../containers/rows/gridPanelRow";
import { Panel } from "../../../../containers/panel";
import { BatchMigrationItemsGrid, BatchMigrationItemsGridRow } from "./batchMigrationItemsGrid";
import { Block } from "../../../../containers/block";
import { CloudService } from "../../../../../types/models/cloudService";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { friendlyCount } from "../../../../../utils/formatting";
import { SimpleToolbar } from "../../../../widgets/simpleToolbar";
import { Modal, useModal } from "../../../../layouts/modal";
import { OperationStatus } from "../../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { Constants } from "../../../../../app/constants";
import { Paragraph } from "../../../../widgets/paragraph";
import { AddMultipleRowsControllerProps, AddMultipleRowsForm } from "./addMultipleRowsForm";
import { ReScanAllControllerProps, ReScanAllForm } from "./reScanAllForm";
import { SingleAccountHint } from "./singleAccountHint";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../../../app/routes/useRoutes";

interface EditConfig {
  content: React.ReactNode;
  adding: boolean;
}

interface Props {
  step: number;
  totalSteps: number;
  onProceed: StepSubmitHandle;
  onNavigateBack?: () => void;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  items: List<BatchMigrationItemsGridRow>;
  edit: EditConfig | undefined;

  sourceConnectionMethod: "admin" | "end-user" | "self";
  destinationConnectionMethod: "admin" | "end-user" | "self";

  sourceConnectLink: string;
  destinationConnectLink: string;

  addingRowsDisabled: boolean;
  addMultipleRowsController: React.ComponentType<AddMultipleRowsControllerProps>;
  reScanAllController: React.ComponentType<ReScanAllControllerProps>;

  onAddRow: () => void;
  onEditRow: (index: number) => void;
  onRemoveRow: (index: number) => void;
  onClear: () => void;

  onDownloadScanReport: () => void;
  downloadScanReportStatus: OperationStatus<any>;
}

export const BatchMigrationPlanStepView: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes().batchMigrationSetup;
  const multipleRowsModal = useModal();
  const clearModal = useModal();
  const reScanModal = useModal();

  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title={"Migration Plan"}
      subTitle={"Configure the source and the corresponding destination accounts."}
      onSubmit={props.onProceed}
      submitHidden={!!props.edit}
      submitDisabled={props.items.isEmpty()}
      onNavigateBack={props.edit ? undefined : props.onNavigateBack}
    >
      {multipleRowsModal.isOpen && (
        <Modal title={"Add Accounts"} onClose={multipleRowsModal.close} wide={true}>
          {
            React.createElement(props.addMultipleRowsController, {
              render: (status, onSubmit) => (
                <AddMultipleRowsForm
                  sourceCloudService={props.sourceCloudService}
                  destinationCloudService={props.destinationCloudService}

                  sourceConnectionMethod={props.sourceConnectionMethod}
                  destinationConnectionMethod={props.destinationConnectionMethod}

                  sourceConnectLink={props.sourceConnectLink}
                  destinationConnectLink={props.destinationConnectLink}

                  isEmpty={props.items.isEmpty()}
                  disabled={props.addingRowsDisabled}
                  status={status}

                  onSubmit={(value) => onSubmit(value).then((missingEmailAddresses) => {
                    if (!missingEmailAddresses) {
                      multipleRowsModal.close();
                    }
                    return missingEmailAddresses;
                  })}
                  onCancel={multipleRowsModal.close}
                />
              )
            })
          }
        </Modal>
      )}

      {clearModal.isOpen && (
        <Modal title={"Remove All"} onClose={clearModal.close}>
          <Panel>
            <PanelRow>
              Clicking the button below will remove all added accounts from the migration plan.
            </PanelRow>
            <PanelRow>
              <Button
                size="narrow"
                onClick={() => {
                  props.onClear();
                  clearModal.close();
                }}
              >
                Remove All Accounts
              </Button>
            </PanelRow>
          </Panel>
        </Modal>
      )}

      {reScanModal.isOpen && (
        <Modal title={"Re-Scan All"} onClose={reScanModal.close}>
          {
            React.createElement(props.reScanAllController, {
              render: (status, onReScanAll) => (
                <ReScanAllForm
                  status={status}
                  onReScan={() =>
                    onReScanAll().then(() =>
                      setTimeout(() => reScanModal.close(), Constants.SuccessIndicatorTimeout)
                    )
                  }
                />
              )
            })
          }
        </Modal>
      )}

      {props.edit
        ? (
          <>
            {/*<Header>&#10149; {props.edit.adding ? "Add" : "Edit"} Accounts</Header>*/}
            {props.edit.content}
          </>
        )
        : (
          <>
            {props.items.count() === 1 && (
              <Block>
                <SingleAccountHint
                  adding={false}
                  sourceCloudService={props.sourceCloudService}
                  destinationCloudService={props.destinationCloudService}
                  item={props.items.first()}
                />
              </Block>
            )}
            <Block>
              <Panel>
                <OperationStatusIndicator
                  status={props.downloadScanReportStatus}
                  progressMessage={"Generating a scan report..."}
                  indicators={StatusIndicators.PanelRow()}
                />
                <PanelRow>
                  <SimpleToolbar
                    centerVertically={true}
                    rightSide={(
                      <>
                        <SimpleToolbar>
                          {/*<Button color="white" size="small" onClick={props.onAddRow}>Add Row</Button>*/}
                          <Button color="blue" size="small" onClick={multipleRowsModal.open}>Add Accounts</Button>
                          <Button
                            color="white"
                            size="small"
                            onClick={props.onDownloadScanReport}
                            disabled={props.items.isEmpty() || props.downloadScanReportStatus.isWorking()}
                          >
                            Download Scan Report
                          </Button>
                          <Button
                            color="white"
                            size="small"
                            onClick={reScanModal.open}
                            disabled={props.items.isEmpty()}
                          >
                            Re-Scan All
                          </Button>
                          <Button
                            color="white"
                            size="small"
                            onClick={clearModal.open}
                            disabled={props.items.isEmpty()}
                          >
                            Remove All
                          </Button>
                        </SimpleToolbar>
                      </>
                    )}
                  >
                    <div>{friendlyCount(props.items.size, "account")} added</div>
                  </SimpleToolbar>
                </PanelRow>
                {props.items.isEmpty()
                  ? (
                    <PanelRow>
                      <Paragraph>
                        Use the "Add Accounts" button above to add accounts to this migration.
                      </Paragraph>
                      {(props.sourceConnectionMethod !== "admin" || props.destinationConnectionMethod !== "admin") && (
                        <Paragraph>
                          <strong>Important!</strong> All{" "}
                          {
                            props.sourceConnectionMethod !== "admin" && props.destinationConnectionMethod !== "admin"
                              ? "source and destination"
                              : props.sourceConnectionMethod !== "admin"
                              ? "source"
                              : "destination"
                          }{" "}
                          accounts must be connected to VaultMe before they can be added to this migration.
                          Please use the instructions given on the{" "}
                          {
                            props.sourceConnectionMethod !== "admin" && props.destinationConnectionMethod !== "admin"
                              ? (
                                <>
                                  <Link to={routes.sourcePath}>Migrate From</Link>
                                  {" "}and{" "}
                                  <Link to={routes.destinationPath}>Migrate To</Link>
                                  {" "}steps
                                </>
                              )
                              : props.sourceConnectionMethod !== "admin"
                              ? <><Link to={routes.sourcePath}>Migrate From</Link> step</>
                              : <><Link to={routes.destinationPath}>Migrate To</Link> step</>
                          }{" "}
                          {" "}to connect your accounts.
                        </Paragraph>
                      )}
                    </PanelRow>
                  )
                  : (
                    <GridPanelRow>
                      <BatchMigrationItemsGrid
                        sourceCloudService={props.sourceCloudService}
                        destinationCloudService={props.destinationCloudService}
                        items={props.items}
                        onEditRow={props.onEditRow}
                        onRemoveRow={props.onRemoveRow}
                      />
                    </GridPanelRow>
                  )}
              </Panel>
            </Block>
          </>
        )
      }
    </Step>
  );
};
