import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { OffboardingProject } from "./offboardingProject";

export interface AccessList {
  readonly id: number;
  readonly organizationId: number;
  readonly name: string;
  readonly notes: string | undefined;

  readonly isEnabled: boolean;
  readonly isProgramAllowlist: boolean;
  readonly program: OffboardingProject.Program | undefined;
  readonly blockingMessage: string | undefined;

  readonly googleGroupId: string | undefined;
  readonly currentUpdateId: number | undefined;
  readonly lastUpdateStartedAt: Date | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace AccessList {
  export interface Program {
    readonly id: string;
    readonly name: string;
  }

  export function parse(data: GraphQL.AccessListFragment): AccessList {
    return {
      id: data.id,
      organizationId: data.organizationId,
      name: data.name,
      notes: nullToUndefined(data.notes),

      isEnabled: data.isEnabled,
      isProgramAllowlist: data.isProgramAllowlist,
      program: mapOptional(data.program, (program) => ({
        id: program.id,
        name: program.name
      })),
      blockingMessage: nullToUndefined(data.blockingMessage),

      googleGroupId: nullToUndefined(data.googleGroupId),
      currentUpdateId: nullToUndefined(data.currentUpdateId),
      lastUpdateStartedAt: mapOptional(data.lastUpdateStartedAt, (lastUpdateStartedAt) =>
        new Date(lastUpdateStartedAt)
      ),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(accessListId: number): string {
    return cachedObjectId("AccessList", accessListId.toString());
  }

  export const fragmentName = "AccessList";
}
