import { BlueprintContext } from "../blueprintContext";
import { RelationshipLocator } from "../blueprintComponents";
import { ComponentVisualization } from "../componentVisualization";
import { AnyHub } from "../hub";
import { AnyComponent } from "../component";
import { AnyRelationship } from "../relationship";
import { BlueprintFilters, FilterState } from "../../state/settings/blueprintFilters";
import { Cluster, Graph } from "./graphView";
import { List, Map, Set } from "immutable";
import { MaterializedBlueprint } from "../materializedBlueprint";
import { ComponentColorSchema } from "../componentColorSchema";
import { BlueprintError } from "../blueprintError";

function renderComponent(context: BlueprintContext, filters: BlueprintFilters, component: AnyComponent): Graph.Node {
  const state = component.state(context);
  const visualization = component.visualization(context, state);
  const colorSet = ComponentVisualization.colorSchema(visualization, component.settings);
  return {
    id: component.id,
    title: visualization.title,
    summary: visualization.summary,
    icon: ComponentVisualization.icon(visualization, component.settings),
    elementState: state,
    hidden: BlueprintFilters.isComponentHidden(filters, component),
    progress: state.progress.getOrElse(() => undefined),
    backgroundColor: colorSet.background,
    borderColor: colorSet.border,
    enabled: !visualization.disabled,
    size: ComponentVisualization.size(visualization, component.settings),
    fontSize: ComponentVisualization.fontSize(visualization, component.settings)
  };
}

const EdgeIdSeparator = " ::: ";

function renderRelationship(
  context: BlueprintContext,
  filters: BlueprintFilters,
  component: AnyComponent,
  hub: AnyHub,
  relationship: AnyRelationship): Graph.Edge {
  const hubState = hub.state(context);
  const relationshipState = relationship.state(context);

  const styling = {
    ...hub.defaultRelationshipStyling(context, hubState),
    ...relationship.styling(context, relationshipState)
  };

  return {
    id: component.id + EdgeIdSeparator + hub.title + EdgeIdSeparator + relationship.componentId,
    title: relationship.props.title || hub.defaultRelationshipTitle(),
    from: component.id,
    to: relationship.componentId,
    elementState: relationshipState,
    hidden: BlueprintFilters.isRelationshipHidden(filters, hub),
    color: relationshipState.isError && BlueprintError.shouldHighlight(relationshipState.errors)
      ? ComponentColorSchema.SolidRed.border
      : styling.color,
    width: styling.width || 1,
    dashes: styling.dashes
  };
}

export function parseEdgeId(edgeId: string): RelationshipLocator | undefined {
  const parts = edgeId.split(EdgeIdSeparator);
  if (parts.length === 3) {
    return {
      originComponentId: parts[0],
      hubTitle: parts[1],
      targetComponentId: parts[2]
    };
  } else {
    return undefined;
  }
}

function edgeKey(edge: Graph.Edge): string {
  return edge.title + " / " + edge.to;
}

function componentEdges(
  component: AnyComponent,
  context: BlueprintContext,
  filters: BlueprintFilters
): List<Graph.Edge> {
  const allEdges = component
    .hubList()
    .filter((hub) => !BlueprintFilters.isRelationshipHidden(filters, hub))
    .map((hub) =>
      hub.relationships
        .filter((relationship) => relationship.componentId !== "")
        .map((relationship) => renderRelationship(context, filters, component, hub, relationship))
    )
    .reduce((a, b) => a.concat(b), List());

  let stats = Map<string, number>();
  allEdges.forEach((edge) => {
    const key = edgeKey(edge);
    stats = stats.set(key, stats.get(key, 0) + 1);
  });

  let result = List<Graph.Edge>();
  stats.forEach((count, key) => {
    const firstEdge = allEdges.find((edge) => edgeKey(edge) === key);
    if (firstEdge) {
      result = count > 1
        ? result.push({ ...firstEdge, title: firstEdge.title + " * " + count })
        : result.push(firstEdge);
    }
  });

  return result;
}

function buildClusters(
  segments: Set<string>,
  components: List<AnyComponent>,
  filters: BlueprintFilters,
  onFiltersUpdate: (filters: BlueprintFilters) => void
): Map<string, Cluster> {
  const pinnedTags = filters.filteredTags.filter((filter) => filter === FilterState.Pin).keySeq().toSet();
  let remainingComponents = components.toSet();

  return segments.map((segment) => Map(
    filters.filteredTags
      .filter((filter) => filter === FilterState.Collapse)
      .keySeq()
      .map((tag): [string, number] => [
        tag,
        components
          .filter((component) =>
            component.inSegment(segment) &&
            component.tags.has(tag) &&
            component.tags.intersect(pinnedTags).isEmpty()
          )
          .size
      ])
      .sortBy((pair) => pair[1])
      .reverse()
      .map((pair): [string, Cluster] => {
        const tag = pair[0];
        const taggedComponents = remainingComponents.filter((component) =>
          component.inSegment(segment) &&
          component.tags.has(tag) &&
          component.tags.intersect(pinnedTags).isEmpty()
        );
        remainingComponents = remainingComponents.subtract(taggedComponents);
        return [
          "__cluster:" + segment + ":" + tag,
          {
            title: "#"  + tag + " (" + taggedComponents.size + ")",
            size: taggedComponents.size,
            nodeIds: taggedComponents.map((component) => component.id).toSet(),
            onDoubleClick: () => onFiltersUpdate({
              ...filters,
              filteredTags: filters.filteredTags.set(tag, FilterState.Show)
            })
          }
        ];
      })
      .filter(([id, cluster]) => cluster.size !== 0)
    )
  ).reduce((a, b) => a.merge(b));
}

export function renderBlueprint(
  blueprint: MaterializedBlueprint,
  filters: BlueprintFilters,
  onFiltersUpdate: (filters: BlueprintFilters) => void
): Graph {
  const filteringOptions = blueprint.blueprint.components.filteringOptions();
  const blueprintComponents = blueprint.blueprint.allComponents()
    .filter((component) => !BlueprintFilters.isComponentHidden(filters, component));
  return {
    nodes: blueprintComponents
      .map((component) => renderComponent(blueprint.context, filters, component)),
    edges: blueprintComponents
      .map((component) => componentEdges(component, blueprint.context, filters))
      .reduce((a, b) => a.concat(b), List()),
    clusters: buildClusters(filteringOptions.segments.keySeq().toSet(), blueprintComponents, filters, onFiltersUpdate)
  };
}
