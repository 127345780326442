import * as React from "react";
import * as moment from "moment";
import { MigrationStatus } from "../../types/models/migrationStatus";
import { List, Map, OrderedMap, Set } from "immutable";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { OperationStatus } from "../../types/operationStatus";
import { Migration } from "../../types/models/migration";
import { None, Option, Some } from "../../utils/monads/option";
import { Connection, ConnectionClass } from "../../types/models/connection";
import { AccessKeyStatus } from "../../types/models/accessKeyStatus";
import { PersonalInfo } from "../../types/models/personalInfo";
import { ValidReportUrlPrefix } from "../../views/screens/migrationStatusPageView/components/migrationReportRow";
import { MigrationStatusPageView } from "../../views/screens/migrationStatusPageView";
import { MigrationStatusPageDefs, } from "../../views/screens/migrationStatusPageView/migrationStatusPageDefs";
import { ItemsByType } from "../../views/models/itemsByType";
import { WorkStatus } from "../../views/models/workStatus";
import { MigrationStatusPageState } from "../../views/screens/migrationStatusPageView/migrationStatusPageState";
import { CloudService } from "../../types/models/cloudService";
import { RawImages } from "../../app/rawImages";
import { useQuery } from "../../utils/useQuery";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { useRoutes } from "../../app/routes/useRoutes";
import { SignInContextType } from "../../types/models/signInContextType";

const Gmail = "gmail";
const GoogleWorkspace = "gsuite";
const Outlook = "outlook";
const Microsoft365 = "office365";

interface AreaConfig {
  id: string;
  title: string;
  description: string;
  icon: any;
  itemType: string;
}

interface ServiceConfig {
  email: AreaConfig;
  ownedFiles: AreaConfig;
  sharedFiles: AreaConfig;
  filesFromSharedDrives: AreaConfig;
  calendar: AreaConfig;
  contacts: AreaConfig;
}

interface DataSize {
  items: number;
  gigabytes: number;
}

interface BuildAreaParams {
  sourceEmailAddress: string;
  destinationEmailAddress: string;
  areaConfig: AreaConfig;
  sinkConfig: AreaConfig;
  dataSize: DataSize;
  cursorPosition: number;
}

function buildArea(params: BuildAreaParams): MigrationStatusPageDefs.SourceArea {
  const migratedItems = Math.min(params.cursorPosition, params.dataSize.items);
  const migrationProgress = migratedItems / params.dataSize.items;
  const routes = useRoutes();

  const stats: MigrationStatusPageDefs.SourceArea.Stats = {
    totalItems: new ItemsByType(OrderedMap(
      [[params.areaConfig.itemType, params.dataSize.items]]
    )),
    migratedItems: new ItemsByType(OrderedMap(
      [[params.areaConfig.itemType, Math.floor(params.dataSize.items * migrationProgress)]]
    )),
    skippedItems: new ItemsByType(OrderedMap()),
    totalBytes: params.dataSize.gigabytes * 1024 * 1024 * 1024,
    migratedBytes: params.dataSize.gigabytes * 1024 * 1024 * 1024 * migrationProgress
  };

  return {
    areaId: params.areaConfig.description,
    appTitle: params.areaConfig.description,
    mainSubject: params.areaConfig.itemType,
    title: params.areaConfig.description,
    description: params.areaConfig.description,
    icon: params.areaConfig.icon,

    status: migrationProgress < 1 ? WorkStatus.Working : WorkStatus.Success,
    actionItems: List(),
    disabled: undefined,
    incrementalSignIn: undefined,
    helpArticle: PreparedHelpArticle.migrationRules({
      routes,
      phase: migrationProgress < 1 ? "during-migration" : "after-migration",
      sourceAppId: params.areaConfig.id,
      destinationAppId: params.sinkConfig.id,
      sourceAppTitle: params.areaConfig.title,
      destinationAppTitle: params.sinkConfig.title,
      vars: Map([
        ["source", params.sourceEmailAddress],
        ["destination", params.destinationEmailAddress],
      ])
    }),

    excluded: false,
    slowness: undefined,
    cumulative: stats,
    iteration: stats
  };
}

const microsoftConfiguration: ServiceConfig = {
  email: {
    id: "outlook",
    title: "Outlook",
    description: "Outlook messages, attachments and folders",
    icon: RawImages["/assets/images/cloudServices/microsoft/outlook.svg"],
    itemType: "email"
  },
  ownedFiles: {
    id: "oneDrive",
    title: "OneDrive",
    description: "OneDrive files and folders OWNED BY ME",
    icon: RawImages["/assets/images/cloudServices/microsoft/one-drive.svg"],
    itemType: "file"
  },
  sharedFiles: {
    id: "oneDrive",
    title: "OneDrive",
    description: "OneDrive files and folders SHARED WITH ME",
    icon: RawImages["/assets/images/cloudServices/microsoft/one-drive.svg"],
    itemType: "file"
  },
  filesFromSharedDrives: {
    id: "oneDrive",
    title: "OneDrive",
    description: "OneDrive files and folders FROM SHARED LIBRARIES",
    icon: RawImages["/assets/images/cloudServices/microsoft/one-drive.svg"],
    itemType: "file"
  },
  calendar: {
    id: "outlookCalendar",
    title: "Outlook Calendar",
    description: "Calendars OWNED BY ME",
    icon: RawImages["/assets/images/cloudServices/microsoft/calendar.svg"],
    itemType: "event"
  },
  contacts: {
    id: "outlookPeople",
    title: "Outlook People",
    description: "Contacts and folders",
    icon: RawImages["/assets/images/cloudServices/microsoft/people.svg"],
    itemType: "contact",
  }
};

const googleConfiguration: ServiceConfig = {
  email: {
    id: "gmail",
    title: "Gmail",
    description: "Gmail messages, attachments and folders",
    icon: RawImages["/assets/images/cloudServices/google/gmail.svg"],
    itemType: "email"
  },
  ownedFiles: {
    id: "googleDrive",
    title: "Google Drive",
    description: "Google Drive files and folders OWNED BY ME",
    icon: RawImages["/assets/images/cloudServices/google/drive.svg"],
    itemType: "file"
  },
  sharedFiles: {
    id: "googleDrive",
    title: "Google Drive",
    description: "Google Drive files and folders SHARED WITH ME",
    icon: RawImages["/assets/images/cloudServices/google/drive.svg"],
    itemType: "file"
  },
  filesFromSharedDrives: {
    id: "googleDrive",
    title: "Google Drive",
    description: "Google Drive files and folders FROM SHARED LIBRARIES",
    icon: RawImages["/assets/images/cloudServices/google/drive.svg"],
    itemType: "file"
  },
  calendar: {
    id: "googleCalendar",
    title: "Google Calendar",
    description: "Calendars OWNED BY ME",
    icon: RawImages["/assets/images/cloudServices/google/calendar.svg"],
    itemType: "event"
  },
  contacts: {
    id: "googleContacts",
    title: "Google Contacts",
    description: "Contacts and folders",
    icon: RawImages["/assets/images/cloudServices/google/contacts.svg"],
    itemType: "contact"
  }
};

function getConfiguration(cloudServiceId: string): ServiceConfig {
  if (cloudServiceId === Gmail || cloudServiceId === GoogleWorkspace) {
    return googleConfiguration;
  } else {
    return microsoftConfiguration;
  }
}

interface SampleData {
  email: DataSize;

  ownedFiles: DataSize;
  ownedFolders: DataSize;
  sharedFiles: DataSize;
  sharedFolders: DataSize;
  filesFromSharedDrives: DataSize;
  foldersFromSharedDrives: DataSize;
  trashedFiles: DataSize;
  trashedFolders: DataSize;

  calendars: DataSize;
  events: DataSize;

  contacts: DataSize;
  contactGroups: DataSize;
}

function buildAreas(
  sourceEmailAddress: string,
  destinationEmailAddress: string,
  source: ServiceConfig,
  destination: ServiceConfig,
  data: SampleData,
  cursorPosition: number
): MigrationStatusPageDefs.SourceArea[] {
  return [
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.email,
      sinkConfig: destination.email,
      dataSize: data.email,
      cursorPosition
    }),
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.ownedFiles,
      sinkConfig: destination.ownedFiles,
      dataSize: data.ownedFiles,
      cursorPosition
    }),
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.sharedFiles,
      sinkConfig: destination.sharedFiles,
      dataSize: data.sharedFiles,
      cursorPosition
    }),
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.filesFromSharedDrives,
      sinkConfig: destination.filesFromSharedDrives,
      dataSize: data.filesFromSharedDrives,
      cursorPosition
    }),
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.calendar,
      sinkConfig: destination.calendar,
      dataSize: data.events,
      cursorPosition
    }),
    buildArea({
      sourceEmailAddress,
      destinationEmailAddress,
      areaConfig: source.contacts,
      sinkConfig: destination.contacts,
      dataSize: data.contacts,
      cursorPosition
    }),
  ];
}

interface BuildDataParams {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  sourceEmailAddress: string;
  destinationEmailAddress: string;

  email: DataSize;

  ownedFiles: DataSize;
  ownedFolders: DataSize;
  sharedFiles: DataSize;
  sharedFolders: DataSize;
  filesFromSharedDrives: DataSize;
  foldersFromSharedDrives: DataSize;
  trashedFiles: DataSize;
  trashedFolders: DataSize;

  calendars: DataSize;
  events: DataSize;

  contacts: DataSize;
  contactGroups: DataSize;

  spinUpDuration: number;
  migrationDuration: number;
  tick: number;
}

interface Data {
  sourceConnection: Connection;
  destinationConnection: Connection;
  migration: Migration & Migration.HasOrderSummary;
  areas: MigrationStatusPageDefs.SourceArea[];
  timeRemaining: MigrationStatusPageDefs.RemainingMigrationTime;
}

function builtData(params: BuildDataParams): Data {
  const migrationId = "VWXYZ";
  const migrationDurationMinutes = 3 * 60;

  const allData = List([
    params.email,

    params.ownedFiles,
    params.ownedFolders,
    params.sharedFiles,
    params.sharedFolders,
    params.filesFromSharedDrives,
    params.foldersFromSharedDrives,
    params.trashedFiles,
    params.trashedFolders,

    params.calendars,
    params.events,

    params.contacts,
  ]);

  const maxItems = allData.map((data) => data.items).max() || 0;
  const totalItems = allData.map((data) => data.items).reduce((a, b) => a + b, 0);
  const totalGigabytes = allData.map((data) => data.gigabytes).reduce((a, b) => a + b, 0);

  const itemsPerTick = maxItems / params.migrationDuration;
  const migrationTick = Math.max(0, params.tick - params.spinUpDuration);
  const migrationProgress = migrationTick / params.migrationDuration;
  const cursorPosition = itemsPerTick * migrationTick;

  const migratedItems = allData
    .map((data) => Math.min(cursorPosition, data.items))
    .reduce((a, b) => a + b, 0);

  const createdAt = moment().add(-(migrationDurationMinutes * migrationProgress), "minute").toDate();
  const elapsedTime = migrationDurationMinutes * migrationProgress * 60;
  const optimisticRemainingTime = migrationDurationMinutes * (1 - migrationProgress) * 60;

  const sourceConnection = new Connection({
    id: "sourceConnectionId",
    userId: "",

    cloudServiceId: params.sourceCloudService.id,
    accessKeyProvider: params.sourceCloudService.authProviderId,
    accessKeyStatus: AccessKeyStatus.Valid,
    roles: Set(),
    class: ConnectionClass.Educational,

    description: params.sourceEmailAddress,
    personalInfo: PersonalInfo.empty,

    metadata: undefined,

    enabled: true,
    eligibleForSignIn: true,

    createdAt: new Date(),
    updatedAt: new Date(),
    validatedAt: new Date(),
    signedInAt: new Date(),
    revealedAt: undefined,
    revokedAt: undefined
  });

  const destinationConnection = new Connection({
    id: "destinationConnectionId",
    userId: "",

    cloudServiceId: params.destinationCloudService.id,
    accessKeyProvider: params.destinationCloudService.authProviderId,
    accessKeyStatus: AccessKeyStatus.Valid,
    roles: Set(),
    class: ConnectionClass.Educational,

    description: params.destinationEmailAddress,
    personalInfo: PersonalInfo.empty,

    metadata: undefined,

    enabled: true,
    eligibleForSignIn: true,

    createdAt: new Date(),
    updatedAt: new Date(),
    validatedAt: new Date(),
    signedInAt: new Date(),
    revealedAt: undefined,
    revokedAt: undefined
  });

  const migration: Migration & Migration.HasOrderSummary = {
    id: migrationId,
    batch: undefined,
    userId: "",

    sourceCloudServiceId: params.sourceCloudService.id,
    destinationCloudServiceId: params.destinationCloudService.id,
    sourceConnectionId: sourceConnection.id,
    destinationConnectionId: destinationConnection.id,

    blueprintInputs: Map(),

    totalBytesEstimate: 41273461248,
    totalItemsEstimate: totalItems,
    timeEstimate: migrationDurationMinutes * 60,

    iteration: 0,
    status: params.tick < params.spinUpDuration
      ? MigrationStatus.Starting
      : migrationProgress < 1
        ? MigrationStatus.Running
        : MigrationStatus.Completed,
    autoResumeAt: undefined,
    isAutoResumingEnabled: true,

    cumulativeStats: {
      totalBytes: totalGigabytes * 1024 * 1024 * 1024,
      totalItems,
      migratedBytes: 0,
      migratedItems,
      skippedBytes: 0,
      skippedItems: 0,

      progress: migrationProgress,
      skippedPercentage: 0
    },
    iterationStats: {
      totalBytes: totalGigabytes * 1024 * 1024 * 1024,
      totalItems,
      migratedBytes: 0,
      migratedItems,
      skippedBytes: 0,
      skippedItems: 0,

      progress: migrationProgress,
      skippedPercentage: 0
    },

    optimisticRemainingTime,
    longestTrackProgress: undefined,

    itemsForManualReview: undefined,
    totalManualReviews: 0,
    supervisedBy: undefined,

    reportUrl: ValidReportUrlPrefix,

    createdAt,
    updatedAt: new Date(),
    startedAt: createdAt,
    completedAt: migrationProgress === 1 ? new Date() : undefined,
    iterationStartedAt: undefined,
    iterationCompletedAt: undefined,

    jobId: "",
    workflowUrl: "",

    totalTimeInWork: elapsedTime,

    mouseFlowRecordingsUrl: "",
    startedOrScheduledAt: createdAt,
    timing: new Migration.RunningTiming({
      startedAt: createdAt,
      originalTimeEstimateOption: migrationDurationMinutes * 60,
      optimisticRemainingTimeOption: optimisticRemainingTime,
      longestTrackProgressOption: undefined
    }),
    totalItemsForManualReview: 0,
    progress: migrationProgress,
    skippedPercentage: 0,

    orderSummary: None(),
  };

  return {
    sourceConnection,
    destinationConnection,
    migration,
    areas: buildAreas(
      sourceConnection.descriptionOrId(),
      destinationConnection.descriptionOrId(),
      getConfiguration(sourceConnection.cloudServiceId),
      getConfiguration(destinationConnection.cloudServiceId),
      params,
      cursorPosition
    ),
    timeRemaining: {
      title: "migration",
      value: {
        estimatedTime: Some(migrationDurationMinutes * 60),
        progress: migrationProgress,
        remainingTime: optimisticRemainingTime
      }
    }
  };
}

function parseCloudServiceIdParam(valueOpt: Option<string>, defaultValue: string): string {
  return valueOpt
    .map((value) => {
      switch (value) {
        case "gmail": return Gmail;
        case "gw": return GoogleWorkspace;
        case "outlook": return Outlook;
        case "m365": return Microsoft365;
        default: return defaultValue;
      }
    })
    .getOrUse(defaultValue);
}

export const MigrationSimulationPage: React.FunctionComponent = (props) => {
  const query = useQuery();
  const sourceCloudServices = useCloudServices(SignInContextType.Source);
  const destinationCloudServices = useCloudServices(SignInContextType.Destination);
  const authProviders = useAuthProviders();

  const spinUpDuration = 3;
  const migrationDuration = query.getStringOption("migration").map(Number.parseInt).getOrUse(30);
  const pauseDuration = query.getStringOption("pause").map(Number.parseInt).getOrUse(10);
  const totalDuration = spinUpDuration + migrationDuration + pauseDuration;

  const sourceCloudServiceId = parseCloudServiceIdParam(query.getStringOption("sourceService"), "office365");
  const destinationCloudServiceId = parseCloudServiceIdParam(query.getStringOption("destinationService"), "gmail");

  const sourceCloudService = sourceCloudServices.getOrFail(sourceCloudServiceId);
  const destinationCloudService = destinationCloudServices.getOrFail(destinationCloudServiceId);

  const [tick, setTick] = React.useState(0);

  React.useEffect(
    () => {
      const timer = setInterval(
        () => setTick((value) => value >= totalDuration && pauseDuration !== 0 ? 0 : value + 1),
        1000
      );
      return () => clearInterval(timer);
    }
  );

  const { sourceConnection, destinationConnection, migration, areas, timeRemaining } = builtData({
    sourceCloudService,
    destinationCloudService,

    sourceEmailAddress: query.getStringOption("sourceEmailAddress")
      .getOrUse("student@college.edu"),
    destinationEmailAddress: query.getStringOption("destinationEmailAddress")
      .getOrUse("student.at.college@gmail.com"),

    email: { items: 59612, gigabytes: 9.45 },

    ownedFiles: { items: 16935, gigabytes: 5.98 },
    ownedFolders: { items: 481, gigabytes: 0 },
    sharedFiles: { items: 46718, gigabytes: 14.99 },
    sharedFolders: { items: 3918, gigabytes: 0 },
    filesFromSharedDrives: { items: 9815, gigabytes: 0.64 },
    foldersFromSharedDrives: { items: 98, gigabytes: 0 },
    trashedFiles: { items: 123, gigabytes: 0.01 },
    trashedFolders: { items: 4, gigabytes: 0 },

    calendars: { items: 5, gigabytes: 0 },
    events: { items: 19648, gigabytes: 0 },

    contacts: { items: 1394, gigabytes: 0 },
    contactGroups: { items: 1765, gigabytes: 0 },

    spinUpDuration,
    migrationDuration,
    tick: Math.min(spinUpDuration + migrationDuration, tick)
  });

  return (
    <MigrationStatusPageView
      loadingStatus={OperationStatus.Pending()}
      source={{
        cloudService: sourceCloudService,
        authProvider: authProviders.getOrFail(sourceConnection.accessKeyProvider),
        connection: sourceConnection,
        actionItems: List(),
        areas,
        slowness: undefined
      }}
      destination={{
        cloudService: destinationCloudService,
        authProvider: authProviders.getOrFail(destinationConnection.accessKeyProvider),
        connection: destinationConnection,
        actionItems: List(),
        areas: [],
        slowness: undefined
      }}
      migration={migration}
      timeRemaining={List([timeRemaining])}
      state={(() => {
        switch (migration.status) {
          case MigrationStatus.Starting:
            return MigrationStatusPageState.starting();
          case MigrationStatus.Completed:
            return MigrationStatusPageState.complete();
          default:
            return MigrationStatusPageState.working();
        }
      })()}
      displaySpinUp={true}
      displayWarning={false}

      iterationIndex={0}
      onIterationSelect={() => { /* swallow */ }}

      iterationStatus={OperationStatus.Pending()}

      migrationReportUrl={ValidReportUrlPrefix}
      invoiceToolController={() => null}
      iterationLaunchController={() => null}
      iterationsHistoryController={() => null}
      disconnectController={undefined}
      pauseMigrationController={() => null}
      resumeMigrationController={() => null}
    />
  );
};
