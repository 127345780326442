import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { EmailPreview } from "../../types/models/emailPreview";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";

type SendTestOffboardingProjectEmailMutationHook = [
  (round: number) => Promise<EmailPreview>,
  OperationStatus<EmailPreview>
];

export function useSendTestOffboardingProjectEmailMutation(
  offboardingProjectId: string
): SendTestOffboardingProjectEmailMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useSendTestOffboardingProjectEmailMutation,
    extract: (data: GraphQL.SendTestOffboardingProjectEmailMutation) =>
      nullToUndefined(data.sendTestOffboardingProjectEmail),
    complete: EmailPreview.parse,
    catch: [
      [
        ErrorClass.InvalidOperationException,
        (error) => UserFacingError.expected(error, {
          summary: "Email delivery is currently disabled",
          recommendations: "Close this window and enable email delivery in the project settings.",
          retry: null
        })
      ]
    ]
  });

  function fireWith(round: number): Promise<EmailPreview> {
    return fire({
      variables: { offboardingProjectId, round },
      retry: () => fireWith(round),
    });
  }

  return [fireWith, status];
}
