import { WorkflowContextLike } from "./workflowContextLike";
import { WorkItemStates } from "./workItemStates";
import { Path } from "./path";
import { WorkItemContexts } from "./workItemContexts";
import { SyncResult } from "./asyncResult";

export class WorkflowState<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  > {
  constructor(protected config: WorkflowState.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>) {
  }

  public get workflowContext(): WorkflowContext {
    return this.config.workflowContext;
  }

  public get workItemStates(): WorkItemStates {
    return this.config.workItemStates;
  }

  public get path(): Path {
    return this.config.path;
  }

  public get correctedPreviousPath(): boolean {
    return this.config.correctedPreviousPath;
  }

  public get workItemContexts(): WorkItemContexts {
    return this.config.workItemContexts;
  }

  public copy(
    updates?: Partial<WorkflowState.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>>
  ): WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return updates ? new WorkflowState({ ...this.config, ...updates }) : this;
  }

  public reset(): WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return this.copy({
      workItemStates: new WorkItemStates(),
      path: Path.Empty
    });
  }

  public buildResult<T>(
    result: T,
    updates?: Partial<WorkflowState.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>>
  ): SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, T> {
    return SyncResult(this.copy(updates), result);
  }

  public prepareForNextCycle(): WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return this.copy({ workItemContexts: this.workItemContexts.prepareForNextCycle() });
  }

  public withPath(
    path: Path,
    correctedPreviousPath: boolean
  ): WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return this.copy({ path, correctedPreviousPath });
  }
}

export namespace WorkflowState {
  export interface Config<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult
    > {
    readonly workflowContext: WorkflowContext;
    readonly workItemStates: WorkItemStates;
    readonly path: Path;

    // True if the current path is a result of "correction" of the previously given path.
    // Correction happens during initialization (when user lands on the root URL and the path is ""), and
    // when user attempts to navigate to an invalid URL.
    readonly correctedPreviousPath: boolean;

    readonly workItemContexts: WorkItemContexts;
  }
}
