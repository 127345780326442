export function scrollIntoViewById(id: string): void {
  const element = window.document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }
}

export function scrollIntoViewByClassName(className: string): void {
  const elements = window.document.getElementsByClassName(className);
  if (elements.length) {
    elements[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }
}
