import * as React from "react";
import { styled } from "../../app/theme";

interface Props {
  error?: boolean;
}

export const Header = styled.div<Props>`
  font-size: 2rem;
  font-weight: 500;
  color: ${(props) => props.error ? props.theme.colors.red : props.theme.colors.titleColor};
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.75rem;
  }  
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
  }  
`;
