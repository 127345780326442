import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProject } from "../../types/models/offboardingProject";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";

type AdvanceToNextEmailDeliveryRoundMutationHook = [
  (expectedNextRound: number) => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export function useAdvanceToNextEmailDeliveryRoundMutation(
  offboardingProjectId: string
): AdvanceToNextEmailDeliveryRoundMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useAdvanceToNextEmailDeliveryRoundMutation,
    extract: (data: GraphQL.AdvanceToNextEmailDeliveryRoundMutation) =>
      nullToUndefined(data.advanceToNextEmailDeliveryRound),
    complete: OffboardingProject.parse,
    catch: [
      [
        ErrorClass.UnexpectedStateException,
        (error) => UserFacingError.expected(error, {
          summary: "This operation cannot be performed while email sending is already in progress " +
            "or after the project is completed",
          recommendations: "Close this window, click the \"Refresh\" button and try again.",
          retry: null
        })
      ]
    ]
  });

  function fireWith(expectedNextRound: number): Promise<OffboardingProject> {
    return fire({
      variables: { offboardingProjectId, expectedNextRound },
      retry: () => fireWith(expectedNextRound),
    });
  }

  return [fireWith, status];
}
