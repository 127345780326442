import * as React from "react";
import { styled } from "../../../../../app/theme";
import { PanelRow } from "../../../../containers/rows/panelRow";

const ErrorTitle = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: ${(props) => props.theme.font.medium};
  margin-bottom: .3rem;
`;

interface Props {
  title: string;
}

export const ErrorInfo: React.FunctionComponent<Props> = (props) => (
  <PanelRow>
    <ErrorTitle>{props.title}</ErrorTitle>
    {props.children}
  </PanelRow>
);
