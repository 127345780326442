import * as React from "react";
import { styled, keyframes, css } from "../../app/theme";
import { Spacer } from "../utils/spacer";
import { VaultMeLogo } from "../glyphs/vaultMeLogo";
import { useLocation } from "react-router";
import { CloseModalButton } from "../widgets/closeModalButton";

export type ModalBackground = "info" | "alert" | "white";

const fadeOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.8; }
`;

const Overlay = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(5,37,53,0.4);
  animation: ${fadeOut} 0.25s forwards;
  z-index: ${(props) => props.theme.layers.modal};
`;

const ClickableHeaderLayout = styled.a.attrs({})`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  z-index: ${(props) => props.theme.layers.modal + 1};
  background: ${(props) => props.theme.colors.offWhite};
  flex-shrink: 0;
`;

const StyledVaultMeLogo = styled(VaultMeLogo)`
  display: none;
  align-self: center;
  flex-shrink: 0;
  height: 2.1rem;
  width: 9.25rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: block;
  }
`;

interface HeaderProps {
  onClick?: () => void;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => (
  <ClickableHeaderLayout onClick={props.onClick}>
    <StyledVaultMeLogo colorSchema="black-and-blue"/>
    <Spacer/>
    {props.onClick && <CloseModalButton color={"primary"}/>}
  </ClickableHeaderLayout>
);

export const Title = styled.h1<{ background: ModalBackground | undefined }>`
  font-weight: 600;
  font-size: 2rem;
  color: ${(props) => props.background === "alert" ? props.theme.colors.red : props.theme.colors.titleColor};
  background: ${(props) => props.background === "white" ? "#ffffff" : props.theme.colors.offWhite};
  position: sticky;
  top: 0;

  // Bottom margin used be -1rem, but why?!
  margin: 2rem 0 0;

  padding: 1rem 0;
  z-index: 1; // Somehow this prevents overflowing by SVG images
`;

const openModal = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
`;

const closeModal = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
`;

const openMixin = (open: boolean) => {
  if (open) {
    return css`
      box-shadow: -0.5rem 0 1rem 0 rgba(5,37,53,0.25);
      animation: ${openModal} 0.25s forwards;
    `;
  } else {
    return css`
      transform: translateX(100%);
      animation: ${closeModal} 0.25s forwards;
    `;
  }
};

{/* tslint:disable max-line-length */}
const backgroundMixin = (background?: ModalBackground) => {
  switch (background) {
    case "info":
      return css`
        background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDEwIDIxIj48ZyBmaWxsPSJub25lIj48ZyBmaWxsPSIjREZFRUY4Ij48cGF0aCBkPSJNMTAgMS40TDEwIDEwQzkuNyAxMC40IDkuMyAxMC43IDguOSAxMS4xIDguNCAxMS41IDguMSAxMiA3LjkgMTIuNSA3LjggMTMgNy43IDEzLjYgNy43IDE0LjNMNC42IDE0LjNDNC42IDEzLjUgNC43IDEyLjggNC44IDEyLjIgNSAxMS43IDUuMiAxMS4yIDUuNSAxMC43IDUuOCAxMC4zIDYuMiA5LjggNi44IDkuMiA3LjMgOC43IDcuNyA4LjMgNy45IDggOC4yIDcuNyA4LjQgNy40IDguNiA3IDguOCA2LjcgOC45IDYuMiA4LjkgNS44IDguOSA1IDguNyA0LjMgOC4zIDMuOSA3LjkgMy41IDcuMyAzLjMgNi41IDMuMyA1LjkgMy4zIDUuMyAzLjUgNC44IDMuOSA0LjMgNC4zIDQuMSA0LjggNCA1LjZMMC44IDUuNkMwLjggNC41IDEgMy42IDEuNSAyLjggMiAyLjEgMi43IDEuNSAzLjYgMS4xIDQuNSAwLjcgNS40IDAuNSA2LjUgMC41IDcuNyAwLjUgOC43IDAuNyA5LjYgMS4xIDkuNyAxLjIgOS45IDEuMyAxMCAxLjRaTTYuMiAxNi42QzYuOCAxNi42IDcuMiAxNi44IDcuNiAxNy4xIDcuOSAxNy41IDguMSAxNy45IDguMSAxOC40IDguMSAxOC45IDcuOSAxOS4zIDcuNiAxOS43IDcuMiAyMCA2LjggMjAuMiA2LjIgMjAuMiA1LjcgMjAuMiA1LjIgMjAgNC45IDE5LjcgNC41IDE5LjMgNC40IDE4LjkgNC40IDE4LjQgNC40IDE3LjkgNC41IDE3LjUgNC45IDE3LjEgNS4yIDE2LjggNS43IDE2LjYgNi4yIDE2LjZaIi8+PC9nPjwvZz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 6.5rem;
        background-size: 25%;
      `;

    case "alert":
      return css`
        background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzIiBoZWlnaHQ9IjE5IiB2aWV3Qm94PSIwIDAgMyAxOSI+PHBhdGggZD0iTTAuNSAxMy4yTDAuMSAwIDMuOCAwIDMuNSAxMy4yIDAuNSAxMy4yWk0yIDE1LjVDMi42IDE1LjUgMy4xIDE1LjcgMy41IDE2IDMuOCAxNi40IDQgMTYuOCA0IDE3LjMgNCAxNy44IDMuOCAxOC4yIDMuNSAxOC41IDMuMSAxOC44IDIuNiAxOSAyIDE5IDEuNCAxOSAwLjkgMTguOCAwLjUgMTguNSAwLjIgMTguMiAwIDE3LjggMCAxNy4zIDAgMTYuOCAwLjIgMTYuNCAwLjUgMTYgMC45IDE1LjcgMS40IDE1LjUgMiAxNS41WiIgZmlsbD0iI0Y2REFFMSIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 8rem;
        background-size: 8.5%;
      `;
  }
};
{/* tslint:enable max-line-length */}

const Body = styled.div<{ width: number, background: ModalBackground | undefined }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => props.width}rem;
  overflow-x: hidden;
  background: ${(props) => props.background === "white" ? "#ffffff" : props.theme.colors.offWhite};
  z-index: ${(props) => props.theme.layers.modal};

  ${(props) => openMixin(!!props)}
  ${(props) => props && backgroundMixin(props.background)}

  ${(props) => props.theme.responsive.respondTo(props.width + "rem")} {
    width: auto;
    left: 0;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    background-image: none;
  }
`;

function width(wide?: boolean): number {
  return wide ? 74 : 38;
}

const paddingMixin = (background: ModalBackground | undefined) => {
  switch (background) {
    case "info":
      return css`
        padding: 2rem 25% 4rem 2rem;
      `;

    case "alert":
      return css`
        padding-right: 3.5rem;
      `;
  }
};

// Not sure why "display: flex" was here... As a result of that, the content of the modal ws always
// out of normal flow, and margin collapsing wasn't working.
const ContentContainer = styled.div<{ background: ModalBackground | undefined, noTopPadding: boolean }>`
  //display: flex;
  //flex-direction: column;
  overflow-x: hidden;
  padding: ${(props) => props.noTopPadding ? "0" : "2rem"} 2rem 4rem 2rem;
  z-index: 1;

  ${(props) => props && paddingMixin(props.background)}

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: ${(props) => props.noTopPadding ? "0" : "1.5rem"} 1rem 4.5rem 1rem;
  }
`;

export interface ModalSettings {
  background?: ModalBackground;
  title?: React.ReactNode;
  wide?: boolean;
}

interface ModalProps extends ModalSettings {
  bodyStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  onClose?: () => void;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const location = useLocation();
  const mounted = React.useRef(false);

  // Close the modal on navigation event
  React.useEffect(
    () => {
      if (mounted.current && props.onClose) {
        props.onClose();
      }
      mounted.current = true;
    },
    [location]
  );

  React.useEffect(
    () => {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "visible";
      };
    },
    []
  );

  return (
    <>
      <Overlay onClick={props.onClose}/>
      <Body width={width(props.wide)} background={props.background} style={props.bodyStyle}>
        <Header onClick={props.onClose}/>
        <ContentContainer noTopPadding={!!props.title} background={props.background}>
          {props.title && <Title background={props.background} style={props.titleStyle}>{props.title}</Title>}
          {props.children}
        </ContentContainer>
      </Body>
    </>
  );
};

export interface ModalHook {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export function useModal(): ModalHook {
  const [open, setOpen] = React.useState(false);
  return {
    isOpen: open,
    open: () => setOpen(true),
    close: () => setOpen(false)
  };
}

interface ModalControllerProps extends ModalSettings {
  modal: ModalHook;
  render: (close: () => void) => React.ReactElement;
  onClose?: () => void;
}

export const ModalController: React.FunctionComponent<ModalControllerProps> = (props) => {
  const { modal, render, ...rest } = props;

  function close() {
    props.modal.close();
    if (props.onClose) {
      props.onClose();
    }
  }

  if (props.modal.isOpen) {
    return (
      <Modal {...rest} onClose={close}>
        {props.render(close)}
      </Modal>
    );
  } else {
    return null;
  }
};

interface DynamicModalSettings extends Partial<ModalSettings> {
  render: (close: () => void) => React.ReactNode;
}

export interface DynamicModalHook {
  settings: DynamicModalSettings | undefined;
  open: (settings: DynamicModalSettings) => void;
  close: () => void;
}

export function useDynamicModal(): DynamicModalHook {
  const [settings, setSettings] = React.useState<DynamicModalSettings>();
  return {
    settings,
    open: setSettings,
    close: () => setSettings(undefined)
  };
}

interface DynamicModalControllerProps extends ModalSettings {
  modal: DynamicModalHook;
}

export const DynamicModalController: React.FunctionComponent<DynamicModalControllerProps> = (props) => {
  const { modal, ...defaults } = props;
  if (props.modal.settings) {
    const { render, ...overrides } = props.modal.settings;
    return (
      <Modal
        {...defaults}
        {...overrides}
        onClose={props.modal.close}
      >
        {render(props.modal.close)}
      </Modal>
    );
  } else {
    return null;
  }
};
