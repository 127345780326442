import { ProgramSummary } from "./program";
import { ThemeOverrides } from "./themeOverrides";
import { SchoolSummary } from "./school";

export interface ProgramEligibilityMessage<T extends SourceConnectionPromotionMessage.Type> {
  readonly type: T;
  readonly isBlocking: boolean;
}

export namespace SourceConnectionPromotionMessage {
  export enum Type {
    SwitchFromVanilla = "SwitchFromVanilla",
    SwitchToVanilla = "SwitchToVanilla",
    SwitchToAnotherProgram = "SwitchToAnotherProgram",
    PossibleFollowUpMigration = "PossibleFollowUpMigration",
  }

  export interface SwitchFromVanilla extends ProgramEligibilityMessage<Type.SwitchFromVanilla> {
    readonly preferredProgram: ProgramSummary;
    readonly preferredProgramTheme: ThemeOverrides | undefined;
  }

  export function switchFromVanilla(
    preferredProgram: ProgramSummary,
    preferredProgramTheme: ThemeOverrides | undefined
  ): SwitchFromVanilla {
    return {
      type: Type.SwitchFromVanilla,
      isBlocking: true,
      preferredProgram,
      preferredProgramTheme
    };
  }

  export interface SwitchToVanilla extends ProgramEligibilityMessage<Type.SwitchToVanilla> {
    readonly currentProgram: ProgramSummary;
  }

  export function switchToVanilla(currentProgram: ProgramSummary): SwitchToVanilla {
    return {
      type: Type.SwitchToVanilla,
      isBlocking: true,
      currentProgram
    };
  }

  export interface SwitchToAnotherProgram extends ProgramEligibilityMessage<Type.SwitchToAnotherProgram> {
    readonly currentProgram: ProgramSummary;
    readonly preferredProgram: ProgramSummary;
    readonly preferredProgramTheme: ThemeOverrides | undefined;
    readonly restartSetupFlow: boolean;
  }

  export function switchToAnotherProgram(
    currentProgram: ProgramSummary,
    preferredProgram: ProgramSummary,
    preferredProgramTheme: ThemeOverrides | undefined
  ): SwitchToAnotherProgram {
    return {
      type: Type.SwitchToAnotherProgram,
      isBlocking: true,
      currentProgram,
      preferredProgram,
      preferredProgramTheme,
      restartSetupFlow: currentProgram.organizationId !== preferredProgram.organizationId
    };
  }

  export interface PossibleFollowUpMigration extends ProgramEligibilityMessage<Type.PossibleFollowUpMigration> {
    readonly currentProgram: ProgramSummary;
  }

  export function possibleFollowUpMigration(currentProgram: ProgramSummary): PossibleFollowUpMigration {
    return {
      type: Type.PossibleFollowUpMigration,
      isBlocking: false,
      currentProgram
    };
  }

  export type Any =
    SwitchFromVanilla |
    SwitchToVanilla |
    SwitchToAnotherProgram |
    PossibleFollowUpMigration;
}
