import { GraphQL } from "../../services/graphql/generated";
import { Map } from "immutable";
import { CacheIdGenerator } from "../../services/graphql/cacheIdGenerator";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { RevenueShare } from "./revenueShare";
import { ReferralCodeDiscount } from "./referralCodeDiscount";
import { CouponCodeDiscount } from "./couponCodeDiscount";
import { AmbassadorStatusDiscount } from "./ambassadorStatusDiscount";
import { AmbassadorCodeDiscount } from "./ambassadorCodeDiscount";

export enum OrderStatus {
  Created = "Created",
  Authorized = "Authorized",

  Settled = "Settled",
  Voided = "Voided",
  Refunded = "Refunded",
  ProcessedExternally = "ProcessedExternally",

  Skipped = "Skipped",
  PaidExternally = "PaidExternally"
}

export interface Order {
  readonly id: string;
  readonly userId: string;

  readonly sourceCloudServiceId: string;
  readonly destinationCloudServiceId: string;
  readonly sourceConnectionId: string;
  readonly destinationConnectionId: string;

  readonly blueprintInputs: Map<string, string>;

  // Context - previous migrations and applicable program
  readonly previousMigrationFromSource: string | undefined;
  readonly previousMigrationFromSourceToDestination: string | undefined;
  readonly programId: string | undefined;

  // Pricing model
  readonly pricingModelName: string;
  readonly pricingModelType: string;
  readonly pricingModelVersion: string;
  readonly pricingModelJson: string;

  // Migration size
  readonly totalBytes: number;
  readonly totalItems: number;

  // Numbers
  readonly originalBasePrice: number;
  readonly originalBytesFee: number;
  readonly originalItemsFee: number;
  readonly finalBasePrice: number;
  readonly finalBytesFee: number;
  readonly finalItemsFee: number;
  readonly priceBeforeDiscounts: number;
  readonly couponCodeDiscount: CouponCodeDiscount | undefined;
  readonly referralCodeDiscount: ReferralCodeDiscount | undefined;
  readonly ambassadorStatusDiscount: AmbassadorStatusDiscount | undefined;
  readonly ambassadorCodeDiscount: AmbassadorCodeDiscount | undefined;
  readonly sponsoredAmount: number | undefined;
  readonly amountToBePaid: number;
  readonly paidAmount: number | undefined;

  // Summary, transaction, status, etc
  readonly priceBreakdown: string;
  readonly transactionId: string | undefined;
  readonly braintreeTransactionDetailsUrl: string | undefined;
  readonly status: OrderStatus;

  readonly checkbox1Label: string | undefined;
  readonly checkbox1Checked: boolean | undefined;
  readonly checkbox2Label: string | undefined;
  readonly checkbox2Checked: boolean | undefined;
  readonly checkbox3Label: string | undefined;
  readonly checkbox3Checked: boolean | undefined;

  readonly createdAt: Date;

  readonly updatedAt: Date;
  readonly updatedBy: string | undefined;
  readonly updateSummary: string | undefined;
  readonly minorUpdate: boolean;

  readonly authorizedAt: Date | undefined;
  readonly settledAt: Date | undefined;
}

export namespace Order {
  const TypeName = "Order";

  export const cacheId = CacheIdGenerator.simple<GraphQL.Order, { id: string }>(
    TypeName,
    ({ id }) => id
  );

  export function parse(data: GraphQL.OrderFragment): Order {
    return {
      id: data.id,
      userId: data.userId,

      sourceCloudServiceId: data.sourceCloudServiceId,
      destinationCloudServiceId: data.destinationCloudServiceId,
      sourceConnectionId: data.sourceConnectionId,
      destinationConnectionId: data.destinationConnectionId,

      blueprintInputs: Map(data.blueprintInputs.map((input) => [input.key, input.value])),

      previousMigrationFromSource: nullToUndefined(data.previousMigrationFromSource),
      previousMigrationFromSourceToDestination: nullToUndefined(data.previousMigrationFromSourceToDestination),
      programId: nullToUndefined(data.programId),

      pricingModelName: data.pricingModelName,
      pricingModelType: data.pricingModelType,
      pricingModelVersion: data.pricingModelVersion,
      pricingModelJson: data.pricingModelJson,

      totalBytes: data.totalBytes,
      totalItems: data.totalItems,

      originalBasePrice: data.originalBasePrice,
      originalBytesFee: data.originalBytesFee,
      originalItemsFee: data.originalItemsFee,
      finalBasePrice: data.finalBasePrice,
      finalBytesFee: data.finalBytesFee,
      finalItemsFee: data.finalItemsFee,
      priceBeforeDiscounts: data.priceBeforeDiscounts,
      couponCodeDiscount: mapOptional(data.couponCodeDiscount, CouponCodeDiscount.parse),
      referralCodeDiscount: mapOptional(data.referralCodeDiscount, ReferralCodeDiscount.parse),
      ambassadorStatusDiscount: mapOptional(data.ambassadorStatusDiscount, AmbassadorStatusDiscount.parse),
      ambassadorCodeDiscount: mapOptional(data.ambassadorCodeDiscount, AmbassadorCodeDiscount.parse),
      sponsoredAmount: nullToUndefined(data.sponsoredAmount),
      amountToBePaid: data.amountToBePaid,
      paidAmount: nullToUndefined(data.paidAmount),

      priceBreakdown: data.priceBreakdown,
      transactionId: nullToUndefined(data.transactionId),
      braintreeTransactionDetailsUrl: nullToUndefined(data.braintreeTransactionDetailsUrl),
      status: data.status,

      checkbox1Label: nullToUndefined(data.checkbox1Label),
      checkbox1Checked: nullToUndefined(data.checkbox1Checked),
      checkbox2Label: nullToUndefined(data.checkbox2Label),
      checkbox2Checked: nullToUndefined(data.checkbox2Checked),
      checkbox3Label: nullToUndefined(data.checkbox3Label),
      checkbox3Checked: nullToUndefined(data.checkbox3Checked),

      createdAt: new Date(data.createdAt),

      updatedAt: new Date(data.updatedAt),
      updatedBy: nullToUndefined(data.updatedBy),
      updateSummary: nullToUndefined(data.updateSummary),
      minorUpdate: data.minorUpdate,

      authorizedAt: mapOptional(data.authorizedAt, (date) => new Date(date)),
      settledAt: mapOptional(data.settledAt, (date) => new Date(date))
    };
  }

  export interface HasRevenueData {
    readonly revenue: number;
    readonly revenueShare: RevenueShare | undefined;
  }

  export namespace HasRevenueData {
    export function parseRevenueData(data: Pick<GraphQL.Order, "revenue" | "revenueShare">): HasRevenueData {
      return {
        revenue: data.revenue,
        revenueShare: mapOptional(data.revenueShare, RevenueShare.parseRevenueShare),
      };
    }
  }
}
