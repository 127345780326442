import { Order } from "../../types/models/order";
import * as React from "react";
import { PanelRow } from "../containers/rows/panelRow";
import {
  DateTimeIndicator, Indicator,
  Indicators, IndicatorValue,
  ItemCountAndSizeIndicator,
  JsonIndicator, PriceIndicator,
  SimpleIndicator
} from "../widgets/indicator";
import { Panel } from "../containers/panel";
import { OperationStatus } from "../../types/operationStatus";
import { Option } from "../../utils/monads/option";
import { DrawerBlock } from "../containers/drawerBlock";
import { CheckoutToolsView } from "./checkoutToolsView";
import { GraphQL } from "../../services/graphql/generated";
import { StatusIndicators } from "../utils/statusIndicators";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { Button } from "../widgets/button";
import { ModalController, useModal } from "../layouts/modal";
import { Row } from "../widgets/row";
import { Characters } from "../characters";
import { RevenueShareSourceType } from "../../types/enums/revenueShareSourceType";

export interface AuthorizationManagementToolViewProps {
  authorizationStatusStatus: OperationStatus<GraphQL.AuthorizationStatus | undefined>;

  submitTransactionForSettlementStatus: OperationStatus<Order | undefined>;
  voidTransactionStatus: OperationStatus<Order | undefined>;
  refundTransactionStatus: OperationStatus<any>;

  onRefreshAuthorizationStatus: () => void;
  onSubmitTransactionForSettlement: () => Promise<Order | undefined>;
  onVoidTransaction: () => Promise<Order | undefined>;
  onRefundTransaction: () => Promise<Order | undefined>;

  onSubmitTransactionForSettlementOperationReset: () => void;
  onVoidTransactionOperationReset: () => void;
  onRefundTransactionOperationReset: () => void;
}

export interface AuthorizationManagementToolControllerProps {
  render: (viewProps: AuthorizationManagementToolViewProps) => React.ReactElement;
}

export type AuthorizationManagementToolControllerType = React.ComponentType<AuthorizationManagementToolControllerProps>;

interface OrderPropertiesProps {
  order: Order & Order.HasRevenueData;
  authorizationManagementToolController?: AuthorizationManagementToolControllerType;
}

export const OrderDetailsPanelView: React.FunctionComponent<OrderPropertiesProps> = (props) => (
  <Panel>
    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator title={"Order ID"} value={props.order.id}/>
        <SimpleIndicator
          title={"Transaction ID"}
          value={props.order.transactionId || "--"}
          parenthetical={
            props.order.braintreeTransactionDetailsUrl &&
              <a href={props.order.braintreeTransactionDetailsUrl} target={"_blank"} key={0}>View</a>
          }
        />
        <SimpleIndicator title={"Status"} value={props.order.status}/>
      </Indicators>
    </PanelRow>

    {
      props.authorizationManagementToolController &&
      React.createElement(props.authorizationManagementToolController, {
        render: (viewProps) => <AuthorizationManagementRow {...viewProps}/>
      })
    }

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <DateTimeIndicator title={"Created At"} dateTime={props.order.createdAt}/>
        <DateTimeIndicator title={"Authorized At"} dateTime={props.order.authorizedAt}/>
        <DateTimeIndicator title={"Settled At"} dateTime={props.order.settledAt}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator
          title={"Previous Migration From The Source"}
          value={props.order.previousMigrationFromSource}
        />
        <SimpleIndicator
          title={"Previous Migration From The Source to The Destination "}
          value={props.order.previousMigrationFromSourceToDestination}
        />
        <SimpleIndicator title={"Program ID"} value={props.order.programId}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator title={"Pricing Model Name"} value={props.order.pricingModelName}/>
        <SimpleIndicator title={"Pricing Model Type"} value={props.order.pricingModelType}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <JsonIndicator
          title={"Pricing Model JSON"}
          value={props.order.pricingModelJson}
          version={props.order.pricingModelVersion}
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <ItemCountAndSizeIndicator
          title="Migration Size"
          count={props.order.totalItems}
          unit="item"
          size={props.order.totalBytes}
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <PriceIndicator title={"Original Migration Fee"} price={props.order.originalBasePrice}/>
        <PriceIndicator title={"Original Items Fee"} price={props.order.originalItemsFee}/>
        <PriceIndicator title={"Original Bytes Fee"} price={props.order.originalBytesFee}/>
        <PriceIndicator title={"Final Migration Fee"} price={props.order.finalBasePrice}/>
        <PriceIndicator title={"Final Items Fee"} price={props.order.finalItemsFee}/>
        <PriceIndicator title={"Final Bytes Fee"} price={props.order.finalBytesFee}/>
        <PriceIndicator title={"Price Before Discounts"} price={props.order.priceBeforeDiscounts}/>
        <PriceIndicator title={"Referral Code Discount"} price={props.order.referralCodeDiscount?.amount}/>
        <PriceIndicator title={"Ambassador Code Discount"} price={props.order.ambassadorCodeDiscount?.amount}/>
        <PriceIndicator title={"Ambassador Status Discount"} price={props.order.ambassadorStatusDiscount?.amount}/>
        <PriceIndicator title={"Coupon Code Discount"} price={props.order.couponCodeDiscount?.amount}/>
        <PriceIndicator title={"Sponsored Amount"} price={props.order.sponsoredAmount}/>
        <PriceIndicator title={"Amount To Be Paid"} price={props.order.amountToBePaid}/>
        <PriceIndicator title={"Paid Amount"} price={props.order.paidAmount}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator title={"Program ID"} value={props.order.programId}/>
        <SimpleIndicator
          title={"Referral Code"}
          value={props.order.referralCodeDiscount?.code}
          parenthetical={
            props.order.referralCodeDiscount
              ? props.order.referralCodeDiscount.percentage + "% off"
              : undefined
          }
        />
        <SimpleIndicator
          title={"Ambassador Code"}
          value={props.order.ambassadorCodeDiscount?.code}
          parenthetical={
            props.order.ambassadorCodeDiscount
              ? props.order.ambassadorCodeDiscount.percentage + "% off"
              : undefined
          }
        />
        <SimpleIndicator
          title={"Ambassador Status"}
          value={
            props.order.ambassadorStatusDiscount &&
            (props.order.ambassadorStatusDiscount.isReturningAmbassadorDiscount ? "Returning" : "New Sign-Up")
          }
          parenthetical={
            props.order.ambassadorStatusDiscount
              ? (
                props.order.ambassadorStatusDiscount.isReturningAmbassadorDiscount
                  ? props.order.ambassadorStatusDiscount.returningAmbassadorPercentage + "% off"
                  : "$" + props.order.ambassadorStatusDiscount.amount + " off"
              )
              : undefined
          }
        />
        <SimpleIndicator
          title={"Coupon Code"}
          value={props.order.couponCodeDiscount?.code}
          parenthetical={
            props.order.couponCodeDiscount
              ? (
                props.order.couponCodeDiscount.isPercentage
                  ? props.order.couponCodeDiscount.value + "% off"
                  : "$" + props.order.couponCodeDiscount.value + " off"
              )
              : undefined
          }
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <PriceIndicator title={"Revenue"} price={props.order.revenue}/>
        <SimpleIndicator
          title={"Revenue Share Source Type"}
          value={
            props.order.revenueShare && RevenueShareSourceType.toFriendlyString(props.order.revenueShare.sourceType)
          }
        />
        <SimpleIndicator title={"Revenue Share Source ID"} value={props.order.revenueShare?.sourceId}/>
        <SimpleIndicator
          title={"Revenue Share Percentage"}
          value={props.order.revenueShare ? props.order.revenueShare.percentage + "%" : undefined}
        />
        <PriceIndicator title={"Revenue Share Amount"} price={props.order.revenueShare?.amount}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <JsonIndicator title={"Price Breakdown"} value={props.order.priceBreakdown}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <Indicator title={"Checkboxes"}>
          <IndicatorValue
            value={
              props.order.checkbox1Label || props.order.checkbox2Label || props.order.checkbox3Label
                ? (
                  <>
                    {props.order.checkbox1Label && (
                      <div>
                        {props.order.checkbox1Checked ? Characters.CheckedCheckbox : Characters.UncheckedCheckbox}{" "}
                        <span dangerouslySetInnerHTML={{ __html: props.order.checkbox1Label }}/>
                      </div>
                    )}
                    {props.order.checkbox2Label && (
                      <div>
                        {props.order.checkbox2Checked ? Characters.CheckedCheckbox : Characters.UncheckedCheckbox}{" "}
                        <span dangerouslySetInnerHTML={{ __html: props.order.checkbox2Label }}/>
                      </div>
                    )}
                    {props.order.checkbox3Label && (
                      <div>
                        {props.order.checkbox3Checked ? Characters.CheckedCheckbox : Characters.UncheckedCheckbox}{" "}
                        <span dangerouslySetInnerHTML={{ __html: props.order.checkbox3Label }}/>
                      </div>
                    )}
                  </>
                )
                : "None"
            }
          />
        </Indicator>
      </Indicators>
    </PanelRow>
  </Panel>
);

interface AuthorizationManagementRowProps {
  authorizationStatusStatus: OperationStatus<GraphQL.AuthorizationStatus | undefined>;

  submitTransactionForSettlementStatus: OperationStatus<Order | undefined>;
  voidTransactionStatus: OperationStatus<Order | undefined>;
  refundTransactionStatus: OperationStatus<Order | undefined>;

  onRefreshAuthorizationStatus: () => void;
  onSubmitTransactionForSettlement: () => Promise<Order | undefined>;
  onVoidTransaction: () => Promise<Order | undefined>;
  onRefundTransaction: () => Promise<Order | undefined>;

  onSubmitTransactionForSettlementOperationReset: () => void;
  onVoidTransactionOperationReset: () => void;
  onRefundTransactionOperationReset: () => void;
}

const AuthorizationManagementRow: React.FunctionComponent<AuthorizationManagementRowProps> = (props) => {
  const submitConfirmationModal = useModal();
  const voidConfirmationModal = useModal();
  const refundConfirmationModal = useModal();

  return (
    <>
      <OperationStatusIndicator
        progressMessage={"Checking transaction status..."}
        failureMessage={"Failed to check transaction status."}
        status={props.authorizationStatusStatus}
        indicators={StatusIndicators.PanelRow({ noProgressIndicatorDelay: true })}
      />

      <ModalController
        modal={submitConfirmationModal}
        title={"Submit Transaction For Settlement"}
        render={(close) => (
          <Panel>
            <PanelRow>
              Click the button below to submit transaction for settlement. If you change your mind later, this
              transaction will have to be refunded with all usual penalties applied.
            </PanelRow>
            <OperationStatusIndicator
              progressMessage={"Submitting transaction for settlement..."}
              failureMessage={"Failed to submit transaction for settlement."}
              successMessage={(result) => result ? "Submitted transaction for settlement" : "No transaction"}
              status={props.submitTransactionForSettlementStatus}
              indicators={StatusIndicators.PanelRow({ keepSuccessIndicator: true })}
            />
            <PanelRow>
              {
                props.submitTransactionForSettlementStatus.isSuccess()
                  ? <Button onClick={close}>Close</Button>
                  : (
                    <Button
                      size={"narrow"}
                      onClick={props.onSubmitTransactionForSettlement}
                      disabled={props.submitTransactionForSettlementStatus.isWorking()}
                    >
                      Submit Transaction For Settlement
                    </Button>
                  )
              }
            </PanelRow>
          </Panel>
        )}
        onClose={props.onSubmitTransactionForSettlementOperationReset}
      />

      <ModalController
        modal={voidConfirmationModal}
        title={"Void Transaction"}
        background={"alert"}
        render={(close) => (
          <Panel>
            <PanelRow>
              Click the button below to void transaction. This operation cannot be reversed, but no penalties will
              be applied, unlike in the case of refunding a previously settled transaction. If you leave the
              authorization pending, it will be submitted for settlement automatically after a period of time, which
              depends on the status of the migration.
            </PanelRow>
            <OperationStatusIndicator
              progressMessage={"Voiding transaction..."}
              failureMessage={"Failed to void transaction."}
              successMessage={(result) => result ? "Voided transaction" : "No transaction"}
              status={props.voidTransactionStatus}
              indicators={StatusIndicators.PanelRow({ keepSuccessIndicator: true })}
            />
            <PanelRow>
              {
                props.voidTransactionStatus.isSuccess()
                  ? <Button onClick={close}>Close</Button>
                  : (
                    <Button
                      color={"red"}
                      size={"narrow"}
                      onClick={props.onVoidTransaction}
                      disabled={props.voidTransactionStatus.isWorking()}
                    >
                      Void Transaction
                    </Button>
                  )
              }
            </PanelRow>
          </Panel>
        )}
        onClose={props.onVoidTransactionOperationReset}
      />

      <ModalController
        modal={refundConfirmationModal}
        title={"Refund Transaction"}
        background={"alert"}
        render={(close) => (
          <Panel>
            <PanelRow>
              Click the button below to refund transaction. This operation cannot be reversed, and penalties will
              be applied. Use this operation only if there's no other way to help the customer (for example, by
              running another discounted migration).
            </PanelRow>
            <OperationStatusIndicator
              progressMessage={"Refunding transaction..."}
              failureMessage={"Failed to refund transaction."}
              successMessage={(result) => result ? "Refunded transaction" : "No transaction"}
              status={props.refundTransactionStatus}
              indicators={StatusIndicators.PanelRow({ keepSuccessIndicator: true })}
            />
            <PanelRow>
              {
                props.refundTransactionStatus.isSuccess()
                  ? <Button onClick={close}>Close</Button>
                  : (
                    <Button
                      color={"red"}
                      size={"narrow"}
                      onClick={props.onRefundTransaction}
                      disabled={props.refundTransactionStatus.isWorking()}
                    >
                      Refund Transaction
                    </Button>
                  )
              }
            </PanelRow>
          </Panel>
        )}
        onClose={props.onRefundTransactionOperationReset}
      />

      {props.authorizationStatusStatus.isSuccess() && (
        <PanelRow size={"smaller"}>
          <Row>
            Transaction status: {props.authorizationStatusStatus.result?.transactionStatus || "N/A"}
          </Row>
          {props.authorizationStatusStatus.result?.transactionStatus && (
            <Row>
              <SimpleToolbar
                items={
                  [
                    <Button
                      key={"refresh"}
                      size={"small"}
                      color={"white"}
                      onClick={props.onRefreshAuthorizationStatus}
                    >
                      Check Again
                    </Button>
                  ]
                    .concat(
                      props.authorizationStatusStatus.result?.isPending
                        ? [
                          <Button
                            key={"submit"}
                            size={"small"}
                            color={"white"}
                            onClick={submitConfirmationModal.open}
                          >
                            Submit For Settlement
                          </Button>,
                          (
                            <Button
                              key={"void"}
                              size={"small"}
                              color={"red"}
                              onClick={voidConfirmationModal.open}
                            >
                              Void
                            </Button>
                          )
                        ]
                        : [
                          <Button
                            key={"void"}
                            size={"small"}
                            color={"red"}
                            onClick={refundConfirmationModal.open}
                          >
                            Refund
                          </Button>
                        ]
                    )
                }
              />
            </Row>
          )}
        </PanelRow>
      )}
    </>
  );
};

interface CheckoutOrderViewProps {
  status: OperationStatus<Order & Order.HasRevenueData>;
  isWorking: boolean;

  onExternalPayment: (amount: number, note: Option<string>) => void;
}

export const CheckoutOrderView: React.FunctionComponent<CheckoutOrderViewProps> = (props) => (
  <CheckoutToolsView
    status={props.status.map((result) => result.amountToBePaid)}
    isWorking={props.isWorking}
    onExternalPayment={props.onExternalPayment}
  >
    {props.status.isSuccess() && (
      <DrawerBlock>
        <OrderDetailsPanelView order={props.status.result}/>
      </DrawerBlock>
    )}
  </CheckoutToolsView>
);
