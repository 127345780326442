import * as React from "react";
import {
  useForceStartEmailDeliveryRoundMutation
} from "../../../queries/automatedOffboarding/useForceStartEmailDeliveryRoundMutation";
import {
  ForceStartEmailDeliveryRoundViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/forceStartEmailDeliveryRoundController";
import { ControllerProps } from "../../../utils/controller";

interface ForceStartEmailDeliveryRoundToolControllerProps
  extends ControllerProps<ForceStartEmailDeliveryRoundViewProps> {
  offboardingProjectId: string;
}

const ForceStartEmailDeliveryRoundToolController
  : React.FunctionComponent<ForceStartEmailDeliveryRoundToolControllerProps> =
  (props) => {
    const [advance, status] = useForceStartEmailDeliveryRoundMutation(props.offboardingProjectId);

    return props.render({
      status,
      onSubmit: advance
    });
  };

export function useForceStartEmailDeliveryRoundController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<ForceStartEmailDeliveryRoundViewProps>) => (
      <ForceStartEmailDeliveryRoundToolController
        offboardingProjectId={offboardingProjectId}
        {...controllerProps}
      />
    ),
    [offboardingProjectId]
  );
}
