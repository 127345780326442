import { Collectable } from "./collectable";
import { GraphQL } from "../../services/graphql/generated";
import { identity } from "../../utils/misc";

export class CollectableBoolean extends Collectable<boolean, boolean> {
  public type: string = "CollectableBoolean";
}

export namespace CollectableBoolean {
  export function build(
    collectable: Collectable.Data,
    partialValue: boolean | null | undefined,
    completeValue: boolean | null | undefined,
  ): CollectableBoolean {
    return new CollectableBoolean(
      Collectable.makeValue(
        collectable,
        partialValue,
        completeValue,
        identity,
        identity
      )
    );
  }

  export function fromGraphQL(collectable: GraphQL.CollectableBoolean): CollectableBoolean {
    return build(collectable, collectable.partialValue, collectable.completeValue);
  }
}
