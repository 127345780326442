import { BlueprintContext } from "./blueprintContext";
import { AnyComponent } from "./component";
import { ConnectionComp } from "./components/connectionComp";
import { Option } from "../utils/monads/option";

export class Tracer {
  constructor(
    public readonly component: AnyComponent,
    public readonly context: BlueprintContext) {
  }

  public findConnection(): Option<ConnectionComp> {
    const connections = this.component.trace<ConnectionComp>(
      (component) => component instanceof ConnectionComp ? component : undefined,
      (component) => component instanceof ConnectionComp || component.segment !== this.component.segment
    );
    return Option.mayBe(connections.first(undefined)).filter(() => connections.size === 1);
  }

  public connectionId(): Option<string> {
    return this.findConnection().flatMap((connection) =>
      connection.state(this.context).output.map((output) => output.connectionId)
    );
  }

  public domain(includeAt: boolean = false): Option<string> {
    return this.connectionId()
      .flatMap((connectionId) => this.context.connections.getOption(connectionId))
      .flatMap((connection) => Option.mayBe(connection.personalInfo.domain))
      .map((domain) => includeAt ? "@" + domain : domain);
  }
}
