import * as React from "react";
import { OAuthSignInForm, OAuthSignInFormProps } from "./oAuthSignInForm";
import { ServiceAccountSignInForm } from "./serviceAccountSignInForm";
import { useAdminToolsVisibility } from "../../../utils/useAppState";
import { LinkButton } from "../../widgets/linkButton";

export interface ServiceAccountSignInConfig {
  predefinedEmailAddress?: string;
  onSignIn: (emailAddress: string) => void;
}

export interface OAuthAndServiceAccountSignInFormProps extends OAuthSignInFormProps {
  serviceAccountSignInConfig: ServiceAccountSignInConfig | undefined;
}

export const OAuthAndServiceAccountSignInForm: React.FunctionComponent<OAuthAndServiceAccountSignInFormProps> =
  (props) => {
    const adminToolsVisible = useAdminToolsVisibility();
    const [serviceAccountSignInMode, setServiceAccountSignInMode] = React.useState(false);

    if (props.serviceAccountSignInConfig && serviceAccountSignInMode) {
      return (
        <ServiceAccountSignInForm
          predefinedEmailAddress={props.serviceAccountSignInConfig.predefinedEmailAddress}
          onSubmit={props.serviceAccountSignInConfig.onSignIn}
          onCancel={() => setServiceAccountSignInMode(false)}
        />
      );
    } else {
      return (
        <OAuthSignInForm
          {...props}
          additionalOptions={
            props.additionalOptions.concat(
              adminToolsVisible
                ? [(
                  <LinkButton onClick={() => setServiceAccountSignInMode(true)}>
                    Sign in using service account
                  </LinkButton>
                )]
                : []
            )
          }
        />
      );
    }
  };
