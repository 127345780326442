import * as React from "react";
import { CloudService } from "../../../../../types/models/cloudService";
import { Connection } from "../../../../../types/models/connection";
import { OperationStatus } from "../../../../../types/operationStatus";
import { SignInFlowState } from "../../../../../components/auth/useSignInFlow";
import { OneTimeAuthCodeSummary } from "../../../../../types/models/oneTimeAuthCodeSummary";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { DrawerContent } from "../../../../utils/drawerContent";
import { styled } from "../../../../../app/theme";
import { CheckedCircle } from "../../../../glyphs/checkedCircle";
import { LinkButton } from "../../../../widgets/linkButton";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { SignUpOptionLayout } from "../../../signUpPageView";
import { AdminConnectionVerificationResult } from "../../../../../types/adminConnectionVerificationResult";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { PaddedPanel } from "../../../../widgets/paddedPanel";
import { Button } from "../../../../widgets/button";
import { ModalController, useModal } from "../../../../layouts/modal";
import { TextBox } from "../../../../widgets/textBox";
import { OAuthSignInForm } from "../../../../blocks/auth/oAuthSignInForm";
import { useAppBootstrapConfig } from "../../../../../app/configuration";
import { Paragraph } from "../../../../widgets/paragraph";
import {
  closeWhenFoundLoginResult,
  closeWhenReachedUrl,
  popUpLoginScreen
} from "../../../../../components/auth/loginPopup";
import { SemiBold } from "../../../../widgets/semiBold";
import { SimpleToolbar } from "../../../../widgets/simpleToolbar";

const GrantAdminConsentButtonContainer = styled.div`
  margin: .5rem 0 1rem;
`;

export interface ConnectAdminAccountViewProps {
  state: SignInFlowState;
  pendingConnection: Connection | undefined;
  connectionVerificationStatus: OperationStatus<AdminConnectionVerificationResult>;

  onSignIn: (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
  onSignOut: () => void;
}

export interface ConnectAdminAccountControllerProps {
  render: (viewProps: ConnectAdminAccountViewProps) => React.ReactElement;
}

export type ConnectAdminAccountControllerType = React.ComponentType<ConnectAdminAccountControllerProps>;

interface MicrosoftAdminCredentialsPanelProps {
  source: boolean;
  cloudService: CloudService;
  verifiedConnection: Connection | undefined;
  connectAdminAccountController: ConnectAdminAccountControllerType;
}

export const MicrosoftAdminCredentialsPanel: React.FunctionComponent<MicrosoftAdminCredentialsPanelProps> = (props) => {
  const modal = useModal();

  return (
    <>
      <ModalController
        modal={modal}
        title={"Grant Admin Consent to VaultMe"}
        render={(close) => <GrantAdminConsentForm cloudService={props.cloudService} onComplete={close}/>}
      />
      <PaddedPanel tallBottom={true}>
        <PanelRow>
          <DrawerContent>
            <p>Steps for connecting VaultMe to your Microsoft 365 account:</p>
            <ol>
              <li>
                Install VaultMe in your Microsoft 365 account by granting admin consent to the API permissions used by
                VaultMe. This can be done by clicking the link below:
                <GrantAdminConsentButtonContainer>
                  <LinkButton onClick={modal.open}>
                    Grant admin consent to VaultMe
                  </LinkButton>
                </GrantAdminConsentButtonContainer>
              </li>
              <li>
                Verify Microsoft 365 account ownership by signing in below as global admin:
                {
                  React.createElement(props.connectAdminAccountController, {
                    render: (viewProps) => (
                      <ConnectAdminAccount
                        source={props.source}
                        cloudService={props.cloudService}

                        verifiedConnection={props.verifiedConnection}

                        state={viewProps.state}
                        pendingConnection={viewProps.pendingConnection}
                        connectionVerificationStatus={viewProps.connectionVerificationStatus}

                        onSignIn={viewProps.onSignIn}
                        onSignOut={viewProps.onSignOut}
                      />
                    )
                  })
                }
              </li>
            </ol>
          </DrawerContent>
        </PanelRow>
      </PaddedPanel>
    </>
  );
};

// Really crappy! But that's just a prototype, right?
const SignInContainer = styled.div`
  margin-top: -1rem;
`;

const OperationStatusIndicatorWrapper = styled.div`
  margin: 1.5rem 0 0;
`;

const ConnectedAccountLayout = styled.div`
  margin-top: .5rem;
`;

interface ConnectedAccountProps {
  connection: Connection;
  onSignOut: () => void;
}

const ConnectedAccount: React.FunctionComponent<ConnectedAccountProps> = (props) => (
  <ConnectedAccountLayout>
    Signed in as <strong>{props.connection.descriptionOrId()}</strong>&nbsp;&nbsp;
    <LinkButton onClick={props.onSignOut}>Disconnect</LinkButton>
  </ConnectedAccountLayout>
);

const Success = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.3rem;
  display: flex;
  line-height: 1.2;
  align-items: center;
  max-width: 50rem;
  
  > div {
    padding-left: .3rem;
  }
`;

const Failure = styled.div`
  margin-top: .5rem;
  color: ${(props) => props.theme.colors.red};
`;

// Overriding styles from DrawerContent :-\
const StyledCheckedCircle = styled(CheckedCircle)`
  margin: -0.1rem .5rem -0.1rem 0 !important;
  width: 2rem !important;
  height: 2rem !important;
  display: inline !important;
  flex-shrink: 0;
`;

interface ConnectAdminAccountProps {
  source: boolean;
  cloudService: CloudService;

  verifiedConnection: Connection | undefined;

  state: SignInFlowState;
  pendingConnection: Connection | undefined;
  connectionVerificationStatus: OperationStatus<AdminConnectionVerificationResult>;

  onSignIn: (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
  onSignOut: () => void;
}

const ConnectAdminAccount: React.FunctionComponent<ConnectAdminAccountProps> = (props) => {
  if (props.verifiedConnection) {
    return (
      <>
        <ConnectedAccount connection={props.verifiedConnection} onSignOut={props.onSignOut}/>
        <Success>
          <StyledCheckedCircle hollow={true} lineStrokeWidthMultiplier={0.5}/>
          <div>{props.source ? "Source" : "Destination"} Microsoft 365 account is connected to VaultMe</div>
        </Success>
      </>
    );
  } else if (props.pendingConnection) {
    const connection = props.pendingConnection;

    return (
      <>
        <ConnectedAccount connection={props.pendingConnection} onSignOut={props.onSignOut}/>
        <OperationStatusIndicator
          progressMessage={"Verifying account ownership..."}
          status={props.connectionVerificationStatus}
          indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
        />
        {
          props.connectionVerificationStatus.isSuccess() &&
          props.connectionVerificationStatus.result !== AdminConnectionVerificationResult.Success
          && (
            <Failure>
              {
                (() => {
                  switch (props.connectionVerificationStatus.result) {
                    case AdminConnectionVerificationResult.FailedToConnect: return (
                      <>
                        <p>
                          VaultMe failed to access the Microsoft 365 account where {connection.descriptionOrId()}{" "}
                          is expected to have global admin privileges. This means one of the following:
                        </p>
                        <ul>
                          <li>
                            VaultMe was not installed in this Microsoft 365 account (see step #1)).
                          </li>
                          <li>
                            Some of the scopes required by VaultMe to operate are missing.
                          </li>
                          <li>
                            Your changes haven't been applied by Microsoft yet (this may take several minutes).
                          </li>
                        </ul>
                        <p>
                          Please check your Microsoft 365 configuration, re-install if needed by clicking the
                          "Grant admin consent to VaultMe" link above, then click the "Disconnect" link
                          and try signing in as global admin again.
                        </p>
                      </>
                    );

                    case AdminConnectionVerificationResult.NotAnAdmin: return (
                      <>
                        <p>
                          {connection.descriptionOrId()} does not seem to have global admin privileges.
                          If this account was promoted to global admin recently, the changes may not have
                          been applied by Microsoft yet (this may take several minutes).
                        </p>
                        <p>
                          Please click the "Disconnect" link above and sign in as global admin again.
                        </p>
                      </>
                    );
                  }
                })()
              }
              <p>
                If this error message does not go away, please contact us for assistance at <EmailSupportLink/>.
              </p>
            </Failure>
          )
        }
      </>
    );
  } else {
    switch (props.state.type) {
      case SignInFlowState.Type.SelectingCloudService:
        return null;

      case SignInFlowState.Type.InitializingSignInForm:
        return (
          <OperationStatusIndicator
            subject={"configuration"}
            status={props.state.status}
            indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
          />
        );

      case SignInFlowState.Type.PendingSignIn: {
        const state = props.state;
        return (
          <SignInContainer>
            {
              props.state.signInComponents.map((signInComponent, index) =>
                React.createElement(signInComponent, {
                  key: index,
                  layoutComponent: SignUpOptionLayout,
                  verb: "Sign In",
                  cloudServiceId: props.cloudService.id,
                  authProviderId: props.cloudService.authProviderId,
                  roles: state.roles,
                  onSignIn: props.onSignIn
                })
              )
            }
          </SignInContainer>
        );
      }

      case SignInFlowState.Type.CompletingSignIn:
        return (
          <OperationStatusIndicator
            progressMessage={"Signing in..."}
            successMessage={"Done!"}
            status={props.state.status}
            indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
          />
        );

      default:
        return null;
    }
  }
};

const DomainNameFormLayout = styled.div`
  margin: 1rem 0 0;
`;

const DomainNameFormButtons = styled.div`
  margin: 1rem 0 2rem;
`;

const ChangeDomainNameLink = styled.div`
  margin: .5rem 0 1.5rem;
`;

interface GrantAdminConsentFormProps {
  cloudService: CloudService;
  onComplete: () => void;
}

const GrantAdminConsentForm: React.FunctionComponent<GrantAdminConsentFormProps> = (props) => {
  const config = useAppBootstrapConfig();

  const textBoxRef = React.createRef<HTMLInputElement>();

  React.useEffect(
    () => {
      if (textBoxRef.current) {
        textBoxRef.current.focus();
      }
    },
    []
  );

  const [domainName, setDomainName] = React.useState("");
  const [domainNameAccepted, setDomainNameAccepted] = React.useState(false);
  const [adminConsentGranted, setAdminConsentGranted] = React.useState(false);

  const domainNameParts = domainName.split(".").map((part) => part.trim());
  const isDomainNameValid = domainNameParts.length > 1 &&
    domainNameParts.findIndex((part) => part.length === 0) === -1;

  function handleDomainNameSubmit() {
    setDomainName((value) =>
      value.split(".").map((part) => part.trim().toLowerCase()).join(".")
    );
    setDomainNameAccepted(true);
  }

  if (adminConsentGranted) {
    return (
      <>
        <Paragraph>
          Success! Admin consent granted to VaultMe in <strong>{domainName}</strong>.
        </Paragraph>
        <Paragraph>
          Click the <SemiBold>Continue</SemiBold> button below to proceed to the next steps.
        </Paragraph>
        <DomainNameFormButtons>
          <SimpleToolbar>
            <Button color={"primary"} onClick={props.onComplete}>Continue</Button>
            <Button
              color={"secondary"}
              onClick={() => {
                setAdminConsentGranted(false);
                setDomainNameAccepted(false);
              }}
            >
              Start Over
            </Button>
          </SimpleToolbar>
        </DomainNameFormButtons>

      </>
    );
  } else {

    return (
      <>
        <Paragraph>
          Type the primary domain name of you Microsoft 365 account:
        </Paragraph>
        <DomainNameFormLayout>
          <TextBox
            ref={textBoxRef}
            value={domainName}
            onChange={(event) => setDomainName(event.target.value)}
            disabled={domainNameAccepted}
            onKeyPress={(event) => {
              if (event.key === "Enter" && isDomainNameValid) {
                handleDomainNameSubmit();
              }
            }}
          />
        </DomainNameFormLayout>
        {
          domainNameAccepted
            ? (
              <ChangeDomainNameLink>
                <LinkButton
                  onClick={() => {
                    setDomainNameAccepted(false);
                    setTimeout(() => textBoxRef.current && textBoxRef.current.focus(), 100);
                  }}
                >
                  Change the domain name
                </LinkButton>
              </ChangeDomainNameLink>
            )
            : (
              <DomainNameFormButtons>
                <Button
                  color={"primary"}
                  disabled={!isDomainNameValid}
                  onClick={handleDomainNameSubmit}
                >
                  Submit
                </Button>
              </DomainNameFormButtons>
            )
        }
        {domainNameAccepted && (
          <>
            <Paragraph>
              Now, click the button below to grant admin consent to VaultMe in <strong>{domainName}</strong>:
            </Paragraph>
            <DomainNameFormLayout>
              <OAuthSignInForm
                cloudServiceId={props.cloudService.id}
                verb={"Grant Admin Consent"}
                vendorName={"Microsoft"}
                buttonCss={config.microsoftSignInButtonCss}
                buttonIcon={config.microsoftSignInButtonIcon}

                howToSwitchAccountHelpArticle={undefined}
                additionalOptions={[]}

                onSignInButtonClick={() => {
                  popUpLoginScreen({
                    url: "https://login.microsoftonline.com/" + domainName + "/adminconsent?client_id=" +
                      config.microsoftAppId,
                    title: "Grant Admin Consent to VaultMe with Microsoft",
                    closeWhen: closeWhenReachedUrl(config.microsoftOAuthCallbackPath, closeWhenFoundLoginResult),
                    onSuccess: () => setAdminConsentGranted(true)
                  });
                }}
              />
            </DomainNameFormLayout>
          </>
        )}
      </>
    );
  }
};
