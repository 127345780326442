import * as React from "react";
import * as ReactDOM from "react-dom";
import { Envelope } from "./envelope";
import { Map } from "immutable";

export namespace RenderedAssets {
  enum Type {
    Envelope = "Envelope"
  }

  const Header = "data:image/svg+xml;base64,";

  let renderedAssets = Map<Type, string>();
  let preparedAssets = Map<string, string>();

  function renderAsset(markup: React.ReactElement, type: Type): void {
    const div = document.createElement("div");
    ReactDOM.render(
      markup,
      div,
      () => renderedAssets = renderedAssets.set(type, div.innerHTML)
    );
  }

  function prepareAsset(asset: string, params: { [key: string]: string }): string {
    let result = asset;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        result = result.replace("{{" + key + "}}", value);
      }
    }
    return Header + btoa(result);
  }

  export function use(type: Type, params: { [key: string]: string }): string | undefined {
    const asset = renderedAssets.get(type);
    if (asset) {
      const assetKey = type + "/" + JSON.stringify(params);
      let result = preparedAssets.get(assetKey);
      if (result === undefined) {
        result = prepareAsset(asset, params);
        preparedAssets = preparedAssets.set(assetKey, result);
      }
      return result;
    }
  }

  export function isRenderedAsset(url: string): boolean {
    return url.startsWith("@") && url.indexOf("#") !== -1;
  }

  export function decode(params: string): string | undefined {
    if (params.startsWith("@")) {
      const trimmed = params.slice(1);
      const separator = trimmed.indexOf("#");
      if (separator !== -1) {
        const type = trimmed.slice(0, separator) as Type;
        const json = trimmed.slice(separator + 1);
        try {
          const assetParams = JSON.parse(json);
          return use(type, assetParams);
        } catch (error) {
          console.error("Could not parse asset params: ", params, error);
          return undefined;
        }
      }
    }
  }

  export function mayBeDecode(params: string): string {
    return decode(params) || params;
  }

  export function init(): void {
    renderAsset(<Envelope color={"{{color}}"}/>, Type.Envelope);
  }

  // setTimeout(() => use(Type.Envelope, { color: "black" }), 1000);
}
