import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { OrganizationPortalUserRole } from "../enums/organizationPortalUserRole";

export interface OrganizationPortalUser {
  readonly id: number;
  readonly organizationId: number;
  readonly emailAddress: string;
  readonly role: OrganizationPortalUserRole;

  readonly firstName: string | undefined;
  readonly lastName: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly signedInAt: Date | undefined;
}

export namespace OrganizationPortalUser {
  export function parse(data: GraphQL.OrganizationPortalUserFragment): OrganizationPortalUser {
    return {
      id: data.id,
      organizationId: data.organizationId,
      emailAddress: data.emailAddress,
      role: data.role,

      firstName: nullToUndefined(data.firstName),
      lastName: nullToUndefined(data.lastName),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      signedInAt: mapOptional(data.signedInAt, (value) => new Date(value))
    };
  }

  export function cacheId(organizationPortalUserId: number): string {
    return cachedObjectId("OrganizationPortalUser", organizationPortalUserId.toString());
  }

  export const fragmentName = "OrganizationPortalUser";
}
