import * as React from "react";
import { styled } from "../../app/theme";
import { prettyPrint } from "../../utils/formatting";
import { State } from "../state";
import { Placeholder } from "./placeholder";
import { deepToJSON } from "../../utils/misc";

const StyledPre = styled.pre`
  padding: 0;
  margin: 0;
  font-size: .8rem;
  overflow: auto;
`;

interface JsonContentProps {
  data?: any;
  state?: State<any>;
  placeholder?: string;
}

export const JsonContent: React.FunctionComponent<JsonContentProps> = (props) => {
  const output = props.state && props.state.output.toJS();
  return (
    <StyledPre>
      {
        prettyPrint(deepToJSON(output || props.data)) ||
        <Placeholder>{props.placeholder || "Unavailable"}</Placeholder>
      }
    </StyledPre>
  );
};
