import * as React from "react";
import { Block } from "../containers/block";
import { Panel } from "../containers/panel";
import { TitlePanelRow } from "../containers/rows/titlePanelRow";
import { PanelRow } from "../containers/rows/panelRow";
import { Paragraph } from "../widgets/paragraph";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { CopyToClipboardButton } from "../utils/copyTextToClipboard";
import { useAppBootstrapConfig } from "../../app/configuration";
import { styled } from "../../app/theme";
import { SchoolSummary } from "../../types/models/school";
import { makeAmbassadorLink } from "../../utils/makeAmbassadorLink";
import { Connection } from "../../types/models/connection";
import { useRoutes } from "../../app/routes/useRoutes";
import { Link } from "react-router-dom";

interface Props {
  school: SchoolSummary;
  ambassadorCode: string;
  destinationConnection: Connection | undefined;
  numberOfFriendsHelped: number | undefined;
  showDashboardLink: boolean;
}

export const AmbassadorLinksPanel: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();
  const routes = useRoutes();

  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  const genericAmbassadorLink = makeAmbassadorLink(props.ambassadorCode, appBootstrapConfig.ambassadorsProgram.link);
  const schoolAmbassadorLink = props.school.landingPageUrl &&
    makeAmbassadorLink(props.ambassadorCode, props.school.landingPageUrl);

  const genericLinkContent = (
    <>
      <Paragraph>
        <SimpleToolbar>
          <AmbassadorLink href={genericAmbassadorLink} target={"_blank"}>{genericAmbassadorLink}</AmbassadorLink>
          <CopyToClipboardButton text={genericAmbassadorLink}/>
        </SimpleToolbar>
      </Paragraph>
      <BoldParagraph>
        Share this link with anyone on any social media platform - whether they're from{" "}
        {props.school.commonName ? props.school.commonName : "your school "} or another school - in any way
        you like to get unlimited entries to the iPad giveaway!
      </BoldParagraph>
    </>
  );

  return (
    <Block>
      <Panel>
        <TitlePanelRow>Want to help more people and earn more entries to the monthly iPad giveaway?</TitlePanelRow>
        <PanelRow>
          {
            schoolAmbassadorLink
              ? (
                <>
                  <Paragraph>Your EDU Ambassador Links:</Paragraph>
                  <Paragraph>
                    <SimpleToolbar>
                      <AmbassadorLink href={schoolAmbassadorLink} target={"_blank"}>
                        {schoolAmbassadorLink}
                      </AmbassadorLink>
                      <CopyToClipboardButton text={schoolAmbassadorLink}/>
                    </SimpleToolbar>
                  </Paragraph>
                  <BoldParagraph>
                    Share this link with anyone from{" "}
                    {props.school.commonName ? props.school.commonName : "your school"}{" "}
                    on any social media platform in any way you like to get unlimited entries to the iPad giveaway!
                  </BoldParagraph>
                  {genericLinkContent}
                </>
              )
              : (
                <>
                  <Paragraph>Your EDU Ambassador Link:</Paragraph>
                  {genericLinkContent}
                </>
              )
          }
          <Paragraph>
            For every friend who uses your link to set up their EDU account migration, they'll get {codeDiscount}% off
            their EDU Migration Fee, and you'll earn an additional entry in the iPad giveaway. No purchase
            necessary. Unlimited entries!
          </Paragraph>
          <Paragraph>
            {props.numberOfFriendsHelped && (
              <>
                A copy of your EDU Ambassador link has been sent to your source account and destination account for
                your records, along with further details on how to track how many entries you've got.{" "}
              </>
            )}
            If you win the iPad giveaway, you'll be notified{" "}
            {
              props.destinationConnection
                ? "at your destination account: " + props.destinationConnection.personalInfo.emailAddress
                : "via email"
            }.
          </Paragraph>
          {props.showDashboardLink && (
            <Paragraph>
              Visit your{" "}
              <Link to={routes.ambassadors.myAmbassadorDashboardPath} target={"_blank"}>
                EDU Ambassador Dashboard
              </Link>
              {" "}to keep track of your entries to the iPad giveaway.
              You can use your source or destination account to access this dashboard.
            </Paragraph>
          )}
        </PanelRow>
      </Panel>
    </Block>
  );
};

const AmbassadorLink = styled.a`
  font-weight: ${(props) => props.theme.font.medium};
  font-size: 1.2rem;
  margin-right: .5rem;
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: ${(props) => props.theme.font.medium};
  margin-bottom: 1.5rem;
`;
