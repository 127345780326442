import { PricingModel } from "../../types/models/pricingModel";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchPricingModelsQuery(
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchPricingModelsQueryVariables, List<PricingModel>> {
  return useManagedQuery({
    query: GraphQL.useSearchPricingModelsQuery,
    deps: term,
    prepare: (deps) => ({ term: deps }),
    extract: (data: GraphQL.SearchPricingModelsQuery) => nullToUndefined(data.searchPricingModels),
    complete: (pricingModels) => List(pricingModels)
      .filter((pricingModel) => pricingModel.id >= 0)
      .map(PricingModel.parse),
    fetchPolicy: "cache-and-network"
  });
}
