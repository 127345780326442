import * as React from "react";
import { styled } from "../../../../app/theme";
import { Link } from "react-router-dom";
import { Block } from "../../../containers/block";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Panel } from "../../../containers/panel";
import { Paragraph } from "../../../widgets/paragraph";

interface Props {
  name: string;
  sourceType: string;
  entityType: string;
  endUserDiscount: string;
  revenueShare: number;
  endUserAccess: React.ReactNode;
  isEnabled: boolean;
  detailsPath?: string;
  children?: React.ReactNode;
}

export const EarningsSourceDetails: React.FunctionComponent<Props> = (props) => (
  <Block>
    <Panel>
      <PanelRow>
        <Type>{props.sourceType}:{" "}</Type>
        <Name>{props.name}</Name>
      </PanelRow>
      <PanelRow>
        <Paragraph>
          {props.entityType} ({props.isEnabled ? "enabled" : "disabled"})
          {props.detailsPath && <>&nbsp;&nbsp;<Link to={props.detailsPath}>Manage</Link></>}
        </Paragraph>
        <Paragraph>Member Discount: {props.endUserDiscount}</Paragraph>
        <Paragraph>Rev Share to Alumni Association: {props.revenueShare}%</Paragraph>
        <Paragraph>
          <EndUserAccessLayout>
            <div>Member Benefit Access:</div>
            <EndUserAccessText>{props.endUserAccess}</EndUserAccessText>
          </EndUserAccessLayout>
        </Paragraph>
      </PanelRow>
      {props.children}
    </Panel>
  </Block>
);

const Type = styled.span`
  font-size: 1.7rem;
`;

const Name = styled.span`
  font-size: 1.7rem;
  font-weight: 400;
`;

const EndUserAccessLayout = styled.div`
  display: flex;
`;

const EndUserAccessText = styled.div`
  max-width: 40rem;
  margin-left: .5rem;
`;
