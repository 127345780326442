import * as React from "react";
import { Panel } from "../../containers/panel";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { UserMigrationsGrid } from "../../screens/userDashboardPageView/userMigrationsGrid";
import { OperationStatus } from "../../../types/operationStatus";
import { PanelRow } from "../../containers/rows/panelRow";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";
import { friendlyCount } from "../../../utils/formatting";
import { UserMigrations } from "../../../types/models/userMigrations";

interface Props {
  status: OperationStatus<UserMigrations>;
  onRefresh: () => void;
}

export const UserMigrationsPanel: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      status={props.status}
      subject={"migrations"}
      indicators={StatusIndicators.PanelRow()}
    />
    {props.status.mapLastResult((result) => (
      <>
        {props.status.isSuccess() && (
          <PanelRow>
            <SimpleToolbar
              centerVertically={true}
              rightSide={props.onRefresh && (
                <Button
                  size={"small"}
                  color={"white"}
                  disabled={props.status.isWorking()}
                  onClick={props.onRefresh}
                >
                  Refresh
                </Button>
              )}
            >
              {friendlyCount(result.batches.size + result.migrations.size, "migration") + " found"}
            </SimpleToolbar>
          </PanelRow>
        )}
        <GridPanelRow>
          <UserMigrationsGrid userMigrations={result}/>
        </GridPanelRow>
      </>
    ))}
  </Panel>
);
