import * as React from "react";
import { LookupControllerType } from "../lookupModal";
import { Program } from "../../../types/models/program";
import { ItemDetailsControllerType, LookupField } from "../../widgets/lookupField";
import { useBrowser } from "../../../utils/useBrowser";

export type ProgramLookupControllerType = LookupControllerType<string, Program>;

export type ProgramDetailsControllerType = ItemDetailsControllerType<string, Program>;

export type ProgramViewPathFactory = (id: string) => string;

interface Props<V> {
  name: keyof V;

  lookupController: ProgramLookupControllerType;
  detailsController: ProgramDetailsControllerType;
  viewPathFactory: ProgramViewPathFactory;

  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export function ProgramLookupField<V>(props: Props<V>): React.ReactElement {
  const browser = useBrowser();

  return (
    <LookupField<V, string, Program>
      label={props.label || "End-user program"}
      name={props.name}
      subject={"program"}
      gridColumns={[
        {
          title: "Name",
          render: (program) => program.name
        }
      ]}

      lookupController={props.lookupController}
      itemDetailsController={props.detailsController}
      renderItem={(program) => <>{program.name}</>}

      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled}

      onView={(programId) => browser.openNewTab(props.viewPathFactory(programId))}
    />
  );
}
