import { List } from "immutable";

export abstract class Option<A> {
  public abstract isEmpty(): boolean;

  public isDefined(): boolean {
    return !this.isEmpty();
  }

  public abstract get(): A;

  public getOrElse<B>(defaultValue: () => B): A | B {
    return this.isEmpty() ? defaultValue() : this.get();
  }

  public getOrUse<B>(defaultValue: B): A | B {
    return this.isEmpty() ? defaultValue : this.get();
  }

  public map<B>(f: (a: A) => B): Option<B> {
    return this.isEmpty() ? None() : Some(f(this.get()));
  }

  public flatMap<B>(f: (a: A) => Option<B>): Option<B> {
    return this.isEmpty() ? None() : f(this.get());
  }

  public filter(p: (a: A) => boolean): Option<A> {
    return this.isEmpty() || p(this.get()) ? this : None();
  }

  public filterNot(p: (a: A) => boolean): Option<A> {
    return this.isEmpty() || !p(this.get()) ? this : None();
  }

  public nonEmpty(): boolean {
    return this.isDefined();
  }

  public contains(elem: A): boolean {
    return !this.isEmpty() && this.get() === elem;
  }

  public exists(p: (a: A) => boolean): boolean {
    return !this.isEmpty() && p(this.get());
  }

  public forall(p: (a: A) => boolean): boolean {
    return this.isEmpty() || p(this.get());
  }

  public orElse<B>(alternative: () => Option<B>): Option<A | B> {
    return this.isEmpty() ? alternative() : this;
  }

  public toList(): List<A> {
    return this.isEmpty() ? List() : List([this.get()]);
  }

  public toJS(): A | undefined {
    return this.isEmpty() ? undefined : this.get();
  }
}

export function None<A>(): Option.NoneImpl<A> {
  return new Option.NoneImpl();
}

export function Some<A>(value: A): Option.SomeImpl<A> {
  return new Option.SomeImpl(value);
}

export namespace Option {
  export function mayBe<A>(value: A | null | undefined): Option<A> {
    return value === null || value === undefined ? None() : Some(value);
  }

  export function isOption<A>(value: any): value is Option<A> {
    return value instanceof Option;
  }

  export function flatten<A>(opt: Option<Option<A>>): Option<A> {
    return opt.getOrElse(() => None());
  }

  export function flatten2<A>(opt: Option<Option<Option<A>>>): Option<A> {
    return opt.getOrElse<Option<Option<A>>>(() => None()).getOrElse(() => None());
  }

  export class NoneImpl<A> extends Option<A> {
    public isEmpty(): boolean {
      return true;
    }

    public get(): A {
      throw new Error("Option is empty");
    }

    public toJSON(): any {
      return null;
    }
  }

  export class SomeImpl<A> extends Option<A> {
    constructor(private value: A) {
      super();
    }

    public isEmpty(): boolean {
      return false;
    }

    public get(): A {
      return this.value;
    }

    public toJSON(): any {
      return this.value;
    }
  }
}
