import { styled } from "../../../../app/theme";

export const PropertiesTable = styled.table`
  tr {
    td {
      vertical-align: top;
      padding: .25rem .5rem;

      &:first-of-type {
        padding-left: 0;
        white-space: nowrap;
      }

      &:last-of-type {
        font-weight: 400;
        max-width: 50rem;

        a {
          font-weight: 300;
        }

        &.error {
          font-weight: 300;
          color: ${(props) => props.theme.colors.red};
        }

        &.pending {
          font-weight: 300;
          color: ${(props) => props.theme.colors.darkGray};
        }
      }
    }

    &:first-of-type td {
      padding-top: 0;
    }

    &:last-of-type td {
      padding-bottom: 0;
    }
  }
`;
