import * as React from "react";
import { styled } from "../../app/theme";

export const PropertiesTableContainer = styled.div<{ noMargin?: boolean }>`
  overflow: auto;
  margin-top: ${(props) => props.noMargin ? 0 : 0.5}rem;
`;

export const PropertiesTable = styled.table`
  > tbody > tr {
    > th {
      padding: .1rem .3rem .1rem 0;
      font-size: 0.8rem;
      font-weight: normal;
      text-align: right;
      vertical-align: top;
      white-space: nowrap;
    }

    > td {
      padding: .1rem 0;
      font-size: 0.8rem;
      text-align: left;
      vertical-align: top;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
`;
