import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { EmailPreview } from "../../types/models/emailPreview";

type RenderAmbassadorEmailPreviewMutationHook = [
  (sourceConnectionId: string) => Promise<EmailPreview>,
  OperationStatus<EmailPreview>
];

export function useRenderAmbassadorEmailPreviewMutation(): RenderAmbassadorEmailPreviewMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useRenderAmbassadorEmailPreviewMutation,
    extract: (data: GraphQL.RenderAmbassadorEmailPreviewMutation) => nullToUndefined(data.renderAmbassadorEmailPreview),
    complete: identity
  });

  function fireWith(sourceConnectionId: string): Promise<EmailPreview> {
    return fire({
      variables: { sourceConnectionId },
      retry: () => fireWith(sourceConnectionId),
    });
  }

  return [fireWith, status];
}
