import { List } from "immutable";

export enum WorkStatus {
  Pending = "Pending",
  Working = "Working",
  Issue = "Issue",
  Failure = "Failure",
  Success = "Success"
}

export namespace WorkStatus {
  export function reduce(statuses: List<WorkStatus>): WorkStatus {
    if (statuses.contains(WorkStatus.Working)) {
      return WorkStatus.Working;
    } else if (statuses.contains(WorkStatus.Issue)) {
      return WorkStatus.Issue;
    } else if (statuses.contains(WorkStatus.Failure)) {
      return WorkStatus.Failure;
    } else {
      return WorkStatus.Success;
    }
  }
}
