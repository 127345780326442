import * as React from "react";
import { styled } from "../../app/theme";
import { OverflowDetector } from "./overflowDetector";
import { PinnedContentPortal } from "./pinnedContent";
import { AdminToolsMode } from "../../state/settings/state";
import { useAdminToolsMode } from "../layouts/useAdminToolsMode";

const StickyContentLayout = styled.div<{ sidebarSize: AdminToolsMode }>`
  position: sticky;

  top: ${(props) => props.theme.layout.header.desktop}rem;

  ${(props) => props.theme.responsive.respondToSmall(
  props.sidebarSize === AdminToolsMode.SplitScreen
  )} {
    top: ${(props) => props.theme.layout.header.mobile}rem;
  }
`;

const StyledOverflowDetector = styled(OverflowDetector)<{ sidebarSize: AdminToolsMode }>`
  //background-color: red;
  //width: 100%;  

  top: ${(props) => -props.theme.layout.header.desktop}rem;
  
  ${(props) => props.theme.responsive.respondToSmall(
  props.sidebarSize === AdminToolsMode.SplitScreen
  )} {
    top: ${(props) => -props.theme.layout.header.mobile}rem;
  }
`;

const Overflow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.offWhite};
`;

interface Props {
  whenPinned?: React.ReactNode;
}

export const StickyContent: React.FunctionComponent<Props> = (props) => {
  const [pinned, setPinned] = React.useState(false);

  const sidebarSize = useAdminToolsMode();

  return (
    <>
      <StyledOverflowDetector sidebarSize={sidebarSize} onChange={setPinned}/>
      <StickyContentLayout sidebarSize={sidebarSize}>
        {props.children}
        {pinned && <Overflow/>}
      </StickyContentLayout>
      {pinned && <PinnedContentPortal>{props.whenPinned || props.children}</PinnedContentPortal>}
    </>
  );
};
