import * as React from "react";
import { Breadcrumbs, BreadcrumbsContent } from "./breadcrumbs";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { css, styled } from "../../app/theme";
import { PanelRowDefs } from "../containers/rows/panelRow";
import { TextBox } from "./textBox";
import { Button } from "./button";
import { useAppBootstrapConfig } from "../../app/configuration";
import { useRoutes } from "../../app/routes/useRoutes";
import { AdminToolsMode } from "../../state/settings/state";
import { useAdminToolsMode } from "../layouts/useAdminToolsMode";

export interface EditableTitleConfig {
  maxLength: number;
  onChange: (value: string) => Promise<any>;
  formDisabled: boolean;
}

interface Props {
  breadcrumbs?: BreadcrumbsContent;
  title: string;
  subTitle?: React.ReactNode;
  footer?: React.ReactNode;
  editableTitleConfig?: EditableTitleConfig;
  logoId?: string;
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-size: 0.8rem;
  margin-bottom: .8rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin-bottom: .5rem;
  }
`;

const MasterLayout = styled.div`
  background: white;
`;

const NoLogoLayout = styled.div`
  padding: ${PanelRowDefs.PanelHorizontalPadding}rem;
`;

const LogoLayout = styled.div`
  padding: ${PanelRowDefs.PanelHorizontalPadding}rem;
  display: flex;
`;

const FooterLayout = styled.div`
  padding: ${PanelRowDefs.PanelHorizontalPadding}rem;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
`;

const ContentContainer = styled.div`
  flex-grow: 1;
`;

const Logo = styled.img<{ adminToolsMode: AdminToolsMode }>`
  max-height: 6.5rem;
  max-width: 6.5rem;
  margin-left: 1rem;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    max-height: 5rem;
    max-width: 5rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: none;
  }
`;

const StyledHeader = styled(Header).attrs({})<{ editable: boolean }>`
  ${(props) => props.editable && css`
    margin: -.3rem;
    padding: .3rem;
    width: fit-content;
    box-sizing: content-box;
    border: 1px solid transparent;

    &:hover {
      border-color: ${props.theme.colors.lightGray};
    }
  `}
`;

export const HeaderBlock: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();
  const routes = useRoutes();
  const adminToolsMode = useAdminToolsMode();

  const logoId = props.logoId || props.breadcrumbs && props.subTitle && appBootstrapConfig.configuration?.mainLogoId;
  const logoUrl = logoId && routes.api.downloadUrl(logoId);

  const [editing, setEditing] = React.useState(false);

  const content = (
    <>
      {props.breadcrumbs && <StyledBreadcrumbs content={props.breadcrumbs}/>}
      {
        props.editableTitleConfig && editing
          ? (
            <EditTitleForm
              title={props.title}
              maxLength={props.editableTitleConfig.maxLength}
              formDisabled={props.editableTitleConfig.formDisabled}
              onSubmit={(value) => {
                if (props.editableTitleConfig) {
                  props.editableTitleConfig.onChange(value).then(() => setEditing(false));
                }
              }}
              onCancel={() => setEditing(false)}
            />
          )
          : (
            <StyledHeader
              editable={!!props.editableTitleConfig}
              onClick={() => {
                if (props.editableTitleConfig) {
                  setEditing(true);
                }
              }}
            >
              {props.title}
            </StyledHeader>
          )
      }
      {props.subTitle && <SubHeader>{props.subTitle}</SubHeader>}
    </>
  );

  return (
    <MasterLayout>
      {
        logoUrl
          ? (
            <LogoLayout>
              <ContentContainer>{content}</ContentContainer>
              <Logo src={logoUrl} adminToolsMode={adminToolsMode}/>
            </LogoLayout>
          )
          : <NoLogoLayout>{content}</NoLogoLayout>
      }
      {props.footer && <FooterLayout>{props.footer}</FooterLayout>}
    </MasterLayout>
  );
};

const EditTitleFormLayout = styled.div`
  max-width: 30rem;
  display: flex;
  align-items: center;

  > * {
    flex-shrink: 0;
  }

  * + * {
    margin-left: .5rem;
  }
`;

interface EditTitleFormProps {
  title: string;
  maxLength: number;
  formDisabled: boolean;

  onSubmit: (value: string) => void;
  onCancel: () => void;
}

const EditTitleForm: React.FunctionComponent<EditTitleFormProps> = (props) => {
  const [value, setValue] = React.useState(props.title);

  const textBoxRef = React.createRef<HTMLInputElement>();

  React.useEffect(
    () => {
      if (textBoxRef.current) {
        textBoxRef.current.focus();
      }
    },
    []
  );

  return (
    <EditTitleFormLayout>
      <TextBox
        ref={textBoxRef}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        maxLength={props.maxLength}
        disabled={props.formDisabled}
      />
      <Button
        size={"small"}
        color={"primary"}
        disabled={props.formDisabled}
        onClick={() => props.onSubmit(value)}
      >
        Submit
      </Button>
      <Button
        size={"small"}
        color={"secondary"}
        onClick={props.onCancel}
      >
        Cancel
      </Button>
    </EditTitleFormLayout>
  );
};
