import * as React from "react";
import { OrganizationReportsPageView } from "../../views/screens/organizationPortal/organizationReportsPageView";
import { useBrowser } from "../../utils/useBrowser";
import { useRoutes } from "../../app/routes/useRoutes";

interface Props {
  organizationId: number;
}

export const OrganizationReportsPage: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  return (
    <OrganizationReportsPageView
      maxDays={100}
      onGenerate={(startDate, endDate) =>
        browser.openNewTab(routes.api.migrationsReportUrl(props.organizationId, startDate, endDate), true)
      }
    />
  );
};
