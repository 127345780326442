import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, mapOptional, nullToUndefined } from "../../utils/misc";
import { AccountAction } from "../../types/enums/accountAction";
import * as moment from "moment";

interface Params {
  sourceConnectionId: string;
  customMessage: string | undefined;
  accountAction: AccountAction;
  accountActionDate: Date | undefined;
}

type SendTestAmbassadorEmailMutationHook = [(params: Params) => Promise<string>, OperationStatus<string>];

export function useSendTestAmbassadorEmailMutation(): SendTestAmbassadorEmailMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useSendTestAmbassadorEmailMutation,
    extract: (data: GraphQL.SendTestAmbassadorEmailMutation) => nullToUndefined(data.sendTestAmbassadorEmail),
    complete: identity
  });

  function fireWith(params: Params): Promise<string> {
    return fire({
      variables: {
        ...params,
        accountActionDate: mapOptional(
          params.accountActionDate,
          // This interprets the local date as an UTC date
          (date) => moment.utc(moment(date).format("YYYY-MM-DD")).toISOString()
        )
      },
      retry: () => fireWith(params),
    });
  }

  return [fireWith, status];
}
