import { Connection } from "../../types/models/connection";
import * as yup from "yup";
import { CloudServices } from "../../types/models/cloudServices";
import { CloudService } from "../../types/models/cloudService";

export interface ConnectionSettings {
  mode: "admin" | "end-user" | "self";
  adminConnectionId: string | undefined;
}

export namespace ConnectionSettings {
  export const Schema = yup.object<ConnectionSettings>({
    mode: yup.mixed().oneOf(["admin", "end-user", "self"]),
    adminConnectionId: yup.string()
  });

  export function isValid(settings: ConnectionSettings, cloudServiceId: string): boolean {
    return settings.mode === "end-user" || settings.mode === "self" || (
      settings.mode === "admin" &&
      (cloudServiceId === CloudServices.GSuite || cloudServiceId === CloudServices.Office365) &&
      settings.adminConnectionId !== undefined
    );
  }

  export function description(
    source: boolean,
    settings: ConnectionSettings,
    cloudService: CloudService,
    connection: Connection | undefined
  ): string {
    const context = source ? "source" : "destination";

    switch (settings.mode) {
      case "admin":
        return cloudService.name + " using admin authentication" +
          (connection ? " (as " + connection.descriptionOrId() + ")" : "");

      case "self":
        return cloudService.name + " manually connecting each " + context + " account";

      case "end-user":
        return cloudService.name + " asking the owners of the " + context + " accounts to connect them";
    }
  }
}

export interface ResolvedConnectionSettings {
  mode: "admin" | "end-user" | "self";
  adminConnection: Connection | undefined;
}
