import * as React from "react";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { Link } from "react-router-dom";
import { OperationStatus } from "../../../../types/operationStatus";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { Button } from "../../../widgets/button";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OffboardingProjectUpdate } from "../../../../types/models/offboardingProjectUpdate";
import { List } from "immutable";
import { Grid } from "../../../widgets/grid";
import { Block } from "views/containers/block";
import { GridPanelRow } from "views/containers/rows/gridPanelRow";
import { Panel } from "views/containers/panel";
import { commafy, friendlyCount, friendlyDateTime } from "../../../../utils/formatting";
import { PanelRow } from "views/containers/rows/panelRow";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { styled } from "../../../../app/theme";
import { CheckedCircle } from "../../../glyphs/checkedCircle";
import { Spinner } from "../../../glyphs/spinner";
import { LinkButton } from "../../../widgets/linkButton";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { AccessListUpdateStatus } from "../../../../types/enums/accessListUpdateStatus";

interface Props {
  offboardingProjectId: string;
  offboardingProjectStatus: OperationStatus<OffboardingProject>;
  offboardingProjectHistoryStatus: OperationStatus<List<OffboardingProjectUpdate>>;

  homePath: string;
  offboardingProjectPath: string;

  onRefresh: () => void;
  onDownloadOutput: (offboardingProjectUpdateId: string) => void;
}

export const ImportHistoryPageView: React.FunctionComponent<Props> = (props) => (
  <>
    <HeaderBlock
      breadcrumbs={[
        <Link key={0} to={props.homePath}>End-User Offboarding</Link>,
        <Link key={1} to={props.offboardingProjectPath}>
          {
            props.offboardingProjectStatus
              .mapLastResult((offboardingProject) => offboardingProject.name) || "..."
          }
        </Link>,
        "Import History"
      ]}
      title={"Import History"}
    />
    <OperationStatusIndicator
      subject={"offboarding project details"}
      status={props.offboardingProjectStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    <Block>
      <Panel>
        <OperationStatusIndicator
          subject={"offboarding project history"}
          status={props.offboardingProjectHistoryStatus}
          indicators={StatusIndicators.PanelRow()}
        />
        {props.offboardingProjectHistoryStatus.mapLastResult((offboardingProjectUpdates) => (
          <>
            <PanelRow size={"smaller"}>
              <SimpleToolbar
                centerVertically={true}
                rightSide={
                  <Button
                    size={"small"}
                    color={"secondary"}
                    disabled={props.offboardingProjectStatus.isWorking()}
                    onClick={props.onRefresh}
                  >
                    Refresh
                  </Button>
                }
              >
                <span>{friendlyCount(offboardingProjectUpdates.size, "import")} found</span>
              </SimpleToolbar>
            </PanelRow>
            {!offboardingProjectUpdates.isEmpty() && (
              <GridPanelRow>
                <HistoryGrid
                  offboardingProjectUpdates={offboardingProjectUpdates}
                  onDownloadOutput={props.onDownloadOutput}
                />
              </GridPanelRow>
            )}
          </>
        ))}
      </Panel>
    </Block>
    <Block>
      <Link to={props.offboardingProjectPath}>
        <Button size={"small"} color={"secondary"}>Back</Button>
      </Link>
    </Block>
  </>
);

interface HistoryGridProps {
  offboardingProjectUpdates: List<OffboardingProjectUpdate>;
  onDownloadOutput: (offboardingProjectUpdateId: string) => void;
}

export const HistoryGrid: React.FunctionComponent<HistoryGridProps> = (props) => (
  <Grid>
    <Grid.Header>
      <Grid.Column>Started</Grid.Column>
      <Grid.Column>Completed</Grid.Column>
      <Grid.Column>Status</Grid.Column>
      <Grid.Column>Imported Entries</Grid.Column>
      <Grid.Column>Errors</Grid.Column>
      <Grid.Column>Import Results (CSV)</Grid.Column>
    </Grid.Header>
    <Grid.Body>
      {props.offboardingProjectUpdates.map((update) => (
        <Grid.Row key={update.id}>
          <Grid.Cell>{friendlyDateTime(update.createdAt)}</Grid.Cell>
          <Grid.Cell>{update.completedAt ? friendlyDateTime(update.createdAt) : ""}</Grid.Cell>
          <Grid.Cell
            status={
              update.status === AccessListUpdateStatus.Success
                ? WidgetStatus.Success
                : update.status === AccessListUpdateStatus.Failure
                  ? WidgetStatus.Error
                  : WidgetStatus.Info
            }
          >
            <StatusIndicatorContainer>
              {
                update.status === AccessListUpdateStatus.Success
                  ? <CheckedCircle/>
                  : update.status === AccessListUpdateStatus.Failure
                    ? <Spinner progress={50} color={"red"}/>
                    : <Spinner progress={-1}/>
              }
            </StatusIndicatorContainer>
            {
              update.status === AccessListUpdateStatus.Success || update.status === AccessListUpdateStatus.Failure
                ? update.status
                : "Running"
            }
            {update.status === AccessListUpdateStatus.Failure && <>{" "}({update.errorMessage})</>}
          </Grid.Cell>
          <Grid.Cell>{commafy(update.processedEntries)}</Grid.Cell>
          <Grid.Cell>{commafy(update.errors)}</Grid.Cell>
          <Grid.Cell>
            {
              update.status === AccessListUpdateStatus.Success &&
                <LinkButton onClick={() => props.onDownloadOutput(update.id)}>Download import results (CSV)</LinkButton>
            }
          </Grid.Cell>
        </Grid.Row>
      ))}
    </Grid.Body>
  </Grid>
);

const StatusIndicatorContainer = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  font-size: 0.9rem;
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
`;
