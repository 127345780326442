import * as React from "react";
import { AccessKeyRevealTool } from "./accessKeyRevealTool";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { ConnectionProperties } from "../../views/blocks/connectionManagement/connectionProperties";
import { ConnectionManagementToolsView } from "../../views/blocks/connectionManagement/connectionManagementToolsView";
import { Connection } from "../../types/models/connection";
import { useConnection } from "../../queries/useConnections";
import { AccessKeyCheckTool } from "./accessKeyCheckTool";
import { AccessKeyUpgradeTool } from "./accessKeyUpgradeTool";
import { AccessKeyRevokeTool } from "./accessKeyRevokeTool";
import { AdminArea } from "../../views/utils/whenElevated";

interface Props {
  connection: Connection;
}

export const ConnectionManagementTools: React.FunctionComponent<Props> = (props) => {
  const authProviders = useAuthProviders();

  // This components is displayed in a drawer, and therefore it won't receive updates on Connection object from
  // it's parent. Therefore, we are going to query it again.
  const [status] = useConnection(props.connection.userId, props.connection.id);

  // This seems to be the only way to prevent the situation when managed query does not receive completion signal
  // and stays in Working state forever. :( Is this because some Observable subscriptions are not working properly?
  // GraphQL.useGetConnectionQuery({
  //   variables: {
  //     userId: props.connection.userId,
  //     connectionId: props.connection.id
  //   },
  //   fetchPolicy: "cache-and-network"
  // });

  return (
    <AdminArea>
      <ConnectionManagementToolsView
        status={status}
        renderViewTool={({ result: connection }) => (
          <ConnectionProperties
            connection={connection}
            authProvider={authProviders.getOrFail(connection.accessKeyProvider)}
          />
        )}
        renderCheckTool={({ result: connection }) => (
          <AccessKeyCheckTool
            connection={connection}
            authProvider={authProviders.getOrFail(connection.accessKeyProvider)}
          />
        )}
        renderUpgradeTool={({ result: connection }) => (
          <AccessKeyUpgradeTool connection={connection}/>
        )}
        renderRevealTool={({ result: connection }) => (
          <AccessKeyRevealTool
            connection={connection}
            authProvider={authProviders.getOrFail(connection.accessKeyProvider)}
          />
        )}
        renderRevokeTool={({ result: connection }) => (
          <AccessKeyRevokeTool connection={connection}/>
        )}
      />
    </AdminArea>
  );
};
