import * as React from "react";
import { AppTheme, css, styled } from "../../app/theme";
import { LinkButton } from "../widgets/linkButton";
import { StyledComponentsProps } from "../utils/styledComponentsProps";
import { WarningSign } from "../glyphs/warningSign";
import { ActionItem } from "../models/actionItem";
import { Button } from "../widgets/button";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { DynamicModalController, DynamicModalHook, ModalController, useDynamicModal, useModal } from "../layouts/modal";
import { DrawerBlock } from "../containers/drawerBlock";
import { scrollIntoViewById } from "../../utils/scrollIntoView";
import { Paragraph } from "../widgets/paragraph";
import { titleize } from "../../utils/titleize";
import { SemiBold } from "../widgets/semiBold";
import { indefiniteArticle } from "../../utils/indefiniteArticle";
import { WhenElevated } from "../utils/whenElevated";

function actionItemViewId(actionItemId: string): string {
  return "ActionItem::" + actionItemId;
}

export const ActionItemViewClassName = "action-item-view";

function backgroundColor(theme: AppTheme, actionItemType: ActionItem.Type, suppressed: boolean): string {
  if (suppressed) {
    return theme.colors.lighterGray;
  } else {
    switch (actionItemType) {
      case ActionItem.Type.Action:
      case ActionItem.Type.Notification:
        return theme.colors.faintPrimary;

      case ActionItem.Type.Warning:
        return theme.colors.lightYellow;

      case ActionItem.Type.Error:
        return theme.colors.lightRed;
    }
  }
}

function borderColor(theme: AppTheme, actionItemType: ActionItem.Type, suppressed: boolean): string {
  if (suppressed) {
    return theme.colors.darkGray;
  } else {
    switch (actionItemType) {
      case ActionItem.Type.Action:
      case ActionItem.Type.Notification:
        return theme.colors.primary;

      case ActionItem.Type.Warning:
        return theme.colors.yellow;

      case ActionItem.Type.Error:
        return theme.colors.red;
    }
  }
}

function icon(actionItemType: ActionItem.Type): React.ReactNode {
  switch (actionItemType) {
    case ActionItem.Type.Warning: return <Icon color={"yellow"}/>;
    case ActionItem.Type.Error: return <Icon color={"red"}/>;
  }
}

function resolveActionTitle(actionItemType: ActionItem.Type): string {
  switch (actionItemType) {
    case ActionItem.Type.Action: return "take";
    default: return "clear";
  }
}

function suppressActionTitle(actionItemType: ActionItem.Type): string {
  switch (actionItemType) {
    case ActionItem.Type.Action: return "skip";
    default: return "ignore";
  }
}

function longActionDescription(actionItemType: ActionItem.Type, suppressAction: ActionItem.SuppressAction): string {
  switch (actionItemType) {
    case ActionItem.Type.Action:
      return indefiniteArticle(ActionItem.friendlyType(actionItemType)) + " " +
        ActionItem.friendlyType(actionItemType) + " " +
        "that will enable VaultMe to migrate your " + suppressAction.affectedSubject + "s";

    default:
      return indefiniteArticle(ActionItem.friendlyType(actionItemType)) + " " +
        ActionItem.friendlyType(actionItemType) + " " +
        "that must be cleared to have your " + suppressAction.affectedSubject + "s migrated";
  }
}

function suppressMessage(actionItemType: ActionItem.Type, suppressAction: ActionItem.SuppressAction) {
  switch (suppressAction.result) {
    case ActionItem.SuppressAction.Result.WillNotMigrate:
      return (
        <>
          <Paragraph>
            You are about to{" "}
            {
              suppressActionTitle(actionItemType) + " " +
              longActionDescription(actionItemType, suppressAction)
            }
            . By clicking
            the <SemiBold>{titleize(suppressActionTitle(actionItemType))}</SemiBold> button below you confirm that you
            do not need to migrate your {suppressAction.affectedSubject}s and they can be safely excluded from this
            migration.
          </Paragraph>
          <Paragraph>
            Please note: if you change your mind after the migration starts, VaultMe will be unable to additionally
            include {suppressAction.affectedSubject}s in the progressing migration. Instead, you will need to wait
            until your current migration is complete and run a new migration to copy {suppressAction.affectedSubject}s.
            Standard migration fees will apply.
          </Paragraph>
          <Paragraph>
            Click <SemiBold>Cancel</SemiBold> if you prefer to go back and {resolveActionTitle(actionItemType)} the{" "}
            {ActionItem.friendlyType(actionItemType)} to enable VaultMe to migrate your{" "}
            {suppressAction.affectedSubject}s.
          </Paragraph>
        </>
      );
  }
}

const ActionItemLayout = styled.div<{ actionItemType: ActionItem.Type, suppressed: boolean }>`
  display: flex;
  background: ${(props) => backgroundColor(props.theme, props.actionItemType, props.suppressed)};
  color: ${(props) => props.suppressed ? props.theme.colors.darkGray : props.theme.colors.black};
  ${(props) =>
  !props.suppressed &&
  css`border-left: 2px solid ${borderColor(props.theme, props.actionItemType, props.suppressed)};`
};
  padding: 0.8rem 0.8rem 0.8rem ${(props) => props.actionItemType === ActionItem.Type.Action ? 1.25 : 0.8}rem;
`;

const Icon = styled(WarningSign)`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .8rem;
  flex-shrink: 0;
`;

const Body = styled.div`
`;

const Message = styled.div<{ spacing: "none" | "small" | "large" }>`
  margin-bottom: ${(props) => props.spacing === "none" ? 0 : props.spacing === "small" ? 0.25 : 0.5}rem;
`;

const Suppressed = styled.div`
  font-size: ${(props) => props.theme.font.small}rem;
`;

interface ActionItemViewProps extends StyledComponentsProps {
  actionItem: ActionItem;
  suppressed: boolean;

  onSuppress: (() => void) | undefined;
  onReActivate: (() => void) | undefined;
}

const StyledActionItemView: React.FunctionComponent<ActionItemViewProps> = (props) => (
  <ActionItemLayout
    id={actionItemViewId(props.actionItem.id)}
    actionItemType={props.actionItem.type}
    suppressed={props.suppressed}
    className={
      props.actionItem.masterActionItemId !== undefined ? "" : (ActionItemViewClassName + " ") +
        props.className
    }
  >
    {
      props.suppressed
        ? (
          <Body>
            <SuppressedState
              actionItem={props.actionItem}
              onReActivate={props.onReActivate}
            />
          </Body>
        )
        : (
          <>
            {icon(props.actionItem.type)}
            <Body>
              <ActiveState
                actionItem={props.actionItem}
                onSuppress={props.onSuppress}
              />
            </Body>
          </>
        )
    }
  </ActionItemLayout>
);

interface ActiveStateProps {
  actionItem: ActionItem;
  onSuppress: (() => void) | undefined;
}

const ActiveState: React.FunctionComponent<ActiveStateProps> = (props) => {
  const actionModal = useDynamicModal();

  const masterActionItemId = props.actionItem.masterActionItemId;
  const suppressAction = props.actionItem.suppressAction;
  const suppressVerb = suppressActionTitle(props.actionItem.type);

  const actions = props.actionItem.actions
    .map((action, index) => ({ isPrimary: index === 0, ...action }))
    .concat(
      suppressAction && props.onSuppress
        ? [{
          title: titleize(suppressVerb),
          modal: {
            background: "alert",
            title: titleize(suppressVerb + " " + ActionItem.friendlyType(props.actionItem.type)),
            render: ({ close }) => (
              <>
                <DrawerBlock>
                  {suppressAction && suppressMessage(props.actionItem.type, suppressAction)}
                </DrawerBlock>
                <DrawerBlock>
                  <SimpleToolbar>
                    <Button
                      onClick={() => {
                        if (props.onSuppress) {
                          props.onSuppress();
                        }
                        close();
                      }}
                    >
                      {titleize(suppressVerb)}
                    </Button>
                    <Button color={"secondary"} onClick={close}>
                      Cancel
                    </Button>
                  </SimpleToolbar>
                </DrawerBlock>
              </>
            )
          },
          isPrimary: false
        }]
        : []
    )
    .concat(
      masterActionItemId
        ? [{
          title: "View",
          onClick: () => masterActionItemId && scrollIntoViewById(actionItemViewId(masterActionItemId)),
          isPrimary: false
        }]
        : []
    );

  return (
    <>
      <DynamicModalController modal={actionModal}/>
      <Message spacing={actions.length !== 0 ? "large" : "none"}>
        {props.actionItem.message}
        {/*{masterActionItemId && <div>{masterActionItemId}</div>}*/}
      </Message>
      <SimpleToolbar>
        {actions.map((action) => {
          const button = (
            <ActionButton
              key={action.title}
              action={action}
              isPrimary={action.isPrimary}
              actionModal={actionModal}
              onSuppress={props.onSuppress}
            />
          );
          return action.adminOnly
            ? <WhenElevated>{button}</WhenElevated>
            : button;
        })}
      </SimpleToolbar>
    </>
  );
};

interface ActionButtonProps {
  action: ActionItem.Action;
  isPrimary: boolean;
  actionModal: DynamicModalHook;
  onSuppress: (() => void) | undefined;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = (props) => (
  <Button
    key={props.action.title}
    size={"small"}
    color={props.isPrimary ? "primary" : "secondary"}
    onClick={() => {
      if (props.action.onClick) {
        props.action.onClick();
      } else if (props.action.modal) {
        const modal = props.action.modal;
        props.actionModal.open({
          ...modal,
          render: (close) =>
            modal.render({
              close,
              suppressAndClose: () => {
                if (props.onSuppress) {
                  props.onSuppress();
                }
                close();
              }
            })
        });
      } else if (props.action.helpArticle) {
        const helpArticle = props.action.helpArticle;
        props.actionModal.open({
          title: helpArticle.title,
          render: () => helpArticle.content
        });
      }
    }}
  >
    {props.action.title}
  </Button>
);

interface SuppressedStateProps {
  actionItem: ActionItem;
  onReActivate: (() => void) | undefined;
}

const SuppressedState: React.FunctionComponent<SuppressedStateProps> = (props) => {
  const suppressVerb = suppressActionTitle(props.actionItem.type);

  const reActivateButton = (
    <LinkButton onClick={() => props.onReActivate && props.onReActivate()}>
      {props.actionItem.suppressed?.reActivateOptionTitle || "Undo"}
    </LinkButton>
  );

  return props.actionItem.suppressed?.message
    ? (
      <>
        <Message spacing={"small"}>{props.actionItem.suppressed?.message}</Message>
        <Suppressed>{!props.actionItem.masterActionItemId && reActivateButton}</Suppressed>
      </>
    )
    : (
      <>
        <Message spacing={"small"}>{props.actionItem.message}</Message>
        <Suppressed>
          You chose to {suppressVerb} this {ActionItem.friendlyType(props.actionItem.type)}.{" "}
          {!props.actionItem.masterActionItemId && reActivateButton}
        </Suppressed>
      </>
    );
};

export const ActionItemView = styled(StyledActionItemView)``;

const NotificationLayout = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 0.8rem;
`;

interface NotificationViewProps extends StyledComponentsProps {
  actionItem: ActionItem;
}

export const NotificationView: React.FunctionComponent<NotificationViewProps> = (props) => (
  <NotificationLayout>{props.actionItem.message}</NotificationLayout>
);
