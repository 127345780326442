import * as React from "react";
import { UserDashboardPageView } from "../views/screens/userDashboardPageView/userDashboardPageView";
import { useSessionOrFail } from "../utils/useAppState";
import { useUserMigrations } from "../components/useUserMigrations";
import { useNavigate } from "react-router";
import { useRoutes } from "../app/routes/useRoutes";

export const UserDashboardPage: React.FunctionComponent = () => {
  const session = useSessionOrFail();
  const navigate = useNavigate();
  const routes = useRoutes();

  const [status, refresh] = useUserMigrations(session.user.id, true);

  return (
    <UserDashboardPageView
      status={status}
      onNewMigration={(type) =>
        navigate(type ? routes.batchMigrationSetup.homePath : routes.migrationSetup.singleMigrationHomePath)
      }
      onRefresh={refresh}
    />
  );
};
