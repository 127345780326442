export function downloadFrom(url: string): Promise<string> {
  return fetch(url)
    .then((response) => {
      if (response.status === 200) {
        return response.text();
      } else {
        return response.text().then((error) => Promise.reject(error));
      }
    });
}
