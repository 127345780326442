import * as React from "react";
import { Connection } from "../../types/models/connection";
import { useSession } from "../../utils/useAppState";
import { ConnectionsPanel } from "./connectionsPanel";

interface Props {
  onSelect: (connection: Connection) => void;
}

export const MyConnectionSelectorPanel: React.FunctionComponent<Props> = (props) => {
  const session = useSession();
  if (session) {
    return (
      <ConnectionsPanel
        userId={session.user.id}
        showStatusRow={false}
        actionTitle={"Connect"}
        onAction={props.onSelect}
      />
    );
  } else {
    return null;
  }
};
