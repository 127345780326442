import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, Hub, HubProps } from "../hub";
import { Association } from "../relationships/association";
import { State } from "../state";
import { List } from "immutable";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { RelationshipStyling } from "../relationship";

export class AssociationsHub extends Hub<AssociationsHub.Props> {
  public associations(): List<Association> {
    return this.relationships;
  }

  public calcState(context: BlueprintContext): State<undefined> {
    return State.resolved(undefined);
  }

  public proposedRelationshipTitle(): string {
    return this.props.proposedRelationshipTitle || "related to";
  }

  public proposedRelationshipStyling(): RelationshipStyling {
    return {
      dashes: [1, 4],
      color: "#dc8700"
    };
  }
}

export namespace AssociationsHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.AssociationsHubFragment,
    settings?: ComponentHubSettings
  ): AssociationsHub {
    return new AssociationsHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
