import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Theme } from "../../types/models/theme";

export function useGetThemeQuery(id: number): ManagedQueryHook<GraphQL.GetThemeQueryVariables, Theme> {
  return useManagedQuery({
    query: GraphQL.useGetThemeQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetThemeQuery) => nullToUndefined(data.theme),
    complete: (theme) => Theme.parse(theme),
    fetchPolicy: "cache-and-network"
  });
}
