import * as React from "react";
import { ConnectionPanelDefs } from "../../../../blocks/connectionPanel/connectionPanelDefs";
import { ConnectionPanel } from "../../../../blocks/connectionPanel/connectionPanel";
import { Block } from "../../../../containers/block";
import { styled } from "../../../../../app/theme";
import { BackButton } from "../../../../widgets/backButton";
import { Button } from "../../../../widgets/button";

const ButtonsLayout = styled.div`
  display: flex;
  
  * ~ * {
    margin-left: 1rem;
  }
`;

interface Props {
  sourceConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  destinationConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;

  submitDisabled: boolean;
  adding: boolean;

  onSubmit: () => void;
  onCancel: () => void;
  onDelete: (() => void) | undefined;
}

export const EditBatchMigrationItemView: React.FunctionComponent<Props> = (props) => {
  const viewSettings: ConnectionPanelDefs.ViewSettings = {
    showConnectionStatusRow: ConnectionPanelDefs.ShowConnectionStatusRow.Always,
    showSuccessRow: ConnectionPanelDefs.ShowSuccessRow.Always,
    showAreasWithNoIssues: true,
    showProgress: true,
    processDescription: undefined,
    showSecurityStatement: false
  };

  return (
    <>
      <Block>
        <ButtonsLayout>
          <Button onClick={props.onCancel}>&lsaquo; Back</Button>
        </ButtonsLayout>
      </Block>
      <ConnectionPanel
        viewSettings={viewSettings}
        {...props.sourceConnectionState}
        onCloudServiceSelect={undefined}
      />
      <ConnectionPanel
        viewSettings={viewSettings}
        {...props.destinationConnectionState}
        onCloudServiceSelect={undefined}
      />
      <Block>
        <ButtonsLayout>
          <Button onClick={props.onCancel}>&lsaquo; Back</Button>
          {/*<Button size="wide" onClick={props.onSubmit} disabled={props.submitDisabled}>*/}
          {/*  {props.adding ? "Add" : "Save"}*/}
          {/*</Button>*/}
          {/*{props.onDelete && (*/}
          {/*  <Button size="wide" color="red" onClick={props.onDelete}>Delete</Button>*/}
          {/*)}*/}
        </ButtonsLayout>
      </Block>
    </>
  );
};
