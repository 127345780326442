import * as React from "react";
import { Facts } from "../../../types/facts/facts";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Panel } from "../../containers/panel";
import { friendlyCount } from "../../../utils/formatting";
import { PanelRow } from "../../containers/rows/panelRow";
import { List, Set } from "immutable";
import { Button } from "../../widgets/button";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { FactManagementControllerComponentType, FactsGrid } from "./factsGrid";
import { SimpleToolbar } from "../../widgets/simpleToolbar";

interface Props {
  facts: Facts;
  reScanStatus: OperationStatus<List<string>>;
  factManagementController: FactManagementControllerComponentType;

  onReScan: (factIds: Set<string>) => void;
}

export const FactsManagementToolView: React.FunctionComponent<Props> = (props) => {
  const [checkedFactIds, setCheckedFactIds] = React.useState<Set<string>>(Set());
  return (
    <>
      <Panel>
        <OperationStatusIndicator
          status={props.reScanStatus}
          progressMessage={"Re-scanning selected facts..."}
          failureMessage={"Failed to re-scan selected facts"}
          successMessage={(result: List<string>) =>
            result.isEmpty()
              ? "Successfully submitted, but no new scan jobs started"
              : friendlyCount(result.count(), "scan job") + " started: " + result.join(", ")
          }
          indicators={StatusIndicators.PanelRow()}
        />
        <PanelRow>
          <SimpleToolbar>
            {friendlyCount(props.facts.list().size, "fact") + " found"}
            <Button
              size={"small"}
              disabled={checkedFactIds.isEmpty()}
              onClick={() => props.onReScan(checkedFactIds)}
            >
              Re-Scan{" "}
              {
                checkedFactIds.isEmpty()
                  ? "Selected Facts"
                  : friendlyCount(checkedFactIds.count(), "Selected Fact")
              }
            </Button>
          </SimpleToolbar>
        </PanelRow>
        <GridPanelRow>
          <FactsGrid
            facts={props.facts}
            checkedFactIds={checkedFactIds}
            factManagementController={props.factManagementController}
            onSelect={setCheckedFactIds}
          />
        </GridPanelRow>
      </Panel>
    </>
  );
};
