import * as React from "react";
import { Grid } from "../../../widgets/grid";
import { friendlyDuration } from "../../../../utils/formatting";
import { List } from "immutable";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { Migration } from "../../../../types/models/migration";
import { Panel } from "../../../containers/panel";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Indicator, Indicators, IndicatorValue, TimeIndicator } from "../../../widgets/indicator";

interface Props {
  items: List<MigrationStatusPageDefs.RemainingMigrationTime>;
  migration: Migration;
}

export const MigrationTimingDetails: React.FunctionComponent<Props> = (props) => {
  const timing = props.migration.timing;

  const elapsedTime = timing instanceof Migration.RunningTiming ? timing.elapsedTime : undefined;

  function renderValue(value?: MigrationStatusPageDefs.RemainingMigrationTime.Value) {
    if (value) {
      return (
        <>
          <Grid.Cell nowrap={true}>
            {value.estimatedTime.map((t) => friendlyDuration(t, true)).getOrUse("--")}
          </Grid.Cell>
          <Grid.Cell nowrap={true}>
            {value.progress.toFixed(2)}%
          </Grid.Cell>
          <Grid.Cell nowrap={true}>
            {value.progress === 100 ? "--" : friendlyDuration(value.remainingTime, true)}
          </Grid.Cell>
          <Grid.Cell nowrap={true}>
            {
              value.progress === 100
                ? "--"
                : elapsedTime === undefined || value.progress === 0
                ? "?"
                : friendlyDuration(
                  Math.max(0, elapsedTime - Migration.Timing.WarmUpPeriod) / value.progress * 100,
                  true
                )
            }
          </Grid.Cell>
        </>
      );
    } else {
      return <Grid.Cell colSpan={4} status={WidgetStatus.Uncertain}>Not included in this migration</Grid.Cell>;
    }
  }

  function renderSummary() {
    if (timing instanceof Migration.RunningTiming) {
      return (
        <PanelRow>
          <Indicators size={"small"}>
            <TimeIndicator
              title="Original Time Estimate"
              time={timing.originalTimeEstimateOption}
            />
            <TimeIndicator
              title="Optimistic Remaining Time (Server-Side)"
              time={timing.optimisticRemainingTimeOption}
            />
            <Indicator title={"Longest Track Progress (Server-Side)"}>
              <IndicatorValue
                value={
                  timing.longestTrackProgressOption !== undefined
                    ? timing.longestTrackProgressOption.toFixed(2) + "%"
                    : "?"
                }
              />
            </Indicator>

            <TimeIndicator
              title="Elapsed Time"
              time={timing.elapsedTime}
            />
            <TimeIndicator
              title="Optimistic Remaining Time"
              time={timing.optimisticRemainingTime}
            />
            <TimeIndicator
              title="Optimistic Time Estimate"
              time={timing.optimisticTimeEstimate}
            />
            <TimeIndicator
              title="Pessimistic Time Estimate"
              time={timing.pessimisticTimeEstimate}
            />
            <TimeIndicator
              title="New Total Time Estimate"
              time={timing.timeEstimate}
            />
            <TimeIndicator
              title="New Remaining Time Estimate"
              time={timing.remainingTimeEstimate}
            />
            <Indicator title={"Delay"}>
              <IndicatorValue
                value={timing.delayFactor.toFixed(2) + "x"}
                parenthetical={timing.delayLevel}
              />
            </Indicator>
          </Indicators>
        </PanelRow>
      );
    } else if (timing instanceof Migration.CompletedTiming) {
      return (
        <PanelRow>
          <Indicators size={"small"}>
            <TimeIndicator
              title="Original Time Estimate"
              time={timing.originalTimeEstimate}
            />
            <TimeIndicator
              title="Completed In"
              time={timing.completedIn}
            />
            <Indicator title={"Delay"}>
              <IndicatorValue
                value={timing.delayFactor.toFixed(2) + "x"}
                parenthetical={timing.delayLevel}
              />
            </Indicator>
          </Indicators>
        </PanelRow>
      );
    }
  }

  return (
    <Panel>
      <GridPanelRow>
        <Grid>
          <Grid.Header>
            <Grid.Column>Subject</Grid.Column>
            <Grid.Column>Estimated Time</Grid.Column>
            <Grid.Column>Progress</Grid.Column>
            <Grid.Column>Optimistic<br/>Remaining Time</Grid.Column>
            <Grid.Column>Pessimistic<br/>Remaining Time</Grid.Column>
          </Grid.Header>
          <Grid.Body>
            {
              props.items.map((item) => (
                <Grid.Row key={item.title}>
                  <Grid.Cell>{item.title}</Grid.Cell>
                  {renderValue(item.value)}
                </Grid.Row>
              ))
            }
          </Grid.Body>
        </Grid>
      </GridPanelRow>
      {renderSummary()}
    </Panel>
  );
};
