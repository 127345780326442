import * as React from "react";
import * as yup from "yup";
import { OperationStatus } from "../../../types/operationStatus";
import { Form, Formik } from "formik";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Button } from "../../widgets/button";
import { FormLayout } from "../../widgets/formLayout";
import { DropDownField } from "../../widgets/dropDownField";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { DrawerBlock } from "../../containers/drawerBlock";
import { Panel } from "views/containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { FormField } from "views/widgets/formField";
import { OrderedMap, Set } from "immutable";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Grid } from "../../widgets/grid";
import { LinkButton } from "../../widgets/linkButton";
import { styled } from "../../../app/theme";
import { Checkbox } from "../../widgets/checkbox";
import { ChatGPTLookupViewProps } from "../../../controllers/schoolAdmin/chatGPTLookupController";
import { multiline } from "../../../utils/formatting";

export enum ChatGPTLookupPropertyType {
  String = "String",
  ImageURL = "ImageURL"
}

export interface ChatGPTLookupPropertyDef {
  key: string;
  displayName: string;
  type: ChatGPTLookupPropertyType;
  transient?: boolean;
}

interface Props extends ChatGPTLookupViewProps {
  properties: ChatGPTLookupPropertyDef[];

  makePrompt: () => Promise<string>;
  promptStatus: OperationStatus<string>;

  lookup: (model: string, prompt: string, temperature: number) => Promise<string>;
  lookupStatus: OperationStatus<string>;

  onApply: (result: OrderedMap<string, string>) => void;
}

export const ChatGPTLookupTool: React.FunctionComponent<Props> = (props) => {
  React.useEffect(
    () => {
      props.makePrompt();
    },
    []
  );

  return (
    <>
      <OperationStatusIndicator
        progressMessage={"Making a ChatGPT prompt..."}
        failureMessage={"Failed to make a ChatGPT prompt"}
        status={props.promptStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.promptStatus.isSuccess() && (
        <>
          <LookupRequestForm
            defaultPrompt={props.promptStatus.result}
            lookup={props.lookup}
            lookupStatus={props.lookupStatus}
          />
          {props.lookupStatus.isSuccess() && (
            <LookupResultForm
              properties={props.properties}
              result={props.lookupStatus.result}
              onApply={props.onApply}
            />
          )}
        </>
      )}
    </>
  );
};

enum Models {
  GPT4o = "gpt-4o"
}

interface FormData {
  readonly model: string;
  readonly prompt: string;
  readonly temperature: string;
}

namespace FormData {
  export const blank: FormData = {
    model: Models.GPT4o,
    prompt: "",
    temperature: "0.3"
  };

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    model: yup.string(),
    prompt: yup.string(),
    temperature: yup.string().test(
      "maxDigitsAfterDecimal",
      "Temperature must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
  });
}

interface ChatGPTLookupFormProps {
  defaultPrompt: string;

  lookup: (model: string, prompt: string, temperature: number) => Promise<string>;
  lookupStatus: OperationStatus<string>;
}

const LookupRequestForm: React.FunctionComponent<ChatGPTLookupFormProps> = (props) => {
  const firstInputRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []
  );

  const submitting = props.lookupStatus.isWorking();

  return (
    <>
      <Formik<FormData>
        initialValues={{ ...FormData.blank, prompt: props.defaultPrompt }}
        validationSchema={FormData.validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={(data, actions) => {
          actions.setSubmitting(false);
          props.lookup(data.model, data.prompt, Number.parseFloat(data.temperature));
        }}
        render={(formProps) => (
          <DrawerBlock>
            <Panel>
              <PanelRow>
                <Form>
                  <FormLayout noBottomMargin={true}>
                    <TextAreaField<FormData>
                      label={"Prompt"}
                      name={"prompt"}
                      required={true}
                      disabled={submitting}
                      rows={15}
                      textBoxRef={firstInputRef}
                      limitWidth={false}
                    />
                    <DropDownField<FormData>
                      label={"Model"}
                      name={"model"}
                      disabled={submitting}
                    >
                      <option value={Models.GPT4o}>{Models.GPT4o}</option>
                    </DropDownField>
                    <TextField<FormData>
                      label={"Temperature"}
                      name={"temperature"}
                      required={true}
                      disabled={submitting}
                      maxLength={10}
                      autoComplete={false}
                      dirtyRegExp={/^[\.\d]*$/}
                    />
                    <FormField>
                      {/* Regular submit button does not work when this form is created using a React portal */}
                      {/* from within another form %-| */}
                      <Button
                        size={"small"}
                        disabled={submitting}
                        onClick={() => formProps.submitForm()}
                      >
                        Look Up
                      </Button>
                    </FormField>
                  </FormLayout>
                </Form>
              </PanelRow>
              <OperationStatusIndicator
                progressMessage={"Requesting ChatGPT..."}
                failureMessage={"Failed to request ChatGPT"}
                status={props.lookupStatus}
                indicators={StatusIndicators.PanelRow()}
              />
            </Panel>
          </DrawerBlock>
        )}
      />
    </>
  );
};

interface LookupResultFormProps {
  properties: ChatGPTLookupPropertyDef[];
  result: string;
  onApply: (result: OrderedMap<string, string>) => void;
}

const LookupResultForm: React.FunctionComponent<LookupResultFormProps> = (props) => {
  const [showRawJson, setShowRawJson] = React.useState(false);

  function parseJsonString(jsonString: string): OrderedMap<string, string> {
    try {
      return OrderedMap<string, any>(JSON.parse(jsonString)).filter((value) => !!value).map((value) => "" + value);
    } catch {
      return OrderedMap<string, string>();
    }
  }

  const result = parseJsonString(props.result);

  const [selected, setSelected] = React.useState(() =>
    Set<string>(props.properties.filter(({ transient }) => !transient).map(({ key }) => key))
  );

  return (
    <DrawerBlock>
      <Panel>
        <GridPanelRow>
          <Grid>
            <Grid.Body>
              {props.properties.map(({ key, displayName, type, transient }) => {
                const value = result.get(key);
                return (
                  <Grid.Row key={key}>
                    <Grid.Cell nowrap={true} style={{ width: "1%" }}>
                      {!transient && (
                        <Checkbox
                          checked={!!value && selected.contains(key)}
                          disabled={!value}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelected((currentSelected) => currentSelected.add(key));
                            } else {
                              setSelected((currentSelected) => currentSelected.remove(key));
                            }
                          }}
                        />
                      )}
                    </Grid.Cell>
                    <Grid.Cell nowrap={true} style={{ width: "1%" }}>{displayName}</Grid.Cell>
                    <Grid.Cell>{value ? <Value type={type} value={value}/> : "--"}</Grid.Cell>
                  </Grid.Row>
                );
              })}
            </Grid.Body>
          </Grid>
        </GridPanelRow>
        <PanelRow>
          {
            showRawJson
              ? (
                <>
                  <HideRawJsonLinkContainer>
                    <LinkButton onClick={() => setShowRawJson(false)}>Hide raw JSON</LinkButton>
                  </HideRawJsonLinkContainer>
                  <pre>
                    {props.result}
                  </pre>
                </>
              )
              : <LinkButton onClick={() => setShowRawJson(true)}>Show raw JSON</LinkButton>
          }
        </PanelRow>
        <PanelRow>
          <Button
            size={"small"}
            color={"secondary"}
            onClick={() => props.onApply(result.filter((value, key) => selected.contains(key)))}
          >
            Copy Selected Properties To The Form
          </Button>
        </PanelRow>
      </Panel>
    </DrawerBlock>
  );
};

const HideRawJsonLinkContainer = styled.div`
  margin-bottom: .5rem;
`;

interface ValueProps {
  type: ChatGPTLookupPropertyType;
  value: string;
}

const Value: React.FunctionComponent<ValueProps> = (props) => {
  switch (props.type) {
    case ChatGPTLookupPropertyType.String:
      return <>{multiline(props.value)}</>;

    case ChatGPTLookupPropertyType.ImageURL:
      return (
        <>
          {props.value}
          <StyledImage src={props.value}/>
        </>
      );
  }
};

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 15rem;
  margin-top: .5rem;
`;
