import * as React from "react";
import { List } from "immutable";
import { Paragraph } from "../../../../widgets/paragraph";
import { BulletPoints } from "../../../../widgets/bulletPoints";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { ErrorInfo } from "./errorInfo";
import { Connection } from "../../../../../types/models/connection";
import { useCloudServices } from "../../../../../app/configuration";
import { CloudService } from "../../../../../types/models/cloudService";
import { SemiBold } from "../../../../widgets/semiBold";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../../../app/routes/useRoutes";

interface Props {
  isEmpty: boolean;
  source: boolean;
  connectionMethod: "admin" | "end-user" | "self";
  cloudService: CloudService;
  connections: List<Connection>;
}

export const WrongCloudServiceError: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const routes = useRoutes().batchMigrationSetup;
  const context = props.source ? "source" : "destination";

  const connectionStep = (
    <Link to={props.source ? routes.sourcePath : routes.destinationPath}>
      Migrate {props.source ? "From" : "To"}
    </Link>
  );

  const otherProviders = props.connections
    .map((connection) => cloudServices.get(connection.cloudServiceId)?.name || connection.cloudServiceId)
    .toSet()
    .toList()
    .sort();
  const wrongProvider = otherProviders.size === 1 ? otherProviders.first(undefined) : undefined;

  return (
    <ErrorInfo title={"Incompatible " + context + " accounts found."}>
      <Paragraph>
        The <SemiBold>{props.cloudService.name}</SemiBold> accounts listed below could not be found,
        but instead there are{" "}
        {
          wrongProvider
            ? <><SemiBold>{wrongProvider}</SemiBold> accounts</>
            : "accounts of other types"
        }
        {" "}with matching email addresses:
      </Paragraph>
      <BulletPoints>
        {
          props.connections
            .sortBy((connection) => connection.descriptionOrId())
            .map((connection) =>
              <li key={connection.id}>
                {connection.descriptionOrId() + " "}
                {!wrongProvider && (
                  <>
                    (
                    <SemiBold>
                      {cloudServices.get(connection.cloudServiceId)?.name || connection.cloudServiceId}
                    </SemiBold>
                    {" "}account)
                  </>
                )}
              </li>
            )
        }
      </BulletPoints>
      <Paragraph>
        Here's what could be wrong:
      </Paragraph>
      {
        props.isEmpty
          ? (
            <BulletPoints>
              <li>
                <Paragraph>
                  You selected <SemiBold>{props.cloudService.name}</SemiBold> as the {context} account type for this
                  migration, but perhaps you meant to select{" "}
                  {
                    wrongProvider
                      ? (
                        <>
                          <SemiBold>{wrongProvider}</SemiBold>. If so, please go back to
                          the <Link to={routes.providersPath}>Account Types</Link> step and change
                          the {context} account type to <SemiBold>{wrongProvider}</SemiBold>.
                        </>
                      )
                      : (
                        <>
                          {
                            otherProviders.map((provider, index) => (
                              <>
                                <SemiBold>{provider}</SemiBold>
                                {otherProviders.size > 2 && index < otherProviders.size - 1 ? ", " : ""}
                                {index === otherProviders.size - 2 ? " or " : ""}
                              </>
                            ))
                          }.
                          If so, please go back to the
                          {" "}<Link to={routes.providersPath}>Account Types</Link>
                          {" "}step and select a different {context} account type.
                        </>
                      )
                  }
                </Paragraph>
                {!wrongProvider && (
                  <Paragraph>
                    Note that VaultMe does not allow migrating {props.source ? "from" : "to"} accounts of different
                    types in a single migration. If you wish to do that, please run separate migrations for each
                    combination of the source and the destination account types.
                  </Paragraph>
                )}
              </li>
              <li>
                The <SemiBold>{props.cloudService.name}</SemiBold> accounts with the email addresses listed
                above are not connected to VaultMe yet. Please follow the instructions given on the {connectionStep}
                {" "}step to connect them.
              </li>
            </BulletPoints>
          )
          : (
            <BulletPoints>
              <li>
                The <SemiBold>{props.cloudService.name}</SemiBold> accounts with the email addresses listed above are
                not connected to VaultMe yet. Please follow the instructions given on the {connectionStep} step to
                connect them.
              </li>
              <li>
                You are trying to migrate {props.source ? "from" : "to"} accounts of different types in a single
                migration, but this feature is currently not supported. If you wish to do that, please run separate
                migrations for each combination of the source and the destination account types.
              </li>
            </BulletPoints>
          )
      }
      <Paragraph>
        If you need assistance, contact us at <EmailSupportLink/>.
      </Paragraph>
    </ErrorInfo>
  );
};
