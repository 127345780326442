import * as React from "react";
import { styled } from "../../app/theme";

export const ContentPlaceholder = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.lightGray};
  border-radius: 5px;
  padding: 3rem 1rem;
  margin-top: 2rem;
  color: #cccccc;
  font-size: 1.4rem;
`;
