import { nullToUndefined } from "../../utils/misc";
import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";

export interface CouponCode {
  readonly id: string;
  readonly notes: string | undefined;
  readonly enabled: boolean;

  readonly discount: number;
  readonly isPercentage: boolean;
  readonly remainingUses: number | undefined;
  readonly lastUsedFor: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace CouponCode {
  export function parse(data: GraphQL.CouponCodeFragment): CouponCode {
    return {
      id: data.id,
      notes: nullToUndefined(data.notes),
      enabled: data.enabled,

      discount: data.discount,
      isPercentage: data.isPercentage,
      remainingUses: nullToUndefined(data.remainingUses),
      lastUsedFor: nullToUndefined(data.lastUsedFor),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(couponCodeId: string): string {
    return cachedObjectId("CouponCode", couponCodeId);
  }
}
