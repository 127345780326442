import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProject } from "../../types/models/offboardingProject";

type DisableOffboardingProjectEmailDeliveryMutationHook = [
  () => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export function useDisableOffboardingProjectEmailDeliveryMutation(
  id: string
): DisableOffboardingProjectEmailDeliveryMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDisableOffboardingProjectEmailDeliveryMutation,
    extract: (data: GraphQL.DisableOffboardingProjectEmailDeliveryMutation) =>
      nullToUndefined(data.disableOffboardingProjectEmailDelivery),
    complete: OffboardingProject.parse
  });

  function fireWith(): Promise<OffboardingProject> {
    return fire({
      variables: { id },
      retry: () => fireWith(),
    });
  }

  return [fireWith, status];
}
