import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type JobStatusTransitionSchedulingHook = [
  (id: string, desiredStatus: string, force: boolean) => Promise<GraphQL.JobStatusTransitionSchedulingResult>,
  OperationStatus<GraphQL.JobStatusTransitionSchedulingResult>
];

export function useJobStatusTransitionScheduling(): JobStatusTransitionSchedulingHook {
  const [scheduleJobStatusTransition, { status }] = useManagedMutation({
    mutation: GraphQL.useScheduleJobStatusTransitionMutation,
    extract: (data: GraphQL.ScheduleJobStatusTransitionMutation) => nullToUndefined(data.scheduleJobStatusTransition),
    complete: identity,
    cancel: true
  });

  function schedule(
    jobId: string,
    desiredStatus: string,
    force: boolean
  ): Promise<GraphQL.JobStatusTransitionSchedulingResult> {
    return scheduleJobStatusTransition({
      variables: { jobId, desiredStatus, force },
      retry: () => schedule(jobId, desiredStatus, force),
    });
  }

  return [schedule, status];
}
