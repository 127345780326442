import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { List } from "immutable";
import { Block } from "../../containers/block";
import { Button } from "../../widgets/button";
import { titleize } from "../../../utils/titleize";
import { ColumnConfig } from "./columnConfig";
import { SearchPanel } from "./searchPanel";
import { HeaderBlock } from "../../widgets/headerBlock";
import { Link } from "react-router-dom";

interface Props<Id, Item> {
  breadcrumbs?: React.ReactNode[];
  title: string;
  subject: string;
  readOnly: boolean;
  layout?: "default" | "compact";
  gridColumns: ColumnConfig<Item>[];
  additionalTools: React.ReactElement[] | undefined;
  topChildren: React.ReactElement | undefined;
  bottomChildren: React.ReactElement | undefined;

  getItemId: (item: Item) => Id;
  compare: (a: Item, b: Item) => number;

  searchTerm: string;
  searchStatus: OperationStatus<List<Item>>;
  transientItems: List<Item>;
  newPath: string;

  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
  onReset: (() => void) | undefined;
  onListAll: (() => void) | undefined;
  onSelect: (id: Id, item: Item) => void;
}

export function SearchPageView<Id, Item>(props: Props<Id, Item>): React.ReactElement {
  return (
    <>
      <HeaderBlock title={props.title} breadcrumbs={props.breadcrumbs}/>
      {props.topChildren}
      <Block>
        <SearchPanel
          subject={props.subject}
          layout={props.layout}
          tools={
            (
              props.readOnly
                ? []
                : [
                  <Link key={"new"} to={props.newPath}>
                    <Button size={"small"}>{titleize("New " + props.subject)}</Button>
                  </Link>
                ]
            ).concat(
              props.onListAll
                ? [
                  <Button key={"listAll"} size={"small"} color={"secondary"} onClick={props.onListAll}>
                    List All
                  </Button>
                ]
                : []
            ).concat(props.additionalTools || [])
          }
          gridColumns={props.gridColumns}

          getItemId={props.getItemId}
          comparator={props.compare}

          searchTerm={props.searchTerm}
          searchStatus={props.searchStatus}
          transientItems={props.transientItems}

          onSearch={props.onSearch}
          onRefresh={props.onRefresh}
          onReset={props.onReset}
          onSelect={props.onSelect}
        />
      </Block>
      {props.bottomChildren}
    </>
  );
}
