import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { Upload } from "./upload";

export interface ConfigurationCloudService {
  readonly id: number;

  readonly configurationId: number;
  readonly isSource: boolean;
  readonly cloudServiceId: string;

  readonly index: number;
  readonly reference: string | undefined;
  readonly description: string | undefined;
  readonly logo: Upload | undefined;
  readonly excludedApps: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace ConfigurationCloudService {
  export function parse(data: GraphQL.ConfigurationCloudServiceFragment): ConfigurationCloudService {
    return {
      id: data.id,

      configurationId: data.configurationId,
      isSource: data.isSource,
      cloudServiceId: data.cloudServiceId,

      index: data.index,
      reference: nullToUndefined(data.reference),
      description: nullToUndefined(data.description),
      logo: mapOptional(data.logo, (upload) => Upload.parse(upload)),
      excludedApps: nullToUndefined(data.excludedApps),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(configurationCloudServiceId: number): string {
    return cachedObjectId("ConfigurationCloudService", configurationCloudServiceId.toString());
  }
}
