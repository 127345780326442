import { GraphQL } from "../../services/graphql/generated";
import { Map, OrderedMap } from "immutable";

export class AvailableJobStatuses implements AvailableJobStatuses.Props {
  constructor(protected readonly props: AvailableJobStatuses.Props) {
  }

  public get allSupported(): OrderedMap<string, string> { return this.props.allSupported; }
  public get proposed(): OrderedMap<string, string> { return this.props.proposed; }
}

export namespace AvailableJobStatuses {
  export interface Props {
    readonly allSupported: OrderedMap<string, string>;
    readonly proposed: OrderedMap<string, string>;
  }

  export function fromGraphQL(availableJobStatuses: GraphQL.AvailableJobStatuses): AvailableJobStatuses {
    return new AvailableJobStatuses({
      allSupported: OrderedMap(availableJobStatuses.allSupported.map((e) => [e.status, e.description])),
      proposed: OrderedMap(availableJobStatuses.proposed.map((e) => [e.status, e.description]))
    });
  }
}
