import * as React from "react";
import { styled, css } from "../../app/theme";
import { HasClickHandler, withSimpleClickHandler } from "../utils/simpleClickHandler";
import { Omit } from "react-redux";
import { useBrowser } from "../../utils/useBrowser";

export type ButtonColor = "blue" | "white" | "red" | "gray" | "black" | "primary" | "secondary" | "admin";
export type ButtonSize = "dry" | "small" | "compact" | "narrow" | "normal" | "wide";
export type ButtonType = "submit" | "reset" | "button";

interface ButtonProps extends HasClickHandler<HTMLButtonElement> {
  color?: ButtonColor;
  size?: ButtonSize;
  children?: any;
  type?: ButtonType;
  disabled?: boolean;
}

const colorMixin = (color: ButtonColor) => {
  switch (color) {
    case "blue":
    case "primary":
      return css`
        background: ${(props) => props.theme.colors.primary};
        color: #ffffff;
        border: 1px solid ${(props) => props.theme.colors.primary};
      `;

    case "white":
    case "secondary":
      return css`
        background: #ffffff;
        color: ${(props) => props.theme.colors.primary};
        border: 1px solid ${(props) => props.theme.colors.primary};
      `;

    case "red":
    case "admin":
      return css`
        background: #ffffff;
        color: ${(props) => props.theme.colors.red};
        border: 1px solid ${(props) => props.theme.colors.red};
      `;

    case "gray":
      return css`
        background: #ffffff;
        color: ${(props) => props.theme.colors.gray};
        border: 1px solid ${(props) => props.theme.colors.gray};
      `;

    case "black":
      return css`
        background: #ffffff;
        border: 1px solid ${(props) => props.theme.colors.black};
      `;
  }
};

const sizeMixin = (size?: ButtonSize) => {
  switch (size) {
    case "dry":
      return css`
        padding: 0;
      `;

    case "small":
      return css`
        text-transform: uppercase;
        padding: 0.25rem 1rem;
        font-size: 0.8125rem;

        ${(props) => props.theme.responsive.respondToXSmall()} {
          padding: 0.2rem 0.5rem;
        }
      `;

    case "compact":
      return css`
        text-transform: uppercase;
        padding: 0.25rem 0.4rem;
        font-size: 0.8125rem;

        ${(props) => props.theme.responsive.respondToXSmall()} {
          padding: 0.2rem 0.3rem;
        }
      `;

    case "narrow":
      return css`
        padding: 0.4rem 1.5rem;

        ${(props) => props.theme.responsive.respondToXXSmall()} {
          padding: 0.3rem 1.5rem;
          font-size: 1.2rem;
        }
      `;

    case "normal":
      return css`
        padding: 0.4rem 3rem;

        ${(props) => props.theme.responsive.respondToXXSmall()} {
          padding: 0.3rem 1rem;
          font-size: 1.2rem;
        }
      `;

    case "wide":
      return css`
        padding: 0.4rem 5rem;

        ${(props) => props.theme.responsive.respondToXXSmall()} {
          padding: 0.3rem 2.5rem;
          font-size: 1.2rem;
        }
      `;

    default: // normal or undefined
      return css`
        padding: 0.4rem 3rem;

        ${(props) => props.theme.responsive.respondToXXSmall()} {
          padding: 0.3rem 1rem;
          font-size: 1.2rem;
        }
      `;
  }
};

export const Button = styled.button.attrs((props: ButtonProps) => ({
  type: props.disabled ? "button" : (props.type || "button")
}))<ButtonProps>`
  border-radius: 0.15rem;
  text-decoration: none;
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;

  ${(props) => colorMixin(props.color || "blue")}
  ${(props) => sizeMixin(props.size)}

  &:active {
    box-shadow: none;
    filter: brightness(95%);
  }

  &[disabled] {
    background: #ffffff;
    color: #dadcde;
    border: 1px solid #dadcde;
  }

  @media(hover: hover) {
    &:hover:not([disabled]) {
      transition: 0.1s;
      box-shadow: 0px 2px 1px rgba(124,124,124,0.6);
      transform: scale(1.01);
      filter: brightness(110%);
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

export const SimpleButton = withSimpleClickHandler(Button);

export const SmallButton: React.FunctionComponent<Omit<ButtonProps, "size">> = (props) => (
  <Button {...props} size="small"/>
);

export const MultiLineButtonTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Chip = styled(Button)`
  border-radius: 1.2rem;
  ${(props) => colorMixin(props.color || "blue")}
`;
