import * as React from "react";
import { commafy } from "../../../../utils/formatting";
import { LinkButton } from "../../../widgets/linkButton";
import { OperationStatus } from "../../../../types/operationStatus";
import { styled } from "../../../../app/theme";

interface Props {
  status: OperationStatus<number>;
  onRefresh?: () => void;
}

export const AccessListEntryCount: React.FunctionComponent<Props> = (props) => {
  const onRefresh = props.onRefresh;

  return (
    <span>
      {props.status.mapLastResult((entryCount) => <Value>{commafy(entryCount)}</Value>)}
      {onRefresh && (
        <>
          &nbsp;&nbsp;
          <LinkButton onClick={() => onRefresh()} disabled={!props.status.isSuccess()}>Refresh</LinkButton>
        </>
      )}
  </span>
  );
};

const Value = styled.span`
  font-weight: 400;
`;
