import { GraphQL } from "../../services/graphql/generated";
import { InternalJobStatus } from "./internalJobStatus";
import { mapOptional, nullToUndefined } from "../../utils/misc";

export class JobHistoryRecord implements JobHistoryRecord.Props {
  constructor(protected readonly props: JobHistoryRecord.Props) {
  }

  public get id(): string { return this.props.id; }

  public get type(): string { return this.props.type; }
  public get internalStatus(): InternalJobStatus { return this.props.internalStatus; }

  public get currentStatus(): string | undefined { return this.props.currentStatus; }
  public get desiredStatus(): string { return this.props.desiredStatus; }
  public get nextStatus(): string | undefined { return this.props.nextStatus; }

  public get transition(): number { return this.props.transition; }

  public get propsVersion(): string { return this.props.propsVersion; }
  public get propsJson(): string { return this.props.propsJson; }

  public get stateVersion(): string { return this.props.stateVersion; }
  public get stateJson(): string { return this.props.stateJson; }

  public get createdAt(): Date { return this.props.createdAt; }

  public get updatedAt(): Date { return this.props.updatedAt; }
  public get updatedBy(): string | undefined { return this.props.updatedBy; }
  public get updateSummary(): string | undefined { return this.props.updateSummary; }
  public get minorUpdate(): boolean { return this.props.minorUpdate; }

  public get completedAt(): Date | undefined { return this.props.completedAt; }
  public get archivedAt(): Date | undefined { return this.props.archivedAt; }

  // There's a chance of collision here if two identical events happen at exactly the same moment of time
  public get key(): string { return this.updatedAt.toISOString() + "/" + this.updateSummary; }

  public get timestamp(): Date { return this.updatedAt; }

  public isArchived(): boolean { return this.internalStatus === InternalJobStatus.Archived; }
}

export namespace JobHistoryRecord {
  export interface Props {
    readonly id: string;

    readonly type: string;
    readonly internalStatus: InternalJobStatus;

    readonly currentStatus: string | undefined;
    readonly desiredStatus: string;
    readonly nextStatus: string | undefined;

    readonly transition: number;

    readonly propsVersion: string;
    readonly propsJson: string;

    readonly stateVersion: string;
    readonly stateJson: string;

    readonly createdAt: Date;

    readonly updatedAt: Date;
    readonly updatedBy: string | undefined;
    readonly updateSummary: string | undefined;
    readonly minorUpdate: boolean;

    readonly completedAt: Date | undefined;
    readonly archivedAt: Date | undefined;
  }

  export function fromGraphQL(jobHistoryRecord: GraphQL.JobHistoryRecord): JobHistoryRecord {
    return new JobHistoryRecord({
      id: jobHistoryRecord.id,

      type: jobHistoryRecord.type,
      internalStatus: jobHistoryRecord.internalStatus,

      currentStatus: nullToUndefined(jobHistoryRecord.currentStatus),
      desiredStatus: jobHistoryRecord.desiredStatus,
      nextStatus: nullToUndefined(jobHistoryRecord.nextStatus),

      transition: jobHistoryRecord.transition,

      propsVersion: jobHistoryRecord.propsVersion,
      propsJson: jobHistoryRecord.propsJson,

      stateVersion: jobHistoryRecord.stateVersion,
      stateJson: jobHistoryRecord.stateJson,

      createdAt: new Date(jobHistoryRecord.createdAt),

      updatedAt: new Date(jobHistoryRecord.updatedAt),
      updatedBy: nullToUndefined(jobHistoryRecord.updatedBy),
      updateSummary: nullToUndefined(jobHistoryRecord.updateSummary),
      minorUpdate: jobHistoryRecord.minorUpdate,

      completedAt: mapOptional(jobHistoryRecord.completedAt, (value) => new Date(value)),
      archivedAt: mapOptional(jobHistoryRecord.archivedAt, (value) => new Date(value))
    });
  }
}
