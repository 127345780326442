import * as React from "react";
import { LookupControllerType } from "../lookupModal";
import { Configuration } from "../../../types/models/configuration";
import { ItemDetailsControllerType, LookupField } from "../../widgets/lookupField";
import { useBrowser } from "../../../utils/useBrowser";

export type ConfigurationLookupControllerType = LookupControllerType<number, Configuration>;

export type ConfigurationDetailsControllerType = ItemDetailsControllerType<number, Configuration>;

export type ConfigurationViewPathFactory = (id: number) => string;

interface Props<V> {
  name: keyof V;

  lookupController: ConfigurationLookupControllerType;
  detailsController: ConfigurationDetailsControllerType;
  viewPathFactory: ConfigurationViewPathFactory;

  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export function ConfigurationLookupField<V>(props: Props<V>): React.ReactElement {
  const browser = useBrowser();

  return (
    <LookupField<V, number, Configuration>
      label={props.label || "Configuration"}
      name={props.name}
      subject={"configuration"}
      gridColumns={[
        {
          title: "Name",
          render: (configuration) => configuration.name
        }
      ]}

      lookupController={props.lookupController}
      itemDetailsController={props.detailsController}
      renderItem={(configuration) => <>{configuration.name}</>}

      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled}

      onView={(configurationId) => browser.openNewTab(props.viewPathFactory(configurationId))}
    />
  );
}
