import * as React from "react";
import { styled } from "../../../../app/theme";
import { UserFacingError } from "../../../../types/userFacingError";
import { AppBootstrapConfig } from "../../../../types/models/appBootstrapConfig";

interface DropinContainerProps {
  visible: boolean;
}

const DropinContainer = styled.div<DropinContainerProps>`
  display: ${(props) => props.visible ? "block" : "none"};
  margin-top: ${(props) => props.visible ? 2 : 0}rem;
  
  .braintree-option {
    border: none;
    border-radius: 0;
    background: ${(props) => props.theme.colors.white};
    
    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
    }
    
    &:not(.braintree-disabled):hover {
      border-left: 3px solid ${(props) => props.theme.colors.primary};
      background: ${(hoverProps) => hoverProps.theme.colors.faintPrimary};
    }
    
    .braintree-disabled {
      background: ${(props) => props.theme.colors.lightGray};
    }
  }
  
  .braintree-form {
    border: none;
    border-radius: 0;
    background: ${(props) => props.theme.colors.white};
  }
  
  .braintree-sheet__header {
    border-bottom-color: ${(props) => props.theme.colors.primary};
  }  
`;

declare var braintree: any;

interface BraintreeFormProps {
  configuration: AppBootstrapConfig;
  clientToken: string;
  amount: number;
  onInit: () => void;
  onInitSuccess: (braintree: any, deviceData: any) => void;
  onInitFailure: (error: UserFacingError) => void;
  onTeardown: () => void;
}

export class BraintreeForm extends React.Component<BraintreeFormProps> {
  private instance: any;

  public componentDidMount() {
    this.initBraintree();
  }

  public componentWillUnmount() {
    this.teardownBraintree();
  }

  public render() {
    return (
      <DropinContainer
        id="dropin-container"
        visible={this.props.amount > 0}
      />
    );
  }

  protected initBraintree() {
    this.props.onInit();

    if (typeof braintree !== "undefined") {
      braintree.dropin.create({
        authorization: this.props.clientToken,
        container: "#dropin-container",
        threeDSecure: true,
        paypal: {
          flow: "checkout",
          amount: this.props.amount,
          currency: "USD"
        },
        googlePay: this.props.configuration.googleMerchantId && {
          merchantId: this.props.configuration.googleMerchantId,
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: this.props.amount.toFixed(2),
            currencyCode: "USD"
          }
        },
        venmo: this.props.configuration.enableVenmo ? { allowNewBrowserTab: false } : undefined
      }).then((instance: any) => {
        this.instance = instance;

        // [Tim, June 2022] Braintree does cover the usage of data collector with Dropin UI, the documentation
        // assumes using a client instead:
        // https://developer.paypal.com/braintree/docs/guides/premium-fraud-management-tools/client-side/javascript/v3
        // But, the code below seems to work!
        try {
          return braintree.dataCollector.create({ client: instance._client })
            .then((dataCollectorInstance: any) => {
              this.props.onInitSuccess(instance, dataCollectorInstance.deviceData);
            })
            .catch((error: any) => {
              console.error("Failed to initialize Braintree data collector", error);
              this.props.onInitSuccess(instance, undefined);
            });
        } catch (error) {
          console.warn("Failed to initialize Braintree data collector", error);
          this.props.onInitSuccess(instance, undefined);
        }
      }).catch((error: any) => {
        this.props.onInitFailure(
          UserFacingError.unexpected(error, {
            summary: "Failed to initialize secure payment gateway (Braintree Drop-in UI could not initialize)",
            recommendations: "Please try refreshing this page in your browser"
          })
        );
      });
    } else {
      this.props.onInitFailure(
        UserFacingError.synthetic({
          summary: "Failed to initialize secure payment gateway (Braintree script failed to load)",
          recommendations: "Please try refreshing this page in your browser"
        })
      );
    }
  }

  protected teardownBraintree() {
    if (this.instance) {
      this.instance.teardown();
      this.props.onTeardown();
    }
  }
}
