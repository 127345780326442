import * as React from "react";
import { SchoolSummary } from "../../../types/models/school";
import { useSessionOrFail } from "../../../utils/useAppState";
import { makeSchoolWelcomeMessage } from "../migrationSetup/connectionInterstitialStepView";
import { styled } from "../../../app/theme";

interface Props {
  school: SchoolSummary;
}

export const WelcomeMessagePreview: React.FunctionComponent<Props> = (props) => {
  const session = useSessionOrFail();
  const message = makeSchoolWelcomeMessage(props.school, session.user.personalInfo.firstName);

  return (
    <Layout>
      {
        message
        ? (
            <>
              <Header>{message.title}</Header>
              <div>{message.subTitle}</div>
            </>
          )
          : <>(Invalid format - two paragraphs are required)</>
      }
    </Layout>
  );
};

const Layout = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  padding: 1rem;
  max-width: 50rem;
`;

const Header = styled.div`
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.font.medium};
  margin-bottom: .7rem;
`;
