import * as React from "react";
import { ContestParticipantsReportPageView } from "../views/screens/contestParticipantsReportPageView";
import { useRoutes } from "../app/routes/useRoutes";
import { useBrowser } from "../utils/useBrowser";
import * as moment from "moment";
import { useAppBootstrapConfig } from "../app/configuration";

export const ContestParticipantsReportPage: React.FunctionComponent = () => {
  const browser = useBrowser();
  const routes = useRoutes();
  const appBootstrapConfig = useAppBootstrapConfig();

  return (
    <ContestParticipantsReportPageView
      startDate={
        moment()
          .startOf("day")
          .subtract(appBootstrapConfig.ambassadorsProgram.contestEntryExpirationMonths, "month")
          .toDate()
      }
      maxDays={365}
      onGenerate={(startDate, endDate) =>
        browser.openNewTab(routes.api.contestParticipantsReportUrl(startDate, endDate), true)
      }
    />
  );
};
