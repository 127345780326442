import { Theme } from "../../types/models/theme";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { ThemeFormData } from "../../types/models/themeFormData";

type UpdateThemeMutationHook = [
  (id: number, formData: ThemeFormData) => Promise<Theme>,
  OperationStatus<Theme>
];

export function useUpdateThemeMutation(): UpdateThemeMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateThemeMutation,
    extract: (data: GraphQL.UpdateThemeMutation) => nullToUndefined(data.updateTheme),
    complete: Theme.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(id: number, formData: ThemeFormData): Promise<Theme> {
    return fire({
      variables: { id, formData: ThemeFormData.toGraphQL(formData) },
      retry: () => fireWith(id, formData),
    });
  }

  return [fireWith, status];
}
