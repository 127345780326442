import * as React from "react";
import { useNavigate } from "react-router";
import { FeedbackPageView } from "../views/screens/feedbackPageView";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { identity } from "../utils/misc";
import { useRoutes } from "../app/routes/useRoutes";

export const FeedbackPage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.feedback.params();
  const navigate = useNavigate();

  const [setFeedback] = useManagedMutation({
    mutation: GraphQL.useSetMigrationFeedbackMutation,
    extract: (data: GraphQL.SetMigrationFeedbackMutation) => data.setMigrationFeedback,
    complete: identity
  });

  return (
    <FeedbackPageView
      migrationId={params.migrationId}
      onSubmit={(question, answer) => {
        if (answer !== "") {
          setFeedback({ variables: { migrationId: params.migrationId, question, answer } });
        }
        navigate(routes.migrations.migrationStatusPath(params.migrationId));
      }}
    />
  );
};
