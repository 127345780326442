import * as React from "react";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { GraphQL } from "../../services/graphql/generated";
import { OutlookSignInForm } from "../../views/blocks/auth/outlookSignInForm";
import { OAuthSignInControllerConfig } from "./oAuthSignInController";
import { useServiceAccountSignInFlow } from "./useServiceAccountSignInFlow";
import { useOAuthSignInFlow } from "./useOAuthSignInFlow";
import { UserFacingError } from "../../types/userFacingError";
import { Paragraph } from "../../views/widgets/paragraph";
import { SemiBold } from "../../views/widgets/semiBold";

export interface OutlookSignInControllerConfig {
  exchange: OAuthSignInControllerConfig;
  imap: OAuthSignInControllerConfig;
  emailAddress: string;
  userId: string;
  tenantId: string;
}

export namespace OutlookSignInControllerConfig {
  export function fromGraphQL(
    config: GraphQL.OutlookSignInFormConfigFragment,
    customization: GraphQL.OAuthSignInFormCustomization | undefined | null
  ): OutlookSignInControllerConfig {
    return {
      exchange: OAuthSignInControllerConfig.fromGraphQL(config.exchange, customization),
      imap: OAuthSignInControllerConfig.fromGraphQL(config.imap, customization),
      emailAddress: config.emailAddress,
      userId: config.userId,
      tenantId: config.tenantId,
    };
  }
}

interface Props extends SignInDefs.SignInComponentProps {
  config: OutlookSignInControllerConfig;
}

export const OutlookSignInController: React.FunctionComponent<Props> = (props) => {
  const exchangeOAuthSignInFlow = useOAuthSignInFlow({
    url: props.config.exchange.url,
    callbackUrl: props.config.exchange.callbackUrl,
    vendorName: "Microsoft",
    verb: props.verb,
    onSignIn: props.onSignIn,
    onFailure: (code, summary, recommendations) => {
      switch (code) {
        case "ExchangeNotSupported":
          setExchangeOptionFailed(true);
          return true;

        case "NoOutlookLicenseAssigned":
          return UserFacingError.synthetic({
            summary: (
              <>
                <Paragraph>
                  Your Microsoft 365 account does not seem to have a valid Outlook license. This usually indicates
                  that your mailbox is hosted separately from other Microsoft apps.
                </Paragraph>
                <Paragraph>
                  To resolve the issue, you could reach out to your IT department for assistance or you could try
                  connecting your account using the IMAP protocol. This will involve entering your account login and
                  password into the secure login form, similar to connecting the account to a desktop or
                  mobile email app. If you regularly use such an app to access your mailbox, this might be
                  the easiest option. If you usually access your mailbox from a browser, then reaching out to
                  your IT department might be the only way out.
                </Paragraph>
                <Paragraph>
                  If you decide to try connecting the account using the IMAP protocol, close this popup window,
                  click the <SemiBold>Change</SemiBold> link next to the Microsoft 365 logo, select{" "}
                  <SemiBold>IMAP</SemiBold> from the list of options, and sign in. If you are unsure what host and
                  port settings you should use, refer to the server settings that you have in your desktop or mobile
                  email app.
                </Paragraph>
              </>
            )
          });

        default:
          return false;
      }
    }
  });

  const imapOAuthSignInFlow = useOAuthSignInFlow({
    url: props.config.imap.url,
    callbackUrl: props.config.imap.callbackUrl,
    vendorName: "Microsoft",
    verb: props.verb,
    onSignIn: props.onSignIn
  });

  const serviceAccountSignInFlowState = {
    emailAddress: props.config.emailAddress,
    userId: props.config.userId,
    tenantId: props.config.tenantId,
  };

  const exchangeServiceAccountSignInFlow = useServiceAccountSignInFlow({
    cloudServiceId: props.cloudServiceId,
    roles: props.roles.toArray(),
    state: { target: "Exchange", ...serviceAccountSignInFlowState }
  });

  const imapServiceAccountSignInFlow = useServiceAccountSignInFlow({
    cloudServiceId: props.cloudServiceId,
    roles: props.roles.toArray(),
    state: { target: "Imap", ...serviceAccountSignInFlowState }
  });

  const [exchangeOptionFailed, setExchangeOptionFailed] = React.useState(false);

  return React.createElement(
    props.layoutComponent,
    {
      signInForm: (
        <OutlookSignInForm
          exchange={{
            cloudServiceId: props.cloudServiceId,
            verb: props.verb,
            vendorName: "Microsoft",
            buttonCss: props.config.exchange.buttonCss,
            buttonIcon: props.config.exchange.buttonIcon,
            onSignInButtonClick: exchangeOAuthSignInFlow.fire,

            howToSwitchAccountHelpArticle: props.config.exchange.helpContent?.howToSwitchAccount,
            additionalOptions: [],

            serviceAccountSignInConfig: {
              predefinedEmailAddress: props.config.emailAddress,
              onSignIn: (emailAddress) => {
                exchangeOAuthSignInFlow.resetStatus();
                imapOAuthSignInFlow.resetStatus();
                exchangeServiceAccountSignInFlow.signIn(emailAddress).then(props.onSignIn);
              }
            }
          }}
          imap={{
            cloudServiceId: props.cloudServiceId,
            verb: props.verb,
            vendorName: "Microsoft",
            buttonCss: props.config.imap.buttonCss,
            buttonIcon: props.config.imap.buttonIcon,
            onSignInButtonClick: imapOAuthSignInFlow.fire,

            howToSwitchAccountHelpArticle: props.config.exchange.helpContent?.howToSwitchAccount,
            additionalOptions: [],

            serviceAccountSignInConfig: {
              predefinedEmailAddress: props.config.emailAddress,
              onSignIn: (emailAddress) => {
                exchangeOAuthSignInFlow.resetStatus();
                imapOAuthSignInFlow.resetStatus();
                imapServiceAccountSignInFlow.signIn(emailAddress).then(props.onSignIn);
              }
            }
          }}
          exchangeOptionFailed={exchangeOptionFailed}
        />
      ),
      operationStatusIndicator: {
        status:
          !exchangeOAuthSignInFlow.status.isPending()
            ? exchangeOAuthSignInFlow.status
            : !imapOAuthSignInFlow.status.isPending()
            ? imapOAuthSignInFlow.status
            : !exchangeServiceAccountSignInFlow.status.isPending()
              ? exchangeServiceAccountSignInFlow.status
              : imapServiceAccountSignInFlow.status
      },
    });
};
