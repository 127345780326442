import * as React from "react";
import { prettyPrint } from "../../utils/formatting";
import { OperationStatus } from "../../types/operationStatus";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { styled } from "../../app/theme";

const Json = styled.pre`
  font-size: 0.85rem;
  padding: .5rem;
  background: ${(props) => props.theme.colors.white};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.7rem;
  }
`;

interface Props {
  status: OperationStatus<any>;
}

export const EntityJsonView: React.FunctionComponent<Props> = (props) => (
  <>
    <OperationStatusIndicator
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.status.isSuccess() && <Json>{prettyPrint(props.status.result)}</Json>}
  </>
);
