import { AccountAction } from "../enums/accountAction";
import { List } from "immutable";
import { GraphQL } from "../../services/graphql/generated";

export interface SchoolStats {
  readonly schoolId: string;
  readonly migrationCount: number;
  readonly ambassadorCount: number;
  readonly accountActionStats: List<SchoolStats.AccountActionStats>;
}

export namespace SchoolStats {
  export interface AccountActionStats {
    readonly date: Date;
    readonly action: AccountAction;
    readonly count: number;
  }

  export function parse(data: GraphQL.SchoolStats): SchoolStats {
    return {
      schoolId: data.schoolId,
      migrationCount: data.migrationCount,
      ambassadorCount: data.ambassadorCount,
      accountActionStats: List(data.accountActionStats).map((stats) => ({
        date: new Date(stats.date),
        action: stats.action,
        count: stats.count
      }))
    };
  }
}
