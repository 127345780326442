import * as React from "react";
import { DataGroup, DataItem, IdType } from "vis";
import { JobHistory } from "../../../types/models/jobHistory";
import { List, Map, OrderedMap } from "immutable";
import { Checkbox } from "../../widgets/checkbox";
import { JobHistoryRecord } from "../../../types/models/jobHistoryRecord";
import { preciseTimestamp } from "../../../utils/formatting";
import { TaskIssue } from "../../../types/models/taskIssue";
import { TaskSummaryRecord } from "../../../types/models/taskSummaryRecord";
import { itemProgress, renderTooltip } from "./utils";
import { TimelinePanel } from "../timeline/timelinePanel";
import { DataItemEx } from "../timeline/dataItemEx";
import { useBrowser } from "../../../utils/useBrowser";
import { useRoutes } from "../../../app/routes/useRoutes";

const NoStatus = "(None)";

namespace Groups {
  export const Events = "0/Events";
  export const Transitions = "1/Transitions";

  export const Statuses = "2/Status";

  export function statusGroupId(status: string | undefined): string {
    return Statuses + "/" + (status || NoStatus);
  }

  export const Tasks = "3/Tasks";

  export function taskGroupId(taskId: string): string {
    return Tasks + "/" + taskId;
  }

  export const TaskIssues = "3/Tasks"; // Using the same Id as Tasks to retain correct sorting order

  export function taskIssueGroupId(taskId: string, issueId: string): string {
    return TaskIssues + "/" + taskId + "/" + issueId;
  }
}

namespace Items {
  export function taskItemId(taskId: string, transition: number): string {
    return "Task/" + taskId + "/" + transition;
  }

  export function taskIssueItemId(taskId: string, transition: number, issueId: string, reportedAt: Date): string {
    return "TaskIssue/" + taskId + "/" + transition + "/" + issueId + "/" + reportedAt;
  }
}

interface Props {
  jobHistory: JobHistory;
}

export const JobTimeline: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();
  const [showEvents, setShowEvents] = React.useState(false);

  const lastRecord = props.jobHistory.jobHistory.last(undefined);
  const archived = lastRecord !== undefined && lastRecord.isArchived();

  function renderGroups(): OrderedMap<IdType, DataGroup> {
    const allStatuses = props.jobHistory.jobHistory
      .flatMap((record) =>
        record.currentStatus
          ? List([record.currentStatus, record.desiredStatus])
          : List([record.desiredStatus])
      )
      .toSet()
      .toList()
      .sort();

    const allTaskIds = props.jobHistory.tasks.map((task) => task.taskId).toSet().toList().sort();

    return OrderedMap(
      List([
        {
          id: Groups.Events,
          content: "Events",
          className: "job-events"
        },
        {
          id: Groups.Transitions,
          content: "Transitions",
          className: "job-statuses"
        }
      ])
        .concat(
          allStatuses.map((status) => ({
            id: Groups.statusGroupId(status),
            content: status,
            className: "job-statuses"
          }))
        )
        .concat(
          allTaskIds
            .flatMap((taskId) => {
              const allTaskIssueIds = props.jobHistory.taskIssues
                .filter((taskIssue) => taskIssue.taskId === taskId)
                .map((taskIssue) => taskIssue.issueId)
                .toSet()
                .toList()
                .sort();

              return List([{
                id: Groups.taskGroupId(taskId),
                content:
                  "<a href=\"" + browser.href(routes.jobs.taskPath(props.jobHistory.jobId, taskId)) + "\">" +
                  taskId +
                  "</a>",
                // nestedGroups: allTaskIssueIds.isEmpty()
                //   ? undefined
                //   : allTaskIssueIds
                //     .map((taskIssueId) => Groups.taskIssueGroupId(taskId, taskIssueId))
                //     .toArray(),
                className: "tasks"
              }]).concat(
                allTaskIssueIds.map((taskIssueId) => ({
                  id: Groups.taskIssueGroupId(taskId, taskIssueId),
                  content:
                    "<a href=\"" +
                    browser.href(routes.jobs.taskIssuePath(props.jobHistory.jobId, taskId, taskIssueId)) +
                    "\">" +
                    taskIssueId +
                    "</a>",
                  className: "task-issues"
                }))
              );
            })
        )
        .map((item) => [item.id, item]));
  }

  function renderJobStatusChange(
    currentStatus: string | undefined,
    desiredStatus: string,
    transition: number,
    start: Date,
    end: Date | undefined,
    idling: boolean
  ): DataItem[] {
    const content = currentStatus !== desiredStatus
      ? (currentStatus || NoStatus) + " \u2192 " + desiredStatus
      : (currentStatus || "");

    const title = renderTooltip({
      props: [
        ["Started at", preciseTimestamp(start)],
        ["Completed at", end ? preciseTimestamp(end) : "--"],
      ]
    });

    // Transition may not be unique, so adding start timestamp to guarantee uniqueness
    const id = transition + ":" + start.toString() + ":" + (
      currentStatus !== desiredStatus
        ? "Transition(" + (currentStatus || "None") + "->" + desiredStatus
        : "Stable(" + currentStatus + ")"
    );

    const endOrNow = end || new Date();
    if (currentStatus !== desiredStatus) {
      return [
        {
          id,
          group: Groups.Transitions,
          content,
          title,
          start,
          end: endOrNow,
          type: "range",
          className: "job-status " + (end ? "completed" : "active")
        }
      ];
    } else if (idling) {
      return [
        {
          id,
          group: Groups.statusGroupId(currentStatus),
          content,
          title,
          start,
          end: endOrNow,
          type: "range",
          className: "job-status inactive"
        }
      ];
    } else {
      return [
        {
          id,
          group: Groups.statusGroupId(currentStatus),
          content,
          title,
          start,
          end: endOrNow,
          type: "range",
          className: "job-status " + (end ? "completed" : "active")
        },
        {
          id: id + "#Background",
          content,
          start,
          end: endOrNow,
          type: "background"
        }
      ];
    }
  }

  function renderJobEvent(
    record: JobHistoryRecord,
    type: "started" | "stopped" | "other"
  ): DataItem {
    const content = record.updateSummary + (
      record.updatedBy && record.updatedBy !== "System"
        ? "<br><span class=\"updated-by\">(by " + record.updatedBy + ")</span>"
        : ""
    );

    const title = renderTooltip({
      props: [
        ["Timestamp", preciseTimestamp(record.updatedAt)],
        ["Transition", "" + record.transition],
        ["Update summary", record.updateSummary || "--"],
        ["Updated by", record.updatedBy || "--"],
        ["System status", record.internalStatus],
        ["Current status", record.currentStatus || NoStatus],
        ["Desired status", record.desiredStatus],
        ["Scheduled status", record.nextStatus || "--"],
      ]
    });

    return {
      id: record.timestamp + "/" + record.updateSummary,
      group: Groups.Events,
      content,
      title,
      start: record.timestamp,
      className: "event " + type
    };
  }

  function renderTask(task: TaskSummaryRecord): DataItemEx {
    return {
      id: Items.taskItemId(task.taskId, task.transition),
      group: Groups.taskGroupId(task.taskId),
      content: task.taskId,
      title: renderTooltip(task.timelineTooltip()),
      start: task.createdAt,
      end: task.archivedAt || new Date(),
      className: "task " + (task.archivedAt ? "completed" : "active"),
      progress: itemProgress(task.createdAt, task.completedAt, task.archivedAt)
    };
  }

  function renderTaskIssue(taskIssue: TaskIssue): DataItem {
    const task = props.jobHistory.tasks.find((t) =>
      t.taskId === taskIssue.taskId && t.transition === taskIssue.transition
    );
    return {
      id: Items.taskIssueItemId(taskIssue.taskId, taskIssue.transition, taskIssue.issueId, taskIssue.createdAt),
      group: Groups.taskIssueGroupId(taskIssue.taskId, taskIssue.issueId),
      content: taskIssue.issueId,
      title: renderTooltip(taskIssue.timelineTooltip()),
      start: taskIssue.createdAt,
      // Making sure that unresolved issues will not break the boundaries of the task
      end: taskIssue.resolvedAt || task && task.archivedAt || new Date(),
      className: "issue " + (taskIssue.resolvedAt ? "completed" : "active")
    };
  }

  function renderJobStatuses(): List<DataItem> {
    const firstRecord = props.jobHistory.jobHistory.first(undefined);

    if (firstRecord && lastRecord) {
      let prevChange = firstRecord;

      const result = props.jobHistory.jobHistory.flatMap((record) => {
        if (
          record.isArchived() !== prevChange.isArchived() ||
          record.currentStatus !== prevChange.currentStatus ||
          record.desiredStatus !== prevChange.desiredStatus
        ) {
          const statusChange = List(
            renderJobStatusChange(
              prevChange.currentStatus,
              prevChange.desiredStatus,
              prevChange.transition,
              prevChange.updatedAt,
              record.updatedAt,
              prevChange.isArchived() && !record.isArchived()
            )
          );

          prevChange = record;

          return statusChange;
        } else {
          return List();
        }
      });

      return result.concat(
        renderJobStatusChange(
          prevChange.currentStatus,
          prevChange.desiredStatus,
          prevChange.transition,
          prevChange.updatedAt,
          !archived ? undefined : lastRecord.updatedAt,
          prevChange.isArchived()
        )
      );
    } else {
      return List();
    }
  }

  function renderEvents(): List<DataItem> {
    let prevRecord: JobHistoryRecord | undefined;
    return props.jobHistory.jobHistory.flatMap((record) => {
      const prevArchived = prevRecord === undefined || prevRecord.isArchived();
      const events = record.updateSummary && showEvents
        ? List([
          renderJobEvent(
            record,
            prevArchived && !record.isArchived()
              ? "started"
              : !prevArchived && record.isArchived()
                ? "stopped"
                : "other"
          )
        ])
        : List();
      prevRecord = record;
      return events;
    });
  }

  function renderItems(): Map<IdType, DataItem> {
    return Map(
      renderJobStatuses()
        .concat(renderEvents())
        .concat(props.jobHistory.tasks.map(renderTask))
        .concat(props.jobHistory.taskIssues.map(renderTaskIssue))
        .map((item) => [item.id || 0, item])
    );
  }

  return (
    <TimelinePanel
      subject={props.jobHistory.jobId}

      groups={renderGroups()}
      items={renderItems()}

      canFollow={!archived}
      additionalTools={(
        <Checkbox checked={showEvents} onChange={() => setShowEvents(!showEvents)}>
          Job events
        </Checkbox>
      )}
    />
  );
};
