import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { ReducerRegistry } from "../reducerRegistry";
import { Reducer } from "../reducer";
import { State } from "../state";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { None, Option, Some } from "../../utils/monads/option";
import { BlueprintException } from "../blueprintException";

export class MultipleRelationshipsHub<Output>
  extends DataSource<MultipleRelationshipsHub.Props, Output, Option<Output>> {

  public calcState(context: BlueprintContext): State<Option<Output>> {
    return State.reduceStates<Output, Option<Output>>(
      this.dataFlows().map((dataFlow) => dataFlow.state(context)),
      this.props.ignoreBlocked,
      (values) => Some(Some(this.reducer.reduce(values))),
      State.resolved(None())
    );
  }

  protected get reducer(): Reducer<Output> {
    return ReducerRegistry.get(this.props.reducerType);
  }
}

export namespace MultipleRelationshipsHub {
  export interface Props extends HubProps {
    reducerType: string;
    ignoreBlocked: boolean;
  }

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.MultipleRelationshipsHubFragment,
    settings?: ComponentHubSettings
  ): MultipleRelationshipsHub<any> {
    if (ReducerRegistry.keys().indexOf(hub.props.reducerType) !== -1) {
      return new MultipleRelationshipsHub(
        binding,
        hub,
        {
          ...HubProps.fromGraphQL(hub.props),
          reducerType: hub.props.reducerType,
          ignoreBlocked: hub.props.ignoreBlocked
        },
        settings);
    } else {
      throw new BlueprintException("Unknown reducer " + hub.props.reducerType);
    }
  }
}
