import * as React from "react";
import { styled, css } from "../../app/theme";
import { multiline } from "../../utils/formatting";
import { Spacer } from "../utils/spacer";
import { HasClickHandler, HasSimpleClickHandler, withSimpleClickHandler } from "../utils/simpleClickHandler";
import { CheckedCircle } from "../glyphs/checkedCircle";
import { Spinner } from "../glyphs/spinner";
import { ClickableAnchor } from "../../wizardry/anchor";
import { List } from "immutable";
import { WorkflowTitle } from "./workflowTitle";

export const WorkflowNavigation = styled.ul`
   list-style-type: none;
   margin: 0;
   padding: 0.5rem 0 0 0;
`;

interface WorkflowStepStatus {
  enabled?: boolean;
  selected?: boolean;
  started?: boolean; // this is an equivalent to progress=100
  complete?: boolean;

  progress?: number;
  error?: string;
}

interface WorkflowStepConfig extends WorkflowStepStatus {
  /* tslint:disable-next-line:variable-name */
  number?: number;
  title: string;
  description?: string;

  hideProgressIndicator?: boolean;

  nextSelected?: boolean;
}

const ListItem = styled.li`
`;

interface SeparatorProps {
  transparent?: boolean;
}

const Separator = styled.div<SeparatorProps>`
  border-top: 1px solid ${(props) => props.transparent ? "transparent" : props.theme.colors.lightGray};
  margin: 0 10%;
`;

const ItemNumber = styled.div`
`;

const ItemTitleAndDescriptionContainer = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 10rem;
  padding: 0 0 0 0.2rem;
`;

const ItemTitle = styled.div`
  text-transform: uppercase;
`;

const ItemDescription = styled.div`
  padding: 0.2rem 0 0 0;
  font-size: 0.75rem;
  font-weight: ${(props) => props.theme.font.light};
  line-height: 1.3;
`;

const layoutMixin = css`
  display: flex;
  width: 100%;
  padding: 1.0rem 1.0rem 1.0rem 1.085rem;
  border-left: 3px solid transparent;
  box-sizing: border-box;
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.font.medium};
  color: ${(props) => props.theme.colors.textColor};
  text-decoration: none;
`;

const SelectedItem = styled.div`
  ${layoutMixin};

  background: ${(props) => props.theme.colors.lightPrimary};
  border-left-color: ${(props) => props.theme.colors.primary};
  cursor: default;
`;

const PlaceholderItem = styled.div`
  ${layoutMixin};
`;

const ClickableItem = styled.a`
  ${layoutMixin};

  text-decoration: none;

  &:hover {
    text-decoration: none;
    transition: 0.1s;
    background-color: ${(props) => props.theme.colors.faintPrimary};
    border-left-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const ItemProgressIndicatorContainer = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  flex-shrink: 0;
`;

const ItemProgressIndicator: React.FunctionComponent<WorkflowStepStatus> = (props) => {
  if (props.error) {
    return <Spinner progress={100} color="red"/>;
  } else if (props.progress) {
    return <Spinner progress={props.progress}/>;
  } else if (props.complete) {
    return <CheckedCircle/>;
  } else if (props.started) {
    return <Spinner progress={100}/>;
  } else {
    return <Spinner progress={0}/>;
  }
};

interface ItemLayoutProps extends WorkflowStepConfig {
  showProgressIndicator: boolean;
}

const ItemLayout: React.FunctionComponent<ItemLayoutProps> = (props) => {
  const progressIndicator = props.showProgressIndicator && (
    <React.Fragment>
      <Spacer/>
      <ItemProgressIndicatorContainer>
        <ItemProgressIndicator {...props}/>
      </ItemProgressIndicatorContainer>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {props.number && <ItemNumber>{props.number}.</ItemNumber>}
      <ItemTitleAndDescriptionContainer>
        <ItemTitle>{props.title}</ItemTitle>
        {props.description && <ItemDescription>{multiline(props.description)}</ItemDescription>}
      </ItemTitleAndDescriptionContainer>
      {progressIndicator}
    </React.Fragment>
  );
};

interface ListItemBodyProps extends WorkflowStepConfig, HasClickHandler {
}

const ListItemBody: React.FunctionComponent<ListItemBodyProps> = (props) => {
  const { onClick, ...workflowStepConfig } = props; // Taking everything except the onClick handler

  if (props.enabled) {
    const itemLayout = <ItemLayout {...workflowStepConfig} showProgressIndicator={!props.hideProgressIndicator}/>;
    const separator = <Separator transparent={props.selected || props.nextSelected}/>;
    if (props.selected) {
      return (
        <React.Fragment>
          <SelectedItem>
            {itemLayout}
          </SelectedItem>
          {separator}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ClickableItem onClick={props.onClick}>
            {itemLayout}
          </ClickableItem>
          {separator}
        </React.Fragment>
      );
    }
  } else {
    return (
      <React.Fragment>
        <PlaceholderItem>
          <ItemLayout {...workflowStepConfig} showProgressIndicator={false}/>
        </PlaceholderItem>
        <Separator hidden={false}/>
      </React.Fragment>
    );
  }
};

interface WorkflowNavigationItemProps extends WorkflowStepConfig, HasClickHandler {
}

export const WorkflowNavigationItem: React.FunctionComponent<WorkflowNavigationItemProps> = (props) => (
  <ListItem key={props.title}>
    <ListItemBody {...props}/>
  </ListItem>
);

export const SimpleWorkflowNavigationItem = withSimpleClickHandler(WorkflowNavigationItem);
