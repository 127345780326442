import * as React from "react";
import { styled } from "../../app/theme";
import { LinkButton } from "../widgets/linkButton";

export const StyleguideBlock = styled.div`
`;

const HeaderLayout = styled.div`
  font-size: 1.3rem;
  font-weight: ${(props) => props.theme.font.regular};
  padding: .7rem .5rem .5rem .5rem;
  margin: 1.5rem 0 1rem;
  background: ${(props) => props.theme.colors.lighterGray};
  display: flex;
  justify-content: center;
`;

const HeaderContent = styled.div`
  flex-grow: 1;
`;

const HeaderTools = styled.div`
  display: flex;
  
  > * {
    margin-left: 1rem;
  }
  
  > ${LinkButton} {
    font-size: 0.85rem;
  }
`;

interface StyleguideHeaderProps {
  tools?: React.ReactNode;
}

export const StyleguideHeader: React.FunctionComponent<StyleguideHeaderProps> = (props) => (
  <HeaderLayout>
    <HeaderContent>{props.children}</HeaderContent>
    {props.tools && <HeaderTools>{props.tools}</HeaderTools>}
  </HeaderLayout>
);
