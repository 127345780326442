import * as React from "react";
import { Page } from "../containers/page";
import { OperationStatus } from "../../types/operationStatus";
import { JobHistory } from "../../types/models/jobHistory";
import { JobHistoryPanelView } from "../blocks/jobManagement/jobHistoryPanelView";
import { Block } from "../containers/block";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { JobInfoPanel } from "../blocks/jobManagement/jobInfoPanel";
import { JobTimeline } from "../blocks/jobManagement/jobTimeline";

interface Props {
  jobId: string;
  includeMinorUpdates: boolean;

  loadingStatus: OperationStatus<JobHistory>;

  onRefresh: () => void;
  onScheduleTransition: () => void;
  onAbort: () => void;
  onChangeIncludeMinorUpdates: (value: boolean) => void;
}

export const JobPageView: React.FunctionComponent<Props> = (props) => {
  const [showJobEvents, setShowJobEvents] = React.useState(true);
  const [showRunningTasks, setShowRunningTasks] = React.useState(true);
  const [showCompletedTasks, setShowCompletedTasks] = React.useState(true);
  const [showPendingIssues, setShowPendingIssues] = React.useState(true);
  const [showResolvedIssues, setShowResolvedIssues] = React.useState(true);

  function setAll(value: boolean) {
    setShowJobEvents(value);
    setShowRunningTasks(value);
    setShowCompletedTasks(value);
    setShowPendingIssues(value);
    setShowResolvedIssues(value);
  }

  function reset() {
    setAll(true);
    props.onChangeIncludeMinorUpdates(false);
  }

  function clear() {
    setAll(false);
    props.onChangeIncludeMinorUpdates(false);
  }

  return (
    <Page
      breadcrumbs={["Jobs"]}
      title={`Job \"${props.jobId}\"`}
      fullWidth={true}
    >
      {(!props.loadingStatus.someResult() || props.loadingStatus.isFailure()) && (
        <OperationStatusIndicator
          subject={"job"}
          status={props.loadingStatus}
          indicators={StatusIndicators.SimplePanel()}
        />
      )}
      {props.loadingStatus.mapLastResult((jobHistory) => (
        <>
          <Block>
            <JobInfoPanel
              jobHistory={jobHistory}

              refreshing={props.loadingStatus.isWorking()}
              onRefresh={props.onRefresh}

              onScheduleTransition={props.onScheduleTransition}
              onAbort={props.onAbort}

              includeMinorUpdates={props.includeMinorUpdates}
              onToggleIncludeMinorUpdates={() => props.onChangeIncludeMinorUpdates(!props.includeMinorUpdates)}

              onShowRunningTasksOnly={() => { clear(); setShowRunningTasks(true); }}
              onShowCompletedTasksOnly={() => { clear(); setShowCompletedTasks(true); }}
              onShowPendingIssuesOnly={() => { clear(); setShowPendingIssues(true); }}
              onShowResolvedIssuesOnly={() => { clear(); setShowResolvedIssues(true); }}
            />
          </Block>
          <Block>
            <JobTimeline jobHistory={jobHistory}/>
          </Block>
          <Block>
            <JobHistoryPanelView
              jobHistory={jobHistory}

              showJobEvents={showJobEvents}
              showRunningTasks={showRunningTasks}
              showCompletedTasks={showCompletedTasks}
              showPendingIssues={showPendingIssues}
              showResolvedIssues={showResolvedIssues}

              reset={reset}

              onToggleShowJobEvents={() => setShowJobEvents(!showJobEvents)}
              onToggleShowRunningTasks={() => setShowRunningTasks(!showRunningTasks)}
              onToggleShowCompletedTasks={() => setShowCompletedTasks(!showCompletedTasks)}
              onToggleShowPendingIssues={() => setShowPendingIssues(!showPendingIssues)}
              onToggleShowResolvedIssues={() => setShowResolvedIssues(!showResolvedIssues)}
            />
          </Block>
        </>
      ))}
    </Page>
  );
};
