import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";
import { OperationStatus } from "../types/operationStatus";
import { List, Set } from "immutable";
import { Connection } from "../types/models/connection";

interface Variables {
  sourceCloudServiceId: string;
  sourceEmailAddresses: Set<string>;
  sourceAdminConnectionId: string | undefined;
  destinationCloudServiceId: string;
  destinationEmailAddresses: Set<string>;
  destinationAdminConnectionId: string | undefined;
}

export namespace ResolveConnectionsResult {
  export interface Segment {
    resolved: List<Connection>;
    notAccessible: List<string>;
    wrongCloudService: List<Connection>;
    ambiguous: List<string>;
    missing: List<string>;

    noIssues: boolean;
  }

  export namespace Segment {
    export function parse(data: GraphQL.ResolveConnectionsResult_Segment): Segment {
      const notAccessible = List(data.notAccessible);
      const wrongCloudService = List(data.wrongCloudService).map(Connection.fromGraphQL);
      const ambiguous = List(data.ambiguous);
      const missing = List(data.missing);

      return {
        resolved: List(data.resolved).map(Connection.fromGraphQL),
        notAccessible,
        wrongCloudService,
        ambiguous,
        missing,

        noIssues: (
          notAccessible.isEmpty() &&
          wrongCloudService.isEmpty() &&
          ambiguous.isEmpty() &&
          missing.isEmpty()
        )
      };
    }
  }

  export function parseCore(data: GraphQL.ResolveConnectionsResult): ResolveConnectionsResult {
    const source = Segment.parse(data.source);
    const destination = Segment.parse(data.destination);

    return {
      source,
      destination,
      noIssues: source.noIssues && destination.noIssues
    };
  }
}

export interface ResolveConnectionsResult {
  source: ResolveConnectionsResult.Segment;
  destination: ResolveConnectionsResult.Segment;

  noIssues: boolean;
}

type ResolveConnectionsMutationHook = [
  (variables: Variables) => Promise<ResolveConnectionsResult>,
  OperationStatus<ResolveConnectionsResult>
];

export function useResolveConnectionsMutation(): ResolveConnectionsMutationHook {
  const [resolveConnections, { status }] = useManagedMutation({
    mutation: GraphQL.useResolveConnectionsMutation,
    extract: (data: GraphQL.ResolveConnectionsMutation) => nullToUndefined(data.resolveConnections),
    complete: ResolveConnectionsResult.parseCore
  });

  function go(variables: Variables): Promise<ResolveConnectionsResult> {
    return resolveConnections({
      variables: {
        sourceCloudServiceId: variables.sourceCloudServiceId,
        sourceEmailAddresses: variables.sourceEmailAddresses.toArray(),
        sourceAdminConnectionId: variables.sourceAdminConnectionId,
        destinationCloudServiceId: variables.destinationCloudServiceId,
        destinationEmailAddresses: variables.destinationEmailAddresses.toArray(),
        destinationAdminConnectionId: variables.destinationAdminConnectionId
      },
      retry: () => go(variables)
    });
  }

  return [go, status];
}
