import { BlueprintContext } from "../blueprintContext";
import { DataFlowWithProvider, DataFlowWithProviderProps } from "../relationship";
import { State } from "../state";
import { Option } from "../../utils/monads/option";
import { GraphQL } from "../../services/graphql/generated";
import { Variant } from "../../types/models/variant";

export class PipeFromOption<Output>
  extends DataFlowWithProvider<PipeFromOption.Props<Output>, Option<Output>, Output> {

  public stateWhenUnblocked(context: BlueprintContext, componentState: State<unknown>): State<Output> {
    return componentState.output
      .flatMap((componentOutput) => this.getInput(componentOutput))
      .map((componentOutput) => componentState.map(() => componentOutput))
      .getOrElse(() => State.pending());
  }
}

export namespace PipeFromOption {
  export type Props<Output> = DataFlowWithProviderProps<Output>;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.PipeFromOptionPropsFragment): Props<any> {
      return DataFlowWithProviderProps.fromGraphQL(props, Variant.fromGraphQL);
    }
  }
}
