import { ComponentColorSchema } from "./componentColorSchema";
import { ComponentSettings } from "./components/componentSettings";
import { State } from "./state";
import { resolveImage } from "../app/images";

export namespace ComponentVisualization {
  export interface Font {
    size?: number;
  }

  export function icon(
    visualization: ComponentVisualization,
    settings: ComponentSettings): any | undefined {
    return resolveImage(settings.icon, resolveImage(visualization.icon));
  }

  export function colorSchema(
    visualization: ComponentVisualization,
    settings: ComponentSettings
  ): ComponentColorSchema {
    return settings.color || visualization.color || ComponentColorSchema.SolidBlack;
  }

  export function size(visualization: ComponentVisualization, settings: ComponentSettings): number {
    return 16 * (visualization.sizeMultiplier || 1) * settings.sizeMultiplier;
  }

  export function fontSize(visualization: ComponentVisualization, settings: ComponentSettings): number {
    return visualization.font && visualization.font.size || size(visualization, settings) * 0.66;
  }

  export function finalBackgroundColor(
    color: string | undefined,
    state: State<any>,
    enabled: boolean
  ): string {
    return color ? (state.isEnabled && enabled ? color : "#cccccc") : "white";
  }

  export function finalBorderColor(
    color: string,
    state: State<any>,
    enabled: boolean
  ): string {
    return state.isEnabled && enabled ? color : "#cccccc";
  }
}

export interface ComponentVisualization {
  title: string;
  summary?: string;
  sizeMultiplier?: number;
  icon?: any;
  color?: ComponentColorSchema;
  font?: ComponentVisualization.Font;
  disabled?: boolean;
}
