import { GraphQL } from "../../services/graphql/generated";
import { ObjectRepo } from "../../utils/objectRepo";
import { AuthProvider } from "./authProvider";

export class AuthProviders extends ObjectRepo<AuthProvider> {
  public getOrFail(id: string): AuthProvider {
    const result = this.get(id);
    if (result) {
      return result;
    } else {
      throw Error("Unknown authentication provider " + id);
    }
  }

  public default(): AuthProvider {
    return this.getOrFail("google");
  }
}

export namespace AuthProviders {
  export const Empty: AuthProviders = new AuthProviders();

  export function fromGraphQLList(authProviders: GraphQL.AuthProvider[]): AuthProviders {
    return new AuthProviders(authProviders.map(AuthProvider.fromGraphQL));
  }
}
