import * as React from "react";
import { UpdateCustomerDetailsToolControllerProps } from "../views/blocks/updateCustomerDetailsForm";
import { OperationStatus } from "../types/operationStatus";
import { useBrowser } from "../utils/useBrowser";
import { GraphQL } from "../services/graphql/generated";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { nullToUndefined } from "../utils/misc";
import { User } from "../types/models/user";
import { useSession } from "../utils/useAppState";
import { useManagedQuery } from "../services/graphql/useManagedQuery";

interface Props extends UpdateCustomerDetailsToolControllerProps {
  userId: string | undefined;
  currentCustomerDetails: string | undefined;
  buildDownloadUrl: (customerDetails: string) => string;
}

export const InvoiceToolController: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const session = useSession();

  const [getUserStatus] = useManagedQuery({
    query: GraphQL.useGetUserQuery,
    deps: props.userId,
    prepare: (userId) => ({ userId }),
    extract: (data: GraphQL.GetUserQuery) => data.getUser,
    complete: User.parse
  });

  const [updateCustomerDetails, updateCustomerDetailsStatus] = useUpdateCustomerDetailsMutation(props.userId || "");

  function mayBeUpdateCustomerDetails(customerDetails: string): Promise<string> {
    return props.userId && props.userId === session?.user.id
      ? updateCustomerDetails(customerDetails).then((user) => user.customerDetails || "")
      // Note: customerDetails will be truncated on the back-end, so we are just ensuring that no one (not even admins)
      // will type something extra-long.
      : Promise.resolve(customerDetails.substring(0, 1000));
  }

  return props.render({
    customerDetailsStatus: getUserStatus.map((user) => user.customerDetails || ""),
    submitStatus: updateCustomerDetailsStatus,
    onSubmit: (customerDetails) =>
      mayBeUpdateCustomerDetails(customerDetails).then((preparedCustomerDetails) =>
        browser.openNewTab(props.buildDownloadUrl(preparedCustomerDetails), true)
      )
  });
};

export function useInvoiceToolController(
  userId: string | undefined,
  buildDownloadUrl: (customerDetails: string) => string
) {
  return React.useCallback(
    (controllerProps: UpdateCustomerDetailsToolControllerProps) => (
      <InvoiceToolController
        {...controllerProps}
        userId={userId}
        currentCustomerDetails={"customerDetails"}
        buildDownloadUrl={buildDownloadUrl}
      />
    ),
    [userId, buildDownloadUrl]
  );
}

type UpdateCustomerDetailsHook = [
  (customerDetails: string) => Promise<User>,
  OperationStatus<User>
];

function useUpdateCustomerDetailsMutation(userId: string): UpdateCustomerDetailsHook {
  const [fireWith, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateCustomerDetailsMutation,
    extract: (data: GraphQL.UpdateCustomerDetailsMutation) => nullToUndefined(data.updateCustomerDetails),
    complete: User.parse
  });

  function fire(customerDetails: string): Promise<User> {
    return fireWith({
      variables: { userId, customerDetails },
      retry: () => fire(customerDetails),
    });
  }

  return [fire, status];
}
