import * as React from "react";
import { styled } from "../../../../app/theme";

export const CarouselLink = styled.button`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.125rem;
  display: flex;
  justify-content: center;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1rem;
  }

  @media(hover: hover) {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
