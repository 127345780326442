import * as React from "react";
import { css, styled } from "../../app/theme";
import { bytesToGigabytes, commafy, friendlyDateTime, friendlyDuration, prettyPrint } from "../../utils/formatting";
import { StyledComponentsProps } from "../utils/styledComponentsProps";
import { LinkButton } from "./linkButton";
import { Option } from "../../utils/monads/option";

interface IndicatorValueProps {
  value: React.ReactNode;
  prefix?: string;
  suffix?: string;
  parenthetical?: React.ReactNode;
  onClick?: () => void;
}

const IndicatorValueLayoutOuter = styled.div`
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
`;

const InnerIndicatorValueLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: -0.1rem 0;
  
  > * {
    margin: 0.1rem 0;
  }
`;

const Value = styled.div<{ hasSuffix: boolean }>`
  font-size: 1.2rem;
  padding-right: ${(props) => props.hasSuffix ? 0.3 : 0}rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1rem;
  }
`;

const JsonValuePreviewLines = 5;

const JsonValue = styled.pre<{ expanded: boolean }>`
  font-size: 1rem;
  overflow-y: hidden;
  max-height: ${(props) => props.expanded ? "inherit" : (JsonValuePreviewLines + 0.5) + "rem"};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.8rem;
  }
`;

const Prefix = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-size: .85rem;
  padding-right: 0.15rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.7rem;
  }
`;

const Suffix = styled.div<{ hasParenthetical: boolean }>`
  color: ${(props) => props.theme.colors.gray};
  font-size: .85rem;
  text-transform: uppercase;
  padding-right: ${(props) => props.hasParenthetical ? .7 : 0}rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.7rem;
  }
`;

const Parenthetical = styled.div`
  flex-grow: 0.05;
  font-size: .85rem;
  text-transform: uppercase;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.7rem;
  }
`;

function preparedValue(value: React.ReactNode): React.ReactNode {
  if (typeof value === "number") {
    return commafy(value);
  } else {
    return value;
  }
}

export const IndicatorValue: React.FunctionComponent<IndicatorValueProps> = (props) => (
  <IndicatorValueLayoutOuter onClick={props.onClick}>
    <InnerIndicatorValueLayout>
      {props.prefix && <Prefix>{props.prefix}</Prefix>}
      <Value hasSuffix={!!props.suffix || !!props.parenthetical}>
        {preparedValue(props.value)}
      </Value>
      {props.suffix && <Suffix hasParenthetical={!!props.parenthetical}>{props.suffix}</Suffix>}
      {props.parenthetical && <Parenthetical>[{props.parenthetical}]</Parenthetical>}
    </InnerIndicatorValueLayout>
  </IndicatorValueLayoutOuter>
);

interface JsonIndicatorValueProps {
  json: string;
  expanded: boolean;
}

export const JsonIndicatorValue: React.FunctionComponent<JsonIndicatorValueProps> = (props) => {
  return (
    <IndicatorValueLayoutOuter>
      <InnerIndicatorValueLayout>
        <JsonValue expanded={props.expanded}>{props.json}</JsonValue>
      </InnerIndicatorValueLayout>
    </IndicatorValueLayoutOuter>
  );
};

interface IndicatorProps {
  title?: React.ReactNode;
  compact?: boolean;
}

const IndicatorLayout = styled.div`
`;

const IndicatorTitle = styled.div`
  text-transform: uppercase;
  font-size: .75rem;
  margin-bottom: 0.3rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
  }
`;

const IndicatorValues = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: -.2rem -${(props) => props.compact ? .5 : 1}rem;
  
  > ${IndicatorValueLayoutOuter} {
    margin: .2rem ${(props) => props.compact ? .5 : 1}rem;
  }
`;

export const StyledIndicator: React.FunctionComponent<IndicatorProps & StyledComponentsProps> = (props) => {
  return (
    <IndicatorLayout className={props.className}>
      {props.title && <IndicatorTitle>{props.title}</IndicatorTitle>}
      <IndicatorValues compact={props.compact}>{props.children}</IndicatorValues>
    </IndicatorLayout>
  );
};

export const Indicator = styled(StyledIndicator)``;

const smallIndicatorsMixin = css`
  ${Value} {
    font-size: 1rem;
  
    ${(props) => props.theme.responsive.respondToXSmall()} {
      font-size: 0.85rem;
    }
  }
  
  ${Prefix}, ${Suffix}, ${Parenthetical} {
    font-size: 0.7rem;
  
    ${(props) => props.theme.responsive.respondToXSmall()} {
      font-size: 0.58rem;
    }
  }
  
  ${IndicatorTitle} {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
  
    ${(props) => props.theme.responsive.respondToXSmall()} {
      font-size: 0.6rem;
      margin-bottom: 0.1rem;
    }
  }
  
  ${JsonValue} {
    font-size: 0.85rem;
  
    ${(props) => props.theme.responsive.respondToXSmall()} {
      font-size: 0.7rem;
    }
  }
`;

interface IndicatorsProps {
  direction?: "row" | "column";
  size?: "regular" | "small";
}

export const Indicators = styled.div<IndicatorsProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: wrap;
  margin: -.5rem -1rem;
  
  > ${Indicator} {
    margin: .5rem 1rem;
  }
  
  ${(props) => props.size === "small" && smallIndicatorsMixin};
`;

interface SimpleIndicatorProps {
  title?: string;
  value: React.ReactNode | Option<React.ReactNode>;
  parenthetical?: React.ReactNode;
}

export const SimpleIndicator: React.FunctionComponent<SimpleIndicatorProps> = (props) => {
  const value = Option.isOption(props.value) ? props.value.toJS() : props.value;
  return (
    <Indicator title={props.title}>
      <IndicatorValue
        value={value !== undefined ? value : "--"}
        parenthetical={props.parenthetical}
      />
    </Indicator>
  );
};

interface DateTimeIndicatorProps {
  title?: string;
  dateTime: string | Date | undefined | Option<string | Date>;
  parenthetical?: React.ReactNode;
}

export const DateTimeIndicator: React.FunctionComponent<DateTimeIndicatorProps> = (props) => {
  const dateTime = Option.isOption(props.dateTime) ? props.dateTime.toJS() : props.dateTime;
  return (
    <Indicator title={props.title}>
      <IndicatorValue
        value={dateTime !== undefined ? friendlyDateTime(dateTime) : "--"}
        parenthetical={props.parenthetical}
      />
    </Indicator>
  );
};

interface TimeIndicatorProps {
  title?: string;
  time?: number;
  parenthetical?: React.ReactNode;
}

export const TimeIndicator: React.FunctionComponent<TimeIndicatorProps> = (props) => {
  let minutes = props.time ? Math.max(1, Math.round(props.time / 60)) : 0;
  const days = Math.floor(minutes / 60 / 24);
  const hours = Math.floor((minutes - days * 60 * 24) / 60);
  minutes %= 60;

  return (
    <Indicator title={props.title} compact={true}>
      <IndicatorValue
        value={days}
        suffix={"day" + (days === 1 ? "" : "s")}
      />
      <IndicatorValue
        value={hours}
        suffix={"hour" + (hours === 1 ? "" : "s")}
      />
      <IndicatorValue
        value={minutes}
        suffix={"minute" + (minutes === 1 ? "" : "s")}
        parenthetical={props.parenthetical}
      />
    </Indicator>
  );
};

interface SizeIndicatorValueProps {
  size?: number;
  parenthetical?: React.ReactNode;
}

export const SizeIndicatorValue: React.FunctionComponent<SizeIndicatorValueProps> = (props) => (
  <IndicatorValue
    value={props.size !== undefined ? bytesToGigabytes(props.size).toFixed(2) : "0.00"}
    suffix="GB"
    parenthetical={props.parenthetical}
  />
);

export const SizeIndicator: React.FunctionComponent<IndicatorProps & SizeIndicatorValueProps> = (props) => (
  <Indicator {...props}>
    <SizeIndicatorValue {...props}/>
  </Indicator>
);

interface ItemCountIndicatorValueProps {
  count: number | undefined;
  unit: string;
  placeholder?: string;
  parenthetical?: React.ReactNode;
}

export const ItemCountIndicatorValue: React.FunctionComponent<ItemCountIndicatorValueProps> = (props) => (
  <IndicatorValue
    value={props.count === undefined ? (props.placeholder || "--") : props.count}
    suffix={
      props.count === undefined
        ? undefined
        : (props.count === 1 ? props.unit : props.unit + "s")
    }
    parenthetical={props.parenthetical}
  />
);

export const ItemCountIndicator: React.FunctionComponent<IndicatorProps & ItemCountIndicatorValueProps> = (props) => (
  <Indicator {...props}>
    <ItemCountIndicatorValue {...props}/>
    {props.children}
  </Indicator>
);

export const ItemCountAndSizeIndicator: React.FunctionComponent<
  IndicatorProps & ItemCountIndicatorValueProps & SizeIndicatorValueProps> = (props) => (
  <Indicator {...props}>
    <ItemCountIndicatorValue {...props}/>
    <SizeIndicatorValue {...props}/>
  </Indicator>
);

interface PriceIndicatorProps {
  title?: string;
  price: number | undefined | Option<number>;
  parenthetical?: React.ReactNode;
}

export const PriceIndicator: React.FunctionComponent<PriceIndicatorProps> = (props) => {
  const price = Option.isOption(props.price) ? props.price.toJS() : props.price;
  return (
    <Indicator title={props.title}>
      <IndicatorValue
        value={price !== undefined ? price.toFixed(2) : "--"}
        prefix={price !== undefined ? "$" : undefined}
        parenthetical={props.parenthetical}
      />
    </Indicator>
  );
};

interface JsonIndicatorProps {
  title?: string;
  value: any;
  version?: string;
}

export const JsonIndicator: React.FunctionComponent<JsonIndicatorProps> = (props) => {
  const json = (typeof props.value === "string" ? props.value : prettyPrint(props.value)) || "--";
  const expandable = json.split("\n").length > JsonValuePreviewLines;
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Indicator
      title={(
        <>
          {props.title}
          &nbsp;&nbsp;
          {props.version ? "(" + props.version + ")" : ""}
          &nbsp;&nbsp;
          {
            expandable &&
            <LinkButton onClick={() => setExpanded(!expanded)}>{expanded ? "Collapse" : "Expand"}</LinkButton>
          }
        </>
      )}
    >
      <JsonIndicatorValue json={json} expanded={!expandable || expanded}/>
    </Indicator>
  );
};
