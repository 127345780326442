import * as React from "react";
import { ActionItem, ActionItems } from "./actionItem";
import { List } from "immutable";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";

export interface Slowness {
  subjectId: string;
  message?: React.ReactNode;
  helpArticle?: PreparedHelpArticle;
}

export namespace Slowness {
  export function toActionItems(slowness?: Slowness): ActionItems {
    if (slowness) {
      return List([{
        id: ActionItem.slowness(slowness.subjectId),
        type: ActionItem.Type.Warning,
        message: slowness.message || "Experiencing slowness",
        actions: [{
          title: "Learn more",
          helpArticle: slowness.helpArticle
        }],
        suppressAction: undefined
      }]);
    } else {
      return List();
    }
  }
}
