import * as React from "react";
import { ConnectionPanelDefs } from "../views/blocks/connectionPanel/connectionPanelDefs";
import { MigrationEstimates } from "../views/blocks/migrationDetailsPanel";
import { CloudService } from "../types/models/cloudService";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { Connection } from "../types/models/connection";
import { useConnectionFlow } from "./useConnectionFlow";
import { OperationStatus } from "../types/operationStatus";
import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { PricingModelCalculator } from "../types/models/pricingModelCalculator";
import { List, Set } from "immutable";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { nullToUndefined } from "../utils/misc";
import { ActionItem, ActionItems } from "../views/models/actionItem";
import { SemiBold } from "../views/widgets/semiBold";
import { Constants } from "../app/constants";
import { useProgramAlias } from "../app/configuration";

interface Config {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: MaterializedMigrationBlueprint;
  sourceConnection: Connection;
  destinationConnection: Connection;

  excluded: Set<string>;
  onSelectionChange: (excluded: Set<string>) => void;

  actionItemSuppressing: ActionItems.Suppressing;
}

interface PreferencesFlowHook {
  sourceConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  destinationConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  estimates: MigrationEstimates | undefined;
  pricingModelStatus: OperationStatus<PricingModelCalculator>;
  migrationBlocker: MaterializedMigrationBlueprint.MigrationBlocker | undefined;
}

export function usePreferencesFlow(config: Config): PreferencesFlowHook {
  const sourceConnectionState = useConnectionFlow({
    source: true,
    cloudService: config.sourceCloudService,
    oppositeCloudService: config.destinationCloudService,
    blueprint: config.blueprint,
    areas: config.blueprint.listAreas().map(([area, sink]) => ({
      component: area,
      config: {},
      notUsed: !sink,
      disabled: sink && (
        !config.blueprint.missingRoles(sink).isEmpty()
          ? {
            id: ActionItem.incrementalSignIn(area.id),
            type: ActionItem.Type.Action,
            message: (
              <>
                Additional sign-in is required{" "}
                <SemiBold>in the destination account</SemiBold>{" "}
                ({config.destinationConnection.description})
              </>
            ),
            actions: [],
            suppressAction: undefined,
            masterActionItemId: ActionItem.incrementalSignIn(sink.id)
          }
          : (
            sink.state(config.blueprint.context).isBlocked
              ? {
                id: ActionItem.notEnabled(area.id),
                type: ActionItem.Type.Warning,
                message: (
                  <>
                    {
                      sink.props.appTitle === Constants.OutlookHack.appTitle
                        ? Constants.OutlookHack.subject
                        : sink.props.appTitle
                    }{" "}is not enabled{" "}
                    <SemiBold>in the destination account</SemiBold>{" "}
                    ({config.destinationConnection.description})
                  </>
                ),
                actions: [],
                suppressAction: undefined,
                masterActionItemId: ActionItem.notEnabled(sink.id)
              }
              : undefined
          )
      )
    })),
    defaultRoles: config.blueprint.roles(true),

    connection: config.sourceConnection,
    oppositeConnection: config.destinationConnection,

    showRestrictions: false,
    newUserSettings: undefined,

    actionItemSuppressing: config.actionItemSuppressing
  });

  const destinationConnectionState = useConnectionFlow({
    source: false,
    cloudService: config.destinationCloudService,
    oppositeCloudService: config.sourceCloudService,
    blueprint: config.blueprint,
    areas: config.blueprint.listSinks().map(([sink, areas]) => ({
      component: sink,
      config: {},
      notUsed: areas.isEmpty()
    })),
    defaultRoles: config.blueprint.roles(false),

    connection: config.destinationConnection,
    oppositeConnection: config.sourceConnection,

    showRestrictions: true,
    newUserSettings: undefined,

    actionItemSuppressing: config.actionItemSuppressing
  });

  const programAlias = useProgramAlias();

  const [pricingModelStatus] = useManagedQuery({
    query: GraphQL.useGetMigrationPriceCalculatorQuery,
    deps: null,
    prepare: () => ({
      sourceCloudServiceId: config.sourceCloudService.id,
      destinationCloudServiceId: config.destinationCloudService.id,
      sourceConnectionId: config.sourceConnection.id,
      destinationConnectionId: config.destinationConnection.id,
      programAlias
    }),
    extract: (data: GraphQL.GetMigrationPriceCalculatorQuery) => data.getMigrationPriceCalculator,
    complete: PricingModelCalculator.parse
  });

  const migrationTotals = config.blueprint.migrationTotals();

  const [calcTimeEstimates, calcTimeEstimatesStatus] = useManagedMutation({
    mutation: GraphQL.useCalcTimeEstimatesMutation,
    extract: (data: GraphQL.CalcTimeEstimatesMutation) => nullToUndefined(data.calcTimeEstimates),
    complete: (timeEstimates) => List(timeEstimates)
  });

  const [scheduledCalc, setScheduledCalc] = React.useState<GraphQL.CalcTimeEstimatesMutationVariables>();

  function triggerCalcTimeEstimates(variables: GraphQL.CalcTimeEstimatesMutationVariables): void {
    calcTimeEstimates({ variables })
      .then((result) => {
        setScheduledCalc((nextVariables) => {
          if (nextVariables) {
            triggerCalcTimeEstimates(nextVariables);
          }
          return undefined;
        });
        return result;
      });
  }

  React.useEffect(
    () => {
      const variables: GraphQL.CalcTimeEstimatesMutationVariables = {
        sourceCloudServiceId: config.sourceCloudService.id,
        destinationCloudServiceId: config.destinationCloudService.id,
        blueprintInputs: config.blueprint.context.inputs.toGraphQL()
      };
      if (calcTimeEstimatesStatus.status.isWorking()) {
        setScheduledCalc(variables);
      } else {
        triggerCalcTimeEstimates(variables);
      }
    },
    [config.excluded.sort().join(","), config.blueprint.isScanningInProgress()]
  );

  return {
    sourceConnectionState: {
      ...sourceConnectionState,
      areaSelection: {
        excluded: Set(config.excluded),
        onChange: config.onSelectionChange
      },
      actionItemSuppressing: config.actionItemSuppressing
    },
    destinationConnectionState: {
      ...destinationConnectionState,
      actionItemSuppressing: config.actionItemSuppressing
    },
    estimates: {
      totalBytes: migrationTotals.size,
      totalItems: migrationTotals.itemCount,
      time: calcTimeEstimatesStatus.status,
    },
    pricingModelStatus,
    migrationBlocker: config.blueprint.migrationBlocker()
  };
}
