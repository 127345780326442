import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { Theme } from "../../types/models/theme";

type DeleteThemeMutationHook = [(id: number) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteThemeMutation(): DeleteThemeMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteThemeMutation,
    extract: (data: GraphQL.DeleteThemeMutation) => nullToUndefined(data.deleteTheme),
    complete: identity
  });

  function fireWith(id: number): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = Theme.cacheId(id);

      const theme = apolloClient.cache.readFragment<GraphQL.ThemeFragment>({
        id: cacheId,
        fragment: GraphQL.ThemeFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: Theme.fragmentName
      });

      if (theme) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.ThemeFragmentDoc,
          fragmentName: Theme.fragmentName,
          data: { ...theme, id: -1 }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
