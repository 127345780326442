import { None, Option } from "../utils/monads/option";
import { List } from "immutable";
import { GraphQL } from "../services/graphql/generated";

export interface BlueprintError {
  type: string;
  message: Option<string>;
}

export namespace BlueprintError {
  export const NoServerStateAvailable = build("NO_SERVER_STATE_AVAILABLE");

  export function shouldHighlight(errors: List<BlueprintError>): boolean {
    return !!errors.find((error) => error.type !== NoServerStateAvailable.type);
  }

  export function build(type: string, message: Option<string> = None()): BlueprintError {
    return { type, message };
  }

  export function isEqual(a: BlueprintError, b: BlueprintError): boolean {
    return a.type  === b.type && a.message.toJS() === b.message.toJS();
  }

  export function reduce(errors: List<BlueprintError>, error: BlueprintError): List<BlueprintError> {
    const existing = errors.find((existingError) => isEqual(existingError, error));
    return existing ? errors : errors.push(error);
  }

  export function reduceList(errors: List<BlueprintError>): List<BlueprintError> {
    return errors.reduce(reduce, List());
  }

  export function fromGraphQL(blueprintError: GraphQL.BlueprintError): BlueprintError {
    return build(blueprintError.type, Option.mayBe(blueprintError.message));
  }

  export function fromGraphQLList(blueprintErrors: GraphQL.BlueprintError[]): List<BlueprintError> {
    return List(blueprintErrors.map(fromGraphQL));
  }

  export function areEqual(a: List<BlueprintError>, b: List<BlueprintError>): boolean {
    const aSet = a
      .map((error) => error.type + error.message.map((message) => ": " + message).getOrElse(() => ""))
      .toSet();
    const bSet = b
      .map((error) => error.type + error.message.map((message) => ": " + message).getOrElse(() => ""))
      .toSet();
    return aSet.equals(bSet);
  }
}
