import { ItemTypes } from "./types/itemTypes";
import { None, Option } from "../utils/monads/option";
import { CollectableItemBreakdown } from "../types/collectables/collectableItemBreakdown";
import { CollectableInteger } from "../types/collectables/collectableInteger";
import { List, Set } from "immutable";

export interface MigrationStream {
  itemTypes: ItemTypes;
  totalBytes: Option<CollectableInteger>;
  totalItems: Option<CollectableItemBreakdown>;
  maxPathLength: Option<CollectableInteger>;
}

export namespace MigrationStream {
  export const empty: MigrationStream = {
    itemTypes: Set(),
    totalBytes: None(),
    totalItems: None(),
    maxPathLength: None()
  };

  export function reduce(values: List<MigrationStream>): MigrationStream {
    return {
      itemTypes: values.map((value) => value.itemTypes).reduce((a, b) => a.merge(b), Set()),
      totalBytes: CollectableInteger.SumOpt.reduce(values.map((value) => value.totalBytes)),
      totalItems: CollectableItemBreakdown.MergeOpt.reduce(values.map((value) => value.totalItems)),
      maxPathLength: CollectableInteger.MaxOpt.reduce(values.map((value) => value.maxPathLength))
    };
  }
}
