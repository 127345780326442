import * as React from "react";
import { OperationStatus } from "../../../../types/operationStatus";
import { Button } from "../../../widgets/button";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Panel } from "../../../containers/panel";
import { TextBox } from "views/widgets/textBox";
import { friendlyPrice } from "../../../../utils/formatting";
import { Paragraph } from "views/widgets/paragraph";

interface Props {
  currentAmount: number | undefined;
  status: OperationStatus<any>;
  onSubmit: (amount: number) => Promise<any>;
}

export const IncrementSponsorshipLimitAmountRemainingTool: React.FunctionComponent<Props> = (props) => {
  const textBoxRef = React.createRef<HTMLInputElement>();
  const [amount, setAmount] = React.useState("");
  const [error, setError] = React.useState(false);

  const disabled = props.status.isWorking();

  React.useEffect(
    () => {
      if (!disabled && textBoxRef.current) {
        // For some reason we have to memoize the value before setting the timer, otherwise it's not always available
        const textArea = textBoxRef.current;
        window.setTimeout(
          () => {
            textArea.focus();
          },
          0
        );
      }
    },
    [disabled, textBoxRef.current]
  );

  function submit() {
    if (/^\-?\d+(\.\d{1,2})?$/.test(amount)) {
      const parsedAmount = Number.parseFloat(amount);
      if (parsedAmount) {
        setError(false);
        setAmount("");
        props.onSubmit(parsedAmount);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  }

  return (
    <Panel>
      <OperationStatusIndicator
        status={props.status}
        progressMessage={"Increasing sponsorship limit amount remaining..."}
        failureMessage={"Failed to increase sponsorship limit amount remaining"}
        successMessage={"Sponsorship limit amount remaining increased successfully"}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow>
        <Paragraph>
          Sponsorship limit amount remaining:{" "}
          <strong>{props.currentAmount !== undefined ? friendlyPrice(props.currentAmount) : "(not set)"}</strong>
        </Paragraph>
        <Paragraph>
          Type the amount to be added (can be negative to subtract):
        </Paragraph>
      </PanelRow>
      <PanelRow>
        <TextBox
          ref={textBoxRef}
          value={amount}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (/^\-?[\.\d]*$/.test(event.target.value)) {
              setAmount(event.target.value);
            }
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.charCode === 13) {
              submit();
              event.preventDefault();
            }
          }}
          disabled={disabled}
          maxLength={13}
          error={error}
        />
      </PanelRow>
      <PanelRow>
        <Button onClick={submit} disabled={disabled}>Add</Button>
      </PanelRow>
    </Panel>
  );
};
