import { ActionLike } from "../actionLike";

export enum ToursActionType {
  CompletedInfoButtonsTour = "tours/completedInfoButtonsTour",
}

export interface CompletedInfoButtonsTourAction extends ActionLike<ToursActionType.CompletedInfoButtonsTour> {
}

export function completedInfoButtonsTourAction(): CompletedInfoButtonsTourAction {
  return {
    type: ToursActionType.CompletedInfoButtonsTour
  };
}

export type ToursAction =
  CompletedInfoButtonsTourAction;
