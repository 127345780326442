import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Fact } from "../../types/facts/fact";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";

type PutFactMutationHook = [
  (value: string) => Promise<Fact>,
  OperationStatus<Fact>,
  () => void
];

export function usePutFactMutation(fact: Fact): PutFactMutationHook {
  const [putFact, { status, reset }] = useManagedMutation({
    mutation: GraphQL.usePutFactMutation,
    extract: (data: GraphQL.PutFactMutation) => nullToUndefined(data.putFact),
    complete: Fact.fromGraphQL,
    catch: [
      [
        ErrorClass.InvalidFactValueException,
        (error) => UserFacingError.expected(error, {
          summary: "You have provided an invalid JSON value for a fact of type " + fact.valueType,
          recommendations: "Please check your input and try again",
          showTechnicalDetails: true
        })
      ]
    ]
  });

  function execute(value: string): Promise<Fact> {
    return putFact({
      variables: {
        subjectType: fact.subjectType,
        subjectId: fact.subjectId,
        factId: fact.id,
        family: fact.family,
        valueType: fact.valueType,
        valueJson: value
      }
    });
  }

  return [execute, status, reset];
}
