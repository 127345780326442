import { WorkStatus } from "../models/workStatus";
import * as React from "react";
import { Spinner, SpinnerBackgroundColor } from "../glyphs/spinner";
import { CheckedCircle } from "../glyphs/checkedCircle";
import { styled } from "../../app/theme";
import { InfoButtonDefaultSize } from "./infoButton";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const Container = styled.div<{ large: boolean }>`
  display: inline-block;
  width: ${(props) => props.large ? 3 : InfoButtonDefaultSize}rem;
  height: ${(props) => props.large ? 3 : InfoButtonDefaultSize}rem;
  margin: ${(props) => props.large ? "-.5rem " + (-(3 - InfoButtonDefaultSize) / 2) + "rem" : "0"};
  position: relative;
`;

interface ProgressIndicatorProps extends StyledComponentsProps {
  status: WorkStatus;
  indicateSlowness: boolean;
  large: boolean;
}

export const ProgressIndicator: React.FunctionComponent<ProgressIndicatorProps> = (props) => {
  function renderBody() {
    const settings = {
      backgroundColor: "gray" as SpinnerBackgroundColor,
      strokeWidthMultiplier: 1.2
    };
    switch (props.status) {
      case WorkStatus.Pending:
        return <Spinner progress={0} {...settings}/>;
      case WorkStatus.Working:
        return <Spinner progress={-1} animationSpeed={props.indicateSlowness ? 0.25 : 1} {...settings}/>;
      case WorkStatus.Issue:
        return <Spinner progress={50} color="red" {...settings}/>;
      case WorkStatus.Failure:
        return <Spinner progress={100} color="red" {...settings}/>;
      case WorkStatus.Success:
        return (
          <CheckedCircle
            hollow={true}
            lineStrokeWidthMultiplier={props.large ? 0.4 : 0.5}
          />
        );
    }
  }

  return <Container large={props.large} className={props.className}>{renderBody()}</Container>;
};
