import * as React from "react";
import { EmailSupportLink } from "../widgets/emailSupportLink";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { UserFacingError } from "../../types/userFacingError";
import { SimpleErrorPanel } from "../containers/simplePanel";

function retry() {
  alert("Trying again");
}

function cancel() {
  alert("Cancelling");
}

export const Utils: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Unexpected Errors</StyleguideHeader>
    <StyleguideBlock>
      <SimpleErrorPanel
        error={UserFacingError.unexpected(Error("This is a simplest possible unexpected error with no customization"))}
      />
      <SimpleErrorPanel
        error={UserFacingError.unexpected(Error("Dummy"), {
          title: "Custom title",
          summary: "Custom description and buttons",
          retry,
          cancel
        })}
      />
    </StyleguideBlock>

    <StyleguideHeader>Expected Error</StyleguideHeader>
    <StyleguideBlock>
      <SimpleErrorPanel
        error={UserFacingError.expected(Error("Dummy"), {
          title: "Something went wrong!",
          summary: "We worked really hard, but failed to deliver.",
        })}
      />
      <SimpleErrorPanel
        error={UserFacingError.expected(Error("Dummy"), {
          title: "Something went wrong!",
          summary: "We worked really hard, but failed to deliver.",
          retry,
          cancel
        })}
      />
    </StyleguideBlock>

    <StyleguideHeader>Support Link</StyleguideHeader>
    <StyleguideBlock>
      <EmailSupportLink subject="Request subject">Please contact support</EmailSupportLink>
    </StyleguideBlock>

  </React.Fragment>
);
