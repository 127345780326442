import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { ManagedQueryHook } from "../../services/graphql/useManagedQuery";
import { RefreshButtonWithTimer, RefreshTimer, useRefreshTimer } from "./refreshTimer";

export interface ManagedQueryRefreshButtonWithTimerProps {
  dataSource: RefreshingDataSource<any, any>;
}

export const RefreshingDataSourceTimer: React.FunctionComponent<ManagedQueryRefreshButtonWithTimerProps> =
  (props) => (
    <RefreshButtonWithTimer
      startedAt={props.dataSource.startedAt}
      timeout={props.dataSource.timeout}
      refreshDisabled={props.dataSource.status.isWorking()}
      onRefresh={props.dataSource.refresh}
    />
  );

export interface RefreshingDataSource<Vars, Result> extends RefreshTimer {
  status: OperationStatus<Result>;
  refreshWith: (variableOverrides?: Partial<Vars>, soft?: boolean) => void;
}

interface Options<Vars> {
  timeout?: number;
  variableOverrides?: Partial<Vars>;
}

export function useRefreshingDataSource<Vars, Result>(
  [status, refresh]: ManagedQueryHook<Vars, Result>,
  options?: Options<Vars>
): RefreshingDataSource<Vars, Result> {
  const refreshFunctionRef = React.useRef<() => void>();
  refreshFunctionRef.current = () => refresh(options?.variableOverrides, true);

  const refreshTimer = useRefreshTimer(
    () => {
      if (refreshFunctionRef.current) {
        refreshFunctionRef.current();
      }
    },
    options?.timeout || 60
  );

  return {
    ...refreshTimer,
    status,
    refreshWith: refresh
  };
}
