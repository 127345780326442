import * as React from "react";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { OperationStatus } from "../../../../types/operationStatus";
import { AccessList } from "../../../../types/models/accessList";
import { Link } from "react-router-dom";
import { Block } from "views/containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { FileUpload, FileUploadController } from "../../../widgets/fileUpload";
import { SimpleFormButtonsBlock } from "../../../widgets/simpleForm";
import { Button } from "../../../widgets/button";
import { AccessListDetailsPanel } from "./accessListDetailsPanel";
import { AccessListUpdate } from "../../../../types/models/accessListUpdate";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { commafy, friendlyDateTime } from "../../../../utils/formatting";
import { PropertiesTable } from "./propertiesTable";
import { AccessListUpdateStatus } from "../../../../types/enums/accessListUpdateStatus";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { LinkButton } from "views/widgets/linkButton";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { styled } from "../../../../app/theme";
import { WarningSign } from "../../../glyphs/warningSign";
import { ListSourceDetails } from "./listSourceDetails";
import {
  GoogleGroupDetailsController
} from "../../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";

interface Props {
  accessListId: number;
  accessListStatus: OperationStatus<AccessList>;
  accessListUpdateStatus: OperationStatus<AccessListUpdate>;

  displayListUpdateInfo: boolean;

  entryCountStatus: OperationStatus<number>;
  onEntryCountRefresh: () => void;

  homePath: string;
  accessListPath: string;

  fileUploadController: FileUploadController;
  onUpload: (response: string) => void;

  googleGroupDetailsController: GoogleGroupDetailsController;
  onImport: () => void;

  onDownloadOutput: (accessListUpdateId: number) => void;
}

export const ImportAccessListPageView: React.FunctionComponent<Props> = (props) => {
  const verb = props.accessListStatus
    .mapLastResult((accessList) => accessList.googleGroupId ? "Re-Sync" : "Import") ||
    "Import / Re-Sync";
  const subject = verb.toLowerCase();

  return (
    <>
      <HeaderBlock
        breadcrumbs={[
          <Link key={0} to={props.homePath}>Access Lists</Link>,
          <Link key={1} to={props.accessListPath}>
            {props.accessListStatus.mapLastResult((accessList) => accessList.name) || props.accessListId}
          </Link>,
          verb + " Entries"
        ]}
        title={verb + " Access List Entries"}
      />
      <AccessListDetailsPanel
        accessListStatus={props.accessListStatus}
        entryCountStatus={props.entryCountStatus}
        onEntryCountRefresh={props.onEntryCountRefresh}
      />
      {props.accessListStatus.mapLastResult((accessList) => (
        <>
          {
            props.displayListUpdateInfo &&
            props.accessListUpdateStatus.mapLastResult((accessListUpdate) => (
              <AccessListUpdateStatusPanel
                subject={subject}
                accessListUpdate={accessListUpdate}
                onDownloadOutput={props.onDownloadOutput}
              />
            ))
          }
          <Block>
            <Panel>
              <ListSourceDetails
                googleGroupId={accessList.googleGroupId}
                lastUpdateStartedAt={accessList.lastUpdateStartedAt}
                googleGroupDetailsController={props.googleGroupDetailsController}
              />
              {
                accessList.googleGroupId
                  ? (
                    <PanelRow>
                      <CSVSpec>
                        Note: This will overwrite the entries with the current members of the Google Group.
                      </CSVSpec>
                      <Button
                        size={"small"}
                        disabled={accessList.currentUpdateId !== undefined}
                        onClick={props.onImport}
                      >
                        Re-Sync Entries Now
                      </Button>
                    </PanelRow>
                  )
                  : (
                    <>
                      <PanelRow>
                        <CSVSpec>
                          Each row in the CSV must contain one to three email addresses from a domain registered to this
                          organization. All email addresses in a row must belong to the same person.
                        </CSVSpec>
                        <FileUpload
                          controller={props.fileUploadController}
                          upload={undefined}
                          disabled={accessList.currentUpdateId !== undefined}
                          onUpload={(result) => props.onUpload(result.response)}
                        />
                      </PanelRow>
                    </>
                  )
              }
            </Panel>
          </Block>
        </>
      ))}
      <SimpleFormButtonsBlock>
        <Link to={props.accessListPath}>
          <Button size={"small"} color={"secondary"}>Back</Button>
        </Link>
      </SimpleFormButtonsBlock>
    </>
  );
};

interface AccessListUpdateStatusPanelProps {
  subject: string;
  accessListUpdate: AccessListUpdate;
  onDownloadOutput: (accessListUpdateId: number) => void;
}

const AccessListUpdateStatusPanel: React.FunctionComponent<AccessListUpdateStatusPanelProps> = (props) => {
  const processed = props.accessListUpdate.addedEntries + props.accessListUpdate.removedEntries;
  const failed = props.accessListUpdate.skippedEntries + props.accessListUpdate.errors;

  function renderStatus(): { status: WidgetStatus, text: string } {
    switch (props.accessListUpdate.status) {
      case AccessListUpdateStatus.Success:
        if (failed === 0) {
          return {
            status: WidgetStatus.Success,
            text: "The " + props.subject + " was completed successfully."
          };
        } else if (processed === 0) {
          return {
            status: WidgetStatus.Error,
            text: "The " + props.subject + " failed."
          };
        } else {
          return {
            status: WidgetStatus.Warning,
            text: "The " + props.subject + " was completed with some errors."
          };
        }

      case AccessListUpdateStatus.Failure:
        return {
          status: WidgetStatus.Error,
          text: "The "  + props.subject + " failed."
        };

      default:
        return {
          status: WidgetStatus.Info,
          text: "An update is currently being performed to this access list. " +
            "This update must be completed before another update can be performed."
        };
    }
  }

  const { status, text } = renderStatus();

  return (
    <Block>
      <Panel>
        {
          props.accessListUpdate.status !== AccessListUpdateStatus.Success &&
          props.accessListUpdate.status !== AccessListUpdateStatus.Failure &&
            <IndefiniteProgressBar/>
        }
        <PanelRow status={status}>
          {text}
          <br/>
          <br/>
          <PropertiesTable>
            <tbody>
            <tr>
              <td>Started:</td>
              <td>{friendlyDateTime(props.accessListUpdate.createdAt)}</td>
            </tr>
            {props.accessListUpdate.completedAt && (
              <tr>
                <td>Completed:</td>
                <td>{friendlyDateTime(props.accessListUpdate.completedAt)}</td>
              </tr>
            )}
            <tr>
              <td>Entries added:</td>
              <td>{commafy(props.accessListUpdate.addedEntries)}</td>
            </tr>
            <tr>
              <td>Entries removed:</td>
              <td>{commafy(props.accessListUpdate.removedEntries)}</td>
            </tr>
            <tr>
              <td>Errors found:</td>
              <td>
                {commafy(failed)}
                {failed !== 0 && (
                  <IconContainer>
                    <WarningSign color={status === WidgetStatus.Error ? "red" : "yellow"}/>
                  </IconContainer>
                )}
              </td>
            </tr>
            {props.accessListUpdate.status === AccessListUpdateStatus.Success && (
              <tr>
                <td>Results (CSV):</td>
                <td>
                  <LinkButton onClick={() => props.onDownloadOutput(props.accessListUpdate.id)}>Download</LinkButton>
                </td>
              </tr>
            )}
            {props.accessListUpdate.status === AccessListUpdateStatus.Failure && (
              <tr>
                <td>Error Message:</td>
                <td>{props.accessListUpdate.errorMessage}</td>
              </tr>
            )}
            </tbody>
          </PropertiesTable>
        </PanelRow>
      </Panel>
    </Block>
  );
};

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: .2rem;
`;

const CSVSpec = styled.div`
  margin-bottom: .5rem;
`;
