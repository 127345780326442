import * as React from "react";
import { styled } from "../../../../app/theme";

export const CarouselTitle = styled.div`
  padding: 1.5rem 0 0;
  font-size: 2.6rem;
  font-weight: ${(props) => props.theme.font.regular};
  text-align: center;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.8rem;
  }
`;
