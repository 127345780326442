import { CouponCode } from "../../types/models/couponCode";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchCouponCodesQuery(
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchCouponCodesQueryVariables, List<CouponCode>> {
  return useManagedQuery({
    query: GraphQL.useSearchCouponCodesQuery,
    deps: term,
    prepare: (deps) => ({ term: deps }),
    extract: (data: GraphQL.SearchCouponCodesQuery) => nullToUndefined(data.searchCouponCodes),
    complete: (couponCodes) => List(couponCodes)
      .filter((couponCode) => !!couponCode.id)
      .map(CouponCode.parse),
    fetchPolicy: "cache-and-network"
  });
}
