import { Option } from "../../utils/monads/option";
import { List } from "immutable";
import { Location } from "react-router";

export abstract class RoutesContext {
  constructor(
    public routeParams: Record<string, string | undefined>,
    public searchParams: URLSearchParams,
    public location: Location
  ) {}

  public abstract resolvePath(path: string): string;

  public renderSearchParams(params: Iterable<[string, string]>): string {
    const searchParams = new URLSearchParams(Array.from(params)).toString();
    return searchParams.length !== 0 ? ("?" + searchParams) : "";
  }

  public renderOptionalSearchParams(params: Iterable<[string, Option<string>]>): string {
    const searchParams = new URLSearchParams(
      List(params)
        .flatMap(([key, mayBeValue]) => mayBeValue.map((value) => [key, value]).toList())
        .toArray()
    ).toString();
    return searchParams.length !== 0 ? ("?" + searchParams) : "";
  }

  public missingRequiredURLParamsError(): Error {
    return new Error("Missing required URL parameters");
  }
}
