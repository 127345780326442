export enum ReferralCodeAnnouncementButtonVersion {
  ColoredBackgroundAndBlackText = "ColoredBackgroundAndBlackText",
  ColoredBackgroundAndWhiteText = "ColoredBackgroundAndWhiteText",
  NoBackgroundAndBlackText = "NoBackgroundAndBlackText",
  NoBackgroundAndColoredText = "NoBackgroundAndColoredText"
}

export namespace ReferralCodeAnnouncementButtonVersionDefs {
  export const Values: string[] = Object.values(ReferralCodeAnnouncementButtonVersion);
}
