import { GraphQL } from "../../services/graphql/generated";

export interface OrganizationFormData {
  readonly alias: string;
  readonly name: string;
  readonly notes: string | undefined;

  readonly themeId: number | undefined;
  readonly logoId: string | undefined;
  readonly domains: string;

  readonly revenueSharingEnabled: boolean;

  readonly activatedAt: Date;
}

export namespace OrganizationFormData {
  export function toGraphQL(data: OrganizationFormData): GraphQL.OrganizationFormData {
    return {
      alias: data.alias,
      name: data.name,
      notes: data.notes,

      themeId: data.themeId,
      logoId: data.logoId,
      domains: data.domains,

      revenueSharingEnabled: data.revenueSharingEnabled,

      activatedAt: data.activatedAt.toISOString()
    };
  }
}
