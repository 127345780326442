import { GraphQL } from "../../services/graphql/generated";
import { AuthProviders } from "./authProviders";
import { CloudServices } from "./cloudServices";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { RoutesHook } from "../../app/routes/useRoutes";
import { ProgramSummary } from "./program";
import { ReferralCodeSummary } from "./referralCode";

export interface AppBootstrapConfig {
  readonly development: boolean;
  readonly production: boolean;
  readonly cloudServices: CloudServices;
  readonly sourceCloudServices: CloudServices;
  readonly destinationCloudServices: CloudServices;
  readonly authProviders: AuthProviders;

  readonly ambassadorsProgram: AppBootstrapConfig.AmbassadorsProgram;

  readonly microsoftAppId: string;
  readonly microsoftSignInButtonCss: string;
  readonly microsoftSignInButtonIcon: string;
  readonly microsoftOAuthCallbackPath: string;

  readonly googleMerchantId: string | undefined;
  readonly enableVenmo: boolean;

  readonly program: ProgramSummary | undefined;
  readonly configuration: AppBootstrapConfig.Configuration | undefined;

  readonly referralCode: ReferralCodeSummary | undefined;

  readonly sourceCloudServiceReference: (cloudServiceId: string) => string;
  readonly destinationCloudServiceReference: (cloudServiceId: string) => string;
}

export namespace AppBootstrapConfig {
  export interface AmbassadorsProgram {
    readonly link: string;
    readonly signUpDiscount: number;
    readonly codeDiscount: number;
    readonly minNumberOfContacts: number;
    readonly maxNumberOfContacts: number;
    readonly contestEntryExpirationMonths: number;
  }

  export interface Configuration {
    readonly headerSuffix: string | undefined;
    readonly mainLogoId: string | undefined;
    readonly escapeLinkText: string | undefined;

    readonly introBannerId: string | undefined;
    readonly introTitle: string | undefined;
    readonly introContent: string | undefined;
    readonly pricingPageBannerId: string | undefined;
    readonly pricingPageTitle: string | undefined;
    readonly pricingPageContent: string | undefined;

    readonly sourceCloudServiceReference: string | undefined;
    readonly destinationCloudServiceReference: string | undefined;

    readonly enableFeedback: boolean;
    readonly feedbackQuestion: string | undefined;
    readonly checkbox1Label: string | undefined;
    readonly checkbox1Required: boolean;
    readonly checkbox2Label: string | undefined;
    readonly checkbox2Required: boolean;
    readonly checkbox3Label: string | undefined;
    readonly checkbox3Required: boolean;

    readonly hasCheckboxes: boolean;

    readonly cloudServices: List<ConfigurationCloudService>;
  }

  export interface ConfigurationCloudService {
    readonly isSource: boolean;
    readonly cloudServiceId: string;

    readonly index: number;
    readonly reference: string | undefined;
    readonly description: string | undefined;
    readonly logoId: string | undefined;
    readonly excludedApps: string | undefined;
  }

  export function parse(data: GraphQL.AppBootstrapConfigFragment, routes: RoutesHook): AppBootstrapConfig {
    const configuration = mapOptional(
      data.configuration,
      (configurationData) => ({
        headerSuffix: nullToUndefined(configurationData.headerSuffix),
        mainLogoId: nullToUndefined(configurationData.mainLogoId),
        escapeLinkText: nullToUndefined(configurationData.escapeLinkText),

        introBannerId: nullToUndefined(configurationData.introBannerId),
        introTitle: nullToUndefined(configurationData.introTitle),
        introContent: nullToUndefined(configurationData.introContent),
        pricingPageBannerId: nullToUndefined(configurationData.pricingPageBannerId),
        pricingPageTitle: nullToUndefined(configurationData.pricingPageTitle),
        pricingPageContent: nullToUndefined(configurationData.pricingPageContent),

        sourceCloudServiceReference: nullToUndefined(configurationData.sourceCloudServiceReference),
        destinationCloudServiceReference: nullToUndefined(configurationData.destinationCloudServiceReference),

        enableFeedback: configurationData.enableFeedback,
        feedbackQuestion: nullToUndefined(configurationData.feedbackQuestion),
        checkbox1Label: nullToUndefined(configurationData.checkbox1Label),
        checkbox1Required: configurationData.checkbox1Required,
        checkbox2Label: nullToUndefined(configurationData.checkbox2Label),
        checkbox2Required: configurationData.checkbox2Required,
        checkbox3Label: nullToUndefined(configurationData.checkbox3Label),
        checkbox3Required: configurationData.checkbox3Required,

        hasCheckboxes: !!configurationData.checkbox1Label ||
          !!configurationData.checkbox2Label ||
          !!configurationData.checkbox3Label,

        cloudServices: List(configurationData.cloudServices)
          .map((cloudServiceData) => ({
            isSource: cloudServiceData.isSource,
            cloudServiceId: cloudServiceData.cloudServiceId,

            index: cloudServiceData.index,
            reference: nullToUndefined(cloudServiceData.reference),
            description: nullToUndefined(cloudServiceData.description),
            logoId: nullToUndefined(cloudServiceData.logoId),
            excludedApps: nullToUndefined(cloudServiceData.excludedApps)
          }))
          .sortBy((item) => item.index)
      })
    );

    const cloudServices = CloudServices.fromGraphQLList(data.cloudServices);
    const sourceCloudServices = configuration
      ? cloudServices.withConfiguration(true, configuration, routes)
      : cloudServices;
    const destinationCloudServices = configuration
      ? cloudServices.withConfiguration(false, configuration, routes)
      : cloudServices;

    return {
      development: data.development,
      production: data.production,
      cloudServices,
      sourceCloudServices,
      destinationCloudServices,
      authProviders: AuthProviders.fromGraphQLList(data.authProviders),

      ambassadorsProgram: {
        link: data.ambassadorsProgram.link,
        signUpDiscount: data.ambassadorsProgram.signUpDiscount,
        codeDiscount: data.ambassadorsProgram.codeDiscount,
        minNumberOfContacts: data.ambassadorsProgram.minNumberOfContacts,
        maxNumberOfContacts: data.ambassadorsProgram.maxNumberOfContacts,
        contestEntryExpirationMonths: data.ambassadorsProgram.contestEntryExpirationMonths
      },

      microsoftAppId: data.microsoftAppId,
      microsoftSignInButtonCss: data.microsoftSignInButtonCss,
      microsoftSignInButtonIcon: data.microsoftSignInButtonIcon,
      microsoftOAuthCallbackPath: data.microsoftOAuthCallbackPath,

      googleMerchantId: nullToUndefined(data.googleMerchantId),
      enableVenmo: data.enableVenmo,

      program: mapOptional(data.program, ProgramSummary.parse),
      configuration,

      referralCode: mapOptional(data.referralCode, ReferralCodeSummary.parse),

      sourceCloudServiceReference: (cloudServiceId) =>
        sourceCloudServices.get(cloudServiceId)?.reference ||
        configuration?.sourceCloudServiceReference ||
        "source account",
      destinationCloudServiceReference: (cloudServiceId) =>
        destinationCloudServices.get(cloudServiceId)?.reference ||
        configuration?.destinationCloudServiceReference ||
        "destination account"
    };
  }
}
