import * as React from "react";
import { ItemDetailsControllerProps } from "../../../views/widgets/lookupField";
import { Configuration } from "../../../types/models/configuration";
import { useGetConfigurationQuery } from "../../../queries/configurations/useGetConfigurationQuery";

export const UseConfigurationDetailsController: React.FunctionComponent<
  ItemDetailsControllerProps<number, Configuration>
  > =
  (props) => {
    const [status] = useGetConfigurationQuery(props.id);
    return props.render({ status });
  };

export function useConfigurationDetailsController() {
  return React.useCallback(
    (controllerProps: ItemDetailsControllerProps<number, Configuration>) =>
      <UseConfigurationDetailsController {...controllerProps}/>,
    []
  );
}
