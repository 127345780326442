import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { JobHistoryRecord } from "./jobHistoryRecord";
import { TaskSummaryRecord } from "./taskSummaryRecord";
import { TaskIssue } from "./taskIssue";

export class JobHistory implements JobHistory.Props {
  constructor(protected readonly props: JobHistory.Props) {
  }

  public get jobId(): string { return this.props.jobId; }
  public get job(): JobHistoryRecord { return this.props.job; }
  public get jobHistory(): List<JobHistoryRecord> { return this.props.jobHistory; }
  public get tasks(): List<TaskSummaryRecord> { return this.props.tasks; }
  public get taskIssues(): List<TaskIssue> { return this.props.taskIssues; }

  public get runningTasksCount(): number {
    return this.tasks.filter((task) => !task.archivedAt).size;
  }

  public get completedTasksCount(): number {
    return this.tasks.size - this.runningTasksCount;
  }

  public get pendingTaskIssuesCount(): number {
    return this.taskIssues.filter((taskIssue) => !taskIssue.resolvedAt).size;
  }

  public get resolvedTaskIssuesCount(): number {
    return this.taskIssues.size - this.pendingTaskIssuesCount;
  }

  public toEvents(): List<JobHistory.Event> {
    return this.jobHistory
      .concat(
        this.tasks
          .map((task) => task.toEvents())
          .reduce((a, b) => a.concat(b), List())
      )
      .concat(
        this.taskIssues
          .map((taskIssue) => taskIssue.toEvents())
          .reduce((a, b) => a.concat(b), List())
      )
      .sortBy((event: JobHistory.Event) => event.timestamp)
      .reverse();
  }
}

export namespace JobHistory {
  export type Event = JobHistoryRecord | TaskSummaryRecord.Event | TaskIssue.Event;

  export interface Props {
    readonly jobId: string;
    readonly job: JobHistoryRecord;
    readonly jobHistory: List<JobHistoryRecord>;
    readonly tasks: List<TaskSummaryRecord>;
    readonly taskIssues: List<TaskIssue>;
  }

  export function fromGraphQL(jobHistory: GraphQL.JobHistory): JobHistory {
    return new JobHistory({
      jobId: jobHistory.jobId,
      job: JobHistoryRecord.fromGraphQL(jobHistory.job),
      jobHistory: List(jobHistory.jobHistory.map(JobHistoryRecord.fromGraphQL)),
      tasks: List(jobHistory.tasks.map(TaskSummaryRecord.fromGraphQL)),
      taskIssues: List(jobHistory.taskIssues.map(TaskIssue.fromGraphQL)),
    });
  }
}
