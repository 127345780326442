import { BlueprintInputs } from "./blueprintInputs";
import { Map } from "immutable";
import { Facts } from "../types/facts/facts";
import { Connections } from "../types/models/connections";
import { AuthProviders } from "../types/models/authProviders";
import { ServerElements } from "./serverElements";
import { CloudServices } from "../types/models/cloudServices";

export class BlueprintContext {
  private cache = Map<string, any>();

  public constructor(public readonly config: BlueprintContext.Config) {
  }

  public get inputs(): BlueprintInputs { return this.config.inputs; }
  public get cloudServices(): CloudServices { return this.config.cloudServices; }
  public get authProviders(): AuthProviders { return this.config.authProviders; }
  public get connections(): Connections { return this.config.connections; }
  public get facts(): Facts { return this.config.facts; }
  public get serverElements(): ServerElements { return this.config.serverElements; }

  public memoize<T>(key: string, value: () => T): T {
    if (this.cache.has(key)) {
      return this.cache.get(key, undefined);
    } else {
      const result = value();
      this.cache = this.cache.set(key, result);
      return result;
    }
  }

  public equals(other: BlueprintContext): boolean {
    return (
      this.inputs.equals(other.inputs) &&
      this.connections.equals(other.connections) &&
      this.facts.equals(other.facts) &&
      this.serverElements.equals(other.serverElements)
    );
  }

  public withServerElements(serverElements: ServerElements): BlueprintContext {
    return new BlueprintContext({ ...this.config, serverElements });
  }
}

export namespace BlueprintContext {
  export interface Config {
    readonly inputs: BlueprintInputs;
    readonly cloudServices: CloudServices;
    readonly authProviders: AuthProviders;
    readonly connections: Connections;
    readonly facts: Facts;
    readonly serverElements: ServerElements;
  }

  export const Empty: BlueprintContext = new BlueprintContext({
    inputs: BlueprintInputs.Empty,
    cloudServices: CloudServices.Empty,
    authProviders: AuthProviders.Empty,
    connections: Connections.Empty,
    facts: Facts.Empty,
    serverElements: ServerElements.Empty
  });
}
