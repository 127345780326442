import * as React from "react";
import { Connection } from "../../types/models/connection";
import { useUserConnections } from "../../queries/useConnections";
import { GraphQL } from "../../services/graphql/generated";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { ConnectionsPanelView } from "../../views/blocks/connectionManagement/connectionsPanelView";

export interface ConnectionsPanelProps {
  userId: string;
  showStatusRow: boolean;
  actionTitle: string | undefined;
  onAction: ((connection: Connection) => void) | undefined;
}

export const ConnectionsPanel: React.FunctionComponent<ConnectionsPanelProps> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const [connectionsStatus, refreshConnections] = useUserConnections({
    userId: props.userId,
    accessKeyCheck: GraphQL.AccessKeyCheck.Quick
  });

  const [refreshing, setRefreshing] = React.useState(false);

  return (
    <ConnectionsPanelView
      cloudServices={cloudServices}
      authProviders={authProviders}

      showStatusRow={props.showStatusRow}
      status={connectionsStatus}
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refreshConnections({ accessKeyCheck: GraphQL.AccessKeyCheck.Full });
      }}

      actionTitle={props.actionTitle}
      onAction={props.onAction}
    />
  );
};
