import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { nullToUndefined } from "../../utils/misc";

export interface PricingModel {
  readonly id: number;
  readonly name: string;
  readonly basePriceDescription: string;
  readonly notes: string | undefined;

  readonly basePrice: number;
  readonly basePriceForFollowUpMigrations: number | undefined;
  readonly numberOfGbsIncluded: number;
  readonly numberOfItemsIncluded: number;
  readonly pricePerGb: number;
  readonly pricePerThousandItems: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace PricingModel {
  export function parse(data: GraphQL.PricingModelFragment): PricingModel {
    return {
      id: data.id,
      name: data.name,
      basePriceDescription: data.basePriceDescription,
      notes: nullToUndefined(data.notes),

      basePrice: data.basePrice,
      basePriceForFollowUpMigrations: nullToUndefined(data.basePriceForFollowUpMigrations),
      numberOfGbsIncluded: data.numberOfGbsIncluded,
      numberOfItemsIncluded: data.numberOfItemsIncluded,
      pricePerGb: data.pricePerGb,
      pricePerThousandItems: data.pricePerThousandItems,

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(pricingModelId: number): string {
    return cachedObjectId("PricingModel", pricingModelId.toString());
  }

  export const fragmentName = "PricingModel";
}
