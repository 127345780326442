import { GraphQL } from "../../services/graphql/generated";
import { DiscountType } from "./discountType";
import { ProgramStatus } from "./programStatus";

export interface ProgramFormData {
  readonly id: string;
  readonly alias: string | undefined;
  readonly name: string;
  readonly description: string | undefined;
  readonly productName: string | undefined;
  readonly landingPageUrl: string | undefined;
  readonly logoId: string | undefined;
  readonly notes: string | undefined;
  readonly endUserAccess: string | undefined;

  readonly configurationId: number | undefined;
  readonly introBannerId: string | undefined;
  readonly introTitle: string | undefined;
  readonly introContent: string | undefined;
  readonly pricingPageBannerId: string | undefined;
  readonly pricingPageTitle: string | undefined;
  readonly pricingPageContent: string | undefined;

  readonly pricingModelId: number | undefined;
  readonly sendMigrationNotificationsToAdmins: boolean;
  readonly sendFeedbackNotificationsToAdmins: boolean;

  readonly status: ProgramStatus;

  readonly sourceCloudServiceId: string | undefined;
  readonly destinationCloudServiceId: string | undefined;
  readonly sourceDomains: string | undefined;
  readonly destinationDomains: string | undefined;

  readonly discount: number;
  readonly discountType: DiscountType;
  readonly sponsoredAmount: number;
  readonly revenueShare: number;
}

export namespace ProgramFormData {
  export function toGraphQL(data: ProgramFormData): GraphQL.ProgramFormData {
    return {
      id: data.id,
      alias: data.alias,
      name: data.name,
      description: data.description,
      productName: data.productName,
      landingPageUrl: data.landingPageUrl,
      logoId: data.logoId,
      notes: data.notes,
      endUserAccess: data.endUserAccess,

      configurationId: data.configurationId,
      carousel: {
        introBannerId: data.introBannerId,
        introTitle: data.introTitle,
        introContent: data.introContent,
        pricingPageBannerId: data.pricingPageBannerId,
        pricingPageTitle: data.pricingPageTitle,
        pricingPageContent: data.pricingPageContent,
      },

      pricingModelId: data.pricingModelId,
      sendMigrationNotificationsToAdmins: data.sendMigrationNotificationsToAdmins,
      sendFeedbackNotificationsToAdmins: data.sendFeedbackNotificationsToAdmins,

      status: data.status,

      sourceCloudServiceId: data.sourceCloudServiceId,
      destinationCloudServiceId: data.destinationCloudServiceId,
      sourceDomains: data.sourceDomains,
      destinationDomains: data.destinationDomains,

      pricing: {
        discount: data.discount,
        discountType: data.discountType,
        sponsoredAmount: data.sponsoredAmount,
        revenueShare: data.revenueShare
      }
    };
  }
}
