import { CollectableInteger } from "../types/collectables/collectableInteger";
import { CollectableItemBreakdown } from "../types/collectables/collectableItemBreakdown";
import { NamedRegistry } from "./registry";
import { AnyReducer } from "./reducer";
import { ItemTypes } from "./types/itemTypes";

export const ReducerRegistry = NamedRegistry.fromArrays<AnyReducer>(
  "reducer",
  CollectableInteger.Reducers,
  CollectableItemBreakdown.Reducers,
  ItemTypes.Reducers,
);
