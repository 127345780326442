import * as React from "react";
import { ManagedQueryHook } from "../services/graphql/useManagedQuery";
import { OperationStatus } from "../types/operationStatus";

export interface ControllerProps<ViewProps> {
  render: (viewProps: ViewProps) => React.ReactElement | null;
}

export type Controller<ViewProps> = React.ComponentType<ControllerProps<ViewProps>>;

interface ControllerHostProps<ViewProps> {
  controller: Controller<ViewProps>;
  render: (viewProps: ViewProps) => React.ReactElement | null | undefined;
}

export function ControllerHost<ViewProps>(props: ControllerHostProps<ViewProps>): React.ReactElement {
  return React.createElement(props.controller, {
    render: (viewProps) => {
      const result = props.render(viewProps);
      return result === undefined ? null : result;
    }
  });
}

export interface ManagedQueryViewProps<Result> {
  status: OperationStatus<Result>;
  onRefresh: () => void;
}

export interface ManagedQueryControllerProps<Result> extends ControllerProps<ManagedQueryViewProps<Result>> {
  query: ManagedQueryHook<any, Result>;
}

function ManagedQueryController<Result>(props: ManagedQueryControllerProps<Result>) {
  return props.render({
    status: props.query[0],
    onRefresh: props.query[1]
  });
}

export function useManagedQueryController<Result>(query: ManagedQueryHook<any, Result>) {
  return React.useCallback(
    (controllerProps: ControllerProps<ManagedQueryViewProps<Result>>) =>
      <ManagedQueryController query={query} {...controllerProps}/>,
    [query]
  );
}
