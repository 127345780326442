import * as React from "react";
import * as yup from "yup";
import { CouponCode } from "../../../types/models/couponCode";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { friendlyDateTime } from "../../../utils/formatting";
import { CheckboxField } from "../../widgets/checkboxField";
import { SimplePanel } from "../../containers/simplePanel";
import { Random } from "../../../utils/random";
import { CouponCodeFormData } from "../../../types/models/couponCodeFormData";
import { FormConfig } from "../../blocks/crud/formConfig";
import { ItemForm } from "../../blocks/crud/itemForm";
import { DropDownBox } from "../../widgets/dropDownBox";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../app/routes/useRoutes";

interface FormData {
  id: string;
  notes: string;
  enabled: boolean;

  discount: string;
  isPercentage: boolean;
  remainingUses: string;
}

namespace FormData {
  export function makeBlank(): FormData {
    return {
      id: new Random().alphanumeric(10).toUpperCase(),
      notes: "",
      enabled: true,

      discount: "0.00",
      isPercentage: false,
      remainingUses: "1"
    };
  }

  export function make(couponCode: CouponCode): FormData {
    return {
      id: couponCode.id.toUpperCase(),
      notes: couponCode.notes || "",
      enabled: couponCode.enabled,

      discount: couponCode.isPercentage ? couponCode.discount.toString() : couponCode.discount.toFixed(2),
      isPercentage: couponCode.isPercentage,
      remainingUses: couponCode.remainingUses !== undefined ? couponCode.remainingUses.toString() : ""
    };
  }

  export function toResult(formData: FormData): CouponCodeFormData {
    const trimmedNotes = formData.notes.trim();
    return {
      id: formData.id.toUpperCase(),
      notes: trimmedNotes.length !== 0 ? trimmedNotes : undefined,
      enabled: formData.enabled,

      discount: Number.parseFloat(formData.discount),
      isPercentage: formData.isPercentage,
      remainingUses: formData.remainingUses !== undefined && formData.remainingUses !== ""
        ? Number(formData.remainingUses)
        : undefined
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    id: yup.string().required("Alphanumeric coupon code ID is required"),
    notes: yup.string(),
    enabled: yup.boolean(),

    discount: yup.string().required("Discount value is required").test(
      "maxDigitsAfterDecimal",
      "Discount value must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    isPercentage: yup.boolean(),
    remainingUses: yup.string().test(
      "integer",
      "Number of uses must be an empty string or an integer",
      (amount) => amount === undefined || amount === "" || /^\d+$/.test(amount)
    )
  });
}

interface Props {
  config: FormConfig<CouponCode, CouponCodeFormData>;
}

export const CouponCodeForm: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  return (
    <ItemForm<CouponCode, FormData, CouponCodeFormData>
      config={props.config}

      subject={"coupon code"}

      blankFormData={FormData.makeBlank()}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, formProps, submitting }) => (
        <>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextField<FormData>
                label={"Code"}
                name="id"
                required={true}
                maxLength={64}
                disabled={!props.config.isNew || submitting}
                textBoxRef={props.config.isNew ? firstInputRef as React.RefObject<HTMLInputElement> : undefined}
              />
              <TextAreaField<FormData>
                label={"Internal notes"}
                name="notes"
                disabled={submitting}
                textBoxRef={props.config.isNew ? undefined : firstInputRef as React.RefObject<HTMLTextAreaElement>}
              />
              <CheckboxField
                label={"Enabled"}
                name={"enabled"}
                disabled={submitting}
              />
              <TextField<FormData>
                label={"Discount"}
                name="discount"
                required={true}
                maxLength={10}
                autoComplete={false}
                disabled={submitting}
                dirtyRegExp={/^[\.\d]*$/}
                appendix={
                  <DropDownBox
                    value={formProps.values.isPercentage ? "true" : "false"}
                    onChange={(event) => formProps.setFieldValue("isPercentage", event.target.value === "true")}
                    disabled={submitting}
                  >
                    <option value={"false"}>USD</option>
                    <option value={"true"}>%</option>
                  </DropDownBox>
                }
              />
              <TextField<FormData>
                label={"Remaining uses (blank for unlimited)"}
                name="remainingUses"
                disabled={submitting}
                maxLength={10}
                autoComplete={false}
                dirtyRegExp={/^\d*$/}
              />
              {props.config.item && !props.config.isNew && (
                <>
                  <FormField>
                    Last used for:{" "}
                    {
                      props.config.item.lastUsedFor
                        ? (
                          <Link to={routes.migrations.migrationStatusPath(props.config.item.lastUsedFor)}>
                            {props.config.item.lastUsedFor}
                          </Link>
                        )
                        : "(never used)"
                    }
                  </FormField>
                  <FormField>Created at: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                </>
              )}
            </FormLayout>
          </SimplePanel>
        </>
      )}
    </ItemForm>
  );
};
