import * as React from "react";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Button } from "../../../widgets/button";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Migration } from "../../../../types/models/migration";
import { OperationStatus } from "../../../../types/operationStatus";
import { GraphQL } from "../../../../services/graphql/generated";

interface Props {
  migration: Migration;
  status: OperationStatus<GraphQL.JobStatusTransitionSchedulingResult>;
  onLaunch: () => Promise<GraphQL.JobStatusTransitionSchedulingResult>;
}

export const IterationLaunchTool: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow>
      VaultMe will identify updates and new items in your source account and will sync the changes with the
      destination account.
    </PanelRow>
    <PanelRow>
      <Button onClick={props.onLaunch} disabled={props.status.isWorking()}>
        Sync Up Now
      </Button>
    </PanelRow>
    <OperationStatusIndicator
      progressMessage={"Starting sync-up migration..."}
      successMessage={(result) => "Started sync-up migration (" + result + ")..."}
      failureMessage={"Failed to start sync-up migration"}
      status={props.status}
      indicators={StatusIndicators.PanelRow()}
    />
  </Panel>
);
