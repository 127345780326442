import * as React from "react";
import { useRoutes } from "../../../app/routes/useRoutes";
import { OffboardingProject } from "../../../types/models/offboardingProject";
import { CrudPages } from "../../../components/crud/crudPages";
import { friendlyDate, friendlyDateTime } from "../../../utils/formatting";
import {
  useSearchOffboardingProjectsQuery
} from "../../../queries/automatedOffboarding/useSearchOffboardingProjectsQuery";
import {
  useDeleteOffboardingProjectMutation
} from "../../../queries/automatedOffboarding/useDeleteOffboardingProjectMutation";
import {
  useUpdateOffboardingProjectMutation
} from "../../../queries/automatedOffboarding/useUpdateOffboardingProjectMutation";
import {
  useInsertOffboardingProjectMutation
} from "../../../queries/automatedOffboarding/useInsertOffboardingProjectMutation";
import { useGetOffboardingProjectQuery } from "../../../queries/automatedOffboarding/useGetOffboardingProjectQuery";
import {
  OffboardingProjectForm
} from "../../../views/screens/organizationPortal/automatedOffboarding/offboardingProjectForm";
import { OffboardingProjectFormData } from "../../../types/models/offboardingProjectFormData";
import { CRUDConfig } from "../../../components/crud/crudConfig";
import { OrganizationPortalUserRole } from "../../../types/enums/organizationPortalUserRole";
import { OrganizationContext } from "types/models/organizationContext";
import { OperationStatus } from "../../../types/operationStatus";
import { WidgetStatus } from "../../../views/utils/widgetStatus";
import {
  OffboardingProjectOverview
} from "../../../views/screens/organizationPortal/automatedOffboarding/offboardingProjectOverview";
import {
  useGetEmailDeliverySchedulesQuery
} from "../../../queries/automatedOffboarding/useGetEmailDeliverySchedulesQuery";
import { EmailDeliverySchedule } from "../../../types/models/emailDeliverySchedule";
import { List } from "immutable";
import { useAdvanceToNextEmailDeliveryRoundController } from "./useAdvanceToNextEmailDeliveryRoundController";
import { useRestartCurrentEmailDeliveryRoundController } from "./useRestartCurrentEmailDeliveryRoundController";
import { useForceStartEmailDeliveryRoundController } from "./useForceStartEmailDeliveryRoundController";
import { useBrowser } from "../../../utils/useBrowser";
import {
  useGetOffboardingProjectEntryCountQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectEntryCountQuery";
import {
  useListOffboardingProjectStatsQuery
} from "../../../queries/automatedOffboarding/useListOffboardingProjectStatsQuery";
import { useRenderOffboardingEmailPreviewController } from "./useRenderOffboardingEmailPreviewController";
import { useSendTestOffboardingProjectEmailController } from "./useSendTestOffboardingProjectEmailController";
import {
  ConnectEmailDeliveryAccountController
} from "../../../controllers/organizationPortal/automatedOffboarding/connectEmailDeliveryAccountController";
import { useConnectEmailDeliveryAccountController } from "./useConnectEmailDeliveryAccountController";
import { useDisableEmailDeliveryController } from "./useDisableEmailDeliveryController";
import { EmailDeliveryErrorType } from "../../../types/enums/emailDeliveryErrorType";
import { ProgramLookupControllerType, ProgramViewPathFactory } from "../../../views/blocks/lookups/programLookupField";
import { useProgramLookupController } from "../../../components/lookup/programs/useProgramLookupController";
import { useProgramViewPathFactory } from "../../../components/lookup/programs/useProgramViewPathFactory";
import { useProgramDetailsController } from "../../../components/lookup/programs/useProgramDetailsController";
import {
  useGetCurrentOffboardingProjectUpdateQuery
} from "../../../queries/automatedOffboarding/useGetCurrentOffboardingProjectUpdateQuery";

const DefaultSubjectLine = "Your student account will be deleted in {remaining_days}";

const DefaultBody =
  "Hi {first_name}!\n" +
  "\n" +
  "Your student account will be deleted in {remaining_days}. " +
  "Follow the link below to copy the content of your student account to a personal account:\n" +
  "\n" +
  "{vaultme_url}\n" +
  "\n" +
  "Best regards,  \n" +
  "Your Alumni Association\n\n" +
  "If you don't want to receive these notifications, click the following link: {opt_out_url}\n";

interface OffboardingProjectAdminPagesProps extends Pick<
  CRUDConfig<string, OffboardingProject, OffboardingProjectFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  readOnly: boolean;

  emailDeliverySchedulesStatus: OperationStatus<List<EmailDeliverySchedule>>;

  programLookupController: ProgramLookupControllerType;
  programViewPathFactory: ProgramViewPathFactory;
  connectEmailDeliveryAccountController: ConnectEmailDeliveryAccountController;

  makeEditPath: (id: string) => string;
  makeImportEntriesPath: (id: string) => string;
  makeImportHistoryPath: (id: string) => string;
  makeDeleteEntriesPath: (id: string) => string;
}

export const OffboardingProjectAdminPages
  : React.FunctionComponent<OffboardingProjectAdminPagesProps> =
  (props) => {
    const programDetailsController = useProgramDetailsController();

    return CrudPages<string, OffboardingProject, OffboardingProjectFormData>({
      title: "End-User Offboarding",
      subject: "offboarding project",
      readOnly: props.readOnly,
      startFrom: props.startFrom,
      layout: props.layout,
      gridColumns: [
        {
          title: "Name",
          render: (offboardingProject) => offboardingProject.name
        },
        {
          title: "Email Delivery Account",
          render: (offboardingProject) => ({
            content: offboardingProject.connection ? offboardingProject.connection.description : "(Disabled)",
            status: offboardingProject.connection ? WidgetStatus.Normal : WidgetStatus.Uncertain
          })
        },
        {
          title: "End-User Program",
          render: (offboardingProject) => ({
            content: offboardingProject.program ? offboardingProject.program.name : "(Not assigned)",
            status: offboardingProject.program ? WidgetStatus.Normal : WidgetStatus.Uncertain
          })
        },
        {
          title: "Cutoff Date",
          render: (offboardingProject) => friendlyDate(offboardingProject.cutoffAt)
        },
        {
          title: "Email Sending Schedule",
          render: (offboardingProject) => offboardingProject.schedule.name
        },
        {
          title: "Status",
          render: (offboardingProject) => {
            if (offboardingProject.isCompleted) {
              return { content: "Completed", status: WidgetStatus.Success };
            } else if (!offboardingProject.connection) {
              return { content: "On hold (email delivery is disabled)", status: WidgetStatus.Warning };
            } else if (offboardingProject.emailDeliveryError?.type === EmailDeliveryErrorType.InvalidAccessKey) {
              return { content: "On hold (action required)", status: WidgetStatus.Error };
            } else {
              return { content: "In progress", status: WidgetStatus.Info };
            }
          }
        },
        {
          title: "Created",
          render: (offboardingProject) => friendlyDateTime(offboardingProject.createdAt)
        },
      ],
      renderOverview: (offboardingProject) => (
        <OffboardingProjectOverviewWrapper
          offboardingProject={offboardingProject}

          readOnly={props.readOnly}

          editPath={props.makeEditPath(offboardingProject.id)}
          importEntriesPath={props.makeImportEntriesPath(offboardingProject.id)}
          importHistoryPath={props.makeImportHistoryPath(offboardingProject.id)}
          deleteEntriesPath={props.makeDeleteEntriesPath(offboardingProject.id)}

          programViewPathFactory={props.programViewPathFactory}
        />
      ),
      renderForm: (config) => (
        <OffboardingProjectForm
          config={config}
          emailDeliverySchedulesStatus={props.emailDeliverySchedulesStatus}

          defaultSubjectLine={DefaultSubjectLine}
          defaultBody={DefaultBody}

          programLookupController={props.programLookupController}
          programDetailsController={programDetailsController}
          programViewPathFactory={props.programViewPathFactory}
          connectEmailDeliveryAccountController={props.connectEmailDeliveryAccountController}
        />
      ),

      useRoutes: props.useRoutes,
      getItemId: (offboardingProject) => offboardingProject.id,
      getItemTitle: (offboardingProject) => offboardingProject.name,
      getItemDescription: (offboardingProject) => offboardingProject.notes,
      clone: (offboardingProject) => ({
        ...offboardingProject,
        name: "Copy of " + offboardingProject.name
      }),
      compare: (a: OffboardingProject, b: OffboardingProject) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      },
      autoRefreshInterval: (offboardingProject) => offboardingProject.isEmailSendingInProgress ? 10 : 60,

      useGetQuery: (id) => {
        const [offboardingProjectStatus, refreshOffboardingProject] = useGetOffboardingProjectQuery(id);
        const [entryCountStatus, refreshEntryCount] = useGetOffboardingProjectEntryCountQuery(id);
        const [statsStatus, refreshStats] = useListOffboardingProjectStatsQuery(id);

        return [
          offboardingProjectStatus,
          (variableOverrides, soft) => {
            refreshOffboardingProject(variableOverrides, soft);
            refreshEntryCount({}, soft);
            refreshStats({}, soft);
          }
        ];
      },
      useSearchQuery: props.useSearchQuery,
      useInsertMutation: props.useInsertMutation,
      useUpdateMutation: () => {
        const [update, updateStatus] = useUpdateOffboardingProjectMutation();
        return [(id, formData) => update(id, formData), updateStatus];
      },
      useDeleteMutation: useDeleteOffboardingProjectMutation
    });
  };

interface OffboardingProjectOverviewWrapperProps {
  offboardingProject: OffboardingProject;

  readOnly: boolean;

  editPath: string;
  importEntriesPath: string;
  importHistoryPath: string;
  deleteEntriesPath: string;

  programViewPathFactory: ProgramViewPathFactory;
}

const OffboardingProjectOverviewWrapper: React.FunctionComponent<OffboardingProjectOverviewWrapperProps> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  const [entryCountStatus, refreshEntryCount] = useGetOffboardingProjectEntryCountQuery(props.offboardingProject.id);
  const [statsStatus] = useListOffboardingProjectStatsQuery(props.offboardingProject.id);

  const [currentOffboardingProjectUpdateStatus, refreshCurrentOffboardingProjectUpdate] =
    useGetCurrentOffboardingProjectUpdateQuery(props.offboardingProject.id);

  const currentUpdateId = currentOffboardingProjectUpdateStatus.someResult()?.currentUpdateId;

  React.useEffect(
    () => {
      if (currentUpdateId !== undefined) {
        const timer = setInterval(() => refreshCurrentOffboardingProjectUpdate({}, true), 5000);
        return () => clearInterval(timer);
      }
    },
    [currentUpdateId]
  );  

  const disableEmailDeliveryController =
    useDisableEmailDeliveryController(props.offboardingProject.id);
  const renderOffboardingEmailPreviewController =
    useRenderOffboardingEmailPreviewController(props.offboardingProject.id);
  const sendTestOffboardingProjectEmailController =
    useSendTestOffboardingProjectEmailController(props.offboardingProject.id);
  const forceStartEmailDeliveryRoundController =
    useForceStartEmailDeliveryRoundController(props.offboardingProject.id);
  const restartCurrentEmailDeliveryRoundController =
    useRestartCurrentEmailDeliveryRoundController(props.offboardingProject.id);
  const advanceToNextEmailDeliveryRoundController =
    useAdvanceToNextEmailDeliveryRoundController(props.offboardingProject.id);

  return (
    <OffboardingProjectOverview
      offboardingProject={props.offboardingProject}

      editPath={props.editPath}
      importEntriesPath={props.importEntriesPath}
      importHistoryPath={props.importHistoryPath}
      deleteEntriesPath={props.deleteEntriesPath}

      programViewPathFactory={props.programViewPathFactory}

      entryCountStatus={entryCountStatus}
      onEntryCountRefresh={refreshEntryCount}

      statsStatus={statsStatus}

      disableUpdates={currentUpdateId !== undefined}
      readOnly={props.readOnly}

      disableEmailDeliveryController={disableEmailDeliveryController}
      renderOffboardingEmailPreviewController={renderOffboardingEmailPreviewController}
      sendTestOffboardingProjectEmailController={sendTestOffboardingProjectEmailController}
      forceStartEmailDeliveryRoundController={forceStartEmailDeliveryRoundController}
      advanceToNextEmailDeliveryRoundController={advanceToNextEmailDeliveryRoundController}
      restartCurrentEmailDeliveryRoundController={restartCurrentEmailDeliveryRoundController}

      onExportItems={() =>
        browser.openNewTab(routes.api.offboardingProjectImportExportUrl(props.offboardingProject.id), true)
      }
    />
  );
};

interface OrganizationOffboardingProjectAdminPagesProps {
  organizationContext: OrganizationContext;
}

export const OrganizationOffboardingProjectAdminPages
  : React.FunctionComponent<OrganizationOffboardingProjectAdminPagesProps> =
  (props) => {
    const organizationAlias = props.organizationContext.organization.alias;
    const organizationId = props.organizationContext.organization.id;

    const [emailDeliverySchedulesStatus] = useGetEmailDeliverySchedulesQuery();

    const programLookupController = useProgramLookupController(organizationId);
    const programViewPathFactory = useProgramViewPathFactory(organizationId);
    const connectEmailDeliveryAccountController = useConnectEmailDeliveryAccountController(organizationId);

    const routes = useRoutes().organizationPortalRoutes.automatedOffboarding(organizationAlias);

    return (
      <OffboardingProjectAdminPages
        readOnly={
          (
            !!props.organizationContext.organizationPortalUser &&
            !OrganizationPortalUserRole.implies(
              props.organizationContext.organizationPortalUser.role,
              OrganizationPortalUserRole.ReadAndWriteAccess
            )
          )
        }

        startFrom={"list"}
        layout={"compact"}

        useRoutes={() => useRoutes().organizationPortalRoutes.automatedOffboarding(organizationAlias)}
        useSearchQuery={(term) => useSearchOffboardingProjectsQuery(organizationId, term)}
        useInsertMutation={() => useInsertOffboardingProjectMutation(organizationId)}

        emailDeliverySchedulesStatus={emailDeliverySchedulesStatus}

        programLookupController={programLookupController}
        programViewPathFactory={programViewPathFactory}
        connectEmailDeliveryAccountController={connectEmailDeliveryAccountController}

        makeEditPath={(id) => routes.editPath(id)}
        makeImportEntriesPath={(id) => routes.importPath(id)}
        makeImportHistoryPath={(id) => routes.importHistoryPath(id)}
        makeDeleteEntriesPath={(id) => routes.deleteEntriesPath(id)}
      />
    );
  };
