import * as React from "react";
import { styled } from "../../app/theme";
import { multiline } from "../../utils/formatting";
import { BlueprintContext } from "../blueprintContext";
import { AnyComponent } from "../component";
import { ComponentVisualization } from "../componentVisualization";

const Layout = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: ${(props) => props.clickable ? "pointer" : "auto"};
  margin: -0.3rem;
  padding: 0.3rem;
  border-radius: 3px;
  
  &:hover {
    background: ${(props) => props.clickable ? props.theme.colors.faintPrimary : "auto"};
  }
`;

const Arrow: React.SFC = () => (
  <svg viewBox="-30 -15 40 29">
    <line
      x1="-30"
      y1="0"
      x2="0"
      y2="0"
      stroke="#666666"
      strokeWidth="1"
      // strokeDasharray="4"
      shapeRendering="crispEdges"
    />
    <path d="M0,4 L0,-5 L10,0 z" fill="#666666" />
  </svg>
);

const ArrowAndIcon = styled.div<{ reverseArrow?: boolean }>`
  display: flex;
  align-items: center;
  
  > svg {
    width: 2rem;
  }
`;

const IconContainer = styled.div<{ backgroundColor: string | undefined, borderColor: string }>`
  border-radius: 50%;
  border: 1px solid ${(props) => props.borderColor};
  background: ${(props) => props.backgroundColor || "white"};
  width: 2rem;
  height: 2rem;
  padding: .35rem;
  box-sizing: border-box;
  
  > img {
    width: 100%;
    height: 100%;
  }
`;

const DetailsContainer = styled.div`
  padding: 0.15rem 0 0 .5rem;
`;

const Title = styled.div<{ borderColor: string }>`
  font-size: 1.3rem;
  font-weight: normal;
  color: ${(props) => props.borderColor};
  word-wrap: break-word;
  word-break: break-all;
`;

const Description = styled.div<{ borderColor: string }>`
  font-size: 0.8rem;
  color: ${(props) => props.borderColor};
  padding-left: .1rem;
  word-wrap: break-word;
  word-break: break-all;
`;

interface ComponentSummaryProps {
  component: AnyComponent;
  blueprintContext: BlueprintContext;
  showOutput?: boolean;
  showArrow?: boolean;
  reverseArrow?: boolean;
  onComponentSelect?: (componentId: string) => void;
}

export class ComponentSummary extends React.Component<ComponentSummaryProps> {
  constructor(props: ComponentSummaryProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  public render() {
    const state = this.props.component.state(this.props.blueprintContext);
    const visualization = this.props.component.visualization(this.props.blueprintContext, state);
    const colorSet = ComponentVisualization.colorSchema(visualization, this.props.component.settings);
    const icon = ComponentVisualization.icon(visualization, this.props.component.settings);

    const summary = visualization.summary &&
      <Description borderColor={colorSet.border}>{multiline(visualization.summary)}</Description>;

    return (
      <Layout clickable={this.props.onComponentSelect !== undefined} onClick={this.handleClick}>
        <ArrowAndIcon reverseArrow={this.props.reverseArrow}>
          {this.props.showArrow && !this.props.reverseArrow && <Arrow/>}
          <IconContainer backgroundColor={colorSet.background} borderColor={colorSet.border}>
            <img src={icon}/>
          </IconContainer>
          {this.props.showArrow && this.props.reverseArrow && <Arrow/>}
        </ArrowAndIcon>
        <DetailsContainer>
          <Title borderColor={colorSet.border}>{visualization.title}</Title>
          {summary}
        </DetailsContainer>
      </Layout>
    );
  }

  protected handleClick() {
    if (this.props.onComponentSelect) {
      this.props.onComponentSelect(this.props.component.id);
    }
  }
}
