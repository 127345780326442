import { Program } from "./program";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional } from "../../utils/misc";

export interface EmailAddressWhitelistingForProgram {
  readonly emailAddress: string;
  readonly program: Program | undefined;
  readonly sortKey: string;
}

export namespace EmailAddressWhitelistingForProgram {
  export function parse(data: GraphQL.EmailAddressWhitelistingForProgramFragment): EmailAddressWhitelistingForProgram {
    const emailAddressParts = data.emailAddress.split("@");
    return {
      emailAddress: data.emailAddress,
      sortKey: (emailAddressParts[emailAddressParts.length - 1] + "@" + emailAddressParts[0]).toLowerCase(),
      program: mapOptional(data.program, Program.parse)
    };
  }
}
