import * as React from "react";
import { styled } from "../../app/theme";

export const Row = styled.div`
  & ~ & {
    margin-top: .5rem;
    
    ${(props) => props.theme.responsive.respondToXSmall()} {
      margin-top: .4rem;
    }
  }
`;
