import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { Component, ComponentHubs } from "../component";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentBinding } from "./componentBinding";
import { Option } from "../../utils/monads/option";
import { MultipleRelationshipsHub } from "../hubs/multipleRelationshipsHub";
import { CollectableItemBreakdown } from "../../types/collectables/collectableItemBreakdown";
import { CollectableInteger } from "../../types/collectables/collectableInteger";
import { parseServerJson } from "../parseServerJson";
import { FactRef } from "../../types/facts/factRef";
import { AssociationsHub } from "../hubs/associationsHub";
import { List } from "immutable";
import { FactHelper } from "../factHelper";

export class TimeEstimateComp
  extends Component<TimeEstimateComp.Props, TimeEstimateComp.Hubs, TimeEstimateComp.Output> {

  public stateWhenUnblocked(context: BlueprintContext): State<TimeEstimateComp.Output> {
    return State.blocked();
  }

  public visualization(): ComponentVisualization {
    return {
      title: "Time Estimate",
      summary: this.props.title,
      icon: Images.Blueprint.Clock,
      color: ComponentColorSchema.SolidBlue,
    };
  }

  public unblockedFactIds(context: BlueprintContext): List<string> {
    return FactHelper.resolvedFactRef(context, this, this.props.estimatedTimeFactRef)
      .map(((factRef) => factRef.id))
      .toList();
  }

  public estimatedTimeFact(context: BlueprintContext) {
    return FactHelper.getCollectable<number, number, CollectableInteger>(
      context,
      this,
      this.props.estimatedTimeFactRef
    );
  }
}

export namespace TimeEstimateComp {
  export interface Props {
    title: string;
    connector: string;
    serviceProvider: Option<string>;
    fallback: string;
    estimatedTimeFactRef: FactRef.Props;
    elapsedTimeFactRef: FactRef.Props;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.TimeEstimateCompProps): Props {
      return {
        title: props.title,
        connector: props.connector,
        serviceProvider: Option.mayBe(props.serviceProvider),
        fallback: props.fallback,
        estimatedTimeFactRef: FactRef.Props.fromGraphQL(props.estimatedTimeFactRef),
        elapsedTimeFactRef: FactRef.Props.fromGraphQL(props.elapsedTimeFactRef)
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    totalItems: MultipleRelationshipsHub<Option<CollectableItemBreakdown>>;
    totalBytes: MultipleRelationshipsHub<Option<CollectableInteger>>;
    migrationProgresses: AssociationsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.TimeEstimateCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        totalBytes: binding.multipleRelationshipsHub("totalBytes", hubs.totalBytes),
        totalItems: binding.multipleRelationshipsHub("totalItems", hubs.totalItems),
        migrationProgresses: binding.associationsHub("migrationProgresses", hubs.migrationProgresses),
      };
    }
  }

  export interface Output {
    totalBytes: Option<CollectableInteger>;
    totalItems: Option<CollectableItemBreakdown>;
    params: any;
    fallbackEstimate: Option<number>;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.TimeEstimateCompOutputFragment): Output {
      return {
        totalBytes: Option.mayBe(output.totalBytesCollectable).map(CollectableInteger.fromGraphQL),
        totalItems: Option.mayBe(output.totalItemsCollectable).map(CollectableItemBreakdown.fromGraphQL),
        params: output.params && parseServerJson(output.params),
        fallbackEstimate: Option.mayBe(output.fallbackEstimate)
      };
    }
  }
}
