import { Organization } from "../../types/models/organization";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchOrganizationsQuery(
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchOrganizationsQueryVariables, List<Organization>> {
  return useManagedQuery({
    query: GraphQL.useSearchOrganizationsQuery,
    deps: term,
    prepare: (deps) => ({ term: deps }),
    extract: (data: GraphQL.SearchOrganizationsQuery) => nullToUndefined(data.searchOrganizations),
    complete: (organizations) => List(organizations)
      .filter((organization) => organization.id >= 0)
      .map(Organization.parse),
    fetchPolicy: "cache-and-network"
  });
}
