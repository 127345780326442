import * as React from "react";
import {
  DisconnectControllerProps, DisconnectControllerType
} from "../../views/screens/migrationStatusPageView/components/migrationToolsPanel";
import { Connections } from "../../types/models/connections";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type DisconnectHook = [() => Promise<Connections>, OperationStatus<Connections>];

export function useDisconnect(): DisconnectHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useRevokeAllAccessKeysMutation,
    extract: (data: GraphQL.RevokeAllAccessKeysMutation) => nullToUndefined(data.revokeAllAccessKeys),
    complete: (connections) => Connections.fromGraphQLList(connections)
  });

  function fireWith(): Promise<Connections> {
    return fire({
      variables: {},
      retry: fireWith,
    });
  }

  return [fireWith, status];
}

interface Props extends DisconnectControllerProps {
}

export const DisconnectController: React.FunctionComponent<Props> = (props) => {
  const [disconnect, status] = useDisconnect();

  return props.render({
    status,
    onDisconnect: disconnect
  });
};

export function useDisconnectController(): DisconnectControllerType {
  return React.useCallback(
    (controllerProps: DisconnectControllerProps) => <DisconnectController {...controllerProps}/>,
    []
  );
}
