/* tslint:disable:no-var-requires */

import { RenderedAssets } from "../views/glyphs/renderedAssets";
import { RawImages } from "./rawImages";

export const Images = {
  Carousel: {
    A: require("../../assets/images/carousel/carousel-a.png"),
    B: require("../../assets/images/carousel/carousel-b.png"),
    C: require("../../assets/images/carousel/carousel-c.png"),
    D: require("../../assets/images/carousel/carousel-d.png"),
    E: require("../../assets/images/carousel/carousel-e.png"),
    F: require("../../assets/images/carousel/carousel-f.png"),
  },

  MissingIcon: require("../../assets/images/dummy.svg"),
  ZenGuy: require("../../assets/images/zen-guy.svg"),

  Blueprint: {
    Bolt: require("../../assets/images/blueprint/bolt.svg"),
    CheckMark: require("../../assets/images/blueprint/check-mark.svg"),
    Clock: require("../../assets/images/blueprint/clock.svg"),
    Cross: require("../../assets/images/blueprint/cross.svg"),
    Digits: require("../../assets/images/blueprint/digits.svg"),
    ExclamationMark: require("../../assets/images/blueprint/exclamation-mark.svg"),
    Key: require("../../assets/images/blueprint/key.svg"),
    Lock: require("../../assets/images/blueprint/lock.svg"),
    NoIcon: require("../../assets/images/blueprint/no-icon.svg"),
    Progress: require("../../assets/images/blueprint/progress.svg"),
    Socket: require("../../assets/images/blueprint/socket.svg"),
    Variable: require("../../assets/images/blueprint/variable.svg"),
  },

  Jobs: {
    IssueReported: require("../../assets/images/jobs/issue-reported.svg"),
    IssueResolved: require("../../assets/images/jobs/issue-resolved.svg"),
    Job: require("../../assets/images/jobs/job.svg"),
    TaskAbort: require("../../assets/images/jobs/task-abort.svg"),
    TaskContinued: require("../../assets/images/jobs/task-continued.svg"),
    TaskFailure: require("../../assets/images/jobs/task-failure.svg"),
    TaskSuccess: require("../../assets/images/jobs/task-success.svg"),
    TaskStarted: require("../../assets/images/jobs/task-started.svg"),
  }
};

export function resolveImage(src: any, placeholder?: any): any {
  if (typeof src === "string") {
    if (src.startsWith("data:image/svg+xml;")) {
      return src;
    } else if (RenderedAssets.isRenderedAsset(src)) {
      return RenderedAssets.decode(src) || placeholder;
    } else {
      return RawImages[src] || placeholder;
    }
  } else {
    return src === undefined ? placeholder : src;
  }
}
