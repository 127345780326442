import * as React from "react";
import { LookupControllerType } from "../lookupModal";
import { PricingModel } from "../../../types/models/pricingModel";
import { ItemDetailsControllerType, LookupField } from "../../widgets/lookupField";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useBrowser } from "../../../utils/useBrowser";

export type PricingModelLookupControllerType = LookupControllerType<number, PricingModel>;

export type PricingModelDetailsControllerType = ItemDetailsControllerType<number, PricingModel>;

interface Props<V> {
  name: keyof V;

  lookupController: PricingModelLookupControllerType;
  detailsController: PricingModelDetailsControllerType;

  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export function PricingModelLookupField<V>(props: Props<V>): React.ReactElement {
  const browser = useBrowser();
  const routes = useRoutes();

  return (
    <LookupField<V, number, PricingModel>
      label={props.label || "Pricing config"}
      name={props.name}
      subject={"pricing config"}
      gridColumns={[
        {
          title: "Name",
          render: (pricingModel) => pricingModel.name
        }
      ]}

      lookupController={props.lookupController}
      itemDetailsController={props.detailsController}
      renderItem={(pricingModel) => <>{pricingModel.name}</>}

      required={props.required}
      disabled={props.disabled}

      onView={(pricingModelId) => browser.openNewTab(routes.pricingModels.editPath(pricingModelId))}
    />
  );
}
