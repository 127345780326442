import * as React from "react";
import { SignInFlowView, SignInFlowViewProps } from "../auth/signInFlowView";
import { DrawerBlock } from "../../containers/drawerBlock";

export const AccessKeyUpgradeToolView: React.FunctionComponent<SignInFlowViewProps> = (props) => {
  return (
    <>
      <DrawerBlock>
        Add the following roles to this connection:
      </DrawerBlock>
      <DrawerBlock>
        <SignInFlowView {...props}/>
      </DrawerBlock>
    </>
  );
};
