import * as React from "react";
import { List } from "immutable";
import { User } from "../../../types/models/user";
import { Grid } from "../../widgets/grid";

interface UsersTableProps {
  users: List<User>;
  onSelect: (user: User) => void;
}

export const UsersGrid: React.SFC<UsersTableProps> = (props) => {
  return (
    <Grid selectable={true}>
      <Grid.Header>
        <Grid.Column>User Id</Grid.Column>
        <Grid.Column>User Name</Grid.Column>
        <Grid.Column>Email</Grid.Column>
      </Grid.Header>
      <Grid.Body>
      {
        props.users.map((user) => (
         <Grid.Row key={user.id} onClick={() => props.onSelect(user)}>
           <Grid.Cell>{user.id}</Grid.Cell>
           <Grid.Cell>{user.personalInfo.fullName}</Grid.Cell>
           <Grid.Cell>{user.personalInfo.emailAddress}</Grid.Cell>
         </Grid.Row>
        ))
      }
      </Grid.Body>
    </Grid>
  );
};
