import { BlueprintFactRef } from "./blueprintFactRef";
import { FactRef } from "../types/facts/factRef";
import { Tracer } from "./tracer";
import { BlueprintFactRefFactory } from "./blueprintFactRefFactory";
import { Collectable } from "../types/collectables/collectable";
import { Option } from "../utils/monads/option";

export class ConnectionFactRef<P, C, T extends Collectable<P, C>>
  extends BlueprintFactRef<P, C, T, ConnectionFactRef.Context> {

  public makeContext(ctx: BlueprintFactRef.Context): Option<ConnectionFactRef.Context> {
    return new Tracer(ctx.component, ctx.blueprintContext).connectionId().map((connectionId) => ({ connectionId }));
  }
}

export namespace ConnectionFactRef {
  export interface Context extends FactRef.Context {
    connectionId: string;
  }

  export const Factory: BlueprintFactRefFactory = {
    type: "ConnectionFactRef",
    create<P, C, T extends Collectable<P, C>>(family: string): ConnectionFactRef<P, C, T> {
      return new ConnectionFactRef(family);
    }
  };
}
