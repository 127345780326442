import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyRelationship } from "../relationship";
import { PropertiesTable, PropertiesTableContainer } from "./propertiesTable";
import { ServerLabel } from "./serverLabel";
import { ElementOutput } from "./elementOutput";
import { BlueprintDiff } from "../blueprintDiff";
import { ElementStatus } from "./elementStatus";
import { ElementErrors } from "./elementErrors";

interface ComponentRelationshipProps {
  relationship: AnyRelationship;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  showOutput?: boolean;
  noMargin?: boolean;
}

export const RelationshipPropertiesTable: React.SFC<ComponentRelationshipProps> = (props) => {
  const state = props.relationship.state(props.blueprintContext);
  const resolvedByServer = props.relationship.resolvedByServer(props.blueprintContext);
  const diff = props.diffs.getRelationshipDiff(
    props.relationship.sourceComponentId,
    props.relationship.hubName,
    props.relationship.componentId,
  );
  const output = props.showOutput && (
    <tr>
      <th>Output:</th>
      <td>
        <ElementOutput section={false} state={state} resolvedByServer={resolvedByServer} diff={diff}/>
      </td>
    </tr>
  );
  return (
    <PropertiesTableContainer noMargin={props.noMargin}>
      <PropertiesTable>
        <tbody>
        <tr>
          <th>Rel. Type:</th>
          <td>{props.relationship.type}</td>
        </tr>
        <tr>
          <th>Resolvable At:</th>
          <td>
            {props.relationship.serverSide && <ServerLabel/>}
            {props.relationship.serverSide ? "Server only" : "Client and server"}
          </td>
        </tr>
        <tr>
          <th>Status:</th>
          <td>
            <ElementStatus state={state} resolvedByServer={resolvedByServer} diff={diff}/>
          </td>
        </tr>
        <tr>
          <th>Errors:</th>
          <td>
            <ElementErrors state={state} resolvedByServer={resolvedByServer} diff={diff}/>
          </td>
        </tr>
        {output}
        </tbody>
      </PropertiesTable>
    </PropertiesTableContainer>
  );
};
