import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

// The result is wrapped in an object to avoid confusion with undefined values
export function useGetCurrentOffboardingProjectUpdateQuery(
  id: string | undefined
): ManagedQueryHook<GraphQL.GetCurrentOffboardingProjectUpdateQueryVariables, { currentUpdateId: string | undefined }> {
  return useManagedQuery({
    query: GraphQL.useGetCurrentOffboardingProjectUpdateQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetCurrentOffboardingProjectUpdateQuery) => nullToUndefined(data.offboardingProject),
    complete: (offboardingProject) => ({ currentUpdateId: nullToUndefined(offboardingProject.currentUpdateId) })
  });
}
