import * as yup from "yup";

export interface CheckboxesState {
  checkbox1Label: string | undefined;
  checkbox1Checked: boolean;
  checkbox2Label: string | undefined;
  checkbox2Checked: boolean;
  checkbox3Label: string | undefined;
  checkbox3Checked: boolean;
}

export namespace CheckboxesState {
  export const dummy: CheckboxesState = {
    checkbox1Label: undefined,
    checkbox1Checked: false,
    checkbox2Label: undefined,
    checkbox2Checked: false,
    checkbox3Label: undefined,
    checkbox3Checked: false
  };

  export const schema = yup.object<CheckboxesState>({
    checkbox1Label: yup.string().notRequired(),
    checkbox1Checked: yup.boolean(),
    checkbox2Label: yup.string().notRequired(),
    checkbox2Checked: yup.boolean(),
    checkbox3Label: yup.string().notRequired(),
    checkbox3Checked: yup.boolean()
  });
}
