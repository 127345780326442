import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProjectUpdate } from "../../types/models/offboardingProjectUpdate";
import { List } from "immutable";

export function useGetOffboardingProjectUpdateHistoryQuery(
  id: string
): ManagedQueryHook<GraphQL.GetOffboardingProjectUpdateHistoryQueryVariables, List<OffboardingProjectUpdate>> {
  return useManagedQuery({
    query: GraphQL.useGetOffboardingProjectUpdateHistoryQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetOffboardingProjectUpdateHistoryQuery) =>
      nullToUndefined(data.offboardingProjectUpdateHistory),
    complete: (offboardingProjectUpdates) => List(offboardingProjectUpdates)
      .map(OffboardingProjectUpdate.parse)
      .sortBy((update) => -update.createdAt)
  });
}
