import * as React from "react";
import { Route, Routes } from "react-router";
import { AdminPortalSection } from "../adminPortalSection";
import { AdminPortalSectionId } from "../../../views/screens/organizationPortal/adminPortalNav";
import { OrganizationAccessListAdminCrudPages } from "./accessListAdminCrudPages";
import { AccessListsRoutes as _AccessListsRoutes } from "../../../app/routes/accessListsRoutes";
import { ImportAccessListPage } from "./importAccessListPage";
import { DeleteAccessListEntriesPage } from "./deleteAccessListEntriesPage";
import { ImportHistoryPage } from "./importHistoryPage";

export const AccessListsRoutes: React.FunctionComponent = () => (
  <Routes>
    <Route
      path={"/*"}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AccessLists}>
          {(context) => <OrganizationAccessListAdminCrudPages organizationContext={context}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AccessListsRoutes.importMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AccessLists}>
          {(context) => <ImportAccessListPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AccessListsRoutes.importHistoryMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AccessLists}>
          {(context) => <ImportHistoryPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AccessListsRoutes.deleteEntriesMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AccessLists}>
          {(context) => <DeleteAccessListEntriesPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
  </Routes>
);
