import * as React from "react";
import { OperationStatusIndicatorProps } from "../../utils/operationStatusIndicator";
import { Set } from "immutable";
import { OneTimeAuthCodeSummary } from "../../../types/models/oneTimeAuthCodeSummary";
import { PreparedHelpArticle } from "../../../utils/preparedHelpArticle";

export namespace SignInDefs {
  export interface SignInComponentProps {
    layoutComponent: SignInDefs.SignInLayoutComponentType;
    verb: string;
    cloudServiceId: string;
    authProviderId: string;
    roles: Set<string>;
    onSignIn: SignInHandle;
  }

  export type SignInComponentType = React.ComponentType<SignInComponentProps>;

  export type SignInHandle = (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;

  export interface SignInHelpContent {
    intro: React.ReactNode | undefined;
    inline: React.ReactNode | undefined;
    inlineHelpArticle: PreparedHelpArticle | undefined;
    footer: React.ReactNode | undefined;
    howToSwitchAccount: PreparedHelpArticle | undefined;
  }

  export namespace SignInHelpContent {
    export const empty: SignInHelpContent = {
      intro: undefined,
      inline: undefined,
      inlineHelpArticle: undefined,
      footer: undefined,
      howToSwitchAccount: undefined
    };
  }

  export interface SignInLayoutProps {
    operationStatusIndicator?: Omit<OperationStatusIndicatorProps<any>, "indicators">;
    signInForm: React.ReactNode;
    helpContent?: SignInHelpContent;
  }

  export type SignInLayoutComponentType = React.ComponentType<SignInLayoutProps>;
}
