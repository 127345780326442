import marked from "marked";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { MigrationNoteType } from "./migrationNoteType";
import { DataProxy } from "apollo-cache";
import { CacheIdGenerator } from "../../services/graphql/cacheIdGenerator";
import { MigrationNoteList } from "./migrationNoteList";

export class MigrationNote implements MigrationNote.Props {
  public readonly renderedMessage: string;
  public readonly renderedMessageSnippetWithAuthor: string;

  constructor(protected readonly props: MigrationNote.Props) {
    this.renderedMessage = marked(props.message);
    this.renderedMessageSnippetWithAuthor = marked(
      "[" + props.createdBy + "] " + (
        props.message.length > MigrationNote.DashboardSnippetLength
          ? (
            props.message.substring(0, MigrationNote.DashboardSnippetLength) +
            "... (+" + (props.message.length - MigrationNote.DashboardSnippetLength) + " chars)"
          )
          : props.message
      )
    );
  }

  public get id(): number { return this.props.id; }
  public get migrationId(): string { return this.props.migrationId; }

  public get type(): MigrationNoteType { return this.props.type; }
  public get active(): boolean { return this.props.active; }
  public get message(): string { return this.props.message; }

  public get createdBy(): string { return this.props.createdBy; }
  public get resolvedBy(): string | undefined | undefined { return this.props.resolvedBy; }

  public get createdAt(): Date { return this.props.createdAt; }
  public get updatedAt(): Date { return this.props.updatedAt; }
  public get resolvedAt(): Date | undefined { return this.props.resolvedAt; }

  public get isImportant(): boolean {
    return this.type !== MigrationNoteType.Info && this.active;
  }

  public get priority(): number {
    switch (this.props.type) {
      case MigrationNoteType.Issue: return 0;
      case MigrationNoteType.Warning: return 1;
      case MigrationNoteType.Info: return 2;
    }
  }
}

export namespace MigrationNote {
  export const DashboardSnippetLength = 200;

  export interface Props {
    id: number;
    migrationId: string;

    type: MigrationNoteType;
    active: boolean;
    message: string;

    createdBy: string;
    resolvedBy: string | undefined;

    createdAt: Date;
    updatedAt: Date;
    resolvedAt: Date | undefined;
  }

  const TypeName = "MigrationNote";

  export const cacheId = CacheIdGenerator.simple<GraphQL.MigrationNote, { migrationId: string, id: number }>(
    TypeName,
    ({ migrationId, id }) => [migrationId, id]
  );

  export function fromGraphQL(migrationNote: GraphQL.MigrationNoteFragment): MigrationNote {
    return new MigrationNote({
      id: migrationNote.id,
      migrationId: migrationNote.migrationId,

      type: migrationNote.type,
      active: migrationNote.active,
      message: migrationNote.message,

      createdBy: migrationNote.createdBy,
      resolvedBy: nullToUndefined(migrationNote.resolvedBy),

      createdAt: new Date(migrationNote.createdAt),
      updatedAt: new Date(migrationNote.updatedAt),
      resolvedAt: mapOptional(migrationNote.resolvedAt, (value) => new Date(value))
    });
  }

  export function cache(dataProxy: DataProxy, migrationNotes: Iterable<GraphQL.MigrationNoteFragment>): void {
    for (const migrationNote of migrationNotes) {
      MigrationNoteList.cacheMigrationNote(dataProxy, migrationNote);
    }
  }
}
