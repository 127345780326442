import * as React from "react";
import { Grid } from "../../../../widgets/grid";
import { List } from "immutable";
import { CloudService } from "../../../../../types/models/cloudService";
import { styled } from "../../../../../app/theme";
import { friendlyCount, friendlySize } from "../../../../../utils/formatting";
import { Button } from "../../../../widgets/button";
import { WorkStatus } from "../../../../models/workStatus";
import { ProgressIndicator } from "../../../../widgets/progressIndicator";
import { Connection } from "../../../../../types/models/connection";
import { WidgetStatus } from "../../../../utils/widgetStatus";
import {
  CloudServiceIconWithText,
} from "../../../../blocks/connectionManagement/cloudServiceNameAndIcon";
import { ModalController, useModal } from "../../../../layouts/modal";
import { Panel } from "../../../../containers/panel";
import { PanelRow } from "../../../../containers/rows/panelRow";

const ConnectionAndStatus = styled.div`
  display: flex;
  align-items: center;
`;

const SmallStatusIndicator = styled(ProgressIndicator)`
  width: 1.4rem;
  height: 1.4rem;
  margin: -.2rem .5rem -.2rem 0;
`;

const Buttons = styled.div`
  display: flex;
  
  > *:not(:first-child) {
    margin-left: .3rem;
  }
`;

export interface BatchMigrationItemsGridRow {
  sourceConnection: Connection | undefined;
  destinationConnection: Connection | undefined;

  sourceStatus: WorkStatus;
  destinationStatus: WorkStatus;

  totalSize: number | undefined;
  totalItems: number | undefined;
}

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  items: List<BatchMigrationItemsGridRow>;

  onEditRow: (index: number) => void;
  onRemoveRow: (index: number) => void;
}

export const BatchMigrationItemsGrid: React.FunctionComponent<Props> = (props) => (
  <Grid>
    <Grid.Header>
      <Grid.Column>
        <CloudServiceIconWithText cloudServiceId={props.sourceCloudService.id}>
          Source Account
        </CloudServiceIconWithText>
      </Grid.Column>
      <Grid.Column>
        <CloudServiceIconWithText cloudServiceId={props.destinationCloudService.id}>
          Destination Account
        </CloudServiceIconWithText>
      </Grid.Column>
      <Grid.Column>Size</Grid.Column>
      <Grid.Column>Status</Grid.Column>
      <Grid.Column/>
    </Grid.Header>
    <Grid.Body>
      {
        props.items.map((item, index) => (
          <Row
            key={index}
            item={item}
            onEditRow={() => props.onEditRow(index)}
            onRemoveRow={() => props.onRemoveRow(index)}
          />
        ))
      }
    </Grid.Body>
  </Grid>
);

interface RowProps {
  item: BatchMigrationItemsGridRow;
  onEditRow: () => void;
  onRemoveRow: () => void;
}

const Row: React.FunctionComponent<RowProps> = (props) => {
  const modal = useModal();

  const item = props.item;

  function itemStatus(): [string, WidgetStatus] {
    if (item.sourceStatus === WorkStatus.Failure || item.destinationStatus === WorkStatus.Failure) {
      return ["Error (action required)", WidgetStatus.Error];
    } else if (item.sourceStatus === WorkStatus.Issue || item.destinationStatus === WorkStatus.Issue) {
      return ["Found issues (action required)", WidgetStatus.Error];
    } else if (!item.sourceConnection || !item.destinationConnection) {
      return ["Pending", WidgetStatus.Normal];
    } else if (item.sourceStatus === WorkStatus.Working || item.destinationStatus === WorkStatus.Working) {
      return ["Scanning", WidgetStatus.Normal];
    } else {
      return ["Ready", WidgetStatus.Success];
    }
  }

  return (
    <>
      <ModalController
        modal={modal}
        title={"Remove"}
        render={(close) => (
          <Panel>
            <PanelRow>
              Would you really like to remove this row?
            </PanelRow>
            <PanelRow>
              <Button
                onClick={() => {
                  props.onRemoveRow();
                  close();
                }}
              >
                Remove
              </Button>
            </PanelRow>
          </Panel>
        )}
      />
      <Grid.Row>
        <Grid.Cell>
          {
            item.sourceConnection
              ? (
                <ConnectionAndStatus>
                  <SmallStatusIndicator status={item.sourceStatus} indicateSlowness={false} large={false}/>
                  {item.sourceConnection.descriptionOrId()}
                </ConnectionAndStatus>
              )
              : "--"
          }
        </Grid.Cell>
        <Grid.Cell>
          {
            item.destinationConnection
              ? (
                <ConnectionAndStatus>
                  <SmallStatusIndicator status={item.destinationStatus} indicateSlowness={false} large={false}/>
                  {item.destinationConnection.descriptionOrId()}
                </ConnectionAndStatus>
              )
              : "--"
          }
        </Grid.Cell>
        <Grid.Cell>
          {
            item.sourceConnection && item.destinationConnection &&
            item.totalItems !== undefined && item.totalSize !== undefined
              ? friendlyCount(item.totalItems, "item") + " / " + friendlySize(item.totalSize)
              : "--"
          }
        </Grid.Cell>
        <Grid.Cell status={itemStatus()[1]}>{itemStatus()[0]}</Grid.Cell>
        <Grid.ActionsCell>
          <Buttons>
            <Button color={"white"} size={"small"} onClick={props.onEditRow}>Details</Button>
            <Button color={"white"} size={"small"} onClick={modal.open}>Remove</Button>
          </Buttons>
        </Grid.ActionsCell>
      </Grid.Row>
    </>
  );
};
