import { OrganizationSummary } from "../../types/models/organization";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type ClearSponsorshipLimitAmountRemainingMutationHook = [
  () => Promise<OrganizationSummary>,
  OperationStatus<OrganizationSummary>
];

export function useClearSponsorshipLimitAmountRemainingMutation(
  organizationId: number
): ClearSponsorshipLimitAmountRemainingMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useClearSponsorshipLimitAmountRemainingMutation,
    extract: (data: GraphQL.ClearSponsorshipLimitAmountRemainingMutation) =>
      nullToUndefined(data.clearSponsorshipLimitAmountRemaining),
    complete: OrganizationSummary.parse
  });

  function fireWith(): Promise<OrganizationSummary> {
    return fire({
      variables: { organizationId },
      retry: () => fireWith(),
    });
  }

  return [fireWith, status];
}
