import { GraphQL } from "../../services/graphql/generated";
import { mapOptional } from "../../utils/misc";
import { MigrationStatus } from "./migrationStatus";
import { Migration } from "./migration";
import { List } from "immutable";
import { BatchOrder } from "./batchOrder";

export interface Batch {
  readonly id: string;
  readonly userId: string;

  readonly sourceCloudServiceId: string;
  readonly destinationCloudServiceId: string;

  readonly name: string;
  readonly status: MigrationStatus;

  readonly batchOrder: BatchOrder;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly startedAt: Date | undefined;
  readonly completedAt: Date | undefined;

  readonly jobId: string;
}

export namespace Batch {
  export function parse(data: GraphQL.BatchFragment): Batch {
    return {
      id: data.id,
      userId: data.userId,

      sourceCloudServiceId: data.sourceCloudServiceId,
      destinationCloudServiceId: data.destinationCloudServiceId,

      name: data.name,
      status: data.status,

      batchOrder: BatchOrder.parse(data.batchOrder),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      startedAt: mapOptional(data.startedAt, (value) => new Date(value)),
      completedAt: mapOptional(data.completedAt, (value) => new Date(value)),

      jobId: data.jobId
    };
  }

  export interface HasMigrations {
    migrations: List<Migration & Migration.HasConnections>;
  }

  export namespace HasMigrations {
    export function parseMigrations(data: Pick<GraphQL.BatchMigrationsFragment, "migrations">): HasMigrations {
      return {
        migrations: List(
          data.migrations.map((migration) => ({
            ...Migration.parseCore(migration),
            ...Migration.HasConnections.parse(migration)
          }))
        )
      };
    }
  }
}
