import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { AmbassadorStatus } from "../../types/models/ambassadorStatus";

export function useGetAmbassadorStatusQuery(
  userId: string
): ManagedQueryHook<GraphQL.GetAmbassadorStatusQueryVariables, AmbassadorStatus> {
  return useManagedQuery({
    query: GraphQL.useGetAmbassadorStatusQuery,
    deps: null,
    prepare: () => ({ userId }),
    extract: (data: GraphQL.GetAmbassadorStatusQuery) => nullToUndefined(data.ambassadorStatus),
    complete: AmbassadorStatus.parse,
    fetchPolicy: "network-only"
  });
}
