import * as React from "react";
import * as yup from "yup";
import * as Color from "color";
import { Theme } from "../../../types/models/theme";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { friendlyDateTime } from "../../../utils/formatting";
import { CheckboxField } from "../../widgets/checkboxField";
import { SimplePanel } from "../../containers/simplePanel";
import { ItemForm } from "../../blocks/crud/itemForm";
import { FormConfig } from "../../blocks/crud/formConfig";
import { ThemeFormData } from "../../../types/models/themeFormData";
import { AppTheme, defaultAppTheme, styled } from "../../../app/theme";
import { ThemeOverrides } from "../../../types/models/themeOverrides";
import { ThemeProvider } from "styled-components";
import { Button } from "views/widgets/button";
import { NewTabLink } from "../../widgets/newTabLink";
import { TextBox } from "../../widgets/textBox";
import { Checkbox } from "../../widgets/checkbox";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { BackButton } from "../../widgets/backButton";
import { FormErrorMessage } from "../../widgets/formErrorMessage";
import { useConfigurationRoutes } from "../../../app/routes/useRoutes";
import { ViewOnlyWarningPanel } from "./viewOnlyWarningPanel";
import { ColorSample } from "views/widgets/colorSample";

interface FormData {
  readonly name: string;
  readonly notes: string;

  readonly primary: string;
  readonly darkPrimary: string;
  readonly darkerPrimary: string;
  readonly lightPrimary: string;
  readonly faintPrimary: string;

  readonly red: string;
  readonly lightRed: string;

  readonly yellow: string;
  readonly lightYellow: string;

  readonly orange: string;
  readonly lightOrange: string;

  readonly green: string;
  readonly lightGreen: string;

  readonly titleColor: string;
  readonly textColor: string;

  readonly useMonochromeLogo: boolean;
}

namespace FormData {
  export const blank: FormData = {
    name: "",
    notes: "",

    primary: "#000000",
    darkPrimary: "",
    darkerPrimary: "",
    lightPrimary: "",
    faintPrimary: "",

    red: "",
    lightRed: "",

    yellow: "",
    lightYellow: "",

    orange: "",
    lightOrange: "",

    green: "",
    lightGreen: "",

    titleColor: "",
    textColor: "",

    useMonochromeLogo: true
  };

  export function make(theme: Theme): FormData {
    return {
      name: theme.name,
      notes: theme.notes || "",

      primary: theme.primary,
      darkPrimary: theme.darkPrimary || "",
      darkerPrimary: theme.darkerPrimary || "",
      lightPrimary: theme.lightPrimary || "",
      faintPrimary: theme.faintPrimary || "",

      red: theme.red || "",
      lightRed: theme.lightRed || "",

      yellow: theme.yellow || "",
      lightYellow: theme.lightYellow || "",

      orange: theme.orange || "",
      lightOrange: theme.lightOrange || "",

      green: theme.green || "",
      lightGreen: theme.lightGreen || "",

      titleColor: theme.titleColor || "",
      textColor: theme.textColor || "",

      useMonochromeLogo: theme.useMonochromeLogo
    };
  }

  export function makeThemeOverrides(formData: FormData): ThemeOverrides {
    const trimmedDarkPrimary = formData.darkPrimary.trim();
    const trimmedDarkerPrimary = formData.darkerPrimary.trim();
    const trimmedLightPrimary = formData.lightPrimary.trim();
    const trimmedFaintPrimary = formData.faintPrimary.trim();

    const trimmedRed = formData.red.trim();
    const trimmedLightRed = formData.lightRed.trim();

    const trimmedYellow = formData.yellow.trim();
    const trimmedLightYellow = formData.lightYellow.trim();

    const trimmedOrange = formData.orange.trim();
    const trimmedLightOrange = formData.lightOrange.trim();

    const trimmedGreen = formData.green.trim();
    const trimmedLightGreen = formData.lightGreen.trim();

    const trimmedTitleColor = formData.titleColor.trim();
    const trimmedTextColor = formData.textColor.trim();

    return {
      primary: formData.primary,
      darkPrimary: trimmedDarkPrimary.length !== 0 ? trimmedDarkPrimary : undefined,
      darkerPrimary: trimmedDarkerPrimary.length !== 0 ? trimmedDarkerPrimary : undefined,
      lightPrimary: trimmedLightPrimary.length !== 0 ? trimmedLightPrimary : undefined,
      faintPrimary: trimmedFaintPrimary.length !== 0 ? trimmedFaintPrimary : undefined,

      red: trimmedRed.length !== 0 ? trimmedRed : undefined,
      lightRed: trimmedLightRed.length !== 0 ? trimmedLightRed : undefined,

      yellow: trimmedYellow.length !== 0 ? trimmedYellow : undefined,
      lightYellow: trimmedLightYellow.length !== 0 ? trimmedLightYellow : undefined,

      orange: trimmedOrange.length !== 0 ? trimmedOrange : undefined,
      lightOrange: trimmedLightOrange.length !== 0 ? trimmedLightOrange : undefined,

      green: trimmedGreen.length !== 0 ? trimmedGreen : undefined,
      lightGreen: trimmedLightGreen.length !== 0 ? trimmedLightGreen : undefined,

      titleColor: trimmedTitleColor.length !== 0 ? trimmedTitleColor : undefined,
      textColor: trimmedTextColor.length !== 0 ? trimmedTextColor : undefined,

      useMonochromeLogo: formData.useMonochromeLogo
    };
  }

  export function toResult(formData: FormData): ThemeFormData {
    return {
      name: formData.name.trim(),
      notes: formData.notes,
      ...makeThemeOverrides(formData)
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    name: yup.string().required("Theme name is required"),
    notes: yup.string()
  });
}

interface Props {
  config: FormConfig<Theme, ThemeFormData>;
}

export const ThemeForm: React.FunctionComponent<Props> = (props) => {
  const themeRoutes = useConfigurationRoutes({ themeId: props.config.item?.id });

  return (
    <ItemForm<Theme, FormData, ThemeFormData>
      config={props.config}

      subject={"theme"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.blank}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, formProps, submitting }) => (
        <>
          {props.config.readOnly && <ViewOnlyWarningPanel/>}
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextField<FormData>
                label={"Name"}
                name={"name"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={200}
                textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
              />
              {!props.config.readOnly && (
                <TextAreaField<FormData>
                  label={"Internal notes"}
                  name={"notes"}
                  disabled={submitting}
                />
              )}
              {
                props.config.item && !props.config.isNew && (
                  <>
                    <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                    <FormField>
                      <>
                        <NewTabLink to={themeRoutes.styleguide.homePath}>Preview in VaultMe</NewTabLink>
                        {!props.config.readOnly && <>{" "}(changes must be saved to become visible)</>}
                      </>
                    </FormField>
                  </>
                )
              }
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <ThemeColors
                themeId={props.config.item?.id}
                theme={AppTheme.applyOverrides(defaultAppTheme, FormData.makeThemeOverrides(formProps.values))}
                submitting={submitting}
                readOnly={props.config.readOnly}
              />
            </FormLayout>
          </SimplePanel>
        </>
      )}
    </ItemForm>
  );
};

const ThemeColorsLayout = styled.div`
  display: flex;
`;

const ThemeColorsFormColumn = styled.div`
  max-width: 40rem;
  flex-grow: 1;

  input::placeholder {
    color: #aaaaaa;
  }

  input:placeholder-shown {
    background: #f0f0f0;
  }
`;

const ThemeColorsPreviewColumn = styled.div`
  width: 30rem;
  margin: 1.5rem;
`;

const DarkColorSample = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  color: ${(props) => props.theme.colors.white};
  padding: .5rem;
`;

const LightColorSample = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  color: ${(props) => props.theme.colors.black};
  padding: .5rem;
`;

const ColorSampleRow = styled.div`
  display: flex;

  > * {
    width: 50%;
  }
`;

const ColorGrid = styled.table`
  width: 25rem;

  tr {
    td {
      padding: .5rem .75rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }

    &:first-child {
      td {
        padding-top: 1rem;
      }
    }

    &:last-child {
      td {
        padding-bottom: 1rem;
      }
    }
  }
`;

interface ThemeColorsProps {
  themeId: number | undefined;
  theme: AppTheme;
  submitting: boolean;
  readOnly: boolean;
}

const ThemeColors: React.FunctionComponent<ThemeColorsProps> = (props) => {
  const primaryColorHex = AppTheme.parseColor(props.theme.colors.primary).getOrUse(Color("#000000")).hex();

  return (
    <ThemeColorsLayout>
      <ThemeColorsFormColumn>
        <TextField<FormData>
          label={"Primary color"}
          name={"primary"}
          required={true}
          readOnly={props.readOnly}
          disabled={props.submitting}
          maxLength={10}
          appendix={<ColorSample color={props.theme.colors.primary}/>}
        />
        <ColorGrid>
          <tbody>
          <tr>
            <td>
              <TextField<FormData>
                label={"Dark primary color"}
                name={"darkPrimary"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.darkPrimary}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.darkPrimary}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Light primary color"}
                name={"lightPrimary"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.lightPrimary}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.lightPrimary}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Darker primary color"}
                name={"darkerPrimary"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.darkerPrimary}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.darkerPrimary}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Faint primary color"}
                name={"faintPrimary"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.faintPrimary}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.faintPrimary}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Error"}
                name={"red"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.red}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.red}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Error (light) "}
                name={"lightRed"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.lightRed}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.lightRed}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Warning"}
                name={"yellow"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.yellow}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.yellow}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Warning (light)"}
                name={"lightYellow"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.lightYellow}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.lightYellow}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Heads up"}
                name={"orange"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.orange}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.orange}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Heads up (light)"}
                name={"lightOrange"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.lightOrange}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.lightOrange}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Success"}
                name={"green"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.green}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.green}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Success (light)"}
                name={"lightGreen"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.lightGreen}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.lightGreen}/>}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField<FormData>
                label={"Title text color"}
                name={"titleColor"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.titleColor}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.titleColor}/>}
              />
            </td>
            <td>
              <TextField<FormData>
                label={"Supporting text color"}
                name={"textColor"}
                readOnly={props.readOnly}
                disabled={props.submitting}
                placeholder={props.theme.colors.textColor}
                maxLength={10}
                appendix={<ColorSample color={props.theme.colors.textColor}/>}
              />
            </td>
          </tr>
          </tbody>
        </ColorGrid>
        <CheckboxField<FormData>
          label={"Use monochrome VaultMe logo (black or white instead of blue)"}
          name={"useMonochromeLogo"}
          readOnly={props.readOnly}
          disabled={props.submitting}
        />
      </ThemeColorsFormColumn>
      <ThemeColorsPreviewColumn>
        <DarkColorSample color={props.theme.colors.primary}>
          Primary
        </DarkColorSample>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.darkPrimary}>
            Dark primary
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.lightPrimary}>
            Light primary
          </LightColorSample>
        </ColorSampleRow>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.darkerPrimary}>
            Darker primary
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.faintPrimary}>
            Faint primary
          </LightColorSample>
        </ColorSampleRow>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.red}>
            Error
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.lightRed}>
            Error (light)
          </LightColorSample>
        </ColorSampleRow>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.yellow}>
            Warning
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.lightYellow}>
            Warning (light)
          </LightColorSample>
        </ColorSampleRow>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.orange}>
            Heads up
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.lightOrange}>
            Heads up (light)
          </LightColorSample>
        </ColorSampleRow>
        <ColorSampleRow>
          <DarkColorSample color={props.theme.colors.green}>
            Success
          </DarkColorSample>
          <LightColorSample color={props.theme.colors.lightGreen}>
            Success (light)
          </LightColorSample>
        </ColorSampleRow>

        <br/>
        <br/>

        <NewTabLink to={"https://mycolor.space/?hex=" + encodeURIComponent(primaryColorHex) + "&sub=1"}>
          Generate palettes in ColorSpace
        </NewTabLink>
        <br/>
        <NewTabLink to={"https://colors.muz.li/color-palette-generator/" + primaryColorHex.substr(1)}>
          Generate palettes in Muzli Colors
        </NewTabLink>

        <br/>
        <br/>
        <br/>

        <ThemeProvider theme={props.theme}>
          <FormLayout>
            <FormField label="Sample valid text box" required={true}><TextBox className="focus"/></FormField>
            <FormField label="Sample invalid text box" required={true} error={true}>
              <TextBox error={true}/>
              <FormErrorMessage>Something's wrong here</FormErrorMessage>
            </FormField>
            <FormField>
              <Checkbox
                checked={true}
                onChange={() => {
                  // Just avoiding React complaints in JS console
                }}
              >
                Sample checkbox
              </Checkbox>
            </FormField>
            <SimpleToolbar>
              <BackButton/>
              <Button>Next</Button>
            </SimpleToolbar>
          </FormLayout>
        </ThemeProvider>
      </ThemeColorsPreviewColumn>
    </ThemeColorsLayout>
  );
};
