import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const term: string = "term";
}

export class OrganizationMigrationsRoutes {
  constructor(private readonly context: RoutesContext, public readonly root: string) {}

  public get homeMask(): string {
    return "";
  }

  public get searchMask(): string {
    return "/:" + RouteParams.term;
  }

  public get homePath(): string {
    return this.searchPath(undefined);
  }

  public searchPath(term: string | undefined): string {
    return this.context.resolvePath(this.root + (term ? "/" + encodeURIComponent(term) : ""));
  }

  public searchParams(): OrganizationMigrationsRoutes.SearchParams {
    return { term: this.context.routeParams[RouteParams.term] };
  }
}

export namespace OrganizationMigrationsRoutes {
  export interface SearchParams {
    term: string | undefined;
  }
}
