import * as React from "react";
import { AppTheme, styled } from "../../app/theme";
import { Button } from "./button";
import { prettyPrint } from "../../utils/formatting";

const Layout = styled.div`
`;

const Container = styled.div`
`;

const Title = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.lighterGray};
  color: ${(props) => props.theme.colors.darkGray};
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
`;

type ValueStatus = "normal" | "error" | "uncertain";

function valueColor(theme: AppTheme, status?: ValueStatus): string {
  switch (status) {
    case "error": return theme.colors.red;
    case "uncertain": return theme.colors.gray;
    default: return theme.colors.black;
  }
}

const Value = styled.div<{ status?: ValueStatus }>`
  color: ${(props) => valueColor(props.theme, props.status)};
  padding-bottom: 0.5rem;
`;

const JsonValue = styled.textarea<{ lines: number }>`
  margin: 0.2rem 0 0 0;
  padding: 0.2rem;
  font-size: 0.8rem;
  width: 100%;
  height: ${(props) => props.lines * 0.8 + 2.5}rem;
  white-space: pre;
`;

const JsonValueButtonContainer = styled.div`
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CopiedToClipboardMessage = styled.span`
  margin-left: 0.5rem;
  font-size: 0.8rem;
`;

interface PropertyRowProps {
  title: string;
  status?: ValueStatus;
}

export const PropertyRow: React.SFC<PropertyRowProps> = (props) => (
  <Container>
    <Title>{props.title}</Title>
    <Value status={props.status}>{props.children || "--"}</Value>
  </Container>
);

interface JsonPropertyRowInternalState {
  copySuccess?: string;
}

export class JsonPropertyRow extends React.Component<PropertyRowProps, JsonPropertyRowInternalState> {
  protected textAreaRef: React.RefObject<any>;

  constructor(props: PropertyRowProps) {
    super(props);

    this.state = {};

    this.textAreaRef = React.createRef();

    this.onCopyToClipboardClick = this.onCopyToClipboardClick.bind(this);
  }

  public render() {
    if (this.props.children === undefined) {
      return (
        <Container>
          <Title>{this.props.title}</Title>
          <Value status={this.props.status}>--</Value>
        </Container>
      );
    } else {
      const json = prettyPrint(this.props.children);
      return (
        <Container>
          <Title>{this.props.title}</Title>
          <Value status={json === undefined ? "error" : this.props.status}>
            <JsonValue
              ref={this.textAreaRef}
              value={json || "Failed to convert to JSON"}
              readOnly={true}
              lines={json ? json.split("\n").length : 1}
            />
            <JsonValueButtonContainer>
              <Button color="white" size="small" onClick={this.onCopyToClipboardClick}>Copy To Clipboard</Button>
              <CopiedToClipboardMessage>{this.state.copySuccess}</CopiedToClipboardMessage>
            </JsonValueButtonContainer>
          </Value>
        </Container>
      );
    }
  }

  protected onCopyToClipboardClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.textAreaRef.current) {
      this.textAreaRef.current.select();
    }
    document.execCommand("copy");
    const target = event.target as HTMLButtonElement;
    if (target) {
      target.focus();
    }
    this.setState({ copySuccess: "Copied!" });
  }
}

export const PropertiesGrid: React.FunctionComponent = (props) => (
  <Layout>
    {props.children}
  </Layout>
);
