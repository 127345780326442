import * as React from "react";
import { useSimpleLookupController } from "../../simpleLookupController";
import { Configuration } from "../../../types/models/configuration";
import { useSearchConfigurationsQuery } from "../../../queries/configurations/useSearchConfigurationsQuery";
import {
  ConfigurationLookupControllerType
} from "../../../views/blocks/lookups/configurationLookupField";

export function useConfigurationLookupController(organizationId: number): ConfigurationLookupControllerType {
  const getItemId = React.useCallback((item: Configuration) => item.id, []);

  const comparator = React.useCallback(
    (a: Configuration, b: Configuration) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },
    []
  );

  const useSearchQuery = React.useCallback(
    (configuration) => useSearchConfigurationsQuery(organizationId, configuration),
    [organizationId]
  );

  return useSimpleLookupController<number, Configuration>({
    getItemId,
    comparator,
    listAll: true,
    useSearchQuery
  });
}
