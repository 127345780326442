import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyComponent } from "../component";
import { PropertiesTable, PropertiesTableContainer } from "./propertiesTable";
import { ServerLabel } from "./serverLabel";
import { BlueprintDiff } from "../blueprintDiff";
import { ElementStatus } from "./elementStatus";
import { ElementOutput } from "./elementOutput";
import { ElementErrors } from "./elementErrors";

interface ComponentPropertiesTableProps {
  component: AnyComponent;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  showOutput?: boolean;
}

export const ComponentPropertiesTable: React.SFC<ComponentPropertiesTableProps> = (props) => {
  const state = props.component.state(props.blueprintContext);
  const resolvedByServer = props.component.resolvedByServer(props.blueprintContext);
  const diff = props.diffs.getComponentDiff(props.component.id);

  const output = props.showOutput && (
    <tr>
      <th>Output:</th>
      <td>
        <ElementOutput
          section={false}
          state={state}
          resolvedByServer={props.component.resolvedByServer(props.blueprintContext)}
          diff={props.diffs.getComponentDiff(props.component.id)}
        />
      </td>
    </tr>
  );

  return (
    <PropertiesTableContainer>
      <PropertiesTable>
        <tbody>
        <tr>
          <th>Component Id:</th>
          <td>{props.component.id}</td>
        </tr>
        <tr>
          <th>Component Type:</th>
          <td>{props.component.type}</td>
        </tr>
        <tr>
          <th>Resolvable At:</th>
          <td>
            {props.component.serverSide && <ServerLabel/>}
            {props.component.serverSide ? "Server only" : "Client and server"}
          </td>
        </tr>
        <tr>
          <th>Segment:</th>
          <td>{props.component.segment}</td>
        </tr>
        <tr>
          <th>Tags:</th>
          <td>{props.component.tags.sort().join(", ")}</td>
        </tr>
        <tr>
          <th>Status:</th>
          <td>
            <ElementStatus state={state} resolvedByServer={resolvedByServer} diff={diff}/>
          </td>
        </tr>
        <tr>
          <th>Errors:</th>
          <td>
            <ElementErrors state={state} resolvedByServer={resolvedByServer} diff={diff}/>
          </td>
        </tr>
        {output}
        </tbody>
      </PropertiesTable>
    </PropertiesTableContainer>
  );
};
