import * as React from "react";
import { styled } from "../../app/theme";
import { SimpleToolbar } from "./simpleToolbar";
import { OperationStatus } from "../../types/operationStatus";
import { ErrorInfo } from "./errorInfo";
import { Button } from "./button";
import { useRoutes } from "../../app/routes/useRoutes";

const ImagePreview = styled.img`
  max-height: 10rem;
  max-width: 100%;
`;

interface ImageUploadPreviewProps {
  id: string;
}

export const ImageUploadPreview: React.FunctionComponent<ImageUploadPreviewProps> = (props) => {
  const routes = useRoutes();
  return <ImagePreview src={routes.api.downloadUrl(props.id)}/>;
};

const FileUploadLayout = styled.div`
  //margin-top: .5rem;
`;

interface FileDetailsContainerProps {
  isEmpty: boolean;
  error: boolean;
  disabled: boolean;
}

const FileDetailsContainer = styled.div<FileDetailsContainerProps>`
  min-height: 2rem;
  color: ${(props) => props.isEmpty ? props.theme.colors.lightGray : "inherit"};
  padding: 0.25rem;
  border: 2px solid ${(props) => props.error ? props.theme.colors.red : props.theme.colors.lightGray};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.animation.transitionAll()};

  &[disabled] {
    background: ${(props) => props.theme.colors.lighterGray};
    color: ${(props) => props.theme.colors.darkGray};
  }
`;

const PreviewContainer = styled.div`
  margin-top: .5rem;
`;

const StyledSimpleToolbar = styled(SimpleToolbar)`
  margin-top: .5rem;
`;

export type FileUploadController = [
  (file: File) => Promise<string>,
  { status: OperationStatus<string>, reset: () => void }
];

export interface CurrentFile {
  name: string;
}

export interface FileUploadResult {
  response: string;
  name: string;
  length: number;
}

interface FileUploadProps {
  controller: FileUploadController;
  upload: CurrentFile | undefined;
  preview?: React.ReactNode;

  error?: boolean;
  readOnly?: boolean;
  disabled?: boolean;

  onUpload: (upload: FileUploadResult) => void;
  onClear?: () => void;
}

export const FileUpload: React.FunctionComponent<FileUploadProps> = (props) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [uploadInProgress, setUploadInProgress] = React.useState<string | undefined>(undefined);
  const [upload, { status, reset }] = props.controller;

  function renderStatus() {
    if (props.upload) {
      return (
        <FileDetailsContainer isEmpty={false} error={!!props.error} disabled={!!props.disabled}>
          {!props.readOnly && props.upload.name}
          {props.preview && <PreviewContainer>{props.preview}</PreviewContainer>}
        </FileDetailsContainer>
      );
    } else if (status.isFailure()) {
      return <ErrorInfo error={status.error}/>;
    } else if (status.isWorking()) {
      return (
        <FileDetailsContainer isEmpty={false} error={!!props.error} disabled={!!props.disabled}>
          Uploading "{uploadInProgress}"...
        </FileDetailsContainer>
      );
    } else {
      return (
        <FileDetailsContainer isEmpty={true} error={!!props.error} disabled={!!props.disabled}>
          (No file)
        </FileDetailsContainer>
      );
    }
  }

  return (
    <FileUploadLayout>
      <input
        type={"file"}
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target.files) {
            const file = event.target.files[0];
            if (file) {
              setUploadInProgress(file.name);
              reset();
              upload(file).then((response) =>
                props.onUpload({ response, name: file.name, length: file.size })
              );
            }
            event.target.value = null as unknown as string;
          }
        }}
      />
      {renderStatus()}
      {!props.readOnly && (
        <StyledSimpleToolbar>
          <Button
            size={"small"}
            color={"secondary"}
            disabled={props.disabled}
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {props.upload ? "Change" : "Select a File"}
          </Button>
          {props.onClear && (
            <Button
              size={"small"}
              color={"secondary"}
              disabled={props.disabled}
              onClick={() => {
                reset();
                if (props.onClear) {
                  props.onClear();
                }
              }}
            >
              Clear
            </Button>
          )}
        </StyledSimpleToolbar>
      )}
    </FileUploadLayout>
  );
};
