import * as React from "react";
import { styled } from "../../../../app/theme";
import { StyledComponentsProps } from "../../../utils/styledComponentsProps";

export const CarouselLinksSeparatorLayout = styled.div`
  content: "&bull;";
  color: #aaa;

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    display: none;
  }
`;

export const CarouselLinksSeparator: React.FunctionComponent<StyledComponentsProps> = (props) => (
  <CarouselLinksSeparatorLayout className={props.className}>&bull;</CarouselLinksSeparatorLayout>
);

export const CarouselLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  padding: 0 1rem;
  
  > * {
    margin: 0 .5rem;
  }

  ${(props) => props.theme.responsive.respondToSmall()} {
    > * {
      margin: 0 .2rem;
    }
  }  

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    flex-direction: column;
  }  
`;
