import { CRUDRoutes } from "./crudRoutes";
import { Program } from "../../types/models/program";
import { RoutesContext } from "./routesContext";

export class ProgramsRoutes extends CRUDRoutes<string, Program> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  protected parseId(s: string): string {
    return s;
  }
}
