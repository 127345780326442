import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyComponent } from "../component";
import { ComponentPropertiesTable } from "./componentPropertiesTable";
import { ComponentSummary } from "./componentSummary";
import { Placeholder } from "./placeholder";
import { RelationshipPropertiesTable } from "./relationshipPropertiesTable";
import { SubSection } from "./subSection";
import { BlueprintDiff } from "../blueprintDiff";

interface IncomingComponentRelationshipsProps {
  component: AnyComponent;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  onComponentSelect: (componentId: string) => void;
}

export const IncomingComponentRelationships: React.SFC<IncomingComponentRelationshipsProps> = (props) => {
  const relationships = props.component.incomingRelationships().map((incomingRelationship) => (
    <SubSection key={incomingRelationship.hub.title + " ::: " + incomingRelationship.component.id}>
      <ComponentSummary
        component={incomingRelationship.component}
        blueprintContext={props.blueprintContext}
        showOutput={true}
        showArrow={true}
        reverseArrow={true}
        onComponentSelect={props.onComponentSelect}
      />
      <RelationshipPropertiesTable
        relationship={incomingRelationship.relationship}
        blueprintContext={props.blueprintContext}
        diffs={props.diffs}
        showOutput={true}
      />
      <ComponentPropertiesTable
        component={incomingRelationship.component}
        blueprintContext={props.blueprintContext}
        diffs={props.diffs}
        showOutput={true}
      />
    </SubSection>
  ));
  if (relationships.isEmpty()) {
    return <Placeholder>No incoming relationships</Placeholder>;
  } else {
    return <div>{relationships}</div>;
  }
};
