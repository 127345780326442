import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { CloudService } from "./cloudService";

export class CloudServiceCategory implements CloudServiceCategory.Props {
  constructor(protected readonly props: CloudServiceCategory.Props) {
  }

  public get title(): string { return this.props.title; }
  public get cloudServices(): List<CloudService> { return this.props.cloudServices; }
}

export namespace CloudServiceCategory {
  export interface Props {
    readonly title: string;
    readonly cloudServices: List<CloudService>;
  }

  export function fromGraphQL(category: GraphQL.CloudServiceCategory): CloudServiceCategory {
    return new CloudServiceCategory({
      title: category.title,
      cloudServices: List(category.cloudServices.map((cloudService) => CloudService.fromGraphQL(cloudService)))
    });
  }
}
