import { Connection } from "../../types/models/connection";
import { BlueprintContext } from "../blueprintContext";
import { ComponentHubs, Component } from "../component";
import { ComponentColorSchema } from "../componentColorSchema";
import { ComponentVisualization } from "../componentVisualization";
import { State } from "../state";
import { Set } from "immutable";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentBinding } from "./componentBinding";
import { Option, Some } from "../../utils/monads/option";
import { Either, Left, Right } from "../../utils/monads/either";
import { UserFacingError } from "../../types/userFacingError";
import { BlueprintError } from "../blueprintError";

export class ConnectionComp extends Component<ConnectionComp.Props, ConnectionComp.Hubs, ConnectionComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<ConnectionComp.Output> {
    return context.inputs.get(this.props.inputId)
      .map((connectionId) =>
        this.getConnection(context, connectionId).fold<State<ConnectionComp.Output>>(
          (error) => State.error(BlueprintError.build("CONNECTION_NOT_FOUND", Some(error.toLogString()))),
          (connection) => State.resolved({
            roles: connection.roles || Set(),
            connectionId: connection.id,
            emailAddress: Option.mayBe(connection.personalInfo.emailAddress),
            accessKey: undefined
          })
        )
      )
      .getOrElse(() => State.blocked());
  }

  public visualization(context: BlueprintContext): ComponentVisualization {
    const connectionOpt = context.inputs
      .get(this.props.inputId)
      .flatMap((connectionId) => this.getConnection(context, connectionId).toOption());
    return {
      title: "Connection",
      summary: this.props.purpose + " (" + this.props.branding.name + ")" + 
        connectionOpt.map((connection) => "\n" + connection.id).getOrElse(() => ""),
      sizeMultiplier: 2.5,
      icon: this.props.branding.icon,
      color: ComponentColorSchema.hollow(this.props.branding.color),
      font: {
        size: 18
      }
    };
  }

  protected getConnection(context: BlueprintContext, connectionId: string): Either<UserFacingError, Connection> {
    return context.connections
      .getOption(connectionId)
      .map((connection) => Right<UserFacingError, Connection>(connection))
      .getOrElse(() => 
        Left<UserFacingError, Connection>(UserFacingError.synthetic({ 
          summary: "No connection with ID " + connectionId
        }))
      );
  }
}

export namespace ConnectionComp {
  export interface Branding {
    name: string;
    icon: string;
    color: string;
  }

  export namespace Branding {
    export function fromGraphQL(props: GraphQL.ConnectionCompBranding): Branding {
      return {
        name: props.name,
        icon: props.icon,
        color: props.color
      };
    }
  }

  export interface Props {
    purpose: string;
    inputId: string;
    authProviderId: string;
    branding: Branding;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.ConnectionCompProps): Props {
      return {
        purpose: props.purpose,
        inputId: props.inputId,
        authProviderId: props.authProviderId,
        branding: Branding.fromGraphQL(props.branding),
      };
    }
  }

  export type Hubs = ComponentHubs;

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.ConnectionCompHubsFragment): Hubs {
      return ComponentHubs.fromGraphQL(binding, hubs);
    }
  }

  export interface Output {
    roles: Set<string>;
    connectionId: string;
    emailAddress: Option<string>;
    accessKey: any; // Only required to make relationships work
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.ConnectionCompOutputFragment): Output {
      return {
        roles: Set(output.roles),
        connectionId: output.connectionId,
        emailAddress: Option.mayBe(output.emailAddress),
        accessKey: undefined
      };
    }
  }
}
