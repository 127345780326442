import { Batch } from "../../types/models/batch";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type RenameBatchMutationHook = [(name: string) => Promise<Batch>, OperationStatus<Batch>];

export function useRenameBatchMutation(batchId: string): RenameBatchMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useRenameBatchMutation,
    extract: (data: GraphQL.RenameBatchMutation) => nullToUndefined(data.renameBatch),
    complete: Batch.parse
  });

  function fireWith(name: string): Promise<Batch> {
    return fire({
      variables: { batchId, name },
      retry: () => fireWith(name),
    });
  }

  return [fireWith, status];
}
