import * as yup from "yup";
import { Map, Set } from "immutable";

export interface WorkItemState<T> {
  result: T | undefined; // We want to be able to clear result, but retain completion status
  completed: boolean;
  lastCompletedResult: T | undefined; // Used for better UX with interstitial steps
}

export namespace WorkItemState {
  export function equals(a: AnyWorkItemState, b: AnyWorkItemState): boolean {
    return a.result === b.result && a.completed === b.completed && a.lastCompletedResult === b.lastCompletedResult;
  }

  export const Schema = yup.object<WorkItemState<any>>().shape({
    result: yup.mixed(),
    completed: yup.boolean().required(),
    lastCompletedResult: yup.mixed(),
  });
}

export type AnyWorkItemState = WorkItemState<any>;

export class WorkItemStates {
  constructor(private map: Map<string, AnyWorkItemState> = Map()) {
  }

  public applyResult(key: string, result: any, completeAndProceed: boolean): [WorkItemStates, WorkItemState<any>] {
    const existingState = this.map.get(key);
    const newState: WorkItemState<any> = {
      result,
      completed: completeAndProceed || existingState !== undefined && existingState.completed,
      lastCompletedResult: completeAndProceed
        ? result
        : existingState && existingState.lastCompletedResult
    };
    if (existingState && WorkItemState.equals(existingState, newState)) {
      return [this, existingState];
    } else {
      return [new WorkItemStates(this.map.set(key, newState)), newState];
    }
  }

  public updateResult(key: string, result: any): WorkItemStates {
    const existingState = this.map.get(key);
    if (existingState) {
      return this.set(
        key,
        result,
        existingState.completed,
        existingState.lastCompletedResult === existingState.result
          ? result
          : existingState.lastCompletedResult);
    } else {
      return this.set(key, result, false, undefined);
    }
  }

  public changeToUncompleted(key: string, result: any): WorkItemStates {
    return this.set(key, result, false, undefined);
  }

  public clearResult(key: string): WorkItemStates {
    const existingState = this.map.get(key);
    if (existingState) {
      return new WorkItemStates(this.map.set(key, { ...existingState, result: undefined }));
    } else {
      return this;
    }
  }

  public has(key: string): boolean {
    return this.map.has(key);
  }

  public get<T>(key: string): WorkItemState<T> | undefined {
    const existingState = this.map.get(key);
    if (existingState) {
      if (!existingState.result) {
        // This may happen after using clearResult - totally fine here, but not got for consumers
        return { ...existingState, completed: false };
      } else {
        return existingState;
      }
    } else {
      return undefined;
    }
  }

  public toArray(): [string, AnyWorkItemState][] {
    return this.map.toArray();
  }

  public toJSON(): [string, AnyWorkItemState][] {
    return this.map.toArray().map((item) => [item[0], { ...item[1], lastCompletedResult: undefined }]);
  }

  public equals(that: WorkItemStates): boolean {
    return this.map.equals(that.map);
  }

  public trim(keys: Set<string>): WorkItemStates {
    return new WorkItemStates(this.map.removeAll(this.map.keySeq().toSet().subtract(keys)));
  }

  protected set(key: string, result: any, completed: boolean, lastCompletedResult: any): WorkItemStates {
    const existingState = this.map.get(key);
    const newState: WorkItemState<any> = { result, completed, lastCompletedResult };
    if (existingState && WorkItemState.equals(existingState, newState)) {
      return this;
    } else {
      return new WorkItemStates(this.map.set(key, newState));
    }
  }
}

export namespace WorkItemStates {
  export function fromJSON(json: any): WorkItemStates {
    if (
      Array.isArray(json) &&
      json.every((item) =>
        Array.isArray(item) &&
        item.length === 2 &&
        typeof item[0] === "string" &&
        WorkItemState.Schema.isValidSync(item[1])
      )
    ) {
      return new WorkItemStates(Map(json as [string, AnyWorkItemState][]));
    } else {
      return new WorkItemStates();
    }
  }
}
