import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type ValidateAmbassadorsProgramEmailAddressMutationHook = [
  (sourceConnectionId: string, emailAddress: string) => Promise<GraphQL.AmbassadorsProgramEmailAddressValidationResult>,
  OperationStatus<GraphQL.AmbassadorsProgramEmailAddressValidationResult>
];

export function useValidateAmbassadorsProgramEmailAddressMutation()
  : ValidateAmbassadorsProgramEmailAddressMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useValidateAmbassadorsProgramEmailAddressMutation,
    extract: (data: GraphQL.ValidateAmbassadorsProgramEmailAddressMutation) =>
      nullToUndefined(data.validateAmbassadorsProgramEmailAddress),
    complete: identity
  });

  function fireWith(
    sourceConnectionId: string,
    emailAddress: string
  ): Promise<GraphQL.AmbassadorsProgramEmailAddressValidationResult> {
    return fire({
      variables: { sourceConnectionId, emailAddress },
      retry: () => fireWith(sourceConnectionId, emailAddress),
    });
  }

  return [fireWith, status];
}
