import * as React from "react";
import { Connection } from "../../../types/models/connection";
import { Panel } from "../../containers/panel";
import { DrawerBlock } from "../../containers/drawerBlock";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Button } from "../../widgets/button";
import { OperationStatus } from "../../../types/operationStatus";
import { PanelRow } from "../../containers/rows/panelRow";

interface Props {
  onCheck: () => void;
  status: OperationStatus<Connection>;
}

export const AccessKeyRevokeToolView: React.FunctionComponent<Props> = (props) => (
  <>
    <DrawerBlock>Revoke access key for this connection and erase it from the database</DrawerBlock>
    <DrawerBlock>
      <Panel>
        <PanelRow>
          <Button color="red" onClick={props.onCheck} disabled={props.status.isWorking()}>Revoke Access Key</Button>
        </PanelRow>
        <OperationStatusIndicator
          progressMessage={"Revoking access key..."}
          successMessage={"Access key revoked."}
          status={props.status}
          indicators={StatusIndicators.PanelRow()}
        />
      </Panel>
    </DrawerBlock>
  </>
);
