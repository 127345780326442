import { RoutesContext } from "./routesContext";

export class StaticRoutes {
  private readonly contestParticipantsReportRoot = "contest-participants";
  private readonly migrationSimulationRoot = "migration-simulation";
  private readonly monitoringDashboardRoot = "dashboard";
  private readonly myProfileRoot = "profile";
  private readonly signInRoot = "sign-in";
  private readonly signOutRoot = StaticRoutes.signOutRoot;
  private readonly signUpRoot = "sign-up";
  private readonly testAccountWhitelistingForPrograms: string = "test-account-whitelisting-for-programs";

  constructor(private context: RoutesContext) {}

  public get contestParticipantsReportMask() { return this.contestParticipantsReportRoot; }

  public get contestParticipantsReportPath() { return this.context.resolvePath(this.contestParticipantsReportRoot); }

  public get migrationSimulationMask() { return this.migrationSimulationRoot; }

  public get migrationSimulationPath() { return this.context.resolvePath(this.migrationSimulationRoot); }

  public get monitoringDashboardMask() { return this.monitoringDashboardRoot; }

  public get monitoringDashboardPath() { return this.context.resolvePath(this.monitoringDashboardRoot); }

  public get myProfileMask() { return this.myProfileRoot; }

  public get myProfilePath() { return this.context.resolvePath(this.myProfileRoot); }

  public get signInMask() { return this.signInRoot; }

  public get signInPath() { return this.context.resolvePath(this.signInRoot); }

  public get signOutMask() { return this.signOutRoot; }

  public get signOutPath() { return this.context.resolvePath(this.signOutRoot); }

  public get signUpMask() { return this.signUpRoot; }

  public get signUpPath() { return this.context.resolvePath(this.signUpRoot); }

  public get testAccountWhitelistingForProgramsMask(): string {
    return this.testAccountWhitelistingForPrograms;
  }

  public get testAccountWhitelistingForProgramsPath(): string {
    return this.context.resolvePath(this.testAccountWhitelistingForPrograms);
  }
}

export namespace StaticRoutes {
  export const signOutRoot = "sign-out";
  export const signOutPath = "/" + signOutRoot;

  export const signedOutRoot = "signed-out";
  export const signedOutPath = "/" + signedOutRoot;
}
