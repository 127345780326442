import { GraphQL } from "../services/graphql/generated";
import { ConnectionComp } from "./components/connectionComp";
import { State } from "./state";
import { EndpointComp } from "./components/endpointComp";
import { BlueprintException } from "./blueprintException";
import { RoleComp } from "./components/roleComp";
import { PhaseComp } from "./components/phaseComp";
import { AreaComp } from "./components/areaComp";
import { SinkComp } from "./components/sinkComp";
import { VariableComp } from "./components/variableComp";
import { FactCompLike } from "./components/factCompLike";
import { ReducerComp } from "./components/reducerComp";
import { RestrictionComp } from "./components/restrictionComp";
import { Option } from "../utils/monads/option";
import { MigrationProgressComp } from "./components/migrationProgressComp";
import { BlueprintError } from "./blueprintError";
import { StorageRestrictionComp } from "./components/storageRestrictionComp";
import { TimeEstimateComp } from "./components/timeEstimateComp";

// Created a separate namespace to avoid circular dependencies
export namespace StateParser {
  export function valueFromGraphQL(output?: GraphQL.BlueprintStateOutputFragment | null): any {
    if (output) {
      switch (output.__typename) {
        case "AreaCompOutput":
          return AreaComp.Output.fromGraphQL(output);
        case "ChildEndpointCompOutput":
          return EndpointComp.Output.fromGraphQL(output);
        case "ConnectionCompOutput":
          return ConnectionComp.Output.fromGraphQL(output);
        case "FactCompLikeOutput":
          return FactCompLike.Output.fromGraphQL(output);
        case "MigrationProgressCompOutput":
          return MigrationProgressComp.Output.fromGraphQL(output);
        case "PhaseCompOutput":
          return PhaseComp.Output.fromGraphQL(output);
        case "ReducerCompOutput":
          return ReducerComp.Output.fromGraphQL(output);
        case "RestrictionCompOutput":
          return RestrictionComp.Output.fromGraphQL(output);
        case "RoleCompOutput":
          return RoleComp.Output.fromGraphQL(output);
        case "RootEndpointCompOutput":
          return EndpointComp.Output.fromGraphQL(output);
        case "SinkCompOutput":
          return SinkComp.Output.fromGraphQL(output);
        case "StorageRestrictionCompOutput":
          return StorageRestrictionComp.Output.fromGraphQL(output);
        case "TimeEstimateCompOutput":
          return TimeEstimateComp.Output.fromGraphQL(output);
        case "VariableCompOutput":
          return VariableComp.Output.fromGraphQL(output);
        case "UnregisteredOutputType": {
          const { __typename, ...rest } = output;
          return rest;
        }
      }
      console.error("Could not parse state output", output);
      throw new BlueprintException("Could not parse state output");
    } else {
      return undefined;
    }
  }

  // No type safety here, because the only reference to type is available from output which is not included in every
  // state. Therefore, currently we are simply relying on the fact that all states are created automatically on the
  // server from the same data that was used to produce the original blueprint. So, as long as client and server are
  // sharing exactly the same blueprint, there should be no conflicts or errors.
  export function fromGraphQL(state: GraphQL.BlueprintStateFragment): State<any> {
    const errors = BlueprintError.fromGraphQLList(state.errors);
    switch (state.__typename) {
      case "BlockedBlueprintState":
        return State.blocked(errors);
      case "PendingBlueprintState":
        return State.pending(errors);
      case "PreparingBlueprintState":
        return State.preparing(errors);
      case "ResolvingBlueprintState":
        return State.resolving(valueFromGraphQL(state.output), Option.mayBe(state.progress), errors);
      case "AlmostResolvedBlueprintState":
        return State.almostResolved(valueFromGraphQL(state.output), Option.mayBe(state.progress), errors);
      case "ResolvedBlueprintState":
        return State.resolved(valueFromGraphQL(state.output), errors);
      case "ErrorBlueprintState":
        return State.errorEx(errors);
    }
    console.error("Could not parse state", state);
    throw new BlueprintException("Could not parse state");
  }
}
