import { FastPromise } from "./fastPromise";
import { Observable, isObservable } from "rxjs";

export class DataSource<T> {
  constructor(
    public readonly promise: FastPromise<T>,
    public readonly observable?: Observable<T>,
    public readonly updateOnEveryCycle?: boolean,
  ) {
  }
}

export namespace DataSource {
  export type CompatibleValue<T> = FastPromise.CompatibleValue<T> | Observable<T> | DataSource<T>;

  function create<T>(value: DataSource.CompatibleValue<T>): DataSource<T> {
    if (value instanceof DataSource) {
      return value;
    } else if (isObservable(value)) {
      return new DataSource(
        FastPromise(new Promise<T>((resolve, reject) => value.subscribe(resolve, reject))),
        value,
        false
      );
    } else {
      return new DataSource(FastPromise(value), undefined, !(value instanceof Promise || value instanceof FastPromise));
    }
  }

  // If onInitializationError is provided, the first thrown error will be sent there
  export function fromFactory<T>(
    factory: () => CompatibleValue<T>,
    onInitializationError?: (error: any) => FastPromise<T>
  ): DataSource<T>  {
    try {
      let initializing = true;
      const dataSource = create(factory());
      return new DataSource(
        dataSource.promise
          .recover((error) => {
            if (initializing && onInitializationError) {
              initializing = false;
              return onInitializationError(error);
            } else {
              throw error;
            }
          }),
        dataSource.observable,
        dataSource.updateOnEveryCycle
      );
    } catch (error) {
      return new DataSource(
        onInitializationError ? onInitializationError(error) : FastPromise.reject(error),
        undefined,
        true
      );
    }
  }
}
