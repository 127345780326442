import { Anchor } from "../anchor";
import { InjectedWorkItemConfig, WorkItem, WorkItemConfigUpdates } from "../workItem";
import { WorkflowContextLike } from "../workflowContextLike";
import { List } from "immutable";

export interface SkipConditions<WizardContext> {
  workflowContext: WizardContext;
  previousStepCompletedOrUpdated: boolean;
  navigatingBack: boolean;
}

export interface WizardStep<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  >
  extends WorkItem<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext> {
  type: "WizardStep";

  copy(
    updates: WorkItemConfigUpdates<WizardContext, Props, PropsContext, Result, ResultContext>
  ): WizardStep<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>;

  getNextDestination(): ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> | undefined;

  skipWhen(conditions: SkipConditions<WizardContext>): boolean;

  getFutureSteps(): List<Anchor>;

  getWizardPartialResult(): Partial<WizardResult>;
}

export type AnyWizardStep<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  > =
  WizardStep<WizardContext, WizardFeedback, WizardResult, any, any, any, any>;

export interface WizardFinish<WizardResult> {
  type: "WizardFinish";

  wizardResult: WizardResult;
}

export type WizardDestination<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > =
  AnyWizardStep<WizardContext, WizardFeedback, WizardResult> | WizardFinish<WizardResult>;

export function finishWizard<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  >(
  wizardResult: WizardResult
): ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> {
  return () => ({
    type: "WizardFinish",
    wizardResult
  });
}

export function isWizardStep<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  >(
  destination: WizardDestination<WizardContext, WizardFeedback, WizardResult>
): destination is AnyWizardStep<WizardContext, WizardFeedback, WizardResult> {
  return destination.type === "WizardStep";
}

export function isWizardFinish<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  >(
  destination: WizardDestination<WizardContext, WizardFeedback, WizardResult>
): destination is WizardFinish<WizardResult> {
  return destination.type === "WizardFinish";
}

export type InjectedWizardStepConfig<
  WizardContext extends WorkflowContextLike,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > = Omit<InjectedWorkItemConfig<WizardContext, Props, PropsContext, Result, ResultContext>, "id">;

export type ProvideInjectedWizardStepConfig<
  WizardContext extends WorkflowContextLike,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > =
  (id: string) => InjectedWizardStepConfig<WizardContext, Props, PropsContext, Result, ResultContext>;

export type ConfigurableWizardDestinationFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult
  > = (
  provide: ProvideInjectedWizardStepConfig<WizardContext, any, any, any, any>
) => WizardDestination<WizardContext, WizardFeedback, WizardResult>;

export type ConfigurableWizardStepFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > = (
  provide: ProvideInjectedWizardStepConfig<WizardContext, Props, PropsContext, Result, ResultContext>
) => WizardStep<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>;

export type WizardStepFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > = (
  props: Props
) => ConfigurableWizardStepFactory<
  WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext
  >;
