import * as React from "react";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Form, Formik } from "formik";
import { FormLayout } from "../../../widgets/formLayout";
import { TextAreaField } from "../../../widgets/textAreaField";
import { Panel } from "../../../containers/panel";
import { Block } from "../../../containers/block";
import { PanelRow } from "../../../containers/rows/panelRow";
import * as yup from "yup";
import { Button } from "../../../widgets/button";
import { friendlyCount } from "../../../../utils/formatting";
import { Link } from "react-router-dom";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { useNavigate } from "react-router";
import { List, Set } from "immutable";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { OffboardingProjectDetailsPanel } from "./offboardingProjectDetailsPanel";
import { Paragraph } from "../../../widgets/paragraph";
import { WidgetStatus } from "../../../utils/widgetStatus";
import {
  OffboardingProjectEntryCountController
} from "../../../../controllers/organizationPortal/automatedOffboarding/offboardingProjectEntryCountController";
import { SimpleFormButtonsBlock } from "../../../widgets/simpleForm";

interface DeleteOffboardingProjectEntriesPageViewProps {
  offboardingProjectId: string;
  offboardingProjectStatus: OperationStatus<OffboardingProject>;

  entryCountController: OffboardingProjectEntryCountController;

  homePath: string;
  offboardingProjectPath: string;

  deleteStatus: OperationStatus<[number, List<string>]>;

  onDelete: (emailAddresses: Set<string>) => Promise<[number, List<string>]>;
}

export const DeleteOffboardingProjectEntriesPageView:
  React.FunctionComponent<DeleteOffboardingProjectEntriesPageViewProps> = (props) => (
  <>
    <HeaderBlock
      breadcrumbs={[
        <Link key={0} to={props.homePath}>End-User Offboarding</Link>,
        <Link key={1} to={props.offboardingProjectPath}>
          {
            props.offboardingProjectStatus
              .mapLastResult((offboardingProject) => offboardingProject.name) || "..."
          }
        </Link>,
        "Remove Entries"
      ]}
      title={"Remove Offboarding Project Entries"}
    />
    <OffboardingProjectDetailsPanel
      offboardingProjectStatus={props.offboardingProjectStatus}
      entryCountController={props.entryCountController}
    />
    {props.deleteStatus.isSuccess() && (
      <Block>
        <Panel>
          {props.deleteStatus.result[0] !== 0 && (
            <PanelRow status={props.deleteStatus.result[1].isEmpty() ? WidgetStatus.Success : WidgetStatus.Normal}>
              Removed {friendlyCount(props.deleteStatus.result[0], "email address")}
            </PanelRow>
          )}
          {props.deleteStatus.result[1].size !== 0 && (
            <PanelRow status={WidgetStatus.HeadsUp}>
              <Paragraph>
                Could not find {friendlyCount(props.deleteStatus.result[1].size, "email address")} in this
                offboarding project:
              </Paragraph>
              <Paragraph>
                {props.deleteStatus.result[1].map((emailAddress, index) =>
                  <div key={index}>{emailAddress}</div>
                )}
              </Paragraph>
            </PanelRow>
          )}
        </Panel>
      </Block>
    )}
    <DeleteForm
      homePath={props.homePath}
      offboardingProjectPath={props.offboardingProjectPath}

      status={props.deleteStatus}

      onSubmit={(result) =>
        props.onDelete(
          Set(
            result.emailAddresses.split("\n")
              .map((emailAddress) => emailAddress.toLowerCase().trim())
              .filter((emailAddress) => emailAddress.length !== 0)
          )
        )
      }
    />
  </>
);

interface FormData {
  emailAddresses: string;
}

interface Result {
  emailAddresses: string;
}

namespace FormData {
  export const empty: FormData = {
    emailAddresses: ""
  };

  export function toResult(formData: FormData): Result {
    return {
      emailAddresses: formData.emailAddresses
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    emailAddresses: yup.string().required("Email addresses are required")
  });
}

interface DeleteFormProps {
  homePath: string;
  offboardingProjectPath: string;

  status: OperationStatus<any>;

  onSubmit: (result: Result) => Promise<any>;
}

const DeleteForm: React.FunctionComponent<DeleteFormProps> = (props) => {
  const navigate = useNavigate();

  const firstInputRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []
  );

  const submitting = props.status.isWorking();

  return (
    <Formik<FormData>
      initialValues={FormData.empty}
      validationSchema={FormData.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={false}
      onSubmit={(data, actions) => {
        props.onSubmit(FormData.toResult(data));
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form>
          <Block>
            <Panel>
              <OperationStatusIndicator
                progressMessage={"Removing email addresses..."}
                failureMessage={"Failed to remove email addresses"}
                status={props.status}
                indicators={StatusIndicators.PanelRow()}
              />
              <PanelRow>
                <FormLayout>
                  <TextAreaField<FormData>
                    textBoxRef={firstInputRef}
                    label={"Email addresses (one per line)"}
                    name="emailAddresses"
                    disabled={submitting}
                    required={true}
                    rows={20}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <SimpleFormButtonsBlock>
            <SimpleToolbar>
              <Button type={"submit"} size={"small"} disabled={formProps.isSubmitting || submitting}>
                Remove
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                disabled={formProps.isSubmitting || submitting}
                onClick={() => navigate(props.offboardingProjectPath)}
              >
                Back
              </Button>
            </SimpleToolbar>
          </SimpleFormButtonsBlock>
        </Form>
      )}
    />
  );
};
