import { CRUDRoutes } from "./crudRoutes";
import { Configuration } from "../../types/models/configuration";
import { RoutesContext } from "./routesContext";

export class ConfigurationsRoutes extends CRUDRoutes<number, Configuration> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}
