import * as React from "react";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { CloudServiceChooserRow } from "../../../blocks/cloudServiceChooser/cloudServiceChooserRow";
import { AuthContext } from "../../../blocks/authContext";
import { TotalsRow } from "../rows/totalsRow";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { MigrationStatusPageState } from "../migrationStatusPageState";
import { MigrationCompleteIndicatorRow } from "../rows/migrationCompleteIndicatorRow";
import { StyledConnectionDescriptionRow } from "../rows/styledConnectionDescriptionRow";
import { ConnectionIssuesRow } from "../rows/connectionStatusRow";
import { SourceAreaRow } from "../rows/sourceAreaRow";
import { ProgressBar } from "../../../widgets/progressBar";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { OperationStatus } from "../../../../types/operationStatus";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Iteration } from "../../../../types/models/iteration";
import { IterationStatsRow } from "./iterationStatsRow";
import { Migration } from "../../../../types/models/migration";
import { IterationsHistoryControllerType } from "./iterationToolsRow";
import { ActionItems } from "../../../models/actionItem";
import { useCloudServices } from "../../../../app/configuration";
import { SignInContextType } from "../../../../types/models/signInContextType";

interface SourceConnectionPanelProps {
  title?: string;
  state: MigrationStatusPageState.Any;
  source: MigrationStatusPageDefs.SourceConnectionDetails;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  totals: MigrationStatusPageDefs.SourceAreaTotals;
  context: MigrationStatusPageDefs.ContextType;

  migration: Migration;
  iterationIndex: number | undefined;
  iterationStatus: OperationStatus<Iteration>;

  iterationsHistoryController: IterationsHistoryControllerType;

  onIterationSelect: (iterationIndex: number | undefined) => void;

  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const SourceConnectionPanel: React.FunctionComponent<SourceConnectionPanelProps> = (props) => {
  const cloudServices = useCloudServices(SignInContextType.Source);

  const connectionErrors = ActionItems.Stats.build(props.source.actionItems).errors !== 0;
  const stats = props.context === MigrationStatusPageDefs.ContextType.Cumulative
    ? props.totals.cumulative
    : props.totals.iteration;

  return (
    <Block id={MigrationStatusPageDefs.SourceConnectionPanelId}>
      <Panel>
        <TitlePanelRow>{props.title || "Migrate From"}</TitlePanelRow>
        <CloudServiceChooserRow
          authContext={AuthContext.Source}
          cloudServices={cloudServices}
          selectedCloudServiceId={props.source.cloudService.id}
        />
        {
          props.state.type !== MigrationStatusPageState.Type.Complete &&
          props.state.type !== MigrationStatusPageState.Type.Aborted &&
          <ProgressBar progress={stats.progress}/>
        }
        {props.state.type === MigrationStatusPageState.Type.Complete && <MigrationCompleteIndicatorRow/>}
        <StyledConnectionDescriptionRow
          authContext={AuthContext.Source}
          connectionDescription={props.source.connection.descriptionOrId()}
          status={
            props.state.type === MigrationStatusPageState.Type.Complete ||
            props.state.type === MigrationStatusPageState.Type.Aborted
              ? "All data migrated to " + props.destination.cloudService.name
              : (
                connectionErrors
                  ? "Migration to " + props.destination.cloudService.name + " is paused..."
                  : "Migrating data to " + props.destination.cloudService.name + "..."
              )
          }
          reserveSpaceForCompletionIndicator={props.state.type === MigrationStatusPageState.Type.Complete}
        />
        {
          props.iterationStatus.isWorking()
            ? (
              <OperationStatusIndicator
                subject={"sync-up migration stats"}
                status={OperationStatus.Working()}
                indicators={StatusIndicators.PanelRow()}
              />
            )
            : (
              <>
                {props.iterationStatus.isSuccess() && (
                  <IterationStatsRow
                    migration={props.migration}
                    iterationIndex={props.iterationIndex}
                    iteration={props.iterationStatus.result}

                    iterationsHistoryController={props.iterationsHistoryController}

                    onIterationSelect={props.onIterationSelect}
                  />
                )}
                <ConnectionIssuesRow connectionDetails={props.source}/>
                {
                  props.source.areas.map((area) => (
                    <SourceAreaRow
                      key={area.title}
                      cloudServices={cloudServices}
                      selectedCloudServiceId={props.source.cloudService.id}
                      connectionId={props.source.connection.id}
                      destination={props.destination}
                      area={area}
                      connectionSlowness={!!area.slowness || !!props.source.slowness}
                      connectionErrors={connectionErrors}
                      context={props.context}
                      actionItemSuppressing={props.actionItemSuppressing}
                    />
                  ))
                }
                <TotalsRow
                  cloudService={props.source.cloudService}
                  complete={props.state.type === MigrationStatusPageState.Type.Complete}
                  totals={props.totals}
                  context={props.context}
                />
              </>
            )
        }
      </Panel>
    </Block>
  );
};
