import { GraphQL } from "../../services/graphql/generated";
import { CouponCodeDiscount } from "./couponCodeDiscount";
import { ReferralCodeDiscount } from "./referralCodeDiscount";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { RevenueShare } from "./revenueShare";

export interface BatchOrder {
  // Pricing model
  readonly pricingModelName: string;
  readonly pricingModelType: string;
  readonly pricingModelVersion: string;
  readonly pricingModelJson: string;

  // Batch size
  readonly totalAccounts: number;
  readonly totalBytes: number;
  readonly totalItems: number;

  // Numbers
  readonly accountsFee: number;
  readonly bytesFee: number;
  readonly itemsFee: number;
  readonly dataFee: number;
  readonly priceBeforeDiscounts: number;
  readonly couponCodeDiscount: CouponCodeDiscount | undefined;
  readonly referralCodeDiscount: ReferralCodeDiscount | undefined;
  readonly amountToBePaid: number;
  readonly paidAmount: number | undefined;

  // Summary, transaction, etc
  readonly priceBreakdown: string;
  readonly transactionId: string | undefined;
  readonly braintreeTransactionDetailsUrl: string | undefined;
}

export namespace BatchOrder {
  export function parse(data: GraphQL.BatchOrderFragment): BatchOrder {
    const bytesFee = data.bytesFee;
    const itemsFee = data.itemsFee;

    return {
      pricingModelName: data.pricingModelName,
      pricingModelType: data.pricingModelType,
      pricingModelVersion: data.pricingModelVersion,
      pricingModelJson: data.pricingModelJson,

      totalAccounts: data.totalAccounts,
      totalBytes: data.totalBytes,
      totalItems: data.totalItems,

      accountsFee: data.accountsFee,
      bytesFee,
      itemsFee,
      dataFee: bytesFee + itemsFee,
      priceBeforeDiscounts: data.priceBeforeDiscounts,
      couponCodeDiscount: mapOptional(data.couponCodeDiscount, CouponCodeDiscount.parse),
      referralCodeDiscount: mapOptional(data.referralCodeDiscount, ReferralCodeDiscount.parse),
      amountToBePaid: data.amountToBePaid,
      paidAmount: nullToUndefined(data.paidAmount),

      priceBreakdown: data.priceBreakdown,
      transactionId: nullToUndefined(data.transactionId),
      braintreeTransactionDetailsUrl: nullToUndefined(data.braintreeTransactionDetailsUrl),
    };
  }

  export interface HasRevenueData {
    readonly revenue: number;
    readonly revenueShare: RevenueShare | undefined;
  }

  export namespace HasRevenueData {
    export function parseRevenueData(data: Pick<GraphQL.BatchOrder, "revenue" | "revenueShare">): HasRevenueData {
      return {
        revenue: data.revenue,
        revenueShare: mapOptional(data.revenueShare, RevenueShare.parseRevenueShare),
      };
    }

    export const empty: HasRevenueData = {
      revenue: 0,
      revenueShare: undefined
    };
  }
}
