import * as React from "react";
import { Panel } from "../../containers/panel";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { PanelRow } from "../../containers/rows/panelRow";
import { Checkbox } from "../../widgets/checkbox";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";
import { TaskHistory } from "../../../types/models/taskHistory";
import { TaskHistoryEventsGrid } from "./taskHistoryEventsGrid";

interface Props {
  taskHistory: TaskHistory;

  showTaskEvents: boolean;
  showPendingIssues: boolean;
  showResolvedIssues: boolean;

  reset: () => void;

  onToggleShowTaskEvents: () => void;
  onToggleShowPendingIssues: () => void;
  onToggleShowResolvedIssues: () => void;
}

export const TaskHistoryPanelView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow>
      <SimpleToolbar>
        <Button size={"small"} color={"white"} onClick={props.reset}>Reset</Button>
        <Checkbox checked={props.showTaskEvents} onChange={props.onToggleShowTaskEvents}>
          Task events
        </Checkbox>
        <Checkbox checked={props.showPendingIssues} onChange={props.onToggleShowPendingIssues}>
          Pending issues
        </Checkbox>
        <Checkbox checked={props.showResolvedIssues} onChange={props.onToggleShowResolvedIssues}>
          Resolved issues
        </Checkbox>
      </SimpleToolbar>
    </PanelRow>
    <GridPanelRow>
      <TaskHistoryEventsGrid
        events={props.taskHistory.toEvents()}
        showTaskEvents={props.showTaskEvents}
        showPendingIssues={props.showPendingIssues}
        showResolvedIssues={props.showResolvedIssues}
      />
    </GridPanelRow>
  </Panel>
);
