import { styled } from "../../../../app/theme";

export const CarouselContent = styled.div`
  margin: ${(props) => props.theme.layout.header.desktop}rem auto 0 auto;
  max-width: ${(props) => props.theme.layout.carousel.width};

  ${(props) => props.theme.responsive.respondToSmall()} {
    margin-top: ${(props) => props.theme.layout.header.mobile}rem;
    padding: 0rem 1rem 2rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0rem 0rem 2rem;
  }

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    padding: 0rem 0rem 2rem;
  }
`;
