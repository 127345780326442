import * as React from "react";
import { Migration } from "../../../../types/models/migration";
import { Iteration } from "../../../../types/models/iteration";
import { List } from "immutable";
import { Panel } from "../../../containers/panel";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { Grid } from "../../../widgets/grid";
import { MigrationStatus } from "../../../../types/models/migrationStatus";
import { IterationStatus } from "../../../../types/models/iterationStatus";
import { OperationStatus } from "../../../../types/operationStatus";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { friendlyCount, friendlyDateTime, friendlyDuration, friendlySize } from "../../../../utils/formatting";
import { Button } from "../../../widgets/button";

interface Props {
  migration: Migration;
  status: OperationStatus<List<Iteration>>;
  onViewDetailsClick: (iterationIndex: number) => void;
}

export const IterationsHistoryTool: React.FunctionComponent<Props> = (props) => {
  return (
    <Panel>
      <OperationStatusIndicator
        subject={"sync-up history"}
        status={props.status}
        indicators={StatusIndicators.PanelRow()}
      />
      {props.status.isSuccess() && (
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Pass</Grid.Column>
              <Grid.Column>Status</Grid.Column>
              <Grid.Column>Migrated</Grid.Column>
              <Grid.Column>Skipped</Grid.Column>
              <Grid.Column>Completed</Grid.Column>
              <Grid.Column/>
            </Grid.Header>
            <Grid.Body>
              {
                props.status.result
                  .sortBy((iteration) => -iteration.index)
                  .map((iteration) => (
                    <IterationRow
                      key={"iteration-" + iteration.index}
                      migration={props.migration}
                      iteration={iteration}
                      onViewDetailsClick={props.onViewDetailsClick}
                    />
                  ))
              }
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      )}
    </Panel>
  );
};

interface IterationRowProps {
  migration: Migration;
  iteration: Iteration;
  onViewDetailsClick: (iterationIndex: number) => void;
}

const IterationRow: React.FunctionComponent<IterationRowProps> = (props) => {
  function renderSize() {
    const totalItems = props.iteration.index === props.migration.iteration
      ? props.migration.iterationStats.migratedItems
      : props.iteration.stats.migratedItems;

    const totalBytes = props.iteration.index === props.migration.iteration
      ? props.migration.iterationStats.migratedBytes
      : props.iteration.stats.migratedBytes;

    if (totalItems !== undefined && totalBytes !== undefined) {
      return friendlyCount(totalItems, "item") + " / " + friendlySize(totalBytes);
    } else {
      return "Looking for updates...";
    }
  }

  return (
    <Grid.Row>
      <Grid.Cell>
        {props.iteration.index === 0 ? "Initial Migration" : ("Sync-up Pass #" + props.iteration.index)}
      </Grid.Cell>
      <Grid.Cell>
        {
          props.iteration.index === props.migration.iteration
            ? MigrationStatus.userFriendly(props.migration.status, props.migration.iteration)
            : IterationStatus.userFriendly(props.iteration.status)
        }
      </Grid.Cell>
      <Grid.Cell>{renderSize()}</Grid.Cell>
      <Grid.Cell>
        {
          friendlyCount(
            (
              props.iteration.index === props.migration.iteration
                ? props.migration.iterationStats.skippedItems
                : props.iteration.stats.skippedItems
            ) || 0,
            "item"
          )
        }
      </Grid.Cell>
      <Grid.Cell>
        {
          props.iteration.completedAt &&
          props.iteration.completedIn && (
            friendlyDateTime(props.iteration.completedAt) +
            " (in " + friendlyDuration(props.iteration.completedIn) + ")"
          )
        }
      </Grid.Cell>
      <Grid.ActionsCell>
        <Button
          color={"white"}
          size={"small"}
          onClick={() => props.onViewDetailsClick(props.iteration.index)}
        >
          View Details
        </Button>
      </Grid.ActionsCell>
    </Grid.Row>
  );
};
