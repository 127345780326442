import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { CouponCode } from "../../types/models/couponCode";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime, multiline } from "../../utils/formatting";
import { useSearchCouponCodesQuery } from "../../queries/couponCodes/useSearchCouponCodesQuery";
import { useDeleteCouponCodeMutation } from "../../queries/couponCodes/useDeleteCouponCodeMutation";
import { useUpdateCouponCodeMutation } from "../../queries/couponCodes/useUpdateCouponCodeMutation";
import { useInsertCouponCodeMutation } from "../../queries/couponCodes/useInsertCouponCodeMutation";
import { useGetCouponCodeQuery } from "../../queries/couponCodes/useGetCouponCodeQuery";
import { CouponCodeFormData } from "../../types/models/couponCodeFormData";
import { CouponCodeForm } from "../../views/screens/couponCodes/couponCodeForm";
import { WidgetStatus } from "../../views/utils/widgetStatus";
import { Link } from "react-router-dom";
import { Button } from "../../views/widgets/button";
import { useNavigate } from "react-router";
import { CenteredContent } from "../../views/layouts/centeredContent";

export const CouponCodesAdminPages: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const routes = useRoutes();

  return (
    <CenteredContent fullWidth={true}>
      {
        CrudPages<string, CouponCode, CouponCodeFormData>({
          title: "Coupon Codes",
          subject: "coupon code",
          additionalTools: [(
            <Button
              key={"batch-send"}
              size={"small"}
              color={"secondary"}
              onClick={() => navigate(routes.couponCodes.sendPath)}
            >
              Generate & Send in Bulk
            </Button>
          )],
          gridColumns: [
            {
              title: "ID",
              render: (couponCode) => couponCode.id
            },
            {
              title: "Internal notes",
              render: (couponCode) => multiline(couponCode.notes)
            },
            {
              title: "Status",
              render: (couponCode) => ({
                content: couponCode.enabled ? "Enabled" : "Disabled",
                status: couponCode.enabled ? undefined : WidgetStatus.Uncertain
              })
            },
            {
              title: "Discount",
              render: (couponCode) =>
                couponCode.isPercentage ? couponCode.discount + "%" : "$" + couponCode.discount.toFixed(2)
            },
            {
              title: "Last Used For",
              render: (couponCode) => ({
                status: couponCode.lastUsedFor ? undefined : WidgetStatus.Uncertain,
                content: couponCode.lastUsedFor
                  ? (
                    <Link to={routes.migrations.migrationStatusPath(couponCode.lastUsedFor)}>
                      {couponCode.lastUsedFor}
                    </Link>
                  )
                  : "Never used"
              })
            },
            {
              title: "Remaining Uses",
              render: (couponCode) =>
                couponCode.remainingUses !== undefined ? couponCode.remainingUses : "Unlimited"
            },
            {
              title: "Created At",
              render: (couponCode) => friendlyDateTime(couponCode.createdAt)
            },
          ],
          renderForm: (config) => <CouponCodeForm config={config}/>,

          useRoutes: () => useRoutes().couponCodes,
          getItemId: (couponCode) => couponCode.id,
          getItemTitle: (couponCode) => couponCode.id,
          clone: (couponCode) => ({ ...couponCode, id: "COPY_OF_" + couponCode.id }),
          compare: (a: CouponCode, b: CouponCode) => {
            const nameA = a.id.toLowerCase();
            const nameB = b.id.toLowerCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          },

          useGetQuery: useGetCouponCodeQuery,
          useSearchQuery: useSearchCouponCodesQuery,
          useInsertMutation: useInsertCouponCodeMutation,
          useUpdateMutation: () => {
            const [update, updateStatus] = useUpdateCouponCodeMutation();
            return [(id, formData) => update(formData), updateStatus];
          },
          useDeleteMutation: useDeleteCouponCodeMutation
        })
      }
    </CenteredContent>
  );
};
