import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

// The result is wrapped in an object to avoid confusion with undefined values
export function useGetCurrentAccessListUpdateQuery(
  id: number | undefined
): ManagedQueryHook<GraphQL.GetCurrentAccessListUpdateQueryVariables, { currentUpdateId: number | undefined }> {
  return useManagedQuery({
    query: GraphQL.useGetCurrentAccessListUpdateQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetCurrentAccessListUpdateQuery) => nullToUndefined(data.accessList),
    complete: (accessList) => ({ currentUpdateId: nullToUndefined(accessList.currentUpdateId) })
  });
}
