import { BlueprintContext } from "../blueprintContext";
import { ComponentHubs, Component } from "../component";
import { ComponentVisualization } from "../componentVisualization";
import { State } from "../state";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { None, Option, Some } from "../../utils/monads/option";
import { ComponentBinding } from "./componentBinding";
import { Set } from "immutable";

export class VariableComp extends Component<VariableComp.Props, VariableComp.Hubs, VariableComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<VariableComp.Output> {
    return context.inputs.get(this.props.inputId)
      .map((value) =>
        State.resolved({ value: this.props.emptyValues.contains(value) ? None<string>() : Some(value) })
      )
      .getOrElse(() => State.blocked());
  }

  public visualization(context: BlueprintContext): ComponentVisualization {
    return {
      title: "Variable",
      summary: this.props.inputId,
      sizeMultiplier: 1.5,
      icon: Images.Blueprint.Variable,
      color: ComponentColorSchema.SolidCoral,
    };
  }
}

export namespace VariableComp {
  export interface Props {
    inputId: string;
    emptyValues: Set<string>;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.VariableCompProps): Props {
      return {
        inputId: props.inputId,
        emptyValues: Set(props.emptyValues),
      };
    }
  }

  export type Hubs = ComponentHubs;

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.VariableCompHubsFragment): Hubs {
      return ComponentHubs.fromGraphQL(binding, hubs);
    }
  }

  export interface Output {
    value: Option<string>;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.VariableCompOutputFragment): Output {
      return {
        value: Option.mayBe(output.variableCompOutputValue)
      };
    }
  }
}
