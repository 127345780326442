import { GraphQL } from "../../services/graphql/generated";
import { Fact } from "./fact";
import { ObjectRepo } from "../../utils/objectRepo";
import { Option } from "../../utils/monads/option";
import { withoutUndefined } from "../../utils/misc";
import { FactRef } from "./factRef";
import { Map, Set } from "immutable";

export class Facts extends ObjectRepo<Fact> {
  public readonly fingerprint: string;

  constructor(data?: Map<string, Fact> | Fact[]) {
    super(data);

    this.fingerprint = JSON.stringify(
      this.map.map((fact) => [fact.id, fact.state]).toArray()
    );
  }

  public find(factRef: FactRef.Resolved): Option<Fact> {
    return this.getOption(factRef.id)
      .filter((fact) => fact.family === factRef.family && fact.valueType === factRef.valueType);
  }

  public concat(other: Facts): Facts {
    return new Facts(this.map.merge(other.map));
  }

  public subset(factIds: Set<string>): Facts {
    return new Facts(this.map.filter((fact, factId) => factIds.contains(factId)));
  }
}

export namespace Facts {
  export const Empty: Facts = new Facts();

  export function fromGraphQLList(facts: GraphQL.FactOrPlaceholderFragment[]): Facts {
    return new Facts(
      withoutUndefined(facts.map((fact) =>
        fact.__typename === "Fact" && fact.state.__typename !== Fact.MissingFactStateTypeName
          ? Fact.fromGraphQL(fact)
          : undefined
        ))
    );
  }
}
