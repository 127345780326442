import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useOffboardingProjectImportController } from "../../../queries/api/useFileUploadController";
import { useBrowser } from "../../../utils/useBrowser";
import { useGetOffboardingProjectQuery } from "../../../queries/automatedOffboarding/useGetOffboardingProjectQuery";
import {
  useGetOffboardingProjectEntryCountQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectEntryCountQuery";
import {
  useGetOffboardingProjectUpdateQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectUpdateQuery";
import { AccessListUpdateStatus } from "../../../types/enums/accessListUpdateStatus";
import {
  ImportOffboardingProjectEntriesPageView
} from "../../../views/screens/organizationPortal/automatedOffboarding/importOffboardingProjectEntriesPageView";
import { useManagedQueryController } from "../../../utils/controller";

interface Props {
  organization: OrganizationSummary;
}

export const ImportOffboardingProjectEntriesPage: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const apiRoutes = useRoutes().api;
  const offboardingProjectsRoutes = useRoutes().organizationPortalRoutes.automatedOffboarding(props.organization.alias);
  const params = offboardingProjectsRoutes.importParams();

  const fileUploadController = useOffboardingProjectImportController(params.id);

  const [trackedUpdateId, setTrackedUpdateId] = React.useState<string | undefined>(undefined);

  const [offboardingProjectStatus, refreshOffboardingProject] = useGetOffboardingProjectQuery(params.id);

  const [entryCountStatus, refreshEntryCount] = useGetOffboardingProjectEntryCountQuery(params.id);
  const entryCountController = useManagedQueryController([entryCountStatus, refreshEntryCount]);

  const currentUpdateId = offboardingProjectStatus.someResult()?.currentUpdateId || trackedUpdateId;

  const [offboardingProjectUpdateStatus, refreshOffboardingProjectUpdate] =
    useGetOffboardingProjectUpdateQuery(currentUpdateId);

  function refresh(offboardingProjectUpdateId: string) {
    refreshOffboardingProject({}, true);
    refreshEntryCount();
    refreshOffboardingProjectUpdate({ id: offboardingProjectUpdateId }, true);
  }

  React.useEffect(
    () => {
      if (currentUpdateId) {
        const timer = setInterval(() => refresh(currentUpdateId), 5000);
        return () => clearInterval(timer);
      }
    },
    [currentUpdateId]
  );

  return (
    <ImportOffboardingProjectEntriesPageView
      offboardingProjectId={params.id}
      offboardingProjectStatus={offboardingProjectStatus}
      offboardingProjectUpdateStatus={offboardingProjectUpdateStatus}

      displayListUpdateInfo={
        trackedUpdateId !== undefined ||
        offboardingProjectUpdateStatus.mapLastResult((offboardingProjectUpdate) =>
          offboardingProjectUpdate.status !== AccessListUpdateStatus.Success
        ) || false
      }

      entryCountController={entryCountController}

      homePath={offboardingProjectsRoutes.homePath}
      offboardingProjectPath={offboardingProjectsRoutes.overviewPath(params.id)}

      fileUploadController={fileUploadController}
      onUpload={(offboardingProjectUpdateId) => {
        refresh(offboardingProjectUpdateId);
        setTrackedUpdateId(offboardingProjectUpdateId);
      }}

      onDownloadOutput={(offboardingProjectUpdateId) =>
        browser.openNewTab(apiRoutes.offboardingProjectUpdateOutputUrl(offboardingProjectUpdateId), true)
      }
    />
  );
};
