import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";

export interface ComponentColorSchema {
  background: string | undefined;
  border: string;
}

export namespace ComponentColorSchema {
  export function fromGraphQL(schema: GraphQL.ComponentColorSchema): ComponentColorSchema {
    return {
      background: nullToUndefined(schema.background),
      border: schema.border
    };
  }

  export function solid(color: string): ComponentColorSchema {
    return {
      background: color,
      border: color
    };
  }

  export function hollow(borderColor: string): ComponentColorSchema {
    return {
      background: undefined,
      border: borderColor
    };
  }

  export const SolidBlack = solid("#000000");

  export const SolidPurple = solid("#B6004E");
  export const SolidGreen = solid("#00912F");
  export const SolidRed = solid("#C00E0E");
  export const SolidBlue = solid("#0079AE");
  export const SolidDarkBlue = solid("#334A73");
  export const SolidGray = solid("#888888");
  export const SolidBrown = solid("#9C5900");
  export const SolidYellow = solid("#dc8700");
  export const SolidCoral = solid("#952726");

  export const HollowBrown = hollow("#9C5900");
}
