import { BlueprintContext } from "../blueprintContext";
import { BlueprintException } from "../blueprintException";
import { AnyComponent } from "../component";
import { DataFlow, DataFlowProps } from "../relationship";
import { State } from "../state";
import { nullToUndefined } from "../../utils/misc";
import { GraphQL } from "../../services/graphql/generated";
import { Variant } from "../../types/models/variant";

export class Plug<Output> extends DataFlow<Plug.Props<Output>, Output> {
  public component(): AnyComponent | undefined {
    return undefined;
  }

  public calcState(context: BlueprintContext): State<Output> {
    return State.resolved(this.props.value);
  }

  public stateWhenUnblocked(context: BlueprintContext, componentState: State<unknown>): State<Output> {
    throw new BlueprintException("Must not be called");
  }
}

export namespace Plug {
  export interface Props<Output> extends DataFlowProps<Output> {
    value: Output;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.PlugPropsFragment): Props<any> {
      return {
        title: nullToUndefined(props.title),
        value: Variant.fromGraphQL(props.value)
      };
    }
  }
}
