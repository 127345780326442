import { NamedRegistry } from "./registry";

export interface Comparator<T> extends NamedRegistry.HasName {
  name: string;

  title: string | undefined;

  compare(a: T, b: T): number | undefined;
}

export function Comparator<T>(
  name: string,
  title: string | undefined,
  compare: (a: T, b: T) => number | undefined
): Comparator<T> {
  return { name, title, compare };
}

export namespace Comparator {
  export type CompareFunction<C> = (a: C, b: C) => number | undefined;
}

export type AnyComparator = Comparator<any>;
