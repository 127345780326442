import * as React from "react";
import { HasClickHandler, SimpleClickHandler } from "./simpleClickHandler";
import { DrawerBackground, useDrawer } from "../layouts/drawer";
import { isPreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { useBrowser } from "../../utils/useBrowser";

interface ClickableComponentProps {
  component: React.ComponentType<HasClickHandler>;
  props: object;
  onSimpleClick?: SimpleClickHandler;
  drawerBackground?: DrawerBackground;
}

export function ClickableComponent(props: ClickableComponentProps): React.ReactElement | null {
  const drawer = useDrawer();
  const browser = useBrowser();

  function onClick() {
    if (props.onSimpleClick) {
      if (typeof props.onSimpleClick === "string") {
        browser.navigateTo(props.onSimpleClick);
      } else if (typeof props.onSimpleClick === "function") {
        (props.onSimpleClick as (() => void))();
      } else if (isPreparedHelpArticle(props.onSimpleClick)) {
        drawer.open({
          content: props.onSimpleClick.content,
          title: props.onSimpleClick.title
        });
      } else {
        drawer.open({
          content: props.onSimpleClick,
          background: props.drawerBackground
        });
      }
    }
  }

  return React.createElement(props.component, { ...props.props, onClick });
}
