import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { TaskHistoryRecord } from "./taskHistoryRecord";
import { TaskIssue } from "./taskIssue";

export class TaskHistory implements TaskHistory.Props {
  constructor(protected readonly props: TaskHistory.Props) {
  }

  public get jobId(): string { return this.props.jobId; }
  public get taskId(): string { return this.props.taskId; }

  public get task(): TaskHistoryRecord { return this.props.task; }
  public get taskHistory(): List<TaskHistoryRecord> { return this.props.taskHistory; }
  public get taskIssues(): List<TaskIssue> { return this.props.taskIssues; }

  public get pendingTaskIssuesCount(): number {
    return this.taskIssues.filter((taskIssue) => !taskIssue.resolvedAt).size;
  }

  public get resolvedTaskIssuesCount(): number {
    return this.taskIssues.size - this.pendingTaskIssuesCount;
  }

  public toEvents(): List<TaskHistory.Event> {
    return this.taskHistory
      .concat(
        this.taskIssues
          .map((taskIssue) => taskIssue.toEvents())
          .reduce((a, b) => a.concat(b), List())
      )
      .sortBy((event: TaskHistory.Event) => event.timestamp)
      .reverse();
  }
}

export namespace TaskHistory {
  export type Event = TaskHistoryRecord | TaskIssue.Event;

  export interface Props {
    readonly jobId: string;
    readonly taskId: string;

    readonly task: TaskHistoryRecord;
    readonly taskHistory: List<TaskHistoryRecord>;
    readonly taskIssues: List<TaskIssue>;
  }

  export function fromGraphQL(taskHistory: GraphQL.TaskHistory): TaskHistory {
    return new TaskHistory({
      jobId: taskHistory.jobId,
      taskId: taskHistory.taskId,
      task: TaskHistoryRecord.fromGraphQL(taskHistory.task),
      taskHistory: List(taskHistory.taskHistory.map(TaskHistoryRecord.fromGraphQL)),
      taskIssues: List(taskHistory.taskIssues.map(TaskIssue.fromGraphQL)),
    });
  }
}
