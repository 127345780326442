import { AnyComparator } from "./comparator";
import { CollectableInteger } from "../types/collectables/collectableInteger";
import { CollectableItemBreakdown } from "../types/collectables/collectableItemBreakdown";
import { NamedRegistry } from "./registry";

export const ComparatorRegistry = NamedRegistry.fromArrays<AnyComparator>(
  "comparator",
  CollectableInteger.Comparators,
  CollectableItemBreakdown.Comparators
);
