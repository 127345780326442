import * as React from "react";
import * as yup from "yup";
import { AccessList } from "../../../../types/models/accessList";
import { FormLayout } from "../../../widgets/formLayout";
import { TextField } from "../../../widgets/textField";
import { FormField } from "../../../widgets/formField";
import { friendlyDateTime } from "../../../../utils/formatting";
import { ItemForm } from "../../../blocks/crud/itemForm";
import { FormConfig } from "../../../blocks/crud/formConfig";
import { AccessListFormData } from "../../../../types/models/accessListFormData";
import { TextAreaField } from "../../../widgets/textAreaField";
import { CheckboxField } from "../../../widgets/checkboxField";
import { DropDownField } from "../../../widgets/dropDownField";
import {
  ProgramDetailsControllerType,
  ProgramLookupControllerType, ProgramLookupField,
  ProgramViewPathFactory
} from "../../../blocks/lookups/programLookupField";
import { Button } from "views/widgets/button";
import { styled } from "../../../../app/theme";
import { ModalController, useModal } from "../../../layouts/modal";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Block } from "views/containers/block";
import {
  GoogleGroupDetailsController
} from "../../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import { GoogleGroupIdField } from "./googleGroupIdField";
import { BlockingMessagePreviewModal } from "./blockingMessagePreviewModal";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { HelpArticles } from "../../../../app/helpArticles";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { LinkButton } from "views/widgets/linkButton";

enum AccessListType {
  ProgramAllowlist = "ProgramAllowlist",
  OrganizationBlockList = "OrganizationBlockList"
}

enum SourceType {
  CSV = "CSV",
  GoogleGroup = "GoogleGroup"
}

interface FormData {
  name: string;
  notes: string;

  isEnabled: boolean;
  accessListType: AccessListType;
  programId: string | undefined;
  blockingMessage: string;

  sourceType: SourceType;
  googleGroupId: string;
}

namespace FormData {
  export const blank: FormData = {
    name: "",
    notes: "",

    isEnabled: true,
    accessListType: AccessListType.ProgramAllowlist,
    programId: undefined,
    blockingMessage: "",

    sourceType: SourceType.CSV,
    googleGroupId: ""
  };

  export function make(accessList: AccessList): FormData {
    return {
      name: accessList.name,
      notes: accessList.notes || "",

      isEnabled: accessList.isEnabled,
      accessListType: accessList.isProgramAllowlist
        ? AccessListType.ProgramAllowlist
        : AccessListType.OrganizationBlockList,
      programId: accessList.program?.id,
      blockingMessage: accessList.blockingMessage || "",

      sourceType: accessList.googleGroupId !== undefined ? SourceType.GoogleGroup : SourceType.CSV,
      googleGroupId: accessList.googleGroupId || ""
    };
  }

  export function toResult(formData: FormData): AccessListFormData {
    return {
      name: formData.name.trim(),
      notes: formData.notes.length ? formData.notes : undefined,

      isEnabled: formData.isEnabled,
      isProgramAllowlist: formData.accessListType === AccessListType.ProgramAllowlist,
      programId: formData.programId,
      blockingMessage: formData.blockingMessage.length ? formData.blockingMessage : undefined,

      googleGroupId: formData.sourceType === SourceType.GoogleGroup ? formData.googleGroupId : undefined
    };
  }

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    name: yup.string().required("Name is required"),
    notes: yup.string(),

    isEnabled: yup.boolean(),
    accessListType: yup.mixed().required().oneOf([
      AccessListType.ProgramAllowlist,
      AccessListType.OrganizationBlockList
    ]),
    programId: yup.string(),
    blockingMessage: yup.string(),

    sourceType: yup.mixed().required().oneOf([
      SourceType.CSV,
      SourceType.GoogleGroup
    ]),
    googleGroupId: yup.string()
  }).test(
    "programIdIsDefined",
    "Program is required",
    (formData: FormData) => {
      if (formData.accessListType === AccessListType.ProgramAllowlist && !formData.programId) {
        return new yup.ValidationError("Program is required", undefined, "programId");
      } else if (formData.sourceType === SourceType.GoogleGroup && !formData.googleGroupId) {
        return new yup.ValidationError("Google Group ID is required", undefined, "googleGroupId");
      } else {
        return true;
      }
    }
  );
}

interface Props {
  config: FormConfig<AccessList, AccessListFormData>;

  programLookupController: ProgramLookupControllerType;
  programDetailsController: ProgramDetailsControllerType;
  programViewPathFactory: ProgramViewPathFactory;

  googleGroupDetailsController: GoogleGroupDetailsController;
}

export const AccessListForm: React.FunctionComponent<Props> = (props) => {
  const previewModal = useModal();
  const googleGroupConfigurationHelpArticleModal = useModal();

  return (
    <ItemForm<AccessList, FormData, AccessListFormData>
      config={props.config}

      subject={"access list"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.blank}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, submitting, formProps }) => (
        <>
          <Block>
            <Panel>
              <TitlePanelRow>General Settings</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Name"}
                    name={"name"}
                    required={true}
                    disabled={submitting}
                    readOnly={props.config.readOnly}
                    maxLength={200}
                    textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
                  />
                  <TextAreaField<FormData>
                    label={"Notes"}
                    name={"notes"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <CheckboxField<FormData>
                    label={"Enabled"}
                    name={"isEnabled"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  {
                    props.config.item && !props.config.isNew &&
                      <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                  }
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>List Type Settings</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <DropDownField<FormData>
                    label={"List type"}
                    name={"accessListType"}
                    disabled={submitting}
                    readOnly={props.config.readOnly}
                  >
                    <option value={AccessListType.ProgramAllowlist}>
                      Program allowlist
                    </option>
                    <option value={AccessListType.OrganizationBlockList}>
                      Blocklist
                    </option>
                  </DropDownField>
                  {
                    formProps.values.accessListType === AccessListType.ProgramAllowlist
                      ? (
                        <ProgramLookupField<FormData>
                          name={"programId"}
                          required={true}
                          readOnly={props.config.readOnly}
                          disabled={submitting}
                          lookupController={props.programLookupController}
                          detailsController={props.programDetailsController}
                          viewPathFactory={props.programViewPathFactory}
                        />
                      )
                      : (
                        <>
                          <BlockingMessagePreviewModal
                            modal={previewModal}
                            blockingMessage={formProps.values.blockingMessage}
                          />
                          <TextAreaField<FormData>
                            label={"Message displayed to end users"}
                            name={"blockingMessage"}
                            readOnly={props.config.readOnly}
                            disabled={submitting}
                          >
                            <PreviewButtonContainer>
                              <Button size={"small"} color={"secondary"} onClick={previewModal.open}>
                                Preview
                              </Button>
                            </PreviewButtonContainer>
                          </TextAreaField>
                        </>
                      )
                  }
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>Entry List Settings</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <DropDownField<FormData>
                    label={"Source of list"}
                    name={"sourceType"}
                    disabled={submitting}
                    readOnly={props.config.readOnly}
                  >
                    <option value={SourceType.CSV}>
                      CSV
                    </option>
                    <option value={SourceType.GoogleGroup}>
                      Google Group
                    </option>
                  </DropDownField>
                  {formProps.values.sourceType === SourceType.GoogleGroup && (
                    <>
                      <ModalController
                        modal={googleGroupConfigurationHelpArticleModal}
                        title={HelpArticles.googleGroupConfiguration.title}
                        render={() =>
                          <>{PreparedHelpArticle.fromExternal(HelpArticles.googleGroupConfiguration).content}</>
                        }
                      />
                      <FormField>
                        This access list entries will be auto-populated from the list of members of the Google Group
                        configured below.{" "}
                        <LinkButton onClick={googleGroupConfigurationHelpArticleModal.open}>Learn more</LinkButton>
                      </FormField>
                      <GoogleGroupIdField<FormData>
                        label={"Google Group ID"}
                        name={"googleGroupId"}
                        controller={props.googleGroupDetailsController}
                        required={true}
                        disabled={submitting}
                        readOnly={props.config.readOnly}
                      />
                    </>
                  )}
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
        </>
      )}
    </ItemForm>
  );
};

const PreviewButtonContainer = styled.div`
  margin-top: 0.5rem;
`;
