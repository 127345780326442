import * as React from "react";
import { Page } from "../containers/page";
import { Block } from "../containers/block";
import { User } from "../../types/models/user";
import { OperationStatus } from "../../types/operationStatus";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";

interface Props {
  userId: string | undefined;
  userStatus: OperationStatus<User>;

  renderUserSelector: () => React.ReactNode;
  renderUserProfile: (user: User) => React.ReactNode;
}

export const UsersPageView: React.FunctionComponent<Props> = (props) => (
  <Page
    title={props.userStatus.isSuccess()
      ? "User Profile for "  + props.userStatus.result.personalInfo.fullNameOrEmailAddress
      : "Users"
    }
    fullWidth={true}
  >
    {props.userId
      ? (
        <>
          <OperationStatusIndicator
            subject={"user"}
            status={props.userStatus}
            indicators={StatusIndicators.SimplePanel()}
          />
          {props.userStatus.isSuccess() && props.renderUserProfile(props.userStatus.result)}
        </>
      )
      : (
        <Block>
          {props.renderUserSelector()}
        </Block>
      )}
  </Page>
);
