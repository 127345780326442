// tslint:disable-next-line import-name
import mixpanel from "mixpanel-browser";

let mixpanelDistinctId: string | undefined;

mixpanel.init((window as any).MP_TOKEN, {
  loaded: (instance) => {
    mixpanelDistinctId = instance.get_distinct_id();
  }
});

export namespace Mixpanel {
  export function distinctId(): string | undefined {
    return mixpanelDistinctId;
  }

  // The app is re-loaded on sign-out, so we can simply overwrite the original distinct Id assigned by Mixpanel
  export function setUserId(userId: string): void {
    mixpanelDistinctId = userId;
  }
}
