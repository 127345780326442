import * as React from "react";
import { WorkflowController } from "../../../wizardry/workflowController";
import { WorkflowLayout } from "../../layouts/workflowLayout";
import { CenteredContent } from "../../layouts/centeredContent";
import { SimpleErrorPanel } from "../../containers/simplePanel";
import { UserFacingError } from "../../../types/userFacingError";

export const WorkflowError: React.FunctionComponent<WorkflowController.ErrorSummary> = (props) =>
  props.onTryAgain
    ? (
      <WorkflowLayout>
        <CenteredContent>
          <SimpleErrorPanel
            error={UserFacingError.unexpected(props.error, {
              summary: "Failed to load required data.",
              retry: props.onTryAgain,
              cancel: props.onCancel,
              actions: props.onCleanUp ? [{ title: "Repair", onClick: props.onCleanUp }] : []
            })}
          />
        </CenteredContent>
      </WorkflowLayout>
    )
    : (
      <WorkflowLayout>
        <CenteredContent>
          <SimpleErrorPanel
            error={UserFacingError.unexpected(props.error, {
              recommendations: "Please try refreshing this page in your browser."
            })}
          />
        </CenteredContent>
      </WorkflowLayout>
    );
