import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";

export function useGetOrganizationPortalUserQuery(
  id: number
): ManagedQueryHook<GraphQL.GetOrganizationPortalUserQueryVariables, OrganizationPortalUser> {
  return useManagedQuery({
    query: GraphQL.useGetOrganizationPortalUserQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetOrganizationPortalUserQuery) => nullToUndefined(data.organizationPortalUser),
    complete: (organizationPortalUser) => OrganizationPortalUser.parse(organizationPortalUser),
    fetchPolicy: "cache-and-network"
  });
}
