import * as React from "react";
import moment from "moment";
import { Migration } from "../../../../types/models/migration";
import { MigrationStatus } from "../../../../types/models/migrationStatus";
import { UserFacingError } from "../../../../types/userFacingError";
import { PanelRow } from "../../../containers/rows/panelRow";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { ErrorInfo } from "../../../widgets/errorInfo";
import { Button } from "../../../widgets/button";
import { HelpArticles } from "../../../../app/helpArticles";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import { Constants } from "../../../../app/constants";
import { friendlyDate } from "../../../../utils/formatting";
import { ReportIcon } from "../../../glyphs/reportIcon";

interface MigrationReportPanelRowProps {
  migration: Migration;
  downloadUrl: string;
}

export const ValidReportUrlPrefix = "s3://";

function reportGenerationFailed(migration: Migration): boolean {
  return (
    migration.status === MigrationStatus.Completed &&
    migration.reportUrl !== undefined &&
    !migration.reportUrl.startsWith(ValidReportUrlPrefix)
  );
}

function isSimulation(migration: Migration): boolean {
  return migration.reportUrl === ValidReportUrlPrefix;
}

export const MigrationReportRow: React.FunctionComponent<MigrationReportPanelRowProps> = (props) => {
  const reportUrl = props.migration.reportUrl;

  if (props.migration.status === MigrationStatus.Completed) {
    if (!reportUrl) {
      return (
        <>
          <IndefiniteProgressBar/>
          <PanelRow icon={<ReportIcon/>}>
            A report containing the details of all migrated and skipped items is being generated...
          </PanelRow>
        </>
      );
    } else if (reportGenerationFailed(props.migration)) {
      return (
        <>
          <IndefiniteProgressBar error={true}/>
          <PanelRow icon={<ReportIcon/>}>
            <ErrorInfo
              error={
                UserFacingError.synthetic({
                  summary: "An unexpected error happened while generating migration report",
                  technicalDetails: [
                    {
                      title: "Error message",
                      content: reportUrl,
                      preFormatted: false
                    }
                  ],
                  showTechnicalDetails: true
                })
              }
            />
          </PanelRow>
        </>
      );
    } else {
      return (
        <ToolsRow
          icon={<ReportIcon/>}
          helpArticle={PreparedHelpArticle.fromExternal(HelpArticles.migrationReport)}
          header={
            "Download a report containing the details of all migrated and skipped items. " +
            "The report is available for " + Constants.MigrationReportLifetimeDays +
            " days after the the migration is completed" +
            (
              props.migration.completedAt
                ? " (until " +
                friendlyDate(
                  moment(props.migration.completedAt).add(Constants.MigrationReportLifetimeDays, "day").toDate()
                )
                + ")"
                : ""
            ) + "."
          }
        >
          {/* There used to be download={true} attribute instead of target={"_blank"}, but it did not work well with */}
          {/* error responses. Chrome did not show the error message from the server, there was something generic */}
          {/* instead. The message from the server could not be found anywhere! */}
          {
            isSimulation(props.migration)
              ? <Button color={"white"} size={"small"}>Download Report</Button>
              : (
                <a href={props.downloadUrl} target={"_blank"}>
                  <Button color={"white"} size={"small"}>Download Report</Button>
                </a>
              )
          }
        </ToolsRow>
      );
    }
  } else {
    return null;
  }
};
