import { Blueprint } from "../blueprint";
import { GraphQL } from "../../services/graphql/generated";
import { ComponentHubSettings } from "../hub";
import { AssociationsHub } from "../hubs/associationsHub";
import { MultipleRelationshipsHub } from "../hubs/multipleRelationshipsHub";
import { PreconditionsHub } from "../hubs/preconditionsHub";
import { HubBinding } from "../hubBinding";
import { ArrayHub } from "../hubs/arrayHub";
import { ExactlyOneRelationshipHub } from "../hubs/exactlyOneRelationshipHub";
import { OptionalRelationshipHub } from "../hubs/optionalRelationshipHub";
import { SelectorHub } from "../hubs/selectorHub";
import { VarsHub } from "../hubs/varsHub";
import { MigrationFlowsHub } from "../hubs/migrationFlowsHub";

export class ComponentBinding {
  constructor(
    public readonly blueprint: Blueprint,
    public readonly componentId: string
  ) {
  }

  public hub(hubName: string): HubBinding {
    return {
      blueprint: this.blueprint,
      sourceComponentId: this.componentId,
      hubName
    };
  }

  public arrayHub(
    name: string,
    hub: GraphQL.ArrayHubFragment,
    settings?: ComponentHubSettings
  ): ArrayHub<any> {
    return ArrayHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public associationsHub(
    name: string,
    hub: GraphQL.AssociationsHubFragment,
    settings?: ComponentHubSettings
  ): AssociationsHub {
    return AssociationsHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public exactlyOneRelationshipHub(
    name: string,
    hub: GraphQL.ExactlyOneRelationshipHubFragment,
    settings?: ComponentHubSettings
  ): ExactlyOneRelationshipHub<any> {
    return ExactlyOneRelationshipHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public migrationFlowsHub(
    name: string,
    hub: GraphQL.MigrationFlowsHubFragment,
    settings?: ComponentHubSettings
  ): MigrationFlowsHub {
    return MigrationFlowsHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public multipleRelationshipsHub(
    name: string,
    hub: GraphQL.MultipleRelationshipsHubFragment,
    settings?: ComponentHubSettings
  ): MultipleRelationshipsHub<any> {
    return MultipleRelationshipsHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public optionalRelationshipHub(
    name: string,
    hub: GraphQL.OptionalRelationshipHubFragment,
    settings?: ComponentHubSettings
  ): OptionalRelationshipHub<any> {
    return OptionalRelationshipHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public preconditionsHub(
    name: string,
    hub: GraphQL.PreconditionsHubFragment,
    settings?: ComponentHubSettings
  ): PreconditionsHub {
    return PreconditionsHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public selectorHub(
    name: string,
    hub: GraphQL.SelectorHubFragment,
    settings?: ComponentHubSettings
  ): SelectorHub<any> {
    return SelectorHub.fromGraphQL(this.hub(name), hub, settings);
  }

  public varsHub(
    name: string,
    hub: GraphQL.VarsHubFragment,
    settings?: ComponentHubSettings
  ): VarsHub {
    return VarsHub.fromGraphQL(this.hub(name), hub, settings);
  }
}
