import { List, Map } from "immutable";
import { Option } from "./monads/option";

export class ObjectRepo<T extends ObjectRepo.HasId> {
  public readonly map: Map<string, T>;

  constructor(data?: Map<string, T> | T[]) {
    this.map = data
      ? (Map.isMap(data) ? data : Map(data.map((item) => [item.id, item])))
      : Map();
  }

  public get(id: string): T | undefined {
    return this.map.get(id);
  }

  public getOption(id: string): Option<T> {
    return Option.mayBe(this.map.get(id));
  }

  public list(): List<T> {
    return this.map.valueSeq().toList();
  }

  public equals(other: ObjectRepo<T>): boolean {
    return this.map.equals(other.map);
  }

  public concat(other: ObjectRepo<T>): ObjectRepo<T> {
    return new ObjectRepo(this.map.merge(other.map));
  }
}

export namespace ObjectRepo {
  export interface HasId {
    id: string;
  }
}
