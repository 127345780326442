import { Connection } from "../../types/models/connection";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

type AccessKeyRevokeHook = [() => Promise<Connection>, OperationStatus<Connection>];

export function useAccessKeyRevoke(connectionId: string, force: boolean): AccessKeyRevokeHook {
  const [revokeAccessKey, { status }] = useManagedMutation({
    mutation: GraphQL.useRevokeAccessKeyMutation,
    extract: (data: GraphQL.RevokeAccessKeyMutation) => nullToUndefined(data.revokeAccessKey),
    complete: (connection) => Connection.fromGraphQL(connection)
  });

  function revoke(): Promise<Connection> {
    return revokeAccessKey({
      variables: { connectionId, force },
      retry: revoke,
    });
  }

  return [revoke, status];
}
