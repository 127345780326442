import * as React from "react";
import { List } from "immutable";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { Panel } from "../../../containers/panel";
import { Grid } from "../../../widgets/grid";
import { Button } from "views/widgets/button";
import { PanelRow } from "views/containers/rows/panelRow";
import { Upload } from "../../../../types/models/upload";
import { styled } from "../../../../app/theme";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import { useCloudServices } from "../../../../app/configuration";
import { ConfigurationCloudService } from "../../../../types/models/configurationCloudService";
import { ConfigurationCloudServiceFormData } from "../../../../types/models/configurationCloudServiceFormData";
import { TextBox } from "../../../widgets/textBox";
import { TextArea } from "views/widgets/textArea";
import { FileUpload, FileUploadController, ImageUploadPreview } from "../../../widgets/fileUpload";
import { ModalController, useModal } from "../../../layouts/modal";
import { CloudServiceListPopup } from "../../../blocks/cloudServiceChooser/cloudServiceListPopup";
import { AuthContext } from "../../../blocks/authContext";

export interface FormData {
  readonly cloudServiceId: string;
  readonly index: number;
  readonly reference: string;
  readonly description: string;
  readonly logo: Upload | undefined;
  readonly excludedApps: string;
}

export namespace FormDataUtils {
  export function make(configurationCloudService: ConfigurationCloudService): FormData {
    return {
      cloudServiceId: configurationCloudService.cloudServiceId,
      index: configurationCloudService.index,
      reference: configurationCloudService.reference || "",
      description: configurationCloudService.description || "",
      logo: configurationCloudService.logo,
      excludedApps: configurationCloudService.excludedApps || ""
    };
  }

  export function toResult(isSource: boolean, formData: FormData): ConfigurationCloudServiceFormData {
    return {
      isSource,
      cloudServiceId: formData.cloudServiceId,

      index: formData.index,
      reference: formData.reference.length ? formData.reference : undefined,
      description: formData.description.length ? formData.description : undefined,
      logoId: formData.logo?.id,
      excludedApps: formData.excludedApps.length ? formData.excludedApps : undefined
    };
  }
}

const StyledPanel = styled(Panel)`
  max-width: 120rem;
`;

interface Props {
  readOnly: boolean;

  isSource: boolean;
  cloudServices: List<FormData>;
  fileUploadController: FileUploadController;

  onChange: (cloudServices: List<FormData>) => void;
}

export const ConfigurationCloudServicesPanel: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const modal = useModal();

  function updateIndexes(items: List<FormData>): List<FormData> {
    return items.map((item, index) => ({ ...item, index }));
  }

  const sortedCloudServices = props.cloudServices.sortBy((item) => item.index);

  return (
    <StyledPanel>
      <ModalController
        modal={modal}
        render={(close) => (
          <CloudServiceListPopup
            cloudServices={cloudServices}
            onCloudServiceSelect={(cloudServiceId) => {
              const maxIndex = props.cloudServices.map((item) => item.index).max();
              if (!props.cloudServices.find((item) => item.cloudServiceId === cloudServiceId)) {
                props.onChange(props.cloudServices.push({
                  cloudServiceId,

                  index: maxIndex === undefined ? 0 : maxIndex + 1,
                  reference: "",
                  description: "",
                  logo: undefined,
                  excludedApps: ""
                }));
                close();
              }
            }}
            authContext={props.isSource ? AuthContext.Source : AuthContext.Destination}
          />
        )}
      />
      <TitlePanelRow>{props.isSource ? "Source" : "Destination"} Cloud Services</TitlePanelRow>
      {!props.readOnly && (
        <PanelRow>
          <Button color={"secondary"} size={"small"} onClick={modal.open}>Add</Button>
        </PanelRow>
      )}
      {!props.cloudServices.isEmpty() && (
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Cloud Service</Grid.Column>
              <Grid.Column>Reference</Grid.Column>
              <Grid.Column>Description</Grid.Column>
              <Grid.Column>Logo</Grid.Column>
              <Grid.Column>Excluded Apps</Grid.Column>
              <Grid.Column/>
            </Grid.Header>
            <Grid.Body>
              {
                sortedCloudServices.map((item, index) => (
                  <CloudServiceRow
                    key={item.cloudServiceId}
                    formData={item}
                    fileUploadController={props.fileUploadController}
                    isFirst={index === 0}
                    isLast={index === props.cloudServices.size - 1}
                    readOnly={props.readOnly}

                    onMoveUp={() =>
                      props.onChange(updateIndexes(sortedCloudServices.remove(index).insert(index - 1, item)))
                    }
                    onMoveDown={() =>
                      props.onChange(updateIndexes(sortedCloudServices.remove(index).insert(index + 1, item)))
                    }
                    onChange={(formData) => props.onChange(sortedCloudServices.set(index, formData))}
                    onRemove={() => props.onChange(sortedCloudServices.remove(index))}
                  />
                ))
              }
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      )}
    </StyledPanel>
  );
};

const CloudServiceNameAndIcon = styled.div<{ icon: any }>`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: .85rem;
  background-position-y: .15rem;
  padding-left: 1.3rem;
`;

const Buttons = styled.div`
  display: flex;

  *:not(:first-child) {
    margin-left: .5rem;
  }
`;

interface CloudServiceRowProps {
  formData: FormData;
  fileUploadController: FileUploadController;
  isFirst: boolean;
  isLast: boolean;
  readOnly: boolean;

  onChange: (formData: FormData) => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
}

const CloudServiceRow: React.FunctionComponent<CloudServiceRowProps> = (props) => {
  const cloudServices = useCloudServices();
  const cloudService = cloudServices.get(props.formData.cloudServiceId);

  return (
    <Grid.Row>
      <Grid.Cell>
        {
          cloudService
            ? <CloudServiceNameAndIcon icon={cloudService.icon}>{cloudService.name}</CloudServiceNameAndIcon>
            : props.formData.cloudServiceId
        }
      </Grid.Cell>
      <Grid.Cell style={{ minWidth: "15rem" }}>
        <TextBox
          readOnly={props.readOnly}
          maxLength={200}
          value={props.formData.reference}
          onChange={(event) => props.onChange({ ...props.formData, reference: event.target.value })}
        />
      </Grid.Cell>
      <Grid.Cell style={{ minWidth: "15rem" }}>
        <TextArea
          readOnly={props.readOnly}
          value={props.formData.description}
          onChange={(event) => props.onChange({ ...props.formData, description: event.target.value })}
        />
      </Grid.Cell>
      <Grid.Cell style={{ minWidth: "15rem" }}>
        <FileUpload
          readOnly={props.readOnly}
          controller={props.fileUploadController}
          upload={props.formData.logo}
          preview={props.formData.logo && <ImageUploadPreview id={props.formData.logo.id}/>}
          onUpload={(upload) => props.onChange({
            ...props.formData,
            logo: {
              id: upload.response,
              name: upload.name,
              length: upload.length
            }
          })}
          onClear={() => props.onChange({ ...props.formData, logo: undefined })}
        />
      </Grid.Cell>
      <Grid.Cell style={{ minWidth: "15rem" }}>
        <TextArea
          readOnly={props.readOnly}
          value={props.formData.excludedApps}
          onChange={(event) => props.onChange({ ...props.formData, excludedApps: event.target.value })}
        />
      </Grid.Cell>
      <Grid.ActionsCell>
        {!props.readOnly && (
          <Buttons>
            <Button
              color={"secondary"}
              size={"small"}
              disabled={props.isFirst}
              onClick={props.onMoveUp}
            >
              Move Up
            </Button>
            <Button
              color={"secondary"}
              size={"small"}
              disabled={props.isLast}
              onClick={props.onMoveDown}
            >
              Move Down
            </Button>
            <Button color={"red"} size={"small"} onClick={props.onRemove}>Remove</Button>
          </Buttons>
        )}
      </Grid.ActionsCell>
    </Grid.Row>
  );
};
