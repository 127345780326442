import { Set, Map } from "immutable";
import { buildQueryString } from "../utils/misc";

export namespace MouseFlow {
  const SiteId = (window as any).MF_PROJECT_ID || "MISSING_PROJECT_ID";

  export namespace Tags {
    export const Elevated = "elevated";
    export const BadDestination = "bad-destination";
    export const ViewedHelp = "viewed-help";
  }

  export namespace Variables {
    export const Destination = "destination";
    export const MigrationId = "migration-id";
    export const Source = "source";
    export const SourceClass = "source-class";
    export const SourceSize = "source-size";
    export const UserId = "user-id";
  }

  let cachedTags = Set<string>();
  let cachedVariables = Map<string, string>();

  function pushToMouseFlow(item: any[]): void {
    try {
      (window as any)._mfq.push(item);
    } catch (error) {
      // console.error(error);
    }
  }

  export function tagMouseFlowSession(tag: string): void {
    if (!cachedTags.contains(tag)) {
      pushToMouseFlow(["tag", tag]);
      cachedTags = cachedTags.add(tag);
    }
  }

  export function setMouseFlowSessionVariable(name: string, value: string, doNotOverwrite: boolean = false): void {
    if (cachedVariables.get(name) !== value) {
      // console.log(name, value);
      pushToMouseFlow(
        doNotOverwrite
          ? ["setVariable", name, value, "session", false]
          : ["setVariable", name, value]
      );
      cachedVariables = cachedVariables.set(name, value);
    }
  }

  export function recordingsUrl(variables: [string, string][], tags: string[] = []): string {
    return "https://eu.mouseflow.com/websites/" + SiteId + "/recordings?" + buildQueryString(Map([
      [
        "vars",
        variables.map(([key, value]) => key + "=" + value).join(",")
      ]       ,
      [
        "tags",
        tags.join(",")
      ]
    ]));
  }

  export function recordingsForUserUrl(userId: string): string {
    return recordingsUrl([["user-id", userId]]);
  }

  export function recordingsForMigrationUrl(migrationId: string): string {
    return recordingsUrl([["migration-id", migrationId]]);
  }
}
