import { GraphQL } from "../../services/graphql/generated";

export interface AmbassadorStatusDiscount {
  readonly amount: number;
  readonly isReturningAmbassadorDiscount: boolean;
  readonly returningAmbassadorPercentage: number;
}

export namespace AmbassadorStatusDiscount {
  export function parse(data: GraphQL.AmbassadorStatusDiscountFragment): AmbassadorStatusDiscount {
    return data;
  }
}
