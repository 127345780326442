import * as React from "react";
import { List } from "immutable";
import { OffboardingProjectStats } from "../../../../types/models/offboardingProjectStats";
import * as moment from "moment";
import {
  ActivityChart,
  ActivityChartType,
  Dataset
} from "../../monitoringDashboardPageView/activityChart";
import { styled } from "../../../../app/theme";
import { ActivityTimeUnit } from "../../../../types/enums/activityTimeUnit";

interface Props {
  data: List<OffboardingProjectStats>;
}

export const OffboardingProjectTimelineChart: React.FunctionComponent<Props> = (props) => {
  const { timestamps, datasets } = React.useMemo(
    () => makeOffboardingProjectChartData(props.data),
    [props.data]
  );

  if (props.data.size > 1) {
    return (
      <StyledActivityChart
        timestamps={timestamps}
        datasets={datasets}
        displayLegend={true}
        timeUnit={ActivityTimeUnit.Day}
        type={ActivityChartType.Area}
      />
    );
  } else {
    return <>Activity data will be available the day after email addresses are added to this project.</>;
  }
};

function makeOffboardingProjectChartData(
  data: List<OffboardingProjectStats>
): { timestamps: List<moment.Moment>, datasets: List<Dataset> } {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  return {
    timestamps: data.map((stats) => moment(stats.date.getTime() + timezoneOffset)),
    datasets: List([
      new Dataset("Pending", data.map((stats) => stats.pending)),
      new Dataset("Opted Out", data.map((stats) => stats.optedOut)),
      new Dataset("Opened Link", data.map((stats) => stats.openedLink)),
      new Dataset("Connected Account", data.map((stats) => stats.connectedAccount)),
      new Dataset("Migration In Progress", data.map((stats) => stats.startedMigration)),
      new Dataset("Completed Migration", data.map((stats) => stats.completedMigration)),
    ])
  };
}

const StyledActivityChart = styled(ActivityChart)`
  width: 100%;
  height: 25vh;
`;
