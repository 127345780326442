import * as React from "react";
import { Switch, SwitchItem } from "../../widgets/switch";
import { DrawerBlock } from "../../containers/drawerBlock";
import { OperationStatus } from "../../../types/operationStatus";
import { Connection } from "../../../types/models/connection";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Panel } from "../../containers/panel";
import { styled } from "../../../app/theme";

const ConnectionId = styled(DrawerBlock)`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
`;

enum Tool {
  View,
  Check,
  Upgrade,
  Reveal,
  Revoke
}

const ToolbarItems: SwitchItem[] = [
  { value: Tool.View, content: "View" },
  { value: Tool.Check, content: "Check" },
  { value: Tool.Upgrade, content: "Upgrade" },
  { value: Tool.Reveal, content: "Reveal" },
  { value: Tool.Revoke, content: "Revoke" }
];

interface ConnectionToolsViewProps {
  status: OperationStatus<Connection>;
  renderViewTool: (success: OperationStatus.SuccessStatus<Connection>) => React.ReactNode;
  renderCheckTool: (success: OperationStatus.SuccessStatus<Connection>) => React.ReactNode;
  renderUpgradeTool: (success: OperationStatus.SuccessStatus<Connection>) => React.ReactNode;
  renderRevealTool: (success: OperationStatus.SuccessStatus<Connection>) => React.ReactNode;
  renderRevokeTool: (success: OperationStatus.SuccessStatus<Connection>) => React.ReactNode;
}

export const ConnectionManagementToolsView: React.FunctionComponent<ConnectionToolsViewProps> = (props) => {
  const [activeTool, setActiveTool] = React.useState<Tool>(Tool.View);

  if (props.status.isSuccess()) {
    return (
      <>
        <ConnectionId>
          Connection ID: <strong>{props.status.result.id}</strong>
        </ConnectionId>
        <DrawerBlock>
          <Switch items={ToolbarItems} selected={activeTool} onSelect={setActiveTool}/>
        </DrawerBlock>
        {(() => {
          switch (activeTool) {
            case Tool.View: return props.status.isSuccess() && props.renderViewTool(props.status);
            case Tool.Check: return props.status.isSuccess() && props.renderCheckTool(props.status);
            case Tool.Upgrade: return props.status.isSuccess() && props.renderUpgradeTool(props.status);
            case Tool.Reveal: return props.status.isSuccess() && props.renderRevealTool(props.status);
            case Tool.Revoke: return props.status.isSuccess() && props.renderRevokeTool(props.status);
          }
        })()}
      </>
    );
  } else {
    return (
      <OperationStatusIndicator
        subject={"connection"}
        status={props.status}
        indicators={StatusIndicators.PanelRow({ wrapper: Panel })}
      />
    );
  }
};
