import * as React from "react";
import { DrawerContent } from "../../utils/drawerContent";
import { Highlight } from "../../widgets/highlight";
import { styled } from "../../../app/theme";

export const PopupContent: React.FunctionComponent = () => (
  <DrawerContent>
    <br/>
    <h2>Your migration is now in progress!</h2>
    <p>
      During your copy, please <Highlight>do not</Highlight> move, rename, or delete any{" "}
      <Highlight>folders, labels, or groups</Highlight> in either your source or destination account.
    </p>
    <p>
      VaultMe needs these to stay undisturbed during the migration to ensure you get the best migration
      experience possible.
      <br/>
      <br/>
    </p>
    <h2>What else should I know?</h2>
    <ol>
      <li>
        Once your migration completes, VaultMe will send you a "Migration Complete" email to your{" "}
        <Highlight>destination</Highlight> email account. Until then, feel free to watch the progress bars online
        on the migration page covered by this popup.
      </li>
      <li>
        To learn exactly how to find your migrated items in your destination account after the migration, use the "?"
        icons on the migration page covered by this popup.
      </li>
      <li>
        A migration report will become available to you after the migration is complete. Keep it for your records.
        It will provide you useful detail on every single item processed during the migration.
      </li>
    </ol>
    <p>
      Got it? OK! Now you can click the "X" at the top of this panel!
    </p>
  </DrawerContent>
);
