import * as React from "react";
import { useSession } from "../utils/useAppState";
import { styled } from "./theme";
import { StaticRoutes } from "./routes/staticRoutes";

export const SessionWatcher: React.FunctionComponent = () => {
  const session = useSession();

  const showClock = false;
  const timerStep = 10;
  const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];

  const [remainingSeconds, setRemainingSeconds] = React.useState<number>();

  function resetTimerForSession(): void {
    if (session) {
      setRemainingSeconds(session.idleTimeout);
    }
  }

  React.useEffect(
    () => {
      if (session) {
        resetTimerForSession();

        const timer = setInterval(
          () => {
            setRemainingSeconds((value) => {
              if (value !== undefined) {
                if (value - timerStep > 0) {
                  return value - timerStep;
                } else {
                  window.location.assign(StaticRoutes.signedOutPath);
                  return undefined;
                }
              } else {
                return value;
              }
            });
          },
          timerStep * 1000
        );

        events.forEach((name) => document.addEventListener(name, resetTimerForSession, true));

        return () => {
          clearInterval(timer);
          events.forEach((name) => document.removeEventListener(name, resetTimerForSession, true));
        };
      }
    },
    [session]
  );

  if (session && showClock) {
    return <Layout>{remainingSeconds}</Layout>;
  } else {
    return null;
  }
};

const Layout = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  background: rgba(5,37,53,0.9);
  color: white;
  padding: 1rem;
`;
