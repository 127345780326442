import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRow } from "../../containers/rows/panelRow";
import { friendlyCount } from "../../../utils/formatting";
import { Button } from "../../widgets/button";

interface ConnectionsStatusRowProps {
  connectionCount: number;
  onRefreshButtonClick: () => void;
}

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled.div`
`;

export const ConnectionsStatusRow: React.FunctionComponent<ConnectionsStatusRowProps> = (props) => (
  <PanelRow>
    <Layout>
      <Status>{friendlyCount(props.connectionCount, "connection") + " found"}</Status>
      <Button color="white" size="small" onClick={props.onRefreshButtonClick}>
        Refresh and Check All
      </Button>
    </Layout>
  </PanelRow>
);
