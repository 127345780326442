import * as React from "react";
import * as yup from "yup";
import { ProvidersStepView } from "../views/screens/migrationSetup/providersStepView";
import { useAuthProviders, useCloudServices } from "../app/configuration";
import { Blueprint } from "../blueprints/blueprint";
import { BlueprintContext } from "../blueprints/blueprintContext";
import { Connections } from "../types/models/connections";
import { Facts } from "../types/facts/facts";
import { ServerElements } from "../blueprints/serverElements";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { BlueprintInputs } from "../blueprints/blueprintInputs";
import { resolveImage } from "../app/images";
import { ProviderInfoPanel } from "../views/screens/migrationSetup/providersStepView/providerInfoPanel";
import { PreparedHelpArticle } from "../utils/preparedHelpArticle";
import { AdminSidebar } from "../views/layouts/sidebar";
import { useRoutes } from "../app/routes/useRoutes";
import { SignInContextType } from "../types/models/signInContextType";

export interface SourceAndDestination {
  sourceCloudServiceId: string | undefined;
  destinationCloudServiceId: string | undefined;
}

export namespace SourceAndDestination {
  export const Schema = yup.object<SourceAndDestination>({
    sourceCloudServiceId: yup.string(),
    destinationCloudServiceId: yup.string()
  });
}

interface Props extends SourceAndDestination {
  step: number;
  totalSteps: number;

  predefinedSourceCloudServiceId: string | undefined;
  predefinedDestinationCloudServiceId: string | undefined;

  blueprint: Blueprint | undefined;
  onApply: (step: SourceAndDestination) => void;
  onComplete: (step: SourceAndDestination) => void;
  onNavigateBack?: () => void;
}

export const SourceAndDestinationStep: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const sourceCloudServices = useCloudServices(SignInContextType.Source);
  const authProviders = useAuthProviders();
  const routes = useRoutes();

  const migrationBlueprint = React.useMemo(
    () =>
      props.sourceCloudServiceId !== undefined &&
      props.destinationCloudServiceId !== undefined &&
      props.blueprint
        ? (
          new MaterializedMigrationBlueprint(
            props.blueprint,
            new BlueprintContext({
              inputs: BlueprintInputs.Empty,
              cloudServices,
              authProviders,
              connections: Connections.Empty,
              facts: Facts.Empty,
              serverElements: ServerElements.Empty
            }),
            props.sourceCloudServiceId,
            props.destinationCloudServiceId
          )
        )
        : undefined,
    [props.sourceCloudServiceId, props.destinationCloudServiceId, props.blueprint]
  );

  React.useEffect(
    () => {
      if (props.predefinedSourceCloudServiceId || props.predefinedDestinationCloudServiceId) {
        props.onApply({
          sourceCloudServiceId: props.predefinedSourceCloudServiceId || props.sourceCloudServiceId,
          destinationCloudServiceId: props.predefinedDestinationCloudServiceId || props.destinationCloudServiceId
        });
      }
    },
    [props.predefinedSourceCloudServiceId, props.predefinedDestinationCloudServiceId]
  );

  const sourceCloudService = props.sourceCloudServiceId !== undefined
    ? sourceCloudServices.getOrFail(props.sourceCloudServiceId)
    : undefined;
  
  return (
    <>
      <AdminSidebar blueprint={migrationBlueprint}/>
      <ProvidersStepView
        step={props.step}
        totalSteps={props.totalSteps}
        areas={
          migrationBlueprint && migrationBlueprint.listMigrationFlows()
            .filter(([area]) =>
              sourceCloudService && !sourceCloudService.excludedApps.contains(area.props.title)
            )
            .sortBy(([area]) => area.props.order)
            .map(([area, sink]): ProviderInfoPanel.Area => ({
              title: area.props.title,
              description: area.props.description,
              icon: resolveImage(area.settings.icon),
              helpArticle: PreparedHelpArticle.migrationRules({
                routes,
                phase: "before-migration",
                sourceAppId: area.props.internalId,
                destinationAppId: sink.props.internalId,
                sourceAppTitle: area.resolvedAppTitle(migrationBlueprint.context),
                destinationAppTitle: sink.resolvedAppTitle(migrationBlueprint.context),
              })
            }))
            .toArray()
        }

        sourceCloudServiceId={props.sourceCloudServiceId}
        destinationCloudServiceId={props.destinationCloudServiceId}

        onSourceCloudServiceSelect={(sourceCloudServiceId) =>
          props.onApply({ sourceCloudServiceId, destinationCloudServiceId: props.destinationCloudServiceId })
        }
        onDestinationCloudServiceSelect={(destinationCloudServiceId) =>
          props.onApply({ sourceCloudServiceId: props.sourceCloudServiceId, destinationCloudServiceId })
        }

        onSubmit={() => props.onComplete({
          sourceCloudServiceId: props.sourceCloudServiceId,
          destinationCloudServiceId: props.destinationCloudServiceId
        })}

        onNavigateBack={props.onNavigateBack}
      />
    </>
  );
};
