import { prettyPrint } from "../utils/formatting";
import { Blueprint } from "./blueprint";
import { BlueprintContext } from "./blueprintContext";
import { BlueprintException } from "./blueprintException";
import { ComponentSettings } from "./components/componentSettings";
import { ComponentVisualization } from "./componentVisualization";
import { Element } from "./element";
import { AnyHub } from "./hub";
import { AssociationsHub } from "./hubs/associationsHub";
import { PreconditionsHub } from "./hubs/preconditionsHub";
import { AnyRelationship } from "./relationship";
import { State } from "./state";
import { List, Set } from "immutable";
import { GraphQL } from "../services/graphql/generated";
import { ComponentBinding } from "./components/componentBinding";

export interface IncomingRelationship {
  component: AnyComponent;
  hub: AnyHub;
  relationship: AnyRelationship;
}

export interface ComponentHubs {
  preconditions: PreconditionsHub;
  associations: AssociationsHub;
}

export namespace ComponentHubs {
  export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.ComponentHubsFragment): ComponentHubs {
    return {
      preconditions: binding.preconditionsHub("preconditions", hubs.preconditions),
      associations: binding.associationsHub("associations", hubs.associations),
    };
  }
}

export class ComponentProviders {
  constructor(public component: AnyComponent) {
  }

  public get<T>(output: any, outputName: string): T {
    if (output.hasOwnProperty(outputName)) {
      return output[outputName] as T;
    } else {
      throw new BlueprintException(
        `Output ${outputName} is missing in component ${this.component.id}` + prettyPrint(output));
    }
  }
}

export interface ComponentData {
  id: string;
  serverSide: boolean;
  segment: string;
  settings: GraphQL.ComponentSettings;
  tags: string[];
}

export abstract class Component<Props, Hubs extends ComponentHubs, Output> implements Element<Props> {
  public readonly blueprint: Blueprint;

  public readonly id: string;
  public readonly serverSide: boolean;
  public readonly segment: string;
  public readonly settings: ComponentSettings;
  public readonly tags: Set<string>;

  public readonly props: Props;
  public readonly hubs: Hubs;

  public readonly out: ComponentProviders = new ComponentProviders(this);

  constructor(
    blueprint: Blueprint,
    data: ComponentData,
    props: Props,
    hubs: Hubs
  ) {
    this.blueprint = blueprint;

    this.id = data.id;
    this.serverSide = data.serverSide;
    this.segment = data.segment;
    this.settings = ComponentSettings.fromGraphQL(data.settings);
    this.tags = Set(data.tags);

    this.props = props;
    this.hubs = hubs;
  }

  public get type(): string {
    return this.constructor.name;
  }

  public segments(): List<string> {
    return List(this.segment.split(":"));
  }

  public inSegment(segment: string): boolean {
    return this.segments().last() === segment;
  }

  public hubList(): List<AnyHub> {
    const hubsMap = this.hubs as unknown as { [id: string]: AnyHub };
    let result: List<AnyHub> = List();
    for (const key in hubsMap) {
      if (hubsMap.hasOwnProperty(key)) {
        result = result.push(hubsMap[key]);
      }
    }
    return result;
  }

  public relationships(): List<AnyRelationship> {
    return this.hubList().flatMap((hub) => hub.relationships);
  }

  public incomingRelationships(): List<IncomingRelationship> {
    let result = List<IncomingRelationship>();
    this.blueprint.allComponents().forEach((component) => {
      if (component.id !== this.id) {
        component.hubList().forEach((hub) => {
          hub.relationships.forEach((relationship) => {
            if (relationship.componentId === this.id) {
              result = result.push({ component, hub, relationship });
            }
          });
        });
      }
    });
    return result;
  }

  public state(context: BlueprintContext): State<Output> {
    try {
      return context.memoize(
        this.id,
        () => {
          if (this.serverSide) {
            const serverState = context.serverElements.getComponentState(this.id);
            if (serverState !== undefined) {
              return serverState;
            } else {
              return State.blocked();
            }
          } else {
            if (this.isUnblocked(context)) {
              return this.stateWhenUnblocked(context);
            } else {
              return State.blocked();
            }
          }
        });
    } catch (error) {
      console.error("Unhandled error in Component.state (component: " + this.id + ")", error);
      throw error;
    }
  }

  public abstract stateWhenUnblocked(context: BlueprintContext): State<Output>;

  public resolvedByServer(context: BlueprintContext): boolean {
    return this.serverSide && context.serverElements.getComponentState(this.id) !== undefined;
  }

  public messages(context: BlueprintContext): List<string> {
    return List();
  }

  public abstract visualization(context: BlueprintContext, state: State<Output>): ComponentVisualization;

  public unblockedFactIds(context: BlueprintContext): List<string> {
    return List();
  }

  public trace<S extends AnyComponent>(
    pickWhen: (component: AnyComponent) => S | undefined,
    stopWhen: (component: AnyComponent) => boolean
  ): Set<S> {
    return this.blueprint.trace(this, pickWhen, stopWhen);
  }

  protected isUnblocked(context: BlueprintContext): boolean {
    return this.allHubsUnblocked(context);
  }

  protected allHubsUnblocked(context: BlueprintContext): boolean {
    return (
      this.standardHubsUnblocked(context) &&
      !this.hubList().find((hub) => hub.state(context).isBlocked)
    );
  }

  protected standardHubsUnblocked(context: BlueprintContext): boolean {
    const preconditionsState = this.hubs.preconditions.state(context);
    return (
      !preconditionsState.isBlocked &&
      !preconditionsState.isError &&
      !preconditionsState.resolvedOutput.contains(false) &&
      !this.hubs.associations.state(context).isBlocked
    );
  }
}

export type AnyComponent = Component<any, any, any>;

export type ComponentOf<Output> = Component<any, any, Output>;
