import * as React from "react";
import { styled } from "../../app/theme";
import { SimpleClickHandler } from "../utils/simpleClickHandler";
import { SimpleBackButton } from "./backButton";
import { ButtonColor, ButtonSize, ButtonType, SimpleButton } from "./button";
import { useFlash } from "../../utils/useFlash";
import { Panel } from "../containers/panel";
import { PanelRow } from "../containers/rows/panelRow";
import { WidgetStatus } from "../utils/widgetStatus";

interface BackButtonProps {
  backPath?: string;
  onNavigateBack?: () => void;
}

interface SubmitButtonProps {
  submitType?: ButtonType;
  submitTitle?: string | JSX.Element;
  submitDisabled?: boolean;
  submitDisabledMessage?: string;

  submitHidden?: boolean;
  submitColor?: ButtonColor;
  submitSize?: ButtonSize;
  submitWidget?: React.ReactNode;
  submitSubText?: string;

  onSubmitClick?: SimpleClickHandler;
}

const BackButtonContainer = styled.div`
  display: flex;
  padding: 0 1.2rem 0 0;
  align-self: flex-start;
`;

const WizardBackButton: React.FunctionComponent<BackButtonProps> = (props) => {
  if (props.backPath || props.onNavigateBack) {
    return (
      <BackButtonContainer>
        <SimpleBackButton onSimpleClick={props.backPath || props.onNavigateBack}/>
      </BackButtonContainer>
    );
  } else {
    return null;
  }
};

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitSubText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.font.light};
  color: ${(props) => props.theme.colors.darkGray};
  text-align: center;
`;

const WizardSubmitButton: React.FunctionComponent<SubmitButtonProps & { onDisabledClick: () => void }> = (props) => {
  if (props.submitHidden) {
    return null;
  } else if (props.submitWidget) {
    return (
      <SubmitButtonContainer>
        {props.submitWidget}
        {props.submitSubText && <SubmitSubText>{props.submitSubText}</SubmitSubText>}
      </SubmitButtonContainer>
    );
  } else {
    const hasDisabledMessage = props.submitDisabled && props.submitDisabledMessage;
    return (
      <SubmitButtonContainer>
        <SimpleButton
          type={hasDisabledMessage ? "button" : props.submitType || "submit"}
          color={props.submitColor || "blue"}
          size={props.submitSize || "wide"}
          disabled={hasDisabledMessage ? false : props.submitDisabled}
          onSimpleClick={hasDisabledMessage ? props.onDisabledClick : props.onSubmitClick}
        >
          {props.submitTitle || "Next"} &nbsp;&rsaquo;
        </SimpleButton>
        {props.submitSubText && <SubmitSubText>{props.submitSubText}</SubmitSubText>}
      </SubmitButtonContainer>
    );
  }
};

const StyledDisabledMessagePanel = styled(Panel)`
  margin-bottom: 1rem;
`;

const Layout = styled.div`
  display: flex;
`;

export interface WizardNavigationProps extends BackButtonProps, SubmitButtonProps {
}

export const WizardNavigation: React.FunctionComponent<WizardNavigationProps> = (props) => {
  const [showDisabledMessage, flashDisabledMessage] = useFlash(3000);
  return (
    <>
      {showDisabledMessage && (
        <StyledDisabledMessagePanel>
          <PanelRow status={WidgetStatus.Warning}>
            {props.submitDisabledMessage}
          </PanelRow>
        </StyledDisabledMessagePanel>
      )}
      <Layout>
        <WizardBackButton {...props}/>
        <WizardSubmitButton {...props} onDisabledClick={flashDisabledMessage}/>
      </Layout>
    </>
  );
};
