import * as React from "react";
import { Constants } from "../../../app/constants";
import { PanelRow } from "./panelRow";
import { Flash } from "../../utils/flash";
import { styled } from "../../../app/theme";
import { wrapped } from "../../utils/wrapped";

export const StyledSuccessIndicatorRow = styled(PanelRow)`
  background: ${(props) => props.theme.colors.faintPrimary};
`;

export interface SuccessIndicatorRowProps {
  timeout?: number;
  wrapper?: React.ComponentType;
}

export const SuccessIndicatorRow: React.FunctionComponent<SuccessIndicatorRowProps> = (props) => {
  return (
    <Flash timeout={props.timeout !== undefined ? props.timeout : Constants.SuccessIndicatorTimeout}>
      {
        wrapped(
          <StyledSuccessIndicatorRow>{props.children}</StyledSuccessIndicatorRow>,
          props.wrapper
        )
      }
    </Flash>
  );
};
