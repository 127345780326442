import * as React from "react";
import { GraphQL } from "../../services/graphql/generated";
import { ImapSignInController, ImapSignInControllerConfig } from "./imapSignInController";
import { OAuthSignInController, OAuthSignInControllerConfig } from "./oAuthSignInController";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { List } from "immutable";
import { GoogleSignInController } from "./googleSignInController";
import { OutlookSignInController, OutlookSignInControllerConfig } from "./outlookSignInController";
import {
  OAuthAndServiceAccountSignInController,
  OAuthAndServiceAccountSignInControllerConfig
} from "./oAuthAndServiceAccountSignInController";

export type SignInFormConfig =
  GraphQL.ImapSignInFormConfig |
  GraphQL.OAuthSignInFormConfig;

export function createSignInControllers(
  response: GraphQL.GetSignInFormConfigResponseFragment,
  offerAccountCreation: boolean
): SignInDefs.SignInComponentType[] {
  function findCustomization<T extends object>(
    typeName: string,
    name: string
  ): T | undefined {
    const parts = List(name.split("/"));
    return List(response.customizations)
      .filter((c) => {
        const p = List(c.name.split("/"));
        return c.customization.__typename === typeName && parts.take(p.size).equals(p);
      })
      .sortBy((c) => c.name)
      .map((c) => c.customization as unknown as T)
      .reduce((r, v) => {
        const result: any = { ...r };
        for (const key in v) {
          if (v.hasOwnProperty(key)) {
            const value = v[key];
            if (value !== null && value !== undefined) {
              result[key] = value;
            }
          }
        }
        return result;
      });
  }

  return response.configurations.map((configEntry) => (props) => {
    const { name, config } = configEntry;
    switch (config.__typename) {
      case "ImapSignInFormConfig": {
        return (
          <ImapSignInController
            {...props}
            config={ImapSignInControllerConfig.fromGraphQL(
              config,
              findCustomization<GraphQL.ImapSignInFormCustomization>("ImapSignInFormCustomization", name)
            )}
          />
        );
      }

      case "OAuthSignInFormConfig":
        return (
          <OAuthSignInController
            {...props}
            config={OAuthSignInControllerConfig.fromGraphQL(
              config,
              findCustomization<GraphQL.OAuthSignInFormCustomization>("OAuthSignInFormCustomization", name)
            )}
          />
        );

      case "OAuthAndServiceAccountSignInFormConfig":
        return (
          <OAuthAndServiceAccountSignInController
            {...props}
            config={OAuthAndServiceAccountSignInControllerConfig.fromGraphQL(
              config,
              findCustomization<GraphQL.OAuthSignInFormCustomization>("OAuthSignInFormCustomization", name)
            )}
          />
        );

      case "GoogleSignInFormConfig":
        return (
          <GoogleSignInController
            {...props}
            config={OAuthAndServiceAccountSignInControllerConfig.fromGraphQL(
              config,
              findCustomization<GraphQL.OAuthSignInFormCustomization>("OAuthSignInFormCustomization", name)
            )}
            offerAccountCreation={offerAccountCreation}
          />
        );

      case "OutlookSignInFormConfig":
        return (
          <OutlookSignInController
            {...props}
            config={OutlookSignInControllerConfig.fromGraphQL(
              config,
              findCustomization<GraphQL.OAuthSignInFormCustomization>("OAuthSignInFormCustomization", name)
            )}
          />
        );

      default:
        return <>Internal error: unknown or missing sign-in form type: {config.__typename}</>;
    }
  });
}
