import * as React from "react";
import { Step } from "../../containers/step";
import { styled } from "../../../app/theme";
import { Images } from "../../../app/images";
import { Review, ReviewsContainer } from "./carouselStepView/reviews";
import { ReviewData } from "./carouselStepView/reviewData";
import { useAppBootstrapConfig } from "../../../app/configuration";
import { Connection } from "../../../types/models/connection";
import { CloudService } from "../../../types/models/cloudService";
import { SchoolSummary } from "../../../types/models/school";
import { useRoutes } from "../../../app/routes/useRoutes";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";

export function makeSchoolWelcomeMessage(
  school: SchoolSummary,
  firstName: string | undefined
): { title: string, subTitle: string } | undefined {

  function makeText(): string {
    return school.welcomeMessage ||
      "Welcome to VaultMe, {firstName}! We're thrilled to have you here.\n" +
      "\n" +
      "As you prepare to transition from student life to the next exciting chapter, VaultMe is here to help you save " +
      "and manage your school account data. Our service ensures that all your important documents and memories" +
      (school.officialName ? " from " + school.officialName : "") + " are securely stored and easily accessible. " +
      "Congratulations on your achievements, and best of luck in your future endeavors!";
  }

  const parts = makeText().split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  if (parts.length > 1) {
    return {
      title: parts[0].replace("{firstName}", firstName || "student"),
      subTitle: parts[1]
    };
  }
}

interface Props {
  step: number;
  totalSteps: number;

  schoolLoadingStatus: OperationStatus<SchoolSummary | undefined>;

  sourceCloudService: CloudService;
  sourceConnection: Connection;
  destinationCloudService: CloudService;

  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const ConnectionInterstitialStepView: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  const reviews = (
    <ReviewsContainer largeTopPadding={true}>
      <Review review={ReviewData.victor}/>
      <Review review={ReviewData.colin}/>
      <Review review={ReviewData.brianna}/>
    </ReviewsContainer>
  );

  if (props.schoolLoadingStatus.isSuccess()) {
    const school = props.schoolLoadingStatus.result;
    const welcomeMessage = school
      ? makeSchoolWelcomeMessage(school, props.sourceConnection.personalInfo.firstName)
      : undefined;

    return (
      <Step
        breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
        title={
          welcomeMessage?.title || (
            "You did it! Now let's connect your " +
            (appBootstrapConfig.configuration?.destinationCloudServiceReference || "destination account") +
            "."
          )
        }
        subTitle={welcomeMessage?.subTitle}
        onSubmit={props.onSkip}
        onNavigateBack={props.onNavigateBack}
        noForm={true}
        additionalContent={reviews}
      >
        <Congratulations
          sourceConnection={props.sourceConnection}
          sourceCloudService={props.sourceCloudService}
          sourceLogoId={school?.logoId}
          sourceLogoWidth={school?.logoWidth}
          sourceConnectionDetails={undefined}

          destinationCloudService={props.destinationCloudService}
          destinationConnection={undefined}
          destinationConnectionDetails={undefined}

          displayCloudServiceNamePlaceholder={false}
        />
      </Step>
    );
  } else {
    return (
      <Step
        breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
        title={"Just a moment..."}
        onNavigateBack={props.onNavigateBack}
        additionalContent={reviews}
      >
        <OperationStatusIndicator
          status={props.schoolLoadingStatus}
          indicators={StatusIndicators.SimplePanel()}
        />
      </Step>
    );
  }
};

const AccountBoxLayout = styled.div<AccountBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  background: ${(props) => props.theme.colors.white};;
  padding: 1rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: auto;

    & ~ & {
      margin-top: 1rem;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 6rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    min-height: 4.2rem; // 6 * 0.7
  }
`;

const Logo = styled.img<{ logoWidth: number | undefined }>`
  width: ${(props) => props.logoWidth || 6}rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: ${(props) => (props.logoWidth || 6) * 0.7}rem;
  }
`;

const AccountBoxTitle = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkGray};
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 0.8rem;
  margin: 1.5rem 0 .5rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin: 1rem 0 .2rem;
  }
`;

const AccountDescription = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.font.medium};
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
`;

const ComingUpNext = styled.div`
  text-transform: uppercase;
`;

const AccountDetailsContainer = styled.div`
  margin-top: .5rem;
`;

interface AccountBoxProps {
  title: string;
  logo: any;
  logoWidth: number | undefined;
  description: string | undefined;
  children?: React.ReactNode;
}

export const AccountBox: React.FunctionComponent<AccountBoxProps> = (props) => (
  <AccountBoxLayout {...props}>
    {props.logo && (
      <LogoContainer>
        <Logo src={props.logo} logoWidth={props.logoWidth}/>
      </LogoContainer>
    )}
    <AccountBoxTitle>{props.title}</AccountBoxTitle>
    {
      props.description
        ? <AccountDescription>{props.description}</AccountDescription>
        : <ComingUpNext>Coming Up Next...</ComingUpNext>
    }
    {props.children && <AccountDetailsContainer>{props.children}</AccountDetailsContainer>}
  </AccountBoxLayout>
);

interface TimeLineBarProps {
  arrow: boolean;
}

const TimeLineBarDiv = styled.div`
  display: flex;
  padding: 5rem 0 1.5rem;
  width: 4rem;

  .line {
    background: #239bef;
    margin: 4px 0;
    height: 2px;
    opacity: 0.9;
    flex-grow: 1;
  }

  .arrow-head-right {
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid #239bef;
    opacity: 0.9;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: none;
  }
`;

export const TimeLineBar: React.FunctionComponent<TimeLineBarProps> = (props) => (
  <TimeLineBarDiv>
    <div className="line"/>
    {props.arrow && <div className="arrow-head-right"/>}
  </TimeLineBarDiv>
);

const Layout = styled.div`
  width: 100%;
  display: flex;
  padding-top: 3rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-top: 1rem;
    flex-direction: column;
  }
`;

const ZenGuy = styled.img`
  width: 136px;
  height: 186px;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: none;
  }
`;

interface CongratulationsProps {
  sourceCloudService: CloudService;
  sourceConnection: Connection | undefined;
  sourceLogoId: string | undefined;
  sourceLogoWidth: number | undefined;
  sourceConnectionDetails: React.ReactNode;

  destinationCloudService: CloudService;
  destinationConnection: Connection | undefined;
  destinationConnectionDetails: React.ReactNode;

  displayCloudServiceNamePlaceholder: boolean;
}

export const Congratulations: React.FunctionComponent<CongratulationsProps> = (props) => {
  const routes = useRoutes();

  return (
    <Layout>
      <AccountBox
        title="Migrate From:"
        logo={props.sourceLogoId ? routes.api.downloadUrl(props.sourceLogoId) : props.sourceCloudService.icon}
        logoWidth={props.sourceLogoId ? props.sourceLogoWidth : undefined}
        description={
          props.sourceConnection
            ? props.sourceConnection.descriptionOrId()
            : props.displayCloudServiceNamePlaceholder ? props.sourceCloudService.name : undefined
        }
      >
        {props.sourceConnectionDetails}
      </AccountBox>
      <TimeLineBar arrow={false}/>
      <ZenGuy src={Images.ZenGuy} alt="Experience the Zen with VaultMe"/>
      <TimeLineBar arrow={true}/>
      <AccountBox
        title="Migrate To:"
        logo={props.destinationCloudService.icon}
        logoWidth={undefined}
        description={
          props.destinationConnection
            ? props.destinationConnection.descriptionOrId()
            : props.displayCloudServiceNamePlaceholder ? props.destinationCloudService.name : undefined
        }
      >
        {props.destinationConnectionDetails}
      </AccountBox>
    </Layout>
  );
};
