import * as React from "react";
import { Connection } from "../../types/models/connection";
import { ConnectionsPanel } from "./connectionsPanel";
import { User } from "../../types/models/user";
import { UserSelectorPanel } from "../userSelectorPanel";
import { Button } from "../../views/widgets/button";
import { UserInfoPanel } from "../../views/blocks/userManagement/userInfoPanel";
import { SimpleToolbar } from "../../views/widgets/simpleToolbar";
import { useBrowser } from "../../utils/useBrowser";
import { Characters } from "../../views/characters";
import { useRoutes } from "../../app/routes/useRoutes";

interface Props {
  onSelect: (connection: Connection, user: User) => void;
}

export const UserConnectionSelectorPanel: React.FunctionComponent<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [appliedSearchTerm, setAppliedSearchTerm] = React.useState<string>();
  const [user, setUser] = React.useState<User>();
  const browser = useBrowser();
  const routes = useRoutes();

  if (user) {
    // Simply stacking user info and connections panel with no margin - looks fine in this case
    return (
      <>
        <UserInfoPanel
          user={user}
          headerContent={(
            <SimpleToolbar>
              <Button color="white" size="small" onClick={() => setUser(undefined)}>&lsaquo; Back</Button>
              {user && (
                <Button
                  color="white"
                  size="small"
                  onClick={() => browser.openNewTab(routes.users.userDetailsPath(user.id))}
                >
                  View Complete User Profile {Characters.NewTab}
                </Button>
              )}
            </SimpleToolbar>
          )}
          displayAdminTools={false}
        />
        <ConnectionsPanel
          userId={user.id}
          showStatusRow={false}
          actionTitle={"Connect"}
          onAction={(connection) => props.onSelect(connection, user)}
        />
      </>
    );
  } else {
    return (
      <UserSelectorPanel
        searchTerm={searchTerm}
        appliedSearchTerm={appliedSearchTerm}
        onSearchTermChange={setSearchTerm}
        onSearch={setAppliedSearchTerm}
        onSelect={setUser}
      />
    );
  }
};
