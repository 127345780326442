import { Facts } from "../../../types/facts/facts";
import { Seq, Set } from "immutable";
import * as React from "react";
import { Collectable } from "../../../types/collectables/collectable";
import { Modal } from "../../layouts/modal";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Grid } from "../../widgets/grid";
import { Checkbox } from "../../widgets/checkbox";
import { styled } from "../../../app/theme";
import { Fact, FactState } from "../../../types/facts/fact";
import { WidgetStatus } from "../../utils/widgetStatus";
import { ItemBreakdown } from "../../../types/itemBreakdown";
import { Button } from "../../widgets/button";
import { DrawerBlock } from "../../containers/drawerBlock";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { prettyPrint } from "../../../utils/formatting";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Switch } from "../../widgets/switch";
import { TextArea } from "../../widgets/textArea";
import { Indicators, JsonIndicator, SimpleIndicator } from "../../widgets/indicator";

export interface FactManagementControllerProps {
  fact: Fact;
  render: (params: {
    updateStatus: OperationStatus<any>
    onUpdate: (value: string) => Promise<any>,
    resetUpdate: () => void,

    invalidationStatus: OperationStatus<any>
    onInvalidation: () => Promise<any>,
    resetInvalidation: () => void
  }) => React.ReactElement;
}

export type FactManagementControllerComponentType = React.ComponentType<FactManagementControllerProps>;

interface FactsGridProps {
  facts: Facts;
  checkedFactIds: Set<string>;
  factManagementController: FactManagementControllerComponentType;

  onSelect: (factIds: Set<string>) => void;
}

export const FactsGrid: React.FunctionComponent<FactsGridProps> = (props) => {
  const [currentFactId, setCurrentFactId] = React.useState<string>();
  const currentFact = currentFactId !== undefined ? props.facts.get(currentFactId) : undefined;

  const allFactIds = props.facts.list().map((fact) => fact.id).toSet();

  return (
    <>
      {currentFact && (
        <Modal onClose={() => setCurrentFactId(undefined)} wide={true}>
          {
            React.createElement(props.factManagementController, {
              fact: currentFact,
              render: ((viewProps) => (
                <FactValueDetails
                  fact={currentFact}

                  updateStatus={viewProps.updateStatus}
                  onUpdate={viewProps.onUpdate}
                  resetUpdate={viewProps.resetUpdate}

                  invalidationStatus={viewProps.invalidationStatus}
                  onInvalidation={viewProps.onInvalidation}
                  resetInvalidation={viewProps.resetInvalidation}
                />
              ))
            })
          }
        </Modal>
      )}
      <Grid>
        <Grid.Header>
          <Grid.Column style={{ width: "1%" }}>
            <Checkbox
              checked={!props.checkedFactIds.isEmpty()}
              indeterminate={!props.checkedFactIds.isEmpty() && !props.checkedFactIds.equals(allFactIds)}
              onChange={() => !props.checkedFactIds.equals(allFactIds)
                ? props.onSelect(allFactIds)
                : props.onSelect(Set())
              }
            />
          </Grid.Column>
          <Grid.Column>Fact ID</Grid.Column>
          <Grid.Column>Type</Grid.Column>
          <Grid.Column>Status</Grid.Column>
          <Grid.Column>Value</Grid.Column>
          <Grid.Column/>
        </Grid.Header>
        <Grid.Body>
          {
            props.facts
              .list()
              .sortBy((fact) => fact.id)
              .map((fact) => (
                <FactRow
                  key={fact.id}
                  fact={fact}
                  checked={props.checkedFactIds.contains(fact.id)}
                  onManage={() => setCurrentFactId(fact.id)}
                  onToggle={() => props.onSelect(
                    props.checkedFactIds.contains(fact.id)
                      ? props.checkedFactIds.remove(fact.id)
                      : props.checkedFactIds.add(fact.id)
                  )}
                />
              ))
          }
        </Grid.Body>
      </Grid>
    </>
  );
};

const ValuePrefix = styled.span`
  color: ${(props) => props.theme.colors.gray};
  font-size: 0.85rem;
`;

interface FactRowProps {
  fact: Fact;
  checked: boolean;

  onManage: () => void;
  onToggle: () => void;
}

const FactRow: React.FunctionComponent<FactRowProps> = (props) => {
  function valueCellStatus(): WidgetStatus | undefined {
    if (props.fact.state.isCollected()) {
      return WidgetStatus.Success;
    } else if (props.fact.state.isFailed()) {
      return WidgetStatus.Error;
    }
  }

  function renderValue(value: Collectable.Any) {
    const currentValue = value.currentValue();
    if (currentValue instanceof ItemBreakdown) {
      return <><ValuePrefix>(Sum)</ValuePrefix> {currentValue.sum()}</>;
    } else {
      return currentValue.toString();
    }
  }

  return (
    <Grid.Row>
      <Grid.Cell>
        <Checkbox
          id={props.fact.id}
          name={props.fact.id}
          checked={props.checked}
          onChange={props.onToggle}
        />
      </Grid.Cell>
      <Grid.Cell>{props.fact.id}</Grid.Cell>
      <Grid.Cell>{props.fact.valueType}</Grid.Cell>
      <Grid.Cell status={valueCellStatus()}>
        {props.fact.state.statusDescription()}
      </Grid.Cell>
      <Grid.Cell style={{ textAlign: "right" }} nowrap={true}>
        {props.fact.state.valueOption().map(renderValue).getOrUse("--")}
      </Grid.Cell>
      <Grid.ActionsCell>
        {<Button size="small" color={"white"} onClick={() => props.onManage()} key={0}>Details</Button>}
      </Grid.ActionsCell>
    </Grid.Row>
  );
};

const StyledTextArea = styled(TextArea)`
  margin-bottom: .5rem;
`;

const InvalidationPrompt = styled.div`
  margin-bottom: .75rem;
`;

enum FactManagementToolType {
  Edit = "Edit",
  Invalidate = "Invalidate"
}

interface FactValueDetailsProps {
  fact: Fact;

  updateStatus: OperationStatus<any>;
  onUpdate: (value: string) => Promise<any>;
  resetUpdate: () => void;

  invalidationStatus: OperationStatus<any>;
  onInvalidation: () => Promise<any>;
  resetInvalidation: () => void;
}

const FactValueDetails: React.FunctionComponent<FactValueDetailsProps> = (props) => {
  const currentValue = props.fact.state.valueOption().toJS()?.currentValue();
  const [currentTool, setCurrentTool] = React.useState<FactManagementToolType>();

  return (
    <>
      <DrawerBlock>
        <Panel>
          <PanelRow>
            <Indicators size={"small"} direction={"column"}>
              <SimpleIndicator title={"Fact ID"} value={props.fact.id}/>
              <SimpleIndicator title={"Subject Type"} value={props.fact.subjectType}/>
              <SimpleIndicator title={"Subject ID"} value={props.fact.subjectId}/>
              <SimpleIndicator title={"Family"} value={props.fact.family}/>
              <SimpleIndicator title={"Value Type"} value={props.fact.valueType}/>
              <SimpleIndicator title={"Status"} value={props.fact.state.statusDescription()}/>
              <JsonIndicator title={"Error Details"} value={props.fact.state.errorDetails().getOrUse("--")}/>
            </Indicators>
          </PanelRow>
        </Panel>
      </DrawerBlock>

      {currentValue instanceof ItemBreakdown && (
        <DrawerBlock>
          <Panel>
            <GridPanelRow>
              <ItemBreakdownGrid value={currentValue}/>
            </GridPanelRow>
          </Panel>
        </DrawerBlock>
      )}

      <DrawerBlock>
        <Panel>
          <PanelRow>
            <pre>{prettyPrint(currentValue)}</pre>
          </PanelRow>
        </Panel>
      </DrawerBlock>

      <DrawerBlock>
        <PanelRow>
          <Switch
            items={[
              { value: FactManagementToolType.Edit, content: "Edit" },
              { value: FactManagementToolType.Invalidate, content: "Invalidate" }
            ]}
            onSelect={(tool) => {
              setCurrentTool(tool);
              props.resetUpdate();
            }}
            selected={currentTool}
          />
        </PanelRow>

        {currentTool === FactManagementToolType.Edit && (
          <>
            <OperationStatusIndicator
              progressMessage={"Updating..."}
              status={props.updateStatus}
              indicators={StatusIndicators.PanelRow()}
            />
            <PanelRow>
              <EditFactForm
                value={currentValue}
                isWorking={props.updateStatus.isWorking()}
                onSubmit={(value) => props.onUpdate(value).then(() => setCurrentTool(undefined))}
                onCancel={() => {
                  setCurrentTool(undefined);
                  props.resetUpdate();
                }}
              />
            </PanelRow>
          </>
        )}

        {currentTool === FactManagementToolType.Invalidate && (
          <>
            <OperationStatusIndicator
              progressMessage={"Invalidating..."}
              status={props.invalidationStatus}
              indicators={StatusIndicators.PanelRow()}
            />
            <PanelRow>
              <InvalidationPrompt>
                This will change the status of this fact to "Error".
              </InvalidationPrompt>
              <SimpleToolbar>
                <Button
                  size={"small"}
                  color={"red"}
                  disabled={props.invalidationStatus.isWorking()}
                  onClick={props.onInvalidation}
                >
                  Invalidate
                </Button>
                <Button
                  size={"small"}
                  color={"white"}
                  onClick={() => {
                    setCurrentTool(undefined);
                    props.resetInvalidation();
                  }}
                >
                  Cancel
                </Button>
              </SimpleToolbar>
            </PanelRow>
          </>
        )}
      </DrawerBlock>
    </>
  );
};

interface EditFactFormProps {
  value: any;
  isWorking: boolean;
  onSubmit: (value: string) => void;
  onCancel: () => void;
}

const EditFactForm: React.FunctionComponent<EditFactFormProps> = (props) => {
  const [value, setValue] = React.useState(prettyPrint(props.value) || "");
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      window.setTimeout(
        ()  => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          }
        },
        0
      );
    },
    []
  );

  return (
    <>
      <StyledTextArea
        ref={textAreaRef}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)}
        rows={5}
        maxWidth={40}
        disabled={props.isWorking}
      />
      <SimpleToolbar>
        <Button
          size={"small"}
          onClick={() => props.onSubmit(value)}
          disabled={props.isWorking}
        >
          Submit
        </Button>
        <Button size={"small"} color={"white"} onClick={props.onCancel}>Cancel</Button>
      </SimpleToolbar>
    </>
  );
};

interface ItemBreakdownGridProps {
  value: ItemBreakdown;
}

const ItemBreakdownGrid: React.FunctionComponent<ItemBreakdownGridProps> = (props) => {
  const items: Seq.Indexed<[string, string, number]> =
    props.value.entries
      .flatMap((tagged, itemType) =>
        tagged.values.map((count, tags): [string, string, number] =>
          [itemType, tags.join(", "), count]
        )
      )
      .valueSeq()
      .sortBy(([itemType, tags]) => itemType + ":" + tags);

  return (
    <Grid>
      <Grid.Header>
        <Grid.Column>Item Type</Grid.Column>
        <Grid.Column>Tags</Grid.Column>
        <Grid.Column>Count</Grid.Column>
      </Grid.Header>
      <Grid.Body>
        {
          items.map(([itemType, tags, count]) => (
            <Grid.Row key={itemType + ":" + tags}>
              <Grid.Cell>{itemType}</Grid.Cell>
              <Grid.Cell>{tags}</Grid.Cell>
              <Grid.Cell>{count}</Grid.Cell>
            </Grid.Row>
          ))
        }
      </Grid.Body>
    </Grid>
  );
};
