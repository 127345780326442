import { ActionLike } from "../actionLike";
import { BlueprintFilters } from "./blueprintFilters";

export enum SettingsActionType {
  ToggledAdminTools = "settings/toggledAdminTools",
  MaximizedSidebar = "settings/maximizedSidebar",
  RestoredSidebar = "settings/restoredSidebar",
  UpdatedBlueprintFilters = "settings/updatedBlueprintFilters",
  ToggledBlueprintAnimation = "settings/toggledBlueprintAnimation",
}

export interface ToggledAdminToolsAction extends ActionLike<SettingsActionType.ToggledAdminTools> {
}

export function toggledAdminToolsAction(): ToggledAdminToolsAction {
  return {
    type: SettingsActionType.ToggledAdminTools
  };
}

export interface MaximizedSidebarAction extends ActionLike<SettingsActionType.MaximizedSidebar> {
}

export function maximizedSidebarAction(): MaximizedSidebarAction {
  return {
    type: SettingsActionType.MaximizedSidebar
  };
}

export interface RestoredSidebarAction extends ActionLike<SettingsActionType.RestoredSidebar> {
}

export function restoredSidebarAction(): RestoredSidebarAction {
  return {
    type: SettingsActionType.RestoredSidebar
  };
}

export interface UpdatedBlueprintFiltersAction extends ActionLike<SettingsActionType.UpdatedBlueprintFilters> {
  filters: BlueprintFilters;
}

export function updatedBlueprintFiltersAction(filters: BlueprintFilters): UpdatedBlueprintFiltersAction {
  return {
    type: SettingsActionType.UpdatedBlueprintFilters,
    filters
  };
}

export interface ToggledBlueprintAnimationAction extends ActionLike<SettingsActionType.ToggledBlueprintAnimation> {
}

export function toggledBlueprintAnimationAction(): ToggledBlueprintAnimationAction {
  return {
    type: SettingsActionType.ToggledBlueprintAnimation
  };
}

export type SettingsAction =
  ToggledAdminToolsAction |
  MaximizedSidebarAction |
  RestoredSidebarAction |
  UpdatedBlueprintFiltersAction |
  ToggledBlueprintAnimationAction;
