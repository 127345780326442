import { ActionItems } from "../../models/actionItem";
import { Slowness } from "../../models/slowness";

interface Params {
  actionItems: ActionItems;
  slowness?: Slowness;
}

export function prepareActionItems(params: Params): ActionItems {
  return params.actionItems.concat(Slowness.toActionItems(params.slowness));
}
