import * as React from "react";
import { styled } from "../../app/theme";
import { Spinner } from "../glyphs/spinner";
import { DrawerContent } from "./drawerContent";
import { UserFacingError } from "../../types/userFacingError";
import { ErrorInfo } from "../widgets/errorInfo";
import { downloadFrom } from "../../utils/download";

export interface RemoteContentProps {
  url: string;
}

interface InternalState {
  content?: string;
  error?: UserFacingError;
}

const ProgressCircleContainer = styled.div`
  margin-right: .5rem;
  width: 1.2rem;
  height: 1.2rem;
`;

const Loading = styled.div`
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
`;

const ErrorMessageContainer = styled.div`
  padding-top: 1.5rem;
`;

export class RemoteContent extends React.Component<RemoteContentProps, InternalState> {
  constructor(props: RemoteContentProps) {
    super(props);

    this.state = {};

    this.downloadContent = this.downloadContent.bind(this);
  }

  public componentDidMount() {
    this.downloadContent();
  }

  public componentDidUpdate(prevProps: RemoteContentProps) {
    if (prevProps.url !== this.props.url) {
      this.downloadContent();
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorMessageContainer>
          <ErrorInfo error={this.state.error}/>
        </ErrorMessageContainer>
      );
    } else if (this.state.content !== undefined) {
      return <DrawerContent html={this.state.content}/>;
    } else {
      return (
        <Loading>
          <ProgressCircleContainer><Spinner progress={-1}/></ProgressCircleContainer>
          Loading...
        </Loading>
      );
    }
  }

  protected downloadContent() {
    this.setState({ content: undefined, error: undefined });
    downloadFrom(this.props.url)
      .then((content) =>
        this.setState({ content, error: undefined })
      )
      .catch((error) =>
        this.setState({
          error: UserFacingError.unexpected(error, {
            summary: "Failed to download content",
            retry: () => this.downloadContent()
          })
        })
      );
  }
}
