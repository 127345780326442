import { useGetSourceConnectionPromotionsQuery } from "../queries/useGetSourceConnectionPromotionsQuery";
import { useProgramAlias } from "../app/configuration";
import { ProgramStatus } from "../types/models/programStatus";
import { SourceConnectionPromotionMessage } from "../types/models/sourceConnectionPromotionMessage";

interface Params {
  sourceConnectionId: string | undefined;
  destinationCloudServiceId: string;
  destinationConnectionId: string | undefined;
}

export function useSourceConnectionPromotions(params: Params): SourceConnectionPromotionMessage.Any | undefined {
  const currentProgramAlias = useProgramAlias();

  const [eligibleProgramsStatus] = useGetSourceConnectionPromotionsQuery({
    currentProgramAlias,
    sourceConnectionId: params.sourceConnectionId,
    destinationCloudServiceId: params.destinationCloudServiceId,
    destinationConnectionId: params.destinationConnectionId
  });

  if (eligibleProgramsStatus.isSuccess()) {
    const currentProgram = eligibleProgramsStatus.result.currentProgram;
    const eligibleProgramIds = eligibleProgramsStatus.result.programEligibility
      .map((programEligibility) => programEligibility.program.id);
    const preferredProgram = eligibleProgramsStatus.result.programEligibility
      .filter((programEligibility) => programEligibility.program.status === ProgramStatus.Live)
      .first(undefined);
    const hasPreviousMigrationsFromSource = eligibleProgramsStatus.result.hasPreviousMigrationsFromSource;

    if (
      currentProgram &&
      eligibleProgramIds.contains(currentProgram.id) &&
      (
        currentProgram.status === ProgramStatus.Restricted ||
        preferredProgram && currentProgram.id === preferredProgram.program.id
      )
    ) {
      if (hasPreviousMigrationsFromSource) {
        return SourceConnectionPromotionMessage.possibleFollowUpMigration(currentProgram);
      } else {
        return undefined;
      }
    } else if (currentProgram && preferredProgram) {
      return SourceConnectionPromotionMessage.switchToAnotherProgram(
        currentProgram,
        preferredProgram.program,
        preferredProgram.theme
      );
    } else if (currentProgram) {
      return SourceConnectionPromotionMessage.switchToVanilla(currentProgram);
    } else if (preferredProgram) {
      return SourceConnectionPromotionMessage.switchFromVanilla(preferredProgram.program, preferredProgram.theme);
    } else {
      return undefined;
    }
  }
}
