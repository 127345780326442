import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { MigrationNote } from "../../types/models/migrationNote";
import { MigrationNoteType } from "../../types/models/migrationNoteType";

type UpdateMigrationNoteMutationHook = [
  (type: MigrationNoteType, message: string) => Promise<MigrationNote>,
  OperationStatus<MigrationNote>
];

export function useUpdateMigrationNoteMutation(noteId: number): UpdateMigrationNoteMutationHook {
  const [updateMigrationNote, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateMigrationNoteMutation,
    extract: (data: GraphQL.UpdateMigrationNoteMutation) => nullToUndefined(data.updateMigrationNote),
    complete: (migrationNote) => MigrationNote.fromGraphQL(migrationNote)
  });

  function go(type: MigrationNoteType, message: string): Promise<MigrationNote> {
    return updateMigrationNote({
      variables: { noteId, type, message },
    });
  }

  return [go, status];
}
