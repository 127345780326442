import * as React from "react";
import { AppBootstrapConfig } from "../types/models/appBootstrapConfig";
import { AuthProviders } from "../types/models/authProviders";
import { useDispatch } from "react-redux";
import { replacedSessionAction } from "../state/session/actions";
import { Session } from "../types/models/session";
import { styled } from "./theme";
import { StatusIndicators } from "../views/utils/statusIndicators";
import { CloudServices } from "../types/models/cloudServices";
import { ThemeOverrides } from "../types/models/themeOverrides";
import { useRoutes } from "./routes/useRoutes";
import { SignInContextType } from "../types/models/signInContextType";
import { useGetAppBootstrapConfigQuery } from "../queries/useGetAppBootstrapConfigQuery";
import { ErrorClass } from "../services/graphql/errorClass";
import { OperationStatusIndicator } from "../views/utils/operationStatusIndicator";
import { SimplePanel } from "../views/containers/simplePanel";
import { Button } from "../views/widgets/button";
import { useBrowser } from "../utils/useBrowser";
import { Constants } from "./constants";
import { useBrowserQuery } from "../utils/useQuery";

const AppBootstrapConfigContext = React.createContext<AppBootstrapConfig | null>(null);

const ProgramAliasContext = React.createContext<string | undefined>(undefined);
const ConfigurationAliasContext = React.createContext<string | undefined>(undefined);
const ThemeIdContext = React.createContext<number | undefined>(undefined);

const StatusIndicatorsWrapper = styled.div`
  margin: 0 2rem;
`;

interface Props {
  configurationAlias: string | undefined;
  programAlias: string | undefined;
  themeId: number | undefined;
  organizationIdOrAlias: string | undefined;

  children: (theme: ThemeOverrides | undefined) => React.ReactElement;
}

export const ConfigurationProvider: React.FunctionComponent<Props> = (props) => {
  const query = useBrowserQuery();

  const [getAppBootstrapConfigQueryStatus] = useGetAppBootstrapConfigQuery({
    configurationAlias: props.configurationAlias,
    programAlias: props.programAlias,
    themeId: props.themeId,
    organizationIdOrAlias: props.organizationIdOrAlias,
    referralCode: query.getString(Constants.QueryParams.ReferralCode)
  });

  const dispatch = useDispatch();

  // Session gets propagated in an effect => it's happening after finishing current render cycle =>
  // children will not see propagated session right away => <ElevatedRoute> will redirect to sign-in =>
  // we need a special flag that will tell that we are ready to render children
  const [sessionPropagated, setSessionPropagated] = React.useState(false);

  React.useEffect(
    () => {
      // Is this query complete?
      if (getAppBootstrapConfigQueryStatus.isSuccess()) {
        if (getAppBootstrapConfigQueryStatus.result.session) {
          dispatch(replacedSessionAction(Session.fromGraphQL(getAppBootstrapConfigQueryStatus.result.session)));
        }
        setSessionPropagated(true);
      }
    },
    [getAppBootstrapConfigQueryStatus.isSuccess()]
  );

  const routes = useRoutes();
  const browser = useBrowser();

  if (getAppBootstrapConfigQueryStatus.isSuccess()) {
    const appBootstrapConfig = getAppBootstrapConfigQueryStatus.result.appBootstrapConfig;
    return (
      <AppBootstrapConfigContext.Provider value={AppBootstrapConfig.parse(appBootstrapConfig, routes)}>
        <ProgramAliasContext.Provider value={props.programAlias}>
          <ConfigurationAliasContext.Provider value={props.configurationAlias}>
            <ThemeIdContext.Provider value={props.themeId}>
              {
                sessionPropagated &&
                props.children(
                  appBootstrapConfig.theme
                    ? ThemeOverrides.parse(appBootstrapConfig.theme)
                    : undefined
                )
              }
            </ThemeIdContext.Provider>
          </ConfigurationAliasContext.Provider>
        </ProgramAliasContext.Provider>
      </AppBootstrapConfigContext.Provider>
    );
  } else if (
    getAppBootstrapConfigQueryStatus.isFailure() &&
    ErrorClass.extract(getAppBootstrapConfigQueryStatus.error.cause) === ErrorClass.InvalidProgramAliasException
  ) {
    return (
      <StatusIndicatorsWrapper>
        <SimplePanel>
          We're sorry, the web address entered is referencing a partnership program that is no longer active. Please
          check your web address or click the button below to navigate to the home page.
          <br/>
          <br/>
          <Button
            size={"narrow"}
            onClick={() => browser.navigateTo("/")}
          >
            Navigate To The Home Page
          </Button>
        </SimplePanel>
      </StatusIndicatorsWrapper>
    );
  } else {
    return (
      <OperationStatusIndicator
        subject={"system configuration"}
        failureMessage={
          "Failed to load system configuration, please click the \"Try again\" button below. " +
          "If this does not help, please refresh this page in your browser."
        }
        indicators={StatusIndicators.SimplePanel({ wrapper: StatusIndicatorsWrapper })}
        status={getAppBootstrapConfigQueryStatus}
      />
    );
  }
};

export function useAppBootstrapConfig(): AppBootstrapConfig {
  const appBootstrapConfig = React.useContext(AppBootstrapConfigContext);
  if (appBootstrapConfig) {
    return appBootstrapConfig;
  } else {
    throw Error("App configuration is not available");
  }
}

export function useCloudServices(signInContextType?: SignInContextType): CloudServices {
  switch (signInContextType) {
    case SignInContextType.Source: return useAppBootstrapConfig().sourceCloudServices;
    case SignInContextType.Destination: return useAppBootstrapConfig().destinationCloudServices;
    default: return useAppBootstrapConfig().cloudServices;
  }
}

export function useAuthProviders(): AuthProviders {
  return useAppBootstrapConfig().authProviders;
}

export function useProgramAlias(): string | undefined {
  return React.useContext(ProgramAliasContext);
}

export function useConfigurationAlias(): string | undefined {
  return React.useContext(ConfigurationAliasContext);
}

export function useThemeId(): number | undefined {
  return React.useContext(ThemeIdContext);
}
