import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type UnsubscribeOffboardingProjectEntryMutationHook = [
  () => Promise<boolean>,
  OperationStatus<boolean>
];

export function useUnsubscribeOffboardingProjectEntryMutation(
  id: string
): UnsubscribeOffboardingProjectEntryMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUnsubscribeOffboardingProjectEntryMutation,
    extract: (data: GraphQL.UnsubscribeOffboardingProjectEntryMutation) =>
      nullToUndefined(data.unsubscribeOffboardingProjectEntry),
    complete: identity
  });

  function fireWith(): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(),
    });
  }

  return [fireWith, status];
}
