import { GraphQL } from "../../services/graphql/generated";
import { ObjectRepo } from "../../utils/objectRepo";
import { Connection } from "./connection";

export class Connections extends ObjectRepo<Connection> {
}

export namespace Connections {
  export const Empty: Connections = new Connections();

  export function fromGraphQLList(connections: GraphQL.ConnectionFragment[]): Connections {
    return new Connections(connections.map(Connection.fromGraphQL));
  }
}
