import * as React from "react";
import { OperationStatus } from "../../../../../types/operationStatus";
import { Step } from "../../../../containers/step";
import { StatusIndicators } from "../../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../../utils/operationStatusIndicator";
import { BatchOrder } from "../../../../../types/models/batchOrder";
import { BatchPriceBreakdownPanel } from "./batchPriceBreakdownPanel";
import { BraintreeForm } from "../../checkoutStepView/braintreeForm";
import { UserFacingError } from "../../../../../types/userFacingError";
import { Payment } from "../../../../../migrationSetup/usePaymentFlow";
import { PanelRow } from "../../../../containers/rows/panelRow";
import { Block } from "../../../../containers/block";
import { Panel } from "../../../../containers/panel";
import { SingleAccountHint } from "../batchMigrationPlanStepView/singleAccountHint";
import { CloudService } from "../../../../../types/models/cloudService";
import { List } from "immutable";
import { BatchMigrationItem } from "../../../../../migrationSetup/batch/batchCheckoutStep";
import { useAppBootstrapConfig } from "../../../../../app/configuration";
import { DiscountsConfig, DiscountsPanel } from "../../checkoutStepView/discountsPanel";

interface PrepareBatchOrderResult {
  batchOrder: BatchOrder;
  clientToken: string;
}

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  items: List<BatchMigrationItem>;
  onNavigateBack?: () => void;

  loadingStatus: OperationStatus<PrepareBatchOrderResult>;
  braintreeInitStatus: OperationStatus<any>;
  requestPaymentMethodStatus: OperationStatus<any>;
  scheduleBatchStatus: OperationStatus<any>;

  onBraintreeInit: () => void;
  onBraintreeInitSuccess: (braintree: any, deviceData: any) => void;
  onBraintreeInitFailure: (error: UserFacingError) => void;
  onSubmit: () => void;
  onBraintreeTeardown: () => void;

  discounts: DiscountsConfig;
  payment: Payment | undefined;
  submitDisabled: boolean;
}

export const BatchCheckoutStepView: React.FunctionComponent<Props> = (props) => {
  const configuration = useAppBootstrapConfig();

  function submitTitle() {
    if (props.payment) {
      return props.payment.amountToBePaid > 0
        ? `Pay $${props.payment.amountToBePaid.toFixed(2)} Now`
        : "Submit Batch Now";
    }
  }

  return (
    <Step
      noForm={true}
      breadcrumbs={["Confirm & Go"]}
      title={"Secure Checkout"}
      subTitle={`Make sure that everything looks good!`}
      submitDisabled={props.submitDisabled}
      submitTitle={submitTitle()}
      submitSubText={
        props.payment &&
        props.payment.amountToBePaid > 0
          ? "& your batch migration will be created"
          : undefined
      }
      submitSize={"normal"}
      onSubmit={props.onSubmit}
      onNavigateBack={props.onNavigateBack}
    >
      <OperationStatusIndicator
        progressMessage={"Preparing your receipt..."}
        failureMessage={"Failed to load your receipt."}
        status={props.loadingStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.loadingStatus.isSuccess() && props.payment && (
        <>
          {props.items.size === 1 && (
            <Block>
              <SingleAccountHint
                adding={false}
                sourceCloudService={props.sourceCloudService}
                destinationCloudService={props.destinationCloudService}
                item={props.items.first()}
              />
            </Block>
          )}
          <BatchPriceBreakdownPanel
            batchOrder={props.loadingStatus.result.batchOrder}
            payment={props.payment}
          />
          {/*<Block>*/}
          {/*  <Panel>*/}
          {/*    <PanelRow>*/}
          {/*      You have added {friendlyCount(props.batchSize, "account")} for this migration. Click the*/}
          {/*      "Schedule Batch" button below to create a batch and individual account migrations.*/}
          {/*    </PanelRow>*/}
          {/*    <PanelRow>*/}
          {/*      Note: migrations will NOT be launched immediately, they will have to be launched manually once you*/}
          {/*      are ready.*/}
          {/*    </PanelRow>*/}
          {/*  </Panel>*/}
          {/*</Block>*/}
          <DiscountsPanel {...props.discounts}/>
          <OperationStatusIndicator
            progressMessage={"Initializing secure payment gateway..."}
            failureMessage={"Failed to initialize secure payment gateway."}
            status={props.braintreeInitStatus}
            indicators={StatusIndicators.SimplePanel()}
          />
          <OperationStatusIndicator
            progressMessage={"Submitting payment..."}
            failureMessage={"Failed to submit payment."}
            status={props.requestPaymentMethodStatus}
            indicators={StatusIndicators.SimplePanel()}
          />
          <OperationStatusIndicator
            progressMessage={"Creating batch..."}
            failureMessage={"Failed to submit batch"}
            status={props.scheduleBatchStatus}
            indicators={StatusIndicators.SimplePanel()}
          />
          {props.scheduleBatchStatus.isPending() && (
            <BraintreeForm
              configuration={configuration}
              clientToken={props.loadingStatus.result.clientToken}
              amount={props.loadingStatus.result.batchOrder.priceBeforeDiscounts}
              onInit={props.onBraintreeInit}
              onInitSuccess={props.onBraintreeInitSuccess}
              onInitFailure={props.onBraintreeInitFailure}
              onTeardown={props.onBraintreeTeardown}
            />
          )}
          <Block>
            <Panel>
              <PanelRow>
                Please note: your batch migration will not be started immediately after your payment is accepted.
                You will be able to start the migration manually at the planned time.
              </PanelRow>
            </Panel>
          </Block>
        </>
      )}
    </Step>
  );
};
