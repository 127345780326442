import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { ObjectRepo } from "../../utils/objectRepo";
import { List } from "immutable";
import { MigrationIssueAction } from "./migrationIssueAction";
import { friendlyDuration, preciseDuration } from "../../utils/formatting";

export class MigrationIssue implements MigrationIssue.Props {
  constructor(protected readonly props: MigrationIssue.Props) {
  }

  public get id(): string { return this.props.id; }

  public get class(): string { return this.props.class; }
  public get message(): string { return this.props.message; }
  public get errorJson(): string | undefined { return this.props.errorJson; }
  public get endpointAlias(): string { return this.props.endpointAlias; }
  public get requiredAction(): MigrationIssueAction { return this.props.requiredAction; }
  public get isBlocking(): boolean { return this.props.isBlocking; }

  public get durationMillis(): number | undefined { return this.props.durationMillis; }
  public get willBeAutoResolvedAt(): Date | undefined { return this.props.willBeAutoResolvedAt; }

  public get createdAt(): Date { return this.props.createdAt; }
  public get updatedAt(): Date { return this.props.updatedAt; }
  public get resolvedAt(): Date | undefined { return this.props.resolvedAt; }
  public get ignoreUntil(): Date | undefined { return this.props.ignoreUntil; }

  public get friendlyDuration(): string | undefined {
    return this.durationMillis !== undefined
      ? friendlyDuration(this.durationMillis / 1000)
      : undefined;
  }

  public isResolved(): boolean {
    return this.ignoreUntil !== undefined && this.ignoreUntil > new Date();
  }

  public get classNameOnly(): string {
    const parts = this.props.class.split(".");
    return parts[parts.length - 1];
  }

  public get adminSummary(): string {
    const duration = this.durationMillis !== undefined
      ? preciseDuration(this.durationMillis / 1000)
      : undefined;
    return this.classNameOnly + (duration ? " (" + duration + ")" : "");
  }
}

export namespace MigrationIssue {
  export interface Props extends ObjectRepo.HasId {
    id: string;

    class: string;
    message: string;
    errorJson: string | undefined;
    endpointAlias: string;
    requiredAction: MigrationIssueAction;
    isBlocking: boolean;

    durationMillis: number | undefined;
    willBeAutoResolvedAt: Date | undefined;

    createdAt: Date;
    updatedAt: Date;
    resolvedAt: Date | undefined;
    ignoreUntil: Date | undefined;
  }

  export function fromGraphQL(migrationIssue: GraphQL.MigrationIssueFragment): MigrationIssue {
    return new MigrationIssue({
      id: migrationIssue.id,

      class: migrationIssue.class,
      message: migrationIssue.message,
      errorJson: nullToUndefined(migrationIssue.errorJson),
      endpointAlias: migrationIssue.endpointAlias,
      requiredAction: migrationIssue.requiredAction,
      isBlocking: migrationIssue.isBlocking,

      durationMillis: nullToUndefined(migrationIssue.durationMillis),
      willBeAutoResolvedAt: mapOptional(migrationIssue.willBeAutoResolvedAt, (value) => new Date(value)),

      createdAt: new Date(migrationIssue.createdAt),
      updatedAt: new Date(migrationIssue.updatedAt),
      resolvedAt: mapOptional(migrationIssue.resolvedAt, (value) => new Date(value)),
      ignoreUntil: mapOptional(migrationIssue.ignoreUntil, (value) => new Date(value)),
    });
  }

  export function fromGraphQLList(migrationIssues: GraphQL.MigrationIssueFragment[]): List<MigrationIssue> {
    return List(migrationIssues.map(fromGraphQL));
    // return TestData;
  }

  export enum IssueClass {
    InvalidAccessKeyIssue = "com.unboundedcloud.bamboo.framework.issues.InvalidAccessKeyIssue",
    ServiceNotEnabledIssue = "com.unboundedcloud.bamboo.framework.issues.ServiceNotEnabledIssue",
    StorageQuotaExceededIssue = "com.unboundedcloud.bamboo.framework.issues.StorageQuotaExceededIssue",
    TooManyRequestsIssue = "com.unboundedcloud.bamboo.framework.issues.TooManyRequestsIssue",
  }

  export const TestData: List<MigrationIssue> = List([
    // new MigrationIssue({
    //   id: "Source:ChildEndpoint:DriveSource",
    //   class: IssueClass.InvalidAccessKeyIssue,
    //   message: "Message from the server",
    //   endpointAlias: "Source:RootEndpoint:Source",
    //   requiredAction: MigrationIssueAction.ProvideNewAccessKey,
    //   isBlocking: true,
    //   createdAt: new Date(),
    //   updatedAt: new Date(),
    //   resolvedAt: undefined,
    //   ignoreUntil: undefined
    // }),
    // new MigrationIssue({
    //   id: "Destination-InvalidAccessKeyIssue",
    //   class: IssueClass.InvalidAccessKeyIssue,
    //   message: "Message from the server",
    //   endpointAlias: "Destination:RootEndpoint:Destination",
    //   requiredAction: MigrationIssueAction.ProvideNewAccessKey,
    //   isBlocking: true,
    //   createdAt: new Date(),
    //   updatedAt: new Date(),
    //   resolvedAt: undefined,
    //   ignoreUntil: undefined
    // }),

    new MigrationIssue({
      id: "Source-DriveDisabled",
      class: IssueClass.ServiceNotEnabledIssue,
      message: "Message from the server",
      errorJson: undefined,
      endpointAlias: "Source:ChildEndpoint:DriveSource",
      requiredAction: MigrationIssueAction.Fix,
      isBlocking: true,
      durationMillis: undefined,
      willBeAutoResolvedAt: undefined,
      createdAt: new Date(),
      updatedAt: new Date(),
      resolvedAt: undefined,
      ignoreUntil: undefined
    }),
    new MigrationIssue({
      id: "Source-SomethingDisabled",
      class: IssueClass.ServiceNotEnabledIssue,
      message: "Message from the server",
      errorJson: undefined,
      endpointAlias: "Source:ChildEndpoint:Something",
      requiredAction: MigrationIssueAction.Fix,
      isBlocking: true,
      durationMillis: undefined,
      willBeAutoResolvedAt: undefined,
      createdAt: new Date(),
      updatedAt: new Date(),
      resolvedAt: undefined,
      ignoreUntil: undefined
    }),

    new MigrationIssue({
      id: "Destination-DriveDisabled",
      class: IssueClass.ServiceNotEnabledIssue,
      message: "Message from the server",
      errorJson: undefined,
      endpointAlias: "Destination:ChildEndpoint:DriveDestination",
      requiredAction: MigrationIssueAction.Fix,
      isBlocking: true,
      durationMillis: undefined,
      willBeAutoResolvedAt: undefined,
      createdAt: new Date(),
      updatedAt: new Date(),
      resolvedAt: undefined,
      ignoreUntil: undefined
    }),
    new MigrationIssue({
      id: "Destination-SomethingDisabled",
      class: IssueClass.ServiceNotEnabledIssue,
      message: "Message from the server",
      errorJson: undefined,
      endpointAlias: "Destination:ChildEndpoint:Something",
      requiredAction: MigrationIssueAction.Fix,
      isBlocking: true,
      durationMillis: undefined,
      willBeAutoResolvedAt: undefined,
      createdAt: new Date(),
      updatedAt: new Date(),
      resolvedAt: undefined,
      ignoreUntil: undefined
    }),

    //   new MigrationIssue({
    //     id: "Destination-StorageQuotaExceededIssue-1",
    //     class: IssueClass.StorageQuotaExceededIssue,
    //     message: "Account is out of storage (root endpoint issue)",
    //     endpointAlias: "Destination:RootEndpoint:Destination",
    //     requiredAction: MigrationIssueAction.Fix,
    //     isBlocking: true,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     resolvedAt: undefined,
    //     ignoreUntil: undefined
    //   }),
    //   new MigrationIssue({
    //     id: "Destination-StorageQuotaExceededIssue-2",
    //     class: IssueClass.StorageQuotaExceededIssue,
    //     message: "Drive is out of storage (child endpoint issue)",
    //     endpointAlias: "Destination:ChildEndpoint:DriveDestination",
    //     requiredAction: MigrationIssueAction.Fix,
    //     isBlocking: true,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     resolvedAt: undefined,
    //     ignoreUntil: undefined
    //   }),
    //
    //   new MigrationIssue({
    //     id: "Source-TooManyRequestsIssue",
    //     class: IssueClass.TooManyRequestsIssue,
    //     message: "Message from the server",
    //     endpointAlias: "Source:ChildEndpoint:DriveSource",
    //     requiredAction: MigrationIssueAction.View,
    //     isBlocking: false,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     resolvedAt: undefined,
    //     ignoreUntil: undefined
    //   }),
    //   new MigrationIssue({
    //     id: "Destination-TooManyRequestsIssue",
    //     class: IssueClass.TooManyRequestsIssue,
    //     message: "Message from the server",
    //     endpointAlias: "Destination:ChildEndpoint:DriveDestination",
    //     requiredAction: MigrationIssueAction.View,
    //     isBlocking: false,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     resolvedAt: undefined,
    //     ignoreUntil: undefined
    //   }),
    //
    //   new MigrationIssue({
    //     id: "SomethingTemporary",
    //     class: "SomethingTemporary",
    //     message: "Message from the server",
    //     endpointAlias: "Destination:ChildEndpoint:DriveDestination",
    //     requiredAction: MigrationIssueAction.View,
    //     isBlocking: true,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     resolvedAt: undefined,
    //     ignoreUntil: undefined
    //   })
  ]);
}
