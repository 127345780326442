import * as React from "react";
import * as yup from "yup";
import { OrganizationPortalUser } from "../../../../types/models/organizationPortalUser";
import { FormLayout } from "../../../widgets/formLayout";
import { TextField } from "../../../widgets/textField";
import { FormField } from "../../../widgets/formField";
import { friendlyDateTime } from "../../../../utils/formatting";
import { SimplePanel } from "../../../containers/simplePanel";
import { ItemForm } from "../../../blocks/crud/itemForm";
import { FormConfig } from "../../../blocks/crud/formConfig";
import { OrganizationPortalUserFormData } from "../../../../types/models/organizationPortalUserFormData";
import { OrganizationPortalUserRole } from "../../../../types/enums/organizationPortalUserRole";
import { DropDownField } from "../../../widgets/dropDownField";

interface FormData {
  emailAddress: string;
  role: OrganizationPortalUserRole;

  firstName: string;
  lastName: string;
}

namespace FormData {
  export const blank: FormData = {
    emailAddress: "",
    role: OrganizationPortalUserRole.ReadAccess,

    firstName: "",
    lastName: "",
  };

  export function make(organizationPortalUser: OrganizationPortalUser): FormData {
    return {
      emailAddress: organizationPortalUser.emailAddress,
      role: organizationPortalUser.role,

      firstName: organizationPortalUser.firstName || "",
      lastName: organizationPortalUser.lastName || ""
    };
  }

  export function toResult(formData: FormData): OrganizationPortalUserFormData {
    return {
      emailAddress: formData.emailAddress.trim(),
      role: formData.role,

      firstName: formData.firstName.length ? formData.firstName : undefined,
      lastName: formData.lastName.length ? formData.lastName : undefined,
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    emailAddress: yup.string().required("Email address is required"),
    role: yup.mixed().oneOf(OrganizationPortalUserRole.all),

    firstName: yup.string(),
    lastName: yup.string()
  });
}

interface Props {
  config: FormConfig<OrganizationPortalUser, OrganizationPortalUserFormData>;
  allowChangingRole: boolean;
  allowOwnerRole: boolean;
}

export const OrganizationPortalUserForm: React.FunctionComponent<Props> = (props) => (
  <ItemForm<OrganizationPortalUser, FormData, OrganizationPortalUserFormData>
    config={props.config}

    subject={"user"}

    blankFormData={FormData.blank}
    makeFormData={FormData.make}
    makeResult={FormData.toResult}
    validationSchema={FormData.validationSchema}
  >
    {({ firstInputRef, submitting, formProps }) => (
      <>
        <SimplePanel>
          <FormLayout noBottomMargin={true}>
            <TextField<FormData>
              label={"Email address"}
              name={"emailAddress"}
              required={true}
              maxLength={255}
              disabled={submitting}
              textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
            />
            <TextField<FormData>
              label={"First name"}
              name={"firstName"}
              maxLength={255}
              disabled={submitting}
            />
            <TextField<FormData>
              label={"Last name"}
              name={"lastName"}
              maxLength={255}
              disabled={submitting}
            />
            <DropDownField<FormData>
              label={"Role"}
              name={"role"}
              disabled={submitting || !props.allowChangingRole}
            >
              <option value={OrganizationPortalUserRole.ReadAccess}>
                {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.ReadAccess)}
              </option>
              <option value={OrganizationPortalUserRole.ReadAndWriteAccess}>
                {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.ReadAndWriteAccess)}
              </option>
              <option value={OrganizationPortalUserRole.Administrator}>
                {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.Administrator)}
              </option>
              {props.allowOwnerRole && (
                <option value={OrganizationPortalUserRole.Owner}>
                  {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.Owner)}
                </option>
              )}
            </DropDownField>
            {
              props.config.item && !props.config.isNew && (
                <>
                  <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                  <FormField>
                    Signed in:{" "}
                    {props.config.item.signedInAt ? friendlyDateTime(props.config.item.signedInAt) : "--"}
                  </FormField>
                </>
              )
            }
          </FormLayout>
        </SimplePanel>
      </>
    )}
  </ItemForm>
);
