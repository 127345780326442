import * as React from "react";
import { styled, AppTheme } from "../../app/theme";

type CheckMarkColor = "blue" | "red" | "white" | "black";

interface CheckMarkProps {
  color?: CheckMarkColor;
  strokeWidthMultiplier?: number;
}

function strokeColor(theme: AppTheme, color?: CheckMarkColor) {
  switch (color) {
    case "white": return theme.colors.white;
    case "black": return theme.colors.black;
    case "red": return theme.colors.red;
    default: return theme.colors.primary;
  }
}

const StyledSvg = styled.svg<CheckMarkProps>`
  width: 100%;
  height: 100%;
  
  stroke: ${(props) => strokeColor(props.theme, props.color)};
`;

const X1 = 15.2;
const Y1 = 0.5;
const DX = X1 - 6.3;
const DY = 13.6 - Y1;
const Angle = Math.atan2(DY, DX);
const L1 = Math.sqrt(DX * DX + DY * DY);
const L2 = L1 * 0.45;

export const CheckMark: React.FunctionComponent<CheckMarkProps> = (props) => {
  const strokeWidth = props.strokeWidthMultiplier || 1;

  const x2 = X1 - Math.cos(Angle) * L1;
  const y2 = Y1 + Math.sin(Angle) * L1;
  const x3 = x2 - Math.sin(Angle) * L2;
  const y3 = y2 - Math.cos(Angle) * L2;

  const vx1 = x3 - Math.cos(Angle) * strokeWidth / 2;
  const vy1 = Y1 - Math.cos(Angle) * strokeWidth / 2;
  const vx2 = X1 + Math.sin(Angle) * strokeWidth / 2;
  const vy2 = vy1 + (vx2 - vx1);

  return (
    <StyledSvg
      {...props}
      viewBox={vx1 + " " + vy1 + " " + (vx2 - vx1) + " " + (vy2 - vy1)}
    >
      <path
        fill="none"
        strokeWidth={strokeWidth}
        d={"M " + X1 + " " + Y1 + " L " + x2 + " " + y2 + " L " + x3 + " " + y3}
      />
    </StyledSvg>
  );
};
