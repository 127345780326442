import * as React from "react";
import { css, keyframes, styled } from "../../app/theme";
import { Spacer } from "../utils/spacer";
import { AppFooter } from "./appFooter";
import { AppHeader } from "./appHeader";
import { LeftHandNav } from "./leftHandNav";
import { AdminToolsMode } from "../../state/settings/state";
import { useLocation } from "react-router";
import { SidebarPlaceholder } from "./sidebar";
import { PinnedContent } from "../utils/pinnedContent";
import { useAdminToolsMode } from "./useAdminToolsMode";
import { useAppBootstrapConfig } from "../../app/configuration";

export interface PluggableContent {
  leftHandNavComponent?: React.ComponentType<any>;
  leftHandNavContent?: React.ReactNode;
}

interface AppLayoutProps extends PluggableContent {
  keepLeftHandNavOpenOnLargeScreens: boolean;
}

function masterContainerMixin(adminToolsMode: AdminToolsMode) {
  switch (adminToolsMode) {
    case AdminToolsMode.Hidden:
      return css`
        width: 100%;
        display: block;
        overflow: inherit;
      `;

    case AdminToolsMode.SplitScreen:
      return css`
        width: 40%;
        display: block;
        overflow: auto;
        
        ${(props) => props.theme.responsive.respondToSmall()} {
          display: none;
        }
      `;

    case AdminToolsMode.FullScreen:
      return css`
        display: none;
      `;
  }
}

const MasterContainer = styled.div<{ adminToolsMode: AdminToolsMode }>`
  height: 100%;
  ${(props) => masterContainerMixin(props.adminToolsMode)}
`;

function sidebarContainerMixin(adminToolsMode: AdminToolsMode) {
  switch (adminToolsMode) {
    case AdminToolsMode.Hidden:
      return css`
        display: none;
      `;

    case AdminToolsMode.SplitScreen:
      return css`
        width: 60%;
        display: flex;
        flex-direction: column;
        
        ${(props) => props.theme.responsive.respondToSmall()} {
          width: 100%;
        }
      `;

    case AdminToolsMode.FullScreen:
      return css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `;
  }
}

const SidebarContainer = styled.div<{ adminToolsMode: AdminToolsMode }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.colors.darkGray};
  background: ${(props) => props.theme.colors.white};
  
  ${(props) => sidebarContainerMixin(props.adminToolsMode)}
`;

interface LeftHandNavOverlayProps {
  leftHandNavOpen: boolean;
}

const fadeOut = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LeftHandNavOverlay = styled.div<LeftHandNavOverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(5,37,53,0.4);
  z-index: ${(props) => props.theme.layers.leftHandNavOverlay};
  display: ${(props) => props.leftHandNavOpen ? "block" : "none"};
  animation: ${fadeOut} 0.25s forwards;
`;

interface ContentOuterProps {
  keepLeftHandNavOpenOnLargeScreens: boolean;
}

const Content = styled.div<ContentOuterProps>`
  position: relative;
  margin-left: ${(props) => props.keepLeftHandNavOpenOnLargeScreens ? props.theme.layout.leftHandNav.width : 0};
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 18rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    margin-left: 0;
  }
`;

const StyledPinnedContent = styled(PinnedContent)<ContentOuterProps>`
  box-sizing: border-box;
  left: ${(props) => props.keepLeftHandNavOpenOnLargeScreens ? props.theme.layout.leftHandNav.width : 0};
  width: calc(${(props) => 
    (props.blueprintExplorerSize === AdminToolsMode.Hidden ? 100 : 40) + "% - " + 
    (props.keepLeftHandNavOpenOnLargeScreens ? props.theme.layout.leftHandNav.width : 0)
  });
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    left: 0;
    width: 100%;
  }
`;

export const AppLayout: React.FunctionComponent<AppLayoutProps> = (props) => {
  const [leftHandNavOpen, setLeftHandNavOpen] = React.useState(false);

  const location = useLocation();
  const mounted = React.useRef(false);

  React.useEffect(
    () => {
      if (mounted.current) {
        setLeftHandNavOpen(false);
      }
      mounted.current = true;
    },
    [location]
  );

  const appBootstrapConfig = useAppBootstrapConfig();
  const adminToolsMode = useAdminToolsMode();

  function renderLeftHandNavContent() {
    if (props.leftHandNavContent) {
      return props.leftHandNavContent;
    } else if (props.leftHandNavComponent) {
      return React.createElement(props.leftHandNavComponent);
    }
  }

  const keepLeftHandNavOpenOnLargeScreens =
    adminToolsMode === AdminToolsMode.Hidden && props.keepLeftHandNavOpenOnLargeScreens;

  return (
    <>
      <MasterContainer adminToolsMode={adminToolsMode}>
        <AppHeader
          leftHandNavOpen={leftHandNavOpen}
          adminToolsMode={adminToolsMode}
          onLeftHandNavToggle={() => setLeftHandNavOpen((current) => !current)}
        />
        <LeftHandNav
          open={leftHandNavOpen}
          keepOpenOnLargeScreens={keepLeftHandNavOpenOnLargeScreens}
          appBootstrapConfig={appBootstrapConfig}
        >
          {renderLeftHandNavContent()}
        </LeftHandNav>
        <LeftHandNavOverlay leftHandNavOpen={leftHandNavOpen} onClick={() => setLeftHandNavOpen(false)}/>
        <Content keepLeftHandNavOpenOnLargeScreens={keepLeftHandNavOpenOnLargeScreens}>
          <StyledPinnedContent
            blueprintExplorerSize={adminToolsMode}
            keepLeftHandNavOpenOnLargeScreens={keepLeftHandNavOpenOnLargeScreens}
          />
          {/* The div below is required to make "width: 100%" work properly for child components */}
          <div>
            {props.children}
          </div>
          <Spacer/>
          <AppFooter/>
        </Content>
      </MasterContainer>
      <SidebarContainer adminToolsMode={adminToolsMode} id={"sidebar"}>
        <SidebarPlaceholder/>
      </SidebarContainer>
    </>
  );
};
