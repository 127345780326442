import * as React from "react";
import { ChatGPTLookupController } from "../../../controllers/schoolAdmin/chatGPTLookupController";
import { ControllerHost } from "../../../utils/controller";
import {
  ChatGPTLookupTool,
  ChatGPTLookupPropertyDef,
  ChatGPTLookupPropertyType
} from "../../blocks/chatGPTLookup/chatGPTLookupTool";
import { ModalController, ModalHook } from "../../layouts/modal";
import { OrderedMap } from "immutable";

export namespace Properties {
  export const officialName: ChatGPTLookupPropertyDef = {
    key: "officialName",
    displayName: "Official name",
    type: ChatGPTLookupPropertyType.String
  };

  export const commonName: ChatGPTLookupPropertyDef = {
    key: "commonName",
    displayName: "Common name",
    type: ChatGPTLookupPropertyType.String
  };

  export const mascot: ChatGPTLookupPropertyDef = {
    key: "mascot",
    displayName: "Mascot",
    type: ChatGPTLookupPropertyType.String
  };

  export const country: ChatGPTLookupPropertyDef = {
    key: "country",
    displayName: "Country",
    type: ChatGPTLookupPropertyType.String
  };

  export const type: ChatGPTLookupPropertyDef = {
    key: "type",
    displayName: "Type",
    type: ChatGPTLookupPropertyType.String
  };

  export const avgAnnualGrads: ChatGPTLookupPropertyDef = {
    key: "avgAnnualGrads",
    displayName: "Average annual grads",
    type: ChatGPTLookupPropertyType.String
  };

  export const welcomeMessage: ChatGPTLookupPropertyDef = {
    key: "welcomeMessage",
    displayName: "Welcome message",
    type: ChatGPTLookupPropertyType.String
  };

  export const comments: ChatGPTLookupPropertyDef = {
    key: "comments",
    displayName: "Comments",
    type: ChatGPTLookupPropertyType.String,
    transient: true
  };

  export const all = [officialName, commonName, mascot, country, type, avgAnnualGrads, welcomeMessage, comments];
}

interface ChatGPTLookupModalProps {
  modal: ModalHook;
  domains: string;
  chatGPTLookupController: ChatGPTLookupController;
  onApply: (result: OrderedMap<string, string>) => void;
}

export const ChatGPTLookupModal: React.FunctionComponent<ChatGPTLookupModalProps> = (props) => {
  const domains = props.domains
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length !== 0);

  return (
    <ModalController
      modal={props.modal}
      title={"Look up using ChatGPT"}
      wide={true}
      render={() =>
        domains.length
          ? (
            <ControllerHost
              controller={props.chatGPTLookupController}
              render={(viewProps) => (
                <ChatGPTLookupTool
                  properties={Properties.all}

                  makePrompt={() => viewProps.makePrompt(domains[0])}
                  promptStatus={viewProps.promptStatus}

                  lookup={viewProps.lookup}
                  lookupStatus={viewProps.lookupStatus}

                  onApply={props.onApply}
                />
              )}
            />
          )
          : <>No domains have been provided, lookup is not possible.</>
      }
    />
  );
};
