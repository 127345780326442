import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { AccessListUpdate } from "../../types/models/accessListUpdate";
import { List } from "immutable";

export function useGetAccessListUpdateHistoryQuery(
  id: number
): ManagedQueryHook<GraphQL.GetAccessListUpdateHistoryQueryVariables, List<AccessListUpdate>> {
  return useManagedQuery({
    query: GraphQL.useGetAccessListUpdateHistoryQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetAccessListUpdateHistoryQuery) => nullToUndefined(data.accessListUpdateHistory),
    complete: (accessListUpdates) => List(accessListUpdates)
      .map(AccessListUpdate.parse)
      .sortBy((update) => -update.createdAt)
  });
}
