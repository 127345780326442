import { GraphQL } from "../../graphql/generated";
import { List } from "immutable";
import { WatcherAction } from "../watcherAction";
import { WatcherPlugin } from "../watcherPlugin";
import { ApolloClient } from "apollo-client";
import { WatchedItems } from "../watchedItems";

export namespace WatchedMigrations {
  type WatchMigrationsActionType = "WatchMigrations" | "MonitorMigrations";

  export interface WatchMigrationsAction extends WatcherAction<WatchMigrationsActionType> {
    migrationIds: List<string>;
  }

  export const WatchMigrationsAction = (migrations: Iterable<string>): WatchMigrationsAction => ({
    type: "WatchMigrations",
    migrationIds: List(migrations)
  });

  export const MonitorMigrationsAction = (migrations: Iterable<string>): WatchMigrationsAction => ({
    type: "MonitorMigrations",
    migrationIds: List(migrations)
  });

  export const Plugin: WatcherPlugin<ApolloClient<object>, string, number> = {
    itemType: "migration",

    refreshTimeout: 30 * 1000,

    key: (item) => item,

    // discriminator: (context, item) => {
    //   if (item.__typename) {
    //     const migration = context.readFragment(
    //       {
    //         id: item.__typename + ":" + item.id,
    //         fragment: gql(`
    //           fragment Value on ${item.__typename} {
    //             value {
    //               type
    //               completeValue
    //               partialValue
    //             }
    //           }
    //         `)
    //       }
    //     );
    //     return migration && migration.value.completeValue;
    //   }
    // },

    reduce: (action, existingItems) => {
      switch (action.type) {
        case "WatchMigrations":
          return WatchedItems.Diff.build(action.migrationIds, true);

        case "MonitorMigrations":
          return WatchedItems.Diff.build(
            action.migrationIds,
            false,
            existingItems.toSet().subtract(action.migrationIds).toList()
          );
      }
    },

    updateSubscriptions: (context, diff, dispatchRenews) => {
      if (!diff.added.isEmpty()) {
        context
          .subscribe<GraphQL.MigrationEventsSubscription, GraphQL.MigrationEventsSubscriptionVariables>({
            query: GraphQL.MigrationEventsDocument,
            variables: { migrationIds: diff.addedItems.toArray() }
          })
          .subscribe(({ data }) => {
            if (data) {
              dispatchRenews(data.subscribeForMigrationEvents.migrations.map((migration) => migration.id));
            }
          });
      }

      // Note: Un-subscribing is not supported by Apollo Client, so not doing anything for diff.removed
    },

    refreshItems: (context, migrationIds, dispatchRenews) => {
      if (!migrationIds.isEmpty()) {
        return context
          .query<GraphQL.FreshMigrationsQuery, GraphQL.FreshMigrationsQueryVariables>({
            query: GraphQL.FreshMigrationsDocument,
            variables: { migrationIds: migrationIds.toArray() },
            fetchPolicy: "network-only"
          })
          .then(({ data }) => dispatchRenews(data.migrations.map((migration) => migration.id)));
      }
    }
  };
}
