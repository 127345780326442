import * as React from "react";
import { useAvailableJobStatuses } from "./useAvailableJobStatuses";
import { ScheduleJobTransitionView } from "../../views/blocks/jobManagement/scheduleJobTransitionView";
import { useJobStatusTransitionScheduling } from "./useJobStatusTransitionScheduling";

interface Props {
  jobId: string;
  onScheduled: () => void;
}

export const ScheduleJobTransition: React.FunctionComponent<Props> = (props) => {
  const [loadingStatus, refreshJobStatuses] = useAvailableJobStatuses(props.jobId);
  const [schedulingTransitionTo, setSchedulingTransitionTo] = React.useState<string | undefined>();
  const [schedule, schedulingStatus] = useJobStatusTransitionScheduling();

  return (
    <ScheduleJobTransitionView
      loadingStatus={loadingStatus}
      schedulingTransitionTo={schedulingTransitionTo}
      schedulingStatus={schedulingStatus}
      onScheduleTransition={(status, force: boolean) => {
        setSchedulingTransitionTo(status);
        schedule(props.jobId, status, force).then(() => {
          refreshJobStatuses();
          props.onScheduled();
        });
      }}
    />
  );
};
