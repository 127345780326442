import * as React from "react";
import { UserFacingError } from "./userFacingError";

type MapLastResultFunction<T, S> = (result: T) => S;
type FlatMapLastResultFunction<T, S> = (result: T) => OperationStatus<S>;

export abstract class OperationStatus<T> {
  public static Pending<T>(): OperationStatus.PendingStatus<T> {
    return new OperationStatus.PendingStatus<T>();
  }

  public static Working<T>(options: OperationStatus.WorkingStatusOptions<T> = {}): OperationStatus.WorkingStatus<T> {
    return new OperationStatus.WorkingStatus<T>(options);
  }

  public static Success<T>(result: T): OperationStatus.SuccessStatus<T> {
    return new OperationStatus.SuccessStatus<T>(result);
  }

  public static Failure<T>(
    error: UserFacingError,
    options: OperationStatus.FailureStatusOptions<T> = {}
    ): OperationStatus.FailureStatus<T> {
    return new OperationStatus.FailureStatus<T>(error, options);
  }

  public isPending(): this is OperationStatus.PendingStatus<T> {
    return false;
  }

  public isWorking(): this is OperationStatus.WorkingStatus<T> {
    return false;
  }

  public isSuccess(): this is OperationStatus.SuccessStatus<T> {
    return false;
  }

  public isFailure(): this is OperationStatus.FailureStatus<T> {
    return false;
  }

  public abstract withResult(result: T): OperationStatus<T>;

  public abstract map<S>(f: MapLastResultFunction<T, S>): OperationStatus<S>;

  public abstract flatMap<S>(f: FlatMapLastResultFunction<T, S>): OperationStatus<S>;

  public abstract mapLastResult<S>(f: MapLastResultFunction<T, S>, includeFailures?: boolean): S | undefined;

  public abstract someResult(): T | undefined;

  public abstract adopted<S>(lastResult?: S): OperationStatus<S>;
}

export namespace OperationStatus {
  export class PendingStatus<T> extends OperationStatus<T> {
    public isPending(): this is PendingStatus<T> {
      return true;
    }

    public withResult(result: T): OperationStatus<T> {
      return this;
    }

    public map<S>(f: MapLastResultFunction<T, S>): OperationStatus<S> {
      return new PendingStatus();
    }

    public flatMap<S>(f: FlatMapLastResultFunction<T, S>): OperationStatus<S> {
      return new PendingStatus();
    }

    public mapLastResult<S>(f: MapLastResultFunction<T, S>, includeFailures?: boolean): S | undefined {
      return undefined;
    }

    public someResult(): T | undefined {
      return undefined;
    }

    public adopted<S>(): PendingStatus<S> {
      return new PendingStatus();
    }
  }

  export interface WorkingStatusOptions<T> {
    readonly lastResult?: T;
    readonly retrying?: boolean;
  }

  export class WorkingStatus<T> extends OperationStatus<T> {
    constructor(protected readonly options: WorkingStatusOptions<T>) {
      super();
    }

    public get lastResult(): T | undefined {
      return this.options.lastResult;
    }

    public get retrying(): boolean | undefined {
      return this.options.retrying;
    }

    public isWorking(): this is WorkingStatus<T> {
      return true;
    }

    public withResult(result: T): OperationStatus<T> {
      return new WorkingStatus({ ...this.options, lastResult: result });
    }

    public map<S>(f: MapLastResultFunction<T, S>): OperationStatus<S> {
      return new WorkingStatus({ retrying: this.retrying });
    }

    public flatMap<S>(f: FlatMapLastResultFunction<T, S>): OperationStatus<S> {
      return new WorkingStatus({ retrying: this.retrying });
    }

    public mapLastResult<S>(f: MapLastResultFunction<T, S>, includeFailures?: boolean): S | undefined {
      return this.options.lastResult !== undefined ? f(this.options.lastResult) : undefined;
    }

    public someResult(): T | undefined {
      return this.options.lastResult;
    }

    public adopted<S>(lastResult?: S): PendingStatus<S> {
      return new WorkingStatus({ retrying: this.retrying, lastResult });
    }
  }

  export class SuccessStatus<T> extends OperationStatus<T> {
    constructor(public readonly result: T) {
      super();
    }

    public isSuccess(): this is SuccessStatus<T> {
      return true;
    }

    public withResult(result: T): OperationStatus<T> {
      return new SuccessStatus(result);
    }

    public map<S>(f: MapLastResultFunction<T, S>): OperationStatus<S> {
      return new SuccessStatus(f(this.result));
    }

    public flatMap<S>(f: FlatMapLastResultFunction<T, S>): OperationStatus<S> {
      return f(this.result);
    }

    public mapLastResult<S>(f: MapLastResultFunction<T, S>, includeFailures?: boolean): S | undefined {
      return f(this.result);
    }

    public someResult(): T | undefined {
      return this.result;
    }

    public adopted<S>(): SuccessStatus<S> {
      throw new Error("This method cannot be used for success status");
    }
  }

  export interface FailureStatusOptions<T> {
    lastResult?: T;
  }

  export class FailureStatus<T> extends OperationStatus<T> {
    constructor(
      public readonly error: UserFacingError,
      protected readonly options: FailureStatusOptions<T>
    ) {
      super();
    }

    public isFailure(): this is FailureStatus<T> {
      return true;
    }

    public withResult(result: T): OperationStatus<T> {
      return new FailureStatus(this.error, { ...this.options, lastResult: result });
    }

    public map<S>(f: MapLastResultFunction<T, S>): OperationStatus<S> {
      return new FailureStatus(this.error, {});
    }

    public flatMap<S>(f: FlatMapLastResultFunction<T, S>): OperationStatus<S> {
      return new FailureStatus(this.error, {});
    }

    public mapLastResult<S>(f: MapLastResultFunction<T, S>, includeFailures?: boolean): S | undefined {
      return includeFailures && this.options.lastResult !== undefined ? f(this.options.lastResult) : undefined;
    }

    public someResult(): T | undefined {
      return this.options.lastResult;
    }

    public adopted<S>(lastResult?: S): PendingStatus<S> {
      return new FailureStatus(this.error, { lastResult });
    }
  }
}

export interface OperationStatusHook<T> {
  current: OperationStatus<T>;

  setPending: () => OperationStatus.PendingStatus<T>;
  setWorking: (options?: OperationStatus.WorkingStatusOptions<T>) => OperationStatus.WorkingStatus<T>;
  setSuccess: (result: T) => OperationStatus.SuccessStatus<T>;
  setFailure: (
    error: UserFacingError,
    options?: OperationStatus.FailureStatusOptions<T>
  ) => OperationStatus.FailureStatus<T>;

  setUnexpectedError: (
    error: any,
    props?: Partial<UserFacingError.Props>,
    options?: OperationStatus.FailureStatusOptions<T>
  ) => OperationStatus.FailureStatus<T>;

  setExpectedError: (
    error: any,
    props: Partial<UserFacingError.Props>,
    options?: OperationStatus.FailureStatusOptions<T>
  ) => OperationStatus.FailureStatus<T>;

  setSyntheticError: (
    props: Partial<UserFacingError.Props>,
    options?: OperationStatus.FailureStatusOptions<T>
  ) => OperationStatus.FailureStatus<T>;
}

export function useOperationStatus<T>(): OperationStatusHook<T> {
  const [status, setStatus] = React.useState<OperationStatus<T>>(OperationStatus.Pending());

  function change<S extends OperationStatus<T>>(value: S): S {
    setStatus(value);
    return value;
  }

  return {
    current: status,

    setPending: () => change(OperationStatus.Pending()),
    setWorking: (options) => change(OperationStatus.Working(options)),
    setSuccess: (result) => change(OperationStatus.Success(result)),
    setFailure: (error, options) => change(OperationStatus.Failure(error, options || {})),

    setUnexpectedError: (error, props, options) =>
      change(OperationStatus.Failure(UserFacingError.unexpected(error, props), options)),

    setExpectedError: (error, props, options) =>
      change(OperationStatus.Failure(UserFacingError.expected(error, props), options)),

    setSyntheticError: (props, options) =>
      change(OperationStatus.Failure(UserFacingError.synthetic(props), options))
  };
}
