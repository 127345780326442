import { List, Map } from "immutable";

export abstract class Registry<T> {
  private map: Map<string, T>;

  constructor(public readonly itemType: string, items: List<T>) {
    this.map = Map(items.map((item) => [this.key(item), item]));
  }

  public get(key: string): T {
    const item = this.map.get(key);
    if (item) {
      return item;
    } else {
      throw Error("Unknown " + this.itemType + ": " + key);
    }
  }

  public keys(): string[] {
    return this.map.keySeq().toArray();
  }

  public abstract key(item: T): string;
}

export class NamedRegistry<T extends NamedRegistry.HasName> extends Registry<T> {
  public key(item: T): string {
    return item.name;
  }
}

export namespace NamedRegistry {
  export interface HasName {
    name: string;
  }

  export function fromArrays<T extends NamedRegistry.HasName>(itemType: string, ...items: T[][]): NamedRegistry<T> {
    return new NamedRegistry<T>(itemType, List(items.reduce((a, b) => a.concat(b))));
  }
}

export class TypedRegistry<T extends TypedRegistry.HasType> extends Registry<T> {
  public key(item: T): string {
    return item.type;
  }
}

export namespace TypedRegistry {
  export interface HasType {
    type: string;
  }

  export function fromArrays<T extends TypedRegistry.HasType>(itemType: string, ...items: T[][]): TypedRegistry<T> {
    return new TypedRegistry<T>(itemType, List(items.reduce((a, b) => a.concat(b))));
  }
}
