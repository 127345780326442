import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Button } from "../../../widgets/button";
import { ModalController, ModalHook } from "../../../layouts/modal";
import {
  DisableEmailDeliveryController
} from "../../../../controllers/organizationPortal/automatedOffboarding/disableEmailDeliveryController";
import { ControllerHost } from "../../../../utils/controller";
import { DrawerBlock } from "../../../containers/drawerBlock";

interface Props {
  controller: DisableEmailDeliveryController;
  modal: ModalHook;
}

export const DisableEmailDeliveryTool: React.FunctionComponent<Props> = (props) => {
  return (
    <ModalController
      modal={props.modal}
      title={"Disable Email Delivery"}
      background={"alert"}
      render={(close) => (
        <ControllerHost
          controller={props.controller}
          render={(viewProps) => (
            <>
              <DrawerBlock>
                Click the button below to disable email delivery for this offboarding project.
              </DrawerBlock>
              <OperationStatusIndicator
                progressMessage={"Disabling email delivery..."}
                failureMessage={"Failed to disable email delivery"}
                status={viewProps.status}
                indicators={StatusIndicators.SimplePanel()}
              />
              <DrawerBlock>
                <Button
                  color={"red"}
                  size={"narrow"}
                  onClick={() => viewProps.onDisable().then(close)}
                  disabled={viewProps.status.isWorking()}
                >
                  Disable
                </Button>
              </DrawerBlock>
            </>
          )}
        />
      )}
    />
  );
};
