import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Button } from "../../../widgets/button";
import { ModalController, ModalHook } from "../../../layouts/modal";
import {
  RestartCurrentEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/restartCurrentEmailDeliveryRoundController";
import { ControllerHost } from "../../../../utils/controller";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { DrawerBlock } from "views/containers/drawerBlock";

interface Props {
  offboardingProject: OffboardingProject;
  controller: RestartCurrentEmailDeliveryRoundController;
  modal: ModalHook;
}

export const RestartCurrentEmailDeliveryRoundTool: React.FunctionComponent<Props> = (props) => (
  <ModalController
    modal={props.modal}
    title={"Repeat The Last Round"}
    render={(close) =>
      <ControllerHost
        controller={props.controller}
        render={(viewProps) => {
          if (
            !props.offboardingProject.isEmailSendingInProgress &&
            props.offboardingProject.currentRound !== undefined
          ) {
            const expectedCurrentRound = props.offboardingProject.currentRound;

            return (
              <>
                <DrawerBlock>
                  Click the button below to repeat the last completed round of emailing
                  (round {expectedCurrentRound + 1}). Emails will only be sent to the email addresses that have
                  not been processed in this round previously (usually newly added email addresses).
                </DrawerBlock>
                <OperationStatusIndicator
                  progressMessage={"Starting email sending..."}
                  failureMessage={"Failed to start email sending"}
                  status={viewProps.status}
                  indicators={StatusIndicators.SimplePanel()}
                />
                <DrawerBlock>
                  <Button
                    color={"primary"}
                    size={"narrow"}
                    onClick={() => viewProps.onSubmit(expectedCurrentRound).then(close)}
                    disabled={viewProps.status.isWorking()}
                  >
                    Repeat The Last Round
                  </Button>
                </DrawerBlock>
              </>
            );
          } else {
            return (
              <>
                <DrawerBlock>
                  This tool can be used to repeat the last completed round of emailing. Emails will only be
                  sent to the email addresses that have not been processed in this round previously
                  (usually newly added email addresses). This tool cannot be used while emailing has not yet started
                  or is already in progress.
                </DrawerBlock>
                <DrawerBlock>
                  <Button color={"primary"} size={"narrow"} onClick={close}>Close</Button>
                </DrawerBlock>
              </>
            );
          }
        }}
      />
    }
  />
);
