import { LocalStorage } from "../app/localStorage";
import { Session } from "../types/models/session";
import { initialSettingsState, SettingsState } from "./settings/state";
import { initialProtoState, ProtoState } from "./protoState/state";
import { initialToursState, ToursState } from "./tours/state";

export interface AppState {
  readonly protoState: ProtoState;
  readonly session?: Session;
  readonly settings: SettingsState;
  readonly tours: ToursState;
}

export const initialAppState: AppState = {
  protoState: initialProtoState,
  session: undefined,
  settings: initialSettingsState,
  tours: initialToursState
};

export function loadState(): AppState {
  return {
    ...initialAppState,
    protoState: LocalStorage.loadObject("proto") || initialProtoState,
    settings: SettingsState.fromJson(LocalStorage.loadObject("settings")),
  };
}

export function saveState(state: AppState) {
  LocalStorage.saveObject("proto", state.protoState);
  LocalStorage.saveObject("settings", SettingsState.toJson(state.settings));
}
