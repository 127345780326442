import * as React from "react";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { Block } from "views/containers/block";
import { Panel } from "views/containers/panel";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import { PanelRow } from "views/containers/rows/panelRow";
import { commafy, friendlyDate, friendlyDateTime, friendlyTime } from "../../../../utils/formatting";
import { Indicators, JsonIndicator, SimpleIndicator } from "../../../widgets/indicator";
import { ScheduleGrid } from "./scheduleGrid";
import { styled } from "../../../../app/theme";
import { EmailDeliveryScheduleTimeUnit } from "../../../../types/enums/emailDeliveryScheduleTimeUnit";
import { WhenElevated } from "../../../utils/whenElevated";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../../app/routes/useRoutes";
import {
  AdvanceToNextEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/advanceToNextEmailDeliveryRoundController";
import { Button } from "views/widgets/button";
import { useModal } from "../../../layouts/modal";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { AdvanceToNextEmailDeliveryRoundTool } from "./advanceToNextEmailDeliveryRoundTool";
import {
  RestartCurrentEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/restartCurrentEmailDeliveryRoundController";
import { RestartCurrentEmailDeliveryRoundTool } from "./restartCurrentEmailDeliveryRoundTool";
import {
  ForceStartEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/forceStartEmailDeliveryRoundController";
import { EmailDeliveryErrorType } from "../../../../types/enums/emailDeliveryErrorType";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatus } from "../../../../types/operationStatus";
import { OffboardingProjectStats } from "../../../../types/models/offboardingProjectStats";
import { List } from "immutable";
import {
  OffboardingProjectTimelineChart
} from "./offboardingProjectTimelineChart";
import {
  RenderOffboardingEmailPreviewController
} from "../../../../controllers/organizationPortal/automatedOffboarding/renderOffboardingEmailPreviewController";
import {
  SendTestOffboardingProjectEmailController
} from "../../../../controllers/organizationPortal/automatedOffboarding/sendTestOffboardingProjectEmailController";
import { EmailDeliveryConnectionDescription } from "./emailDeliveryConnectionDescription";
import {
  DisableEmailDeliveryController
} from "../../../../controllers/organizationPortal/automatedOffboarding/disableEmailDeliveryController";
import { DisableEmailDeliveryTool } from "./disableEmailDeliveryTool";
import { Paragraph } from "../../../widgets/paragraph";
import { OffboardingProjectEntryCount } from "./offboardingProjectEntryCount";
import { ProgramViewPathFactory } from "../../../blocks/lookups/programLookupField";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";

interface Props {
  offboardingProject: OffboardingProject;

  editPath: string;
  importEntriesPath: string;
  importHistoryPath: string;
  deleteEntriesPath: string;

  programViewPathFactory: ProgramViewPathFactory;

  entryCountStatus: OperationStatus<number>;
  onEntryCountRefresh: () => void;

  statsStatus: OperationStatus<List<OffboardingProjectStats>>;

  disableUpdates: boolean;
  readOnly: boolean;

  disableEmailDeliveryController: DisableEmailDeliveryController;
  renderOffboardingEmailPreviewController: RenderOffboardingEmailPreviewController;
  sendTestOffboardingProjectEmailController: SendTestOffboardingProjectEmailController;
  forceStartEmailDeliveryRoundController: ForceStartEmailDeliveryRoundController;
  restartCurrentEmailDeliveryRoundController: RestartCurrentEmailDeliveryRoundController;
  advanceToNextEmailDeliveryRoundController: AdvanceToNextEmailDeliveryRoundController;

  onExportItems: () => void;
}

export const OffboardingProjectOverview: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  const disableEmailDeliveryModal = useModal();
  const restartCurrentEmailDeliveryRoundModal = useModal();
  const advanceToNextEmailDeliveryRoundModal = useModal();

  return (
    <>
      <DisableEmailDeliveryTool
        modal={disableEmailDeliveryModal}
        controller={props.disableEmailDeliveryController}
      />
      <RestartCurrentEmailDeliveryRoundTool
        offboardingProject={props.offboardingProject}
        modal={restartCurrentEmailDeliveryRoundModal}
        controller={props.restartCurrentEmailDeliveryRoundController}
      />
      <AdvanceToNextEmailDeliveryRoundTool
        offboardingProject={props.offboardingProject}
        modal={advanceToNextEmailDeliveryRoundModal}
        controller={props.advanceToNextEmailDeliveryRoundController}
      />
      <Block>
        <Panel>
          <TitlePanelRow>Activity Trends</TitlePanelRow>
          <OperationStatusIndicator
            progressMessage={"Receiving activity data"}
            failureMessage={"Failed to receive activity data"}
            status={props.statsStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          {props.statsStatus.mapLastResult((stats) => (
            <PanelRow>
              <OffboardingProjectTimelineChart data={stats}/>
            </PanelRow>
          ))}
        </Panel>
      </Block>
      <Block>
        <Panel>
          <TitlePanelRow>Email Delivery Settings</TitlePanelRow>
          <>
            {
              props.offboardingProject.connection
                ? (
                  <>
                    <PanelRow>
                      <EmailDeliveryConnectionDescription connection={props.offboardingProject.connection}/>
                    </PanelRow>
                    <PanelRow>
                      <Button
                        size={"small"}
                        color={"secondary"}
                        onClick={disableEmailDeliveryModal.open}
                      >
                        Disable
                      </Button>
                    </PanelRow>
                    {props.offboardingProject.emailDeliveryError && (
                      <EmailDeliveryError
                        error={props.offboardingProject.emailDeliveryError}
                        editPath={props.editPath}
                      />
                    )}
                  </>
                )
                : (
                  <PanelRow status={WidgetStatus.Warning}>
                    <Paragraph>
                      Email delivery is currently disabled. Click the button below to enable email delivery
                      in the project settings.
                    </Paragraph>
                    <Paragraph>
                      <Link to={props.editPath}><Button size={"small"}>Enable</Button></Link>
                    </Paragraph>
                  </PanelRow>
                )
            }
          </>
        </Panel>
      </Block>
      <Block>
        <Panel>
          <TitlePanelRow>Email List</TitlePanelRow>
          <OperationStatusIndicator
            progressMessage={"Calculating entry count"}
            failureMessage={"Failed to calculate entry count"}
            status={props.entryCountStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          <PanelRow>
            Entry count:{" "}
            <OffboardingProjectEntryCount
              status={props.entryCountStatus}
              offboardingProject={props.offboardingProject}
              onRefresh={props.onEntryCountRefresh}
            />
          </PanelRow>
          {props.disableUpdates && (
            <>
              <IndefiniteProgressBar/>
              <PanelRow status={WidgetStatus.Info}>
                An update is currently being performed to this offboarding project. This update must be completed
                before another update can be performed.
              </PanelRow>
            </>
          )}
          <PanelRow>
            <SimpleToolbar
              items={
                [
                  <Button
                    key={"download"}
                    size={"small"}
                    color={"secondary"}
                    disabled={props.disableUpdates}
                    onClick={() => props.onExportItems()}
                  >
                    Download Status Report (CSV)
                  </Button>
                ].concat(
                  props.readOnly
                    ? []
                    : [
                      <Link key={"import"} to={props.importEntriesPath}>
                        <Button
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disableUpdates}
                        >
                          Import Entries From CSV
                        </Button>
                      </Link>
                    ]
                ).concat([
                  <Link key={"import"} to={props.importHistoryPath}>
                    <Button
                      size={"small"}
                      color={"secondary"}
                    >
                      View Import History
                    </Button>
                  </Link>
                ]).concat(
                  props.readOnly
                    ? []
                    : [
                      <Link key={"delete"} to={props.deleteEntriesPath}>
                        <Button
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disableUpdates}
                        >
                          Remove Entries
                        </Button>
                      </Link>,
                    ]
                )
              }
            />
          </PanelRow>
        </Panel>
      </Block>
      <Block>
        <Panel>
          <PanelRow>
            End-user program:{" "}
            {
              props.offboardingProject.program
                ? (
                  <>
                    <ProgramName>{props.offboardingProject.program.name}</ProgramName>&nbsp;&nbsp;
                    <Link to={props.programViewPathFactory(props.offboardingProject.program.id)}>View</Link>
                  </>
                )
                : <ProgramName>(not assigned)</ProgramName>
            }
          </PanelRow>
        </Panel>
      </Block>
      <Block>
        <Panel>
          <TitlePanelRow>Email Sending Schedule</TitlePanelRow>
          <PanelRow>
            <WideSection>
              <Indicators size={"small"}>
                <SimpleIndicator title={"Schedule"} value={props.offboardingProject.schedule.name}/>
                {
                  props.offboardingProject.schedule.timeUnit === EmailDeliveryScheduleTimeUnit.Day
                    ? (
                      <>
                        <SimpleIndicator
                          title={"Cutoff date"}
                          value={friendlyDate(props.offboardingProject.cutoffAt)}
                        />
                        <SimpleIndicator
                          title={"Preferred Emailing Time"}
                          value={friendlyTime(props.offboardingProject.cutoffAt)}
                        />
                      </>
                    )
                    : (
                      <SimpleIndicator
                        title={"Cutoff timestamp"}
                        value={friendlyDateTime(props.offboardingProject.cutoffAt)}
                      />
                    )
                }
                <SimpleIndicator
                  title={"Status"}
                  value={props.offboardingProject.isCompleted ? "Completed" : "In progress"}
                />
              </Indicators>
            </WideSection>
            {
              props.offboardingProject.schedule.description &&
                <Section>{props.offboardingProject.schedule.description}</Section>
            }
            <WideSection>
              <ScheduleGrid
                offboardingProject={props.offboardingProject}

                renderOffboardingEmailPreviewController={props.renderOffboardingEmailPreviewController}
                sendTestOffboardingProjectEmailController={props.sendTestOffboardingProjectEmailController}
                forceStartEmailDeliveryRoundController={props.forceStartEmailDeliveryRoundController}
              />
            </WideSection>
            <Section>
              <SimpleToolbar>
                <Button
                  size={"small"}
                  color={"secondary"}
                  onClick={restartCurrentEmailDeliveryRoundModal.open}
                  disabled={!props.offboardingProject.connection}
                >
                  Repeat The Last Round
                </Button>
                <Button
                  size={"small"}
                  color={"secondary"}
                  onClick={advanceToNextEmailDeliveryRoundModal.open}
                  disabled={!props.offboardingProject.connection}
                >
                  Start The Next Round
                </Button>
              </SimpleToolbar>
            </Section>
          </PanelRow>
        </Panel>
      </Block>
      <WhenElevated>
        <Block>
          <Panel>
            <PanelRow size={"smaller"}>
              <Indicators size={"small"}>
                <SimpleIndicator
                  title={"Job ID"}
                  value={props.offboardingProject.jobId}
                  parenthetical={<Link to={routes.jobs.jobPath(props.offboardingProject.jobId)}>View</Link>}
                />
                <SimpleIndicator title={"Current Round"} value={props.offboardingProject.currentRound}/>
                <SimpleIndicator title={"Next Round"} value={props.offboardingProject.nextRound}/>
                <SimpleIndicator title={"Total Emails"} value={props.offboardingProject.currentRoundTotalEmails}/>
                <SimpleIndicator title={"Sent Emails"} value={props.offboardingProject.currentRoundSentEmails}/>
                <SimpleIndicator
                  title={"Email Delivery Error Type"}
                  value={props.offboardingProject.emailDeliveryError?.type}
                />
              </Indicators>
            </PanelRow>
            <PanelRow size={"smaller"}>
              <Indicators size={"small"}>
                <JsonIndicator
                  title={"Email Delivery Error Details"}
                  value={props.offboardingProject.emailDeliveryError?.details}
                />
              </Indicators>
            </PanelRow>
          </Panel>
        </Block>
      </WhenElevated>
    </>
  );
};

const WideSection = styled.div`
  & ~ & {
    margin-top: 1.5rem;
  }
`;

const Section = styled(WideSection)`
  max-width: 40rem;
`;

interface EmailDeliveryErrorProps {
  error: OffboardingProject.EmailDeliveryError;
  editPath: string;
}

const EmailDeliveryError: React.FunctionComponent<EmailDeliveryErrorProps> = (props) => {
  if (props.error.type === EmailDeliveryErrorType.Cancelled) {
    return null;
  } else {
    return (
      <PanelRow
        status={
          props.error.type === EmailDeliveryErrorType.InvalidAccessKey
            ? WidgetStatus.Error
            : WidgetStatus.Warning
        }
      >
        <Section>
          {(() => {
            switch (props.error.type) {
              case EmailDeliveryErrorType.Suspended:
                return "Email delivery is currently on pause.";

              case EmailDeliveryErrorType.InvalidAccessKey:
                return (
                  <>
                    <Paragraph>
                      VaultMe has lost access to the account used to send emails. Click the button below to re-connect
                      the account in the project settings. Once this is done, VaultMe will continue to send the
                      remaining emails and this message will disappear (it may take a few minutes for the updates
                      to take effect).
                    </Paragraph>
                    <Paragraph>
                      <Link to={props.editPath}><Button size={"small"}>Re-connect</Button></Link>
                    </Paragraph>
                  </>
                );

              case EmailDeliveryErrorType.ExhaustedQuota:
                return (
                  <>
                    The limit on the number of emails that can be sent in one batch has been exceeded. VaultMe
                    will continue to attempt to send the remaining emails. Once emails can be sent again, this
                    message will automatically disappear.
                  </>
                );

              case EmailDeliveryErrorType.UnexpectedError:
                return (
                  <>
                    An unexpected error occurred while sending emails. VaultMe
                    will continue to attempt to send the remaining emails. Once emails can be sent again, this
                    message will automatically disappear.
                  </>
                );
            }
          })()}
        </Section>
      </PanelRow>
    );
  }
};

const ProgramName = styled.span`
  font-weight: 400;
`;
