import * as React from "react";
import { AuthProviders } from "../../../types/models/authProviders";
import { Set } from "immutable";
import { RoleSelectorRow } from "./roleSelectorRow";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { SignInLayout } from "./signInLayout";
import { SignInFlowState } from "../../../components/auth/useSignInFlow";
import { CloudServices } from "../../../types/models/cloudServices";
import { AuthContext } from "../authContext";
import { CloudServiceChooserRow } from "../cloudServiceChooser/cloudServiceChooserRow";
import { SignInDefs } from "./signInDefs";
import { WhenElevated } from "../../utils/whenElevated";
import { ErrorInfoRow } from "../../containers/rows/errorInfoRow";
import { UserFacingError } from "../../../types/userFacingError";

export interface SignInFlowViewProps {
  cloudServices: CloudServices;
  authProviders: AuthProviders;

  authContext: AuthContext;
  state: SignInFlowState;

  grantedRoles?: Set<string>;
  defaultRoles: Set<string>;

  badConnectionError?: UserFacingError;

  onCloudServiceSelect?: (cloudServiceId: string) => void;
  onRolesSelect: (roles: Set<string>) => void;
  onSignIn: SignInDefs.SignInHandle;
}

export const SignInFlowView: React.FunctionComponent<SignInFlowViewProps> = ({ state, ...props }) => (
  <>
    <CloudServiceChooserRow
      authContext={props.authContext}
      cloudServices={props.cloudServices}
      selectedCloudServiceId={
        state.type !== SignInFlowState.Type.SelectingCloudService ? state.cloudService.id : undefined
      }
      buttonTitle={"Choose"}
      onCloudServiceSelect={props.onCloudServiceSelect}
    />
    {props.badConnectionError && <ErrorInfoRow error={props.badConnectionError}/>}

    {state.type !== SignInFlowState.Type.SelectingCloudService && (
      <>
        <WhenElevated>
          <RoleSelectorRow
            authProviderId={state.authProvider.id}
            roles={{
              allRoles: state.authProvider.roles,
              selectedRoles: state.roles,
              grantedRoles: props.grantedRoles,
              defaultRoles: props.defaultRoles
            }}
            onSelect={props.onRolesSelect}
          />
        </WhenElevated>

        {(() => {
          switch (state.type) {
            case SignInFlowState.Type.InitializingSignInForm:
              return (
                <OperationStatusIndicator
                  subject={"sign-in form configuration"}
                  status={state.status}
                  indicators={StatusIndicators.PanelRow()}
                />
              );

            case SignInFlowState.Type.PendingSignIn: {
              return (
                <>
                  {
                    state.signInComponents.map((signInComponent, index) =>
                      React.createElement(signInComponent, {
                        key: index,
                        layoutComponent: SignInLayout,
                        verb: "Sign In",
                        cloudServiceId: state.cloudService.id,
                        authProviderId: state.authProvider.id,
                        roles: state.roles,
                        onSignIn: props.onSignIn
                      })
                    )
                  }
                </>
              );
            }

            case SignInFlowState.Type.CompletingSignIn:
              return (
                <OperationStatusIndicator
                  progressMessage={"Completing authentication..."}
                  successMessage={"Done!"}
                  status={state.status}
                  indicators={StatusIndicators.PanelRow()}
                />
              );
          }
        })()}
      </>
    )}
  </>
);
