import * as React from "react";
import { HasClickHandler, withSimpleClickHandler } from "../utils/simpleClickHandler";
import { Button } from "./button";

export interface BackButtonProps extends HasClickHandler {
}

export const BackButton = (props: BackButtonProps) => (
  <Button color="white" onClick={props.onClick} size="narrow">&lsaquo;</Button>
);

export const SimpleBackButton = withSimpleClickHandler(BackButton);
