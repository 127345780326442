import { GraphQL } from "../../services/graphql/generated";
import { AccessKeyStatus } from "./accessKeyStatus";
import { Set } from "immutable";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { PersonalInfo } from "./personalInfo";
import { ObjectRepo } from "../../utils/objectRepo";

export class Connection implements Connection.Props {
  constructor(protected readonly props: Connection.Props) {
  }

  public get id(): string { return this.props.id; }
  public get userId(): string { return this.props.userId; }

  public get cloudServiceId(): string { return this.props.cloudServiceId; }
  public get accessKeyProvider(): string { return this.props.accessKeyProvider; }
  public get accessKeyStatus(): AccessKeyStatus { return this.props.accessKeyStatus; }
  public get roles(): Set<string> | undefined  { return this.props.roles; }
  public get class(): ConnectionClass  { return this.props.class; }

  public get description(): string | undefined { return this.props.description; }
  public get personalInfo(): PersonalInfo  { return this.props.personalInfo; }

  public get metadata(): string | undefined { return this.props.metadata; }

  public get enabled(): boolean { return this.props.enabled; }
  public get eligibleForSignIn(): boolean { return this.props.eligibleForSignIn; }

  public get createdAt(): Date { return this.props.createdAt; }
  public get updatedAt(): Date { return this.props.updatedAt; }
  public get validatedAt(): Date { return this.props.validatedAt; }
  public get signedInAt(): Date { return this.props.signedInAt; }
  public get revealedAt(): Date | undefined { return this.props.revealedAt; }
  public get revokedAt(): Date  | undefined  { return this.props.revokedAt; }

  public descriptionOrId(): string { return this.props.description || this.props.id; }
}

export enum ConnectionClass {
  Educational = "Educational",
  Business = "Business",
  Consumer = "Consumer"
}

export namespace Connection {
  export interface Props extends ObjectRepo.HasId {
    userId: string;

    cloudServiceId: string;
    accessKeyProvider: string;
    accessKeyStatus: AccessKeyStatus;
    roles?: Set<string>;
    class: ConnectionClass;

    description: string | undefined;
    personalInfo: PersonalInfo;

    metadata?: string | undefined;

    enabled: boolean;
    eligibleForSignIn: boolean;

    createdAt: Date;
    updatedAt: Date;
    validatedAt: Date;
    signedInAt: Date;
    revealedAt?: Date;
    revokedAt?: Date;
  }

  export function fromGraphQL(connection: GraphQL.Connection): Connection {
    return new Connection({
      id: connection.id,
      userId: connection.userId,

      cloudServiceId: connection.cloudServiceId,
      accessKeyProvider: connection.accessKeyProvider,
      accessKeyStatus: connection.accessKeyStatus,
      roles: mapOptional(connection.roles, (value) => Set(value)),
      class: connection.class,

      description: nullToUndefined(connection.description),
      personalInfo: PersonalInfo.parse(connection.personalInfo),

      metadata: nullToUndefined(connection.metadata),

      enabled: connection.enabled,
      eligibleForSignIn: connection.eligibleForSignIn,

      createdAt: new Date(connection.createdAt),
      updatedAt: new Date(connection.updatedAt),
      validatedAt: new Date(connection.validatedAt),
      signedInAt: new Date(connection.signedInAt),
      revealedAt: mapOptional(connection.revealedAt, (value) => new Date(value)),
      revokedAt: mapOptional(connection.revokedAt, (value) => new Date(value)),
    });
  }
}
