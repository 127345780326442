import * as React from "react";
import { styled } from "../../app/theme";

interface KebabButtonProps {
  onClick?: () => void;
}

const StyledButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.gray};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  padding-bottom: 0.1rem;
  
  &:hover {
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.faintPrimary};
  }
`;

export const KebabButton: React.FunctionComponent<KebabButtonProps> = (props) => (
  <StyledButton onClick={props.onClick}>&#x22EE;</StyledButton>
);
