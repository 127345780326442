import * as React from "react";
import { List, Set } from "immutable";
import { Connection } from "../../../types/models/connection";
import { RevealedAccessKey } from "../../../types/models/revealedAccessKey";
import { multiline } from "../../../utils/formatting";
import { JsonPropertyRow, PropertiesGrid, PropertyRow } from "../../widgets/propertiesGrid";
import { AccessKeyStatus } from "../../../types/models/accessKeyStatus";

interface RevealedAccessKeyPropertiesProps {
  connection: Connection;
  revealedAccessKey: RevealedAccessKey;
}

function multilineOrPlaceholder(strings: List<string>) {
  return strings.isEmpty() ? undefined : multiline(strings);
}

function permissionsList(permissions?: Set<string>) {
  return permissions && multilineOrPlaceholder(permissions.toList().sort());
}

function accessModesList(roles?: Set<string>) {
  return roles && multilineOrPlaceholder(roles.toList().sort());
}

export const RevealedAccessKeyProperties: React.FunctionComponent<RevealedAccessKeyPropertiesProps> = (props) => (
  <PropertiesGrid>
    <PropertyRow
      title="Access Key Status"
      status={props.revealedAccessKey.accessKeyStatus === AccessKeyStatus.Valid ? "normal" : "error"}
    >
      {props.revealedAccessKey.accessKeyStatus}
    </PropertyRow>
    <PropertyRow title="Access Key Version">
      {props.revealedAccessKey.accessKeyVersion}
    </PropertyRow>
    <JsonPropertyRow title="Access Key">
      {props.revealedAccessKey.accessKey}
    </JsonPropertyRow>
    <PropertyRow title="Access Key Params Version">
      {props.revealedAccessKey.accessKeyParamsVersion}
    </PropertyRow>
    <JsonPropertyRow title="Access Key Params">
      {props.revealedAccessKey.accessKeyParams}
    </JsonPropertyRow>
    <PropertyRow title="Granted Roles">
      {accessModesList(props.revealedAccessKey.currentRoles)}
    </PropertyRow>
    <PropertyRow title="Granted Permissions">
      {permissionsList(props.revealedAccessKey.currentPermissions)}
    </PropertyRow>
    <PropertyRow title="Confirmed Permissions">
      {permissionsList(props.revealedAccessKey.validatedPermissions)}
    </PropertyRow>
    <PropertyRow title="Missing Permissions">
      {permissionsList(props.revealedAccessKey.missingPermissions)}
    </PropertyRow>
  </PropertiesGrid>
);
