import * as React from "react";
import { PreparedAnchor } from "./anchor";
import { WorkflowMutationResult } from "./workflowMutationResult";
import { WorkflowContextLike } from "./workflowContextLike";
import { List, Set } from "immutable";
import { AnyRenderHooks } from "./workItem";
import { Path } from "./path";
import { Observable } from "rxjs";
import { AsyncResult, SyncResult } from "./asyncResult";
import { WorkflowState } from "./workflowState";

export namespace Workflow {
  export type AsyncPathResult<
    WorkflowContext extends WorkflowContextLike, 
    WorkflowFeedback, 
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, Path>;

  export type AsyncOptionalPathResult<
    WorkflowContext extends WorkflowContextLike, 
    WorkflowFeedback, 
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, Path | undefined>;
  
  export type SyncMutationResult<
    WorkflowContext extends WorkflowContextLike, 
    WorkflowFeedback, 
    WorkflowResult> =
    SyncResult<
      WorkflowContext, 
      WorkflowFeedback, 
      WorkflowResult, 
      WorkflowMutationResult.OneOf<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >;

  export type AsyncMutationResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<
      WorkflowContext,
      WorkflowFeedback,
      WorkflowResult,
      WorkflowMutationResult.OneOf<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >;

  export type AsyncOptionalReactElementResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, React.ReactElement | undefined>;

  export type AsyncAnchorsResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, List<PreparedAnchor>>;

  export type AsyncBooleanResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, boolean>;

  export type AsyncVoidResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, void>;

  export type AsyncPartialResultResult<
    WorkflowContext extends WorkflowContextLike,
    WorkflowFeedback,
    WorkflowResult> =
    AsyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, Partial<WorkflowResult>>;
}

export interface Workflow<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  > {
  validatePath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  getFirstWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  getLastWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  getPreviousWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncOptionalPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  applyResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    result: any,
    completeAndProceed: boolean
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  clearResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  processFeedback(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    feedback: WorkflowFeedback
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  cleanUp(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  renderCurrentWorkItem(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    hooks: AnyRenderHooks<WorkflowFeedback>
  ): Workflow.AsyncOptionalReactElementResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  getAnchors(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncAnchorsResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  fireSideEffects(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncVoidResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  isComplete(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncBooleanResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  getPartialResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncPartialResultResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  watch(): Observable<Set<string>>;
}

export abstract class AbstractWorkflow<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  >
  implements Workflow<WorkflowContext, WorkflowFeedback, WorkflowResult> {

  public abstract validatePath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract getFirstWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract getLastWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract getPreviousWorkItemPath(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): Workflow.AsyncOptionalPathResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract applyResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    result: any,
    completeAndProceed: boolean
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract clearResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract processFeedback(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    feedback: WorkflowFeedback
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract cleanUp(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncMutationResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract renderCurrentWorkItem(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    hooks: AnyRenderHooks<WorkflowFeedback>
  ): Workflow.AsyncOptionalReactElementResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract getAnchors(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncAnchorsResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract fireSideEffects(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncVoidResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract isComplete(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncBooleanResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract getPartialResult(
    state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  ): Workflow.AsyncPartialResultResult<WorkflowContext, WorkflowFeedback, WorkflowResult>;

  public abstract watch(): Observable<Set<string>>;
}
