import * as React from "react";

export function useFlash(timeout: number): [boolean, () => void] {
  const [state, setState] = React.useState(false);

  React.useEffect(
    () => {
      if (state) {
        const timer = setTimeout(() => setState(false), timeout);
        return () => clearTimeout(timer);
      }
    },
    [state]
  );

  return [
    state,
    () => setState(true)
  ];
}
