import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { SchoolStats } from "../../types/models/schoolStats";

export function useGetSchoolStatsQuery(
  id: string
): ManagedQueryHook<GraphQL.GetSchoolStatsQueryVariables, SchoolStats> {
  return useManagedQuery({
    query: GraphQL.useGetSchoolStatsQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetSchoolStatsQuery) => nullToUndefined(data.schoolStats),
    complete: SchoolStats.parse
  });
}
