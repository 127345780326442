import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import {
  OffboardingProjectEntryParticipationStatus
} from "../../types/enums/offboardingProjectEntryParticipationStatus";

export function useGetOffboardingProjectEntryParticipationStatusQuery(
  id: string
): ManagedQueryHook<
  GraphQL.GetOffboardingProjectEntryParticipationStatusQueryVariables,
  OffboardingProjectEntryParticipationStatus
  > {
  return useManagedQuery({
    query: GraphQL.useGetOffboardingProjectEntryParticipationStatusQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetOffboardingProjectEntryParticipationStatusQuery) =>
      nullToUndefined(data.offboardingProjectEntryParticipationStatus),
    complete: identity
  });
}
