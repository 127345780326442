import * as React from "react";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const StyledButton = styled.button`
  background: #ffffff;
  color: ${(props) => props.theme.colors.gray};
  border: 1px dashed ${(props) => props.theme.colors.gray};
  border-radius: 0.15rem;
  padding: 0.25rem 0.4rem;
  font-size: 0.8125rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0.2rem 0.3rem;
  }

  @media(hover: hover) {
    &:hover:not([disabled]) {
      transition: 0.1s;
      background: #f0f0f0;
      border-style: solid;
      cursor: pointer;
    }
  }
`;

interface ExpandCollapseButtonProps extends StyledComponentsProps {
  direction?: "left" | "right" | "both";
  expanded: boolean;
  onClick: () => void;
}

export const ExpandCollapseButton: React.FunctionComponent<ExpandCollapseButtonProps> = (props) => {
  function renderCharacters() {
    switch (props.direction) {
      case "left": return props.expanded ? <>&#x21D2;</> : <>&#x21D0;</>;
      case "right": return props.expanded ? <>&#x21D0;</> : <>&#x21D2;</>;
      default: return props.expanded ? <>&#x21D2;&#x21D0;</> : <>&#x21D0;&#x21D2;</>;
    }
  }

  return (
    <StyledButton onClick={props.onClick} className={props.className}>
      {renderCharacters()}
    </StyledButton>
  );
};

interface CloseButtonProps extends StyledComponentsProps {
  onClick: () => void;
}

export const CloseButton: React.FunctionComponent<CloseButtonProps> = (props) => (
  <StyledButton onClick={props.onClick} className={props.className}>
    &#x2715;
  </StyledButton>
);
