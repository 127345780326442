import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { MigrationStatus } from "./migrationStatus";
import { List, Map } from "immutable";
import { BatchBinding } from "./batchBinding";
import { MouseFlow } from "../../services/mouseFlow";
import { Connection } from "./connection";
import { Facts } from "../facts/facts";
import { MigrationIssue } from "./migrationIssue";
import { MigrationNote } from "./migrationNote";
import { MigrationNoteList } from "./migrationNoteList";
import { Order } from "./order";
import { Option } from "../../utils/monads/option";
import { MigrationHistoryItem } from "./migrationHistoryItem";
import { OrderSummary } from "./orderSummary";
import { MigrationStats } from "./migrationStats";

export interface ManualReviewItem {
  phase: string;
  itemType: string;
  count: number;
}

export namespace ManualReviewItem {
  export function fromGraphQL(item: GraphQL.ManualReviewItem): ManualReviewItem {
    return item;
  }
}

export interface Migration {
  readonly id: string;
  readonly batch: BatchBinding | undefined;
  readonly userId: string;

  readonly sourceCloudServiceId: string;
  readonly destinationCloudServiceId: string;
  readonly sourceConnectionId: string;
  readonly destinationConnectionId: string;

  readonly blueprintInputs: Map<string, string>;

  readonly totalBytesEstimate: number;
  readonly totalItemsEstimate: number;
  readonly timeEstimate: number | undefined;

  readonly iteration: number;
  readonly status: MigrationStatus;
  readonly autoResumeAt: Date | undefined;
  readonly isAutoResumingEnabled: boolean;

  readonly cumulativeStats: MigrationStats;
  readonly iterationStats: MigrationStats;

  readonly optimisticRemainingTime: number | undefined;
  readonly longestTrackProgress: number | undefined;

  readonly itemsForManualReview: List<ManualReviewItem> | undefined;
  readonly totalManualReviews: number;
  readonly supervisedBy: string | undefined;

  readonly reportUrl: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly startedAt: Date | undefined;
  readonly completedAt: Date | undefined;
  readonly iterationStartedAt: Date | undefined;
  readonly iterationCompletedAt: Date | undefined;

  readonly jobId: string;
  readonly workflowUrl: string;

  // This is a weird property - should use timing instead
  readonly totalTimeInWork: number;

  readonly mouseFlowRecordingsUrl: string;
  readonly startedOrScheduledAt: Date;
  readonly timing: Migration.Timing;
  readonly totalItemsForManualReview: number;
  readonly progress: number;
  readonly skippedPercentage: number;
}

export namespace Migration {
  export function parseCore(migration: GraphQL.MigrationFragment): Migration {
    const id = migration.id;

    const createdAt = new Date(migration.createdAt);
    const startedAt = mapOptional(migration.startedAt, (value) => new Date(value));
    const completedAt = mapOptional(migration.completedAt, (value) => new Date(value));

    const timeEstimate = nullToUndefined(migration.timeEstimate);

    // In this context, completion affects calculation of progress. We don't care of the migration was completed in the
    // past, we only care about the current status.
    const isCompleted =
      migration.status === GraphQL.MigrationStatus.Completed ||
      migration.status === GraphQL.MigrationStatus.Aborted;
    const cumulativeStats = MigrationStats.parse(migration.cumulativeStats, isCompleted);
    const iterationStats = MigrationStats.parse(migration.iterationStats, isCompleted);

    const optimisticRemainingTime = nullToUndefined(migration.optimisticRemainingTime);
    const longestTrackProgress = nullToUndefined(migration.longestTrackProgress);

    const itemsForManualReview = mapOptional(
      migration.itemsForManualReview,
      (items) => List(items.map(ManualReviewItem.fromGraphQL))
    );

    function timing(): Migration.Timing {
      if (startedAt !== undefined) {
        if (completedAt !== undefined) {
          return new Migration.CompletedTiming({
            startedAt,
            completedAt,
            originalTimeEstimate: timeEstimate
          });
        } else {
          return new Migration.RunningTiming({
            startedAt,
            originalTimeEstimateOption: timeEstimate,
            optimisticRemainingTimeOption: optimisticRemainingTime,
            longestTrackProgressOption: longestTrackProgress
          });
        }
      } else {
        return new Migration.ScheduledTiming(createdAt);
      }
    }

    function totalItemsForManualReview(): number {
      return itemsForManualReview !== undefined
        ? itemsForManualReview.map((i) => i.count)
          .reduce((a, b) => a + b, 0)
        : 0;
    }

    return {
      id,
      batch: nullToUndefined(migration.batch),
      userId: migration.userId,

      sourceCloudServiceId: migration.sourceCloudServiceId,
      destinationCloudServiceId: migration.destinationCloudServiceId,
      sourceConnectionId: migration.sourceConnectionId,
      destinationConnectionId: migration.destinationConnectionId,

      blueprintInputs: Map(migration.blueprintInputs.map((input) => [input.key, input.value])),

      totalBytesEstimate: migration.totalBytesEstimate,
      totalItemsEstimate: migration.totalItemsEstimate,
      timeEstimate,

      iteration: migration.iteration,
      status: migration.status,
      autoResumeAt: mapOptional(migration.autoResumeAt, (value) => new Date(value)),
      isAutoResumingEnabled: migration.isAutoResumingEnabled,

      cumulativeStats,
      iterationStats,

      optimisticRemainingTime,
      longestTrackProgress,

      itemsForManualReview,
      totalManualReviews: migration.totalManualReviews,
      supervisedBy: nullToUndefined(migration.supervisedBy),

      reportUrl: nullToUndefined(migration.reportUrl),

      createdAt,
      updatedAt: new Date(migration.updatedAt),
      startedAt,
      completedAt,
      iterationStartedAt: mapOptional(migration.iterationStartedAt, (value) => new Date(value)),
      iterationCompletedAt: mapOptional(migration.iterationCompletedAt, (value) => new Date(value)),

      jobId: migration.jobId,
      workflowUrl: migration.workflowUrl,

      totalTimeInWork: ((completedAt || new Date()).getTime() - (startedAt || createdAt).getTime()) / 1000,

      mouseFlowRecordingsUrl: MouseFlow.recordingsForMigrationUrl(id),
      startedOrScheduledAt: startedAt || createdAt,
      timing: timing(),
      totalItemsForManualReview: totalItemsForManualReview(),
      progress: iterationStats.progress,
      skippedPercentage: iterationStats.skippedPercentage
    };
  }

  export abstract class Timing {
    public abstract get delayFactor(): number;

    public abstract get isDelayFactorReliable(): boolean;

    public get delayLevel(): Timing.DelayLevel {
      if (this.isDelayFactorReliable) {
        if (this.delayFactor >= Timing.MajorDelayFactorThreshold) {
          return Timing.DelayLevel.Major;
        } else if (this.delayFactor >= Timing.MinorDelayFactorThreshold) {
          return Timing.DelayLevel.Minor;
        } else {
          return Timing.DelayLevel.OnTime;
        }
      } else {
        return Timing.DelayLevel.OnTime;
      }
    }

    public get isOnTime(): boolean {
      return this.delayLevel === Timing.DelayLevel.OnTime;
    }

    public get isDelayed(): boolean {
      return this.delayLevel === Timing.DelayLevel.Major;
    }

    public get mayBeDelayed(): boolean {
      return this.delayFactor >= Timing.MinorDelayFactorThreshold;
    }
  }

  export namespace Timing {
    export const WarmUpPeriod = 5 * 60;

    export class ElapsedTimeThresholds {
      constructor(
        public readonly absolute: number,
        public readonly percentageOfOriginalTimeEstimate: number
      ) {
      }

      public exceeded(originalTimeEstimateOption: number | undefined, elapsedTime: number): boolean {
        return (
          elapsedTime >= this.absolute &&
          originalTimeEstimateOption !== undefined &&
          originalTimeEstimateOption !== 0 &&
          elapsedTime / originalTimeEstimateOption * 100 >= this.percentageOfOriginalTimeEstimate
        );
      }
    }

    export const PessimisticTimeEstimateThresholds = new ElapsedTimeThresholds(
      15 * 60,
      15
    );

    export const ReliableDelayFactorThresholds = new ElapsedTimeThresholds(
      30 * 60,
      15
    );

    export const MinorDelayFactorThreshold = 1.5;
    export const MajorDelayFactorThreshold = 3;

    export enum DelayLevel {
      OnTime = "OnTime",
      Minor = "Minor",
      Major = "Major"
    }
  }

  export class ScheduledTiming extends Timing {
    public readonly pendingFor: number;

    constructor(public readonly scheduledAt: Date) {
      super();
      this.pendingFor = (new Date().getTime() - scheduledAt.getTime()) / 1000;
    }

    public get delayFactor(): number {
      return 0;
    }

    public get isDelayFactorReliable(): boolean {
      return true;
    }
  }

  export class RunningTiming extends Timing {
    public readonly startedAt: Date;
    public readonly originalTimeEstimateOption: number | undefined;
    public readonly optimisticRemainingTimeOption: number | undefined;
    public readonly longestTrackProgressOption: number | undefined;

    public readonly elapsedTime: number;
    public readonly elapsedProductiveTime: number;
    public readonly optimisticRemainingTime: number;
    public readonly optimisticTimeEstimate: number;
    public readonly pessimisticTimeEstimate: number;
    public readonly timeEstimate: number;
    public readonly remainingTimeEstimate: number;

    constructor(params: {
      startedAt: Date,
      originalTimeEstimateOption: number | undefined,
      optimisticRemainingTimeOption: number | undefined,
      longestTrackProgressOption: number | undefined
    }) {
      super();

      this.startedAt = params.startedAt;
      this.originalTimeEstimateOption = params.originalTimeEstimateOption;
      this.optimisticRemainingTimeOption = params.optimisticRemainingTimeOption;
      this.longestTrackProgressOption = params.longestTrackProgressOption;

      this.elapsedTime = (new Date().getTime() - this.startedAt.getTime()) / 1000;
      this.elapsedProductiveTime = Math.max(0, this.elapsedTime - Timing.WarmUpPeriod);

      if (this.optimisticRemainingTimeOption !== undefined) {
        this.optimisticRemainingTime = this.optimisticRemainingTimeOption;
      } else if (this.originalTimeEstimateOption !== undefined) {
        this.optimisticRemainingTime = Math.max(0, this.originalTimeEstimateOption - this.elapsedProductiveTime);
      } else {
        this.optimisticRemainingTime = 0;
      }

      this.optimisticTimeEstimate = this.optimisticRemainingTime + this.elapsedTime;

      this.pessimisticTimeEstimate =
        this.longestTrackProgressOption !== undefined && this.longestTrackProgressOption !== 0
          // Need to ensure that the result will not be less than already elapsed time
          ? Math.max(
          this.elapsedTime,
          Math.round(this.elapsedProductiveTime / this.longestTrackProgressOption * 100)
          )
          : this.optimisticTimeEstimate;

      this.timeEstimate = this.optimisticTimeEstimate;
      // this.timeEstimate =
      //   Timing.PessimisticTimeEstimateThresholds.exceeded(this.originalTimeEstimateOption, this.elapsedTime)
      //     ? this.pessimisticTimeEstimate
      //     : this.optimisticTimeEstimate;

      this.remainingTimeEstimate = this.timeEstimate - this.elapsedTime;
    }

    public get delayFactor(): number {
      return this.originalTimeEstimateOption !== undefined && this.originalTimeEstimateOption !== 0
        ? this.timeEstimate / this.originalTimeEstimateOption
        : 0;
    }

    public get isDelayFactorReliable(): boolean {
      return (
        this.originalTimeEstimateOption !== undefined &&
        this.originalTimeEstimateOption !== 0 &&
        Timing.ReliableDelayFactorThresholds.exceeded(this.originalTimeEstimateOption, this.elapsedTime)
      );
    }
  }

  export class CompletedTiming extends Timing {
    public readonly startedAt: Date;
    public readonly completedAt: Date;
    public readonly originalTimeEstimate: number | undefined;

    public readonly completedIn: number;

    constructor(params: {
      startedAt: Date,
      completedAt: Date,
      originalTimeEstimate: number | undefined
    }) {
      super();
      this.startedAt = params.startedAt;
      this.completedAt = params.completedAt;
      this.originalTimeEstimate = params.originalTimeEstimate;
      this.completedIn = (this.completedAt.getTime() - this.startedAt.getTime()) / 1000;
    }

    public get delayFactor(): number {
      return this.originalTimeEstimate !== undefined && this.originalTimeEstimate !== 0
        ? this.completedIn / this.originalTimeEstimate
        : 0;
    }

    public get isDelayFactorReliable(): boolean {
      return (
        this.originalTimeEstimate !== undefined &&
        this.originalTimeEstimate !== 0
      );
    }
  }

  export interface HasConnections {
    sourceConnection: Connection;
    destinationConnection: Connection;
  }

  export namespace HasConnections {
    export function parse(
      data: Pick<GraphQL.Migration, "sourceConnection" | "destinationConnection">
    ): HasConnections {
      return {
        sourceConnection: Connection.fromGraphQL(data.sourceConnection),
        destinationConnection: Connection.fromGraphQL(data.destinationConnection),
      };
    }
  }

  export interface HasOrderSummary {
    orderSummary: Option<OrderSummary>;
  }

  export namespace HasOrderSummary {
    export function parse(data: Pick<GraphQL.MigrationOrderSummaryFragment, "orderSummary">): HasOrderSummary {
      return {
        orderSummary: Option.mayBe(data.orderSummary).map(OrderSummary.parse)
      };
    }
  }

  export interface HasProgressData {
    facts: Facts;
    issues: List<MigrationIssue>;
  }

  export namespace HasProgressData {
    export function parse(data: Pick<GraphQL.Migration, "facts" | "issues">): HasProgressData {
      return {
        facts: Facts.fromGraphQLList(data.facts),
        issues: MigrationIssue.fromGraphQLList(data.issues.issues)
      };
    }
  }

  export enum MonitoringGroup {
    RunningNormally = "RunningNormally",

    RequireManualReview = "RequireManualReview",
    WaitingForUserAction = "WaitingForUserAction",
    Delayed = "Delayed",
    RunningWithIssues = "RunningWithIssues",

    CompletedWithIssues = "CompletedWithIssues",
    CompletedNormally = "CompletedNormally",
  }

  export interface JobIssueSummary {
    issueId: string;
    summary: string;
    createdAt: Date;
  }

  export namespace JobIssueSummary {
    export function parse(data: Pick<GraphQL.TaskIssue, "issueId" | "summary" | "createdAt">): JobIssueSummary {
      return {
        issueId: data.issueId,
        summary: data.summary,
        createdAt: new Date(data.createdAt)
      };
    }
  }

  export interface HasMonitoringDashboardData {
    migrationIssues: List<MigrationIssue>;
    activeJobIssues: List<JobIssueSummary>;
    activeNotes: List<MigrationNote>;
    value: number | undefined;

    hasIssues: boolean;
    monitoringGroup: MonitoringGroup | undefined;
    isVIP: boolean;
  }

  export namespace HasMonitoringDashboardData {
    export function parse(
      migration: Migration,
      data: Pick<GraphQL.Migration, "issues" | "activeNotes" | "value"> & {
        activeJobIssues: Pick<GraphQL.TaskIssue, "issueId" | "summary" | "createdAt">[]
      }
    ): HasMonitoringDashboardData {
      const migrationIssues = MigrationIssue.fromGraphQLList(data.issues.issues);
      const activeJobIssues = List(data.activeJobIssues).map(JobIssueSummary.parse);
      const activeNotes = MigrationNoteList.fromGraphQL(data.activeNotes);
      const value = nullToUndefined(data.value);

      function hasIssues(): boolean {
        const hasImportantNotes =
          !!activeNotes.find((migrationNote) => migrationNote.isImportant);

        if (
          migration.status === MigrationStatus.Completed ||
          migration.status === MigrationStatus.Aborted
        ) {
          return hasImportantNotes;
        } else {
          return (
            migration.timing.isDelayed ||
            migration.itemsForManualReview !== undefined ||
            !activeJobIssues.isEmpty() ||
            !!migrationIssues.find((issue) => issue.isBlocking) ||
            hasImportantNotes
          );
        }
      }

      function monitoringGroup(): MonitoringGroup {
        if (
          migration.status === MigrationStatus.Completed ||
          migration.status === MigrationStatus.Aborted
        ) {
          return hasIssues() || migration.supervisedBy
            ? MonitoringGroup.CompletedWithIssues
            : MonitoringGroup.CompletedNormally;
        } else if (migration.status === MigrationStatus.Paused) {
          return MonitoringGroup.WaitingForUserAction;
        } else if (hasIssues()) {
          if (migration.itemsForManualReview !== undefined) {
            return MonitoringGroup.RequireManualReview;
          } else if (migration.status === MigrationStatus.WaitingForUserAction) {
            return MonitoringGroup.WaitingForUserAction;
          } else if (
            migration.timing.isDelayed &&
            activeJobIssues.isEmpty() &&
            !migrationIssues.find((issue) => issue.isBlocking) &&
            !activeNotes.find((migrationNote) => migrationNote.isImportant)
          ) {
            return MonitoringGroup.Delayed;
          } else {
            return MonitoringGroup.RunningWithIssues;
          }
        } else {
          return MonitoringGroup.RunningNormally;
        }
      }

      function isVIP(): boolean {
        return value !== undefined && value >= 100;
      }

      return {
        migrationIssues,
        activeJobIssues,
        activeNotes,
        value,

        hasIssues: hasIssues(),
        monitoringGroup: monitoringGroup(),
        isVIP: isVIP()
      };
    }
  }

  export interface AdminSidebarData extends Migration, Migration.HasConnections {
    order: Option<Order & Order.HasRevenueData>;
    issues: List<MigrationIssue>;
    activeJobIssues: List<JobIssueSummary>;
    notes: List<MigrationNote>;
  }

  export namespace AdminSidebarData {
    type Props = "sourceConnection" | "destinationConnection" | "issues" | "notes";

    type Data = GraphQL.MigrationFragment &
      Pick<GraphQL.Migration, Props> &
      {
        order: GraphQL.Maybe<GraphQL.OrderFragment & GraphQL.OrderRevenueFragment>,
        activeJobIssues: Pick<GraphQL.TaskIssue, "issueId" | "summary" | "createdAt">[]
      };

    export function parse(data: Data): AdminSidebarData {
      return {
        ...parseCore(data),
        ...HasConnections.parse(data),

        order: Option.mayBe(data.order).map((orderData) => ({
          ...Order.parse(orderData),
          ...Order.HasRevenueData.parseRevenueData(orderData)
        })),
        issues: MigrationIssue.fromGraphQLList(data.issues.issues),
        activeJobIssues: List(data.activeJobIssues).map(JobIssueSummary.parse),
        notes: MigrationNoteList.fromGraphQL(data.notes)
      };
    }
  }

  export interface HasHistory {
    history: List<MigrationHistoryItem>;
  }

  export namespace HasHistory {
    export function parse(data: Pick<GraphQL.Migration, "history">): HasHistory {
      return {
        history: List(data.history).map(MigrationHistoryItem.parse)
      };
    }
  }
}
