import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRow } from "../../containers/rows/panelRow";
import { SignInDefs } from "./signInDefs";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";

const InlineHint = styled.div`
  margin-bottom: 1rem;
`;

const FormContainer = styled.div`
  // Why is this here?! It forces sign-in forms to expand behind the parent's borders in case when they contain 
  // some long text. 
  //width: max-content;
`;

export const SignInLayout: SignInDefs.SignInLayoutComponentType = (props) => (
  <>
    {props.operationStatusIndicator && (
      <OperationStatusIndicator
        {...props.operationStatusIndicator}
        indicators={StatusIndicators.PanelRow()}
      />
    )}
    {props.helpContent?.intro}
    <PanelRow helpArticle={props.helpContent?.inlineHelpArticle}>
      {props.helpContent?.inline && <InlineHint>{props.helpContent.inline}</InlineHint>}
      <FormContainer>{props.signInForm}</FormContainer>
    </PanelRow>
    {props.helpContent?.footer}
  </>
);
