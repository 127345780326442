import * as React from "react";
import { WorkflowContextLike } from "../wizardry/workflowContextLike";
import { ApolloClient } from "apollo-client";
import { AuthProviders } from "../types/models/authProviders";
import { AnyWatcherAction } from "../services/watcher/anyWatcherAction";
import { Session } from "../types/models/session";
import { EMPTY, Observable, of } from "rxjs";
import { CloudServices } from "../types/models/cloudServices";
import { GraphQL } from "../services/graphql/generated";
import { Connection } from "../types/models/connection";
import { Blueprint } from "../blueprints/blueprint";
import { migrationBlueprintParams } from "../blueprints/blueprintParams";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { flatMap } from "rxjs/operators";
import { Facts } from "../types/facts/facts";
import { WatchedFacts } from "../services/watcher/plugins/watchedFactsPlugin";
import { Fact } from "../types/facts/fact";
import { Set } from "immutable";
import { Connections } from "../types/models/connections";
import { BatchMigrationPlan } from "./batch/batchMigrationPlan";
import { AppBootstrapConfig } from "../types/models/appBootstrapConfig";
import { SchoolSummary } from "../types/models/school";
import { mapOptional } from "../utils/misc";

interface Config {
  readonly sessionObservable: Observable<Session | undefined>;
  readonly apolloClient: ApolloClient<any>;
  readonly appBootstrapConfig: AppBootstrapConfig;
  readonly cloudServices: CloudServices;
  readonly sourceCloudServices: CloudServices;
  readonly destinationCloudServices: CloudServices;
  readonly authProviders: AuthProviders;
  readonly watcher: React.Dispatch<AnyWatcherAction>;
}

export interface ConfigurationIntroStep {
  bannerId: string;
  title: string;
  content: string;
}

interface SourceConnectionPromotions {
  isEligibleForCurrentProgram: boolean;
  school: SchoolSummary | undefined;
}

export class MigrationSetupWorkflowContext implements WorkflowContextLike {
  constructor(protected readonly config: Config) {
  }

  public get sessionObservable(): Observable<Session | undefined> { return this.config.sessionObservable; }
  public get apolloClient(): ApolloClient<any> { return this.config.apolloClient; }
  public get appBootstrapConfig(): AppBootstrapConfig { return this.config.appBootstrapConfig; }
  public get cloudServices(): CloudServices { return this.config.cloudServices; }
  public get sourceCloudServices(): CloudServices { return this.config.sourceCloudServices; }
  public get destinationCloudServices(): CloudServices { return this.config.destinationCloudServices; }
  public get authProviders(): AuthProviders { return this.config.authProviders; }
  public get watcher(): React.Dispatch<AnyWatcherAction> { return this.config.watcher; }

  public loadConnection(connectionId: string): Observable<Connection> {
    return this.sessionObservable
      .pipe(
        flatMap((session) => {
          if (session) {
            return new Observable<Connection>((subscriber) =>
              this.apolloClient
                .watchQuery<GraphQL.GetConnectionQuery, GraphQL.GetConnectionQueryVariables>({
                  query: GraphQL.GetConnectionDocument,
                  variables: { connectionId, userId: session.user.id }
                })
                .subscribe(
                  (value) => subscriber.next(Connection.fromGraphQL(value.data.connection)),
                  (error) => subscriber.error(error)
                )
            );
          } else {
            return EMPTY;
          }
        })
      );
  }

  public loadConnections(connectionIds: Set<string>): Observable<Connections> {
    return this.sessionObservable
      .pipe(
        flatMap((session) => {
          if (session) {
            return new Observable<Connections>((subscriber) =>
              this.apolloClient
                .watchQuery<GraphQL.GetConnectionsQuery, GraphQL.GetConnectionsQueryVariables>({
                  query: GraphQL.GetConnectionsDocument,
                  variables: {
                    connectionIds: connectionIds.toArray(),
                    userId: session.user.id,
                    accessKeyCheck: GraphQL.AccessKeyCheck.Skip
                  }
                })
                .subscribe(
                  (value) => subscriber.next(Connections.fromGraphQLList(value.data.getConnections)),
                  (error) => subscriber.error(error)
                )
            );
          } else {
            return EMPTY;
          }
        })
      );
  }

  public loadBlueprint(
    sourceCloudServiceId: string,
    destinationCloudServiceId: string
  ): Observable<Blueprint> {
    return new Observable<Blueprint>((subscriber) => this.apolloClient
      .watchQuery<GraphQL.GetMigrationBlueprintQuery, GraphQL.GetMigrationBlueprintQueryVariables>({
        query: GraphQL.GetMigrationBlueprintDocument,
        variables: { sourceCloudServiceId, destinationCloudServiceId }
      })
      .subscribe(
        (value) => {
          try {
            return subscriber.next(
              new Blueprint(
                migrationBlueprintParams(sourceCloudServiceId, destinationCloudServiceId),
                value.data.getMigrationBlueprint.components
              )
            );
          } catch (error) {
            console.error(error);
            subscriber.error(error);
          }
        },
        (error) => subscriber.error(error)
      )
    );
  }

  public loadFacts(blueprint: MaterializedMigrationBlueprint): Observable<Facts> {
    const factIds = blueprint.unblockedFactIds();
    this.watcher(WatchedFacts.WatchFactsAction(factIds));
    if (factIds.isEmpty()) {
      return of(Facts.Empty);
    } else {
      const missingFactIds = Fact.filterMissing(factIds, this.apolloClient);
      // if (!missingFactIds.isEmpty()) {
      //   console.log("Some facts are missing, will request them from the server:", missingFactIds.toArray());
      // }

      return new Observable<Facts>((subscriber) => this.apolloClient
        .watchQuery<GraphQL.GetBlueprintFactsQuery, GraphQL.GetBlueprintFactsQueryVariables>({
          query: GraphQL.GetBlueprintFactsDocument,
          variables: {
            sourceCloudServiceId: blueprint.sourceCloudServiceId,
            destinationCloudServiceId: blueprint.destinationCloudServiceId,
            blueprintFactSpecs: [{
              blueprintInputs: blueprint.context.inputs.toGraphQL(),
              factIds: factIds.toArray()
            }]
          },
          fetchPolicy: missingFactIds.isEmpty() ? undefined : "network-only"
        })
        .subscribe(
          (value) => subscriber.next(Facts.fromGraphQLList(value.data.getBlueprintFacts.facts)),
          (error) => subscriber.error(error)
        )
      );
    }
  }

  public loadBatchFacts(batchMigrationPlan: BatchMigrationPlan): Observable<Facts> {
    const factIds = batchMigrationPlan.factIds;

    this.watcher(WatchedFacts.WatchFactsAction(factIds));

    if (factIds.isEmpty()) {
      return of(Facts.Empty);
    } else {
      // This is an expensive operation, but it supposed to be performed only when the migration plan changes,
      // and that in turn happens only when providers change or items are added or removed
      // Upd: It's actually no SO expensive - takes 2-10 ms on relatively a large set of facts (50+ accounts)
      const missingFactIds = Fact.filterMissing(factIds, this.apolloClient);

      return new Observable<Facts>((subscriber) => this.apolloClient
        .watchQuery<GraphQL.GetBlueprintFactsQuery, GraphQL.GetBlueprintFactsQueryVariables>({
          query: GraphQL.GetBlueprintFactsDocument,
          variables: {
            sourceCloudServiceId: batchMigrationPlan.batchContext.sourceCloudService.id,
            destinationCloudServiceId: batchMigrationPlan.batchContext.destinationCloudService.id,
            blueprintFactSpecs: batchMigrationPlan.blueprintFactSpecs.map((spec) => ({
              blueprintInputs: spec.blueprintInputs.toGraphQL(),
              factIds: spec.factIds.toArray()
            })).toArray()
          },
          fetchPolicy: missingFactIds.isEmpty() ? undefined : "network-only"
        })
        .subscribe(
          (value) => subscriber.next(Facts.fromGraphQLList(value.data.getBlueprintFacts.facts)),
          (error) => subscriber.error(error)
        )
      );
    }
  }

  public get configurationIntroStep(): ConfigurationIntroStep | undefined {
    const program = this.appBootstrapConfig.program;
    const configuration = this.appBootstrapConfig.configuration;

    const bannerId = program?.introBannerId || configuration?.introBannerId;
    const title = program?.introTitle || configuration?.introTitle;
    const content = program?.introContent || configuration?.introContent;

    return bannerId && title && content ? { bannerId, title, content } : undefined;
  }

  public get configurationPricingPage(): ConfigurationIntroStep | undefined {
    const program = this.appBootstrapConfig.program;
    const configuration = this.appBootstrapConfig.configuration;

    const bannerId = program?.pricingPageBannerId || configuration?.pricingPageBannerId;
    const title = program?.pricingPageTitle || configuration?.pricingPageTitle;
    const content = program?.pricingPageContent || configuration?.pricingPageContent;

    return bannerId && title && content ? { bannerId, title, content } : undefined;
  }

  public getSourceConnectionPromotions(
    sourceConnectionId: string,
    destinationCloudServiceId: string,
    destinationConnectionId: string | undefined
  ): Observable<SourceConnectionPromotions> {
    const programAlias = this.appBootstrapConfig.program?.alias;
    return new Observable((subscriber) => this.apolloClient
      .watchQuery<GraphQL.GetSourceConnectionPromotionsQuery, GraphQL.GetSourceConnectionPromotionsQueryVariables>({
        query: GraphQL.GetSourceConnectionPromotionsDocument,
        variables: {
          sourceConnectionId,
          destinationCloudServiceId,
          destinationConnectionId,
          currentProgramAlias: programAlias
        },
      })
      .subscribe(
        (value) => {
          const school = mapOptional(value.data.getSourceConnectionPromotions.school, SchoolSummary.parse);
          if (programAlias) {
            return subscriber.next({
              isEligibleForCurrentProgram: !!value.data.getSourceConnectionPromotions.programEligibility
                .find((programEligibility) => programEligibility.program.alias === programAlias),
              school
            });
          } else {
            subscriber.next({
              isEligibleForCurrentProgram: value.data.getSourceConnectionPromotions.programEligibility.length === 0,
              school
            });
          }
        },
        (error) => subscriber.error(error)
      )
    );
  }
}
