import * as React from "react";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { styled } from "../../../../app/theme";
import { useDrawer } from "../../../layouts/drawer";
import { Tracking } from "../../../../services/tracking";
import { sentence } from "../../../../utils/misc";
import { List } from "immutable";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { ToolWithDescriptionLink } from "../../../widgets/toolWithDescriptionLink";

const Details = styled.span`
  display: block;
  padding-top: .2rem;
  font-size: .85rem;
`;

function sharedStart(array: string[]): string {
  const A = array.concat().sort();
  const a1 = A[0];
  const a2 = A[A.length - 1];
  const L = a1.length;
  let i = 0;
  while (i < L && a1.charAt(i) === a2.charAt(i)) i += 1;
  return a1.substring(0, i);
}

interface Props {
  areas: MigrationStatusPageDefs.SourceArea[];
}

export const FaqPanel: React.FunctionComponent<Props> = (props) => {
  const drawer = useDrawer();

  return (
    <Panel>
      <TitlePanelRow>Frequently Asked Questions</TitlePanelRow>
      {
        List(props.areas)
          .filter((area) => !area.excluded && area.helpArticle)
          .groupBy((area) => area.appTitle + "/" + (area.helpArticle as PreparedHelpArticle).id)
          .map((areas) => {
            const areasArray = areas.toList().toArray();
            const title = sharedStart(areasArray.map((area) => area.description));
            const firstInGroup = areasArray[0];
            const helpArticle = firstInGroup.helpArticle as PreparedHelpArticle;

            return (
              <PanelRow key={title} icon={firstInGroup.icon}>
                <ToolWithDescriptionLink
                  title={sentence(title)}
                  description={
                    "Where did my copied " +
                    firstInGroup.mainSubject +
                    "s go? How can I be sure it's all been copied? ..."
                  }
                  onClick={() => {
                    drawer.open({ content: helpArticle.content, title: helpArticle.title });
                    if (helpArticle.id) {
                      Tracking.viewedHelpArticle(helpArticle.id);
                    }
                  }}
                />
              </PanelRow>
            );
          })
          .toList()
      }
    </Panel>
  );
};
