import * as React from "react";
import {
  useRestartCurrentEmailDeliveryRoundMutation
} from "../../../queries/automatedOffboarding/useRestartCurrentEmailDeliveryRoundMutation";
import {
  RestartCurrentEmailDeliveryRoundViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/restartCurrentEmailDeliveryRoundController";
import { ControllerProps } from "../../../utils/controller";

interface RestartCurrentEmailDeliveryRoundToolControllerProps
  extends ControllerProps<RestartCurrentEmailDeliveryRoundViewProps> {
  offboardingProjectId: string;
}

const RestartCurrentEmailDeliveryRoundToolController
  : React.FunctionComponent<RestartCurrentEmailDeliveryRoundToolControllerProps> =
  (props) => {
    const [advance, status] = useRestartCurrentEmailDeliveryRoundMutation(props.offboardingProjectId);

    return props.render({
      status,
      onSubmit: advance
    });
  };

export function useRestartCurrentEmailDeliveryRoundController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<RestartCurrentEmailDeliveryRoundViewProps>) => (
      <RestartCurrentEmailDeliveryRoundToolController
        offboardingProjectId={offboardingProjectId}
        {...controllerProps}
      />
    ),
    [offboardingProjectId]
  );
}
