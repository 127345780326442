import * as React from "react";
import * as ReactDOM from "react-dom";
import * as yup from "yup";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { SchoolFormData } from "../../../types/models/schoolFormData";
import { School } from "../../../types/models/school";
import { FormConfig } from "../../blocks/crud/formConfig";
import { ItemForm } from "../../blocks/crud/itemForm";
import { blankStringToUndefined } from "../../../utils/misc";
import { DropDownField } from "../../widgets/dropDownField";
import { Countries } from "../../../app/countries";
import { ChatGPTLookupController } from "../../../controllers/schoolAdmin/chatGPTLookupController";
import { useModal } from "../../layouts/modal";
import { Button } from "../../widgets/button";
import { ChatGPTLookupModal } from "./chatGPTLookupModal";
import { Upload } from "../../../types/models/upload";
import { FileUploadController, ImageUploadPreview } from "../../widgets/fileUpload";
import { FileUploadField } from "../../widgets/fileUploadField";
import { CheckboxField } from "../../widgets/checkboxField";
import { YupTests } from "../../../utils/yupTests";
import { LogoPreview } from "./logoPreview";
import { PanelRow } from "../../containers/rows/panelRow";
import { Block } from "views/containers/block";
import { Panel } from "views/containers/panel";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import { WelcomeMessagePreview } from "./welcomeMessagePreview";

enum ServiceProviderUsage {
  Yes = "Yes",
  No = "No",
  Unknown = "Unknown"
}

namespace ServiceProviderUsageDefs {
  export const Values: string[] = Object.values(ServiceProviderUsage);

  export const Options = (
    <>
      <option value={ServiceProviderUsage.Unknown}>Unknown</option>
      <option value={ServiceProviderUsage.Yes}>Yes</option>
      <option value={ServiceProviderUsage.No}>No</option>
    </>
  );

  export function fromBoolean(value: boolean | undefined): ServiceProviderUsage {
    if (value) {
      return ServiceProviderUsage.Yes;
    } else if (value === undefined) {
      return ServiceProviderUsage.Unknown;
    } else {
      return ServiceProviderUsage.No;
    }
  }

  export function toBoolean(value: ServiceProviderUsage): boolean | undefined {
    switch (value) {
      case ServiceProviderUsage.Yes: return true;
      case ServiceProviderUsage.No: return false;
      case ServiceProviderUsage.Unknown: return undefined;
    }
  }
}

interface FormData {
  readonly domains: string;

  readonly officialName: string;
  readonly commonName: string;
  readonly mascot: string;
  readonly logo: Upload | undefined;
  readonly logoWidth: string;
  readonly landingPageUrl: string;

  readonly country: string;
  readonly type: string;
  readonly website: string;
  readonly ncesUrl: string;
  readonly avgAnnualGrads: string;
  readonly usesGoogle: ServiceProviderUsage;
  readonly usesMicrosoft: ServiceProviderUsage;

  readonly welcomeMessage: string;
  readonly notes: string;

  readonly reviewed: boolean;
}

namespace FormData {
  export const blank: FormData = {
    domains: "",

    officialName: "",
    commonName: "",
    mascot: "",
    logo: undefined,
    logoWidth: "",
    landingPageUrl: "",

    country: "",
    type: "",
    website: "",
    ncesUrl: "",
    avgAnnualGrads: "",
    usesGoogle: ServiceProviderUsage.Unknown,
    usesMicrosoft: ServiceProviderUsage.Unknown,

    welcomeMessage: "",
    notes: "",

    reviewed: false
  };

  export function make(school: School): FormData {
    return {
      domains: school.domains || "",

      officialName: school.officialName || "",
      commonName: school.commonName || "",
      mascot: school.mascot || "",
      logo: school.logo,
      logoWidth: school.logoWidth !== undefined ? school.logoWidth.toString() : "",
      landingPageUrl: school.landingPageUrl || "",

      country: school.country || "",
      type: school.type || "",
      website: school.website || "",
      ncesUrl: school.ncesUrl || "",
      avgAnnualGrads: school.avgAnnualGrads !== undefined ? school.avgAnnualGrads.toString() : "",
      usesGoogle: ServiceProviderUsageDefs.fromBoolean(school.usesGoogle),
      usesMicrosoft: ServiceProviderUsageDefs.fromBoolean(school.usesMicrosoft),

      welcomeMessage: school.welcomeMessage || "",
      notes: school.notes || "",

      reviewed: school.reviewed
    };
  }

  export function toResult(formData: FormData): SchoolFormData {
    const trimmedAvgAnnualGrads = formData.avgAnnualGrads.trim();

    return {
      domains: formData.domains,

      officialName: blankStringToUndefined(formData.officialName),
      commonName: blankStringToUndefined(formData.commonName),
      mascot: blankStringToUndefined(formData.mascot),
      logoId: formData.logo?.id,
      logoWidth: formData.logoWidth.length ? Number.parseFloat(formData.logoWidth) : undefined,
      landingPageUrl: blankStringToUndefined(formData.landingPageUrl),

      country: blankStringToUndefined(formData.country),
      type: blankStringToUndefined(formData.type),
      website: blankStringToUndefined(formData.website),
      ncesUrl: blankStringToUndefined(formData.ncesUrl),
      avgAnnualGrads: trimmedAvgAnnualGrads.length ? Number.parseInt(trimmedAvgAnnualGrads, 10) : undefined,
      usesGoogle: ServiceProviderUsageDefs.toBoolean(formData.usesGoogle),
      usesMicrosoft: ServiceProviderUsageDefs.toBoolean(formData.usesMicrosoft),

      welcomeMessage: blankStringToUndefined(formData.welcomeMessage),
      notes: blankStringToUndefined(formData.notes),

      reviewed: formData.reviewed
    };
  }

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    domains: yup.string().required("Domains are required"),

    officialName: yup.string(),
    commonName: yup.string(),
    mascot: yup.string(),
    logo: yup.mixed().notRequired(),
    logoWidth: yup.string().test(YupTests.twoDigitalPlacesFloat(false)),
    landingPageUrl: yup.string(),

    country: yup.string(),
    type: yup.string(),
    website: yup.string(),
    ncesUrl: yup.string(),
    avgAnnualGrads: yup.string().test(
      "integer",
      "Average annual grads must be an empty string or an integer",
      (amount) => amount === undefined || amount === "" || /^\d+$/.test(amount)
    ),
    usesGoogle: yup.mixed().oneOf(ServiceProviderUsageDefs.Values),
    usesMicrosoft: yup.mixed().oneOf(ServiceProviderUsageDefs.Values),

    welcomeMessage: yup.string(),
    notes: yup.string(),

    reviewed: yup.boolean()
  });
}

interface Props {
  config: FormConfig<School, SchoolFormData>;

  fileUploadController: FileUploadController;
  chatGPTLookupController: ChatGPTLookupController;
}

export const SchoolForm: React.FunctionComponent<Props> = (props) => {
  const [lookedUpDomains, setLookedUpDomains] = React.useState("");

  const modalContainerRef = React.createRef<HTMLDivElement>();
  const [modalContainer, setModalContainer] = React.useState<HTMLDivElement>();

  // Initializing the ref does not result in re-rendering, so we have to trigger it ourselves by updating the state
  React.useEffect(
    () => {
      if (modalContainerRef.current) {
        setModalContainer(modalContainerRef.current);
      }
    },
    [modalContainerRef.current]
  );

  const lookupModal = useModal();

  return (
    <>
      {/* The modal has to be rendered outside the form because it contains a form too */}
      <div ref={modalContainerRef}/>
      <ItemForm<School, FormData, SchoolFormData>
        config={props.config}

        subject={"school"}
        readOnly={props.config.readOnly}

        blankFormData={FormData.blank}
        makeFormData={FormData.make}
        makeResult={FormData.toResult}
        validationSchema={FormData.validationSchema}
      >
        {({ firstInputRef, submitting, formProps }) => (
          <>
            {modalContainer && ReactDOM.createPortal(
              <ChatGPTLookupModal
                modal={lookupModal}
                domains={lookedUpDomains}
                chatGPTLookupController={props.chatGPTLookupController}
                onApply={(result) =>
                  result.forEach((value, key) => formProps.setFieldValue(key, value))
                }
              />,
              modalContainer
            )}
            <Block>
              <Panel>
                <TitlePanelRow>Key Properties</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <TextAreaField<FormData>
                      label={"Domains"}
                      name={"domains"}
                      required={true}
                      disabled={submitting}
                      textBoxRef={firstInputRef as React.RefObject<HTMLTextAreaElement>}
                    />
                    <TextField<FormData>
                      label={"Official name"}
                      name={"officialName"}
                      disabled={submitting}
                      maxLength={255}
                    />
                    <TextField<FormData>
                      label={"Common name"}
                      name={"commonName"}
                      disabled={submitting}
                      maxLength={255}
                    />
                    <TextField<FormData>
                      label={"Branded landing page URL"}
                      name={"landingPageUrl"}
                      disabled={submitting}
                      maxLength={255}
                    />
                    <TextAreaField<FormData>
                      label={"Notes"}
                      name={"notes"}
                      disabled={submitting}
                    />
                    <CheckboxField<FormData>
                      label={"Mark as reviewed"}
                      name={"reviewed"}
                      disabled={submitting}
                    />
                    {props.config.item && !props.config.isNew && (
                      <FormField>
                        <Button
                          size={"small"}
                          color={"secondary"}
                          onClick={() => {
                            setLookedUpDomains(formProps.values.domains);
                            lookupModal.open();
                          }}
                        >
                          Look Up Using ChatGPT
                        </Button>
                      </FormField>
                    )}
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Welcome message</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <TextAreaField<FormData>
                      label={"Custom welcome message"}
                      name={"welcomeMessage"}
                      disabled={submitting}
                      rows={15}
                    />
                    <FormField label={"Preview"}>
                      <WelcomeMessagePreview school={{ id: "", ...FormData.toResult(formProps.values) }}/>
                    </FormField>
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Logo</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <FileUploadField<FormData>
                      label={"Logo"}
                      name={"logo"}
                      controller={props.fileUploadController}
                      renderPreview={(id) => <ImageUploadPreview id={id}/>}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    />
                    <TextField<FormData>
                      label={"Logo width (rem)"}
                      name={"logoWidth"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                      maxLength={10}
                      dirtyRegExp={/^\-?[\.\d]*$/}
                    />
                    <FormField label={"Preview"}>
                      <LogoPreview
                        logoId={formProps.values.logo?.id}
                        logoWidth={
                          formProps.values.logoWidth
                            ? Number.parseFloat(formProps.values.logoWidth)
                            : undefined
                        }
                      />
                    </FormField>
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Reference Information</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    <DropDownField<FormData>
                      label={"Country"}
                      name={"country"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    >
                      <option value={""}/>
                      {Countries.map((country) => <option key={country} value={country}>{country}</option>)}
                    </DropDownField>
                    <TextField<FormData>
                      label={"Type"}
                      name={"type"}
                      disabled={submitting}
                      maxLength={50}
                    />
                    <TextField<FormData>
                      label={"Website"}
                      name={"website"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                      autoComplete={false}
                      maxLength={255}
                    />
                    <TextField<FormData>
                      label={"NCES URL"}
                      name={"ncesUrl"}
                      disabled={submitting}
                      maxLength={255}
                      autoComplete={false}
                    />
                    <TextField<FormData>
                      label={"Average annual grads"}
                      name={"avgAnnualGrads"}
                      disabled={submitting}
                      maxLength={50}
                      autoComplete={false}
                      dirtyRegExp={/^\d*$/}
                    />
                    <DropDownField<FormData>
                      label={"Uses Google"}
                      name={"usesGoogle"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    >
                      {ServiceProviderUsageDefs.Options}
                    </DropDownField>
                    <DropDownField<FormData>
                      label={"Uses Microsoft"}
                      name={"usesMicrosoft"}
                      readOnly={props.config.readOnly}
                      disabled={submitting}
                    >
                      {ServiceProviderUsageDefs.Options}
                    </DropDownField>
                    <TextField<FormData>
                      label={"Mascot"}
                      name={"mascot"}
                      disabled={submitting}
                      maxLength={255}
                    />
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
          </>
        )}
      </ItemForm>
    </>
  );
};
