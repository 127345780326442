import * as React from "react";
import { OperationStatus } from "../types/operationStatus";
import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { Blueprint } from "../blueprints/blueprint";
import { migrationBlueprintParams } from "../blueprints/blueprintParams";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { AuthProviders } from "../types/models/authProviders";
import { BlueprintContext } from "../blueprints/blueprintContext";
import { Connections } from "../types/models/connections";
import { ServerElements } from "../blueprints/serverElements";
import { BlueprintInputs } from "../blueprints/blueprintInputs";
import { CloudServices } from "../types/models/cloudServices";
import { RoleComp } from "../blueprints/components/roleComp";
import { IterationFactRef, MigrationFactRef } from "../blueprints/migrationFactRef";
import { Migration } from "../types/models/migration";
import { nullToUndefined } from "../utils/misc";
import { Iteration } from "../types/models/iteration";
import { usePrevious } from "../utils/usePrevious";

function useMigration(migrationId: string) {
  // MigrationProgress query returns the set of facts for the last iteration. But, if the migration object changes
  // and iteration increases (for example, as a result of mutation), the new set of facts will not be re-fetched
  // automatically. Therefore, we are specifically tracking this condition below, so that the result always
  // contains a fresh set of facts for the last iteration.

  const [status, refetch] = useManagedQuery({
    query: GraphQL.useMigrationProgressQuery,
    deps: null,
    prepare: () => ({ migrationId }),
    extract: (data: GraphQL.MigrationProgressQuery) => nullToUndefined(data.migration),
    complete: (data) => ({
      ...Migration.parseCore(data),
      ...Migration.HasConnections.parse(data),
      ...Migration.HasOrderSummary.parse(data),
      ...Migration.HasProgressData.parse(data)
    })
  });

  const latestIterationIndex = status.someResult()?.iteration;
  const previousMigrationId = usePrevious(migrationId);
  const previousLatestIterationIndex = React.useRef<number | undefined>(latestIterationIndex);

  React.useEffect(
    () => {
      if (previousMigrationId === migrationId) {
        if (latestIterationIndex !== undefined) {
          if (
            previousLatestIterationIndex.current !== undefined &&
            latestIterationIndex !== previousLatestIterationIndex.current
          ) {
            console.log(
              "Current iteration index has been updated (" +
              previousLatestIterationIndex.current + " -> " + latestIterationIndex +
              "), refetching migration progress"
            );
            refetch({}, true);
          }
          previousLatestIterationIndex.current = latestIterationIndex;
        }
      } else {
        previousLatestIterationIndex.current = undefined;
      }
    },
    [latestIterationIndex]
  );

  return status;
}

function useIteration(migrationId: string, iterationIndexOpt: number | undefined) {
  return useManagedQuery({
    query: GraphQL.useIterationProgressQuery,
    deps: iterationIndexOpt,
    prepare: (iterationIndex) => ({ migrationId, iterationIndex }),
    extract: (data: GraphQL.IterationProgressQuery) => nullToUndefined(data.iteration),
    complete: (data) => ({
      ...Iteration.parseCore(data),
      ...Iteration.HasProgressData.parse(data)
    })
  });
}

export function useMigrationBlueprint(
  sourceCloudServiceId: string | undefined,
  destinationCloudServiceId: string | undefined
) {
  return useManagedQuery({
    query: GraphQL.useGetMigrationBlueprintQuery,
    deps: sourceCloudServiceId !== undefined && destinationCloudServiceId !== undefined
      ? { sourceCloudServiceId, destinationCloudServiceId }
      : undefined,
    prepare: (deps) => deps,
    extract: (data: GraphQL.GetMigrationBlueprintQuery) => data.getMigrationBlueprint,
    complete: (blueprint, deps) => new Blueprint(
      migrationBlueprintParams(deps.sourceCloudServiceId, deps.destinationCloudServiceId),
      blueprint.components
    )
  });
}

interface Options {
  cloudServices: CloudServices;
  authProviders: AuthProviders;
  migrationId: string;
  iterationIndex: number | undefined;
}

export interface UseMigrationDetailsHook {
  migration: Migration & Migration.HasOrderSummary & Migration.HasConnections & Migration.HasProgressData;
  blueprint: MaterializedMigrationBlueprint;
  iterationStatus: OperationStatus<Iteration>;
}

export function useMigrationProgressQuery(options: Options): OperationStatus<UseMigrationDetailsHook> {
  const lastResult = React.useRef<UseMigrationDetailsHook>();

  const getMigrationStatus = useMigration(options.migrationId);

  // Iteration only needs to be requested when it's not the latest iteration (the facts for the latest iteration
  // are always returned with migration status).
  const latestIterationIndex = getMigrationStatus.someResult()?.iteration;
  const iterationIndex =
    latestIterationIndex !== undefined &&
    options.iterationIndex !== undefined &&
    options.iterationIndex < latestIterationIndex
      ? options.iterationIndex
      : undefined;
  const [getIterationStatus] = useIteration(options.migrationId, iterationIndex);

  const [getMigrationBlueprintStatus] = useMigrationBlueprint(
    getMigrationStatus.someResult()?.sourceCloudServiceId,
    getMigrationStatus.someResult()?.destinationCloudServiceId
  );

  if (!getMigrationStatus.isSuccess()) {
    return getMigrationStatus.adopted(lastResult.current);
  } else if (getIterationStatus.isFailure()) {
    return getIterationStatus.adopted(lastResult.current);
  } else if (!getMigrationBlueprintStatus.isSuccess()) {
    return getMigrationBlueprintStatus.adopted(lastResult.current);
  } else {
    const migration = getMigrationStatus.result;
    const result: UseMigrationDetailsHook = {
      migration,
      blueprint: new MaterializedMigrationBlueprint(
        getMigrationBlueprintStatus.result,
        new BlueprintContext({
          inputs: new BlueprintInputs(
            migration.blueprintInputs
              .set(MigrationFactRef.MigrationIdInput, migration.id)
              .set(
                IterationFactRef.IterationInput,
                (iterationIndex !== undefined ? iterationIndex : migration.iteration).toString()
              )
              .set(RoleComp.IgnoreMissingRolesInputId, RoleComp.Enabled)
          ),
          cloudServices: options.cloudServices,
          authProviders: options.authProviders,
          connections: new Connections([
            migration.sourceConnection,
            migration.destinationConnection
          ]),
          facts: iterationIndex !== undefined
            ? (getIterationStatus.isSuccess() ? getIterationStatus.result.facts : migration.facts)
            : migration.facts,
          serverElements: ServerElements.Empty
        }),
        migration.sourceCloudServiceId,
        migration.destinationCloudServiceId
      ),
      iterationStatus: iterationIndex !== undefined
        ? getIterationStatus
        : OperationStatus.Success(Iteration.fromMigration(migration))
    };
    lastResult.current = result;
    return OperationStatus.Success(result);
  }
}
