import * as React from "react";
import { Page } from "../../../containers/page";
import {
  OffboardingProjectEntryParticipationStatus
} from "../../../../types/enums/offboardingProjectEntryParticipationStatus";
import { OperationStatus } from "../../../../types/operationStatus";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { Button } from "../../../widgets/button";
import { Option } from "views/widgets/option";
import { styled } from "../../../../app/theme";
import { TextArea } from "../../../widgets/textArea";

interface Props {
  participationStatus: OperationStatus<OffboardingProjectEntryParticipationStatus>;

  optOutStatus: OperationStatus<any>;
  onOptOut: (reason: string, comment: string | undefined) => Promise<any>;

  unsubscribeStatus: OperationStatus<any>;
  onUnsubscribe: () => Promise<any>;
}

export const OptOutPageView: React.FunctionComponent<Props> = (props) => (
  <Page title={"Opt Out"}>
    <Block>
      <Panel>
        <OperationStatusIndicator
          status={props.participationStatus}
          indicators={StatusIndicators.PanelRow()}
        />
        {props.participationStatus.mapLastResult((participationStatus) => {
          switch (participationStatus) {
            case OffboardingProjectEntryParticipationStatus.Unknown:
              return <PanelRow>This link is not valid.</PanelRow>;

            case OffboardingProjectEntryParticipationStatus.Pending:
              return (
                <OptOutForm
                  optOutStatus={props.optOutStatus}
                  onOptOut={props.onOptOut}
                />
              );

            case OffboardingProjectEntryParticipationStatus.OptedOut:
              return (
                <PanelRow>
                  You have opted out already. If you change your mind, please use the link provided in the
                  notification email.
                </PanelRow>
              );

            case OffboardingProjectEntryParticipationStatus.Migrated:
              return (
                <UnsubscribeForm
                  unsubscribeStatus={props.unsubscribeStatus}
                  onUnsubscribe={props.onUnsubscribe}
                />
              );

            case OffboardingProjectEntryParticipationStatus.Unsubscribed:
              return <PanelRow>You have unsubscribed from all email notifications already.</PanelRow>;
          }
        })}
      </Panel>
    </Block>
  </Page>
);

const WillNotMigrate = "I don't want to migrate my content";
const WillUseFreeSolution = "I will use a free solution";
const Other = "Other";

interface OptOutFormProps {
  optOutStatus: OperationStatus<any>;
  onOptOut: (reason: string, comment: string | undefined) => Promise<any>;
}

const OptOutForm: React.FunctionComponent<OptOutFormProps> = (props) => {
  const [reason, setReason] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");

  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  if (props.optOutStatus.isSuccess()) {
    return (
      <PanelRow>
        You have opted out and will not be reminded again. If you change your mind, please use the link provided in the
        notification email.
      </PanelRow>
    );
  } else {
    return (
      <>
        <OperationStatusIndicator
          progressMessage={"Opting out..."}
          failureMessage={"Failed to opt out"}
          status={props.optOutStatus}
          indicators={StatusIndicators.PanelRow()}
        />
        <PanelRow>
          Please let us know why you have decided to opt out and click the button below. After this you will no
          longer be reminded.
          <br/>
          <br/>

          <Option
            name={"willNotMigrate"}
            value={WillNotMigrate}
            checked={reason === WillNotMigrate}
            onChange={() => setReason(WillNotMigrate)}
          >
            {WillNotMigrate}
          </Option>
          <Option
            name={"willUseFreeSolution"}
            value={WillUseFreeSolution}
            checked={reason === WillUseFreeSolution}
            onChange={() => setReason(WillUseFreeSolution)}
          >
            {WillUseFreeSolution} (like Google Takeout)
          </Option>
          <Option
            name={"other"}
            value={Other}
            checked={reason === Other}
            onChange={() => {
              setReason((prevReason) => {
                if (prevReason !== Other) {
                  setTimeout(0, () => {
                    if (textAreaRef.current) {
                      textAreaRef.current.focus();
                    }
                  });
                }
                return Other;
              });
            }}
          >
            {Other} (please specify a reason below)
          </Option>
          <StyledTextArea
            ref={textAreaRef}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            disabled={reason !== Other}
          />
          <br/>

          <Button
            disabled={reason === ""}
            onClick={() => {
              const trimmedComment = comment.trim();
              props.onOptOut(reason, trimmedComment === "" ? undefined : trimmedComment);
            }}
          >
            Opt Out
          </Button>
        </PanelRow>
      </>
    );
  }
};

const StyledTextArea = styled(TextArea)`
  margin: 0 0 2rem 3.5rem;
  max-width: 30rem;
`;

interface UnsubscribeFormProps {
  unsubscribeStatus: OperationStatus<any>;
  onUnsubscribe: () => Promise<any>;
}

const UnsubscribeForm: React.FunctionComponent<UnsubscribeFormProps> = (props) => {
  if (props.unsubscribeStatus.isSuccess()) {
    return (
      <PanelRow>
        You have unsubscribed from all email notifications.
      </PanelRow>
    );
  } else {
    return (
      <>
        <OperationStatusIndicator
          progressMessage={"Unsubscribing..."}
          failureMessage={"Failed to unsubscribe"}
          status={props.unsubscribeStatus}
          indicators={StatusIndicators.PanelRow()}
        />
        <PanelRow>
          You have migrated your content already. If you don't want to receive any further email notifications,
          click the "Unsubscribe" button below.
          <br/>
          <br/>
          <Button onClick={() => props.onUnsubscribe()}>Unsubscribe</Button>
        </PanelRow>
      </>
    );
  }
};
