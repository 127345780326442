import { BlueprintContext } from "./blueprintContext";
import { AnyBlueprintParams, BlueprintParams } from "./blueprintParams";
import { BlueprintComponents } from "./blueprintComponents";
import { AnyComponent, ComponentOf } from "./component";
import { List, Set } from "immutable";
import { ComponentParser } from "./components/componentParser";
import { GraphQL } from "../services/graphql/generated";

export class Blueprint {
  public components: BlueprintComponents;

  constructor(public readonly params: AnyBlueprintParams, components: GraphQL.ComponentFragment[]) {
    this.components = new BlueprintComponents();

    components.forEach((componentData) => {
      this.components.add(ComponentParser.fromGraphQL(this, componentData));
    });
  }

  public allComponents(): List<AnyComponent> {
    return this.components.allComponents();
  }

  public id(): string {
    return BlueprintParams.id(this.params);
  }

  public get<Output>(id: string): ComponentOf<Output> {
    return this.components.getOrFail(id);
  }

  public find<T extends AnyComponent>(f: (component: AnyComponent) => T | undefined): T | undefined {
    return this.components.find(f);
  }

  public list<T extends AnyComponent>(f: (component: AnyComponent) => T | undefined): List<T> {
    return this.components.list(f);
  }

  public trace<T extends AnyComponent>(
    startFrom: AnyComponent,
    pickWhen: (component: AnyComponent) => T | undefined,
    stopWhen: (component: AnyComponent) => boolean
  ): Set<T> {
    return this.components.trace(startFrom, pickWhen, stopWhen);
  }

  public messages(startFrom: AnyComponent, context: BlueprintContext): List<string> {
    return this.components.messages(startFrom, context);
  }
}
