import { List } from "immutable";

export class Path {
  constructor(private fragments: List<string>) {
  }

  public currentId(): string | undefined {
    return this.fragments.first(undefined);
  }

  public nestedPath(): Path {
    return new Path(this.fragments.shift());
  }

  public nestedPathFor(id: string, orElse: Path = Path.Empty): Path {
    return this.currentId() === id ? this.nestedPath() : orElse;
  }

  public toString(): string {
    return this.fragments.join("/");
  }

  public equals(that: Path): boolean {
    return this.fragments.equals(that.fragments);
  }
}

export namespace Path {
  export const Empty: Path = new Path(List());

  export function parse(s: string | Path): Path {
    return s instanceof Path ? s : new Path(List(s.split("/")));
  }

  export function single(fragment: string): Path {
    return new Path(List([fragment]));
  }
}
