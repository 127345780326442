import { User } from "../types/models/user";
import { Connection } from "../types/models/connection";
import { MouseFlow } from "./mouseFlow";
import { Mixpanel } from "./mixpanel";

export namespace Tracking {
  export function signedIn(user: User, elevated: boolean): void {
    if (elevated) {
      MouseFlow.tagMouseFlowSession(MouseFlow.Tags.Elevated);
    }
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.UserId, user.id);
    Mixpanel.setUserId(user.id);
  }

  export function connectedSource(connection: Connection): void {
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.Source, connection.descriptionOrId());
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.SourceClass, connection.class);
  }

  export function connectedDestination(connection: Connection, sameAsSource: boolean): void {
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.Destination, connection.descriptionOrId());
    if (sameAsSource) {
      MouseFlow.tagMouseFlowSession(MouseFlow.Tags.BadDestination);
    }
  }

  function migrationSize(itemCount: number): string {
    if (itemCount <= 1000) {
      return "tiny";
    } else if (itemCount <= 5000) {
      return "small";
    } else if (itemCount <= 25000) {
      return "medium";
    } else if (itemCount <= 100000) {
      return "large";
    } else {
      return "x-large";
    }
  }

  export function scannedSource(connection: Connection, itemCount: number): void {
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.SourceSize, migrationSize(itemCount));
  }

  export function checkedMigrationStatus(migrationId: string): void {
    MouseFlow.setMouseFlowSessionVariable(MouseFlow.Variables.MigrationId, migrationId);
  }

  export function viewedHelpArticle(helpArticleId: string): void {
    MouseFlow.tagMouseFlowSession(MouseFlow.Tags.ViewedHelp);
  }
}
