import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyHub } from "../hub";
import { AnyComponent } from "../component";
import { ComponentRelationship } from "./componentRelationship";
import { PropertiesTable, PropertiesTableContainer } from "./propertiesTable";
import { Section } from "./section";
import { SubSection } from "./subSection";
import { ServerLabel } from "./serverLabel";
import { BlueprintDiff } from "../blueprintDiff";
import { ElementOutput } from "./elementOutput";
import { ElementStatus } from "./elementStatus";
import { ElementErrors } from "./elementErrors";

interface ComponentHubProps {
  horizontal: boolean;
  component: AnyComponent;
  index: number;
  hub: AnyHub;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  openSectionId?: string;
  onSectionSelect: (sectionId: string) => void;
  onComponentSelect: (componentId: string) => void;
}

export const ComponentHub: React.SFC<ComponentHubProps> = (props) => {
  const state = props.hub.state(props.blueprintContext);
  const resolvedByServer = props.hub.resolvedByServer(props.blueprintContext);
  const diff = props.diffs.getHubDiff(props.component.id, props.hub.name);

  const relationships = props.hub.relationships.map((relationship) => (
      <ComponentRelationship
        key={relationship.componentId}
        relationship={relationship}
        blueprintContext={props.blueprintContext}
        diffs={props.diffs}
        onComponentSelect={props.onComponentSelect}
      />
    )
  );

  const title = props.horizontal
    ? <React.Fragment>{props.hub.title} &#x2689;</React.Fragment>
    : <React.Fragment>&#x2689; {props.hub.title}</React.Fragment>;

  return (
    <Section
      horizontal={props.horizontal}
      title={title}
      sectionId={"hub-" + props.index}
      openSectionId={props.openSectionId}
      onSelect={props.onSectionSelect}
    >
      <SubSection>
        <PropertiesTableContainer>
          <PropertiesTable>
            <tbody>
            <tr>
              <th>Hub Title:</th>
              <td>{props.hub.title}</td>
            </tr>
            <tr>
              <th>Hub Type:</th>
              <td>{props.hub.type}</td>
            </tr>
            <tr>
              <th>Resolvable At:</th>
              <td>
                {props.hub.serverSide && <ServerLabel/>}
                {props.hub.serverSide ? "Server only" : "Client and server"}
              </td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>
                <ElementStatus state={state} resolvedByServer={resolvedByServer} diff={diff}/>
              </td>
            </tr>
            <tr>
              <th>Errors:</th>
              <td>
                <ElementErrors state={state} resolvedByServer={resolvedByServer} diff={diff}/>
              </td>
            </tr>
            <tr>
              <th>Output:</th>
              <td>
                <ElementOutput section={false} state={state} resolvedByServer={resolvedByServer} diff={diff}/>
              </td>
            </tr>
            </tbody>
          </PropertiesTable>
        </PropertiesTableContainer>
      </SubSection>
      {relationships}
    </Section>
  );
};
