import { Collectable } from "../types/collectables/collectable";
import { BlueprintContext } from "./blueprintContext";
import { FactRef } from "../types/facts/factRef";
import { Option } from "../utils/monads/option";
import { BlueprintFactRefFactoryRegistry } from "./blueprintFactRefFactoryRegistry";
import { Fact } from "../types/facts/fact";
import { AnyComponent } from "./component";

export namespace FactHelper {
  export function resolvedFactRef<P, C, T extends Collectable<P, C>>(
    context: BlueprintContext,
    component: AnyComponent,
    factRef: FactRef.Props
  ): Option<FactRef.Resolved> {
    return BlueprintFactRefFactoryRegistry
      .get(factRef.type)
      .create(factRef.family)
      .resolveId({ blueprint: component.blueprint, blueprintContext: context, component })
      .map((id) => ({ id, family: factRef.family, valueType: factRef.valueType }));
  }

  export function getFact<P, C, T extends Collectable<P, C>>(
    context: BlueprintContext,
    component: AnyComponent,
    factRef: FactRef.Props
  ): Option<Fact> {
    return resolvedFactRef(context, component, factRef).flatMap((resolved) => context.facts.find(resolved));
  }

  export function getCollectable<P, C, T extends Collectable<P, C>>(
    context: BlueprintContext,
    component: AnyComponent,
    factRef: FactRef.Props
  ): Option<T> {
    return getFact(context, component, factRef).flatMap((fact) => fact.valueOfType<T>(factRef.valueType));
  }
}
