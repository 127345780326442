import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { Upload } from "./upload";

export interface OrganizationSummary {
  readonly id: number;
  readonly alias: string;
  readonly name: string;

  readonly themeId: number | undefined;
  readonly logoId: string | undefined;

  readonly revenueSharingEnabled: boolean;
  readonly sponsorshipLimitAmountRemaining: number | undefined;
}

export namespace OrganizationSummary {
  export function parse(data: GraphQL.OrganizationSummaryFragment): OrganizationSummary {
    return {
      id: data.id,
      alias: data.alias,
      name: data.name,

      themeId: nullToUndefined(data.themeId),
      logoId: nullToUndefined(data.logoId),

      revenueSharingEnabled: data.revenueSharingEnabled,
      sponsorshipLimitAmountRemaining: nullToUndefined(data.sponsorshipLimitAmountRemaining)
    };
  }
}

export interface Organization extends OrganizationSummary {
  readonly notes: string | undefined;

  readonly logo: Upload | undefined;
  readonly domains: string;

  readonly createdAt: Date;
  readonly activatedAt: Date;
  readonly updatedAt: Date;
}

export namespace Organization {
  export function parse(data: GraphQL.OrganizationFragment): Organization {
    return {
      ...OrganizationSummary.parse(data),

      notes: nullToUndefined(data.notes),

      logo: mapOptional(data.logo, (upload) => Upload.parse(upload)),
      domains: data.domains,

      createdAt: new Date(data.createdAt),
      activatedAt: new Date(data.activatedAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(organizationId: number): string {
    return cachedObjectId("Organization", organizationId.toString());
  }

  export const fragmentName = "Organization";
}
