import { AnyWorkItem } from "./workItem";
import { FastPromise } from "./fastPromise";
import { DataSource } from "./dataSource";
import { Observable } from "rxjs";
import { List, Map, Set } from "immutable";
import { Listener } from "./listener";

export class WorkItemContexts {
  constructor(
    private readonly listener: Listener<any>,
    private readonly map: Map<string, WorkItemContexts.Entry> = Map()
  ) {
  }

  public getContexts(
    workItem: AnyWorkItem<any, any>
  ): FastPromise<[WorkItemContexts.WorkItemContextsSnapshot, WorkItemContexts]> {
    if (workItem.result) {
      return this.resultContextDataSource(workItem).map(([value, updatedSelf]) => {
        return [{ propsContext: value.context, resultContext: value.context }, updatedSelf];
      });
    } else {
      return this.propsContextDataSource(workItem).map(([value, updatedSelf]) =>
        [{ propsContext: value.context, resultContext: undefined }, updatedSelf]
      );
    }
  }

  public toList(): List<WorkItemContexts.Entry> {
    return this.map.valueSeq().toList();
  }

  public keys(): Set<string> {
    return this.map.keySeq().toSet();
  }

  public applyUpdates(updates: List<Listener.Event<any>>): WorkItemContexts {
    const updateMap = Map(updates.map((update) => [update.subject, update.value]));

    const unknownKeys = updateMap.keySeq().toSet().subtract(this.map.keySeq());
    if (!unknownKeys.isEmpty()) {
      console.warn("[Wizardry] Received updates for unregistered work items: " + unknownKeys.join(", "));
    }

    const updatesToApply = updateMap.deleteAll(unknownKeys);

    if (!updatesToApply.isEmpty()) {
      // console.log("[Wizardry] Received context updates for", updatesToApply.keySeq().join(", "));

      return new WorkItemContexts(
        this.listener,
        this.map.merge(
          updatesToApply.map((context, key) => {
            const existing = this.map.get(key) as WorkItemContexts.Entry;
            return { ...existing, context };
          })
        )
      );
    } else {
      return this;
    }
  }

  public prepareForNextCycle(): WorkItemContexts {
    const cleared = this.map.filter((entry) => entry.updateOnEveryCycle);
    if (!cleared.isEmpty()) {
      // console.log("[Wizardry] Cleared contexts for", cleared.keySeq().join(", "));
      return new WorkItemContexts(this.listener, this.map.removeAll(cleared.keys()));
    } else {
      return this;
    }
  }

  protected propsContextDataSource(
    workItem: AnyWorkItem<any, any>
  ): FastPromise<[WorkItemContexts.Entry, WorkItemContexts]> {
    return this.findOrUpdate(workItem, () => workItem.getPropsContext());
  }

  protected resultContextDataSource(
    workItem: AnyWorkItem<any, any>
  ): FastPromise<[WorkItemContexts.Entry, WorkItemContexts]> {
    return this.findOrUpdate(workItem, () => workItem.getResultContext());
  }

  protected findOrUpdate(
    workItem: AnyWorkItem<any, any>,
    dataSourceFactory: () => DataSource<any>
  ): FastPromise<[WorkItemContexts.Entry, WorkItemContexts]> {
    const key = this.key(workItem);
    const existing = this.map.get(key);
    if (existing) {
      return FastPromise([existing, this]);
    } else {
      return this.prepareDataSource(workItem, dataSourceFactory()).map((entry) =>
        [entry, new WorkItemContexts(this.listener, this.map.set(key, entry))]
      );
    }
  }

  protected prepareDataSource(
    workItem: AnyWorkItem<any, any>,
    dataSource: DataSource<any>
  ): FastPromise<WorkItemContexts.Entry> {
    return dataSource.promise.map((context): WorkItemContexts.Entry => {
      // Since promise can be created from an observable, we'll wait for promise to resolve before subscribing
      if (dataSource.observable) {
        this.listener.listen(this.key(workItem), dataSource.observable);
      }
      return {
        context,
        observable: dataSource.observable,
        updateOnEveryCycle: dataSource.updateOnEveryCycle === true
      };
    });
  }

  protected key(workItem: AnyWorkItem<any, any>): string {
    const base = workItem.id + "//" + workItem.getPropsKey();
    const resultKey = workItem.getResultKey();
    return resultKey !== undefined ? base + "//" + resultKey : base;
  }
}

export namespace WorkItemContexts {
  export interface Entry {
    readonly context: any;
    readonly observable?: Observable<any>;
    readonly updateOnEveryCycle: boolean;
  }

  export interface WorkItemContextsSnapshot {
    readonly propsContext: any;
    readonly resultContext: any; // can be undefined!
  }
}
