import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { List } from "immutable";
import { ProgramEligibility } from "../types/models/programEligibility";
import { mapOptional } from "../utils/misc";
import { ProgramSummary } from "../types/models/program";
import { SchoolSummary } from "../types/models/school";

export function useGetSourceConnectionPromotionsQuery(
  params: {
    currentProgramAlias: string | undefined,
    sourceConnectionId: string | undefined,
    destinationCloudServiceId: string,
    destinationConnectionId: string | undefined,
    forceReload?: boolean
  },
) {
  return useManagedQuery({
    query: GraphQL.useGetSourceConnectionPromotionsQuery,
    deps: params.sourceConnectionId,
    prepare: (deps) => ({ ...params, sourceConnectionId: deps }),
    extract: (data: GraphQL.GetSourceConnectionPromotionsQuery) => data.getSourceConnectionPromotions,
    complete: (result) => ({
      ...result,
      currentProgram: mapOptional(result.currentProgram, ProgramSummary.parse),
      programEligibility: List(result.programEligibility).map(ProgramEligibility.parse),
      school: mapOptional(result.school, SchoolSummary.parse)
    }),
    fetchPolicy: params.forceReload ? "network-only" : undefined
  });
}
