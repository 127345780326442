import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface GoogleGroup {
  readonly internalId: string;
  readonly friendlyId: string;
  readonly name: string | undefined;
  readonly description: string | undefined;
}

export namespace GoogleGroup {
  export function parse(data: GraphQL.GoogleGroupFragment): GoogleGroup {
    return {
      internalId: data.internalId,
      friendlyId: data.friendlyId,
      name: nullToUndefined(data.name),
      description: nullToUndefined(data.description)
    };
  }
}
