import { identity } from "../../utils/misc";
import { List } from "immutable";
import { Reducer } from "../../blueprints/reducer";
import { Comparator } from "../../blueprints/comparator";
import { None, Option, Some } from "../../utils/monads/option";
import { Either, Left, Right } from "../../utils/monads/either";

export abstract class Collectable<P, C = P> {
  public abstract type: string;

  public constructor(public value: Collectable.Value<P, C>) {
  }

  public completeValue(): Option<C> {
    return this.value.fold(() => None(), (c) => Some(c));
  }

  public partialValue(): Option<Collectable.PartialValue<P>> {
    return this.value.fold((p) => Some(p), () => None());
  }

  public currentValue(): P | C {
    return this.value.fold<P | C>((p) => p.value, identity);
  }

  public isComplete(): boolean {
    return this.value.isRight();
  }

  public isPartial(): boolean {
    return this.value.isLeft();
  }

  public progress(): Option<number> {
    return this.partialValue().flatMap((p) => p.progress);
  }

  public toJSON(): any {
    return this.value.fold<any>(
      (p) => ({
        type: "Partial",
        value: p.value,
        progress: p.progress.getOrElse(() => undefined)
      }),
      (c) => ({
        type: "Complete",
        value: c
      })
    );
  }
}

export namespace Collectable {
  export type Any = Collectable<any, any>;

  export interface PartialValue<T> {
    value: T;
    progress: Option<number>;
  }

  export function PartialValue<T>(value: T, progress: Option<number> = None()): PartialValue<T> {
    return { value, progress };
  }

  export type Value<P, C> = Either<PartialValue<P>, C>;

  export interface Data {
    isComplete: boolean;
    progress?: number | null;
  }

  export function makeValue<P, C, PPrep, CPrep>(
    collectable: Data,
    partialValue: P | null | undefined,
    completeValue: C | null | undefined,
    preparePartial: (value: P) => PPrep,
    prepareComplete: (value: C) => CPrep,
  ): Value<PPrep, CPrep> {
    if (collectable.isComplete) {
      if (completeValue === null || completeValue === undefined) {
        console.error("Missing completeValue in ", collectable);
        throw new Error("Missing completeValue: " + collectable);
      } else {
        return Right<Collectable.PartialValue<PPrep>, CPrep>(prepareComplete(completeValue));
      }
    } else {
      if (partialValue === null || partialValue === undefined) {
        console.error("Missing partialValue in ", collectable);
        throw new Error("Missing partialValue: " + collectable);
      } else {
        const progress = collectable.progress !== null && collectable.progress !== undefined
          ? Some(collectable.progress)
          : None<number>();
        return Left<Collectable.PartialValue<PPrep>, CPrep>(
          PartialValue(preparePartial(partialValue), progress)
        );
      }
    }
  }

  export function groupValues<P, C>(
    collectables: List<Collectable<P, C>>
  ): { partialValues: List<P>, completeValues: List<C> } {
    return {
      partialValues: collectables
        .filter((c) => {
          try {
            return c.isPartial();
          } catch (error) {
            console.error(c, error);
            throw error;
          }
        })
        .map((c) => c.partialValue().get().value),
      completeValues: collectables.filter((c) => c.isComplete()).map((c) => c.completeValue().get())
    };
  }

  export function avgProgress<P, C>(collectables: List<Collectable<P, C>>): Option<number> {
    const progresses = collectables
      .map((c) => c.progress())
      .filter((p) => p.isDefined())
      .map((p) => p.get());
    return progresses.size
      ? Some(progresses.reduce<number>((a, b) => a + b) / progresses.size)
      : None();
  }

  export function reduceList<P, C>(
    collectables: List<Collectable<P, C>>,
    reducePartial: (a: P, b: P) => P,
    reduceComplete: (a: C, b: C) => C,
    completeToPartial: (a: C) => P
  ): Value<P, C> {
    const { partialValues, completeValues } = groupValues(collectables);
    if (partialValues.isEmpty()) {
      return Right<PartialValue<P>, C>(completeValues.reduce(reduceComplete));
    } else {
      return Left<PartialValue<P>, C>({
        value: partialValues.concat(completeValues.map(completeToPartial)).reduce(reducePartial),
        progress: avgProgress(collectables)
      });
    }
  }

  export interface TypeConfig<P, C, T extends Collectable<P, C>> {
    typeName: string;
    build: (value: Value<P, C>) => T;
    completeToPartial: (a: C) => P;
  }

  interface StandardReducersConfig<P, C, T extends Collectable<P, C>> {
    operation: string;
    reducerName?: string;
    optReducerName?: string;
    reducePartial: Reducer.ReduceFunction<P>;
    reduceComplete: Reducer.ReduceFunction<C>;
  }

  export function standardReducers<P, C, T extends Collectable<P, C>>(
    { typeName, build, completeToPartial }: TypeConfig<P, C, T>,
    { reducePartial, reduceComplete, ...rest }: StandardReducersConfig<P, C, T>
  ): { reducer: Reducer<T>, optReducer: Reducer<Option<T>> } {
    return Reducer.standardListReducers<T>({
      typeName,
      ...rest,
      reduce: (values) => build(Collectable.reduceList(values, reducePartial, reduceComplete, completeToPartial))
    });
  }

  export function standardSumReducers<P, C, T extends Collectable<P, C>>(
    typeConfig: TypeConfig<P, C, T>,
    reducePartial: Reducer.ReduceFunction<P>,
    reduceComplete: Reducer.ReduceFunction<C>
  ) {
    const reducers = standardReducers(typeConfig, { operation: "Sum", reducePartial, reduceComplete });
    return { Sum: reducers.reducer, SumOpt: reducers.optReducer };
  }

  export function standardMergeReducers<P, C, T extends Collectable<P, C>>(
    typeConfig: TypeConfig<P, C, T>,
    reducePartial: Reducer.ReduceFunction<P>,
    reduceComplete: Reducer.ReduceFunction<C>
  ) {
    const reducers = standardReducers(typeConfig, { operation: "Merge", reducePartial, reduceComplete });
    return { Merge: reducers.reducer, MergeOpt: reducers.optReducer };
  }

  export function standardMaxReducers<P, C, T extends Collectable<P, C>>(
    typeConfig: TypeConfig<P, C, T>,
    reducePartial: Reducer.ReduceFunction<P>,
    reduceComplete: Reducer.ReduceFunction<C>
  ) {
    const reducers = standardReducers(typeConfig, { operation: "Max", reducePartial, reduceComplete });
    return { Max: reducers.reducer, MaxOpt: reducers.optReducer };
  }

  interface StandardComparatorsConfig<P, C, T extends Collectable<P, C>> {
    comparatorName?: string;
    completeValuesComparatorName?: string;
    compare: Comparator.CompareFunction<T>;
    compareCompleteValues: Comparator.CompareFunction<C>;
  }

  export function standardComparators<P, C, T extends Collectable<P, C>>(
    { typeName, build, completeToPartial }: TypeConfig<P, C, T>,
    config: StandardComparatorsConfig<P, C, T>
  ): { CompareCompleteValues: Comparator<T>, Compare: Comparator<T> } {
    const title = "Compare(" + typeName + ")";
    return {
      Compare: Comparator<T>(
        config.comparatorName || (typeName + "$Compare$"),
        title,
        config.compare
      ),
      CompareCompleteValues: Comparator<T>(
        config.completeValuesComparatorName || (typeName + "$CompareCompleteValues$"),
        title,
        (a, b) => a.completeValue()
          .flatMap((a1) => b.completeValue().map((b1) => config.compareCompleteValues(a1, b1)))
          .getOrElse(() => undefined)
      ),
    };
  }
}
