import * as React from "react";
import { styled } from "../../../../app/theme";
import { AuthContext } from "../../authContext";
import { PanelRow } from "../../../containers/rows/panelRow";
import { LinkButton } from "../../../widgets/linkButton";
import { StyledComponentsProps } from "../../../utils/styledComponentsProps";

const Status = styled.div`
  font-size: 0.85rem;
  color: ${(props) => props.theme.colors.darkGray};
  margin-bottom: .2rem;
`;

const Description = styled.div`
  font-size: 1.1rem;
  font-weight: ${(props) => props.theme.font.bold};
  word-wrap: break-word;
  word-break: break-all;
`;

interface ConnectionDescriptionRowProps extends StyledComponentsProps {
  authContext: AuthContext;
  connectionDescription: string;
  status: string;
  onReScan?: () => void;
  onDisconnect?: () => void;
}

export const ConnectionDescriptionRow: React.FunctionComponent<ConnectionDescriptionRowProps> = (props) => {
  let actions: React.ReactElement[] = [];
  if (props.onDisconnect) {
    actions = [
      <LinkButton key="disconnect" onClick={props.onDisconnect}>Disconnect</LinkButton>
    ].concat(actions);
  }
  if (props.onReScan) {
    actions = [(
      <LinkButton key="re-scan" onClick={props.onReScan}>
        Re-{AuthContext.scanOrTest(props.authContext).toLowerCase()}
      </LinkButton>
    )].concat(actions);
  }
  return (
    <PanelRow actions={actions} className={props.className}>
      <Status>{props.status}</Status>
      <Description>{props.connectionDescription}</Description>
    </PanelRow>
  );
};
