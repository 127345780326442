import * as React from "react";
import { useSession } from "../../utils/useAppState";
import { Outlet, Navigate, useLocation } from "react-router";
import { SignInRedirectState } from "../../types/signInRedirectState";
import { UserType } from "../../types/models/userType";
import { useRoutes } from "../../app/routes/useRoutes";

export const BusinessUserRoutes: React.FunctionComponent = () => {
  const routes = useRoutes();
  const session = useSession();
  const location = useLocation();

  return session && (session.user.type === UserType.Business || session.user.type === UserType.Admin)
    ? <Outlet/>
    : <Navigate to={routes.static.signUpPath} state={SignInRedirectState(location, false)}/>;
};
