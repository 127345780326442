import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useGetOffboardingProjectQuery } from "../../../queries/automatedOffboarding/useGetOffboardingProjectQuery";
import {
  ImportHistoryPageView
} from "../../../views/screens/organizationPortal/automatedOffboarding/importHistoryPageView";
import {
  useGetOffboardingProjectUpdateHistoryQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectUpdateHistoryQuery";
import { useBrowser } from "../../../utils/useBrowser";
import {
  useListOffboardingProjectUpdatesQuery
} from "../../../queries/automatedOffboarding/useListOffboardingProjectUpdatesQuery";
import { AccessListUpdateStatus } from "../../../types/enums/accessListUpdateStatus";

interface Props {
  organization: OrganizationSummary;
}

export const ImportHistoryPage: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const apiRoutes = useRoutes().api;
  const offboardingProjectsRoutes = useRoutes().organizationPortalRoutes.automatedOffboarding(props.organization.alias);
  const params = offboardingProjectsRoutes.importHistoryParams();

  const [offboardingProjectStatus] = useGetOffboardingProjectQuery(params.id);
  const [offboardingProjectHistoryStatus, refreshOffboardingProjectHistory] =
    useGetOffboardingProjectUpdateHistoryQuery(params.id);

  const pendingUpdateIds = offboardingProjectHistoryStatus
    .mapLastResult((updates) =>
      updates
        .filter((update) =>
          update.status !== AccessListUpdateStatus.Success &&
          update.status !== AccessListUpdateStatus.Failure
        )
        .map((update) => update.id)
        .toArray()
    ) || [];

  const [offboardingProjectUpdatesStatus, refreshOffboardingProjectUpdates] =
    useListOffboardingProjectUpdatesQuery(pendingUpdateIds);

  React.useEffect(
    () => {
      if (pendingUpdateIds.length !== 0) {
        const timer = setInterval(
          () => refreshOffboardingProjectUpdates({ ids: pendingUpdateIds }, true),
          5000
        );
        return () => clearInterval(timer);
      }
    },
    [pendingUpdateIds]
  );

  return (
    <ImportHistoryPageView
      offboardingProjectId={params.id}
      offboardingProjectStatus={offboardingProjectStatus}
      offboardingProjectHistoryStatus={offboardingProjectHistoryStatus}

      homePath={offboardingProjectsRoutes.homePath}
      offboardingProjectPath={offboardingProjectsRoutes.overviewPath(params.id)}

      onRefresh={() => refreshOffboardingProjectHistory()}
      onDownloadOutput={(offboardingProjectUpdateId) =>
        browser.openNewTab(apiRoutes.offboardingProjectUpdateOutputUrl(offboardingProjectUpdateId), true)
      }
    />
  );
};
