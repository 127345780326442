import * as React from "react";
import { SandboxWorkflow, SandboxWorkflowContext, SandboxWorkflowResult } from "./sandboxWorkflow";
import { WorkflowController } from "../workflowController";
import { useApolloClient } from "@apollo/react-hooks";
import { useWatcherDispatch } from "../../services/watcher/useWatcher";
import { WorkflowLayout } from "../../views/layouts/workflowLayout";
import { WorkflowProgressIndicator } from "../../views/blocks/wizardry/workflowProgressIndicator";
import { WorkflowError } from "../../views/blocks/wizardry/workflowError";
import { List } from "immutable";
import { ClickableAnchor } from "../anchor";
import { WorkflowTitle } from "../../views/layouts/workflowTitle";
import { WorkflowNavigationAnchors } from "../../views/blocks/wizardry/workflowNavigationAnchors";
import { useBrowser } from "../../utils/useBrowser";
import { useRoutes } from "../../app/routes/useRoutes";

interface LeftHandNavProps {
  anchors: List<ClickableAnchor>;
}

const LeftHandNav: React.FunctionComponent<LeftHandNavProps> = (props) => (
  <>
    <WorkflowTitle>Wizardry Sandbox</WorkflowTitle>
    <WorkflowNavigationAnchors anchors={props.anchors}/>
  </>
);

export const WizardrySandbox: React.FunctionComponent = () => {
  const watcher = useWatcherDispatch();
  const apolloClient = useApolloClient();
  const routes = useRoutes().wizardrySandbox;
  const params = routes.params();  const browser = useBrowser();

  const context = React.useMemo<SandboxWorkflowContext>(
    () => ({ apolloClient, watcher }),
    [apolloClient, watcher]
  );

  const workflow = React.useMemo(() => SandboxWorkflow({}), []);

  return (
    <WorkflowController<SandboxWorkflowContext, any, SandboxWorkflowResult>
      workflow={workflow}
      context={context}
      path={params.path.getOrUse("")}
      localStorageKey="wizardry-sandbox"
      renderInitialization={<WorkflowLayout><WorkflowProgressIndicator/></WorkflowLayout>}
      renderError={(error) => <WorkflowError {...error}/>}
      onPathChange={(path) => browser.navigateTo(routes.wizardPath(path))}
      onComplete={(result) => console.log("Completed wizard", result)}
    >
      {
        ({ anchors, renderedWorkItem, working }) => (
          <WorkflowLayout leftHandNavContent={<LeftHandNav anchors={anchors}/>}>
            {working && <WorkflowProgressIndicator leftHandNavContent={<LeftHandNav anchors={anchors}/>}/>}
            {renderedWorkItem}
          </WorkflowLayout>
        )
      }
    </WorkflowController>
  );
};
