import * as React from "react";
import * as moment from "moment";
import { List } from "immutable";

export function bytesToGigabytes(bytes: number): number {
  return Math.round(bytes / (1024 * 1024 * 1024) * 100) / 100;
}

export function friendlySize(size: number | undefined): string {
  if (size !== undefined && size !== null) {
    if (size === -1) {
      return "Unlimited";
    } else {
      return bytesToGigabytes(size).toFixed(2) + " GB";
    }
  } else {
    return "? GB";
  }
}

export function friendlySizeOf(size: number | undefined, total: number): string {
  if (size !== undefined && size !== null) {
    return bytesToGigabytes(size).toFixed(2) + " of " +
      bytesToGigabytes(total).toFixed(2) + " GB";
  } else {
    return "? GB";
  }
}

export function humanSize(size: number | undefined, whenUndefined: string = "? Bytes") {
  if (size !== undefined && size !== null) {
    let bytes = size;
    if (Math.abs(bytes) < 1024) {
      return bytes + " B";
    }
    const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
      bytes /= 1024;
      u += 1;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  } else {
    return whenUndefined;
  }
}

export function commafy(inVal: number, fractionDigits?: number): string {
  const arrWhole = (fractionDigits !== undefined ? inVal.toFixed(fractionDigits) : inVal.toString()).split(".");
  const arrTheNumber = arrWhole[0].split("").reverse();
  const newNum = Array();
  for (let i = 0; i < arrTheNumber.length; i += 1) {
    newNum[newNum.length] = ((i % 3 === 2) && (i < arrTheNumber.length - 1)) ? "," + arrTheNumber[i] : arrTheNumber[i];
  }
  let returnNum = newNum.reverse().join("");
  if (arrWhole[1]) {
    returnNum += "." + arrWhole[1];
  }
  return returnNum;
}

const consonants = "BCDFGHJKLMNPQRSTVWXYZ";

export function friendlyCount(count: number | undefined, unit: string): string {
  if (count !== undefined && count !== null) {
    if (count === 1) {
      return commafy(count) + " " + unit;
    } else if (
      unit.length >= 2 &&
      unit[unit.length - 1] === "y" &&
      consonants.indexOf(unit[unit.length - 2].toUpperCase()) !== -1
    ) {
      return commafy(count) + " " + unit.substr(0, unit.length - 1) + "ies";

    } else {
      return commafy(count) + " " + unit + "s";
    }
  } else {
    return "? " + unit + "s";
  }
}

export function friendlyCountOf(count: number | undefined, total: number, unit: string): string {
  if (count !== undefined && count !== null) {
    return commafy(count) + " of " + commafy(total) + " " + unit + (total !== 1 ? "s" : "");
  } else {
    return "? " + unit + "s";
  }
}

export function friendlyCountWithPercentage(count: number | undefined, unit: string, percentage: number): string {
  return friendlyCount(count, unit) + " (" + percentage + "%)";
}

export function friendlyPrice(amount: number): string {
  return "$" + commafy(amount, 2);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Duration

export function secondsBetween(date1: Date, date2: Date): number {
  return (date1.getTime() - date2.getTime()) / 1000;
}

export function friendlyDuration(seconds: number | undefined, brief?: boolean): string {
  const fullUnits: [string, string, string] = ["day", "hour", "minute"];
  const briefUnits: [string, string, string] = ["day", "hr", "min"];

  const [day, hour, minute] = brief ? briefUnits : fullUnits;

  if (seconds !== undefined && seconds !== null) {
    let result = "";
    let minutes = Math.round(seconds / 60);

    if (minutes === 0) {
      result = "<1 " + minute;
    } else {
      const days = Math.floor(minutes / 24 / 60);
      if (days > 0) {
        result += days + " " + day;
        if (days > 1) {
          result += "s";
        }
        minutes %= (24 * 60);
      }

      const hours = Math.floor(minutes / 60);
      if (hours > 0) {
        if (result.length > 0) {
          result += " ";
        }
        result += hours + " " + hour;
        if (hours > 1) {
          result += "s";
        }
        minutes %= 60;
      }

      if (minutes > 0 || result.length === 0) {
        if (result.length > 0) {
          result += " ";
        }
        result += minutes + " " + minute;
        if (minutes > 1) {
          result += "s";
        }
      }
    }

    return result;
  } else {
    return "?";
  }
}

export function approximateDuration(
  seconds: number,
  justNow: string = "",
  prefix: string = "over the past "
): string {
  const hours = Math.round(seconds / 3600);
  if (hours < 1) {
    return justNow;
  } else if (hours === 1) {
    return prefix + "hour";
  } if (hours < 48) {
    return prefix + friendlyCount(hours, "hour");
  } else {
    return prefix + friendlyCount(Math.round(hours / 24), "day");
  }
}

export function approximateDurationBetween(
  date1: Date,
  date2: Date,
  justNow: string = "",
  prefix: string = "over the past "
): string {
  return approximateDuration(secondsBetween(date1, date2), justNow, prefix);
}

export function preciseDuration(seconds: number, includeSeconds: boolean = true): string {
  const absSeconds = Math.abs(seconds);
  const hours = Math.floor(absSeconds / 3600);
  const minutesOfHour = Math.floor(absSeconds % 3600 / 60);
  const secondsOfMinute = Math.floor(absSeconds % 60);
  return (seconds < 0 ? "-" : "") +
    hours + ":" +
    (minutesOfHour < 10 ? "0" : "") + minutesOfHour +
    (includeSeconds ? ":" + (secondsOfMinute < 10 ? "0" : "") + secondsOfMinute : "");
}

export function preciseDurationBetween(date1: Date, date2: Date, includeSeconds: boolean = true): string {
  return preciseDuration(secondsBetween(date1, date2), includeSeconds);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Date & Time

export function friendlyDateTime(s: string | Date, addAt: boolean = false): string {
  const m = moment(s);
  if (m.isValid()) {
    const date = m.format("MMM D, YYYY ");
    const time = m.format("h:mm a");
    return date + (addAt ? "at " : "") + time;
  } else {
    return s.toString();
  }
}

export function friendlyDate(s: string | Date): string {
  const m = moment(s);
  if (m.isValid()) {
    return m.format("MMM D, YYYY");
  } else {
    return s.toString();
  }
}

export function friendlyTime(s: string | Date): string {
  const m = moment(s);
  if (m.isValid()) {
    return m.format("h:mm a");
  } else {
    return s.toString();
  }
}

export function briefDateTime(s: string | Date, inline: boolean = false): string {
  const m = moment(s);
  if (m.isValid()) {
    const yesterday = moment().subtract(1, "days").startOf("day");
    const today = moment().startOf("day");
    const tomorrow = moment().add(1, "days").startOf("day");
    const dayAfterTomorrow = moment().add(2, "days").startOf("day");

    if (m.isBetween(today, tomorrow)) {
      return (inline ? "today" : "Today") + ", " + m.format("h:mm a");
    } else if (m.isBetween(tomorrow, dayAfterTomorrow)) {
      return (inline ? "tomorrow" : "Tomorrow") + ", " + m.format("h:mm a");
    } else if (m.isBetween(yesterday, today)) {
      return (inline ? "yesterday" : "Yesterday") + ", " + m.format("h:mm a");
    } else {
      return m.format("MMM D, h:mm a");
    }
  } else {
    return s.toString();
  }
}

export function preciseTimestamp(s: string | Date): string {
  const m = moment(s);
  if (m.isValid()) {
    return m.format("MMM D, YYYY h:mm:ss a");
  } else {
    return s.toString();
  }
}

export function iso8601DateTime(s: string | Date): string {
  const m = moment(s);
  if (m.isValid()) {
    return m.format();
  } else {
    return s.toString();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Misc

export function multiline(s: string | string[] | List<string> | undefined): JSX.Element | undefined {
  if (s  !== undefined) {
    const lines: string[] = List.isList(s)
      ? s.toArray()
      : typeof s === "string" ? s.split("\n") : s;
    const htmlLines = lines.map((line, index) => (
      <span key={index}>{line}{(index < lines.length - 1) && <br/>}</span>
    ));
    return <span>{htmlLines}</span>;
  }
}

export function prettyPrint(obj: any): string | undefined {
  if (obj === undefined) {
    return undefined;
  } else {
    try {
      return JSON.stringify(obj, null, 2);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

export function camelCaseToWords(s: string): string {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
