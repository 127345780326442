import * as React from "react";
import { Step, StepSubmitHandle } from "../../../containers/step";
import { ConnectionPanelState } from "../../../blocks/connectionPanel/connectionPanelState";
import { ConnectionPanelDefs } from "../../../blocks/connectionPanel/connectionPanelDefs";
import { ConnectionPanel } from "../../../blocks/connectionPanel/connectionPanel";
import { MigrationDetailsPanel, MigrationEstimates } from "../../../blocks/migrationDetailsPanel";
import { connectionStepSettings } from "../connectionStepView/connectionStepSettings";
import { AuthContext } from "../../../blocks/authContext";
import { OperationStatus } from "../../../../types/operationStatus";
import { PricingModelCalculator } from "../../../../types/models/pricingModelCalculator";
import { MaterializedMigrationBlueprint } from "../../../../blueprints/materializedMigrationBlueprint";
import { StickyActionItemsSummaryPanel } from "../../../blocks/stickyActionItemsSummaryPanel";
import { Set } from "immutable";
import { ActionItems } from "../../../models/actionItem";
import { prepareActionItems } from "../../../blocks/connectionPanel/prepareActionItems";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { PricingDetailsTable } from "../../../blocks/pricingDetailsTable";
import { BrandedTitlePanelRow } from "../../../containers/rows/brandedTitlePanelRow";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";

interface Props {
  step: number;
  totalSteps: number;
  onProceed: StepSubmitHandle;
  onNavigateBack?: () => void;

  sourceConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  destinationConnectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  estimates?: MigrationEstimates;
  pricingModelStatus: OperationStatus<PricingModelCalculator>;
  migrationBlocker: MaterializedMigrationBlueprint.MigrationBlocker | undefined;
}

export const PreferencesStepView: React.FunctionComponent<Props> = (props) => {
  const highestPriorityState = ConnectionPanelState.highestPriorityState(
    props.sourceConnectionState.panelState,
    props.destinationConnectionState.panelState
  );

  const state = highestPriorityState.type === ConnectionPanelState.Type.Connected ? undefined : highestPriorityState;

  const appBootstrapConfig = useAppBootstrapConfig();
  const stepSettings = state && connectionStepSettings(
    state === props.sourceConnectionState.panelState
      ? AuthContext.Source
      : AuthContext.Destination,
    props.sourceConnectionState.panelState
      ? props.sourceConnectionState.cloudService.id
      : props.destinationConnectionState.cloudService.id,
    state,
    appBootstrapConfig
  );

  const allSuppressedActionItemsIds =
    (props.sourceConnectionState.actionItemSuppressing?.suppressed || Set())
      .concat((props.destinationConnectionState.actionItemSuppressing?.suppressed || Set()));

  const actionItemsStats = ConnectionPanelState.areas(props.sourceConnectionState.panelState)
    .concat(ConnectionPanelState.areas(props.destinationConnectionState.panelState))
    .map((area) => ConnectionPanelDefs.Area.activeActionItemsStats(area, allSuppressedActionItemsIds))
    .concat(
      [props.sourceConnectionState, props.destinationConnectionState]
        .map((connectionState) =>
          connectionState.panelState.type !== ConnectionPanelState.Type.SigningIn &&
          connectionState.panelState.type !== ConnectionPanelState.Type.ConnectedBadAccount
            ? ActionItems.Stats.buildForActive(
              prepareActionItems({
                subjectId: connectionState.panelState.connectionDetails.connection.id,
                affectedSubject: undefined,
                actionItems: connectionState.panelState.connectionDetails.actionItems,
                availableStorage: connectionState.panelState.connectionDetails.availableStorage
              }),
              allSuppressedActionItemsIds
            )
            : ActionItems.Stats.empty
        )
    )
    .reduce(ActionItems.Stats.add, ActionItems.Stats.empty);

  function renderSubmitDisabledMessage(): string | undefined {
    switch (props.migrationBlocker) {
      case MaterializedMigrationBlueprint.MigrationBlocker.PendingIssues:
        return "Please resolve the issues listed above before proceeding to the next step.";
      case MaterializedMigrationBlueprint.MigrationBlocker.NothingIsSelected:
        return "Please use the checkboxes above to select items for this migration.";
      case MaterializedMigrationBlueprint.MigrationBlocker.ScanningInProgress:
        return "Please wait until scanning of your accounts is completed.";
      case MaterializedMigrationBlueprint.MigrationBlocker.SourceAndDestinationAreTheSame:
        return "Source and destination accounts have to be different.";
      case undefined:
        if (actionItemsStats.totalActionable) {
          return "Please take the actions above before proceeding to the next step.";
        } else {
          return undefined;
        }
    }
  }

  const submitDisabledMessage = renderSubmitDisabledMessage();

  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title={stepSettings ? stepSettings.title : "Migration Preferences"}
      subTitle={stepSettings ? stepSettings.subTitle : "Make sure your migration is set up how you want."}
      submitHidden={stepSettings ? stepSettings.submitHidden : false}
      submitDisabled={!!submitDisabledMessage}
      submitDisabledMessage={submitDisabledMessage}
      onSubmit={props.onProceed}
      onNavigateBack={props.onNavigateBack}
    >
      <StickyActionItemsSummaryPanel stats={actionItemsStats}/>
      <ConnectionPanel
        viewSettings={{
          showConnectionStatusRow: ConnectionPanelDefs.ShowConnectionStatusRow.WithConnectionIssues,
          showSuccessRow: ConnectionPanelDefs.ShowSuccessRow.Never,
          showAreasWithNoIssues: true,
          showProgress: false,
          processDescription: "will be migrated to " + props.destinationConnectionState.cloudService.name,
          showSecurityStatement: true
        }}
        {...props.sourceConnectionState}
      />
      <ConnectionPanel
        viewSettings={{
          showConnectionStatusRow: ConnectionPanelDefs.ShowConnectionStatusRow.WithActionableItemsOrVisibleAreas,
          showSuccessRow: ConnectionPanelDefs.ShowSuccessRow.Never,
          showAreasWithNoIssues: false,
          showProgress: false,
          processDescription: undefined,
          showSecurityStatement: true
        }}
        {...props.destinationConnectionState}
      />
      {
        props.estimates &&
        props.sourceConnectionState.panelState.type === ConnectionPanelState.Type.Connected &&
        props.destinationConnectionState.panelState.type === ConnectionPanelState.Type.Connected && (
          <>
            <MigrationDetailsPanel
              sourceCloudServiceId={props.sourceConnectionState.cloudService.id}
              destinationCloudServiceId={props.destinationConnectionState.cloudService.id}
              estimates={props.estimates}
            />
            <OperationStatusIndicator
              progressMessage={"Calculating price for this migration..."}
              status={props.pricingModelStatus}
              indicators={StatusIndicators.SimplePanel()}
            />
            {props.pricingModelStatus.isSuccess() && props.estimates && (
              <Block>
                <PricingPanel
                  priceCalculator={props.pricingModelStatus.result}
                  estimates={props.estimates}
                />
              </Block>
            )}
          </>
        )
      }
    </Step>
  );
};

interface PricingPanelProps {
  priceCalculator: PricingModelCalculator;
  estimates: MigrationEstimates;
}

const PricingPanel: React.FunctionComponent<PricingPanelProps> = (props) => {
  const priceBeforeDiscounts = props.priceCalculator.priceBeforeDiscounts(
    props.estimates.totalBytes,
    props.estimates.totalItems
  );
  const paymentSpec = props.priceCalculator.makePaymentSpec(priceBeforeDiscounts, []);

  return (
    <Panel>
      <BrandedTitlePanelRow>Order Summary</BrandedTitlePanelRow>
      <GridPanelRow>
        <PricingDetailsTable
          receipt={{
            totalBytes: props.estimates.totalBytes,
            totalItems: props.estimates.totalItems,

            isProgramDiscountApplied: props.priceCalculator.isProgramDiscountApplied,

            priceBeforeDiscounts,
            couponCodeDiscount: undefined,
            referralCodeDiscount: undefined,
            ambassadorStatusDiscount: undefined,
            ambassadorCodeDiscount: undefined,
            priceAfterDiscounts: paymentSpec.priceAfterDiscounts,

            usedSponsorship: paymentSpec.usedSponsorship,
            amountToBePaid: paymentSpec.amountToBePaid
          }}
          pricingDetails={props.priceCalculator.renderBreakdown(props.estimates.totalBytes, props.estimates.totalItems)}
        />
      </GridPanelRow>
    </Panel>
  );
};
