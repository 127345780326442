import { Map } from "immutable";
import { RoutesContext } from "./routesContext";
import * as moment from "moment";

// The routes here are not URLs technically, but it's better not to call them paths to avoid confusion with React
// Router's paths.

export class ApiRoutes {
  private basePath = "/api";

  constructor(private context: RoutesContext) {}

  public migrationReportUrl(migrationId: string): string {
    return this.basePath + "/migrationReport/" + migrationId;
  }

  public batchMigrationReportUrl(batchId: string): string {
    return this.basePath + "/batchMigrationReport/" + batchId;
  }

  public batchScanReportUrl(id: string): string {
    return this.basePath + "/batchScanReport" + this.context.renderSearchParams([["id", id]]);
  }

  // public helpArticleUrl(id: string, vars: Map<string, string>): string {
  //   return this.basePath + "/helpArticle/" + id + this.context.renderSearchParams(vars);
  // }

  public lookupHelpArticleUrl(table: string, vars: Map<string, string>): string {
    return this.basePath + "/lookupHelpArticle/" + table + this.context.renderSearchParams(vars);
  }

  public migrationInvoiceUrl(migrationId: string, customerDetails: string): string {
    return this.basePath + "/invoice/migration/" + migrationId + "/pdf" +
      this.context.renderSearchParams([["customerDetails", customerDetails]]);
  }

  public batchInvoiceUrl(batchId: string, customerDetails: string): string {
    return this.basePath + "/invoice/batch/" + batchId + "/pdf" +
      this.context.renderSearchParams([["customerDetails", customerDetails]]);
  }

  public get uploadUrl(): string {
    return this.basePath + "/uploads";
  }

  public downloadUrl(id: string): string {
    return this.basePath + "/uploads/" + id;
  }

  public accessListImportExportUrl(accessListId: number): string {
    return this.basePath + "/accessLists/" + accessListId;
  }

  public accessListUpdateOutputUrl(accessListUpdateId: number): string {
    return this.basePath + "/accessListUpdates/" + accessListUpdateId;
  }

  public offboardingProjectImportExportUrl(offboardingProjectId: string): string {
    return this.basePath + "/offboardingProjects/" + offboardingProjectId;
  }

  public offboardingProjectUpdateOutputUrl(offboardingProjectUpdateId: string): string {
    return this.basePath + "/offboardingProjectUpdates/" + offboardingProjectUpdateId;
  }

  public migrationsReportUrl(organizationId: number, startDate: Date, endDate: Date): string {
    return this.basePath + "/migrationsReport/" + organizationId +
      "?start=" + encodeURIComponent(moment(startDate).format(ApiRoutes.DateFormat)) +
      "&end=" + encodeURIComponent(moment(endDate).format(ApiRoutes.DateFormat));
  }

  public earningsReportUrl(organizationId: number, startDate: Date, endDate: Date): string {
    return this.basePath + "/earningsReport/" + organizationId +
      "?start=" + encodeURIComponent(moment(startDate).format(ApiRoutes.DateFormat)) +
      "&end=" + encodeURIComponent(moment(endDate).format(ApiRoutes.DateFormat));
  }

  public schoolsReportUrl(pendingReviewOnly: boolean): string {
    return this.basePath + "/schoolsReport?scope=" + (pendingReviewOnly ? "pendingReview" : "all");
  }

  public contestParticipantsReportUrl(startDate: Date, endDate: Date): string {
    return this.basePath + "/contestParticipants" +
      "?start=" + encodeURIComponent(moment(startDate).format(ApiRoutes.DateFormat)) +
      "&end=" + encodeURIComponent(moment(endDate).format(ApiRoutes.DateFormat));
  }
}

export namespace ApiRoutes {
  export const DateFormat = "YYYY-MM-DD";
}
