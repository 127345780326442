import * as React from "react";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const SimpleLayout = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -.2rem -0.3rem;
`;

const SimpleLayoutItem = styled.div`
  margin: .2rem 0.3rem;
`;

const TwoSidedLayout = styled.div<{ centerVertically: boolean; }>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.centerVertically ? "center" : "flex-start"};
`;

interface Props extends StyledComponentsProps {
  items?: React.ReactNode[];
  rightSide?: React.ReactNode;
  centerVertically?: boolean;
}

export const SimpleToolbar: React.FunctionComponent<Props> = (props) => {
  const wrappedChildren = props.items
    ? props.items.map((child, index) => <SimpleLayoutItem key={index}>{child}</SimpleLayoutItem>)
    : React.Children.map(props.children, (child) => <SimpleLayoutItem>{child}</SimpleLayoutItem>);

  if (props.rightSide) {
    return (
      <TwoSidedLayout
        centerVertically={!!props.centerVertically}
        className={props.className}
      >
        <SimpleLayout>{wrappedChildren}</SimpleLayout>
        <SimpleLayout>
          {React.Children.map(props.rightSide, (child) => <SimpleLayoutItem>{child}</SimpleLayoutItem>)}
        </SimpleLayout>
      </TwoSidedLayout>
    );
  } else {
    return <SimpleLayout className={props.className}>{wrappedChildren}</SimpleLayout>;
  }
};
