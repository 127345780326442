import * as moment from "moment";
import * as yup from "yup";
import * as React from "react";
import { Form, Formik } from "formik";
import { FormLayout } from "../widgets/formLayout";
import { DateField } from "../widgets/dateField";
import { FormField } from "../widgets/formField";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { Button } from "../widgets/button";

function startOfMonth(): moment.Moment {
  return moment().startOf("month").startOf("day");
}

function startOfQuarter(): moment.Moment {
  return moment().startOf("quarter").startOf("day");
}

interface FormData {
  startDate: Date;
  endDate: Date;
}

type Result = FormData;

namespace FormData {
  export function makeEmpty(startedDate: Date | undefined): FormData {
    return {
      startDate: startedDate || startOfMonth().toDate(),
      endDate: new Date()
    };
  }

  export function toResult(formData: FormData): Result {
    return formData;
  }

  export function makeValidationSchema(maxDays: number) {
    return yup.object<FormData>()
      .shape({
        startDate: yup.date().required("Start date is required"),
        endDate: yup.date().required("End date is required")
      })
      .test(
        "maxDateRange",
        "Date range must be within " + maxDays + " days",
        (formData: FormData) => {
          const days = moment(formData.endDate).diff(moment(formData.startDate), "day") + 1;
          if (days > maxDays) {
            return new yup.ValidationError("Date range exceeded " + maxDays + " days", undefined, "endDate");
          } else {
            return true;
          }
        }
      );
  }
}

interface Props {
  startDate?: Date;
  maxDays: number;
  submitTitle: string;
  onSubmit: (result: Result) => void;
}

export const ReportSettingsForm: React.FunctionComponent<Props> = (props) => (
  <Formik<FormData>
    initialValues={FormData.makeEmpty(props.startDate)}
    validationSchema={FormData.makeValidationSchema(props.maxDays)}
    validateOnChange={false}
    validateOnBlur={false}
    enableReinitialize={false}
    onSubmit={(data, actions) => {
      props.onSubmit(FormData.toResult(data));
      actions.setSubmitting(false);
    }}
    render={(formProps) => (
      <Form>
        <FormLayout noBottomMargin={true}>
          <DateField<FormData>
            label={"Start date"}
            name={"startDate"}
            required={true}
            selectsStart={true}
            startDate={formProps.values.startDate}
            endDate={formProps.values.endDate}
          />
          <DateField<FormData>
            label={"End date (up to " + props.maxDays + " days from the start date)"}
            name={"endDate"}
            required={true}
            selectsStart={true}
            startDate={formProps.values.startDate}
            endDate={formProps.values.endDate}
            minDate={formProps.values.startDate}
            maxDate={moment(formProps.values.startDate).add(props.maxDays - 1, "days").toDate()}
          />
          <FormField>
            <SimpleToolbar>
              <Button
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  const startDate = moment().startOf("day");
                  formProps.setFieldValue("startDate", startDate.toDate());
                  formProps.setFieldValue("endDate", startDate.endOf("day").toDate());
                }}
              >
                Today
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  const startDate = startOfMonth();
                  formProps.setFieldValue("startDate", startDate.toDate());
                  formProps.setFieldValue("endDate", startDate.endOf("month").toDate());
                }}
              >
                This Month
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  const startDate = startOfMonth().subtract(1, "month");
                  formProps.setFieldValue("startDate", startDate.toDate());
                  formProps.setFieldValue("endDate", startDate.endOf("month").toDate());
                }}
              >
                Last Month
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  const startDate = startOfQuarter();
                  formProps.setFieldValue("startDate", startDate.toDate());
                  formProps.setFieldValue("endDate", startDate.endOf("quarter").toDate());
                }}
              >
                This Quarter
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  const startDate = startOfMonth().subtract(1, "quarter");
                  formProps.setFieldValue("startDate", startDate.toDate());
                  formProps.setFieldValue("endDate", startDate.endOf("quarter").toDate());
                }}
              >
                Last Quarter
              </Button>
            </SimpleToolbar>
          </FormField>
          <FormField>
            <Button type={"submit"} size={"small"}>{props.submitTitle}</Button>
          </FormField>
        </FormLayout>
      </Form>
    )}
  />
);
