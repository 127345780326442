import * as React from "react";
import { styled } from "../../../../app/theme";
import { Panel } from "../../../containers/panel";
import { CheckoutStepDefs } from "./checkoutStepDefs";
import { Block } from "../../../containers/block";
import { PricingDetailsTable } from "../../../blocks/pricingDetailsTable";
import { PanelRow } from "../../../containers/rows/panelRow";
import { BrandedTitlePanelRow } from "../../../containers/rows/brandedTitlePanelRow";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";

const StyledPanelRow = styled(PanelRow)`
  &:last-of-type {
    border-top-color: ${(props) => props.theme.colors.primary};
  }
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  border-right: 1px solid #dadcde;
  padding: 1.1rem 1rem 1.1rem 0;
  align-self: stretch;
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  width: 8rem;
  flex-shrink: 0;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 3.5rem;
    padding: 1rem;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: .5rem 1.5rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: .4rem 0.75rem;
  }
`;

interface BreakdownRowProps {
  title: string;
  children?: any;
  helpArticle?: PreparedHelpArticle;
}

export const BreakdownRow: React.FunctionComponent<BreakdownRowProps> = (props) => {
  return (
    <StyledPanelRow size="custom" helpArticle={props.helpArticle}>
      <Layout>
        <Title>{props.title}</Title>
        <Content>{props.children}</Content>
      </Layout>
    </StyledPanelRow>
  );
};

export interface PriceBreakdownPanelProps {
  priceInfo: CheckoutStepDefs.PriceInfo;
  additionalRows?: JSX.Element;
}

export const PriceBreakdownPanel: React.FunctionComponent<PriceBreakdownPanelProps> = (props) => (
  <Block>
    <Panel>
      <BrandedTitlePanelRow>Order Summary</BrandedTitlePanelRow>
      {props.priceInfo.pricingDetails && props.priceInfo.pricingDetails.length !== 0 && (
        <GridPanelRow>
          <PricingDetailsTable
            receipt={props.priceInfo.receipt}
            pricingDetails={props.priceInfo.pricingDetails}
          />
        </GridPanelRow>
      )}
    </Panel>
  </Block>
);
