import { Map, Set } from "immutable";
import { None, Option, Some } from "../utils/monads/option";
import { GraphQL } from "../services/graphql/generated";

export class ItemBreakdown {
  constructor(public readonly entries: Map<string, Tagged>) {
  }

  public add(itemType: string, count: number): ItemBreakdown {
    return this.addTagged(itemType, Set(), count);
  }

  public addTagged(itemType: string, tags: Set<string>, count: number): ItemBreakdown {
    return new ItemBreakdown(
      this.entries.set(itemType, (this.entries.get(itemType) || Tagged.empty).add(tags, count))
    );
  }

  public getTotal(itemType: string): Option<number> {
    return Option.mayBe(this.entries.get(itemType)).map((tagged) => tagged.sum());
  }

  public getTagged(itemType: string, tags: Set<string>): Option<number> {
    return Option.mayBe(this.entries.get(itemType)).map((tagged) => tagged.get(tags));
  }

  public getUntagged(itemType: string): Option<number> {
    return this.getTagged(itemType, Set());
  }

  public get(itemType: string, tag: string, ...tags: string[]): Option<number> {
    return this.getTagged(itemType, Set([tag]).union(Set(tags)));
  }

  public addOther(other: ItemBreakdown): ItemBreakdown {
    const itemTypes = this.itemTypes().union(other.itemTypes());
    return new ItemBreakdown(
      Map(
        itemTypes.map((itemType) => [
          itemType,
          (this.entries.get(itemType) || Tagged.empty).addOther(other.entries.get(itemType) || Tagged.empty)
        ])
      )
    );
  }

  public max(other: ItemBreakdown): ItemBreakdown {
    const itemTypes = this.itemTypes().union(other.itemTypes());
    return new ItemBreakdown(
      Map(
        itemTypes.map((itemType) => [
          itemType,
          (this.entries.get(itemType) || Tagged.empty).max(other.entries.get(itemType) || Tagged.empty)
        ])
      )
    );
  }

  public compare(other: ItemBreakdown): Option<number> {
    const commonItemTypes = this.itemTypes().intersect(other.itemTypes());
    if (!commonItemTypes.isEmpty()) {
      const diff = commonItemTypes.map((itemType) =>
        this.getTotal(itemType).getOrElse(() => 0) - other.getTotal(itemType).getOrElse(() => 0)
      );
      const minDiff = diff.min() || 0;
      const maxDiff = diff.max() || 0;
      if (minDiff === 0 && maxDiff === 0) {
        return Some(0);
      } else if (maxDiff <= 0) {
        return Some(-1);
      } else if (minDiff >= 0) {
        return Some(1);
      } else {
        return None();
      }
    } else {
      return None();
    }
  }

  public sum(): number {
    return this.entries.valueSeq()
      .map((tagged) => tagged.sum())
      .reduce((a, b) => a + b, 0);
  }

  public toJSON(): any {
    return this.entries.toJSON();
  }

  protected itemTypes(): Set<string> {
    return this.entries.keySeq().toSet();
  }
}

export namespace ItemBreakdown {
  export function fromGraphQL(itemBreakdown: GraphQL.ItemBreakdown): ItemBreakdown {
    return new ItemBreakdown(
      Map(
        itemBreakdown.entries.map((entry) => [entry.itemType, Tagged.fromGraphQL(entry.taggedEntries)])
      )
    );
  }

  export function add(a: ItemBreakdown, b: ItemBreakdown): ItemBreakdown {
    return a.addOther(b);
  }

  export function compare(a: ItemBreakdown, b: ItemBreakdown): Option<number> {
    return a.compare(b);
  }
}

class Tagged {
  constructor(public readonly values: Map<Set<string>, number>) {
  }

  public sum(): number {
    return this.values.valueSeq().reduce((a, b) => a + b, 0);
  }

  public add(tags: Set<string>, count: number): Tagged {
    return new Tagged(this.values.set(tags, (this.values.get(tags) || 0) + count));
  }

  public get(tags: Set<string>): number {
    if (tags.isEmpty()) {
      return this.values.get(Set()) || 0;
    } else {
      return this.values
        .filter((_, entryTags) => tags.isSubset(entryTags))
        .valueSeq()
        .reduce((a, b) => a + b, 0);
    }
  }

  public addOther(other: Tagged): Tagged {
    const tagSets = this.values.keySeq().toSet().union(other.values.keySeq().toSet());
    return new Tagged(
      Map(
        tagSets.map((tags) => [tags, (this.values.get(tags) || 0) + (other.values.get(tags) || 0)])
      )
    );
  }

  public max(other: Tagged): Tagged {
    return this.sum() >= other.sum() ? this : other;
  }

  public compare(other: Tagged): number {
    const thisSum = this.sum();
    const otherSum = other.sum();
    return thisSum < otherSum ? -1 : thisSum > otherSum ? 1 : 0;
  }

  public toJSON(): any {
    return this.values.toArray().map(([tags, count]) => ({
      tags: tags.toJS().sort(),
      count
    }));
  }
}

namespace Tagged {
  export const empty: Tagged = new Tagged(Map());

  export function fromGraphQL(taggedEntries: GraphQL.ItemBreakdownTaggedEntry[]): Tagged {
    return new Tagged(Map(taggedEntries.map((tagged) => [Set(tagged.tags), tagged.count])));
  }
}
