import * as React from "react";
import { Page } from "../../../containers/page";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Form, Formik } from "formik";
import { FormLayout } from "../../../widgets/formLayout";
import { TextAreaField } from "../../../widgets/textAreaField";
import { Panel } from "../../../containers/panel";
import { Block } from "../../../containers/block";
import { PanelRow } from "../../../containers/rows/panelRow";
import * as yup from "yup";
import { Button } from "../../../widgets/button";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { Grid } from "../../../widgets/grid";
import { friendlyCount } from "../../../../utils/formatting";
import { Link } from "react-router-dom";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { useNavigate } from "react-router";
import { OrganizationPortalUserRole } from "../../../../types/enums/organizationPortalUserRole";
import { List } from "immutable";
import { OrganizationPortalUser } from "../../../../types/models/organizationPortalUser";
import { DropDownField } from "../../../widgets/dropDownField";
import { HeaderBlock } from "../../../widgets/headerBlock";

interface BulkInsertOrganizationPortalUsersPageViewProps {
  homePath: string;
  status: OperationStatus<List<OrganizationPortalUser>>;
  onInsert: (emailAddresses: List<string>, role: OrganizationPortalUserRole) => Promise<List<OrganizationPortalUser>>;
}

export const BulkInsertOrganizationPortalUsersPageView:
  React.FunctionComponent<BulkInsertOrganizationPortalUsersPageViewProps> = (props) => (
  <>
    <HeaderBlock
      breadcrumbs={[<Link key={0} to={props.homePath}>Users</Link>, "Add In Bulk"]}
      title={"Add Users In Bulk"}
    />
    {props.status.isSuccess() && (
      <Block>
        <Panel>
          <PanelRow>
            Added {friendlyCount(props.status.result.count(), "user")}
          </PanelRow>
          <GridPanelRow>
            <Grid>
              <Grid.Header>
                <Grid.Column nowrap={true}>Email Address</Grid.Column>
                <Grid.Column>Result</Grid.Column>
              </Grid.Header>
              <Grid.Body>
                {
                  props.status.result.map((result, index) => (
                    <Grid.Row key={index}>
                      <Grid.Cell style={{ width: "1%" }}>{result.emailAddress}</Grid.Cell>
                      <Grid.Cell>Added</Grid.Cell>
                    </Grid.Row>
                  ))
                }
              </Grid.Body>
            </Grid>
          </GridPanelRow>
        </Panel>
      </Block>
    )}
    <InsertForm
      homePath={props.homePath}
      status={props.status}
      onSubmit={(result) =>
        props.onInsert(
          List(result.emailAddresses.split("\n"))
            .map((emailAddress) => emailAddress.toLowerCase().trim())
            .filter((emailAddress) => emailAddress.length !== 0),
          result.role
        )
      }
    />
  </>
);

interface FormData {
  emailAddresses: string;
  role: OrganizationPortalUserRole;
}

interface Result {
  emailAddresses: string;
  role: OrganizationPortalUserRole;
}

namespace FormData {
  export const empty: FormData = {
    emailAddresses: "",
    role: OrganizationPortalUserRole.ReadAccess
  };

  export function toResult(formData: FormData): Result {
    return {
      emailAddresses: formData.emailAddresses,
      role: formData.role
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    emailAddresses: yup.string().required("Email addresses are required"),
    role: yup.mixed().oneOf(OrganizationPortalUserRole.all),
  });
}

interface InsertFormProps {
  homePath: string;
  status: OperationStatus<any>;
  onSubmit: (result: Result) => Promise<any>;
}

const InsertForm: React.FunctionComponent<InsertFormProps> = (props) => {
  const navigate = useNavigate();

  const firstInputRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []
  );

  const submitting = props.status.isWorking();

  return (
    <Formik<FormData>
      initialValues={FormData.empty}
      validationSchema={FormData.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={false}
      onSubmit={(data, actions) => {
        props.onSubmit(FormData.toResult(data));
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form>
          <Block>
            <Panel>
              <OperationStatusIndicator
                progressMessage={"Adding users..."}
                failureMessage={"Failed to add users"}
                status={props.status}
                indicators={StatusIndicators.PanelRow()}
              />
              <PanelRow>
                <FormLayout>
                  <TextAreaField<FormData>
                    textBoxRef={firstInputRef}
                    label={"Email addresses (one per line)"}
                    name="emailAddresses"
                    disabled={submitting}
                    required={true}
                    rows={20}
                  />
                </FormLayout>
                <DropDownField<FormData>
                  label={"Role"}
                  name={"role"}
                  disabled={submitting}
                >
                  <option value={OrganizationPortalUserRole.ReadAccess}>
                    {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.ReadAccess)}
                  </option>
                  <option value={OrganizationPortalUserRole.ReadAndWriteAccess}>
                    {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.ReadAndWriteAccess)}
                  </option>
                  <option value={OrganizationPortalUserRole.Administrator}>
                    {OrganizationPortalUserRole.userFriendly(OrganizationPortalUserRole.Administrator)}
                  </option>
                </DropDownField>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <SimpleToolbar>
              <Button type={"submit"} size={"small"} disabled={formProps.isSubmitting || submitting}>
                Add
              </Button>
              <Button
                size={"small"}
                color={"secondary"}
                disabled={formProps.isSubmitting || submitting}
                onClick={() => navigate(props.homePath)}
              >
                Close
              </Button>
            </SimpleToolbar>
          </Block>
        </Form>
      )}
    />
  );
};
