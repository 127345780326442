import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { CouponCode } from "../../types/models/couponCode";
import { nullToUndefined } from "../../utils/misc";

export function useGetCouponCodeQuery(id: string): ManagedQueryHook<GraphQL.GetCouponCodeQueryVariables, CouponCode> {
  return useManagedQuery({
    query: GraphQL.useGetCouponCodeQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetCouponCodeQuery) => nullToUndefined(data.couponCode),
    complete: (couponCode) => CouponCode.parse(couponCode),
    fetchPolicy: "cache-and-network"
  });
}
