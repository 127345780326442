import { ConnectionPanelState } from "../../../blocks/connectionPanel/connectionPanelState";
import { AuthContext } from "../../../blocks/authContext";
import { Constants } from "../../../../app/constants";
import { AppBootstrapConfig } from "../../../../types/models/appBootstrapConfig";

export interface ConnectionStepSettings {
  title: string;
  subTitle: string;
  submitHidden?: boolean;
}

export function connectionStepSettings(
  authContext: AuthContext,
  cloudServiceId: string,
  panelState: ConnectionPanelState.Any,
  appBootstrapConfig: AppBootstrapConfig
): ConnectionStepSettings {
  switch (panelState.type) {
    case ConnectionPanelState.Type.Scanning:
      return {
        title: AuthContext.scanningOrTesting(authContext) + " " +
          AuthContext.toString(authContext, appBootstrapConfig) + "...",
        subTitle: `Please be patient as ${Constants.Product.name} ` + AuthContext.choose(
          authContext,
          "maps the items in your account.",
          "securely tests your account to make sure it can accept migration items."
        )
      };

    case ConnectionPanelState.Type.ScanningError:
      return {
        title: "Error while " + AuthContext.scanningOrTesting(authContext) + "...",
        subTitle: AuthContext.choose(
          authContext,
          "This scan is paused.",
          "Connection to this account is paused."
        )
      };

    case ConnectionPanelState.Type.Connected:
      return {
        title: AuthContext.toString(authContext, appBootstrapConfig),
        subTitle: `${Constants.Product.name} is securely connected to this account.`
      };

    default:
      return {
        title: "Connect Your " + AuthContext.toString(authContext, appBootstrapConfig),
        subTitle: AuthContext.choose(
          authContext,
          "Connect VaultMe to your " + appBootstrapConfig.sourceCloudServiceReference(cloudServiceId)  +
          " so that it can count your items.",
          "Connect VaultMe to the " + appBootstrapConfig.destinationCloudServiceReference(cloudServiceId) +
          " you want to migrate to."
        ),
        submitHidden: true
      };
  }
}
