import * as React from "react";
import { styled } from "../../app/theme";
import { SimplePanel } from "../containers/simplePanel";
import { Page } from "../containers/page";
import { Button, MultiLineButtonTitle } from "../widgets/button";
import { Block } from "../containers/block";
import { Constants } from "../../app/constants";
import { useAppBootstrapConfig } from "../../app/configuration";

const Intro = styled.div`
  font-size: 1.1rem; 
`;

const Question = styled.div`
  font-size: 1.75rem;
  padding: 0.5rem 0 0 0;
`;

const TextArea = styled.div`
  border-bottom: 1px solid #079fef;
  border-top: none;
  border-left: none;
  border-right: none;
  background: white;
  font-size: 1.5rem;
  font-weight: 300;
  color: #052535;
  padding: 1rem 0.5rem 0.5rem;
  width: 95%;
  min-height: 3rem;
  max-height: 12rem;
  overflow: auto;
  
  &:focus {
    outline: none;
  }

  &[contenteditable=true]:empty:before  {
    content: attr(placeholder);
    color: ${(props) => props.theme.colors.primary};
    display: block;
  }  
`;

export interface Props {
  migrationId: string;
  onSubmit: (question: string, answer: string) => void;
}

export const FeedbackPageView: React.FunctionComponent<Props> = (props) => (
  <Page
    title="Thank You! Your Migration Has Begun."
    subTitle={
      "An email confirmation has been sent to your DESTINATION ACCOUNT email address. " +
      "Another email will be sent when your migration is complete."
    }
  >
    <Form onSubmit={props.onSubmit}/>
  </Page>
);

interface FormProps {
  onSubmit: (question: string, answer: string) => void;
}

const Form: React.FunctionComponent<FormProps> = (props) => {
  const textAreaRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(
    () => {
      window.setTimeout(
        () => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          } else {
            console.log("Text area ref is empty");
          }
        },
        0);
    },
    []
  );

  const appBootstrapConfig = useAppBootstrapConfig();
  const question = appBootstrapConfig.configuration?.feedbackQuestion || Constants.DefaultFeedbackQuestion;

  return (
    <>
      <SimplePanel>
        <Intro>Before you proceed, please tell us one thing...</Intro>
        <Question>{question}</Question>
        <TextArea
          ref={textAreaRef}
          contentEditable={true}
          placeholder="Type your answer here..."
        />
      </SimplePanel>
      <Block>
        <Button
          color="blue"
          onClick={() => textAreaRef.current && props.onSubmit(question, textAreaRef.current.innerText.trim())}
        >
          <MultiLineButtonTitle>
            <div>Go to Migration&nbsp;</div>
            <div>Progress Page</div>
          </MultiLineButtonTitle>
        </Button>
      </Block>
    </>
  );
};
