import { List } from "immutable";
import { Migration } from "./migration";
import { Batch } from "./batch";

export interface UserMigrations {
  migrations: List<Migration & Migration.HasConnections>;
  batches: List<Batch>;
  isEmpty: boolean;
}

export namespace UserMigrations {
  export function build(
    migrations: List<Migration & Migration.HasConnections>,
    batches: List<Batch>
  ): UserMigrations {
    return {
      migrations,
      batches,
      isEmpty: migrations.isEmpty() && batches.isEmpty()
    };
  }
}
