import * as React from "react";
import { List, Set } from "immutable";
import { SearchPageView } from "../../views/blocks/crud/searchPageView";
import { CRUDConfig } from "./crudConfig";

interface Props<Id, Item> {
  config: CRUDConfig<Id, Item, any>;

  transientItems: List<Item>;
  newPath: string;

  onSearch: (searchTerm: string) => void;
  onSelect: (id: Id, item: Item) => void;
  onReset: (() => void) | undefined;
  onListAll: (() => void) | undefined;
}

export function ListPage<Id, Item>(props: Props<Id, Item>): React.ReactElement {
  const [searchStatus, refresh] = props.config.useSearchQuery("");

  const queryItemIds =
    searchStatus.mapLastResult((items) => items.map(props.config.getItemId).toSet()) || Set();
  const transientItems =
    props.transientItems.filter((item) => !queryItemIds.contains(props.config.getItemId(item)));

  return (
    <SearchPageView<Id, Item>
      breadcrumbs={props.config.breadcrumbs ? props.config.breadcrumbs.concat([props.config.title]) : undefined}
      title={props.config.title}
      subject={props.config.subject}
      readOnly={!!props.config.readOnly}
      layout={props.config.layout}
      gridColumns={props.config.gridColumns}
      additionalTools={props.config.additionalTools}
      topChildren={props.config.topChildren}
      bottomChildren={props.config.bottomChildren}

      getItemId={props.config.getItemId}
      compare={props.config.compare}

      searchTerm={""}
      searchStatus={searchStatus}
      transientItems={transientItems}
      newPath={props.newPath}

      onSearch={props.onSearch}
      onRefresh={refresh}
      onReset={props.onReset}
      onListAll={props.onListAll}
      onSelect={props.onSelect}
    />
  );
}
