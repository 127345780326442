import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { AvailableJobStatuses } from "../../types/models/availableJobStatuses";

export function useAvailableJobStatuses(
  jobId: string,
): ManagedQueryHook<GraphQL.GetAvailableJobStatusesQueryVariables, AvailableJobStatuses> {
  return useManagedQuery({
    query: GraphQL.useGetAvailableJobStatusesQuery,
    deps: null,
    prepare: () => ({ jobId }),
    extract: (data: GraphQL.GetAvailableJobStatusesQuery) => data.getAvailableJobStatuses,
    complete: AvailableJobStatuses.fromGraphQL,
    fetchPolicy: "no-cache", // See comments for useConnection
  });
}
