import { GraphQL } from "../../services/graphql/generated";
import { InternalJobStatus } from "./internalJobStatus";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { TaskResultType } from "./taskResultType";
import { TimelineTooltip } from "./timelineTooltip";
import { preciseTimestamp } from "../../utils/formatting";

export class TaskSummaryRecord implements TaskSummaryRecord.Props {
  constructor(protected readonly props: TaskSummaryRecord.Props) {
  }

  public get jobId(): string { return this.props.jobId; }
  public get taskId(): string { return this.props.taskId; }
  public get transition(): number { return this.props.transition; }

  public get type(): string { return this.props.type; }
  public get internalStatus(): InternalJobStatus { return this.props.internalStatus; }

  public get resultType(): TaskResultType | undefined { return this.props.resultType; }

  public get createdAt(): Date { return this.props.createdAt; }
  public get completedAt(): Date | undefined { return this.props.completedAt; }
  public get archivedAt(): Date | undefined { return this.props.archivedAt; }

  public toEvents(): List<TaskSummaryRecord.Event> {
    const started = new TaskSummaryRecord.Event({
      jobId: this.jobId,
      taskId: this.taskId,
      transition: this.transition,

      type: this.type,

      eventType: TaskSummaryRecord.Event.Type.Started,
      resultType: undefined,
      timestamp: this.createdAt,
      taskIsRunning: !this.archivedAt
    });

    if (this.archivedAt) {
      return List([
        started,
        new TaskSummaryRecord.Event({
          jobId: this.jobId,
          taskId: this.taskId,
          transition: this.transition,

          type: this.type,

          eventType: TaskSummaryRecord.Event.Type.Ended,
          resultType: this.resultType,
          timestamp: this.archivedAt,
          taskIsRunning: false
        })
      ]);

    } else {
      return List([started]);
    }
  }

  public timelineTooltip(): TimelineTooltip {
    return {
      summary: "Task \"" + this.taskId + "\"",
      props: [
        ["Type", this.type],
        ["Transition", "" + this.transition],
        ["Started at", preciseTimestamp(this.createdAt)],
        ["Completed at", this.completedAt ? preciseTimestamp(this.completedAt) : "--"],
        ["Archived at", this.archivedAt ? preciseTimestamp(this.archivedAt) : "--"],
        ["Result type", this.resultType || "--"],
      ]
    };
  }
}

export namespace TaskSummaryRecord {
  export interface Props {
    readonly jobId: string;
    readonly taskId: string;
    readonly transition: number;

    readonly type: string;
    readonly internalStatus: InternalJobStatus;

    readonly resultType: TaskResultType | undefined;

    readonly createdAt: Date;
    readonly completedAt: Date | undefined;
    readonly archivedAt: Date | undefined;
  }

  export function fromGraphQL(taskSummaryRecord: GraphQL.TaskSummaryRecord): TaskSummaryRecord {
    return new TaskSummaryRecord({
      jobId: taskSummaryRecord.jobId,
      taskId: taskSummaryRecord.taskId,
      transition: taskSummaryRecord.transition,

      type: taskSummaryRecord.type,
      internalStatus: taskSummaryRecord.internalStatus,

      resultType: nullToUndefined(taskSummaryRecord.resultType),

      createdAt: new Date(taskSummaryRecord.createdAt),
      completedAt: mapOptional(taskSummaryRecord.completedAt, (value) => new Date(value)),
      archivedAt: mapOptional(taskSummaryRecord.archivedAt, (value) => new Date(value))
    });
  }

  export class Event implements Event.EventProps {
    constructor(protected readonly props: Event.EventProps) {
    }

    public get jobId(): string { return this.props.jobId; }
    public get taskId(): string { return this.props.taskId; }
    public get transition(): number { return this.props.transition; }

    public get type(): string { return this.props.type; }

    public get eventType(): Event.Type { return this.props.eventType; }
    public get resultType(): TaskResultType | undefined { return this.props.resultType; }
    public get timestamp(): Date { return this.props.timestamp; }
    public get taskIsRunning(): boolean { return this.props.taskIsRunning; }

    public get key(): string { return this.taskId + "/" + this.transition + "/" + this.eventType; }
  }

  export namespace Event {
    export enum Type {
      Started = "Started",
      Ended = "Ended",
    }

    export interface EventProps {
      readonly jobId: string;
      readonly taskId: string;
      readonly transition: number;

      readonly type: string;

      readonly eventType: Event.Type;
      readonly resultType: TaskResultType | undefined;
      readonly timestamp: Date;
      readonly taskIsRunning: boolean;
    }
  }
}
