import * as React from "react";
import { Page } from "../containers/page";
import { Block } from "../containers/block";
import { Panel } from "../containers/panel";
import { PanelRow } from "../containers/rows/panelRow";
import { Button } from "../widgets/button";
import { ModalController, useModal } from "../layouts/modal";
import { OperationStatus } from "../../types/operationStatus";
import { DrawerBlock } from "../containers/drawerBlock";
import { StatusIndicators } from "../utils/statusIndicators";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { Paragraph } from "../widgets/paragraph";
import { Connections } from "types/models/connections";
import { ConnectionIdAndIcon } from "../blocks/connectionManagement/cloudServiceNameAndIcon";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { Option } from "../widgets/option";
import { User } from "../../types/models/user";

export interface UpgradeAccountToolProps {
  connectionsStatus: OperationStatus<Connections>;
  upgradeUserStatus: OperationStatus<User>;
  onSubmit: (connectionId: string) => Promise<any>;
}

export interface UpgradeAccountToolControllerProps {
  render: (viewProps: UpgradeAccountToolProps) => React.ReactElement;
}

export type UpgradeAccountToolControllerType = React.ComponentType<UpgradeAccountToolControllerProps>;

interface Props {
  upgradeAccountToolController: UpgradeAccountToolControllerType;
}

export const UpgradeAccountPageView: React.FunctionComponent<Props> = (props) => {
  const modal = useModal();

  return (
    <Page
      title={"Business account required"}
      subTitle={"The feature that you are trying to access is only available to the business users"}
    >
      <ModalController
        modal={modal}
        render={(close) => React.createElement(props.upgradeAccountToolController, {
          render: (viewProps) => (
            <UpgradeAccountTool
              connectionsStatus={viewProps.connectionsStatus}
              upgradeUserStatus={viewProps.upgradeUserStatus}
              onSubmit={(connectionId) => viewProps.onSubmit(connectionId).then(close)}
            />
          )
        })}
      />

      <Block>
        <Panel>
          <PanelRow>
            <Paragraph>
              This feature requires a business VaultMe account, but you are currently signed in to a personal account.
            </Paragraph>
            <Paragraph>
              Click the button below to upgrade your personal account to a business account. This will preserve all
              your existing settings and migrations.
            </Paragraph>
            <Paragraph>
              Alternatively, sign out from your personal account using the "Sign out" link at the top of the page,
              and then sign in again using an existing business VaultMe account or create a new one.
            </Paragraph>
          </PanelRow>
          <PanelRow>
            <Button size={"narrow"} onClick={modal.open}>Upgrade To Business Account</Button>
          </PanelRow>
        </Panel>
      </Block>
    </Page>
  );
};

const UpgradeAccountTool: React.FunctionComponent<UpgradeAccountToolProps> = (props) => {
  const [connectionId, setConnectionId] = React.useState<string>();

  return (
    <>
      <OperationStatusIndicator
        subject={"connected account"}
        status={props.connectionsStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.connectionsStatus.isSuccess() && (
        <>
          <DrawerBlock>
            <Panel>
              <PanelRow>
                <Paragraph>
                  Please select the email address that you will use for signing in to your business VaultMe account.
                  All other accounts will stay connected, but you will not be able to use them for accessing your
                  VaultMe business account.
                </Paragraph>
                <Paragraph>
                  Important: please make sure that the selected account is exclusively owned by you and is not used
                  by any other person in your organization.
                </Paragraph>
              </PanelRow>
            </Panel>
          </DrawerBlock>
          <DrawerBlock>
            <Panel>
              <GridPanelRow>
                {
                  props.connectionsStatus.result.list()
                    .sortBy((connection) => connection.descriptionOrId())
                    .map((connection) => (
                      <Option
                        key={connection.id}
                        name={connection.id}
                        value={connection.id}
                        checked={connection.id === connectionId}
                        onChange={() => setConnectionId(connection.id)}
                        disabled={props.upgradeUserStatus.isWorking()}
                      >
                        <ConnectionIdAndIcon connection={connection}/>
                      </Option>
                    ))
                }
              </GridPanelRow>
              <OperationStatusIndicator
                progressMessage={"Upgrading account..."}
                failureMessage={"Failed to upgrade account"}
                status={props.upgradeUserStatus}
                indicators={StatusIndicators.PanelRow()}
              />
              <PanelRow>
                <Button
                  onClick={() => connectionId && props.onSubmit(connectionId)}
                  disabled={!connectionId || props.upgradeUserStatus.isWorking()}
                >
                  Select And Upgrade
                </Button>
              </PanelRow>
            </Panel>
          </DrawerBlock>
        </>
      )}
    </>
  );
};
