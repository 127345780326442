import { GraphQL } from "../../services/graphql/generated";

export interface EmailPreview {
  readonly from: string;
  readonly to: string;
  readonly subject: string;
  readonly body: string;
}

export namespace EmailPreview {
  export function parse(data: GraphQL.EmailPreviewFragment): EmailPreview {
    return data;
  }
}
