import * as React from "react";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { Constants } from "../../../app/constants";
import { WidgetStatus } from "../../utils/widgetStatus";

export const ViewOnlyWarningPanel: React.FunctionComponent = () => (
  <Block>
    <Panel>
      <PanelRow status={WidgetStatus.Info}>
        The information on this page is view-only. If you need to make changes, please send an email to our
        EDU services team at{" "}
        <a href={"mailto:" + Constants.Product.eduServicesEmail} style={{ whiteSpace: "nowrap" }}>
          {Constants.Product.eduServicesEmail}
        </a>
      </PanelRow>
    </Panel>
  </Block>
);
