export enum MigrationStatus {
  Scheduled = "Scheduled",

  Starting = "Starting",
  Running = "Running",
  Pausing = "Pausing",
  Paused = "Paused",

  WaitingForTemporaryCondition = "WaitingForTemporaryCondition",
  WaitingForUserAction = "WaitingForUserAction",
  WaitingForManualReview = "WaitingForManualReview",
  StoppedForUnknownReason = "StoppedForUnknownReason",

  Completed = "Completed",
  Aborted = "Aborted"
}

export namespace MigrationStatus {
  export function userFriendly(status: MigrationStatus, iteration: number): string {
    if (status !== MigrationStatus.Completed && iteration > 0) {
      return "Syncing up";
    } else if (
      status === MigrationStatus.WaitingForTemporaryCondition ||
      status === MigrationStatus.WaitingForUserAction ||
      status === MigrationStatus.WaitingForManualReview ||
      status === MigrationStatus.StoppedForUnknownReason
    ) {
      return "Temporarily stopped";
    } else if (status === MigrationStatus.Scheduled) {
      return "Ready";
    } else if (status === MigrationStatus.Pausing) {
      return "Pausing";
    } else if (status === MigrationStatus.Paused) {
      return "Paused";
    } else {
      return status;
    }
  }

  export function adminFriendly(status: MigrationStatus, iteration: number): string {
    if (status !== MigrationStatus.Completed && iteration > 0) {
      return "Syncing up";
    } else if (status === MigrationStatus.WaitingForTemporaryCondition) {
      return "Waiting for temporary condition";
    } else if (status === MigrationStatus.WaitingForUserAction) {
      return "Waiting for user action";
    } else if (status === MigrationStatus.WaitingForManualReview) {
      return "Waiting for manual review";
    } else if (status === MigrationStatus.StoppedForUnknownReason) {
      return "Stopped for unknown reason";
    } else if (status === MigrationStatus.Scheduled) {
      return "Ready";
    } else if (status === MigrationStatus.Pausing) {
      return "Paused by customer (suspending)";
    } else if (status === MigrationStatus.Paused) {
      return "Paused by customer";
    } else {
      return status;
    }
  }
}
