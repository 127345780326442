import { GraphQL } from "../../services/graphql/generated";
import { OrganizationPortalUserRole } from "../enums/organizationPortalUserRole";

export interface OrganizationPortalUserFormData {
  readonly emailAddress: string;
  readonly role: OrganizationPortalUserRole;

  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
}

export namespace OrganizationPortalUserFormData {
  export function toGraphQL(data: OrganizationPortalUserFormData): GraphQL.OrganizationPortalUserFormData {
    return {
      emailAddress: data.emailAddress,
      role: data.role,

      firstName: data.firstName,
      lastName: data.lastName
    };
  }
}
