import * as React from "react";

// From here: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<T>();

  // Store current value in ref
  React.useEffect(
    () => {
      ref.current = value;
    },
    [value]
  ); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
