import * as React from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { initStore } from "./store";

import { HashRouter } from "react-router-dom";
import { AppRoutes } from "./routes/appRoutes";

const store = initStore();

const App = () => (
  <Provider store={store}>
    {/* Make sure to review hrefFromPath function in the case of changes in the router */}
    <HashRouter>
      <AppRoutes/>
    </HashRouter>
  </Provider>
);

export const HotApp = hot(module)(App);
