import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { EmailPreview } from "../../types/models/emailPreview";

type RenderOffboardingEmailPreviewMutationHook = [
  (round: number) => Promise<EmailPreview>,
  OperationStatus<EmailPreview>
];

export function useRenderOffboardingEmailPreviewMutation(
  offboardingProjectId: string
): RenderOffboardingEmailPreviewMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useRenderOffboardingEmailPreviewMutation,
    extract: (data: GraphQL.RenderOffboardingEmailPreviewMutation) =>
      nullToUndefined(data.renderOffboardingEmailPreview),
    complete: EmailPreview.parse
  });

  function fireWith(round: number): Promise<EmailPreview> {
    return fire({
      variables: { offboardingProjectId, round },
      retry: () => fireWith(round),
    });
  }

  return [fireWith, status];
}
