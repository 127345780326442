import * as React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { List, Map } from "immutable";
import { SearchPage } from "./searchPage";
import { CRUDConfig } from "./crudConfig";
import { EditItemPage } from "./editItemPage";
import { NewItemPage } from "./newItemPage";
import { ListPage } from "./listPage";
import { OverviewItemPage } from "./overviewItemPage";

export function CrudPages<Id, Item, FormData>(props: CRUDConfig<Id, Item, FormData>): React.ReactElement {
  const navigate = useNavigate();
  const routes = props.useRoutes();

  const searchTermRef = React.useRef<string | undefined>(undefined);
  const [transientItems, setTransientItems] = React.useState<Map<Id, Item>>(Map());

  const homePath = routes.searchPath(searchTermRef.current);

  function overviewPath(id: Id): string {
    return props.renderOverview ? routes.overviewPath(id) : homePath;
  }

  function handleSelect(id: Id): void {
    navigate(props.renderOverview ? routes.overviewPath(id) : routes.editPath(id));
  }

  function handleCloning(item: Item): void {
    navigate(routes.newPath, { state: routes.newState(props.clone(item)) });
  }

  function handleUpdate(item: Item, continueEditing: boolean): void {
    const id = props.getItemId(item);

    setTransientItems((value) => value.set(id, item));

    if (continueEditing) {
      navigate(routes.editPath(id), { replace: true });
    } else {
      navigate(overviewPath(id));
    }
  }

  function handleDeletion(id: Id): void {
    setTransientItems((value) => value.remove(id));
    navigate(homePath);
  }

  function cleanTransientItemsIfNeeded(searchTerm: string | undefined): void {
    if (searchTermRef.current !== searchTerm) {
      setTransientItems(Map());
      searchTermRef.current = searchTerm;
    }
  }

  function handleSearch(searchTerm: string | undefined): void {
    navigate(routes.searchPath(searchTerm), { replace: !!searchTermRef.current });
  }

  function makeSearchElement(config: { onReset?: () => void, onListAll?: () => void }) {
    return (
      <SearchPageRouter<Id, Item>
        config={props}

        transientItems={List(transientItems.values())}
        newPath={routes.newPath}

        onNavigate={cleanTransientItemsIfNeeded}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onReset={config.onReset}
        onListAll={config.onListAll}
      />
    );
  }

  function makeListElement(config: { onReset?: () => void, onListAll?: () => void }) {
    return (
      <ListPageRouter<Id, Item>
        config={props}

        transientItems={List(transientItems.values())}
        newPath={routes.newPath}

        onInit={() => cleanTransientItemsIfNeeded("")}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onReset={config.onReset}
        onListAll={config.onListAll}
      />
    );
  }

  const navigateHome = React.useCallback(() => navigate(routes.homePath), []);
  const navigateToList = React.useCallback(() => navigate(routes.listPath), []);

  return (
    <Routes>
      {
        props.startFrom === "list"
          ? (
            <>
              <Route path={routes.homeMask} element={makeListElement({ onReset: navigateHome })}/>
              <Route path={routes.searchMask} element={makeSearchElement({ onReset: navigateHome })}/>
              <Route path={routes.listMask} element={<Navigate to={routes.homePath}/>}/>
            </>
          )
          : (
            <>
              <Route path={routes.homeMask} element={makeSearchElement({ onListAll: navigateToList })}/>
              <Route path={routes.searchMask} element={makeSearchElement({ onListAll: navigateToList })}/>
              <Route path={routes.listMask} element={makeListElement({ onListAll: navigateToList })}/>
            </>
          )
      }
      <Route
        path={routes.overviewMask}
        element={(
          <OverviewPageRouter<Id, Item, FormData>
            config={props}
            homePath={homePath}

            onClone={handleCloning}
            onDelete={handleDeletion}
          />
        )}
      />
      <Route
        path={routes.editMask}
        element={(
          <EditPageRouter<Id, Item, FormData>
            config={props}

            homePath={homePath}
            makeCancelPath={overviewPath}

            onClone={handleCloning}
            onUpdate={handleUpdate}
            onDelete={handleDeletion}
          />
        )}
      />
      <Route
        path={routes.newMask}
        element={(
          <NewItemPage<Id, Item, FormData>
            config={props}

            homePath={homePath}
            cancelPath={homePath}

            onComplete={handleUpdate}
          />
        )}
      />
    </Routes>
  );
}

interface SearchPageRouterProps<Id, Item> {
  config: CRUDConfig<Id, Item, any>;

  transientItems: List<Item>;
  newPath: string;

  onNavigate: (searchTerm: string | undefined) => void;
  onSearch: (searchTerm: string | undefined) => void;
  onSelect: (id: Id, item: Item) => void;
  onReset: (() => void) | undefined;
  onListAll: (() => void) | undefined;
}

function SearchPageRouter<Id, Item>(props: SearchPageRouterProps<Id, Item>): React.ReactElement {
  const routes = props.config.useRoutes();
  const params = routes.searchParams();

  React.useEffect(() => props.onNavigate(params.term), [params.term]);

  return (
    <SearchPage<Id, Item>
      config={props.config}

      searchTerm={params.term}
      transientItems={props.transientItems}
      newPath={props.newPath}

      onSearch={props.onSearch}
      onSelect={props.onSelect}
      onReset={props.onReset}
      onListAll={props.onListAll}
    />
  );
}

interface ListPageRouterProps<Id, Item> {
  config: CRUDConfig<Id, Item, any>;

  transientItems: List<Item>;
  newPath: string;

  onInit: () => void;
  onSearch: (listTerm: string) => void;
  onSelect: (id: Id, item: Item) => void;
  onReset: (() => void) | undefined;
  onListAll: (() => void) | undefined;
}

function ListPageRouter<Id, Item>(props: ListPageRouterProps<Id, Item>): React.ReactElement {
  React.useEffect(props.onInit, []);

  return (
    <ListPage<Id, Item>
      config={props.config}

      transientItems={props.transientItems}
      newPath={props.newPath}

      onSearch={props.onSearch}
      onSelect={props.onSelect}
      onReset={props.onReset}
      onListAll={props.onListAll}
    />
  );
}

interface OverviewPageRouterProps<Id, Item, FormData> {
  config: CRUDConfig<Id, Item, FormData>;
  homePath: string;

  onClone: (item: Item) => void;
  onDelete: (id: Id) => void;
}

function OverviewPageRouter<Id, Item, FormData>(
  props: OverviewPageRouterProps<Id, Item, FormData>
): React.ReactElement {
  const routes = props.config.useRoutes();
  const params = routes.overviewParams();

  return (
    <OverviewItemPage<Id, Item, FormData>
      config={props.config}
      id={params.id}

      homePath={props.homePath}
      editPath={routes.editPath(params.id)}

      onClone={props.onClone}
      onDelete={() => props.onDelete(params.id)}
    />
  );
}

interface EditPageRouterProps<Id, Item, FormData> {
  config: CRUDConfig<Id, Item, FormData>;

  homePath: string;
  makeCancelPath: (id: Id) => string;

  onClone: (item: Item) => void;
  onUpdate: (item: Item, continueEditing: boolean) => void;
  onDelete: (id: Id) => void;
}

function EditPageRouter<Id, Item, FormData>(props: EditPageRouterProps<Id, Item, FormData>): React.ReactElement {
  const routes = props.config.useRoutes();
  const params = routes.editParams();

  return (
    <EditItemPage<Id, Item, FormData>
      config={props.config}
      id={params.id}

      homePath={props.homePath}
      cancelPath={props.makeCancelPath(params.id)}

      onClone={props.config.renderOverview ? undefined : props.onClone}
      onUpdate={props.onUpdate}
      onDelete={props.config.renderOverview ? undefined : () => props.onDelete(params.id)}
    />
  );
}
