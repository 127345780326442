import * as React from "react";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { useRoutes } from "../../../../app/routes/useRoutes";
import { SimplePanel } from "../../../containers/simplePanel";
import { Link } from "react-router-dom";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { ReportSettingsForm } from "../../../blocks/reportSettingsForm";
import { ReferralCode } from "../../../../types/models/referralCode";
import { List } from "immutable";
import { OperationStatus } from "../../../../types/operationStatus";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { ReferralCodeDashboard } from "./referralCodeDashboard";
import { Program } from "../../../../types/models/program";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { ProgramDashboard } from "./programDashboard";
import { Paragraph } from "../../../widgets/paragraph";
import { styled } from "../../../../app/theme";

interface Props {
  organizationAlias: string;
  readOnly: boolean;

  referralCodesStatus: OperationStatus<List<ReferralCode>>;
  programsStatus: OperationStatus<List<Program>>;

  maxDays: number;
  onGenerateEarningsReport: (startDate: Date, endDate: Date) => void;
}

export const ReferAndEarnPageView: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  return (
    <>
      <HeaderBlock title={"Alumni Association Member Benefit Program"}/>
      {!props.readOnly && (
        <SimplePanel>
          <SimpleToolbar>
            <Link to={routes.organizationPortalRoutes.referralCodes(props.organizationAlias).homePath}>
              Manage referral codes
            </Link>
            <Link to={routes.organizationPortalRoutes.programs(props.organizationAlias).homePath}>
              Manage programs
            </Link>
          </SimpleToolbar>
        </SimplePanel>
      )}
      <SimplePanel>
        <Paragraph>
          Your Alumni Association has signed a VaultMe Alumni Association Member Benefit Program Agreement to provide
          your Members and Prospective Members discounts for their qualified use of VaultMe for their school, business,
          or personal accounts, and for which your Alumni Association will earn a share of VaultMe's revenue.
        </Paragraph>
        <Paragraph>
          To direct your Members to their VaultMe Member Benefit, please reference the member benefit referral link
          information below.
        </Paragraph>
        <Paragraph>
          To review your Alumni Association's earnings to date, please generate an earnings report below.
        </Paragraph>
      </SimplePanel>
      <OperationStatusIndicator
        subject={"programs"}
        status={props.programsStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.programsStatus.isSuccess() && (
        <ProgramsList
          organizationAlias={props.organizationAlias}
          programs={props.programsStatus.result}
        />
      )}
      <OperationStatusIndicator
        subject={"referral codes"}
        status={props.referralCodesStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.referralCodesStatus.isSuccess() && (
        props.referralCodesStatus.result.isEmpty()
          ? <SimplePanel>No referral codes found.</SimplePanel>
          : (
            <ReferralCodesList
              organizationAlias={props.organizationAlias}
              readOnly={props.readOnly}
              referralCodes={props.referralCodesStatus.result}
            />
          )
      )
      }
      <Block>
        <Panel>
          <PanelTitle>Alumni Association Earnings Report</PanelTitle>
          <PanelRow>
            <ReportSettingsForm
              maxDays={props.maxDays}
              submitTitle={"Generate Earnings Report"}
              onSubmit={(formData) => props.onGenerateEarningsReport(formData.startDate, formData.endDate)}
            />
          </PanelRow>
        </Panel>
      </Block>
    </>
  );
};

const PanelTitle = styled(PanelRow)`
  font-size: 1.7rem;
`;

interface ReferralCodesListProps {
  organizationAlias: string;
  readOnly: boolean;
  referralCodes: List<ReferralCode>;
}

const ReferralCodesList: React.FunctionComponent<ReferralCodesListProps> = (props) => (
  <>
    {props.referralCodes.map((referralCode) => (
      <ReferralCodeDashboard
        key={referralCode.id}
        organizationAlias={props.organizationAlias}
        readOnly={props.readOnly}
        referralCode={referralCode}
      />
    ))}
  </>
);

interface ProgramsListProps {
  organizationAlias: string;
  programs: List<Program>;
}

const ProgramsList: React.FunctionComponent<ProgramsListProps> = (props) => (
  <>
    {props.programs.map((program) => (
      <ProgramDashboard
        key={program.id}
        organizationAlias={props.organizationAlias}
        program={program}
      />
    ))}
  </>
);
