import * as React from "react";
import { CloudService } from "../types/models/cloudService";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { List, Map, OrderedMap, Set } from "immutable";
import { Connection } from "../types/models/connection";
import { ConnectionPanelDefs } from "../views/blocks/connectionPanel/connectionPanelDefs";
import { useAuthProviders, useCloudServices } from "../app/configuration";
import { useSession } from "../utils/useAppState";
import { SignInFlowState, useSignInFlow } from "../components/auth/useSignInFlow";
import { GraphQL } from "../services/graphql/generated";
import { ConnectionPanelState } from "../views/blocks/connectionPanel/connectionPanelState";
import { AuthContext } from "../views/blocks/authContext";
import { resolveImage } from "../app/images";
import { WorkStatus } from "../views/models/workStatus";
import { IncrementalSignIn } from "./incrementalSignIn";
import { identity, nullToUndefined } from "../utils/misc";
import { RoleComp } from "../blueprints/components/roleComp";
import { AreaComp } from "../blueprints/components/areaComp";
import { SinkComp } from "../blueprints/components/sinkComp";
import { ItemsByType } from "../views/models/itemsByType";
import { ActionItem, ActionItems } from "../views/models/actionItem";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { WatchedFacts } from "../services/watcher/plugins/watchedFactsPlugin";
import { useWatcherDispatch } from "../services/watcher/useWatcher";
import { UserFacingError } from "../types/userFacingError";
import { AreaStatus } from "../views/models/areaStatus";
import { PreparedHelpArticle } from "../utils/preparedHelpArticle";
import { bytesToGigabytes } from "../utils/formatting";
import { Tracking } from "../services/tracking";
import { UserType } from "../types/models/userType";
import { SignInContextType } from "../types/models/signInContextType";
import { NewUserSettings } from "../types/models/newUserSettings";
import { HelpArticles } from "../app/helpArticles";
import { Constants } from "../app/constants";
import { useRoutes } from "../app/routes/useRoutes";

interface ConnectionHandlers {
  onConnect: (connection: Connection) => void;
  onDisconnect?: () => void;
  // This just a hook to send feedback to the wizard flow.
  // onConnect() is expected to handle change in cloud service as well.
  // If this is not sent, we don't want to change cloud service (and will handle this situation in onConnect).
  onCloudServiceChange?: (cloudServiceId: string) => void;
}

interface PreparedArea {
  component: AreaComp | SinkComp;
  config: Partial<ConnectionPanelDefs.Area>;
  notUsed: boolean;
  disabled?: AreaStatus.Disabled;
}

interface Config {
  source: boolean;
  cloudService: CloudService;
  oppositeCloudService: CloudService;
  blueprint: MaterializedMigrationBlueprint;
  areas: List<PreparedArea>;
  defaultRoles: Set<string>;

  connection: Connection | undefined;
  oppositeConnection: Connection | undefined;

  connectionHandlers?: ConnectionHandlers;

  showRestrictions: boolean;
  newUserSettings: NewUserSettings | undefined;

  actionItemSuppressing?: ActionItems.Suppressing;
}

export function useConnectionFlow(config: Config): ConnectionPanelDefs.ControlledConnectionPanelProps {
  const authContext = config.source ? AuthContext.Source : AuthContext.Destination;
  const signInContextType = config.source ? SignInContextType.Source : SignInContextType.Destination;

  const cloudServices = useCloudServices(signInContextType);
  const authProviders = useAuthProviders();
  const session = useSession();
  const watcher = useWatcherDispatch();
  const routes = useRoutes();

  const signInFlow = useSignInFlow({
    flowId: config.source ? "Source" : "Destination",
    contextType: signInContextType,
    newUserSettings: config.newUserSettings,
    defaultRoles: config.defaultRoles,
    onSignIn: (connectResult) => handleConnectionSuccess(connectResult.connection),
    cloudServiceId: config.cloudService.id,
    options: {
      // If destination connection is the same as source, the error message is supposed to give all recommendations
      offerAccountCreation: !config.source &&
        (!config.connection || config.connection.id !== config.oppositeConnection?.id)
    }
  });

  const [refresh] = useManagedMutation({
    mutation: GraphQL.useRefreshBlueprintFactsMutation,
    extract: (data: GraphQL.RefreshBlueprintFactsMutation) => nullToUndefined(data.refreshBlueprintFacts),
    complete: identity
  });

  const [refreshed, setRefreshed] = React.useState(
    config.areas.some((area) =>
      area.component.state(config.blueprint.context).reliableOutput.isEmpty()
    )
  );
  const [refreshedAreas, setRefreshedAreas] = React.useState<Set<string>>(Set());

  function handleConnectionSuccess(connection: Connection): void {
    if (config.source) {
      Tracking.connectedSource(connection);
    } else {
      Tracking.connectedDestination(connection, connection.id === config.oppositeConnection?.id);
    }

    setPanelState(buildPanelState(connection));

    if (config.connectionHandlers) {
      config.connectionHandlers.onConnect(connection);

      if (connection.cloudServiceId !== config.cloudService.id && config.connectionHandlers.onCloudServiceChange) {
        config.connectionHandlers.onCloudServiceChange(connection.cloudServiceId);
      }
    }
  }

  const [revoke] = GraphQL.useRevokeAccessKeyMutation();

  function handleDisconnect(connection: Connection): void {
    // Not revoking access keys when:
    // (a) source and destination connections are the same
    // (b) the session is elevated
    if (
      session &&
      config.oppositeConnection?.id !== connection.id &&
      session.user.type !== UserType.Admin
    ) {
      revoke({ variables: { connectionId: connection.id, force: false } });
    }
    if (config.connectionHandlers && config.connectionHandlers.onDisconnect) {
      config.connectionHandlers.onDisconnect();
    }
  }

  function reScan(factIds: Set<string>, areas: Set<string>): void {
    setRefreshed(true);
    setRefreshedAreas((current) => current.intersect(areas));

    watcher(WatchedFacts.WatchFactsAction(factIds));

    refresh({
      variables: {
        factIds: factIds.toArray(),
        sourceCloudServiceId: config.blueprint.sourceCloudServiceId,
        destinationCloudServiceId: config.blueprint.destinationCloudServiceId,
        blueprintInputs: config.blueprint.context.inputs.toGraphQL()
      }
    }).then((result) => {
      setRefreshedAreas((current) => current.subtract(areas));
      return result;
    }).catch((error) => {
      setRefreshedAreas((current) => current.subtract(areas));
      throw error;
    });
  }

  function renderFailedRestriction(restriction: MaterializedMigrationBlueprint.RestrictionInfo): ActionItem {
    return {
      id: ActionItem.restrictionTriggered(restriction.restriction.id),
      type: restriction.restriction.props.isCritical ? ActionItem.Type.Error : ActionItem.Type.Warning,
      message: "Restriction triggered: " + restriction.restriction.id,
      suppressAction: undefined,
      actions: []
    };
  }

  function sourceAndDestinationVars(connection: Connection): Map<string, string> {
    return Map<string, string>(
      config.source
        ? [
          ["source", connection.descriptionOrId()],
          ["destination", config.oppositeConnection ? config.oppositeConnection.descriptionOrId() : ""],
        ]
        : [
          ["source", config.oppositeConnection ? config.oppositeConnection.descriptionOrId() : ""],
          ["destination", connection.descriptionOrId()]
        ]
    );
  }

  function renderAvailableStorage(
    connection: Connection,
    restriction: MaterializedMigrationBlueprint.StorageRestrictionInfo | undefined,
    checkAgain: () => void
  ): ConnectionPanelDefs.AvailableStorageInfo.Any | undefined {
    if (!config.source && restriction) {
      const storageType = restriction.restriction.props.storageType.toJS();

      if (restriction.state.isPending || restriction.state.isPreparing || restriction.state.isResolving) {
        return ConnectionPanelDefs.AvailableStorageInfo.collecting(storageType);
      } else if (restriction.state.isAlmostResolved || restriction.state.isResolved) {
        return restriction.state.reliableOutput
          .map((output) => {
            const required = output.required
              .map((collectable) => collectable.currentValue())
              .getOrElse(() => 0);

            if (restriction.restriction.hubs.available.dataFlow().isEmpty() || output.available.isEmpty()) {
              if (required > 0) {
                const helpArticle = PreparedHelpArticle.mayBeFromExternal(
                  restriction.restriction.props.helpArticle.toJS(),
                  {
                    vars: sourceAndDestinationVars(connection).concat(Map([
                      ["migrationSize", bytesToGigabytes(required).toFixed(2)],
                      ["requiredStorage", bytesToGigabytes(required).toFixed(2)],
                    ]))
                  }
                );
                if (config.showRestrictions) {
                  return ConnectionPanelDefs.AvailableStorageInfo.needToEnsure(required, storageType, helpArticle);
                } else {
                  return ConnectionPanelDefs.AvailableStorageInfo.unableToCollect(storageType, helpArticle);
                }
              } else {
                return undefined;
              }
            } else {
              return output.available
                .map((availableCollectable) => {
                  const available = availableCollectable.currentValue();
                  if (output.restrictionState) {
                    return ConnectionPanelDefs.AvailableStorageInfo.enough(
                      available, required, storageType
                    );
                  } else if (config.showRestrictions) {
                    const helpArticle = PreparedHelpArticle.mayBeFromExternal(
                      restriction.restriction.props.helpArticle.toJS(),
                      {
                        vars: sourceAndDestinationVars(connection).concat(Map([
                          ["migrationSize", bytesToGigabytes(required).toFixed(2)],
                          ["availableStorage", bytesToGigabytes(available).toFixed(2)],
                          ["requiredStorage", bytesToGigabytes(required - available).toFixed(2)],
                        ]))
                      }
                    );
                    return ConnectionPanelDefs.AvailableStorageInfo.notEnough(
                      available, required, checkAgain, storageType, helpArticle
                    );
                  } else {
                    return ConnectionPanelDefs.AvailableStorageInfo.collected(available, storageType);
                  }
                })
                .getOrElse(() => undefined);
            }
          })
          .getOrElse(() => undefined);
      } else {
        return undefined;
      }
    }
  }

  function buildAreas(
    connection: Connection,
    isBlueprintReady: boolean,
    storageRestrictions: List<MaterializedMigrationBlueprint.StorageRestrictionInfo>,
    failedRestrictions: List<MaterializedMigrationBlueprint.RestrictionInfo>
  ): List<ConnectionPanelDefs.Area> {
    return config.areas
      .filter((area) => !config.cloudService.excludedApps.contains(area.component.props.title))
      .sortBy((area) => area.component.props.order)
      .map((area): ConnectionPanelDefs.Area => {
        const component = area.component;
        const state = area.component.state(config.blueprint.context);

        // const failedPreconditions = area.component.hubs.preconditions.failedPreconditions(config.blueprint.context);
        // console.log(failedPreconditions.map((r) => r.componentId + "#" + r.hubName).toArray());

        const missingRoles = !isBlueprintReady || area.notUsed
          ? Set<RoleComp>()
          : config.blueprint.missingRoles(component);

        const sourceAppId = component instanceof AreaComp
          ? component.props.internalId
          : component.sourceAppId().toJS();

        const destinationAppId = component instanceof SinkComp
          ? component.props.internalId
          : component.destinationAppId().toJS();

        const sourceAppTitle = component instanceof AreaComp
          ? component.resolvedAppTitle(config.blueprint.context)
          : component.sourceAppTitle().toJS();

        const destinationAppTitle = component instanceof SinkComp
          ? component.resolvedAppTitle(config.blueprint.context)
          : component.destinationAppTitle().toJS();

        const helpArticle = sourceAppId && destinationAppId && sourceAppTitle && destinationAppTitle
          ? PreparedHelpArticle.migrationRules({
            routes,
            phase: "before-migration",
            sourceAppId,
            destinationAppId,
            sourceAppTitle,
            destinationAppTitle,
            vars: sourceAndDestinationVars(connection)
          })
          : undefined;

        const availableStorage = component instanceof SinkComp
          ? renderAvailableStorage(
            connection,
            storageRestrictions.find((restriction) =>
              restriction.affectedSinks.size === 1 && restriction.affectedSinks.contains(component)
            ),
            () => reScan(allFactsIds, Set(component.props.title))
          )
          : undefined;

        const actionItems: ActionItems = config.showRestrictions && component instanceof SinkComp
          ? failedRestrictions
            .filter((restriction) =>
              restriction.affectedSinks.size === 1 && restriction.affectedSinks.contains(component)
            )
            .map(renderFailedRestriction)
          : List();

        const allFacts = config.blueprint.allFacts(component);
        const allFactsIds = allFacts
          .flatMap((fact) => fact.unblockedFactIds(config.blueprint.context));

        const failedFacts = config.blueprint.failedFacts(component);
        const failedFactsIds = failedFacts
          .flatMap((fact) => fact.unblockedFactIds(config.blueprint.context));

        const refreshing = refreshedAreas.contains(component.props.title);

        function buildDisabled(): AreaStatus.Disabled | undefined {
          if (area.notUsed) {
            return config.source
              ? {
                id: ActionItem.noApp(area.component.id),
                type: ActionItem.Type.Notification,
                message: "No app in " + config.oppositeCloudService.name + " can receive these items",
                actions: []
              }
              : {
                id: ActionItem.noApp(area.component.id),
                type: ActionItem.Type.Notification,
                message: "No items from " + config.oppositeCloudService.name + " can be received here",
                noSuffix: true,
                actions: []
              };
          } else if (isBlueprintReady && state.isBlocked && missingRoles.isEmpty()) {
            if (component.props.appTitle === Constants.OutlookHack.appTitle) {
              return {
                id: ActionItem.notEnabled(area.component.id),
                type: ActionItem.Type.Warning,
                message: Constants.OutlookHack.subject + " is not accessible",
                actions: [
                  {
                    title: "Fix",
                    helpArticle: PreparedHelpArticle.fromExternal(HelpArticles.outlookNotEnabled),
                  },
                  {
                    title: "Check Again",
                    onClick: () => reScan(allFactsIds, Set(component.props.title))
                  },
                ],
                suppressAction: {
                  result: ActionItem.SuppressAction.Result.WillNotMigrate,
                  affectedSubject: area.component.props.mainSubject
                }
              };
            } else {
              return {
                id: ActionItem.notEnabled(component.id),
                type: ActionItem.Type.Warning,
                message: component.props.appTitle + " is not accessible",
                actions: [],
                suppressAction: {
                  result: ActionItem.SuppressAction.Result.WillNotMigrate,
                  affectedSubject: area.component.props.mainSubject
                }
              };
            }
          } else if (state.isError) {
            return {
              id: ActionItem.scanError(component.id),
              type: ActionItem.Type.Error,
              message: "Something went wrong, this " +
                AuthContext.scanOrTest(authContext).toLowerCase() +
                " is paused",
              actions: [{
                title: "Re-scan",
                onClick: () => reScan(failedFactsIds, Set(component.props.title))
              }]
            };
          } else {
            return area.disabled;
          }
        }

        return {
          areaId: component.id,
          appTitle: component.resolvedAppTitle(config.blueprint.context),
          mainSubject: component.props.mainSubject,
          title: component.props.title,
          description: component.props.description,
          icon: resolveImage(component.settings.icon),
          status: refreshing ? WorkStatus.Pending : state.toWorkStatus(),
          availableStorage,
          actionItems,
          helpArticle,
          disabled: buildDisabled(),
          incrementalSignIn: missingRoles.isEmpty()
            ? undefined
            : {
              areaId: component.id,
              prompt: "Additional sign-in is required to migrate " + component.props.mainSubject + "s",
              affectedSubject: component.props.mainSubject,
              connection,
              signInComponent: (props) => (
                <IncrementalSignIn
                  cloudService={config.cloudService}
                  connection={connection}
                  roles={missingRoles.map((role) => role.props.roleId)}
                  {...props}
                />
              ),
            },
          items: refreshing || !(component instanceof AreaComp)
            ? undefined
            : state.output
              .flatMap((output) =>
                output.totalItems.map((items) => {
                  const totalItemCount = items.currentValue();
                  return new ItemsByType(
                    OrderedMap(
                      component.props.itemTypeAliases.map(([title, itemTypes]): [string, number] => [
                        title,
                        itemTypes
                          .map((itemType) => totalItemCount.getTotal(itemType).getOrElse(() => 0))
                          .reduce((a, b) => a + b, 0)
                      ])
                    )
                  );
                })
              )
              .toJS(),
          size: refreshing || !config.source
            ? undefined
            : state.output
              .flatMap((output) => output.totalBytes.map((totalSize) => totalSize.currentValue()))
              .toJS(),
          ...area.config
        };
      });
  }

  function buildPanelState(connection: Connection | undefined): ConnectionPanelState.Any {
    if (connection) {
      const availableStorageRestrictions = config.blueprint.storageRestrictions();
      const failedRestrictions = config.blueprint.failedRestrictions();

      const availableStorage = renderAvailableStorage(
        connection,
        availableStorageRestrictions.find((restriction) => restriction.affectedSinks.size > 1),
        () => reScan(
          config.blueprint.unblockedFactIds(config.source),
          config.areas.map((area) => area.component.props.title).toSet()
        )
      );

      const actionItems: ActionItems = config.showRestrictions
        ? failedRestrictions
          .filter((restriction) => restriction.affectedSinks.size > 1)
          .map(renderFailedRestriction)
        : List();

      const connectionDetails = {
        cloudService: config.cloudService,
        authProvider: authProviders.getOrFail(config.cloudService.authProviderId),
        connection,
        availableStorage,
        actionItems
      };

      const onDisconnect =
        config.connectionHandlers &&
        config.connectionHandlers.onDisconnect &&
        (() => handleDisconnect(connection));

      if (config.source || connection.id !== config.oppositeConnection?.id) {
        // We may have a connection already, but the blueprint may not be ready yet (since it's coming from the parent
        // component that will "see" and use the connection object after making a number of additional API calls).
        const isBlueprintReady = !config.blueprint
          .findConnectionOrFail(config.source)
          .state(config.blueprint.context)
          .isBlocked;

        const areas = buildAreas(connection, isBlueprintReady, availableStorageRestrictions, failedRestrictions);

        if (
          !isBlueprintReady ||
          !refreshedAreas.isEmpty() ||
          // Condition below used to include "area.status === WorkStatus.Pending", but this provoked
          // infinite scanning indication for areas that required additional authentication (like Outlook)
          areas.find((area) => area.status === WorkStatus.Working)
        ) {
          return ConnectionPanelState.scanning({ connectionDetails, areas, onDisconnect });
        } else if (areas.every((area) => area.status === WorkStatus.Issue)) {
          return ConnectionPanelState.scanningError({
            connectionDetails,
            areas: areas.map((area) => ({ ...area, issues: List() })),
            onDisconnect,
            error: UserFacingError.synthetic(UserFacingError.enrichablePropsBase())
          });
        } else {
          return ConnectionPanelState.connected({
            connectionDetails,
            areas,
            onDisconnect,
            onReScan: () => reScan(
              config.blueprint.unblockedFactIds(config.source),
              areas.map((area) => area.title).toSet()
            )
          });
        }
      } else {
        return ConnectionPanelState.ConnectedBadAccount({
          error: UserFacingError.synthetic({
            title: UserFacingError.BadActionTitle,
            summary: (
              <>
                You have already connected <strong>{connectionDetails.connection.descriptionOrId()}</strong>{" "}
                as a source of content for your migration.
                Source and destination accounts must be different.
                Please connect another account that VaultMe can use to copy your content to.
              </>
            )
          }),
          connectionDetails,
          onDisconnect
        });
      }
    } else {
      return ConnectionPanelState.SigningIn();
    }
  }

  const [panelState, setPanelState] = React.useState<ConnectionPanelState.Any>(() => {
    const initialPanelState = buildPanelState(config.connection);
    if (
      config.source &&
      config.connection &&
      initialPanelState.type === ConnectionPanelState.Type.Connected
    ) {
      Tracking.scannedSource(config.connection, config.blueprint.totalItemCountInSource());
    }
    return initialPanelState;
  });

  React.useEffect(
    () => {
      const newPanelState = buildPanelState(config.connection);
      if (
        config.source &&
        config.connection &&
        newPanelState.type === ConnectionPanelState.Type.Connected
      ) {
        Tracking.scannedSource(config.connection, config.blueprint.totalItemCountInSource());
      }
      setPanelState(newPanelState);
    },
    [
      config.connection,
      config.blueprint, // Well, blueprint is currently created on every render :-\
      refreshedAreas.sort().join(", ")
    ]
  );

  return {
    cloudServices,
    authProviders,
    signInState: signInFlow.state,
    onCloudServiceSelect: config.connectionHandlers && config.connectionHandlers.onCloudServiceChange,
    onRolesSelect: signInFlow.selectRoles,
    onSignIn: signInFlow.handleSignInSuccess,

    cloudService: config.cloudService,
    authContext: config.source ? AuthContext.Source : AuthContext.Destination,
    defaultRoles: signInFlow.state.type !== SignInFlowState.Type.SelectingCloudService
      ? signInFlow.state.roles
      : config.defaultRoles,
    panelState,
    areaSelection: undefined,
    viewSettingsOverrides: {
      showScanProgress: refreshed ? true : undefined
    },

    connect: handleConnectionSuccess
  };
}
