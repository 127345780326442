import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { Upload } from "./upload";
import { List } from "immutable";
import { ConfigurationCloudService } from "./configurationCloudService";

export interface Configuration {
  readonly id: number;
  readonly alias: string;
  readonly name: string;
  readonly notes: string | undefined;

  readonly themeId: number | undefined;
  readonly headerSuffix: string | undefined;
  readonly mainLogo: Upload | undefined;
  readonly escapeLinkText: string | undefined;

  readonly introBanner: Upload | undefined;
  readonly introTitle: string | undefined;
  readonly introContent: string | undefined;
  readonly pricingPageBanner: Upload | undefined;
  readonly pricingPageTitle: string | undefined;
  readonly pricingPageContent: string | undefined;

  readonly sourceCloudServiceReference: string | undefined;
  readonly destinationCloudServiceReference: string | undefined;

  readonly enableFeedback: boolean;
  readonly feedbackQuestion: string | undefined;
  readonly checkbox1Label: string | undefined;
  readonly checkbox1Required: boolean;
  readonly checkbox2Label: string | undefined;
  readonly checkbox2Required: boolean;
  readonly checkbox3Label: string | undefined;
  readonly checkbox3Required: boolean;

  readonly cloudServices: List<ConfigurationCloudService>;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace Configuration {
  export function parse(data: GraphQL.ConfigurationFragment): Configuration {
    return {
      id: data.id,
      alias: data.alias,
      name: data.name,
      notes: nullToUndefined(data.notes),

      themeId: nullToUndefined(data.themeId),
      headerSuffix: nullToUndefined(data.headerSuffix),
      mainLogo: mapOptional(data.mainLogo, (upload) => Upload.parse(upload)),
      escapeLinkText: nullToUndefined(data.escapeLinkText),

      introBanner: mapOptional(data.introBanner, (upload) => Upload.parse(upload)),
      introTitle: nullToUndefined(data.introTitle),
      introContent: nullToUndefined(data.introContent),
      pricingPageBanner: mapOptional(data.pricingPageBanner, (upload) => Upload.parse(upload)),
      pricingPageTitle: nullToUndefined(data.pricingPageTitle),
      pricingPageContent: nullToUndefined(data.pricingPageContent),

      sourceCloudServiceReference: nullToUndefined(data.sourceCloudServiceReference),
      destinationCloudServiceReference: nullToUndefined(data.destinationCloudServiceReference),

      enableFeedback: data.enableFeedback,
      feedbackQuestion: nullToUndefined(data.feedbackQuestion),
      checkbox1Label: nullToUndefined(data.checkbox1Label),
      checkbox1Required: data.checkbox1Required,
      checkbox2Label: nullToUndefined(data.checkbox2Label),
      checkbox2Required: data.checkbox2Required,
      checkbox3Label: nullToUndefined(data.checkbox3Label),
      checkbox3Required: data.checkbox3Required,

      cloudServices: List(data.cloudServices).map(ConfigurationCloudService.parse),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(configurationId: number): string {
    return cachedObjectId("Configuration", configurationId.toString());
  }

  export const fragmentName = "Configuration";
}
