import * as React from "react";
import { SchoolSummary } from "../types/models/school";
import {
  AmbassadorSignUpStepView,
  LocalValidationResult,
  RemoteValidationResult
} from "../views/screens/migrationSetup/ambassadorStepView/ambassadorSignUpStepView";
import { replacedUserAction } from "../state/session/actions";
import { useDispatch } from "react-redux";
import { useBecomeAnAmbassadorMutation } from "../queries/payments/useBecomeAnAmbassadorMutation";
import { Connection } from "../types/models/connection";
import { useSendTestAmbassadorEmailMutation } from "../queries/payments/useSendTestAmbassadorEmailMutation";
import { Constants } from "../app/constants";
import { useRenderAmbassadorEmailPreviewMutation } from "../queries/payments/useRenderAmbassadorEmailPreviewMutation";
import { List, Map, Range } from "immutable";
import {
  useValidateAmbassadorsProgramEmailAddressMutation
} from "../queries/payments/useValidateAmbassadorsProgramEmailAddressMutation";
import { GraphQL } from "../services/graphql/generated";
import { blankStringToUndefined } from "../utils/misc";
import { useAppBootstrapConfig } from "../app/configuration";
import { AmbassadorOfferStepView } from "../views/screens/migrationSetup/ambassadorStepView/ambassadorOfferStepView";
import { useSession } from "../utils/useAppState";
import {
  ReturningAmbassadorStepView
} from "../views/screens/migrationSetup/ambassadorStepView/returningAmbassadorStepView";
import { OperationStatus } from "../types/operationStatus";
import { useEnableAuthRolesController } from "../controllers/enableAuthRolesController";
import { AccountAction } from "../types/enums/accountAction";

interface Props {
  sourceConnection: Connection;
  destinationConnection: Connection;
  school: SchoolSummary;

  onComplete: () => void;
  onNavigateBack?: () => void;
}

export const AmbassadorsProgramStep: React.FunctionComponent<Props> = (props) => {
  const session = useSession();
  const [signUpFormExpanded, setSignUpFormExpanded] = React.useState(false);
  const [numberOfFriendsHelped, setNumberOfFriendsHelped] = React.useState<number>();

  if (session?.user.ambassadorsProgramMembership) {
    return (
      <ReturningAmbassadorStepView
        school={props.school}
        sourceConnection={props.sourceConnection}
        destinationConnection={props.destinationConnection}
        ambassadorsProgramMembership={session.user.ambassadorsProgramMembership}

        numberOfFriendsHelped={numberOfFriendsHelped}

        onProceed={props.onComplete}
        onNavigateBack={props.onNavigateBack}
      />
    );
  } else if (signUpFormExpanded) {
    return <AmbassadorSignUpStep {...props} onSignUp={setNumberOfFriendsHelped}/>;
  } else {
    return (
      <AmbassadorOfferStepView
        school={props.school}
        onProceed={() => setSignUpFormExpanded(true)}
        onSkip={props.onComplete}
        onNavigateBack={props.onNavigateBack}
      />
    );
  }
};

const MaxCustomMessageLength = 280;

const TestContacts = List<[string, string]>([
  ["Tim", "tim@combine8.com"],
  ["Mark", "mark@combine8.com"],
  ["Vlad", "vlad@combine8.com"],
  ["Liza", "lizav@combin8.com"],
  ["Liza", "liza.test.2@combin8.com"]
]);

interface Contact {
  firstName: string;
  emailAddress: string;
}

interface PreparedContact {
  firstName: string | undefined;
  emailAddress: string;
}

interface ContactEntry {
  contact: Contact;
  preparedEmailAddress: string;
  isEmailAddressFormatValid: boolean | undefined;
}

namespace ContactEntry {
  export const empty: ContactEntry = {
    contact: {
      firstName: "",
      emailAddress: ""
    },
    preparedEmailAddress: "",
    isEmailAddressFormatValid: undefined
  };
}

interface ContactsState {
  entries: List<ContactEntry>;
  remoteValidationResults: Map<string, RemoteValidationResult | undefined>;
}

namespace ContactsState {
  export function makeEmpty(minNumberOfContacts: number): ContactsState {
    return {
      entries: Range(0, minNumberOfContacts).map(() => ContactEntry.empty).toList(),
      remoteValidationResults: Map()
    };
  }

  export const test: ContactsState = {
    entries: TestContacts.map(([firstName, emailAddress]) => ({
      contact: { firstName, emailAddress },
      preparedEmailAddress: emailAddress,
      isEmailAddressFormatValid: true
    })),
    remoteValidationResults: Map(
      TestContacts.map(([firstName, emailAddress]): [string, RemoteValidationResult] =>
        [emailAddress, RemoteValidationResult.Valid]
      )
    )
  };
}

namespace EmailDeliveryOperation {
  export enum Type {
    SendTestEmail = "SendTestEmail",
    BecomeAnAmbassador = "BecomeAnAmbassador",
  }

  interface Base<T extends Type> {
    type: T;
    customMessage: string | undefined;
    accountAction: AccountAction;
  }

  export interface SendTestEmail extends Base<Type.SendTestEmail> {
    accountActionDate: Date | undefined;
  }

  export function sendTestEmail(
    params: {
      customMessage: string | undefined,
      accountAction: AccountAction,
      accountActionDate: Date | undefined
    }
  ): SendTestEmail {
    return { type: Type.SendTestEmail, ...params };
  }

  export interface BecomeAnAmbassador extends Base<Type.BecomeAnAmbassador> {
    accountActionDate: Date;
    contacts: List<PreparedContact>;
  }

  export function becomeAnAmbassador(
    params: {
      customMessage: string | undefined,
      accountAction: AccountAction,
      accountActionDate: Date,
      contacts: List<PreparedContact>
    }
  ): BecomeAnAmbassador {
    return { type: Type.BecomeAnAmbassador, ...params };
  }

  export type Any = SendTestEmail | BecomeAnAmbassador;
}

const EmailDeliveryRoles = [Constants.Auth.SendEmailsRole];
const VerifyEmailAddressesRoles = [Constants.Auth.VerifyEmailAddresses];

interface AmbassadorSignUpStepProps extends Props {
  onSignUp: (numberOfContacts: number) => void;
}

const AmbassadorSignUpStep: React.FunctionComponent<AmbassadorSignUpStepProps> = (props) => {
  const dispatch = useDispatch();
  const appBootstrapConfig = useAppBootstrapConfig();

  const minNumberOfContacts = appBootstrapConfig.ambassadorsProgram.minNumberOfContacts;
  const maxNumberOfContacts = appBootstrapConfig.ambassadorsProgram.maxNumberOfContacts;

  const [renderEmailPreview, renderEmailPreviewStatus] = useRenderAmbassadorEmailPreviewMutation();
  const [sendTestEmail, sendTestEmailStatus] = useSendTestAmbassadorEmailMutation();
  const [validateEmailAddress] = useValidateAmbassadorsProgramEmailAddressMutation();
  const [becomeAnAmbassador, becomeAnAmbassadorStatus] = useBecomeAnAmbassadorMutation();
  const [sendingDelay, setSendingDelay] = React.useState(false);

  const [customMessage, setCustomMessage] = React.useState("");
  const preparedCustomMessage = blankStringToUndefined(customMessage);

  const [accountAction, setAccountAction] = React.useState<AccountAction>(AccountAction.Delete);
  const [accountActionDate, setAccountActionDate] = React.useState<Date | undefined>(undefined);

  const [contactsState, setContactsState] = React.useState<ContactsState>(() =>
    ContactsState.makeEmpty(minNumberOfContacts)
  );

  const contacts = contactsState.entries.map((entry, index) => ({
    firstName: entry.contact.firstName,
    emailAddress: entry.contact.emailAddress,
    localValidationResult: makeLocalValidationResult(entry, index),
    remoteValidationResult: contactsState.remoteValidationResults.get(entry.preparedEmailAddress)
  }));

  const validatedContacts: List<PreparedContact> =
    contactsState.entries.flatMap((entry, index) =>
      makeLocalValidationResult(entry, index) === LocalValidationResult.Valid &&
      contactsState.remoteValidationResults.get(entry.preparedEmailAddress) === RemoteValidationResult.Valid
        ? List([{
          firstName: blankStringToUndefined(entry.contact.firstName),
          emailAddress: entry.preparedEmailAddress
        }])
        : List()
    );

  React.useEffect(
    () => {
      renderEmailPreview(props.sourceConnection.id);
    },
    [props.sourceConnection.id]
  );

  const [deferredEmailDeliveryOperation, setDeferredEmailDeliveryOperation] =
    React.useState<EmailDeliveryOperation.Any>();

  function executeEmailDeliveryOperation(operation: EmailDeliveryOperation.Any): Promise<any> {
    switch (operation.type) {
      case EmailDeliveryOperation.Type.SendTestEmail:
        return sendTestEmail({ sourceConnectionId: props.sourceConnection.id, ...operation });

      case EmailDeliveryOperation.Type.BecomeAnAmbassador:
        setSendingDelay(true);
        return becomeAnAmbassador({
          sourceConnectionId: props.sourceConnection.id,
          destinationConnectionId: props.destinationConnection.id,
          ...operation
        });
    }
  }

  React.useEffect(
    () => {
      if (sendingDelay) {
        const timeout = setTimeout(() => setSendingDelay(false), 10000);
        return () => clearTimeout(timeout);
      }
    },
    [sendingDelay]
  );

  // Using becomeAnAmbassador.then() does not work after retries because of flaws in the useManagedMutation() function.
  React.useEffect(
    () => {
      if (becomeAnAmbassadorStatus.isSuccess() && !sendingDelay) {
        props.onSignUp(validatedContacts.size);
        dispatch(replacedUserAction(becomeAnAmbassadorStatus.result));
      }
    },
    [becomeAnAmbassadorStatus.isSuccess(), sendingDelay]
  );

  function scheduleEmailDeliveryOperation(operation: EmailDeliveryOperation.Any): void {
    if (!!props.sourceConnection.roles?.contains(Constants.Auth.SendEmailsRole)) {
      executeEmailDeliveryOperation(operation);
    } else {
      setDeferredEmailDeliveryOperation(operation);
    }
  }

  const handleEnableEmailDeliverySuccess = React.useCallback(
    () => {
      if (deferredEmailDeliveryOperation) {
        executeEmailDeliveryOperation(deferredEmailDeliveryOperation);
        setDeferredEmailDeliveryOperation(undefined);
      }
    },
    [deferredEmailDeliveryOperation]
  );

  const enableEmailDeliveryController = useEnableAuthRolesController({
    connection: props.sourceConnection,
    roles: EmailDeliveryRoles,
    onSuccess: handleEnableEmailDeliverySuccess
  });

  const enableEmailAddressVerificationController = useEnableAuthRolesController({
    connection: props.sourceConnection,
    roles: VerifyEmailAddressesRoles
  });

  function makeLocalValidationResult(entry: ContactEntry, index: number): LocalValidationResult | undefined {
    switch (entry.isEmailAddressFormatValid) {
      case undefined:
        return undefined;

      case false:
        return LocalValidationResult.InvalidEmailAddressFormat;

      case true:
        if (
          contactsState.entries.take(index).find((precedingEntry) =>
            precedingEntry.preparedEmailAddress === entry.preparedEmailAddress
          )
        ) {
          return LocalValidationResult.Duplicate;
        } else {
          return LocalValidationResult.Valid;
        }
    }
  }

  function makeRemoteValidationResult(
    result: GraphQL.AmbassadorsProgramEmailAddressValidationResult
  ): RemoteValidationResult {
    switch (result) {
      case GraphQL.AmbassadorsProgramEmailAddressValidationResult.Valid:
        return RemoteValidationResult.Valid;

      case GraphQL.AmbassadorsProgramEmailAddressValidationResult.InvalidDomain:
        return RemoteValidationResult.InvalidDomain;

      case GraphQL.AmbassadorsProgramEmailAddressValidationResult.ContactNotFound:
        return RemoteValidationResult.ContactNotFound;

      case GraphQL.AmbassadorsProgramEmailAddressValidationResult.EmailAddressBelongsToUser:
        return RemoteValidationResult.EmailAddressBelongsToUser;
    }
  }

  function completeRemoteValidation(emailAddress: string, result: RemoteValidationResult): void {
    setContactsState((state) => ({
      ...state,
      remoteValidationResults: state.remoteValidationResults.set(emailAddress, result)
    }));
  }

  function validateEmailAddressFormat(emailAddress: string): boolean | undefined {
    if (emailAddress.length === 0) {
      return undefined;
    } else {
      const parts = emailAddress.split("@");
      return parts.length === 2 && parts[0].length !== 0 && parts[1].length !== 0;
    }
  }

  function updateContact(index: number, update: (contact: Contact) => [Contact, boolean]): void {
    setContactsState((state) => {
      const entry = state.entries.get(index);

      if (entry) {
        const [updatedContact, triggerValidationIfNeeded] = update(entry.contact);

        if (triggerValidationIfNeeded) {
          const preparedEmailAddress = updatedContact.emailAddress.trim().toLowerCase();
          const isEmailAddressFormatValid = validateEmailAddressFormat(preparedEmailAddress);

          const updatedEntry: ContactEntry = {
            contact: updatedContact,
            preparedEmailAddress,
            isEmailAddressFormatValid
          };

          if (isEmailAddressFormatValid) {
            const triggerRemoveValidation = triggerValidationIfNeeded && (
              !state.remoteValidationResults.has(preparedEmailAddress) ||
              state.remoteValidationResults.get(preparedEmailAddress) === RemoteValidationResult.Failure
            );

            if (triggerRemoveValidation) {
              validateEmailAddress(props.sourceConnection.id, preparedEmailAddress)
                .then((result) => completeRemoteValidation(preparedEmailAddress, makeRemoteValidationResult(result)))
                .catch(() => completeRemoteValidation(preparedEmailAddress, RemoteValidationResult.Failure));
            }

            return {
              entries: state.entries.set(index, updatedEntry),
              remoteValidationResults: triggerRemoveValidation
                ? state.remoteValidationResults.set(preparedEmailAddress, undefined)
                : state.remoteValidationResults
            };
          } else {
            return {
              ...state,
              entries: state.entries.set(index, updatedEntry),
            };
          }
        } else {
          return {
            ...state,
            entries: state.entries.set(index, { ...entry, contact: updatedContact }),
          };
        }
      } else {
        return state;
      }
    });
  }

  return (
    <AmbassadorSignUpStepView
      school={props.school}
      sourceConnection={props.sourceConnection}

      emailPreviewStatus={renderEmailPreviewStatus}

      maxCustomMessageLength={MaxCustomMessageLength}
      customMessage={customMessage}
      onCustomMessageChange={setCustomMessage}

      accountAction={accountAction}
      onAccountActionChange={setAccountAction}

      accountActionDate={accountActionDate}
      onAccountActionDateChange={setAccountActionDate}

      enableEmailDeliveryController={deferredEmailDeliveryOperation ? enableEmailDeliveryController : undefined}
      onEmailDeliveryOperationCancelled={() => setDeferredEmailDeliveryOperation(undefined)}

      enableEmailAddressVerificationController={
        props.sourceConnection.roles?.contains(Constants.Auth.VerifyEmailAddresses)
          ? undefined
          : enableEmailAddressVerificationController
      }

      sendTestEmailStatus={sendTestEmailStatus}
      onSendTestEmailClick={() =>
        scheduleEmailDeliveryOperation(
          EmailDeliveryOperation.sendTestEmail({
            customMessage: preparedCustomMessage,
            accountAction,
            accountActionDate
          })
        )
      }

      contacts={contacts}
      minNumberOfContacts={minNumberOfContacts}
      maxNumberOfContacts={maxNumberOfContacts}
      onFirstNameChange={(index, firstName) =>
        updateContact(index, (contact) => [{ ...contact, firstName }, false])
      }
      onEmailAddressChange={(index, emailAddress) =>
        updateContact(index, (contact) => [{ ...contact, emailAddress }, false])
      }
      onContactValidationTriggered={(index) =>
        updateContact(index, (contact) => [contact, true])
      }
      onContactAdded={() =>
        setContactsState((state) =>
          state.entries.size < maxNumberOfContacts
            ? { ...state, entries: state.entries.push(ContactEntry.empty) }
            : state
        )
      }
      onTestContactsAdded={() => setContactsState(ContactsState.test)}
      onContactRemoved={(index) =>
        setContactsState((state) => ({
          ...state,
          entries: state.entries.remove(index).concat(
            Range(0, Math.max(0, minNumberOfContacts - state.entries.size + 1))
              .map(() => ContactEntry.empty)
              .toList()
          )
        }))
      }

      applicationStatus={
        becomeAnAmbassadorStatus.isSuccess() && sendingDelay
          ? OperationStatus.Working()
          : becomeAnAmbassadorStatus
      }
      canSubmit={validatedContacts.size >= minNumberOfContacts}
      onSubmit={() => {
        if (accountActionDate) {
          scheduleEmailDeliveryOperation(
            EmailDeliveryOperation.becomeAnAmbassador({
              customMessage: preparedCustomMessage,
              accountAction,
              accountActionDate,
              contacts: validatedContacts
            })
          );
        }
      }}
      onSkip={props.onComplete}
      onNavigateBack={props.onNavigateBack}
    />
  );
};
