import { AnyComponent } from "./component";
import { RoleComp } from "./components/roleComp";
import { AreaComp } from "./components/areaComp";
import { ConnectionComp } from "./components/connectionComp";
import { RestrictionComp } from "./components/restrictionComp";
import { SinkComp } from "./components/sinkComp";
import { List, Map, Set } from "immutable";
import { withoutUndefined } from "../utils/misc";
import { MaterializedBlueprint } from "./materializedBlueprint";
import { BlueprintException } from "./blueprintException";
import { Blueprint } from "./blueprint";
import { Connection } from "../types/models/connection";
import { BlueprintContext } from "./blueprintContext";
import { AuthProviders } from "../types/models/authProviders";
import { Connections } from "../types/models/connections";
import { Facts } from "../types/facts/facts";
import { BlueprintInputs } from "./blueprintInputs";
import { ServerElements } from "./serverElements";
import { ApolloClient } from "apollo-client";
import { GraphQL } from "../services/graphql/generated";
import { MigrationProgressComp } from "./components/migrationProgressComp";
import { FactComp } from "./components/factComp";
import { State } from "./state";
import { StorageRestrictionComp } from "./components/storageRestrictionComp";
import { Option } from "../utils/monads/option";
import { TimeEstimateComp } from "./components/timeEstimateComp";
import { Constants } from "../app/constants";
import { WorkStatus } from "../views/models/workStatus";
import { CloudServices } from "../types/models/cloudServices";
import { ChildEndpointComp, EndpointComp } from "./components/endpointComp";
import { AccessKeyStatus } from "../types/models/accessKeyStatus";

export class MaterializedMigrationBlueprint extends MaterializedBlueprint {
  public constructor(
    blueprint: Blueprint,
    context: BlueprintContext,
    // Params below are not used currently, but it's good to have them
    public readonly sourceCloudServiceId: string,
    public readonly destinationCloudServiceId: string
  ) {
    super(blueprint, context);
  }

  public withServerElements(serverElements: ServerElements): MaterializedBlueprint {
    if (serverElements.blueprintId === this.blueprint.id()) {
      return new MaterializedMigrationBlueprint(
        this.blueprint,
        this.context.withServerElements(serverElements),
        this.sourceCloudServiceId,
        this.destinationCloudServiceId
      );
    } else {
      return this;
    }
  }

  public segment(source: boolean): string {
    return source ? "Source" : "Destination";
  }

  public oppositeSegment(source: boolean): string {
    return source ? "Destination" : "Source";
  }

  public findConnectionInSegment(segment: string): ConnectionComp | undefined {
    return this.blueprint
      .find((component) =>
        component instanceof ConnectionComp && component.inSegment(segment) ? component : undefined
      );
  }

  public findConnection(source: boolean): ConnectionComp | undefined {
    return this.findConnectionInSegment(this.segment(source));
  }

  public findConnectionOrFail(source: boolean): ConnectionComp {
    const result = this.findConnection(source);
    if (result) {
      return result;
    } else {
      throw new BlueprintException("Connection component could not be found for " + this.segment(source));
    }
  }

  public authProviderId(source: boolean): string {
    const connection = this.findConnection(source);
    if (connection) {
      return connection.props.authProviderId;
    } else {
      throw new BlueprintException("Connection could not be found");
    }
  }

  public listMigrationFlows(): List<[AreaComp, SinkComp]> {
    return this.blueprint
      .list((component) => component instanceof SinkComp ? component : undefined)
      .sortBy((area) => area.props.order)
      .flatMap((sink) =>
        sink.hubs.migrationFlows.dataFlows()
          .filter((flow) => flow.component() instanceof AreaComp)
          .map((flow) => [flow.component() as AreaComp, sink] as [AreaComp, SinkComp])
      );
  }

  public listAreas(): List<[AreaComp, SinkComp | undefined, MigrationProgressComp.Output | undefined]> {
    return this.blueprint
      .list((component) => component instanceof AreaComp ? component : undefined)
      .sortBy((area) => area.props.order)
      .map((area) => [
        area,
        area.incomingRelationships()
          .filter(({ component, hub }) =>
            component instanceof SinkComp && component.hubs.migrationFlows === hub
          )
          .map(({ component }) => component as SinkComp)
          .first(undefined),
        area.incomingRelationships()
          .filter(({ component }) => component instanceof MigrationProgressComp)
          .map(({ component }) =>
            (component as MigrationProgressComp).state(this.context).resolvedOutput.toJS()
          )
          .first(undefined)
      ]);
  }

  public listSinks(): List<[SinkComp, Set<AreaComp>]> {
    return this.blueprint
      .list((component) => component instanceof SinkComp ? component : undefined)
      .sortBy((sink) => sink.props.order)
      .map((sink) => [
        sink,
        sink.hubs.migrationFlows.dataFlows()
          .filter((flow) => flow.component() instanceof AreaComp)
          .map((flow) => flow.component() as AreaComp)
          .toSet()
      ]);
  }

  public listBlockedAreaIds(): Set<string> {
    return this.listMigrationFlows()
      .filter(([area]) => area.state(this.context).isBlocked)
      .map(([area]) => area.props.title)
      .toSet();
  }

  public listRoles(source: boolean): Set<RoleComp> {
    return this
      .listMigrationFlows()
      .toSet()
      .flatMap((migrationFlow) => {
        // For source, start from Area, for destination, start from Sink
        const traceFrom = migrationFlow[source ? 0 : 1];
        return traceFrom.trace(
          (comp) => comp instanceof RoleComp ? comp : undefined,
          (comp) => comp instanceof RoleComp || !comp.inSegment(traceFrom.segment)
        );
      });
  }

  public roles(source: boolean): Set<string> {
    const roles = this.listRoles(source).map((role) => role.props.roleId);
    return this.context.authProviders
      .getOrFail(this.authProviderId(source))
      .withoutUnsupportedRoles(roles);
  }

  public missingRoles(traceFrom: AnyComponent): Set<RoleComp> {
    // Should this check for connection state first?
    return traceFrom
      .trace(
        (comp) => comp instanceof RoleComp ? comp : undefined,
        (comp) => comp instanceof RoleComp || !comp.inSegment(traceFrom.segment)
      )
      .filter((comp) => comp.state(this.context).isBlocked);
  }

  public listMigrationRoles(source: boolean): Set<string> {
    const areasOrSinks: List<AreaComp | SinkComp> = source
      ? this.listAreas()
        .filter(([area]) => area.isEnabled(this.context))
        .map(([area]) => area)
      : this.listSinks()
        .filter(([sink, areas]) => areas.find((area) => area.isEnabled(this.context)))
        .map(([sink]) => sink);

    return areasOrSinks
      .toSet()
      .flatMap((areaOrSink) => {
        return areaOrSink.trace(
          (comp) => comp instanceof RoleComp ? comp : undefined,
          (comp) => comp instanceof RoleComp || !comp.inSegment(areaOrSink.segment)
        );
      })
      .map((role) => role.props.roleId);
  }

  public listEndpointAliases(source: boolean): Set<string> {
    const segment = this.segment(source);
    return this.blueprint
      .list((component) =>
        component instanceof EndpointComp && component.inSegment(segment) ? component : undefined
      )
      .map((endpoint) => endpoint.id)
      .toSet();
  }

  public listChildEndpointAliases(area: AreaComp | SinkComp): Set<string> {
    return area.incomingRelationships()
      .map((r) => r.component instanceof ChildEndpointComp ? r.component : undefined)
      .filter((endpointOrUndefined) => endpointOrUndefined !== undefined)
      .map((endpoint) => (endpoint as ChildEndpointComp).id)
      .toSet();
  }

  public messages(startFrom: AnyComponent): List<string> {
    // const variables = new Tracer(startFrom, this.context).variables();
    // return this.blueprint.messages(startFrom, this.context).map((s) => interpolate(s, variables));
    return List();
  }

  public failedRestrictions(): List<MaterializedMigrationBlueprint.RestrictionInfo> {
    return this.blueprint
      .list<RestrictionComp<any>>((component) => component instanceof RestrictionComp ? component : undefined)
      .map((restriction): [RestrictionComp<any>, State<RestrictionComp.Output<any>>] =>
        [restriction, restriction.state(this.context)]
      )
      .filter(([restriction, state]) =>
        state.isResolved && state.output.forall((output) => !output.restrictionState)
      )
      .map(([restriction, state]): MaterializedMigrationBlueprint.RestrictionInfo => {
        const dataFlows = restriction.hubs.values.dataFlows()
          .filter((dataFlow) => dataFlow.component() instanceof SinkComp);
        return {
          restriction,
          state,
          affectedSinks: dataFlows.map((dataFlow) => dataFlow.component() as SinkComp)
        };
      });
  }

  public storageRestrictions(): List<MaterializedMigrationBlueprint.StorageRestrictionInfo> {
    return this.blueprint
      .list<StorageRestrictionComp>((component) =>
        component instanceof StorageRestrictionComp ? component : undefined
      )
      .map((restriction): [StorageRestrictionComp, State<StorageRestrictionComp.Output>] =>
        [restriction, restriction.state(this.context)]
      )
      .map(([restriction, state]): MaterializedMigrationBlueprint.StorageRestrictionInfo => {
        const dataFlows = restriction.hubs.required.dataFlows()
          .filter((dataFlow) => dataFlow.component() instanceof SinkComp);
        return {
          restriction,
          state,
          affectedSinks: dataFlows.map((dataFlow) => dataFlow.component() as SinkComp)
        };
      });
  }

  public unblockedFactIds(source?: boolean): Set<string> {
    return this.blueprint.allComponents()
      .filter((component) => source === undefined || component.inSegment(this.segment(source)))
      .flatMap((component) => component.unblockedFactIds(this.context))
      .toSet();
  }

  public allFacts(startFrom: AnyComponent): Set<FactComp<any, any, any>> {
    return startFrom.trace(
      (comp) => comp instanceof FactComp ? comp : undefined,
      (comp) => comp instanceof FactComp
    );
  }

  public failedFacts(startFrom: AnyComponent): Set<FactComp<any, any, any>> {
    return startFrom.trace(
      (comp) => comp instanceof FactComp && comp.state(this.context).isError ? comp : undefined,
      (comp) => comp instanceof FactComp
    );
  }

  public migrationTotals(): MaterializedMigrationBlueprint.MigrationTotals {
    return this.listSinks()
      .map(([sink, areas]) =>
        sink.state(this.context).output
          .map((output) => ({
            size: output.totalBytes.map((totalSize) => totalSize.currentValue()).getOrElse(() => 0),
            itemCount: output.totalItems
              .map((totalItemCount) => totalItemCount.currentValue().sum())
              .getOrElse(() => 0),
            areas: areas
              .filter((area) =>
                area.state(this.context).output.forall((areaOutput) => areaOutput.enabled)
              )
              .map((area) => area.resolvedAppTitle(this.context))
              .toList(),
          }))
          .getOrElse(() => MaterializedMigrationBlueprint.MigrationTotals.empty)
      )
      .reduce(
        MaterializedMigrationBlueprint.MigrationTotals.reduce,
        MaterializedMigrationBlueprint.MigrationTotals.empty
      );
  }

  public totalItemCountInSource(): number {
    return this.listAreas()
      .map(([area]) =>
        area.state(this.context).output
          .flatMap((output) =>
            output.totalItems.map((totalItemCount) => totalItemCount.currentValue().sum())
          )
          .getOrElse(() => 0)
      )
      .reduce((a, b) => a + b, 0);
  }

  // TODO Review this logic - no confidence that it's working correctly
  public segmentStatus(source: boolean, checkIfConnected: () => WorkStatus): WorkStatus {
    const connectionComp = this.findConnection(source);
    if (!connectionComp) {
      return WorkStatus.Pending;
    } else {
      return connectionComp.state(this.context).output
        .flatMap((output) =>
          this.context.connections.getOption(output.connectionId).map((connection) => {
            if (connection.accessKeyStatus === AccessKeyStatus.Valid) {
              return checkIfConnected();
            } else {
              return WorkStatus.Issue;
            }
          })
        )
        .getOrUse(WorkStatus.Pending);
    }
  }

  public sourceStatus(): WorkStatus {
    return this.segmentStatus(
      true,
      () => WorkStatus.reduce(
        this.listAreas().map(([area]) => area.state(this.context).toWorkStatus())
      )
    );
  }

  public destinationStatus(): WorkStatus {
    return this.segmentStatus(
      false,
      () => WorkStatus.reduce(
        this.listSinks().map(([sink]) => sink.state(this.context).toWorkStatus())
      )
    );
  }

  public isScanningInProgress(): boolean {
    return this.listMigrationFlows().some(([area, sink]) =>
      area.state(this.context).isWorking || sink.state(this.context).isWorking
    );
  }

  public migrationBlocker(): MaterializedMigrationBlueprint.MigrationBlocker | undefined {
    const sourceConnectionId = this.findConnection(true)
      ?.state(this.context)
      .output
      .map((output) => output.connectionId)
      .toJS();
    const destinationConnectionId = this.findConnection(false)
      ?.state(this.context)
      .output
      .map((output) => output.connectionId)
      .toJS();

    if (sourceConnectionId === destinationConnectionId) {
      return MaterializedMigrationBlueprint.MigrationBlocker.SourceAndDestinationAreTheSame;
    } else
      // Should reliableOutput be used here or simply output?
    if (this.isScanningInProgress()) {
      return MaterializedMigrationBlueprint.MigrationBlocker.ScanningInProgress;
    } else if (
      !this.listMigrationFlows().some(([area]) =>
        area.state(this.context).reliableOutput.exists((output) => output.enabled)
      )
    ) {
      return MaterializedMigrationBlueprint.MigrationBlocker.NothingIsSelected;
    } else if (
      // Restrictions can be in Blocked state if their Sink has no migration flows
      // (and probably if there are no permissions to read facts?)
      this.failedRestrictions().some((restriction) =>
        restriction.restriction.props.isCritical &&
        restriction.state.reliableOutput.exists((output) => !output.restrictionState)
      ) ||
      this.storageRestrictions().some((restriction) =>
        restriction.state.reliableOutput.exists((output) => !output.restrictionState)
      )
    ) {
      return MaterializedMigrationBlueprint.MigrationBlocker.PendingIssues;
    }
  }

  public remainingMigrationTime(): List<MaterializedMigrationBlueprint.RemainingMigrationTime> {
    return this.listSinks().flatMap(([sink, areas]) =>
      Option
        .mayBe(sink.incomingRelationships().find((r) => r.component instanceof TimeEstimateComp))
        .map((r) => {
          const enabled = !!areas.find((area) =>
            area.state(this.context).output.exists((output) => output.enabled)
          );

          if (enabled) {
            const timeEstimate = r.component as TimeEstimateComp;

            const estimatedTime = timeEstimate.estimatedTimeFact(this.context)
              .flatMap((c) => c.completeValue());

            const [grandTotal, grandProcessed] = timeEstimate.hubs.migrationProgresses.associations()
              .flatMap((a) =>
                a.componentOpt()
                  .filter((component) => component instanceof MigrationProgressComp)
                  .map((component) => component as MigrationProgressComp)
                  .toList()
              )
              .flatMap((migrationProgress) =>
                migrationProgress.state(this.context).output
                  .flatMap((output) =>
                    output.iteration.totalItems.map((totalMap): [number, number] => {
                      const total = totalMap.valueSeq()
                        .reduce((a, b) => a + b, 0);
                      const processed = output.iteration.processedItems.valueSeq()
                        .reduce((a, b) => a + b, 0);
                      const skipped = output.iteration.skippedItems.valueSeq()
                        .reduce((a, b) => a + b, 0);
                      if (output.completed) {
                        return [total, total];
                      } else {
                        return [total, processed + skipped];
                      }
                    })
                  )
                  .toList()
              )
              .reduce(
                ([totalA, processedA], [totalB, processedB]): [number, number] =>
                  [totalA + totalB, processedA + processedB],
                [0, 0] as [number, number]
              );

            const progress = grandTotal === 0 ? 100 : grandProcessed / grandTotal * 100;
            const preparedEstimatedTime = Math.max(Constants.DefaultTimeEstimate, estimatedTime.getOrUse(0));

            return {
              sink,
              value: {
                estimatedTime,
                progress,
                remainingTime: preparedEstimatedTime * (100 - progress) / 100
              }
            };
          } else {
            return {
              sink,
              value: undefined
            };
          }
        }).toList()
    );
  }
}

export namespace MigrationBlueprintInputs {
  interface AdvancedOptions {
    doNotCreateSubfolder?: boolean;
  }

  export interface BuildOptions {
    readonly sourceCloudServiceId: string;
    readonly destinationCloudServiceId: string;
    readonly blueprint: Blueprint;
    readonly sourceConnection: Connection | undefined;
    readonly destinationConnection: Connection | undefined;
    readonly excludedAreas: string[];
    readonly advancedOptions?: AdvancedOptions;
  }

  function excludedAreasInputs(
    blueprint: MaterializedMigrationBlueprint,
    excludedAreas: string[]
  ): Map<string, string> {
    return Map(
      blueprint.listMigrationFlows().map(([area]) => [
        area.props.title,
        (excludedAreas.indexOf(area.props.title) === -1).toString()
      ])
    );
  }

  export function disabledAreasInputs(blueprint: MaterializedMigrationBlueprint): Map<string, string> {
    return Map(
      blueprint.listMigrationFlows()
        .filter(([area, sink]) => {
          const areaState = area.state(blueprint.context);
          const sinkState = sink.state(blueprint.context);
          return areaState.isBlocked || areaState.isError || sinkState.isBlocked || sinkState.isError;
        })
        .map(([area]) => [area.props.title, "false"])
    );
  }

  export function build(options: BuildOptions): BlueprintInputs  {
    const blueprint = new MaterializedMigrationBlueprint(
      options.blueprint,
      BlueprintContext.Empty,
      options.sourceCloudServiceId,
      options.destinationCloudServiceId
    );

    const sourceConnectionInputId = blueprint.findConnectionOrFail(true).props.inputId;
    const destinationConnectionInputId = blueprint.findConnectionOrFail(false).props.inputId;

    const connections = Map([
      [sourceConnectionInputId, options.sourceConnection && options.sourceConnection.id],
      [destinationConnectionInputId, options.destinationConnection && options.destinationConnection.id],
    ]);

    const variables = Map([
      [
        "subFolderTitle",
        options.advancedOptions?.doNotCreateSubfolder
          ? ""
          : options.sourceConnection && options.sourceConnection.description
      ],
    ]);

    return new BlueprintInputs(
      connections
        .merge(excludedAreasInputs(blueprint, options.excludedAreas))
        .merge(variables)
        .filter((value) => value !== undefined && value !== null)
        .map<string>((value) => value as string)
    );
  }
}

export namespace MaterializedMigrationBlueprint {
  export interface BuildOptions extends MigrationBlueprintInputs.BuildOptions {
    readonly cloudServices: CloudServices;
    readonly authProviders: AuthProviders;
    readonly facts: Facts;
  }

  export function build(options: BuildOptions): MaterializedMigrationBlueprint {
    return new MaterializedMigrationBlueprint(
      options.blueprint,
      new BlueprintContext({
        inputs: MigrationBlueprintInputs.build(options),
        cloudServices: options.cloudServices,
        authProviders: options.authProviders,
        connections: new Connections(withoutUndefined([options.sourceConnection, options.destinationConnection])),
        facts: options.facts || Facts.Empty,
        serverElements: ServerElements.Empty
      }),
      options.sourceCloudServiceId,
      options.destinationCloudServiceId
    );
  }

  export function renderServerElements(
    apolloClient: ApolloClient<any>,
    blueprint: MaterializedMigrationBlueprint,
  ): Promise<ServerElements> {
    return apolloClient.query<GraphQL.GetRenderedBlueprintQuery, GraphQL.GetRenderedBlueprintQueryVariables>({
      query: GraphQL.GetRenderedBlueprintDocument,
      variables: {
        sourceCloudServiceId: blueprint.sourceCloudServiceId,
        destinationCloudServiceId: blueprint.destinationCloudServiceId,
        blueprintInputs: blueprint.context.inputs.values
          .map((value, key) => ({ key, value }))
          .valueSeq()
          .toArray()
      },
      fetchPolicy: "network-only"
    }).then((result) =>
      ServerElements.fromGraphQL(blueprint.blueprint.id(), blueprint.context.inputs, result.data)
    );
  }

  export interface MigrationTotals {
    size: number;
    itemCount: number;
    areas: List<string>;
  }

  export namespace MigrationTotals {
    export const empty: MigrationTotals = { size: 0, itemCount: 0, areas: List() };

    export function reduce(reduction: MigrationTotals, value: MigrationTotals): MigrationTotals {
      return {
        size: reduction.size + value.size,
        itemCount: reduction.itemCount + value.itemCount,
        areas: reduction.areas.concat(value.areas.filterNot((area) => reduction.areas.contains(area)))
      };
    }
  }

  export interface RestrictionInfo {
    restriction: RestrictionComp<any>;
    state: State<RestrictionComp.Output<any>>;
    affectedSinks: List<SinkComp>;
  }

  export interface StorageRestrictionInfo {
    restriction: StorageRestrictionComp;
    state: State<StorageRestrictionComp.Output>;
    affectedSinks: List<SinkComp>;
  }

  export enum MigrationBlocker {
    ScanningInProgress = "ScanningInProgress",
    NothingIsSelected = "NothingIsSelected",
    PendingIssues = "PendingIssues",
    SourceAndDestinationAreTheSame = "SourceAndDestinationAreTheSame"
  }

  export interface RemainingMigrationTime {
    sink: SinkComp;
    value?: RemainingMigrationTime.Value;
  }

  export namespace RemainingMigrationTime {
    export interface Value {
      estimatedTime: Option<number>;
      progress: number;
      remainingTime: number;
    }
  }
}
