import { Collectable } from "./collectable";
import { identity } from "../../utils/misc";
import { GraphQL } from "../../services/graphql/generated";
import { ItemBreakdown } from "../itemBreakdown";

export class CollectableItemBreakdown extends Collectable<ItemBreakdown, ItemBreakdown> {
  public type: string = "CollectableItemBreakdown";
}

export namespace CollectableItemBreakdown {
  export function build(
    collectable: Collectable.Data,
    partialValue: GraphQL.ItemBreakdown | null | undefined,
    completeValue: GraphQL.ItemBreakdown | null | undefined
  ): CollectableItemBreakdown {
    return new CollectableItemBreakdown(
      Collectable.makeValue(
        collectable,
        partialValue,
        completeValue,
        ItemBreakdown.fromGraphQL,
        ItemBreakdown.fromGraphQL
      )
    );
  }

  export function fromGraphQL(collectable: GraphQL.CollectableItemBreakdown): CollectableItemBreakdown {
    return build(collectable, collectable.partialValue, collectable.completeValue);
  }

  const typeConfig: Collectable.TypeConfig<ItemBreakdown, ItemBreakdown, CollectableItemBreakdown> = {
    typeName: "CollectableItemBreakdown",
    build: (value) => new CollectableItemBreakdown(value),
    completeToPartial: identity
  };

  export const { Merge, MergeOpt } = Collectable.standardMergeReducers(
    typeConfig,
    ItemBreakdown.add,
    ItemBreakdown.add,
  );

  export const { Compare, CompareCompleteValues } = Collectable.standardComparators(
    typeConfig,
    {
      compare: (a, b) =>
        ItemBreakdown.compare(a.currentValue(), b.currentValue()).toJS(),
      compareCompleteValues:(a, b) =>
        ItemBreakdown.compare(a, b).toJS()
    }
  );

  export const Reducers = [Merge, MergeOpt];
  export const Comparators = [Compare, CompareCompleteValues];
}
