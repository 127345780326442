import * as React from "react";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Batch } from "../../types/models/batch";
import { OperationStatus } from "../../types/operationStatus";
import {
  BatchLaunchToolControllerProps,
  BatchLaunchToolControllerType
} from "../../views/screens/batchStatusPageView/batchToolsPanel";

type LaunchBatchMutationHook = [() => Promise<Batch>, OperationStatus<Batch>];

function useLaunchBatchMutation(batchId: string): LaunchBatchMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useLaunchBatchMutation,
    extract: (data: GraphQL.LaunchBatchMutation) => nullToUndefined(data.launchBatch),
    complete: Batch.parse
  });

  function fireWith(): Promise<Batch> {
    return fire({
      variables: { batchId },
      retry: () => fireWith(),
    });
  }

  return [fireWith, status];
}

interface Props extends BatchLaunchToolControllerProps {
  batchId: string;
}

const BatchLaunchToolController: React.FunctionComponent<Props> = (props) => {
  const [launchBatch, status] = useLaunchBatchMutation(props.batchId);
  return props.render(() => launchBatch(), status);
};

export function useBatchLaunchToolController(batchId: string): BatchLaunchToolControllerType {
  return React.useCallback(
    (controllerProps: BatchLaunchToolControllerProps) => (
      <BatchLaunchToolController
        {...controllerProps}
        batchId={batchId}
      />
    ),
    [batchId]
  );
}
