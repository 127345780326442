import { GraphQL } from "../../services/graphql/generated";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { DiscountType } from "./discountType";
import {
  ReferralCodeAnnouncementBannerVaultMeLogoVersion
} from "../enums/referralCodeAnnouncementBannerVaultMeLogoVersion";
import { ReferralCodeAnnouncementButtonVersion } from "../enums/referralCodeAnnouncementButtonVersion";
import { Upload } from "./upload";

export interface ReferralCodeSummary {
  readonly id: number;
  readonly code: string;
  readonly organizationId: number;

  readonly isEnabled: boolean;
  readonly url: string | undefined;

  readonly discount: number;
  readonly discountType: DiscountType;

  readonly announcement: ReferralCodeSummary.Announcement;
  readonly beacon: ReferralCodeSummary.Beacon;
}

export namespace ReferralCodeSummary {
  export interface Announcement {
    readonly enabled: boolean;

    readonly bannerBackground: string;
    readonly bannerVaultMeLogoVersion: ReferralCodeAnnouncementBannerVaultMeLogoVersion;
    readonly bannerOrganizationLogoId: string | undefined;
    readonly bannerOrganizationLogoWidth: number;
    readonly bannerOrganizationLogoOffsetXRow: number;
    readonly bannerOrganizationLogoOffsetYRow: number;
    readonly bannerOrganizationLogoOffsetXColumn: number;
    readonly bannerOrganizationLogoOffsetYColumn: number;

    readonly titleBackground: string;
    readonly title: string;

    readonly bodyBackground: string;
    readonly bodyDesktop: string;
    readonly bodyMobile: string;

    readonly buttonColor: string;
    readonly buttonVersion: ReferralCodeAnnouncementButtonVersion;
  }

  export namespace Announcement {
    export function parseAnnouncement(data: GraphQL.ReferralCode_Announcement): Announcement {
      return {
        enabled: data.enabled,

        bannerBackground: data.bannerBackground,
        bannerVaultMeLogoVersion: data.bannerVaultMeLogoVersion,
        bannerOrganizationLogoId: nullToUndefined(data.bannerOrganizationLogoId),
        bannerOrganizationLogoWidth: data.bannerOrganizationLogoWidth,
        bannerOrganizationLogoOffsetXRow: data.bannerOrganizationLogoOffsetXRow,
        bannerOrganizationLogoOffsetYRow: data.bannerOrganizationLogoOffsetYRow,
        bannerOrganizationLogoOffsetXColumn: data.bannerOrganizationLogoOffsetXColumn,
        bannerOrganizationLogoOffsetYColumn: data.bannerOrganizationLogoOffsetYColumn,

        titleBackground: data.titleBackground,
        title: data.title,

        bodyBackground: data.bodyBackground,
        bodyDesktop: data.bodyDesktop,
        bodyMobile: data.bodyMobile,

        buttonColor: data.buttonColor,
        buttonVersion: data.buttonVersion
      };
    }
  }

  export interface Beacon {
    readonly enabled: boolean;
    readonly logoId: string | undefined;
    readonly logoWidth: number;
  }
  
  export namespace Beacon {
    export function parseBeacon(data: GraphQL.ReferralCode_Beacon): Beacon {
      return {
        enabled: data.enabled,
        logoId: nullToUndefined(data.logoId),
        logoWidth: data.logoWidth
      };
    }
  }
  
  export function parse(data: GraphQL.ReferralCodeSummaryFragment): ReferralCodeSummary {
    return {
      id: data.id,
      code: data.code,
      organizationId: data.organizationId,

      isEnabled: data.isEnabled,
      url: nullToUndefined(data.url),

      discount: data.discount,
      discountType: data.discountType,

      announcement: Announcement.parseAnnouncement(data.announcement),
      beacon: Beacon.parseBeacon(data.beacon)
    };
  }
}

export interface ReferralCode extends ReferralCodeSummary {
  readonly notes: string | undefined;
  readonly endUserAccess: string | undefined;

  readonly revenueShare: number;

  readonly announcementBannerOrganizationLogo: Upload | undefined;
  readonly beaconLogo: Upload | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace ReferralCode {
  export function parse(data: GraphQL.ReferralCodeFragment): ReferralCode {
    return {
      ...ReferralCodeSummary.parse(data),

      notes: nullToUndefined(data.notes),
      endUserAccess: nullToUndefined(data.endUserAccess),

      revenueShare: data.revenueShare,

      announcementBannerOrganizationLogo: mapOptional(data.announcement.bannerOrganizationLogo, Upload.parse),
      beaconLogo: mapOptional(data.beacon.logo, Upload.parse),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(referralCodeId: number): string {
    return cachedObjectId("ReferralCode", referralCodeId.toString());
  }

  export const fragmentName = "ReferralCode";
}
