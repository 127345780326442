import * as React from "react";
import { Block } from "../../../../containers/block";
import { Panel } from "../../../../containers/panel";
import { BrandedTitlePanelRow } from "../../../../containers/rows/brandedTitlePanelRow";
import { PriceIndicator } from "../../../../widgets/indicator";
import { BatchOrder } from "../../../../../types/models/batchOrder";
import { friendlyCount, friendlySize } from "../../../../../utils/formatting";
import { BreakdownRow } from "../../checkoutStepView/priceBreakdownPanel";
import { Payment } from "../../../../../migrationSetup/usePaymentFlow";
import { DiscountType } from "../../../../../types/models/discountType";

interface Props {
  batchOrder: BatchOrder;
  payment: Payment;
}

export const BatchPriceBreakdownPanel: React.FunctionComponent<Props> = (props) => (
  <Block>
    <Panel>
      <BrandedTitlePanelRow>Order Summary</BrandedTitlePanelRow>
      <BreakdownRow title={"Migration Fee"}>
        <PriceIndicator
          price={props.batchOrder.accountsFee}
          parenthetical={friendlyCount(props.batchOrder.totalAccounts, "user account")}
        />
      </BreakdownRow>
      <BreakdownRow title={"Data Fee"}>
        <PriceIndicator
          price={props.batchOrder.dataFee}
          parenthetical={
            friendlyCount(props.batchOrder.totalItems, "item") + " / " +
            friendlySize(props.batchOrder.totalBytes)
          }
        />
      </BreakdownRow>
      {props.payment.referralCodeDiscount && (
        <BreakdownRow
          title={"Alumni Association Member Benefit code \"" + props.payment.referralCodeDiscount.code + "\""}
        >
          <PriceIndicator
            price={-props.payment.referralCodeDiscount.amount}
            parenthetical={
              <>
                {props.payment.referralCodeDiscount.percentage}% off Migration Fee
                {
                  props.payment.referralCodeDiscount.discountType === DiscountType.BasePriceAndDataFees
                    ? " and Data Fee"
                    : ""
                }
              </>
            }
          />
        </BreakdownRow>
      )}
      {props.payment.couponCodeDiscount && (
        <BreakdownRow title={"Coupon code \"" + props.payment.couponCodeDiscount.code + "\""}>
          <PriceIndicator price={-props.payment.couponCodeDiscount.amount}/>
        </BreakdownRow>
      )}
      <BreakdownRow title="Order Total">
        <PriceIndicator price={props.payment.amountToBePaid}/>
      </BreakdownRow>
    </Panel>
  </Block>
);
