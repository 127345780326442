import * as React from "react";
import { AppTheme, css, styled } from "../../../app/theme";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { InfoButton, InfoButtonDefaultSize } from "../../widgets/infoButton";
import { LinkButton } from "../../widgets/linkButton";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { DisableAdminHighlight, InlineAdminContent } from "../../utils/adminHighlight";
import { PreparedHelpArticle } from "../../../utils/preparedHelpArticle";
import { Tracking } from "../../../services/tracking";
import { WidgetStatus } from "../../utils/widgetStatus";
import { SelfPosition } from "csstype";

export namespace PanelRowDefs {
  export const PanelHorizontalPadding = 1.25; // rem
  export const XSmallPanelHorizontalPadding = 0.75; // rem
  export const XSmallVerticalPaddingMultiplier = 0.75;

  export const InfoButtonPadding = 1.25;
  export const XSmallInfoButtonPadding = 0.75;

  export type RowSize = "regular" | "custom" | "small" | "smaller" | "larger" | "large" | "x-large";

  export function rowPadding(size: RowSize): number {
    switch (size) {
      case "custom":
        return 0;
      case "small":
        return 0.5;
      case "smaller":
        return 0.75;
      case "regular":
        return 1;
      case "larger":
        return 1.25;
      case "large":
        return 1.5;
      case "x-large":
        return 2;
    }
  }

  export const NoBorder = "panel-row-no-border";

  export type BorderColor = "gray" | "blue";

  export function expandBorderColor(theme: AppTheme, color?: BorderColor): string {
    switch (color) {
      case "blue":
        return theme.colors.primary;
      default:
        return theme.colors.lightGray;
    }
  }

  export const borderTopMixin = (color?: BorderColor) => css`
    &:not(:first-child) {
      border-top: 1px solid ${(props) => expandBorderColor(props.theme, color)};
      
      .${NoBorder} + &, ${IndefiniteProgressBar}:first-child + & {
        border-top: none;
      }
    }
  `;
}

interface PanelRowLayoutProps {
  padding: number;
  verticalAlignment: SelfPosition;
  adminHighlight: boolean;
  status?: WidgetStatus;
}

export const PanelRowLayout = styled.div<PanelRowLayoutProps>`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: ${(props) => props.verticalAlignment};
  padding: ${(props) => props.padding}rem ${PanelRowDefs.PanelHorizontalPadding}rem;
  flex: 1 1 auto;

  ${(props) => props.adminHighlight && css`
    border-left: 2px solid ${props.theme.colors.red};
    background: #fff9f9;
  `}
  
  ${(props) => props.status && css`
    color: ${WidgetStatus.valueColor(props.theme, props.status)};
    border-left: 2px solid ${WidgetStatus.valueStroke(props.theme, props.status)};
    background: ${WidgetStatus.valueBackground(props.theme, props.status, props.theme.colors.white)};
  `}

  ${PanelRowDefs.borderTopMixin()};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: ${(props) => props.padding * PanelRowDefs.XSmallVerticalPaddingMultiplier}rem 
      ${PanelRowDefs.XSmallPanelHorizontalPadding}rem;
  }  
`;

interface RowIconProps {
  disabled: boolean;
}

export const PanelRowIcon = styled.img<RowIconProps>`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  opacity: ${(props) => props.disabled ? 0.3 : "auto"};
  flex-shrink: 0;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.75rem;
  }
`;

const CustomIconContainer = styled.div`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.75rem;
  }
`;

const RowContent = styled.div<{ horizontalScrollBar: boolean }>`
  flex-grow: 1;
  overflow-x: ${(props) => props.horizontalScrollBar ? "auto" : "inherit"};
  
  // This is required to have wide items with overflow to work properly. Without this, there was a situation when
  // ErrorInfo with JSON technical details will not scroll horizontally, instead extended the entire panel.
  // Based on this: https://stackoverflow.com/questions/31967019/max-width-doesnt-work-in-flexbox
  min-width: 0; 
`;

const InfoButtonContainer = styled.div`
  padding-left: ${PanelRowDefs.InfoButtonPadding}rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-left: ${PanelRowDefs.XSmallInfoButtonPadding}rem;
  }
`;

const InfoButtonPlaceholder = styled.div`
  padding-left: ${() => InfoButtonDefaultSize + 1.25}rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
     padding-left: ${() => InfoButtonDefaultSize + 0.75}rem;
  }
`;

interface ActionContainerProps {
  primary: boolean;
}

const ActionContainer = styled.div<ActionContainerProps>`
  margin: 0.3rem .5rem;
  display: flex;
  justify-content: flex-end;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding-right: 0;
    padding-left: 0.75rem;
  }
`;

const Actions = styled.div`
  display: flex;
  margin: -0.3rem -0.5rem;
  padding-left: 1rem;
  
  ${LinkButton} {
    font-size: ${(props) => props.theme.font.small}rem;
  }

  ${(props) => props.theme.responsive.respondToSmall()} {
    flex-direction: column;
    padding-left: 0.75rem;
  }
`;

const Sidebar = styled.div<{ noWrap: boolean }>`
  display: flex;
  flex-direction: row-reverse;

  ${(props) => !props.noWrap && css`
    ${() => props.theme.responsive.respondToSmall()} {
      flex-direction: column;
      align-items: flex-end;
      
      ${InfoButtonContainer} {
        margin-bottom: .75rem;
      }
    }
  `}
`;

export interface PanelRowProps extends StyledComponentsProps {
  size?: PanelRowDefs.RowSize;
  icon?: any;
  iconDisabled?: boolean;
  actions?: React.ReactNode;
  helpArticle?: PreparedHelpArticle | "placeholder";
  horizontalScrollBar?: boolean;
  verticalAlignment?: SelfPosition;
  status?: WidgetStatus;
}

function renderActions(actions: React.ReactNode) {
  if (actions) {
    const items = actions instanceof Array ? actions : [actions];
    const elements = items.map((item, index) => {
      if (item) {
        const key = item.hasOwnProperty("key") ? (item as any).key : index;
        return <ActionContainer key={key} primary={index === items.length - 1}>{item}</ActionContainer>;
      } else {
        return item;
      }
    });
    return <Actions>{elements}</Actions>;
  }
}

function renderSidebar(actions: React.ReactNode, helpArticle: PreparedHelpArticle | "placeholder" | undefined) {
  if (actions || helpArticle) {
    return (
      <Sidebar noWrap={!!helpArticle}>
        {helpArticle && (
          helpArticle === "placeholder"
            ? <InfoButtonPlaceholder/>
            : (
              <InfoButtonContainer>
                <InfoButton
                  helpArticle={helpArticle}
                  clickSideEffect={() => helpArticle.id && Tracking.viewedHelpArticle(helpArticle.id)}
                />
              </InfoButtonContainer>
            )
        )}
        {renderActions(actions)}
      </Sidebar>
    );
  }
}

const InternalPanelRow: React.FunctionComponent<PanelRowProps> = (props) => {
  const adminHighlight = React.useContext(InlineAdminContent) && !React.useContext(DisableAdminHighlight);
  return (
    <PanelRowLayout
      className={props.className}
      padding={PanelRowDefs.rowPadding(props.size || "regular")}
      verticalAlignment={props.verticalAlignment || "center"}
      adminHighlight={adminHighlight}
      status={props.status}
    >
      {props.icon && (typeof props.icon === "string"
        ? <PanelRowIcon src={props.icon} disabled={props.iconDisabled || false}/>
        : <CustomIconContainer>{props.icon}</CustomIconContainer>)}
      <RowContent horizontalScrollBar={!!props.horizontalScrollBar}>
        <div>
          <InlineAdminContent.Provider value={false}>
            {props.children}
          </InlineAdminContent.Provider>
        </div>
      </RowContent>
      {renderSidebar(props.actions, props.helpArticle)}
    </PanelRowLayout>
  );
};

export const PanelRow = styled(InternalPanelRow)``;
