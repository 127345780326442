import * as React from "react";
import { styled } from "../../../app/theme";
import { SmallButton } from "../../widgets/button";
import { Set } from "immutable";
import { Roles } from "../../models/roles";
import { Checkbox } from "../../widgets/checkbox";

export interface RoleSelectorProps {
  roles: Roles;
  disabled?: boolean;
  implicitUpdates?: boolean;
  onSelect?: (roles: Set<string>) => void;
}

const CheckboxContainer = styled.div`
  margin-bottom: .3rem;
`;

const Buttons = styled.div`
  margin-top: .5rem;
`;

const RoleStatus = styled.span`
  color: white;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  margin-left: 0.8rem;
  font-weight: ${(props) => props.theme.font.medium};
`;

const Granted = styled(RoleStatus)`
  background: ${(props) => props.theme.colors.primary};
`;

const Denied = styled(RoleStatus)`
  background: ${(props) => props.theme.colors.red};
`;

interface InternalState {
  editing: boolean;
  selectedRoles: Set<string>;
}

export const RoleSelector: React.FunctionComponent<RoleSelectorProps> = (props) => {
  const [selectedRoles, setSelectedRoles] = React.useState(props.roles.selectedRoles);
  const [editing, setEditing] = React.useState(false);

  function updateSelectedRoles(roles: Set<string>): void {
    setSelectedRoles(roles);
    if (props.implicitUpdates && props.onSelect) {
      props.onSelect(roles);
    }
  }

  function roleStatus(role: string) {
    if (props.roles.grantedRoles) {
      if (props.roles.grantedRoles.includes(role)) {
        return <Granted>Granted</Granted>;
      } else if (props.roles.selectedRoles.includes(role)) {
        return <Denied>Missing</Denied>;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function renderButtons() {
    if (!props.disabled && props.onSelect) {
      const resetButton = props.roles.defaultRoles && (
        <React.Fragment>
          <SmallButton
            color="secondary"
            onClick={() => props.roles.defaultRoles && updateSelectedRoles(props.roles.defaultRoles)}
            disabled={props.roles.defaultRoles.equals(selectedRoles)}
          >
            Reset
          </SmallButton>
          &nbsp;
          &nbsp;
        </React.Fragment>
      );

      if (props.implicitUpdates) {
        return resetButton && <Buttons>{resetButton}</Buttons>;
      } else if (editing) {
        return (
          <Buttons>
            <SmallButton
              color="primary"
              onClick={() => {
                setEditing(false);
                if (props.onSelect) {
                  props.onSelect(selectedRoles);
                }
              }}
            >
              Accept
            </SmallButton>
            &nbsp;
            &nbsp;
            {resetButton}
            <SmallButton
              color="secondary"
              onClick={() => {
                setEditing(false);
                updateSelectedRoles(props.roles.selectedRoles);
              }}
            >
              Cancel
            </SmallButton>
          </Buttons>
        );
      } else {
        return (
          <Buttons>
            <SmallButton
              color="secondary"
              onClick={() => setEditing(true)}
            >
              Edit
            </SmallButton>
          </Buttons>
        );
      }
    }
  }

  return (
    <>
      {
        props.roles.allRoles.sort().map((role) => (
          <CheckboxContainer key={role}>
            <Checkbox
              name={role}
              disabled={props.disabled || (!editing && !props.implicitUpdates)}
              checked={selectedRoles.includes(role)}
              onChange={(event) => {
                const checked = event.currentTarget.checked;
                updateSelectedRoles(checked ? selectedRoles.add(role) : selectedRoles.remove(role));
              }}
            >
              {role}{roleStatus(role)}
            </Checkbox>
          </CheckboxContainer>
        ))
      }
      {renderButtons()}
    </>
  );
};
