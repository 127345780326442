import * as React from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Button } from "../widgets/button";
import { GrandOption } from "../widgets/option";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { StyleguideTable } from "./styleguideTable";
import { Panel } from "../containers/panel";
import { TextBox } from "../widgets/textBox";
import { TextField } from "../widgets/textField";
import { PasswordField } from "../widgets/passwordField";
import { Checkbox } from "../widgets/checkbox";
import { SimpleLinkButton } from "../widgets/linkButton";
import { CheckboxField } from "../widgets/checkboxField";
import { NewTabLink } from "../widgets/newTabLink";
import { FormLayout } from "../widgets/formLayout";
import { PanelRow } from "../containers/rows/panelRow";

const description =
  `Make a private copy of Gmail, Drive, Contacts, and/or Calendars from a work or school Google / G Suite account
  without changing anything in the original account`;

interface FormData {
  login: string;
  password: string;
  host: string;
  port?: number;
  useSSL: boolean;
  accept: boolean;
}

const SignupSchema = yup.object<FormData>().shape({
  login: yup.string().required("Please type your User Id"),
  password: yup.string().required("Please type your password"),
  host: yup.string().required("Please type IMAP host"),
  port: yup.number().required().min(1).max(65535).integer("Please type IMAP port"),
  useSSL: yup.boolean().required(),
  accept: yup.boolean().oneOf([true], "Please accept VaultMe Privacy Policy and Terms of Use")
});

const SignInForm: React.FunctionComponent = () => (
  <Formik<FormData>
    initialValues={{ login: "", password: "", host: "", port: "" as any, useSSL: false, accept: false }}
    validationSchema={SignupSchema}
    validateOnChange={false}
    validateOnBlur={false}
    onSubmit={(values, actions) => {
      setTimeout(
        () => {
          alert(JSON.stringify(values, undefined, 2));
          actions.setSubmitting(false);
        },
        500);
    }}
    render={(props) => (
      <Form>
        <FormLayout>
          <TextField<FormData> label="Login" name="login"/>
          <PasswordField<FormData> label="Password" name="password"/>
          <TextField<FormData> label="Host" name="host"/>
          <TextField<FormData>
            label="Port"
            name="port"
            maxLength={5}
            maxWidth={10}
            appendix={<CheckboxField<FormData> label="Use SSL" name="useSSL" noWrap={true}/>}
          />
          <CheckboxField<FormData>
            label={(
              <React.Fragment>
                I agree to the VaultMe&nbsp;
                <NewTabLink to="https://www.vaultme.com/privacy">Privacy Policy</NewTabLink>
                &nbsp;and&nbsp;
                <NewTabLink to="https://www.vaultme.com/terms">Terms of Use</NewTabLink>
              </React.Fragment>
            )}
            name="accept"
          />
          <Button color="blue" type="submit" disabled={props.isSubmitting}>
            Submit
          </Button>
        </FormLayout>
      </Form>
    )}
  />
);

export const Forms: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Grand Radio Button</StyleguideHeader>
    <StyleguideBlock>
      <GrandOption
        name="option"
        value="1"
        description={description}
        defaultChecked={true}
      >
        Checked option with description
      </GrandOption>
      <GrandOption
        name="option"
        value="2"
        description={description}
      >
        Unchecked option with description
      </GrandOption>
      <GrandOption
        name="option"
        value="3"
      >
        Unchecked option without description
      </GrandOption>
    </StyleguideBlock>

    <StyleguideHeader>Text Box</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <tbody>
        <tr>
          <th>Normal</th>
          <td>
            <TextBox/>
          </td>
        </tr>
        <tr>
          <th>Focused</th>
          <td>
            <TextBox className="focus"/>
          </td>
        </tr>
        <tr>
          <th>Error</th>
          <td>
            <TextBox error={true}/>
          </td>
        </tr>
        <tr>
          <th>Error & Focused</th>
          <td>
            <TextBox error={true} className="focus"/>
          </td>
        </tr>
        <tr>
          <th>Disabled</th>
          <td>
            <TextBox disabled={true}/>
          </td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

    <StyleguideHeader>Checkbox</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <tbody>
        <tr>
          <th>Normal</th>
          <td>
            <Checkbox>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Focused</th>
          <td>
            <Checkbox simulateFocus={true}>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Checked</th>
          <td>
            <Checkbox defaultChecked={true}>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Checked & Focused</th>
          <td>
            <Checkbox defaultChecked={true} simulateFocus={true}>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Disabled</th>
          <td>
            <Checkbox disabled={true}>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Checked & Disabled</th>
          <td>
            <Checkbox defaultChecked={true} disabled={true}>This is a checkbox</Checkbox>
          </td>
        </tr>
        <tr>
          <th>Checked & Disabled<br/>with Hidden Check Mark</th>
          <td>
            <Checkbox defaultChecked={true} disabled={true} hideCheckMarkWhenDisabled={true}>
              This is a checkbox
            </Checkbox>
          </td>
        </tr>
        <tr>
          <th>Advanced</th>
          <td>
            <Checkbox>
              This is a multi-line checkbox<br/>
              with a <SimpleLinkButton onSimpleClick={<div>Hi!</div>}>link</SimpleLinkButton> that can be<br/>
              clicked without changing the current state
            </Checkbox>
          </td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

    <StyleguideHeader>A Working Form In A Panel (Powered By Formik)</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow>
          <SignInForm/>
        </PanelRow>
      </Panel>
    </StyleguideBlock>
  </React.Fragment>
);
