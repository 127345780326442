import * as yup from "yup";
import { ParsingError } from "../types/errors/parsingError";

export type Validator<T> = (value: T) => Promise<T>;

export namespace Validator {
  export function empty<T>(value: T) {
    return Promise.resolve(value);
  }
}

function validate<T>(value: T, validator?: Validator<T> | yup.Schema<T>): Promise<T> {
  if (validator) {
    if (typeof validator === "object") {
      return validator.validate(value);
    } else {
      return validator(value);
    }
  } else {
    return Promise.resolve(value);
  }
}

export function parseJson<T>(text: string, validator?: Validator<T> | yup.Schema<T>): Promise<T> {
  try {
    return validate(JSON.parse(text), validator);
  } catch (e) {
    const snippetLength = 200;
    const snippet = text.length > snippetLength
      ? ("\"" + text.substring(0, snippetLength) + "...\" and " + (text.length - snippetLength) + " more")
      : ("\"" + text + "\"");
    const message = "Could not parse JSON string (snippet: " + snippet + ")";
    console.log(message);
    return Promise.reject(new ParsingError(message));
  }
}
