import * as React from "react";
import { CloudService } from "../../types/models/cloudService";
import { Blueprint } from "../../blueprints/blueprint";
import { Connection } from "../../types/models/connection";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { Facts } from "../../types/facts/facts";
import { useConnectionFlow } from "../useConnectionFlow";
import {
  EditBatchMigrationItemView
} from "../../views/screens/migrationSetup/batch/batchMigrationPlanStepView/editBatchMigrationItemView";
import { UserFacingError } from "../../types/userFacingError";
import { useBlueprintFacts } from "../../queries/useBlueprintFacts";
import { List } from "immutable";
import { UserType } from "../../types/models/userType";

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: Blueprint;

  adding: boolean;
  sourceConnection: Connection | undefined;
  destinationConnection: Connection | undefined;

  onSubmit: (sourceConnection?: Connection, destinationConnection?: Connection) => void;
  onCancel: () => void;
  onDelete: (() => void) | undefined;
}

export const EditBatchMigrationItem: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const [sourceCloudService, setSourceCloudService] =
    React.useState<CloudService>(props.sourceCloudService);
  const [destinationCloudService, setDestinationCloudService] =
    React.useState<CloudService>(props.destinationCloudService);

  const [sourceConnection, setSourceConnection] =
    React.useState<Connection | undefined>(props.sourceConnection);
  const [destinationConnection, setDestinationConnection] =
    React.useState<Connection | undefined>(props.destinationConnection);

  const factsBlueprint = MaterializedMigrationBlueprint.build({
    sourceCloudServiceId: sourceCloudService.id,
    destinationCloudServiceId: destinationCloudService.id,
    blueprint: props.blueprint,
    cloudServices,
    authProviders,
    sourceConnection,
    destinationConnection,
    facts: Facts.Empty,
    excludedAreas: []
  });

  const [factsStatus] = useBlueprintFacts(
    sourceCloudService.id,
    destinationCloudService.id,
    List([{
      blueprintInputs: factsBlueprint.context.inputs,
      factIds: factsBlueprint.unblockedFactIds()
    }])
  );

  const facts = factsStatus.someResult() || Facts.Empty;

  const blueprint = React.useMemo(
    () => MaterializedMigrationBlueprint.build({
      sourceCloudServiceId: sourceCloudService.id,
      destinationCloudServiceId: destinationCloudService.id,
      blueprint: props.blueprint,
      cloudServices,
      authProviders,
      sourceConnection,
      destinationConnection,
      facts,
      excludedAreas: []
    }),
    [sourceCloudService.id, destinationCloudService.id, props.blueprint, facts]
  );

  const sourceConnectionState = useConnectionFlow({
    source: true,
    cloudService: sourceCloudService,
    oppositeCloudService: destinationCloudService,
    blueprint,
    areas: blueprint.listAreas().map(([area, sink]) =>
      ({ component: area, config: {}, notUsed: !sink })
    ),
    defaultRoles: blueprint.roles(true),

    connection: sourceConnection,
    oppositeConnection: destinationConnection,

    connectionHandlers: {
      onConnect: (connection) => {
        setSourceConnection(connection);
        setSourceCloudService(cloudServices.getOrFail(connection.cloudServiceId));
      },
      // onDisconnect: () => {
      //   setSourceConnection(undefined);
      //   setSourceCloudService(props.sourceCloudService);
      // },
    },

    showRestrictions: false,
    newUserSettings: undefined
  });

  const destinationConnectionState = useConnectionFlow({
    source: false,
    cloudService: destinationCloudService,
    oppositeCloudService: sourceCloudService,
    blueprint,
    areas: blueprint.listSinks().map(([sink, areas]) =>
      ({ component: sink, config: {}, notUsed: areas.isEmpty() })
    ),
    defaultRoles: blueprint.roles(false),

    connection: destinationConnection,
    oppositeConnection: sourceConnection,

    connectionHandlers: {
      onConnect: (connection) => {
        setDestinationConnection(connection);
        setDestinationCloudService(cloudServices.getOrFail(connection.cloudServiceId));
      },
      // onDisconnect: () => {
      //   setDestinationConnection(undefined);
      //   setDestinationCloudService(props.destinationCloudService);
      // },
    },

    showRestrictions: true,
    newUserSettings: undefined
  });

  return (
    <EditBatchMigrationItemView
      sourceConnectionState={{
        ...sourceConnectionState,
        badCloudServiceError: sourceCloudService.id !== props.sourceCloudService.id
          ? UserFacingError.synthetic({
            title: UserFacingError.BadActionTitle,
            summary: "You have connected a " + sourceCloudService.name +
              " account, but this batch is configured to migrate accounts from " + props.sourceCloudService.name,
            recommendations: "If you want to migrate accounts from " +
              sourceCloudService.name + ", either go back to the first step and " +
              "change configuration for this batch, or create a separate batch for this account."
          })
          : undefined
      }}
      destinationConnectionState={{
        ...destinationConnectionState,
        badCloudServiceError: destinationCloudService.id !== props.destinationCloudService.id
          ? UserFacingError.synthetic({
            title: UserFacingError.BadActionTitle,
            summary: "You have connected a " + destinationCloudService.name +
              " account, but this batch is configured to migrate accounts to " + props.destinationCloudService.name,
            recommendations: "If you want to migrate accounts to " +
              destinationCloudService.name + ", either go back to the first step and " +
              "change configuration for this batch, or create a separate batch for this account."
          })
          : undefined
      }}

      adding={props.adding}
      submitDisabled={
        (sourceConnection === undefined && destinationConnection === undefined) ||
        (
          sourceConnection !== undefined &&
          sourceConnection.cloudServiceId !== props.sourceCloudService.id
        ) ||
        (
          destinationConnection !== undefined &&
          destinationConnection.cloudServiceId !== props.destinationCloudService.id
        )
      }

      onSubmit={() => props.onSubmit(sourceConnection, destinationConnection)}
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  );
};
