import * as React from "react";
import { AppTheme, styled, useAppTheme } from "../../app/theme";

export type SpinnerColor = "blue" | "red" | "yellow";
export type SpinnerBackgroundColor = "white" | "gray";

function spinnerColor(theme: AppTheme, color?: SpinnerColor): string {
  switch (color) {
    case "red": return theme.colors.red;
    case "yellow": return theme.colors.yellow;
    default: return theme.colors.primary;
  }
}

function backgroundColor(theme: AppTheme, color?: SpinnerBackgroundColor): string {
  switch (color) {
    case "gray": return theme.colors.lighterGray;
    default: return theme.colors.white;
  }
}

function animationKeys(count: number, f: (mult: number) => number | string): string {
  const result: (number | string)[] = [];
  for (let i = 0; i <= count; i += 1) {
    result.push(f(i / count));
  }
  return result.join(";");
}

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
`;

export interface SpinnerProps {
  progress: number;
  lineStrokeWidthMultiplier?: number;
  textStrokeWidthMultiplier?: number;
  color?: SpinnerColor;
  backgroundColor?: SpinnerBackgroundColor;
  displayPercentage?: boolean;
  animationSpeed?: number;
  icon?: React.ReactNode;
  iconOffsetX?: number;
  iconOffsetY?: number;
  spinUpSeconds?: number;
}

export const Spinner: React.FunctionComponent<SpinnerProps> = (props) => {
  const theme = useAppTheme();

  const lineStrokeWidthMultiplier = props.lineStrokeWidthMultiplier || 1;
  const strokeWidth = 1.5 * lineStrokeWidthMultiplier;
  const radius = 8;
  const length = 2 * Math.PI * (radius - strokeWidth);
  const offset = length * (1 - props.progress / 100);
  const color = spinnerColor(theme, props.color);

  const outerDivStyle: React.CSSProperties = {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "100%"
  };

  const iconContainerDivStyle: React.CSSProperties = {
    display: "block",
    position: "absolute",
    top: (30 + (props.iconOffsetY || 0)) + "%",
    right: (30 - (props.iconOffsetX || 0)) + "%",
    bottom: (30 - (props.iconOffsetY || 0)) + "%",
    left: (30 + (props.iconOffsetX || 0)) + "%"
  };

  const svgStyle = {
    transform: "rotate(-90deg)"
  };

  const outerCircleStyle = {
    fill: "transparent",
    strokeWidth,
    stroke: backgroundColor(theme, props.backgroundColor)
  };

  const innerCircleStyle = {
    stroke: color,
    strokeWidth,
    strokeDashoffset: props.progress < 0 ? "auto" : offset,
    strokeDasharray: props.progress < 0 ? "auto" : length,
    fill: "none",
    transition: "stroke-dashoffset .25s linear"
  };

  // Text transformations are not working in Safari, so the text has to be wrapped in a <g>
  const percentageGroupStyle: React.CSSProperties = {
    transform: "rotate(90deg) translate(50%, -50%) translate(0px, 0.4px)",
  };

  const percentageStyle: React.CSSProperties = {
    stroke: "none",
    fill: theme.colors.primary,
    strokeWidth: 0.3 * (props.textStrokeWidthMultiplier || 1),
    textAnchor: "middle",
    alignmentBaseline: "middle",
    // transform: "rotate(90deg) translate(50%, -50%) translate(0px, 0.4px)",
    fontSize: 4.4
  };

  function renderSpinUpAnimation() {
    if (props.spinUpSeconds) {
      const spinUpTicks = Math.max(10, props.spinUpSeconds * 4);
      const dashLength = length / 15;

      return (
        <React.Fragment>
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values={"0 " + radius + " " + radius + ";" + (360 * props.spinUpSeconds) + " " + radius + " " + radius}
            keyTimes={"0;1"}
            keySplines=".42 0 1 1"
            dur={props.spinUpSeconds + "s"}
            begin="0s"
            repeatCount={1}
          />
          <animate
            attributeName="stroke-dasharray"
            calcMode="linear"
            values={dashLength + " " + (length - dashLength) + ";" + dashLength + " " + (length - dashLength)}
            keyTimes={"0;1"}
            dur={props.spinUpSeconds + "s"}
            begin="0s"
            repeatCount={1}
          />
        </React.Fragment>
      );
    }
  }

  function renderAnimation() {
    if (props.progress < 0) {
      const minDashLength = length / 15;
      const maxDashLength = length / 2;
      const duration = (1 / (props.animationSpeed || 1));

      const dasharrayAnimation = [
        [minDashLength, length - minDashLength],
        [maxDashLength, length - maxDashLength],
        [minDashLength, length - minDashLength]
      ].map((frame) => frame.join(" ")).join(";");

      return (
        <React.Fragment>
          {renderSpinUpAnimation()}
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values={"0 " + radius + " " + radius + ";360 " + radius + " " + radius}
            keyTimes="0;1"
            dur={duration + "s"}
            begin={(props.spinUpSeconds || 0) + "s"}
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dasharray"
            calcMode="linear"
            values={dasharrayAnimation}
            keyTimes="0;0.5;1"
            dur={duration + "s"}
            begin={(props.spinUpSeconds || 0) + "s"}
            repeatCount="indefinite"
          />
        </React.Fragment>
      );
    }
  }

  return (
    <Container>
      <svg
        width="100%"
        height="100%"
        viewBox={"0 0 " + radius * 2 + " " + radius * 2}
        style={svgStyle}
      >
        <circle cx={radius} cy={radius} r={radius - strokeWidth} style={outerCircleStyle}/>
        <circle cx={radius} cy={radius} r={radius - strokeWidth} style={innerCircleStyle}>
          {renderAnimation()}
        </circle>
        {props.displayPercentage && (
          <g style={percentageGroupStyle}>
            <text x={0} y={0} style={percentageStyle}>{Math.floor(props.progress)}%</text>
          </g>
        )}
      </svg>
      {props.icon && <div style={iconContainerDivStyle}>{props.icon}</div>}
    </Container>
  );
};

export const LargeSpinner: React.FunctionComponent<SpinnerProps> = (props) => (
  <Spinner
    {...props}
    lineStrokeWidthMultiplier={0.3}
    textStrokeWidthMultiplier={0.1}
  />
);
