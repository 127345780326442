import { GraphQL } from "../../services/graphql/generated";
import { DiscountType } from "./discountType";
import { ReferralCodeSummary } from "./referralCode";

export interface ReferralCodeFormData {
  readonly code: string;

  readonly notes: string | undefined;
  readonly isEnabled: boolean;
  readonly url: string | undefined;
  readonly endUserAccess: string | undefined;

  readonly discount: number;
  readonly discountType: DiscountType;
  readonly revenueShare: number;

  readonly announcement: ReferralCodeSummary.Announcement;
  readonly beacon: ReferralCodeSummary.Beacon;
}

export namespace ReferralCodeFormData {
  export function toGraphQL(data: ReferralCodeFormData): GraphQL.ReferralCodeFormData {
    return {
      code: data.code,

      isEnabled: data.isEnabled,
      notes: data.notes,
      url: data.url,
      endUserAccess: data.endUserAccess,

      discount: data.discount,
      discountType: data.discountType,
      revenueShare: data.revenueShare,

      announcement: data.announcement,
      beacon: data.beacon,
    };
  }
}
