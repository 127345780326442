import { ProgramPerformanceDataPoint } from "./programPerformanceDataPoint";
import { List } from "immutable";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface ProgramPerformance {
  readonly programName: string | undefined;
  readonly dataPoints: List<ProgramPerformanceDataPoint>;
}

export namespace ProgramPerformance {
  export function parse(data: GraphQL.ProgramPerformance, timeZoneOffset: number): ProgramPerformance {
    return {
      programName: nullToUndefined(data.programName),
      dataPoints: List(data.dataPoints.map((dataPoint) =>
        ProgramPerformanceDataPoint.parse(dataPoint, timeZoneOffset))
      )
    };
  }
}
