import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRow } from "./panelRow";

export const TitlePanelRow = styled(PanelRow)`
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.darkerGray};
  padding-top: 0.73rem !important;
  padding-bottom: 0.73rem !important;
  text-transform: uppercase;
`;
