import * as React from "react";
import * as yup from "yup";
import * as moment from "moment";
import { GraphQL } from "../../services/graphql/generated";
import { SandboxStep1, Value } from "./sandboxStep1";
import { WorkflowContextLike } from "../workflowContextLike";
import { wizardFactory } from "../wizard/makeWizard";
import { finishWizard } from "../wizard/wizardDestination";
import { futureStep } from "../utils";
import { SandboxStep2 } from "./sandboxStep2";
import { SandboxStep0 } from "./sandboxStep0";
import { SandboxInterstitial } from "./sandboxInterstitial";
import { Observable, Subject } from "rxjs";
import { ApolloClient } from "apollo-client";
import { AnyWatcherAction } from "../../services/watcher/anyWatcherAction";
import { WatchedFacts } from "../../services/watcher/plugins/watchedFactsPlugin";
import { List } from "immutable";
import { DataSource } from "../dataSource";
import { FastPromise } from "../fastPromise";

/*
Manually edit local storage to test draft cleanup

[
  ["step0",{"result":"Hi!","complete":true}],
  ["step1",{"result":{"value":"Init error on this step"},"complete":true}]
]

(cleanup will erase the result for step1)

[
  ["step0",{"result":"Hi!","complete":true}],
  ["step1",{"result":{"value":"Init error on next step"},"complete":true}],
  ["interstitial",{"result":{"value":"Init error on next step"},"complete":true}]
]

(cleanup will un-complete step1 but will keep the result)
 */

export interface SandboxWorkflowResult {
  message: string;
}

export interface SandboxWorkflowFeedback {
  value: Value;
}

export interface SandboxWorkflowContext extends WorkflowContextLike {
  apolloClient: ApolloClient<any>;
  watcher: React.Dispatch<AnyWatcherAction>;
}

const factory = wizardFactory<SandboxWorkflowContext, SandboxWorkflowFeedback, SandboxWorkflowResult>();

export const SandboxWorkflow = factory.makeWizard({
  startFrom: () => Step0({}),
});

interface Context {
  data: string;
}

function delayed<T>(value: T, delay: number = 1000): Promise<T> {
  return new Promise((resolve) =>
    setTimeout(() => resolve(value), delay)
  );
}

function rejected<T>(): Promise<T> {
  return new Promise((resolve, reject) =>
    reject("Oops!")
  );
}

let tickerValue: boolean = true;
setInterval(() => { tickerValue = !tickerValue; }, 1000);

const observableTicker = new Observable<Context>((observer) => {
  const timer = setInterval(
    () => observer.next({ data: tickerValue ? "tick" : "tack" }),
    1000
  );

  return () => clearTimeout(timer);
});

const observableTimer = new Observable<Context>((observer) => {
  const timer = setInterval(
    () => {
      observer.next({ data: moment(new Date()).format("H:mm:ss") });
    },
    1000
  );

  return () => clearTimeout(timer);
});

// function observableGraphQLQuery(context: SandboxWorkflowContext): Observable<Context> {
//   return new Observable<Context>((subscriber) =>
//     context.apolloClient
//       .watchQuery<GraphQL.GetAllFactsQuery>({ query: GraphQL.GetAllFactsDocument })
//       .subscribe((value) => {
//         const fact = value.data.getAllFacts
//           .filter((f) => f.id === "google:tim@unboundedcloud.com/drive/sharedFiles/totalSize")
//           .pop();
//         if (fact) {
//           context.watcher(WatchedFacts.WatchFactsAction([fact]));
//           const data = fact.__typename === "IntegerFact" && fact.integerValue.complete
//             ? fact.integerValue.complete.value
//             : "???";
//           subscriber.next({ data });
//         } else {
//           console.error("Ooops!");
//         }
//       })
//   );
// }

export namespace Context {
  export function init() {
    return delayed({ data: "..." });
    // return rejected<{data: string}>();
    // return { data: "..." };
    // return observableTicker;
    // return new DataSource(FastPromise(delayed({ data: "..." })), undefined, true);
  }

  export function initForResult(context: SandboxWorkflowContext) {
    return delayed({ data: "DATA" });
    // return rejected<{data: string}>();
    // return { data: "DATA" };
    // return observableTimer;
    // return observableGraphQLQuery(context);
    // return new DataSource(FastPromise(delayed({ data: "DATA" })), undefined, true);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Step0 = factory.makeStep<{}, {}, string>({
  propsContext: () => ({}),
  resultContext: () => ({}),
  id: "step0",
  render: ({ hooks }) => <SandboxStep0 {...hooks}/>,
  title: () => "Step 0",
  description: () => "Nothing useful",
  resultSchema: yup.string(),
  route: () => Step1({}),
  getFutureSteps: () => [
    futureStep(true, "Step 1", "Something useful"),
    futureStep(true, "Step 2", "Something useful too"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Step1 {
  export interface Result {
    value: Value;
  }
}

const Step1ResultSchema = yup.object<Step1.Result>({
  value: yup.mixed().required()
});

const Step1 = factory.makeStep<{}, Context, Step1.Result>({
  propsContext: () => {
    console.log("Initializing Step 1...");
    return Context.init();
  },
  resultContext: ({ workflowContext, result }) => {
    console.log("Initializing Step 1 for result ", result);
    if (result.value === Value.InitErrorOnThisStep) {
      throw Error("Fatal error!");
    } else {
      return Context.initForResult(workflowContext);
    }
  },
  id: "step1",
  numbered: true,
  render: ({ result, hooks }) => <SandboxStep1 {...hooks} currentValue={result && result.value}/>,
  title: "Step 1",
  description: ({ propsContext }) => "Data: " + propsContext.data,
  resultSchema: Step1ResultSchema,
  validateResult: ({ result }) => result.value !== Value.Invalid,
  resultSummary: ({ result, resultContext }) => "Result: " + result.value + " (" + resultContext.data + ")",
  errorIndicator: ({ result }) => result && result.value === Value.ErrorIndicator ? "Oops!" : undefined,
  route: ({ result }) => Interstitial({ value: result.value }),
  processFeedback: ({ feedback }) => feedback,
  getFutureSteps: () => [
    futureStep(true, "Step 2", "Something useful too"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Interstitial = factory.makeInterstitialStep<Step1.Result>({
  id: "interstitial",
  renderInterstitial: ({ hooks }) => <SandboxInterstitial {...hooks}/>,
  route: ({ props }) => Step2(props),
  getFutureSteps: () => [
    futureStep(true, "Step 2", "Something useful too"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Step2 {
  export interface Props {
    value: Value;
  }

  export interface Result {
    value: Value;
  }
}

const Step2ResultSchema = yup.object<Step2.Result>({
  value: yup.mixed().required()
});

const Step2 = factory.makeStep<Step2.Props, Context, Step2.Result>({
  propsContext: ({ props }) => {
    console.log("Initializing Step 2...");
    if (props.value === Value.InitErrorOnNextStep) {
      throw Error("Fatal error!");
    } else {
      return Context.init();
    }
  },
  resultContext: ({ workflowContext, result }) => {
    console.log("Initializing Step 2 for result ", result);
    return Context.initForResult(workflowContext);
  },
  id: "step2",
  numbered: true,
  render: ({ props, hooks }) => <SandboxStep2 {...hooks} previousResult={props.value}/>,
  title: "Step 2",
  description: ({ propsContext }) => "Data: " + propsContext.data,
  progress: ({ result }) => result ? undefined : 50,
  resultSchema: Step2ResultSchema,
  resultSummary: ({ result, resultContext }) => "Result: " + result.value + " (" + resultContext.data + ")",
  route: ({ result, resultContext }) => finishWizard({
    message: "Well done! (" + result.value + ", " + resultContext.data + ")"
  })
});
