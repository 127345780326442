import * as React from "react";
import { useSession } from "../../utils/useAppState";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ConnectResult } from "../../types/models/connectResult";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { cacheConnection } from "../../queries/useConnections";
import { SignInContextType } from "../../types/models/signInContextType";
import { OperationStatus } from "../../types/operationStatus";
import { getTimeZone } from "../../utils/getTimeZone";
import { Markdown } from "../../views/widgets/markdown";

type Variables = Pick<
  GraphQL.ConnectMutationVariables,
  "contextType" | "cloudServiceId" | "oneTimeAuthCode" | "newUserSettings" | "ambassadorCode"
  >;

type ConnectMutationHook = [
  (variables: Variables) => Promise<ConnectResult>,
  OperationStatus<ConnectResult>
];

export function parseBlockedAccountExceptionMessage(
  message: string,
  cancel?: () => void
): Partial<UserFacingError.Props> {
  const trimmedMessage = message.trim();

  const text = trimmedMessage.length !== 0
    ? (() => {
      const emptyLineIndex = trimmedMessage.indexOf("\n\n");
      if (emptyLineIndex === -1) {
        return {
          title: "This account is not allowed to be used with VaultMe.",
          recommendations: trimmedMessage
        };
      } else {
        return {
          title: trimmedMessage.substring(0, emptyLineIndex),
          recommendations: <Markdown>{trimmedMessage.substring(emptyLineIndex + 2)}</Markdown>
        };
      }
    })()
    : {
      title: "This account is not allowed to be used with VaultMe.",
      recommendations: "Please contact your administrator",
    };

  return {
    ...text,
    retry: null,
    cancel: cancel || (() => { /* Do nothing */ }),
    cancelTitle: "Use a different account"
  };
}

export function useConnectMutation(
  contextType: SignInContextType,
  refreshSignInForm: () => void
): ConnectMutationHook {
  const session = useSession();

  const [fire, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useConnectMutation,
    extract: (data: GraphQL.ConnectMutation) => nullToUndefined(data.connect),
    complete: (extract) => ConnectResult.parse(extract),
    catch: [
      [
        ErrorClass.ConnectionBelongsToAnotherUserException,
        (error) => {
          function errorInfo(): { title: string, recommendations: string } {
            if (contextType === SignInContextType.App) {
              return {
                title: "This account cannot be used for signing in to VaultMe",
                recommendations: "Please sign in using one of the eligible accounts",
              };
            } else if (session) {
              return {
                title: "The account that you tried to connect is exclusively owned by another VaultMe user",
                recommendations:
                  "Please sign out from VaultMe using the \"Sign Out\" link at the top of the page, " +
                  "and sign in as the owner of the account. Then, try connecting this account again.",
              };
            } else {
              return {
                title: "The account that you tried to connect is not accessible to anonymous users",
                recommendations:
                  "Please sign in to VaultMe using the \"Sign In\" link at the top of the page, and then try again. " +
                  "Make sure to sign in as the owner of the account that you are trying to connect.",
              };
            }
          }
          return UserFacingError.expected(error, {
            ...errorInfo(),
            contactSupport: true,
            showTechnicalDetails: true,
            retry: null,
            cancel: () => {
              reset();
              refreshSignInForm();
            },
            cancelTitle: "Use a different account"
          });
        }
      ],
      [
        ErrorClass.BlockedAccountException,
        (error) => UserFacingError.expected(
          error,
          parseBlockedAccountExceptionMessage(
            error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : "",
            () => {
              reset();
              refreshSignInForm();
            }
          )
        )
      ],
      (error) => UserFacingError.unexpected(error, {
        title: "Our apologies. Something's wrong when connecting to your account.",
        summary: null,
        recommendations: "Please check your internet connection and hit \"Try again\". " +
          "If this does not help please hit \"Cancel\" button and try again.",
      })
    ],
    update: (cache, { data }) => {
      if (data && data.connect) {
        cacheConnection(cache, data.connect.connection);
      }
    }
  });

  // I don't know what retrying flag is doing here
  function fireWith(variables: Variables, retrying?: boolean): Promise<ConnectResult> {
    return fire({
      variables: {
        ...variables,
        timeZone: getTimeZone(),
      },
      retry: () => fireWith(variables, true),
      cancel: true,
      retrying
    }).then((result) => {
      reset(); // I don't know why, but it's here for a long time
      return result;
    });
  }

  return [fireWith, status];
}
