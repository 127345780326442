import * as React from "react";
import { Connection } from "../../types/models/connection";
import { AuthProvider } from "../../types/models/authProvider";
import { useAccessKeyRevoke } from "./useAccessKeyRevoke";
import { AccessKeyRevokeToolView } from "../../views/blocks/connectionManagement/accessKeyRevokeToolView";

interface AccessKeyCheckToolProps {
  connection: Connection;
}

export const AccessKeyRevokeTool: React.FunctionComponent<AccessKeyCheckToolProps> = (props) => {
  const [revoke, status] = useAccessKeyRevoke(props.connection.id, true);
  return (
    <AccessKeyRevokeToolView
      onCheck={revoke}
      status={status}
    />
  );
};
