import { styled } from "../../app/theme";
import * as React from "react";
import { TextBox } from "../widgets/textBox";
import { Button } from "../widgets/button";
import { Constants } from "../../app/constants";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-left: .5rem;
`;

interface SearchFormProps extends StyledComponentsProps {
  searchTerm: string;
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
  onReset?: () => void;
}

export const SearchForm: React.FunctionComponent<SearchFormProps> = (props) => {
  const textBoxRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [searchTerm, setSearchTerm] = React.useState(props.searchTerm);
  const trimmedSearchTerm = searchTerm.trim();

  React.useEffect(() => setSearchTerm(props.searchTerm), [props.searchTerm]);

  React.useEffect(
    () => {
      if (textBoxRef.current) {
        textBoxRef.current.focus();
      }
    },
    []
  );

  return (
    <Layout className={props.className}>
      <TextBox
        ref={textBoxRef}
        autoFocus={true}
        value={searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.charCode === 13 && trimmedSearchTerm.length >= Constants.MinSearchTermLength) {
            props.onSearch(trimmedSearchTerm);
            event.preventDefault();
          }
        }}
        placeholder={props.placeholder || "Type search criteria and press Enter"}
      />
      <ButtonContainer>
        <Button
          color="secondary"
          size="small"
          disabled={trimmedSearchTerm.length < Constants.MinSearchTermLength}
          onClick={() => props.onSearch(trimmedSearchTerm)}
        >
          Search
        </Button>
      </ButtonContainer>
      {props.onReset && (
        <ButtonContainer>
          <Button
            color="secondary"
            size="small"
            disabled={props.searchTerm.length === 0}
            onClick={props.onReset}
          >
            Reset
          </Button>
        </ButtonContainer>
      )}

    </Layout>
  );
};
