import * as React from "react";
import { styled } from "../../app/theme";
import { Switch, SwitchItem, SwitchLayout } from "../../views/widgets/switch";

export enum SidebarMode {
  Props,
  Filters,
  Inputs,
  Diffs,
}

interface SidebarModesProps {
  horizontal: boolean;
  sidebarMode: SidebarMode;
  onViewModeChange: (viewMode: SidebarMode) => void;
}

const SidebarModesLayout = styled.div<{ horizontal: boolean }>`
  padding: .5rem .5rem .5rem .5rem;
`;

export const SidebarModes: React.SFC<SidebarModesProps> = (props) => {
  const items: SwitchItem[] = [
    { value: SidebarMode.Props, content: "Props" },
    { value: SidebarMode.Filters, content: "Filters" },
    { value: SidebarMode.Inputs, content: "Inputs" },
    { value: SidebarMode.Diffs, content: "Diffs" },
  ];

  return (
    <SidebarModesLayout horizontal={props.horizontal}>
      <Switch
        layout={props.horizontal ? SwitchLayout.Vertical : SwitchLayout.Horizontal}
        items={items}
        selected={props.sidebarMode}
        onSelect={props.onViewModeChange}
      />
    </SidebarModesLayout>
  );
};
