import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { EmailAddressWhitelistingForProgram } from "../../types/models/emailAddressWhitelistingForProgram";

type WhitelistEmailAddressForProgramMutationHook = [
  (emailAddress: string, programId: string | undefined) => Promise<EmailAddressWhitelistingForProgram>,
  OperationStatus<EmailAddressWhitelistingForProgram>
];

export function useWhitelistEmailAddressForProgramMutation(): WhitelistEmailAddressForProgramMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useWhitelistEmailAddressForProgramMutation,
    extract: (data: GraphQL.WhitelistEmailAddressForProgramMutation) =>
      nullToUndefined(data.whitelistEmailAddressForProgram),
    complete: EmailAddressWhitelistingForProgram.parse
  });

  function fireWith(emailAddress: string, programId: string | undefined): Promise<EmailAddressWhitelistingForProgram> {
    return fire({
      variables: { emailAddress, programId },
      retry: () => fireWith(emailAddress, programId),
    });
  }

  return [fireWith, status];
}
