import { DataProxy } from "apollo-cache";

export function updateFragment<FragmentType, Variables = any>(
  dataProxy: DataProxy,
  fragmentRef: DataProxy.Fragment<Variables>,
  update: (fragment: FragmentType) => FragmentType | undefined
): void {
  try {
    const fragment = dataProxy.readFragment<FragmentType, Variables>(fragmentRef);

    if (fragment) {
      const updatedData = update(fragment);
      if (updatedData) {
        dataProxy.writeFragment({ ...fragmentRef, data: updatedData });
      }
    }
  } catch (error) {
    console.warn("Failed to update fragment " + fragmentRef.id, error);
  }
}

export function updateFragments<FragmentType, Variables = any>(
  dataProxy: DataProxy,
  fragmentRefs: Iterable<DataProxy.Fragment<Variables>>,
  update: (fragment: FragmentType) => FragmentType | undefined
): void {
  for (const fragmentRef of fragmentRefs) {
    updateFragment(dataProxy, fragmentRef, update);
  }
}
