import * as React from "react";
import { Constants } from "../../../app/constants";
import { List, Set } from "immutable";
import { ControllerProps } from "../../../utils/controller";
import { useManagedQuery } from "../../../services/graphql/useManagedQuery";
import { GraphQL } from "../../../services/graphql/generated";
import { nullToUndefined } from "../../../utils/misc";
import { createSignInControllers } from "../../../components/auth/createSignInControllers";
import {
  ConnectEmailDeliveryAccountViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/connectEmailDeliveryAccountController";

// TODO Remove organizationId
interface DefaultConnectEmailDeliveryAccountControllerProps
  extends ControllerProps<ConnectEmailDeliveryAccountViewProps> {
  organizationId: number;
}

const DefaultConnectEmailDeliveryAccountController
  : React.FunctionComponent<DefaultConnectEmailDeliveryAccountControllerProps> =
  (props) => {
    const roles = Set([Constants.Auth.SendEmailsRole]);

    const [signInFormConfigStatus] = useManagedQuery({
      query: GraphQL.useGetSignUpFormConfigQuery,
      deps: null,
      prepare: () => ({ authFlowId: "EnableEmailDelivery", roles: roles.toArray() }),
      extract: (data: GraphQL.GetSignUpFormConfigQuery) => nullToUndefined(data.getSignUpFormConfig),
      complete: (config) => List(config.map((signUpFormConfig) => ({
        cloudServiceId: signUpFormConfig.cloudServiceId,
        signInComponents: List(createSignInControllers(signUpFormConfig, false))
      }))),
      fetchPolicy: "network-only"
    });

    return props.render({
      roles: Set([Constants.Auth.SendEmailsRole]),
      signInFormConfigStatus
    });
  };

export function useConnectEmailDeliveryAccountController(organizationId: number) {
  return React.useCallback(
    (controllerProps: ControllerProps<ConnectEmailDeliveryAccountViewProps>) => (
      <DefaultConnectEmailDeliveryAccountController {...controllerProps} organizationId={organizationId}/>
    ),
    [organizationId]
  );
}
