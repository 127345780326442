import { FileUploadController } from "../../views/widgets/fileUpload";
import { useOperationStatus } from "../../types/operationStatus";
import { UserFacingError } from "../../types/userFacingError";
import { useRoutes } from "../../app/routes/useRoutes";
import { getCookie } from "../../utils/getCookie";

// When a single instance of this controller is used in multiple places, they will all share the same status. So,
// while a file is being uploaded in one place, all these places will display the working status. It's okay
// for now on the admin screens, but will have to be fixed once it's exposed to the end users.
export function useGenericFileUploadController(uploadUrl: string): FileUploadController {
  const operationStatus = useOperationStatus<string>();

  function fire(file: File): Promise<string> {
    operationStatus.setWorking();
    return fetch(uploadUrl, {
      method: "POST",
      headers: {
        "Content-Type": file.type,
        "X-File-Name": file.name,
        // See application.conf (csrf section)
        "Csrf-Token": getCookie("csrfToken") || ""
      },
      body: file
    }).then((response) =>
      response.text().then((text): [number, string] => [response.status, text])
    ).then(([status, body]) => {
      if (status === 200) {
        operationStatus.setSuccess(body);
        return body;
      } else {
        throw new Error("HTTP " + status + ": " + body);
      }
    }).catch((error) => {
      operationStatus.setFailure(
        UserFacingError.expected(error, {
          summary: "Failed to upload file",
          showTechnicalDetails: true
        })
      );
      throw error;
    });
  }

  return [fire, { status: operationStatus.current, reset: () => operationStatus.setPending() }];
}

export function useFileUploadController(): FileUploadController {
  return useGenericFileUploadController(useRoutes().api.uploadUrl);
}

export function useAccessListImportController(accessListId: number): FileUploadController {
  return useGenericFileUploadController(useRoutes().api.accessListImportExportUrl(accessListId));
}

export function useOffboardingProjectImportController(offboardingProjectId: string): FileUploadController {
  return useGenericFileUploadController(useRoutes().api.offboardingProjectImportExportUrl(offboardingProjectId));
}
