import { Program } from "../../types/models/program";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchProgramsQuery(
  organizationId: number | undefined,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchProgramsQueryVariables, List<Program>> {
  return useManagedQuery({
    query: GraphQL.useSearchProgramsQuery,
    deps: term,
    prepare: (deps) => ({ organizationId, term: deps }),
    extract: (data: GraphQL.SearchProgramsQuery) => nullToUndefined(data.searchPrograms),
    complete: (programs) => List(programs)
      .filter((program) => program.id !== "")
      .map(Program.parse),
    fetchPolicy: "cache-and-network"
  });
}
