import * as React from "react";
import { List } from "immutable";
import { Paragraph } from "../../../../widgets/paragraph";
import { BulletPoints } from "../../../../widgets/bulletPoints";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { ErrorInfo } from "./errorInfo";
import { sentence } from "../../../../../utils/misc";
import { ConnectLink } from "../batchConnectionStepView/endUserCredentialsPanel";

interface Props {
  source: boolean;
  connectionMethod: "admin" | "end-user" | "self";
  connectLink: string;
  emailAddresses: List<string>;
}

export const NotAccessibleAccountsError: React.FunctionComponent<Props> = (props) => {
  const context = props.source ? "source" : "destination";

  const longStory = (
    <>
      <Paragraph>
        If you've got multiple user profiles in VaultMe, you may need to sign in to another profile. Here's why:
      </Paragraph>
      <Paragraph>
        <BulletPoints>
          <li>
            When accounts are connected to VaultMe by visiting a link, access to them is only granted to the VaultMe
            user profile which used to create this link (not to all VaultMe user profiles at once). It's possible that
            access to the accounts listed above was granted to one of your other VaultMe user profiles.
          </li>
          <li>
            Due to security restrictions, only one VaultMe user profile at a time can be granted access to any given
            account connected to VaultMe. If access to the accounts listed above was first granted to your current
            VaultMe user profile and then to another profile, then your access was removed. In this situation, you may
            choose to sign in to another VaultMe user profile, or restore your access to the accounts by using the link
            given above.
          </li>
        </BulletPoints>
      </Paragraph>
    </>
  );

  return (
    <ErrorInfo title={sentence(context) + " accounts are not accessible."}>
      <Paragraph>
        The following accounts are connected to VaultMe, but they are owned by other VaultMe users and are not
        accessible to you:
      </Paragraph>
      <BulletPoints>
        {props.emailAddresses.map((emailAddress) =>
          <li key={emailAddress}>{emailAddress}</li>
        )}
      </BulletPoints>
      {(() => {
        switch (props.connectionMethod) {
          case "admin":
            return (
              <Paragraph>
                It looks like there was an error on our side.
                Please contact us at <EmailSupportLink/>, and we'll help you resolve this issue.
              </Paragraph>
            );

          case "end-user":
            return (
              <>
                <Paragraph>
                  Here's what you can try:
                </Paragraph>
                <BulletPoints>
                  <li>
                    <Paragraph>
                      Ask the owners of these accounts to grant you access by visiting the following link:
                    </Paragraph>
                    <ConnectLink link={props.connectLink}/>
                  </li>
                  <li>{longStory}</li>
                </BulletPoints>
                <Paragraph>
                  If you need assistance, contact us at <EmailSupportLink/>.
                </Paragraph>
              </>
            );

          case "self":
            return (
              <>
                <Paragraph>
                  Here's what you can try:
                </Paragraph>
                <BulletPoints>
                  <li>
                    <Paragraph>
                      Get access to these accounts or ask their owners to grant you access. This can be
                      done by visiting the following link:
                    </Paragraph>
                    <ConnectLink link={props.connectLink}/>
                  </li>
                  <li>{longStory}</li>
                </BulletPoints>
                <Paragraph>
                  If you need assistance, contact us at <EmailSupportLink/>.
                </Paragraph>
              </>
            );
        }
      })()}
    </ErrorInfo>
  );
};
