import * as React from "react";
import { styled, AppTheme } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

export type VaultMeLogoColorSchema = "white-and-blue" | "black-and-blue" | "white";

interface VaultMeLogoProps extends StyledComponentsProps {
  colorSchema: VaultMeLogoColorSchema;
  ignoreTheme?: boolean;
}

export namespace VaultMeLogoColors {
  export const white = "#ffffff";
  export const black = "#25282A";
  export const blue = "#079fef";
}

const swooshColor = (colorSchema: VaultMeLogoColorSchema, theme: AppTheme | undefined) => {
  switch (colorSchema) {
    case "white-and-blue": return theme?.colors.useMonochromeLogo ? VaultMeLogoColors.white : VaultMeLogoColors.blue;
    case "black-and-blue": return theme?.colors.useMonochromeLogo ? VaultMeLogoColors.black : VaultMeLogoColors.blue;
    case "white": return VaultMeLogoColors.white;
  }
};

const vaultColor = (colorSchema: VaultMeLogoColorSchema) => {
  switch (colorSchema) {
    case "white-and-blue": return VaultMeLogoColors.white;
    case "black-and-blue": return VaultMeLogoColors.black;
    case "white": return VaultMeLogoColors.white;
  }
};

const meColor = (colorSchema: VaultMeLogoColorSchema, theme: AppTheme | undefined) => {
  switch (colorSchema) {
    case "white-and-blue": return theme?.colors.useMonochromeLogo ? VaultMeLogoColors.white : VaultMeLogoColors.blue;
    case "black-and-blue": return theme?.colors.useMonochromeLogo ? VaultMeLogoColors.black : VaultMeLogoColors.blue;
    case "white": return VaultMeLogoColors.white;
  }
};

interface StyledGProps {
  colorSchema: VaultMeLogoColorSchema;
  ignoreTheme: boolean;
  colorForSchema: (logoTheme: VaultMeLogoColorSchema, appTheme: AppTheme | undefined) => string;
}

const StyledG = styled.svg<StyledGProps>`
  fill: ${(props) => props.colorForSchema(props.colorSchema, props.ignoreTheme ? undefined : props.theme)};
`;

// tslint:disable max-line-length
const Swoosh: React.FunctionComponent<VaultMeLogoProps> = (props) => (
  <StyledG colorSchema={props.colorSchema} ignoreTheme={!!props.ignoreTheme} colorForSchema={swooshColor}>
    <path id="swoosh" d="M27.9 0C27.9 0 27.9 0 27.9 0 29.2 0 30 0.8 30.3 1.9 30.5 3.1 30.1 4.3 28.6 5.1L1.9 17.3C1.7 16.6 2.3 15.4 4.1 14.3L25.5 0.8C26.4 0.2 27.3 0 27.9 0Z"/>
  </StyledG>
);

const Vault: React.FunctionComponent<VaultMeLogoProps> = (props) => (
  <StyledG colorSchema={props.colorSchema} ignoreTheme={!!props.ignoreTheme} colorForSchema={vaultColor}>
    <polygon id="V" points="17.6 16 10.4 33.2 5.7 20.3 0.4 22.2 7.9 40 13.5 40 24.5 13.5"/>
    <path id="A" d="M32.4 36.4C32.9 36.2 33.1 35.8 33.1 35.3L33.1 32.3C32.2 32 31.3 31.9 30.2 31.9 29 31.9 28.1 32.1 27.5 32.4 26.9 32.8 26.6 33.4 26.6 34.2 26.6 34.9 26.9 35.5 27.5 35.9 28.1 36.4 28.8 36.6 29.6 36.6 31 36.6 31.9 36.5 32.4 36.4L32.4 36.4ZM25.4 20.8C25.6 20.4 28.8 20.3 30.4 20.4 32.5 20.6 34.4 21.2 36.1 22.2 37.8 23.2 38.8 25.4 38.8 27.9L38.7 36.1C38.7 37.6 37.9 38.8 36.3 39.5 34.8 40.3 32.5 40.6 29.4 40.6 23.8 40.6 21 38.4 21 34 21 32.2 21.6 30.7 22.7 29.7 23.9 28.7 25.6 28.1 27.9 28.1 28.8 28.1 29.9 28.3 30.7 28.5 31.7 28.8 32.6 29.3 33.1 30L33.1 28.2C33.1 26 31.7 25.4 28.9 24.9 27.3 24.7 25 24.6 24 24.7L25.4 20.8Z"/>
    <path id="U" d="M42 32.7L42 20.6 47.6 20.6 47.6 32.3C47.6 33.5 47.9 34.4 48.5 35 49 35.6 49.9 35.9 51.1 35.9 52.3 35.9 53.1 35.6 53.7 35 54.2 34.4 54.5 33.5 54.5 32.3L54.5 20.6 59.8 20.6 59.8 32.7C59.8 35.4 59 37.4 57.5 38.7 56 40 53.8 40.6 51 40.6 48.2 40.6 46 40 44.4 38.6 42.8 37.3 42 35.3 42 32.7"/>
    <polygon id="L" points="64.2 40 69.8 40 69.8 13.5 64.2 13.5"/>
    <path id="T" d="M85.1 35.8C84.9 35.8 84.7 35.8 84.4 35.8 83.6 35.7 82.7 35.6 82.5 35.4 82.2 35 82 34.6 82 33.9L82 25 84.7 25 86.6 20.7 82 20.7 82 15.4 76.1 15.4 76.1 20.7 73.3 20.7 73.3 25 76.1 25 76.1 34.6C76.1 36.6 76.6 38.2 77.8 39.1 78.5 39.7 79.7 40.1 81.2 40.3 81.8 40.5 82.5 40.5 83.2 40.6L85.1 35.8Z"/>
  </StyledG>
);

const Me: React.FunctionComponent<VaultMeLogoProps> = (props) => (
  <StyledG colorSchema={props.colorSchema} ignoreTheme={!!props.ignoreTheme} colorForSchema={meColor}>
    <path id="M" d="M112.7 21.2C117 21.2 120.5 24.7 120.5 28.9L120.5 39.3C120.5 39.8 120.1 40.2 119.6 40.2 119 40.2 118.7 39.8 118.7 39.3L118.7 28.9C118.6 25.7 116 23 112.7 23 109.5 23 106.8 25.7 106.8 28.9L106.8 39.3 106.8 39.5C106.8 39.5 106.8 39.5 106.8 39.6 106.7 39.9 106.5 40 106.3 40.1 106.2 40.2 106.1 40.2 105.9 40.2 105.8 40.2 105.6 40.2 105.5 40.1 105.3 40 105.1 39.9 105 39.6 105 39.5 105 39.5 105 39.5L105 39.3 105 28.9C105 25.7 102.3 23 99.1 23 95.8 23 93.2 25.7 93.2 28.9L93.2 39.3C93.2 39.8 92.8 40.2 92.3 40.2 91.7 40.2 91.3 39.8 91.3 39.3L91.3 28.9C91.3 24.7 94.8 21.2 99.1 21.2 102 21.2 104.5 22.8 105.9 25.2 107.3 22.8 109.8 21.2 112.7 21.2"/>
    <path id="E" d="M125.3 29.4L139.8 29.4C139.2 25.8 136.1 23.2 132.6 23.2 129.1 23.2 126.1 25.8 125.3 29.4M132.6 21.3C137.5 21.3 141.4 25.2 141.8 30.2L141.8 30.4C141.8 30.9 141.4 31.2 140.9 31.2L125.2 31.2C125.4 35.4 129 38.6 133 38.6 135.5 38.6 138.2 37.2 139.4 35.1 139.7 34.6 140.3 34.5 140.7 34.8 141.1 35.1 141.3 35.6 141 36.1 139.4 38.7 136.2 40.4 133 40.4 127.8 40.4 123.4 36.1 123.4 30.9 123.4 25.6 127.5 21.3 132.6 21.3"/>
  </StyledG>
);

export const VaultMeLogo: React.FunctionComponent<VaultMeLogoProps> = (props) => (
  <svg viewBox="0 0 142 41" className={props.className}>
    <Swoosh {...props}/>
    <Vault {...props}/>
    <Me {...props}/>
  </svg>
);
