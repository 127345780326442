import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { AccessList } from "../../types/models/accessList";

type DeleteAccessListMutationHook = [(id: number) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteAccessListMutation(): DeleteAccessListMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteAccessListMutation,
    extract: (data: GraphQL.DeleteAccessListMutation) => nullToUndefined(data.deleteAccessList),
    complete: identity
  });

  function fireWith(id: number): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = AccessList.cacheId(id);

      const accessList = apolloClient.cache.readFragment<GraphQL.AccessListFragment>({
        id: cacheId,
        fragment: GraphQL.AccessListFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: AccessList.fragmentName
      });

      if (accessList) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.AccessListFragmentDoc,
          fragmentName: AccessList.fragmentName,
          data: { ...accessList, id: -1 }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
