import * as React from "react";
import { useAbortJobManagedMutation } from "./useAbortJobManagedMutation";
import { AbortJobView } from "../../views/blocks/jobManagement/abortJobView";

interface Props {
  jobId: string;
  onAborted: () => void;
}

export const AbortJob: React.FunctionComponent<Props> = (props) => {
  const [abort, abortStatus] = useAbortJobManagedMutation();

  return (
    <AbortJobView
      abortStatus={abortStatus}
      onAbort={() => abort(props.jobId).then(props.onAborted)}
    />
  );
};
