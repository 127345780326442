import { AccessList } from "../../types/models/accessList";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { AccessListFormData } from "../../types/models/accessListFormData";

type InsertAccessListMutationHook = [
  (formData: AccessListFormData) => Promise<AccessList>,
  OperationStatus<AccessList>
];

export function useInsertAccessListMutation(organizationId: number): InsertAccessListMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertAccessListMutation,
    extract: (data: GraphQL.InsertAccessListMutation) => nullToUndefined(data.insertAccessList),
    complete: AccessList.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(formData: AccessListFormData): Promise<AccessList> {
    return fire({
      variables: { organizationId, formData: AccessListFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
