import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { AccessListUpdateStatus } from "../enums/accessListUpdateStatus";

export interface OffboardingProjectUpdate {
  readonly id: string;
  readonly offboardingProjectId: string;

  readonly status: AccessListUpdateStatus;

  readonly processedEntries: number;
  readonly skippedEntries: number;
  readonly errors: number;
  readonly errorMessage: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly completedAt: Date | undefined;
}

export namespace OffboardingProjectUpdate {
  export function parse(data: GraphQL.OffboardingProjectUpdateFragment): OffboardingProjectUpdate {
    return {
      id: data.id,
      offboardingProjectId: data.offboardingProjectId,

      status: data.status,

      processedEntries: data.processedEntries,
      skippedEntries: data.skippedEntries,
      errors: data.errors,
      errorMessage: nullToUndefined(data.errorMessage),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      completedAt: mapOptional(data.completedAt, (value) => new Date(value))
    };
  }
}
