import * as React from "react";
import { css, styled } from "../../app/theme";

interface Props {
  error?: boolean;
  maxWidth?: number;
  rows?: number;
  readOnly?: boolean;
}

export const TextArea = styled.textarea.attrs((props: Props) => ({
  rows: props.rows || 5
}))<Props>`
  padding: 0 0.25rem;
  border: 2px solid ${(props) => props.error ? props.theme.colors.red : props.theme.colors.lightGray};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  max-width: ${(props) => props.maxWidth ? props.maxWidth + "rem" : "inherit"};
  ${(props) => props.theme.animation.transitionAll()};

  ${(props) => !props.readOnly && css`
    &.focus, &:focus {
      border: 2px solid ${props.error ? props.theme.colors.red : props.theme.colors.primary};
      box-shadow: 0 0 0.2rem ${props.error ? props.theme.colors.red : props.theme.colors.primary};
    }
  `}

  &[disabled] {
    background: ${(props) => props.theme.colors.lighterGray};
    color: ${(props) => props.theme.colors.darkGray};
  }
  
  &::placeholder {
    color: ${(props) => props.theme.colors.gray};
  }
`;
