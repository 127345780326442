// tslint:disable-next-line import-name
import Shepherd from "shepherd.js";
import * as React from "react";

interface Config {
  title: string;
  targetRef: React.RefObject<HTMLElement>;
  containerRef?: React.RefObject<HTMLElement>;
  disable?: boolean;
  onComplete?: () => void;
}

export function useShepherd(config: Config): void {
  React.useEffect(
    () => {
      if (!config.disable && config.targetRef.current) {
        const tour = new Shepherd.Tour({
          defaultStepOptions: {
            cancelIcon: {
              enabled: false
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 5,
            scrollTo: { behavior: "smooth", block: "center" },
            popperOptions: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [10, 20],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    altAxis: true,
                    padding: 20
                  },
                }
              ],
            }
          },
          useModalOverlay: true
        });

        function stop() {
          tour.cancel();

          if (config.targetRef.current) {
            config.targetRef.current.style.zIndex = "";

            // Not sure if this actually does something... Will reference to stop() be the same as when
            // addEventListener was called?
            config.targetRef.current.removeEventListener("click", stop);
          }

          if (config.onComplete) {
            config.onComplete();
          }
        }

        const step = tour.addStep({
          text: config.title,
          attachTo: {
            element: config.targetRef.current,
            on: "top"
          },
          buttons: [
            {
              text: "I got it",
              action: stop
            }
          ]
        });

        // Note: on("hide") did not work or some reason, had to use stop() to reset the state of the target
        step.on(
          "show",
          () => {
            if (config.containerRef?.current) {
              const element = step.getElement();
              // Shepherd always adds the tooltip to the document body, so we have to move it inside our own scrollable
              // area. Yikes!..
              if (element && element.parentNode) {
                element.parentNode.removeChild(element);
                config.containerRef.current.appendChild(element);
              }
            }

            if (config.targetRef.current) {
              config.targetRef.current.style.zIndex = "9999";
              config.targetRef.current.addEventListener("click", stop);

              const overlay = document.getElementsByClassName("shepherd-modal-overlay-container")[0];
              if (overlay) {
                overlay.addEventListener("click", stop);
              }
            }
          }
        );

        tour.start();
      }
    },
    [config.disable]
  );
}
