import * as React from "react";
import { WorkflowLayout } from "../../layouts/workflowLayout";
import { CenteredContent } from "../../layouts/centeredContent";
import { AdminPortalNav, AdminPortalSectionId } from "./adminPortalNav";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OrganizationContext } from "../../../types/models/organizationContext";

interface Props {
  sectionId: AdminPortalSectionId;
  status: OperationStatus<OrganizationContext>;
  refresh: () => void;
  children: (organizationContext: OrganizationContext, refresh: () => void) => React.ReactElement;
}

export const AdminPortalSectionView: React.FunctionComponent<Props> = (props) => (
  <WorkflowLayout
    leftHandNavContent={
      props.status.mapLastResult((context) => (
        <AdminPortalNav
          sectionId={props.sectionId}
          organization={context.organization}
          organizationPortalUserRole={context.organizationPortalUser?.role}
        />
      )) || <div/>
    }
  >
    <CenteredContent fullWidth={true}>
      <OperationStatusIndicator
        status={props.status}
        subject={"organization details"}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.status.mapLastResult((context) => props.children(context, props.refresh))}
    </CenteredContent>
  </WorkflowLayout>
);
