import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { Component, ComponentHubs } from "../component";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { OptionalRelationshipHub } from "../hubs/optionalRelationshipHub";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { parseServerJson } from "../parseServerJson";
import { SelectorHub } from "../hubs/selectorHub";
import { nullToUndefined } from "../../utils/misc";
import { ComponentBinding } from "./componentBinding";
import { List, Map, Set } from "immutable";
import { VarsHub } from "../hubs/varsHub";
import { Option } from "../../utils/monads/option";

export class PhaseComp extends Component<PhaseComp.Props, PhaseComp.Hubs, PhaseComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<PhaseComp.Output> {
    return State.blocked();
  }

  public visualization(): ComponentVisualization {
    return {
      title: "Phase",
      summary: this.props.title,
      icon: Images.Blueprint.Bolt,
      color: ComponentColorSchema.SolidYellow,
    };
  }
}

export namespace PhaseComp {
  export interface ItemTypeSelector {
    bambooItemType: string;
    withTags: Map<string, string>;
    withoutTags: Set<string>;
    itemType: string;
    itemCategory: Option<string>;
    addToSkipped: boolean;
  }

  export namespace ItemTypeSelector {
    export function fromGraphQL(props: GraphQL.PhaseCompItemTypeSelector): ItemTypeSelector {
      return {
        bambooItemType: props.bambooItemType,
        withTags: Map(props.withTags.map((entry) => [entry.tag, entry.value])),
        withoutTags: Set(props.withoutTags),
        itemType: props.itemType,
        itemCategory: Option.mayBe(props.itemCategory),
        addToSkipped: props.addToSkipped,
      };
    }
  }

  export interface Props {
    title: string;
    inputProjection: string | undefined;
    outputProjection: string | undefined;
    itemTypeSelectors: List<ItemTypeSelector>;
    endOfProcessing: boolean;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.PhaseCompProps): Props {
      return {
        title: props.title,
        inputProjection: nullToUndefined(props.inputProjection),
        outputProjection: nullToUndefined(props.outputProjection),
        itemTypeSelectors: List(props.itemTypeSelectors.map(ItemTypeSelector.fromGraphQL)),
        endOfProcessing: props.endOfProcessing
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    pullFrom: SelectorHub<string>;
    accessKey1: OptionalRelationshipHub<any>;
    accessKey2: OptionalRelationshipHub<any>;
    endpointRef1: OptionalRelationshipHub<any>;
    endpointRef2: OptionalRelationshipHub<any>;
    vars: VarsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.PhaseCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        pullFrom: binding.selectorHub("pullFrom", hubs.pullFrom),
        accessKey1: binding.optionalRelationshipHub("accessKey1", hubs.accessKey1),
        accessKey2: binding.optionalRelationshipHub("accessKey2", hubs.accessKey2),
        endpointRef1: binding.optionalRelationshipHub("endpointRef1", hubs.endpointRef1),
        endpointRef2: binding.optionalRelationshipHub("endpointRef2", hubs.endpointRef2),
        vars: binding.varsHub("vars", hubs.vars),
      };
    }
  }

  export interface Output {
    phaseId: string;
    phaseDefJson: any;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.PhaseCompOutputFragment): Output {
      return {
        phaseId: output.phaseId,
        phaseDefJson: parseServerJson(output.phaseDefJson)
      };
    }
  }
}
