import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { List } from "immutable";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { friendlyCount } from "../../../utils/formatting";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { ColumnConfig } from "./columnConfig";
import { SearchForm } from "../searchForm";
import { ItemGrid } from "./itemGrid";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "views/widgets/button";
import { styled } from "../../../app/theme";

const PaddedSearchForm = styled(SearchForm)`
  margin-left: 1rem;
`;

interface Props<Id, Item> {
  subject: string;
  layout?: "default" | "compact";
  tools?: React.ReactNode[];
  gridColumns: ColumnConfig<Item>[];

  getItemId: (item: Item) => Id;
  comparator: (a: Item, b: Item) => number;

  searchTerm: string;
  searchStatus: OperationStatus<List<Item>>;
  transientItems?: List<Item>;

  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
  onReset?: () => void;
  onSelect: (id: Id, item: Item) => void;
}

export function SearchPanel<Id, Item>(props: Props<Id, Item>): React.ReactElement {
  const tools = props.tools || [];

  const items = (props.searchStatus.someResult() || List())
    .concat(props.transientItems || List())
    .sort(props.comparator);

  const resultSummary =
    "Found " + friendlyCount(items.size, props.subject) +
    (props.searchTerm ? " matching \"" + props.searchTerm + "\"" : "");

  const refreshButton = (
    <Button
      size={"small"}
      color={"secondary"}
      disabled={props.searchStatus.isWorking()}
      // Make sure we won't pass the onClick event as a hidden argument
      onClick={() => props.onRefresh()}
    >
      Refresh
    </Button>
  );

  return (
    <Panel>
      {
        props.layout === "compact"
          ? (
            <PanelRow>
              <SimpleToolbar
                items={tools.concat([<React.Fragment key={"count"}>{resultSummary}</React.Fragment>])}
                rightSide={(
                  <SimpleToolbar>
                    {refreshButton}
                    <PaddedSearchForm
                      searchTerm={props.searchTerm}
                      placeholder={"Search"}
                      onSearch={props.onSearch}
                      onReset={props.onReset}
                    />
                  </SimpleToolbar>
                )}
                centerVertically={true}
              />
            </PanelRow>
          )
          : (
            <>
              {tools.length !== 0 && <PanelRow><SimpleToolbar items={tools}/></PanelRow>}
              <PanelRow>
                <SearchForm searchTerm={props.searchTerm} onSearch={props.onSearch} onReset={props.onReset}/>
              </PanelRow>
            </>
          )
      }
      <OperationStatusIndicator
        progressMessage={"Searching..."}
        status={props.searchStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      {(props.searchStatus.isSuccess() || !items.isEmpty()) && (
        <>
          {props.layout !== "compact" && (
            <PanelRow size={"smaller"}>
              <SimpleToolbar rightSide={refreshButton} centerVertically={true}>{resultSummary}</SimpleToolbar>
            </PanelRow>
          )}
          <GridPanelRow>
            <ItemGrid
              gridColumns={props.gridColumns}
              getItemId={props.getItemId}
              items={items}
              onSelect={props.onSelect}
            />
          </GridPanelRow>
        </>
      )}
    </Panel>
  );
}
