import * as React from "react";
import { SecurityStatementRow } from "../../securityStatementRow";
import { SignInDefs } from "../../auth/signInDefs";
import { SignInFlowView } from "../../auth/signInFlowView";
import { AuthContext } from "../../authContext";
import { CloudServices } from "../../../../types/models/cloudServices";
import { AuthProviders } from "../../../../types/models/authProviders";
import { SignInFlowState } from "../../../../components/auth/useSignInFlow";
import { Set } from "immutable";
import { UserFacingError } from "../../../../types/userFacingError";

interface Props {
  cloudServices: CloudServices;
  authProviders: AuthProviders;

  authContext: AuthContext;
  signInState: SignInFlowState;

  grantedRoles?: Set<string>;
  defaultRoles: Set<string>;

  showSecurityStatement: boolean;
  badConnectionError: UserFacingError | undefined;

  onCloudServiceSelect?: (cloudServiceId: string) => void;
  onRolesSelect: (roles: Set<string>) => void;
  onSignIn: SignInDefs.SignInHandle;
}

export const ConnectingState: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <SignInFlowView
        authContext={props.authContext}
        cloudServices={props.cloudServices}
        authProviders={props.authProviders}
        state={props.signInState}
        grantedRoles={props.grantedRoles}
        defaultRoles={props.defaultRoles}
        badConnectionError={props.badConnectionError}
        onCloudServiceSelect={props.onCloudServiceSelect}
        onRolesSelect={props.onRolesSelect}
        onSignIn={props.onSignIn}
      />
      {props.showSecurityStatement && <SecurityStatementRow/>}
    </>
  );
};
