import * as React from "react";
import { User } from "../../../types/models/user";
import { OperationStatus } from "../../../types/operationStatus";
import { Batch } from "../../../types/models/batch";
import { ModalController, useModal } from "../../layouts/modal";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { DrawerBlock } from "../../containers/drawerBlock";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";
import { ListPanelRow } from "../../containers/rows/listPanelRow";
import { Link } from "react-router-dom";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { BatchAssignmentTool } from "./batchAssignmentTool";
import { useRoutes } from "../../../app/routes/useRoutes";

export interface BatchAssignmentToolControllerProps {
  render: (
    renderUserSelectorPanel: () => React.ReactElement,
    user: User | undefined,
    onAssign: (user: User) => Promise<any>,
    onChange: () => void,
    status: OperationStatus<any>
  ) => React.ReactElement;
}

export type BatchAssignmentToolControllerType = React.ComponentType<BatchAssignmentToolControllerProps>;

interface BatchInsightsViewProps {
  batchId: string;
  batchStatus: OperationStatus<Batch & Batch.HasMigrations>;

  batchAssignmentToolController: BatchAssignmentToolControllerType;
}

export const BatchInsightsView: React.FunctionComponent<BatchInsightsViewProps> = (props) => {
  const assignmentToolModal = useModal();
  const routes = useRoutes();

  return (
    <>
      <OperationStatusIndicator
        status={props.batchStatus}
        subject={"batch"}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.batchStatus.isSuccess() && (
        <>
          <DrawerBlock>
            <ModalController
              modal={assignmentToolModal}
              title={"Assign Batch"}
              wide={true}
              render={(close) =>
                React.createElement(props.batchAssignmentToolController, {
                  render: (renderUserSelectorPanel, user, onAssign, onChange, status) => (
                    <BatchAssignmentTool
                      renderUserSelectorPanel={renderUserSelectorPanel}
                      user={user}
                      onAssign={(selectedUser) => onAssign(selectedUser).then(close)}
                      onChange={onChange}
                      status={status}
                    />
                  )
                })
              }
            />

            <Panel>
              <PanelRow>
                Batch ID: {props.batchId}
              </PanelRow>
              <ListPanelRow size={"smaller"}>
                <ul>
                  <li><Link to={routes.jobs.jobPath(props.batchStatus.result.jobId)}>Batch job</Link></li>
                  <li><Link to={routes.users.userDetailsPath(props.batchStatus.result.userId)}>User profile</Link></li>
                </ul>
              </ListPanelRow>
            </Panel>
          </DrawerBlock>
          <DrawerBlock>
            <Panel>
              <PanelRow>
                <SimpleToolbar>
                  <Button size="small" color={"white"} onClick={assignmentToolModal.open}>Assign To User</Button>
                </SimpleToolbar>
              </PanelRow>
            </Panel>
          </DrawerBlock>
        </>
      )}
    </>
  );
};
