import * as React from "react";
import { DrawerHooks } from "../views/layouts/drawer";
import { List } from "immutable";
import { MigrationIssue } from "../types/models/migrationIssue";
import { ActionItem } from "../views/models/actionItem";
import {
  InvalidAccessKeyMigrationIssueView,
  ServiceNotEnabledMigrationIssueView,
  StorageQuotaExceededMigrationIssueView,
  TooManyRequestsMigrationIssueView,
  UnrecognizedBlockingMigrationIssueView,
  UnrecognizedNonBlockingMigrationIssueView
} from "../views/screens/migrationStatusPageView/components/migrationIssueView";
import { MigrationStatusPageDefs } from "../views/screens/migrationStatusPageView/migrationStatusPageDefs";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { identity } from "../utils/misc";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { useCloudServices } from "../app/configuration";
import { AreaComp } from "../blueprints/components/areaComp";
import { SinkComp } from "../blueprints/components/sinkComp";
import { IncrementalSignIn } from "../migrationSetup/incrementalSignIn";
import { CloudService } from "../types/models/cloudService";
import { UserFacingError } from "../types/userFacingError";
import { OperationStatus } from "../types/operationStatus";
import { Migration } from "../types/models/migration";

interface RenderMigrationActionItemsOptions {
  drawer: DrawerHooks;

  migration: Migration & Migration.HasConnections;
  blueprint: MaterializedMigrationBlueprint;
  source: boolean;
  areaOrSink: AreaComp | SinkComp | undefined;

  migrationIssues: List<MigrationIssue>;
}

export function renderMigrationActionItems(options: RenderMigrationActionItemsOptions): List<ActionItem> {
  const { drawer, areaOrSink, migrationIssues } = options;

  const appTitle = areaOrSink?.props.appTitle;
  const mainSubject = areaOrSink?.props.mainSubject;

  return migrationIssues.map((issue) => {
    const { message, content } = renderIssue({
      migration: options.migration,
      blueprint: options.blueprint,
      source: options.source,
      appTitle,
      mainSubject,

      issue,

      onResolution: () => drawer.close()
    });

    return {
      id: issue.id,
      type: issue.isBlocking ? ActionItem.Type.Error : ActionItem.Type.Warning,
      message: message + (issue.isResolved() ? " (Fixed, resuming migration...)" : ""),
      actions: issue.isResolved()
        ? []
        : [{
          title: issue.isBlocking ? "Fix" : "Learn more",
          onClick: () => drawer.open({ content })
        }],
      suppressAction: undefined
    };
  });
}

interface IssueDef {
  message: string;
  content: React.ReactNode;
}

const UnexpectedIssueMessage = "VaultMe has encountered an unexpected issue";

interface RenderIssueOptions {
  migration: Migration & Migration.HasConnections;
  blueprint: MaterializedMigrationBlueprint;
  source: boolean;
  appTitle: string | undefined;
  mainSubject: string | undefined;

  issue: MigrationIssue;

  onResolution: () => void;
}

function renderIssue(options: RenderIssueOptions): IssueDef {
  const { issue, source } = options;

  if (issue.class === MigrationIssue.IssueClass.InvalidAccessKeyIssue) {
    return {
      message: "VaultMe has lost connection to your account",
      content: <AuthMigrationIssueHandler {...options} view={InvalidAccessKeyMigrationIssueView}/>
    };
  } else if (issue.class === MigrationIssue.IssueClass.ServiceNotEnabledIssue) {
    return {
      message: "VaultMe is unable to " + (source ? "read" : "write") + " your " +
        (options.mainSubject ? options.mainSubject + "s" : "data"),
      content: <SimpleMigrationIssueHandler {...options} view={ServiceNotEnabledMigrationIssueView}/>
    };
  } else if (issue.class === MigrationIssue.IssueClass.StorageQuotaExceededIssue) {
    return {
      message: "Your account is out of available storage space",
      content: <SimpleMigrationIssueHandler {...options} view={StorageQuotaExceededMigrationIssueView}/>
    };
  } else if (issue.class === MigrationIssue.IssueClass.TooManyRequestsIssue) {
    return {
      message: "VaultMe has encountered an issue which may extend your migration's estimated time to completion",
      content: <SimpleMigrationIssueHandler {...options} view={TooManyRequestsMigrationIssueView}/>
    };
  } else if (!issue.isBlocking) {
    return {
      message: UnexpectedIssueMessage,
      content: <SimpleMigrationIssueHandler {...options} view={UnrecognizedBlockingMigrationIssueView}/>
    };
  } else {
    return {
      message: UnexpectedIssueMessage,
      content: <SimpleMigrationIssueHandler {...options} view={UnrecognizedNonBlockingMigrationIssueView}/>
    };
  }
}

function useCloudService(migration: Migration, source: boolean): CloudService {
  const cloudServices = useCloudServices();
  const cloudServiceId = source ? migration.sourceCloudServiceId : migration.destinationCloudServiceId;
  return cloudServices.getOrFail(cloudServiceId);
}

interface SimpleMigrationIssueHandlerProps extends RenderIssueOptions {
  view: MigrationStatusPageDefs.SimpleMigrationIssueViewComponentType;
}

const SimpleMigrationIssueHandler: React.FunctionComponent<SimpleMigrationIssueHandlerProps> = (props) => {
  const [resolveIssue, { status }] = useManagedMutation({
    mutation: GraphQL.useResolveIssueMutation,
    extract: (data: GraphQL.ResolveIssueMutation) => data.resolveIssue,
    complete: identity
  });

  const cloudService = useCloudService(props.migration, props.source);

  function resolve(): Promise<any> {
    return resolveIssue({
      variables: { migrationId: props.migration.id, issueId: props.issue.id },
      retry: resolve,
    }).then(props.onResolution);
  }

  return React.createElement(props.view, {
    migration: props.migration,
    issue: props.issue,

    source: props.source,
    providerName: cloudService.name,
    appName: props.appTitle,
    mainSubject: props.mainSubject,
    needAnotherRound: false,

    status,

    onResolve: resolve
  });
};

interface AuthMigrationIssueHandlerProps extends RenderIssueOptions {
  view: MigrationStatusPageDefs.AuthMigrationIssueViewComponentType;
}

const AuthMigrationIssueHandler: React.FunctionComponent<AuthMigrationIssueHandlerProps> = (props) => {
  const [resolveIssue, { status }] = useManagedMutation({
    mutation: GraphQL.useResolveIssueMutation,
    extract: (data: GraphQL.ResolveIssueMutation) => data.resolveIssue,
    complete: identity
  });

  const [validationError, setValidationError] = React.useState<UserFacingError>();

  const cloudService = useCloudService(props.migration, props.source);

  const [needAnotherRound, setNeedAnotherRound] = React.useState(false);

  function resolve(): Promise<any> {
    return resolveIssue({
      variables: { migrationId: props.migration.id, issueId: props.issue.id },
      retry: resolve,
    }).then((result) => {
      if (result.__typename === "ResolveIssueResult_PartiallyResolved") {
        setNeedAnotherRound(true);
      } else {
        props.onResolution();
      }
    });
  }

  const connection = props.source
    ? props.migration.sourceConnection
    : props.migration.destinationConnection;

  return React.createElement(props.view, {
    migration: props.migration,
    issue: props.issue,

    source: props.source,
    providerName: cloudService.name,
    appName: props.appTitle,
    mainSubject: props.mainSubject,
    needAnotherRound,

    status: validationError ? OperationStatus.Failure(validationError) : status,

    onReconnect: (connectResult) => {
      if (connectResult.connection.id !== connection.id) {
        setValidationError(UserFacingError.synthetic({
          title: UserFacingError.BadActionTitle,
          summary: "You need to sign in to " + connection.descriptionOrId()
        }));
      } else {
        setValidationError(undefined);
        resolve();
      }
    },

    signInComponent: (signInComponentProps) => (
      <IncrementalSignIn
        cloudService={cloudService}
        connection={connection}
        roles={props.blueprint.listMigrationRoles(props.source)}
        {...signInComponentProps}
      />
    ),
  });
};
