import * as React from "react";

interface PreloadProps {
  src: any;
}

export class Preload extends React.Component<PreloadProps> {
  public componentDidMount() {
    new Image().src = this.props.src;
  }

  public render() {
    return null;
  }
}
