import { OperationStatus } from "../../types/operationStatus";
import { Set } from "immutable";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { RevealedAccessKey } from "../../types/models/revealedAccessKey";

type AccessKeyRevealHook = [
  () => Promise<RevealedAccessKey>,
  OperationStatus<RevealedAccessKey>,
  RevealedAccessKey | undefined
];

export function useAccessKeyReveal(connectionId: string, roles: Set<string>): AccessKeyRevealHook {
  const [revealAccessKey, { status, lastResult }] = useManagedMutation({
    mutation: GraphQL.useRevealAccessKeyMutation,
    extract: (data: GraphQL.RevealAccessKeyMutation) => nullToUndefined(data.revealAccessKey),
    complete: (extract) => RevealedAccessKey.fromGraphQL(extract.revealedAccessKey),
    cancel: true
  });

  function reveal(): Promise<RevealedAccessKey> {
    return revealAccessKey({
      variables: { connectionId, roles: roles.toArray() },
      retry: reveal,
    });
  }

  return [reveal, status, lastResult];
}
