import * as React from "react";
import { Anchor } from "./anchor";
import { WorkItemMutationResult } from "./workItemMutationResult";
import { WorkItemDef } from "./makeWorkItem";
import { WorkflowContextLike } from "./workflowContextLike";
import { Path } from "./path";
import { DataSource } from "./dataSource";

export interface WorkItemConfig<
  WorkflowContext extends WorkflowContextLike,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  > {
  readonly workflowContext: WorkflowContext;

  readonly id: string;
  readonly nestedPath: Path;

  readonly props: Props;

  readonly isCompleted: boolean;
  readonly result?: Result;
  readonly lastCompletedResult?: Result;
  readonly propsContext?: PropsContext;
  readonly resultContext?: ResultContext;
}

export namespace WorkItemConfig {
  export function paramsForInitialized<
    WorkflowContext extends WorkflowContextLike,
    Props,
    PropsContext,
    Result,
    ResultContext extends PropsContext = PropsContext
    >(
    config: WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>,
    validatedResult: Result |  undefined
  ): WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext> {
    if (config.propsContext) {
      return {
        workflowContext: config.workflowContext,
        props: config.props,
        result: validatedResult,
        propsContext: config.propsContext,
        resultContext: config.resultContext
      };
    } else {
      throw Error(`Work item ${config.id} must be initialized`);
    }
  }

  export function paramsForComplete<
    WorkflowContext extends WorkflowContextLike,
    Props,
    PropsContext,
    Result,
    ResultContext extends PropsContext = PropsContext
    >(
    config: WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>,
    validatedResult: Result |  undefined
  ): WorkItemDef.ParamsForComplete<WorkflowContext, Props, PropsContext, Result, ResultContext> {
    if (validatedResult !== undefined && config.resultContext !== undefined) {
      return {
        workflowContext: config.workflowContext,
        props: config.props,
        result: validatedResult,
        resultContext: config.resultContext
      };
    } else {
      console.error(`Work item ${config.id} must be completed with valid result`, config);
      throw Error(`Work item ${config.id} must be completed with valid result`);
    }
  }
}

export type WorkItemConfigUpdates<
  WorkflowContext extends WorkflowContextLike,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  > = Partial<Pick<
  WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>,
  "isCompleted" | "result" | "lastCompletedResult" | "propsContext" | "resultContext"
  >>;

export type InjectedWorkItemConfig<
  WorkflowContext extends WorkflowContextLike,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  > =
  Omit<WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>, "props">;

export interface RenderHooks<WorkflowFeedback, Result> {
  onComplete: (result: Result) => void;
  onApply: (result: Result) => void;
  onClear: () => void;
  onFeedback: (feedback: WorkflowFeedback) => void;
  onNavigateTo: (path: Path | string) => void;
  onNavigateBack?: () => void;
}

export type AnyRenderHooks<WorkflowFeedback> = RenderHooks<WorkflowFeedback, any>;

export interface WorkItem<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  >
  extends WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext> {

  copy(
    updates: WorkItemConfigUpdates<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ): WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>;

  getPropsContext(): DataSource<PropsContext>;

  getResultContext(): DataSource<ResultContext>;

  /**
   * Validate the nested path and return the closest valid FULL path
   */
  getValidatedPath(nestedPath: Path): Path;

  /**
   * Full path to the very last inner work item
   */
  getLastNestedWorkItemPath(): Path;

  /**
   * Full path to the previous nested work item, or undefined if the current item is the first one
   */
  getPreviousNestedWorkItemPath(): Path | undefined;

  apply(result: any): WorkItemMutationResult.OneOf<Result>;

  complete(result: any): WorkItemMutationResult.OneOf<Result>;

  processFeedback(feedback: WorkflowFeedback): WorkItemMutationResult.OneOf<Result>;

  getValidatedResult(): Result | undefined;

  getPropsKey(): string;

  getResultKey(): string | undefined;

  getLastCompletedResultKey(): string | undefined;

  isCompleteWithValidResult(): boolean;

  render(hooks: RenderHooks<WorkflowFeedback, Result>): React.ReactElement;

  getAnchor(): Anchor | undefined;

  sideEffect(last: boolean): void;
}

export type AnyWorkItem<WorkflowContext extends WorkflowContextLike, WorkflowState> =
  WorkItem<WorkflowContext, WorkflowState, any, any, any, any>;

export class WorkItemDecorator<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  >
  implements WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {

  protected decoratedWorkItem: WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>;

  constructor(workItem: WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>) {
    this.decoratedWorkItem = workItem;
  }

  get workItem(): WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {
    return this.decoratedWorkItem;
  }

  get workflowContext(): WorkflowContext {
    return this.workItem.workflowContext;
  }

  get id(): string {
    return this.workItem.id;
  }

  get nestedPath(): Path {
    return this.workItem.nestedPath;
  }

  get props(): Props {
    return this.workItem.props;
  }

  get isCompleted(): boolean {
    return this.workItem.isCompleted;
  }

  get result(): Result | undefined {
    return this.workItem.result;
  }

  get lastCompletedResult(): Result | undefined {
    return this.workItem.lastCompletedResult;
  }

  get propsContext(): PropsContext | undefined {
    return this.workItem.propsContext;
  }

  get resultContext(): ResultContext | undefined {
    return this.workItem.resultContext;
  }

  public copy(
    updates: WorkItemConfigUpdates<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ): WorkItemDecorator<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {
    return new WorkItemDecorator(this.decoratedWorkItem.copy(updates));
  }

  public getPropsContext(): DataSource<PropsContext> {
    return this.workItem.getPropsContext();
  }

  public getResultContext(): DataSource<ResultContext> {
    return this.workItem.getResultContext();
  }

  public getValidatedPath(nestedPath: Path): Path {
    return this.workItem.getValidatedPath(nestedPath);
  }

  public getLastNestedWorkItemPath(): Path {
    return this.workItem.getLastNestedWorkItemPath();
  }

  public getPreviousNestedWorkItemPath(): Path | undefined {
    return this.workItem.getPreviousNestedWorkItemPath();
  }

  public apply(result: any): WorkItemMutationResult.OneOf<Result> {
    return this.workItem.apply(result);
  }

  public complete(result: any): WorkItemMutationResult.OneOf<Result> {
    return this.workItem.complete(result);
  }

  public processFeedback(feedback: WorkflowFeedback): WorkItemMutationResult.OneOf<Result> {
    return this.workItem.processFeedback(feedback);
  }

  public getValidatedResult(): Result | undefined {
    return this.workItem.getValidatedResult();
  }

  public getPropsKey(): string {
    return this.workItem.getPropsKey();
  }

  public getResultKey(): string | undefined {
    return this.workItem.getResultKey();
  }

  public getLastCompletedResultKey(): string | undefined {
    return this.workItem.getLastCompletedResultKey();
  }

  public isCompleteWithValidResult(): boolean {
    return this.workItem.isCompleteWithValidResult();
  }

  public render(hooks: RenderHooks<WorkflowFeedback, Result>): React.ReactElement {
    return this.workItem.render(hooks);
  }

  public getAnchor(): Anchor | undefined {
    return this.workItem.getAnchor();
  }

  public sideEffect(last: boolean): void {
    this.workItem.sideEffect(last);
  }
}

export type ConfigurableWorkItemFactory<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > = (
  config: InjectedWorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>,
) => WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>;

export type WorkItemFactory<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > = (
  props: Props
) => ConfigurableWorkItemFactory<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>;
