import * as React from "react";
import { styled } from "../../app/theme";

const Body = styled.div`
  ol, ul {
    margin-block-start: -.25rem;
    margin-block-end: 1.5rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 2rem;
    line-height: 1.5;
  }
  
  h1 {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 3rem 0 0.75rem 0;
    color: ${(props) => props.theme.colors.primary};
  }
  
  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    margin: 1.2rem 0 0.75rem 0;
  }
  
  p {
    margin: 0.75rem 0;
    line-height: 1.5;
  }
  
  img, svg {
    position: relative;
    display: block;
    margin: 2.5rem 0;
    max-width: 86%;
  }
  
  div.svg-container, div.img-container {
    display: block;
    margin: 2.5rem 0;
    max-width: 86%;
    position: relative;
    cursor: zoom-in;
    
    & > svg, & > img {
      display: block;
      margin: 0;
      max-width: 100%;
    }
    
    &::after {
      content: "\\21f1";
      background: rgba(255, 255, 255, 0.6);
      color: #999999;
      font-size: 2rem;
      line-height: 1;
      position: absolute;
      right: -0.5rem;
      bottom: -0.5rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      transition: all 0.1s;
      cursor: pointer;
    }
    
    &:hover {
      &::after {
        background: rgba(255, 255, 255, 0.9);
        color: ${(props) => props.theme.colors.primary};
        font-size: 3rem;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  
  table {
    margin: 1rem 0;
  }
  
  li {
    margin: 0.5rem 0;
  }
  
  pre {
    margin: 0.5rem 0;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    padding: .5rem;
  }
`;

interface Props {
  html?: string;
}

export const DrawerContent: React.FunctionComponent<Props> = (props) => {
  const bodyRef: React.RefObject<HTMLDivElement> = React.createRef();
  const overlayRef = React.useRef<HTMLDivElement>();

  React.useEffect(
    () => {
      if (bodyRef.current) {
        const svgs = bodyRef.current.getElementsByTagName("svg");
        for (const image of svgs) {
          if (image.parentElement && image.parentElement.className === "svg-container") {
            image.parentElement.addEventListener("click", () => zoomIn(image));
          }
        }

        const imgs = bodyRef.current.getElementsByTagName("img");
        for (const image of imgs) {
          if (image.parentElement && image.parentElement.className === "img-container") {
            image.parentElement.addEventListener("click", () => zoomIn(image));
          }
        }

        const anchors = bodyRef.current.getElementsByTagName("a");
        for (const anchor of anchors) {
          anchor.addEventListener(
            "click",
            (event) => {
              event.preventDefault();
              window.open(anchor.href, "_blank");
            }
          );
        }
      }
    },
    [props.html, bodyRef.current]
  );

  function zoomOut(): void {
    if (overlayRef.current) {
      document.body.removeChild(overlayRef.current);
    }
  }

  function zoomIn(image: SVGSVGElement | HTMLImageElement): void {
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.zIndex = "2000";
    overlay.style.left = "0";
    overlay.style.top = "0";
    overlay.style.right = "0";
    overlay.style.bottom = "0";
    overlay.style.background = "rgba(0, 0, 0, 0.7)";
    overlay.addEventListener("click", zoomOut);
    document.body.appendChild(overlay);

    const background = document.createElement("div");
    background.style.position = "absolute";
    background.style.left = "5%";
    background.style.top = "5%";
    background.style.right = "5%";
    background.style.bottom = "5%";
    background.style.background = "white";
    background.style.cursor = "zoom-out";
    overlay.appendChild(background);

    const newImage = image.cloneNode(true) as (SVGSVGElement | HTMLImageElement);
    newImage.style.position = "absolute";
    newImage.style.left = "5%";
    newImage.style.top = "5%";
    newImage.style.width = "90%";
    newImage.style.height = "90%";
    newImage.style.cursor = "zoom-out";
    newImage.style.margin = "0";
    newImage.style.objectFit = "contain";
    background.appendChild(newImage);

    overlayRef.current = overlay;
  }

  return (
    <Body
      ref={bodyRef}
      dangerouslySetInnerHTML={props.html !== undefined ? { __html: props.html } : undefined}
    >
      {props.children}
    </Body>
  );
};
