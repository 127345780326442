import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { Upload } from "./upload";

export interface SchoolSummary {
  readonly id: string;

  readonly officialName: string | undefined;
  readonly commonName: string | undefined;
  readonly logoId: string | undefined;
  readonly logoWidth: number | undefined;
  readonly landingPageUrl: string | undefined;

  readonly welcomeMessage: string | undefined;
}

export namespace SchoolSummary {
  export function parse(data: GraphQL.SchoolSummaryFragment): SchoolSummary {
    return {
      id: data.id,

      officialName: nullToUndefined(data.officialName),
      commonName: nullToUndefined(data.commonName),
      logoId: nullToUndefined(data.logoId),
      logoWidth: nullToUndefined(data.logoWidth),
      landingPageUrl: nullToUndefined(data.landingPageUrl),

      welcomeMessage: nullToUndefined(data.welcomeMessage)
    };
  }
}

export interface School extends SchoolSummary {
  readonly domains: string;

  readonly mascot: string | undefined;
  readonly logo: Upload | undefined;

  readonly country: string | undefined;
  readonly type: string | undefined;
  readonly website: string | undefined;
  readonly ncesUrl: string | undefined;
  readonly avgAnnualGrads: number | undefined;
  readonly usesGoogle: boolean | undefined;
  readonly usesMicrosoft: boolean | undefined;

  readonly notes: string | undefined;

  readonly reviewed: boolean;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly title: string;
}

export namespace School {
  export function parse(data: GraphQL.SchoolFragment): School {
    return {
      ...SchoolSummary.parse(data),

      domains: data.domains,

      mascot: nullToUndefined(data.mascot),
      logo: mapOptional(data.logo, (upload) => Upload.parse(upload)),

      country: nullToUndefined(data.country),
      type: nullToUndefined(data.type),
      website: nullToUndefined(data.website),
      ncesUrl: nullToUndefined(data.ncesUrl),
      avgAnnualGrads: nullToUndefined(data.avgAnnualGrads),
      usesGoogle: nullToUndefined(data.usesGoogle),
      usesMicrosoft: nullToUndefined(data.usesMicrosoft),

      notes: nullToUndefined(data.notes),

      reviewed: data.reviewed,

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),

      title: (
        data.officialName ||
        data.domains.split("\n").find((line) => line.trim().length !== 0) ||
        ""
      ).trim()
    };
  }

  export function cacheId(schoolId: string): string {
    return cachedObjectId("School", schoolId);
  }

  export const fragmentName = "School";
}
