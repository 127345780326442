import { Option } from "../../utils/monads/option";
import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const path: string = "path";
}

namespace WizardSteps {
  export const providers = "types";
  export const source = "source";
  export const destination = "destination";
}

export class BatchMigrationSetupRoutes {
  private readonly root = "setup-batch";

  constructor(private context: RoutesContext) {}

  public params(): BatchMigrationSetupRoutes.Params {
    return { path: Option.mayBe(this.context.routeParams[RouteParams.path]) };
  }

  public get homeMask(): string {
    return this.root;
  }

  public get wizardPathMask(): string {
    return this.root + "/:" + RouteParams.path + "/*";
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public wizardPath(wizardPath: string): string {
    return this.context.resolvePath(this.root + (wizardPath.startsWith("/") ? "" : "/") + wizardPath);
  }

  public get providersPath(): string {
    return this.wizardPath(WizardSteps.providers);
  }

  public get sourcePath(): string {
    return this.wizardPath(WizardSteps.source);
  }

  public get destinationPath(): string {
    return this.wizardPath(WizardSteps.destination);
  }
}

export namespace BatchMigrationSetupRoutes {
  export interface Params {
    path: Option<string>;
  }
}
