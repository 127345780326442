import * as React from "react";
import {
  RemoveSponsorshipLimitControllerProps
} from "../../../views/screens/organizationPortal/dashboard/organizationDashboardView";
import {
  useClearSponsorshipLimitAmountRemainingMutation
} from "../../../queries/organizations/useClearSponsorshipLimitAmountRemainingMutation";

interface Props extends RemoveSponsorshipLimitControllerProps {
  organizationId: number;
}

export const ToolController: React.FunctionComponent<Props> = (props) => {
  const [submit, status] = useClearSponsorshipLimitAmountRemainingMutation(props.organizationId);

  return props.render({
    status,
    onSubmit: submit
  });
};

export function useRemoveSponsorshipLimitController(organizationId: number) {
  return React.useCallback(
    (controllerProps: RemoveSponsorshipLimitControllerProps) =>
      <ToolController {...controllerProps} organizationId={organizationId}/>,
    [organizationId]
  );
}
