import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { Panel } from "../containers/panel";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { Button } from "../widgets/button";
import { DrawerBlock } from "../containers/drawerBlock";
import { PanelRow } from "../containers/rows/panelRow";
import { None, Option, Some } from "../../utils/monads/option";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { Form, Formik } from "formik";
import { FormButtons, FormLayout } from "../widgets/formLayout";
import { TextField } from "../widgets/textField";
import * as yup from "yup";
import { TextAreaField } from "../widgets/textAreaField";

interface CheckoutToolsViewProps {
  status: OperationStatus<number>;
  isWorking: boolean;

  onExternalPayment: (amount: number, note: Option<string>) => void;
}

export const CheckoutToolsView: React.FunctionComponent<CheckoutToolsViewProps> = (props) => {
  const [paymentFormOpen, setPaymentFormOpen] = React.useState(false);
  return (
    <>
      <OperationStatusIndicator
        subject={"migration"}
        status={props.status}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.status.isSuccess() && (
        <>
          <DrawerBlock>
            <Panel>
              <PanelRow>
                <SimpleToolbar>
                  <Button
                    size={"small"}
                    color={"red"}
                    onClick={() => props.onExternalPayment(0, None())}
                    disabled={props.isWorking}
                  >
                    Skip Payment
                  </Button>
                  <Button
                    size={"small"}
                    color={paymentFormOpen ? "blue" : "white"}
                    onClick={() => setPaymentFormOpen(true)}
                    disabled={props.isWorking}
                  >
                    Submit External Payment
                  </Button>
                </SimpleToolbar>
              </PanelRow>
              {paymentFormOpen && (
                <PanelRow>
                  <ExternalPaymentForm
                    price={props.status.result}
                    disabled={props.isWorking}
                    onSubmit={props.onExternalPayment}
                    onCancel={() => setPaymentFormOpen(false)}
                  />
                </PanelRow>
              )}
            </Panel>
          </DrawerBlock>
          {props.children}
        </>
      )}
    </>
  );
};

interface ExternalPaymentFormData {
  amount: number;
  note: string;
}

namespace ExternalPaymentFormData {
  export function initialValues(price: number): ExternalPaymentFormData {
    return { amount: price, note: "" };
  }

  export const validationSchema: yup.ObjectSchema<ExternalPaymentFormData> =
    yup.object<ExternalPaymentFormData>().shape({
      amount: yup.number().test(
        "maxDigitsAfterDecimal",
        "Amount must have 2 digits after decimal or less",
        (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
      ),
      note: yup.string()
    });
}

interface ExternalPaymentFormProps {
  price: number;
  disabled: boolean;

  onSubmit: (amount: number, note: Option<string>) => void;
  onCancel: () => void;
}

const ExternalPaymentForm: React.FunctionComponent<ExternalPaymentFormProps> = (props) => {
  const amountRef = React.createRef<HTMLInputElement>();

  React.useEffect(
    () => {
      if (amountRef.current) {
        amountRef.current.focus();
      }
    },
    []
  );

  return (
    <Formik<ExternalPaymentFormData>
      initialValues={ExternalPaymentFormData.initialValues(props.price)}
      validationSchema={ExternalPaymentFormData.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(data, actions) => {
        const trimmedNote = data.note.trim();
        // Note: data.amount appears to be a string when passed from Formik
        props.onSubmit(Number(data.amount), trimmedNote ? Some(trimmedNote) : None());
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form>
          <FormLayout>
            <TextField<ExternalPaymentFormData>
              label={"Amount"}
              name="amount"
              required={true}
              disabled={props.disabled}
              textBoxRef={amountRef}
            />
            <TextAreaField<ExternalPaymentFormData>
              label={"Note"}
              name="note"
              placeholder={"(Optional) How did you receive the payment?"}
              disabled={props.disabled}
            />
            <FormButtons>
              <Button type="submit" size={"small"} disabled={formProps.isSubmitting || props.disabled}>
                Submit
              </Button>
              <Button size={"small"} color={"white"} onClick={props.onCancel}>
                Cancel
              </Button>
            </FormButtons>
          </FormLayout>
        </Form>
      )}
    />
  );
};
