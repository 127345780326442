import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List, Set } from "immutable";

type DeleteAccessListEntriesMutationHook = [
  (emailAddresses: Set<string>) => Promise<[number, List<string>]>,
  OperationStatus<[number, List<string>]>
];

export function useDeleteAccessListEntriesMutation(accessListId: number): DeleteAccessListEntriesMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteAccessListEntriesMutation,
    extract: (data: GraphQL.DeleteAccessListEntriesMutation) => nullToUndefined(data.deleteAccessListEntries),
    complete: (result): [number, List<string>] => [result.deletedEntryCount, List(result.skippedEmailAddresses)]
  });

  function fireWith(emailAddresses: Set<string>): Promise<[number, List<string>]> {
    return fire({
      variables: { id: accessListId, emailAddresses: emailAddresses.toArray() },
      retry: () => fireWith(emailAddresses),
    });
  }

  return [fireWith, status];
}
