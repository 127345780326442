import * as React from "react";
import { Panel } from "../../../containers/panel";
import { Migration } from "../../../../types/models/migration";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { MigrationReportRow } from "./migrationReportRow";
import { IterationLaunchControllerType, IterationsHistoryControllerType, IterationToolsRow } from "./iterationToolsRow";
import { Connections } from "../../../../types/models/connections";
import { OperationStatus } from "../../../../types/operationStatus";
import { ControllerProps } from "../../../../utils/controllerProps";
import { ModalController, useModal } from "../../../layouts/modal";
import { DisconnectTool } from "./disconnectTool";
import { Button } from "../../../widgets/button";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import { MigrationInvoiceRow } from "./migrationInvoiceRow";
import { UpdateCustomerDetailsToolControllerType } from "../../../blocks/updateCustomerDetailsForm";
import { DisconnectIcon } from "../../../glyphs/disconnectIcon";

export interface DisconnectViewProps {
  onDisconnect: () => Promise<Connections>;
  status: OperationStatus<Connections>;
}

export type DisconnectControllerProps = ControllerProps<DisconnectViewProps>;

export type DisconnectControllerType = React.ComponentType<DisconnectControllerProps>;

interface Props {
  migration: Migration;
  migrationReportUrl: string;

  invoiceToolController: UpdateCustomerDetailsToolControllerType;
  iterationLaunchController: IterationLaunchControllerType;
  iterationsHistoryController: IterationsHistoryControllerType;
  disconnectController: DisconnectControllerType | undefined;

  onIterationSelect: (iterationIndex: number | undefined) => void;
}

export const MigrationToolsPanel: React.FunctionComponent<Props> = (props) => {
  const disconnectModal = useModal();

  function renderDisconnectModal(disconnectController: DisconnectControllerType) {
    return (
      <ModalController
        modal={disconnectModal}
        render={(close) => React.createElement(disconnectController, {
          render: (viewProps) => (
            <DisconnectTool
              status={viewProps.status}
              onDisconnect={() => viewProps.onDisconnect().then(close)}
            />
          )
        })}
      />
    );
  }

  return (
    <>
      {props.disconnectController && renderDisconnectModal(props.disconnectController)}
      <Panel>
        <TitlePanelRow>Next Steps</TitlePanelRow>
        <MigrationReportRow
          migration={props.migration}
          downloadUrl={props.migrationReportUrl}
        />
        {!props.migration.batch && <MigrationInvoiceRow invoiceToolController={props.invoiceToolController}/>}
        <IterationToolsRow
          migration={props.migration}
          iterationLaunchController={props.iterationLaunchController}
          iterationsHistoryController={props.iterationsHistoryController}
          onIterationSelect={props.onIterationSelect}
        />
        {props.disconnectController && (
          <ToolsRow
            icon={<DisconnectIcon/>}
            header={"Disconnect all your accounts currently connected to VaultMe"}
          >
            <Button color={"white"} size={"small"} onClick={disconnectModal.open}>Disconnect accounts</Button>
          </ToolsRow>
        )}
      </Panel>
    </>
  );
};
