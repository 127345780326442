import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { ArrayHub } from "../hubs/arrayHub";
import { ComponentHubs, Component } from "../component";
import { ReducerRegistry } from "../reducerRegistry";
import { Reducer } from "../reducer";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { GraphQL } from "../../services/graphql/generated";
import { BlueprintException } from "../blueprintException";
import { ComponentBinding } from "./componentBinding";
import { Variant } from "../../types/models/variant";
import { Some } from "../../utils/monads/option";

export class ReducerComp<T> extends Component<ReducerComp.Props, ReducerComp.Hubs<T>, ReducerComp.Output<T>> {
  public get reducer(): Reducer<T> {
    return ReducerRegistry.get(this.props.reducerType);
  }

  // public isUnblocked(context: BlueprintContext): boolean {
  //   return this.standardHubsUnblocked(context);
  // }

  public stateWhenUnblocked(context: BlueprintContext): State<ReducerComp.Output<T>> {
    return State.reduceStates<T, ReducerComp.Output<T>>(
      this.hubs.inputs.dataFlows().map((dataFlow) => dataFlow.state(context)),
      this.props.ignoreBlocked,
      (values) => Some({ value: this.reducer.reduce(values) })
    );
  }

  public visualization(): ComponentVisualization {
    const reducerTitle = this.reducer.title;
    return {
      title: "Reducer",
      summary: this.props.name + (reducerTitle ? "\n" + reducerTitle : ""),
      color: ComponentColorSchema.SolidBrown
    };
  }
}

export namespace ReducerComp {
  export interface Props {
    name: string;
    reducerType: string;
    ignoreBlocked: boolean;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.ReducerCompProps): Props {
      if (ReducerRegistry.keys().indexOf(props.reducerType) !== -1) {
        return {
          name: props.name,
          reducerType: props.reducerType,
          ignoreBlocked: props.ignoreBlocked,
        };
      } else {
        throw new BlueprintException("Unknown reducer " + props.reducerType);
      }
    }
  }

  export interface Hubs<T> extends ComponentHubs {
    inputs: ArrayHub<T>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.ReducerCompHubsFragment): Hubs<any> {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        inputs: binding.arrayHub("inputs", hubs.inputs),
      };
    }
  }

  export interface Output<T> {
    value: T;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.ReducerCompOutputFragment): Output<any> {
      return {
        value: Variant.fromGraphQL(output.reducerCompOutputValue)
      };
    }
  }
}
