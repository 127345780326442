import * as React from "react";
import { Delayed } from "../../utils/delayed";
import { Constants } from "../../../app/constants";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { PanelRow } from "./panelRow";
import { wrapped } from "../../utils/wrapped";

export interface ProgressIndicatorRowProps {
  delay?: number;
  wrapper?: React.ComponentType;
}

export const ProgressIndicatorRow: React.FunctionComponent<ProgressIndicatorRowProps> = (props) => {
  return (
    <Delayed delay={props.delay !== undefined ? props.delay : Constants.ProgressIndicatorDelay}>
      {
        wrapped(
          (
            <>
              <IndefiniteProgressBar/>
              <PanelRow>{props.children}</PanelRow>
            </>
          ),
          props.wrapper
        )
      }
    </Delayed>
  );
};
