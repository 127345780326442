import * as React from "react";
import { PanelRow } from "../../containers/rows/panelRow";
import { Indicator, Indicators, IndicatorValue, JsonIndicator } from "../../widgets/indicator";
import { Panel } from "../../containers/panel";
import { JobHistory } from "../../../types/models/jobHistory";
import { preciseTimestamp } from "../../../utils/formatting";
import { Button } from "../../widgets/button";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { Constants } from "../../../app/constants";
import { Delayed } from "../../utils/delayed";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Checkbox } from "../../widgets/checkbox";

interface Props {
  jobHistory: JobHistory;

  refreshing: boolean;
  onRefresh: () => void;

  onScheduleTransition: () => void;
  onAbort: () => void;

  includeMinorUpdates: boolean;
  onToggleIncludeMinorUpdates: () => void;

  onShowRunningTasksOnly: () => void;
  onShowCompletedTasksOnly: () => void;
  onShowPendingIssuesOnly: () => void;
  onShowResolvedIssuesOnly: () => void;
}

export const JobInfoPanel: React.FunctionComponent<Props> = (props) => {
  const job = props.jobHistory.job;
  return (
    <Panel>
      {props.refreshing && (
        <Delayed delay={Constants.ProgressIndicatorDelay}>
          <IndefiniteProgressBar/>
        </Delayed>
      )}
      <PanelRow size={"smaller"}>
        <SimpleToolbar>
          <Button
            size={"small"}
            color={"white"}
            onClick={props.onRefresh}
            disabled={props.refreshing}
          >
            Refresh
          </Button>
          <Checkbox
            checked={props.includeMinorUpdates}
            onChange={props.onToggleIncludeMinorUpdates}
          >
            List minor job events
          </Checkbox>
          <Button
            size={"small"}
            color={"white"}
            onClick={props.onScheduleTransition}
          >
            Schedule Transition
          </Button>
          <Button
            size={"small"}
            color={"white"}
            onClick={props.onAbort}
          >
            Abort
          </Button>
        </SimpleToolbar>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"Type"}>
            <IndicatorValue value={job.type}/>
          </Indicator>
          <Indicator title={"Started By"}>
            <IndicatorValue value={job.updatedBy || "?"}/>
          </Indicator>
          <Indicator title={"Started At"}>
            <IndicatorValue value={preciseTimestamp(job.createdAt)}/>
          </Indicator>
          <Indicator title={"Completed At"}>
            <IndicatorValue value={job.completedAt ? preciseTimestamp(job.completedAt) : "--"}/>
          </Indicator>
          <Indicator title={"Archived At"}>
            <IndicatorValue value={job.archivedAt ? preciseTimestamp(job.archivedAt) : "--"}/>
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <JsonIndicator title={"Props"} value={job.propsJson} version={job.propsVersion}/>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"System Status"}>
            <IndicatorValue value={job.internalStatus}/>
          </Indicator>
          <Indicator title={"Transition"}>
            <IndicatorValue value={job.transition}/>
          </Indicator>
          <Indicator title={"Current Transition"}>
            <IndicatorValue
              value={
                job.currentStatus === job.desiredStatus
                  ? job.currentStatus
                  : (job.currentStatus || "(None)") + " \u2192 " + job.desiredStatus
              }
            />
          </Indicator>
          <Indicator title={"Scheduled Transition"}>
            <IndicatorValue value={job.nextStatus ? "\u2192 " + job.nextStatus : "--"}/>
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"Tasks"}>
            <IndicatorValue
              value={props.jobHistory.runningTasksCount}
              suffix={"Running"}
              onClick={props.onShowRunningTasksOnly}
            />
            <IndicatorValue
              value={props.jobHistory.completedTasksCount}
              suffix={"Completed"}
              onClick={props.onShowCompletedTasksOnly}
            />
          </Indicator>
          <Indicator title={"Issues"}>
            <IndicatorValue
              value={props.jobHistory.pendingTaskIssuesCount}
              suffix={"Pending"}
              onClick={props.onShowPendingIssuesOnly}
            />
            <IndicatorValue
              value={props.jobHistory.resolvedTaskIssuesCount}
              suffix={"Resolved"}
              onClick={props.onShowResolvedIssuesOnly}
            />
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <JsonIndicator title={"State"} value={job.stateJson} version={job.stateVersion}/>
      </PanelRow>
    </Panel>
  );
};
