import { ApolloError } from "apollo-client";

export enum ErrorClass {
  AccessDeniedException = "AccessDeniedException",
  APITransportError_InvalidAccessKey = "GoogleAPITransportError$InvalidAccessKey",
  BlockedAccountException = "BlockedAccountException",
  AvsValidationException = "AvsValidationException",
  BraintreeException = "BraintreeException",
  ConnectionBelongsToAnotherUserException = "ConnectionBelongsToAnotherUserException",
  CvvValidationException = "CvvValidationException",
  ConnectionError = "ConnectionError",
  InvalidCredentialsError = "InvalidCredentialsError",
  InvalidFactValueException = "InvalidFactValueException",
  InvalidOperationException = "InvalidOperationException",
  InvalidProgramAliasException = "InvalidProgramAliasException",
  ItemAlreadyExistsException = "ItemAlreadyExistsException",
  ItemNotFoundException = "ItemNotFoundException",
  NotFoundError = "NotFoundError",
  NotAuthorizedError = "NotAuthorizedError",
  UnexpectedStateException = "UnexpectedStateException"
}

export namespace ErrorClass {
  export function extract(error: any): ErrorClass | undefined {
    if (error instanceof ApolloError) {
      if (error.graphQLErrors.length === 1) {
        const extensions = error.graphQLErrors[0].extensions;
        if (extensions && extensions.hasOwnProperty("class")) {
          return extensions.class;
        }
      } else {
        console.warn("More than one GraphQL error returned");
      }
    }
  }
}
