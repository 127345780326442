import * as React from "react";
import { AppLayout, PluggableContent } from "./appLayout";

interface WorkflowLayoutProps extends PluggableContent {
}

export const WorkflowLayout: React.FunctionComponent<WorkflowLayoutProps> = (props) => (
  <AppLayout {...props} keepLeftHandNavOpenOnLargeScreens={true}>
    {props.children}
  </AppLayout>
);
