import * as React from "react";
import { Set } from "immutable";
import { Connection } from "../../types/models/connection";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { useSignInFlow } from "../auth/useSignInFlow";
import { Constants } from "../../app/constants";
import { AccessKeyUpgradeToolView } from "../../views/blocks/connectionManagement/accessKeyUpgradeToolView";
import { AuthContext } from "../../views/blocks/authContext";
import { SignInContextType } from "../../types/models/signInContextType";

interface Props {
  connection: Connection;
}

export const AccessKeyUpgradeTool: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const defaultRoles = props.connection.roles || Set.of(Constants.Auth.IdentificationRole);

  const signInFlow = useSignInFlow({
    flowId: "Upgrade",
    contextType: SignInContextType.Increment,
    newUserSettings: undefined,
    defaultRoles,
    existingConnection: props.connection
  });

  return (
    <AccessKeyUpgradeToolView
      authContext={AuthContext.Identification}
      cloudServices={cloudServices}
      authProviders={authProviders}
      state={signInFlow.state}
      defaultRoles={defaultRoles}
      grantedRoles={props.connection.roles}
      onRolesSelect={signInFlow.selectRoles}
      onSignIn={signInFlow.handleSignInSuccess}
    />
  );
};
