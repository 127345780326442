import { List, Map } from "immutable";
import { Scan, ScanStatus } from "../../../types/models/scan";
import * as React from "react";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Grid } from "../../widgets/grid";
import { CloudServiceNameAndIcon, ConnectionIdAndIcon } from "../connectionManagement/cloudServiceNameAndIcon";
import { WidgetStatus } from "../../utils/widgetStatus";
import { briefDateTime, preciseDuration } from "../../../utils/formatting";
import { Link } from "react-router-dom";
import { Characters } from "../../characters";
import { Modal } from "../../layouts/modal";
import { Button } from "../../widgets/button";
import { DrawerBlock } from "../../containers/drawerBlock";
import { DrawerContent } from "../../utils/drawerContent";
import { OperationStatus } from "../../../types/operationStatus";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { Stopwatch } from "../../widgets/stopwatch";
import { Connections } from "../../../types/models/connections";
import { Connection } from "../../../types/models/connection";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { Indicators, JsonIndicator, SimpleIndicator } from "../../widgets/indicator";
import { useRoutes } from "../../../app/routes/useRoutes";

function scanStatus(status: ScanStatus): WidgetStatus | undefined {
  switch (status) {
    case ScanStatus.Success: return WidgetStatus.Success;
    case ScanStatus.Failure: return WidgetStatus.Error;
  }
}

export interface StopScanToolControllerProps {
  scan: Scan;
  render: (onStop: () => Promise<string>, status: OperationStatus<string>) => React.ReactElement;
}

export type StopScanToolControllerComponentType = React.ComponentType<StopScanToolControllerProps>;

interface ScansGridProps {
  connections: Connections;
  scans: List<Scan>;
  stopScanToolController: StopScanToolControllerComponentType;
  onRefresh: () => void;
}

export const ScansGrid: React.FunctionComponent<ScansGridProps> = (props) => {
  const [currentScan, setCurrentScan] = React.useState<Scan>();
  const [stoppedScan, setStoppedScan] = React.useState<Scan>();

  return (
    <>
      {currentScan && (
        <Modal onClose={() => setCurrentScan(undefined)} wide={true}>
          <ScanDetails scan={currentScan}/>
        </Modal>
      )}
      {stoppedScan && (
        <Modal
          title={"Confirm Stopping Scan #" + stoppedScan.id}
          background={"alert"}
          onClose={() => setStoppedScan(undefined)}
        >
          {
            React.createElement(props.stopScanToolController, {
              scan: stoppedScan,
              render: (stop, status) => (
                <StopScanTool
                  scan={stoppedScan}
                  status={status}
                  onStop={() => stop().then(() => setTimeout(
                    () => {
                      setStoppedScan(undefined);
                      props.onRefresh();
                    },
                    1000
                  ))}
                />
              )
            })
          }
        </Modal>
      )}

      <GridPanelRow>
        <Grid>
          <Grid.Header>
            <Grid.Column>Scan ID</Grid.Column>
            <Grid.Column>Connection</Grid.Column>
            <Grid.Column>Context</Grid.Column>
            <Grid.Column>Status</Grid.Column>
            <Grid.Column>Started At</Grid.Column>
            <Grid.Column>Elapsed / Completed At</Grid.Column>
            <Grid.Column>Job</Grid.Column>
            <Grid.Column>Workflow</Grid.Column>
            <Grid.Column/>
            <Grid.Column/>
          </Grid.Header>
          <Grid.Body>
            {
              props.scans
                .sortBy((scan) => -scan.createdAt.getTime())
                .map((scan) => (
                  <ScanRow
                    key={scan.id}
                    connection={props.connections.get(scan.connectionId)}
                    scan={scan}
                    onDetails={() => setCurrentScan(scan)}
                    onStop={() => setStoppedScan(scan)}
                  />
                ))
            }
          </Grid.Body>
        </Grid>
      </GridPanelRow>
    </>
  );
};

interface ScanRowProps {
  connection: Connection | undefined;
  scan: Scan;
  onDetails: () => void;
  onStop: () => void;
}

const ScanRow: React.FunctionComponent<ScanRowProps> = (props) => {
  const routes = useRoutes();

  return (
    <Grid.Row>
      <Grid.Cell>{props.scan.id}</Grid.Cell>
      <Grid.Cell>
        {props.connection && <ConnectionIdAndIcon connection={props.connection}/>}
      </Grid.Cell>
      <Grid.Cell>
        <CloudServiceNameAndIcon cloudServiceId={props.scan.sourceCloudServiceId} addFromToArrow={true}/>
        <CloudServiceNameAndIcon cloudServiceId={props.scan.destinationCloudServiceId}/>
      </Grid.Cell>
      <Grid.Cell status={scanStatus(props.scan.status)}>
        {props.scan.status}
        {props.scan.errorMessage.map((error) => " (" + error + ")").getOrUse("")}
      </Grid.Cell>
      <Grid.Cell>{briefDateTime(props.scan.createdAt)}</Grid.Cell>
      <Grid.Cell>
        {
          props.scan.completedAt
            .map((completedAt) => (
              <>
                {briefDateTime(completedAt)}
                <br/>
                in {preciseDuration(props.scan.timeElapsed())}
              </>
            ))
            .getOrUse(<Stopwatch from={props.scan.createdAt}/>)
        }
      </Grid.Cell>
      <Grid.Cell><Link to={routes.jobs.jobPath(props.scan.jobId)}>{props.scan.jobId}</Link></Grid.Cell>
      <Grid.Cell>
        <a href={props.scan.workflowUrl} target={"_blank"}>{props.scan.jobId}</a> {Characters.NewTab}
      </Grid.Cell>
      <Grid.ActionsCell>
        <Button size="small" color={"white"} onClick={props.onDetails}>Details</Button>
      </Grid.ActionsCell>
      <Grid.ActionsCell>
        {
          props.scan.completedAt.isEmpty() &&
          <Button size="small" color={"red"} onClick={props.onStop}>Stop</Button>
        }
      </Grid.ActionsCell>
    </Grid.Row>
  );
};

interface StopScanToolProps {
  scan: Scan;
  status: OperationStatus<string>;
  onStop: () => void;
}

const StopScanTool: React.FunctionComponent<StopScanToolProps> = (props) => {
  const routes = useRoutes();

  return (
    <>
      <DrawerBlock>
        <DrawerContent>
          <p>
            Confirming this will abort the scan
            job <Link to={routes.jobs.jobPath(props.scan.jobId)}>{props.scan.jobId}</Link>, mark the scan as aborted,
            and also mark all the facts still being collected by this scan as failed. All other scans and facts will
            remain unaffected.
          </p>
          <p>Note that the failure status will be visible to the end user.</p>
          <p>
            Also, an attempt will be made to stop the corresponding Bamboo workflow{" "}
            <a href={props.scan.workflowUrl} target={"_blank"}>{props.scan.jobId}</a> {Characters.NewTab}, but this
            may not work if the workflow is not in a healthy state or if the scan is really large. Aborting the
            workflow in such situation requires a restart of the shared Bamboo instance in AWS ECS console.
          </p>
        </DrawerContent>
      </DrawerBlock>
      <OperationStatusIndicator
        progressMessage={"Aborting..."}
        successMessage={(result) => "Success: " + result}
        status={props.status}
        indicators={StatusIndicators.SimplePanel()}
      />
      <DrawerBlock>
        <Button
          color={"red"}
          onClick={props.onStop}
          disabled={props.status.isWorking()}
        >
          Stop This Scan
        </Button>
      </DrawerBlock>
    </>
  );
};

interface ScanDetailsProps {
  scan: Scan;
}

const ScanDetails: React.FunctionComponent<ScanDetailsProps> = (props) => (
  <DrawerBlock>
    <Panel>
      <PanelRow>
        <Indicators size={"small"} direction={"column"}>
          <JsonIndicator title={"Blueprint Inputs"} value={props.scan.blueprintInputs}/>
          <JsonIndicator title={"Error Details"} value={props.scan.errorDetails.getOrUse("--")}/>
        </Indicators>
      </PanelRow>
    </Panel>
  </DrawerBlock>
);
