import * as React from "react";
import { styled } from "../../app/theme";

export const StyledSvg = styled.svg`
  fill: ${(props) => props.theme.colors.primary};
  stroke: ${(props) => props.theme.colors.primary};
`;

// tslint:disable max-line-length
export const PauseIcon: React.FunctionComponent = () => (
  <StyledSvg viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="240" strokeWidth="25" fill="none"/>
    <path d="M318.061,139.636c-12.853,0-23.273,10.42-23.273,23.273v186.182c0,12.853,10.42,23.273,23.273,23.273 c12.853,0,23.273-10.42,23.273-23.273V162.909C341.333,150.056,330.913,139.636,318.061,139.636z"/>
    <path d="M193.939,139.636c-12.853,0-23.273,10.42-23.273,23.273v186.182c0,12.853,10.42,23.273,23.273,23.273 c12.853,0,23.273-10.42,23.273-23.273V162.909C217.212,150.056,206.792,139.636,193.939,139.636z"/>
  </StyledSvg>
);
