import * as React from "react";
import { Step, StepSubmitHandle } from "../../../containers/step";
import { Block } from "../../../containers/block";
import { CloudServiceChooserPanel } from "../../../blocks/cloudServiceChooser/cloudServiceChooserPanel";
import { ProviderInfoPanel } from "./providerInfoPanel";
import { AuthContext } from "../../../blocks/authContext";
import { useCloudServices } from "../../../../app/configuration";
import { SignInContextType } from "../../../../types/models/signInContextType";

interface Props {
  step: number;
  totalSteps: number;

  areas: ProviderInfoPanel.Area[] | undefined;

  sourceCloudServiceId: string | undefined;
  destinationCloudServiceId: string | undefined;

  onSourceCloudServiceSelect: (providerId: string) => void;
  onDestinationCloudServiceSelect: (providerId: string) => void;

  onSubmit: StepSubmitHandle;
  onNavigateBack?: () => void;
}

export const ProvidersStepView: React.FunctionComponent<Props> = (props) => {
  const sourceCloudServices = useCloudServices(SignInContextType.Source);
  const destinationCloudServices = useCloudServices(SignInContextType.Destination);

  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title="What two types of accounts do you want to migrate between?"
      subTitle="Choose the types of accounts you'll be migrating from and to."
      submitTitle="Next"
      submitSize="wide"
      onSubmit={props.onSubmit}
      submitDisabled={!props.sourceCloudServiceId || !props.destinationCloudServiceId}
      submitDisabledMessage={"Please choose the types of accounts you'll be migrating from and to."}
      onNavigateBack={props.onNavigateBack}
    >
      <Block>
        <CloudServiceChooserPanel
          authContext={AuthContext.Source}
          cloudServices={sourceCloudServices}
          selectedCloudServiceId={props.sourceCloudServiceId}
          onCloudServiceSelect={props.onSourceCloudServiceSelect}
        />
      </Block>
      <Block>
        <CloudServiceChooserPanel
          authContext={AuthContext.Destination}
          cloudServices={destinationCloudServices}
          selectedCloudServiceId={props.destinationCloudServiceId}
          onCloudServiceSelect={props.onDestinationCloudServiceSelect}
        />
      </Block>
      <Block>
        <ProviderInfoPanel
          config={
            props.areas && props.sourceCloudServiceId && props.destinationCloudServiceId
              ? ({
                areas: props.areas,
                sourceCloudService: sourceCloudServices.getOrFail(props.sourceCloudServiceId),
                destinationCloudService: destinationCloudServices.getOrFail(props.destinationCloudServiceId)
              })
              : undefined
          }
        />
      </Block>
    </Step>
  );
};
