import { WorkflowContextLike } from "./workflowContextLike";
import { WorkflowState } from "./workflowState";
import { FastPromise } from "./fastPromise";

export interface SyncResult<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult,
  T> {
  readonly state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>;
  readonly result: T;

  map<R>(
    mapper: (result: T) => R,
    updates?: Partial<WorkflowState.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>>
  ): SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, R>;
}

class SyncResultImpl<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult,
  T>
  implements SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, T> {
  constructor(
    public readonly state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    public readonly result: T
  ) {
  }

  public map<R>(
    mapper: (result: T) => R,
    updates?: Partial<WorkflowState.Config<WorkflowContext, WorkflowFeedback, WorkflowResult>>
  ): SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, R> {
    return new SyncResultImpl(this.state.copy(updates), mapper(this.result));
  }
}

export function SyncResult<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult,
  T>(
  state: WorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
  result: T
): SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, T> {
  return new SyncResultImpl(state, result);
}

export type AsyncResult<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult,
  T> =
  FastPromise<SyncResult<WorkflowContext, WorkflowFeedback, WorkflowResult, T>>;
