import * as React from "react";
import { styled } from "../../../app/theme";
import { Paragraph } from "../../widgets/paragraph";
import { WhenElevated } from "../../utils/whenElevated";
import { PanelRow } from "../../containers/rows/panelRow";
import { Panel } from "../../containers/panel";
import { WidgetStatus } from "../../utils/widgetStatus";
import {
  OAuthAndServiceAccountSignInForm,
  OAuthAndServiceAccountSignInFormProps
} from "./oAuthAndServiceAccountSignInForm";

const SignInOption = styled.div`
  & + & {
    margin-top: 2rem;
  }
  
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div<{ disabled?: boolean }>`
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: .7rem;
  text-decoration: ${(props) => props.disabled ? "line-through" : "inherit"};
  color: ${(props) => props.disabled ? props.theme.colors.gray : "inherit"};
`;

const Comments = styled.div`
  margin-bottom: 1rem;
`;

export interface OutlookSignInFormProps {
  exchange: OAuthAndServiceAccountSignInFormProps;
  imap: OAuthAndServiceAccountSignInFormProps;
  exchangeOptionFailed: boolean;
}

export const OutlookSignInForm: React.FunctionComponent<OutlookSignInFormProps> = (props) => {
  const imapSignInForm = (
    <OAuthAndServiceAccountSignInForm
      {...props.imap}
      serviceAccountSignInConfig={props.imap.serviceAccountSignInConfig}
    />
  );

  return (
    <>
      <SignInOption>
        <Title disabled={props.exchangeOptionFailed}>
          Step 1. Provide permissions to VaultMe for using the Exchange protocol.
        </Title>
        <Comments>
          {
            props.exchangeOptionFailed
              ? (
                <Panel>
                  <PanelRow status={WidgetStatus.Warning}>
                    The Exchange protocol is not supported by Microsoft for your account. Please proceed to step 2
                    below.
                  </PanelRow>
                </Panel>
              )
              : (
                <>
                  <Paragraph>
                    Click the button below to provide VaultMe the permissions to migrate your emails using the
                    Exchange protocol.
                  </Paragraph>
                  <Paragraph>
                    The Exchange protocol is only supported for work and education Microsoft 365 accounts. For personal
                    Microsoft accounts VaultMe will likely have to use the less reliable IMAP protocol (see step 2
                    below).
                  </Paragraph>
                </>
              )
          }
        </Comments>
        {!props.exchangeOptionFailed && (
          <OAuthAndServiceAccountSignInForm
            {...props.exchange}
            serviceAccountSignInConfig={props.exchange.serviceAccountSignInConfig}
          />
        )}
      </SignInOption>
      <SignInOption>
        <Title>Step 2 (optional). Provide permissions to VaultMe for using the IMAP protocol.</Title>
        <Comments>
          {
            props.exchangeOptionFailed
              ? (
                <>
                  Click the button below to provide VaultMe the permissions to migrate your emails using the IMAP
                  protocol.
                </>
              )
              : (
                <>
                  If it turns out that the Exchange protocol is not supported by Microsoft for your account, there's
                  an option to migrate your emails using the IMAP protocol.
                </>
              )
          }
        </Comments>
        {
          props.exchangeOptionFailed
            ? imapSignInForm
            : <WhenElevated>{imapSignInForm}</WhenElevated>
        }
      </SignInOption>
    </>
  );
};
