import * as React from "react";
import { styled } from "../../../app/theme";
import { Button } from "../../widgets/button";
import { CloudServiceListPopup } from "./cloudServiceListPopup";
import { AuthContext } from "../authContext";
import { PanelRow } from "../../containers/rows/panelRow";
import { LinkButton } from "../../widgets/linkButton";
import { CloudServices } from "../../../types/models/cloudServices";
import { useDrawer } from "../../layouts/drawer";

interface CloudServiceChooserRowProps {
  authContext: AuthContext;
  cloudServices: CloudServices;
  selectedCloudServiceId: string | undefined;
  locked?: boolean;
  buttonTitle?: string;
  onCloudServiceSelect?: (cloudServiceId: string) => void;
}

const Logo = styled.img`
  display: block;
  height: 2.75rem;
  margin-top: -0.25rem;
  margin-bottom: -0.55rem;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  margin-top: 1.25rem;
`;

export const CloudServiceChooserRow: React.FunctionComponent<CloudServiceChooserRowProps> = (props) => {
  const drawer = useDrawer();

  function handleCloudServiceSelect(cloudServiceId: string) {
    drawer.close();
    if (props.onCloudServiceSelect) {
      props.onCloudServiceSelect(cloudServiceId);
    }
  }

  function openDrawer() {
    drawer.open(
      <CloudServiceListPopup
        cloudServices={props.cloudServices}
        onCloudServiceSelect={handleCloudServiceSelect}
        authContext={props.authContext}
      />
    );
  }

  const cloudService = props.selectedCloudServiceId && props.cloudServices.get(props.selectedCloudServiceId);
  if (cloudService) {
    const changeLink = props.locked || !props.onCloudServiceSelect
      ? undefined
      : <LinkButton onClick={openDrawer}>Change</LinkButton>;
    return (
      <PanelRow actions={changeLink}>
        <Logo src={cloudService.logo} alt={cloudService.name}/>
        {cloudService.description && <Description>{cloudService.description}</Description>}
      </PanelRow>
    );
  } else {
    return (
      <PanelRow>
        <Button onClick={openDrawer}>{props.buttonTitle || "Add +"}</Button>
      </PanelRow>
    );
  }
};
