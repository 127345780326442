import * as React from "react";
import { css, styled } from "../../app/theme";

export const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  cursor: ${(props) => props.disabled ? "inherit" : "pointer"};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const VisibleCheckboxLayout = styled.div`
  display: flex;
`;

const CheckMark = styled.svg`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: none;
`;

interface StyledCheckboxProps {
  simulateFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hideCheckMarkWhenDisabled?: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  background: ${(props) => props.disabled ? props.theme.colors.lightGray : "auto"};
  border-radius: 3px;
  flex: 0 0 auto;
  ${(props) => props.theme.animation.transitionAll()};
  
  ${(props) => !props.readOnly && css`
    ${HiddenCheckbox}:focus + ${VisibleCheckboxLayout} > & ${props.simulateFocus ? ", &" : ""} {
      border: 2px solid ${props.theme.colors.primary};
      box-shadow: 0 0 0.2rem ${props.theme.colors.primary};
    }
  `}
  
  ${HiddenCheckbox}:checked + ${VisibleCheckboxLayout} > & {
    background: ${(props) => props.disabled ? props.theme.colors.lightGray : props.theme.colors.primary};
    border: 2px solid ${(props) => props.disabled ? props.theme.colors.lightGray : props.theme.colors.primary};
    
    ${CheckMark} {
      fill: ${(props) => !props.disabled || !props.hideCheckMarkWhenDisabled ? props.theme.colors.white : "none"};
      stroke: ${(props) => !props.disabled || !props.hideCheckMarkWhenDisabled ? props.theme.colors.white : "none"};
    }
  }
  
  ${HiddenCheckbox}:indeterminate + ${VisibleCheckboxLayout} > & {
    background: ${(props) => props.disabled ? props.theme.colors.lightGray : props.theme.colors.lightPrimary};
    border: 2px solid ${(props) => props.disabled ? props.theme.colors.lightGray : props.theme.colors.primary};

    ${CheckMark} {
      fill: none;
      stroke: none;
    }
  }
`;

interface ContentProps {
  disabled?: boolean;
  noWrap?: boolean;
}

const Content = styled.div<ContentProps>`
  margin-left: .5rem;
  margin-top: .1rem;
  color: ${(props) => props.disabled ? props.theme.colors.gray : "auto"};
  white-space: ${(props) => props.noWrap ? "nowrap" : "auto"};
`;

interface CheckboxProps {
  id?: string;

  name?: string;

  checked?: boolean;
  indeterminate?: boolean;
  defaultChecked?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hideCheckMarkWhenDisabled?: boolean;
  title?: string;

  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  simulateFocus?: boolean;
  noWrap?: boolean;

  rawContent?: React.ReactNode;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
  const checkboxRef = React.createRef<HTMLInputElement>();
  const id = props.id || props.name;

  React.useEffect(
    () => {
      if (checkboxRef.current) {
        (checkboxRef.current as any).indeterminate = props.indeterminate;
      }
    },
    [props.indeterminate]
  );

  return (
    <CheckboxLabel title={props.title} disabled={props.disabled}>
      <HiddenCheckbox
        ref={checkboxRef}
        id={id}
        name={props.name}
        value="true"
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled || props.readOnly}
      />
      <VisibleCheckboxLayout>
        <StyledCheckbox
          simulateFocus={props.simulateFocus}
          disabled={props.disabled}
          hideCheckMarkWhenDisabled={props.hideCheckMarkWhenDisabled}
        >
          <CheckMark viewBox="-2 -2 20 20">
            <polygon points="14.5 0 16 1.1 6.5 15 0 10.5 1 8.9 6 12.4"/>
          </CheckMark>
        </StyledCheckbox>
        {props.rawContent}
        {props.children && <Content disabled={props.disabled} noWrap={props.noWrap}>{props.children}</Content>}
      </VisibleCheckboxLayout>
    </CheckboxLabel>
  );
};
