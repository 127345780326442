import * as React from "react";

const Day = 24 * 60 * 60;
const Hour = 60 * 60;
const Minute = 60;

interface ScaleItem {
  threshold: number;
  precision: number;
}

const Scale: ScaleItem[] = [
  { threshold: Day * 6,     precision: Day },
  { threshold: Day * 3,     precision: Day / 2 },
  { threshold: Day,         precision: Day / 4 },
  { threshold: Hour * 6,    precision: Hour },
  { threshold: Hour * 3,    precision: Hour / 2 },
  { threshold: Hour,        precision: Hour / 4 },
  { threshold: Minute * 30, precision: Minute * 10 },
  { threshold: Minute * 10, precision: Minute * 5 },
];

// Same scale as above, but without quarters
// const Scale: ScaleItem[] = [
//   { threshold: Day * 6,     precision: Day },
//   { threshold: Day,         precision: Day / 2 },
//   { threshold: Hour * 6,    precision: Hour },
//   { threshold: Hour,        precision: Hour / 2 },
//   { threshold: Minute * 30, precision: Minute * 10 },
//   { threshold: Minute * 10, precision: Minute * 5 },
// ];

function round(seconds: number): number {
  const item = Scale.find((i) => seconds >= i.threshold);
  return item
    ? (Math.floor(seconds / item.precision) + (seconds % item.precision === 0 ? 0 : 1)) * item.precision
    : seconds;
}

function htmlFraction(value: number): React.ReactNode {
  const s = value.toFixed(2);
  if (s.endsWith(".25")) {
    return <React.Fragment>&#188;</React.Fragment>;
  } else if (s.endsWith(".50")) {
    return <React.Fragment>&#189;</React.Fragment>;
  } else if (s.endsWith(".75")) {
    return <React.Fragment>&#190;</React.Fragment>;
  } else {
    return null;
  }
}

interface FormattedTime {
  value: React.ReactNode;
  fraction: React.ReactNode;
  unit: string;
}

function formatValue(value: number, unit: string): FormattedTime {
  return {
    value: Math.floor(value),
    fraction: htmlFraction(value),
    unit: unit + (value === 1 ? "" : "s")
  };
}

export function prepareTimeEstimate(seconds: number): FormattedTime {
  const rounded = round(seconds);
  const days = Math.floor(rounded / Day);
  const hours = Math.floor(rounded % Day / Hour);
  const minutes = Math.floor(rounded % Day % Hour / Minute);

  if (days !== 0) {
    return formatValue(days + hours / 24, "Day");
  } else if (hours !== 0) {
    return formatValue(hours + minutes / 60, "Hour");
  } else {
    return formatValue(Math.max(1, minutes), "Minute");
  }
}
