import { GraphQL } from "../../services/graphql/generated";
import { User } from "./user";

export class Session implements Session.Props {
  constructor(protected readonly props: Session.Props) {
  }

  public get user(): User { return this.props.user; }
  public get elevated(): boolean { return this.props.elevated; }
  public get idleTimeout(): number { return this.props.idleTimeout; }
}

export namespace Session {
  export interface Props {
    readonly user: User;
    readonly elevated: boolean;
    readonly idleTimeout: number;
  }

  export function fromGraphQL(data: GraphQL.SessionFragment): Session {
    return new Session({
      user: User.parse(data.user),
      elevated: data.elevated,
      idleTimeout: data.idleTimeout
    });
  }
}
