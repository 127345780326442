import * as React from "react";
import { useRoutes } from "../../../app/routes/useRoutes";
import { AccessList } from "../../../types/models/accessList";
import { CrudPages } from "../../../components/crud/crudPages";
import { friendlyDateTime } from "../../../utils/formatting";
import { useSearchAccessListsQuery } from "../../../queries/accessLists/useSearchAccessListsQuery";
import { useDeleteAccessListMutation } from "../../../queries/accessLists/useDeleteAccessListMutation";
import { useUpdateAccessListMutation } from "../../../queries/accessLists/useUpdateAccessListMutation";
import { useInsertAccessListMutation } from "../../../queries/accessLists/useInsertAccessListMutation";
import { useGetAccessListQuery } from "../../../queries/accessLists/useGetAccessListQuery";
import { AccessListForm } from "../../../views/screens/organizationPortal/accessLists/accessListForm";
import { AccessListFormData } from "../../../types/models/accessListFormData";
import { CRUDConfig } from "../../../components/crud/crudConfig";
import { WidgetStatus } from "../../../views/utils/widgetStatus";
import { useProgramDetailsController } from "../../../components/lookup/programs/useProgramDetailsController";
import {
  ProgramLookupControllerType,
  ProgramViewPathFactory
} from "../../../views/blocks/lookups/programLookupField";
import { useProgramLookupController } from "../../../components/lookup/programs/useProgramLookupController";
import { useProgramViewPathFactory } from "../../../components/lookup/programs/useProgramViewPathFactory";
import { useGetAccessListEntryCountQuery } from "../../../queries/accessLists/useGetAccessListEntryCountQuery";
import { useBrowser } from "../../../utils/useBrowser";
import { OrganizationPortalUserRole } from "../../../types/enums/organizationPortalUserRole";
import { OrganizationContext } from "types/models/organizationContext";
import { useGetCurrentAccessListUpdateQuery } from "../../../queries/accessLists/useGetCurrentAccessListUpdateQuery";
import { useGoogleGroupDetailsController } from "./useGoogleGroupDetailsController";
import {
  GoogleGroupDetailsController
} from "../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import {
  AccessListOverview
} from "../../../views/screens/organizationPortal/accessLists/accessListOverview";

interface AccessListAdminCrudPagesProps extends Pick<
  CRUDConfig<number, AccessList, AccessListFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  readOnly: boolean;

  makeEditPath: (id: number) => string;
  makeImportEntriesPath: (id: number) => string;
  makeImportHistoryPath: (id: number) => string;
  makeDeleteEntriesPath: (id: number) => string;

  programLookupController: ProgramLookupControllerType;
  programViewPathFactory: ProgramViewPathFactory;
}

export const AccessListAdminCrudPages: React.FunctionComponent<AccessListAdminCrudPagesProps> = (props) => {
  const programDetailsController = useProgramDetailsController();
  const googleGroupDetailsController = useGoogleGroupDetailsController();

  return CrudPages<number, AccessList, AccessListFormData>({
    title: "End-User Access Lists",
    subject: "access list",
    readOnly: props.readOnly,
    startFrom: props.startFrom,
    layout: props.layout,
    gridColumns: [
      {
        title: "Name",
        render: (accessList) => accessList.name
      },
      {
        title: "Type",
        render: (accessList) => accessList.isProgramAllowlist ? "Program allowlist" : "Blocklist"
      },
      {
        title: "End-User Program",
        render: (accessList) => ({
          content: accessList.program ? accessList.program.name : "N/A",
          status: accessList.program ? WidgetStatus.Normal : WidgetStatus.Uncertain
        })
      },
      {
        title: "Source of List",
        render: (accessList) => accessList.googleGroupId ? "Google Group" : "CSV"
      },
      {
        title: "Status",
        render: (accessList) => ({
          content: accessList.isEnabled ? "Enabled" : "Disabled",
          status: accessList.isEnabled ? WidgetStatus.Normal : WidgetStatus.Uncertain
        })
      },
      {
        title: "Entries Updated",
        render: (accessList) =>
          accessList.lastUpdateStartedAt ? friendlyDateTime(accessList.lastUpdateStartedAt) : "--"
      },
      {
        title: "Created",
        render: (accessList) => friendlyDateTime(accessList.createdAt)
      },
    ],
    renderOverview: (accessList) => (
      <AccessListOverviewWrapper
        accessList={accessList}

        readOnly={props.readOnly}

        editPath={props.makeEditPath(accessList.id)}
        importEntriesPath={props.makeImportEntriesPath(accessList.id)}
        importHistoryPath={props.makeImportHistoryPath(accessList.id)}
        deleteEntriesPath={props.makeDeleteEntriesPath(accessList.id)}

        programViewPathFactory={props.programViewPathFactory}
        googleGroupDetailsController={googleGroupDetailsController}
      />
    ),
    renderForm: (config) => (
      <AccessListForm
        config={config}

        programLookupController={props.programLookupController}
        programDetailsController={programDetailsController}
        programViewPathFactory={props.programViewPathFactory}

        googleGroupDetailsController={googleGroupDetailsController}
      />
    ),

    useRoutes: props.useRoutes,
    getItemId: (accessList) => accessList.id,
    getItemTitle: (accessList) => accessList.name,
    getItemDescription: (accessList) => accessList.notes,
    clone: (accessList) => ({
      ...accessList,
      name: "Copy of " + accessList.name
    }),
    compare: (a: AccessList, b: AccessList) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },

    useGetQuery: (id) => {
      const [accessListStatus, refreshAccessList] = useGetAccessListQuery(id);
      const [entryCountStatus, refreshEntryCount] = useGetAccessListEntryCountQuery(id);

      return [
        accessListStatus,
        (variableOverrides, soft) => {
          refreshAccessList(variableOverrides, soft);
          refreshEntryCount({}, soft);
        }
      ];
    },
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: () => {
      const [update, updateStatus] = useUpdateAccessListMutation();
      return [(id, formData) => update(id, formData), updateStatus];
    },
    useDeleteMutation: useDeleteAccessListMutation
  });
};

interface AccessListOverviewWrapperProps {
  accessList: AccessList;

  readOnly: boolean;

  editPath: string;
  importEntriesPath: string;
  importHistoryPath: string;
  deleteEntriesPath: string;

  programViewPathFactory: ProgramViewPathFactory;
  googleGroupDetailsController: GoogleGroupDetailsController;
}

const AccessListOverviewWrapper: React.FunctionComponent<AccessListOverviewWrapperProps> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  const [entryCountStatus, refreshEntryCount] = useGetAccessListEntryCountQuery(props.accessList.id);

  const [currentAccessListUpdateStatus, refreshCurrentAccessListUpdate] = useGetCurrentAccessListUpdateQuery(
    props.accessList.id
  );

  const currentUpdateId = currentAccessListUpdateStatus.someResult()?.currentUpdateId;

  React.useEffect(
    () => {
      if (currentUpdateId !== undefined) {
        const timer = setInterval(() => refreshCurrentAccessListUpdate({}, true), 5000);
        return () => clearInterval(timer);
      }
    },
    [currentUpdateId]
  );

  return (
    <AccessListOverview
      accessList={props.accessList}

      importEntriesPath={props.importEntriesPath}
      importHistoryPath={props.importHistoryPath}
      deleteEntriesPath={props.deleteEntriesPath}

      programViewPathFactory={props.programViewPathFactory}
      googleGroupDetailsController={props.googleGroupDetailsController}

      entryCountStatus={entryCountStatus}
      onEntryCountRefresh={refreshEntryCount}

      disableUpdates={currentUpdateId !== undefined}
      readOnly={props.readOnly}

      onExportItems={() => browser.openNewTab(routes.api.accessListImportExportUrl(props.accessList.id), true)}
    />
  );
};

interface OrganizationAccessListAdminCrudPagesProps {
  organizationContext: OrganizationContext;
}

export const OrganizationAccessListAdminCrudPages: React.FunctionComponent<OrganizationAccessListAdminCrudPagesProps> =
  (props) => {
    const organizationAlias = props.organizationContext.organization.alias;
    const organizationId = props.organizationContext.organization.id;

    const routes = useRoutes().organizationPortalRoutes.accessLists(organizationAlias);

    const programLookupController = useProgramLookupController(organizationId);
    const programViewPathFactory = useProgramViewPathFactory(organizationId);

    return (
      <AccessListAdminCrudPages
        readOnly={
          !!props.organizationContext.organizationPortalUser &&
          !OrganizationPortalUserRole.implies(
            props.organizationContext.organizationPortalUser.role,
            OrganizationPortalUserRole.ReadAndWriteAccess
          )
        }

        startFrom={"list"}
        layout={"compact"}

        useRoutes={() => useRoutes().organizationPortalRoutes.accessLists(organizationAlias)}
        useSearchQuery={(term) => useSearchAccessListsQuery(organizationId, term)}
        useInsertMutation={() => useInsertAccessListMutation(organizationId)}

        makeEditPath={(id) => routes.editPath(id)}
        makeImportEntriesPath={(id) => routes.importPath(id)}
        makeImportHistoryPath={(id) => routes.importHistoryPath(id)}
        makeDeleteEntriesPath={(id) => routes.deleteEntriesPath(id)}

        programLookupController={programLookupController}
        programViewPathFactory={programViewPathFactory}
      />
    );
  };
