import * as React from "react";
import { styled } from "../../app/theme";
import { FormLabel } from "./formLabel";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

interface FormFieldProps extends StyledComponentsProps {
  label?: string;
  required?: boolean;
  error?: boolean;
  action?: React.ReactNode;
  actions?: React.ReactNode[];
  limitWidth?: boolean | string;
}

interface LayoutProps {
  limitWidth?: boolean | string;
}

const Layout = styled.div<LayoutProps>`
  max-width: ${(props) =>
    props.limitWidth ? (typeof props.limitWidth === "string" ? props.limitWidth : "25rem") : "auto"
  };
`;

const Header = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
`;

const ActionContainer = styled.div`
  margin-left: 2rem;
`;

export const RequiredFieldSign = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const InternalFormField: React.FunctionComponent<FormFieldProps> = (props) => {
  const actions = props.action ? [props.action] : props.actions;
  const renderedActions = actions && actions.map((action, index) => {
    if (action) {
      const key = action.hasOwnProperty("key") ? (action as any).key : index;
      return <ActionContainer key={key}>{action}</ActionContainer>;
    }
  });
  const header = (props.label || renderedActions) && (
    <Header>
      {props.label && (
        <FormLabel error={props.error}>
          {props.label}{props.required && <RequiredFieldSign> *</RequiredFieldSign>}
        </FormLabel>
      )}
      {renderedActions && <Actions>{renderedActions}</Actions>}
    </Header>
  );
  return (
    <Layout limitWidth={props.limitWidth} className={props.className}>
      {header}
      {props.children}
    </Layout>
  );
};

// This allows to use ${FormField} syntax with styled components
export const FormField = styled(InternalFormField)``;
