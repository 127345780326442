import { ApolloError } from "apollo-client";
import { UserFacingError } from "../../types/userFacingError";
import { ErrorClass } from "./errorClass";

type ErrorClassHandler<R> = (error: ApolloError) => UserFacingError | R | undefined;
type ErrorClassHandlerDef<R> = [string | string[], ErrorClassHandler<R>];
type CatchAllHandler<R> = (error: any) => UserFacingError | R | undefined;

export type ErrorHandler<R> = ErrorClassHandlerDef<R> | CatchAllHandler<R>;

export namespace ErrorHandler {
  function findErrorClassHandler<R>(error: any, handlers: ErrorHandler<R>[]): ErrorClassHandler<R> | undefined {
    const errorClass = ErrorClass.extract(error);
    if (errorClass) {
      const handlerDef = handlers.find((handler) => {
        if (Array.isArray(handler)) {
          const condition = handler[0];
          if (typeof condition === "string") {
            return condition === errorClass;
          } else {
            return condition.indexOf(errorClass) !== -1;
          }
        } else {
          return false;
        }
      });
      if (handlerDef) {
        return (handlerDef as ErrorClassHandlerDef<R>)[1];
      }
    }
  }

  function findCatchAllHandler<R>(handlers: ErrorHandler<R>[]): CatchAllHandler<R> | undefined {
    return handlers.find((handler) => typeof handler === "function") as CatchAllHandler<R>;
  }

  export function handleError<R>(
    error: any,
    handlers: ErrorHandler<R>[]
  ): UserFacingError | R | undefined {
    if (error instanceof UserFacingError) {
      return error;
    } else {
      const errorClassHandler = findErrorClassHandler<R>(error, handlers);
      if (errorClassHandler) {
        return errorClassHandler(error);
      } else {
        const catchAllHandler = findCatchAllHandler(handlers);
        if (catchAllHandler) {
          return catchAllHandler(error);
        } else {
          return undefined;
        }
      }
    }
  }
}
