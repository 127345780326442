import { List, Map } from "immutable";
import { None, Option, Some } from "../utils/monads/option";
import { WorkStatus } from "../views/models/workStatus";
import { BlueprintError } from "./blueprintError";

export abstract class State<Output> {
  constructor(public readonly errors: List<BlueprintError>) {
  }

  public get isBlocked(): boolean { return false; }

  public get isPending(): boolean { return false; }

  public get isPreparing(): boolean { return false; }

  public get isResolving(): boolean { return false; }

  public get isAlmostResolved(): boolean { return false; }

  public get isResolved(): boolean { return false; }

  public get isError(): boolean { return false; }

  public get isWorking(): boolean { return false; }

  // Used by Blueprint Explorer only
  public get isEnabled(): boolean { return false; }

  public get hasOutput(): boolean { return false; }

  public get output(): Option<Output> { return None(); }

  public get reliableOutput(): Option<Output> { return None(); }

  public get resolvedOutput(): Option<Output> { return None(); }

  public get progress(): Option<number> { return None(); }

  public abstract get assumedProgress(): number;

  public abstract map<T>(mapOutput: (output: Output) => T): State<T>;

  public abstract withErrors(errors: List<BlueprintError>): State<Output>;

  public get name(): string {
    const c = this.constructor.name;
    return c.endsWith("State") ? c.substr(0, c.length - 5) : c;
  }

  public toString(): string {
    return this.name +
      this.progress.map((progress) => " (" + Math.round(progress) + "%)").getOrElse(() => "");
  }

  public abstract toWorkStatus(): WorkStatus;
}

abstract class StateWithOutput<Output> extends State<Output> {
  public abstract get stateOutput(): Output;

  public get hasOutput(): boolean { return true; }

  public get output(): Option<Output> { return Some(this.stateOutput); }
}

export class BlockedState<Output> extends State<Output> {
  public get isBlocked(): boolean { return true; }

  public get assumedProgress(): number { return 0; }

  public map<T>(mapOutput: (output: Output) => T): State<T> {
    return new BlockedState(this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<Output> {
    return new BlockedState(errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Pending;
  }
}

export class PendingState<Output> extends State<Output> {
  public get isPending(): boolean { return true; }

  public get assumedProgress(): number { return 0; }

  public map<T>(mapOutput: (output: Output) => T): State<T> {
    return new PendingState(this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<Output> {
    return new PendingState(errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Pending;
  }
}

export class PreparingState<Output> extends State<Output> {
  public get isPreparing(): boolean { return true; }

  public get isEnabled(): boolean { return true; }

  public get isWorking(): boolean { return true; }

  public get assumedProgress(): number { return 0; }

  public map<T>(mapOutput: (output: Output) => T): State<T> {
    return new PreparingState(this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<Output> {
    return new PreparingState(errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Working;
  }
}

class ResolvingState<PartialOutput> extends StateWithOutput<PartialOutput> {
  constructor(
    public readonly stateOutput: PartialOutput,
    public readonly stateProgress: Option<number>,
    errors: List<BlueprintError>,
  ) {
    super(errors);
  }

  public get isResolving(): boolean { return true; }

  public get isEnabled(): boolean { return true; }

  public get isWorking(): boolean { return true; }

  public get progress(): Option<number> { return this.stateProgress; }

  public get assumedProgress(): number { return this.stateProgress.getOrElse(() => 0); }

  public map<T>(mapOutput: (output: PartialOutput) => T): State<T> {
    return new ResolvingState(mapOutput(this.stateOutput), this.stateProgress, this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<PartialOutput> {
    return new ResolvingState(this.stateOutput, this.stateProgress, errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Working;
  }
}

class AlmostResolvedState<PartialOutput> extends StateWithOutput<PartialOutput> {
  constructor(
    public readonly stateOutput: PartialOutput,
    public readonly stateProgress: Option<number>,
    errors: List<BlueprintError>,
  ) {
    super(errors);
  }

  public get isAlmostResolved(): boolean { return true; }

  public get isEnabled(): boolean { return true; }

  public get isWorking(): boolean { return true; }

  public get reliableOutput(): Option<PartialOutput> { return Some(this.stateOutput); }

  public get progress(): Option<number> { return this.stateProgress; }

  public get assumedProgress(): number { return this.stateProgress.getOrElse(() => 0); }

  public map<T>(mapOutput: (output: PartialOutput) => T): State<T> {
    return new AlmostResolvedState(mapOutput(this.stateOutput), this.stateProgress, this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<PartialOutput> {
    return new AlmostResolvedState(this.stateOutput, this.stateProgress, errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Working;
  }
}

class ResolvedState<Output> extends StateWithOutput<Output> {
  constructor(
    public readonly stateOutput: Output,
    errors: List<BlueprintError>,
  ) {
    super(errors);
  }

  public get isResolved(): boolean { return true; }

  public get isEnabled(): boolean { return true; }

  public get output(): Option<Output> { return Some(this.stateOutput); }

  public get reliableOutput(): Option<Output> { return Some(this.stateOutput); }

  public get resolvedOutput(): Option<Output> { return Some(this.stateOutput); }

  public get assumedProgress(): number { return 100; }

  public map<T>(mapOutput: (output: Output) => T): State<T> {
    return new ResolvedState(mapOutput(this.stateOutput), this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<Output> {
    return new ResolvedState(this.stateOutput, errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Success;
  }
}

class ErrorState<Output> extends State<Output> {
  public get isError(): boolean { return true; }

  public get isEnabled(): boolean { return true; }

  public get assumedProgress(): number { return 0; }

  public map<T>(mapOutput: (output: Output) => T): State<T> {
    return new ErrorState(this.errors);
  }

  public withErrors(errors: List<BlueprintError>): State<Output> {
    return new ErrorState(errors);
  }

  public toWorkStatus(): WorkStatus {
    return WorkStatus.Issue;
  }
}

export namespace State {
  export function blocked<Output>(errors: List<BlueprintError> = List()): BlockedState<Output> {
    return new BlockedState(errors);
  }

  export function pending<Output>(errors: List<BlueprintError> = List()): PendingState<Output> {
    return new PendingState(errors);
  }

  export function preparing<Output>(errors: List<BlueprintError> = List()): PreparingState<Output> {
    return new PreparingState(errors);
  }

  export function resolving<PartialOutput>(
    output: PartialOutput,
    progress: Option<number> = None(),
    errors: List<BlueprintError> = List()
  ): ResolvingState<PartialOutput> {
    return new ResolvingState(output, progress, errors);
  }

  export function almostResolved<PartialOutput>(
    output: PartialOutput,
    progress: Option<number> = None(),
    errors: List<BlueprintError> = List()
  ): AlmostResolvedState<PartialOutput> {
    return new AlmostResolvedState(output, progress, errors);
  }

  export function resolved<Output>(output: Output, errors: List<BlueprintError> = List()): ResolvedState<Output> {
    return new ResolvedState(output, errors);
  }

  export function error<Output>(...errors: BlueprintError[]): ErrorState<Output> {
    return new ErrorState(List(errors));
  }

  export function errorEx<Output>(errors: List<BlueprintError>): ErrorState<Output> {
    return new ErrorState(List(errors));
  }

  export function totalProgress(states: List<State<any>>): Option<number> {
    const progresses = states.map((state) => state.assumedProgress);
    return progresses.isEmpty()
      ? None()
      : Some(progresses.reduce<number>((a, b) => a + b) / progresses.size);
  }

  export interface StateTypesCounts {
    blocked: number;
    pending: number;
    preparing: number;
    resolving: number;
    almostResolved: number;
    resolved: number;
    error: number;
  }

  export function stateTypesCounts(states: List<State<any>>): StateTypesCounts {
    const result: StateTypesCounts = {
      blocked: 0,
      pending: 0,
      preparing: 0,
      resolving: 0,
      almostResolved: 0,
      resolved: 0,
      error: 0
    };
    states.forEach((state) => {
      if (state instanceof BlockedState) {
        result.blocked += 1;
      } else if (state instanceof PendingState) {
        result.pending += 1;
      } else if (state instanceof PreparingState) {
        result.preparing += 1;
      } else if (state instanceof ResolvingState) {
        result.resolving += 1;
      } else if (state instanceof AlmostResolvedState) {
        result.almostResolved += 1;
      } else if (state instanceof ResolvedState) {
        result.resolved += 1;
      } else if (state instanceof ErrorState) {
        result.error += 1;
      }
    });
    return result;
  }

  export function reduceStates<T, Output>(
    states: List<State<T>>,
    ignoreBlocked: boolean,
    buildOutput: (values: List<T>) => Option<Output>,
    defaultState: State<Output> = pending()
  ): State<Output> {
    return reduceStateMap(
      states.toMap(),
      ignoreBlocked,
      (values) => buildOutput(values.valueSeq().toList()),
      defaultState
    );
  }

  export function reduceStateMap<K, T, Output>(
    states: Map<K, State<T>>,
    ignoreBlocked: boolean,
    buildOutput: (values: Map<K, T>) => Option<Output>,
    defaultState: State<Output> = pending()
  ): State<Output> {
    const filteredStates = states.filter((state) => !ignoreBlocked || !state.isBlocked);

    const filteredStateList = filteredStates.valueSeq().toList();
    const outputs = filteredStates.flatMap((state, key) =>
      state.output.map((output) => [key, output] as [K, T]).toList()
    );

    const errors = BlueprintError.reduceList(
      filteredStates.valueSeq().flatMap((state) => state.errors).toList()
    );
    const defaultStateWithErrors = defaultState.withErrors(errors);

    if (!filteredStates.isEmpty()) {
      const stats = stateTypesCounts(filteredStateList);
      if (stats.error !== 0) {
        return errorEx(
          BlueprintError.reduceList(
            filteredStates
              .valueSeq()
              .filter((state) => state.isError)
              .flatMap((state) => state.errors)
              .toList()
          )
        );
      } else if (stats.blocked !== 0) {
        return blocked();
      } else if (stats.resolved === filteredStates.size) {
        return buildOutput(outputs)
          .map((output) => resolved(output, errors))
          .getOrElse(() => defaultStateWithErrors);
      } else if (stats.almostResolved !== 0 && stats.almostResolved + stats.resolved === filteredStates.size) {
        return buildOutput(outputs)
          .map((output) => almostResolved(output, totalProgress(filteredStates.valueSeq().toList()), errors))
          .getOrElse(() => defaultStateWithErrors);
      } else if (stats.pending === filteredStates.size) {
        return pending(errors);
      } else if (stats.preparing !== 0 && stats.pending + stats.preparing === filteredStates.size) {
        return preparing(errors);
      } else {
        return buildOutput(outputs)
          .map((output) => resolving(output, totalProgress(filteredStates.valueSeq().toList()), errors))
          .getOrElse(() => defaultStateWithErrors);
      }
    } else {
      return defaultStateWithErrors;
    }
  }

  export function compute<Output>(...states: State<any>[]): ((buildOutput: () => Option<Output>) => State<Output>) {
    return (buildOutput) => reduceStates<any, Output>(List(states), false, buildOutput);
  }

  // Used to extract output from a state previously converted to JSON object
  export function getOutput(state: any): any {
    return state.hasOwnProperty("stateOutput") ? state.stateOutput : undefined;
  }
}
