import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const userId: string = "userId";
}

export class AmbassadorsRoutes {
  private readonly root = "amb";

  constructor(private context: RoutesContext) {}

  public get myAmbassadorDashboardMask(): string {
    return this.root;
  }

  public get myAmbassadorDashboardPath(): string {
    return this.context.resolvePath(this.root);
  }

  public get otherAmbassadorDashboardMask(): string {
    return this.root + "/:" + RouteParams.userId;
  }

  public otherAmbassadorDashboardPath(userId: string): string {
    return this.context.resolvePath(this.root + "/" + userId);
  }

  public otherAmbassadorDashboardParams(): AmbassadorsRoutes.OtherAmbassadorDashboardParams {
    const userId = this.context.routeParams[RouteParams.userId];
    if (userId) {
      return { userId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }
}

export namespace AmbassadorsRoutes {
  export interface OtherAmbassadorDashboardParams {
    userId: string;
  }
}
