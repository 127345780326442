import { MigrationStatus } from "./migrationStatus";

export enum IterationStatus {
  Scheduled = "Scheduled",
  Starting = "Starting",
  Running = "Running",
  Completed = "Completed",
  Aborted = "Aborted"
}

export namespace IterationStatus {
  export function userFriendly(status: IterationStatus): string {
    return status;
  }

  export function fromMigrationStatus(status: MigrationStatus): IterationStatus {
    switch (status) {
      case MigrationStatus.Scheduled:
        return IterationStatus.Scheduled;

      case MigrationStatus.Starting:
        return IterationStatus.Starting;

      case MigrationStatus.Running:
      case MigrationStatus.Pausing:
      case MigrationStatus.Paused:
      case MigrationStatus.WaitingForTemporaryCondition:
      case MigrationStatus.WaitingForUserAction:
      case MigrationStatus.WaitingForManualReview:
      case MigrationStatus.StoppedForUnknownReason:
        return IterationStatus.Running;

      case MigrationStatus.Completed:
        return IterationStatus.Completed;
      case MigrationStatus.Aborted:
        return IterationStatus.Aborted;
    }
  }
}
