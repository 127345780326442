import { Program } from "../../types/models/program";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { ProgramFormData } from "../../types/models/programFormData";

type InsertProgramMutationHook = [
  (formData: ProgramFormData) => Promise<Program>,
  OperationStatus<Program>
];

export function useInsertProgramMutation(organizationId: number): InsertProgramMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertProgramMutation,
    extract: (data: GraphQL.InsertProgramMutation) => nullToUndefined(data.insertProgram),
    complete: Program.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This ID is already being used" })
      ]
    ]
  });

  function fireWith(formData: ProgramFormData): Promise<Program> {
    return fire({
      variables: { organizationId, formData: ProgramFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
