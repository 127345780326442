import * as React from "react";
import { ServerLabel } from "./serverLabel";
import { State } from "../state";
import { BlueprintDiff } from "../blueprintDiff";
import { DiffLabel } from "./diffLabel";
import { multiline } from "../../utils/formatting";
import { styled } from "../../app/theme";
import { BlueprintError } from "../blueprintError";
import { List } from "immutable";

interface Props {
  state: State<any>;
  resolvedByServer: boolean;
  diff: BlueprintDiff.ElementStateDiff | undefined;
}

const Errors = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-weight: ${(props) => props.theme.font.medium};
`;

export const ElementErrors: React.FunctionComponent<Props> = (props) => {
  function render(errors: List<BlueprintError>) {
    return errors.isEmpty()
      ? "--"
      : (
        <Errors>
          {
            multiline(
              errors.map((error) =>
                error.type + error.message.map((message) => " (" + message + ")").getOrElse(() => "")
              )
            )
          }
        </Errors>
      );
  }

  const state = render(props.state.errors);

  const diff = props.diff && !BlueprintError.areEqual(props.diff.serverState.errors, props.state.errors)
    ? render(props.diff.serverState.errors)
    : undefined;

  if (diff) {
    return (
      <>
        <DiffLabel>diff</DiffLabel>
        {state}
        <br/>
        On the server:<br/>
        {diff}
      </>
    );
  } else {
    return (
      <>
        {props.resolvedByServer && <ServerLabel/>}
        {state}
      </>
    );
  }
};
