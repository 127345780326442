import * as React from "react";
import { DataGroup, DataItem, IdType } from "vis";
import { List, Map, OrderedMap } from "immutable";
import { Checkbox } from "../../widgets/checkbox";
import { preciseTimestamp } from "../../../utils/formatting";
import { TaskIssue } from "../../../types/models/taskIssue";
import { itemProgress, renderTooltip } from "./utils";
import { TimelinePanel } from "../timeline/timelinePanel";
import { TaskHistory } from "../../../types/models/taskHistory";
import { TaskHistoryRecord } from "../../../types/models/taskHistoryRecord";
import { DataItemEx } from "../timeline/dataItemEx";
import { useBrowser } from "../../../utils/useBrowser";
import { useRoutes } from "../../../app/routes/useRoutes";

namespace Groups {
  export const Events = "0/Events";

  export const Tasks = "1/Tasks";

  export const TaskIssues = "1/Tasks"; // Using the same Id as Tasks to retain correct sorting order

  export function taskIssueGroupId(taskId: string, issueId: string): string {
    return TaskIssues + "/" + taskId + "/" + issueId;
  }
}

namespace Items {
  export function taskItemId(taskId: string, transition: number): string {
    return "Task/" + taskId + "/" + transition;
  }

  export function taskIssueItemId(taskId: string, transition: number, issueId: string, reportedAt: Date): string {
    return "TaskIssue/" + taskId + "/" + transition + "/" + issueId + "/" + reportedAt;
  }
}

interface Props {
  taskHistory: TaskHistory;
}

export const TaskTimeline: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();
  const [showEvents, setShowEvents] = React.useState(false);

  const lastRecord = props.taskHistory.taskHistory.last(undefined);
  const archived = lastRecord !== undefined && lastRecord.isArchived();

  function renderGroups(): OrderedMap<string, DataGroup> {
    const allTaskIssueIds = props.taskHistory.taskIssues
      .filter((taskIssue) => taskIssue.taskId === props.taskHistory.taskId)
      .map((taskIssue) => taskIssue.issueId)
      .toSet()
      .toList()
      .sort();

    return OrderedMap(
      List([
        {
          id: Groups.Events,
          content: "Events",
          className: "job-events"
        },
        {
          id: Groups.Tasks,
          content: "Task Runs",
          className: "tasks"
        },
      ])
        .concat(
          allTaskIssueIds.map((taskIssueId) => ({
            id: Groups.taskIssueGroupId(props.taskHistory.taskId, taskIssueId),
            content: "<a href=\"" +
              browser.href(routes.jobs.taskIssuePath(props.taskHistory.jobId, props.taskHistory.taskId, taskIssueId)) +
              "\">" + taskIssueId + "</a>",
            className: "task-issues"
          })))
        .map((item) => [item.id, item]));
  }

  function renderJobEvent(
    record: TaskHistoryRecord,
    type: "started" | "stopped" | "other"
  ): DataItem {
    const content = record.updateSummary + (
      record.updatedBy && record.updatedBy !== "System"
        ? "<br><span class=\"updated-by\">(by " + record.updatedBy + ")</span>"
        : ""
    );

    const title = renderTooltip({
      props: [
        ["Timestamp", preciseTimestamp(record.updatedAt)],
        ["Transition", "" + record.transition],
        ["Update summary", record.updateSummary || "--"],
        ["Updated by", record.updatedBy || "--"],
        ["System status", record.internalStatus],
      ]
    });

    return {
      id: record.timestamp + "/" + record.updateSummary,
      group: Groups.Events,
      content,
      title,
      start: record.timestamp,
      className: "event " + type
    };
  }

  function renderTask(task: TaskHistoryRecord): DataItemEx {
    return {
      id: Items.taskItemId(task.taskId, task.transition),
      group: Groups.Tasks,
      content: task.transition + ":" + task.taskId,
      title: renderTooltip({
        props: [
          ["Type", task.type],
          ["Transition", "" + task.transition],
          ["Started at", preciseTimestamp(task.createdAt)],
          ["Completed at", task.completedAt ? preciseTimestamp(task.completedAt) : "--"],
          ["Archived at", task.archivedAt ? preciseTimestamp(task.archivedAt) : "--"],
          ["Result type", task.resultType || "--"],
        ]
      }),
      start: task.createdAt,
      end: task.archivedAt || new Date(),
      className: "task " + (task.archivedAt ? "completed" : "active"),
      progress: itemProgress(task.createdAt, task.completedAt, task.archivedAt)
    };
  }

  function renderTaskIssue(taskIssue: TaskIssue): DataItem {
    const task = props.taskHistory.taskHistory.findLast((t) => t.transition === taskIssue.transition);
    return {
      id: Items.taskIssueItemId(taskIssue.taskId, taskIssue.transition, taskIssue.issueId, taskIssue.createdAt),
      group: Groups.taskIssueGroupId(taskIssue.taskId, taskIssue.issueId),
      content: taskIssue.issueId,
      title: renderTooltip(taskIssue.timelineTooltip()),
      start: taskIssue.createdAt,
      // Making sure that unresolved issues will not break the boundaries of the task
      end: taskIssue.resolvedAt || task && task.archivedAt || new Date(),
      className: "issue " + (taskIssue.resolvedAt ? "completed" : "active")
    };
  }

  function renderEvents(): List<DataItem> {
    let prevRecord: TaskHistoryRecord | undefined;
    return props.taskHistory.taskHistory.flatMap((record) => {
      const prevArchived = prevRecord === undefined || prevRecord.isArchived();
      const events = record.updateSummary && showEvents
        ? List([
          renderJobEvent(
            record,
            prevArchived && !record.isArchived()
              ? "started"
              : !prevArchived && record.isArchived()
                ? "stopped"
                : "other"
          )
        ])
        : List();
      prevRecord = record;
      return events;
    });
  }

  function renderItems(): Map<IdType, DataItem> {
    return Map(
      renderEvents()
        .concat(props.taskHistory.taskHistory.map(renderTask))
        .concat(props.taskHistory.taskIssues.map(renderTaskIssue))
        .map((item) => [item.id || 0, item])
    );
  }

  return (
    <TimelinePanel
      subject={props.taskHistory.jobId + "/" + props.taskHistory.taskId}

      groups={renderGroups()}
      items={renderItems()}

      canFollow={!archived}
      additionalTools={(
        <Checkbox checked={showEvents} onChange={() => setShowEvents(!showEvents)}>
          Task events
        </Checkbox>
      )}
    />
  );
};
