import * as React from "react";
import { styled } from "../../../../app/theme";
import { HasClickHandler } from "../../../utils/simpleClickHandler";

interface DotProps extends HasClickHandler {
  isBlue?: boolean;
}

const CarouselDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.8rem 0 0;
`;

interface CarouselDotsProps {
  count: number;
  selected: number;
  onSelect: (index: number) => void;
}

export const CarouselDots: React.FunctionComponent<CarouselDotsProps> = (props) => {
  const dots: React.ReactNode[] = [];
  for (let index = 0; index < props.count; index += 1) {
    dots.push(
      <CarouselDot
        key={index}
        isBlue={index === props.selected}
        onClick={() => props.onSelect(index)}
      />
    );
  }

  return <CarouselDotsContainer>{dots}</CarouselDotsContainer>;
};

const StyledDot = styled.svg<DotProps>`
  fill: ${(props) => props.isBlue ? props.theme.colors.primary : props.theme.colors.lightGray};
  width: 0.75rem;
`;

const DotSVG: React.FunctionComponent<DotProps> = (props) => (
  <StyledDot viewBox="0 0 12 12" isBlue={props.isBlue}>
    <circle cx="6" cy="6" r="6"/>
  </StyledDot>
);

const DotLink = styled.div`
  padding: 0.125rem;
  margin: 0.3125rem;
  line-height: 0.8;

  @media(hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const CarouselDot: React.FunctionComponent<DotProps> = (props) => (
  <DotLink onClick={props.onClick}>
    <DotSVG isBlue={props.isBlue}/>
  </DotLink>
);
