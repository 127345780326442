import { PricingModel } from "../../types/models/pricingModel";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { PricingModelFormData } from "../../types/models/pricingModelFormData";

type UpdatePricingModelMutationHook = [
  (id: number, formData: PricingModelFormData) => Promise<PricingModel>,
  OperationStatus<PricingModel>
];

export function useUpdatePricingModelMutation(): UpdatePricingModelMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdatePricingModelMutation,
    extract: (data: GraphQL.UpdatePricingModelMutation) => nullToUndefined(data.updatePricingModel),
    complete: PricingModel.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(id: number, formData: PricingModelFormData): Promise<PricingModel> {
    return fire({
      variables: { id, formData: PricingModelFormData.toGraphQL(formData) },
      retry: () => fireWith(id, formData),
    });
  }

  return [fireWith, status];
}
