import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { ReferralCode } from "../../types/models/referralCode";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime } from "../../utils/formatting";
import { useDeleteReferralCodeMutation } from "../../queries/referralCodes/useDeleteReferralCodeMutation";
import { useUpdateReferralCodeMutation } from "../../queries/referralCodes/useUpdateReferralCodeMutation";
import { useInsertReferralCodeMutation } from "../../queries/referralCodes/useInsertReferralCodeMutation";
import { useGetReferralCodeQuery } from "../../queries/referralCodes/useGetReferralCodeQuery";
import { ReferralCodeFormData } from "../../types/models/referralCodeFormData";
import { WidgetStatus } from "../../views/utils/widgetStatus";
import { useSearchReferralCodesQuery } from "../../queries/referralCodes/useSearchReferralCodesQuery";
import { ReferralCodeForm } from "../../views/screens/organizationPortal/referAndEarn/referralCodeForm";
import { OrganizationSummary } from "../../types/models/organization";
import { CRUDConfig } from "../../components/crud/crudConfig";
import { useElevated } from "../../utils/useAppState";
import { Link } from "react-router-dom";
import { useFileUploadController } from "../../queries/api/useFileUploadController";

interface Props extends Pick<
  CRUDConfig<number, ReferralCode, ReferralCodeFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  breadcrumbs?: React.ReactNode[];
}

export const ReferralCodeAdminPages: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();

  const fileUploadController = useFileUploadController();

  return CrudPages<number, ReferralCode, ReferralCodeFormData>({
    breadcrumbs: props.breadcrumbs,
    title: "Referral Codes",
    subject: "referral code",
    readOnly: !elevated,
    startFrom: props.startFrom,
    layout: props.layout,
    gridColumns: [
      {
        title: "Code",
        render: (referralCode) => referralCode.code
      },
      {
        title: "Status",
        render: (referralCode) => ({
          content: referralCode.isEnabled ? "Enabled" : "Disabled",
          status: referralCode.isEnabled ? undefined : WidgetStatus.Uncertain
        })
      },
      {
        title: "Discount",
        render: (referralCode) => referralCode.discount + "%"
      },
      {
        title: "Revenue Share",
        render: (referralCode) => referralCode.revenueShare + "%"
      },
      {
        title: "Created At",
        render: (referralCode) => friendlyDateTime(referralCode.createdAt)
      },
    ],
    renderForm: (config) => <ReferralCodeForm config={config} fileUploadController={fileUploadController}/>,

    useRoutes: props.useRoutes,
    getItemId: (referralCode) => referralCode.id,
    getItemTitle: (referralCode) => referralCode.code,
    clone: (referralCode) => ({ ...referralCode, code: "COPY_OF_" + referralCode.code }),
    compare: (a: ReferralCode, b: ReferralCode) => {
      const codeA = a.code.toLowerCase();
      const codeB = b.code.toLowerCase();
      return codeA < codeB ? -1 : codeA > codeB ? 1 : 0;
    },

    useGetQuery: useGetReferralCodeQuery,
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: useUpdateReferralCodeMutation,
    useDeleteMutation: useDeleteReferralCodeMutation
  });
};

interface OrganizationReferralCodeAdminPagesProps {
  organization: OrganizationSummary;
}

export const OrganizationReferralCodeAdminPages: React.FunctionComponent<OrganizationReferralCodeAdminPagesProps> =
  (props) => {
    const routes = useRoutes();

    return (
      <ReferralCodeAdminPages
        startFrom={"list"}
        layout={"compact"}

        useRoutes={() => useRoutes().organizationPortalRoutes.referralCodes(props.organization.alias)}
        useSearchQuery={(term) => useSearchReferralCodesQuery(props.organization.id, term)}
        useInsertMutation={() => useInsertReferralCodeMutation(props.organization.id)}

        breadcrumbs={[
          <Link key={0} to={routes.organizationPortalRoutes.referAndEarnPath(props.organization.alias)}>
            Alumni Association Member Benefit Program
          </Link>
        ]}
      />
    );
  };
