import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { OrganizationPortalUserFormData } from "../../types/models/organizationPortalUserFormData";

type UpdateOrganizationPortalUserMutationHook = [
  (id: number, formData: OrganizationPortalUserFormData) => Promise<OrganizationPortalUser>,
  OperationStatus<OrganizationPortalUser>
];

export function useUpdateOrganizationPortalUserMutation(): UpdateOrganizationPortalUserMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateOrganizationPortalUserMutation,
    extract: (data: GraphQL.UpdateOrganizationPortalUserMutation) => nullToUndefined(data.updateOrganizationPortalUser),
    complete: OrganizationPortalUser.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This email address is already registered" })
      ]
    ]
  });

  function fireWith(id: number, formData: OrganizationPortalUserFormData): Promise<OrganizationPortalUser> {
    return fire({
      variables: { id, formData: OrganizationPortalUserFormData.toGraphQL(formData) },
      retry: () => fireWith(id, formData),
    });
  }

  return [fireWith, status];
}
