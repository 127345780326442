import * as yup from "yup";

export interface BatchMigrationPreferences {
  createSubfolder: boolean;
  excludedAreas: string[];
}

export namespace BatchMigrationPreferences {
  export const Schema = yup.object<BatchMigrationPreferences>({
    createSubfolder: yup.boolean().required(),
    excludedAreas: yup.array().of(yup.string().required())
  });
}
