import { BlueprintException } from "../blueprintException";
import { Blueprint } from "../blueprint";
import { AnyComponent } from "../component";
import { GraphQL } from "../../services/graphql/generated";
import { AreaComp } from "./areaComp";
import { ComponentBinding } from "./componentBinding";
import { ConnectionComp } from "./connectionComp";
import { ChildEndpointComp, EndpointComp, RootEndpointComp } from "./endpointComp";
import { FactComp } from "./factComp";
import { PhaseComp } from "./phaseComp";
import { ReducerComp } from "./reducerComp";
import { RestrictionComp } from "./restrictionComp";
import { RoleComp } from "./roleComp";
import { SinkComp } from "./sinkComp";
import { VariableComp } from "./variableComp";
import { MigrationProgressComp } from "./migrationProgressComp";
import { StorageRestrictionComp } from "./storageRestrictionComp";
import { TimeEstimateComp } from "./timeEstimateComp";

export namespace ComponentParser {
  export function fromGraphQL(
    blueprint: Blueprint,
    component: GraphQL.ComponentFragment
  ): AnyComponent {
    const binding = new ComponentBinding(blueprint, component.id);

    switch (component.__typename) {
      case "AreaComp":
        return new AreaComp(
          blueprint,
          component,
          AreaComp.Props.fromGraphQL(component.areaCompProps),
          AreaComp.Hubs.fromGraphQL(binding, component.areaCompHubs),
        );

      case "ChildEndpointComp":
        return new ChildEndpointComp(
          blueprint,
          component,
          EndpointComp.Props.fromGraphQL(component.childEndpointCompProps),
          ChildEndpointComp.Hubs.fromGraphQL(binding, component.childEndpointCompHubs),
        );

      case "ConnectionComp":
        return new ConnectionComp(
          blueprint,
          component,
          ConnectionComp.Props.fromGraphQL(component.connectionCompProps),
          ConnectionComp.Hubs.fromGraphQL(binding, component.connectionCompHubs),
        );

      case "FactComp":
        return new FactComp(
          blueprint,
          component,
          FactComp.Props.fromGraphQL(component.factCompProps),
          FactComp.Hubs.fromGraphQL(binding, component.factCompHubs),
        );

      case "PhaseComp":
        return new PhaseComp(
          blueprint,
          component,
          PhaseComp.Props.fromGraphQL(component.phaseCompProps),
          PhaseComp.Hubs.fromGraphQL(binding, component.phaseCompHubs),
        );

      case "MigrationProgressComp":
        return new MigrationProgressComp(
          blueprint,
          component,
          MigrationProgressComp.Props.fromGraphQL(component.migrationProgressCompProps),
          MigrationProgressComp.Hubs.fromGraphQL(binding, component.migrationProgressCompHubs),
        );

      case "ReducerComp":
        return new ReducerComp(
          blueprint,
          component,
          ReducerComp.Props.fromGraphQL(component.reducerCompProps),
          ReducerComp.Hubs.fromGraphQL(binding, component.reducerCompHubs),
        );

      case "RestrictionComp":
        return new RestrictionComp(
          blueprint,
          component,
          RestrictionComp.Props.fromGraphQL(component.restrictionCompProps),
          RestrictionComp.Hubs.fromGraphQL(binding, component.restrictionCompHubs),
        );

      case "RoleComp":
        return new RoleComp(
          blueprint,
          component,
          RoleComp.Props.fromGraphQL(component.roleCompProps),
          RoleComp.Hubs.fromGraphQL(binding, component.roleCompHubs),
        );

      case "RootEndpointComp":
        return new RootEndpointComp(
          blueprint,
          component,
          EndpointComp.Props.fromGraphQL(component.rootEndpointCompProps),
          RootEndpointComp.Hubs.fromGraphQL(binding, component.rootEndpointCompHubs),
        );

      case "SinkComp":
        return new SinkComp(
          blueprint,
          component,
          SinkComp.Props.fromGraphQL(component.sinkCompProps),
          SinkComp.Hubs.fromGraphQL(binding, component.sinkCompHubs),
        );

      case "StorageRestrictionComp":
        return new StorageRestrictionComp(
          blueprint,
          component,
          StorageRestrictionComp.Props.fromGraphQL(component.storageRestrictionCompProps),
          StorageRestrictionComp.Hubs.fromGraphQL(binding, component.storageRestrictionCompHubs),
        );

      case "TimeEstimateComp":
        return new TimeEstimateComp(
          blueprint,
          component,
          TimeEstimateComp.Props.fromGraphQL(component.timeEstimateCompProps),
          TimeEstimateComp.Hubs.fromGraphQL(binding, component.timeEstimateCompHubs),
        );

      case "VariableComp":
        return new VariableComp(
          blueprint,
          component,
          VariableComp.Props.fromGraphQL(component.variableCompProps),
          VariableComp.Hubs.fromGraphQL(binding, component.variableCompHubs),
        );

      case undefined:
        console.error("Undefined component type returned from the server", component);
        throw new BlueprintException("Undefined component type returned from the server");
    }
  }
}
