import { AppAction } from "../appAction";
import { ProtoStateActionType } from "./actions";
import { ProtoState } from "./state";

export function reduceProtoState(state: ProtoState, action: AppAction): ProtoState {
  switch (action.type) {
    case ProtoStateActionType.ProtoStateUpdated:
      return { ...state, ...action.protoState };

    default:
      return state;
  }
}
