import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { State } from "../state";
import { None, Option, Some } from "../../utils/monads/option";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";

export class SelectorHub<Output> extends DataSource<SelectorHub.Props, Output, Option<Output>> {
  public calcState(context: BlueprintContext): State<Option<Output>> {
    if (!this.dataFlows().isEmpty()) {
      const states = this.dataFlows()
        .map((dataFlow) => dataFlow.state(context))
        .filter((state) => state.isResolved);
      return Option.mayBe(states.first(undefined))
        .filter(() => states.size === 1)
        .map((firstAndOnlyState) => firstAndOnlyState.map((output) => Some(output)))
        .getOrElse(() => State.pending());
    } else {
      return State.resolved(None());
    }
  }
}

export namespace SelectorHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.SelectorHubFragment,
    settings?: ComponentHubSettings
  ): SelectorHub<any> {
    return new SelectorHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
