import * as React from "react";
import { Navigate } from "react-router";
import { WorkflowLayout } from "../layouts/workflowLayout";
import { Header } from "../widgets/header";
import { StyleguideNav } from "./styleguideNav";
import { StyleguideSections } from "./styleguideSections";
import { CenteredContent } from "../layouts/centeredContent";
import { useRoutes } from "../../app/routes/useRoutes";

interface StyleguideProps {
  section: string;
}

export const Styleguide: React.FunctionComponent<StyleguideProps> = (props) => {
  const routes = useRoutes().styleguide;
  const section = StyleguideSections.find((s) => s.id === props.section);
  if (section) {
    return (
      <WorkflowLayout leftHandNavContent={<StyleguideNav section={props.section}/>}>
        <CenteredContent>
          <Header>{section.title}</Header>
          {section.content}
        </CenteredContent>
      </WorkflowLayout>
    );
  } else {
    return <Navigate to={routes.sectionPath(StyleguideSections[0].id)}/>;
  }
};

export const RoutedStyleGuide: React.FunctionComponent = () => {
  const params = useRoutes().styleguide.params();
  return <Styleguide section={params.section.getOrUse("")}/>;
};
