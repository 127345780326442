import { ConnectionPanelDefs } from "./connectionPanelDefs";
import { UserFacingError } from "../../../types/userFacingError";
import { List } from "immutable";

export namespace ConnectionPanelState {
  export enum Type {
    SigningIn = "SigningIn",
    ConnectedBadAccount = "ConnectedBadAccount",
    Scanning = "Scanning",
    ScanningError = "ScanningError",
    Connected = "Connected"
  }

  export function statePriority(state: Any): number {
    const sortedStates = [
      Type.SigningIn,
      Type.ConnectedBadAccount,
      Type.Scanning,
      Type.ScanningError,
      Type.Connected
    ];
    return sortedStates.indexOf(state.type);
  }

  export function highestPriorityState(a: Any, b: Any): Any {
    const priorityA = statePriority(a);
    const priorityB = statePriority(b);
    if (priorityA < priorityB) {
      return b;
    } else {
      return a;
    }
  }

  export interface Base<T extends Type> {
    type: T;
  }

  export interface SigningIn extends Base<Type.SigningIn> {
  }

  export function SigningIn(): SigningIn {
    return {
      type: Type.SigningIn
    };
  }

  export interface ConnectedBadAccountProps {
    error: UserFacingError;
    connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
    onDisconnect?: () => void;
  }

  export interface ConnectedBadAccount extends Base<Type.ConnectedBadAccount>, ConnectedBadAccountProps {
  }

  export function ConnectedBadAccount(props: ConnectedBadAccountProps): ConnectedBadAccount {
    return {
      type: Type.ConnectedBadAccount,
      ...props
    };
  }

  interface ScanningProps {
    connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
    areas: List<ConnectionPanelDefs.Area>;
    onDisconnect?: () => void;
  }

  export interface Scanning extends Base<Type.Scanning>, ScanningProps {
  }

  export function scanning(props: ScanningProps): Scanning {
    return {
      type: Type.Scanning,
      ...props
    };
  }

  interface ScanningErrorProps extends ScanningProps {
    error: UserFacingError;
  }

  export interface ScanningError extends Base<Type.ScanningError>, ScanningErrorProps {
  }

  export function scanningError(props: ScanningErrorProps): ScanningError {
    return {
      type: Type.ScanningError,
      ...props
    };
  }

  interface ConnectedProps {
    connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
    areas: List<ConnectionPanelDefs.Area>;
    onReScan: () => void;
    onDisconnect?: () => void;
  }

  export interface Connected extends Base<Type.Connected>, ConnectedProps {
  }

  export function connected(props: ConnectedProps): Connected {
    return {
      type: Type.Connected,
      ...props
    };
  }

  export type Any = SigningIn | ConnectedBadAccount | Scanning | ScanningError | Connected;

  export function areas(state: Any): List<ConnectionPanelDefs.Area> {
    if (state.type !== Type.SigningIn && state.type !== Type.ConnectedBadAccount) {
      return state.areas;
    } else {
      return List();
    }
  }
}
