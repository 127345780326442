import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface ThemeOverrides {
  readonly primary: string;
  readonly darkPrimary: string | undefined;
  readonly darkerPrimary: string | undefined;
  readonly lightPrimary: string | undefined;
  readonly faintPrimary: string | undefined;

  readonly red: string | undefined;
  readonly lightRed: string | undefined;

  readonly yellow: string | undefined;
  readonly lightYellow: string | undefined;

  readonly orange: string | undefined;
  readonly lightOrange: string | undefined;

  readonly green: string | undefined;
  readonly lightGreen: string | undefined;

  readonly titleColor: string | undefined;
  readonly textColor: string | undefined;

  readonly useMonochromeLogo: boolean;
}

export namespace ThemeOverrides {
  export function parse(data: GraphQL.ThemeOverridesFragment): ThemeOverrides {
    return {
      primary: data.primary,
      darkPrimary: nullToUndefined(data.darkPrimary),
      darkerPrimary: nullToUndefined(data.darkerPrimary),
      lightPrimary: nullToUndefined(data.lightPrimary),
      faintPrimary: nullToUndefined(data.faintPrimary),

      red: nullToUndefined(data.red),
      lightRed: nullToUndefined(data.lightRed),

      yellow: nullToUndefined(data.yellow),
      lightYellow: nullToUndefined(data.lightYellow),

      orange: nullToUndefined(data.orange),
      lightOrange: nullToUndefined(data.lightOrange),

      green: nullToUndefined(data.green),
      lightGreen: nullToUndefined(data.lightGreen),

      titleColor: nullToUndefined(data.titleColor),
      textColor: nullToUndefined(data.textColor),

      useMonochromeLogo: data.useMonochromeLogo,
    };
  }
}
