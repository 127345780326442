import * as React from "react";
import { ConnectionPanelRow } from "../../../blocks/connectionPanelRow";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { Slowness } from "../../../models/slowness";
import { WorkStatus } from "../../../models/workStatus";
import { List } from "immutable";

interface ConnectionIssuesRowProps {
  connectionDetails: MigrationStatusPageDefs.ConnectionDetailsEx;
}

export const ConnectionIssuesRow: React.FunctionComponent<ConnectionIssuesRowProps> = (props) => {
  const issues = (props.connectionDetails.actionItems || List())
    .concat(Slowness.toActionItems(props.connectionDetails.slowness));
  if (!issues.isEmpty()) {
    return (
      <ConnectionPanelRow
        icon={props.connectionDetails.cloudService.icon}
        title={"Secure account connection"}
        content={{ actionItems: issues }}
        status={WorkStatus.Issue}
        progressIndicator={"hidden"}
        actionItemSuppressing={undefined}
      />
    );
  } else {
    return null;
  }
};
