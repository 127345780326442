import { GraphQL } from "../../services/graphql/generated";
import { ContestEvent } from "../enums/contestEvent";

export interface ContestEntry {
  readonly event: ContestEvent;
  readonly count: number;
  readonly createdAt: Date;
}

export namespace ContestEntry {
  export function parse(data: GraphQL.ContestEntry): ContestEntry {
    return {
      event: data.event,
      count: data.count,
      createdAt: new Date(data.createdAt)
    };
  }
}
