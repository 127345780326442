import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type OptOutOffboardingProjectEntryMutationHook = [
  (reason: string, comment: string | undefined) => Promise<boolean>,
  OperationStatus<boolean>
];

export function useOptOutOffboardingProjectEntryMutation(id: string): OptOutOffboardingProjectEntryMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useOptOutOffboardingProjectEntryMutation,
    extract: (data: GraphQL.OptOutOffboardingProjectEntryMutation) =>
      nullToUndefined(data.optOutOffboardingProjectEntry),
    complete: identity
  });

  function fireWith(reason: string, comment: string | undefined): Promise<boolean> {
    return fire({
      variables: { id, reason, comment },
      retry: () => fireWith(reason, comment),
    });
  }

  return [fireWith, status];
}
