import * as React from "react";
import { Step, WizardNavigationContainer } from "../../../containers/step";
import { FieldArray, Form, Formik } from "formik";
import { FormLayout } from "../../../widgets/formLayout";
import { CheckboxField } from "../../../widgets/checkboxField";
import { WizardNavigation } from "../../../widgets/wizardNavigation";
import { List, Set } from "immutable";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { Block } from "../../../containers/block";
import { DrawerContent } from "../../../utils/drawerContent";
import { SingleAccountHint } from "./batchMigrationPlanStepView/singleAccountHint";
import { CloudService } from "../../../../types/models/cloudService";
import { BatchMigrationItem } from "../../../../migrationSetup/batch/batchCheckoutStep";

interface Result {
  createSubfolder: boolean;
  excludedAreas: string[];
}

export interface IncludedAreasData {
  [area: string]: boolean;
}

export interface FormData {
  createSubfolder: boolean;
  includedAreas: IncludedAreasData;
}

namespace FormData {
  export function empty(areas: List<string>): FormData {
    const includedAreas: IncludedAreasData = {};
    areas.forEach((area) => includedAreas[area] = true);
    return {
      createSubfolder: false,
      includedAreas
    };
  }

  export function fromResult(areas: List<string>, result: Result): FormData {
    const { excludedAreas, ...rest } = result;

    const includedAreas: IncludedAreasData = {};
    const excludedAreasSet = Set(excludedAreas);
    areas.forEach((area) => {
      includedAreas[area] = !excludedAreasSet.contains(area);
    });

    return {
      ...rest,
      includedAreas
    };
  }

  export function toResult(data: FormData): Result {
    const { includedAreas, ...rest } = data;

    const excludedAreas: string[] = [];
    for (const area in includedAreas) {
      if (includedAreas.hasOwnProperty(area) && !includedAreas[area]) {
        excludedAreas.push(area);
      }
    }

    return {
      excludedAreas,
      ...rest
    };
  }
}

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  items: List<BatchMigrationItem>;

  areas: List<string>;

  isReady: boolean;

  initialFormData: Result | undefined;
  onSubmit: (formData: Result) => void;
  onNavigateBack?: () => void;
}

export const BatchMigrationPreferencesStepView: React.FunctionComponent<Props> = (props) => {
  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title={"Preferences"}
      subTitle={"Configure how this migration will be executed."}
      submitHidden={true}
    >
      <Formik<FormData>
        initialValues={
          props.initialFormData
            ? FormData.fromResult(props.areas, props.initialFormData)
            : FormData.empty(props.areas)
        }
        enableReinitialize={true}
        onSubmit={(data, actions) => {
          props.onSubmit(FormData.toResult(data));
          actions.setSubmitting(false);
        }}
        render={(formProps) => (
          <Form>
            {props.items.size === 1 && (
              <Block>
                <SingleAccountHint
                  adding={false}
                  sourceCloudService={props.sourceCloudService}
                  destinationCloudService={props.destinationCloudService}
                  item={props.items.first()}
                />
              </Block>
            )}
            <Block>
              <Panel>
                <TitlePanelRow>Apps to include</TitlePanelRow>
                <PanelRow>
                  <FormLayout noBottomMargin={true}>
                    {
                      props.areas.map((area) => (
                        <CheckboxField<FormData>
                          key={area}
                          label={area}
                          /* Dirty hack to cheat type checking */
                          name={("includedAreas." + area) as keyof FormData}
                          disabled={formProps.isSubmitting}
                        />
                      ))
                    }
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <Block>
              <Panel>
                <TitlePanelRow>Other Preferences</TitlePanelRow>
                <PanelRow
                  helpArticle={{
                    id: undefined,
                    content: (
                      <DrawerContent>
                        <p>
                          Selecting this box will create a new migration sub-folder to separate the copied
                          content from the content that may already exist in the destination account (recommended
                          for archiving).
                        </p>
                      </DrawerContent>
                    ),
                    title: "Place migrated items in a sub-folder"
                  }}
                >
                  <FormLayout noBottomMargin={true}>
                    <CheckboxField<FormData>
                      label="Place migrated items in a sub-folder"
                      name="createSubfolder"
                      disabled={formProps.isSubmitting}
                    />
                  </FormLayout>
                </PanelRow>
              </Panel>
            </Block>
            <WizardNavigationContainer>
              <WizardNavigation
                onNavigateBack={props.onNavigateBack}
                submitType={"submit"}
                submitDisabled={!props.isReady}
                submitDisabledMessage={
                  props.isReady
                    ? undefined
                    : "Please make sure that all accounts added for migration are successfully connected and scanned"
                }
              />
            </WizardNavigationContainer>
          </Form>
        )}
      />
    </Step>
  );
};
