import * as React from "react";
import { Migration } from "../../../../types/models/migration";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import {
  briefDateTime,
  friendlyCount,
  friendlyDateTime,
  preciseDurationBetween,
} from "../../../../utils/formatting";
import { Button } from "../../../widgets/button";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Link } from "react-router-dom";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { List, Range } from "immutable";
import {
  AddMigrationNoteControllerProps,
  MigrationNoteControllerProps,
  MigrationNotesView
} from "./migrationNotesView";
import { DrawerBlock } from "../../../containers/drawerBlock";
import { Characters } from "../../../characters";
import { None, Option, Some } from "../../../../utils/monads/option";
import { Modal, ModalController, useModal } from "../../../layouts/modal";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { TextArea } from "../../../widgets/textArea";
import { Row } from "../../../widgets/row";
import { RefreshingDataSource, RefreshingDataSourceTimer } from "../../../utils/refreshingDataSource";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { Grid } from "../../../widgets/grid";
import { ListPanelRow } from "../../../containers/rows/listPanelRow";
import { MigrationStatus } from "../../../../types/models/migrationStatus";
import { Checkbox } from "../../../widgets/checkbox";
import { TextBox } from "../../../widgets/textBox";
import * as moment from "moment";
import { styled } from "../../../../app/theme";
import { MigrationIssue } from "../../../../types/models/migrationIssue";
import { Paragraph } from "../../../widgets/paragraph";
import { SemiBold } from "../../../widgets/semiBold";
import { BulletPoints } from "../../../widgets/bulletPoints";
import { GrandOption } from "../../../widgets/option";
import { GraphQL } from "../../../../services/graphql/generated";
import { useRoutes } from "../../../../app/routes/useRoutes";

enum AuthorizationAction {
  Keep = "Keep",
  SubmitForSettlement = "SubmitForSettlement",
  Void = "Void"
}

export interface AbortMigrationToolViewProps {
  authorizationStatusStatus: OperationStatus<GraphQL.AuthorizationStatus | undefined>;
  abortMigrationStatus: OperationStatus<any>;

  onAbort: (authorizationAction: AuthorizationAction) => Promise<any>;
}

export interface AbortMigrationToolControllerProps {
  render: (viewProps: AbortMigrationToolViewProps) => React.ReactElement;
}

export type AbortMigrationToolControllerType = React.ComponentType<AbortMigrationToolControllerProps>;

interface Props {
  migrationId: string;
  dataSource: RefreshingDataSource<any, Migration.AdminSidebarData>;

  takeForSupervisionStatus: OperationStatus<any>;
  takeForSupervision: () => void;

  releaseFromSupervisionStatus: OperationStatus<any>;
  releaseFromSupervision: (note: Option<string>) => Promise<any>;

  abortMigrationToolController: AbortMigrationToolControllerType;

  configureAutoResumingTimerStatus: OperationStatus<any>;
  configureAutoResumingTimer: (enabled: boolean, timestamp: Date | undefined) => Promise<any>;

  migrationNoteController: React.ComponentType<MigrationNoteControllerProps>;
  addMigrationNoteController: React.ComponentType<AddMigrationNoteControllerProps>;
}

export const MigrationInsightsView: React.FunctionComponent<Props> = (props) => {
  const lastResult = props.dataSource.status.someResult();

  return (
    <>
      <DrawerBlock>
        <Panel>
          <OperationStatusIndicator
            subject={"migration"}
            status={props.dataSource.status}
            indicators={StatusIndicators.PanelRow()}
          />
          <PanelRow>
            <SimpleToolbar
              centerVertically={true}
              rightSide={<RefreshingDataSourceTimer dataSource={props.dataSource}/>}
            >
              <span>
                Migration: {props.migrationId}
                {lastResult && (" (" + MigrationStatus.adminFriendly(lastResult.status, lastResult.iteration) + ")")}
              </span>
            </SimpleToolbar>
          </PanelRow>
          {lastResult && (
            <>
              <LinksRow migration={lastResult}/>
              <ToolsRow
                migration={lastResult}
                abortMigrationToolController={props.abortMigrationToolController}
              />
            </>
          )}
        </Panel>
      </DrawerBlock>

      {lastResult && (
        <>
          <DrawerBlock>
            <Panel>
              <SupervisionRow
                migration={lastResult}

                takeForSupervisionStatus={props.takeForSupervisionStatus}
                takeForSupervision={props.takeForSupervision}

                releaseFromSupervisionStatus={props.releaseFromSupervisionStatus}
                releaseFromSupervision={props.releaseFromSupervision}
              />
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <ManualReviewRow migration={lastResult}/>
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <TimingRow migration={lastResult}/>
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <MigrationIssuesRow
                migration={lastResult}
                migrationIssues={lastResult.issues}
              />
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <JobIssuesRow
                migration={lastResult}
                jobIssues={lastResult.activeJobIssues}
              />
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <AutoResumingRow
                migration={lastResult}
                status={props.configureAutoResumingTimerStatus}
                onSubmit={props.configureAutoResumingTimer}
              />
            </Panel>
          </DrawerBlock>

          <DrawerBlock>
            <Panel>
              <MigrationNotesView
                migrationId={lastResult.id}
                migrationNotes={lastResult.notes}
                migrationNoteController={props.migrationNoteController}
                addMigrationNoteController={props.addMigrationNoteController}
              />
            </Panel>
          </DrawerBlock>
        </>
      )}
    </>
  );
};

interface LinksRowProps {
  migration: Migration.AdminSidebarData;
}

const LinksRow: React.FunctionComponent<LinksRowProps> = (props) => {
  const routes = useRoutes();

  return (
    <ListPanelRow size={"smaller"}>
      <ul>
        <li><a href={props.migration.workflowUrl} target={"_blank"}>Bamboo workflow</a> {Characters.NewTab}</li>
        <li><Link to={routes.jobs.jobPath(props.migration.jobId)}>Migration job</Link></li>
        <li><Link to={routes.users.userDetailsPath(props.migration.userId)}>User profile</Link></li>
        <li>
          <a href={props.migration.mouseFlowRecordingsUrl} target={"_blank"}>
            MouseFlow recordings for this migration
          </a>
          {" "}
          {Characters.NewTab}
        </li>
        <li>
          <a
            href={routes.quickSightDashboards.migrationPerformanceHref(props.migration.id)}
            target={"_blank"}
          >
            QuickSight dashboard
          </a>
          {" "}
          {Characters.NewTab}
        </li>
      </ul>
    </ListPanelRow>
  );
};

interface ToolsRowProps {
  migration: Migration.AdminSidebarData;
  abortMigrationToolController: AbortMigrationToolControllerType;
}

const ToolsRow: React.FunctionComponent<ToolsRowProps> = (props) => {
  const abortMigrationModal = useModal();

  return (
    <>
      <ModalController
        modal={abortMigrationModal}
        background={"alert"}
        title={"Abort Migration"}
        render={(close) =>
          React.createElement(props.abortMigrationToolController, {
            render: (viewProps) => (
              <AbortMigrationForm
                migration={props.migration}
                authorizationStatusStatus={viewProps.authorizationStatusStatus}
                abortMigrationStatus={viewProps.abortMigrationStatus}

                onAbort={(authorizationAction) => viewProps.onAbort(authorizationAction).then(close)}
                onCancel={close}
              />
            )
          })
        }
      />
      <PanelRow>
        <SimpleToolbar>
          <Button size={"small"} color={"red"} onClick={abortMigrationModal.open}>
            Abort Migration
          </Button>
        </SimpleToolbar>
      </PanelRow>
    </>
  );
};

interface AbortMigrationFormProps {
  migration: Migration;
  authorizationStatusStatus: OperationStatus<GraphQL.AuthorizationStatus | undefined>;
  abortMigrationStatus: OperationStatus<any>;

  onAbort: (authorizationAction: AuthorizationAction) => Promise<any>;
  onCancel: () => void;
}

export const AbortMigrationForm: React.FunctionComponent<AbortMigrationFormProps> = (props) => {
  const routes = useRoutes();
  const [authorizationAction, setAuthorizationAction] = React.useState<AuthorizationAction>(AuthorizationAction.Keep);

  return (
    <>
      <DrawerBlock>
        <Panel>
          <OperationStatusIndicator
            progressMessage={"Aborting migration..."}
            failureMessage={"Failed to abort migration"}
            status={props.abortMigrationStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          <PanelRow>
            <Row>
              <Paragraph>
                Clicking the <SemiBold>Abort Migration</SemiBold> button below will result in the following:
              </Paragraph>
              <BulletPoints>
                <li>Migration and current sync-up (if any) will be changed to "Aborted" status.</li>
                <li>
                  The <Link to={routes.jobs.jobPath(props.migration.jobId)}>job</Link> responsible for running the
                  migration will be aborted.
                </li>
                <li>
                  The <a href={props.migration.workflowUrl} target={"_blank"}>Bamboo workflow</a> will be stopped.
                </li>
                <li>All Bamboo issues (expired authentication, throttling, storage issues, etc) will be cleared.</li>
                <li>
                  All facts that are currently in "Collecting" status will be changed to "Collected" status (so
                  that the progress wheels don't spin anymore).
                </li>
              </BulletPoints>
              <Paragraph>
                The following will NOT be done:
              </Paragraph>
              <BulletPoints>
                <li>Users will not receive any email notifications.</li>
                <li>
                  The migration report will not be generated - instead, the migration report panel will display an
                  error.
                </li>
              </BulletPoints>
              <Paragraph>
                Note: the skipped actions listed above can be performed by transitioning
                the <Link to={routes.jobs.jobPath(props.migration.jobId)}>job</Link> to "Completed" status manually.
              </Paragraph>
              <Paragraph>
                This page will auto-refresh upon completion to ensure that all updates are visible.
              </Paragraph>
            </Row>
          </PanelRow>
        </Panel>
      </DrawerBlock>
      <DrawerBlock>
        <Panel>
          <OperationStatusIndicator
            progressMessage={"Checking transaction status..."}
            failureMessage={"Failed to check transaction status."}
            status={props.authorizationStatusStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          {props.authorizationStatusStatus.isSuccess() && (
            <>
              <PanelRow size={"smaller"}>
                <Row>
                  Transaction status: {props.authorizationStatusStatus.result?.transactionStatus || "N/A"}
                </Row>
                <Row>
                  {
                    props.authorizationStatusStatus.result?.isPending
                      ? "Choose what you want to do with pending authorization for this migration:"
                      : "This transaction cannot be submitted for settlement or voided."
                  }
                </Row>
              </PanelRow>
            </>
          )}
        </Panel>
      </DrawerBlock>
      {props.authorizationStatusStatus.isSuccess() && props.authorizationStatusStatus.result?.isPending && (
        <>
          <GrandOption
            name={"action"}
            value={AuthorizationAction.Keep}
            checked={authorizationAction === AuthorizationAction.Keep}
            onChange={() => setAuthorizationAction(AuthorizationAction.Keep)}
            description={
              <>
                This transaction will be automatically submitted for settlement after 5 days (or sooner if the
                transaction is old or authorization is about to expire).
              </>
            }
          >
            Keep authorization pending
          </GrandOption>
          <GrandOption
            name={"action"}
            value={AuthorizationAction.SubmitForSettlement}
            checked={authorizationAction === AuthorizationAction.SubmitForSettlement}
            onChange={() => setAuthorizationAction(AuthorizationAction.SubmitForSettlement)}
            description={
              <>
                If you change your mind later, this transaction will have to be refunded with all usual penalties
                applied.
              </>
            }
          >
            Submit transaction for settlement
          </GrandOption>
          <GrandOption
            name={"action"}
            value={AuthorizationAction.Void}
            checked={authorizationAction === AuthorizationAction.Void}
            onChange={() => setAuthorizationAction(AuthorizationAction.Void)}
            description={
              <>
                This operation cannot be reversed, but no penalties will be applied, unlike in the case of
                refunding a previously settled transaction.
              </>
            }
          >
            Void transaction
          </GrandOption>
        </>
      )}
      <DrawerBlock>
        <Button
          color={"red"}
          onClick={() => props.onAbort(authorizationAction)}
          disabled={props.authorizationStatusStatus.isWorking() || props.abortMigrationStatus.isWorking()}
        >
          Abort Migration
        </Button>
      </DrawerBlock>
    </>
  );
};

interface TimingRowProps {
  migration: Migration.AdminSidebarData;
}

const TimingRow: React.FunctionComponent<TimingRowProps> = (props) => {
  const timing = props.migration.timing;

  function delayFactorToCellStatus(delayLevel: Migration.Timing.DelayLevel): WidgetStatus | undefined {
    switch (delayLevel) {
      case Migration.Timing.DelayLevel.Major: return WidgetStatus.Error;
      case Migration.Timing.DelayLevel.Minor: return WidgetStatus.Warning;
      default: return undefined;
    }
  }

  if (timing instanceof Migration.ScheduledTiming) {
    return null;
  } else {
    return (
      <PanelRow size={"smaller"} status={delayFactorToCellStatus(timing.delayLevel)}>
        {(timing.isDelayed ? "Delayed" : "Running on time") + " (" + timing.delayFactor.toFixed(2) + "x)"}
      </PanelRow>
    );
  }
};

interface SupervisionRowProps {
  migration: Migration.AdminSidebarData;

  takeForSupervisionStatus: OperationStatus<any>;
  takeForSupervision: () => void;

  releaseFromSupervisionStatus: OperationStatus<any>;
  releaseFromSupervision: (note: Option<string>) => Promise<any>;
}

const SupervisionRow: React.FunctionComponent<SupervisionRowProps> = (props) => {
  const releaseModal = useModal();

  return (
    <>
      {releaseModal.isOpen && (
        <Modal
          title={"Release From Supervision"}
          onClose={releaseModal.close}
        >
          <DrawerBlock>
            <ReleaseFromSupervisionForm
              status={props.releaseFromSupervisionStatus}
              onSubmit={(note) => props.releaseFromSupervision(note).then(releaseModal.close)}
              onCancel={releaseModal.close}
            />
          </DrawerBlock>
        </Modal>
      )}
      <OperationStatusIndicator
        progressMessage={"Taking for supervision..."}
        status={props.takeForSupervisionStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow size={"smaller"} status={props.migration.supervisedBy ? WidgetStatus.Info : undefined}>
        {
          props.migration.supervisedBy
            ? (
              <>
                <Row>
                  Currently being supervised by <strong>{props.migration.supervisedBy}</strong>
                </Row>
                <Row>
                  <Button size={"small"} color={"white"} onClick={releaseModal.open}>
                    Release from supervision
                  </Button>
                </Row>
              </>
            )
            : (
              <>
                <Row>Not being supervised by anyone</Row>
                <Row>
                  <Button size={"small"} color={"white"} onClick={props.takeForSupervision}>
                    Take for supervision
                  </Button>
                </Row>
              </>
            )
        }
      </PanelRow>
    </>
  );
};

interface ReleaseFromSupervisionFormProps {
  status: OperationStatus<any>;
  onSubmit: (note: Option<string>) => void;
  onCancel: () => void;
}

export const ReleaseFromSupervisionForm: React.FunctionComponent<ReleaseFromSupervisionFormProps> = (props) => {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const [note, setNote] = React.useState("");

  const trimmedNote = note.trim();

  React.useEffect(
    () => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    },
    []
  );

  return (
    <Panel>
      <OperationStatusIndicator
        progressMessage={"Releasing from supervision..."}
        status={props.status}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow>
        <Row>
          <TextArea
            ref={textAreaRef}
            value={note}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value)}
            disabled={props.status.isWorking()}
            placeholder={"(Optional) Leave a note to let others know what happened!"}
          />
        </Row>
        <Row>
          <SimpleToolbar>
            <Button
              size={"small"}
              onClick={() => props.onSubmit(trimmedNote ? Some(trimmedNote) : None())}
              disabled={props.status.isWorking()}
            >
              Release
            </Button>
            <Button size={"small"} color={"white"} onClick={props.onCancel}>
              Cancel
            </Button>
          </SimpleToolbar>
        </Row>
      </PanelRow>
    </Panel>
  );
};

interface ManualReviewsProps {
  migration: Migration;
}

const ManualReviewRow: React.FunctionComponent<ManualReviewsProps> = (props) => {
  if (props.migration.itemsForManualReview) {
    const hasBeenReviewed = props.migration.totalManualReviews > 1 && (
      "Note: this migration has already been reviewed " +
      friendlyCount(props.migration.totalManualReviews - 1, "time")
    );

    const items = props.migration.itemsForManualReview;
    const total = items.reduce((sum, item) => sum + item.count, 0);

    return (
      <>
        <PanelRow size={"smaller"} status={WidgetStatus.Error}>
          <Row>{friendlyCount(total, "item")} require manual review!</Row>
          {hasBeenReviewed && <Row>{hasBeenReviewed}</Row>}
          <Row>
            <a href={props.migration.workflowUrl} target={"_blank"}>Review items in Bamboo Console</a>
            {" "}
            {Characters.NewTab}
          </Row>
        </PanelRow>
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Phase</Grid.Column>
              <Grid.Column>Item Type</Grid.Column>
              <Grid.Column># of Items</Grid.Column>
            </Grid.Header>
            <Grid.Body>
              {
                props.migration.itemsForManualReview
                  .sortBy((item) => item.itemType)
                  .map((item) => (
                    <Grid.Row key={item.itemType}>
                      <Grid.Cell>{item.phase}</Grid.Cell>
                      <Grid.Cell>{item.itemType}</Grid.Cell>
                      <Grid.Cell>{item.count}</Grid.Cell>
                    </Grid.Row>
                  ))
              }
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      </>
    );
  } else {
    const hasBeenReviewed = props.migration.totalManualReviews > 0 && (
      "Note: this migration has been reviewed " +
      friendlyCount(props.migration.totalManualReviews, "time")
    );

    return (
      <PanelRow size={"smaller"}>
        <Row>Manual review is not required</Row>
        {hasBeenReviewed && <Row>{hasBeenReviewed}</Row>}
        <Row>
          <a href={props.migration.workflowUrl} target={"_blank"}>Check workflow status in Bamboo Console</a>
          {" "}
          {Characters.NewTab}
        </Row>
      </PanelRow>
    );
  }
};

interface MigrationIssuesRowProps {
  migration: Migration;
  migrationIssues: List<MigrationIssue>;
}

const MigrationIssuesRow: React.FunctionComponent<MigrationIssuesRowProps> = (props) => {
  const [currentMigrationIssue, setCurrentMigrationIssue] = React.useState<MigrationIssue>();

  const checkStatus = (
    <>
      <a href={props.migration.workflowUrl} target={"_blank"}>
        Check workflow status in Bamboo Console
      </a>
      {" "}
      {Characters.NewTab}
    </>
  );

  if (props.migrationIssues.isEmpty()) {
    return (
      <PanelRow size={"smaller"}>
        <>
          <Row>No Bamboo issues</Row>
          <Row>{checkStatus}</Row>
        </>
      </PanelRow>
    );
  } else {
    return (
      <>
        {currentMigrationIssue && (
          <Modal onClose={() => setCurrentMigrationIssue(undefined)} wide={true}>
            <pre>{currentMigrationIssue.errorJson}</pre>
          </Modal>
        )}
        <PanelRow
          size={"smaller"}
          status={
            props.migrationIssues.isEmpty()
              ? undefined
              : !!props.migrationIssues.find((issue) => issue.isBlocking)
              ? WidgetStatus.Error
              : WidgetStatus.Warning
          }
        >
          <Row>{friendlyCount(props.migrationIssues.size, "Bamboo issue")}</Row>
          <Row>{checkStatus}</Row>
        </PanelRow>
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Reported At</Grid.Column>
              <Grid.Column>Endpoint</Grid.Column>
              <Grid.Column>Class</Grid.Column>
              <Grid.Column>Severity</Grid.Column>
              <Grid.Column>Required Action</Grid.Column>
              <Grid.Column>Message</Grid.Column>
              <Grid.Column>JSON</Grid.Column>
              <Grid.Column>Duration</Grid.Column>
              <Grid.Column>Will Be Auto-Resolved At</Grid.Column>
            </Grid.Header>
            <Grid.Body>
              {
                props.migrationIssues
                  .sortBy((migrationIssue) => migrationIssue.createdAt)
                  .map((migrationIssue) => (
                    <Grid.Row key={migrationIssue.id}>
                      <Grid.Cell>{friendlyDateTime(migrationIssue.createdAt)}</Grid.Cell>
                      <Grid.Cell>{migrationIssue.endpointAlias}</Grid.Cell>
                      <Grid.Cell>{migrationIssue.classNameOnly}</Grid.Cell>
                      <Grid.Cell status={migrationIssue.isBlocking ? WidgetStatus.Error : undefined}>
                        {migrationIssue.isBlocking ? "Blocking" : "Non-blocking"}
                      </Grid.Cell>
                      <Grid.Cell>{migrationIssue.requiredAction}</Grid.Cell>
                      <Grid.Cell>{migrationIssue.message}</Grid.Cell>
                      <Grid.ActionsCell>
                        {
                          migrationIssue.errorJson
                            ? (
                              <Button
                                color={"white"}
                                size={"small"}
                                onClick={() => setCurrentMigrationIssue(migrationIssue)}
                              >
                                View
                              </Button>
                            )
                            : "--"
                        }
                      </Grid.ActionsCell>
                      <Grid.Cell>{migrationIssue.friendlyDuration || "--"}</Grid.Cell>
                      <Grid.Cell>
                        {
                          migrationIssue.willBeAutoResolvedAt !== undefined
                            ? friendlyDateTime(migrationIssue.willBeAutoResolvedAt) + " (in " +
                            preciseDurationBetween(migrationIssue.willBeAutoResolvedAt, new Date(), false) + ")"
                            : "--"
                        }
                      </Grid.Cell>
                    </Grid.Row>
                  ))
              }
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      </>
    );
  }
};

interface JobIssuesRowProps {
  migration: Migration;
  jobIssues: List<Migration.JobIssueSummary>;
}

const JobIssuesRow: React.FunctionComponent<JobIssuesRowProps> = (props) => {
  const routes = useRoutes();
  const checkStatus = <Link to={routes.jobs.jobPath(props.migration.jobId)}>Check migration job status</Link>;

  if (props.jobIssues.isEmpty()) {
    return (
      <PanelRow size={"smaller"}>
        <>
          <Row>No migration job issues</Row>
          <Row>{checkStatus}</Row>
        </>
      </PanelRow>
    );
  } else {
    return (
      <>
        <PanelRow
          size={"smaller"}
          status={props.jobIssues.isEmpty() ? undefined : WidgetStatus.Error}
        >
          <Row>{friendlyCount(props.jobIssues.size, "migration job issue")}</Row>
          <Row>{checkStatus}</Row>
        </PanelRow>
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Issue ID</Grid.Column>
              <Grid.Column>Reported at</Grid.Column>
              <Grid.Column>Summary</Grid.Column>
            </Grid.Header>
            <Grid.Body>
              {
                props.jobIssues
                  .sortBy((jobIssue) => jobIssue.createdAt)
                  .map((jobIssue) => (
                    <Grid.Row key={jobIssue.issueId}>
                      <Grid.Cell>{jobIssue.issueId}</Grid.Cell>
                      <Grid.Cell>{friendlyDateTime(jobIssue.createdAt)}</Grid.Cell>
                      <Grid.Cell>{jobIssue.summary}</Grid.Cell>
                    </Grid.Row>
                  ))
              }
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      </>
    );
  }
};

interface AutoResumingRowProps {
  migration: Migration;
  status: OperationStatus<any>;
  onSubmit: (enabled: boolean, timestamp: Date | undefined) => Promise<any>;
}

const AutoResumingRow: React.FunctionComponent<AutoResumingRowProps> = (props) => {
  const modal = useModal();

  return (
    <PanelRow
      status={props.migration.autoResumeAt && props.migration.isAutoResumingEnabled ? WidgetStatus.Info : undefined}
    >
      <ModalController
        modal={modal}
        title={"Auto-Resuming Configuration"}
        render={(close) => (
          <AutoResumingTimerForm
            migration={props.migration}
            status={props.status}
            onSubmit={(enabled, timestamp) => props.onSubmit(enabled, timestamp).then(close)}
            onCancel={close}
          />
        )}
      />
      <Row>
        {
          props.migration.autoResumeAt
            ? (
              props.migration.isAutoResumingEnabled
                ? (
                  <>
                    Will auto-resume: {briefDateTime(props.migration.autoResumeAt, true)}{" "}
                    (in {preciseDurationBetween(props.migration.autoResumeAt, new Date(), false)})
                  </>
                )
                : (
                  <>
                    Auto-resuming timer is set for: {briefDateTime(props.migration.autoResumeAt, true)}{" "}
                    <strong>(but auto-resuming is DISABLED)</strong>
                  </>
                )
            )
            : "No auto-resuming timer is set"
        }
      </Row>
      <Row>
        <Button size={"small"} color={"white"} onClick={modal.open}>Configure Auto-Resuming</Button>
      </Row>
    </PanelRow>
  );
};

export const ButtonsRow = styled.div`
  & ~ &, ${Row} ~ & {
    margin-top: .8rem;
  }
`;

interface AutoResumingTimerFormProps {
  migration: Migration;
  status: OperationStatus<any>;
  onSubmit: (enabled: boolean, timestamp: Date | undefined) => Promise<any>;
  onCancel: () => void;
}

const AutoResumingTimerForm: React.FunctionComponent<AutoResumingTimerFormProps> = (props) => {
  const format = "MM/DD/YYYY HH:mm";

  const [enabled, setEnabled] = React.useState(props.migration.isAutoResumingEnabled);
  const [timestamp, setTimestamp] = React.useState(() =>
    props.migration.autoResumeAt ? moment(props.migration.autoResumeAt).format(format) : ""
  );
  const [error, setError] = React.useState(false);

  const textBoxRef = React.createRef<HTMLInputElement>();
  React.useEffect(
    () => {
      if (textBoxRef.current) {
        textBoxRef.current.focus();
      }
    },
    []
  );
  const trimmedTimestamp = timestamp.trim();
  const parsedTimestamp = moment(trimmedTimestamp, format);

  function setMoment(f: (m: moment.Moment) => moment.Moment): void {
    setTimestamp((value) => {
      const current = moment(value.trim(), format);
      return f(current.isValid() ? current : moment()).format(format);
    });
    setError(false);
  }

  function setDate(date: moment.Moment): void {
    return setMoment((m) => m.set("year", date.year()).set("month", date.month()).set("date", date.date()));
  }

  function setHour(hour: number): void {
    return setMoment((m) => m.startOf("day").set("hour", hour));
  }

  return (
    <Panel>
      <OperationStatusIndicator
        progressMessage={"Configuring auto-resuming timer..."}
        status={props.status}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow>
        <Checkbox
          checked={enabled}
          onChange={() => setEnabled((value) => !value)}
        >
          Enable auto-resuming
        </Checkbox>
      </PanelRow>
      <PanelRow>
        <Row>
          Timestamp (mm/dd/yyyy hh:mm, leave blank to clear):
        </Row>
        <Row>
          <TextBox
            ref={textBoxRef}
            value={timestamp}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTimestamp(event.target.value);
              setError(false);
            }}
            error={error}
            disabled={props.status.isWorking()}
          />
        </Row>
        <Row>
          {
            trimmedTimestamp.length === 0
              ? "Not set"
              : parsedTimestamp.isValid()
              ? "In " + preciseDurationBetween(parsedTimestamp.toDate(), new Date(), false)
              : "(editing)"
          }
        </Row>
        <ButtonsRow>
          <SimpleToolbar>
            <Button size={"small"} color={"white"} onClick={() => setMoment(() => moment())}>
              Now
            </Button>
            <Button size={"small"} color={"white"} onClick={() => setMoment((m) => m.add(30, "minutes"))}>
              +30 min
            </Button>
            <Button size={"small"} color={"white"} onClick={() => setMoment((m) => m.add(1, "hours"))}>
              +1 hr
            </Button>
            <Button size={"small"} color={"white"} onClick={() => setMoment((m) => m.add(-30, "minutes"))}>
              -30 min
            </Button>
            <Button size={"small"} color={"white"} onClick={() => setMoment((m) => m.add(-1, "hours"))}>
              -1 hr
            </Button>
            <Button size={"small"} color={"red"} onClick={() => setTimestamp("")}>
              Clear
            </Button>
          </SimpleToolbar>
        </ButtonsRow>
        <ButtonsRow>
          <SimpleToolbar>
            <Button size={"small"} color={"white"} onClick={() => setDate(moment().startOf("day"))}>
              Today
            </Button>
            <Button
              size={"small"}
              color={"white"}
              onClick={() => setDate(moment().startOf("day").add(1, "days"))}
            >
              Tomorrow
            </Button>
          </SimpleToolbar>
        </ButtonsRow>
        <ButtonsRow>
          {
            Range(0, 24 / 6).map((row) => (
              <Row key={row}>
                <SimpleToolbar>
                  {
                    Range(0, 6).map((index) => {
                      const hour = row * 6 + index;
                      return (
                        <Button key={index} size={"small"} color={"white"} onClick={() => setHour(hour)}>
                          {(hour < 10 ? "0" : "") + hour}:00
                        </Button>
                      );
                    })
                  }
                </SimpleToolbar>
              </Row>
            ))
          }
        </ButtonsRow>
      </PanelRow>
      <PanelRow>
        <SimpleToolbar>
          <Button
            size={"small"}
            onClick={() => {
              if (!trimmedTimestamp.length) {
                props.onSubmit(enabled, undefined);
              } else {
                if (parsedTimestamp.isValid()) {
                  props.onSubmit(enabled, parsedTimestamp.toDate());
                } else {
                  setError(true);
                }
              }
            }}
            disabled={props.status.isWorking()}
          >
            Submit
          </Button>
          <Button size={"small"} color={"white"} onClick={props.onCancel}>
            Cancel
          </Button>
        </SimpleToolbar>
      </PanelRow>
    </Panel>
  );
};
