import * as React from "react";
import { LookupControllerType } from "../../lookupModal";
import { Theme } from "../../../../types/models/theme";
import { ItemDetailsControllerType, LookupField } from "../../../widgets/lookupField";
import { useBrowser } from "../../../../utils/useBrowser";
import { ThemeColorSample } from "./themeColorSample";

export type ThemeLookupControllerType = LookupControllerType<number, Theme>;

export type OrganizationThemeLookupControllerTypeFactory =
  (organizationId: number | undefined) => ThemeLookupControllerType;

export type ThemeDetailsControllerType = ItemDetailsControllerType<number, Theme>;

export type ThemeViewPathFactory = (id: number) => string;

export type OrganizationThemeViewPathFactory = (organizationId: number | undefined) => ThemeViewPathFactory;

interface Props<V> {
  name: keyof V;

  lookupController: ThemeLookupControllerType;
  detailsController: ThemeDetailsControllerType;
  viewPathFactory: ThemeViewPathFactory;

  label?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export function ThemeLookupField<V>(props: Props<V>): React.ReactElement {
  const browser = useBrowser();

  return (
    <LookupField<V, number, Theme>
      label={props.label || "Theme"}
      name={props.name}
      subject={"theme"}
      gridColumns={[
        {
          title: "Name",
          render: (theme) => theme.name
        },
        {
          title: "Primary Color",
          render: (theme) => <><ThemeColorSample color={theme.primary}/>{theme.primary}</>
        },
      ]}

      lookupController={props.lookupController}
      itemDetailsController={props.detailsController}
      renderItem={(theme) => <><ThemeColorSample color={theme.primary}/>{theme.name} ({theme.primary})</>}

      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled}

      onView={(themeId) => browser.openNewTab(props.viewPathFactory(themeId))}
    />
  );
}
