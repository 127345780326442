import * as React from "react";
import { Constants } from "../../app/constants";
import { CarouselDot, CarouselDots } from "../screens/migrationSetup/carouselStepView/carouselDots";
import { Button } from "../widgets/button";
import { LinkButton } from "../widgets/linkButton";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { StyleguideTable } from "./styleguideTable";
import { InfoButton } from "../widgets/infoButton";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";

const remoteContent = "http://www.unboundedcloud.net/lorem-ipsum";

const embeddedContent = `
  <p>
  ${Constants.Product.name} will copy emails of all kinds and sizes
  (excluding Spam and Trash folders), with and without attachments.
  Conversations and labeling will be retained, as well as read/unread
  status, importance indicators and starring.
  </p>
`;

export const Buttons: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Colors and Sizes</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <thead>
        <tr>
          <th/>
          <th>Dry</th>
          <th>Small</th>
          <th>Narrow</th>
          <th>Normal</th>
          <th>Wide</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Blue</th>
          <td><Button color="blue" size="dry">Title</Button></td>
          <td><Button color="blue" size="small">Title</Button></td>
          <td><Button color="blue" size="narrow">Title</Button></td>
          <td><Button color="blue" size="normal">Title</Button></td>
          <td><Button color="blue" size="wide">Title</Button></td>
        </tr>
        <tr>
          <th>White</th>
          <td><Button color="white" size="dry">Title</Button></td>
          <td><Button color="white" size="small">Title</Button></td>
          <td><Button color="white" size="narrow">Title</Button></td>
          <td><Button color="white" size="normal">Title</Button></td>
          <td><Button color="white" size="wide">Title</Button></td>
        </tr>
        <tr>
          <th>Red</th>
          <td><Button color="red" size="dry">Title</Button></td>
          <td><Button color="red" size="small">Title</Button></td>
          <td><Button color="red" size="narrow">Title</Button></td>
          <td><Button color="red" size="normal">Title</Button></td>
          <td><Button color="red" size="wide">Title</Button></td>
        </tr>
        <tr>
          <th>Gray</th>
          <td><Button color="gray" size="dry">Title</Button></td>
          <td><Button color="gray" size="small">Title</Button></td>
          <td><Button color="gray" size="narrow">Title</Button></td>
          <td><Button color="gray" size="normal">Title</Button></td>
          <td><Button color="gray" size="wide">Title</Button></td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

    <StyleguideHeader>States</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <thead>
        <tr>
          <th/>
          <th>Normal</th>
          <th>Disabled</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Blue</th>
          <td><Button color="blue">Title</Button></td>
          <td><Button color="blue" disabled={true}>Title</Button></td>
        </tr>
        <tr>
          <th>White</th>
          <td><Button color="white">Title</Button></td>
          <td><Button color="white" disabled={true}>Title</Button></td>
        </tr>
        <tr>
          <th>Red</th>
          <td><Button color="red">Title</Button></td>
          <td><Button color="red" disabled={true}>Title</Button></td>
        </tr>
        <tr>
          <th>Gray</th>
          <td><Button color="gray">Title</Button></td>
          <td><Button color="gray" disabled={true}>Title</Button></td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

    <StyleguideHeader>Info Button</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <tbody>
        <tr>
          <th>With remote content</th>
          <td>
            <InfoButton
              helpArticle={
                PreparedHelpArticle.fromExternal({
                  content: remoteContent,
                  title: undefined,
                  summary: "Click to download content"
                })
              }
            />
          </td>
        </tr>
        <tr>
          <th>With embedded content</th>
          <td>
            <InfoButton
              helpArticle={
                PreparedHelpArticle.fromExternal({
                  content: embeddedContent,
                  title: undefined,
                  summary: "Click to view content"
                })
              }
            />
          </td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

    <StyleguideHeader>Link Button</StyleguideHeader>
    <StyleguideBlock>
      <LinkButton>A button that looks and acts like a link</LinkButton>
    </StyleguideBlock>

    <StyleguideHeader>Carousel Dots</StyleguideHeader>
    <StyleguideBlock>
      <StyleguideTable>
        <tbody>
        <tr>
          <td>
            <CarouselDots
              count={6}
              selected={0}
              onSelect={() => {
                // Do nothing
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <CarouselDots
              count={6}
              selected={2}
              onSelect={() => {
                // Do nothing
              }}
            />
          </td>
        </tr>
        </tbody>
      </StyleguideTable>
    </StyleguideBlock>

  </React.Fragment>
);
