import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Link } from "react-router-dom";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";
import { ModalController, useModal } from "../../layouts/modal";
import { titleize } from "../../../utils/titleize";
import { FormConfig } from "./formConfig";
import { HeaderBlock } from "../../widgets/headerBlock";

interface Props<Id, Item, FormData> {
  breadcrumbs?: React.ReactNode[];
  title: string;
  subject: string;
  readOnly: boolean;
  renderForm: (config: FormConfig<Item, FormData>) => React.ReactElement;

  getItemTitle: (item: Item) => React.ReactNode;

  id: Id;
  homePath: string;
  cancelPath: string;

  loadStatus: OperationStatus<Item>;
  updateStatus: OperationStatus<Item>;
  deleteStatus: OperationStatus<boolean>;

  onSubmit: (formData: FormData, continueEditing: boolean) => Promise<Item>;
  onClone: ((item: Item) => void) | undefined;
  onDelete: (() => void) | undefined;
}

export function EditItemPageView<Id, Item, FormData>(props: Props<Id, Item, FormData>): React.ReactElement {
  const modal = useModal();

  const reference = props.loadStatus.mapLastResult((result) => props.getItemTitle(result)) || "...";

  return (
    <>
      <HeaderBlock
        breadcrumbs={
          (props.breadcrumbs || []).concat([
            <Link key={0} to={props.homePath}>{props.title}</Link>,
            reference,
            "Update"
          ])
        }
        title={"Update " + titleize(props.subject) + " \"" + reference + "\""}
      />
      <ModalController
        modal={modal}
        title={titleize("Delete " + props.subject) + " \"" + reference + "\"?"}
        render={() => (
          <Panel>
            <OperationStatusIndicator
              progressMessage={"Deleting..."}
              status={props.deleteStatus}
              indicators={StatusIndicators.PanelRow()}
            />
            <PanelRow>
              Click the button below to delete {props.subject} "{reference}".
            </PanelRow>
            <PanelRow>
              <Button color="red" disabled={props.deleteStatus.isWorking()} onClick={props.onDelete}>Delete</Button>
            </PanelRow>
          </Panel>
        )}
      />
      <OperationStatusIndicator
        subject={props.subject}
        status={props.loadStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.loadStatus.isSuccess() && props.renderForm({
        readOnly: props.readOnly,

        item: props.loadStatus.result,
        isNew: false,
        cancelPath: props.cancelPath,
        submitStatus: props.updateStatus,

        onSubmit: props.onSubmit,
        onClone: props.onClone && (
          () => props.loadStatus.isSuccess() && props.onClone && props.onClone(props.loadStatus.result)
        ),
        onDelete: props.onDelete && modal.open
      })}
    </>
  );
}
