export enum BlueprintType {
  Migration = "Migration"
}

export interface BlueprintParams<T extends BlueprintType> {
  readonly type: T;
}

export interface MigrationBlueprintParams extends BlueprintParams<BlueprintType.Migration> {
  readonly sourceCloudServiceId: string;
  readonly destinationCloudServiceId: string;
}

export function migrationBlueprintParams(
  sourceCloudServiceId: string,
  destinationCloudServiceId: string): MigrationBlueprintParams {
  return {
    type: BlueprintType.Migration,
    sourceCloudServiceId,
    destinationCloudServiceId
  };
}

export type AnyBlueprintParams = MigrationBlueprintParams;

interface BlueprintParamsMap {
  [id: string]: string;
}

export namespace BlueprintParams {
  export function id(params: AnyBlueprintParams): string {
    const paramsMap = params as unknown as BlueprintParamsMap;
    let result = "";
    for (const key in paramsMap) {
      if (paramsMap.hasOwnProperty(key)) {
        result += (result.length === 0 ? "?" : "&") + key + "=" + paramsMap[key];
      }
    }
    return "Blueprint" + result;
  }

  export function migrationBlueprintId(sourceCloudServiceId: string, destinationCloudServiceId: string): string {
    return id(migrationBlueprintParams(sourceCloudServiceId, destinationCloudServiceId));
  }
}
