import * as React from "react";
import { WorkflowContextLike } from "./workflowContextLike";
import { Workflow } from "./workflow";
import { WorkItemStates } from "./workItemStates";
import { Path } from "./path";
import { LocalStorage } from "../app/localStorage";
import { InitializationError } from "./initializationError";
import { WorkflowState } from "./workflowState";
import { WorkItemContexts } from "./workItemContexts";
import { Listener } from "./listener";
import { ControlledWorkflowState, ControlledWorkflowStateDefs } from "./controlledWorkflowState";
import { StateMachine } from "./stateMachine";
import { usePrevious } from "../utils/usePrevious";
import { useIsFirstRender } from "../utils/useIsFirstRender";

interface PropListenerProps<T> {
  value: T;
  onChange: (value: T) => void;
}

function PropListener<T>(props: PropListenerProps<T>) {
  const previous = usePrevious(props.value);
  React.useEffect(
    () => {
      if (previous !== undefined) {
        props.onChange(props.value);
      }
    },
    [props.value]);
  return null;
}

interface ChildrenConfig<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  >
  extends ControlledWorkflowStateDefs.CalculatedChildrenConfig<WorkflowContext, WorkflowFeedback, WorkflowResult> {
  working: boolean;
}

interface WorkflowControllerProps<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  > {
  workflow: Workflow<WorkflowContext, WorkflowFeedback, WorkflowResult>;
  context: WorkflowContext;
  path: string;
  localStorageKey?: string;

  renderInitialization?: React.ReactElement;
  renderError?: (error: WorkflowController.ErrorSummary) => React.ReactElement | null;

  children: (config: ChildrenConfig<WorkflowContext, WorkflowFeedback, WorkflowResult>) => React.ReactElement;

  onPathChange?: (path: string, replacePreviousPath: boolean) => void;
  onComplete?: (result: WorkflowResult) => void;
}

export function WorkflowController<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  WorkflowResult
  >(
  props: WorkflowControllerProps<WorkflowContext, WorkflowFeedback, WorkflowResult>
): React.ReactElement | null {
  const [listener] = React.useState(new Listener());

  function buildInitialState(
    schedule: StateMachine.ScheduleFunction<
      ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
      ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >
  ): ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    return new ControlledWorkflowState(
      {
        workflow: props.workflow,
        listener,
        complete: (path, result) => schedule(ControlledWorkflowStateDefs.CompleteAction(path, result)),
        apply: (path, result) => schedule(ControlledWorkflowStateDefs.ApplyAction(path, result)),
        clear: (path) => schedule(ControlledWorkflowStateDefs.ClearAction(path)),
        processFeedback: (path, feedback) => schedule(
          ControlledWorkflowStateDefs.ProcessFeedbackAction(path, feedback)
        ),
        navigateTo: (path) => schedule(ControlledWorkflowStateDefs.NavigateAction(path))
      },
      new WorkflowState({
        workflowContext: props.context,
        workItemStates: createWorkItemStates(props.localStorageKey),
        path: Path.parse(props.path),
        correctedPreviousPath: false,
        workItemContexts: new WorkItemContexts(listener)
      })
    );
  }

  function handleInit(
    state: ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    schedule: StateMachine.ScheduleFunction<
      ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
      ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >
  ) {
    const subscription = listener.watch().subscribe((events) =>
      schedule(ControlledWorkflowStateDefs.ApplyUpdatesAction(events))
    );
    return () => subscription.unsubscribe();
  }

  function renderError(
    error: StateMachine.ErrorSummary<
      ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
      ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >
  ): React.ReactElement | null {
    if (props.renderError) {
      if (error.error instanceof InitializationError) {
        return props.renderError({
          error: error.error,
          onTryAgain: error.tryAgain,
          onCancel: error.cancel,
          onCleanUp: error.cleanUp
            ? () => error.cleanUp && error.cleanUp((badState) => badState.cleanUp())
            : undefined
        });
      } else {
        return props.renderError({
          error: error.error,
        });
      }
    } else {
      console.error("An error occurred (but no renderer configured)", error);
      return null;
    }
  }

  function fireSideEffect(
    state: ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
    sideEffect: ControlledWorkflowStateDefs.SideEffect<WorkflowResult>
  ): void {
    switch (sideEffect.type) {
      case ControlledWorkflowStateDefs.SideEffectType.CompleteWorkflow:
        if (props.onComplete) {
          props.onComplete(sideEffect.result);
        }
        return;
    }
  }

  const lastPathChange = React.useRef<string>();
  const isFirstRender = useIsFirstRender();

  function reviewState(
    state: ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>
  ): ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult> {
    saveWorkItemStates(props.localStorageKey, state.workflowState.workItemStates);

    const newPath = state.workflowState.path.toString();
    if (props.onPathChange && newPath !== props.path) {
      // console.log("###", "Redirecting "  + props.path + " -> " + newPath);
      lastPathChange.current = newPath;
      if (isFirstRender) {
        // Avoid the following warning from React Route:
        // You should call navigate() in a React.useEffect(), not when your component is first rendered.
        setTimeout(
          () => props.onPathChange && props.onPathChange(newPath, state.workflowState.correctedPreviousPath),
          0
        );
      } else {
        props.onPathChange(newPath, state.workflowState.correctedPreviousPath);
      }
    }

    state.sideEffects.forEach((sideEffect) => fireSideEffect(state, sideEffect));

    return state.clearSideEffects();
  }

  function handlePathChange(
    schedule: StateMachine.ScheduleFunction<
      ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
      ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >,
    path: string
  ): void {
    if (path !== lastPathChange.current) {
      // console.log("@@@", "Manually changed path to " + path);
      schedule(ControlledWorkflowStateDefs.NavigateAction(path));
      lastPathChange.current = undefined;
    } else {
      // console.log("@@@", "Redirected to " + path);
    }
  }

  return (
    <StateMachine<
        ControlledWorkflowState<WorkflowContext, WorkflowFeedback, WorkflowResult>,
        ControlledWorkflowStateDefs.Action<WorkflowContext, WorkflowFeedback, WorkflowResult>
      >
      initialState={buildInitialState}
      onInit={handleInit}
      initializationAction={() => ControlledWorkflowStateDefs.InitializeAction(props.path)}
      fireAction={(state, action) => state.fireAction(action)}
      reviewState={reviewState}
      enqueue={ControlledWorkflowStateDefs.enqueue}
      purgeQueue={(queue) => queue.filter(ControlledWorkflowStateDefs.isCriticalAction)}
    >
      {
        ({ state, schedule, currentAction, error }) => {
          function renderBody() {
            if (error) {
              return renderError(error);
            } else if (state.childrenConfig) {
              return props.children({ ...state.childrenConfig, working: !!currentAction });
            } else {
              return props.renderInitialization || null;
            }
          }
          return (
            <>
              <PropListener value={props.path} onChange={(path) => handlePathChange(schedule, path)}/>
              <PropListener
                value={props.context}
                onChange={(context) => schedule(ControlledWorkflowStateDefs.ChangeWorkflowContextAction(context))}
              />
              {renderBody()}
            </>
          );
        }
      }
    </StateMachine>
  );
}

export namespace WorkflowController {
  export interface ErrorSummary {
    error: any;
    onTryAgain?: () => void;
    onCleanUp?: () => void;
    onCancel?: () => void;
  }
}

function createWorkItemStates(localStorageKey: string | undefined): WorkItemStates {
  if (localStorageKey) {
    const json = LocalStorage.loadObject(localStorageKey);
    if (json) {
      return WorkItemStates.fromJSON(json);
    }
  }
  return new WorkItemStates();
}

function saveWorkItemStates(localStorageKey: string | undefined, workflowState: WorkItemStates): void {
  if (localStorageKey) {
    LocalStorage.saveObject(localStorageKey, workflowState.toJSON());
  }
}
