import { GraphQL } from "../../services/graphql/generated";

export interface SponsorshipConfig {
  programName: string;
  maxSponsoredAmount: number;
}

export namespace SponsorshipConfig {
  export function parse(data: GraphQL.SponsorshipConfigFragment): SponsorshipConfig {
    return data;
  }
}
