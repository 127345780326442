import { RelationshipProps, DataFlow, RelationshipStyling } from "../relationship";
import { GraphQL } from "../../services/graphql/generated";
import { MigrationStream } from "../migrationStream";
import { BlueprintContext } from "../blueprintContext";
import { State } from "../state";
import { AreaComp } from "../components/areaComp";

export class MigrationFlow<Output> extends DataFlow<MigrationFlow.Props, MigrationStream> {
  public stateWhenUnblocked(
    context: BlueprintContext,
    componentState: State<MigrationStream>
  ): State<MigrationStream> {
    return (componentState as State<AreaComp.Output>).map((output) => {
      if (output.enabled) {
        return {
          itemTypes: output.itemTypes,
          totalItems: output.totalItems,
          totalBytes: output.totalBytes,
          maxPathLength: output.maxPathLength
        };
      } else {
        return MigrationStream.empty;
      }
    });
  }

  public styling(context: BlueprintContext): RelationshipStyling {
    const disabled: boolean = this.componentOpt()
      .map((component) =>
        (component.state(context) as State<AreaComp.Output>).output.exists((output) => !output.enabled)
      )
      .getOrElse(() => false);
    return {
      color: disabled ? "#b4cecb" : "#00A086",
      width: 5,
      dashes: [5, 10]
    };
  }
}

export namespace MigrationFlow {
  export type Props = RelationshipProps;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.MigrationFlowPropsFragment): Props {
      return RelationshipProps.fromGraphQL(props);
    }
  }
}
