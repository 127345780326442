import { GraphQL } from "../../graphql/generated";
import { List } from "immutable";
import { WatcherAction } from "../watcherAction";
import { WatcherPlugin } from "../watcherPlugin";
import { ApolloClient } from "apollo-client";
import { WatchedItems } from "../watchedItems";

export namespace WatchedMigrationIssueLists {
  type WatchMigrationIssueListsActionType = "WatchMigrationIssueLists";

  type Subscription = GraphQL.MigrationIssueListEventsSubscription;
  type SubscriptionVariables = GraphQL.MigrationIssueListEventsSubscriptionVariables;

  export interface WatchMigrationIssueListsAction extends WatcherAction<WatchMigrationIssueListsActionType> {
    migrationIds: List<string>;
    replace: boolean;
    shouldRefresh: boolean;
  }

  export const WatchMigrationIssueListsAction =
    (migrationIds: Iterable<string>, replace: boolean, shouldRefresh: boolean): WatchMigrationIssueListsAction => ({
      type: "WatchMigrationIssueLists",
      migrationIds: List(migrationIds),
      replace,
      shouldRefresh
    });

  export const Plugin: WatcherPlugin<ApolloClient<object>, string, number> = {
    itemType: "migration issue list",

    refreshTimeout: 30 * 1000,

    key: (item) => item,

    reduce: (action, existingItems) => {
      if (action.type === "WatchMigrationIssueLists") {
        return WatchedItems.Diff.build(
          action.migrationIds,
          action.shouldRefresh,
          action.replace ? existingItems.toSet().subtract(action.migrationIds) : undefined
        );
      }
    },

    updateSubscriptions: (context, diff, dispatchRenews) => {
      if (!diff.added.isEmpty()) {
        context
          .subscribe<Subscription, SubscriptionVariables>({
            query: GraphQL.MigrationIssueListEventsDocument,
            variables: { migrationIds: diff.addedItems.toArray() }
          })
          .subscribe(({ data }) => {
            if (data) {
              dispatchRenews(
                data.subscribeForMigrationIssueListEvents.migrationIssues
                  .map((migrationIssues) => migrationIssues.migrationId)
              );
            }
          });
      }

      // Note: Un-subscribing is not supported by Apollo Client, so not doing anything for diff.removed
    },

    refreshItems: (context, migrationIds, dispatchRenews) => {
      if (!migrationIds.isEmpty()) {
        return context
          .query<GraphQL.FreshMigrationIssueListsQuery, GraphQL.FreshMigrationIssueListsQueryVariables>({
            query: GraphQL.FreshMigrationIssueListsDocument,
            variables: { migrationIds: migrationIds.toArray() },
            fetchPolicy: "network-only"
          })
          .then(({ data }) =>
            dispatchRenews(data.migrations.map(({ issues  }) => issues.migrationId))
          );
      }
    }
  };
}
