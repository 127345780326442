import { RoutesContext } from "./routesContext";
import { CRUDRoutes } from "./crudRoutes";
import { OffboardingProject } from "../../types/models/offboardingProject";

export class AutomatedOffboardingRoutes extends CRUDRoutes<string, OffboardingProject> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public importPath(id: string): string {
    return this.context.resolvePath(this.root + "/" + AutomatedOffboardingRoutes.importRoot + "/" + id);
  }

  public importParams(): AutomatedOffboardingRoutes.ImportParams {
    const id = this.context.routeParams[AutomatedOffboardingRoutes.Params.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public importHistoryPath(id: string): string {
    return this.context.resolvePath(this.root + "/" + AutomatedOffboardingRoutes.importHistoryRoot + "/" + id);
  }

  public importHistoryParams(): AutomatedOffboardingRoutes.ImportHistoryParams {
    const id = this.context.routeParams[AutomatedOffboardingRoutes.Params.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public deleteEntriesPath(id: string): string {
    return this.context.resolvePath(this.root + "/" + AutomatedOffboardingRoutes.deleteEntriesRoot + "/" + id);
  }

  protected parseId(s: string): string {
    return s;
  }
}

export namespace AutomatedOffboardingRoutes {
  export namespace Params {
    export const id: string = "id";
  }

  export const importRoot = "import";
  export const importMask = importRoot + "/:" + Params.id;

  export const importHistoryRoot = "import-history";
  export const importHistoryMask = importHistoryRoot + "/:" + Params.id;

  export const deleteEntriesRoot = "remove-entries";
  export const deleteEntriesMask = deleteEntriesRoot + "/:" + Params.id;

  export interface ImportParams {
    id: string;
  }

  export interface ImportHistoryParams {
    id: string;
  }
}
