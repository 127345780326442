import * as React from "react";
import { ItemDetailsControllerProps } from "../../views/widgets/lookupField";
import { PricingModel } from "../../types/models/pricingModel";
import { useGetPricingModelQuery } from "../../queries/pricingModels/useGetPricingModelQuery";

export const PricingModelDetailsController: React.FunctionComponent<
  ItemDetailsControllerProps<number, PricingModel>
  > =
  (props) => {
    const [status] = useGetPricingModelQuery(props.id);
    return props.render({ status });
  };

export function usePricingModelDetailsController() {
  return React.useCallback(
    (controllerProps: ItemDetailsControllerProps<number, PricingModel>) =>
      <PricingModelDetailsController {...controllerProps}/>,
    []
  );
}
