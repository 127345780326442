import * as React from "react";
import * as yup from "yup";
import { CloudService } from "../../types/models/cloudService";
import { useNavigate } from "react-router";
import { Connections } from "../../types/models/connections";
import { Blueprint } from "../../blueprints/blueprint";
import { List } from "immutable";
import { Connection } from "../../types/models/connection";
import {
  BatchCheckoutStepView
} from "../../views/screens/migrationSetup/batch/batchCheckoutStepView/batchCheckoutStepView";
import { PendingBatchMigrationItemJson } from "./batchMigrationPlanStep";
import { BatchMigrationPreferences } from "./batchMigrationPreferences";
import { useBatchCheckoutFlow } from "./useBatchCheckoutFlow";
import { LocalStorage } from "../../app/localStorage";
import { useElevated } from "../../utils/useAppState";
import { Order } from "../../types/models/order";
import { AdminSidebar } from "../../views/layouts/sidebar";
import { BatchCheckoutOrderView } from "../../views/blocks/batchOrderDetailsPanelView";
import { useRoutes } from "../../app/routes/useRoutes";
import { BatchOrder } from "../../types/models/batchOrder";

export interface BatchMigrationItem {
  sourceConnection: Connection;
  destinationConnection: Connection;
}

export namespace BatchMigrationItem {
  export function build(row: PendingBatchMigrationItemJson, connections: Connections): BatchMigrationItem | undefined {
    const sourceConnection = row.sourceConnectionId
      ? connections.get(row.sourceConnectionId)
      : undefined;
    const destinationConnection = row.destinationConnectionId
      ? connections.get(row.destinationConnectionId)
      : undefined;
    return sourceConnection && destinationConnection && { sourceConnection, destinationConnection };
  }
}

export interface CheckoutStepResult {
}

export namespace CheckoutStepResult {
  export const Schema = yup.object<CheckoutStepResult>({});
}

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: Blueprint;

  items: List<BatchMigrationItem>;
  preferences: BatchMigrationPreferences;

  onNavigateBack?: () => void;
}

export const BatchCheckoutStep: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const elevated = useElevated();
  const routes = useRoutes();

  const checkoutFlow = useBatchCheckoutFlow({
    ...props,
    onSuccess: (batch) => {
      if (!elevated) {
        LocalStorage.removeObject(LocalStorage.BatchMigrationSetupWorkflowKey);
      }
      navigate(routes.batches.path(batch.jobId));
    }
  });

  return (
    <>
      <AdminSidebar
        tools={[
          {
            title: "Order",
            render: () => (
              <BatchCheckoutOrderView
                status={checkoutFlow.loadingStatus.map((result) => ({
                  ...result.batchOrder,
                  ...BatchOrder.HasRevenueData.empty
                }))}
                isWorking={checkoutFlow.submitDisabled}
                onExternalPayment={(amount, note) =>
                  checkoutFlow.onExternalPayment({ amount, note })
                }
              />
            )
          },
        ]}
      />
      <BatchCheckoutStepView
        {...checkoutFlow}
        sourceCloudService={props.sourceCloudService}
        destinationCloudService={props.destinationCloudService}
        items={props.items}
        onNavigateBack={props.onNavigateBack}
      />
    </>
  );
};
