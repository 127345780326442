import { Theme } from "../../types/models/theme";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchThemesQuery(
  organizationId: number | undefined,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchThemesQueryVariables, List<Theme>> {
  return useManagedQuery({
    query: GraphQL.useSearchThemesQuery,
    deps: organizationId !== undefined && term !== undefined ? { organizationId, term } : undefined,
    prepare: (deps) => ({ organizationId: deps.organizationId, term: deps.term }),
    extract: (data: GraphQL.SearchThemesQuery) => nullToUndefined(data.searchThemes),
    complete: (themes) => List(themes).filter((theme) => theme.id >= 0).map(Theme.parse),
    fetchPolicy: "cache-and-network"
  });
}
