import { GraphQL } from "../../services/graphql/generated";

export interface OffboardingProjectFormData {
  readonly name: string;
  readonly notes: string | undefined;

  readonly emailDeliveryAccountConfig: OffboardingProjectFormData.EmailDeliveryAccountConfig | undefined;

  readonly programId: string | undefined;

  readonly cutoffAt: Date;
  readonly scheduleId: string;

  readonly from: string | undefined;
  readonly subject1: string;
  readonly body1: string;
  readonly subject2: string | undefined;
  readonly body2: string | undefined;
  readonly subject3: string | undefined;
  readonly body3: string | undefined;
  readonly subject4: string | undefined;
  readonly body4: string | undefined;
}

export namespace OffboardingProjectFormData {
  export interface EmailDeliveryAccountConfig {
    readonly oneTimeAuthCode: string;
    readonly cloudServiceId: string;
  }

  export function toGraphQL(data: OffboardingProjectFormData): GraphQL.OffboardingProjectFormData {
    return {
      name: data.name,
      notes: data.notes,

      emailDeliveryAccountConfig: data.emailDeliveryAccountConfig,

      programId: data.programId,

      cutoffAt: data.cutoffAt.toISOString(),
      scheduleId: data.scheduleId,

      from: data.from,
      subject1: data.subject1,
      body1: data.body1,
      subject2: data.subject2,
      body2: data.body2,
      subject3: data.subject3,
      body3: data.body3,
      subject4: data.subject4,
      body4: data.body4
    };
  }
}
