import { styled, css } from "../../app/theme";
import { FormField } from "./formField";

export const FormRow = styled.div`
  display: flex;
  align-items: center;

  > ${FormField}:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

interface FormLayoutProps {
  noBottomMargin?: boolean;
}

export const FormLayout = styled.div<FormLayoutProps>`
  > ${FormField}, > ${FormRow} {
    margin-bottom: 1rem;
  }
  
  ${(props) => props.noBottomMargin && css`
    > ${FormField}:last-of-type, > ${FormRow}:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export const FormButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  > * {
    margin: 0 1rem 0 0;  
  } 
`;
