import * as React from "react";
import { styled } from "../../../app/theme";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { Characters } from "../../characters";
import { Connection } from "../../../types/models/connection";
import { useCloudServices } from "../../../app/configuration";

const Layout = styled.div<{ icon: any }>`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: .85rem;
  background-position-y: .15rem;
  padding-left: 1.3rem;
`;

interface Props extends StyledComponentsProps {
  cloudServiceId: string;
  addFromToArrow?: boolean;
}

export const CloudServiceIconWithText: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  return (
    <Layout icon={cloudServices.getOrFail(props.cloudServiceId).icon} className={props.className}>
      {props.children}
      {props.addFromToArrow && <>&nbsp;{Characters.FromToArrow}</>}
    </Layout>
  );
};

export const CloudServiceNameAndIcon: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  return (
    <CloudServiceIconWithText {...props}>
      {cloudServices.getOrFail(props.cloudServiceId).name}
    </CloudServiceIconWithText>
  );
};

interface ConnectionIdAndIcon extends StyledComponentsProps {
  connection: Connection;
  addFromToArrow?: boolean;
}

export const ConnectionIdAndIcon: React.FunctionComponent<ConnectionIdAndIcon> = (props) => (
  <CloudServiceIconWithText
    cloudServiceId={props.connection.cloudServiceId}
    className={props.className}
    addFromToArrow={props.addFromToArrow}
  >
    {props.connection.descriptionOrId()}
  </CloudServiceIconWithText>
);
