import * as React from "react";
import { css, styled } from "../../app/theme";
import { Map, Set } from "immutable";
import { LinkButton } from "../../views/widgets/linkButton";
import { FilterState } from "../../state/settings/blueprintFilters";

interface FilteringOptionsProps {
  allowCollapsing?: boolean;
  allowPinning?: boolean;
  options: Map<string, number>;
  filters: Map<string, FilterState>;
  onChange: (filters: Map<string, FilterState>) => void;
}

const NestedLayout = styled.div`
  margin-left: 2rem;
`;

const OptionContainer = styled.div`
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
`;

const LabelText = styled.div<{ pinned: boolean; dimmed: boolean; }>`
  margin-left: .2rem;
  ${(props) => props.pinned && css`font-weight: ${props.theme.font.bold};`}
  ${(props) => props.dimmed && css`text-decoration: line-through; color: ${props.theme.colors.lightGray};`}
`;

const Buttons = styled.div`
  padding-bottom: .25rem;
  
  > *:not(:last-child) {
    margin-right: .3rem;
  }
`;

export class FilteringOptions extends React.Component<FilteringOptionsProps> {
  constructor(props: FilteringOptionsProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleSelectNoneClick = this.handleSelectNoneClick.bind(this);
  }

  public render() {
    const checkboxes = this.props.options
      .keySeq()
      .sort()
      .map((option) => {
        const filter = this.props.filters.get(option, FilterState.Show);
        return (
          <OptionContainer key={option}>
            <Label>
              <Checkbox
                type="checkbox"
                checked={filter === FilterState.Show || filter === FilterState.Pin}
                value={option}
                onClick={this.handleChange}
                onChange={() => {
                  // We don't want React to complain about controlled/uncontrolled checkboxes
                }}
              />
              <LabelText
                pinned={filter === FilterState.Pin}
                dimmed={!!this.props.allowCollapsing && filter === FilterState.Hide}
              >
                {(option || "(no name)") + " (" + this.props.options.get(option, 0) + ")"}
              </LabelText>
            </Label>
          </OptionContainer>
        );
      });

    return (
      <React.Fragment>
        <Buttons>
          <LinkButton onClick={this.handleSelectAllClick}>Reset</LinkButton>
          <LinkButton onClick={this.handleSelectNoneClick}>Clear</LinkButton>
        </Buttons>
        {checkboxes}
      </React.Fragment>
    );
  }

  protected handleChange(e: React.MouseEvent<HTMLInputElement>) {
    function update(
      allowCollapsing: boolean | undefined,
      allowPinning: boolean | undefined,
      filters: Map<string, FilterState>,
      option: string
    ): Map<string, FilterState> {
      if (allowCollapsing && e.ctrlKey) {
        return filters.set(option, FilterState.Hide);
      } else if (allowPinning && e.altKey) {
        return filters.set(option, FilterState.Pin);
      } else if (e.currentTarget.checked) {
        return filters.remove(option);
      } else {
        return filters.set(option, allowCollapsing ? FilterState.Collapse : FilterState.Hide);
      }
    }

    this.props.onChange(
      update(this.props.allowCollapsing, this.props.allowPinning, this.props.filters, e.currentTarget.value)
    );
  }

  protected handleSelectAllClick() {
    this.props.onChange(Map());
  }

  protected handleSelectNoneClick() {
    this.props.onChange(Map(this.props.options.keySeq().map((option) => [option, FilterState.Hide])));
  }
}
