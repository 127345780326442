import * as React from "react";
import * as yup from "yup";
import { makeWizardStep, WizardStepDef } from "./makeWizardStep";
import { Wizard } from "./wizard";
import { WorkflowContextLike } from "../workflowContextLike";
import { ConfigurableWizardDestinationFactory, WizardStepFactory } from "./wizardDestination";
import { WorkItemDef } from "../makeWorkItem";
import { RenderHooks } from "../workItem";

export interface WizardDef<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps
  > {
  startFrom: (props: WizardProps) => ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult>;
}

export class WizardImpl<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps
  >
  extends Wizard<WizardContext, WizardFeedback, WizardResult> {

  constructor(
    protected readonly wizardDef: WizardDef<WizardContext, WizardFeedback, WizardResult, WizardProps>,
    protected readonly props: WizardProps
  ) {
    super();
  }

  public firstDestinationFactory(): ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> {
    return this.wizardDef.startFrom(this.props);
  }
}

type WizardFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps
  > =
  (
    props: WizardProps
  ) => Wizard<WizardContext, WizardFeedback, WizardResult>;

export function makeWizard<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps = {}
  >(
  wizardDef: WizardDef<WizardContext, WizardFeedback, WizardResult, WizardProps>
): WizardFactory<WizardContext, WizardFeedback, WizardResult, WizardProps> {
  return (
    props: WizardProps
  ) => {
    return new WizardImpl(wizardDef, props);
  };
}

class ConfiguredWizardFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps = {}
  > {
  public makeWizard(
    wizardDef: WizardDef<WizardContext, WizardFeedback, WizardResult, WizardProps>
  ): WizardFactory<WizardContext, WizardFeedback, WizardResult, WizardProps> {
    return makeWizard(wizardDef);
  }

  public makeStep<
    Props,
    PropsContext,
    Result,
    ResultContext extends PropsContext = PropsContext
    >(
    wizardStepDef: WorkItemDef<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext> &
      WizardStepDef<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>
  ): WizardStepFactory<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext> {
    return makeWizardStep(wizardStepDef);
  }

  public makeInterstitialStep<T>(
    interstitialStepDef: ConfiguredWizardFactory.InterstitialStepDef<WizardContext, WizardFeedback, WizardResult, T>
  ): WizardStepFactory<WizardContext, WizardFeedback, WizardResult, T, {}, {}, {}> {
    return makeWizardStep({
      propsKey: () => "",
      propsContext: () => ({}),
      resultContext: () => ({}),
      resultSchema: yup.mixed(),
      hideFromNavigation: () => true,
      skipWhen: ({ previousStepCompletedOrUpdated }) => !previousStepCompletedOrUpdated,
      render: (params) => interstitialStepDef.renderInterstitial({
        ...params,
        hooks: {
          ...params.hooks,
          onSkip: () => params.hooks.onComplete({})
        }
      }),
      ...interstitialStepDef
    });
  }
}

namespace ConfiguredWizardFactory {
  interface InterstitialRenderHooks<WorkflowFeedback, Result> extends RenderHooks<WorkflowFeedback, Result> {
    onSkip: () => void;
  }

  export type InterstitialStepDef<
    WizardContext extends WorkflowContextLike,
    WizardFeedback,
    WizardResult,
    T
    > =
    {
      renderInterstitial: (
        params: WorkItemDef.RenderParams<WizardContext, T, {}, {}, {}, InterstitialRenderHooks<WizardFeedback, T>>
      ) => React.ReactElement
    } &
    Omit<WizardStepDef<WizardContext, WizardFeedback, WizardResult, T, {}, {}, {}>, "skipWhen">;
}

export function wizardFactory<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  WizardProps = {}
  >(): ConfiguredWizardFactory<WizardContext, WizardFeedback, WizardResult, WizardProps> {
  return new ConfiguredWizardFactory();
}
