import * as React from "react";
import { styled, keyframes, css } from "../../app/theme";
import { Spacer } from "../utils/spacer";
import { HamburgerClose } from "../glyphs/hamburgerClose";
import { VaultMeLogo } from "../glyphs/vaultMeLogo";
// import { useHistory } from "react-router";
import { usePrevious } from "../../utils/usePrevious";
import { useLocation } from "react-router";

export type DrawerBackground = "facebook" | "info" | "alert";

export interface DrawerSettings {
  id?: string;
  deps?: any[];
  content: React.ReactNode;
  url?: string;
  background?: DrawerBackground;
  title?: React.ReactNode;
  wide?: boolean;
}

export interface UpdatableDrawerSettings extends DrawerSettings {
  id: string;
  deps: any[];
}

export namespace DrawerSettings {
  export type CompatibleValue = DrawerSettings | React.ReactNode;

  export function isDrawerSettings(value: CompatibleValue): value is DrawerSettings {
    return value !==  null && value !== undefined && typeof value === "object" && value.hasOwnProperty("content");
  }

  export function prepare(value: CompatibleValue): DrawerSettings {
    return isDrawerSettings(value) ? value : { content: value };
  }
}

const fadeOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.8; }
`;

const Overlay = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(5,37,53,0.4);
  animation: ${fadeOut} 0.25s forwards;
  z-index: ${(props) => props.theme.layers.modal};
`;

const ClickableHeaderLayout = styled.a.attrs({})`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  z-index: ${(props) => props.theme.layers.modal + 1};
  background: ${(props) => props.theme.colors.offWhite};
  flex-shrink: 0;
`;

const StyledVaultMeLogo = styled(VaultMeLogo)`
  display: none;
  align-self: center;
  flex-shrink: 0;
  height: 2.1rem;
  width: 9.25rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    display: block;
  }
`;

const CloseButton = styled.button`
  height: 5rem;
  width: 5rem;
  padding: 1.5rem;
  z-index: 2;
  flex-shrink: 0;
  border: none;
  background: none;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 1rem;
    width: 3.4rem;
    height: 3.4rem;
  }

  @media(hover: hover) {
    &:hover {
      transition: 0.5s;
      filter: brightness(110%);
      cursor: pointer;
      text-decoration: none;
      stroke-width: 0.4px;
      stroke: ${(props) => props.theme.colors.primary};
      transform: rotate(90deg);
    }
  }
`;

const StyledHamburgerClose = styled(HamburgerClose)`
  width: 100%;
  height: 100%;
`;

interface HeaderProps {
  onClick: () => void;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => (
  <ClickableHeaderLayout onClick={props.onClick}>
    <StyledVaultMeLogo colorSchema="black-and-blue"/>
    <Spacer/>
    <CloseButton>
      <StyledHamburgerClose color="primary"/>
    </CloseButton>
  </ClickableHeaderLayout>
);

const Title = styled.h1<{ red?: boolean }>`
  font-weight: 600;
  font-size: 2rem;
  color: ${(props) => props.red ? props.theme.colors.red : props.theme.colors.titleColor};
  background: ${(props) => props.theme.colors.offWhite};
  position: sticky;
  top: 0;
  margin: 2rem 0 -1rem;
  padding: 1rem 0;
  z-index: 1; // Somehow this prevents overflowing by SVG images
`;

const openDrawer = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
`;

const closeDrawer = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
`;

const openMixin = (open: boolean) => {
  if (open) {
    return css`
      box-shadow: -0.5rem 0 1rem 0 rgba(5,37,53,0.25);
      animation: ${openDrawer} 0.25s forwards;
    `;
  } else {
    return css`
      transform: translateX(100%);
      animation: ${closeDrawer} 0.25s forwards;
    `;
  }
};

{/* tslint:disable max-line-length */}
const backgroundMixin = (background?: DrawerBackground) => {
  switch (background) {
    case "info":
      return css`
        background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDEwIDIxIj48ZyBmaWxsPSJub25lIj48ZyBmaWxsPSIjREZFRUY4Ij48cGF0aCBkPSJNMTAgMS40TDEwIDEwQzkuNyAxMC40IDkuMyAxMC43IDguOSAxMS4xIDguNCAxMS41IDguMSAxMiA3LjkgMTIuNSA3LjggMTMgNy43IDEzLjYgNy43IDE0LjNMNC42IDE0LjNDNC42IDEzLjUgNC43IDEyLjggNC44IDEyLjIgNSAxMS43IDUuMiAxMS4yIDUuNSAxMC43IDUuOCAxMC4zIDYuMiA5LjggNi44IDkuMiA3LjMgOC43IDcuNyA4LjMgNy45IDggOC4yIDcuNyA4LjQgNy40IDguNiA3IDguOCA2LjcgOC45IDYuMiA4LjkgNS44IDguOSA1IDguNyA0LjMgOC4zIDMuOSA3LjkgMy41IDcuMyAzLjMgNi41IDMuMyA1LjkgMy4zIDUuMyAzLjUgNC44IDMuOSA0LjMgNC4zIDQuMSA0LjggNCA1LjZMMC44IDUuNkMwLjggNC41IDEgMy42IDEuNSAyLjggMiAyLjEgMi43IDEuNSAzLjYgMS4xIDQuNSAwLjcgNS40IDAuNSA2LjUgMC41IDcuNyAwLjUgOC43IDAuNyA5LjYgMS4xIDkuNyAxLjIgOS45IDEuMyAxMCAxLjRaTTYuMiAxNi42QzYuOCAxNi42IDcuMiAxNi44IDcuNiAxNy4xIDcuOSAxNy41IDguMSAxNy45IDguMSAxOC40IDguMSAxOC45IDcuOSAxOS4zIDcuNiAxOS43IDcuMiAyMCA2LjggMjAuMiA2LjIgMjAuMiA1LjcgMjAuMiA1LjIgMjAgNC45IDE5LjcgNC41IDE5LjMgNC40IDE4LjkgNC40IDE4LjQgNC40IDE3LjkgNC41IDE3LjUgNC45IDE3LjEgNS4yIDE2LjggNS43IDE2LjYgNi4yIDE2LjZaIi8+PC9nPjwvZz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 6.5rem;
        background-size: 25%;
      `;

    case "alert":
      return css`
        background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzIiBoZWlnaHQ9IjE5IiB2aWV3Qm94PSIwIDAgMyAxOSI+PHBhdGggZD0iTTAuNSAxMy4yTDAuMSAwIDMuOCAwIDMuNSAxMy4yIDAuNSAxMy4yWk0yIDE1LjVDMi42IDE1LjUgMy4xIDE1LjcgMy41IDE2IDMuOCAxNi40IDQgMTYuOCA0IDE3LjMgNCAxNy44IDMuOCAxOC4yIDMuNSAxOC41IDMuMSAxOC44IDIuNiAxOSAyIDE5IDEuNCAxOSAwLjkgMTguOCAwLjUgMTguNSAwLjIgMTguMiAwIDE3LjggMCAxNy4zIDAgMTYuOCAwLjIgMTYuNCAwLjUgMTYgMC45IDE1LjcgMS40IDE1LjUgMiAxNS41WiIgZmlsbD0iI0Y2REFFMSIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 8rem;
        background-size: 8.5%;
      `;
  }
};
{/* tslint:enable max-line-length */}

const Body = styled.div<{ width: number, background?: DrawerBackground }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => props.width}rem;
  overflow-x: hidden;
  background: ${(props) => props.theme.colors.offWhite};
  z-index: ${(props) => props.theme.layers.modal};

  ${(props) => openMixin(!!props)}
  ${(props) => props && backgroundMixin(props.background)}

  ${(props) => props.theme.responsive.respondTo(props.width + "rem")} {
    width: auto;
    left: 0;
  }
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    background-image: none;
  }
`;

function width(wide?: boolean): number {
  return wide ? 74 : 38;
}

const paddingMixin = (background?: DrawerBackground) => {
  switch (background) {
    case "info":
      return css`
        padding: 2rem 25% 4rem 2rem;
      `;

    case "alert":
      return css`
        padding-right: 3.5rem;
      `;
  }
};

// Not sure why "display: flex" was here... As a result of that, the content of the drawer ws always
// out of normal flow, and margin collapsing wasn't working.
const ContentContainer = styled.div<{ background: DrawerBackground | undefined, noTopPadding: boolean }>`
  //display: flex;
  //flex-direction: column;
  overflow-x: hidden;
  padding: ${(props) => props.noTopPadding ? "0" : "2rem"} 2rem 4rem 2rem;
  z-index: 1;
  
  ${(props) => props && paddingMixin(props.background)}

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: ${(props) => props.noTopPadding ? "0" : "1.5rem"} 1rem 4.5rem 1rem;
  }
`;

interface DrawerProps {
  settings: DrawerSettings | undefined;
}

export const Drawer: React.FunctionComponent<DrawerProps> = (props) => {
  const contentRef = React.useRef<React.RefObject<any>>(React.createRef());
  const [active, setActive] = React.useState(false);
  const drawer = useDrawer();
  const previousId = usePrevious(props.settings?.id);

  function close() {
    drawer.close();
  }

  const location = useLocation();
  const mounted = React.useRef(false);

  // Close the drawer on navigation event
  React.useEffect(
    () => {
      if (mounted.current) {
        close();
      }
      mounted.current = true;
    },
    [location]
  );

  React.useEffect(
    () => {
      if (props.settings) {
        if (contentRef.current.current && (props.settings.id === undefined || props.settings.id !== previousId)) {
          contentRef.current.current.scrollTop = 0;
        }
        setActive(true);
      } else {
        setTimeout(() => setActive(false), 500);
      }
    },
    [props.settings, props.settings?.id]
  );

  if (props.settings && active) {
    document.body.style.overflow = "hidden";
    return (
      <>
        {props.settings && <Overlay onClick={close}/>}
        <Body width={width(props.settings.wide)} background={props.settings.background}>
          <Header onClick={close}/>
          <ContentContainer
            ref={contentRef.current}
            noTopPadding={!!props.settings.title}
            background={props.settings.background}
          >
            {
              props.settings.title &&
              <Title red={props.settings.background === "alert"}>{props.settings.title}</Title>
            }
            {typeof props.settings.content === "function" ? props.settings.content() : props.settings.content}
          </ContentContainer>
        </Body>
      </>
    );
  } else {
    document.body.style.overflow = "visible";
    return null;
  }
};

interface DrawerProviderProps {
  children: (drawerSettings: DrawerSettings | undefined) => React.ReactElement;
}

enum DrawerEventType {
  Open = "Open",
  Update = "Update",
  Close = "Close"
}

interface DrawerEvent<T extends DrawerEventType> {
  type: T;
}

interface OpenDrawerEvent extends DrawerEvent<DrawerEventType.Open> {
  settings: DrawerSettings;
}

function openDrawerEvent(settings: DrawerSettings.CompatibleValue): OpenDrawerEvent {
  return {
    type: DrawerEventType.Open,
    settings: DrawerSettings.prepare(settings)
  };
}

interface UpdateDrawerEvent extends DrawerEvent<DrawerEventType.Update> {
  settings: UpdatableDrawerSettings;
}

function updateDrawerEvent(settings: UpdatableDrawerSettings): UpdateDrawerEvent {
  return {
    type: DrawerEventType.Update,
    settings
  };
}

interface CloseDrawerEvent extends DrawerEvent<DrawerEventType.Close> {
}

function closeDrawerEvent(): CloseDrawerEvent {
  return {
    type: DrawerEventType.Close
  };
}

type AnyDrawerEvent = OpenDrawerEvent | UpdateDrawerEvent | CloseDrawerEvent;

function reduceState(
  currentSettings: DrawerSettings | undefined,
  event: AnyDrawerEvent
): DrawerSettings | undefined {
  switch (event.type) {
    case DrawerEventType.Open:
      return event.settings;

    case DrawerEventType.Update:
      return (
        currentSettings &&
        currentSettings.id &&
        currentSettings.deps &&
        currentSettings.id === event.settings.id &&
        currentSettings.deps.length === event.settings.deps.length &&
        currentSettings.deps.findIndex((value, index) => !Object.is(value, event.settings.deps[index])) !== -1
      ) ? event.settings : currentSettings;

    case DrawerEventType.Close:
      return undefined;
  }
}

const DrawerDispatchContext = React.createContext<React.Dispatch<AnyDrawerEvent> | undefined>(undefined);

export const DrawerProvider: React.FunctionComponent<DrawerProviderProps> = (props) => {
  const [state, dispatch] = React.useReducer(reduceState, undefined);

  return (
    <DrawerDispatchContext.Provider value={dispatch}>
      {/*<DrawerStateContext.Provider value={content}>*/}
      {props.children(state)}
      {/*</DrawerStateContext.Provider>*/}
    </DrawerDispatchContext.Provider>
  );
};

export interface DrawerHooks {
  open: (settings: DrawerSettings.CompatibleValue) => void;
  update: (settings: UpdatableDrawerSettings) => void;
  close: () => void;
}

export function useDrawer(): DrawerHooks {
  const dispatch = React.useContext(DrawerDispatchContext);
  return {
    open: (settings) => dispatch && dispatch(openDrawerEvent(settings)),
    update: (settings) => dispatch && dispatch(updateDrawerEvent(settings)),
    close: () => dispatch && dispatch(closeDrawerEvent())
  };
}
