import * as React from "react";
import { Set } from "immutable";
import { SignInDefs } from "../blocks/auth/signInDefs";
import { styled } from "../../app/theme";
import { VaultMeIcon } from "../glyphs/vaultMeIcon";
import { Link } from "react-router-dom";
import { StatusIndicators } from "../utils/statusIndicators";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { ModalController, useModal } from "../layouts/modal";
import { SignUpFlowState } from "../../components/auth/useSignUpFlow";
import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { useCloudServices } from "../../app/configuration";
import { OAuthSignInButton } from "../blocks/auth/oAuthSignInForm";
import { useRoutes } from "../../app/routes/useRoutes";

const Layout = styled.div`
  margin: 
    ${(props) => props.theme.layout.header.desktop * 2}rem 
    auto
    ${(props) => props.theme.layout.header.desktop}rem;
    
  min-width: 30rem;
  max-width: 60rem;
  width: max-content;
  background: ${(props) => props.theme.colors.white};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    max-width: none;
    margin: 
      ${(props) => props.theme.layout.header.mobile}rem
      0 
      ${(props) => props.theme.layout.header.mobile}rem;
  }  
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 3rem 1.5rem;
  }  
`;

const Options = styled.div`
  width: max-content;
  
  ${OAuthSignInButton} {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  margin: 2rem 0 1rem;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.75rem;
  }  
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
  }  
`;

const SubTitle = styled.div`
  font-size: 1rem;
  margin: 1rem 0 2rem;
  max-width: 16rem;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.75rem;
  }  
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
  }  
`;

const LogoContainer = styled.div`
  width: 3rem;
  height: 3rem;
`;

const SignInContainer = styled.div`
  margin-top: 1.5rem;
`;

const OperationStatusIndicatorWrapper = styled.div`
  margin: 1.5rem 0;
  max-width: 60rem;
  overflow-x: auto;
`;

const SignUpOptionLayoutInner = styled.div`
  width: 100%;
  margin: 1.5rem 0;
`;

export const SignUpOptionLayout: SignInDefs.SignInLayoutComponentType = (props) => {
  const modal = useModal();

  const failure = props.operationStatusIndicator && props.operationStatusIndicator.status.isFailure()
    ? props.operationStatusIndicator.status
    : undefined;

  React.useEffect(
    () => {
      if (failure) {
        modal.open();
      } else {
        modal.close();
      }
    },
    [failure]
  );

  return (
    <>
      <ModalController
        modal={modal}
        render={() => (
          <>
            {props.operationStatusIndicator && (
              <OperationStatusIndicator
                {...props.operationStatusIndicator}
                indicators={StatusIndicators.SimplePanel()}
              />
            )}
          </>
        )}
      />
      {props.operationStatusIndicator && props.operationStatusIndicator.status.isPending() && (
        <OperationStatusIndicator
          {...props.operationStatusIndicator}
          indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
        />
      )}
      <SignUpOptionLayoutInner>
        {props.signInForm}
      </SignUpOptionLayoutInner>
    </>
  );
};

interface Props {
  state: SignUpFlowState;
  roles: Set<string>;
  onSignUp: (cloudServiceId: string, oneTimeAuthCodeSummary: OneTimeAuthCodeSummary) => void;
}

export const SignUpPageView: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const routes = useRoutes();

  return (
    <Layout>
      <Content>
        <LogoContainer><VaultMeIcon/></LogoContainer>
        <Title>VaultMe for Admins</Title>
        <SubTitle>
          Using VaultMe for Admins requires a sign-in with your admin account. If you are an end user and want to do a
          single migration, click <Link to={routes.migrationSetup.homePath}>here</Link>.
        </SubTitle>

        {(() => {
          switch (props.state.type) {
            case SignUpFlowState.Type.InitializingSignUpForm:
              return (
                <OperationStatusIndicator
                  subject={"configuration"}
                  status={props.state.status}
                  indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
                />
              );

            case SignUpFlowState.Type.PendingSignUp: {
              return (
                <Options>
                  {
                    props.state.signInForms.flatMap(({ cloudServiceId, signInComponents }) =>
                      signInComponents.map((signInComponent, index) =>
                        React.createElement(signInComponent, {
                          key: cloudServiceId + "-" + index,
                          layoutComponent: SignUpOptionLayout,
                          verb: "Sign In",
                          cloudServiceId,
                          authProviderId: cloudServices.getOrFail(cloudServiceId).authProviderId,
                          roles: props.roles,
                          onSignIn: (oneTimeAuthCode) => props.onSignUp(cloudServiceId, oneTimeAuthCode)
                        })
                      ))
                  }
                </Options>
              );
            }

            case SignUpFlowState.Type.CompletingSignUp:
              return (
                <OperationStatusIndicator
                  progressMessage={"Creating a VaultMe account..."}
                  successMessage={"Done!"}
                  status={props.state.status}
                  indicators={StatusIndicators.PanelRow({ wrapper: OperationStatusIndicatorWrapper })}
                />
              );
          }
        })()}
      </Content>
    </Layout>
  );
};
