import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Program } from "../../types/models/program";

export function useGetProgramQuery(
  id: string
): ManagedQueryHook<GraphQL.GetProgramQueryVariables, Program> {
  return useManagedQuery({
    query: GraphQL.useGetProgramQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetProgramQuery) => nullToUndefined(data.program),
    complete: (program) => Program.parse(program),
    fetchPolicy: "cache-and-network"
  });
}
