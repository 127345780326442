import * as React from "react";
import { useNavigate } from "react-router";
import { MigrationSetupWorkflow, MigrationSetupWorkflowResult } from "./migrationSetupWorkflow";
import { useWatcherDispatch } from "../services/watcher/useWatcher";
import { useApolloClient } from "@apollo/react-hooks";
import { WorkflowController } from "../wizardry/workflowController";
import { WorkflowProgressIndicator } from "../views/blocks/wizardry/workflowProgressIndicator";
import { WorkflowError } from "../views/blocks/wizardry/workflowError";
import { WorkflowLayout } from "../views/layouts/workflowLayout";
import { List } from "immutable";
import { ClickableAnchor } from "../wizardry/anchor";
import { WorkflowTitle } from "../views/layouts/workflowTitle";
import { WorkflowNavigationAnchors } from "../views/blocks/wizardry/workflowNavigationAnchors";
import { useSession, useSessionObservable } from "../utils/useAppState";
import { useAppBootstrapConfig, useAuthProviders, useCloudServices } from "../app/configuration";
import { MigrationSetupWorkflowContext } from "./migrationSetupWorkflowContext";
import { LocalStorage } from "../app/localStorage";
import { Constants } from "../app/constants";
import { NewMigrationsAreNotAllowed } from "../views/screens/newMigrationsAreNotAllowed";
import { useRoutes } from "../app/routes/useRoutes";
import { SignInContextType } from "../types/models/signInContextType";

interface LeftHandNavProps {
  anchors: List<ClickableAnchor>;
}

const LeftHandNav: React.FunctionComponent<LeftHandNavProps> = (props) => (
  <>
    <WorkflowTitle>Migration Setup</WorkflowTitle>
    <WorkflowNavigationAnchors anchors={props.anchors}/>
  </>
);

export const MigrationSetup: React.FunctionComponent = () => {
  const apolloClient = useApolloClient();
  const appBootstrapConfig = useAppBootstrapConfig();
  const cloudServices = useCloudServices();
  const sourceCloudServices = useCloudServices(SignInContextType.Source);
  const destinationCloudServices = useCloudServices(SignInContextType.Destination);
  const authProviders = useAuthProviders();
  const watcher = useWatcherDispatch();
  const sessionObservable = useSessionObservable();
  const routes = useRoutes().migrationSetup;
  const params = routes.params();
  const navigate = useNavigate();

  // This is really ugly (and may not even work given the comment below), but okay for something so non-critical
  if (Constants.NewMigrationsAreNotAllowed && !useSession()?.elevated) {
    return <NewMigrationsAreNotAllowed/>;
  }

  // There's a complex issue here that is forcing us using useSessionObservable() hook instead of plain useSession().
  // The problem is that when a user connects an account for the first time, a session also gets created.
  // But a change in the session gets propagated through Redux AFTER the wizard gets updated and starts using this
  // session (see MigrationSetupWorkflowContext.loadConnection). The easiest and hopefully the most transparent way
  // of overcoming this is using Observable<Session> that will emit the correct session once it's propagated in Redux.
  // The alternative way would be to use WizardStep.resultKey and introduce a dependency on session, but that would
  // require much more discipline and may not actually work.

  const context = React.useMemo<MigrationSetupWorkflowContext>(
    () => new MigrationSetupWorkflowContext({
      sessionObservable,
      apolloClient,
      appBootstrapConfig,
      cloudServices,
      sourceCloudServices,
      destinationCloudServices,
      authProviders,
      watcher,
    }),
    // Only listing the things that may actually change and make some impact
    [apolloClient, watcher, appBootstrapConfig]
  );

  const workflow = React.useMemo(
    () => {
      return MigrationSetupWorkflow({
        sourceCloudServiceId: params.sourceCloudServiceId.toJS(),
        destinationCloudServiceId: params.destinationCloudServiceId.toJS(),
        forceSingleMigration: params.forceSingleMigration || !!appBootstrapConfig.configuration
      });
    },
    [appBootstrapConfig]
  );

  return (
    <WorkflowController<MigrationSetupWorkflowContext, any, MigrationSetupWorkflowResult>
      workflow={workflow}
      context={context}
      path={params.path.getOrUse("")}
      localStorageKey={LocalStorage.MigrationSetupWorkflowKey}
      renderInitialization={(
        <WorkflowLayout>
          <WorkflowProgressIndicator leftHandNavContent={<LeftHandNav anchors={List()}/>}/>
        </WorkflowLayout>
      )}
      renderError={(error) => <WorkflowError {...error}/>}
      onPathChange={(path, replacePreviousPath) =>
        navigate(routes.wizardPath(path), { replace: replacePreviousPath })
      }
      onComplete={(result) =>
        console.log("Completed wizard with message: " + result)
        // setTimeout(() => history.push(Paths.Migrations.path(result.message)), 0)
      }
    >
      {
        ({ anchors, renderedWorkItem, working }) => (
          <WorkflowLayout leftHandNavContent={<LeftHandNav anchors={anchors}/>}>
            {working && <WorkflowProgressIndicator leftHandNavContent={<LeftHandNav anchors={anchors}/>}/>}
            {renderedWorkItem}
          </WorkflowLayout>
        )
      }
    </WorkflowController>
  );
};
