import * as React from "react";
import { Panel } from "./panel";
import { Block } from "./block";
import { IndefiniteProgressBar } from "../widgets/indefiniteProgressBar";
import { PanelRow } from "./rows/panelRow";
import { Delayed } from "../utils/delayed";
import { Constants } from "../../app/constants";
import { ErrorInfo, ErrorInfoProps } from "../widgets/errorInfo";
import { ProgressIndicatorRowProps } from "./rows/progressIndicatorRow";
import { Flash } from "../utils/flash";
import { StyledSuccessIndicatorRow, SuccessIndicatorRowProps } from "./rows/successIndicatorRow";
import { wrapped } from "../utils/wrapped";

export const SimplePanel: React.FunctionComponent = (props) => (
  <Block>
    <Panel>
      <PanelRow {...props}/>
    </Panel>
  </Block>
);

export const SimpleProgressIndicatorPanel: React.FunctionComponent<ProgressIndicatorRowProps> = (props) => {
  return (
    <Delayed delay={props.delay !== undefined ? props.delay : Constants.ProgressIndicatorDelay}>
      {
        wrapped(
          (
            <Block>
              <Panel>
                <IndefiniteProgressBar/>
                <PanelRow>{props.children}</PanelRow>
              </Panel>
            </Block>
          ),
          props.wrapper
        )
      }
    </Delayed>
  );
};

export const SimpleSuccessIndicatorPanel: React.FunctionComponent<SuccessIndicatorRowProps> = (props) => {
  return (
    <Flash timeout={props.timeout !== undefined ? props.timeout : Constants.SuccessIndicatorTimeout}>
      {
        wrapped(
          (
            <Block>
              <Panel>
                <StyledSuccessIndicatorRow>{props.children}</StyledSuccessIndicatorRow>
              </Panel>
            </Block>
          ),
          props.wrapper
        )
      }
    </Flash>
  );
};

interface SimpleErrorPanelProps extends ErrorInfoProps {
  hideProgressBar?: boolean;
}

export const SimpleErrorPanel: React.FunctionComponent<SimpleErrorPanelProps> = (props) => (
  <Block>
    <Panel>
      {!props.hideProgressBar && <IndefiniteProgressBar error={true}/>}
      <PanelRow>
        <ErrorInfo {...props}/>
      </PanelRow>
    </Panel>
  </Block>
);
