import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { ComponentHubs, Component } from "../component";
import { State } from "../state";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { ExactlyOneRelationshipHub } from "../hubs/exactlyOneRelationshipHub";
import { parseServerJson } from "../parseServerJson";
import { VarsHub } from "../hubs/varsHub";
import { ComponentBinding } from "./componentBinding";

export abstract class EndpointComp<Hubs extends ComponentHubs>
  extends Component<EndpointComp.Props, Hubs, EndpointComp.Output> {

  public abstract readonly isRoot: boolean;

  public stateWhenUnblocked(context: BlueprintContext): State<EndpointComp.Output> {
    return State.blocked();
  }

  public visualization(): ComponentVisualization {
    return {
      title: (this.isRoot ? "" : "Child ") + "Endpoint",
      summary: this.props.title,
      icon: Images.Blueprint.Socket,
      color: ComponentColorSchema.SolidDarkBlue,
      sizeMultiplier: this.isRoot ? 1.5 : 1.0
    };
  }
}

export namespace EndpointComp {
  export interface Props {
    title: string;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.EndpointCompProps): Props {
      return {
        title: props.title
      };
    }
  }

  export interface Output {
    endpointDefJson: any;
    endpointRef: any;
  }

  export namespace Output {
    export function fromGraphQL(
      output: GraphQL.RootEndpointCompOutputFragment | GraphQL.ChildEndpointCompOutputFragment
    ): Output {
      return {
        endpointDefJson: parseServerJson(output.endpointDefJson),
        endpointRef: parseServerJson(output.endpointRef)
      };
    }
  }
}

export class RootEndpointComp extends EndpointComp<RootEndpointComp.Hubs> {
  public readonly isRoot: boolean = true;
}

export namespace RootEndpointComp {
  export interface Hubs extends ComponentHubs {
    accessKey: ExactlyOneRelationshipHub<any>;
    vars: VarsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.RootEndpointCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        accessKey: binding.exactlyOneRelationshipHub("accessKey", hubs.accessKey),
        vars: binding.varsHub("vars", hubs.vars),
      };
    }
  }
}

export class ChildEndpointComp extends EndpointComp<ChildEndpointComp.Hubs> {
  public readonly isRoot: boolean = false;
}

export namespace ChildEndpointComp {
  export interface Hubs extends ComponentHubs {
    accessKey: ExactlyOneRelationshipHub<any>;
    vars: VarsHub;
    rootEndpointRef: ExactlyOneRelationshipHub<any>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.ChildEndpointCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        accessKey: binding.exactlyOneRelationshipHub("accessKey", hubs.accessKey),
        vars: binding.varsHub("vars", hubs.vars),
        rootEndpointRef: binding.exactlyOneRelationshipHub("rootEndpointRef", hubs.rootEndpointRef),
      };
    }
  }
}
