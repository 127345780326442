import { interpolate } from "../../utils/misc";
import { Map } from "immutable";
import { Collectable } from "../collectables/collectable";
import { GraphQL } from "../../services/graphql/generated";
import { Option } from "../../utils/monads/option";

export abstract class FactRef<P, C, T extends Collectable<P, C>, DefCtx extends FactRef.Context, RefCtx> {
  constructor(public readonly family: string) {
  }

  public abstract makeContext(ctx: RefCtx): Option<DefCtx>;

  public resolveId(ctx: RefCtx): Option<string> {
    return this.makeContext(ctx).map((context) => {
      let result = this.family;
      for (const key in context) {
        if (context.hasOwnProperty(key)) {
          const value = context[key];
          result = interpolate(result, Map([[key, value]]));
        }
      }
      return result;
    });
  }
}

export namespace FactRef {
  export interface Props {
    type: string;
    family: string;
    valueType: string;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.FactRef): Props {
      return {
        type: props.type,
        family: props.family,
        valueType: props.valueType,
      };
    }
  }

  export interface Context {
    [key: string]: string | undefined;
  }

  export interface Resolved {
    id: string;
    family: string;
    valueType: string;
  }
}
