import * as React from "react";
import { styled } from "../../../../app/theme";
import { Button } from "../../../widgets/button";
import { LinkButton } from "../../../widgets/linkButton";
import { TextBox } from "../../../widgets/textBox";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { OperationStatus } from "../../../../types/operationStatus";
import { GraphQL } from "../../../../services/graphql/generated";
import { ReferralCodeValidationResult } from "../../../../types/models/referralCodeValidationResult";
import Type = ReferralCodeValidationResult.Type;
import { useAppBootstrapConfig } from "../../../../app/configuration";

interface CodeValidationResult {
  code: string;
  discount: string;
  error: string | undefined;
}

export interface DiscountsConfig {
  couponCodeStatus: OperationStatus<GraphQL.CouponCodeSummary>;
  onSubmitCouponCode: (code: string) => void;
  onClearCouponCodeError: () => void;

  referralCodeStatus: OperationStatus<ReferralCodeValidationResult>;
  onSubmitReferralCode: (code: string) => void;
  onClearReferralCodeError: () => void;

  ambassadorCodeStatus: OperationStatus<string>;
  onSubmitAmbassadorCode: (code: string) => void;
  onClearAmbassadorCodeError: () => void;
}

export const DiscountsPanel: React.FunctionComponent<DiscountsConfig> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  return (
    <Block>
      <Panel>
        <DiscountCodePanelRow
          subject={"Alumni Association Member Benefit Code"}
          status={props.referralCodeStatus.map((validationResult) => ({
            code: validationResult.referralCode.code,
            discount: validationResult.referralCode.discount.toFixed(1) + "%",
            error: validationResult.type === Type.NotEligible
              ? "Sorry, Alumni Association Member Benefit Codes are not eligible for this migration because " +
              "this migration is governed by " + validationResult.governingOrganizationName
              : undefined
          }))}
          onSubmit={props.onSubmitReferralCode}
          onClearError={props.onClearReferralCodeError}
        />
        <DiscountCodePanelRow
          subject={"EDU Ambassador Code"}
          status={props.ambassadorCodeStatus.map((ambassadorCode) => ({
            code: ambassadorCode,
            discount: appBootstrapConfig.ambassadorsProgram.codeDiscount.toFixed(1) + "%",
            error: undefined
          }))}
          onSubmit={props.onSubmitAmbassadorCode}
          onClearError={props.onClearAmbassadorCodeError}
        />
        <DiscountCodePanelRow
          subject={"Coupon Code"}
          status={props.couponCodeStatus.map((couponCode) => ({
            code: couponCode.id,
            discount: couponCode.isPercentage
              ? (couponCode.discount.toFixed(1) + "%")
              : ("$" + couponCode.discount.toFixed(2)),
            error: undefined
          }))}
          onSubmit={props.onSubmitCouponCode}
          onClearError={props.onClearCouponCodeError}
        />
      </Panel>
    </Block>
  );
};

interface DiscountCodePanelRowProps {
  subject: string;
  status: OperationStatus<CodeValidationResult>;
  onSubmit: (code: string) => void;
  onClearError: () => void;
}

const DiscountCodePanelRow: React.FunctionComponent<DiscountCodePanelRowProps> = (props) => {
  const validatedCode = props.status.isSuccess() && !props.status.result.error ? props.status.result.code : "";

  const [value, setValue] = React.useState<string>(validatedCode);
  const [expanded, setExpanded] = React.useState(false);

  const subjectStartsWithVowel = ["A", "E", "I", "O", "U"].indexOf(props.subject[0].toUpperCase()) !== -1;

  React.useEffect(
    () => {
      if (props.status.isSuccess()) {
        setExpanded(false);
      }
    },
    [props.status.isSuccess()]
  );

  function expand(): void {
    setValue(validatedCode);
    setExpanded(true);
  }

  return (
    <>
      {props.status.isWorking() && <IndefiniteProgressBar/>}
      <StyledPanelRow>
        {props.status.isSuccess()
          ? (
            <>
              <Prompt>
                APPLIED {props.subject.toUpperCase()} "{props.status.result.code.toUpperCase()}"
                ({props.status.result.discount} off)
                <LinkButton onClick={expand}>Change</LinkButton>
              </Prompt>
              {props.status.result.error && <ErrorMessage>{props.status.result.error}</ErrorMessage>}
            </>
          )
          : (
            <Prompt>
              Got a{subjectStartsWithVowel ? "n" : ""} {props.subject}?{" "}
              <LinkButton onClick={expand}>Apply it here</LinkButton>
            </Prompt>
          )
        }
        {expanded && (
          <Form>
            <TextBoxContainer>
              <TextBox
                placeholder={"Please type " + props.subject + " here"}
                value={value}
                autoFocus={true}
                disabled={props.status.isWorking()}
                onChange={(event) => setValue(event.target.value)}
                onKeyPress={(event) => event.key === "Enter" && value.trim().length !== 0 && props.onSubmit(value)}
              />
            </TextBoxContainer>
            <Buttons>
              <div>
                <Button
                  type="submit"
                  color="blue"
                  size="small"
                  onClick={() => props.onSubmit(value)}
                  disabled={props.status.isWorking() || value.trim().length === 0}
                >
                  {props.status.isWorking() ? "Applying..." : "Apply Code"}
                </Button>
              </div>
              <div>
                <Button
                  color="white"
                  size="small"
                  onClick={() => {
                    props.onClearError();
                    setExpanded(false);
                  }}
                  disabled={props.status.isWorking()}
                >
                  Cancel
                </Button>
              </div>
            </Buttons>
            {props.status.isFailure() && (
              <ErrorMessage>
                We are sorry, but the {props.subject} you entered is not valid. Please try again.
              </ErrorMessage>
            )}
          </Form>
        )}
      </StyledPanelRow>
    </>
  );
};

const StyledPanelRow = styled(PanelRow)`
  font-size: ${(props) => props.theme.font.small}rem;
`;

const Prompt = styled.div`
  ${LinkButton} {
    margin-left: .5rem;
  }
`;

const Form = styled.div`
  max-width: 30rem;
  padding-top: .25rem;
  display: flex;
  flex-wrap: wrap;
`;

const TextBoxContainer = styled.div`
  flex-grow: 1;
  padding: .25rem .5rem .25rem 0;

  input {
    width: 100%;
    font-size: 0.9rem;
    padding: .5rem;
    border: 1px solid #aaa;
    border-radius: 3px;
    box-sizing: border-box;

    &::placeholder {
      color: #dddddd;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${Button} {
    margin: .25rem .5rem .25rem 0;
  }
`;

const ErrorMessage = styled.div`
  margin-top: .25rem;
  color: ${(props) => props.theme.colors.red};

  ${Prompt} ~ & {
    margin-top: .5rem;
  }
`;
