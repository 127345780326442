import { camelCaseToWords } from "../utils/formatting";
import { Blueprint } from "./blueprint";
import { BlueprintContext } from "./blueprintContext";
import { Element } from "./element";
import { AnyRelationship, DataFlowOf, RelationshipStyling } from "./relationship";
import { State } from "./state";
import { List } from "immutable";
import { RelationshipParser } from "./relationships/relationshipParser";
import { GraphQL } from "../services/graphql/generated";
import { HubBinding } from "./hubBinding";
import { nullToUndefined } from "../utils/misc";

export interface HubProps {
  proposedRelationshipTitle: string | undefined;
}

export namespace HubProps {
  export interface Data {
    proposedRelationshipTitle?: string | null;
  }

  export function fromGraphQL(data: Data): HubProps {
    return {
      proposedRelationshipTitle: nullToUndefined(data.proposedRelationshipTitle)
    };
  }
}

export interface ComponentHubSettings {
  title?: string;
  defaultRelationshipTitle?: string;
  defaultRelationshipStyling?: RelationshipStyling;
}

export interface HubData {
  serverSide: boolean;
  relationships: GraphQL.RelationshipFragment[];
}

export abstract class Hub<Props extends HubProps> implements Element<Props> {
  public readonly blueprint: Blueprint;
  public readonly sourceComponentId: string;
  public readonly name: string;

  public readonly serverSide: boolean;
  public readonly relationships: List<AnyRelationship>;

  public readonly props: Props;
  public readonly settings: ComponentHubSettings | undefined;

  constructor(
    binding: HubBinding,
    data: HubData,
    props: Props,
    settings: ComponentHubSettings | undefined,
  ) {
    this.blueprint = binding.blueprint;
    this.sourceComponentId = binding.sourceComponentId;
    this.name = binding.hubName;

    this.serverSide = data.serverSide;
    this.relationships = List(data.relationships.map((r) => RelationshipParser.fromGraphQL(binding, r)));

    this.props = props;
    this.settings = settings;
  }

  public get type(): string {
    return this.constructor.name;
  }

  public get title(): string {
    return this.settings && this.settings.title || camelCaseToWords(this.name);
  }

  public proposedRelationshipTitle(): string {
    return this.props.proposedRelationshipTitle || "uses";
  }

  public defaultRelationshipTitle(): string {
    return this.settings && this.settings.defaultRelationshipTitle || this.proposedRelationshipTitle();
  }

  public proposedRelationshipStyling(): RelationshipStyling {
    return {};
  }

  public defaultRelationshipStyling(context: BlueprintContext, state: State<any>): RelationshipStyling {
    return this.settings && this.settings.defaultRelationshipStyling || this.proposedRelationshipStyling();
  }

  public state(context: BlueprintContext): State<any> {
    try {
      return context.memoize(
        this.sourceComponentId + "$" + this.name,
        () => {
          if (this.serverSide) {
            const serverState = context.serverElements.getHubState(this.sourceComponentId, this.name);
            if (serverState !== undefined) {
              return serverState;
            } else {
              return State.blocked();
            }
          } else {
            return this.calcState(context);
          }
        }
      );
    } catch (error) {
      console.error(
        "Unhandled error in Hub.state (component: " + this.sourceComponentId + ", hub: " + this.name + ")",
        error
      );
      throw error;
    }
  }

  public abstract calcState(context: BlueprintContext): State<any>;

  public resolvedByServer(context: BlueprintContext): boolean {
    return this.serverSide && context.serverElements.getHubState(this.sourceComponentId, this.name) !== undefined;
  }

  public messages(context: BlueprintContext): List<string> {
    return List();
  }
}

export abstract class DataSource<Props extends HubProps, Input, Output> extends Hub<Props> {
  public state(context: BlueprintContext): State<Output> {
    return super.state(context);
  }

  public abstract calcState(context: BlueprintContext): State<Output>;

  public dataFlows(): List<DataFlowOf<Input>> {
    return this.relationships as List<DataFlowOf<Input>>;
  }
}

export type AnyHub = Hub<any>;
