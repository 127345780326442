import { Session } from "../../types/models/session";
import { AppAction } from "../appAction";
import { SessionActionType } from "./actions";
import { Tracking } from "../../services/tracking";

export function reduceSession(state: Session | undefined, action: AppAction): Session | undefined {
  switch (action.type) {
    case SessionActionType.ReplacedSession:
      Tracking.signedIn(action.session.user, action.session.elevated);
      return action.session;

    case SessionActionType.ReplacedUser:
      if (state) {
        return new Session({
          user: action.user,
          elevated: state.elevated,
          idleTimeout: state.idleTimeout
        });
      } else {
        return undefined;
      }

    case SessionActionType.SignedIn:
      Tracking.signedIn(action.connectResult.user, action.connectResult.elevated);
      return new Session({
        user: action.connectResult.user,
        elevated: action.connectResult.elevated,
        idleTimeout: action.connectResult.idleTimeout
      });

    default:
      return state;
  }
}
