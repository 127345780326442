import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { School } from "../../types/models/school";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime, multiline } from "../../utils/formatting";
import { useSearchSchoolsQuery } from "../../queries/schools/useSearchSchoolsQuery";
import { useDeleteSchoolMutation } from "../../queries/schools/useDeleteSchoolMutation";
import { useUpdateSchoolMutation } from "../../queries/schools/useUpdateSchoolMutation";
import { useInsertSchoolMutation } from "../../queries/schools/useInsertSchoolMutation";
import { useGetSchoolQuery } from "../../queries/schools/useGetSchoolQuery";
import { SchoolFormData } from "../../types/models/schoolFormData";
import { CenteredContent } from "../../views/layouts/centeredContent";
import { SchoolForm } from "../../views/screens/schoolAdmin/schoolForm";
import { useChatGPTLookupController } from "./useChatGPTLookupController";
import { useFileUploadController } from "../../queries/api/useFileUploadController";
import { WidgetStatus } from "../../views/utils/widgetStatus";
import { Button } from "../../views/widgets/button";
import { useBrowser } from "../../utils/useBrowser";
import { useGetSchoolStatsQuery } from "../../queries/schools/useGetSchoolStatsQuery";
import { SchoolOverview } from "../../views/screens/schoolAdmin/schoolOverview";

export const SchoolAdminPages: React.FunctionComponent = () => {
  const browser = useBrowser();
  const routes = useRoutes();

  const fileUploadController = useFileUploadController();
  const chatGPTLookupController = useChatGPTLookupController();

  return (
    <CenteredContent fullWidth={true}>
      {
        CrudPages<string, School, SchoolFormData>({
          title: "Schools",
          subject: "school",
          additionalTools: [
            <Button
              key={"download-all"}
              size={"small"}
              color={"secondary"}
              onClick={() => browser.openNewTab(routes.api.schoolsReportUrl(false), true)}
            >
              Download All (CSV)
            </Button>,
            <Button
              key={"download-all"}
              size={"small"}
              color={"secondary"}
              onClick={() => browser.openNewTab(routes.api.schoolsReportUrl(true), true)}
            >
              Download Awaiting Review (CSV)
            </Button>,
          ],
          gridColumns: [
            {
              title: "Domains",
              render: (school) => multiline(school.domains)
            },
            {
              title: "Name",
              render: (school) => school.officialName
            },
            {
              title: "Country",
              render: (school) => school.country
            },
            {
              title: "Type",
              render: (school) => school.type
            },
            {
              title: "Notes",
              render: (school) => multiline(school.notes)
            },
            {
              title: "Review Status",
              render: (school) => school.reviewed
                ? { content: "Reviewed", status: WidgetStatus.Success }
                : { content: "Awaiting Review", status: WidgetStatus.Warning }
            },
            {
              title: "Created At",
              render: (school) => friendlyDateTime(school.createdAt)
            },
          ],
          renderOverview: (school) => <SchoolOverviewWrapper school={school}/>,
          renderForm: (config) => (
            <SchoolForm
              config={config}
              fileUploadController={fileUploadController}
              chatGPTLookupController={chatGPTLookupController}
            />
          ),

          useRoutes: () => useRoutes().schools,
          getItemId: (school) => school.id,
          getItemTitle: (school) => school.title,
          clone: (school) => ({ ...school, name: "Copy of " + school.title }),
          compare: (a: School, b: School) => {
            const titleA = a.title?.toLowerCase() || "";
            const titleB = b.title?.toLowerCase() || "";
            return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
          },

          useGetQuery: (id) => {
            const [schoolStatus, refreshSchool] = useGetSchoolQuery(id);
            const [statsStatus, refreshStats] = useGetSchoolStatsQuery(id);

            return [
              schoolStatus,
              (variableOverrides, soft) => {
                refreshSchool(variableOverrides, soft);
                refreshStats({}, soft);
              }
            ];
          },
          useSearchQuery: useSearchSchoolsQuery,
          useInsertMutation: useInsertSchoolMutation,
          useUpdateMutation: useUpdateSchoolMutation,
          useDeleteMutation: useDeleteSchoolMutation
        })
      }
    </CenteredContent>
  );
};

interface SchoolOverviewWrapperProps {
  school: School;
}

const SchoolOverviewWrapper: React.FunctionComponent<SchoolOverviewWrapperProps> = (props) => {
  const [statsStatus] = useGetSchoolStatsQuery(props.school.id);

  return <SchoolOverview school={props.school} statsStatus={statsStatus}/>;
};
