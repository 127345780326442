import * as React from "react";
import { WatcherAction } from "./watcherAction";
import { WatchedItems } from "./watchedItems";
import { Map, Set } from "immutable";
import { AnyWatcherAction } from "./anyWatcherAction";

function dispatchReviewsFunction(
  listName: string,
  dispatch: React.Dispatch<AnyWatcherAction>): WatchedItems.DispatchRenews {
  return (keys) => dispatch(WatcherState.ApplyChangesAction(Map([[listName, Set(keys)]]), Map()));
}

export class WatcherState<Ctx> {
  constructor(protected lists: Map<string, WatchedItems<Ctx, any, any>>) {
  }

  public reduce(context: Ctx, action: AnyWatcherAction): WatcherState<Ctx> {
    const updatedLists = this.reduceLists(context, action);
    return this.lists.equals(updatedLists) ?  this : new WatcherState(updatedLists);
  }

  public updateSubscriptions(
    context: Ctx,
    previousState: WatcherState<Ctx>,
    dispatch: React.Dispatch<AnyWatcherAction>): WatcherState.UpdateSubscriptionsResult | undefined {
    return this.lists
      .map((list, listName) => {
        const previousList = previousState.lists.get(listName);
        if (previousList) {
          const diffs = list.updateSubscriptions(context, previousList, dispatchReviewsFunction(listName, dispatch));
          if (!diffs.isEmpty()) {
            return {
              subscribed: list.friendlyCount(diffs.added.size),
              unsubscribed: list.friendlyCount(diffs.removed.size)
            };
          }
        }
      })
      .reduce(WatcherState.UpdateSubscriptionsResult.reduce, undefined);
  }

  public review(
    context: Ctx,
    dispatch: React.Dispatch<AnyWatcherAction>): WatcherState.ReviewResult<Ctx> {
    const reviewResults = this.lists.map((list, listName) => ({
      list,
      result: list.review(context, dispatchReviewsFunction(listName, dispatch))
    }));

    const renewKeys: Map<string, Set<string>> = reviewResults
      .map((r) => r.result.renewKeys)
      .filterNot((keys) => keys.isEmpty());

    const removeKeys: Map<string, Set<string>> = reviewResults
      .map((r) => r.result.removeKeys)
      .filterNot((keys) => keys.isEmpty());

    if (!renewKeys.isEmpty() || !removeKeys.isEmpty()) {
      dispatch(WatcherState.ApplyChangesAction(renewKeys, removeKeys));
    }

    const refreshed = reviewResults.valueSeq().filter((reviewResult) => !!reviewResult.result.refreshed);

    return {
      refreshed: refreshed.isEmpty()
        ? undefined
        : {
          promise: Promise.all(refreshed.map((r) => r.result.refreshed!.promise)),
          summary: refreshed.map((r) => r.list.friendlyCount(r.result.refreshed!.count)).join(", ")
        },
      renewed: renewKeys.isEmpty()
        ? undefined
        : reviewResults.valueSeq()
          .filterNot((r) => r.result.renewKeys.isEmpty())
          .map((r) => r.list.friendlyCount(r.result.renewKeys.count())).join(", "),
      removed: removeKeys.isEmpty()
        ? undefined
        : reviewResults.valueSeq()
          .filterNot((r) => r.result.removeKeys.isEmpty())
          .map((r) => r.list.friendlyCount(r.result.removeKeys.count())).join(", "),
    };
  }

  protected reduceLists(context: Ctx, action: AnyWatcherAction): Map<string, WatchedItems<Ctx, any, any>> {
    if (action.type === "ApplyChanges") {
      return this.lists.map((list, listName) => {
        const renewed = this.renewKeys(context, list, listName, action.renewKeys.get(listName, Set()));
        return this.removeKeys(renewed, action.removeKeys.get(listName, Set()));
      });
    } else {
      return this.lists.map((list) => list.reduce(context, action));
    }
  }

  protected renewKeys(
    context: Ctx,
    list: WatchedItems<Ctx, any, any>,
    listName: string,
    keys: Set<string>): WatchedItems<Ctx, any, any> {
    if (keys.isEmpty()) {
      return list;
    } else {
      // console.log("Renewing " + list.friendlyCount(keys.size));
      return list.renew(context, keys);
    }
  }

  protected removeKeys(
    list: WatchedItems<Ctx, any, any>,
    keys: Set<string>): WatchedItems<Ctx, any, any> {
    if (keys.isEmpty()) {
      return list;
    } else {
      // console.log("Removing " + list.friendlyCount(keys.size));
      return list.remove(keys);
    }
  }
}

export namespace WatcherState {
  export interface ApplyChangesAction extends WatcherAction<"ApplyChanges"> {
    renewKeys: Map<string, Set<string>>;
    removeKeys: Map<string, Set<string>>;
  }

  export const ApplyChangesAction = (
    renewKeys: Map<string, Set<string>>,
    removeKeys: Map<string, Set<string>>): ApplyChangesAction => (
    {
      type: "ApplyChanges",
      renewKeys,
      removeKeys,
    }
  );

  export interface UpdateSubscriptionsResult {
    subscribed: string;
    unsubscribed: string;
  }

  export namespace UpdateSubscriptionsResult {
    export function reduce(
      a: UpdateSubscriptionsResult | undefined,
      b: UpdateSubscriptionsResult | undefined): UpdateSubscriptionsResult | undefined {
      if (!a) {
        return b;
      } else if (!b) {
        return a;
      } else {
        return {
          subscribed: a.subscribed + ", " + b.subscribed,
          unsubscribed: a.unsubscribed + ", " + b.unsubscribed,
        };
      }
    }
  }

  export interface ReviewResult<Ctx> {
    refreshed?: {
      promise: Promise<any>;
      summary: string;
    };
    renewed?: string;
    removed?: string;
  }
}
