import * as React from "react";
import { styled } from "../../app/theme";

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

interface Props {
  color: string;
}

// tslint:disable max-line-length
export const Contacts: React.FunctionComponent<Props> = (props) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="210"
    height="210"
    viewBox="-5 -14 206 196"
    fill="none"
  >
    <circle cx="55" cy="37" r="35.5" stroke={props.color} strokeWidth="11"/>
    <circle cx="142" cy="37" r="35.5" stroke={props.color} strokeWidth="11"/>
    <path d="M95.5 160.5H11C6 160.5 1.50002 157 1.5 151.5V125.5C1.4999 109.5 21 91 39 91H72C82 91 105.5 109.5 105.5 125.5V151.5C105.5 157.5 102 160.5 95.5 160.5Z" stroke={props.color} strokeWidth="11"/>
    <path d="M91.5 160.5C99 160.5 169 160.5 184 160.5C190.5 160.5 194 157.5 194 151.5C194 137.5 194 141.5 194 125.5C194 109.5 170.5 91 160.5 91C145 91 145.5 91 127.5 91C116.444 91 104.822 97.9798 97.5 107.073" stroke={props.color} strokeWidth="11"/>
  </StyledSvg>
);
