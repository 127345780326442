import * as React from "react";
import { useSession } from "../../utils/useAppState";
import { styled } from "../../app/theme";
import { signOut } from "../../app/signOut";
import { LinkButton } from "../widgets/linkButton";

const Layout = styled.div`
  display: flex;
  
  > * ~ * {
    margin-left: 0.25rem;
  }
`;

export const CurrentUserInfo: React.FunctionComponent = () => {
  const session = useSession();

  if (session) {
    const fullName = session.user.personalInfo.fullName || "an anonymous user";
    return (
      <Layout>
        <span>Signed in as {fullName.substring(0, 50) + (fullName.length > 50 ? "..." : "")}</span>
        <LinkButton onClick={signOut}>Sign Out</LinkButton>
      </Layout>
    );
  } else {
    return null;
  }
};
