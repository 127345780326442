import * as React from "react";
import { Page } from "../../containers/page";
import { Block } from "../../containers/block";
import { OperationStatus } from "../../../types/operationStatus";
import { ModalController, useModal } from "../../layouts/modal";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { UserMigrationsGrid } from "./userMigrationsGrid";
import { GrandOption } from "../../widgets/option";
import { UserMigrations } from "../../../types/models/userMigrations";
import { CenteredContent } from "../../layouts/centeredContent";
import { useConfigurationAlias, useProgramAlias } from "../../../app/configuration";

interface Props {
  status: OperationStatus<UserMigrations>;
  onNewMigration: (type: boolean) => void;
  onRefresh: () => void;
}

export const UserDashboardPageView: React.FunctionComponent<Props> = (props) => {
  const modal = useModal();
  const programAlias = useProgramAlias();
  const configurationAlias = useConfigurationAlias();

  const result = props.status.someResult();
  if (result) {
    return (
      <Page
        title="My Migrations"
        fullWidth={!result.isEmpty}
      >
        <ModalController
          modal={modal}
          title={"What do you want to accomplish?"}
          render={() => (
            <NewMigrationTool
              onSubmit={(type) => {
                modal.close();
                props.onNewMigration(type);
              }}
            />
          )}
        />

        <Block>
          <UserMigrationsPanel
            status={props.status}
            userMigrations={result}
            onNewMigration={() => {
              if (!programAlias && !configurationAlias) {
                modal.open();
              } else {
                props.onNewMigration(false);
              }
            }}
            onRefresh={props.onRefresh}
          />
        </Block>
      </Page>
    );
  } else {
    return (
      <CenteredContent>
        <OperationStatusIndicator
          status={props.status}
          subject={"dashboard data"}
          indicators={StatusIndicators.SimplePanel()}
        />
      </CenteredContent>
    );
  }
};

interface UserMigrationsPanelViewProps {
  status: OperationStatus<UserMigrations>;
  userMigrations: UserMigrations;
  onNewMigration: () => void;
  onRefresh: () => void;
}

const UserMigrationsPanel: React.FunctionComponent<UserMigrationsPanelViewProps> = (props) => {
  return (
    <Panel>
      <OperationStatusIndicator
        status={props.status}
        subject={"migrations"}
        indicators={StatusIndicators.PanelRow()}
      />

      <PanelRow>
        <SimpleToolbar
          centerVertically={true}
          rightSide={!props.userMigrations.isEmpty && (
            <Button
              size={"small"}
              color={"white"}
              disabled={props.status.isWorking()}
              onClick={props.onRefresh}
            >
              Refresh
            </Button>
          )}
        >
          <Button size={"narrow"} onClick={props.onNewMigration}>Start New Migration</Button>
        </SimpleToolbar>
      </PanelRow>

      {props.userMigrations.isEmpty
        ? (
          <PanelRow>
            You don't have any migrations yet. Use the button above to start one.
          </PanelRow>
        )
        : (
          <GridPanelRow>
            <UserMigrationsGrid userMigrations={props.userMigrations}/>
          </GridPanelRow>
        )
      }
    </Panel>
  );
};

interface NewMigrationProps {
  onSubmit: (batch: boolean) => void;
}

const NewMigrationTool: React.FunctionComponent<NewMigrationProps> = (props) => {
  const [type, setType] = React.useState<boolean>();

  return (
    <>
      <GrandOption
        name={"single"}
        value={false}
        checked={type === false}
        onChange={() => setType(false)}
        description={
          "Select this option to migrate the contents of an old user account to a new account. " +
          "Works for personal, educational, or small business needs."
        }
      >
        Migrate a single user account to another account
      </GrandOption>
      <GrandOption
        name={"batch"}
        value={true}
        checked={type === true}
        onChange={() => setType(true)}
        description={
          "Select this option to run simultaneous migrations of multiple user accounts. " +
          "Intended for admins and business owners."
        }
      >
        Migrate or archive multiple user accounts (advanced)
      </GrandOption>
      <Block>
        <Button
          disabled={type === undefined}
          onClick={() => {
            if (type !== undefined) {
              props.onSubmit(type);
            }
          }}
        >
          Next &nbsp;&rsaquo;
        </Button>
      </Block>
    </>
  );
};
