import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProject } from "../../types/models/offboardingProject";

export function useGetOffboardingProjectQuery(
  id: string
): ManagedQueryHook<GraphQL.GetOffboardingProjectQueryVariables, OffboardingProject> {
  return useManagedQuery({
    query: GraphQL.useGetOffboardingProjectQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetOffboardingProjectQuery) => nullToUndefined(data.offboardingProject),
    complete: (offboardingProject) => OffboardingProject.parse(offboardingProject),
    fetchPolicy: "cache-and-network"
  });
}
