import { None, Option, Some } from "../../utils/monads/option";
import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const path: string = "path";
}

namespace SearchParams {
  export const sourceCloudServiceId = "sourceCloudServiceId";
  export const destinationCloudServiceId = "destinationCloudServiceId";
  export const forceSingleMigration = "forceSingleMigration";
  export const couponCode = "couponCode";
}

export class MigrationSetupRoutes {
  private readonly root = "setup";

  constructor(private context: RoutesContext) {}

  public params(): MigrationSetupRoutes.Params {
    return {
      path: Option.mayBe(this.context.routeParams[RouteParams.path]),
      sourceCloudServiceId:
        Option.mayBe(this.context.searchParams.get(SearchParams.sourceCloudServiceId)),
      destinationCloudServiceId:
        Option.mayBe(this.context.searchParams.get(SearchParams.destinationCloudServiceId)),
      forceSingleMigration: !!this.context.searchParams.get(SearchParams.forceSingleMigration),
      couponCode: Option.mayBe(this.context.searchParams.get(SearchParams.couponCode))
    };
  }

  public get homeMask(): string {
    return this.root;
  }

  public get wizardPathMask(): string {
    return this.root + "/:" + RouteParams.path + "/*";
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public get sourcePath(): string {
    return this.context.resolvePath(this.root + "/" + MigrationSetupRoutes.source);
  }

  public pathForParams(params: MigrationSetupRoutes.Params): string {
    return this.context.resolvePath(
        this.root +
        params.path
          .map((wizardPath) => (wizardPath.startsWith("/") ? wizardPath : "/" + wizardPath))
          .getOrUse("")
      ) +
      this.context.renderOptionalSearchParams([
        [SearchParams.forceSingleMigration, params.forceSingleMigration ? Some("true") : None()],
        [SearchParams.sourceCloudServiceId, params.sourceCloudServiceId],
        [SearchParams.destinationCloudServiceId, params.destinationCloudServiceId],
        [SearchParams.couponCode, params.couponCode],
      ]);
  }

  public wizardPath(wizardPath: string): string {
    return this.pathForParams(MigrationSetupRoutes.Params.forWizardPath(wizardPath));
  }

  public get singleMigrationHomePath(): string {
    return this.pathForParams({
      ...MigrationSetupRoutes.Params.empty,
      forceSingleMigration: true
    });
  }

  public preConfiguredSingleMigrationHomePath(sourceCloudServiceId: string, destinationCloudServiceId: string): string {
    return this.pathForParams({
      ...MigrationSetupRoutes.Params.empty,
      sourceCloudServiceId: Some(sourceCloudServiceId),
      destinationCloudServiceId: Some(destinationCloudServiceId),
      forceSingleMigration: true
    });
  }
}

export namespace MigrationSetupRoutes {
  export const source = "source";

  export interface Params {
    path: Option<string>;
    sourceCloudServiceId: Option<string>;
    destinationCloudServiceId: Option<string>;
    forceSingleMigration: boolean;
    couponCode: Option<string>;
  }

  export namespace Params {
    export const empty: Params = {
      path: None(),
      sourceCloudServiceId: None(),
      destinationCloudServiceId: None(),
      forceSingleMigration: false,
      couponCode: None()
    };

    export function forWizardPath(wizardPath: string): Params {
      return {
        ...empty,
        path: Some(wizardPath)
      };
    }
  }
}
