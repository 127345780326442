import * as React from "react";
import * as ReactDOM from "react-dom";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "./styledComponentsProps";
import { AdminToolsMode } from "../../state/settings/state";

const Id = "pinned-content";

interface PinnedContentProps {
  blueprintExplorerSize: AdminToolsMode;
}

const Div = styled.div<PinnedContentProps>`
  background: none;
  position: fixed;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.layers.header};
  width: ${(props) => props.blueprintExplorerSize === AdminToolsMode.Hidden ? 100 : 40}%;
  
  top: ${(props) => props.theme.layout.header.desktop}rem;
  
  ${(props) => props.theme.responsive.respondToSmall(
    props.blueprintExplorerSize === AdminToolsMode.SplitScreen
  )} {
    top: ${(props) => props.theme.layout.header.mobile}rem;
  }
`;

export const PinnedContent: React.FunctionComponent<PinnedContentProps & StyledComponentsProps> = (props) => (
  <Div
    id={Id}
    className={props.className}
    blueprintExplorerSize={props.blueprintExplorerSize}
  />
);

export const PinnedContentPortal: React.FunctionComponent = (props) => {
  const container = document.getElementById(Id);
  if (container) {
    return ReactDOM.createPortal(props.children, container);
  } else {
    return null;
  }
};
