import { Collectable } from "../collectables/collectable";
import { GraphQL } from "../../services/graphql/generated";
import { CollectableInteger } from "../collectables/collectableInteger";
import { CollectableBoolean } from "../collectables/collectableBoolean";
import { CollectableItemBreakdown } from "../collectables/collectableItemBreakdown";
import { parseCollectable } from "../collectables/parseCollectable";
import { ParsingError } from "../errors/parsingError";

export type Variant = boolean | number | Collectable.Any;

export namespace Variant {
  export function fromGraphQL(variant: GraphQL.VariantFragment): Variant {
    switch (variant.__typename) {
      case "VariantBoolean":
        return variant.booleanValue;

      case "VariantLong":
        return variant.longValue;

      case "CollectableBoolean":
      case "CollectableInteger":
      case "CollectableItemBreakdown":
        return parseCollectable(variant);

      case undefined:
        throw new ParsingError("Missing variant type");
    }
  }
}
