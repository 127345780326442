import * as React from "react";
import { Grid } from "../../widgets/grid";
import { preciseTimestamp } from "../../../utils/formatting";
import { List } from "immutable";
import { JobHistoryRecord } from "../../../types/models/jobHistoryRecord";
import { JobHistory } from "../../../types/models/jobHistory";
import { TaskSummaryRecord } from "../../../types/models/taskSummaryRecord";
import { Images } from "../../../app/images";
import { TaskIssue } from "../../../types/models/taskIssue";
import { TaskIssueEventRow } from "./taskIssueEventRow";
import { Link } from "react-router-dom";
import { JobHistoryGridIcon, JobHistoryGridSubject, taskResultTypeIcon, taskResultTypeStyle } from "./utils";
import { WidgetStatus } from "../../utils/widgetStatus";
import { useRoutes } from "../../../app/routes/useRoutes";

interface JobEventRowProps {
  event: JobHistoryRecord;
  prevEvent: JobHistoryRecord | undefined;
  flash: boolean;
}

const JobEventRow: React.FunctionComponent<JobEventRowProps> = (props) => (
  <>
    <Grid.Cell flash={props.flash}>{preciseTimestamp(props.event.updatedAt)}</Grid.Cell>
    <Grid.Cell flash={props.flash}>
      <JobHistoryGridSubject>
        <JobHistoryGridIcon src={Images.Jobs.Job}/>
        <div>Job "{props.event.id}"</div>
      </JobHistoryGridSubject>
    </Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.transition}</Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.updateSummary || "--"}</Grid.Cell>
    <Grid.Cell flash={props.flash}>{props.event.updatedBy || "--"}</Grid.Cell>
    <Grid.Cell
      status={
        props.prevEvent && props.prevEvent.internalStatus !== props.event.internalStatus
          ? WidgetStatus.Info
          : undefined
      }
      flash={props.flash}
    >
      {props.event.internalStatus}
    </Grid.Cell>
    <Grid.Cell
      status={
        !props.event.currentStatus
          ? WidgetStatus.Uncertain
          : props.prevEvent && props.prevEvent.currentStatus !== props.event.currentStatus
          ? WidgetStatus.Info
          : undefined
      }
      flash={props.flash}
    >
      {props.event.currentStatus || "(None)"}
    </Grid.Cell>
    <Grid.Cell
      status={
        props.prevEvent && props.prevEvent.desiredStatus !== props.event.desiredStatus
          ? WidgetStatus.Info
          : undefined
      }
      flash={props.flash}
    >
      {props.event.desiredStatus}
    </Grid.Cell>
    <Grid.Cell
      status={props.prevEvent && props.prevEvent.nextStatus !== props.event.nextStatus ? WidgetStatus.Info : undefined}
      flash={props.flash}
    >
      {props.event.nextStatus || "--"}
    </Grid.Cell>
    <Grid.JsonCell
      version={props.event.stateVersion}
      status={props.prevEvent && props.prevEvent.stateJson !== props.event.stateJson ? WidgetStatus.Info : undefined}
      flash={props.flash}
    >
      {props.event.stateJson}
    </Grid.JsonCell>
  </>
);

interface TaskEventRowProps {
  event: TaskSummaryRecord.Event;
  flash: boolean;
}

const TaskEventRow: React.FunctionComponent<TaskEventRowProps> = (props) => {
  const routes = useRoutes();

  return (
    <>
      <Grid.Cell flash={props.flash}>{preciseTimestamp(props.event.timestamp)}</Grid.Cell>
      <Grid.Cell flash={props.flash}>
        <JobHistoryGridSubject>
          <JobHistoryGridIcon src={taskResultTypeIcon(props.event.resultType) || Images.Jobs.TaskStarted}/>
          <Link to={routes.jobs.taskPath(props.event.jobId, props.event.taskId)}>
            Task "{props.event.taskId}" ({props.event.type})
          </Link>
        </JobHistoryGridSubject>
      </Grid.Cell>
      <Grid.Cell flash={props.flash}>{props.event.transition}</Grid.Cell>
      <Grid.Cell status={taskResultTypeStyle(props.event.resultType)} flash={props.flash}>
        {props.event.eventType + (props.event.resultType ? " (" + props.event.resultType + ")" : "")}
      </Grid.Cell>
      <Grid.Cell flash={props.flash}/>
      <Grid.Cell flash={props.flash}/>
      <Grid.Cell flash={props.flash}/>
      <Grid.Cell flash={props.flash}/>
      <Grid.Cell flash={props.flash}/>
      <Grid.Cell flash={props.flash}/>
    </>
  );
};

interface Props {
  events: List<JobHistory.Event>;

  showJobEvents: boolean;
  showRunningTasks: boolean;
  showCompletedTasks: boolean;
  showPendingIssues: boolean;
  showResolvedIssues: boolean;
}

export const JobHistoryEventsGrid: React.FunctionComponent<Props> = (props) => {
  const highlightNewRows = React.useRef(props.events.size);

  React.useEffect(() => {
    highlightNewRows.current = props.events.size;
  });
  const newRows = props.events.size - highlightNewRows.current;

  return (
    <Grid>
      <Grid.Header>
        <Grid.Column>Timestamp</Grid.Column>
        <Grid.Column>Subject</Grid.Column>
        <Grid.Column>Transition</Grid.Column>
        <Grid.Column>Update Summary</Grid.Column>
        <Grid.Column>Updated By</Grid.Column>
        <Grid.Column>System Status</Grid.Column>
        <Grid.Column>Current Status</Grid.Column>
        <Grid.Column>Desired Status</Grid.Column>
        <Grid.Column>Scheduled Status</Grid.Column>
        <Grid.Column>State</Grid.Column>
      </Grid.Header>
      <Grid.Body>
        {
          props.events.map((event, index) => (
            <Grid.Row key={event.key}>
              {
                event instanceof JobHistoryRecord && props.showJobEvents && (
                  <JobEventRow
                    event={event}
                    prevEvent={
                      props.events
                        .takeLast(props.events.size - index - 1)
                        .find((prevEvent) => prevEvent instanceof JobHistoryRecord, undefined) as
                        (JobHistoryRecord | undefined)
                    }
                    flash={index < newRows}
                  />
                )
              }
              {
                event instanceof TaskSummaryRecord.Event &&
                (
                  props.showRunningTasks && event.taskIsRunning ||
                  props.showCompletedTasks && !event.taskIsRunning
                ) &&
                (
                  <TaskEventRow
                    event={event}
                    flash={index < newRows}
                  />
                )
              }
              {
                event instanceof TaskIssue.Event &&
                (
                  props.showPendingIssues && event.issueIsPending ||
                  props.showResolvedIssues && !event.issueIsPending
                ) &&
                (
                  <TaskIssueEventRow
                    event={event}
                    flash={index < newRows}
                    columnCount={10}
                  />
                )
              }
            </Grid.Row>
          ))
        }
      </Grid.Body>
    </Grid>
  );
};
