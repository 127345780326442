import * as React from "react";
import marked from "marked";
import { styled } from "../../app/theme";

const Container = styled.div`
  p, ul, ol {
    margin: .5rem 0;
  }
  
  ol, ul {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 2rem;
  }
  
  strong {
    font-weight: ${(props) => props.theme.font.medium};
  }
  
  > *:first-child {
    margin-top: 0;
  }
  
  > *:last-child {
    margin-bottom: 0;
  }
`;

marked.use({
  renderer: {
    link(href: string | null, title: string | null, text: string): string {
      return `<a href=\"${href}\" target="_blank">${text}</a>`;
    }
  }
});

interface Props {
  markdown?: string;
  children?: string;
}

export const Markdown: React.FunctionComponent<Props> = (props) => {
  const html = props.markdown || props.children && marked(props.children);
  return <Container dangerouslySetInnerHTML={html !== undefined ? { __html: html } : undefined}/>;
};
