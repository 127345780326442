import { School } from "../../types/models/school";
import { CRUDRoutes } from "./crudRoutes";

export class SchoolsRoutes extends CRUDRoutes<string, School> {
  protected readonly root: string = "schools";

  protected parseId(s: string): string {
    return s;
  }
}
