import * as yup from "yup";
import { GraphQL } from "../../services/graphql/generated";

export interface OneTimeAuthCodeSummary {
  code: string;
  expiresAt: Date;
}

export namespace OneTimeAuthCodeSummary {
  export const schema: yup.ObjectSchema<OneTimeAuthCodeSummary> = yup.object().shape({
    code: yup.string().required(),
    expiresAt: yup.date().required()
  });

  export function fromGraphQL(oneTimeAuthCodeSummary: GraphQL.OneTimeAuthCodeSummary): OneTimeAuthCodeSummary {
    return {
      code: oneTimeAuthCodeSummary.code,
      expiresAt: new Date(oneTimeAuthCodeSummary.expiresAt)
    };
  }
}
