import * as React from "react";
import { Field, FieldProps } from "formik";
import {
  ConnectEmailDeliveryAccountController
} from "../../../../controllers/organizationPortal/automatedOffboarding/connectEmailDeliveryAccountController";
import { ControllerHost } from "../../../../utils/controller";
import { ConnectEmailDeliveryAccountTool } from "./connectEmailDeliveryAccountTool";
import { PanelRow } from "../../../containers/rows/panelRow";
import { LinkButton } from "../../../widgets/linkButton";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { EmailDeliveryConnectionDescription } from "./emailDeliveryConnectionDescription";
import { Paragraph } from "views/widgets/paragraph";
import { styled } from "../../../../app/theme";
import { BulletPoints } from "../../../widgets/bulletPoints";
import { commafy } from "../../../../utils/formatting";
import { Constants } from "../../../../app/constants";

const Restrictions: React.FunctionComponent = () => (
  <>
    <Paragraph>
      The following restrictions will apply:
    </Paragraph>
    <BottomlessBulletPoints>
      <li>
        Each account can only be used with one project. If you want to run multiple projects in parallel,
        connect a separate account to each of them.
      </li>
      <li>
        Connecting a Google Workspace account will limit the number of entries that can be added to
        this project to {commafy(Constants.AutomatedOffboarding.MaxGmailEntryCount)}.
      </li>
      <li>
        Connecting a Microsoft 365 account will limit the number of entries that can be added to
        this project to {commafy(Constants.AutomatedOffboarding.MaxOutlookEntryCount)}.
      </li>
    </BottomlessBulletPoints></>
);

interface Props<V> {
  name: keyof V;

  controller: ConnectEmailDeliveryAccountController;
  connection: OffboardingProject.Connection | undefined;
  isNew: boolean;
  hasConnectionError: boolean;

  disabled?: boolean;
}

export function EmailDeliveryAccountField<V>(props: Props<V>): React.ReactElement {
  const [updating, setUpdating] = React.useState(false);

  function renderPrompt() {
    if (props.isNew) {
      return (
        <PanelRow>
          <Paragraph>
            VaultMe needs access to a Google Workspace or Microsoft 365 account to send emails on behalf
            of your organization.
          </Paragraph>
          <Paragraph>
            Use one of the sign-in options below to connect an account and enable email delivery.
          </Paragraph>
          <Restrictions/>
        </PanelRow>
      );
    } else if (!props.connection) {
      return (
        <PanelRow status={WidgetStatus.Warning}>
          <Paragraph>
            Use one of the sign-in options below to connect an account and enable email delivery.
          </Paragraph>
          <Restrictions/>
        </PanelRow>
      );
    } else if (props.hasConnectionError) {
      return (
        <PanelRow status={WidgetStatus.Error}>
          VaultMe has lost access to the account used to send emails ({props.connection.description}).
          Please re-connect the account using one of the sign-in options below.
        </PanelRow>
      );
    } else {
      return (
        <PanelRow>
          <EmailDeliveryConnectionDescription connection={props.connection}/>{" "}
          {!updating && (
            <LinkButton onClick={() => setUpdating(true)}>
              Select a different account
            </LinkButton>
          )}
        </PanelRow>
      );
    }
  }

  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const hasValue = !!field.form.values[props.name];

        if (hasValue) {
          return (
            <PanelRow status={WidgetStatus.Info}>
              An account has been selected. Save the changes to complete the connection process.{" "}
              <LinkButton
                onClick={() => {
                  field.form.setFieldError(field.field.name, "");
                  field.form.setFieldValue(field.field.name, undefined);
                }}
              >
                Select a different account
              </LinkButton>
            </PanelRow>
          );
        } else {
          return (
            <>
              {renderPrompt()}
              {(props.isNew || !props.connection || props.hasConnectionError || updating) && (
                <ControllerHost
                  controller={props.controller}
                  render={(viewProps) => (
                    <ConnectEmailDeliveryAccountTool
                      {...viewProps}
                      onSignIn={(oneTimeAuthCodeSummary, cloudServiceId) => {
                        field.form.setFieldError(field.field.name, "");
                        field.form.setFieldValue(field.field.name, {
                          oneTimeAuthCode: oneTimeAuthCodeSummary.code,
                          cloudServiceId
                        });
                      }}
                    />
                  )}
                />
              )}
            </>
          );
        }
      }}
    />
  );
}

export const BottomlessBulletPoints = styled(BulletPoints)`
  margin-bottom: 0;

  li:last-child {
    margin-bottom: 0;
  }
`;
