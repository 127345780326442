import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { JobHistory } from "../../types/models/jobHistory";

export function useJobHistory(
  jobId: string,
  includeMinorUpdates: boolean
): ManagedQueryHook<GraphQL.GetJobHistoryQueryVariables, JobHistory> {
  return useManagedQuery({
    query: GraphQL.useGetJobHistoryQuery,
    deps: null,
    prepare: () => ({ jobId, includeMinorUpdates }),
    extract: (data: GraphQL.GetJobHistoryQuery) => data.getJobHistory,
    // Do we really need memoDeps? :-\
    // memoDeps: (extract) => [
    //   extract,
    //   extract && extract.jobHistory.length,
    //   extract && extract.tasks.length,
    //   extract && extract.taskIssues.length,
    // ],
    complete: JobHistory.fromGraphQL,
  });
}
