import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Panel } from "views/containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Button } from "../../../widgets/button";
import { ModalController, ModalHook } from "../../../layouts/modal";
import { ControllerHost } from "../../../../utils/controller";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { DrawerBlock } from "views/containers/drawerBlock";
import {
  RenderOffboardingEmailPreviewController, RenderOffboardingEmailPreviewViewProps
} from "../../../../controllers/organizationPortal/automatedOffboarding/renderOffboardingEmailPreviewController";
import { multiline } from "../../../../utils/formatting";
import { styled } from "../../../../app/theme";
import {
  SendTestOffboardingProjectEmailController
} from "../../../../controllers/organizationPortal/automatedOffboarding/sendTestOffboardingProjectEmailController";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { OperationStatus } from "../../../../types/operationStatus";
import { EmailPreview } from "../../../../types/models/emailPreview";
import { DrawerContent } from "../../../utils/drawerContent";
import { WidgetStatus } from "../../../utils/widgetStatus";

interface Props {
  offboardingProject: OffboardingProject;

  renderPreviewController: RenderOffboardingEmailPreviewController;
  sendTestEmailController: SendTestOffboardingProjectEmailController;

  modal: ModalHook;
  round: number;
}

export const PreviewOffboardingEmailTool: React.FunctionComponent<Props> = (props) => (
  <ModalController
    modal={props.modal}
    title={"Email Preview"}
    render={(close) =>
      <ControllerHost
        controller={props.renderPreviewController}
        render={(renderPreviewViewProps) => (
          <ControllerHost
            controller={props.sendTestEmailController}
            render={(sendTestEmailViewProps) => (
              <Body
                round={props.round}

                previewStatus={renderPreviewViewProps.status}
                onPreview={() => renderPreviewViewProps.onPreview(props.round)}

                sendTestEmailStatus={sendTestEmailViewProps.status}
                onSendTestEmail={() => sendTestEmailViewProps.onSend(props.round)}

                onClose={close}
              />
            )}
          />
        )}
      />
    }
  />
);

interface BodyProps {
  round: number;

  previewStatus: OperationStatus<EmailPreview>;
  onPreview: () => Promise<EmailPreview>;

  sendTestEmailStatus: OperationStatus<EmailPreview>;
  onSendTestEmail: () => void;

  onClose: () => void;
}

const Body: React.FunctionComponent<BodyProps> = (props) => {
  React.useEffect(
    () => {
      props.onPreview();
    },
    [props.round]
  );

  if (props.sendTestEmailStatus.isSuccess()) {
    return (
      <>
        <DrawerBlock>
          <PanelRow status={WidgetStatus.Success}>
            A test email has been sent to {props.sendTestEmailStatus.result.to}.
          </PanelRow>
        </DrawerBlock>
        <DrawerBlock>
          <Button size={"narrow"} onClick={props.onClose}>Close</Button>
        </DrawerBlock>
      </>
    );
  } else {
    return (
      <>
        <DrawerBlock>
          <Panel>
            <OperationStatusIndicator
              progressMessage={"Preparing email preview..."}
              failureMessage={"Failed to prepare email preview"}
              status={props.previewStatus}
              indicators={StatusIndicators.PanelRow()}
            />
            {props.previewStatus.mapLastResult((preview) => (
              <PanelRow>
                <SubjectLine>{preview.subject}</SubjectLine>
                <From>{preview.from}</From>
                <To>to {preview.to}</To>
                <DrawerContent html={preview.body}/>
              </PanelRow>
            ))}
            <OperationStatusIndicator
              progressMessage={"Sending a test email..."}
              failureMessage={"Failed to send a test email"}
              successMessage={(result) => "Sent a test email to " + result.to + "."}
              status={props.sendTestEmailStatus}
              indicators={StatusIndicators.PanelRow({ keepSuccessIndicator: false })}
            />
          </Panel>
        </DrawerBlock>
        <DrawerBlock>
          <SimpleToolbar>
            <Button color={"primary"} size={"narrow"} onClick={props.onClose}>Close</Button>
            <Button
              color={"secondary"}
              size={"narrow"}
              onClick={props.onSendTestEmail}
              disabled={props.sendTestEmailStatus.isWorking()}
            >
              Send A Test Email
            </Button>
          </SimpleToolbar>
        </DrawerBlock>
      </>
    );
  }
};

const SubjectLine = styled.div`
  font-size: 1.375rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

const From = styled.div`
  font-weight: 500;
  margin-bottom: 0.3rem;
`;

const To = styled.div`
  font-size: 0.8rem;
  color: #888888;
  margin-bottom: 1.5rem;
`;
