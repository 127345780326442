import * as React from "react";
import { styled } from "../../../app/theme";
import { Button } from "../../widgets/button";
import { TextBox } from "../../widgets/textBox";

// Current search terms are stored in the parent's state so that they don't get list after hiding the search bar
interface UserSearchProps {
  searchTerm: string;
  disabled: boolean;

  onSearchTermChange: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
}

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-left: .5rem;
`;

// Eh... Why is this outside of the component?!
const textBoxRef: React.RefObject<HTMLInputElement> = React.createRef();

export const UserSearch: React.FunctionComponent<UserSearchProps> = (props) => {
  React.useEffect(
    () => {
      if (!props.disabled) {
        window.setTimeout(
          ()  => {
            if (textBoxRef.current) {
              textBoxRef.current.focus();
            }
          },
          0
        );
      }
    },
    [props.disabled]
  );

  return (
    <Layout>
      <TextBox
        ref={textBoxRef}
        autoFocus={true}
        value={props.searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSearchTermChange(event.target.value)}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.charCode === 13 && props.searchTerm.length >= 2) {
            props.onSearch(props.searchTerm);
            event.preventDefault();
          }
        }}
        placeholder="Please type a search criteria for a user (name, email address or internal ID)"
        disabled={props.disabled}
      />
      <ButtonContainer>
        <Button
          color="blue"
          size="small"
          disabled={props.disabled || props.searchTerm.length < 2}
          onClick={() => props.onSearch(props.searchTerm)}
        >
          Search
        </Button>
      </ButtonContainer>
    </Layout>
  );
};
