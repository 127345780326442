import * as React from "react";
import { PanelRow } from "../containers/rows/panelRow";
import {
  Indicator,
  Indicators,
  ItemCountIndicatorValue,
  JsonIndicator, PriceIndicator,
  SimpleIndicator, SizeIndicatorValue
} from "../widgets/indicator";
import { Panel } from "../containers/panel";
import { BatchOrder } from "../../types/models/batchOrder";
import { OperationStatus } from "../../types/operationStatus";
import { Option } from "../../utils/monads/option";
import { DrawerBlock } from "../containers/drawerBlock";
import { CheckoutToolsView } from "./checkoutToolsView";
import { RevenueShareSourceType } from "../../types/enums/revenueShareSourceType";

interface Props {
  order: BatchOrder & BatchOrder.HasRevenueData;
}

export const BatchOrderDetailsPanelView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator
          title={"Transaction ID"}
          value={props.order.transactionId || "--"}
          parenthetical={
            props.order.braintreeTransactionDetailsUrl &&
              <a href={props.order.braintreeTransactionDetailsUrl} target={"_blank"} key={0}>View</a>
          }
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator title={"Pricing Model Name"} value={props.order.pricingModelName}/>
        <SimpleIndicator title={"Pricing Model Type"} value={props.order.pricingModelType}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <JsonIndicator
          title={"Pricing Model JSON"}
          value={props.order.pricingModelJson}
          version={props.order.pricingModelVersion}
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <Indicator title="Batch Size">
          <ItemCountIndicatorValue count={props.order.totalAccounts} unit={"user account"}/>
          <ItemCountIndicatorValue count={props.order.totalItems} unit={"item"}/>
          <SizeIndicatorValue size={props.order.totalBytes}/>
        </Indicator>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <PriceIndicator title={"Accounts Fee"} price={props.order.accountsFee}/>
        <PriceIndicator title={"Items Fee"} price={props.order.itemsFee}/>
        <PriceIndicator title={"Bytes Fee"} price={props.order.bytesFee}/>
        <PriceIndicator title={"Referral Code Discount"} price={props.order.referralCodeDiscount?.amount}/>
        <PriceIndicator title={"Coupon Code Discount"} price={props.order.couponCodeDiscount?.amount}/>
        <PriceIndicator title={"Amount To Be Paid"} price={props.order.amountToBePaid}/>
        <PriceIndicator title={"Paid Amount"} price={props.order.paidAmount}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <SimpleIndicator
          title={"Referral Code"}
          value={props.order.referralCodeDiscount?.code}
          parenthetical={
            props.order.referralCodeDiscount
              ? props.order.referralCodeDiscount.percentage + "%"
              : undefined
          }
        />
        <SimpleIndicator
          title={"Coupon Code"}
          value={props.order.couponCodeDiscount?.code}
          parenthetical={
            props.order.couponCodeDiscount
              ? (
                props.order.couponCodeDiscount.isPercentage
                  ? props.order.couponCodeDiscount.value + "%"
                  : "$" + props.order.couponCodeDiscount.value
              )
              : undefined
          }
        />
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <PriceIndicator title={"Revenue"} price={props.order.revenue}/>
        <SimpleIndicator
          title={"Revenue Share Source Type"}
          value={
            props.order.revenueShare && RevenueShareSourceType.toFriendlyString(props.order.revenueShare.sourceType)
          }
        />
        <SimpleIndicator title={"Revenue Share Source ID"} value={props.order.revenueShare?.sourceId}/>
        <SimpleIndicator
          title={"Revenue Share Percentage"}
          value={props.order.revenueShare ? props.order.revenueShare.percentage + "%" : undefined}
        />
        <PriceIndicator title={"Revenue Share Amount"} price={props.order.revenueShare?.amount}/>
      </Indicators>
    </PanelRow>

    <PanelRow size={"smaller"}>
      <Indicators size={"small"}>
        <JsonIndicator title={"Price Breakdown"} value={props.order.priceBreakdown}/>
      </Indicators>
    </PanelRow>
  </Panel>
);

interface BatchCheckoutOrderViewProps {
  status: OperationStatus<BatchOrder & BatchOrder.HasRevenueData>;
  isWorking: boolean;

  onExternalPayment: (amount: number, note: Option<string>) => void;
}

export const BatchCheckoutOrderView: React.FunctionComponent<BatchCheckoutOrderViewProps> = (props) => (
  <CheckoutToolsView
    status={props.status.map((result) => result.amountToBePaid)}
    isWorking={props.isWorking}
    onExternalPayment={props.onExternalPayment}
  >
    {props.status.isSuccess() && (
      <DrawerBlock>
        <BatchOrderDetailsPanelView order={props.status.result}/>
      </DrawerBlock>
    )}
  </CheckoutToolsView>
);
