import { AccessList } from "../../types/models/accessList";
import { CRUDRoutes } from "./crudRoutes";
import { RoutesContext } from "./routesContext";

export class AccessListsRoutes extends CRUDRoutes<number, AccessList> {
  constructor(context: RoutesContext, public readonly root: string) {
    super(context);
  }

  public importPath(id: number): string {
    return this.context.resolvePath(this.root + "/" + AccessListsRoutes.importRoot + "/" + id);
  }

  public importParams(): AccessListsRoutes.ImportParams {
    const id = this.context.routeParams[AccessListsRoutes.Params.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public importHistoryPath(id: number): string {
    return this.context.resolvePath(this.root + "/" + AccessListsRoutes.importHistoryRoot + "/" + id);
  }

  public importHistoryParams(): AccessListsRoutes.ImportHistoryParams {
    const id = this.context.routeParams[AccessListsRoutes.Params.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public deleteEntriesPath(id: number): string {
    return this.context.resolvePath(this.root + "/" + AccessListsRoutes.deleteEntriesRoot + "/" + id);
  }

  protected parseId(s: string): number {
    return Number.parseInt(s, 10);
  }
}

export namespace AccessListsRoutes {
  export namespace Params {
    export const id: string = "id";
  }

  export const importRoot = "import";
  export const importMask = importRoot + "/:" + Params.id;

  export const importHistoryRoot = "import-history";
  export const importHistoryMask = importHistoryRoot + "/:" + Params.id;

  export const deleteEntriesRoot = "remove-entries";
  export const deleteEntriesMask = deleteEntriesRoot + "/:" + Params.id;

  export interface ImportParams {
    id: number;
  }

  export interface ImportHistoryParams {
    id: number;
  }
}
