import * as React from "react";
import { Omit } from "react-redux";
import { ClickableComponent } from "./clickableComponent";
import { DrawerBackground } from "../layouts/drawer";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";

export interface HasClickHandler<T = any> {
  onClick?: (event: React.MouseEvent<T>) => void;
}

export type SimpleClickHandler = string | (() => void) | PreparedHelpArticle | React.ReactNode;

export interface HasSimpleClickHandler {
  onSimpleClick?: SimpleClickHandler;
  drawerBackground?: DrawerBackground;
}

export function withSimpleClickHandler<T extends object & HasClickHandler>(
  component: React.ComponentType<T>,
  defaultDrawerBackground?: DrawerBackground):
  React.ComponentType<Omit<T, keyof HasClickHandler> & HasSimpleClickHandler> {
  return (props: object & HasSimpleClickHandler) => {
    const { onSimpleClick, drawerBackground, ...rest } = props;
    return React.createElement(ClickableComponent, {
      component: component as any as React.ComponentType<HasClickHandler>,
      props: rest,
      onSimpleClick,
      drawerBackground: drawerBackground || defaultDrawerBackground
    });
  };
}
