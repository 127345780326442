import * as React from "react";
import { useSimpleLookupController } from "../../simpleLookupController";
import { Theme } from "../../../types/models/theme";
import { useSearchThemesQuery } from "../../../queries/themes/useSearchThemesQuery";
import {
  OrganizationThemeLookupControllerTypeFactory
} from "../../../views/blocks/lookups/themes/themeLookupField";

export function useOrganizationThemeLookupControllerFactory(): OrganizationThemeLookupControllerTypeFactory {
  return (organizationId: number | undefined) => {
    const getItemId = React.useCallback((item: Theme) => item.id, []);

    const comparator = React.useCallback(
      (a: Theme, b: Theme) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      },
      []
    );

    const useSearchQuery = React.useCallback(
      (theme) => useSearchThemesQuery(organizationId, theme),
      [organizationId]
    );

    return useSimpleLookupController<number, Theme>({
      getItemId,
      comparator,
      listAll: true,
      useSearchQuery
    });
  };
}
