import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ReferralCode } from "../../types/models/referralCode";

export function useGetReferralCodeQuery(
  id: number
): ManagedQueryHook<GraphQL.GetReferralCodeQueryVariables, ReferralCode> {
  return useManagedQuery({
    query: GraphQL.useGetReferralCodeQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetReferralCodeQuery) => nullToUndefined(data.referralCode),
    complete: (referralCode) => ReferralCode.parse(referralCode),
    fetchPolicy: "cache-and-network"
  });
}
