import * as React from "react";
import { Step } from "../../views/containers/step";
import { Block } from "../../views/containers/block";
import { Panel } from "../../views/containers/panel";
import { PanelRow } from "../../views/containers/rows/panelRow";
import { LinkButton } from "../../views/widgets/linkButton";

export enum Value {
  Some = "Some value",
  SomeOther = "Some other value",
  Invalid = "Invalid value",
  ErrorIndicator = "Displays error indicator",
  InitErrorOnThisStep = "Init error on this step",
  InitErrorOnNextStep = "Init error on next step"
}

interface Result {
  value: Value;
}

interface SandboxStep1Props {
  currentValue: string | undefined;
  onComplete: (result: Result) => void;
  onApply: (result: Result) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const SandboxStep1: React.FunctionComponent<SandboxStep1Props> = (props) => (
  <Step
    title="Step 1"
    onSubmit={() => props.onComplete({ value: Value.Some })}
    submitHidden={true}
    onNavigateBack={props.onNavigateBack}
    noForm={true}
  >
    <Block>
      <Panel>
        <PanelRow>
          Current value: {props.currentValue}
        </PanelRow>
      </Panel>
    </Block>

    <Block>
      <Panel>
        <PanelRow>
          <LinkButton onClick={() => props.onComplete({ value: Value.Some })}>
            Complete with a regular value
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onComplete({ value: Value.Invalid })}>
            Complete with a non-valid value
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onComplete({ value: Value.ErrorIndicator })}>
            Complete with a value that triggers an error indicator
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onComplete({ value: Value.InitErrorOnThisStep })}>
            Complete with a value that provokes an init error on this step
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onComplete({ value: Value.InitErrorOnNextStep })}>
            Complete with a value that provokes an init error on the next step
          </LinkButton>
        </PanelRow>
      </Panel>
    </Block>

    <Block>
      <Panel>
        <PanelRow>
          <LinkButton onClick={() => props.onApply({ value: Value.Some })}>
            Apply a regular value
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onApply({ value: Value.Invalid })}>
            Apply a non-valid value
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onApply({ value: Value.ErrorIndicator })}>
            Apply a value that triggers an error indicator
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onApply({ value: Value.InitErrorOnThisStep })}>
            Apply a value that provokes an init error on this step
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onApply({ value: Value.InitErrorOnNextStep })}>
            Apply a value that provokes an init error on the next step
          </LinkButton>
        </PanelRow>
      </Panel>
    </Block>

    <Block>
      <Panel>
        <PanelRow>
          <LinkButton onClick={props.onClear}>
            Clear
          </LinkButton>
        </PanelRow>
      </Panel>
    </Block>
  </Step>
);
