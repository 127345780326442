import { identity } from "../../utils/misc";

type IdPart = string | number;

type GeneratedCacheId = IdPart | IdPart[] | { __typename: string, parts: IdPart[] };

export class CacheIdGenerator<T, Comps> {
  constructor(
    readonly typename: string,
    readonly extract: ((object: T) => Comps),
    readonly generate: ((comps: Comps) => GeneratedCacheId)
  ) {}

  public forObject(object: T): string {
    return this.forComps(this.extract(object));
  }

  public forComps(comps: Comps): string {
    const result = this.generate(comps);
    if (typeof result === "string" || typeof result === "number") {
      return "{" + this.typename + "}" + result;
    } else if (result instanceof Array) {
      return "{" + this.typename + "}" + result.join(":");
    } else {
      return "{" + result.__typename + "}" + result.parts.join(":");
    }
  }
}

export namespace CacheIdGenerator {
  export function simple<T extends Comps, Comps>(
    typename: string,
    generate: ((comps: Comps) => GeneratedCacheId)
  ): CacheIdGenerator<Comps, Comps> {
    return new CacheIdGenerator(typename, identity, generate);
  }
}
