import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { Link } from "react-router-dom";
import { titleize } from "../../../utils/titleize";
import { FormConfig } from "./formConfig";
import { HeaderBlock } from "../../widgets/headerBlock";

interface Props<Item, FormData> {
  breadcrumbs?: React.ReactNode[];
  title: string;
  subject: string;
  readOnly: boolean;
  renderForm: (config: FormConfig<Item, FormData>) => React.ReactElement;

  template: Item | undefined;
  homePath: string;
  cancelPath: string;
  insertStatus: OperationStatus<Item>;

  onSubmit: (formData: FormData, continueEditing: boolean) => Promise<Item>;
}

export function NewItemPageView<Id, Item, FormData>(props: Props<Item, FormData>): React.ReactElement {
  return (
    <>
      <HeaderBlock
        breadcrumbs={
          (props.breadcrumbs || []).concat([<Link key={0} to={props.homePath}>{props.title}</Link>, "New"])
        }
        title={titleize("New " + props.subject)}
      />
      {props.renderForm({
        readOnly: props.readOnly,

        item: props.template,
        isNew: true,
        cancelPath: props.cancelPath,
        submitStatus: props.insertStatus,

        onSubmit: props.onSubmit
      })}
    </>
  );
}
