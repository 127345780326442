import * as React from "react";
import { AmbassadorStatus } from "../../types/models/ambassadorStatus";
import { OperationStatus } from "../../types/operationStatus";
import { StatusIndicators } from "../utils/statusIndicators";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { Block } from "../containers/block";
import { Panel } from "../containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { TitlePanelRow } from "../containers/rows/titlePanelRow";
import { Grid } from "../widgets/grid";
import { friendlyDateTime } from "../../utils/formatting";
import { List } from "immutable";
import { ContestEntry } from "../../types/models/contestEntry";
import { styled } from "../../app/theme";
import { ContestEvent } from "../../types/enums/contestEvent";
import { AmbassadorLinksPanel } from "../blocks/ambassadorLinksPanel";
import { CenteredContent } from "../layouts/centeredContent";
import { HeaderBlock } from "../widgets/headerBlock";
import { useAppBootstrapConfig } from "../../app/configuration";
import { SimplePanel } from "../containers/simplePanel";
import { NewTabLink } from "../widgets/newTabLink";
import { Constants } from "../../app/constants";

interface Props {
  loadingStatus: OperationStatus<AmbassadorStatus>;
}

export const AmbassadorDashboardPageView: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();
  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  const school = props.loadingStatus.isSuccess() ? props.loadingStatus.result.school : undefined;

  return (
    <CenteredContent>
      <HeaderBlock
        title={"EDU Ambassador Dashboard"}
        subTitle={
          school && (
            <>
              Score big by helping your friends learn how VaultMe can save their {school.commonName || "school"}{" "}
              content before their accounts are deleted!
              Every friend you help gets {codeDiscount}% off their EDU Migration Fee and gets you another shot
              at our monthly iPad giveaway (no purchase necessary;{" "}
              <NewTabLink to={Constants.Product.eduAmbassadorTermsUrl}>terms & conditions</NewTabLink> apply).
              {school.commonName ? " Go " + school.commonName + "!" : ""}
            </>
          )
        }
        // It doesn't seem to be legal to use school logo
        // logoId={school?.logoId}
      />
      <OperationStatusIndicator
        subject={"details"}
        status={props.loadingStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {props.loadingStatus.isSuccess() && (
        <>
          <SimplePanel>
            <PanelRow size={"small"}>
              <TotalContestEntriesIndicator>
                {props.loadingStatus.result.totalContestEntries}
              </TotalContestEntriesIndicator>
              <TotalContestEntriesPrompt>iPad giveaway entries earned</TotalContestEntriesPrompt>
            </PanelRow>
          </SimplePanel>
          <AmbassadorLinksPanel
            ambassadorCode={props.loadingStatus.result.ambassadorCode}
            school={props.loadingStatus.result.school}
            destinationConnection={undefined}
            numberOfFriendsHelped={undefined}
            showDashboardLink={false}
          />
          <ContestEntriesPanel
            totalContestEntries={props.loadingStatus.result.totalContestEntries}
            recentContestEntries={props.loadingStatus.result.recentContestEntries}
          />
        </>
      )}
    </CenteredContent>
  );
};

interface ContestEntriesPanelProps {
  totalContestEntries: number;
  recentContestEntries: List<ContestEntry>;
}

const ContestEntriesPanel: React.FunctionComponent<ContestEntriesPanelProps> = (props) => {
  const sum = props.recentContestEntries
    .map((entry) => entry.count)
    .reduce((a, b) => a + b, 0);

  function renderEventDescription(event: ContestEvent): string {
    switch (event) {
      case ContestEvent.SignedUp:
        return "You became an EDU Ambassador and sent emails to contacts from your educational institution";

      case ContestEvent.StartedMigration:
        return "You started a migration from an EDU account";

      case ContestEvent.FriendSignedIn:
        return "An EDU user used your link or code and signed into VaultMe with their EDU Account";

      case ContestEvent.FriendStartedMigration:
        return "An EDU user used your link or code to migrate their EDU Account";
    }
  }

  return (
    <Block>
      <Panel>
        <TitlePanelRow>Your iPad Giveaway Entries</TitlePanelRow>
        {/*<PanelRow>*/}
        {/*  <TotalContestEntriesIndicator>{props.totalContestEntries}</TotalContestEntriesIndicator>*/}
        {/*  <TotalContestEntriesPrompt>iPad giveaway entries earned</TotalContestEntriesPrompt>*/}
        {/*</PanelRow>*/}
        <GridPanelRow>
          <Grid>
            <Grid.Header>
              <Grid.Column>Date</Grid.Column>
              <Grid.Column>Event</Grid.Column>
              <Grid.Column>Number Of Entries Earned</Grid.Column>
            </Grid.Header>
            <Grid.Body>
              {props.recentContestEntries.map((entry, index) => (
                <Grid.Row key={index}>
                  <Grid.Cell nowrap={true}>{friendlyDateTime(entry.createdAt)}</Grid.Cell>
                  <Grid.Cell>{renderEventDescription(entry.event)}</Grid.Cell>
                  <Grid.Cell>{entry.count}</Grid.Cell>
                </Grid.Row>
              ))}
              {(sum < props.totalContestEntries) && (
                <Grid.Row>
                  <Grid.Cell colSpan={3}>...and more</Grid.Cell>
                </Grid.Row>
              )}
            </Grid.Body>
          </Grid>

        </GridPanelRow>
      </Panel>
    </Block>
  );
};

const TotalContestEntriesPrompt = styled.div`
  text-align: center;
  margin-top: -.7rem;
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.font.regular};
  text-transform: uppercase;
`;

const TotalContestEntriesIndicator = styled.div`
  margin-top: -1rem;
  font-size: 6rem;
  font-weight: ${(props) => props.theme.font.regular};
  text-align: center;
`;
