import * as React from "react";
import { BatchOrder } from "../../../types/models/batchOrder";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { BatchOrderDetailsPanelView } from "../../blocks/batchOrderDetailsPanelView";

interface Props {
  status: OperationStatus<BatchOrder & BatchOrder.HasRevenueData>;
}

export const BatchOrderView: React.FunctionComponent<Props> = (props) => (
  <>
    <OperationStatusIndicator
      subject={"batch"}
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.status.isSuccess() && <BatchOrderDetailsPanelView order={props.status.result}/>}
  </>
);
