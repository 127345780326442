import * as React from "react";
import * as yup from "yup";
import { Configuration } from "../../../../types/models/configuration";
import { FormLayout } from "../../../widgets/formLayout";
import { TextField } from "../../../widgets/textField";
import { TextAreaField } from "../../../widgets/textAreaField";
import { FormField } from "../../../widgets/formField";
import { friendlyDateTime } from "../../../../utils/formatting";
import { SimplePanel } from "../../../containers/simplePanel";
import { ItemForm } from "../../../blocks/crud/itemForm";
import { FormConfig } from "../../../blocks/crud/formConfig";
import { ConfigurationFormData } from "../../../../types/models/configurationFormData";
import {
  ThemeDetailsControllerType, ThemeLookupControllerType,
  ThemeLookupField, ThemeViewPathFactory
} from "../../../blocks/lookups/themes/themeLookupField";
import { NewTabLink } from "../../../widgets/newTabLink";
import { useConfigurationRoutes } from "../../../../app/routes/useRoutes";
import { FileUploadField } from "../../../widgets/fileUploadField";
import { Upload } from "../../../../types/models/upload";
import { FileUploadController, ImageUploadPreview } from "../../../widgets/fileUpload";
import { List } from "immutable";
import { Block } from "../../../containers/block";
import {
  ConfigurationCloudServicesPanel,
  FormData as ConfigurationCloudServiceFormData,
  FormDataUtils as ConfigurationCloudServiceFormDataUtils
} from "./configurationCloudServicesPanel";
import { Field, FieldProps } from "formik";
import { CheckboxField } from "../../../widgets/checkboxField";
import { DropDownField } from "../../../widgets/dropDownField";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import { ViewOnlyWarningPanel } from "../viewOnlyWarningPanel";

enum FeedbackMode {
  Disabled = "Disabled",
  DefaultQuestion = "DefaultQuestion",
  CustomQuestion = "CustomQuestion"
}

interface FormData {
  alias: string;
  name: string;
  notes: string;

  themeId: number | undefined;
  headerSuffix: string;
  mainLogo: Upload | undefined;
  escapeLinkText: string;

  introBanner: Upload | undefined;
  introTitle: string;
  introContent: string;
  pricingPageBanner: Upload | undefined;
  pricingPageTitle: string;
  pricingPageContent: string;

  sourceCloudServiceReference: string;
  destinationCloudServiceReference: string;

  feedbackMode: FeedbackMode;
  feedbackQuestion: string;
  checkbox1Label: string;
  checkbox1Required: boolean;
  checkbox2Label: string;
  checkbox2Required: boolean;
  checkbox3Label: string;
  checkbox3Required: boolean;

  sourceCloudServices: List<ConfigurationCloudServiceFormData>;
  destinationCloudServices: List<ConfigurationCloudServiceFormData>;
}

namespace FormData {
  export const blank: FormData = {
    alias: "",
    name: "",
    notes: "",

    themeId: undefined,
    headerSuffix: "",
    mainLogo: undefined,
    escapeLinkText: "",

    introBanner: undefined,
    introTitle: "",
    introContent: "",
    pricingPageBanner: undefined,
    pricingPageTitle: "",
    pricingPageContent: "",

    sourceCloudServiceReference: "",
    destinationCloudServiceReference: "",

    feedbackMode: FeedbackMode.DefaultQuestion,
    feedbackQuestion: "",
    checkbox1Label: "",
    checkbox1Required: false,
    checkbox2Label: "",
    checkbox2Required: false,
    checkbox3Label: "",
    checkbox3Required: false,

    sourceCloudServices: List(),
    destinationCloudServices: List(),
  };

  export function make(configuration: Configuration): FormData {
    return {
      alias: configuration.alias,
      name: configuration.name,
      notes: configuration.notes || "",

      themeId: configuration.themeId,
      headerSuffix: configuration.headerSuffix || "",
      mainLogo: configuration.mainLogo,
      escapeLinkText: configuration.escapeLinkText || "",

      introBanner: configuration.introBanner,
      introTitle: configuration.introTitle || "",
      introContent: configuration.introContent || "",
      pricingPageBanner: configuration.pricingPageBanner,
      pricingPageTitle: configuration.pricingPageTitle || "",
      pricingPageContent: configuration.pricingPageContent || "",

      sourceCloudServiceReference: configuration.sourceCloudServiceReference || "",
      destinationCloudServiceReference: configuration.destinationCloudServiceReference || "",

      sourceCloudServices: configuration.cloudServices
        .filter((item) => item.isSource)
        .map(ConfigurationCloudServiceFormDataUtils.make),
      destinationCloudServices: configuration.cloudServices
        .filter((item) => !item.isSource)
        .map(ConfigurationCloudServiceFormDataUtils.make),

      feedbackMode: configuration.enableFeedback
        ? (configuration.feedbackQuestion ? FeedbackMode.CustomQuestion : FeedbackMode.DefaultQuestion)
        : FeedbackMode.Disabled,
      feedbackQuestion: configuration.feedbackQuestion || "",
      checkbox1Label: configuration.checkbox1Label || "",
      checkbox1Required: configuration.checkbox1Required,
      checkbox2Label: configuration.checkbox2Label || "",
      checkbox2Required: configuration.checkbox2Required,
      checkbox3Label: configuration.checkbox3Label || "",
      checkbox3Required: configuration.checkbox3Required,
    };
  }

  export function toResult(formData: FormData): ConfigurationFormData {
    return {
      alias: formData.alias.trim(),
      name: formData.name.trim(),
      notes: formData.notes.length ? formData.notes : undefined,

      themeId: formData.themeId,
      headerSuffix: formData.headerSuffix.trim(),
      mainLogoId: formData.mainLogo?.id,
      escapeLinkText: formData.escapeLinkText.length ? formData.escapeLinkText : undefined,

      introBannerId: formData.introBanner?.id,
      introTitle: formData.introTitle.length ? formData.introTitle : undefined,
      introContent: formData.introContent.length ? formData.introContent : undefined,
      pricingPageBannerId: formData.pricingPageBanner?.id,
      pricingPageTitle: formData.pricingPageTitle.length ? formData.pricingPageTitle : undefined,
      pricingPageContent: formData.pricingPageContent.length ? formData.pricingPageContent : undefined,

      sourceCloudServiceReference: formData.sourceCloudServiceReference.length
        ? formData.sourceCloudServiceReference
        : undefined,
      destinationCloudServiceReference: formData.destinationCloudServiceReference.length
        ? formData.destinationCloudServiceReference
        : undefined,

      enableFeedback: formData.feedbackMode !== FeedbackMode.Disabled,
      feedbackQuestion: formData.feedbackMode === FeedbackMode.CustomQuestion ? formData.feedbackQuestion : undefined,
      checkbox1Label: formData.checkbox1Label.length !== 0 ? formData.checkbox1Label : undefined,
      checkbox1Required: formData.checkbox1Required,
      checkbox2Label: formData.checkbox2Label.length !== 0 ? formData.checkbox2Label : undefined,
      checkbox2Required: formData.checkbox2Required,
      checkbox3Label: formData.checkbox3Label.length !== 0 ? formData.checkbox3Label : undefined,
      checkbox3Required: formData.checkbox3Required,

      cloudServices: formData.sourceCloudServices
        .map((item) => ConfigurationCloudServiceFormDataUtils.toResult(true, item))
        .concat(
          formData.destinationCloudServices
            .map((item) => ConfigurationCloudServiceFormDataUtils.toResult(false, item))
        )
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    alias: yup.string().required("Configuration alias is required"),
    name: yup.string().required("Configuration name is required"),
    notes: yup.string(),

    themeId: yup.number().notRequired(),
    headerSuffix: yup.string(),
    mainLogoId: yup.string().notRequired(),
    escapeLinkText: yup.string(),

    introBannerId: yup.string().notRequired(),
    introTitle: yup.string(),
    introContent: yup.string(),
    pricingPageBannerId: yup.string().notRequired(),
    pricingPageTitle: yup.string(),
    pricingPageContent: yup.string(),

    sourceCloudServiceReference: yup.string(),
    destinationCloudServiceReference: yup.string(),

    feedbackQuestion: yup.string(),
    checkbox1Label: yup.string(),
    checkbox1Required: yup.boolean(),
    checkbox2Label: yup.string(),
    checkbox2Required: yup.boolean(),
    checkbox3Label: yup.string(),
    checkbox3Required: yup.boolean(),

    sourceCloudServices: yup.mixed().required(),
    destinationCloudServices: yup.mixed().required(),
  });
}

interface Props {
  config: FormConfig<Configuration, ConfigurationFormData>;

  themeLookupController: ThemeLookupControllerType;
  themeDetailsController: ThemeDetailsControllerType;
  themeViewPathFactory: ThemeViewPathFactory;

  fileUploadController: FileUploadController;
}

export const ConfigurationForm: React.FunctionComponent<Props> = (props) => {
  const configurationRoutes = useConfigurationRoutes({ configurationAlias: props.config.item?.alias });

  return (
    <ItemForm<Configuration, FormData, ConfigurationFormData>
      config={props.config}

      subject={"configuration"}
      readOnly={props.config.readOnly}

      blankFormData={FormData.blank}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, submitting, formProps }) => (
        <>
          {props.config.readOnly && <ViewOnlyWarningPanel/>}
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextField<FormData>
                label={"URL-friendly alias"}
                name={"alias"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={100}
                textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
              />
              <TextField<FormData>
                label={"Name"}
                name={"name"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={200}
              />
              {!props.config.readOnly && (
                <TextAreaField<FormData>
                  label={"Internal notes"}
                  name={"notes"}
                  disabled={submitting}
                />
              )}
              {props.config.item && !props.config.isNew && (
                <>
                  <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                  <FormField>
                    <>
                      <NewTabLink to={configurationRoutes.migrationSetup.homePath}>Preview in VaultMe</NewTabLink>
                      {!props.config.readOnly && <>{" "}(changes must be saved to become visible)</>}
                    </>
                  </FormField>
                </>
              )}
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <ThemeLookupField<FormData>
                name={"themeId"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                lookupController={props.themeLookupController}
                detailsController={props.themeDetailsController}
                viewPathFactory={props.themeViewPathFactory}
              />
              <TextField<FormData>
                label={"Header Suffix (ex. \"for SampleU\")"}
                name={"headerSuffix"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={200}
              />
              <FileUploadField<FormData>
                label={"Logo (displayed on every page)"}
                name={"mainLogo"}
                controller={props.fileUploadController}
                renderPreview={(id) => <ImageUploadPreview id={id}/>}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              <TextField<FormData>
                label={"Escape link text"}
                name={"escapeLinkText"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={255}
              />
            </FormLayout>
          </SimplePanel>
          <Block>
            <Panel>
              <TitlePanelRow>Carousel :: intro</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <FileUploadField<FormData>
                    label={"Banner"}
                    name={"introBanner"}
                    controller={props.fileUploadController}
                    renderPreview={(id) => <ImageUploadPreview id={id}/>}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Title"}
                    name={"introTitle"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"Sub-title"}
                    name={"introContent"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>Carousel :: pricing details</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <FileUploadField<FormData>
                    label={"Banner"}
                    name={"pricingPageBanner"}
                    controller={props.fileUploadController}
                    renderPreview={(id) => <ImageUploadPreview id={id}/>}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextField<FormData>
                    label={"Title"}
                    name={"pricingPageTitle"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                  <TextAreaField<FormData>
                    label={"Sub-title"}
                    name={"pricingPageContent"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <Block>
            <Panel>
              <TitlePanelRow>Language</TitlePanelRow>
              <PanelRow>
                <FormLayout noBottomMargin={true}>
                  <TextField<FormData>
                    label={"Source cloud service reference"}
                    name={"sourceCloudServiceReference"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={200}
                  />
                  <TextField<FormData>
                    label={"Destination cloud service reference"}
                    name={"destinationCloudServiceReference"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                    maxLength={200}
                  />
                </FormLayout>
              </PanelRow>
            </Panel>
          </Block>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <DropDownField<FormData>
                label={"Feedback"}
                name={"feedbackMode"}
                readOnly={props.config.readOnly}
                disabled={submitting}
              >
                <option value={FeedbackMode.Disabled}>Disabled</option>
                <option value={FeedbackMode.DefaultQuestion}>Enabled (default question)</option>
                <option value={FeedbackMode.CustomQuestion}>Enabled (custom question)</option>
              </DropDownField>
              <TextAreaField<FormData>
                label={"Feedback question"}
                name={"feedbackQuestion"}
                readOnly={props.config.readOnly}
                disabled={submitting || formProps.values.feedbackMode !== FeedbackMode.CustomQuestion}
              />
              <TextField<FormData>
                label={"Checkbox 1"}
                name={"checkbox1Label"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                appendix={
                  <CheckboxField<FormData>
                    label={"Required"}
                    name={"checkbox1Required"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                }
              />
              <TextField<FormData>
                label={"Checkbox 2"}
                name={"checkbox2Label"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                appendix={
                  <CheckboxField<FormData>
                    label={"Required"}
                    name={"checkbox2Required"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                }
              />
              <TextField<FormData>
                label={"Checkbox 3"}
                name={"checkbox3Label"}
                readOnly={props.config.readOnly}
                disabled={submitting}
                appendix={
                  <CheckboxField<FormData>
                    label={"Required"}
                    name={"checkbox3Required"}
                    readOnly={props.config.readOnly}
                    disabled={submitting}
                  />
                }
              />
            </FormLayout>
          </SimplePanel>
          <Field
            name={"cloudServices"}
            render={(field: FieldProps<FormData>) => (
              <>
                <Block>
                  <ConfigurationCloudServicesPanel
                    readOnly={props.config.readOnly}
                    isSource={true}
                    cloudServices={field.form.values.sourceCloudServices}
                    fileUploadController={props.fileUploadController}
                    onChange={(value) => field.form.setFieldValue("sourceCloudServices", value)}
                  />
                </Block>
                <Block>
                  <ConfigurationCloudServicesPanel
                    readOnly={props.config.readOnly}
                    isSource={false}
                    cloudServices={field.form.values.destinationCloudServices}
                    fileUploadController={props.fileUploadController}
                    onChange={(value) => field.form.setFieldValue("destinationCloudServices", value)}
                  />
                </Block>
              </>
            )}
          />
        </>
      )}
    </ItemForm>
  );
};
