import * as React from "react";
import { Congratulations } from "../connectionInterstitialStepView";
import { CloudService } from "../../../../types/models/cloudService";
import { Step } from "../../../containers/step";

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const BatchConnectionInterstitialStep: React.FunctionComponent<Props> = (props) => (
  <Step
    breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
    title={"You did it! Now let's connect your destination accounts."}
    onSubmit={props.onSkip}
    onNavigateBack={props.onNavigateBack}
    noForm={true}
  >
    <Congratulations
      sourceCloudService={props.sourceCloudService}
      sourceConnection={undefined}
      sourceLogoId={undefined}
      sourceLogoWidth={undefined}
      sourceConnectionDetails={undefined}

      destinationCloudService={props.destinationCloudService}
      destinationConnection={undefined}
      destinationConnectionDetails={undefined}

      displayCloudServiceNamePlaceholder={true}
    />
  </Step>
);
