import * as React from "react";
import { PanelRow } from "../../containers/rows/panelRow";
import { Panel } from "../../containers/panel";
import { Button } from "../../widgets/button";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { Constants } from "../../../app/constants";
import { Delayed } from "../../utils/delayed";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useBrowser } from "../../../utils/useBrowser";

interface Props {
  jobId: string;
  taskId: string;
  refreshing: boolean;
  onRefresh: () => void;
}

export const TaskIssueInfoPanel: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  return (
    <Panel>
      {props.refreshing && (
        <Delayed delay={Constants.ProgressIndicatorDelay}>
          <IndefiniteProgressBar/>
        </Delayed>
      )}
      <PanelRow size={"smaller"}>
        <SimpleToolbar>
          <Button
            color="white"
            size="small"
            onClick={() => browser.navigateTo(routes.jobs.taskPath(props.jobId, props.taskId))}
          >
            &lsaquo; Task
          </Button>
          <Button
            size={"small"}
            color={"white"}
            onClick={props.onRefresh}
            disabled={props.refreshing}
          >
            Refresh
          </Button>
        </SimpleToolbar>
      </PanelRow>
    </Panel>
  );
};
