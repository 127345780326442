import * as React from "react";
import { Organization } from "../../../types/models/organization";
import { HeaderBlock } from "../../widgets/headerBlock";
import { OrganizationFormData } from "../../../types/models/organizationFormData";
import {
  OrganizationThemeLookupControllerTypeFactory, OrganizationThemeViewPathFactory,
  ThemeDetailsControllerType
} from "../../blocks/lookups/themes/themeLookupField";
import { FileUploadController } from "../../widgets/fileUpload";
import { OrganizationForm } from "./organizationForm";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { OperationStatus } from "../../../types/operationStatus";

interface Props {
  readOnly: boolean;

  loadStatus: OperationStatus<Organization>;
  updateStatus: OperationStatus<Organization>;

  onSubmit: (formData: OrganizationFormData, continueEditing: boolean) => Promise<Organization>;

  organizationThemeLookupControllerFactory: OrganizationThemeLookupControllerTypeFactory;
  themeDetailsController: ThemeDetailsControllerType;
  organizationThemeViewPathFactory: OrganizationThemeViewPathFactory;

  fileUploadController: FileUploadController;
}

export const OrganizationSettingsPageView: React.FunctionComponent<Props> = (props) => (
  <>
    <HeaderBlock title={"EDU Settings"}/>
    <OperationStatusIndicator
      subject={"organization details"}
      status={props.loadStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.loadStatus.isSuccess() && (
      <OrganizationForm
        config={{
          readOnly: props.readOnly,

          item: props.loadStatus.result,
          isNew: false,
          submitStatus: props.updateStatus,

          onSubmit: props.onSubmit,
          cancelPath: undefined
        }}
        embedded={true}

        organizationThemeLookupControllerFactory={props.organizationThemeLookupControllerFactory}
        themeDetailsController={props.themeDetailsController}
        organizationThemeViewPathFactory={props.organizationThemeViewPathFactory}

        fileUploadController={props.fileUploadController}
      />
    )}
  </>
);
