import * as React from "react";
import { SandboxWorkflowFeedback } from "./sandboxWorkflow";
import { Value } from "./sandboxStep1";
import { Step } from "../../views/containers/step";
import { SimplePanel } from "../../views/containers/simplePanel";
import { Block } from "../../views/containers/block";
import { Panel } from "../../views/containers/panel";
import { PanelRow } from "../../views/containers/rows/panelRow";
import { LinkButton } from "../../views/widgets/linkButton";

interface Result {
  value: Value;
}

interface SandboxStep2Props {
  previousResult: Value;
  onComplete: (result: Result) => void;
  onFeedback: (feedback: SandboxWorkflowFeedback) => void;
  onNavigateBack?: () => void;
}

export const SandboxStep2: React.FunctionComponent<SandboxStep2Props> = (props) => (
  <Step
    title="Step 2"
    onSubmit={() => props.onComplete({ value: props.previousResult })}
    onNavigateBack={props.onNavigateBack}
    noForm={true}
  >
    <SimplePanel>
      Hi! Here's result from the previous step: {props.previousResult}
    </SimplePanel>

    <Block>
      <Panel>
        <PanelRow>
          <LinkButton onClick={() => props.onFeedback({ value: Value.SomeOther })}>
            Change previous result to something else
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onFeedback({ value: Value.Invalid })}>
            Change previous result to a non-valid value
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onFeedback({ value: Value.ErrorIndicator })}>
            Change previous result a value that triggers an error indicator
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onFeedback({ value: Value.InitErrorOnThisStep })}>
            Change previous result to a value that provokes an init error on previous step
          </LinkButton>
        </PanelRow>
        <PanelRow>
          <LinkButton onClick={() => props.onFeedback({ value: Value.InitErrorOnNextStep })}>
            Change previous result to a value that provokes an init error on the current step
          </LinkButton>
        </PanelRow>
      </Panel>
    </Block>

  </Step>
);
