import * as React from "react";
import { styled } from "../../app/theme";

const SectionLayout = styled.div<{ horizontal: boolean, open: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.horizontal ? "row" : "column"};
  flex-grow: ${(props) => props.horizontal && props.open ? 1 : 0};
  border-right: ${(props) => props.horizontal ? "1px solid white" : "none"};
  border-bottom: ${(props) => props.horizontal ? "none" : "1px solid white"};
`;

const SectionHeader = styled.div<{ horizontal: boolean, collapsible: boolean, open: boolean }>`
  background: ${(props) => props.horizontal && props.collapsible && props.open 
      ? props.theme.colors.primary 
      : "#bbbbbb"};
  color: #ffffff;
  font-size: 0.8rem;
  padding: ${(props) => props.horizontal ? "0.5rem 0.2rem" : "0.2rem .5rem 0.2rem"};
  
  writing-mode: ${(props) => props.horizontal ? "vertical-lr" : "auto"};
  transform-origin: ${(props) => props.horizontal ? "center" : "auto"};;
  transform: ${(props) => props.horizontal ? "rotate(180deg)" : "none"};
  text-align: ${(props) => props.horizontal ? "right" : "left"};

  cursor: ${(props) => props.horizontal && !props.open ? "pointer" : "auto"};
  
  &:hover {
    background: ${(props) => props.horizontal && props.collapsible && !props.open 
      ? props.theme.colors.primary 
      : "#bbbbbb"};
  }
`;

const SectionBody = styled.div<{ horizontal: boolean }>`
  padding: .5rem;
  overflow-y: auto;
  width: ${(props) => props.horizontal ? "100%" : "auto"};
`;

interface SectionProps {
  horizontal: boolean;
  title: React.ReactNode;
  sectionId: string;
  openSectionId?: string;
  onSelect?: (section: string) => void;
}

export class Section extends React.Component<SectionProps> {
  constructor(props: SectionProps) {
    super(props);

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  public render() {
    const open = this.props.onSelect === undefined || this.props.sectionId === this.props.openSectionId;
    const body = (!this.props.horizontal || open) &&
      <SectionBody horizontal={this.props.horizontal}>{this.props.children}</SectionBody>;
    return (
      <SectionLayout horizontal={this.props.horizontal} open={open}>
        <SectionHeader
          horizontal={this.props.horizontal}
          collapsible={this.props.onSelect !== undefined}
          open={open}
          onClick={this.handleHeaderClick}
        >
          {this.props.title}
        </SectionHeader>
        {body}
      </SectionLayout>
    );
  }

  public handleHeaderClick() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.sectionId);
    }
  }
}
