import * as React from "react";
import { Grid } from "../../widgets/grid";
import { preciseTimestamp } from "../../../utils/formatting";
import { List } from "immutable";
import { TaskIssue } from "../../../types/models/taskIssue";
import { WidgetStatus } from "../../utils/widgetStatus";

interface TaskIssueRowProps {
  taskIssue: TaskIssue;
  flash: boolean;
}

export const TaskIssueRow: React.FunctionComponent<TaskIssueRowProps> = (props) => {
  const status = props.taskIssue.resolvedAt ? WidgetStatus.Normal : WidgetStatus.Error;
  return (
    <>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.transition}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {preciseTimestamp(props.taskIssue.createdAt)}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.resolvedAt ? preciseTimestamp(props.taskIssue.resolvedAt) : "--"}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.summary}
      </Grid.Cell>
      <Grid.JsonCell status={status} flash={props.flash}>
        {props.taskIssue.details}
      </Grid.JsonCell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.timesConfirmed}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.confirmedAt ? preciseTimestamp(props.taskIssue.confirmedAt) : "--"}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.timesNotified}
      </Grid.Cell>
      <Grid.Cell status={status} flash={props.flash}>
        {props.taskIssue.notifiedAt ? preciseTimestamp(props.taskIssue.notifiedAt) : "--"}
      </Grid.Cell>
    </>
  );
};

interface Props {
  taskIssues: List<TaskIssue>;
}

export const TaskIssueHistoryGrid: React.FunctionComponent<Props> = (props) => {
  const highlightNewRows = React.useRef(props.taskIssues.size);

  React.useEffect(() => {
    highlightNewRows.current = props.taskIssues.size;
  });
  const newRows = props.taskIssues.size - highlightNewRows.current;

  return (
    <Grid>
      <Grid.Header>
        <Grid.Column>Transition</Grid.Column>
        <Grid.Column>Reported At</Grid.Column>
        <Grid.Column>Resolved At</Grid.Column>
        <Grid.Column>Summary</Grid.Column>
        <Grid.Column>Details</Grid.Column>
        <Grid.Column>Times Confirmed</Grid.Column>
        <Grid.Column>Last Confirmed At</Grid.Column>
        <Grid.Column>Times Notified</Grid.Column>
        <Grid.Column>Last Notified At</Grid.Column>
      </Grid.Header>
      <Grid.Body>
        {
          props.taskIssues.map((taskIssue, index) => (
            <Grid.Row key={taskIssue.createdAt.toISOString()}>
              <TaskIssueRow taskIssue={taskIssue} flash={index < newRows}/>
            </Grid.Row>
          ))
        }
      </Grid.Body>
    </Grid>
  );
};
