import { OperationStatus } from "../types/operationStatus";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { identity, nullToUndefined } from "../utils/misc";
import { useManagedQuery } from "../services/graphql/useManagedQuery";
import { ErrorClass } from "../services/graphql/errorClass";
import { UserFacingError } from "../types/userFacingError";
import { ErrorHandler } from "../services/graphql/errorHandler";
import { Order } from "../types/models/order";

export function useGetAuthorizationStatus(migrationId: string) {
  return useManagedQuery({
    query: GraphQL.useGetAuthorizationStatusQuery,
    deps: null,
    prepare: () => ({ migrationId }),
    extract: (data: GraphQL.GetAuthorizationStatusQuery) => nullToUndefined(data.getAuthorizationStatus),
    complete: identity,
    fetchPolicy: "network-only"
  });
}

function braintreeExceptionHandler(errorMessage: string): ErrorHandler<any> {
  return [
    ErrorClass.BraintreeException,
    (error) => UserFacingError.expected(error, {
      summary: errorMessage + " (" + error.message + ")",
      showTechnicalDetails: true
    })
  ];
}

type SubmitTransactionForSettlementHook = [
  () => Promise<Order | undefined>,
  OperationStatus<Order | undefined>,
  () => void
];

export function useSubmitTransactionForSettlement(migrationId: string): SubmitTransactionForSettlementHook {
  const [fireWith, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useSubmitTransactionForSettlementMutation,
    extract: (data: GraphQL.SubmitTransactionForSettlementMutation) => data.submitTransactionForSettlement,
    complete: (extract) => extract ? Order.parse(extract) : undefined,
    catch: [braintreeExceptionHandler("Failed to submit transaction for settlement")]
  });

  function fire(): Promise<Order | undefined> {
    return fireWith({
      variables: { migrationId },
      retry: fire
    });
  }

  return [fire, status, reset];
}

type VoidTransactionHook = [
  () => Promise<Order | undefined>,
  OperationStatus<Order | undefined>,
  () => void
];

export function useVoidTransaction(migrationId: string): VoidTransactionHook {
  const [fireWith, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useVoidTransactionMutation,
    extract: (data: GraphQL.VoidTransactionMutation) => data.voidTransaction,
    complete: (extract) => extract ? Order.parse(extract) : undefined,
    catch: [braintreeExceptionHandler("Failed to void transaction")]
  });

  function fire(): Promise<Order | undefined> {
    return fireWith({
      variables: { migrationId },
      retry: fire
    });
  }

  return [fire, status, reset];
}

type RefundTransactionHook = [
  () => Promise<Order | undefined>,
  OperationStatus<Order | undefined>,
  () => void
];

export function useRefundTransaction(migrationId: string): RefundTransactionHook {
  const [fireWith, { status, reset }] = useManagedMutation({
    mutation: GraphQL.useRefundTransactionMutation,
    extract: (data: GraphQL.RefundTransactionMutation) => data.refundTransaction,
    complete: (extract) => extract ? Order.parse(extract) : undefined,
    catch: [braintreeExceptionHandler("Failed to refund transaction")]
  });

  function fire(): Promise<Order | undefined> {
    return fireWith({
      variables: { migrationId },
      retry: fire
    });
  }

  return [fire, status, reset];
}
