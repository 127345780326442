import { GraphQL } from "../../services/graphql/generated";

export type ReferralCodeValidationResult = ReferralCodeValidationResult.Any;

export namespace ReferralCodeValidationResult {
  export enum Type {
    Success = "Success",
    NotEligible = "NotEligible"
  }

  export interface Base {
    type: Type;
  }
  
  export interface Success extends Base {
    type: Type.Success;
    referralCode: GraphQL.ReferralCodeSummaryFragment;
  }

  export interface NotEligible extends Base {
    type: Type.NotEligible;
    referralCode: GraphQL.ReferralCodeSummaryFragment;
    governingOrganizationName: string;
  }

  export type Any = Success | NotEligible;

  export function parse(data: GraphQL.ValidateReferralCodeResultFragment): Any {
    switch (data.__typename) {
      case "ValidateReferralCodeResult_Success":
        return {
          type: Type.Success,
          referralCode: data.referralCode
        };

      case "ValidateReferralCodeResult_NotEligible":
        return {
          type: Type.NotEligible,
          referralCode: data.referralCode,
          governingOrganizationName: data.governingOrganizationName
        };

      case undefined: throw new Error("Unrecognized ValidateReferralCodeResult type");
    }
  }
}
