import * as React from "react";
import { CRUDConfig } from "./crudConfig";
import { EditItemPageView } from "../../views/blocks/crud/editItemPageView";

interface Props<Id, Item, FormData> {
  config: CRUDConfig<Id, Item, FormData>;
  id: Id;

  homePath: string;
  cancelPath: string;

  onUpdate: (item: Item, continueEditing: boolean) => void;
  onClone: ((item: Item) => void) | undefined;
  onDelete: (() => void) | undefined;
}

export function EditItemPage<Id, Item, FormData>(props: Props<Id, Item, FormData>): React.ReactElement {
  const [loadStatus] = props.config.useGetQuery(props.id);
  const [update, updateStatus] = props.config.useUpdateMutation();
  const [deleteItem, deleteStatus] = props.config.useDeleteMutation();

  return (
    <EditItemPageView
      breadcrumbs={props.config.breadcrumbs}
      title={props.config.title}
      subject={props.config.subject}
      readOnly={!!props.config.readOnly}
      renderForm={props.config.renderForm}

      getItemTitle={props.config.getItemTitle}

      id={props.id}

      homePath={props.homePath}
      cancelPath={props.cancelPath}

      loadStatus={loadStatus}
      updateStatus={updateStatus}
      deleteStatus={deleteStatus}

      onSubmit={(formData, continueEditing) => update(props.id, formData).then((result) => {
        props.onUpdate(result, continueEditing);
        return result;
      })}
      onClone={props.onClone}
      onDelete={props.onDelete && (() => deleteItem(props.id).then(() => props.onDelete && props.onDelete()))}
    />
  );
}
