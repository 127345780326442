import * as React from "react";
import { styled } from "../../app/theme";

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

interface EnvelopeProps {
  color: string;
}

export const Envelope: React.FunctionComponent<EnvelopeProps> = (props) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="58.13 24.5 66.55 66.55"
    width="66.55"
    height="66.55"
  >
    <path
      fill={props.color}
      stroke="none"
      d="M 63.54,78.69
         C 63.54,78.69 119.27,78.69 119.27,78.69
           122.25,78.69 124.68,76.37 124.68,73.51
           124.68,73.51 124.68,40.73 124.68,40.73
           124.68,37.88 122.28,35.57 119.31,35.55
           119.31,35.55 119.30,35.55 119.29,35.55
           119.29,35.55 119.27,35.55 119.27,35.55
           119.27,35.55 63.54,35.55 63.54,35.55
           63.54,35.55 63.53,35.55 63.53,35.55
           63.52,35.55 63.51,35.55 63.50,35.55
           60.54,35.57 58.13,37.88 58.13,40.73
           58.13,40.73 58.13,73.51 58.13,73.51
           58.13,76.37 60.56,78.69 63.54,78.69 Z
         M 64.11,75.24
         C 64.11,75.24 82.54,56.07 82.54,56.07
           82.54,56.07 90.20,62.71 90.20,62.71
           90.54,63.01 90.97,63.16 91.41,63.16
           91.84,63.16 92.27,63.01 92.61,62.71
           92.61,62.71 100.27,56.07 100.27,56.07
           100.27,56.07 118.71,75.24 118.71,75.24
           118.71,75.24 64.11,75.24 64.11,75.24 Z
         M 119.85,39.11
         C 120.56,39.34 121.09,39.97 121.09,40.72
           121.09,40.72 121.09,72.62 121.09,72.62
           121.09,72.62 102.94,53.76 102.94,53.76
           102.94,53.76 119.85,39.11 119.85,39.11 Z
         M 114.61,39.00
         C 114.61,39.00 91.41,59.11 91.41,59.11
           91.41,59.11 68.20,39.00 68.20,39.00
           68.20,39.00 114.61,39.00 114.61,39.00 Z
         M 61.73,40.73
         C 61.73,39.97 62.25,39.34 62.96,39.11
           62.96,39.11 79.87,53.76 79.87,53.76
           79.87,53.76 61.73,72.62 61.73,72.62
           61.73,72.62 61.73,40.73 61.73,40.73 Z
         M 95.16,133.96"
    />
  </StyledSvg>
);
