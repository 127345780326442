import * as React from "react";
import { Panel } from "../../../containers/panel";
import { Constants } from "../../../../app/constants";
import { PanelRow } from "../../../containers/rows/panelRow";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { sentence, withIndefiniteArticle } from "../../../../utils/misc";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { CloudService } from "../../../../types/models/cloudService";
import { styled } from "../../../../app/theme";
import { useShepherd } from "../../../../utils/useShepherd";
import { completedInfoButtonsTourAction } from "../../../../state/tours/actions";
import { useTours } from "../../../../utils/useAppState";
import { useDispatch } from "react-redux";

export namespace ProviderInfoPanel {
  export interface Area {
    title: string;
    description: string;
    icon: any;
    helpArticle?: PreparedHelpArticle;
  }

  export interface MigrationConfig {
    areas: ProviderInfoPanel.Area[];
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
  }
}

const ItemsContainer = styled.div`
  position: relative;
`;

const InfoButtons = styled.div`
  position: absolute;
  background: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4.25rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 3.25rem;
  }
`;

interface ProviderInfoPanelProps {
  config: ProviderInfoPanel.MigrationConfig | undefined;
}

export const ProviderInfoPanel: React.FunctionComponent<ProviderInfoPanelProps> = (props) => {
  function renderItems() {
    if (props.config) {
      return props.config.areas.map((area) => (
        <PanelRow icon={area.icon} key={area.title} helpArticle={area.helpArticle}>
          {sentence(area.description)}
        </PanelRow>
      ));
    } else {
      return (
        <PanelRow>
          Please choose both your source and destination account types above to see what can be migrated
        </PanelRow>
      );
    }
  }

  const tours = useTours();
  const dispatch = useDispatch();
  const shepherdTargetRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useShepherd({
    title: "Use these buttons throughout the application to learn how each part of VaultMe works. Help content " +
      "will adapt to the choices you make throughout this process.",
    targetRef: shepherdTargetRef,
    containerRef,
    disable: !props.config || tours.completedInfoButtonsTour,
    onComplete: () => dispatch(completedInfoButtonsTourAction())
  });

  return (
    <Panel ref={containerRef}>
      <TitlePanelRow>
        {
          `Here's what ${Constants.Product.name} can migrate ` + (
            props.config
              ? "from " + withIndefiniteArticle(props.config.sourceCloudService.reference) +
              " to " + withIndefiniteArticle(props.config.destinationCloudService.reference) + ":"
              : "between these 2 types of accounts:"
          )
        }
      </TitlePanelRow>
      <ItemsContainer>
        <InfoButtons ref={shepherdTargetRef}/>
        {renderItems()}
      </ItemsContainer>
    </Panel>
  );
};
