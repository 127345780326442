export class InitializationError extends Error {
  constructor(message: string, public readonly stepId: string, public readonly initForResult: boolean) {
    super(message);
    Object.setPrototypeOf(this, InitializationError.prototype);
  }

  public toString(): string {
    return this.message +
      " (step: " + this.stepId +
      ", scope: " + (this.initForResult ? "initForResult" : "init") + ")";
  }
}

export namespace InitializationError {
  export function create(source: any, stepId: string, initForResult: boolean): InitializationError {
    return new InitializationError(source.toString(), stepId, initForResult);
  }
}
