import { OperationStatus } from "../types/operationStatus";
import { Migration } from "../types/models/migration";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";
import { Option } from "../utils/monads/option";

type TakeMigrationForSupervisionHook = [() => Promise<Migration>, OperationStatus<Migration>];

export function useTakeMigrationForSupervision(migrationId: string): TakeMigrationForSupervisionHook {
  const [takeMigrationForSupervision, { status }] = useManagedMutation({
    mutation: GraphQL.useTakeMigrationForSupervisionMutation,
    extract: (data: GraphQL.TakeMigrationForSupervisionMutation) => nullToUndefined(data.takeForSupervision),
    complete: (migration) => Migration.parseCore(migration)
  });

  function take(): Promise<Migration> {
    return takeMigrationForSupervision({
      variables: { migrationId },
      retry: take,
    });
  }

  return [take, status];
}

type ReleaseMigrationFromSupervisionHook = [(note: Option<string>) => Promise<Migration>, OperationStatus<Migration>];

export function useReleaseMigrationFromSupervision(migrationId: string): ReleaseMigrationFromSupervisionHook {
  const [releaseMigrationFromSupervision, { status }] = useManagedMutation({
    mutation: GraphQL.useReleaseMigrationFromSupervisionMutation,
    extract: (data: GraphQL.ReleaseMigrationFromSupervisionMutation) => nullToUndefined(data.releaseFromSupervision),
    complete: (migration) => Migration.parseCore(migration)
  });

  function take(note: Option<string>): Promise<Migration> {
    return releaseMigrationFromSupervision({
      variables: { migrationId, note: note.toJS() },
      retry: () => take(note),
    });
  }

  return [take, status];
}
