import * as React from "react";
import { styled } from "../../../../app/theme";
import { Spinner } from "../../../glyphs/spinner";
import { CheckMark } from "../../../glyphs/checkMark";
import { PanelRowDefs } from "../../../containers/rows/panelRow";
import { InfoButtonDefaultSize } from "../../../widgets/infoButton";

const Layout = styled.div`
  position: relative;
  height: 0;
`;

const SpinnerContainer = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -2rem;
  right: ${PanelRowDefs.PanelHorizontalPadding + InfoButtonDefaultSize + PanelRowDefs.InfoButtonPadding + 
    InfoButtonDefaultSize / 2 - 2}rem;
  background: ${(props) => props.theme.colors.white};
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    right: ${PanelRowDefs.XSmallPanelHorizontalPadding + InfoButtonDefaultSize + PanelRowDefs.XSmallInfoButtonPadding +
      InfoButtonDefaultSize / 2 - 2}rem;
  }
`;

export const MigrationCompleteIndicatorRow: React.FunctionComponent = () => (
  <Layout>
    <SpinnerContainer>
      <Spinner
        progress={100}
        lineStrokeWidthMultiplier={0.4}
        icon={<CheckMark strokeWidthMultiplier={2}/>}
        iconOffsetX={2.5}
      />
    </SpinnerContainer>
  </Layout>
);
