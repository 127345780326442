import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProjectUpdate } from "../../types/models/offboardingProjectUpdate";

export function useGetOffboardingProjectUpdateQuery(
  id: string | undefined
): ManagedQueryHook<GraphQL.GetOffboardingProjectUpdateQueryVariables, OffboardingProjectUpdate> {
  return useManagedQuery({
    query: GraphQL.useGetOffboardingProjectUpdateQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetOffboardingProjectUpdateQuery) => nullToUndefined(data.offboardingProjectUpdate),
    complete: OffboardingProjectUpdate.parse
  });
}
