// tslint:disable max-line-length

import { Constants } from "../../../../app/constants";

export interface ReviewData {
  stars: number;
  quote: string;
  name: string;
  description?: string;
  link?: string;
}

export namespace ReviewData {
  export const victor: ReviewData = {
    stars: 5,
    quote: `"I am so grateful I found ${Constants.Product.name}. If I came across it sooner I would have saved 16+ hours of time."`,
    name: "Victor P.",
    description: "Migrated from Gmail to G-Suite",
    link: "https://www.facebook.com/victor.pidkowich/posts/10160730250110582:0"
  };

  export const colin: ReviewData = {
    stars: 5,
    quote: '"Literally saved me about 40-50 hours worth of manual labor."',
    name: "Colin D.",
    description: "G-Suite Administrator, Migrated faculty-member's Google Account",
    link: "https://www.facebook.com/colin.davitt/posts/10207404047112675:0"
  };

  export const lisette: ReviewData = {
    stars: 5,
    quote: '"Brilliant service... The customer support for all the queries I had were great. I couldn\'t have asked for more."',
    name: "Lisette B.",
    description: "Migrated her work account",
    link: "https://www.facebook.com/lisette.brittan/posts/10154366905467795:0"
  };

  export const brianna: ReviewData = {
    stars: 5,
    quote: `"As an organizational freak, ${Constants.Product.name} is a beautiful and efficient way of saving all your hard work WITHOUT having to organize it. I highly recommend this software!"`,
    name: "Brianna C.",
    description: "Migrated college Gmail account",
    link: "https://www.facebook.com/brianna.carroll.378/posts/1165665613485388:0"
  };

  export const jonathan: ReviewData = {
    stars: 5,
    quote: '"The transfer process is easy, and requires minimal work. I highly recommend this software."',
    name: "Jonathan P.",
    description: "Migrated school email",
    link: "https://www.facebook.com/Toasty.j/posts/10153606327201172:0"
  };

  export const fitz: ReviewData = {
    stars: 5,
    quote: '"This service was so simple and really quick and I’m glad I’ll still have access to my stuff..."',
    name: "Fitz A.",
    description: "Migrated Google Drive",
    link: "https://www.facebook.com/fitz.anderson.16/posts/10154755966563976:0"
  };

  export const audrey: ReviewData = {
    stars: 5,
    quote: '"Super quick and very straight forward"',
    name: "Audrey H.",
    description: "Migrated college email, contacts, and files",
    link: "https://www.facebook.com/audrey.hahn.7/posts/1184408184924435:0"
  };

  export const hoku: ReviewData = {
    stars: 5,
    quote: `"Instead of having to manually sort through and organize what files I wanted to preserve, ${Constants.Product.name} allowed me to transfer them all in about an hour"`,
    name: "Hoku K.",
    description: "Migrated email, files, and shared files",
    link: "https://www.facebook.com/hoku.krueger/posts/10206164340363138:0"
  };

  export const dacia: ReviewData = {
    stars: 5,
    quote: `"I am SO, so thankful for this great service... ${Constants.Product.name} moved 11,000+ files in 2 hours... It was the best money that I have ever spent!"`,
    name: "Dacia J.",
    description: "Migrated work data",
    link: "https://www.facebook.com/musicaldacia/posts/10210014975039197:0"
  };

  export const jennifer: ReviewData = {
    stars: 5,
    quote: `"${Constants.Product.name} transferred everything in its original format, no problems what so ever. Plus, it kept all of my contacts on hand if I should ever need them."`,
    name: "Jennifer V.",
    description: "Migrated emails, files, etc.",
    link: "https://www.facebook.com/jennifer.vasquez.7923/posts/1235597033147612:0"
  };

  export const sabri: ReviewData = {
    stars: 5,
    quote: '"Could not be easier... and you don’t even need to keep the browser open! You simply get an email once everything’s sorted out."',
    name: "Sabri E.",
    description: "Migrated school account",
    link: "https://www.facebook.com/sabri.espasandin/posts/10207650042663811:0"
  };

  export const lili: ReviewData = {
    stars: 5,
    quote: '"I really like this product."',
    name: "Lili R.",
    description: "Migrated email",
    link: "https://www.facebook.com/lilirodriguez29/posts/10209145395131399:0"
  };

  export const katie: ReviewData = {
    stars: 5,
    quote: '"Super useful and easy to use."',
    name: "Katie C.",
    description: "Migrated email",
    link: "https://www.facebook.com/katie.courtney.543/posts/1200917526615381:0"
  };

  export const natasha: ReviewData = {
    stars: 5,
    quote: `"I was a bit overwhelmed by the thought of having to move 1000s of y files... ${Constants.Product.name} was great... super simple and convenient."`,
    name: "Natasha A.",
    description: "Migrated account",
    link: "https://www.facebook.com/natasha.anderson.5220/posts/10153527820867227:0"
  };

  export const keith: ReviewData = {
    stars: 5,
    quote: '"Had a really good experience... Very easy to use. Also, great feature that you don’t have to stay on the browser while it transfers files."',
    name: "Keith J.",
    description: "Saved work data",
    link: "https://www.facebook.com/keith.a.jones.96/posts/10154769305603452:0"
  };

  export const jessica: ReviewData = {
    stars: 5,
    quote: '"This software is awesome! Although it took 2 hours it was all done offline so I was able to use my google accounts and use my computer regularly."',
    name: "Jessica H.",
    description: "Migrated school account",
    link: "https://www.facebook.com/permalink.php?story_fbid=1798970466997408&id=100006534161116&substory_index=0"
  };

  export const amy: ReviewData = {
    stars: 5,
    quote: '"Worked ridiculously easily. Worth every penny."',
    name: "Amy C.",
    description: "Migrated work account",
    link: "https://www.facebook.com/amy.clayman/posts/10154970468149684:0"
  };

  export const evelyn: ReviewData = {
    stars: 5,
    quote: `"${Constants.Product.name} totally exceeded my expectations... I would highly recommend this software."`,
    name: "Evelyn V.",
    description: "Migrated school files",
    link: "https://www.facebook.com/evega31/posts/10209772473331247:0"
  };
}
