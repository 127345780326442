import * as React from "react";
import { styled } from "../../app/theme";

export const ServerLabel = styled.div`
  display: inline-block;
  padding: 0 .2rem;
  margin-right: .2rem;
  color: white;
  background: ${(props) => props.theme.colors.primary};
  font-family: monospace;
  
  &:before {
    content: "srv";
  }
`;
