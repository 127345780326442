import { AnyRelationship } from "../relationship";
import { ComponentResolved } from "./componentResolved";
import { Association } from "./association";
import { GraphQL } from "../../services/graphql/generated";
import { Pipe } from "./pipe";
import { PipeFromOption } from "./pipeFromOption";
import { PipeToOption } from "./pipeToOption";
import { Plug } from "./plug";
import { BlueprintException } from "../blueprintException";
import { HubBinding } from "../hubBinding";
import { MigrationFlow } from "./migrationFlow";

export namespace RelationshipParser {
  export function fromGraphQL(
    binding: HubBinding,
    relationship: GraphQL.RelationshipFragment
  ): AnyRelationship {
    switch (relationship.__typename) {
      case "Association":
        return new Association(
          binding,
          relationship,
          Association.Props.fromGraphQL(relationship.associationProps)
        );

      case "ComponentResolved":
        return new ComponentResolved(
          binding,
          relationship,
          ComponentResolved.Props.fromGraphQL(relationship.componentResolvedProps)
        );

      case "MigrationFlow":
        return new MigrationFlow(
          binding,
          relationship,
          MigrationFlow.Props.fromGraphQL(relationship.migrationFlowProps)
        );

      case "Pipe":
        return new Pipe(
          binding,
          relationship,
          Pipe.Props.fromGraphQL(relationship.pipeProps)
        );

      case "PipeFromOption":
        return new PipeFromOption(
          binding,
          relationship,
          PipeFromOption.Props.fromGraphQL(relationship.pipeFromOptionProps)
        );

      case "PipeToOption":
        return new PipeToOption(
          binding,
          relationship,
          PipeToOption.Props.fromGraphQL(relationship.pipeToOptionProps)
        );

      case "Plug":
        return new Plug(
          binding,
          relationship,
          Plug.Props.fromGraphQL(relationship.plugProps)
        );

      case undefined:
        console.error("Undefined relationship type returned from the server", binding, relationship);
        throw new BlueprintException("Undefined relationship type returned from the server");
    }
  }
}
