import { Program } from "../../types/models/program";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useListProgramsWithRevenueSharingQuery(
  organizationId: number
): ManagedQueryHook<GraphQL.ListProgramsWithRevenueSharingQueryVariables, List<Program>> {
  return useManagedQuery({
    query: GraphQL.useListProgramsWithRevenueSharingQuery,
    deps: null,
    prepare: () => ({ organizationId }),
    extract: (data: GraphQL.ListProgramsWithRevenueSharingQuery) =>
      nullToUndefined(data.listProgramsWithRevenueSharing),
    complete: (programs) => List(programs)
      .filter((program) => program.id !== "")
      .map(Program.parse),
    fetchPolicy: "cache-and-network"
  });
}
