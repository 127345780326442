import * as React from "react";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { ActivityChart, Dataset } from "../../monitoringDashboardPageView/activityChart";
import { List, Range } from "immutable";
import { css, styled } from "../../../../app/theme";
import { Button } from "views/widgets/button";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { Block } from "../../../containers/block";
import * as moment from "moment";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { ActivityTimeUnit } from "../../../../types/enums/activityTimeUnit";
import { OrganizationMigrationStats } from "../../../../types/models/organizationMigrationStats";
import { commafy } from "../../../../utils/formatting";
import { Link } from "react-router-dom";
import { OrganizationSummary } from "../../../../types/models/organization";
import { useRoutes } from "../../../../app/routes/useRoutes";
import { OrganizationAccessListStats } from "../../../../types/models/organizationAccessListStats";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { ModalController, useModal } from "../../../layouts/modal";
import { IncrementSponsorshipLimitAmountRemainingTool } from "./incrementSponsorshipLimitAmountRemainingTool";
import { RemoveSponsorshipLimitTool } from "./removeSponsorshipLimitTool";
import { useElevated } from "../../../../utils/useAppState";

const TimeUnits = [/*ActivityTimeUnit.Hour, */ActivityTimeUnit.Day, ActivityTimeUnit.Month, ActivityTimeUnit.Year];

export interface IncrementSponsorshipLimitAmountRemainingToolViewProps {
  status: OperationStatus<any>;
  onSubmit: (amount: number) => Promise<any>;
}

export interface IncrementSponsorshipLimitAmountRemainingToolControllerProps {
  render: (viewProps: IncrementSponsorshipLimitAmountRemainingToolViewProps) => React.ReactElement;
}

export type IncrementSponsorshipLimitAmountRemainingToolControllerType =
  React.ComponentType<IncrementSponsorshipLimitAmountRemainingToolControllerProps>;

export interface RemoveSponsorshipLimitViewProps {
  status: OperationStatus<any>;
  onSubmit: () => Promise<any>;
}

export interface RemoveSponsorshipLimitControllerProps {
  render: (viewProps: RemoveSponsorshipLimitViewProps) => React.ReactElement;
}

export type RemoveSponsorshipLimitControllerType =
  React.ComponentType<RemoveSponsorshipLimitControllerProps>;

interface Props {
  organization: OrganizationSummary;

  timeUnit: ActivityTimeUnit;
  migrationStatsStatus: OperationStatus<OrganizationMigrationStats>;
  onTimeUnitChange: (timeUnit: ActivityTimeUnit) => void;

  accessListStatsStatus: OperationStatus<OrganizationAccessListStats>;

  incrementSponsorshipLimitAmountRemainingToolController: IncrementSponsorshipLimitAmountRemainingToolControllerType;
  removeSponsorshipLimitController: RemoveSponsorshipLimitControllerType;

  onRefresh: () => void;
}

export const OrganizationDashboardView: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();
  const routes = useRoutes().organizationPortalRoutes;

  const incrementSponsorshipLimitAmountRemainingToolModal = useModal();
  const removeSponsorshipLimitModal = useModal();

  return (
    <>
      <HeaderBlock title={"Dashboard"}/>
      <ModalController
        modal={incrementSponsorshipLimitAmountRemainingToolModal}
        title={"Increase Sponsorship Limit Amount Remaining"}
        render={() => React.createElement(props.incrementSponsorshipLimitAmountRemainingToolController, {
          render: (viewProps) => (
            <IncrementSponsorshipLimitAmountRemainingTool
              currentAmount={props.organization.sponsorshipLimitAmountRemaining}
              status={viewProps.status}
              onSubmit={(amount) => viewProps.onSubmit(amount)}
            />
          )
        })}
      />
      <ModalController
        modal={removeSponsorshipLimitModal}
        title={"Remove Sponsorship Limit"}
        render={(close) => React.createElement(props.removeSponsorshipLimitController, {
          render: (viewProps) => (
            <RemoveSponsorshipLimitTool
              currentAmount={props.organization.sponsorshipLimitAmountRemaining}
              status={viewProps.status}
              onSubmit={() => viewProps.onSubmit().then(() => setTimeout(close, 1000))}
            />
          )
        })}
      />
      <Block>
        <Panel>
          <TitlePanelRow>Migration Activity</TitlePanelRow>
          <PanelRow>
            <SimpleToolbar
              rightSide={(
                <Button
                  size={"small"}
                  color={"secondary"}
                  disabled={props.migrationStatsStatus.isWorking() || props.accessListStatsStatus.isWorking()}
                  onClick={() => props.onRefresh()}
                >
                  Refresh
                </Button>
              )}
            >
              {TimeUnits.map((timeUnit) => (
                <Button
                  key={timeUnit}
                  size={"small"}
                  color={props.timeUnit === timeUnit ? "primary" : "secondary"}
                  onClick={() => props.onTimeUnitChange(timeUnit)}
                >
                  {timeUnit}
                </Button>
              ))}
            </SimpleToolbar>
          </PanelRow>
          <OperationStatusIndicator
            subject={"chart data"}
            status={props.migrationStatsStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          {props.migrationStatsStatus.mapLastResult((stats) => (
            <PanelRow>
              <ProgramPerformanceChart data={stats}/>
            </PanelRow>
          ))}
        </Panel>
      </Block>
      {props.migrationStatsStatus.mapLastResult((stats) => (
        <Block>
          <Panels>
            <Panel>
              <PanelRow>
                <DataPoints>
                  <NumericIndicator title={"Total Migrations"} value={stats.totalMigrationCount}/>
                  <NumericIndicator
                    title={"Recent Migrations"}
                    subTitle={"(Past " + stats.recentMigrationDayCount + " Days)"}
                    value={stats.recentMigrationCount}
                  />
                </DataPoints>
              </PanelRow>
              <PanelRow>
                <LinksContainer>
                  <Links>
                    <WidgetLink to={routes.migrations(props.organization.alias).homePath} $primary={true}>
                      Find a migration and view its details
                    </WidgetLink>
                    <WidgetLink to={routes.reportsPath(props.organization.alias)} $primary={false}>
                      View migration reports
                    </WidgetLink>
                  </Links>
                </LinksContainer>
              </PanelRow>
            </Panel>
            <Panel>
              <OperationStatusIndicator
                subject={"chart data"}
                status={props.accessListStatsStatus}
                indicators={StatusIndicators.PanelRow()}
              />
              {props.accessListStatsStatus.mapLastResult((accessListStats) => (
                <PanelRow>
                  <DataPoints>
                    <NumericIndicator title={"Allowlists"} value={accessListStats.programAccessListCount}/>
                    <NumericIndicator title={"Blocklists"} value={accessListStats.organizationBlockListCount}/>
                  </DataPoints>
                </PanelRow>
              ))}
              <PanelRow>
                <LinksContainer>
                  <Links>
                    <WidgetLink to={routes.accessLists(props.organization.alias).homePath} $primary={true}>
                      Manage access lists
                    </WidgetLink>
                  </Links>
                </LinksContainer>
              </PanelRow>
            </Panel>
            {(props.organization.sponsorshipLimitAmountRemaining !== undefined || elevated) && (
              <Panel>
                <PanelRow>
                  <DataPoints>
                    {
                      props.organization.sponsorshipLimitAmountRemaining !== undefined
                        ? (
                          <DollarAmountIndicator
                            title={"Sponsorship Limit Amount Remaining"}
                            amount={props.organization.sponsorshipLimitAmountRemaining}
                          />
                        )
                        : <Indicator title={"Sponsorship Limit Amount Remaining"}>--</Indicator>
                    }
                  </DataPoints>
                </PanelRow>
                {elevated && (
                  <PanelRow>
                    <LinksContainer>
                      <Links>
                        <WidgetButton $primary={true} onClick={incrementSponsorshipLimitAmountRemainingToolModal.open}>
                          Increase sponsorship limit amount remaining
                        </WidgetButton>
                        <WidgetButton
                          $primary={false}
                          disabled={props.organization.sponsorshipLimitAmountRemaining === undefined}
                          onClick={removeSponsorshipLimitModal.open}
                        >
                          Remove sponsorship limit
                        </WidgetButton>
                      </Links>
                    </LinksContainer>
                  </PanelRow>
                )}
              </Panel>
            )}
          </Panels>
        </Block>
      ))}
    </>
  );
};

const StyledActivityChart = styled(ActivityChart)`
  width: 100%;
  height: 25vh;
`;

interface ProgramPerformanceChartProps {
  data: OrganizationMigrationStats;
}

const ProgramPerformanceChart: React.FunctionComponent<ProgramPerformanceChartProps> = (props) => {
  const { timestamps, datasets } = React.useMemo(
    () => makeProgramPerformanceChartData(props.data),
    [props.data]
  );

  return (
    <StyledActivityChart
      timestamps={timestamps}
      datasets={datasets}
      displayLegend={true}
      timeUnit={props.data.timeUnit}
    />
  );
};

function makeProgramPerformanceChartData(
  data: OrganizationMigrationStats
): { timestamps: List<moment.Moment>, datasets: List<Dataset> } {
  const momentUnitOfTime = ActivityTimeUnit.momentUnitOfTime(data.timeUnit);
  let timestamps = Range(0, data.timeUnitCount)
    .map((index) =>
      moment().startOf(momentUnitOfTime).subtract(data.timeUnitCount - 1 - index, momentUnitOfTime)
    )
    .toList();

  const minTimestamp = data.programPerformances
    .flatMap((programPerformance) =>
      programPerformance.dataPoints.map((dataPoint) => dataPoint.timestamp)
    )
    .min();
  if (minTimestamp && (data.timeUnit === ActivityTimeUnit.Month || data.timeUnit === ActivityTimeUnit.Year)) {
    timestamps = timestamps.filter((timestamp) => timestamp.isSameOrAfter(minTimestamp));
  }

  const datasets = data.programPerformances.map((programPerformance) =>
    new Dataset(
      programPerformance.programName || "(No program)",
      timestamps.map((timestamp) =>
        programPerformance.dataPoints
          .find((dataPoint) => dataPoint.timestamp.isSame(timestamp))?.migrationCount || null
      )
    )
  );

  return { timestamps: timestamps.map((timestamp) => moment(timestamp)), datasets };
}

const Panels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;

  ${Panel} {
    margin: 1rem 1rem 1rem 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const DataPoints = styled(SimpleToolbar)`
  padding: 0 1rem;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0;
  align-items: center;

  &:first-of-type {
    padding-left: 2rem;
  }

  &:last-of-type {
    padding-right: 2rem;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  text-align: center;
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: .8rem;
  text-align: center;
`;

const Value = styled.div`
  color: #606060;
  font-size: 7rem;
  font-weight: 400;
  height: 8rem;
`;

interface IndicatorProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  children: React.ReactNode;
}

const Indicator: React.FunctionComponent<IndicatorProps> = (props) => (
  <Layout>
    <Title>{props.title}</Title>
    <SubTitle>{props.subTitle || <>&nbsp;</>}</SubTitle>
    <Value>{props.children}</Value>
  </Layout>
);

interface NumericIndicatorProps extends Omit<IndicatorProps, "children"> {
  value: number;
}

const NumericIndicator: React.FunctionComponent<NumericIndicatorProps> = (props) => (
  <Indicator title={props.title} subTitle={props.subTitle}>
    {commafy(props.value)}
  </Indicator>
);

interface DollarAmountIndicatorProps extends Omit<IndicatorProps, "children"> {
  amount: number;
}

const DollarSign = styled.span`
  font-size: 5rem;
  font-weight: 300;
  padding-right: 0.5rem;
`;

const Cents = styled.span`
  position: relative;
  top: -1.3rem;
  font-size: 5rem;
  font-weight: 300;
  padding-left: 0.5rem;
`;

const DollarAmountIndicator: React.FunctionComponent<DollarAmountIndicatorProps> = (props) => {
  const parts = commafy(props.amount, 2).split(".");
  return (
    <Indicator title={props.title} subTitle={props.subTitle}>
      <DollarSign>$</DollarSign>
      {parts[0]}
      {parts.length > 0 && <Cents>.{parts[1]}</Cents>}
    </Indicator>
  );
};

const LinksContainer = styled.div`
  text-align: center;
`;

const Links = styled.div`
  display: inline-block;
`;

function widgetControlMixin(primary: boolean) {
  return css`
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: ${(props) => primary ? props.theme.colors.primary : props.theme.colors.white};
    color: ${(props) => primary ? props.theme.colors.white : props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.7rem 3rem;
    text-align: center;
    border-radius: 0.15rem;
    margin-bottom: .8rem;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      transition: 0.1s;
      box-shadow: 0 2px 1px rgba(124,124,124,0.6);
      transform: scale(1.01);
      filter: brightness(110%);
      text-decoration: none;
    }
  `;
}

// The dollar sign prevents passing property to the DOM elements which raises the following warning:
// "Received `true` for a non-boolean attribute `primary`. If you want to write it to the DOM, pass a string
// instead: primary="true" or primary={value.toString()}."
const WidgetLink = styled(Link)<{ $primary: boolean }>`
  ${(props) => widgetControlMixin(props.$primary)};
`;

const WidgetButton = styled.button<{ $primary: boolean }>`
  ${(props) => widgetControlMixin(props.$primary)};
`;
