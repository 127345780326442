import * as React from "react";
import {
  BatchConnectionStepView
} from "../../views/screens/migrationSetup/batch/batchConnectionStepView/batchConnectionStepView";
import { CloudService } from "../../types/models/cloudService";
import { useSession } from "../../utils/useAppState";
import { ConnectionSettings, ResolvedConnectionSettings } from "./connectionSettings";
import { useConnectGoogleAdminAccountController } from "./connectGoogleAdminAccountController";
import { useConnectMicrosoftAdminAccountController } from "./connectMicrosoftAdminAccountController";
import { useBrowser } from "../../utils/useBrowser";
import { useRoutes } from "../../app/routes/useRoutes";

interface Props {
  step: number;
  totalSteps: number;

  source: boolean;
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  connectionSettings: ResolvedConnectionSettings | undefined;

  onApply: (data: ConnectionSettings) => void;
  onComplete: (data: ConnectionSettings) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const BatchConnectionStep: React.FunctionComponent<Props> = (props) => {
  const session = useSession();
  const browser = useBrowser();
  const routes = useRoutes();

  const connectGoogleAdminAccountController = useConnectGoogleAdminAccountController(
    (adminConnectionId) => props.onApply({
      mode: "admin",
      adminConnectionId
    }),
    () => props.onApply({
      mode: "admin",
      adminConnectionId: undefined
    })
  );

  const connectMicrosoftAdminAccountController = useConnectMicrosoftAdminAccountController(
    (adminConnectionId) => props.onApply({
      mode: "admin",
      adminConnectionId
    }),
    () => props.onApply({
      mode: "admin",
      adminConnectionId: undefined
    })
  );

  return session
    ? (
      <BatchConnectionStepView
        step={props.step}
        totalSteps={props.totalSteps}

        source={props.source}
        cloudService={props.source ? props.sourceCloudService : props.destinationCloudService}
        connectLink={
          browser.url(
            routes.connect.pathForParams({
              userId: session.user.id,
              sourceCloudServiceId: props.sourceCloudService.id,
              destinationCloudServiceId: props.destinationCloudService.id,
              isSource: props.source
            })
          )
        }

        connectionSettings={props.connectionSettings}
        connectGoogleAdminAccountController={connectGoogleAdminAccountController}
        connectMicrosoftAdminAccountController={connectMicrosoftAdminAccountController}

        onApply={props.onApply}
        onSubmit={props.onComplete}
        onNavigateBack={props.onNavigateBack}
      />
    )
    : null;
};
