import * as React from "react";
import { styled } from "../../../../app/theme";

export const CarouselButton = styled.div`
  display: flex;
  padding: 2rem 0 0;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 2rem 0 0;
  }
`;
