import * as React from "react";
import { ExternalHelpArticle } from "../types/models/externalHelpArticle";
import { buildQueryString } from "../utils/misc";
import { Map } from "immutable";
import { CloudServices } from "../types/models/cloudServices";

export namespace HelpArticles {
  const prefix = "/api/helpArticle/";

  export const skippedItems: ExternalHelpArticle = {
    content: prefix + "report/skipped-items",
    title: "What is a skipped item",
    summary: "What is a skipped item",
  };

  export const migrationReport: ExternalHelpArticle = {
    content: prefix + "report/about-report",
    title: "How to use detailed migration report",
    summary: "How to use detailed migration report",
  };

  export const outlookNotEnabled: ExternalHelpArticle = {
    content: prefix + "authentication/outlook-not-enabled",
    title: "Outlook is not accessible",
    summary: undefined
  };

  export const googleGroupConfiguration: ExternalHelpArticle = {
    content: prefix + "edu-portal/google-group-configuration",
    title: "Import from Google Group",
    summary: undefined
  };

  export function oAuthAccountSwitch(cloudServiceName: string): ExternalHelpArticle {
    return {
      content: prefix + "authentication/oauth-account-switch" + "?" +
        buildQueryString(Map([["cloud_service_name", cloudServiceName]])),
      title: "How to sign in to the right " + cloudServiceName + " account",
      summary: undefined
    };
  }

  function cloudServiceNames(
    cloudServices: CloudServices,
    sourceCloudServiceId: string,
    destinationCloudServiceId: string
  ): [string, string | undefined] {
    return [
      cloudServices.getOrFail(sourceCloudServiceId).name,
      sourceCloudServiceId !== destinationCloudServiceId
        ? cloudServices.getOrFail(destinationCloudServiceId).name
        : undefined
    ];
  }

  export function delaysArticle(
    url: string,
    title: string,
    sourceCloudServiceName: string,
    destinationCloudServiceName: string | undefined
  ): ExternalHelpArticle {
    return {
      content: prefix + url + "?" + buildQueryString(
        Map([["source_cloud_service_name", sourceCloudServiceName]]).concat(
          destinationCloudServiceName
            ? Map([["destination_cloud_service_name", destinationCloudServiceName]])
            : Map()
        )
      ),
      title,
      summary: undefined
    };
  }

  export function delaysArePossible(
    sourceCloudServiceName: string,
    destinationCloudServiceName: string | undefined
  ): ExternalHelpArticle {
    return delaysArticle(
      "time-estimate/delays-are-possible",
      "Why migration time may be extended",
      sourceCloudServiceName,
      destinationCloudServiceName
    );
  }

  export function delaysArePossibleEx(
    cloudServices: CloudServices,
    sourceCloudServiceId: string,
    destinationCloudServiceId: string
  ): ExternalHelpArticle {
    const [sourceCloudServiceName, destinationCloudServiceName] =
      cloudServiceNames(cloudServices, sourceCloudServiceId, destinationCloudServiceId);
    return delaysArePossible(sourceCloudServiceName, destinationCloudServiceName);
  }

  export function experiencingDelays(
    sourceCloudServiceName: string,
    destinationCloudServiceName: string | undefined
  ): ExternalHelpArticle {
    return delaysArticle(
      "time-estimate/experiencing-delays",
      "Why migration is delayed",
      sourceCloudServiceName,
      destinationCloudServiceName
    );
  }

  export function experiencingDelaysEx(
    cloudServices: CloudServices,
    sourceCloudServiceId: string,
    destinationCloudServiceId: string
  ): ExternalHelpArticle {
    const [sourceCloudServiceName, destinationCloudServiceName] =
      cloudServiceNames(cloudServices, sourceCloudServiceId, destinationCloudServiceId);
    return experiencingDelays(sourceCloudServiceName, destinationCloudServiceName);
  }
}
