import { Migration } from "../../../../types/models/migration";
import * as React from "react";
import { ModalController, useModal } from "../../../layouts/modal";
import { Button } from "../../../widgets/button";
import { OperationStatus } from "../../../../types/operationStatus";
import { Constants } from "../../../../app/constants";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import { DrawerBlock } from "../../../containers/drawerBlock";
import { PauseIcon } from "../../../glyphs/pauseIcon";

export interface PauseMigrationViewProps {
  status: OperationStatus<Migration>;
  onPause: () => Promise<Migration>;
}

export interface PauseMigrationControllerProps {
  render: (viewProps: PauseMigrationViewProps) => React.ReactElement;
}

export type PauseMigrationControllerType = React.ComponentType<PauseMigrationControllerProps>;

interface Props {
  migration: Migration;
  pauseMigrationController: PauseMigrationControllerType;
}

export const PauseMigrationToolRow: React.FunctionComponent<Props> = (props) => {
  const pauseMigrationModal = useModal();

  return (
    <>
      <ModalController
        modal={pauseMigrationModal}
        render={(close) => React.createElement(props.pauseMigrationController, {
          render: (viewProps) => (
            <PauseMigrationTool
              migration={props.migration}
              status={viewProps.status}
              onPause={() => viewProps.onPause().then((result) => {
                setTimeout(close, Constants.SuccessIndicatorTimeout);
                return result;
              })}
            />
          )
        })}
      />

      <ToolsRow
        icon={<PauseIcon/>}
        header={"Pause migration to continue at a later time"}
      >
        <Button color={"white"} size={"small"} onClick={pauseMigrationModal.open}>Pause Migration</Button>
      </ToolsRow>
    </>
  );
};

interface PauseMigrationToolProps {
  migration: Migration;
  status: OperationStatus<Migration>;
  onPause: () => Promise<Migration>;
}

const PauseMigrationTool: React.FunctionComponent<PauseMigrationToolProps> = (props) => (
  <>
    <DrawerBlock>
      Click the button below to pause your migration. You will be able to resume it a later time.
    </DrawerBlock>
    <DrawerBlock>
      To avoid data loss or corruption, VaultMe will gracefully complete all in-progress transactions
      before suspending. Depending on the current state of the migration, this process can take up to 1 hour.
    </DrawerBlock>
    <DrawerBlock>
      <Button size={"narrow"} onClick={props.onPause} disabled={props.status.isWorking()}>Pause Migration</Button>
    </DrawerBlock>
    <OperationStatusIndicator
      progressMessage={"Pausing migration..."}
      successMessage={(result) => "Success, migration is pausing now"}
      failureMessage={"Failed to pause migration"}
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
  </>
);
