import { styled } from "../../app/theme";

export const ColorSample = styled.div<{ color: string }>`
  display: inline-block;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  top: 2px;
  border-radius: 3px;
  background: ${(props) => props.color};
`;
