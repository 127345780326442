import * as React from "react";
import { styled } from "../../../app/theme";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormButtons, FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { PasswordField } from "../../widgets/passwordField";
import { CheckboxField } from "../../widgets/checkboxField";
import { NewTabLink } from "../../widgets/newTabLink";
import { Button } from "../../widgets/button";
import { Constants } from "../../../app/constants";
import { FormField } from "../../widgets/formField";
import { LinkButton } from "../../widgets/linkButton";
import { PreparedHelpArticle } from "../../../utils/preparedHelpArticle";
import { InfoButton } from "../../widgets/infoButton";

export interface ImapSignInFormData {
  login: string;
  password: string;
  host?: string;
  port?: number;
  useSSL?: boolean;
  accept: boolean;
}

export namespace ImapSignInFormData {
  export const Default: ImapSignInFormData = {
    login: "",
    password: "",
    host: "",
    port: 993,
    useSSL: true,
    accept: false
  };

  export function merge(
    defaults: ImapSignInFormData,
    overrides: Partial<ImapSignInFormData> | undefined): ImapSignInFormData {
    if (overrides) {
      return {
        login: overrides.login !== undefined ? overrides.login : defaults.login,
        password: overrides.password !== undefined ? overrides.password : defaults.password,
        host: overrides.host !== undefined ? overrides.host : defaults.host,
        port: overrides.port !== undefined ? overrides.port : defaults.port,
        useSSL: overrides.useSSL !== undefined ? overrides.useSSL : defaults.useSSL,
        accept: overrides.accept !== undefined ? overrides.accept : defaults.accept
      };
    } else {
      return defaults;
    }
  }

  export function prepare(data: ImapSignInFormData): ImapSignInFormData {
    return {
      ...data,
      login: data.login.trim(),
      host: data.host && data.host.trim()
    };
  }
}

function validationSchema(
  cloudServiceName: string | undefined,
  requireHostAndPort: boolean): yup.ObjectSchema<ImapSignInFormData> {
  const cloudServiceNameOrEmpty = cloudServiceName ? cloudServiceName + " " : "";
  const coreShape = {
    login: yup
      .string()
      .required(`Please type your ${cloudServiceNameOrEmpty} email address`),
    password: yup
      .string()
      .required(`Please type your ${cloudServiceNameOrEmpty} password`),
    accept: yup
      .boolean()
      .oneOf([true], `Please accept ${Constants.Product.name} Privacy Policy and Terms of Use`)
  };

  if (requireHostAndPort) {
    return yup.object<ImapSignInFormData>().shape({
      ...coreShape,
      host: yup
        .string()
        .required("Please type IMAP host"),
      port: yup
        .number()
        .required("Please type IMAP port")
        .min(1)
        .max(65535)
        .integer("Please type IMAP port"),
      useSSL: yup
        .boolean()
        .required(),
    });
  } else {
    return yup.object<ImapSignInFormData>().shape({
      ...coreShape,
      host: yup.string().notRequired(),
      port: yup.number().notRequired(),
      useSSL: yup.boolean().notRequired(),
    });
  }
}

interface ConnectionSettingsProps {
  disabled?: boolean;
}

const ConnectionSettings: React.FunctionComponent<ConnectionSettingsProps> = (props) => (
  <React.Fragment>
    <TextField<ImapSignInFormData> label="Host" name="host" disabled={props.disabled}/>
    <TextField<ImapSignInFormData>
      label="Port"
      name="port"
      onChange={(event, field) => {
        const mayBeNumber = parseInt(event.target.value, 10);
        // Anything except for a valid number (including a blank string) will trigger a validation error
        field.setFieldValue(event.target.name, isNaN(mayBeNumber) ? event.target.value : mayBeNumber);
      }}
      disabled={props.disabled}
      maxLength={5}
      maxWidth={10}
      appendix={
        <CheckboxField<ImapSignInFormData>
          label="Use SSL"
          name="useSSL"
          disabled={true}
          noWrap={true}
        />
      }
      appendixMargin={"double"}
    />
  </React.Fragment>
);

interface AdvancedSettingsProps {
  helpArticle?: PreparedHelpArticle;
  visible: boolean;
  disabled?: boolean;
  onChange: (enabled: boolean) => void;
}

const AdvancedSettingsLayout = styled(FormField)`
  display: flex;
  
  ${LinkButton} {
    margin-right: .5rem;
  }
`;

export const AdvancedSettings: React.FunctionComponent<AdvancedSettingsProps> = (props) => (
  <FormField>
    <AdvancedSettingsLayout>
      <LinkButton onClick={() => props.onChange(!props.visible)}>
        {props.visible ? "Disable advanced settings" : "Enable advanced settings"}
      </LinkButton>
      {props.helpArticle && <InfoButton size="small" helpArticle={props.helpArticle}/>}
    </AdvancedSettingsLayout>
  </FormField>
);

export enum ImapSignInFormMode {
  Simple = "Simple",
  SimpleOrAdvanced = "SimpleOrAdvanced",
  Advanced = "Advanced"
}

export interface ImapSignInFormProps {
  serviceName: string | undefined;

  initialValues?: Partial<ImapSignInFormData>;
  loginLocked?: boolean;

  mode: ImapSignInFormMode;
  advancedSettingsHelpArticle?: PreparedHelpArticle;

  disabled?: boolean;
  secondarySubmitButton: boolean; // TODO Is this still required?
  submitButtonText?: string;
  actions?: React.ReactNode;

  onSubmit: (data: ImapSignInFormData) => void;
}

export const ImapSignInForm: React.FunctionComponent<ImapSignInFormProps> = (props) => {
  const [advancedSettings, setAdvancedSettings] = React.useState(false);

  const firstInputRef = React.createRef<HTMLInputElement>();

  React.useEffect(
    () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    },
    []);

  const loginDisabled = props.loginLocked && !advancedSettings;
  const usingAdvancedSettings = props.mode === ImapSignInFormMode.Advanced || advancedSettings;

  return (
    <Formik<ImapSignInFormData>
      initialValues={ImapSignInFormData.merge(ImapSignInFormData.Default, props.initialValues)}
      validationSchema={validationSchema(props.serviceName, usingAdvancedSettings)}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(data, actions) => {
        props.onSubmit(ImapSignInFormData.prepare(data));
        actions.setSubmitting(false);
      }}
      render={(formProps) => (
        <Form>
          <FormLayout>
            <TextField<ImapSignInFormData>
              label={(props.serviceName ? props.serviceName + " " : "") + "Login"}
              name="login"
              disabled={loginDisabled || props.disabled}
              textBoxRef={loginDisabled ? undefined : firstInputRef}
            />
            <PasswordField<ImapSignInFormData>
              label={(props.serviceName ? props.serviceName + " " : "") + "Password"}
              name="password"
              disabled={props.disabled}
              textBoxRef={loginDisabled ? firstInputRef : undefined}
            />
            {usingAdvancedSettings && <ConnectionSettings disabled={props.disabled}/>}
            {props.mode === ImapSignInFormMode.SimpleOrAdvanced && (
              <AdvancedSettings
                helpArticle={props.advancedSettingsHelpArticle}
                visible={advancedSettings}
                disabled={props.disabled}
                onChange={setAdvancedSettings}
              />
            )}
            <CheckboxField<ImapSignInFormData>
              label={(
                <React.Fragment>
                  I agree to the VaultMe&nbsp;
                  <NewTabLink to="https://www.vaultme.com/privacy">Privacy Policy</NewTabLink>
                  &nbsp;and&nbsp;
                  <NewTabLink to="https://www.vaultme.com/terms">Terms of Use</NewTabLink>
                </React.Fragment>
              )}
              name="accept"
              disabled={props.disabled}
            />
            <FormButtons>
              <Button
                color={props.secondarySubmitButton ? "white" : "blue"}
                type="submit"
                disabled={formProps.isSubmitting || props.disabled}
              >
                {
                  props.submitButtonText ||
                  ("Connect" + (props.serviceName ? " to " + props.serviceName : ""))
                }
              </Button>
              {props.actions}
            </FormButtons>
          </FormLayout>
        </Form>
      )}
    />
  );
};
