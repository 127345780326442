import { GraphQL } from "../../services/graphql/generated";

export interface Upload {
  id: string;
  name: string;
  length: number;
}

export namespace Upload {
  export function parse(data: GraphQL.UploadFragment): Upload {
    return {
      id: data.id,
      name: data.name,
      length: data.length
    };
  }
}
