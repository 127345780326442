import { Component, ComponentHubs } from "../component";
import { ComponentBinding } from "./componentBinding";
import { GraphQL } from "../../services/graphql/generated";
import { CollectableItemBreakdown } from "../../types/collectables/collectableItemBreakdown";
import { FactRef } from "../../types/facts/factRef";
import { None, Option, Some } from "../../utils/monads/option";
import { AssociationsHub } from "../hubs/associationsHub";
import { OptionalRelationshipHub } from "../hubs/optionalRelationshipHub";
import { List, OrderedMap } from "immutable";
import { BlueprintContext } from "../blueprintContext";
import { State } from "../state";
import { ComponentVisualization } from "../componentVisualization";
import { Images } from "../../app/images";
import { ComponentColorSchema } from "../componentColorSchema";
import { FactHelper } from "../factHelper";
import { friendlyCount, friendlyCountOf } from "../../utils/formatting";
import { AreaComp } from "./areaComp";
import { ItemBreakdown } from "../../types/itemBreakdown";
import { CollectableInteger } from "../../types/collectables/collectableInteger";
import { ItemsByType } from "../../views/models/itemsByType";
import { IterationFactRef } from "../migrationFactRef";
import { zipOrderedMapsEx } from "../../utils/misc";

export class MigrationProgressComp
  extends Component<MigrationProgressComp.Props, MigrationProgressComp.Hubs, MigrationProgressComp.Output> {

  public stateWhenUnblocked(context: BlueprintContext): State<MigrationProgressComp.Output> {
    const [cumulative] = this.buildOutputMetrics(
      context,
      this.props.cumulativeFactRefs,
      true
    );
    const [iteration, completed] = this.buildOutputMetrics(
      context,
      this.props.iterationFactRefs,
      context.inputs.get(IterationFactRef.IterationInput).contains("0")
    );
    return State.resolved<MigrationProgressComp.Output>(({
      cumulative,
      iteration,
      completed
    }));
  }

  public visualization(context: BlueprintContext, state: State<MigrationProgressComp.Output>): ComponentVisualization {
    return {
      title: "Migration Progress",
      summary: this.props.title + this.getSummary(context)
        .map((summary) =>
          "\n" + friendlyCountOf(summary.processed, summary.total, "item") + " processed" +
          "\n" + friendlyCount(summary.skipped, "item") + " skipped"
        )
        .getOrElse(() => ""),
      icon: Images.Blueprint.Progress,
      color: ComponentColorSchema.SolidCoral,
      sizeMultiplier: 1.5
    };
  }

  public unblockedFactIds(context: BlueprintContext): List<string> {
    return this.props.cumulativeFactRefs.asList
      .flatMap((factRef) => FactHelper.resolvedFactRef(context, this, factRef).toList())
      .concat(
        this.props.iterationFactRefs.asList
          .flatMap((factRef) => FactHelper.resolvedFactRef(context, this, factRef).toList())
      )
      .map(((factRef) => factRef.id));
  }

  protected buildOutputMetrics(
    context: BlueprintContext,
    factRefs: MigrationProgressComp.Props.FactRefs,
    useEstimates: boolean
  ): [MigrationProgressComp.Output.Metrics, boolean] {
    const totalBytesState = useEstimates
      ? this.hubs.totalBytes.state(context)
      : State.resolved(None<Option<CollectableInteger>>());
    const totalItemsState = useEstimates
      ? this.hubs.totalItems.state(context)
      : State.resolved(None<Option<CollectableItemBreakdown>>());

    const facts = this.getFacts(context, factRefs);

    const estimatedBytesOpt = Option.flatten2(totalBytesState.output).map((output) => output.currentValue());
    const estimatedItemsOpt = Option.flatten2(totalItemsState.output).map((output) => output.currentValue());

    const enumeratedOrEstimatedItemsOpt = facts.enumeratedItemsOpt
      .flatMap((enumeratedItems) =>
        enumeratedItems.value.fold(() => estimatedItemsOpt, (c) => Some(c))
      )
      .orElse(() => estimatedItemsOpt)
      .map((value) => this.prepareOutput(value));

    const processedItems = this.prepareOutputOpt(
      facts.processedItemsOpt.map((value) => value.currentValue())
    );

    const skippedItems = this.prepareOutputOpt(
      facts.skippedItemsOpt.map((value) => value.currentValue())
    );

    const totalItemsOpt = enumeratedOrEstimatedItemsOpt.map((enumeratedOrEstimatedItems) =>
      zipOrderedMapsEx(
        enumeratedOrEstimatedItems,
        zipOrderedMapsEx(
          processedItems,
          skippedItems,
          (a, b) => a + b
        ),
        (a, b) => Math.max(a, b)
      )
    );

    const progress = totalItemsOpt
      .map((totalItems) => {
        const totalCount = new ItemsByType(totalItems).totalCount();
        return totalCount ? Math.min(1, new ItemsByType(processedItems).totalCount() / totalCount) : 0;
      })
      .getOrUse(0);

    const enumeratedOrEstimatedBytesOpt = facts.enumeratedBytesOpt
      .flatMap((enumeratedBytes) =>
        enumeratedBytes.value.fold(() => estimatedBytesOpt, (c) => Some(c))
      )
      .orElse(() => estimatedBytesOpt);

    const processedBytes = facts.processedBytesOpt
      .map((collectable) => collectable.currentValue())
      .getOrElse(() =>
        enumeratedOrEstimatedBytesOpt.map((totalBytes) => Math.round(totalBytes * progress)).getOrUse(0)
      );

    const skippedBytes = facts.skippedBytesOpt
      .map((value) => value.currentValue())
      .getOrUse(0);

    const totalBytesOpt = enumeratedOrEstimatedBytesOpt
      .map((enumeratedOrEstimatedBytes) => Math.max(enumeratedOrEstimatedBytes, processedBytes + skippedBytes));

    return [
      {
        estimatedBytes: estimatedBytesOpt,
        estimatedItems: estimatedItemsOpt.map((estimated) => this.prepareOutput(estimated)),

        enumeratedBytes: facts.enumeratedBytesOpt
          .map((enumeratedBytes) => enumeratedBytes.currentValue()),
        enumeratedItems: facts.enumeratedItemsOpt
          .map((enumeratedItems) => this.prepareOutput(enumeratedItems.currentValue())),

        totalBytes: totalBytesOpt,
        totalItems: totalItemsOpt,

        processedBytes,
        processedItems,

        skippedBytes,
        skippedItems
      },
      facts.processedItemsOpt.exists((value) => value.isComplete())
    ];
  }

  protected getFacts(context: BlueprintContext, factRefs: MigrationProgressComp.Props.FactRefs) {
    return {
      enumeratedBytesOpt: FactHelper.getCollectable<number, number, CollectableInteger>(
        context, this, factRefs.bytesEnumerated
      ),
      // enumeratedBytesOpt: None<CollectableInteger>(),
      enumeratedItemsOpt: FactHelper.getCollectable<ItemBreakdown, ItemBreakdown, CollectableItemBreakdown>(
        context, this, factRefs.itemsEnumerated
      ),

      processedBytesOpt: FactHelper.getCollectable<number, number, CollectableInteger>(
        context, this, factRefs.bytesProcessed
      ),
      // processedBytesOpt: None<CollectableInteger>(),
      processedItemsOpt: FactHelper.getCollectable<ItemBreakdown, ItemBreakdown, CollectableItemBreakdown>(
        context, this, factRefs.itemsProcessed
      ),

      skippedBytesOpt: FactHelper.getCollectable<number, number, CollectableInteger>(
        context, this, factRefs.bytesSkipped
      ),
      // skippedBytesOpt: None<CollectableInteger>(),
      skippedItemsOpt: FactHelper.getCollectable<ItemBreakdown, ItemBreakdown, CollectableItemBreakdown>(
        context, this, factRefs.itemsSkipped
      )
    };
  }

  protected getSummary(context: BlueprintContext): Option<{ total: number, processed: number, skipped: number }> {
    const { enumeratedItemsOpt, processedItemsOpt, skippedItemsOpt } =
      this.getFacts(context, this.props.cumulativeFactRefs);

    return enumeratedItemsOpt.flatMap((enumeratedItems) =>
      processedItemsOpt.flatMap((processedItems) =>
        skippedItemsOpt.map((skippedItems) => ({
          total: enumeratedItems.currentValue().sum(),
          processed: processedItems.currentValue().sum(),
          skipped: skippedItems.currentValue().sum(),
        }))
      )
    );
  }

  protected itemTypeAliases(): List<[string, List<GraphQL.ItemType>]> {
    const area = this.relationships()
      .flatMap((relationship) => relationship.componentOpt().toList())
      .filter((component) => component instanceof AreaComp)
      .map((component) => component as AreaComp)
      .first(undefined);
    return area ? area.props.itemTypeAliases : List();
  }

  protected prepareOutput(itemBreakdown: ItemBreakdown): OrderedMap<string, number> {
    return OrderedMap(
      this.itemTypeAliases().map(([alias, itemTypes]) => [
        alias,
        itemTypes
          .flatMap((itemType) => itemBreakdown.getTotal(itemType).toList())
          .reduce((a, b) => a + b, 0)
      ])
    );
  }

  protected prepareOutputOpt(itemBreakdownOpt: Option<ItemBreakdown>): OrderedMap<string, number> {
    return OrderedMap(
      this.itemTypeAliases().map(([alias, itemTypes]) => [
        alias,
        itemTypes
          .flatMap((itemType) =>
            itemBreakdownOpt.flatMap((itemBreakdown) => itemBreakdown.getTotal(itemType)).toList()
          )
          .reduce((a, b) => a + b, 0)
      ])
    );
  }
}

export namespace MigrationProgressComp {
  export interface Props {
    title: string;
    itemCategory: Option<string>;

    cumulativeFactRefs: Props.FactRefs;
    iterationFactRefs: Props.FactRefs;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.MigrationProgressCompProps): Props {
      return {
        title: props.title,
        itemCategory: Option.mayBe(props.itemCategory),

        cumulativeFactRefs: FactRefs.fromFactRefsGraphQL(props.cumulativeFactRefs),
        iterationFactRefs: FactRefs.fromFactRefsGraphQL(props.iterationFactRefs)
      };
    }

    export interface FactRefs {
      bytesEnumerated: FactRef.Props;
      itemsEnumerated: FactRef.Props;

      bytesProcessed: FactRef.Props;
      itemsProcessed: FactRef.Props;

      bytesSkipped: FactRef.Props;
      itemsSkipped: FactRef.Props;

      asList: List<FactRef.Props>;
    }

    export namespace FactRefs {
      export function fromFactRefsGraphQL(
        factRefs: GraphQL.MigrationProgressCompProps_CumulativeFactRefs |
          GraphQL.MigrationProgressCompProps_IterationFactRefs
      ): FactRefs {
        const bytesEnumerated = FactRef.Props.fromGraphQL(factRefs.bytesEnumerated);
        const itemsEnumerated = FactRef.Props.fromGraphQL(factRefs.itemsEnumerated);

        const bytesProcessed = FactRef.Props.fromGraphQL(factRefs.bytesProcessed);
        const itemsProcessed = FactRef.Props.fromGraphQL(factRefs.itemsProcessed);

        const bytesSkipped = FactRef.Props.fromGraphQL(factRefs.bytesSkipped);
        const itemsSkipped = FactRef.Props.fromGraphQL(factRefs.itemsSkipped);

        return {
          bytesEnumerated,
          itemsEnumerated,

          bytesProcessed,
          itemsProcessed,

          bytesSkipped,
          itemsSkipped,

          asList: List([
            bytesEnumerated,
            itemsEnumerated,

            bytesProcessed,
            itemsProcessed,

            bytesSkipped,
            itemsSkipped,
          ])
        };
      }
    }
  }

  export interface Hubs extends ComponentHubs {
    totalBytes: OptionalRelationshipHub<Option<CollectableInteger>>;
    totalItems: OptionalRelationshipHub<Option<CollectableItemBreakdown>>;
    endOfEnumerationPhases: AssociationsHub;
    endOfProcessingPhases: AssociationsHub;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.MigrationProgressCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        totalBytes: binding.optionalRelationshipHub("totalBytes", hubs.totalBytes),
        totalItems: binding.optionalRelationshipHub("totalItems", hubs.totalItems),
        endOfEnumerationPhases: binding.associationsHub("endOfEnumerationPhases", hubs.endOfEnumerationPhases),
        endOfProcessingPhases: binding.associationsHub("endOfProcessingPhases", hubs.endOfProcessingPhases),
      };
    }
  }

  export interface Output {
    cumulative: Output.Metrics;
    iteration: Output.Metrics;
    completed: boolean;
  }

  export namespace Output {
    export interface Metrics {
      estimatedBytes: Option<number>;
      estimatedItems: Option<OrderedMap<string, number>>;

      enumeratedBytes: Option<number>;
      enumeratedItems: Option<OrderedMap<string, number>>;

      totalBytes: Option<number>;
      totalItems: Option<OrderedMap<string, number>>;

      processedBytes: number;
      processedItems: OrderedMap<string, number>;

      skippedBytes: number;
      skippedItems: OrderedMap<string, number>;
    }

    export namespace Metrics {
      function parseEntries(entries: { itemType: string, count: number}[]): OrderedMap<string, number> {
        return OrderedMap(entries.map((entry) => [entry.itemType, entry.count]));
      }

      export function fromMetricsGraphQL(output: GraphQL.MigrationProgressCompOutput_Metrics): Metrics {
        return {
          estimatedBytes: Option.mayBe(output.estimatedBytes),
          estimatedItems: Option.mayBe(output.estimatedItems).map(parseEntries),

          enumeratedBytes: Option.mayBe(output.enumeratedBytes),
          enumeratedItems: Option.mayBe(output.enumeratedItems).map(parseEntries),

          totalBytes: Option.mayBe(output.totalBytes),
          totalItems: Option.mayBe(output.totalItems).map(parseEntries),

          processedBytes: output.processedBytes,
          processedItems: parseEntries(output.processedItems),

          skippedBytes: output.skippedBytes,
          skippedItems: parseEntries(output.skippedItems),
        };
      }
    }

    export function fromGraphQL(output: GraphQL.MigrationProgressCompOutput): Output {
      return {
        cumulative: Metrics.fromMetricsGraphQL(output.cumulative),
        iteration: Metrics.fromMetricsGraphQL(output.iteration),
        completed: output.completed
      };
    }
  }
}
