import * as React from "react";
import { Constants } from "../../app/constants";
import { AuthProviders } from "../../types/models/authProviders";
import { Page } from "../containers/page";
import { Panel } from "../containers/panel";
import { Block } from "../containers/block";
import { Set } from "immutable";
import { SignInFlowState } from "../../components/auth/useSignInFlow";
import { CloudServices } from "../../types/models/cloudServices";
import { PanelRow } from "../containers/rows/panelRow";
import { SignInFlowView } from "../blocks/auth/signInFlowView";
import { SignInDefs } from "../blocks/auth/signInDefs";
import { AuthContext } from "../blocks/authContext";

interface SignInPageViewProps {
  requireElevation: boolean;

  cloudServices: CloudServices;
  authProviders: AuthProviders;

  state: SignInFlowState;

  defaultRoles: Set<string>;

  onCloudServiceSelect: (cloudServiceId: string) => void;
  onRolesSelect: (roles: Set<string>) => void;
  onSignIn: SignInDefs.SignInHandle;
}

export const SignInPageView: React.FunctionComponent<SignInPageViewProps> = (props) => (
  <Page
    title={"Sign in"}
    subTitle={
      props.requireElevation
        ? `Sign in as an administrator to view this page`
        : `Sign in using any account you have previously used with ${Constants.Product.title}`
    }
    // Makes header red
    error={props.requireElevation}
  >
    <Block>
      <Panel>
        <>
          {props.state.type === SignInFlowState.Type.SelectingCloudService && (
            <PanelRow>
              Click the button below to choose the type of account you have used previously
              with {Constants.Product.title}
            </PanelRow>
          )}
          <SignInFlowView
            authContext={AuthContext.Identification}
            cloudServices={props.cloudServices}
            authProviders={props.authProviders}
            state={props.state}
            defaultRoles={props.defaultRoles}
            onCloudServiceSelect={props.onCloudServiceSelect}
            onRolesSelect={props.onRolesSelect}
            onSignIn={props.onSignIn}
          />
        </>
      </Panel>
    </Block>
  </Page>
);
