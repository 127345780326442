import * as React from "react";
import { OAuthSignInFormProps } from "./oAuthSignInForm";
import { OAuthAndServiceAccountSignInForm, ServiceAccountSignInConfig } from "./oAuthAndServiceAccountSignInForm";

export interface GoogleSignInFormProps extends OAuthSignInFormProps {
  offerAccountCreation: boolean;
  serviceAccountSignInConfig: ServiceAccountSignInConfig | undefined;
}

export const GoogleSignInForm: React.FunctionComponent<GoogleSignInFormProps> = (props) => (
  <OAuthAndServiceAccountSignInForm
    {...props}
    additionalOptions={
      props.offerAccountCreation
        ? [(
          <React.Fragment key={"offerAccountCreation"}>
            Don’t have a Google Account?
            You can either{" "}
            <a target={"_blank"} href={"https://accounts.google.com/SignUp"}>Sign up for Free Gmail</a>
            {" "}or{" "}
            <a target={"_blank"} href={"https://referworkspace.app.goo.gl/kjxY"}>Sign up for Google Workspace</a>.
          </React.Fragment>
        )]
        : []
    }
  />
);
