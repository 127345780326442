import { ComponentColorSchema } from "../componentColorSchema";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";

export interface ComponentSettings {
  sizeMultiplier: number;
  color: ComponentColorSchema | undefined;
  icon: string | undefined;
  anchor: string | undefined;
}

export namespace ComponentSettings {
  export function fromGraphQL(schema: GraphQL.ComponentSettings): ComponentSettings {
    return {
      sizeMultiplier: schema.sizeMultiplier,
      color: mapOptional(schema.color, ComponentColorSchema.fromGraphQL),
      icon: nullToUndefined(schema.icon),
      anchor: nullToUndefined(schema.anchor),
    };
  }
}
