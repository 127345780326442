import * as React from "react";
import moment from "moment";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Panel } from "../../containers/panel";
import { Block } from "../../containers/block";
import { MigrationsGrid } from "./migrationsGrid";
import { Overview } from "./overview";
import { CenteredContent } from "../../layouts/centeredContent";
import { styled } from "../../../app/theme";
import { PanelRow } from "../../containers/rows/panelRow";
import { MonitoringDashboardPageViewDefs } from "./monitoringDashboardPageViewDefs";
import { RefreshingDataSourceTimer, RefreshingDataSource } from "../../utils/refreshingDataSource";

const HeaderRowLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: 500;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.75rem;
  }  
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
  }  
`;

interface Props {
  dataSource: RefreshingDataSource<any, MonitoringDashboardPageViewDefs.MonitoringDashboardData>;

  completedNormallyCount: number;
  completedNormallyWindows: number[];
  onCompletedNormallyCountChange: (value: number) => void;
}

export const MonitoringDashboardPageView: React.FunctionComponent<Props> = (props) => {
  const data = props.dataSource.status.someResult();
  const timeZoneOffset = moment().utcOffset() / 60;

  return (
    <CenteredContent fullWidth={true}>
      <Panel>
        <PanelRow>
          <HeaderRowLayout>
            <Title>Migrations</Title>
            <RefreshingDataSourceTimer dataSource={props.dataSource}/>
          </HeaderRowLayout>
        </PanelRow>
      </Panel>
      <OperationStatusIndicator
        status={props.dataSource.status}
        subject={"migrations"}
        indicators={StatusIndicators.SimplePanel({ loadingDelay: 2000 })}
      />
      {data && (
        <>
          <Overview
            data={data}
            timeZoneOffset={Number.isInteger(timeZoneOffset) ? timeZoneOffset : 0}
          />
          <Block>
            <Panel>
              <MigrationsGrid
                completedWithIssues={data.completedWithIssues}

                requireManualReview={data.requireManualReview}
                runningWithIssues={data.runningWithIssues}
                delayed={data.delayed}
                waitingForUserAction={data.waitingForUserAction}

                runningNormally={data.runningNormally}

                completedNormally={data.completedNormally}

                completedNormallyCount={props.completedNormallyCount}
                completedNormallyWindows={props.completedNormallyWindows}
                onCompletedNormallyCountChange={props.onCompletedNormallyCountChange}
              />
            </Panel>
          </Block>
        </>
      )}
    </CenteredContent>
  );
};
