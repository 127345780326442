import { useNavigate } from "react-router";
import { isAbsoluteUrl } from "./misc";

export interface BrowserHook {
  href: (path: string) => string;
  url: (path: string) => string;
  navigateTo: (pathOrUrl: string) => void;
  openNewTab: (pathOrUrl: string, forceAbsolute?: boolean) => void;
  refresh: () => void;
}

// The function below is not fancy at all, but without it, we'd have to jump through lots of hoops with React Router.
// In 6.x, they have moved the href building logic into a hook which would not work in some scenarios. The code
// from the hook can be re-used here, but it's too complicated for something so simple. So, just remember to
// update this logic if router configuration changes.
export function hrefFromPath(path: string): string {
  return "/#" + (path.startsWith("/") ? "" : "/") + path;
}

export function urlFromPath(path: string): string {
  return window.location.origin + hrefFromPath(path);
}

export function useBrowser(): BrowserHook {
  const navigate = useNavigate();

  return {
    href: hrefFromPath,
    url: urlFromPath,
    navigateTo: (pathOrUrl) =>
      isAbsoluteUrl(pathOrUrl)
        ? window.open(pathOrUrl, "_blank") // External links are always opened in a new tab
        : navigate(pathOrUrl),

    openNewTab: (pathOrUrl, forceAbsolute?: boolean) =>
      window.open(
        isAbsoluteUrl(pathOrUrl) || forceAbsolute ? pathOrUrl : hrefFromPath(pathOrUrl),
        "_blank"
      ),
    refresh: () => location.reload()
  };
}
