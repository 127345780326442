import { FactRef } from "../types/facts/factRef";
import { Blueprint } from "./blueprint";
import { AnyComponent } from "./component";
import { BlueprintContext } from "./blueprintContext";
import { Collectable } from "../types/collectables/collectable";

export abstract class BlueprintFactRef<P, C, T extends Collectable<P, C>, Ctx extends FactRef.Context>
  extends FactRef<P, C, T, Ctx, BlueprintFactRef.Context> {
}

export namespace BlueprintFactRef {
  export interface Context {
    blueprint: Blueprint;
    blueprintContext: BlueprintContext;
    component: AnyComponent;
  }
}
