import { School } from "../../types/models/school";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { SchoolFormData } from "../../types/models/schoolFormData";

type UpdateSchoolMutationHook = [
  (id: string, formData: SchoolFormData) => Promise<School>,
  OperationStatus<School>
];

export function useUpdateSchoolMutation(): UpdateSchoolMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useUpdateSchoolMutation,
    extract: (data: GraphQL.UpdateSchoolMutation) => nullToUndefined(data.updateSchool),
    complete: School.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "These domains are already being used" })
      ]
    ]
  });

  function fireWith(id: string, formData: SchoolFormData): Promise<School> {
    return fire({
      variables: { id, formData: SchoolFormData.toGraphQL(formData) },
      retry: () => fireWith(id, formData),
    });
  }

  return [fireWith, status];
}
