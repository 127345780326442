import * as React from "react";
import * as yup from "yup";
import { CarouselAssets } from "../views/screens/migrationSetup/carouselStepView/carouselAssets";
import { CarouselStepView } from "../views/screens/migrationSetup/carouselStepView/carouselStepView";
import { ConfigurationIntroStep } from "./migrationSetupWorkflowContext";
import { CarouselStep, CarouselSteps } from "../views/screens/migrationSetup/carouselStepView/carouselSteps";
import { useRoutes } from "../app/routes/useRoutes";

export interface IntroStatus {
  complete: number;
}

export namespace IntroStatus {
  export const Empty: IntroStatus = {
    complete: 0
  };

  export const Schema = yup.object<IntroStatus>({
    complete: yup.number().required()
  });
}

interface IntroStepProps {
  configurationIntroStep: ConfigurationIntroStep | undefined;
  configurationPricingPage: ConfigurationIntroStep | undefined;
  status: IntroStatus;

  onApply: (step: IntroStatus) => void;
  onComplete: (step: IntroStatus) => void;
}

export const IntroStep: React.FunctionComponent<IntroStepProps> = (props) => {
  const routes = useRoutes();

  const [current, setCurrent] = React.useState(0);

  const steps: CarouselStep[] = CarouselSteps.slice();
  if (props.configurationIntroStep) {
    steps[0] = {
      image: props.configurationIntroStep.bannerId
        ? routes.api.downloadUrl(props.configurationIntroStep.bannerId)
        : "",
      title: props.configurationIntroStep.title,
      subTitle: props.configurationIntroStep.content,
      reviews: steps[0].reviews
    };
  }
  if (props.configurationPricingPage) {
    steps.splice(steps.length - 1, 0, {
      image: props.configurationPricingPage.bannerId
        ? routes.api.downloadUrl(props.configurationPricingPage.bannerId)
        : "",
      title: props.configurationPricingPage.title,
      subTitle: props.configurationPricingPage.content,
      reviews: steps[0].reviews
    });
  }

  function select(step: number) {
    setCurrent(step);
    props.onApply({ complete: props.status.complete >= 0 ? Math.max(props.status.complete, step) : -1 });
  }

  function next(selectedStep: number) {
    if (selectedStep === steps.length - 1) {
      skip();
    } else {
      select(selectedStep + 1);
    }
  }

  function skip() {
    props.onComplete({ ...props.status, complete: -1 });
  }

  const preparedCurrent = current < steps.length ? current : 0;

  return (
    <>
      <CarouselAssets/>
      <CarouselStepView
        stepCount={steps.length}
        selectedStep={preparedCurrent}
        stepConfig={steps[preparedCurrent]}

        onNext={next}
        onSelect={select}
        onSkip={skip}
      />
    </>
  );
};
