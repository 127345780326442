import * as React from "react";
import { ServerLabel } from "./serverLabel";
import { State } from "../state";
import { BlueprintDiff } from "../blueprintDiff";
import { DiffLabel } from "./diffLabel";
import { styled } from "../../app/theme";

interface Props {
  state: State<any>;
  resolvedByServer: boolean;
  diff: BlueprintDiff.ElementStateDiff | undefined;
}

const Error = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-weight: ${(props) => props.theme.font.medium};
`;

export const ElementStatus: React.FunctionComponent<Props> = (props) => {
  // Check if there's a difference in the state itself (it can be localized in output)
  const diff = props.diff && props.diff.serverState.toString() !== props.state.toString()
    ? props.diff.serverState.toString()
    : undefined;

  const state = <>{props.state.isError ? <Error>{props.state.toString()}</Error> : props.state.toString()}</>;

  if (diff) {
    return (
      <>
        <DiffLabel>diff</DiffLabel>
        {state} ({diff} on the server)
      </>
    );
  } else {
    return (
      <>
        {props.resolvedByServer && <ServerLabel/>}
        {state}
      </>
    );
  }
};
