import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { ReferralCode } from "../../types/models/referralCode";

type DeleteReferralCodeMutationHook = [(id: number) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteReferralCodeMutation(): DeleteReferralCodeMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteReferralCodeMutation,
    extract: (data: GraphQL.DeleteReferralCodeMutation) => nullToUndefined(data.deleteReferralCode),
    complete: identity
  });

  function fireWith(id: number): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = ReferralCode.cacheId(id);

      const referralCode = apolloClient.cache.readFragment<GraphQL.ReferralCodeFragment>({
        id: cacheId,
        fragment: GraphQL.ReferralCodeFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: ReferralCode.fragmentName
      });

      if (referralCode) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.ReferralCodeFragmentDoc,
          fragmentName: ReferralCode.fragmentName,
          data: { ...referralCode, id: -1 }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
