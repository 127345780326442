import * as React from "react";
import { OperationStatus } from "../../../../types/operationStatus";
import { Migration } from "../../../../types/models/migration";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Panel } from "../../../containers/panel";
import { prettyPrint } from "../../../../utils/formatting";

interface Props {
  status: OperationStatus<Migration & Migration.HasHistory>;
}

export const MigrationHistoryView: React.FunctionComponent<Props> = (props) => {
  return (
    <Panel>
      <OperationStatusIndicator
        subject={"migration"}
        status={props.status}
        indicators={StatusIndicators.PanelRow()}
      />
      {
        props.status.isSuccess() && (
          <pre>{prettyPrint(props.status.result.history)}</pre>
        )
      }
    </Panel>
  );
};
