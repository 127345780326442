import * as React from "react";
import { ReportSettingsForm } from "../blocks/reportSettingsForm";
import { Page } from "../containers/page";
import { Block } from "../containers/block";
import { Panel } from "views/containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { NewTabLink } from "../widgets/newTabLink";

interface Props {
  startDate: Date;
  maxDays: number;
  onGenerate: (startDate: Date, endDate: Date) => void;
}

export const ContestParticipantsReportPageView: React.FunctionComponent<Props> = (props) => (
  <Page title={"Contest Participants Report"}>
    <Block>
      <Panel>
        <PanelRow>
          <NewTabLink
            to={"https://www.gigacalculator.com/calculators/random-number-generator.php"}
          >
            Open Random Number Generator
          </NewTabLink>
        </PanelRow>
        <PanelRow>
          <ReportSettingsForm
            startDate={props.startDate}
            maxDays={props.maxDays}
            submitTitle={"Generate Contest Participants Report"}
            onSubmit={(result) => props.onGenerate(result.startDate, result.endDate)}
          />
        </PanelRow>
      </Panel>
    </Block>
  </Page>
);
