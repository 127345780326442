import { List } from "immutable";
import { NamedRegistry } from "./registry";
import { None, Option, Some } from "../utils/monads/option";

export interface Reducer<T> extends NamedRegistry.HasName {
  title: string | undefined;

  reduce(values: List<T>): T;
}

export function Reducer<T>(name: string, title: string, reduce: (values: List<T>) => T): Reducer<T> {
  return { name, title, reduce };
}

export namespace Reducer {
  export type ReduceFunction<C> = (a: C, b: C) => C;

  interface StandardListReducersOptions<T> {
    typeName: string;
    operation: string;
    reducerName?: string;
    optReducerName?: string;
    reduce: (values: List<T>) => T;
  }

  export function standardListReducers<T>(
    { typeName, operation, reduce, ...options }: StandardListReducersOptions<T>
  ): { reducer: Reducer<T>, optReducer: Reducer<Option<T>> } {
    const title = operation + "(" + typeName + ")";
    return {
      reducer: Reducer<T>(
        options.reducerName || (typeName + "$" + operation + "$"),
        title,
        reduce
      ),
      optReducer: Reducer<Option<T>>(
        options.optReducerName || (typeName + "$" + operation + "Opt$"),
        title,
        (values) => {
          const defined = values.filter((v) => v.isDefined()).map((v) => v.get());
          return defined.isEmpty() ? None() : Some(reduce(defined));
        }
      )
    };
  }

  interface StandardReducersOptions<T> {
    typeName: string;
    operation: string;
    reducerName?: string;
    optReducerName?: string;
    reduce: ReduceFunction<T>;
  }

  export function standardReducers<T>(
    { reduce, ...options }: StandardReducersOptions<T>
  ): { reducer: Reducer<T>, optReducer: Reducer<Option<T>> } {
    return standardListReducers<T>({
      ...options,
      reduce: (values) => values.reduce(reduce)
    });
  }

  export function standardSumReducers<T>(typeName: string, reduce: ReduceFunction<T>) {
    const reducers = standardReducers<T>({ typeName, operation: "Sum", reduce });
    return { Sum: reducers.reducer, SumOpt: reducers.optReducer };
  }

  export function standardMergeReducers<T>(typeName: string, reduce: ReduceFunction<T>) {
    const reducers = standardReducers<T>({ typeName, operation: "Merge", reduce });
    return { Merge: reducers.reducer, MergeOpt: reducers.optReducer };
  }

  export function standardMaxReducers<T>(typeName: string, reduce: ReduceFunction<T>) {
    const reducers = standardReducers<T>({ typeName, operation: "Max", reduce });
    return { Max: reducers.reducer, MaxOpt: reducers.optReducer };
  }
}

export type AnyReducer = Reducer<any>;
