import * as React from "react";
import { SchoolSummary } from "../../../../types/models/school";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { Step } from "../../../containers/step";
import { OperationStatus } from "../../../../types/operationStatus";
import { User } from "../../../../types/models/user";
import { Button } from "../../../widgets/button";
import { Block } from "views/containers/block";
import { Panel } from "views/containers/panel";
import { PanelRow } from "views/containers/rows/panelRow";
import { EnableEmailDeliveryTool } from "./enableEmailDeliveryTool";
import { EnableAuthRolesController } from "../../../../controllers/enableAuthRolesController";
import { Connection } from "../../../../types/models/connection";
import { TitlePanelRow } from "views/containers/rows/titlePanelRow";
import { styled, useAppTheme } from "../../../../app/theme";
import { Envelope } from "../../../glyphs/envelope";
import { TextArea } from "../../../widgets/textArea";
import { EmailPreview } from "../../../../types/models/emailPreview";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Spinner } from "../../../glyphs/spinner";
import { CheckMark } from "../../../glyphs/checkMark";
import { GridPanelRow } from "../../../containers/rows/gridPanelRow";
import { Grid } from "../../../widgets/grid";
import { List } from "immutable";
import { TextBox } from "views/widgets/textBox";
import { LinkButton } from "../../../widgets/linkButton";
import { WhenElevated } from "../../../utils/whenElevated";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { AmbassadorNavigation } from "./ambassadorNavigation";
import { useFlash } from "../../../../utils/useFlash";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { Contacts } from "../../../glyphs/contacts";
import { SimplePanel } from "../../../containers/simplePanel";
import { Checkbox } from "../../../widgets/checkbox";
import { FormErrorMessage } from "../../../widgets/formErrorMessage";
import { RequiredFieldSign } from "../../../widgets/formField";
import { NewTabLink } from "../../../widgets/newTabLink";
import { Constants } from "../../../../app/constants";
import { EnableEmailAddressVerificationTool } from "./enableEmailAddressVerificationTool";
import { RadioButton } from "../../../widgets/option";
import { DatePicker } from "../../../widgets/dateField";
import * as moment from "moment";
import { AccountAction } from "../../../../types/enums/accountAction";

export enum LocalValidationResult {
  Valid = "Valid",
  InvalidEmailAddressFormat = "InvalidEmailAddressFormat",
  Duplicate = "Duplicate"
}

export enum RemoteValidationResult {
  Valid = "Valid",
  InvalidDomain = "InvalidDomain",
  ContactNotFound = "ContactNotFound",
  EmailAddressBelongsToUser = "EmailAddressBelongsToUser",
  Failure = "Failure"
}

interface Contact {
  firstName: string;
  emailAddress: string;
  localValidationResult: LocalValidationResult | undefined;
  remoteValidationResult: RemoteValidationResult | undefined;
}

namespace Contact {
  export const empty: Contact = {
    firstName: "",
    emailAddress: "",
    localValidationResult: undefined,
    remoteValidationResult: undefined
  };
}

interface Props {
  school: SchoolSummary;
  sourceConnection: Connection;

  emailPreviewStatus: OperationStatus<EmailPreview>;

  maxCustomMessageLength: number;
  customMessage: string;
  onCustomMessageChange: (value: string) => void;

  accountAction: AccountAction;
  onAccountActionChange: (value: AccountAction) => void;

  accountActionDate: Date | undefined;
  onAccountActionDateChange: (value: Date) => void;

  enableEmailDeliveryController: EnableAuthRolesController | undefined;
  onEmailDeliveryOperationCancelled: () => void;

  enableEmailAddressVerificationController: EnableAuthRolesController | undefined;

  sendTestEmailStatus: OperationStatus<string>;
  onSendTestEmailClick: () => void;

  contacts: List<Contact>;
  minNumberOfContacts: number;
  maxNumberOfContacts: number;
  onFirstNameChange: (index: number, firstName: string) => void;
  onEmailAddressChange: (index: number, emailAddress: string) => void;
  onContactValidationTriggered: (index: number) => void;
  onContactAdded: () => void;
  onTestContactsAdded: () => void;
  onContactRemoved: (index: number) => void;

  applicationStatus: OperationStatus<User>;
  canSubmit: boolean;
  onSubmit: () => void;
  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const AmbassadorSignUpStepView: React.FunctionComponent<Props> = (props) => {
  const theme = useAppTheme();
  const appBootstrapConfig = useAppBootstrapConfig();

  const signUpDiscount = appBootstrapConfig.ambassadorsProgram.signUpDiscount;
  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  const domain = props.sourceConnection.personalInfo.titleizedDomain;

  const [showDisabledMessage, flashDisabledMessage] = useFlash(3000);

  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [showNotAcceptedError, setShowNotAcceptedError] = React.useState(false);

  const [showMissingAccountActionDateError, setShowMissingAccountActionDateError] = React.useState(false);

  return (
    <Step
      breadcrumbs={[
        `Get $${signUpDiscount} off now, ${codeDiscount}% off for friends, and entries to win an iPad`
      ]}
      title={`Share VaultMe with your ${props.school.commonName ? props.school.commonName + " " : ""} besties!`}
      subTitle={
        "VaultMe can help you easily share the benefits with your friends" +
        (props.school.commonName ? " from " + props.school.commonName : "") + "! " +
        "You can use VaultMe to send them a personalized message directly from your " +
        (domain ? "@" + domain + " " : "") + "account!"
      }
      noForm={true}
      submitWidget={(
        <AmbassadorNavigation
          submitTitle={["Send Emails &", `Get $${signUpDiscount} Off Now`]}
          hintText={
            `& Help Your Friends Save Their ${props.school.commonName ? props.school.commonName + " " : ""} Data Too!`
          }
          disabled={props.applicationStatus.isWorking()}
          onSubmit={() => {
            if (props.accountActionDate) {
              if (acceptedTerms) {
                if (props.canSubmit) {
                  props.onSubmit();
                } else {
                  flashDisabledMessage();
                }
              } else {
                setShowNotAcceptedError(true);
              }
            } else {
              setShowMissingAccountActionDateError(true);
            }
          }}
          onSkip={props.onSkip}
        />
      )}
      onNavigateBack={props.onNavigateBack}
    >
      <Block>
        <Panel>
          <TitlePanelRow>Step 1 - Design Your Email Message</TitlePanelRow>
          <PanelRow icon={<Envelope color={theme.colors.black}/>} verticalAlignment={"start"}>
            <SectionTitle>From:</SectionTitle>
            <StudentEmailAddress>{props.sourceConnection.description}</StudentEmailAddress>
            <FinePrint>
              All emails will be sent from your email account and will appear in your "Sent Mail".
              <br/>
              Your friends can reply directly to you. No emails will <strong>ever</strong> be sent by or from VaultMe.
            </FinePrint>
            {props.enableEmailDeliveryController && (
              <EnableEmailDeliveryTool
                controller={props.enableEmailDeliveryController}
                onCancel={props.onEmailDeliveryOperationCancelled}
              />
            )}
            <Button
              color={"secondary"}
              size={"narrow"}
              disabled={props.sendTestEmailStatus.isWorking() || props.applicationStatus.isWorking()}
              onClick={() => props.onSendTestEmailClick()}
            >
              Send Me a Test Email
            </Button>
            {props.sendTestEmailStatus.isWorking() && (
              <FinePrint>
                <TestEmailStatusContainer>
                  <Spinner progress={-1} backgroundColor={"gray"}/>
                </TestEmailStatusContainer>
                Sending...
              </FinePrint>
            )}
            {props.sendTestEmailStatus.isSuccess() && (
              <FinePrint>
                <TestEmailStatusContainer>
                  <CheckMark/>
                </TestEmailStatusContainer>
                Test email sent successfully to {props.sourceConnection.description}!
                Check your inbox to see how it looks!
              </FinePrint>
            )}
          </PanelRow>
          <OperationStatusIndicator
            progressMessage={"Preparing your message template..."}
            failureMessage={"Failed to prepare your message template"}
            status={props.emailPreviewStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          {props.sendTestEmailStatus.isFailure() && (
            <OperationStatusIndicator
              failureMessage={"Failed to send a test email"}
              status={props.sendTestEmailStatus}
              indicators={StatusIndicators.PanelRow()}
            />
          )}
          {props.emailPreviewStatus.isSuccess() && (
            <EmailTemplateForm
              school={props.school}
              email={props.emailPreviewStatus.result}
              disabled={props.applicationStatus.isWorking()}

              maxCustomMessageLength={props.maxCustomMessageLength}
              customMessage={props.customMessage}
              onCustomMessageChange={props.onCustomMessageChange}

              accountAction={props.accountAction}
              onAccountActionChange={props.onAccountActionChange}

              accountActionDate={props.accountActionDate}
              onAccountActionDateChange={(date) => {
                setShowMissingAccountActionDateError(false);
                props.onAccountActionDateChange(date);
              }}
              showMissingAccountActionDateError={showMissingAccountActionDateError}
            />
          )}
        </Panel>
      </Block>
      <Block>
        <Panel>
          <TitlePanelRow>
            Step 2 - Choose The Friends You'd Like To Help Save Their {props.school.commonName || "School"} Content
            Before It's Deleted
          </TitlePanelRow>
          {
            props.enableEmailAddressVerificationController &&
              <EnableEmailAddressVerificationTool controller={props.enableEmailAddressVerificationController}/>
          }
          <OverlaidPanelRow icon={<Contacts color={theme.colors.black}/>} verticalAlignment={"start"}>
            <SectionTitle>Source Account:</SectionTitle>
            <StudentEmailAddress>{props.sourceConnection.description}</StudentEmailAddress>
            <FinePrint>
              Add at least {props.minNumberOfContacts} contacts below. <strong>None</strong> of your friend's
              contacts are stored or used by VaultMe.
            </FinePrint>
            <FinePrint>
              Each friend you message with this tool gets you and additional entry to the monthly iPad giveaway!{" "}
              {props.maxNumberOfContacts} friends max.
            </FinePrint>
            {props.enableEmailAddressVerificationController && <Overlay/>}
          </OverlaidPanelRow>
          <ContactsForm
            contacts={props.contacts}
            canAddContacts={props.contacts.size < props.maxNumberOfContacts}
            overlaid={!!props.enableEmailAddressVerificationController}
            disabled={props.applicationStatus.isWorking()}

            onFirstNameChange={props.onFirstNameChange}
            onEmailAddressChange={props.onEmailAddressChange}
            onContactValidationTriggerred={props.onContactValidationTriggered}
            onContactAdded={props.onContactAdded}
            onTestContactsAdded={props.onTestContactsAdded}
            onContactRemoved={props.onContactRemoved}
          />
        </Panel>
      </Block>
      <SimplePanel>
        <Checkbox
          checked={acceptedTerms}
          onChange={(event) => {
            setAcceptedTerms(event.target.checked);
            setShowNotAcceptedError(false);
          }}
          disabled={props.applicationStatus.isWorking()}
        >
          I agree to the{" "}
          <NewTabLink to={Constants.Product.marketingWebSiteUrl + "/edu-ambassador-terms"}>
            EDU Ambassador Program Terms & Conditions
          </NewTabLink>
          <RequiredFieldSign> *</RequiredFieldSign>
        </Checkbox>
        {showNotAcceptedError && (
          <FormErrorMessage>
            Please agree to the EDU Ambassador Program Terms & Conditions by checking the box above
          </FormErrorMessage>
        )}
      </SimplePanel>
      <OperationStatusIndicator
        progressMessage={
          "Your emails to your besties are now being sent! " +
          "You can see each email in the Sent Mail of your " +
          (props.sourceConnection.personalInfo.emailAddress || "school") + " account."
        }
        failureMessage={"Failed to send emails"}
        status={props.applicationStatus}
        indicators={StatusIndicators.SimplePanel({ noProgressIndicatorDelay: true })}
      />
      {showDisabledMessage && (
        <DisabledMessageBlock>
          <Panel>
            <PanelRow status={WidgetStatus.Warning}>
              Please add at least {props.minNumberOfContacts} verified contacts above.
            </PanelRow>
          </Panel>
        </DisabledMessageBlock>
      )}
    </Step>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.theme.layers.modal};
  background: rgba(200, 200, 200, 0.3);
  backdrop-filter: blur(2px);
`;

const OverlaidPanelRow = styled(PanelRow)`
  position: relative;
`;

const OverlaidGridPanelRow = styled(GridPanelRow)`
  position: relative;
`;

const DisabledMessageBlock = styled(Block)`
  margin-bottom: -1rem;
`;

function trimTemplate(s: string): string {
  let result = s.trim();
  while (result.startsWith("<br/>")) {
    result = result.substring(5);
  }
  while (result.endsWith("<br/>")) {
    result = result.substring(0, result.length - 5);
  }
  return result;
}

interface EmailTemplateFormProps {
  school: SchoolSummary;
  email: EmailPreview;
  disabled: boolean;

  maxCustomMessageLength: number;
  customMessage: string;
  onCustomMessageChange: (value: string) => void;

  accountAction: AccountAction;
  onAccountActionChange: (value: AccountAction) => void;

  accountActionDate: Date | undefined;
  onAccountActionDateChange: (value: Date) => void;
  showMissingAccountActionDateError: boolean;
}

const EmailTemplateForm: React.FunctionComponent<EmailTemplateFormProps> = (props) => {
  const bodyParts = props.email.body.split("{customMessage}");

  const subject = props.email.subject.replace(
    "{action}",
    "<span class=\"action\">" +
    (props.accountAction === AccountAction.Delete ? "deletes" : "reduces storage in") +
    " our accounts</span>"
  );

  const part1 = trimTemplate(bodyParts[0]).replace(
    "{action}",
    "<span class=\"action\">" +
    (props.accountAction === AccountAction.Delete ? "deleting" : "reducing storage in") +
    " our accounts on " +
    (props.accountActionDate ? moment(props.accountActionDate).format("MMMM D") : "{Mmm DD}") +
    "</span>"
  );

  const part2 = bodyParts.length > 1 ? trimTemplate(bodyParts[1]) : undefined;

  return (
    <>
      <PanelRow icon={<div/>} verticalAlignment={"start"}>
        <SectionTitle>Email Subject:</SectionTitle>
        <EmailSubject dangerouslySetInnerHTML={{ __html: subject }}/>
      </PanelRow>
      <PanelRow icon={<div/>} verticalAlignment={"start"}>
        <EmailBody dangerouslySetInnerHTML={{ __html: part1 }}/>
        <CustomMessageContainer>
          <TextArea
            placeholder={
              "< Optionally, type anything else you'd like to say to your friends in a second paragraph here >"
            }
            disabled={props.disabled}
            value={props.customMessage}
            onChange={(event) => {
              if (event.target.value.length <= props.maxCustomMessageLength) {
                props.onCustomMessageChange(event.target.value);
              } else {
                event.preventDefault();
              }
            }}
          />
          <CustomMessageLength>
            {props.customMessage.length} of {props.maxCustomMessageLength} characters
          </CustomMessageLength>
        </CustomMessageContainer>
        {part2 && <EmailBody dangerouslySetInnerHTML={{ __html: part2 }}/>}
      </PanelRow>
      <PanelRow icon={<div/>} verticalAlignment={"start"}>
        <SectionTitle>What is {props.school.commonName} doing to your accounts?</SectionTitle>
        <StyledRadioButton
          name={"action"}
          value={AccountAction.Delete}
          checked={props.accountAction === AccountAction.Delete}
          disabled={props.disabled}
          onChange={() => props.onAccountActionChange(AccountAction.Delete)}
        >
          {props.school.commonName} is deleting your accounts
        </StyledRadioButton>
        <StyledRadioButton
          name={"action"}
          value={AccountAction.ReduceStorage}
          checked={props.accountAction === AccountAction.ReduceStorage}
          disabled={props.disabled}
          onChange={() => props.onAccountActionChange(AccountAction.ReduceStorage)}
        >
          {props.school.commonName} is reducing storage in your accounts
        </StyledRadioButton>
      </PanelRow>
      <PanelRow icon={<div/>} verticalAlignment={"start"}>
        <SectionTitle>
          When is {props.school.commonName}{" "}
          {props.accountAction === AccountAction.Delete ? "deleting" : "reducing storage in"}{" "}
          your accounts?
          <RequiredFieldSign> *</RequiredFieldSign>
        </SectionTitle>
        <StyledDatePicker
          selected={props.accountActionDate}
          disabled={props.disabled}
          error={props.showMissingAccountActionDateError}
          onChange={(date) => {
            if (date instanceof Date) {
              props.onAccountActionDateChange(date);
            }
          }}
        />
        {props.showMissingAccountActionDateError && <FormErrorMessage>Please select a date</FormErrorMessage>}
      </PanelRow>
    </>
  );
};

const SectionTitle = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: .8rem;
  font-weight: ${(props) => props.theme.font.medium};
  text-transform: uppercase;
  margin-bottom: .2rem;
`;

const StudentEmailAddress = styled.div`
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: .2rem;
`;

const FinePrint = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: .8rem;
  margin: .6rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  & + & {
    margin-top: -.4rem;
  }

  strong {
    font-weight: ${(props) => props.theme.font.bold};
  }
`;

const EmailSubject = styled.div`
  font-weight: ${(props) => props.theme.font.medium};

  .action {
    text-decoration-style: dotted;
    text-decoration-line: underline;
  }
`;

const EmailBody = styled.div`
  max-width: 40rem;

  .action {
    text-decoration-style: dotted;
    text-decoration-line: underline;
  }
`;

const CustomMessageContainer = styled.div`
  margin: 1rem 0 .5rem;
  max-width: 40rem;
`;

const CustomMessageLength = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: .8rem;
  text-align: right;
`;

const TestEmailStatusContainer = styled.div`
  display: inline-block;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
  position: relative;
  top: .05rem;
`;

const StyledRadioButton = styled(RadioButton)`
  margin-top: .5rem;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-top: .3rem;
`;

interface ContactsFormProps {
  contacts: List<Contact>;
  canAddContacts: boolean;
  overlaid: boolean;
  disabled: boolean;

  onFirstNameChange: (index: number, firstName: string) => void;
  onEmailAddressChange: (index: number, emailAddress: string) => void;
  onContactValidationTriggerred: (index: number) => void;
  onContactAdded: () => void;
  onTestContactsAdded: () => void;
  onContactRemoved: (index: number) => void;
}

const ContactsForm: React.FunctionComponent<ContactsFormProps> = (props) => (
  <>
    <OverlaidGridPanelRow>
      <Grid>
        <Grid.Header>
          <Grid.Column/>
          <Grid.Column>Friend's First Name</Grid.Column>
          <Grid.Column>Friend's Email Address</Grid.Column>
          <Grid.Column/>
          <Grid.Column/>
        </Grid.Header>
        <Grid.Body>
          {props.contacts.map((contact, index) => {
            const error =
              !!contact.localValidationResult && contact.localValidationResult !== LocalValidationResult.Valid ||
              !!contact.remoteValidationResult && contact.remoteValidationResult !== RemoteValidationResult.Valid;

            return (
              <Grid.Row key={index}>
                <IndexCell>{index + 1}.</IndexCell>
                <TextBoxCell>
                  <TextBox
                    value={contact.firstName}
                    onChange={(event) => props.onFirstNameChange(index, event.target.value)}
                    disabled={props.disabled}
                  />
                </TextBoxCell>
                <TextBoxCell>
                  <TextBox
                    value={contact.emailAddress}
                    onChange={(event) => props.onEmailAddressChange(index, event.target.value)}
                    onBlur={() => props.onContactValidationTriggerred(index)}
                    onKeyPress={(event) => event.key === "Enter" && props.onContactValidationTriggerred(index)}
                    error={error}
                    disabled={props.disabled}
                  />
                </TextBoxCell>
                <ContactStatusCell>
                  <ContactStatus
                    localValidationResult={contact.localValidationResult}
                    remoteValidationResult={contact.remoteValidationResult}
                    onValidationRetried={() => props.onContactValidationTriggerred(index)}
                  />
                </ContactStatusCell>
                <RemoveButtonCell>
                  <Button
                    size={"small"}
                    color={"secondary"}
                    tabIndex={-1}
                    onClick={() => props.onContactRemoved(index)}
                    disabled={props.disabled}
                  >
                    Remove
                  </Button>
                </RemoveButtonCell>
              </Grid.Row>
            );
          })}
        </Grid.Body>
      </Grid>
      {props.overlaid && <Overlay/>}
    </OverlaidGridPanelRow>
    <OverlaidPanelRow icon={<div/>} size={"smaller"}>
      <SimpleToolbar>
        <Button
          color={"secondary"}
          size={"small"}
          disabled={!props.canAddContacts || props.disabled}
          onClick={() => props.onContactAdded()}
        >
          + Add A Contact
        </Button>
        <div>...and get another shot at the monthly iPad drawing!</div>
      </SimpleToolbar>
      {props.overlaid && <Overlay/>}
    </OverlaidPanelRow>
    <WhenElevated>
      <OverlaidPanelRow icon={<div/>} size={"smaller"}>
        <Button
          color={"red"}
          size={"small"}
          onClick={() => props.onTestContactsAdded()}
        >
          Add @Combine8.com Contacts
        </Button>
        {props.overlaid && <Overlay/>}
      </OverlaidPanelRow>
    </WhenElevated>
  </>
);

const IndexCell = styled(Grid.Cell)`
  width: 2rem;
  text-align: right !important;
  vertical-align: middle !important;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    min-width: 1.3rem;
    width: 1.5rem;
    padding-right: .3rem !important;
  }
`;

const TextBoxCell = styled(Grid.Cell)`
  min-width: 10rem;
  width: 15rem;
  vertical-align: middle !important;
`;

const ContactStatusCell = styled(Grid.Cell)`
  min-width: 10rem;
  vertical-align: middle !important;
  padding-left: 0 !important;
`;

const RemoveButtonCell = styled(Grid.Cell)`
  text-align: right !important;
  vertical-align: middle !important;
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

interface ContactStatusProps {
  localValidationResult: LocalValidationResult | undefined;
  remoteValidationResult: RemoteValidationResult | undefined;
  onValidationRetried: () => void;
}

const ContactStatus: React.FunctionComponent<ContactStatusProps> = (props) => {
  const { localValidationResult: local, remoteValidationResult: remote } = props;

  if (local) {
    if (local === LocalValidationResult.Valid) {
      switch (remote) {
        case undefined:
          return (
            <VerificationStatus>
              <VerificationStatusContainer>
                <Spinner progress={-1} backgroundColor={"gray"}/>
              </VerificationStatusContainer>
              Verifying...
            </VerificationStatus>
          );

        case RemoteValidationResult.Valid:
          return (
            <VerificationStatus>
              <VerificationStatusContainer><CheckMark/></VerificationStatusContainer>
              Verified - You'll be getting one more shot at an iPad!
            </VerificationStatus>
          );

        case RemoteValidationResult.InvalidDomain:
        case RemoteValidationResult.ContactNotFound:
          return <ErrorMessage>Only verifiable email addresses from your school are allowed</ErrorMessage>;

        case RemoteValidationResult.EmailAddressBelongsToUser:
          return <ErrorMessage>Your own email addresses are not allowed</ErrorMessage>;

        case RemoteValidationResult.Failure:
          return (
            <ErrorMessage>
              Email address verification failed{" "}
              <LinkButton onClick={() => props.onValidationRetried()}>Try again</LinkButton>
            </ErrorMessage>
          );
      }
    } else {
      switch (local) {
        case LocalValidationResult.InvalidEmailAddressFormat:
          return <ErrorMessage>The email address provided is not valid</ErrorMessage>;

        case LocalValidationResult.Duplicate:
          return <ErrorMessage>This email address is already provided</ErrorMessage>;
      }
    }
  } else {
    return <>Once verified, you'll get another shot an an iPad!</>;
  }
};

const VerificationStatus = styled.div`
  display: flex;
`;

const VerificationStatusContainer = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .4rem;
  position: relative;
  top: .2rem;
`;
