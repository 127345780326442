import * as React from "react";
import { Page } from "../../containers/page";
import { MigrationStatusPageState } from "./migrationStatusPageState";
import { MigrationStatusPageDefs } from "./migrationStatusPageDefs";
import { friendlyCount } from "../../../utils/formatting";
import { SourceConnectionPanel } from "./components/sourceConnectionPanel";
import { DestinationConnectionPanel } from "./components/destinationConnectionPanel";
import { OverviewPanel } from "./components/overviewPanel";
import { ContactSupportBlock } from "./components/contactSupportBlock";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { MigrationStatus } from "../../../types/models/migrationStatus";
import { useDrawer } from "../../layouts/drawer";
import { useElevated } from "../../../utils/useAppState";
import { PopupContent } from "./popupContent";
import { WaitingForManualReviewPanel } from "./components/waitingForManualReviewPanel";
import { PriceBreakdownPanel } from "../migrationSetup/checkoutStepView/priceBreakdownPanel";
import { FaqPanel } from "./components/faqPanel";
import { MigrationToolsPanel } from "./components/migrationToolsPanel";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Link } from "react-router-dom";
import { MigrationInvoiceRow } from "./components/migrationInvoiceRow";
import { MigrationDelaysPanel } from "./components/migrationDelaysPanel";
import { Migration } from "../../../types/models/migration";
import { PauseMigrationToolRow } from "./components/pauseMigrationToolRow";
import { ResumeMigrationToolRow } from "./components/resumeMigrationToolRow";
import { PanelRow } from "../../containers/rows/panelRow";
import { WidgetStatus } from "../../utils/widgetStatus";
import { Paragraph } from "../../widgets/paragraph";
import { DoNotMoveFoldersWarning } from "./components/doNotMoveFoldersWarning";
import { useRoutes } from "../../../app/routes/useRoutes";

type Props = MigrationStatusPageDefs.ControlledMigrationStatusPageProps;

export const MigrationStatusPageView: React.FunctionComponent<Props> = (props) => {
  const drawer = useDrawer();
  const elevated = useElevated();
  const routes = useRoutes();

  // This is really terrible as the number of states is growing :(
  function renderTitle() {
    const prefix = props.migration.batch ? "This" : "Your";
    const subject = props.iterationIndex !== 0 ? "sync-up migration" : "migration";
    switch (props.state.type) {
      case MigrationStatusPageState.Type.Scheduled: return `${prefix} ${subject} is ready to be started`;
      case MigrationStatusPageState.Type.Starting: return `${prefix} ${subject} is starting`;
      case MigrationStatusPageState.Type.Complete: return `${prefix} migration is complete`;
      case MigrationStatusPageState.Type.Aborted: return `${prefix} migration has been aborted`;
      case MigrationStatusPageState.Type.WaitingForManualReview: return `${prefix} ${subject} is paused`;
      default: {
        switch (props.migration.status) {
          case MigrationStatus.Pausing: return `${prefix} ${subject} is pausing`;
          case MigrationStatus.Paused: return `${prefix} ${subject} is paused`;
          default: return `${prefix} ${subject} is in progress`;
        }
      }
    }
  }

  function renderSubTitle() {
    if (props.migration.batch) {
      return undefined;
    } else if (
      props.state.type === MigrationStatusPageState.Type.Complete ||
      props.state.type === MigrationStatusPageState.Type.Aborted
    ) {
      return `${friendlyCount(sourceTotals.cumulative.migratedItemCount, "item")} have been migrated`;
    } else if (props.migration.status === MigrationStatus.Paused) {
      return "Resume the migration when you feel ready";
    } else {
      return "Feel free to close your browser (you'll get an email at " +
        `${props.destination.connection.description} when it's done)`;
    }
  }

  const sourceTotals = props.source.areas
      .map(MigrationStatusPageDefs.SourceArea.toTotals)
      .reduce(MigrationStatusPageDefs.SourceAreaTotals.reduce) ||
    MigrationStatusPageDefs.SourceAreaTotals.Empty;

  const destinationTotals = props.destination.areas
    .map(MigrationStatusPageDefs.DestinationArea.toTotals)
    .reduce(MigrationStatusPageDefs.DestinationAreaTotals.reduce, MigrationStatusPageDefs.DestinationAreaTotals.Empty);

  React.useEffect(
    () => {
      if (
        props.displayWarning &&
        props.state.type !== MigrationStatusPageState.Type.Scheduled &&
        props.state.type !== MigrationStatusPageState.Type.Complete &&
        !props.migration.batch &&
        !elevated
      ) {
        setTimeout(
          () => drawer.open({
            content: <PopupContent/>,
            title: <>Important!<br/>Please read carefully</>,
            background: "alert"
          }),
          3000
        );
      }
    },
    []
  );

  return (
    <Page
      breadcrumbs={
        [<Link key={0} to={routes.migrations.homePath}>My Migrations</Link>]
          .concat(
            props.migration.batch
              ? [(
                <Link key={0} to={routes.batches.path(props.migration.batch.batchId)}>
                  Batch {props.migration.batch.batchId}
                </Link>
              )]
              : []
          )
          .concat([<>Migration {props.migration.id}</>])
      }
      title={renderTitle()}
      subTitle={renderSubTitle()}
    >
      <OperationStatusIndicator
        status={props.loadingStatus}
        progressMessage={"Updating migration status..."}
        failureMessage={"Failed to update migration status..."}
        indicators={StatusIndicators.SimplePanel()}
      />
      <OverviewPanel
        state={props.state}
        source={props.source}
        destination={props.destination}
        migration={props.migration}
        iteration={props.iterationStatus.someResult()}
        timeRemaining={props.timeRemaining}
        sourceTotals={sourceTotals}
        destinationTotals={destinationTotals}
        displaySpinUp={props.displaySpinUp}
      />
      {(() => {
        if (
          props.migration.status === MigrationStatus.WaitingForUserAction ||
          props.migration.status === MigrationStatus.Completed ||
          props.migration.status === MigrationStatus.Aborted
        ) {
          return null;
        } else if (props.migration.status === MigrationStatus.Pausing) {
          return (
            <Block>
              <Panel>
                <PanelRow status={WidgetStatus.Info}>
                  <Paragraph>
                    Your migration is being paused and VaultMe is gracefully completing all in-progress transactions to
                    avoid data loss or corruption. Depending on the state of the migration, this process can take up to
                    1 hour. After that, you will be able to resume the migration at any time.
                  </Paragraph>
                  <DoNotMoveFoldersWarning/>
                </PanelRow>
              </Panel>
            </Block>
          );
        } else if (props.migration.status === MigrationStatus.Paused) {
          return (
            <Block>
              <Panel>
                <ResumeMigrationToolRow
                  migration={props.migration}
                  resumeMigrationController={props.resumeMigrationController}
                />
              </Panel>
            </Block>
          );
        } else if (props.migration.status === MigrationStatus.WaitingForManualReview) {
          return (
            <Block>
              <Panel>
                <WaitingForManualReviewPanel/>
              </Panel>
            </Block>
          );
        } else if (
          props.migration.status === MigrationStatus.WaitingForTemporaryCondition ||
          props.migration.status === MigrationStatus.StoppedForUnknownReason ||
          props.migration.timing.delayLevel !== Migration.Timing.DelayLevel.OnTime
        ) {
          return (
            <Block>
              <Panel>
                <MigrationDelaysPanel
                  sourceCloudService={props.source.cloudService}
                  destinationCloudService={props.destination.cloudService}
                />
              </Panel>
            </Block>
          );
        }
      })()}
      {props.migration.status === MigrationStatus.Completed && (
        <>
          <Block>
            <MigrationToolsPanel
              migration={props.migration}
              migrationReportUrl={props.migrationReportUrl}

              invoiceToolController={props.invoiceToolController}
              iterationLaunchController={props.iterationLaunchController}
              iterationsHistoryController={props.iterationsHistoryController}
              disconnectController={props.disconnectController}

              onIterationSelect={props.onIterationSelect}
            />
          </Block>
          <Block>
            <FaqPanel areas={props.source.areas}/>
          </Block>
        </>
      )}
      <SourceConnectionPanel
        title={"Migrate From (Source Account)"}
        state={props.state}
        source={props.source}
        destination={props.destination}
        totals={sourceTotals}
        context={
          props.iterationIndex === undefined
            ? MigrationStatusPageDefs.ContextType.Cumulative
            : MigrationStatusPageDefs.ContextType.Iteration
        }

        migration={props.migration}
        iterationIndex={props.iterationIndex}
        iterationStatus={props.iterationStatus}

        iterationsHistoryController={props.iterationsHistoryController}

        onIterationSelect={props.onIterationSelect}

        actionItemSuppressing={undefined}
      />
      <DestinationConnectionPanel
        title={"Migrate To (Destination Account)"}
        state={props.state}
        source={props.source}
        destination={props.destination}
        actionItemSuppressing={undefined}
      />
      {
        props.migration.orderSummary.map((orderSummary) => (
          <PriceBreakdownPanel
            key={0}
            priceInfo={{
              receipt: {
                totalBytes: orderSummary.totalBytes,
                totalItems: orderSummary.totalItems,

                isProgramDiscountApplied: orderSummary.priceCalculator.isProgramDiscountApplied,

                priceBeforeDiscounts: orderSummary.priceBeforeDiscounts,
                couponCodeDiscount: orderSummary.couponCodeDiscount,
                referralCodeDiscount: orderSummary.referralCodeDiscount,
                ambassadorStatusDiscount: orderSummary.ambassadorStatusDiscount,
                ambassadorCodeDiscount: orderSummary.ambassadorCodeDiscount,
                priceAfterDiscounts: orderSummary.priceAfterDiscounts,

                usedSponsorship: orderSummary.usedSponsorship,
                amountToBePaid: orderSummary.amountToBePaid
              },
              pricingDetails: orderSummary.breakdown
            }}
          />
        )).toJS()
      }
      {props.migration.status !== MigrationStatus.Completed && (
        <Block>
          <Panel>
            {!props.migration.batch && (
              <MigrationInvoiceRow invoiceToolController={props.invoiceToolController}/>
            )}
            {props.migration.status === MigrationStatus.Running && (
              <PauseMigrationToolRow
                migration={props.migration}
                pauseMigrationController={props.pauseMigrationController}
              />
            )}
          </Panel>
        </Block>
      )}
      <ContactSupportBlock/>
    </Page>
  );
};
