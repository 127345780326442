import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { Program } from "../../types/models/program";

type DeleteProgramMutationHook = [(id: string) => Promise<boolean>, OperationStatus<boolean>];

export function useDeleteProgramMutation(): DeleteProgramMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteProgramMutation,
    extract: (data: GraphQL.DeleteProgramMutation) => nullToUndefined(data.deleteProgram),
    complete: identity
  });

  function fireWith(id: string): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = Program.cacheId(id);

      const program = apolloClient.cache.readFragment<GraphQL.ProgramFragment>({
        id: cacheId,
        fragment: GraphQL.ProgramFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: Program.fragmentName
      });

      if (program) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.ProgramFragmentDoc,
          fragmentName: Program.fragmentName,
          data: { ...program, id: "" }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
