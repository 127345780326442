import * as React from "react";
import { styled } from "../../app/theme";

export const Block = styled.div`
  padding-top: 2rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding-top: 1.25rem;
  }
`;
