import * as React from "react";
import { styled } from "../../../../app/theme";

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;

  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 1px 4px rgba(124,124,124,0.6);
  padding: 2rem 1rem 1.5rem;
  /* width: 100%; */
  max-width: 64rem;
  min-height: auto;
  min-height: -webkit-min-content;
  margin: 0 1rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    margin: auto;
  }
`;
