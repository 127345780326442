import * as React from "react";
import { Step } from "../../views/containers/step";
import { SimplePanel } from "../../views/containers/simplePanel";

interface SandboxInterstitialProps {
  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const SandboxInterstitial: React.FunctionComponent<SandboxInterstitialProps> = (props) => (
  <Step
    title="Interstitial"
    onSubmit={props.onSkip}
    onNavigateBack={props.onNavigateBack}
    noForm={true}
  >
    <SimplePanel>
      This is an interstitial page.
    </SimplePanel>
  </Step>
);
