import { AccessList } from "../../types/models/accessList";
import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";

export function useSearchAccessListsQuery(
  organizationId: number | undefined,
  term: string | undefined
): ManagedQueryHook<GraphQL.SearchAccessListsQueryVariables, List<AccessList>> {
  return useManagedQuery({
    query: GraphQL.useSearchAccessListsQuery,
    deps: organizationId !== undefined && term !== undefined ? { organizationId, term } : undefined,
    prepare: (deps) => ({ organizationId: deps.organizationId, term: deps.term }),
    extract: (data: GraphQL.SearchAccessListsQuery) => nullToUndefined(data.searchAccessLists),
    complete: (accessLists) => List(accessLists)
      .filter((accessList) => accessList.id >= 0)
      .map(AccessList.parse),
    fetchPolicy: "cache-and-network"
  });
}
