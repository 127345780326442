import * as React from "react";
import { Button } from "../../../widgets/button";
import { ToolsRow } from "../../../containers/rows/toolsRow";
import {
  UpdateCustomerDetailsTool,
  UpdateCustomerDetailsToolControllerType
} from "../../../blocks/updateCustomerDetailsForm";
import { ModalController, useModal } from "../../../layouts/modal";
import { InvoiceIcon } from "../../../glyphs/invoiceIcon";

interface MigrationInvoicePanelRowProps {
  invoiceToolController: UpdateCustomerDetailsToolControllerType;
}

export const MigrationInvoiceRow: React.FunctionComponent<MigrationInvoicePanelRowProps> = (props) => {
  const invoiceModal = useModal();

  return (
    <>
      <ModalController
        modal={invoiceModal}
        title={"Download Invoice"}
        render={(close) =>
          React.createElement(props.invoiceToolController, {
            render: ({ customerDetailsStatus, submitStatus, onSubmit }) => (
              <UpdateCustomerDetailsTool
                customerDetailsStatus={customerDetailsStatus}
                submitStatus={submitStatus}
                onSubmit={(customerDetails) => onSubmit(customerDetails).then(close)}
              />
            )
          })
        }
      />
      <ToolsRow
        icon={<InvoiceIcon/>}
        header={"Download invoice containing payment details (PDF)"}
      >
        <Button color={"white"} size={"small"} onClick={invoiceModal.open}>Download Invoice</Button>
      </ToolsRow>
    </>
  );
};
