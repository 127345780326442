import * as React from "react";
import { useBatchQuery } from "../../queries/useBatchQuery";
import { useBatchAssignmentToolController } from "./batchAssignmentToolController";
import { BatchInsightsView } from "../../views/screens/batchStatusPageView/batchInsightsView";

interface Props {
  batchId: string;
}

export const BatchInsights: React.FunctionComponent<Props> = (props) => {
  const [batchStatus] = useBatchQuery(props.batchId);

  const batchAssignmentController = useBatchAssignmentToolController(props.batchId);

  return (
    <BatchInsightsView
      batchId={props.batchId}
      batchStatus={batchStatus}
      batchAssignmentToolController={batchAssignmentController}
    />
  );
};
