import { GraphQL } from "../../services/graphql/generated";

export interface CouponCodeDiscount {
  readonly code: string;
  readonly value: number;
  readonly isPercentage: boolean;
  readonly amount: number;
}

export namespace CouponCodeDiscount {
  export function parse(data: GraphQL.CouponCodeDiscountFragment): CouponCodeDiscount {
    return data;
  }
}
