import * as React from "react";
import { StyledComponentsProps } from "../utils/styledComponentsProps";
import { AppTheme, css, styled } from "../../app/theme";

interface Props extends StyledComponentsProps {
  id?: string;

  name: string;
  value: any;

  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;

  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const RadioButton: React.FunctionComponent<Props> = (props) => {
  const id = props.id || (props.name + "-" + props.value);
  return (
    <RadioButtonOuterLayout className={props.className} disabled={!!props.disabled}>
      <HiddenRadioButton
        id={id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      <Label htmlFor={id} disabled={props.disabled}>
        <RadioButtonInnerLayout>
          <StyledIndicator/>
          <RadioButtonTitle>{props.children}</RadioButtonTitle>
        </RadioButtonInnerLayout>
      </Label>
    </RadioButtonOuterLayout>
  );
};

const RadioButtonTitle = styled.div`
  padding-left: .5rem;
`;

const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label<{ disabled: boolean | undefined }>`
  cursor: ${(props) => props.disabled ? "default" : "pointer"};
  position: relative;
  display: block;
`;

const TitleLayout = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const OuterCircle: React.FunctionComponent<StyledComponentsProps> = (props) => (
  <circle className={props.className} cx={10} cy={10} r={9}/>
);

const StyledOuterCircle = styled(OuterCircle)`
  stroke: ${(props) => props.theme.colors.lightGray};
  fill: ${(props) => props.theme.colors.white};
`;

const InnerCircle: React.FunctionComponent<StyledComponentsProps> = (props) => (
  <circle className={props.className} cx={10} cy={10} r={5}/>
);

const StyledInnerCircle = styled(InnerCircle)`
`;

const Indicator: React.FunctionComponent<StyledComponentsProps> = (props) => (
  <svg viewBox="0 0 20 20" className={props.className}>
    <StyledOuterCircle/>
    <StyledInnerCircle/>
  </svg>
);

export const StyledIndicator = styled(Indicator)`
  fill: none;
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 auto;
`;

const Title = styled.div<{ disabled: boolean | undefined }>`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.font.medium};
  color: ${(props) => props.disabled ? props.theme.colors.gray : "inherit"};
  padding: 0 6rem 0 1rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 0 2rem 0 1rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.9375rem;
    padding: 0 1.5rem 0 1rem;
  }
`;

const SubTitle = styled.div<{ disabled: boolean | undefined }>`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.font.light};
  color: ${(props) => props.disabled ? props.theme.colors.gray : props.theme.colors.textColor};
  border-top: 1px solid ${(props) => props.theme.colors.lighterGray};
  padding: 1rem 6rem 1rem 3.5rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: 0.8rem 2rem 0.8rem 3.5rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem 0.5rem 3.5rem;
  }
`;

function hoverableMergeIn(theme: AppTheme) {
  return css`
  cursor: pointer;
  
  @media(hover: hover) {
    &:hover {
    ${Title}, ${SubTitle} {
      padding-right: 5.8125rem;

      ${theme.responsive.respondToSmall()} {
        padding-right: 1.8125rem;
      }

      ${theme.responsive.respondToXSmall()} {
        padding-right: 1.3125rem;
      }
    }

    ${HiddenRadioButton} + ${Label} {
      ${StyledIndicator} {
        ${StyledOuterCircle} {
        }
      }
    }

    ${HiddenRadioButton}:checked + ${Label} {
      cursor: default;
      border-left: 3px solid ${theme.colors.primary};
    }

    ${HiddenRadioButton}:not(:checked) + ${Label} {
      background: ${theme.colors.faintPrimary};
      border-left: 3px solid ${theme.colors.primary};
    }
  }
`;
}

const RadioButtonOuterLayout = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => props.disabled ? "default" : "pointer"};
  
  ${HiddenRadioButton}:checked + ${Label} {
    ${StyledIndicator} {
      ${StyledOuterCircle} {
        stroke: ${(props) => props.theme.colors.darkGray};
      }

      ${StyledInnerCircle} {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const RadioButtonInnerLayout = styled.div`
  display: flex;
  align-items: center;
`;

const OptionLayout = styled.div<{ disabled: boolean | undefined }>`
  background: ${(props) => props.theme.colors.white};

  & ~ & {
    border-top: 1px solid ${(props) => props.theme.colors.offWhite};
  }

  ${HiddenRadioButton}:checked + ${Label} {
    border-left: 3px solid ${(props) => props.theme.colors.primary};

    ${StyledIndicator} {
      ${StyledOuterCircle} {
        stroke: ${(props) => props.theme.colors.darkGray};
      }

      ${StyledInnerCircle} {
        fill: ${(props) => props.theme.colors.primary};
      }
    }

    ${Title}, ${SubTitle} {
      padding-right: 5.8125rem;

      ${(props) => props.theme.responsive.respondToSmall()} {
        padding-right: 1.8125rem;
      }

      ${(props) => props.theme.responsive.respondToXSmall()} {
        padding-right: 1.3125rem;
      }
    }
  }

  ${(props) => !props.disabled && hoverableMergeIn(props.theme)}
`;

export const Option: React.FunctionComponent<Props> = (props) => {
  const id = props.id || (props.name + "-" + props.value);
  return (
    <OptionLayout disabled={props.disabled}>
      <HiddenRadioButton
        id={id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      <Label htmlFor={id} disabled={props.disabled}>
        <TitleLayout>
          <StyledIndicator/>
          <Title disabled={props.disabled}>{props.children}</Title>
        </TitleLayout>
      </Label>
    </OptionLayout>
  );
};

const GrandOptionLayout = styled(OptionLayout)`
  border: 1px solid ${(props) => props.theme.colors.offWhite};

  & ~ & {
    margin-top: .75rem;

    ${(props) => props.theme.responsive.respondToXSmall()} {
      margin-top: .5rem;
    }
  }
`;

export interface GrandOptionProps extends Props {
  description?: React.ReactNode;
}

export const GrandOption: React.FunctionComponent<GrandOptionProps> = (props) => {
  const id = props.id || (props.name + "-" + props.value);
  return (
    <GrandOptionLayout className={props.className} disabled={props.disabled}>
      <HiddenRadioButton
        id={id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      <Label htmlFor={id} disabled={props.disabled}>
        <TitleLayout>
          <StyledIndicator/>
          <Title disabled={props.disabled}>{props.children}</Title>
        </TitleLayout>
        {props.description && <SubTitle disabled={props.disabled}>{props.description}</SubTitle>}
      </Label>
    </GrandOptionLayout>
  );
};
