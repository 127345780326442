import {
  GoogleGroupDetailsController,
  GoogleGroupDetailsViewProps
} from "../../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import * as React from "react";
import { ControllerHost } from "../../../../utils/controller";
import { PanelRow } from "../../../containers/rows/panelRow";
import { PropertiesTable } from "./propertiesTable";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { LinkButton } from "../../../widgets/linkButton";
import { Button } from "../../../widgets/button";
import { styled } from "../../../../app/theme";
import { HelpArticles } from "../../../../app/helpArticles";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { ModalController, useModal } from "../../../layouts/modal";
import { friendlyDateTime } from "../../../../utils/formatting";

interface ListSourceDetailsProps {
  googleGroupId: string | undefined;
  lastUpdateStartedAt: Date | undefined;
  googleGroupDetailsController: GoogleGroupDetailsController;
}

export const ListSourceDetails: React.FunctionComponent<ListSourceDetailsProps> = (props) => {
  const googleGroupId = props.googleGroupId;

  if (googleGroupId) {
    return (
      <ControllerHost
        controller={props.googleGroupDetailsController}
        render={(viewProps) => (
          <GoogleGroupListSource
            {...viewProps}
            googleGroupId={googleGroupId}
            lastUpdateStartedAt={props.lastUpdateStartedAt}
          />
        )
      }
      />
    );
  } else {
    return (
      <PanelRow>
        <PropertiesTable>
          <tbody>
          <tr>
            <td>Source of list:</td>
            <td>CSV</td>
          </tr>
          <tr>
            <td>Last CSV import:</td>
            <td>{props.lastUpdateStartedAt ? friendlyDateTime(props.lastUpdateStartedAt) : "(not imported yet)"}</td>
          </tr>
          </tbody>
        </PropertiesTable>
      </PanelRow>
    );
  }
};

interface GoogleGroupListSourceProps extends GoogleGroupDetailsViewProps {
  googleGroupId: string;
  lastUpdateStartedAt: Date | undefined;
}

const GoogleGroupListSource: React.FunctionComponent<GoogleGroupListSourceProps> = (props) => {
  const googleGroupConfigurationHelpArticleModal = useModal();

  React.useEffect(
    () => {
      // Do not return the Promise - the app will crash
      props.onRequest(props.googleGroupId);
    },
    [props.googleGroupId]
  );

  return (
    <>
      <OperationStatusIndicator
        progressMessage={"Retrieving Google Group details"}
        failureMessage={"Failed to retrieve Google Group details"}
        status={props.status}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow>
        <ModalController
          modal={googleGroupConfigurationHelpArticleModal}
          title={HelpArticles.googleGroupConfiguration.title}
          render={() =>
            <>{PreparedHelpArticle.fromExternal(HelpArticles.googleGroupConfiguration).content}</>
          }
        />
        <PropertiesTable style={{ maxWidth: "50rem" }}>
          <tbody>
          <tr>
            <td>Source of list:</td>
            <td>
              Google Group
              {" "}&nbsp;
              <LinkButton onClick={googleGroupConfigurationHelpArticleModal.open}>Learn how it works</LinkButton>
            </td>
          </tr>
          <tr>
            <td>Group ID:</td>
            <td>
              {props.googleGroupId}
              {" "}&nbsp;
              <LinkButton
                disabled={props.status.isWorking()}
                onClick={() => props.onRequest(props.googleGroupId)}
              >
                {props.status.isWorking() ? "Testing connection..." : "Test connection"}
              </LinkButton>
            </td>
          </tr>
          {props.status.isSuccess() && !props.status.result && (
            <tr>
              <td/>
              <td className={"error"}>
                Failed to connect to Google Group. Either the Group ID is incorrect or the VaultMe service account
                has not been added as a member of this Group.
                <CheckAgainButtonContainer>
                  <Button
                    size={"small"}
                    color={"secondary"}
                    onClick={() => props.onRequest(props.googleGroupId)}
                  >
                    Check Again
                  </Button>
                </CheckAgainButtonContainer>
              </td>
            </tr>
          )}
          <tr>
            <td>Group email:</td>
            <td className={!props.status.isSuccess() || !props.status.result ? "pending" : undefined}>
              {props.status.isSuccess() && props.status.result ? props.status.result.friendlyId : "(pending)"}
            </td>
          </tr>
          <tr>
            <td>Group name:</td>
            <td className={!props.status.isSuccess() || !props.status.result ? "pending" : undefined}>
              {
                props.status.isSuccess() && props.status.result
                  ? props.status.result.name || "(not set)"
                  : "(pending)"}
            </td>
          </tr>
          <tr>
            <td>Group description:</td>
            <td className={!props.status.isSuccess() || !props.status.result ? "pending" : undefined}>
              {
                props.status.isSuccess() && props.status.result
                  ? props.status.result?.description || "(not set)"
                  : "(pending)"
              }
            </td>
          </tr>
          <tr>
            <td>Last re-sync:</td>
            <td>{props.lastUpdateStartedAt ? friendlyDateTime(props.lastUpdateStartedAt) : "(not synced yet)"}</td>
          </tr>
          </tbody>
        </PropertiesTable>
      </PanelRow>
    </>
  );
};

const CheckAgainButtonContainer = styled.div`
  margin: .5rem 0 1rem;
`;
