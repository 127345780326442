import * as React from "react";
import { Block } from "../../../containers/block";
import { EmailSupportLink } from "../../../widgets/emailSupportLink";
import { Button, MultiLineButtonTitle } from "../../../widgets/button";

export const ContactSupportBlock: React.FunctionComponent = () => (
  <Block>
    <EmailSupportLink>
      <Button color={"white"}>
        <MultiLineButtonTitle>
          <div>Got an issue?&nbsp;</div>
          <div>Contact Support</div>
        </MultiLineButtonTitle>
      </Button>
    </EmailSupportLink>
  </Block>
);
