import * as React from "react";
import { Block } from "../containers/block";
import { Panel } from "../containers/panel";
import { Indicator, IndicatorValue, ItemCountAndSizeIndicator } from "../widgets/indicator";
import { Constants } from "../../app/constants";
import { BrandedTitlePanelRow } from "../containers/rows/brandedTitlePanelRow";
import { PanelRow } from "../containers/rows/panelRow";
import { OperationStatus } from "../../types/operationStatus";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { TimeEstimatesGrid } from "./timeEstimatesGrid";
import { prepareTimeEstimate } from "../../utils/prepareTimeEstimate";
import { useDrawer } from "../layouts/drawer";
import { styled } from "../../app/theme";
import { Button } from "../widgets/button";
import { WhenElevated } from "../utils/whenElevated";
import { LinkButton } from "../widgets/linkButton";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { HelpArticles } from "../../app/helpArticles";
import { useCloudServices } from "../../app/configuration";

export interface MigrationEstimates {
  time: OperationStatus<List<GraphQL.EstimationResult>>;
  totalBytes: number;
  totalItems: number;
}

interface MigrationDetailsPanelProps {
  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  estimates?: MigrationEstimates;
}

export const MigrationDetailsPanel: React.FunctionComponent<MigrationDetailsPanelProps> = (props) => {
  if (props.estimates) {
    return (
      <Block>
        <Panel>
          <BrandedTitlePanelRow>Migration Details</BrandedTitlePanelRow>

          <OperationStatusIndicator
            progressMessage={"Estimating migration time..."}
            failureMessage={"Failed to receive time estimates for this migration."}
            status={props.estimates.time}
            indicators={StatusIndicators.PanelRow({ noProgressIndicatorDelay: true })}
          />
          {props.estimates.time.isSuccess() && (
            <TimeEstimateRow
              sourceCloudServiceId={props.sourceCloudServiceId}
              destinationCloudServiceId={props.destinationCloudServiceId}
              estimates={props.estimates.time.result}
            />
          )}

          <PanelRow>
            <ItemCountAndSizeIndicator
              title={`${Constants.Product.title} will process`}
              count={props.estimates.totalItems}
              unit={"item"}
              size={props.estimates.totalBytes}
            />
          </PanelRow>
        </Panel>
      </Block>
    );
  } else {
    return null;
  }
};

const WarningContainer = styled.div`
  margin-top: .4rem;
  margin-left: .5rem;
  display: flex;
`;

const TextContainer = styled.div`
`;

const WarningSignContainer = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: .3rem;
`;

const ButtonRow = styled.div`
  margin-top: .8rem;
`;

interface TimeEstimateRowProps {
  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  estimates: List<GraphQL.EstimationResult>;
}

const TimeEstimateRow: React.FunctionComponent<TimeEstimateRowProps> = (props) => {
  const drawer = useDrawer();
  const cloudServices = useCloudServices();

  const maxTime = props.estimates.map((estimate) => estimate.estimate?.value || 0).max() || 0;
  const finalEstimate = Math.max(Constants.DefaultTimeEstimate, maxTime);
  const { value, fraction, unit } = prepareTimeEstimate(finalEstimate);

  return (
    <PanelRow>
      <Indicator title={"Approximate migration time"} compact={true}>
        <IndicatorValue
          value={<>{value}{fraction}</>}
          suffix={unit}
        />
        <WarningContainer>
          {/*<WarningSignContainer><WarningSign color={"yellow"}/></WarningSignContainer>*/}
          <TextContainer>
            May be extended (
            <LinkButton
              onClick={() => drawer.open(
                PreparedHelpArticle.fromExternal(
                  HelpArticles.delaysArePossibleEx(
                    cloudServices,
                    props.sourceCloudServiceId,
                    props.destinationCloudServiceId
                  )
                )
              )}
            >
              learn why
            </LinkButton>
            )
          </TextContainer>
        </WarningContainer>
      </Indicator>
      <WhenElevated>
        <ButtonRow>
          <Button
            color={"white"}
            size={"small"}
            onClick={() => drawer.open({
              content: (
                <Panel>
                  <GridPanelRow>
                    <TimeEstimatesGrid estimates={props.estimates}/>
                  </GridPanelRow>
                </Panel>
              ),
              wide: true
            })}
          >
            View Estimation Details
          </Button>
        </ButtonRow>
      </WhenElevated>
    </PanelRow>
  );
};
