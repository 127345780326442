import * as React from "react";
import { styled } from "../../../app/theme";
import { VaultMeLogo } from "../../glyphs/vaultMeLogo";
import { PanelRow, PanelRowDefs } from "./panelRow";

const BrandedTitlePanelRowLayout = styled(PanelRow).attrs({ className: PanelRowDefs.NoBorder })`
  font-weight: ${(props) => props.theme.font.bold};
  background: ${(props) => props.theme.colors.darkPrimary};
  color: ${(props) => props.theme.colors.white};
  
  border-bottom-color: #05689e !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.73rem !important;
`;

const StyledVaultMeLogo = styled(VaultMeLogo)`
  width: 5rem;
  margin-right: 1rem;
`;

export const BrandedTitlePanelRow: React.FunctionComponent = (props) => (
  <BrandedTitlePanelRowLayout>
    <StyledVaultMeLogo colorSchema="white"/>
    {props.children}
  </BrandedTitlePanelRowLayout>
);
