import * as React from "react";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { AccessList } from "../../../../types/models/accessList";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { PanelRow } from "../../../containers/rows/panelRow";
import { OperationStatus } from "../../../../types/operationStatus";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { Link } from "react-router-dom";
import { Button } from "../../../widgets/button";
import { ProgramViewPathFactory } from "../../../blocks/lookups/programLookupField";
import { PropertiesTable } from "./propertiesTable";
import { BlockingMessagePreviewModal } from "./blockingMessagePreviewModal";
import { useModal } from "../../../layouts/modal";
import { LinkButton } from "views/widgets/linkButton";
import {
  GoogleGroupDetailsController
} from "../../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import { ListSourceDetails } from "./listSourceDetails";
import { AccessListEntryCount } from "./accessListEntryCount";

interface Props {
  accessList: AccessList;

  importEntriesPath: string;
  importHistoryPath: string;
  deleteEntriesPath: string;

  programViewPathFactory: ProgramViewPathFactory;
  googleGroupDetailsController: GoogleGroupDetailsController;

  entryCountStatus: OperationStatus<number>;
  onEntryCountRefresh: () => void;

  disableUpdates: boolean;
  readOnly: boolean;

  onExportItems: () => void;
}

export const AccessListOverview: React.FunctionComponent<Props> = (props) => {
  const previewModal = useModal();

  return (
    <>
      <BlockingMessagePreviewModal
        modal={previewModal}
        blockingMessage={props.accessList.blockingMessage || ""}
      />
      <Block>
        <Panel>
          <OperationStatusIndicator
            progressMessage={"Calculating entry count"}
            failureMessage={"Failed to calculate entry count"}
            status={props.entryCountStatus}
            indicators={StatusIndicators.PanelRow()}
          />
          <PanelRow>
            <PropertiesTable>
              <tbody>
              <tr>
                <td>Status:</td>
                <td>{props.accessList.isEnabled ? "Enabled" : "Disabled"}</td>
              </tr>
              <tr>
                <td>List type:</td>
                <td>{props.accessList.isProgramAllowlist ? "Program allowlist" : "Blocklist"}</td>
              </tr>
              {
                props.accessList.isProgramAllowlist
                  ? (
                    <tr>
                      <td>End-user program:</td>
                      <td>
                        {props.accessList.program && (
                          <>
                            {props.accessList.program.name} &nbsp;
                            <Link to={props.programViewPathFactory(props.accessList.program.id)}>View</Link>
                          </>
                        )}
                      </td>
                    </tr>
                  )
                  : (
                    <tr>
                      <td>Message displayed to end users:</td>
                      <td>
                        {
                          props.accessList.blockingMessage
                            ? (
                              <>
                                {props.accessList.blockingMessage} &nbsp;
                                <LinkButton onClick={previewModal.open}>Preview</LinkButton>
                              </>
                            )
                            : "(not set)"}
                      </td>
                    </tr>
                  )
              }
              <tr>
                <td>Entry count:</td>
                <td><AccessListEntryCount status={props.entryCountStatus}/></td>
              </tr>
              </tbody>
            </PropertiesTable>
          </PanelRow>
        </Panel>
      </Block>
      <Block>
        <Panel>
          <TitlePanelRow>Entry List Management</TitlePanelRow>
          <ListSourceDetails
            googleGroupId={props.accessList.googleGroupId}
            lastUpdateStartedAt={props.accessList.lastUpdateStartedAt}
            googleGroupDetailsController={props.googleGroupDetailsController}
          />
          {props.disableUpdates && (
            <>
              <IndefiniteProgressBar/>
              <PanelRow status={WidgetStatus.Info}>
                An update is currently being performed to this access list. This update must be completed before
                another update can be performed.
              </PanelRow>
            </>
          )}
          <PanelRow>
            <SimpleToolbar
              items={
                [
                  <Button
                    key={"export"}
                    size={"small"}
                    color={"secondary"}
                    disabled={props.disableUpdates}
                    onClick={() => props.onExportItems()}
                  >
                    Export Entries To CSV
                  </Button>
                ].concat(
                  props.readOnly
                    ? []
                    : [
                      <Link key={"import"} to={props.importEntriesPath}>
                        <Button
                          size={"small"}
                          color={"secondary"}
                        >
                          {
                            props.accessList.googleGroupId
                              ? "Re-Sync Entries From Google Group"
                              : "Import Entries From CSV"
                          }
                        </Button>
                      </Link>
                    ]
                ).concat([
                  <Link key={"import"} to={props.importHistoryPath}>
                    <Button
                      size={"small"}
                      color={"secondary"}
                    >
                      {
                        props.accessList.googleGroupId
                          ? "View Re-Sync History"
                          : "View Import History"
                      }
                    </Button>
                  </Link>
                ]).concat(
                  props.readOnly || props.accessList.googleGroupId
                    ? []
                    : [
                      <Link key={"delete"} to={props.deleteEntriesPath}>
                        <Button
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disableUpdates}
                        >
                          Remove Entries
                        </Button>
                      </Link>,
                    ]
                )
              }
            />
          </PanelRow>
        </Panel>
      </Block>
    </>
  );
};
