/* tslint:disable:no-var-requires max-line-length */

export interface ImagesMap {
  [key: string]: any;
}

export const RawImages: ImagesMap = {
  "/assets/images/blueprint/bolt.svg": require("../../assets/images/blueprint/bolt.svg"),
  "/assets/images/blueprint/check-mark.svg": require("../../assets/images/blueprint/check-mark.svg"),
  "/assets/images/blueprint/clock.svg": require("../../assets/images/blueprint/clock.svg"),
  "/assets/images/blueprint/cross.svg": require("../../assets/images/blueprint/cross.svg"),
  "/assets/images/blueprint/digits.svg": require("../../assets/images/blueprint/digits.svg"),
  "/assets/images/blueprint/exclamation-mark.svg": require("../../assets/images/blueprint/exclamation-mark.svg"),
  "/assets/images/blueprint/key.svg": require("../../assets/images/blueprint/key.svg"),
  "/assets/images/blueprint/lock.svg": require("../../assets/images/blueprint/lock.svg"),
  "/assets/images/blueprint/no-icon.svg": require("../../assets/images/blueprint/no-icon.svg"),
  "/assets/images/blueprint/progress.svg": require("../../assets/images/blueprint/progress.svg"),
  "/assets/images/blueprint/socket.svg": require("../../assets/images/blueprint/socket.svg"),
  "/assets/images/blueprint/variable.svg": require("../../assets/images/blueprint/variable.svg"),
  "/assets/images/carousel/carousel-a.png": require("../../assets/images/carousel/carousel-a.png"),
  "/assets/images/carousel/carousel-b.png": require("../../assets/images/carousel/carousel-b.png"),
  "/assets/images/carousel/carousel-c-old.png": require("../../assets/images/carousel/carousel-c-old.png"),
  "/assets/images/carousel/carousel-c.png": require("../../assets/images/carousel/carousel-c.png"),
  "/assets/images/carousel/carousel-d.png": require("../../assets/images/carousel/carousel-d.png"),
  "/assets/images/carousel/carousel-e.png": require("../../assets/images/carousel/carousel-e.png"),
  "/assets/images/carousel/carousel-f.png": require("../../assets/images/carousel/carousel-f.png"),
  "/assets/images/cloudServices/aol/icon.svg": require("../../assets/images/cloudServices/aol/icon.svg"),
  "/assets/images/cloudServices/aol/logo.svg": require("../../assets/images/cloudServices/aol/logo.svg"),
  "/assets/images/cloudServices/exchange/icon.svg": require("../../assets/images/cloudServices/exchange/icon.svg"),
  "/assets/images/cloudServices/exchange/logo.svg": require("../../assets/images/cloudServices/exchange/logo.svg"),
  "/assets/images/cloudServices/google/calendar.svg": require("../../assets/images/cloudServices/google/calendar.svg"),
  "/assets/images/cloudServices/google/contacts.svg": require("../../assets/images/cloudServices/google/contacts.svg"),
  "/assets/images/cloudServices/google/drive.svg": require("../../assets/images/cloudServices/google/drive.svg"),
  "/assets/images/cloudServices/google/gmail/logo.svg": require("../../assets/images/cloudServices/google/gmail/logo.svg"),
  "/assets/images/cloudServices/google/gmail.svg": require("../../assets/images/cloudServices/google/gmail.svg"),
  "/assets/images/cloudServices/google/gsuite/logo-old.svg": require("../../assets/images/cloudServices/google/gsuite/logo-old.svg"),
  "/assets/images/cloudServices/google/gsuite/logo.svg": require("../../assets/images/cloudServices/google/gsuite/logo.svg"),
  "/assets/images/cloudServices/google/icon.svg": require("../../assets/images/cloudServices/google/icon.svg"),
  "/assets/images/cloudServices/icloud/icon.svg": require("../../assets/images/cloudServices/icloud/icon.svg"),
  "/assets/images/cloudServices/icloud/logo.svg": require("../../assets/images/cloudServices/icloud/logo.svg"),
  "/assets/images/cloudServices/imap/icon.svg": require("../../assets/images/cloudServices/imap/icon.svg"),
  "/assets/images/cloudServices/imap/logo.svg": require("../../assets/images/cloudServices/imap/logo.svg"),
  "/assets/images/cloudServices/microsoft/calendar.svg": require("../../assets/images/cloudServices/microsoft/calendar.svg"),
  "/assets/images/cloudServices/microsoft/office365/icon.svg": require("../../assets/images/cloudServices/microsoft/office365/icon.svg"),
  "/assets/images/cloudServices/microsoft/office365/logo.svg": require("../../assets/images/cloudServices/microsoft/office365/logo.svg"),
  "/assets/images/cloudServices/microsoft/one-drive.svg": require("../../assets/images/cloudServices/microsoft/one-drive.svg"),
  "/assets/images/cloudServices/microsoft/outlook/icon.svg": require("../../assets/images/cloudServices/microsoft/outlook/icon.svg"),
  "/assets/images/cloudServices/microsoft/outlook/logo.svg": require("../../assets/images/cloudServices/microsoft/outlook/logo.svg"),
  "/assets/images/cloudServices/microsoft/outlook.svg": require("../../assets/images/cloudServices/microsoft/outlook.svg"),
  "/assets/images/cloudServices/microsoft/people.svg": require("../../assets/images/cloudServices/microsoft/people.svg"),
  "/assets/images/cloudServices/microsoft/sign-in-button.svg": require("../../assets/images/cloudServices/microsoft/sign-in-button.svg"),
  "/assets/images/cloudServices/yahoo/icon.svg": require("../../assets/images/cloudServices/yahoo/icon.svg"),
  "/assets/images/cloudServices/yahoo/logo.svg": require("../../assets/images/cloudServices/yahoo/logo.svg"),
  "/assets/images/cloudServices/yandex/icon.svg": require("../../assets/images/cloudServices/yandex/icon.svg"),
  "/assets/images/cloudServices/yandex/logo.svg": require("../../assets/images/cloudServices/yandex/logo.svg"),
  "/assets/images/cloudServices/yandex/mail.svg": require("../../assets/images/cloudServices/yandex/mail.svg"),
  "/assets/images/dummy.svg": require("../../assets/images/dummy.svg"),
  "/assets/images/jobs/issue-reported.svg": require("../../assets/images/jobs/issue-reported.svg"),
  "/assets/images/jobs/issue-resolved.svg": require("../../assets/images/jobs/issue-resolved.svg"),
  "/assets/images/jobs/job.svg": require("../../assets/images/jobs/job.svg"),
  "/assets/images/jobs/task-abort.svg": require("../../assets/images/jobs/task-abort.svg"),
  "/assets/images/jobs/task-continued.svg": require("../../assets/images/jobs/task-continued.svg"),
  "/assets/images/jobs/task-failure.svg": require("../../assets/images/jobs/task-failure.svg"),
  "/assets/images/jobs/task-started.svg": require("../../assets/images/jobs/task-started.svg"),
  "/assets/images/jobs/task-success.svg": require("../../assets/images/jobs/task-success.svg"),
  "/assets/images/vaultme/android-chrome-192x192.png": require("../../assets/images/vaultme/android-chrome-192x192.png"),
  "/assets/images/vaultme/android-chrome-512x512.png": require("../../assets/images/vaultme/android-chrome-512x512.png"),
  "/assets/images/vaultme/apple-touch-icon.png": require("../../assets/images/vaultme/apple-touch-icon.png"),
  "/assets/images/vaultme/favicon-16x16.png": require("../../assets/images/vaultme/favicon-16x16.png"),
  "/assets/images/vaultme/favicon-32x32.png": require("../../assets/images/vaultme/favicon-32x32.png"),
  "/assets/images/vaultme/mstile-144x144.png": require("../../assets/images/vaultme/mstile-144x144.png"),
  "/assets/images/vaultme/mstile-150x150.png": require("../../assets/images/vaultme/mstile-150x150.png"),
  "/assets/images/vaultme/mstile-310x150.png": require("../../assets/images/vaultme/mstile-310x150.png"),
  "/assets/images/vaultme/mstile-310x310.png": require("../../assets/images/vaultme/mstile-310x310.png"),
  "/assets/images/vaultme/mstile-70x70.png": require("../../assets/images/vaultme/mstile-70x70.png"),
  "/assets/images/vaultme/safari-pinned-tab.svg": require("../../assets/images/vaultme/safari-pinned-tab.svg"),
};
