import * as React from "react";
import { GraphQL } from "../services/graphql/generated";
import { Connections } from "../types/models/connections";
import { ManagedQueryHook, useManagedQuery } from "../services/graphql/useManagedQuery";
import { DataProxy } from "apollo-cache";
import { enumValues, nullToUndefined } from "../utils/misc";
import { Connection } from "../types/models/connection";
import { useApolloClient } from "@apollo/react-hooks";
import { cachedConnectionId, cachedObjectId, ObjectTypes } from "../app/apolloClientProvider";

export function useConnection(
  userId: string,
  connectionId: string
): ManagedQueryHook<GraphQL.GetConnectionQueryVariables, Connection> {
  return useManagedQuery({
    query: GraphQL.useGetConnectionQuery,
    deps: null,
    prepare: () => ({ userId, connectionId }),
    extract: (data: GraphQL.GetConnectionQuery) => data.connection,
    complete: (connection) => Connection.fromGraphQL(connection),
    // This randomly causes endless loading
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "no-cache",
  });
}

export function useCachedConnection(connectionId: string | undefined): Connection | undefined {
  const apolloClient = useApolloClient();

  const graphQLConnection = nullToUndefined(
    apolloClient.readFragment({
      id: cachedConnectionId(connectionId),
      fragment: GraphQL.ConnectionFragmentDoc,
      fragmentName: ObjectTypes.Connection.fragmentName
    })
  ) as GraphQL.Connection | undefined;

  return React.useMemo(
    () => graphQLConnection && Connection.fromGraphQL(graphQLConnection),
    [graphQLConnection]
  );
}

export function useUserConnections(
  variables: GraphQL.GetUserConnectionsQueryVariables
): ManagedQueryHook<GraphQL.GetUserConnectionsQueryVariables, Connections> {
  return useManagedQuery({
    query: GraphQL.useGetUserConnectionsQuery,
    deps: null,
    prepare: () => variables,
    extract: (data: GraphQL.GetUserConnectionsQuery) => data.connections,
    complete: (connections) => Connections.fromGraphQLList(connections)
  });
}

export function useConnections(
  variables: GraphQL.GetConnectionsQueryVariables
): ManagedQueryHook<GraphQL.GetConnectionsQueryVariables, Connections> {
  return useManagedQuery({
    query: GraphQL.useGetConnectionsQuery,
    deps: null,
    prepare: () => variables,
    extract: (data: GraphQL.GetConnectionsQuery) => data.getConnections,
    complete: (connections) => Connections.fromGraphQLList(connections)
  });
}

// TODO This does not cover getBlueprintResources, GetConnection, etc!
export function cacheConnection(cache: DataProxy, connection: GraphQL.Connection): void {
  enumValues<GraphQL.AccessKeyCheck>(GraphQL.AccessKeyCheck).forEach((accessKeyCheck) => {
    const variables: GraphQL.GetUserConnectionsQueryVariables = { userId: connection.userId, accessKeyCheck };
    try {
      const getUserConnectionsQuery =
        cache.readQuery<GraphQL.GetUserConnectionsQuery, GraphQL.GetUserConnectionsQueryVariables>({
          query: GraphQL.GetUserConnectionsDocument,
          variables
        });
      if (getUserConnectionsQuery && !getUserConnectionsQuery.connections.find((c) => c.id === connection.id)) {
        cache.writeQuery<GraphQL.GetUserConnectionsQuery, GraphQL.GetUserConnectionsQueryVariables>({
          query: GraphQL.GetUserConnectionsDocument,
          variables,
          data: {
            connections: [...getUserConnectionsQuery.connections, connection],
          },
        });
      }
    } catch {
      // Don't do anything
    }
  });
}
