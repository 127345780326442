import * as React from "react";
import { PanelRow } from "../containers/rows/panelRow";
import { Panel } from "../containers/panel";
import { WidgetStatus } from "../utils/widgetStatus";
import { AppTheme, styled } from "../../app/theme";
import { WarningSign } from "../glyphs/warningSign";
import { Button } from "../widgets/button";
import { friendlyCount } from "../../utils/formatting";
import { StyledComponentsProps } from "../utils/styledComponentsProps";
import { StickyContent } from "../utils/stickyContent";
import { Block } from "../containers/block";
import { ActionItems } from "../models/actionItem";
import { scrollIntoViewByClassName } from "../../utils/scrollIntoView";
import { ActionItemViewClassName } from "./actionItemView";

function scrollToActionItems(): void {
  scrollIntoViewByClassName(ActionItemViewClassName);
}

function widgetStatus(stats: ActionItems.Stats): WidgetStatus {
  if (stats.errors) {
    return WidgetStatus.Error;
  } else if (stats.warnings) {
    return WidgetStatus.Warning;
  } else {
    return WidgetStatus.Info;
  }
}

function backgroundColor(theme: AppTheme, stats: ActionItems.Stats): string {
  if (stats.errors) {
    return theme.colors.lightRed;
  } else if (stats.warnings) {
    return theme.colors.lightYellow;
  } else {
    return theme.colors.faintPrimary;
  }
}

function borderColor(theme: AppTheme, stats: ActionItems.Stats): string {
  if (stats.errors) {
    return theme.colors.red;
  } else if (stats.warnings) {
    return theme.colors.yellow;
  } else {
    return theme.colors.primary;
  }
}

function getIcon(stats: ActionItems.Stats): React.ReactNode | undefined {
  if (stats.errors) {
    return <WarningSign color={"red"}/>;
  } else if (stats.warnings) {
    return <WarningSign color={"yellow"}/>;
  } else {
    return undefined;
  }
}

const PortalLayout = styled.div<Props>`
  background: ${(props) => backgroundColor(props.theme, props.stats)};
  border-bottom: 1px solid ${(props) => borderColor(props.theme, props.stats)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
`;

const WarningSignContainer = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  flex-shrink: 0;
  margin-right: .5rem;
`;

const ButtonContainer = styled.div`
  margin-left: .7rem;
`;

interface Props {
  stats: ActionItems.Stats;
}

export const StickyActionItemsSummaryPanel: React.FunctionComponent<Props> = (props) => {
  const icon = getIcon(props.stats);

  if (props.stats.totalActionable) {
    return (
      <Block>
        <StickyContent
          whenPinned={(
            <PortalLayout stats={props.stats}>
              {icon && <WarningSignContainer>{icon}</WarningSignContainer>}
              <Summary brief={true} {...props}/>
              <ButtonContainer>
                <Button size={"small"} color={"secondary"} onClick={scrollToActionItems}>View</Button>
              </ButtonContainer>
            </PortalLayout>
          )}
        >
          <ContentPanel {...props}/>
        </StickyContent>
      </Block>
    );
  } else {
    return null;
  }
};

const ContentPanel: React.FunctionComponent<Props & StyledComponentsProps> = (props) => (
  <Panel>
    <PanelRow
      className={props.className}
      icon={getIcon(props.stats)}
      status={widgetStatus(props.stats)}
      actions={<Button size={"small"} color={"secondary"} onClick={scrollToActionItems}>View</Button>}
    >
      <Summary brief={false} {...props}/>
      <br/>
      Scroll down for details.
    </PanelRow>
  </Panel>
);

interface SummaryProps extends Props {
  brief: boolean;
}

const Summary: React.FunctionComponent<SummaryProps> = (props) => {
  const count = friendlyCount(props.stats.totalActionable, "additional action");
  if (props.brief) {
    return <>{count} need{props.stats.totalActionable === 1 ? "s" : ""} to be taken</>;
  } else {
    return <>You need to take {count} to be able to proceed.</>;
  }
};

// const Summary: React.FunctionComponent<SummaryProps> = (props) => {
//   const isSingle = props.stats.errors === 1 || (props.stats.errors === 0 && props.stats.warnings === 1);
//   return (
//     <>
//       {!props.brief && <>There {isSingle ? "is" : "are"}{" "}</>}
//       {
//         props.stats.errors !== 0 && props.stats.warnings !== 0
//           ? friendlyCount(props.stats.errors, "issue") +
//           " and " +
//           friendlyCount(props.stats.warnings, "warning")
//           : (
//             props.stats.warnings !== 0
//               ? friendlyCount(props.stats.warnings, "warning")
//               : friendlyCount(props.stats.errors, "issue")
//           )
//       }{" "}
//       to be cleared{props.brief ? "" : "."}
//     </>
//   );
// };
