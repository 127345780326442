import * as React from "react";
import { Page } from "../containers/page";
import { OperationStatus } from "../../types/operationStatus";
import { Block } from "../containers/block";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { TaskIssue } from "../../types/models/taskIssue";
import { List } from "immutable";
import { TaskIssueInfoPanel } from "../blocks/jobManagement/taskIssueInfoPanel";
import { Panel } from "../containers/panel";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { TaskIssueHistoryGrid } from "../blocks/jobManagement/taskIssueHistoryGrid";

interface Props {
  jobId: string;
  taskId: string;
  issueId: string;

  loadingStatus: OperationStatus<List<TaskIssue>>;

  onRefresh: () => void;
}

export const TaskIssuePageView: React.FunctionComponent<Props> = (props) => {
  return (
    <Page
      breadcrumbs={["Jobs", props.jobId, props.taskId]}
      title={`Issue \"${props.issueId}\"`}
      fullWidth={true}
    >
      {(!props.loadingStatus.someResult() || props.loadingStatus.isFailure()) && (
        <OperationStatusIndicator
          subject={"issue"}
          status={props.loadingStatus}
          indicators={StatusIndicators.SimplePanel()}
        />
      )}
      {props.loadingStatus.mapLastResult((taskIssues) => (
        <>
          <Block>
            <TaskIssueInfoPanel
              jobId={props.jobId}
              taskId={props.taskId}
              refreshing={props.loadingStatus.isWorking()}
              onRefresh={props.onRefresh}
            />
          </Block>
          <Block>
            <Panel>
              <GridPanelRow>
                <TaskIssueHistoryGrid taskIssues={taskIssues.reverse()}/>
              </GridPanelRow>
            </Panel>
          </Block>
        </>
      ))}
    </Page>
  );
};
