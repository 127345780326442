import * as React from "react";
import { styled } from "../../../app/theme";
import { CloudServiceList } from "./cloudServiceList";
import { Constants } from "../../../app/constants";
import { AuthContext } from "../authContext";
import { CloudServices } from "../../../types/models/cloudServices";
import { useAppBootstrapConfig } from "../../../app/configuration";
import { DrawerBlock } from "../../containers/drawerBlock";
import { LinkButton } from "../../widgets/linkButton";
import { ProgramEscape } from "../programEscape";

interface CloudServiceListPopupProps {
  authContext: AuthContext;
  cloudServices: CloudServices;
  onCloudServiceSelect: (cloudServiceId: string) => void;
}

const StyledCloudServiceList = styled(CloudServiceList)`
  margin-top: 1.5rem;
`;

const Paragraph = styled.div`
  margin-top: 1rem;
`;

const Direction = styled.span`
  font-weight: ${(props) => props.theme.font.bold};
`;

interface AuthContextSettings {
  paragraphs: React.ReactNode[];
}

const SignUp: AuthContextSettings = {
  paragraphs: [(
    <>
      Choose which service provider you'd like to use for signing in to VaultMe
      (or used previously in the case if you already have a VaultMe account)
    </>
  )]
};

const Identification: AuthContextSettings = {
  paragraphs: [
    <>Choose which service provider you have used previously with {Constants.Product.name}</>
  ]
};

const Source: AuthContextSettings = {
  paragraphs: [
    <>Choose which service provider you want to migrate your data <Direction>from</Direction></>,
    (
      <>
        Once you choose a source and a destination provider, {Constants.Product.name}{" "}
        will tell you what types of data can be migrated between them.
      </>
    )
  ]
};

const Destination: AuthContextSettings = {
  paragraphs: [
    <>Choose which service provider you want to migrate your data <Direction>to</Direction></>
  ]
};

function settingsForAuthContext(authContext: AuthContext): AuthContextSettings {
  switch (authContext) {
    case AuthContext.SignUp: return SignUp;
    case AuthContext.Identification: return Identification;
    case AuthContext.Source: return Source;
    case AuthContext.Destination: return Destination;
  }
}

export const CloudServiceListPopup: React.FunctionComponent<CloudServiceListPopupProps> = (props) => {
  const settings = settingsForAuthContext(props.authContext);
  const appBootstrapConfig = useAppBootstrapConfig();
  const [switching, setSwitching] = React.useState(false);

  if (switching) {
    return <ProgramEscape onContinue={() => setSwitching(false)}/>;
  } else {
    return (
      <>
        {settings.paragraphs.map((p, index) => <Paragraph key={index}>{p}</Paragraph>)}
        <StyledCloudServiceList cloudServices={props.cloudServices} onCloudServiceSelect={props.onCloudServiceSelect}/>
        {props.authContext === AuthContext.Source && appBootstrapConfig.configuration?.escapeLinkText && (
          <DrawerBlock>
            <LinkButton onClick={() => setSwitching(true)}>
              {appBootstrapConfig.configuration.escapeLinkText}
            </LinkButton>
          </DrawerBlock>
        )}
      </>
    );
  }
};
