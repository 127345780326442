import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { OrganizationAccessListStats } from "../../types/models/organizationAccessListStats";
import { identity, nullToUndefined } from "../../utils/misc";

export function useGetOrganizationAccessListStatsQuery(
    organizationId: number
): ManagedQueryHook<GraphQL.GetOrganizationAccessListStatsQueryVariables, OrganizationAccessListStats> {
  return useManagedQuery({
    query: GraphQL.useGetOrganizationAccessListStatsQuery,
    deps: null,
    prepare: () => ({ organizationId }) ,
    extract: (data: GraphQL.GetOrganizationAccessListStatsQuery) => nullToUndefined(data.organizationAccessListStats),
    complete: identity,
    // cache-and-network looks like a better strategy, but it's not working well with re-tries (the query gets stuck
    // in a loading state for some reason)
    fetchPolicy: "network-only"
  });
}
