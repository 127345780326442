import * as React from "react";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { TitlePanelRow } from "../../../containers/rows/titlePanelRow";
import { CloudServiceChooserRow } from "../../../blocks/cloudServiceChooser/cloudServiceChooserRow";
import { AuthContext } from "../../../blocks/authContext";
import { MigrationStatusPageState } from "../migrationStatusPageState";
import { MigrationCompleteIndicatorRow } from "../rows/migrationCompleteIndicatorRow";
import { StyledConnectionDescriptionRow } from "../rows/styledConnectionDescriptionRow";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { ConnectionIssuesRow } from "../rows/connectionStatusRow";
import { DestinationAreaRow } from "../rows/destinationAreaRow";
import { prepareActionItems } from "../prepareActionItems";
import { ActionItems } from "../../../models/actionItem";
import { useCloudServices } from "../../../../app/configuration";
import { SignInContextType } from "../../../../types/models/signInContextType";

interface DestinationConnectionPanelProps {
  title?: string;
  state: MigrationStatusPageState.Any;
  source: MigrationStatusPageDefs.SourceConnectionDetails;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const DestinationConnectionPanel: React.FunctionComponent<DestinationConnectionPanelProps> = (props) => {
  const cloudServices = useCloudServices(SignInContextType.Source);

  const connectionErrors = ActionItems.Stats.build(props.destination.actionItems).errors !== 0;

  return (
    <Block>
      <Panel>
        <TitlePanelRow>{props.title || "Migrate To"}</TitlePanelRow>
        <CloudServiceChooserRow
          authContext={AuthContext.Destination}
          cloudServices={cloudServices}
          selectedCloudServiceId={props.destination.cloudService.id}
        />
        {props.state.type === MigrationStatusPageState.Type.Complete && <MigrationCompleteIndicatorRow/>}
        <StyledConnectionDescriptionRow
          authContext={AuthContext.Destination}
          connectionDescription={props.destination.connection.descriptionOrId()}
          status={
            props.state.type === MigrationStatusPageState.Type.Complete ||
            props.state.type === MigrationStatusPageState.Type.Aborted
              ? "All data received from " + props.source.cloudService.name
              : (
                connectionErrors
                  ? "Receiving data from " + props.source.cloudService.name + " is paused..."
                  : "Receiving data from " + props.source.cloudService.name + "..."
              )
          }
          reserveSpaceForCompletionIndicator={props.state.type === MigrationStatusPageState.Type.Complete}
        />
        <ConnectionIssuesRow connectionDetails={props.destination}/>
        {
          props.destination.areas
            .filter((area) => !prepareActionItems(area).isEmpty())
            .map((area) => (
              <DestinationAreaRow
                key={area.title}
                cloudServices={cloudServices}
                selectedCloudServiceId={props.source.cloudService.id}
                connectionId={props.source.connection.id}
                destination={props.destination}
                area={area}
                connectionSlowness={!!area.slowness || !!props.source.slowness}
                connectionErrors={connectionErrors}
                actionItemSuppressing={props.actionItemSuppressing}
              />
            ))
        }
      </Panel>
    </Block>
  );
};
