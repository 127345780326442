// tslint:disable:no-bitwise
// tslint:disable:no-parameter-reassignment

// Taken from here:
// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript/47593316#47593316
function xmur3(str: string) {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i += 1) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = h << 13 | h >>> 19;
  }
  return () => {
    h = Math.imul(h ^ h >>> 16, 2246822507);
    h = Math.imul(h ^ h >>> 13, 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
}

function xoshiro128ss(a: number, b: number, c: number, d: number) {
  return () => {
    const t = b << 9;
    let r = a * 5;
    r = (r << 7 | r >>> 25) * 9;
    c ^= a;
    d ^= b;
    b ^= c;
    a ^= d;
    c ^= t;
    d = d << 11 | d >>> 21;
    return (r >>> 0) / 4294967296;
  };
}

export class Random {
  public static seed = xmur3(new Date().toString());

  protected generate: () => number;

  constructor(seed: number = new Date().getTime()) {
    this.generate = xoshiro128ss(seed, seed, seed, seed);
  }

  public next(): number {
    return this.generate();
  }

  public nextInt(max: number = 4294967296): number {
    return Math.floor(this.generate() * max);
  }

  public shuffle<T>(array: T[]): T[] {
    const result: T[] = [];
    const source = array.slice();
    while (source.length > 0) {
      const index = this.nextInt(source.length);
      result.push(source[index]);
      source.splice(index, 1);
    }
    return result;
  }

  public alphanumeric(length: number): string {
    return Array.from(Array(length), () => Math.floor(Math.random() * 36).toString(36)).join("");
  }
}
