import * as React from "react";
import { Grid } from "../../widgets/grid";
import { MigrationStatus } from "../../../types/models/migrationStatus";
import {
  briefDateTime,
  friendlyCount,
  friendlyDuration,
  friendlySize,
  preciseDurationBetween
} from "../../../utils/formatting";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { ConnectionIdAndIcon, } from "../../blocks/connectionManagement/cloudServiceNameAndIcon";
import { styled } from "../../../app/theme";
import { Link } from "react-router-dom";
import { WidgetStatus } from "../../utils/widgetStatus";
import { List } from "immutable";
import { Migration } from "../../../types/models/migration";
import { MigrationNoteType } from "../../../types/models/migrationNoteType";
import { MigrationNote } from "../../../types/models/migrationNote";
import { Markdown } from "../../widgets/markdown";
import { MonitoringDashboardPageViewDefs } from "./monitoringDashboardPageViewDefs";
import { Switch } from "../../widgets/switch";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { CheckedCircle } from "../../glyphs/checkedCircle";
import { Spinner } from "../../glyphs/spinner";
import { useRoutes } from "../../../app/routes/useRoutes";

export const SmallConnectionIdAndIcon = styled(ConnectionIdAndIcon)`
  background-size: 0.75rem;
  background-position-y: 0.28rem;
  padding-left: 1rem;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    background-size: 0.6rem;
    background-position-y: 0.21rem;
    padding-left: 0.85rem;
  }
`;

const MigrationNoteIconContainer = styled.div`
  flex: 0 0 auto;
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  position: relative;
  top: .15rem;
`;

const MigrationNoteLayout = styled.div`
  width: 20rem;
  display: flex;

  & ~ & {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid ${(props) => props.theme.colors.lightGray}; 
  }
`;

interface MigrationNoteViewProps {
  migrationNote: MigrationNote;
}

const MigrationNoteView: React.FunctionComponent<MigrationNoteViewProps> = (props) => (
  <MigrationNoteLayout>
    <MigrationNoteIconContainer>
      {MigrationNoteType.icon(props.migrationNote.type)}
    </MigrationNoteIconContainer>
    <Markdown markdown={props.migrationNote.renderedMessageSnippetWithAuthor}/>
  </MigrationNoteLayout>
);

const IssueViewLayout = styled.div`
  min-width: 15rem;
  word-wrap: break-word;
  word-break: break-all;

  & ~ & {
    margin-top: .5rem;
  }
`;

const AreaLabel = styled.div<{ isCritical: boolean }>`
  display: inline-block;
  position: relative;
  top: -.1rem;
  margin-right: .2rem;

  background: ${(props) => props.isCritical ? props.theme.colors.red : props.theme.colors.yellow};
  color: white;
  font-size: 0.7rem;
  height: 1.1rem;
  padding: 0.2rem;
  box-sizing: border-box;
`;

const NoRealTimeUpdates = styled.span`
  font-weight: ${(props) => props.theme.font.light};
  color: #aaa;
  font-size: 0.65rem;
  position: relative;
  top: -.1rem;
`;

interface IssueSummary {
  area: string;
  message: string;
  isCritical: boolean;
}

interface IssueViewProps {
  issue: IssueSummary;
}

const IssueView: React.FunctionComponent<IssueViewProps> = (props) => (
  <IssueViewLayout>
    <AreaLabel isCritical={props.issue.isCritical}>{props.issue.area}</AreaLabel>
    {props.issue.message}
  </IssueViewLayout>
);

const IterationLabel = styled.div`
  display: inline-block;
  position: relative;
  top: -.1rem;
  margin-right: .2rem;

  background: ${(props) => props.theme.colors.darkGray};
  color: white;
  font-size: 0.7rem;
  height: 1.1rem;
  padding: 0.2rem;
  box-sizing: border-box;
`;

const StatusCellLine = styled.div`
  display: flex;
`;

const StatusIndicatorContainer = styled.div<{ shiftDown?: boolean }>`
  flex-shrink: 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  font-size: 0.9rem;
  position: relative;
  top: ${(props) => props.shiftDown ? 0.1 : 0}rem;
  left: ${(props) => props.shiftDown ? 0.1 : 0}rem;
`;

const GroupTitle = styled.div<{ status: "inactive" | "active" | "error" }>`
  color: ${(props) =>
  props.status === "inactive"
    ? props.theme.colors.gray
    : props.status === "active"
    ? props.theme.colors.black
    : props.theme.colors.red
};
`;

interface Props {
  completedWithIssues: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;

  requireManualReview: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;
  runningWithIssues: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;
  delayed: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;
  waitingForUserAction: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;

  runningNormally: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;

  completedNormally: List<MonitoringDashboardPageViewDefs.MonitoredMigration>;

  completedNormallyCount: number;
  completedNormallyWindows: number[];
  onCompletedNormallyCountChange: (value: number) => void;
}

export const MigrationsGrid: React.FunctionComponent<Props> = (props) => {
  function renderRows(
    migrations: List<MonitoringDashboardPageViewDefs.MonitoredMigration>,
    suffix: string,
    errorHighlight: boolean,
    tools?: React.ReactNode
  ) {
    const title = (
      <GroupTitle
        status={migrations.isEmpty() ? "inactive" : errorHighlight ? "error" : "active"}
      >
        {friendlyCount(migrations.size, "migration") + " " + suffix}
      </GroupTitle>
    );
    return (
      <>
        <Grid.Row>
          <Grid.Group colSpan={11}>
            {
              tools
                ? (
                  <SimpleToolbar>
                    {title}
                    {tools}
                  </SimpleToolbar>
                )
                : title
            }
          </Grid.Group>
        </Grid.Row>
        {migrations.map((item, index) => <MigrationRow key={item.id} migration={item}/>)}
      </>
    );
  }

  return (
    <GridPanelRow>
      <Grid stickyFirstColumn={true} compact={true}>
        <Grid.Header>
          <Grid.Column nowrap={true}>ID</Grid.Column>
          <Grid.Column nowrap={true}>Source / Destination</Grid.Column>
          <Grid.Column nowrap={true}>Size</Grid.Column>
          <Grid.Column nowrap={true}>Value</Grid.Column>
          <Grid.Column nowrap={true}>Status</Grid.Column>
          <Grid.Column nowrap={true}>Started</Grid.Column>
          <Grid.Column nowrap={true}>Completed /<br/>Remaining</Grid.Column>
          <Grid.Column nowrap={true}>Manual Review</Grid.Column>
          <Grid.Column nowrap={true}>
            Issues <NoRealTimeUpdates>&#x23F1;</NoRealTimeUpdates>
          </Grid.Column>
          <Grid.Column nowrap={true}>Supervised By</Grid.Column>
          <Grid.Column nowrap={true}>Notes</Grid.Column>
        </Grid.Header>
        <Grid.Body>
          {renderRows(props.completedWithIssues, "completed with pending actions", true)}

          {renderRows(props.requireManualReview, "requiring manual review", true)}
          {renderRows(props.runningWithIssues, "having technical issues", true)}

          {renderRows(props.delayed, "experiencing major delays", false)}
          {renderRows(props.waitingForUserAction, "waiting for user action", false)}

          {renderRows(props.runningNormally, "running normally", false)}
          {
            renderRows(
              props.completedNormally,
              "completed normally",
              false,
              (
                <Switch
                  selected={props.completedNormallyCount}
                  items={
                    [{ value: 0, content: "Hide" }]
                      .concat(props.completedNormallyWindows.map((count) =>
                        ({ value: count, content: "Last " + count })
                      ))
                  }
                  onSelect={props.onCompletedNormallyCountChange}
                />
              )
            )
          }
        </Grid.Body>
      </Grid>
    </GridPanelRow>
  );
};

interface MigrationRowProps {
  migration: MonitoringDashboardPageViewDefs.MonitoredMigration;
}

const MigrationRow: React.FunctionComponent<MigrationRowProps> = (props) => {
  const routes = useRoutes();

  function migrationStatusToCellStatus(status: MigrationStatus): WidgetStatus | undefined {
    if (status === MigrationStatus.Scheduled) {
      return WidgetStatus.Uncertain;
    } else if (status === MigrationStatus.Completed) {
      return WidgetStatus.Success;
    } else if (
      status === MigrationStatus.WaitingForManualReview ||
      status === MigrationStatus.StoppedForUnknownReason
    ) {
      return WidgetStatus.Error;
    } if (
      status === MigrationStatus.WaitingForTemporaryCondition ||
      status === MigrationStatus.WaitingForUserAction
    ) {
      return WidgetStatus.Warning;
    }
  }

  function delayFactorToCellStatus(delayLevel: Migration.Timing.DelayLevel): WidgetStatus | undefined {
    switch (delayLevel) {
      case Migration.Timing.DelayLevel.Major: return WidgetStatus.Error;
      case Migration.Timing.DelayLevel.Minor: return WidgetStatus.Warning;
      default: return undefined;
    }
  }

  function renderTimingCells() {
    const timing = props.migration.timing;
    if (timing instanceof Migration.CompletedTiming) {
      return (
        <>
          <Grid.Cell nowrap={true}>
            {briefDateTime(timing.startedAt)}
            <br/>
            {friendlyDuration(timing.completedIn, true)} elapsed
          </Grid.Cell>
          <Grid.Cell nowrap={true} status={delayFactorToCellStatus(timing.delayLevel)}>
            {briefDateTime(timing.completedAt)}
            <br/>
            {(timing.isDelayed ? "Delayed" : "On time") + " (" + timing.delayFactor.toFixed(2) + "x)"}
          </Grid.Cell>
        </>
      );
    } else if (timing instanceof Migration.RunningTiming) {
      const mayBeDelayed = timing.mayBeDelayed && !timing.isDelayFactorReliable;
      return (
        <>
          <Grid.Cell nowrap={true}>
            {briefDateTime(timing.startedAt)}
            <br/>
            {friendlyDuration(timing.elapsedTime, true)} elapsed
          </Grid.Cell>
          <Grid.Cell
            nowrap={true}
            status={mayBeDelayed ? WidgetStatus.Uncertain : delayFactorToCellStatus(timing.delayLevel)}
          >
            {friendlyDuration(timing.remainingTimeEstimate, true) + (mayBeDelayed ? "?" : "")}
            <br/>
            {
              (timing.mayBeDelayed ? "Delayed" : "On time") +
              " (" + timing.delayFactor.toFixed(2) + "x)" +
              (mayBeDelayed ? "?" : "")
            }
          </Grid.Cell>
        </>
      );
    } else if (timing instanceof Migration.ScheduledTiming) {
      return (
        <>
          <Grid.Cell nowrap={true}>
            {briefDateTime(timing.scheduledAt)}
            <br/>
            {friendlyDuration(timing.pendingFor, true)} ago
          </Grid.Cell>
          <Grid.Cell>--</Grid.Cell>
        </>
      );
    }
  }

  function migrationNotesCellStatus(): WidgetStatus | undefined {
    if (
      props.migration.activeNotes.find(((migrationNote) => migrationNote.type === MigrationNoteType.Issue))
    ) {
      return WidgetStatus.Error;
    } else if (
      props.migration.activeNotes.find(((migrationNote) => migrationNote.type === MigrationNoteType.Warning))
    ) {
      return WidgetStatus.Warning;
    } else if (!props.migration.activeNotes.isEmpty()) {
      return WidgetStatus.Info;
    } else {
      return undefined;
    }
  }

  const progress = props.migration.progress;
  const totalItemsForManualReview = props.migration.totalItemsForManualReview;

  const issues = props.migration.migrationIssues
    .map((issue): IssueSummary =>
      ({ area: "Engine", message: issue.adminSummary, isCritical: issue.isBlocking })
    )
    .concat(props.migration.activeJobIssues.map((issue): IssueSummary =>
      ({ area: "Job", message: issue.issueId, isCritical: true })
    ))
    .sortBy(({ area, message }) => area + "-" + message);

  return (
    <Grid.Row>
      <Grid.Cell>
        {props.migration.batch?.batchId && ("(" + props.migration.batch?.batchId + ") ")}
        <Link to={routes.migrations.migrationStatusPath(props.migration.id)}>{props.migration.id}</Link>
      </Grid.Cell>
      <Grid.Cell nowrap={true}>
        <SmallConnectionIdAndIcon connection={props.migration.sourceConnection} addFromToArrow={true}/>
        <SmallConnectionIdAndIcon connection={props.migration.destinationConnection}/>
      </Grid.Cell>
      <Grid.Cell nowrap={true}>
        {friendlyCount(props.migration.cumulativeStats.totalItems || props.migration.totalItemsEstimate, "item")}
        <br/>
        {friendlySize(props.migration.cumulativeStats.totalBytes || props.migration.totalBytesEstimate)}
      </Grid.Cell>
      <Grid.Cell nowrap={true}>
        {
          props.migration.value !== undefined
            ? (
              props.migration.batch
                ? <strong>Batch</strong>
                : props.migration.isVIP
                ? <strong>{"$" + props.migration.value.toFixed(2)}</strong>
                : ("$" + props.migration.value.toFixed(2))
            )
            : "--"
        }
      </Grid.Cell>
      <Grid.ProgressCell
        nowrap={true}
        status={migrationStatusToCellStatus(props.migration.status)}
        progress={progress}
      >
        <StatusCellLine>
          <StatusIndicatorContainer shiftDown={true}>
            {
              props.migration.status === MigrationStatus.Completed
                ? <CheckedCircle/>
                : props.migration.status === MigrationStatus.Starting ||
                props.migration.status === MigrationStatus.Running ||
                props.migration.status === MigrationStatus.Pausing
                ? <Spinner progress={-1}/>
                : <Spinner progress={50} color={"red"}/>
            }
          </StatusIndicatorContainer>
          {props.migration.iteration > 0 ? <IterationLabel>&Delta;{props.migration.iteration}</IterationLabel> : ""}
          {MigrationStatus.adminFriendly(props.migration.status, props.migration.iteration)}
        </StatusCellLine>
        {props.migration.autoResumeAt && (
          <StatusCellLine>
            <StatusIndicatorContainer>&#9200;</StatusIndicatorContainer>
            {
              props.migration.isAutoResumingEnabled
                ? briefDateTime(props.migration.autoResumeAt) + " (in " +
                preciseDurationBetween(props.migration.autoResumeAt, new Date(), false) +
                ")"
                : (
                  <>
                    {briefDateTime(props.migration.autoResumeAt)}&nbsp;
                    <strong>(DISABLED)</strong>
                  </>
                )
            }
          </StatusCellLine>
        )}
        <StatusCellLine>
          <StatusIndicatorContainer>&nbsp;</StatusIndicatorContainer>
          {progress.toFixed(1)}% ({props.migration.skippedPercentage.toFixed(1)}% skpd)
        </StatusCellLine>
      </Grid.ProgressCell>
      {renderTimingCells()}
      <Grid.Cell
        nowrap={true}
        status={totalItemsForManualReview > 0 ? WidgetStatus.Error : undefined}
      >
        {totalItemsForManualReview !== 0 ? friendlyCount(totalItemsForManualReview, "item") : "--"}
      </Grid.Cell>
      <Grid.Cell
        status={
          issues.isEmpty()
            ? undefined
            : !!issues.find((issue) => issue.isCritical)
            ? WidgetStatus.Error
            : WidgetStatus.Warning
        }
      >
        {
          issues.isEmpty()
            ? "--"
            : issues.map((issue) => <IssueView key={issue.area + "-" + issue.message} issue={issue}/>)
        }
      </Grid.Cell>
      <Grid.Cell status={props.migration.supervisedBy ? WidgetStatus.Info : undefined}>
        {props.migration.supervisedBy || "--"}
      </Grid.Cell>
      <Grid.Cell status={migrationNotesCellStatus()}>
        {
          props.migration.activeNotes.isEmpty()
            ? "--"
            : props.migration.activeNotes
              .sortBy((migrationNote) =>
                (3 - migrationNote.priority) + "/" + migrationNote.createdAt.toISOString()
              )
              .reverse()
              .map((migrationNote) =>
                <MigrationNoteView key={migrationNote.id} migrationNote={migrationNote}/>
              )
        }
      </Grid.Cell>
    </Grid.Row>
  );
};
