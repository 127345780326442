import { Facts } from "../../types/facts/facts";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { List, Set } from "immutable";
import { Connection } from "../../types/models/connection";
import { BlueprintFactSpec } from "../../queries/useBlueprintFacts";
import { CloudServices } from "../../types/models/cloudServices";
import { AuthProviders } from "../../types/models/authProviders";
import { CloudService } from "../../types/models/cloudService";
import { Blueprint } from "../../blueprints/blueprint";
import {
  BatchMigrationItemsGridRow
} from "../../views/screens/migrationSetup/batch/batchMigrationPlanStepView/batchMigrationItemsGrid";

export interface BatchContext {
  cloudServices: CloudServices;
  authProviders: AuthProviders;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;

  blueprint: Blueprint;
}

export interface BatchMigrationItem {
  sourceConnection: Connection | undefined;
  destinationConnection: Connection | undefined;
}

export class BatchMigrationPlan {
  public blueprintFactSpecs: List<BlueprintFactSpec>;
  public factIds: Set<string>;

  private data: List<BatchMigrationPlan.ItemData>;

  constructor(public batchContext: BatchContext) {
    this.data = List();
    this.blueprintFactSpecs = List();
    this.factIds = Set();
  }

  public matchesContext(batchContext: BatchContext): boolean {
    return (
      this.batchContext.cloudServices === batchContext.cloudServices &&
      this.batchContext.authProviders === batchContext.authProviders &&
      this.batchContext.sourceCloudService === batchContext.sourceCloudService &&
      this.batchContext.destinationCloudService === batchContext.destinationCloudService &&
      this.batchContext.blueprint === batchContext.blueprint
    );
  }

  public setItems(items: List<BatchMigrationItem>): BatchMigrationPlan {
    const mayBeNewData = items.map((item) => {
      const existing = this.data.find((itemData) => itemData.matches(item));
      return existing ? existing : new BatchMigrationPlan.ItemData(this.batchContext, item);
    });

    if (!mayBeNewData.equals(this.data)) {
      this.data = mayBeNewData;

      this.blueprintFactSpecs = this.data.map((itemData) => itemData.blueprintFactSpec);

      this.factIds = this.blueprintFactSpecs
        .map((spec) => spec.factIds)
        .reduce((a, b) => a.merge(b), Set<string>());
    }

    return this;
  }

  public get(item: BatchMigrationItem): BatchMigrationPlan.ItemData | undefined {
    return this.data.find((itemData) => itemData.matches(item));
  }

  public buildGridRows(facts: Facts): List<BatchMigrationItemsGridRow> {
    return this.data.map((itemData) => itemData.buildGridRow(facts));
  }

  public withFacts(facts: Facts): BatchMigrationPlanWithFacts {
    return new BatchMigrationPlanWithFacts(this, facts);
  }
}

export class BatchMigrationPlanWithFacts {
  public readonly gridRows: List<BatchMigrationItemsGridRow>;

  constructor(
    public readonly plan: BatchMigrationPlan,
    public readonly facts: Facts
  ) {
    this.gridRows = plan.buildGridRows(facts);
  }

  public getMigrationBlueprint(item: BatchMigrationItem): MaterializedMigrationBlueprint | undefined {
    return this.plan.get(item)?.migrationBlueprint(this.facts);
  }
}

export namespace BatchMigrationPlan {
  interface MemoizedMigrationBlueprint {
    allFacts: Facts;
    itemFacts: Facts;
    migrationBlueprint: MaterializedMigrationBlueprint;
  }

  interface MemoizedBatchMigrationItemsGridRow {
    migrationBlueprint: MaterializedMigrationBlueprint;
    batchMigrationItemsGridRow: BatchMigrationItemsGridRow;
  }

  export class ItemData {
    public readonly blueprintFactSpec: BlueprintFactSpec;

    private memoizedMigrationBlueprint: MemoizedMigrationBlueprint | undefined;
    private memoizedBatchMigrationItemsGridRow: MemoizedBatchMigrationItemsGridRow | undefined;

    constructor(
      private batchContext: BatchContext,
      private item: BatchMigrationItem
    ) {
      const emptyBlueprint = this.buildBlueprint(Facts.Empty);

      this.blueprintFactSpec = {
        blueprintInputs: emptyBlueprint.context.inputs,
        factIds: emptyBlueprint.unblockedFactIds()
      };
    }

    public matches(item: BatchMigrationItem): boolean {
      return item.sourceConnection === this.item.sourceConnection &&
        item.destinationConnection === this.item.destinationConnection;
    }

    public migrationBlueprint(allFacts: Facts): MaterializedMigrationBlueprint {
      if (
        this.memoizedMigrationBlueprint &&
        this.memoizedMigrationBlueprint.allFacts.fingerprint === allFacts.fingerprint
      ) {
        // Since all facts are the same, the subset relevant to this item is the same too => let's use the cached
        // blueprint
      } else {
        // All facts have changed, let's see if this item is affected
        const itemFacts = allFacts.subset(this.blueprintFactSpec.factIds);
        if (
          this.memoizedMigrationBlueprint &&
          itemFacts.fingerprint === this.memoizedMigrationBlueprint.itemFacts.fingerprint
        ) {
          // All facts have changed, but the item facts are still the same - let's simply update all facts and use the
          // cached blueprint
          this.memoizedMigrationBlueprint = {
            ...this.memoizedMigrationBlueprint,
            allFacts
          };
        } else {
          this.memoizedMigrationBlueprint = {
            allFacts,
            itemFacts,
            migrationBlueprint: this.buildBlueprint(itemFacts)
          };
        }
      }
      return this.memoizedMigrationBlueprint.migrationBlueprint;
    }

    public buildGridRow(facts: Facts): BatchMigrationItemsGridRow {
      const blueprint = this.migrationBlueprint(facts);
      if (
        this.memoizedBatchMigrationItemsGridRow &&
        this.memoizedBatchMigrationItemsGridRow.migrationBlueprint === blueprint
      ) {
        return this.memoizedBatchMigrationItemsGridRow.batchMigrationItemsGridRow;
      } else {
        const totals = blueprint.migrationTotals();
        this.memoizedBatchMigrationItemsGridRow = {
          migrationBlueprint: blueprint,
          batchMigrationItemsGridRow: {
            sourceConnection: this.item.sourceConnection,
            destinationConnection: this.item.destinationConnection,
            totalItems: this.item.sourceConnection ? totals.itemCount : undefined,
            totalSize: this.item.sourceConnection ? totals.size : undefined,
            sourceStatus: blueprint.sourceStatus(),
            destinationStatus: blueprint.destinationStatus()
          }
        };
        return this.memoizedBatchMigrationItemsGridRow.batchMigrationItemsGridRow;
      }
    }

    private buildBlueprint(facts: Facts): MaterializedMigrationBlueprint {
      return MaterializedMigrationBlueprint.build({
        cloudServices: this.batchContext.cloudServices,
        authProviders: this.batchContext.authProviders,

        sourceCloudServiceId: this.batchContext.sourceCloudService.id,
        destinationCloudServiceId: this.batchContext.destinationCloudService.id,

        blueprint: this.batchContext.blueprint,

        sourceConnection: this.item.sourceConnection,
        destinationConnection: this.item.destinationConnection,

        excludedAreas: [],
        facts
      });
    }
  }
}
