import * as React from "react";
import { JobHistory } from "../../../types/models/jobHistory";
import { Panel } from "../../containers/panel";
import { JobHistoryEventsGrid } from "./jobHistoryEventsGrid";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { PanelRow } from "../../containers/rows/panelRow";
import { Checkbox } from "../../widgets/checkbox";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";

interface Props {
  jobHistory: JobHistory;

  showJobEvents: boolean;
  showRunningTasks: boolean;
  showCompletedTasks: boolean;
  showPendingIssues: boolean;
  showResolvedIssues: boolean;

  reset: () => void;

  onToggleShowJobEvents: () => void;
  onToggleShowRunningTasks: () => void;
  onToggleShowCompletedTasks: () => void;
  onToggleShowPendingIssues: () => void;
  onToggleShowResolvedIssues: () => void;
}

export const JobHistoryPanelView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow>
      <SimpleToolbar>
        <Button size={"small"} color={"white"} onClick={props.reset}>Reset</Button>
        <Checkbox checked={props.showJobEvents} onChange={props.onToggleShowJobEvents}>
          Job events
        </Checkbox>
        <Checkbox checked={props.showRunningTasks} onChange={props.onToggleShowRunningTasks}>
          Running tasks
        </Checkbox>
        <Checkbox checked={props.showCompletedTasks} onChange={props.onToggleShowCompletedTasks}>
          Completed tasks
        </Checkbox>
        <Checkbox checked={props.showPendingIssues} onChange={props.onToggleShowPendingIssues}>
          Pending issues
        </Checkbox>
        <Checkbox checked={props.showResolvedIssues} onChange={props.onToggleShowResolvedIssues}>
          Resolved issues
        </Checkbox>
      </SimpleToolbar>
    </PanelRow>
    <GridPanelRow>
      <JobHistoryEventsGrid
        events={props.jobHistory.toEvents()}
        showJobEvents={props.showJobEvents}
        showRunningTasks={props.showRunningTasks}
        showCompletedTasks={props.showCompletedTasks}
        showPendingIssues={props.showPendingIssues}
        showResolvedIssues={props.showResolvedIssues}
      />
    </GridPanelRow>
  </Panel>
);
