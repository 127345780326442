import * as React from "react";
import { styled } from "../../../../app/theme";
import { Block } from "../../../containers/block";
import { Panel } from "../../../containers/panel";
import { Column, ColumnResponsiveBehavior, MultiColumnRow } from "../../../containers/rows/multiColumnRow";
import { VaultMeIcon } from "../../../glyphs/vaultMeIcon";
import { LargeSpinner, SpinnerColor } from "../../../glyphs/spinner";
import {
  DateTimeIndicator,
  Indicators,
  ItemCountAndSizeIndicator,
  ItemCountIndicator,
  SimpleIndicator,
  TimeIndicator
} from "../../../widgets/indicator";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { CheckMark } from "../../../glyphs/checkMark";
import { EstimatedTimeIndicator } from "./estimatedTimeIndicator";
import { MigrationStatusPageState } from "../migrationStatusPageState";
import { WarningSign } from "../../../glyphs/warningSign";
import { ActionItems } from "../../../models/actionItem";
import { List } from "immutable";
import { Migration } from "../../../../types/models/migration";
import { InfoButton } from "../../../widgets/infoButton";
import { HelpArticles } from "../../../../app/helpArticles";
import { PanelRowDefs } from "../../../containers/rows/panelRow";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { MigrationStatus } from "../../../../types/models/migrationStatus";
import { Iteration } from "../../../../types/models/iteration";
import { useDrawer } from "../../../layouts/drawer";
import { useCloudServices } from "../../../../app/configuration";

const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnTitle = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.gray};
  font-size: ${(props) => props.theme.font.small}rem;
  margin-bottom: .7rem;
`;

// Specifying width is required in Safari, the div takes 100% of parent, and the checkmask in the first column appears
// to be shifted
const LargeIndicatorContainer = styled.div<{ square: boolean }>`
  width: ${(props) => props.square ? "6rem" : "auto"};
  height: 6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${(props) => props.theme.responsive.respondToSmall()} {
    width: ${(props) => props.square ? "5rem" : "auto"};
    height: 5rem;
  }
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: ${(props) => props.square ? "5rem" : "auto"};
    height: 4rem;
  }
`;

const MigrationStatusDiv = styled.div<{ error?: boolean }>`
  font-size: 1.4rem;
  margin-top: 1rem;
  color: ${(props) => props.error ? props.theme.colors.red : "inherit"};
`;

const IssueIcon = styled(WarningSign)`
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
`;

const IssueLayout = styled.div`
  display: flex;
  margin-top: .3rem;
`;

const Issues = styled.div`
  margin-top: .2rem;
`;

const ScrollDown = styled.div`
  margin-top: .5rem;
  font-size: ${(props) => props.theme.font.small}rem;
  color: ${(props) => props.theme.colors.gray};
`;

interface IssueProps {
  count: number;
  issueType: "warning" | "error";
}

const IssueView: React.FunctionComponent<IssueProps> = (props) => (
  <IssueLayout>
    <IssueIcon color={props.issueType === "error" ? "red" : "yellow"}/>
    {props.count} {props.issueType === "warning" ? "warning" : "issue"}{props.count !== 1 && "s"}
  </IssueLayout>
);

const ExperiencingSlowness: React.FunctionComponent = () => (
  <IssueLayout>
    <IssueIcon color={"yellow"}/>Experiencing slowness
  </IssueLayout>
);

interface StatusColumnProps {
  state: MigrationStatusPageState.Any;

  source: MigrationStatusPageDefs.SourceConnectionDetails;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  migration: Migration;

  sourceTotals: MigrationStatusPageDefs.SourceAreaTotals;
  destinationTotals: MigrationStatusPageDefs.DestinationAreaTotals;

  displaySpinUp: boolean;
}

const StatusColumn: React.FunctionComponent<StatusColumnProps> = (props) => {
  if (props.state.type === MigrationStatusPageState.Type.Complete) {
    return (
      <StyledColumn responsiveBehavior={ColumnResponsiveBehavior.Expand}>
        <LargeIndicatorContainer square={true}>
          <LargeSpinner
            progress={100}
            icon={<CheckMark strokeWidthMultiplier={1.5}/>}
            iconOffsetX={2.5}
            backgroundColor="gray"
          />
        </LargeIndicatorContainer>
        <MigrationStatusDiv>Success!</MigrationStatusDiv>
      </StyledColumn>
    );
  } else if (props.state.type === MigrationStatusPageState.Type.Aborted) {
    return (
      <StyledColumn responsiveBehavior={ColumnResponsiveBehavior.Expand}>
        <LargeIndicatorContainer square={true}>
          <LargeSpinner
            progress={0}
            icon={<WarningSign color={"red"}/>}
            iconOffsetY={-3}
            backgroundColor="gray"
          />
        </LargeIndicatorContainer>
        <MigrationStatusDiv>Aborted</MigrationStatusDiv>
      </StyledColumn>
    );
  } else {
    const onPause = (
      props.migration.status === MigrationStatus.WaitingForTemporaryCondition ||
      props.migration.status === MigrationStatus.WaitingForUserAction ||
      props.migration.status === MigrationStatus.WaitingForManualReview ||
      props.migration.status === MigrationStatus.StoppedForUnknownReason
    );
    const sourceActionItems = ActionItems.Stats.build(props.source.actionItems);
    const destinationActionItems = ActionItems.Stats.build(props.destination.actionItems);
    const errorCount = props.sourceTotals.errorCount + props.destinationTotals.errorCount +
      sourceActionItems.errors + destinationActionItems.errors;
    const warningCount = props.sourceTotals.warningCount + props.destinationTotals.warningCount +
      sourceActionItems.warnings + destinationActionItems.warnings;

    const slownesses =
      props.source.areas.map((area) => area.slowness)
        .concat(props.destination.areas.map((area) => area.slowness))
        .concat(props.source.slowness ? [props.source.slowness] : [])
        .concat(props.destination.slowness ? [props.source.slowness] : [])
        .filter((s) => !!s);
    const slowness = slownesses.length === 0
      ? undefined
      : slownesses.length === 1 ? slownesses[0] : {};

    function renderIcon() {
      if (errorCount !== 0) {
        return <WarningSign color={"red"}/>;
      } else if (
        warningCount !== 0 ||
        slowness ||
        onPause ||
        props.migration.timing.delayLevel !== Migration.Timing.DelayLevel.OnTime
      ) {
        return <WarningSign color={"yellow"}/>;
      } else {
        return <VaultMeIcon/>;
      }
    }

    function spinnerColor(): SpinnerColor {
      if (errorCount !== 0) {
        return "red";
      } else if (
        warningCount !== 0 ||
        slowness ||
        onPause ||
        props.migration.timing.delayLevel !== Migration.Timing.DelayLevel.OnTime
      ) {
        return "yellow";
      } else {
        return "blue";
      }
    }

    function renderStatus() {
      if (errorCount !== 0) {
        return <MigrationStatusDiv error={true}>Action required!</MigrationStatusDiv>;
      } else if (onPause) {
        return <MigrationStatusDiv>Paused</MigrationStatusDiv>;
      } else if (props.migration.iteration !== 0) {
        return <MigrationStatusDiv>Syncing up</MigrationStatusDiv>;
      } else if (props.state.type === MigrationStatusPageState.Type.Scheduled) {
        return <MigrationStatusDiv>Ready</MigrationStatusDiv>;
      } else if (props.state.type === MigrationStatusPageState.Type.Starting) {
        return <MigrationStatusDiv>Starting</MigrationStatusDiv>;
      } else if (props.sourceTotals.cumulative.remaining === 0) {
        return <MigrationStatusDiv>Cleaning up</MigrationStatusDiv>;
      } else if (props.migration.timing.delayLevel !== Migration.Timing.DelayLevel.OnTime) {
        return <MigrationStatusDiv>Delayed</MigrationStatusDiv>;
      } else if (props.migration.status === MigrationStatus.Pausing) {
        return <MigrationStatusDiv>Pausing</MigrationStatusDiv>;
      } else if (props.migration.status === MigrationStatus.Paused) {
        return <MigrationStatusDiv>Paused</MigrationStatusDiv>;
      } else {
        return <MigrationStatusDiv>In progress</MigrationStatusDiv>;
      }
    }

    function renderIssues() {
      if (errorCount !== 0 || warningCount !== 0 || slowness || onPause) {
        return (
          <>
            <Issues>
              {errorCount !== 0 && <IssueView count={errorCount} issueType="error"/>}
              {warningCount !== 0 && <IssueView count={warningCount} issueType="warning"/>}
              {slowness && <ExperiencingSlowness/>}
            </Issues>
            <ScrollDown>(Scroll down for details)</ScrollDown>
          </>
        );
      }
    }

    function animationSpeed() {
      if (
        props.state.type === MigrationStatusPageState.Type.Scheduled ||
        props.migration.status === MigrationStatus.Paused
      ) {
        return 0.05;
      } else if (
        slowness ||
        onPause ||
        props.migration.status === MigrationStatus.Pausing
      ) {
        return 0.25;
      } else if (errorCount !== 0) {
        return 0.5;
      } else {
        return 1.0;
      }
    }

    return (
      <StyledColumn responsiveBehavior={ColumnResponsiveBehavior.Expand}>
        <ColumnTitle>Migration Status</ColumnTitle>
        <LargeIndicatorContainer square={true}>
          <LargeSpinner
            progress={-1}
            backgroundColor="gray"
            icon={renderIcon()}
            iconOffsetY={-3}
            color={spinnerColor()}
            spinUpSeconds={props.displaySpinUp ? 5 : undefined}
            animationSpeed={animationSpeed() * 0.5}
          />
        </LargeIndicatorContainer>
        {renderStatus()}
        {renderIssues()}
      </StyledColumn>
    );
  }
};

const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const SummaryContainer = styled.div`
  width: 100%;
`;

interface ProgressColumnProps {
  state: MigrationStatusPageState.Any;
  totals: MigrationStatusPageDefs.SourceAreaTotals;
  migration: Migration;
}

const SkippedItemsInfoButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  // There's additional padding of 0.25rem set by Column %-|
  margin-right: ${PanelRowDefs.PanelHorizontalPadding - 0.25}rem;
  justify-content: flex-end;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    // For some reason everything's different on mobile, hmm....
    margin-right: ${PanelRowDefs.XSmallPanelHorizontalPadding + 0.25}rem;
  }
`;

const ProgressColumn: React.FunctionComponent<ProgressColumnProps> = (props) => {
  if (
    props.state.type === MigrationStatusPageState.Type.Complete ||
    props.state.type === MigrationStatusPageState.Type.Aborted
  ) {
    return (
      <StyledColumn>
        <SummaryContainer>
          <Indicators direction={"column"}>
            <ItemCountAndSizeIndicator
              title="Indexed During Migration"
              count={props.totals.cumulative.totalItemCount}
              unit="item"
              size={props.totals.cumulative.totalSize}
            />
            <ItemCountAndSizeIndicator
              title="Migrated Successfully"
              count={props.totals.cumulative.migratedItemCount}
              unit="item"
              size={props.totals.cumulative.migratedSize}
            />
            <ItemCountIndicator
              title="Skipped"
              count={props.totals.cumulative.skippedItemCount}
              unit="item"
            >
              <SkippedItemsInfoButtonContainer>
                <InfoButton helpArticle={PreparedHelpArticle.fromExternal(HelpArticles.skippedItems)}/>
              </SkippedItemsInfoButtonContainer>
            </ItemCountIndicator>
          </Indicators>
        </SummaryContainer>
      </StyledColumn>
    );
  } else {
    const stats = props.migration.iteration === 0 ? props.totals.cumulative : props.totals.iteration;
    return (
      <StyledColumn>
        <ColumnTitle>Items {props.migration.iteration === 0 ? "" : "Additionally "}Migrated</ColumnTitle>
        <LargeIndicatorContainer square={true}>
          <LargeSpinner progress={stats.progress} displayPercentage={true} backgroundColor="gray"/>
        </LargeIndicatorContainer>
        <DetailsContainer>
          <Indicators>
            {
              stats.totalItemCount !== undefined && stats.remaining !== undefined
                ? (
                  <>
                    <ItemCountIndicator title="Total" count={stats.totalItemCount} unit="item"/>
                    <ItemCountIndicator title="Remaining" count={stats.remaining} unit="item"/>
                  </>
                )
                : (
                  <SimpleIndicator
                    title="Total / Remaining"
                    value={props.migration.iteration === 0 ? "Listing items..." : "Looking for updates..."}
                  />
                )
            }
          </Indicators>
        </DetailsContainer>
      </StyledColumn>
    );
  }
};

const UnknownRemainingTime = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.darkGray};
  //height: 3.2rem;
  padding-top: 1.9rem;
  line-height: 1;
  box-sizing: border-box;

  ${(props) => props.theme.responsive.respondToSmall()} {
    font-size: 1.8rem;
    //height: 3rem;
    padding-top: 1.6rem;
  }
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 1.5rem;
    //height: 2.5rem;
    padding-top: 1rem;
  }
`;

const DelayDetailsContainer = styled.div<{ padding: "large" | "small" }>`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.padding === "large" ? 1 : .3}rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding-top: ${(props) => props.padding === "large" ? .7 : 0}rem;
    font-size: 0.9rem;
  }
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    font-size: 0.8rem;
  }
`;

const WarningSignContainer = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: .3rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    width: .9rem;
    height: .9rem;
  }
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: .8rem;
    height: .8rem;
    margin-right: .2rem;
  }
`;

const LearnMoreContainer = styled.div<{ padding: "large" | "small" }>`
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: ${(props) => props.padding === "large" ? .5 : 0}rem;
  font-size: ${(props) => props.theme.font.small}rem;
`;

interface TimingColumnProps {
  state: MigrationStatusPageState.Any;
  migration: Migration;
  iteration: Iteration | undefined;
  timeRemaining: List<MigrationStatusPageDefs.RemainingMigrationTime> | undefined;
}

const TimingColumn: React.FunctionComponent<TimingColumnProps> = (props) => {
  const drawer = useDrawer();
  const cloudServices = useCloudServices();

  // Ensure that the component will be re-drawn every minute and the timer will be ticking (during sync-up!)
  const [tick, setTick] = React.useState(0);

  React.useEffect(
    () => {
      const interval = setInterval(() => setTick((value) => value + 1), 60 * 60);
      return () => clearInterval(interval);
    },
    []
  );

  function remainingTime(timeRemaining: List<MigrationStatusPageDefs.RemainingMigrationTime>): number {
    return timeRemaining.map((time) => time.value?.remainingTime ?? 0).max() || 0;
  }

  if (
    props.state.type === MigrationStatusPageState.Type.Complete ||
    props.state.type === MigrationStatusPageState.Type.Aborted
  ) {
    return (
      <StyledColumn>
        <SummaryContainer>
          <Indicators direction={"column"}>
            <DateTimeIndicator
              title="Migration Started"
              dateTime={props.migration.startedAt || props.migration.createdAt}
            />
            {props.migration.completedAt && (
              <DateTimeIndicator
                title={
                  "Migration " + (props.state.type === MigrationStatusPageState.Type.Aborted ? "Aborted" : "Completed")
                }
                dateTime={props.migration.completedAt}
              />
            )}
            {props.migration.iterationCompletedAt
              ? (
                <DateTimeIndicator
                  title="Last Synced Up"
                  dateTime={props.migration.iterationCompletedAt}
                />
              )
              : (
                <TimeIndicator
                  title="Time Elapsed"
                  time={props.migration.totalTimeInWork}
                />
              )}
          </Indicators>
        </SummaryContainer>
      </StyledColumn>
    );
  } else {
    const elapsed = props.migration.iteration === 0
      ? props.migration.totalTimeInWork
      : props.migration.iterationStartedAt &&
      (new Date().getTime() - props.migration.iterationStartedAt.getTime()) / 1000;

    const remaining = props.migration.iteration === 0
      ? props.timeRemaining ? remainingTime(props.timeRemaining) : 0
      : Math.max(60, 15 * 60 - (elapsed || 0));

    // const experiencingDelays = PreparedHelpArticle.fromExternal(
    //   HelpArticles.experiencingDelaysEx(
    //     cloudServices,
    //     props.migration.sourceCloudServiceId,
    //     props.migration.destinationCloudServiceId
    //   )
    // );

    return (
      <StyledColumn>
        <ColumnTitle>Approx. Time Remaining</ColumnTitle>
        {
          props.migration.timing.delayLevel === Migration.Timing.DelayLevel.Major
            ? (
              <>
                <LargeIndicatorContainer square={false}>
                  <UnknownRemainingTime>Unknown</UnknownRemainingTime>
                  {/*<DelayDetailsContainer padding={"small"}>*/}
                  {/*  <WarningSignContainer><WarningSign color={"yellow"}/></WarningSignContainer>*/}
                  {/*  Delayed*/}
                  {/*</DelayDetailsContainer>*/}
                  {/*<LearnMoreContainer padding={"small"}>*/}
                  {/*  <LinkButton onClick={() => drawer.open(experiencingDelays)}>Learn more</LinkButton>*/}
                  {/*</LearnMoreContainer>*/}
                </LargeIndicatorContainer>
              </>
            )
            : (
              <>
                <LargeIndicatorContainer square={true}>
                  <EstimatedTimeIndicator time={remaining}/>
                </LargeIndicatorContainer>
              </>
            )
        }
        <DetailsContainer>
          <Indicators direction={"column"}>
            <TimeIndicator title="Time Elapsed" time={elapsed}/>
          </Indicators>
        </DetailsContainer>
      </StyledColumn>
    );
  }
};

interface OverviewPanelProps {
  state: MigrationStatusPageState.Any;

  source: MigrationStatusPageDefs.SourceConnectionDetails;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  migration: Migration;
  iteration: Iteration | undefined;
  timeRemaining: List<MigrationStatusPageDefs.RemainingMigrationTime> | undefined;

  sourceTotals: MigrationStatusPageDefs.SourceAreaTotals;
  destinationTotals: MigrationStatusPageDefs.DestinationAreaTotals;

  displaySpinUp: boolean;
}

export const OverviewPanel: React.FunctionComponent<OverviewPanelProps> = (props) => (
  <Block>
    <Panel>
      <MultiColumnRow>
        <StatusColumn
          state={props.state}
          source={props.source}
          destination={props.destination}
          migration={props.migration}
          sourceTotals={props.sourceTotals}
          destinationTotals={props.destinationTotals}
          displaySpinUp={props.displaySpinUp}
        />
        <ProgressColumn
          state={props.state}
          totals={props.sourceTotals}
          migration={props.migration}
        />
        <TimingColumn
          state={props.state}
          migration={props.migration}
          iteration={props.iteration}
          timeRemaining={props.timeRemaining}
        />
      </MultiColumnRow>
    </Panel>
  </Block>
);
