import { AppTheme } from "../../app/theme";

export enum WidgetStatus {
  Normal = "Normal",
  Error = "Error",
  Uncertain = "Uncertain",
  Warning = "Warning",
  HeadsUp = "HeadsUp",
  Success = "Success",
  Info = "Info"
}

export namespace WidgetStatus {
  export function valueColor(
    theme: AppTheme,
    status?: WidgetStatus | undefined,
    defaultColor: string = "inherit"
  ): string {
    switch (status) {
      // case WidgetStatus.Error:
      //   return theme.colors.red;
      case WidgetStatus.Uncertain:
        return theme.colors.lightGray;
      case WidgetStatus.Success:
        return theme.colors.green;
      case WidgetStatus.HeadsUp:
        return theme.colors.orange;
      default:
        return defaultColor;
    }
  }

  export function valueBackground(
    theme: AppTheme,
    status: WidgetStatus | undefined,
    defaultColor: string = "inherit"
  ): string {
    switch (status) {
      case WidgetStatus.Error:
        return theme.colors.lightRed;
      case WidgetStatus.Warning:
        return theme.colors.lightYellow;
      case WidgetStatus.Success:
        return theme.colors.lightGreen;
      case WidgetStatus.Info:
        return theme.colors.faintPrimary;
      case WidgetStatus.HeadsUp:
        return theme.colors.lightOrange;
      default:
        return defaultColor;
    }
  }

  export function brightValueBackground(
    theme: AppTheme,
    status: WidgetStatus | undefined,
    defaultColor: string = "inherit"
  ): string {
    if (status === WidgetStatus.Info) {
      return theme.colors.lightPrimary;
    } else {
      return valueBackground(theme, status, defaultColor);
    }
  }

  export function valueStroke(
    theme: AppTheme,
    status: WidgetStatus | undefined,
    defaultColor: string = "inherit"
  ): string {
    switch (status) {
      case WidgetStatus.Error:
        return theme.colors.red;
      case WidgetStatus.Warning:
        return theme.colors.yellow;
      case WidgetStatus.Success:
        return theme.colors.green;
      case WidgetStatus.Info:
        return theme.colors.primary;
      case WidgetStatus.HeadsUp:
        return theme.colors.orange;
      default:
        return defaultColor;
    }
  }
}
