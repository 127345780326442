import * as React from "react";
import { usePrevious } from "../../utils/usePrevious";
import { WatcherDispatchContext } from "./useWatcher";
import { WatcherState } from "./watcherState";
import { useApolloClient } from "@apollo/react-hooks";
import { useInterval } from "../../utils/useInterval";
import { ApolloClient } from "apollo-client";
import { Map } from "immutable";
import { WatchedItems } from "./watchedItems";
import { WatchedFacts } from "./plugins/watchedFactsPlugin";
import { AnyWatcherAction } from "./anyWatcherAction";
import { WatchedMigrations } from "./plugins/watchedMigrationsPlugin";
import { WatchedBatches } from "./plugins/watchedBatchesPlugin";
import { WatchedMigrationIssueLists } from "./plugins/watchedMigrationIssuesPlugin";
import { WatchedMigrationNoteLists } from "./plugins/watchedMigrationNoteListsPlugin";

const ReviewInterval = 60000;

interface WatcherProps {
  children: React.ReactElement;
}

type State = WatcherState<ApolloClient<object>>;

const InitialState = new WatcherState<ApolloClient<object>>(
  Map([
    ["batches", new WatchedItems(WatchedBatches.Plugin)],
    ["facts", new WatchedItems(WatchedFacts.Plugin)],
    ["migrations", new WatchedItems(WatchedMigrations.Plugin)],
    ["migrationIssues", new WatchedItems(WatchedMigrationIssueLists.Plugin)],
    ["migrationNoteList", new WatchedItems(WatchedMigrationNoteLists.Plugin)],
  ])
);

export const Watcher = (props: WatcherProps) => {
  const apolloClient = useApolloClient();

  const reduce = React.useCallback(
    (s: State, action: AnyWatcherAction): State => s.reduce(apolloClient, action),
    [apolloClient]
  );

  const [state, dispatch] = React.useReducer(reduce, InitialState);
  const previousState = usePrevious(state);

  React.useEffect(
    () => {
      if (previousState || state !== InitialState) {
        const summary = state.updateSubscriptions(apolloClient, previousState || InitialState, dispatch);
        if (summary) {
          console.log("[Watcher] Subscribed for " + summary.subscribed + ", unsubscribed from " + summary.unsubscribed);
        }
      }
    },
    [state]
  );

  useInterval(
    () => {
      const reviewResult = state.review(apolloClient, dispatch);

      if (reviewResult.refreshed) {
        const refreshed = reviewResult.refreshed;
        console.log(`[Watcher] Refreshing ${refreshed.summary}...`);
        refreshed.promise
          .then(() => console.log(`[Watcher] Refreshed ${refreshed.summary}`))
          .catch((error) => console.error(`[Watcher] Failed to refresh ${refreshed.summary}`, error));
      }

      if (reviewResult.renewed) {
        console.log("[Watcher] Renewed " + reviewResult.renewed);
      }

      if (reviewResult.removed) {
        console.log("[Watcher] Selected for un-subscribing " + reviewResult.removed + " (no more updates expected)");
      }
    },
    ReviewInterval
  );

  return (
    <WatcherDispatchContext.Provider value={dispatch}>
      {props.children}
    </WatcherDispatchContext.Provider>
  );
};
