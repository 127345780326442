import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { AccessListUpdateStatus } from "../enums/accessListUpdateStatus";

export interface AccessListUpdate {
  readonly id: number;
  readonly accessListId: number;

  readonly status: AccessListUpdateStatus;

  readonly addedEntries: number;
  readonly removedEntries: number;
  readonly skippedEntries: number;
  readonly errors: number;
  readonly errorMessage: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly completedAt: Date | undefined;
}

export namespace AccessListUpdate {
  export function parse(data: GraphQL.AccessListUpdateFragment): AccessListUpdate {
    return {
      id: data.id,
      accessListId: data.accessListId,

      status: data.status,

      addedEntries: data.addedEntries,
      removedEntries: data.removedEntries,
      skippedEntries: data.skippedEntries,
      errors: data.errors,
      errorMessage: nullToUndefined(data.errorMessage),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      completedAt: mapOptional(data.completedAt, (value) => new Date(value))
    };
  }
}
