import { isDefined } from "../utils/misc";
import { BlueprintContext } from "./blueprintContext";
import { BlueprintException } from "./blueprintException";
import { AnyComponent, ComponentOf } from "./component";
import { AnyRelationship } from "./relationship";
import { List, Map, Set } from "immutable";

export interface RelationshipLocator {
  originComponentId: string;
  hubTitle: string;
  targetComponentId: string;
}

export interface RelationshipWithOriginComponent {
  component: AnyComponent;
  relationship: AnyRelationship;
}

export interface BlueprintFilteringOptions {
  segments: Map<string, number>;
  tags: Map<string, number>;
  componentTypes: Map<string, number>;
  hubTitles: Map<string, number>;
}

namespace BlueprintFilteringOptions {
  export const Empty: BlueprintFilteringOptions = {
    segments: Map(),
    tags: Map(),
    componentTypes: Map(),
    hubTitles: Map()
  };
}

export class BlueprintComponents {
  protected components: Map<string, AnyComponent> = Map();

  public add(component: AnyComponent): void {
    this.components = this.components.set(component.id, component);
  }

  public get<Output>(id: string): ComponentOf<Output> | undefined {
    return this.components.get(id) as unknown as ComponentOf<Output>;
  }

  public getOrFail<Output>(id: string): ComponentOf<Output> {
    const result = this.components.get(id) as unknown as ComponentOf<Output>;
    if (result) {
      return result;
    } else {
      throw new BlueprintException(`Component ${id} not found`);
    }
  }

  public find<T extends AnyComponent>(f: (component: AnyComponent) => T | undefined): T | undefined {
    const component = this.components.find((c) => !!f(c));
    return component && f(component);
  }

  public list<T extends AnyComponent>(f: (component: AnyComponent) => T | undefined): List<T> {
    return this.components.valueSeq().map((c) => f(c)).filter<T>(isDefined).toList();
  }

  public trace<T extends AnyComponent>(
    startFrom: AnyComponent,
    pickWhen: (component: AnyComponent) => T | undefined,
    stopWhen: (component: AnyComponent) => boolean
  ): Set<T> {
    let result = Set<T>();
    startFrom.hubList().forEach((hub) => {
      hub.relationships.forEach((relationship) => {
        const component = relationship.component();
        if (component) {
          if (pickWhen(component)) {
            result = result.add(component as T);
          }
          if (!stopWhen(component)) {
            result = result.concat(this.trace(component, pickWhen, stopWhen));
          }
        }
      });
    });
    return result;
  }

  public findRelationship(locator: RelationshipLocator): RelationshipWithOriginComponent | undefined {
    const component = this.components.get(locator.originComponentId);
    if (component) {
      const hub = component.hubList().find((h) => h.title === locator.hubTitle);
      if (hub) {
        const relationship = hub.relationships.find((r) => r.componentId === locator.targetComponentId);
        if (relationship) {
          return { component, relationship };
        }
      }
    }
    return undefined;
  }

  public allComponents(): List<AnyComponent> {
    return this.components.valueSeq().toList();
  }

  public filteringOptions(): BlueprintFilteringOptions {
    return this.components.reduce<BlueprintFilteringOptions>(
      (reduction, component): BlueprintFilteringOptions => ({
        segments: reduction.segments.set(component.segment, reduction.segments.get(component.segment, 0) + 1),
        tags: reduction.tags.merge(component.tags.map((tag) => [tag, reduction.tags.get(tag, 0) + 1])),
        componentTypes: reduction.componentTypes.set(
          component.type,
          reduction.componentTypes.get(component.type, 0) + 1
        ),
        hubTitles: reduction.hubTitles.merge(
          component.hubList()
            .filter((hub) => !hub.relationships.isEmpty())
            .map((hub) => [hub.title, reduction.hubTitles.get(hub.title, 0) + 1])
        )
      }),
      BlueprintFilteringOptions.Empty
    );
  }

  public messages(startFrom: AnyComponent, context: BlueprintContext): List<string> {
    return startFrom
      .messages(context)
      .concat(
        startFrom.hubList().flatMap((hub) =>
          hub
            .messages(context)
            .concat(
              hub.relationships.flatMap((relationship) => {
                const component = relationship.component();
                return component ? relationship.messages(context).concat(this.messages(component, context)) : List();
              })
            )
        )
      )
      .toSet()
      .toList();
  }
}
