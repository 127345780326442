import { BlueprintContext } from "../blueprintContext";
import { ComponentVisualization } from "../componentVisualization";
import { MultipleRelationshipsHub } from "../hubs/multipleRelationshipsHub";
import { Component, ComponentHubs } from "../component";
import { ItemTypes } from "../types/itemTypes";
import { State } from "../state";
import { List, Set } from "immutable";
import { CollectableItemBreakdown } from "../../types/collectables/collectableItemBreakdown";
import { CollectableInteger } from "../../types/collectables/collectableInteger";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { Option, Some } from "../../utils/monads/option";
import { ComponentBinding } from "./componentBinding";
import { nullToUndefined } from "../../utils/misc";
import { SinkComp } from "./sinkComp";
import { Tracer } from "../tracer";

export class AreaComp extends Component<AreaComp.Props, AreaComp.Hubs, AreaComp.Output> {
  public stateWhenUnblocked(context: BlueprintContext): State<AreaComp.Output> {
    const preconditionsState = this.hubs.preconditions.state(context);
    const totalBytesState = this.hubs.totalBytes.state(context);
    const totalItemsState = this.hubs.totalItems.state(context);
    const maxPathLengthState = this.hubs.maxPathLength.state(context);

    return State.compute<AreaComp.Output>(
      preconditionsState, totalItemsState, totalBytesState, maxPathLengthState
    )(
      () => Some({
        itemTypes: Set(this.props.itemTypes),
        totalBytes: Option.flatten2(totalBytesState.output),
        totalItems: Option.flatten2(totalItemsState.output),
        maxPathLength: Option.flatten2(maxPathLengthState.output),
        enabled: this.isEnabled(context)
      })
    );
  }

  public visualization(context: BlueprintContext, state: State<AreaComp.Output>): ComponentVisualization {
    return {
      title: "Area",
      summary: this.props.title + "\n" + this.props.itemTypes.join(", "),
      icon: Images.Blueprint.NoIcon,
      color: ComponentColorSchema.HollowBrown,
      sizeMultiplier: 1.5,
      disabled: state.isResolved && !state.output.exists((o) => o.enabled)
    };
  }

  public resolvedAppTitle(context: BlueprintContext): string {
    return (
      this.props.appTitle ||
      new Tracer(this, context).domain(true).toJS() ||
      context.cloudServices.getOrFail(this.blueprint.params.sourceCloudServiceId).name
    );
  }

  // This method is expected co be called directly instead of using output.enabled because output is not available in
  // many states (ex. Blocked, Pending, Error). In this states, the caller would have to make tough assumptions about
  // the default value for output.enabled.
  public isEnabled(context: BlueprintContext): boolean {
    return context.inputs.get(this.props.title).contains(AreaComp.Enabled);
  }

  public destinationAppId(): Option<string> {
    return Option
      .mayBe(this.incomingRelationships().find((r) => r.component instanceof SinkComp))
      .map((r) => (r.component as SinkComp).props.internalId);
  }

  public destinationAppTitle(): Option<string> {
    return Option
      .mayBe(this.incomingRelationships().find((r) => r.component instanceof SinkComp))
      .flatMap((r) => Option.mayBe((r.component as SinkComp).props.appTitle));
  }
}

export namespace AreaComp {
  export const Enabled = "true";

  export interface Props {
    internalId: string;
    appTitle: string | undefined;
    mainSubject: string;
    title: string;
    description: string;
    order: number;
    itemTypes: Set<string>;
    itemTypeAliases: List<[string, List<GraphQL.ItemType>]>;
  }

  export namespace Props {
    export function fromGraphQL(props: GraphQL.AreaCompProps): Props {
      return {
        internalId: props.internalId,
        appTitle: nullToUndefined(props.appTitle),
        mainSubject: props.mainSubject,
        title: props.title,
        description: props.description,
        order: props.order,
        itemTypes: Set(props.itemTypes),
        itemTypeAliases: List(props.itemTypeAliases.map((alias) => [alias.alias, List(alias.itemTypes)])),
      };
    }
  }

  export interface Hubs extends ComponentHubs {
    totalBytes: MultipleRelationshipsHub<Option<CollectableInteger>>;
    totalItems: MultipleRelationshipsHub<Option<CollectableItemBreakdown>>;
    maxPathLength: MultipleRelationshipsHub<Option<CollectableInteger>>;
  }

  export namespace Hubs {
    export function fromGraphQL(binding: ComponentBinding, hubs: GraphQL.AreaCompHubsFragment): Hubs {
      return {
        ...ComponentHubs.fromGraphQL(binding, hubs),
        totalBytes: binding.multipleRelationshipsHub("totalBytes", hubs.totalBytes),
        totalItems: binding.multipleRelationshipsHub("totalItems", hubs.totalItems),
        maxPathLength: binding.multipleRelationshipsHub("maxPathLength", hubs.maxPathLength),
      };
    }
  }

  export interface Output {
    itemTypes: ItemTypes;
    totalItems: Option<CollectableItemBreakdown>;
    totalBytes: Option<CollectableInteger>;
    maxPathLength: Option<CollectableInteger>;
    enabled: boolean;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.AreaCompOutputFragment): Output {
      return {
        itemTypes: Set(output.itemTypes),
        totalBytes: Option.mayBe(output.totalBytesCollectable).map(CollectableInteger.fromGraphQL),
        totalItems: Option.mayBe(output.totalItemsCollectable).map(CollectableItemBreakdown.fromGraphQL),
        maxPathLength: Option.mayBe(output.maxPathLength).map(CollectableInteger.fromGraphQL),
        enabled: output.enabled
      };
    }
  }
}
