import * as React from "react";
import { Slowness } from "../../models/slowness";
import { AreaStatus } from "../../models/areaStatus";
import { ConnectionDetails } from "../../models/connectionDetails";
import { ItemsByType } from "../../models/itemsByType";
import { ActionItems } from "../../models/actionItem";
import { MigrationStatusPageState } from "./migrationStatusPageState";
import { List } from "immutable";
import { Option } from "../../../utils/monads/option";
import { Migration } from "../../../types/models/migration";
import { MigrationIssue } from "../../../types/models/migrationIssue";
import { OperationStatus } from "../../../types/operationStatus";
import { IncrementalSignInDefs } from "../../blocks/incrementalSignIn";
import { ConnectResult } from "../../../types/models/connectResult";
import { Iteration } from "../../../types/models/iteration";
import { IterationLaunchControllerType, IterationsHistoryControllerType } from "./components/iterationToolsRow";
import { DisconnectControllerType } from "./components/migrationToolsPanel";
import { UpdateCustomerDetailsToolControllerType } from "../../blocks/updateCustomerDetailsForm";
import { PauseMigrationControllerType } from "./components/pauseMigrationToolRow";
import { ResumeMigrationControllerType } from "./components/resumeMigrationToolRow";

export namespace MigrationStatusPageDefs {
  export const SourceConnectionPanelId = "source-connection";

  export enum ContextType {
    Cumulative = "Cumulative",
    Iteration = "Iteration"
  }

  export class SourceAreaTotals {
    public readonly cumulative: SourceAreaTotals.Stats;
    public readonly iteration: SourceAreaTotals.Stats;
    public readonly warningCount: number; // Number of original warnings (without synthetic slowness and storage issues)
    public readonly errorCount: number;   // Number of original errors (without synthetic storage issues)

    constructor(props: SourceAreaTotals.Props) {
      this.cumulative = props.cumulative;
      this.iteration = props.iteration;

      this.warningCount = props.warningCount;
      this.errorCount = props.errorCount;
    }

    public stats(context: ContextType): SourceAreaTotals.Stats {
      return context === ContextType.Cumulative ? this.cumulative : this.iteration;
    }
  }

  export namespace SourceAreaTotals {
    export class Stats {
      public readonly totalItemCount?: number;
      public readonly totalSize?: number;
      public readonly migratedItemCount: number;
      public readonly migratedSize: number;
      public readonly skippedItemCount: number;

      public readonly processed: number;
      public readonly remaining: number | undefined;
      public readonly progress: number;
      public readonly migratedSuccessfully: number;

      constructor(props: Stats.StatsProps) {
        this.totalItemCount = props.totalItemCount;
        this.totalSize = props.totalSize;
        this.migratedItemCount = props.migratedItemCount;
        this.migratedSize = props.migratedSize;
        this.skippedItemCount = props.skippedItemCount;

        this.processed = props.migratedItemCount + props.skippedItemCount;

        this.remaining = props.totalItemCount !== undefined
          ? Math.max(0, props.totalItemCount - this.processed)
          : undefined;

        this.progress = props.totalItemCount
          ? Math.min(100, this.processed / props.totalItemCount * 100)
          : 0;

        this.migratedSuccessfully = props.totalItemCount
          ? Math.min(100, props.migratedItemCount / props.totalItemCount)
          : 100;
      }
    }

    export namespace Stats {
      export interface StatsProps {
        totalItemCount?: number;
        totalSize?: number;
        migratedItemCount: number;
        migratedSize: number;
        skippedItemCount: number;
      }

      export const EmptyStats: Stats = new Stats({
        migratedItemCount: 0,
        skippedItemCount: 0,
        migratedSize: 0
      });

      function mayBeAdd(a: number | undefined, b: number | undefined): number | undefined {
        return a !== undefined && b !== undefined ? a + b : undefined;
      }

      export function reduceStats(a: Stats | undefined, b: Stats | undefined): Stats | undefined {
        if (a && b) {
          return new Stats({
            totalItemCount: mayBeAdd(a.totalItemCount, b.totalItemCount),
            totalSize: mayBeAdd(a.totalSize, b.totalSize),
            migratedItemCount: a.migratedItemCount + b.migratedItemCount,
            migratedSize: a.migratedSize + b.migratedSize,
            skippedItemCount: a.skippedItemCount + b.skippedItemCount
          });
        } else if (a) {
          return a;
        } else {
          return b;
        }
      }
    }

    export interface Props {
      cumulative: Stats;
      iteration: Stats;
      warningCount: number; // Number of original warnings (without synthetic slowness and storage issues)
      errorCount: number;   // Number of original errors (without synthetic storage issues)
    }

    export const Empty: SourceAreaTotals = new SourceAreaTotals({
      cumulative: Stats.EmptyStats,
      iteration: Stats.EmptyStats,
      warningCount: 0,
      errorCount: 0
    });

    export function reduce(
      a: SourceAreaTotals | undefined,
      b: SourceAreaTotals | undefined
    ): SourceAreaTotals | undefined {
      if (a && b) {
        return new SourceAreaTotals({
          cumulative: Stats.reduceStats(a?.cumulative, b?.cumulative) || Stats.EmptyStats,
          iteration: Stats.reduceStats(a?.iteration, b?.iteration) || Stats.EmptyStats,
          warningCount: a.warningCount + b.warningCount,
          errorCount: a.errorCount + b.errorCount
        });
      } else if (a) {
        return a;
      } else {
        return b;
      }
    }
  }

  export interface SourceArea extends AreaStatus {
    excluded?: boolean;
    slowness?: Slowness;
    cumulative: SourceArea.Stats;
    iteration: SourceArea.Stats;
  }

  export namespace SourceArea {
    export interface Stats {
      totalItems?: ItemsByType;
      migratedItems: ItemsByType;
      skippedItems: ItemsByType;
      totalBytes?: number;
      migratedBytes: number;
    }

    export namespace Stats {
      export function toStatsTotals(area: Stats): SourceAreaTotals.Stats {
        return new SourceAreaTotals.Stats({
          totalItemCount: area.totalItems && area.totalItems.totalCount(),
          totalSize: area.totalBytes,
          migratedItemCount: area.migratedItems.totalCount(),
          migratedSize: area.migratedBytes,
          skippedItemCount: area.skippedItems.totalCount(),
        });
      }
    }

    export function toTotals(area: SourceArea): SourceAreaTotals | undefined {
      if (!area.excluded) {
        const actionItemStats = ActionItems.Stats.build(area.actionItems);
        return new SourceAreaTotals({
          cumulative: Stats.toStatsTotals(area.cumulative),
          iteration: Stats.toStatsTotals(area.iteration),
          warningCount: actionItemStats.warnings,
          errorCount: actionItemStats.errors,
        });
      }
    }
  }

  export interface DestinationAreaTotals {
    warningCount: number; // Number of original warnings (without synthetic slowness and storage issues)
    errorCount: number;   // Number of original errors (without synthetic storage issues)
  }

  export namespace DestinationAreaTotals {
    export function reduce(a: DestinationAreaTotals, b: DestinationAreaTotals): DestinationAreaTotals {
      return {
        warningCount: a.warningCount + b.warningCount,
        errorCount: a.errorCount + b.errorCount
      };
    }

    export const Empty: DestinationAreaTotals = {
      warningCount: 0,
      errorCount: 0
    };
  }

  export interface DestinationArea extends AreaStatus {
    slowness?: Slowness;
  }

  export namespace DestinationArea {
    export function toTotals(area: DestinationArea): DestinationAreaTotals {
      const actionItemsStats = ActionItems.Stats.build(area.actionItems);
      return {
        warningCount: actionItemsStats.warnings,
        errorCount: actionItemsStats.errors
      };
    }
  }

  export interface RemainingMigrationTime {
    title: string;
    value?: RemainingMigrationTime.Value;
  }

  export namespace RemainingMigrationTime {
    export interface Value {
      estimatedTime: Option<number>;
      progress: number;
      remainingTime: number;
    }
  }

  export interface ConnectionDetailsEx extends ConnectionDetails {
    slowness: Slowness | undefined;
  }

  export interface SourceConnectionDetails extends ConnectionDetailsEx {
    areas: MigrationStatusPageDefs.SourceArea[];
  }

  export interface DestinationConnectionDetails extends ConnectionDetailsEx {
    areas: MigrationStatusPageDefs.DestinationArea[];
  }

  export interface MigrationIssueViewProps {
    migration: Migration & Migration.HasConnections;
    issue: MigrationIssue;

    source: boolean;
    providerName: string;
    appName: string | undefined;
    mainSubject: string | undefined;
    needAnotherRound: boolean;

    status: OperationStatus<any>;
  }

  export interface SimpleMigrationIssueViewProps extends MigrationIssueViewProps {
    onResolve: () => void;
  }

  export type SimpleMigrationIssueViewComponentType = React.ComponentType<SimpleMigrationIssueViewProps>;

  export interface AuthMigrationIssueViewProps extends MigrationIssueViewProps {
    signInComponent: IncrementalSignInDefs.SignInComponentType;

    onReconnect: (connectResult: ConnectResult) => void;
  }

  export type AuthMigrationIssueViewComponentType = React.ComponentType<AuthMigrationIssueViewProps>;

  export interface ControlledMigrationStatusPageProps {
    loadingStatus: OperationStatus<any>;

    state: MigrationStatusPageState.Any;

    source: MigrationStatusPageDefs.SourceConnectionDetails;
    destination: MigrationStatusPageDefs.DestinationConnectionDetails;
    migration: Migration & Migration.HasOrderSummary;
    timeRemaining: List<RemainingMigrationTime> | undefined;

    displaySpinUp: boolean;
    displayWarning: boolean;

    iterationIndex: number | undefined;
    onIterationSelect: (iterationIndex: number | undefined) => void;
    iterationStatus: OperationStatus<Iteration>;

    migrationReportUrl: string;
    invoiceToolController: UpdateCustomerDetailsToolControllerType;
    iterationLaunchController: IterationLaunchControllerType;
    iterationsHistoryController: IterationsHistoryControllerType;
    disconnectController: DisconnectControllerType | undefined;
    pauseMigrationController: PauseMigrationControllerType;
    resumeMigrationController: ResumeMigrationControllerType;
  }

  export interface MigrationStatusPageControllerProps {
    render: (props: ControlledMigrationStatusPageProps) => React.ReactElement;
  }
}
