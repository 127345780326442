import * as React from "react";
import { OrganizationThemeViewPathFactory } from "../../../views/blocks/lookups/themes/themeLookupField";
import { useRoutes } from "../../../app/routes/useRoutes";

export function useOrganizationThemeViewPathFactory(): OrganizationThemeViewPathFactory {
  const routes = useRoutes().organizationPortalRoutes;

  return (organizationId) =>
    React.useCallback(
      (themeId) => organizationId !== undefined ? routes.themes(organizationId).editPath(themeId) : "",
      [organizationId]
    );
}
