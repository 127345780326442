import * as React from "react";
import { styled } from "../../../app/theme";
import { CloudServiceButtonList } from "./cloudServiceButtonList";
import { StyledComponentsProps } from "../../utils/styledComponentsProps";
import { CloudServices } from "../../../types/models/cloudServices";

const Layout = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 1rem .75rem;
`;

const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.titleColor};
  padding: 0 .75rem;
`;

const Section = styled.div`
  & ~ & {
    margin-top: 1.75rem;
  }
`;

interface CloudServiceListProps extends StyledComponentsProps {
  cloudServices: CloudServices;
  onCloudServiceSelect: (cloudServiceId: string) => void;
}

export const CloudServiceList: React.FunctionComponent<CloudServiceListProps> = (props) => (
  <Layout className={props.className}>
    {
      props.cloudServices.categories.map((category) => (
        <Section key={category.title}>
          <Title>{category.title}</Title>
          <CloudServiceButtonList
            cloudServices={category.cloudServices}
            onCloudServiceSelect={props.onCloudServiceSelect}
          />
        </Section>
      ))
    }
  </Layout>
);
