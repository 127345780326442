import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

export function useGetAccessListEntryCountQuery(
  id: number | undefined
): ManagedQueryHook<GraphQL.GetAccessListEntryCountQueryVariables, number> {
  return useManagedQuery({
    query: GraphQL.useGetAccessListEntryCountQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetAccessListEntryCountQuery) => nullToUndefined(data.accessListEntryCount),
    complete: identity
  });
}
