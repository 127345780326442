import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { School } from "../../types/models/school";

export function useGetSchoolQuery(
  id: string | undefined
): ManagedQueryHook<GraphQL.GetSchoolQueryVariables, School> {
  return useManagedQuery({
    query: GraphQL.useGetSchoolQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetSchoolQuery) => nullToUndefined(data.school),
    complete: (school) => School.parse(school),
    fetchPolicy: "cache-and-network"
  });
}
