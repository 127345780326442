import * as React from "react";
import { Batch } from "../../../types/models/batch";
import { Panel } from "../../containers/panel";
import { DateTimeIndicator, Indicators, ItemCountIndicator, SimpleIndicator } from "../../widgets/indicator";
import { ModalController, useModal } from "../../layouts/modal";
import { BatchLaunchTool } from "./batchLaunchTool";
import { BatchSyncUpTool } from "./batchSyncUpTool";
import { OperationStatus } from "../../../types/operationStatus";
import { Button } from "../../widgets/button";
import { MigrationStatus } from "../../../types/models/migrationStatus";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { MultiColumnRow, Column, ColumnResponsiveBehavior } from "../../containers/rows/multiColumnRow";
import {
  UpdateCustomerDetailsTool,
  UpdateCustomerDetailsToolControllerType
} from "../../blocks/updateCustomerDetailsForm";
import { SimpleToolbar } from "../../widgets/simpleToolbar";

export interface BatchLaunchToolControllerProps {
  render: (
    onSubmit: () => Promise<any>,
    status: OperationStatus<any>
  ) => React.ReactElement;
}

export type BatchLaunchToolControllerType = React.ComponentType<BatchLaunchToolControllerProps>;

export interface BatchSyncUpToolControllerProps {
  render: (
    onSubmit: () => Promise<any>,
    status: OperationStatus<any>
  ) => React.ReactElement;
}

export type BatchSyncUpToolControllerType = React.ComponentType<BatchSyncUpToolControllerProps>;

interface Props {
  batch: Batch;

  batchLaunchToolController: BatchLaunchToolControllerType;
  batchSyncUpToolController: BatchSyncUpToolControllerType;
  invoiceToolController: UpdateCustomerDetailsToolControllerType;

  onDownloadReports: () => void;
}

export const BatchToolsPanel: React.FunctionComponent<Props> = (props) => {
  const launchModal = useModal();
  const syncUpToolModal = useModal();
  const invoiceModal = useModal();

  return (
    <>
      <ModalController
        modal={launchModal}
        title={"Start Migration"}
        render={(close) =>
          React.createElement(props.batchLaunchToolController, {
            render: (onSubmit, status) => (
              <BatchLaunchTool
                onSubmit={() => onSubmit().then(close)}
                status={status}
              />
            )
          })
        }
      />

      <ModalController
        modal={syncUpToolModal}
        title={"Sync Up"}
        render={(close) =>
          React.createElement(props.batchSyncUpToolController, {
            render: (onSubmit, status) => (
              <BatchSyncUpTool
                onSubmit={() => onSubmit().then(close)}
                status={status}
              />
            )
          })
        }
      />

      <ModalController
        modal={invoiceModal}
        title={"Download Invoice"}
        render={(close) =>
          React.createElement(props.invoiceToolController, {
            render: ({ customerDetailsStatus, submitStatus, onSubmit }) => (
              <UpdateCustomerDetailsTool
                customerDetailsStatus={customerDetailsStatus}
                submitStatus={submitStatus}
                onSubmit={(customerDetails) => onSubmit(customerDetails).then(close)}
              />
            )
          })
        }
      />

      <Panel>
        {props.batch.status === MigrationStatus.Running && <IndefiniteProgressBar/>}
        <MultiColumnRow>
          <Column width={0}>
            {
              props.batch.status === MigrationStatus.Scheduled
                ? <Button size={"narrow"} onClick={launchModal.open}>Start Migration</Button>
                : (
                  <Button
                    color={"white"}
                    size={"narrow"}
                    onClick={syncUpToolModal.open}
                    disabled={props.batch.status !== MigrationStatus.Completed}
                  >
                    Sync Up
                  </Button>
                )
            }
          </Column>
          <Column responsiveBehavior={ColumnResponsiveBehavior.Expand}>
            <Indicators>
              <ItemCountIndicator title={"Size"} count={props.batch.batchOrder.totalAccounts} unit={"account"}/>
              <SimpleIndicator title={"Status"} value={MigrationStatus.userFriendly(props.batch.status, 0)}/>
              <DateTimeIndicator title={"Created At"} dateTime={props.batch.createdAt}/>
              <DateTimeIndicator title={"Started At"} dateTime={props.batch.startedAt}/>
              <DateTimeIndicator title={"Completed At"} dateTime={props.batch.completedAt}/>
            </Indicators>
          </Column>
          <Column>
            <SimpleToolbar>
              <Button color={"white"} size={"small"} onClick={invoiceModal.open}>Download Invoice</Button>
              <Button color={"white"} size={"small"} onClick={props.onDownloadReports}>Download Reports</Button>
            </SimpleToolbar>
          </Column>
        </MultiColumnRow>
      </Panel>
    </>
  );
};
