import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { EmailDeliverySchedule } from "../../types/models/emailDeliverySchedule";
import { List } from "immutable";

export function useGetEmailDeliverySchedulesQuery(
): ManagedQueryHook<GraphQL.GetEmailDeliverySchedulesQueryVariables, List<EmailDeliverySchedule>> {
  return useManagedQuery({
    query: GraphQL.useGetEmailDeliverySchedulesQuery,
    deps: null,
    prepare: () => ({}),
    extract: (data: GraphQL.GetEmailDeliverySchedulesQuery) => nullToUndefined(data.emailDeliverySchedules),
    complete: (emailDeliverySchedules) => List(emailDeliverySchedules).map(EmailDeliverySchedule.parse)
  });
}
