import { Collectable } from "./collectable";
import { identity } from "../../utils/misc";
import { GraphQL } from "../../services/graphql/generated";

export class CollectableInteger extends Collectable<number, number> {
  public type: string = "CollectableInteger";
}

export namespace CollectableInteger {
  export function build(
    collectable: Collectable.Data,
    partialValue: number | null | undefined,
    completeValue: number | null | undefined
  ): CollectableInteger {
    return new CollectableInteger(
      Collectable.makeValue(
        collectable,
        partialValue,
        completeValue,
        identity,
        identity
      )
    );
  }

  export function fromGraphQL(collectable: GraphQL.CollectableInteger): CollectableInteger {
    return build(collectable,  collectable.partialValue, collectable.completeValue);
  }

  const typeConfig: Collectable.TypeConfig<number, number, CollectableInteger> = {
    typeName: "CollectableInteger",
    build: (value) => new CollectableInteger(value),
    completeToPartial: identity
  };

  export const { Max, MaxOpt } = Collectable.standardMaxReducers(
    typeConfig,
    (a, b) => Math.max(a, b),
    (a, b) => Math.max(a, b)
  );

  export const { Sum, SumOpt } = Collectable.standardSumReducers(
    typeConfig,
    (a, b) => a + b,
    (a, b) => a + b
  );

  function compareValues(a: number, b: number): number {
    return a < b ? -1 : (a > b ? 1 : 0);
  }

  export const { Compare, CompareCompleteValues } = Collectable.standardComparators(
    typeConfig,
    {
      compare: (a, b) => compareValues(a.currentValue(), b.currentValue()),
      compareCompleteValues: compareValues
    }
  );

  export const Reducers = [Max, MaxOpt, Sum, SumOpt];
  export const Comparators = [Compare, CompareCompleteValues];
}
