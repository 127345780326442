import * as React from "react";
import { CloudService } from "../types/models/cloudService";
import { Connection } from "../types/models/connection";
import { useProgramAlias } from "../app/configuration";
import { useGetSourceConnectionPromotionsQuery } from "../queries/useGetSourceConnectionPromotionsQuery";
import { ConnectionInterstitialStepView } from "../views/screens/migrationSetup/connectionInterstitialStepView";

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  sourceConnection: Connection;
  destinationCloudService: CloudService;

  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const ConnectionInterstitialStep: React.FunctionComponent<Props> = (props) => {
  const currentProgramAlias = useProgramAlias();

  // The reason for having this query with force reload is that the school information may be updated during the time
  // between connecting the source account and reaching this step. If this happens, the school information will be
  // updated in the Apollo Client cache and override previous results.
  const [loadingStatus] = useGetSourceConnectionPromotionsQuery({
    currentProgramAlias,
    sourceConnectionId: props.sourceConnection.id,
    destinationCloudServiceId: props.destinationCloudService.id,
    destinationConnectionId: undefined,
    forceReload: true
  });

  return (
    <ConnectionInterstitialStepView
      step={props.step}
      totalSteps={props.totalSteps}

      schoolLoadingStatus={loadingStatus.map((result) => result.school)}

      sourceCloudService={props.sourceCloudService}
      sourceConnection={props.sourceConnection}
      destinationCloudService={props.destinationCloudService}

      onSkip={props.onSkip}
      onNavigateBack={props.onNavigateBack}
    />
  );
};
