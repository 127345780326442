import * as React from "react";
import { styled, useAppTheme } from "../../app/theme";
import { VaultMeLogoColors } from "./vaultMeLogo";

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

interface Props {
  inverted?: boolean;
}

// tslint:disable max-line-length
export const VaultMeIcon: React.FunctionComponent<Props> = (props) => {
  const theme = useAppTheme();

  return (
    <StyledSvg viewBox="-6 0 41 41">
      <path
        id="swoosh"
        fill={theme.colors.useMonochromeLogo ? VaultMeLogoColors.white : VaultMeLogoColors.blue}
        d="M27.9 0C27.9 0 27.9 0 27.9 0 29.2 0 30 0.8 30.3 1.9 30.5 3.1 30.1 4.3 28.6 5.1L1.9 17.3C1.7 16.6 2.3 15.4 4.1 14.3L25.5 0.8C26.4 0.2 27.3 0 27.9 0Z"
      />
      <polygon
        id="V"
        fill={props.inverted ? VaultMeLogoColors.white : VaultMeLogoColors.black}
        points="17.6 16 10.4 33.2 5.7 20.3 0.4 22.2 7.9 40 13.5 40 24.5 13.5"
      />
    </StyledSvg>
  );
};
