import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import { useApolloClient } from "@apollo/react-hooks";
import { PricingModel } from "../../types/models/pricingModel";

type DeletePricingModelMutationHook = [(id: number) => Promise<boolean>, OperationStatus<boolean>];

export function useDeletePricingModelMutation(): DeletePricingModelMutationHook {
  const apolloClient = useApolloClient();

  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeletePricingModelMutation,
    extract: (data: GraphQL.DeletePricingModelMutation) => nullToUndefined(data.deletePricingModel),
    complete: identity
  });

  function fireWith(id: number): Promise<boolean> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    }).then((result) => {
      const cacheId = PricingModel.cacheId(id);

      const pricingModel = apolloClient.cache.readFragment<GraphQL.PricingModelFragment>({
        id: cacheId,
        fragment: GraphQL.PricingModelFragmentDoc,
        // Prevents the following error:
        // Invariant Violation: Found 2 fragments. `fragmentName` must be provided when there is not exactly 1 fragment.
        fragmentName: PricingModel.fragmentName
      });

      if (pricingModel) {
        apolloClient.cache.writeFragment({
          id: cacheId,
          fragment: GraphQL.PricingModelFragmentDoc,
          fragmentName: PricingModel.fragmentName,
          data: { ...pricingModel, id: -1 }
        });
      }

      return result;
    });
  }

  return [fireWith, status];
}
