import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { PricingModel } from "../../types/models/pricingModel";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime, multiline } from "../../utils/formatting";
import { useSearchPricingModelsQuery } from "../../queries/pricingModels/useSearchPricingModelsQuery";
import { useDeletePricingModelMutation } from "../../queries/pricingModels/useDeletePricingModelMutation";
import { useUpdatePricingModelMutation } from "../../queries/pricingModels/useUpdatePricingModelMutation";
import { useInsertPricingModelMutation } from "../../queries/pricingModels/useInsertPricingModelMutation";
import { useGetPricingModelQuery } from "../../queries/pricingModels/useGetPricingModelQuery";
import { PricingModelForm } from "../../views/blocks/pricingModels/pricingModelForm";
import { PricingModelFormData } from "../../types/models/pricingModelFormData";
import { CenteredContent } from "../../views/layouts/centeredContent";

export const PricingModelAdminPages: React.FunctionComponent = () => (
  <CenteredContent fullWidth={true}>
    {
      CrudPages<number, PricingModel, PricingModelFormData>({
        title: "Pricing Configs",
        subject: "Pricing Config",
        gridColumns: [
          {
            title: "Name",
            render: (pricingModel) => pricingModel.name
          },
          {
            title: "Internal notes",
            render: (pricingModel) => multiline(pricingModel.notes)
          },
          {
            title: "Created At",
            render: (pricingModel) => friendlyDateTime(pricingModel.createdAt)
          },
        ],
        renderForm: (config) => <PricingModelForm config={config}/>,

        useRoutes: () => useRoutes().pricingModels,
        getItemId: (pricingModel) => pricingModel.id,
        getItemTitle: (pricingModel) => pricingModel.name,
        clone: (pricingModel) => ({
          ...pricingModel,
          name: "Copy of " + pricingModel.name
        }),
        compare: (a: PricingModel, b: PricingModel) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        },

        useGetQuery: useGetPricingModelQuery,
        useSearchQuery: useSearchPricingModelsQuery,
        useInsertMutation: useInsertPricingModelMutation,
        useUpdateMutation: useUpdatePricingModelMutation,
        useDeleteMutation: useDeletePricingModelMutation
      })
    }
  </CenteredContent>
);
