import * as React from "react";
import { styled } from "../../app/theme";
import { Button } from "./button";
import { UserFacingError } from "../../types/userFacingError";
import { EmailSupportLink } from "./emailSupportLink";

export interface ErrorInfoProps {
  error: UserFacingError;
}

const Layout = styled.div`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: ${(props) => props.theme.font.medium};
  margin-bottom: .3rem;
`;

const Body = styled.div`
  margin-bottom: .3rem;

  p, ol, ul {
    margin-top: .75rem;
  }
  
  ol, ul {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 2rem;
    line-height: 1.5;
  }
`;

const Actions = styled.div`
  margin: .8rem 0;
  display: flex;
`;

const ActionContainer = styled.div`
  margin-right: 1rem;
`;

const TechnicalDetails = styled.div`
  margin: .8rem 0;
  padding: .8rem;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  font-size: .8rem;
  word-break: break-word;
  
  > *:not(:first-child) {
    margin-top: 0.3rem;
  }
`;

const Footer = styled.div`
  margin-top: 0.5rem;
  font-size: .8rem;
`;

export const InlineErrorMessage = styled.pre`
  margin: 0.5rem 0;
  padding: 0.25rem;
  background: ${(props) => props.theme.colors.offWhite};
`;

const TechnicalDetailsPre = styled.pre`
  overflow-x: auto;
`;

export const ErrorInfo: React.FunctionComponent<ErrorInfoProps> = (props) => {
  const [showTechnicalDetails, setShowTechnicalDetails] = React.useState(false);

  const error = props.error.enrich({
    summary: props.error.cause && props.error.cause.hasOwnProperty("message") && props.error.cause.message
  });
  const summary = error.summary;
  const technicalDetails = renderTechnicalDetails(error.allTechnicalDetails());

  function renderTechnicalDetails(details: UserFacingError.TechnicalDetails | undefined) {
    if (details) {
      const items = details.filter((item) => !!item.content);
      if (items.length !== 0) {
        return items.map((item, index) => item.preFormatted
          ? (
            <React.Fragment key={index}>
              <div>{item.title}</div>
              <TechnicalDetailsPre>{item.content}</TechnicalDetailsPre>
            </React.Fragment>
          )
          : <div key={index}>{item.title}: <strong>{item.content}</strong></div>
        );
      }
    }
  }

  function renderAction(action: UserFacingError.Action): JSX.Element {
    if (UserFacingError.isButtonAction(action)) {
      return (
        <Button color={action.color || "secondary"} size="small" onClick={action.onClick}>
          {action.title}
        </Button>
      );
    } else {
      return action;
    }
  }

  function renderActions() {
    let actions: JSX.Element[] = [];

    if (error.retry) {
      actions.push(
        <ActionContainer key={"tryAgain"}>
          <Button color="primary" size="small" onClick={error.retry}>
            {error.retryTitle || "Try again"}
          </Button>
        </ActionContainer>
      );
    }

    if (error.cancel) {
      actions.push(
        <ActionContainer key={"cancel"}>
          <Button color="secondary" size="small" onClick={error.cancel}>
            {error.cancelTitle || "Cancel"}
          </Button>
        </ActionContainer>
      );
    }

    if (error.actions) {
      actions = actions.concat(error.actions.map((action, index) => (
        <ActionContainer key={"action" + index}>{renderAction(action)}</ActionContainer>
      )));
    }

    if (error.showTechnicalDetails !== false && technicalDetails) {
      actions.push(
        <ActionContainer key={"technicalDetails"}>
          <Button color="secondary" size="small" onClick={() => setShowTechnicalDetails((current) => !current)}>
            {showTechnicalDetails ? "Hide technical details" : "Show technical details"}
          </Button>
        </ActionContainer>
      );
    }

    if (actions.length > 0) {
      return <Actions>{actions}</Actions>;
    } else {
      return null;
    }
  }

  function sentence(s: React.ReactNode): React.ReactNode {
    if (typeof s === "string" && s.length > 0 && s[s.length - 1] !== ".") {
      return s + ".";
    } else {
      return s;
    }
  }

  return (
    <Layout>
      <Title>{error.title || "Oops! Something went wrong."}</Title>
      {(summary || error.recommendations) && (
        <Body>
          {summary && <>{sentence(summary)}&nbsp;</>}
          {error.recommendations && sentence(error.recommendations)}
        </Body>
      )}
      {renderActions()}
      {showTechnicalDetails && <TechnicalDetails>{technicalDetails}</TechnicalDetails>}
      {error.contactSupport !== false && (
        <Footer>
          If this error does not go away, please <EmailSupportLink>contact support.</EmailSupportLink>
        </Footer>
      )}
    </Layout>
  );
};
