import * as React from "react";
import { useWatcherDispatch } from "../../services/watcher/useWatcher";
import { WatchedMigrations } from "../../services/watcher/plugins/watchedMigrationsPlugin";
import { useCloudServices } from "../../app/configuration";
import { useBatchQuery } from "../../queries/useBatchQuery";
import { WatchedBatches } from "../../services/watcher/plugins/watchedBatchesPlugin";
import { AdminSidebar } from "../../views/layouts/sidebar";
import { BatchStatusPageView } from "../../views/screens/batchStatusPageView/batchStatusPageView";
import { BatchInsights } from "./batchInsights";
import { EntityJsonView } from "../../views/blocks/entityJsonView";
import { BatchOrderView } from "../../views/screens/batchStatusPageView/batchOrderView";
import { useBatchLaunchToolController } from "./batchLaunchToolController";
import { useBatchSyncUpToolController } from "./batchSyncUpToolController";
import { useInvoiceToolController } from "../../components/invoiceToolController";
import { useRenameBatchMutation } from "./useRenameBatchMutation";
import { useRoutes } from "../../app/routes/useRoutes";
import { useBrowser } from "../../utils/useBrowser";
import { useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { Order } from "../../types/models/order";
import { nullToUndefined } from "../../utils/misc";
import { BatchOrder } from "../../types/models/batchOrder";

export const BatchStatusPage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.batches.params();
  const browser = useBrowser();

  const watcher = useWatcherDispatch();
  const cloudServices = useCloudServices();

  const [batchStatus] = useBatchQuery(params.batchId);

  const batchLaunchToolController = useBatchLaunchToolController(params.batchId);
  const batchSyncUpToolController = useBatchSyncUpToolController(params.batchId);

  const buildDownloadUrl = React.useCallback(
    (customerDetails: string) => routes.api.batchInvoiceUrl(params.batchId, customerDetails),
    [params.batchId]
  );
  const invoiceToolController = useInvoiceToolController(
    batchStatus.isSuccess() ? batchStatus.result.userId : "", // This is not too nice, but really minor
    buildDownloadUrl
  );

  if (batchStatus.isSuccess()) {
    watcher(WatchedBatches.WatchBatchesAction([batchStatus.result.id]));
    watcher(WatchedMigrations.WatchMigrationsAction(
      batchStatus.result.migrations.map((migration) => migration.id)
    ));
  }

  const [renameBatch, renameBatchStatus] = useRenameBatchMutation(params.batchId);

  return (
    <>
      <AdminSidebar
        tools={[
          {
            title: "Overview",
            render: () => <BatchInsights batchId={params.batchId}/>
          },
          {
            title: "Order",
            render: () => <BatchOrderPage batchId={params.batchId}/>
          },
          {
            title: "JSON",
            render: () => <EntityJsonView status={batchStatus.map(({ migrations, ...rest }) => rest)}/>,
            noPadding: true
          }
        ]}
      />
      <BatchStatusPageView
        batchId={params.batchId}
        batchStatus={
          batchStatus.map((result) => ({
            ...result,
            sourceCloudService: cloudServices.getOrFail(result.sourceCloudServiceId),
            destinationCloudService: cloudServices.getOrFail(result.destinationCloudServiceId)
          }))
        }

        onBatchRename={renameBatch}
        renameBatchStatus={renameBatchStatus}

        batchLaunchToolController={batchLaunchToolController}
        batchSyncUpToolController={batchSyncUpToolController}
        invoiceToolController={invoiceToolController}

        onDownloadReports={() => browser.openNewTab(routes.api.batchMigrationReportUrl(params.batchId), true)}
      />
    </>
  );
};

interface BatchOrderPageProps {
  batchId: string;
}

const BatchOrderPage: React.FunctionComponent<BatchOrderPageProps> = (props) => {
  const [status] = useManagedQuery({
    query: GraphQL.useGetBatchOrderQuery,
    deps: null,
    prepare: (deps) => ({ batchId: props.batchId }),
    extract: (data: GraphQL.GetBatchOrderQuery) => nullToUndefined(data.batch),
    complete: (batch) => ({
      ...BatchOrder.parse(batch.batchOrder),
      ...BatchOrder.HasRevenueData.parseRevenueData(batch.batchOrder)
    }),
    fetchPolicy: "no-cache"
  });

  return <BatchOrderView status={status}/>;
};
