import * as React from "react";
import { styled } from "../../../../app/theme";

export const ThemeColorSample = styled.div<{ color: string }>`
  display: inline-block;
  position: relative;
  top: 0.1rem;
  width: 2rem;
  height: 1rem;
  margin-right: .5rem;
  background: ${(props) => props.color};
`;
