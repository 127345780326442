import { Option } from "../../utils/monads/option";
import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const section: string = "section";
}

export class StyleguideRoutes {
  private readonly root = "styleguide";

  constructor(private context: RoutesContext) {}

  public params(): StyleguideRoutes.Params {
    return { section: Option.mayBe(this.context.routeParams[RouteParams.section]) };
  }

  public get homeMask(): string {
    return this.root;
  }

  public get wizardPathMask(): string {
    return this.root + "/:" + RouteParams.section + "/*";
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public sectionPath(section: string): string {
    return this.context.resolvePath(this.root + "/" + section);
  }
}

export namespace StyleguideRoutes {
  export interface Params {
    section: Option<string>;
  }
}
