import * as React from "react";
import { useAuthProviders, useCloudServices } from "../app/configuration";
import { ConnectPageLoadingView, ConnectPageView, SignOutRequiredView } from "../views/screens/connectPageView";
import { useConnectionFlow } from "../migrationSetup/useConnectionFlow";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { Connection } from "../types/models/connection";
import { useMigrationBlueprint } from "../queries/useMigrationProgressQuery";
import { Facts } from "../types/facts/facts";
import { AdminSidebar } from "../views/layouts/sidebar";
import { Blueprint } from "../blueprints/blueprint";
import { OperationStatus } from "../types/operationStatus";
import { UserFacingError } from "../types/userFacingError";
import { UserType } from "../types/models/userType";
import { ManagedQueryHook, useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";
import { UserSummary } from "../types/models/userSummary";
import { useSession } from "../utils/useAppState";
import { useCachedConnection } from "../queries/useConnections";
import { useRoutes } from "../app/routes/useRoutes";

export const ConnectPage: React.FunctionComponent = () => {
  const session = useSession();
  const cloudServices = useCloudServices();
  const params = useRoutes().connect.params();

  const [connectionId, setConnectionId] = React.useState<string>();
  const connection = useCachedConnection(connectionId);

  function failure(summary: string): OperationStatus.FailureStatus<any> {
    return OperationStatus.Failure(UserFacingError.synthetic({
      title: "Oops! Please check your URL.",
      summary
    }));
  }

  if (!cloudServices.get(params.sourceCloudServiceId)) {
    return (
      <ConnectPageLoadingView
        status={failure("\"" + params.sourceCloudServiceId + "\" is not a valid source provider ID")}
      />
    );
  } else if (!cloudServices.get(params.destinationCloudServiceId)) {
    return (
      <ConnectPageLoadingView
        status={failure("\"" + params.destinationCloudServiceId + "\" is not a valid destination provider ID")}
      />
    );
  } else if (
    session &&
    !session.elevated &&
    session.user.id !== params.userId &&
    session.user.createdForUserId !== params.userId
  ) {
    return <SignOutRequiredView/>;
  } else {
    return (
      <ConnectPageLoader
        source={params.isSource}
        userId={params.userId}
        sourceCloudServiceId={
          params.isSource
            ? connection?.cloudServiceId || params.sourceCloudServiceId
            : params.sourceCloudServiceId
        }
        destinationCloudServiceId={
          !params.isSource
            ? connection?.cloudServiceId || params.destinationCloudServiceId
            : params.destinationCloudServiceId
        }
        connection={connection}
        setConnectionId={setConnectionId}
      />
    );
  }
};

interface ConnectPageLoaderProps {
  source: boolean;
  userId: string;

  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  connection: Connection | undefined;

  setConnectionId: (connectionId: string | undefined) => void;
}

const ConnectPageLoader: React.FunctionComponent<ConnectPageLoaderProps> = (props) => {
  const [userSummaryStatus] = useUserSummary(props.userId);

  const [getMigrationBlueprintStatus] = useMigrationBlueprint(
    props.sourceCloudServiceId,
    props.destinationCloudServiceId
  );

  if (!userSummaryStatus.isSuccess()) {
    return <ConnectPageLoadingView status={userSummaryStatus}/>;
  } else
  if (!getMigrationBlueprintStatus.isSuccess()) {
    return <ConnectPageLoadingView status={getMigrationBlueprintStatus}/>;
  } else {
    return (
      <ConnectPageBody
        source={props.source}
        userId={props.userId}
        requestingUser={userSummaryStatus.result}
        sourceCloudServiceId={props.sourceCloudServiceId}
        destinationCloudServiceId={props.destinationCloudServiceId}
        blueprint={getMigrationBlueprintStatus.result}
        connection={props.connection}
        setConnectionId={props.setConnectionId}
      />
    );
  }
};

interface ConnectPageBodyProps {
  source: boolean;
  userId: string;
  requestingUser: UserSummary;
  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  blueprint: Blueprint;
  connection: Connection | undefined;

  setConnectionId: (connectionId: string | undefined) => void;
}

const ConnectPageBody: React.FunctionComponent<ConnectPageBodyProps> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const blueprint = React.useMemo(
    () => MaterializedMigrationBlueprint.build({
      sourceCloudServiceId: props.sourceCloudServiceId,
      destinationCloudServiceId: props.destinationCloudServiceId,
      blueprint: props.blueprint,
      cloudServices,
      authProviders,
      sourceConnection: props.source ? props.connection : undefined,
      destinationConnection: props.source ? undefined : props.connection,
      facts: Facts.Empty,
      excludedAreas: []
    }),
    [props.sourceCloudServiceId, props.destinationCloudServiceId, props.blueprint, props.connection]
  );

  const connectionState = useConnectionFlow({
    source: props.source,
    cloudService: cloudServices.getOrFail(
      props.source ? props.sourceCloudServiceId : props.destinationCloudServiceId
    ),
    oppositeCloudService: cloudServices.getOrFail(
      props.source ? props.destinationCloudServiceId : props.sourceCloudServiceId
    ),
    blueprint,
    areas: props.source
      ? blueprint.listAreas().map(([area, sink]) =>
        ({ component: area, config: {}, notUsed: !sink })
      )
      : blueprint.listSinks().map(([sink, areas]) =>
        ({ component: sink, config: {}, notUsed: areas.isEmpty() })
      ),
    defaultRoles: blueprint.roles(props.source),

    connection: props.connection,
    oppositeConnection: undefined,

    connectionHandlers: {
      onConnect: (connection) => props.setConnectionId(connection.id),
      onDisconnect: () => props.setConnectionId(undefined)
    },

    showRestrictions: false,
    newUserSettings: { userType: UserType.Temporary, createdForUserId: props.userId }
  });

  return (
    <>
      <AdminSidebar blueprint={blueprint}/>
      <ConnectPageView requestingUser={props.requestingUser} connectionState={connectionState}/>
    </>
  );
};

function useUserSummary(userId: string): ManagedQueryHook<GraphQL.GetUserSummaryQueryVariables, UserSummary> {
  return useManagedQuery({
    query: GraphQL.useGetUserSummaryQuery,
    deps: null,
    prepare: () => ({ userId }),
    extract: (data: GraphQL.GetUserSummaryQuery) => nullToUndefined(data.getUserSummary),
    complete: UserSummary.parse
  });
}
