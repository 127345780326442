import * as React from "react";
import { Page } from "../containers/page";
import { OperationStatus } from "../../types/operationStatus";
import { EmailAddressWhitelistingForProgram } from "../../types/models/emailAddressWhitelistingForProgram";
import { List } from "immutable";
import { Block } from "../containers/block";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { Panel } from "../containers/panel";
import { Grid } from "../widgets/grid";
import { OperationStatusIndicator } from "../utils/operationStatusIndicator";
import { StatusIndicators } from "../utils/statusIndicators";
import { styled } from "../../app/theme";
import { Button } from "../widgets/button";
import { Program, ProgramSummary } from "../../types/models/program";
import { useRoutes } from "../../app/routes/useRoutes";
import { SimpleToolbar } from "../widgets/simpleToolbar";
import { NewTabLink } from "../widgets/newTabLink";
import { Modal } from "../layouts/modal";
import { SearchPanel } from "../blocks/crud/searchPanel";
import { LinkButton } from "../widgets/linkButton";
import { DrawerBlock } from "views/containers/drawerBlock";

export interface WhitelistEmailAddressToolViewProps {
  searchStatus: OperationStatus<any>;
  whitelistingStatus: OperationStatus<any>;

  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
  onWhitelist: (emailAddress: string, programId: string) => Promise<any>;
  onRemove: (emailAddress: string) => Promise<any>;
}

export interface WhitelistEmailAddressToolControllerProps {
  render: (viewProps: WhitelistEmailAddressToolViewProps) => React.ReactElement;
}

export type WhitelistEmailAddressToolControllerType = React.ComponentType<WhitelistEmailAddressToolControllerProps>;

interface Props {
  status: OperationStatus<List<EmailAddressWhitelistingForProgram>>;
  whitelistEmailAddressToolController: WhitelistEmailAddressToolControllerType;
}

export const TestAccountWhitelistingForProgramsView: React.FunctionComponent<Props> = (props) => {
  const [currentItem, setCurrentItem] = React.useState<EmailAddressWhitelistingForProgram>();

  function closeModal() {
    setCurrentItem(undefined);
  }

  return (
    <Page title={"Test Account Whitelisting For Programs"}>
      <OperationStatusIndicator
        status={props.status}
        indicators={StatusIndicators.SimplePanel()}
      />
      {currentItem && (
        <Modal onClose={closeModal}>
          {
            React.createElement(props.whitelistEmailAddressToolController, {
              render: (viewProps) => (
                <WhitelistEmailAddressTool
                  {...viewProps}
                  canRemove={!!currentItem.program}
                  onWhitelist={(programId) =>
                    viewProps.onWhitelist(currentItem.emailAddress, programId).then(closeModal)
                  }
                  onRemove={() => viewProps.onRemove(currentItem.emailAddress).then(closeModal)}
                />
              )
            })
          }
        </Modal>
      )}
      {props.status.isSuccess() && (
        <Block>
          <TestAccountGridPanel items={props.status.result} onWhitelist={setCurrentItem}/>
        </Block>
      )}
    </Page>
  );
};

interface TestAccountGridPanelProps {
  items: List<EmailAddressWhitelistingForProgram>;
  onWhitelist: (item: EmailAddressWhitelistingForProgram) => void;
}

export const TestAccountGridPanel: React.FunctionComponent<TestAccountGridPanelProps> = (props) => (
  <Panel>
    <GridPanelRow>
      <Grid>
        <Grid.Header>
          <Grid.Column>Test Account</Grid.Column>
          <Grid.Column>Current Program</Grid.Column>
          <Grid.Column/>
        </Grid.Header>
        <Grid.Body>
          {
            props.items.map((item) => (
              <GridRow
                key={item.emailAddress}
                item={item}
                onWhitelist={() => props.onWhitelist(item)}
              />
            ))
          }
        </Grid.Body>
      </Grid>
    </GridPanelRow>
  </Panel>
);

interface GridRowProps {
  item: EmailAddressWhitelistingForProgram;
  onWhitelist: () => void;
}

const GridRow: React.FunctionComponent<GridRowProps> = (props) => {
  const routes = useRoutes();

  return (
    <Grid.Row>
      <Grid.Cell>{props.item.emailAddress}</Grid.Cell>
      <Grid.Cell>
        {
          props.item.program
            ? <ProgramInfo program={props.item.program}/>
            : <NotWhitelisted>Not whitelisted</NotWhitelisted>
        }
      </Grid.Cell>
      <Grid.Cell>
        <SimpleToolbar>
          <Button size={"small"} color={"secondary"} onClick={props.onWhitelist}>Whitelist</Button>
          {props.item.program && (
            <ViewProgramLink
              to={
                routes.organizationPortalRoutes
                  .programs(props.item.program.organizationId)
                  .editPath(props.item.program.id)
              }
            >
              View program
            </ViewProgramLink>
          )}
        </SimpleToolbar>
      </Grid.Cell>
    </Grid.Row>
  );
};

const NotWhitelisted = styled.div`
  color: ${(props) => props.theme.colors.lightGray};
`;

const ViewProgramLink = styled(NewTabLink)`
  font-size: 0.8rem;
`;

const ProgramInfoLayout = styled.div`
  display: flex;
`;

const ProgramLogoContainer = styled.div`
  width: 10rem;
  max-height: 5rem;
  margin-right: 1rem;
  text-align: center;
  flex-shrink: 0;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    width: 7rem;
    max-height: 3.5rem;
  }

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    width: 5rem;
    max-height: 2.5rem;
  }
`;

const ProgramLogo = styled.img`
  max-width: 10rem;
  max-height: 5rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    max-width: 7rem;
    max-height: 3.5rem;
  }

  ${(props) => props.theme.responsive.respondToXXSmall()} {
    max-width: 5rem;
    max-height: 2.5rem;
  }
`;

const ProgramName = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
`;

const ProgramDescription = styled.div`
  margin-top: .4rem;
  font-size: .9rem;
`;

interface ProgramInfoProps {
  program: ProgramSummary;
}

const ProgramInfo: React.FunctionComponent<ProgramInfoProps> = (props) => {
  const routes = useRoutes();

  return (
    <ProgramInfoLayout>
      {props.program.logoId && (
        <ProgramLogoContainer>
          <ProgramLogo src={routes.api.downloadUrl(props.program.logoId)}/>
        </ProgramLogoContainer>
      )}
      <div>
        <ProgramName>{props.program.name}</ProgramName>
        {props.program.description && (
          <ProgramDescription>
            {props.program.description}
          </ProgramDescription>
        )}
      </div>
    </ProgramInfoLayout>
  );
};

interface WhitelistEmailAddressToolProps {
  canRemove: boolean;
  searchStatus: OperationStatus<any>;
  whitelistingStatus: OperationStatus<any>;

  onSearch: (searchTerm: string) => void;
  onRefresh: () => void;
  onWhitelist: (programId: string) => void;
  onRemove: () => void;
}

const WhitelistEmailAddressTool: React.FunctionComponent<WhitelistEmailAddressToolProps> = (props) => {
  return (
    <>
      <OperationStatusIndicator
        progressMessage={"Whitelisting"}
        failureMessage={"Failed to whitelist email address"}
        status={props.whitelistingStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      <DrawerBlock>
        <SearchPanel<string, Program>
          subject={"program"}
          tools={
            props.canRemove
              ? [<LinkButton key={"remove"} onClick={props.onRemove}>Remove from the current whitelist</LinkButton>]
              : []
          }
          gridColumns={[
            {
              title: "Name",
              render: (program) => program.name
            }
          ]}

          getItemId={(program) => program.id}
          comparator={(a: Program, b: Program) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          }}

          searchTerm={""}
          searchStatus={props.searchStatus}

          onSearch={props.onSearch}
          onRefresh={props.onRefresh}
          onSelect={props.onWhitelist}
        />
      </DrawerBlock>
    </>
  );
};
