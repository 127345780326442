import { createStore, Store } from "redux";
import { appReducer } from "../state/appReducer";
import { AppState, saveState, initialAppState, loadState } from "../state/appState";
import throttle from "lodash/throttle";

let store: Store<AppState> | undefined;

export function initStore(): Store<AppState> {
  if (store) {
    throw new Error("Redux store has already been initialized");
  } else {
    store = createStore(appReducer, loadState() || initialAppState);
    store.subscribe(
      throttle(
        () => {
          const state = store ? store.getState() : undefined;
          if (state) {
            saveState(state);
          }
        },
        1000));
    return store;
  }
}
