import * as React from "react";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { GoogleSignInForm } from "../../views/blocks/auth/googleSignInForm";
import {
  OAuthAndServiceAccountSignInController,
  OAuthAndServiceAccountSignInControllerConfig
} from "./oAuthAndServiceAccountSignInController";

interface Props extends SignInDefs.SignInComponentProps {
  config: OAuthAndServiceAccountSignInControllerConfig;
  offerAccountCreation: boolean;
}

export const GoogleSignInController: React.FunctionComponent<Props> = (props) => (
  <OAuthAndServiceAccountSignInController
    {...props}
    render={(viewProps) => (
      <GoogleSignInForm
        {...viewProps}
        offerAccountCreation={props.offerAccountCreation}
      />
    )}
  />
);
