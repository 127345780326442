import * as React from "react";
import { ThemeProvider } from "styled-components";
import { AppTheme, defaultAppTheme } from "./theme";
import { ApolloClientProvider } from "./apolloClientProvider";
import { ApolloProvider } from "@apollo/react-hooks";
import { GlobalStyle } from "../views/globalStyle";
import { ConfigurationProvider } from "./configuration";
import { Watcher } from "../services/watcher/watcher";
import { CacheProvider } from "../utils/useCache";
import { Drawer, DrawerProvider } from "../views/layouts/drawer";
import { HotKeys } from "./hotKeys";
import { Outlet, useParams } from "react-router";
import { SessionWatcher } from "./sessionWatcher";
import { ReferralCodeIndication } from "../components/referralCodeIndication";

export const AppStack: React.FunctionComponent = () => {
  const params = useParams();

  return (
    <ThemeProvider theme={defaultAppTheme}>
      <GlobalStyle/>
      <ApolloClientProvider>
        {(apolloClient) => (
          <ApolloProvider client={apolloClient}>
            <ConfigurationProvider
              configurationAlias={params.configurationAlias}
              programAlias={params.programAlias}
              themeId={params.themeId ? Number(params.themeId) : undefined}
              organizationIdOrAlias={params.organizationIdOrAlias}
            >
              {(theme) => (
                <ThemeProvider theme={theme ? AppTheme.applyOverrides(defaultAppTheme, theme) : defaultAppTheme}>
                  <GlobalStyle/>
                  <SessionWatcher/>
                  <Watcher>
                    <CacheProvider>
                      <DrawerProvider>
                        {(drawerSettings) => (
                          <HotKeys>
                            <Outlet/>
                            <Drawer settings={drawerSettings}/>
                            <ReferralCodeIndication/>
                          </HotKeys>
                        )}
                      </DrawerProvider>
                    </CacheProvider>
                  </Watcher>
                </ThemeProvider>
              )}
            </ConfigurationProvider>
          </ApolloProvider>
        )}
      </ApolloClientProvider>
    </ThemeProvider>
  );
};
