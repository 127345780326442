import * as React from "react";
import * as yup from "yup";
import { PreferencesStepView } from "../views/screens/migrationSetup/preferencesStepView";
import { CloudService } from "../types/models/cloudService";
import { Connection } from "../types/models/connection";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { usePreferencesFlow } from "./usePreferencesFlow";
import { Set } from "immutable";
import { AdminSidebar } from "../views/layouts/sidebar";

export interface PreferencesStepResult {
  excluded: string[];
  suppressedActionItemIds: string[];
}

export namespace PreferencesStepResult {
  export const Schema = yup.object<PreferencesStepResult>({
    excluded: yup.array().of(yup.string().required()),
    suppressedActionItemIds: yup.array().of(yup.string().required())
  });
}

interface Props {
  step: number;
  totalSteps: number;

  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
  blueprint: MaterializedMigrationBlueprint;
  sourceConnection: Connection;
  destinationConnection: Connection;
  excluded: string[];
  suppressedActionItemIds: string[];

  onApply: (step: PreferencesStepResult) => void;
  onComplete: (step: PreferencesStepResult) => void;
  onClear: () => void;
  onNavigateBack?: () => void;
}

export const PreferencesStep: React.FunctionComponent<Props> = (props) => {
  const suppressedActionItemIds = Set(props.suppressedActionItemIds);

  const preferencesFlow = usePreferencesFlow({
    sourceCloudService: props.sourceCloudService,
    destinationCloudService: props.destinationCloudService,
    blueprint: props.blueprint,
    sourceConnection: props.sourceConnection,
    destinationConnection: props.destinationConnection,

    excluded: Set(props.excluded),
    onSelectionChange: (excluded) => props.onApply({
      excluded: excluded.toArray(),
      suppressedActionItemIds: props.suppressedActionItemIds
    }),

    actionItemSuppressing: {
      suppressed: suppressedActionItemIds,
      onSuppress: (actionItemId) => props.onApply({
        excluded: props.excluded,
        suppressedActionItemIds: suppressedActionItemIds.add(actionItemId).toArray()
      }),
      onReActivate: (actionItemId) => props.onApply({
        excluded: props.excluded,
        suppressedActionItemIds: suppressedActionItemIds.remove(actionItemId).toArray()
      })
    }
  });

  return (
    <>
      <AdminSidebar blueprint={props.blueprint}/>
      <PreferencesStepView
        step={props.step}
        totalSteps={props.totalSteps}
        onNavigateBack={props.onNavigateBack}
        onProceed={() => props.onComplete({
          excluded: props.excluded,
          suppressedActionItemIds: props.suppressedActionItemIds
        })}

        sourceConnectionState={preferencesFlow.sourceConnectionState}
        destinationConnectionState={preferencesFlow.destinationConnectionState}
        estimates={preferencesFlow.estimates}
        pricingModelStatus={preferencesFlow.pricingModelStatus}
        migrationBlocker={preferencesFlow.migrationBlocker}
      />
    </>
  );
};
