import { PricingModelCalculator } from "./pricingModelCalculator";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { ProgramStatus } from "./programStatus";
import { SponsorshipConfig } from "./sponsorshipConfig";
import { UsedSponsorship } from "./usedSponsorship";
import { CouponCodeDiscount } from "./couponCodeDiscount";
import { ReferralCodeDiscount } from "./referralCodeDiscount";
import { AmbassadorStatusDiscount } from "./ambassadorStatusDiscount";
import { AmbassadorCodeDiscount } from "./ambassadorCodeDiscount";

export interface OrderSummary {
  readonly program: OrderSummary.ProgramSummary | undefined;

  readonly totalBytes: number;
  readonly totalItems: number;
  readonly priceCalculator: PricingModelCalculator;

  readonly originalBasePrice: number;
  readonly originalBytesFee: number;
  readonly originalItemsFee: number;
  readonly priceBeforeDiscounts: number;
  readonly couponCodeDiscount: CouponCodeDiscount | undefined;
  readonly referralCodeDiscount: ReferralCodeDiscount | undefined;
  readonly ambassadorStatusDiscount: AmbassadorStatusDiscount | undefined;
  readonly ambassadorCodeDiscount: AmbassadorCodeDiscount | undefined;

  readonly amountToBePaid: number;
  readonly usedSponsorship: UsedSponsorship | undefined;

  readonly priceAfterDiscounts: number;
  readonly breakdown: PricingModelCalculator.PricingItem[][];
}

export namespace OrderSummary {
  export interface ProgramSummary {
    id: string;
    alias: string | undefined;
    status: ProgramStatus;
  }

  export function parse(data: Omit<GraphQL.OrderSummaryFragment, "orderId">): OrderSummary {
    const priceCalculator = PricingModelCalculator.parse(data.priceCalculator);
    return {
      program: mapOptional(data.program, (program) => ({
        id: program.id,
        alias: nullToUndefined(program.alias),
        status: program.status,
      })),

      totalBytes: data.totalBytes,
      totalItems: data.totalItems,
      priceCalculator,

      priceBeforeDiscounts: data.priceBeforeDiscounts,
      originalBasePrice: data.originalBasePrice,
      originalBytesFee: data.originalBytesFee,
      originalItemsFee: data.originalItemsFee,
      couponCodeDiscount: mapOptional(data.couponCodeDiscount, CouponCodeDiscount.parse),
      referralCodeDiscount: mapOptional(data.referralCodeDiscount, ReferralCodeDiscount.parse),
      ambassadorStatusDiscount: mapOptional(data.ambassadorStatusDiscount, AmbassadorStatusDiscount.parse),
      ambassadorCodeDiscount: mapOptional(data.ambassadorCodeDiscount, AmbassadorCodeDiscount.parse),

      usedSponsorship: mapOptional(data.usedSponsorship, (usedSponsorship) => ({
        sponsorshipConfig: mapOptional(usedSponsorship.sponsorshipConfig, SponsorshipConfig.parse),
        sponsoredAmount: usedSponsorship.sponsoredAmount
      })),
      amountToBePaid: data.amountToBePaid,

      priceAfterDiscounts: data.priceAfterDiscounts,
      breakdown: priceCalculator.renderBreakdown(data.totalBytes, data.totalItems)
    };
  }
}
