import * as React from "react";
import { keyframes, styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const Height = 0.2;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

const increase = keyframes`
  from { left: -5%; width: 5%; }
  to { left: 130%; width: 100%;}
`;

const decrease = keyframes`
  from { left: -80%; width: 80%; }
  to { left: 110%; width: 10%;}
`;

const Background = styled.div`
  position: absolute;
  top: -${Height / 2}rem;
  width: 100%;
  height: ${Height}rem; 
  overflow-x: hidden;
`;

const Line = styled.div`
  position: absolute;
  background: ${(props) => props.theme.colors.primary};
  height: ${Height}rem; 

  &.inc {
    animation: ${increase} 2.5s infinite;
  }
  
  &.dec {
    animation: ${decrease} 2.5s .65s infinite;
  }
`;

const RedLine = styled.div`
  position: absolute;
  background-image: linear-gradient(
    to right, 
    ${(props) => props.theme.colors.red}, 
    ${(props) => props.theme.colors.lightRed},
    ${(props) => props.theme.colors.red}
  );
  height: ${Height}rem; 
  left: -30%;
  width: 80%;
`;

interface IndefiniteProgressBarProps extends StyledComponentsProps {
  error?: boolean;
}

const StyledIndefiniteProgressBar: React.FunctionComponent<IndefiniteProgressBarProps> = (props) => {
  const lines = props.error
    ? <RedLine/>
    : (
      <React.Fragment>
        <Line className="inc"/>
        <Line className="dec"/>
      </React.Fragment>
    );
  return (
    <Container className={props.className}>
      <Background>
        {lines}
      </Background>
    </Container>
  );
};

export const IndefiniteProgressBar = styled(StyledIndefiniteProgressBar)``;
