import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { ThemeOverrides } from "./themeOverrides";

export interface Theme extends ThemeOverrides {
  readonly id: number;
  readonly name: string;
  readonly notes: string | undefined;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export namespace Theme {
  export function parse(data: GraphQL.ThemeFragment): Theme {
    return {
      id: data.id,
      name: data.name,
      notes: nullToUndefined(data.notes),

      ...ThemeOverrides.parse(data),

      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  }

  export function cacheId(themeId: number): string {
    return cachedObjectId("Theme", themeId.toString());
  }

  export const fragmentName = "Theme";
}
