import * as React from "react";
import { useAdminToolsVisibility } from "../../utils/useAppState";
import { DisableAdminHighlight, InlineAdminContent } from "./adminHighlight";

export interface WhenElevatedProps {
  alwaysShow?: boolean;
}

export const WhenElevated: React.FunctionComponent<WhenElevatedProps> = (props) => {
  const show = useAdminToolsVisibility(props.alwaysShow);
  return show
    ? <InlineAdminContent.Provider value={true}>{props.children}</InlineAdminContent.Provider>
    : null;
};

export const AdminArea: React.FunctionComponent = (props) => (
  <DisableAdminHighlight.Provider value={true}>
    {props.children}
  </DisableAdminHighlight.Provider>
);
