import { OrganizationSummary } from "./organization";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional } from "../../utils/misc";
import { OrganizationPortalUser } from "./organizationPortalUser";

export interface OrganizationContext {
  readonly organization: OrganizationSummary;
  readonly organizationPortalUser: OrganizationPortalUser | undefined;
}

export namespace OrganizationContext {
  export function parse(data: GraphQL.OrganizationContextFragment): OrganizationContext {
    return {
      organization: OrganizationSummary.parse(data.organization),
      organizationPortalUser: mapOptional(data.organizationPortalUser, OrganizationPortalUser.parse)
    };
  }
}
