import { BlueprintFilters } from "./blueprintFilters";

export enum AdminToolsMode {
  Hidden = "Hidden",
  SplitScreen = "SplitScreen",
  FullScreen = "FullScreen"
}

export interface SettingsState {
  adminToolsMode: AdminToolsMode;
  blueprintFilters: BlueprintFilters;
  blueprintAnimation: boolean;
}

export const initialSettingsState: SettingsState = {
  adminToolsMode: AdminToolsMode.Hidden,
  blueprintFilters: BlueprintFilters.Empty,
  blueprintAnimation: false
};

export namespace SettingsState {
  export function toJson(settings: SettingsState) {
    return {
      ...settings,
      blueprintFilters: BlueprintFilters.toJson(settings.blueprintFilters)
    };
  }

  export function fromJson(json: any): SettingsState {
    if (json) {
      return {
        adminToolsMode: json.adminToolsMode || AdminToolsMode.Hidden,
        blueprintFilters: BlueprintFilters.fromJson(json.blueprintFilters),
        blueprintAnimation: json.blueprintAnimation
      };
    } else {
      return initialSettingsState;
    }
  }
}
