import { Map } from "immutable";
import { parse, ParsedQuery } from "query-string";
import { useLocation } from "react-router";
import { None, Option, Some } from "./monads/option";

// Parsed query is an object without a prototype, so .hasOwnProperty method is not available
/* tslint:disable:forin */

export class Query {
  constructor(private data: Map<string, string | string[] | null | undefined>) {
  }

  public getStringOption(key: string): Option<string> {
    const value = this.data.get(key);
    if (typeof value === "string") {
      return Some(value);
    } else {
      return None();
    }
  }

  public getString(key: string): string | undefined {
    const value = this.data.get(key);
    if (typeof value === "string") {
      return value;
    } else {
      return undefined;
    }
  }

  public fingerprint(): string {
    return this.data.toArray().toString();
  }
}

export namespace Query {
  export function make(parsed: ParsedQuery): Query {
    let result: Map<string, string | string[] | null | undefined> = Map();
    for (const key in parsed) {
      result = result.set(key, parsed[key]);
    }
    return new Query(result);
  }
}

export function useQuery(): Query {
  const location = useLocation();
  return Query.make(parse(location.search));
}

export function useBrowserQuery(): Query {
  return Query.make(parse(window.location.search));
}
