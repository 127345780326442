import * as React from "react";
import { List } from "immutable";
import { Paragraph } from "../../../../widgets/paragraph";
import { BulletPoints } from "../../../../widgets/bulletPoints";
import { EmailSupportLink } from "../../../../widgets/emailSupportLink";
import { ErrorInfo } from "./errorInfo";
import { sentence } from "../../../../../utils/misc";

interface Props {
  source: boolean;
  emailAddresses: List<string>;
}

export const AmbiguousEmailAddressesError: React.FunctionComponent<Props> = (props) => {
  const context = props.source ? "source" : "destination";

  return (
    <ErrorInfo title={sentence(context) + " email addresses appeared to be ambiguous."}>
      <Paragraph>
        The following email addresses matched multiple connected accounts:
      </Paragraph>
      <BulletPoints>
        {props.emailAddresses.map((emailAddress) =>
          <li key={emailAddress}>{emailAddress}</li>
        )}
      </BulletPoints>
      <Paragraph>
        Please contact us at <EmailSupportLink/>, and we'll help you resolve this issue.
      </Paragraph>
    </ErrorInfo>
  );
};
