import * as React from "react";
import { Route, Routes } from "react-router";
import { AdminPortalSection } from "../adminPortalSection";
import { AdminPortalSectionId } from "../../../views/screens/organizationPortal/adminPortalNav";
import {
  OrganizationPortalUsersRoutes as _OrganizationPortalUsersRoutes
} from "../../../app/routes/organizationPortalUsersRoutes";
import { BulkInsertOrganizationPortalUsersPage } from "./bulkInsertOrganizationPortalUsersPage";
import { RoutedOrganizationPortalUsersCrudPages } from "./organizationPortalUsersCrudPages";

export const OrganizationPortalUsersRoutes: React.FunctionComponent = () => (
  <Routes>
    <Route
      path={"/*"}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.Users}>
          {(context) => <RoutedOrganizationPortalUsersCrudPages organizationContext={context}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_OrganizationPortalUsersRoutes.insertInBulkMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.Users}>
          {(context) => <BulkInsertOrganizationPortalUsersPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
  </Routes>
);
