import { GraphQL } from "../../services/graphql/generated";

export interface ConfigurationCloudServiceFormData {
  readonly isSource: boolean;
  readonly cloudServiceId: string;

  readonly index: number;
  readonly reference: string | undefined;
  readonly description: string | undefined;
  readonly logoId: string | undefined;
  readonly excludedApps: string | undefined;
}

export namespace ConfigurationCloudServiceFormData {
  export function toGraphQL(data: ConfigurationCloudServiceFormData): GraphQL.ConfigurationCloudServiceFormData {
    return {
      isSource: data.isSource,
      cloudServiceId: data.cloudServiceId,

      index: data.index,
      reference: data.reference,
      description: data.description,
      logoId: data.logoId,
      excludedApps: data.excludedApps
    };
  }
}
