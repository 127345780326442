import * as React from "react";
import { AdminArea } from "../views/utils/whenElevated";
import { TaskPageView } from "../views/screens/taskPageView";
import { useTaskHistory } from "../components/jobManagement/useTaskHistory";
import { Constants } from "../app/constants";
import { useRoutes } from "../app/routes/useRoutes";

export const TaskPage: React.FunctionComponent = () => {
  const routes = useRoutes();
  const params = routes.jobs.taskParams();
  const [includeMinorUpdates, setIncludeMinorUpdates] = React.useState(false);
  const [loadingStatus, refresh] = useTaskHistory(
    params.jobId,
    params.taskId,
    includeMinorUpdates
  );

  React.useEffect(
    () => {
      const timer = setInterval(() => refresh(), Constants.Jobs.PollingInterval);
      return () => clearInterval(timer);
    }
  );

  return (
    <AdminArea>
      <TaskPageView
        jobId={params.jobId}
        taskId={params.taskId}
        includeMinorUpdates={includeMinorUpdates}

        loadingStatus={loadingStatus}

        onRefresh={() => refresh()}
        onChangeIncludeMinorUpdates={(value) => {
          if (includeMinorUpdates !== value) {
            setIncludeMinorUpdates(value);
            refresh({ includeMinorUpdates: value });
          }
        }}
      />
    </AdminArea>
  );
};
