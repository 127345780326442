import * as React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router";
import { RoutedStyleGuide } from "../../views/styleguide/styleguide";
import { WizardrySandbox } from "../../wizardry/sandbox/sandbox";
import { PageNotFound } from "../../views/pageNotFound";
import { MigrationSetup } from "../../migrationSetup/migrationSetup";
import { SimpleLayout } from "../../views/layouts/simpleLayout";
import { SignInPage } from "../../pages/signInPage";
import { MyProfilePage } from "../../pages/myProfilePage";
import { ElevatedRoutes } from "../../views/utils/elevatedRoutes";
import { UsersPage } from "../../pages/usersPage";
import { AuthenticatedRoutes } from "../../views/utils/authenticatedRoutes";
import { MigrationStatusPage } from "../../pages/migrationStatusPage/migrationStatusPage";
import { JobPage } from "../../pages/jobPage";
import { TaskPage } from "../../pages/taskPage";
import { TaskIssuePage } from "../../pages/taskIssuePage";
import { BatchMigrationSetup } from "../../migrationSetup/batch/batchMigrationSetup";
import { BatchStatusPage } from "../../pages/batchStatusPage/batchStatusPage";
import { UserDashboardPage } from "../../pages/userDashboardPage";
import { FeedbackPage } from "../../pages/feedbackPage";
import { MonitoringDashboardPage } from "../../pages/monitoringDashboardPage";
import { ConnectPage } from "../../pages/connectPage";
import { SignUpPage } from "../../pages/signUpPage";
import { BusinessUserRoutes } from "../../views/utils/businessUserRoutes";
import { Sandbox2 } from "../../sandbox/sandbox2";
import { MigrationSimulationPage } from "../../pages/migrationStatusPage/migrationSimulationPage";
import { SendCouponCodesPage } from "../../pages/couponCodes/sendCouponCodesPage";
import { AppStack } from "../appStack";
import { RoutesHook, useRoutes } from "./useRoutes";
import { OrganizationThemeAdminPages } from "../../pages/organizationPortal/themeAdminPages";
import { CouponCodesAdminPages } from "../../pages/couponCodes/couponCodeAdminPages";
import { Constants } from "../constants";
import {
  OrganizationConfigurationAdminPages
} from "../../pages/organizationPortal/configurationAdminPages";
import { OrganizationProgramAdminPages } from "../../pages/organizationPortal/programAdminPages";
import { PricingModelAdminPages } from "../../pages/pricingModels/pricingModelAdminPages";
import { OrganizationAdminPages } from "../../pages/organizationAdminPages";
import { OrganizationPortalRoutes } from "./organizationPortalRoutes";
import { AdminPortalSectionId } from "../../views/screens/organizationPortal/adminPortalNav";
import { TestAccountWhitelistingForProgramsPage } from "../../pages/testAccountWhitelistingForProgramsPage";
import { AdminPortalHome, AdminPortalSection } from "../../pages/organizationPortal/adminPortalSection";
import { OrganizationSettingsPage } from "../../pages/organizationPortal/organizationSettingsPage";
import { OrganizationDashboardPage } from "../../pages/organizationPortal/dashboard/organizationDashboardPage";
import { OrganizationMigrationsPage } from "../../pages/organizationPortal/organizationMigrationsPage";
import { OrganizationReportsPage } from "../../pages/organizationPortal/organizationReportsPage";
import { OrganizationPortalUsersRoutes } from "../../pages/organizationPortal/users/organizationPortalUsersRoutes";
import { AccessListsRoutes } from "../../pages/organizationPortal/accessLists/accessListsRoutes";
import { OrganizationPortalSignInPage } from "../../pages/organizationPortal/organizationPortalSignInPage";
import { OrganizationReferralCodeAdminPages } from "../../pages/organizationPortal/referralCodeAdminPages";
import { ReferAndEarnPage } from "../../pages/organizationPortal/referAndEarnPage";
import { OrganizationPortalTitle } from "../../pages/organizationPortal/organizationPortalTitle";
import {
  AutomatedOffboardingRoutes
} from "../../pages/organizationPortal/automatedOffboarding/automatedOffboardingRoutes";
import { MutationPromiseFreezingAfterRetry } from "../../services/graphql/bugs/mutationPromiseFreezingAfterRetry";
import { OptOutPage } from "../../pages/organizationPortal/automatedOffboarding/optOutPage";
import { OptOutRoutes } from "./optOutRoutes";
import { SchoolAdminPages } from "../../pages/schoolAdmin/schoolAdminPages";
import { MyAmbassadorDashboardPage, OtherAmbassadorDashboardPage } from "../../pages/ambassadorDashboardPage";
import { ContestParticipantsReportPage } from "../../pages/contestParticipantsReportPage";

// Important! Make sure to review useSidebarSize() when making structural changes here.

export const AppRoutes: React.FunctionComponent = () => {
  const routes = useRoutes();
  return (
    <Routes>
      <Route element={<AppStack/>}>
        {makeConfigurationRoutes(routes)}
      </Route>
      <Route path={Constants.Paths.configuration + "/:configurationAlias"} element={<AppStack/>}>
        {makeConfigurationRoutes(routes)}
      </Route>
      <Route path={Constants.Paths.program + "/:programAlias"} element={<AppStack/>}>
        {makeConfigurationRoutes(routes)}
      </Route>
      <Route path={Constants.Paths.theme + "/:themeId"} element={<AppStack/>}>
        {makeConfigurationRoutes(routes)}
      </Route>
    </Routes>
  );
};

// Required to pick up configuration ID from the path
const NavigateToMigrationSetup: React.FunctionComponent = () => {
  const routes = useRoutes();
  return <Navigate to={routes.migrationSetup.homePath}/>;
};

function makeConfigurationRoutes(routes: RoutesHook): React.ReactElement {
  return (
    <>
      <Route path={""} element={<NavigateToMigrationSetup/>}/>

      {/* Full-screen wizards */}
      <Route element={<BusinessUserRoutes/>}>
        <Route path={routes.batchMigrationSetup.homeMask} element={<BatchMigrationSetup/>}/>
        <Route path={routes.batchMigrationSetup.wizardPathMask} element={<BatchMigrationSetup/>}/>
      </Route>

      <Route path={routes.migrationSetup.homeMask} element={<MigrationSetup/>}/>
      <Route path={routes.migrationSetup.wizardPathMask} element={<MigrationSetup/>}/>

      <Route path={routes.styleguide.homeMask} element={<RoutedStyleGuide/>}/>
      <Route path={routes.styleguide.wizardPathMask} element={<RoutedStyleGuide/>}/>

      <Route path={routes.wizardrySandbox.homeMask} element={<WizardrySandbox/>}/>
      <Route path={routes.wizardrySandbox.wizardPathMask} element={<WizardrySandbox/>}/>

      <Route path={routes.sandbox.mask} element={<MutationPromiseFreezingAfterRetry/>}/>

      <Route path={OrganizationPortalRoutes.signInMask} element={<OrganizationPortalSignInPage/>}/>
      <Route path={OrganizationPortalRoutes.rootMask} element={<><OrganizationPortalTitle/><Outlet/></>}>
        <Route index={true} element={<AdminPortalHome/>}/>
        <Route path={OrganizationPortalRoutes.accessListsMask} element={<AccessListsRoutes/>}/>
        <Route path={OrganizationPortalRoutes.automatedOffboardingMask} element={<AutomatedOffboardingRoutes/>}/>
        <Route
          path={OrganizationPortalRoutes.configurationsRootMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Configurations}>
              {(context) => <OrganizationConfigurationAdminPages organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.dashboardMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Dashboard}>
              {(context, refresh) => (
                <OrganizationDashboardPage
                  organization={context.organization}
                  refreshOrganization={refresh}
                />
              )}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.migrationsRootMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Migrations}>
              {(context) => <OrganizationMigrationsPage organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.programsRootMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Programs}>
              {(context) => <OrganizationProgramAdminPages organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.referAndEarnMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.ReferAndEarn}>
              {(context) => <ReferAndEarnPage organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.referralCodesRootMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.ReferAndEarn}>
              {(context) => <OrganizationReferralCodeAdminPages organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.reportsMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Reports}>
              {(context) => <OrganizationReportsPage organizationId={context.organization.id}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.settingsMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Settings}>
              {(context) => <OrganizationSettingsPage organizationId={context.organization.id}/>}
            </AdminPortalSection>
          )}
        />
        <Route
          path={OrganizationPortalRoutes.themesRootMask}
          element={(
            <AdminPortalSection sectionId={AdminPortalSectionId.Themes}>
              {(context) => <OrganizationThemeAdminPages organization={context.organization}/>}
            </AdminPortalSection>
          )}
        />
        <Route path={OrganizationPortalRoutes.usersRootMask} element={<OrganizationPortalUsersRoutes/>}/>
      </Route>

      {/* Pages */}
      <Route element={<SimpleLayout/>}>
        <Route element={<ElevatedRoutes/>}>
          <Route path={routes.ambassadors.otherAmbassadorDashboardMask} element={<OtherAmbassadorDashboardPage/>}/>
          <Route path={routes.couponCodes.rootMask} element={<CouponCodesAdminPages/>}/>
          <Route path={routes.couponCodes.sendMask} element={<SendCouponCodesPage/>}/>
          <Route path={routes.jobs.jobsMask} element={<JobPage/>}/>
          <Route path={routes.jobs.tasksMask} element={<TaskPage/>}/>
          <Route path={routes.jobs.taskIssuesMask} element={<TaskIssuePage/>}/>
          <Route path={routes.organizations.rootMask} element={<OrganizationAdminPages/>}/>
          <Route path={routes.schools.rootMask} element={<SchoolAdminPages/>}/>
          <Route path={routes.pricingModels.rootMask} element={<PricingModelAdminPages/>}/>
          <Route
            path={routes.static.testAccountWhitelistingForProgramsMask}
            element={<TestAccountWhitelistingForProgramsPage/>}
          />
          <Route path={routes.static.myProfileMask} element={<MyProfilePage/>}/>
          <Route path={routes.users.homeMask} element={<UsersPage/>}/>
          <Route path={routes.users.userDetailsMask} element={<UsersPage/>}/>
          <Route path={routes.static.contestParticipantsReportMask} element={<ContestParticipantsReportPage/>}/>
        </Route>

        <Route element={<AuthenticatedRoutes/>}>
          <Route path={routes.ambassadors.myAmbassadorDashboardMask} element={<MyAmbassadorDashboardPage/>}/>
          <Route path={routes.batches.mask} element={<BatchStatusPage/>}/>
          <Route path={routes.feedback.mask} element={<FeedbackPage/>}/>
          <Route path={routes.migrations.homeMask} element={<UserDashboardPage/>}/>
          <Route path={routes.migrations.migrationStatusMask} element={<MigrationStatusPage/>}/>
        </Route>

        <Route path={routes.static.monitoringDashboardMask} element={<MonitoringDashboardPage/>}/>
        {/*<Route path={Paths.Sandbox} element={Sandbox2}/>*/}
        <Route path={routes.static.signInMask} element={<SignInPage/>}/>
        <Route path={routes.static.signUpMask} element={<SignUpPage/>}/>
        <Route path={routes.connect.mask} element={<ConnectPage/>}/>

        <Route path={routes.static.migrationSimulationMask} element={<MigrationSimulationPage/>}/>

        <Route path={OptOutRoutes.optOutMask} element={<OptOutPage/>}/>

        <Route path={"wf/newTransfer/*"} element={<NavigateToMigrationSetup/>}/>

        <Route path={"programs/dartmouth-new-grads"} element={<Navigate to={"/edu/dartmouth"}/>}/>
        <Route path={"programs/princeton-new-grads"} element={<Navigate to={"/edu/princeton"}/>}/>
        <Route path={"programs/cornell"} element={<Navigate to={"/edu/cornell"}/>}/>
        <Route path={"programs/newTransfer/*"} element={<NavigateToMigrationSetup/>}/>

        <Route path={"*"} element={<PageNotFound/>}/>
      </Route>
    </>
  );
}
