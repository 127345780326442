import { OrderedMap } from "immutable";
import { pick3 } from "../../utils/pick";
import { friendlyCount, friendlyCountOf } from "../../utils/formatting";

export class ItemsByType {
  public map: OrderedMap<string, number>;
  private memoizedTotalCount: number | undefined;

  constructor(data: ItemsByType.Data | OrderedMap<string, number>) {
    this.map = OrderedMap.isOrderedMap(data) ? data : OrderedMap(data);
  }

  public interpolate(multiplier: number, reduceBy: ItemsByType): ItemsByType {
    return new ItemsByType(
      this.map.map((count, itemType) =>
        Math.max(0, Math.round(count * multiplier) - (reduceBy.map.get(itemType, 0)))
      )
    );
  }

  public briefSummary(): string {
    if (this.map.size === 1) {
      return friendlyCount(this.totalCount(), this.map.keySeq().get(0, "item"));
    } else {
      return friendlyCount(this.totalCount(), "item");
    }

    // return this.map.reduce(
    //   (reduction, count, itemType) =>
    //     reduction + (reduction.length !== 0 ? ", " : "") + friendlyCount(count, itemType),
    //   ""
    // );
  }

  public verboseSummary(): string {
    return this.toVerboseString(friendlyCount);
  }

  public migrationSummary(total: ItemsByType | undefined): string {
    return this.toVerboseString((count, itemType) => {
      const totalCount = total && total.map.get(itemType);
      return totalCount !== undefined ? friendlyCountOf(count, totalCount, itemType) : friendlyCount(count, itemType);
    });
  }

  public totalCount(): number {
    if (this.memoizedTotalCount === undefined) {
      this.memoizedTotalCount = this.map.reduce((total, count) => total + count, 0);
    }
    return this.memoizedTotalCount;
  }

  private toVerboseString(makeValue: (count: number, itemType: string) => string): string {
    return this.map.reduce<{ count: number, result: string }>(
      (reduction, count, itemType) => ({
        count: reduction.count + 1,
        result: reduction.result +
          pick3(reduction.count === 1, " and ", reduction.count >= 2, ", ", "") +
          makeValue(count, itemType)
      }),
      { count: 0, result: "" }
    ).result;
  }
}

export namespace ItemsByType {
  export const Empty = new ItemsByType({});

  export interface Data {
    [itemType: string]: number;
  }
}
