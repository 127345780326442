import * as React from "react";
import { Constants } from "../../../../app/constants";
import { Block } from "../../../containers/block";
import { GrandOption } from "../../../widgets/option";
import { Step } from "../../../containers/step";
import { PanelRow } from "../../../containers/rows/panelRow";
import { styled } from "../../../../app/theme";
import { Panel } from "../../../containers/panel";

const PaddedPanel = styled(Panel)`
  border: 1px dashed #a0a0a0;

  margin-top: .75rem;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    margin-top: .5rem;
  }
`;

type MigrationType = "single" | "batch";

interface Props {
  step: number;
  totalSteps: number;

  type: MigrationType | undefined;

  onApply: (type: MigrationType) => void;
  onSubmit: (type: MigrationType) => void;
  onNavigateBack?: () => void;
}

export const YourGoalStepView: React.FunctionComponent<Props> = (props) => (
  <Step
    breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
    title={`What do you want to accomplish today?`}
    subTitle={
      `${Constants.Product.name} is finely-tuned to do thousands of migration combinations
      and gets smarter every day.`}
    submitTitle="Next"
    noForm={true}
    submitDisabled={!props.type}
    onSubmit={() => props.type && props.onSubmit(props.type)}
    onNavigateBack={props.onNavigateBack}
  >
    <Block>
      <GrandOption
        name="option"
        value="single"
        checked={props.type === "single"}
        onChange={() => props.onApply("single")}
        description={
          "Select this option to migrate the contents of an old user account to a new account. " +
          "Works for personal, educational, or small business needs."
        }
      >
        Migrate a single user account to another account
      </GrandOption>
      <GrandOption
        name="option"
        value="batch"
        checked={props.type === "batch"}
        onChange={() => props.onApply("batch")}
        description={
          "Select this option to run simultaneous migrations of multiple user accounts. " +
          "Intended for admins and business owners. "
        }
      >
        Migrate or archive multiple user accounts (advanced)
      </GrandOption>
      {props.type === "batch" && (
        <PaddedPanel>
          <PanelRow>
            Click the "Next" button below to proceed to VaultMe for Admins.
          </PanelRow>
        </PaddedPanel>
      )}
    </Block>
  </Step>
);
