import * as React from "react";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { Panel } from "../containers/panel";
import { IndefiniteProgressBar } from "../widgets/indefiniteProgressBar";
import { AssetCollection, AssetCollectionItem } from "./assetCollection";
import { LargeSpinner, Spinner } from "../glyphs/spinner";
import { CheckedCircle } from "../glyphs/checkedCircle";
import { PanelRow } from "../containers/rows/panelRow";
import { CheckMark } from "../glyphs/checkMark";
import { WarningSign } from "../glyphs/warningSign";
import { VaultMeIcon } from "../glyphs/vaultMeIcon";
import { ProgressBar } from "../widgets/progressBar";

const SmallSpinners: AssetCollectionItem[] = [
  {
    id: "indefinite",
    src: <Spinner progress={-1}/>
  },
  {
    id: "indefinite-slow",
    src: <Spinner progress={-1} animationSpeed={.2}/>
  },
  {
    id: "indefinite-fast",
    src: <Spinner progress={-1} animationSpeed={2}/>
  },
  {
    id: "progress-0",
    src: <Spinner progress={0}/>
  },
  {
    id: "progress-25",
    src: <Spinner progress={25}/>
  },
  {
    id: "progress-50",
    src: <Spinner progress={50}/>
  },
  {
    id: "progress-75",
    src: <Spinner progress={75}/>
  },
  {
    id: "progress-100",
    src: <Spinner progress={100}/>
  },
  {
    id: "error-50",
    src: <Spinner progress={50} color="red"/>
  },
  {
    id: "error-100",
    src: <Spinner progress={100} color="red"/>
  },
  {
    id: "blue-on-yellow",
    src: <Spinner progress={50} backgroundColor="gray"/>
  },
  {
    id: "thick",
    src: <Spinner progress={50} lineStrokeWidthMultiplier={2}/>
  },
  {
    id: "check",
    src: <CheckedCircle/>
  },
];

const LargeSpinners: AssetCollectionItem[] = [
  {
    id: "indefinite",
    src: <LargeSpinner progress={-1}/>
  },
  {
    id: "indefinite-slow",
    src: <LargeSpinner progress={-1} animationSpeed={.2}/>
  },
  {
    id: "indefinite-with-spin-up",
    src: <LargeSpinner progress={-1} spinUpSeconds={10}/>
  },
  {
    id: "indefinite-with-icon",
    src: <LargeSpinner progress={-1} icon={<VaultMeIcon/>}/>
  },
  {
    id: "percentage",
    src: <LargeSpinner progress={73} displayPercentage={true}/>
  },
  {
    id: "complete",
    src: <LargeSpinner progress={100} icon={<CheckMark strokeWidthMultiplier={1.5}/>}/>
  },
  {
    id: "warnings",
    src: <LargeSpinner progress={-1} color="yellow" animationSpeed={.5} icon={<WarningSign color={"yellow"}/>}/>
  },
  {
    id: "errors",
    src: <LargeSpinner progress={-1} color="red" animationSpeed={.5} icon={<WarningSign color={"red"}/>}/>
  },
];

export const ProgressIndicators: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Progress Bars</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow>
          A row with an indefinite progress bar
        </PanelRow>
        <IndefiniteProgressBar/>
        <PanelRow>
          Another row (just to have something after the progress bar)
        </PanelRow>
        <IndefiniteProgressBar error={true}/>
        <PanelRow>
          And one more row
        </PanelRow>
        <ProgressBar progress={75}/>
        <PanelRow>
          And the last row
        </PanelRow>
      </Panel>
    </StyleguideBlock>

    <AssetCollection
      title="Regular Spinners"
      items={SmallSpinners}
      tileWidth={3}
      tileHeight={3}
    />

    <AssetCollection
      title="Large Spinners"
      items={LargeSpinners}
      tileWidth={8}
      tileHeight={8}
    />

  </React.Fragment>
);
