import * as React from "react";
import { HeaderBlock } from "../../../widgets/headerBlock";
import { OperationStatus } from "../../../../types/operationStatus";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { Link } from "react-router-dom";
import { Block } from "views/containers/block";
import { Panel } from "../../../containers/panel";
import { PanelRow } from "../../../containers/rows/panelRow";
import { FileUpload, FileUploadController } from "../../../widgets/fileUpload";
import { SimpleFormButtonsBlock } from "../../../widgets/simpleForm";
import { Button } from "../../../widgets/button";
import { OffboardingProjectUpdate } from "../../../../types/models/offboardingProjectUpdate";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { commafy, friendlyDateTime } from "../../../../utils/formatting";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { LinkButton } from "views/widgets/linkButton";
import { IndefiniteProgressBar } from "../../../widgets/indefiniteProgressBar";
import { OffboardingProjectDetailsPanel } from "./offboardingProjectDetailsPanel";
import { AccessListUpdateStatus } from "../../../../types/enums/accessListUpdateStatus";
import { PropertiesTable } from "../accessLists/propertiesTable";
import {
  OffboardingProjectEntryCountController
} from "../../../../controllers/organizationPortal/automatedOffboarding/offboardingProjectEntryCountController";
import { styled } from "../../../../app/theme";
import { WarningSign } from "../../../glyphs/warningSign";
import { Paragraph } from "../../../widgets/paragraph";
import { BottomlessBulletPoints } from "./emailDeliveryAccountField";

interface Props {
  offboardingProjectId: string;
  offboardingProjectStatus: OperationStatus<OffboardingProject>;
  offboardingProjectUpdateStatus: OperationStatus<OffboardingProjectUpdate>;

  displayListUpdateInfo: boolean;

  entryCountController: OffboardingProjectEntryCountController;

  homePath: string;
  offboardingProjectPath: string;

  fileUploadController: FileUploadController;
  onUpload: (response: string) => void;

  onDownloadOutput: (offboardingProjectUpdateId: string) => void;
}

export const ImportOffboardingProjectEntriesPageView: React.FunctionComponent<Props> = (props) => (
  <>
    <HeaderBlock
      breadcrumbs={[
        <Link key={0} to={props.homePath}>End-User Offboarding</Link>,
        <Link key={1} to={props.offboardingProjectPath}>
          {
            props.offboardingProjectStatus
              .mapLastResult((offboardingProject) => offboardingProject.name) || "..."
          }
        </Link>,
        "Import Entries From CSV"
      ]}
      title={"Import Offboarding Project Entries From CSV"}
    />
    <OffboardingProjectDetailsPanel
      offboardingProjectStatus={props.offboardingProjectStatus}
      entryCountController={props.entryCountController}
    />
    <OperationStatusIndicator
      subject={"offboarding project update details"}
      status={props.offboardingProjectStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.offboardingProjectStatus.mapLastResult((offboardingProject) => (
      <>
        {
          props.displayListUpdateInfo &&
          props.offboardingProjectUpdateStatus.mapLastResult((offboardingProjectUpdate) => (
            <AccessListUpdateStatusPanel
              offboardingProjectUpdate={offboardingProjectUpdate}
              onDownloadOutput={props.onDownloadOutput}
            />
          ))
        }
        <Block>
          <Panel>
            <PanelRow>
              <Paragraph>
                Each row in the CSV must contain at least one email address from a domain registered to this
                organization. Additionally, each row can contain up to two secondary email addresses belonging to the
                same person, as well as that person's first and last name.
              </Paragraph>
              <BottomlessBulletPoints>
                <li>
                  <a href={"/assets/csvs/offboarding-project-template-1.csv"}>Template 1</a>{" "}
                  (one email address, first and last name)
                </li>
                <li>
                  <a href={"/assets/csvs/offboarding-project-template-2.csv"}>Template 2</a>{" "}
                  (one primary and one secondary email address, first and last name)
                </li>
                <li>
                  <a href={"/assets/csvs/offboarding-project-template-3.csv"}>Template 3</a>{" "}
                  (one primary and two secondary email addresses, first and last name)
                </li>
              </BottomlessBulletPoints>
            </PanelRow>
            <PanelRow>
              <FileUpload
                controller={props.fileUploadController}
                upload={undefined}
                disabled={offboardingProject.currentUpdateId !== undefined}
                onUpload={(result) => props.onUpload(result.response)}
              />
            </PanelRow>
          </Panel>
        </Block>
      </>
    ))}
    <SimpleFormButtonsBlock>
      <Link to={props.offboardingProjectPath}>
        <Button size={"small"} color={"secondary"}>Back</Button>
      </Link>
    </SimpleFormButtonsBlock>
  </>
);

interface AccessListUpdateStatusPanelProps {
  offboardingProjectUpdate: OffboardingProjectUpdate;
  onDownloadOutput: (offboardingProjectUpdateId: string) => void;
}

const AccessListUpdateStatusPanel: React.FunctionComponent<AccessListUpdateStatusPanelProps> = (props) => {
  const processed = props.offboardingProjectUpdate.processedEntries;
  const failed = props.offboardingProjectUpdate.skippedEntries + props.offboardingProjectUpdate.errors;

  function renderStatus(): { status: WidgetStatus, text: string } {
    switch (props.offboardingProjectUpdate.status) {
      case AccessListUpdateStatus.Success:
        if (processed !== 0 && failed === 0) {
          return {
            status: WidgetStatus.Success,
            text: "The import was completed successfully."
          };
        } else if (processed === 0 && failed !== 0) {
          return {
            status: WidgetStatus.Error,
            text: "The import failed."
          };
        } else {
          return {
            status: WidgetStatus.Warning,
            text: "The import was completed with some errors."
          };
        }

      case AccessListUpdateStatus.Failure:
        return {
          status: WidgetStatus.Error,
          text: "The import failed."
        };

      default:
        return {
          status: WidgetStatus.Info,
          text: "An import into this offboarding project is currently in progress. This import must be completed " +
            " before any other operation can be performed."
        };
    }
  }

  const { status, text } = renderStatus();

  return (
    <Block>
      <Panel>
        {
          props.offboardingProjectUpdate.status !== AccessListUpdateStatus.Success &&
          props.offboardingProjectUpdate.status !== AccessListUpdateStatus.Failure &&
            <IndefiniteProgressBar/>
        }
        <PanelRow status={status}>
          {text}
          <br/>
          <br/>
          <PropertiesTable>
            <tbody>
            <tr>
              <td>Started:</td>
              <td>{friendlyDateTime(props.offboardingProjectUpdate.createdAt)}</td>
            </tr>
            {props.offboardingProjectUpdate.completedAt && (
              <tr>
                <td>Completed:</td>
                <td>{friendlyDateTime(props.offboardingProjectUpdate.completedAt)}</td>
              </tr>
            )}
            <tr>
              <td>Imported Rows:</td>
              <td>{commafy(processed)}</td>
            </tr>
            <tr>
              <td>Errors:</td>
              <td>
                {commafy(failed)}
                {failed !== 0 && (
                  <IconContainer>
                    <WarningSign color={status === WidgetStatus.Error ? "red" : "yellow"}/>
                  </IconContainer>
                )}
              </td>
            </tr>
            {props.offboardingProjectUpdate.status === AccessListUpdateStatus.Success && (
              <tr>
                <td>Import Results (CSV):</td>
                <td>
                  <LinkButton onClick={() => props.onDownloadOutput(props.offboardingProjectUpdate.id)}>
                    Download
                  </LinkButton>
                </td>
              </tr>
            )}
            {props.offboardingProjectUpdate.status === AccessListUpdateStatus.Failure && (
              <tr>
                <td>Error Message:</td>
                <td>{props.offboardingProjectUpdate.errorMessage}</td>
              </tr>
            )}
            </tbody>
          </PropertiesTable>
        </PanelRow>
      </Panel>
    </Block>
  );
};

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: .2rem;
`;
