import * as React from "react";
import { styled } from "../../app/theme";

export const DiffLabel = styled.div`
  display: inline-block;
  padding: 0 .2rem;
  margin-right: .2rem;
  color: white;
  background: ${(props) => props.theme.colors.red};
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
  font-family: monospace;
  
  &:hover {
    background: ${(props) => props.onClick ? "#e84e72" : props.theme.colors.red};
  }
`;
