import * as React from "react";
import { useElevated } from "../utils/useAppState";
import { useDispatch } from "react-redux";
import { toggledAdminToolsAction } from "../state/settings/actions";
import { useDrawer } from "../views/layouts/drawer";
import { stealKeyStrokesFrom } from "../utils/misc";

export const HotKeys: React.FunctionComponent = (props) => {
  const elevated = useElevated();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  React.useEffect(
    () => {
      document.addEventListener("keydown", keyPressCallback);
      return () => document.removeEventListener("keydown", keyPressCallback);
    },
    []
  );

  function handleKeyPress(event: KeyboardEvent) {
    if (stealKeyStrokesFrom(event.target as Element)) {
      if (event.key === "Escape") {
        drawer.close();
      } else if (elevated) {
        switch (event.key) {
          case "a":
          case "A":
            dispatch(toggledAdminToolsAction());
            return;
        }
      }
    }
  }

  const keyPressCallback = React.useCallback(handleKeyPress, []);

  return <>{props.children}</>;
};
