import { ActionLike } from "../actionLike";
import { ProtoState } from "./state";

export enum ProtoStateActionType {
  ProtoStateUpdated = "proto/stateUpdated",
}

export interface ProtoStateUpdatedAction extends ActionLike<ProtoStateActionType.ProtoStateUpdated> {
  protoState: Partial<ProtoState>;
}

export function protoStateUpdatedAction(protoState: Partial<ProtoState>): ProtoStateUpdatedAction {
  return {
    type: ProtoStateActionType.ProtoStateUpdated,
    protoState
  };
}

export type ProtoStateAction = ProtoStateUpdatedAction;
