import { Migration } from "../../../../types/models/migration";
import * as React from "react";
import { ModalController, useModal } from "../../../layouts/modal";
import { Button } from "../../../widgets/button";
import { OperationStatus } from "../../../../types/operationStatus";
import { Constants } from "../../../../app/constants";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Panel } from "../../../containers/panel";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Paragraph } from "../../../widgets/paragraph";
import { DoNotMoveFoldersWarning } from "./doNotMoveFoldersWarning";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { DrawerBlock } from "../../../containers/drawerBlock";

export interface ResumeMigrationViewProps {
  status: OperationStatus<Migration>;
  onResume: () => Promise<Migration>;
}

export interface ResumeMigrationControllerProps {
  render: (viewProps: ResumeMigrationViewProps) => React.ReactElement;
}

export type ResumeMigrationControllerType = React.ComponentType<ResumeMigrationControllerProps>;

interface Props {
  migration: Migration;
  resumeMigrationController: ResumeMigrationControllerType;
}

export const ResumeMigrationToolRow: React.FunctionComponent<Props> = (props) => {
  const resumeMigrationModal = useModal();

  return (
    <>
      <ModalController
        modal={resumeMigrationModal}
        render={(close) => React.createElement(props.resumeMigrationController, {
          render: (viewProps) => (
            <ResumeMigrationTool
              migration={props.migration}
              status={viewProps.status}
              onResume={() => viewProps.onResume().then((result) => {
                setTimeout(close, Constants.SuccessIndicatorTimeout);
                return result;
              })}
            />
          )
        })}
      />

      <PanelRow status={WidgetStatus.Info}>
        <Paragraph>
          Your migration is paused. Click the button below to resume it.
        </Paragraph>
        <DoNotMoveFoldersWarning prefix={"Important: until the migration is complete"}/>
        <Paragraph>
          <Button size={"small"} onClick={resumeMigrationModal.open}>Resume Migration</Button>
        </Paragraph>
      </PanelRow>
    </>
  );
};

interface ResumeMigrationToolProps {
  migration: Migration;
  status: OperationStatus<Migration>;
  onResume: () => Promise<Migration>;
}

const ResumeMigrationTool: React.FunctionComponent<ResumeMigrationToolProps> = (props) => (
  <>
    <DrawerBlock>
      Click the button below to resume your migration. Please note that it may take up to 15 minutes for VaultMe to
      provision the required resources before the migration starts making progress.
    </DrawerBlock>
    <DrawerBlock>
      <Button size={"narrow"} onClick={props.onResume} disabled={props.status.isWorking()}>
        Resume Migration
      </Button>
    </DrawerBlock>
    <OperationStatusIndicator
      progressMessage={"Resuming migration..."}
      successMessage={"Successfully resumed migration"}
      failureMessage={"Failed to resume migration"}
      status={props.status}
      indicators={StatusIndicators.SimplePanel()}
    />
  </>
);
