export function pick<T>(options: [boolean, T][], orElse: T): T {
  const index = options.findIndex((option) => option[0]);
  return index !== -1 ? options[index][1] : orElse;
}

export function pick3<T>(c1: boolean, v1: T, c2: boolean, v2: T, orElse: T): T {
  if (c1) {
    return v1;
  } else if (c2) {
    return v2;
  } else {
    return orElse;
  }
}

export function pick4<T>(c1: boolean, v1: T, c2: boolean, v2: T, c3: boolean, v3: T, orElse: T): T {
  if (c1) {
    return v1;
  } else if (c2) {
    return v2;
  } if (c3) {
    return v3;
  } else {
    return orElse;
  }
}
