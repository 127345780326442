import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { DataFlowOf } from "../relationship";
import { State } from "../state";
import { None, Option, Some } from "../../utils/monads/option";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";

export class OptionalRelationshipHub<Output>
  extends DataSource<OptionalRelationshipHub.Props, Output, Option<Output>> {

  public dataFlow(): Option<DataFlowOf<Output>> {
    const dataFlows = this.dataFlows();
    return dataFlows.size > 0 ? Some(dataFlows.first()) : None();
  }

  public calcState(context: BlueprintContext): State<Option<Output>> {
    return this.dataFlow()
      .map((dataFlow) => dataFlow.state(context).map((output) => Some(output)))
      .getOrElse(() => State.resolved(None()));
  }
}

export namespace OptionalRelationshipHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.OptionalRelationshipHubFragment,
    settings?: ComponentHubSettings
  ): OptionalRelationshipHub<any> {
    return new OptionalRelationshipHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
