import * as React from "react";
import { Connection } from "../../../types/models/connection";
import { AccessKeyStatus } from "../../../types/models/accessKeyStatus";
import { Grid } from "../../widgets/grid";
import { AuthProviders } from "../../../types/models/authProviders";
import { multiline } from "../../../utils/formatting";
import { LinkButton } from "../../widgets/linkButton";
import { Button } from "../../widgets/button";
import { KebabButton } from "../../widgets/kebabButton";
import { CloudServices } from "../../../types/models/cloudServices";
import { CloudServiceNameAndIcon } from "./cloudServiceNameAndIcon";
import { WidgetStatus } from "../../utils/widgetStatus";

export interface ConnectionsGridRowProps {
  cloudServices: CloudServices;
  authProviders: AuthProviders;
  connection: Connection;
  actionTitle: string | undefined;
  onAction: ((connection: Connection) => void) | undefined;
}

export const ConnectionsGridRow: React.FunctionComponent<ConnectionsGridRowProps> = (props) => {
  const [showAllRoles, setShowAllRoles] = React.useState(false);

  const connection = props.connection;

  function renderRoles() {
    if (connection.roles && !connection.roles.isEmpty()) {
      const sortedRoles = connection.roles.sort();
      const first = sortedRoles.first(undefined);
      if (first !== undefined) {
        if (sortedRoles.size === 1) {
          return first;
        } else if (showAllRoles) {
          return multiline(sortedRoles.toArray());
        } else {
          return (
            <>
              <span>{first}, ...</span>
              &nbsp;
              <LinkButton onClick={() => setShowAllRoles(true)}>+{sortedRoles.size - 1} more</LinkButton>
            </>
          );
        }
      }
    }
    return "--";
  }

  function renderAction() {
    if (props.onAction) {
      if (props.actionTitle) {
        return (
          <Grid.ActionsCell>
            <Button
              color="white"
              size="small"
              disabled={connection.accessKeyStatus !== AccessKeyStatus.Valid}
              onClick={() => props.onAction && props.onAction(connection)}
            >
              {props.actionTitle}
            </Button>
          </Grid.ActionsCell>
        );
      } else {
        return (
          <Grid.ActionsCell actionType={"kebab"}>
            <KebabButton onClick={() => props.onAction && props.onAction(connection)}/>
          </Grid.ActionsCell>
        );
      }
    }
  }

  return (
    <Grid.Row>
      <Grid.Cell>{connection.id}</Grid.Cell>
      <Grid.Cell><CloudServiceNameAndIcon cloudServiceId={connection.cloudServiceId}/></Grid.Cell>
      <Grid.Cell>{connection.descriptionOrId()}</Grid.Cell>
      <Grid.Cell>{connection.eligibleForSignIn ? "Yes" : "No"}</Grid.Cell>
      <Grid.Cell
        status={connection.accessKeyStatus === AccessKeyStatus.Valid ? WidgetStatus.Normal : WidgetStatus.Error}
      >
        {connection.accessKeyStatus}
      </Grid.Cell>
      <Grid.Cell
        status={connection.accessKeyStatus === AccessKeyStatus.Invalid ? WidgetStatus.Uncertain : WidgetStatus.Normal}
      >
        {renderRoles()}
      </Grid.Cell>
      {renderAction()}
    </Grid.Row>
  );
};
