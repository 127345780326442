import * as React from "react";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { GraphQL } from "../../services/graphql/generated";
import { useServiceAccountSignInFlow } from "./useServiceAccountSignInFlow";
import { useOAuthSignInFlow } from "./useOAuthSignInFlow";
import { OAuthSignInControllerConfig } from "./oAuthSignInController";
import {
  OAuthAndServiceAccountSignInForm,
  OAuthAndServiceAccountSignInFormProps
} from "../../views/blocks/auth/oAuthAndServiceAccountSignInForm";
import { OAuthSignInForm } from "../../views/blocks/auth/oAuthSignInForm";

export interface OAuthAndServiceAccountSignInControllerConfig extends OAuthSignInControllerConfig {
  allowServiceAccountSignIn: boolean;
}

export namespace OAuthAndServiceAccountSignInControllerConfig {
  export function fromGraphQL(
    config: Omit<GraphQL.OAuthAndServiceAccountSignInFormConfigFragment, "__typename">,
    customization: GraphQL.OAuthSignInFormCustomization | undefined | null
  ): OAuthAndServiceAccountSignInControllerConfig {
    return {
      ...OAuthSignInControllerConfig.fromGraphQL(config, customization),
      allowServiceAccountSignIn: config.allowServiceAccountSignIn
    };
  }
}

interface Props extends SignInDefs.SignInComponentProps {
  config: OAuthAndServiceAccountSignInControllerConfig;
  offerAccountCreation?: boolean;
  render?: (props: OAuthAndServiceAccountSignInFormProps) => React.ReactElement;
}

export const OAuthAndServiceAccountSignInController: React.FunctionComponent<Props> = (props) => {
  const oAuthSignInFlow = useOAuthSignInFlow({
    url: props.config.url,
    callbackUrl: props.config.callbackUrl,
    vendorName: props.config.vendorName,
    verb: props.verb,
    onSignIn: props.onSignIn
  });

  const serviceAccountSignInFlow = useServiceAccountSignInFlow({
    cloudServiceId: props.cloudServiceId,
    roles: props.roles.toArray(),
    state: {}
  });

  const viewProps: OAuthAndServiceAccountSignInFormProps = {
    cloudServiceId: props.cloudServiceId,
    verb: props.verb,
    vendorName: props.config.vendorName,
    buttonCss: props.config.buttonCss,
    buttonIcon: props.config.buttonIcon,
    onSignInButtonClick: oAuthSignInFlow.fire,

    howToSwitchAccountHelpArticle: props.config.helpContent?.howToSwitchAccount,
    additionalOptions: [],

    serviceAccountSignInConfig: props.config.allowServiceAccountSignIn
      ? {
        onSignIn: (emailAddress) => {
          oAuthSignInFlow.resetStatus();
          serviceAccountSignInFlow.signIn(emailAddress).then(props.onSignIn);
        }
      }
      : undefined
  };

  return React.createElement(
    props.layoutComponent,
    {
      signInForm: props.render
        ? props.render(viewProps)
        : <OAuthAndServiceAccountSignInForm {...viewProps}/>,
      operationStatusIndicator: {
        status: !oAuthSignInFlow.status.isPending()
          ? oAuthSignInFlow.status
          : serviceAccountSignInFlow.status
      },
      helpContent: {
        ...SignInDefs.SignInHelpContent.empty,
        ...props.config.helpContent,
        footer: props.config.helpContent?.footer
      }
    });
};
