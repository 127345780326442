import * as React from "react";
import { DrawerBlock } from "views/containers/drawerBlock";
import { Button } from "../widgets/button";
import { useAppBootstrapConfig } from "../../app/configuration";
import { LinkButton } from "views/widgets/linkButton";
import { useConfigurationRoutes } from "../../app/routes/useRoutes";
import { useBrowser } from "../../utils/useBrowser";
import { Header } from "../widgets/header";

interface Props {
  onContinue: () => void;
}

export const ProgramEscape: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();
  const programRoutes = useConfigurationRoutes({ programAlias: undefined });
  const browser = useBrowser();

  return (
    <>
      <Header>{appBootstrapConfig.configuration?.escapeLinkText}</Header>
      <DrawerBlock>
        Use <strong>VaultMe for Individuals</strong> to automagically migrate from almost any other Microsoft, Google,
        or email account
      </DrawerBlock>
      <DrawerBlock>
        <Button size={"narrow"} onClick={() => browser.navigateTo(programRoutes.migrationSetup.homePath)}>
          Go to VaultMe for Individuals &nbsp;&rsaquo;
        </Button>
      </DrawerBlock>
      {appBootstrapConfig.configuration?.headerSuffix && (
        <DrawerBlock>
          or,{" "}
          <LinkButton onClick={props.onContinue}>
            return to VaultMe {appBootstrapConfig.configuration.headerSuffix}
          </LinkButton>
        </DrawerBlock>
      )}
    </>
  );
};
