import * as React from "react";
import { wizardFactory } from "../wizardry/wizard/makeWizard";
import { finishWizard } from "../wizardry/wizard/wizardDestination";
import { IntroStatus, IntroStep } from "./introStep";
import { CarouselSteps } from "../views/screens/migrationSetup/carouselStepView/carouselSteps";
import { futureStep } from "../wizardry/utils";
import { SourceAndDestination, SourceAndDestinationStep } from "./sourceAndDestinationStep";
import { of, combineLatest } from "rxjs";
import { withIndefiniteArticle } from "../utils/misc";
import { ConnectionStep, ConnectionStepResult } from "./connectionStep";
import { CloudService } from "../types/models/cloudService";
import { Connection } from "../types/models/connection";
import { MaterializedMigrationBlueprint } from "../blueprints/materializedMigrationBlueprint";
import { Blueprint } from "../blueprints/blueprint";
import { map, mergeMap } from "rxjs/operators";
import { MigrationSetupWorkflowContext } from "./migrationSetupWorkflowContext";
import { PreferencesStep, PreferencesStepResult } from "./preferencesStep";
import { CheckoutStep, CheckoutStepResult } from "./checkoutStep";
import { Facts } from "../types/facts/facts";
import { friendlyCount, friendlySize } from "../utils/formatting";
import { WorkStatus } from "../views/models/workStatus";
import { Set } from "immutable";
import { MigrationFlow, YourGoalStep } from "./goalStep";
import { FinalInterstitialStep } from "../views/screens/migrationSetup/finalInterstitialStep";
import { CheckboxesState } from "../types/models/checkboxesState";
import { MigrationSetupRoutes } from "../app/routes/migrationSetupRoutes";
import { AmbassadorsProgramStep } from "./ambassadorsProgramStep";
import { SchoolSummary } from "../types/models/school";
import * as yup from "yup";
import { ConnectionInterstitialStep } from "./connectionInterstitialStep";

export interface MigrationSetupWorkflowResult {
  message: string;
}

namespace Feedback {
  export enum Type {
    ChangeSourceCloudService = "ChangeSourceCloudService",
    ChangeDestinationCloudService = "ChangeDestinationCloudService",
  }

  interface Base<T extends Type> {
    type: T;
  }

  export interface ChangeSourceCloudService extends Base<Type.ChangeSourceCloudService> {
    cloudServiceId: string;
  }

  export function ChangeSourceCloudService(cloudServiceId: string): ChangeSourceCloudService {
    return { type: Type.ChangeSourceCloudService, cloudServiceId };
  }

  export interface ChangeDestinationCloudService extends Base<Type.ChangeDestinationCloudService> {
    cloudServiceId: string;
  }

  export function ChangeDestinationCloudService(cloudServiceId: string): ChangeDestinationCloudService {
    return { type: Type.ChangeDestinationCloudService, cloudServiceId };
  }
}

type Feedback =
  Feedback.ChangeSourceCloudService |
  Feedback.ChangeDestinationCloudService;

interface WizardProps {
  sourceCloudServiceId: string | undefined;
  destinationCloudServiceId: string | undefined;
  forceSingleMigration: boolean;
}

const factory = wizardFactory<MigrationSetupWorkflowContext, Feedback, MigrationSetupWorkflowResult, WizardProps>();

export const MigrationSetupWorkflow = factory.makeWizard({
  startFrom: (wizardProps) => Intro(wizardProps)
});

const TotalSteps = 5;

function stepsOffset(wizardProps: WizardProps): number {
  return wizardProps.forceSingleMigration ? -1 : 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Intro = factory.makeStep<WizardProps, {}, IntroStatus>({
  id: "introduction",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ workflowContext, result, hooks }) => (
    <IntroStep
      configurationIntroStep={workflowContext.configurationIntroStep}
      configurationPricingPage={workflowContext.configurationPricingPage}
      status={result || IntroStatus.Empty}
      {...hooks}
    />
  ),
  title: "Introduction",
  progress: ({ workflowContext, result }) => {
    const totalStepCount = CarouselSteps.length + (workflowContext.configurationIntroStep ? 1 : 0);
    return result && result.complete >= 0 ? (result.complete / totalStepCount * 100) : undefined;
  },
  resultSchema: IntroStatus.Schema,
  route: ({ props }) => props.forceSingleMigration ? Providers(props) : YourGoal(props),
  getFutureSteps: ({ props }) =>
    (props.forceSingleMigration ? [] : [futureStep(true, "Your Goal")]).concat([
      futureStep(true, "Account Types"),
      futureStep(true, "Migrate From"),
      futureStep(true, "Migrate To"),
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ])
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const YourGoal = factory.makeStep<WizardProps, {}, MigrationFlow>({
  id: "goal",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ result, hooks }) => (
    <YourGoalStep
      step={1}
      totalSteps={TotalSteps}
      type={result?.type}
      {...hooks}
    />
  ),
  title: "Your Goal",
  numbered: true,
  description: ({ result  }) => result && (
    result.type === "single"
      ? "Migrate a single account"
      : "Migrate multiple accounts"
  ),
  resultSchema: MigrationFlow.Schema,
  route: ({ props, result }) =>
    result.type === "single"
      ? Providers(props)
      : finishWizard({ message: "Redirect to VaultMe for Admin" }),
  getFutureSteps: ({ result }) =>
    !result || result.type === "single"
      ? [
        futureStep(true, "Account Types"),
        futureStep(true, "Migrate From"),
        futureStep(true, "Migrate To"),
        futureStep(true, "Preferences"),
        futureStep(false, "Confirm & Go"),
      ]
      : [
        futureStep(
          false,
          "... To Be Continued",
          "Setup will continue in VaultMe for Admins"
        )
      ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Providers {
  export interface ResultContext {
    blueprint: Blueprint | undefined;
  }
}

const Providers = factory.makeStep<WizardProps, {}, SourceAndDestination, Providers.ResultContext>({
  id: "types",
  propsContext: () => ({}),
  resultContext: ({ workflowContext, result }) => {
    if (result.sourceCloudServiceId && result.destinationCloudServiceId) {
      return workflowContext.loadBlueprint(result.sourceCloudServiceId, result.destinationCloudServiceId)
        .pipe(map((blueprint) => ({ blueprint })));
    } else {
      return of({ blueprint: undefined });
    }
  },
  render: ({ props, result, resultContext, hooks }) => (
    <SourceAndDestinationStep
      step={2 + stepsOffset(props)}
      totalSteps={TotalSteps + stepsOffset(props)}
      predefinedSourceCloudServiceId={props.sourceCloudServiceId}
      predefinedDestinationCloudServiceId={props.destinationCloudServiceId}
      sourceCloudServiceId={result && result.sourceCloudServiceId}
      destinationCloudServiceId={result && result.destinationCloudServiceId}
      blueprint={resultContext && resultContext.blueprint}
      {...hooks}
    />
  ),
  title: "Account Types",
  numbered: true,
  description: ({ workflowContext, result, resultContext }) =>
    result && result.sourceCloudServiceId && result.destinationCloudServiceId && resultContext &&
    "Migrate from " +
    withIndefiniteArticle(
      workflowContext.sourceCloudServices.getOrFail(result.sourceCloudServiceId).reference
    ) +
    " to " +
    withIndefiniteArticle(
      workflowContext.destinationCloudServices.getOrFail(result.destinationCloudServiceId).reference
    ),
  progress: ({ result }) => result && (!result.sourceCloudServiceId || !result.destinationCloudServiceId)
    ? 50
    : undefined,
  resultSchema: SourceAndDestination.Schema,
  route: ({ workflowContext, props, result, resultContext }) => {
    if (result.sourceCloudServiceId && result.destinationCloudServiceId && resultContext.blueprint) {
      return SourceConnection({
        stepsOffset: stepsOffset(props),
        sourceCloudService: workflowContext.sourceCloudServices.getOrFail(result.sourceCloudServiceId),
        destinationCloudService: workflowContext.destinationCloudServices.getOrFail(result.destinationCloudServiceId),
        blueprint: resultContext.blueprint
      });
    }
  },
  processFeedback: ({ feedback, result }) => {
    if (result) {
      switch (feedback.type) {
        case Feedback.Type.ChangeSourceCloudService:
          return { ...result, sourceCloudServiceId: feedback.cloudServiceId };
        case Feedback.Type.ChangeDestinationCloudService:
          return { ...result, destinationCloudServiceId: feedback.cloudServiceId };
      }
    }
  },
  getFutureSteps: () => [
    futureStep(true, "Migrate From"),
    futureStep(true, "Migrate To"),
    futureStep(true, "Preferences"),
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace SourceConnection {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
  }

  export interface ResultContext {
    connection: Connection | undefined;
    school: SchoolSummary | undefined;
    facts: Facts | undefined;
    isEligibleForCurrentProgram: boolean;
  }
}

namespace SourceConnectionUtils {
  export function migrationBlueprint(
    workflowContext: MigrationSetupWorkflowContext,
    props: SourceConnection.Props,
    resultContext: SourceConnection.ResultContext | undefined
  ): MaterializedMigrationBlueprint {
    return MaterializedMigrationBlueprint.build({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprint: props.blueprint,
      cloudServices: workflowContext.cloudServices,
      authProviders: workflowContext.authProviders,
      sourceConnection: resultContext && resultContext.connection,
      destinationConnection: undefined,
      facts: resultContext && resultContext.facts || Facts.Empty,
      excludedAreas: []
    });
  }
}

const SourceConnection = factory.makeStep<
  SourceConnection.Props,
  {},
  ConnectionStepResult,
  SourceConnection.ResultContext>(
  {
    id: MigrationSetupRoutes.source,
    propsKey: (props) => ({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprintId: props.blueprint.id()
    }),
    propsContext: () => ({}),
    resultContext: ({ workflowContext, props, result }) =>
      workflowContext
        .loadConnection(result.connectionId)
        .pipe(
          mergeMap((connection) => {
            const tempResultContext: SourceConnection.ResultContext = {
              // If cloud service has been changed on previous step, this result is no longer valid
              connection: connection.cloudServiceId === props.sourceCloudService.id ? connection : undefined,
              school: undefined,
              facts: undefined,
              isEligibleForCurrentProgram: true
            };

            return combineLatest([
              workflowContext.sessionObservable,
              workflowContext
                .loadFacts(SourceConnectionUtils.migrationBlueprint(workflowContext, props, tempResultContext)),
              workflowContext.getSourceConnectionPromotions(
                connection.id,
                props.destinationCloudService.id,
                undefined
              )
            ]).pipe(
              map(([session, facts, { isEligibleForCurrentProgram, school }]) => ({
                ...tempResultContext,
                school,
                facts,
                isEligibleForCurrentProgram,
              }))
            );
          }),
        ),
    progress: ({ workflowContext, props, resultContext }) => {
      // TODO Consider using MaterializedMigrationBlueprint.sourceStatus()
      const blueprint = SourceConnectionUtils.migrationBlueprint(workflowContext, props, resultContext);
      const statuses = blueprint.listAreas().map(([area]) => area.state(blueprint.context).toWorkStatus());
      return statuses.contains(WorkStatus.Working) ? -1 : undefined;
    },
    errorIndicator: ({ workflowContext, props, resultContext }) => {
      // TODO Consider using MaterializedMigrationBlueprint.sourceStatus()
      const blueprint = SourceConnectionUtils.migrationBlueprint(workflowContext, props, resultContext);
      const statuses = blueprint.listAreas().map(([area]) => area.state(blueprint.context).toWorkStatus());
      return statuses.contains(WorkStatus.Issue) ||
      statuses.contains(WorkStatus.Failure) ||
      (resultContext && !resultContext.isEligibleForCurrentProgram)
        ? "Something went wrong"
        : undefined;
    },
    render: ({ workflowContext, props, resultContext, hooks }) => (
      <ConnectionStep
        step={3 + props.stepsOffset}
        totalSteps={TotalSteps + props.stepsOffset}
        source={true}
        cloudService={props.sourceCloudService}
        oppositeCloudService={props.destinationCloudService}
        blueprint={SourceConnectionUtils.migrationBlueprint(workflowContext, props, resultContext)}
        connection={resultContext && resultContext.connection}
        oppositeConnection={undefined}
        onCloudServiceChange={(cloudServiceId) => hooks.onFeedback(Feedback.ChangeSourceCloudService(cloudServiceId))}
        {...hooks}
      />
    ),
    title: "Migrate From",
    numbered: true,
    description: ({ props, resultContext }) => resultContext && resultContext.connection
      ? props.sourceCloudService.name + "\n" + resultContext.connection.descriptionOrId()
      : undefined,
    resultSchema: ConnectionStepResult.Schema,
    validateResult: ({ resultContext }) => !!(resultContext && resultContext.connection),
    route: ({ props, resultContext }) =>
      resultContext.connection && resultContext.facts && resultContext.isEligibleForCurrentProgram
        ? ConnectionInterstitial({
          ...props,
          sourceConnection: resultContext.connection,
          school: resultContext.school,
          facts: resultContext.facts
        })
        : undefined,
    getFutureSteps: () => [
      futureStep(true, "Migrate To"),
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ]
  });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ConnectionInterstitial = factory.makeInterstitialStep<DestinationConnection.Props>({
  id: "source-connected",
  renderInterstitial: ({ props, hooks }) => (
    <ConnectionInterstitialStep
      step={3 + props.stepsOffset}
      totalSteps={TotalSteps + props.stepsOffset}

      sourceCloudService={props.sourceCloudService}
      sourceConnection={props.sourceConnection}
      destinationCloudService={props.destinationCloudService}

      {...hooks}
    />
  ),
  route: ({ props }) => DestinationConnection(props),
  getFutureSteps: () => [
    futureStep(true, "Migrate To"),
    futureStep(true, "Preferences"),
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace DestinationConnection {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    school: SchoolSummary | undefined;
    facts: Facts;
  }

  export interface ResultContext {
    connection: Connection | undefined;
    facts: Facts | undefined;
    isEligibleForCurrentProgram: boolean;
  }
}

namespace DestinationConnectionUtils {
  export function migrationBlueprint(
    workflowContext: MigrationSetupWorkflowContext,
    props: DestinationConnection.Props,
    resultContext: DestinationConnection.ResultContext | undefined
  ): MaterializedMigrationBlueprint {
    return MaterializedMigrationBlueprint.build({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprint: props.blueprint,
      cloudServices: workflowContext.cloudServices,
      authProviders: workflowContext.authProviders,
      sourceConnection: props.sourceConnection,
      destinationConnection: resultContext && resultContext.connection,
      facts: resultContext && resultContext.facts || props.facts,
      excludedAreas: []
    });
  }
}

const DestinationConnection = factory.makeStep<
  DestinationConnection.Props,
  {},
  ConnectionStepResult,
  DestinationConnection.ResultContext>(
  {
    id: "destination",
    propsKey: (props) => ({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprintId: props.blueprint.id(),
      sourceConnectionId: props.sourceConnection.id,
      // The list of roles affects the list of unblocked facts and therefore must be included here to ensure that the
      // subsequent steps update when new roles are added. See #1k4wun7 (Scanning indicator is spinning indefinitely
      // when access to Outlook is authorized on the Preferences step).
      sourceConnectionRoles: props.sourceConnection.roles?.toArray()
    }),
    propsContext: () => ({}),
    resultContext: ({ workflowContext, props, result }) =>
      workflowContext
        .loadConnection(result.connectionId)
        .pipe(
          mergeMap((connection) => {
            const tempResultContext: DestinationConnection.ResultContext = {
              // If cloud service has been changed on previous destinationCloudService, this result is no longer valid
              connection: connection.cloudServiceId === props.destinationCloudService.id ? connection : undefined,
              facts: undefined,
              isEligibleForCurrentProgram: true
            };

            return combineLatest([
              workflowContext
                .loadFacts(DestinationConnectionUtils.migrationBlueprint(workflowContext, props, tempResultContext)),
              workflowContext.getSourceConnectionPromotions(
                props.sourceConnection.id,
                connection.cloudServiceId,
                connection.id
              )
            ]).pipe(
              map(([facts, { isEligibleForCurrentProgram }]) => ({
                ...tempResultContext, facts, isEligibleForCurrentProgram
              }))
            );
          }),
        ),
    progress: ({ workflowContext, props, resultContext }) => {
      // TODO Consider using MaterializedMigrationBlueprint.destinationStatus()
      const blueprint = DestinationConnectionUtils.migrationBlueprint(workflowContext, props, resultContext);
      const statuses = blueprint.listSinks().map(([sink]) => sink.state(blueprint.context).toWorkStatus());
      return statuses.contains(WorkStatus.Working) ? -1 : undefined;
    },
    errorIndicator: ({ workflowContext, props, resultContext }) => {
      if (resultContext?.connection?.id === props.sourceConnection.id) {
        return "Source and destination accounts must be different";
      } else {
        // TODO Consider using MaterializedMigrationBlueprint.destinationStatus()
        const blueprint = DestinationConnectionUtils.migrationBlueprint(workflowContext, props, resultContext);
        const statuses = blueprint.listSinks().map(([sink]) => sink.state(blueprint.context).toWorkStatus());
        return statuses.contains(WorkStatus.Issue) ||
        statuses.contains(WorkStatus.Failure) ||
        (resultContext && !resultContext.isEligibleForCurrentProgram)
          ? "Something went wrong"
          : undefined;
      }
    },
    render: ({ workflowContext, props, resultContext, hooks }) => (
      <ConnectionStep
        step={4 + props.stepsOffset}
        totalSteps={TotalSteps + props.stepsOffset}
        source={false}
        cloudService={props.destinationCloudService}
        oppositeCloudService={props.sourceCloudService}
        blueprint={DestinationConnectionUtils.migrationBlueprint(workflowContext, props, resultContext)}
        connection={resultContext && resultContext.connection}
        oppositeConnection={props.sourceConnection}
        onCloudServiceChange={(cloudServiceId) =>
          hooks.onFeedback(Feedback.ChangeDestinationCloudService(cloudServiceId))
        }
        {...hooks}
      />
    ),
    title: "Migrate To",
    numbered: true,
    description: ({ props, resultContext }) => resultContext && resultContext.connection
      ? props.destinationCloudService.name + "\n" + resultContext.connection.descriptionOrId()
      : undefined,
    resultSchema: ConnectionStepResult.Schema,
    validateResult: ({ props, resultContext }) => !!(resultContext && resultContext.connection),
    route: ({ props, resultContext }) =>
      resultContext.connection &&
      resultContext.connection.id !== props.sourceConnection.id &&
      resultContext.isEligibleForCurrentProgram
        ? Preferences({
          ...props,
          destinationConnection: resultContext.connection,
          facts: resultContext.facts || Facts.Empty
        })
        : undefined,
    getFutureSteps: () => [
      futureStep(true, "Preferences"),
      futureStep(false, "Confirm & Go"),
    ]
  });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Preferences {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    destinationConnection: Connection;
    school: SchoolSummary | undefined;
    facts: Facts;
  }
}

namespace PreferencesUtils {
  export function migrationBlueprint(
    workflowContext: MigrationSetupWorkflowContext,
    props: Preferences.Props,
    excludedAreas: string[]
  ): MaterializedMigrationBlueprint {
    return MaterializedMigrationBlueprint.build({
      sourceCloudServiceId: props.sourceCloudService.id,
      destinationCloudServiceId: props.destinationCloudService.id,
      blueprint: props.blueprint,
      cloudServices: workflowContext.cloudServices,
      authProviders: workflowContext.authProviders,
      sourceConnection: props.sourceConnection,
      destinationConnection: props.destinationConnection,
      facts: props.facts,
      excludedAreas: Set(excludedAreas).concat(props.sourceCloudService.excludedApps).toArray()
    });
  }
}

const Preferences = factory.makeStep<Preferences.Props, {}, PreferencesStepResult, {}>({
  id: "preferences",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ workflowContext, props, result, hooks }) => (
    <PreferencesStep
      step={5 + props.stepsOffset}
      totalSteps={TotalSteps + props.stepsOffset}

      sourceCloudService={props.sourceCloudService}
      destinationCloudService={props.destinationCloudService}
      blueprint={PreferencesUtils.migrationBlueprint(workflowContext, props, result && result.excluded || [])}
      sourceConnection={props.sourceConnection}
      destinationConnection={props.destinationConnection}

      excluded={result && result.excluded || []}
      suppressedActionItemIds={result && result.suppressedActionItemIds || []}

      {...hooks}
    />
  ),
  title: "Preferences",
  numbered: true,
  description: ({ workflowContext, props, result }) => {
    const totals = PreferencesUtils
      .migrationBlueprint(workflowContext, props, result && result.excluded || [])
      .migrationTotals();
    if (totals.areas.isEmpty()) {
      return "Nothing's selected";
    } else {
      return "Include " + friendlyCount(totals.itemCount, "item") + "\n" +
        (totals.size === 0 ? "Zero GB" : friendlySize(totals.size)) + " of " +
        totals.areas.join(", ");
    }
  },
  resultSchema: PreferencesStepResult.Schema,
  errorIndicator: ({ workflowContext, props, result }) =>
    result &&
    PreferencesUtils.migrationBlueprint(workflowContext, props, result.excluded).migrationBlocker() ===
    MaterializedMigrationBlueprint.MigrationBlocker.PendingIssues
      ? "Unresolved issues"
      : undefined,
  route: ({ workflowContext, props, result }) => {
    const blueprint = PreferencesUtils.migrationBlueprint(workflowContext, props, result.excluded);
    return blueprint.migrationBlocker()
      ? undefined
      : FinalInterstitial({
        ...props,
        // The list of the areas explicitly disabled by the user is extended here with the list of blocked areas,
        // so that we don't have to guess about the user's expectations on the subsequent steps
        excludedAreas: Set(result.excluded)
          .concat(props.sourceCloudService.excludedApps)
          .concat(blueprint.listBlockedAreaIds())
          .toArray()
      });
  },
  getFutureSteps: () => [
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace FinalInterstitial {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    destinationConnection: Connection;
    school: SchoolSummary | undefined;
    facts: Facts;
    excludedAreas: string[];
  }
}

const FinalInterstitial = factory.makeStep<FinalInterstitial.Props, {}, CheckboxesState, {}>({
  id: "ready",
  propsContext: () => ({}),
  resultContext: () => ({}),
  resultSchema: CheckboxesState.schema,
  // Returning false here will wipe out the result completely, so the user will have to start from scratch.
  // But it's okay in this scenario.
  validateResult: ({ workflowContext, result }) => {
    const configuration = workflowContext.appBootstrapConfig?.configuration;
    if (configuration) {
      return (!configuration.checkbox1Label || !configuration.checkbox1Required || result.checkbox1Checked) &&
        configuration.checkbox1Label === result.checkbox1Label &&
        (!configuration.checkbox2Label || !configuration.checkbox2Required || result.checkbox2Checked) &&
        configuration.checkbox2Label === result.checkbox2Label &&
        (!configuration.checkbox3Label || !configuration.checkbox3Required || result.checkbox3Checked) &&
        configuration.checkbox3Label === result.checkbox3Label;
    } else {
      return true;
    }
  },
  hideFromNavigation: () => true,
  skipWhen: ({ workflowContext, previousStepCompletedOrUpdated }) =>
    !workflowContext.appBootstrapConfig?.configuration?.hasCheckboxes &&
    previousStepCompletedOrUpdated,
  render: ({ workflowContext, props, result, hooks }) => (
    <FinalInterstitialStep
      sourceCloudService={props.sourceCloudService}
      sourceConnection={props.sourceConnection}
      school={props.school}
      migrationTotals={
        PreferencesUtils.migrationBlueprint(workflowContext, props, props.excludedAreas).migrationTotals()
      }

      destinationCloudService={props.destinationCloudService}
      destinationConnection={props.destinationConnection}

      result={result}

      {...hooks}
    />
  ),
  route: ({ props, result }) =>
    props.school
      ? AmbassadorProgram({ ...props, school: props.school, checkboxesState: result })
      : Checkout({ ...props, checkboxesState: result }),
  getFutureSteps: () => [
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace AmbassadorProgram {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    destinationConnection: Connection;
    school: SchoolSummary;
    facts: Facts;
    excludedAreas: string[];
    checkboxesState: CheckboxesState;
  }
}

const AmbassadorProgram = factory.makeStep<AmbassadorProgram.Props, {}, {}, {}>({
  id: "edu-ambassador",
  propsContext: () => ({}),
  resultContext: () => ({}),
  hideFromNavigation: () => true,
  render: ({ props, hooks }) => (
    <AmbassadorsProgramStep
      school={props.school}
      sourceConnection={props.sourceConnection}
      destinationConnection={props.destinationConnection}
      onComplete={() => hooks.onComplete({})}
      onNavigateBack={hooks.onNavigateBack}
    />
  ),
  title: "Ambassadors Program",
  resultSchema: yup.mixed(),
  route: ({ props }) => Checkout(props),
  getFutureSteps: () => [
    futureStep(false, "Confirm & Go"),
  ]
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace Checkout {
  export interface Props {
    stepsOffset: number;
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    destinationConnection: Connection;
    school: SchoolSummary | undefined;
    facts: Facts;
    excludedAreas: string[];
    checkboxesState: CheckboxesState;
  }
}

const Checkout = factory.makeStep<Checkout.Props, {}, CheckoutStepResult, {}>({
  id: "checkout",
  propsContext: () => ({}),
  resultContext: () => ({}),
  render: ({ workflowContext, props, result, hooks }) => (
    <CheckoutStep
      sourceCloudService={props.sourceCloudService}
      destinationCloudService={props.destinationCloudService}
      blueprint={PreferencesUtils.migrationBlueprint(workflowContext, props, props.excludedAreas)}
      sourceConnection={props.sourceConnection}
      destinationConnection={props.destinationConnection}
      checkboxesState={props.checkboxesState}
      {...hooks}
    />
  ),
  title: "Confirm & Go",
  resultSchema: CheckoutStepResult.Schema,
  route: ({ result }) => finishWizard({ message: result.something }),
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

namespace WorkflowPreview {
  export interface Props {
    sourceCloudService: CloudService;
    destinationCloudService: CloudService;
    blueprint: Blueprint;
    sourceConnection: Connection;
    destinationConnection: Connection;
    facts: Facts;
    excludedAreas: string[];
  }
}
