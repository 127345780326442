import * as React from "react";
import { AmbassadorDashboardPageView } from "../views/screens/ambassadorDashboardPageView";
import { useRoutes } from "../app/routes/useRoutes";
import { useSessionOrFail } from "../utils/useAppState";
import { useGetAmbassadorStatusQuery } from "../queries/users/useGetAmbassadorStatusQuery";

export const MyAmbassadorDashboardPage: React.FunctionComponent = () => {
  const session = useSessionOrFail();

  return <AmbassadorDashboardPage userId={session.user.id}/>;
};

export const OtherAmbassadorDashboardPage: React.FunctionComponent = () => {
  const routes = useRoutes().ambassadors;
  const params = routes.otherAmbassadorDashboardParams();

  return <AmbassadorDashboardPage userId={params.userId}/>;
};

interface AmbassadorDashboardPageProps {
  userId: string;
}

const AmbassadorDashboardPage: React.FunctionComponent<AmbassadorDashboardPageProps> = (props) => {
  const [loadingStatus] = useGetAmbassadorStatusQuery(props.userId);

  return <AmbassadorDashboardPageView loadingStatus={loadingStatus}/>;
};
