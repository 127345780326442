import { Map } from "immutable";
import { Option } from "../utils/monads/option";
import { GraphQL } from "../services/graphql/generated";

export class BlueprintInputs {
  public constructor(public readonly values: Map<string, string>) {
  }

  public equals(other: BlueprintInputs): boolean {
    return this.values.equals(other.values);
  }

  public get(key: string): Option<string> {
    return Option.mayBe(this.values.get(key, undefined));
  }

  public fingerprint(): string {
    return this.values
      .toSeq()
      .sortBy((value, key) => key)
      .map((value, key) => key + "=" + value)
      .join();
  }

  public toGraphQL(): GraphQL.BlueprintInputsEntryInput[] {
    return this.values
      .map((value, key) => ({ key, value }))
      .valueSeq()
      .toArray();
  }

  public merge(overrides: Map<string, string>): BlueprintInputs {
    return new BlueprintInputs(this.values.merge(overrides));
  }
}

export namespace BlueprintInputs {
  export const Empty = new BlueprintInputs(Map());

  export function build(entries: [string, string][]): BlueprintInputs {
    return new BlueprintInputs(Map(entries.filter((entry) => entry[1] !== undefined)));
  }
}
