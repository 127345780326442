import { Anchor } from "./anchor";

export function futureStep(numbered: boolean, title: string, description?: string): Anchor {
  return {
    path: title,
    numbered,
    title,
    description,
    result: undefined,
    started: false,
    complete: false,
    unlocked: false,
    progress: undefined,
    errorIndicator: undefined
  };
}
