import * as React from "react";
import { useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { Iteration } from "../../types/models/iteration";
import { List } from "immutable";
import { Migration } from "../../types/models/migration";
import { MigrationStatus } from "../../types/models/migrationStatus";
import { usePrevious } from "../../utils/usePrevious";
import {
  IterationsHistoryControllerProps,
  IterationsHistoryControllerType
} from "../../views/screens/migrationStatusPageView/components/iterationToolsRow";

// [Tim] I'm really lazy and don't want ti implement push updates for iterations. Therefore, I'll refresh the list
// on every pop-up, and also when current iteration or status changes.
function useIterationsHistoryQuery(migrationId: string, latestIterationIndex: number, isRunning: boolean) {
  const [status, refetch] = useManagedQuery({
    query: GraphQL.useMigrationIterationsQuery,
    deps: null,
    prepare: () => ({ migrationId }),
    extract: (data: GraphQL.MigrationIterationsQuery) => data.iterations,
    complete: (extract) => List(extract).map(Iteration.parseCore),
    fetchPolicy: "cache-and-network"
  });

  const previousMigrationId = usePrevious(migrationId);

  React.useEffect(
    () => {
      if (previousMigrationId === migrationId) {
        refetch({}, true);
      }
    },
    [latestIterationIndex, isRunning]
  );

  return status;
}

interface Props extends IterationsHistoryControllerProps {
  migrationId: string;
  latestIterationIndex: number;
  isRunning: boolean;
}

export const IterationsHistoryController: React.FunctionComponent<Props> = (props) => {
  const status = useIterationsHistoryQuery(props.migrationId, props.latestIterationIndex, props.isRunning);
  return props.render({ status });
};

export function useIterationsHistoryController(migration: Migration): IterationsHistoryControllerType {
  return React.useCallback(
    (controllerProps: IterationsHistoryControllerProps) => (
      <IterationsHistoryController
        {...controllerProps}
        migrationId={migration.id}
        latestIterationIndex={migration.iteration}
        isRunning={migration.status === MigrationStatus.Completed}
      />
    ),
    [migration.id, migration.iteration, migration.status === MigrationStatus.Completed]
  );
}
