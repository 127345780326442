import * as React from "react";
import { Grid } from "../../widgets/grid";
import { List } from "immutable";
import { MigrationStatus } from "../../../types/models/migrationStatus";
import { WidgetStatus } from "../../utils/widgetStatus";
import { Migration } from "../../../types/models/migration";
import {
  CloudServiceNameAndIcon,
  ConnectionIdAndIcon
} from "../../blocks/connectionManagement/cloudServiceNameAndIcon";
import { briefDateTime, friendlyCount, friendlyDuration, friendlySize } from "../../../utils/formatting";
import { Link } from "react-router-dom";
import { Batch } from "../../../types/models/batch";
import { UserMigrations } from "../../../types/models/userMigrations";
import { useRoutes } from "../../../app/routes/useRoutes";

interface UserMigrationsGridProps {
  userMigrations: UserMigrations;
}

export const UserMigrationsGrid: React.FunctionComponent<UserMigrationsGridProps> = (props) => (
  <MigrationsGridBody displayType={true}>
    {
      props.userMigrations.migrations.map((migration) => ({
        row: (
          <MigrationRow
            key={"migration-" + migration.id}
            migration={migration}
            displayType={true}
          />
        ),
        createdAt: migration.createdAt
      }))
        .concat(
          props.userMigrations.batches.map((batch) => ({
            row: (
              <BatchRow
                key={"batch-" + batch.id}
                batch={batch}
                displayType={true}
              />
            ),
            createdAt: batch.createdAt
          }))
        )
        .sortBy((item) => -item.createdAt.getTime())
        .map(({ row }) => row)
    }
  </MigrationsGridBody>
);

interface MigrationsGridProps {
  migrations: List<Migration & Migration.HasConnections>;
}

export const MigrationsGrid: React.FunctionComponent<MigrationsGridProps> = (props) => (
  <MigrationsGridBody>
    {props.migrations.map((migration) => <MigrationRow key={"migration-" + migration.id} migration={migration}/>)}
  </MigrationsGridBody>
);

interface MigrationsGridBodyProps {
  displayType?: boolean;
}

const MigrationsGridBody: React.FunctionComponent<MigrationsGridBodyProps> = (props) => (
  <Grid>
    <Grid.Header>
      <Grid.Column>ID</Grid.Column>
      {props.displayType && <Grid.Column>Type</Grid.Column>}
      <Grid.Column>Name</Grid.Column>
      <Grid.Column>Source</Grid.Column>
      <Grid.Column>Destination</Grid.Column>
      <Grid.Column>Size</Grid.Column>
      <Grid.Column>Status</Grid.Column>
      <Grid.Column>Started At</Grid.Column>
      <Grid.Column>Completed At / Remaining</Grid.Column>
      <Grid.Column>Synced Up At</Grid.Column>
    </Grid.Header>
    <Grid.Body>{props.children}</Grid.Body>
  </Grid>
);

function widgetStatus(status: MigrationStatus): WidgetStatus | undefined {
  if (status === MigrationStatus.Scheduled) {
    return WidgetStatus.Uncertain;
  } else if (status === MigrationStatus.Completed) {
    return WidgetStatus.Success;
  }
}

interface MigrationRowProps {
  migration: Migration & Migration.HasConnections;
  displayType?: boolean;
}

const MigrationRow: React.FunctionComponent<MigrationRowProps> = (props) => {
  const routes = useRoutes();

  function renderTimingCells(migration: Migration) {
    const timing = migration.timing;

    if (timing instanceof Migration.CompletedTiming) {
      return (
        <>
          <Grid.Cell>{briefDateTime(timing.startedAt)}</Grid.Cell>
          <Grid.Cell>
            {
              briefDateTime(timing.completedAt) +
              " (in " + friendlyDuration(timing.completedIn) + ")"
            }
          </Grid.Cell>
        </>
      );
    } else if (timing instanceof Migration.RunningTiming) {
      return (
        <>
          <Grid.Cell>{briefDateTime(timing.startedAt)}</Grid.Cell>
          <Grid.Cell>
            {
              friendlyDuration(
                timing.optimisticRemainingTimeOption !== undefined
                  ? timing.optimisticRemainingTimeOption
                  : timing.timeEstimate
              ) +
              " (" + friendlyDuration(timing.elapsedTime) + " elapsed)"
            }
          </Grid.Cell>
        </>
      );
    } else if (timing instanceof Migration.ScheduledTiming) {
      return (
        <>
          {/*<Grid.Cell status={WidgetStatus.Uncertain}>{briefDateTime(timing.scheduledAt)}</Grid.Cell>*/}
          <Grid.Cell>--</Grid.Cell>
          <Grid.Cell>Est. {friendlyDuration(migration.timeEstimate)}</Grid.Cell>
        </>
      );
    }
  }

  return (
    <Grid.Row>
      <Grid.Cell>
        <Link to={routes.migrations.migrationStatusPath(props.migration.id)}>{props.migration.id}</Link>
      </Grid.Cell>
      {props.displayType && <Grid.Cell>Single account</Grid.Cell>}
      <Grid.Cell/>
      <Grid.Cell><ConnectionIdAndIcon connection={props.migration.sourceConnection}/></Grid.Cell>
      <Grid.Cell><ConnectionIdAndIcon connection={props.migration.destinationConnection}/></Grid.Cell>
      <Grid.Cell>
        {
          friendlyCount(
            props.migration.cumulativeStats.totalItems || props.migration.totalItemsEstimate,
            "item"
          ) +
          " / " +
          friendlySize(props.migration.totalBytesEstimate)
        }
      </Grid.Cell>
      <Grid.ProgressCell
        status={widgetStatus(props.migration.status)}
        progress={props.migration.status !== MigrationStatus.Completed ? props.migration.progress : 100}
      >
        {
          MigrationStatus.userFriendly(props.migration.status, props.migration.iteration) + (
            props.migration.status !== MigrationStatus.Scheduled && props.migration.status !== MigrationStatus.Completed
              ? " (" + props.migration.progress.toFixed(1) + "% complete)"
              : ""
          )
        }
      </Grid.ProgressCell>
      {renderTimingCells(props.migration)}
      <Grid.Cell>
        {props.migration.iterationCompletedAt ? briefDateTime(props.migration.iterationCompletedAt) : "--"}
      </Grid.Cell>
    </Grid.Row>
  );
};

interface BatchRowProps {
  batch: Batch;
  displayType?: boolean;
}

const BatchRow: React.FunctionComponent<BatchRowProps> = (props) => {
  const routes = useRoutes();
  return (
    <Grid.Row>
      <Grid.Cell><Link to={routes.batches.path(props.batch.id)}>{props.batch.id}</Link></Grid.Cell>
      {props.displayType && <Grid.Cell>Batch</Grid.Cell>}
      <Grid.Cell status={props.batch.name ? undefined : WidgetStatus.Uncertain}>
        {props.batch.name || "Untitled batch"}
      </Grid.Cell>
      <Grid.Cell><CloudServiceNameAndIcon cloudServiceId={props.batch.sourceCloudServiceId}/></Grid.Cell>
      <Grid.Cell><CloudServiceNameAndIcon cloudServiceId={props.batch.destinationCloudServiceId}/></Grid.Cell>
      <Grid.Cell>
        {
          friendlyCount(props.batch.batchOrder.totalAccounts, "account") +
          " / " +
          friendlyCount(props.batch.batchOrder.totalItems, "item") +
          " / " +
          friendlySize(props.batch.batchOrder.totalBytes)
        }
      </Grid.Cell>
      <Grid.ProgressCell
        status={widgetStatus(props.batch.status)}
        progress={props.batch.status === MigrationStatus.Completed ? 100 : 0}
      >
        {MigrationStatus.userFriendly(props.batch.status, 0)}
      </Grid.ProgressCell>
      <Grid.Cell>{props.batch.startedAt ? briefDateTime(props.batch.startedAt) : "--"}</Grid.Cell>
      <Grid.Cell>{props.batch.completedAt ? briefDateTime(props.batch.completedAt) : "--"}</Grid.Cell>
      <Grid.Cell>--</Grid.Cell>
    </Grid.Row>
  );
};
