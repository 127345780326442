import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { AccessList } from "../../types/models/accessList";

export function useGetAccessListQuery(id: number): ManagedQueryHook<GraphQL.GetAccessListQueryVariables, AccessList> {
  return useManagedQuery({
    query: GraphQL.useGetAccessListQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetAccessListQuery) => nullToUndefined(data.accessList),
    complete: (accessList) => AccessList.parse(accessList),
    fetchPolicy: "cache-and-network"
  });
}
