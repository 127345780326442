import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../state/appState";
import { Session } from "../types/models/session";
import { AdminToolsMode, SettingsState } from "../state/settings/state";
import { BehaviorSubject, Observable } from "rxjs";
import { ToursState } from "../state/tours/state";

export function useAppState<TSelected>(
  selector: (state: AppState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return useSelector<AppState, TSelected>(selector, equalityFn);
}

export function useSession(): Session | undefined {
  return useAppState((state) => state.session);
}

export function useSessionOrFail(): Session {
  const session = useSession();
  if (session) {
    return session;
  } else {
    throw new Error("No session is available");
  }
}

export function useElevated(): boolean {
  return useAppState((state) => state.session !== undefined && state.session.elevated);
}

export function useSettings(): SettingsState {
  return useAppState((state) => state.settings);
}

export function useAdminToolsVisibility(alwaysShow?: boolean): boolean {
  const elevated = useElevated();
  const hideAdminTools = useSettings().adminToolsMode === AdminToolsMode.Hidden && !alwaysShow;
  return elevated && !hideAdminTools;
}

export function useTours(): ToursState {
  return useAppState((state) => state.tours);
}

export function useSessionEffect(f: (session: Session | undefined) => void): void {
  const session = useSession();
  React.useEffect(
    () => f(session),
    [session?.user.id, session?.user.ambassadorsProgramMembership?.code, session?.elevated]
  );
}

export function useSessionObservable(): Observable<Session | undefined> {
  const session = useSession();
  const subject = React.useRef(new BehaviorSubject<Session | undefined>(session));
  useSessionEffect((s) => subject.current.next(s));
  return subject.current.asObservable();
}
