import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { OrganizationPortalUserFormData } from "../../types/models/organizationPortalUserFormData";

type InsertOrganizationPortalUserMutationHook = [
  (formData: OrganizationPortalUserFormData) => Promise<OrganizationPortalUser>,
  OperationStatus<OrganizationPortalUser>
];

export function useInsertOrganizationPortalUserMutation(
  organizationId: number
): InsertOrganizationPortalUserMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertOrganizationPortalUserMutation,
    extract: (data: GraphQL.InsertOrganizationPortalUserMutation) => nullToUndefined(data.insertOrganizationPortalUser),
    complete: OrganizationPortalUser.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This email address is already registered" })
      ]
    ]
  });

  function fireWith(formData: OrganizationPortalUserFormData): Promise<OrganizationPortalUser> {
    return fire({
      variables: { organizationId, formData: OrganizationPortalUserFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
