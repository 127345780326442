import * as React from "react";
import { SimpleWorkflowNavigationItem, WorkflowNavigation } from "../../layouts/workflowNavigation";
import { WorkflowTitle } from "../../layouts/workflowTitle";
import { useRoutes } from "../../../app/routes/useRoutes";
import { OrganizationPortalRoutes } from "../../../app/routes/organizationPortalRoutes";
import { OrganizationSummary } from "../../../types/models/organization";
import { styled } from "../../../app/theme";
import { Constants } from "../../../app/constants";
import { OrganizationPortalUserRole } from "../../../types/enums/organizationPortalUserRole";
import { useNavigate } from "react-router";
import { useElevated } from "../../../utils/useAppState";

export enum AdminPortalSectionId {
  AccessLists = "AccessLists",
  AutomatedOffboarding = "AutomatedOffboarding",
  Configurations = "Configurations",
  Dashboard = "Dashboard",
  Migrations = "Migrations",
  Programs = "Programs",
  ReferAndEarn = "ReferAndEarn",
  Reports = "Reports",
  Settings = "Settings",
  Themes = "Themes",
  Users = "Users"
}

type IsVisibleCheck = (
  organization: OrganizationSummary,
  organizationPortalUserRole: OrganizationPortalUserRole | undefined,
  elevated: boolean
) => boolean;

interface AdminPortalSectionConfig {
  id: AdminPortalSectionId;
  title: string;
  isVisible?: IsVisibleCheck;
  url: (routes: OrganizationPortalRoutes, organizationAlias: string) => string;
}

export const AdminPortalSections: AdminPortalSectionConfig[] = [
  {
    id: AdminPortalSectionId.Dashboard,
    title: "Dashboard",
    url: (routes, organizationAlias) => routes.dashboardPath(organizationAlias)
  },
  {
    id: AdminPortalSectionId.Migrations,
    title: "Migration Search",
    url: (routes, organizationAlias) => routes.migrations(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.Reports,
    title: "Migration Reports",
    url: (routes, organizationAlias) => routes.reportsPath(organizationAlias)
  },
  {
    id: AdminPortalSectionId.Programs,
    title: "End-User Programs",
    url: (routes, organizationAlias) => routes.programs(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.AccessLists,
    title: "End-User Access Lists",
    url: (routes, organizationAlias) => routes.accessLists(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.AutomatedOffboarding,
    title: "End-User Offboarding",
    url: (routes, organizationAlias) => routes.automatedOffboarding(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.ReferAndEarn,
    title: "Alumni Member Benefit",
    isVisible: (organization, role, elevated) =>
      organization.revenueSharingEnabled || elevated,
    url: (routes, organizationAlias) => routes.referAndEarnPath(organizationAlias)
  },
  {
    id: AdminPortalSectionId.Users,
    title: "EDU Admin Users",
    isVisible: (organization, role) =>
      !role || OrganizationPortalUserRole.implies(role, OrganizationPortalUserRole.Administrator),
    url: (routes, organizationAlias) => routes.users(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.Configurations,
    title: "EDU Configurations",
    url: (routes, organizationAlias) => routes.configurations(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.Themes,
    title: "EDU Themes",
    url: (routes, organizationAlias) => routes.themes(organizationAlias).homePath
  },
  {
    id: AdminPortalSectionId.Settings,
    title: "EDU Settings",
    url: (routes, organizationAlias) => routes.settingsPath(organizationAlias)
  },
];

interface Props {
  sectionId: AdminPortalSectionId;
  organization: OrganizationSummary;
  organizationPortalUserRole: OrganizationPortalUserRole | undefined;
}

export const AdminPortalNav: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();
  const navigate = useNavigate();
  const elevated = useElevated();

  const navigationItems = AdminPortalSections
    .filter((section) =>
      !section.isVisible ||
      section.isVisible(props.organization, props.organizationPortalUserRole, elevated)
    )
    .map((section, index) => (
      <SimpleWorkflowNavigationItem
        key={section.id}
        title={section.title}
        enabled={true}
        selected={props.sectionId === section.id}
        nextSelected={index < AdminPortalSections.length - 1 && AdminPortalSections[index + 1].id === props.sectionId}
        hideProgressIndicator={true}
        onSimpleClick={section.url(routes.organizationPortalRoutes, props.organization.alias)}
      />
    ));

  return (
    <>
      <WorkflowTitle>EDU Admin Portal</WorkflowTitle>
      <LogoContainer onClick={() => navigate(routes.organizationPortalRoutes.dashboardPath(props.organization.alias))}>
        {props.organization.logoId && <Logo src={routes.api.downloadUrl(props.organization.logoId)}/>}
        <OrganizationName>{props.organization.name}</OrganizationName>
      </LogoContainer>
      <WorkflowNavigation>
        {navigationItems}
        <ContactInfo>
          If you need help administering your organization, please send an email to our EDU services team at{" "}
          <a href={"mailto:" + Constants.Product.eduServicesEmail} style={{ whiteSpace: "nowrap" }}>
            {Constants.Product.eduServicesEmail}
          </a>
        </ContactInfo>
      </WorkflowNavigation>
    </>
  );
};

const LogoContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 1.2rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const OrganizationName = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
`;

const Logo = styled.img`
  display: block;
  max-width: 12rem;
  max-height: 8rem;
  margin-bottom: 1rem;
`;

const ContactInfo = styled.div`
  padding: 1.285rem;
  border-left: 3px solid transparent;
  font-size: 0.85rem;
`;
