export namespace LocalStorage {
  export const MigrationSetupWorkflowKey = "migration";
  export const BatchMigrationSetupWorkflowKey = "batch-migration";

  export function loadObject<T>(key: string): T | undefined {
    try {
      const serializedObject = localStorage.getItem(key);
      if (serializedObject) {
        return JSON.parse(serializedObject);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }

  export function saveObject<T>(key: string, state: T): void {
    try {
      const serializedObject = JSON.stringify(state);
      localStorage.setItem(key, serializedObject);
    } catch (error) {
      // Do nothing
    }
  }

  export function removeObject<T>(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      // Do nothing
    }
  }

  export function removeAllDrafts(): void {
    removeObject(MigrationSetupWorkflowKey);
    removeObject(BatchMigrationSetupWorkflowKey);
  }
}
