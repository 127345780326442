export interface FastPromise<T> {
  isAsync(): boolean;

  beforeAsync(f?: () => void): FastPromise<T>;

  map<R>(f: (value: T) => FastPromise.CompatibleValue<R>): FastPromise<R>;

  recover(f: (error: any) => FastPromise.CompatibleValue<T>): FastPromise<T>;
}

export class Unresolved<T> implements FastPromise<T> {
  constructor(public readonly promise: Promise<T>) {
  }

  public isAsync(): boolean {
    return true;
  }

  public beforeAsync(f?: () => void): FastPromise<T> {
    if (f) {
      f();
    }
    return this;
  }

  public map<R>(f: (value: T) => FastPromise.CompatibleValue<R>): FastPromise<R> {
    return new Chained(
      this.promise
        .then((value) => FastPromise.fromFactory(() => f(value)))
        .catch((error) => new Rejected(error, undefined))
    );
  }

  public recover(f: (error: any) => FastPromise.CompatibleValue<T>): FastPromise<T> {
    return new Chained(
      this.promise
        .then((value) => FastPromise(value))
        .catch((error) => FastPromise.fromFactory(() => f(error)))
    );
  }
}

export class Chained<T> implements FastPromise<T> {
  constructor(public readonly promise: Promise<FastPromise<T>>) {
  }

  public isAsync(): boolean {
    return true;
  }

  public beforeAsync(f?: () => void): FastPromise<T> {
    if (f) {
      f();
    }
    return this;
  }

  public map<R>(f: (value: T) => FastPromise.CompatibleValue<R>): FastPromise<R> {
    return new Chained(this.promise.then((fastPromise) => fastPromise.map(f)));
  }

  public recover(f: (error: any) => FastPromise.CompatibleValue<T>): FastPromise<T> {
    return new Chained(this.promise.then((fastPromise) => fastPromise.recover(f)));
  }
}

export class Resolved<T> implements FastPromise<T> {
  constructor(
    public readonly value: T,
    protected beforeAsyncHook: (() => void) | undefined
  ) {
  }

  public isAsync(): boolean {
    return false;
  }

  public beforeAsync(f?: () => void): FastPromise<T> {
    return new Resolved(this.value, f);
  }

  public map<R>(f: (value: T) => FastPromise.CompatibleValue<R>): FastPromise<R> {
    return FastPromise.fromFactory(() => f(this.value), this.beforeAsyncHook);
  }

  public recover(f: (error: any) => FastPromise.CompatibleValue<T>): FastPromise<T> {
    return new Resolved(this.value, this.beforeAsyncHook);
  }
}

export class Rejected<T> implements FastPromise<T> {
  constructor(
    public readonly error: any,
    protected beforeAsyncHook: (() => void) | undefined) {
  }

  public isAsync(): boolean {
    return false;
  }

  public beforeAsync(f: () => void): FastPromise<T> {
    return new Rejected(this.error, f);
  }

  public map<R>(f: (value: T) => FastPromise.CompatibleValue<R>): FastPromise<R> {
    return new Rejected(this.error, this.beforeAsyncHook);
  }

  public recover(f: (error: any) => FastPromise.CompatibleValue<T>): FastPromise<T> {
    return FastPromise.fromFactory(() => f(this.error), this.beforeAsyncHook);
  }
}

export function FastPromise<T>(
  value: FastPromise.CompatibleValue<T>,
  beforeAsyncHook?: () => void
): FastPromise<T> {
  if (FastPromise.isFastPromise(value)) {
    return value.beforeAsync(beforeAsyncHook);
  } else if (value instanceof Promise) {
    return new Unresolved(value).beforeAsync(beforeAsyncHook);
  } else {
    return new Resolved(value, beforeAsyncHook);
  }
}

export namespace FastPromise {
  export type CompatibleValue<T> = T | Promise<T> | FastPromise<T>;

  export function isFastPromise<T>(value: any): value is FastPromise<T> {
    return value && (
      value instanceof Unresolved ||
      value instanceof Chained ||
      value instanceof Resolved ||
      value instanceof Rejected
    );
  }

  export function resolve<T>(value: CompatibleValue<T>): FastPromise<T> {
    return FastPromise(value);
  }

  export function reject<T>(error: any): FastPromise<T> {
    return new Rejected(error, undefined);
  }

  export function fromFactory<T>(
    factory: () => FastPromise.CompatibleValue<T>,
    beforeAsyncHook?: () => void
  ): FastPromise<T> {
    try {
      return FastPromise(factory(), beforeAsyncHook);
    } catch (error) {
      return new Rejected(error, beforeAsyncHook);
    }
  }
}

// FastPromise<number>(Promise.resolve(1))
//   .map((value) => value + 1)
//   .map<number>((value) => { console.log("map 1", value); return Promise.reject("Oops!"); })
//   .recover((error) => { console.log("recover 1"); return 10; })
//   .map((value) => value + 1)
//   .map<number>((value) => { console.log("map 2", value); return Promise.reject("Oops!"); })
//   .recover((error) => { console.log("recover 2"); return 20; })
//   .map((value) => value + 1)
//   .map<number>((value) => { console.log("map 3", value); return Promise.reject("Oops!"); })
//   .recover((error) => { console.log("recover 3"); return 30; });

// Promise
//   .resolve(1)
//   .then((value) => value + 1)
//   .then((value) => { console.log("then 1", value); return Promise.reject("Oops!"); })
//   .catch((error) => { console.log("catch 1"); return 10; })
//   .then((value) => value + 1)
//   .then((value) => { console.log("then 2", value); return Promise.reject("Oops!"); })
//   .catch((error) => { console.log("catch 2"); return 20; })
//   .then((value) => value + 1)
//   .then((value) => { console.log("then 3", value); return Promise.reject("Oops!"); })
//   .catch((error) => { console.log("catch 3"); return 30; });

// FastPromise
//   .resolve(Promise.resolve(1))
//   .map((value1) =>
//     FastPromise
//       .resolve(2)
//       .map((value2) =>
//         FastPromise
//           .reject<number>(3)
//           .map((value3) => value2 + value3)
//           .recover((error) => { console.log("recover 3"); return 30; })
//       )
//       .map((value2) => value1 + value2)
//       // .map<number>((value2) => { throw Error("Oopa!"); })
//       .recover((error) => { console.log("recover 2"); return 30; })
//   )
//   .map((value1) => { console.log("!!!!!!!! 1", value1); return 66; })
//   .recover((error) => { console.log("recover 1"); return 30; })
//   .map((result) => console.log("!!!!!!!! 2", result));

// Promise
//   .resolve(1)
//   .then((value1) =>
//     Promise
//       .resolve(2)
//       .then((value2) =>
//         Promise
//           .reject(3)
//           .then((value3) => value2 + value3)
//           .catch((error) => { console.log("catch 3"); return 30; })
//       )
//       .then((value2) => value1 + value2)
//       // .then((value2) => { throw Error("Oopa!"); })
//       .catch((error) => { console.log("catch 2"); return 30; })
//   )
//   .then((value1) => console.log("!!!!!!!! 1", value1))
//   .catch((error) => { console.log("catch 1"); return 30; })
//   .then((result) => console.log("!!!!!!!! 2", result));

// const fp: FastPromise<number> =
//   FastPromise(Promise.reject("Oops!"))
//   // FastPromise(Promise.resolve(666))
//     .map((value) => Promise.resolve(value + 111))
//     .map((value) => value + 111)
//     // .map((value) => Promise.reject("Oops!!!"))
//     // .map((value) => new Rejected<number>("Oi!"))
//     // .map((value) => { throw Error("Oi!"); })
//     .map((value) => Promise.resolve(value + 111));
//
// fp
//   .map((value) => {
//     console.log("!!!!!!!!!!!!!!!!!!", value);
//     return "DONE!";
//   })
//   .recover((error) => {
//     console.error("!!!!!!!!!! RECOVER", error);
//     return "OOPS!!";
//   });
