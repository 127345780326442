import { GraphQL } from "../../services/graphql/generated";
import { Connection } from "./connection";
import { User } from "./user";

export interface ConnectResult {
  readonly user: User;
  readonly elevated: boolean;
  readonly idleTimeout: number;
  readonly connection: Connection;
}

export namespace ConnectResult {
  export function parse(data: GraphQL.ConnectResultFragment): ConnectResult {
    return {
      user: User.parse(data.user),
      elevated: data.elevated,
      idleTimeout: data.idleTimeout,
      connection: Connection.fromGraphQL(data.connection)
    };
  }
}
