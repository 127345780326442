import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { MigrationNote } from "../../types/models/migrationNote";
import { MigrationNoteType } from "../../types/models/migrationNoteType";

type AddMigrationNoteMutationHook = [
  (type: MigrationNoteType, message: string) => Promise<MigrationNote>,
  OperationStatus<MigrationNote>
];

export function useAddMigrationNoteMutation(migrationId: string): AddMigrationNoteMutationHook {
  const [addMigrationNote, { status }] = useManagedMutation({
    mutation: GraphQL.useAddMigrationNoteMutation,
    extract: (data: GraphQL.AddMigrationNoteMutation) => nullToUndefined(data.addMigrationNote),
    complete: (migrationNote) => MigrationNote.fromGraphQL(migrationNote)
  });

  function go(type: MigrationNoteType, message: string): Promise<MigrationNote> {
    return addMigrationNote({
      variables: { migrationId, type, message },
      update: (cache, { data }) => {
        if (data?.addMigrationNote) {
          MigrationNote.cache(cache, [data.addMigrationNote]);
        }
      }
    });
  }

  return [go, status];
}
