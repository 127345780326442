import { BlueprintContext } from "../blueprintContext";
import { ComponentHubSettings, DataSource, HubProps } from "../hub";
import { State } from "../state";
import { HubBinding } from "../hubBinding";
import { GraphQL } from "../../services/graphql/generated";
import { Some } from "../../utils/monads/option";

export class ArrayHub<Output> extends DataSource<ArrayHub.Props, Output, Output[]> {
  public calcState(context: BlueprintContext): State<Output[]> {
    return State.error({
      type: "TO_BE_IGNORED",
      message: Some("ArrayHub does not calculate its state - please ignore")
    });
  }
}

export namespace ArrayHub {
  export type Props = HubProps;

  export function fromGraphQL(
    binding: HubBinding,
    hub: GraphQL.ArrayHubFragment,
    settings?: ComponentHubSettings
  ): ArrayHub<any> {
    return new ArrayHub(binding, hub, HubProps.fromGraphQL(hub.props), settings);
  }
}
