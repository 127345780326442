import * as React from "react";
import { Paragraph } from "../../../widgets/paragraph";

interface Props {
  prefix?: string;
}

export const DoNotMoveFoldersWarning: React.FunctionComponent<Props> = (props) => (
  <Paragraph>
    {props.prefix || "While waiting"}, please do not move, rename, or delete any folders, labels, or groups in either
    your source or destination account. VaultMe needs these to stay undisturbed during the migration to ensure you get
    the best migration possible.
  </Paragraph>
);
