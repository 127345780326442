import * as React from "react";
import { Option } from "../utils/monads/option";
import { GraphQL } from "../services/graphql/generated";
import { OperationStatus } from "../types/operationStatus";
import { UserFacingError } from "../types/userFacingError";
import { useManagedMutation } from "../services/graphql/useManagedMutation";
import { identity, nullToUndefined, roundCurrencyAmount, withoutUndefined } from "../utils/misc";
import { ErrorHandler } from "../services/graphql/errorHandler";
import { ErrorClass } from "../services/graphql/errorClass";
import { InlineErrorMessage } from "../views/widgets/errorInfo";
import { useSessionOrFail } from "../utils/useAppState";
import { useBrowserQuery } from "../utils/useQuery";
import { Constants } from "../app/constants";
import { CouponCodeDiscount } from "../types/models/couponCodeDiscount";
import { ReferralCodeDiscount } from "../types/models/referralCodeDiscount";
import { ReferralCodeValidationResult } from "../types/models/referralCodeValidationResult";
import { DiscountType } from "../types/models/discountType";
import { useAppBootstrapConfig } from "../app/configuration";
import { AmbassadorStatusDiscount } from "../types/models/ambassadorStatusDiscount";
import { AmbassadorCodeDiscount } from "../types/models/ambassadorCodeDiscount";

interface BraintreeInitResult {
  braintree: any;
  deviceData: any;
}

export interface ExternalPayment {
  amount: number;
  note: Option<string>;
}

export interface Payment {
  priceBeforeDiscounts: number;

  couponCodeDiscount: CouponCodeDiscount | undefined;
  referralCodeDiscount: ReferralCodeDiscount | undefined;
  ambassadorStatusDiscount: AmbassadorStatusDiscount | undefined;
  ambassadorCodeDiscount: AmbassadorCodeDiscount | undefined;

  amountToBePaid: number;
}

function getErrorMessage(error: any): string | undefined {
  if (typeof error === "string") {
    return error + (error.endsWith(".") ? "" : ".");
  } else if (error.hasOwnProperty("message") && typeof error.message === "string") {
    return error.message + (error.message.endsWith(".") ? "" : ".");
  } else if (error) {
    return error.toString();
  }
}

type CalcAmountToBePaidFunction = (price: number, discounts: number[]) => number;

export interface PaymentFlowConfig {
  sourceConnectionId: string | undefined;
  order: PaymentFlowConfig.OrderConfig | undefined;
  onPayment: (
    payment: Payment,
    braintreePayload: any,
    deviceData: any,
    externalPayment: GraphQL.ExternalPayment | undefined
  ) => void;
}

export namespace PaymentFlowConfig {
  export interface OrderConfig {
    originalBasePrice: number;
    originalBytesFee: number;
    originalItemsFee: number;
    priceBeforeDiscounts: number;
    calcAmountToBePaid: CalcAmountToBePaidFunction;
    isAmbassadorDiscountEnabled: boolean;
  }

  export interface DiscountsConfig {
    couponCodeStatus: OperationStatus<GraphQL.CouponCodeSummary>;
    onSubmitCouponCode: (code: string) => void;
    onClearCouponCodeError: () => void;

    referralCodeStatus: OperationStatus<ReferralCodeValidationResult>;
    onSubmitReferralCode: (code: string) => void;
    onClearReferralCodeError: () => void;

    ambassadorCodeStatus: OperationStatus<string>;
    onSubmitAmbassadorCode: (code: string) => void;
    onClearAmbassadorCodeError: () => void;
  }
}

export interface PaymentFlowHook {
  braintreeInitStatus: OperationStatus<any>;
  requestPaymentMethodStatus: OperationStatus<any>;

  onBraintreeInit: () => void;
  onBraintreeInitSuccess: (braintree: any, deviceData: any) => void;
  onBraintreeInitFailure: (error: UserFacingError) => void;
  onSubmit: () => void;
  onExternalPayment: (payment: ExternalPayment) => void;
  onBraintreeTeardown: () => void;

  discounts: PaymentFlowConfig.DiscountsConfig;
  payment: Payment | undefined;
}

export function usePaymentFlow(config: PaymentFlowConfig): PaymentFlowHook {
  const session = useSessionOrFail();
  const appBootstrapConfig = useAppBootstrapConfig();
  const query = useBrowserQuery();

  const [braintreeInitStatus, setBraintreeInitStatus] =
    React.useState<OperationStatus<BraintreeInitResult>>(OperationStatus.Pending());

  const [requestPaymentMethodStatus, setRequestPaymentMethodStatus] =
    React.useState<OperationStatus<any>>(OperationStatus.Pending());

  function handleSubmit() {
    if (config.order) {
      const payment = buildPayment(config.order);

      if (payment.amountToBePaid !== 0) {
        if (braintreeInitStatus.isSuccess()) {
          setRequestPaymentMethodStatus(OperationStatus.Working());

          const threeDSecureParameters = {
            amount: payment.amountToBePaid.toFixed(2),
            email: session.user.personalInfo.emailAddress,
            billingAddress: {
              // ASCII-printable characters required, else will throw a validation error
              // The filter is made using this source:
              // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
              givenName: session.user.personalInfo.firstName?.replace(/[^\x00-\x7F]/g, ""),
              surname: session.user.personalInfo.lastName?.replace(/[^\x00-\x7F]/g, ""),
              // Removing everything but numbers
              phoneNumber: session.user.personalInfo.phoneNumber?.replace(/[^\d]/g, "")
            }
          };

          braintreeInitStatus.result.braintree.requestPaymentMethod({ threeDSecure: threeDSecureParameters })
            .then((payload: any) => {
              setRequestPaymentMethodStatus(OperationStatus.Success(payload));
              config.onPayment(payment, payload, braintreeInitStatus.result.deviceData, undefined);
            })
            .catch((error: any) => {
              setRequestPaymentMethodStatus(OperationStatus.Failure(UserFacingError.expected(error, {
                title: "Oops. Your payment could not be processed.",
                recommendations: getErrorMessage(error) === "No payment method is available."
                  ? "Please choose a way to pay below."
                  : "Please hit the \"Choose another way to pay\" link below and try re-submitting your payment."
              })));
            });
        }
      } else {
        config.onPayment(payment, undefined, undefined, undefined);
      }
    }
  }

  function handleExternalPayment(payment: ExternalPayment) {
    if (config.order) {
      config.onPayment(
        buildPayment(config.order),
        undefined,
        undefined,
        payment.amount !== 0 ? { amount: payment.amount, note: payment.note.toJS() } : undefined
      );
    }
  }

  const [validateCouponCode, { status: validateCouponCodeStatus, reset: resetValidateCouponCode }] =
    useManagedMutation({
      mutation: GraphQL.useValidateCouponCodeMutation,
      extract: (data: GraphQL.ValidateCouponCodeMutation) => nullToUndefined(data.validateCouponCode),
      complete: identity
    });

  function buildCouponCodeDiscount(order: PaymentFlowConfig.OrderConfig): CouponCodeDiscount | undefined {
    if (validateCouponCodeStatus.isSuccess()) {
      const couponCode = validateCouponCodeStatus.result;
      const amount = couponCode.isPercentage
        ? Math.round(order.priceBeforeDiscounts * couponCode.discount) / 100
        : couponCode.discount > 0
          ? couponCode.discount
          : order.priceBeforeDiscounts + couponCode.discount;
      return {
        code: couponCode.id,
        value: couponCode.discount,
        isPercentage: couponCode.isPercentage,
        amount
      };
    }
  }

  const [validateReferralCode, { status: validateReferralCodeStatus, reset: resetValidateReferralCode }] =
    useManagedMutation({
      mutation: GraphQL.useValidateReferralCodeMutation,
      extract: (data: GraphQL.ValidateReferralCodeMutation) => nullToUndefined(data.validateReferralCode),
      complete: ReferralCodeValidationResult.parse
    });

  const [validateAmbassadorCode, { status: validateAmbassadorCodeStatus, reset: resetValidateAmbassadorCode }] =
    useManagedMutation({
      mutation: GraphQL.useValidateAmbassadorCodeMutation,
      extract: (data: GraphQL.ValidateAmbassadorCodeMutation) => nullToUndefined(data.validateAmbassadorCode),
      complete: identity
    });

  const combinedReferralCodeValidationStatus =
    validateReferralCodeStatus.isPending() &&
    validateAmbassadorCodeStatus.isSuccess() &&
    validateAmbassadorCodeStatus.result.referralCode
      ? OperationStatus.Success<ReferralCodeValidationResult.Success>({
        type: ReferralCodeValidationResult.Type.Success,
        referralCode: validateAmbassadorCodeStatus.result.referralCode
      })
      : validateReferralCodeStatus;

  function buildReferralCodeDiscount(order: PaymentFlowConfig.OrderConfig): ReferralCodeDiscount | undefined {
    if (
      combinedReferralCodeValidationStatus.isSuccess() && combinedReferralCodeValidationStatus.result &&
      combinedReferralCodeValidationStatus.result.type === ReferralCodeValidationResult.Type.Success
    ) {
      const referralCode = combinedReferralCodeValidationStatus.result.referralCode;
      const discountedPrices = referralCode.discountType === DiscountType.BasePriceOnly
        ? [order.originalBasePrice]
        : [order.originalBasePrice, order.originalBytesFee, order.originalItemsFee];
      const amount = discountedPrices.reduce(
        (sum, price) => roundCurrencyAmount(
          sum + roundCurrencyAmount(price * referralCode.discount / 100)
        ),
        0
      );
      return {
        code: referralCode.code,
        percentage: referralCode.discount,
        discountType: referralCode.discountType,
        amount
      };
    }
  }

  const referralCodeParam = query.getString(Constants.QueryParams.ReferralCode);
  React.useEffect(
    () => {
      if (referralCodeParam) {
        validateReferralCode({
          variables: {
            sourceConnectionId: config.sourceConnectionId,
            referralCode: referralCodeParam
          }
        }).catch(() => resetValidateReferralCode());
      }
    },
    [referralCodeParam]
  );

  const ambassadorStatus = session.user.ambassadorsProgramMembership;
  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  function buildAmbassadorStatusDiscount(order: PaymentFlowConfig.OrderConfig): AmbassadorStatusDiscount | undefined {
    if (ambassadorStatus) {
      if (ambassadorStatus.signUpDiscountMigrationId) {
        return {
          amount: Math.round(order.originalBasePrice * codeDiscount) / 100,
          isReturningAmbassadorDiscount: true,
          returningAmbassadorPercentage: codeDiscount
        };
      } else {
        return {
          amount: appBootstrapConfig.ambassadorsProgram.signUpDiscount,
          isReturningAmbassadorDiscount: false,
          returningAmbassadorPercentage: codeDiscount
        };
      }
    }
  }

  function buildAmbassadorCodeDiscount(order: PaymentFlowConfig.OrderConfig): AmbassadorCodeDiscount | undefined {
    if (validateAmbassadorCodeStatus.isSuccess()) {
      return {
        code: validateAmbassadorCodeStatus.result.ambassadorCode,
        percentage: codeDiscount,
        amount: Math.round(order.originalBasePrice * codeDiscount) / 100
      };
    }
  }

  const ambassadorCodeParam = query.getString(Constants.QueryParams.AmbassadorCode);
  React.useEffect(
    () => {
      if (ambassadorCodeParam) {
        validateAmbassadorCode({
          variables: {
            sourceConnectionId: config.sourceConnectionId,
            ambassadorCode: ambassadorCodeParam
          }
        }).catch(() => resetValidateAmbassadorCode());
      }
    },
    [ambassadorCodeParam]
  );

  function buildPayment(order: PaymentFlowConfig.OrderConfig): Payment {
    const couponCodeDiscount = buildCouponCodeDiscount(order);
    const referralCodeDiscount = buildReferralCodeDiscount(order);
    const ambassadorStatusDiscount = buildAmbassadorStatusDiscount(order);
    const ambassadorCodeDiscount = buildAmbassadorCodeDiscount(order);

    return {
      priceBeforeDiscounts: order.priceBeforeDiscounts,
      amountToBePaid: order.calcAmountToBePaid(
        order.priceBeforeDiscounts,
        withoutUndefined([
          couponCodeDiscount?.amount,
          referralCodeDiscount?.amount,
          ambassadorStatusDiscount?.amount,
          ambassadorCodeDiscount?.amount
        ])
      ),
      couponCodeDiscount,
      referralCodeDiscount,
      ambassadorStatusDiscount,
      ambassadorCodeDiscount
    };
  }

  return {
    braintreeInitStatus,
    requestPaymentMethodStatus,

    onBraintreeInit: () => setBraintreeInitStatus(OperationStatus.Working()),
    onBraintreeInitSuccess: (braintree, deviceData) =>
      setBraintreeInitStatus(OperationStatus.Success({ braintree, deviceData })),
    onBraintreeInitFailure: (error) => setBraintreeInitStatus(OperationStatus.Failure(error)),
    onSubmit: handleSubmit,
    onExternalPayment: handleExternalPayment,
    onBraintreeTeardown: () => setBraintreeInitStatus(OperationStatus.Pending()),

    discounts: {
      couponCodeStatus: validateCouponCodeStatus,
      onSubmitCouponCode: (couponCodeId) => validateCouponCode({ variables: { couponCodeId } }),
      onClearCouponCodeError: () => {
        if (validateCouponCodeStatus.isFailure()) {
          resetValidateCouponCode();
        }
      },

      referralCodeStatus: combinedReferralCodeValidationStatus,
      onSubmitReferralCode: (referralCode) => validateReferralCode({
        variables: {
          sourceConnectionId: config.sourceConnectionId,
          referralCode
        }
      }),
      onClearReferralCodeError: () => {
        if (combinedReferralCodeValidationStatus.isFailure()) {
          resetValidateReferralCode();
        }
      },

      ambassadorCodeStatus: validateAmbassadorCodeStatus.map((result) => result.ambassadorCode),
      onSubmitAmbassadorCode: (ambassadorCode) => validateAmbassadorCode({
        variables: {
          sourceConnectionId: config.sourceConnectionId,
          ambassadorCode
        }
      }),
      onClearAmbassadorCodeError: () => {
        if (validateAmbassadorCodeStatus.isFailure()) {
          resetValidateAmbassadorCode();
        }
      }
    },

    payment: config.order && buildPayment(config.order)
  };
}

export function buildPaymentErrorHandlers(reset: () => void): ErrorHandler<any>[] { // Any?!
  const braintreeErrorTitle = "Oops! Your payment didn't go through.";
  const braintreeErrorRecommendation =
    "Click the \"Try Again\" button below to re-submit your payment " +
    "or use a different payment method.";

  return [
    [
      ErrorClass.AvsValidationException,
      (error) => UserFacingError.expected(error, {
        title: braintreeErrorTitle,
        summary: "The postal code you provided seems to be incorrect.",
        recommendations: braintreeErrorRecommendation,
        retry: reset,
        contactSupport: true
      })
    ],
    [
      ErrorClass.CvvValidationException,
      (error) => UserFacingError.expected(error, {
        title: braintreeErrorTitle,
        summary: "The CVV code you provided seems to be incorrect.",
        recommendations: braintreeErrorRecommendation,
        retry: reset,
        contactSupport: true
      })
    ],
    [
      ErrorClass.BraintreeException,
      (error) => UserFacingError.expected(error, {
        title: braintreeErrorTitle,
        summary: (
          <>
            Secure payment gateway returned the following errors:
            <InlineErrorMessage>
              {
                error.graphQLErrors.length !== 0
                  ? error.graphQLErrors.map((e, index) => <div key={index}>{e.message}</div>)
                  : error.message
              }
            </InlineErrorMessage>
            {braintreeErrorRecommendation}
          </>
        ),
        showTechnicalDetails: true,
        retry: reset,
        contactSupport: true
      })
    ],
  ];
}
