import * as React from "react";
import { styled } from "../../app/theme";
import { HasClickHandler, withSimpleClickHandler } from "../utils/simpleClickHandler";

export const LinkButton = styled.button.attrs({ type: "button" })<HasClickHandler>`
  color: ${(props) => props.theme.colors.primary};
  white-space: nowrap;
  
  // This compensates the global 16px font size when a link is placed inside text of of non-default  
  // font size - not sure if this is okay
  font-size: inherit; 

  @media(hover: hover) {
    &:hover:not([disabled]) {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &[disabled] {
    color: ${(props) => props.theme.colors.gray};
  }  
`;

export const SimpleLinkButton = withSimpleClickHandler(LinkButton);
