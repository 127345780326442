import * as React from "react";
import { styled } from "../../app/theme";

export const StyledSvg = styled.svg`
  fill: ${(props) => props.theme.colors.primary};
`;

// tslint:disable max-line-length
export const InvoiceIcon: React.FunctionComponent = () => (
  <StyledSvg viewBox="106 106 805 805">
    <path d="M789.333333 106.666667H234.666667C198.4 106.666667 170.666667 134.4 170.666667 170.666667v676.266666c0 36.266667 27.733333 64 64 64h554.666666c36.266667 0 64-27.733333 64-64V170.666667c0-36.266667-27.733333-64-64-64z m21.333334 740.266666c0 12.8-8.533333 21.333333-21.333334 21.333334H234.666667c-12.8 0-21.333333-8.533333-21.333334-21.333334V170.666667c0-12.8 8.533333-21.333333 21.333334-21.333334h554.666666c12.8 0 21.333333 8.533333 21.333334 21.333334v676.266666z"  />
    <g transform="translate(200 190) scale(10 10)">
      <path d="M 25.645354,38.743144 C 27.706139,41.625309 33.995692,41.833441 36.155115,40.6114 C 36.947727,40.162852 37.145708,38.864507 37.145725,37.98432 C 37.145708,37.380283 36.986739,36.905677 36.668816,36.560499 C 36.333199,36.215341 35.479466,35.339232 34.490338,35.080346 C 29.473962,33.785975 25.998702,32.169274 24.638637,30.995688 C 22.942961,29.528736 22.669211,28.570622 22.669213,26.016357 C 22.669211,23.462132 23.403703,21.32133 25.064052,19.785304 C 26.724393,18.24933 29.135426,17.481331 32.297159,17.481304 C 35.317562,17.481331 39.565451,18.278588 41.636566,20.737131 L 38.183516,24.493331 C 36.236051,22.858359 34.787665,22.710631 32.403139,22.710609 C 30.901751,22.710631 30.120168,22.926362 29.484299,23.3578 C 28.848414,23.772024 28.530476,24.315664 28.530483,24.988721 C 28.530476,25.592786 28.821918,26.09328 29.404815,26.490205 C 29.987693,26.904426 31.511133,27.525729 34.549225,28.354116 C 37.958218,29.286087 40.272103,30.416513 41.490888,31.745396 C 42.691968,33.074309 43.292518,34.843296 43.292541,37.052364 C 43.292518,39.710168 42.356366,41.901988 40.484082,43.627827 C 38.629421,45.353671 36.346462,46.694998 33.061111,46.694997 C 28.832157,46.694996 23.187374,45.201054 21.630014,42.439699"/>
      <path d="M 30.375,12.875 L 30.375,19.71875 L 33.625,19.71875 L 33.625,12.875 L 30.375,12.875 z M 30.375,44.96875 L 30.375,51.125 L 33.625,51.125 L 33.625,44.96875 L 30.375,44.96875 z "/>
    </g>
  </StyledSvg>
);
