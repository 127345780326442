import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { StatusIndicators } from "./statusIndicators";

export interface PipeProps<T> {
  subject?: string;
  loadingMessage?: string;
  successMessage?: string | ((result: T) => string);
  failureMessage?: string;

  indicators: StatusIndicators;

  status: OperationStatus<T>;

  children?: (data: T) => React.ReactElement;
}

export function Pipe<T>(props: PipeProps<T>): React.ReactElement | null {
  if (props.status.isFailure()) {
    return props.indicators.renderFailure(
      props.status.error.enrich({
        summary: props.failureMessage || ("Failed to load " + (props.subject || "required data")),
      })
    );
  } else if (props.status.isWorking()) {
    return props.indicators.renderProgress(
      props.loadingMessage || ("Loading" + (props.subject ? " " + props.subject : "") + "..."),
      !!props.status.retrying
    );
  } else if (props.status.isSuccess()) {
    if (props.successMessage) {
      const preparedSuccessMessage: string = typeof props.successMessage === "function"
        ? props.successMessage(props.status.result)
        : props.successMessage;
      const finalSuccessMessage = preparedSuccessMessage.endsWith(".")
        ? preparedSuccessMessage
        : preparedSuccessMessage + ".";
      return (
        <>
          {props.indicators.renderSuccess(finalSuccessMessage)}
          {props.children && props.children(props.status.result)}
        </>
      );
    } else if (props.children) {
      return props.children(props.status.result);
    } else {
      return null;
    }
  } else {
    return null;
  }
}
