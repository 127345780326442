import * as React from "react";
import * as yup from "yup";
import { Anchor } from "./anchor";
import { WorkItemMutationResult } from "./workItemMutationResult";
import {
  InjectedWorkItemConfig,
  WorkItemConfigUpdates,
  WorkItem,
  WorkItemConfig,
  WorkItemFactory, RenderHooks
} from "./workItem";
import { WorkflowContextLike } from "./workflowContextLike";
import { Path } from "./path";
import { FastPromise } from "./fastPromise";
import { InitializationError } from "./initializationError";
import { DataSource } from "./dataSource";

export namespace WorkItemDef {
  export interface InitParams<WorkflowContext extends WorkflowContextLike, Props> {
    workflowContext: WorkflowContext;
    props: Props;
  }

  export interface InitForResultParams<WorkflowContext extends WorkflowContextLike, Props, Result, ResultContext> {
    workflowContext: WorkflowContext;
    props: Props;
    result: Result;
    prevResultContext: ResultContext | undefined;
  }

  export interface ParamsForInitialized<
    WorkflowContext extends WorkflowContextLike,
    Props,
    PropsContext,
    Result,
    ResultContext
    > {
    workflowContext: WorkflowContext;
    props: Props;
    propsContext: PropsContext;
    result: Result | undefined;
    resultContext: ResultContext | undefined;
  }

  export interface ParamsForComplete<
    WorkflowContext extends WorkflowContextLike,
    Props,
    PropsContext,
    Result,
    ResultContext
    > {
    workflowContext: WorkflowContext;
    props: Props;
    result: Result;
    resultContext: ResultContext;
  }

  export interface RenderParams<
    WorkflowContext extends WorkflowContextLike,
    Props,
    PropsContext,
    Result,
    ResultContext,
    Hooks
    >
    extends ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext> {
    hooks: Hooks;
  }
}

export interface WorkItemDef<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext
  > {
  propsContext: (
    params: WorkItemDef.InitParams<WorkflowContext, Props>
  ) => DataSource.CompatibleValue<PropsContext>;

  resultContext: (
    params: WorkItemDef.InitForResultParams<WorkflowContext, Props, Result, ResultContext>
  ) => DataSource.CompatibleValue<ResultContext>;

  render: (
    params: WorkItemDef.RenderParams<
      WorkflowContext, Props, PropsContext, Result, ResultContext, RenderHooks<WorkflowFeedback, Result>
      >
  ) => React.ReactElement;

  hideFromNavigation?: boolean | ((
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => boolean);

  numbered?: boolean | ((
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => boolean);

  title?: string | ((
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => string);

  progress?: (
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => number | undefined;

  errorIndicator?: (
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => string | undefined;

  description?: string | undefined | ((
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => string | undefined);

  resultSchema: yup.Schema<Result>;

  propsKey?: (props: Props) => any;

  resultKey?: (result: Result) => string;

  validateResult?: (
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext> &
      { result: Result }
  ) => boolean;

  resultSummary?: (
    params: WorkItemDef.ParamsForComplete<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ) => string;

  processFeedback?: (
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext> &
      { feedback: WorkflowFeedback }
  ) => Result | undefined;

  sideEffect?: (
    params: WorkItemDef.ParamsForInitialized<WorkflowContext, Props, PropsContext, Result, ResultContext> &
      { last: boolean }
  ) => void;
}

export class WorkItemImpl<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext = PropsContext
  >
  implements WorkItem<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {

  constructor(
    private readonly config: WorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>,
    public readonly workItemDef: WorkItemDef<
      WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext
      >
  ) {
  }

  get workflowContext(): WorkflowContext {
    return this.config.workflowContext;
  }

  get id(): string {
    return this.config.id;
  }

  get nestedPath(): Path {
    return this.config.nestedPath;
  }

  get props(): Props {
    return this.config.props;
  }

  get isCompleted(): boolean {
    return this.config.isCompleted;
  }

  get result(): Result | undefined {
    return this.config.result;
  }

  get lastCompletedResult(): Result | undefined {
    return this.config.lastCompletedResult;
  }

  get propsContext(): PropsContext | undefined {
    return this.config.propsContext;
  }

  get resultContext(): ResultContext | undefined {
    return this.config.resultContext;
  }

  public copy(
    updates: WorkItemConfigUpdates<WorkflowContext, Props, PropsContext, Result, ResultContext>
  ): WorkItemImpl<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {
    return new WorkItemImpl({ ...this.config, ...updates }, this.workItemDef);
  }

  public getPropsContext(): DataSource<PropsContext> {
    return DataSource.fromFactory(
      () => this.workItemDef.propsContext({
        workflowContext: this.workflowContext,
        props: this.props
      }),
      (error) => FastPromise.reject(InitializationError.create(error, this.id, false))
    );
  }

  public getResultContext(): DataSource<ResultContext> {
    if (this.result) {
      const result = this.result;
      return DataSource.fromFactory(
        () => this.workItemDef.resultContext({
          workflowContext: this.workflowContext,
          props: this.props,
          result,
          // Attempted to cache and re-use results from the previous resultContext, but it did not work -
          // resultContext is always undefined :-\
          prevResultContext: this.resultContext
        }),
        (error) => FastPromise.reject(InitializationError.create(error, this.id, true))
      );
    } else {
      throw Error("Work item must have a result");
    }
  }

  public getValidatedPath(nestedPath: Path): Path {
    return Path.single(this.id);
  }

  public getLastNestedWorkItemPath(): Path {
    return Path.single(this.id);
  }

  public getPreviousNestedWorkItemPath(): Path | undefined {
    return undefined;
  }

  public apply(result: any): WorkItemMutationResult.OneOf<Result> {
    return WorkItemMutationResult.Progressed(result, Path.single(this.id));
  }

  public complete(result: any): WorkItemMutationResult.OneOf<Result> {
    return WorkItemMutationResult.Completed(result);
  }

  public processFeedback(feedback: WorkflowFeedback): WorkItemMutationResult.OneOf<Result> {
    const result = this.workItemDef.processFeedback && this.workItemDef.processFeedback({
      ...WorkItemConfig.paramsForInitialized(this, this.getValidatedResult()),
      feedback
    });
    if (result) {
      return WorkItemMutationResult.Completed(result);
    } else {
      return WorkItemMutationResult.DidNotChange();
    }
  }

  public getValidatedResult(): Result | undefined {
    return this.result && this.isResultValid(this.result) ? this.result : undefined;
  }

  public getPropsKey(): string {
    if (this.workItemDef.propsKey) {
      const key = this.workItemDef.propsKey(this.props);
      if (typeof key === "string") {
        return key;
      } else {
        try {
          return JSON.stringify(key);
        } catch (error) {
          console.error("Failed to serialize props key to JSON for \"" + this.id + "\"", key, error);
          throw error;
        }
      }
    } else {
      return "*";
    }
  }

  public getResultKey(): string | undefined {
    if (this.result) {
      return this.workItemDef.resultKey ? this.workItemDef.resultKey(this.result) : JSON.stringify(this.result);
    } else {
      return undefined;
    }
  }

  public getLastCompletedResultKey(): string | undefined {
    if (this.lastCompletedResult) {
      return this.workItemDef.resultKey
        ? this.workItemDef.resultKey(this.lastCompletedResult)
        : JSON.stringify(this.lastCompletedResult);
    } else {
      return undefined;
    }
  }

  public isCompleteWithValidResult(): boolean {
    return this.isCompleted && this.result !== undefined && this.isResultValid(this.result);
  }

  public render(hooks: RenderHooks<WorkflowFeedback, Result>): React.ReactElement {
    return this.workItemDef.render({
      hooks,
      ...WorkItemConfig.paramsForInitialized(this, this.getValidatedResult())
    });
  }

  public getAnchor(): Anchor | undefined {
    const paramsForInitialized = WorkItemConfig.paramsForInitialized(this, this.getValidatedResult());
    if (
      this.workItemDef.hideFromNavigation === undefined ||
      this.workItemDef.hideFromNavigation === false ||
      (typeof this.workItemDef.hideFromNavigation === "function" &&
        !this.workItemDef.hideFromNavigation(paramsForInitialized))
    ) {
      return {
        path: this.id,
        numbered: this.workItemDef.numbered === true ||
          (typeof this.workItemDef.numbered === "function" && this.workItemDef.numbered(paramsForInitialized)),
        title: this.workItemDef.title
          ? (
            typeof this.workItemDef.title === "function"
              ? this.workItemDef.title(paramsForInitialized)
              : this.workItemDef.title
          )
          : this.id,
        description: typeof this.workItemDef.description === "function"
          ? this.workItemDef.description(paramsForInitialized)
          : this.workItemDef.description,
        result: this.isCompleteWithValidResult()
          ? this.workItemDef.resultSummary &&
          this.workItemDef.resultSummary(WorkItemConfig.paramsForComplete(this, this.getValidatedResult()))
          : undefined,
        started: !!this.result,
        complete: this.isCompleteWithValidResult(),
        unlocked: true,
        progress: this.workItemDef.progress && this.workItemDef.progress(paramsForInitialized),
        errorIndicator: this.workItemDef.errorIndicator && this.workItemDef.errorIndicator(paramsForInitialized)
      };
    } else {
      return undefined;
    }
  }

  public sideEffect(last: boolean): void {
    if (this.workItemDef.sideEffect) {
      this.workItemDef.sideEffect({
        last,
        ...WorkItemConfig.paramsForInitialized(this, this.getValidatedResult())
      });
    }
  }

  protected isResultValid(result: Result): boolean {
    if (this.workItemDef.resultSchema.isValidSync(result)) {
      return this.workItemDef.validateResult
        ? this.workItemDef.validateResult({ ...WorkItemConfig.paramsForInitialized(this, result), result })
        : true;
    } else {
      return false;
    }
  }
}

export function makeWorkItem<
  WorkflowContext extends WorkflowContextLike,
  WorkflowFeedback,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  >(
  workItemDef: WorkItemDef<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>
): WorkItemFactory<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext> {
  return (props: Props) =>
    (config: InjectedWorkItemConfig<WorkflowContext, Props, PropsContext, Result, ResultContext>) =>
      new WorkItemImpl<WorkflowContext, WorkflowFeedback, Props, PropsContext, Result, ResultContext>(
        { ...config, props },
        workItemDef
      );
}
