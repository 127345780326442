import * as React from "react";
import { OperationStatus } from "../../types/operationStatus";
import { ApolloError, NetworkStatus } from "apollo-client";
import { QueryResult } from "@apollo/react-common";
import { UserFacingError } from "../../types/userFacingError";
import { identity } from "../../utils/misc";
import { ErrorHandler } from "./errorHandler";
import { useCachedMemo } from "../../utils/useCachedMemo";
import { QueryHookOptions } from "@apollo/react-hooks";

enum RefetchMode {
  Soft = "Soft",
  Hard = "Hard"
}

interface ManagedQueryHookOptions<QueryData, Vars, Deps, Extract, Result>
  extends Omit<QueryHookOptions<QueryData, Vars>, "query" | "skip"> {
  query: (baseOptions?: QueryHookOptions<QueryData, Vars>) => QueryResult<QueryData, Vars>;
  deps: Deps | undefined;
  prepare: (deps: Deps) => Vars;
  extract: (data: QueryData) => Extract | undefined;
  complete: (extract: Extract, deps: Deps) => Result;

  memoDeps?: (extract?: Extract) => any[];
  cacheKeys?: (result: Result) => string[];

  catch?: ErrorHandler<Result>[];

  debugLogs?: boolean;
}

function apolloClientOptions<QueryData, Vars, Deps, Extract, Result>(
  options: ManagedQueryHookOptions<QueryData, Vars, Deps, Extract, Result>
): Omit<QueryHookOptions<QueryData, Vars>, "query"> {
  // [Tim, Apr 14, 2024] We must remove all additional properties from the "options" object, otherwise Apollo Client
  // will somehow take them into account, so each change in these properties will result in a partial reset of the
  // query state. Specifically, Apollo Client will "forget" the last thrown error and reset the managed query from
  // OperationStatus.Failure to OperationStatus.Pending.
  const {
    query,
    deps,
    prepare,
    extract,
    complete,

    memoDeps,
    cacheKeys,

    "catch": catchValue,

    debugLogs,

    ...rest
  } = options;
  return rest;
}

export type ManagedQueryHook<Vars, Result> = [
  OperationStatus<Result>,
  (variableOverrides?: Partial<Vars>, soft?: boolean) => void
  ];

export function useManagedQuery<QueryData, Vars, Deps, Extract, Result>(
  options: ManagedQueryHookOptions<QueryData, Vars, Deps, Extract, Result>
): ManagedQueryHook<Vars, Result> {
  const [lastRefetchMode, setLastRefetchMode] = React.useState<RefetchMode | undefined>(undefined);
  const variablesOverrides = React.useRef<Partial<Vars>>();
  const lastResult = React.useRef<Result>();

  // In some cases, an error seems to be retained in the query only until the next refresh (?).
  // After that, both result and error become undefined, and the hook returns a silly "Server did not return required
  // data" error. Let's try saving the error and reusing it.
  const lastError = React.useRef<ApolloError>();

  // There seems to be a bug in Apollo Client when using "skip: true" AFTER previously making a successful call
  // results in another call with empty variables, and that obviously results in an error. At the same time,
  // this error does not get propagated to the code below, and does not affect the application negatively. So, the only
  // side effect of all that is that we have an error in the browser's network log. Can't do anything about it for
  // right now - need to check in new version of Apollo Client (current version is 2.6.4).
  const query = options.deps !== undefined
    ? options.query({ ...apolloClientOptions(options), variables: options.prepare(options.deps) })
    : options.query({ ...apolloClientOptions(options), skip: true });

  if (options.debugLogs) {
    console.log("[useManagedQuery] networkStatus=" + query.networkStatus + ", error=" + !!query.error);
  }

  function refetch(mode: RefetchMode) {
    setLastRefetchMode(mode);
    query.refetch(variablesOverrides.current && { ...query.variables, ...variablesOverrides.current });
  }

  function retry() {
    refetch(RefetchMode.Hard);
  }

  function handleError(error: ApolloError): OperationStatus<Result> {
    if (options.catch) {
      const errorOrResult = ErrorHandler.handleError(error, options.catch);
      if (errorOrResult === undefined) {
        return OperationStatus.Failure(UserFacingError.unexpected(error, { retry }));
      } else if (errorOrResult instanceof UserFacingError) {
        return OperationStatus.Failure(errorOrResult.enrich({ retry }));
      } else {
        return OperationStatus.Success(errorOrResult);
      }
    } else {
      return OperationStatus.Failure(UserFacingError.unexpected(error, { retry }));
    }
  }

  function getStatus(): OperationStatus<Result> {
    if (
      query.networkStatus === NetworkStatus.loading ||
      query.networkStatus === NetworkStatus.setVariables ||
      query.networkStatus === NetworkStatus.refetch
    ) {
      return OperationStatus.Working({
        retrying: query.networkStatus !== NetworkStatus.loading && lastRefetchMode === RefetchMode.Hard,
        lastResult: lastResult.current
      });
    } else if (query.error) {
      lastError.current = query.error;
      return handleError(query.error);
    } else if (query.data !== undefined) {
      const extract = options.extract(query.data);
      if (extract !== undefined) {
        lastError.current = undefined;
        if (options.deps !== undefined) {
          const result = options.complete(extract, options.deps);
          return OperationStatus.Success(result);
        } else {
          return OperationStatus.Failure(
            UserFacingError.synthetic({ summary: "Internal error: deps are not defined", retry })
          );
        }
      } else if (lastError.current) {
        return handleError(lastError.current);
      } else {
        return OperationStatus.Failure(
          UserFacingError.synthetic({ summary: "Server did not return required data", retry })
        );
      }
    } else {
      // "skip" option set to true
      return OperationStatus.Pending();
    }
  }

  // This function violates the Rules of Hooks - must be used with caution!

  // Note on isSuccess parameter: when refreshing query programmatically (using refetching state variable),
  // query.data and extract are updated BEFORE status changes to Success and new result becomes available.
  // In this situation, we are calling memoizeResult() with "undefined" result, and this value gets memoized.
  // Later, when refetching flag is cleared, we are attempting to memoize a real result using the same
  // extract and the same memoDeps, but nothing happens, and previously memoized "undefined" is returned.
  // To overcome this issue, we are adding "isSuccess" flag to memoDeps, so that undefined result does not conflict
  // with real result.

  function memoizeResult(isSuccess: boolean, result: Result | undefined): Result | undefined {
    const extract = query.data && options.extract(query.data);
    const memoDeps = [isSuccess].concat(options.memoDeps ? options.memoDeps(extract) : [extract]);
    if (options.cacheKeys && options.cacheKeys.length !== 0) {
      return useCachedMemo(
        () => result,
        memoDeps,
        result && options.cacheKeys && options.cacheKeys(result) || []
      );
    } else {
      return React.useMemo(
        () => result,
        memoDeps
      );
    }
  }

  const status = getStatus();
  const memoizedResult = memoizeResult(status.isSuccess(), status.isSuccess() ? status.result : undefined);

  // const xtrct = query.data && options.extract(query.data);
  // console.log({
  //   networkStatus: query.networkStatus,
  //   status,
  //   extract: xtrct,
  //   memoDeps: options.memoDeps ? options.memoDeps(xtrct) : [xtrct],
  //   resultFromStatus: status.isSuccess() ? status.result : undefined,
  //   memoizedResult
  // });

  if (memoizedResult !== undefined) {
    lastResult.current = memoizedResult;
  }

  return [
    memoizedResult !== undefined
      ? status.withResult(memoizedResult)
      : lastResult.current !== undefined
      ? status.withResult(lastResult.current)
      : status,
    (overrides, soft) => {
      variablesOverrides.current = overrides;
      // [Jan 2024] Hard refresh shows a progress bar without delay, and it seems to be too annoying. Let's try
      // to use soft refreshes and see how it goes.
      refetch(RefetchMode.Soft);
      // refetch(soft ? RefetchMode.Soft : RefetchMode.Hard);
    }
  ];
}

export function useRawManagedQuery<QueryData, Vars, Deps>(
  options: Omit<ManagedQueryHookOptions<QueryData, Vars, Deps, QueryData, QueryData>, "extract" | "complete">
): ManagedQueryHook<Vars, QueryData> {
  return useManagedQuery<QueryData, Vars, Deps, QueryData, QueryData>({
    ...options,
    extract: identity,
    complete: identity
  });
}

export function useSimpleManagedQuery<QueryData, Vars>(
  query: (baseOptions?: QueryHookOptions<QueryData, Vars>) => QueryResult<QueryData, Vars>,
  vars: Vars,
  options: Omit<
    ManagedQueryHookOptions<QueryData, Vars, null, QueryData, QueryData>,
    "query" | "deps" | "prepare" | "extract" | "complete"
    > = {}
): ManagedQueryHook<Vars, QueryData> {
  return useManagedQuery<QueryData, Vars, null, QueryData, QueryData>({
    ...options,
    query,
    deps: null,
    prepare: () => vars,
    extract: identity,
    complete: identity
  });
}
