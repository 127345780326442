import * as React from "react";
import { styled } from "../../app/theme";

const Description = styled.div`
  padding-top: 0.2rem;
  font-size: 0.85rem;
`;

interface Props {
  title: string;
  description: string;

  href?: string;
  download?: boolean;

  onClick?: () => void;
}

export const ToolWithDescriptionLink: React.FunctionComponent<Props> = (props) => {
  const body = (
    <>
      {props.title}
      <Description>{props.description}</Description>
    </>
  );

  if (props.onClick) {
    const onClick = props.onClick;
    return (
      <a
        href={"#"}
        onClick={(event) => {
          onClick();
          event.preventDefault();
        }}
      >
        {body}
      </a>
    );
  } else if (props.href) {
    return <a href={props.href} download={props.download}>{body}</a>;
  } else {
    return <div>{body}</div>;
  }
};
