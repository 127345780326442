import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const term: string = "term";
  export const id: string = "id";
}

// Important: level 2 masks are relative to the root mask because they are used in a level 2 <Routes> declaration,
// while all paths are relative to the context's root path (so they are semi-absolute).

export abstract class CRUDRoutes<Id, Item> {
  protected abstract readonly root: string;

  protected readonly search = "search";
  protected readonly list = "list";
  protected readonly overview = "view";
  protected readonly edit = "edit";
  protected readonly new = "new";

  constructor(protected context: RoutesContext) {}

  public get rootMask(): string {
    return this.root + "/*";
  }

  public get homeMask(): string {
    return "";
  }

  public get searchMask(): string {
    return this.search + "/:" + RouteParams.term;
  }

  public get homePath(): string {
    return this.searchPath(undefined);
  }

  public searchPath(term: string | undefined): string {
    return this.context.resolvePath(this.root + (term ? "/" + this.search + "/" + encodeURIComponent(term) : ""));
  }

  public searchParams(): CRUDRoutes.SearchParams {
    return { term: this.context.routeParams[RouteParams.term] };
  }

  public get listMask(): string {
    return this.list;
  }

  public get listPath(): string {
    return this.context.resolvePath(this.root + "/" + this.list);
  }

  public get overviewMask(): string {
    return this.overview + "/:" + RouteParams.id;
  }

  public overviewPath(id: Id): string {
    return this.context.resolvePath(this.root + "/" + this.overview + "/" + id);
  }

  // TODO Return undefined in case if ID cannot be parsed
  public overviewParams(): CRUDRoutes.OverviewParams<Id> {
    const id = this.context.routeParams[RouteParams.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get editMask(): string {
    return this.edit + "/:" + RouteParams.id;
  }

  public editPath(id: Id): string {
    return this.context.resolvePath(this.root + "/" + this.edit + "/" + id);
  }

  // TODO Return undefined in case if ID cannot be parsed
  public editParams(): CRUDRoutes.EditParams<Id> {
    const id = this.context.routeParams[RouteParams.id];
    if (id) {
      return { id: this.parseId(id) };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get newMask(): string {
    return this.new;
  }

  public get newPath(): string {
    return this.context.resolvePath(this.root + "/" + this.new);
  }

  public newState(template: Item | undefined): CRUDRoutes.NewState<Item> {
    return { template };
  }

  public newParams(): CRUDRoutes.NewParams<Item> {
    const state = this.context.location.state as CRUDRoutes.NewState<Item> | undefined;
    return { template: state?.template };
  }

  protected abstract parseId(s: string): Id;
}

export namespace CRUDRoutes {
  export interface SearchParams {
    term: string | undefined;
  }

  export interface OverviewParams<Id> {
    id: Id;
  }

  export interface EditParams<Id> {
    id: Id;
  }

  export interface NewParams<T> {
    template: T | undefined;
  }

  export interface NewState<T> {
    template: T | undefined;
  }
}
