import * as React from "react";
import { BlueprintDiff } from "../blueprintDiff";
import { SubSection } from "./subSection";
import { Blueprint } from "../blueprint";
import { BlueprintContext } from "../blueprintContext";
import { ComponentSummary } from "./componentSummary";

interface Props {
  blueprint: Blueprint;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  onComponentSelect: (componentId: string) => void;
}

export const BlueprintDiffList: React.FunctionComponent<Props> = (props) => (
  <>
    {
      props.diffs.components.valueSeq()
        .filter((componentDiff) => componentDiff.critical)
        .sortBy((componentDiff) => {
          const component = props.blueprint.get(componentDiff.id);
          const state = component.state(props.blueprintContext);
          const visualization = component.visualization(props.blueprintContext, state);
          return visualization.title + " " + visualization.summary;
        })
        .map((componentDiff) => {
          const component = props.blueprint.get(componentDiff.id);
          return (
            <SubSection key={componentDiff.id}>
              <ComponentSummary
                component={component}
                blueprintContext={props.blueprintContext}
                onComponentSelect={props.onComponentSelect}
              />
            </SubSection>
          );
        })
    }
  </>
);
