// [Tim] Borrowed the source code from here: https://www.npmjs.com/package/titleizejs
// For some reason I could not use the version from the npm package. :-\

interface IExceptions {
  keepUpperCaseLetters?: boolean;
  keepUpperCaseWords?: boolean;
  ignoreSymbols?: string;
  isSlug?: boolean;
}

const symbols = /[!¡⁄÷…æ«≤πø¬^˚¨∆¥˙†®´∑œåß∂ƒ∂©˙~µ∫√ç≈§±"#$%&()*+,./:;<=>¿?@[\\\]^_`{|}~‹™›€£¢∞]/;
const lowerCasedWords = [
  "a",
  "an",
  "the",
  "and",
  "but",
  "or",
  "nor",
  "via",
  "to",
  "on",
  "onto",
  "per",
  "for",
  "in",
  "into",
  "of",
  "by",
  "at",
  "as",
  "yet",
  "so",
];

const capitalize = (value: string) => {
  const splitValue = value.toString().split("");
  splitValue[0] = splitValue[0].toUpperCase();
  return splitValue.join("");
};

const cleanseSymbolList = (symbolsToIgnore: string, regex: string) => {
  let result = regex;
  symbolsToIgnore.split("").forEach((symbol) => {
    result = result.replace(new RegExp("[\\" + symbol + "]", "g"), "");
  });
  return result;
};

const getWordForTitle = (word: string, index: number, array: string[], exceptions: IExceptions = {}) => {
  if (word === word.toUpperCase() && exceptions.keepUpperCaseWords) {
    return word;
  } else if (exceptions.keepUpperCaseLetters) {
    return capitalize(word);
  } else if (index === 0 || index === array.length - 1) {
    return capitalize(word.toLowerCase());
  } else if (lowerCasedWords.includes(word.toLowerCase())) {
    return word.toLowerCase();
  } else {
    return capitalize(word.toLowerCase());
  }
};

export const titleize = (value: string, exceptions: IExceptions = {}) => {
  if (!value) {
    try {
      throw new Error("Empty string provided");
    } catch (err) {
      console.error(err.name + ":", err.message);
      throw err;
    }
  }
  const symbolsForRegex = exceptions.ignoreSymbols
    ? cleanseSymbolList(
      exceptions.ignoreSymbols,
      symbols.toString().slice(2, -2),
    )
    : symbols.toString().slice(2, -2);

  const symbolRegExp = new RegExp("[" + symbolsForRegex + "]", "gi");

  const prepared = exceptions.isSlug ? value.replace(/-/g, " ") : value;

  return prepared
    .replace(symbolRegExp, "")
    .split(" ")
    .filter((word) => {
      if (word) return word;
    })
    .map((word, index, array) => {
      if (exceptions.isSlug) {
        return getWordForTitle(word, index, array, exceptions);
      } else if (
        word.indexOf("-") !== -1 &&
        word.length >= 3 &&
        // The regexp appeared to include so called positive lookbehind which is not supported by Safari and possibly
        // other browsers. It's not clear why something so complicated had to be used here, so replaced with the simpler
        // version.
        // word.match(/(?<=[\w+])-(?=[\w+])/gi)
        word.match(/(\w+-)+\w+/gi)
      ) {
        return word
          .split("-")
          .map((w) => {
            return getWordForTitle(w, index, array, exceptions);
          })
          .join("-");
      } else if (
        (word.indexOf("-") !== -1 && word.length < 3) ||
        (word.indexOf("-") !== -1 &&
          word.length >= 3 &&
          // See the comment above
          // !word.match(/(?<=[\w+])-(?=[\w+])/gi))
          !word.match(/(\w+-)+\w+/gi))
      ) {
        const v = word.replace(/-/g, "");
        if (v.length > 0) {
          return getWordForTitle(
            word.replace(/-/g, ""),
            index,
            array,
            exceptions,
          );
        }
      } else {
        return getWordForTitle(word, index, array, exceptions);
      }
    })
    .join(" ")
    .trim();
};
