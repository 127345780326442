import * as React from "react";
import { styled } from "../../../../app/theme";
import { ReviewData } from "./reviewData";
import { FiveStars } from "../../../glyphs/fiveStars";
import { Spacer } from "../../../utils/spacer";
import { HasClickHandler, withSimpleClickHandler } from "../../../utils/simpleClickHandler";

export interface ReviewProps {
  review: ReviewData;
}

interface ReviewLinkProps extends HasClickHandler {
}

const StyledReviewLink = styled.div`
  padding: 0.5rem 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  font-size: 0.6875rem;
  text-decoration: none;

  ${(props) => props.theme.responsive.respondToXSmall()} {
  }

  @media(hover: hover) {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const ReviewLink: React.FunctionComponent<ReviewLinkProps> = (props) => (
  <StyledReviewLink onClick={props.onClick}>
    Read full review on Facebook
  </StyledReviewLink>
);

const SimpleReviewLink = withSimpleClickHandler(ReviewLink);

const ReviewBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  margin: 0.6rem 2rem;
  max-width: 20rem;
  background: ${(props) => props.theme.colors.white};
  padding: 1.8rem;
  min-height: 8rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) => props.theme.responsive.respondToSmall()} {
    flex: auto;
    max-width: none;
    min-height: 0;
    margin: 0.6rem;
  }
`;

const ReviewCredits = styled.div<{ tall: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.tall ? "5rem" : "auto"};

  ${(props) => props.theme.responsive.respondToSmall()} {
    flex-basis: 0;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 0.8rem;
`;

const ReviewStarsContainer = styled.div`
  height: 1.1rem;
  width: 5.6rem;
  padding: 0 0 0 0.5rem;
`;

const Quote = styled.div`
  font-size: 0.8125rem;
  padding-top: 0.75rem;
  line-height: 1.35;
`;

const Name = styled.div`
  padding-top: 0.75rem;
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 0.75rem;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 0.6875rem;
  line-height: 1.4;
  padding: 0.2rem 0 0 0;
`;

const ReviewStars = styled(FiveStars)`
  fill: #fbd103;
`;

export const Review: React.FunctionComponent<ReviewProps> = (props) => (
  <ReviewBlock>
    <RatingContainer>
      5.0
      <ReviewStarsContainer>
        <ReviewStars/>
      </ReviewStarsContainer>
    </RatingContainer>
    <Quote>
      {props.review.quote}
    </Quote>
    <Spacer/>
    <ReviewCredits tall={!!props.review.link}>
      <Name>
        {props.review.name}
      </Name>
      {props.review.description && (
        <Description>
          {props.review.description}
        </Description>
      )}
      {props.review.link && (
        <>
          <Spacer/>
          <SimpleReviewLink onSimpleClick={props.review.link}/>
        </>
      )}
    </ReviewCredits>
  </ReviewBlock>
);

interface ReviewsContainerProps {
  centered?: boolean;
  largeTopPadding?: boolean;
  noBottomPadding?: boolean;
}

export const ReviewsContainer = styled.div<ReviewsContainerProps>`
  display: flex;
  justify-content: ${(props) => !props.centered ? "left" : "space-around"};
  margin: ${(props) => !props.centered ? "0" : "auto"};
  padding: ${(props) => props.largeTopPadding ? 4 : 2.5}rem 0 ${(props) => props.noBottomPadding ? 0 : 2.5}rem;
  max-width: 54rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    flex-direction: column;
  }
`;
