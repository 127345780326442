import * as React from "react";
import { ConnectionPanelDefs } from "../connectionPanelDefs";
import { ConnectionPanelRow } from "../../connectionPanelRow";
import { friendlySize } from "../../../../utils/formatting";
import { shouldDisplayAreaRow } from "../areasForm";
import { WorkStatus } from "../../../models/workStatus";
import { prepareActionItems } from "../prepareActionItems";
import { ActionItems } from "../../../models/actionItem";
import { Omit } from "react-redux";
import { CloudService } from "../../../../types/models/cloudService";
import { List } from "immutable";
import { InfoLink } from "../../../widgets/infoLink";

export function totalConnectionAndAreaActionableItems(
  connection: ConnectionPanelDefs.ConnectionDetailsEx,
  areas: List<ConnectionPanelDefs.Area>
): number {
  return (
    ActionItems.Stats.build(prepareActionItems({
      subjectId: connection.connection.id,
      affectedSubject: undefined,
      ...connection
    })).totalActionable +
    areas
      .map((area) => ActionItems.Stats.build(prepareActionItems({
        subjectId: area.areaId,
        affectedSubject: area.mainSubject,
        ...area
      })).totalActionable)
      .reduce((a, b) => a + b, 0)
  );
}

export function shouldDisplayConnectionStatusRow(
  connection: ConnectionPanelDefs.ConnectionDetailsEx,
  areas: List<ConnectionPanelDefs.Area>,
  viewSettings: ConnectionPanelDefs.ViewSettings): boolean {
  if (viewSettings.showProgress) {
    return true;
  } else if (viewSettings.showConnectionStatusRow === ConnectionPanelDefs.ShowConnectionStatusRow.Always) {
    return true;
  } else {
    const connectionIssues = !prepareActionItems({
      subjectId: connection.connection.id,
      affectedSubject: undefined,
      ...connection
    }).isEmpty();
    if (viewSettings.showConnectionStatusRow === ConnectionPanelDefs.ShowConnectionStatusRow.WithConnectionIssues) {
      return connectionIssues;
    } else {
      // Showing if there are issues to show and (a) there are errors or (b) any areas wil be rendered
      return connectionIssues && (
        totalConnectionAndAreaActionableItems(connection, areas) !== 0 ||
        !!areas.find((area) => shouldDisplayAreaRow(area, viewSettings))
      );
    }
  }
}

interface ShouldDisplayConnectionStatusRowExParams {
  connectionDetails: ConnectionPanelDefs.ConnectionDetailsEx;
  areas: List<ConnectionPanelDefs.Area>;
  viewSettings: ConnectionPanelDefs.ViewSettings;
}

export function shouldDisplayConnectionStatusRowEx(params: ShouldDisplayConnectionStatusRowExParams): boolean {
  return shouldDisplayConnectionStatusRow(params.connectionDetails, params.areas, params.viewSettings);
}

const ConnectionStatusRowTitle = "Secure account connection";

interface ConnectionStatusRowProps {
  cloudService: CloudService;
  checkboxPlaceholder: boolean;
  showProgressIndicator: boolean;
}

function renderAvailableStorage(availableStorage: ConnectionPanelDefs.AvailableStorageInfo.Any | undefined) {
  if (availableStorage) {
    const storageType = ConnectionPanelDefs.AvailableStorageInfo.getStorageType(availableStorage);

    switch (availableStorage.type) {
      case ConnectionPanelDefs.AvailableStorageInfo.Type.Collecting:
        return `Successfully connected. Reading available ${storageType}...`;

      case ConnectionPanelDefs.AvailableStorageInfo.Type.Collected:
      case ConnectionPanelDefs.AvailableStorageInfo.Type.Enough:
      case ConnectionPanelDefs.AvailableStorageInfo.Type.NotEnough:
        if (availableStorage.available === -1) {
          return `Successfully connected. Unlimited ${storageType} available.`;
        } else {
          return `Successfully connected. ${friendlySize(availableStorage.available)} available ${storageType}.`;
        }

      case ConnectionPanelDefs.AvailableStorageInfo.Type.UnableToCollect:
        return (
          <>
            Connected, but unable to read available {storageType}.&nbsp;
            {
              availableStorage.helpArticle &&
              <InfoLink helpArticle={availableStorage.helpArticle}>Learn more</InfoLink>
            }
          </>
        );
    }
  }
  return "Successfully connected.";
}

interface ConnectionSuccessRowProps extends Omit<ConnectionStatusRowProps, "cloudService"> {
  connection: ConnectionPanelDefs.ConnectionDetailsEx;
  viewSettings: ConnectionPanelDefs.ViewSettings;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const ConnectionSuccessRow: React.FunctionComponent<ConnectionSuccessRowProps> = (props) => (
  <ConnectionPanelRow
    icon={props.connection.cloudService.icon}
    title={ConnectionStatusRowTitle}
    content={{
      summary: renderAvailableStorage(props.connection.availableStorage),
      actionItems: prepareActionItems({
        subjectId: props.connection.connection.id,
        affectedSubject: undefined,
        ...props.connection
      })
    }}
    status={
      props.connection.availableStorage &&
      props.connection.availableStorage.type === ConnectionPanelDefs.AvailableStorageInfo.Type.Collecting
        ? WorkStatus.Working
        : WorkStatus.Success
    }
    checkBox={props.checkboxPlaceholder ? "placeholder" : undefined}
    progressIndicator={"hidden"}
    actionItemSuppressing={props.actionItemSuppressing}
  />
);
