import { PricingModel } from "../../types/models/pricingModel";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { PricingModelFormData } from "../../types/models/pricingModelFormData";

type InsertPricingModelMutationHook = [
  (formData: PricingModelFormData) => Promise<PricingModel>,
  OperationStatus<PricingModel>
];

export function useInsertPricingModelMutation(): InsertPricingModelMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertPricingModelMutation,
    extract: (data: GraphQL.InsertPricingModelMutation) => nullToUndefined(data.insertPricingModel),
    complete: PricingModel.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(formData: PricingModelFormData): Promise<PricingModel> {
    return fire({
      variables: { formData: PricingModelFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
