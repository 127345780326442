import { GraphQL } from "../../services/graphql/generated";
import { friendlyCount } from "../../utils/formatting";
import { mapOptional, nullToUndefined, roundCurrencyAmount } from "../../utils/misc";
import { DiscountType } from "./discountType";
import { SponsorshipConfig } from "./sponsorshipConfig";
import { UsedSponsorship } from "./usedSponsorship";

export interface PricingModelCalculator {
  priceBeforeDiscounts: (totalBytes: number, totalItems: number) => number;

  makePaymentSpec: PricingModelCalculator.MakePaymentSpecFunction;

  renderBreakdown: (totalBytes: number, totalItems: number) => PricingModelCalculator.PricingItem[][];

  isProgramDiscountApplied: boolean;
}

export namespace PricingModelCalculator {
  export interface PricingItem {
    title: string;
    price: number | string;
    comment?: string;
  }

  export function parse(data: GraphQL.PricingModelCalculator): PricingModelCalculator {
    switch (data.__typename) {
      case "SimplePricingModelCalculator":
        return SimplePricingModelCalculator.parseCore(data);

      default: throw Error("Unknown pricing model type: " + data.__typename);
    }
  }

  export interface PaymentSpec {
    priceAfterDiscounts: number;
    usedSponsorship: UsedSponsorship | undefined;
    amountToBePaid: number;
  }

  export type MakePaymentSpecFunction = (price: number, discounts: number[]) => PaymentSpec;
}

export interface SimplePricingModelCalculator extends PricingModelCalculator {
  readonly pricingModelName: string;
  readonly basePriceDescription: string;

  readonly previousMigrationFromSource: string | undefined;
  readonly previousMigrationFromSourceToDestination: string | undefined;
  readonly programId: string | undefined;

  readonly firstMigrationBasePrice: number;
  readonly numberOfGbsIncluded: number;
  readonly numberOfItemsIncluded: number;

  readonly followUpMigration: SimplePricingModelCalculator.FollowUpMigration | undefined;

  readonly originalBasePrice: number;
  readonly originalPricePerGb: number;
  readonly originalPricePerThousandItems: number;

  readonly programDiscount: SimplePricingModelCalculator.ProgramDiscount | undefined;
  readonly finalBasePrice: number;
  readonly dataFeeProgramDiscount: number | undefined;

  readonly sponsorshipConfig: SponsorshipConfig | undefined;
}

export namespace SimplePricingModelCalculator {
  export interface FollowUpMigration {
    readonly previousMigrationFromSourceToDestination: string;
  }

  export namespace FollowUpMigration {
    export function parse(data: GraphQL.SimplePricingModelCalculator_FollowUpMigration): FollowUpMigration {
      return {
        previousMigrationFromSourceToDestination: data.previousMigrationFromSourceToDestination
      };
    }
  }

  export interface ProgramDiscount {
    readonly programName: string;
    readonly percentage: number;
    readonly discountType: DiscountType;
  }

  export namespace ProgramDiscount {
    export function parse(data: GraphQL.SimplePricingModelCalculator_ProgramDiscount): ProgramDiscount {
      return {
        programName: data.programName,
        percentage: data.percentage,
        discountType: data.discountType
      };
    }
  }

  export function parseCore(data: GraphQL.SimplePricingModelCalculator): SimplePricingModelCalculator {
    const followUpMigration = mapOptional(data.followUpMigration, FollowUpMigration.parse);
    const programDiscount = mapOptional(data.programDiscount, ProgramDiscount.parse);
    const sponsorshipConfig = mapOptional(data.sponsorshipConfig, SponsorshipConfig.parse);

    function calculate(totalBytes: number, totalItems: number) {
      const additionalBytes = Math.max(0, totalBytes - data.numberOfGbsIncluded * 1024 * 1024 * 1024);
      const additionalItems = Math.max(0, totalItems - data.numberOfItemsIncluded);

      const originalBytesFee = roundCurrencyAmount(
        data.originalPricePerGb * (additionalBytes / (1024 * 1024 * 1024))
      );
      const originalItemsFee = roundCurrencyAmount(
        data.originalPricePerThousandItems * (additionalItems / 1000)
      );
      const originalBasePriceWithDataFees = roundCurrencyAmount(
        data.originalBasePrice + originalBytesFee + originalItemsFee
      );

      const finalBytesFee = data.dataFeeProgramDiscount !== undefined
        ? roundCurrencyAmount(
          originalBytesFee - roundCurrencyAmount(originalBytesFee / 100 * data.dataFeeProgramDiscount)
        )
        : originalBytesFee;
      const finalItemsFee = data.dataFeeProgramDiscount !== undefined
        ? roundCurrencyAmount(
          originalItemsFee - roundCurrencyAmount(originalItemsFee / 100 * data.dataFeeProgramDiscount)
        )
        : originalItemsFee;
      const priceBeforeDiscounts = roundCurrencyAmount(data.finalBasePrice + finalBytesFee + finalItemsFee);

      return {
        additionalBytes,
        additionalItems,

        originalBytesFee,
        originalItemsFee,
        originalBasePriceWithDataFees,

        finalBytesFee,
        finalItemsFee,
        priceBeforeDiscounts
      };
    }

    function calcPriceBeforeDiscounts(totalBytes: number, totalItems: number): number {
      return calculate(totalBytes, totalItems).priceBeforeDiscounts;
    }

    function makePaymentSpec(priceBeforeDiscounts: number, discounts: number[]): PricingModelCalculator.PaymentSpec {
      const totalDiscount = discounts.reduce((a, b) => roundCurrencyAmount(a + b), 0);
      const priceAfterDiscounts = Math.max(0, roundCurrencyAmount(priceBeforeDiscounts - totalDiscount));
      const usedSponsorship = sponsorshipConfig && {
        sponsorshipConfig,
        sponsoredAmount: sponsorshipConfig.maxSponsoredAmount === -1
          ? priceAfterDiscounts
          : Math.min(priceAfterDiscounts, sponsorshipConfig.maxSponsoredAmount)
      };
      const amountToBePaid = roundCurrencyAmount(priceAfterDiscounts - (usedSponsorship?.sponsoredAmount || 0));
      return {
        priceAfterDiscounts,
        usedSponsorship,
        amountToBePaid: amountToBePaid < 1 ? 0 : amountToBePaid
      };
    }

    function renderBreakdown(totalBytes: number, totalItems: number): PricingModelCalculator.PricingItem[][] {
      const numbers = calculate(totalBytes, totalItems);

      return [
        [{
          title: "Migration Fee",
          price: data.originalBasePrice,
          comment: followUpMigration
            ? "Follow up to migration " + followUpMigration.previousMigrationFromSourceToDestination
            : data.basePriceDescription
        }].concat(
          numbers.additionalBytes
            ? [
              {
                title: "+ GB Data Fees",
                price: "$" + numbers.originalBytesFee.toFixed(2),
                comment:
                  "$" + data.originalPricePerGb.toFixed(2) + " per " +
                  (
                    data.numberOfGbsIncluded
                      ? "additional GB of data migrated (" + data.numberOfGbsIncluded + " GB included)"
                      : "GB of data migrated"
                  ) +
                  "\n" +
                  (numbers.additionalBytes / (1024 * 1024 * 1024)).toFixed(2) + " " +
                  (data.numberOfGbsIncluded ? "additional GB" : "GB") + " x $" +
                  data.originalPricePerGb.toFixed(2) + " per GB"
              }
            ]
            : []
        ).concat(
          numbers.additionalItems
            ? [
              {
                title: "+ Items Data Fees",
                price: "$" + numbers.originalItemsFee.toFixed(2),
                comment:
                  "$" + data.originalPricePerThousandItems.toFixed(2) + " per " +
                  (
                    data.numberOfItemsIncluded
                      ? "additional 1,000 items migrated (" +
                      friendlyCount(data.numberOfItemsIncluded, "item") +
                      " included)"
                      : "1,000 items migrated"
                  ) +
                  "\n" +
                  friendlyCount(numbers.additionalItems, data.numberOfItemsIncluded ? "additional item" : "item") +
                  " x $" +
                  data.originalPricePerThousandItems.toFixed(2) + " per 1,000 items"
              }
            ]
            : []
        )
      ].concat(
        programDiscount
          ? [[
            {
              title: "Sub-Total (USD)",
              price: "$" + numbers.originalBasePriceWithDataFees.toFixed(2),
              comment: "To migrate " + (totalBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB and " +
                friendlyCount(totalItems, "item")
            },
            {
              title: "EDU Program Discount",
              price: "($" +
                (roundCurrencyAmount(numbers.originalBasePriceWithDataFees - numbers.priceBeforeDiscounts))
                  .toFixed(2) +
                ")",
              comment: programDiscount.programName + "\n" + programDiscount.percentage + "% off Migration Fee" +
                (programDiscount.discountType === DiscountType.BasePriceAndDataFees ? " and Data Fees" : "")
            },
          ]]
          : []
      );
    }

    return {
      pricingModelName: data.pricingModelName,
      basePriceDescription: data.basePriceDescription,

      previousMigrationFromSource: nullToUndefined(data.previousMigrationFromSource),
      previousMigrationFromSourceToDestination: nullToUndefined(data.previousMigrationFromSourceToDestination),
      programId: nullToUndefined(data.programId),

      firstMigrationBasePrice: data.firstMigrationBasePrice,
      numberOfGbsIncluded: data.numberOfGbsIncluded,
      numberOfItemsIncluded: data.numberOfItemsIncluded,

      followUpMigration,

      originalBasePrice: data.originalBasePrice,
      originalPricePerGb: data.originalPricePerGb,
      originalPricePerThousandItems: data.originalPricePerThousandItems,

      programDiscount,
      finalBasePrice: data.finalBasePrice,
      dataFeeProgramDiscount: data.dataFeeProgramDiscount,

      sponsorshipConfig,

      priceBeforeDiscounts: calcPriceBeforeDiscounts,
      makePaymentSpec,
      renderBreakdown,
      isProgramDiscountApplied: !!programDiscount
    };
  }
}
