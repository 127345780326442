export function getCookie(name: string): string | undefined {
  if (!document.cookie) {
    return undefined;
  }

  const xsrfCookies = document.cookie.split(";")
    .map((c) => c.trim())
    .filter((c) => c.startsWith(name + "="));

  if (xsrfCookies.length === 0) {
    return undefined;
  }

  return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}
