import * as React from "react";
import {
  TestAccountWhitelistingForProgramsView,
  WhitelistEmailAddressToolControllerProps
} from "../views/screens/testAccountWhitelistingForProgramsView";
import {
  useListTestAccountProgramAssignmentsQuery
} from "../queries/programs/useListTestAccountProgramAssignmentsQuery";
import {
  useWhitelistEmailAddressForProgramMutation
} from "../queries/programs/useWhitelistEmailAddressForProgramMutation";
import { useSearchProgramsQuery } from "../queries/programs/useSearchProgramsQuery";

export const TestAccountWhitelistingForProgramsPage: React.FunctionComponent = () => {
  const [status] = useListTestAccountProgramAssignmentsQuery();

  const controller = useWhitelistEmailAddressToolController();

  return (
    <TestAccountWhitelistingForProgramsView
      status={status}
      whitelistEmailAddressToolController={controller}
    />
  );
};

export const WhitelistEmailAddressToolController: React.FunctionComponent<WhitelistEmailAddressToolControllerProps> =
  (props) => {
    const [searchTerm, setSearchTerm] = React.useState<string>();
    const [searchStatus, refresh] = useSearchProgramsQuery(undefined, searchTerm);
    const [whitelist, whitelistingStatus] = useWhitelistEmailAddressForProgramMutation();

    return props.render({
      searchStatus,
      whitelistingStatus,
      onSearch: setSearchTerm,
      onRefresh: refresh,
      onWhitelist: whitelist,
      onRemove: (emailAddress) => whitelist(emailAddress, undefined)
    });
  };

export function useWhitelistEmailAddressToolController() {
  return React.useCallback(
    (controllerProps: WhitelistEmailAddressToolControllerProps) =>
      <WhitelistEmailAddressToolController {...controllerProps}/>,
    []
  );
}
