import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const jobId: string = "jobId";
  export const taskId: string = "taskId";
  export const issueId: string = "issueId";
}

export class JobsRoutes {
  private readonly root = "jobs";

  constructor(private context: RoutesContext) {}

  public jobParams(): JobsRoutes.JobParams {
    const jobId = this.context.routeParams[RouteParams.jobId];
    if (jobId) {
      return { jobId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get jobsMask(): string {
    return this.root + "/:" + RouteParams.jobId;
  }

  public jobPath(jobId: string): string {
    return this.context.resolvePath(this.root + "/" + jobId);
  }

  public taskParams(): JobsRoutes.TaskParams {
    const jobId = this.context.routeParams[RouteParams.jobId];
    const taskId = this.context.routeParams[RouteParams.taskId];
    if (jobId && taskId) {
      return { jobId, taskId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get tasksMask(): string {
    return this.root + "/:" + RouteParams.jobId + "/:" + RouteParams.taskId;
  }

  public taskPath(jobId: string, taskId: string): string {
    return this.context.resolvePath(this.root + "/" + jobId + "/" + taskId);
  }

  public taskIssueParams(): JobsRoutes.TaskIssueParams {
    const jobId = this.context.routeParams[RouteParams.jobId];
    const taskId = this.context.routeParams[RouteParams.taskId];
    const issueId = this.context.routeParams[RouteParams.issueId];
    if (jobId && taskId && issueId) {
      return { jobId, taskId, issueId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get taskIssuesMask(): string {
    return this.root + "/:" + RouteParams.jobId + "/:" + RouteParams.taskId + "/:" + RouteParams.issueId;
  }

  public taskIssuePath(jobId: string, taskId: string, issueId: string): string {
    return this.context.resolvePath(this.root + "/" + jobId + "/" + taskId + "/" + issueId);
  }
}

export namespace JobsRoutes {
  export interface JobParams {
    jobId: string;
  }

  export interface TaskParams {
    jobId: string;
    taskId: string;
  }

  export interface TaskIssueParams {
    jobId: string;
    taskId: string;
    issueId: string;
  }
}
