import * as React from "react";
import { AdminPortalSectionId } from "../../views/screens/organizationPortal/adminPortalNav";
import { useGetOrganizationContextQuery } from "../../queries/organizations/useGetOrganizationContextQuery";
import { useRoutes } from "../../app/routes/useRoutes";
import { AdminPortalSectionView } from "../../views/screens/organizationPortal/adminPortalSectionView";
import { Navigate } from "react-router";
import { OrganizationContext } from "../../types/models/organizationContext";
import { ErrorClass } from "../../services/graphql/errorClass";
import { useSession } from "../../utils/useAppState";
import { RedirectingPlaceholder } from "../../views/blocks/redirectingPlaceholder";

interface Props {
  sectionId: AdminPortalSectionId;
  children: (organizationContext: OrganizationContext, refresh: () => void) => React.ReactElement;
}

export const AdminPortalSection: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  const session = useSession();
  const params = routes.organizationPortalRoutes.params();

  const [status, refresh] = useGetOrganizationContextQuery(params.organizationIdOrAlias);

  if (
    !session ||
    status.isFailure() &&
    status.error.cause?.graphQLErrors &&
    Array.isArray(status.error.cause?.graphQLErrors) &&
    status.error.cause?.graphQLErrors.length > 0 &&
    status.error.cause?.graphQLErrors[0]?.extensions?.class === ErrorClass.AccessDeniedException
  ) {
    // Redirect to the URL that hosts a copy of the app which is identical to the main copy. After sign-in, we'll
    // redirect back to the main URL, which will cause the app to reload completely and update branding.
    window.location.replace(routes.organizationPortalRoutes.signInRedirectUrl(window.location.href));
    return <RedirectingPlaceholder message="Redirecting to sign-in..."/>;
  } else {
    return (
      <AdminPortalSectionView
        sectionId={props.sectionId}
        status={status}
        refresh={() => refresh({}, true)}
      >
        {props.children}
      </AdminPortalSectionView>
    );
  }
};

export const AdminPortalHome: React.FunctionComponent = () => {
  const routes = useRoutes();
  return (
    <Navigate
      to={routes.organizationPortalRoutes.dashboardPath(routes.organizationPortalRoutes.params().organizationIdOrAlias)}
    />
  );
};
