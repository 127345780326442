import * as React from "react";

interface DelayedProps {
  delay: number;
}

export const Delayed: React.FunctionComponent<DelayedProps> = (props) => {
  const [tooLong, setTooLong] = React.useState(false);

  React.useEffect(
    () => {
      if (props.delay) {
        setTooLong(false);
        const timer = setTimeout(() => setTooLong(true), props.delay);
        return () => clearTimeout(timer);
      }
    },
    [props.delay]
  );

  if (!props.delay || tooLong) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
