import * as React from "react";
import { styled } from "../../app/theme";
import { prettyPrint } from "../../utils/formatting";
import { State } from "../state";
import { Placeholder } from "./placeholder";
import { BlueprintDiff } from "../blueprintDiff";
import { DiffLabel } from "./diffLabel";
import { ServerLabel } from "./serverLabel";
import { deepToJSON } from "../../utils/misc";

enum Mode {
  Diff = "Diff",
  Local = "Local",
  Server = "Server"
}

const StyledPre = styled.pre<{ section: boolean, resolvedByServer: boolean, diff: boolean }>`
  margin: ${(props) => props.section ? -0.5 : 0}rem;
  padding: .5rem;
  font-size: .8rem;
  overflow: auto;
  
  border-left: ${(props) =>
  props.resolvedByServer
    ? "2px solid " + props.theme.colors.primary
    : props.diff ? "2px solid " + props.theme.colors.red : "none"
  };
  
  background: ${(props) =>
  props.resolvedByServer
    ? props.theme.colors.faintPrimary
    : props.diff ? props.theme.colors.subtleRed : "inherit"
  };
`;

function renderOutput(output: any) {
  return prettyPrint(deepToJSON(output)) || <Placeholder>Unavailable</Placeholder>;
}

interface Props {
  section: boolean;
  state: State<any>;
  resolvedByServer: boolean;
  diff: BlueprintDiff.ElementStateDiff | undefined;
}

export const ElementOutput: React.FunctionComponent<Props> = (props) => {
  if (props.diff) {
    if (props.diff.critical) {
      return <CriticalDiff section={props.section} diff={props.diff}/>;
    } else {
      return <NonCriticalDiff section={props.section} diff={props.diff}/>;
    }
  } else {
    return (
      <StyledPre section={props.section} resolvedByServer={props.resolvedByServer} diff={false}>
        {props.resolvedByServer && <ServerLabel/>}
        {renderOutput(props.state.output.toJS())}
      </StyledPre>
    );
  }
};

interface DiffProps {
  section: boolean;
  diff: BlueprintDiff.ElementStateDiff;
}

const CriticalDiff: React.FunctionComponent<DiffProps> = (props) => {
  const [mode, setMode] = React.useState<Mode>(Mode.Diff);

  React.useEffect(() => setMode(Mode.Diff), [props.diff]);

  function changeMode(setToDiff: boolean): void {
    if (setToDiff) {
      setMode(Mode.Diff);
    } else {
      switch (mode) {
        case Mode.Diff:
        case Mode.Server:
          setMode(Mode.Local);
          return;
        case Mode.Local:
          setMode(Mode.Server);
          return;
      }
    }
  }

  function outputDiff(): any {
    if (props.diff) {
      switch (mode) {
        case Mode.Diff: return State.getOutput(props.diff.stateDiff);
        case Mode.Local: return props.diff.localState.output;
        case Mode.Server: return props.diff.serverState.output;
      }
    }
  }

  return (
    <StyledPre section={props.section} resolvedByServer={false} diff={true}>
      <DiffLabel onClick={(event) => changeMode(event.ctrlKey)}>{mode.toLowerCase()}</DiffLabel>
      {renderOutput(outputDiff())}
    </StyledPre>
  );
};

const NonCriticalDiff: React.FunctionComponent<DiffProps> = (props) => {
  const [local, setLocal] = React.useState(true);

  React.useEffect(() => setLocal(true), [props.diff]);

  return (
    <StyledPre section={props.section} resolvedByServer={false} diff={false}>
      <DiffLabel onClick={() => setLocal(!local)}>{local ? "local" : "server"}</DiffLabel>
      {renderOutput(local ? props.diff.localState.output : props.diff.serverState.output)}
    </StyledPre>
  );
};
