import * as React from "react";
import { DataGroup, DataItem, IdType } from "vis";
import { Map, OrderedMap } from "immutable";
import { TimelineView } from "./timelineView";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { Button } from "../../widgets/button";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { Checkbox } from "../../widgets/checkbox";
import { stealKeyStrokesFrom } from "../../../utils/misc";

interface Props {
  subject: string;

  groups: OrderedMap<IdType, DataGroup>;
  items: Map<IdType, DataItem>;

  canFollow: boolean;
  additionalTools?: React.ReactNode;
}

export const TimelinePanel: React.FunctionComponent<Props> = (props) => {
  const fitCallbackRef = React.useRef<() => void>();
  const [follow, setFollow] = React.useState(false);
  const [showTooltips, setShowTooltips] = React.useState(false);

  React.useEffect(
    () => {
      document.addEventListener("keydown", keyDownCallback);
      document.addEventListener("keyup", keyUpCallback);
      return () => {
        document.removeEventListener("keydown", keyDownCallback);
        document.removeEventListener("keyup", keyUpCallback);
      };
    },
    []
  );

  function handleKeyDown(event: KeyboardEvent) {
    if (stealKeyStrokesFrom(event.target as Element)) {
      if (event.key === "Shift") {
        setShowTooltips(true);
      }
    }
  }

  function handleKeyUp(event: KeyboardEvent) {
    if (stealKeyStrokesFrom(event.target as Element)) {
      if (event.key === "Shift") {
        setShowTooltips(false);
      }
    }
  }

  const keyDownCallback = React.useCallback(handleKeyDown, []);
  const keyUpCallback = React.useCallback(handleKeyUp, []);

  return (
    <Panel>
      <PanelRow size={"small"}>
        <SimpleToolbar>
          <Button
            size={"small"}
            color={"white"}
            onClick={() => {
              if (fitCallbackRef.current) {
                fitCallbackRef.current();
              }
              setFollow(false);
            }}
          >
            Fit
          </Button>
          <Button
            size={"small"}
            color={props.canFollow && follow ? "blue" : "white"}
            onClick={() => setFollow(!follow)}
            disabled={!props.canFollow}
          >
            Follow
          </Button>
          <Checkbox checked={showTooltips} onChange={() => setShowTooltips(!showTooltips)}>
            Tooltips (use Shift!)
          </Checkbox>
          {props.additionalTools}
        </SimpleToolbar>
      </PanelRow>
      <GridPanelRow>
        <TimelineView
          subject={props.subject}
          follow={props.canFollow && follow ? 5 * 60 * 1000 : undefined}
          showTooltips={showTooltips}

          groups={props.groups}
          items={props.items}

          fitCallbackRef={fitCallbackRef}
          onRangeChange={() => setFollow(false)}
        />
      </GridPanelRow>
    </Panel>
  );
};
