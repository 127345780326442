import * as React from "react";
import { Panel } from "../containers/panel";
import { TitlePanelRow } from "../containers/rows/titlePanelRow";
import { PanelRow, PanelRowDefs } from "../containers/rows/panelRow";
import { SignInDefs } from "./auth/signInDefs";
import { ActionItem, ActionItems } from "../models/actionItem";
import { List } from "immutable";
import { Connection } from "../../types/models/connection";
import { ConnectResult } from "../../types/models/connectResult";

export namespace IncrementalSignInDefs {
  export interface SignInComponentProps {
    layoutComponent: SignInDefs.SignInLayoutComponentType;
    onSignIn: (connectResult: ConnectResult) => void;
  }

  export type SignInComponentType = React.ComponentType<SignInComponentProps>;

  export interface Settings {
    areaId: string;
    prompt: React.ReactNode;
    affectedSubject: string;
    connection: Connection;
    action?: string;
    signInComponent: SignInComponentType;
  }

  export namespace Settings {
    export function toIssues(settings: Settings, onSignInClick: (settings: Settings) => void): ActionItems {
      return List([{
        id: ActionItem.incrementalSignIn(settings.areaId),
        type: ActionItem.Type.Action,
        message: settings.prompt + ".",
        actions: [{
          title: settings.action || "Sign in",
          onClick: () => onSignInClick(settings)
        }],
        suppressAction: {
          result: ActionItem.SuppressAction.Result.WillNotMigrate,
          affectedSubject: settings.affectedSubject
        }
      }]);
    }

    export function mayBeToIssues(
      settings: Settings | undefined,
      onSignInClick: (settings: Settings) => void
    ): ActionItems {
      return settings ? toIssues(settings, onSignInClick) : List();
    }
  }
}

export interface IncrementalSignInProps {
  incrementalSignIn: IncrementalSignInDefs.Settings;
  layoutComponent: SignInDefs.SignInLayoutComponentType;
  onSignIn: () => void;
}

export const IncrementalSignIn: React.FunctionComponent<IncrementalSignInProps> = (props) => {
  const SignInComponent = props.incrementalSignIn.signInComponent;
  return (
    <>
      <Panel>
        <TitlePanelRow>Additional Sign-In</TitlePanelRow>
        <PanelRow>
          VaultMe needs additional permissions to migrate {props.incrementalSignIn.affectedSubject}s.
          To provide VaultMe with these permissions, please sign in to your account
          ({props.incrementalSignIn.connection.descriptionOrId()}) using the secure form below.
          If you choose to skip this step, VaultMe will be unable to migrate
          your {props.incrementalSignIn.affectedSubject}s.
        </PanelRow>
        <SignInComponent
          layoutComponent={props.layoutComponent}
          onSignIn={props.onSignIn}
        />
      </Panel>
    </>
  );
};
