import * as React from "react";
import { ControllerProps } from "../../../utils/controller";
import {
  GoogleGroupDetailsViewProps
} from "../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import { useDescribeGoogleGroupMutation } from "../../../queries/accessLists/useDescribeGoogleGroupMutation";

const DefaultGoogleGroupDetailsController
  : React.FunctionComponent<ControllerProps<GoogleGroupDetailsViewProps>> =
  (props) => {
    // This mutation supposed to be a query with cached result, but there appeared to be numerous problems with
    // retries both after an error and after a successful result. It appeared to be just easier for now to use a
    // mutation.
    const [describe, status] = useDescribeGoogleGroupMutation();

    return props.render({
      status,
      onRequest: describe
    });
  };

export function useGoogleGroupDetailsController() {
  return React.useCallback(
    (controllerProps: ControllerProps<GoogleGroupDetailsViewProps>) =>
      <DefaultGoogleGroupDetailsController {...controllerProps}/>,
    []
  );
}
