import * as React from "react";
import { Connection } from "../../../types/models/connection";
import { AccessKeyStatus } from "../../../types/models/accessKeyStatus";
import { PropertiesGrid, PropertyRow } from "../../widgets/propertiesGrid";
import { friendlyDateTime, multiline } from "../../../utils/formatting";
import { AuthProvider } from "../../../types/models/authProvider";
import { DrawerBlock } from "../../containers/drawerBlock";
import { CloudServiceNameAndIcon } from "./cloudServiceNameAndIcon";
import { styled } from "../../../app/theme";

interface ConnectionPropertiesProps {
  connection: Connection;
  authProvider: AuthProvider;
}

const StyledCloudServiceNameAndIcon = styled(CloudServiceNameAndIcon)`
  margin-top: .2rem;
`;

export const ConnectionProperties: React.FunctionComponent<ConnectionPropertiesProps> = (props) => (
  <DrawerBlock>
    <PropertiesGrid>
      <PropertyRow title="Connection ID">{props.connection.id}</PropertyRow>
      <PropertyRow title="Cloud Service">
        <StyledCloudServiceNameAndIcon cloudServiceId={props.connection.cloudServiceId}/>
      </PropertyRow>
      <PropertyRow title="Auth Provider">{props.authProvider.id}</PropertyRow>

      <PropertyRow title="First Name">{props.connection.personalInfo.firstName}</PropertyRow>
      <PropertyRow title="Last Name">{props.connection.personalInfo.lastName}</PropertyRow>
      <PropertyRow title="Email Address">{props.connection.personalInfo.emailAddress}</PropertyRow>
      <PropertyRow title="Phone Number">{props.connection.personalInfo.phoneNumber}</PropertyRow>
      <PropertyRow title="PayPal Email Address">{props.connection.personalInfo.payPalEmailAddress}</PropertyRow>
      <PropertyRow title="Time Zone">{props.connection.personalInfo.timeZone}</PropertyRow>

      <PropertyRow
        title="Access Key Status"
        status={props.connection.accessKeyStatus === AccessKeyStatus.Valid ? "normal" : "error"}
      >
        {props.connection.accessKeyStatus}
      </PropertyRow>

      <PropertyRow
        title="Granted Roles"
        status={props.connection.accessKeyStatus === AccessKeyStatus.Valid ? "normal" : "uncertain"}
      >
        {props.connection.roles && multiline(props.connection.roles.toList().sort())}
      </PropertyRow>

      <PropertyRow title="Enabled">{props.connection.enabled ? "Yes" : "No"}</PropertyRow>
      <PropertyRow title="Eligible For Sign-In">{props.connection.eligibleForSignIn ? "Yes" : "No"}</PropertyRow>

      <PropertyRow title="Created At">{friendlyDateTime(props.connection.createdAt)}</PropertyRow>
      <PropertyRow title="Updated At">{friendlyDateTime(props.connection.updatedAt)}</PropertyRow>
      <PropertyRow title="Validated At">{friendlyDateTime(props.connection.validatedAt)}</PropertyRow>
      <PropertyRow title="Signed In At">{friendlyDateTime(props.connection.signedInAt)}</PropertyRow>
      <PropertyRow title="Revealed At">
        {props.connection.revealedAt && friendlyDateTime(props.connection.revealedAt)}
      </PropertyRow>
      <PropertyRow title="Revoked At">
        {props.connection.revokedAt && friendlyDateTime(props.connection.revokedAt)}
      </PropertyRow>
    </PropertiesGrid>
  </DrawerBlock>
);
