import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List, Set } from "immutable";

type DeleteOffboardingProjectEntriesMutationHook = [
  (emailAddresses: Set<string>) => Promise<[number, List<string>]>,
  OperationStatus<[number, List<string>]>
];

export function useDeleteOffboardingProjectEntriesMutation(
  offboardingProjectId: string
): DeleteOffboardingProjectEntriesMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDeleteOffboardingProjectEntriesMutation,
    extract: (data: GraphQL.DeleteOffboardingProjectEntriesMutation) =>
      nullToUndefined(data.deleteOffboardingProjectEntries),
    complete: (result): [number, List<string>] =>
      [result.deletedEntryCount, List(result.skippedEmailAddresses)]
  });

  function fireWith(emailAddresses: Set<string>): Promise<[number, List<string>]> {
    return fire({
      variables: { id: offboardingProjectId, emailAddresses: emailAddresses.toArray() },
      retry: () => fireWith(emailAddresses),
    });
  }

  return [fireWith, status];
}
