import * as React from "react";
import { Set } from "immutable";
import { useAuthProviders, useCloudServices } from "../../app/configuration";
import { OperationStatus } from "../../types/operationStatus";
import { useRawManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { OneTimeAuthCodeSummary } from "../../types/models/oneTimeAuthCodeSummary";
import { ConnectResult } from "../../types/models/connectResult";
import { signedInAction } from "../../state/session/actions";
import { useDispatch } from "react-redux";
import { createSignInControllers } from "./createSignInControllers";
import { AuthProvider } from "../../types/models/authProvider";
import { CloudService } from "../../types/models/cloudService";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { Connection } from "../../types/models/connection";
import { useConnectMutation } from "./useConnectMutation";
import { SignInContextType } from "../../types/models/signInContextType";
import { NewUserSettings } from "../../types/models/newUserSettings";
import { Constants } from "../../app/constants";
import { useBrowserQuery } from "../../utils/useQuery";

export namespace SignInFlowState {
  export enum Type {
    SelectingCloudService = "SelectingCloudService",
    InitializingSignInForm = "InitializingSignInForm",
    PendingSignIn = "PendingSignIn",
    CompletingSignIn = "CompletingSignIn"
  }

  interface Base<T extends Type> {
    type: T;
  }

  export interface SelectingCloudService extends Base<Type.SelectingCloudService> {
  }

  export function SelectingCloudService(): SelectingCloudService {
    return { type: Type.SelectingCloudService };
  }

  export interface CloudServiceSelected {
    cloudService: CloudService;
    authProvider: AuthProvider;
    roles: Set<string>;
    defaultRoles: Set<string>;
  }

  interface InitializingSignInFormProps extends CloudServiceSelected {
    status: OperationStatus<any>;
  }

  export type InitializingSignInForm = Base<Type.InitializingSignInForm> & InitializingSignInFormProps;

  export function InitializingSignInForm(props: InitializingSignInFormProps): InitializingSignInForm {
    return { type: Type.InitializingSignInForm, ...props };
  }

  interface PendingSignInProps extends CloudServiceSelected {
    signInComponents: SignInDefs.SignInComponentType[];
  }

  export type PendingSignIn = Base<Type.PendingSignIn> & PendingSignInProps;

  export function PendingSignIn(props: PendingSignInProps): PendingSignIn {
    return { type: Type.PendingSignIn, ...props };
  }

  interface CompletingSignInProps extends CloudServiceSelected {
    status: OperationStatus<any>;
  }

  export type CompletingSignIn = Base<Type.CompletingSignIn> & CompletingSignInProps;

  export function CompletingSignIn(props: CompletingSignInProps): CompletingSignIn {
    return { type: Type.CompletingSignIn, ...props };
  }
}

export type SignInFlowState =
  SignInFlowState.SelectingCloudService |
  SignInFlowState.InitializingSignInForm |
  SignInFlowState.PendingSignIn |
  SignInFlowState.CompletingSignIn;

export interface SignInFlowHook {
  state: SignInFlowState;
  selectCloudService: (cloudServiceId: string) => void;
  selectRoles: (roles: Set<string>) => void;
  handleSignInSuccess: (oneTimeAuthCode: OneTimeAuthCodeSummary) => void;
}

export interface SignInFlowOptions {
  offerAccountCreation?: boolean;
}

export interface SignInFlowConfig {
  flowId: string;
  contextType: SignInContextType;
  newUserSettings: NewUserSettings | undefined;
  onSignIn?: (connectResult: ConnectResult) => void;
  defaultRoles: Set<string>;
  cloudServiceId?: string;
  existingConnection?: Connection;
  options?: SignInFlowOptions;
}

export function useSignInFlow(config: SignInFlowConfig): SignInFlowHook {
  const cloudServices = useCloudServices(config.contextType);
  const authProviders = useAuthProviders();
  const dispatch = useDispatch();

  const suppliedCloudServiceId =
    config.existingConnection && config.existingConnection.cloudServiceId || config.cloudServiceId;
  const [currentCloudServiceId, setCurrentCloudServiceId] = React.useState<string | undefined>(suppliedCloudServiceId);
  const cloudServiceId = suppliedCloudServiceId || currentCloudServiceId;

  const [customRoles, setCustomRoles] = React.useState<Set<string>>();
  const roles = customRoles || config.defaultRoles;

  // When default roles are changing, that's probably because we've switched context (like going from source account
  // connection to destination). In this situation, it's totally fare to reset custom roles if any;
  React.useEffect(
    () => setCustomRoles(undefined),
    [config.defaultRoles.toArray().sort().join(",")]
  );

  const [initSignInFormStatus, refreshSignInForm] = useRawManagedQuery({
    query: GraphQL.useGetSignInFormConfigQuery,
    deps: cloudServiceId,
    prepare: (id) => ({
      authFlowId: config.flowId,
      cloudServiceId: id,
      roles: filterSupportedRoles(id, roles).toArray(),
      // TODO The line below leads to funny bugs in Apollo!
      // roles: roles.toArray(),

      // Disabling check for upgradable(), because it doesn't seem to make sense. At least, after revoking a
      // connection using internal admin tools, the status of the connection becomes Revoked, and that results in
      // this connection to become not upgradable (?!) But, if all that happens during a migration, we definitely want
      // to reconnect an existing account, and this is a variation if 'upgrade' logic (going from no permissions to
      // all required permissions). Therefore, having upgradable() == false in this situation makes no logical sense.
      existingAccessKeyId: config.existingConnection // && config.existingConnection.upgradable()
        ? config.existingConnection.id
        : undefined
    }),
    fetchPolicy: "network-only"
  });

  const query = useBrowserQuery();
  const ambassadorCodeParam = query.getString(Constants.QueryParams.AmbassadorCode);

  const [connect, connectStatus] = useConnectMutation(config.contextType, refreshSignInForm);

  function handleSignInSuccess(oneTimeAuthCodeSummary: OneTimeAuthCodeSummary, retrying?: boolean): void {
    if (cloudServiceId !== undefined) {
      connect({
        contextType: config.contextType,
        cloudServiceId,
        oneTimeAuthCode: oneTimeAuthCodeSummary.code,
        newUserSettings: config.newUserSettings,
        ambassadorCode: ambassadorCodeParam
      }).then((connectResult) => {
        if (config.onSignIn) {
          config.onSignIn(connectResult);
        }
        dispatch(signedInAction(connectResult));

        setCurrentCloudServiceId(undefined);
        refreshSignInForm();
      });
    } else {
      throw Error("Internal error: cloudServiceId is undefined");
    }
  }

  function filterSupportedRoles(aCloudServiceId: string, aRoles: Set<string>): Set<string> {
    return aRoles.intersect(
      authProviders.getOrFail(cloudServices.getOrFail(aCloudServiceId).authProviderId).roles.toSet()
    );
  }

  function buildHook(state: SignInFlowState): SignInFlowHook {
    return {
      state,
      selectCloudService: setCurrentCloudServiceId,
      selectRoles: setCustomRoles,
      handleSignInSuccess
    };
  }

  if (cloudServiceId !== undefined) {
    const cloudService = cloudServices.getOrFail(cloudServiceId);
    const cloudServiceConfig = {
      cloudService,
      authProvider: authProviders.getOrFail(cloudService.authProviderId),
      roles: filterSupportedRoles(cloudServiceId, roles),
      defaultRoles: config.defaultRoles
    };

    if (!initSignInFormStatus.isSuccess()) {
      return buildHook(SignInFlowState.InitializingSignInForm({
        ...cloudServiceConfig,
        status: initSignInFormStatus,
      }));
    } else if (connectStatus.isPending()) {
      return buildHook(SignInFlowState.PendingSignIn({
        ...cloudServiceConfig,
        signInComponents: createSignInControllers(
          initSignInFormStatus.result.getSignInFormConfig,
          !!config.options?.offerAccountCreation
        )
      }));
    } else {
      return buildHook(SignInFlowState.CompletingSignIn({
        ...cloudServiceConfig,
        status: connectStatus
      }));
    }
  } else {
    return buildHook(SignInFlowState.SelectingCloudService());
  }
}
