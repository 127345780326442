import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { FormErrorMessage } from "./formErrorMessage";
import { Checkbox } from "./checkbox";

interface CheckboxFieldProps<T> {
  label: React.ReactNode;
  noWrap?: boolean;
  name: keyof T;
  readOnly?: boolean;
  disabled?: boolean;
}

// tslint:disable-next-line function-name
export function CheckboxField<V>(props: CheckboxFieldProps<V>) {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => (
        <FormField>
          <Checkbox
            {...field.field}
            readOnly={props.readOnly}
            disabled={props.disabled}
            noWrap={props.noWrap}
            checked={field.field.value}
            onChange={(event) => {
              field.form.setFieldError(event.target.name, "");
              field.field.onChange(event);
            }}
          >
            {props.label}
          </Checkbox>
          <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
        </FormField>
      )}
    />
  );
}
