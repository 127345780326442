import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProject } from "../../types/models/offboardingProject";

type RestartCurrentEmailDeliveryRoundMutationHook = [
  (expectedCurrentRound: number) => Promise<OffboardingProject>,
  OperationStatus<OffboardingProject>
];

export function useRestartCurrentEmailDeliveryRoundMutation(
  offboardingProjectId: string
): RestartCurrentEmailDeliveryRoundMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useRestartCurrentEmailDeliveryRoundMutation,
    extract: (data: GraphQL.RestartCurrentEmailDeliveryRoundMutation) =>
      nullToUndefined(data.restartCurrentEmailDeliveryRound),
    complete: OffboardingProject.parse
  });

  function fireWith(expectedCurrentRound: number): Promise<OffboardingProject> {
    return fire({
      variables: { offboardingProjectId, expectedCurrentRound },
      retry: () => fireWith(expectedCurrentRound),
    });
  }

  return [fireWith, status];
}
