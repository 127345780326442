import * as React from "react";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { Grid } from "../widgets/grid";
import { Panel } from "../containers/panel";
import { GridPanelRow } from "../containers/rows/gridPanelRow";
import { KebabButton } from "../widgets/kebabButton";
import { WidgetStatus } from "../utils/widgetStatus";

export const Grids: React.FunctionComponent = (props) => (
  <>
    <StyleguideHeader>Simple grid</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <GridPanelRow>
          <Grid selectable={true}>
            <Grid.Header>
              <Grid.Column>Column 1</Grid.Column>
              <Grid.Column>Column 2</Grid.Column>
              <Grid.Column/>
            </Grid.Header>
            <Grid.Body>
              <Grid.Row>
                <Grid.Group colSpan={3}>Group 1</Grid.Group>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell>
                  Some value
                </Grid.Cell>
                <Grid.Cell>
                  Some other regular boring value
                </Grid.Cell>
                <Grid.ActionsCell actionType={"kebab"}>
                  <KebabButton/>
                </Grid.ActionsCell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell>
                  Something
                </Grid.Cell>
                <Grid.Cell status={WidgetStatus.Error}>
                  Error value
                </Grid.Cell>
                <Grid.ActionsCell actionType={"kebab"}>
                  <KebabButton/>
                </Grid.ActionsCell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Group colSpan={3}>Group 2</Grid.Group>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell>
                  Something
                </Grid.Cell>
                <Grid.Cell status={WidgetStatus.Uncertain}>
                  Uncertain value
                </Grid.Cell>
                <Grid.ActionsCell actionType={"kebab"}>
                  <KebabButton/>
                </Grid.ActionsCell>
              </Grid.Row>
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Cell types</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <GridPanelRow>
          <Grid>
            <Grid.Body>
              <Grid.Row>
                <Grid.Cell>
                  Regular
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell status={WidgetStatus.Error}>
                  Error
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell status={WidgetStatus.Warning}>
                  Warning
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell status={WidgetStatus.Success}>
                  Success
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell status={WidgetStatus.Info}>
                  Info
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.Cell status={WidgetStatus.Uncertain}>
                  Uncertain
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row>
                <Grid.ProgressCell progress={33}>
                  Making progress!
                </Grid.ProgressCell>
              </Grid.Row>
            </Grid.Body>
          </Grid>
        </GridPanelRow>
      </Panel>
    </StyleguideBlock>
  </>
);
