import * as React from "react";
import { styled } from "../../app/theme";
import { PreparedHelpArticle } from "../../utils/preparedHelpArticle";
import { useDrawer } from "../layouts/drawer";

type InfoButtonSize = "default" | "small";

export interface InfoButtonProps {
  helpArticle: PreparedHelpArticle;
  size?: InfoButtonSize;
  clickSideEffect?: () => void;
}

const Tooltip = styled.div`
  visibility: hidden;
  width: 5.5rem;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: 0 1px 2px rgba(124,124,124,0.5);
  color: ${(props) => props.theme.colors.white};
  font-size: 0.55rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  padding: 0.3rem 0.5rem;
  border-radius: 0.1rem;
  position: absolute;
  bottom: 1.4rem;
  right: 1.4rem;
  z-index: ${(props) => props.theme.layers.tooltip};
`;

export const InfoButtonDefaultSize = 1.65;

const StyledInfoButton = styled.div<{ sizeCoeff: number }>`
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  box-sizing: border-box;
  width: ${(props) => props.sizeCoeff * InfoButtonDefaultSize}rem;
  height: ${(props) => props.sizeCoeff * InfoButtonDefaultSize}rem;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  padding: ${(props) => props.sizeCoeff * 0.2}rem 0;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.sizeCoeff * 1.0}rem;

  &:active {
    transform: scale(1.5);
    background-color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: none;
  }

  @media(hover: hover) {
    &:hover {
      transition: 0.1s;
      transform: scale(1.5);
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};
      box-shadow: 0px 1px 2px rgba(124,124,124,0.5);

      // The line below is required to overcome the problem when z-index is ignored when used together with transform
      // Somehow it helps!
      z-index: ${(props) => props.theme.layers.tooltip};

      > ${Tooltip} {
        visibility: visible;
      }
    }
  }
`;

export const InfoButton: React.FunctionComponent<InfoButtonProps> = (props) => {
  const drawer = useDrawer();

  const tooltip = props.helpArticle.summary || props.helpArticle.title;

  return (
    <StyledInfoButton
      onClick={(event) => {
        drawer.open({ content: props.helpArticle.content, title: props.helpArticle.title });

        if (props.clickSideEffect) {
          props.clickSideEffect();
        }

        // This prevents clicks from bubbling up and triggering row checkboxes where they are added.
        // In case of undesirable side-effects, pass a special property to enable this feature.
        event.preventDefault();
      }}
      sizeCoeff={props.size === "small" ? 0.85 : 1.0}
    >
      ?
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </StyledInfoButton>
  );
};
