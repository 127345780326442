import * as React from "react";
import * as yup from "yup";
import { PricingModel } from "../../../types/models/pricingModel";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { friendlyDateTime } from "../../../utils/formatting";
import { SimplePanel } from "../../containers/simplePanel";
import { ItemForm } from "../crud/itemForm";
import { FormConfig } from "../crud/formConfig";
import { PricingModelFormData } from "../../../types/models/pricingModelFormData";

interface FormData {
  readonly name: string;
  readonly basePriceDescription: string;
  readonly notes: string;

  readonly basePrice: string;
  readonly basePriceForFollowUpMigrations: string;
  readonly numberOfGbsIncluded: string;
  readonly numberOfItemsIncluded: string;
  readonly pricePerGb: string;
  readonly pricePerThousandItems: string;
}

namespace FormData {
  export const blank: FormData = {
    name: "",
    basePriceDescription: "",
    notes: "",

    basePrice: "12.99",
    basePriceForFollowUpMigrations: "1.00",
    numberOfGbsIncluded: "0",
    numberOfItemsIncluded: "0",
    pricePerGb: "0.20",
    pricePerThousandItems: "0.20"
  };

  export function make(pricingModel: PricingModel): FormData {
    return {
      name: pricingModel.name,
      basePriceDescription: pricingModel.basePriceDescription,
      notes: pricingModel.notes || "",

      basePrice: pricingModel.basePrice.toFixed(2),
      basePriceForFollowUpMigrations: pricingModel.basePriceForFollowUpMigrations
        ? pricingModel.basePriceForFollowUpMigrations.toFixed(2)
        : "",
      numberOfGbsIncluded: pricingModel.numberOfGbsIncluded.toString(),
      numberOfItemsIncluded: pricingModel.numberOfItemsIncluded.toString(),
      pricePerGb: pricingModel.pricePerGb.toFixed(2),
      pricePerThousandItems: pricingModel.pricePerThousandItems.toFixed(2)
    };
  }

  export function toResult(formData: FormData): PricingModelFormData {
    return {
      name: formData.name.trim(),
      basePriceDescription: formData.basePriceDescription.trim(),
      notes: formData.notes.length ? formData.notes : undefined,

      basePrice: Number.parseFloat(formData.basePrice),
      basePriceForFollowUpMigrations: formData.basePriceForFollowUpMigrations.length
        ? Number.parseFloat(formData.basePriceForFollowUpMigrations)
        : undefined,
      numberOfGbsIncluded: Number.parseFloat(formData.numberOfGbsIncluded),
      numberOfItemsIncluded: Number.parseFloat(formData.numberOfItemsIncluded),
      pricePerGb: Number.parseFloat(formData.pricePerGb),
      pricePerThousandItems: Number.parseFloat(formData.pricePerThousandItems)
    };
  }

  export const validationSchema = yup.object<FormData>().shape({
    name: yup.string().required("Pricing config name is required"),
    basePriceDescription: yup.string().required("Migration fee name is required"),
    notes: yup.string(),

    basePrice: yup.string().test(
      "maxDigitsAfterDecimal",
      "Migration fee must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    basePriceForFollowUpMigrations: yup.string().test(
      "maxDigitsAfterDecimal",
      "Migration fee for follow-up migrations must be a number containing no more than two decimal places (x.xx)",
      (amount) => amount === "" || /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    numberOfGbsIncluded: yup.string().test(
      "integer",
      "Number of GBs included must be an integer",
      (amount) => /^\d+$/.test(amount)
    ),
    numberOfItemsIncluded: yup.string().test(
      "integer",
      "Number of items included must be an integer",
      (amount) => /^\d+$/.test(amount)
    ),
    pricePerGb: yup.string().test(
      "maxDigitsAfterDecimal",
      "Price per GB must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
    pricePerThousandItems: yup.string().test(
      "maxDigitsAfterDecimal",
      "Price per 1,000 items must be a number containing no more than two decimal places (x.xx)",
      (amount) => /^\d+(\.\d{1,2})?$/.test(amount)
    ),
  });
}

interface Props {
  config: FormConfig<PricingModel, PricingModelFormData>;
}

export const PricingModelForm: React.FunctionComponent<Props> = (props) => (
  <ItemForm<PricingModel, FormData, PricingModelFormData>
    config={props.config}

    subject={"Pricing Config"}

    blankFormData={FormData.blank}
    makeFormData={FormData.make}
    makeResult={FormData.toResult}
    validationSchema={FormData.validationSchema}
  >
    {({ firstInputRef, submitting, formProps }) => (
      <>
        <SimplePanel>
          <FormLayout noBottomMargin={true}>
            <TextField<FormData>
              label={"Name"}
              name={"name"}
              required={true}
              maxLength={200}
              disabled={submitting}
              textBoxRef={props.config.isNew ? firstInputRef as React.RefObject<HTMLInputElement> : undefined}
            />
            <TextField<FormData>
              label={"Migration fee name"}
              name={"basePriceDescription"}
              required={true}
              maxLength={200}
              disabled={submitting}
            />
            <TextAreaField<FormData>
              label={"Internal notes"}
              name={"notes"}
              disabled={submitting}
            />
            {
              props.config.item && !props.config.isNew &&
              <FormField>Created at: {friendlyDateTime(props.config.item.createdAt)}</FormField>
            }
          </FormLayout>
        </SimplePanel>
        <SimplePanel>
          <FormLayout noBottomMargin={true}>
            <TextField<FormData>
              label={"Migration fee"}
              name={"basePrice"}
              required={true}
              maxLength={10}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
            <TextField<FormData>
              label={"Migration fee for follow-up migrations"}
              name={"basePriceForFollowUpMigrations"}
              maxLength={10}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
            <TextField<FormData>
              label={"Number of GBs included"}
              name={"numberOfGbsIncluded"}
              required={true}
              maxLength={9}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
            <TextField<FormData>
              label={"Number of items included"}
              name={"numberOfItemsIncluded"}
              required={true}
              maxLength={9}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
            <TextField<FormData>
              label={"Price per GB"}
              name={"pricePerGb"}
              required={true}
              maxLength={10}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
            <TextField<FormData>
              label={"Price per 1,000 items"}
              name={"pricePerThousandItems"}
              required={true}
              maxLength={10}
              autoComplete={false}
              disabled={submitting}
              dirtyRegExp={/^[\.\d]*$/}
            />
          </FormLayout>
        </SimplePanel>
      </>
    )}
  </ItemForm>
);
