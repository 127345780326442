import * as React from "react";
import { styled } from "../../app/theme";

const StyledSvg = styled.svg<{ color: string }>`
  width: 100%;
  height: 100%;
  stroke: ${(props) => props.color};
`;

interface Props {
  color: string;
}

// tslint:disable max-line-length
export const UserIcon: React.FunctionComponent<Props> = (props) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="170"
    height="170"
    viewBox="-32.5 -4 170 170"
    fill="none"
    color={props.color}
  >
    <circle cx="55" cy="37" r="35.5" strokeWidth="11"/>
    <path d="M95.5 160.5H11C6 160.5 1.50002 157 1.5 151.5V125.5C1.4999 109.5 21 91 39 91H72C82 91 105.5 109.5 105.5 125.5V151.5C105.5 157.5 102 160.5 95.5 160.5Z" strokeWidth="11"/>
  </StyledSvg>
);
