import * as React from "react";
import { School } from "../../../types/models/school";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { PanelRow } from "../../containers/rows/panelRow";
import { PropertiesTable } from "../organizationPortal/accessLists/propertiesTable";
import { friendlyDate, friendlyDateTime, multiline } from "../../../utils/formatting";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { OperationStatus } from "../../../types/operationStatus";
import { SchoolStats } from "../../../types/models/schoolStats";
import { NewTabLink } from "../../widgets/newTabLink";
import { GridPanelRow } from "views/containers/rows/gridPanelRow";
import { Grid } from "../../widgets/grid";
import { AccountAction } from "../../../types/enums/accountAction";
import { styled } from "../../../app/theme";
import { TitlePanelRow } from "../../containers/rows/titlePanelRow";
import { LogoPreview } from "./logoPreview";
import { WelcomeMessagePreview } from "./welcomeMessagePreview";

interface Props {
  school: School;
  statsStatus: OperationStatus<SchoolStats>;
}

export const SchoolOverview: React.FunctionComponent<Props> = (props) => (
  <>
    <Block>
      <Panel>
        <TitlePanelRow>Key Properties</TitlePanelRow>
        <PanelRow>
          <PropertiesTable>
            <tbody>
            <tr>
              <td>Domains:</td>
              <td>{multiline(props.school.domains)}</td>
            </tr>
            <tr>
              <td>Official name:</td>
              <td>{props.school.officialName || "--"}</td>
            </tr>
            <tr>
              <td>Common name:</td>
              <td>{props.school.commonName || "--"}</td>
            </tr>
            <tr>
              <td>Branded landing page URL:</td>
              <td>
                {
                  props.school.landingPageUrl
                    ? <NewTabLink to={props.school.landingPageUrl}>{props.school.landingPageUrl}</NewTabLink>
                    : "--"
                }
              </td>
            </tr>
            <tr>
              <td>Notes:</td>
              <td>{multiline(props.school.notes || "--")}</td>
            </tr>
            <tr>
              <td>Review status:</td>
              <td>{props.school.reviewed ? "Reviewed" : "Awaiting review"}</td>
            </tr>
            <tr>
              <td>Created:</td>
              <td>{friendlyDateTime(props.school.createdAt)}</td>
            </tr>
            </tbody>
          </PropertiesTable>
        </PanelRow>
      </Panel>
    </Block>
    <Block>
      <Panel>
        <TitlePanelRow>Welcome Message Preview</TitlePanelRow>
        <PanelRow>
          <WelcomeMessagePreview school={props.school}/>
        </PanelRow>
      </Panel>
    </Block>
    <Block>
      <Panel>
        <TitlePanelRow>Logo Preview</TitlePanelRow>
        <PanelRow>
          {
            props.school.logo
              ? <LogoPreview logoId={props.school.logo.id} logoWidth={props.school.logoWidth}/>
              : "(No logo configured)"
          }
        </PanelRow>
      </Panel>
    </Block>
    <Block>
      <Panel>
        <TitlePanelRow>Reference Information</TitlePanelRow>
        <PanelRow>
          <PropertiesTable>
            <tbody>
            <tr>
              <td>Country:</td>
              <td>{props.school.country || "--"}</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>{props.school.type || "--"}</td>
            </tr>
            <tr>
              <td>Website:</td>
              <td>
                {
                  props.school.website
                    ? <NewTabLink to={props.school.website}>{props.school.website}</NewTabLink>
                    : "--"
                }
              </td>
            </tr>
            <tr>
              <td>NCES URL:</td>
              <td>
                {
                  props.school.ncesUrl
                    ? <NewTabLink to={props.school.ncesUrl}>{props.school.ncesUrl}</NewTabLink>
                    : "--"
                }
              </td>
            </tr>
            <tr>
              <td>Average annual grads:</td>
              <td>{props.school.avgAnnualGrads !== undefined ? props.school.avgAnnualGrads : "--"}</td>
            </tr>
            <tr>
              <td>Uses Google:</td>
              <td>{serviceUsage(props.school.usesGoogle)}</td>
            </tr>
            <tr>
              <td>Uses Microsoft:</td>
              <td>{serviceUsage(props.school.usesMicrosoft)}</td>
            </tr>
            <tr>
              <td>Mascot:</td>
              <td>{props.school.mascot || "--"}</td>
            </tr>
            </tbody>
          </PropertiesTable>
        </PanelRow>
      </Panel>
    </Block>
    <OperationStatusIndicator
      progressMessage={"Loading stats..."}
      failureMessage={"Failed to load stats"}
      status={props.statsStatus}
      indicators={StatusIndicators.SimplePanel()}
    />
    {props.statsStatus.isSuccess() && <SchoolStatsPanel stats={props.statsStatus.result}/>}
  </>
);

function serviceUsage(value: boolean | undefined): string {
  switch (value) {
    case undefined: return "Unknown";
    case true: return "Yes";
    case false: return "No";
  }
}

interface SchoolStatsPanelProps {
  stats: SchoolStats;
}

const SchoolStatsPanel: React.FunctionComponent<SchoolStatsPanelProps> = (props) => {
  const maxVotes = props.stats.accountActionStats.map((entry) => entry.count).max();

  return (
    <Block>
      <Panel>
        <TitlePanelRow>Stats</TitlePanelRow>
        <PanelRow>
          <PropertiesTable>
            <tbody>
            <tr>
              <td>Migrations:</td>
              <td>{props.stats.migrationCount}</td>
            </tr>
            <tr>
              <td>Ambassadors:</td>
              <td>{props.stats.ambassadorCount}</td>
            </tr>
            </tbody>
          </PropertiesTable>
        </PanelRow>
        {maxVotes && (
          <GridPanelRow>
            <Grid>
              <Grid.Header>
                <Grid.Column nowrap={true}>Date</Grid.Column>
                <Grid.Column nowrap={true}>The school will...</Grid.Column>
                <Grid.Column>Votes</Grid.Column>
                <Grid.Column/>
              </Grid.Header>
              <Grid.Body>
                {props.stats.accountActionStats.map((entry, index) => (
                  <Grid.Row key={index}>
                    <Grid.Cell nowrap={true}>{friendlyDate(entry.date)}</Grid.Cell>
                    <Grid.Cell nowrap={true}>
                      {
                        entry.action === AccountAction.Delete
                          ? "Delete student accounts"
                          : "Reduce storage in student accounts"
                      }
                    </Grid.Cell>
                    <Grid.Cell>{entry.count}</Grid.Cell>
                    <VotesBarCell>{maxVotes && <VotesBar value={entry.count} max={maxVotes}/>}</VotesBarCell>
                  </Grid.Row>
                ))}
              </Grid.Body>
            </Grid>
          </GridPanelRow>
        )}
      </Panel>
    </Block>
  );
};

const VotesBarCell = styled(Grid.Cell)`
  width: 100%;
`;

const VotesBar = styled.div<{ value: number, max: number }>`
  background: ${(props) => props.theme.colors.primary};
  width: ${(props) => 30 * props.value / props.max}rem;
  height: 1rem;
`;
