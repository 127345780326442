import * as React from "react";
import { styled } from "../../app/theme";
import { VaultMeLogo } from "../glyphs/vaultMeLogo";
import { Step } from "../containers/step";
import { WizardNavigation } from "../widgets/wizardNavigation";
import { ContentPlaceholder } from "./contentPlaceholder";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { StyleguideTable } from "./styleguideTable";
import { useLocation } from "react-router";

function onSubmit() {
  alert("Submitted");
}

const StyledVaultMeLogo = styled(VaultMeLogo)`
  height: 2rem;
`;

export const Wizards: React.FunctionComponent = (props) => {
  const backPath = useLocation().pathname;
  return (
    <React.Fragment>
      <StyleguideHeader>Step Layout</StyleguideHeader>
      <StyleguideBlock>
        <Step
          breadcrumbs={["Section", "Sub-section"]}
          title="Title"
          subTitle="Sub-Title"
          onSubmit={onSubmit}
          backPath={backPath}
          submitSubText="Sub-title"
          additionalContent={<ContentPlaceholder>Additional content</ContentPlaceholder>}
        >
          <ContentPlaceholder>Main content</ContentPlaceholder>
        </Step>
      </StyleguideBlock>

      <StyleguideHeader>Navigation</StyleguideHeader>
      <StyleguideBlock>
        <StyleguideTable>
          <tbody>
          <tr>
            <th>Default look</th>
            <td><WizardNavigation/></td>
          </tr>
          <tr>
            <th>Customized</th>
            <td>
              <WizardNavigation
                backPath={backPath}
                submitTitle="Accept & Proceed"
                submitSubText="You'll be surprised"
                submitSize="narrow"
                submitColor="red"
              />
            </td>
          </tr>
          <tr>
            <th>Submit disabled</th>
            <td>
              <WizardNavigation
                backPath={backPath}
                submitDisabled={true}
              />
            </td>
          </tr>
          <tr>
            <th>Submit disabled with message</th>
            <td>
              <WizardNavigation
                backPath={backPath}
                submitDisabled={true}
                submitDisabledMessage={"This is disabled!"}
              />
            </td>
          </tr>
          <tr>
            <th>Custom submit widget</th>
            <td>
              <WizardNavigation
                backPath={backPath}
                submitWidget={<StyledVaultMeLogo colorSchema={"black-and-blue"}/>}
              />
            </td>
          </tr>
          <tr>
            <th>Submit hidden</th>
            <td>
              <WizardNavigation
                backPath={backPath}
                submitHidden={true}
              />
            </td>
          </tr>
          </tbody>
        </StyleguideTable>
      </StyleguideBlock>
    </React.Fragment>
  );
};
