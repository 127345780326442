import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import {
  GoogleGroupDetailsController
} from "../../../../controllers/organizationPortal/accessLists/googleGroupDetailsController";
import { TextBox } from "../../../widgets/textBox";
import { FormField } from "../../../widgets/formField";
import { styled } from "../../../../app/theme";
import { FormErrorMessage } from "../../../widgets/formErrorMessage";
import { Button } from "../../../widgets/button";
import { ControllerHost } from "../../../../utils/controller";
import { OperationStatus } from "../../../../types/operationStatus";
import { GoogleGroup } from "../../../../types/models/googleGroup";
import { PropertiesTable } from "./propertiesTable";
import { ErrorInfo } from "../../../widgets/errorInfo";

interface Props<V> {
  label: string;
  name: keyof V;

  controller: GoogleGroupDetailsController;

  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export function GoogleGroupIdField<V>(props: Props<V>): React.ReactElement {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) =>
        <ControllerHost
          controller={props.controller}
          render={(viewProps) => {
            const value = field.form.values[props.name] as unknown as string;
            const isEmpty = !value;
            const error = !!field.form.errors[props.name] ||
              viewProps.status.isFailure() ||
              viewProps.status.isSuccess() && !viewProps.status.result;

            return (
              <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
                <TextBox
                  {...field.field}
                  error={error}
                  disabled={props.disabled || viewProps.status.isWorking()}
                  readOnly={props.readOnly}
                  maxLength={100}
                  onChange={(event) => {
                    field.form.setFieldError(event.target.name, "");
                    field.field.onChange(event);
                  }}
                />
                <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
                <Section>
                  <Button
                    size={"small"}
                    color={"secondary"}
                    disabled={props.disabled || isEmpty || viewProps.status.isWorking()}
                    onClick={() => viewProps.onRequest(value)}
                  >
                    Test
                  </Button>
                </Section>
                {!viewProps.status.isPending() && (
                  <Section>
                    <GoogleGroupIdTestStatus status={viewProps.status}/>
                  </Section>
                )}
              </FormField>
            );
          }}
        />
      }
    />
  );
}

const Section = styled.div`
  margin-top: .5rem;
`;

const ErrorDescription = styled.div`
  color: ${(props) => props.theme.colors.red};
`;

interface GoogleGroupIdTestStatusProps {
  status: OperationStatus<GoogleGroup | undefined>;
}

const GoogleGroupIdTestStatus: React.FunctionComponent<GoogleGroupIdTestStatusProps> = (props) => {
  if (props.status.isSuccess()) {
    if (props.status.result) {
      return (
        <>
          Successfully connected to Google Group:
          <Section>
            <PropertiesTable>
              <tbody>
              <tr>
                <td>Email:</td>
                <td>{props.status.result.friendlyId}</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td>{props.status.result.name || "(not set)"}</td>
              </tr>
              <tr>
                <td>Description:</td>
                <td>{props.status.result.description || "(not set)"}</td>
              </tr>
              </tbody>
            </PropertiesTable>
          </Section>
        </>
      );
    } else {
      return (
        <ErrorDescription>
          Failed to connect to Google Group. Please make sure that the Group ID is correct and that the
          VaultMe service account has been added as a member of this Group.
        </ErrorDescription>
      );
    }
  } else if (props.status.isFailure()) {
    return (
      <ErrorInfo
        error={props.status.error.enrich({
          summary: "Failed to retrieve Google Group description",
          recommendations: "Please try again",
          retry: null
        })}
      />
    );
  } else if (props.status.isWorking()) {
    return <>Retrieving Google Group description...</>;
  } else {
    return null;
  }
};
