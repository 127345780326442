import { GraphQL } from "../../services/graphql/generated";
import { mapOptional, nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { preciseTimestamp } from "../../utils/formatting";
import { TimelineTooltip } from "./timelineTooltip";

export class TaskIssue implements TaskIssue.Props {
  constructor(protected readonly props: TaskIssue.Props) {
  }

  public get jobId(): string { return this.props.jobId; }
  public get taskId(): string { return this.props.taskId; }
  public get transition(): number { return this.props.transition; }
  public get issueId(): string { return this.props.issueId; }

  public get summary(): string { return this.props.summary; }
  public get details(): string | undefined { return this.props.details; }

  public get createdAt(): Date { return this.props.createdAt; }

  public get confirmedAt(): Date | undefined { return this.props.confirmedAt; }
  public get timesConfirmed(): number { return this.props.timesConfirmed; }

  public get notifiedAt(): Date | undefined { return this.props.notifiedAt; }
  public get timesNotified(): number { return this.props.timesNotified; }

  public get resolvedAt(): Date | undefined { return this.props.resolvedAt; }

  public toEvents(): List<TaskIssue.Event> {
    const started = new TaskIssue.Event({
      jobId: this.jobId,
      taskId: this.taskId,
      transition: this.transition,
      issueId: this.issueId,

      summary: this.summary,
      details: this.details,

      eventType: TaskIssue.Event.Type.Reported,
      timestamp: this.createdAt,
      issueIsPending: !this.resolvedAt
    });

    if (this.resolvedAt) {
      return List([
        started,
        new TaskIssue.Event({
          jobId: this.jobId,
          taskId: this.taskId,
          transition: this.transition,
          issueId: this.issueId,

          summary: this.summary,
          details: this.details,

          eventType: TaskIssue.Event.Type.Resolved,
          timestamp: this.resolvedAt,
          issueIsPending: false
        })
      ]);

    } else {
      return List([started]);
    }
  }

  public timelineTooltip(): TimelineTooltip {
    return {
      summary: "Issue \"" + this.issueId + "\" (" + this.summary + ")",
      props: [
        ["Transition", "" + this.transition],
        ["Reported at", preciseTimestamp(this.createdAt)],
        ["Resolved at", this.resolvedAt ? preciseTimestamp(this.resolvedAt) : "--"],
        ["Confirmed", this.timesConfirmed + " times"],
        ["Last confirmed at", this.confirmedAt ? preciseTimestamp(this.confirmedAt) : "--"],
        ["Notified", this.timesNotified + " times"],
        ["Last notified at", this.notifiedAt ? preciseTimestamp(this.notifiedAt) : "--"],
      ]
    };
  }
}

export namespace TaskIssue {
  export interface Props {
    readonly jobId: string;
    readonly taskId: string;
    readonly transition: number;
    readonly issueId: string;

    readonly summary: string;
    readonly details: string | undefined;

    readonly createdAt: Date;

    readonly confirmedAt: Date | undefined;
    readonly timesConfirmed: number;

    readonly notifiedAt: Date | undefined;
    readonly timesNotified: number;

    readonly resolvedAt: Date | undefined;
  }

  export function fromGraphQL(taskIssue: GraphQL.TaskIssue): TaskIssue {
    return new TaskIssue({
      jobId: taskIssue.jobId,
      taskId: taskIssue.taskId,
      transition: taskIssue.transition,
      issueId: taskIssue.issueId,

      summary: taskIssue.summary,
      details: nullToUndefined(taskIssue.details),

      createdAt: new Date(taskIssue.createdAt),

      confirmedAt: mapOptional(taskIssue.confirmedAt, (value) => new Date(value)),
      timesConfirmed: taskIssue.timesConfirmed,

      notifiedAt: mapOptional(taskIssue.notifiedAt, (value) => new Date(value)),
      timesNotified: taskIssue.timesNotified,

      resolvedAt: mapOptional(taskIssue.resolvedAt, (value) => new Date(value))
    });
  }

  export class Event implements Event.EventProps {
    constructor(protected readonly props: Event.EventProps) {
    }

    public get jobId(): string { return this.props.jobId; }
    public get taskId(): string { return this.props.taskId; }
    public get transition(): number { return this.props.transition; }
    public get issueId(): string { return this.props.issueId; }

    public get summary(): string { return this.props.summary; }
    public get details(): string | undefined { return this.props.details; }

    public get eventType(): Event.Type { return this.props.eventType; }
    public get timestamp(): Date { return this.props.timestamp; }
    public get issueIsPending(): boolean { return this.props.issueIsPending; }

    public get key(): string {
      // The same issue can be reported multiple times, so a timestamp is required to construct a unique code
      return this.taskId + "/" + this.transition + "/" + this.issueId + "/" + this.eventType +
        "/" + this.timestamp.toISOString();
    }
  }

  export namespace Event {
    export enum Type {
      Reported = "Reported",
      Resolved = "Resolved",
    }

    export interface EventProps {
      readonly jobId: string;
      readonly taskId: string;
      readonly transition: number;
      readonly issueId: string;

      readonly summary: string;
      readonly details: string | undefined;

      readonly eventType: Event.Type;
      readonly timestamp: Date;
      readonly issueIsPending: boolean;
    }
  }
}
