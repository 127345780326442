import * as React from "react";
import { Step, StepSubmitHandle } from "../../../containers/step";
import { ConnectionPanel } from "../../../blocks/connectionPanel/connectionPanel";
import { ConnectionPanelDefs } from "../../../blocks/connectionPanel/connectionPanelDefs";
import { connectionStepSettings } from "./connectionStepSettings";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { SourceConnectionPromotionMessageModal } from "./sourceConnectionPromotionMessageModal";
import { ConnectionPanelState } from "../../../blocks/connectionPanel/connectionPanelState";
import { SourceConnectionPromotionMessage } from "../../../../types/models/sourceConnectionPromotionMessage";
import { Preload } from "../../../utils/preload";
import { Images } from "../../../../app/images";

interface Props {
  step: number;
  totalSteps: number;

  onProceed: StepSubmitHandle;
  onNavigateBack?: () => void;
  connectionState: ConnectionPanelDefs.ControlledConnectionPanelProps;
  sourceConnectionPromotionMessage: SourceConnectionPromotionMessage.Any | undefined;
}

export const ConnectionStepView: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();
  const stepSettings = connectionStepSettings(
    props.connectionState.authContext,
    props.connectionState.cloudService.id,
    props.connectionState.panelState,
    appBootstrapConfig
  );
  const [closedEligibilityModal, setClosedEligibilityModal] = React.useState(false);

  return (
    <Step
      noForm={true}
      breadcrumbs={["Setup", `Step ${props.step} of ${props.totalSteps}`]}
      title={stepSettings.title}
      subTitle={stepSettings.subTitle}
      submitHidden={stepSettings.submitHidden || props.sourceConnectionPromotionMessage?.isBlocking}
      onSubmit={props.onProceed}
      onNavigateBack={props.onNavigateBack}
    >
      <Preload src={Images.ZenGuy}/>
      {
        props.sourceConnectionPromotionMessage &&
        props.connectionState.panelState.type !== ConnectionPanelState.Type.SigningIn &&
        !closedEligibilityModal && (
          <SourceConnectionPromotionMessageModal
            sourceConnectionPromotionMessage={props.sourceConnectionPromotionMessage}
            connection={props.connectionState.panelState.connectionDetails.connection}
            onDisconnect={props.connectionState.panelState.onDisconnect}
            onClose={() => setClosedEligibilityModal(true)}
          />
        )
      }
      <ConnectionPanel
        viewSettings={{
          showConnectionStatusRow: ConnectionPanelDefs.ShowConnectionStatusRow.Always,
          showSuccessRow: ConnectionPanelDefs.ShowSuccessRow.Always,
          showAreasWithNoIssues: true,
          showProgress: true,
          processDescription: undefined,
          showSecurityStatement: true
        }}
        {...props.connectionState}
      />
    </Step>
  );
};
