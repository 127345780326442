import * as React from "react";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Page } from "../../containers/page";
import { Block } from "../../containers/block";
import { Panel } from "../../containers/panel";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { OperationStatus } from "../../../types/operationStatus";
import { Batch } from "../../../types/models/batch";
import { BatchLaunchToolControllerType, BatchSyncUpToolControllerType, BatchToolsPanel } from "./batchToolsPanel";
import { MigrationsGrid } from "../userDashboardPageView/userMigrationsGrid";
import { Link } from "react-router-dom";
import { UpdateCustomerDetailsToolControllerType } from "../../blocks/updateCustomerDetailsForm";
import { useRoutes } from "../../../app/routes/useRoutes";

interface Props {
  batchId: string;
  batchStatus: OperationStatus<Batch & Batch.HasMigrations>;

  onBatchRename: (name: string) => Promise<Batch>;
  renameBatchStatus: OperationStatus<Batch>;

  batchLaunchToolController: BatchLaunchToolControllerType;
  batchSyncUpToolController: BatchSyncUpToolControllerType;
  invoiceToolController: UpdateCustomerDetailsToolControllerType;

  onDownloadReports: () => void;
}

export const BatchStatusPageView: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  return (
    <Page
      breadcrumbs={[<Link key={0} to={routes.migrations.homePath}>My Migrations</Link>, "Batch " + props.batchId]}
      title={
        props.batchStatus.isSuccess()
          ? props.batchStatus.result.name || "Untitled batch"
          : "Loading batch..."
      }
      fullWidth={true}
      editableTitleConfig={{
        maxLength: 200,
        formDisabled: props.renameBatchStatus.isWorking(),
        onChange: props.onBatchRename
      }}
    >
      <OperationStatusIndicator
        status={props.renameBatchStatus}
        progressMessage={"Renaming batch..."}
        failureMessage={"Failed to rename batch"}
        indicators={StatusIndicators.SimplePanel()}
      />
      <OperationStatusIndicator
        status={props.batchStatus}
        subject={"batch"}
        indicators={StatusIndicators.SimplePanel()}
      />

      {props.batchStatus.isSuccess() && (
        <>
          <Block>
            <BatchToolsPanel
              batch={props.batchStatus.result}

              batchLaunchToolController={props.batchLaunchToolController}
              batchSyncUpToolController={props.batchSyncUpToolController}
              invoiceToolController={props.invoiceToolController}

              onDownloadReports={props.onDownloadReports}
            />
          </Block>
          <Block>
            <Panel>
              <GridPanelRow>
                <MigrationsGrid
                  migrations={
                    props.batchStatus.result.migrations.sort((migration) => migration.batch?.index || 0)
                  }
                />
              </GridPanelRow>
            </Panel>
          </Block>
        </>
      )}
    </Page>
  );
};
