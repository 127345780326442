import * as React from "react";
import { Route, Routes } from "react-router";
import { AdminPortalSection } from "../adminPortalSection";
import { AdminPortalSectionId } from "../../../views/screens/organizationPortal/adminPortalNav";
import {
  AutomatedOffboardingRoutes as _AutomatedOffboardingRoutes
} from "../../../app/routes/automatedOffboardingRoutes";
import { OrganizationOffboardingProjectAdminPages } from "./offboardingProjectAdminPages";
import { ImportOffboardingProjectEntriesPage } from "./importOffboardingProjectEntriesPage";
import { ImportHistoryPage } from "./importHistoryPage";
import { DeleteOffboardingProjectEntriesPage } from "./deleteOffboardingProjectEntriesPage";

export const AutomatedOffboardingRoutes: React.FunctionComponent = () => (
  <Routes>
    <Route
      path={"/*"}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AutomatedOffboarding}>
          {(context) => <OrganizationOffboardingProjectAdminPages organizationContext={context}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AutomatedOffboardingRoutes.importMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AutomatedOffboarding}>
          {(context) => <ImportOffboardingProjectEntriesPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AutomatedOffboardingRoutes.importHistoryMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AutomatedOffboarding}>
          {(context) => <ImportHistoryPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
    <Route
      path={_AutomatedOffboardingRoutes.deleteEntriesMask}
      element={(
        <AdminPortalSection sectionId={AdminPortalSectionId.AutomatedOffboarding}>
          {(context) => <DeleteOffboardingProjectEntriesPage organization={context.organization}/>}
        </AdminPortalSection>
      )}
    />
  </Routes>
);
