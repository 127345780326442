import { Configuration } from "../../types/models/configuration";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { ErrorClass } from "../../services/graphql/errorClass";
import { UserFacingError } from "../../types/userFacingError";
import { ConfigurationFormData } from "../../types/models/configurationFormData";

type InsertConfigurationMutationHook = [
  (formData: ConfigurationFormData) => Promise<Configuration>,
  OperationStatus<Configuration>
];

export function useInsertConfigurationMutation(organizationId: number): InsertConfigurationMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertConfigurationMutation,
    extract: (data: GraphQL.InsertConfigurationMutation) => nullToUndefined(data.insertConfiguration),
    complete: Configuration.parse,
    catch: [
      [
        ErrorClass.ItemAlreadyExistsException,
        (error) => UserFacingError.expected(error, { summary: "This name is already being used" })
      ]
    ]
  });

  function fireWith(formData: ConfigurationFormData): Promise<Configuration> {
    return fire({
      variables: { organizationId, formData: ConfigurationFormData.toGraphQL(formData) },
      retry: () => fireWith(formData),
    });
  }

  return [fireWith, status];
}
