import { AppAction } from "../appAction";
import { SettingsActionType } from "./actions";
import { SettingsState, AdminToolsMode } from "./state";

export function reduceSettings(state: SettingsState, action: AppAction): SettingsState {
  switch (action.type) {
    case SettingsActionType.ToggledAdminTools:
      return {
        ...state,
        adminToolsMode: state.adminToolsMode === AdminToolsMode.Hidden
          ? AdminToolsMode.SplitScreen
          : AdminToolsMode.Hidden
      };

    case SettingsActionType.MaximizedSidebar:
      return { ...state, adminToolsMode: AdminToolsMode.FullScreen };

    case SettingsActionType.RestoredSidebar:
      return { ...state, adminToolsMode: AdminToolsMode.SplitScreen };

    case SettingsActionType.UpdatedBlueprintFilters:
      return { ...state, blueprintFilters: action.filters };

    case SettingsActionType.ToggledBlueprintAnimation:
      return { ...state, blueprintAnimation: !state.blueprintAnimation };

    default:
      return state;
  }
}
