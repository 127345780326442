import { BlueprintFactRef } from "./blueprintFactRef";
import { FactRef } from "../types/facts/factRef";
import { Tracer } from "./tracer";
import { BlueprintFactRefFactory } from "./blueprintFactRefFactory";
import { Collectable } from "../types/collectables/collectable";
import { Option } from "../utils/monads/option";

export class MigrationFactRef<P, C, T extends Collectable<P, C>>
  extends BlueprintFactRef<P, C, T, MigrationFactRef.Context> {

  public makeContext(ctx: BlueprintFactRef.Context): Option<MigrationFactRef.Context> {
    return ctx.blueprintContext.inputs.get(MigrationFactRef.MigrationIdInput).map((migrationId) => ({ migrationId }));
  }
}

export namespace MigrationFactRef {
  export const MigrationIdInput = "migrationId";

  export interface Context extends FactRef.Context {
    migrationId: string;
  }

  export const Factory: BlueprintFactRefFactory = {
    type: "MigrationFactRef",
    create<P, C, T extends Collectable<P, C>>(family: string): MigrationFactRef<P, C, T> {
      return new MigrationFactRef(family);
    }
  };
}

export class IterationFactRef<P, C, T extends Collectable<P, C>>
  extends BlueprintFactRef<P, C, T, IterationFactRef.Context> {

  public makeContext(ctx: BlueprintFactRef.Context): Option<IterationFactRef.Context> {
    return ctx.blueprintContext.inputs.get(MigrationFactRef.MigrationIdInput).flatMap((migrationId) =>
      ctx.blueprintContext.inputs.get(IterationFactRef.IterationInput).map((iteration) => ({
        migrationId,
        iteration
      }))
    );
  }
}

export namespace IterationFactRef {
  export const IterationInput = "iteration";

  export interface Context extends MigrationFactRef.Context {
    iteration: string;
  }

  export const Factory: BlueprintFactRefFactory = {
    type: "IterationFactRef",
    create<P, C, T extends Collectable<P, C>>(family: string): IterationFactRef<P, C, T> {
      return new IterationFactRef(family);
    }
  };
}
