import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { User } from "../../types/models/user";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { AccountAction } from "../../types/enums/accountAction";
import * as moment from "moment";

interface Contact {
  firstName: string | undefined;
  emailAddress: string;
}

interface Params {
  sourceConnectionId: string;
  destinationConnectionId: string;
  customMessage: string | undefined;
  accountAction: AccountAction;
  accountActionDate: Date;
  contacts: List<Contact>;
}

type BecomeAnAmbassadorMutationHook = [(params: Params) => Promise<User>, OperationStatus<User>];

export function useBecomeAnAmbassadorMutation(): BecomeAnAmbassadorMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useBecomeAnAmbassadorMutation,
    extract: (data: GraphQL.BecomeAnAmbassadorMutation) => nullToUndefined(data.becomeAnAmbassador),
    complete: User.parse
  });

  function fireWith(params: Params): Promise<User> {
    return fire({
      variables: {
        ...params,
        accountActionDate: moment.utc(moment(params.accountActionDate).format("YYYY-MM-DD")).toISOString(),
        contacts: params.contacts.toArray()
      },
      retry: () => fireWith(params),
    });
  }

  return [fireWith, status];
}
