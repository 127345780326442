import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { FormErrorMessage } from "./formErrorMessage";
import { FormikProps } from "formik/dist/types";
import { RadioButton } from "./option";
import { styled } from "../../app/theme";

interface Props<T> {
  label: string;
  name: keyof T;
  options: [any, React.ReactNode][];

  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;

  onChange?: (event: React.ChangeEvent<any>, form: FormikProps<T>) => void;
}

// tslint:disable-next-line function-name
export function RadioButtonGroupField<V>(props: Props<V>) {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];
        return (
          <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
            {props.options.map(([value, content], index) => (
              <StyledRadioButton
                key={index}
                name={field.field.name}
                value={value}

                checked={field.field.value === value}
                disabled={props.disabled}

                onChange={(event) => {
                  field.form.setFieldError(event.target.name, "");
                  field.field.onChange(event);
                }}
              >
                {content}
              </StyledRadioButton>
            ))}
            <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
          </FormField>
        );
      }}
    />
  );
}

const StyledRadioButton = styled(RadioButton)`
  & ~ & {
    margin-top: .5rem;
  }
`;
