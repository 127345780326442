import { GraphQL } from "../../services/graphql/generated";
import { ThemeOverrides } from "./themeOverrides";

export interface ThemeFormData extends ThemeOverrides {
  readonly name: string;
  readonly notes: string;
}

export namespace ThemeFormData {
  export function toGraphQL(data: ThemeFormData): GraphQL.ThemeFormData {
    return {
      name: data.name,
      notes: data.notes,

      primary: data.primary,
      darkPrimary: data.darkPrimary,
      darkerPrimary: data.darkerPrimary,
      lightPrimary: data.lightPrimary,
      faintPrimary: data.faintPrimary,

      red: data.red,
      lightRed: data.lightRed,

      yellow: data.yellow,
      lightYellow: data.lightYellow,

      orange: data.orange,
      lightOrange: data.lightOrange,

      green: data.green,
      lightGreen: data.lightGreen,

      titleColor: data.titleColor,
      textColor: data.textColor,

      useMonochromeLogo: data.useMonochromeLogo
    };
  }
}
