import * as React from "react";
import { useSimpleLookupController } from "../../simpleLookupController";
import { Program } from "../../../types/models/program";
import { useSearchProgramsQuery } from "../../../queries/programs/useSearchProgramsQuery";
import {
  ProgramLookupControllerType
} from "../../../views/blocks/lookups/programLookupField";

export function useProgramLookupController(organizationId: number): ProgramLookupControllerType {
  const getItemId = React.useCallback((item: Program) => item.id, []);

  const comparator = React.useCallback(
    (a: Program, b: Program) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },
    []
  );

  const useSearchQuery = React.useCallback(
    (program) => useSearchProgramsQuery(organizationId, program),
    [organizationId]
  );

  return useSimpleLookupController<string, Program>({
    getItemId,
    comparator,
    listAll: true,
    useSearchQuery
  });
}
