import { ManagedQueryHook, useManagedQuery } from "../services/graphql/useManagedQuery";
import { GraphQL } from "../services/graphql/generated";
import { nullToUndefined } from "../utils/misc";
import { List } from "immutable";
import { useWatcherDispatch } from "../services/watcher/useWatcher";
import { WatchedMigrations } from "../services/watcher/plugins/watchedMigrationsPlugin";
import { WatchedBatches } from "../services/watcher/plugins/watchedBatchesPlugin";
import { Migration } from "../types/models/migration";
import { Batch } from "../types/models/batch";
import { UserMigrations } from "../types/models/userMigrations";

export type UserMigrationsHook = ManagedQueryHook<GraphQL.UserMigrationsQueryVariables, UserMigrations>;

export function useUserMigrations(
  userId: string,
  watch?: boolean
): UserMigrationsHook {
  const watcher = useWatcherDispatch();

  const [status, refresh] = useManagedQuery({
    query: GraphQL.useUserMigrationsQuery,
    deps: null,
    prepare: () => ({ userId, includeBatched: false }),
    extract: (data: GraphQL.UserMigrationsQuery) => nullToUndefined(
      data.userMigrations && data.userBatches && { migrations: data.userMigrations, batches: data.userBatches }
    ),
    // complete: () => UserMigrations.build(List<Migration & Migration.HasConnections>(), List<Batch>()),
    complete: ({ migrations, batches }) => UserMigrations.build(
      List(
        migrations.map((data) => ({
          ...Migration.parseCore(data),
          ...Migration.HasConnections.parse(data)
        }))
      ),
      List(batches.map(Batch.parse))
    ),
    fetchPolicy: "cache-and-network"
  });

  if (watch && status.isSuccess()) {
    watcher(WatchedMigrations.WatchMigrationsAction(status.result.migrations.map((migration) => migration.id)));
    watcher(WatchedBatches.WatchBatchesAction(status.result.batches.map((batch) => batch.id)));
  }

  return [status, refresh];
}
