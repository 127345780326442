import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface UserSummary {
  readonly id: string;
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly emailAddress: string | undefined;

  readonly name: string | undefined;
}

export namespace UserSummary {
  export function parse(data: GraphQL.UserSummary): UserSummary {
    const firstName = nullToUndefined(data.firstName);
    const lastName = nullToUndefined(data.lastName);

    return {
      id: data.id,
      firstName,
      lastName,
      emailAddress: nullToUndefined(data.emailAddress),

      name: firstName
        ? (lastName ? firstName + " " + lastName : firstName)
        : lastName
    };
  }
}
