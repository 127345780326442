import * as React from "react";
import { styled } from "../../../../../app/theme";
import { LinkButton } from "../../../../widgets/linkButton";

const StyledChevron = styled.svg`
  fill: none; 
  stroke: ${(props) => props.theme.colors.primary}; 
  stroke-width: 2; 
  stroke-linecap: round;
  stroke-linejoin: round;
  width: 1rem;
  height: 1rem;
  margin: -.15rem 0 -.15rem .2rem;
`;

const Content = styled.div`
  margin: .5rem 0 1rem;
`;

interface EmbeddedHelpArticleProps {
  title: string;
}

export const EmbeddedHelpArticle: React.FunctionComponent<EmbeddedHelpArticleProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div>
        <LinkButton onClick={() => setOpen((value) => !value)}>
          {props.title}
          <StyledChevron width={24} height={24} viewBox={"0 0 24 24"} transform={"scale(1," + (open ? 1 : -1) + ")"}>
            <polyline points="18 15 12 9 6 15"/>
          </StyledChevron>
        </LinkButton>
      </div>
      {open && <Content>{props.children}</Content>}
    </>
  );
};
