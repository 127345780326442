export enum LoginResultType {
  Success = "success",
  Failure = "failure",
  Cancellation = "cancellation"
}

export interface LoginResult<T extends LoginResultType> {
  type: T;
}

export interface LoginSuccess extends LoginResult<LoginResultType.Success> {
  result: string;
}

export interface LoginFailure extends LoginResult<LoginResultType.Failure> {
  code: string;
  error: string;
  recommendations: string | undefined;
}

export interface LoginCancellation extends LoginResult<LoginResultType.Cancellation> {
}

export type AnyLoginResult = LoginSuccess | LoginFailure | LoginCancellation;

type CloseWhenFunction = (document: any) => AnyLoginResult | undefined;

export interface LoginPopupConfig {
  url: string;
  title: string;

  closeWhen: CloseWhenFunction;
  onSuccess: (result: string) => void;
  onFailure?: (code: string, summary: string, recommendations: string | undefined) => void;

  desiredWidth?: number;
  desiredHeight?: number;
}

export function popUpLoginScreen(config: LoginPopupConfig) {
  // Fixes dual-screen position: most browsers vs Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement && document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement && document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const finalWidth = Math.min(width * 0.9, config.desiredWidth || 800);
  const finalHeight = Math.min(height * 0.9, config.desiredHeight || 1800);

  const left = ((width / 2) - (finalWidth / 2)) + dualScreenLeft;
  const top = ((height / 2) - (finalWidth / 2)) + dualScreenTop;
  const newWindow = window.open(
    config.url,
    config.title,
    "scrollbars=yes, width=" + finalWidth + ", height=" + finalHeight + ", top=" + top + ", left=" + left);

  if (newWindow) {
    // Not sure if this check is required, but it was there in the original example
    if (window.focus !== undefined) {
      newWindow.focus();
    }

    const pollTimer = window.setInterval(
      () => {
        if (newWindow) {
          try {
            if (newWindow.document) {
              const result = config.closeWhen(newWindow.document);
              if (result) {
                switch (result.type) {
                  case LoginResultType.Success:
                    config.onSuccess(result.result);
                    break;

                  case LoginResultType.Failure:
                    if (config.onFailure) {
                      config.onFailure(result.code, result.error, result.recommendations);
                    }
                    break;

                  case LoginResultType.Cancellation:
                    break;
                }

                window.clearInterval(pollTimer);
                newWindow.close();
              }
            } else {
              window.clearInterval(pollTimer);
            }
          } catch (e) {
            // console.log(e);
          }
        } else {
          window.clearInterval(pollTimer);
        }
      },
      100);
  }
}

export function closeWhenReachedUrl(url: string, closeWhen: CloseWhenFunction): CloseWhenFunction {
  return (document) => {
    if (document.URL.indexOf(url) !== -1) {
      return closeWhen(document);
    }
  };
}

export function closeWhenFoundLoginResult(document: any): AnyLoginResult | undefined {
  const result = document.getElementById("result");
  if (result) {
    return {
      type: LoginResultType.Success,
      result: result.innerText
    };
  } else {
    const code = document.getElementById("code");
    const summary = document.getElementById("summary");
    if (code && summary) {
      const recommendations = document.getElementById("recommendations")?.innerText;
      return {
        type: LoginResultType.Failure,
        code: code.innerText,
        error: summary.innerText,
        recommendations: recommendations ? recommendations : undefined // Converting an empty string to undefined
      };
    } else {
      const cancelled = document.getElementById("cancelled");
      if (cancelled) {
        return {
          type: LoginResultType.Cancellation
        };
      }
    }
  }
}
