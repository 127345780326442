import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const batchId: string = "batchId";
}

export class BatchesRoutes {
  private readonly root = "b";

  constructor(private context: RoutesContext) {}

  public params(): BatchesRoutes.Params {
    const batchId = this.context.routeParams[RouteParams.batchId];
    if (batchId) {
      return { batchId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get mask(): string {
    return this.root + "/:" + RouteParams.batchId;
  }

  public path(batchId: string): string {
    return this.context.resolvePath(this.root + "/" + batchId);
  }
}

export namespace BatchesRoutes {
  export interface Params {
    batchId: string;
  }
}
