import { Collectable } from "../collectables/collectable";
import { FactStatus } from "./factStatus";
import { None, Option, Some } from "../../utils/monads/option";
import { GraphQL } from "../../services/graphql/generated";
import { parseCollectable } from "../collectables/parseCollectable";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { Set } from "immutable";
import { cachedObjectId } from "../../app/apolloClientProvider";
import { prepareErrorMessage } from "../../utils/misc";

export abstract class FactState {
  public abstract status(): FactStatus;

  public statusDescription(): string {
    return this.status();
  }

  public errorDetails(): Option<string> {
    return None();
  }

  public valueOption(): Option<Collectable.Any> {
    return None();
  }

  public progressOption(): Option<number> {
    return None();
  }

  public errorTypeOption(): Option<string> {
    return None();
  }

  public errorMessageOption(): Option<string> {
    return None();
  }

  public isMissing(): boolean {
    return false;
  }

  public isFailed(): boolean {
    return false;
  }

  public isPreparing(): boolean {
    return false;
  }

  public isCollecting(): boolean {
    return false;
  }

  public isCollected(): boolean {
    return false;
  }
}

export namespace FactState {
  export class Missing extends FactState {
    public status(): FactStatus {
      return FactStatus.Missing;
    }

    public isMissing(): boolean {
      return true;
    }
  }

  export function missing(): Missing {
    return new Missing();
  }

  export class Failed extends FactState {
    public constructor(
      public readonly errorType: string,
      public readonly errorMessage: Option<string>,
      private readonly factId: string
    ) {
      super();
    }

    public status(): FactStatus {
      return FactStatus.Error;
    }

    public statusDescription(): string {
      return FactStatus.Error + " (" +
        this.errorType +
        this.errorMessage
          .map((message) => {
            const parts = prepareErrorMessage(message);
            return ": " + parts[0] + (parts[1] ? " {...}" : "");
          })
          .getOrUse("") +
        ")";
    }

    public errorDetails(): Option<string> {
      return this.errorMessage.flatMap((message) => Option.mayBe(prepareErrorMessage(message)[1]));
    }

    public isFailed(): boolean {
      return true;
    }

    public errorTypeOption(): Option<string> {
      return Some(this.errorType);
    }

    public errorMessageOption(): Option<string> {
      return this.errorMessage;
    }
  }

  export function failed(errorType: string, errorMessage: Option<string>, factId: string): Failed {
    return new Failed(errorType, errorMessage, factId);
  }

  export class Preparing extends FactState {
    public status(): FactStatus {
      return FactStatus.Preparing;
    }

    public isPreparing(): boolean {
      return true;
    }
  }

  export function preparing(): Preparing {
    return new Preparing();
  }

  export class Collecting extends FactState {
    public constructor(public readonly value: Collectable.Any) {
      super();
    }

    public status(): FactStatus {
      return FactStatus.Collecting;
    }

    public isCollecting(): boolean {
      return true;
    }

    public valueOption(): Option<Collectable.Any> {
      return Some(this.value);
    }

    public progressOption(): Option<number> {
      return this.value.progress();
    }
  }

  export function collecting(value: Collectable.Any): Collecting {
    return new Collecting(value);
  }

  export class Collected extends FactState {
    public constructor(public readonly value: Collectable.Any) {
      super();
    }

    public status(): FactStatus {
      return FactStatus.Collected;
    }

    public isCollected(): boolean {
      return true;
    }

    public valueOption(): Option<Collectable.Any> {
      return Some(this.value);
    }
  }

  export function collected(value: Collectable.Any): Collected {
    return new Collected(value);
  }
}

export enum FactSubjectType {
  Connection = "Connection",
  Iteration = "Iteration",
  Migration = "Migration",
}

export class Fact implements Fact.Props  {
  constructor(protected readonly props: Fact.Props) {
  }

  public get subjectId(): string { return this.props.subjectId; }
  public get subjectType(): FactSubjectType { return this.props.subjectType; }
  public get id(): string { return this.props.id; }
  public get family(): string { return this.props.family; }
  public get valueType(): string { return this.props.valueType; }
  public get state(): FactState { return this.props.state; }

  public valueOfType<Val extends Collectable<any, any>>(type: string): Option<Val> {
    return this.state.valueOption().map((value) => {
      if (type === this.valueType) {
        return value as Val;
      } else {
        throw new Error("Fact value is not of type " + type + "(" + this.valueType + ")");
      }
    });
  }
}

export namespace Fact {
  export interface Props {
    subjectId: string;
    subjectType: FactSubjectType;
    id: string;
    family: string;
    valueType: string;
    state: FactState;
  }

  export function fromGraphQL(fact: GraphQL.FactFragment): Fact {
    function parseFactState(): FactState {
      switch (fact.state.__typename) {
        case "MissingFactState":
          return FactState.missing();

        case "FailedFactState":
          return FactState.failed(fact.state.errorType, Option.mayBe(fact.state.errorMessage), fact.id);

        case "PreparingFactState":
          return FactState.preparing();

        case "CollectingFactState":
          return FactState.collecting(parseCollectable(fact.state.value));

        case "CollectedFactState":
          return FactState.collected(parseCollectable(fact.state.value));

        case undefined:
          throw new Error("No fact state type returned from the server");
      }
    }

    return new Fact({
      subjectId: fact.subjectId,
      subjectType: fact.subjectType,
      id: fact.id,
      family: fact.family,
      valueType: fact.valueType,
      state: parseFactState()
    });
  }

  export function cacheId(factId: string): string {
    return cachedObjectId("Fact", factId);
  }

  export const MissingFactStateTypeName = "MissingFactState";

  export function isAvailable(fragment: any): boolean {
    return fragment && fragment.state && fragment.state.__typename !== MissingFactStateTypeName;
  }

  export function filterMissing(factIds: Set<string>, apolloClient: ApolloClient<any>): Set<string> {
    return factIds.filterNot((factId) => isAvailable(apolloClient.readFragment({
      id: cacheId(factId),
      fragment: gql`
        fragment FactStateCheck on Fact {
          state {
            __typename
          }
        }
        `
    })));
  }
}
