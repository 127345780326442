import * as React from "react";
import { EnableAuthRolesController } from "../../../../controllers/enableAuthRolesController";
import { ControllerHost } from "../../../../utils/controller";
import { Modal } from "../../../layouts/modal";
import { PanelRow } from "views/containers/rows/panelRow";
import { Panel } from "../../../containers/panel";
import { EnableAuthRolesView } from "./enableAuthRolesView";

interface Props {
  controller: EnableAuthRolesController;
  onCancel: () => void;
}

export const EnableEmailDeliveryTool: React.FunctionComponent<Props> = (props) => (
  <Modal
    title={"Allow Sending Emails"}
    onClose={props.onCancel}
  >
    <Panel>
      <PanelRow>
        Click the button below to allow VaultMe to send emails from your email account.
        All sent emails will appear in your "Sent Mail". Your friends can reply directly to you.
        No emails will <strong>ever</strong> be sent by or from VaultMe.
      </PanelRow>
      <ControllerHost
        controller={props.controller}
        render={(viewProps) => <EnableAuthRolesView {...viewProps}/>}
      />
    </Panel>
  </Modal>
);
