import * as React from "react";
import { OrganizationSummary } from "../../../types/models/organization";
import {
  DeleteOffboardingProjectEntriesPageView
} from "../../../views/screens/organizationPortal/automatedOffboarding/deleteOffboardingProjectEntriesPageView";
import { useRoutes } from "../../../app/routes/useRoutes";
import {
  useDeleteOffboardingProjectEntriesMutation
} from "../../../queries/automatedOffboarding/useDeleteOffboardingProjectEntriesMutation";
import { useGetOffboardingProjectQuery } from "../../../queries/automatedOffboarding/useGetOffboardingProjectQuery";
import {
  useGetOffboardingProjectEntryCountQuery
} from "../../../queries/automatedOffboarding/useGetOffboardingProjectEntryCountQuery";
import { useManagedQueryController } from "../../../utils/controller";

interface Props {
  organization: OrganizationSummary;
}

export const DeleteOffboardingProjectEntriesPage: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes().organizationPortalRoutes.automatedOffboarding(props.organization.alias);
  const params = routes.importParams();

  const [offboardingProjectStatus] = useGetOffboardingProjectQuery(params.id);
  const [deleteEntries, deleteStatus] = useDeleteOffboardingProjectEntriesMutation(params.id);

  const [entryCountStatus, refreshEntryCount] = useGetOffboardingProjectEntryCountQuery(params.id);
  const entryCountController = useManagedQueryController([entryCountStatus, refreshEntryCount]);

  return (
    <DeleteOffboardingProjectEntriesPageView
      offboardingProjectId={params.id}
      offboardingProjectStatus={offboardingProjectStatus}

      entryCountController={entryCountController}

      homePath={routes.homePath}
      offboardingProjectPath={routes.overviewPath(params.id)}

      deleteStatus={deleteStatus}
      onDelete={(emailAddresses) =>
        deleteEntries(emailAddresses).then((result) => {
          refreshEntryCount();
          return result;
        })
      }
    />
  );
};
