import { DataFlowWithInput, DataFlowWithProviderProps } from "../relationship";
import { Option, Some } from "../../utils/monads/option";
import { GraphQL } from "../../services/graphql/generated";
import { Variant } from "../../types/models/variant";

export class PipeToOption<Output> extends DataFlowWithInput<PipeToOption.Props<Output>, Output, Option<Output>> {
  protected mapInput(input: Output): Option<Output> {
    return Some(input);
  }
}

export namespace PipeToOption {
  export type Props<Output> = DataFlowWithProviderProps<Option<Output>>;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.PipeToOptionPropsFragment): Props<any> {
      return DataFlowWithProviderProps.fromGraphQL(
        props,
        (value) => Option.mayBe(value.value).map(Variant.fromGraphQL)
      );
    }
  }
}
