import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type StartAccessListUpdateJobMutationHook = [(id: number) => Promise<number>, OperationStatus<number>];

export function useStartAccessListUpdateJobMutation(): StartAccessListUpdateJobMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useStartAccessListUpdateJobMutation,
    extract: (data: GraphQL.StartAccessListUpdateJobMutation) => nullToUndefined(data.startAccessListUpdateJob),
    complete: identity
  });

  function fireWith(id: number): Promise<number> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    });
  }

  return [fireWith, status];
}
