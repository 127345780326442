import * as React from "react";
import { styled } from "../../../app/theme";
import { PanelRow } from "./panelRow";

export const ListPanelRow = styled(PanelRow)`
  ul {
    margin: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 1.25rem;
    
    > li {
      word-wrap: break-word;
      word-break: break-all;
    }
    
    > li > ul {
      margin: .25rem 0;
    }
  }
`;
