import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";

type GenerateJsonUsingChatGPTMutationHook = [
  (model: string, prompt: string, temperature: number) => Promise<string>,
  OperationStatus<string>
];

export function useGenerateJsonUsingChatGPTMutation(): GenerateJsonUsingChatGPTMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useGenerateJsonUsingChatGptMutation,
    extract: (data: GraphQL.GenerateJsonUsingChatGptMutation) => nullToUndefined(data.generateJsonUsingChatGPT),
    complete: identity
  });

  function fireWith(model: string, prompt: string, temperature: number): Promise<string> {
    return fire({
      variables: { model, prompt, temperature },
      retry: () => fireWith(model, prompt, temperature),
    });
  }

  return [fireWith, status];
}
