import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { FormErrorMessage } from "./formErrorMessage";
import { TextArea } from "./textArea";

interface TextAreaFieldProps<T> {
  label: string;
  name: keyof T;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  rows?: number;
  placeholder?: string;
  textBoxRef?: React.RefObject<HTMLTextAreaElement>;
  children?: React.ReactNode;
  limitWidth?: boolean | string;
}

// tslint:disable-next-line function-name
export function TextAreaField<V>(props: TextAreaFieldProps<V>) {
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];
        return (
          <FormField
            label={props.label}
            required={props.required}
            error={error}
            limitWidth={props.limitWidth === undefined ? true : props.limitWidth}
          >
            <TextArea
              {...field.field}
              ref={props.textBoxRef}
              disabled={props.disabled}
              readOnly={props.readOnly}
              rows={props.rows}
              placeholder={props.placeholder}
            >
              {props.label}
            </TextArea>
            <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
            {props.children}
          </FormField>
        );
      }}
    />
  );
}
