import * as React from "react";
import { Step } from "../../../containers/step";
import { Constants } from "../../../../app/constants";
import { CheckoutStepDefs } from "./checkoutStepDefs";
import { OperationStatus } from "../../../../types/operationStatus";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { BraintreeForm } from "./braintreeForm";
import { PriceBreakdownPanel } from "./priceBreakdownPanel";
import { UserFacingError } from "../../../../types/userFacingError";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { DiscountsConfig, DiscountsPanel } from "./discountsPanel";

interface Props {
  onNavigateBack?: () => void;

  loadingStatus: OperationStatus<CheckoutStepDefs.OrderSummary>;
  braintreeInitStatus: OperationStatus<any>;
  requestPaymentMethodStatus: OperationStatus<any>;
  launchMigrationStatus: OperationStatus<any>;

  onBraintreeInit: () => void;
  onBraintreeInitSuccess: (braintree: any, deviceData: any) => void;
  onBraintreeInitFailure: (error: UserFacingError) => void;
  onSubmit: () => void;
  onBraintreeTeardown: () => void;

  discounts: DiscountsConfig;
  submitDisabled: boolean;
}

export const CheckoutStepView: React.FunctionComponent<Props> = (props) => {
  const configuration = useAppBootstrapConfig();

  function submitTitle() {
    if (props.loadingStatus.isSuccess()) {
      return props.loadingStatus.result.priceInfo.receipt.amountToBePaid > 0
        ? `Pay $${props.loadingStatus.result.priceInfo.receipt.amountToBePaid.toFixed(2)} Now`
        : "Start Now";
    }
  }

  return (
    <Step
      noForm={true}
      breadcrumbs={["Confirm & Go"]}
      title={"Secure Checkout"}
      subTitle={`Your Time & History are valuable. You're about to save both with ${Constants.Product.name}!`}
      submitHidden={!props.loadingStatus.isSuccess()}
      submitDisabled={props.submitDisabled}
      submitTitle={submitTitle()}
      submitSubText={
        props.loadingStatus.isSuccess() &&
        props.loadingStatus.result.priceInfo.receipt.amountToBePaid > 0
          ? "& your auto-migration will begin immediately"
          : undefined
      }
      submitSize="normal"
      onSubmit={props.onSubmit}
      onNavigateBack={props.onNavigateBack}
    >
      <OperationStatusIndicator
        progressMessage={"Preparing your receipt..."}
        failureMessage={"Failed to load your receipt."}
        status={props.loadingStatus}
        indicators={StatusIndicators.SimplePanel()}
      />
      {
        props.loadingStatus.isSuccess() && (
          <>
            <PriceBreakdownPanel priceInfo={props.loadingStatus.result.priceInfo}/>
            <DiscountsPanel {...props.discounts}/>
            <OperationStatusIndicator
              progressMessage={"Initializing secure payment gateway..."}
              failureMessage={"Failed to initialize secure payment gateway"}
              status={props.braintreeInitStatus}
              indicators={StatusIndicators.SimplePanel()}
            />
            <OperationStatusIndicator
              progressMessage={"Submitting payment..."}
              failureMessage={"Failed to submit payment"}
              status={props.requestPaymentMethodStatus}
              indicators={StatusIndicators.SimplePanel()}
            />
            <OperationStatusIndicator
              progressMessage={"Launching migration..."}
              failureMessage={"Failed to launch migration"}
              status={props.launchMigrationStatus}
              indicators={StatusIndicators.SimplePanel()}
            />
            {props.launchMigrationStatus.isPending() && (
              <BraintreeForm
                configuration={configuration}
                clientToken={props.loadingStatus.result.clientToken}
                amount={props.loadingStatus.result.priceInfo.receipt.amountToBePaid}
                onInit={props.onBraintreeInit}
                onInitSuccess={props.onBraintreeInitSuccess}
                onInitFailure={props.onBraintreeInitFailure}
                onTeardown={props.onBraintreeTeardown}
              />
            )}
          </>
        )
      }
    </Step>
  );
};
