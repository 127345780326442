import * as React from "react";
import * as yup from "yup";
import { FormLayout } from "../../widgets/formLayout";
import { TextField } from "../../widgets/textField";
import { TextAreaField } from "../../widgets/textAreaField";
import { FormField } from "../../widgets/formField";
import { friendlyDateTime } from "../../../utils/formatting";
import { SimplePanel } from "../../containers/simplePanel";
import { OrganizationFormData } from "../../../types/models/organizationFormData";
import { Upload } from "../../../types/models/upload";
import { Organization } from "../../../types/models/organization";
import { FormConfig } from "../../blocks/crud/formConfig";
import { ItemForm } from "../../blocks/crud/itemForm";
import {
  OrganizationThemeLookupControllerTypeFactory,
  OrganizationThemeViewPathFactory,
  ThemeDetailsControllerType,
  ThemeLookupField
} from "../../blocks/lookups/themes/themeLookupField";
import { FileUploadField } from "../../widgets/fileUploadField";
import { FileUploadController, ImageUploadPreview } from "../../widgets/fileUpload";
import { NewTabLink } from "../../widgets/newTabLink";
import { useRoutes } from "../../../app/routes/useRoutes";
import { ViewOnlyWarningPanel } from "./viewOnlyWarningPanel";
import { DateField } from "../../widgets/dateField";
import { CheckboxField } from "../../widgets/checkboxField";

interface FormData {
  readonly alias: string;
  readonly name: string;
  readonly notes: string;

  readonly themeId: number | undefined;
  readonly logo: Upload | undefined;
  readonly domains: string;

  readonly revenueSharingEnabled: boolean;

  readonly activatedAt: Date;
}

namespace FormData {
  export const blank: FormData = {
    alias: "",
    name: "",
    notes: "",

    themeId: undefined,
    logo: undefined,
    domains: "",

    revenueSharingEnabled: false,

    activatedAt: new Date()
  };

  export function make(organization: Organization): FormData {
    return {
      alias: organization.alias,
      name: organization.name,
      notes: organization.notes || "",

      themeId: organization.themeId,
      logo: organization.logo,
      domains: organization.domains,

      revenueSharingEnabled: organization.revenueSharingEnabled,

      activatedAt: organization.activatedAt
    };
  }

  export function toResult(formData: FormData): OrganizationFormData {
    return {
      alias: formData.alias.trim(),
      name: formData.name.trim(),
      notes: formData.notes.length ? formData.notes : undefined,

      themeId: formData.themeId,
      logoId: formData.logo?.id,
      domains: formData.domains,

      revenueSharingEnabled: formData.revenueSharingEnabled,

      activatedAt: formData.activatedAt
    };
  }

  export const validationSchema = yup.object<FormData>().shape<FormData>({
    alias: yup.string().required("Organization alias is required"),
    name: yup.string().required("Organization name is required"),
    notes: yup.string(),

    themeId: yup.number().notRequired(),
    logo: yup.mixed().notRequired(),
    domains: yup.string().required("Domains are required"),

    revenueSharingEnabled: yup.boolean(),

    activatedAt: yup.date().required("Activation date is required")
  });
}

interface Props {
  config: FormConfig<Organization, OrganizationFormData>;
  embedded: boolean;

  organizationThemeLookupControllerFactory: OrganizationThemeLookupControllerTypeFactory;
  themeDetailsController: ThemeDetailsControllerType;
  organizationThemeViewPathFactory: OrganizationThemeViewPathFactory;

  fileUploadController: FileUploadController;
}

export const OrganizationForm: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes().organizationPortalRoutes;
  const themeLookupController = props.organizationThemeLookupControllerFactory(props.config.item?.id);
  const themeViewPathFactory = props.organizationThemeViewPathFactory(props.config.item?.id);

  return (
    <ItemForm<Organization, FormData, OrganizationFormData>
      config={props.config}

      subject={"organization"}
      embedded={props.embedded}
      readOnly={props.config.readOnly}

      blankFormData={FormData.blank}
      makeFormData={FormData.make}
      makeResult={FormData.toResult}
      validationSchema={FormData.validationSchema}
    >
      {({ firstInputRef, submitting, formProps }) => (
        <>
          {props.config.readOnly && <ViewOnlyWarningPanel/>}
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextField<FormData>
                label={"URL-friendly alias"}
                name={"alias"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={100}
                textBoxRef={firstInputRef as React.RefObject<HTMLInputElement>}
              />
              <TextField<FormData>
                label={"Name"}
                name={"name"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
                maxLength={200}
              />
              {!props.config.readOnly && (
                <TextAreaField<FormData>
                  label={"Internal notes"}
                  name={"notes"}
                  disabled={submitting}
                />
              )}
              {
                props.config.item && !props.config.isNew && (
                  <>
                    <FormField>Created: {friendlyDateTime(props.config.item.createdAt)}</FormField>
                    {!props.embedded && (
                      <FormField>
                        <NewTabLink to={routes.organizationHomePath(props.config.item.alias)}>
                          Open Admin Portal
                        </NewTabLink>
                      </FormField>
                    )}
                  </>
                )
              }
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              <TextAreaField<FormData>
                label={"Domains"}
                name={"domains"}
                required={true}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
              <DateField<FormData>
                label={"Activation date"}
                name={"activatedAt"}
                required={true}
              />
              {!props.config.readOnly && (
                <CheckboxField<FormData>
                  label={"Enable Alumni Member Benefit Program"}
                  name={"revenueSharingEnabled"}
                  disabled={submitting}
                />
              )}
            </FormLayout>
          </SimplePanel>
          <SimplePanel>
            <FormLayout noBottomMargin={true}>
              {props.config.item && !props.config.isNew && (
                <ThemeLookupField<FormData>
                  name={"themeId"}
                  readOnly={props.config.readOnly}
                  disabled={submitting}
                  lookupController={themeLookupController}
                  detailsController={props.themeDetailsController}
                  viewPathFactory={themeViewPathFactory}
                />
              )}
              <FileUploadField<FormData>
                label={"Logo"}
                name={"logo"}
                controller={props.fileUploadController}
                renderPreview={(id) => <ImageUploadPreview id={id}/>}
                readOnly={props.config.readOnly}
                disabled={submitting}
              />
            </FormLayout>
          </SimplePanel>
        </>
      )}
    </ItemForm>
  );
};
