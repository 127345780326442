import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { AccessListUpdate } from "../../types/models/accessListUpdate";

export function useGetAccessListUpdateQuery(
  id: number | undefined
): ManagedQueryHook<GraphQL.GetAccessListUpdateQueryVariables, AccessListUpdate> {
  return useManagedQuery({
    query: GraphQL.useGetAccessListUpdateQuery,
    deps: id,
    prepare: (deps) => ({ id: deps }),
    extract: (data: GraphQL.GetAccessListUpdateQuery) => nullToUndefined(data.accessListUpdate),
    complete: AccessListUpdate.parse
  });
}
