import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface ExternalHelpArticle {
  content: string;              // Can be a URL or plain HTML
  title: string | undefined;    // This is for breadcrumbs
  summary: string | undefined;  // This is a tooltip
}

export namespace ExternalHelpArticle {
  export function fromGraphQL(props: GraphQL.ExternalHelpArticle): ExternalHelpArticle {
    return {
      content: props.content,
      title: nullToUndefined(props.title),
      summary: nullToUndefined(props.summary),
    };
  }
}
