import * as React from "react";
import { keyframes, styled } from "../../app/theme";
import { Keyframes } from "styled-components";

interface Props {
  timeout: number;
  onHide?: () => void;
}

const FadeTime = 250;

const Container = styled.div<{ animation: Keyframes, time: number }>`
  animation: ${(props) => props.animation} ${(props) => props.time / 1000}s forwards;
`;

export const Flash: React.FunctionComponent<Props> = (props) => {
  const [hidden, setHidden] = React.useState(false);

  React.useEffect(
    () => {
      if (props.timeout) {
        setHidden(false);
        const beginTimer = window.setTimeout(() => setHidden(true), props.timeout);
        const endTimer = window.setTimeout(
          () => {
            if (props.onHide) {
              props.onHide();
            }
          },
          props.timeout + FadeTime
        );
        return () => {
          clearTimeout(beginTimer);
          clearTimeout(endTimer);
        };
      }
    },
    []
  );

  if (props.timeout >= FadeTime * 2) {
    if (!hidden) {
      const animation = keyframes`
        0% { opacity: 0; }
        ${Math.round(FadeTime / props.timeout * 100)}% { opacity: 1; }
        ${Math.round((props.timeout - FadeTime) / props.timeout * 100)}% { opacity: 1; }
        100% { opacity: 0; }
    `;
      return <Container animation={animation} time={props.timeout}>{props.children}</Container>;
    } else {
      return null;
    }
  } else {
    return <>{props.children}</>;
  }
};
