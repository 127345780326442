import * as React from "react";
import { Program } from "../../../../types/models/program";
import { EarningsSourceDetails } from "./earningsSourceDetails";
import { ProgramStatus } from "../../../../types/models/programStatus";
import { useRoutes } from "../../../../app/routes/useRoutes";
import { DiscountType } from "../../../../types/models/discountType";
import { Markdown } from "../../../widgets/markdown";

interface Props {
  organizationAlias: string;
  program: Program;
}

export const ProgramDashboard: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  return (
    <EarningsSourceDetails
      name={props.program.name}
      sourceType={"Auto-Redirect to Member Benefits"}
      entityType={"Auto-Member-Benefit Program"}
      endUserDiscount={
        props.program.discount + "% off EDU Migration Fee" +
        (props.program.discountType === DiscountType.BasePriceAndDataFees ? " & Data" : "")
      }
      revenueShare={props.program.revenueShare}
      endUserAccess={
        props.program.endUserAccess
          ? <Markdown>{props.program.endUserAccess}</Markdown>
          : (
            <>
              VaultMe automatically redirects qualified End Users to this program based on the Source Account
              used in such migrations.
            </>
          )
      }
      isEnabled={props.program.status === ProgramStatus.Live}
      detailsPath={routes.organizationPortalRoutes.programs(props.organizationAlias).editPath(props.program.id)}
    />
  );
};
