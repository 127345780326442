import { AppBootstrapConfig } from "../../types/models/appBootstrapConfig";

export enum AuthContext {
  SignUp = "SignUp",
  Identification = "Identification",
  Source = "Source",
  Destination = "Destination"
}

export namespace AuthContext {
  export function choose<T>(context: AuthContext, source: T, destination: T): T {
    return context === AuthContext.Source ? source : destination;
  }

  export function toString(context: AuthContext, appBootstrapConfig: AppBootstrapConfig): string {
    return context === AuthContext.Source
      ? appBootstrapConfig.configuration?.sourceCloudServiceReference || "Source Account"
      : appBootstrapConfig.configuration?.destinationCloudServiceReference || "Destination Account";
  }

  export function scanOrTest(context: AuthContext): string {
    return context === AuthContext.Source ? "Scan" : "Test";
  }

  export function scanningOrTesting(context: AuthContext): string {
    return context === AuthContext.Source ? "Scanning" : "Testing";
  }
}
