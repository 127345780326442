import * as React from "react";
import { styled } from "../../app/theme";
import { Spinner } from "../glyphs/spinner";
import { SmallButton } from "../widgets/button";

const RefreshButtonWithTimerLayout = styled.div`
  display: flex;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  flex-shrink: 0;
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: .5rem;
  
  ${(props) => props.theme.responsive.respondToXSmall()} {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
`;

interface RefreshButtonWithTimerProps {
  startedAt: Date;
  timeout: number;
  refreshDisabled?: boolean;
  onRefresh: () => void;
}

export const RefreshButtonWithTimer: React.FunctionComponent<RefreshButtonWithTimerProps> = (props) => {
  const [tick, setTick] = React.useState(0);

  React.useEffect(
    () => {
      const timer = setInterval(
        () => setTick((current) => current + 1),
        1000
      );
      return () => clearTimeout(timer);
    },
    []
  );

  React.useEffect(() => setTick(0), [props.startedAt]);

  const progress = Math.min(100, tick / props.timeout * 100);

  return (
    <RefreshButtonWithTimerLayout>
      <SpinnerContainer>
        <Spinner progress={progress} backgroundColor={"gray"}/>
      </SpinnerContainer>
      <SmallButton
        color={"white"}
        onClick={props.onRefresh}
        disabled={props.refreshDisabled}
      >
        Refresh
      </SmallButton>
    </RefreshButtonWithTimerLayout>
  );
};

export interface RefreshTimer {
  startedAt: Date;
  timeout: number;
  resetTimer: () => void;
  refresh: () => void;
}

export function useRefreshTimer(refresh: () => void, timeout: number = 60): RefreshTimer {
  const [startedAt, setStartedAt] = React.useState<Date>(new Date());

  React.useEffect(
    () => {
      resetTimer();
      const timer = setInterval(
        () => {
          refresh();
          resetTimer();
        },
        timeout * 1000
      );
      return () => clearInterval(timer);
    },
    [timeout]
  );

  function resetTimer() {
    setStartedAt(new Date());
  }

  return {
    startedAt,
    timeout,
    resetTimer,
    refresh: () => {
      refresh();
      resetTimer();
    }
  };
}
