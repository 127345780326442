import { OrganizationPortalUser } from "../../types/models/organizationPortalUser";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { List } from "immutable";
import { OrganizationPortalUserRole } from "../../types/enums/organizationPortalUserRole";

type InsertOrganizationPortalUsersMutationHook = [
  (emailAddresses: List<string>, role: OrganizationPortalUserRole) => Promise<List<OrganizationPortalUser>>,
  OperationStatus<List<OrganizationPortalUser>>
];

export function useInsertOrganizationPortalUsersMutation(
  organizationId: number
): InsertOrganizationPortalUsersMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useInsertOrganizationPortalUsersMutation,
    extract: (data: GraphQL.InsertOrganizationPortalUsersMutation) =>
      nullToUndefined(data.insertOrganizationPortalUsers),
    complete: (users) => List(users).map(OrganizationPortalUser.parse)
  });

  function fireWith(
    emailAddresses: List<string>,
    role: OrganizationPortalUserRole
  ): Promise<List<OrganizationPortalUser>> {
    return fire({
      variables: { organizationId, emailAddresses: emailAddresses.toArray(), role },
      retry: () => fireWith(emailAddresses, role),
    });
  }

  return [fireWith, status];
}
