import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OffboardingProjectUpdate } from "../../types/models/offboardingProjectUpdate";
import { List } from "immutable";

export function useListOffboardingProjectUpdatesQuery(
  ids: string[]
): ManagedQueryHook<GraphQL.ListOffboardingProjectUpdatesQueryVariables, List<OffboardingProjectUpdate>> {
  return useManagedQuery({
    query: GraphQL.useListOffboardingProjectUpdatesQuery,
    deps: ids.length !== 0 ? ids : undefined,
    prepare: (deps) => ({ ids: deps }),
    extract: (data: GraphQL.ListOffboardingProjectUpdatesQuery) => nullToUndefined(data.offboardingProjectUpdates),
    complete: (offboardingProjectUpdates) => List(offboardingProjectUpdates)
      .map(OffboardingProjectUpdate.parse)
      .sortBy((update) => -update.createdAt)
  });
}
