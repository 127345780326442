import * as React from "react";
import { styled } from "../../app/theme";
import { Button } from "../../views/widgets/button";

const Layout = styled.div`
  position: absolute;
  top: .5rem;;
  left: .5rem;
  background: #f8f8f8;
  padding: .5rem;
  border-radius: 3px;
  
  > *:not(:last-child) {
    margin-right: .3rem;
  }
`;

interface ToolbarProps {
  // loading: boolean;
  animation: boolean;
  // onReloadButtonClick: () => void;
  onFitButtonClick: () => void;
  onAnimateButtonClick: () => void;
}

export const Toolbar: React.SFC<ToolbarProps> = (props) => (
  <Layout>
    {/*<Button size="small" color="white" onClick={props.onReloadButtonClick} disabled={props.loading}>*/}
    {/*  {props.loading ? "Loading..." : "Reload"}*/}
    {/*</Button>*/}
    <Button size="small" color="white" onClick={props.onFitButtonClick}>Fit</Button>
    <Button
      size="small"
      color={props.animation ? "blue" : "white"}
      onClick={props.onAnimateButtonClick}
    >
      Anim
    </Button>
  </Layout>
);
