import * as React from "react";
import { useSession } from "../../utils/useAppState";
import { Navigate, Outlet, useLocation } from "react-router";
import { SignInRedirectState } from "../../types/signInRedirectState";
import { useRoutes } from "../../app/routes/useRoutes";

export const AuthenticatedRoutes: React.FunctionComponent = () => {
  const routes = useRoutes();
  const session = useSession();
  const location = useLocation();

  return session
    ? <Outlet/>
    : <Navigate to={routes.static.signInPath} state={SignInRedirectState(location, false)}/>;
};
