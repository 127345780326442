import { Anchor } from "../anchor";
import {
  ConfigurableWizardDestinationFactory,
  WizardStepFactory,
  WizardStep, ProvideInjectedWizardStepConfig, SkipConditions
} from "./wizardDestination";
import { makeWorkItem, WorkItemDef } from "../makeWorkItem";
import {
  WorkItemFactory,
  WorkItem, WorkItemConfig,
  WorkItemConfigUpdates,
  WorkItemDecorator
} from "../workItem";
import { WorkflowContextLike } from "../workflowContextLike";
import { List } from "immutable";

export interface WizardStepDef<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  > {
  id: string;

  route: (
    params: WorkItemDef.ParamsForComplete<WizardContext, Props, PropsContext, Result, ResultContext>
  ) => ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> | undefined;

  skipWhen?: (conditions: SkipConditions<WizardContext>) => boolean;

  getFutureSteps?: (
    params: WorkItemDef.ParamsForInitialized<WizardContext, Props, PropsContext, Result, ResultContext>
  ) => Anchor[];

  getPartialWizardResult?: (
    params: WorkItemDef.ParamsForInitialized<WizardContext, Props, PropsContext, Result, ResultContext>
  ) => Partial<WizardResult>;
}

class WizardStepImpl<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  >
  extends WorkItemDecorator<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext>
  implements WizardStep<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext> {
  public readonly type = "WizardStep";

  constructor(
    workItem: WorkItem<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext>,
    readonly wizardStepDef: WizardStepDef<
      WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext
      >
  ) {
    super(workItem);
  }

  public copy(
    updates: WorkItemConfigUpdates<WizardContext, Props, PropsContext, Result, ResultContext>
  ): WizardStepImpl<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext> {
    return new WizardStepImpl(this.workItem.copy(updates), this.wizardStepDef);
  }

  public getNextDestination():
    ConfigurableWizardDestinationFactory<WizardContext, WizardFeedback, WizardResult> | undefined {
    const result = this.getValidatedResult();
    return result && this.wizardStepDef.route(WorkItemConfig.paramsForComplete(this, this.getValidatedResult()));
  }

  public skipWhen(conditions: SkipConditions<WizardContext>): boolean {
    return this.wizardStepDef.skipWhen ? this.wizardStepDef.skipWhen(conditions) : false;
  }

  public getFutureSteps(): List<Anchor> {
    return this.wizardStepDef.getFutureSteps
      ? List(this.wizardStepDef.getFutureSteps(WorkItemConfig.paramsForInitialized(this, this.getValidatedResult())))
      : List();
  }

  public getWizardPartialResult(): Partial<WizardResult> {
    return this.wizardStepDef.getPartialWizardResult
      ? this.wizardStepDef.getPartialWizardResult(WorkItemConfig.paramsForInitialized(this, this.getValidatedResult()))
      : {};
  }
}

export function makeWizardStepFromWorkItem<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  >(
  makeWorkItemFunction: WorkItemFactory<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext>,
  wizardStepDef: WizardStepDef<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>
): WizardStepFactory<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext> {
  return (props: Props) =>
    (provide: ProvideInjectedWizardStepConfig<WizardContext, Props, PropsContext, Result, ResultContext>) =>
        new WizardStepImpl<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>(
          makeWorkItemFunction(props)({ ...provide(wizardStepDef.id), id: wizardStepDef.id }),
          wizardStepDef
        );
}

export function makeWizardStep<
  WizardContext extends WorkflowContextLike,
  WizardFeedback,
  WizardResult,
  Props,
  PropsContext,
  Result,
  ResultContext extends PropsContext
  >(
  wizardStepDef: WorkItemDef<WizardContext, WizardFeedback, Props, PropsContext, Result, ResultContext> &
    WizardStepDef<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext>
): WizardStepFactory<WizardContext, WizardFeedback, WizardResult, Props, PropsContext, Result, ResultContext> {
  return makeWizardStepFromWorkItem(makeWorkItem(wizardStepDef), wizardStepDef);
}
