import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { Configuration } from "../../types/models/configuration";

export function useGetConfigurationQuery(
  id: number
): ManagedQueryHook<GraphQL.GetConfigurationQueryVariables, Configuration> {
  return useManagedQuery({
    query: GraphQL.useGetConfigurationQuery,
    deps: null,
    prepare: () => ({ id }),
    extract: (data: GraphQL.GetConfigurationQuery) => nullToUndefined(data.configuration),
    complete: (configuration) => Configuration.parse(configuration),
    fetchPolicy: "cache-and-network"
  });
}
