import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";

export interface PricingModelFormData {
  readonly name: string;
  readonly basePriceDescription: string;
  readonly notes: string | undefined;

  readonly basePrice: number;
  readonly basePriceForFollowUpMigrations: number | undefined;
  readonly numberOfGbsIncluded: number;
  readonly numberOfItemsIncluded: number;
  readonly pricePerGb: number;
  readonly pricePerThousandItems: number;
}

export namespace PricingModelFormData {
  export function toGraphQL(data: PricingModelFormData): GraphQL.PricingModelFormData {
    return {
      name: data.name,
      basePriceDescription: data.basePriceDescription,
      notes: nullToUndefined(data.notes),

      basePrice: data.basePrice,
      basePriceForFollowUpMigrations: nullToUndefined(data.basePriceForFollowUpMigrations),
      numberOfGbsIncluded: data.numberOfGbsIncluded,
      numberOfItemsIncluded: data.numberOfItemsIncluded,
      pricePerGb: data.pricePerGb,
      pricePerThousandItems: data.pricePerThousandItems
    };
  }
}
