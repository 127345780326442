import * as React from "react";
import { AppTheme, css, keyframes, styled, useAppTheme } from "../../app/theme";
import { Constants } from "../../app/constants";
import { Hamburger } from "../glyphs/hamburger";
import { HamburgerClose } from "../glyphs/hamburgerClose";
import { VaultMeLogo } from "../glyphs/vaultMeLogo";
import { AdminToolsMode } from "../../state/settings/state";
import { useSession, useSessionOrFail, useSettings } from "../../utils/useAppState";
import { WhenElevated } from "../utils/whenElevated";
import { CurrentUserInfo } from "./currentUserInfo";
import { LinkButton } from "../widgets/linkButton";
import { AdminMenu } from "./adminMenu";
import { Link } from "react-router-dom";
import { useDrawer } from "./drawer";
import { useDispatch } from "react-redux";
import { toggledAdminToolsAction } from "../../state/settings/actions";
import { WhenAuthenticated } from "../utils/whenAuthenticated";
import { useRoutes } from "../../app/routes/useRoutes";
import { useAppBootstrapConfig } from "../../app/configuration";
import { UserIcon } from "../glyphs/userIcon";
import { signOut } from "../../app/signOut";
import { useLocation } from "react-router";

export interface HeaderProps {
  leftHandNavOpen: boolean;
  adminToolsMode: AdminToolsMode;
  onLeftHandNavToggle: () => void;
}

const StyledHeader = styled.header<{ adminToolsMode: AdminToolsMode }>`
  background: ${(props) => props.theme.colors.darkerPrimary};
  height: ${(props) => props.theme.layout.header.desktop}rem;
  position: fixed;
  z-index: ${(props) => props.theme.layers.header};
  display: flex;
  width: ${(props) => props.adminToolsMode === AdminToolsMode.Hidden ? 100 : 40}%;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    height: ${(props) => props.theme.layout.header.mobile}rem;
  }
`;

const HamburgerButton = styled.button<{ adminToolsMode: AdminToolsMode }>`
  padding: 0.85rem 0.8rem 0.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  display: none;
  align-items: center;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    display: flex;
  }
`;

const StyledHamburgerIcon = styled.svg`
  height: 1rem;
  width: 1rem;
`;

interface LogoLinkProps {
  leftHandNavOpen: boolean;
  adminToolsMode: AdminToolsMode;
}

const LogoLink = styled.a<LogoLinkProps>`
  display: ${(props) => props.leftHandNavOpen ? "none" : "flex"};
  flex-shrink: 0;
  flex-grow: 0;
  align-items: baseline;
  padding: 0.7rem 1rem;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    padding: 0.55rem 0.3rem 0.55rem 0.1rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

const StyledVaultMeLogo = styled(VaultMeLogo)<{ adminToolsMode: AdminToolsMode }>`
  height: 2.3rem;
  width: 8.1rem;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    height: 1.7rem;
    width: 5.6rem;
  }
`;

const LogoSuffix = styled.div<{ adminToolsMode: AdminToolsMode }>`
  color: ${(props) => props.theme.colors.white};
  font-size: 0.9rem;
  margin-left: .5rem;

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    font-size: 0.7rem;
    margin-left: .3rem;
    position: relative;
    top: -0.05rem;
  }
`;

const ToolbarSection = styled.div`
  display: flex;

  > * {
    margin-left: .6rem;
  }
`;

const Toolbar = styled.div<{ adminToolsMode: AdminToolsMode }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font.small}rem;

  ${LinkButton}, a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: underline;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
    ${ToolbarSection} {
      display: none;
    }
  }
`;

const AdminButtonContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

function styledSidebarButtonHoverMixin(theme: AppTheme, hideAdminTools: boolean) {
  return hideAdminTools
    ? css`
            border-color: ${theme.colors.red};
            color: ${theme.colors.red};
    `
    : css`
            border-color: ${theme.colors.lightRed};
            background: ${theme.colors.lightRed};
            color: ${theme.colors.black};
    `;
}

const StyledSidebarButton = styled.button<{ hideAdminTools: boolean }>`
  display: block;
  box-sizing: border-box;
  height: 1rem;
  padding: 0 .7rem;

  border: 1px solid ${(props) => props.hideAdminTools ? props.theme.colors.lightRed : props.theme.colors.red};
  border-radius: 1rem;
  background: ${(props) => props.hideAdminTools ? "none" : props.theme.colors.red};
  color: ${(props) => props.hideAdminTools ? props.theme.colors.lightRed : props.theme.colors.white};

  font-size: .65rem;

  text-transform: uppercase;
  cursor: pointer;

  transition: border-color .25s, background-color .25s, color .25s;

  &:hover {
    ${(props) => styledSidebarButtonHoverMixin(props.theme, props.hideAdminTools)};
  }
`;

const SidebarButton: React.FunctionComponent = () => {
  const hideAdminTools = useSettings().adminToolsMode === AdminToolsMode.Hidden;
  const dispatch = useDispatch();
  return (
    <StyledSidebarButton
      title="Show/hide admin tools (A)"
      hideAdminTools={hideAdminTools}
      onClick={() => dispatch(toggledAdminToolsAction())}
    >
      Admin
    </StyledSidebarButton>
  );
};

const StyledAdminButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: .5rem;
  width: 2rem;
  height: 2rem;
  display: block;

  svg {
    fill: ${(props) => props.theme.colors.lightRed};
    transition: fill .25s;
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.red};
    }
  }
`;

const AdminButton: React.FunctionComponent = () => {
  const drawer = useDrawer();

  return (
    <StyledAdminButton
      title="More admin tools"
      onClick={() => drawer.open(<AdminMenu/>)}
    >
      <svg viewBox="0 0 10 10">
        <circle cx={5} cy={5} r={5}/>
      </svg>
    </StyledAdminButton>
  );
};

const UserMenuButton = styled.button<{ adminToolsMode: AdminToolsMode }>`
  display: none;
  background: none;
  border: none;
  padding: .5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: -.5rem;

  &:hover {
    svg {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
  
  ${(props) => props.theme.responsive.respondToSmall(props.adminToolsMode === AdminToolsMode.SplitScreen)} {
   display: inline-block;
  }
`;

export const AppHeader: React.FunctionComponent<HeaderProps> = (props) => {
  const routes = useRoutes();
  const appBootstrapConfig = useAppBootstrapConfig();
  const session = useSession();
  const theme = useAppTheme();
  const location = useLocation();

  const [showUserMenu, setShowUserMenu] = React.useState(false);

  React.useEffect(
    () => {
      setShowUserMenu(false);
    },
    [location]
  );

  return (
    <>
      <StyledHeader adminToolsMode={props.adminToolsMode}>
        <HamburgerButton
          type="button"
          adminToolsMode={props.adminToolsMode}
          onClick={props.onLeftHandNavToggle}
        >
          {props.leftHandNavOpen ? <StyledHamburgerIcon as={HamburgerClose}/> : <StyledHamburgerIcon as={Hamburger}/>}
        </HamburgerButton>
        <LogoLink
          href={Constants.Product.marketingWebSiteUrl}
          leftHandNavOpen={props.leftHandNavOpen}
          adminToolsMode={props.adminToolsMode}
        >
          <StyledVaultMeLogo colorSchema="white-and-blue" adminToolsMode={props.adminToolsMode}/>
          {appBootstrapConfig.configuration?.headerSuffix && (
            <LogoSuffix adminToolsMode={props.adminToolsMode}>
              {appBootstrapConfig.configuration?.headerSuffix}
            </LogoSuffix>
          )}
        </LogoLink>
        <Toolbar adminToolsMode={props.adminToolsMode}>
          <WhenAuthenticated>
            <ToolbarSection>
              <Link to={routes.migrations.homePath}>My Migrations</Link>
              {
                session?.user.ambassadorsProgramMembership &&
                  <Link to={routes.ambassadors.myAmbassadorDashboardPath}>EDU Ambassador Dashboard</Link>
              }
              <WhenElevated alwaysShow={true}><Link to={routes.static.myProfilePath}>My Profile</Link></WhenElevated>
            </ToolbarSection>
          </WhenAuthenticated>
          <ToolbarSection>
            <CurrentUserInfo/>
          </ToolbarSection>
          <WhenAuthenticated>
            <UserMenuButton adminToolsMode={props.adminToolsMode} onClick={() => setShowUserMenu(true)}>
              <UserIcon color={theme.colors.white}/>
            </UserMenuButton>
          </WhenAuthenticated>
          <WhenElevated alwaysShow={true}>
            <AdminButtonContainer>
              <SidebarButton/>
              <AdminButton/>
            </AdminButtonContainer>
          </WhenElevated>
          {!session && <Link to={routes.static.signInPath}>Sign In</Link>}
        </Toolbar>
        {session && showUserMenu && <UserMenu onClose={() => setShowUserMenu(false)}/>}
      </StyledHeader>
    </>
  );
};

const fadeOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.8; }
`;

const Overlay = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(5,37,53,0.4);
  animation: ${fadeOut} 0.25s forwards;
  z-index: ${(props) => props.theme.layers.modal};
`;

const UserMenuLayout = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.layers.modal + 1};
  top: 3.5rem;
  right: .5rem;
  max-width: 20rem;

  border: 1px solid ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkerPrimary};
  color: ${(props) => props.theme.colors.white};

  ${LinkButton}, a {
    color: ${(props) => props.theme.colors.white};
    font-size: 0.9rem;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const SignedIn = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  padding: 0.8rem;
`;

const Links = styled.div`
  padding: 0.8rem;

  * {
    display: block;
  }

  * ~ * {
    margin-top: .4rem
  }
`;

interface UserMenuProps {
  onClose: () => void;
}

const UserMenu: React.FunctionComponent<UserMenuProps> = (props) => {
  const routes = useRoutes();
  const session = useSessionOrFail();

  return (
    <>
      <Overlay onClick={props.onClose}/>
      <UserMenuLayout>
        <SignedIn>Signed in as {session.user.personalInfo.fullName || "an anonymous user"}</SignedIn>
        <Links>
          <Link to={routes.migrations.homePath}>My Migrations</Link>
          {
            session?.user.ambassadorsProgramMembership &&
              <Link to={routes.ambassadors.myAmbassadorDashboardPath}>EDU Ambassador Dashboard</Link>
          }
          <WhenElevated alwaysShow={true}><Link to={routes.static.myProfilePath}>My Profile</Link></WhenElevated>
          <LinkButton onClick={signOut}>Sign Out</LinkButton>
        </Links>
      </UserMenuLayout>
    </>
  );
};
