import * as React from "react";
import { PanelRow } from "../../../containers/rows/panelRow";
import { MigrationNote } from "../../../../types/models/migrationNote";
import { OperationStatus } from "../../../../types/operationStatus";
import { List } from "immutable";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { AppTheme, styled } from "../../../../app/theme";
import { briefDateTime } from "../../../../utils/formatting";
import { Button } from "../../../widgets/button";
import { MigrationNoteType } from "../../../../types/models/migrationNoteType";
import { Switch, SwitchItem, SwitchStyle } from "../../../widgets/switch";
import { SimpleToolbar } from "../../../widgets/simpleToolbar";
import { TextArea } from "../../../widgets/textArea";
import { Markdown } from "../../../widgets/markdown";
import { WidgetStatus } from "../../../utils/widgetStatus";

export interface MigrationNoteControllerProps {
  migrationNote: MigrationNote;
  render: (props: MigrationNoteViewProps) => React.ReactElement;
}

interface MigrationNotesViewProps {
  migrationId: string;
  migrationNotes: List<MigrationNote>;
  migrationNoteController: React.ComponentType<MigrationNoteControllerProps>;
  addMigrationNoteController: React.ComponentType<AddMigrationNoteControllerProps>;
}

export const MigrationNotesView: React.FunctionComponent<MigrationNotesViewProps> = (props) => (
  <>
    {
      React.createElement(props.addMigrationNoteController, {
        migrationId: props.migrationId,
        render: (viewProps) => <AddMigrationNoteRow {...viewProps}/>
      })
    }
    {
      props.migrationNotes
        .sortBy((migrationNote) => -migrationNote.createdAt.getTime())
        .map((migrationNote) =>
          React.createElement(props.migrationNoteController, {
            key: migrationNote.id,
            migrationNote,
            render: (viewProps) => <MigrationNoteRow {...viewProps}/>
          })
        )
    }
  </>
);

const NoteHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: .2rem;
`;

const NoteHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -.1rem .2rem .2rem -.2rem;
  align-items: center;
  
  &:last-child {
    margin-right: -.2rem;
  }
  
  > * {
    margin: .1rem .2rem;
  }
`;

const IconContainer = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`;

const Author = styled.div`
  font-weight: ${(props) => props.theme.font.medium};
`;

const Timestamp = styled.div`
`;

type ActiveTagColor = "red" | "yellow" | "blue";

function activeTagColor(theme: AppTheme, color: ActiveTagColor) {
  switch (color) {
    case "red": return theme.colors.red;
    case "yellow": return theme.colors.yellow;
    case "blue": return theme.colors.primary;
  }
}

interface ActiveTagProps {
  color: ActiveTagColor;
}

const ActiveTag = styled.div<ActiveTagProps>`
  background: ${(props) => activeTagColor(props.theme, props.color)};
  color: white;
  font-size: 0.7rem;
  height: 1.1rem;
  padding: 0.2rem;
  box-sizing: border-box;
`;

export interface MigrationNoteViewProps {
  migrationNote: MigrationNote;
  editing: boolean;

  resolveMigrationNoteStatus: OperationStatus<MigrationNote>;
  reopenMigrationNoteStatus: OperationStatus<MigrationNote>;
  updateMigrationNoteStatus: OperationStatus<MigrationNote>;

  onResolve: () => void;
  onReopen: () => void;
  onSetEditState: (state: boolean) => void;
  onUpdate: (type: MigrationNoteType, message: string) => void;
}

const MigrationNoteRow: React.FunctionComponent<MigrationNoteViewProps> = (props) => {
  const isWorking = (
    props.resolveMigrationNoteStatus.isWorking() ||
    props.reopenMigrationNoteStatus.isWorking() ||
    props.updateMigrationNoteStatus.isWorking()
  );

  function renderResolveButton(title: string) {
    return (
      <Button
        size={"small"}
        color={"white"}
        onClick={props.onResolve}
        disabled={isWorking}
      >
        {title}
      </Button>
    );
  }

  function renderReopenButton(title: string) {
    return (
      <Button
        size={"small"}
        color={"white"}
        onClick={props.onReopen}
        disabled={isWorking}
      >
        {title}
      </Button>
    );
  }

  function renderStatus() {
    if (props.migrationNote.type === MigrationNoteType.Info) {
      if (props.migrationNote.active) {
        return (
          <>
            <ActiveTag color={"blue"}>Pinned</ActiveTag>
            {renderResolveButton("Unpin")}
          </>
        );
      } else {
        return renderReopenButton("Pin");
      }
    } else {
      if (props.migrationNote.active) {
        return (
          <>
            <ActiveTag
              color={props.migrationNote.type === MigrationNoteType.Warning ? "yellow" : "red"}
            >
              Active
            </ActiveTag>
            {renderResolveButton("Resolve")}
          </>
        );
      } else {
        return renderReopenButton("Reopen");
      }
    }
  }

  function rowStatus(): WidgetStatus {
    if (props.migrationNote.type === MigrationNoteType.Info) {
      if (props.migrationNote.active) {
        return WidgetStatus.Info;
      }
    } else if (props.migrationNote.active) {
      return props.migrationNote.type === MigrationNoteType.Warning
        ? WidgetStatus.Warning
        : WidgetStatus.Error;
    }
    return WidgetStatus.Normal;
  }

  return (
    <>
      <OperationStatusIndicator
        progressMessage={"Updating note status..."}
        failureMessage={"Failed to update note status"}
        status={props.resolveMigrationNoteStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      <OperationStatusIndicator
        progressMessage={"Updating note status..."}
        failureMessage={"Failed to update note status"}
        status={props.reopenMigrationNoteStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      <OperationStatusIndicator
        progressMessage={"Updating note..."}
        failureMessage={"Failed to update note"}
        status={props.updateMigrationNoteStatus}
        indicators={StatusIndicators.PanelRow()}
      />
      <PanelRow size={"smaller"} status={rowStatus()}>
        <NoteHeader>
          <NoteHeaderSection>
            <IconContainer>{MigrationNoteType.icon(props.migrationNote.type)}</IconContainer>
            <Author>{props.migrationNote.createdBy}</Author>
            <div>-</div>
            <Timestamp>{briefDateTime(props.migrationNote.createdAt)}</Timestamp>
            {
              !props.migrationNote.active &&
              props.migrationNote.type !== MigrationNoteType.Info &&
              props.migrationNote.resolvedAt &&
              <Timestamp>
                {
                  "(Resolved - " + briefDateTime(props.migrationNote.resolvedAt) +
                  " by " + (props.migrationNote.resolvedBy || "?") + ")"
                }
              </Timestamp>
            }
          </NoteHeaderSection>
          <NoteHeaderSection>
            {renderStatus()}
            <Button
              size={"small"}
              color={"white"}
              disabled={isWorking}
              onClick={() => props.onSetEditState(!props.editing)}
            >
              Edit
            </Button>
          </NoteHeaderSection>
        </NoteHeader>
        {
          props.editing
            ? (
              <MigrationNoteForm
                message={props.migrationNote.message}
                migrationNoteType={props.migrationNote.type}
                isWorking={props.updateMigrationNoteStatus.isWorking()}

                onSubmit={props.onUpdate}
                onCancel={() => props.onSetEditState(false)}
              />
            )
            : <Markdown markdown={props.migrationNote.renderedMessage}/>
        }
      </PanelRow>
    </>
  );
};

const ChipIconContainer = styled.div`
  display: inline-block;
  width: .8rem;
  height: 0.8rem;
  position: relative;
  top: .1rem;
  margin-right: .2rem;
`;

const MigrationNoteTypeToolbarItems: SwitchItem[] = [
  {
    value: MigrationNoteType.Info,
    content: (
      <>
        <ChipIconContainer>{MigrationNoteType.icon(MigrationNoteType.Info)}</ChipIconContainer>
        Note
      </>
    )
  },
  {
    value: MigrationNoteType.Warning,
    content: (
      <>
        <ChipIconContainer>{MigrationNoteType.icon(MigrationNoteType.Warning)}</ChipIconContainer>
        Warning
      </>
    )
  },
  {
    value: MigrationNoteType.Issue,
    content: (
      <>
        <ChipIconContainer>{MigrationNoteType.icon(MigrationNoteType.Issue)}</ChipIconContainer>
        Issue
      </>
    )
  },
];

const StyledTextArea = styled(TextArea)`
  margin: .75rem 0;
`;

export interface AddMigrationNoteControllerProps {
  migrationId: string;
  render: (props: AddMigrationNoteViewProps) => React.ReactElement;
}

interface MigrationNoteFormProps {
  message: string;
  migrationNoteType: MigrationNoteType;

  isWorking: boolean;

  onSubmit: (type: MigrationNoteType, message: string) => void;
  onCancel: () => void;
}

export interface AddMigrationNoteViewProps {
  open: boolean;
  status: OperationStatus<any>;

  onStateChange: (open: boolean) => void;
  onSubmit: (type: MigrationNoteType, message: string) => void;
}

const AddMigrationNoteRow: React.FunctionComponent<AddMigrationNoteViewProps> = (props) => {
  if (props.open) {
    return (
      <>
        <OperationStatusIndicator
          progressMessage={"Adding migration note..."}
          failureMessage={"Failed to add a note"}
          status={props.status}
          indicators={StatusIndicators.PanelRow()}
        />
        <PanelRow>
          <MigrationNoteForm
            message={""}
            migrationNoteType={MigrationNoteType.Info}
            isWorking={props.status.isWorking()}

            onSubmit={props.onSubmit}
            onCancel={() => props.onStateChange(false)}
          />
        </PanelRow>
      </>
    );
  } else {
    return (
      <PanelRow>
        <Button size={"small"} color={"white"} onClick={() => props.onStateChange(true)}>+ Add Note</Button>
      </PanelRow>
    );
  }
};

const MigrationNoteForm: React.FunctionComponent<MigrationNoteFormProps> = (props) => {
  const [migrationNoteType, setMigrationNoteType] = React.useState<MigrationNoteType>(props.migrationNoteType);
  const [message, setMessage] = React.useState(props.message);
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(
    () => {
      window.setTimeout(
        ()  => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          }
        },
        0
      );
    },
    []
  );

  return (
    <>
      <Switch
        style={SwitchStyle.Chips}
        items={MigrationNoteTypeToolbarItems}
        selected={migrationNoteType}
        onSelect={setMigrationNoteType}
      />
      <StyledTextArea
        ref={textAreaRef}
        value={message}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)}
        maxWidth={40}
        disabled={props.isWorking}
        placeholder={"Type your message here. Feel free to use Markdown!"}
      />
      <SimpleToolbar>
        <Button
          size={"small"}
          onClick={() => props.onSubmit(migrationNoteType, message)}
          disabled={props.isWorking}
        >
          Submit
        </Button>
        <Button
          size={"small"}
          color={"white"}
          onClick={props.onCancel}
          disabled={props.isWorking}
        >
          Cancel
        </Button>
        {
          "Note: only the first " + MigrationNote.DashboardSnippetLength +
          " characters will be displayed on the dashboard"
        }
      </SimpleToolbar>
    </>
  );
};
