import * as React from "react";
import { ConnectionPanelRow, ConnectionPanelRowDefs } from "../../../blocks/connectionPanelRow";
import { IncrementalSignIn, IncrementalSignInDefs } from "../../../blocks/incrementalSignIn";
import { SignInLayout } from "../../../blocks/auth/signInLayout";
import { sentence } from "../../../../utils/misc";
import { WorkStatus } from "../../../models/workStatus";
import { friendlySizeOf } from "../../../../utils/formatting";
import { prepareActionItems } from "../prepareActionItems";
import { MigrationStatusPageDefs } from "../migrationStatusPageDefs";
import { AreaStatus } from "../../../models/areaStatus";
import { CloudServices } from "../../../../types/models/cloudServices";
import { useDrawer } from "../../../layouts/drawer";
import { ProgressBar } from "../../../widgets/progressBar";
import { ActionItems } from "../../../models/actionItem";

interface SourceAreaRowProps {
  cloudServices: CloudServices;
  selectedCloudServiceId: string;
  connectionId: string;
  destination: MigrationStatusPageDefs.DestinationConnectionDetails;
  area: MigrationStatusPageDefs.SourceArea;
  connectionSlowness: boolean;
  connectionErrors: boolean;
  context: MigrationStatusPageDefs.ContextType;
  actionItemSuppressing: ActionItems.Suppressing | undefined;
}

export const SourceAreaRow: React.FunctionComponent<SourceAreaRowProps> = (props) => {
  const drawer = useDrawer();

  const disabled = !!props.area.incrementalSignIn || !!props.area.disabled;
  const stats = props.context === MigrationStatusPageDefs.ContextType.Cumulative
    ? props.area.cumulative
    : props.area.iteration;

  function renderSummary(): string {
    const breakdown = stats.migratedItems.migrationSummary(stats.totalItems) + " migrated";
    const size = stats.totalBytes !== undefined && stats.migratedBytes !== undefined
      ? friendlySizeOf(stats.migratedBytes, stats.totalBytes)
      : undefined;
    return breakdown + (size ? " (" + size + ")" : "");
  }

  function renderSkippedItems(): React.ReactNode {
    if (stats.skippedItems.totalCount() === 0) {
      return "0 items skipped";
    } else {
      return stats.skippedItems.briefSummary() + " skipped";
    }
  }

  function renderTitle() {
    if (disabled || props.area.excluded) {
      return sentence(props.area.description);
    } else if (!disabled) {
      return sentence(props.area.description) + " " +
        (props.area.status === WorkStatus.Success ? "have been copied to " : "are being copied to ") +
        "destination";
    }
  }

  function renderContent(): ConnectionPanelRowDefs.Content {
    if (props.area.excluded) {
      return {};
    } if (props.area.disabled) {
      return { actionItems: AreaStatus.Disabled.toIssues(props.area.disabled, props.area) };
    } else {
      // const scanStatus = renderMigrationStatus(props.area) + " ";
      const summary = renderSummary() || "";
      return {
        summary: [
          // scanStatus,
          <>{summary}, {renderSkippedItems()}</>
        ],
        actionItems: prepareActionItems(props.area)
          .concat(IncrementalSignInDefs.Settings.mayBeToIssues(props.area.incrementalSignIn, handleSignInClick))
      };
    }
  }

  function handleSignInClick(incrementalSignIn: IncrementalSignInDefs.Settings) {
    drawer.open(
      <IncrementalSignIn
        incrementalSignIn={incrementalSignIn}
        layoutComponent={SignInLayout}
        onSignIn={() => drawer.close()}
      />
    );
  }

  const total = stats.totalItems?.totalCount();
  const migrated = stats.migratedItems.totalCount() + stats.skippedItems.totalCount();

  return (
    <>
      <ConnectionPanelRow
        icon={props.area.icon}
        title={renderTitle()}
        content={renderContent()}
        helpArticle={props.area.helpArticle}
        status={
          props.connectionErrors &&
          (props.area.status !== WorkStatus.Success && props.area.status !== WorkStatus.Failure)
            ? WorkStatus.Issue
            : props.area.status
        }
        disabled={disabled || props.area.excluded}
        strokeOut={!!props.area.disabled || props.area.excluded}
        progressIndicator={"normal"}
        indicateSlowness={!!props.area.slowness || props.connectionSlowness}
        actionItemSuppressing={props.actionItemSuppressing}
      />
      {
        props.area.status !== WorkStatus.Success &&
        <ProgressBar progress={total && Math.min(100, migrated / total * 100) || 0}/>
      }
    </>
  );
};
