import * as React from "react";
import { useNavigate, useLocation, Navigate } from "react-router";
import { SignInRedirectState } from "../types/signInRedirectState";
import { useAuthProviders, useCloudServices } from "../app/configuration";
import { SignInPageView } from "../views/screens/signInPageView";
import { useSignInFlow } from "../components/auth/useSignInFlow";
import { Constants } from "../app/constants";
import { Set } from "immutable";
import { UserType } from "../types/models/userType";
import { SignInContextType } from "../types/models/signInContextType";
import { useSession } from "../utils/useAppState";
import { useRoutes } from "../app/routes/useRoutes";

export const SignInPage: React.FunctionComponent = () => {
  const location  = useLocation();
  const navigate = useNavigate();
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();
  const session = useSession();
  const routes = useRoutes();

  const defaultRoles = Set.of(Constants.Auth.IdentificationRole);

  const signInFlow = useSignInFlow({
    flowId: "SignIn",
    contextType: SignInContextType.App,
    newUserSettings: { userType: UserType.Personal },
    defaultRoles,
    onSignIn: () => setTimeout(() => navigate(redirectTo), 0)
  });

  const redirectState: SignInRedirectState | undefined = location.state;
  const redirectTo = redirectState && redirectState.redirectTo || "/";
  const requireElevation = redirectState && redirectState.requireElevation;

  if (session) {
    return <Navigate to={routes.migrations.homePath}/>;
  } else {
    return (
      <SignInPageView
        requireElevation={!!requireElevation}
        cloudServices={cloudServices}
        authProviders={authProviders}
        state={signInFlow.state}
        defaultRoles={defaultRoles}
        onCloudServiceSelect={signInFlow.selectCloudService}
        onRolesSelect={signInFlow.selectRoles}
        onSignIn={signInFlow.handleSignInSuccess}
      />
    );
  }
};
