import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { TextBox } from "./textBox";
import { FormErrorMessage } from "./formErrorMessage";
import { FormFieldActionLink } from "./formFieldActionLink";

interface PasswordFieldProps<T> {
  label: string;
  name: keyof T;
  disabled?: boolean;
  textBoxRef?: React.RefObject<HTMLInputElement>;
}

// tslint:disable-next-line function-name
export function PasswordField<V>(props: PasswordFieldProps<V>) {
  const [reveal, setReveal] = React.useState(false);
  const showPassword = (
    <FormFieldActionLink onClick={() => setReveal(!reveal)}>
      {reveal ? "Hide" : "Show"} Password
    </FormFieldActionLink>
  );
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];
        return (
          <FormField label={props.label} error={error} action={showPassword} limitWidth={true}>
            <TextBox
              {...field.field}
              ref={props.textBoxRef}
              password={!reveal}
              error={error}
              autoComplete="off"
              disabled={props.disabled}
              onChange={(event) => {
                field.form.setFieldError(event.target.name, "");
                field.field.onChange(event);
              }}
            />
            <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
          </FormField>
        );
      }}
    />
  );
}
