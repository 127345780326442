import { GraphQL } from "../../services/graphql/generated";
import { CollectableBoolean } from "./collectableBoolean";
import { CollectableInteger } from "./collectableInteger";
import { CollectableItemBreakdown } from "./collectableItemBreakdown";
import { Collectable } from "./collectable";
import { ParsingError } from "../errors/parsingError";

export function parseCollectable(collectable: GraphQL.CollectableFragment): Collectable.Any {
  switch (collectable.__typename) {
    case "CollectableBoolean":
      return CollectableBoolean.build(
        collectable,
        collectable.partialBooleanValue,
        collectable.completeBooleanValue,
      );

    case "CollectableInteger":
      return CollectableInteger.build(
        collectable,
        collectable.partialIntegerValue,
        collectable.completeIntegerValue
      );

    case "CollectableItemBreakdown":
      return CollectableItemBreakdown.build(
        collectable,
        collectable.partialItemBreakdownValue,
        collectable.completeItemBreakdownValue,
      );

    case undefined:
      throw new ParsingError("Missing collectable type");
  }
}
