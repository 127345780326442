import * as React from "react";
import { Panel } from "../../containers/panel";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { PanelRow } from "../../containers/rows/panelRow";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { friendlyCount } from "../../../utils/formatting";
import { GridPanelRow } from "../../containers/rows/gridPanelRow";
import { ScansGrid, StopScanToolControllerComponentType } from "./scansGrid";
import { OperationStatus } from "../../../types/operationStatus";
import { List } from "immutable";
import { Scan } from "../../../types/models/scan";
import { Button } from "../../widgets/button";
import { Switch } from "../../widgets/switch";
import { RefreshingDataSource, RefreshingDataSourceTimer } from "../../utils/refreshingDataSource";
import { Connections } from "../../../types/models/connections";

export enum ScansManagementToolMode {
  Running = "Running",
  Recent = "Recent",
  All = "All"
}

interface Props {
  mode: ScansManagementToolMode;
  limit: number;
  connections: Connections;
  dataSource: RefreshingDataSource<any, List<Scan>>;
  stopScanToolController: StopScanToolControllerComponentType;

  onModeChange: (mode: ScansManagementToolMode) => void;
}

export const ScansManagementToolView: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      status={props.dataSource.status}
      subject={"scans"}
      indicators={StatusIndicators.PanelRow()}
    />
    <PanelRow>
      <SimpleToolbar rightSide={<RefreshingDataSourceTimer dataSource={props.dataSource}/>}>
        {
          friendlyCount(props.dataSource.status.mapLastResult((scans) => scans.count()) || 0, "scan") +
          " found"
        }
        <Switch
          selected={props.mode}
          items={[
            { value: ScansManagementToolMode.Running, content: "Running Only" },
            { value: ScansManagementToolMode.Recent, content: "Last " + props.limit },
            { value: ScansManagementToolMode.All, content: "All" },
          ]}
          onSelect={props.onModeChange}
        />
      </SimpleToolbar>
    </PanelRow>
    {
      props.dataSource.status.mapLastResult((scans) =>
        !scans.isEmpty() && (
          <GridPanelRow>
            <ScansGrid
              connections={props.connections}
              scans={scans}
              stopScanToolController={props.stopScanToolController}
              onRefresh={props.dataSource.refresh}
            />
          </GridPanelRow>
        )
      )
    }
  </Panel>
);
