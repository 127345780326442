import { GraphQL } from "../../services/graphql/generated";
import { resolveImage } from "../../app/images";
import { AppBootstrapConfig } from "./appBootstrapConfig";
import { RoutesHook } from "../../app/routes/useRoutes";
import { Set } from "immutable";

export class CloudService implements CloudService.Props {
  constructor(protected readonly props: CloudService.Props) {
  }

  public get id(): string { return this.props.id; }
  public get name(): string { return this.props.name; }
  public get reference(): string { return this.props.reference; }
  public get description(): string | undefined { return this.props.description; }

  public get logo(): any { return this.props.logo; }
  public get icon(): any { return this.props.icon; }
  public get color(): string { return this.props.color; }

  public get authProviderId(): string { return this.props.authProviderId; }
  public get accessKeyParams(): string { return this.props.accessKeyParams; }

  public get excludedApps(): Set<string> { return this.props.excludedApps; }

  public withConfiguration(
    configuration: AppBootstrapConfig.ConfigurationCloudService,
    routes: RoutesHook
  ): CloudService {
    return new CloudService({
      ...this.props,
      reference: configuration.reference || this.props.reference,
      description: configuration.description,
      logo: configuration.logoId ? routes.api.downloadUrl(configuration.logoId) : this.props.logo,
      excludedApps: configuration.excludedApps
        ? Set(configuration.excludedApps.split("\n").map((s) => s.trim()))
        : Set()
    });
  }
}

export namespace CloudService {
  export interface Props {
    readonly id: string;
    readonly name: string;
    readonly reference: string;
    readonly description: string | undefined;

    readonly logo: any;
    readonly icon: any;
    readonly color: string;

    readonly authProviderId: string;
    readonly accessKeyParams: string;

    readonly excludedApps: Set<string>;
  }

  export function fromGraphQL(cloudService: GraphQL.CloudService): CloudService {
    return new CloudService({
      id: cloudService.id,
      name: cloudService.name,
      reference: cloudService.name + " account",
      description: undefined,

      logo: resolveImage(cloudService.logo),
      icon: resolveImage(cloudService.icon),
      color: cloudService.color,

      authProviderId: cloudService.authProviderId,
      accessKeyParams: cloudService.accessKeyParams,

      excludedApps: Set()
    });
  }
}
