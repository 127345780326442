import * as React from "react";
import { OAuthSignInForm } from "../../views/blocks/auth/oAuthSignInForm";
import { SignInDefs } from "../../views/blocks/auth/signInDefs";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { buildHelpContent } from "./buildHelpContent";
import { useOAuthSignInFlow } from "./useOAuthSignInFlow";

export interface OAuthSignInControllerConfig {
  url: string;
  callbackUrl: string;
  vendorName: string | undefined;
  buttonCss: string | undefined;
  buttonIcon: string | undefined;
  helpContent: SignInDefs.SignInHelpContent | undefined;
}

export namespace OAuthSignInControllerConfig {
  export function fromGraphQL(
    config: Omit<GraphQL.OAuthSignInFormConfigFragment, "__typename">,
    customization: GraphQL.OAuthSignInFormCustomization | undefined | null
  ): OAuthSignInControllerConfig {
    return {
      url: config.url,
      callbackUrl: config.callbackUrl,
      vendorName: customization !== undefined && customization !== null
        ? nullToUndefined(customization.vendorName)
        : undefined,
      buttonCss: customization !== undefined && customization !== null
        ? nullToUndefined(customization.buttonCss)
        : undefined,
      buttonIcon: customization !== undefined && customization !== null
        ? nullToUndefined(customization.buttonIcon)
        : undefined,
      helpContent: buildHelpContent(nullToUndefined(customization))
    };
  }
}

interface Props extends SignInDefs.SignInComponentProps {
  config: OAuthSignInControllerConfig;
}

export const OAuthSignInController: React.FunctionComponent<Props> = (props) => {
  const oAuthSignInFlow = useOAuthSignInFlow({
    url: props.config.url,
    callbackUrl: props.config.callbackUrl,
    vendorName: props.config.vendorName,
    verb: props.verb,
    onSignIn: props.onSignIn
  });

  return React.createElement(
    props.layoutComponent,
    {
      signInForm: (
        <OAuthSignInForm
          cloudServiceId={props.cloudServiceId}
          verb={props.verb}
          vendorName={props.config.vendorName}
          buttonCss={props.config.buttonCss}
          buttonIcon={props.config.buttonIcon}

          howToSwitchAccountHelpArticle={props.config.helpContent?.howToSwitchAccount}
          additionalOptions={[]}

          onSignInButtonClick={oAuthSignInFlow.fire}
        />
      ),
      operationStatusIndicator: { status: oAuthSignInFlow.status },
      helpContent: props.config.helpContent
    });
};
