export namespace MigrationStatusPageState {
  export enum Type {
    Scheduled = "Scheduled",
    Starting = "Starting",
    Working = "Working",
    WaitingForManualReview = "WaitingForManualReview",
    Complete = "Complete",
    Aborted = "Aborted",
  }

  interface Base<T extends Type> {
    type: T;
  }

  export interface Scheduled extends Base<Type.Scheduled> {
  }

  export function scheduled(): Scheduled {
    return { type: Type.Scheduled };
  }

  export interface Starting extends Base<Type.Starting> {
  }

  export function starting(): Starting {
    return { type: Type.Starting };
  }

  export interface Working extends Base<Type.Working> {
  }

  export function working(): Working {
    return { type: Type.Working };
  }

  export interface WaitingForManualReview extends Base<Type.WaitingForManualReview> {
  }

  export function waitingForManualReview(): WaitingForManualReview {
    return { type: Type.WaitingForManualReview };
  }

  export interface Complete extends Base<Type.Complete> {
  }

  export function complete(): Complete {
    return { type: Type.Complete };
  }

  export interface Aborted extends Base<Type.Aborted> {
  }

  export function aborted(): Aborted {
    return { type: Type.Aborted };
  }

  export type Any = Scheduled | Starting | Working | WaitingForManualReview | Complete | Aborted;
}
