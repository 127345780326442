import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { mapOptional } from "../../utils/misc";
import { GoogleGroup } from "../../types/models/googleGroup";

type DescribeGoogleGroupMutationHook = [
  (id: string) => Promise<GoogleGroup | undefined>,
  OperationStatus<GoogleGroup | undefined>
];

export function useDescribeGoogleGroupMutation(): DescribeGoogleGroupMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useDescribeGoogleGroupMutation,
    extract: (data: GraphQL.DescribeGoogleGroupMutation) => data.describeGoogleGroup,
    complete: (googleGroup) => mapOptional(googleGroup, GoogleGroup.parse),
  });

  function fireWith(id: string): Promise<GoogleGroup | undefined> {
    return fire({
      variables: { id },
      retry: () => fireWith(id),
    });
  }

  return [fireWith, status];
}
