import { css, styled } from "../../app/theme";
import * as React from "react";
import { Random } from "../../utils/random";
import { LinkButton } from "../widgets/linkButton";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";

const AssetPadding = "1rem";
const AssetGridColor = "#dddddd";

const Image = styled.img`
  display: block;
  height: 100%;
`;

interface ImageContainerProps {
  tileWidth: string;
  tileHeight: string;
  backgroundColor: string | undefined;
}

const ImageContainer = styled.div<ImageContainerProps>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  width: ${(props) => props.tileWidth};
  height: ${(props) => props.tileHeight};
  ${(props) => props.backgroundColor && css`background: ${props.backgroundColor}`};
`;

const Container = styled.div``;

const Row = styled.div`
  display: flex;
`;

interface GridProps {
  showGrid: boolean;
  padding: string;
}

const Grid = styled.div<GridProps>`
  box-sizing: border-box;
  width: ${(props) => props.padding};
  height: ${(props) => props.padding};
  border: 0 solid ${(props) => props.showGrid ? AssetGridColor : "transparent"};
`;

const TopLeft = styled(Grid)`
  border-right-width: 1px;
  border-bottom-width: 1px;
`;

const TopMiddle = styled(Grid)`
  border-bottom-width: 1px;
  flex-grow: 1;
  width: auto;
`;

const TopRight = styled(Grid)`
  border-bottom-width: 1px;
  border-left-width: 1px;
`;

const MiddleLeft = styled(Grid)`
  border-right-width: 1px;
  height: auto;
`;

const MiddleRight = styled(Grid)`
  border-left-width: 1px;
  height: auto;
`;

const BottomLeft = styled(Grid)`
  border-right-width: 1px;
  border-top-width: 1px;
`;

const BottomMiddle = styled(Grid)`
  border-top-width: 1px;
  flex-grow: 1;
  width: auto;
`;

const BottomRight = styled(Grid)`
  border-top-width: 1px;
  border-left-width: 1px;
`;

interface AssetTileProps {
  src: any;
  tileWidth: string;
  tileHeight: string;
  padding: string;
  showGrid: boolean;
  backgroundColor: string | undefined;
}

const AssetTile: React.FunctionComponent<AssetTileProps> = (props) => (
  <Container>
    <Row>
      <TopLeft showGrid={props.showGrid} padding={props.padding}/>
      <TopMiddle showGrid={props.showGrid} padding={props.padding}/>
      <TopRight showGrid={props.showGrid} padding={props.padding}/>
    </Row>
    <Row>
      <MiddleLeft showGrid={props.showGrid} padding={props.padding}/>
      <ImageContainer
        tileWidth={props.tileWidth}
        tileHeight={props.tileHeight}
        backgroundColor={props.backgroundColor}
      >
        {typeof props.src === "string" ? <Image src={props.src}/> : props.src}
      </ImageContainer>
      <MiddleRight showGrid={props.showGrid} padding={props.padding}/>
    </Row>
    <Row>
      <BottomLeft showGrid={props.showGrid} padding={props.padding}/>
      <BottomMiddle showGrid={props.showGrid} padding={props.padding}/>
      <BottomRight showGrid={props.showGrid} padding={props.padding}/>
    </Row>
  </Container>
);

const AssetTilesLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface AssetTilesProps {
  randomSeed?: number;
  items: React.ReactNode[];
}

export const AssetTiles: React.FunctionComponent<AssetTilesProps> = (props) => (
  <AssetTilesLayout>
    {props.randomSeed !== undefined ? new Random(props.randomSeed).shuffle(props.items) : props.items}
  </AssetTilesLayout>
);

export interface AssetCollectionItem {
  id: string;
  src: any;
}

interface AssetCollectionProps {
  title: string;
  items: AssetCollectionItem[];
  tileWidth: number;
  tileHeight: number;
  backgroundColor?: string;
}

export const AssetCollection: React.FunctionComponent<AssetCollectionProps> = (props) => {
  const [randomSeed, setRandomSeed] = React.useState<undefined | number>(undefined);
  const [showGrid, setShowGrid] = React.useState(true);
  const [size, setSize] = React.useState(1);

  function reset() {
    setRandomSeed(undefined);
    setShowGrid(true);
    setSize(1);
  }

  const tools = [
    (
      <LinkButton key="shuffle" onClick={() => setRandomSeed(new Random(randomSeed || Random.seed()).nextInt())}>
        Shuffle
      </LinkButton>
    ),
    (
      <LinkButton key="showGrid" onClick={() => setShowGrid(!showGrid)}>
        {showGrid ? "Hide Grid" : "Show Grid"}
      </LinkButton>
    ),
    (
      <LinkButton key="smaller" onClick={() => setSize(size * 0.9)}>
        Smaller
      </LinkButton>
    ),
    (
      <LinkButton key="larger" onClick={() => setSize(size / 0.9)}>
        Larger
      </LinkButton>
    ),
    (
      <LinkButton key="reset" onClick={reset}>
        Reset
      </LinkButton>
    ),
  ];

  return (
    <React.Fragment>
      <StyleguideHeader tools={tools}>{props.title}</StyleguideHeader>
      <StyleguideBlock>
        <AssetTiles
          randomSeed={randomSeed}
          items={
            props.items.map((item) => (
              <AssetTile
                key={item.id}
                src={item.src}
                tileWidth={props.tileWidth * size + "rem"}
                tileHeight={props.tileHeight * size + "rem"}
                padding={1 * size + "rem"}
                showGrid={showGrid}
                backgroundColor={props.backgroundColor}
              />
            ))
          }
        />
      </StyleguideBlock>
    </React.Fragment>
  );
};
