import { GraphQL } from "../../services/graphql/generated";
import { List } from "immutable";
import { ContestEntry } from "./contestEntry";
import { SchoolSummary } from "./school";

export interface AmbassadorStatus {
  readonly ambassadorCode: string;
  readonly school: SchoolSummary;
  readonly totalContestEntries: number;
  readonly recentContestEntries: List<ContestEntry>;
}

export namespace AmbassadorStatus {
  export function parse(data: GraphQL.AmbassadorStatusFragment): AmbassadorStatus {
    return {
      ambassadorCode: data.ambassadorCode,
      school: SchoolSummary.parse(data.school),
      totalContestEntries: data.totalContestEntries,
      recentContestEntries: List(data.recentContestEntries).map(ContestEntry.parse)
    };
  }
}
