import * as React from "react";
import {
  useRenderOffboardingEmailPreviewMutation
} from "../../../queries/automatedOffboarding/useRenderOffboardingEmailPreviewMutation";
import {
  RenderOffboardingEmailPreviewViewProps
} from "../../../controllers/organizationPortal/automatedOffboarding/renderOffboardingEmailPreviewController";
import { ControllerProps } from "../../../utils/controller";

interface RenderOffboardingEmailPreviewToolControllerProps
  extends ControllerProps<RenderOffboardingEmailPreviewViewProps> {
  offboardingProjectId: string;
}

const RenderOffboardingEmailPreviewToolController
  : React.FunctionComponent<RenderOffboardingEmailPreviewToolControllerProps> =
  (props) => {
    const [render, status] = useRenderOffboardingEmailPreviewMutation(props.offboardingProjectId);

    return props.render({
      status,
      onPreview: render
    });
  };

export function useRenderOffboardingEmailPreviewController(offboardingProjectId: string) {
  return React.useCallback(
    (controllerProps: ControllerProps<RenderOffboardingEmailPreviewViewProps>) =>
      <RenderOffboardingEmailPreviewToolController offboardingProjectId={offboardingProjectId} {...controllerProps}/>,
    [offboardingProjectId]
  );
}
