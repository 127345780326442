import { Path } from "./path";

export interface WorkItemMutationResult<T extends WorkItemMutationResult.Type> {
  type: T;
}

export namespace WorkItemMutationResult {
  export enum Type {
    Completed = "Completed",
    Progressed = "Progressed",
    DidNotChange = "DidNotChange"
  }

  export interface Completed<Result> extends WorkItemMutationResult<Type.Completed> {
    result: Result;
  }

  export function Completed<Result>(result: Result): Completed<Result> {
    return { type: Type.Completed, result };
  }

  export interface Progressed<Result> extends WorkItemMutationResult<Type.Progressed> {
    result: Result;
    newPath: Path;
  }

  export function Progressed<Result>(result: Result, newPath: Path): Progressed<Result> {
    return { type: Type.Progressed, result, newPath };
  }

  export interface DidNotChange extends WorkItemMutationResult<Type.DidNotChange> {
  }

  export function DidNotChange(): DidNotChange {
    return { type: Type.DidNotChange };
  }

  export type OneOf<Result> =
    Completed<Result> |
    Progressed<Result> |
    DidNotChange;
}
