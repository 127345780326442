import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { Theme } from "../../types/models/theme";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime } from "../../utils/formatting";
import { useSearchThemesQuery } from "../../queries/themes/useSearchThemesQuery";
import { useDeleteThemeMutation } from "../../queries/themes/useDeleteThemeMutation";
import { useUpdateThemeMutation } from "../../queries/themes/useUpdateThemeMutation";
import { useInsertThemeMutation } from "../../queries/themes/useInsertThemeMutation";
import { useGetThemeQuery } from "../../queries/themes/useGetThemeQuery";
import { ThemeForm } from "../../views/screens/organizationPortal/themeForm";
import { ThemeFormData } from "../../types/models/themeFormData";
import { ThemeColorSample } from "../../views/blocks/lookups/themes/themeColorSample";
import { OrganizationSummary } from "../../types/models/organization";
import { CRUDConfig } from "../../components/crud/crudConfig";
import { useElevated } from "../../utils/useAppState";

type Props = Pick<
  CRUDConfig<number, Theme, ThemeFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  >;

export const ThemeAdminPages: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();

  return CrudPages<number, Theme, ThemeFormData>({
    title: "EDU Themes",
    subject: "theme",
    readOnly: !elevated,
    startFrom: props.startFrom,
    layout: props.layout,
    gridColumns: [
      {
        title: "Name",
        render: (theme) => theme.name
      },
      {
        title: "Primary Color",
        render: (theme) => <><ThemeColorSample color={theme.primary}/>{theme.primary}</>
      },
      {
        title: "Created",
        render: (theme) => friendlyDateTime(theme.createdAt)
      },
    ],
    renderForm: (config) => <ThemeForm config={config}/>,

    useRoutes: props.useRoutes,
    getItemId: (theme) => theme.id,
    getItemTitle: (theme) => theme.name,
    clone: (theme) => ({ ...theme, name: "Copy of " + theme.name }),
    compare: (a: Theme, b: Theme) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },

    useGetQuery: useGetThemeQuery,
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: useUpdateThemeMutation,
    useDeleteMutation: useDeleteThemeMutation
  });
};

interface OrganizationThemeAdminPagesProps {
  organization: OrganizationSummary;
}

export const OrganizationThemeAdminPages: React.FunctionComponent<OrganizationThemeAdminPagesProps> = (props) => {
  return (
    <ThemeAdminPages
      startFrom={"list"}
      layout={"compact"}

      useRoutes={() => useRoutes().organizationPortalRoutes.themes(props.organization.alias)}
      useSearchQuery={(term) => useSearchThemesQuery(props.organization.id, term)}
      useInsertMutation={() => useInsertThemeMutation(props.organization.id)}
    />
  );
};
