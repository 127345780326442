import * as React from "react";
import { Panel } from "../../../containers/panel";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { Button } from "../../../widgets/button";
import { OperationStatus } from "../../../../types/operationStatus";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Connections } from "../../../../types/models/connections";

interface Props {
  onDisconnect: () => void;
  status: OperationStatus<Connections>;
}

export const DisconnectTool: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <PanelRow>
      Clicking the button below will disconnect ALL your accounts connected to VaultMe. All your currently
      running migrations (if any) will be stopped.
    </PanelRow>
    <PanelRow>
      <Button color="red" onClick={props.onDisconnect} disabled={props.status.isWorking()}>
        Disconnect Accounts
      </Button>
    </PanelRow>
    <OperationStatusIndicator
      progressMessage={"Disconnecting accounts..."}
      successMessage={"All accounts disconnected."}
      status={props.status}
      indicators={StatusIndicators.PanelRow()}
    />
  </Panel>
);
