import * as React from "react";
import { useSimpleLookupController } from "../../components/simpleLookupController";
import { PricingModel } from "../../types/models/pricingModel";
import { useSearchPricingModelsQuery } from "../../queries/pricingModels/useSearchPricingModelsQuery";

export function usePricingModelLookupController() {
  const getItemId = React.useCallback((item: PricingModel) => item.id, []);

  const comparator = React.useCallback(
    (a: PricingModel, b: PricingModel) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },
    []
  );

  return useSimpleLookupController<number, PricingModel>({
    getItemId,
    comparator,
    listAll: true,
    useSearchQuery: useSearchPricingModelsQuery
  });
}
