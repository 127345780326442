import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { useManagedMutation } from "../useManagedMutation";
import { GraphQL } from "../generated";
import { identity, nullToUndefined } from "../../../utils/misc";
import { Page } from "../../../views/containers/page";
import { Button } from "../../../views/widgets/button";
import { StatusIndicators } from "../../../views/utils/statusIndicators";
import { OperationStatusIndicator } from "../../../views/utils/operationStatusIndicator";
import { SimplePanel } from "../../../views/containers/simplePanel";

type FailWhenZeroMutationHook = [
  (argument: number) => Promise<string>,
  OperationStatus<string>
];

export function useFailWhenZeroMutation(): FailWhenZeroMutationHook {
  const [fire, { status }] = useManagedMutation({
    mutation: GraphQL.useFailWhenZeroMutation,
    extract: (data: GraphQL.FailWhenZeroMutation) => nullToUndefined(data.failWhenZero),
    complete: identity
  });

  function fireWith(argument: number): Promise<string> {
    return fire({
      variables: { argument },
      retry: () => fireWith(argument === 0 ? 1 : argument),
    });
  }

  return [fireWith, status];
}

export const MutationPromiseFreezingAfterRetry: React.FunctionComponent = () => {
  const [call, status] = useFailWhenZeroMutation();

  return (
    <Page title={"MutationPromiseFreezingAfterRetry"}>
      <OperationStatusIndicator
        status={status}
        successMessage={"Success!"}
        indicators={StatusIndicators.SimplePanel({ keepSuccessIndicator: true })}
      />
      <SimplePanel>
        <Button
          onClick={() => call(0).then(() => alert("Success!"))}
        >
          Call
        </Button>
      </SimplePanel>
    </Page>
  );
};
