import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { TaskHistory } from "../../types/models/taskHistory";

export function useTaskHistory(
  jobId: string,
  taskId: string,
  includeMinorUpdates: boolean
): ManagedQueryHook<GraphQL.GetTaskHistoryQueryVariables, TaskHistory> {
  return useManagedQuery({
    query: GraphQL.useGetTaskHistoryQuery,
    deps: null,
    prepare: () => ({ jobId, taskId, includeMinorUpdates }),
    extract: (data: GraphQL.GetTaskHistoryQuery) => data.getTaskHistory,
    // Do we really need memoDeps? :-\
    // memoDeps: (extract) => [
    //   extract,
    //   extract && extract.taskHistory.length,
    //   extract && extract.taskIssues.length
    // ],
    complete: TaskHistory.fromGraphQL,
  });
}
