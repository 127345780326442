import * as React from "react";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Panel } from "../../containers/panel";
import { styled } from "../../../app/theme";
import { Button } from "../../widgets/button";
import { PanelRow } from "../../containers/rows/panelRow";

const Description = styled.div`
  color: ${(props) => props.theme.colors.red};
  
  ul {
    margin: 1rem 0 0 1rem;
    
    li:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
`;

interface Props {
  abortStatus: OperationStatus<string>;
  onAbort: () => void;
}

export const AbortJobView: React.FunctionComponent<Props> = (props) => {
  return (
    <Panel>
      {
        props.abortStatus.isSuccess()
          ? (
            <PanelRow>Result: {props.abortStatus.result}</PanelRow>
          )
          : (
            <OperationStatusIndicator
              progressMessage={"Aborting..."}
              status={props.abortStatus}
              indicators={StatusIndicators.PanelRow()}
            />
          )
      }
      <PanelRow>
        <Description>
          All currently running tasks will be aborted (as quickly as possible, but safely) and the job will be stopped.
          Use this function to stop the job from doing anything NEW. Please note that:
          <ul>
            <li>
              After successful abort the job will end up in clean and nice "Archived" state. It then can be resumed
              using the "Schedule Transition" button.
            </li>
            <li>
              This function will make no effect at all if the job is not running.
            </li>
            <li>
              This function will call abort() hooks defined for the currently running tasks. Errors will be displayed
              (if any), but calls won't be automatically retried. In case of errors you can attempt to use the "Abort"
              button again, or perform cleanup steps manually.
            </li>
            <li>
              If any of the currently running tasks are frozen (meaning that they are running code that can't finish
              for some reason), the job will never be aborted. In this case, the app will have to be restarted manually.
              After restart, the job will not be resumed.
            </li>
          </ul>
        </Description>
      </PanelRow>
      <PanelRow>
        <Button color="red" disabled={props.abortStatus.isWorking()} onClick={props.onAbort}>
          Abort Job
        </Button>
      </PanelRow>
    </Panel>
  );
};
