import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export namespace GraphQL {
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Instant: string,
  /** The `BigDecimal` scalar type represents signed fractional values with arbitrary precision. */
  BigDecimal: any,
  /** 
 * The `Long` scalar type represents non-fractional signed whole numeric values.
   * Long can represent values between -(2^63) and 2^63 - 1.
 **/
  Long: any,
  SensitiveString: any,
};

export enum AccessKeyCheck {
  Skip = 'Skip',
  Quick = 'Quick',
  Full = 'Full'
}

export type AccessKeyContainer = {
   __typename?: 'AccessKeyContainer',
  id: Scalars['String'],
  revision: Scalars['String'],
  roles: Array<Scalars['String']>,
  body: Scalars['String'],
};

export enum AccessKeyReplacementReason {
  ExpiredAccessKey = 'ExpiredAccessKey',
  InvalidAccessKey = 'InvalidAccessKey',
  SignedIn = 'SignedIn'
}

export enum AccessKeyStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Revoked = 'Revoked',
  Missing = 'Missing'
}

export enum AccessKeyValidationCapabilities {
  CannotValidatePermissions = 'CannotValidatePermissions',
  CanValidateRequestedPermissions = 'CanValidateRequestedPermissions',
  CanListGrantedPermissions = 'CanListGrantedPermissions'
}

export type AccessList = {
   __typename?: 'AccessList',
  id: Scalars['Int'],
  organizationId: Scalars['Int'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  isEnabled: Scalars['Boolean'],
  isProgramAllowlist: Scalars['Boolean'],
  programId?: Maybe<Scalars['String']>,
  blockingMessage?: Maybe<Scalars['String']>,
  googleGroupId?: Maybe<Scalars['String']>,
  currentUpdateId?: Maybe<Scalars['Int']>,
  lastUpdateStartedAt?: Maybe<Scalars['Instant']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  program?: Maybe<Program>,
};

export type AccessListFormData = {
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  isEnabled: Scalars['Boolean'],
  isProgramAllowlist: Scalars['Boolean'],
  programId?: Maybe<Scalars['String']>,
  blockingMessage?: Maybe<Scalars['String']>,
  googleGroupId?: Maybe<Scalars['String']>,
};

export type AccessListUpdate = {
   __typename?: 'AccessListUpdate',
  id: Scalars['Int'],
  accessListId: Scalars['Int'],
  status: AccessListUpdateStatus,
  addedEntries: Scalars['Int'],
  removedEntries: Scalars['Int'],
  skippedEntries: Scalars['Int'],
  errors: Scalars['Int'],
  errorMessage?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  completedAt?: Maybe<Scalars['Instant']>,
};

export enum AccessListUpdateStatus {
  Running = 'Running',
  Failure = 'Failure',
  Started = 'Started',
  Created = 'Created',
  Success = 'Success'
}

export enum AccountAction {
  Delete = 'Delete',
  ReduceStorage = 'ReduceStorage'
}

export enum ActivityTimeUnit {
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}

export enum AdminConnectionVerificationResult {
  Success = 'Success',
  NotAnAdmin = 'NotAnAdmin',
  FailedToConnect = 'FailedToConnect'
}

export type AlmostResolvedBlueprintState = BlueprintState & {
   __typename?: 'AlmostResolvedBlueprintState',
  output?: Maybe<BlueprintStateOutput>,
  progress?: Maybe<Scalars['Float']>,
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type AmbassadorCodeDiscount = {
   __typename?: 'AmbassadorCodeDiscount',
  code: Scalars['String'],
  percentage: Scalars['BigDecimal'],
  amount: Scalars['BigDecimal'],
};

export type AmbassadorsProgramContact = {
  firstName?: Maybe<Scalars['String']>,
  emailAddress: Scalars['String'],
};

export enum AmbassadorsProgramEmailAddressValidationResult {
  Valid = 'Valid',
  InvalidDomain = 'InvalidDomain',
  ContactNotFound = 'ContactNotFound',
  EmailAddressBelongsToUser = 'EmailAddressBelongsToUser'
}

export type AmbassadorStatus = {
   __typename?: 'AmbassadorStatus',
  ambassadorCode: Scalars['String'],
  school: School,
  totalContestEntries: Scalars['Int'],
  recentContestEntries: Array<ContestEntry>,
};

export type AmbassadorStatusDiscount = {
   __typename?: 'AmbassadorStatusDiscount',
  amount: Scalars['BigDecimal'],
  isReturningAmbassadorDiscount: Scalars['Boolean'],
  returningAmbassadorPercentage: Scalars['BigDecimal'],
};

export type AppBootstrapConfig = {
   __typename?: 'AppBootstrapConfig',
  development: Scalars['Boolean'],
  production: Scalars['Boolean'],
  cloudServices: Array<CloudServiceCategory>,
  authProviders: Array<AuthProvider>,
  ambassadorsProgram: AppBootstrapConfig_AmbassadorsProgram,
  microsoftAppId: Scalars['String'],
  microsoftSignInButtonCss: Scalars['String'],
  microsoftSignInButtonIcon: Scalars['String'],
  microsoftOAuthCallbackPath: Scalars['String'],
  googleMerchantId?: Maybe<Scalars['String']>,
  enableVenmo: Scalars['Boolean'],
  program?: Maybe<Program>,
  configuration?: Maybe<Configuration>,
  theme?: Maybe<Theme>,
  referralCode?: Maybe<ReferralCode>,
};

export type AppBootstrapConfig_AmbassadorsProgram = {
   __typename?: 'AppBootstrapConfig_AmbassadorsProgram',
  link: Scalars['String'],
  signUpDiscount: Scalars['BigDecimal'],
  codeDiscount: Scalars['BigDecimal'],
  minNumberOfContacts: Scalars['Int'],
  maxNumberOfContacts: Scalars['Int'],
  contestEntryExpirationMonths: Scalars['Int'],
};

export type AppliedEmailDeliverySchedule = {
   __typename?: 'AppliedEmailDeliverySchedule',
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  timeUnit: EmailDeliveryScheduleTimeUnit,
  rounds: Array<AppliedEmailDeliverySchedule_Round>,
};

export type AppliedEmailDeliverySchedule_Round = {
   __typename?: 'AppliedEmailDeliverySchedule_Round',
  timeUnits: Scalars['Int'],
  scheduledFor: Scalars['Instant'],
  template: Scalars['Int'],
};

export type AreaComp = Component & {
   __typename?: 'AreaComp',
  props: AreaCompProps,
  hubs: AreaCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type AreaCompHubs = ComponentHubs & {
   __typename?: 'AreaCompHubs',
  totalItems: MultipleRelationshipsHub,
  totalBytes: MultipleRelationshipsHub,
  maxPathLength: MultipleRelationshipsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type AreaCompOutput = {
   __typename?: 'AreaCompOutput',
  totalBytes?: Maybe<CollectableInteger>,
  totalItems?: Maybe<CollectableItemBreakdown>,
  maxPathLength?: Maybe<CollectableInteger>,
  enabled: Scalars['Boolean'],
  itemTypes: Array<ItemType>,
};

export type AreaCompProps = {
   __typename?: 'AreaCompProps',
  internalId: Scalars['String'],
  appTitle?: Maybe<Scalars['String']>,
  mainSubject: Scalars['String'],
  title: Scalars['String'],
  description: Scalars['String'],
  order: Scalars['Int'],
  itemTypes: Array<ItemType>,
  itemTypeAliases: Array<ItemTypeAlias>,
};

export type ArrayHub = Hub & {
   __typename?: 'ArrayHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type Association = Relationship & {
   __typename?: 'Association',
  props: AssociationProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type AssociationProps = {
   __typename?: 'AssociationProps',
  title?: Maybe<Scalars['String']>,
};

export type AssociationsHub = Hub & {
   __typename?: 'AssociationsHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export enum AuthorizationAction {
  SubmitForSettlement = 'SubmitForSettlement',
  Void = 'Void',
  Keep = 'Keep'
}

export type AuthorizationStatus = {
   __typename?: 'AuthorizationStatus',
  isPending: Scalars['Boolean'],
  transactionStatus: Scalars['String'],
};

export type AuthProvider = {
   __typename?: 'AuthProvider',
  id: Scalars['String'],
  roles: Array<Scalars['String']>,
  accessKeyValidationCapabilities: AccessKeyValidationCapabilities,
};

export type AvailableJobStatuses = {
   __typename?: 'AvailableJobStatuses',
  allSupported: Array<JobStatusDescription>,
  proposed: Array<JobStatusDescription>,
};

export type Batch = {
   __typename?: 'Batch',
  id: Scalars['String'],
  userId: Scalars['String'],
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  name: Scalars['String'],
  notificationsEmail?: Maybe<Scalars['String']>,
  status: MigrationStatus,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  startedAt?: Maybe<Scalars['Instant']>,
  completedAt?: Maybe<Scalars['Instant']>,
  batchOrder: BatchOrder,
  jobId: Scalars['String'],
  migrations: Array<Migration>,
};

export type BatchBinding = {
   __typename?: 'BatchBinding',
  batchId: Scalars['String'],
  index: Scalars['Int'],
};

export type BatchEvent = {
   __typename?: 'BatchEvent',
  eventType: Scalars['String'],
  batches: Array<Batch>,
};

export type BatchItemSpec = {
  blueprintInputs: Array<BlueprintInputsEntryInput>,
};

export type BatchOrder = {
   __typename?: 'BatchOrder',
  pricingModelName: Scalars['String'],
  pricingModelType: Scalars['String'],
  totalAccounts: Scalars['Long'],
  totalBytes: Scalars['Long'],
  totalItems: Scalars['Long'],
  accountsFee: Scalars['BigDecimal'],
  bytesFee: Scalars['BigDecimal'],
  itemsFee: Scalars['BigDecimal'],
  priceBeforeDiscounts: Scalars['BigDecimal'],
  couponCodeDiscount?: Maybe<CouponCodeDiscount>,
  referralCodeDiscount?: Maybe<ReferralCodeDiscount>,
  amountToBePaid: Scalars['BigDecimal'],
  paidAmount?: Maybe<Scalars['BigDecimal']>,
  priceBreakdown: Scalars['String'],
  transactionId?: Maybe<Scalars['String']>,
  pricingModelVersion: Scalars['String'],
  pricingModelJson: Scalars['String'],
  revenue: Scalars['BigDecimal'],
  revenueShare?: Maybe<RevenueShare>,
  braintreeTransactionDetailsUrl?: Maybe<Scalars['String']>,
};


export type BlockedBlueprintState = BlueprintState & {
   __typename?: 'BlockedBlueprintState',
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type BlueprintError = {
   __typename?: 'BlueprintError',
  type: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

export type BlueprintFactsSpec = {
  factIds: Array<Scalars['String']>,
  blueprintInputs: Array<BlueprintInputsEntryInput>,
};

export type BlueprintInputsEntry = {
   __typename?: 'BlueprintInputsEntry',
  key: Scalars['String'],
  value: Scalars['String'],
};

export type BlueprintInputsEntryInput = {
  key: Scalars['String'],
  value: Scalars['String'],
};

export type BlueprintState = {
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type BlueprintStateOutput = AreaCompOutput | ChildEndpointCompOutput | ConnectionCompOutput | FactCompLikeOutput | PhaseCompOutput | MigrationProgressCompOutput | ReducerCompOutput | RestrictionCompOutput | RoleCompOutput | RootEndpointCompOutput | SinkCompOutput | StorageRestrictionCompOutput | TimeEstimateCompOutput | VariableCompOutput | UnregisteredOutputType;

export type CheckboxesState = {
  checkbox1Label?: Maybe<Scalars['String']>,
  checkbox1Checked: Scalars['Boolean'],
  checkbox2Label?: Maybe<Scalars['String']>,
  checkbox2Checked: Scalars['Boolean'],
  checkbox3Label?: Maybe<Scalars['String']>,
  checkbox3Checked: Scalars['Boolean'],
};

export type ChildEndpointComp = Component & {
   __typename?: 'ChildEndpointComp',
  props: EndpointCompProps,
  hubs: ChildEndpointCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type ChildEndpointCompHubs = ComponentHubs & {
   __typename?: 'ChildEndpointCompHubs',
  vars: VarsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  accessKey: ExactlyOneRelationshipHub,
  rootEndpointRef: ExactlyOneRelationshipHub,
};

export type ChildEndpointCompOutput = {
   __typename?: 'ChildEndpointCompOutput',
  endpointDefJson: Scalars['String'],
  endpointRef: Scalars['String'],
};

export type CloudService = {
   __typename?: 'CloudService',
  id: Scalars['String'],
  name: Scalars['String'],
  logo: Scalars['String'],
  icon: Scalars['String'],
  color: Scalars['String'],
  authProviderId: Scalars['String'],
  accessKeyParams: Scalars['String'],
};

export type CloudServiceCategory = {
   __typename?: 'CloudServiceCategory',
  title: Scalars['String'],
  cloudServices: Array<CloudService>,
};

export type Collectable = {
  type: Scalars['String'],
  isComplete: Scalars['Boolean'],
  progress?: Maybe<Scalars['Float']>,
};

export type CollectableBoolean = Collectable & {
   __typename?: 'CollectableBoolean',
  partialValue?: Maybe<Scalars['Boolean']>,
  completeValue?: Maybe<Scalars['Boolean']>,
  type: Scalars['String'],
  isComplete: Scalars['Boolean'],
  progress?: Maybe<Scalars['Float']>,
};

export type CollectableInteger = Collectable & {
   __typename?: 'CollectableInteger',
  partialValue?: Maybe<Scalars['Long']>,
  completeValue?: Maybe<Scalars['Long']>,
  type: Scalars['String'],
  isComplete: Scalars['Boolean'],
  progress?: Maybe<Scalars['Float']>,
};

export type CollectableItemBreakdown = Collectable & {
   __typename?: 'CollectableItemBreakdown',
  partialValue?: Maybe<ItemBreakdown>,
  completeValue?: Maybe<ItemBreakdown>,
  type: Scalars['String'],
  isComplete: Scalars['Boolean'],
  progress?: Maybe<Scalars['Float']>,
};

export type CollectedFactState = {
   __typename?: 'CollectedFactState',
  value: Collectable,
};

export type CollectingFactState = {
   __typename?: 'CollectingFactState',
  value: Collectable,
};

export type Component = {
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type ComponentColorSchema = {
   __typename?: 'ComponentColorSchema',
  background?: Maybe<Scalars['String']>,
  border: Scalars['String'],
};

export type ComponentHubs = {
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type ComponentResolved = Relationship & {
   __typename?: 'ComponentResolved',
  props: ComponentResolvedProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type ComponentResolvedProps = {
   __typename?: 'ComponentResolvedProps',
  promotePartial: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  default?: Maybe<Scalars['Boolean']>,
};

export type ComponentSettings = {
   __typename?: 'ComponentSettings',
  sizeMultiplier: Scalars['Float'],
  color?: Maybe<ComponentColorSchema>,
  icon?: Maybe<Scalars['String']>,
  anchor?: Maybe<Scalars['String']>,
};

export type Configuration = {
   __typename?: 'Configuration',
  id: Scalars['Int'],
  alias: Scalars['String'],
  name: Scalars['String'],
  themeId?: Maybe<Scalars['Int']>,
  headerSuffix?: Maybe<Scalars['String']>,
  mainLogoId?: Maybe<Scalars['String']>,
  escapeLinkText?: Maybe<Scalars['String']>,
  introBannerId?: Maybe<Scalars['String']>,
  introTitle?: Maybe<Scalars['String']>,
  introContent?: Maybe<Scalars['String']>,
  pricingPageBannerId?: Maybe<Scalars['String']>,
  pricingPageTitle?: Maybe<Scalars['String']>,
  pricingPageContent?: Maybe<Scalars['String']>,
  sourceCloudServiceReference?: Maybe<Scalars['String']>,
  destinationCloudServiceReference?: Maybe<Scalars['String']>,
  enableFeedback: Scalars['Boolean'],
  feedbackQuestion?: Maybe<Scalars['String']>,
  checkbox1Label?: Maybe<Scalars['String']>,
  checkbox1Required: Scalars['Boolean'],
  checkbox2Label?: Maybe<Scalars['String']>,
  checkbox2Required: Scalars['Boolean'],
  checkbox3Label?: Maybe<Scalars['String']>,
  checkbox3Required: Scalars['Boolean'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
  mainLogo?: Maybe<Upload>,
  introBanner?: Maybe<Upload>,
  pricingPageBanner?: Maybe<Upload>,
  cloudServices: Array<ConfigurationCloudService>,
};

export type ConfigurationCloudService = {
   __typename?: 'ConfigurationCloudService',
  id: Scalars['Int'],
  configurationId: Scalars['Int'],
  isSource: Scalars['Boolean'],
  cloudServiceId: Scalars['String'],
  index: Scalars['Int'],
  reference?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  excludedApps?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  logo?: Maybe<Upload>,
};

export type ConfigurationCloudServiceFormData = {
  isSource: Scalars['Boolean'],
  cloudServiceId: Scalars['String'],
  index: Scalars['Int'],
  reference?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  excludedApps?: Maybe<Scalars['String']>,
};

export type ConfigurationFormData = {
  alias: Scalars['String'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  themeId?: Maybe<Scalars['Int']>,
  headerSuffix?: Maybe<Scalars['String']>,
  mainLogoId?: Maybe<Scalars['String']>,
  escapeLinkText?: Maybe<Scalars['String']>,
  carousel: ConfigurationFormData_Carousel,
  sourceCloudServiceReference?: Maybe<Scalars['String']>,
  destinationCloudServiceReference?: Maybe<Scalars['String']>,
  enableFeedback: Scalars['Boolean'],
  feedbackQuestion?: Maybe<Scalars['String']>,
  checkbox1Label?: Maybe<Scalars['String']>,
  checkbox1Required: Scalars['Boolean'],
  checkbox2Label?: Maybe<Scalars['String']>,
  checkbox2Required: Scalars['Boolean'],
  checkbox3Label?: Maybe<Scalars['String']>,
  checkbox3Required: Scalars['Boolean'],
  cloudServices?: Maybe<Array<ConfigurationCloudServiceFormData>>,
};

export type ConfigurationFormData_Carousel = {
  introBannerId?: Maybe<Scalars['String']>,
  introTitle?: Maybe<Scalars['String']>,
  introContent?: Maybe<Scalars['String']>,
  pricingPageBannerId?: Maybe<Scalars['String']>,
  pricingPageTitle?: Maybe<Scalars['String']>,
  pricingPageContent?: Maybe<Scalars['String']>,
};

export type Connection = {
   __typename?: 'Connection',
  id: Scalars['String'],
  userId: Scalars['String'],
  cloudServiceId: Scalars['String'],
  accessKeyProvider: Scalars['String'],
  accessKeyStatus: AccessKeyStatus,
  description?: Maybe<Scalars['String']>,
  personalInfo: PersonalInfo,
  metadata?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  eligibleForSignIn: Scalars['Boolean'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  validatedAt: Scalars['Instant'],
  signedInAt: Scalars['Instant'],
  revealedAt?: Maybe<Scalars['Instant']>,
  revokedAt?: Maybe<Scalars['Instant']>,
  roles?: Maybe<Array<Scalars['String']>>,
  class: ConnectionClass,
};

export enum ConnectionClass {
  Educational = 'Educational',
  Business = 'Business',
  Consumer = 'Consumer'
}

export type ConnectionComp = Component & {
   __typename?: 'ConnectionComp',
  props: ConnectionCompProps,
  hubs: StandardComponentHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type ConnectionCompBranding = {
   __typename?: 'ConnectionCompBranding',
  name: Scalars['String'],
  icon: Scalars['String'],
  color: Scalars['String'],
};

export type ConnectionCompOutput = {
   __typename?: 'ConnectionCompOutput',
  connectionId: Scalars['String'],
  emailAddress?: Maybe<Scalars['String']>,
  roles: Array<Scalars['String']>,
};

export type ConnectionCompProps = {
   __typename?: 'ConnectionCompProps',
  purpose: Scalars['String'],
  inputId: Scalars['String'],
  branding: ConnectionCompBranding,
  authProviderId: Scalars['String'],
};

export type ConnectResult = {
   __typename?: 'ConnectResult',
  user: User,
  elevated: Scalars['Boolean'],
  idleTimeout: Scalars['Long'],
  connection: Connection,
  erasedFacts: Array<FactPlaceholder>,
};

export type ContestEntry = {
   __typename?: 'ContestEntry',
  event: ContestEvent,
  count: Scalars['Int'],
  createdAt: Scalars['Instant'],
};

export enum ContestEvent {
  SignedUp = 'SignedUp',
  StartedMigration = 'StartedMigration',
  FriendSignedIn = 'FriendSignedIn',
  FriendStartedMigration = 'FriendStartedMigration'
}

export type CouponCode = {
   __typename?: 'CouponCode',
  id: Scalars['String'],
  enabled: Scalars['Boolean'],
  discount: Scalars['BigDecimal'],
  isPercentage: Scalars['Boolean'],
  remainingUses?: Maybe<Scalars['Int']>,
  lastUsedFor?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
};

export type CouponCodeDiscount = {
   __typename?: 'CouponCodeDiscount',
  code: Scalars['String'],
  value: Scalars['BigDecimal'],
  isPercentage: Scalars['Boolean'],
  amount: Scalars['BigDecimal'],
};

export type CouponCodeFormData = {
  id: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  discount: Scalars['BigDecimal'],
  isPercentage: Scalars['Boolean'],
  remainingUses?: Maybe<Scalars['Int']>,
};

export type CouponCodeSummary = {
   __typename?: 'CouponCodeSummary',
  id: Scalars['String'],
  discount: Scalars['Float'],
  isPercentage: Scalars['Boolean'],
  remainingUses?: Maybe<Scalars['Int']>,
};

export type DeleteAccessListEntriesResult = {
   __typename?: 'DeleteAccessListEntriesResult',
  deletedEntryCount: Scalars['Int'],
  skippedEmailAddresses: Array<Scalars['String']>,
};

export type DeleteOffboardingProjectEntriesResult = {
   __typename?: 'DeleteOffboardingProjectEntriesResult',
  deletedEntryCount: Scalars['Int'],
  skippedEmailAddresses: Array<Scalars['String']>,
};

export type Discounts = {
  couponCode?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>,
  ambassadorCode?: Maybe<Scalars['String']>,
};

export enum DiscountType {
  BasePriceOnly = 'BasePriceOnly',
  BasePriceAndDataFees = 'BasePriceAndDataFees'
}

export type EmailAddressWhitelistingForProgram = {
   __typename?: 'EmailAddressWhitelistingForProgram',
  emailAddress: Scalars['String'],
  program?: Maybe<Program>,
};

export enum EmailDeliveryErrorType {
  InvalidAccessKey = 'InvalidAccessKey',
  ExhaustedQuota = 'ExhaustedQuota',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended',
  UnexpectedError = 'UnexpectedError'
}

export type EmailDeliverySchedule = {
   __typename?: 'EmailDeliverySchedule',
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  isTest: Scalars['Boolean'],
};

export enum EmailDeliveryScheduleTimeUnit {
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day'
}

export type EmailPreview = {
   __typename?: 'EmailPreview',
  fromName: Scalars['String'],
  fromEmailAddress: Scalars['String'],
  to: Scalars['String'],
  subject: Scalars['String'],
  body: Scalars['String'],
  from: Scalars['String'],
};

export type EndpointCompProps = {
   __typename?: 'EndpointCompProps',
  title: Scalars['String'],
};

export type ErrorBlueprintState = BlueprintState & {
   __typename?: 'ErrorBlueprintState',
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type Estimate = {
   __typename?: 'Estimate',
  value: Scalars['Long'],
  trainingSamples?: Maybe<Scalars['Int']>,
  goodnessOfFit: Scalars['String'],
  fact?: Maybe<Fact>,
};

export type EstimationResult = {
   __typename?: 'EstimationResult',
  title: Scalars['String'],
  connector: Scalars['String'],
  serviceProvider: Scalars['String'],
  estimate?: Maybe<Estimate>,
  params: Scalars['String'],
};

export type ExactlyOneRelationshipHub = Hub & {
   __typename?: 'ExactlyOneRelationshipHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type ExternalHelpArticle = {
   __typename?: 'ExternalHelpArticle',
  content: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
};

export type ExternalPayment = {
  amount: Scalars['BigDecimal'],
  note?: Maybe<Scalars['String']>,
};

export type Fact = FactOrPlaceholder & {
   __typename?: 'Fact',
  id: Scalars['String'],
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String'],
  state: FactState,
};

export type FactComp = Component & {
   __typename?: 'FactComp',
  props: FactCompProps,
  hubs: FactCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type FactCompHubs = ComponentHubs & {
   __typename?: 'FactCompHubs',
  phase: AssociationsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type FactCompLikeOutput = {
   __typename?: 'FactCompLikeOutput',
  collectable: Collectable,
  valueJson?: Maybe<Scalars['String']>,
};

export type FactCompProps = {
   __typename?: 'FactCompProps',
  name: Scalars['String'],
  factRef: FactRef,
};

export type FactEvent = {
   __typename?: 'FactEvent',
  eventType: Scalars['String'],
  facts: Array<Fact>,
};

export type FactOrPlaceholder = {
  id: Scalars['String'],
};

export type FactPlaceholder = FactOrPlaceholder & {
   __typename?: 'FactPlaceholder',
  id: Scalars['String'],
};

export type FactRef = {
   __typename?: 'FactRef',
  type: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String'],
};

export type FactState = MissingFactState | FailedFactState | PreparingFactState | CollectingFactState | CollectedFactState;

export enum FactSubjectType {
  Connection = 'Connection',
  Migration = 'Migration',
  Iteration = 'Iteration'
}

export type FailedFactState = {
   __typename?: 'FailedFactState',
  errorType: Scalars['String'],
  errorMessage?: Maybe<Scalars['String']>,
};

export type GetBlueprintFactsResult = {
   __typename?: 'GetBlueprintFactsResult',
  facts: Array<FactOrPlaceholder>,
  notCollectedFactsIds: Array<Scalars['String']>,
  scanJobIds: Array<Scalars['String']>,
};

export type GetSignInFormConfigResponse = {
   __typename?: 'GetSignInFormConfigResponse',
  cloudServiceId: Scalars['String'],
  configurations: Array<NamedSignInFormConfig>,
  customizations: Array<NamedSignInFormCustomization>,
};

export type GetSourceConnectionPromotionsResult = {
   __typename?: 'GetSourceConnectionPromotionsResult',
  currentProgram?: Maybe<Program>,
  programEligibility: Array<ProgramEligibility>,
  hasPreviousMigrationsFromSource: Scalars['Boolean'],
  school?: Maybe<School>,
};

export type GoogleGroup = {
   __typename?: 'GoogleGroup',
  internalId: Scalars['String'],
  friendlyId: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type GoogleSignInFormConfig = SignInFormConfig & {
   __typename?: 'GoogleSignInFormConfig',
  providerId: Scalars['String'],
  url: Scalars['String'],
  callbackUrl: Scalars['String'],
  allowServiceAccountSignIn: Scalars['Boolean'],
};

export type Hub = {
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type ImapConnectionDefaults = {
   __typename?: 'ImapConnectionDefaults',
  host: Scalars['String'],
  port: Scalars['Int'],
  customizable: Scalars['Boolean'],
};

export type ImapCredentialsDefaults = {
   __typename?: 'ImapCredentialsDefaults',
  login: Scalars['String'],
  customizable: Scalars['Boolean'],
};

export type ImapSignInFormConfig = SignInFormConfig & {
   __typename?: 'ImapSignInFormConfig',
  providerId: Scalars['String'],
  credentialsDefaults?: Maybe<ImapCredentialsDefaults>,
  connectionDefaults?: Maybe<ImapConnectionDefaults>,
  existingAccessKeyId?: Maybe<Scalars['String']>,
};

export type ImapSignInFormCustomization = SignInFormCustomization & {
   __typename?: 'ImapSignInFormCustomization',
  vendorName?: Maybe<Scalars['String']>,
  serviceName?: Maybe<Scalars['String']>,
  introHtml?: Maybe<Scalars['String']>,
  introHelpArticle?: Maybe<ExternalHelpArticle>,
  inlineHtml?: Maybe<Scalars['String']>,
  inlineHelpArticle?: Maybe<ExternalHelpArticle>,
  footerHtml?: Maybe<Scalars['String']>,
  footerHelpArticle?: Maybe<ExternalHelpArticle>,
  advancedSettingsHelpArticle?: Maybe<ExternalHelpArticle>,
  howToSwitchAccountHelpArticle?: Maybe<ExternalHelpArticle>,
};

export type ImapSignInParams = {
  login: Scalars['String'],
  password: Scalars['SensitiveString'],
  host: Scalars['String'],
  port: Scalars['Int'],
  existingAccessKeyId?: Maybe<Scalars['String']>,
};


export enum InternalJobStatus {
  Completed = 'Completed',
  Archived = 'Archived',
  Created = 'Created',
  Running = 'Running'
}

export type ItemBreakdown = {
   __typename?: 'ItemBreakdown',
  entries: Array<ItemBreakdownEntry>,
};

export type ItemBreakdownEntry = {
   __typename?: 'ItemBreakdownEntry',
  itemType: ItemType,
  taggedEntries: Array<ItemBreakdownTaggedEntry>,
};

export type ItemBreakdownTaggedEntry = {
   __typename?: 'ItemBreakdownTaggedEntry',
  tags: Array<Scalars['String']>,
  count: Scalars['Long'],
};

export enum ItemType {
  Event = 'Event',
  Folder = 'Folder',
  Relationship = 'Relationship',
  Calendar = 'Calendar',
  Contact = 'Contact',
  Email = 'Email',
  File = 'File'
}

export type ItemTypeAlias = {
   __typename?: 'ItemTypeAlias',
  alias: Scalars['String'],
  itemTypes: Array<ItemType>,
};

export type Iteration = {
   __typename?: 'Iteration',
  migrationId: Scalars['String'],
  status: IterationStatus,
  stats: MigrationStats,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  startedAt?: Maybe<Scalars['Instant']>,
  completedAt?: Maybe<Scalars['Instant']>,
  index: Scalars['Int'],
  facts: Array<FactOrPlaceholder>,
};

export enum IterationStatus {
  Scheduled = 'Scheduled',
  Aborted = 'Aborted',
  Completed = 'Completed',
  Running = 'Running',
  Starting = 'Starting'
}

export type JobHistory = {
   __typename?: 'JobHistory',
  jobId: Scalars['String'],
  job: JobHistoryRecord,
  jobHistory: Array<JobHistoryRecord>,
  tasks: Array<TaskSummaryRecord>,
  taskIssues: Array<TaskIssue>,
};

export type JobHistoryRecord = {
   __typename?: 'JobHistoryRecord',
  id: Scalars['String'],
  type: Scalars['String'],
  internalStatus: InternalJobStatus,
  currentStatus?: Maybe<Scalars['String']>,
  desiredStatus: Scalars['String'],
  nextStatus?: Maybe<Scalars['String']>,
  transition: Scalars['Int'],
  propsVersion: Scalars['String'],
  propsJson: Scalars['String'],
  stateVersion: Scalars['String'],
  stateJson: Scalars['String'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  completedAt?: Maybe<Scalars['Instant']>,
  archivedAt?: Maybe<Scalars['Instant']>,
};

export type JobStatusDescription = {
   __typename?: 'JobStatusDescription',
  status: Scalars['String'],
  description: Scalars['String'],
};

export enum JobStatusTransitionSchedulingResult {
  Scheduled = 'Scheduled',
  AlreadyTransitioningToDesiredState = 'AlreadyTransitioningToDesiredState',
  AlreadyInDesiredState = 'AlreadyInDesiredState',
  Rejected = 'Rejected',
  Started = 'Started'
}

export type LaunchMigrationResult = {
   __typename?: 'LaunchMigrationResult',
  migration: Migration,
  user: User,
};


export type ManualReviewItem = {
   __typename?: 'ManualReviewItem',
  phase: Scalars['String'],
  itemType: Scalars['String'],
  count: Scalars['Long'],
};

export type Migration = {
   __typename?: 'Migration',
  id: Scalars['String'],
  userId: Scalars['String'],
  orderId?: Maybe<Scalars['String']>,
  batch?: Maybe<BatchBinding>,
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  notificationsEmail?: Maybe<Scalars['String']>,
  totalBytesEstimate: Scalars['Long'],
  totalItemsEstimate: Scalars['Long'],
  timeEstimate?: Maybe<Scalars['Long']>,
  status: MigrationStatus,
  autoResumeAt?: Maybe<Scalars['Instant']>,
  isAutoResumingEnabled: Scalars['Boolean'],
  cumulativeStats: MigrationStats,
  iterationStats: MigrationStats,
  optimisticRemainingTime?: Maybe<Scalars['Long']>,
  longestTrackProgress?: Maybe<Scalars['Float']>,
  lastIssue?: Maybe<Scalars['String']>,
  itemsForManualReview?: Maybe<Array<ManualReviewItem>>,
  totalManualReviews: Scalars['Int'],
  reportUrl?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  startedAt?: Maybe<Scalars['Instant']>,
  completedAt?: Maybe<Scalars['Instant']>,
  iterationStartedAt?: Maybe<Scalars['Instant']>,
  iterationCompletedAt?: Maybe<Scalars['Instant']>,
  blueprintInputs: Array<BlueprintInputsEntry>,
  itemTypes: Array<ItemType>,
  supervisedBy?: Maybe<Scalars['String']>,
  iteration: Scalars['Int'],
  orderSummary?: Maybe<OrderSummary>,
  order?: Maybe<Order>,
  sourceConnection: Connection,
  destinationConnection: Connection,
  facts: Array<FactOrPlaceholder>,
  issues: MigrationIssueList,
  activeJobIssues: Array<TaskIssue>,
  notes: MigrationNoteList,
  activeNotes: MigrationNoteList,
  history: Array<MigrationHistoryItem>,
  jobId: Scalars['String'],
  workflowUrl: Scalars['String'],
  value: Scalars['BigDecimal'],
};

export type MigrationBlueprint = {
   __typename?: 'MigrationBlueprint',
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  components: Array<Component>,
};

export type MigrationEvent = {
   __typename?: 'MigrationEvent',
  eventType: Scalars['String'],
  migrations: Array<Migration>,
};

export type MigrationFlow = Relationship & {
   __typename?: 'MigrationFlow',
  props: MigrationFlowProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type MigrationFlowProps = {
   __typename?: 'MigrationFlowProps',
  title?: Maybe<Scalars['String']>,
};

export type MigrationFlowsHub = Hub & {
   __typename?: 'MigrationFlowsHub',
  props: MigrationFlowsHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type MigrationFlowsHubProps = {
   __typename?: 'MigrationFlowsHubProps',
  proposedRelationshipTitle?: Maybe<Scalars['String']>,
};

export type MigrationHistoryItem = {
   __typename?: 'MigrationHistoryItem',
  isEvent: Scalars['Boolean'],
  startedAt: Scalars['Instant'],
  actor: Scalars['String'],
  type: Scalars['String'],
  details: Scalars['String'],
  completion?: Maybe<MigrationHistoryItemCompletion>,
};

export type MigrationHistoryItemCompletion = {
   __typename?: 'MigrationHistoryItemCompletion',
  completedAt: Scalars['Instant'],
  details: Scalars['String'],
};

export type MigrationIssue = {
   __typename?: 'MigrationIssue',
  id: Scalars['String'],
  migrationId: Scalars['String'],
  class: Scalars['String'],
  message: Scalars['String'],
  endpointAlias: Scalars['String'],
  requiredAction: MigrationIssueAction,
  isBlocking: Scalars['Boolean'],
  durationMillis?: Maybe<Scalars['Long']>,
  willBeAutoResolvedAt?: Maybe<Scalars['Instant']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  resolvedAt?: Maybe<Scalars['Instant']>,
  ignoreUntil?: Maybe<Scalars['Instant']>,
  errorJson?: Maybe<Scalars['String']>,
};

export enum MigrationIssueAction {
  NoAction = 'NoAction',
  Fix = 'Fix',
  ProvideNewAccessKey = 'ProvideNewAccessKey',
  View = 'View'
}

export type MigrationIssueList = {
   __typename?: 'MigrationIssueList',
  migrationId: Scalars['String'],
  issues: Array<MigrationIssue>,
};

export type MigrationIssueListEvent = {
   __typename?: 'MigrationIssueListEvent',
  eventType: Scalars['String'],
  migrationIssues: Array<MigrationIssueList>,
};

export type MigrationNote = {
   __typename?: 'MigrationNote',
  id: Scalars['Int'],
  migrationId: Scalars['String'],
  type: MigrationNoteType,
  active: Scalars['Boolean'],
  message: Scalars['String'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  resolvedAt?: Maybe<Scalars['Instant']>,
  createdBy: Scalars['String'],
  resolvedBy?: Maybe<Scalars['String']>,
};

export type MigrationNoteList = {
   __typename?: 'MigrationNoteList',
  migrationId: Scalars['String'],
  activeOnly: Scalars['Boolean'],
  migrationNotes: Array<MigrationNote>,
};

export type MigrationNoteListEvent = {
   __typename?: 'MigrationNoteListEvent',
  eventType: Scalars['String'],
  migrationNotes: Array<MigrationNote>,
};

export enum MigrationNoteType {
  Info = 'Info',
  Warning = 'Warning',
  Issue = 'Issue'
}

export type MigrationProgressComp = Component & {
   __typename?: 'MigrationProgressComp',
  props: MigrationProgressCompProps,
  hubs: MigrationProgressCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type MigrationProgressCompHubs = ComponentHubs & {
   __typename?: 'MigrationProgressCompHubs',
  totalBytes: OptionalRelationshipHub,
  totalItems: OptionalRelationshipHub,
  endOfEnumerationPhases: AssociationsHub,
  endOfProcessingPhases: AssociationsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type MigrationProgressCompOutput = {
   __typename?: 'MigrationProgressCompOutput',
  cumulative: MigrationProgressCompOutput_Metrics,
  iteration: MigrationProgressCompOutput_Metrics,
  completed: Scalars['Boolean'],
};

export type MigrationProgressCompOutput_Metrics = {
   __typename?: 'MigrationProgressCompOutput_Metrics',
  estimatedBytes?: Maybe<Scalars['Long']>,
  enumeratedBytes?: Maybe<Scalars['Long']>,
  totalBytes?: Maybe<Scalars['Long']>,
  processedBytes: Scalars['Long'],
  skippedBytes: Scalars['Long'],
  estimatedItems?: Maybe<Array<MigrationProgressCompOutputEntry>>,
  enumeratedItems?: Maybe<Array<MigrationProgressCompOutputEntry>>,
  totalItems?: Maybe<Array<MigrationProgressCompOutputEntry>>,
  processedItems: Array<MigrationProgressCompOutputEntry>,
  skippedItems: Array<MigrationProgressCompOutputEntry>,
};

export type MigrationProgressCompOutputEntry = {
   __typename?: 'MigrationProgressCompOutputEntry',
  itemType: Scalars['String'],
  count: Scalars['Long'],
};

export type MigrationProgressCompProps = {
   __typename?: 'MigrationProgressCompProps',
  title: Scalars['String'],
  itemCategory?: Maybe<Scalars['String']>,
  cumulativeFactRefs: MigrationProgressCompProps_CumulativeFactRefs,
  iterationFactRefs: MigrationProgressCompProps_IterationFactRefs,
};

export type MigrationProgressCompProps_CumulativeFactRefs = {
   __typename?: 'MigrationProgressCompProps_CumulativeFactRefs',
  bytesEnumerated: FactRef,
  itemsEnumerated: FactRef,
  bytesProcessed: FactRef,
  itemsProcessed: FactRef,
  bytesSkipped: FactRef,
  itemsSkipped: FactRef,
};

export type MigrationProgressCompProps_IterationFactRefs = {
   __typename?: 'MigrationProgressCompProps_IterationFactRefs',
  bytesEnumerated: FactRef,
  itemsEnumerated: FactRef,
  bytesProcessed: FactRef,
  itemsProcessed: FactRef,
  bytesSkipped: FactRef,
  itemsSkipped: FactRef,
};

export type MigrationsActivity = {
   __typename?: 'MigrationsActivity',
  timestamp: Scalars['Instant'],
  count: Scalars['Int'],
};

export type MigrationStats = {
   __typename?: 'MigrationStats',
  totalBytes?: Maybe<Scalars['Long']>,
  totalItems?: Maybe<Scalars['Long']>,
  migratedBytes?: Maybe<Scalars['Long']>,
  migratedItems?: Maybe<Scalars['Long']>,
  skippedBytes?: Maybe<Scalars['Long']>,
  skippedItems?: Maybe<Scalars['Long']>,
};

export enum MigrationStatus {
  Pausing = 'Pausing',
  Scheduled = 'Scheduled',
  Aborted = 'Aborted',
  WaitingForManualReview = 'WaitingForManualReview',
  WaitingForTemporaryCondition = 'WaitingForTemporaryCondition',
  Completed = 'Completed',
  Starting = 'Starting',
  Running = 'Running',
  StoppedForUnknownReason = 'StoppedForUnknownReason',
  WaitingForUserAction = 'WaitingForUserAction',
  Paused = 'Paused'
}

export type MissingFactState = {
   __typename?: 'MissingFactState',
  foo: Scalars['String'],
};

export type MultipleRelationshipsHub = Hub & {
   __typename?: 'MultipleRelationshipsHub',
  props: MultipleRelationshipsHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type MultipleRelationshipsHubProps = {
   __typename?: 'MultipleRelationshipsHubProps',
  proposedRelationshipTitle?: Maybe<Scalars['String']>,
  reducerType: Scalars['String'],
  ignoreBlocked: Scalars['Boolean'],
};

export type Mutation = {
   __typename?: 'Mutation',
  insertAccessList: AccessList,
  updateAccessList: AccessList,
  deleteAccessList: Scalars['Boolean'],
  deleteAccessListEntries: DeleteAccessListEntriesResult,
  startAccessListUpdateJob: Scalars['Int'],
  describeGoogleGroup?: Maybe<GoogleGroup>,
  imapSignIn: OneTimeAuthCodeSummary,
  serviceAccountSignIn: OneTimeAuthCodeSummary,
  getAccessKey: AccessKeyContainer,
  replaceAccessKey: AccessKeyContainer,
  insertOffboardingProject: OffboardingProject,
  updateOffboardingProject: OffboardingProject,
  deleteOffboardingProject: Scalars['Boolean'],
  disableOffboardingProjectEmailDelivery: OffboardingProject,
  forceStartEmailDeliveryRound: OffboardingProject,
  restartCurrentEmailDeliveryRound: OffboardingProject,
  advanceToNextEmailDeliveryRound: OffboardingProject,
  deleteOffboardingProjectEntries: DeleteOffboardingProjectEntriesResult,
  renderOffboardingEmailPreview: EmailPreview,
  sendTestOffboardingProjectEmail: EmailPreview,
  optOutOffboardingProjectEntry: Scalars['Boolean'],
  unsubscribeOffboardingProjectEntry: Scalars['Boolean'],
  refreshBatchFacts: GetBlueprintFactsResult,
  generateBatchReport: Scalars['String'],
  prepareBatchOrder: PrepareBatchOrderResult,
  scheduleBatch: Batch,
  launchBatch: Batch,
  syncUpBatch: Batch,
  assignBatch: Batch,
  renameBatch: Batch,
  verifyGoogleAdminConnection: AdminConnectionVerificationResult,
  verifyMicrosoftAdminConnection: AdminConnectionVerificationResult,
  calcTimeEstimates: Array<EstimationResult>,
  makeSchoolLookupPrompt: Scalars['String'],
  generateJsonUsingChatGPT: Scalars['String'],
  insertConfiguration: Configuration,
  updateConfiguration: Configuration,
  deleteConfiguration: Scalars['Boolean'],
  connect?: Maybe<ConnectResult>,
  checkAccessKey?: Maybe<Connection>,
  revealAccessKey?: Maybe<RevealAccessKeyResult>,
  revokeAccessKey?: Maybe<Connection>,
  revokeAllAccessKeys: Array<Connection>,
  resolveConnections: ResolveConnectionsResult,
  insertCouponCode: CouponCode,
  updateCouponCode: CouponCode,
  deleteCouponCode: Scalars['Boolean'],
  sendCouponCodes: Array<SendCouponCodeResult>,
  refreshBlueprintFacts: GetBlueprintFactsResult,
  putFact: Fact,
  putIncompleteFact: Fact,
  invalidateFact: Fact,
  launchIteration: JobStatusTransitionSchedulingResult,
  startJob: JobHistoryRecord,
  scheduleJobStatusTransition: JobStatusTransitionSchedulingResult,
  abortJob: Scalars['String'],
  pingJob: Scalars['String'],
  restartJobSupervisor: Scalars['String'],
  addMigrationNote: MigrationNote,
  updateMigrationNote: MigrationNote,
  resolveMigrationNote: MigrationNote,
  reopenMigrationNote: MigrationNote,
  placeOrder: PlaceOrderResult,
  launchMigration: LaunchMigrationResult,
  setMigrationFeedback: Scalars['String'],
  resolveIssue: ResolveIssueResult,
  takeForSupervision: Migration,
  releaseFromSupervision: Migration,
  findOrganizationMigrations: Array<Migration>,
  setAutoResumingConfiguration: Migration,
  pauseMigration: Migration,
  resumeMigration: Migration,
  abortMigration: Migration,
  submitTransactionForSettlement?: Maybe<Order>,
  voidTransaction?: Maybe<Order>,
  refundTransaction?: Maybe<Order>,
  insertOrganization: Organization,
  updateOrganization: Organization,
  deleteOrganization: Scalars['Boolean'],
  incrementSponsorshipLimitAmountRemaining: Organization,
  clearSponsorshipLimitAmountRemaining: Organization,
  insertOrganizationPortalUser: OrganizationPortalUser,
  insertOrganizationPortalUsers: Array<OrganizationPortalUser>,
  updateOrganizationPortalUser: OrganizationPortalUser,
  deleteOrganizationPortalUser: Scalars['Boolean'],
  validateCouponCode: CouponCodeSummary,
  validateReferralCode: ValidateReferralCodeResult,
  validateAmbassadorCode: ValidateAmbassadorCodeResult,
  renderAmbassadorEmailPreview: EmailPreview,
  sendTestAmbassadorEmail: Scalars['String'],
  validateAmbassadorsProgramEmailAddress: AmbassadorsProgramEmailAddressValidationResult,
  becomeAnAmbassador: User,
  insertPricingModel: PricingModel,
  updatePricingModel: PricingModel,
  deletePricingModel: Scalars['Boolean'],
  insertProgram: Program,
  updateProgram: Program,
  deleteProgram: Scalars['Boolean'],
  whitelistEmailAddressForProgram: EmailAddressWhitelistingForProgram,
  insertReferralCode: ReferralCode,
  updateReferralCode: ReferralCode,
  deleteReferralCode: Scalars['Boolean'],
  failWhenZero: Scalars['String'],
  insertSchool: School,
  updateSchool: School,
  deleteSchool: Scalars['Boolean'],
  insertTheme: Theme,
  updateTheme: Theme,
  deleteTheme: Scalars['Boolean'],
  upgradeUser: User,
  updateCustomerDetails: User,
};


export type MutationInsertAccessListArgs = {
  organizationId: Scalars['Int'],
  formData: AccessListFormData
};


export type MutationUpdateAccessListArgs = {
  id: Scalars['Int'],
  formData: AccessListFormData
};


export type MutationDeleteAccessListArgs = {
  id: Scalars['Int']
};


export type MutationDeleteAccessListEntriesArgs = {
  id: Scalars['Int'],
  emailAddresses: Array<Scalars['String']>
};


export type MutationStartAccessListUpdateJobArgs = {
  id: Scalars['Int']
};


export type MutationDescribeGoogleGroupArgs = {
  id: Scalars['String']
};


export type MutationImapSignInArgs = {
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  signInParams: ImapSignInParams
};


export type MutationServiceAccountSignInArgs = {
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  emailAddress: Scalars['String'],
  state: Scalars['String']
};


export type MutationGetAccessKeyArgs = {
  connectionId: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type MutationReplaceAccessKeyArgs = {
  connectionId: Scalars['String'],
  accessKeyRevision: Scalars['String'],
  roles: Array<Scalars['String']>,
  reason: AccessKeyReplacementReason
};


export type MutationInsertOffboardingProjectArgs = {
  organizationId: Scalars['Int'],
  formData: OffboardingProjectFormData
};


export type MutationUpdateOffboardingProjectArgs = {
  id: Scalars['String'],
  formData: OffboardingProjectFormData
};


export type MutationDeleteOffboardingProjectArgs = {
  id: Scalars['String']
};


export type MutationDisableOffboardingProjectEmailDeliveryArgs = {
  id: Scalars['String']
};


export type MutationForceStartEmailDeliveryRoundArgs = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type MutationRestartCurrentEmailDeliveryRoundArgs = {
  offboardingProjectId: Scalars['String'],
  expectedCurrentRound: Scalars['Int']
};


export type MutationAdvanceToNextEmailDeliveryRoundArgs = {
  offboardingProjectId: Scalars['String'],
  expectedNextRound: Scalars['Int']
};


export type MutationDeleteOffboardingProjectEntriesArgs = {
  id: Scalars['String'],
  emailAddresses: Array<Scalars['String']>
};


export type MutationRenderOffboardingEmailPreviewArgs = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type MutationSendTestOffboardingProjectEmailArgs = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type MutationOptOutOffboardingProjectEntryArgs = {
  id: Scalars['String'],
  reason: Scalars['String'],
  comment?: Maybe<Scalars['String']>
};


export type MutationUnsubscribeOffboardingProjectEntryArgs = {
  id: Scalars['String']
};


export type MutationRefreshBatchFactsArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>
};


export type MutationGenerateBatchReportArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<ReportItem>
};


export type MutationPrepareBatchOrderArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>
};


export type MutationScheduleBatchArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>,
  expectedAmountToBePaid: Scalars['BigDecimal'],
  discounts?: Maybe<Discounts>,
  braintreePayloadJson?: Maybe<Scalars['String']>,
  deviceData?: Maybe<Scalars['String']>,
  externalPayment?: Maybe<ExternalPayment>
};


export type MutationLaunchBatchArgs = {
  batchId: Scalars['String']
};


export type MutationSyncUpBatchArgs = {
  batchId: Scalars['String']
};


export type MutationAssignBatchArgs = {
  batchId: Scalars['String'],
  userId: Scalars['String']
};


export type MutationRenameBatchArgs = {
  batchId: Scalars['String'],
  name: Scalars['String']
};


export type MutationVerifyGoogleAdminConnectionArgs = {
  connectionId: Scalars['String']
};


export type MutationVerifyMicrosoftAdminConnectionArgs = {
  connectionId: Scalars['String']
};


export type MutationCalcTimeEstimatesArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type MutationMakeSchoolLookupPromptArgs = {
  domainName: Scalars['String']
};


export type MutationGenerateJsonUsingChatGptArgs = {
  model: Scalars['String'],
  prompt: Scalars['String'],
  temperature: Scalars['Float']
};


export type MutationInsertConfigurationArgs = {
  organizationId: Scalars['Int'],
  formData: ConfigurationFormData
};


export type MutationUpdateConfigurationArgs = {
  id: Scalars['Int'],
  formData: ConfigurationFormData
};


export type MutationDeleteConfigurationArgs = {
  id: Scalars['Int']
};


export type MutationConnectArgs = {
  contextType: SignInContextType,
  cloudServiceId: Scalars['String'],
  oneTimeAuthCode: Scalars['String'],
  timeZone?: Maybe<Scalars['String']>,
  newUserSettings?: Maybe<NewUserSettings>,
  ambassadorCode?: Maybe<Scalars['String']>
};


export type MutationCheckAccessKeyArgs = {
  connectionId: Scalars['String'],
  roles?: Maybe<Array<Scalars['String']>>
};


export type MutationRevealAccessKeyArgs = {
  connectionId: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type MutationRevokeAccessKeyArgs = {
  connectionId: Scalars['String'],
  force: Scalars['Boolean']
};


export type MutationResolveConnectionsArgs = {
  sourceCloudServiceId: Scalars['String'],
  sourceEmailAddresses: Array<Scalars['String']>,
  sourceAdminConnectionId?: Maybe<Scalars['String']>,
  destinationCloudServiceId: Scalars['String'],
  destinationEmailAddresses: Array<Scalars['String']>,
  destinationAdminConnectionId?: Maybe<Scalars['String']>
};


export type MutationInsertCouponCodeArgs = {
  formData: CouponCodeFormData
};


export type MutationUpdateCouponCodeArgs = {
  formData: CouponCodeFormData
};


export type MutationDeleteCouponCodeArgs = {
  id: Scalars['String']
};


export type MutationSendCouponCodesArgs = {
  discount: Scalars['BigDecimal'],
  emailAddresses: Scalars['String'],
  note: Scalars['String']
};


export type MutationRefreshBlueprintFactsArgs = {
  factIds: Array<Scalars['String']>,
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type MutationPutFactArgs = {
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  factId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String'],
  valueJson: Scalars['String']
};


export type MutationPutIncompleteFactArgs = {
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  factId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String'],
  valueJson: Scalars['String'],
  progress?: Maybe<Scalars['Float']>
};


export type MutationInvalidateFactArgs = {
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  factId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String']
};


export type MutationLaunchIterationArgs = {
  migrationId: Scalars['String']
};


export type MutationStartJobArgs = {
  type: Scalars['String'],
  jobId?: Maybe<Scalars['String']>,
  props: Scalars['String'],
  desiredStatus: Scalars['String']
};


export type MutationScheduleJobStatusTransitionArgs = {
  jobId: Scalars['String'],
  desiredStatus: Scalars['String'],
  force: Scalars['Boolean']
};


export type MutationAbortJobArgs = {
  jobId: Scalars['String']
};


export type MutationPingJobArgs = {
  jobId: Scalars['String'],
  count?: Maybe<Scalars['Int']>,
  interval?: Maybe<Scalars['Int']>
};


export type MutationAddMigrationNoteArgs = {
  migrationId: Scalars['String'],
  message: Scalars['String'],
  type: MigrationNoteType
};


export type MutationUpdateMigrationNoteArgs = {
  noteId: Scalars['Int'],
  message: Scalars['String'],
  type: MigrationNoteType
};


export type MutationResolveMigrationNoteArgs = {
  noteId: Scalars['Int']
};


export type MutationReopenMigrationNoteArgs = {
  noteId: Scalars['Int']
};


export type MutationPlaceOrderArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  programAlias?: Maybe<Scalars['String']>,
  blueprintInputs: Array<BlueprintInputsEntryInput>,
  checkboxesState?: Maybe<CheckboxesState>
};


export type MutationLaunchMigrationArgs = {
  orderId: Scalars['String'],
  expectedAmountToBePaid: Scalars['BigDecimal'],
  discounts?: Maybe<Discounts>,
  braintreePayloadJson?: Maybe<Scalars['String']>,
  deviceData?: Maybe<Scalars['String']>,
  externalPayment?: Maybe<ExternalPayment>
};


export type MutationSetMigrationFeedbackArgs = {
  migrationId: Scalars['String'],
  question: Scalars['String'],
  answer: Scalars['String']
};


export type MutationResolveIssueArgs = {
  migrationId: Scalars['String'],
  issueId: Scalars['String']
};


export type MutationTakeForSupervisionArgs = {
  migrationId: Scalars['String']
};


export type MutationReleaseFromSupervisionArgs = {
  migrationId: Scalars['String'],
  note?: Maybe<Scalars['String']>
};


export type MutationFindOrganizationMigrationsArgs = {
  organizationId: Scalars['Int'],
  searchCriteria: Scalars['String']
};


export type MutationSetAutoResumingConfigurationArgs = {
  migrationId: Scalars['String'],
  enabled: Scalars['Boolean'],
  timestamp?: Maybe<Scalars['String']>
};


export type MutationPauseMigrationArgs = {
  migrationId: Scalars['String']
};


export type MutationResumeMigrationArgs = {
  migrationId: Scalars['String']
};


export type MutationAbortMigrationArgs = {
  migrationId: Scalars['String'],
  authorizationAction: AuthorizationAction
};


export type MutationSubmitTransactionForSettlementArgs = {
  migrationId: Scalars['String']
};


export type MutationVoidTransactionArgs = {
  migrationId: Scalars['String']
};


export type MutationRefundTransactionArgs = {
  migrationId: Scalars['String']
};


export type MutationInsertOrganizationArgs = {
  formData: OrganizationFormData
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['Int'],
  formData: OrganizationFormData
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['Int']
};


export type MutationIncrementSponsorshipLimitAmountRemainingArgs = {
  organizationId: Scalars['Int'],
  amount: Scalars['BigDecimal']
};


export type MutationClearSponsorshipLimitAmountRemainingArgs = {
  organizationId: Scalars['Int']
};


export type MutationInsertOrganizationPortalUserArgs = {
  organizationId: Scalars['Int'],
  formData: OrganizationPortalUserFormData
};


export type MutationInsertOrganizationPortalUsersArgs = {
  organizationId: Scalars['Int'],
  emailAddresses: Array<Scalars['String']>,
  role: OrganizationPortalUserRole
};


export type MutationUpdateOrganizationPortalUserArgs = {
  id: Scalars['Int'],
  formData: OrganizationPortalUserFormData
};


export type MutationDeleteOrganizationPortalUserArgs = {
  id: Scalars['Int']
};


export type MutationValidateCouponCodeArgs = {
  couponCodeId: Scalars['String']
};


export type MutationValidateReferralCodeArgs = {
  sourceConnectionId?: Maybe<Scalars['String']>,
  referralCode: Scalars['String']
};


export type MutationValidateAmbassadorCodeArgs = {
  sourceConnectionId?: Maybe<Scalars['String']>,
  ambassadorCode: Scalars['String']
};


export type MutationRenderAmbassadorEmailPreviewArgs = {
  sourceConnectionId: Scalars['String']
};


export type MutationSendTestAmbassadorEmailArgs = {
  sourceConnectionId: Scalars['String'],
  customMessage?: Maybe<Scalars['String']>,
  accountAction: AccountAction,
  accountActionDate?: Maybe<Scalars['Instant']>
};


export type MutationValidateAmbassadorsProgramEmailAddressArgs = {
  sourceConnectionId: Scalars['String'],
  emailAddress: Scalars['String']
};


export type MutationBecomeAnAmbassadorArgs = {
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  customMessage?: Maybe<Scalars['String']>,
  accountAction: AccountAction,
  accountActionDate: Scalars['Instant'],
  contacts: Array<AmbassadorsProgramContact>
};


export type MutationInsertPricingModelArgs = {
  formData: PricingModelFormData
};


export type MutationUpdatePricingModelArgs = {
  id: Scalars['Int'],
  formData: PricingModelFormData
};


export type MutationDeletePricingModelArgs = {
  id: Scalars['Int']
};


export type MutationInsertProgramArgs = {
  organizationId: Scalars['Int'],
  formData: ProgramFormData
};


export type MutationUpdateProgramArgs = {
  formData: ProgramFormData
};


export type MutationDeleteProgramArgs = {
  id: Scalars['String']
};


export type MutationWhitelistEmailAddressForProgramArgs = {
  emailAddress: Scalars['String'],
  programId?: Maybe<Scalars['String']>
};


export type MutationInsertReferralCodeArgs = {
  organizationId: Scalars['Int'],
  formData: ReferralCodeFormData
};


export type MutationUpdateReferralCodeArgs = {
  id: Scalars['Int'],
  formData: ReferralCodeFormData
};


export type MutationDeleteReferralCodeArgs = {
  id: Scalars['Int']
};


export type MutationFailWhenZeroArgs = {
  argument: Scalars['Int']
};


export type MutationInsertSchoolArgs = {
  formData: SchoolFormData
};


export type MutationUpdateSchoolArgs = {
  id: Scalars['String'],
  formData: SchoolFormData
};


export type MutationDeleteSchoolArgs = {
  id: Scalars['String']
};


export type MutationInsertThemeArgs = {
  organizationId: Scalars['Int'],
  formData: ThemeFormData
};


export type MutationUpdateThemeArgs = {
  id: Scalars['Int'],
  formData: ThemeFormData
};


export type MutationDeleteThemeArgs = {
  id: Scalars['Int']
};


export type MutationUpgradeUserArgs = {
  userId: Scalars['String'],
  connectionId: Scalars['String']
};


export type MutationUpdateCustomerDetailsArgs = {
  userId: Scalars['String'],
  customerDetails: Scalars['String']
};

export type NamedSignInFormConfig = {
   __typename?: 'NamedSignInFormConfig',
  name: Scalars['String'],
  config: SignInFormConfig,
};

export type NamedSignInFormCustomization = {
   __typename?: 'NamedSignInFormCustomization',
  name: Scalars['String'],
  customization: SignInFormCustomization,
};

export type NewUserSettings = {
  userType: UserType,
  createdForUserId?: Maybe<Scalars['String']>,
};

export type OAuthAndServiceAccountSignInFormConfig = SignInFormConfig & {
   __typename?: 'OAuthAndServiceAccountSignInFormConfig',
  providerId: Scalars['String'],
  url: Scalars['String'],
  callbackUrl: Scalars['String'],
  allowServiceAccountSignIn: Scalars['Boolean'],
};

export type OAuthSignInFormConfig = SignInFormConfig & {
   __typename?: 'OAuthSignInFormConfig',
  providerId: Scalars['String'],
  url: Scalars['String'],
  callbackUrl: Scalars['String'],
};

export type OAuthSignInFormCustomization = SignInFormCustomization & {
   __typename?: 'OAuthSignInFormCustomization',
  vendorName?: Maybe<Scalars['String']>,
  serviceName?: Maybe<Scalars['String']>,
  introHtml?: Maybe<Scalars['String']>,
  introHelpArticle?: Maybe<ExternalHelpArticle>,
  inlineHtml?: Maybe<Scalars['String']>,
  inlineHelpArticle?: Maybe<ExternalHelpArticle>,
  footerHtml?: Maybe<Scalars['String']>,
  footerHelpArticle?: Maybe<ExternalHelpArticle>,
  howToSwitchAccountHelpArticle?: Maybe<ExternalHelpArticle>,
  buttonCss?: Maybe<Scalars['String']>,
  buttonIcon?: Maybe<Scalars['String']>,
};

export type OffboardingProject = {
   __typename?: 'OffboardingProject',
  id: Scalars['String'],
  organizationId: Scalars['Int'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  connectionId?: Maybe<Scalars['String']>,
  programId?: Maybe<Scalars['String']>,
  cutoffAt: Scalars['Instant'],
  scheduleId: Scalars['String'],
  from?: Maybe<Scalars['String']>,
  subject1: Scalars['String'],
  body1: Scalars['String'],
  subject2?: Maybe<Scalars['String']>,
  body2?: Maybe<Scalars['String']>,
  subject3?: Maybe<Scalars['String']>,
  body3?: Maybe<Scalars['String']>,
  subject4?: Maybe<Scalars['String']>,
  body4?: Maybe<Scalars['String']>,
  currentRound?: Maybe<Scalars['Int']>,
  nextRound?: Maybe<Scalars['Int']>,
  currentRoundTotalEmails?: Maybe<Scalars['Int']>,
  currentRoundSentEmails?: Maybe<Scalars['Int']>,
  emailDeliveryError?: Maybe<OffboardingProject_EmailDeliveryError>,
  currentUpdateId?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  connection?: Maybe<Connection>,
  program?: Maybe<Program>,
  schedule: AppliedEmailDeliverySchedule,
  jobId: Scalars['String'],
  isEmailSendingInProgress: Scalars['Boolean'],
  isCompleted: Scalars['Boolean'],
};

export type OffboardingProject_EmailDeliveryError = {
   __typename?: 'OffboardingProject_EmailDeliveryError',
  type: EmailDeliveryErrorType,
  details: Scalars['String'],
};

export enum OffboardingProjectEntryParticipationStatus {
  OptedOut = 'OptedOut',
  Unsubscribed = 'Unsubscribed',
  Pending = 'Pending',
  Unknown = 'Unknown',
  Migrated = 'Migrated'
}

export type OffboardingProjectFormData = {
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  emailDeliveryAccountConfig?: Maybe<OffboardingProjectFormData_EmailDeliveryAccountConfig>,
  programId?: Maybe<Scalars['String']>,
  cutoffAt: Scalars['Instant'],
  scheduleId: Scalars['String'],
  from?: Maybe<Scalars['String']>,
  subject1: Scalars['String'],
  body1: Scalars['String'],
  subject2?: Maybe<Scalars['String']>,
  body2?: Maybe<Scalars['String']>,
  subject3?: Maybe<Scalars['String']>,
  body3?: Maybe<Scalars['String']>,
  subject4?: Maybe<Scalars['String']>,
  body4?: Maybe<Scalars['String']>,
};

export type OffboardingProjectFormData_EmailDeliveryAccountConfig = {
  oneTimeAuthCode: Scalars['String'],
  cloudServiceId: Scalars['String'],
};

export type OffboardingProjectStats = {
   __typename?: 'OffboardingProjectStats',
  offboardingProjectId: Scalars['String'],
  pending: Scalars['Int'],
  openedLink: Scalars['Int'],
  connectedAccount: Scalars['Int'],
  startedMigration: Scalars['Int'],
  completedMigration: Scalars['Int'],
  optedOut: Scalars['Int'],
  createdAt: Scalars['Instant'],
  date: Scalars['Instant'],
};

export type OffboardingProjectUpdate = {
   __typename?: 'OffboardingProjectUpdate',
  id: Scalars['String'],
  offboardingProjectId: Scalars['String'],
  status: AccessListUpdateStatus,
  processedEntries: Scalars['Int'],
  skippedEntries: Scalars['Int'],
  errors: Scalars['Int'],
  errorMessage?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  completedAt?: Maybe<Scalars['Instant']>,
};

export type OneTimeAuthCodeSummary = {
   __typename?: 'OneTimeAuthCodeSummary',
  code: Scalars['String'],
  expiresAt: Scalars['Instant'],
};

export type OptionalRelationshipHub = Hub & {
   __typename?: 'OptionalRelationshipHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type Order = {
   __typename?: 'Order',
  id: Scalars['String'],
  userId: Scalars['String'],
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  previousMigrationFromSource?: Maybe<Scalars['String']>,
  previousMigrationFromSourceToDestination?: Maybe<Scalars['String']>,
  programId?: Maybe<Scalars['String']>,
  pricingModelName: Scalars['String'],
  pricingModelType: Scalars['String'],
  totalBytes: Scalars['Long'],
  totalItems: Scalars['Long'],
  originalBasePrice: Scalars['BigDecimal'],
  originalBytesFee: Scalars['BigDecimal'],
  originalItemsFee: Scalars['BigDecimal'],
  finalBasePrice: Scalars['BigDecimal'],
  finalBytesFee: Scalars['BigDecimal'],
  finalItemsFee: Scalars['BigDecimal'],
  priceBeforeDiscounts: Scalars['BigDecimal'],
  couponCodeDiscount?: Maybe<CouponCodeDiscount>,
  referralCodeDiscount?: Maybe<ReferralCodeDiscount>,
  ambassadorStatusDiscount?: Maybe<AmbassadorStatusDiscount>,
  ambassadorCodeDiscount?: Maybe<AmbassadorCodeDiscount>,
  sponsoredAmount?: Maybe<Scalars['BigDecimal']>,
  amountToBePaid: Scalars['BigDecimal'],
  paidAmount?: Maybe<Scalars['BigDecimal']>,
  priceBreakdown: Scalars['String'],
  transactionId?: Maybe<Scalars['String']>,
  status: OrderStatus,
  checkbox1Label?: Maybe<Scalars['String']>,
  checkbox1Checked?: Maybe<Scalars['Boolean']>,
  checkbox2Label?: Maybe<Scalars['String']>,
  checkbox2Checked?: Maybe<Scalars['Boolean']>,
  checkbox3Label?: Maybe<Scalars['String']>,
  checkbox3Checked?: Maybe<Scalars['Boolean']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  authorizedAt?: Maybe<Scalars['Instant']>,
  settledAt?: Maybe<Scalars['Instant']>,
  blueprintInputs: Array<BlueprintInputsEntry>,
  pricingModelVersion: Scalars['String'],
  pricingModelJson: Scalars['String'],
  revenue: Scalars['BigDecimal'],
  revenueShare?: Maybe<RevenueShare>,
  braintreeTransactionDetailsUrl?: Maybe<Scalars['String']>,
};

export enum OrderStatus {
  PaidExternally = 'PaidExternally',
  Voided = 'Voided',
  ProcessedExternally = 'ProcessedExternally',
  Settled = 'Settled',
  Skipped = 'Skipped',
  Refunded = 'Refunded',
  Authorized = 'Authorized',
  Created = 'Created'
}

export type OrderSummary = {
   __typename?: 'OrderSummary',
  orderId: Scalars['String'],
  programId?: Maybe<Scalars['String']>,
  totalBytes: Scalars['Long'],
  totalItems: Scalars['Long'],
  originalBasePrice: Scalars['BigDecimal'],
  originalBytesFee: Scalars['BigDecimal'],
  originalItemsFee: Scalars['BigDecimal'],
  priceBeforeDiscounts: Scalars['BigDecimal'],
  couponCodeDiscount?: Maybe<CouponCodeDiscount>,
  referralCodeDiscount?: Maybe<ReferralCodeDiscount>,
  ambassadorStatusDiscount?: Maybe<AmbassadorStatusDiscount>,
  ambassadorCodeDiscount?: Maybe<AmbassadorCodeDiscount>,
  priceAfterDiscounts: Scalars['BigDecimal'],
  sponsoredAmount?: Maybe<Scalars['BigDecimal']>,
  amountToBePaid: Scalars['BigDecimal'],
  priceCalculator: PricingModelCalculator,
  program?: Maybe<Program>,
  usedSponsorship?: Maybe<OrderSummary_UsedSponsorship>,
};

export type OrderSummary_UsedSponsorship = {
   __typename?: 'OrderSummary_UsedSponsorship',
  sponsorshipConfig?: Maybe<SponsorshipConfig>,
  sponsoredAmount: Scalars['BigDecimal'],
};

export type Organization = {
   __typename?: 'Organization',
  id: Scalars['Int'],
  alias: Scalars['String'],
  name: Scalars['String'],
  themeId?: Maybe<Scalars['Int']>,
  logoId?: Maybe<Scalars['String']>,
  domains: Scalars['String'],
  revenueSharingEnabled: Scalars['Boolean'],
  sponsorshipLimitAmountRemaining?: Maybe<Scalars['BigDecimal']>,
  createdAt: Scalars['Instant'],
  activatedAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
  logo?: Maybe<Upload>,
};

export type OrganizationAccessListStats = {
   __typename?: 'OrganizationAccessListStats',
  programAccessListCount: Scalars['Int'],
  organizationBlockListCount: Scalars['Int'],
};

export type OrganizationContext = {
   __typename?: 'OrganizationContext',
  organization: Organization,
  organizationPortalUser?: Maybe<OrganizationPortalUser>,
};

export type OrganizationFormData = {
  alias: Scalars['String'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  themeId?: Maybe<Scalars['Int']>,
  logoId?: Maybe<Scalars['String']>,
  domains: Scalars['String'],
  revenueSharingEnabled: Scalars['Boolean'],
  activatedAt: Scalars['Instant'],
};

export type OrganizationMigrationStats = {
   __typename?: 'OrganizationMigrationStats',
  totalMigrationCount: Scalars['Int'],
  recentMigrationCount: Scalars['Int'],
};

export type OrganizationPortalUser = {
   __typename?: 'OrganizationPortalUser',
  id: Scalars['Int'],
  organizationId: Scalars['Int'],
  emailAddress: Scalars['String'],
  role: OrganizationPortalUserRole,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  signedInAt?: Maybe<Scalars['Instant']>,
};

export type OrganizationPortalUserFormData = {
  emailAddress: Scalars['String'],
  role: OrganizationPortalUserRole,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
};

export enum OrganizationPortalUserRole {
  ReadAccess = 'ReadAccess',
  ReadAndWriteAccess = 'ReadAndWriteAccess',
  Administrator = 'Administrator',
  Owner = 'Owner'
}

export type OutlookSignInFormConfig = SignInFormConfig & {
   __typename?: 'OutlookSignInFormConfig',
  providerId: Scalars['String'],
  exchange: OAuthSignInFormConfig,
  imap: OAuthSignInFormConfig,
  emailAddress: Scalars['String'],
  userId: Scalars['String'],
  tenantId: Scalars['String'],
};

export type PendingBlueprintState = BlueprintState & {
   __typename?: 'PendingBlueprintState',
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type PersonalInfo = {
   __typename?: 'PersonalInfo',
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  emailAddress?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  payPalEmailAddress?: Maybe<Scalars['String']>,
  timeZone?: Maybe<Scalars['String']>,
};

export type PhaseComp = Component & {
   __typename?: 'PhaseComp',
  props: PhaseCompProps,
  hubs: PhaseCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type PhaseCompHubs = ComponentHubs & {
   __typename?: 'PhaseCompHubs',
  pullFrom: SelectorHub,
  vars: VarsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  accessKey1: OptionalRelationshipHub,
  accessKey2: OptionalRelationshipHub,
  endpointRef1: OptionalRelationshipHub,
  endpointRef2: OptionalRelationshipHub,
};

export type PhaseCompItemTypeSelector = {
   __typename?: 'PhaseCompItemTypeSelector',
  bambooItemType: Scalars['String'],
  itemType: ItemType,
  itemCategory?: Maybe<Scalars['String']>,
  addToSkipped: Scalars['Boolean'],
  withTags: Array<PhaseCompTagSelector>,
  withoutTags: Array<Scalars['String']>,
};

export type PhaseCompOutput = {
   __typename?: 'PhaseCompOutput',
  phaseId: Scalars['String'],
  phaseDefJson: Scalars['String'],
};

export type PhaseCompProps = {
   __typename?: 'PhaseCompProps',
  title: Scalars['String'],
  inputProjection?: Maybe<Scalars['String']>,
  outputProjection?: Maybe<Scalars['String']>,
  itemTypeSelectors: Array<PhaseCompItemTypeSelector>,
  endOfProcessing: Scalars['Boolean'],
};

export type PhaseCompTagSelector = {
   __typename?: 'PhaseCompTagSelector',
  tag: Scalars['String'],
  value: Scalars['String'],
};

export type Pipe = Relationship & {
   __typename?: 'Pipe',
  props: PipeProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type PipeFromOption = Relationship & {
   __typename?: 'PipeFromOption',
  props: PipeFromOptionProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type PipeFromOptionProps = {
   __typename?: 'PipeFromOptionProps',
  outputName: Scalars['String'],
  promotePartial: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  default?: Maybe<Variant>,
};

export type PipeProps = {
   __typename?: 'PipeProps',
  outputName: Scalars['String'],
  promotePartial: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  default?: Maybe<Variant>,
};

export type PipeToOption = Relationship & {
   __typename?: 'PipeToOption',
  props: PipeToOptionProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type PipeToOptionProps = {
   __typename?: 'PipeToOptionProps',
  outputName: Scalars['String'],
  promotePartial: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  default?: Maybe<StrictOption>,
};

export type PlaceOrderResult = PlaceOrderResult_Success | PlaceOrderResult_ConnectionsAreInUse;

export type PlaceOrderResult_ConnectionsAreInUse = {
   __typename?: 'PlaceOrderResult_ConnectionsAreInUse',
  migrationIds: Array<Scalars['String']>,
};

export type PlaceOrderResult_Success = {
   __typename?: 'PlaceOrderResult_Success',
  orderId: Scalars['String'],
  totalBytes: Scalars['Long'],
  totalItems: Scalars['Long'],
  originalBasePrice: Scalars['BigDecimal'],
  originalBytesFee: Scalars['BigDecimal'],
  originalItemsFee: Scalars['BigDecimal'],
  priceBeforeDiscounts: Scalars['BigDecimal'],
  clientToken: Scalars['String'],
  school?: Maybe<School>,
  priceCalculator: PricingModelCalculator,
};

export type Plug = Relationship & {
   __typename?: 'Plug',
  props: PlugProps,
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type PlugProps = {
   __typename?: 'PlugProps',
  title?: Maybe<Scalars['String']>,
  value: Variant,
};

export type PreconditionsHub = Hub & {
   __typename?: 'PreconditionsHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type PrepareBatchOrderResult = {
   __typename?: 'PrepareBatchOrderResult',
  clientToken: Scalars['String'],
  batchOrder: BatchOrder,
};

export type PreparingBlueprintState = BlueprintState & {
   __typename?: 'PreparingBlueprintState',
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type PreparingFactState = {
   __typename?: 'PreparingFactState',
  foo: Scalars['String'],
};

export type PricingModel = {
   __typename?: 'PricingModel',
  id: Scalars['Int'],
  basePriceDescription: Scalars['String'],
  basePrice: Scalars['BigDecimal'],
  basePriceForFollowUpMigrations?: Maybe<Scalars['BigDecimal']>,
  numberOfGbsIncluded: Scalars['Int'],
  numberOfItemsIncluded: Scalars['Int'],
  pricePerGb: Scalars['BigDecimal'],
  pricePerThousandItems: Scalars['BigDecimal'],
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
};

export type PricingModelCalculator = SimplePricingModelCalculator;

export type PricingModelFormData = {
  name: Scalars['String'],
  basePriceDescription: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  basePrice: Scalars['BigDecimal'],
  basePriceForFollowUpMigrations?: Maybe<Scalars['BigDecimal']>,
  numberOfGbsIncluded: Scalars['Int'],
  numberOfItemsIncluded: Scalars['Int'],
  pricePerGb: Scalars['BigDecimal'],
  pricePerThousandItems: Scalars['BigDecimal'],
};

export type Program = {
   __typename?: 'Program',
  id: Scalars['String'],
  organizationId: Scalars['Int'],
  alias?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  productName?: Maybe<Scalars['String']>,
  landingPageUrl?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  endUserAccess?: Maybe<Scalars['String']>,
  configurationId?: Maybe<Scalars['Int']>,
  introBannerId?: Maybe<Scalars['String']>,
  introTitle?: Maybe<Scalars['String']>,
  introContent?: Maybe<Scalars['String']>,
  pricingPageBannerId?: Maybe<Scalars['String']>,
  pricingPageTitle?: Maybe<Scalars['String']>,
  pricingPageContent?: Maybe<Scalars['String']>,
  pricingModelId?: Maybe<Scalars['Int']>,
  sendMigrationNotificationsToAdmins: Scalars['Boolean'],
  sendFeedbackNotificationsToAdmins: Scalars['Boolean'],
  status: ProgramStatus,
  sourceCloudServiceId?: Maybe<Scalars['String']>,
  destinationCloudServiceId?: Maybe<Scalars['String']>,
  sourceDomains?: Maybe<Scalars['String']>,
  destinationDomains?: Maybe<Scalars['String']>,
  discount: Scalars['BigDecimal'],
  discountType: DiscountType,
  sponsoredAmount: Scalars['BigDecimal'],
  revenueShare: Scalars['BigDecimal'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
  logo?: Maybe<Upload>,
  configuration?: Maybe<Configuration>,
  introBanner?: Maybe<Upload>,
  pricingPageBanner?: Maybe<Upload>,
  pricingModel?: Maybe<PricingModel>,
};

export type ProgramEligibility = {
   __typename?: 'ProgramEligibility',
  program: Program,
  configuration?: Maybe<Configuration>,
  theme?: Maybe<Theme>,
  discount: Scalars['BigDecimal'],
  isWhitelisted: Scalars['Boolean'],
};

export type ProgramFormData = {
  id: Scalars['String'],
  alias?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  productName?: Maybe<Scalars['String']>,
  landingPageUrl?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  endUserAccess?: Maybe<Scalars['String']>,
  configurationId?: Maybe<Scalars['Int']>,
  carousel: ProgramFormData_Carousel,
  pricingModelId?: Maybe<Scalars['Int']>,
  sendMigrationNotificationsToAdmins: Scalars['Boolean'],
  sendFeedbackNotificationsToAdmins: Scalars['Boolean'],
  status: ProgramStatus,
  sourceCloudServiceId?: Maybe<Scalars['String']>,
  destinationCloudServiceId?: Maybe<Scalars['String']>,
  sourceDomains?: Maybe<Scalars['String']>,
  destinationDomains?: Maybe<Scalars['String']>,
  pricing: ProgramFormData_Pricing,
};

export type ProgramFormData_Carousel = {
  introBannerId?: Maybe<Scalars['String']>,
  introTitle?: Maybe<Scalars['String']>,
  introContent?: Maybe<Scalars['String']>,
  pricingPageBannerId?: Maybe<Scalars['String']>,
  pricingPageTitle?: Maybe<Scalars['String']>,
  pricingPageContent?: Maybe<Scalars['String']>,
};

export type ProgramFormData_Pricing = {
  discount: Scalars['BigDecimal'],
  discountType: DiscountType,
  sponsoredAmount: Scalars['BigDecimal'],
  revenueShare: Scalars['BigDecimal'],
};

export type ProgramPerformance = {
   __typename?: 'ProgramPerformance',
  programName?: Maybe<Scalars['String']>,
  dataPoints: Array<ProgramPerformance_DataPoint>,
};

export type ProgramPerformance_DataPoint = {
   __typename?: 'ProgramPerformance_DataPoint',
  timestamp: Scalars['Instant'],
  migrationCount: Scalars['Int'],
};

export enum ProgramStatus {
  Disabled = 'Disabled',
  Restricted = 'Restricted',
  Live = 'Live'
}

export type Query = {
   __typename?: 'Query',
  accessList: AccessList,
  searchAccessLists: Array<AccessList>,
  organizationAccessListStats: OrganizationAccessListStats,
  accessListEntryCount: Scalars['Int'],
  accessListUpdate: AccessListUpdate,
  accessListUpdateHistory: Array<AccessListUpdate>,
  accessListUpdates: Array<AccessListUpdate>,
  getAppBootstrapConfig: AppBootstrapConfig,
  getSession?: Maybe<Session>,
  getSignInFormConfig: GetSignInFormConfigResponse,
  getSignUpFormConfig: Array<GetSignInFormConfigResponse>,
  offboardingProject: OffboardingProject,
  searchOffboardingProjects: Array<OffboardingProject>,
  emailDeliverySchedules: Array<EmailDeliverySchedule>,
  offboardingProjectEntryCount: Scalars['Int'],
  offboardingProjectUpdate: OffboardingProjectUpdate,
  offboardingProjectUpdateHistory: Array<OffboardingProjectUpdate>,
  offboardingProjectUpdates: Array<OffboardingProjectUpdate>,
  offboardingProjectStats: Array<OffboardingProjectStats>,
  offboardingProjectEntryParticipationStatus: OffboardingProjectEntryParticipationStatus,
  batch: Batch,
  batches: Array<Batch>,
  userBatches: Array<Batch>,
  getMigrationBlueprint: MigrationBlueprint,
  getRenderedBlueprint: RenderedBlueprint,
  buildWorkflowDef: WorkflowDef,
  configuration: Configuration,
  searchConfigurations: Array<Configuration>,
  getConnections: Array<Connection>,
  getConnection: Connection,
  couponCode: CouponCode,
  searchCouponCodes: Array<CouponCode>,
  getFacts: Array<FactOrPlaceholder>,
  getBlueprintFacts: GetBlueprintFactsResult,
  scans: Array<Scan>,
  iterations: Array<Iteration>,
  iteration: Iteration,
  getJobHistory: JobHistory,
  getTaskHistory: TaskHistory,
  getTaskIssueHistory: Array<TaskIssue>,
  getAvailableJobStatuses: AvailableJobStatuses,
  order: Order,
  migration: Migration,
  migrations: Array<Migration>,
  userMigrations: Array<Migration>,
  monitoredMigrations: Array<Migration>,
  recentMigrationsActivity: Array<MigrationsActivity>,
  getAuthorizationStatus: AuthorizationStatus,
  organizationProgramsPerformance: Array<ProgramPerformance>,
  organizationMigrationStats: OrganizationMigrationStats,
  organization: Organization,
  organizationContext: OrganizationContext,
  searchOrganizations: Array<Organization>,
  organizationPortalUser: OrganizationPortalUser,
  searchOrganizationPortalUsers: Array<OrganizationPortalUser>,
  getMigrationPriceCalculator: PricingModelCalculator,
  getSourceConnectionPromotions: GetSourceConnectionPromotionsResult,
  pricingModel: PricingModel,
  searchPricingModels: Array<PricingModel>,
  program: Program,
  searchPrograms: Array<Program>,
  listProgramsWithRevenueSharing: Array<Program>,
  testAccountWhitelistingsForPrograms: Array<EmailAddressWhitelistingForProgram>,
  referralCode: ReferralCode,
  searchReferralCodes: Array<ReferralCode>,
  sandbox: Scalars['String'],
  school: School,
  searchSchools: Array<School>,
  schoolStats: SchoolStats,
  theme: Theme,
  searchThemes: Array<Theme>,
  getUser: User,
  getUserSummary: UserSummary,
  searchUsers: Array<User>,
  ambassadorStatus: AmbassadorStatus,
};


export type QueryAccessListArgs = {
  id: Scalars['Int']
};


export type QuerySearchAccessListsArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QueryOrganizationAccessListStatsArgs = {
  organizationId: Scalars['Int']
};


export type QueryAccessListEntryCountArgs = {
  id: Scalars['Int']
};


export type QueryAccessListUpdateArgs = {
  id: Scalars['Int']
};


export type QueryAccessListUpdateHistoryArgs = {
  id: Scalars['Int']
};


export type QueryAccessListUpdatesArgs = {
  ids: Array<Scalars['Int']>
};


export type QueryGetAppBootstrapConfigArgs = {
  programAlias?: Maybe<Scalars['String']>,
  configurationAlias?: Maybe<Scalars['String']>,
  themeId?: Maybe<Scalars['Int']>,
  organizationIdOrAlias?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>
};


export type QueryGetSignInFormConfigArgs = {
  authFlowId: Scalars['String'],
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  existingAccessKeyId?: Maybe<Scalars['String']>
};


export type QueryGetSignUpFormConfigArgs = {
  authFlowId: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type QueryOffboardingProjectArgs = {
  id: Scalars['String']
};


export type QuerySearchOffboardingProjectsArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QueryOffboardingProjectEntryCountArgs = {
  id: Scalars['String']
};


export type QueryOffboardingProjectUpdateArgs = {
  id: Scalars['String']
};


export type QueryOffboardingProjectUpdateHistoryArgs = {
  id: Scalars['String']
};


export type QueryOffboardingProjectUpdatesArgs = {
  ids: Array<Scalars['String']>
};


export type QueryOffboardingProjectStatsArgs = {
  id: Scalars['String']
};


export type QueryOffboardingProjectEntryParticipationStatusArgs = {
  id: Scalars['String']
};


export type QueryBatchArgs = {
  batchId: Scalars['String']
};


export type QueryBatchesArgs = {
  batchIds: Array<Scalars['String']>
};


export type QueryUserBatchesArgs = {
  userId: Scalars['String']
};


export type QueryGetMigrationBlueprintArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String']
};


export type QueryGetRenderedBlueprintArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type QueryBuildWorkflowDefArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type QueryConfigurationArgs = {
  id: Scalars['Int']
};


export type QuerySearchConfigurationsArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QueryGetConnectionsArgs = {
  userId: Scalars['String'],
  connectionIds?: Maybe<Array<Scalars['String']>>,
  accessKeyCheck: AccessKeyCheck
};


export type QueryGetConnectionArgs = {
  userId: Scalars['String'],
  connectionId: Scalars['String']
};


export type QueryCouponCodeArgs = {
  id: Scalars['String']
};


export type QuerySearchCouponCodesArgs = {
  term: Scalars['String']
};


export type QueryGetFactsArgs = {
  factIds: Array<Scalars['String']>
};


export type QueryGetBlueprintFactsArgs = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintFactSpecs: Array<BlueprintFactsSpec>
};


export type QueryScansArgs = {
  connectionIds: Array<Scalars['String']>,
  includeCompleted: Scalars['Boolean'],
  limit?: Maybe<Scalars['Int']>
};


export type QueryIterationsArgs = {
  migrationId: Scalars['String']
};


export type QueryIterationArgs = {
  migrationId: Scalars['String'],
  iterationIndex: Scalars['Int']
};


export type QueryGetJobHistoryArgs = {
  jobId: Scalars['String'],
  includeMinorUpdates?: Maybe<Scalars['Boolean']>
};


export type QueryGetTaskHistoryArgs = {
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  includeMinorUpdates?: Maybe<Scalars['Boolean']>
};


export type QueryGetTaskIssueHistoryArgs = {
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  issueId: Scalars['String']
};


export type QueryGetAvailableJobStatusesArgs = {
  jobId: Scalars['String']
};


export type QueryOrderArgs = {
  orderId: Scalars['String']
};


export type QueryMigrationArgs = {
  migrationId: Scalars['String']
};


export type QueryMigrationsArgs = {
  migrationIds: Array<Scalars['String']>
};


export type QueryUserMigrationsArgs = {
  userId: Scalars['String'],
  includeBatched: Scalars['Boolean']
};


export type QueryMonitoredMigrationsArgs = {
  includeCompleted: Scalars['Int']
};


export type QueryGetAuthorizationStatusArgs = {
  migrationId: Scalars['String']
};


export type QueryOrganizationProgramsPerformanceArgs = {
  organizationId: Scalars['Int'],
  timeUnit: ActivityTimeUnit,
  timeUnitCount: Scalars['Int'],
  timeZoneOffset: Scalars['Int']
};


export type QueryOrganizationMigrationStatsArgs = {
  organizationId: Scalars['Int'],
  recentMigrationDayCount: Scalars['Int'],
  timeZoneOffset: Scalars['Int']
};


export type QueryOrganizationArgs = {
  id: Scalars['Int']
};


export type QueryOrganizationContextArgs = {
  idOrAlias: Scalars['String']
};


export type QuerySearchOrganizationsArgs = {
  term: Scalars['String']
};


export type QueryOrganizationPortalUserArgs = {
  id: Scalars['Int']
};


export type QuerySearchOrganizationPortalUsersArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QueryGetMigrationPriceCalculatorArgs = {
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  programAlias?: Maybe<Scalars['String']>
};


export type QueryGetSourceConnectionPromotionsArgs = {
  currentProgramAlias?: Maybe<Scalars['String']>,
  sourceConnectionId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  destinationConnectionId?: Maybe<Scalars['String']>
};


export type QueryPricingModelArgs = {
  id: Scalars['Int']
};


export type QuerySearchPricingModelsArgs = {
  term: Scalars['String']
};


export type QueryProgramArgs = {
  id: Scalars['String']
};


export type QuerySearchProgramsArgs = {
  organizationId?: Maybe<Scalars['Int']>,
  term: Scalars['String']
};


export type QueryListProgramsWithRevenueSharingArgs = {
  organizationId: Scalars['Int']
};


export type QueryReferralCodeArgs = {
  id: Scalars['Int']
};


export type QuerySearchReferralCodesArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QuerySchoolArgs = {
  id: Scalars['String']
};


export type QuerySearchSchoolsArgs = {
  term: Scalars['String']
};


export type QuerySchoolStatsArgs = {
  id: Scalars['String']
};


export type QueryThemeArgs = {
  id: Scalars['Int']
};


export type QuerySearchThemesArgs = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type QueryGetUserArgs = {
  userId: Scalars['String']
};


export type QueryGetUserSummaryArgs = {
  userId: Scalars['String']
};


export type QuerySearchUsersArgs = {
  term: Scalars['String']
};


export type QueryAmbassadorStatusArgs = {
  userId: Scalars['String']
};

export type ReducerComp = Component & {
   __typename?: 'ReducerComp',
  props: ReducerCompProps,
  hubs: ReducerCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type ReducerCompHubs = ComponentHubs & {
   __typename?: 'ReducerCompHubs',
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  inputs: ArrayHub,
};

export type ReducerCompOutput = {
   __typename?: 'ReducerCompOutput',
  value: Variant,
};

export type ReducerCompProps = {
   __typename?: 'ReducerCompProps',
  name: Scalars['String'],
  ignoreBlocked: Scalars['Boolean'],
  reducerType: Scalars['String'],
};

export type ReferralCode = {
   __typename?: 'ReferralCode',
  id: Scalars['Int'],
  code: Scalars['String'],
  organizationId: Scalars['Int'],
  isEnabled: Scalars['Boolean'],
  url?: Maybe<Scalars['String']>,
  endUserAccess?: Maybe<Scalars['String']>,
  discount: Scalars['BigDecimal'],
  discountType: DiscountType,
  revenueShare: Scalars['BigDecimal'],
  announcement: ReferralCode_Announcement,
  beacon: ReferralCode_Beacon,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
};

export type ReferralCode_Announcement = {
   __typename?: 'ReferralCode_Announcement',
  enabled: Scalars['Boolean'],
  bannerBackground: Scalars['String'],
  bannerVaultMeLogoVersion: ReferralCodeAnnouncementBannerVaultMeLogoVersion,
  bannerOrganizationLogoId?: Maybe<Scalars['String']>,
  bannerOrganizationLogoWidth: Scalars['Float'],
  bannerOrganizationLogoOffsetXRow: Scalars['Float'],
  bannerOrganizationLogoOffsetYRow: Scalars['Float'],
  bannerOrganizationLogoOffsetXColumn: Scalars['Float'],
  bannerOrganizationLogoOffsetYColumn: Scalars['Float'],
  titleBackground: Scalars['String'],
  title: Scalars['String'],
  bodyBackground: Scalars['String'],
  bodyDesktop: Scalars['String'],
  bodyMobile: Scalars['String'],
  buttonColor: Scalars['String'],
  buttonVersion: ReferralCodeAnnouncementButtonVersion,
  bannerOrganizationLogo?: Maybe<Upload>,
};

export type ReferralCode_Beacon = {
   __typename?: 'ReferralCode_Beacon',
  enabled: Scalars['Boolean'],
  logoId?: Maybe<Scalars['String']>,
  logoWidth: Scalars['Float'],
  logo?: Maybe<Upload>,
};

export enum ReferralCodeAnnouncementBannerVaultMeLogoVersion {
  DarkBlueAndBrightBlue = 'DarkBlueAndBrightBlue',
  White = 'White',
  WhiteAndBrightBlue = 'WhiteAndBrightBlue'
}

export enum ReferralCodeAnnouncementButtonVersion {
  ColoredBackgroundAndBlackText = 'ColoredBackgroundAndBlackText',
  ColoredBackgroundAndWhiteText = 'ColoredBackgroundAndWhiteText',
  NoBackgroundAndBlackText = 'NoBackgroundAndBlackText',
  NoBackgroundAndColoredText = 'NoBackgroundAndColoredText'
}

export type ReferralCodeDiscount = {
   __typename?: 'ReferralCodeDiscount',
  code: Scalars['String'],
  id: Scalars['Int'],
  percentage: Scalars['BigDecimal'],
  discountType: DiscountType,
  amount: Scalars['BigDecimal'],
};

export type ReferralCodeFormData = {
  code: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  isEnabled: Scalars['Boolean'],
  url?: Maybe<Scalars['String']>,
  endUserAccess?: Maybe<Scalars['String']>,
  discount: Scalars['BigDecimal'],
  discountType: DiscountType,
  revenueShare: Scalars['BigDecimal'],
  announcement: ReferralCodeFormData_Announcement,
  beacon: ReferralCodeFormData_Beacon,
};

export type ReferralCodeFormData_Announcement = {
  enabled: Scalars['Boolean'],
  bannerBackground: Scalars['String'],
  bannerVaultMeLogoVersion: ReferralCodeAnnouncementBannerVaultMeLogoVersion,
  bannerOrganizationLogoId?: Maybe<Scalars['String']>,
  bannerOrganizationLogoWidth: Scalars['Float'],
  bannerOrganizationLogoOffsetXRow: Scalars['Float'],
  bannerOrganizationLogoOffsetYRow: Scalars['Float'],
  bannerOrganizationLogoOffsetXColumn: Scalars['Float'],
  bannerOrganizationLogoOffsetYColumn: Scalars['Float'],
  titleBackground: Scalars['String'],
  title: Scalars['String'],
  bodyBackground: Scalars['String'],
  bodyDesktop: Scalars['String'],
  bodyMobile: Scalars['String'],
  buttonColor: Scalars['String'],
  buttonVersion: ReferralCodeAnnouncementButtonVersion,
};

export type ReferralCodeFormData_Beacon = {
  enabled: Scalars['Boolean'],
  logoId?: Maybe<Scalars['String']>,
  logoWidth: Scalars['Float'],
};

export type Relationship = {
  componentId?: Maybe<Scalars['String']>,
  serverSide: Scalars['Boolean'],
};

export type RenderedBlueprint = {
   __typename?: 'RenderedBlueprint',
  components: Array<RenderedBlueprintComponent>,
};

export type RenderedBlueprintComponent = {
   __typename?: 'RenderedBlueprintComponent',
  id: Scalars['String'],
  state: BlueprintState,
  hubs: Array<RenderedBlueprintHub>,
};

export type RenderedBlueprintHub = {
   __typename?: 'RenderedBlueprintHub',
  name: Scalars['String'],
  state: BlueprintState,
  relationships: Array<RenderedBlueprintRelationship>,
};

export type RenderedBlueprintRelationship = {
   __typename?: 'RenderedBlueprintRelationship',
  componentId: Scalars['String'],
  state: BlueprintState,
};

export type ReportItem = {
  sourceConnectionId: Scalars['String'],
  destinationConnectionId?: Maybe<Scalars['String']>,
};

export type ResolveConnectionsResult = {
   __typename?: 'ResolveConnectionsResult',
  source: ResolveConnectionsResult_Segment,
  destination: ResolveConnectionsResult_Segment,
};

export type ResolveConnectionsResult_Segment = {
   __typename?: 'ResolveConnectionsResult_Segment',
  resolved: Array<Connection>,
  notAccessible: Array<Scalars['String']>,
  wrongCloudService: Array<Connection>,
  ambiguous: Array<Scalars['String']>,
  missing: Array<Scalars['String']>,
};

export type ResolvedBlueprintState = BlueprintState & {
   __typename?: 'ResolvedBlueprintState',
  output?: Maybe<BlueprintStateOutput>,
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type ResolveIssueResult = ResolveIssueResult_NoActiveIssueFound | ResolveIssueResult_Resolved | ResolveIssueResult_PartiallyResolved;

export type ResolveIssueResult_NoActiveIssueFound = {
   __typename?: 'ResolveIssueResult_NoActiveIssueFound',
  foo: Scalars['String'],
};

export type ResolveIssueResult_PartiallyResolved = {
   __typename?: 'ResolveIssueResult_PartiallyResolved',
  issue: MigrationIssue,
};

export type ResolveIssueResult_Resolved = {
   __typename?: 'ResolveIssueResult_Resolved',
  issue: MigrationIssue,
};

export type ResolvingBlueprintState = BlueprintState & {
   __typename?: 'ResolvingBlueprintState',
  output?: Maybe<BlueprintStateOutput>,
  progress?: Maybe<Scalars['Float']>,
  type: Scalars['String'],
  errors: Array<BlueprintError>,
};

export type RestrictionComp = Component & {
   __typename?: 'RestrictionComp',
  props: RestrictionCompProps,
  hubs: RestrictionCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type RestrictionCompHubs = ComponentHubs & {
   __typename?: 'RestrictionCompHubs',
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  values: MultipleRelationshipsHub,
  threshold: ExactlyOneRelationshipHub,
};

export type RestrictionCompOutput = {
   __typename?: 'RestrictionCompOutput',
  restrictionState: Scalars['Boolean'],
  value: Variant,
  threshold: Variant,
};

export type RestrictionCompProps = {
   __typename?: 'RestrictionCompProps',
  name: Scalars['String'],
  isCritical: Scalars['Boolean'],
  acceptableResults: Array<Maybe<Scalars['Int']>>,
  comparatorType: Scalars['String'],
};

export type RevealAccessKeyResult = {
   __typename?: 'RevealAccessKeyResult',
  connection: Connection,
  revealedAccessKey: RevealedAccessKey,
};

export type RevealedAccessKey = {
   __typename?: 'RevealedAccessKey',
  accessKeyVersion?: Maybe<Scalars['String']>,
  accessKey?: Maybe<Scalars['String']>,
  accessKeyStatus: AccessKeyStatus,
  accessKeyParamsVersion: Scalars['String'],
  accessKeyParams: Scalars['String'],
  currentPermissions?: Maybe<Array<Scalars['String']>>,
  currentRoles?: Maybe<Array<Scalars['String']>>,
  validatedPermissions?: Maybe<Array<Scalars['String']>>,
  missingPermissions?: Maybe<Array<Scalars['String']>>,
};

export type RevenueShare = {
   __typename?: 'RevenueShare',
  sourceType: RevenueShareSourceType,
  sourceId: Scalars['String'],
  percentage: Scalars['BigDecimal'],
  amount: Scalars['BigDecimal'],
};

export enum RevenueShareSourceType {
  Program = 'Program',
  ReferralCode = 'ReferralCode'
}

export type RoleComp = Component & {
   __typename?: 'RoleComp',
  props: RoleCompProps,
  hubs: RoleCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type RoleCompHubs = ComponentHubs & {
   __typename?: 'RoleCompHubs',
  roles: ExactlyOneRelationshipHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type RoleCompOutput = {
   __typename?: 'RoleCompOutput',
  conditionState: Scalars['Boolean'],
};

export type RoleCompProps = {
   __typename?: 'RoleCompProps',
  roleId: Scalars['String'],
};

export type RootEndpointComp = Component & {
   __typename?: 'RootEndpointComp',
  props: EndpointCompProps,
  hubs: RootEndpointCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type RootEndpointCompHubs = ComponentHubs & {
   __typename?: 'RootEndpointCompHubs',
  vars: VarsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  accessKey: ExactlyOneRelationshipHub,
};

export type RootEndpointCompOutput = {
   __typename?: 'RootEndpointCompOutput',
  endpointDefJson: Scalars['String'],
  endpointRef: Scalars['String'],
};

export type Scan = {
   __typename?: 'Scan',
  id: Scalars['Int'],
  userId: Scalars['String'],
  connectionId: Scalars['String'],
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntry>,
  status: ScanStatus,
  createdAt: Scalars['Instant'],
  completedAt?: Maybe<Scalars['Instant']>,
  jobId: Scalars['String'],
  workflowUrl: Scalars['String'],
  error?: Maybe<Scalars['String']>,
};

export enum ScanStatus {
  Working = 'Working',
  Failure = 'Failure',
  Success = 'Success'
}

export type School = {
   __typename?: 'School',
  id: Scalars['String'],
  domains: Scalars['String'],
  officialName?: Maybe<Scalars['String']>,
  commonName?: Maybe<Scalars['String']>,
  mascot?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  logoWidth?: Maybe<Scalars['BigDecimal']>,
  landingPageUrl?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
  ncesUrl?: Maybe<Scalars['String']>,
  avgAnnualGrads?: Maybe<Scalars['Int']>,
  usesGoogle?: Maybe<Scalars['Boolean']>,
  usesMicrosoft?: Maybe<Scalars['Boolean']>,
  welcomeMessage?: Maybe<Scalars['String']>,
  reviewed: Scalars['Boolean'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
  logo?: Maybe<Upload>,
};

export type SchoolAccountActionStats = {
   __typename?: 'SchoolAccountActionStats',
  date: Scalars['Instant'],
  action: AccountAction,
  count: Scalars['Int'],
};

export type SchoolFormData = {
  domains: Scalars['String'],
  officialName?: Maybe<Scalars['String']>,
  commonName?: Maybe<Scalars['String']>,
  mascot?: Maybe<Scalars['String']>,
  logoId?: Maybe<Scalars['String']>,
  logoWidth?: Maybe<Scalars['BigDecimal']>,
  landingPageUrl?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
  ncesUrl?: Maybe<Scalars['String']>,
  avgAnnualGrads?: Maybe<Scalars['Int']>,
  usesGoogle?: Maybe<Scalars['Boolean']>,
  usesMicrosoft?: Maybe<Scalars['Boolean']>,
  welcomeMessage?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  reviewed: Scalars['Boolean'],
};

export type SchoolStats = {
   __typename?: 'SchoolStats',
  schoolId: Scalars['String'],
  migrationCount: Scalars['Int'],
  ambassadorCount: Scalars['Int'],
  accountActionStats: Array<SchoolAccountActionStats>,
};

export type SelectorHub = Hub & {
   __typename?: 'SelectorHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type SendCouponCodeResult = {
   __typename?: 'SendCouponCodeResult',
  emailAddress: Scalars['String'],
  result: Scalars['String'],
};


export type Session = {
   __typename?: 'Session',
  user: User,
  elevated: Scalars['Boolean'],
  idleTimeout: Scalars['Long'],
};

export enum SignInContextType {
  Other = 'Other',
  Increment = 'Increment',
  Source = 'Source',
  Destination = 'Destination',
  App = 'App'
}

export type SignInFormConfig = {
  providerId: Scalars['String'],
};

export type SignInFormCustomization = {
  vendorName?: Maybe<Scalars['String']>,
  serviceName?: Maybe<Scalars['String']>,
  introHtml?: Maybe<Scalars['String']>,
  introHelpArticle?: Maybe<ExternalHelpArticle>,
  inlineHtml?: Maybe<Scalars['String']>,
  inlineHelpArticle?: Maybe<ExternalHelpArticle>,
  footerHtml?: Maybe<Scalars['String']>,
  footerHelpArticle?: Maybe<ExternalHelpArticle>,
  howToSwitchAccountHelpArticle?: Maybe<ExternalHelpArticle>,
};

export type SimplePricingModelCalculator = {
   __typename?: 'SimplePricingModelCalculator',
  pricingModelName: Scalars['String'],
  basePriceDescription: Scalars['String'],
  previousMigrationFromSource?: Maybe<Scalars['String']>,
  previousMigrationFromSourceToDestination?: Maybe<Scalars['String']>,
  programId?: Maybe<Scalars['String']>,
  firstMigrationBasePrice: Scalars['BigDecimal'],
  numberOfGbsIncluded: Scalars['Int'],
  numberOfItemsIncluded: Scalars['Int'],
  followUpMigration?: Maybe<SimplePricingModelCalculator_FollowUpMigration>,
  originalBasePrice: Scalars['BigDecimal'],
  originalPricePerGb: Scalars['BigDecimal'],
  originalPricePerThousandItems: Scalars['BigDecimal'],
  programDiscount?: Maybe<SimplePricingModelCalculator_ProgramDiscount>,
  finalBasePrice: Scalars['BigDecimal'],
  dataFeeProgramDiscount?: Maybe<Scalars['BigDecimal']>,
  sponsorshipConfig?: Maybe<SponsorshipConfig>,
};

export type SimplePricingModelCalculator_FollowUpMigration = {
   __typename?: 'SimplePricingModelCalculator_FollowUpMigration',
  previousMigrationFromSourceToDestination: Scalars['String'],
};

export type SimplePricingModelCalculator_ProgramDiscount = {
   __typename?: 'SimplePricingModelCalculator_ProgramDiscount',
  programName: Scalars['String'],
  percentage: Scalars['BigDecimal'],
  discountType: DiscountType,
};

export type SinkComp = Component & {
   __typename?: 'SinkComp',
  props: SinkCompProps,
  hubs: SinkCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type SinkCompHubs = ComponentHubs & {
   __typename?: 'SinkCompHubs',
  migrationFlows: MigrationFlowsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type SinkCompOutput = {
   __typename?: 'SinkCompOutput',
  totalBytes?: Maybe<CollectableInteger>,
  totalItems?: Maybe<CollectableItemBreakdown>,
  maxPathLength?: Maybe<CollectableInteger>,
  itemTypes: Array<ItemType>,
};

export type SinkCompProps = {
   __typename?: 'SinkCompProps',
  internalId: Scalars['String'],
  appTitle?: Maybe<Scalars['String']>,
  mainSubject: Scalars['String'],
  title: Scalars['String'],
  description: Scalars['String'],
  order: Scalars['Int'],
  itemTypes: Array<ItemType>,
};

export type SponsorshipConfig = {
   __typename?: 'SponsorshipConfig',
  programName: Scalars['String'],
  maxSponsoredAmount: Scalars['BigDecimal'],
};

export type StandardComponentHubs = ComponentHubs & {
   __typename?: 'StandardComponentHubs',
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type StandardHubProps = {
   __typename?: 'StandardHubProps',
  proposedRelationshipTitle?: Maybe<Scalars['String']>,
};

export type StorageRestrictionComp = Component & {
   __typename?: 'StorageRestrictionComp',
  props: StorageRestrictionCompProps,
  hubs: StorageRestrictionCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type StorageRestrictionCompHubs = ComponentHubs & {
   __typename?: 'StorageRestrictionCompHubs',
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
  required: MultipleRelationshipsHub,
  available: OptionalRelationshipHub,
};

export type StorageRestrictionCompOutput = {
   __typename?: 'StorageRestrictionCompOutput',
  restrictionState: Scalars['Boolean'],
  required?: Maybe<CollectableInteger>,
  available?: Maybe<CollectableInteger>,
};

export type StorageRestrictionCompProps = {
   __typename?: 'StorageRestrictionCompProps',
  storageType?: Maybe<Scalars['String']>,
  headroom: Scalars['Float'],
  helpArticle?: Maybe<ExternalHelpArticle>,
};

export type StrictOption = {
   __typename?: 'StrictOption',
  value?: Maybe<Variant>,
};

export type Subscription = {
   __typename?: 'Subscription',
  batchesUpdated: BatchEvent,
  factsUpdated: FactEvent,
  subscribeForMigrationNoteListEvents: MigrationNoteListEvent,
  subscribeForMigrationEvents: MigrationEvent,
  subscribeForMigrationIssueListEvents: MigrationIssueListEvent,
};


export type SubscriptionBatchesUpdatedArgs = {
  eventNames?: Maybe<Array<Scalars['String']>>,
  batchIds: Array<Scalars['String']>
};


export type SubscriptionFactsUpdatedArgs = {
  eventNames?: Maybe<Array<Scalars['String']>>,
  factIds: Array<Scalars['String']>
};


export type SubscriptionSubscribeForMigrationNoteListEventsArgs = {
  eventNames?: Maybe<Array<Scalars['String']>>,
  migrationIds: Array<Scalars['String']>
};


export type SubscriptionSubscribeForMigrationEventsArgs = {
  eventNames?: Maybe<Array<Scalars['String']>>,
  migrationIds: Array<Scalars['String']>
};


export type SubscriptionSubscribeForMigrationIssueListEventsArgs = {
  eventNames?: Maybe<Array<Scalars['String']>>,
  migrationIds: Array<Scalars['String']>
};

export type TaskHistory = {
   __typename?: 'TaskHistory',
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  task: TaskHistoryRecord,
  taskHistory: Array<TaskHistoryRecord>,
  taskIssues: Array<TaskIssue>,
};

export type TaskHistoryRecord = {
   __typename?: 'TaskHistoryRecord',
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  transition: Scalars['Int'],
  type: Scalars['String'],
  internalStatus: InternalJobStatus,
  propsVersion: Scalars['String'],
  propsJson: Scalars['String'],
  stateVersion: Scalars['String'],
  stateJson: Scalars['String'],
  resultVersion?: Maybe<Scalars['String']>,
  resultJson?: Maybe<Scalars['String']>,
  abortReason?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  updatedBy?: Maybe<Scalars['String']>,
  updateSummary?: Maybe<Scalars['String']>,
  minorUpdate: Scalars['Boolean'],
  completedAt?: Maybe<Scalars['Instant']>,
  archivedAt?: Maybe<Scalars['Instant']>,
};

export type TaskIssue = {
   __typename?: 'TaskIssue',
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  transition: Scalars['Int'],
  issueId: Scalars['String'],
  summary: Scalars['String'],
  details?: Maybe<Scalars['String']>,
  createdAt: Scalars['Instant'],
  confirmedAt?: Maybe<Scalars['Instant']>,
  timesConfirmed: Scalars['Int'],
  notifiedAt?: Maybe<Scalars['Instant']>,
  timesNotified: Scalars['Int'],
  resolvedAt?: Maybe<Scalars['Instant']>,
};

export enum TaskResultType {
  Success = 'Success',
  Failure = 'Failure',
  Abort = 'Abort'
}

export type TaskSummaryRecord = {
   __typename?: 'TaskSummaryRecord',
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  transition: Scalars['Int'],
  type: Scalars['String'],
  internalStatus: InternalJobStatus,
  resultType?: Maybe<TaskResultType>,
  createdAt: Scalars['Instant'],
  completedAt?: Maybe<Scalars['Instant']>,
  archivedAt?: Maybe<Scalars['Instant']>,
};

export type Theme = {
   __typename?: 'Theme',
  id: Scalars['Int'],
  name: Scalars['String'],
  primary: Scalars['String'],
  darkPrimary?: Maybe<Scalars['String']>,
  darkerPrimary?: Maybe<Scalars['String']>,
  lightPrimary?: Maybe<Scalars['String']>,
  faintPrimary?: Maybe<Scalars['String']>,
  red?: Maybe<Scalars['String']>,
  lightRed?: Maybe<Scalars['String']>,
  yellow?: Maybe<Scalars['String']>,
  lightYellow?: Maybe<Scalars['String']>,
  orange?: Maybe<Scalars['String']>,
  lightOrange?: Maybe<Scalars['String']>,
  green?: Maybe<Scalars['String']>,
  lightGreen?: Maybe<Scalars['String']>,
  titleColor?: Maybe<Scalars['String']>,
  textColor?: Maybe<Scalars['String']>,
  useMonochromeLogo: Scalars['Boolean'],
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  notes?: Maybe<Scalars['String']>,
};

export type ThemeFormData = {
  name: Scalars['String'],
  notes?: Maybe<Scalars['String']>,
  primary: Scalars['String'],
  darkPrimary?: Maybe<Scalars['String']>,
  darkerPrimary?: Maybe<Scalars['String']>,
  lightPrimary?: Maybe<Scalars['String']>,
  faintPrimary?: Maybe<Scalars['String']>,
  red?: Maybe<Scalars['String']>,
  lightRed?: Maybe<Scalars['String']>,
  yellow?: Maybe<Scalars['String']>,
  lightYellow?: Maybe<Scalars['String']>,
  orange?: Maybe<Scalars['String']>,
  lightOrange?: Maybe<Scalars['String']>,
  green?: Maybe<Scalars['String']>,
  lightGreen?: Maybe<Scalars['String']>,
  titleColor?: Maybe<Scalars['String']>,
  textColor?: Maybe<Scalars['String']>,
  useMonochromeLogo: Scalars['Boolean'],
};

export type TimeEstimateComp = Component & {
   __typename?: 'TimeEstimateComp',
  props: TimeEstimateCompProps,
  hubs: TimeEstimateCompHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type TimeEstimateCompHubs = ComponentHubs & {
   __typename?: 'TimeEstimateCompHubs',
  totalBytes: MultipleRelationshipsHub,
  totalItems: MultipleRelationshipsHub,
  migrationProgresses: AssociationsHub,
  preconditions: PreconditionsHub,
  associations: AssociationsHub,
};

export type TimeEstimateCompOutput = {
   __typename?: 'TimeEstimateCompOutput',
  totalBytes?: Maybe<CollectableInteger>,
  totalItems?: Maybe<CollectableItemBreakdown>,
  fallbackEstimate?: Maybe<Scalars['Int']>,
  params?: Maybe<Scalars['String']>,
};

export type TimeEstimateCompProps = {
   __typename?: 'TimeEstimateCompProps',
  title: Scalars['String'],
  connector: Scalars['String'],
  serviceProvider?: Maybe<Scalars['String']>,
  fallback: Scalars['String'],
  estimatedTimeFactRef: FactRef,
  elapsedTimeFactRef: FactRef,
};

export type UnregisteredOutputType = {
   __typename?: 'UnregisteredOutputType',
  class: Scalars['String'],
  asString: Scalars['String'],
};

export type Upload = {
   __typename?: 'Upload',
  id: Scalars['String'],
  name: Scalars['String'],
  length: Scalars['Long'],
};

export type User = {
   __typename?: 'User',
  id: Scalars['String'],
  personalInfo: PersonalInfo,
  customerDetails?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  type: UserType,
  createdForUserId?: Maybe<Scalars['String']>,
  ambassadorsProgramMembership?: Maybe<User_AmbassadorsProgramMembership>,
  createdAt: Scalars['Instant'],
  updatedAt: Scalars['Instant'],
  signedInAt: Scalars['Instant'],
};

export type User_AmbassadorsProgramMembership = {
   __typename?: 'User_AmbassadorsProgramMembership',
  code: Scalars['String'],
  signedUpAt: Scalars['Instant'],
  agreementAccepted: Scalars['String'],
  signUpDiscountMigrationId?: Maybe<Scalars['String']>,
};

export type UserSummary = {
   __typename?: 'UserSummary',
  id: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  emailAddress?: Maybe<Scalars['String']>,
};

export enum UserType {
  Admin = 'Admin',
  Personal = 'Personal',
  Temporary = 'Temporary',
  Business = 'Business'
}

export type ValidateAmbassadorCodeResult = {
   __typename?: 'ValidateAmbassadorCodeResult',
  ambassadorCode: Scalars['String'],
  referralCode?: Maybe<ReferralCode>,
};

export type ValidateReferralCodeResult = ValidateReferralCodeResult_Success | ValidateReferralCodeResult_NotEligible;

export type ValidateReferralCodeResult_NotEligible = {
   __typename?: 'ValidateReferralCodeResult_NotEligible',
  referralCode: ReferralCode,
  governingOrganizationName: Scalars['String'],
};

export type ValidateReferralCodeResult_Success = {
   __typename?: 'ValidateReferralCodeResult_Success',
  referralCode: ReferralCode,
};

export type VariableComp = Component & {
   __typename?: 'VariableComp',
  props: VariableCompProps,
  hubs: StandardComponentHubs,
  id: Scalars['String'],
  serverSide: Scalars['Boolean'],
  segment: Scalars['String'],
  settings: ComponentSettings,
  tags: Array<Scalars['String']>,
};

export type VariableCompOutput = {
   __typename?: 'VariableCompOutput',
  value?: Maybe<Scalars['String']>,
};

export type VariableCompProps = {
   __typename?: 'VariableCompProps',
  inputId: Scalars['String'],
  emptyValues: Array<Scalars['String']>,
};

export type Variant = VariantBoolean | VariantLong | CollectableBoolean | CollectableInteger | CollectableItemBreakdown;

export type VariantBoolean = {
   __typename?: 'VariantBoolean',
  booleanValue: Scalars['Boolean'],
};

export type VariantLong = {
   __typename?: 'VariantLong',
  longValue: Scalars['Long'],
};

export type VarsHub = Hub & {
   __typename?: 'VarsHub',
  props: StandardHubProps,
  serverSide: Scalars['Boolean'],
  relationships: Array<Relationship>,
};

export type WorkflowDef = {
   __typename?: 'WorkflowDef',
  endpointFamilyDef: Scalars['String'],
  workflowDef: Scalars['String'],
};
export type AccessListFragment = (
  { __typename?: 'AccessList' }
  & Pick<AccessList, 'id' | 'organizationId' | 'name' | 'notes' | 'isEnabled' | 'isProgramAllowlist' | 'blockingMessage' | 'googleGroupId' | 'currentUpdateId' | 'lastUpdateStartedAt' | 'createdAt' | 'updatedAt'>
  & { program: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
  )> }
);

export type AccessListUpdateFragment = (
  { __typename?: 'AccessListUpdate' }
  & Pick<AccessListUpdate, 'id' | 'accessListId' | 'status' | 'addedEntries' | 'removedEntries' | 'skippedEntries' | 'errors' | 'errorMessage' | 'createdAt' | 'updatedAt' | 'completedAt'>
);

export type AmbassadorCodeDiscountFragment = (
  { __typename?: 'AmbassadorCodeDiscount' }
  & Pick<AmbassadorCodeDiscount, 'code' | 'percentage' | 'amount'>
);

export type AmbassadorStatusDiscountFragment = (
  { __typename?: 'AmbassadorStatusDiscount' }
  & Pick<AmbassadorStatusDiscount, 'amount' | 'isReturningAmbassadorDiscount' | 'returningAmbassadorPercentage'>
);

export type AppBootstrapConfigFragment = (
  { __typename?: 'AppBootstrapConfig' }
  & Pick<AppBootstrapConfig, 'development' | 'production' | 'microsoftAppId' | 'microsoftSignInButtonCss' | 'microsoftSignInButtonIcon' | 'microsoftOAuthCallbackPath' | 'googleMerchantId' | 'enableVenmo'>
  & { cloudServices: Array<(
    { __typename?: 'CloudServiceCategory' }
    & Pick<CloudServiceCategory, 'title'>
    & { cloudServices: Array<(
      { __typename?: 'CloudService' }
      & Pick<CloudService, 'id' | 'name' | 'logo' | 'icon' | 'color' | 'authProviderId' | 'accessKeyParams'>
    )> }
  )>, authProviders: Array<(
    { __typename?: 'AuthProvider' }
    & Pick<AuthProvider, 'id' | 'roles' | 'accessKeyValidationCapabilities'>
  )>, ambassadorsProgram: (
    { __typename?: 'AppBootstrapConfig_AmbassadorsProgram' }
    & Pick<AppBootstrapConfig_AmbassadorsProgram, 'link' | 'signUpDiscount' | 'codeDiscount' | 'minNumberOfContacts' | 'maxNumberOfContacts' | 'contestEntryExpirationMonths'>
  ), program: Maybe<{ __typename?: 'Program' }
    & ProgramSummaryFragment
  >, configuration: Maybe<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'headerSuffix' | 'mainLogoId' | 'escapeLinkText' | 'introBannerId' | 'introTitle' | 'introContent' | 'pricingPageBannerId' | 'pricingPageTitle' | 'pricingPageContent' | 'sourceCloudServiceReference' | 'destinationCloudServiceReference' | 'enableFeedback' | 'feedbackQuestion' | 'checkbox1Label' | 'checkbox1Required' | 'checkbox2Label' | 'checkbox2Required' | 'checkbox3Label' | 'checkbox3Required'>
    & { cloudServices: Array<(
      { __typename?: 'ConfigurationCloudService' }
      & Pick<ConfigurationCloudService, 'isSource' | 'cloudServiceId' | 'index' | 'reference' | 'description' | 'logoId' | 'excludedApps'>
    )> }
  )>, theme: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )
    & ThemeOverridesFragment
  >, referralCode: Maybe<{ __typename?: 'ReferralCode' }
    & ReferralCodeSummaryFragment
  > }
);

export type BatchFragment = (
  { __typename?: 'Batch' }
  & Pick<Batch, 'id' | 'userId' | 'sourceCloudServiceId' | 'destinationCloudServiceId' | 'name' | 'status' | 'createdAt' | 'updatedAt' | 'startedAt' | 'completedAt' | 'jobId'>
  & { batchOrder: { __typename?: 'BatchOrder' }
    & BatchOrderFragment
   }
);

export type BatchMigrationsFragment = (
  { __typename?: 'Batch' }
  & { migrations: Array<{ __typename?: 'Migration' }
    & MigrationFragment
    & MigrationConnectionsFragment
  > }
);

export type BatchOrderFragment = (
  { __typename?: 'BatchOrder' }
  & Pick<BatchOrder, 'pricingModelName' | 'pricingModelType' | 'pricingModelVersion' | 'pricingModelJson' | 'totalAccounts' | 'totalBytes' | 'totalItems' | 'accountsFee' | 'bytesFee' | 'itemsFee' | 'priceBeforeDiscounts' | 'amountToBePaid' | 'paidAmount' | 'priceBreakdown' | 'transactionId' | 'braintreeTransactionDetailsUrl'>
  & { couponCodeDiscount: Maybe<{ __typename?: 'CouponCodeDiscount' }
    & CouponCodeDiscountFragment
  >, referralCodeDiscount: Maybe<{ __typename?: 'ReferralCodeDiscount' }
    & ReferralCodeDiscountFragment
  > }
);

export type BatchOrderRevenueFragment = (
  { __typename?: 'BatchOrder' }
  & Pick<BatchOrder, 'revenue'>
  & { revenueShare: Maybe<{ __typename?: 'RevenueShare' }
    & RevenueShareFragment
  > }
);

export type ConfigurationFragment = (
  { __typename?: 'Configuration' }
  & Pick<Configuration, 'id' | 'name' | 'alias' | 'notes' | 'themeId' | 'headerSuffix' | 'escapeLinkText' | 'introTitle' | 'introContent' | 'pricingPageTitle' | 'pricingPageContent' | 'sourceCloudServiceReference' | 'destinationCloudServiceReference' | 'enableFeedback' | 'feedbackQuestion' | 'checkbox1Label' | 'checkbox1Required' | 'checkbox2Label' | 'checkbox2Required' | 'checkbox3Label' | 'checkbox3Required' | 'createdAt' | 'updatedAt'>
  & { mainLogo: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  >, introBanner: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  >, pricingPageBanner: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  >, cloudServices: Array<{ __typename?: 'ConfigurationCloudService' }
    & ConfigurationCloudServiceFragment
  > }
);

export type ConfigurationCloudServiceFragment = (
  { __typename?: 'ConfigurationCloudService' }
  & Pick<ConfigurationCloudService, 'id' | 'configurationId' | 'isSource' | 'cloudServiceId' | 'index' | 'reference' | 'description' | 'excludedApps' | 'createdAt' | 'updatedAt'>
  & { logo: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  > }
);

export type CouponCodeFragment = (
  { __typename?: 'CouponCode' }
  & Pick<CouponCode, 'id' | 'notes' | 'enabled' | 'discount' | 'isPercentage' | 'remainingUses' | 'lastUsedFor' | 'createdAt' | 'updatedAt'>
);

export type CouponCodeDiscountFragment = (
  { __typename?: 'CouponCodeDiscount' }
  & Pick<CouponCodeDiscount, 'code' | 'value' | 'isPercentage' | 'amount'>
);

export type EmailAddressWhitelistingForProgramFragment = (
  { __typename?: 'EmailAddressWhitelistingForProgram' }
  & Pick<EmailAddressWhitelistingForProgram, 'emailAddress'>
  & { program: Maybe<{ __typename?: 'Program' }
    & ProgramFragment
  > }
);

export type EmailDeliveryScheduleFragment = (
  { __typename?: 'EmailDeliverySchedule' }
  & Pick<EmailDeliverySchedule, 'id' | 'name' | 'description' | 'isTest'>
);

export type EmailPreviewFragment = (
  { __typename?: 'EmailPreview' }
  & Pick<EmailPreview, 'from' | 'to' | 'subject' | 'body'>
);

export type GoogleGroupFragment = (
  { __typename?: 'GoogleGroup' }
  & Pick<GoogleGroup, 'internalId' | 'friendlyId' | 'name' | 'description'>
);

export type IterationFragment = (
  { __typename?: 'Iteration' }
  & Pick<Iteration, 'migrationId' | 'index' | 'status' | 'createdAt' | 'updatedAt' | 'startedAt' | 'completedAt'>
  & { stats: { __typename?: 'MigrationStats' }
    & MigrationStatsFragment
   }
);

export type MigrationFragment = (
  { __typename?: 'Migration' }
  & Pick<Migration, 'id' | 'userId' | 'sourceCloudServiceId' | 'destinationCloudServiceId' | 'sourceConnectionId' | 'destinationConnectionId' | 'totalBytesEstimate' | 'totalItemsEstimate' | 'timeEstimate' | 'iteration' | 'status' | 'autoResumeAt' | 'isAutoResumingEnabled' | 'optimisticRemainingTime' | 'longestTrackProgress' | 'totalManualReviews' | 'supervisedBy' | 'reportUrl' | 'createdAt' | 'updatedAt' | 'startedAt' | 'completedAt' | 'iterationStartedAt' | 'iterationCompletedAt' | 'jobId' | 'workflowUrl'>
  & { batch: Maybe<(
    { __typename?: 'BatchBinding' }
    & Pick<BatchBinding, 'batchId' | 'index'>
  )>, blueprintInputs: Array<(
    { __typename?: 'BlueprintInputsEntry' }
    & Pick<BlueprintInputsEntry, 'key' | 'value'>
  )>, cumulativeStats: { __typename?: 'MigrationStats' }
    & MigrationStatsFragment
  , iterationStats: { __typename?: 'MigrationStats' }
    & MigrationStatsFragment
  , itemsForManualReview: Maybe<Array<(
    { __typename?: 'ManualReviewItem' }
    & Pick<ManualReviewItem, 'phase' | 'itemType' | 'count'>
  )>> }
);

export type MigrationConnectionsFragment = (
  { __typename?: 'Migration' }
  & { sourceConnection: { __typename?: 'Connection' }
    & ConnectionFragment
  , destinationConnection: { __typename?: 'Connection' }
    & ConnectionFragment
   }
);

export type MigrationOrderSummaryFragment = (
  { __typename?: 'Migration' }
  & { orderSummary: Maybe<{ __typename?: 'OrderSummary' }
    & OrderSummaryFragment
  > }
);

export type MigrationIssueFragment = (
  { __typename?: 'MigrationIssue' }
  & Pick<MigrationIssue, 'id' | 'migrationId' | 'class' | 'message' | 'errorJson' | 'endpointAlias' | 'requiredAction' | 'isBlocking' | 'durationMillis' | 'willBeAutoResolvedAt' | 'createdAt' | 'updatedAt' | 'resolvedAt' | 'ignoreUntil'>
);

export type MigrationIssueListFragment = (
  { __typename?: 'MigrationIssueList' }
  & Pick<MigrationIssueList, 'migrationId'>
  & { issues: Array<{ __typename?: 'MigrationIssue' }
    & MigrationIssueFragment
  > }
);

export type MigrationNoteFragment = (
  { __typename?: 'MigrationNote' }
  & Pick<MigrationNote, 'id' | 'migrationId' | 'type' | 'active' | 'message' | 'createdBy' | 'resolvedBy' | 'createdAt' | 'updatedAt' | 'resolvedAt'>
);

export type MigrationNoteListFragment = (
  { __typename?: 'MigrationNoteList' }
  & Pick<MigrationNoteList, 'migrationId' | 'activeOnly'>
  & { migrationNotes: Array<{ __typename?: 'MigrationNote' }
    & MigrationNoteFragment
  > }
);

export type MigrationStatsFragment = (
  { __typename?: 'MigrationStats' }
  & Pick<MigrationStats, 'totalBytes' | 'totalItems' | 'migratedBytes' | 'migratedItems' | 'skippedBytes' | 'skippedItems'>
);

export type OffboardingProjectFragment = (
  { __typename?: 'OffboardingProject' }
  & Pick<OffboardingProject, 'id' | 'name' | 'notes' | 'connectionId' | 'programId' | 'cutoffAt' | 'scheduleId' | 'from' | 'subject1' | 'body1' | 'subject2' | 'body2' | 'subject3' | 'body3' | 'subject4' | 'body4' | 'currentRound' | 'nextRound' | 'currentRoundTotalEmails' | 'currentRoundSentEmails' | 'currentUpdateId' | 'createdAt' | 'updatedAt' | 'jobId' | 'isEmailSendingInProgress' | 'isCompleted'>
  & { program: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
  )>, emailDeliveryError: Maybe<(
    { __typename?: 'OffboardingProject_EmailDeliveryError' }
    & Pick<OffboardingProject_EmailDeliveryError, 'type' | 'details'>
  )>, connection: Maybe<(
    { __typename?: 'Connection' }
    & Pick<Connection, 'id' | 'cloudServiceId' | 'description'>
  )>, schedule: (
    { __typename?: 'AppliedEmailDeliverySchedule' }
    & Pick<AppliedEmailDeliverySchedule, 'id' | 'name' | 'description' | 'timeUnit'>
    & { rounds: Array<(
      { __typename?: 'AppliedEmailDeliverySchedule_Round' }
      & Pick<AppliedEmailDeliverySchedule_Round, 'timeUnits' | 'scheduledFor' | 'template'>
    )> }
  ) }
);

export type OffboardingProjectStatsFragment = (
  { __typename?: 'OffboardingProjectStats' }
  & Pick<OffboardingProjectStats, 'date' | 'pending' | 'openedLink' | 'connectedAccount' | 'startedMigration' | 'completedMigration' | 'optedOut'>
);

export type OffboardingProjectUpdateFragment = (
  { __typename?: 'OffboardingProjectUpdate' }
  & Pick<OffboardingProjectUpdate, 'id' | 'offboardingProjectId' | 'status' | 'processedEntries' | 'skippedEntries' | 'errors' | 'errorMessage' | 'createdAt' | 'updatedAt' | 'completedAt'>
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'userId' | 'sourceCloudServiceId' | 'destinationCloudServiceId' | 'sourceConnectionId' | 'destinationConnectionId' | 'previousMigrationFromSource' | 'previousMigrationFromSourceToDestination' | 'programId' | 'pricingModelName' | 'pricingModelType' | 'pricingModelVersion' | 'pricingModelJson' | 'totalBytes' | 'totalItems' | 'originalBasePrice' | 'originalBytesFee' | 'originalItemsFee' | 'finalBasePrice' | 'finalBytesFee' | 'finalItemsFee' | 'priceBeforeDiscounts' | 'sponsoredAmount' | 'amountToBePaid' | 'paidAmount' | 'priceBreakdown' | 'transactionId' | 'braintreeTransactionDetailsUrl' | 'status' | 'checkbox1Label' | 'checkbox1Checked' | 'checkbox2Label' | 'checkbox2Checked' | 'checkbox3Label' | 'checkbox3Checked' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'updateSummary' | 'minorUpdate' | 'authorizedAt' | 'settledAt'>
  & { blueprintInputs: Array<(
    { __typename?: 'BlueprintInputsEntry' }
    & Pick<BlueprintInputsEntry, 'key' | 'value'>
  )>, couponCodeDiscount: Maybe<{ __typename?: 'CouponCodeDiscount' }
    & CouponCodeDiscountFragment
  >, referralCodeDiscount: Maybe<{ __typename?: 'ReferralCodeDiscount' }
    & ReferralCodeDiscountFragment
  >, ambassadorStatusDiscount: Maybe<{ __typename?: 'AmbassadorStatusDiscount' }
    & AmbassadorStatusDiscountFragment
  >, ambassadorCodeDiscount: Maybe<{ __typename?: 'AmbassadorCodeDiscount' }
    & AmbassadorCodeDiscountFragment
  > }
);

export type OrderRevenueFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'revenue'>
  & { revenueShare: Maybe<{ __typename?: 'RevenueShare' }
    & RevenueShareFragment
  > }
);

export type OrderSummaryFragment = (
  { __typename?: 'OrderSummary' }
  & Pick<OrderSummary, 'totalBytes' | 'totalItems' | 'originalBasePrice' | 'originalBytesFee' | 'originalItemsFee' | 'priceBeforeDiscounts' | 'priceAfterDiscounts' | 'amountToBePaid'>
  & { program: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'alias' | 'name' | 'status' | 'sponsoredAmount'>
  )>, priceCalculator: { __typename?: 'SimplePricingModelCalculator' }
    & PricingModelCalculatorFragment
  , couponCodeDiscount: Maybe<{ __typename?: 'CouponCodeDiscount' }
    & CouponCodeDiscountFragment
  >, referralCodeDiscount: Maybe<{ __typename?: 'ReferralCodeDiscount' }
    & ReferralCodeDiscountFragment
  >, ambassadorStatusDiscount: Maybe<{ __typename?: 'AmbassadorStatusDiscount' }
    & AmbassadorStatusDiscountFragment
  >, ambassadorCodeDiscount: Maybe<{ __typename?: 'AmbassadorCodeDiscount' }
    & AmbassadorCodeDiscountFragment
  >, usedSponsorship: Maybe<(
    { __typename?: 'OrderSummary_UsedSponsorship' }
    & Pick<OrderSummary_UsedSponsorship, 'sponsoredAmount'>
    & { sponsorshipConfig: Maybe<{ __typename?: 'SponsorshipConfig' }
      & SponsorshipConfigFragment
    > }
  )> }
);

export type OrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'notes' | 'domains' | 'createdAt' | 'activatedAt' | 'updatedAt'>
  & { logo: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  > }
)
  & OrganizationSummaryFragment
;

export type OrganizationContextFragment = (
  { __typename?: 'OrganizationContext' }
  & { organization: { __typename?: 'Organization' }
    & OrganizationSummaryFragment
  , organizationPortalUser: Maybe<{ __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
  > }
);

export type OrganizationPortalUserFragment = (
  { __typename?: 'OrganizationPortalUser' }
  & Pick<OrganizationPortalUser, 'id' | 'organizationId' | 'emailAddress' | 'role' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt' | 'signedInAt'>
);

export type OrganizationSummaryFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'alias' | 'name' | 'themeId' | 'logoId' | 'revenueSharingEnabled' | 'sponsorshipLimitAmountRemaining' | 'createdAt' | 'updatedAt'>
);

export type PersonalInfoFragment = (
  { __typename?: 'PersonalInfo' }
  & Pick<PersonalInfo, 'firstName' | 'lastName' | 'emailAddress' | 'phoneNumber' | 'payPalEmailAddress' | 'timeZone'>
);

export type PricingModelFragment = (
  { __typename?: 'PricingModel' }
  & Pick<PricingModel, 'id' | 'name' | 'basePriceDescription' | 'notes' | 'basePrice' | 'basePriceForFollowUpMigrations' | 'numberOfGbsIncluded' | 'numberOfItemsIncluded' | 'pricePerGb' | 'pricePerThousandItems' | 'createdAt' | 'updatedAt'>
);

export type PricingModelCalculatorFragment = (
  { __typename?: 'SimplePricingModelCalculator' }
  & SimplePricingModelCalculatorFragment
);

export type SimplePricingModelCalculatorFragment = (
  { __typename?: 'SimplePricingModelCalculator' }
  & Pick<SimplePricingModelCalculator, 'pricingModelName' | 'basePriceDescription' | 'previousMigrationFromSource' | 'previousMigrationFromSourceToDestination' | 'programId' | 'firstMigrationBasePrice' | 'numberOfGbsIncluded' | 'numberOfItemsIncluded' | 'originalBasePrice' | 'originalPricePerGb' | 'originalPricePerThousandItems' | 'finalBasePrice' | 'dataFeeProgramDiscount'>
  & { followUpMigration: Maybe<(
    { __typename?: 'SimplePricingModelCalculator_FollowUpMigration' }
    & Pick<SimplePricingModelCalculator_FollowUpMigration, 'previousMigrationFromSourceToDestination'>
  )>, programDiscount: Maybe<(
    { __typename?: 'SimplePricingModelCalculator_ProgramDiscount' }
    & Pick<SimplePricingModelCalculator_ProgramDiscount, 'programName' | 'percentage' | 'discountType'>
  )>, sponsorshipConfig: Maybe<{ __typename?: 'SponsorshipConfig' }
    & SponsorshipConfigFragment
  > }
);

export type ProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'notes' | 'endUserAccess' | 'sendMigrationNotificationsToAdmins' | 'sendFeedbackNotificationsToAdmins' | 'revenueShare' | 'createdAt' | 'updatedAt'>
  & { logo: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  >, introBanner: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  >, pricingPageBanner: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  > }
)
  & ProgramSummaryFragment
;

export type ProgramEligibilityFragment = (
  { __typename?: 'ProgramEligibility' }
  & Pick<ProgramEligibility, 'discount' | 'isWhitelisted'>
  & { program: { __typename?: 'Program' }
    & ProgramSummaryFragment
  , theme: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )
    & ThemeOverridesFragment
  > }
);

export type ProgramSummaryFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'organizationId' | 'alias' | 'name' | 'description' | 'productName' | 'landingPageUrl' | 'logoId' | 'configurationId' | 'introBannerId' | 'introTitle' | 'introContent' | 'pricingPageBannerId' | 'pricingPageTitle' | 'pricingPageContent' | 'pricingModelId' | 'status' | 'sourceCloudServiceId' | 'destinationCloudServiceId' | 'sourceDomains' | 'destinationDomains' | 'discount' | 'discountType' | 'sponsoredAmount'>
);

export type ReferralCodeFragment = (
  { __typename?: 'ReferralCode' }
  & Pick<ReferralCode, 'notes' | 'endUserAccess' | 'revenueShare' | 'createdAt' | 'updatedAt'>
  & { announcement: (
    { __typename?: 'ReferralCode_Announcement' }
    & { bannerOrganizationLogo: Maybe<{ __typename?: 'Upload' }
      & UploadFragment
    > }
  ), beacon: (
    { __typename?: 'ReferralCode_Beacon' }
    & { logo: Maybe<{ __typename?: 'Upload' }
      & UploadFragment
    > }
  ) }
)
  & ReferralCodeSummaryFragment
;

export type ReferralCodeDiscountFragment = (
  { __typename?: 'ReferralCodeDiscount' }
  & Pick<ReferralCodeDiscount, 'code' | 'percentage' | 'discountType' | 'amount'>
);

export type ReferralCodeSummaryFragment = (
  { __typename?: 'ReferralCode' }
  & Pick<ReferralCode, 'id' | 'code' | 'organizationId' | 'isEnabled' | 'url' | 'discount' | 'discountType'>
  & { announcement: (
    { __typename?: 'ReferralCode_Announcement' }
    & Pick<ReferralCode_Announcement, 'enabled' | 'bannerBackground' | 'bannerVaultMeLogoVersion' | 'bannerOrganizationLogoId' | 'bannerOrganizationLogoWidth' | 'bannerOrganizationLogoOffsetXRow' | 'bannerOrganizationLogoOffsetYRow' | 'bannerOrganizationLogoOffsetXColumn' | 'bannerOrganizationLogoOffsetYColumn' | 'titleBackground' | 'title' | 'bodyBackground' | 'bodyDesktop' | 'bodyMobile' | 'buttonColor' | 'buttonVersion'>
  ), beacon: (
    { __typename?: 'ReferralCode_Beacon' }
    & Pick<ReferralCode_Beacon, 'enabled' | 'logoId' | 'logoWidth'>
  ) }
);

export type RevenueShareFragment = (
  { __typename?: 'RevenueShare' }
  & Pick<RevenueShare, 'sourceType' | 'sourceId' | 'percentage' | 'amount'>
);

export type SchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'domains' | 'mascot' | 'country' | 'type' | 'website' | 'ncesUrl' | 'avgAnnualGrads' | 'usesGoogle' | 'usesMicrosoft' | 'notes' | 'reviewed' | 'createdAt' | 'updatedAt'>
  & { logo: Maybe<{ __typename?: 'Upload' }
    & UploadFragment
  > }
)
  & SchoolSummaryFragment
;

export type SchoolSummaryFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'officialName' | 'commonName' | 'logoId' | 'logoWidth' | 'landingPageUrl' | 'welcomeMessage'>
);

export type SponsorshipConfigFragment = (
  { __typename?: 'SponsorshipConfig' }
  & Pick<SponsorshipConfig, 'programName' | 'maxSponsoredAmount'>
);

export type ThemeFragment = (
  { __typename?: 'Theme' }
  & Pick<Theme, 'id' | 'name' | 'notes' | 'createdAt' | 'updatedAt'>
)
  & ThemeOverridesFragment
;

export type ThemeOverridesFragment = (
  { __typename?: 'Theme' }
  & Pick<Theme, 'primary' | 'darkPrimary' | 'darkerPrimary' | 'lightPrimary' | 'faintPrimary' | 'red' | 'lightRed' | 'yellow' | 'lightYellow' | 'orange' | 'lightOrange' | 'green' | 'lightGreen' | 'titleColor' | 'textColor' | 'useMonochromeLogo'>
);

export type UploadFragment = (
  { __typename?: 'Upload' }
  & Pick<Upload, 'id' | 'name' | 'length'>
);

export type User_AmbassadorsProgramMembershipFragment = (
  { __typename?: 'User_AmbassadorsProgramMembership' }
  & Pick<User_AmbassadorsProgramMembership, 'code' | 'signUpDiscountMigrationId'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'customerDetails' | 'enabled' | 'type' | 'createdForUserId' | 'createdAt' | 'updatedAt' | 'signedInAt'>
  & { personalInfo: { __typename?: 'PersonalInfo' }
    & PersonalInfoFragment
  , ambassadorsProgramMembership: Maybe<{ __typename?: 'User_AmbassadorsProgramMembership' }
    & User_AmbassadorsProgramMembershipFragment
  > }
);

export type DeleteAccessListMutationVariables = {
  id: Scalars['Int']
};


export type DeleteAccessListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccessList'>
);

export type DeleteAccessListEntriesMutationVariables = {
  id: Scalars['Int'],
  emailAddresses: Array<Scalars['String']>
};


export type DeleteAccessListEntriesMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccessListEntries: (
    { __typename?: 'DeleteAccessListEntriesResult' }
    & Pick<DeleteAccessListEntriesResult, 'deletedEntryCount' | 'skippedEmailAddresses'>
  ) }
);

export type DescribeGoogleGroupMutationVariables = {
  id: Scalars['String']
};


export type DescribeGoogleGroupMutation = (
  { __typename?: 'Mutation' }
  & { describeGoogleGroup: Maybe<{ __typename?: 'GoogleGroup' }
    & GoogleGroupFragment
  > }
);

export type GetAccessListQueryVariables = {
  id: Scalars['Int']
};


export type GetAccessListQuery = (
  { __typename?: 'Query' }
  & { accessList: { __typename?: 'AccessList' }
    & AccessListFragment
   }
);

export type GetAccessListEntryCountQueryVariables = {
  id: Scalars['Int']
};


export type GetAccessListEntryCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'accessListEntryCount'>
);

export type GetAccessListUpdateQueryVariables = {
  id: Scalars['Int']
};


export type GetAccessListUpdateQuery = (
  { __typename?: 'Query' }
  & { accessListUpdate: { __typename?: 'AccessListUpdate' }
    & AccessListUpdateFragment
   }
);

export type GetAccessListUpdateHistoryQueryVariables = {
  id: Scalars['Int']
};


export type GetAccessListUpdateHistoryQuery = (
  { __typename?: 'Query' }
  & { accessListUpdateHistory: Array<{ __typename?: 'AccessListUpdate' }
    & AccessListUpdateFragment
  > }
);

export type GetCurrentAccessListUpdateQueryVariables = {
  id: Scalars['Int']
};


export type GetCurrentAccessListUpdateQuery = (
  { __typename?: 'Query' }
  & { accessList: (
    { __typename?: 'AccessList' }
    & Pick<AccessList, 'id' | 'currentUpdateId'>
  ) }
);

export type GetOrganizationAccessListStatsQueryVariables = {
  organizationId: Scalars['Int']
};


export type GetOrganizationAccessListStatsQuery = (
  { __typename?: 'Query' }
  & { organizationAccessListStats: (
    { __typename?: 'OrganizationAccessListStats' }
    & Pick<OrganizationAccessListStats, 'programAccessListCount' | 'organizationBlockListCount'>
  ) }
);

export type InsertAccessListMutationVariables = {
  organizationId: Scalars['Int'],
  formData: AccessListFormData
};


export type InsertAccessListMutation = (
  { __typename?: 'Mutation' }
  & { insertAccessList: { __typename?: 'AccessList' }
    & AccessListFragment
   }
);

export type ListAccessListUpdatesQueryVariables = {
  ids: Array<Scalars['Int']>
};


export type ListAccessListUpdatesQuery = (
  { __typename?: 'Query' }
  & { accessListUpdates: Array<{ __typename?: 'AccessListUpdate' }
    & AccessListUpdateFragment
  > }
);

export type SearchAccessListsQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchAccessListsQuery = (
  { __typename?: 'Query' }
  & { searchAccessLists: Array<{ __typename?: 'AccessList' }
    & AccessListFragment
  > }
);

export type StartAccessListUpdateJobMutationVariables = {
  id: Scalars['Int']
};


export type StartAccessListUpdateJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startAccessListUpdateJob'>
);

export type UpdateAccessListMutationVariables = {
  id: Scalars['Int'],
  formData: AccessListFormData
};


export type UpdateAccessListMutation = (
  { __typename?: 'Mutation' }
  & { updateAccessList: { __typename?: 'AccessList' }
    & AccessListFragment
   }
);

export type SessionFragment = (
  { __typename?: 'Session' }
  & Pick<Session, 'elevated' | 'idleTimeout'>
  & { user: { __typename?: 'User' }
    & UserFragment
   }
);

export type GetAppBootstrapConfigQueryVariables = {
  programAlias?: Maybe<Scalars['String']>,
  configurationAlias?: Maybe<Scalars['String']>,
  themeId?: Maybe<Scalars['Int']>,
  organizationIdOrAlias?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>
};


export type GetAppBootstrapConfigQuery = (
  { __typename?: 'Query' }
  & { getAppBootstrapConfig: { __typename?: 'AppBootstrapConfig' }
    & AppBootstrapConfigFragment
  , getSession: Maybe<{ __typename?: 'Session' }
    & SessionFragment
  > }
);

export type GetSignInFormConfigQueryVariables = {
  authFlowId: Scalars['String'],
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  existingAccessKeyId?: Maybe<Scalars['String']>
};


export type GetSignInFormConfigQuery = (
  { __typename?: 'Query' }
  & { getSignInFormConfig: { __typename?: 'GetSignInFormConfigResponse' }
    & GetSignInFormConfigResponseFragment
   }
);

export type GetSignInFormConfigResponseFragment = (
  { __typename?: 'GetSignInFormConfigResponse' }
  & Pick<GetSignInFormConfigResponse, 'cloudServiceId'>
  & { configurations: Array<(
    { __typename?: 'NamedSignInFormConfig' }
    & Pick<NamedSignInFormConfig, 'name'>
    & { config: (
      { __typename?: 'GoogleSignInFormConfig' }
      & Pick<GoogleSignInFormConfig, 'providerId'>
      & GoogleSignInFormConfigFragment
    ) | (
      { __typename?: 'ImapSignInFormConfig' }
      & Pick<ImapSignInFormConfig, 'providerId'>
      & ImapSignInFormConfigFragment
    ) | (
      { __typename?: 'OAuthAndServiceAccountSignInFormConfig' }
      & Pick<OAuthAndServiceAccountSignInFormConfig, 'providerId'>
      & OAuthAndServiceAccountSignInFormConfigFragment
    ) | (
      { __typename?: 'OAuthSignInFormConfig' }
      & Pick<OAuthSignInFormConfig, 'providerId'>
      & OAuthSignInFormConfigFragment
    ) | (
      { __typename?: 'OutlookSignInFormConfig' }
      & Pick<OutlookSignInFormConfig, 'providerId'>
      & OutlookSignInFormConfigFragment
    ) }
  )>, customizations: Array<(
    { __typename?: 'NamedSignInFormCustomization' }
    & Pick<NamedSignInFormCustomization, 'name'>
    & { customization: (
      { __typename?: 'ImapSignInFormCustomization' }
      & Pick<ImapSignInFormCustomization, 'vendorName' | 'serviceName'>
      & ImapSignInFormCustomizationFragment
    ) | (
      { __typename?: 'OAuthSignInFormCustomization' }
      & Pick<OAuthSignInFormCustomization, 'vendorName' | 'serviceName'>
      & OAuthSignInFormCustomizationFragment
    ) }
  )> }
);

export type GetSignUpFormConfigQueryVariables = {
  authFlowId: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type GetSignUpFormConfigQuery = (
  { __typename?: 'Query' }
  & { getSignUpFormConfig: Array<{ __typename?: 'GetSignInFormConfigResponse' }
    & GetSignInFormConfigResponseFragment
  > }
);

export type GoogleSignInFormConfigFragment = (
  { __typename?: 'GoogleSignInFormConfig' }
  & Pick<GoogleSignInFormConfig, 'url' | 'callbackUrl' | 'allowServiceAccountSignIn'>
);

export type ImapSignInMutationVariables = {
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  signInParams: ImapSignInParams
};


export type ImapSignInMutation = (
  { __typename?: 'Mutation' }
  & { imapSignIn: (
    { __typename?: 'OneTimeAuthCodeSummary' }
    & Pick<OneTimeAuthCodeSummary, 'code' | 'expiresAt'>
  ) }
);

export type ImapSignInFormConfigFragment = (
  { __typename?: 'ImapSignInFormConfig' }
  & Pick<ImapSignInFormConfig, 'existingAccessKeyId'>
  & { credentialsDefaults: Maybe<(
    { __typename?: 'ImapCredentialsDefaults' }
    & Pick<ImapCredentialsDefaults, 'login' | 'customizable'>
  )>, connectionDefaults: Maybe<(
    { __typename?: 'ImapConnectionDefaults' }
    & Pick<ImapConnectionDefaults, 'host' | 'port' | 'customizable'>
  )> }
);

export type ImapSignInFormCustomizationFragment = (
  { __typename?: 'ImapSignInFormCustomization' }
  & { advancedSettingsHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  > }
)
  & SignInFormCustomizationFragment
;

export type OAuthAndServiceAccountSignInFormConfigFragment = (
  { __typename?: 'OAuthAndServiceAccountSignInFormConfig' }
  & Pick<OAuthAndServiceAccountSignInFormConfig, 'url' | 'callbackUrl' | 'allowServiceAccountSignIn'>
);

export type OAuthSignInFormConfigFragment = (
  { __typename?: 'OAuthSignInFormConfig' }
  & Pick<OAuthSignInFormConfig, 'url' | 'callbackUrl'>
);

export type OAuthSignInFormCustomizationFragment = (
  { __typename?: 'OAuthSignInFormCustomization' }
  & Pick<OAuthSignInFormCustomization, 'buttonCss' | 'buttonIcon'>
)
  & SignInFormCustomizationFragment
;

export type OutlookSignInFormConfigFragment = (
  { __typename?: 'OutlookSignInFormConfig' }
  & Pick<OutlookSignInFormConfig, 'emailAddress' | 'userId' | 'tenantId'>
  & { exchange: { __typename?: 'OAuthSignInFormConfig' }
    & OAuthSignInFormConfigFragment
  , imap: { __typename?: 'OAuthSignInFormConfig' }
    & OAuthSignInFormConfigFragment
   }
);

export type ServiceAccountSignInMutationVariables = {
  cloudServiceId: Scalars['String'],
  roles: Array<Scalars['String']>,
  emailAddress: Scalars['String'],
  state: Scalars['String']
};


export type ServiceAccountSignInMutation = (
  { __typename?: 'Mutation' }
  & { serviceAccountSignIn: (
    { __typename?: 'OneTimeAuthCodeSummary' }
    & Pick<OneTimeAuthCodeSummary, 'code' | 'expiresAt'>
  ) }
);

export type SignInFormCustomizationFragment = (
  { __typename?: 'ImapSignInFormCustomization' }
  & Pick<ImapSignInFormCustomization, 'vendorName' | 'serviceName' | 'introHtml' | 'inlineHtml' | 'footerHtml'>
  & { introHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, inlineHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, footerHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, howToSwitchAccountHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  > }
) | (
  { __typename?: 'OAuthSignInFormCustomization' }
  & Pick<OAuthSignInFormCustomization, 'vendorName' | 'serviceName' | 'introHtml' | 'inlineHtml' | 'footerHtml'>
  & { introHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, inlineHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, footerHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  >, howToSwitchAccountHelpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
    & ExternalHelpArticleFragment
  > }
);

export type AdvanceToNextEmailDeliveryRoundMutationVariables = {
  offboardingProjectId: Scalars['String'],
  expectedNextRound: Scalars['Int']
};


export type AdvanceToNextEmailDeliveryRoundMutation = (
  { __typename?: 'Mutation' }
  & { advanceToNextEmailDeliveryRound: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type DeleteOffboardingProjectMutationVariables = {
  id: Scalars['String']
};


export type DeleteOffboardingProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOffboardingProject'>
);

export type DeleteOffboardingProjectEntriesMutationVariables = {
  id: Scalars['String'],
  emailAddresses: Array<Scalars['String']>
};


export type DeleteOffboardingProjectEntriesMutation = (
  { __typename?: 'Mutation' }
  & { deleteOffboardingProjectEntries: (
    { __typename?: 'DeleteOffboardingProjectEntriesResult' }
    & Pick<DeleteOffboardingProjectEntriesResult, 'deletedEntryCount' | 'skippedEmailAddresses'>
  ) }
);

export type DisableOffboardingProjectEmailDeliveryMutationVariables = {
  id: Scalars['String']
};


export type DisableOffboardingProjectEmailDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { disableOffboardingProjectEmailDelivery: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type ForceStartEmailDeliveryRoundMutationVariables = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type ForceStartEmailDeliveryRoundMutation = (
  { __typename?: 'Mutation' }
  & { forceStartEmailDeliveryRound: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type GetCurrentOffboardingProjectUpdateQueryVariables = {
  id: Scalars['String']
};


export type GetCurrentOffboardingProjectUpdateQuery = (
  { __typename?: 'Query' }
  & { offboardingProject: (
    { __typename?: 'OffboardingProject' }
    & Pick<OffboardingProject, 'id' | 'currentUpdateId'>
  ) }
);

export type GetEmailDeliverySchedulesQueryVariables = {};


export type GetEmailDeliverySchedulesQuery = (
  { __typename?: 'Query' }
  & { emailDeliverySchedules: Array<{ __typename?: 'EmailDeliverySchedule' }
    & EmailDeliveryScheduleFragment
  > }
);

export type GetOffboardingProjectQueryVariables = {
  id: Scalars['String']
};


export type GetOffboardingProjectQuery = (
  { __typename?: 'Query' }
  & { offboardingProject: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type GetOffboardingProjectEntryCountQueryVariables = {
  id: Scalars['String']
};


export type GetOffboardingProjectEntryCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'offboardingProjectEntryCount'>
);

export type GetOffboardingProjectEntryParticipationStatusQueryVariables = {
  id: Scalars['String']
};


export type GetOffboardingProjectEntryParticipationStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'offboardingProjectEntryParticipationStatus'>
);

export type GetOffboardingProjectUpdateQueryVariables = {
  id: Scalars['String']
};


export type GetOffboardingProjectUpdateQuery = (
  { __typename?: 'Query' }
  & { offboardingProjectUpdate: { __typename?: 'OffboardingProjectUpdate' }
    & OffboardingProjectUpdateFragment
   }
);

export type GetOffboardingProjectUpdateHistoryQueryVariables = {
  id: Scalars['String']
};


export type GetOffboardingProjectUpdateHistoryQuery = (
  { __typename?: 'Query' }
  & { offboardingProjectUpdateHistory: Array<{ __typename?: 'OffboardingProjectUpdate' }
    & OffboardingProjectUpdateFragment
  > }
);

export type InsertOffboardingProjectMutationVariables = {
  organizationId: Scalars['Int'],
  formData: OffboardingProjectFormData
};


export type InsertOffboardingProjectMutation = (
  { __typename?: 'Mutation' }
  & { insertOffboardingProject: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type ListOffboardingProjectStatsQueryVariables = {
  id: Scalars['String']
};


export type ListOffboardingProjectStatsQuery = (
  { __typename?: 'Query' }
  & { offboardingProjectStats: Array<{ __typename?: 'OffboardingProjectStats' }
    & OffboardingProjectStatsFragment
  > }
);

export type ListOffboardingProjectUpdatesQueryVariables = {
  ids: Array<Scalars['String']>
};


export type ListOffboardingProjectUpdatesQuery = (
  { __typename?: 'Query' }
  & { offboardingProjectUpdates: Array<{ __typename?: 'OffboardingProjectUpdate' }
    & OffboardingProjectUpdateFragment
  > }
);

export type OptOutOffboardingProjectEntryMutationVariables = {
  id: Scalars['String'],
  reason: Scalars['String'],
  comment?: Maybe<Scalars['String']>
};


export type OptOutOffboardingProjectEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'optOutOffboardingProjectEntry'>
);

export type RenderOffboardingEmailPreviewMutationVariables = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type RenderOffboardingEmailPreviewMutation = (
  { __typename?: 'Mutation' }
  & { renderOffboardingEmailPreview: { __typename?: 'EmailPreview' }
    & EmailPreviewFragment
   }
);

export type RestartCurrentEmailDeliveryRoundMutationVariables = {
  offboardingProjectId: Scalars['String'],
  expectedCurrentRound: Scalars['Int']
};


export type RestartCurrentEmailDeliveryRoundMutation = (
  { __typename?: 'Mutation' }
  & { restartCurrentEmailDeliveryRound: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type SearchOffboardingProjectsQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchOffboardingProjectsQuery = (
  { __typename?: 'Query' }
  & { searchOffboardingProjects: Array<{ __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
  > }
);

export type SendTestOffboardingProjectEmailMutationVariables = {
  offboardingProjectId: Scalars['String'],
  round: Scalars['Int']
};


export type SendTestOffboardingProjectEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendTestOffboardingProjectEmail: { __typename?: 'EmailPreview' }
    & EmailPreviewFragment
   }
);

export type UnsubscribeOffboardingProjectEntryMutationVariables = {
  id: Scalars['String']
};


export type UnsubscribeOffboardingProjectEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unsubscribeOffboardingProjectEntry'>
);

export type UpdateOffboardingProjectMutationVariables = {
  id: Scalars['String'],
  formData: OffboardingProjectFormData
};


export type UpdateOffboardingProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateOffboardingProject: { __typename?: 'OffboardingProject' }
    & OffboardingProjectFragment
   }
);

export type AssignBatchMutationVariables = {
  batchId: Scalars['String'],
  userId: Scalars['String']
};


export type AssignBatchMutation = (
  { __typename?: 'Mutation' }
  & { assignBatch: { __typename?: 'Batch' }
    & BatchFragment
    & BatchMigrationsFragment
   }
);

export type BatchesUpdatedSubscriptionVariables = {
  batchIds: Array<Scalars['String']>
};


export type BatchesUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { batchesUpdated: (
    { __typename?: 'BatchEvent' }
    & Pick<BatchEvent, 'eventType'>
    & { batches: Array<{ __typename?: 'Batch' }
      & BatchFragment
    > }
  ) }
);

export type GenerateBatchReportMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<ReportItem>
};


export type GenerateBatchReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateBatchReport'>
);

export type GetBatchQueryVariables = {
  batchId: Scalars['String']
};


export type GetBatchQuery = (
  { __typename?: 'Query' }
  & { batch: { __typename?: 'Batch' }
    & BatchFragment
    & BatchMigrationsFragment
   }
);

export type GetBatchOrderQueryVariables = {
  batchId: Scalars['String']
};


export type GetBatchOrderQuery = (
  { __typename?: 'Query' }
  & { batch: (
    { __typename?: 'Batch' }
    & { batchOrder: { __typename?: 'BatchOrder' }
      & BatchOrderFragment
      & BatchOrderRevenueFragment
     }
  ) }
);

export type GetBatchesQueryVariables = {
  batchIds: Array<Scalars['String']>
};


export type GetBatchesQuery = (
  { __typename?: 'Query' }
  & { batches: Array<{ __typename?: 'Batch' }
    & BatchFragment
  > }
);

export type LaunchBatchMutationVariables = {
  batchId: Scalars['String']
};


export type LaunchBatchMutation = (
  { __typename?: 'Mutation' }
  & { launchBatch: { __typename?: 'Batch' }
    & BatchFragment
   }
);

export type PrepareBatchOrderMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>
};


export type PrepareBatchOrderMutation = (
  { __typename?: 'Mutation' }
  & { prepareBatchOrder: { __typename?: 'PrepareBatchOrderResult' }
    & PrepareBatchOrderResultFragment
   }
);

export type PrepareBatchOrderResultFragment = (
  { __typename?: 'PrepareBatchOrderResult' }
  & Pick<PrepareBatchOrderResult, 'clientToken'>
  & { batchOrder: { __typename?: 'BatchOrder' }
    & BatchOrderFragment
   }
);

export type RefreshBatchFactsMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>
};


export type RefreshBatchFactsMutation = (
  { __typename?: 'Mutation' }
  & { refreshBatchFacts: (
    { __typename?: 'GetBlueprintFactsResult' }
    & Pick<GetBlueprintFactsResult, 'scanJobIds'>
    & { facts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
      & FactOrPlaceholderFragment
    > }
  ) }
);

export type RenameBatchMutationVariables = {
  batchId: Scalars['String'],
  name: Scalars['String']
};


export type RenameBatchMutation = (
  { __typename?: 'Mutation' }
  & { renameBatch: { __typename?: 'Batch' }
    & BatchFragment
   }
);

export type ScheduleBatchMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  batchItems: Array<BatchItemSpec>,
  expectedAmountToBePaid: Scalars['BigDecimal'],
  discounts?: Maybe<Discounts>,
  braintreePayloadJson?: Maybe<Scalars['String']>,
  deviceData?: Maybe<Scalars['String']>,
  externalPayment?: Maybe<ExternalPayment>
};


export type ScheduleBatchMutation = (
  { __typename?: 'Mutation' }
  & { scheduleBatch: { __typename?: 'Batch' }
    & BatchFragment
   }
);

export type SyncUpBatchMutationVariables = {
  batchId: Scalars['String']
};


export type SyncUpBatchMutation = (
  { __typename?: 'Mutation' }
  & { syncUpBatch: { __typename?: 'Batch' }
    & BatchFragment
   }
);

export type VerifyGoogleAdminConnectionMutationVariables = {
  connectionId: Scalars['String']
};


export type VerifyGoogleAdminConnectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyGoogleAdminConnection'>
);

export type VerifyMicrosoftAdminConnectionMutationVariables = {
  connectionId: Scalars['String']
};


export type VerifyMicrosoftAdminConnectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyMicrosoftAdminConnection'>
);

export type BuildWorkflowDefQueryVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type BuildWorkflowDefQuery = (
  { __typename?: 'Query' }
  & { buildWorkflowDef: (
    { __typename?: 'WorkflowDef' }
    & Pick<WorkflowDef, 'endpointFamilyDef' | 'workflowDef'>
  ) }
);

export type CalcTimeEstimatesMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type CalcTimeEstimatesMutation = (
  { __typename?: 'Mutation' }
  & { calcTimeEstimates: Array<(
    { __typename?: 'EstimationResult' }
    & Pick<EstimationResult, 'title' | 'connector' | 'serviceProvider' | 'params'>
    & { estimate: Maybe<(
      { __typename?: 'Estimate' }
      & Pick<Estimate, 'value' | 'trainingSamples' | 'goodnessOfFit'>
    )> }
  )> }
);

export type GetMigrationBlueprintQueryVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String']
};


export type GetMigrationBlueprintQuery = (
  { __typename?: 'Query' }
  & { getMigrationBlueprint: (
    { __typename?: 'MigrationBlueprint' }
    & Pick<MigrationBlueprint, 'sourceCloudServiceId' | 'destinationCloudServiceId'>
    & { components: Array<({ __typename?: 'AreaComp' } | { __typename?: 'ChildEndpointComp' } | { __typename?: 'ConnectionComp' } | { __typename?: 'FactComp' } | { __typename?: 'MigrationProgressComp' } | { __typename?: 'PhaseComp' } | { __typename?: 'ReducerComp' } | { __typename?: 'RestrictionComp' } | { __typename?: 'RoleComp' } | { __typename?: 'RootEndpointComp' } | { __typename?: 'SinkComp' } | { __typename?: 'StorageRestrictionComp' } | { __typename?: 'TimeEstimateComp' } | { __typename?: 'VariableComp' })
      & ComponentFragment
    > }
  ) }
);

export type ComponentFragment = (
  { __typename?: 'AreaComp' }
  & Pick<AreaComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & AreaCompFragment
) | (
  { __typename?: 'ChildEndpointComp' }
  & Pick<ChildEndpointComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & ChildEndpointCompFragment
) | (
  { __typename?: 'ConnectionComp' }
  & Pick<ConnectionComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & ConnectionCompFragment
) | (
  { __typename?: 'FactComp' }
  & Pick<FactComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & FactCompFragment
) | (
  { __typename?: 'MigrationProgressComp' }
  & Pick<MigrationProgressComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & MigrationProgressCompFragment
) | (
  { __typename?: 'PhaseComp' }
  & Pick<PhaseComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & PhaseCompFragment
) | (
  { __typename?: 'ReducerComp' }
  & Pick<ReducerComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & ReducerCompFragment
) | (
  { __typename?: 'RestrictionComp' }
  & Pick<RestrictionComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & RestrictionCompFragment
) | (
  { __typename?: 'RoleComp' }
  & Pick<RoleComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & RoleCompFragment
) | (
  { __typename?: 'RootEndpointComp' }
  & Pick<RootEndpointComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & RootEndpointCompFragment
) | (
  { __typename?: 'SinkComp' }
  & Pick<SinkComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & SinkCompFragment
) | (
  { __typename?: 'StorageRestrictionComp' }
  & Pick<StorageRestrictionComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & StorageRestrictionCompFragment
) | (
  { __typename?: 'TimeEstimateComp' }
  & Pick<TimeEstimateComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & TimeEstimateCompFragment
) | (
  { __typename?: 'VariableComp' }
  & Pick<VariableComp, 'id' | 'serverSide' | 'segment' | 'tags'>
  & { settings: (
    { __typename?: 'ComponentSettings' }
    & Pick<ComponentSettings, 'sizeMultiplier' | 'icon' | 'anchor'>
    & { color: Maybe<(
      { __typename?: 'ComponentColorSchema' }
      & Pick<ComponentColorSchema, 'background' | 'border'>
    )> }
  ) }
  & VariableCompFragment
);

export type AreaCompFragment = (
  { __typename?: 'AreaComp' }
  & { areaCompProps: (
    { __typename?: 'AreaCompProps' }
    & Pick<AreaCompProps, 'internalId' | 'appTitle' | 'mainSubject' | 'title' | 'description' | 'order' | 'itemTypes'>
    & { itemTypeAliases: Array<(
      { __typename?: 'ItemTypeAlias' }
      & Pick<ItemTypeAlias, 'alias' | 'itemTypes'>
    )> }
  ), areaCompHubs: { __typename?: 'AreaCompHubs' }
    & AreaCompHubsFragment
   }
);

export type AreaCompHubsFragment = (
  { __typename?: 'AreaCompHubs' }
  & { totalBytes: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , totalItems: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , maxPathLength: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
   }
)
  & ComponentHubsFragment
;

export type ChildEndpointCompFragment = (
  { __typename?: 'ChildEndpointComp' }
  & { childEndpointCompProps: (
    { __typename?: 'EndpointCompProps' }
    & Pick<EndpointCompProps, 'title'>
  ), childEndpointCompHubs: { __typename?: 'ChildEndpointCompHubs' }
    & ChildEndpointCompHubsFragment
   }
);

export type ChildEndpointCompHubsFragment = (
  { __typename?: 'ChildEndpointCompHubs' }
  & { accessKey: { __typename?: 'ExactlyOneRelationshipHub' }
    & ExactlyOneRelationshipHubFragment
  , vars: { __typename?: 'VarsHub' }
    & VarsHubFragment
  , rootEndpointRef: { __typename?: 'ExactlyOneRelationshipHub' }
    & ExactlyOneRelationshipHubFragment
   }
)
  & ComponentHubsFragment
;

export type ConnectionCompFragment = (
  { __typename?: 'ConnectionComp' }
  & { connectionCompProps: (
    { __typename?: 'ConnectionCompProps' }
    & Pick<ConnectionCompProps, 'purpose' | 'inputId' | 'authProviderId'>
    & { branding: (
      { __typename?: 'ConnectionCompBranding' }
      & Pick<ConnectionCompBranding, 'name' | 'icon' | 'color'>
    ) }
  ), connectionCompHubs: { __typename?: 'StandardComponentHubs' }
    & ConnectionCompHubsFragment
   }
);

export type ConnectionCompHubsFragment = { __typename?: 'StandardComponentHubs' }
  & ComponentHubsFragment
;

export type FactCompFragment = (
  { __typename?: 'FactComp' }
  & { factCompProps: (
    { __typename?: 'FactCompProps' }
    & Pick<FactCompProps, 'name'>
    & { factRef: { __typename?: 'FactRef' }
      & FactRefFragment
     }
  ), factCompHubs: { __typename?: 'FactCompHubs' }
    & FactCompHubsFragment
   }
);

export type FactCompHubsFragment = (
  { __typename?: 'FactCompHubs' }
  & { phase: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
)
  & ComponentHubsFragment
;

export type MigrationProgressCompFragment = (
  { __typename?: 'MigrationProgressComp' }
  & { migrationProgressCompProps: (
    { __typename?: 'MigrationProgressCompProps' }
    & Pick<MigrationProgressCompProps, 'title' | 'itemCategory'>
    & { cumulativeFactRefs: (
      { __typename?: 'MigrationProgressCompProps_CumulativeFactRefs' }
      & { bytesEnumerated: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsEnumerated: { __typename?: 'FactRef' }
        & FactRefFragment
      , bytesProcessed: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsProcessed: { __typename?: 'FactRef' }
        & FactRefFragment
      , bytesSkipped: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsSkipped: { __typename?: 'FactRef' }
        & FactRefFragment
       }
    ), iterationFactRefs: (
      { __typename?: 'MigrationProgressCompProps_IterationFactRefs' }
      & { bytesEnumerated: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsEnumerated: { __typename?: 'FactRef' }
        & FactRefFragment
      , bytesProcessed: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsProcessed: { __typename?: 'FactRef' }
        & FactRefFragment
      , bytesSkipped: { __typename?: 'FactRef' }
        & FactRefFragment
      , itemsSkipped: { __typename?: 'FactRef' }
        & FactRefFragment
       }
    ) }
  ), migrationProgressCompHubs: { __typename?: 'MigrationProgressCompHubs' }
    & MigrationProgressCompHubsFragment
   }
);

export type MigrationProgressCompHubsFragment = (
  { __typename?: 'MigrationProgressCompHubs' }
  & { totalBytes: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , totalItems: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , endOfEnumerationPhases: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
  , endOfProcessingPhases: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
)
  & ComponentHubsFragment
;

export type PhaseCompFragment = (
  { __typename?: 'PhaseComp' }
  & { phaseCompProps: (
    { __typename?: 'PhaseCompProps' }
    & Pick<PhaseCompProps, 'title' | 'inputProjection' | 'outputProjection' | 'endOfProcessing'>
    & { itemTypeSelectors: Array<(
      { __typename?: 'PhaseCompItemTypeSelector' }
      & Pick<PhaseCompItemTypeSelector, 'bambooItemType' | 'itemType' | 'withoutTags' | 'itemCategory' | 'addToSkipped'>
      & { withTags: Array<(
        { __typename?: 'PhaseCompTagSelector' }
        & Pick<PhaseCompTagSelector, 'tag' | 'value'>
      )> }
    )> }
  ), phaseCompHubs: { __typename?: 'PhaseCompHubs' }
    & PhaseCompHubsFragment
   }
);

export type PhaseCompHubsFragment = (
  { __typename?: 'PhaseCompHubs' }
  & { pullFrom: { __typename?: 'SelectorHub' }
    & SelectorHubFragment
  , accessKey1: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , accessKey2: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , endpointRef1: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , endpointRef2: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
  , vars: { __typename?: 'VarsHub' }
    & VarsHubFragment
   }
)
  & ComponentHubsFragment
;

export type ReducerCompFragment = (
  { __typename?: 'ReducerComp' }
  & { reducerCompProps: (
    { __typename?: 'ReducerCompProps' }
    & Pick<ReducerCompProps, 'name' | 'reducerType' | 'ignoreBlocked'>
  ), reducerCompHubs: { __typename?: 'ReducerCompHubs' }
    & ReducerCompHubsFragment
   }
);

export type ReducerCompHubsFragment = (
  { __typename?: 'ReducerCompHubs' }
  & { inputs: { __typename?: 'ArrayHub' }
    & ArrayHubFragment
   }
)
  & ComponentHubsFragment
;

export type RestrictionCompFragment = (
  { __typename?: 'RestrictionComp' }
  & { restrictionCompProps: (
    { __typename?: 'RestrictionCompProps' }
    & Pick<RestrictionCompProps, 'name' | 'comparatorType' | 'acceptableResults' | 'isCritical'>
  ), restrictionCompHubs: { __typename?: 'RestrictionCompHubs' }
    & RestrictionCompHubsFragment
   }
);

export type RestrictionCompHubsFragment = (
  { __typename?: 'RestrictionCompHubs' }
  & { values: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , threshold: { __typename?: 'ExactlyOneRelationshipHub' }
    & ExactlyOneRelationshipHubFragment
   }
)
  & ComponentHubsFragment
;

export type RoleCompFragment = (
  { __typename?: 'RoleComp' }
  & { roleCompProps: (
    { __typename?: 'RoleCompProps' }
    & Pick<RoleCompProps, 'roleId'>
  ), roleCompHubs: { __typename?: 'RoleCompHubs' }
    & RoleCompHubsFragment
   }
);

export type RoleCompHubsFragment = (
  { __typename?: 'RoleCompHubs' }
  & { roles: { __typename?: 'ExactlyOneRelationshipHub' }
    & ExactlyOneRelationshipHubFragment
   }
)
  & ComponentHubsFragment
;

export type RootEndpointCompFragment = (
  { __typename?: 'RootEndpointComp' }
  & { rootEndpointCompProps: (
    { __typename?: 'EndpointCompProps' }
    & Pick<EndpointCompProps, 'title'>
  ), rootEndpointCompHubs: { __typename?: 'RootEndpointCompHubs' }
    & RootEndpointCompHubsFragment
   }
);

export type RootEndpointCompHubsFragment = (
  { __typename?: 'RootEndpointCompHubs' }
  & { accessKey: { __typename?: 'ExactlyOneRelationshipHub' }
    & ExactlyOneRelationshipHubFragment
  , vars: { __typename?: 'VarsHub' }
    & VarsHubFragment
   }
)
  & ComponentHubsFragment
;

export type SinkCompFragment = (
  { __typename?: 'SinkComp' }
  & { sinkCompProps: (
    { __typename?: 'SinkCompProps' }
    & Pick<SinkCompProps, 'internalId' | 'appTitle' | 'mainSubject' | 'title' | 'description' | 'order' | 'itemTypes'>
  ), sinkCompHubs: { __typename?: 'SinkCompHubs' }
    & SinkCompHubsFragment
   }
);

export type SinkCompHubsFragment = (
  { __typename?: 'SinkCompHubs' }
  & { migrationFlows: { __typename?: 'MigrationFlowsHub' }
    & MigrationFlowsHubFragment
   }
)
  & ComponentHubsFragment
;

export type StorageRestrictionCompFragment = (
  { __typename?: 'StorageRestrictionComp' }
  & { storageRestrictionCompProps: (
    { __typename?: 'StorageRestrictionCompProps' }
    & Pick<StorageRestrictionCompProps, 'storageType' | 'headroom'>
    & { helpArticle: Maybe<{ __typename?: 'ExternalHelpArticle' }
      & ExternalHelpArticleFragment
    > }
  ), storageRestrictionCompHubs: { __typename?: 'StorageRestrictionCompHubs' }
    & StorageRestrictionCompHubsFragment
   }
);

export type StorageRestrictionCompHubsFragment = (
  { __typename?: 'StorageRestrictionCompHubs' }
  & { required: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , available: { __typename?: 'OptionalRelationshipHub' }
    & OptionalRelationshipHubFragment
   }
)
  & ComponentHubsFragment
;

export type TimeEstimateCompFragment = (
  { __typename?: 'TimeEstimateComp' }
  & { timeEstimateCompProps: (
    { __typename?: 'TimeEstimateCompProps' }
    & Pick<TimeEstimateCompProps, 'title' | 'connector' | 'serviceProvider' | 'fallback'>
    & { estimatedTimeFactRef: { __typename?: 'FactRef' }
      & FactRefFragment
    , elapsedTimeFactRef: { __typename?: 'FactRef' }
      & FactRefFragment
     }
  ), timeEstimateCompHubs: { __typename?: 'TimeEstimateCompHubs' }
    & TimeEstimateCompHubsFragment
   }
);

export type TimeEstimateCompHubsFragment = (
  { __typename?: 'TimeEstimateCompHubs' }
  & { totalBytes: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , totalItems: { __typename?: 'MultipleRelationshipsHub' }
    & MultipleRelationshipsHubFragment
  , migrationProgresses: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
)
  & ComponentHubsFragment
;

export type VariableCompFragment = (
  { __typename?: 'VariableComp' }
  & { variableCompProps: (
    { __typename?: 'VariableCompProps' }
    & Pick<VariableCompProps, 'inputId' | 'emptyValues'>
  ), variableCompHubs: { __typename?: 'StandardComponentHubs' }
    & VariableCompHubsFragment
   }
);

export type VariableCompHubsFragment = { __typename?: 'StandardComponentHubs' }
  & ComponentHubsFragment
;

export type RelationshipFragment = (
  { __typename?: 'Association' }
  & Pick<Association, 'componentId' | 'serverSide'>
  & { associationProps: { __typename?: 'AssociationProps' }
    & AssociationPropsFragment
   }
) | (
  { __typename?: 'ComponentResolved' }
  & Pick<ComponentResolved, 'componentId' | 'serverSide'>
  & { componentResolvedProps: { __typename?: 'ComponentResolvedProps' }
    & ComponentResolvedPropsFragment
   }
) | (
  { __typename?: 'MigrationFlow' }
  & Pick<MigrationFlow, 'componentId' | 'serverSide'>
  & { migrationFlowProps: { __typename?: 'MigrationFlowProps' }
    & MigrationFlowPropsFragment
   }
) | (
  { __typename?: 'Pipe' }
  & Pick<Pipe, 'componentId' | 'serverSide'>
  & { pipeProps: { __typename?: 'PipeProps' }
    & PipePropsFragment
   }
) | (
  { __typename?: 'PipeFromOption' }
  & Pick<PipeFromOption, 'componentId' | 'serverSide'>
  & { pipeFromOptionProps: { __typename?: 'PipeFromOptionProps' }
    & PipeFromOptionPropsFragment
   }
) | (
  { __typename?: 'PipeToOption' }
  & Pick<PipeToOption, 'componentId' | 'serverSide'>
  & { pipeToOptionProps: { __typename?: 'PipeToOptionProps' }
    & PipeToOptionPropsFragment
   }
) | (
  { __typename?: 'Plug' }
  & Pick<Plug, 'componentId' | 'serverSide'>
  & { plugProps: { __typename?: 'PlugProps' }
    & PlugPropsFragment
   }
);

export type AssociationPropsFragment = (
  { __typename?: 'AssociationProps' }
  & Pick<AssociationProps, 'title'>
);

export type ComponentResolvedPropsFragment = (
  { __typename?: 'ComponentResolvedProps' }
  & Pick<ComponentResolvedProps, 'title' | 'default' | 'promotePartial'>
);

export type MigrationFlowPropsFragment = (
  { __typename?: 'MigrationFlowProps' }
  & Pick<MigrationFlowProps, 'title'>
);

export type PipePropsFragment = (
  { __typename?: 'PipeProps' }
  & Pick<PipeProps, 'title' | 'promotePartial' | 'outputName'>
  & { default: Maybe<({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
  > }
);

export type PipeFromOptionPropsFragment = (
  { __typename?: 'PipeFromOptionProps' }
  & Pick<PipeFromOptionProps, 'title' | 'promotePartial' | 'outputName'>
  & { default: Maybe<({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
  > }
);

export type PipeToOptionPropsFragment = (
  { __typename?: 'PipeToOptionProps' }
  & Pick<PipeToOptionProps, 'title' | 'promotePartial' | 'outputName'>
  & { default: Maybe<(
    { __typename?: 'StrictOption' }
    & { value: Maybe<({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
      & VariantFragment
    > }
  )> }
);

export type PlugPropsFragment = (
  { __typename?: 'PlugProps' }
  & Pick<PlugProps, 'title'>
  & { value: ({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
   }
);

export type HubFragment = (
  { __typename?: 'PreconditionsHub' }
  & Pick<PreconditionsHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'AssociationsHub' }
  & Pick<AssociationsHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'MultipleRelationshipsHub' }
  & Pick<MultipleRelationshipsHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'ArrayHub' }
  & Pick<ArrayHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'VarsHub' }
  & Pick<VarsHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'ExactlyOneRelationshipHub' }
  & Pick<ExactlyOneRelationshipHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'MigrationFlowsHub' }
  & Pick<MigrationFlowsHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'OptionalRelationshipHub' }
  & Pick<OptionalRelationshipHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
) | (
  { __typename?: 'SelectorHub' }
  & Pick<SelectorHub, 'serverSide'>
  & { relationships: Array<({ __typename?: 'Association' } | { __typename?: 'ComponentResolved' } | { __typename?: 'MigrationFlow' } | { __typename?: 'Pipe' } | { __typename?: 'PipeFromOption' } | { __typename?: 'PipeToOption' } | { __typename?: 'Plug' })
    & RelationshipFragment
  > }
);

export type AssociationsHubFragment = (
  { __typename?: 'AssociationsHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type ArrayHubFragment = (
  { __typename?: 'ArrayHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type ExactlyOneRelationshipHubFragment = (
  { __typename?: 'ExactlyOneRelationshipHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type MultipleRelationshipsHubFragment = (
  { __typename?: 'MultipleRelationshipsHub' }
  & { props: (
    { __typename?: 'MultipleRelationshipsHubProps' }
    & Pick<MultipleRelationshipsHubProps, 'proposedRelationshipTitle' | 'reducerType' | 'ignoreBlocked'>
  ) }
)
  & HubFragment
;

export type MigrationFlowsHubFragment = (
  { __typename?: 'MigrationFlowsHub' }
  & { props: (
    { __typename?: 'MigrationFlowsHubProps' }
    & Pick<MigrationFlowsHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type PreconditionsHubFragment = (
  { __typename?: 'PreconditionsHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type OptionalRelationshipHubFragment = (
  { __typename?: 'OptionalRelationshipHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type SelectorHubFragment = (
  { __typename?: 'SelectorHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type VarsHubFragment = (
  { __typename?: 'VarsHub' }
  & { props: (
    { __typename?: 'StandardHubProps' }
    & Pick<StandardHubProps, 'proposedRelationshipTitle'>
  ) }
)
  & HubFragment
;

export type ComponentHubsFragment = (
  { __typename?: 'AreaCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'ChildEndpointCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'StandardComponentHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'FactCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'MigrationProgressCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'PhaseCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'ReducerCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'RestrictionCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'RoleCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'RootEndpointCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'SinkCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'StorageRestrictionCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
) | (
  { __typename?: 'TimeEstimateCompHubs' }
  & { preconditions: { __typename?: 'PreconditionsHub' }
    & PreconditionsHubFragment
  , associations: { __typename?: 'AssociationsHub' }
    & AssociationsHubFragment
   }
);

export type GetRenderedBlueprintQueryVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type GetRenderedBlueprintQuery = (
  { __typename?: 'Query' }
  & { getRenderedBlueprint: (
    { __typename?: 'RenderedBlueprint' }
    & { components: Array<{ __typename?: 'RenderedBlueprintComponent' }
      & RenderedBlueprintComponentFragment
    > }
  ) }
);

export type RenderedBlueprintComponentFragment = (
  { __typename?: 'RenderedBlueprintComponent' }
  & Pick<RenderedBlueprintComponent, 'id'>
  & { state: ({ __typename?: 'AlmostResolvedBlueprintState' } | { __typename?: 'BlockedBlueprintState' } | { __typename?: 'ErrorBlueprintState' } | { __typename?: 'PendingBlueprintState' } | { __typename?: 'PreparingBlueprintState' } | { __typename?: 'ResolvedBlueprintState' } | { __typename?: 'ResolvingBlueprintState' })
    & BlueprintStateFragment
  , hubs: Array<{ __typename?: 'RenderedBlueprintHub' }
    & RenderedBlueprintHubFragment
  > }
);

export type RenderedBlueprintHubFragment = (
  { __typename?: 'RenderedBlueprintHub' }
  & Pick<RenderedBlueprintHub, 'name'>
  & { state: ({ __typename?: 'AlmostResolvedBlueprintState' } | { __typename?: 'BlockedBlueprintState' } | { __typename?: 'ErrorBlueprintState' } | { __typename?: 'PendingBlueprintState' } | { __typename?: 'PreparingBlueprintState' } | { __typename?: 'ResolvedBlueprintState' } | { __typename?: 'ResolvingBlueprintState' })
    & BlueprintStateFragment
  , relationships: Array<{ __typename?: 'RenderedBlueprintRelationship' }
    & RenderedBlueprintRelationshipFragment
  > }
);

export type RenderedBlueprintRelationshipFragment = (
  { __typename?: 'RenderedBlueprintRelationship' }
  & Pick<RenderedBlueprintRelationship, 'componentId'>
  & { state: ({ __typename?: 'AlmostResolvedBlueprintState' } | { __typename?: 'BlockedBlueprintState' } | { __typename?: 'ErrorBlueprintState' } | { __typename?: 'PendingBlueprintState' } | { __typename?: 'PreparingBlueprintState' } | { __typename?: 'ResolvedBlueprintState' } | { __typename?: 'ResolvingBlueprintState' })
    & BlueprintStateFragment
   }
);

export type BlueprintStateFragment = (
  { __typename?: 'AlmostResolvedBlueprintState' }
  & Pick<AlmostResolvedBlueprintState, 'progress' | 'type'>
  & { output: Maybe<({ __typename?: 'AreaCompOutput' } | { __typename?: 'ChildEndpointCompOutput' } | { __typename?: 'ConnectionCompOutput' } | { __typename?: 'FactCompLikeOutput' } | { __typename?: 'PhaseCompOutput' } | { __typename?: 'MigrationProgressCompOutput' } | { __typename?: 'ReducerCompOutput' } | { __typename?: 'RestrictionCompOutput' } | { __typename?: 'RoleCompOutput' } | { __typename?: 'RootEndpointCompOutput' } | { __typename?: 'SinkCompOutput' } | { __typename?: 'StorageRestrictionCompOutput' } | { __typename?: 'TimeEstimateCompOutput' } | { __typename?: 'VariableCompOutput' } | { __typename?: 'UnregisteredOutputType' })
    & BlueprintStateOutputFragment
  >, errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'BlockedBlueprintState' }
  & Pick<BlockedBlueprintState, 'type'>
  & { errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'ErrorBlueprintState' }
  & Pick<ErrorBlueprintState, 'type'>
  & { errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'PendingBlueprintState' }
  & Pick<PendingBlueprintState, 'type'>
  & { errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'PreparingBlueprintState' }
  & Pick<PreparingBlueprintState, 'type'>
  & { errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'ResolvedBlueprintState' }
  & Pick<ResolvedBlueprintState, 'type'>
  & { output: Maybe<({ __typename?: 'AreaCompOutput' } | { __typename?: 'ChildEndpointCompOutput' } | { __typename?: 'ConnectionCompOutput' } | { __typename?: 'FactCompLikeOutput' } | { __typename?: 'PhaseCompOutput' } | { __typename?: 'MigrationProgressCompOutput' } | { __typename?: 'ReducerCompOutput' } | { __typename?: 'RestrictionCompOutput' } | { __typename?: 'RoleCompOutput' } | { __typename?: 'RootEndpointCompOutput' } | { __typename?: 'SinkCompOutput' } | { __typename?: 'StorageRestrictionCompOutput' } | { __typename?: 'TimeEstimateCompOutput' } | { __typename?: 'VariableCompOutput' } | { __typename?: 'UnregisteredOutputType' })
    & BlueprintStateOutputFragment
  >, errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
) | (
  { __typename?: 'ResolvingBlueprintState' }
  & Pick<ResolvingBlueprintState, 'progress' | 'type'>
  & { output: Maybe<({ __typename?: 'AreaCompOutput' } | { __typename?: 'ChildEndpointCompOutput' } | { __typename?: 'ConnectionCompOutput' } | { __typename?: 'FactCompLikeOutput' } | { __typename?: 'PhaseCompOutput' } | { __typename?: 'MigrationProgressCompOutput' } | { __typename?: 'ReducerCompOutput' } | { __typename?: 'RestrictionCompOutput' } | { __typename?: 'RoleCompOutput' } | { __typename?: 'RootEndpointCompOutput' } | { __typename?: 'SinkCompOutput' } | { __typename?: 'StorageRestrictionCompOutput' } | { __typename?: 'TimeEstimateCompOutput' } | { __typename?: 'VariableCompOutput' } | { __typename?: 'UnregisteredOutputType' })
    & BlueprintStateOutputFragment
  >, errors: Array<(
    { __typename?: 'BlueprintError' }
    & Pick<BlueprintError, 'type' | 'message'>
  )> }
);

export type BlueprintStateOutputFragment = (
  { __typename?: 'AreaCompOutput' }
  & AreaCompOutputFragment
) | (
  { __typename?: 'ChildEndpointCompOutput' }
  & ChildEndpointCompOutputFragment
) | (
  { __typename?: 'ConnectionCompOutput' }
  & ConnectionCompOutputFragment
) | (
  { __typename?: 'FactCompLikeOutput' }
  & FactCompLikeOutputFragment
) | (
  { __typename?: 'PhaseCompOutput' }
  & PhaseCompOutputFragment
) | (
  { __typename?: 'MigrationProgressCompOutput' }
  & MigrationProgressCompOutputFragment
) | (
  { __typename?: 'ReducerCompOutput' }
  & ReducerCompOutputFragment
) | (
  { __typename?: 'RestrictionCompOutput' }
  & RestrictionCompOutputFragment
) | (
  { __typename?: 'RoleCompOutput' }
  & RoleCompOutputFragment
) | (
  { __typename?: 'RootEndpointCompOutput' }
  & RootEndpointCompOutputFragment
) | (
  { __typename?: 'SinkCompOutput' }
  & SinkCompOutputFragment
) | (
  { __typename?: 'StorageRestrictionCompOutput' }
  & StorageRestrictionCompOutputFragment
) | (
  { __typename?: 'TimeEstimateCompOutput' }
  & TimeEstimateCompOutputFragment
) | (
  { __typename?: 'VariableCompOutput' }
  & VariableCompOutputFragment
) | (
  { __typename?: 'UnregisteredOutputType' }
  & UnregisteredOutputTypeFragment
);

export type AreaCompOutputFragment = (
  { __typename?: 'AreaCompOutput' }
  & Pick<AreaCompOutput, 'itemTypes' | 'enabled'>
  & { totalBytesCollectable: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  >, totalItemsCollectable: Maybe<{ __typename?: 'CollectableItemBreakdown' }
    & CollectableItemBreakdownFragment
  >, maxPathLength: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  > }
);

export type ChildEndpointCompOutputFragment = (
  { __typename?: 'ChildEndpointCompOutput' }
  & Pick<ChildEndpointCompOutput, 'endpointDefJson' | 'endpointRef'>
);

export type ConnectionCompOutputFragment = (
  { __typename?: 'ConnectionCompOutput' }
  & Pick<ConnectionCompOutput, 'roles' | 'connectionId' | 'emailAddress'>
);

export type FactCompLikeOutputFragment = (
  { __typename?: 'FactCompLikeOutput' }
  & Pick<FactCompLikeOutput, 'valueJson'>
  & { collectable: ({ __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' } | { __typename?: 'CollectableBoolean' })
    & CollectableFragment
   }
);

export type MigrationProgressCompOutputFragment = (
  { __typename?: 'MigrationProgressCompOutput' }
  & Pick<MigrationProgressCompOutput, 'completed'>
  & { cumulative: { __typename?: 'MigrationProgressCompOutput_Metrics' }
    & MigrationProgressCompOutput_MetricsFragment
  , iteration: { __typename?: 'MigrationProgressCompOutput_Metrics' }
    & MigrationProgressCompOutput_MetricsFragment
   }
);

export type MigrationProgressCompOutput_MetricsFragment = (
  { __typename?: 'MigrationProgressCompOutput_Metrics' }
  & Pick<MigrationProgressCompOutput_Metrics, 'estimatedBytes' | 'enumeratedBytes' | 'totalBytes' | 'processedBytes' | 'skippedBytes'>
  & { estimatedItems: Maybe<Array<(
    { __typename?: 'MigrationProgressCompOutputEntry' }
    & Pick<MigrationProgressCompOutputEntry, 'itemType' | 'count'>
  )>>, enumeratedItems: Maybe<Array<(
    { __typename?: 'MigrationProgressCompOutputEntry' }
    & Pick<MigrationProgressCompOutputEntry, 'itemType' | 'count'>
  )>>, totalItems: Maybe<Array<(
    { __typename?: 'MigrationProgressCompOutputEntry' }
    & Pick<MigrationProgressCompOutputEntry, 'itemType' | 'count'>
  )>>, processedItems: Array<(
    { __typename?: 'MigrationProgressCompOutputEntry' }
    & Pick<MigrationProgressCompOutputEntry, 'itemType' | 'count'>
  )>, skippedItems: Array<(
    { __typename?: 'MigrationProgressCompOutputEntry' }
    & Pick<MigrationProgressCompOutputEntry, 'itemType' | 'count'>
  )> }
);

export type PhaseCompOutputFragment = (
  { __typename?: 'PhaseCompOutput' }
  & Pick<PhaseCompOutput, 'phaseId' | 'phaseDefJson'>
);

export type ReducerCompOutputFragment = (
  { __typename?: 'ReducerCompOutput' }
  & { reducerCompOutputValue: ({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
   }
);

export type RestrictionCompOutputFragment = (
  { __typename?: 'RestrictionCompOutput' }
  & Pick<RestrictionCompOutput, 'restrictionState'>
  & { restrictionCompOutputValue: ({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
  , threshold: ({ __typename?: 'VariantBoolean' } | { __typename?: 'VariantLong' } | { __typename?: 'CollectableBoolean' } | { __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' })
    & VariantFragment
   }
);

export type RoleCompOutputFragment = (
  { __typename?: 'RoleCompOutput' }
  & Pick<RoleCompOutput, 'conditionState'>
);

export type RootEndpointCompOutputFragment = (
  { __typename?: 'RootEndpointCompOutput' }
  & Pick<RootEndpointCompOutput, 'endpointDefJson' | 'endpointRef'>
);

export type SinkCompOutputFragment = (
  { __typename?: 'SinkCompOutput' }
  & Pick<SinkCompOutput, 'itemTypes'>
  & { totalBytesCollectable: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  >, totalItemsCollectable: Maybe<{ __typename?: 'CollectableItemBreakdown' }
    & CollectableItemBreakdownFragment
  >, maxPathLength: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  > }
);

export type StorageRestrictionCompOutputFragment = (
  { __typename?: 'StorageRestrictionCompOutput' }
  & Pick<StorageRestrictionCompOutput, 'restrictionState'>
  & { required: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  >, available: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  > }
);

export type TimeEstimateCompOutputFragment = (
  { __typename?: 'TimeEstimateCompOutput' }
  & Pick<TimeEstimateCompOutput, 'params' | 'fallbackEstimate'>
  & { totalBytesCollectable: Maybe<{ __typename?: 'CollectableInteger' }
    & CollectableIntegerFragment
  >, totalItemsCollectable: Maybe<{ __typename?: 'CollectableItemBreakdown' }
    & CollectableItemBreakdownFragment
  > }
);

export type VariableCompOutputFragment = (
  { __typename?: 'VariableCompOutput' }
  & { variableCompOutputValue: VariableCompOutput['value'] }
);

export type UnregisteredOutputTypeFragment = (
  { __typename?: 'UnregisteredOutputType' }
  & Pick<UnregisteredOutputType, 'class' | 'asString'>
);

export type GenerateJsonUsingChatGptMutationVariables = {
  model: Scalars['String'],
  prompt: Scalars['String'],
  temperature: Scalars['Float']
};


export type GenerateJsonUsingChatGptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateJsonUsingChatGPT'>
);

export type MakeSchoolLookupPromptMutationVariables = {
  domainName: Scalars['String']
};


export type MakeSchoolLookupPromptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeSchoolLookupPrompt'>
);

export type DeleteConfigurationMutationVariables = {
  id: Scalars['Int']
};


export type DeleteConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConfiguration'>
);

export type GetConfigurationQueryVariables = {
  id: Scalars['Int']
};


export type GetConfigurationQuery = (
  { __typename?: 'Query' }
  & { configuration: { __typename?: 'Configuration' }
    & ConfigurationFragment
   }
);

export type InsertConfigurationMutationVariables = {
  organizationId: Scalars['Int'],
  formData: ConfigurationFormData
};


export type InsertConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { insertConfiguration: { __typename?: 'Configuration' }
    & ConfigurationFragment
   }
);

export type SearchConfigurationsQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchConfigurationsQuery = (
  { __typename?: 'Query' }
  & { searchConfigurations: Array<{ __typename?: 'Configuration' }
    & ConfigurationFragment
  > }
);

export type UpdateConfigurationMutationVariables = {
  id: Scalars['Int'],
  formData: ConfigurationFormData
};


export type UpdateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateConfiguration: { __typename?: 'Configuration' }
    & ConfigurationFragment
   }
);

export type CheckAccessKeyMutationVariables = {
  connectionId: Scalars['String'],
  roles?: Maybe<Array<Scalars['String']>>
};


export type CheckAccessKeyMutation = (
  { __typename?: 'Mutation' }
  & { checkAccessKey: Maybe<{ __typename?: 'Connection' }
    & ConnectionFragment
  > }
);

export type ConnectResultFragment = (
  { __typename?: 'ConnectResult' }
  & Pick<ConnectResult, 'elevated' | 'idleTimeout'>
  & { connection: { __typename?: 'Connection' }
    & ConnectionFragment
  , user: { __typename?: 'User' }
    & UserFragment
  , erasedFacts: Array<(
    { __typename?: 'FactPlaceholder' }
    & Pick<FactPlaceholder, 'id'>
  )> }
);

export type ConnectMutationVariables = {
  contextType: SignInContextType,
  cloudServiceId: Scalars['String'],
  oneTimeAuthCode: Scalars['String'],
  timeZone?: Maybe<Scalars['String']>,
  newUserSettings?: Maybe<NewUserSettings>,
  ambassadorCode?: Maybe<Scalars['String']>
};


export type ConnectMutation = (
  { __typename?: 'Mutation' }
  & { connect: Maybe<{ __typename?: 'ConnectResult' }
    & ConnectResultFragment
  > }
);

export type ConnectionFragment = (
  { __typename?: 'Connection' }
  & Pick<Connection, 'id' | 'userId' | 'cloudServiceId' | 'accessKeyProvider' | 'accessKeyStatus' | 'roles' | 'class' | 'description' | 'metadata' | 'enabled' | 'eligibleForSignIn' | 'createdAt' | 'updatedAt' | 'validatedAt' | 'signedInAt' | 'revealedAt' | 'revokedAt'>
  & { personalInfo: { __typename?: 'PersonalInfo' }
    & PersonalInfoFragment
   }
);

export type GetConnectionQueryVariables = {
  userId: Scalars['String'],
  connectionId: Scalars['String']
};


export type GetConnectionQuery = (
  { __typename?: 'Query' }
  & { connection: { __typename?: 'Connection' }
    & ConnectionFragment
   }
);

export type GetConnectionsQueryVariables = {
  userId: Scalars['String'],
  connectionIds: Array<Scalars['String']>,
  accessKeyCheck: AccessKeyCheck
};


export type GetConnectionsQuery = (
  { __typename?: 'Query' }
  & { getConnections: Array<{ __typename?: 'Connection' }
    & ConnectionFragment
  > }
);

export type GetUserConnectionsQueryVariables = {
  userId: Scalars['String'],
  accessKeyCheck: AccessKeyCheck
};


export type GetUserConnectionsQuery = (
  { __typename?: 'Query' }
  & { connections: Array<{ __typename?: 'Connection' }
    & ConnectionFragment
  > }
);

export type ResolveConnectionsMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  sourceEmailAddresses: Array<Scalars['String']>,
  sourceAdminConnectionId?: Maybe<Scalars['String']>,
  destinationCloudServiceId: Scalars['String'],
  destinationEmailAddresses: Array<Scalars['String']>,
  destinationAdminConnectionId?: Maybe<Scalars['String']>
};


export type ResolveConnectionsMutation = (
  { __typename?: 'Mutation' }
  & { resolveConnections: (
    { __typename?: 'ResolveConnectionsResult' }
    & { source: (
      { __typename?: 'ResolveConnectionsResult_Segment' }
      & Pick<ResolveConnectionsResult_Segment, 'notAccessible' | 'ambiguous' | 'missing'>
      & { resolved: Array<{ __typename?: 'Connection' }
        & ConnectionFragment
      >, wrongCloudService: Array<{ __typename?: 'Connection' }
        & ConnectionFragment
      > }
    ), destination: (
      { __typename?: 'ResolveConnectionsResult_Segment' }
      & Pick<ResolveConnectionsResult_Segment, 'notAccessible' | 'ambiguous' | 'missing'>
      & { resolved: Array<{ __typename?: 'Connection' }
        & ConnectionFragment
      >, wrongCloudService: Array<{ __typename?: 'Connection' }
        & ConnectionFragment
      > }
    ) }
  ) }
);

export type RevealAccessKeyMutationVariables = {
  connectionId: Scalars['String'],
  roles: Array<Scalars['String']>
};


export type RevealAccessKeyMutation = (
  { __typename?: 'Mutation' }
  & { revealAccessKey: Maybe<(
    { __typename?: 'RevealAccessKeyResult' }
    & { connection: { __typename?: 'Connection' }
      & ConnectionFragment
    , revealedAccessKey: { __typename?: 'RevealedAccessKey' }
      & RevealedAccessKeyFragmentFragment
     }
  )> }
);

export type RevealedAccessKeyFragmentFragment = (
  { __typename?: 'RevealedAccessKey' }
  & Pick<RevealedAccessKey, 'accessKeyVersion' | 'accessKey' | 'accessKeyStatus' | 'accessKeyParamsVersion' | 'accessKeyParams' | 'currentPermissions' | 'currentRoles' | 'validatedPermissions' | 'missingPermissions'>
);

export type RevokeAccessKeyMutationVariables = {
  connectionId: Scalars['String'],
  force: Scalars['Boolean']
};


export type RevokeAccessKeyMutation = (
  { __typename?: 'Mutation' }
  & { revokeAccessKey: Maybe<{ __typename?: 'Connection' }
    & ConnectionFragment
  > }
);

export type RevokeAllAccessKeysMutationVariables = {};


export type RevokeAllAccessKeysMutation = (
  { __typename?: 'Mutation' }
  & { revokeAllAccessKeys: Array<{ __typename?: 'Connection' }
    & ConnectionFragment
  > }
);

export type DeleteCouponCodeMutationVariables = {
  id: Scalars['String']
};


export type DeleteCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCouponCode'>
);

export type GetCouponCodeQueryVariables = {
  id: Scalars['String']
};


export type GetCouponCodeQuery = (
  { __typename?: 'Query' }
  & { couponCode: { __typename?: 'CouponCode' }
    & CouponCodeFragment
   }
);

export type InsertCouponCodeMutationVariables = {
  formData: CouponCodeFormData
};


export type InsertCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { insertCouponCode: { __typename?: 'CouponCode' }
    & CouponCodeFragment
   }
);

export type SearchCouponCodesQueryVariables = {
  term: Scalars['String']
};


export type SearchCouponCodesQuery = (
  { __typename?: 'Query' }
  & { searchCouponCodes: Array<{ __typename?: 'CouponCode' }
    & CouponCodeFragment
  > }
);

export type SendCouponCodesMutationVariables = {
  emailAddresses: Scalars['String'],
  discount: Scalars['BigDecimal'],
  note: Scalars['String']
};


export type SendCouponCodesMutation = (
  { __typename?: 'Mutation' }
  & { sendCouponCodes: Array<(
    { __typename?: 'SendCouponCodeResult' }
    & Pick<SendCouponCodeResult, 'emailAddress' | 'result'>
  )> }
);

export type UpdateCouponCodeMutationVariables = {
  formData: CouponCodeFormData
};


export type UpdateCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateCouponCode: { __typename?: 'CouponCode' }
    & CouponCodeFragment
   }
);

export type CollectableFragment = (
  { __typename?: 'CollectableInteger' }
  & Pick<CollectableInteger, 'type' | 'isComplete' | 'progress'>
  & { partialIntegerValue: CollectableInteger['partialValue'], completeIntegerValue: CollectableInteger['completeValue'] }
) | (
  { __typename?: 'CollectableItemBreakdown' }
  & Pick<CollectableItemBreakdown, 'type' | 'isComplete' | 'progress'>
  & { partialItemBreakdownValue: Maybe<{ __typename?: 'ItemBreakdown' }
    & ItemBreakdownFragment
  >, completeItemBreakdownValue: Maybe<{ __typename?: 'ItemBreakdown' }
    & ItemBreakdownFragment
  > }
) | (
  { __typename?: 'CollectableBoolean' }
  & Pick<CollectableBoolean, 'type' | 'isComplete' | 'progress'>
  & { partialBooleanValue: CollectableBoolean['partialValue'], completeBooleanValue: CollectableBoolean['completeValue'] }
);

export type CollectableBooleanFragment = (
  { __typename?: 'CollectableBoolean' }
  & Pick<CollectableBoolean, 'type' | 'isComplete' | 'progress' | 'partialValue' | 'completeValue'>
);

export type CollectableIntegerFragment = (
  { __typename?: 'CollectableInteger' }
  & Pick<CollectableInteger, 'type' | 'isComplete' | 'progress' | 'partialValue' | 'completeValue'>
);

export type CollectableItemBreakdownFragment = (
  { __typename: 'CollectableItemBreakdown' }
  & Pick<CollectableItemBreakdown, 'type' | 'isComplete' | 'progress'>
  & { partialValue: Maybe<{ __typename?: 'ItemBreakdown' }
    & ItemBreakdownFragment
  >, completeValue: Maybe<{ __typename?: 'ItemBreakdown' }
    & ItemBreakdownFragment
  > }
);

export type FactFragment = (
  { __typename?: 'Fact' }
  & Pick<Fact, 'id' | 'subjectType' | 'subjectId' | 'family' | 'valueType'>
  & { state: ({ __typename?: 'MissingFactState' } | { __typename?: 'FailedFactState' } | { __typename?: 'PreparingFactState' } | { __typename?: 'CollectingFactState' } | { __typename?: 'CollectedFactState' })
    & FactStateFragment
   }
);

export type FactRefFragment = (
  { __typename?: 'FactRef' }
  & Pick<FactRef, 'type' | 'family' | 'valueType'>
);

export type FactStateFragment = (
  { __typename?: 'MissingFactState' }
  & Pick<MissingFactState, 'foo'>
) | (
  { __typename?: 'FailedFactState' }
  & Pick<FailedFactState, 'errorType' | 'errorMessage'>
) | (
  { __typename?: 'PreparingFactState' }
  & Pick<PreparingFactState, 'foo'>
) | (
  { __typename?: 'CollectingFactState' }
  & { value: ({ __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' } | { __typename?: 'CollectableBoolean' })
    & CollectableFragment
   }
) | (
  { __typename?: 'CollectedFactState' }
  & { value: ({ __typename?: 'CollectableInteger' } | { __typename?: 'CollectableItemBreakdown' } | { __typename?: 'CollectableBoolean' })
    & CollectableFragment
   }
);

export type FactsUpdatedSubscriptionVariables = {
  factIds: Array<Scalars['String']>
};


export type FactsUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { factsUpdated: (
    { __typename?: 'FactEvent' }
    & Pick<FactEvent, 'eventType'>
    & { facts: Array<{ __typename?: 'Fact' }
      & FactFragment
    > }
  ) }
);

export type GetBlueprintFactsQueryVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintFactSpecs: Array<BlueprintFactsSpec>
};


export type GetBlueprintFactsQuery = (
  { __typename?: 'Query' }
  & { getBlueprintFacts: (
    { __typename?: 'GetBlueprintFactsResult' }
    & { facts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
      & FactOrPlaceholderFragment
    > }
  ) }
);

export type GetFactsQueryVariables = {
  factIds: Array<Scalars['String']>
};


export type GetFactsQuery = (
  { __typename?: 'Query' }
  & { getFacts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
    & FactOrPlaceholderFragment
  > }
);

export type FactOrPlaceholderFragment = (
  { __typename?: 'Fact' }
  & Pick<Fact, 'id'>
  & FactFragment
) | (
  { __typename?: 'FactPlaceholder' }
  & Pick<FactPlaceholder, 'id'>
);

export type InvalidateFactMutationVariables = {
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  factId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String']
};


export type InvalidateFactMutation = (
  { __typename?: 'Mutation' }
  & { invalidateFact: { __typename?: 'Fact' }
    & FactFragment
   }
);

export type ItemBreakdownFragment = (
  { __typename?: 'ItemBreakdown' }
  & { entries: Array<(
    { __typename?: 'ItemBreakdownEntry' }
    & Pick<ItemBreakdownEntry, 'itemType'>
    & { taggedEntries: Array<(
      { __typename?: 'ItemBreakdownTaggedEntry' }
      & Pick<ItemBreakdownTaggedEntry, 'tags' | 'count'>
    )> }
  )> }
);

export type PutFactMutationVariables = {
  subjectType: FactSubjectType,
  subjectId: Scalars['String'],
  factId: Scalars['String'],
  family: Scalars['String'],
  valueType: Scalars['String'],
  valueJson: Scalars['String']
};


export type PutFactMutation = (
  { __typename?: 'Mutation' }
  & { putFact: { __typename?: 'Fact' }
    & FactFragment
   }
);

export type RefreshBlueprintFactsMutationVariables = {
  factIds: Array<Scalars['String']>,
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  blueprintInputs: Array<BlueprintInputsEntryInput>
};


export type RefreshBlueprintFactsMutation = (
  { __typename?: 'Mutation' }
  & { refreshBlueprintFacts: (
    { __typename?: 'GetBlueprintFactsResult' }
    & Pick<GetBlueprintFactsResult, 'scanJobIds'>
    & { facts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
      & FactOrPlaceholderFragment
    > }
  ) }
);

export type ScansQueryVariables = {
  connectionIds: Array<Scalars['String']>,
  includeCompleted: Scalars['Boolean'],
  limit?: Maybe<Scalars['Int']>
};


export type ScansQuery = (
  { __typename?: 'Query' }
  & { scans: Array<(
    { __typename?: 'Scan' }
    & Pick<Scan, 'id' | 'userId' | 'connectionId' | 'sourceCloudServiceId' | 'destinationCloudServiceId' | 'status' | 'error' | 'createdAt' | 'completedAt' | 'jobId' | 'workflowUrl'>
    & { blueprintInputs: Array<(
      { __typename?: 'BlueprintInputsEntry' }
      & Pick<BlueprintInputsEntry, 'key' | 'value'>
    )> }
  )> }
);

export type AbortJobMutationVariables = {
  jobId: Scalars['String']
};


export type AbortJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'abortJob'>
);

export type GetAvailableJobStatusesQueryVariables = {
  jobId: Scalars['String']
};


export type GetAvailableJobStatusesQuery = (
  { __typename?: 'Query' }
  & { getAvailableJobStatuses: (
    { __typename?: 'AvailableJobStatuses' }
    & { allSupported: Array<(
      { __typename?: 'JobStatusDescription' }
      & Pick<JobStatusDescription, 'status' | 'description'>
    )>, proposed: Array<(
      { __typename?: 'JobStatusDescription' }
      & Pick<JobStatusDescription, 'status' | 'description'>
    )> }
  ) }
);

export type GetJobHistoryQueryVariables = {
  jobId: Scalars['String'],
  includeMinorUpdates: Scalars['Boolean']
};


export type GetJobHistoryQuery = (
  { __typename?: 'Query' }
  & { getJobHistory: (
    { __typename?: 'JobHistory' }
    & Pick<JobHistory, 'jobId'>
    & { job: { __typename?: 'JobHistoryRecord' }
      & JobHistoryRecordFragmentFragment
    , jobHistory: Array<{ __typename?: 'JobHistoryRecord' }
      & JobHistoryRecordFragmentFragment
    >, tasks: Array<(
      { __typename?: 'TaskSummaryRecord' }
      & Pick<TaskSummaryRecord, 'jobId' | 'taskId' | 'transition' | 'type' | 'internalStatus' | 'resultType' | 'createdAt' | 'completedAt' | 'archivedAt'>
    )>, taskIssues: Array<{ __typename?: 'TaskIssue' }
      & TaskIssueFragmentFragment
    > }
  ) }
);

export type GetTaskHistoryQueryVariables = {
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  includeMinorUpdates: Scalars['Boolean']
};


export type GetTaskHistoryQuery = (
  { __typename?: 'Query' }
  & { getTaskHistory: (
    { __typename?: 'TaskHistory' }
    & Pick<TaskHistory, 'jobId' | 'taskId'>
    & { task: { __typename?: 'TaskHistoryRecord' }
      & TaskHistoryRecordFragmentFragment
    , taskHistory: Array<{ __typename?: 'TaskHistoryRecord' }
      & TaskHistoryRecordFragmentFragment
    >, taskIssues: Array<{ __typename?: 'TaskIssue' }
      & TaskIssueFragmentFragment
    > }
  ) }
);

export type GetTaskIssueHistoryQueryVariables = {
  jobId: Scalars['String'],
  taskId: Scalars['String'],
  issueId: Scalars['String']
};


export type GetTaskIssueHistoryQuery = (
  { __typename?: 'Query' }
  & { getTaskIssueHistory: Array<{ __typename?: 'TaskIssue' }
    & TaskIssueFragmentFragment
  > }
);

export type JobHistoryRecordFragmentFragment = (
  { __typename?: 'JobHistoryRecord' }
  & Pick<JobHistoryRecord, 'id' | 'type' | 'internalStatus' | 'currentStatus' | 'desiredStatus' | 'nextStatus' | 'transition' | 'propsVersion' | 'propsJson' | 'stateVersion' | 'stateJson' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'updateSummary' | 'minorUpdate' | 'completedAt' | 'archivedAt'>
);

export type ScheduleJobStatusTransitionMutationVariables = {
  jobId: Scalars['String'],
  desiredStatus: Scalars['String'],
  force: Scalars['Boolean']
};


export type ScheduleJobStatusTransitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'scheduleJobStatusTransition'>
);

export type TaskHistoryRecordFragmentFragment = (
  { __typename?: 'TaskHistoryRecord' }
  & Pick<TaskHistoryRecord, 'jobId' | 'taskId' | 'transition' | 'type' | 'internalStatus' | 'propsVersion' | 'propsJson' | 'stateVersion' | 'stateJson' | 'resultVersion' | 'resultJson' | 'abortReason' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'updateSummary' | 'minorUpdate' | 'completedAt' | 'archivedAt'>
);

export type TaskIssueFragmentFragment = (
  { __typename?: 'TaskIssue' }
  & Pick<TaskIssue, 'jobId' | 'taskId' | 'transition' | 'issueId' | 'summary' | 'details' | 'createdAt' | 'confirmedAt' | 'timesConfirmed' | 'notifiedAt' | 'timesNotified' | 'resolvedAt'>
);

export type AddMigrationNoteMutationVariables = {
  migrationId: Scalars['String'],
  type: MigrationNoteType,
  message: Scalars['String']
};


export type AddMigrationNoteMutation = (
  { __typename?: 'Mutation' }
  & { addMigrationNote: { __typename?: 'MigrationNote' }
    & MigrationNoteFragment
   }
);

export type MigrationNoteListEventsSubscriptionVariables = {
  migrationIds: Array<Scalars['String']>
};


export type MigrationNoteListEventsSubscription = (
  { __typename?: 'Subscription' }
  & { subscribeForMigrationNoteListEvents: (
    { __typename?: 'MigrationNoteListEvent' }
    & Pick<MigrationNoteListEvent, 'eventType'>
    & { migrationNotes: Array<{ __typename?: 'MigrationNote' }
      & MigrationNoteFragment
    > }
  ) }
);

export type MigrationNotesQueryVariables = {
  migrationId: Scalars['String']
};


export type MigrationNotesQuery = (
  { __typename?: 'Query' }
  & { migration: (
    { __typename?: 'Migration' }
    & Pick<Migration, 'id'>
    & { notes: { __typename?: 'MigrationNoteList' }
      & MigrationNoteListFragment
     }
  ) }
);

export type ReopenMigrationNoteMutationVariables = {
  noteId: Scalars['Int']
};


export type ReopenMigrationNoteMutation = (
  { __typename?: 'Mutation' }
  & { reopenMigrationNote: { __typename?: 'MigrationNote' }
    & MigrationNoteFragment
   }
);

export type ResolveMigrationNoteMutationVariables = {
  noteId: Scalars['Int']
};


export type ResolveMigrationNoteMutation = (
  { __typename?: 'Mutation' }
  & { resolveMigrationNote: { __typename?: 'MigrationNote' }
    & MigrationNoteFragment
   }
);

export type UpdateMigrationNoteMutationVariables = {
  noteId: Scalars['Int'],
  type: MigrationNoteType,
  message: Scalars['String']
};


export type UpdateMigrationNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateMigrationNote: { __typename?: 'MigrationNote' }
    & MigrationNoteFragment
   }
);

export type AbortMigrationMutationVariables = {
  migrationId: Scalars['String'],
  authorizationAction: AuthorizationAction
};


export type AbortMigrationMutation = (
  { __typename?: 'Mutation' }
  & { abortMigration: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type FindOrganizationMigrationsMutationVariables = {
  organizationId: Scalars['Int'],
  searchCriteria: Scalars['String']
};


export type FindOrganizationMigrationsMutation = (
  { __typename?: 'Mutation' }
  & { findOrganizationMigrations: Array<{ __typename?: 'Migration' }
    & MigrationFragment
    & MigrationConnectionsFragment
  > }
);

export type FreshMigrationIssueListsQueryVariables = {
  migrationIds: Array<Scalars['String']>
};


export type FreshMigrationIssueListsQuery = (
  { __typename?: 'Query' }
  & { migrations: Array<(
    { __typename?: 'Migration' }
    & { issues: { __typename?: 'MigrationIssueList' }
      & MigrationIssueListFragment
     }
  )> }
);

export type FreshMigrationsQueryVariables = {
  migrationIds: Array<Scalars['String']>
};


export type FreshMigrationsQuery = (
  { __typename?: 'Query' }
  & { migrations: Array<{ __typename?: 'Migration' }
    & MigrationFragment
  > }
);

export type GetAuthorizationStatusQueryVariables = {
  migrationId: Scalars['String']
};


export type GetAuthorizationStatusQuery = (
  { __typename?: 'Query' }
  & { getAuthorizationStatus: (
    { __typename?: 'AuthorizationStatus' }
    & Pick<AuthorizationStatus, 'isPending' | 'transactionStatus'>
  ) }
);

export type GetOrganizationMigrationStatsQueryVariables = {
  organizationId: Scalars['Int'],
  timeUnit: ActivityTimeUnit,
  timeUnitCount: Scalars['Int'],
  recentMigrationDayCount: Scalars['Int'],
  timeZoneOffset: Scalars['Int']
};


export type GetOrganizationMigrationStatsQuery = (
  { __typename?: 'Query' }
  & { organizationProgramsPerformance: Array<(
    { __typename?: 'ProgramPerformance' }
    & Pick<ProgramPerformance, 'programName'>
    & { dataPoints: Array<(
      { __typename?: 'ProgramPerformance_DataPoint' }
      & Pick<ProgramPerformance_DataPoint, 'timestamp' | 'migrationCount'>
    )> }
  )>, organizationMigrationStats: (
    { __typename?: 'OrganizationMigrationStats' }
    & Pick<OrganizationMigrationStats, 'totalMigrationCount' | 'recentMigrationCount'>
  ) }
);

export type IterationProgressQueryVariables = {
  migrationId: Scalars['String'],
  iterationIndex: Scalars['Int']
};


export type IterationProgressQuery = (
  { __typename?: 'Query' }
  & { iteration: (
    { __typename?: 'Iteration' }
    & { facts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
      & FactOrPlaceholderFragment
    > }
  )
    & IterationFragment
   }
);

export type LaunchIterationMutationVariables = {
  migrationId: Scalars['String']
};


export type LaunchIterationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'launchIteration'>
);

export type LaunchMigrationMutationVariables = {
  orderId: Scalars['String'],
  expectedAmountToBePaid: Scalars['BigDecimal'],
  discounts?: Maybe<Discounts>,
  braintreePayloadJson?: Maybe<Scalars['String']>,
  deviceData?: Maybe<Scalars['String']>,
  externalPayment?: Maybe<ExternalPayment>
};


export type LaunchMigrationMutation = (
  { __typename?: 'Mutation' }
  & { launchMigration: (
    { __typename?: 'LaunchMigrationResult' }
    & { migration: { __typename?: 'Migration' }
      & MigrationFragment
    , user: { __typename?: 'User' }
      & UserFragment
     }
  ) }
);

export type MigrationAdminSidebarDataQueryVariables = {
  migrationId: Scalars['String']
};


export type MigrationAdminSidebarDataQuery = (
  { __typename?: 'Query' }
  & { migration: (
    { __typename?: 'Migration' }
    & { order: Maybe<{ __typename?: 'Order' }
      & OrderFragment
      & OrderRevenueFragment
    >, notes: { __typename?: 'MigrationNoteList' }
      & MigrationNoteListFragment
    , activeJobIssues: Array<(
      { __typename?: 'TaskIssue' }
      & Pick<TaskIssue, 'issueId' | 'summary' | 'createdAt'>
    )>, issues: { __typename?: 'MigrationIssueList' }
      & MigrationIssueListFragment
     }
  )
    & MigrationFragment
    & MigrationConnectionsFragment
   }
);

export type MigrationEventsSubscriptionVariables = {
  migrationIds: Array<Scalars['String']>
};


export type MigrationEventsSubscription = (
  { __typename?: 'Subscription' }
  & { subscribeForMigrationEvents: (
    { __typename?: 'MigrationEvent' }
    & Pick<MigrationEvent, 'eventType'>
    & { migrations: Array<{ __typename?: 'Migration' }
      & MigrationFragment
    > }
  ) }
);

export type MigrationHistoryQueryVariables = {
  migrationId: Scalars['String']
};


export type MigrationHistoryQuery = (
  { __typename?: 'Query' }
  & { migration: (
    { __typename?: 'Migration' }
    & { history: Array<(
      { __typename?: 'MigrationHistoryItem' }
      & Pick<MigrationHistoryItem, 'isEvent' | 'startedAt' | 'actor' | 'type' | 'details'>
      & { completion: Maybe<(
        { __typename?: 'MigrationHistoryItemCompletion' }
        & Pick<MigrationHistoryItemCompletion, 'completedAt' | 'details'>
      )> }
    )> }
  )
    & MigrationFragment
   }
);

export type MigrationIssueListEventsSubscriptionVariables = {
  migrationIds: Array<Scalars['String']>
};


export type MigrationIssueListEventsSubscription = (
  { __typename?: 'Subscription' }
  & { subscribeForMigrationIssueListEvents: (
    { __typename?: 'MigrationIssueListEvent' }
    & Pick<MigrationIssueListEvent, 'eventType'>
    & { migrationIssues: Array<(
      { __typename?: 'MigrationIssueList' }
      & Pick<MigrationIssueList, 'migrationId'>
      & { issues: Array<{ __typename?: 'MigrationIssue' }
        & MigrationIssueFragment
      > }
    )> }
  ) }
);

export type MigrationIterationsQueryVariables = {
  migrationId: Scalars['String']
};


export type MigrationIterationsQuery = (
  { __typename?: 'Query' }
  & { iterations: Array<{ __typename?: 'Iteration' }
    & IterationFragment
  > }
);

export type MigrationProgressQueryVariables = {
  migrationId: Scalars['String']
};


export type MigrationProgressQuery = (
  { __typename?: 'Query' }
  & { migration: (
    { __typename?: 'Migration' }
    & { facts: Array<({ __typename?: 'Fact' } | { __typename?: 'FactPlaceholder' })
      & FactOrPlaceholderFragment
    >, issues: { __typename?: 'MigrationIssueList' }
      & MigrationIssueListFragment
     }
  )
    & MigrationFragment
    & MigrationConnectionsFragment
    & MigrationOrderSummaryFragment
   }
);

export type MonitoringDashboardDataQueryVariables = {
  includeCompleted: Scalars['Int']
};


export type MonitoringDashboardDataQuery = (
  { __typename?: 'Query' }
  & { monitoredMigrations: Array<(
    { __typename?: 'Migration' }
    & Pick<Migration, 'value'>
    & { activeNotes: { __typename?: 'MigrationNoteList' }
      & MigrationNoteListFragment
    , activeJobIssues: Array<(
      { __typename?: 'TaskIssue' }
      & Pick<TaskIssue, 'issueId' | 'summary' | 'createdAt'>
    )>, issues: { __typename?: 'MigrationIssueList' }
      & MigrationIssueListFragment
     }
  )
    & MigrationFragment
    & MigrationConnectionsFragment
  >, recentMigrationsActivity: Array<(
    { __typename?: 'MigrationsActivity' }
    & Pick<MigrationsActivity, 'timestamp' | 'count'>
  )> }
);

export type PauseMigrationMutationVariables = {
  migrationId: Scalars['String']
};


export type PauseMigrationMutation = (
  { __typename?: 'Mutation' }
  & { pauseMigration: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type PlaceOrderResult_SuccessFragment = (
  { __typename?: 'PlaceOrderResult_Success' }
  & Pick<PlaceOrderResult_Success, 'orderId' | 'totalBytes' | 'totalItems' | 'originalBasePrice' | 'originalBytesFee' | 'originalItemsFee' | 'priceBeforeDiscounts' | 'clientToken'>
  & { priceCalculator: { __typename?: 'SimplePricingModelCalculator' }
    & PricingModelCalculatorFragment
  , school: Maybe<{ __typename?: 'School' }
    & SchoolSummaryFragment
  > }
);

export type PlaceOrderResultFragment = (
  { __typename?: 'PlaceOrderResult_Success' }
  & PlaceOrderResult_SuccessFragment
) | (
  { __typename?: 'PlaceOrderResult_ConnectionsAreInUse' }
  & Pick<PlaceOrderResult_ConnectionsAreInUse, 'migrationIds'>
);

export type PlaceOrderMutationVariables = {
  sourceCloudServiceId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  programAlias?: Maybe<Scalars['String']>,
  blueprintInputs: Array<BlueprintInputsEntryInput>,
  checkboxesState?: Maybe<CheckboxesState>
};


export type PlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder: ({ __typename?: 'PlaceOrderResult_Success' } | { __typename?: 'PlaceOrderResult_ConnectionsAreInUse' })
    & PlaceOrderResultFragment
   }
);

export type RefundTransactionMutationVariables = {
  migrationId: Scalars['String']
};


export type RefundTransactionMutation = (
  { __typename?: 'Mutation' }
  & { refundTransaction: Maybe<{ __typename?: 'Order' }
    & OrderFragment
  > }
);

export type ReleaseMigrationFromSupervisionMutationVariables = {
  migrationId: Scalars['String'],
  note?: Maybe<Scalars['String']>
};


export type ReleaseMigrationFromSupervisionMutation = (
  { __typename?: 'Mutation' }
  & { releaseFromSupervision: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type ResolveIssueMutationVariables = {
  migrationId: Scalars['String'],
  issueId: Scalars['String']
};


export type ResolveIssueMutation = (
  { __typename?: 'Mutation' }
  & { resolveIssue: (
    { __typename?: 'ResolveIssueResult_NoActiveIssueFound' }
    & Pick<ResolveIssueResult_NoActiveIssueFound, 'foo'>
  ) | (
    { __typename?: 'ResolveIssueResult_Resolved' }
    & { issue: { __typename?: 'MigrationIssue' }
      & MigrationIssueFragment
     }
  ) | (
    { __typename?: 'ResolveIssueResult_PartiallyResolved' }
    & { issue: { __typename?: 'MigrationIssue' }
      & MigrationIssueFragment
     }
  ) }
);

export type ResumeMigrationMutationVariables = {
  migrationId: Scalars['String']
};


export type ResumeMigrationMutation = (
  { __typename?: 'Mutation' }
  & { resumeMigration: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type SetAutoResumingConfigurationMutationVariables = {
  migrationId: Scalars['String'],
  enabled: Scalars['Boolean'],
  timestamp?: Maybe<Scalars['String']>
};


export type SetAutoResumingConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { setAutoResumingConfiguration: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type SetMigrationFeedbackMutationVariables = {
  migrationId: Scalars['String'],
  question: Scalars['String'],
  answer: Scalars['String']
};


export type SetMigrationFeedbackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMigrationFeedback'>
);

export type SubmitTransactionForSettlementMutationVariables = {
  migrationId: Scalars['String']
};


export type SubmitTransactionForSettlementMutation = (
  { __typename?: 'Mutation' }
  & { submitTransactionForSettlement: Maybe<{ __typename?: 'Order' }
    & OrderFragment
  > }
);

export type TakeMigrationForSupervisionMutationVariables = {
  migrationId: Scalars['String']
};


export type TakeMigrationForSupervisionMutation = (
  { __typename?: 'Mutation' }
  & { takeForSupervision: { __typename?: 'Migration' }
    & MigrationFragment
   }
);

export type UserMigrationsQueryVariables = {
  userId: Scalars['String'],
  includeBatched: Scalars['Boolean']
};


export type UserMigrationsQuery = (
  { __typename?: 'Query' }
  & { userMigrations: Array<{ __typename?: 'Migration' }
    & MigrationFragment
    & MigrationConnectionsFragment
  >, userBatches: Array<{ __typename?: 'Batch' }
    & BatchFragment
  > }
);

export type VoidTransactionMutationVariables = {
  migrationId: Scalars['String']
};


export type VoidTransactionMutation = (
  { __typename?: 'Mutation' }
  & { voidTransaction: Maybe<{ __typename?: 'Order' }
    & OrderFragment
  > }
);

export type DeleteOrganizationPortalUserMutationVariables = {
  id: Scalars['Int']
};


export type DeleteOrganizationPortalUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganizationPortalUser'>
);

export type GetOrganizationPortalUserQueryVariables = {
  id: Scalars['Int']
};


export type GetOrganizationPortalUserQuery = (
  { __typename?: 'Query' }
  & { organizationPortalUser: { __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
   }
);

export type InsertOrganizationPortalUserMutationVariables = {
  organizationId: Scalars['Int'],
  formData: OrganizationPortalUserFormData
};


export type InsertOrganizationPortalUserMutation = (
  { __typename?: 'Mutation' }
  & { insertOrganizationPortalUser: { __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
   }
);

export type InsertOrganizationPortalUsersMutationVariables = {
  organizationId: Scalars['Int'],
  emailAddresses: Array<Scalars['String']>,
  role: OrganizationPortalUserRole
};


export type InsertOrganizationPortalUsersMutation = (
  { __typename?: 'Mutation' }
  & { insertOrganizationPortalUsers: Array<{ __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
  > }
);

export type SearchOrganizationPortalUsersQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchOrganizationPortalUsersQuery = (
  { __typename?: 'Query' }
  & { searchOrganizationPortalUsers: Array<{ __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
  > }
);

export type UpdateOrganizationPortalUserMutationVariables = {
  id: Scalars['Int'],
  formData: OrganizationPortalUserFormData
};


export type UpdateOrganizationPortalUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationPortalUser: { __typename?: 'OrganizationPortalUser' }
    & OrganizationPortalUserFragment
   }
);

export type ClearSponsorshipLimitAmountRemainingMutationVariables = {
  organizationId: Scalars['Int']
};


export type ClearSponsorshipLimitAmountRemainingMutation = (
  { __typename?: 'Mutation' }
  & { clearSponsorshipLimitAmountRemaining: { __typename?: 'Organization' }
    & OrganizationSummaryFragment
   }
);

export type DeleteOrganizationMutationVariables = {
  id: Scalars['Int']
};


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganization'>
);

export type GetOrganizationQueryVariables = {
  id: Scalars['Int']
};


export type GetOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: { __typename?: 'Organization' }
    & OrganizationFragment
   }
);

export type GetOrganizationContextQueryVariables = {
  idOrAlias: Scalars['String']
};


export type GetOrganizationContextQuery = (
  { __typename?: 'Query' }
  & { organizationContext: { __typename?: 'OrganizationContext' }
    & OrganizationContextFragment
   }
);

export type IncrementSponsorshipLimitAmountRemainingMutationVariables = {
  organizationId: Scalars['Int'],
  amount: Scalars['BigDecimal']
};


export type IncrementSponsorshipLimitAmountRemainingMutation = (
  { __typename?: 'Mutation' }
  & { incrementSponsorshipLimitAmountRemaining: { __typename?: 'Organization' }
    & OrganizationSummaryFragment
   }
);

export type InsertOrganizationMutationVariables = {
  formData: OrganizationFormData
};


export type InsertOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { insertOrganization: { __typename?: 'Organization' }
    & OrganizationFragment
   }
);

export type SearchOrganizationsQueryVariables = {
  term: Scalars['String']
};


export type SearchOrganizationsQuery = (
  { __typename?: 'Query' }
  & { searchOrganizations: Array<{ __typename?: 'Organization' }
    & OrganizationFragment
  > }
);

export type UpdateOrganizationMutationVariables = {
  id: Scalars['Int'],
  formData: OrganizationFormData
};


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: { __typename?: 'Organization' }
    & OrganizationFragment
   }
);

export type BecomeAnAmbassadorMutationVariables = {
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  customMessage?: Maybe<Scalars['String']>,
  accountAction: AccountAction,
  accountActionDate: Scalars['Instant'],
  contacts: Array<AmbassadorsProgramContact>
};


export type BecomeAnAmbassadorMutation = (
  { __typename?: 'Mutation' }
  & { becomeAnAmbassador: { __typename?: 'User' }
    & UserFragment
   }
);

export type GetMigrationPriceCalculatorQueryVariables = {
  sourceConnectionId: Scalars['String'],
  destinationConnectionId: Scalars['String'],
  programAlias?: Maybe<Scalars['String']>
};


export type GetMigrationPriceCalculatorQuery = (
  { __typename?: 'Query' }
  & { getMigrationPriceCalculator: { __typename?: 'SimplePricingModelCalculator' }
    & PricingModelCalculatorFragment
   }
);

export type GetOrderQueryVariables = {
  orderId: Scalars['String']
};


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { order: { __typename?: 'Order' }
    & OrderFragment
    & OrderRevenueFragment
   }
);

export type GetSourceConnectionPromotionsQueryVariables = {
  currentProgramAlias?: Maybe<Scalars['String']>,
  sourceConnectionId: Scalars['String'],
  destinationCloudServiceId: Scalars['String'],
  destinationConnectionId?: Maybe<Scalars['String']>
};


export type GetSourceConnectionPromotionsQuery = (
  { __typename?: 'Query' }
  & { getSourceConnectionPromotions: (
    { __typename?: 'GetSourceConnectionPromotionsResult' }
    & Pick<GetSourceConnectionPromotionsResult, 'hasPreviousMigrationsFromSource'>
    & { currentProgram: Maybe<{ __typename?: 'Program' }
      & ProgramSummaryFragment
    >, programEligibility: Array<{ __typename?: 'ProgramEligibility' }
      & ProgramEligibilityFragment
    >, school: Maybe<{ __typename?: 'School' }
      & SchoolSummaryFragment
    > }
  ) }
);

export type RenderAmbassadorEmailPreviewMutationVariables = {
  sourceConnectionId: Scalars['String']
};


export type RenderAmbassadorEmailPreviewMutation = (
  { __typename?: 'Mutation' }
  & { renderAmbassadorEmailPreview: { __typename?: 'EmailPreview' }
    & EmailPreviewFragment
   }
);

export type SendTestAmbassadorEmailMutationVariables = {
  sourceConnectionId: Scalars['String'],
  customMessage?: Maybe<Scalars['String']>,
  accountAction: AccountAction,
  accountActionDate?: Maybe<Scalars['Instant']>
};


export type SendTestAmbassadorEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendTestAmbassadorEmail'>
);

export type ValidateAmbassadorCodeMutationVariables = {
  sourceConnectionId?: Maybe<Scalars['String']>,
  ambassadorCode: Scalars['String']
};


export type ValidateAmbassadorCodeMutation = (
  { __typename?: 'Mutation' }
  & { validateAmbassadorCode: (
    { __typename?: 'ValidateAmbassadorCodeResult' }
    & Pick<ValidateAmbassadorCodeResult, 'ambassadorCode'>
    & { referralCode: Maybe<{ __typename?: 'ReferralCode' }
      & ReferralCodeSummaryFragment
    > }
  ) }
);

export type ValidateAmbassadorsProgramEmailAddressMutationVariables = {
  sourceConnectionId: Scalars['String'],
  emailAddress: Scalars['String']
};


export type ValidateAmbassadorsProgramEmailAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validateAmbassadorsProgramEmailAddress'>
);

export type ValidateCouponCodeMutationVariables = {
  couponCodeId: Scalars['String']
};


export type ValidateCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { validateCouponCode: (
    { __typename?: 'CouponCodeSummary' }
    & Pick<CouponCodeSummary, 'id' | 'discount' | 'isPercentage' | 'remainingUses'>
  ) }
);

export type ValidateReferralCodeMutationVariables = {
  sourceConnectionId?: Maybe<Scalars['String']>,
  referralCode: Scalars['String']
};


export type ValidateReferralCodeMutation = (
  { __typename?: 'Mutation' }
  & { validateReferralCode: ({ __typename?: 'ValidateReferralCodeResult_Success' } | { __typename?: 'ValidateReferralCodeResult_NotEligible' })
    & ValidateReferralCodeResultFragment
   }
);

export type ValidateReferralCodeResultFragment = (
  { __typename?: 'ValidateReferralCodeResult_Success' }
  & { referralCode: { __typename?: 'ReferralCode' }
    & ReferralCodeSummaryFragment
   }
) | (
  { __typename?: 'ValidateReferralCodeResult_NotEligible' }
  & Pick<ValidateReferralCodeResult_NotEligible, 'governingOrganizationName'>
  & { referralCode: { __typename?: 'ReferralCode' }
    & ReferralCodeSummaryFragment
   }
);

export type DeletePricingModelMutationVariables = {
  id: Scalars['Int']
};


export type DeletePricingModelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePricingModel'>
);

export type GetPricingModelQueryVariables = {
  id: Scalars['Int']
};


export type GetPricingModelQuery = (
  { __typename?: 'Query' }
  & { pricingModel: { __typename?: 'PricingModel' }
    & PricingModelFragment
   }
);

export type InsertPricingModelMutationVariables = {
  formData: PricingModelFormData
};


export type InsertPricingModelMutation = (
  { __typename?: 'Mutation' }
  & { insertPricingModel: { __typename?: 'PricingModel' }
    & PricingModelFragment
   }
);

export type SearchPricingModelsQueryVariables = {
  term: Scalars['String']
};


export type SearchPricingModelsQuery = (
  { __typename?: 'Query' }
  & { searchPricingModels: Array<{ __typename?: 'PricingModel' }
    & PricingModelFragment
  > }
);

export type UpdatePricingModelMutationVariables = {
  id: Scalars['Int'],
  formData: PricingModelFormData
};


export type UpdatePricingModelMutation = (
  { __typename?: 'Mutation' }
  & { updatePricingModel: { __typename?: 'PricingModel' }
    & PricingModelFragment
   }
);

export type DeleteProgramMutationVariables = {
  id: Scalars['String']
};


export type DeleteProgramMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProgram'>
);

export type GetProgramQueryVariables = {
  id: Scalars['String']
};


export type GetProgramQuery = (
  { __typename?: 'Query' }
  & { program: { __typename?: 'Program' }
    & ProgramFragment
   }
);

export type InsertProgramMutationVariables = {
  organizationId: Scalars['Int'],
  formData: ProgramFormData
};


export type InsertProgramMutation = (
  { __typename?: 'Mutation' }
  & { insertProgram: { __typename?: 'Program' }
    & ProgramFragment
   }
);

export type ListProgramsWithRevenueSharingQueryVariables = {
  organizationId: Scalars['Int']
};


export type ListProgramsWithRevenueSharingQuery = (
  { __typename?: 'Query' }
  & { listProgramsWithRevenueSharing: Array<{ __typename?: 'Program' }
    & ProgramFragment
  > }
);

export type SearchProgramsQueryVariables = {
  organizationId?: Maybe<Scalars['Int']>,
  term: Scalars['String']
};


export type SearchProgramsQuery = (
  { __typename?: 'Query' }
  & { searchPrograms: Array<{ __typename?: 'Program' }
    & ProgramFragment
  > }
);

export type TestAccountWhitelistingsForProgramsQueryVariables = {};


export type TestAccountWhitelistingsForProgramsQuery = (
  { __typename?: 'Query' }
  & { testAccountWhitelistingsForPrograms: Array<{ __typename?: 'EmailAddressWhitelistingForProgram' }
    & EmailAddressWhitelistingForProgramFragment
  > }
);

export type UpdateProgramMutationVariables = {
  formData: ProgramFormData
};


export type UpdateProgramMutation = (
  { __typename?: 'Mutation' }
  & { updateProgram: { __typename?: 'Program' }
    & ProgramFragment
   }
);

export type WhitelistEmailAddressForProgramMutationVariables = {
  emailAddress: Scalars['String'],
  programId?: Maybe<Scalars['String']>
};


export type WhitelistEmailAddressForProgramMutation = (
  { __typename?: 'Mutation' }
  & { whitelistEmailAddressForProgram: { __typename?: 'EmailAddressWhitelistingForProgram' }
    & EmailAddressWhitelistingForProgramFragment
   }
);

export type DeleteReferralCodeMutationVariables = {
  id: Scalars['Int']
};


export type DeleteReferralCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReferralCode'>
);

export type GetReferralCodeQueryVariables = {
  id: Scalars['Int']
};


export type GetReferralCodeQuery = (
  { __typename?: 'Query' }
  & { referralCode: { __typename?: 'ReferralCode' }
    & ReferralCodeFragment
   }
);

export type InsertReferralCodeMutationVariables = {
  organizationId: Scalars['Int'],
  formData: ReferralCodeFormData
};


export type InsertReferralCodeMutation = (
  { __typename?: 'Mutation' }
  & { insertReferralCode: { __typename?: 'ReferralCode' }
    & ReferralCodeFragment
   }
);

export type SearchReferralCodesQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchReferralCodesQuery = (
  { __typename?: 'Query' }
  & { searchReferralCodes: Array<{ __typename?: 'ReferralCode' }
    & ReferralCodeFragment
  > }
);

export type UpdateReferralCodeMutationVariables = {
  id: Scalars['Int'],
  formData: ReferralCodeFormData
};


export type UpdateReferralCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateReferralCode: { __typename?: 'ReferralCode' }
    & ReferralCodeFragment
   }
);

export type FailWhenZeroMutationVariables = {
  argument: Scalars['Int']
};


export type FailWhenZeroMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'failWhenZero'>
);

export type DeleteSchoolMutationVariables = {
  id: Scalars['String']
};


export type DeleteSchoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSchool'>
);

export type GetSchoolQueryVariables = {
  id: Scalars['String']
};


export type GetSchoolQuery = (
  { __typename?: 'Query' }
  & { school: { __typename?: 'School' }
    & SchoolFragment
   }
);

export type GetSchoolStatsQueryVariables = {
  id: Scalars['String']
};


export type GetSchoolStatsQuery = (
  { __typename?: 'Query' }
  & { schoolStats: (
    { __typename?: 'SchoolStats' }
    & Pick<SchoolStats, 'schoolId' | 'migrationCount' | 'ambassadorCount'>
    & { accountActionStats: Array<(
      { __typename?: 'SchoolAccountActionStats' }
      & Pick<SchoolAccountActionStats, 'date' | 'action' | 'count'>
    )> }
  ) }
);

export type InsertSchoolMutationVariables = {
  formData: SchoolFormData
};


export type InsertSchoolMutation = (
  { __typename?: 'Mutation' }
  & { insertSchool: { __typename?: 'School' }
    & SchoolFragment
   }
);

export type SearchSchoolsQueryVariables = {
  term: Scalars['String']
};


export type SearchSchoolsQuery = (
  { __typename?: 'Query' }
  & { searchSchools: Array<{ __typename?: 'School' }
    & SchoolFragment
  > }
);

export type UpdateSchoolMutationVariables = {
  id: Scalars['String'],
  formData: SchoolFormData
};


export type UpdateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { updateSchool: { __typename?: 'School' }
    & SchoolFragment
   }
);

export type ExternalHelpArticleFragment = (
  { __typename?: 'ExternalHelpArticle' }
  & Pick<ExternalHelpArticle, 'content' | 'title' | 'summary'>
);

export type VariantFragment = (
  { __typename?: 'VariantBoolean' }
  & Pick<VariantBoolean, 'booleanValue'>
) | (
  { __typename?: 'VariantLong' }
  & Pick<VariantLong, 'longValue'>
) | (
  { __typename?: 'CollectableBoolean' }
  & CollectableFragment
) | (
  { __typename?: 'CollectableInteger' }
  & CollectableFragment
) | (
  { __typename?: 'CollectableItemBreakdown' }
  & CollectableFragment
);

export type DeleteThemeMutationVariables = {
  id: Scalars['Int']
};


export type DeleteThemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTheme'>
);

export type GetThemeQueryVariables = {
  id: Scalars['Int']
};


export type GetThemeQuery = (
  { __typename?: 'Query' }
  & { theme: { __typename?: 'Theme' }
    & ThemeFragment
   }
);

export type InsertThemeMutationVariables = {
  organizationId: Scalars['Int'],
  formData: ThemeFormData
};


export type InsertThemeMutation = (
  { __typename?: 'Mutation' }
  & { insertTheme: { __typename?: 'Theme' }
    & ThemeFragment
   }
);

export type SearchThemesQueryVariables = {
  organizationId: Scalars['Int'],
  term: Scalars['String']
};


export type SearchThemesQuery = (
  { __typename?: 'Query' }
  & { searchThemes: Array<{ __typename?: 'Theme' }
    & ThemeFragment
  > }
);

export type UpdateThemeMutationVariables = {
  id: Scalars['Int'],
  formData: ThemeFormData
};


export type UpdateThemeMutation = (
  { __typename?: 'Mutation' }
  & { updateTheme: { __typename?: 'Theme' }
    & ThemeFragment
   }
);

export type AmbassadorStatusFragment = (
  { __typename?: 'AmbassadorStatus' }
  & Pick<AmbassadorStatus, 'ambassadorCode' | 'totalContestEntries'>
  & { school: { __typename?: 'School' }
    & SchoolSummaryFragment
  , recentContestEntries: Array<(
    { __typename?: 'ContestEntry' }
    & Pick<ContestEntry, 'event' | 'count' | 'createdAt'>
  )> }
);

export type GetAmbassadorStatusQueryVariables = {
  userId: Scalars['String']
};


export type GetAmbassadorStatusQuery = (
  { __typename?: 'Query' }
  & { ambassadorStatus: { __typename?: 'AmbassadorStatus' }
    & AmbassadorStatusFragment
   }
);

export type GetUserQueryVariables = {
  userId: Scalars['String']
};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: { __typename?: 'User' }
    & UserFragment
   }
);

export type GetUserSummaryQueryVariables = {
  userId: Scalars['String']
};


export type GetUserSummaryQuery = (
  { __typename?: 'Query' }
  & { getUserSummary: (
    { __typename?: 'UserSummary' }
    & Pick<UserSummary, 'id' | 'firstName' | 'lastName' | 'emailAddress'>
  ) }
);

export type SearchUsersQueryVariables = {
  term: Scalars['String']
};


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchUsers: Array<{ __typename?: 'User' }
    & UserFragment
  > }
);

export type UpdateCustomerDetailsMutationVariables = {
  userId: Scalars['String'],
  customerDetails: Scalars['String']
};


export type UpdateCustomerDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerDetails: { __typename?: 'User' }
    & UserFragment
   }
);

export type UpgradeUserMutationVariables = {
  userId: Scalars['String'],
  connectionId: Scalars['String']
};


export type UpgradeUserMutation = (
  { __typename?: 'Mutation' }
  & { upgradeUser: { __typename?: 'User' }
    & UserFragment
   }
);
export const AccessListFragmentDoc = gql`
    fragment AccessList on AccessList {
  id
  organizationId
  name
  notes
  isEnabled
  isProgramAllowlist
  program {
    id
    name
  }
  blockingMessage
  googleGroupId
  currentUpdateId
  lastUpdateStartedAt
  createdAt
  updatedAt
}
    `;
export const AccessListUpdateFragmentDoc = gql`
    fragment AccessListUpdate on AccessListUpdate {
  id
  accessListId
  status
  addedEntries
  removedEntries
  skippedEntries
  errors
  errorMessage
  createdAt
  updatedAt
  completedAt
}
    `;
export const ProgramSummaryFragmentDoc = gql`
    fragment ProgramSummary on Program {
  id
  organizationId
  alias
  name
  description
  productName
  landingPageUrl
  logoId
  configurationId
  introBannerId
  introTitle
  introContent
  pricingPageBannerId
  pricingPageTitle
  pricingPageContent
  pricingModelId
  status
  sourceCloudServiceId
  destinationCloudServiceId
  sourceDomains
  destinationDomains
  discount
  discountType
  sponsoredAmount
}
    `;
export const ThemeOverridesFragmentDoc = gql`
    fragment ThemeOverrides on Theme {
  primary
  darkPrimary
  darkerPrimary
  lightPrimary
  faintPrimary
  red
  lightRed
  yellow
  lightYellow
  orange
  lightOrange
  green
  lightGreen
  titleColor
  textColor
  useMonochromeLogo
}
    `;
export const ReferralCodeSummaryFragmentDoc = gql`
    fragment ReferralCodeSummary on ReferralCode {
  id
  code
  organizationId
  isEnabled
  url
  discount
  discountType
  announcement {
    enabled
    bannerBackground
    bannerVaultMeLogoVersion
    bannerOrganizationLogoId
    bannerOrganizationLogoWidth
    bannerOrganizationLogoOffsetXRow
    bannerOrganizationLogoOffsetYRow
    bannerOrganizationLogoOffsetXColumn
    bannerOrganizationLogoOffsetYColumn
    titleBackground
    title
    bodyBackground
    bodyDesktop
    bodyMobile
    buttonColor
    buttonVersion
  }
  beacon {
    enabled
    logoId
    logoWidth
  }
}
    `;
export const AppBootstrapConfigFragmentDoc = gql`
    fragment AppBootstrapConfig on AppBootstrapConfig {
  development
  production
  cloudServices {
    title
    cloudServices {
      id
      name
      logo
      icon
      color
      authProviderId
      accessKeyParams
    }
  }
  authProviders {
    id
    roles
    accessKeyValidationCapabilities
  }
  ambassadorsProgram {
    link
    signUpDiscount
    codeDiscount
    minNumberOfContacts
    maxNumberOfContacts
    contestEntryExpirationMonths
  }
  microsoftAppId
  microsoftSignInButtonCss
  microsoftSignInButtonIcon
  microsoftOAuthCallbackPath
  googleMerchantId
  enableVenmo
  program {
    ...ProgramSummary
  }
  configuration {
    id
    headerSuffix
    mainLogoId
    escapeLinkText
    introBannerId
    introTitle
    introContent
    pricingPageBannerId
    pricingPageTitle
    pricingPageContent
    sourceCloudServiceReference
    destinationCloudServiceReference
    enableFeedback
    feedbackQuestion
    checkbox1Label
    checkbox1Required
    checkbox2Label
    checkbox2Required
    checkbox3Label
    checkbox3Required
    cloudServices {
      isSource
      cloudServiceId
      index
      reference
      description
      logoId
      excludedApps
    }
  }
  theme {
    id
    ...ThemeOverrides
  }
  referralCode {
    ...ReferralCodeSummary
  }
}
    ${ProgramSummaryFragmentDoc}
${ThemeOverridesFragmentDoc}
${ReferralCodeSummaryFragmentDoc}`;
export const CouponCodeDiscountFragmentDoc = gql`
    fragment CouponCodeDiscount on CouponCodeDiscount {
  code
  value
  isPercentage
  amount
}
    `;
export const ReferralCodeDiscountFragmentDoc = gql`
    fragment ReferralCodeDiscount on ReferralCodeDiscount {
  code
  percentage
  discountType
  amount
}
    `;
export const BatchOrderFragmentDoc = gql`
    fragment BatchOrder on BatchOrder {
  pricingModelName
  pricingModelType
  pricingModelVersion
  pricingModelJson
  totalAccounts
  totalBytes
  totalItems
  accountsFee
  bytesFee
  itemsFee
  priceBeforeDiscounts
  couponCodeDiscount {
    ...CouponCodeDiscount
  }
  referralCodeDiscount {
    ...ReferralCodeDiscount
  }
  amountToBePaid
  paidAmount
  priceBreakdown
  transactionId
  braintreeTransactionDetailsUrl
}
    ${CouponCodeDiscountFragmentDoc}
${ReferralCodeDiscountFragmentDoc}`;
export const BatchFragmentDoc = gql`
    fragment Batch on Batch {
  id
  userId
  sourceCloudServiceId
  destinationCloudServiceId
  name
  status
  batchOrder {
    ...BatchOrder
  }
  createdAt
  updatedAt
  startedAt
  completedAt
  jobId
}
    ${BatchOrderFragmentDoc}`;
export const MigrationStatsFragmentDoc = gql`
    fragment MigrationStats on MigrationStats {
  totalBytes
  totalItems
  migratedBytes
  migratedItems
  skippedBytes
  skippedItems
}
    `;
export const MigrationFragmentDoc = gql`
    fragment Migration on Migration {
  id
  batch {
    batchId
    index
  }
  userId
  sourceCloudServiceId
  destinationCloudServiceId
  sourceConnectionId
  destinationConnectionId
  blueprintInputs {
    key
    value
  }
  totalBytesEstimate
  totalItemsEstimate
  timeEstimate
  iteration
  status
  autoResumeAt
  isAutoResumingEnabled
  cumulativeStats {
    ...MigrationStats
  }
  iterationStats {
    ...MigrationStats
  }
  optimisticRemainingTime
  longestTrackProgress
  itemsForManualReview {
    phase
    itemType
    count
  }
  totalManualReviews
  supervisedBy
  reportUrl
  createdAt
  updatedAt
  startedAt
  completedAt
  iterationStartedAt
  iterationCompletedAt
  jobId
  workflowUrl
}
    ${MigrationStatsFragmentDoc}`;
export const PersonalInfoFragmentDoc = gql`
    fragment PersonalInfo on PersonalInfo {
  firstName
  lastName
  emailAddress
  phoneNumber
  payPalEmailAddress
  timeZone
}
    `;
export const ConnectionFragmentDoc = gql`
    fragment Connection on Connection {
  id
  userId
  cloudServiceId
  accessKeyProvider
  accessKeyStatus
  roles
  class
  description
  personalInfo {
    ...PersonalInfo
  }
  metadata
  enabled
  eligibleForSignIn
  createdAt
  updatedAt
  validatedAt
  signedInAt
  revealedAt
  revokedAt
}
    ${PersonalInfoFragmentDoc}`;
export const MigrationConnectionsFragmentDoc = gql`
    fragment MigrationConnections on Migration {
  sourceConnection {
    ...Connection
  }
  destinationConnection {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;
export const BatchMigrationsFragmentDoc = gql`
    fragment BatchMigrations on Batch {
  migrations {
    ...Migration
    ...MigrationConnections
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}`;
export const RevenueShareFragmentDoc = gql`
    fragment RevenueShare on RevenueShare {
  sourceType
  sourceId
  percentage
  amount
}
    `;
export const BatchOrderRevenueFragmentDoc = gql`
    fragment BatchOrderRevenue on BatchOrder {
  revenue
  revenueShare {
    ...RevenueShare
  }
}
    ${RevenueShareFragmentDoc}`;
export const UploadFragmentDoc = gql`
    fragment Upload on Upload {
  id
  name
  length
}
    `;
export const ConfigurationCloudServiceFragmentDoc = gql`
    fragment ConfigurationCloudService on ConfigurationCloudService {
  id
  configurationId
  isSource
  cloudServiceId
  index
  reference
  description
  logo {
    ...Upload
  }
  excludedApps
  createdAt
  updatedAt
}
    ${UploadFragmentDoc}`;
export const ConfigurationFragmentDoc = gql`
    fragment Configuration on Configuration {
  id
  name
  alias
  notes
  themeId
  headerSuffix
  mainLogo {
    ...Upload
  }
  escapeLinkText
  introBanner {
    ...Upload
  }
  introTitle
  introContent
  pricingPageBanner {
    ...Upload
  }
  pricingPageTitle
  pricingPageContent
  sourceCloudServiceReference
  destinationCloudServiceReference
  enableFeedback
  feedbackQuestion
  checkbox1Label
  checkbox1Required
  checkbox2Label
  checkbox2Required
  checkbox3Label
  checkbox3Required
  cloudServices {
    ...ConfigurationCloudService
  }
  createdAt
  updatedAt
}
    ${UploadFragmentDoc}
${ConfigurationCloudServiceFragmentDoc}`;
export const CouponCodeFragmentDoc = gql`
    fragment CouponCode on CouponCode {
  id
  notes
  enabled
  discount
  isPercentage
  remainingUses
  lastUsedFor
  createdAt
  updatedAt
}
    `;
export const ProgramFragmentDoc = gql`
    fragment Program on Program {
  ...ProgramSummary
  logo {
    ...Upload
  }
  notes
  endUserAccess
  introBanner {
    ...Upload
  }
  pricingPageBanner {
    ...Upload
  }
  sendMigrationNotificationsToAdmins
  sendFeedbackNotificationsToAdmins
  revenueShare
  createdAt
  updatedAt
}
    ${ProgramSummaryFragmentDoc}
${UploadFragmentDoc}`;
export const EmailAddressWhitelistingForProgramFragmentDoc = gql`
    fragment EmailAddressWhitelistingForProgram on EmailAddressWhitelistingForProgram {
  emailAddress
  program {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;
export const EmailDeliveryScheduleFragmentDoc = gql`
    fragment EmailDeliverySchedule on EmailDeliverySchedule {
  id
  name
  description
  isTest
}
    `;
export const EmailPreviewFragmentDoc = gql`
    fragment EmailPreview on EmailPreview {
  from
  to
  subject
  body
}
    `;
export const GoogleGroupFragmentDoc = gql`
    fragment GoogleGroup on GoogleGroup {
  internalId
  friendlyId
  name
  description
}
    `;
export const IterationFragmentDoc = gql`
    fragment Iteration on Iteration {
  migrationId
  index
  status
  stats {
    ...MigrationStats
  }
  createdAt
  updatedAt
  startedAt
  completedAt
}
    ${MigrationStatsFragmentDoc}`;
export const SponsorshipConfigFragmentDoc = gql`
    fragment SponsorshipConfig on SponsorshipConfig {
  programName
  maxSponsoredAmount
}
    `;
export const SimplePricingModelCalculatorFragmentDoc = gql`
    fragment SimplePricingModelCalculator on SimplePricingModelCalculator {
  pricingModelName
  basePriceDescription
  previousMigrationFromSource
  previousMigrationFromSourceToDestination
  programId
  firstMigrationBasePrice
  numberOfGbsIncluded
  numberOfItemsIncluded
  followUpMigration {
    previousMigrationFromSourceToDestination
  }
  originalBasePrice
  originalPricePerGb
  originalPricePerThousandItems
  programDiscount {
    programName
    percentage
    discountType
  }
  finalBasePrice
  dataFeeProgramDiscount
  sponsorshipConfig {
    ...SponsorshipConfig
  }
}
    ${SponsorshipConfigFragmentDoc}`;
export const PricingModelCalculatorFragmentDoc = gql`
    fragment PricingModelCalculator on PricingModelCalculator {
  ... on SimplePricingModelCalculator {
    ...SimplePricingModelCalculator
  }
}
    ${SimplePricingModelCalculatorFragmentDoc}`;
export const AmbassadorStatusDiscountFragmentDoc = gql`
    fragment AmbassadorStatusDiscount on AmbassadorStatusDiscount {
  amount
  isReturningAmbassadorDiscount
  returningAmbassadorPercentage
}
    `;
export const AmbassadorCodeDiscountFragmentDoc = gql`
    fragment AmbassadorCodeDiscount on AmbassadorCodeDiscount {
  code
  percentage
  amount
}
    `;
export const OrderSummaryFragmentDoc = gql`
    fragment OrderSummary on OrderSummary {
  program {
    id
    alias
    name
    status
    sponsoredAmount
  }
  totalBytes
  totalItems
  priceCalculator {
    ...PricingModelCalculator
  }
  originalBasePrice
  originalBytesFee
  originalItemsFee
  priceBeforeDiscounts
  couponCodeDiscount {
    ...CouponCodeDiscount
  }
  referralCodeDiscount {
    ...ReferralCodeDiscount
  }
  ambassadorStatusDiscount {
    ...AmbassadorStatusDiscount
  }
  ambassadorCodeDiscount {
    ...AmbassadorCodeDiscount
  }
  priceAfterDiscounts
  usedSponsorship {
    sponsorshipConfig {
      ...SponsorshipConfig
    }
    sponsoredAmount
  }
  amountToBePaid
}
    ${PricingModelCalculatorFragmentDoc}
${CouponCodeDiscountFragmentDoc}
${ReferralCodeDiscountFragmentDoc}
${AmbassadorStatusDiscountFragmentDoc}
${AmbassadorCodeDiscountFragmentDoc}
${SponsorshipConfigFragmentDoc}`;
export const MigrationOrderSummaryFragmentDoc = gql`
    fragment MigrationOrderSummary on Migration {
  orderSummary {
    ...OrderSummary
  }
}
    ${OrderSummaryFragmentDoc}`;
export const MigrationIssueFragmentDoc = gql`
    fragment MigrationIssue on MigrationIssue {
  id
  migrationId
  class
  message
  errorJson
  endpointAlias
  requiredAction
  isBlocking
  durationMillis
  willBeAutoResolvedAt
  createdAt
  updatedAt
  resolvedAt
  ignoreUntil
}
    `;
export const MigrationIssueListFragmentDoc = gql`
    fragment MigrationIssueList on MigrationIssueList {
  migrationId
  issues {
    ...MigrationIssue
  }
}
    ${MigrationIssueFragmentDoc}`;
export const MigrationNoteFragmentDoc = gql`
    fragment MigrationNote on MigrationNote {
  id
  migrationId
  type
  active
  message
  createdBy
  resolvedBy
  createdAt
  updatedAt
  resolvedAt
}
    `;
export const MigrationNoteListFragmentDoc = gql`
    fragment MigrationNoteList on MigrationNoteList {
  migrationId
  activeOnly
  migrationNotes {
    ...MigrationNote
  }
}
    ${MigrationNoteFragmentDoc}`;
export const OffboardingProjectFragmentDoc = gql`
    fragment OffboardingProject on OffboardingProject {
  id
  name
  notes
  connectionId
  programId
  program {
    id
    name
  }
  cutoffAt
  scheduleId
  from
  subject1
  body1
  subject2
  body2
  subject3
  body3
  subject4
  body4
  currentRound
  nextRound
  currentRoundTotalEmails
  currentRoundSentEmails
  emailDeliveryError {
    type
    details
  }
  currentUpdateId
  createdAt
  updatedAt
  connection {
    id
    cloudServiceId
    description
  }
  jobId
  isEmailSendingInProgress
  isCompleted
  schedule {
    id
    name
    description
    timeUnit
    rounds {
      timeUnits
      scheduledFor
      template
    }
  }
}
    `;
export const OffboardingProjectStatsFragmentDoc = gql`
    fragment OffboardingProjectStats on OffboardingProjectStats {
  date
  pending
  openedLink
  connectedAccount
  startedMigration
  completedMigration
  optedOut
}
    `;
export const OffboardingProjectUpdateFragmentDoc = gql`
    fragment OffboardingProjectUpdate on OffboardingProjectUpdate {
  id
  offboardingProjectId
  status
  processedEntries
  skippedEntries
  errors
  errorMessage
  createdAt
  updatedAt
  completedAt
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  id
  userId
  sourceCloudServiceId
  destinationCloudServiceId
  sourceConnectionId
  destinationConnectionId
  blueprintInputs {
    key
    value
  }
  previousMigrationFromSource
  previousMigrationFromSourceToDestination
  programId
  pricingModelName
  pricingModelType
  pricingModelVersion
  pricingModelJson
  totalBytes
  totalItems
  originalBasePrice
  originalBytesFee
  originalItemsFee
  finalBasePrice
  finalBytesFee
  finalItemsFee
  priceBeforeDiscounts
  couponCodeDiscount {
    ...CouponCodeDiscount
  }
  referralCodeDiscount {
    ...ReferralCodeDiscount
  }
  ambassadorStatusDiscount {
    ...AmbassadorStatusDiscount
  }
  ambassadorCodeDiscount {
    ...AmbassadorCodeDiscount
  }
  sponsoredAmount
  amountToBePaid
  paidAmount
  priceBreakdown
  transactionId
  braintreeTransactionDetailsUrl
  status
  checkbox1Label
  checkbox1Checked
  checkbox2Label
  checkbox2Checked
  checkbox3Label
  checkbox3Checked
  createdAt
  updatedAt
  updatedBy
  updateSummary
  minorUpdate
  authorizedAt
  settledAt
}
    ${CouponCodeDiscountFragmentDoc}
${ReferralCodeDiscountFragmentDoc}
${AmbassadorStatusDiscountFragmentDoc}
${AmbassadorCodeDiscountFragmentDoc}`;
export const OrderRevenueFragmentDoc = gql`
    fragment OrderRevenue on Order {
  revenue
  revenueShare {
    ...RevenueShare
  }
}
    ${RevenueShareFragmentDoc}`;
export const OrganizationSummaryFragmentDoc = gql`
    fragment OrganizationSummary on Organization {
  id
  alias
  name
  themeId
  logoId
  revenueSharingEnabled
  sponsorshipLimitAmountRemaining
  createdAt
  updatedAt
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  ...OrganizationSummary
  notes
  logo {
    ...Upload
  }
  domains
  createdAt
  activatedAt
  updatedAt
}
    ${OrganizationSummaryFragmentDoc}
${UploadFragmentDoc}`;
export const OrganizationPortalUserFragmentDoc = gql`
    fragment OrganizationPortalUser on OrganizationPortalUser {
  id
  organizationId
  emailAddress
  role
  firstName
  lastName
  createdAt
  updatedAt
  signedInAt
}
    `;
export const OrganizationContextFragmentDoc = gql`
    fragment OrganizationContext on OrganizationContext {
  organization {
    ...OrganizationSummary
  }
  organizationPortalUser {
    ...OrganizationPortalUser
  }
}
    ${OrganizationSummaryFragmentDoc}
${OrganizationPortalUserFragmentDoc}`;
export const PricingModelFragmentDoc = gql`
    fragment PricingModel on PricingModel {
  id
  name
  basePriceDescription
  notes
  basePrice
  basePriceForFollowUpMigrations
  numberOfGbsIncluded
  numberOfItemsIncluded
  pricePerGb
  pricePerThousandItems
  createdAt
  updatedAt
}
    `;
export const ProgramEligibilityFragmentDoc = gql`
    fragment ProgramEligibility on ProgramEligibility {
  program {
    ...ProgramSummary
  }
  theme {
    id
    ...ThemeOverrides
  }
  discount
  isWhitelisted
}
    ${ProgramSummaryFragmentDoc}
${ThemeOverridesFragmentDoc}`;
export const ReferralCodeFragmentDoc = gql`
    fragment ReferralCode on ReferralCode {
  ...ReferralCodeSummary
  notes
  endUserAccess
  revenueShare
  announcement {
    bannerOrganizationLogo {
      ...Upload
    }
  }
  beacon {
    logo {
      ...Upload
    }
  }
  createdAt
  updatedAt
}
    ${ReferralCodeSummaryFragmentDoc}
${UploadFragmentDoc}`;
export const SchoolSummaryFragmentDoc = gql`
    fragment SchoolSummary on School {
  id
  officialName
  commonName
  logoId
  logoWidth
  landingPageUrl
  welcomeMessage
}
    `;
export const SchoolFragmentDoc = gql`
    fragment School on School {
  ...SchoolSummary
  domains
  mascot
  logo {
    ...Upload
  }
  country
  type
  website
  ncesUrl
  avgAnnualGrads
  usesGoogle
  usesMicrosoft
  notes
  reviewed
  createdAt
  updatedAt
}
    ${SchoolSummaryFragmentDoc}
${UploadFragmentDoc}`;
export const ThemeFragmentDoc = gql`
    fragment Theme on Theme {
  id
  name
  notes
  ...ThemeOverrides
  createdAt
  updatedAt
}
    ${ThemeOverridesFragmentDoc}`;
export const User_AmbassadorsProgramMembershipFragmentDoc = gql`
    fragment User_AmbassadorsProgramMembership on User_AmbassadorsProgramMembership {
  code
  signUpDiscountMigrationId
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  personalInfo {
    ...PersonalInfo
  }
  customerDetails
  enabled
  type
  createdForUserId
  ambassadorsProgramMembership {
    ...User_AmbassadorsProgramMembership
  }
  createdAt
  updatedAt
  signedInAt
}
    ${PersonalInfoFragmentDoc}
${User_AmbassadorsProgramMembershipFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment Session on Session {
  user {
    ...User
  }
  elevated
  idleTimeout
}
    ${UserFragmentDoc}`;
export const ImapSignInFormConfigFragmentDoc = gql`
    fragment ImapSignInFormConfig on ImapSignInFormConfig {
  credentialsDefaults {
    login
    customizable
  }
  connectionDefaults {
    host
    port
    customizable
  }
  existingAccessKeyId
}
    `;
export const OAuthSignInFormConfigFragmentDoc = gql`
    fragment OAuthSignInFormConfig on OAuthSignInFormConfig {
  url
  callbackUrl
}
    `;
export const OAuthAndServiceAccountSignInFormConfigFragmentDoc = gql`
    fragment OAuthAndServiceAccountSignInFormConfig on OAuthAndServiceAccountSignInFormConfig {
  url
  callbackUrl
  allowServiceAccountSignIn
}
    `;
export const GoogleSignInFormConfigFragmentDoc = gql`
    fragment GoogleSignInFormConfig on GoogleSignInFormConfig {
  url
  callbackUrl
  allowServiceAccountSignIn
}
    `;
export const OutlookSignInFormConfigFragmentDoc = gql`
    fragment OutlookSignInFormConfig on OutlookSignInFormConfig {
  exchange {
    ...OAuthSignInFormConfig
  }
  imap {
    ...OAuthSignInFormConfig
  }
  emailAddress
  userId
  tenantId
}
    ${OAuthSignInFormConfigFragmentDoc}`;
export const ExternalHelpArticleFragmentDoc = gql`
    fragment ExternalHelpArticle on ExternalHelpArticle {
  content
  title
  summary
}
    `;
export const SignInFormCustomizationFragmentDoc = gql`
    fragment SignInFormCustomization on SignInFormCustomization {
  vendorName
  serviceName
  introHtml
  introHelpArticle {
    ...ExternalHelpArticle
  }
  inlineHtml
  inlineHelpArticle {
    ...ExternalHelpArticle
  }
  footerHtml
  footerHelpArticle {
    ...ExternalHelpArticle
  }
  howToSwitchAccountHelpArticle {
    ...ExternalHelpArticle
  }
}
    ${ExternalHelpArticleFragmentDoc}`;
export const ImapSignInFormCustomizationFragmentDoc = gql`
    fragment ImapSignInFormCustomization on ImapSignInFormCustomization {
  ...SignInFormCustomization
  advancedSettingsHelpArticle {
    ...ExternalHelpArticle
  }
}
    ${SignInFormCustomizationFragmentDoc}
${ExternalHelpArticleFragmentDoc}`;
export const OAuthSignInFormCustomizationFragmentDoc = gql`
    fragment OAuthSignInFormCustomization on OAuthSignInFormCustomization {
  ...SignInFormCustomization
  buttonCss
  buttonIcon
}
    ${SignInFormCustomizationFragmentDoc}`;
export const GetSignInFormConfigResponseFragmentDoc = gql`
    fragment GetSignInFormConfigResponse on GetSignInFormConfigResponse {
  cloudServiceId
  configurations {
    name
    config {
      providerId
      ... on ImapSignInFormConfig {
        ...ImapSignInFormConfig
      }
      ... on OAuthSignInFormConfig {
        ...OAuthSignInFormConfig
      }
      ... on OAuthAndServiceAccountSignInFormConfig {
        ...OAuthAndServiceAccountSignInFormConfig
      }
      ... on GoogleSignInFormConfig {
        ...GoogleSignInFormConfig
      }
      ... on OutlookSignInFormConfig {
        ...OutlookSignInFormConfig
      }
    }
  }
  customizations {
    name
    customization {
      vendorName
      serviceName
      ... on ImapSignInFormCustomization {
        ...ImapSignInFormCustomization
      }
      ... on OAuthSignInFormCustomization {
        ...OAuthSignInFormCustomization
      }
    }
  }
}
    ${ImapSignInFormConfigFragmentDoc}
${OAuthSignInFormConfigFragmentDoc}
${OAuthAndServiceAccountSignInFormConfigFragmentDoc}
${GoogleSignInFormConfigFragmentDoc}
${OutlookSignInFormConfigFragmentDoc}
${ImapSignInFormCustomizationFragmentDoc}
${OAuthSignInFormCustomizationFragmentDoc}`;
export const PrepareBatchOrderResultFragmentDoc = gql`
    fragment PrepareBatchOrderResult on PrepareBatchOrderResult {
  batchOrder {
    ...BatchOrder
  }
  clientToken
}
    ${BatchOrderFragmentDoc}`;
export const AssociationPropsFragmentDoc = gql`
    fragment AssociationProps on AssociationProps {
  title
}
    `;
export const ComponentResolvedPropsFragmentDoc = gql`
    fragment ComponentResolvedProps on ComponentResolvedProps {
  title
  default
  promotePartial
}
    `;
export const MigrationFlowPropsFragmentDoc = gql`
    fragment MigrationFlowProps on MigrationFlowProps {
  title
}
    `;
export const ItemBreakdownFragmentDoc = gql`
    fragment ItemBreakdown on ItemBreakdown {
  entries {
    itemType
    taggedEntries {
      tags
      count
    }
  }
}
    `;
export const CollectableFragmentDoc = gql`
    fragment Collectable on Collectable {
  type
  isComplete
  progress
  ... on CollectableBoolean {
    partialBooleanValue: partialValue
    completeBooleanValue: completeValue
  }
  ... on CollectableInteger {
    partialIntegerValue: partialValue
    completeIntegerValue: completeValue
  }
  ... on CollectableItemBreakdown {
    partialItemBreakdownValue: partialValue {
      ...ItemBreakdown
    }
    completeItemBreakdownValue: completeValue {
      ...ItemBreakdown
    }
  }
}
    ${ItemBreakdownFragmentDoc}`;
export const VariantFragmentDoc = gql`
    fragment Variant on Variant {
  ... on VariantBoolean {
    booleanValue
  }
  ... on VariantLong {
    longValue
  }
  ... on Collectable {
    ...Collectable
  }
}
    ${CollectableFragmentDoc}`;
export const PipePropsFragmentDoc = gql`
    fragment PipeProps on PipeProps {
  title
  default {
    ...Variant
  }
  promotePartial
  outputName
}
    ${VariantFragmentDoc}`;
export const PipeFromOptionPropsFragmentDoc = gql`
    fragment PipeFromOptionProps on PipeFromOptionProps {
  title
  default {
    ...Variant
  }
  promotePartial
  outputName
}
    ${VariantFragmentDoc}`;
export const PipeToOptionPropsFragmentDoc = gql`
    fragment PipeToOptionProps on PipeToOptionProps {
  title
  default {
    value {
      ...Variant
    }
  }
  promotePartial
  outputName
}
    ${VariantFragmentDoc}`;
export const PlugPropsFragmentDoc = gql`
    fragment PlugProps on PlugProps {
  title
  value {
    ...Variant
  }
}
    ${VariantFragmentDoc}`;
export const RelationshipFragmentDoc = gql`
    fragment Relationship on Relationship {
  componentId
  serverSide
  ... on Association {
    associationProps: props {
      ...AssociationProps
    }
  }
  ... on ComponentResolved {
    componentResolvedProps: props {
      ...ComponentResolvedProps
    }
  }
  ... on MigrationFlow {
    migrationFlowProps: props {
      ...MigrationFlowProps
    }
  }
  ... on Pipe {
    pipeProps: props {
      ...PipeProps
    }
  }
  ... on PipeFromOption {
    pipeFromOptionProps: props {
      ...PipeFromOptionProps
    }
  }
  ... on PipeToOption {
    pipeToOptionProps: props {
      ...PipeToOptionProps
    }
  }
  ... on Plug {
    plugProps: props {
      ...PlugProps
    }
  }
}
    ${AssociationPropsFragmentDoc}
${ComponentResolvedPropsFragmentDoc}
${MigrationFlowPropsFragmentDoc}
${PipePropsFragmentDoc}
${PipeFromOptionPropsFragmentDoc}
${PipeToOptionPropsFragmentDoc}
${PlugPropsFragmentDoc}`;
export const HubFragmentDoc = gql`
    fragment Hub on Hub {
  serverSide
  relationships {
    ...Relationship
  }
}
    ${RelationshipFragmentDoc}`;
export const PreconditionsHubFragmentDoc = gql`
    fragment PreconditionsHub on PreconditionsHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const AssociationsHubFragmentDoc = gql`
    fragment AssociationsHub on AssociationsHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const ComponentHubsFragmentDoc = gql`
    fragment ComponentHubs on ComponentHubs {
  preconditions {
    ...PreconditionsHub
  }
  associations {
    ...AssociationsHub
  }
}
    ${PreconditionsHubFragmentDoc}
${AssociationsHubFragmentDoc}`;
export const MultipleRelationshipsHubFragmentDoc = gql`
    fragment MultipleRelationshipsHub on MultipleRelationshipsHub {
  ...Hub
  props {
    proposedRelationshipTitle
    reducerType
    ignoreBlocked
  }
}
    ${HubFragmentDoc}`;
export const AreaCompHubsFragmentDoc = gql`
    fragment AreaCompHubs on AreaCompHubs {
  ...ComponentHubs
  totalBytes {
    ...MultipleRelationshipsHub
  }
  totalItems {
    ...MultipleRelationshipsHub
  }
  maxPathLength {
    ...MultipleRelationshipsHub
  }
}
    ${ComponentHubsFragmentDoc}
${MultipleRelationshipsHubFragmentDoc}`;
export const AreaCompFragmentDoc = gql`
    fragment AreaComp on AreaComp {
  areaCompProps: props {
    internalId
    appTitle
    mainSubject
    title
    description
    order
    itemTypes
    itemTypeAliases {
      alias
      itemTypes
    }
  }
  areaCompHubs: hubs {
    ...AreaCompHubs
  }
}
    ${AreaCompHubsFragmentDoc}`;
export const ExactlyOneRelationshipHubFragmentDoc = gql`
    fragment ExactlyOneRelationshipHub on ExactlyOneRelationshipHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const VarsHubFragmentDoc = gql`
    fragment VarsHub on VarsHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const ChildEndpointCompHubsFragmentDoc = gql`
    fragment ChildEndpointCompHubs on ChildEndpointCompHubs {
  ...ComponentHubs
  accessKey {
    ...ExactlyOneRelationshipHub
  }
  vars {
    ...VarsHub
  }
  rootEndpointRef {
    ...ExactlyOneRelationshipHub
  }
}
    ${ComponentHubsFragmentDoc}
${ExactlyOneRelationshipHubFragmentDoc}
${VarsHubFragmentDoc}`;
export const ChildEndpointCompFragmentDoc = gql`
    fragment ChildEndpointComp on ChildEndpointComp {
  childEndpointCompProps: props {
    title
  }
  childEndpointCompHubs: hubs {
    ...ChildEndpointCompHubs
  }
}
    ${ChildEndpointCompHubsFragmentDoc}`;
export const ConnectionCompHubsFragmentDoc = gql`
    fragment ConnectionCompHubs on StandardComponentHubs {
  ...ComponentHubs
}
    ${ComponentHubsFragmentDoc}`;
export const ConnectionCompFragmentDoc = gql`
    fragment ConnectionComp on ConnectionComp {
  connectionCompProps: props {
    purpose
    inputId
    authProviderId
    branding {
      name
      icon
      color
    }
  }
  connectionCompHubs: hubs {
    ...ConnectionCompHubs
  }
}
    ${ConnectionCompHubsFragmentDoc}`;
export const FactRefFragmentDoc = gql`
    fragment FactRef on FactRef {
  type
  family
  valueType
}
    `;
export const FactCompHubsFragmentDoc = gql`
    fragment FactCompHubs on FactCompHubs {
  ...ComponentHubs
  phase {
    ...AssociationsHub
  }
}
    ${ComponentHubsFragmentDoc}
${AssociationsHubFragmentDoc}`;
export const FactCompFragmentDoc = gql`
    fragment FactComp on FactComp {
  factCompProps: props {
    name
    factRef {
      ...FactRef
    }
  }
  factCompHubs: hubs {
    ...FactCompHubs
  }
}
    ${FactRefFragmentDoc}
${FactCompHubsFragmentDoc}`;
export const OptionalRelationshipHubFragmentDoc = gql`
    fragment OptionalRelationshipHub on OptionalRelationshipHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const MigrationProgressCompHubsFragmentDoc = gql`
    fragment MigrationProgressCompHubs on MigrationProgressCompHubs {
  ...ComponentHubs
  totalBytes {
    ...OptionalRelationshipHub
  }
  totalItems {
    ...OptionalRelationshipHub
  }
  endOfEnumerationPhases {
    ...AssociationsHub
  }
  endOfProcessingPhases {
    ...AssociationsHub
  }
}
    ${ComponentHubsFragmentDoc}
${OptionalRelationshipHubFragmentDoc}
${AssociationsHubFragmentDoc}`;
export const MigrationProgressCompFragmentDoc = gql`
    fragment MigrationProgressComp on MigrationProgressComp {
  migrationProgressCompProps: props {
    title
    itemCategory
    cumulativeFactRefs {
      bytesEnumerated {
        ...FactRef
      }
      itemsEnumerated {
        ...FactRef
      }
      bytesProcessed {
        ...FactRef
      }
      itemsProcessed {
        ...FactRef
      }
      bytesSkipped {
        ...FactRef
      }
      itemsSkipped {
        ...FactRef
      }
    }
    iterationFactRefs {
      bytesEnumerated {
        ...FactRef
      }
      itemsEnumerated {
        ...FactRef
      }
      bytesProcessed {
        ...FactRef
      }
      itemsProcessed {
        ...FactRef
      }
      bytesSkipped {
        ...FactRef
      }
      itemsSkipped {
        ...FactRef
      }
    }
  }
  migrationProgressCompHubs: hubs {
    ...MigrationProgressCompHubs
  }
}
    ${FactRefFragmentDoc}
${MigrationProgressCompHubsFragmentDoc}`;
export const SelectorHubFragmentDoc = gql`
    fragment SelectorHub on SelectorHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const PhaseCompHubsFragmentDoc = gql`
    fragment PhaseCompHubs on PhaseCompHubs {
  ...ComponentHubs
  pullFrom {
    ...SelectorHub
  }
  accessKey1 {
    ...OptionalRelationshipHub
  }
  accessKey2 {
    ...OptionalRelationshipHub
  }
  endpointRef1 {
    ...OptionalRelationshipHub
  }
  endpointRef2 {
    ...OptionalRelationshipHub
  }
  vars {
    ...VarsHub
  }
}
    ${ComponentHubsFragmentDoc}
${SelectorHubFragmentDoc}
${OptionalRelationshipHubFragmentDoc}
${VarsHubFragmentDoc}`;
export const PhaseCompFragmentDoc = gql`
    fragment PhaseComp on PhaseComp {
  phaseCompProps: props {
    title
    inputProjection
    outputProjection
    itemTypeSelectors {
      bambooItemType
      itemType
      withTags {
        tag
        value
      }
      withoutTags
      itemCategory
      addToSkipped
    }
    endOfProcessing
  }
  phaseCompHubs: hubs {
    ...PhaseCompHubs
  }
}
    ${PhaseCompHubsFragmentDoc}`;
export const ArrayHubFragmentDoc = gql`
    fragment ArrayHub on ArrayHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const ReducerCompHubsFragmentDoc = gql`
    fragment ReducerCompHubs on ReducerCompHubs {
  ...ComponentHubs
  inputs {
    ...ArrayHub
  }
}
    ${ComponentHubsFragmentDoc}
${ArrayHubFragmentDoc}`;
export const ReducerCompFragmentDoc = gql`
    fragment ReducerComp on ReducerComp {
  reducerCompProps: props {
    name
    reducerType
    ignoreBlocked
  }
  reducerCompHubs: hubs {
    ...ReducerCompHubs
  }
}
    ${ReducerCompHubsFragmentDoc}`;
export const RestrictionCompHubsFragmentDoc = gql`
    fragment RestrictionCompHubs on RestrictionCompHubs {
  ...ComponentHubs
  values {
    ...MultipleRelationshipsHub
  }
  threshold {
    ...ExactlyOneRelationshipHub
  }
}
    ${ComponentHubsFragmentDoc}
${MultipleRelationshipsHubFragmentDoc}
${ExactlyOneRelationshipHubFragmentDoc}`;
export const RestrictionCompFragmentDoc = gql`
    fragment RestrictionComp on RestrictionComp {
  restrictionCompProps: props {
    name
    comparatorType
    acceptableResults
    isCritical
  }
  restrictionCompHubs: hubs {
    ...RestrictionCompHubs
  }
}
    ${RestrictionCompHubsFragmentDoc}`;
export const RoleCompHubsFragmentDoc = gql`
    fragment RoleCompHubs on RoleCompHubs {
  ...ComponentHubs
  roles {
    ...ExactlyOneRelationshipHub
  }
}
    ${ComponentHubsFragmentDoc}
${ExactlyOneRelationshipHubFragmentDoc}`;
export const RoleCompFragmentDoc = gql`
    fragment RoleComp on RoleComp {
  roleCompProps: props {
    roleId
  }
  roleCompHubs: hubs {
    ...RoleCompHubs
  }
}
    ${RoleCompHubsFragmentDoc}`;
export const RootEndpointCompHubsFragmentDoc = gql`
    fragment RootEndpointCompHubs on RootEndpointCompHubs {
  ...ComponentHubs
  accessKey {
    ...ExactlyOneRelationshipHub
  }
  vars {
    ...VarsHub
  }
}
    ${ComponentHubsFragmentDoc}
${ExactlyOneRelationshipHubFragmentDoc}
${VarsHubFragmentDoc}`;
export const RootEndpointCompFragmentDoc = gql`
    fragment RootEndpointComp on RootEndpointComp {
  rootEndpointCompProps: props {
    title
  }
  rootEndpointCompHubs: hubs {
    ...RootEndpointCompHubs
  }
}
    ${RootEndpointCompHubsFragmentDoc}`;
export const MigrationFlowsHubFragmentDoc = gql`
    fragment MigrationFlowsHub on MigrationFlowsHub {
  ...Hub
  props {
    proposedRelationshipTitle
  }
}
    ${HubFragmentDoc}`;
export const SinkCompHubsFragmentDoc = gql`
    fragment SinkCompHubs on SinkCompHubs {
  ...ComponentHubs
  migrationFlows {
    ...MigrationFlowsHub
  }
}
    ${ComponentHubsFragmentDoc}
${MigrationFlowsHubFragmentDoc}`;
export const SinkCompFragmentDoc = gql`
    fragment SinkComp on SinkComp {
  sinkCompProps: props {
    internalId
    appTitle
    mainSubject
    title
    description
    order
    itemTypes
  }
  sinkCompHubs: hubs {
    ...SinkCompHubs
  }
}
    ${SinkCompHubsFragmentDoc}`;
export const StorageRestrictionCompHubsFragmentDoc = gql`
    fragment StorageRestrictionCompHubs on StorageRestrictionCompHubs {
  ...ComponentHubs
  required {
    ...MultipleRelationshipsHub
  }
  available {
    ...OptionalRelationshipHub
  }
}
    ${ComponentHubsFragmentDoc}
${MultipleRelationshipsHubFragmentDoc}
${OptionalRelationshipHubFragmentDoc}`;
export const StorageRestrictionCompFragmentDoc = gql`
    fragment StorageRestrictionComp on StorageRestrictionComp {
  storageRestrictionCompProps: props {
    storageType
    headroom
    helpArticle {
      ...ExternalHelpArticle
    }
  }
  storageRestrictionCompHubs: hubs {
    ...StorageRestrictionCompHubs
  }
}
    ${ExternalHelpArticleFragmentDoc}
${StorageRestrictionCompHubsFragmentDoc}`;
export const TimeEstimateCompHubsFragmentDoc = gql`
    fragment TimeEstimateCompHubs on TimeEstimateCompHubs {
  ...ComponentHubs
  totalBytes {
    ...MultipleRelationshipsHub
  }
  totalItems {
    ...MultipleRelationshipsHub
  }
  migrationProgresses {
    ...AssociationsHub
  }
}
    ${ComponentHubsFragmentDoc}
${MultipleRelationshipsHubFragmentDoc}
${AssociationsHubFragmentDoc}`;
export const TimeEstimateCompFragmentDoc = gql`
    fragment TimeEstimateComp on TimeEstimateComp {
  timeEstimateCompProps: props {
    title
    connector
    serviceProvider
    fallback
    estimatedTimeFactRef {
      ...FactRef
    }
    elapsedTimeFactRef {
      ...FactRef
    }
  }
  timeEstimateCompHubs: hubs {
    ...TimeEstimateCompHubs
  }
}
    ${FactRefFragmentDoc}
${TimeEstimateCompHubsFragmentDoc}`;
export const VariableCompHubsFragmentDoc = gql`
    fragment VariableCompHubs on StandardComponentHubs {
  ...ComponentHubs
}
    ${ComponentHubsFragmentDoc}`;
export const VariableCompFragmentDoc = gql`
    fragment VariableComp on VariableComp {
  variableCompProps: props {
    inputId
    emptyValues
  }
  variableCompHubs: hubs {
    ...VariableCompHubs
  }
}
    ${VariableCompHubsFragmentDoc}`;
export const ComponentFragmentDoc = gql`
    fragment Component on Component {
  id
  serverSide
  segment
  settings {
    sizeMultiplier
    color {
      background
      border
    }
    icon
    anchor
  }
  tags
  ... on AreaComp {
    ...AreaComp
  }
  ... on ChildEndpointComp {
    ...ChildEndpointComp
  }
  ... on ChildEndpointComp {
    ...ChildEndpointComp
  }
  ... on ConnectionComp {
    ...ConnectionComp
  }
  ... on FactComp {
    ...FactComp
  }
  ... on MigrationProgressComp {
    ...MigrationProgressComp
  }
  ... on PhaseComp {
    ...PhaseComp
  }
  ... on ReducerComp {
    ...ReducerComp
  }
  ... on RestrictionComp {
    ...RestrictionComp
  }
  ... on RoleComp {
    ...RoleComp
  }
  ... on RootEndpointComp {
    ...RootEndpointComp
  }
  ... on SinkComp {
    ...SinkComp
  }
  ... on StorageRestrictionComp {
    ...StorageRestrictionComp
  }
  ... on TimeEstimateComp {
    ...TimeEstimateComp
  }
  ... on VariableComp {
    ...VariableComp
  }
}
    ${AreaCompFragmentDoc}
${ChildEndpointCompFragmentDoc}
${ConnectionCompFragmentDoc}
${FactCompFragmentDoc}
${MigrationProgressCompFragmentDoc}
${PhaseCompFragmentDoc}
${ReducerCompFragmentDoc}
${RestrictionCompFragmentDoc}
${RoleCompFragmentDoc}
${RootEndpointCompFragmentDoc}
${SinkCompFragmentDoc}
${StorageRestrictionCompFragmentDoc}
${TimeEstimateCompFragmentDoc}
${VariableCompFragmentDoc}`;
export const CollectableIntegerFragmentDoc = gql`
    fragment CollectableInteger on CollectableInteger {
  type
  isComplete
  progress
  partialValue
  completeValue
}
    `;
export const CollectableItemBreakdownFragmentDoc = gql`
    fragment CollectableItemBreakdown on CollectableItemBreakdown {
  __typename
  type
  isComplete
  progress
  partialValue {
    ...ItemBreakdown
  }
  completeValue {
    ...ItemBreakdown
  }
}
    ${ItemBreakdownFragmentDoc}`;
export const AreaCompOutputFragmentDoc = gql`
    fragment AreaCompOutput on AreaCompOutput {
  itemTypes
  totalBytesCollectable: totalBytes {
    ...CollectableInteger
  }
  totalItemsCollectable: totalItems {
    ...CollectableItemBreakdown
  }
  maxPathLength {
    ...CollectableInteger
  }
  enabled
}
    ${CollectableIntegerFragmentDoc}
${CollectableItemBreakdownFragmentDoc}`;
export const ChildEndpointCompOutputFragmentDoc = gql`
    fragment ChildEndpointCompOutput on ChildEndpointCompOutput {
  endpointDefJson
  endpointRef
}
    `;
export const ConnectionCompOutputFragmentDoc = gql`
    fragment ConnectionCompOutput on ConnectionCompOutput {
  roles
  connectionId
  emailAddress
}
    `;
export const FactCompLikeOutputFragmentDoc = gql`
    fragment FactCompLikeOutput on FactCompLikeOutput {
  collectable {
    ...Collectable
  }
  valueJson
}
    ${CollectableFragmentDoc}`;
export const MigrationProgressCompOutput_MetricsFragmentDoc = gql`
    fragment MigrationProgressCompOutput_Metrics on MigrationProgressCompOutput_Metrics {
  estimatedBytes
  estimatedItems {
    itemType
    count
  }
  enumeratedBytes
  enumeratedItems {
    itemType
    count
  }
  totalBytes
  totalItems {
    itemType
    count
  }
  processedBytes
  processedItems {
    itemType
    count
  }
  skippedBytes
  skippedItems {
    itemType
    count
  }
}
    `;
export const MigrationProgressCompOutputFragmentDoc = gql`
    fragment MigrationProgressCompOutput on MigrationProgressCompOutput {
  cumulative {
    ...MigrationProgressCompOutput_Metrics
  }
  iteration {
    ...MigrationProgressCompOutput_Metrics
  }
  completed
}
    ${MigrationProgressCompOutput_MetricsFragmentDoc}`;
export const PhaseCompOutputFragmentDoc = gql`
    fragment PhaseCompOutput on PhaseCompOutput {
  phaseId
  phaseDefJson
}
    `;
export const ReducerCompOutputFragmentDoc = gql`
    fragment ReducerCompOutput on ReducerCompOutput {
  reducerCompOutputValue: value {
    ...Variant
  }
}
    ${VariantFragmentDoc}`;
export const RestrictionCompOutputFragmentDoc = gql`
    fragment RestrictionCompOutput on RestrictionCompOutput {
  restrictionState
  restrictionCompOutputValue: value {
    ...Variant
  }
  threshold {
    ...Variant
  }
}
    ${VariantFragmentDoc}`;
export const RoleCompOutputFragmentDoc = gql`
    fragment RoleCompOutput on RoleCompOutput {
  conditionState
}
    `;
export const RootEndpointCompOutputFragmentDoc = gql`
    fragment RootEndpointCompOutput on RootEndpointCompOutput {
  endpointDefJson
  endpointRef
}
    `;
export const SinkCompOutputFragmentDoc = gql`
    fragment SinkCompOutput on SinkCompOutput {
  itemTypes
  totalBytesCollectable: totalBytes {
    ...CollectableInteger
  }
  totalItemsCollectable: totalItems {
    ...CollectableItemBreakdown
  }
  maxPathLength {
    ...CollectableInteger
  }
}
    ${CollectableIntegerFragmentDoc}
${CollectableItemBreakdownFragmentDoc}`;
export const StorageRestrictionCompOutputFragmentDoc = gql`
    fragment StorageRestrictionCompOutput on StorageRestrictionCompOutput {
  restrictionState
  required {
    ...CollectableInteger
  }
  available {
    ...CollectableInteger
  }
}
    ${CollectableIntegerFragmentDoc}`;
export const TimeEstimateCompOutputFragmentDoc = gql`
    fragment TimeEstimateCompOutput on TimeEstimateCompOutput {
  totalBytesCollectable: totalBytes {
    ...CollectableInteger
  }
  totalItemsCollectable: totalItems {
    ...CollectableItemBreakdown
  }
  params
  fallbackEstimate
}
    ${CollectableIntegerFragmentDoc}
${CollectableItemBreakdownFragmentDoc}`;
export const VariableCompOutputFragmentDoc = gql`
    fragment VariableCompOutput on VariableCompOutput {
  variableCompOutputValue: value
}
    `;
export const UnregisteredOutputTypeFragmentDoc = gql`
    fragment UnregisteredOutputType on UnregisteredOutputType {
  class
  asString
}
    `;
export const BlueprintStateOutputFragmentDoc = gql`
    fragment BlueprintStateOutput on BlueprintStateOutput {
  ... on AreaCompOutput {
    ...AreaCompOutput
  }
  ... on ChildEndpointCompOutput {
    ...ChildEndpointCompOutput
  }
  ... on ConnectionCompOutput {
    ...ConnectionCompOutput
  }
  ... on FactCompLikeOutput {
    ...FactCompLikeOutput
  }
  ... on MigrationProgressCompOutput {
    ...MigrationProgressCompOutput
  }
  ... on PhaseCompOutput {
    ...PhaseCompOutput
  }
  ... on ReducerCompOutput {
    ...ReducerCompOutput
  }
  ... on RestrictionCompOutput {
    ...RestrictionCompOutput
  }
  ... on RoleCompOutput {
    ...RoleCompOutput
  }
  ... on RootEndpointCompOutput {
    ...RootEndpointCompOutput
  }
  ... on SinkCompOutput {
    ...SinkCompOutput
  }
  ... on StorageRestrictionCompOutput {
    ...StorageRestrictionCompOutput
  }
  ... on TimeEstimateCompOutput {
    ...TimeEstimateCompOutput
  }
  ... on VariableCompOutput {
    ...VariableCompOutput
  }
  ... on UnregisteredOutputType {
    ...UnregisteredOutputType
  }
}
    ${AreaCompOutputFragmentDoc}
${ChildEndpointCompOutputFragmentDoc}
${ConnectionCompOutputFragmentDoc}
${FactCompLikeOutputFragmentDoc}
${MigrationProgressCompOutputFragmentDoc}
${PhaseCompOutputFragmentDoc}
${ReducerCompOutputFragmentDoc}
${RestrictionCompOutputFragmentDoc}
${RoleCompOutputFragmentDoc}
${RootEndpointCompOutputFragmentDoc}
${SinkCompOutputFragmentDoc}
${StorageRestrictionCompOutputFragmentDoc}
${TimeEstimateCompOutputFragmentDoc}
${VariableCompOutputFragmentDoc}
${UnregisteredOutputTypeFragmentDoc}`;
export const BlueprintStateFragmentDoc = gql`
    fragment BlueprintState on BlueprintState {
  type
  errors {
    type
    message
  }
  ... on ResolvingBlueprintState {
    output {
      ...BlueprintStateOutput
    }
    progress
  }
  ... on AlmostResolvedBlueprintState {
    output {
      ...BlueprintStateOutput
    }
    progress
  }
  ... on ResolvedBlueprintState {
    output {
      ...BlueprintStateOutput
    }
  }
}
    ${BlueprintStateOutputFragmentDoc}`;
export const RenderedBlueprintRelationshipFragmentDoc = gql`
    fragment RenderedBlueprintRelationship on RenderedBlueprintRelationship {
  componentId
  state {
    ...BlueprintState
  }
}
    ${BlueprintStateFragmentDoc}`;
export const RenderedBlueprintHubFragmentDoc = gql`
    fragment RenderedBlueprintHub on RenderedBlueprintHub {
  name
  state {
    ...BlueprintState
  }
  relationships {
    ...RenderedBlueprintRelationship
  }
}
    ${BlueprintStateFragmentDoc}
${RenderedBlueprintRelationshipFragmentDoc}`;
export const RenderedBlueprintComponentFragmentDoc = gql`
    fragment RenderedBlueprintComponent on RenderedBlueprintComponent {
  id
  state {
    ...BlueprintState
  }
  hubs {
    ...RenderedBlueprintHub
  }
}
    ${BlueprintStateFragmentDoc}
${RenderedBlueprintHubFragmentDoc}`;
export const ConnectResultFragmentDoc = gql`
    fragment ConnectResult on ConnectResult {
  connection {
    ...Connection
  }
  elevated
  idleTimeout
  user {
    ...User
  }
  erasedFacts {
    id
  }
}
    ${ConnectionFragmentDoc}
${UserFragmentDoc}`;
export const RevealedAccessKeyFragmentFragmentDoc = gql`
    fragment RevealedAccessKeyFragment on RevealedAccessKey {
  accessKeyVersion
  accessKey
  accessKeyStatus
  accessKeyParamsVersion
  accessKeyParams
  currentPermissions
  currentRoles
  validatedPermissions
  missingPermissions
}
    `;
export const CollectableBooleanFragmentDoc = gql`
    fragment CollectableBoolean on CollectableBoolean {
  type
  isComplete
  progress
  partialValue
  completeValue
}
    `;
export const FactStateFragmentDoc = gql`
    fragment FactState on FactState {
  ... on MissingFactState {
    foo
  }
  ... on FailedFactState {
    errorType
    errorMessage
  }
  ... on PreparingFactState {
    foo
  }
  ... on CollectingFactState {
    value {
      ...Collectable
    }
  }
  ... on CollectedFactState {
    value {
      ...Collectable
    }
  }
}
    ${CollectableFragmentDoc}`;
export const FactFragmentDoc = gql`
    fragment Fact on Fact {
  id
  subjectType
  subjectId
  family
  valueType
  state {
    ...FactState
  }
}
    ${FactStateFragmentDoc}`;
export const FactOrPlaceholderFragmentDoc = gql`
    fragment FactOrPlaceholder on FactOrPlaceholder {
  id
  ... on Fact {
    ...Fact
  }
}
    ${FactFragmentDoc}`;
export const JobHistoryRecordFragmentFragmentDoc = gql`
    fragment JobHistoryRecordFragment on JobHistoryRecord {
  id
  type
  internalStatus
  currentStatus
  desiredStatus
  nextStatus
  transition
  propsVersion
  propsJson
  stateVersion
  stateJson
  createdAt
  updatedAt
  updatedBy
  updateSummary
  minorUpdate
  completedAt
  archivedAt
}
    `;
export const TaskHistoryRecordFragmentFragmentDoc = gql`
    fragment TaskHistoryRecordFragment on TaskHistoryRecord {
  jobId
  taskId
  transition
  type
  internalStatus
  propsVersion
  propsJson
  stateVersion
  stateJson
  resultVersion
  resultJson
  abortReason
  createdAt
  updatedAt
  updatedBy
  updateSummary
  minorUpdate
  completedAt
  archivedAt
}
    `;
export const TaskIssueFragmentFragmentDoc = gql`
    fragment TaskIssueFragment on TaskIssue {
  jobId
  taskId
  transition
  issueId
  summary
  details
  createdAt
  confirmedAt
  timesConfirmed
  notifiedAt
  timesNotified
  resolvedAt
}
    `;
export const PlaceOrderResult_SuccessFragmentDoc = gql`
    fragment PlaceOrderResult_Success on PlaceOrderResult_Success {
  orderId
  totalBytes
  totalItems
  originalBasePrice
  originalBytesFee
  originalItemsFee
  priceBeforeDiscounts
  priceCalculator {
    ...PricingModelCalculator
  }
  clientToken
  school {
    ...SchoolSummary
  }
}
    ${PricingModelCalculatorFragmentDoc}
${SchoolSummaryFragmentDoc}`;
export const PlaceOrderResultFragmentDoc = gql`
    fragment PlaceOrderResult on PlaceOrderResult {
  ... on PlaceOrderResult_Success {
    ...PlaceOrderResult_Success
  }
  ... on PlaceOrderResult_ConnectionsAreInUse {
    migrationIds
  }
}
    ${PlaceOrderResult_SuccessFragmentDoc}`;
export const ValidateReferralCodeResultFragmentDoc = gql`
    fragment ValidateReferralCodeResult on ValidateReferralCodeResult {
  ... on ValidateReferralCodeResult_Success {
    referralCode {
      ...ReferralCodeSummary
    }
  }
  ... on ValidateReferralCodeResult_NotEligible {
    referralCode {
      ...ReferralCodeSummary
    }
    governingOrganizationName
  }
}
    ${ReferralCodeSummaryFragmentDoc}`;
export const AmbassadorStatusFragmentDoc = gql`
    fragment AmbassadorStatus on AmbassadorStatus {
  ambassadorCode
  school {
    ...SchoolSummary
  }
  totalContestEntries
  recentContestEntries {
    event
    count
    createdAt
  }
}
    ${SchoolSummaryFragmentDoc}`;
export const DeleteAccessListDocument = gql`
    mutation DeleteAccessList($id: Int!) {
  deleteAccessList(id: $id)
}
    `;
export type DeleteAccessListMutationFn = ApolloReactCommon.MutationFunction<DeleteAccessListMutation, DeleteAccessListMutationVariables>;

    export function useDeleteAccessListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccessListMutation, DeleteAccessListMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteAccessListMutation, DeleteAccessListMutationVariables>(DeleteAccessListDocument, baseOptions);
    }
export type DeleteAccessListMutationHookResult = ReturnType<typeof useDeleteAccessListMutation>;
export type DeleteAccessListMutationResult = ApolloReactCommon.MutationResult<DeleteAccessListMutation>;
export type DeleteAccessListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccessListMutation, DeleteAccessListMutationVariables>;
export const DeleteAccessListEntriesDocument = gql`
    mutation DeleteAccessListEntries($id: Int!, $emailAddresses: [String!]!) {
  deleteAccessListEntries(id: $id, emailAddresses: $emailAddresses) {
    deletedEntryCount
    skippedEmailAddresses
  }
}
    `;
export type DeleteAccessListEntriesMutationFn = ApolloReactCommon.MutationFunction<DeleteAccessListEntriesMutation, DeleteAccessListEntriesMutationVariables>;

    export function useDeleteAccessListEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccessListEntriesMutation, DeleteAccessListEntriesMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteAccessListEntriesMutation, DeleteAccessListEntriesMutationVariables>(DeleteAccessListEntriesDocument, baseOptions);
    }
export type DeleteAccessListEntriesMutationHookResult = ReturnType<typeof useDeleteAccessListEntriesMutation>;
export type DeleteAccessListEntriesMutationResult = ApolloReactCommon.MutationResult<DeleteAccessListEntriesMutation>;
export type DeleteAccessListEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccessListEntriesMutation, DeleteAccessListEntriesMutationVariables>;
export const DescribeGoogleGroupDocument = gql`
    mutation DescribeGoogleGroup($id: String!) {
  describeGoogleGroup(id: $id) {
    ...GoogleGroup
  }
}
    ${GoogleGroupFragmentDoc}`;
export type DescribeGoogleGroupMutationFn = ApolloReactCommon.MutationFunction<DescribeGoogleGroupMutation, DescribeGoogleGroupMutationVariables>;

    export function useDescribeGoogleGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DescribeGoogleGroupMutation, DescribeGoogleGroupMutationVariables>) {
      return ApolloReactHooks.useMutation<DescribeGoogleGroupMutation, DescribeGoogleGroupMutationVariables>(DescribeGoogleGroupDocument, baseOptions);
    }
export type DescribeGoogleGroupMutationHookResult = ReturnType<typeof useDescribeGoogleGroupMutation>;
export type DescribeGoogleGroupMutationResult = ApolloReactCommon.MutationResult<DescribeGoogleGroupMutation>;
export type DescribeGoogleGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DescribeGoogleGroupMutation, DescribeGoogleGroupMutationVariables>;
export const GetAccessListDocument = gql`
    query GetAccessList($id: Int!) {
  accessList(id: $id) {
    ...AccessList
  }
}
    ${AccessListFragmentDoc}`;

    export function useGetAccessListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessListQuery, GetAccessListQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAccessListQuery, GetAccessListQueryVariables>(GetAccessListDocument, baseOptions);
    }
      export function useGetAccessListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessListQuery, GetAccessListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAccessListQuery, GetAccessListQueryVariables>(GetAccessListDocument, baseOptions);
      }
      
export type GetAccessListQueryHookResult = ReturnType<typeof useGetAccessListQuery>;
export type GetAccessListQueryResult = ApolloReactCommon.QueryResult<GetAccessListQuery, GetAccessListQueryVariables>;
export const GetAccessListEntryCountDocument = gql`
    query GetAccessListEntryCount($id: Int!) {
  accessListEntryCount(id: $id)
}
    `;

    export function useGetAccessListEntryCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessListEntryCountQuery, GetAccessListEntryCountQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAccessListEntryCountQuery, GetAccessListEntryCountQueryVariables>(GetAccessListEntryCountDocument, baseOptions);
    }
      export function useGetAccessListEntryCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessListEntryCountQuery, GetAccessListEntryCountQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAccessListEntryCountQuery, GetAccessListEntryCountQueryVariables>(GetAccessListEntryCountDocument, baseOptions);
      }
      
export type GetAccessListEntryCountQueryHookResult = ReturnType<typeof useGetAccessListEntryCountQuery>;
export type GetAccessListEntryCountQueryResult = ApolloReactCommon.QueryResult<GetAccessListEntryCountQuery, GetAccessListEntryCountQueryVariables>;
export const GetAccessListUpdateDocument = gql`
    query GetAccessListUpdate($id: Int!) {
  accessListUpdate(id: $id) {
    ...AccessListUpdate
  }
}
    ${AccessListUpdateFragmentDoc}`;

    export function useGetAccessListUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessListUpdateQuery, GetAccessListUpdateQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAccessListUpdateQuery, GetAccessListUpdateQueryVariables>(GetAccessListUpdateDocument, baseOptions);
    }
      export function useGetAccessListUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessListUpdateQuery, GetAccessListUpdateQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAccessListUpdateQuery, GetAccessListUpdateQueryVariables>(GetAccessListUpdateDocument, baseOptions);
      }
      
export type GetAccessListUpdateQueryHookResult = ReturnType<typeof useGetAccessListUpdateQuery>;
export type GetAccessListUpdateQueryResult = ApolloReactCommon.QueryResult<GetAccessListUpdateQuery, GetAccessListUpdateQueryVariables>;
export const GetAccessListUpdateHistoryDocument = gql`
    query GetAccessListUpdateHistory($id: Int!) {
  accessListUpdateHistory(id: $id) {
    ...AccessListUpdate
  }
}
    ${AccessListUpdateFragmentDoc}`;

    export function useGetAccessListUpdateHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessListUpdateHistoryQuery, GetAccessListUpdateHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAccessListUpdateHistoryQuery, GetAccessListUpdateHistoryQueryVariables>(GetAccessListUpdateHistoryDocument, baseOptions);
    }
      export function useGetAccessListUpdateHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessListUpdateHistoryQuery, GetAccessListUpdateHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAccessListUpdateHistoryQuery, GetAccessListUpdateHistoryQueryVariables>(GetAccessListUpdateHistoryDocument, baseOptions);
      }
      
export type GetAccessListUpdateHistoryQueryHookResult = ReturnType<typeof useGetAccessListUpdateHistoryQuery>;
export type GetAccessListUpdateHistoryQueryResult = ApolloReactCommon.QueryResult<GetAccessListUpdateHistoryQuery, GetAccessListUpdateHistoryQueryVariables>;
export const GetCurrentAccessListUpdateDocument = gql`
    query GetCurrentAccessListUpdate($id: Int!) {
  accessList(id: $id) {
    id
    currentUpdateId
  }
}
    `;

    export function useGetCurrentAccessListUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentAccessListUpdateQuery, GetCurrentAccessListUpdateQueryVariables>) {
      return ApolloReactHooks.useQuery<GetCurrentAccessListUpdateQuery, GetCurrentAccessListUpdateQueryVariables>(GetCurrentAccessListUpdateDocument, baseOptions);
    }
      export function useGetCurrentAccessListUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentAccessListUpdateQuery, GetCurrentAccessListUpdateQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetCurrentAccessListUpdateQuery, GetCurrentAccessListUpdateQueryVariables>(GetCurrentAccessListUpdateDocument, baseOptions);
      }
      
export type GetCurrentAccessListUpdateQueryHookResult = ReturnType<typeof useGetCurrentAccessListUpdateQuery>;
export type GetCurrentAccessListUpdateQueryResult = ApolloReactCommon.QueryResult<GetCurrentAccessListUpdateQuery, GetCurrentAccessListUpdateQueryVariables>;
export const GetOrganizationAccessListStatsDocument = gql`
    query GetOrganizationAccessListStats($organizationId: Int!) {
  organizationAccessListStats(organizationId: $organizationId) {
    programAccessListCount
    organizationBlockListCount
  }
}
    `;

    export function useGetOrganizationAccessListStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationAccessListStatsQuery, GetOrganizationAccessListStatsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrganizationAccessListStatsQuery, GetOrganizationAccessListStatsQueryVariables>(GetOrganizationAccessListStatsDocument, baseOptions);
    }
      export function useGetOrganizationAccessListStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrganizationAccessListStatsQuery, GetOrganizationAccessListStatsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrganizationAccessListStatsQuery, GetOrganizationAccessListStatsQueryVariables>(GetOrganizationAccessListStatsDocument, baseOptions);
      }
      
export type GetOrganizationAccessListStatsQueryHookResult = ReturnType<typeof useGetOrganizationAccessListStatsQuery>;
export type GetOrganizationAccessListStatsQueryResult = ApolloReactCommon.QueryResult<GetOrganizationAccessListStatsQuery, GetOrganizationAccessListStatsQueryVariables>;
export const InsertAccessListDocument = gql`
    mutation InsertAccessList($organizationId: Int!, $formData: AccessListFormData!) {
  insertAccessList(organizationId: $organizationId, formData: $formData) {
    ...AccessList
  }
}
    ${AccessListFragmentDoc}`;
export type InsertAccessListMutationFn = ApolloReactCommon.MutationFunction<InsertAccessListMutation, InsertAccessListMutationVariables>;

    export function useInsertAccessListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertAccessListMutation, InsertAccessListMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertAccessListMutation, InsertAccessListMutationVariables>(InsertAccessListDocument, baseOptions);
    }
export type InsertAccessListMutationHookResult = ReturnType<typeof useInsertAccessListMutation>;
export type InsertAccessListMutationResult = ApolloReactCommon.MutationResult<InsertAccessListMutation>;
export type InsertAccessListMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertAccessListMutation, InsertAccessListMutationVariables>;
export const ListAccessListUpdatesDocument = gql`
    query ListAccessListUpdates($ids: [Int!]!) {
  accessListUpdates(ids: $ids) {
    ...AccessListUpdate
  }
}
    ${AccessListUpdateFragmentDoc}`;

    export function useListAccessListUpdatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAccessListUpdatesQuery, ListAccessListUpdatesQueryVariables>) {
      return ApolloReactHooks.useQuery<ListAccessListUpdatesQuery, ListAccessListUpdatesQueryVariables>(ListAccessListUpdatesDocument, baseOptions);
    }
      export function useListAccessListUpdatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAccessListUpdatesQuery, ListAccessListUpdatesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ListAccessListUpdatesQuery, ListAccessListUpdatesQueryVariables>(ListAccessListUpdatesDocument, baseOptions);
      }
      
export type ListAccessListUpdatesQueryHookResult = ReturnType<typeof useListAccessListUpdatesQuery>;
export type ListAccessListUpdatesQueryResult = ApolloReactCommon.QueryResult<ListAccessListUpdatesQuery, ListAccessListUpdatesQueryVariables>;
export const SearchAccessListsDocument = gql`
    query SearchAccessLists($organizationId: Int!, $term: String!) {
  searchAccessLists(organizationId: $organizationId, term: $term) {
    ...AccessList
  }
}
    ${AccessListFragmentDoc}`;

    export function useSearchAccessListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchAccessListsQuery, SearchAccessListsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchAccessListsQuery, SearchAccessListsQueryVariables>(SearchAccessListsDocument, baseOptions);
    }
      export function useSearchAccessListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchAccessListsQuery, SearchAccessListsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchAccessListsQuery, SearchAccessListsQueryVariables>(SearchAccessListsDocument, baseOptions);
      }
      
export type SearchAccessListsQueryHookResult = ReturnType<typeof useSearchAccessListsQuery>;
export type SearchAccessListsQueryResult = ApolloReactCommon.QueryResult<SearchAccessListsQuery, SearchAccessListsQueryVariables>;
export const StartAccessListUpdateJobDocument = gql`
    mutation StartAccessListUpdateJob($id: Int!) {
  startAccessListUpdateJob(id: $id)
}
    `;
export type StartAccessListUpdateJobMutationFn = ApolloReactCommon.MutationFunction<StartAccessListUpdateJobMutation, StartAccessListUpdateJobMutationVariables>;

    export function useStartAccessListUpdateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartAccessListUpdateJobMutation, StartAccessListUpdateJobMutationVariables>) {
      return ApolloReactHooks.useMutation<StartAccessListUpdateJobMutation, StartAccessListUpdateJobMutationVariables>(StartAccessListUpdateJobDocument, baseOptions);
    }
export type StartAccessListUpdateJobMutationHookResult = ReturnType<typeof useStartAccessListUpdateJobMutation>;
export type StartAccessListUpdateJobMutationResult = ApolloReactCommon.MutationResult<StartAccessListUpdateJobMutation>;
export type StartAccessListUpdateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<StartAccessListUpdateJobMutation, StartAccessListUpdateJobMutationVariables>;
export const UpdateAccessListDocument = gql`
    mutation UpdateAccessList($id: Int!, $formData: AccessListFormData!) {
  updateAccessList(id: $id, formData: $formData) {
    ...AccessList
  }
}
    ${AccessListFragmentDoc}`;
export type UpdateAccessListMutationFn = ApolloReactCommon.MutationFunction<UpdateAccessListMutation, UpdateAccessListMutationVariables>;

    export function useUpdateAccessListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessListMutation, UpdateAccessListMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateAccessListMutation, UpdateAccessListMutationVariables>(UpdateAccessListDocument, baseOptions);
    }
export type UpdateAccessListMutationHookResult = ReturnType<typeof useUpdateAccessListMutation>;
export type UpdateAccessListMutationResult = ApolloReactCommon.MutationResult<UpdateAccessListMutation>;
export type UpdateAccessListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccessListMutation, UpdateAccessListMutationVariables>;
export const GetAppBootstrapConfigDocument = gql`
    query GetAppBootstrapConfig($programAlias: String, $configurationAlias: String, $themeId: Int, $organizationIdOrAlias: String, $referralCode: String) {
  getAppBootstrapConfig(programAlias: $programAlias, configurationAlias: $configurationAlias, themeId: $themeId, organizationIdOrAlias: $organizationIdOrAlias, referralCode: $referralCode) {
    ...AppBootstrapConfig
  }
  getSession {
    ...Session
  }
}
    ${AppBootstrapConfigFragmentDoc}
${SessionFragmentDoc}`;

    export function useGetAppBootstrapConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppBootstrapConfigQuery, GetAppBootstrapConfigQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAppBootstrapConfigQuery, GetAppBootstrapConfigQueryVariables>(GetAppBootstrapConfigDocument, baseOptions);
    }
      export function useGetAppBootstrapConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppBootstrapConfigQuery, GetAppBootstrapConfigQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAppBootstrapConfigQuery, GetAppBootstrapConfigQueryVariables>(GetAppBootstrapConfigDocument, baseOptions);
      }
      
export type GetAppBootstrapConfigQueryHookResult = ReturnType<typeof useGetAppBootstrapConfigQuery>;
export type GetAppBootstrapConfigQueryResult = ApolloReactCommon.QueryResult<GetAppBootstrapConfigQuery, GetAppBootstrapConfigQueryVariables>;
export const GetSignInFormConfigDocument = gql`
    query GetSignInFormConfig($authFlowId: String!, $cloudServiceId: String!, $roles: [String!]!, $existingAccessKeyId: String) {
  getSignInFormConfig(authFlowId: $authFlowId, cloudServiceId: $cloudServiceId, roles: $roles, existingAccessKeyId: $existingAccessKeyId) {
    ...GetSignInFormConfigResponse
  }
}
    ${GetSignInFormConfigResponseFragmentDoc}`;

    export function useGetSignInFormConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSignInFormConfigQuery, GetSignInFormConfigQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSignInFormConfigQuery, GetSignInFormConfigQueryVariables>(GetSignInFormConfigDocument, baseOptions);
    }
      export function useGetSignInFormConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignInFormConfigQuery, GetSignInFormConfigQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetSignInFormConfigQuery, GetSignInFormConfigQueryVariables>(GetSignInFormConfigDocument, baseOptions);
      }
      
export type GetSignInFormConfigQueryHookResult = ReturnType<typeof useGetSignInFormConfigQuery>;
export type GetSignInFormConfigQueryResult = ApolloReactCommon.QueryResult<GetSignInFormConfigQuery, GetSignInFormConfigQueryVariables>;
export const GetSignUpFormConfigDocument = gql`
    query GetSignUpFormConfig($authFlowId: String!, $roles: [String!]!) {
  getSignUpFormConfig(authFlowId: $authFlowId, roles: $roles) {
    ...GetSignInFormConfigResponse
  }
}
    ${GetSignInFormConfigResponseFragmentDoc}`;

    export function useGetSignUpFormConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSignUpFormConfigQuery, GetSignUpFormConfigQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSignUpFormConfigQuery, GetSignUpFormConfigQueryVariables>(GetSignUpFormConfigDocument, baseOptions);
    }
      export function useGetSignUpFormConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignUpFormConfigQuery, GetSignUpFormConfigQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetSignUpFormConfigQuery, GetSignUpFormConfigQueryVariables>(GetSignUpFormConfigDocument, baseOptions);
      }
      
export type GetSignUpFormConfigQueryHookResult = ReturnType<typeof useGetSignUpFormConfigQuery>;
export type GetSignUpFormConfigQueryResult = ApolloReactCommon.QueryResult<GetSignUpFormConfigQuery, GetSignUpFormConfigQueryVariables>;
export const ImapSignInDocument = gql`
    mutation ImapSignIn($cloudServiceId: String!, $roles: [String!]!, $signInParams: ImapSignInParams!) {
  imapSignIn(cloudServiceId: $cloudServiceId, roles: $roles, signInParams: $signInParams) {
    code
    expiresAt
  }
}
    `;
export type ImapSignInMutationFn = ApolloReactCommon.MutationFunction<ImapSignInMutation, ImapSignInMutationVariables>;

    export function useImapSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImapSignInMutation, ImapSignInMutationVariables>) {
      return ApolloReactHooks.useMutation<ImapSignInMutation, ImapSignInMutationVariables>(ImapSignInDocument, baseOptions);
    }
export type ImapSignInMutationHookResult = ReturnType<typeof useImapSignInMutation>;
export type ImapSignInMutationResult = ApolloReactCommon.MutationResult<ImapSignInMutation>;
export type ImapSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<ImapSignInMutation, ImapSignInMutationVariables>;
export const ServiceAccountSignInDocument = gql`
    mutation ServiceAccountSignIn($cloudServiceId: String!, $roles: [String!]!, $emailAddress: String!, $state: String!) {
  serviceAccountSignIn(cloudServiceId: $cloudServiceId, roles: $roles, emailAddress: $emailAddress, state: $state) {
    code
    expiresAt
  }
}
    `;
export type ServiceAccountSignInMutationFn = ApolloReactCommon.MutationFunction<ServiceAccountSignInMutation, ServiceAccountSignInMutationVariables>;

    export function useServiceAccountSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ServiceAccountSignInMutation, ServiceAccountSignInMutationVariables>) {
      return ApolloReactHooks.useMutation<ServiceAccountSignInMutation, ServiceAccountSignInMutationVariables>(ServiceAccountSignInDocument, baseOptions);
    }
export type ServiceAccountSignInMutationHookResult = ReturnType<typeof useServiceAccountSignInMutation>;
export type ServiceAccountSignInMutationResult = ApolloReactCommon.MutationResult<ServiceAccountSignInMutation>;
export type ServiceAccountSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<ServiceAccountSignInMutation, ServiceAccountSignInMutationVariables>;
export const AdvanceToNextEmailDeliveryRoundDocument = gql`
    mutation AdvanceToNextEmailDeliveryRound($offboardingProjectId: String!, $expectedNextRound: Int!) {
  advanceToNextEmailDeliveryRound(offboardingProjectId: $offboardingProjectId, expectedNextRound: $expectedNextRound) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type AdvanceToNextEmailDeliveryRoundMutationFn = ApolloReactCommon.MutationFunction<AdvanceToNextEmailDeliveryRoundMutation, AdvanceToNextEmailDeliveryRoundMutationVariables>;

    export function useAdvanceToNextEmailDeliveryRoundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvanceToNextEmailDeliveryRoundMutation, AdvanceToNextEmailDeliveryRoundMutationVariables>) {
      return ApolloReactHooks.useMutation<AdvanceToNextEmailDeliveryRoundMutation, AdvanceToNextEmailDeliveryRoundMutationVariables>(AdvanceToNextEmailDeliveryRoundDocument, baseOptions);
    }
export type AdvanceToNextEmailDeliveryRoundMutationHookResult = ReturnType<typeof useAdvanceToNextEmailDeliveryRoundMutation>;
export type AdvanceToNextEmailDeliveryRoundMutationResult = ApolloReactCommon.MutationResult<AdvanceToNextEmailDeliveryRoundMutation>;
export type AdvanceToNextEmailDeliveryRoundMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvanceToNextEmailDeliveryRoundMutation, AdvanceToNextEmailDeliveryRoundMutationVariables>;
export const DeleteOffboardingProjectDocument = gql`
    mutation DeleteOffboardingProject($id: String!) {
  deleteOffboardingProject(id: $id)
}
    `;
export type DeleteOffboardingProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteOffboardingProjectMutation, DeleteOffboardingProjectMutationVariables>;

    export function useDeleteOffboardingProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOffboardingProjectMutation, DeleteOffboardingProjectMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteOffboardingProjectMutation, DeleteOffboardingProjectMutationVariables>(DeleteOffboardingProjectDocument, baseOptions);
    }
export type DeleteOffboardingProjectMutationHookResult = ReturnType<typeof useDeleteOffboardingProjectMutation>;
export type DeleteOffboardingProjectMutationResult = ApolloReactCommon.MutationResult<DeleteOffboardingProjectMutation>;
export type DeleteOffboardingProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOffboardingProjectMutation, DeleteOffboardingProjectMutationVariables>;
export const DeleteOffboardingProjectEntriesDocument = gql`
    mutation DeleteOffboardingProjectEntries($id: String!, $emailAddresses: [String!]!) {
  deleteOffboardingProjectEntries(id: $id, emailAddresses: $emailAddresses) {
    deletedEntryCount
    skippedEmailAddresses
  }
}
    `;
export type DeleteOffboardingProjectEntriesMutationFn = ApolloReactCommon.MutationFunction<DeleteOffboardingProjectEntriesMutation, DeleteOffboardingProjectEntriesMutationVariables>;

    export function useDeleteOffboardingProjectEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOffboardingProjectEntriesMutation, DeleteOffboardingProjectEntriesMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteOffboardingProjectEntriesMutation, DeleteOffboardingProjectEntriesMutationVariables>(DeleteOffboardingProjectEntriesDocument, baseOptions);
    }
export type DeleteOffboardingProjectEntriesMutationHookResult = ReturnType<typeof useDeleteOffboardingProjectEntriesMutation>;
export type DeleteOffboardingProjectEntriesMutationResult = ApolloReactCommon.MutationResult<DeleteOffboardingProjectEntriesMutation>;
export type DeleteOffboardingProjectEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOffboardingProjectEntriesMutation, DeleteOffboardingProjectEntriesMutationVariables>;
export const DisableOffboardingProjectEmailDeliveryDocument = gql`
    mutation DisableOffboardingProjectEmailDelivery($id: String!) {
  disableOffboardingProjectEmailDelivery(id: $id) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type DisableOffboardingProjectEmailDeliveryMutationFn = ApolloReactCommon.MutationFunction<DisableOffboardingProjectEmailDeliveryMutation, DisableOffboardingProjectEmailDeliveryMutationVariables>;

    export function useDisableOffboardingProjectEmailDeliveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableOffboardingProjectEmailDeliveryMutation, DisableOffboardingProjectEmailDeliveryMutationVariables>) {
      return ApolloReactHooks.useMutation<DisableOffboardingProjectEmailDeliveryMutation, DisableOffboardingProjectEmailDeliveryMutationVariables>(DisableOffboardingProjectEmailDeliveryDocument, baseOptions);
    }
export type DisableOffboardingProjectEmailDeliveryMutationHookResult = ReturnType<typeof useDisableOffboardingProjectEmailDeliveryMutation>;
export type DisableOffboardingProjectEmailDeliveryMutationResult = ApolloReactCommon.MutationResult<DisableOffboardingProjectEmailDeliveryMutation>;
export type DisableOffboardingProjectEmailDeliveryMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableOffboardingProjectEmailDeliveryMutation, DisableOffboardingProjectEmailDeliveryMutationVariables>;
export const ForceStartEmailDeliveryRoundDocument = gql`
    mutation ForceStartEmailDeliveryRound($offboardingProjectId: String!, $round: Int!) {
  forceStartEmailDeliveryRound(offboardingProjectId: $offboardingProjectId, round: $round) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type ForceStartEmailDeliveryRoundMutationFn = ApolloReactCommon.MutationFunction<ForceStartEmailDeliveryRoundMutation, ForceStartEmailDeliveryRoundMutationVariables>;

    export function useForceStartEmailDeliveryRoundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceStartEmailDeliveryRoundMutation, ForceStartEmailDeliveryRoundMutationVariables>) {
      return ApolloReactHooks.useMutation<ForceStartEmailDeliveryRoundMutation, ForceStartEmailDeliveryRoundMutationVariables>(ForceStartEmailDeliveryRoundDocument, baseOptions);
    }
export type ForceStartEmailDeliveryRoundMutationHookResult = ReturnType<typeof useForceStartEmailDeliveryRoundMutation>;
export type ForceStartEmailDeliveryRoundMutationResult = ApolloReactCommon.MutationResult<ForceStartEmailDeliveryRoundMutation>;
export type ForceStartEmailDeliveryRoundMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceStartEmailDeliveryRoundMutation, ForceStartEmailDeliveryRoundMutationVariables>;
export const GetCurrentOffboardingProjectUpdateDocument = gql`
    query GetCurrentOffboardingProjectUpdate($id: String!) {
  offboardingProject(id: $id) {
    id
    currentUpdateId
  }
}
    `;

    export function useGetCurrentOffboardingProjectUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentOffboardingProjectUpdateQuery, GetCurrentOffboardingProjectUpdateQueryVariables>) {
      return ApolloReactHooks.useQuery<GetCurrentOffboardingProjectUpdateQuery, GetCurrentOffboardingProjectUpdateQueryVariables>(GetCurrentOffboardingProjectUpdateDocument, baseOptions);
    }
      export function useGetCurrentOffboardingProjectUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentOffboardingProjectUpdateQuery, GetCurrentOffboardingProjectUpdateQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetCurrentOffboardingProjectUpdateQuery, GetCurrentOffboardingProjectUpdateQueryVariables>(GetCurrentOffboardingProjectUpdateDocument, baseOptions);
      }
      
export type GetCurrentOffboardingProjectUpdateQueryHookResult = ReturnType<typeof useGetCurrentOffboardingProjectUpdateQuery>;
export type GetCurrentOffboardingProjectUpdateQueryResult = ApolloReactCommon.QueryResult<GetCurrentOffboardingProjectUpdateQuery, GetCurrentOffboardingProjectUpdateQueryVariables>;
export const GetEmailDeliverySchedulesDocument = gql`
    query GetEmailDeliverySchedules {
  emailDeliverySchedules {
    ...EmailDeliverySchedule
  }
}
    ${EmailDeliveryScheduleFragmentDoc}`;

    export function useGetEmailDeliverySchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmailDeliverySchedulesQuery, GetEmailDeliverySchedulesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetEmailDeliverySchedulesQuery, GetEmailDeliverySchedulesQueryVariables>(GetEmailDeliverySchedulesDocument, baseOptions);
    }
      export function useGetEmailDeliverySchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailDeliverySchedulesQuery, GetEmailDeliverySchedulesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetEmailDeliverySchedulesQuery, GetEmailDeliverySchedulesQueryVariables>(GetEmailDeliverySchedulesDocument, baseOptions);
      }
      
export type GetEmailDeliverySchedulesQueryHookResult = ReturnType<typeof useGetEmailDeliverySchedulesQuery>;
export type GetEmailDeliverySchedulesQueryResult = ApolloReactCommon.QueryResult<GetEmailDeliverySchedulesQuery, GetEmailDeliverySchedulesQueryVariables>;
export const GetOffboardingProjectDocument = gql`
    query GetOffboardingProject($id: String!) {
  offboardingProject(id: $id) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;

    export function useGetOffboardingProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardingProjectQuery, GetOffboardingProjectQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOffboardingProjectQuery, GetOffboardingProjectQueryVariables>(GetOffboardingProjectDocument, baseOptions);
    }
      export function useGetOffboardingProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardingProjectQuery, GetOffboardingProjectQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOffboardingProjectQuery, GetOffboardingProjectQueryVariables>(GetOffboardingProjectDocument, baseOptions);
      }
      
export type GetOffboardingProjectQueryHookResult = ReturnType<typeof useGetOffboardingProjectQuery>;
export type GetOffboardingProjectQueryResult = ApolloReactCommon.QueryResult<GetOffboardingProjectQuery, GetOffboardingProjectQueryVariables>;
export const GetOffboardingProjectEntryCountDocument = gql`
    query GetOffboardingProjectEntryCount($id: String!) {
  offboardingProjectEntryCount(id: $id)
}
    `;

    export function useGetOffboardingProjectEntryCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardingProjectEntryCountQuery, GetOffboardingProjectEntryCountQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOffboardingProjectEntryCountQuery, GetOffboardingProjectEntryCountQueryVariables>(GetOffboardingProjectEntryCountDocument, baseOptions);
    }
      export function useGetOffboardingProjectEntryCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardingProjectEntryCountQuery, GetOffboardingProjectEntryCountQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOffboardingProjectEntryCountQuery, GetOffboardingProjectEntryCountQueryVariables>(GetOffboardingProjectEntryCountDocument, baseOptions);
      }
      
export type GetOffboardingProjectEntryCountQueryHookResult = ReturnType<typeof useGetOffboardingProjectEntryCountQuery>;
export type GetOffboardingProjectEntryCountQueryResult = ApolloReactCommon.QueryResult<GetOffboardingProjectEntryCountQuery, GetOffboardingProjectEntryCountQueryVariables>;
export const GetOffboardingProjectEntryParticipationStatusDocument = gql`
    query GetOffboardingProjectEntryParticipationStatus($id: String!) {
  offboardingProjectEntryParticipationStatus(id: $id)
}
    `;

    export function useGetOffboardingProjectEntryParticipationStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardingProjectEntryParticipationStatusQuery, GetOffboardingProjectEntryParticipationStatusQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOffboardingProjectEntryParticipationStatusQuery, GetOffboardingProjectEntryParticipationStatusQueryVariables>(GetOffboardingProjectEntryParticipationStatusDocument, baseOptions);
    }
      export function useGetOffboardingProjectEntryParticipationStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardingProjectEntryParticipationStatusQuery, GetOffboardingProjectEntryParticipationStatusQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOffboardingProjectEntryParticipationStatusQuery, GetOffboardingProjectEntryParticipationStatusQueryVariables>(GetOffboardingProjectEntryParticipationStatusDocument, baseOptions);
      }
      
export type GetOffboardingProjectEntryParticipationStatusQueryHookResult = ReturnType<typeof useGetOffboardingProjectEntryParticipationStatusQuery>;
export type GetOffboardingProjectEntryParticipationStatusQueryResult = ApolloReactCommon.QueryResult<GetOffboardingProjectEntryParticipationStatusQuery, GetOffboardingProjectEntryParticipationStatusQueryVariables>;
export const GetOffboardingProjectUpdateDocument = gql`
    query GetOffboardingProjectUpdate($id: String!) {
  offboardingProjectUpdate(id: $id) {
    ...OffboardingProjectUpdate
  }
}
    ${OffboardingProjectUpdateFragmentDoc}`;

    export function useGetOffboardingProjectUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardingProjectUpdateQuery, GetOffboardingProjectUpdateQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOffboardingProjectUpdateQuery, GetOffboardingProjectUpdateQueryVariables>(GetOffboardingProjectUpdateDocument, baseOptions);
    }
      export function useGetOffboardingProjectUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardingProjectUpdateQuery, GetOffboardingProjectUpdateQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOffboardingProjectUpdateQuery, GetOffboardingProjectUpdateQueryVariables>(GetOffboardingProjectUpdateDocument, baseOptions);
      }
      
export type GetOffboardingProjectUpdateQueryHookResult = ReturnType<typeof useGetOffboardingProjectUpdateQuery>;
export type GetOffboardingProjectUpdateQueryResult = ApolloReactCommon.QueryResult<GetOffboardingProjectUpdateQuery, GetOffboardingProjectUpdateQueryVariables>;
export const GetOffboardingProjectUpdateHistoryDocument = gql`
    query GetOffboardingProjectUpdateHistory($id: String!) {
  offboardingProjectUpdateHistory(id: $id) {
    ...OffboardingProjectUpdate
  }
}
    ${OffboardingProjectUpdateFragmentDoc}`;

    export function useGetOffboardingProjectUpdateHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardingProjectUpdateHistoryQuery, GetOffboardingProjectUpdateHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOffboardingProjectUpdateHistoryQuery, GetOffboardingProjectUpdateHistoryQueryVariables>(GetOffboardingProjectUpdateHistoryDocument, baseOptions);
    }
      export function useGetOffboardingProjectUpdateHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardingProjectUpdateHistoryQuery, GetOffboardingProjectUpdateHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOffboardingProjectUpdateHistoryQuery, GetOffboardingProjectUpdateHistoryQueryVariables>(GetOffboardingProjectUpdateHistoryDocument, baseOptions);
      }
      
export type GetOffboardingProjectUpdateHistoryQueryHookResult = ReturnType<typeof useGetOffboardingProjectUpdateHistoryQuery>;
export type GetOffboardingProjectUpdateHistoryQueryResult = ApolloReactCommon.QueryResult<GetOffboardingProjectUpdateHistoryQuery, GetOffboardingProjectUpdateHistoryQueryVariables>;
export const InsertOffboardingProjectDocument = gql`
    mutation InsertOffboardingProject($organizationId: Int!, $formData: OffboardingProjectFormData!) {
  insertOffboardingProject(organizationId: $organizationId, formData: $formData) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type InsertOffboardingProjectMutationFn = ApolloReactCommon.MutationFunction<InsertOffboardingProjectMutation, InsertOffboardingProjectMutationVariables>;

    export function useInsertOffboardingProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOffboardingProjectMutation, InsertOffboardingProjectMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertOffboardingProjectMutation, InsertOffboardingProjectMutationVariables>(InsertOffboardingProjectDocument, baseOptions);
    }
export type InsertOffboardingProjectMutationHookResult = ReturnType<typeof useInsertOffboardingProjectMutation>;
export type InsertOffboardingProjectMutationResult = ApolloReactCommon.MutationResult<InsertOffboardingProjectMutation>;
export type InsertOffboardingProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOffboardingProjectMutation, InsertOffboardingProjectMutationVariables>;
export const ListOffboardingProjectStatsDocument = gql`
    query ListOffboardingProjectStats($id: String!) {
  offboardingProjectStats(id: $id) {
    ...OffboardingProjectStats
  }
}
    ${OffboardingProjectStatsFragmentDoc}`;

    export function useListOffboardingProjectStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListOffboardingProjectStatsQuery, ListOffboardingProjectStatsQueryVariables>) {
      return ApolloReactHooks.useQuery<ListOffboardingProjectStatsQuery, ListOffboardingProjectStatsQueryVariables>(ListOffboardingProjectStatsDocument, baseOptions);
    }
      export function useListOffboardingProjectStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOffboardingProjectStatsQuery, ListOffboardingProjectStatsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ListOffboardingProjectStatsQuery, ListOffboardingProjectStatsQueryVariables>(ListOffboardingProjectStatsDocument, baseOptions);
      }
      
export type ListOffboardingProjectStatsQueryHookResult = ReturnType<typeof useListOffboardingProjectStatsQuery>;
export type ListOffboardingProjectStatsQueryResult = ApolloReactCommon.QueryResult<ListOffboardingProjectStatsQuery, ListOffboardingProjectStatsQueryVariables>;
export const ListOffboardingProjectUpdatesDocument = gql`
    query ListOffboardingProjectUpdates($ids: [String!]!) {
  offboardingProjectUpdates(ids: $ids) {
    ...OffboardingProjectUpdate
  }
}
    ${OffboardingProjectUpdateFragmentDoc}`;

    export function useListOffboardingProjectUpdatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListOffboardingProjectUpdatesQuery, ListOffboardingProjectUpdatesQueryVariables>) {
      return ApolloReactHooks.useQuery<ListOffboardingProjectUpdatesQuery, ListOffboardingProjectUpdatesQueryVariables>(ListOffboardingProjectUpdatesDocument, baseOptions);
    }
      export function useListOffboardingProjectUpdatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOffboardingProjectUpdatesQuery, ListOffboardingProjectUpdatesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ListOffboardingProjectUpdatesQuery, ListOffboardingProjectUpdatesQueryVariables>(ListOffboardingProjectUpdatesDocument, baseOptions);
      }
      
export type ListOffboardingProjectUpdatesQueryHookResult = ReturnType<typeof useListOffboardingProjectUpdatesQuery>;
export type ListOffboardingProjectUpdatesQueryResult = ApolloReactCommon.QueryResult<ListOffboardingProjectUpdatesQuery, ListOffboardingProjectUpdatesQueryVariables>;
export const OptOutOffboardingProjectEntryDocument = gql`
    mutation OptOutOffboardingProjectEntry($id: String!, $reason: String!, $comment: String) {
  optOutOffboardingProjectEntry(id: $id, reason: $reason, comment: $comment)
}
    `;
export type OptOutOffboardingProjectEntryMutationFn = ApolloReactCommon.MutationFunction<OptOutOffboardingProjectEntryMutation, OptOutOffboardingProjectEntryMutationVariables>;

    export function useOptOutOffboardingProjectEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OptOutOffboardingProjectEntryMutation, OptOutOffboardingProjectEntryMutationVariables>) {
      return ApolloReactHooks.useMutation<OptOutOffboardingProjectEntryMutation, OptOutOffboardingProjectEntryMutationVariables>(OptOutOffboardingProjectEntryDocument, baseOptions);
    }
export type OptOutOffboardingProjectEntryMutationHookResult = ReturnType<typeof useOptOutOffboardingProjectEntryMutation>;
export type OptOutOffboardingProjectEntryMutationResult = ApolloReactCommon.MutationResult<OptOutOffboardingProjectEntryMutation>;
export type OptOutOffboardingProjectEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<OptOutOffboardingProjectEntryMutation, OptOutOffboardingProjectEntryMutationVariables>;
export const RenderOffboardingEmailPreviewDocument = gql`
    mutation RenderOffboardingEmailPreview($offboardingProjectId: String!, $round: Int!) {
  renderOffboardingEmailPreview(offboardingProjectId: $offboardingProjectId, round: $round) {
    ...EmailPreview
  }
}
    ${EmailPreviewFragmentDoc}`;
export type RenderOffboardingEmailPreviewMutationFn = ApolloReactCommon.MutationFunction<RenderOffboardingEmailPreviewMutation, RenderOffboardingEmailPreviewMutationVariables>;

    export function useRenderOffboardingEmailPreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenderOffboardingEmailPreviewMutation, RenderOffboardingEmailPreviewMutationVariables>) {
      return ApolloReactHooks.useMutation<RenderOffboardingEmailPreviewMutation, RenderOffboardingEmailPreviewMutationVariables>(RenderOffboardingEmailPreviewDocument, baseOptions);
    }
export type RenderOffboardingEmailPreviewMutationHookResult = ReturnType<typeof useRenderOffboardingEmailPreviewMutation>;
export type RenderOffboardingEmailPreviewMutationResult = ApolloReactCommon.MutationResult<RenderOffboardingEmailPreviewMutation>;
export type RenderOffboardingEmailPreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<RenderOffboardingEmailPreviewMutation, RenderOffboardingEmailPreviewMutationVariables>;
export const RestartCurrentEmailDeliveryRoundDocument = gql`
    mutation RestartCurrentEmailDeliveryRound($offboardingProjectId: String!, $expectedCurrentRound: Int!) {
  restartCurrentEmailDeliveryRound(offboardingProjectId: $offboardingProjectId, expectedCurrentRound: $expectedCurrentRound) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type RestartCurrentEmailDeliveryRoundMutationFn = ApolloReactCommon.MutationFunction<RestartCurrentEmailDeliveryRoundMutation, RestartCurrentEmailDeliveryRoundMutationVariables>;

    export function useRestartCurrentEmailDeliveryRoundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestartCurrentEmailDeliveryRoundMutation, RestartCurrentEmailDeliveryRoundMutationVariables>) {
      return ApolloReactHooks.useMutation<RestartCurrentEmailDeliveryRoundMutation, RestartCurrentEmailDeliveryRoundMutationVariables>(RestartCurrentEmailDeliveryRoundDocument, baseOptions);
    }
export type RestartCurrentEmailDeliveryRoundMutationHookResult = ReturnType<typeof useRestartCurrentEmailDeliveryRoundMutation>;
export type RestartCurrentEmailDeliveryRoundMutationResult = ApolloReactCommon.MutationResult<RestartCurrentEmailDeliveryRoundMutation>;
export type RestartCurrentEmailDeliveryRoundMutationOptions = ApolloReactCommon.BaseMutationOptions<RestartCurrentEmailDeliveryRoundMutation, RestartCurrentEmailDeliveryRoundMutationVariables>;
export const SearchOffboardingProjectsDocument = gql`
    query SearchOffboardingProjects($organizationId: Int!, $term: String!) {
  searchOffboardingProjects(organizationId: $organizationId, term: $term) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;

    export function useSearchOffboardingProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOffboardingProjectsQuery, SearchOffboardingProjectsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchOffboardingProjectsQuery, SearchOffboardingProjectsQueryVariables>(SearchOffboardingProjectsDocument, baseOptions);
    }
      export function useSearchOffboardingProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOffboardingProjectsQuery, SearchOffboardingProjectsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchOffboardingProjectsQuery, SearchOffboardingProjectsQueryVariables>(SearchOffboardingProjectsDocument, baseOptions);
      }
      
export type SearchOffboardingProjectsQueryHookResult = ReturnType<typeof useSearchOffboardingProjectsQuery>;
export type SearchOffboardingProjectsQueryResult = ApolloReactCommon.QueryResult<SearchOffboardingProjectsQuery, SearchOffboardingProjectsQueryVariables>;
export const SendTestOffboardingProjectEmailDocument = gql`
    mutation SendTestOffboardingProjectEmail($offboardingProjectId: String!, $round: Int!) {
  sendTestOffboardingProjectEmail(offboardingProjectId: $offboardingProjectId, round: $round) {
    ...EmailPreview
  }
}
    ${EmailPreviewFragmentDoc}`;
export type SendTestOffboardingProjectEmailMutationFn = ApolloReactCommon.MutationFunction<SendTestOffboardingProjectEmailMutation, SendTestOffboardingProjectEmailMutationVariables>;

    export function useSendTestOffboardingProjectEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTestOffboardingProjectEmailMutation, SendTestOffboardingProjectEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<SendTestOffboardingProjectEmailMutation, SendTestOffboardingProjectEmailMutationVariables>(SendTestOffboardingProjectEmailDocument, baseOptions);
    }
export type SendTestOffboardingProjectEmailMutationHookResult = ReturnType<typeof useSendTestOffboardingProjectEmailMutation>;
export type SendTestOffboardingProjectEmailMutationResult = ApolloReactCommon.MutationResult<SendTestOffboardingProjectEmailMutation>;
export type SendTestOffboardingProjectEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTestOffboardingProjectEmailMutation, SendTestOffboardingProjectEmailMutationVariables>;
export const UnsubscribeOffboardingProjectEntryDocument = gql`
    mutation UnsubscribeOffboardingProjectEntry($id: String!) {
  unsubscribeOffboardingProjectEntry(id: $id)
}
    `;
export type UnsubscribeOffboardingProjectEntryMutationFn = ApolloReactCommon.MutationFunction<UnsubscribeOffboardingProjectEntryMutation, UnsubscribeOffboardingProjectEntryMutationVariables>;

    export function useUnsubscribeOffboardingProjectEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeOffboardingProjectEntryMutation, UnsubscribeOffboardingProjectEntryMutationVariables>) {
      return ApolloReactHooks.useMutation<UnsubscribeOffboardingProjectEntryMutation, UnsubscribeOffboardingProjectEntryMutationVariables>(UnsubscribeOffboardingProjectEntryDocument, baseOptions);
    }
export type UnsubscribeOffboardingProjectEntryMutationHookResult = ReturnType<typeof useUnsubscribeOffboardingProjectEntryMutation>;
export type UnsubscribeOffboardingProjectEntryMutationResult = ApolloReactCommon.MutationResult<UnsubscribeOffboardingProjectEntryMutation>;
export type UnsubscribeOffboardingProjectEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeOffboardingProjectEntryMutation, UnsubscribeOffboardingProjectEntryMutationVariables>;
export const UpdateOffboardingProjectDocument = gql`
    mutation UpdateOffboardingProject($id: String!, $formData: OffboardingProjectFormData!) {
  updateOffboardingProject(id: $id, formData: $formData) {
    ...OffboardingProject
  }
}
    ${OffboardingProjectFragmentDoc}`;
export type UpdateOffboardingProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateOffboardingProjectMutation, UpdateOffboardingProjectMutationVariables>;

    export function useUpdateOffboardingProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOffboardingProjectMutation, UpdateOffboardingProjectMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateOffboardingProjectMutation, UpdateOffboardingProjectMutationVariables>(UpdateOffboardingProjectDocument, baseOptions);
    }
export type UpdateOffboardingProjectMutationHookResult = ReturnType<typeof useUpdateOffboardingProjectMutation>;
export type UpdateOffboardingProjectMutationResult = ApolloReactCommon.MutationResult<UpdateOffboardingProjectMutation>;
export type UpdateOffboardingProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOffboardingProjectMutation, UpdateOffboardingProjectMutationVariables>;
export const AssignBatchDocument = gql`
    mutation AssignBatch($batchId: String!, $userId: String!) {
  assignBatch(batchId: $batchId, userId: $userId) {
    ...Batch
    ...BatchMigrations
  }
}
    ${BatchFragmentDoc}
${BatchMigrationsFragmentDoc}`;
export type AssignBatchMutationFn = ApolloReactCommon.MutationFunction<AssignBatchMutation, AssignBatchMutationVariables>;

    export function useAssignBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignBatchMutation, AssignBatchMutationVariables>) {
      return ApolloReactHooks.useMutation<AssignBatchMutation, AssignBatchMutationVariables>(AssignBatchDocument, baseOptions);
    }
export type AssignBatchMutationHookResult = ReturnType<typeof useAssignBatchMutation>;
export type AssignBatchMutationResult = ApolloReactCommon.MutationResult<AssignBatchMutation>;
export type AssignBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignBatchMutation, AssignBatchMutationVariables>;
export const BatchesUpdatedDocument = gql`
    subscription BatchesUpdated($batchIds: [String!]!) {
  batchesUpdated(batchIds: $batchIds) {
    eventType
    batches {
      ...Batch
    }
  }
}
    ${BatchFragmentDoc}`;

    export function useBatchesUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BatchesUpdatedSubscription, BatchesUpdatedSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<BatchesUpdatedSubscription, BatchesUpdatedSubscriptionVariables>(BatchesUpdatedDocument, baseOptions);
    }
export type BatchesUpdatedSubscriptionHookResult = ReturnType<typeof useBatchesUpdatedSubscription>;
export type BatchesUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BatchesUpdatedSubscription>;
export const GenerateBatchReportDocument = gql`
    mutation GenerateBatchReport($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $batchItems: [ReportItem!]!) {
  generateBatchReport(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, batchItems: $batchItems)
}
    `;
export type GenerateBatchReportMutationFn = ApolloReactCommon.MutationFunction<GenerateBatchReportMutation, GenerateBatchReportMutationVariables>;

    export function useGenerateBatchReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateBatchReportMutation, GenerateBatchReportMutationVariables>) {
      return ApolloReactHooks.useMutation<GenerateBatchReportMutation, GenerateBatchReportMutationVariables>(GenerateBatchReportDocument, baseOptions);
    }
export type GenerateBatchReportMutationHookResult = ReturnType<typeof useGenerateBatchReportMutation>;
export type GenerateBatchReportMutationResult = ApolloReactCommon.MutationResult<GenerateBatchReportMutation>;
export type GenerateBatchReportMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateBatchReportMutation, GenerateBatchReportMutationVariables>;
export const GetBatchDocument = gql`
    query GetBatch($batchId: String!) {
  batch(batchId: $batchId) {
    ...Batch
    ...BatchMigrations
  }
}
    ${BatchFragmentDoc}
${BatchMigrationsFragmentDoc}`;

    export function useGetBatchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchQuery, GetBatchQueryVariables>) {
      return ApolloReactHooks.useQuery<GetBatchQuery, GetBatchQueryVariables>(GetBatchDocument, baseOptions);
    }
      export function useGetBatchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchQuery, GetBatchQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetBatchQuery, GetBatchQueryVariables>(GetBatchDocument, baseOptions);
      }
      
export type GetBatchQueryHookResult = ReturnType<typeof useGetBatchQuery>;
export type GetBatchQueryResult = ApolloReactCommon.QueryResult<GetBatchQuery, GetBatchQueryVariables>;
export const GetBatchOrderDocument = gql`
    query GetBatchOrder($batchId: String!) {
  batch(batchId: $batchId) {
    batchOrder {
      ...BatchOrder
      ...BatchOrderRevenue
    }
  }
}
    ${BatchOrderFragmentDoc}
${BatchOrderRevenueFragmentDoc}`;

    export function useGetBatchOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchOrderQuery, GetBatchOrderQueryVariables>) {
      return ApolloReactHooks.useQuery<GetBatchOrderQuery, GetBatchOrderQueryVariables>(GetBatchOrderDocument, baseOptions);
    }
      export function useGetBatchOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchOrderQuery, GetBatchOrderQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetBatchOrderQuery, GetBatchOrderQueryVariables>(GetBatchOrderDocument, baseOptions);
      }
      
export type GetBatchOrderQueryHookResult = ReturnType<typeof useGetBatchOrderQuery>;
export type GetBatchOrderQueryResult = ApolloReactCommon.QueryResult<GetBatchOrderQuery, GetBatchOrderQueryVariables>;
export const GetBatchesDocument = gql`
    query GetBatches($batchIds: [String!]!) {
  batches(batchIds: $batchIds) {
    ...Batch
  }
}
    ${BatchFragmentDoc}`;

    export function useGetBatchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, baseOptions);
    }
      export function useGetBatchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, baseOptions);
      }
      
export type GetBatchesQueryHookResult = ReturnType<typeof useGetBatchesQuery>;
export type GetBatchesQueryResult = ApolloReactCommon.QueryResult<GetBatchesQuery, GetBatchesQueryVariables>;
export const LaunchBatchDocument = gql`
    mutation LaunchBatch($batchId: String!) {
  launchBatch(batchId: $batchId) {
    ...Batch
  }
}
    ${BatchFragmentDoc}`;
export type LaunchBatchMutationFn = ApolloReactCommon.MutationFunction<LaunchBatchMutation, LaunchBatchMutationVariables>;

    export function useLaunchBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LaunchBatchMutation, LaunchBatchMutationVariables>) {
      return ApolloReactHooks.useMutation<LaunchBatchMutation, LaunchBatchMutationVariables>(LaunchBatchDocument, baseOptions);
    }
export type LaunchBatchMutationHookResult = ReturnType<typeof useLaunchBatchMutation>;
export type LaunchBatchMutationResult = ApolloReactCommon.MutationResult<LaunchBatchMutation>;
export type LaunchBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<LaunchBatchMutation, LaunchBatchMutationVariables>;
export const PrepareBatchOrderDocument = gql`
    mutation PrepareBatchOrder($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $batchItems: [BatchItemSpec!]!) {
  prepareBatchOrder(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, batchItems: $batchItems) {
    ...PrepareBatchOrderResult
  }
}
    ${PrepareBatchOrderResultFragmentDoc}`;
export type PrepareBatchOrderMutationFn = ApolloReactCommon.MutationFunction<PrepareBatchOrderMutation, PrepareBatchOrderMutationVariables>;

    export function usePrepareBatchOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareBatchOrderMutation, PrepareBatchOrderMutationVariables>) {
      return ApolloReactHooks.useMutation<PrepareBatchOrderMutation, PrepareBatchOrderMutationVariables>(PrepareBatchOrderDocument, baseOptions);
    }
export type PrepareBatchOrderMutationHookResult = ReturnType<typeof usePrepareBatchOrderMutation>;
export type PrepareBatchOrderMutationResult = ApolloReactCommon.MutationResult<PrepareBatchOrderMutation>;
export type PrepareBatchOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareBatchOrderMutation, PrepareBatchOrderMutationVariables>;
export const RefreshBatchFactsDocument = gql`
    mutation RefreshBatchFacts($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $batchItems: [BatchItemSpec!]!) {
  refreshBatchFacts(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, batchItems: $batchItems) {
    facts {
      ...FactOrPlaceholder
    }
    scanJobIds
  }
}
    ${FactOrPlaceholderFragmentDoc}`;
export type RefreshBatchFactsMutationFn = ApolloReactCommon.MutationFunction<RefreshBatchFactsMutation, RefreshBatchFactsMutationVariables>;

    export function useRefreshBatchFactsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshBatchFactsMutation, RefreshBatchFactsMutationVariables>) {
      return ApolloReactHooks.useMutation<RefreshBatchFactsMutation, RefreshBatchFactsMutationVariables>(RefreshBatchFactsDocument, baseOptions);
    }
export type RefreshBatchFactsMutationHookResult = ReturnType<typeof useRefreshBatchFactsMutation>;
export type RefreshBatchFactsMutationResult = ApolloReactCommon.MutationResult<RefreshBatchFactsMutation>;
export type RefreshBatchFactsMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshBatchFactsMutation, RefreshBatchFactsMutationVariables>;
export const RenameBatchDocument = gql`
    mutation RenameBatch($batchId: String!, $name: String!) {
  renameBatch(batchId: $batchId, name: $name) {
    ...Batch
  }
}
    ${BatchFragmentDoc}`;
export type RenameBatchMutationFn = ApolloReactCommon.MutationFunction<RenameBatchMutation, RenameBatchMutationVariables>;

    export function useRenameBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameBatchMutation, RenameBatchMutationVariables>) {
      return ApolloReactHooks.useMutation<RenameBatchMutation, RenameBatchMutationVariables>(RenameBatchDocument, baseOptions);
    }
export type RenameBatchMutationHookResult = ReturnType<typeof useRenameBatchMutation>;
export type RenameBatchMutationResult = ApolloReactCommon.MutationResult<RenameBatchMutation>;
export type RenameBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameBatchMutation, RenameBatchMutationVariables>;
export const ScheduleBatchDocument = gql`
    mutation ScheduleBatch($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $batchItems: [BatchItemSpec!]!, $expectedAmountToBePaid: BigDecimal!, $discounts: Discounts, $braintreePayloadJson: String, $deviceData: String, $externalPayment: ExternalPayment) {
  scheduleBatch(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, batchItems: $batchItems, expectedAmountToBePaid: $expectedAmountToBePaid, discounts: $discounts, braintreePayloadJson: $braintreePayloadJson, deviceData: $deviceData, externalPayment: $externalPayment) {
    ...Batch
  }
}
    ${BatchFragmentDoc}`;
export type ScheduleBatchMutationFn = ApolloReactCommon.MutationFunction<ScheduleBatchMutation, ScheduleBatchMutationVariables>;

    export function useScheduleBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleBatchMutation, ScheduleBatchMutationVariables>) {
      return ApolloReactHooks.useMutation<ScheduleBatchMutation, ScheduleBatchMutationVariables>(ScheduleBatchDocument, baseOptions);
    }
export type ScheduleBatchMutationHookResult = ReturnType<typeof useScheduleBatchMutation>;
export type ScheduleBatchMutationResult = ApolloReactCommon.MutationResult<ScheduleBatchMutation>;
export type ScheduleBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleBatchMutation, ScheduleBatchMutationVariables>;
export const SyncUpBatchDocument = gql`
    mutation SyncUpBatch($batchId: String!) {
  syncUpBatch(batchId: $batchId) {
    ...Batch
  }
}
    ${BatchFragmentDoc}`;
export type SyncUpBatchMutationFn = ApolloReactCommon.MutationFunction<SyncUpBatchMutation, SyncUpBatchMutationVariables>;

    export function useSyncUpBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncUpBatchMutation, SyncUpBatchMutationVariables>) {
      return ApolloReactHooks.useMutation<SyncUpBatchMutation, SyncUpBatchMutationVariables>(SyncUpBatchDocument, baseOptions);
    }
export type SyncUpBatchMutationHookResult = ReturnType<typeof useSyncUpBatchMutation>;
export type SyncUpBatchMutationResult = ApolloReactCommon.MutationResult<SyncUpBatchMutation>;
export type SyncUpBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncUpBatchMutation, SyncUpBatchMutationVariables>;
export const VerifyGoogleAdminConnectionDocument = gql`
    mutation VerifyGoogleAdminConnection($connectionId: String!) {
  verifyGoogleAdminConnection(connectionId: $connectionId)
}
    `;
export type VerifyGoogleAdminConnectionMutationFn = ApolloReactCommon.MutationFunction<VerifyGoogleAdminConnectionMutation, VerifyGoogleAdminConnectionMutationVariables>;

    export function useVerifyGoogleAdminConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyGoogleAdminConnectionMutation, VerifyGoogleAdminConnectionMutationVariables>) {
      return ApolloReactHooks.useMutation<VerifyGoogleAdminConnectionMutation, VerifyGoogleAdminConnectionMutationVariables>(VerifyGoogleAdminConnectionDocument, baseOptions);
    }
export type VerifyGoogleAdminConnectionMutationHookResult = ReturnType<typeof useVerifyGoogleAdminConnectionMutation>;
export type VerifyGoogleAdminConnectionMutationResult = ApolloReactCommon.MutationResult<VerifyGoogleAdminConnectionMutation>;
export type VerifyGoogleAdminConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyGoogleAdminConnectionMutation, VerifyGoogleAdminConnectionMutationVariables>;
export const VerifyMicrosoftAdminConnectionDocument = gql`
    mutation VerifyMicrosoftAdminConnection($connectionId: String!) {
  verifyMicrosoftAdminConnection(connectionId: $connectionId)
}
    `;
export type VerifyMicrosoftAdminConnectionMutationFn = ApolloReactCommon.MutationFunction<VerifyMicrosoftAdminConnectionMutation, VerifyMicrosoftAdminConnectionMutationVariables>;

    export function useVerifyMicrosoftAdminConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyMicrosoftAdminConnectionMutation, VerifyMicrosoftAdminConnectionMutationVariables>) {
      return ApolloReactHooks.useMutation<VerifyMicrosoftAdminConnectionMutation, VerifyMicrosoftAdminConnectionMutationVariables>(VerifyMicrosoftAdminConnectionDocument, baseOptions);
    }
export type VerifyMicrosoftAdminConnectionMutationHookResult = ReturnType<typeof useVerifyMicrosoftAdminConnectionMutation>;
export type VerifyMicrosoftAdminConnectionMutationResult = ApolloReactCommon.MutationResult<VerifyMicrosoftAdminConnectionMutation>;
export type VerifyMicrosoftAdminConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyMicrosoftAdminConnectionMutation, VerifyMicrosoftAdminConnectionMutationVariables>;
export const BuildWorkflowDefDocument = gql`
    query BuildWorkflowDef($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $blueprintInputs: [BlueprintInputsEntryInput!]!) {
  buildWorkflowDef(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, blueprintInputs: $blueprintInputs) {
    endpointFamilyDef
    workflowDef
  }
}
    `;

    export function useBuildWorkflowDefQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BuildWorkflowDefQuery, BuildWorkflowDefQueryVariables>) {
      return ApolloReactHooks.useQuery<BuildWorkflowDefQuery, BuildWorkflowDefQueryVariables>(BuildWorkflowDefDocument, baseOptions);
    }
      export function useBuildWorkflowDefLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BuildWorkflowDefQuery, BuildWorkflowDefQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<BuildWorkflowDefQuery, BuildWorkflowDefQueryVariables>(BuildWorkflowDefDocument, baseOptions);
      }
      
export type BuildWorkflowDefQueryHookResult = ReturnType<typeof useBuildWorkflowDefQuery>;
export type BuildWorkflowDefQueryResult = ApolloReactCommon.QueryResult<BuildWorkflowDefQuery, BuildWorkflowDefQueryVariables>;
export const CalcTimeEstimatesDocument = gql`
    mutation CalcTimeEstimates($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $blueprintInputs: [BlueprintInputsEntryInput!]!) {
  calcTimeEstimates(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, blueprintInputs: $blueprintInputs) {
    title
    connector
    serviceProvider
    params
    estimate {
      value
      trainingSamples
      goodnessOfFit
    }
  }
}
    `;
export type CalcTimeEstimatesMutationFn = ApolloReactCommon.MutationFunction<CalcTimeEstimatesMutation, CalcTimeEstimatesMutationVariables>;

    export function useCalcTimeEstimatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalcTimeEstimatesMutation, CalcTimeEstimatesMutationVariables>) {
      return ApolloReactHooks.useMutation<CalcTimeEstimatesMutation, CalcTimeEstimatesMutationVariables>(CalcTimeEstimatesDocument, baseOptions);
    }
export type CalcTimeEstimatesMutationHookResult = ReturnType<typeof useCalcTimeEstimatesMutation>;
export type CalcTimeEstimatesMutationResult = ApolloReactCommon.MutationResult<CalcTimeEstimatesMutation>;
export type CalcTimeEstimatesMutationOptions = ApolloReactCommon.BaseMutationOptions<CalcTimeEstimatesMutation, CalcTimeEstimatesMutationVariables>;
export const GetMigrationBlueprintDocument = gql`
    query GetMigrationBlueprint($sourceCloudServiceId: String!, $destinationCloudServiceId: String!) {
  getMigrationBlueprint(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId) {
    sourceCloudServiceId
    destinationCloudServiceId
    components {
      ...Component
    }
  }
}
    ${ComponentFragmentDoc}`;

    export function useGetMigrationBlueprintQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMigrationBlueprintQuery, GetMigrationBlueprintQueryVariables>) {
      return ApolloReactHooks.useQuery<GetMigrationBlueprintQuery, GetMigrationBlueprintQueryVariables>(GetMigrationBlueprintDocument, baseOptions);
    }
      export function useGetMigrationBlueprintLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMigrationBlueprintQuery, GetMigrationBlueprintQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetMigrationBlueprintQuery, GetMigrationBlueprintQueryVariables>(GetMigrationBlueprintDocument, baseOptions);
      }
      
export type GetMigrationBlueprintQueryHookResult = ReturnType<typeof useGetMigrationBlueprintQuery>;
export type GetMigrationBlueprintQueryResult = ApolloReactCommon.QueryResult<GetMigrationBlueprintQuery, GetMigrationBlueprintQueryVariables>;
export const GetRenderedBlueprintDocument = gql`
    query GetRenderedBlueprint($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $blueprintInputs: [BlueprintInputsEntryInput!]!) {
  getRenderedBlueprint(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, blueprintInputs: $blueprintInputs) {
    components {
      ...RenderedBlueprintComponent
    }
  }
}
    ${RenderedBlueprintComponentFragmentDoc}`;

    export function useGetRenderedBlueprintQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRenderedBlueprintQuery, GetRenderedBlueprintQueryVariables>) {
      return ApolloReactHooks.useQuery<GetRenderedBlueprintQuery, GetRenderedBlueprintQueryVariables>(GetRenderedBlueprintDocument, baseOptions);
    }
      export function useGetRenderedBlueprintLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRenderedBlueprintQuery, GetRenderedBlueprintQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetRenderedBlueprintQuery, GetRenderedBlueprintQueryVariables>(GetRenderedBlueprintDocument, baseOptions);
      }
      
export type GetRenderedBlueprintQueryHookResult = ReturnType<typeof useGetRenderedBlueprintQuery>;
export type GetRenderedBlueprintQueryResult = ApolloReactCommon.QueryResult<GetRenderedBlueprintQuery, GetRenderedBlueprintQueryVariables>;
export const GenerateJsonUsingChatGptDocument = gql`
    mutation GenerateJsonUsingChatGPT($model: String!, $prompt: String!, $temperature: Float!) {
  generateJsonUsingChatGPT(model: $model, prompt: $prompt, temperature: $temperature)
}
    `;
export type GenerateJsonUsingChatGptMutationFn = ApolloReactCommon.MutationFunction<GenerateJsonUsingChatGptMutation, GenerateJsonUsingChatGptMutationVariables>;

    export function useGenerateJsonUsingChatGptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateJsonUsingChatGptMutation, GenerateJsonUsingChatGptMutationVariables>) {
      return ApolloReactHooks.useMutation<GenerateJsonUsingChatGptMutation, GenerateJsonUsingChatGptMutationVariables>(GenerateJsonUsingChatGptDocument, baseOptions);
    }
export type GenerateJsonUsingChatGptMutationHookResult = ReturnType<typeof useGenerateJsonUsingChatGptMutation>;
export type GenerateJsonUsingChatGptMutationResult = ApolloReactCommon.MutationResult<GenerateJsonUsingChatGptMutation>;
export type GenerateJsonUsingChatGptMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateJsonUsingChatGptMutation, GenerateJsonUsingChatGptMutationVariables>;
export const MakeSchoolLookupPromptDocument = gql`
    mutation MakeSchoolLookupPrompt($domainName: String!) {
  makeSchoolLookupPrompt(domainName: $domainName)
}
    `;
export type MakeSchoolLookupPromptMutationFn = ApolloReactCommon.MutationFunction<MakeSchoolLookupPromptMutation, MakeSchoolLookupPromptMutationVariables>;

    export function useMakeSchoolLookupPromptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSchoolLookupPromptMutation, MakeSchoolLookupPromptMutationVariables>) {
      return ApolloReactHooks.useMutation<MakeSchoolLookupPromptMutation, MakeSchoolLookupPromptMutationVariables>(MakeSchoolLookupPromptDocument, baseOptions);
    }
export type MakeSchoolLookupPromptMutationHookResult = ReturnType<typeof useMakeSchoolLookupPromptMutation>;
export type MakeSchoolLookupPromptMutationResult = ApolloReactCommon.MutationResult<MakeSchoolLookupPromptMutation>;
export type MakeSchoolLookupPromptMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSchoolLookupPromptMutation, MakeSchoolLookupPromptMutationVariables>;
export const DeleteConfigurationDocument = gql`
    mutation DeleteConfiguration($id: Int!) {
  deleteConfiguration(id: $id)
}
    `;
export type DeleteConfigurationMutationFn = ApolloReactCommon.MutationFunction<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;

    export function useDeleteConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>(DeleteConfigurationDocument, baseOptions);
    }
export type DeleteConfigurationMutationHookResult = ReturnType<typeof useDeleteConfigurationMutation>;
export type DeleteConfigurationMutationResult = ApolloReactCommon.MutationResult<DeleteConfigurationMutation>;
export type DeleteConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>;
export const GetConfigurationDocument = gql`
    query GetConfiguration($id: Int!) {
  configuration(id: $id) {
    ...Configuration
  }
}
    ${ConfigurationFragmentDoc}`;

    export function useGetConfigurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
      return ApolloReactHooks.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, baseOptions);
    }
      export function useGetConfigurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, baseOptions);
      }
      
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationQueryResult = ApolloReactCommon.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const InsertConfigurationDocument = gql`
    mutation InsertConfiguration($organizationId: Int!, $formData: ConfigurationFormData!) {
  insertConfiguration(organizationId: $organizationId, formData: $formData) {
    ...Configuration
  }
}
    ${ConfigurationFragmentDoc}`;
export type InsertConfigurationMutationFn = ApolloReactCommon.MutationFunction<InsertConfigurationMutation, InsertConfigurationMutationVariables>;

    export function useInsertConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertConfigurationMutation, InsertConfigurationMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertConfigurationMutation, InsertConfigurationMutationVariables>(InsertConfigurationDocument, baseOptions);
    }
export type InsertConfigurationMutationHookResult = ReturnType<typeof useInsertConfigurationMutation>;
export type InsertConfigurationMutationResult = ApolloReactCommon.MutationResult<InsertConfigurationMutation>;
export type InsertConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertConfigurationMutation, InsertConfigurationMutationVariables>;
export const SearchConfigurationsDocument = gql`
    query SearchConfigurations($organizationId: Int!, $term: String!) {
  searchConfigurations(organizationId: $organizationId, term: $term) {
    ...Configuration
  }
}
    ${ConfigurationFragmentDoc}`;

    export function useSearchConfigurationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchConfigurationsQuery, SearchConfigurationsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchConfigurationsQuery, SearchConfigurationsQueryVariables>(SearchConfigurationsDocument, baseOptions);
    }
      export function useSearchConfigurationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchConfigurationsQuery, SearchConfigurationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchConfigurationsQuery, SearchConfigurationsQueryVariables>(SearchConfigurationsDocument, baseOptions);
      }
      
export type SearchConfigurationsQueryHookResult = ReturnType<typeof useSearchConfigurationsQuery>;
export type SearchConfigurationsQueryResult = ApolloReactCommon.QueryResult<SearchConfigurationsQuery, SearchConfigurationsQueryVariables>;
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($id: Int!, $formData: ConfigurationFormData!) {
  updateConfiguration(id: $id, formData: $formData) {
    ...Configuration
  }
}
    ${ConfigurationFragmentDoc}`;
export type UpdateConfigurationMutationFn = ApolloReactCommon.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

    export function useUpdateConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, baseOptions);
    }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = ApolloReactCommon.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const CheckAccessKeyDocument = gql`
    mutation CheckAccessKey($connectionId: String!, $roles: [String!]) {
  checkAccessKey(connectionId: $connectionId, roles: $roles) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;
export type CheckAccessKeyMutationFn = ApolloReactCommon.MutationFunction<CheckAccessKeyMutation, CheckAccessKeyMutationVariables>;

    export function useCheckAccessKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckAccessKeyMutation, CheckAccessKeyMutationVariables>) {
      return ApolloReactHooks.useMutation<CheckAccessKeyMutation, CheckAccessKeyMutationVariables>(CheckAccessKeyDocument, baseOptions);
    }
export type CheckAccessKeyMutationHookResult = ReturnType<typeof useCheckAccessKeyMutation>;
export type CheckAccessKeyMutationResult = ApolloReactCommon.MutationResult<CheckAccessKeyMutation>;
export type CheckAccessKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckAccessKeyMutation, CheckAccessKeyMutationVariables>;
export const ConnectDocument = gql`
    mutation Connect($contextType: SignInContextType!, $cloudServiceId: String!, $oneTimeAuthCode: String!, $timeZone: String, $newUserSettings: NewUserSettings, $ambassadorCode: String) {
  connect(contextType: $contextType, cloudServiceId: $cloudServiceId, oneTimeAuthCode: $oneTimeAuthCode, timeZone: $timeZone, newUserSettings: $newUserSettings, ambassadorCode: $ambassadorCode) {
    ...ConnectResult
  }
}
    ${ConnectResultFragmentDoc}`;
export type ConnectMutationFn = ApolloReactCommon.MutationFunction<ConnectMutation, ConnectMutationVariables>;

    export function useConnectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectMutation, ConnectMutationVariables>) {
      return ApolloReactHooks.useMutation<ConnectMutation, ConnectMutationVariables>(ConnectDocument, baseOptions);
    }
export type ConnectMutationHookResult = ReturnType<typeof useConnectMutation>;
export type ConnectMutationResult = ApolloReactCommon.MutationResult<ConnectMutation>;
export type ConnectMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectMutation, ConnectMutationVariables>;
export const GetConnectionDocument = gql`
    query GetConnection($userId: String!, $connectionId: String!) {
  connection: getConnection(userId: $userId, connectionId: $connectionId) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;

    export function useGetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConnectionQuery, GetConnectionQueryVariables>) {
      return ApolloReactHooks.useQuery<GetConnectionQuery, GetConnectionQueryVariables>(GetConnectionDocument, baseOptions);
    }
      export function useGetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConnectionQuery, GetConnectionQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetConnectionQuery, GetConnectionQueryVariables>(GetConnectionDocument, baseOptions);
      }
      
export type GetConnectionQueryHookResult = ReturnType<typeof useGetConnectionQuery>;
export type GetConnectionQueryResult = ApolloReactCommon.QueryResult<GetConnectionQuery, GetConnectionQueryVariables>;
export const GetConnectionsDocument = gql`
    query GetConnections($userId: String!, $connectionIds: [String!]!, $accessKeyCheck: AccessKeyCheck!) {
  getConnections(userId: $userId, connectionIds: $connectionIds, accessKeyCheck: $accessKeyCheck) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;

    export function useGetConnectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConnectionsQuery, GetConnectionsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetConnectionsQuery, GetConnectionsQueryVariables>(GetConnectionsDocument, baseOptions);
    }
      export function useGetConnectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConnectionsQuery, GetConnectionsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetConnectionsQuery, GetConnectionsQueryVariables>(GetConnectionsDocument, baseOptions);
      }
      
export type GetConnectionsQueryHookResult = ReturnType<typeof useGetConnectionsQuery>;
export type GetConnectionsQueryResult = ApolloReactCommon.QueryResult<GetConnectionsQuery, GetConnectionsQueryVariables>;
export const GetUserConnectionsDocument = gql`
    query GetUserConnections($userId: String!, $accessKeyCheck: AccessKeyCheck!) {
  connections: getConnections(userId: $userId, connectionIds: null, accessKeyCheck: $accessKeyCheck) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;

    export function useGetUserConnectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>(GetUserConnectionsDocument, baseOptions);
    }
      export function useGetUserConnectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>(GetUserConnectionsDocument, baseOptions);
      }
      
export type GetUserConnectionsQueryHookResult = ReturnType<typeof useGetUserConnectionsQuery>;
export type GetUserConnectionsQueryResult = ApolloReactCommon.QueryResult<GetUserConnectionsQuery, GetUserConnectionsQueryVariables>;
export const ResolveConnectionsDocument = gql`
    mutation ResolveConnections($sourceCloudServiceId: String!, $sourceEmailAddresses: [String!]!, $sourceAdminConnectionId: String, $destinationCloudServiceId: String!, $destinationEmailAddresses: [String!]!, $destinationAdminConnectionId: String) {
  resolveConnections(sourceCloudServiceId: $sourceCloudServiceId, sourceEmailAddresses: $sourceEmailAddresses, sourceAdminConnectionId: $sourceAdminConnectionId, destinationCloudServiceId: $destinationCloudServiceId, destinationEmailAddresses: $destinationEmailAddresses, destinationAdminConnectionId: $destinationAdminConnectionId) {
    source {
      resolved {
        ...Connection
      }
      notAccessible
      wrongCloudService {
        ...Connection
      }
      ambiguous
      missing
    }
    destination {
      resolved {
        ...Connection
      }
      notAccessible
      wrongCloudService {
        ...Connection
      }
      ambiguous
      missing
    }
  }
}
    ${ConnectionFragmentDoc}`;
export type ResolveConnectionsMutationFn = ApolloReactCommon.MutationFunction<ResolveConnectionsMutation, ResolveConnectionsMutationVariables>;

    export function useResolveConnectionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResolveConnectionsMutation, ResolveConnectionsMutationVariables>) {
      return ApolloReactHooks.useMutation<ResolveConnectionsMutation, ResolveConnectionsMutationVariables>(ResolveConnectionsDocument, baseOptions);
    }
export type ResolveConnectionsMutationHookResult = ReturnType<typeof useResolveConnectionsMutation>;
export type ResolveConnectionsMutationResult = ApolloReactCommon.MutationResult<ResolveConnectionsMutation>;
export type ResolveConnectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveConnectionsMutation, ResolveConnectionsMutationVariables>;
export const RevealAccessKeyDocument = gql`
    mutation RevealAccessKey($connectionId: String!, $roles: [String!]!) {
  revealAccessKey(connectionId: $connectionId, roles: $roles) {
    connection {
      ...Connection
    }
    revealedAccessKey {
      ...RevealedAccessKeyFragment
    }
  }
}
    ${ConnectionFragmentDoc}
${RevealedAccessKeyFragmentFragmentDoc}`;
export type RevealAccessKeyMutationFn = ApolloReactCommon.MutationFunction<RevealAccessKeyMutation, RevealAccessKeyMutationVariables>;

    export function useRevealAccessKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevealAccessKeyMutation, RevealAccessKeyMutationVariables>) {
      return ApolloReactHooks.useMutation<RevealAccessKeyMutation, RevealAccessKeyMutationVariables>(RevealAccessKeyDocument, baseOptions);
    }
export type RevealAccessKeyMutationHookResult = ReturnType<typeof useRevealAccessKeyMutation>;
export type RevealAccessKeyMutationResult = ApolloReactCommon.MutationResult<RevealAccessKeyMutation>;
export type RevealAccessKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<RevealAccessKeyMutation, RevealAccessKeyMutationVariables>;
export const RevokeAccessKeyDocument = gql`
    mutation RevokeAccessKey($connectionId: String!, $force: Boolean!) {
  revokeAccessKey(connectionId: $connectionId, force: $force) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;
export type RevokeAccessKeyMutationFn = ApolloReactCommon.MutationFunction<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>;

    export function useRevokeAccessKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>) {
      return ApolloReactHooks.useMutation<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>(RevokeAccessKeyDocument, baseOptions);
    }
export type RevokeAccessKeyMutationHookResult = ReturnType<typeof useRevokeAccessKeyMutation>;
export type RevokeAccessKeyMutationResult = ApolloReactCommon.MutationResult<RevokeAccessKeyMutation>;
export type RevokeAccessKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>;
export const RevokeAllAccessKeysDocument = gql`
    mutation RevokeAllAccessKeys {
  revokeAllAccessKeys {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;
export type RevokeAllAccessKeysMutationFn = ApolloReactCommon.MutationFunction<RevokeAllAccessKeysMutation, RevokeAllAccessKeysMutationVariables>;

    export function useRevokeAllAccessKeysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAllAccessKeysMutation, RevokeAllAccessKeysMutationVariables>) {
      return ApolloReactHooks.useMutation<RevokeAllAccessKeysMutation, RevokeAllAccessKeysMutationVariables>(RevokeAllAccessKeysDocument, baseOptions);
    }
export type RevokeAllAccessKeysMutationHookResult = ReturnType<typeof useRevokeAllAccessKeysMutation>;
export type RevokeAllAccessKeysMutationResult = ApolloReactCommon.MutationResult<RevokeAllAccessKeysMutation>;
export type RevokeAllAccessKeysMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeAllAccessKeysMutation, RevokeAllAccessKeysMutationVariables>;
export const DeleteCouponCodeDocument = gql`
    mutation DeleteCouponCode($id: String!) {
  deleteCouponCode(id: $id)
}
    `;
export type DeleteCouponCodeMutationFn = ApolloReactCommon.MutationFunction<DeleteCouponCodeMutation, DeleteCouponCodeMutationVariables>;

    export function useDeleteCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCouponCodeMutation, DeleteCouponCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteCouponCodeMutation, DeleteCouponCodeMutationVariables>(DeleteCouponCodeDocument, baseOptions);
    }
export type DeleteCouponCodeMutationHookResult = ReturnType<typeof useDeleteCouponCodeMutation>;
export type DeleteCouponCodeMutationResult = ApolloReactCommon.MutationResult<DeleteCouponCodeMutation>;
export type DeleteCouponCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCouponCodeMutation, DeleteCouponCodeMutationVariables>;
export const GetCouponCodeDocument = gql`
    query GetCouponCode($id: String!) {
  couponCode(id: $id) {
    ...CouponCode
  }
}
    ${CouponCodeFragmentDoc}`;

    export function useGetCouponCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCouponCodeQuery, GetCouponCodeQueryVariables>) {
      return ApolloReactHooks.useQuery<GetCouponCodeQuery, GetCouponCodeQueryVariables>(GetCouponCodeDocument, baseOptions);
    }
      export function useGetCouponCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCouponCodeQuery, GetCouponCodeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetCouponCodeQuery, GetCouponCodeQueryVariables>(GetCouponCodeDocument, baseOptions);
      }
      
export type GetCouponCodeQueryHookResult = ReturnType<typeof useGetCouponCodeQuery>;
export type GetCouponCodeQueryResult = ApolloReactCommon.QueryResult<GetCouponCodeQuery, GetCouponCodeQueryVariables>;
export const InsertCouponCodeDocument = gql`
    mutation InsertCouponCode($formData: CouponCodeFormData!) {
  insertCouponCode(formData: $formData) {
    ...CouponCode
  }
}
    ${CouponCodeFragmentDoc}`;
export type InsertCouponCodeMutationFn = ApolloReactCommon.MutationFunction<InsertCouponCodeMutation, InsertCouponCodeMutationVariables>;

    export function useInsertCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertCouponCodeMutation, InsertCouponCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertCouponCodeMutation, InsertCouponCodeMutationVariables>(InsertCouponCodeDocument, baseOptions);
    }
export type InsertCouponCodeMutationHookResult = ReturnType<typeof useInsertCouponCodeMutation>;
export type InsertCouponCodeMutationResult = ApolloReactCommon.MutationResult<InsertCouponCodeMutation>;
export type InsertCouponCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertCouponCodeMutation, InsertCouponCodeMutationVariables>;
export const SearchCouponCodesDocument = gql`
    query SearchCouponCodes($term: String!) {
  searchCouponCodes(term: $term) {
    ...CouponCode
  }
}
    ${CouponCodeFragmentDoc}`;

    export function useSearchCouponCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchCouponCodesQuery, SearchCouponCodesQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchCouponCodesQuery, SearchCouponCodesQueryVariables>(SearchCouponCodesDocument, baseOptions);
    }
      export function useSearchCouponCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchCouponCodesQuery, SearchCouponCodesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchCouponCodesQuery, SearchCouponCodesQueryVariables>(SearchCouponCodesDocument, baseOptions);
      }
      
export type SearchCouponCodesQueryHookResult = ReturnType<typeof useSearchCouponCodesQuery>;
export type SearchCouponCodesQueryResult = ApolloReactCommon.QueryResult<SearchCouponCodesQuery, SearchCouponCodesQueryVariables>;
export const SendCouponCodesDocument = gql`
    mutation SendCouponCodes($emailAddresses: String!, $discount: BigDecimal!, $note: String!) {
  sendCouponCodes(emailAddresses: $emailAddresses, discount: $discount, note: $note) {
    emailAddress
    result
  }
}
    `;
export type SendCouponCodesMutationFn = ApolloReactCommon.MutationFunction<SendCouponCodesMutation, SendCouponCodesMutationVariables>;

    export function useSendCouponCodesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendCouponCodesMutation, SendCouponCodesMutationVariables>) {
      return ApolloReactHooks.useMutation<SendCouponCodesMutation, SendCouponCodesMutationVariables>(SendCouponCodesDocument, baseOptions);
    }
export type SendCouponCodesMutationHookResult = ReturnType<typeof useSendCouponCodesMutation>;
export type SendCouponCodesMutationResult = ApolloReactCommon.MutationResult<SendCouponCodesMutation>;
export type SendCouponCodesMutationOptions = ApolloReactCommon.BaseMutationOptions<SendCouponCodesMutation, SendCouponCodesMutationVariables>;
export const UpdateCouponCodeDocument = gql`
    mutation UpdateCouponCode($formData: CouponCodeFormData!) {
  updateCouponCode(formData: $formData) {
    ...CouponCode
  }
}
    ${CouponCodeFragmentDoc}`;
export type UpdateCouponCodeMutationFn = ApolloReactCommon.MutationFunction<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>;

    export function useUpdateCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>(UpdateCouponCodeDocument, baseOptions);
    }
export type UpdateCouponCodeMutationHookResult = ReturnType<typeof useUpdateCouponCodeMutation>;
export type UpdateCouponCodeMutationResult = ApolloReactCommon.MutationResult<UpdateCouponCodeMutation>;
export type UpdateCouponCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>;
export const FactsUpdatedDocument = gql`
    subscription FactsUpdated($factIds: [String!]!) {
  factsUpdated(factIds: $factIds) {
    eventType
    facts {
      ...Fact
    }
  }
}
    ${FactFragmentDoc}`;

    export function useFactsUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<FactsUpdatedSubscription, FactsUpdatedSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<FactsUpdatedSubscription, FactsUpdatedSubscriptionVariables>(FactsUpdatedDocument, baseOptions);
    }
export type FactsUpdatedSubscriptionHookResult = ReturnType<typeof useFactsUpdatedSubscription>;
export type FactsUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<FactsUpdatedSubscription>;
export const GetBlueprintFactsDocument = gql`
    query GetBlueprintFacts($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $blueprintFactSpecs: [BlueprintFactsSpec!]!) {
  getBlueprintFacts(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, blueprintFactSpecs: $blueprintFactSpecs) {
    facts {
      ...FactOrPlaceholder
    }
  }
}
    ${FactOrPlaceholderFragmentDoc}`;

    export function useGetBlueprintFactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlueprintFactsQuery, GetBlueprintFactsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetBlueprintFactsQuery, GetBlueprintFactsQueryVariables>(GetBlueprintFactsDocument, baseOptions);
    }
      export function useGetBlueprintFactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlueprintFactsQuery, GetBlueprintFactsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetBlueprintFactsQuery, GetBlueprintFactsQueryVariables>(GetBlueprintFactsDocument, baseOptions);
      }
      
export type GetBlueprintFactsQueryHookResult = ReturnType<typeof useGetBlueprintFactsQuery>;
export type GetBlueprintFactsQueryResult = ApolloReactCommon.QueryResult<GetBlueprintFactsQuery, GetBlueprintFactsQueryVariables>;
export const GetFactsDocument = gql`
    query GetFacts($factIds: [String!]!) {
  getFacts(factIds: $factIds) {
    ...FactOrPlaceholder
  }
}
    ${FactOrPlaceholderFragmentDoc}`;

    export function useGetFactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFactsQuery, GetFactsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetFactsQuery, GetFactsQueryVariables>(GetFactsDocument, baseOptions);
    }
      export function useGetFactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFactsQuery, GetFactsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetFactsQuery, GetFactsQueryVariables>(GetFactsDocument, baseOptions);
      }
      
export type GetFactsQueryHookResult = ReturnType<typeof useGetFactsQuery>;
export type GetFactsQueryResult = ApolloReactCommon.QueryResult<GetFactsQuery, GetFactsQueryVariables>;
export const InvalidateFactDocument = gql`
    mutation InvalidateFact($subjectType: FactSubjectType!, $subjectId: String!, $factId: String!, $family: String!, $valueType: String!) {
  invalidateFact(subjectType: $subjectType, subjectId: $subjectId, factId: $factId, family: $family, valueType: $valueType) {
    ...Fact
  }
}
    ${FactFragmentDoc}`;
export type InvalidateFactMutationFn = ApolloReactCommon.MutationFunction<InvalidateFactMutation, InvalidateFactMutationVariables>;

    export function useInvalidateFactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvalidateFactMutation, InvalidateFactMutationVariables>) {
      return ApolloReactHooks.useMutation<InvalidateFactMutation, InvalidateFactMutationVariables>(InvalidateFactDocument, baseOptions);
    }
export type InvalidateFactMutationHookResult = ReturnType<typeof useInvalidateFactMutation>;
export type InvalidateFactMutationResult = ApolloReactCommon.MutationResult<InvalidateFactMutation>;
export type InvalidateFactMutationOptions = ApolloReactCommon.BaseMutationOptions<InvalidateFactMutation, InvalidateFactMutationVariables>;
export const PutFactDocument = gql`
    mutation PutFact($subjectType: FactSubjectType!, $subjectId: String!, $factId: String!, $family: String!, $valueType: String!, $valueJson: String!) {
  putFact(subjectType: $subjectType, subjectId: $subjectId, factId: $factId, family: $family, valueType: $valueType, valueJson: $valueJson) {
    ...Fact
  }
}
    ${FactFragmentDoc}`;
export type PutFactMutationFn = ApolloReactCommon.MutationFunction<PutFactMutation, PutFactMutationVariables>;

    export function usePutFactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PutFactMutation, PutFactMutationVariables>) {
      return ApolloReactHooks.useMutation<PutFactMutation, PutFactMutationVariables>(PutFactDocument, baseOptions);
    }
export type PutFactMutationHookResult = ReturnType<typeof usePutFactMutation>;
export type PutFactMutationResult = ApolloReactCommon.MutationResult<PutFactMutation>;
export type PutFactMutationOptions = ApolloReactCommon.BaseMutationOptions<PutFactMutation, PutFactMutationVariables>;
export const RefreshBlueprintFactsDocument = gql`
    mutation RefreshBlueprintFacts($factIds: [String!]!, $sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $blueprintInputs: [BlueprintInputsEntryInput!]!) {
  refreshBlueprintFacts(factIds: $factIds, sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, blueprintInputs: $blueprintInputs) {
    facts {
      ...FactOrPlaceholder
    }
    scanJobIds
  }
}
    ${FactOrPlaceholderFragmentDoc}`;
export type RefreshBlueprintFactsMutationFn = ApolloReactCommon.MutationFunction<RefreshBlueprintFactsMutation, RefreshBlueprintFactsMutationVariables>;

    export function useRefreshBlueprintFactsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshBlueprintFactsMutation, RefreshBlueprintFactsMutationVariables>) {
      return ApolloReactHooks.useMutation<RefreshBlueprintFactsMutation, RefreshBlueprintFactsMutationVariables>(RefreshBlueprintFactsDocument, baseOptions);
    }
export type RefreshBlueprintFactsMutationHookResult = ReturnType<typeof useRefreshBlueprintFactsMutation>;
export type RefreshBlueprintFactsMutationResult = ApolloReactCommon.MutationResult<RefreshBlueprintFactsMutation>;
export type RefreshBlueprintFactsMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshBlueprintFactsMutation, RefreshBlueprintFactsMutationVariables>;
export const ScansDocument = gql`
    query Scans($connectionIds: [String!]!, $includeCompleted: Boolean!, $limit: Int) {
  scans(connectionIds: $connectionIds, includeCompleted: $includeCompleted, limit: $limit) {
    id
    userId
    connectionId
    sourceCloudServiceId
    destinationCloudServiceId
    blueprintInputs {
      key
      value
    }
    status
    error
    createdAt
    completedAt
    jobId
    workflowUrl
  }
}
    `;

    export function useScansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScansQuery, ScansQueryVariables>) {
      return ApolloReactHooks.useQuery<ScansQuery, ScansQueryVariables>(ScansDocument, baseOptions);
    }
      export function useScansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScansQuery, ScansQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ScansQuery, ScansQueryVariables>(ScansDocument, baseOptions);
      }
      
export type ScansQueryHookResult = ReturnType<typeof useScansQuery>;
export type ScansQueryResult = ApolloReactCommon.QueryResult<ScansQuery, ScansQueryVariables>;
export const AbortJobDocument = gql`
    mutation AbortJob($jobId: String!) {
  abortJob(jobId: $jobId)
}
    `;
export type AbortJobMutationFn = ApolloReactCommon.MutationFunction<AbortJobMutation, AbortJobMutationVariables>;

    export function useAbortJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AbortJobMutation, AbortJobMutationVariables>) {
      return ApolloReactHooks.useMutation<AbortJobMutation, AbortJobMutationVariables>(AbortJobDocument, baseOptions);
    }
export type AbortJobMutationHookResult = ReturnType<typeof useAbortJobMutation>;
export type AbortJobMutationResult = ApolloReactCommon.MutationResult<AbortJobMutation>;
export type AbortJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AbortJobMutation, AbortJobMutationVariables>;
export const GetAvailableJobStatusesDocument = gql`
    query GetAvailableJobStatuses($jobId: String!) {
  getAvailableJobStatuses(jobId: $jobId) {
    allSupported {
      status
      description
    }
    proposed {
      status
      description
    }
  }
}
    `;

    export function useGetAvailableJobStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvailableJobStatusesQuery, GetAvailableJobStatusesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAvailableJobStatusesQuery, GetAvailableJobStatusesQueryVariables>(GetAvailableJobStatusesDocument, baseOptions);
    }
      export function useGetAvailableJobStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableJobStatusesQuery, GetAvailableJobStatusesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAvailableJobStatusesQuery, GetAvailableJobStatusesQueryVariables>(GetAvailableJobStatusesDocument, baseOptions);
      }
      
export type GetAvailableJobStatusesQueryHookResult = ReturnType<typeof useGetAvailableJobStatusesQuery>;
export type GetAvailableJobStatusesQueryResult = ApolloReactCommon.QueryResult<GetAvailableJobStatusesQuery, GetAvailableJobStatusesQueryVariables>;
export const GetJobHistoryDocument = gql`
    query GetJobHistory($jobId: String!, $includeMinorUpdates: Boolean!) {
  getJobHistory(jobId: $jobId, includeMinorUpdates: $includeMinorUpdates) {
    jobId
    job {
      ...JobHistoryRecordFragment
    }
    jobHistory {
      ...JobHistoryRecordFragment
    }
    tasks {
      jobId
      taskId
      transition
      type
      internalStatus
      resultType
      createdAt
      completedAt
      archivedAt
    }
    taskIssues {
      ...TaskIssueFragment
    }
  }
}
    ${JobHistoryRecordFragmentFragmentDoc}
${TaskIssueFragmentFragmentDoc}`;

    export function useGetJobHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJobHistoryQuery, GetJobHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetJobHistoryQuery, GetJobHistoryQueryVariables>(GetJobHistoryDocument, baseOptions);
    }
      export function useGetJobHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJobHistoryQuery, GetJobHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetJobHistoryQuery, GetJobHistoryQueryVariables>(GetJobHistoryDocument, baseOptions);
      }
      
export type GetJobHistoryQueryHookResult = ReturnType<typeof useGetJobHistoryQuery>;
export type GetJobHistoryQueryResult = ApolloReactCommon.QueryResult<GetJobHistoryQuery, GetJobHistoryQueryVariables>;
export const GetTaskHistoryDocument = gql`
    query GetTaskHistory($jobId: String!, $taskId: String!, $includeMinorUpdates: Boolean!) {
  getTaskHistory(jobId: $jobId, taskId: $taskId, includeMinorUpdates: $includeMinorUpdates) {
    jobId
    taskId
    task {
      ...TaskHistoryRecordFragment
    }
    taskHistory {
      ...TaskHistoryRecordFragment
    }
    taskIssues {
      ...TaskIssueFragment
    }
  }
}
    ${TaskHistoryRecordFragmentFragmentDoc}
${TaskIssueFragmentFragmentDoc}`;

    export function useGetTaskHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>(GetTaskHistoryDocument, baseOptions);
    }
      export function useGetTaskHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>(GetTaskHistoryDocument, baseOptions);
      }
      
export type GetTaskHistoryQueryHookResult = ReturnType<typeof useGetTaskHistoryQuery>;
export type GetTaskHistoryQueryResult = ApolloReactCommon.QueryResult<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>;
export const GetTaskIssueHistoryDocument = gql`
    query GetTaskIssueHistory($jobId: String!, $taskId: String!, $issueId: String!) {
  getTaskIssueHistory(jobId: $jobId, taskId: $taskId, issueId: $issueId) {
    ...TaskIssueFragment
  }
}
    ${TaskIssueFragmentFragmentDoc}`;

    export function useGetTaskIssueHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskIssueHistoryQuery, GetTaskIssueHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTaskIssueHistoryQuery, GetTaskIssueHistoryQueryVariables>(GetTaskIssueHistoryDocument, baseOptions);
    }
      export function useGetTaskIssueHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskIssueHistoryQuery, GetTaskIssueHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetTaskIssueHistoryQuery, GetTaskIssueHistoryQueryVariables>(GetTaskIssueHistoryDocument, baseOptions);
      }
      
export type GetTaskIssueHistoryQueryHookResult = ReturnType<typeof useGetTaskIssueHistoryQuery>;
export type GetTaskIssueHistoryQueryResult = ApolloReactCommon.QueryResult<GetTaskIssueHistoryQuery, GetTaskIssueHistoryQueryVariables>;
export const ScheduleJobStatusTransitionDocument = gql`
    mutation ScheduleJobStatusTransition($jobId: String!, $desiredStatus: String!, $force: Boolean!) {
  scheduleJobStatusTransition(jobId: $jobId, desiredStatus: $desiredStatus, force: $force)
}
    `;
export type ScheduleJobStatusTransitionMutationFn = ApolloReactCommon.MutationFunction<ScheduleJobStatusTransitionMutation, ScheduleJobStatusTransitionMutationVariables>;

    export function useScheduleJobStatusTransitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleJobStatusTransitionMutation, ScheduleJobStatusTransitionMutationVariables>) {
      return ApolloReactHooks.useMutation<ScheduleJobStatusTransitionMutation, ScheduleJobStatusTransitionMutationVariables>(ScheduleJobStatusTransitionDocument, baseOptions);
    }
export type ScheduleJobStatusTransitionMutationHookResult = ReturnType<typeof useScheduleJobStatusTransitionMutation>;
export type ScheduleJobStatusTransitionMutationResult = ApolloReactCommon.MutationResult<ScheduleJobStatusTransitionMutation>;
export type ScheduleJobStatusTransitionMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleJobStatusTransitionMutation, ScheduleJobStatusTransitionMutationVariables>;
export const AddMigrationNoteDocument = gql`
    mutation AddMigrationNote($migrationId: String!, $type: MigrationNoteType!, $message: String!) {
  addMigrationNote(migrationId: $migrationId, type: $type, message: $message) {
    ...MigrationNote
  }
}
    ${MigrationNoteFragmentDoc}`;
export type AddMigrationNoteMutationFn = ApolloReactCommon.MutationFunction<AddMigrationNoteMutation, AddMigrationNoteMutationVariables>;

    export function useAddMigrationNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMigrationNoteMutation, AddMigrationNoteMutationVariables>) {
      return ApolloReactHooks.useMutation<AddMigrationNoteMutation, AddMigrationNoteMutationVariables>(AddMigrationNoteDocument, baseOptions);
    }
export type AddMigrationNoteMutationHookResult = ReturnType<typeof useAddMigrationNoteMutation>;
export type AddMigrationNoteMutationResult = ApolloReactCommon.MutationResult<AddMigrationNoteMutation>;
export type AddMigrationNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMigrationNoteMutation, AddMigrationNoteMutationVariables>;
export const MigrationNoteListEventsDocument = gql`
    subscription MigrationNoteListEvents($migrationIds: [String!]!) {
  subscribeForMigrationNoteListEvents(migrationIds: $migrationIds) {
    eventType
    migrationNotes {
      ...MigrationNote
    }
  }
}
    ${MigrationNoteFragmentDoc}`;

    export function useMigrationNoteListEventsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MigrationNoteListEventsSubscription, MigrationNoteListEventsSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<MigrationNoteListEventsSubscription, MigrationNoteListEventsSubscriptionVariables>(MigrationNoteListEventsDocument, baseOptions);
    }
export type MigrationNoteListEventsSubscriptionHookResult = ReturnType<typeof useMigrationNoteListEventsSubscription>;
export type MigrationNoteListEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<MigrationNoteListEventsSubscription>;
export const MigrationNotesDocument = gql`
    query MigrationNotes($migrationId: String!) {
  migration(migrationId: $migrationId) {
    id
    notes {
      ...MigrationNoteList
    }
  }
}
    ${MigrationNoteListFragmentDoc}`;

    export function useMigrationNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MigrationNotesQuery, MigrationNotesQueryVariables>) {
      return ApolloReactHooks.useQuery<MigrationNotesQuery, MigrationNotesQueryVariables>(MigrationNotesDocument, baseOptions);
    }
      export function useMigrationNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MigrationNotesQuery, MigrationNotesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MigrationNotesQuery, MigrationNotesQueryVariables>(MigrationNotesDocument, baseOptions);
      }
      
export type MigrationNotesQueryHookResult = ReturnType<typeof useMigrationNotesQuery>;
export type MigrationNotesQueryResult = ApolloReactCommon.QueryResult<MigrationNotesQuery, MigrationNotesQueryVariables>;
export const ReopenMigrationNoteDocument = gql`
    mutation ReopenMigrationNote($noteId: Int!) {
  reopenMigrationNote(noteId: $noteId) {
    ...MigrationNote
  }
}
    ${MigrationNoteFragmentDoc}`;
export type ReopenMigrationNoteMutationFn = ApolloReactCommon.MutationFunction<ReopenMigrationNoteMutation, ReopenMigrationNoteMutationVariables>;

    export function useReopenMigrationNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReopenMigrationNoteMutation, ReopenMigrationNoteMutationVariables>) {
      return ApolloReactHooks.useMutation<ReopenMigrationNoteMutation, ReopenMigrationNoteMutationVariables>(ReopenMigrationNoteDocument, baseOptions);
    }
export type ReopenMigrationNoteMutationHookResult = ReturnType<typeof useReopenMigrationNoteMutation>;
export type ReopenMigrationNoteMutationResult = ApolloReactCommon.MutationResult<ReopenMigrationNoteMutation>;
export type ReopenMigrationNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReopenMigrationNoteMutation, ReopenMigrationNoteMutationVariables>;
export const ResolveMigrationNoteDocument = gql`
    mutation ResolveMigrationNote($noteId: Int!) {
  resolveMigrationNote(noteId: $noteId) {
    ...MigrationNote
  }
}
    ${MigrationNoteFragmentDoc}`;
export type ResolveMigrationNoteMutationFn = ApolloReactCommon.MutationFunction<ResolveMigrationNoteMutation, ResolveMigrationNoteMutationVariables>;

    export function useResolveMigrationNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResolveMigrationNoteMutation, ResolveMigrationNoteMutationVariables>) {
      return ApolloReactHooks.useMutation<ResolveMigrationNoteMutation, ResolveMigrationNoteMutationVariables>(ResolveMigrationNoteDocument, baseOptions);
    }
export type ResolveMigrationNoteMutationHookResult = ReturnType<typeof useResolveMigrationNoteMutation>;
export type ResolveMigrationNoteMutationResult = ApolloReactCommon.MutationResult<ResolveMigrationNoteMutation>;
export type ResolveMigrationNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveMigrationNoteMutation, ResolveMigrationNoteMutationVariables>;
export const UpdateMigrationNoteDocument = gql`
    mutation UpdateMigrationNote($noteId: Int!, $type: MigrationNoteType!, $message: String!) {
  updateMigrationNote(noteId: $noteId, type: $type, message: $message) {
    ...MigrationNote
  }
}
    ${MigrationNoteFragmentDoc}`;
export type UpdateMigrationNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateMigrationNoteMutation, UpdateMigrationNoteMutationVariables>;

    export function useUpdateMigrationNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMigrationNoteMutation, UpdateMigrationNoteMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateMigrationNoteMutation, UpdateMigrationNoteMutationVariables>(UpdateMigrationNoteDocument, baseOptions);
    }
export type UpdateMigrationNoteMutationHookResult = ReturnType<typeof useUpdateMigrationNoteMutation>;
export type UpdateMigrationNoteMutationResult = ApolloReactCommon.MutationResult<UpdateMigrationNoteMutation>;
export type UpdateMigrationNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMigrationNoteMutation, UpdateMigrationNoteMutationVariables>;
export const AbortMigrationDocument = gql`
    mutation AbortMigration($migrationId: String!, $authorizationAction: AuthorizationAction!) {
  abortMigration(migrationId: $migrationId, authorizationAction: $authorizationAction) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type AbortMigrationMutationFn = ApolloReactCommon.MutationFunction<AbortMigrationMutation, AbortMigrationMutationVariables>;

    export function useAbortMigrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AbortMigrationMutation, AbortMigrationMutationVariables>) {
      return ApolloReactHooks.useMutation<AbortMigrationMutation, AbortMigrationMutationVariables>(AbortMigrationDocument, baseOptions);
    }
export type AbortMigrationMutationHookResult = ReturnType<typeof useAbortMigrationMutation>;
export type AbortMigrationMutationResult = ApolloReactCommon.MutationResult<AbortMigrationMutation>;
export type AbortMigrationMutationOptions = ApolloReactCommon.BaseMutationOptions<AbortMigrationMutation, AbortMigrationMutationVariables>;
export const FindOrganizationMigrationsDocument = gql`
    mutation FindOrganizationMigrations($organizationId: Int!, $searchCriteria: String!) {
  findOrganizationMigrations(organizationId: $organizationId, searchCriteria: $searchCriteria) {
    ...Migration
    ...MigrationConnections
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}`;
export type FindOrganizationMigrationsMutationFn = ApolloReactCommon.MutationFunction<FindOrganizationMigrationsMutation, FindOrganizationMigrationsMutationVariables>;

    export function useFindOrganizationMigrationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FindOrganizationMigrationsMutation, FindOrganizationMigrationsMutationVariables>) {
      return ApolloReactHooks.useMutation<FindOrganizationMigrationsMutation, FindOrganizationMigrationsMutationVariables>(FindOrganizationMigrationsDocument, baseOptions);
    }
export type FindOrganizationMigrationsMutationHookResult = ReturnType<typeof useFindOrganizationMigrationsMutation>;
export type FindOrganizationMigrationsMutationResult = ApolloReactCommon.MutationResult<FindOrganizationMigrationsMutation>;
export type FindOrganizationMigrationsMutationOptions = ApolloReactCommon.BaseMutationOptions<FindOrganizationMigrationsMutation, FindOrganizationMigrationsMutationVariables>;
export const FreshMigrationIssueListsDocument = gql`
    query FreshMigrationIssueLists($migrationIds: [String!]!) {
  migrations(migrationIds: $migrationIds) {
    issues {
      ...MigrationIssueList
    }
  }
}
    ${MigrationIssueListFragmentDoc}`;

    export function useFreshMigrationIssueListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FreshMigrationIssueListsQuery, FreshMigrationIssueListsQueryVariables>) {
      return ApolloReactHooks.useQuery<FreshMigrationIssueListsQuery, FreshMigrationIssueListsQueryVariables>(FreshMigrationIssueListsDocument, baseOptions);
    }
      export function useFreshMigrationIssueListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FreshMigrationIssueListsQuery, FreshMigrationIssueListsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<FreshMigrationIssueListsQuery, FreshMigrationIssueListsQueryVariables>(FreshMigrationIssueListsDocument, baseOptions);
      }
      
export type FreshMigrationIssueListsQueryHookResult = ReturnType<typeof useFreshMigrationIssueListsQuery>;
export type FreshMigrationIssueListsQueryResult = ApolloReactCommon.QueryResult<FreshMigrationIssueListsQuery, FreshMigrationIssueListsQueryVariables>;
export const FreshMigrationsDocument = gql`
    query FreshMigrations($migrationIds: [String!]!) {
  migrations(migrationIds: $migrationIds) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;

    export function useFreshMigrationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FreshMigrationsQuery, FreshMigrationsQueryVariables>) {
      return ApolloReactHooks.useQuery<FreshMigrationsQuery, FreshMigrationsQueryVariables>(FreshMigrationsDocument, baseOptions);
    }
      export function useFreshMigrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FreshMigrationsQuery, FreshMigrationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<FreshMigrationsQuery, FreshMigrationsQueryVariables>(FreshMigrationsDocument, baseOptions);
      }
      
export type FreshMigrationsQueryHookResult = ReturnType<typeof useFreshMigrationsQuery>;
export type FreshMigrationsQueryResult = ApolloReactCommon.QueryResult<FreshMigrationsQuery, FreshMigrationsQueryVariables>;
export const GetAuthorizationStatusDocument = gql`
    query GetAuthorizationStatus($migrationId: String!) {
  getAuthorizationStatus(migrationId: $migrationId) {
    isPending
    transactionStatus
  }
}
    `;

    export function useGetAuthorizationStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuthorizationStatusQuery, GetAuthorizationStatusQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAuthorizationStatusQuery, GetAuthorizationStatusQueryVariables>(GetAuthorizationStatusDocument, baseOptions);
    }
      export function useGetAuthorizationStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuthorizationStatusQuery, GetAuthorizationStatusQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAuthorizationStatusQuery, GetAuthorizationStatusQueryVariables>(GetAuthorizationStatusDocument, baseOptions);
      }
      
export type GetAuthorizationStatusQueryHookResult = ReturnType<typeof useGetAuthorizationStatusQuery>;
export type GetAuthorizationStatusQueryResult = ApolloReactCommon.QueryResult<GetAuthorizationStatusQuery, GetAuthorizationStatusQueryVariables>;
export const GetOrganizationMigrationStatsDocument = gql`
    query GetOrganizationMigrationStats($organizationId: Int!, $timeUnit: ActivityTimeUnit!, $timeUnitCount: Int!, $recentMigrationDayCount: Int!, $timeZoneOffset: Int!) {
  organizationProgramsPerformance(organizationId: $organizationId, timeUnit: $timeUnit, timeUnitCount: $timeUnitCount, timeZoneOffset: $timeZoneOffset) {
    programName
    dataPoints {
      timestamp
      migrationCount
    }
  }
  organizationMigrationStats(organizationId: $organizationId, recentMigrationDayCount: $recentMigrationDayCount, timeZoneOffset: $timeZoneOffset) {
    totalMigrationCount
    recentMigrationCount
  }
}
    `;

    export function useGetOrganizationMigrationStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationMigrationStatsQuery, GetOrganizationMigrationStatsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrganizationMigrationStatsQuery, GetOrganizationMigrationStatsQueryVariables>(GetOrganizationMigrationStatsDocument, baseOptions);
    }
      export function useGetOrganizationMigrationStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrganizationMigrationStatsQuery, GetOrganizationMigrationStatsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrganizationMigrationStatsQuery, GetOrganizationMigrationStatsQueryVariables>(GetOrganizationMigrationStatsDocument, baseOptions);
      }
      
export type GetOrganizationMigrationStatsQueryHookResult = ReturnType<typeof useGetOrganizationMigrationStatsQuery>;
export type GetOrganizationMigrationStatsQueryResult = ApolloReactCommon.QueryResult<GetOrganizationMigrationStatsQuery, GetOrganizationMigrationStatsQueryVariables>;
export const IterationProgressDocument = gql`
    query IterationProgress($migrationId: String!, $iterationIndex: Int!) {
  iteration(migrationId: $migrationId, iterationIndex: $iterationIndex) {
    ...Iteration
    facts {
      ...FactOrPlaceholder
    }
  }
}
    ${IterationFragmentDoc}
${FactOrPlaceholderFragmentDoc}`;

    export function useIterationProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IterationProgressQuery, IterationProgressQueryVariables>) {
      return ApolloReactHooks.useQuery<IterationProgressQuery, IterationProgressQueryVariables>(IterationProgressDocument, baseOptions);
    }
      export function useIterationProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IterationProgressQuery, IterationProgressQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<IterationProgressQuery, IterationProgressQueryVariables>(IterationProgressDocument, baseOptions);
      }
      
export type IterationProgressQueryHookResult = ReturnType<typeof useIterationProgressQuery>;
export type IterationProgressQueryResult = ApolloReactCommon.QueryResult<IterationProgressQuery, IterationProgressQueryVariables>;
export const LaunchIterationDocument = gql`
    mutation LaunchIteration($migrationId: String!) {
  launchIteration(migrationId: $migrationId)
}
    `;
export type LaunchIterationMutationFn = ApolloReactCommon.MutationFunction<LaunchIterationMutation, LaunchIterationMutationVariables>;

    export function useLaunchIterationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LaunchIterationMutation, LaunchIterationMutationVariables>) {
      return ApolloReactHooks.useMutation<LaunchIterationMutation, LaunchIterationMutationVariables>(LaunchIterationDocument, baseOptions);
    }
export type LaunchIterationMutationHookResult = ReturnType<typeof useLaunchIterationMutation>;
export type LaunchIterationMutationResult = ApolloReactCommon.MutationResult<LaunchIterationMutation>;
export type LaunchIterationMutationOptions = ApolloReactCommon.BaseMutationOptions<LaunchIterationMutation, LaunchIterationMutationVariables>;
export const LaunchMigrationDocument = gql`
    mutation LaunchMigration($orderId: String!, $expectedAmountToBePaid: BigDecimal!, $discounts: Discounts, $braintreePayloadJson: String, $deviceData: String, $externalPayment: ExternalPayment) {
  launchMigration(orderId: $orderId, expectedAmountToBePaid: $expectedAmountToBePaid, discounts: $discounts, braintreePayloadJson: $braintreePayloadJson, deviceData: $deviceData, externalPayment: $externalPayment) {
    migration {
      ...Migration
    }
    user {
      ...User
    }
  }
}
    ${MigrationFragmentDoc}
${UserFragmentDoc}`;
export type LaunchMigrationMutationFn = ApolloReactCommon.MutationFunction<LaunchMigrationMutation, LaunchMigrationMutationVariables>;

    export function useLaunchMigrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LaunchMigrationMutation, LaunchMigrationMutationVariables>) {
      return ApolloReactHooks.useMutation<LaunchMigrationMutation, LaunchMigrationMutationVariables>(LaunchMigrationDocument, baseOptions);
    }
export type LaunchMigrationMutationHookResult = ReturnType<typeof useLaunchMigrationMutation>;
export type LaunchMigrationMutationResult = ApolloReactCommon.MutationResult<LaunchMigrationMutation>;
export type LaunchMigrationMutationOptions = ApolloReactCommon.BaseMutationOptions<LaunchMigrationMutation, LaunchMigrationMutationVariables>;
export const MigrationAdminSidebarDataDocument = gql`
    query MigrationAdminSidebarData($migrationId: String!) {
  migration(migrationId: $migrationId) {
    ...Migration
    ...MigrationConnections
    order {
      ...Order
      ...OrderRevenue
    }
    notes {
      ...MigrationNoteList
    }
    activeJobIssues {
      issueId
      summary
      createdAt
    }
    issues {
      ...MigrationIssueList
    }
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}
${OrderFragmentDoc}
${OrderRevenueFragmentDoc}
${MigrationNoteListFragmentDoc}
${MigrationIssueListFragmentDoc}`;

    export function useMigrationAdminSidebarDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MigrationAdminSidebarDataQuery, MigrationAdminSidebarDataQueryVariables>) {
      return ApolloReactHooks.useQuery<MigrationAdminSidebarDataQuery, MigrationAdminSidebarDataQueryVariables>(MigrationAdminSidebarDataDocument, baseOptions);
    }
      export function useMigrationAdminSidebarDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MigrationAdminSidebarDataQuery, MigrationAdminSidebarDataQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MigrationAdminSidebarDataQuery, MigrationAdminSidebarDataQueryVariables>(MigrationAdminSidebarDataDocument, baseOptions);
      }
      
export type MigrationAdminSidebarDataQueryHookResult = ReturnType<typeof useMigrationAdminSidebarDataQuery>;
export type MigrationAdminSidebarDataQueryResult = ApolloReactCommon.QueryResult<MigrationAdminSidebarDataQuery, MigrationAdminSidebarDataQueryVariables>;
export const MigrationEventsDocument = gql`
    subscription MigrationEvents($migrationIds: [String!]!) {
  subscribeForMigrationEvents(migrationIds: $migrationIds) {
    eventType
    migrations {
      ...Migration
    }
  }
}
    ${MigrationFragmentDoc}`;

    export function useMigrationEventsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MigrationEventsSubscription, MigrationEventsSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<MigrationEventsSubscription, MigrationEventsSubscriptionVariables>(MigrationEventsDocument, baseOptions);
    }
export type MigrationEventsSubscriptionHookResult = ReturnType<typeof useMigrationEventsSubscription>;
export type MigrationEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<MigrationEventsSubscription>;
export const MigrationHistoryDocument = gql`
    query MigrationHistory($migrationId: String!) {
  migration(migrationId: $migrationId) {
    ...Migration
    history {
      isEvent
      startedAt
      actor
      type
      details
      completion {
        completedAt
        details
      }
    }
  }
}
    ${MigrationFragmentDoc}`;

    export function useMigrationHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MigrationHistoryQuery, MigrationHistoryQueryVariables>) {
      return ApolloReactHooks.useQuery<MigrationHistoryQuery, MigrationHistoryQueryVariables>(MigrationHistoryDocument, baseOptions);
    }
      export function useMigrationHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MigrationHistoryQuery, MigrationHistoryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MigrationHistoryQuery, MigrationHistoryQueryVariables>(MigrationHistoryDocument, baseOptions);
      }
      
export type MigrationHistoryQueryHookResult = ReturnType<typeof useMigrationHistoryQuery>;
export type MigrationHistoryQueryResult = ApolloReactCommon.QueryResult<MigrationHistoryQuery, MigrationHistoryQueryVariables>;
export const MigrationIssueListEventsDocument = gql`
    subscription MigrationIssueListEvents($migrationIds: [String!]!) {
  subscribeForMigrationIssueListEvents(migrationIds: $migrationIds) {
    eventType
    migrationIssues {
      migrationId
      issues {
        ...MigrationIssue
      }
    }
  }
}
    ${MigrationIssueFragmentDoc}`;

    export function useMigrationIssueListEventsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MigrationIssueListEventsSubscription, MigrationIssueListEventsSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<MigrationIssueListEventsSubscription, MigrationIssueListEventsSubscriptionVariables>(MigrationIssueListEventsDocument, baseOptions);
    }
export type MigrationIssueListEventsSubscriptionHookResult = ReturnType<typeof useMigrationIssueListEventsSubscription>;
export type MigrationIssueListEventsSubscriptionResult = ApolloReactCommon.SubscriptionResult<MigrationIssueListEventsSubscription>;
export const MigrationIterationsDocument = gql`
    query MigrationIterations($migrationId: String!) {
  iterations(migrationId: $migrationId) {
    ...Iteration
  }
}
    ${IterationFragmentDoc}`;

    export function useMigrationIterationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MigrationIterationsQuery, MigrationIterationsQueryVariables>) {
      return ApolloReactHooks.useQuery<MigrationIterationsQuery, MigrationIterationsQueryVariables>(MigrationIterationsDocument, baseOptions);
    }
      export function useMigrationIterationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MigrationIterationsQuery, MigrationIterationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MigrationIterationsQuery, MigrationIterationsQueryVariables>(MigrationIterationsDocument, baseOptions);
      }
      
export type MigrationIterationsQueryHookResult = ReturnType<typeof useMigrationIterationsQuery>;
export type MigrationIterationsQueryResult = ApolloReactCommon.QueryResult<MigrationIterationsQuery, MigrationIterationsQueryVariables>;
export const MigrationProgressDocument = gql`
    query MigrationProgress($migrationId: String!) {
  migration(migrationId: $migrationId) {
    ...Migration
    ...MigrationConnections
    ...MigrationOrderSummary
    facts {
      ...FactOrPlaceholder
    }
    issues {
      ...MigrationIssueList
    }
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}
${MigrationOrderSummaryFragmentDoc}
${FactOrPlaceholderFragmentDoc}
${MigrationIssueListFragmentDoc}`;

    export function useMigrationProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MigrationProgressQuery, MigrationProgressQueryVariables>) {
      return ApolloReactHooks.useQuery<MigrationProgressQuery, MigrationProgressQueryVariables>(MigrationProgressDocument, baseOptions);
    }
      export function useMigrationProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MigrationProgressQuery, MigrationProgressQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MigrationProgressQuery, MigrationProgressQueryVariables>(MigrationProgressDocument, baseOptions);
      }
      
export type MigrationProgressQueryHookResult = ReturnType<typeof useMigrationProgressQuery>;
export type MigrationProgressQueryResult = ApolloReactCommon.QueryResult<MigrationProgressQuery, MigrationProgressQueryVariables>;
export const MonitoringDashboardDataDocument = gql`
    query MonitoringDashboardData($includeCompleted: Int!) {
  monitoredMigrations(includeCompleted: $includeCompleted) {
    ...Migration
    ...MigrationConnections
    value
    activeNotes {
      ...MigrationNoteList
    }
    activeJobIssues {
      issueId
      summary
      createdAt
    }
    issues {
      ...MigrationIssueList
    }
  }
  recentMigrationsActivity {
    timestamp
    count
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}
${MigrationNoteListFragmentDoc}
${MigrationIssueListFragmentDoc}`;

    export function useMonitoringDashboardDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonitoringDashboardDataQuery, MonitoringDashboardDataQueryVariables>) {
      return ApolloReactHooks.useQuery<MonitoringDashboardDataQuery, MonitoringDashboardDataQueryVariables>(MonitoringDashboardDataDocument, baseOptions);
    }
      export function useMonitoringDashboardDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonitoringDashboardDataQuery, MonitoringDashboardDataQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MonitoringDashboardDataQuery, MonitoringDashboardDataQueryVariables>(MonitoringDashboardDataDocument, baseOptions);
      }
      
export type MonitoringDashboardDataQueryHookResult = ReturnType<typeof useMonitoringDashboardDataQuery>;
export type MonitoringDashboardDataQueryResult = ApolloReactCommon.QueryResult<MonitoringDashboardDataQuery, MonitoringDashboardDataQueryVariables>;
export const PauseMigrationDocument = gql`
    mutation PauseMigration($migrationId: String!) {
  pauseMigration(migrationId: $migrationId) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type PauseMigrationMutationFn = ApolloReactCommon.MutationFunction<PauseMigrationMutation, PauseMigrationMutationVariables>;

    export function usePauseMigrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseMigrationMutation, PauseMigrationMutationVariables>) {
      return ApolloReactHooks.useMutation<PauseMigrationMutation, PauseMigrationMutationVariables>(PauseMigrationDocument, baseOptions);
    }
export type PauseMigrationMutationHookResult = ReturnType<typeof usePauseMigrationMutation>;
export type PauseMigrationMutationResult = ApolloReactCommon.MutationResult<PauseMigrationMutation>;
export type PauseMigrationMutationOptions = ApolloReactCommon.BaseMutationOptions<PauseMigrationMutation, PauseMigrationMutationVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($sourceCloudServiceId: String!, $destinationCloudServiceId: String!, $programAlias: String, $blueprintInputs: [BlueprintInputsEntryInput!]!, $checkboxesState: CheckboxesState) {
  placeOrder(sourceCloudServiceId: $sourceCloudServiceId, destinationCloudServiceId: $destinationCloudServiceId, programAlias: $programAlias, blueprintInputs: $blueprintInputs, checkboxesState: $checkboxesState) {
    ...PlaceOrderResult
  }
}
    ${PlaceOrderResultFragmentDoc}`;
export type PlaceOrderMutationFn = ApolloReactCommon.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;

    export function usePlaceOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
      return ApolloReactHooks.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, baseOptions);
    }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = ApolloReactCommon.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const RefundTransactionDocument = gql`
    mutation RefundTransaction($migrationId: String!) {
  refundTransaction(migrationId: $migrationId) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type RefundTransactionMutationFn = ApolloReactCommon.MutationFunction<RefundTransactionMutation, RefundTransactionMutationVariables>;

    export function useRefundTransactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundTransactionMutation, RefundTransactionMutationVariables>) {
      return ApolloReactHooks.useMutation<RefundTransactionMutation, RefundTransactionMutationVariables>(RefundTransactionDocument, baseOptions);
    }
export type RefundTransactionMutationHookResult = ReturnType<typeof useRefundTransactionMutation>;
export type RefundTransactionMutationResult = ApolloReactCommon.MutationResult<RefundTransactionMutation>;
export type RefundTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundTransactionMutation, RefundTransactionMutationVariables>;
export const ReleaseMigrationFromSupervisionDocument = gql`
    mutation ReleaseMigrationFromSupervision($migrationId: String!, $note: String) {
  releaseFromSupervision(migrationId: $migrationId, note: $note) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type ReleaseMigrationFromSupervisionMutationFn = ApolloReactCommon.MutationFunction<ReleaseMigrationFromSupervisionMutation, ReleaseMigrationFromSupervisionMutationVariables>;

    export function useReleaseMigrationFromSupervisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseMigrationFromSupervisionMutation, ReleaseMigrationFromSupervisionMutationVariables>) {
      return ApolloReactHooks.useMutation<ReleaseMigrationFromSupervisionMutation, ReleaseMigrationFromSupervisionMutationVariables>(ReleaseMigrationFromSupervisionDocument, baseOptions);
    }
export type ReleaseMigrationFromSupervisionMutationHookResult = ReturnType<typeof useReleaseMigrationFromSupervisionMutation>;
export type ReleaseMigrationFromSupervisionMutationResult = ApolloReactCommon.MutationResult<ReleaseMigrationFromSupervisionMutation>;
export type ReleaseMigrationFromSupervisionMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseMigrationFromSupervisionMutation, ReleaseMigrationFromSupervisionMutationVariables>;
export const ResolveIssueDocument = gql`
    mutation ResolveIssue($migrationId: String!, $issueId: String!) {
  resolveIssue(migrationId: $migrationId, issueId: $issueId) {
    ... on ResolveIssueResult_NoActiveIssueFound {
      foo
    }
    ... on ResolveIssueResult_PartiallyResolved {
      issue {
        ...MigrationIssue
      }
    }
    ... on ResolveIssueResult_Resolved {
      issue {
        ...MigrationIssue
      }
    }
  }
}
    ${MigrationIssueFragmentDoc}`;
export type ResolveIssueMutationFn = ApolloReactCommon.MutationFunction<ResolveIssueMutation, ResolveIssueMutationVariables>;

    export function useResolveIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResolveIssueMutation, ResolveIssueMutationVariables>) {
      return ApolloReactHooks.useMutation<ResolveIssueMutation, ResolveIssueMutationVariables>(ResolveIssueDocument, baseOptions);
    }
export type ResolveIssueMutationHookResult = ReturnType<typeof useResolveIssueMutation>;
export type ResolveIssueMutationResult = ApolloReactCommon.MutationResult<ResolveIssueMutation>;
export type ResolveIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveIssueMutation, ResolveIssueMutationVariables>;
export const ResumeMigrationDocument = gql`
    mutation ResumeMigration($migrationId: String!) {
  resumeMigration(migrationId: $migrationId) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type ResumeMigrationMutationFn = ApolloReactCommon.MutationFunction<ResumeMigrationMutation, ResumeMigrationMutationVariables>;

    export function useResumeMigrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeMigrationMutation, ResumeMigrationMutationVariables>) {
      return ApolloReactHooks.useMutation<ResumeMigrationMutation, ResumeMigrationMutationVariables>(ResumeMigrationDocument, baseOptions);
    }
export type ResumeMigrationMutationHookResult = ReturnType<typeof useResumeMigrationMutation>;
export type ResumeMigrationMutationResult = ApolloReactCommon.MutationResult<ResumeMigrationMutation>;
export type ResumeMigrationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeMigrationMutation, ResumeMigrationMutationVariables>;
export const SetAutoResumingConfigurationDocument = gql`
    mutation SetAutoResumingConfiguration($migrationId: String!, $enabled: Boolean!, $timestamp: String) {
  setAutoResumingConfiguration(migrationId: $migrationId, enabled: $enabled, timestamp: $timestamp) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type SetAutoResumingConfigurationMutationFn = ApolloReactCommon.MutationFunction<SetAutoResumingConfigurationMutation, SetAutoResumingConfigurationMutationVariables>;

    export function useSetAutoResumingConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAutoResumingConfigurationMutation, SetAutoResumingConfigurationMutationVariables>) {
      return ApolloReactHooks.useMutation<SetAutoResumingConfigurationMutation, SetAutoResumingConfigurationMutationVariables>(SetAutoResumingConfigurationDocument, baseOptions);
    }
export type SetAutoResumingConfigurationMutationHookResult = ReturnType<typeof useSetAutoResumingConfigurationMutation>;
export type SetAutoResumingConfigurationMutationResult = ApolloReactCommon.MutationResult<SetAutoResumingConfigurationMutation>;
export type SetAutoResumingConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAutoResumingConfigurationMutation, SetAutoResumingConfigurationMutationVariables>;
export const SetMigrationFeedbackDocument = gql`
    mutation SetMigrationFeedback($migrationId: String!, $question: String!, $answer: String!) {
  setMigrationFeedback(migrationId: $migrationId, question: $question, answer: $answer)
}
    `;
export type SetMigrationFeedbackMutationFn = ApolloReactCommon.MutationFunction<SetMigrationFeedbackMutation, SetMigrationFeedbackMutationVariables>;

    export function useSetMigrationFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMigrationFeedbackMutation, SetMigrationFeedbackMutationVariables>) {
      return ApolloReactHooks.useMutation<SetMigrationFeedbackMutation, SetMigrationFeedbackMutationVariables>(SetMigrationFeedbackDocument, baseOptions);
    }
export type SetMigrationFeedbackMutationHookResult = ReturnType<typeof useSetMigrationFeedbackMutation>;
export type SetMigrationFeedbackMutationResult = ApolloReactCommon.MutationResult<SetMigrationFeedbackMutation>;
export type SetMigrationFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMigrationFeedbackMutation, SetMigrationFeedbackMutationVariables>;
export const SubmitTransactionForSettlementDocument = gql`
    mutation submitTransactionForSettlement($migrationId: String!) {
  submitTransactionForSettlement(migrationId: $migrationId) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type SubmitTransactionForSettlementMutationFn = ApolloReactCommon.MutationFunction<SubmitTransactionForSettlementMutation, SubmitTransactionForSettlementMutationVariables>;

    export function useSubmitTransactionForSettlementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitTransactionForSettlementMutation, SubmitTransactionForSettlementMutationVariables>) {
      return ApolloReactHooks.useMutation<SubmitTransactionForSettlementMutation, SubmitTransactionForSettlementMutationVariables>(SubmitTransactionForSettlementDocument, baseOptions);
    }
export type SubmitTransactionForSettlementMutationHookResult = ReturnType<typeof useSubmitTransactionForSettlementMutation>;
export type SubmitTransactionForSettlementMutationResult = ApolloReactCommon.MutationResult<SubmitTransactionForSettlementMutation>;
export type SubmitTransactionForSettlementMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitTransactionForSettlementMutation, SubmitTransactionForSettlementMutationVariables>;
export const TakeMigrationForSupervisionDocument = gql`
    mutation TakeMigrationForSupervision($migrationId: String!) {
  takeForSupervision(migrationId: $migrationId) {
    ...Migration
  }
}
    ${MigrationFragmentDoc}`;
export type TakeMigrationForSupervisionMutationFn = ApolloReactCommon.MutationFunction<TakeMigrationForSupervisionMutation, TakeMigrationForSupervisionMutationVariables>;

    export function useTakeMigrationForSupervisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TakeMigrationForSupervisionMutation, TakeMigrationForSupervisionMutationVariables>) {
      return ApolloReactHooks.useMutation<TakeMigrationForSupervisionMutation, TakeMigrationForSupervisionMutationVariables>(TakeMigrationForSupervisionDocument, baseOptions);
    }
export type TakeMigrationForSupervisionMutationHookResult = ReturnType<typeof useTakeMigrationForSupervisionMutation>;
export type TakeMigrationForSupervisionMutationResult = ApolloReactCommon.MutationResult<TakeMigrationForSupervisionMutation>;
export type TakeMigrationForSupervisionMutationOptions = ApolloReactCommon.BaseMutationOptions<TakeMigrationForSupervisionMutation, TakeMigrationForSupervisionMutationVariables>;
export const UserMigrationsDocument = gql`
    query UserMigrations($userId: String!, $includeBatched: Boolean!) {
  userMigrations(userId: $userId, includeBatched: $includeBatched) {
    ...Migration
    ...MigrationConnections
  }
  userBatches(userId: $userId) {
    ...Batch
  }
}
    ${MigrationFragmentDoc}
${MigrationConnectionsFragmentDoc}
${BatchFragmentDoc}`;

    export function useUserMigrationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserMigrationsQuery, UserMigrationsQueryVariables>) {
      return ApolloReactHooks.useQuery<UserMigrationsQuery, UserMigrationsQueryVariables>(UserMigrationsDocument, baseOptions);
    }
      export function useUserMigrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserMigrationsQuery, UserMigrationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<UserMigrationsQuery, UserMigrationsQueryVariables>(UserMigrationsDocument, baseOptions);
      }
      
export type UserMigrationsQueryHookResult = ReturnType<typeof useUserMigrationsQuery>;
export type UserMigrationsQueryResult = ApolloReactCommon.QueryResult<UserMigrationsQuery, UserMigrationsQueryVariables>;
export const VoidTransactionDocument = gql`
    mutation VoidTransaction($migrationId: String!) {
  voidTransaction(migrationId: $migrationId) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type VoidTransactionMutationFn = ApolloReactCommon.MutationFunction<VoidTransactionMutation, VoidTransactionMutationVariables>;

    export function useVoidTransactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidTransactionMutation, VoidTransactionMutationVariables>) {
      return ApolloReactHooks.useMutation<VoidTransactionMutation, VoidTransactionMutationVariables>(VoidTransactionDocument, baseOptions);
    }
export type VoidTransactionMutationHookResult = ReturnType<typeof useVoidTransactionMutation>;
export type VoidTransactionMutationResult = ApolloReactCommon.MutationResult<VoidTransactionMutation>;
export type VoidTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidTransactionMutation, VoidTransactionMutationVariables>;
export const DeleteOrganizationPortalUserDocument = gql`
    mutation DeleteOrganizationPortalUser($id: Int!) {
  deleteOrganizationPortalUser(id: $id)
}
    `;
export type DeleteOrganizationPortalUserMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationPortalUserMutation, DeleteOrganizationPortalUserMutationVariables>;

    export function useDeleteOrganizationPortalUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationPortalUserMutation, DeleteOrganizationPortalUserMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteOrganizationPortalUserMutation, DeleteOrganizationPortalUserMutationVariables>(DeleteOrganizationPortalUserDocument, baseOptions);
    }
export type DeleteOrganizationPortalUserMutationHookResult = ReturnType<typeof useDeleteOrganizationPortalUserMutation>;
export type DeleteOrganizationPortalUserMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationPortalUserMutation>;
export type DeleteOrganizationPortalUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationPortalUserMutation, DeleteOrganizationPortalUserMutationVariables>;
export const GetOrganizationPortalUserDocument = gql`
    query GetOrganizationPortalUser($id: Int!) {
  organizationPortalUser(id: $id) {
    ...OrganizationPortalUser
  }
}
    ${OrganizationPortalUserFragmentDoc}`;

    export function useGetOrganizationPortalUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationPortalUserQuery, GetOrganizationPortalUserQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrganizationPortalUserQuery, GetOrganizationPortalUserQueryVariables>(GetOrganizationPortalUserDocument, baseOptions);
    }
      export function useGetOrganizationPortalUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrganizationPortalUserQuery, GetOrganizationPortalUserQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrganizationPortalUserQuery, GetOrganizationPortalUserQueryVariables>(GetOrganizationPortalUserDocument, baseOptions);
      }
      
export type GetOrganizationPortalUserQueryHookResult = ReturnType<typeof useGetOrganizationPortalUserQuery>;
export type GetOrganizationPortalUserQueryResult = ApolloReactCommon.QueryResult<GetOrganizationPortalUserQuery, GetOrganizationPortalUserQueryVariables>;
export const InsertOrganizationPortalUserDocument = gql`
    mutation InsertOrganizationPortalUser($organizationId: Int!, $formData: OrganizationPortalUserFormData!) {
  insertOrganizationPortalUser(organizationId: $organizationId, formData: $formData) {
    ...OrganizationPortalUser
  }
}
    ${OrganizationPortalUserFragmentDoc}`;
export type InsertOrganizationPortalUserMutationFn = ApolloReactCommon.MutationFunction<InsertOrganizationPortalUserMutation, InsertOrganizationPortalUserMutationVariables>;

    export function useInsertOrganizationPortalUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOrganizationPortalUserMutation, InsertOrganizationPortalUserMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertOrganizationPortalUserMutation, InsertOrganizationPortalUserMutationVariables>(InsertOrganizationPortalUserDocument, baseOptions);
    }
export type InsertOrganizationPortalUserMutationHookResult = ReturnType<typeof useInsertOrganizationPortalUserMutation>;
export type InsertOrganizationPortalUserMutationResult = ApolloReactCommon.MutationResult<InsertOrganizationPortalUserMutation>;
export type InsertOrganizationPortalUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOrganizationPortalUserMutation, InsertOrganizationPortalUserMutationVariables>;
export const InsertOrganizationPortalUsersDocument = gql`
    mutation InsertOrganizationPortalUsers($organizationId: Int!, $emailAddresses: [String!]!, $role: OrganizationPortalUserRole!) {
  insertOrganizationPortalUsers(organizationId: $organizationId, emailAddresses: $emailAddresses, role: $role) {
    ...OrganizationPortalUser
  }
}
    ${OrganizationPortalUserFragmentDoc}`;
export type InsertOrganizationPortalUsersMutationFn = ApolloReactCommon.MutationFunction<InsertOrganizationPortalUsersMutation, InsertOrganizationPortalUsersMutationVariables>;

    export function useInsertOrganizationPortalUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOrganizationPortalUsersMutation, InsertOrganizationPortalUsersMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertOrganizationPortalUsersMutation, InsertOrganizationPortalUsersMutationVariables>(InsertOrganizationPortalUsersDocument, baseOptions);
    }
export type InsertOrganizationPortalUsersMutationHookResult = ReturnType<typeof useInsertOrganizationPortalUsersMutation>;
export type InsertOrganizationPortalUsersMutationResult = ApolloReactCommon.MutationResult<InsertOrganizationPortalUsersMutation>;
export type InsertOrganizationPortalUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOrganizationPortalUsersMutation, InsertOrganizationPortalUsersMutationVariables>;
export const SearchOrganizationPortalUsersDocument = gql`
    query SearchOrganizationPortalUsers($organizationId: Int!, $term: String!) {
  searchOrganizationPortalUsers(organizationId: $organizationId, term: $term) {
    ...OrganizationPortalUser
  }
}
    ${OrganizationPortalUserFragmentDoc}`;

    export function useSearchOrganizationPortalUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOrganizationPortalUsersQuery, SearchOrganizationPortalUsersQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchOrganizationPortalUsersQuery, SearchOrganizationPortalUsersQueryVariables>(SearchOrganizationPortalUsersDocument, baseOptions);
    }
      export function useSearchOrganizationPortalUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOrganizationPortalUsersQuery, SearchOrganizationPortalUsersQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchOrganizationPortalUsersQuery, SearchOrganizationPortalUsersQueryVariables>(SearchOrganizationPortalUsersDocument, baseOptions);
      }
      
export type SearchOrganizationPortalUsersQueryHookResult = ReturnType<typeof useSearchOrganizationPortalUsersQuery>;
export type SearchOrganizationPortalUsersQueryResult = ApolloReactCommon.QueryResult<SearchOrganizationPortalUsersQuery, SearchOrganizationPortalUsersQueryVariables>;
export const UpdateOrganizationPortalUserDocument = gql`
    mutation UpdateOrganizationPortalUser($id: Int!, $formData: OrganizationPortalUserFormData!) {
  updateOrganizationPortalUser(id: $id, formData: $formData) {
    ...OrganizationPortalUser
  }
}
    ${OrganizationPortalUserFragmentDoc}`;
export type UpdateOrganizationPortalUserMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationPortalUserMutation, UpdateOrganizationPortalUserMutationVariables>;

    export function useUpdateOrganizationPortalUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationPortalUserMutation, UpdateOrganizationPortalUserMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateOrganizationPortalUserMutation, UpdateOrganizationPortalUserMutationVariables>(UpdateOrganizationPortalUserDocument, baseOptions);
    }
export type UpdateOrganizationPortalUserMutationHookResult = ReturnType<typeof useUpdateOrganizationPortalUserMutation>;
export type UpdateOrganizationPortalUserMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationPortalUserMutation>;
export type UpdateOrganizationPortalUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationPortalUserMutation, UpdateOrganizationPortalUserMutationVariables>;
export const ClearSponsorshipLimitAmountRemainingDocument = gql`
    mutation ClearSponsorshipLimitAmountRemaining($organizationId: Int!) {
  clearSponsorshipLimitAmountRemaining(organizationId: $organizationId) {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export type ClearSponsorshipLimitAmountRemainingMutationFn = ApolloReactCommon.MutationFunction<ClearSponsorshipLimitAmountRemainingMutation, ClearSponsorshipLimitAmountRemainingMutationVariables>;

    export function useClearSponsorshipLimitAmountRemainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearSponsorshipLimitAmountRemainingMutation, ClearSponsorshipLimitAmountRemainingMutationVariables>) {
      return ApolloReactHooks.useMutation<ClearSponsorshipLimitAmountRemainingMutation, ClearSponsorshipLimitAmountRemainingMutationVariables>(ClearSponsorshipLimitAmountRemainingDocument, baseOptions);
    }
export type ClearSponsorshipLimitAmountRemainingMutationHookResult = ReturnType<typeof useClearSponsorshipLimitAmountRemainingMutation>;
export type ClearSponsorshipLimitAmountRemainingMutationResult = ApolloReactCommon.MutationResult<ClearSponsorshipLimitAmountRemainingMutation>;
export type ClearSponsorshipLimitAmountRemainingMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearSponsorshipLimitAmountRemainingMutation, ClearSponsorshipLimitAmountRemainingMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($id: Int!) {
  deleteOrganization(id: $id)
}
    `;
export type DeleteOrganizationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

    export function useDeleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, baseOptions);
    }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: Int!) {
  organization(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

    export function useGetOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
    }
      export function useGetOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
      }
      
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationQueryResult = ApolloReactCommon.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetOrganizationContextDocument = gql`
    query GetOrganizationContext($idOrAlias: String!) {
  organizationContext(idOrAlias: $idOrAlias) {
    ...OrganizationContext
  }
}
    ${OrganizationContextFragmentDoc}`;

    export function useGetOrganizationContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationContextQuery, GetOrganizationContextQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrganizationContextQuery, GetOrganizationContextQueryVariables>(GetOrganizationContextDocument, baseOptions);
    }
      export function useGetOrganizationContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrganizationContextQuery, GetOrganizationContextQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrganizationContextQuery, GetOrganizationContextQueryVariables>(GetOrganizationContextDocument, baseOptions);
      }
      
export type GetOrganizationContextQueryHookResult = ReturnType<typeof useGetOrganizationContextQuery>;
export type GetOrganizationContextQueryResult = ApolloReactCommon.QueryResult<GetOrganizationContextQuery, GetOrganizationContextQueryVariables>;
export const IncrementSponsorshipLimitAmountRemainingDocument = gql`
    mutation IncrementSponsorshipLimitAmountRemaining($organizationId: Int!, $amount: BigDecimal!) {
  incrementSponsorshipLimitAmountRemaining(organizationId: $organizationId, amount: $amount) {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export type IncrementSponsorshipLimitAmountRemainingMutationFn = ApolloReactCommon.MutationFunction<IncrementSponsorshipLimitAmountRemainingMutation, IncrementSponsorshipLimitAmountRemainingMutationVariables>;

    export function useIncrementSponsorshipLimitAmountRemainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncrementSponsorshipLimitAmountRemainingMutation, IncrementSponsorshipLimitAmountRemainingMutationVariables>) {
      return ApolloReactHooks.useMutation<IncrementSponsorshipLimitAmountRemainingMutation, IncrementSponsorshipLimitAmountRemainingMutationVariables>(IncrementSponsorshipLimitAmountRemainingDocument, baseOptions);
    }
export type IncrementSponsorshipLimitAmountRemainingMutationHookResult = ReturnType<typeof useIncrementSponsorshipLimitAmountRemainingMutation>;
export type IncrementSponsorshipLimitAmountRemainingMutationResult = ApolloReactCommon.MutationResult<IncrementSponsorshipLimitAmountRemainingMutation>;
export type IncrementSponsorshipLimitAmountRemainingMutationOptions = ApolloReactCommon.BaseMutationOptions<IncrementSponsorshipLimitAmountRemainingMutation, IncrementSponsorshipLimitAmountRemainingMutationVariables>;
export const InsertOrganizationDocument = gql`
    mutation InsertOrganization($formData: OrganizationFormData!) {
  insertOrganization(formData: $formData) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type InsertOrganizationMutationFn = ApolloReactCommon.MutationFunction<InsertOrganizationMutation, InsertOrganizationMutationVariables>;

    export function useInsertOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOrganizationMutation, InsertOrganizationMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertOrganizationMutation, InsertOrganizationMutationVariables>(InsertOrganizationDocument, baseOptions);
    }
export type InsertOrganizationMutationHookResult = ReturnType<typeof useInsertOrganizationMutation>;
export type InsertOrganizationMutationResult = ApolloReactCommon.MutationResult<InsertOrganizationMutation>;
export type InsertOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOrganizationMutation, InsertOrganizationMutationVariables>;
export const SearchOrganizationsDocument = gql`
    query SearchOrganizations($term: String!) {
  searchOrganizations(term: $term) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

    export function useSearchOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>(SearchOrganizationsDocument, baseOptions);
    }
      export function useSearchOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>(SearchOrganizationsDocument, baseOptions);
      }
      
export type SearchOrganizationsQueryHookResult = ReturnType<typeof useSearchOrganizationsQuery>;
export type SearchOrganizationsQueryResult = ApolloReactCommon.QueryResult<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: Int!, $formData: OrganizationFormData!) {
  updateOrganization(id: $id, formData: $formData) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

    export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
    }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const BecomeAnAmbassadorDocument = gql`
    mutation BecomeAnAmbassador($sourceConnectionId: String!, $destinationConnectionId: String!, $customMessage: String, $accountAction: AccountAction!, $accountActionDate: Instant!, $contacts: [AmbassadorsProgramContact!]!) {
  becomeAnAmbassador(sourceConnectionId: $sourceConnectionId, destinationConnectionId: $destinationConnectionId, customMessage: $customMessage, accountAction: $accountAction, accountActionDate: $accountActionDate, contacts: $contacts) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type BecomeAnAmbassadorMutationFn = ApolloReactCommon.MutationFunction<BecomeAnAmbassadorMutation, BecomeAnAmbassadorMutationVariables>;

    export function useBecomeAnAmbassadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BecomeAnAmbassadorMutation, BecomeAnAmbassadorMutationVariables>) {
      return ApolloReactHooks.useMutation<BecomeAnAmbassadorMutation, BecomeAnAmbassadorMutationVariables>(BecomeAnAmbassadorDocument, baseOptions);
    }
export type BecomeAnAmbassadorMutationHookResult = ReturnType<typeof useBecomeAnAmbassadorMutation>;
export type BecomeAnAmbassadorMutationResult = ApolloReactCommon.MutationResult<BecomeAnAmbassadorMutation>;
export type BecomeAnAmbassadorMutationOptions = ApolloReactCommon.BaseMutationOptions<BecomeAnAmbassadorMutation, BecomeAnAmbassadorMutationVariables>;
export const GetMigrationPriceCalculatorDocument = gql`
    query GetMigrationPriceCalculator($sourceConnectionId: String!, $destinationConnectionId: String!, $programAlias: String) {
  getMigrationPriceCalculator(sourceConnectionId: $sourceConnectionId, destinationConnectionId: $destinationConnectionId, programAlias: $programAlias) {
    ...PricingModelCalculator
  }
}
    ${PricingModelCalculatorFragmentDoc}`;

    export function useGetMigrationPriceCalculatorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMigrationPriceCalculatorQuery, GetMigrationPriceCalculatorQueryVariables>) {
      return ApolloReactHooks.useQuery<GetMigrationPriceCalculatorQuery, GetMigrationPriceCalculatorQueryVariables>(GetMigrationPriceCalculatorDocument, baseOptions);
    }
      export function useGetMigrationPriceCalculatorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMigrationPriceCalculatorQuery, GetMigrationPriceCalculatorQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetMigrationPriceCalculatorQuery, GetMigrationPriceCalculatorQueryVariables>(GetMigrationPriceCalculatorDocument, baseOptions);
      }
      
export type GetMigrationPriceCalculatorQueryHookResult = ReturnType<typeof useGetMigrationPriceCalculatorQuery>;
export type GetMigrationPriceCalculatorQueryResult = ApolloReactCommon.QueryResult<GetMigrationPriceCalculatorQuery, GetMigrationPriceCalculatorQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder($orderId: String!) {
  order(orderId: $orderId) {
    ...Order
    ...OrderRevenue
  }
}
    ${OrderFragmentDoc}
${OrderRevenueFragmentDoc}`;

    export function useGetOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
    }
      export function useGetOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
      
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderQueryResult = ApolloReactCommon.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetSourceConnectionPromotionsDocument = gql`
    query GetSourceConnectionPromotions($currentProgramAlias: String, $sourceConnectionId: String!, $destinationCloudServiceId: String!, $destinationConnectionId: String) {
  getSourceConnectionPromotions(currentProgramAlias: $currentProgramAlias, sourceConnectionId: $sourceConnectionId, destinationCloudServiceId: $destinationCloudServiceId, destinationConnectionId: $destinationConnectionId) {
    currentProgram {
      ...ProgramSummary
    }
    programEligibility {
      ...ProgramEligibility
    }
    hasPreviousMigrationsFromSource
    school {
      ...SchoolSummary
    }
  }
}
    ${ProgramSummaryFragmentDoc}
${ProgramEligibilityFragmentDoc}
${SchoolSummaryFragmentDoc}`;

    export function useGetSourceConnectionPromotionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSourceConnectionPromotionsQuery, GetSourceConnectionPromotionsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSourceConnectionPromotionsQuery, GetSourceConnectionPromotionsQueryVariables>(GetSourceConnectionPromotionsDocument, baseOptions);
    }
      export function useGetSourceConnectionPromotionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSourceConnectionPromotionsQuery, GetSourceConnectionPromotionsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetSourceConnectionPromotionsQuery, GetSourceConnectionPromotionsQueryVariables>(GetSourceConnectionPromotionsDocument, baseOptions);
      }
      
export type GetSourceConnectionPromotionsQueryHookResult = ReturnType<typeof useGetSourceConnectionPromotionsQuery>;
export type GetSourceConnectionPromotionsQueryResult = ApolloReactCommon.QueryResult<GetSourceConnectionPromotionsQuery, GetSourceConnectionPromotionsQueryVariables>;
export const RenderAmbassadorEmailPreviewDocument = gql`
    mutation RenderAmbassadorEmailPreview($sourceConnectionId: String!) {
  renderAmbassadorEmailPreview(sourceConnectionId: $sourceConnectionId) {
    ...EmailPreview
  }
}
    ${EmailPreviewFragmentDoc}`;
export type RenderAmbassadorEmailPreviewMutationFn = ApolloReactCommon.MutationFunction<RenderAmbassadorEmailPreviewMutation, RenderAmbassadorEmailPreviewMutationVariables>;

    export function useRenderAmbassadorEmailPreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenderAmbassadorEmailPreviewMutation, RenderAmbassadorEmailPreviewMutationVariables>) {
      return ApolloReactHooks.useMutation<RenderAmbassadorEmailPreviewMutation, RenderAmbassadorEmailPreviewMutationVariables>(RenderAmbassadorEmailPreviewDocument, baseOptions);
    }
export type RenderAmbassadorEmailPreviewMutationHookResult = ReturnType<typeof useRenderAmbassadorEmailPreviewMutation>;
export type RenderAmbassadorEmailPreviewMutationResult = ApolloReactCommon.MutationResult<RenderAmbassadorEmailPreviewMutation>;
export type RenderAmbassadorEmailPreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<RenderAmbassadorEmailPreviewMutation, RenderAmbassadorEmailPreviewMutationVariables>;
export const SendTestAmbassadorEmailDocument = gql`
    mutation SendTestAmbassadorEmail($sourceConnectionId: String!, $customMessage: String, $accountAction: AccountAction!, $accountActionDate: Instant) {
  sendTestAmbassadorEmail(sourceConnectionId: $sourceConnectionId, customMessage: $customMessage, accountAction: $accountAction, accountActionDate: $accountActionDate)
}
    `;
export type SendTestAmbassadorEmailMutationFn = ApolloReactCommon.MutationFunction<SendTestAmbassadorEmailMutation, SendTestAmbassadorEmailMutationVariables>;

    export function useSendTestAmbassadorEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTestAmbassadorEmailMutation, SendTestAmbassadorEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<SendTestAmbassadorEmailMutation, SendTestAmbassadorEmailMutationVariables>(SendTestAmbassadorEmailDocument, baseOptions);
    }
export type SendTestAmbassadorEmailMutationHookResult = ReturnType<typeof useSendTestAmbassadorEmailMutation>;
export type SendTestAmbassadorEmailMutationResult = ApolloReactCommon.MutationResult<SendTestAmbassadorEmailMutation>;
export type SendTestAmbassadorEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTestAmbassadorEmailMutation, SendTestAmbassadorEmailMutationVariables>;
export const ValidateAmbassadorCodeDocument = gql`
    mutation ValidateAmbassadorCode($sourceConnectionId: String, $ambassadorCode: String!) {
  validateAmbassadorCode(sourceConnectionId: $sourceConnectionId, ambassadorCode: $ambassadorCode) {
    ambassadorCode
    referralCode {
      ...ReferralCodeSummary
    }
  }
}
    ${ReferralCodeSummaryFragmentDoc}`;
export type ValidateAmbassadorCodeMutationFn = ApolloReactCommon.MutationFunction<ValidateAmbassadorCodeMutation, ValidateAmbassadorCodeMutationVariables>;

    export function useValidateAmbassadorCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateAmbassadorCodeMutation, ValidateAmbassadorCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<ValidateAmbassadorCodeMutation, ValidateAmbassadorCodeMutationVariables>(ValidateAmbassadorCodeDocument, baseOptions);
    }
export type ValidateAmbassadorCodeMutationHookResult = ReturnType<typeof useValidateAmbassadorCodeMutation>;
export type ValidateAmbassadorCodeMutationResult = ApolloReactCommon.MutationResult<ValidateAmbassadorCodeMutation>;
export type ValidateAmbassadorCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateAmbassadorCodeMutation, ValidateAmbassadorCodeMutationVariables>;
export const ValidateAmbassadorsProgramEmailAddressDocument = gql`
    mutation ValidateAmbassadorsProgramEmailAddress($sourceConnectionId: String!, $emailAddress: String!) {
  validateAmbassadorsProgramEmailAddress(sourceConnectionId: $sourceConnectionId, emailAddress: $emailAddress)
}
    `;
export type ValidateAmbassadorsProgramEmailAddressMutationFn = ApolloReactCommon.MutationFunction<ValidateAmbassadorsProgramEmailAddressMutation, ValidateAmbassadorsProgramEmailAddressMutationVariables>;

    export function useValidateAmbassadorsProgramEmailAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateAmbassadorsProgramEmailAddressMutation, ValidateAmbassadorsProgramEmailAddressMutationVariables>) {
      return ApolloReactHooks.useMutation<ValidateAmbassadorsProgramEmailAddressMutation, ValidateAmbassadorsProgramEmailAddressMutationVariables>(ValidateAmbassadorsProgramEmailAddressDocument, baseOptions);
    }
export type ValidateAmbassadorsProgramEmailAddressMutationHookResult = ReturnType<typeof useValidateAmbassadorsProgramEmailAddressMutation>;
export type ValidateAmbassadorsProgramEmailAddressMutationResult = ApolloReactCommon.MutationResult<ValidateAmbassadorsProgramEmailAddressMutation>;
export type ValidateAmbassadorsProgramEmailAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateAmbassadorsProgramEmailAddressMutation, ValidateAmbassadorsProgramEmailAddressMutationVariables>;
export const ValidateCouponCodeDocument = gql`
    mutation ValidateCouponCode($couponCodeId: String!) {
  validateCouponCode(couponCodeId: $couponCodeId) {
    id
    discount
    isPercentage
    remainingUses
  }
}
    `;
export type ValidateCouponCodeMutationFn = ApolloReactCommon.MutationFunction<ValidateCouponCodeMutation, ValidateCouponCodeMutationVariables>;

    export function useValidateCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateCouponCodeMutation, ValidateCouponCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<ValidateCouponCodeMutation, ValidateCouponCodeMutationVariables>(ValidateCouponCodeDocument, baseOptions);
    }
export type ValidateCouponCodeMutationHookResult = ReturnType<typeof useValidateCouponCodeMutation>;
export type ValidateCouponCodeMutationResult = ApolloReactCommon.MutationResult<ValidateCouponCodeMutation>;
export type ValidateCouponCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateCouponCodeMutation, ValidateCouponCodeMutationVariables>;
export const ValidateReferralCodeDocument = gql`
    mutation ValidateReferralCode($sourceConnectionId: String, $referralCode: String!) {
  validateReferralCode(sourceConnectionId: $sourceConnectionId, referralCode: $referralCode) {
    ...ValidateReferralCodeResult
  }
}
    ${ValidateReferralCodeResultFragmentDoc}`;
export type ValidateReferralCodeMutationFn = ApolloReactCommon.MutationFunction<ValidateReferralCodeMutation, ValidateReferralCodeMutationVariables>;

    export function useValidateReferralCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateReferralCodeMutation, ValidateReferralCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<ValidateReferralCodeMutation, ValidateReferralCodeMutationVariables>(ValidateReferralCodeDocument, baseOptions);
    }
export type ValidateReferralCodeMutationHookResult = ReturnType<typeof useValidateReferralCodeMutation>;
export type ValidateReferralCodeMutationResult = ApolloReactCommon.MutationResult<ValidateReferralCodeMutation>;
export type ValidateReferralCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateReferralCodeMutation, ValidateReferralCodeMutationVariables>;
export const DeletePricingModelDocument = gql`
    mutation DeletePricingModel($id: Int!) {
  deletePricingModel(id: $id)
}
    `;
export type DeletePricingModelMutationFn = ApolloReactCommon.MutationFunction<DeletePricingModelMutation, DeletePricingModelMutationVariables>;

    export function useDeletePricingModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePricingModelMutation, DeletePricingModelMutationVariables>) {
      return ApolloReactHooks.useMutation<DeletePricingModelMutation, DeletePricingModelMutationVariables>(DeletePricingModelDocument, baseOptions);
    }
export type DeletePricingModelMutationHookResult = ReturnType<typeof useDeletePricingModelMutation>;
export type DeletePricingModelMutationResult = ApolloReactCommon.MutationResult<DeletePricingModelMutation>;
export type DeletePricingModelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePricingModelMutation, DeletePricingModelMutationVariables>;
export const GetPricingModelDocument = gql`
    query GetPricingModel($id: Int!) {
  pricingModel(id: $id) {
    ...PricingModel
  }
}
    ${PricingModelFragmentDoc}`;

    export function useGetPricingModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPricingModelQuery, GetPricingModelQueryVariables>) {
      return ApolloReactHooks.useQuery<GetPricingModelQuery, GetPricingModelQueryVariables>(GetPricingModelDocument, baseOptions);
    }
      export function useGetPricingModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPricingModelQuery, GetPricingModelQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetPricingModelQuery, GetPricingModelQueryVariables>(GetPricingModelDocument, baseOptions);
      }
      
export type GetPricingModelQueryHookResult = ReturnType<typeof useGetPricingModelQuery>;
export type GetPricingModelQueryResult = ApolloReactCommon.QueryResult<GetPricingModelQuery, GetPricingModelQueryVariables>;
export const InsertPricingModelDocument = gql`
    mutation InsertPricingModel($formData: PricingModelFormData!) {
  insertPricingModel(formData: $formData) {
    ...PricingModel
  }
}
    ${PricingModelFragmentDoc}`;
export type InsertPricingModelMutationFn = ApolloReactCommon.MutationFunction<InsertPricingModelMutation, InsertPricingModelMutationVariables>;

    export function useInsertPricingModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertPricingModelMutation, InsertPricingModelMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertPricingModelMutation, InsertPricingModelMutationVariables>(InsertPricingModelDocument, baseOptions);
    }
export type InsertPricingModelMutationHookResult = ReturnType<typeof useInsertPricingModelMutation>;
export type InsertPricingModelMutationResult = ApolloReactCommon.MutationResult<InsertPricingModelMutation>;
export type InsertPricingModelMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertPricingModelMutation, InsertPricingModelMutationVariables>;
export const SearchPricingModelsDocument = gql`
    query SearchPricingModels($term: String!) {
  searchPricingModels(term: $term) {
    ...PricingModel
  }
}
    ${PricingModelFragmentDoc}`;

    export function useSearchPricingModelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPricingModelsQuery, SearchPricingModelsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchPricingModelsQuery, SearchPricingModelsQueryVariables>(SearchPricingModelsDocument, baseOptions);
    }
      export function useSearchPricingModelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPricingModelsQuery, SearchPricingModelsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchPricingModelsQuery, SearchPricingModelsQueryVariables>(SearchPricingModelsDocument, baseOptions);
      }
      
export type SearchPricingModelsQueryHookResult = ReturnType<typeof useSearchPricingModelsQuery>;
export type SearchPricingModelsQueryResult = ApolloReactCommon.QueryResult<SearchPricingModelsQuery, SearchPricingModelsQueryVariables>;
export const UpdatePricingModelDocument = gql`
    mutation UpdatePricingModel($id: Int!, $formData: PricingModelFormData!) {
  updatePricingModel(id: $id, formData: $formData) {
    ...PricingModel
  }
}
    ${PricingModelFragmentDoc}`;
export type UpdatePricingModelMutationFn = ApolloReactCommon.MutationFunction<UpdatePricingModelMutation, UpdatePricingModelMutationVariables>;

    export function useUpdatePricingModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePricingModelMutation, UpdatePricingModelMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdatePricingModelMutation, UpdatePricingModelMutationVariables>(UpdatePricingModelDocument, baseOptions);
    }
export type UpdatePricingModelMutationHookResult = ReturnType<typeof useUpdatePricingModelMutation>;
export type UpdatePricingModelMutationResult = ApolloReactCommon.MutationResult<UpdatePricingModelMutation>;
export type UpdatePricingModelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePricingModelMutation, UpdatePricingModelMutationVariables>;
export const DeleteProgramDocument = gql`
    mutation DeleteProgram($id: String!) {
  deleteProgram(id: $id)
}
    `;
export type DeleteProgramMutationFn = ApolloReactCommon.MutationFunction<DeleteProgramMutation, DeleteProgramMutationVariables>;

    export function useDeleteProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteProgramMutation, DeleteProgramMutationVariables>(DeleteProgramDocument, baseOptions);
    }
export type DeleteProgramMutationHookResult = ReturnType<typeof useDeleteProgramMutation>;
export type DeleteProgramMutationResult = ApolloReactCommon.MutationResult<DeleteProgramMutation>;
export type DeleteProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const GetProgramDocument = gql`
    query GetProgram($id: String!) {
  program(id: $id) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;

    export function useGetProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
      return ApolloReactHooks.useQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, baseOptions);
    }
      export function useGetProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, baseOptions);
      }
      
export type GetProgramQueryHookResult = ReturnType<typeof useGetProgramQuery>;
export type GetProgramQueryResult = ApolloReactCommon.QueryResult<GetProgramQuery, GetProgramQueryVariables>;
export const InsertProgramDocument = gql`
    mutation InsertProgram($organizationId: Int!, $formData: ProgramFormData!) {
  insertProgram(organizationId: $organizationId, formData: $formData) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;
export type InsertProgramMutationFn = ApolloReactCommon.MutationFunction<InsertProgramMutation, InsertProgramMutationVariables>;

    export function useInsertProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertProgramMutation, InsertProgramMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertProgramMutation, InsertProgramMutationVariables>(InsertProgramDocument, baseOptions);
    }
export type InsertProgramMutationHookResult = ReturnType<typeof useInsertProgramMutation>;
export type InsertProgramMutationResult = ApolloReactCommon.MutationResult<InsertProgramMutation>;
export type InsertProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertProgramMutation, InsertProgramMutationVariables>;
export const ListProgramsWithRevenueSharingDocument = gql`
    query ListProgramsWithRevenueSharing($organizationId: Int!) {
  listProgramsWithRevenueSharing(organizationId: $organizationId) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;

    export function useListProgramsWithRevenueSharingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListProgramsWithRevenueSharingQuery, ListProgramsWithRevenueSharingQueryVariables>) {
      return ApolloReactHooks.useQuery<ListProgramsWithRevenueSharingQuery, ListProgramsWithRevenueSharingQueryVariables>(ListProgramsWithRevenueSharingDocument, baseOptions);
    }
      export function useListProgramsWithRevenueSharingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListProgramsWithRevenueSharingQuery, ListProgramsWithRevenueSharingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ListProgramsWithRevenueSharingQuery, ListProgramsWithRevenueSharingQueryVariables>(ListProgramsWithRevenueSharingDocument, baseOptions);
      }
      
export type ListProgramsWithRevenueSharingQueryHookResult = ReturnType<typeof useListProgramsWithRevenueSharingQuery>;
export type ListProgramsWithRevenueSharingQueryResult = ApolloReactCommon.QueryResult<ListProgramsWithRevenueSharingQuery, ListProgramsWithRevenueSharingQueryVariables>;
export const SearchProgramsDocument = gql`
    query SearchPrograms($organizationId: Int, $term: String!) {
  searchPrograms(organizationId: $organizationId, term: $term) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;

    export function useSearchProgramsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchProgramsQuery, SearchProgramsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchProgramsQuery, SearchProgramsQueryVariables>(SearchProgramsDocument, baseOptions);
    }
      export function useSearchProgramsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProgramsQuery, SearchProgramsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchProgramsQuery, SearchProgramsQueryVariables>(SearchProgramsDocument, baseOptions);
      }
      
export type SearchProgramsQueryHookResult = ReturnType<typeof useSearchProgramsQuery>;
export type SearchProgramsQueryResult = ApolloReactCommon.QueryResult<SearchProgramsQuery, SearchProgramsQueryVariables>;
export const TestAccountWhitelistingsForProgramsDocument = gql`
    query TestAccountWhitelistingsForPrograms {
  testAccountWhitelistingsForPrograms {
    ...EmailAddressWhitelistingForProgram
  }
}
    ${EmailAddressWhitelistingForProgramFragmentDoc}`;

    export function useTestAccountWhitelistingsForProgramsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestAccountWhitelistingsForProgramsQuery, TestAccountWhitelistingsForProgramsQueryVariables>) {
      return ApolloReactHooks.useQuery<TestAccountWhitelistingsForProgramsQuery, TestAccountWhitelistingsForProgramsQueryVariables>(TestAccountWhitelistingsForProgramsDocument, baseOptions);
    }
      export function useTestAccountWhitelistingsForProgramsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestAccountWhitelistingsForProgramsQuery, TestAccountWhitelistingsForProgramsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<TestAccountWhitelistingsForProgramsQuery, TestAccountWhitelistingsForProgramsQueryVariables>(TestAccountWhitelistingsForProgramsDocument, baseOptions);
      }
      
export type TestAccountWhitelistingsForProgramsQueryHookResult = ReturnType<typeof useTestAccountWhitelistingsForProgramsQuery>;
export type TestAccountWhitelistingsForProgramsQueryResult = ApolloReactCommon.QueryResult<TestAccountWhitelistingsForProgramsQuery, TestAccountWhitelistingsForProgramsQueryVariables>;
export const UpdateProgramDocument = gql`
    mutation UpdateProgram($formData: ProgramFormData!) {
  updateProgram(formData: $formData) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;
export type UpdateProgramMutationFn = ApolloReactCommon.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>;

    export function useUpdateProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, baseOptions);
    }
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = ApolloReactCommon.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const WhitelistEmailAddressForProgramDocument = gql`
    mutation WhitelistEmailAddressForProgram($emailAddress: String!, $programId: String) {
  whitelistEmailAddressForProgram(emailAddress: $emailAddress, programId: $programId) {
    ...EmailAddressWhitelistingForProgram
  }
}
    ${EmailAddressWhitelistingForProgramFragmentDoc}`;
export type WhitelistEmailAddressForProgramMutationFn = ApolloReactCommon.MutationFunction<WhitelistEmailAddressForProgramMutation, WhitelistEmailAddressForProgramMutationVariables>;

    export function useWhitelistEmailAddressForProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WhitelistEmailAddressForProgramMutation, WhitelistEmailAddressForProgramMutationVariables>) {
      return ApolloReactHooks.useMutation<WhitelistEmailAddressForProgramMutation, WhitelistEmailAddressForProgramMutationVariables>(WhitelistEmailAddressForProgramDocument, baseOptions);
    }
export type WhitelistEmailAddressForProgramMutationHookResult = ReturnType<typeof useWhitelistEmailAddressForProgramMutation>;
export type WhitelistEmailAddressForProgramMutationResult = ApolloReactCommon.MutationResult<WhitelistEmailAddressForProgramMutation>;
export type WhitelistEmailAddressForProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<WhitelistEmailAddressForProgramMutation, WhitelistEmailAddressForProgramMutationVariables>;
export const DeleteReferralCodeDocument = gql`
    mutation DeleteReferralCode($id: Int!) {
  deleteReferralCode(id: $id)
}
    `;
export type DeleteReferralCodeMutationFn = ApolloReactCommon.MutationFunction<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>;

    export function useDeleteReferralCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>(DeleteReferralCodeDocument, baseOptions);
    }
export type DeleteReferralCodeMutationHookResult = ReturnType<typeof useDeleteReferralCodeMutation>;
export type DeleteReferralCodeMutationResult = ApolloReactCommon.MutationResult<DeleteReferralCodeMutation>;
export type DeleteReferralCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>;
export const GetReferralCodeDocument = gql`
    query GetReferralCode($id: Int!) {
  referralCode(id: $id) {
    ...ReferralCode
  }
}
    ${ReferralCodeFragmentDoc}`;

    export function useGetReferralCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
      return ApolloReactHooks.useQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, baseOptions);
    }
      export function useGetReferralCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, baseOptions);
      }
      
export type GetReferralCodeQueryHookResult = ReturnType<typeof useGetReferralCodeQuery>;
export type GetReferralCodeQueryResult = ApolloReactCommon.QueryResult<GetReferralCodeQuery, GetReferralCodeQueryVariables>;
export const InsertReferralCodeDocument = gql`
    mutation InsertReferralCode($organizationId: Int!, $formData: ReferralCodeFormData!) {
  insertReferralCode(organizationId: $organizationId, formData: $formData) {
    ...ReferralCode
  }
}
    ${ReferralCodeFragmentDoc}`;
export type InsertReferralCodeMutationFn = ApolloReactCommon.MutationFunction<InsertReferralCodeMutation, InsertReferralCodeMutationVariables>;

    export function useInsertReferralCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertReferralCodeMutation, InsertReferralCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertReferralCodeMutation, InsertReferralCodeMutationVariables>(InsertReferralCodeDocument, baseOptions);
    }
export type InsertReferralCodeMutationHookResult = ReturnType<typeof useInsertReferralCodeMutation>;
export type InsertReferralCodeMutationResult = ApolloReactCommon.MutationResult<InsertReferralCodeMutation>;
export type InsertReferralCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertReferralCodeMutation, InsertReferralCodeMutationVariables>;
export const SearchReferralCodesDocument = gql`
    query SearchReferralCodes($organizationId: Int!, $term: String!) {
  searchReferralCodes(organizationId: $organizationId, term: $term) {
    ...ReferralCode
  }
}
    ${ReferralCodeFragmentDoc}`;

    export function useSearchReferralCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchReferralCodesQuery, SearchReferralCodesQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchReferralCodesQuery, SearchReferralCodesQueryVariables>(SearchReferralCodesDocument, baseOptions);
    }
      export function useSearchReferralCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchReferralCodesQuery, SearchReferralCodesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchReferralCodesQuery, SearchReferralCodesQueryVariables>(SearchReferralCodesDocument, baseOptions);
      }
      
export type SearchReferralCodesQueryHookResult = ReturnType<typeof useSearchReferralCodesQuery>;
export type SearchReferralCodesQueryResult = ApolloReactCommon.QueryResult<SearchReferralCodesQuery, SearchReferralCodesQueryVariables>;
export const UpdateReferralCodeDocument = gql`
    mutation UpdateReferralCode($id: Int!, $formData: ReferralCodeFormData!) {
  updateReferralCode(id: $id, formData: $formData) {
    ...ReferralCode
  }
}
    ${ReferralCodeFragmentDoc}`;
export type UpdateReferralCodeMutationFn = ApolloReactCommon.MutationFunction<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>;

    export function useUpdateReferralCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>(UpdateReferralCodeDocument, baseOptions);
    }
export type UpdateReferralCodeMutationHookResult = ReturnType<typeof useUpdateReferralCodeMutation>;
export type UpdateReferralCodeMutationResult = ApolloReactCommon.MutationResult<UpdateReferralCodeMutation>;
export type UpdateReferralCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>;
export const FailWhenZeroDocument = gql`
    mutation FailWhenZero($argument: Int!) {
  failWhenZero(argument: $argument)
}
    `;
export type FailWhenZeroMutationFn = ApolloReactCommon.MutationFunction<FailWhenZeroMutation, FailWhenZeroMutationVariables>;

    export function useFailWhenZeroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FailWhenZeroMutation, FailWhenZeroMutationVariables>) {
      return ApolloReactHooks.useMutation<FailWhenZeroMutation, FailWhenZeroMutationVariables>(FailWhenZeroDocument, baseOptions);
    }
export type FailWhenZeroMutationHookResult = ReturnType<typeof useFailWhenZeroMutation>;
export type FailWhenZeroMutationResult = ApolloReactCommon.MutationResult<FailWhenZeroMutation>;
export type FailWhenZeroMutationOptions = ApolloReactCommon.BaseMutationOptions<FailWhenZeroMutation, FailWhenZeroMutationVariables>;
export const DeleteSchoolDocument = gql`
    mutation DeleteSchool($id: String!) {
  deleteSchool(id: $id)
}
    `;
export type DeleteSchoolMutationFn = ApolloReactCommon.MutationFunction<DeleteSchoolMutation, DeleteSchoolMutationVariables>;

    export function useDeleteSchoolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(DeleteSchoolDocument, baseOptions);
    }
export type DeleteSchoolMutationHookResult = ReturnType<typeof useDeleteSchoolMutation>;
export type DeleteSchoolMutationResult = ApolloReactCommon.MutationResult<DeleteSchoolMutation>;
export type DeleteSchoolMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>;
export const GetSchoolDocument = gql`
    query GetSchool($id: String!) {
  school(id: $id) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;

    export function useGetSchoolQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
    }
      export function useGetSchoolLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
      }
      
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolQueryResult = ApolloReactCommon.QueryResult<GetSchoolQuery, GetSchoolQueryVariables>;
export const GetSchoolStatsDocument = gql`
    query GetSchoolStats($id: String!) {
  schoolStats(id: $id) {
    schoolId
    migrationCount
    ambassadorCount
    accountActionStats {
      date
      action
      count
    }
  }
}
    `;

    export function useGetSchoolStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolStatsQuery, GetSchoolStatsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSchoolStatsQuery, GetSchoolStatsQueryVariables>(GetSchoolStatsDocument, baseOptions);
    }
      export function useGetSchoolStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolStatsQuery, GetSchoolStatsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetSchoolStatsQuery, GetSchoolStatsQueryVariables>(GetSchoolStatsDocument, baseOptions);
      }
      
export type GetSchoolStatsQueryHookResult = ReturnType<typeof useGetSchoolStatsQuery>;
export type GetSchoolStatsQueryResult = ApolloReactCommon.QueryResult<GetSchoolStatsQuery, GetSchoolStatsQueryVariables>;
export const InsertSchoolDocument = gql`
    mutation InsertSchool($formData: SchoolFormData!) {
  insertSchool(formData: $formData) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;
export type InsertSchoolMutationFn = ApolloReactCommon.MutationFunction<InsertSchoolMutation, InsertSchoolMutationVariables>;

    export function useInsertSchoolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertSchoolMutation, InsertSchoolMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertSchoolMutation, InsertSchoolMutationVariables>(InsertSchoolDocument, baseOptions);
    }
export type InsertSchoolMutationHookResult = ReturnType<typeof useInsertSchoolMutation>;
export type InsertSchoolMutationResult = ApolloReactCommon.MutationResult<InsertSchoolMutation>;
export type InsertSchoolMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertSchoolMutation, InsertSchoolMutationVariables>;
export const SearchSchoolsDocument = gql`
    query SearchSchools($term: String!) {
  searchSchools(term: $term) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;

    export function useSearchSchoolsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchSchoolsQuery, SearchSchoolsQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchSchoolsQuery, SearchSchoolsQueryVariables>(SearchSchoolsDocument, baseOptions);
    }
      export function useSearchSchoolsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSchoolsQuery, SearchSchoolsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchSchoolsQuery, SearchSchoolsQueryVariables>(SearchSchoolsDocument, baseOptions);
      }
      
export type SearchSchoolsQueryHookResult = ReturnType<typeof useSearchSchoolsQuery>;
export type SearchSchoolsQueryResult = ApolloReactCommon.QueryResult<SearchSchoolsQuery, SearchSchoolsQueryVariables>;
export const UpdateSchoolDocument = gql`
    mutation UpdateSchool($id: String!, $formData: SchoolFormData!) {
  updateSchool(id: $id, formData: $formData) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;
export type UpdateSchoolMutationFn = ApolloReactCommon.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

    export function useUpdateSchoolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, baseOptions);
    }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = ApolloReactCommon.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const DeleteThemeDocument = gql`
    mutation DeleteTheme($id: Int!) {
  deleteTheme(id: $id)
}
    `;
export type DeleteThemeMutationFn = ApolloReactCommon.MutationFunction<DeleteThemeMutation, DeleteThemeMutationVariables>;

    export function useDeleteThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteThemeMutation, DeleteThemeMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteThemeMutation, DeleteThemeMutationVariables>(DeleteThemeDocument, baseOptions);
    }
export type DeleteThemeMutationHookResult = ReturnType<typeof useDeleteThemeMutation>;
export type DeleteThemeMutationResult = ApolloReactCommon.MutationResult<DeleteThemeMutation>;
export type DeleteThemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteThemeMutation, DeleteThemeMutationVariables>;
export const GetThemeDocument = gql`
    query GetTheme($id: Int!) {
  theme(id: $id) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

    export function useGetThemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
      return ApolloReactHooks.useQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, baseOptions);
    }
      export function useGetThemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, baseOptions);
      }
      
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeQueryResult = ApolloReactCommon.QueryResult<GetThemeQuery, GetThemeQueryVariables>;
export const InsertThemeDocument = gql`
    mutation InsertTheme($organizationId: Int!, $formData: ThemeFormData!) {
  insertTheme(organizationId: $organizationId, formData: $formData) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;
export type InsertThemeMutationFn = ApolloReactCommon.MutationFunction<InsertThemeMutation, InsertThemeMutationVariables>;

    export function useInsertThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertThemeMutation, InsertThemeMutationVariables>) {
      return ApolloReactHooks.useMutation<InsertThemeMutation, InsertThemeMutationVariables>(InsertThemeDocument, baseOptions);
    }
export type InsertThemeMutationHookResult = ReturnType<typeof useInsertThemeMutation>;
export type InsertThemeMutationResult = ApolloReactCommon.MutationResult<InsertThemeMutation>;
export type InsertThemeMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertThemeMutation, InsertThemeMutationVariables>;
export const SearchThemesDocument = gql`
    query SearchThemes($organizationId: Int!, $term: String!) {
  searchThemes(organizationId: $organizationId, term: $term) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

    export function useSearchThemesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchThemesQuery, SearchThemesQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchThemesQuery, SearchThemesQueryVariables>(SearchThemesDocument, baseOptions);
    }
      export function useSearchThemesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchThemesQuery, SearchThemesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchThemesQuery, SearchThemesQueryVariables>(SearchThemesDocument, baseOptions);
      }
      
export type SearchThemesQueryHookResult = ReturnType<typeof useSearchThemesQuery>;
export type SearchThemesQueryResult = ApolloReactCommon.QueryResult<SearchThemesQuery, SearchThemesQueryVariables>;
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($id: Int!, $formData: ThemeFormData!) {
  updateTheme(id: $id, formData: $formData) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;
export type UpdateThemeMutationFn = ApolloReactCommon.MutationFunction<UpdateThemeMutation, UpdateThemeMutationVariables>;

    export function useUpdateThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateThemeMutation, UpdateThemeMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, baseOptions);
    }
export type UpdateThemeMutationHookResult = ReturnType<typeof useUpdateThemeMutation>;
export type UpdateThemeMutationResult = ApolloReactCommon.MutationResult<UpdateThemeMutation>;
export type UpdateThemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateThemeMutation, UpdateThemeMutationVariables>;
export const GetAmbassadorStatusDocument = gql`
    query GetAmbassadorStatus($userId: String!) {
  ambassadorStatus(userId: $userId) {
    ...AmbassadorStatus
  }
}
    ${AmbassadorStatusFragmentDoc}`;

    export function useGetAmbassadorStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAmbassadorStatusQuery, GetAmbassadorStatusQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAmbassadorStatusQuery, GetAmbassadorStatusQueryVariables>(GetAmbassadorStatusDocument, baseOptions);
    }
      export function useGetAmbassadorStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAmbassadorStatusQuery, GetAmbassadorStatusQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAmbassadorStatusQuery, GetAmbassadorStatusQueryVariables>(GetAmbassadorStatusDocument, baseOptions);
      }
      
export type GetAmbassadorStatusQueryHookResult = ReturnType<typeof useGetAmbassadorStatusQuery>;
export type GetAmbassadorStatusQueryResult = ApolloReactCommon.QueryResult<GetAmbassadorStatusQuery, GetAmbassadorStatusQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: String!) {
  getUser(userId: $userId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

    export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
    }
      export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
      
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserSummaryDocument = gql`
    query GetUserSummary($userId: String!) {
  getUserSummary(userId: $userId) {
    id
    firstName
    lastName
    emailAddress
  }
}
    `;

    export function useGetUserSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSummaryQuery, GetUserSummaryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserSummaryQuery, GetUserSummaryQueryVariables>(GetUserSummaryDocument, baseOptions);
    }
      export function useGetUserSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSummaryQuery, GetUserSummaryQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetUserSummaryQuery, GetUserSummaryQueryVariables>(GetUserSummaryDocument, baseOptions);
      }
      
export type GetUserSummaryQueryHookResult = ReturnType<typeof useGetUserSummaryQuery>;
export type GetUserSummaryQueryResult = ApolloReactCommon.QueryResult<GetUserSummaryQuery, GetUserSummaryQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($term: String!) {
  searchUsers(term: $term) {
    ...User
  }
}
    ${UserFragmentDoc}`;

    export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
    }
      export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
      
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const UpdateCustomerDetailsDocument = gql`
    mutation UpdateCustomerDetails($userId: String!, $customerDetails: String!) {
  updateCustomerDetails(userId: $userId, customerDetails: $customerDetails) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateCustomerDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>;

    export function useUpdateCustomerDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>(UpdateCustomerDetailsDocument, baseOptions);
    }
export type UpdateCustomerDetailsMutationHookResult = ReturnType<typeof useUpdateCustomerDetailsMutation>;
export type UpdateCustomerDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerDetailsMutation>;
export type UpdateCustomerDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>;
export const UpgradeUserDocument = gql`
    mutation UpgradeUser($userId: String!, $connectionId: String!) {
  upgradeUser(userId: $userId, connectionId: $connectionId) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpgradeUserMutationFn = ApolloReactCommon.MutationFunction<UpgradeUserMutation, UpgradeUserMutationVariables>;

    export function useUpgradeUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpgradeUserMutation, UpgradeUserMutationVariables>) {
      return ApolloReactHooks.useMutation<UpgradeUserMutation, UpgradeUserMutationVariables>(UpgradeUserDocument, baseOptions);
    }
export type UpgradeUserMutationHookResult = ReturnType<typeof useUpgradeUserMutation>;
export type UpgradeUserMutationResult = ApolloReactCommon.MutationResult<UpgradeUserMutation>;
export type UpgradeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpgradeUserMutation, UpgradeUserMutationVariables>;
}