import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const migrationId: string = "migrationId";
}

export class MigrationsRoutes {
  private readonly root = "m";

  constructor(private context: RoutesContext) {}

  public migrationStatusParams(): MigrationsRoutes.Params {
    const migrationId = this.context.routeParams[RouteParams.migrationId];
    if (migrationId) {
      return { migrationId };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get homeMask(): string {
    return this.root;
  }

  public get migrationStatusMask(): string {
    return this.root + "/:" + RouteParams.migrationId;
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public migrationStatusPath(migrationId: string): string {
    return this.context.resolvePath(this.root + "/" + migrationId);
  }
}

export namespace MigrationsRoutes {
  export interface Params {
    migrationId: string;
  }
}
