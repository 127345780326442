import * as React from "react";
import { Migration } from "../../types/models/migration";
import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { identity, nullToUndefined } from "../../utils/misc";
import {
  IterationLaunchControllerProps,
  IterationLaunchControllerType
} from "../../views/screens/migrationStatusPageView/components/iterationToolsRow";

type LaunchIterationHook = [
  () => Promise<GraphQL.JobStatusTransitionSchedulingResult>,
  OperationStatus<GraphQL.JobStatusTransitionSchedulingResult>
];

function useLaunchIteration(migrationId: string): LaunchIterationHook {
  const [fireWith, { status }] = useManagedMutation({
    mutation: GraphQL.useLaunchIterationMutation,
    extract: (data: GraphQL.LaunchIterationMutation) => nullToUndefined(data.launchIteration),
    complete: identity
  });

  function fire(): Promise<GraphQL.JobStatusTransitionSchedulingResult> {
    return fireWith({
      variables: { migrationId },
      retry: fire,
    });
  }

  return [fire, status];
}

interface Props extends IterationLaunchControllerProps {
  migration: Migration;
  onLaunch: (result: GraphQL.JobStatusTransitionSchedulingResult) => void;
}

export const IterationLaunchController: React.FunctionComponent<Props> = (props) => {
  const [launch, status] = useLaunchIteration(props.migration.id);

  return props.render({
    status,
    onLaunch: () => launch().then((result) => {
      props.onLaunch(result);
      return result;
    })
  });
};

export function useIterationLaunchController(
  migration: Migration,
  onLaunch: (result: GraphQL.JobStatusTransitionSchedulingResult) => void
): IterationLaunchControllerType {
  return React.useCallback(
    (controllerProps: IterationLaunchControllerProps) =>
      <IterationLaunchController {...controllerProps} migration={migration} onLaunch={onLaunch}/>,
    [migration, onLaunch]
  );
}
