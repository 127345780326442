import * as React from "react";
import { AnyWatcherAction } from "./anyWatcherAction";
import { WatchedMigrationNoteLists } from "./plugins/watchedMigrationNoteListsPlugin";
import { WatchedMigrationIssueLists } from "./plugins/watchedMigrationIssuesPlugin";

export const WatcherDispatchContext = React.createContext<React.Dispatch<AnyWatcherAction> | null>(null);

export function useWatcherDispatch(): React.Dispatch<AnyWatcherAction> {
  const dispatch = React.useContext(WatcherDispatchContext);
  if (dispatch) {
    return dispatch;
  } else {
    throw Error("Watcher is not initialized");
  }
}

interface WatcherHook {
  watchMigrationIssueLists: (migrationIds: Iterable<string>, replace: boolean, shouldRefresh: boolean) => void;

  watchMigrationNoteLists: (migrationIds: Iterable<string>, replace: boolean, shouldRefresh: boolean) => void;
}

export function useWatcher(): WatcherHook {
  const watcherDispatch = useWatcherDispatch();

  return {
    watchMigrationIssueLists: (migrationIds, replace, shouldRefresh) =>
      watcherDispatch(WatchedMigrationIssueLists.WatchMigrationIssueListsAction(migrationIds, replace, shouldRefresh)),

    watchMigrationNoteLists: (migrationIds, replace, shouldRefresh) =>
      watcherDispatch(WatchedMigrationNoteLists.WatchMigrationNoteListsAction(migrationIds, replace, shouldRefresh))
  };
}
