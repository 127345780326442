import { OperationStatus } from "../../types/operationStatus";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { MigrationNote } from "../../types/models/migrationNote";

type ResolveMigrationNoteMutationHook = [() => Promise<MigrationNote>, OperationStatus<MigrationNote>];

export function useResolveMigrationNoteMutation(noteId: number): ResolveMigrationNoteMutationHook {
  const [resolveMigrationNote, { status }] = useManagedMutation({
    mutation: GraphQL.useResolveMigrationNoteMutation,
    extract: (data: GraphQL.ResolveMigrationNoteMutation) => nullToUndefined(data.resolveMigrationNote),
    complete: (migrationNote) => MigrationNote.fromGraphQL(migrationNote)
  });

  function go(): Promise<MigrationNote> {
    return resolveMigrationNote({
      variables: { noteId },
      retry: go
    });
  }

  return [go, status];
}
