import { Option } from "../../utils/monads/option";
import { RoutesContext } from "./routesContext";

namespace RouteParams {
  export const path: string = "path";
}

export class WizardrySandboxRoutes {
  private readonly root = "wizardry";

  constructor(private context: RoutesContext) {}

  public params(): WizardrySandboxRoutes.Params {
    return { path: Option.mayBe(this.context.routeParams[RouteParams.path]) };
  }

  public get homeMask(): string {
    return this.root;
  }

  public get wizardPathMask(): string {
    return this.root + "/:" + RouteParams.path + "/*";
  }

  public get homePath(): string {
    return this.context.resolvePath(this.root);
  }

  public wizardPath(wizardPath: string): string {
    return this.context.resolvePath(this.root + (wizardPath.startsWith("/") ? "" : "/") + wizardPath);
  }
}

export namespace WizardrySandboxRoutes {
  export interface Params {
    path: Option<string>;
  }
}
