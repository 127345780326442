import * as React from "react";
import { StyleguideBlock, StyleguideHeader } from "./styleguideBlock";
import { Panel } from "../containers/panel";
import {
  IndicatorValue,
  Indicator,
  TimeIndicator,
  SizeIndicator,
  ItemCountIndicator,
  PriceIndicator, ItemCountAndSizeIndicator
} from "../widgets/indicator";
import { PanelRow } from "../containers/rows/panelRow";

export const DataIndicators: React.FunctionComponent = (props) => (
  <React.Fragment>
    <StyleguideHeader>Simple Data Indicators</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow>
          <Indicator title={"Single number"}>
            <IndicatorValue value={1234567890}/>
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"Single string"}>
            <IndicatorValue value={"This is a string"}/>
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"Number with prefix"}>
            <IndicatorValue value={28734.56} prefix={"$"}/>
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"Number with suffix"}>
            <IndicatorValue value={7} suffix={"days"}/>
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"Number with additional content in parenthetical"}>
            <IndicatorValue value={177654.76} prefix="$" parenthetical="This could be something meaningful"/>
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"All together"}>
            <IndicatorValue
              value={177654.76}
              prefix="$"
              suffix="bucks"
              parenthetical="This could be something meaningful too"
            />
          </Indicator>
        </PanelRow>
        <PanelRow>
          <Indicator title={"Multiple indicators grouped together"}>
            <IndicatorValue value={9873} suffix={"appetizers"}/>
            <IndicatorValue value={1234} suffix={"soups"}/>
            <IndicatorValue value={1134} suffix={"salads"}/>
            <IndicatorValue value={3948} suffix={"drinks"}/>
            <IndicatorValue value={3847} suffix={"deserts"}/>
            <IndicatorValue value={9238} suffix={"digestives"}/>
          </Indicator>
        </PanelRow>
      </Panel>
    </StyleguideBlock>

    <StyleguideHeader>Complex Data Indicators</StyleguideHeader>
    <StyleguideBlock>
      <Panel>
        <PanelRow>
          <TimeIndicator title={"Time"} time={8734617834}/>
        </PanelRow>
        <PanelRow>
          <SizeIndicator title={"Size"} size={8734617834}/>
        </PanelRow>
        <PanelRow>
          <ItemCountIndicator title={"Count"} count={7} unit={"elephant"}/>
        </PanelRow>
        <PanelRow>
          <ItemCountAndSizeIndicator title={"Count and Size"} count={7} unit={"elephant"} size={8734617834}/>
        </PanelRow>
        <PanelRow>
          <PriceIndicator title={"Price"} price={9.99} parenthetical={"This is really cheap!"}/>
        </PanelRow>
      </Panel>
    </StyleguideBlock>
  </React.Fragment>
);
