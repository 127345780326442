import * as React from "react";
import { StatusIndicators } from "../../../utils/statusIndicators";
import { OperationStatusIndicator } from "../../../utils/operationStatusIndicator";
import { Button } from "../../../widgets/button";
import { ModalController, ModalHook } from "../../../layouts/modal";
import {
  AdvanceToNextEmailDeliveryRoundController
} from "../../../../controllers/organizationPortal/automatedOffboarding/advanceToNextEmailDeliveryRoundController";
import { ControllerHost } from "../../../../utils/controller";
import { OffboardingProject } from "../../../../types/models/offboardingProject";
import { DrawerBlock } from "views/containers/drawerBlock";

interface Props {
  offboardingProject: OffboardingProject;
  controller: AdvanceToNextEmailDeliveryRoundController;
  modal: ModalHook;
}

export const AdvanceToNextEmailDeliveryRoundTool: React.FunctionComponent<Props> = (props) => (
  <ModalController
    modal={props.modal}
    title={"Start The Next Round"}
    render={(close) =>
      <ControllerHost
        controller={props.controller}
        render={(viewProps) => {
          if (
            !props.offboardingProject.isEmailSendingInProgress &&
            !props.offboardingProject.isCompleted &&
            props.offboardingProject.nextRound !== undefined
          ) {
            const expectedNextRound = props.offboardingProject.nextRound;

            return (
              <>
                <DrawerBlock>
                  Click the button below to immediately start the next round of emailing according to the
                  project schedule.
                </DrawerBlock>
                <OperationStatusIndicator
                  progressMessage={"Starting emailing..."}
                  failureMessage={"Failed to start emailing"}
                  status={viewProps.status}
                  indicators={StatusIndicators.SimplePanel()}
                />
                <DrawerBlock>
                  <Button
                    color={"primary"}
                    size={"narrow"}
                    onClick={() => viewProps.onSubmit(expectedNextRound).then(close)}
                    disabled={viewProps.status.isWorking()}
                  >
                    Start The Next Round
                  </Button>
                </DrawerBlock>
              </>
            );
          } else {
            return (
              <>
                <DrawerBlock>
                  This tool can be used to immediately start the next round of emailing according to the
                  project schedule. It cannot be used while emailing is already in progress or after the project
                  is completed.
                </DrawerBlock>
                <DrawerBlock>
                  <Button color={"primary"} size={"narrow"} onClick={close}>Close</Button>
                </DrawerBlock>
              </>
            );
          }
        }}
      />
    }
  />
);
