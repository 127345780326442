import * as React from "react";
import {
  useInsertOrganizationPortalUsersMutation
} from "../../../queries/organizationPortalUsers/useInsertOrganizationPortalUsersMutation";
import { OrganizationSummary } from "../../../types/models/organization";
import {
  BulkInsertOrganizationPortalUsersPageView
} from "../../../views/screens/organizationPortal/users/bulkInsertOrganizationPortalUsersPageView";
import { useRoutes } from "../../../app/routes/useRoutes";

interface Props {
  organization: OrganizationSummary;
}

export const BulkInsertOrganizationPortalUsersPage: React.FunctionComponent<Props> = (props) => {
  const routes = useRoutes();

  const [insert, status] = useInsertOrganizationPortalUsersMutation(props.organization.id);

  return (
    <BulkInsertOrganizationPortalUsersPageView
      homePath={routes.organizationPortalRoutes.users(props.organization.alias).homePath}
      status={status}
      onInsert={insert}
    />
  );
};
