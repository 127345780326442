import * as React from "react";
import { BlueprintContext } from "../blueprintContext";
import { AnyRelationship } from "../relationship";
import { ComponentPropertiesTable } from "./componentPropertiesTable";
import { ComponentSummary } from "./componentSummary";
import { RelationshipPropertiesTable } from "./relationshipPropertiesTable";
import { SubSection } from "./subSection";
import { BlueprintDiff } from "../blueprintDiff";

interface ComponentRelationshipProps {
  relationship: AnyRelationship;
  blueprintContext: BlueprintContext;
  diffs: BlueprintDiff;
  onComponentSelect: (componentId: string) => void;
}

export const ComponentRelationship: React.SFC<ComponentRelationshipProps> = (props) => {
  const component = props.relationship.component();
  const componentSummary = component && (
    <ComponentSummary
      component={component}
      blueprintContext={props.blueprintContext}
      showOutput={true}
      showArrow={true}
      onComponentSelect={props.onComponentSelect}
    />
  );
  const componentProps = component && (
    <ComponentPropertiesTable
      component={component}
      blueprintContext={props.blueprintContext}
      diffs={props.diffs}
      showOutput={true}
    />
  );
  return (
    <SubSection>
      {componentSummary}
      <RelationshipPropertiesTable
        relationship={props.relationship}
        blueprintContext={props.blueprintContext}
        diffs={props.diffs}
        showOutput={true}
      />
      {componentProps}
    </SubSection>
  );
};
