import * as React from "react";
import {
  FactsManagementToolView
} from "../../views/blocks/factsManagement/factsManagementToolView";
import { Facts } from "../../types/facts/facts";
import { BlueprintInputs } from "../../blueprints/blueprintInputs";
import { useManagedMutation } from "../../services/graphql/useManagedMutation";
import { GraphQL } from "../../services/graphql/generated";
import { List, Set } from "immutable";
import { useManagedQuery } from "../../services/graphql/useManagedQuery";
import { Scan } from "../../types/models/scan";
import {
  ScansManagementToolMode,
  ScansManagementToolView
} from "../../views/blocks/scansManagement/scansManagementToolView";
import { DrawerBlock } from "../../views/containers/drawerBlock";
import { StopScanToolControllerProps } from "../../views/blocks/scansManagement/scansGrid";
import { useAbortJobManagedMutation } from "../jobManagement/useAbortJobManagedMutation";
import { useRefreshingDataSource } from "../../views/utils/refreshingDataSource";
import { FactManagementControllerProps } from "../../views/blocks/factsManagement/factsGrid";
import { usePutFactMutation } from "./usePutFactMutation";
import { useInvalidateFactMutation } from "./useInvalidateFactMutation";
import { Connections } from "../../types/models/connections";

interface Props {
  facts: Facts;
  connections: Connections;
  sourceCloudServiceId: string;
  destinationCloudServiceId: string;
  blueprintInputs: BlueprintInputs;
}

export const FactsAndScansManagementTool: React.FunctionComponent<Props> = (props) => {
  const limit = 20;
  const [scansToolMode, setScansToolMode] = React.useState<ScansManagementToolMode>(ScansManagementToolMode.Running);

  function queryVariables(mode: ScansManagementToolMode): GraphQL.ScansQueryVariables {
    return {
      connectionIds: props.connections.list().toArray().map((connection) => connection.id),
      includeCompleted: mode !== ScansManagementToolMode.Running,
      limit: mode === ScansManagementToolMode.Recent ? limit : undefined
    };
  }

  const dataSource = useRefreshingDataSource(
    useManagedQuery({
      query: GraphQL.useScansQuery,
      deps: null,
      prepare: () => queryVariables(scansToolMode),
      extract: (data: GraphQL.ScansQuery) => data.scans,
      complete: (scans) => List(scans.map(Scan.parse))
    }),
    { timeout: scansToolMode === ScansManagementToolMode.Running ? 10 : 60 }
  );

  const [reScan, { status: reScanStatus }] = useManagedMutation({
    mutation: GraphQL.useRefreshBlueprintFactsMutation,
    extract: (data: GraphQL.RefreshBlueprintFactsMutation) => data.refreshBlueprintFacts,
    complete: (extract) => List(extract.scanJobIds)
  });

  return (
    <>
      <DrawerBlock>
        <ScansManagementToolView
          mode={scansToolMode}
          limit={limit}
          connections={props.connections}
          dataSource={dataSource}
          stopScanToolController={StopScanToolController}
          onModeChange={(mode) => {
            setScansToolMode(mode);
            dataSource.refreshWith(queryVariables(mode), true);
          }}
        />
      </DrawerBlock>
      <DrawerBlock>
        <FactsManagementToolView
          facts={props.facts}
          reScanStatus={reScanStatus}
          factManagementController={FactManagementController}
          onReScan={(factIds) => {
            reScan({
              variables: {
                factIds: factIds.toArray(),
                sourceCloudServiceId: props.sourceCloudServiceId,
                destinationCloudServiceId: props.destinationCloudServiceId,
                blueprintInputs: props.blueprintInputs.toGraphQL()
              }
            }).then(dataSource.refresh);
          }}
        />
      </DrawerBlock>
    </>
  );
};

const StopScanToolController: React.FunctionComponent<StopScanToolControllerProps> = (props) => {
  const [stop, stopStatus] = useAbortJobManagedMutation();
  return props.render(() => stop(props.scan.jobId), stopStatus);
};

const FactManagementController: React.FunctionComponent<FactManagementControllerProps> = (props) => {
  const [update, updateStatus, resetUpdate] = usePutFactMutation(props.fact);
  const [invalidate, invalidationStatus, resetInvalidation] = useInvalidateFactMutation(props.fact);

  return props.render({
    updateStatus,
    onUpdate: update,
    resetUpdate,

    invalidationStatus,
    onInvalidation: invalidate,
    resetInvalidation
  });
};
