export enum OrganizationPortalUserRole {
  ReadAccess = "ReadAccess",
  ReadAndWriteAccess = "ReadAndWriteAccess",
  Administrator = "Administrator",
  Owner = "Owner"
}

export namespace OrganizationPortalUserRole {
  export const all: OrganizationPortalUserRole[] = [
    OrganizationPortalUserRole.ReadAccess,
    OrganizationPortalUserRole.ReadAndWriteAccess,
    OrganizationPortalUserRole.Administrator,
    OrganizationPortalUserRole.Owner
  ];

  export function userFriendly(role: OrganizationPortalUserRole): string {
    switch (role) {
      case OrganizationPortalUserRole.ReadAccess: return "Read access";
      case OrganizationPortalUserRole.ReadAndWriteAccess: return "Read and write access";
      case OrganizationPortalUserRole.Administrator: return "Administrator";
      case OrganizationPortalUserRole.Owner: return "Owner";
    }
  }

  export function rank(role: OrganizationPortalUserRole): number {
    switch (role) {
      case OrganizationPortalUserRole.ReadAccess: return 0;
      case OrganizationPortalUserRole.ReadAndWriteAccess: return 1;
      case OrganizationPortalUserRole.Administrator: return 2;
      case OrganizationPortalUserRole.Owner: return 3;
    }
  }

  export function implies(role: OrganizationPortalUserRole, requiredRole: OrganizationPortalUserRole): boolean {
    return rank(role) >= rank(requiredRole);
  }
}
