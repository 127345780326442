import { SignInDefs } from "../views/blocks/auth/signInDefs";
import { Controller, ControllerProps } from "../utils/controller";
import { SignInFlowState, useSignInFlow } from "../components/auth/useSignInFlow";
import { Connection } from "../types/models/connection";
import * as React from "react";
import { SignInContextType } from "../types/models/signInContextType";
import { Set } from "immutable";
import { Constants } from "../app/constants";

export interface EnableAuthRolesViewProps {
  state: SignInFlowState;
  onSignIn: SignInDefs.SignInHandle;
}

export type EnableAuthRolesController = Controller<EnableAuthRolesViewProps>;

interface DefaultEnableAuthRolesControllerProps extends ControllerProps<EnableAuthRolesViewProps> {
  connection: Connection;
  roles: string[];
  onSuccess?: () => void;
}

const DefaultEnableAuthRolesController: React.FunctionComponent<DefaultEnableAuthRolesControllerProps> = (props) => {
  const signInFlow = useSignInFlow({
    flowId: "EnableAuthRoles",
    contextType: SignInContextType.Increment,
    newUserSettings: undefined,
    defaultRoles: (props.connection.roles || Set()).add(Constants.Auth.IdentificationRole).concat(Set(props.roles)),
    existingConnection: props.connection,
    onSignIn: props.onSuccess
  });

  return props.render({
    state: signInFlow.state,
    onSignIn: signInFlow.handleSignInSuccess
  });
};

export function useEnableAuthRolesController(
  params: { connection: Connection, roles: string[], onSuccess?: () => void }
) {
  return React.useCallback(
    (controllerProps: ControllerProps<EnableAuthRolesViewProps>) => (
      <DefaultEnableAuthRolesController {...controllerProps} {...params}/>
    ),
    [params.connection, params.roles, params.onSuccess]
  );
}
