import * as React from "react";
import { ButtonColor } from "../views/widgets/button";
import { prettyPrint } from "../utils/formatting";
import { ErrorClass } from "../services/graphql/errorClass";
import { nullToUndefined } from "../utils/misc";

function enrich<T>(currentValue: T | null | undefined, suggestedValue: T | undefined): T | null | undefined {
  return currentValue === undefined ? suggestedValue : currentValue;
}

export class UserFacingError implements UserFacingError.Props {
  constructor(
    public readonly cause: any,
    protected readonly props: UserFacingError.Props
  ) {
  }

  public get title(): React.ReactNode | undefined { return nullToUndefined(this.props.title); }
  public get summary(): React.ReactNode | undefined { return nullToUndefined(this.props.summary); }
  public get recommendations(): React.ReactNode | undefined { return nullToUndefined(this.props.recommendations); }
  public get contactSupport(): boolean | undefined { return nullToUndefined(this.props.contactSupport); }

  public get technicalDetails(): UserFacingError.TechnicalDetails | undefined {
    return nullToUndefined(this.props.technicalDetails);
  }

  public get showTechnicalDetails(): boolean | undefined { return nullToUndefined(this.props.showTechnicalDetails); }

  public get retryTitle(): string | undefined { return nullToUndefined(this.props.retryTitle); }
  public get retry(): (() => void) | undefined { return nullToUndefined(this.props.retry); }

  public get cancelTitle(): string | undefined { return nullToUndefined(this.props.cancelTitle); }
  public get cancel(): (() => void) | undefined { return nullToUndefined(this.props.cancel); }

  public get actions(): UserFacingError.Action[] | undefined { return nullToUndefined(this.props.actions); }

  public toLogString(): string {
    if (typeof this.summary === "string") {
      return this.summary;
    } else if (this.cause && this.cause.hasOwnProperty("message")) {
      return this.cause.message;
    } else {
      return "Unknown error";
    }
  }

  public allTechnicalDetails(): UserFacingError.TechnicalDetails | undefined {
    function withDefaultDetails(
      error: any,
      providedDetails: UserFacingError.TechnicalDetails | undefined | null,
      ...additionalDetails: UserFacingError.TechnicalDetails
    ): UserFacingError.TechnicalDetails {
      const json = prettyPrint(error);
      if (json) {
        return (providedDetails || [])
          .concat(additionalDetails)
          .concat([
            {
              title: "Client-side type",
              content: typeof error === "object" ? error.constructor.name : typeof error,
              preFormatted: false
            },
            {
              title: "Server-side class",
              content: ErrorClass.extract(error) || "unknown",
              preFormatted: false
            },
            {
              title: "Error object",
              content: json,
              preFormatted: true
            },
          ]);
      } else {
        return (providedDetails || []).concat(additionalDetails);
      }
    }

    if (this.cause) {
      if (this.cause.hasOwnProperty("message")) {
        return withDefaultDetails(
          this.cause,
          this.props.technicalDetails,
          { title: "Message", content: this.cause.message, preFormatted: false }
        );
      } else {
        return withDefaultDetails(this.cause, this.props.technicalDetails);
      }
    } else {
      return nullToUndefined(this.props.technicalDetails);
    }
  }

  public enrich(props: Partial<UserFacingError.Props>): UserFacingError {
    return new UserFacingError(this.cause, {
      title: enrich(this.props.title, props.title),
      summary: enrich(this.props.summary, props.summary),
      recommendations: enrich(this.props.recommendations, props.recommendations),
      contactSupport: enrich(this.props.contactSupport, props.contactSupport),

      technicalDetails: enrich(this.props.technicalDetails, props.technicalDetails),
      showTechnicalDetails: enrich(this.props.showTechnicalDetails, props.showTechnicalDetails),

      retryTitle: enrich(this.props.retryTitle, props.retryTitle),
      retry: enrich(this.props.retry, props.retry),

      cancelTitle: enrich(this.props.cancelTitle, props.cancelTitle),
      cancel: enrich(this.props.cancel, props.cancel),

      actions: enrich(this.props.actions, props.actions),
    });
  }
}

export namespace UserFacingError {
  export const BadActionTitle = "Oops! This will not work.";

  export interface Props {
    readonly title: React.ReactNode | undefined | null;
    readonly summary: React.ReactNode | undefined | null;
    readonly recommendations: React.ReactNode | undefined | null;
    readonly contactSupport: boolean | undefined | null;

    readonly technicalDetails: UserFacingError.TechnicalDetails | undefined | null;
    readonly showTechnicalDetails: boolean | undefined | null;

    readonly retryTitle: string | undefined | null;
    readonly retry: (() => void) | undefined | null;

    readonly cancelTitle: string | undefined | null;
    readonly cancel: (() => void) | undefined | null;

    readonly actions: UserFacingError.Action[] | undefined | null;
  }

  export function enrichablePropsBase(): Props {
    return {
      title: undefined,
      summary: undefined,
      recommendations: undefined,
      contactSupport: undefined,

      technicalDetails: undefined,
      showTechnicalDetails: undefined,

      retryTitle: undefined,
      retry: undefined,

      cancelTitle: undefined,
      cancel: undefined,

      actions: undefined
    };
  }

  // Only actions can be enriched
  function fixedPropsBase(): Props {
    return {
      title: null,
      summary: null,
      recommendations: null,
      contactSupport: null,

      technicalDetails: null,
      showTechnicalDetails: null,

      retryTitle: undefined,
      retry: undefined,

      cancelTitle: undefined,
      cancel: undefined,

      actions: undefined
    };
  }

  export function unexpected(error: any, props: Partial<UserFacingError.Props> = {}): UserFacingError {
    if (error instanceof UserFacingError) {
      return error.enrich(props);
    } else {
      return new UserFacingError(error, {
        ...enrichablePropsBase(),
        ...props
      });
    }
  }

  export function expected(error: any, props: Partial<UserFacingError.Props>): UserFacingError {
    if (error instanceof UserFacingError) {
      return error;
    } else {
      return new UserFacingError(error, {
        ...fixedPropsBase(),
        contactSupport: false,
        showTechnicalDetails: false,
        ...props
      });
    }
  }

  export function synthetic(props: Partial<UserFacingError.Props>): UserFacingError {
    return new UserFacingError(undefined, {
      ...fixedPropsBase(),
      contactSupport: false,
      showTechnicalDetails: false,
      ...props
    });
  }

  export interface ButtonAction {
    title: string;
    onClick: () => void;
    color?: ButtonColor;
  }

  export type Action = ButtonAction  | JSX.Element;

  export function isButtonAction(action: Action): action is ButtonAction {
    return action.hasOwnProperty("title");
  }

  export interface TechnicalDetailsItem {
    title: string;
    content: string | undefined;
    preFormatted: boolean;
  }

  export type TechnicalDetails = TechnicalDetailsItem[];
}
