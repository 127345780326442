import * as React from "react";
import { SchoolSummary } from "../../../../types/models/school";
import { useAppBootstrapConfig } from "../../../../app/configuration";
import { Step } from "../../../containers/step";
import { AmbassadorNavigation } from "./ambassadorNavigation";
import { styled } from "../../../../app/theme";
import { Block } from "views/containers/block";
import { NewTabLink } from "../../../widgets/newTabLink";
import { Constants } from "../../../../app/constants";

interface Props {
  school: SchoolSummary;
  onProceed: () => void;
  onSkip: () => void;
  onNavigateBack?: () => void;
}

export const AmbassadorOfferStepView: React.FunctionComponent<Props> = (props) => {
  const appBootstrapConfig = useAppBootstrapConfig();

  const signUpDiscount = appBootstrapConfig.ambassadorsProgram.signUpDiscount;
  const codeDiscount = appBootstrapConfig.ambassadorsProgram.codeDiscount;

  return (
    <Step
      breadcrumbs={"EDU Ambassador Offer"}
      title={`Get $${signUpDiscount} off now & win an iPad by sharing your experience`}
      subTitle={
        <>
          Score big by helping your friends learn how VaultMe can save their {props.school.commonName || "school"}{" "}
          content before their accounts are deleted!
          Follow two easy steps to tell some friends and snag your immediate ${signUpDiscount} discount.
          Plus, every friend you help gets {codeDiscount}% off their EDU Migration Fee and gets you another shot
          at our monthly iPad giveaway (no purchase necessary;{" "}
          <NewTabLink to={Constants.Product.eduAmbassadorTermsUrl}>terms & conditions</NewTabLink> apply).
          {props.school.commonName ? " Go " + props.school.commonName + "!" : ""}
        </>
      }
      noForm={true}
      submitWidget={(
        <AmbassadorNavigation
          submitTitle={["OK, I'm Willing", "to Check It Out"]}
          hintText={"Don't worry, you can switch your option on the next screen!"}
          disabled={false}
          onSubmit={props.onProceed}
          onSkip={props.onSkip}
        />
      )}
      onNavigateBack={props.onNavigateBack}
    >
      <Block>
        <Bubbles>
          <Bubble>
            <Message>
              "I only knew about VaultMe through a friend, and so glad I found out!!!!!
              My university should be promoting this to all grads!"
            </Message>
            <Name>Alexander M., Stanford University Grad</Name>
          </Bubble>
          <Bubble>
            <Message>
              "I didn't know about VaultMe until the very last minute. I wish my college had sent reminders
              about losing access to my school account, and offered VaultMe instead of tools that don't work at all."
            </Message>
            <Name>J.N., Cornell University Grad</Name>
          </Bubble>
        </Bubbles>
      </Block>
    </Step>
  );
};

const Bubbles = styled.div`
  display: flex;

  ${(props) => props.theme.responsive.respondToXSmall()} {
    flex-direction: column;
  }
`;

const Bubble = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  padding: 2rem;
  max-width: 20rem;
  margin: 0 1rem 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;

    left: 50px;
    bottom: -39px;
    border: 20px solid;
    border-color: ${(props) => props.theme.colors.white} transparent transparent ${(props) => props.theme.colors.white};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    max-width: inherit;
    margin: 0 0 3rem 0;

    &:after {
      bottom: -29px;
      border-width: 15px;
    }
  }
`;

const Message = styled.div`
  font-style: italic;
  font-weight: ${(props) => props.theme.font.medium};
  font-size: 1.1rem;
`;

const Name = styled.div`
  margin-top: 1rem;
  font-weight: ${(props) => props.theme.font.medium};
  color: ${(props) => props.theme.colors.primary};
  font-size: 0.9rem;
`;
