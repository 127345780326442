import * as React from "react";
import { Panel } from "../../containers/panel";
import { CloudServiceChooserRow } from "./cloudServiceChooserRow";
import { AuthContext } from "../authContext";
import { TitlePanelRow } from "../../containers/rows/titlePanelRow";
import { CloudServices } from "../../../types/models/cloudServices";

interface CloudServiceChooserPanelProps {
  authContext: AuthContext;
  cloudServices: CloudServices;
  selectedCloudServiceId: string | undefined;
  onCloudServiceSelect: (cloudServiceId: string) => void;
}

export const CloudServiceChooserPanel: React.FunctionComponent<CloudServiceChooserPanelProps> = (props) => (
  <Panel>
    <TitlePanelRow>Migrate {props.authContext === AuthContext.Source ? "from" : "to"}</TitlePanelRow>
    <CloudServiceChooserRow {...props}/>
  </Panel>
);
