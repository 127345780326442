import * as React from "react";
import { WidgetStatus } from "../../utils/widgetStatus";

export interface CellContent {
  content: React.ReactNode;
  status: WidgetStatus | undefined;
}

export namespace CellContent {
  export function isCellContent(value: any): value is CellContent {
    return value && typeof value === "object" && value.hasOwnProperty("content");
  }
}

export interface ColumnConfig<Item> {
  title: React.ReactNode;
  render: (item: Item) => React.ReactNode | CellContent;
}
