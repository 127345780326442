import * as React from "react";
import { useRoutes } from "../../app/routes/useRoutes";
import { Configuration } from "../../types/models/configuration";
import { CrudPages } from "../../components/crud/crudPages";
import { friendlyDateTime, multiline } from "../../utils/formatting";
import { useSearchConfigurationsQuery } from "../../queries/configurations/useSearchConfigurationsQuery";
import { useDeleteConfigurationMutation } from "../../queries/configurations/useDeleteConfigurationMutation";
import { useUpdateConfigurationMutation } from "../../queries/configurations/useUpdateConfigurationMutation";
import { useInsertConfigurationMutation } from "../../queries/configurations/useInsertConfigurationMutation";
import { useGetConfigurationQuery } from "../../queries/configurations/useGetConfigurationQuery";
import { ConfigurationForm } from "../../views/screens/organizationPortal/configurations/configurationForm";
import { ConfigurationFormData } from "../../types/models/configurationFormData";
import {
  useOrganizationThemeLookupControllerFactory
} from "../../components/lookup/themes/useOrganizationThemeLookupControllerFactory";
import { useThemeDetailsController } from "../../components/lookup/themes/useThemeDetailsController";
import { useFileUploadController } from "../../queries/api/useFileUploadController";
import {
  useOrganizationThemeViewPathFactory
} from "../../components/lookup/themes/useOrganizationThemeViewPathFactory";
import { CRUDConfig } from "../../components/crud/crudConfig";
import { OrganizationSummary } from "../../types/models/organization";
import {
  ThemeLookupControllerType,
  ThemeViewPathFactory
} from "../../views/blocks/lookups/themes/themeLookupField";
import { useElevated } from "../../utils/useAppState";

interface Props extends Pick<
  CRUDConfig<number, Configuration, ConfigurationFormData>,
  "startFrom" | "layout" | "useRoutes" | "useSearchQuery" | "useInsertMutation"
  > {
  themeLookupController: ThemeLookupControllerType;
  themeViewPathFactory: ThemeViewPathFactory;
}

export const ConfigurationAdminPages: React.FunctionComponent<Props> = (props) => {
  const elevated = useElevated();

  const themeDetailsController = useThemeDetailsController();

  const fileUploadController = useFileUploadController();

  return CrudPages<number, Configuration, ConfigurationFormData>({
    title: "EDU Configurations",
    subject: "configuration",
    readOnly: !elevated,
    startFrom: props.startFrom,
    layout: props.layout,
    gridColumns: [
      {
        title: "Name",
        render: (configuration) => configuration.name
      },
      {
        title: "URL-Friendly Alias",
        render: (configuration) => configuration.alias
      },
      {
        title: "Created",
        render: (configuration) => friendlyDateTime(configuration.createdAt)
      },
    ],
    renderForm: (config) => (
      <ConfigurationForm
        config={config}

        themeLookupController={props.themeLookupController}
        themeDetailsController={themeDetailsController}
        themeViewPathFactory={props.themeViewPathFactory}

        fileUploadController={fileUploadController}
      />
    ),

    useRoutes: () => props.useRoutes(),
    getItemId: (configuration) => configuration.id,
    getItemTitle: (configuration) => configuration.name,
    clone: (configuration) => ({
      ...configuration,
      alias: "copy-of-" + configuration.alias,
      name: "Copy of " + configuration.name
    }),
    compare: (a: Configuration, b: Configuration) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    },

    useGetQuery: useGetConfigurationQuery,
    useSearchQuery: props.useSearchQuery,
    useInsertMutation: props.useInsertMutation,
    useUpdateMutation: useUpdateConfigurationMutation,
    useDeleteMutation: useDeleteConfigurationMutation
  });
};

interface OrganizationConfigurationAdminPagesProps {
  organization: OrganizationSummary;
}

export const OrganizationConfigurationAdminPages: React.FunctionComponent<OrganizationConfigurationAdminPagesProps> =
  (props) => {
    const themeLookupController = useOrganizationThemeLookupControllerFactory()(props.organization.id);
    const themeViewPathFactory = useOrganizationThemeViewPathFactory()(props.organization.id);

    return (
      <ConfigurationAdminPages
        startFrom={"list"}
        layout={"compact"}

        useRoutes={() => useRoutes().organizationPortalRoutes.configurations(props.organization.alias)}
        useSearchQuery={(term) => useSearchConfigurationsQuery(props.organization.id, term)}
        useInsertMutation={() => useInsertConfigurationMutation(props.organization.id)}

        themeLookupController={themeLookupController}
        themeViewPathFactory={themeViewPathFactory}
      />
    );
  };
