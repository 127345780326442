import * as React from "react";
import { Set } from "immutable";
import { Connection } from "../../types/models/connection";
import { AuthProvider } from "../../types/models/authProvider";
import { useAccessKeyCheck } from "./useAccessKeyCheck";
import { AccessKeyCheckToolView } from "../../views/blocks/connectionManagement/accessKeyCheckToolView";

interface AccessKeyCheckToolProps {
  connection: Connection;
  authProvider: AuthProvider;
}

export const AccessKeyCheckTool: React.FunctionComponent<AccessKeyCheckToolProps> = (props) => {
  const [roles, setRoles] = React.useState(props.connection.roles || Set());
  const [check, status] = useAccessKeyCheck(props.connection.id, roles);

  return (
    <AccessKeyCheckToolView
      connection={props.connection}
      authProvider={props.authProvider}

      roles={roles}
      onRolesSelect={setRoles}

      onCheck={check}
      status={status}
    />
  );
};
