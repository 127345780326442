import { ManagedQueryHook, useManagedQuery } from "../../services/graphql/useManagedQuery";
import { GraphQL } from "../../services/graphql/generated";
import { nullToUndefined } from "../../utils/misc";
import { OrganizationContext } from "../../types/models/organizationContext";

export function useGetOrganizationContextQuery(
  idOrAlias: string
): ManagedQueryHook<GraphQL.GetOrganizationContextQueryVariables, OrganizationContext> {
  return useManagedQuery({
    query: GraphQL.useGetOrganizationContextQuery,
    deps: null,
    prepare: () => ({ idOrAlias }),
    extract: (data: GraphQL.GetOrganizationContextQuery) => nullToUndefined(data.organizationContext),
    complete: (organizationContext) => OrganizationContext.parse(organizationContext)
  });
}
