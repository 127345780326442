import * as React from "react";
import { PanelRow } from "../../../containers/rows/panelRow";
import { Paragraph } from "../../../widgets/paragraph";
import { DoNotMoveFoldersWarning } from "./doNotMoveFoldersWarning";
import { CloudService } from "../../../../types/models/cloudService";
import { PreparedHelpArticle } from "../../../../utils/preparedHelpArticle";
import { HelpArticles } from "../../../../app/helpArticles";
import { useDrawer } from "../../../layouts/drawer";
import { useCloudServices } from "../../../../app/configuration";
import { WidgetStatus } from "../../../utils/widgetStatus";
import { Button } from "../../../widgets/button";

interface Props {
  sourceCloudService: CloudService;
  destinationCloudService: CloudService;
}

export const MigrationDelaysPanel: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const drawer = useDrawer();

  return (
    <>
      <PanelRow status={WidgetStatus.Warning}>
        <Paragraph>
          Your migration is delayed because your source and destination accounts are not performing fast enough.
          VaultMe is continuously checking the status of your accounts and attempting to reach the maximum migration
          speed.
        </Paragraph>
        <DoNotMoveFoldersWarning/>
        <Paragraph>
          <Button
            size={"small"}
            onClick={() => drawer.open(
              PreparedHelpArticle.fromExternal(
                HelpArticles.experiencingDelaysEx(
                  cloudServices,
                  props.sourceCloudService.id,
                  props.destinationCloudService.id
                )
              )
            )}
          >
            Learn more
          </Button>
        </Paragraph>
      </PanelRow>
    </>
  );
};
