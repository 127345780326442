import * as React from "react";
import { styled } from "../../app/theme";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FormField } from "./formField";
import { LookupControllerType, LookupModal } from "../blocks/lookupModal";
import { useModal } from "../layouts/modal";
import { Button } from "./button";
import { SimpleToolbar } from "./simpleToolbar";
import { Characters } from "../characters";
import { OperationStatus } from "../../types/operationStatus";
import { ErrorInfo } from "./errorInfo";
import { ColumnConfig } from "../blocks/crud/columnConfig";
import { FormErrorMessage } from "./formErrorMessage";

export interface ItemDetailsViewProps<Id, Item> {
  status: OperationStatus<Item>;
}

export interface ItemDetailsControllerProps<Id, Item> {
  id: Id;
  render: (viewProps: ItemDetailsViewProps<Id, Item>) => React.ReactElement;
}

export type ItemDetailsControllerType<Id, Item> = React.ComponentType<ItemDetailsControllerProps<Id, Item>>;

interface ItemDetailsContainerProps {
  isEmpty: boolean;
  error: boolean;
  disabled: boolean;
}

const ItemDetailsContainer = styled.div<ItemDetailsContainerProps>`
  min-height: 2rem;
  color: ${(props) => props.isEmpty ? props.theme.colors.lightGray : "inherit"};
  padding: 0.25rem;
  border: 2px solid ${(props) => props.error ? props.theme.colors.red : props.theme.colors.lightGray};
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.animation.transitionAll()};

  &[disabled] {
    background: ${(props) => props.theme.colors.lighterGray};
    color: ${(props) => props.theme.colors.darkGray};
  }
`;

const ErrorDetailsContainer = styled.div`
  margin: 1rem 0;
`;

interface ItemDetailsProps<Id, Item> {
  valueController: ItemDetailsControllerType<Id, Item>;
  renderItem: (item: Item) => React.ReactNode;

  error: boolean;
  disabled: boolean;
  value: Id | undefined;
}

function ItemDetails<Id, Item>(props: ItemDetailsProps<Id, Item>): React.ReactElement {
  if (props.value === undefined) {
    return (
      <ItemDetailsContainer isEmpty={true} error={props.error} disabled={props.disabled}>
        (No value)
      </ItemDetailsContainer>
    );
  } else {
    return React.createElement(props.valueController, {
      id: props.value,
      render: (viewProps) => {
        if (viewProps.status.isSuccess()) {
          return (
            <ItemDetailsContainer isEmpty={false} error={props.error} disabled={props.disabled}>
              {props.renderItem(viewProps.status.result)}
            </ItemDetailsContainer>
          );
        } else if (viewProps.status.isFailure()) {
          return (
            <ErrorDetailsContainer>
              <ErrorInfo error={viewProps.status.error.enrich({ summary: "Failed to load item details" })}/>
            </ErrorDetailsContainer>
          );
        } else if (viewProps.status.isWorking()) {
          return (
            <ItemDetailsContainer isEmpty={false} error={props.error} disabled={props.disabled}>
              Loading...
            </ItemDetailsContainer>
          );
        } else {
          return (
            <ItemDetailsContainer isEmpty={true} error={props.error} disabled={props.disabled}>
              (No value)
            </ItemDetailsContainer>
          );
        }
      }
    });
  }
}

const StyledSimpleToolbar = styled(SimpleToolbar)`
  margin-top: .5rem;
`;

interface LookupFieldProps<V, Id, Item> {
  label: string;
  name: keyof V;
  subject: string;
  gridColumns: ColumnConfig<Item>[];

  lookupController: LookupControllerType<Id, Item>;
  itemDetailsController: ItemDetailsControllerType<Id, Item>;
  renderItem: (item: Item) => React.ReactNode;

  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;

  onView?: (id: Id) => void;
}

export function LookupField<V, Id, Item>(props: LookupFieldProps<V, Id, Item>) {
  const modal = useModal();
  return (
    <Field
      name={props.name}
      render={(field: FieldProps<V>) => {
        const value = field.form.values[props.name] as unknown as Id | undefined;
        const isEmpty = value === undefined;
        const error = /*field.form.touched[props.name] && */ !!field.form.errors[props.name];

        return (
          <>
            <LookupModal
              subject={props.subject}
              gridColumns={props.gridColumns}

              modal={modal}
              controller={props.lookupController}
              onSelect={(id) => {
                field.form.setFieldError(field.field.name, "");
                field.form.setFieldValue(field.field.name, id);
              }}
            />
            <FormField label={props.label} required={props.required} error={error} limitWidth={true}>
              <ItemDetails
                valueController={props.itemDetailsController}
                renderItem={props.renderItem}

                value={value}
                error={error}
                disabled={!!props.disabled}
              />
              {/*It's buggy - error message is not displayed after using the Clear button even though it presents in*/}
              {/*field.form.errors[props.name]*/}
              <ErrorMessage name={props.name as string} component={FormErrorMessage}/>
              <StyledSimpleToolbar
                items={
                  (
                    props.readOnly
                      ? []
                      : [
                        <Button
                          key={"select"}
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disabled}
                          onClick={modal.open}
                        >
                          {isEmpty ? "Select" : "Change"}
                        </Button>,
                        <Button
                          key={"clear"}
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disabled || isEmpty}
                          onClick={() => {
                            field.form.setFieldValue(field.field.name, undefined);
                            field.form.setFieldError(field.field.name, "");
                          }
                          }
                        >
                          Clear
                        </Button>
                      ]
                  ).concat(
                    props.onView
                      ? [
                        <Button
                          key={"view"}
                          size={"small"}
                          color={"secondary"}
                          disabled={props.disabled || isEmpty}
                          onClick={() => props.onView && value !== undefined && props.onView(value)}
                        >
                          View {Characters.NewTab}
                        </Button>
                      ]
                      : []
                  )
                }
              />
            </FormField>
          </>
        );
      }}
    />
  );
}
