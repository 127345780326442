import * as React from "react";
import { AvailableJobStatuses } from "../../../types/models/availableJobStatuses";
import { OperationStatus } from "../../../types/operationStatus";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { Panel } from "../../containers/panel";
import { styled } from "../../../app/theme";
import { Button } from "../../widgets/button";
import { GraphQL } from "../../../services/graphql/generated";
import { PanelRow } from "../../containers/rows/panelRow";
import { Checkbox } from "../../widgets/checkbox";
import { Switch } from "../../widgets/switch";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import JobStatusTransitionSchedulingResult = GraphQL.JobStatusTransitionSchedulingResult;
import { WidgetStatus } from "../../utils/widgetStatus";

const StatusButton = styled(Button)`
  width: 100%;
`;

const Description = styled.div`
  margin-top: 1rem;
`;

interface StatusProps {
  status: string;
  description: string;
  isProposed: boolean;
  disabled: boolean;
  onScheduleTransition: (status: string) => void;
}

export const Status: React.FunctionComponent<StatusProps> = (props) => (
  <>
    <StatusButton
      onClick={() => props.onScheduleTransition(props.status)}
      disabled={props.disabled}
      color={props.isProposed ? "blue" : "red"}
    >
      {"\u2192 " + props.status}
    </StatusButton>
    <Description>{props.description}</Description>
  </>
);

function resultMessage(
  result: GraphQL.JobStatusTransitionSchedulingResult,
  status: string | undefined
): string {
  switch (result) {
    case GraphQL.JobStatusTransitionSchedulingResult.Started:
      return "Started transitioning to status \"" + status + "\"";
    case GraphQL.JobStatusTransitionSchedulingResult.Scheduled:
      return "This job is currently transitioning to a different status => " +
        "transitioning to status \"" + status + "\" has been scheduled";
    case GraphQL.JobStatusTransitionSchedulingResult.Rejected:
      return "This job cannot transition to this status right now, " +
        "please try again later or use the \"Force transition\" checkbox";
    case GraphQL.JobStatusTransitionSchedulingResult.AlreadyInDesiredState:
      return "This job is already in status \"" + status + "\", nothing will be done";
    case GraphQL.JobStatusTransitionSchedulingResult.AlreadyTransitioningToDesiredState:
      return "This job is already transitioning to status \"" + status + "\", nothing will be done";
  }
}

interface Props {
  loadingStatus: OperationStatus<AvailableJobStatuses>;
  schedulingTransitionTo: string | undefined;
  schedulingStatus: OperationStatus<GraphQL.JobStatusTransitionSchedulingResult>;
  onScheduleTransition: (status: string, force: boolean) => void;
}

export const ScheduleJobTransitionView: React.FunctionComponent<Props> = (props) => {
  const [showAll, setShowAll] = React.useState(false);
  const [force, setForce] = React.useState(false);

  if (props.loadingStatus.isSuccess()) {
    const result = props.loadingStatus.result;
    const statuses = showAll ? result.allSupported : result.proposed;

    return (
      <Panel>
        {
          props.schedulingStatus.isSuccess()
            ? (
              <PanelRow
                status={
                  props.schedulingStatus.result === JobStatusTransitionSchedulingResult.Rejected
                    ? WidgetStatus.Error
                    : WidgetStatus.Info
                }
              >
                {resultMessage(props.schedulingStatus.result, props.schedulingTransitionTo)}
              </PanelRow>
            )
            : (
              <OperationStatusIndicator
                progressMessage={"Scheduling transition..."}
                status={props.schedulingStatus}
                indicators={StatusIndicators.PanelRow()}
              />
            )
        }
        {statuses.isEmpty() && <PanelRow>No transitions are proposed for the current status</PanelRow>}
        {
          statuses
            .map((description, status) => (
              <PanelRow key={status}>
                <Status
                  status={status}
                  description={description}
                  isProposed={result.proposed.keySeq().contains(status)}
                  disabled={props.schedulingStatus.isWorking()}
                  onScheduleTransition={(selectedStatus) => props.onScheduleTransition(selectedStatus, force)}
                />
              </PanelRow>
            ))
            .valueSeq()
            .toArray()
        }
        <PanelRow>
          <SimpleToolbar>
            <Switch
              items={[
                { value: false, content: "Proposed Statuses" },
                { value: true, content: "All Statuses" }
              ]}
              onSelect={setShowAll}
              selected={showAll}
            />
            <Checkbox
              defaultChecked={force}
              onChange={(event) => setForce(event.target.checked)}
              disabled={!showAll}
            >
              Force transition (unsafe!)
            </Checkbox>
          </SimpleToolbar>
        </PanelRow>
      </Panel>
    );
  } else {
    return (
      <OperationStatusIndicator
        subject={"available statuses"}
        status={props.loadingStatus}
        indicators={StatusIndicators.PanelRow({ wrapper: Panel })}
      />
    );
  }
};
