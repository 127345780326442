import { GraphQL } from "../../graphql/generated";
import { List } from "immutable";
import { WatcherAction } from "../watcherAction";
import { WatcherPlugin } from "../watcherPlugin";
import { ApolloClient } from "apollo-client";
import { WatchedItems } from "../watchedItems";
import { MigrationNote } from "../../../types/models/migrationNote";

export namespace WatchedMigrationNoteLists {
  type WatchMigrationNoteListsActionType = "WatchMigrationNoteLists";

  type Subscription = GraphQL.MigrationNoteListEventsSubscription;
  type SubscriptionVariables = GraphQL.MigrationNoteListEventsSubscriptionVariables;

  export interface WatchMigrationNotesAction extends WatcherAction<WatchMigrationNoteListsActionType> {
    migrationIds: List<string>;
    replace: boolean;
    shouldRefresh: boolean;
  }

  export const WatchMigrationNoteListsAction =
    (migrationIds: Iterable<string>, replace: boolean, shouldRefresh: boolean): WatchMigrationNotesAction => ({
      type: "WatchMigrationNoteLists",
      migrationIds: List(migrationIds),
      replace,
      shouldRefresh
    });

  export const Plugin: WatcherPlugin<ApolloClient<object>, string, number> = {
    itemType: "migration note list",

    refreshTimeout: 30 * 1000,

    key: (item) => item,

    reduce: (action, existingItems) => {
      if (action.type === "WatchMigrationNoteLists") {
        return WatchedItems.Diff.build(
          action.migrationIds,
          action.shouldRefresh,
          action.replace ? existingItems.toSet().subtract(action.migrationIds) : undefined
        );
      }
    },

    updateSubscriptions: (context, diff, dispatchRenews) => {
      if (!diff.added.isEmpty()) {
        context
          .subscribe<Subscription, SubscriptionVariables>({
            query: GraphQL.MigrationNoteListEventsDocument,
            variables: { migrationIds: diff.addedItems.toArray() }
          })
          .subscribe(({ data }) => {
            if (data) {
              MigrationNote.cache(context, data.subscribeForMigrationNoteListEvents.migrationNotes);
              dispatchRenews(
                data.subscribeForMigrationNoteListEvents.migrationNotes
                  .map((migrationNotes) => migrationNotes.migrationId)
              );
            }
          });
      }

      // Note: Un-subscribing is not supported by Apollo Client, so not doing anything for diff.removed
    },

    // refreshItems: (context, migrationIds, dispatchRenews) => {
    //   if (!migrationIds.isEmpty()) {
    //     return context
    //       .query<GraphQL.GetMigrationNotesQuery, GraphQL.GetMigrationNotesQueryVariables>({
    //         query: GraphQL.GetMigrationNotesDocument,
    //         variables: { migrationIds: migrationIds.toArray() },
    //         fetchPolicy: "network-only"
    //       })
    //       .then(({ data }) =>
    //         dispatchRenews(data.getMigrationNotes.map((notes) => notes.migrationId))
    //       );
    //   }
    // }
  };
}
