import * as React from "react";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

// tslint:disable max-line-length
export const FiveStars: React.FunctionComponent<StyledComponentsProps> = (props) => (
  <svg viewBox="0 0 73 13" className={props.className}>
    <g>
      <polygon points="7.1 10.8 2.9 13 3.8 8.3 0.4 5 5 4.3 7.1 0 9.2 4.3 13.9 5 10.5 8.3 11.3 13"/>
      <polygon points="21.8 10.8 17.6 13 18.4 8.3 15 5 19.7 4.3 21.8 0 23.9 4.3 28.6 5 25.2 8.3 26 13"/>
      <polygon points="36.5 10.8 32.3 13 33.1 8.3 29.7 5 34.4 4.3 36.5 0 38.6 4.3 43.3 5 39.9 8.3 40.7 13"/>
      <polygon points="51.1 10.8 46.9 13 47.7 8.3 44.3 5 49 4.3 51.1 0 53.2 4.3 57.9 5 54.5 8.3 55.3 13"/>
      <polygon points="65.8 10.8 61.6 13 62.4 8.3 59 5 63.7 4.3 65.8 0 67.9 4.3 72.6 5 69.2 8.3 70 13"/>
    </g>
  </svg>
);
