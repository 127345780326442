import { RoutesContext } from "./routesContext";

namespace Subject {
  export const From = "from";
  export const To = "to";
}

namespace RouteParams {
  export const userId = "userId";
  export const sourceCloudServiceId = "sourceCloudServiceId";
  export const destinationCloudServiceId = "destinationCloudServiceId";
  export const subject = "subject";
}

export class ConnectRoutes {
  private readonly root = "connect";

  constructor(private context: RoutesContext) {}

  public params(): ConnectRoutes.Params {
    const userId = this.context.routeParams[RouteParams.userId];
    const sourceCloudServiceId = this.context.routeParams[RouteParams.sourceCloudServiceId];
    const destinationCloudServiceId = this.context.routeParams[RouteParams.destinationCloudServiceId];
    const subject = this.context.routeParams[RouteParams.subject];

    if (userId && sourceCloudServiceId && destinationCloudServiceId && subject) {
      return {
        userId,
        sourceCloudServiceId,
        destinationCloudServiceId,
        isSource: subject === Subject.From
      };
    } else {
      throw this.context.missingRequiredURLParamsError();
    }
  }

  public get mask(): string {
    return this.root +
      "/:" + RouteParams.userId +
      "/:" + RouteParams.sourceCloudServiceId +
      "/:" + RouteParams.destinationCloudServiceId +
      "/:" + RouteParams.subject;
  }

  public get homePath(): string { return this.context.resolvePath(this.root); }

  public pathForParams(params: ConnectRoutes.Params): string {
    return this.context.resolvePath(
      this.root +
      "/" + params.userId +
      "/" + params.sourceCloudServiceId +
      "/" + params.destinationCloudServiceId +
      "/" + (params.isSource ? Subject.From : Subject.To)
    );
  }
}

export namespace ConnectRoutes {
  export interface Params {
    userId: string;
    sourceCloudServiceId: string;
    destinationCloudServiceId: string;
    isSource: boolean;
  }
}
