import { WorkStatus } from "../models/workStatus";
import * as React from "react";
import { Spinner, SpinnerBackgroundColor } from "../glyphs/spinner";
import { CheckedCircle } from "../glyphs/checkedCircle";
import { styled } from "../../app/theme";
import { StyledComponentsProps } from "../utils/styledComponentsProps";

const Container = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  font-size: 0.9rem;
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
`;

interface Props extends StyledComponentsProps {
  status: WorkStatus;
  indicateSlowness?: boolean;
}

export const InlineProgressIndicator: React.FunctionComponent<Props> = (props) => {
  function renderBody() {
    const settings = {
      backgroundColor: "gray" as SpinnerBackgroundColor
    };
    switch (props.status) {
      case WorkStatus.Pending:
        return <Spinner progress={0} {...settings}/>;

      case WorkStatus.Working:
        return <Spinner progress={-1} animationSpeed={props.indicateSlowness ? 0.25 : 1} {...settings}/>;

      case WorkStatus.Issue:
        return <Spinner progress={50} color="red" {...settings}/>;

      case WorkStatus.Failure:
        return <Spinner progress={100} color="red" {...settings}/>;

      case WorkStatus.Success:
        return <CheckedCircle/>;
    }
  }

  return <Container className={props.className}>{renderBody()}</Container>;
};

export const LeftHandProgressIndicator = styled(InlineProgressIndicator)`
  margin-right: .5rem;
`;
