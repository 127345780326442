import { OperationStatus } from "../../../types/operationStatus";
import * as React from "react";
import { Panel } from "../../containers/panel";
import { OperationStatusIndicator } from "../../utils/operationStatusIndicator";
import { StatusIndicators } from "../../utils/statusIndicators";
import { PanelRow } from "../../containers/rows/panelRow";
import { Button } from "../../widgets/button";
import { Paragraph } from "../../widgets/paragraph";
import { styled } from "../../../app/theme";
import { EmailSupportLink } from "../../widgets/emailSupportLink";

const List = styled.ul`
  margin-block-start: 0.75rem;
  margin-block-end: 1.5rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 2rem;
  
  li {
    margin: 0.5rem 0;
  }
`;

interface Props {
  onSubmit: () => Promise<any>;
  status: OperationStatus<any>;
}

export const BatchSyncUpTool: React.FunctionComponent<Props> = (props) => (
  <Panel>
    <OperationStatusIndicator
      status={props.status}
      progressMessage={"Starting sync-up..."}
      failureMessage={"Failed to start sync-up"}
      indicators={StatusIndicators.PanelRow()}
    />
    <PanelRow>
      <strong>Important:</strong> The initial migration and sync-ups should be performed either before or after
      you reconfigure the domains and/or MX records. If this condition cannot be met, please contact us for
      assistance at <EmailSupportLink/> before you proceed.
    </PanelRow>
    <PanelRow>
      <Paragraph>
        During a sync-up, VaultMe will identify changes that were made in the source account and will sync them
        with the destination account as follows:
      </Paragraph>
      <List>
        <li>
          If changes were made to the item, the copy of the item in the destination account will be updated with
          these changes.
        </li>
        <li>
          New items created or shared with the source account will additionally be copied to the destination
          account.
        </li>
        <li>
          Copies of the items that were deleted or unshared with the source account will not be removed from the
          destination account.
        </li>
      </List>
      <Paragraph>
        To initiate a sync-up for the completed migrations, click the button below.
      </Paragraph>
    </PanelRow>
    <PanelRow>
      <Button disabled={props.status.isWorking()} onClick={props.onSubmit}>
        Sync Up
      </Button>
    </PanelRow>
  </Panel>
);
