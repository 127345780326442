import * as React from "react";
import { styled } from "../../app/theme";
import { CheckMark } from "../glyphs/checkMark";
import { PanelRow } from "../containers/rows/panelRow";

const Title = styled.div`
`;

const List = styled.div`
  padding: 0.5rem 0 0 0;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0 0 0;
`;

const Text = styled.div`
  font-size: 0.9375rem;
  padding: 0 0 0 0.375rem;
`;

const StyledCheckMark = styled(CheckMark)`
  width: 0.8rem;
  height: 0.8rem;
`;

export const SecurityStatementRow: React.FunctionComponent = () => (
  <PanelRow size="larger">
    <Title>
      We're serious about your Security &amp; Privacy
    </Title>
    <List>
      <Line>
        <StyledCheckMark/>
        <Text>
          Fully automated.
        </Text>
      </Line>
      <Line>
        <StyledCheckMark/>
        <Text>
          Safe &amp; secure.
        </Text>
      </Line>
      <Line>
        <StyledCheckMark/>
        <Text>
          GDPR compliant.
        </Text>
      </Line>
      <Line>
        <StyledCheckMark/>
        <Text>
          Disconnect any time.
        </Text>
      </Line>
      <Line>
        <StyledCheckMark/>
        <Text>
          Data never shared with anyone.
        </Text>
      </Line>
    </List>
  </PanelRow>
);
