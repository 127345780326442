import { ConnectResult } from "../../types/models/connectResult";
import { ActionLike } from "../actionLike";
import { Session } from "../../types/models/session";
import { User } from "../../types/models/user";

export enum SessionActionType {
  ReplacedSession = "session/initialized",
  ReplacedUser = "session/replacedUser",
  SignedIn = "session/signedIn"
}

export interface ReplacedSessionAction extends ActionLike<SessionActionType.ReplacedSession> {
  readonly session: Session;
}

export function replacedSessionAction(session: Session): ReplacedSessionAction {
  return {
    type: SessionActionType.ReplacedSession,
    session
  };
}

export interface ReplacedUserAction extends ActionLike<SessionActionType.ReplacedUser> {
  readonly user: User;
}

export function replacedUserAction(user: User): ReplacedUserAction {
  return {
    type: SessionActionType.ReplacedUser,
    user
  };
}

export interface SignedInAction extends ActionLike<SessionActionType.SignedIn> {
  readonly connectResult: ConnectResult;
}

export function signedInAction(connectResult: ConnectResult): SignedInAction {
  return {
    type: SessionActionType.SignedIn,
    connectResult
  };
}

export type SessionAction = ReplacedSessionAction | ReplacedUserAction | SignedInAction;
