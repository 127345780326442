import * as React from "react";
import { PanelRow } from "../../containers/rows/panelRow";
import { Indicator, Indicators, IndicatorValue, JsonIndicator } from "../../widgets/indicator";
import { Panel } from "../../containers/panel";
import { preciseTimestamp } from "../../../utils/formatting";
import { Button } from "../../widgets/button";
import { IndefiniteProgressBar } from "../../widgets/indefiniteProgressBar";
import { Constants } from "../../../app/constants";
import { Delayed } from "../../utils/delayed";
import { SimpleToolbar } from "../../widgets/simpleToolbar";
import { TaskHistory } from "../../../types/models/taskHistory";
import { Checkbox } from "../../widgets/checkbox";
import { useRoutes } from "../../../app/routes/useRoutes";
import { useBrowser } from "../../../utils/useBrowser";

interface Props {
  taskHistory: TaskHistory;

  refreshing: boolean;
  onRefresh: () => void;

  includeMinorUpdates: boolean;
  onToggleIncludeMinorUpdates: () => void;

  onShowPendingIssuesOnly: () => void;
  onShowResolvedIssuesOnly: () => void;
}

export const TaskInfoPanel: React.FunctionComponent<Props> = (props) => {
  const browser = useBrowser();
  const routes = useRoutes();

  const task = props.taskHistory.task;

  return (
    <Panel>
      {props.refreshing && (
        <Delayed delay={Constants.ProgressIndicatorDelay}>
          <IndefiniteProgressBar/>
        </Delayed>
      )}
      <PanelRow size={"smaller"}>
        <SimpleToolbar>
          <Button
            color="white"
            size="small"
            onClick={() => browser.navigateTo(routes.jobs.jobPath(props.taskHistory.jobId))}
          >
            &lsaquo; Job
          </Button>
          <Button
            size={"small"}
            color={"white"}
            onClick={props.onRefresh}
            disabled={props.refreshing}
          >
            Refresh
          </Button>
          <Checkbox
            checked={props.includeMinorUpdates}
            onChange={props.onToggleIncludeMinorUpdates}
          >
            List minor task events
          </Checkbox>
        </SimpleToolbar>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"Type"}>
            <IndicatorValue value={task.type}/>
          </Indicator>
          <Indicator title={"Started By"}>
            <IndicatorValue value={task.updatedBy || "?"}/>
          </Indicator>
          <Indicator title={"Started At"}>
            <IndicatorValue value={preciseTimestamp(task.createdAt)}/>
          </Indicator>
          <Indicator title={"Completed At"}>
            <IndicatorValue value={task.completedAt ? preciseTimestamp(task.completedAt) : "--"}/>
          </Indicator>
          <Indicator title={"Archived At"}>
            <IndicatorValue value={task.archivedAt ? preciseTimestamp(task.archivedAt) : "--"}/>
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <JsonIndicator title={"Props"} value={task.propsJson} version={task.propsVersion}/>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <Indicators>
          <Indicator title={"System Status"}>
            <IndicatorValue value={task.internalStatus}/>
          </Indicator>
          <Indicator title={"Issues"}>
            <IndicatorValue
              value={props.taskHistory.pendingTaskIssuesCount}
              suffix={"Pending"}
              onClick={props.onShowPendingIssuesOnly}
            />
            <IndicatorValue
              value={props.taskHistory.resolvedTaskIssuesCount}
              suffix={"Resolved"}
              onClick={props.onShowResolvedIssuesOnly}
            />
          </Indicator>
        </Indicators>
      </PanelRow>
      <PanelRow size={"smaller"}>
        <JsonIndicator title={"State"} value={task.stateJson} version={task.stateVersion}/>
      </PanelRow>
      {
        task.resultJson && task.resultVersion && (
          <PanelRow size={"smaller"}>
            <JsonIndicator title={"Result"} value={task.resultJson} version={task.resultVersion}/>
          </PanelRow>
        )
      }
      {
        task.abortReason && (
          <PanelRow size={"smaller"}>
            <JsonIndicator title={"Abort Reason"} value={task.abortReason}/>
          </PanelRow>
        )
      }
    </Panel>
  );
};
