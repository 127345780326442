import { BlueprintContext } from "../blueprintContext";
import { DataFlowWithDefault, DataFlowWithDefaultProps } from "../relationship";
import { State } from "../state";
import { GraphQL } from "../../services/graphql/generated";
import { identity } from "../../utils/misc";

export class ComponentResolved extends DataFlowWithDefault<ComponentResolved.Props, boolean> {
  public stateWhenUnblocked(context: BlueprintContext, componentState: State<unknown>): State<boolean> {
    return componentState.hasOutput ? State.resolved(true) : State.pending();
  }

  // public messages(context: BlueprintContext): string[] {
  //   const state = this.state(context);
  //   const message = state.type === StateType.Resolving || state.type === StateType.Resolved
  //     ? state.output.message
  //     : undefined;
  //   return message ? [message] : [];
  // }
}

export namespace ComponentResolved {
  export type Props = DataFlowWithDefaultProps<boolean>;

  export namespace Props {
    export function fromGraphQL(props: GraphQL.ComponentResolvedPropsFragment): Props {
      return DataFlowWithDefaultProps.fromGraphQL(props, identity);
    }
  }
}
