import { BlueprintContext } from "../blueprintContext";
import { ComponentHubs, Component } from "../component";
import { ComponentVisualization } from "../componentVisualization";
import { State } from "../state";
import { FactRef } from "../../types/facts/factRef";
import { Collectable } from "../../types/collectables/collectable";
import { Fact, FactState } from "../../types/facts/fact";
import { ComponentColorSchema } from "../componentColorSchema";
import { Images } from "../../app/images";
import { GraphQL } from "../../services/graphql/generated";
import { parseServerJson } from "../parseServerJson";
import { parseCollectable } from "../../types/collectables/parseCollectable";
import { None, Option } from "../../utils/monads/option";
import { List } from "immutable";
import { FactHelper } from "../factHelper";
import { BlueprintError } from "../blueprintError";

export abstract class FactCompLike<
  Props extends FactCompLike.PropsLike<P, C, T>,
  Hubs extends ComponentHubs,
  P,
  C,
  T extends Collectable<P, C>>
  extends Component<Props, Hubs, FactCompLike.Output<P, C, T>> {

  public stateWhenUnblocked(context: BlueprintContext): State<FactCompLike.Output<P, C, T>> {
    // FactHelper.getFact will check for value type, so type cast below is safe enough
    return FactHelper.getFact<P, C, T>(context, this, this.props.factRef)
      .map((fact) => {
        if (fact.state instanceof FactState.Missing) {
          return State.pending<FactCompLike.Output<P, C, T>>();
        } else if (fact.state instanceof FactState.Failed) {
          return State.error<FactCompLike.Output<P, C, T>>(
            BlueprintError.build(fact.state.errorType, fact.state.errorMessage)
          );
        } else if (fact.state instanceof FactState.Preparing) {
          return State.preparing<FactCompLike.Output<P, C, T>>();
        } else if (fact.state instanceof FactState.Collecting) {
          return State.resolving(
            { collectable: fact.state.value as T, value: None<C>() },
            fact.state.value.progress()
          );
        } else if (fact.state instanceof FactState.Collected) {
          const collectable = fact.state.value as T;
          return State.resolved({ collectable, value: collectable.completeValue() });
        } else {
          throw new Error("Unexpected fact state type: " + fact);
        }
      })
      .getOrElse(() => State.pending<FactCompLike.Output<P, C, T>>());
  }

  // public messages(context: BlueprintContext): List<string> {
  //   const state = this.state(context);
  //   if (this.props.factRef.valueType === PredefinedCollectable.Type.Boolean) {
  //     const disabled = state.type === StateType.Resolved && !state.output.value;
  //     if (state.type === StateType.Resolving) {
  //       return List(["Checking " + this.props.name + " in " + new Tracer(this, context).accountId() + "..."]);
  //     } else if (disabled) {
  //       return List([this.props.name + " is disabled in " + new Tracer(this, context).accountId()]);
  //     }
  //   } else {
  //     // const cachedItem = context.facts.cachedItem(this.factId(context));
  //     // if (cachedItem && (cachedItem.updating ||
  //     // (cachedItem.item && cachedItem.item.status === FactStatus.Collecting))) {
  //     if (state.type === StateType.Resolving) {
  //       return List([
  //         "Calculating " + this.props.name.toLowerCase() + " in " + new Tracer(this, context).accountId() + "..."
  //       ]);
  //     }
  //   }
  //   return List();
  // }

  public visualization(
    context: BlueprintContext,
    state: State<FactCompLike.Output<P, C, T>>): ComponentVisualization {
    // if (this.props.factRef.valueType === PredefinedCollectable.Type.Boolean) {
    //   const disabled = state.type === StateType.Resolved && !state.output.value;
    //   return {
    //     title: "Capability",
    //     summary: this.props.name + " " + (disabled ? "Disabled" : "Enabled"),
    //     icon: disabled ? Images.Blueprint.Cross : Images.Blueprint.CheckMark,
    //     color: state.type === StateType.Resolving
    //       ? ComponentColorSchema.SolidGray
    //       : disabled ? ComponentColorSchema.SolidRed : ComponentColorSchema.SolidGreen,
    //     sizeMultiplier: disabled ? 1.5 : 1
    //   };
    // } else {
    return {
      title: "Fact",
      summary: this.props.name +
        state.progress.map((percentage) => " (" + Math.round(percentage) + "%)").getOrElse(() => ""),
      icon: Images.Blueprint.Digits,
      color: ComponentColorSchema.SolidBlue
    };
    // }
  }

  public unblockedFactIds(context: BlueprintContext): List<string> {
    if (this.isUnblocked(context)) {
      return FactHelper.resolvedFactRef(context, this, this.props.factRef).map(((factRef) => factRef.id)).toList();
    } else {
      return List();
    }
  }
}

export namespace FactCompLike {
  export interface PropsLike<P, C, T extends Collectable<P, C>> {
    name: string;
    factRef: FactRef.Props;
  }

  export interface Output<P, C, T extends Collectable<P, C>> {
    collectable: T;
    value: Option<C>;
  }

  export namespace Output {
    export function fromGraphQL(output: GraphQL.FactCompLikeOutputFragment): Output<any, any, any> {
      return {
        collectable: parseCollectable(output.collectable),
        value: Option.mayBe(output.valueJson).map(parseServerJson),
      };
    }
  }
}
