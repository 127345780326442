import * as React from "react";
import { CloudService } from "../types/models/cloudService";
import { Set } from "immutable";
import { Connection } from "../types/models/connection";
import { IncrementalSignInDefs } from "../views/blocks/incrementalSignIn";
import { useSignInFlow } from "../components/auth/useSignInFlow";
import { SignInFlowView } from "../views/blocks/auth/signInFlowView";
import { useAuthProviders, useCloudServices } from "../app/configuration";
import { AuthContext } from "../views/blocks/authContext";
import { SignInContextType } from "../types/models/signInContextType";

interface Props extends IncrementalSignInDefs.SignInComponentProps {
  cloudService: CloudService;
  connection: Connection;
  roles: Set<string>;
}

export const IncrementalSignIn: React.FunctionComponent<Props> = (props) => {
  const cloudServices = useCloudServices();
  const authProviders = useAuthProviders();

  const signInFlow = useSignInFlow({
    flowId: "Incremental",
    contextType: SignInContextType.Increment,
    newUserSettings: undefined,
    defaultRoles: props.roles,
    existingConnection: props.connection,
    onSignIn: props.onSignIn
  });

  return (
    <SignInFlowView
      authContext={AuthContext.Identification}
      cloudServices={cloudServices}
      authProviders={authProviders}
      state={signInFlow.state}
      defaultRoles={props.roles}
      grantedRoles={props.connection.roles}
      onRolesSelect={signInFlow.selectRoles}
      onSignIn={signInFlow.handleSignInSuccess}
    />
  );
};
