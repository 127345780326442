import * as React from "react";
import * as ReactDOM from "react-dom";
import { useSettings } from "../../utils/useAppState";
import { AdminToolsMode } from "../../state/settings/state";
import { BlueprintExplorer } from "../../blueprints/explorer/blueprintExplorer";
import { MaterializedMigrationBlueprint } from "../../blueprints/materializedMigrationBlueprint";
import { useApolloClient } from "@apollo/react-hooks";
import { Switch, SwitchItem } from "../widgets/switch";
import { styled } from "../../app/theme";
import {
  maximizedSidebarAction,
  restoredSidebarAction, toggledAdminToolsAction,
} from "../../state/settings/actions";
import { CloseButton, ExpandCollapseButton } from "../widgets/expandCollapseButton";
import { useDispatch } from "react-redux";
import { FactsAndScansManagementTool } from "../../components/factsAndScansManagement/factsAndScansManagementTool";

type SidebarHook = (content: () => React.ReactElement | null | undefined) => React.ReactPortal | null;

export function useSidebar(): SidebarHook {
  const sidebarSize = useSettings().adminToolsMode;
  return (content) => {
    const container = document.getElementById("sidebar");
    if (container) {
      return ReactDOM.createPortal(
        sidebarSize !== AdminToolsMode.Hidden ? content() : null,
        container
      );
    } else {
      return null;
    }
  };
}

interface SidebarProps {
  render: () => React.ReactElement;
}

export const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  const sidebar = useSidebar();
  return sidebar(props.render);
};

const SidebarHeader = styled.div`
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGray};
  padding: .5rem 1rem;
  box-sizing: border-box;
  
  // Required to cover the sidebar placeholder
  background: ${(props) => props.theme.colors.white};
  position: relative;
  
  flex-shrink: 0;

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: .5rem;
  }
`;

const SidebarHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const StyledExpandCollapseButton = styled(ExpandCollapseButton)`
  margin-right: .5rem;

  ${(props) => props.theme.responsive.respondToSmall()} {
    display: none;
  }
`;

const SidebarContent = styled.div<{ noPadding: boolean }>`
  flex-grow: 1;
  overflow: auto;
  padding: ${(props) => props.noPadding ? "0" : "1rem"};
  background: ${(props) => props.theme.colors.offWhite}; 

  ${(props) => props.theme.responsive.respondToSmall()} {
    padding: .5rem;
  }

  ${(props) => props.theme.responsive.respondToXSmall()} {
    padding: 0;
  }
`;

interface AdminSidebarTool {
  title: string;
  render: () => React.ReactElement;
  noPadding?: boolean;
}

interface AdminSidebarProps {
  blueprint?: MaterializedMigrationBlueprint;
  tools?: AdminSidebarTool[];
}

export const AdminSidebar: React.FunctionComponent<AdminSidebarProps> = (props) => {
  const sidebarSize = useSettings().adminToolsMode;
  const sidebar = useSidebar();
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  const [currentToolTitle, setCurrentToolTitle] = React.useState<string>();

  function blueprintTools(): AdminSidebarTool[] {
    if (props.blueprint) {
      const blueprint = props.blueprint;
      return [
        {
          title: "Facts",
          render: () => (
            <FactsAndScansManagementTool
              facts={blueprint.context.facts}
              connections={blueprint.context.connections}
              sourceCloudServiceId={blueprint.sourceCloudServiceId}
              destinationCloudServiceId={blueprint.destinationCloudServiceId}
              blueprintInputs={blueprint.context.inputs}
            />
          )
        },
        {
          title: "Blueprint",
          render: () => (
            <BlueprintExplorer
              config={{
                blueprint,
                renderServerElements: () => MaterializedMigrationBlueprint.renderServerElements(apolloClient, blueprint)
              }}
            />
          ),
          noPadding: true
        }
      ];
    } else {
      return [];
    }
  }

  const tools = (props.tools || []).concat(blueprintTools());
  const toolTitles = tools.map(({ title }) => title);
  const toolbarItems = toolTitles.map((title): SwitchItem => (({ value: title, content: title })));

  const currentToolIndex = currentToolTitle !== undefined ? toolTitles.indexOf(currentToolTitle) : -1;
  const currentTool = currentToolIndex !== -1
    ? tools[currentToolIndex]
    : tools.length !== 0
      ? tools[0]
      : undefined;

  return sidebar(() => {
    return (
      <>
        <SidebarHeader>
          <SidebarHeaderLeft>
            <StyledExpandCollapseButton
              direction={"left"}
              expanded={sidebarSize === AdminToolsMode.FullScreen}
              onClick={() => dispatch(
                sidebarSize === AdminToolsMode.FullScreen ? restoredSidebarAction() : maximizedSidebarAction()
              )}
            />
            <Switch items={toolbarItems} selected={currentTool?.title} onSelect={setCurrentToolTitle}/>
          </SidebarHeaderLeft>
          <CloseButton onClick={() => dispatch(toggledAdminToolsAction())}/>
        </SidebarHeader>
        <SidebarContent noPadding={!!currentTool?.noPadding}>
          {currentTool?.render()}
        </SidebarContent>
      </>
    );
  });
};

const SidebarPlaceholderLayout = styled(SidebarHeader)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: #808080;
`;

export const SidebarPlaceholder: React.FunctionComponent = (props) => {
  const dispatch = useDispatch();
  return (
    <SidebarPlaceholderLayout>
      No admin tools available
      <CloseButton onClick={() => dispatch(toggledAdminToolsAction())}/>
    </SidebarPlaceholderLayout>
  );
};
