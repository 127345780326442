import { GraphQL } from "../../services/graphql/generated";
import { Set, List } from "immutable";

export class AuthProvider implements AuthProvider.Props {
  constructor(protected readonly props: AuthProvider.Props) {
  }

  public get id(): string { return this.props.id; }
  public get roles(): List<string> { return this.props.roles; }

  public get accessKeyValidationCapabilities(): AuthProvider.AccessKeyValidationCapabilities {
    return this.props.accessKeyValidationCapabilities;
  }

  public withoutUnsupportedRoles(roles: Set<string>): Set<string> {
    return roles.filter((role) => this.roles.contains(role)).toSet();
  }
}

export namespace AuthProvider {
  export interface Props {
    readonly id: string;
    readonly roles: List<string>;
    readonly accessKeyValidationCapabilities: AccessKeyValidationCapabilities;
  }

  export function fromGraphQL(authProvider: GraphQL.AuthProvider): AuthProvider {
    return new AuthProvider({
      id: authProvider.id,
      roles: List(authProvider.roles),
      accessKeyValidationCapabilities: authProvider.accessKeyValidationCapabilities
    });
  }

  export enum AccessKeyValidationCapabilities {
    CannotValidatePermissions = "CannotValidatePermissions",
    CanValidateRequestedPermissions = "CanValidateRequestedPermissions",
    CanListGrantedPermissions = "CanListGrantedPermissions"
  }
}
