import * as React from "react";
import { UserType } from "../../types/models/userType";
import { useSignUpFlow } from "../../components/auth/useSignUpFlow";
import { Constants } from "../../app/constants";
import { Set } from "immutable";
import { useRoutes } from "../../app/routes/useRoutes";
import { AppLayout } from "../../views/layouts/appLayout";
import {
  OrganizationPortalSignInPageView
} from "../../views/screens/organizationPortal/organizationPortalSignInPageView";
import { useBrowser } from "../../utils/useBrowser";
import { OrganizationPortalRoutes } from "../../app/routes/organizationPortalRoutes";
import { RedirectingPlaceholder } from "../../views/blocks/redirectingPlaceholder";

export const OrganizationPortalSignInPage: React.FunctionComponent = () => {
  const browser = useBrowser();
  const routes = useRoutes();
  const [redirecting, setRedirecting] = React.useState(false);

  const signUpFlow = useSignUpFlow({
    flowId: "OrganizationPortalSignIn",
    newUserSettings: { userType: UserType.Personal },
    onSignUp: () => {
      setRedirecting(true);
      const urlParams = new URLSearchParams(window.location.search);
      const redirectTo = urlParams.get(OrganizationPortalRoutes.signInUrlRedirectToParam);
      if (redirectTo) {
        window.location.replace(redirectTo);
      } else {
        browser.navigateTo(routes.migrations.homePath);
      }
    }
  });

  if (redirecting) {
    return <RedirectingPlaceholder message="Redirecting to the portal..."/>;
  } else {
    return (
      <AppLayout keepLeftHandNavOpenOnLargeScreens={false}>
        <OrganizationPortalSignInPageView
          state={signUpFlow.state}
          roles={Set([Constants.Auth.IdentificationRole])}
          onSignUp={signUpFlow.handleSignUpSuccess}
        />
      </AppLayout>
    );
  }
};
